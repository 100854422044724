import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import request from "../../utils/helper/request";
import * as TextProps from "../../utils/constants/text";
import { postLogoutRedirectUri, msalConfig } from "config/appConfig";
import { setCookie } from "../../utils/helper/cookie";
import { providerMyClientDetailsInfo } from "../provider/providerMyClientsActions";
import * as roleProps from "../../utils/constants/rolesConstants";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var config = require("../../utils/constants/appConfigConstants");
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;
var loginScreenData = {
  mainHeading: "Consumer Login",
  username: config.loginScreenData_un,
  password: config.loginScreenData_pwd,
  login: "Login",
  signup: "SignUp",
  IS_AUTHENTICATED: "FALSE",
  forgotPassword: "Forgot Password",
};

const actionTypes = {
  postConsumerLoginSuccess: "POST_CONSUMER_LOGIN_SUCCESS",
  networkOrServiceFailed: "NETWORK_OR_SERVICE_FAILED",
  getConsumerLoginRequested: "CONSUMER_LOGIN_REQUEST",
  getConsumerLoginFulfilled: "CONSUMER_LOGIN_FULLFILLED",
  postConumserLoginFailure: "LOGIN_STATUS_MESSAGE",
  loginPassed: "LOGIN_SUCCESSFULL",
  mfaPassed: "MFA_SUCCESSFULL",
  consumerLoginSuccessAction: "CONSUMER_LOGIN_SUCCESS",
  azureLoginPassed: "AZURE_LOGIN_SUCCESSFULL",
};

//Common Fetch Functions
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
export function azureLogin(msalIns) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const username = window.sessionStorage.getItem("user_name");
      var data = {
        email: username,
        password: "",
      };
      const ClientRes = request({
        method: "post",
        url: config.TigerContextURL + "/clientlogininfo",
        data,
      })
        .then(function (ClientRes) {
          // console.log("Client info success", ClientRes)
          dispatch(onAzureLoginSuccess(ClientRes));
          sessionStorage.setItem("applicationInanonymousMode", false);
          ClientRes.roleList.forEach((role) => {
            if (
              role.roleName === roleProps.PROVIDER ||
              role.roleName === roleProps.PROVIDER_ROLE_SPVUSR ||
              role.roleName === roleProps.PROVIDER_ROLE_DCPVSG
            ) {
              dispatch(providerMyClientDetailsInfo(data.email));
            }
          });
        })
        .catch(function (error) {
          setCookie("isLoginFailed", true);
          sessionStorage.setItem("applicationInanonymousMode", false);
          if(error.response.status===401){
            if(error.response.data.message && error.response.data.message === "Invalid Role") {
              setCookie("redirectLogin", true);
            } else {
              setCookie("isLoginFailedDueToUnlinkAc",true);
            }            
          }
          const currentAccount = msalIns.getAccountByHomeId(
            JSON.parse(window.sessionStorage.getItem("home_account"))
              .homeAccountId
          );
          msalIns.logout({
            account: currentAccount,
            postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
            authority: msalConfig.auth.authority,
          });
          window.sessionStorage.clear();
          dispatch(serviceFailedAction(error));
        });
    });
  };
}

export function onLogin(loginData, userPassword) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const audit = getAudit();
      // Axios Http Options

      if (loginData.tenantId !== undefined) {
        var url =
          config.TigerURL +
          "/oauth/token?grant_type=password&username=" +
          loginData.email +
          "&password=" +
          loginData.password +
          "&tenantId=" +
          loginData.tenantId +
          "&sessionId=" +
          loginData.sessionId +
          "&mechanismId=" +
          loginData.mechanismId;
      } else {
        url =
          config.TigerURL +
          "/oauth/token?grant_type=password&username=" +
          loginData.email +
          "&password=" +
          loginData.password;
      }

      axios({
        method: "post",
        url: url,
        withCredentials: TextProps.VALUE_FALSE,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          audit: JSON.stringify(audit),
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
        auth: {
          username: config.username,
          password: config.password,
        },
      })
        .then(function (response) {
          sessionStorage.removeItem("oauthToken");
          const mfaLogin = response.data;
          const oauthToken = response.data;
          sessionStorage.setItem("oauthToken", JSON.stringify(oauthToken));

          if (mfaLogin.expires_in <= 0) {
            return dispatch(onMFASubmitSuccess(mfaLogin));
          } else if (mfaLogin.expires_in >= 0) {
            var userDetails = {
              email: loginData.email,
              password: userPassword,
            };
            return dispatch(getClientLoginInfo(userDetails));
          }
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
        });
    });
  };
}

export function getClientLoginInfo(loginData) {
  return (dispatch) => {
    const audit = getAudit();
    var oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const secureHeaders = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(
        config.TigerContextURL + "/clientlogininfo",
        loginData,
        secureHeaders
      )
      .then(function (response) {
        dispatch(onLoginSuccess(response.data));
        sessionStorage.setItem("applicationInanonymousMode", false);
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
        sessionStorage.setItem("applicationInanonymousMode", false);
      });
  };
}

function onAzureLoginSuccess(data) {
  return {
    type: actionTypes.azureLoginPassed,
    payload: data,
  };
}

function onLoginSuccess(data) {
  return {
    type: actionTypes.loginPassed,
    payload: data,
  };
}

export function onMFASubmit(loginData) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/security/advLogin", loginData, HttpOptions)
      .then(function (response) {
        // dispatch(onMFASubmitSuccess(response.data))
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onMFASubmitSuccess(data) {
  return {
    type: actionTypes.mfaPassed,
    payload: data,
  };
}

export function getConsumerLoginContents() {
  return (dispatch) => {
    dispatch(getConsumerLoginRequestedAction());
    const consumerLogin = loginScreenData;
    dispatch(getConsumerLoginFulfilledAction(consumerLogin));
  };
}

//Consumer Login Action
export function postConsumerLogin(loginRequest) {
  return (dispatch) => {
    var url = config.TigerContextURL + "/consumer/login";
    var options = {
      method: "POST",
      mode: "no-cors",
    };

    axios
      .post(url, loginRequest, options)
      .then(checkStatus)
      .then(function (loginValidationData) {
        dispatch(postLoginSuccessAction(loginValidationData.data));
      })
      .catch(function (error) {
        dispatch(postConumserLoginFailure(error.response));
      });
  };
}

//Common Action for Service Fail
function serviceFailedAction(response) {
  return {
    type: actionTypes.networkOrServiceFailed,
    payload: response,
  };
}

//Post Login Success Action
function postLoginSuccessAction(response) {
  if (response !== undefined && response.IS_AUTHENTICATED === "TRUE") {
    sessionStorage.setItem("IS_SESSIONID", response.IS_SESSIONID);
    if (1 === response.USER_ROLE) window.location.assign("/consumer/home");
    if (2 === response.USER_ROLE) window.location.assign("/provider/home");
  }
  return {
    type: actionTypes.postConsumerLoginSuccess,
    payload: response,
  };
}

function postConumserLoginFailure(responseObj) {
  return {
    type: actionTypes.postConumserLoginFailure,
    payload: responseObj,
  };
}

//Action Dispatch
function getConsumerLoginRequestedAction() {
  return {
    type: actionTypes.getConsumerLoginRequested,
  };
}

function getConsumerLoginFulfilledAction(consumerLogin) {
  return {
    type: actionTypes.getConsumerLoginFulfilled,
    payload: consumerLogin,
  };
}
