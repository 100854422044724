import _ from "lodash";
import { MONTHSHORT } from "../../../utils/constants/controls";
import { isNone } from "../../../utils/components/utilities/controls";
import {
  houseHoldMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  medicalExpenseMultilanguageLabels,
} from "../applySnapText";
import {
  expenseKidsAndAdultsTemplate,
  expenseChildSupportTemplate,
  expenseMedicalExpenseTemplate,
} from "../applySnapMapData";
import * as OptionsProps from "../../../utils/constants/options";
import * as TextProps from "../../../utils/constants/text";
import { childDependnMultilanguageLabels } from "../applySnapText";
let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

let dependentCareExpenseList = [];
let childSupportList = [];
export function populateExpenseBinding(
  sections,
  serverJson,
  ExpenseSectionTemplate
) {
  (dependentCareExpenseList = []), (childSupportList = []);
  // sections[4].completed = TextProps.VALUE_FALSE;
  let HouseHoldSection = sections[2];
  let houseHoldMembers = HouseHoldSection.subsections;
  let HouseHoldMembersOptions =
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options;
  sections[4].subsections[2].questions[0].options = HouseHoldMembersOptions;
  sections[4] = checkActiveSection(sections[4], serverJson);
  sections[4].subsections[0].questions = housingAndUtilityCostBinding(
    sections[4],
    sections[4].subsections[0].questions,
    serverJson
  );
  sections = mapExpenseOptions(sections, serverJson);
  sections[4].subsections[1].questions = childAndAdultCareBinding(
    sections[4],
    sections[4].subsections[1].questions,
    serverJson
  );
  sections[4].subsections[1].subsections = renderDependentsCareCosts(
    sections[4],
    sections[4].subsections[1].questions,
    serverJson
  );
  sections[4].subsections[2].questions = childSupportCostSectionBinding(
    sections[4],
    sections[4].subsections[2].questions,
    serverJson
  );
  sections[4].subsections[3].questions = childMedicalCostSectionBinding(
    sections,
    sections[4].subsections[3].questions,
    serverJson
  );
  sections[4].subsections[3].subsections =
    childMedicalSubSectionCostSectionBinding(
      sections,
      sections[4].subsections[3].questions,
      serverJson
    );
  return sections[4];
}
function checkIfHouseholdContainsData(household, value) {
  if (household.length) {
    household.forEach((houseMem) => {
      if (houseMem[value].length) {
        return TextProps.VALUE_TRUE;
      }
    });
  } else {
    return TextProps.VALUE_FALSE;
  }
}
function checkActiveSection(sections, serverJson) {
  if (
    serverJson.application.payHeat !== TextProps.VALUE_N ||
    serverJson.application.payAC !== TextProps.VALUE_N ||
    serverJson.application.payACFee !== TextProps.VALUE_N ||
    serverJson.application.elgas !== TextProps.VALUE_N ||
    serverJson.application.hasPhone !== TextProps.VALUE_N ||
    serverJson.application.shelterExpenses.length
  ) {
    sections.completed = TextProps.VALUE_TRUE;
    sections.subsections[0].completed = TextProps.VALUE_TRUE;
  }
  if (
    serverJson.application.applicant.dependentCareExpense.length ||
    checkIfHouseholdContainsData(
      serverJson.application.applicant.household,
      "dependentCareExpense"
    )
  ) {
    sections.completed = TextProps.VALUE_TRUE;
    sections.subsections[1].completed = TextProps.VALUE_TRUE;
  }
  if (serverJson.application.applicant.supportExpenses.length) {
    sections.completed = TextProps.VALUE_TRUE;
    sections.subsections[2].completed = TextProps.VALUE_TRUE;
  }
  if (
    serverJson.application.applicant.medicalExpenses.length ||
    checkIfHouseholdContainsData(
      serverJson.application.applicant.household,
      "medicalExpenses"
    )
  ) {
    sections.completed = TextProps.VALUE_TRUE;
    sections.subsections[3].completed = TextProps.VALUE_TRUE;
  }
  return sections;
}
export function housingAndUtilityCostBinding(
  expenseSection,
  housingAndUtilityCost,
  serverJson
) {
  let housingAnswer = [],
    shelterAnswer = [];
  let housingItems = ["payHeat", "payAC", "payACFee", "elgas", "hasPhone"];
  let shelterExpenseItems = ["RENT", "MTGPRN", "PRPTAX", "HOMEIN", "CONFEE"];
  for (let item of housingItems) {
    if (serverJson.application[item] === "Y") {
      housingAnswer.push(item);
    }
  }
  bindBothTargetAnswer(housingAndUtilityCost[0], housingAnswer);

  for (let shellterItem of serverJson.application.shelterExpenses) {
    if (
      shelterExpenseItems.indexOf(shellterItem.householdExpenseTypeCode) !== -1
    ) {
      shelterAnswer.push(shellterItem.householdExpenseTypeCode);
    }
  }
  bindBothTargetAnswer(housingAndUtilityCost[1], shelterAnswer);
  housingAndUtilitySubsectionBinding(expenseSection, shelterAnswer, serverJson);
  return housingAndUtilityCost;
}

export function childAndAdultCareBinding(
  expenseSection,
  childAndAdultCare,
  serverJson
) {
  // if(childAndAdultCare[0].Answer && childAndAdultCare[0].Answer.length || childAndAdultCare[0].targetValue && childAndAdultCare[0].targetValue.length){
  //   let conditionalQuestionTemplate = _.cloneDeep(expenseKidsAndAdultsTemplate.questions[0].conditionals[0]);
  //   let childAndAdultAnswer = childAndAdultCare[0].Answer;
  //   childAndAdultAnswer.forEach(function (ans, ansIndex) {
  //   childAndAdultCare[0].options.forEach(function (member, index) {
  //     if (member.optionValue === ans) {
  //       let conditionals = _.cloneDeep(conditionalQuestionTemplate);
  //       conditionals.valueToMatch = member.optionValue;
  //       conditionals.conditionalQuestions[0].label = houseHoldMultilanguageLabels(member[OptionsProps.OPTION_DISPLAY], language).conditionalLabel;
  //       conditionals.conditionalQuestions[0].name = "recipient-of-care-" + [ansIndex];

  //       conditionals.conditionalQuestions[0].options = [];

  //       childAndAdultCare[0].options.forEach(function (hhMember, memIndex) {
  //         if (hhMember.optionValue !== ans) {
  //           conditionals.conditionalQuestions[0].options.push(hhMember)
  //         }
  //       }, this);
  //       childAndAdultCare[0].conditionals.push(conditionals);
  //     }
  //   }, this);
  //   }, this);
  // }else{
  childAndAdultCare[0].Answer = [];
  childAndAdultCare[0].targetValue = [];
  let conditionalQuestionTemplate = _.cloneDeep(
    childAndAdultCare[0].conditionals[0]
  );
  childAndAdultCare[0].conditionals = [];
  if (serverJson.application.applicant.dependentCareExpense.length) {
    childAndAdultCare[0].Answer.push(serverJson.application.applicant.personId);
    childAndAdultCare[0].targetValue.push(
      serverJson.application.applicant.personId
    );
  }
  //Child Support Cost for Grantee
  for (let dependentItem of serverJson.application.applicant
    .dependentCareExpense) {
    // dependentCareExpenseList.push(dependentItem);
    childAndAdultCare[0].options.forEach(function (member, index) {
      if (
        member.optionValue ===
        serverJson.application.applicant.personId.toString()
      ) {
        if (dependentItem.dependentId !== dependentItem.clientId) {
          dependentCareExpenseList.push(dependentItem);
        }
        let conditionals = _.cloneDeep(conditionalQuestionTemplate);
        conditionals.valueToMatch = member.optionValue;
        conditionals.conditionalQuestions[0].label =
          houseHoldMultilanguageLabels(
            member[OptionsProps.OPTION_DISPLAY],
            language
          ).conditionalLabel;
        conditionals.conditionalQuestions[0].name =
          "recipient-of-care-" + [index];

        conditionals.conditionalQuestions[0].options = [];
        conditionals.conditionalQuestions[0].Answer = [];
        conditionals.conditionalQuestions[0].targetValue = [];
        conditionals.conditionalQuestions[0].Answer.push(
          dependentItem.dependentId
        );
        conditionals.conditionalQuestions[0].targetValue.push(
          dependentItem.dependentId
        );
        childAndAdultCare[0].options.forEach(function (hhMember, memIndex) {
          if (
            hhMember.optionValue !==
            serverJson.application.applicant.personId.toString()
          ) {
            conditionals.conditionalQuestions[0].options.push(hhMember);
          }
        }, this);
        childAndAdultCare[0].conditionals.push(conditionals);
      }
    }, this);
  }
  if (!serverJson.application.applicant.dependentCareExpense.length) {
    let careConditional = _.cloneDeep(
      expenseKidsAndAdultsTemplate.questions[0].conditionals[0]
    );

    childAndAdultCare[0].options.forEach(function (member, index) {
      if (
        member.optionValue ===
        serverJson.application.applicant.personId.toString()
      ) {
        let conditionals = _.cloneDeep(conditionalQuestionTemplate);
        conditionals.valueToMatch = member.optionValue;
        conditionals.conditionalQuestions[0].label =
          houseHoldMultilanguageLabels(
            member[OptionsProps.OPTION_DISPLAY],
            language
          ).conditionalLabel;
        conditionals.conditionalQuestions[0].name =
          "recipient-of-care-" + [index];

        conditionals.conditionalQuestions[0].options = [];

        childAndAdultCare[0].options.forEach(function (hhMember, memIndex) {
          if (
            hhMember.optionValue !==
            serverJson.application.applicant.personId.toString()
          ) {
            conditionals.conditionalQuestions[0].options.push(hhMember);
          }
        }, this);
        childAndAdultCare[0].conditionals.push(conditionals);
      }
    }, this);
  }
  for (let householdItem of serverJson.application.applicant.household) {
    if (householdItem.dependentCareExpense.length > 0) {
      childAndAdultCare[0].Answer.push(householdItem.personId);
      childAndAdultCare[0].targetValue.push(householdItem.personId);
      for (let dependentItem of householdItem.dependentCareExpense) {
        // dependentCareExpenseList.push(dependentItem);
        if (householdItem.personId === dependentItem.clientId) {
          if (dependentItem.dependentId !== dependentItem.clientId) {
            dependentCareExpenseList.push(dependentItem);
          }
          childAndAdultCare[0].options.forEach(function (member, index) {
            if (member.optionValue === householdItem.personId.toString()) {
              let conditionals = _.cloneDeep(conditionalQuestionTemplate);
              conditionals.valueToMatch = member.optionValue;
              conditionals.conditionalQuestions[0].label =
                houseHoldMultilanguageLabels(
                  member[OptionsProps.OPTION_DISPLAY],
                  language
                ).conditionalLabel;
              conditionals.conditionalQuestions[0].name =
                "recipient-of-care-" + [index];

              conditionals.conditionalQuestions[0].options = [];
              conditionals.conditionalQuestions[0].Answer = [];
              conditionals.conditionalQuestions[0].targetValue = [];
              conditionals.conditionalQuestions[0].Answer.push(
                dependentItem.dependentId
              );
              conditionals.conditionalQuestions[0].targetValue.push(
                dependentItem.dependentId
              );
              childAndAdultCare[0].options.forEach(function (
                hhMember,
                memIndex
              ) {
                if (
                  hhMember.optionValue !== householdItem.personId.toString()
                ) {
                  conditionals.conditionalQuestions[0].options.push(hhMember);
                }
              },
              this);
              childAndAdultCare[0].conditionals.push(conditionals);
            }
          }, this);
        }
      }
    } else {
      // for (let supportItem of serverJson.application.applicant.supportExpenses) {
      //   if (householdItem.clientId === supportItem.clientId) {
      let granteeFullName =
        householdItem.firstName +
        " " +
        householdItem.lastName +
        " " +
        _convertDateToState(householdItem.birthDate);
      // childAndAdultCare[0].Answer.push(householdItem.clientId);
      // childAndAdultCare[0].targetValue.push(householdItem.clientId);

      let careConditional = _.cloneDeep(
        expenseKidsAndAdultsTemplate.questions[0].conditionals[0]
      );

      childAndAdultCare[0].options.forEach(function (member, index) {
        if (member.optionValue === householdItem.personId.toString()) {
          // careConditional.conditionalQuestions[0].Answer = { "amount": supportItem.amount, "unit": supportItem.frequencyCode };

          let conditionals = _.cloneDeep(conditionalQuestionTemplate);
          conditionals.valueToMatch = member.optionValue;
          conditionals.conditionalQuestions[0].label =
            houseHoldMultilanguageLabels(
              member[OptionsProps.OPTION_DISPLAY],
              language
            ).conditionalLabel;
          conditionals.conditionalQuestions[0].name =
            "recipient-of-care-" + [index];

          conditionals.conditionalQuestions[0].options = [];

          childAndAdultCare[0].options.forEach(function (hhMember, memIndex) {
            if (hhMember.optionValue !== householdItem.personId.toString()) {
              conditionals.conditionalQuestions[0].options.push(hhMember);
            }
          }, this);
          childAndAdultCare[0].conditionals.push(conditionals);
        }
      }, this);
      // }
      // }
    }
  }
  // }
  return childAndAdultCare;
}
function camelCase(string) {
  return string
    ? string[0].toUpperCase() + string.slice(1).toLowerCase()
    : string;
}
export function renderDependentsCareCosts(
  dependentCosts,
  childAndAdultCare,
  serverJson
) {
  // let dependentCosts = this.props.apply.sections[4];
  let parentIds = dependentCosts.subsections[1].questions[0].Answer;
  let parentOptions = dependentCosts.subsections[1].questions[0].options;
  //Filtering Answers using IDs
  let parentMembers = [];
  let dependentMembers = [];

  for (
    let parentIdIndex = 0;
    parentIdIndex < parentIds.length;
    parentIdIndex++
  ) {
    for (
      let optionIndex = 0;
      optionIndex < parentOptions.length;
      optionIndex++
    ) {
      if (parentIds[parentIdIndex] === parentOptions[optionIndex].optionValue) {
        parentMembers.push(parentOptions[optionIndex].optionDisplay);
      }
    }
  }
  let parentNameMembers = {};
  let parentConditionals =
    dependentCosts.subsections[1].questions[0].conditionals;
  for (var p = 0; p < parentIds.length; p++) {
    parentNameMembers[parentIds[p]] = [];
    let conditionalDependentsCount = [];
    for (var v = 0; v < parentConditionals.length; v++) {
      if (parentConditionals[v].valueToMatch === parentIds[p]) {
        let selectAnswer = parentConditionals[v].conditionalQuestions[0].Answer; // selected members
        let selectOptions =
          parentConditionals[v].conditionalQuestions[0].options; // optional members for parents

        for (let dep = 0; dep < selectOptions.length; dep++) {
          let dependents = {};
          if (selectAnswer !== undefined) {
            for (let d = 0; d < selectAnswer.length; d++) {
              if (selectAnswer[d] === selectOptions[dep].optionValue) {
                dependents[OptionsProps.OPTION_DISPLAY] =
                  selectOptions[dep].optionDisplay;
                dependents[OptionsProps.OPTION_VALUE] =
                  selectOptions[dep].optionValue;
                // adding to the dependents data and pushing to the dependents object
                conditionalDependentsCount.push(dependents);
              }
            }
          }
        }
        // looping the dependents based on parent selected dependents
        for (var d = 0; d < conditionalDependentsCount.length; d++) {
          parentNameMembers[parentIds[p]].push(
            conditionalDependentsCount[d].optionDisplay
          );
          dependentMembers.push(
            parentMembers[p] + "-" + conditionalDependentsCount[d].optionDisplay
          );
        }
      }
    }
  }
  dependentCosts.dependentCare = dependentMembers;
  dependentCosts.memberOptions = parentMembers;

  const existingQuestions =
    dependentCosts.subsections[1].subsections &&
    dependentCosts.subsections[1].subsections[0];
  let previousDependentCosts = dependentCosts;
  //Swap full section for first time and it should not be manipulated!
  // if (this.dependentsCareCostsSubSections[0] === undefined) {
  //   this.dependentsCareCostsSubSections[0] = _.cloneDeep(dependentCosts.subsections[1]);
  // } else {
  dependentCosts.subsections[1] = _.cloneDeep(dependentCosts.subsections[1]);
  if (
    previousDependentCosts &&
    previousDependentCosts.subsections[1].questions[0].targetValue ==
      dependentCosts.subsections[1].questions[0].targetValue &&
    existingQuestions
  ) {
    existingQuestions.questions.forEach((item, index) => {
      const newQuestion =
        dependentCosts.subsections[1].subsections[0].questions[index];
      const oldQuestion = existingQuestions.questions[index];
      newQuestion.targetValue = oldQuestion.targetValue;
      newQuestion.Answer = oldQuestion.Answer;
      if (oldQuestion.conditionals) {
        newQuestion.conditionals = oldQuestion.conditionals;
      }
    });
  }
  // }

  // this.mapDependentCostSection(dependentCosts);

  //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
  // for (let dependentItem of dependentCareExpenseList) {
  //   for (let memIndex = 0; memIndex < dependentMembers.length; memIndex++) {
  for (
    let memIndex = 0;
    memIndex < dependentCareExpenseList.length;
    memIndex++
  ) {
    for (let parIndex = 0; parIndex < parentOptions.length; parIndex++) {
      for (let depIndex = 0; depIndex < dependentMembers.length; depIndex++) {
        if (
          dependentMembers[depIndex].split("-")[1] ===
            parentOptions[parIndex].optionDisplay &&
          parentOptions[parIndex].optionValue ===
            dependentCareExpenseList[memIndex].dependentId
        ) {
          dependentCosts.subsections[1].subsections[memIndex] = _.cloneDeep(
            expenseKidsAndAdultsTemplate.subsections[0]
          );
          dependentCosts.subsections[1].subsections[memIndex].id =
            "kids-and-adults-" + [memIndex];
          dependentCosts.subsections[1].subsections[memIndex].title =
            parentMembers[0];
          dependentCosts.subsections[1].subsections[memIndex].header =
            childDependnMultilanguageLabels(
              dependentMembers[memIndex].split("-")[1],
              "",
              language
            ).depedentCostHeader;
          dependentCosts.subsections[1].subsections[memIndex].dependentCare =
            dependentMembers[memIndex].split("-")[1];
          dependentCosts.subsections[1].subsections[memIndex].clientId =
            dependentCareExpenseList[memIndex].clientId;
          dependentCosts.subsections[1].subsections[memIndex].memberOptions =
            parentMembers;
          // set lables in conditionals questions.
          for (
            var q = 0;
            q <
            dependentCosts.subsections[1].subsections[memIndex].questions
              .length;
            q++
          ) {
            if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "units" &&
              q === 0
            ) {
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).dependentCostsQoneLabel;
              if (
                dependentCareExpenseList[memIndex].providerCareAmount !== ""
              ) {
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].Answer = {
                  amount: dependentCareExpenseList[memIndex].providerCareAmount,
                  unit: camelCase(
                    dependentCareExpenseList[memIndex].providerCareFrequencyCode
                  ),
                };
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].targetValue = {
                  amount: dependentCareExpenseList[memIndex].providerCareAmount,
                  unit: camelCase(
                    dependentCareExpenseList[memIndex].providerCareFrequencyCode
                  ),
                };
              }
            } else if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "radio" &&
              q === 1
            ) {
              /** Translation start */
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).dependentCostsQtwoLabel;
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCoditionalQLabel;
              /** Translation end */
              if (
                dependentCareExpenseList[memIndex].transportationAmount !== ""
              ) {
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].Answer = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].targetValue = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].valueToMatch = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer = {
                  amount:
                    dependentCareExpenseList[memIndex].transportationAmount,
                  unit: camelCase(
                    dependentCareExpenseList[memIndex]
                      .transportationFrequencyCode
                  ),
                };
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].targetValue = {
                  amount:
                    dependentCareExpenseList[memIndex].transportationAmount,
                  unit: camelCase(
                    dependentCareExpenseList[memIndex]
                      .transportationFrequencyCode
                  ),
                };
              }
            } else if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "radio" &&
              q === 2
            ) {
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).driveToQLabel;
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependConQuestion;
              // dependentCosts.subsections[1].subsections[memIndex].subsections[0].header = childDependnMultilanguageLabels(dependentMembers[memIndex].split("-")[0], "", language).chAdultCareAddrHeader;
              // dependentCosts.subsections[1].subsections[memIndex].subsections[0].title = dependentMembers[memIndex].split("-")[1];
              // dependentCosts.subsections[1].subsections[memIndex].subsections[0].questions[1].label = childDependnMultilanguageLabels(dependentMembers[memIndex].split("-")[0], dependentMembers[memIndex].split("-")[1], language).subSectionQuestionLabel;
              if (dependentCareExpenseList[memIndex].transportExpenses.length) {
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].Answer = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].targetValue = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].valueToMatch = TextProps.VALUE_YES;
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer =
                  dependentCareExpenseList[
                    memIndex
                  ].transportExpenses.length.toString();
                dependentCosts.subsections[1].subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].targetValue =
                  dependentCareExpenseList[
                    memIndex
                  ].transportExpenses.length.toString();
                for (
                  var bindAddressIndex = 0;
                  bindAddressIndex <
                  dependentCareExpenseList[memIndex].transportExpenses.length;
                  bindAddressIndex++
                ) {
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex] = _.cloneDeep(
                    expenseKidsAndAdultsTemplate.subsections[0].subsections[0]
                  );
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].header =
                    childDependnMultilanguageLabels(
                      dependentMembers[memIndex].split("-")[0],
                      "",
                      language
                    ).chAdultCareAddrHeader;
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].title =
                    dependentMembers[memIndex].split("-")[1];
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[1].label =
                    incomeSectionMultilanguageLabels(language).addressProvider;
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].id =
                    "kids-adult-multiple-address-" +
                    memIndex +
                    "-" +
                    bindAddressIndex;
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].header =
                    childDependnMultilanguageLabels(
                      dependentMembers[memIndex].split("-")[0],
                      "",
                      language
                    ).chAdultCareAddrHeader +
                    " " +
                    (bindAddressIndex + 1);
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].title =
                    dependentMembers[memIndex].split("-")[1];
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[1].label =
                    incomeSectionMultilanguageLabels(language).addressProvider;
                  let Answer = {
                    street:
                      dependentCareExpenseList[memIndex].transportExpenses[
                        bindAddressIndex
                      ].address.street,
                    city: dependentCareExpenseList[memIndex].transportExpenses[
                      bindAddressIndex
                    ].address.city,
                    state:
                      dependentCareExpenseList[memIndex].transportExpenses[
                        bindAddressIndex
                      ].address.state,
                    zipCode:
                      dependentCareExpenseList[memIndex].transportExpenses[
                        bindAddressIndex
                      ].address.postal,
                  };
                  console.log(
                    dependentCosts.subsections[1].subsections[memIndex]
                      .subsections[bindAddressIndex]
                  );
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[0].Answer = {};
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[0].targetValue = {};
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[0].Answer = Answer;
                  dependentCosts.subsections[1].subsections[
                    memIndex
                  ].subsections[bindAddressIndex].questions[0].targetValue =
                    Answer;
                  if (
                    dependentCareExpenseList[memIndex].transportExpenses[
                      bindAddressIndex
                    ].driveTripCount
                  ) {
                    dependentCosts.subsections[1].subsections[
                      memIndex
                    ].subsections[bindAddressIndex].questions[1].Answer = {};
                    dependentCosts.subsections[1].subsections[
                      memIndex
                    ].subsections[bindAddressIndex].questions[1].targetValue =
                      {};
                    dependentCosts.subsections[1].subsections[
                      memIndex
                    ].subsections[bindAddressIndex].questions[1].Answer = {
                      amount:
                        dependentCareExpenseList[memIndex].transportExpenses[
                          bindAddressIndex
                        ].driveTripCount,
                      unit: camelCase(
                        dependentCareExpenseList[memIndex].transportExpenses[
                          bindAddressIndex
                        ].driveFrequencyCode
                      ),
                    };
                    dependentCosts.subsections[1].subsections[
                      memIndex
                    ].subsections[bindAddressIndex].questions[1].targetValue = {
                      amount:
                        dependentCareExpenseList[memIndex].transportExpenses[
                          bindAddressIndex
                        ].driveTripCount,
                      unit: camelCase(
                        dependentCareExpenseList[memIndex].transportExpenses[
                          bindAddressIndex
                        ].driveFrequencyCode
                      ),
                    };
                  }
                }
              } else {
                delete dependentCosts.subsections[1].subsections[memIndex]
                  .subsections;
              }
            }
          }
        }
      }
    }
    // }
  }
  return dependentCosts.subsections;
}
export function childSupportCostSectionBinding(
  dependents,
  childSupportCost,
  serverJson
) {
  childSupportCost[0].Answer = [];
  childSupportCost[0].targetValue = [];
  //Child Support Cost for Grantee
  for (let supportItem of serverJson.application.applicant.supportExpenses) {
    let granteeFullName =
      serverJson.application.applicant.firstName +
      " " +
      serverJson.application.applicant.lastName +
      " " +
      _convertDateToState(serverJson.application.applicant.birthDate);
    childSupportCost[0].Answer.push(serverJson.application.applicant.personId);
    childSupportCost[0].targetValue.push(
      serverJson.application.applicant.personId
    );
    for (let conditional of childSupportCost[0].conditionals) {
      if (
        conditional.valueToMatch === serverJson.application.applicant.personId
      ) {
        conditional.conditionalQuestions[0].Answer = {
          amount: supportItem.amount,
          unit: camelCase(supportItem.frequencyCode),
        };
        conditional.conditionalQuestions[0].targetValue = {
          amount: supportItem.amount,
          unit: camelCase(supportItem.frequencyCode),
        };
      }
    }
  }
  //Child Support Cost for HH Members
  for (let householdItem of serverJson.application.applicant.household) {
    if (householdItem.supportExpenses.length > 0) {
      for (let supportItem of householdItem.supportExpenses) {
        // if (householdItem.personId === supportItem.clientId) {
        childSupportCost[0].Answer.push(householdItem.personId);
        childSupportCost[0].targetValue.push(householdItem.personId);
        for (let conditional of childSupportCost[0].conditionals) {
          if (conditional.valueToMatch === householdItem.personId) {
            conditional.conditionalQuestions[0].Answer = {
              amount: supportItem.amount,
              unit: camelCase(supportItem.frequencyCode),
            };
            conditional.conditionalQuestions[0].targetValue = {
              amount: supportItem.amount,
              unit: camelCase(supportItem.frequencyCode),
            };
          }
        }
        // }
      }
    }
  }
  return childSupportCost;
}

export function housingAndUtilitySubsectionBinding(
  expenseSection,
  shelterAnswer,
  serverJson
) {
  let costItemInputed = [];
  for (let costItem of expenseSection.subsections[0].subsections[0].questions) {
    if (shelterAnswer.indexOf(costItem.valueToMatch !== -1)) {
      for (let shellterItem of serverJson.application.shelterExpenses) {
        if (shellterItem.householdExpenseTypeCode === costItem.valueToMatch) {
          bindBothTargetAnswer(costItem, [
            { amount: shellterItem.amount, unit: shellterItem.frequencyCode },
          ]);
          costItemInputed.push(costItem);
        }
      }
    }
  }
  expenseSection.subsections[0].subsections[0].questions = costItemInputed;
}

export function childMedicalCostSectionBinding(
  sections,
  medicalSectionQuestion,
  serverJson
) {
  //sections[4].subsections[3].questions
  medicalSectionQuestion[0].Answer = [];
  medicalSectionQuestion[0].targetValue = [];
  let enabledMembersList = [];
  if (sections[4].subsections[3].subsections) {
    sections[4].subsections[3].subsections[0].Answer = [];
  }
  let count = 0;
  let applicant = serverJson.application.applicant;
  let date = new Date(applicant.birthDate);
  let age = _calculateAge(date);
  let hasDisability =
    applicant.hasDisability === TextProps.VALUE_Y
      ? TextProps.VALUE_YES
      : applicant.hasDisability === TextProps.VALUE_N
      ? TextProps.VALUE_NO
      : "";
  if (age >= 60 || hasDisability === TextProps.VALUE_YES) {
    count++;
    for (let granteeMedicalItem of serverJson.application.applicant
      .medicalExpenses) {
      if (
        !medicalSectionQuestion[0].Answer.includes(
          serverJson.application.applicant.personId
        )
      ) {
        medicalSectionQuestion[0].Answer.push(
          serverJson.application.applicant.personId
        );
        medicalSectionQuestion[0].targetValue.push(
          serverJson.application.applicant.personId
        );
        enabledMembersList.push(
          sections[4].subsections[3].questions[0].options.filter(
            (option) =>
              option.optionValue === serverJson.application.applicant.personId
          )[0]
        );
      }
      let medicalExists = false;
      let medicalExpAnswer = {};
      medicalExpAnswer.id = serverJson.application.applicant.personId;
      medicalExpAnswer.drive = "Yes";
      medicalExpAnswer.count =
        serverJson.application.applicant.medicalExpenses.length;
      if (
        granteeMedicalItem.address.street ||
        granteeMedicalItem.address.city ||
        granteeMedicalItem.address.state ||
        granteeMedicalItem.address.postal
      ) {
        medicalExists = true;
        medicalExpAnswer.address = {
          street: granteeMedicalItem.address.street,
          city: granteeMedicalItem.address.city,
          state: granteeMedicalItem.address.state,
          zipCode: granteeMedicalItem.address.postal,
        };
      }
      if (
        granteeMedicalItem.driveTripCount ||
        granteeMedicalItem.transportationAmount
      ) {
        medicalExists = true;
        medicalExpAnswer.cost = {
          amount: granteeMedicalItem.driveTripCount,
          unit: granteeMedicalItem.driveFrequencyCode
            ? camelCase(granteeMedicalItem.driveFrequencyCode)
            : "Month",
        };
        medicalExpAnswer.frequency = {
          amount: granteeMedicalItem.transportationAmount,
          unit: granteeMedicalItem.transportationFrequencyCode
            ? camelCase(granteeMedicalItem.transportationFrequencyCode)
            : "Month",
        };
      }
      // ADDRESS SHOULD BE A ARRAY. iT CAN BE EITHER "address" OR "addresses"
      if (medicalExists) {
        sections[4].subsections[3].subsections[0].Answer.push(medicalExpAnswer);
        childSupportList.push(medicalExpAnswer);
      }
    }
  }

  for (let dependentItem of serverJson.application.applicant.household) {
    let applicant = dependentItem;
    let date = new Date(applicant.birthDate);
    let age = _calculateAge(date);
    let hasDisability =
      applicant.hasDisability === TextProps.VALUE_Y
        ? TextProps.VALUE_YES
        : applicant.hasDisability === TextProps.VALUE_N
        ? TextProps.VALUE_NO
        : "";
    if (age >= 60 || hasDisability === TextProps.VALUE_YES) {
      count++;
      for (let dependentMedicalItem of dependentItem.medicalExpenses) {
        if (
          !medicalSectionQuestion[0].Answer.includes(dependentItem.personId)
        ) {
          medicalSectionQuestion[0].Answer.push(dependentItem.personId);
          medicalSectionQuestion[0].targetValue.push(dependentItem.personId);
          enabledMembersList.push(
            sections[4].subsections[3].questions[0].options.filter(
              (option) => option.optionValue === dependentItem.personId
            )[0]
          );
        }
        let medicalExists = false;
        let medicalExpAnswer = {};
        medicalExpAnswer.id = dependentItem.personId;
        medicalExpAnswer.drive = "Yes";
        medicalExpAnswer.count = dependentItem.medicalExpenses.length;
        if (
          dependentMedicalItem.address.street ||
          dependentMedicalItem.address.city ||
          dependentMedicalItem.address.state ||
          dependentMedicalItem.address.postal
        ) {
          medicalExists = true;
          medicalExpAnswer.address = {
            street: dependentMedicalItem.address.street,
            city: dependentMedicalItem.address.city,
            state: dependentMedicalItem.address.state,
            zipCode: dependentMedicalItem.address.postal,
          };
        }
        if (
          dependentMedicalItem.driveTripCount ||
          dependentMedicalItem.transportationAmount
        ) {
          medicalExists = true;
          medicalExpAnswer.cost = {
            amount: dependentMedicalItem.driveTripCount,
            unit: dependentMedicalItem.driveFrequencyCode
              ? camelCase(dependentMedicalItem.driveFrequencyCode)
              : "Month",
          };
          medicalExpAnswer.frequency = {
            amount: dependentMedicalItem.transportationAmount,
            unit: dependentMedicalItem.transportationFrequencyCode
              ? camelCase(dependentMedicalItem.transportationFrequencyCode)
              : "Month",
          };
        }
        // ADDRESS SHOULD BE A ARRAY. iT CAN BE EITHER "address" OR "addresses"
        if (medicalExists) {
          sections[4].subsections[3].subsections[0].Answer.push(
            medicalExpAnswer
          );
          childSupportList.push(medicalExpAnswer);
        }
      }
    }
  }
  if (count === 0) {
    sections[4].subsections[3].active = TextProps.VALUE_FALSE;
    sections[4].subsections[3].hiddenFromNav = TextProps.VALUE_TRUE;
  } else {
    sections[4].subsections[3].active = TextProps.VALUE_TRUE;
    sections[4].subsections[3].hiddenFromNav = TextProps.VALUE_FALSE;
    sections[4].subsections[3].questions[0].options = enabledMembersList;
  }
  return medicalSectionQuestion;
}
export function childMedicalSubSectionCostSectionBinding(
  sections,
  medicalSectionQuestion,
  serverJson
) {
  let expenseSection = sections[4];
  expenseSection.subsections[3].subsections = [];
  let membersList = medicalSectionQuestion[0].Answer;
  let memberOptions = sections[4].subsections[1].questions[0].options;
  // let medicalSubsections = _.cloneDeep(expenseMedicalExpenseTemplate.subsections[0]);
  for (let member = 0; member < memberOptions.length; member++) {
    if (membersList.length > 0 && membersList[0] !== "") {
      for (let memIndex = 0; memIndex < membersList.length; memIndex++) {
        if (memberOptions[member].optionValue === membersList[memIndex]) {
          let currentMember = memberOptions[member].optionDisplay;
          expenseSection.subsections[3].subsections[memIndex] = _.cloneDeep(
            expenseMedicalExpenseTemplate.subsections[0]
          );
          expenseSection.subsections[3].subsections[memIndex].id =
            "medical-expenses-" + [memIndex];
          expenseSection.subsections[3].subsections[memIndex].header =
            medicalExpenseMultilanguageLabels(currentMember, language).heading;
          expenseSection.subsections[3].subsections[
            memIndex
          ].questions[0].label = medicalExpenseMultilanguageLabels(
            currentMember,
            language
          ).question;
          expenseSection.subsections[3].subsections[
            memIndex
          ].questions[0].conditionals[0].conditionalQuestions[0].label =
            medicalExpenseMultilanguageLabels(
              currentMember,
              language
            ).drivetoLabel;
          // if (resumeApply) {
          let currentChildSupport = childSupportList.filter(
            (support) => support.id === membersList[memIndex]
          );
          if (currentChildSupport.length) {
            expenseSection.subsections[3].subsections[
              memIndex
            ].questions[0].Answer = currentChildSupport[0].drive;
            expenseSection.subsections[3].subsections[
              memIndex
            ].questions[0].targetValue = currentChildSupport[0].drive;
            expenseSection.subsections[3].subsections[
              memIndex
            ].questions[0].conditionals[0].conditionalQuestions[0].Answer =
              currentChildSupport[0].count;
            expenseSection.subsections[3].subsections[
              memIndex
            ].questions[0].conditionals[0].conditionalQuestions[0].targetValue =
              currentChildSupport[0].count;
          } else {
            expenseSection.subsections[3].subsections[
              memIndex
            ].subsections[0].id = "medical-address-" + memIndex + "-" + [0];
            expenseSection.subsections[3].subsections[
              memIndex
            ].subsections[0].title =
              currentMember +
              medicalExpenseMultilanguageLabels(memIndex + 1, language)
                .medConTitle;
            expenseSection.subsections[3].subsections[
              memIndex
            ].subsections[0].header =
              currentMember +
              medicalExpenseMultilanguageLabels(memIndex + 1, language)
                .medConHeader;
          }
          expenseSection.subsections[3].questions[0].options.forEach(function (
            member,
            id
          ) {
            currentChildSupport.forEach(function (selectedMember, id) {
              if (selectedMember.id === member.optionValue) {
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ] = _.cloneDeep(
                  expenseMedicalExpenseTemplate.subsections[0].subsections[0]
                );
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].id = "medical-address-" + memIndex + "-" + id;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].title =
                  currentMember +
                  medicalExpenseMultilanguageLabels(id + 1, language)
                    .medConTitle;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].header =
                  currentMember +
                  medicalExpenseMultilanguageLabels(id + 1, language)
                    .medConHeader;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[1].label = medicalExpenseMultilanguageLabels(
                  currentMember,
                  language
                ).medQoneLabel;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[2].label = medicalExpenseMultilanguageLabels(
                  currentMember,
                  language
                ).medQtwoLabel;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[0].Answer = selectedMember.address;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[1].Answer = selectedMember.cost;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[2].Answer = selectedMember.frequency;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[0].targetValue = selectedMember.address;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[1].targetValue = selectedMember.cost;
                expenseSection.subsections[3].subsections[memIndex].subsections[
                  id
                ].questions[2].targetValue = selectedMember.frequency;
              }
            }, this);
          });
          // }
        }
      }
    } else {
      delete expenseSection.subsections[3].subsections;
    }
  }
  return expenseSection.subsections[3].subsections;
}

export function mapExpenseOptions(sections, serverJson) {
  // Update Expense template with HouseHold Member details
  var memberObject = {};
  var memberListInfo = [];
  var expenseAboutMeFull =
    serverJson.application.applicant.firstName +
    " " +
    serverJson.application.applicant.lastName +
    " " +
    _convertDateToState(serverJson.application.applicant.birthDate);
  memberObject[OptionsProps.OPTION_VALUE] =
    serverJson.application.applicant.personId;
  memberObject[OptionsProps.OPTION_DISPLAY] = expenseAboutMeFull;
  memberObject.isGrantee = TextProps.VALUE_Y;
  memberListInfo.push(memberObject);
  var expenseCopy = sections[4];
  for (
    let memberIndex = 0;
    memberIndex < serverJson.application.applicant.household.length;
    memberIndex++
  ) {
    let member = serverJson.application.applicant.household[memberIndex];
    let childMemberObject = _.cloneDeep(memberObject);
    var expenseCombinedName =
      member.firstName +
      " " +
      member.lastName +
      " " +
      _convertDateToState(member.birthDate);
    childMemberObject[OptionsProps.OPTION_VALUE] = member.personId;
    childMemberObject[OptionsProps.OPTION_DISPLAY] = expenseCombinedName;
    childMemberObject.isGrantee = TextProps.VALUE_N;
    memberListInfo.push(childMemberObject);
  }
  expenseCopy.subsections[1].questions[0].options = memberListInfo;
  expenseCopy.subsections[2].questions[0].options = memberListInfo;
  expenseCopy.subsections[3].questions[0].options = memberListInfo;
  expenseCopy.subsections[2].questions[0].conditionals = [];
  for (let index = 0; index < memberListInfo.length; index++) {
    let conditionalQuestions = _.cloneDeep(
      expenseChildSupportTemplate.questions[0].conditionals[0]
    );
    conditionalQuestions.conditionalQuestions[0].Answer = [];
    conditionalQuestions.conditionalQuestions[0].name =
      "child-support-cost-" + index;
    conditionalQuestions.conditionalQuestions[0].label =
      childDependnMultilanguageLabels(
        memberListInfo[index].optionDisplay,
        "",
        language
      ).childSupptcondQLabel;
    conditionalQuestions.valueToMatch = memberListInfo[index].optionValue;
    expenseCopy.subsections[2].questions[0].conditionals.push(
      conditionalQuestions
    );
  }
  return sections;
}

//Answer and Target Object Created Here
function bindBothTargetAnswer(question, Answer) {
  question.Answer = Answer;
  question.targetValue = Answer;
  return question;
}

function _convertMonthNumberToString(monthNumber) {
  return isNone(MONTHSHORT[monthNumber])
    ? MONTHSHORT[0]
    : MONTHSHORT[monthNumber];
}
function _convertDateToState(date) {
  if (isNone(date)) {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return (
    " (" +
    date.getDate() +
    "." +
    _convertMonthNumberToString(date.getMonth()) +
    "." +
    date.getFullYear() +
    ")"
  );
}
function _calculateAge(dateOfBirth) {
  var ageDifMs = Date.now() - dateOfBirth.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
