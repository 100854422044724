import React, { Component } from "react";

import { interimReportPageLabels } from "../recertCovidText";
import { displayPDFData } from "../../../redux/recertCovid/recertCovidActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { getAudit } from "../../../utils/constants/audit";
import { push } from "react-router-redux";
import * as languageConstants from "../../../utils/constants/constants";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class SubmitSummary extends Component {
  constructor(props) {
    super(props);
    this.displayPDFData = this.displayPDFData.bind(this);
  }

  redirectToSelfDeclare = () => {
    window.location.assign(routeProps.DECLARE_EXPENSES_PATH);
  };
  render() {
    return (
      <div className="pad-all--double">
        <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).referenceNumberText
          }
          {this.props.referenceNumber}
        </p>
        <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).downloadBeforeText
          }{" "}
          <a onClick={this.displayPDFData}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).downloadLinkText
            }
          </a>{" "}
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).downloadAfterText
          }
        </p>
        <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).householdBoldMovedText
          }
        </b>
        {this.props.covidRecert.contactInfoScreen.contactInfoChanged ||
        this.props.covidRecert.householdMembers.membersListUpdated ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).householdMovedText
            }
            <a href={routeProps.DECLARE_EXPENSES_PATH}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).houseHoldMoveLinkText
              }
            </a>
          </p>
        ) : null}

        {this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.earnedIncomeChanged &&
        this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).earnedIncomeChangedText
            }
            <ul>
              {interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).earnedIncomeSteps.map((text) => {
                return <li>{text}</li>;
              })}
            </ul>
          </p>
        ) : null}

        {this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.unearnedIncomeChanged !== undefined &&
        this.props.covidRecert.unearnedIncomeChanged ===
          TextProps.VALUE_TRUE ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).unearnedIncomeChangedText
            }
            <ul>
              {interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).unearnedIncomeSteps.map((text) => {
                return <li>{text}</li>;
              })}
            </ul>
          </p>
        ) : null}
        {this.props.covidRecert.sections.length > 5 &&
        this.props.covidRecert.childSupportChanged !== undefined &&
        this.props.covidRecert.childSupportChanged === TextProps.VALUE_TRUE ? (
          <p>
            <p>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).uneanrednoteBulletDetailText
              }
            </p>
            <b>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).uneanrednoteText
              }
              :
            </b>{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).uneanrednoteDetailText
            }
          </p>
        ) : null}
        <br />
        <br />
        {this.props.covidRecert.sections.length > 4 ? (
          <div>
            <hr className="divider" />
            <p className="interim-recert-heading">
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesTitle
              }
            </p>
            <p>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesQuestion
              }
            </p>
            <button
              type="submit"
              className="dta-button dta-button--outline-secondary no-underline-link"
              onClick={this.redirectToSelfDeclare}
            >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesBtnLabel
              }
            </button>
            <br />
            <br />
          </div>
        ) : null}
        <hr className="divider" />
        <button
          className="dta-button dta-button--primary dta-button--large dta-button--block no-underline-link"
          onClick={() => {
            window.location.href = routeProps.CONSUMER_PATH;
          }}
        >
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).sendDocumentBtnLabel
          }
        </button>
        <p>
          <center>
            <a onClick={() => window.location.assign(routeProps.CONSUMER_PATH)}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).dontSendDocumentBtnLabel
              }
            </a>
          </center>
          <p>
            <span>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).otherDocWays
              }{" "}
            </span>
            <a href={languageConstants.SUBMIT_DOCS_DEPT_TRANS_ASS}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).findMoreText
              }
            </a>
          </p>
        </p>
      </div>
    );
  }

  displayPDFData() {
    var agencyId = this.props.agencyID;
    var auditData = getAudit();
    this.props.displayPDFData(
      this.props.docReferenceNumber,
      auditData,
      agencyId
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayPDFData: displayPDFData,
      goToDeclare: () => push(routeProps.DECLARE_EXPENSES_PATH),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSummary);
