import axios from "axios";
import { sections } from "../../pages/applyTafdc/applyTafdcMapData";
import { getAudit } from "../../utils/constants/audit";
var TigerURL = require("../../utils/constants/appConfigConstants").TigerURL;

const actionTypes = {
  applyTafdcPagesAction: "APPLY_TAFDC_PAGES_FULLFILLED",
  serviceFailedAction: "TAFDC_NETWORK_FAILED_LOAD_DATA",
  postApplyData: "POST_APPLY_TAFDC_INFO_DATA",
  fillInputValuesAction: "FILL_TAFDC_INPUT_VALUES_STATE",
  applySnapSectionData: "TAFDC_APPLY_SNAP_SECTIONS_FULLFILLED",
};

export function applyTafdcQuestionsSections() {
  return (dispatch) => {
    return dispatch(applyTafdcSections(sections));
  };
}

function applyTafdcSections(sectionsVal) {
  return {
    type: actionTypes.applyTafdcPagesAction,
    payload: sectionsVal,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

function questionsInputValuesAction(sectionsValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsValue,
  };
}

export function saveSnapApplyTAFDC(application, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = TigerURL + "/apipublic/security/tafdc/apply";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
        },
      };
      axios
        .post(url, application, options)
        .then(function (data) {
          dispatch(saveApplyData(data));
          resolve(data);
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}

function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}

export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}

export function updateDefault() {
  return (dispatch) => {
    return dispatch({
      type: "default",
    });
  };
}
export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_TAFDC_APPLY_ANSER",
      payload: data,
    });
  };
}
export function updateLastKnownLocation(section) {
  return (dispatch) => {
    return dispatch({
      type: "APPEND_TAFDC_LAST_KNOWN_LOCATION",
      payload: section,
    });
  };
}
