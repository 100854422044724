//Consumer Info Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import {ALL_STATES_COUNTY} from "../../utils/constants/statesCountyCode";
import { compareArrayOfObjects } from "../../utils/components/utilities/utils";
import {getDTANotificationList} from "../consumer/consumerDocumentsActions"
import { getDocumentsDetails } from "../../redux/consumer/consumerDocumentsActions";
import { medicalExpenseObj } from "../../pages/reportChange/reportChangeMapData";
import  { capitalizeFirstLetter } from "../../utils/components/utilities/utils";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerInfoRequested: "CONSUMER_INFO_REQUESTED",
  getConsumerInfoFulfilled: "CONSUMER_INFO_FULFILLED",
  showBenefitListInfo: "SHOW_BENEFIT_LIST_INFO",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  addAboutMeData: "ADD_ABOUT_ME_DATA",
  updateAboutMeData: "UPDATE_ABOUT_ME_DATA",
  getConsumerPhoneFulfilled: "CONSUMER_PHONE_FULFILLED",
  getConsumerEmailFulfilled: "CONSUMER_EMAIL_FULFILLED",
  getConsumerEmailRejected: "CONSUMER_EMAIL_REJECTED",
  getConsumerPhoneRejected: "CONSUMER_PHONE_REJECTED",
  storeClientInfo: "STORE_PROVIDER_CLIENT_DATA",
  consumerAboutMeData: "PROVIDER_CLIENT_ABOUT_ME_INFO",
  getPEBTClientSuccess: "P_EBT_SUCCESS",
  getPEBTClientFailed: "P_EBT_ERROR",
  consumerLoginSuccessAction: "CONSUMER_LOGIN_SUCCESS",
  updateConnecttoDtaAccount: "CONNECT_DTA_ACCOUNT_SUCCESS",
  unlinkAccountServiceSuccess: "UNLINK_ACCOUNT_SERVICE_SUCCESS",
  unlinkAccountStateClear: "UNLINK_ACCOUNT_STATE_CLEAR",
  connecttoDtaAccountError: "CONNECT_DTA_ACCOUNT_ERROR",
  isMailMyInfoActionSuccess: "IS_MAIL_MY_INFO_SUCCESS",
  isHouseholdMyInfoActionSuccess: "IS_HOUSEHOLD_INFO_SUCCESS",
  isPopupClosed: "IS_POP_UP_CLOSED",
  addressValidationPopUp : "ADDR_VALIDATION_POPUP",
  mailingAddressValidationPopUp :"MAILING_ADDR_VALIDATION_POPUP",
  unlinkAccount:"UNLINK_ACCOUNT",
  addrsWarning: "ADDRS_SHOW_WARNING_POPUP",
  setAddrFlag:"SET_ADDRESS_FLAG"
};

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage")

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

/**
 * Getting Client ID
 */
export function getConsumerInfo(email) {
  return (dispatch) => {
    var passingParams = "/consumer/account?email=" + email;
    var options = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };
    axios
      .get(BaseUrl + passingParams, {}, options)
      .then(function (response) {
        dispatch(getDocumentsDetails(response.data));
        return dispatch(saveClientInfo(response.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

// sending/storing to redux store
export function saveClientInfo(clientInfo) {
  return {
    type: actionTypes.getConsumerInfoFulfilled,
    payload: clientInfo,
  };
}

/**
 * Getting About Me Details
 **/
export function addConsumerAboutMeData(data) {
  return (dispatch) => {
    return dispatch(saveAboutMe(data));
  };
}

// sending/storing to redux store
function saveAboutMe(data) {
  return {
    type: actionTypes.addAboutMeData,
    payload: data,
  };
}

/**
 * here getting benefit details to showing in consumer myinfo pages
 */
export function showBenefitListInfo(clientId, userAuth) {
  return (dispatch) => {
    var passingParams = "/consumer/benefitListInfo";
    var benefitListInfo = {
      clientId: clientId,
      userAuth: userAuth,
    };
    var options = {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };
    sessionStorage.setItem(
      "consumerAccountData",
      JSON.stringify(benefitListInfo)
    );
    axios
      .post(BaseUrl + passingParams, benefitListInfo, options)
      .then(function (accountData) {
        sessionStorage.setItem(
          "benefitListResult",
          JSON.stringify(accountData.data)
        );
        return dispatch(showBenefitListInfoAction(accountData.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

// sending/storing to redux store
function showBenefitListInfoAction(benefitInfo) {
  return {
    type: actionTypes.showBenefitListInfo,
    payload: benefitInfo,
  };
}
// if service failure to getting data
export function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

export function updatePhoneNumber(answerObject) {
  return (dispatch, getState) => {
    var url = config.TigerContextURL + "/phone";
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(url, answerObject, TigerOptions)
      .then(checkStatus)
      .then(function (accountUpdateData) {
        if (accountUpdateData.status == 200) {
          const clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
          // clientDetails.clientPhone = answerObject.clientPhone;
          sessionStorage.setItem(
            "clientDetails",
            JSON.stringify(clientDetails)
          );
          dispatch(updatePhoneSuccessAction("s"));
        }
      })
      .catch(function (error) {
        dispatch(updatePhoneRejectedAction({ Result: "False" }));
      });
  };
}

export function updateClientEmail(answerObject) {
  return (dispatch, getState) => {
    var url = config.TigerContextURL + "/email";
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(url, answerObject, TigerOptions)
      .then(checkStatus)
      .then(function (accountUpdateData) {
        if (accountUpdateData.status == 200) {
          const clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
          // clientDetails.clientEmail = answerObject.clientEmail;
          sessionStorage.setItem(
            "clientDetails",
            JSON.stringify(clientDetails)
          );
          dispatch(updateEmailSuccessAction("s"));
        }
      })
      .catch(function (error) {
        dispatch(updateEmailRejectedAction({ Result: "False" }));
      });
  };
}
function findCountyCode(countyValue){
  let countyCode = ALL_STATES_COUNTY.filter(county => county.county === countyValue);
  if(countyCode.length){
    return countyCode[0].code; 
  }else{
    return ""
  } 
}

//Report a change api changes
export function updateClientAddress(answerObject, agencyId,prevAddressObject) {
  var addrData = [...answerObject];
  // if (addrData[0].resAddrMatchFlag) {
  //   // addrData.pop();
  // }
  var addrArray = [];
  addrData.forEach(function (addObj) {
    let address = {
      city: addObj.value.city,
      homelessFlag: addObj.homelessFlag,
      line1 :addObj.value.line1 ? addObj.value.line1 : addObj.value.addressLine1,
      line2: addObj.value.line2 ? addObj.value.line2 : addObj.value.addressLine2,
      countyCode:  addObj.value.county ? findCountyCode(addObj.value.county) : "",    
      county: addObj.value.county,  
      verified: addObj.value.verified ? addObj.value.verified : TextProps.VALUE_N, 
      resAddrMatchFlag: addObj.resAddrMatchFlag,
      state: addObj.value.state,
      typeCode: addObj.label === "Home Address" ? "RES" : "MAIL",
      zip: addObj.value.zip ? addObj.value.zip : addObj.value.zipCode,
    };
    addrArray.push(address);
  });

  return (dispatch) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const userEmail = sessionStorage.getItem("user_name");
    var url = config.TigerContextURL + "/consumer/"+userEmail;

    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    const existingAddressArray = prevAddressObject;
    const currentAddressArray = addrArray && addrArray.map(oneCurrentArray => {
      delete oneCurrentArray['verified'];
      delete oneCurrentArray['countyCode'];
      return oneCurrentArray;
    });

    const isAddressEqual = compareArrayOfObjects(existingAddressArray, currentAddressArray);
    let newPayloadArray;

    let existingAddressObj = JSON.parse(sessionStorage.getItem('loadConsumerUser')).clientAddress;
    let existingResAddress = existingAddressObj && existingAddressObj.length > 0 && existingAddressObj.filter(prevResObj => prevResObj.typeCode === "RES");
    let existingMailAddress = existingAddressObj && existingAddressObj.length > 0 && existingAddressObj.filter(prevMailObj => prevMailObj.typeCode === "MAIL");

    newPayloadArray = currentAddressArray && currentAddressArray.map(oneNewArr => {     
      const {
        city,
        line1,
        line2,
        zip,
        county,
        homelessFlag,
        resAddrMatchFlag
      } = oneNewArr;

      if (
        oneNewArr.typeCode === "RES" &&
        existingResAddress &&
        existingResAddress.length > 0 &&
        existingMailAddress != undefined
      ) {
        if (
          oneNewArr.typeCode === "RES" &&
          (city !== existingResAddress[0].city ||
            zip !== existingResAddress[0].zip ||
            line1 !== existingResAddress[0].line1 ||
            line2 !== existingResAddress[0].line2 ||
            county !== existingResAddress[0].county 
            )
        ) {
          oneNewArr["dataChangeCode"] = "CHANGE";
        } else {
          oneNewArr["dataChangeCode"] = "NOCHNG";
        }
      } else if (
        oneNewArr.typeCode === "MAIL" &&
        existingMailAddress != undefined &&
        existingMailAddress.length > 0
      ) {
        if (
          oneNewArr.typeCode === "MAIL" &&
          (city !== existingMailAddress[0].city ||
            zip !== existingMailAddress[0].zip ||
            line1 !== existingMailAddress[0].line1 ||
            line2 !== existingMailAddress[0].line2 ||
            county !== existingMailAddress[0].county ||
            resAddrMatchFlag !== existingMailAddress[0].resAddrMatchFlag)
        ) {
          if (
            resAddrMatchFlag !== existingMailAddress[0].resAddrMatchFlag &&
            resAddrMatchFlag
          ) {
            oneNewArr["city"] = null;
            oneNewArr["county"] = null;
            oneNewArr["homelessFlag"] = null;
            oneNewArr["line1"] = null;
            oneNewArr["line2"] = null;
            oneNewArr["state"] = null;
            oneNewArr["zip"] = null;
          }
          oneNewArr["dataChangeCode"] = "CHANGE";
        } else {
          oneNewArr["dataChangeCode"] = "NOCHNG";
        }
      }
      if (
        oneNewArr.typeCode === "RES" &&
        existingResAddress &&
        existingResAddress.length > 0 &&
        existingMailAddress != undefined
      ) {
        if (
          oneNewArr.typeCode === "RES" &&
          existingResAddress[0] &&
          homelessFlag !== existingResAddress[0].homelessFlag
        ) {
          oneNewArr["homelessFlagDataChangeCode"] = "CHANGE";
        } else {
          oneNewArr["homelessFlagDataChangeCode"] = "NOCHNG";
        }
      } else if (
        oneNewArr.typeCode === "MAIL" &&
        existingMailAddress != undefined &&
        existingMailAddress.length > 0
      ) {
        if (
          oneNewArr.typeCode === "MAIL" &&
          existingResAddress[0] &&
          homelessFlag !== existingResAddress[0].homelessFlag
        ) {
          oneNewArr["homelessFlagDataChangeCode"] = "CHANGE";
        } else {
          oneNewArr["homelessFlagDataChangeCode"] = "NOCHNG";
        }
      }
      return oneNewArr;
    });
    
    newPayloadArray && newPayloadArray.forEach(oneNewObj => {

      if(existingResAddress && existingResAddress.length > 0 && oneNewObj.typeCode === "RES"  ){
        if(oneNewObj.homelessFlagDataChangeCode === "CHANGE"){
          oneNewObj['previousHomelessFlag'] = existingResAddress[0].homelessFlag;
        }
        if(oneNewObj.dataChangeCode === "CHANGE"){
        setPreviousKeyValues(oneNewObj, existingResAddress);
        }        
      } else if((existingMailAddress && existingMailAddress.length > 0) && oneNewObj.typeCode === "MAIL" && oneNewObj.dataChangeCode === "CHANGE"){
        setPreviousKeyValues(oneNewObj, existingMailAddress);
      } else if(existingResAddress && 
        existingResAddress.length > 0 && 
        existingMailAddress.length < 1 && 
        oneNewObj.typeCode === "MAIL" && 
        currentAddressArray && currentAddressArray.length > 0 && currentAddressArray[0].resAddrMatchFlag !== existingResAddress[0].resAddrMatchFlag
      ){
        oneNewObj['dataChangeCode'] = "NEW";
        oneNewObj['resAddrMatchFlag'] =   currentAddressArray[0].resAddrMatchFlag;
        oneNewObj['homelessFlag'] = currentAddressArray[0].homelessFlag;
      } else if(existingMailAddress && existingMailAddress.length > 0 && existingResAddress.length < 1 && oneNewObj.typeCode === "RES") {
        oneNewObj['dataChangeCode'] = "NEW";
      }else if(existingMailAddress && 
        existingMailAddress.length < 1 && 
        oneNewObj.typeCode === "MAIL" && 
        currentAddressArray && currentAddressArray.length > 0 &&  currentAddressArray[0].resAddrMatchFlag === existingResAddress[0].resAddrMatchFlag
      ){
      oneNewObj['dataChangeCode'] = "";
      oneNewObj['resAddrMatchFlag'] =   currentAddressArray[0].resAddrMatchFlag;
      oneNewObj['homelessFlag'] =  currentAddressArray[0].homelessFlag;
      }
    });
    
    let oldPayload = JSON.stringify({clientAddress:prevAddressObject});
    let newPayload = JSON.stringify({clientAddress: isAddressEqual ? addrArray : newPayloadArray});
    let disableUpdate = false;
    let payload = {
      "prevTxt":oldPayload,
      "chngTxt":newPayload,
      "chngReqTypCdCodeK": "ADDRES",
      "chngReqStatCdCodeK": "REQSTD",
      "clientIdK": agencyId ? agencyId : null,
  }
    axios
      .post(url, payload, TigerOptions)
      .then(checkStatus)
      .then(function (accountUpdateData) {
        if (accountUpdateData.status === 200) {
          const clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
          disableUpdate = true;
          dispatch(isMailMyInfo(accountUpdateData,disableUpdate));
          sessionStorage.removeItem("isHousehold");
          sessionStorage.removeItem("isEditingStarted");
          sessionStorage.removeItem("mailMyInfo");
        }
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function updatePhoneSuccessAction(phoneInfo) {
  return {
    type: actionTypes.getConsumerPhoneFulfilled,
    payload: phoneInfo,
  };
}

function updateEmailSuccessAction(emailInfo) {
  return {
    type: actionTypes.getConsumerEmailFulfilled,
    payload: emailInfo,
  };
}

export function updatePhoneRejectedAction(error) {
  return {
    type: actionTypes.getConsumerPhoneRejected,
  };
}

export function updateEmailRejectedAction(error) {
  return {
    type: actionTypes.getConsumerEmailRejected,
  };
}

export function updateClientInfo(clientObj) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(updateConsumerInfo(clientObj));
  };
}

export function updateConsumerInfo(clientObj) {
  return {
    type: actionTypes.storeClientInfo,
    payload: clientObj,
  };
}

export function getConsumerAboutMeData(userId) {
  return (dispatch) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    if (userInfo && userInfo.roleList[0].roleName === roleProps.CONSUMER) {
      sessionStorage.setItem("isNewConsumer", TextProps.VALUE_FALSE);
      var url = config.TigerContextURL + "/consumer/" + userId;
      axios
        .get(url, TigerOptions)
        .then(function (response) {
          if (response.data.code && response.data.code == 118) {
            dispatch(serviceFailedAction());
          } else {
            var consumerOldDataUserObj = JSON.parse(
              sessionStorage.getItem("loadConsumerUser")
            );
            var oldAlerts = null;
            if (
              consumerOldDataUserObj !== null &&
              consumerOldDataUserObj !== undefined
            ) {
              oldAlerts = consumerOldDataUserObj.alerts;
            }
            var consumerNewDataUserObj = response.data;
            consumerNewDataUserObj.alerts = oldAlerts;

            sessionStorage.setItem(
              "loadConsumerUser",
              JSON.stringify(consumerNewDataUserObj)
            );
            let consumerDataUserObj = JSON.parse(
              sessionStorage.getItem("loadConsumerUser")
            );
            let clientDetails = {};
            clientDetails = {
              clientAddress: consumerDataUserObj.clientAddress,
              clientEmail: consumerDataUserObj.clientEmail,
              clientInfo: consumerDataUserObj.clientInfo,
              clientPhone: consumerDataUserObj.clientPhone,
            };
            sessionStorage.setItem(
              "clientDetails",
              JSON.stringify(clientDetails)
            );
            dispatch(getPEBTClient(response.data));
            dispatch(getDTANotificationList(consumerDataUserObj.clientInfo.agencyID));
            dispatch(getDocumentsDetails(response.data));
            return dispatch(consumerLoginUserObj(response.data));
          }
        })
        .catch(function (error) {
          dispatch(serviceFailedAction());
        });
    } else {
      const providerData = JSON.parse(sessionStorage.getItem("providerData"));
      if (providerData && providerData.clientID) {
        sessionStorage.setItem("isNewConsumer", TextProps.VALUE_FALSE);
        var urlConsumerbyid =
        config.TigerContextURL + "/consumerbyid/" + providerData.clientID;
      axios
        .get(urlConsumerbyid, TigerOptions)
          .then(function (response) {
            if (response.data.code && response.data.code == 118) {
              dispatch(serviceFailedAction());
            } else {
              sessionStorage.setItem(
                "loadConsumerUser",
                JSON.stringify(response.data)
              );
              sessionStorage.setItem("selectedConsumerEmail", response.data.regEmail);
              let consumerDataUserObj = JSON.parse(
                sessionStorage.getItem("loadConsumerUser")
              );
              let clientDetails = {};
              clientDetails = {
                clientAddress: consumerDataUserObj.clientAddress,
                clientEmail: consumerDataUserObj.clientEmail,
                clientInfo: consumerDataUserObj.clientInfo,
                clientPhone: consumerDataUserObj.clientPhone,
              };
              sessionStorage.setItem(
                "restrictedAccessProviderEBTCard",
                TextProps.VALUE_TRUE
              );
              sessionStorage.setItem(
                "clientDetails",
                JSON.stringify(clientDetails)
              );
              dispatch(getPEBTClient(response.data));
              dispatch(consumerLoginUserObj(response.data));
              return dispatch(clientAboutmeData(response.data));
            }
          })
          .catch(function (error) {
            dispatch(serviceFailedAction());
          });
      }
    }
  };
}

function clientAboutmeData(clientAboutMe) {
  return {
    type: actionTypes.consumerAboutMeData,
    payload: clientAboutMe,
  };
}

export function consumerLoginUserObj(data) {
  return {
    type: actionTypes.consumerLoginSuccessAction,
    payload: data,
  };
}

export function getPEBTClient(data) {
  const selectedLanguage = language.slice(0, 2).toUpperCase();
  if (data.clientInfo) {
    const url = config.TigerContextURL + `/banner/${selectedLanguage}/`;
    const agencyId = data.clientInfo.agencyID;
    return (dispatch) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .get(url + agencyId, TigerOptions)
        .then(function (response) {
          dispatch(getPEBTClientSuccessAction(response));
        })
        .catch(function (error) {
          dispatch(getPEBTClientFailedAction());
        });
    };
  }
}

// sending/storing to redux store
function getPEBTClientSuccessAction(data) {
  return {
    type: actionTypes.getPEBTClientSuccess,
    payload: data,
  };
}

function getPEBTClientFailedAction(data) {
  return {
    type: actionTypes.getPEBTClientFailed,
  };
}
//myinfo page update address action
export function isMailMyInfo(data,disableUpdate) {
  data = {...data,disableUpdate:disableUpdate}
  return (dispatch) => {
    dispatch(isMailMyInfoAction(data));
  };
}

export function isHouseholdUpdate(data) {
  return (dispatch) => {
    dispatch(isHouseholdMyInfoAction(data));
  };
}

// connecttodtaaccount api call

export function connecttoDtaAccount(answerObject) {
  return (dispatch, getState) => {
    var url = config.TigerContextURL + "/connecttodtaaccount";
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const Headers = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(url, answerObject, Headers)
      .then(checkStatus)
      .then(function (response) {
        dispatch(connecttoDtaAccountSuccess(response));
      })
      .catch(function (error) {
        dispatch(connecttoDtaAccountError(error));
      });
  };
}

export function unlinkAccount(payload){ 
  return (dispatch) => {     
  const audit = getAudit();
  const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
  const url = config.TigerContextURL + "/unlinkconsumer";  
  const options = {       
    method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        locale: language,
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },    
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,

};     
axios      
 .post(url,payload, options)      
 .then(function (data) {
  return dispatch(unlinkAccountServiceSuccess(data.data));
})
.catch(function (error) {
  dispatch(serviceFailedAction());
});
};
}


function unlinkAccountServiceSuccess(response) {
  return {
    type: actionTypes.unlinkAccountServiceSuccess,
    payload: response,
  };
}

export function unlinkAccountStateClear() {
  return {
    type: actionTypes.unlinkAccountStateClear,
    payload: null,
  };
}

function connecttoDtaAccountSuccess(response) {
  return {
    type: actionTypes.updateConnecttoDtaAccount,
    payload: response,
  };
}

function connecttoDtaAccountError(data) {
  return {
    type: actionTypes.connecttoDtaAccountError,
    payload: data,
  };
}
function isMailMyInfoAction(data) {
  return {
    type: actionTypes.isMailMyInfoActionSuccess,
    payload: data,
  };
}

function isHouseholdMyInfoAction(data) {
  return {
    type: actionTypes.isHouseholdMyInfoActionSuccess,
    payload: data,
  };
}

export function isPopupClosed(data) {
  return (dispatch) => {
    dispatch(isPopupCloseAction(data));
  };
}

function isPopupCloseAction(data) {
  return {
    type: actionTypes.isPopupClosed,
    payload: data,
  };
}

export function consumerInfoDetailsSuccess() {
  return {
    type: actionTypes.consumerInfoDetailsSuccess,
  };
}
export function addressValidationPopupAction(melissaPayload) {
  return {
    type: actionTypes.addressValidationPopUp,
    melissaPayload: melissaPayload
  };
}
export function mailingAddressValidationPopUpAction(melissaPayload) {
  return {
    type: actionTypes.mailingAddressValidationPopUp,
    melissaPayload: melissaPayload
  };
}
export function addrsWarningPopupAction(payload,module,action) {
  return {
    type: actionTypes.addrsWarning,
    payload:{status:payload,module,action}
  };
}

function setPreviousKeyValues(newObj, existingObj){
  for(let key in newObj) {
       if(key !== "dataChangeCode" && key !== "typeCode" && key !== "state"  && key !== "homelessFlag" && key !== "previousHomelessFlag"){
         const previousKey = `previous${capitalizeFirstLetter(key)}`;
         newObj[previousKey] = existingObj[0][key];
      }
     }
}

export function setAddressFlag(addressFlag) {
  return {
    type: actionTypes.setAddrFlag,
    payload: addressFlag,
  };
}