// Vendor / Components
import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { mapStateToProps } from "../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import PropTypes from "prop-types";

// DHR Components
import Icon from "../../../utils/components/dtaIcon";

import infoIcon from '../../../utils/resources/images/icons/notice.svg';
import ListWrapper from "../../../utils/components/list/listWrapper";
import RequestAccountAccessModal from "./requestAccountAccessModal";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import { accountSearchQuestion } from "../providerSearchFiltersMapData";
import {
  onConsumerSearch,
  saveRequestAccountAccessUserInfo,
} from "../../../redux/provider/providerSearchConsumerActions";
import Pagination from "../../../utils/components/pagination";
import { updateConsumerInfo } from "../../../redux/consumer/consumerMyInfoActions";
import { multilanguageSelect } from "../../../redux/provider/providerMyClientsActions";
import { date, fullName, lastFourDigitSSN } from "../../../utils/components/utilities/formatters";
// Constants
import * as SharedProps from "../../../utils/constants/shared";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as TextProps from "../../../utils/constants/text";
import SearchedClientContents from "../../../utils/components/list/itemContents/searchedClient";


// Static data
import * as FiltersData from "../providerSearchFiltersMapData";

import axios from "axios";
import { cond } from "lodash";

class ProviderSearchConsumer extends Component {
  constructor() {
    super();

    this.state = {
      searchValue: "",
      val: '',
      selectedQuestion: '',
      searchResults: [],
      numOfResults: 0,
      RAAModalOpen: TextProps.VALUE_FALSE,
      requestAccessForUser: "",
      filesToUpload: [],
      activePage: 1,
      startIndex: 0,
      endIndex: 4,
      displayPage: 20,
      user: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1988, 9, 27),
        [SharedProps.AGENCY_ID]: "2000001",
      },
      sorting: 0,
    };

    this._handleChangeForQuestion = this._handleChangeForQuestion.bind(this);
    this.searchAccount = this.searchAccount.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.accountAnswer = {};
    this.accountAnswer.answerType = {};

    enableUniqueIds(this);

  }

  applicationdateValue = {
    day: "",
    month: "",
    year: "",
    applicationdateValueFinal: "",
  }

  clientNamevalue = {
    firstName : "",
    lastName : ""
  }

  componentDidMount() {
    this.props.multilanguageSelect();
    /* axios.get("fakeJson.JSON").then((response) => {
      console.log(response.data);
    }) */
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      accountNotFoundError: TextProps.VALUE_FALSE,
      optionNotSelectedError: TextProps.VALUE_FALSE,
      webNumberNotEnterError: TextProps.VALUE_FALSE,
      dtaNumberNotEnterError: TextProps.VALUE_FALSE,
      tanfNumberNotEnterError: TextProps.VALUE_FALSE,
      caseNumberNotEnterError: TextProps.VALUE_FALSE,
      clientFirstNameNotEnterError: TextProps.VALUE_FALSE,
      clientFirstLastNameNotEnterError: TextProps.VALUE_FALSE,
      clientLastNameNotEnterError: TextProps.VALUE_FALSE,
      ssnNumberNotEnterError: TextProps.VALUE_FALSE,
      ebtNumberNotEnterError: TextProps.VALUE_FALSE,
      emailNotEnterError: TextProps.VALUE_FALSE,
    });
    if (
      nextProps !== undefined &&
      nextProps.provider.clientDetail !== undefined &&
      nextProps.provider.clientDetail !== null &&
      nextProps.provider.clientDetail.multiUserDetails !== undefined
    ) {
      let res = [];
      let result = nextProps.provider.clientDetail;
      result.type = "searchedClient";
      result.id = nextProps.provider.clientDetail.multiUserDetails[0].accountId;
      res.push(result);
      console.log("res is:", res);
      this.setState({
        searchValue: "",
        searchResults: res,
        numOfResults: res[0][SharedProps.MULTI_USER].length,
        RAAModalOpen: TextProps.VALUE_FALSE,
        requestAccessForUser: res[0][SharedProps.MULTI_USER][0],
        filesToUpload: [],
        activePage: 1,
        startIndex: 0,
        endIndex: 4,
      });
    } else if (
      nextProps !== undefined &&
      nextProps.provider.clientDetail !== undefined &&
      nextProps.provider.clientDetail !== null &&
      nextProps.provider.clientDetail.found === TextProps.VALUE_FALSE &&
      this.error === TextProps.VALUE_FALSE
    ) {
      this.setState({ accountNotFoundError: TextProps.VALUE_TRUE });
    } else if (
      nextProps !== undefined &&
      nextProps.provider.clientDetail !== undefined &&
      nextProps.provider.clientDetail !== null &&
      nextProps.provider.clientDetail.found === TextProps.VALUE_FALSE
    ) {
      this.setState({ accountNotFoundError: TextProps.VALUE_FALSE });
    } else if (nextProps.provider.clientDetailFailedMessage !== undefined) {
      this.setState({ accountNotFoundError: TextProps.VALUE_TRUE });
    } else if (nextProps.consumer.consumerData.user === undefined) {
      var onSearchConsumerObj = JSON.parse(
        sessionStorage.getItem("onSearchConsumer")
      );
      var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userObject !== null) {
        if (onSearchConsumerObj !== null && onSearchConsumerObj.user !== null) {
          this.setState({
            searchValue: "",
            searchResults: "",
            numOfResults: "",
            RAAModalOpen: TextProps.VALUE_FALSE,
            requestAccessForUser: onSearchConsumerObj,
            filesToUpload: [],
            activePage: 1,
            startIndex: 0,
            endIndex: 4,
          });
        } else {
          this.setState({
            searchValue: "",
            searchResults: "",
            numOfResults: "",
            RAAModalOpen: TextProps.VALUE_FALSE,
            requestAccessForUser: "",
            filesToUpload: [],
            // activePage: 1,
            // startIndex: 0,
            // endIndex: 20,
          });
        }
      } else {
        window.location.assign(routeProps.HOME_PATH);
      }
    }
  }

openRAAModal = (user) => {
    this.setState({
      requestAccessForUser: user,
      RAAModalOpen: TextProps.VALUE_TRUE,
      gotSuccess: TextProps.VALUE_FALSE,
    });
  };

  closeRAAModal = (data) => {
    this.setState({
      RAAModalOpen: TextProps.VALUE_FALSE,
      filesToUpload: [],
    });
    if (this.state.gotSuccess === TextProps.VALUE_TRUE) {
      this._handleChangeForQuestion(
        this.state.questionData,
        this.state.conditionObj,
        this.state.val
      );
      this.searchAccount();
    }
  };

  handleFileInputChange = (event) => {
    const inputEl = event.target,
      files = Array.from(inputEl.files);

    files.forEach((file) => {
      file[SharedProps.ID] = `upload-file-${this.fileIncrementer}`;
      this.fileIncrementer += 1;
    });

    this.setState(
      {
        filesToUpload: this.state.filesToUpload.concat(files),
      },
      () => {
        inputEl.value = null;
      }
    );
  };

  handleRemoveFile = (fileToRemove) => () => {
    const newFileList = this.state.filesToUpload.filter(
      (file) => file[SharedProps.ID] !== fileToRemove[SharedProps.ID]
    );

    this.setState({ filesToUpload: newFileList });
  };

  submitRAA = (
    ref,
    startDate,
    endDate,
    clientId,
    webAppId,
    webApplicationArray
  ) => {
    const { search } = this.props.language.provider;
    ref.showBlur("loading", "Submitting...");
    // Can now read files from this.state.filesToUpload and POST them to server
    var consumerDataUserObj = JSON.parse(sessionStorage.getItem("userInfo"));
    var requestAccountAccessObj = {};
    requestAccountAccessObj.webApplicationArray = webApplicationArray;
    requestAccountAccessObj.startDate = startDate;
    requestAccountAccessObj.endDate = endDate;
    if (
      this.props.consumer.consumerData.user !== null &&
      this.props.consumer.consumerData.user !== undefined
    ) {
      if (this.props.consumer.consumerData.user.userAuth !== undefined) {
        requestAccountAccessObj.userObj = this.props.consumer.consumerData.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      } else {
        requestAccountAccessObj.userObj = consumerDataUserObj.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      }
    } else if (this.props.consumer.centrifyData.user !== undefined) {
      requestAccountAccessObj.userObj = this.props.consumer.centrifyData.user;
      this.props.saveRequestAccountAccessUserInfo(
        webAppId,
        clientId,
        requestAccountAccessObj
      );
      setTimeout(() => {
        this.showMessage(ref, "success", search.submitSuccess);
      }, 3000);
    } else {
      if (consumerDataUserObj !== null) {
        requestAccountAccessObj.userObj = consumerDataUserObj.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      } else {
        window.location.assign(routeProps.HOME_PATH);
      }
    }
  };
  showMessage(ref, type, message) {
    if (type == "success") {
      this.setState({
        RAAError: TextProps.VALUE_FALSE,
        RAAErrorMessage: "",
        gotSuccess: TextProps.VALUE_TRUE,
      });
      ref.showBlur(type, message);
    } else if (type == "error") {
      ref.hideBlur();
      this.setState({
        RAAError: TextProps.VALUE_TRUE,
        RAAErrorMessage: message,
        gotSuccess: TextProps.VALUE_FALSE,
      });
    }
  }

  sortingClients = () => {
    const searchResults = this.state.searchResults.length && this.state.searchResults[0].multiUserDetails;
    var  oldState = {...this.state.searchResults};
    // if (this.state.sorting === 0 || this.state.sorting === 2) {
      const sortedList = searchResults.sort((a, b) => {
        if(a===b) {
          return 0;
        }
        return a.lastName < b.lastName ? -1 : 1;
        });
      // this.setState({ sorting: 1, clients: sortedList });
      if(oldState.length) {
        oldState[0].multiUserDetails = sortedList;
     }
     this.setState({ sorting: 1, searchResults: oldState });
    // } 
    // else if (this.state.sorting === 1) {
    //   const sortedList = searchResults.sort((a, b) =>
    //     b.lastName.localeCompare(a.lastName)
    //   );

    //   if(oldState.length) {
    //      oldState[0].multiUserDetails = sortedList;
    //   }
    //   this.setState({ sorting: 2, searchResults: oldState });
    // }
  };

  getErrorMessage = (validationMessage) => {
    return (
      <b>
        <p className="error-text-clr consumerWarningText"><img src={infoIcon} alt="reply" />{validationMessage}</p>
      </b>
    )
  };

  render() {
    const { search } = this.props.language.provider;
    const { info } = this.props.language.consumer;

    const searchResult = this.state.searchResults.length && this.state.searchResults[0].multiUserDetails.slice((this.state.activePage - 1)*this.state.displayPage, (this.state.activePage - 1)*this.state.displayPage + this.state.displayPage)

    return (
      <div className="max-width-myinfo max-width--three-quarters pad-all--double">
        {/* <div className="provider-search__header">
          <button
            href=""
            onClick={this.gotoApply}
            className="dta-button dta-button--primary dta-button--text-with-icon"
          >
            <Icon name="application" /> {search.snapApplication}
          </button>
        </div> */}

        <div className="dta-modal__body pad-all">
          <div className="dta-modal__body pad-all provider-search-client">
            <div style={{ display: 'flex', flexDirection: 'row' }} className={`${this.state.selectedQuestion === 'applicationdate' ? 'applicationDateSelected': ''}`}>
              {resolveQuestion(accountSearchQuestion, {
                doRetrieve: this._doRetrieve,
                onChange: this._handleChangeForQuestion,
                doGetUniqueId: this._getUniqueId,
              })}
            </div>

            {this.state.accountNotFoundError ? this.getErrorMessage(search.recordsNotMatch): <p></p>}
            
            {this.state.optionNotSelectedError && this.getErrorMessage(info.selectOption)}

            {this.state.webNumberNotEnterError && this.getErrorMessage(search.validWebAppMsg)}

            {this.state.dtaNumberNotEnterError && this.getErrorMessage(search.validDTAMsg) }

            {this.state.tanfNumberNotEnterError ?
            this.getErrorMessage(search.validTanfMsg)  
            : (
              <p></p>
            )}
             {this.state.caseNumberNotEnterError ?
            this.getErrorMessage(search.validcaseMsg) 
             : (
              <p></p>
            )}
            
            {this.state.clientFirstNameNotEnterError ? this.getErrorMessage(sessionStorage.getItem('firstLastNameError')): <p></p>}

            {/* {this.state.clientFirstNameNotEnterError ? 
            this.getErrorMessage(search.validFirstNameMsg) 
             : (
              <p></p>
            )} */}
            {/* {this.state.clientLastNameNotEnterError ? 
            this.getErrorMessage(search.validLastNameMsg) 
            : (
              <p></p>
            )} */}
            {this.state.emailNotEnterError ? 
            this.getErrorMessage(search.validEmailError)
             : (
              <p></p>
            )}
            {this.state.ssnNumberNotEnterError ? 
            this.getErrorMessage(search.validSSNMsg) 
             : (
              <p></p>
            )}
            {this.state.ebtNumberNotEnterError ? 
            this.getErrorMessage(search.validEBTMsg) 
             : (
              <p></p>
            )}
            </div>
            {/* SHOW ERROR MESSAGE ON NO DATA */}
            <div className="dta-modal__footer dta-modal__footer--inline-buttons">
              <button
                className="dta-button dta-button--large dta-button--primary"
                onClick={this.searchAccount}
              >
                {search.search}
              </button>
            
          </div>
        </div>

        {this.state.searchResults.length ? (
          <div>
            <p className="text--gray">
              {search.searchResult} ({this.state.numOfResults})
            </p>
            {/* {(this.state.conditionObj) && (this.state.conditionObj.label) ?
              (<p className="text--gray">{this.state.conditionObj.label}: {(this.state.conditionObj.finalValue) ? this.state.conditionObj.finalValue : ""}</p>) : ""} */}
            <div 
              className="max-width max-width--three-quarters pad-top-none margin-top searchClientSection" 
              onClick={this.goToConsumer} style={{ }}
            >

            <SearchedClientContents
              onRequestAccess={this.openRAAModal}
              {...this.props}
              multiUserDetails={searchResult}
              sortingClients={this.sortingClients}
              sorting={this.state.sorting}
            />
              
          </div>
            <Pagination
              activePage={this.state.activePage}
              displayPage={this.state.displayPage}
              itemsCount={this.state.numOfResults}
              pageRangeDisplayed={5}
              handlePageChange={this.handlePageChange}
            />
        </div>
        ) : null}
        <RequestAccountAccessModal
          requestAccessForUser={this.state.requestAccessForUser}
          isOpen={this.state.RAAModalOpen}
          onClose={this.closeRAAModal}
          filesToUpload={this.state.filesToUpload}
          onSelectFile={this.handleFileInputChange}
          onRemoveFile={this.handleRemoveFile}
          onSubmit={this.submitRAA}
          modalClass="raa-modal"
          error={this.state.RAAError}
          errorMessage={this.state.RAAErrorMessage}
          selection={this.state.questionData}
        />
        <h1 className="provider-search-heading">''</h1>
      </div>
    );
  }

  
  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    var page = pageNumber - 1;
    this.setState({
      activePage: pageNumber,
      startIndex: page * this.state.displayPage,
      endIndex: page * this.state.displayPage + this.state.displayPage,
    });
  };

  // Events
  // ------

  _doRetrieve = (questionData, conditionObj) => {
    let _this = this;
    if (_this.state.accountAnswer === undefined) {
      questionData.value = "";
    }
    return questionData.value;
  };
  gotoApply = (e) => {
    e.preventDefault();
    const obj = this.state.user;
    this.props.updateConsumerInfo(obj);
    this.props.gotoSnapApply();
  };

  _handleChangeForQuestion = (questionData, conditionObj, val) => {
    // UPDATE THE VALUES AGAINST THE EDITABLE FIELDS

    if(!conditionObj){
      this.setState({
        selectedQuestion: val,
      })
    }
    else{
      const prevSelectedOption = this.state.selectedQuestion === conditionObj.valueToMatch;
      if(!prevSelectedOption)
      {
        this.setState({
          selectedQuestion: val,
        })
      }

    }
    this.setState({
      questionData: questionData,
      conditionObj: conditionObj,
      val: val,
    });




    questionData.value = val;
    if (conditionObj === undefined) {
      this.accountAnswer.answerType.question = val;
    }

    if (conditionObj) {
      if (conditionObj.valueToMatch === "applicationdate") {
          conditionObj.label = "Application Date";
        if (questionData.name === "applicationNameday") this.applicationdateValue.day = val;
        if (questionData.name === "applicationNameMonth") this.applicationdateValue.month = val;
        if (questionData.name === "applicationNameyear") this.applicationdateValue.year = val;
        if (this.applicationdateValue.day !== "" && this.applicationdateValue.month !== "" && this.applicationdateValue.year !== "") {
          this.applicationdateValue.applicationdateValueFinal = `${this.applicationdateValue.day}-${this.applicationdateValue.month.toUpperCase()}-${this.applicationdateValue.year.substring(2)}`;
          this.accountAnswer.answerType.applicationdateValue = this.applicationdateValue.applicationdateValueFinal;
          conditionObj.finalValue = this.applicationdateValue.applicationdateValueFinal;
        }
      }
    }

    if (conditionObj !== undefined) {
      this.accountAnswer.answerType.action = "Provider";
      console.log('hey conditionObj.valueToMatch cases ',conditionObj.valueToMatch)
      switch (conditionObj.valueToMatch) {

        case "web":
          conditionObj.label = "SNAP Web Application Number";
          this.accountAnswer.answerType.question = "web";
          if (val !== "" && val !== "undefined") {
            var rex = /^[0-9]+$/;
            if (rex.test(val) === TextProps.VALUE_FALSE) {
              this.error = TextProps.VALUE_TRUE;
              questionData.value = "";
            } else {
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.webAppNumber = val;
              questionData.value = "";
            }
          } else {
            this.error = TextProps.VALUE_TRUE;
            this.accountAnswer.answerType.webAppNumber = "";
          }
          conditionObj.finalValue = val;
          break;
        case "tanfweb":
          conditionObj.label = "TANF Web Application Number";
          this.accountAnswer.answerType.question = "tanfweb";
          if (val !== "" && val !== "undefined") {
            var rex = /^[0-9]+$/;
            if (rex.test(val) === TextProps.VALUE_FALSE) {
              this.error = TextProps.VALUE_TRUE;
              questionData.value = "";
            } else {
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.tanfweb = val;
              questionData.value = "";
            }
          } else {
            this.error = TextProps.VALUE_TRUE;
            this.accountAnswer.answerType.tanf = "";
          }
          conditionObj.finalValue = val;
          break;

          case "casenumber":
            conditionObj.label = "Case Number";
            this.accountAnswer.answerType.question = "casenumber";
            if (val !== "" && val !== "undefined") {
              var rex = /^[0-9]+$/;
              if (rex.test(val) === TextProps.VALUE_FALSE) {
                this.error = TextProps.VALUE_TRUE;
                questionData.value = "";
              } else {
                this.error = TextProps.VALUE_FALSE;
                this.accountAnswer.answerType.casenumber = val;
                questionData.value = "";
              }
            } else {
              this.error = TextProps.VALUE_TRUE;
              this.accountAnswer.answerType.casenumber = "";
            }
            conditionObj.finalValue = val;
            break;

        case "dtaid":
          conditionObj.label = "Client ID";
          this.accountAnswer.answerType.question = "dtaid";
          if (val !== "" && val !== "undefined") {
            var regex = /^[0-9]+$/;
            if (regex.test(val) === TextProps.VALUE_FALSE) {
              this.error = TextProps.VALUE_TRUE;
              questionData.value = "";
            } else {
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.dtaId = val;
              questionData.value = "";
            }
          } else {
            this.error = TextProps.VALUE_TRUE;
            this.accountAnswer.answerType.dtaId = "";
          }
          conditionObj.finalValue = val;
          break;
        case "ebt":
          conditionObj.label = "EBT Card Number";
          this.accountAnswer.answerType.question = "ebt";
          if (val !== "" && val !== "undefined") {
            var regexEBT = /^[0-9]+$/;
            if (regexEBT.test(val) === TextProps.VALUE_FALSE) {
              this.error = TextProps.VALUE_TRUE;
              questionData.value = "";
            } else {
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.ebtNumber = val;
              questionData.value = "";
            }
          } else {
            this.error = TextProps.VALUE_TRUE;
            this.accountAnswer.answerType.ebtNumber = "";
          }
          conditionObj.finalValue = val;
          break;
        case "ssn":
          conditionObj.label = "Social Security Number";
          this.accountAnswer.answerType.question = "ssn";
          if (questionData.name === "ssnNumber") {
            if (val !== "" && val !== "undefined") {
              if (PropTypes.ssnError === 1) {
                this.error = TextProps.VALUE_TRUE;
              } else {
                this.error = TextProps.VALUE_FALSE;
                this.accountAnswer.answerType.ssnNumber = val;
              }
            } else {
              this.error = TextProps.VALUE_TRUE;
              this.accountAnswer.answerType.ssnNumber = "";
            }
          } else {
            this.accountAnswer.answerType.dob = val;
            questionData.value = "";
          }
          questionData.value = "";
          conditionObj.finalValue = val.slice(-4);
          break;
          case "clientname": 
          conditionObj.label = "Client Name"; 
          this.accountAnswer.answerType.question = "clientname"; 
          if (questionData.name === "firstName") { 
            if  (val !=="" && val !== "undefined" ){
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.clientFirstName = val;
            }else{
              this.error = TextProps.VALUE_TRUE;
              this.accountAnswer.answerType.firstName = "";
              this.accountAnswer.answerType.clientFirstName = "";
            }
          } 
          if (questionData.name === "lastName") { 
            if  (val !=="" && val !== "undefined" ){
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.clientLastName = val ;
            }else{
              this.error = TextProps.VALUE_TRUE;
              this.accountAnswer.answerType.lastName = "";
              this.accountAnswer.answerType.clientLastName = "" ;
            }
          }   
          if (this.accountAnswer.answerType.clientFirstName && this.accountAnswer.answerType.clientLastName && this.accountAnswer.answerType.clientFirstName!=="" && this.accountAnswer.answerType.clientFirstName!== "undefined" && this.accountAnswer.answerType.clientLastName!=="" && this.accountAnswer.answerType.clientLastName!=="undefined" ) {    
            this.error = TextProps.VALUE_FALSE;   
          } else { 
            this.error = TextProps.VALUE_TRUE;     // Clear the other fields if validation fails     
            this.accountAnswer.answerType.firstName = "";   
            this.accountAnswer.answerType.lastName = "";   
          }   
          conditionObj.finalValue = `${this.accountAnswer.answerType.clientFirstName} ${this.accountAnswer.answerType.clientLastName}`;  
          questionData.value = "";
          break;
        case "clientemail":
          conditionObj.label = "Client Email";
          this.accountAnswer.answerType.question = "clientemail";
          if (questionData.name === "clientemail") {
            console.log('hey val', val)
            if (val !== "" && val !== "undefined") {
              this.error = TextProps.VALUE_FALSE;
              this.accountAnswer.answerType.clientemail = val;
            } else {
              this.error = TextProps.VALUE_TRUE;
              this.accountAnswer.answerType.clientemail = "";
            }
          }
          conditionObj.finalValue = val;
          break;
        default:
          conditionObj.label = "";
          conditionObj.finalValue = "";
          break;
      }
    }
    this.setState({
      accountNotFoundError: TextProps.VALUE_FALSE,
      optionNotSelectedError: TextProps.VALUE_FALSE,
      webNumberNotEnterError: TextProps.VALUE_FALSE,
      dtaNumberNotEnterError: TextProps.VALUE_FALSE,
      tanfNumberNotEnterError: TextProps.VALUE_FALSE,
      ssnNumberNotEnterError: TextProps.VALUE_FALSE,
      ebtNumberNotEnterError: TextProps.VALUE_FALSE,
      clientFirstNameNotEnterError: TextProps.VALUE_FALSE,
      clientLastNameNotEnterError: TextProps.VALUE_FALSE,
      ebtNumberNotEnterError: TextProps.VALUE_FALSE,
      accountAnswer: this.accountAnswer,
      searchResults: [],
      activePage: 1,
      startIndex: 0,
      endIndex: 4,
    });
    this.forceUpdate();
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };

  goToConsumer = () => {
    sessionStorage.setItem("consumerAccess", TextProps.VALUE_TRUE);
  };

  searchAccount = () => {
    //checking to search the client is selected or not
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.state.accountAnswer !== undefined) {
      let AnswerObject = this.state.accountAnswer.answerType;
      //this.accountAnswer.answerType = {};
      // VALIDATE THE INPUT DATA BEFORE PROCESSING
      if (AnswerObject !== null && Object.keys(AnswerObject).length > 0) {
        console.log('heynswerObject.question', AnswerObject.question, AnswerObject)
        switch (AnswerObject.question) {
          case "web":
            if (
              AnswerObject.webAppNumber === undefined ||
              AnswerObject.webAppNumber === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ webNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
          case "tanfweb":
            if (
              AnswerObject.tanfweb === undefined ||
              AnswerObject.tanfweb === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ tanfNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
            case "casenumber":
            if (
              AnswerObject.casenumber === undefined ||
              AnswerObject.casenumber === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ caseNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
           case "clientname":
            const { search } = this.props.language.provider;
            if (
              (!AnswerObject.clientFirstName || !AnswerObject.clientLastName) || 
              this.error === TextProps.VALUE_TRUE
            ) {
              if (!AnswerObject.clientFirstName && !AnswerObject.clientLastName) 
              {
                this.setState({ 
                  clientFirstNameNotEnterError: TextProps.VALUE_TRUE
                });
                sessionStorage.setItem('firstLastNameError', search.validFirstLastNameMsg)
                return;
              }
              else if(!AnswerObject.clientFirstName){
                // this.setState({ 
                //   clientFirstNameNotEnterError: TextProps.VALUE_TRUE,
                //   clientFirstLastNameNotEnterError: TextProps.VALUE_FALSE
                // });
                this.setState({ 
                  clientFirstNameNotEnterError: TextProps.VALUE_TRUE
                });
                sessionStorage.setItem('firstLastNameError', search.validFirstNameMsg)
                return;
              }
              else if(!AnswerObject.clientLastName){
                // this.setState({ 
                //   clientLastNameNotEnterError: TextProps.VALUE_TRUE,
                //   clientFirstLastNameNotEnterError: TextProps.VALUE_FALSE
                // });
                this.setState({ 
                  clientFirstNameNotEnterError: TextProps.VALUE_TRUE
                });
                sessionStorage.setItem('firstLastNameError', search.validLastNameMsg)
                return;
              }
            }
            
            // if (
            //   (AnswerObject.clientLastName === undefined || AnswerObject.clientLastName === "") ||
            //   this.error === TextProps.VALUE_TRUE
            // ) {
            //   this.setState({ 
            //     clientLastNameNotEnterError: TextProps.VALUE_TRUE,
            //     clientFirstLastNameNotEnterError: TextProps.VALUE_FALSE
            //   });
            //   return;
            // }
            break;
          case "dtaid":
            if (
              AnswerObject.dtaId === undefined ||
              AnswerObject.dtaId === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ dtaNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
          case "ssn":
            if (
              AnswerObject.ssnNumber === undefined ||
              AnswerObject.ssnNumber === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ ssnNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
          case "ebt":
            if (
              AnswerObject.ebtNumber === undefined ||
              AnswerObject.ebtNumber === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ ebtNumberNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
          case 'clientEmail':
            if (
              AnswerObject.clientemail === undefined ||
              AnswerObject.clientemail === "" ||
              this.error === TextProps.VALUE_TRUE
            ) {
              this.setState({ emailNotEnterError: TextProps.VALUE_TRUE });
              return;
            }
            break;
          default:
            break;
        }
        if (
          this.props.consumer.mfaDetails.userAuth !== undefined &&
          this.props.consumer.mfaDetails.roleList !== undefined
        ) {
          AnswerObject.userAuth = this.props.consumer.mfaDetails.userAuth;
          AnswerObject.roleList = this.props.consumer.mfaDetails.roleList;
          AnswerObject.email = userObject.userDetails.email;
          AnswerObject.firstName = userObject.userDetails.firstName;
          AnswerObject.lastName = userObject.userDetails.lastName;
        } else {
          AnswerObject.userAuth = userObject.userAuth;
          AnswerObject.roleList = userObject.roleList;
          AnswerObject.email = userObject.email;
          AnswerObject.firstName = userObject.firstName;
          AnswerObject.lastName = userObject.lastName;
        }
        if(AnswerObject.question && AnswerObject.question === "applicationdate")
        {
          let dt = AnswerObject.applicationdateValue && AnswerObject.applicationdateValue.split('-');
          if(dt && dt.length)
          {
            if(dt[0].length === 1)
            {
              dt[0] = '0' + dt[0];
              dt =dt.join('-');
              AnswerObject.applicationdateValue = dt;
            } 
          }
        }
        this.props.searchConsumer(AnswerObject);
      }
    } else {
      this.setState({ optionNotSelectedError: TextProps.VALUE_TRUE });
    }
  };

  _handleChangeForSearch = (value) => {
    console.log("setting the search value as from handleChageForSearch", this._handleChangeForSearch);
    this.setState({ searchValue: value });
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchConsumer: onConsumerSearch,
      saveRequestAccountAccessUserInfo: saveRequestAccountAccessUserInfo,
      updateConsumerInfo: updateConsumerInfo,
      multilanguageSelect: multilanguageSelect,
      gotoSnapApply: () => push(routeProps.APPLY_PATH),
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderSearchConsumer);
