import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";
var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var config = require("../../utils/constants/appConfigConstants");

const forgotPassword = {
  email: "Email",
  mobile: "MobileNo",
  forgotPasswordHeader: "Forgot Password",
};

const actionTypes = {
  loadForgotPassword: "FORGOT_PASSWORD_INFO_REQUESTED",
  assignPayloadForgotPassword: "ASSIGN_PAYLOAD_FORGOT_PASSWORD",
  saveResetPasswordDataAction: "SAVE_FORGOT_RESET_SUCCESS",
  serviceFailedAction: "FORGOT_FROM_SERVICE_FAIL",
  emailSuccess: "FORGOT_EMAIL_SUCCESS",
  mfaSuccess: "FORGOT_MFACODE_SUCCESS",
  passSuccess: "FORGOT_PASS_SUCCESS",
};

//Common Fetch Support Functions
export function onEmailSubmit(request) {
  return (dispatch) => {
    const audit = getAudit();
    var options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        audit: JSON.stringify(audit),
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
        crossdomain: TextProps.VALUE_FALSE,
      },
    };
    axios
      .post(
        config.TigerURL + "/apipublic/security/sendpasswordresetcode",
        request,
        options
      )
      .then(function (response) {
        dispatch(onEmailSubmitSuccess(response));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onEmailSubmitSuccess(data) {
  return {
    type: actionTypes.emailSuccess,
    payload: data,
  };
}

export function onMfaCodeSubmit(request) {
  return (dispatch) => {
    const audit = getAudit();
    var options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        audit: JSON.stringify(audit),
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
        crossdomain: TextProps.VALUE_FALSE,
      },
    };
    axios
      .post(
        config.TigerURL + "/apipublic/security/verifypasswordresetcode",
        request,
        options
      )
      .then(function (response) {
        dispatch(onMfaCodeSubmitSuccess(response));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onMfaCodeSubmitSuccess(data) {
  return {
    type: actionTypes.mfaSuccess,
    payload: data,
  };
}

export function onPasswordSubmit(request) {
  return (dispatch) => {
    const audit = getAudit();
    var options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        audit: JSON.stringify(audit),
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
        crossdomain: TextProps.VALUE_FALSE,
      },
    };
    axios
      .post(
        config.TigerURL + "/apipublic/security/resetpassword",
        request,
        options
      )

      .then(function (response) {
        dispatch(onSuccessSubmitSuccess(response));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onSuccessSubmitSuccess(data) {
  return {
    type: actionTypes.passSuccess,
    payload: data,
  };
}

export function loadForgotPasswordPage() {
  return (dispatch) => {
    dispatch(forgotPasswordAction(forgotPassword));
  };
}

function forgotPasswordAction(payload) {
  return {
    type: actionTypes.assignPayloadForgotPassword,
    payload,
  };
}
function saveResetPasswordDataAction(data) {
  return {
    type: actionTypes.saveResetPasswordDataAction,
    payload: data,
  };
}

export function sendNotification(postData) {
  return (dispatch) => {
    var url = BaseUrl + "/consumer/updateUserPassword";
    var options = {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };

    axios
      .post(url, postData, options)
      .then(checkStatus)
      .then(function (data) {
        dispatch(saveResetPasswordDataAction(data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
