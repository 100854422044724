import Cleave from "cleave.js";
// do not remove CleavePhone import otherwise app will get crash
import CleavePhone from "cleave.js/dist/addons/cleave-phone.us"; // eslint-disable-line no-unused-vars
import _ from "lodash";
import { DateUtils } from "react-day-picker";
import * as SharedProps from "../../constants/shared";
import * as BenefitProps from "../../constants/benefit";
import { MONTHSHORT } from "../../constants/controls";
import { isNone } from "./controls";
import moment from "moment";
import * as TextProps from "../../constants/text";

const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  fullMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

/**
 * Returns day of week fully spelled out
 * @param  {Date} dateVal    Date objet
 * @return {string}       string day of week fully spelled out
 */
export function dayOfWeek(dateVal) {
  if (!_.isDate(dateVal)) {
    return dateVal;
  }
  return daysOfWeek[dateVal.getDay()];
}

/**
 * Returns local time with 12-hour time (AM/PM) formatting
 * @param  {Date} dateObj    Date object
 * @return {string}       string local time
 */
export function localTime(dateObj) {
  if (!_.isDate(dateObj)) {
    return dateObj;
  }
  const hour = dateObj.getHours(),
    minute =
      dateObj.getMinutes() > 10
        ? dateObj.getMinutes()
        : "0" + dateObj.getMinutes(),
    suffix = hour < 12 ? "AM" : "PM";
  return `${hour % 12 > 9 ? hour % 12 : "0" + (hour % 12)}:${_.padStart(
    minute,
    2
  )} ${suffix}`;
}

// Returns utc time with 12-hour time (AM/PM) formatting
// 2021-11-24T11:00:00.000Z
export function utcFormatAMPM(inputDate) {
  var hours = inputDate.getUTCHours();
  var minutes = inputDate.getUTCMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
}

/**
 * Formats Javascript Date object for display
 * @param  {Date} dateObject                    Date object
 * @param  {boolean} options.humanize     use humanized language if TextProps.VALUE_TRUE, default string otherwise
 * @param  {Object} options.screenReader  use language especially suited for screen readers if TextProps.VALUE_TRUE,
 *                                        default string otherwise
 * @return {string}                       string representing Date for display
 */
export function date(
  dateObject,
  screenReader = TextProps.VALUE_FALSE
) {
  if (
    dateObject === undefined ||
    dateObject === null ||
    dateObject == "" ||
    dateObject.toString().indexOf("Invalid") !== -1
  ) {
    return null;
  }
  const dateString = dateObject.toISOString().substring(0, 10);

  if (
    !_.isDate(dateObject) ||
    dateString.substring(8, 10) != dateObject.getDate()
  ) {
    if (dateString !== null && dateString !== "") {
      const yearString = dateString.substring(0, 4),
        monthString = dateString.substring(5, 7),
        dayOfMonthString = dateString.substring(8, 10),
        formattedDateString = screenReader
          ? `${
              fullMonthNames[monthString]
            } ${dayOfMonthString}, ${yearString}`
          : `${monthString}.${
              dayOfMonthString
            }.${yearString}`;
      return formattedDateString;
    }
    return dateObject;
  }
  const year = dateObject.getFullYear(),
    month = dateObject.getMonth() + 1,
    namemonth = dateObject.getMonth(),
    dayOfMonth = dateObject.getDate(),
    formattedDate = screenReader
      ? `${fullMonthNames[namemonth]} ${dayOfMonth}, ${year}`
      : `${month}.${dayOfMonth}.${year}`;
  return formattedDate;
}

/**
 * Determines if the provided Date object represents today
 * @param  {Date}  dateToday   Date object
 * @return {Boolean}      whether or not this Date represents today
 */
export function isToday(dateToday) {
  return _.isDate(dateToday)
    ? DateUtils.isSameDay(dateToday, new Date(Date.now()))
    : TextProps.VALUE_FALSE;
}

/**
 * Formats a user object's full name for display
 * @param  {object}  user    user object
 * @param  {Boolean} reverse order names 'Last, First Suffix'
 * @return {string}          full name string ('First Last Suffix')
 */
export function fullName(user, reverse = TextProps.VALUE_FALSE) {
  let userName;
  if (reverse && user) {
    userName = `${user[SharedProps.LAST_NAME]}, ${
      user[SharedProps.FIRST_NAME]
    }${user[SharedProps.SUFFIX] ? " " + user[SharedProps.SUFFIX] : ""}`;
  } else if (!reverse && user) {
    userName = `${user[SharedProps.FIRST_NAME]} ${user[SharedProps.LAST_NAME]}${
      user[SharedProps.SUFFIX] ? " " + user[SharedProps.SUFFIX] : ""
    }`;
  } else {
    userName = user;
  }
  return userName;
}

export function fullNameProvider(user, reverse = TextProps.VALUE_FALSE) {
  if (reverse) {
    return user
      ? `${user[SharedProps.LAST]}, ${user[SharedProps.FIRST]}`
      : user;
  } else {
    return user ? `${user[SharedProps.FIRST]} ${user[SharedProps.LAST]}` : user;
  }
}
/**
 * Formats numerical amount for display as money
 * @param  {number}  amount           amount of money
 * @param  {Boolean} withDollarSign   whether to prepend a dollar sign for display
 * @return {string}                   formatting money string
 */
export function money(amount, withDollarSign = TextProps.VALUE_TRUE) {
  let dollerLable, amountString;
  if (amount >= 0) {
    amount = parseFloat(amount);
  } else {
    amount = "";
  }
  if (withDollarSign) {
    dollerLable = "$";
  } else {
    dollerLable = "";
  }
  if (amount) {
    amountString = `${dollerLable}${amount.toFixed(2)}`;
  } else {
    amountString = amount;
  }
  return amountString;
}

/**
 * Normalizes format of address object
 * @param  {object} addressObj   address object
 * @return {object}           new object with address formatted on two lines for display
 */
export function address(addressObj) {
  if(addressObj && (addressObj.line1 !==undefined || typeof addressObj.county === "object")) {
    if(typeof addressObj.county === "object") {
      addressObj = addressObj.county
    }
    let street =  addressObj.line1 + (addressObj.line2 ? addressObj.line2 : "");
    if (addressObj.city === "") {
      if (addressObj.zip != "") {
        return {
          firstLine: street,
          secondLine: `${addressObj.state} ${addressObj.zip}`,
        };
      } else {
        return {
          firstLine: street,
          secondLine: addressObj.zip,
        };
      }
    } else {
      return {
        firstLine: street,
        secondLine: `${addressObj.city}, ${addressObj.state} ${addressObj.zip}`,
      };
    }
  }else {
    let street =  addressObj.addressLine1 + (addressObj.addressLine2 ? addressObj.addressLine2 : "");
    if (addressObj.city === "") {
      if (addressObj.zipCode != "") {
        return {
          firstLine: street,
          secondLine: `${addressObj.state} ${addressObj.zip}`,
        };
      } else {
        return {
          firstLine: street,
          secondLine: addressObj.zipCode,
        };
      }
    } else {
      return {
        firstLine: street,
        secondLine: `${addressObj.city}, ${addressObj.state} ${addressObj.zipCode}`,
      };
    }
  }
  
}

/**
 * Formats US phone numbers for display
 * @param  {string} phoneNumber       phone number
 * @param  {String} delimiter   delimiter between digit blocks, defaults to a single space
 * @return {string}             formatted phone number
 */
export function phone(phoneNumber, delimiter = " ") {
  return phoneNumber === null || phoneNumber === undefined
    ? " "
    : new Cleave.PhoneFormatter(
        new Cleave.AsYouTypeFormatter("us"),
        delimiter
      ).format(phoneNumber);
}

export function lastFourDigitSSN(ssn) {
  if (ssn !== null && ssn !== 0) {
    return `XXX-XX-${ssn}`;
  } else {
    return ssn;
  }
}

/**
 * Formats benefit type for display
 * @param  {string}  type   type of benefit
 * @param  {Boolean} short  whether or use to use the short name for the provided benefit
 * @return {string}         formatted display name for the type of benefit
 */
export function benefitTypeDisplay(type, short = TextProps.VALUE_FALSE) {
  switch (type) {
    case BenefitProps.BENEFIT_TYPE_CASH:
      return short ? "Cash" : "TANF";
    case BenefitProps.BENEFIT_TYPE_SNAP:
    case BenefitProps.BENEFIT_TYPE_FS:
      return "SNAP";
    case BenefitProps.BENEFIT_TYPE_TAFDC:
    case BenefitProps.BENEFIT_TYPE_TANF:
      return "TANF";
    case BenefitProps.BENEFIT_TYPE_EAEDC:
      return "EAEDC";
    default:
      return "";
  }
}

/**
 * Whether the provided object is a coordinate object
 * @param  {object}  coord  object to check
 * @return {Boolean}        whether or not the provided object is a coordinate object
 */
export function isCoord(coord) {
  return coord && coord.lat && coord.lng;
}

/**
 * converting string date obj to Date obj
 * @param {param} stringDate
 * @return {Date}
 */

export function convertStringDateToDate(stringDate) {
  if (stringDate !== undefined && stringDate !== null) {
    return new Date(stringDate);
  } else {
    return new Date();
  }
}

export function convertStringDateToMonth(stringDate, isOverDue) {
  if (stringDate !== undefined && stringDate !== null) {
    var dateNewFormat = new Date(stringDate);
    if(isOverDue) {
      let tempDate = moment(stringDate); 
      const updatedDate = tempDate.add(1, 'months');
      var month = fullMonthNames[updatedDate.month()];
      console.log("New Date", updatedDate);
      console.log("New Date", month, "||", updatedDate);
      var year =  updatedDate.year();
      console.log("Final Date", month + " " + year);
      return month+" "+year;
    }
    else {
      var month = fullMonthNames[dateNewFormat.getMonth()];
      var year =  dateNewFormat.getFullYear();
      return month+" "+year;
    }
  } else {
    return "";
  }
}

export function convertStringDateToMonthOnly(stringDate) {
  if (stringDate !== undefined && stringDate !== null) {
    var dateNewFormat = new Date(stringDate);
      var month = fullMonthNames[dateNewFormat.getMonth()];
      return month;
    }
}


export function convertStringDateToDMY(stringDate) {
  if (stringDate !== undefined && stringDate !== null) {
    return moment(stringDate, "YYYY-MM-DD").format("DD.MMM.YYYY");
  } else {
    return new Date();
  }
}

/**
 *
 * @param {*} stringDate
 * @return with braces for provider
 */
export function convertStringDateToWithFormat(stringDate) {
  if (isNone(stringDate)) {
    return "";
  } else if (stringDate !== undefined && stringDate !== null) {
    const dateFromStr = new Date(stringDate);
    const timezoneoffset = new Date(
      dateFromStr.getTime() + Math.abs(dateFromStr.getTimezoneOffset() * 60000)
    );
    return (
      " (" +
      moment(timezoneoffset).format("MM") +
      "/" +
      moment(timezoneoffset).format("DD") +
      "/" +
      moment(timezoneoffset).format("YYYY") +
      ")"
    );
  }
}

export function convertStringDateToWithNewFormat(stringDate) {
  if (isNone(stringDate)) {
    return "";
  } else if (stringDate !== undefined && stringDate !== null) {
    var dateNewFormat = new Date(stringDate),
      month = "" + (dateNewFormat.getMonth() + 1),
      day = "" + dateNewFormat.getDate(),
      year = dateNewFormat.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
}

// converting months full name to SHORT name
function _convertMonthNumberToString(monthNumber) {
  return isNone(MONTHSHORT[monthNumber])
    ? MONTHSHORT[0]
    : MONTHSHORT[monthNumber];
}

/**
 *
 * @param {STATUS} stringShort
 * @return status full name in exampl in DB status is - PEND
 */
export function convertStatusShortToFull(stringShort) {
  switch (stringShort) {
    case "ACTIVE":
      return BenefitProps.STATUS_TYPE_ACTIVE;
    case "PEND":
      return BenefitProps.STATUS_TYPE_PENDING;
    case "CLOSED":
      return BenefitProps.STATUS_TYPE_CLOSED;
    case "DENIED":
      return BenefitProps.STATUS_TYPE_DENIED;
    case "INELIG":
      return BenefitProps.STATUS_TYPE_INELIGIBLE;
    default:
      return "";
  }
}

//Added by Gowtham on 22 Mar 2019 to format amount with comma seperator

export function formatMoney(moneyString) {
  if (moneyString != null && moneyString != undefined)
    return (
      "$" + moneyString.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  else return "";
}

//Added by Gowtham on 23 May 2019 to format amount string with comma seperator to amount number

export function formatAmountFromStringToNumber(amountString) {
  let tempAmount = amountString.toString().replace(/,/g, "");
  if (tempAmount) return Number(tempAmount);
  else return null;
}

export function formatDate(dateFormatCheck) {
  if (typeof dateFormatCheck === "string" && dateFormatCheck.includes("-")) {
    return (
      " (" +
      moment(dateFormatCheck).format("MM") +
      "/" +
      moment(dateFormatCheck).format("DD") +
      "/" +
      moment(dateFormatCheck).format("YYYY") +
      ")"
    );
  }else if (dateFormatCheck && dateFormatCheck.includes("/")){
    return dateFormatCheck;
  }else{
    return "";
  }
}

export function formatDateNew(dateFormatNew) {
  return (
    moment(dateFormatNew).format("YYYY") +
    "-" +
    moment(dateFormatNew).format("MM") +
    "-" +
    moment(dateFormatNew).format("DD")
  );
}

export function formatDateBySlash(dateFormatCheck) {
  return (
    moment(dateFormatCheck).format("MM") +
      "/" +
      moment(dateFormatCheck).format("DD") +
      "/" +
      moment(dateFormatCheck).format("YYYY") 
  );
}

/**
 * @param dateStateFormat - input date
 * @return string format of date
 */
export function convertDateToStateFormat(dateStateFormat) {
  if (isNone(dateStateFormat)) {
    return "";
  }
  if (typeof dateStateFormat === "string") {
 
  return (
    moment(dateStateFormat).format("MM") +
    "/" +
    moment(dateStateFormat).format("DD") +
    "/" +
    moment(dateStateFormat).format("YYYY") 
  );
  }
}

export function convertTimestampToToDataFormat(dateStateFormat) {
  if (isNone(dateStateFormat)) {
    return "";
  }
  var dateVal = new Date(dateStateFormat),
      month = "" + dateVal.getMonth(),
      day = "" + dateVal.getDate(),
      year = dateVal.getFullYear();
  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return (
    "" + (Number(month)+1) + "/" + day + "/" + year + ""
  );
}
export function convertTimestampToToDataFormatConcern(dateStateFormat) {
  if (isNone(dateStateFormat)) {
    return "";
  }
  var dateVal = new Date(dateStateFormat),
      month = "" + (dateVal.getMonth() + 1),
      day = "" + dateVal.getDate(),
      year = dateVal.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;
  return "" + month + "/" + day + "/" + year;
}


export function convertTimestampToTimeFormat(dateStateFormat) {
  if (isNone(dateStateFormat)) {
    return "";
  }
  var dateVal = new Date(dateStateFormat),
      hours = "" + dateVal.getHours(),
      mins = "" + dateVal.getMinutes();

  return (
    hours+":"+ (mins < 10 ? '0' : '') + mins
  );
}

export function appointmentDateFormat(inputDate) {
  return (
    moment(inputDate).format("MM") +
    "/" +
    moment(inputDate).format("DD") +
    "/" +
    moment(inputDate).format("YYYY")
  );
}

export function MonthDateYearFormat(inputDate) {
  return (
    moment(inputDate).format("MM") +
    "-" +
    moment(inputDate).format("DD") +
    "-" +
    moment(inputDate).format("YYYY")
  );
}

// phone number format function accepts phone number string ex: 1234567890
// output: (123) 456-7890
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}
