import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";

import OptionItem from "./optionItem";
import Radio from "./radio";
import { addStringIf, tryCall, isNone } from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { RADIO_CLASSES } from "../../utils/constants/controls";
import { moreLabels } from "../../pages/screening/screeningText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class Numbers extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    //classes
    optionClass: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
    this.state = {
      ...this._updateStateFromValue(props.value, TextProps.VALUE_TRUE),
      _isOpeningMore: TextProps.VALUE_FALSE,
    };
    this._handleChangeForInputHelper = _.debounce(
      this._handleChangeForInputHelper,
      500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(
        this._updateStateFromValue(nextProps.value, TextProps.VALUE_FALSE)
      );
    }
  }

  render() {
    const { name, disabled, error, required, optionsClass, data } = this.props,
      controlClass = RADIO_CLASSES.controlClass,
      thisOptionClass = addStringIf(
        optionsClass,
        `dta-numbers__item ${RADIO_CLASSES.optionClass}`
      ),
      thisControlClass = `${controlClass} ${controlClass}--without-indicator`;
    return (
      <div className="dta-numbers">
        <Radio
          onChange={this._handleChangeForOption}
          aria-labelledby={this.props["aria-labelledby"]}
          name={name}
          value={this.state._value}
          className={addStringIf(
            this.props.className,
            "dta-numbers__container"
          )}
          optionClass={thisOptionClass}
          controlClass={thisControlClass}
          options={[1, 2, 3, 4, 5]}
          showClear={TextProps.VALUE_FALSE}
          // error={error}
          disabled={disabled}
          required={required}
        >
          {this._buildMoreOption(
            name,
            disabled,
            error,
            required,
            thisOptionClass,
            thisControlClass
          )}
        </Radio>
        {this._buildMoreControl(name, disabled, error, required, data)}
      </div>
    );
  }

  // Rendering
  // ---------

  _buildMoreOption(
    name,
    isDisabled,
    isError,
    required,
    optionClass,
    controlClass
  ) {
    return [
      <OptionItem
        {...RADIO_CLASSES}
        key={this.nextUniqueId()}
        name={name}
        value=""
        text={moreLabels(language)}
        type="radio"
        onChange={this._handleTriggerMore}
        checked={this.state._isMore}
        disabled={isDisabled}
        error={isError}
        required={required}
        className={optionClass}
        controlClass={controlClass}
      />,
    ];
  }

  _buildMoreControl(name, isDisabled, isError, required, data) {
    const moreId = this.nextUniqueId();
    return (
      <div
        className={addStringIf(
          !this.state._isMore,
          "dta-numbers__container",
          "dta-numbers__container--hidden"
        )}
      >
        {data && <p><br/>{ data.numberTxBoxLabel }</p>}
        <input
          id={moreId}
          name={name}
          className={addStringIf(
            isError,
            "dta-input dta-numbers__control",
            "dta-input--error"
          )}
          type="number"
          pattern="[0-9]*"
          min="1"
          value={this.state._value}
          onChange={this._handleChangeForInput}
          onBlur={this._handleBlurForInput}
          disabled={isDisabled}
          required={required}
          ref={(el) => (this.moreObj = el)}
        />
        {/* Label needs to come after input for screen reader during programmatic focus*/}
        <label htmlFor={moreId} className="sr-only">
          Please enter a number.
        </label>
      </div>
    );
  }

  // Events
  // ------

  _handleTriggerMore = () => {
    this._openMore();
  };

  _handleChangeForOption = (value) => {
    this._closeMore();
    this.setState({ _value: value });
    tryCall(this.props.onChange, value);
  };

  _handleChangeForInput = ({ target: { value } }) => {
    let validNum = value >= 0 ? value : "";
    this.setState({ _value: validNum });
    this._handleChangeForInputHelper(validNum);
  };

  _handleChangeForInputHelper(value) {
    tryCall(this.props.onChange, value);
  }

  _handleBlurForInput = (event) => {
    if (!isNone(this.state._value) && this.state._value < 6) {
      this._closeMore();
    }
  };

  // Helpers
  // -------

  _updateStateFromValue(value, isFirstTime) {
    const obj = { _value: value || "" };
    // only check if more should be TextProps.VALUE_TRUE if it is TextProps.VALUE_FALSE
    // if more is TextProps.VALUE_TRUE, then we should leave it to not pre-maturely close the more
    if (isFirstTime || this.state._isMore === TextProps.VALUE_FALSE) {
      obj["_isMore"] = value > 5;
    }
    return obj;
  }

  _openMore() {
    this.setState({
      _isMore: TextProps.VALUE_TRUE,
      _isOpeningMore: TextProps.VALUE_TRUE,
      _value: "",
    });
    setTimeout(() => {
      // schedule prop updating via triggered handler for after the state updates
      tryCall(this.props.onChange, "");
      this.moreObj.focus();
      // set this progress flag to TextProps.VALUE_FALSE after a delay in order to preempt a race condition
      // by which the focus doesn't happen fast enough so the blur event is fired on
      // the more input and the input closes before it is focused
      setTimeout(
        () => this.setState({ _isOpeningMore: TextProps.VALUE_FALSE }),
        200
      );
    }, 300);
  }

  _closeMore() {
    if (!this.state._isOpeningMore) {
      this.setState({
        _isMore: TextProps.VALUE_FALSE,
        _isOpeningMore: TextProps.VALUE_FALSE,
      });
    }
  }
}

export default Numbers;
