import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  addStringIf,
  tryCall,
  validateAria,
} from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";

class DTAButton extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  static defaultProps = {
    ...CommonDefaults,
    // container
    id: "",
  };

  render() {
    const { error, disabled, required, name, className } = this.props,
      buttonId = this.props.id,
      // use the passed in aria labels if present, otherwise, fall back on the select id so that
      // focusing on the option will read the label
      ariaLabels = validateAria(this.props["aria-labelledby"] || buttonId);

    return (
      <button
        name={name}
        id={buttonId}
        type="button"
        className={addStringIf(
          error,
          addStringIf(className, "dta-button"),
          "dta-select--error"
        )}
        disabled={disabled}
        required={required}
        onClick={this._handleChange}
        value={this.props.data.value}
        aria-label={ariaLabels}
      >
        {this.props.data.value}
      </button>
    );
  }

  _handleChange = ({ target: { value } }) => {
    tryCall(this.props.onChange, value);
  };
}

export default DTAButton;
