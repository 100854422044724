import React from "react";

import { addStringIf } from "./utilities/controls";
import TermsSnapTafdcEaedc from "./termsSnapTafdcEaedc";

export default function Terms({ id, className }) {
  return (
    <div id={id} className={addStringIf(className, "dta-terms")}>
      <TermsSnapTafdcEaedc></TermsSnapTafdcEaedc>
    </div>
  );
}
