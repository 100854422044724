import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

import OptionItem from "./optionItem";
import {
  normalizeOption,
  extractFromObject,
  tryCall,
  addStringIf,
} from "../components/utilities/controls";
import { OptionsTypes, OptionsDefaults } from "./propTypes";

class Options extends Component {
  static propTypes = {
    ...OptionsTypes,
    // required
    doBuildState: PropTypes.func.isRequired,
    doBuildValue: PropTypes.func.isRequired,
    inputType: PropTypes.string.isRequired,
    doInitializeCleared: PropTypes.func.isRequired,
    // handlers
    doBuildOptionItemContents: PropTypes.func,
    // classes
    controlClass: PropTypes.string,
    labelClass: PropTypes.string,
    errorClass: PropTypes.string,
    textClass: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  static defaultProps = {
    ...OptionsDefaults,
    textClass: "truncate-text",
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    this.state = {
      ...props.doBuildState(
        props.value,
        props.clearLabel,
        this._getValuesFromOptions(props.options)
      ),
      _isCleared: props.doInitializeCleared(props.value, props.clearLabel),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      this.props.options !== nextProps.options
    ) {
      this.setState({
        ...nextProps.doBuildState(
          nextProps.value,
          nextProps.clearLabel,
          this._getValuesFromOptions(nextProps.options)
        ),
        _isCleared: nextProps.doInitializeCleared(
          nextProps.value,
          nextProps.clearLabel
        ),
      });
    }
  }

  render() {
    const ariaLabels = this.props["aria-labelledby"],
      args = {
        name: this.props.name || this.nextUniqueId(),
        type: this.props.inputType,
        disabled: this.props.disabled,
        error: this.props.error,
        required: this.props.required,
        className: this.props.optionClass,
        controlClass: this.props.controlClass,
        labelClass: this.props.labelClass,
        errorClass: this.props.errorClass,
        textClass: this.props.textClass,
        doBuildOptionItemContents: this.props.doBuildOptionItemContents,
      },
      numOptions = this.props.options.length,
      numChildren = this.props.children ? this.props.children.length : 0;
    return this.props.clearPosition === "top" ? (
      <ul className={this.props.className} aria-labelledby={ariaLabels}>
        {this.props.showClear &&
          this._buildClearItem(
            args,
            this.props.clearLabel,
            numOptions + numChildren
          )}
        {this._buildOptionItems(args, this.props.options.map(normalizeOption))}
        {Children.map(this.props.children, (child, index) => {
          return React.cloneElement(child, {
            index: numOptions + index,
          });
        })}
      </ul>
    ) : (
      <ul className={this.props.className} aria-labelledby={ariaLabels}>
        {this._buildOptionItems(args, this.props.options.map(normalizeOption))}
        {Children.map(this.props.children, (child, index) => {
          return React.cloneElement(child, {
            index: numOptions + index,
          });
        })}
        {this.props.showClear &&
          this._buildClearItem(
            args,
            this.props.clearLabel,
            numOptions + numChildren
          )}
      </ul>
    );
  }

  // Rendering
  // ---------

  _buildOptionItems(args, options) {
    return options.map(
      ({ thisValue, thisDisplay, displayClass, thisCount }, index) => {
        return (
          <OptionItem
            {...args}
            key={index}
            index={index}
            value={thisValue}
            text={thisDisplay}
            onChange={this._handleChangeForOption}
            checked={this.state[thisValue]}
            count={thisCount}
            textClass={addStringIf(displayClass, args.textClass)}
          />
        );
      }
    );
  }

  _buildClearItem(args, label, index) {
    return (
      <OptionItem
        {...args}
        key={index}
        index={index}
        value={label}
        text={label}
        onChange={this._handleChangeForOption}
        checked={this.state._isCleared}
      />
    );
  }

  // Events
  // ------

  _handleChangeForOption = ({ target: { value } }) => {
    const { disabled, clearLabel, onChange, doBuildValue, options } =
      this.props;
    if (disabled) {
      return;
    }
    this.setState({ _isCleared: value === clearLabel });
    tryCall(
      onChange,
      doBuildValue(
        value,
        clearLabel,
        extractFromObject(this._getValuesFromOptions(options), this.state)
      )
    );
  };

  // Helpers
  // -------

  _getValuesFromOptions(options) {
    let values = options.map(normalizeOption).map((opt) => opt.thisValue);
    return values;
  }
}

export default Options;
