import React, { Component } from "react";
import PropTypes from "prop-types";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

import DTAMarker from "./dtaMarker";
import { componentOfType } from "../../components/utilities/proptypes";
import { tryCall } from "../../components/utilities/controls";
import { isCoord } from "../../components/utilities/formatters";
import * as TextProps from "../../constants/text";

/*
  WrappedMap
  ----------

  # Purpose:
  Internal helper wrapper for the library used to integrate Google Maps. This component lays out
  the basic markup required for functionality and handles composition with the appropriate higher-
  order components in the export statement

  # Props:
  onChange: Optional, function called with the new coordinates when the center of the map is changed
  onZoomChange: Optional, function called with the new zoom level when the zoom is changed
  defaultCenter: Optional, center to render the map at if no `value` data binding is provided
  value: Optional, object with `lat`/`lng` coordinates to render the map at
  defaultZoom: Optional, zoom level to render the map at if no `zoom` data binding is provided
  zoom: Optional, number for the zoom level, larger numbers is closer zoom
  findCurrentLocation: Optional, boolean indicating whether or not the map should attempt to
                       recenter on the user's current location after the initial render
  mapRef: Optional, function passed the element reference to the library map element itself for
          accessing the map library's public API
  children: Optional, zero or more `DTAMarkers` to populate the map

  # Example:
  <WrappedMap
    {...otherProps}
    googleMapURL={`${apiURL}&${apiURLKeyParameter}=${apiKey}`}
    loadingElement={this._buildLoading(containerClass, loadingClass, loadingLabel)}
    containerElement={this._buildContainer(containerClass)}
    mapElement={this._buildMap(mapClass)}
  />
 */

class WrappedMap extends Component {
  static propTypes = {
    // handlers
    onChange: PropTypes.func,
    onZoomChange: PropTypes.func,
    // center
    defaultCenter: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    value: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    // zoom
    defaultZoom: PropTypes.number,
    zoom: PropTypes.number,
    // functionality
    findCurrentLocation: PropTypes.bool,
    mapRef: PropTypes.func,
    // children
    children: componentOfType(DTAMarker),
  };

  static defaultProps = {
    defaultCenter: {
      // Coordinates of center of Boston
      lat: 42.3600825,
      lng: -71.05888010000001,
    },
    defaultZoom: 13,
    findCurrentLocation: TextProps.VALUE_FALSE,
  };

  state = {
    value: this.props.value,
    zoom: this.props.zoom,
  };

  componentDidMount() {
    if (this.props.findCurrentLocation && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(this._handleAutoLocation);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
    if (this.props.zoom !== nextProps.zoom) {
      this.setState({ zoom: nextProps.zoom });
    }
  }

  render() {
    const { defaultZoom, defaultCenter, children, mapRef, ...otherProps } =
        this.props,
      { value, zoom } = this.state;
    if (isCoord(value)) {
      otherProps.center = value;
    }
    if (zoom) {
      otherProps.zoom = zoom;
    }
    return (
      <GoogleMap
        {...otherProps}
        ref={this._setRefs}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        onZoomChanged={this._handleZoom}
        options={{
          mapTypeControl: TextProps.VALUE_FALSE,
          clickableIcons: TextProps.VALUE_FALSE,
        }}
      >
        {children}
      </GoogleMap>
    );
  }

  // Rendering
  // ---------

  _setRefs = (el) => {
    this._map = el;
    tryCall(this.props.mapRef, el);
  };

  // Events
  // ------

  _handleZoom = () => {
    if (this._map) {
      tryCall(this.props.onZoomChange, this._map.getZoom());
    }
  };

  _handleAutoLocation = ({ coords: { latitude, longitude } }) => {
    const newCoord = { lat: latitude, lng: longitude };
    this.setState({ value: newCoord });
    tryCall(this.props.onChange, newCoord);
  };
}

export default withScriptjs(withGoogleMap(WrappedMap));
