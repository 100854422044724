import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";

import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import * as languageConstants from "../../utils/constants/constants";
import { medicalExpenseMultilanguageLabels, recertificationPageLabels } from "./recertificationText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import {
  houseHoldSectionMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  resourcesSectionMultilanguageLabels
} from "../applySnap/applySnapText";
import { interimReportPageLabels } from "../recertInterimReport/recertInterimReportText";
let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === "select") {
    language = "en-US";
  }
  var recertType = sessionStorage.getItem('recertType');

export const sections = [
  {
    //newIndex 0
    //index 0
    id: "before-you-start",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.beforeYouStart
    ).heading, //'How does applying work?',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.beforeYouStart
    ).heading,
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 1
    //index 1
    id: "contact-info",
    parentId: "contact-info",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.contactInfo
    ).title,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.contactInfo
    ).header,
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 2
    id: "expedited-snap",
    parentId: "expedited-snap",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.expeditedSnap
    ).titleEmergency,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.expeditedSnap
    ).headerEmergency,
    questions: [
      {
        [Props.NAME]: "less-monthly-housing-expenses",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).incomeMoneylabel,
        [Props.INLINE_HELP]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).inlineHelpLabel,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).optionYesNo,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).alertSelectIncome,
        [OptionsProps.IMM_NEEDS_PGRM_CODE]: 20,
      },
      {
        [Props.NAME]: "less-monthly-income",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).incomeLesslabel,
        [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).monthlyIncomeoptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).selectIncomeErrmsg,
        [OptionsProps.IMM_NEEDS_PGRM_CODE]: 21,
      },
      {
        [Props.NAME]: "migrant-worker",
        [Props.LABEL]: recertificationPageLabels(language,null,languageConstants.expeditedSnap).migrantLabel,
        [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).migrantOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.expeditedSnap
        ).migrantErrmsg,
        [OptionsProps.IMM_NEEDS_PGRM_CODE]: 22,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
    hiddenFromNav: TextProps.VALUE_TRUE,
  },
  {
    //newIndex 3
    //index 2
    id: "my-household",
    parentId: "my-household",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.householdStaticRecert
    ).houseTitle, // 'My household',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.householdStaticRecert
    ).houseHeader,
    subHeader: recertType === "REEVAL" ? recertificationPageLabels(
      language,
      null,
      languageConstants.householdStaticRecert
    ).houseMemberSubHeader : "",
    completed: null,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 4
    /** Household Information sections data*/
    id: "household-information",
    parentId: "household-information",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).titleHouseholdInformation,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).headerHouseholdInformation,
    questions: [
      {
        [Props.NAME]: "is-enrolled",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isEnrolledHigherLearningLabel,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isEnrolledHigherLearning",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "is-enrolled-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isEnrolledHigherLearning",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isanyone-striker",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isAnyoneStriker,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isAnyoneStriker",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isanyone-striker-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isAnyoneStriker",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasvoluntarily-quitjob",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasVoluntarilyQuitJob,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasVoluntarilyQuitJob",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasvoluntarily-quitjob-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasVoluntarilyQuitJob",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "ishousehold-boarder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isHouseholdBoarder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isHouseholdBoarder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "ishousehold-boarder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isHouseholdBoarder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isdisqualified-intlprgvltn",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isDisqualifiedIntlPrgVltn,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isDisqualifiedIntlPrgVltn",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isdisqualified-intlprgvltn-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isDisqualifiedIntlPrgVltn",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isguiltybuy-sellfoodasst",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuySellFoodAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuySellFoodAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isguiltybuy-sellfoodasst-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuySellFoodAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isGgiltybuy-firearms",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuyFirearms,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuyFirearms",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isGgiltybuy-firearms-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuyFirearms",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasmisrepresent-identity",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasMisrepresentIdentity,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasMisrepresentIdentity",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasmisrepresent-identity-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasMisrepresentIdentity",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasconvicted-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasConvictedFelonyDrugs,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasConvictedFelonyDrugs",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasconvicted-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasConvictedFelonyDrugs",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplying-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingFelonyDrugsTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingFelonyDrugsTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplying-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingFelonyDrugsTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedSexualAbuseMurder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedSexualAbuseMurder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedSexualAbuseMurder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplyingsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingSexualAbuseMurderTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingSexualAbuseMurderTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplyingsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingSexualAbuseMurderTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isfleeing-felonparole",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isFleeingFelonParole,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isFleeingFelonParole",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isfleeing-felonparole-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isFleeingFelonParole",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedfraud-duplicate",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedFraudDuplicateAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedFraudDuplicateAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedfraud-duplicate-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedFraudDuplicateAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasreceived-lottery",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasReceivedLottery,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasReceivedLottery",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasreceived-lottery-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasReceivedLottery",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
    skipsubSectionNavigation: TextProps.VALUE_TRUE
  },
      //////////////////
      {
        //newIndex 3
        //index 2
        id: "auth-rep",
        parentId: "auth-rep",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.authRep
        ).authSidebarTitle,
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.authRep
        ).authTitle,
        completed: TextProps.VALUE_FALSE,
        selectionMade: null,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        disabled: TextProps.VALUE_FALSE,
      },
      //////////////////
      {
        //newIndex 3
        //index 2
        id: "add-auth-rep",
        parentId: "auth-rep",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.authRep
        ).authSidebarTitle, // 'My household',
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.authRep
        ).authTitle,
        completed: TextProps.VALUE_FALSE,
        selectionMade: null,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        disabled: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
      },
  {
    //newIndex 5
    id: "resources-summary",
    title: resourcesSectionMultilanguageLabels(language).resourceTitle, //'Resources',
    header: resourcesSectionMultilanguageLabels(language).resourcesSummaryHeader, //"About your household's Resources",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "combined-resources",
        title: "namePlaceholder",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "CASH",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SAVING",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHECK",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "STOCKS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BONDS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETMNT",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "TRUST",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PPDFUN",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
              }
            ],
            // [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-resources-subsection",
            title: "namePlaceholder",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
            originalQuestions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "resources-summary",
        header: resourcesSectionMultilanguageLabels(language).resourcesSummaryTitle,
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
    srApplication: TextProps.VALUE_TRUE,
  },
  {
    //newIndex 6
    //index 3
    id: "noncitizen",
    parentId: "noncitizen",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.nonCitizen
    ).title, //'Income',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.nonCitizen
    ).header, //"About your household's income",
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    active: TextProps.VALUE_TRUE,
    completed: null,
    selectionMade: null,
    hiddenFromNav:  TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 7
    //index 4
    id: "students",
    parentId: "students",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.studentStatic
    ).studentsTitle,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.studentStatic
    ).studentsHeader,
    questions: [
      {
        [Props.NAME]: "types",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.studentStatic
        ).studentsDesc,
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "1",
            [OptionsProps.OPTION_DISPLAY]: "John Smith (16.Aug.1982)",
          },
        ],
        [OptionsProps.CLEAR_OPTION]: recertificationPageLabels(
          language,
          null,
          languageConstants.studentStatic
        ).noneLabel,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 8
    id: "income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "combined-income",
        title: "namePlaceholder",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "WAGES",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SELEMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BOARD",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[2],
              },
            ],

            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            title: "namePlaceholder",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "job-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeTargetValue,
                [OptionsProps.TABLE_DSC]: "WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "job-income-0",

                  amountLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeAmountLabel,
                  employerLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeEmployerLabel,
                  unitLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeUnitLabel,
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeLabel,

                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).labelOtherJobIncome, //'Add another Wage'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Self-Employment?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfGross_Label,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).adsSelfEmployee, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "room-and-board-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).roomAndBoardIncomeTargetValue,
                [OptionsProps.TABLE_DSC]: "BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Room and Board Income?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Room and Board Income?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Room and Board Income?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Room and Board Income?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).roomAndBoardIncomeLabel,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherRoomAndBoardIncome, //'Add another Work Study'
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        // title: interimReportPageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).earnedIncomeMenuTitle, //'Income summary',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).earnedIncomeTitle, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 9
    id: "unearned-income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "unearned-combined-income",
        title: "namePlaceholder",
        header: "John Doe's (8.Aug.1967) income and benefits",
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "TAFDCS",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RSDI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SSI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "VETBEN",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UNEMPL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WRKCMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "INTINC",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MILPE",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETFND",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RRRET",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "LIHEAP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RENTAL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[11],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHSDOR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[12],
              },
              {
                [OptionsProps.OPTION_VALUE]: "ALIMNY",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[13],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONTR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[14],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[15],
              },
            ],
            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "unearned-combined-income-subsection",
            title: "namePlaceholder",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "TANF-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).TANFTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "TAFDCS",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[0],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[0],
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherTANFLabel, //'Add another SSI'
              },
              {
                [Props.NAME]: "social-security-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).rsdiTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RSDI",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[1],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[1],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addRDILabel, //'Add another RSDI'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "SSI",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[2],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[2],
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherSSILabel, //'Add another SSI'
              },
              {
                [Props.NAME]: "veterans-benefits-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).veteranBenefitsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "VETBEN",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[3],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[3],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UNEMPL",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[4],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[4],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmpCompensationLabel, //'Add another Unemployment'
              },
              {
                [Props.NAME]: "workers-compensation-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workerCompTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "WRKCMP",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[5],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[5],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Worker's Compensation?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Worker's Compensation?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Worker's Compensation?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workerCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "interest-income-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "INTINC",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[6],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[6],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "Military-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "MILPE",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[7],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[7],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "Retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RETFND",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[8],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[8],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "Railroad-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RRRET",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[9],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[9],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "hudCompensation-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "LIHEAP",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[10],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[10],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "rental-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).rentalIncomeTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RENTAL",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[11],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[11],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).landCompensationLabel, //'Add another Child Support'
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "CHSDOR",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[12],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[12],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "ALIMNY",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[13],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[13],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) pension?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these pension?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) pension?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyCompensationLabel, // 'Add another Pension'
              },
              {
                [Props.NAME]: "contributions-income-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "CONTR",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[14],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Rental Income?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Rental Income ?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Rental Income?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[14],
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionCompensationLabel,
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[15],
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unearnedIncomeoptions[15],
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [OptionsProps.VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).questionButtonLabel, //"Add another Other"
              },
            ],
          },
        ],
      },
      {
        id: "unearned-income-summary",
        // title: interimReportPageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).unEarnedIncomeMenuTitle, //'Income',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).unEarnedIncomeTitle, //"About your household's income",
        completed: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        hiddenFromNav: TextProps.VALUE_TRUE
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 3
    //index 2
    id: "tanf-household-information",
    parentId: "tanf-household-information",
    title: recertificationPageLabels(
        language,
        null,
        languageConstants.beforeYouStart
      ).addtionalTanfHouseholdInfo,
    header: recertificationPageLabels(
        language,
        null,
        languageConstants.beforeYouStart
      ).addtionalTanfHouseholdInfo,
    subHeader: recertType === "REEVAL" ? recertificationPageLabels(
      language,
      null,
      languageConstants.beforeYouStart
    ).addtionalTanfHouseholdInfoSubHeader : "",
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
    // hiddenFromNav: false
  },
  {
    //newIndex 7
    //index 5
    id: "expenses",
    parentId: "expenses",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.expensesRecert
    ).expensesTitle, //'Expenses',
    header:  recertificationPageLabels(
      language,
      null,
      languageConstants.expensesRecert
    ).expensesTitle, 
    subsections: [
      {
        id: "shelter-utility-summary",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).shelterAndUtilityMenuTitle, //'Income',
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).shelterAndUtilityHeading, //"About your household's income",
        selectionMade: null,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        subsections: [
          {
            id: "shelter-expenses-section",
            title: recertificationPageLabels(
              language,
              null,
              languageConstants.shelterAndutility
            ).shelterExpenseLabel,
            header: recertificationPageLabels(
              language,
              null,
              languageConstants.shelterAndutility
            ).shelterExpenseLabel,
            subHeader:
            recertificationPageLabels(
              language,
              null,
              languageConstants.shelterAndutility
            ).shelterSubTitle,
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: "type",
                [Props.LABEL]:{
                  [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textBefore,
                  [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.triggerText,
                  [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textAfter,
                  [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimShelterLabel.helpText
                },
                // [Props.LABEL]:
                //   incomeSectionMultilanguageLabels(language).houseQLabel, //'What type of housing cost does your household have?',
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: [
                  {
                    [OptionsProps.OPTION_VALUE]: "RENT",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[0],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "MTGPRN",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[1],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "PRPINS",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[5],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "PRPTAX",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[2],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "SECMTG",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[6],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "HOMEXP",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[7],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "HOMEEQ",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[8],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "CONFEE",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[4],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "HOASFE",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[9],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "OTHER",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .houseQOptions[10],
                  },
                ],
                [UnitsProps.CLEAR_OPTION]: incomeSectionMultilanguageLabels(language)
                .houseQOptions[11],
                // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
              },
            ],
            subsections: [
              {
                id: "shelter-expenses-subsection",
                title: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).shelterExpenseLabel,
                header: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).shelterExpenseLabel,
                subHeader:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).shelterSubTitle,
                hiddenFromNav: TextProps.VALUE_TRUE,
                completed: TextProps.VALUE_FALSE,
                questions: [
                  {
                    [Props.NAME]: "rent",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                    recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).rentTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).rentQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).rentErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherRentLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "mortgage",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).mortgageTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).mortageQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).mortageErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anothermortgageLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "property-insurance",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).propInsTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).propInsQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).propInsErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherpropInsLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "property-tax",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).propTaxTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).propTaxQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).propTaxErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherpropTaxLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "second-mortgage",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).secondMortgageTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).secMortgageQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).secondMortgageErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anothersecondMortgageLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "homeless-expense",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).homelessExpTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).homelessQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).homelessExpErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherhomelessExpLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "home-equity",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).homeEquityTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).equityLoanQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).homeEquityErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).anotherhomeEquityLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "condo-fee",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).condoTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).condoQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).condoErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).anothercondoLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "house-owner-association",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).houseOwnerTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).houseAssQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseOwnerErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherhouseOwnerLabel, //"Add another rent"
                  },
                  {
                    [Props.NAME]: "other",
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).otherTargetValue, //"Rent",
                    [Props.TYPE]: Types.REPEATABLE_QUESTION,
                    [ControlConstants.REPEATABLE_QUESTION]: {
                      [Props.LABEL]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).otherQuestion, //"How much is your household's rent?",
                      [Props.TYPE]: Types.UNITS,
                      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null
                      ).perLabel, //'per',
                      [UnitsProps.UNITS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).houseUnitOptions, //['Week', 'Month', 'Year'],

                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).otherErrMsg, // 'Please enter rent'
                    },
                    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.shelterAndutility,
                        null,
                        null,
                        null
                      ).anotherOtherLabel, //"Add another rent"
                  },
                ],
              },
              {
                id: "utility-expenses-section",
                title:  recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).utilityExpensesLabel,
                header:  recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).utilityExpensesLabel,
                subHeader:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility
                ).utilitySubTitle,
                hiddenFromNav: TextProps.VALUE_TRUE,
                completed: TextProps.VALUE_FALSE,
                questions: [
                  {
                    [Props.NAME]: "type",
                    [Props.LABEL]:{
                      [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textBefore,
                      [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.triggerText,
                      [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textAfter,
                      [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.helpText
                    },
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: [
                      {
                        [OptionsProps.OPTION_VALUE]: "heatFlag",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[0],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "acFlag",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[1],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "electricOrGasFlag",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[3],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "phoneFlag",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[4],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "payWaterSewage",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[5],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "payGarbage",
                        [OptionsProps.OPTION_DISPLAY]:
                          incomeSectionMultilanguageLabels(language)
                            .utilityQOptions[6],
                      }
                    ],
                    [UnitsProps.CLEAR_OPTION]: incomeSectionMultilanguageLabels(language).utilityQOptions[8],
                    // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .houseCostErrmsg, //'Please choose housing costs'
                  },
                  {
                    [Props.NAME]: "is-received-liheap",
                    [Props.LABEL]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.householdStatic
                    ).receivedLieapLabel,
                    [Props.TYPE]: Types.RADIO,
                    [OptionsProps.OPTIONS]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.householdStatic,
                      null,
                      null,
                      null
                    ).receivedLieapOptions,
                    [OptionsProps.OPTIONS_PER_ROW]: 2,
                  },
                ],
              },
            ],
            completed: TextProps.VALUE_FALSE,
          },
          {
            id: "shelter-utility-summary",
// title: "Shelter & Utility Expenses", //'Income',
            header: recertificationPageLabels(
              language,
              null,
              languageConstants.shelterAndutility
            ).shelterAndUtilityHeading, //"About your household's income",
            completed: TextProps.VALUE_FALSE,
            skipsubSectionNavigation: TextProps.VALUE_TRUE,
            hiddenFromNav: TextProps.VALUE_TRUE,
          },
        ],
        completed: TextProps.VALUE_FALSE,
        disabled: TextProps.VALUE_FALSE,
      },
      {
        //newIndex 7
        //index 5
        id: "Dependentcarecosts",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.dependentCareCostStatic
        ).dependentCareCostTitle, //'Expenses',
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.dependentCareCostStatic
        ).dependentCareCostHeader,
        subHeader: recertificationPageLabels(
          language,
          null,
          languageConstants.dependentCareCostStatic
        ).dependentCareCostSubHeader,
        questions: [
          {
            [Props.NAME]: "caregivers",
            [Props.LABEL]: [
              {
                [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjbefore1,
                [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjtrigger1, //'transport',
                [Props.LABEL_OBJ_AFTER]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjAfter1, //' the dependent(s) to and/or from a care provider.',
                [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveHelp1,
              },
              {
                // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
                [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjbefore2,
                [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjtrigger2, //'transport',
                [Props.LABEL_OBJ_AFTER]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveObjAfter2, //' the dependent(s) to and/or from a care provider.',
                [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).careGiveHelp2,
              },
            ],
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
            [OptionsProps.CLEAR_OPTION]: recertificationPageLabels(
              language,
              null,
              languageConstants.incomeExpensesRecert
            ).questionClearOption, // 'None',
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "John Doe (8.Aug.1967)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-1",
                    [Props.LABEL]:
                      "Who does John Doe (8.Aug.1967) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "Marty Smith (2.Jun.1994)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-2",
                    [Props.LABEL]:
                      "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
                    [OptionsProps.CLEAR_OPTION]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).questionClearOption, //'None'
                  },
                ],
              },
            ],
          },
        ],
        subsections: [
          {
            id: "kids-and-adults-marty",
            title: "namePlaceholder",
            header: "Caring for Marty Smith (2.Jun.1994)",
            subHeader: recertificationPageLabels(
              language,
              null,
              languageConstants.incomeExpensesRecert
            ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "caring-kids-adults-cost-1",
                [Props.LABEL]:
                  "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).costBetween,
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).freqTripoptions,
                [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "if-pays-transportation-1",
                [Props.LABEL]:
                  "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.incomeExpensesRecert
                      ).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "transportation-cost-1",
                        [Props.LABEL]:
                          "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                        [Props.TYPE]: Types.UNITS,
                        [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                          language,
                          null,
                          languageConstants.incomeExpensesRecert
                        ).costBetween,
                        [UnitsProps.UNITS]: recertificationPageLabels(
                          language,
                          null,
                          languageConstants.incomeExpensesRecert
                        ).freqTripoptions,
                        [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      },
                    ],
                  },
                ],
              },
              {
                [Props.NAME]: "drive-to-provider-1",
                [Props.LABEL]:
                  "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.incomeExpensesRecert
                      ).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "driving-address-1",
                        [Props.LABEL]:
                          "How many addresses, does John drive to medical appointments or the pharmacy?",
                        [Props.TYPE]: Types.NUMBERS,
                        [Props.ERROR]: TextProps.VALUE_FALSE,
                        [Props.ERROR_MESSAGE]: recertificationPageLabels(
                          language,
                          null,
                          languageConstants.incomeExpensesRecert
                        ).noofAppointment,
                      },
                    ],
                  },
                ],
              },
            ],
            subsections: [
              {
                id: "kids-adult-multiple-address",
                title: "namePlaceholder",
                header: "Caring for Marty Smith (2.Jun.1994)",
                subHeader: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).expenseSubHeader,
                //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
                hiddenFromNav: TextProps.VALUE_TRUE,
                questions: [
                  {
                    [Props.NAME]: "address",
                    [Props.LABEL]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).addressProvider, //'What is the address of the provider?',
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.ERROR_MESSAGE]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).mailingAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.aboutMe
                    ).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_COUNTY]:recertificationPageLabels(
                      language,
                      null,
                      languageConstants.aboutMe
                    ).countyErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.aboutMe
                      ).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.aboutMe
                      ).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: recertificationPageLabels(
                        language,
                        null,
                        languageConstants.aboutMe
                      ).mailingAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.aboutMe
                      ).zipCodeErrmsg,
                  },
                  {
                    [Props.NAME]: "frequency-trips-1",
                    [Props.LABEL]:
                      "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).costBetween, //'per',
                    [UnitsProps.UNITS]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).freqTripoptions, //['Week', 'Month', 'Year'],
                    [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                    [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE_LENGTH]: recertificationPageLabels(
                      language,
                      null,
                      languageConstants.incomeExpensesRecert
                    ).tripCountErrMsgLen,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
        disabled: TextProps.VALUE_FALSE,
        active: TextProps.VALUE_TRUE,
      },
      {
        id: "childsupport-expenses",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.childSupportExpense
        ).childSupportSideBar, //'Sign & submit',
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.childSupportExpense
        ).childSupportSummaryHeader, //'Sign & submit',
        completed: TextProps.VALUE_FALSE,
        selectionMade: null,
        disabled: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        subsections: [
          {
            id: "child-support-amount",
            title: "namePlaceholder",
            header: recertificationPageLabels(
              language,
              null,
              languageConstants.childSupportExpense
            ).tellusAbout, //'Tell us about your Child support costs',
            questions: [
              {
                [Props.NAME]: "child-support-cost-1",
                [Props.LABEL]:
                  "What is the child support expense for John Doe (8.Aug.1967)?",
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.childSupportExpense
                ).perLabel,
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.childSupportExpense
                ).freqTripoptions,
                [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
            ],
            completed: TextProps.VALUE_FALSE,
            isRemoved: TextProps.VALUE_FALSE,
          },
          {
            //childsupport-expenses-summary
            id: "childsupport-expenses",
            // title: recertificationPageLabels(
            //   language,
            //   null,
            //   languageConstants.childSupportExpense
            // ).earnedIncomeMenuTitle, //'Income summary',
            header: recertificationPageLabels(
              language,
              null,
              languageConstants.childSupportExpense
            ).childSupportSummaryHeader,
            completed: TextProps.VALUE_FALSE,
            hiddenFromNav: TextProps.VALUE_TRUE,
            skipsubSectionNavigation: TextProps.VALUE_TRUE,
            isRemoved: TextProps.VALUE_FALSE,
          },
        ],
      },
      {
        //index 8
        id: "medical-expenses",
        title: recertificationPageLabels(
          language,
          null,
          languageConstants.incomeExpensesRecert
        ).medicalTitle, //'Medical Costs',
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.incomeExpensesRecert
        ).medicalHeader, //"Tell us about your medical costs",
        selectionMade: null,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
        disabled: TextProps.VALUE_FALSE,
        active: TextProps.VALUE_TRUE,
      }
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "summary",
    parentId: "summary",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.incomeExpensesRecert
    ).summaryLabel,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.incomeExpensesRecert
    ).summaryLabel,
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index 9
    id: "sign-and-submit",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.incomeExpensesRecert
    ).signSubmitLabel, //'Sign & submit',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.incomeExpensesRecert
    ).signSubmitHeader, //'Sign & submit',
    subsections: [
      {
        id: "submitted",
        hiddenFromNav: TextProps.VALUE_TRUE,
        header: recertificationPageLabels(
          language,
          null,
          languageConstants.incomeExpensesRecert
        ).submitSummaryHeader, //'Sign & submit',
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
];

export default sections;

export const studentDetailsTemplate = {
  id: "students-subsection",
  title: "John Smith (16.Aug.1982)", //'Submitted',
  header: "John Smith (16.Aug.1982)'s school details", //'Your application has been submitted!',
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions: [
    {
      [Props.NAME]: "school-type",
      [Props.LABEL]:
        "Which type of school type John Doe (8.Aug.1967) is attending?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "HGHSCH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.studentStatic
          ).hschool,
        },
        {
          [OptionsProps.OPTION_VALUE]: "COLLGE",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.studentStatic
          ).college,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HGHSCH",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "school-name",
              [Props.LABEL]: "What is John Smith (16.Aug.1982)'s school?",
              [Props.TYPE]: Types.INPUT_TEXT,
              [Props.MAX_LENGTH]: 60,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
            },
            // {
            //   [Props.NAME]: "sasid-name",
            //   [Props.LABEL]:
            //     "What is the name of John Smith (16.Aug.1982)'s SASID?",
            //   [Props.TYPE]: Types.INPUT_NUMERIC_ONLY,
            //   [Props.MAX_LENGTH]: 10,
            //   [Props.REQUIRED]: TextProps.VALUE_FALSE,
            //   [Props.ERROR]: TextProps.VALUE_FALSE,
            // },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "COLLGE",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "college-name",
              [Props.LABEL]:
                "What is the name of John Smith (16.Aug.1982)'s college?",
              [Props.TYPE]: Types.INPUT_TEXT,
              [Props.MAX_LENGTH]: 60,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
    },
  ],
};

export const tanfHouseholdTemplate = {
  id: "household-member",
  parentsId: "my-household",
  title: houseHoldSectionMultilanguageLabels(language).houseMemberTitle,
  header: houseHoldSectionMultilanguageLabels(language).houseMemberHeader, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).firstErrmsg, //'Please enter first name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).firstNameErrMsgLength,
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleName, //'Please enter middle name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).middleNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).enterLastname, //'Please enter last name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).lastNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandMaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandFaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandDaugther,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseDobAlertMsg,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: houseHoldSectionMultilanguageLabels(language).genderErrmsg,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const householdTemplate = {
  id: "household-member",
  parentId: "my-household",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).houseMemberTitle,
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).houseMemberTitle, //'Your first household member is ',
  dependentId: "",
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions:  [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandMaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandFaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandDaugther,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).enterDOB,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "ethnicity",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).labelEthnicity,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "HSPLTN",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).hisPanicOption, //'Hispanic/Latino'
        },
        {
          [OptionsProps.OPTION_VALUE]: "NNHSPL",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).nonhisPanicOption, //'Non-Hispanic/Latino'
        },
      ],
      [OptionsProps.CLEAR_OPTION]:
      recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).declineToAnswerLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose ethnicity",
    },
    {
      [Props.NAME]: "race",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).raceLabel,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "AMIALN",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ASIAN",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BLCAFR",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "NTHPCI",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WHITE",
          [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[4],
        }
      ],
      [UnitsProps.CLEAR_OPTION]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).raceOptions[5],
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose race",
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "text-area",
              [Props.TYPE]: Types.INPUT_TEXT_AREA,
              [Props.MAX_LENGTH]: 250,
              [Props.MIN_LENGTH]: 3,
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
              recertificationPageLabels(
                language,
                null,
                languageConstants.householdStaticRecert
              ).otherRaceErrmsg,
             [Props.ERROR_MESSAGE_LENGTH]:
             recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).otherRaceErrMsgLength,
            },
          ],
        },
      ]
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "? ***",
        [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citizenHelp,
      },
      [Props.INLINE_HELP]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).citizenInhelp,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnQLabel,
      [Props.INLINE_HELP]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnQLabelHelp,
      [Props.TYPE]: Types.INPUT_SSN,
      [InputProps.CLEAR_LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnErrLabel,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
    },
    {
      [Props.NAME]: "buy-food",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).buyFoodLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).buyFoodOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "person-income",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).personIncomLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).personIncomeOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).addingNewMemberFootNotes(),
    },
  ],
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
  navigateToPrimaryScreen: TextProps.VALUE_TRUE,
};

export const tanfhouseholdTemplatemain = {
  id: "household-member",
  parentId: "my-household",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).houseMemberTitle,
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).additionalhouseMemberTitle, //'Your first household member is ',
  dependentId: "",
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions:  [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandMaDisplay
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandFaDisplay
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandDaugther
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).enterDOB,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
  ],
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
  navigateToPrimaryScreen: TextProps.VALUE_TRUE,
};

export const householdDisabilityTemplate = {
  id: "add-new-household",
  parentId: "my-household",
  completed: TextProps.VALUE_FALSE,
  header: interimReportPageLabels(language, languageConstants.householdStatic).houseMemberTitle ,//"Add a new Household Member",
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandMaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandFaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandDaugther,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citizenHelp,
      },
      [Props.INLINE_HELP]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).citizenInhelp,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [InputProps.CLEAR_LABEL]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: recertificationPageLabels(
        language,
        null,
        languageConstants.householdStaticRecert
      ).ssnErrLabel,
    },
  ],
};
export const dependentCareTemplate =  {
  id: "Dependentcarecosts",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.dependentCareCostStatic
  ).dependentCareCostTitle, //'Expenses',
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.dependentCareCostStatic
  ).dependentCareCostHeader,
  subHeader: recertificationPageLabels(
    language,
    null,
    languageConstants.dependentCareCostStatic
  ).dependentCareCostSubHeader,
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: [
        {
          [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjbefore1,
          [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjtrigger1, //'transport',
          [Props.LABEL_OBJ_AFTER]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjAfter1, //' the dependent(s) to and/or from a care provider.',
          [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveHelp1,
        },
        {
          // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
          [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjbefore2,
          [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjtrigger2, //'transport',
          [Props.LABEL_OBJ_AFTER]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveObjAfter2, //' the dependent(s) to and/or from a care provider.',
          [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).careGiveHelp2,
        },
      ],
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: recertificationPageLabels(
        language,
        null,
        languageConstants.incomeExpensesRecert
      ).questionClearOption, // 'None',
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]:
            "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]:
            "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).questionClearOption, //'None'
            },
          ],
        },
      ],
    },
  ],
  subsections: [
    {
      id: "kids-and-adults-marty",
      title: "namePlaceholder",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader: recertificationPageLabels(
        language,
        null,
        languageConstants.incomeExpensesRecert
      ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
            "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).costBetween,
          [UnitsProps.UNITS]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).freqTripoptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Month" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                    language,
                    null,
                    languageConstants.incomeExpensesRecert
                  ).costBetween,
                  [UnitsProps.UNITS]: recertificationPageLabels(
                    language,
                    null,
                    languageConstants.incomeExpensesRecert
                  ).freqTripoptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.incomeExpensesRecert
                ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: recertificationPageLabels(
                    language,
                    null,
                    languageConstants.incomeExpensesRecert
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      subsections: [
        {
          id: "kids-adult-multiple-address",
          title: "namePlaceholder",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).expenseSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.ERROR_MESSAGE]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).homeAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).mailingAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_CHAR]: recertificationPageLabels(
                language,
                null,
                languageConstants.aboutMe
              ).citiErrmsg,
              [Props.ERROR_MESSAGE_FOR_COUNTY]:recertificationPageLabels(
                language,
                null,
                languageConstants.aboutMe
              ).countyErrmsg,
              [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.aboutMe
                ).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.aboutMe
                ).homeAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.aboutMe
                ).mailingAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.aboutMe
                ).zipCodeErrmsg,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).costBetween, //'per',
              [UnitsProps.UNITS]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).freqTripoptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE_LENGTH]: recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).tripCountErrMsgLen,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
  active: TextProps.VALUE_TRUE,
};

export const expenseMedicalExpenseTemplate = {
  id: "medical-expenses-type",
  // title: "John Doe (8.Aug.1967)",
  header: "Medical cost for John Doe (8.Aug.1967)",
  // subHeader:
  //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
  // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions: [
    {
      [Props.NAME]: 'medicalExpenseType',
      [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpenseType,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: 'MDENT',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).doctorOrDentistOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HSPTL',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MEDCTN',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'OTCMED',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HINSPR',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MSUPPL',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicalSuppliesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MDCOSH',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'DHP',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'GLSSES',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'TRSPRT',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HHACC',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'DOG',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).serviceAnimalOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'OTHER',
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions,
        }
      ]
    }
  ],
  subsections: [
    {
      id: "medical-expenses-filter",
      // title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
            // subHeader:
            //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "MDENT",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDENT",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).doctorOrDentistOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).doctorOrDentistOptions,
          [OptionsProps.TABLE_DSC]: "MDENT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MDENT-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).doctorOrDentistOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HSPTL",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HSPTL",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions,
          [OptionsProps.TABLE_DSC]: "HSPTL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HSPTL-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MEDCTN",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MEDCTN",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions,
          [OptionsProps.TABLE_DSC]: "MEDCTN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MEDCTN-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "OTCMED",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTCMED",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions,
          [OptionsProps.TABLE_DSC]: "OTCMED",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "OTCMED-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HINSPR",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HINSPR",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions,
          [OptionsProps.TABLE_DSC]: "HINSPR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HINSPR-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MSUPPL",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MSUPPL",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicalSuppliesOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).medicalSuppliesOptions,
          [OptionsProps.TABLE_DSC]: "MSUPPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MSUPPL-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalSuppliesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MDCOSH",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDCOSH",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions,
          [OptionsProps.TABLE_DSC]: "MDCOSH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MDCOSH-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "DHP",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DHP",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions,
          [OptionsProps.TABLE_DSC]: "DHP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "DHP-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "GLSSES",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "GLSSES",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions,
          [OptionsProps.TABLE_DSC]: "GLSSES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "GLSSES-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "TRSPRT",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRSPRT",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions,
          [OptionsProps.TABLE_DSC]: "TRSPRT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "TRSPRT-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HHACC",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HHACC",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions,
          [OptionsProps.TABLE_DSC]: "HHACC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HHACC-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "DOG",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DOG",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).serviceAnimalOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).serviceAnimalOptions,
          [OptionsProps.TABLE_DSC]: "DOG",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "DOG-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).serviceAnimalOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions, 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions,
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "OTHER-medical-expenses",
            amountLabel: medicalExpenseMultilanguageLabels(language).amountLabel,
            unitLabel: medicalExpenseMultilanguageLabels(language).unitLabel,
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: medicalExpenseMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          canBeDeleted: TextProps.VALUE_TRUE,
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        }
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {id: "medical-expenses",
          // title: recertificationPageLabels(
          //   language,
          //   null,
          //   languageConstants.incomeExpensesRecert
          // ).medicalTitle, //'Medical Costs',
          header: recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).medicalHeader, //"Tell us about your medical costs",
          selectionMade: null,
          completed: TextProps.VALUE_FALSE,
        }
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
  active: TextProps.VALUE_TRUE,
};

export const medicalExpenseKeys = {
  MDENT: {
    flag:"medicalDentalFlag",
    amount:"medicalDentalAmt",
    unit:"medicalDentalFreqCd",
    code:"medDentalDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).doctorOrDentistOptions
  },
  HSPTL:{
    flag:"hospitalFlag",
    amount:"hospitalAmt",
    unit:"hospitalFreqCd",
    code:"hsptlDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions
  },
  MEDCTN:{
    flag:"medicationFlag",
    amount:"medicationAmt",
    unit:"medicationFreqCd",
    code:"medicationDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions
  },
  OTCMED:{
    flag:"otcmedFlag",
    amount:"otcmedAmt",
    unit:"otcmedFreqCd",
    code:"otcmedDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions
  },
  HINSPR:{
    flag:"healthInsuranceFlag",
    amount:"hlthInsPremAmt",
    unit:"hlthInsPremFreqCd",
    code:"healthInsDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions
  },
  MSUPPL:{
    flag:"medsupplyFlag",
    amount:"medsupplyAmt",
    unit:"medsupplyFreqCd",
    code:"medsupplyDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).medicalSuppliesOptions
  },
  MDCOSH:{
    flag:"medCoShareFlag",
    amount:"medCoShareAmt",
    unit:"medCoShareFreqCd",
    code:"medCoshareDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions
  },
  DHP:{
    flag:"dentureHearingAidFlag",
    amount:"dentureHearingAidAmt",
    unit:"dentureHearingAidFreqCd",
    code:"hearingAidDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions
  },
  GLSSES:{
    flag:"glassesFlag",
    amount:"glassesAmt",
    unit:"glassesFreqCd",
    code:"glassesDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions
  },
  TRSPRT:{
    flag:"transportFlag",
    amount:"transportAmt",
    unit:"transportFreqCd",
    code:"transportDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions
  },
  HHACC:{
    flag:"hhaccFlag",
    amount:"hhaccAmt",
    unit:"hhaccFreqCd",
    code:"hhaccDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions
  },
  DOG:{
    flag:"dogFlag",
    amount:"dogAmt",
    unit:"dogFreqCd",
    code:"dogDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).serviceAnimalOptions
  },
  OTHER:{
    flag:"otherFlag",
    amount:"otherAmt",
    unit:"otherFreqCd",
    code:"otherDataChangeCode",
    desc:incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions
  }
}

export const medicalExpenseObj = {
    "medicalDentalFlag": false,
    "hospitalFlag": false,
    "medicationFlag": false,
    "otherFlag": false,
    "medicalExpenseAmount": null,
    "medicalExpenseFrequencyCode": null,
    "providerCareAmount": null,
    "providerCareFrequencyCode": null,
    "transportationPayFlag": false,
    "transportationAmount": null,
    "transportationFrequencyCode": null,
    "drivetoApptPharmFlag": false,
    "drivetoApptPharmCount": null,
    "transportDetails": null,
    "dataChangeCode": null,            
    "otcmedFlag": false,
    "healthInsuranceFlag": false,
    "medsupplyFlag": false,
    "medCoShareFlag": false,
    "dentureHearingAidFlag": false,
    "glassesFlag": false,
    "transportFlag": false,
    "hhaccFlag": false,
    "dogFlag": false,
    "otcmedAmt": null,
    "hlthInsPremAmt": null,
    "medsupplyAmt": null,
    "medCoShareAmt": null,
    "dentureHearingAidAmt": null,
    "glassesAmt": null,
    "transportAmt": null,
    "hhaccAmt": null,
    "dogAmt": null,
    "medicalDentalAmt": null,
    "hospitalAmt": null,
    "otherAmt": null,
    "otcmedFreqCd": null,
    "hlthInsPremFreqCd": null,
    "medsupplyFreqCd": null,
    "medCoShareFreqCd": null,
    "dentureHearingAidFreqCd": null,
    "glassesFreqCd": null,
    "transportFreqCd": null,
    "hhaccFreqCd": null,
    "dogFreqCd": null,
    "medicationFreqCd": null,
    "medicalDentalFreqCd": null,
    "hospitalFreqCd": null,
    "otherFreqCd": null,
    "medicationAmt":null,
    "hsptlDataChangeCode":null,
    "medicationDataChangeCode":null,
    "otcmedDataChangeCode":null,
    "healthInsDataChangeCode":null,
    "medsupplyDataChangeCode":null,
    "medCoshareDataChangeCode":null,
    "hearingAidDataChangeCode":null,
    "glassesDataChangeCode":null,
    "transportDataChangeCode":null,
    "hhaccDataChangeCode":null,
    "dogDataChangeCode":null,
    "otherDataChangeCode":null,
    "medDentalDataChangeCode":null
}

export const houseHoldInformationTemplate = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  agencyID: "",
  isEnrolledHigherLearning: TextProps.VALUE_N,
  isAnyoneStriker: TextProps.VALUE_N,
  hasVoluntarilyQuitJob: TextProps.VALUE_N,
  isHouseholdBoarder: TextProps.VALUE_N,
  isDisqualifiedIntlPrgVltn: TextProps.VALUE_N,
  isGuiltyBuySellFoodAsst: TextProps.VALUE_N,
  isGuiltyBuyFirearms: TextProps.VALUE_N,
  hasMisrepresentIdentity: TextProps.VALUE_N,
  hasConvictedFelonyDrugs: TextProps.VALUE_N,
  isComplyingFelonyDrugsTS: TextProps.VALUE_N,
  isConvictedSexualAbuseMurder: TextProps.VALUE_N,
  isComplyingSexualAbuseMurderTS: TextProps.VALUE_N,
  isFleeingFelonParole: TextProps.VALUE_N,
  isConvictedFraudDuplicateAsst: TextProps.VALUE_N,
  hasReceivedLottery: TextProps.VALUE_N,
};

export const expenseChildSupportTemplate = {
  id: "child-support-amount",
  // title: "John Doe (8.Aug.1967)",
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.childSupportExpense
  ).tellusAbout, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "child-support-cost-1",
      [Props.LABEL]:
        "What is the child support expense for John Doe (8.Aug.1967)?",
      [Props.ADD_DELETE_AND_DONE]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.UNITS,
      [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).perLabel,
      [UnitsProps.UNITS]: recertificationPageLabels(
        language,
        null,
        languageConstants.incomeExpensesRecert
      ).freqTripoptions,
      [OptionsProps.TARGET_VALUE]: { unit: "Month" },
      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const shelterUtilitySectionTemplate = {
  id: "shelter-utility-summary",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.shelterAndutility
  ).shelterAndUtilityMenuTitle, //'Income',
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.shelterAndutility
  ).shelterAndUtilityHeading, //"About your household's income",
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  subsections: [
    {
      id: "shelter-expenses-section",
      title: recertificationPageLabels(
        language,
        null,
        languageConstants.shelterAndutility
      ).shelterExpenseLabel,
      header: recertificationPageLabels(
        language,
        null,
        languageConstants.shelterAndutility
      ).shelterExpenseLabel,
      subHeader:
      recertificationPageLabels(
        language,
        null,
        languageConstants.shelterAndutility
      ).shelterSubTitle,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "type",
          [Props.LABEL]:{
            [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textBefore,
            [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.triggerText,
            [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textAfter,
            [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimShelterLabel.helpText
          },
          // [Props.LABEL]: incomeSectionMultilanguageLabels(language).houseQLabel, //'What type of housing cost does your household have?',
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "RENT",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "MTGPRN",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "PRPINS",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[5],
            },
            {
              [OptionsProps.OPTION_VALUE]: "PRPTAX",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "SECMTG",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[6],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOMEXP",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[7],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOMEEQ",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[8],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CONFEE",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[4],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOASFE",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[9],
            },
            {
              [OptionsProps.OPTION_VALUE]: "OTHER",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[10],
            },
          ],
          [UnitsProps.CLEAR_OPTION]: incomeSectionMultilanguageLabels(language)
          .houseQOptions[11],
          // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
        },
      ],
      subsections: [
        {
          id: "shelter-expense-subsection",
          // title: "Shelter Expenses",
          header: recertificationPageLabels(
            language,
            null,
            languageConstants.shelterAndutility
          ).shelterExpenseLabel,
          subHeader:
          recertificationPageLabels(
            language,
            null,
            languageConstants.shelterAndutility
          ).shelterSubTitle,
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "rent",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).rentTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).rentQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).rentErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherRentLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "mortgage",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).mortgageTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).mortageQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).mortageErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anothermortgageLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "property-insurance",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).propInsTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propInsQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).propInsErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherpropInsLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "property-tax",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).propTaxTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propTaxQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).propTaxErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherpropTaxLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "second-mortgage",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).secondMortgageTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).secMortgageQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).secondMortgageErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anothersecondMortgageLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "homeless-expense",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).homelessExpTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homelessQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).homelessExpErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherhomelessExpLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "home-equity",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).homeEquityTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).equityLoanQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).homeEquityErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherhomeEquityLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "condo-fee",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).condoTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).condoQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).condoErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anothercondoLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "house-owner-association",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).houseOwnerTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseAssQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseOwnerErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherhouseOwnerLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "other",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility,
                null,
                null
              ).otherTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).otherQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).otherErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.shelterAndutility,
                  null,
                  null,
                  null
                ).anotherOtherLabel, //"Add another rent"
              canBeDeleted: TextProps.VALUE_TRUE,
              isInterimShelter: TextProps.VALUE_TRUE,
            },
          ],
        },
        {
          id: "utility-expenses-section",
          title:  recertificationPageLabels(
            language,
            null,
            languageConstants.shelterAndutility
          ).utilityExpensesLabel,
          header:  recertificationPageLabels(
            language,
            null,
            languageConstants.shelterAndutility
          ).utilityExpensesLabel,
          subHeader:recertificationPageLabels(
            language,
            null,
            languageConstants.shelterAndutility
          ).utilitySubTitle,
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "type",
              [Props.LABEL]:{
                [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textBefore,
                [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.triggerText,
                [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textAfter,
                [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.helpText
              },
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: [
                {
                  [OptionsProps.OPTION_VALUE]: "heatFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[0],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "acFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[1],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "electricOrGasFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[3],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "phoneFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[4],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "payWaterSewage",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[5],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "payGarbage",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[6],
                }
              ],
              [UnitsProps.CLEAR_OPTION]: incomeSectionMultilanguageLabels(language).utilityQOptions[8],
              // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
            },
            {
              [Props.NAME]: "is-received-liheap",
              [Props.LABEL]: recertificationPageLabels(
                language,
                null,
                languageConstants.householdStatic
              ).receivedLieapLabel,
              [Props.TYPE]: Types.RADIO,
              [OptionsProps.OPTIONS]: recertificationPageLabels(
                language,
                null,
                languageConstants.householdStatic,
                null,
                null,
                null
              ).receivedLieapOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
            },
            {
              [Props.NAME]: "lifeline-provides",
              [Props.LABEL]: recertificationPageLabels(
                language,
                null,
                languageConstants.householdStatic
              ).lifelineProvides,
              [Props.TYPE]: Types.RADIO,
              [OptionsProps.OPTIONS]: recertificationPageLabels(
                language,
                null,
                languageConstants.householdStatic
              ).lifelineProvidesOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
    },
    {
      id: "shelter-utility-summary",
// title: "Shelter & Utility Expenses", //'Income',
      header: recertificationPageLabels(
        language,
        null,
        languageConstants.shelterAndutility
      ).shelterAndUtilityHeading, //"About your household's income",
      completed: TextProps.VALUE_FALSE,
      skipsubSectionNavigation: TextProps.VALUE_TRUE,
      hiddenFromNav: TextProps.VALUE_TRUE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
};

export const shelterTypecode = ["RENT", "MTGPRN", "PRPINS", "PRPTAX", "SECMTG", "HOMEXP", "HOMEEQ", "CONFEE", "HOASFE", "OTHER"];

export const ResourcesSectionTemplate =   {
  id: "combined-resources",
  title: "namePlaceholder",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]: resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "CASH",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SAVING",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHECK",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "STOCKS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BONDS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETMNT",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "TRUST",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PPDFUN",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
        }
      ],
      // [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
    },
  ],
  subsections: [
    {
      id: "combined-resources-subsection",
      title: "namePlaceholder",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
      originalQuestions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const resourcesSummaryTemplate = {
  id: "resources-summary",
  // title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
  //   .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
};

export const resourcesTypecode = ["CASH", "SAVING", "CHECK", "STOCKS", "BONDS", "RETMNT", "TRUST", "PPDFUN", "OTHER"]

export const IncomeSectionTemplate = {
  id: "combined-income",
  title: "namePlaceholder",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "WAGES",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SELEMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BOARD",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[2],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      // title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "job-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeTargetValue,
          [OptionsProps.TABLE_DSC]: "WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "job-income-0",

            amountLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeAmountLabel,
            employerLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeEmployerLabel,
            unitLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeUnitLabel,
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeLabel,

            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [OptionsProps.VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).labelOtherJobIncome, //'Add another Wage'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Self-Employment?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).selfGross_Label,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [OptionsProps.VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).adsSelfEmployee, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "room-and-board-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).roomAndBoardIncomeTargetValue,
          [OptionsProps.TABLE_DSC]: "BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Room and Board Income?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Room and Board Income?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Room and Board Income?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Room and Board Income?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).roomAndBoardIncomeLabel,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [OptionsProps.VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherRoomAndBoardIncome, //'Add another Work Study'
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const AuthRepSectionTemplate = {
  id: "edit-authrep",
  title: "Name",
  subsections: [
    {
      id: "auth-rep-subsection"
    }
  ]
}

export const incomeSummaryTemplate = {
  id: "income-summary",
  // title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
  //   .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
};

export const earnedIncomeTypecode = ["WAGES", "SELEMP", "BOARD"];

export const childSupportTypecode = ["CHDSPT"];

export const unearnedIncomeSummaryTemplate =  {
  id: "unearned-income-summary",
  title: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).unEarnedIncomeMenuTitle, //'Income',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).unEarnedIncomeTitle, //"About your household's income",
  completed: TextProps.VALUE_FALSE,
  subsections: [
    {
      id: "unearned-combined-income",
      // title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "types",
          [Props.LABEL]:
            "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "TAFDCS",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RSDI",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "SSI",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "VETBEN",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[3],
            },
            {
              [OptionsProps.OPTION_VALUE]: "UNEMPL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[4],
            },
            {
              [OptionsProps.OPTION_VALUE]: "WRKCMP",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[5],
            },
            {
              [OptionsProps.OPTION_VALUE]: "INTINC",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[6],
            },
            {
              [OptionsProps.OPTION_VALUE]: "MILPE",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[7],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RETFND",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[8],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RRRET",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[9],
            },
            {
              [OptionsProps.OPTION_VALUE]: "LIHEAP",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[10],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RENTAL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[11],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CHSDOR",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[12],
            },
            {
              [OptionsProps.OPTION_VALUE]: "ALIMNY",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[13],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CONTR",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[14],
            },
            {
              [OptionsProps.OPTION_VALUE]: "OTHER",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[15],
            },
          ],

          [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).utilitiesClearOption,
        },
      ],
      subsections: [
        {
          id: "unearned-combined-income-subsection",
          // title: "John Doe (8.Aug.1967)",
          header: "John Doe's (8.Aug.1967) income and benefits",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "TANF-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).TANFTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "TAFDCS",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[0],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[0],
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherTANFLabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "social-security-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).rsdiTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RSDI",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[1],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[1],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addRDILabel, //'Add another RSDI'
            },
            {
              [Props.NAME]: "ssi-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).SSITargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "SSI",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[2],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[2],
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherSSILabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "veterans-benefits-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).veteranBenefitsTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "VETBEN",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[3],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[3],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
            },
            {
              [Props.NAME]: "unemployment-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmploymentTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "UNEMPL",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[4],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[4],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmpCompensationLabel, //'Add another Unemployment'
            },
            {
              [Props.NAME]: "workers-compensation-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workerCompTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "WRKCMP",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[5],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[5],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Worker's Compensation?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Worker's Compensation?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Worker's Compensation?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workerCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "interest-income-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "INTINC",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[6],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[6],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Military-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "MILPE",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[7],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[7],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Retirement-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RETFND",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[8],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[8],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Railroad-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RRRET",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[9],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[9],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "hudCompensation-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "LIHEAP",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[10],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[10],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "rental-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).rentalIncomeTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RENTAL",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[11],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[11],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "child-support-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSuppTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CHSDOR",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[12],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[12],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherChildsupport, //'Add another Child Support'
            },
            {
              [Props.NAME]: "alimony-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "ALIMNY",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[13],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[13],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) pension?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these pension?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) pension?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyCompensationLabel, // 'Add another Pension'
            },
            {
              [Props.NAME]: "contributions-income-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CONTR",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[14],
              [ControlConstants.REPEATABLE_QUESTION]: {
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) Rental Income?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Rental Income ?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Rental Income?",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[14],
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionCompensationLabel,
            },
            {
              [Props.NAME]: "other-income-benified-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).otherTargetValue,
              [OptionsProps.TABLE_DSC]: "OTHER",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[15],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[15],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).questionButtonLabel, //"Add another Other"
            },
          ],
        },
      ],
    },
    {
      id: "unearned-income-summary",
      // title: interimReportPageLabels(
      //   language,
      //   languageConstants.earnedIncomeStatic
      // ).unEarnedIncomeMenuTitle, //'Income',
      header: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).unEarnedIncomeTitle, //"About your household's income",
      completed: TextProps.VALUE_FALSE,
      skipsubSectionNavigation: TextProps.VALUE_TRUE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      selectionMade: null,
    },
  ],
};

export const unearnedIncomeTypecode = ["TAFDCS", "RSDI", "SSI", "VETBEN", "UNEMPL", "WRKCMP", "INTINC", "MILPE", "RETFND", "RRRET", "LIHEAP", "RENTAL", "CHSDOR", "ALIMNY", "CONTR", "OTHER"];

export const unearnedIncomeSectionTemplate = {
  id: "unearned-combined-income",
  // title: "John Doe beta (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "TAFDCS",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RSDI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SSI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "VETBEN",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UNEMPL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WRKCMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "INTINC",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MILPE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETFND",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RRRET",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "LIHEAP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RENTAL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[11],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHSDOR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[12],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ALIMNY",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[13],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONTR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[14],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[15],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "unearned-combined-income-subsection",
      // title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
            {
              [Props.NAME]: "TANF-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).TANFTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "TAFDCS",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[0],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[0],
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherTANFLabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "social-security-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).rsdiTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RSDI",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[1],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[1],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addRDILabel, //'Add another RSDI'
            },
            {
              [Props.NAME]: "ssi-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).SSITargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "SSI",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[2],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[2],
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherSSILabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "veterans-benefits-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).veteranBenefitsTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "VETBEN",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[3],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[3],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
            },
            {
              [Props.NAME]: "unemployment-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmploymentTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "UNEMPL",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[4],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[4],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmpCompensationLabel, //'Add another Unemployment'
            },
            {
              [Props.NAME]: "workers-compensation-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workerCompTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "WRKCMP",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[5],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[5],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Worker's Compensation?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Worker's Compensation?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Worker's Compensation?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workerCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "interest-income-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "INTINC",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[6],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[6],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Military-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "MILPE",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[7],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[7],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Retirement-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RETFND",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[8],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[8],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "Railroad-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RRRET",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[9],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[9],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "hudCompensation-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "LIHEAP",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[10],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[10],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "rental-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).rentalIncomeTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RENTAL",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[11],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[11],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landCompensationLabel, //'Add another Child Support'
            },
            {
              [Props.NAME]: "child-support-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSuppTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CHSDOR",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[12],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[12],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherChildsupport, //'Add another Child Support'
            },
            {
              [Props.NAME]: "alimony-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "ALIMNY",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[13],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[13],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) pension?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these pension?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) pension?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyCompensationLabel, // 'Add another Pension'
            },
            {
              [Props.NAME]: "contributions-income-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CONTR",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[14],
              [ControlConstants.REPEATABLE_QUESTION]: {
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) Rental Income?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Rental Income ?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Rental Income?",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[14],
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionCompensationLabel,
            },
            {
              [Props.NAME]: "other-income-benified-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).otherTargetValue,
              [OptionsProps.TABLE_DSC]: "OTHER",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[15],
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[15],
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
              [OptionsProps.VALUE]: [{ units: "month" }],
              [ControlConstants.REPEATABLE_QUESTION_CLASS]: "repeatableQueBlock",
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).questionButtonLabel, //"Add another Other"
            },
          ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};



export const utilityExpensesTemplate = [
  {
    key : "heatFlag",
    value : "heatDataChangeCode"
  },
  {
    key :"acFlag",
    value :"acDataChangeCode"
  },
  {
    key :"electricOrGasFlag",
    value :"electricOrGasDataChangeCode"
  },
  {
    key :"phoneFlag",
    value :"phoneDataChangeCode"
  },
  {
    key :"noUtilityFlag",
    value :"noUtilityDataChangeCode"
  },
  {
    key :"payWaterSewage",
    value :"waterSewageDataChangeCode"
  },
  {
    key :"payGarbage",
    value :"garbageDataChangeCode"
  }
]

export const utilityObj = {
  "heatFlag": false,
  "acFlag": false,
  "electricOrGasFlag": false,
  "phoneFlag": false,
  "noUtilityFlag": false,
  "payWaterSewage": false,
  "payGarbage": false,
  "liheapFlag": null,
  "lifelineFlag": null,
  "heatDataChangeCode":"NOCHNG",
  "acDataChangeCode":"NOCHNG",
  "electricOrGasDataChangeCode":"NOCHNG",
  "phoneDataChangeCode":"NOCHNG",
  "noUtilityDataChangeCode":"NOCHNG",
  "waterSewageDataChangeCode":"NOCHNG",
  "garbageDataChangeCode":"NOCHNG"
}

export const excluedSections = ["before-you-start"];

export const ethinicityCodeMapper = (language) => {
  switch (language) {
    case "en-US":
      return {
        HSPLTN: "Hispanic/Latino",
        NNHSPL: "Non-Hispanic/Latino",
        DCL: "Decline to answer",
      }
    case "es-ES":
      return {
        HSPLTN: "Hispano/Latino",
        NNHSPL: "No Hispano/Latino",
        DCL: "Negarse a contestar",
      }
    default:
      return {
        HSPLTN: "Hispanic/Latino",
        NNHSPL: "Non-Hispanic/Latino",
        DCL: "Decline to answer",
      }
    }
}

export const relationshipCodeMapper = (language) => {
  switch (language) {
    case "en-US":
      return {
        SPOUSE: "Spouse",
        MO: "Mother",
        FA: "Father",
        DAUGH: "Daughter",
        SON: "Son",
        BROTH: "Brother",
        SIST: "Sister",
        GRMOT: "GrandMother",
        GRFATH: "Grandfather",
        GRDAU: "Granddaughter",
        GRSON: "Grandson",
        OTHREL: "Other",
      }
    case "es-ES":
      return {
        SPOUSE: "Cónyuge",
        MO: "Madre",
        FA: "Padre",
        DAUGH: "Hija",
        SON: "Hijo",
        BROTH: "Hermano",
        SIST: "Hermana",
        GRMOT: "Abuela",
        GRFATH: "Abuelo",
        GRDAU: "Nieta",
        GRSON: "Nieto",
        OTHREL: "Otro",
      }
    default:
      return {
        SPOUSE: "Spouse",
        MO: "Mother",
        FA: "Father",
        DAUGH: "Daughter",
        SON: "Son",
        BROTH: "Brother",
        SIST: "Sister",
        GRMOT: "GrandMother",
        GRFATH: "Grandfather",
        GRDAU: "Granddaughter",
        GRSON: "Grandson",
        OTHREL: "Other",
      }
  }
};

export const raceCodeMapper = {
  AMIALN: "American Indian",
  NTHPCI: "Pacific Islander",
  WHITE: "White",
  BLCAFR: "Black/African American",
  ASIAN: "Asian",
  Declinetoanswer: "",
};

export const genderCodeMapper = {
  MALE: "Male",
  FEMALE: "Female",
};

export const authRepSummaryTemplate = {
  id: "auth-rep",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.authRep
  ).authRepTitle,
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.authRep
  ).authRepTitle, //AuthRep Summary
  completed: TextProps.VALUE_FALSE,
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  disabled: TextProps.VALUE_FALSE,
  subsections: [
    {
      //edit the address
      id: "edit-authrep",
      header: recertificationPageLabels(
        language,
        null,
        languageConstants.contactInfo
      ).header,
      questions: [
        {
          [Props.NAME]: "firstname",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.authRep
          ).authQuestionone || "What is authorized Representative",
          [Props.TYPE]: Types.INPUT_TEXT,
          [Props.NAME]: TextProps.VALUE_TRUE,
          Answer: {
            firstName: "Thomas Alva",
          },
          targetValue: {
            firstName: "Thomas Alva",
          },
        },
      ]  
    },
  ]
};

export const contactInfoSummaryTemplate = {
  id: "contact-info",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.contactInfo
  ).title,
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.contactInfo
  ).header,
  completed: TextProps.VALUE_FALSE,
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  disabled: TextProps.VALUE_FALSE,
  subsections: [
    {
      //edit the address
      id: "edit-contact",
      header: recertificationPageLabels(
        language,
        null,
        languageConstants.contactInfo
      ).header,
      questions: [
        {
          [Props.NAME]: "head-of-household",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).headhouseholdlabel,
          [Props.TYPE]: Types.INPUT_TEXT,
          Answer: "John Smith",
          targetValue: "John Smith",
        },
        {
          [Props.NAME]: "is-homeless",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).houselessLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ['Yes', 'No'],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: "Yes",
          targetValue: "Yes"
        },
        {
          [Props.NAME]: "address",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).homeAddressLabel || "What is your home address?",
          [Props.TYPE]: Types.ADDRESS,
          [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
          Answer: {
            line1: "Thomas st",
            city: "auburn",
            state: "MA",
            zip: "57657"
           
          },
          targetValue: {
            line1: "Thomas st",
            city: "auburn",
            state: "MA",
            zip: "57657"
           
          },
        },
        {
          [Props.NAME]: "is-also-mailing",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).alsoMailingLabel,
          [Props.TYPE]: Types.RADIO,
          Answer: "Yes",
          targetValue: "Yes",
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_YES,
              [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_YES
            },
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_NO,
              [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_NO
            },
          ],
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                TextProps.VALUE_STR_FALSE,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "mailingAddress",
                  [Props.LABEL]: recertificationPageLabels(
                    language,
                    null,
                    languageConstants.contactInfo
                  ).mailingQlabel,
                  [Props.TYPE]: Types.ADDRESS,
                  [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                  Answer: {
                    line1: "Thomas st",
                    city: "auburn",
                    state: "MA",
                    zip: "57657"
                   
                  },
                  targetValue: {
                    line1: "Thomas st",
                    city: "auburn",
                    state: "MA",
                    zip: "57657"
                   
                  },
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "phoneNumber",
          [Props.LABEL]:  recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).phoneLabel,
          [Props.TYPE]: Types.INPUT_PHONE,
          Answer: "87378373",
          targetValue: "87378373"
        },
        {
          [Props.NAME]: "phone-number-notification",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).phoneNotificationQuestion,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ['Yes', 'No'],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: ['Yes', 'No'],
          targetValue: ['Yes', 'No']
        },
        {
          [Props.NAME]: "emailAddress",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).emailEditTitle,
          [Props.TYPE]: Types.INPUT_TEXT,
          Answer: "test@gmail.com",
          targetValue: "test@gmail.com"
        },
        {
          [Props.NAME]: "email-address-notification",
          [Props.LABEL]: recertificationPageLabels(
            language,
            null,
            languageConstants.contactInfo
          ).emailNotificationQuestion,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ['Yes', 'No'],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: ['Yes', 'No'],
          targetValue: ['Yes', 'No']
        }

      ]  
    },
  ]
}

export const householdSummaryTemplate = {
  id: "my-household",
  title: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).houseMemberTitle,
  header: recertificationPageLabels(
    language,
    null,
    languageConstants.householdStaticRecert
  ).houseMemberTitle, //'Your first household member is ',
  dependentId: "",
  subsections: [{
    id: "household-member-index-template",
    title:  recertificationPageLabels(
      language,
      null,
      languageConstants.householdStaticRecert
    ).householdDetaillabel,
    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).houseFirstName, //"What is this person's first name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).firstErrmsg, //'Please enter first name'
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).houseMiddleLabel, //"What is this person's middle name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).houseMiddleName, //'Please enter middle name'
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: Names.LAST_NAME,
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).houseLastNameLabel, //"What is this person's last name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).enterLastname, //'Please enter last name'
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: Names.SUFFIX,
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).suffixLabel, //'Suffix',
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
      },
      {
        [Props.NAME]: "relationship",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).relationQlabel, // "What is this person's relationship to you?",
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "SPOUSE",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).spouseDisplay, //'Spouse'
          },
          {
            [OptionsProps.OPTION_VALUE]: "MO",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).motherDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "FA",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).faterDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "DAUGH",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).daughterDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "SON",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).sonDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "BROTH",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).brotherDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "SIST",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).sisterDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "GRMOT",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).grandMaDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "GRFATH",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).grandFaDisplay,
          },
          {
            [OptionsProps.OPTION_VALUE]: "GRDAU",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).grandDaugther,
          },
          {
            [OptionsProps.OPTION_VALUE]: "GRSON",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).grandSon,
          },
          {
            [OptionsProps.OPTION_VALUE]: "OTHREL",
            [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).otherDisplay,
          },
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).houseDobLabel, //"What is this person's date of birth?",
        [Props.TYPE]: Types.DATE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
      },
      {
        [Props.NAME]: "biological-sex",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).personGenLabel,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).genderOptios,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "ethnicity",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).labelEthnicity,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "HSPLTN",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).hisPanicOption, //'Hispanic/Latino'
          },
          {
            [OptionsProps.OPTION_VALUE]: "NNHSPL",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).nonhisPanicOption, //'Non-Hispanic/Latino'
          },
        ],
        [OptionsProps.CLEAR_OPTION]:
        recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).declineToAnswerLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "Please choose ethnicity",
      },
      {
        [Props.NAME]: "race",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).raceLabel,
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "AMIALN",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: "ASIAN",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).raceOptions[1],
          },
          {
            [OptionsProps.OPTION_VALUE]: "BLCAFR",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).raceOptions[2],
          },
          {
            [OptionsProps.OPTION_VALUE]: "NTHPCI",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).raceOptions[3],
          },
          {
            [OptionsProps.OPTION_VALUE]: "WHITE",
            [OptionsProps.OPTION_DISPLAY]:
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).raceOptions[4],
          },
          {
            [OptionsProps.OPTION_VALUE]: "declineToAnswer",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).raceOptions[5],
          },
          {
            [OptionsProps.OPTION_VALUE]: "OTHER",
            [OptionsProps.OPTION_DISPLAY]:
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).raceOptions[6],
          },
        ],
        [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "Please choose race",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "text-area",
                [Props.TYPE]: Types.INPUT_TEXT_AREA,
                [Props.MAX_LENGTH]: 250,
                [Props.MIN_LENGTH]: 3,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdStaticRecert
                ).otherRaceErrmsg,
                [Props.ERROR_MESSAGE_LENGTH]:
                recertificationPageLabels(
                 language,
                 null,
                 languageConstants.householdStaticRecert
               ).otherRaceErrMsgLength,
              },
            ],
          },
        ]
      },
      {
        [Props.NAME]: "is-citizen",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).citiBeforeQLabel,
          [Props.LABEL_OBJ_TRIGGER]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).citiTriggerQLabel,
          [Props.LABEL_OBJ_AFTER]: "?",
          [Props.LABEL_OBJ_HELP]: recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).citizenHelp,
        },
        [Props.INLINE_HELP]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citizenInhelp,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).citizenOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "ssn",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).ssnQLabel,
        [Props.TYPE]: Types.INPUT_SSN,
        [Props.CHECKED]: TextProps.VALUE_FALSE,
        [InputProps.CLEAR_LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).ssnDntLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).ssnErrLabel,
      },
      {
        [Props.NAME]: "buy-food",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).buyFoodLabel,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).buyFoodOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "person-income",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).personIncomLabel,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).personIncomeOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
    ],
  }],
  completed: TextProps.VALUE_FALSE,
};
