var homeUrl = require("../../utils/constants/appConfigConstants").homeUrl;
export const URL_PATH = homeUrl;

export const TRACKING_ID = "UA-115036897-2";
export const CLIENT_ID = "112764375";
export const DT_APPLY = "Applying_Result";
export const DP_APPLY = "/Applying_Result";
export const DT_PARTIAL_APPLY = "Partial_Applying";
export const DP_PARTIAL_APPLY = "/Partial_Applying";

export const SNAP_APPLY = "/Apply";
export const SCREENING = "Screening";
export const SCREENING_PATH = "/Screening";
export const EVENT_INPUT = "Click On Eligibilty";
export const EVENT_LABEL = "Screen Lable";

export const EVENT_ACTION_PARTIAL = "Click";
export const EVENT_CATEGORY_PARTIAL = "Save My Progress For Later";

export const ENGLISH = "Submit Application In English";
export const SPANISH = "Submit Application In Spanish";
export const PORTUGUESE = "Submit Application In Portuguese";
export const CHINESE = "Submit Application In Chinese";
export const VIETNAMESE = "Submit Application In Vietnamese";
export const HAITIANCREOLE = "Submit Application In Haitian Creole";

export const DT_AGE = "Group By Age";
export const DP_AGE = "/apply";
export const DT_DISABILITY = "Group By Disability";
export const DP_DISABILITY = "/Applying_Result";
export const DP_DEMOGRAPHICS = "/Applying_Result";
export const DT_DEMOGRAPHICS = "Group By Demographics";

export const EVENT_CATEGORY_AGE_More = "Group By Age Having More Then 60yr";
export const EVENT_CATEGORY_AGE_Less = "Group By Age Having Less Then 60yr";
export const EVENT_ACTION_AGE = "Click";
export const EVENT_CATEGORY_DISABILITY_Y = "Group By Disability Having";
export const EVENT_CATEGORY_DISABILITY_N = "Group By Disability Not Having";
export const EVENT_ACTION_DISABILITY = "Click";
export const EVENT_CATEGORY_DEMOGRAPHICS = "Group By Demographics";
export const EVENT_ACTION_DEMOGRAPHICS = "Click";
