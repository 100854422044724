import React, { Component } from "react";
import '../../css/DaysCheckbox.css';
import { consumerConst } from "../../pages/consumer/consumerText"
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions"

const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");


class DaysCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAMPM: {},
            dayObj: [],
            dayArray: [],
        };
    }

    _handleDaySelection = (e, day) => {
        let dayArray = [...this.state.dayArray];


        if (e.target.checked) {
            dayArray.push(day);
        } else {
            dayArray = dayArray.filter(selectedDay => selectedDay !== day);
        }
        this.setState({ dayArray }, () => {
            this.props.onSelectedDay(this.state.dayArray);
        });
        this.setState({ dayErrorCheck: dayArray })
    }
  

    handleAMPMChange = (e, day, period) => {
        this.setState((prevState) => {
            const { selectedAMPM } = prevState;

            return {
                selectedAMPM: {
                    ...selectedAMPM,
                    [day]: {
                        ...selectedAMPM[day],
                        [period]: e.target.checked
                    },
                },

            };

        }, () => {
            const { selectedAMPM } = this.state;
            this.props.onChange(this.state.selectedAMPM)
        });
    };

    render() {
        const { common } = this.props;
        const {
            error,
            dayValidError,
        } = this.props;
        const { selectedAMPM } = this.state;
        const days = consumerConst(selectedLanguage).days || "";
        const amLabel = consumerConst(selectedLanguage).amLabelText; 
        const pmLabel = consumerConst(selectedLanguage).pmLabelText;

        
        return (
            <div >
                {days.map((day, index) => (
                    <div key={index} className={"customSelector__container"}>
                        <div className={"customSelector__day-container"}>
                            <div className="custom-checkbox ">
                                <input
                                    type="checkbox"
                                    id={'day_' + index}
                                    key={'day_' + index}
                                    className={`checkbox-input ${dayValidError ? "checkbox-input-error" : "checkbox-input"}`}
                                    onChange={(e) => this._handleDaySelection(e, day)}

                                />
                                <label htmlFor={'day_' + index} className={`checkbox-label day-checkbox ${dayValidError ? 'checkbox-label-error checkbox-label-error:before' : 'checkbox-label'}`} >{day}</label>
                            </div>
                        </div>
                        <div className={"customSelector__am-pm-container"}>
                            <div className={"custom-checkbox"}>
                                <input
                                    type="checkbox"
                                    id={'am_' + index}
                                    key={'am_' + index}
                                    className="checkbox-input"
                                    disabled={this.state.dayArray.includes(day) ? false : true}
                                    checked={!this.state.dayArray.includes(day) ? false : null}
                                    onChange={(e) => this.handleAMPMChange(e, day, "AM")}
                                />
                                <label for={'am_' + index} className={`checkbox-label  am-checkbox ${dayValidError ? 'checkbox-label-error' : 'checkbox-label'}`} >{amLabel}</label>
                            </div>
                        </div>
                        <div className={"customSelector__am-pm-container"}>
                            <div className={"custom-checkbox"}>
                                <input
                                    type="checkbox"
                                    id={'pm_' + index}
                                    key={'pm_' + index}
                                    disabled={this.state.dayArray.includes(day) ? false : true}
                                    checked={!this.state.dayArray.includes(day) ? false : null}
                                    className="checkbox-input"
                                    onChange={(e) => this.handleAMPMChange(e, day, "PM")}

                                />
                                <label for={'pm_' + index} className={`checkbox-label  am-checkbox ${dayValidError ? 'checkbox-label-error' : 'checkbox-label'}`} >{pmLabel}</label>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        );
    }
}

export default DaysCheckbox;