import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import Radio from "../../../utils/components/radio";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import { formatMoney,formatDate } from "../../../utils/components/utilities/formatters";
import {
  setDataChangeStatusForExpense,
  setHouseholdMembers,
  setMedicalExpensesChanged,
  setChildSupportData,
} from "../../../redux/recertification/recertificationActions";
import { expenseMedicalExpenseTemplate } from "../recertificationMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import { medicalExpenseMultilanguageLabels, recertificationPageLabels } from "../recertificationText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class MedicalExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      supportSummary: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addChildSupportMembersOpen: TextProps.VALUE_FALSE,
      isObligationChildSupportChanged: TextProps.VALUE_FALSE,
      isCourtOrderedChildSupportChanged: TextProps.VALUE_FALSE,
      subsectionCleared: TextProps.VALUE_FALSE,
      memberTemplate: null,
      summaryTemplate: null,
      valuesPopulated: TextProps.VALUE_FALSE,
      tempChildExpArray: [],
      dataQuestionChanged: null,
    };
    enableUniqueIds(this);
  }

  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.recert.sections,
        respData: this.props.recert.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
  }

  toggleAddChildSupportMembers = () => {
    this.setState({
      addChildSupportMembersOpen: !this.state.addChildSupportMembersOpen,
    });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };

  onObligationChildSupportChanged = (value) => {
    this.setState({ isObligationChildSupportChanged: value });
    const medicalExpenseSectionIndex = this.filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        this.filterData(
          this.filterData(this.props.recert.sections, "expenses").subsections,
         "medical-expenses"
        )
      );
    if (
      value ===
      recertificationPageLabels(language, null, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState(
        {
          valuesPopulated: TextProps.VALUE_FALSE,
          subsectionCleared: TextProps.VALUE_FALSE,
        },
        () => {
          this.props.setMedicalExpensesChanged(
            TextProps.VALUE_FALSE
          );
          this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, medicalExpenseSectionIndex);
          this.props.setSectionDataUpdated(TextProps.VALUE_FALSE);
        }
      );
    } else {
      this.props.setMedicalExpensesChanged(TextProps.VALUE_TRUE);
      this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, medicalExpenseSectionIndex);
      this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
    }
  };

  onCourtOrderedChildSupportChanged = (value) => {
    this.setState({ isCourtOrderedChildSupportChanged: value });
    const medicalExpenseSectionIndex = this.filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.indexOf(
      this.filterData(
        this.filterData(this.props.recert.sections, "expenses").subsections,
       "medical-expenses"
      )
    );
    if (
      value ===
      recertificationPageLabels(language, null, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState(
        {
          valuesPopulated: TextProps.VALUE_FALSE,
          subsectionCleared: TextProps.VALUE_FALSE,
        },
        () => {
          this.props.setMedicalExpensesChanged(
            TextProps.VALUE_FALSE
          );
          this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, medicalExpenseSectionIndex);
          this.props.setSectionDataUpdated(TextProps.VALUE_FALSE);
        }
      );
    } else {
      this.props.setMedicalExpensesChanged(TextProps.VALUE_TRUE);
      this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, medicalExpenseSectionIndex);
      this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
    }
  };

  getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case "Week":
      case "WEEK":
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.medicalExpenses
        ).week;
        return shelterExpenseFrequency;
      case "Month":
      case "MONTH":
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.medicalExpenses
        ).month;
        return shelterExpenseFrequency;
      case "Year":
      case "ANNUAL":
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.medicalExpenses
        ).year;
        return shelterExpenseFrequency;

      default:
        break;
    }
  };

  renderSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <span
          className="interim-recert-heading pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getSummaryTitle(
                summary.name,
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.medicalExpenses
                ).sIncomebefore,
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.medicalExpenses
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </span>
        <div>
          <span className="interim-recert-heading apply__body-header help-text-fix interim-recert-heading-text-color underlined-text">
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.medicalExpenses
              ).medicalExpenses
            }
          </span>
        </div>

        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
        {this.props.recert.medicalExpensesSummaryChanged &&
        this.props.recert.medicalExpensesSummaryChanged ===
          TextProps.VALUE_TRUE ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => {
              this.props.renderMedicalExpensesSubSection(summary.name)
              this.props.prepareMedicalExpensesSummary();
            }}
          >
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}
        <ul>
          {summary.medicalExpenses.map((item,i) => {
            let label = "";
            if (item.lable !== undefined) {
              if (item.lable === "NEW") {
                label = "newLabel";
              } else if (item.lable === "CHANGE") {
                label = "changedLabel";
              }
            }
            // if (incomeItem.Answer !== undefined) {
            //   let answer = incomeItem.Answer;
            //   if (answer.unit && answer.amount) {
                return (
                  item.amount && item.unit && <li key={summary.id+"_"+i}>
                    <p className={label !== "" ? "pure-u-12-24":"pure-u-1-1"}>{item.desc}</p>
                    {label !== "" ? (
                      <p class="pure-u-12-24">
                        <DataChangeIndicator
                          text={
                            recertificationPageLabels(
                              language,
                              null,
                              languageConstants.householdStatic
                            )[label]
                          }
                        />
                      </p>
                    ) : null}
                    <span className="pure-u-12-24">
                      {item.unit ? medicalExpenseMultilanguageLabels(language).unitsOptions.filter((frq) => frq.key === item.unit)[0].value +
                        " " +
                        "Amount" : null}
                    </span>
                    <span className="pure-u-12-24">{"$" + item.amount}</span>
                  </li>
                );
            //   }
            // }
          })}
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;
    if (getFrequencyCode(language)[unit] === "WEEK") {
      amountIterator = 4;
      classRowCount = 24;
    } else if (getFrequencyCode(language)[unit] === "BIWEEK") {
      amountIterator = 2;
      classRowCount = 24;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      if (amount[i]) {
        amountRows.push(
          <div className={"pure-u-" + classRowCount + "-24"}>
            <span>
              {amount !== undefined &&
              amount[i] !== undefined &&
              amount[i] !== ""
                ? formatMoney(amount[i])
                : ""}
            </span>
          </div>
        );
      }
    }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-11-24 pure-u-sm-18-24 pure-u-md-18-24 pure-u-lg-18-24">
          <span className="label-text-clr" style={{ wordBreak: "break-word" }}>
            {getFrequency(language)[getFrequencyCode(language)[unit]]}{" "}
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.medicalExpenses
              ).amountLabel
            }
          </span>
        </div>
        <div
          className={"pure-u-8-24 pure-u-sm-6-24 pure-u-md-6-24 pure-u-lg-6-24"}
        >
          {amountRows}
        </div>
        <hr className="divider" />
      </div>
    );
  };

  returnObligationChildSupportLabel() {
    if (
      this.props.recert.obligationChildSupportChanged ===
      TextProps.VALUE_TRUE
    ) {
      return recertificationPageLabels(
        language,
        null,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else {
      if (
        this.props.recert.obligationChildSupportChanged ===
        TextProps.VALUE_FALSE
      ) {
        return recertificationPageLabels(
          language,
          null,
          languageConstants.aboutMe
        ).optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  returnCourtOrderedChildSupportLabel() {
    if (
      this.props.recert.medicalExpensesSummaryChanged ===
      TextProps.VALUE_TRUE
    ) {
      return recertificationPageLabels(
        language,
        null,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else {
      if (
        this.props.recert.medicalExpensesSummaryChanged ===
        TextProps.VALUE_FALSE
      ) {
        return recertificationPageLabels(
          language,
          null,
          languageConstants.aboutMe
        ).optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  render() {
    var listOfUpdatedHHMembers = [];
    var existingChildSupportMembers = [];
    let addChildSupportMembersActive = "";
    this.props.medicalSummary.summaryData.map((item) => {
      existingChildSupportMembers.push(item.name);
    })
    this.props.recert.householdMembers.membersList.filter(item => !(item.dataChangeCode === "removed" || item.dataChangeCode === "REMOVE")).map((memberHH) => {
      let CombinedName = [memberHH["firstName"],memberHH["lastName"],formatDate(memberHH["dateOfBirth"])].join(' ').replace(/ +(?= )/g,'');
      if(!existingChildSupportMembers.includes(CombinedName)){
        listOfUpdatedHHMembers.push(CombinedName);
      }
    });
    if (this.state.addChildSupportMembersOpen) {
      addChildSupportMembersActive = "is-active";
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {"summaryData" in this.props.medicalSummary && this.props.medicalSummary.summaryData.map((summary) =>
          this.renderSummaryItem(summary)
        )}
        {this.props.recert.medicalExpensesSummaryChanged &&
        this.props.recert.medicalExpensesSummaryChanged ===
          TextProps.VALUE_TRUE ? (
          <div>
            <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${addChildSupportMembersActive}`}
              onClick={this.toggleAddChildSupportMembers}
              aria-expanded={this.state.addChildSupportMembersOpen}
            >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.medicalExpenses
                ).medicalExpenses_AddHouseHold
              }
            </button>
            <Collapse isOpened={this.state.addChildSupportMembersOpen}>
              <span className="add-income-summary__label" />
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingChildSupportMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
                  return (
                    <li key={member.id}>
                      {/*
                      Clicking the following button routes the user to a
                      new child Income section to fill out income info for this
                      household member (e.g. "Listing John's Income"). When user
                      is finished and clicks 'Continue', they will be returned to Income Summary page
                    */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.props.renderMedicalExpensesSubSection(member);
                          this.props.prepareMedicalExpensesSummary();
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
                      >
                        <span className="sr-only">Add income for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        ) : null}
        <hr className="divider" />
        {/* <span>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.medicalExpenses
            ).medicalExpenses_Question
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.recert.sections.filter(sec => sec.id === "expenses")[0].subsections.filter((sub) => sub.id === "medical-expenses")[0].selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    recertificationPageLabels(
                      language,
                      null,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                id="earned-income"
                name="earned-income-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnObligationChildSupportLabel()}
                onChange={(value) =>
                  this.onObligationChildSupportChanged(value)
                }
              />
            </fieldset>
          </div>
        </div> 
        <br />
        <br />*/}
        <span>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.medicalExpenses
            ).medicalExpenses_Question
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.recert.sections.filter(sec => sec.id === "expenses")[0].subsections.filter((sub) => sub.id === "medical-expenses")[0].selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    recertificationPageLabels(
                      language,
                      null,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                id="medical-earnings"
                name="medical-earnings-changed"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={recertificationPageLabels(
                  language,
                  null,
                  languageConstants.aboutMe
                ).optionYesNo}
                required={TextProps.VALUE_TRUE}
                value={this.returnCourtOrderedChildSupportLabel()}
                onChange={(value) =>{
                    this.onObligationChildSupportChanged(value)
                    this.onCourtOrderedChildSupportChanged(value)
                  }
                }
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHouseholdMembers: setHouseholdMembers,
      setDataChangeStatusForExpense: setDataChangeStatusForExpense,
        setMedicalExpensesChanged: setMedicalExpensesChanged,
      setChildSupportData: setChildSupportData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalExpenses);
