import React, { Component } from "react";
import PropTypes from "prop-types";

import * as Props from "../../components/shared";
import HelpTip from "../helpTip";
import { hasHelpTip } from "../../components/utilities/controls";
import { requiredLabels } from "../../../pages/screening/screeningText";
import * as helperFunction from "../helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
/*
  Label
  -----

  # Purpose: To manage how to label a question and whether that label for a question needs
    to include hidden help text.

  # Props:
  data: Required, string if just displaying a label OR an object if this label has hidden help text
    that will be displayed in a HelpTip. See the `types.js` file for the properties that this
    object needs to have in order to display a label with hidden help text
  className: Optional, class to be applied to the container element of the label, whether that is
    a simple label or a HelpTip component
  helpTipId: Optional, a string id to pass to the HelpTip component which the HelpTip component
    will apply to the label that it generates. This id is useful to put in an `aria-labelledby`
    attribute to associate the question in the HelpTip with the question that this component wraps
  tagName: Optional, string representing a valid element name. This will be lowercase if the
    tag is a valid HTML tag and must be capitalized if it is a custom React component
  tagAttributes: Optional, an object of attributes that will be passed to the label with the custom
    tag. These attributes are NOT passed to the HelpTip.
  hasMarkup: Optional, boolean indicating if the label contains HTML markup that should be
    dangerously renderd into the DOM
  required: Optional, boolean indicating whether or not this question is required. If the question
    that this label is for is required, then this component will append '(Required)' to the end
    of the label.
  alwaysRenderLabel: Optional, boolean indicating whether or not to always render the custom-tag
    label no matter if we are displaying the HelpTip or not. Usually if the passed in data indicates
    that we need to render a HelpTip, the custom-tag label is not rendered. However, sometimes
    we want to continue to generate this label for screen readers. Therefore, we can set this prop
    to TextProps.VALUE_TRUE and also passed in an `sr-only` class via the `tagAttributes` property. See the
    Nongrouped component for an example of when this scenario might happen.

  # Example: see the Grouped and Nongrouped components for an example of how this Label
    component is used for questions in different settings
 */

class Label extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    // container
    className: PropTypes.string,
    helpTipId: PropTypes.string,
    tagName: PropTypes.string,
    tagAttributes: PropTypes.object,
    // states
    hasMarkup: PropTypes.bool,
    required: PropTypes.bool,
    alwaysRenderLabel: PropTypes.bool,
  };
  static defaultProps = {
    // container
    className: "",
    helpTipId: "",
    tagName: "span",
    // states
    hasMarkup: TextProps.VALUE_FALSE,
    required: TextProps.VALUE_FALSE,
    alwaysRenderLabel: TextProps.VALUE_FALSE,
  };

  render() {
    const {
      data,
      className,
      helpTipId,
      hasMarkup,
      required,
      tagName,
      alwaysRenderLabel,
      subLabel,
    } = this.props,
      tagAttributes = this.props.tagAttributes || {};

    if (hasHelpTip(data)) {
      if (data.length > 0) {
        return (
          <span>
            {data.map((label) => {
              return this._buildHelpTip(
                helpTipId,
                className,
                hasMarkup,
                required,
                alwaysRenderLabel,
                tagName,
                tagAttributes,
                label
              );
            })}
          </span>
        );
      } else {
        return this._buildHelpTip(
          helpTipId,
          className,
          hasMarkup,
          required,
          alwaysRenderLabel,
          tagName,
          tagAttributes,
          data
        );
      }
    } else {
      return this._buildLabel(
        tagName,
        className,
        tagAttributes,
        hasMarkup,
        required,
        data,
        subLabel
      );
    }
  }

  // Rendering
  // ---------

  _buildHelpTip(
    helpTipId,
    className,
    hasMarkup,
    required,
    alwaysRenderLabel,
    tagName,
    tagAttributes,
    data
  ) {
    const beforeText = data[Props.LABEL_OBJ_BEFORE],
      triggerText = data[Props.LABEL_OBJ_TRIGGER],
      afterText = this._markIfRequired(
        required,
        data[Props.LABEL_OBJ_AFTER] || ""
      ),
      helpTipEl = (
        <HelpTip
          className={className}
          textBefore={beforeText}
          triggerText={triggerText}
          textAfter={afterText}
          helpText={data[Props.LABEL_OBJ_HELP]}
          labelId={helpTipId}
          hasMarkup={hasMarkup}
        />
      );

    if (alwaysRenderLabel) {
      // required is TextProps.VALUE_FALSE because we've already markedIfRequired here
      const reconstructedText = beforeText + triggerText + afterText,
        labelEl = this._buildLabel(
          tagName,
          className,
          tagAttributes,
          hasMarkup,
          TextProps.VALUE_FALSE,
          reconstructedText
        );
      return (
        <div>
          {helpTipEl}
          {labelEl}
        </div>
      );
    } else {
      return helpTipEl;
    }
  }

  _buildLabel(TagName, className, tagAttributes, hasMarkup, required, data, subLabel) {
    if (hasMarkup) {
      return (
        <TagName
          className={className}
          {
          ...tagAttributes /* allow label-specific overriding of overarching className */
          }
          dangerouslySetInnerHTML={{
            __html: this._markIfRequired(required, data),
          }}
        />
      );
    } else {
      return (
        <TagName
          className={className}
          {
          ...tagAttributes /* allow label-specific overriding of overarching className */
          }
        >
          {this._markIfRequired(required, data)}
          {subLabel && (
            <div className="dta-form__label dta-form" style={{ marginTop: '15px' }}>
              {subLabel ? subLabel : null}
            </div>
          )}
        </TagName>
      );
    }
  }

  // Helpers
  // -------

  _markIfRequired(required, text) {
    if (required && text) {
      return `${text} ${requiredLabels(language)}`;
    }
    return text ? `${text}` : null;
  }
}

export default Label;
