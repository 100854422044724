import PropTypes from "prop-types";
import { ZIPCODE } from "../../utils/constants/zipCode";
import { ALL_STATES_COUNTY } from "../../utils/constants/statesCountyCode";
import { ENGLISH } from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";
import _ from "lodash";
import { aboutMeDsnapMultilanguageLabels,MyhouseholdDsnapMultilanguageLabels } from './applyDsnapText';

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select") {
  language = ENGLISH;
}

let applicationType = sessionStorage.getItem("applicationType");

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId] === undefined) {
      if (question.Answer === undefined) {
        question.Answer = "";
        question.targetValue = "";
      }
    } else if (
      AnswerObj[activeId][question.name] !== undefined ||
      (question.conditionals &&
        AnswerObj[activeId][
        question.conditionals[0].conditionalQuestions[0].name
        ])
    ) {
      if (AnswerObj[activeId][question.name] !== undefined) {
        question.Answer = AnswerObj[activeId][question.name];
        question.targetValue = AnswerObj[activeId][question.name];
      }
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
          });
        });
      }
    } else {
      if (
        question.Answer === undefined ||
        (AnswerObj[activeId][question.name] === undefined &&
          question.name === "dateOfBirth")
      ) {
        question.Answer = "";
        question.targetValue = "";
      }
    }
  });
  return section;
}


export function toValidateInputErros(sections, activeId) {
  let forceInvalid = TextProps.VALUE_FALSE;
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if(activeId === "before-you-start"){
        buildApplyBeforeYouStartValidation(inputSections);
      } 
      else if (activeId === "head-of-household-info") {
        buildApplyHouseHoldValidations(inputSections);
      } else if (activeId === "household-situation") {
        buildApplyHouseHoldSitutationValidations(inputSections);
      } else if (activeId === "my-household") {
        buildApplyMyHouseValidations(inputSections);
      } else if (activeId.substring(0, 16) === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId === "combined-resources") {
        buildCombinedResourcesSelectValidations(inputSections)
      } else if (activeId === "combined-expenses") {
        buildCombinedExpensesSelectValidations(inputSections)
      } else if (activeId.includes("combined-income-subsection")) {
        buildIncomeValidationsSubsection(inputSections)
      }
    }


    // total required fields selected or not
    const totalReqFields = [];
    if (sections !== undefined) {
      const dataID = sections.id;
      const dataSections = sections.questions;
      for (var j = 0; j < dataSections.length; j++) {
        var answerNotSelected = dataSections[j];
        if (
          (answerNotSelected.type === "inputAlphaText" || answerNotSelected.type === "specialText") &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "inputPhone" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "inputText" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "") &&
          answerNotSelected.name === "emailAddress"
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "address" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "") &&
            answerNotSelected.name === "address"
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }else if (
          answerNotSelected.type === "radio" &&
          answerNotSelected.name !== "food-benefits" &&
          answerNotSelected.name !== "food-assistance"  &&
          answerNotSelected.name !== "is-Authorized" &&
          answerNotSelected.name !== "is-also-mailing" &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "checkbox" &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "inputDollar" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }
        else if (
          answerNotSelected.type === "numbers" &&
            answerNotSelected.Answer !== ""

        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }
        else if (
          answerNotSelected.type === "date" &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        } else if (
          answerNotSelected.type === "repeatableQuestion"
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }
        else if ( answerNotSelected.name === "food-benefits" || answerNotSelected.name === "food-assistance"){
            totalReqFields.push(answerNotSelected.Answer);
        }else if (
          answerNotSelected.name === "before-you-start-county" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "")

        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }
        else if ( answerNotSelected.name === "is-Authorized" && answerNotSelected.Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[1]){
          totalReqFields.push(answerNotSelected.Answer);
        }
        else if ( answerNotSelected.name === "is-Authorized" && answerNotSelected.Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0]){
          if(
             answerNotSelected.conditionals[0].conditionalQuestions[1].error === TextProps.VALUE_TRUE )
          {
            totalReqFields.push(answerNotSelected.Answer);
          }
        }
        else if (answerNotSelected.name === "is-also-mailing" && answerNotSelected.Answer === "false" ){

         if( answerNotSelected.conditionals[0].conditionalQuestions[0].type === "address" &&
          answerNotSelected.conditionals[0].conditionalQuestions[0].error === TextProps.VALUE_TRUE &&
           answerNotSelected.conditionals[0].conditionalQuestions[0].name === "mailingAddress"){
            totalReqFields.push(answerNotSelected.Answer);
          }
        }
        else if ( answerNotSelected.name === "is-also-mailing" && answerNotSelected.Answer === "true"){
          totalReqFields.push(answerNotSelected.Answer);
        }else if (
          answerNotSelected.type === "inputAlphanumericText" &&
          (answerNotSelected.error === TextProps.VALUE_TRUE ||
            answerNotSelected.Answer !== "") &&
          answerNotSelected.name === "license"
        ) {
          totalReqFields.push(answerNotSelected.Answer);
        }
        }
    }

    // total required fields questions answers has provide or not
    const totalAnswers = [];
    if (sections !== undefined) {
      const dataID = sections.id;
      const dataSections = sections.questions;
      for (var b = 0; b < dataSections.length; b++) {
        var answerSelected = dataSections[b];
        if (
          answerSelected.Answer !== "" &&
          answerSelected.type !== "checkbox" &&
          answerSelected.type !== "radio" &&
          answerSelected.isRequired === TextProps.VALUE_TRUE &&
          dataSections[b].type !== "address" &&
          answerSelected.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        } else if (
          (answerSelected.type === "inputPhone") &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        } else if (
          answerSelected.type === "address" &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE &&
          answerSelected.name === "address"
        ) {
          totalAnswers.push(answerSelected.Answer);
        } 
        else if (
          answerSelected.type === "radio" &&
          answerSelected.name !== "food-benefits" &&
          answerSelected.name !== "food-assistance"  &&
          answerSelected.name !== "is-Authorized"&&
          answerSelected.name !== "is-also-mailing"&&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          answerSelected.error === TextProps.VALUE_FALSE &&
          answerSelected.Answer !== ""
        ) {
          totalAnswers.push(answerSelected.Answer);
        }else if (
          answerSelected.type === "inputText" &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE &&
          answerSelected.name === "emailAddress"
        ) {
          totalAnswers.push(answerSelected.Answer);
        } else if (
          answerSelected.type === "checkbox" &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        } else if (
          (answerSelected.type === "inputDollar") &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        }
        else if (
          answerSelected.type === "numbers" &&
          answerSelected.Answer !== ""
        ) {
          totalAnswers.push(answerSelected.Answer);
        }
        else if (
          answerSelected.type === "repeatableQuestion" &&
          answerSelected.repeatedQuestion.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        }
        else if ( answerSelected.name === "food-benefits" || answerSelected.name === "food-assistance"){
          if(answerSelected.Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[1]){
            totalAnswers.push(answerSelected.Answer);
          }else if(answerSelected.Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0] && answerSelected.conditionals[0].conditionalQuestions[0].error !== TextProps.VALUE_TRUE){
            totalAnswers.push(answerSelected.Answer);
          }
        }
        else if (
          answerSelected.name === "before-you-start-county" &&
          answerNotSelected.isRequired === TextProps.VALUE_TRUE &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE
        ) {
          totalAnswers.push(answerSelected.Answer);
        }
        else if ( answerSelected.name === "is-Authorized" && answerSelected.Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[1]){
          totalAnswers.push(answerSelected.Answer);
        }
        else if ( answerSelected.name === "is-also-mailing" && answerSelected.Answer === "true"){
          totalAnswers.push(answerSelected.Answer);
        }else if (
          answerSelected.type === "inputAlphanumericText" &&
          answerSelected.Answer !== "" &&
          answerSelected.error === TextProps.VALUE_FALSE &&
          answerSelected.name === "license"
        ) {
          totalAnswers.push(answerSelected.Answer);
        }
    }
  }
     // checking required fields and total touched fields
    if (
      forceInvalid ||
      (sections !== undefined && totalReqFields.length !== totalAnswers.length)
    ) {
      return TextProps.VALUE_FALSE;
    } else {
      return TextProps.VALUE_TRUE;
    }
  }
}

function buildApplyBeforeYouStartValidation(dataSections){
if (dataSections !== undefined) {
  const questionsObj = dataSections[0];
  if (questionsObj.name === "before-you-start-county") {
    if (
      (questionsObj.Answer === "") &&
      questionsObj.isRequired === TextProps.VALUE_TRUE
    ) {
      questionsObj.error = TextProps.VALUE_TRUE;

    } else {
      questionsObj.error = TextProps.VALUE_FALSE;
    }
  }
}
}


function buildApplyHouseHoldValidations(dataSections) {
  const resZipCode = dataSections.find((obj) => obj.name === "address").Answer
    .zipCode;
  const alabamaResZipCode = ZIPCODE.find((obj) => obj.ZIPCODE === resZipCode);
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (
        dataSections[k].type === "inputText" ||
        dataSections[k].type === "inputAlphaText" ||
        dataSections[k].type === "specialText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          dataSections[k].Answer.length >= 0 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(dataSections[k].Answer) ===
            TextProps.VALUE_TRUE ||
            rexAnySpace.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          dataSections[k].name === "lastName" &&
          dataSections[k].Answer.length > 20
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          dataSections[k].name !== "lastName" &&
          dataSections[k].Answer.length > 15
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
      } else if (
        dataSections[k].type === "inputPhone" &&
        dataSections[k].Answer.replace(/[^0-9]/g, "").length > 0 &&
        dataSections[k].Answer.replace(/[^0-9]/g, "").length < 10
      ) {
        dataSections[k].error = TextProps.VALUE_TRUE;
      } 
      else if (
        dataSections[k].name === "is-Authorized" &&
        dataSections[k].Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0])if(
          dataSections[k].conditionals[0].conditionalQuestions[1].Answer.replace(/[^0-9]/g, "").length > 0 &&
          dataSections[k].conditionals[0].conditionalQuestions[1].Answer.replace(/[^0-9]/g, "").length < 10
      ) {
        dataSections[k].conditionals[0].conditionalQuestions[1].error = TextProps.VALUE_TRUE;
      }if (
        dataSections[k].type === "inputAlphanumericText" &&
        dataSections[k].name === "license" &&
        dataSections[k].Answer.length > 0 &&
        dataSections[k].Answer.length < 13
      ) {
        dataSections[k].error = TextProps.VALUE_TRUE;
      } if (dataSections[k].type === "inputText") {
        if (
          dataSections[k].Answer.length > 0 ||
          dataSections[k].Answer !== "" ||
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          var emailRegEx =
            /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if (
            dataSections[k].Answer.length &&
            emailRegEx.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "text" &&
        dataSections[k].name === "emailAddress" &&
        dataSections[k].Answer !== undefined
      ) {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].name === "address") {
        // The below condition is when user is household homeless
        let poBoxRegEx = /\bP\.?\s*O\.?\s*Box\b/i;
        if (
          !(
            filterDataByName(dataSections, "is-homeless").Answer ===
            aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0] &&
            filterDataByName(dataSections, "is-also-mailing").Answer === TextProps.VALUE_STR_FALSE
          ) && JSON.stringify(filterDataByName(dataSections, "do-not-have-address").Answer) !== JSON.stringify(aboutMeDsnapMultilanguageLabels(language).yesOption)
        ) {
          if (
            dataSections[k].Answer === "" ||
            dataSections[k].Answer.street === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            dataSections[k].addressLine1 === "" ||
            dataSections[k].Answer.addressLine1 === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            poBoxRegEx.test(dataSections[k].addressLine1) ===
              TextProps.VALUE_TRUE ||
            poBoxRegEx.test(dataSections[k].Answer.addressLine1) ===
              TextProps.VALUE_TRUE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].poBoxErrmsg;
          } else if (
            dataSections[k].Answer.zipCode === "" ||
            (dataSections[k].Answer.zipCode &&
              dataSections[k].Answer.zipCode.length < 5) ||
            !alabamaResZipCode
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].zipCodeErrmsg;
          } else if (dataSections[k].Answer.city === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          } else if (dataSections[k].Answer.county === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].countyErrmsg;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }

       
      }
      let isAlsoMailingAddIndex = dataSections.findIndex(
        (i) => i.name === "is-also-mailing"
      );

      if (dataSections[k].type === "radio" && k === isAlsoMailingAddIndex) {
        var mailingAddressObj = {};
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        if (dataSections[k].Answer === TextProps.VALUE_STR_FALSE && JSON.stringify(filterDataByName(dataSections, "do-not-have-address").Answer) !== JSON.stringify(aboutMeDsnapMultilanguageLabels(language).yesOption)) {
          mailingAddressObj =
            dataSections[k].conditionals[0].conditionalQuestions[0];
          let alabamaMailingZipCodeValidated = TextProps.VALUE_TRUE;
          if (mailingAddressObj.Answer && mailingAddressObj.Answer.state && mailingAddressObj.Answer.state === TextProps.DEFAULT_STATE) {
            let alabamaMailingZipCode = ZIPCODE.filter(
              (obj) => obj.ZIPCODE === mailingAddressObj.Answer.zipCode
            );
            alabamaMailingZipCodeValidated = alabamaMailingZipCode.length
              ? TextProps.VALUE_TRUE
              : TextProps.VALUE_FALSE;
          } else {
            alabamaMailingZipCodeValidated = TextProps.VALUE_TRUE;
          }
          if (mailingAddressObj.Answer) {
            if (
              mailingAddressObj.Answer === "" ||
              mailingAddressObj.Answer.street === ""
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
            } else if (
              mailingAddressObj.addressLine1 === "" ||
              mailingAddressObj.Answer.addressLine1 === ""
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
            } else if (
              mailingAddressObj.Answer.zipCode === "" ||
              (mailingAddressObj.Answer.zipCode &&
                mailingAddressObj.Answer.zipCode.length < 5) ||
              !alabamaMailingZipCodeValidated
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.zipCodeErrmsg;
            } else if (mailingAddressObj.Answer.city === "") {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.citiErrmsg;
            } else if (mailingAddressObj.Answer.county === "") {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.countyErrmsg;
            } else {
              mailingAddressObj.error = TextProps.VALUE_FALSE;
            }
          } else {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
          }
        }
      }
      }
  }
}


function buildApplyHouseHoldSitutationValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "radio") {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
      if (
        dataSections[k].name === "food-benefits"&& dataSections[k].Answer ===  aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0]
      ) {
        if (
          dataSections[k].conditionals[0].conditionalQuestions[0].Answer === "" ||
          dataSections[k].conditionals[0].conditionalQuestions[0].Answer.county ===""     

        ) {          
          dataSections[k].conditionals[0].conditionalQuestions[0].error =
            TextProps.VALUE_TRUE;
          } else {         
          dataSections[k].conditionals[0].conditionalQuestions[0].error =
            TextProps.VALUE_FALSE;
        }
      }
    
    if (
      dataSections[k].name === "food-assistance" && dataSections[k].Answer ===  aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0]
      
    ) {
      if (
        dataSections[k].conditionals[0].conditionalQuestions[0].Answer === "" ||
        dataSections[k].conditionals[0].conditionalQuestions[0].Answer.county ===""     

      ) {          
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_TRUE;
        } else {         
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
      }
    }
  }
  }
}

function buildApplyMyHouseValidations(dataSections) {
  if (dataSections !== undefined) {
    const questionsObj = dataSections[0];
    if (questionsObj.type === "numbers") {
      if (
        (questionsObj.Answer < 1 || questionsObj.Answer === "") &&
        questionsObj.isRequired === TextProps.VALUE_TRUE
      ) {
        questionsObj.error = TextProps.VALUE_TRUE;

      } else {
        questionsObj.error = TextProps.VALUE_FALSE;
      }
    }
  }
}
function buildApplyHouseholdMembersValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "select") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].Answer !== null &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "inputText" ||
        dataSections[k].type === "inputAlphaText" ||
        dataSections[k].type === "specialText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexNumber = /\d+/;
        var rexSpace = /^(?=.*\S).+$/;
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          dataSections[k].Answer.length >= 1 &&
          (rexNumber.test(dataSections[k].Answer) === TextProps.VALUE_TRUE ||
            rexSpace.test(dataSections[k].Answer) === TextProps.VALUE_FALSE)
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "date") {
        if (PropTypes.date_error === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer === "" || dataSections[k].Answer === null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer !== "" || dataSections[k].Answer !== null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          var input = dataSections[k].Answer;
          var currentDate = new Date();
          let inputDate = new Date(input);
          if (inputDate.getTime() > currentDate.getTime()) {
            dataSections[k].error = TextProps.VALUE_TRUE;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputSSN") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (PropTypes.ssnError === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "radio") {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildCombinedResourcesSelectValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "inputDollar") {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildCombinedExpensesSelectValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "inputDollar") {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildIncomeValidationsSubsection(dataSections) {
  if (dataSections !== undefined) {
    // To filter only required fields
    const incomeHoursRequired = dataSections
      .filter((obj) => obj.name !== "rental-income-hours-worked-0")
      .map((obj) => obj.Answer);
    const income = [];
    const incomeHoursRequiredNew = income.concat(...incomeHoursRequired);
    const incomeColumn = incomeHoursRequiredNew.find(
      (obj) => obj.amount === ""
    );

    if (incomeColumn !== undefined) {
      dataSections.map((obj) => {
        if (obj.Answer.find((x) => x.amount === "")) {
          obj.repeatedQuestion.error = TextProps.VALUE_TRUE;
          if (obj.name.includes("rental-income-hours-worked")) {
            obj.repeatedQuestion.error = TextProps.VALUE_FALSE;
          }
        } else {
          obj.repeatedQuestion.error = TextProps.VALUE_FALSE;
        }
      });
      return TextProps.VALUE_TRUE;
    } else {
      dataSections.map(
        (obj) => (obj.repeatedQuestion.error = TextProps.VALUE_FALSE)
      );
      return TextProps.VALUE_FALSE;
    }
  }
}

export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions
) {
  let selectedQuestions = [];
  for (
    var answerIndex = 0;
    answerIndex < activePageAnswers.length;
    answerIndex++
  ) {
    for (
      var questionIndex = 0;
      questionIndex < nextPageQuestions.length;
      questionIndex++
    ) {
      if (
        nextPageQuestions[questionIndex] &&
        activePageAnswers[answerIndex] ===
        nextPageQuestions[questionIndex].valueToMatch
      ) {
        selectedQuestions.push(nextPageQuestions[questionIndex]);
      }
    }
  }
  return selectedQuestions;
}

export function nameWithoutWhiteSpace(name) {
  return name.replace(/\s/g, "");
}
export function deleteHouseholdMems(
  sections,
  confirmedHouseholdMemForDeletion
) {
  let updatedHouseholdMems =

    Number(filterData(sections, "my-household")
      .questions[0].Answer) -
    confirmedHouseholdMemForDeletion.length;
  filterData(sections, "my-household")
    .questions[0].Answer = updatedHouseholdMems.toString();
  filterData(sections, "my-household")
    .questions[0].targetValue = updatedHouseholdMems.toString();
  confirmedHouseholdMemForDeletion.map((memDeleted) => {
    let deletedMem = nameWithoutWhiteSpace(memDeleted);
    //Delete household members
    if (filterData(sections, "my-household")
      .completed) {
      filterData(sections, "my-household")
        .subsections = filterData(sections, "my-household")
          .subsections.filter(
        (deleteHousehold) =>
          nameWithoutWhiteSpace(deleteHousehold.title) !== deletedMem
      );
    }

    //Delete Income member
  if (filterData(sections, "income")) {
    filterData(sections, "income").questions[0].options = filterData(sections, "income").questions[0].options.filter(
          (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
        );

      let answeredOptions = [];
    let valuExists = filterData(sections, "income").questions[0].options.filter(
     
      (opt) => !MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel.includes(opt.optionValue)
          );
          if (valuExists.length) {
        valuExists.forEach(opt => answeredOptions.push(opt.optionValue));
      }

    filterData(sections, "income").questions[0].Answer = answeredOptions;
    filterData(sections, "income").questions[0].targetValue = answeredOptions;

    filterData(sections, "income").subsections = filterData(sections, "income").subsections.filter(
        (sub) => sub && nameWithoutWhiteSpace(sub.title) !== deletedMem
    );
    }
  });
  return sections;
}

function createAddressObj(addressObj, addrType) {
  return {
    line1: addressObj.addressLine1 ? addressObj.addressLine1 : "",
    line2: addressObj.addressLine2 ? addressObj.addressLine2 : "",
    city: addressObj.city ? addressObj.city : "",
    state: addressObj.state ? addressObj.state : "",
    zipCode: addressObj.zipCode ? addressObj.zipCode : "",
    county: addressObj.county ? addressObj.county : "",
    countyCode: addressObj.countyCode ? addressObj.countyCode : "",
    verified: addressObj.verified ? addressObj.verified : TextProps.VALUE_N,
    type: addrType,
  };
}
function validateEnteredAddress(addressObj){
  let validAddress = TextProps.VALUE_TRUE;
  if (
    addressObj === "" ||
    addressObj.street === ""
  ) {
    validAddress = TextProps.VALUE_FALSE;
  } else if (
    addressObj.addressLine1 === "" ||
    addressObj.addressLine1 === ""
  ) {
    validAddress = TextProps.VALUE_FALSE;
  } else if (
    addressObj.zipCode === "" ||
    (addressObj.zipCode &&
      addressObj.zipCode.length < 5) 
  ) {
    validAddress = TextProps.VALUE_FALSE;
  } else if (addressObj.city === "") {
    validAddress = TextProps.VALUE_FALSE;
  } else if (addressObj.county === "") {
    validAddress = TextProps.VALUE_FALSE;
  } else {
    validAddress = TextProps.VALUE_TRUE;
  }
  return validAddress
}
export function addressValidationObj(questions) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0].Answer;
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0].Answer;
  let temporaryAddress = questions.filter(
    (addr) => addr.name === "temporary"
  )[0].Answer;
  let addresses = [];
  if (residentialAddress && validateEnteredAddress(residentialAddress)) {
    addresses.push(createAddressObj(residentialAddress, TextProps.VALUE_RES));
  }
  if (mailingAddress && validateEnteredAddress(mailingAddress)) {
    addresses.push(createAddressObj(mailingAddress, TextProps.VALUE_MAIL));
  }
  if (temporaryAddress && validateEnteredAddress(temporaryAddress)) {
    addresses.push(createAddressObj(temporaryAddress, TextProps.VALUE_TEMP));
  }
  return addresses;
}
export function formAddressDisplayValue(addressArray) {
  addressArray.filter((answer) => {
    let zipCode = answer.zipCode ? answer.zipCode : answer.zip;
    let line2 = answer.line2 ? answer.line2 : "";
    let county = answer.county ? "County: " + answer.county : "";
    answer.formattedAddress =
      answer.line1 +
      " " +
      line2 +
      " " +
      answer.city +
      " " +
      answer.state +
      " " +
      zipCode +
      "\n" +
      county +
      " ";
    answer.verified = answer.verified ? answer.verified : TextProps.VALUE_N;
  });
  return addressArray;
}
export function formatAddressDisplay(addressArray) {
  let formattedAddressToDisplay = [];
  addressArray.map((address) => {
    formattedAddressToDisplay.push(address.formattedAddress);
  });
  return formattedAddressToDisplay;
}
function mapAddress(enteredAddrs, selectedAddrs) {
  let result = _.isEqual(enteredAddrs, selectedAddrs);
  let zip = selectedAddrs.zip ? selectedAddrs.zip : selectedAddrs.zipCode;
  if (!result) {
    enteredAddrs.addressLine1 = selectedAddrs.line1;
    enteredAddrs.addressLine2 = selectedAddrs.line2 ? selectedAddrs.line2 : "";
    enteredAddrs.city = selectedAddrs.city;
    enteredAddrs.county = selectedAddrs.county
      ? selectedAddrs.county
      : selectedAddrs.county;
    enteredAddrs.countyCode = selectedAddrs.countyCode
      ? selectedAddrs.countyCode
      : "";
    enteredAddrs.state = selectedAddrs.state;
    enteredAddrs.verified = selectedAddrs.verified;
    enteredAddrs.zipCode = zip;
  }
  return enteredAddrs;
}
export function appendUpdatedAdrress(
  questions,
  selectedResAddress,
  selectedMailingAddress,
  selectedTempAddress
) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0];
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0];
  let temporaryAddress = questions.filter(
    (addr) => addr.name === "temporary"
  )[0];
  if (residentialAddress.Answer && selectedResAddress) {
    residentialAddress.Answer = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
    residentialAddress.targetValue = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
  }
  if (mailingAddress.Answer && selectedMailingAddress) {
    mailingAddress.Answer = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
    mailingAddress.targetValue = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
  }
  if (temporaryAddress.Answer && selectedTempAddress) {
    temporaryAddress.Answer = mapAddress(
      temporaryAddress.Answer,
      selectedTempAddress
    );
    temporaryAddress.targetValue = mapAddress(
      temporaryAddress.Answer,
      selectedTempAddress
    );
  }
  return questions;
}
export function checkIfAddressIsUpdated(addr1, addr2) {
  let result = _.isEqual(addr1, addr2);
  return result ? TextProps.VALUE_FALSE : TextProps.VALUE_TRUE;
}
export function filterData(section, id) {
  return section.filter((sec) => sec.id === id)[0];
};
export function filterDataByName(section, name) {
  return section.filter((sec) => sec.name === name)[0];
};