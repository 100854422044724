/*
  Constants specific to the TEXT non-question type. See the `types.js` file containing
  all of the possible types for full documentation of what properties
  are allowed for each type in the questions data file.
 */

// Required
export const VALUE = "value";

// Yes/No Constant
export const VALUE_Y = "Y";
export const VALUE_N = "N";
export const VALUE_D = "D";
export const VALUE_YES = "Yes";
export const VALUE_NO = "No";
export const VALUE_TRUE = true;
export const VALUE_FALSE = false;
export const VALUE_STR_TRUE = "true";
export const VALUE_STR_FALSE = "false";
export const DTA_EXACT_MATCH = "DTAEXACTMATCH";
export const DESE_EXACT_MATCH = "DESEEXACTMATCH";
export const PARTIAL_MATCH = "PARTIALMATCH";
export const NO_MATCH = "NOMATCH";
export const MATCH_SNAP = "SNAP";
export const MATCH_TANF = "TANF";
export const MATCH_COMBO = "COMBO";
export const VALUE_RES = "RES";
export const VALUE_MAIL = "MAIL";
export const VALUE_TEMP = "TEMP";
export const VALUE_APP_INPROGRESS = "inprogress";
export const VALUE_APP_SAVED = "saved";
export const VALUE_APP_EXIT = "exit";
export const MATCH_DSNAP = "DSNAP";

//All buttons
export const SAVECONTINE = "saveContinue";
export const SAVEEXIT = "saveExit";
export const REMOVE = "remove";
export const DONE = "done";
export const ACES = "aces";
export const SUBMIT = "submit";
export const RESET = "reset";
export const BACK = "back";
export const SIGNSUBMIT = "signSubmit";
export const CONTINUE = "continue";

//change the locaation
export const HouseHold_Check = "household-details";
export const HouseHold_Check_String = "household";

//Default State
export const DEFAULT_STATE = "AL";

//application type codes
export const SNAP_APP_TYPE_CODE = "SNPAPP";
export const TANF_APP_TYPE_CODE = "TANF";
export const SNAP_INTERM_REPORT_TYPE_CODE = "SNAPIR";
export const SNAP_RECERT_TYPE_CODE = "SNPRCT";


