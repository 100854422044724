import axios from "axios";
import { getAudit } from "../../utils/constants/audit";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerDocumentUploadFailed: "GET_CONSUMERUPLOAD_FAILED",
  getConsumerDocumentUploadFulfilled: "GET_CONSUMERUPLOAD_FULFILLED",
};

//Common Fetch Functions
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

//Upload BASE64 Service
export function uploadBase64(fileInfo) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(config.TigerContextURL + "/documents", fileInfo, TigerOptions)
      .then(checkStatus)
      .then(function (data) {
        dispatch(getUploadFulfilledAction(data));
      })
      .catch(function (error) {
        dispatch(getUploadFailedAction());
      });
  };
}

function getUploadFailedAction() {
  return {
    type: actionTypes.getConsumerDocumentUploadFailed,
  };
}

function getUploadFulfilledAction(constantStrings) {
  return {
    type: actionTypes.getConsumerDocumentUploadFulfilled,
    payload: constantStrings,
  };
}
