import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import * as Types from "../../../utils/constants/types";
import * as formatters from "../../../utils/components/utilities/formatters";
import Radio from "../../../utils/components/radio";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import { formatDate, formatMoney } from "../../../utils/components/utilities/formatters";
import { setDataChangeStatus, appendIRIncomeDetails, clearMemberIncome, setAuthRepChangeStatus, setHouseholdMembers, setRecertDataChangeStatus } from "../../../redux/recertification/recertificationActions";
// import { IncomeSectionTemplate } from "../recertInterimReportMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { recertificationPageLabels } from "../recertificationText";
import DTAInput from "../../../utils/components/dtaInput";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class AuthRep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      isAuthRepChanged: TextProps.VALUE_FALSE,
      sections: null,
      respData: null,
      submitFormId: null,
    };
    enableUniqueIds(this);
  }

  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.recert.sections,
        respData: this.props.recert.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
  }


  toggleAddIncomeMembers = () => {
    this.setState({ addIncomeMembersOpen: !this.state.addIncomeMembersOpen });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
};

  onAuthRepChanged = (value) => {
    const authRepSectionIndex = this.props.recert.sections.indexOf(
      this.filterData(this.props.recert.sections, "add-auth-rep")
  );
    if (
      value ===
      interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1]
    ) {
      this.setState(
        {
          incomeValuesPopulated: TextProps.VALUE_FALSE,
          incomeSubSectionCleared: TextProps.VALUE_FALSE,
        },
        () => {
          this.props.setAuthRepChangeStatus("N");
            this.props.setRecertDataChangeStatus(TextProps.VALUE_TRUE, authRepSectionIndex);
            // this.props.setDataChangeStatus(TextProps.VALUE_TRUE, authRepSectionIndex);
            this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);
        }
      );
    } else {
      this.setState(
        {
        },
        () => {
          this.props.setAuthRepChangeStatus("Y");
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, authRepSectionIndex);
            this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);
          // this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
        }
      );
    }
  };



  addAuthorizedRepresentative = (section) => {
    this.setState({ incomeSummaryEdit: TextProps.VALUE_TRUE });
    document.getElementById("authrep-add-btn").click();

  }

  returnAuthRepLabel() {
    if (
      (this.props.recert.authRepChanged === "Y") && (this.props.recert.authRepChanged !== "N")
    ) {
      return interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
      if (
        this.props.recert.authRepChanged === "N" || this.props.recert.additionalProperties.authRepChanged === "N"
      ) {
        return interimReportPageLabels(language, languageConstants.aboutMe) 
          .optionYesNo[1];
      } else {   
        return null;
      }
    }
  }

  renderEbtAuthLabel = () => {
    let label = "";
    if(this.props.addAuthRepData.ebtWebDataChangeCode === "NEW") {
      return label = "New"
    }
    if(this.props.addAuthRepData.ebtWebDataChangeCode === "CHANGE") {
      return label = "Changed"
    }
  }

  renderSnpAuthLabel = () => {
    let label = "";
    if(this.props.addAuthRepData.snpWebDataChangeCode === "NEW") {
      return label = "New"
    }
    if(this.props.addAuthRepData.snpWebDataChangeCode === "CHANGE") {
      return label = "Changed"
    }
  }

  renderHouseHoldHeadSummary = () => {
    let HOH = [];
    let summary = {};

    if (this.props.recert.householdMembers && this.props.recert.householdMembers.membersList) {
      HOH = this.props.recert.householdMembers.membersList.filter((mem) => {
        return mem.granteeFlag
      })
      summary = HOH[0]
    }

    let summaryTitle = summary.firstName + " " + summary.lastName + formatDate(summary.dateOfBirth)
    const authRepSummary = this.props.recert.authorizedRepresentatives;
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getIncomeSummaryTitle(
                summaryTitle,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <p className="interim-recert-heading apply__body-header help-text-fix">
          <HelpTip
            triggerText={
              recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
              ).triggerText
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                summaryTitle,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).after
            }
            helpText={
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).sAuthRepHelp
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        {
          (this.props.recert.authRepChanged === "Y") && (this.props.recert.authRepChanged !== "N") ? (
          <div>
            <button
              className="dta-button dta-bordered-button pure-u-4-24 income-edit-btn"
              onClick={() => this.addAuthorizedRepresentative(summary.section)}
            >
              <b>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                  ).editButtonLabel
                }
                </b>
            </button>
            <button
              style={{ display: "none" }}
              onClick={this.props.onPrimaryActionClick}
              id="authrep-add-btn"
            ></button>
          </div>
        ) : null}
        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
      {console.log("thisValuesfor12auht-rep", this.props.authRepChangeCode, "----|||||-------",this.props.addAuthRepData)}
        {authRepSummary && authRepSummary !== null && authRepSummary.ebtFrstNam !== null && authRepSummary.ebtLstNam !== null && this.props.addAuthRepData.ebtWebDataChangeCode !== "REMOVE" ? (
          <div>
            <p style={{ color: "#333333", fontWeight: "600" }}>
              {recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
              ).authQuestiontwo
              }
              { }
              {(this.props.addAuthRepData.ebtWebDataChangeCode === "NEW" || this.props.addAuthRepData.ebtWebDataChangeCode === "CHANGE") && (
                <span className="badge">{this.renderEbtAuthLabel()}</span>
              )}
            </p>
            {authRepSummary !== null ? <span>{authRepSummary.ebtFrstNam} {" "} {authRepSummary.ebtLstNam} </span> : ""}
          </div>
        ) : ""}
        {authRepSummary !== null && authRepSummary.snpFrstNam !== null && authRepSummary.snpLstNam !== null && this.props.addAuthRepData.snpWebDataChangeCode !== "REMOVE"? (
        <div>
        <p style={{ color: "#333333", fontWeight: "600" }}>
          {recertificationPageLabels(
            language,
            null, 
            languageConstants.authRep
          ).authCertificationTitle
          }
          {(this.props.addAuthRepData.snpWebDataChangeCode === "NEW" || this.props.addAuthRepData.snpWebDataChangeCode === "CHANGE") && (
           <span className="badge">{this.renderSnpAuthLabel()}</span>
          )}
        </p>
        {authRepSummary !== null ? <span>{authRepSummary.snpFrstNam} {" "} {authRepSummary.snpLstNam}</span> : ""}
        </div>          
        ) : ""}
        </div>
    );
  }

  render() {
    var listOfUpdatedHHMembers = [];
    var existingIncomeMembers = [];

    this.props.recert.householdMembers.membersList.filter(item => !(item.dataChangeCode === "removed" || item.dataChangeCode === "REMOVE")).map((memberHH) => {
      var incomeHouseHoldName = memberHH.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName = incomeHouseHoldName + " " + memberHH.lastName;
        let incomeHouseHoldDOB = formatDate(memberHH.dateOfBirth);
        if (memberHH.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = memberHH.firstName;
      }
      listOfUpdatedHHMembers.push(incomeCombinedName);

      if (this.props.incomeSummary.incomeMembers !== undefined) {
        this.props.incomeSummary.incomeMembers.map((mem) => {
          existingIncomeMembers.push(mem.firstName);
        });
      }
    });

    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.renderHouseHoldHeadSummary()}

        <hr className="divider" />
        <span>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.authRep
            ).summaryQuestion
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <br />
          <div>
            <fieldset className="dta-form__group">
              <Radio
                id="auth-rep"
                name="auth-rep-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(
                    language,
                    languageConstants.aboutMe,
                    null,
                    null,
                    null
                  ).optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnAuthRepLabel()}
                onChange={(value) => this.onAuthRepChanged(value)}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberIncome: clearMemberIncome,
      // appendIRIncomeDetails: appendIRIncomeDetails,
      setHouseholdMembers: setHouseholdMembers,
      setRecertDataChangeStatus: setRecertDataChangeStatus,
      setAuthRepChangeStatus: setAuthRepChangeStatus,
      setDataChangeStatus: setDataChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRep);
