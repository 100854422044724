import React, { Component } from "react";
import Radio from "./radio";
import * as Types from "../constants/types";
import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import DTAInput from "./dtaInput";
import DataChangeIndicator from "./utilities/dataChangeIndicator";
import * as languageConstants from "../constants/constants";
import { interimReportPageLabels } from "../../pages/recertInterimReport/recertInterimReportText";
import { covidRecertPageLabels } from "../../pages/recertCovid/recertCovidText";
import * as formatters from "./utilities/formatters.js";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import { formatDate } from "../../utils/components/utilities/formatters";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === languageConstants.langOptSel) {
    language = Types.ENGLISH;
  }

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      editedEmail: "",
      phoneNumber: "",
      editedPhoneNumber:"",
      emailOptIn: "",
      textOptIn: "",
      emailError: TextProps.VALUE_FALSE,
      phoneError: TextProps.VALUE_FALSE,
      contactInfoChanged: null,
    };
  }

  componentWillMount() {
    if (this.props.contactInfoData.contactInfoScreen !== undefined) {
      const contactInfo = this.props.contactInfoData.contactInfoScreen;
      this.setState({
        email: contactInfo.emailTemp
          ? contactInfo.emailTemp
          : contactInfo.email,
        phoneNumber: contactInfo.phoneNumberTemp
          ? contactInfo.phoneNumberTemp
          : contactInfo.phoneNumber,
      });
      if (contactInfo.emailTemp === "") {
        this.setState({
          email: "",
        });
      }
      if (contactInfo.phoneNumberTemp === "") {
        this.setState({
          phoneNumber: "",
        });
      }
    }
  }

  componentDidMount() {
    if (
      !this.props.contactInfoData.contactInfoScreen ||
      (this.props.contactInfoData.contactInfoScreen &&
        this.props.contactInfoData.contactInfoScreen == null)
    ) {
      this.setInitialData();
    } else {
      this.setState({
        email: this.props.contactInfoData.contactInfoScreen.email,
        phoneNumber: this.props.contactInfoData.contactInfoScreen.phoneNumber,
        emailOptIn: this.props.contactInfoData.contactInfoScreen.emailOptIn,
        textOptIn: this.props.contactInfoData.contactInfoScreen.textOptIn,
        emailError: TextProps.VALUE_FALSE,
        phoneError: TextProps.VALUE_FALSE,
      });
    }
    this.props.setDataCodeChangeFlags();
  }

  getDataChangeLabel = (data) => {
    let value = null
    if(data){
      if (data.dataChangeCode === "NEW") {
        value = "newLabel";
      }else if(data.dataChangeCode === "CHANGE"){
        value = "changedLabel";
      }else if(data.dataChangeCode === "REMOVE"){
        value = "removedLabel";
      }
    }
    return value;
  }

  setInitialData = () => {
    let addressArray = [];
    if (this.props.responseObj !== undefined) {
      this.props.responseObj.clientAddress.map((address) => {
        addressArray.push({
          typeCode: address.typeCode,
          line1: address.line1,
          line2:
            address.line2 && address.line2 !== undefined ? address.line2 : "",
          city: address.city,
          state: address.state,
          zip: address.zip,
          resAddrMatchFlag: address.resAddrMatchFlag,
          homelessFlag: address.homelessFlag,
        });

        return addressArray;
      });

    let {additionalProperties, clientAddress, clientEmail, clientPhone} = this.props.contactInfoData.responseObject;
    let flags = this.props.contactInfoData && this.props.contactInfoData.contactInfoScreen && this.props.contactInfoData.contactInfoScreen.changeFlags;
    let emailCode = this.getDataChangeLabel(clientEmail);
    let phoneCode = this.getDataChangeLabel(clientPhone);
    
    let respResAddress = "";
    let respMailAddress = "";
    for (let respAddressValue of clientAddress) {
      if (respAddressValue.typeCode === "RES")
        respResAddress = respAddressValue;
      else if (respAddressValue.typeCode === "MAIL")
        respMailAddress = respAddressValue;
    }
    let resAddressCode = this.getDataChangeLabel(respResAddress);
    let mailingAddressCode = this.getDataChangeLabel(respMailAddress);

    let initialObj = {
      email: this.props.contactInfoData.responseObject.clientEmail.emailAddress
        ? this.props.contactInfoData.responseObject.clientEmail.emailAddress
        : null,
      phoneNumber: this.props.contactInfoData.responseObject.clientPhone
        .phoneNumber
        ? this.props.contactInfoData.responseObject.clientPhone.phoneNumber
        : null,
      emailOptIn:
        this.props.contactInfoData.responseObject.clientEmail.optInFlag,
      textOptIn:
        this.props.contactInfoData.responseObject.clientPhone.textOptInFlag,
      editPhone: TextProps.VALUE_FALSE,
      editEmail: TextProps.VALUE_FALSE,
      editAddress: TextProps.VALUE_FALSE,
      sameMailingAddress:
        this.props.contactInfoData.responseObject.clientAddress
          .resAddrMatchFlag,
      contactInfoChanged: this.state.contactInfoChanged,
      addressList: {
        homeless:
          this.props.contactInfoData.responseObject.clientAddress[0]
            .homelessFlag,
        address: addressArray,
      },
      changeFlags: {
        emailCode: emailCode,
        phoneCode: phoneCode,
        mailingAddressCode: mailingAddressCode,
        resAddressCode: resAddressCode,
        homelssFlagCode: flags && flags.homelssFlagCode ? flags.homelssFlagCode : additionalProperties && additionalProperties.homelssFlagCode ? additionalProperties.homelssFlagCode :null,
      },
    };
    this.props.setContactInfo(initialObj);
    this.setState({
      email: this.props.contactInfoData.responseObject.clientEmail.emailAddress
        ? this.props.contactInfoData.responseObject.clientEmail.emailAddress
        : null,
      phoneNumber: this.props.contactInfoData.responseObject.clientPhone
        .phoneNumber
        ? this.props.contactInfoData.responseObject.clientPhone.phoneNumber
        : null,
      emailOptIn:
        this.props.contactInfoData.responseObject.clientEmail.optInFlag,
      textOptIn:
        this.props.contactInfoData.responseObject.clientPhone.textOptInFlag,
      emailError: TextProps.VALUE_FALSE,
      phoneError: TextProps.VALUE_FALSE,
    });
    delete this.props.contactInfoData.sections[1].subsections;
    this.props.contactInfoData.sections[1].subsections = null;
    this.props.onDataReset();
  }
  };

  render() {
    let interimRepoLabel = null;
    if (
      this.props.contactInfoData &&
      this.props.contactInfoData.contactInfoScreen &&
      this.props.contactInfoData.contactInfoScreen.contactInfoChanged &&
      this.props.contactInfoData.contactInfoScreen.contactInfoChanged ===
        TextProps.VALUE_TRUE
    ) {
      interimRepoLabel = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else if (
      this.props.contactInfoData &&
      this.props.contactInfoData.contactInfoScreen &&
      this.props.contactInfoData.contactInfoScreen.contactInfoChanged &&
      this.props.contactInfoData.contactInfoScreen.contactInfoChanged ===
        TextProps.VALUE_FALSE
    ) {
      interimRepoLabel = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[1];
    }

    return this.props.contactInfoData.contactInfoScreen ? (
      <div className="pad-all--double">
        {this.addressComponent()}
        <hr className="divider" />

        {this.phoneComponent()}
        <hr className="divider" />

        {this.emailComponent()}
        <hr className="divider" />

        <span style={{ wordBreak: "keep-all" }}>
          {
            interimReportPageLabels(language, languageConstants.contactInfo)
              .contactChangedQuestion
          }
        </span>
        <br />
        <br />
        <div className="pure-g">
          <form className="max-width pure-u-5-5">
            <div>
              <fieldset className="dta-form__group">
                {this.props.contactInfoData.sections[1].selectionMade ===
                  TextProps.VALUE_FALSE && (
                  <span className="dta-form__error-message">
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.contactInfo
                      ).requiredErrorMessage
                    }
                  </span>
                )}
                <Radio
                  name="contact-info-changed"
                  className="dta-form__option-list"
                  optionClass="dta-form__option dta-form__option--width-1-of-2"
                  options={
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).houseLessOptions
                  }
                  required={TextProps.VALUE_TRUE}
                  // value={interimRepoLabel}
                  value={
                    (this.props.contactInfoData.contactInfoScreen
                      .contactInfoChanged === TextProps.VALUE_TRUE || (this.props.contactInfoData.additionalProperties && this.props.contactInfoData.additionalProperties.contactChanged === TextProps.VALUE_TRUE))
                      ? interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).optionYesNo[0]
                      : (this.props.contactInfoData.contactInfoScreen
                          .contactInfoChanged === TextProps.VALUE_FALSE || (this.props.contactInfoData.additionalProperties && this.props.contactInfoData.additionalProperties.contactChanged === TextProps.VALUE_FALSE)) 
                      ? interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).optionYesNo[1]
                      : null
                  }
                  onChange={(value) => {
                    this.handleContactInfoChanged(value);
                  }}
                />
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    ) : null;
  }

  phoneComponent = () => {
    if (this.props.contactInfoData.contactInfoScreen.editPhone && this.props.contactInfoData.contactInfoScreen.contactInfoChanged) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1 max-width">
            <div className="pure-u-1-2">
              <p className="interim-recert-heading">
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).phoneEditTitle
                }
              </p>
              <br />
              <br />
            </div>
            <div
              className="max-width pure-u-1-2"
              style={{ textAlign: "right" }}
            >
              <button
                onClick={() => this.phoneEditDone()}
                className="dta-button dta-button--outline-white text--small interim-recert-outline-button contactinfo-button"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).doneButtonlabel
                }
              </button>
              <br />
              <br />
            </div>
          </div>
          <div className="pure-u-1-1 max-width">
            <div>
              <div className="max-width pure-u-2-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).phoneLabel
                  }
                </span>
              </div>
              <div className="max-width pure-u-3-5">
                <div className={"dta-custom-input__item"}>
                  <DTAInput
                    name={"phone"}
                    id={"phone"}
                    type="tel"
                    value={this.state.phoneNumber}
                    onChange={(value) => this.handlePhoneOnChanged(value)}
                    ariaLabel={"phone"}
                  />
                  <br />
                  {this.state.phoneError ||
                  (this.props.contactInfoData.contactInfoScreen
                    .phoneErrorExists &&
                    this.props.contactInfoData.contactInfoScreen
                      .continueClicked) ? (
                    <span
                      id="phoneError"
                      className="phone dta-form__error-message"
                    >
                      {
                        interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).validPhoneAlert
                      }
                    </span>
                  ) : null}
                </div>
              </div>
              <br />
              <br />
              <div className="max-width pure-u-2-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).phoneNotificationQuestion
                  }
                </span>
              </div>
              <div className="max-width pure-u-3-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).phoneNotificationAgreeLabel
                  }
                </span>
                <br />
                <br />
                <fieldset className="dta-form__group contact-info_editphone">
                  <Radio
                    name="textOptin"
                    className="dta-form__option-list"
                    optionClass="dta-form__option dta-form__option--width-1-of-2"
                    options={
                      interimReportPageLabels(
                        language,
                        languageConstants.contactInfo
                      ).houseLessOptions
                    }
                    required={TextProps.VALUE_TRUE}
                    value={
                      this.state.textOptIn
                        ? interimReportPageLabels(
                            language,
                            languageConstants.aboutMe
                          ).optionYesNo[0]
                        : interimReportPageLabels(
                            language,
                            languageConstants.aboutMe
                          ).optionYesNo[1]
                    }
                    onChange={(value) => this.handleTextOptin(value)}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-g">
          <div className="max-width pure-u-4-5">
            <span className="max-width pure-u-2-5 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .phoneLabel
              }
            </span>
            <span
              className="pure-u-1-2 contactinfo-details"
              style={{ paddingRight: "1rem" }}
            >
              {this.props.contactInfoData.contactInfoScreen.editPhone ?  this.formatPhoneNumber(this.state.phoneNumber) : this.formatPhoneNumber(this.props.contactInfoData.contactInfoScreen.phoneNumber)}
              <span className="pure-u-24-24 pure-u-sm-17-24 pure-u-md-17-24 pure-u-lg-17-24 pure-u-xl-17-24 contactinfo-details">
                {this.props.contactInfoData.contactInfoScreen.changeFlags
                  .phoneCode !== null ? (
                  <DataChangeIndicator
                    text={
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[
                        this.props.contactInfoData.contactInfoScreen.changeFlags
                          .phoneCode
                      ]
                    }
                  />
                ) : null}
              </span>
            </span>
            <br />
            <br />
          </div>
          <div
            className="max-width pure-u-1-5"
            style={{ textAlign: "right", wordBreak: "normal" }}
          >
            {this.props.contactInfoData.contactInfoScreen.contactInfoChanged ? (
              <button
                onClick={() => this.editPhoneNumber()}
                className="dta-button dta-button--outline-white text--small interim-recert-outline-button contactinfo-button"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).editButtonLabel
                }
              </button>
            ) : (
              TextProps.VALUE_FALSE
            )}
          </div>
          <div className="max-width pure-u-5-5 allow-notification">
            <span className="max-width pure-u-2-5 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .phoneNotificationQuestion
              }
            </span>
            <span className="pure-u-3-5 contactinfo-details">
              {this.state.textOptIn
                ? interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[0]
                : interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1]}
            </span>
          </div>
        </div>
      );
    }
  };

  emailComponent = () => {
    if (this.props.contactInfoData.contactInfoScreen.editEmail  && this.props.contactInfoData.contactInfoScreen.contactInfoChanged) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1 max-width">
            <div className="pure-u-1-2">
              <p className="interim-recert-heading">
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).emailLabel
                }
              </p>
              <br />
              <br />
            </div>
            <div
              className="max-width pure-u-1-2"
              style={{ textAlign: "right" }}
            >
              <button
                onClick={() => this.emailEditDone()}
                className="dta-button dta-button--outline-white text--small interim-recert-outline-button contactinfo-button"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).doneButtonlabel
                }
              </button>
              <br />
              <br />
            </div>
          </div>
          <div className="pure-u-1-1 max-width">
            <div>
              <div className="max-width pure-u-2-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).emailEditTitle
                  }
                </span>
              </div>
              <div className="max-width pure-u-3-5">
                <div className={"dta-custom-input__item"}>
                  <DTAInput
                    name={"email"}
                    type="text"
                    id={"email"}
                    maxLength={30}
                    value={this.state.email}
                    onChange={(value) => this.handleEmailOnChanged(value)}
                    ariaLabel={"email"}
                  />
                  <br />
                  {this.state.emailError ||
                  (this.props.contactInfoData.contactInfoScreen
                    .emailErrorExists &&
                    this.props.contactInfoData.contactInfoScreen
                      .continueClicked) ? (
                    <span
                      id="emailError"
                      className="email dta-form__error-message"
                    >
                      {
                        interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).emailErrormsg
                      }
                    </span>
                  ) : null}
                </div>
              </div>
              <br />
              <br />
              <div className="max-width pure-u-2-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).emailNotificationQuestion
                  }
                </span>
              </div>
              <div className="max-width pure-u-3-5">
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).emailNotificationAgreeLabel
                  }
                </span>
                <br />
                <br />
                <fieldset className="dta-form__group contact-info_editphone">
                  <Radio
                    name="emailOptin"
                    className="dta-form__option-list"
                    optionClass="dta-form__option dta-form__option--width-1-of-2"
                    options={
                      interimReportPageLabels(
                        language,
                        languageConstants.contactInfo
                      ).houseLessOptions
                    }
                    required={TextProps.VALUE_TRUE}
                    value={
                      this.state.emailOptIn
                        ? interimReportPageLabels(
                            language,
                            languageConstants.aboutMe
                          ).optionYesNo[0]
                        : interimReportPageLabels(
                            language,
                            languageConstants.aboutMe
                          ).optionYesNo[1]
                    }
                    onChange={(value) => this.handleEmailOptin(value)}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-g">
          <div
            className="max-width pure-u-4-5"
            style={{ marginBottom: "none" }}
          >
            <span className="max-width pure-u-2-5 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .emailEditTitle
              }
            </span>
            <span
              className="pure-u-1-2 contactinfo-details"
              style={{ paddingRight: "1rem" }}
            >
              {this.props.contactInfoData.contactInfoScreen.editEmail ?  this.state.email : this.props.contactInfoData.contactInfoScreen.email}
              <span className="pure-u-24-24 pure-u-sm-17-24 pure-u-md-17-24 pure-u-lg-17-24 pure-u-xl-17-24 contactinfo-details">
                {this.props.contactInfoData.contactInfoScreen.changeFlags
                  .emailCode !== null ? (
                  <span>
                    <DataChangeIndicator
                      text={
                        interimReportPageLabels(
                          language,
                          languageConstants.householdStatic,
                          null,
                          null,
                          null
                        )[
                          this.props.contactInfoData.contactInfoScreen
                            .changeFlags.emailCode
                        ]
                      }
                    />
                  </span>
                ) : null}
              </span>
            </span>
            <br />
            <br />
          </div>
          <div
            className="max-width pure-u-1-5"
            style={{ textAlign: "right", wordBreak: "normal" }}
          >
            {this.props.contactInfoData.contactInfoScreen.contactInfoChanged ? (
              <button
                onClick={() => this.editEmail()}
                className="dta-button dta-button--outline-white text--small interim-recert-outline-button contactinfo-button"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).editButtonLabel
                }
              </button>
            ) : (
              TextProps.VALUE_FALSE
            )}
          </div>
          <div className="max-width pure-u-5-5 allow-notification">
            <span className="max-width pure-u-2-5 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .emailNotificationQuestion
              }
            </span>
            <span className="pure-u-3-5 contactinfo-details">
              {this.state.emailOptIn
                ? interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[0]
                : interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1]}
            </span>
          </div>
        </div>
      );
    }
  };

  addressComponent = () => {
    let resAddCodeLable = null;
    if (
      this.props.contactInfoData.contactInfoScreen.changeFlags
        .resAddressCode !== null
    ) {
      resAddCodeLable = (
        <DataChangeIndicator
          text={
            interimReportPageLabels(
              language,
              languageConstants.householdStatic,
              null,
              null,
              null
            )[
              this.props.contactInfoData.contactInfoScreen.changeFlags
                .resAddressCode
            ]
          }
        />
      );
    }

    let mailAddCodeLabel = null;
    if (
      this.props.contactInfoData.contactInfoScreen.changeFlags
        .mailingAddressCode !== null
    ) {
      mailAddCodeLabel = (
        <DataChangeIndicator
          text={
            interimReportPageLabels(
              language,
              languageConstants.householdStatic,
              null,
              null,
              null
            )[
              this.props.contactInfoData.contactInfoScreen.changeFlags
                .mailingAddressCode
            ]
          }
        />
      );
    }

    let contactInfoAddressVal;
    if (
      this.props.contactInfoData.contactInfoScreen.addressList.address[0]
        .typeCode === "MAIL"
    ) {
      contactInfoAddressVal = (
        <div>
          {this.props.contactInfoData.contactInfoScreen.addressList.address[0]
            .line1 +
            " " +
            this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .line2}
          <br />
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .city
          }{" "}
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .state
          }{" "}
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .zip
          }
          <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-19-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
            {mailAddCodeLabel}
          </span>
        </div>
      );
    } else if (
      this.props.contactInfoData.contactInfoScreen.addressList.address[1] &&
      this.props.contactInfoData.contactInfoScreen.addressList.address[1]
        .typeCode === "MAIL"
    ) {
      contactInfoAddressVal = (
        <div>
          {this.props.contactInfoData.contactInfoScreen.addressList.address[1]
            .line1 +
            " " +
            this.props.contactInfoData.contactInfoScreen.addressList.address[1]
              .line2}
          <br />{" "}
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[1]
              .city
          }{" "}
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[1]
              .state
          }{" "}
          {
            this.props.contactInfoData.contactInfoScreen.addressList.address[1]
              .zip
          }
          <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-19-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
            {mailAddCodeLabel}
          </span>
        </div>
      );
    } else {
      contactInfoAddressVal = <div></div>;
    }

    return (
      <div className="pure max-width">
        <div className="pure-g">
          <div className="contact-info-label pure-u-4-5">
            <span className="max-width pure-u-2-5 label-text-clr">
              {
              interimReportPageLabels(language, languageConstants.contactInfo)
                .headOfHousehold
              }
            </span>
           
              {this.props.contactInfoData.responseObject.householdMembers && this.props.contactInfoData.responseObject.householdMembers.map(member => {
                if(member.granteeFlag === TextProps.VALUE_TRUE){
                 return  <span className="pure-u-1-2 contactinfo-details">{member.firstName} {member.lastName} {formatDate(member.dateOfBirth)}</span>
                }
              })}
              
          </div>
          <div className="max-width pure-u-4-5">
            <span className="max-width pure-u-1-2 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .houselessLabel
              }
            </span>
            <span className="pure-u-1-2 contactinfo-details">
              {this.props.contactInfoData.contactInfoScreen.addressList.homeless
                ? interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).houseLessOptions[0]
                : interimReportPageLabels(
                    language,
                    languageConstants.contactInfo
                  ).houseLessOptions[1]}
              <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-19-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
                {this.props.contactInfoData.contactInfoScreen.changeFlags
                  .homelssFlagCode !== null ? (
                  <DataChangeIndicator
                    text={
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[
                        this.props.contactInfoData.contactInfoScreen.changeFlags
                          .homelssFlagCode
                      ]
                    }
                  />
                ) : null}
              </span>
            </span>
            <br />
            <br />
          </div>
          <div
            className="max-width pure-u-1-5"
            style={{ textAlign: "right", wordBreak: "normal" }}
          >
            {this.props.contactInfoData.contactInfoScreen.contactInfoChanged ? (
              <div>
                <button
                  onClick={() => this.editAddress()}
                  className="dta-button dta-button--outline-white text--small interim-recert-outline-button contactinfo-button"
                >
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).editButtonLabel
                  }
                </button>
                <button
                  style={{ display: "none" }}
                  onClick={this.props.onPrimaryActionClick}
                  id="contact-add-btn"
                ></button>
              </div>
            ) : (
              TextProps.VALUE_FALSE
            )}
          </div>
          <div className="max-width pure-u-5-5">
            <span className="max-width pure-u-2-5 label-text-clr">
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .resAddressLabel
              }
            </span>
            {this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .typeCode === "RES" ? (
              <div className="pure-u-3-5 contactinfo-details">
                {this.props.contactInfoData.contactInfoScreen.addressList
                  .address[0].line1 +
                  " " +
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[0].line2}
                <br />{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[0].city
                }{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[0].state
                }{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[0].zip
                }
                <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-19-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
                  {resAddCodeLable}
                </span>
              </div>
            ) : this.props.contactInfoData.contactInfoScreen.addressList
                .address[1] &&
              this.props.contactInfoData.contactInfoScreen.addressList
                .address[1].typeCode === "RES" ? (
              <div className="pure-u-3-5 contactinfo-details">
                {this.props.contactInfoData.contactInfoScreen.addressList
                  .address[1].line1 +
                  " " +
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[1].line2}
                <br />{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[1].city
                }{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[1].state
                }{" "}
                {
                  this.props.contactInfoData.contactInfoScreen.addressList
                    .address[1].zip
                }
                <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-17-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
                  {(this.props.contactInfoData.contactInfoScreen.changeFlags
                    .resAddressCode !== null) ? (
                    <DataChangeIndicator
                      text={
                        interimReportPageLabels(
                          language,
                          languageConstants.householdStatic,
                          null,
                          null,
                          null
                        )[
                          this.props.contactInfoData.contactInfoScreen
                            .changeFlags.resAddressCode
                        ]
                      }
                    />
                  ) : null}
                </span>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <hr className="divider" />

        <div className="pure-g">
          <div
            className="max-width pure-u-2-5 label-text-clr"
            style={{ wordBreak: "break-word" }}
          >
            <span>
              {
                interimReportPageLabels(language, languageConstants.contactInfo)
                  .mailingAddresssLabel
              }
            </span>
          </div>
          <div
            className="max-width pure-u-3-5"
            style={{ wordBreak: "break-word" }}
          >
            {console.log("MailingAddressChange", this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .resAddrMatchFlag === TextProps.VALUE_TRUE ||
            (this.props.contactInfoData.contactInfoScreen.addressList
              .address[1] &&
              this.props.contactInfoData.contactInfoScreen.addressList
                .address[1].resAddrMatchFlag === TextProps.VALUE_TRUE))}
            {this.props.contactInfoData.contactInfoScreen.addressList.address[0]
              .resAddrMatchFlag === TextProps.VALUE_TRUE ||
            (this.props.contactInfoData.contactInfoScreen.addressList
              .address[1] &&
              this.props.contactInfoData.contactInfoScreen.addressList
                .address[1].resAddrMatchFlag) ? (
              <div>
                <label>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).resSameAddressLabel
                  }
                </label>
                <span className="pure-u-24-24 pure-u-sm-19-24 pure-u-md-19-24 pure-u-lg-19-24 pure-u-xl-19-24 contactinfo-details">
                  {mailAddCodeLabel}
                </span>
              </div>
            ) : (
              contactInfoAddressVal
            )}
          </div>
        </div>
      </div>
    );
  };

  //format phone number to US format
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  editPhoneNumber() {
    let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    contactInfoObj.editPhone = TextProps.VALUE_TRUE;
    this.props.setContactInfo(contactInfoObj);
  }

  phoneEditDone() {
    if (
      this.validatePhoneNumber(this.state.phoneNumber) === TextProps.VALUE_FALSE
    ) {
      this.setState({ phoneError: TextProps.VALUE_FALSE,  phoneNumber: this.state.phoneNumber});
      let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
      contactInfoObj.phoneNumber = this.state.phoneNumber;
      contactInfoObj.editPhone = TextProps.VALUE_FALSE;
      contactInfoObj.textOptIn = this.state.textOptIn;
      this.props.setContactInfo(contactInfoObj);
      this.props.setDataCodeChangeFlags();
      this.setState({contactInfoSummaryChanged: TextProps.VALUE_TRUE});
    } else {
      this.setState({ phoneError: TextProps.VALUE_TRUE });
      return TextProps.VALUE_FALSE;
    }
  }

  handleTextOptin(value) {
    let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    this.setState(
      {
        textOptIn:
          value ===
          interimReportPageLabels(language, languageConstants.aboutMe)
            .optionYesNo[0]
            ? TextProps.VALUE_TRUE
            : TextProps.VALUE_FALSE,
      },
      () => {
        contactInfoObj.phoneErrorExists = this.validatePhoneNumber(
          this.state.phoneNumber
        );
      }
    );
    contactInfoObj.textOptInTemp =
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        ? TextProps.VALUE_TRUE
        : TextProps.VALUE_FALSE;
    this.props.setContactInfo(contactInfoObj);
  }

  editEmail() {
    let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    contactInfoObj.editEmail = TextProps.VALUE_TRUE;
    this.props.setContactInfo(contactInfoObj);
  }

  handleEmailOnChanged = (value) => {
    this.setState({ email: value });
    //let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    // contactInfoObj.emailErrorExists = !this.validateEmail(value);
    // contactInfoObj.continueClicked = TextProps.VALUE_FALSE;
    // contactInfoObj.emailTemp = value;
    // this.props.setContactInfo(contactInfoObj);
    // this.setState({contactInfoSummaryChanged: TextProps.VALUE_TRUE});
  };

  handlePhoneOnChanged = (value) => {
    this.setState({ phoneNumber: value });
    // let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    // contactInfoObj.phoneErrorExists = this.validatePhoneNumber(value);
    // contactInfoObj.continueClicked = TextProps.VALUE_FALSE;
    // contactInfoObj.phoneNumberTemp = value;
    //this.props.setContactInfo(contactInfoObj);
   // this.setState({contactInfoSummaryChanged: TextProps.VALUE_TRUE});
  };

  validateEmail = (email) => {
    let isValidEmail  = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email);
    let { emailOptIn } = this.state;
    if ((emailOptIn && !isValidEmail) || (email && !isValidEmail))
      return TextProps.VALUE_FALSE;
    return TextProps.VALUE_TRUE;
  };

  validatePhoneNumber = (phone) => {
    let phoneNumberString = phone;
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let { textOptIn } = this.state;
    if (
      (cleaned === "" && !textOptIn) ||
      (cleaned !== "" && cleaned.length === 10)
    )
      return TextProps.VALUE_FALSE;
    return TextProps.VALUE_TRUE;
  };

  emailEditDone() {
    if (this.validateEmail(this.state.email) === TextProps.VALUE_TRUE) {
      this.setState({ emailError: TextProps.VALUE_FALSE, email: this.state.email });
      let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
      contactInfoObj.email = this.state.email;
      contactInfoObj.editEmail = TextProps.VALUE_FALSE;
      contactInfoObj.emailOptIn = this.state.emailOptIn;
      this.props.setContactInfo(contactInfoObj);
      this.props.setDataCodeChangeFlags();
      this.setState({contactInfoSummaryChanged: TextProps.VALUE_TRUE});
    } else {
      this.setState({ emailError: TextProps.VALUE_TRUE });
      return TextProps.VALUE_FALSE;
    }
  }

  handleEmailOptin(value) {
    let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
    this.setState(
      {
        emailOptIn:
          value ===
          interimReportPageLabels(language, languageConstants.aboutMe)
            .optionYesNo[0]
            ? TextProps.VALUE_TRUE
            : TextProps.VALUE_FALSE,
      },
      () => {
        contactInfoObj.emailErrorExists = !this.validateEmail(this.state.email);
      }
    );
    contactInfoObj.continueClicked = TextProps.VALUE_FALSE;
    contactInfoObj.emailOptInTemp =
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        ? TextProps.VALUE_TRUE
        : TextProps.VALUE_FALSE;
    this.props.setContactInfo(contactInfoObj);
  }

  editAddress() {
    const caseManager = this.props.responseObj.caseManager;
    const caseManagerNo = caseManager ? caseManager.phoneNumber : "8773822363";
    const caseManagerPhone = formatters.phone(caseManagerNo);
    let mailAddrFlag = TextProps.VALUE_STR_FALSE;
    var covidRecertContact = JSON.parse(
      sessionStorage.getItem("covidRecertContact")
    );
    var recertType = sessionStorage.getItem('recertType');
    var covidSubHeader = covidRecertPageLabels(
      language,
      languageConstants.contactInfo
    ).addContactInfoSubHeader;
    // var interimSubHeader = interimReportPageLabels(
    //   language,
    //   languageConstants.contactInfo
    // ).addContactInfoSubHeader;
    if (
      this.props.contactInfoData.contactInfoScreen.addressList.address[0]
        .resAddrMatchFlag === TextProps.VALUE_TRUE
    ) {
      mailAddrFlag = TextProps.VALUE_STR_TRUE;
    }
    if (!this.props.contactInfoData.sections[1].subsections) {
      let addressObj = this.props.contactInfoData.contactInfoScreen.addressList;
      let resAddressObj = null;
      if (addressObj.address[0].typeCode === "RES") {
        resAddressObj = addressObj.address[0];
      } else if (
        addressObj.address[1] &&
        addressObj.address[1].typeCode === "RES"
      ) {
        resAddressObj = addressObj.address[1];
      }
      let mailAddressObj = null;
      if (addressObj.address[0].typeCode === "MAIL") {
        mailAddressObj = addressObj.address[0];
      } else if (
        addressObj.address[1] &&
        addressObj.address[1].typeCode === "MAIL"
      ) {
        mailAddressObj = addressObj.address[1];
      }

      let subSection = [
        {
          id: "add-contact-info",
          parentId: "contact-info",
          title: interimReportPageLabels(
            language,
            languageConstants.contactInfo
          ).addContactInfoTitle, //'Housing and Utility Costs',
          header: interimReportPageLabels(
            language,
            languageConstants.contactInfo
          ).addContactInfoHeader, //'Tell us about your housing costs',
          subHeader: recertType === "REEVAL" ? interimReportPageLabels(
            language,
            languageConstants.contactInfo
          ).addContactInfoSubHeadertanf: recertType === "AESAP" ? interimReportPageLabels(
            language,
            languageConstants.contactInfo
          ).addContactInfoSubHeaderAesap : recertType === "RECERT" ? interimReportPageLabels(
            language,
            languageConstants.contactInfo
          ).addContactInfoSubHeaderRecert : "", //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
          completed: TextProps.VALUE_FALSE,
          hiddenFromNav: TextProps.VALUE_TRUE,
          navigateToPrimaryScreen: TextProps.VALUE_TRUE,
          questions: [
            {
              Answer: this.props.contactInfoData.contactInfoScreen.addressList
                .homeless
                ? interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[0]
                : interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1],
              [OptionsProps.TARGET_VALUE]: this.props.contactInfoData
                .contactInfoScreen.addressList.homeless
                ? interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[0]
                : interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1],
              [Props.NAME]: "is-homeless",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).houselessLabel,
              [Props.TYPE]: Types.RADIO,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).houselessErrMsg,
              [OptionsProps.OPTIONS]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).houseLessOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
            },
            {
              Answer: resAddressObj
                ? {
                    street: resAddressObj.line1 + " " + resAddressObj.line2,
                    line1: resAddressObj.line1,
                    line2: resAddressObj.line2,
                    addressLine1: resAddressObj.line1,
                    addressLine2: resAddressObj.line2,
                    zipCode: resAddressObj.zip,
                    state: resAddressObj.state,
                    city: resAddressObj.city,
                    county:resAddressObj.county ? resAddressObj.county : "",
                    countyCode:resAddressObj.countyCode ? resAddressObj.countyCode : "",
                    verified:resAddressObj.verified ? resAddressObj.verified : TextProps.VALUE_N
                  }
                : {
                    street: "",
                    line1: "",
                    line2: "",
                    addressLine1: "",
                    addressLine2: "",
                    zipCode: "",
                    state: "",
                    city: "",
                    county:"",
                    countyCode:"",
                    verified:TextProps.VALUE_N
                  },
              [OptionsProps.TARGET_VALUE]: resAddressObj
                ? {
                    street: resAddressObj.line1 + " " + resAddressObj.line2,
                    line1: resAddressObj.line1,
                    line2: resAddressObj.line2,
                    addressLine1: resAddressObj.line1,
                    addressLine2: resAddressObj.line2,
                    zipCode: resAddressObj.zip,
                    state: resAddressObj.state,
                    city: resAddressObj.city,
                    county:resAddressObj.county ? resAddressObj.county : "",
                    countyCode:resAddressObj.countyCode ? resAddressObj.countyCode : "",
                    verified:resAddressObj.verified ? resAddressObj.verified : TextProps.VALUE_N
                  }
                : {
                    street: "",
                    line1: "",
                    line2: "",
                    addressLine1: "",
                    addressLine2: "",
                    zipCode: "",
                    state: "",
                    city: "",
                    county:"",
                    countyCode:"",
                    verified:TextProps.VALUE_N
                  },
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addrQuestionlabel,
              [Props.TYPE]: Types.ADDRESS,
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
              [Props.ERROR_MESSAGE]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_CHAR]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).citiErrmsg,
              [Props.ERROR_MESSAGE_FOR_COUNTY]:interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).countyErrmsg,
              [Props.ERROR_MESSAGE_FOR_ADDRESS]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).homeAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).mailingAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_ZIPCODE]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).zipCodeErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]: interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                caseManagerPhone
              ).masZipCodeErrmsg,
            },
            {
              Answer: mailAddrFlag,
              value: mailAddrFlag,
              [OptionsProps.TARGET_VALUE]: mailAddrFlag,
              [Props.NAME]: "is-also-mailing",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).alsoMailingLabel,
              [Props.TYPE]: Types.RADIO,
              [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
              [OptionsProps.OPTIONS]: [
                {
                  [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_TRUE,
                  [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                    language,
                    languageConstants.aboutMe
                  ).optionsSendMail,
                },
                {
                  [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_FALSE,
                  [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                    language,
                    languageConstants.aboutMe
                  ).optionsNoDiffAddress,
                },
              ],
              [OptionsProps.CONDITIONALS]: [
                {
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                    TextProps.VALUE_STR_FALSE,
                  [OptionsProps.CONDITIONAL_QUESTIONS]: [
                    {
                      Answer: mailAddressObj
                        ? {
                            street:
                              mailAddressObj.line1 + " " + mailAddressObj.line2,
                            line1: mailAddressObj.line1,
                            line2: mailAddressObj.line2,
                            addressLine1: mailAddressObj.line1,
                            addressLine2: mailAddressObj.line2,
                            zipCode: mailAddressObj.zip,
                            state: mailAddressObj.state,
                            city: mailAddressObj.city,
                            county:mailAddressObj.county ? mailAddressObj.county : "",
                            countyCode:mailAddressObj.countyCode ? mailAddressObj.countyCode : "",
                            verified:mailAddressObj.verified ? mailAddressObj.verified : TextProps.VALUE_N
                          }
                        : {
                            street: "",
                            line1: "",
                            line2: "",
                            addressLine1: "",
                            addressLine2: "",
                            zipCode: "",
                            state: "",
                            city: "",
                            county:"",
                            countyCode:"",
                            verified:TextProps.VALUE_N
                          },
                      [OptionsProps.TARGET_VALUE]: mailAddressObj
                        ? {
                            street:
                              mailAddressObj.line1 + " " + mailAddressObj.line2,
                            line1: mailAddressObj.line1,
                            line2: mailAddressObj.line2,
                            addressLine1: mailAddressObj.line1,
                            addressLine2: mailAddressObj.line2,
                            zipCode: mailAddressObj.zip,
                            state: mailAddressObj.state,
                            city: mailAddressObj.city,
                            county:mailAddressObj.county ? mailAddressObj.county : "",
                            countyCode:mailAddressObj.countyCode ? mailAddressObj.countyCode : "",
                            verified:mailAddressObj.verified ? mailAddressObj.verified : TextProps.VALUE_N
                          }
                        : {
                            street: "",
                            line1: "",
                            line2: "",
                            addressLine1: "",
                            addressLine2: "",
                            zipCode: "",
                            state: "",
                            city: "",
                            county:"",
                            countyCode:"",
                            verified:TextProps.VALUE_N
                          },
                      [Props.NAME]: "mailingAddress",
                      [Props.LABEL]: interimReportPageLabels(
                        language,
                        languageConstants.aboutMe
                      ).mailingQlabel,
                      [Props.TYPE]: Types.ADDRESS,
                      [Props.REQUIRED]: TextProps.VALUE_TRUE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]: interimReportPageLabels(
                        language,
                        languageConstants.aboutMe
                      ).addressErrmsg,
                      [Props.ERROR_MESSAGE_FOR_CHAR]: interimReportPageLabels(
                        language,
                        languageConstants.aboutMe
                      ).citiErrmsg,
                      [Props.ERROR_MESSAGE_FOR_COUNTY]:interimReportPageLabels(
                        language,
                        languageConstants.aboutMe
                      ).countyErrmsg,
                      [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                        interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).addressErrmsg,
                      [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]: interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).homeAddressErrmsg,
                      [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]: interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).mailingAddressErrmsg,
                      [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                        interimReportPageLabels(
                          language,
                          languageConstants.aboutMe
                        ).zipCodeErrmsg,
                      [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
                        interimReportPageLabels(
                          language,
                          languageConstants.aboutMe,
                          null,
                          caseManagerPhone
                        ).masZipCodeErrmsg,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ];
      this.props.addContactInfoSubSection(subSection);
    } else {
      let subsection = this.props.contactInfoData.sections[1].subsections[0];
      if (subsection !== undefined) {
        subsection.questions[1].error = TextProps.VALUE_FALSE;
        subsection.questions[2].value = mailAddrFlag;
        subsection.questions[2].Answer = mailAddrFlag;
        subsection.questions[2].conditionals[0].conditionalQuestions[0].error = TextProps.VALUE_FALSE;
        this.props.addContactInfoSubSection([subsection]);
      }
    }
    document.getElementById("contact-add-btn").click();
  }

  handleContactInfoChanged = (value) => {
    if (
      value ===
      interimReportPageLabels(language, languageConstants.contactInfo)
        .houseLessOptions[1]
    ) {
      this.props.addContactInfoSubSection(null);
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 1);
      this.setState({ contactInfoChanged: TextProps.VALUE_FALSE }, () =>
        this.setInitialData()
      );
    } else {
      let contactInfoObj = this.props.contactInfoData.contactInfoScreen;
      contactInfoObj.contactInfoChanged =
        value ===
        interimReportPageLabels(language, languageConstants.contactInfo)
          .houseLessOptions[0]
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;
      this.setState({
        contactInfoChanged:
          value ===
          interimReportPageLabels(language, languageConstants.contactInfo)
            .houseLessOptions[0]
            ? TextProps.VALUE_TRUE
            : TextProps.VALUE_FALSE,
      });
      if (contactInfoObj.contactInfoChanged === TextProps.VALUE_FALSE) {
        contactInfoObj.editEmail = TextProps.VALUE_FALSE;
        contactInfoObj.editPhone = TextProps.VALUE_FALSE;
      }
      this.props.setContactInfo(contactInfoObj);
      this.props.addContactInfoSubSection(null);
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 1);
    }
  };
}

export default ContactInfo;
