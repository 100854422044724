import * as Types from "../../utils/constants/types";

import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import * as UnitsProps from "../../utils/constants/units";
import * as Contorls from "../../utils/constants/controls";
import { screeningMultilanguageLabels } from "./applySnapText";
import * as TextProps from "../../utils/constants/text";

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select") {
  language = "en-US";
}
const sections = [
  {
    id: "screening",
    title: screeningMultilanguageLabels(language).title,
    header: screeningMultilanguageLabels(language).header,
    subHeader: screeningMultilanguageLabels(language).subHeader, // 'Find out if you may be eligible in 10 seconds.',
    subsections: [
      {
        id: "screening-results",
        title: screeningMultilanguageLabels(language).subsections.title, //'You may be eligible for SNAP benefits.' ,
        header: screeningMultilanguageLabels(language).subsections.header, //'You may be eligible for SNAP benefits.',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    questions: [
      {
        [Props.NAME]: "numInHousehold",
        [Props.LABEL]:
          screeningMultilanguageLabels(language).questions[0].propsLABEL, //'How many people live in your household, including you?',
        [Props.INLINE_HELP]:
          screeningMultilanguageLabels(language).questions[0].propsINLINEHELP,
        // 'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
        [Props.TYPE]: Types.NUMBERS,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          screeningMultilanguageLabels(language).questions[0]
            .PropsERROR_MESSAGE, //'Please select how many people live in your household'
      },
      {
        [Props.NAME]: "seniors",
        [Props.LABEL]:
          screeningMultilanguageLabels(language).questions[1].propsLABELAge, //'Is anyone age 60 or older?',
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]:
          screeningMultilanguageLabels(language).questions[1]
            .optionsPropsOPTIONS,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          screeningMultilanguageLabels(language).questions[1]
            .propsERROR_MESSAGE,
      },
      {
        [Props.NAME]: "disabilities",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            screeningMultilanguageLabels(language).questions[2]
              .propsLABEL_OBJ_BEFORE,
          [Props.LABEL_OBJ_TRIGGER]:
            screeningMultilanguageLabels(language).questions[2]
              .propsLABEL_OBJ_TRIGGER,
          [Props.LABEL_OBJ_AFTER]:
            screeningMultilanguageLabels(language).questions[2]
              .propsLABEL_OBJ_AFTER,
          [Props.LABEL_OBJ_HELP]:
            screeningMultilanguageLabels(language).questions[2]
              .propsLABEL_OBJ_HELP,
        },
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]:
          screeningMultilanguageLabels(language).questions[2]
            .optionsPropsOPTIONS,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          screeningMultilanguageLabels(language).questions[2]
            .propsERROR_MESSAGE, //'Please answer if anyone in the household has a physical or mental disability'
      },
      {
        [Props.NAME]: "totalIncome",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            screeningMultilanguageLabels(language).questions[3]
              .propsLABEL_OBJ_BEFORE, //'What is the total ',
          [Props.LABEL_OBJ_TRIGGER]:
            screeningMultilanguageLabels(language).questions[3]
              .propsLABEL_OBJ_TRIGGER, // 'gross income',
          [Props.LABEL_OBJ_AFTER]:
            screeningMultilanguageLabels(language).questions[3]
              .propsLABEL_OBJ_AFTER, //' for your household?',
          [Props.LABEL_OBJ_HELP]:
            screeningMultilanguageLabels(language).questions[3]
              .propsLABEL_OBJ_HELP,
          //'Gross income is the total income before anything is taken out, such as taxes or health insurance.' //screeningMultilanguageLabels(language).questions[3].PropsLABEL_OBJ_HELP
        },
        [Props.TYPE]:
          screeningMultilanguageLabels(language).questions[3].propsTYPE,
        [UnitsProps.IN_BETWEEN]:
          screeningMultilanguageLabels(language).questions[3]
            .unitsPropsINBETWEEN,
        [UnitsProps.UNITS]:
          screeningMultilanguageLabels(language).questions[3].unitsPropsUNITS, // ['Week', 'Month', 'Year'],
        [OptionsProps.TARGET_VALUE]:
          screeningMultilanguageLabels(language).questions[3]
            .optionsPropsTARGET_VALUE,
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Contorls.INPUT_TYPES]: 10,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          screeningMultilanguageLabels(language).questions[3]
            .propsERROR_MESSAGE,
        [Props.ERROR_MESSAGE_FREQ]:
          screeningMultilanguageLabels(language).questions[3]
            .propsERROR_MESSAGE_FREQ,
        [Props.ERROR_MESSAGE_INCM]:
          screeningMultilanguageLabels(language).questions[3]
            .propsERROR_MESSAGE_INCM,
      },
    ],
  },
];

export default sections;
