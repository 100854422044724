import React, { Component } from 'react'
import DTASelect from "../../../../utils/components/dtaSelect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { consumerConst } from "../../consumerText";


const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class ConcernAppealType extends Component {
  constructor(props) {
    super(...arguments);
  }
  render() {
    return (
      <div className='pad-all--double'>
        <div className="pure-u-1">
          <div>
            <span style={{ display: 'flex', fontSize: '2rem', padding: '0px 0rem 1rem 0.2rem' }}><b>{consumerConst(selectedLanguage).consumerconcern}</b></span>
            <span>{consumerConst(selectedLanguage).FairhearingDays}</span>
          </div>
          <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px', marginTop:"20px" }}>
          </div>
        </div>
        <div className="pure-u-1" style={{ padding: '0px 12rem 1rem 0.2rem' }}>
          <label className="dta-form__label" for="concernsType">{consumerConst(selectedLanguage).concernLabel}</label>
          {this.props.concerntype.concernsTypeError && (
            <div>
              <div className="peat-case-error-msg">
                <span className="dta-form__error-message">
                  {consumerConst(selectedLanguage).concernErrorText}
                </span>
              </div>
            </div>
          )}
          <DTASelect
            name={"concernsType"}
            className="dta-form__control dta-select--expand-width"
            id={"concernsType"}
            options={consumerConst(selectedLanguage).concernsAppealTypeOptions}
            error={this.props.concerntype.concernsTypeError}
            value={this.props.concerntype.customerType}
            onChange={this.props.typeSelectsection}
          />
        </div></div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {

    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcernAppealType);