// code for frequency
function frequencyCode() {
  this.getfrequencyCode = {
    Annual: "ANNUAL",
    Bimonthly: "BIMON",
    "Bimonthly(everytwomonths)": "BIMON",
    "Biweekly(everytwoweeks)": "BIWEEK",
    Biweekly: "BIWEEK",
    Monthly: "MONTH",
    Month: "MONTH",
    Quarterly: "QRTRLY",
    "Semi-annual(Twiceayear)": "SEMANL",
    "Semi-monthly(Twiceamonth)": "SEMMON",
    "Bimonthly(every two months)": "BIMON",
    "Biweekly(every two weeks)": "BIWEEK",
    "Semi-annual(Twice a year)": "SEMANL",
    "Semi-monthly(Twice a month)": "SEMMON",
    Anual: "ANNUAL",
    "Bimestral(cadadosmeses)" : "BIMON",
    "Bisemanal(cadadossemanas)" : "BIWEEK",
    Mensual:"MONTH",
    Trimestral: "QRTRLY",
    "Semestral(dosvecesalaño)" : "SEMANL",
    "Quincenal(dosvecesalmes)" : "SEMMON",
    Semanalmente :"WEEK",
    Semiannual: "SEMANL",
    Semimonthly: "SEMMON",
    Weekly: "WEEK",
    Week: "WEEK",
    Year: "ANNUAL",
    Everytwoweeks: "BIWEEK",
    Everytwomonths: "BIMON",
    Mes: "MONTH",
    Cadadossemanas: "BIWEEK",
    Semana: "WEEK",
    Año: "YEAR",
    Mês: "MONTH",
    Acadaduassemanas: "BIWEEK",
    Ano: "YEAR",
    月: "MONTH",
    每两周: "BIWEEK",
    周: "WEEK",
    年: "YEAR",
    Tháng: "MONTH",
    Mỗihaituần: "BIWEEK",
    Tuần: "WEEK",
    Năm: "YEAR",
    Mwa: "MONTH",
    Chakdesemèn: "BIWEEK",
    Semèn: "WEEK",
    Ane: "YEAR",
  };
}
module.exports = new frequencyCode();
