import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";


var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerAppealsRequest: "CONSUMER_APPEALS_REQUESTED",
  getConsumerAppealsSuccess: "CONSUMER_APPEALS_SUCCESS",
  getConsumerAppealsFailure: "CONSUMER_APPEALS_FAILURE",
  customerConcernsDataLoading: "CUSTOMER_CONCERNS_DATA_LOADING",
  customerConcernsSuccess: "CUSTOMER_CONCERNS_SUCCESS",
  customerConcernsFailure: "CUSTOMER_CONCERNS_FAILURE",
  anonymousConcernsDataLoading: "ANONYMOUS_CONCERNS_DATA_LOADING",
  anonymousConcernsSuccess: "ANONYMOUS_CONCERNS_SUCCESS",
  anonymousConcernsFailure: "ANONYMOUS_CONCERNS_FAILURE",
  getConcernsAppealDataLoading: "CONCERNS_APPEAL_DATA_LOADING",
  getConcernAppealSuccess: "CONCERN_APPEAL_SUCCESS",
  getConcernAppealFailure: "CONCERN_APPEAL_FAILURE",
  withdrawappealSuccess: "WITHDRAW_APPOINTMENT_SUCCESS",
  withdrawappealFailure: "WITHDRAW_APPOINTMENT_FAILURE",
  withdrawappealDataLoading: "WITHDRAW_APPOINTMENT_DATA_LOADING",
  appealDocumentCallDataLoading: "APPEAL_DOCUMENT_DATA_LOADING",
  appealDocumentDataSuccess: "APPEAL_DOCUMENT_DATA_SUCCESS",
  appealDocumentDataFailure: "APPEAL_DOCUMENT_DATA-FAILURE",
  anonymousDataLoading: "ANONYMOUS_DOCUMENT_DATA_LOADING",
  anonymousGetCallSuccess: "ANONYMOUS_DOCUMENT_DATA_SUCCESS",
  anonymousGetCallFailure: "ANONYMOUS_DOUCMENT_DATA_FAILURE"

};

// consumer Appeals Post call
export function getConsumerAppealsList(payload, selectedLanguage) {
  return (dispatch) => {
    dispatch(getConsumerAppealsRequestedAction());
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/concernappealapply";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: selectedLanguage,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(getConsumerAppealsSuccessAction(response));
          resolve(response);
        })
        .catch(function (error) {
          dispatch(getConsumerAppealsFailureAction(error));
          reject(error);
        });
    });
  };
}

// Action dispatch

function getConsumerAppealsRequestedAction() {
  return {
    type: actionTypes.getConsumerAppealsRequest,
  };
}

function getConsumerAppealsSuccessAction(response) {
  return {
    type: actionTypes.getConsumerAppealsSuccess,
    payload: response
  }
}
function getConsumerAppealsFailureAction() {
  return {
    type: actionTypes.getConsumerAppealsFailure
  }
}

//customer concerns POST call
export function customerConcernsPostCall(payload, selectedLanguage) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.customerConcernsDataLoading,
    })
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/concernappealapply";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: selectedLanguage,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(customerConcernsPostCallSuccess(response));
          resolve(response);
        })
        .catch(function (error) {
          dispatch(customerConcernsPostCallSuccessFailure(error));
          reject(error);
        });
    });
  };
}


function customerConcernsPostCallSuccess(data) {
  return {
    type: actionTypes.customerConcernsSuccess,
    payload: data,
  };
}

function customerConcernsPostCallSuccessFailure(error) {
  return {
    type: actionTypes.customerConcernsFailure,
    payload: error,
  };
}

// concern Appeal  Get call 
export function getConcernAppeal(agencyId,selectedLanguage) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.getConcernsAppealDataLoading,
    })
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    return new Promise((resolve, reject) => {

      var url = config.TigerContextURL + "/concernappeal/" + agencyId;
      const options = {

        headers: {
          "Content-Type": "application/json",
          Accept: 'application/json',
          locale: selectedLanguage,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(getConcernAppealSuccess(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          dispatch(getConcernAppealFailure(error));
          reject(error);
        });
    });
  };
}

export const getConcernAppealSuccess = (data) => ({
  type: actionTypes.getConcernAppealSuccess,
  payload: data,
});

export const getConcernAppealFailure = (error) => ({
  type: actionTypes.getConcernAppealFailure,
  payload: error,
});


//Anonymous Concern Post Call

export function anonymousConcernsPostCall(payload,selectedLanguage) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.anonymousConcernsDataLoading,
    })
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerURL + "/apipublic/concernapply";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: selectedLanguage,
          audit: JSON.stringify(audit),
        },

      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(anonymousConcernsPostCallSuccess(response));
          resolve(response);
        })
        .catch(function (error) {
          dispatch(anonymousConcernsPostCallSuccessFailure(error));
          reject(error);
        });
    });
  };
}


function anonymousConcernsPostCallSuccess(data) {
  return {
    type: actionTypes.anonymousConcernsSuccess,
    payload: data,
  };
}

function anonymousConcernsPostCallSuccessFailure(error) {
  return {
    type: actionTypes.anonymousConcernsFailure,
    payload: error,
  };
}


export function withdrawappealPostCall(payload, selectedLanguage) {
  return (dispatch) => {
    dispatch(
      { type: actionTypes.withdrawappealDataLoading }
    )
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    // return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/concernappeal/withdrawappeal";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: selectedLanguage,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(withdrawappealPostCallSuccess(response));
          // resolve(response);
        })
        .catch(function (error) {
          dispatch(withdrawappealPostCallFailure(error));
          // reject(error);
        });
    // });
  };
}

function withdrawappealPostCallSuccess(response) {
  return {
    type: actionTypes.withdrawappealSuccess,
    payload: response,
  };
}

function withdrawappealPostCallFailure(error) {
  return {
    type: actionTypes.withdrawappealFailure,
    payload: error,
  };
}


// appealDocument getCall 

export function appealDocumentGetCall(agencyID, selectedLanguage, webAppealIdValue) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url =

        config.TigerContextURL +
        "/notices/" +
        agencyID + "/" + webAppealIdValue
        ;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          locale: selectedLanguage,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      const appId = webAppealIdValue;
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(appealGetCallSuccess(response));
          return toShowPDFinWindow(response, appId);
        })
        .catch(function (error) {
          dispatch(appealGetCallFailure(error));
        });
    });
  };
}

function appealGetCallSuccess(data) {
  return {
    type: actionTypes.appealDocumentDataSuccess,
    payload: data,
  };
}

function appealGetCallFailure(error) {
  return {
    type: actionTypes.appealDocumentDataFailure,
    payload: error,
  };
}


function toShowPDFinWindow(data, appId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    setTimeout(()=>{
      window.navigator.msSaveOrOpenBlob(blob, appId + ".pdf");
    },500)
  
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    setTimeout(()=>{
      window.open(fileURL);
    },500)
   
  }
}


//concernDocumentGetCall

export function concernDocumentGetCall(agencyID, concernRefrenceNumber, selectedLanguage) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url =

        config.TigerContextURL +
        "/notices/" +
        agencyID + "/" + concernRefrenceNumber
        ;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          locale: selectedLanguage,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      const conRefNumber = concernRefrenceNumber;
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(appealGetCallSuccess(response));
          return toShowConcernPDFinWindow(response, conRefNumber);
        })
        .catch(function (error) {
          dispatch(appealGetCallFailure(error));
        });
    });
  };
}


function toShowConcernPDFinWindow(data, appId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    setTimeout(()=>{
      window.navigator.msSaveOrOpenBlob(blob, appId + ".pdf");
    },500)
  
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    setTimeout(()=>{
      window.open(fileURL);
    },500)
  }
}


//appealWithDrawGetCall

export function appealWithDrawDocumentGetCall(selectedLanguage, agencyID, docReferenceNumber) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url =

        config.TigerContextURL +
        "/notices/" + agencyID + '/' + docReferenceNumber;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          locale: selectedLanguage,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      // const appId =webAppealIdValue;
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(appealWithGetCallSuccess(response));
          return toShowWithDrawPDFinWindow(response);
        })
        .catch(function (error) {
          dispatch(appealWithGetCallFailure(error));
        });
    });
  };
}

function appealWithGetCallSuccess(data) {
  return {
    type: actionTypes.appealDocumentDataSuccess,
    payload: data,
  };
}

function appealWithGetCallFailure(error) {
  return {
    type: actionTypes.appealDocumentDataFailure,
    payload: error,
  };
}
  function  toShowWithDrawPDFinWindow(data, appId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    setTimeout(()=>{
      window.navigator.msSaveOrOpenBlob(blob, appId + ".pdf");
    },500)
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var byteCharacters =  atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    setTimeout(()=>{
      window.open(fileURL);
    },500)
  }
}


//anonymousGetCall
export function anonymousConcernsGetCall(selectedLanguage,getconcernRefNum,getDocumentKeyNum, getDocRefNum) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url = config.TigerContextURL.replace("res","public") + "/concern" +"/" + getconcernRefNum +"/"+ getDocRefNum +"/"+  getDocumentKeyNum ;

    
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          locale: selectedLanguage,
        },
      };
      const DocRefNum=getDocRefNum
      const concernRefNum =getconcernRefNum
      const getdocumentKey  =getDocumentKeyNum 
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(anonymousGetCallSuccess(response));
          return toShowAnonymousPDFinWindow(response,DocRefNum,concernRefNum,getdocumentKey)
        })
        .catch(function (error) {
          dispatch(anonymousGetCallFailure(error));
        });
    });
  };
}

function anonymousGetCallSuccess(data) {
  return {
    type: actionTypes.anonymousGetCallSuccess,
    payload: data,
  };
}

function anonymousGetCallFailure(error) {
  return {
    type: actionTypes.anonymousGetCallFailure,
    payload: error,
  };
}


function toShowAnonymousPDFinWindow(data, DocRefNum) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    setTimeout(()=>{
      window.navigator.msSaveOrOpenBlob(blob, DocRefNum + ".pdf");
    },500)
   
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    setTimeout(()=>{
      window.open(fileURL);
    },500)
   
  }
}



