import React, { Component } from 'react'

export class appealsGoBack extends Component {
    render() {
        return (
            <div  className='pad-all--double'>
                <h1>Are You Sure?</h1>
                <p style={{wordSpacing:"3px"}}>Information on the following pages will be lost.Are you<br /> sure you want to Go Back? If yes click Go Back else click <br /> Cancel. </p>
            </div>

        )
    }
}

export default appealsGoBack