import * as Props from "../../utils/components/shared";
import * as Types from "../../utils/constants/types";
import * as OptionsProps from "../../utils/constants/options";
import * as TextProps from "../../utils/constants/text";
import React from "react";
import Icon from "../../utils/components/dtaIcon";
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

let selectedLanguage = localStorage.getItem("selectedLanguage");
if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

export function incomeMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        heading: name + " " + "Income & Benefits",
        mainQuestion:
          "What type(s) of income and benefits does " + name + " have?",
        questionClearOption: "No one",
        noneTargetValue: "None",
        subQuestions: {
          "UI-TAFDCS": {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for TANF?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "EI-SELEMP": {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Self-Employment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "EI-WAGES":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Job Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-RSDI": {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Social Security (SSA / RSDI)?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-SSI" : {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Supplemental Security Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-VETBEN":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-UNEMPL":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Unemployment Compensation?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-WRKCMP":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Workman's Compensation?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-INTINC":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Interest Dividends from Stock and Bonds?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-MILPE":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Military Allotment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-RETFND":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Pension or Retirement Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-RRRET":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Railroad Retirement?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-LIHEAP":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for HUD Utility Assistance?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-RENTAL":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Income from Land Rental or Rental Property?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-CHSDOR":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Child Support?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-ALIMNY":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Alimony?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-CONTR":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount for Contributions from Friends or Relatives?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "EI-ROOMER":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Roomer Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "EI-BOARD":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Boarder Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "EI-FEDWRK":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Work Study?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          "UI-OTHER":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount for Other Income or Benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
        },
      };
    case ES:
      return {
        heading: "Ingreso y beneficios de " + name,
        mainQuestion: "¿Qué tipos de ingresos o beneficios tiene " + name + "?",
        questionClearOption: "Ninguna",
        noneTargetValue: "Ninguno",
        subQuestions: {
          "UI-TAFDCS":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de TANF?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "EI-SELEMP":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de trabajo por cuenta propia?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "EI-WAGES":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " cantidad de ingresos laborales?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-RSDI": {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de Seguro Social (SSA / RSDI)?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-SSI":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de Seguridad de Ingreso Suplementario?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-VETBEN":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de beneficios para veteranos?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-UNEMPL":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " monto de la Compensación por Desempleo?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-WRKCMP":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " ¿Cantidad de Compensación Laboral?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-INTINC":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de dividendos de intereses de acciones y bonos?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-MILPE":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " cantidad de asignación militar?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-RETFND":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " ¿Cantidad de Ingreso de Pensión o Jubilación?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-RRRET":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " ¿Cantidad de jubilación anticipada?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-LIHEAP":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de asistencia de servicios públicos de HUD?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-RENTAL":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                "¿Cantidad de ingresos por alquiler de terrenos o propiedades en alquiler?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-CHSDOR":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de manutención infantil?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-ALIMNY":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de pensión alimenticia?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "UI-CONTR":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " ¿Cantidad de contribuciones de amigos o familiares?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "EI-ROOMER":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de ingresos de roomer?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "EI-BOARD":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de ingresos internos?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          "EI-FEDWRK":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
              [Props.LABEL_OBJ_AFTER]: " cantidad de Trabajo Estudio?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
            },
          },
          "UI-OTHER":{
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de Otros Ingresos o Beneficios?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
        },
      };
    case PT:
      return {
        heading: "Renda e benefícios recebidos por " + name,
        mainQuestion:
          "Qual(is) tipo(s) de renda ou benefícios " + name + " recebe?",
        questionClearOption: "Ninguém",
        noneTargetValue: "Nenhum",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: name + " recebe como Salário?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Trabalho autônomo?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: " " + name + "  recebe como Estudo?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " +
                name +
                " recebe como SSI (Renda de segurança suplementar)?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                "  " +
                name +
                " recebe como RSDI (Seguro de aposentadoria, sobreviventes e deficiências)?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: "" + name + "  recebe como Desemprego?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Suporte infantil?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: " " + name + " recebe como Pensão?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Benefício de veterano?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: name + " recebe como Renda de aluguel?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]:
              "Quantas horas " +
              name +
              " trabalha para pagar o aluguel da propriedade?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                name + " recebe como Compensação do trabalhador?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que ",
              [Props.LABEL_OBJ_AFTER]:
                name + "recebe como outros rendimentos ou benefícios?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
        ],
      };
    case ZH:
      return {
        heading: name + "的收入和补助",
        mainQuestion: name + " 拥有哪种收入或补助？",
        questionClearOption: "无人",
        noneTargetValue: "没有",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: " 工资 总收入 是多少",
              [Props.LABEL_OBJ_AFTER]: " ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "个体经营 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 工作研究 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "  SSI（补充保障收入） 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]:
                "RSDI（退休，幸存者和残疾保险） 是多少 ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "失业 是多少 ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "子女抚养费 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "  养老 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "工伤赔偿其 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "租金收入 是多少  ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: name + "在租赁收入资产上的工作时数?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 工伤赔偿其 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 其他  是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
        ],
      };
    case VI:
      return {
        heading: name + "Thu nhập và trợ cấp của",
        mainQuestion: "Loại thu nhập hoặc trợ cấp nào mà " + name + " có?",
        questionClearOption: "Không có ai",
        noneTargetValue: "Khôngcó thu nhập và trợ cấp",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Tự làm chủ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: "của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Nghiên cứu việc làm",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  SSI (Thu Nhập An Sinh Bổ Sung)",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)?",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thất nghiệp",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  Trợ cấp nuôi con",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương hưu",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  Trợ cấp cho Cựu chiến binh",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thu nhập cho thu ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]:
              name + "  làm việc bao nhiêu giờ trên tài sản thu nhập cho thuê?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  Khôngcó thu nhập và trợ cấp",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
        ],
      };
    case HI:
      return {
        heading: name + "revni ak benefis",
        mainQuestion: "Ki tip(yo) revni ak benefis " + name + " genyen?",
        questionClearOption: "Pesonn",
        noneTargetValue: "Okenn",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " salè?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " travay-endepandan?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " travay etidyan?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " SSI (Revni Sekirite Siplemantè)?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " +
                name +
                " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " chomaj?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " sipò timoun?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " pansyon?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " benefis pou veteran?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " revni lwaye?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]:
              "Konbyen èdtan " +
              name +
              " travay sou pwopriyete ak revni lwaye a?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " Konpansasyon Travayè?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " lòt revni oswa benefis?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante..",
            },
          },
        ],
      };
    default:
      return;
  }
}

export function medicalExpenseMultilanguageLabels(name, language,type) {
  switch (language) {
    case EN:
      return {
        heading: "Medical costs for " + name,
        headingExpense: "Medical Expenses For " + name,
        question:
          "Does " + name + " drive to medical appointments or the pharmacy?",
        conditional: {
          label1:
            "How often does " +
            name +
            " drive to this address? To and from a provider should be counted as two trips.",
          label2:
            "How much does " +
            name +
            " pay for transportation, parking to get to medical appointments or the pharmacy?",
        },
        drivetoLabel:
          "How many medical appointments or pharmacies does " +
          name +
          " drive to?",
        kitHeaderLabel: "Caring for " + name,
        medConTitle: "'s Medical appointment/Pharmacy Address  " + name,
        medConHeader: "'s Medical appointment/Pharmacy Address  " + name,
        medQoneLabel:
          "How often does " +
          name +
          " drive to this address? To and from a provider should be counted as two trips.",
        medQtwoLabel:
          "How much does " +
          name +
          " pay for transportation, parking to get to medical appointments or the pharmacy ?",
          medicalExpensesHeading: " Medical Expenses For " + name ,
          medicalExpenseAmountLabel: "What is the amount " + name + " spends on "+ selectLabel(type) + "?",
          howManyMedicalAppointmentsOrPharmacy:"How many addresses, does " +name +" to medical appointments or the pharmacy?",
      };
    case ES:
      return {
        heading: "Costos médicos para " + name,
        headingExpense: "Gastos Médicos Para " + name,
        question: "¿" + name + " conduce a citas médicas o a la farmacia?",
        conditional: {
          label1:
            "¿Con qué frecuencia " +
            name +
            " conduce a citas médicas o a la farmacia? Hacia y desde un proveedor debe contarse como dos viajes ",
          label2:
            "¿Cuánto paga " +
            name +
            "  por transporte y estacionamiento para ir a citas médicas o la farmacia?",
        },
        drivetoLabel:
          "¿A cuántas citas médicas o farmacias conduce " + name + " conduce?",
        kitHeaderLabel: "Caring for " + name,
        medConTitle: "Dirección de la cita médica o farmacia de ",
        medConHeader: "Dirección de la cita médica o farmacia de ",
        medQoneLabel:
          "¿Con qué frecuencia " +
          name +
          " conduce a citas médicas o a la farmacia? Hacia y desde un proveedor debe contarse como dos viajes ",
        medQtwoLabel:
          "¿Cuánto paga " +
          name +
          " por transporte y estacionamiento para ir a citas médicas o la farmacia?",
          medicalExpensesHeading: " Gastos Médicos Para " + name ,
          medicalExpenseAmountLabel: "¿Cuánta cantidad " + name + " gasta en "+ selectLabel(type) + "?",
          howManyMedicalAppointmentsOrPharmacy:"¿Cuántas direcciones, hace " +name +" ¿A las citas médicas o a la farmacia?",
      };
    case PT:
      return {
        heading: "Custos médicos de " + name,
        question: name + " vai à farmácia ou a consultas médicas?",
        conditional: {
          label1:
            "Com que frequência " +
            name +
            "  vai até a consulta médica/farmácia? Ir e voltar de um prestador de serviço deve contar como duas viagens",
          label2:
            "Quanto " +
            name +
            "  paga pelo transporte e estacionamento quando vai à consulta médica ou à farmácia?",
        },
        drivetoLabel:
          "A quantas farmácias ou consultas médicas " + name + " vai?",
        kitHeaderLabel: "Caring for " + name,
        medConTitle: "Endereço da farmácia ou consulta médica de ",
        medConHeader: "Endereço da farmácia ou consulta médica de ",
        medQoneLabel:
          "Com que frequência " +
          name +
          "  vai até a consulta médica/farmácia? Ir e voltar de um prestador de serviço deve contar como duas viagens",
        medQtwoLabel:
          "Quanto " +
          name +
          " paga pelo transporte e estacionamento quando vai à consulta médica ou à farmácia?",
      };
    case ZH:
      return {
        heading: name + " 的医疗费 ",
        question: name + " 是否开车到医疗机构或药房？",
        conditional: {
          label1:
            name +
            " 开车到医疗机构或药房的频率？接送看护人的费用应计为两趟行程。",
          label2: name + " 到医疗机构或药房所支付的交通费和停车费是多少？",
        },
        drivetoLabel: name + " 开车到几个医疗机构或药房?",
        kitHeaderLabel: "Caring for " + name,
        medConTitle: name + " 的医疗机构或药房地址",
        medConHeader: name + " 的医疗机构或药房地址",
        medQoneLabel:
          "" +
          name +
          " 开车到医疗机构或药房的频率？接送看护人的费用应计为两趟行程。",
        medQtwoLabel:
          " " + name + " 到医疗机构或药房所支付的交通费和停车费是多少？",
      };
    case VI:
      return {
        heading: "Các chi phí y tế đối với " + name,
        question:
          name + "  có lái xe đến các cuộc hẹn y tế hoặc nhà thuốc tây không?",
        conditional: {
          label1:
            name +
            " lái xe đến các cuộc hẹn y tế/nhà thuốc bao nhiêu lần? Đến và về từ nhà cung cấp dịch vụ nên được tính là hai chuyến.",
          label2:
            name +
            " trả tiền chuyên chở và đậu xe cho các cuộc hẹn y tế hoặc hiệu thuốc là bao nhiêu?",
        },
        drivetoLabel:
          "Có bao nhiêu cuộc hẹn y tế hoặc nhà thuốc mà " +
          name +
          " lái xe đến?",
        kitHeaderLabel: "Caring for " + name,
        medConTitle:
          "Địa chỉ của cuộc hẹn y tế hoặc địa chỉ nhà thuốc của  " + name,
        medConHeader:
          "Địa chỉ của cuộc hẹn y tế hoặc địa chỉ nhà thuốc của  " + name,
        medQoneLabel:
          name +
          " lái xe đến các cuộc hẹn y tế/nhà thuốc bao nhiêu lần? Đến và về từ nhà cung cấp dịch vụ nên được tính là hai chuyến.",
        medQtwoLabel:
          name +
          " trả tiền chuyên chở và đậu xe cho các cuộc hẹn y tế hoặc hiệu thuốc là bao nhiêu?",
      };
    case HI:
      return {
        heading: "Depans medikal pou " + name,
        question:
          "Èske " + name + " kondii pou ale nan randevou medikal oswa famasi?",
        conditional: {
          label1:
            "Konbyen fwa " +
            name +
            " kondi pou ale nan adrès sa a? Pou ale ak soti lakay founisè ta dwe konte kòm de vwayaj.",
          label2:
            "Konbyen " +
            name +
            " peye pou transpò, pakin pou ale nan randevou medikal oswa famasi?",
        },
        drivetoLabel:
          "Nan konbyen randevou medikal oswa famasi " + name + " kondi ale?.",
        kitHeaderLabel: "Pran swen " + name,
        medConTitle: "'Adrès Randevou medikal/famasi  " + name,
        medConHeader: "'Adrès Randevou medikal/famasi  " + name,
        medQoneLabel:
          "Konbyen fwa " +
          name +
          " kondi pou ale nan adrès sa a? Pou ale ak soti lakay founisè ta dwe konte kòm de vwayaj.",
        medQtwoLabel:
          "Konbyen " +
          name +
          " peye pou transpò, pakin pou ale nan randevou medikal oswa famasi ?",
      };
    default:
      return;
  }
}

export function beforeYoustartMlanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        okLabel: "Ok",
        consentForDHRLabel:"Do you give consent for DHR to verify the information provided in this application (Name, Address, Date of Birth, and/or SSN) through a third-party verification system?",
        optionYesNo: ["Yes", "No"],
        heading: "Before You Start",
        addressValidHeaderText: "Address Validation",
        addressValidLabelText: "Please select from the results below.",
        enteredHomeAddressLabelText: "Entered home address:",
        suggestedHomeAddressLabelText: "Suggested home address:",
        enteredMailingAddressLabelText: "Entered mailing address:",
        suggestedMailingAddressLabelText: "Suggested mailing address:",
        howApplyLabel: "How does applying work?",
        fieldLabel1:
          "This application is about you, your household, your income, and your expenses. It should only take about 20 minutes.",
        fieldLabel2: "If they apply to you, have these numbers ready!",
        fieldLabel3:
          "You do not need a fixed address or phone number to apply for SNAP, but DHR does need a way to reach you to complete your online SNAP application. If you do not have an address we can send mail to, please call Alabama DHR office Line at",
        fieldLabel4: "or go to your local",
        fieldLabel3Link: "Alabama DHR office.",
        fieldLabel5: "",
        snapFieldLabel1:
          "You may complete your Application using the following methods:",
        fieldFactors: [
          "Online by clicking Get Started. This is the fastest way!",
          "If you do not wish to complete your SNAP application online, you may download and print your paper application ",
          ". You can submit your form by:",
          "Fax, mail, email or take your paper application to your local county DHR office by clicking ",
          "here",
        ],
        snapFieldLabel2:
           "Before you continue, please read the following.",
        fieldFactors2: [
           "You have the right to file an application the same day you contact your county office.",
           "To file an application, you are only required to provide your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information.",
           "Mail, fax, e-mail or take this application to the SNAP Office in the county where you live. You may also apply online at https://acesselfservice.dhr.alabama.gov.",
           "To get the address or phone number of your local county office, call toll-free: 1-833-822- 2202 or online at www.dhr.alabama.gov."
        ],
        snapFieldLabel3:
           "You may receive expedited assistance within 7 days if:",
        fieldFactors3: [
           "Your household’s monthly gross income is less than $150 and your liquid resources (cash, checking, or savings) are less than $100 OR",
           "Your household’s monthly rent/mortgage and utilities are more than your household’s income and liquid resources OR",
           "Your household is a destitute migrant or seasonal farmworker household and your liquid resources are less than $100."
        ],
        snapFieldLabel4:
           "Additional things you need to know before submitting a SNAP application consist of:",
        fieldFactors4: [
           "If you submit your application using this website after 5:00 pm CST or on a weekend or holiday, it will be received the next business day.",
           "When a resident of an institution is jointly applying for SSI and SNAP prior to leaving the institution, the filing date on the application is the date of release of the applicant from the institution.",
           "You must be interviewed after your application is received. You may be notified of your appointment time and date.",
           "We must approve or deny your application within 30 days. Applications must be processed in accordance with SNAP procedures, including timeliness, notice, and fair hearing requirements regardless of whether the application is for SNAP and other programs.",
           "Also, a household may not be denied SNAP benefits solely because it has been denied benefits from other programs."
        ],
        snapFieldLabel5:
            "If you are eligible, your benefits will be determined from the day we receive your signed application. This means your first month of benefits will be prorated from the day we receive your application.",
        tanfFieldLabel1:
          "This application is about you and your household. It should only take about 20 minutes.",
        tanfFieldLabel2: "If they apply to you, have these numbers ready!",
        tanfFieldFactors: [
          "Birth dates",
          "Social Security Number (SSN)",
          "Incomes",
        ],
        tanfInstruction:
          "To use this application to apply for Family Assistance, you must at least enter your name, address, and signature for us to process your application. If you don't want to enter this information, you will need to contact your local office. Click below to get an application form to print, fill out, and sign. Then mail or take it to your local office.",
        tanfApplicationFormLink: "Application Form - English",
        tanfClickHereRulesText:
          "Click here to look at information about eligibility or program benefits:",
        tanfEligibilityLink: "Summarized Eligibility Requirements - English",
        tanfDOHLink: "Dept. of Human Resources",
        tanfImportantInfo:
          "Important information about use and disclosure of your entries.",
        tanfInformationYouEnterText:
          "Information you enter can only be used by our staff. Once you click submit, your information will be sent to the office serving the area where you live for action.",
        tanfTellingOthersText:
          "Telling others about the information you provide is protected from disclosure under the Code of Alabama 1978, 38, Section 2-6(8). It includes sharing within our department as well as with other state and federal offices and law enforcement.",
        tanfTermsAndConditions: "Terms and Conditions",
        boBackButtonLabel: "Go Back",
        getStartButtonLabel: "Get Started",
        sIncome: "'s Income",
        incomeOf: "Income of ",
        resourcesOf: "Resource of ",
        editButtonLabel: "Edit",
        amountLabel: "Amount",
        bankNameLabel: "Name of the bank/brokerage",
        perLabel: "Per",
        ofLabel: "of",
        your: "Your ",
        renderAppNoLabel: " Application Number: ",
        renderSnapAppNoLabel: () => {
          return (<span>Your <b>SNAP</b> Application Number: </span>)
        },
        rendertanfAppNoLabel: () => {
          return (<span>Your <b>TANF</b> Application Number: </span>)
        },
        renderSnapInfoApp: () => {
          return (<div>
            <h4>Information Needed To Process An Application</h4>
            <p>Please provide as much of the following information as possible when submitting your SNAP application. This may help us in processing your application faster. If you need assistance in obtaining any verifications, please contact the SNAP Office for assistance.</p>
            <ol>
              <li>Proof of your identity (examples: birth certificate, driver's license, school ID)</li>
              <li>Names and dates of birth of all persons who live in your home.</li>
              <li>Social Security Numbers for each household member. If an individual does not have a Social Security Number the SNAP Office will tell you how to apply for one. You only have to provide social security numbers (SSN) information for those household members that you are asking for SNAP benefits. </li>
              <li>Current check stubs, pay slips, or employer's statements that show the gross amount of money paid, pay dates and hours worked each pay period.</li>
              <li>A signed and dated statement from any person who gives money to you or to anyone in the household. The statement should show how much money is given, when and how often.</li>
              <li>Award letters or stubs that show the amount of unemployment compensation paid to
              you or any other person in your household.</li>
              <li>Current benefit letters from Social Security, Railroad Retirement, Veteran's Administration, retirement association, or any other agency from which you or anyone in your household receives a check.</li>
              <li>Current proof of rent or mortgage payments, including lot rent, property taxes, and homeowners insurance.</li>
              <li>Current receipts for utilities (gas, lights, water, telephone, garbage service).</li>
              <li>A signed and dated statement from any roomer and/or boarder showing the amount they pay and how often.</li>
              <li>U.S. Citizenship and Immigration Service (USCIS) forms if any member of your household is a non-citizen. You must give citizenship/immigration information for those household members that you are asking for SNAP benefits.</li>
              <li>Current bank statements of savings and checking accounts in banks, savings and loan associations, credit unions, etc.</li>
              <li>Letter or other statement showing the value of any stocks or bonds owned by any household member.</li>
              <li>Current signed and dated statements from the person providing care for a child or other dependent, including the amount charged and how often paid.</li>
              <li>If you have a household member who is age 60 or older or a member who is disabled, your household may be eligible for a medical deduction. You will need to provide proof of their medical expenses, including health insurance policies, premiums, and reimbursements from any medical insurance policy, medicaid or medicare.</li>
              <li>Verification of any legally obligated child support (i.e. court order) paid by any member of your household to/for a non-household member including the amount they actually paid and how often paid.</li>
            </ol>
          </div>
          )
        },
        renderSnapImpInfo: () => {
          return (<div>
            <h4>IMPORTANT INFORMATION ABOUT FOOD ASSISTANCE</h4>
            <p>You have the right to have your application acted on within <b>thirty days</b> without regard to race, sex, religion, national origin, age, handicap or political belief. You have the right to know why your application is denied, or your benefits reduced or terminated. You have the right to request a conference or fair hearing either orally or in writing if you are not satisfied with any decision of the county department. You have the right to be represented by any person you choose. You have the right to examine your food assistance case file in relation to any hearing you may have.</p>
            <p>You have the right to <b>confidentiality.</b> The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.</p>
            <p>The information provided in connection with this application will be subject to verification by Federal, State and local officials to determine if such information is true. If any information is found to be untrue or incorrect, food assistance benefits may be denied to the applicant and the applicant may be subject to <b>criminal prosecution for knowingly providing incorrect information.</b> Any person authorized to act on behalf of the household may be barred from participation as a representative for up to one year or may be subject to fines and/or prosecution if s/he breaks any rules on purpose.</p>
            <p>If a food assistance claim arises against your household, the information on this application, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.</p>
            <p><b>SOCIAL SECURITY NUMBERS:</b> The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008, as amended, 7 US.C. 2011-2036, to determine eligibility for food assistance. The Social Security Number will be used in the administration of the Food Assistance Program to check the identity of household members to prevent duplicate participation and to facilitate making changes. Your SSN will also be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. This may result in criminal or civil administrative claims against persons fraudulently participating in the Food Assistance Program. <b>Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member.</b></p> 
            <p><b>VERIFICATION:</b> To determine eligibility, you may have to provide documents to prove what you have stated on the application. If you are unable to provide proof, <b>you may request help from your worker.</b> The information given on this application will be checked by using the State Income and Eligibility Verification System, other computer matching systems, program reviews and audits. This includes such information as receipt of Social Security benefits, Unemployment benefits, unearned income such as interest and dividends, and wages from employment. When discrepancies are found, verification of this information may be obtained through contact with a third party such as employers, claims representatives or financial institutions. This information may affect your eligibility and level of benefits. In addition, any information given may also be checked by other Federal Aid Programs and Federally Aided State Programs such as school lunch, Family Assistance, and Medicaid. <b>If you give false information on purpose, legal or administrative action may be taken against you. You may have to repay food assistance benefits that you receive to which you are not entitled.</b></p>
            <p>Some elderly and/or disabled household members are allowed certain medical expenses as a deduction if these expenses are reported and proof of the expense is provided to us. Allowable medical expenses include expenses such as the following: prescription drugs, hospital and nursing home bills, doctor, dentist, or other health care professional visits, over the counter medication prescribed by a doctor, Medicare premium, hospital insurance premium, insurance for prescription drug coverage, transportation expenses for travel to doctors, hospitals, drugstores such as amount charged for transportation or for the number of miles driven in your personal vehicle, medical appliances or equipment such as hearing aids, wheelchairs, artificial limbs, eye glasses, contact lenses, dentures, etc., attendant care or homemaker services, service animal expenses such as animal food and veterinary care.</p> 
            <p><b>CITIZENSHIP AND IMMIGRATION STATUS:</b> Citizenship/immigration information is used to determine eligibility for food assistance. Only U. S. citizens and eligible immigrants may participate in the Food Assistance Program. Any household member who is not a citizen or permanent resident alien may be left out of your food assistance household. <b>Providing citizenship/immigration information is voluntary. Failure to provide this information for each household member will result in disqualification of that member. You will still have to give information such as income for this member.</b> The Food Assistance Division will check with U. S. Citizenship and Immigration Service (USCIS) on all non-citizens that you are asking to get food assistance benefits. We will not check on the non-citizens you choose not to include in your food assistance household.</p> 
            <p><b>You will be ineligible for benefits if you refuse to cooperate in completing the application process or in subsequent reviews of eligibility including reviews resulting from reported changes, recertification, or as a part of a State or Federal Quality Control Review.</b></p> 
            <p><b>Your signature on the application will serve as authorization for State and Federal Quality Control Reviewers to verify your household circumstances for food assistance eligibility purposes.</b></p>
            <p><b>You or any member of your household may be disqualified from receiving benefits if you or the member voluntarily quits a job or reduces the number of hours worked without good cause.</b></p>
            <p><b>Your household will not receive an increase in food assistance benefits if anyone in the household fails to comply with the requirements of another income based (means tested) program such as Family Assistance.</b></p>
            <p><b>You are not to use food assistance benefits to buy ineligible items such as alcoholic drinks or tobacco or pay on credit accounts.</b></p>
          </div>
          )
        },
        renderSnapNVRAInfo: () => {
          return (<div>
            <h4>NVRA</h4>
            <p>The National Voter Registration Act (NVRA) requires the Department of Human Resources to provide you with an opportunity to register to vote. </p>
            <p>Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency. </p>
            <p>If you are already registered to vote at your current address, you do not need to reregister. If you would like to register to vote or would like to change your address for voting purposes, you may do so through the Secretary of State’s office at the following link: <a target="_blank"  href="https://www.sos.alabama.gov/alabama-votes">Secretary of State</a> </p>
            <p>If you would like help in filling out the voter registration form, we will help you. You may seek assistance with the application form at the time of your interview or by calling your local Department of Human Resources office located within your county. The decision to seek or accept help is yours. You may fill out the application form in private.</p>
            <p>The Department of Human Resources will not use any information you submit related to voter registration for any other purpose.</p>
            <p>If you believe that someone has interfered with your right to register or to decline to register to vote, your right to privacy in deciding whether to register or in applying to register to vote, or your right to choose your own political party or other political preference, you may file a complaint with the Secretary of State Capitol, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 or by calling 334-242-7210 or 1-800-274-VOTE (1-800-274-8683).</p>
            <p>This project has been funded at least in part with Federal funds from the U.S. Department of Agriculture, Food and Nutrition Service. The contents of this publication do not necessarily reflect the view of policies of the U.S. Department of Agriculture, nor does mention of trade names, commercial products, or organizations imply endorsement by the U.S. government.</p>
          </div>
          )
        },
        renderTanfVoterInfo: () => {
          return (<div>
            <p><b>Voter Registration Information</b></p>
            <p><b>Register to Vote here:</b></p>
            <p>https://sos.alabama.gov/alabama-votes/voter/register-to-vote</p>
            <p>The National Voter Registration Act (NVRA) requires the Department of Human Resources to provide you with an opportunity to register to vote.</p>
            <p>Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency.</p>
            <p>If you are already registered to vote at your current address, you do not need to re-register. If you would like to register to vote or would like to change your address for voting purposes, you may do so through the Secretary of State’s office at the following link: <a target="_blank"  href="https://www.sos.alabama.gov/alabama-votes">Secretary of State</a> </p>
            <p>If you would like help in filling out the voter registration form, we will help you. You may seek assistance with the application form at the time of your interview or by calling your local Department of Human Resources office located within your county. The decision to seek or accept help is yours. You may fill out the application form in private.</p>
            <p>If you choose to apply to register to vote or if you decline to register to vote, the information on your application form will remain confidential and will be used for voter registration purposes only.</p>
            <p>If you believe that someone has interfered with your right to register or to decline to register to vote, your right to privacy in deciding whether to register or in applying to register to vote, or your right to choose your own political party or other political preference, you may file a complaint with the Secretary of State at State Capitol, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 or by calling 334-242-7210 or 1-800-274-VOTE (1-800-274-8683).</p>
          </div>
          )
        },
        renderSnapSummarizedInfo: () => {
          return (<div>
            <h3>DEPARTMENT OF HUMAN RESOURCES</h3>
            <h4>Food Assistance Program</h4>
            <h4>Summarized Eligibility Requirements</h4>
            <p>If you have difficulty communicating with us because you do not speak English or have a disability, we can provide free language assistance or other aids and services to assist you. These services are available by phone or in person upon request.</p>
            <p>Households applying for or receiving food assistance benefits must meet all applicable eligibility requirements based on food assistance policies. Time limits and requirements of other programs do not affect a householdʼs eligibility for food assistance benefits. A household may still qualify for food assistance benefits even if eligibility ends in another program. Households must cooperate with the agency in establishing eligibility for food assistance. Failure to meet these requirements can result in a denial or termination of the food assistance case.</p>
            <h4><u>TECHNICAL REQUIREMENTS</u></h4>
            <ol>
              <li><b>Household Members.</b> The food assistance household is composed of individuals who live together and purchase and prepare their meals together for home consumption. Certain individuals, such as spouses and children under age 22, must be included in one food assistance household regardless of their method of buying food and preparing meals.</li>
              <li><b>Strikers.</b> Households with striking members shall be ineligible to participate in the Food Assistance Program, unless the household was eligible for benefits the day before the strike and is otherwise eligible at the time of application. However, the household shall not receive an increased allotment as a result of a decrease in income of the striking household member(s).</li>
              <li><b>Citizenship and Alien Status.</b> Citizenship/immigration information is used to determine eligibility for food assistance. Only U.S. citizens and eligible aliens may participate in the Food Assistance Program. Any household member who is not a citizen or permanent resident alien may be left out of your food assistance household. Providing citizenship/immigration information is voluntary. The Food Assistance Division will check with the U. S. Citizenship and Immigration Service (USCIS) only for those household members that you are asking for food assistance benefits. We will not check on the non-citizens you do not include in your food assistance household but their income may count in determining the eligibility and food assistance allotment for the other people included in the food assistance household. Failure to provide this information will result in ineligibility (no benefits) for these members.</li>
              <li><b>Social Security Numbers.</b> The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008, as amended, 7 U.S.C. 2011-2036, to determine eligibility for food assistance. The Social Security Number is used in computer matching and program reviews or audits to make sure the household is eligible for the food assistance benefits it receives. The SSN will be used to check the identity of household members to prevent duplicate participation and to facilitate making changes. Providing a social security number for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member.
              <br />The household must furnish a Social Security Number for each household member that you are asking for food assistance benefits. If a household member does not have a number, s/he must apply for one.</li>
              <li><b>Residence.</b> Households must apply for food assistance in the county in which they live. They cannot receive food assistance in more than one county or state in a month.</li>
              <li><b>Work Requirements.</b> Unless exempt from work registration, each member of your household must meet the following work
              requirements:
                <ul>
                  <li><b>must be registered for work</b></li>
                  <li><b>must not quit a job voluntarily</b></li>
                  <li><b>must not voluntarily reduce hours at a job</b></li>
                  <li><b>must accept a suitable job that is offered. (The job must be 30 hours weekly or equal to 30 hours X minimum wage).</b></li>
                </ul>
                If a non-exempt member of your household fails to meet work requirements, [s]he cannot get food assistance. This could reduce or stop your household’s food assistance.
                <p><b>Able-Bodied Adults Without Dependents (ABAWDs)</b></p>
                <p>People between the ages of 18 and 49 (under age 50) who have no children and are not disabled must meet other special work requirements if they want to get food assistance. Federal law calls these people “Able-Bodied Adults without Dependents,” or “ABAWDs.” They may have to work in order to get more than three months of food assistance in the period from Jan 2022 to Dec 2024. But there are exceptions to the three-month time limit. Someone between the ages of 18 and 49 (under age 50) can get food assistance for more than three months if the person meets any one of these exceptions:</p>
                <ol>
                  <li>The person works at least 80 hours a month.</li>
                  <li>The person receives disability benefits.</li>
                  <li>The person receives unemployment compensation.</li>
                  <li>Anyone who has applied for unemployment compensation but has not heard yet is also exempt while waiting for a decision.</li>
                  <li>The person who is not receiving disability but has a physical or mental problem that limits the work [s]he can do. This may have to be verified by a doctor or other medical professional.</li>
                  <li>The person is unfit for work. For example, chronic homelessness may be an indicator that someone is unfit for work.</li>
                  <li>There is a child in the home less than 18 years old</li>
                  <li>The person cares for an adult who is incapacitated</li>
                  <li>The person is pregnant.</li>
                  <li>The person gets Family Assistance and is in a Family Assistance work program.</li>
                  <li>The person goes to a job training program at least 80 hours a month.</li>
                  <li>The person volunteers at least 80 hours a month doing something that serves a public purpose.</li>
                  <li>The total amount of time the person spends each month working, going to training and volunteering is at least 80 hours a month.</li>
                  <li>The person goes to school at least half time.</li>
                  <li>The person goes to rehab for alcohol or drugs.</li>
                  <li>The person works at least 80 hours a month for non-monetary benefits. For example, the person works for free rent or utilities.</li>
                </ol>
              </li>
              <h4><u>FINANCIAL REQUIREMENTS</u></h4>
                <li><b>Income.</b> Income limits vary according to the household size. Households that contain no elderly or disabled individuals must meet both the gross (income before deduction) and the net income (income after allowable deductions) limits. Households that contain an elderly (age 60 or over) individual or a disabled individual must meet only the net income limits. See table below for both the net and gross income limits according to household size. Income includes wages, salaries, commissions, social security benefits, SSI, veteran’s benefits, child support, contributions, unemployment compensation, etc.
                <p style={{textAlign:'center'}}>Monthly Income Eligibility Limits Effective 10/1/2022 - 9/30/2023</p>
                <table>
                  <tr>
                    <th>Household Size</th>
                    <th>Gross Income Limits</th>
                    <th>Net Income Limits</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>$1,473</td>
                    <td>$1,133</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$1,984</td>
                    <td>$1,526</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$2,495</td>
                    <td>$1,920</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$3,007</td>
                    <td>$2,313</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$3,518</td>
                    <td>$2,706</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$4,029</td>
                    <td>$3,100</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$4,541</td>
                    <td>$3,493</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$5,052</td>
                    <td>$3,886</td>
                  </tr>
                  <tr>
                    <td>Each Additional Member</td>
                    <td>$512</td>
                    <td>$394</td>
                  </tr>
                </table>
              </li>
              <li>
              <b>Deductions.</b> Only the following are allowable deductions for food assistance:
              <ul>
                <li>Standard Deduction. The standard deduction will vary according to household size. The minimum amount for household sizes 1 through 3 is $193. It is $193 for a household size of 4. It is $225 for a household size of 5. The maximum amount allowed is 258 for a household of six and above.</li>
                <li>Earned Income Deduction. This deduction is 20% of the gross earned income.</li>
                <li>Self-Employment Deduction. Households with self-employment income are entitled to a standard deduction of 40% of the gross proceeds from the self-employment income as a cost of doing business.</li>
                <li>Medical Deduction. Elderly and/or disabled individuals may be entitled to a medical deduction for out-of-pocket medical expenses they incur in excess of $35 per month. Verification may be required.</li>
                <li>Dependent Care. The costs incurred for the care of a child or other dependent in order for a household member to work, seek employment, attend training, or pursue education that is preparatory for employment.</li>
                <li>Child Support. Legally obligated child support paid by a household member to or for a nonhousehold member.</li>
                <li>Shelter Costs. These costs include rent, mortgage, property taxes, insurance on the structure, utilities, etc.</li>
              </ul>
              </li>
              <li><b>Resources.</b> The maximum allowable resource limits for households that contain a disqualified member shall not exceed $2,750 ($4,250 for households containing an elderly or disabled member). Resources include cash on hand, bank accounts, stocks, savings bonds, lottery, and gambling winnings of $4,250 or more, etc.</li>
              <h4><u>OTHER</u></h4>
              <li><b>Application Processing.</b> The application process includes completing an application, filing the form in the county in which the household lives, being interviewed, and having certain information verified. Households that meet the following criteria will have their application acted on within 7 calendar days: households with less than $150 in monthly gross income and their liquid resources, such as cash or checking/savings' accounts are less than $100; the household’s monthly rent/mortgage and utilities are more than the household’s gross monthly income and liquid resources; the household is a destitute migrant or seasonal farmworker with less than $100 in liquid resources. 
                <p>All other households shall have their application acted upon within 30 days from the date they apply.</p>
              </li>
              <li><b>Confidentiality and Disclosure of Information.</b> All information given to the Department is confidential and any use or disclosure will be made only for certain limited purposes allowed under State or Federal laws and regulations. Such purposes include, but are not limited to, establishing eligibility, determining benefit amount and providing services to applicants and recipients. Information about how long you have received food assistance will be released to the State Employment Service for purposes of qualifying your employer for the Work Opportunity Tax Credit (WOTC).</li>
              <li><b>Allotment Amounts.</b> The amount of food assistance a household actually receives depends on the number of people in the food assistance household and the amount of their net income. See table below for the maximum food assistance allotments by household size.
              <p style={{textAlign:'center'}}>Maximum Monthly Allotments Effective 10/01/22</p>
                <table>
                  <tr>
                    <th>Household Size</th>
                    <th>Maximum Food Assistance Allotment</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>$281</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$516</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$740</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$939</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$1,116</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$1,339</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$1,480</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$1,691</td>
                  </tr>
                  <tr>
                    <td>Each Additional Person</td>
                    <td>$211</td>
                  </tr>
                </table>
              </li>
              <li><b>Authorized Representative.</b> The head of household, spouse, or other responsible household member may designate an authorized representative to act on behalf of the household. This person may apply for benefits, obtain the benefits and/or use the benefits for the food assistance household.</li>
              <li><b>Simplified Reporting.</b> Some households have to report their household situation semi-annually on a six-month report form. These households have to report on a six-month basis regardless of whether there have been any changes in their situation. Required Reportable Changes: (1) If the household’s income increases to more than the maximum income level for their household size; (2) ABAWDs work hours fall below 20 hours per week, average 80 hours per month; (3) Lottery and gambling winnings of $4,250 or more.</li>
              <li><b>Fair Hearings.</b> A household not satisfied with agency action affecting its participation in the Food Assistance Program has the right to request a fair hearing within 90 days of the action being appealed. The request may be made orally or in writing to the County Department of Human Resources, Food Assistance Office or to the Department of Human Resources, Food Assistance Division, S.Gordon Persons Building, 50 N. Ripley Street, Montgomery, Alabama 36130-4000, telephone (334) 242-1700. The household’s case may be presented by a household member or a representative, such as a legal counsel, a relative, a friend or other spokesperson. To obtain free legal advice, contact Legal Services Alabama statewide intake toll-free number at 1-866-456-4995 or at their statewide online intake website at <b><u>WWW.ALABAMA.LEGALHELP.ORG.</u></b></li>
              <li><b>Ineligible Individuals.</b> The following individuals are ineligible to receive food assistance:
                <ul>
                  <li>Anyone who is fleeing to avoid prosecution, custody, or confinement after a felony conviction under the law.</li>
                  <li>Anyone in violation of his/her parole/probation.</li>
                  <li>Anyone found guilty by a court of using food assistance benefits to buy firearms, ammunition, or explosives. These individuals are permanently disqualified from receiving food assistance benefits.</li>
                  <li>Anyone using food assistance benefits to buy illegal drugs may be disqualified from receiving food assistance from 2 years to permanently.</li>
                  <li>You may be ineligible if you are convicted after August 22, 1996 of a felony under Federal or State law for possession, use or distribution of a controlled drug substance.</li>
                  <li>Anyone found guilty by a court of buying or selling food assistance benefits of $500 or more. These individuals are permanently disqualified from receiving food assistance.</li>
                  <li>Anyone who misrepresents his identity or residence in order to receive multiple food assistance benefits simultaneously. These individuals are ineligible to receive food assistance benefits for 10 years from the date of conviction.</li>
                  <li>Anyone found guilty of an intentional program violation (IPV) through an Administrative Disqualification Hearing or by a Federal, State, or local court or signed a hearing waiver, will be ineligible for food assistance for 12 months for first violation, 24 months for the second violation and permanently for the third violation. These individuals may also be fined or imprisoned or both, and may be subject to federal prosecution and penalties.</li>
                  <li>Anyone physically and mentally fit between the ages of 18 and 50 enrolled in an institution of higher education, who does not meet certain eligible student criteria.</li>
                  <li>Anyone who does not meet citizenship or alien status requirements.</li>
                  <li>Anyone who refuses or fails without good cause to provide a social security number.</li>
                  <li>Anyone disqualified for not complying with work requirements. This includes voluntarily quitting a job or reducing the number of hours worked without good cause.</li>
                  <li>Anyone disqualified for violating program regulations.</li>
                  <li>Any household that refuses to cooperate with Quality Control.</li>
                </ul>
              </li>
              <li><b>Claims & Trafficking.</b> Households must report correct information about their situation. Households must use their benefits to buy eligible food for their personal use. There are penalties that can be applied to the household including a requirement to repay benefits received incorrectly. If a household member intentionally provides false information, sells or tries to sell or trade benefits, buys ineligible items such as alcoholic drinks, or tobacco, or pay on credit accounts or gives away or sells an EBT card, there are other penalties that can be applied including disqualification from the program from one year to permanent and prosecution in court.</li>
              <li><b>Voter Registration.</b> If you are not registered to vote where you live now, you may register to vote during the Food Assistance application process. We can help you with the application or you may choose to fill out the application in private. Your decision to register or decline to register to vote does not affect the decision about your Food Assistance application or any benefits you might receive. If you believe that someone has interfered with your right to register or to decline to register to vote, your right to privacy in deciding whether to register or in applying to register to vote, or your right to choose your own political party or other political preference, you may file a complaint with the Secretary of State, Alabama State Capitol, 600 Dexter Avenue Suite E-210, Montgomery, AL 36130.</li>
            </ol>
          </div>
          )
        },
        renderTanfSummarizedInfo: () => {
          return (<div>
            <h3>DEPARTMENT OF HUMAN RESOURCES</h3>
            <h4>Food Assistance (FA) Program</h4>
            <h4>Summarized Eligibility Requirements</h4>
            <h4>IS MY FAMILY ELIGIBLE?</h4>
            <p>Persons who apply for or receive cash benefits must meet certain requirements. They must help determine eligibility for benefits. They must participate in activities that will help them become able to support and better care for their families. This includes cooperating with child support, JOBS and drug screening. Persons who do not cooperate may be denied or may lose benefits.</p>
            <h4><u>TECHNICAL REQUIREMENTS</u></h4>
            <ol type="A">
              <li>The child must live in the home of a parent or other close relative.</li>
              <li>A person can only receive FA benefits as an adult for 5 years in his or her lifetime.</li>
              <li>The child must be under age 18 unless in school. If in school, the child may receive benefits through the month before the 19th birthday or the last month in school, whichever is earlier.</li>
              <li>The child must be a U.S. citizen or a legal, eligible alien.</li>
              <li>The child must live in Alabama. A person who has been convicted of giving a false address to get help from two States at the same time cannot receive benefits for ten years.</li>
              <li>The child cannot be receiving foster care from the Department of Human Resources.</li>
              <li>The child must not be participating in a strike. No family member may receive FA if a parent or stepparent is on strike.</li>
              <li>The relative must cooperate with Child Support unless the Department decides s/he has a good reason not to. <br />
                <u>Child support received for a child is assigned to the State Department of Human Resources when an application for FA is made for the child.</u>
              </li>
              <li>The relative must turn over all child support payments to the State.</li>
              <li>The relative must give a Social Security number for each member of the assistance unit. If a member does not have a Social Security number, the relative must apply for a number. The number must be provided when received.</li>
              <li>The relative must participate in the JOBS program. The relative must accept a job unless the County Department tells him or her otherwise. Parents under age 18 who are dependent children are required to participate.</li>
              <li>The relative must apply for any other benefits for which they may be eligible, such as Veteran's Benefits, Social Security, Unemployment Compensation, etc.</li>
              <li>A parent who is under age 18 and his or her children must live with an adult. This adult must be a relative or a legal guardian. If an adult relative or guardian is not available, the parent under 18 and his or her children must live in an adult-supervised supportive living arrangement.</li>
              <li>The relative must tell the worker about family circumstances. The relative may be asked to prove some or all of the family circumstances with documents.</li>
              <li>A parent under age 18 with child that is at least 12 weeks old must have a high-school diploma or be in school or a training program.</li>
              <li>The child or relative must not be breaking a condition of parole or probation. The child or relative must not be a convicted felon who is on the run to avoid arrest, trial or jail. The child or relative must not have been convicted of a felony for the use or sale of a controlled substance or having a controlled substance unless his or her sentence has been completed.</li>
              <li>A parent must sign and meet the terms of their Family Responsibility Plan.</li>
              <li>The applicant must participate in job search activities during the application process unless exempt or good cause exists not to do so.</li>
              <li>A parent or stepparent grantee must cooperate with drug screening activities. This means providing information and taking and paying for drug screening(s). Activities are determined on a case by case basis.</li>
              <h4><u>FINANCIAL REQUIREMENTS</u></h4>
              <li>The child must be in need by agency rules and not have parents or stepparents who can support him/her. "In need" means the family does not have net monthly income that equals or is more than the payment standard and is eligible for at least $10. The payment standard is listed below. In determining need, the income of all persons in the assistance unit is counted. Before earned income is counted, a 30% work expense and childcare are subtracted from gross income.
              <h4>FA STANDARDS</h4>
                <table>
                  <tr>
                    <th>FAMILY SIZE</th>
                    <th>PAYMENT STANDARDS</th>
                    <th>FAMILY SIZE</th>
                    <th>PAYMENT STANDARDS</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>264</td>
                    <td>9</td>
                    <td>632</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>304</td>
                    <td>10</td>
                    <td>680</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>344</td>
                    <td>11</td>
                    <td>728</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>392</td>
                    <td>12</td>
                    <td>776</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>440</td>
                    <td>13</td>
                    <td>824</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>488</td>
                    <td>14</td>
                    <td>872</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>536</td>
                    <td>15</td>
                    <td>920</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>584</td>
                    <td>16</td>
                    <td>968</td>
                  </tr>
                </table>
                "Family" means all persons included that make up the family size. Generally, persons to be included are the parents and brothers and sisters of the child(ren) living in the home. Other children related to the parent(s) such as stepchildren and nieces, etc, may be included. (If a child in a group of brothers and sisters is included, all his/her brothers and sisters who live in the home must be included if eligible.) The income of all persons in the unit is used to determine eligibility. A family with income that is equal to or more than the payment standard for the family size is not eligible for FA.
                <br />
                Examples of Income:
                  <ul type="None">
                    <li>Wages, commissions, salaries</li>
                    <li>Contributions</li>
                    <li>Social Security, VA, and Unemployment compensation</li>
                    <li>Child Support</li>
                  </ul>
                  <u>Family assistance will be paid for recipients who are eligible for at least $10 for up to five years,</u> Benefits are not paid for less than $10.
              </li> 
            </ol> 
            <h4><u>ADDITIONAL INFORMATION</u></h4>
            <ul>
              <li>
                <b>WHERE TO APPLY</b><br />
                <u>You must apply for FA at the Department of Human Resources in the county where you live.</u> The application interview may start with a general explanation of the program to a group of applicants. You must take part in an in-office or telephone, private interview with a worker.
              </li>
              <li>
                <b>REPORTING REQUIREMENTS</b><br />
                <b>Applicants:</b> <u>You must report any changes in your family's situation before your case is awarded.</u> <br />
                <b>Recipients:</b> <u>You must notify the Department of any change in your family's situation within 10 days. You must give the Department all information needed for a complete review at least every twelve months.</u> The County Department may ask for some of the information needed in a group interview with other recipients.
              </li>
              <li>
                <b>OVERPAYMENTS</b><br />
                If you get an overpayment for any reason, you will have to pay it back. If you receive an overpayment due to fraud you may be subject to prosecution or other penalty.
              </li>
              <li>
                <b>REVIEW OF ACTION TAKEN ON YOUR CASE</b><br />
                <ul style={{listStyleType: 'disclosure-closed'}}>
                  <li> You may notify the County Department or the State Department of Human Resources in Montgomery, Alabama if you are dissatisfied. You may ask for a conference with the County Department, a review by the State Department, or a formal hearing. (The State Department and the County Department operate under the same rules.)</li>
                  <li>You may make a written request for a hearing within 60 days of the action taken. The parent, other relative, legally appointed representative or other authorized person must make the request.</li>
                  <li>The hearing may be conducted by telephone.</li>
                  <li>Your attomey or someone representing you may be present with you at a hearing or conference.</li> 
                  <li>You may withdraw your request for a bearing when concerns are otherwise resolved.</li>
                </ul>
              </li>
              <li>
                <b>BENEFIT DELIVERY and RESTRICTIONS</b><br />
                Benefits are issued through an electronic benefits transfer (EBT) card. There are restrictions on where the card can be used and what can be bought. Places the card cannot be used are in a liquor, wine or beer store, gambling establishment, strip club, tattoo or body piercing store or a place providing psychic services. Benefits cannot be used to buy liquor, wine or beer, tobacco products, or lottery tickets. Misuse of the card and benefits results in loss of benefits and payback of monies misused.</li>
              <li>
                <b>CHILD SUPPORT SERVICES</b><br /> 
                Child support services are available to persons receiving FA. Child support services will continue even if FA payments are stopped unless you request that services stop.</li>
              <li>
                <b>DAY CARE</b><br />
                You may be eligible for childcare after your FA benefits have been terminated. You must need childcare because you are working and you must apply for the care within six months of FA closure and meet other requirements.
              </li>
              <li>
                <b>FOOD ASSISTANCE</b><br /> 
                You may be eligible for food assistance even if you do not get FA. For more information, contact your worker or your County Food Assistance Office.
              </li>
              <li>
                <b>HEALTH CARE COVERAGE</b><br />
                You and your family members may get free or low cost health care coverage even if you do not get FA. If you want to apply for Medicaid, ALL Kids, or help paying for insurance, apply online at <b><a target="_blank"  href="www.insurealabama.org">www.insurealabama.org</a> or call toll-free: 1-888-373-5437 or 1-800-362-1504 if you have questions.</b>
              </li>
              <li>
                <b>LEGAL ASSISTANCE</b><br />
                You may be able to get free legal help. You may contact a local Legal Services office or other community agency. Your county office can help you find them.
              </li>
              <li>
                <b>OTHER HELP AVAILABLE</b><br />
                You may want to access the Health and Human Services website at <a target="_blank"  href="www.myalabama.gov">www.myalabama.gov</a> for information about other programs and services available in the State.
              </li>
              <li>
                <b>NONDISCRIMINATION:</b> Program rules are the same for everyone. Your race, color, birthplace, sex, handicap, beliefs, age or religion do not matter. Tell us if you have a physical, mental or learning problem that limits a major life activity. We may be able to help you to fully use existing programs and services.
              </li>
            </ul>
          </div>
          )
        },
        reviewDta:
          "DHR is reviewing your SNAP Application. If we need more information, we will call you and we will send you a list of what we need.",
        highVolume:
          "Due to high volume as a result of COVID-19, please do not call us unless you have questions about something, we send you.",
        createAccount: "Create an account ",
        trackInfo:
          "to track case status, see documents we send you, upload documents, check your balance, and more!",
        needInterview:
          "We only need to interview you if we need documents from you. ",
        list: "Here is a list",
        commonDocuments: " of the most common documents we need.",
        submitDocuments:
          "You can submit your documents now by Creating an Online Account! Don't have your documents? Don't worry! You can wait to submit things until we tell you what we need.",
        youMayLabelOne: "You may want to ",
        youMayLabeltwo: "download the ", //"download your application",
        youMayWantToDownSnap: "SNAP application", //download your completed
        ifAnyoneHouseholdEarnedInc:
          "If anyone in the household has earned income, send us proof of income for the last four weeks, such as:",
        proofsForEarnedInc: [
          "Pay stubs or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms with all schedules attached, if self-employed",
        ],
        proofsForEarnedInc1: "Pay stubs or record of payment",
        proofsForEarnedInc2: "Proof of any pay you got and hours worked",
        proofsForEarnedInc3:
          "Completed tax forms with all schedules attached, if self-employed",
        ifAnyoneHouseholdUnEarnedInc:
          "If anyone in the household has unearned income, send us proof of the monthly amount, such as:",
        proofsForUnEarnedInc: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        proofsForUnEarnedInc1: "Benefit or award letter",
        proofsForUnEarnedInc2: "Statement from agency making payments",
        note: "NOTE: ",
        noteDetails:
          "We can usually verify the amount of Social Security, SSI, Child Support, or Unemployment Compensation benefits ourselves. We will tell you if you need to verify any of these items.",
        tanfDetails:
          "TANF will contact you to schedule an appointment. TANF will also mail an appointment letter that will give you more information about your appointment.",
        snapYouMayEligible:
          "You may be eligible for TANF. If you wish to apply click the Continue button.",
        snapYouMayEligibleAnon:
          "You may be eligible for TANF. If you wish to apply please login/register to your account from the landing page",
        youMaySnapLabeltwo: "SNAP application ",
        youMaySnapLabelAnd: " & ",
        youMayTnafLabeltwo: " TANF application ", //download the
        snapTnafLabel:
          "We are currently reviewing your applications. If we need any more information from you we will call you and send a list of all the information we need.",
        youMayLabelThree: "for future reference.",
        tanfYouMayLabelPrint: "print",
        tanfYouMayLabelSave: "save ",
        tanfYouMayLabelOr: " or ",
        tanfYouMayLabelForFuture:
          " the application you have just submitted for future reference.",
        tanfDHRReview:
          "DHR is reviewing your TANF application. We will contact you to schedule an appointment. We will also mail an appointment letter that will give you more information about your appointment.",
        tanfYouCanSubmitLabel1: "You can ",
        tanfYouCanSubmitLabel2: "submit your documents ",
        tanfYouCanSubmitLabel3:
          " now through your online account. However, we will request at a later date what is needed to determine if you are eligible for TANF. Here is a ",
        tanfYouCanSubmitLabel4: "list of the most common documents ",
        tanfYouCanSubmitLabel5: " we might need.",
        tanfWithYourOnlineAccount: "With your online account you can:",
        tanfWithYourOnlineAccountOptions: [
          "Upload documents",
          "Track your case status",
          "See documents we send you",
          "Check monthly benefit amount",
          "And more",
        ],
        tanfSubmitDocuments: "There are other ways to submit documents to us. ",
        tanfFindOutMoreLabel: "Find out more.",
        tanfYoueMayEligible:
          "You may be eligible for SNAP. If you wish to apply click the Continue button.",
        whatisNextLabel: "What's Next?",
        dtaReachLabel: "The DHR will reach out to you within the next 5 days.",
        sendDocLabel:
          "Send in documents so that DHR can confirm your situation.",
        howDoSpeedLabel: "How do I speed up the process?",
        donotNeedLabel:
          "You do not need to wait for DHR to send you a list of verifications",
        sendLabelOne: "You can ",
        sendLabelTwo: "send us your documents ",
        sendLabelThree: "in many ways",
        toLearnOneLabel:
          "To learn more about documents that you may submit now,",
        toLearnOneLabelTwo: "click here",
        manyWayLabel: "There are many other ways to submit documents to us.",
        manyWayLabelTwo: "Find out more",
        howDoSubmitLabel: "How do I submit documents?",
        weSuggestLabel: "We suggest",
        weSuggestLabelOne: "creating an online account",
        weSuggestLabelTwo:
          "so you can upload documents, track the progress of your application, check your balance, and more!",
        findOutMoreLabel: "Find out more",
        createOnlineLabel: "Create an online account",
        orExistLabel: "Or exit without uploading any documents",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Changing this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "Great! Here's what we got from you.",
        lookIncorrectLabel:
          "Please verify the Income below is correct. If any income is incorrect click edit to correct.",
        lookResourcesCorrectLabel:
          "Please verify the resources below are correct. If any of the resources are incorrect, click edit to make changes.",
        anotherHouseholdIncomeLabel:
          "Add the income of another household member",
        anotherHouseholdResourcesLabel:
          "Add the resources of another household member",
        applicationSummaryTitle: "Application Summary",
        applicationSummaryHeader: "Application Summary",
        viewApplicationLinkLabel: "View your Application Summary",
        iagreeTermsLabel: "I agree to the terms",
        agreeTermsErrorMsg: "Please agree to the terms.",
        signatureErrorMsg:
          "Signature needs to match the name in the application.",
        signYourAppllication:
          "Sign your application by typing your full name below. (Required)",
        continueLabel: "Continue",
        saveAndContinue: "Save & Continue",
        getStartedLabel: "Get Started",
        submitButtonLabel: "Submit application",
        expenseFieldLabel: "This last section is about",
        expenseHouseutility: "Shelter and utility expenses",
        expenseCaring: "Caring for kids and adults",
        expenseDepCaring: "Dependent care cost",
        expenseChsupport: "Child support expenses",
        expenseMed: "Medical expenses",
        expenseAnyinfo:
          "Any information you provide here will provide a more accurate benefit amount.",
        saveExitLabel0: "This application",
        saveExitLabel1: " has been saved successfully on",
        saveExitLabel2: "at",
        saveExitLabel3:
          "and can be accessed from the home screen. It will be available for the next ",
        saveExitLabel4: " hours for you to make any changes.",
        deleteExitLabel:
          "WARNING! The information you have entered will be permanently deleted. Are you sure you want to delete?",
        deletePopUpLabel:
          "Deleting this application will not affect any current services you are receiving through the Department of Human Resources.",
        CancelButtonLabel: "Cancel",
        confirmButtonLabel: "Confirm",
        deleteButtonLabel: "Delete",
        confirmDelete: "Confirm Delete",
        areYouSureWarningLabel: "Are you sure ?",
        saveOrExitLabel:
          "You might lose the unsaved information in the application. Do you want to save the information before you exit?",
        saveOrExitLabelAnon:
          "You might lose the information in the application. Are you sure you want to exit?",
        tanfSaveOrExitLabel:
          "You will lose unsaved information in the TANF Redetermination. Do you want to save the information before you exit?",
        saveLabel: "Save",
        saveAndExit: "Save & Exit",
        exitLabel: "Exit",
        cancelLabel: "Cancel",
        saveAndExitHeader: "Saved Successfully",
        resetHeader: "Confirm Reset",
        resetBodyLabel:
          "This will delete all the information from the current screen and the following screens. Are you sure you want to reset?",
        resetOk: "Yes, I want to reset",
        headerText: "Which Household Member Do You Wish To Delete",
        householdDeleteLabel: "Delete this household member",
       snapInfoLabel: "Information Needed to Process a SNAP Application",
       snapImpInfoLabel: "Important information About SNAP",  
       snapSummarizedLabel: "SNAP Summarized Eligibility Requirements",
       snapNvraLabel:"NVRA",
       tanfInfoLabel :"Information Needed to Process a TANF Application",
       tanfImpInfoLabel: "Important Information About TANF",
       tanfSummarizedLabel: "TANF Summarized Eligibility Requirements",
       tanfVoterLabel: "Voter Registration",
       comboDocText: "You can upload and send documents to your local county office by clicking",
       hereLabel: "here",
       whatNextLabel: "Whats next?",
      notifyInterviewText: "Before your application can be processed, an interview will be scheduled for each program. An appointment notice will be mailed to you. A caseworker will contact you by your preferred method to complete your interviews",
      reviewImpInfoSnafText:"Please review important information about the SNAP program below.",
      reviewImpInfoTanfText:"Please review important information about the TANF program below.",
      isHomelessHouseholdheader : "Homeless Household",
      isHomelessHouseholdContent : [
        {
          line1:`If you do not have a mailing address,please submit your`,
        },
        {
          line2:`application at your local county DHR office.Click `
        },
        {
          line3:`for more details.`
        }
      ],
      isHomelessHouseholdContentSR : [
        {
          line1:`If you do not have an mailing address,please submit your`,
        },
        {
          line2:`SR at your local county DHR office.Click `
        },
        {
          line3:`for more details.`
        }
      ],
      consentForDHRVerifyErrorMsg:'Please select below options',
      applyForSnapLabel: 'Apply for SNAP',
      applyForSnapContent: "Creating an account will provide you with the exclusive features below. Click the Create Account button to get started. If you already have an account, click the Log In button below.",
      renderApplyForSnapcol1Content: () => {
        return (<div>
          <ul className="list--unstyled">
              <li style={{padding: "5px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Apply for Benefits</li>
              <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />View Application Status</li>
              <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />View Case Information</li>
              <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Submit Six Month Report</li>
              <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Renew Benefits</li>
              <li style={{padding: "5px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Submit Documents</li>
          </ul>
        </div>
        )
      },
      renderApplyForSnapcol2Content: () => {
        return (<div>
          <ul className="list--unstyled">
              <li style={{padding: "5px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack"/>Reschedule an Appointment</li>
              <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Report a Change</li>
              <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />View Notices</li>
              <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Request Proof of Benefits</li>
              <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Send a Secure Message to a Caseworker</li>
              <li style={{padding: "5px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />View/Print Benefit History</li>
          </ul>
        </div>
        )
      },
      applyForSnapcol1Content: ["Apply for Benefits","View Application Status","View Case Information","Submit Six Month Report","Renew Benefits","Submit Documents"],
      applyForSnapcol2Content: ["Reschedule an Appointment","Report a Change","View Notices","Request Proof of Benefits","Send a Secure Message to a Caseworker","View/Print Benefit History"],
      createAccountLabel: "Create Account",
      loginLabel: "Login",
      clickHereLabel1: "You can",
      clickHereLabel2: "to apply without an account.",
      clickHereLabel3: "click here"
    }; //return End

    case ES:
      return {
        headerText: "¿Qué miembro del hogar desea eliminar?",
        optionYesNo: ["Sí", "No"],
        consentForDHRVerifyErrorMsg:'Por favor seleccione las siguientes opciones',
        consentForDHRLabel:"¿Da su consentimiento para que DHR verifique la información proporcionada en esta solicitud (nombre, dirección, fecha de nacimiento y/o número de seguro social) a través de un sistema de verificación de terceros?",
        householdDeleteLabel: "Eliminar este miembro del hogar",
        addressValidHeaderText: "Validación de dirección",
        addressValidLabelText:
          "Seleccione entre los resultados a continuación.",
        enteredHomeAddressLabelText: "Dirección de casa introducida:",
        suggestedHomeAddressLabelText: "Dirección de casa sugerida:",
        enteredMailingAddressLabelText: "Dirección postal introducida:",
        suggestedMailingAddressLabelText: "Dirección postal sugerida:",
        confirmButtonLabel: "Confirmar",
        okLabel: "Ok",
        heading: "Antes de comenzar",
        howApplyLabel: "¿Cómo funciona la solicitud?",
        fieldLabel1:
          "Esta solicitud se trata de usted, de su núcleo familiar, sus ingresos y sus gastos. Debería tomar alrededor de 20 minutos.",
        fieldLabel2: "¡Si corresponde, tenga estos números a manos!",
        fieldLabel3:
          "No es necesario que tenga una dirección fija ni un número de teléfono para solicitar el SNAP, pero el DHR sí necesita una forma de comunicarse con usted para completar su solicitud del SNAP en línea. Si no tiene una dirección a la que podamos enviar el correo, llame a la línea de asistencia del DHR al",
        fieldLabel4: "o acuda a su",
        fieldLabel3Link: "oficina local del DHR.",
        fieldLabel5: "",
        saveAndExitHeader: "Guardar y salir de la aplicación",
        resetHeader: "Confirmar reinicio",
        resetBodyLabel:
          "Esto eliminará toda la información de la pantalla actual y de las siguientes pantallas. ¿Está seguro que quiere reiniciar?",
        resetBody1: "Esto eliminará toda la información del",
        resetBody2:
          "pantalla y las siguientes pantallas. ¿Está seguro que quiere reiniciar?",
        resetOk: "Sí, quiero restablecer",
        snapFieldLabel1:
          "Puede completar su solicitud utilizando los siguientes métodos:",
        fieldFactors: [
          "En línea haciendo clic en Comenzar. ¡Esta es la forma más rápida!",
          "Si no desea completar su solicitud de SNAP en línea, puede descargar e imprimir su solicitud en papel ",
          ". Puede enviar su formulario de la siguiente manera:",
          "Envíe por fax, correo, correo electrónico o lleve su solicitud en papel a la oficina local de DHR de su condado haciendo clic en ",
          "aquí",
        ],
        snapFieldLabel2:
        "Antes de continuar, lea lo siguiente.",
        fieldFactors2: [
        "Tiene derecho a presentar una solicitud el mismo día que se comunica con la oficina de su condado.",
        "Para presentar una solicitud, solo debe proporcionar su nombre, dirección y firma. Si decide hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si califica para asistencia acelerada, ya que su solicitud no contendrá información sobre ingresos o recursos.",
        "Envíe por correo, fax, correo electrónico o lleve esta solicitud a la oficina de SNAP en el condado donde vive. También puede presentar su solicitud en línea en https://acesselfservice.dhr.alabama.gov.",
        "Para obtener la dirección o el número de teléfono de la oficina local de su condado, llame gratis al: 1-833-822-2202 o en línea en www.dhr.alabama.gov."
        ],
        snapFieldLabel3:
        "Puede recibir asistencia acelerada dentro de los 7 días si:",
        fieldFactors3: [
        "El ingreso bruto mensual de su hogar es inferior a $150 y sus recursos líquidos (efectivo, cheques o ahorros) son inferiores a $100 O",
        "El alquiler/hipoteca mensual y los servicios públicos de su hogar son mayores que los ingresos y recursos líquidos de su hogar O",
        "Su hogar es un hogar de inmigrantes indigentes o trabajadores agrícolas estacionales y sus recursos líquidos son menos de $100."
        ],
        snapFieldLabel4:
        "Las cosas adicionales que necesita saber antes de enviar una solicitud SNAP incluyen:",
        fieldFactors4: [
        "Si envía su solicitud a través de este sitio web después de las 5:00 p. m. CST o en un fin de semana o feriado, se recibirá el siguiente día hábil.",
        "Cuando un residente de una institución solicita conjuntamente SSI y SNAP antes de abandonar la institución, la fecha de presentación de la solicitud es la fecha de salida del solicitante de la institución.",
        "Debe ser entrevistado después de recibir su solicitud. Es posible que se le notifique la fecha y hora de su cita.",
        "Debemos aprobar o rechazar su solicitud dentro de los 30 días. Las solicitudes deben procesarse de acuerdo con los procedimientos de SNAP, incluidos los requisitos de puntualidad, notificación y audiencia justa, independientemente de si la solicitud es para SNAP y otros programas.",
        "Además, a un hogar no se le pueden negar los beneficios de SNAP únicamente porque se le han negado beneficios de otros programas."
        ],
        snapFieldLabel5:
         "Si es elegible, sus beneficios se determinarán a partir del día en que recibamos su solicitud firmada. Esto significa que su primer mes de beneficios se prorrateará desde el día en que recibamos su solicitud.",
        tanfSaveOrExitLabel: "Perderá la información no guardada en la Redeterminación de TANF. ¿Quieres guardar la información antes de salir?",
        tanfFieldLabel1:
          "Esta aplicación es sobre usted y su hogar. Solo debería tomar unos 20 minutos.",
        tanfFieldLabel2: "Si se aplican a usted, ¡tenga estos números listos!",
        tanfFieldFactors: [
          "Fechas de Nacimiento",
          "Número de Seguro Social (SSN)",
          "Ingresos",
        ],
        tanfInstruction:
          "Para usar esta solicitud para solicitar Asistencia Familiar, al menos debe ingresar su nombre, dirección y firma para que podamos procesar su solicitud. Si no desea ingresar esta información, deberá comunicarse con su oficina local. Haga clic a continuación para obtener un formulario de solicitud para imprimir, completar y firmar. Luego envíelo por correo o llévelo a su oficina local.",
        tanfApplicationFormLink: "Solicitud - Formulario - Spanish",
        tanfClickHereRulesText:
          "Haga clic aquí para ver información sobre elegibilidad o beneficios del programa:",
        tanfEligibilityLink: "Requisitos de Elegibilidad Resumidos - Spanish",
        tanfDOHLink: "Departamento de Recursos Humanos",
        tanfImportantInfo:
          "Información importante sobre el uso y la divulgación de sus entradas.",
        tanfInformationYouEnterText:
          "La información que ingresa solo puede ser utilizada por nuestro personal. Una vez que haga clic en enviar, su información se enviará a la oficina que atiende el área donde vive para tomar medidas.",
        tanfTellingOthersText:
          "Informar a otros sobre la información que nos proporciona está restringido a lo que permiten las reglas del programa. Incluye compartir dentro de nuestro departamento, así como con otras oficinas estatales y federales y la policía.",
        tanfTermsAndConditions: "Términos y Condiciones",
        boBackButtonLabel: "Regresar",
        getStartButtonLabel: "Comenzar",
        sIncome: "'Ingresos",
        incomeOf: "Ingresos de",
        resourcesOf: "Recurso ",
        editButtonLabel: "Editar",
        amountLabel: "Cantidad",
        bankNameLabel: "Nombre del banco/correduría",
        perLabel: "Por",
        ofLabel: "De",
        your: "Su ",
        renderAppNoLabel: "numero de solicitud: ",
        renderSnapAppNoLabel: () => {
          return (<span>Su Su número de solicitud <b>SNAP</b>: </span>)
        },
        rendertanfAppNoLabel: () => {
          return (<span>Su Su número de solicitud <b>TANF</b>: </span>)
        },
        renderSnapInfoApp: () => {
          return (<div>
            <h4>Información necesaria para procesar una solicitud</h4>
            <p>Proporcione la mayor cantidad posible de la siguiente información al enviar su solicitud SNAP. Esto puede ayudarnos a procesar su solicitud más rápido. Si necesita ayuda para obtener alguna verificación, comuníquese con la oficina de SNAP para obtener ayuda.</p>
            <ol>
              <li>Prueba de su identidad (ejemplos: certificado de nacimiento, licencia de conducir, identificación escolar)</li>
              <li>Nombres y fechas de nacimiento de todas las personas que viven en su hogar.</li>
              <li>Números de Seguro Social de cada miembro del hogar. Si una persona no tiene un número de Seguro Social, la oficina de SNAP le indicará cómo solicitar uno. Solo tiene que proporcionar información de los números de seguro social (SSN) de aquellos miembros del hogar a los que solicita beneficios SNAP.</li>
              <li>Talones de cheques, recibos de pago o declaraciones del empleador actuales que muestren la cantidad bruta de dinero pagada, las fechas de pago y las horas trabajadas en cada período de pago.</li>
              <li>Una declaración firmada y fechada de cualquier persona que le dé dinero a usted o a cualquier miembro del hogar. La declaración debe mostrar cuánto dinero se entrega, cuándo y con qué frecuencia.</li>
              <li>Cartas o talones de adjudicación que muestren el monto de la compensación por desempleo pagada a usted o cualquier otra persona en su hogar.</li>
              <li>Cartas de beneficios actuales del Seguro Social, Jubilación Ferroviaria, Administración de Veteranos, asociación de jubilados o cualquier otra agencia de la cual usted o cualquier miembro de su hogar reciba un cheque.</li>
              <li>Prueba actual de pagos de alquiler o hipoteca, incluido el alquiler del lote, impuestos a la propiedad y seguro de propiedad.</li>
              <li>Recibos vigentes de servicios públicos (gas, luz, agua, teléfono, servicio de basura).</li>
              <li>Una declaración firmada y fechada de cualquier huésped y/o huésped que muestre la cantidad que paga y con qué frecuencia.</li>
              <li>Formularios del Servicio de Ciudadanía e Inmigración de EE. UU. (USCIS) si algún miembro de su hogar no es ciudadano. Debe proporcionar información de ciudadanía/inmigración de aquellos miembros del hogar a quienes solicita beneficios SNAP.</li>
              <li>Extractos bancarios vigentes de cuentas corrientes y de ahorro en bancos, asociaciones de ahorro y préstamo, cooperativas de crédito, etc.</li>
              <li>Carta u otra declaración que muestre el valor de cualquier acción o bono que posea cualquier miembro del hogar.</li>
              <li>Declaraciones actuales firmadas y fechadas de la persona que cuida a un niño u otro dependiente, incluido el monto cobrado y la frecuencia con la que se paga.</li>
              <li>Si tiene un miembro de su hogar que tiene 60 años o más o un miembro discapacitado, su hogar puede ser elegible para una deducción médica. Deberá proporcionar pruebas de sus gastos médicos, incluidas las pólizas de seguro médico, las primas y los reembolsos de cualquier póliza de seguro médico, medicaid o medicare.</li>
              <li>Verificación de cualquier manutención infantil legalmente obligada (es decir, orden judicial) pagada por cualquier miembro de su hogar a/para alguien que no es miembro del hogar, incluido el monto que realmente pagó y la frecuencia con la que lo pagó.</li>
            </ol>
          </div>
          )
        },
        renderSnapImpInfo: () => {
          return (<div>
            <h4>INFORMACIÓN IMPORTANTE SOBRE ASISTENCIA ALIMENTARIA</h4>
            <p>Tiene derecho a que su solicitud se resuelva en un plazo de <b>treinta días</b> sin distinción de raza, sexo, religión, origen nacional, edad, discapacidad o creencias políticas. Tiene derecho a saber por qué se rechaza su solicitud, o se reducen o cancelan sus beneficios. Tiene derecho a solicitar una conferencia o audiencia imparcial, ya sea oralmente o por escrito, si no está satisfecho con alguna decisión del departamento del condado. Tiene derecho a ser representado por cualquier persona que elija. Usted tiene derecho a examinar el expediente de su caso de asistencia alimentaria en relación con cualquier audiencia que pueda tener.</p>
            <p>Tiene derecho a la <b>confidencialidad.</b> El uso o divulgación de la información se realizará únicamente para ciertos fines limitados permitidos por las leyes y regulaciones estatales y federales. También se puede divulgar información a funcionarios encargados de hacer cumplir la ley con el fin de detener a personas que huyen para evitar la ley.</p>
            <p>La información proporcionada en relación con esta solicitud estará sujeta a verificación por parte de funcionarios federales, estatales y locales para determinar si dicha información es verdadera. Si se determina que alguna información es falsa o incorrecta, se le pueden negar los beneficios de asistencia alimentaria al solicitante y el solicitante puede estar sujeto a <b>proceso penal por proporcionar información incorrecta a sabiendas.</b> Cualquier persona autorizada para actuar en nombre de Al grupo familiar se le puede prohibir participar como representante por hasta un año o puede estar sujeto a multas y/o enjuiciamiento si infringe alguna regla a propósito.</p>
            <p>Si surge un reclamo de asistencia alimentaria contra su hogar, la información contenida en esta solicitud, incluidos todos los números de seguro social, puede remitirse a agencias federales y estatales, así como a agencias privadas de cobranza de reclamos, para que se tomen medidas de cobro de reclamos.</p >
            <p><b>NÚMEROS DE SEGURO SOCIAL:</b> La recopilación de un Número de Seguro Social (SSN) para cada miembro del hogar está autorizada según la Ley de Alimentos y Nutrición de 2008, según enmendada, 7 US.C. 2011-2036, para determinar la elegibilidad para recibir asistencia alimentaria. El Número de Seguro Social se utilizará en la administración del Programa de Asistencia Alimentaria para verificar la identidad de los miembros del hogar para evitar la participación duplicada y facilitar la realización de cambios. Su SSN también se utilizará en comparaciones por computadora y revisiones o auditorías de programas para garantizar que su hogar sea elegible para recibir asistencia alimentaria. Esto puede resultar en reclamos administrativos penales o civiles contra personas que participan de manera fraudulenta en el Programa de Asistencia Alimentaria. <b>Proporcionar un SSN para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro. Aún tendrás que proporcionar información como los ingresos de este miembro.</b></p> 
            <p><b>VERIFICACIÓN:</b> Para determinar la elegibilidad, es posible que deba proporcionar documentos que demuestren lo que ha declarado en la solicitud. Si no puede proporcionar pruebas, <b>puede solicitar ayuda de su trabajador.</b> La información proporcionada en esta solicitud se verificará utilizando el Sistema Estatal de Verificación de Ingresos y Elegibilidad, otros sistemas informáticos de comparación, revisiones de programas y auditorías. Esto incluye información como la recepción de beneficios del Seguro Social, beneficios de desempleo, ingresos no derivados del trabajo, como intereses y dividendos, y salarios del empleo. Cuando se encuentran discrepancias, la verificación de esta información se puede obtener a través del contacto con un tercero, como empleadores, representantes de reclamaciones o instituciones financieras. Esta información puede afectar su elegibilidad y nivel de beneficios. Además, cualquier información proporcionada también puede ser verificada por otros programas de ayuda federal y programas estatales con ayuda federal, como almuerzos escolares, asistencia familiar y Medicaid. <b>Si proporciona información falsa a propósito, se pueden tomar acciones legales o administrativas en su contra. Es posible que deba reembolsar los beneficios de asistencia alimentaria que recibe y a los que no tiene derecho.</b></p>
            <p>A algunos miembros del hogar de edad avanzada y/o discapacitados se les permiten ciertos gastos médicos como deducción si estos gastos se informan y se nos proporciona prueba del gasto. Los gastos médicos permitidos incluyen gastos como los siguientes: medicamentos recetados, facturas de hospital y asilo de ancianos, visitas al médico, dentista u otros profesionales de atención médica, medicamentos de venta libre recetados por un médico, prima de Medicare, prima de seguro hospitalario, seguro para medicamentos recetados. cobertura, gastos de transporte para viajes a médicos, hospitales, farmacias, como el monto cobrado por el transporte o por la cantidad de millas recorridas en su vehículo personal, aparatos o equipos médicos como audífonos, sillas de ruedas, miembros artificiales, anteojos, lentes de contacto, dentaduras postizas, etc., cuidado de asistentes o servicios de ama de casa, gastos de animales de servicio, como alimentos para animales y atención veterinaria.</p> 
            <p><b>ESTADO DE CIUDADANÍA Y INMIGRACIÓN:</b> La información de ciudadanía/inmigración se utiliza para determinar la elegibilidad para recibir asistencia alimentaria. Sólo los ciudadanos estadounidenses y los inmigrantes elegibles pueden participar en el Programa de Asistencia Alimentaria. Cualquier miembro del hogar que no sea ciudadano o extranjero residente permanente puede quedar fuera de su hogar que recibe asistencia alimentaria. <b>Proporcionar información sobre ciudadanía/inmigración es voluntario. No proporcionar esta información para cada miembro del hogar resultará en la descalificación de ese miembro. Aún tendrá que proporcionar información como los ingresos de este miembro.</b> La División de Asistencia Alimentaria verificará con el Servicio de Ciudadanía e Inmigración de los EE. UU. (USCIS) todos los no ciudadanos a los que les solicite obtener beneficios de asistencia alimentaria. No verificaremos a los no ciudadanos que usted elija no incluir en su hogar que recibe asistencia alimentaria.</p> 
            <p><b>No será elegible para recibir beneficios si se niega a cooperar para completar el proceso de solicitud o en revisiones posteriores de elegibilidad, incluidas revisiones resultantes de cambios informados, recertificación o como parte de una revisión de control de calidad estatal o federal. </b></p> 
            <p><b>Su firma en la solicitud servirá como autorización para que los revisores de control de calidad estatales y federales verifiquen las circunstancias de su hogar para fines de elegibilidad para recibir asistencia alimentaria.</b></p>
            <p><b>Usted o cualquier miembro de su hogar pueden ser descalificados de recibir beneficios si usted o el miembro renuncian voluntariamente a un trabajo o reducen la cantidad de horas trabajadas sin una buena causa.</b></p>
            <p><b>Su hogar no recibirá un aumento en los beneficios de asistencia alimentaria si alguien en el hogar no cumple con los requisitos de otro programa basado en ingresos (con prueba de recursos), como Family Assistance.</b></p>
            <p><b>No debe utilizar los beneficios de asistencia alimentaria para comprar artículos no elegibles, como bebidas alcohólicas o tabaco, ni pagar con cuentas de crédito.</b></p>
          </div>
          )
        },
        renderSnapNVRAInfo: () => {
          return (<div>
            <h4>NVRA</h4>
            <p>La Ley Nacional de Registro de Votantes (NVRA) exige que el Departamento de Recursos Humanos le brinde la oportunidad de registrarse para votar. </p>
            <p>Solicitar registrarse o negarse a registrarse para votar no afectará la cantidad de asistencia que le brindará esta agencia. </p>
            <p>Si ya está registrado para votar en su dirección actual, no necesita volver a registrarse. Si desea registrarse para votar o desea cambiar su dirección para fines de votación, puede hacerlo a través de la oficina del Secretario de Estado en el siguiente enlace: <a target="_blank"  href="https://www.sos.alabama.gov/alabama-votes">secretario de Estado</a> </p>
            <p>Si desea ayuda para completar el formulario de registro de votantes, lo ayudaremos. Puede buscar ayuda con el formulario de solicitud en el momento de su entrevista o llamando a la oficina local del Departamento de Recursos Humanos ubicada dentro de su condado. La decisión de buscar o aceptar ayuda es suya. Puede completar el formulario de solicitud en privado.</p>
            <p>El Departamento de Recursos Humanos no utilizará ninguna información que usted envíe relacionada con el registro de votantes para ningún otro propósito.</p>
            <p>Si cree que alguien ha interferido con su derecho a registrarse o a negarse a registrarse para votar, su derecho a la privacidad al decidir si registrarse o solicitar registrarse para votar, o su derecho a elegir su propio partido político o otra preferencia política, puede presentar una queja ante el Secretary of State Capitol, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 o llamando al 334-242-7210 o al 1-800-274-VOTE (1-800-274 -8683).</p>
            <p>Este proyecto ha sido financiado al menos en parte con fondos federales del Servicio de Alimentos y Nutrición del Departamento de Agricultura de EE. UU. El contenido de esta publicación no refleja necesariamente la visión de las políticas del Departamento de Agricultura de los EE. UU., ni la mención de nombres comerciales, productos comerciales u organizaciones implica el respaldo del gobierno de los EE. UU.</p>
          </div>
          )
        },
        renderTanfVoterInfo: () => {
          return (<div>
            <p><b>Información de registro de votantes</b></p>
            <p><b>Regístrese para votar aquí:</b></p>
            <p>https://sos.alabama.gov/alabama-votes/voter/register-to-vote</p>
            <p>La Ley Nacional de Registro de Votantes (NVRA) requiere que el Departamento de Recursos Humanos le brinde la oportunidad de registrarse para votar.</p>
            <p>Solicitar registrarse o negarse a registrarse para votar no afectará la cantidad de asistencia que le brindará esta agencia.</p>
            <p>Si ya está registrado para votar en su dirección actual, no necesita volver a registrarse. Si desea registrarse para votar o desea cambiar su dirección para fines de votación, puede hacerlo a través de la oficina del Secretario de Estado en el siguiente enlace: <a target="_blank"  href="https://www.sos.alabama.gov/alabama-votes">secretario de Estado</a> </p>
            <p>Si desea ayuda para completar el formulario de registro de votantes, lo ayudaremos. Puede buscar ayuda con el formulario de solicitud en el momento de su entrevista o llamando a la oficina local del Departamento de Recursos Humanos ubicada dentro de su condado. La decisión de buscar o aceptar ayuda es suya. Puede completar el formulario de solicitud en privado.</p>
            <p>Si decide solicitar registrarse para votar o si rechaza registrarse para votar, la información en su formulario de solicitud permanecerá confidencial y se utilizará únicamente con fines de registro de votantes.</p>
            <p>Si cree que alguien ha interferido con su derecho a registrarse o a negarse a registrarse para votar, su derecho a la privacidad al decidir si registrarse o solicitar registrarse para votar, o su derecho a elegir su propio partido político u otra preferencia política. , puede presentar una queja ante el Secretario de Estado en el Capitolio del Estado, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 o llamando al 334-242-7210 o al 1-800-274-VOTE (1-800-274- 8683).</p>
          </div>
          )
        },
        renderSnapSummarizedInfo: () => {
          return (<div>
            <h3>DEPARTAMENTO DE RECURSOS HUMANOS</h3>
            <h4>Programa de asistencia alimentaria</h4>
            <h4>Requisitos de elegibilidad resumidos</h4>
            <p>Si tiene dificultades para comunicarse con nosotros porque no habla inglés o tiene una discapacidad, podemos brindarle asistencia lingüística gratuita u otras ayudas y servicios para ayudarlo. Estos servicios están disponibles por teléfono o en persona previa solicitud.</p>
            <p>Los hogares que solicitan o reciben beneficios de asistencia alimentaria deben cumplir con todos los requisitos de elegibilidad aplicables según las políticas de asistencia alimentaria. Los límites de tiempo y los requisitos de otros programas no afectan la elegibilidad de un hogar para recibir beneficios de asistencia alimentaria. Un hogar aún puede calificar para recibir beneficios de asistencia alimentaria incluso si la elegibilidad termina en otro programa. Los hogares deben cooperar con la agencia para establecer la elegibilidad para recibir asistencia alimentaria. El incumplimiento de estos requisitos puede resultar en una denegación o terminación del caso de asistencia alimentaria.</p>
            <h4><u>REQUERIMIENTOS TÉCNICOS</u></h4>
            <ol>
              <li><b>Miembros del hogar.</b> El hogar que recibe asistencia alimentaria está compuesto por personas que viven juntas y compran y preparan sus comidas juntas para el consumo doméstico. Ciertas personas, como cónyuges e hijos menores de 22 años, deben incluirse en un hogar que recibe asistencia alimentaria independientemente de su método para comprar alimentos y preparar comidas.</li>
              <li><b>Huelguistas.</b> Los hogares con miembros en huelga no serán elegibles para participar en el Programa de Asistencia Alimentaria, a menos que el hogar fuera elegible para recibir beneficios el día antes de la huelga y sea elegible en el momento de la solicitud. Sin embargo, el hogar no recibirá un aumento de la asignación como resultado de una disminución en los ingresos del miembro o miembros del hogar en huelga.</li>
              <li><b>Ciudadanía y estatus de extranjero.</b> La información de ciudadanía/inmigración se utiliza para determinar la elegibilidad para recibir asistencia alimentaria. Sólo los ciudadanos estadounidenses y los extranjeros elegibles pueden participar en el Programa de Asistencia Alimentaria. Cualquier miembro del hogar que no sea ciudadano o extranjero residente permanente puede quedar fuera de su hogar que recibe asistencia alimentaria. Proporcionar información de ciudadanía/inmigración es voluntario. La División de Asistencia Alimentaria consultará con el Servicio de Ciudadanía e Inmigración de los EE. UU. (USCIS) solo para aquellos miembros del hogar a quienes usted solicita beneficios de asistencia alimentaria. No verificaremos a los no ciudadanos que no incluya en su hogar que recibe asistencia alimentaria, pero sus ingresos pueden contar para determinar la elegibilidad y la asignación de asistencia alimentaria para las otras personas incluidas en el hogar que recibe asistencia alimentaria. No proporcionar esta información resultará en la inelegibilidad (sin beneficios) para estos miembros.</li>
              <li><b>Números de Seguro Social.</b> La recopilación de un Número de Seguro Social (SSN) para cada miembro del hogar está autorizada según la Ley de Alimentos y Nutrición de 2008, según enmendada, 7 U.S.C. 2011-2036, para determinar la elegibilidad para recibir asistencia alimentaria. El Número de Seguro Social se utiliza en comparaciones por computadora y revisiones o auditorías de programas para garantizar que el hogar sea elegible para los beneficios de asistencia alimentaria que recibe. El SSN se utilizará para verificar la identidad de los miembros del hogar para evitar la participación duplicada y facilitar la realización de cambios. Proporcionar un número de seguro social para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro. Aún deberá proporcionar información como los ingresos de este miembro. <br />El hogar debe proporcionar un número de Seguro Social para cada miembro del hogar al que usted solicita beneficios de asistencia alimentaria. Si un miembro del hogar no tiene un número, debe solicitar uno.</li>
              <li><b>Residencia.</b> Los hogares deben solicitar asistencia alimentaria en el condado en el que viven. No pueden recibir asistencia alimentaria en más de un condado o estado en un mes.</li>
              <li><b>Requisitos laborales.</b> A menos que esté exento del registro laboral, cada miembro de su hogar debe cumplir con los siguientes requisitos laborales. requisitos:
                <ul>
                  <li><b>debe estar registrado para trabajar</b></li>
                  <li><b>no debe dejar un trabajo voluntariamente</b></li>
                  <li><b>No debe reducir voluntariamente las horas de trabajo</b></li>
                  <li><b>debe aceptar un trabajo adecuado que se le ofrezca. (El trabajo debe ser de 30 horas semanales o igual a 30 horas X salario mínimo).</b></li>
                </ul>
                Si un miembro no exento de su hogar no cumple con los requisitos laborales, no puede recibir asistencia alimentaria. Esto podría reducir o suspender la asistencia alimentaria de su hogar.
                <p><b>Adultos sanos y sin dependientes (ABAWD)</b></p>
                <p>Las personas entre 18 y 49 años (menores de 50 años) que no tienen hijos y no están discapacitadas deben cumplir otros requisitos laborales especiales si desean recibir asistencia alimentaria. La ley federal llama a estas personas "adultos sanos sin dependientes" o "ABAWD". Es posible que tengan que trabajar para recibir más de tres meses de asistencia alimentaria en el período comprendido entre enero de 2022 y diciembre de 2024. Pero hay excepciones al límite de tiempo de tres meses. Alguien entre 18 y 49 años (menor de 50 años) puede recibir asistencia alimentaria por más de tres meses si cumple con alguna de estas excepciones:</p>
                <ol>
                <li>La persona trabaja al menos 80 horas al mes.</li>
                  <li>La persona recibe beneficios por discapacidad.</li>
                  <li>La persona recibe compensación por desempleo.</li>
                  <li>Cualquier persona que haya solicitado una compensación por desempleo pero aún no haya recibido respuesta también está exenta mientras espera una decisión.</li>
                  <li>La persona que no recibe discapacidad pero tiene un problema físico o mental que limita el trabajo que puede realizar. Es posible que esto deba ser verificado por un médico u otro profesional médico.</li>
                  <li>La persona no es apta para trabajar. Por ejemplo, la falta de vivienda crónica puede ser un indicador de que alguien no es apto para trabajar.</li>
                  <li>Hay un niño en el hogar menor de 18 años</li>
                  <li>La persona cuida a un adulto que está incapacitado</li>
                  <li>La persona está embarazada.</li>
                  <li>La persona recibe Asistencia Familiar y está en un programa de trabajo de Asistencia Familiar.</li>
                  <li>La persona asiste a un programa de capacitación laboral al menos 80 horas al mes.</li>
                  <li>La persona trabaja como voluntaria al menos 80 horas al mes haciendo algo que tenga un propósito público.</li>
                  <li>El tiempo total que la persona dedica cada mes a trabajar, asistir a formación y hacer voluntariado es de al menos 80 horas al mes.</li>
                  <li>La persona va a la escuela al menos medio tiempo.</li>
                  <li>La persona va a rehabilitación por alcohol o drogas.</li>
                  <li>La persona trabaja al menos 80 horas al mes para obtener beneficios no monetarios. Por ejemplo, la persona trabaja por alquiler o servicios públicos gratuitos.</li>
                </ol>
              </li>
              <h4><u>REQUISITOS FINANCIEROS</u></h4>
                <li><b>Ingresos.</b> Los límites de ingresos varían según el tamaño del hogar. Los hogares que no contienen personas mayores o discapacitadas deben cumplir con los límites de ingresos brutos (ingresos antes de la deducción) y netos (ingresos después de las deducciones permitidas). Los hogares que contienen una persona mayor (de 60 años o más) o una persona discapacitada deben cumplir únicamente con los límites de ingresos netos. Consulte la tabla a continuación para conocer los límites de ingresos netos y brutos según el tamaño del hogar. Los ingresos incluyen sueldos, salarios, comisiones, beneficios de seguridad social, SSI, beneficios para veteranos, manutención infantil, contribuciones, compensación por desempleo, etc.
                <p style={{textAlign:'center'}}>Límites de elegibilidad de ingresos mensuales vigentes del 1/10/2022 al 30/9/2023</p>
                <table>
                  <tr>
                    <th>Tamaño del hogar</th>
                    <th>Límites de ingresos brutos</th>
                    <th>Límites de ingresos netos</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>$1,473</td>
                    <td>$1,133</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$1,984</td>
                    <td>$1,526</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$2,495</td>
                    <td>$1,920</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$3,007</td>
                    <td>$2,313</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$3,518</td>
                    <td>$2,706</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$4,029</td>
                    <td>$3,100</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$4,541</td>
                    <td>$3,493</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$5,052</td>
                    <td>$3,886</td>
                  </tr>
                  <tr>
                    <td>Cada miembro adicional</td>
                    <td>$512</td>
                    <td>$394</td>
                  </tr>
                </table>
              </li>
              <li>
              <b>Deducciones.</b> Sólo las siguientes son deducciones permitidas para asistencia alimentaria:
              <ul>
                <li>Deducción estándar. La deducción estándar variará según el tamaño del hogar. El monto mínimo para hogares de tamaño 1 a 3 es $193. Cuesta $193 para un hogar de 4 personas. Cuesta $225 para un hogar de 5 personas. La cantidad máxima permitida es 258 para un hogar de seis personas o más.</li>
                <li>Deducción por ingreso del trabajo. Esta deducción es del 20% del ingreso bruto del trabajo.</li>
                <li>Deducción por Trabajo por Cuenta Propia. Los hogares con ingresos de trabajo por cuenta propia tienen derecho a una deducción estándar del 40% de los ingresos brutos de los ingresos del trabajo por cuenta propia como costo de hacer negocios.</li>
                <li>Deducción médica. Las personas mayores y/o discapacitadas pueden tener derecho a una deducción médica por los gastos médicos de bolsillo en los que incurran que excedan los $35 por mes. Es posible que se requiera verificación.</li>
                <li>Cuidado de dependientes. Los costos incurridos por el cuidado de un niño u otro dependiente para que un miembro del hogar pueda trabajar, buscar empleo, asistir a capacitación o seguir una educación preparatoria para el empleo.</li>
                <li>Manutención de menores. Manutención infantil legalmente obligada pagada por un miembro del hogar a o para un miembro que no es del hogar.</li>
                <li>Costos de alojamiento. Estos costos incluyen alquiler, hipoteca, impuestos a la propiedad, seguro de la estructura, servicios públicos, etc.</li>
              </ul>
              </li>
              <li><b>Recursos.</b> Los límites máximos de recursos permitidos para hogares que contienen un miembro descalificado no excederán los $2,750 ($4,250 para hogares que contienen un miembro anciano o discapacitado). Los recursos incluyen efectivo disponible, cuentas bancarias, acciones, bonos de ahorro, ganancias de lotería y juegos de azar de $4250 o más, etc.</li>
              <h4><u>OTRO</u></h4>
              <li><b>Procesamiento de la solicitud.</b> El proceso de solicitud incluye completar una solicitud, presentar el formulario en el condado en el que vive el hogar, ser entrevistado y verificar cierta información. Los hogares que cumplan con los siguientes criterios recibirán respuesta en su solicitud dentro de los 7 días calendario: hogares con menos de $150 en ingresos brutos mensuales y sus recursos líquidos, como efectivo o cuentas corrientes/de ahorro, sean inferiores a $100; el alquiler/hipoteca mensual y los servicios públicos del hogar superan el ingreso mensual bruto y los recursos líquidos del hogar; el hogar es un migrante indigente o un trabajador agrícola estacional con menos de $100 en recursos líquidos. 
                <p>Todos los demás hogares recibirán respuesta a su solicitud dentro de los 30 días siguientes a la fecha de la solicitud.</p>
              </li>
              <li><b>Confidencialidad y divulgación de información.</b> Toda la información proporcionada al Departamento es confidencial y cualquier uso o divulgación se realizará únicamente para ciertos fines limitados permitidos según las leyes y regulaciones estatales o federales. Dichos propósitos incluyen, entre otros, establecer la elegibilidad, determinar el monto del beneficio y brindar servicios a los solicitantes y beneficiarios. La información sobre cuánto tiempo ha recibido asistencia alimentaria se entregará al Servicio de Empleo Estatal con el fin de calificar a su empleador para el Crédito Fiscal por Oportunidad Laboral (WOTC).</li>
              <li><b>Montos de asignación.</b> El monto de asistencia alimentaria que un hogar realmente recibe depende del número de personas en el hogar que recibe asistencia alimentaria y del monto de sus ingresos netos. Consulte la tabla a continuación para conocer las asignaciones máximas de asistencia alimentaria por tamaño de hogar.
              <p style={{textAlign:'center'}}>Asignaciones mensuales máximas efectivas a partir del 01/10/22</p>
                <table>
                  <tr>
                    <th>Tamaño del hogar</th>
                    <th>Asignación máxima de asistencia alimentaria</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>$281</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$516</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$740</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>$939</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>$1,116</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>$1,339</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>$1,480</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>$1,691</td>
                  </tr>
                  <tr>
                    <td>Cada persona adicional</td>
                    <td>$211</td>
                  </tr>
                </table>
              </li>
              <li><b>Representante autorizado.</b> El cabeza de familia, el cónyuge u otro miembro responsable del hogar puede designar un representante autorizado para actuar en nombre del hogar. Esta persona puede solicitar beneficios, obtener los beneficios y/o utilizar los beneficios para el hogar de asistencia alimentaria.</li>
              <li><b>Informes simplificados.</b> Algunos hogares deben informar la situación de su hogar semestralmente en un formulario de informe semestral. Estos hogares tienen que informar semestralmente independientemente de que se haya producido algún cambio en su situación. Cambios reportables requeridos: (1) Si el ingreso del hogar aumenta a más del nivel de ingreso máximo para el tamaño de su hogar; (2) Las horas de trabajo de los ABAWD caen por debajo de 20 horas por semana, un promedio de 80 horas por mes; (3) Ganancias de lotería y juegos de azar de $4250 o más.</li>
              <li><b>Audiencias imparciales.</b> Un hogar que no esté satisfecho con la acción de la agencia que afecta su participación en el Programa de Asistencia Alimentaria tiene derecho a solicitar una audiencia imparcial dentro de los 90 días posteriores a la apelación de la acción. La solicitud se puede hacer de forma oral o por escrito al Departamento de Recursos Humanos del Condado, Oficina de Asistencia Alimentaria o al Departamento de Recursos Humanos, División de Asistencia Alimentaria, S.Gordon Persons Building, 50 N. Ripley Street, Montgomery, Alabama 36130-4000 , teléfono (334) 242-1700. El caso del hogar puede ser presentado por un miembro del hogar o un representante, como un asesor legal, un pariente, un amigo u otro portavoz. Para obtener asesoramiento legal gratuito, comuníquese con el número gratuito de admisión de Servicios Legales de Alabama al 1-866-456-4995 o en su sitio web de admisión en línea para todo el estado en <b><u>WWW.ALABAMA.LEGALHELP.ORG.</u> </b></li>
              <li><b>Personas no elegibles.</b> Las siguientes personas no son elegibles para recibir asistencia alimentaria:
                <ul>
                <li>Cualquier persona que huya para evitar el procesamiento, la custodia o el encarcelamiento después de una condena por un delito grave conforme a la ley.</li>
                  <li>Cualquier persona que viole su libertad condicional.</li>
                  <li>Cualquier persona declarada culpable por un tribunal de utilizar beneficios de asistencia alimentaria para comprar armas de fuego, municiones o explosivos. Estas personas quedan permanentemente descalificadas para recibir beneficios de asistencia alimentaria.</li>
                  <li>Cualquier persona que utilice los beneficios de asistencia alimentaria para comprar drogas ilegales puede ser descalificada de recibir asistencia alimentaria desde 2 años hasta de forma permanente.</li>
                  <li>Es posible que usted no sea elegible si es condenado después del 22 de agosto de 1996 por un delito grave según la ley federal o estatal por posesión, uso o distribución de una sustancia controlada.</li>
                  <li>Cualquier persona declarada culpable por un tribunal de comprar o vender beneficios de asistencia alimentaria de $500 o más. Estas personas quedan permanentemente descalificadas para recibir asistencia alimentaria.</li>
                  <li>Cualquier persona que tergiverse su identidad o residencia para recibir múltiples beneficios de asistencia alimentaria simultáneamente. Estas personas no son elegibles para recibir beneficios de asistencia alimentaria durante 10 años a partir de la fecha de la condena.</li>
                  <li>Cualquier persona declarada culpable de una violación intencional del programa (IPV) a través de una audiencia de descalificación administrativa o por un tribunal federal, estatal o local o que haya firmado una renuncia a la audiencia, no será elegible para recibir asistencia alimentaria durante 12 meses por la primera violación, 24 meses por la segunda infracción y permanentemente por la tercera infracción. Estas personas también pueden ser multadas, encarceladas o ambas, y pueden estar sujetas a procesamiento y sanciones federales.</li>
                  <li>Cualquier persona en buena forma física y mental entre 18 y 50 años matriculada en una institución de educación superior, que no cumpla con ciertos criterios de estudiante elegible.</li>
                  <li>Cualquier persona que no cumpla con los requisitos de ciudadanía o estatus de extranjero.</li>
                  <li>Cualquier persona que se niegue o no proporcione, sin una buena razón, un número de seguro social.</li>
                  <li>Cualquier persona descalificada por no cumplir con los requisitos laborales. Esto incluye renunciar voluntariamente a un trabajo o reducir la cantidad de horas trabajadas sin una buena causa.</li>
                  <li>Cualquier persona descalificada por violar las regulaciones del programa.</li>
                  <li>Cualquier hogar que se niegue a cooperar con Control de Calidad.</li>
                </ul>
              </li>
              <li><b>Reclamaciones y trata.</b> Los hogares deben reportar información correcta sobre su situación. Los hogares deben utilizar sus beneficios para comprar alimentos elegibles para su uso personal. Hay sanciones que se pueden aplicar al hogar, incluido el requisito de reembolsar los beneficios recibidos incorrectamente. Si un miembro del hogar proporciona intencionalmente información falsa, vende o intenta vender o intercambiar beneficios, compra artículos no elegibles como bebidas alcohólicas o tabaco, o paga con cuentas de crédito o regala o vende una tarjeta EBT, existen otras sanciones que pueden imponerse. aplicado incluyendo la descalificación del programa de un año a permanente y el procesamiento ante los tribunales.</li>
              <li><b>Registro de votantes.</b> Si no está registrado para votar en el lugar donde vive ahora, puede registrarse para votar durante el proceso de solicitud de Asistencia Alimentaria. Podemos ayudarlo con la solicitud o puede optar por completar la solicitud en privado. Su decisión de registrarse o negarse a registrarse para votar no afecta la decisión sobre su solicitud de Asistencia Alimentaria ni los beneficios que pueda recibir. Si cree que alguien ha interferido con su derecho a registrarse o a negarse a registrarse para votar, su derecho a la privacidad al decidir si registrarse o solicitar registrarse para votar, o su derecho a elegir su propio partido político u otra preferencia política. , puede presentar una queja ante la Secretaría de Estado, Capitolio del Estado de Alabama, 600 Dexter Avenue Suite E-210, Montgomery, AL 36130.</li>
            </ol>
          </div>
          )
        },
        renderTanfSummarizedInfo: () => {
          return (<div>
            <h3>DEPARTAMENTO DE RECURSOS HUMANOS</h3>
            <h4>Programa de Asistencia Alimentaria (FA)</h4>
            <h4>Requisitos de elegibilidad resumidos</h4>
            <h4>¿ES MI FAMILIA ELEGIBLE?</h4>
            <p>Las personas que solicitan o reciben beneficios en efectivo deben cumplir ciertos requisitos. Deben ayudar a determinar la elegibilidad para los beneficios. Deben participar en actividades que les ayuden a poder apoyar y cuidar mejor a sus familias. Esto incluye cooperar con manutención infantil, JOBS y detección de drogas. A las personas que no cooperen se les pueden negar los beneficios o perderlos.</p>
            <h4><u>REQUERIMIENTOS TÉCNICOS</u></h4>
            <ol type="A">
            <li>El niño debe vivir en la casa de uno de sus padres u otro pariente cercano.</li>
              <li>Una persona solo puede recibir beneficios de FA como adulto durante 5 años durante su vida.</li>
              <li>El niño debe ser menor de 18 años a menos que esté en la escuela. Si está en la escuela, el niño puede recibir beneficios hasta el mes anterior a cumplir 19 años o el último mes en la escuela, lo que ocurra primero.</li>
              <li>El niño debe ser ciudadano estadounidense o extranjero legal y elegible.</li>
              <li>El niño debe vivir en Alabama. Una persona que haya sido condenada por dar una dirección falsa para obtener ayuda de dos Estados al mismo tiempo no puede recibir beneficios durante diez años.</li>
              <li>El niño no puede recibir cuidado de crianza del Departamento de Recursos Humanos.</li>
              <li>El niño no debe participar en una huelga. Ningún miembro de la familia puede recibir FA si uno de los padres o padrastros está en huelga.</li>
              <li>El familiar debe cooperar con la manutención infantil a menos que el Departamento decida que tiene una buena razón para no hacerlo. <br />
                <u>La manutención infantil recibida por un niño se asigna al Departamento de Recursos Humanos del Estado cuando se presenta una solicitud de FA para el niño.</u>
              </li>
              <li>El familiar debe entregar todos los pagos de manutención infantil al Estado.</li>
              <li>El familiar debe proporcionar un número de Seguro Social para cada miembro de la unidad de asistencia. Si un miembro no tiene un número de Seguro Social, el familiar debe solicitar un número. El número debe proporcionarse cuando se reciba.</li>
              <li>El familiar debe participar en el programa JOBS. El familiar debe aceptar un trabajo a menos que el Departamento del Condado le indique lo contrario. Los padres menores de 18 años que sean hijos dependientes deben participar.</li>
              <li>El familiar debe solicitar cualquier otro beneficio para el cual pueda ser elegible, como Beneficios para Veteranos, Seguro Social, Compensación por Desempleo, etc.</li>
              <li>Un padre menor de 18 años y sus hijos deben vivir con un adulto. Este adulto debe ser un familiar o tutor legal. Si no hay un pariente o tutor adulto disponible, el padre menor de 18 años y sus hijos deben vivir en un alojamiento de apoyo supervisado por un adulto.</li>
              <li>El familiar debe informar al trabajador sobre las circunstancias familiares. Se le podrá pedir al familiar que acredite algunas o todas las circunstancias familiares con documentos.</li>
              <li>Un padre menor de 18 años con un hijo que tenga al menos 12 semanas de edad debe tener un diploma de escuela secundaria o estar en la escuela o en un programa de capacitación.</li>
              <li>El niño o pariente no debe estar infringiendo una condición de libertad condicional o libertad condicional. El niño o pariente no debe ser un delincuente convicto que esté huyendo para evitar el arresto, el juicio o la cárcel. El niño o pariente no debe haber sido condenado por un delito grave por el uso o venta de una sustancia controlada o por tener una sustancia controlada a menos que su sentencia haya cumplido.</li>
              <li>Un padre debe firmar y cumplir con los términos de su Plan de Responsabilidad Familiar.</li>
              <li>El solicitante debe participar en actividades de búsqueda de empleo durante el proceso de solicitud, a menos que exista una exención o una buena causa para no hacerlo.</li>
              <li>Un padre o padrastro beneficiario debe cooperar con las actividades de detección de drogas. Esto significa proporcionar información y realizar y pagar exámenes de detección de drogas. Las actividades se determinan caso por caso.</li>
              <h4><u>REQUISITOS FINANCIEROS</u></h4>
              <li>El niño debe estar necesitado según las reglas de la agencia y no tener padres o padrastros que puedan apoyarlo. "Necesitado" significa que la familia no tiene ingresos mensuales netos iguales o superiores al estándar de pago y es elegible para recibir al menos $10. El estándar de pago se enumera a continuación. Para determinar la necesidad, se cuentan los ingresos de todas las personas en la unidad de asistencia. Antes de contar los ingresos del trabajo, se restan del ingreso bruto el 30% de los gastos laborales y el cuidado de los niños.
              <h4>ESTÁNDARES FA</h4>
                <table>
                  <tr>
                  <th>TAMAÑO DE LA FAMILIA</th>
                    <th>ESTÁNDARES DE PAGO</th>
                    <th>TAMAÑO DE LA FAMILIA</th>
                    <th>ESTÁNDARES DE PAGO</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>264</td>
                    <td>9</td>
                    <td>632</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>304</td>
                    <td>10</td>
                    <td>680</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>344</td>
                    <td>11</td>
                    <td>728</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>392</td>
                    <td>12</td>
                    <td>776</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>440</td>
                    <td>13</td>
                    <td>824</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>488</td>
                    <td>14</td>
                    <td>872</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>536</td>
                    <td>15</td>
                    <td>920</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>584</td>
                    <td>16</td>
                    <td>968</td>
                  </tr>
                </table>
                "Familia" significa todas las personas incluidas que componen el tamaño de la familia. Generalmente, las personas que deben incluirse son los padres y hermanos y hermanas de los niños que viven en el hogar. Se pueden incluir otros hijos relacionados con los padres, como hijastros y sobrinas, etc. (Si se incluye a un niño en un grupo de hermanos y hermanas, todos sus hermanos y hermanas que viven en el hogar deben incluirse si son elegibles). Los ingresos de todas las personas en la unidad se utilizan para determinar la elegibilidad. Una familia con ingresos iguales o superiores al estándar de pago para el tamaño de la familia no es elegible para FA.
                <br />
                Ejemplos de ingresos:
                  <ul type="none">
                    <li>Sueldos, comisiones, salarios</li>
                    <li>Contribuciones</li>
                    <li>Seguro Social, VA y compensación por desempleo</li>
                    <li>Manutención infantil</li>
                  </ul>
                  <u>Se pagará asistencia familiar a los beneficiarios que sean elegibles para recibir al menos $10 durante un máximo de cinco años.</u> No se pagan beneficios por menos de $10.
              </li> 
            </ol> 
            <h4><u>INFORMACIÓN ADICIONAL</u></h4>
            <ul>
            <li>
                <b>DÓNDE SOLICITAR</b><br />
                <u>Debe solicitar FA en el Departamento de Recursos Humanos del condado donde vive.</u> La entrevista de solicitud puede comenzar con una explicación general del programa a un grupo de solicitantes. Debe participar en una entrevista privada en la oficina o por teléfono con un trabajador.
              </li>
              <li>
                <b>REQUISITOS DE INFORMES</b><br />
                <b>Solicitantes:</b> <u>Deben informar cualquier cambio en la situación de su familia antes de que se adjudique su caso.</u> <br />
                <b>Destinatarios:</b> <u>Deberá notificar al Departamento cualquier cambio en la situación de su familia en un plazo de 10 días. Debe brindarle al Departamento toda la información necesaria para una revisión completa al menos cada doce meses.</u> El Departamento del Condado puede solicitar parte de la información necesaria en una entrevista grupal con otros destinatarios.
              </li>
              <li>
                <b>SOBREPAGOS</b><br />
                Si recibe un pago en exceso por cualquier motivo, tendrá que devolverlo. Si recibe un pago excesivo debido a fraude, puede estar sujeto a un proceso u otra sanción.
              </li>
              <li>
                <b>REVISIÓN DE LAS MEDIDAS TOMADAS EN SU CASO</b><br />
                <ul style={{listStyleType: 'disclosure-closed'}}>
                  <li> Puede notificar al Departamento del Condado o al Departamento de Recursos Humanos del Estado en Montgomery, Alabama, si no está satisfecho. Puede solicitar una conferencia con el Departamento del Condado, una revisión por parte del Departamento de Estado o una audiencia formal. (El Departamento de Estado y el Departamento del Condado operan bajo las mismas reglas).</li>
                  <li>Puede presentar una solicitud por escrito para una audiencia dentro de los 60 días posteriores a la acción tomada. El padre, otro familiar, representante legalmente designado u otra persona autorizada debe realizar la solicitud.</li>
                  <li>La audiencia podrá realizarse por teléfono.</li>
                  <li>Su abogado o alguien que lo represente puede estar presente con usted en una audiencia o conferencia.</li> 
                  <li>Puede retirar su solicitud de rodamiento cuando las inquietudes se resuelvan de otra manera.</li>
                </ul>
              </li>
              <li>
                <b>ENTREGA DE BENEFICIOS y RESTRICCIONES</b><br />
                Los beneficios se emiten a través de una tarjeta de transferencia electrónica de beneficios (EBT). Existen restricciones sobre dónde se puede utilizar la tarjeta y qué se puede comprar. Los lugares donde no se puede usar la tarjeta son tiendas de licores, vinos o cervezas, establecimientos de juego, clubes de striptease, tiendas de tatuajes o perforaciones corporales o lugares que brinden servicios psíquicos. Los beneficios no se pueden utilizar para comprar licor, vino o cerveza, productos de tabaco ni billetes de lotería. El mal uso de la tarjeta y de los beneficios resulta en la pérdida de beneficios y la devolución del dinero mal utilizado.</li>
              <li>
                <b>SERVICIOS DE MANUTENCIÓN INFANTIL</b><br /> 
                Los servicios de manutención infantil están disponibles para las personas que reciben FA. Los servicios de manutención infantil continuarán incluso si se suspenden los pagos de FA, a menos que usted solicite que se suspendan los servicios.</li>
              <li>
                <b>GUARDERÍA</b><br />
                Es posible que sea elegible para recibir cuidado infantil después de que se hayan cancelado sus beneficios de FA. Debe necesitar cuidado infantil porque está trabajando y debe solicitar el cuidado dentro de los seis meses posteriores al cierre de FA y cumplir con otros requisitos.
              </li>
              <li>
                <b>ASISTENCIA ALIMENTARIA</b><br /> 
                Usted puede ser elegible para recibir asistencia alimentaria incluso si no recibe FA. Para obtener más información, comuníquese con su trabajador o con la Oficina de Asistencia Alimentaria de su condado.
              </li>
              <li>
                <b>COBERTURA DE ATENCIÓN MÉDICA</b><br />
                Usted y los miembros de su familia pueden obtener cobertura de atención médica gratuita o de bajo costo incluso si no obtiene FA. Si desea solicitar Medicaid, ALL Kids o ayuda para pagar el seguro, solicite en línea en <b><a target="_blank"  href="www.insurealabama.org">www.insurealabama.org</a> o llame gratis al: 1-888-373-5437 o 1-800-362-1504 si tiene preguntas.</b>
              </li>
              <li>
                <b>ASISTENCIA JURÍDICA</b><br />
                Es posible que pueda obtener ayuda legal gratuita. Puede comunicarse con una oficina local de Servicios Legales u otra agencia comunitaria. La oficina de su condado puede ayudarle a encontrarlos.
              </li>
              <li>
                <b>OTRA AYUDA DISPONIBLE</b><br />
                Es posible que desee acceder al sitio web de Salud y Servicios Humanos en <a target="_blank"  href="www.myalabama.gov">www.myalabama.gov</a> para obtener información sobre otros programas y servicios disponibles en el estado.
              </li>
              <li>
                <b>NO DISCRIMINACIÓN:</b> Las reglas del programa son las mismas para todos. No importa tu raza, color, lugar de nacimiento, sexo, discapacidad, creencias, edad o religión. Díganos si tiene un problema físico, mental o de aprendizaje que limite una actividad importante de la vida. Es posible que podamos ayudarle a utilizar plenamente los programas y servicios existentes.
              </li>
            </ul>
          </div>
          )
        },
        reviewDta:
          "El DHR esta revisando su solicitud de SNAP. Si necesitamos mas informacion, le llamaremos y mandaremos una lista de lo que necesitamos.",
        highVolume:
          "Debido al alto volumen de llamadas como resultado del COVID-19, por favor no nos llame almenos que tenga preguntas sobre algo que le mandamos.",
        createAccount: "Crea una cuenta  ",
        trackInfo:
          "para rastrear el estado del caso, vea los documentos que le enviamos, cargue documentos, verifique su saldo y más!",
        needInterview:
          "Solo necesitamos entrevistarte si necesitamos documentos. ",
        list: "Aqui esta una lista",
        commonDocuments: " de los documentos mas comunes que necesitamos.",
        submitDocuments:
          "¡Puedes enviar tus documentos ahora creando una cuenta electonica! ¿No tiene sus documentos? ¡No se preocupe! Puede esperar para enviarnos verificaciones hasta que le digamos que las necesitamos.",
        youMayLabelOne: "Puede ",
        youMayLabeltwo: "descargar el ", //" descargar su solicitud",
        youMayLabelThree: "para tenerla como referencia en el futuro.",
        youMayTnafLabeltwo: "descargar la aplicación TANF",
        youMaySnapLabeltwo: " solicitud de SNAP ",
        youMaySnapLabelAnd: " & ",
        youMayTnafLabeltwo: " Solicitud de TANF ",
        snapTnafLabel:
          "Actualmente estamos revisando sus solicitudes. Si necesitamos más información de usted, lo llamaremos y le enviaremos una lista de toda la información que necesitamos.",
        tanfYouMayLabelPrint: "Imprimir",
        tanfYouMayLabelSave: "salvar ",
        tanfYouMayLabelOr: " o ",
        tanfYouMayLabelForFuture:
          " La solicitud que acaba de enviar para referencia futura.",
        tanfDHRReview:
          "DHR está revisando su solicitud de TANF. Nos pondremos en contacto con usted para programar una cita. También enviaremos por correo una carta de cita que le dará más información sobre su cita.",
        tanfYouCanSubmitLabel1: "Puedes ",
        tanfYouCanSubmitLabel2: "Envíe sus documentos ",
        tanfYouCanSubmitLabel3:
          " ahora a través de su cuenta en línea. Sin embargo, solicitaremos en una fecha posterior lo que se necesita para determinar si usted es elegible para TANF. Aquí hay un ",
        tanfYouCanSubmitLabel4: "Lista de los documentos más comunes ",
        tanfYouCanSubmitLabel5: " Podríamos necesitar.",
        tanfWithYourOnlineAccount: "Con su cuenta en línea puede:",
        tanfWithYourOnlineAccountOptions: [
          "Subir documentos",
          "Seguimiento del estado de su caso",
          "Ver documentos que te enviamos",
          "Verifique el monto del beneficio mensual",
          "Y más",
        ],
        tanfSubmitDocuments: "Hay otras formas de enviarnos documentos. ",
        tanfFindOutMoreLabel: "Más información.",
        tanfYoueMayEligible:
          "Usted puede ser elegible para SNAP. Si desea presentar una solicitud, haga clic en el botón Continuar.",
        whatisNextLabel: "¿Cuál es el siguiente paso?",
        dtaReachLabel: "El DHR se comunicará con usted en los próximos 5 días.",
        sendDocLabel:
          "Envíe los documentos para que el DHR pueda confirmar su situación.",
        howDoSpeedLabel: "¿Cómo puedo acelerar el proceso?",
        donotNeedLabel:
          "No es necesario que espere a que el DHR le envíe una lista de verificaciones. ",
        sendLabelOne: "Puede",
        sendLabelTwo: " enviarnos sus documentos",
        sendLabelThree: " de muchas maneras.",
        toLearnOneLabel:
          "Para saber más acerca de los documentos que puede enviar ahora, ",
        toLearnOneLabelTwo: "haga clic aquí",
        manyWayLabel: "Hay muchas otras maneras de enviarnos los documentos. ",
        manyWayLabelTwo: "Averiguar más",
        howDoSubmitLabel: "¿Cómo envío documentos?",
        weSuggestLabel: "¡Le sugerimos que",
        weSuggestLabelOne: " cree una cuenta en línea",
        weSuggestLabelTwo:
          "para que pueda subir documentos, darle seguimiento a su solicitud, consultar su saldo y más!",
        findOutMoreLabel: "Averiguar más",
        createOnlineLabel: "Crear una cuenta en línea",
        orExistLabel: "O salir sin subir documentos", //
        renderSaveLabel: "Para guardar tu progreso, crea una cuenta aquí.",
        laterOnLabel:
          "Más adelante, su cuenta en línea puede ayudarlo a recibir mensajes del DHR sobre su solicitud, enviar documentos, verificar su saldo y más.",
        orExitLabel: "O salir sin guardar tu trabajo",
        changingLabel:
          "Al marcar este valor, se eliminarán los valores introducidos anteriormente en las próximas secciones.",
        greatHereLabel: "Excelente. Esto es lo que tenemos de usted",
        lookIncorrectLabel:
          "Por favor, verifique que el ingreso a continuación sea correcto. Si algún ingreso es incorrecto, haga clic en editar para corregirlo.",
        lookResourcesCorrectLabel:
          "Verifique que los recursos a continuación sean correctos. Si algún recurso es incorrecto, haga clic en editar para realizar cambios.",
        anotherHouseholdIncomeLabel:
          "Sume el ingreso de otro miembro del hogar.",
        anotherHouseholdResourcesLabel:
          "Agregar los recursos de otro miembro del hogar",
        applicationSummaryTitle: "Resumen de la aplicación",
        applicationSummaryHeader: "Resumen de la aplicación",
        viewApplicationLinkLabel: "Vea el Resumen de su solicitud",
        iagreeTermsLabel: "Acepto los términos",
        agreeTermsErrorMsg: "Por favor, acepte las condiciones.",
        signatureErrorMsg:
          "La firma debe coincidir con el nombre en la solicitud.",
        signYourAppllication:
          "Firme su solicitud escribiendo su nombre completo a continuación. (Requerido)",
        continueLabel: "Continuar",
        saveAndContinue: "Guardar y continuar",
        getStartedLabel: "Comenzar",
        submitButtonLabel: "Enviar solicitud",
        expenseFieldLabel: "Esta última sección trata sobre",
        expenseHouseutility: "Costos de vivienda y servicios públicos",
        expenseCaring: "Cuidado de niños y adultos",
        expenseDepCaring: "Dependent care costs",
        expenseChsupport: "Manutención",
        expenseMed: "Gastos médicos",
        expenseAnyinfo:
          "Toda la información que proporcione aquí dará un monto más exacto del beneficio.",
        saveExitLabel0: "Esta aplicación ",
        saveExitLabel1: "se ha guardado correctamente y",
        saveExitLabel2: "en",
        saveExitLabel3:
          "se puede acceder desde la pantalla de inicio. Estará disponible para la próxima",
        saveExitLabel4: " horas para que pueda realizar cualquier cambio.",
        deleteExitLabel:
          "¡ADVERTENCIA! La información que ha introducido se eliminará de forma permanente. ¿Estás segura de que quieres eliminar?",
        deletePopUpLabel:
          "Eliminar esta solicitud no afectará ningún servicio actual que esté recibiendo a través del Departamento de Recursos Humanos.",
        CancelButtonLabel: "Cancelar",
        deleteButtonLabel: "Borrar",
        confirmDelete: "Confirmar eliminación",
        areYouSureWarningLabel: "Estas segura",
        saveOrExitLabel:
          "Es posible que pierda la información no guardada en la aplicación. ¿Desea guardar la información antes de salir?",
        saveOrExitLabelAnon:
          "Es posible que pierda la información de la solicitud. ¿Estás seguro de que quieres salir?",
        saveLabel: "Ahorrar",
        saveAndExit: "Guardar la salida",
        exitLabel: "Salida",
        cancelLabel: "Cancelar",
        youMayWantToDownSnap: "solicitud de SNAP", //"descargue su solicitud SNAP completa",
        ifAnyoneHouseholdEarnedInc:
          "Si alguien en el hogar ha obtenido ingresos del trabajo, envíenos comprobantes de ingresos de las últimas cuatro semanas, tales como:",
        proofsForEarnedInc: [
          "Talones de pago o registro de pago",
          "Prueba de cualquier pago que recibió y horas trabajadas",
          "Formularios de impuestos completos con todos los anexos adjuntos, si trabaja por cuenta propia",
        ],

        ifAnyoneHouseholdUnEarnedInc:
          "Si alguien en el hogar tiene ingresos no derivados del trabajo, envíenos un comprobante de la cantidad mensual, como:",
        proofsForUnEarnedInc: [
          "Beneficio o carta de adjudicación",
          "Declaración de la agencia que realiza los pagos",
        ],
        proofsForEarnedInc1: "Recibos de pago o constancia de pago",
        proofsForEarnedInc2:
          "Comprobante de cualquier pago que haya recibido y horas trabajadas",
        proofsForEarnedInc3:
          "Formularios de impuestos completos con todos los anexos adjuntos, si trabaja por cuenta propia",
        proofsForUnEarnedInc1: "Carta de beneficio o premio",
        proofsForUnEarnedInc2:
          "Declaración de la agencia que realiza los pagos",
        note: "NOTA: ",
        noteDetails:
          "Por lo general, podemos verificar nosotros mismos el monto de los beneficios del Seguro Social, SSI, manutención infantil o compensación por desempleo. Le informaremos si necesita verificar alguno de estos elementos.",
        tanfDetails:
          "TANF se comunicará con usted para programar una cita. TANF también le enviará por correo una carta de cita que le brindará más información sobre su cita.",
        snapYouMayEligible:
          "Puede ser elegible para TANF. Si desea aplicar haga clic en el botón Continuar.",
          snapInfoLabel: "Información necesaria para procesar una solicitud SNAP",
       snapImpInfoLabel: "Información importante sobre SNAP", 
       snapSummarizedLabel: "Requisitos de elegibilidad resumidos de SNAP", 
       snapNvraLabel:"NVRA",
       tanfInfoLabel :"Información necesaria para procesar una solicitud TANF",
       tanfImpInfoLabel: "Información importante sobre TANF",
       tanfSummarizedLabel: "Requisitos de elegibilidad resumidos de TANF",
       tanfVoterLabel: "Registro de votantes",
       comboDocText: "Puede cargar y enviar documentos a la oficina local de su condado haciendo clic",
       hereLabel: "aquí",
       whatNextLabel: "¿Que sigue?",
       notifyInterviewText: "Antes de que se pueda procesar su solicitud, se programará una entrevista para cada programa. Se le enviará por correo un aviso de cita. Un asistente social se comunicará con usted mediante su método preferido para completar sus entrevistas.",
      reviewImpInfoSnafText:"Revise la información importante sobre el programa SNAP a continuación.",
      reviewImpInfoTanfText:"Revise la información importante sobre el programa TANF a continuación.",
        isHomelessHouseholdheader : "Hogar sin hogar",
        isHomelessHouseholdContent : [
          {
          line1:`Si no tiene una dirección, envíe su`,
          },
          {
          line2:`solicitud en la oficina local del DHR de su condado. Haga clic `
          },
          {
          line3:`para más detalles.`
          }
        ],
        isHomelessHouseholdContentSR : [
          {
          line1:`Si no tiene una dirección, envíe su`,
          },
          {
          line2:`solicitud en la oficina local del DHR de su condado. Haga clic `
          },
          {
          line3:`para más detalles.`
          }
        ],
        applyForSnapLabel: 'Solicitar SNAP',
        applyForSnapContent: "Crear una cuenta le proporcionará las funciones exclusivas que aparecen a continuación. Haga clic en el botón Crear cuenta para comenzar. Si ya tiene una cuenta, haga clic en el botón Iniciar sesión a continuación.",
        renderApplyForSnapcol1Content: () => {
          return (<div>
            <ul className="list--unstyled">
                <li style={{padding: "5px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Solicite beneficios</li>
                <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Ver el estado de la solicitud</li>
                <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Ver información del caso</li>
                <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Enviar informe semestral</li>
                <li style={{padding: "2px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Renovar beneficios</li>
                <li style={{padding: "5px 5px 2px 3px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Enviar documentos</li>
            </ul>
          </div>
          )
        },
        renderApplyForSnapcol2Content: () => {
          return (<div>
            <ul className="list--unstyled">
                <li style={{padding: "5px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack"/>Reprogramar una cita</li>
                <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Informar un cambio</li>
                <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Ver avisos</li>
                <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Solicitar prueba de beneficios</li>
                <li style={{padding: "2px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Enviar un mensaje seguro a una trabajadora social</li>
                <li style={{padding: "5px 5px 2px 1px"}}><Icon name="checkmarkblack" label="checkmarkblack" />Ver/imprimir historial de beneficios</li>
            </ul>
          </div>
          )
        },
        applyForSnapcol1Content: ["Apply for Benefits","View Application Status","View Case Information","Submit Six Month Report","Renew Benefits","Submit Documents"],
        applyForSnapcol2Content: ["Reschedule an Appointment","Report a Change","View Notices","Request Proof of Benefits","Send a Secure Message to a Caseworker","View/Print Benefit History"],
        createAccountLabel: "Crear una cuenta",
        loginLabel: "Acceso",
        clickHereLabel1: "Puede",
        clickHereLabel2: "aplicar sin cuenta.",
        clickHereLabel3: "haga clic aquí",
        snapYouMayEligibleAnon:
        "Puede ser elegible para TANF. Si desea presentar una solicitud, inicie sesión/regístrese en su cuenta desde la página de inicio.",
      };

    case PT:
      return {
        heading: "Antes de começar",
        howApplyLabel: "Como funciona a solicitação?",
        fieldLabel1:
          "Esta solicitação se refere a você, sua família, sua renda e suas despesas. Isso deve levar apenas 20 minutos.",
        fieldLabel2: "Se isso se aplica a você, tenha esses números à mão!",
        fieldLabel3:
          "Você não precisa de um endereço fixo ou de um número de telefone para solicitar o benefício SNAP. Porém, a DHR precisa dos seus dados de contato para concluir a sua solicitação on-line do SNAP. Caso você não tenha um endereço para onde possamos enviar correspondências, ligue para o Serviço de Atendimento DHR no número",
        fieldLabel4: "ou compareça ao seu",
        fieldLabel3Link: "escritório do DHR local.",
        fieldLabel5: "",
        fieldFactors: [
          "Datas de nascimento",
          "Números do seguro social",
          "Renda",
          "Despesas",
        ],
        tanfFieldLabel1:
          "This application is about you and your household. It should only take about 20 minutes.",
        tanfFieldLabel2: "If they apply to you, have these numbers ready!",
        tanfFieldFactors: [
          "Birth dates",
          "Social Security Number (SSN)",
          "Incomes",
        ],
        tanfInstruction:
          "To use this app to apply for Family Assistance or report changes, you must at least enter your name, address, and signature for us to process your application. If you don’t have one or don’t want to enter it, you will need to contact your local office. Click below to get an application form to print, fill out, and sign. Then mail or take it to your local office.",
        tanfApplicationFormLink: "Application Form - English",
        tanfClickHereRulesText:
          "Click here to look at information about the rules to get help:",
        tanfEligibilityLink: "Summarized Eligibility Requirements - English",
        tanfDOHLink: "Dept. of Human Resources",
        tanfImportantInfo:
          "IMPORTANT INFORMATION ABOUT USE and DISCLOSURE of YOUR ENTRIES",
        tanfInformationYouEnterText:
          "La información que ingresa solo puede ser utilizada por nuestro personal. Una vez que haga clic en enviar, su información se enviará a la oficina que atiende el área donde vive para la acción. Informar a otros sobre la información que proporciona está protegido contra la divulgación según el Código de Alabama 1978, 38, Sección 2-6(8). Incluye compartir dentro de nuestro departamento, así como con otras oficinas estatales y federales y con las fuerzas del orden público.",
        tanfTellingOthersText:
          "Telling others about the information you give us is restricted to what is allowed by program rules. It includes sharing within our department as well as with other state and federal offices and law enforcement.",
        tanfTermsAndConditions: "Terms and Conditions",
        boBackButtonLabel: "Voltar",
        sIncome: " Renda",
        getStartButtonLabel: "Começar",
        editButtonLabel: "Editar",
        perLabel: "Por",
        ofLabel: "do",

        //new label:
        your: "O seu ",
        renderAppNoLabel: "número de aplicação: ",
        reviewDta:
          "O DHR está revisando a sua aplicação do SNAP. Se precisarmos de mais informações, ligaremos para você e enviaremos uma lista do que precisamos.",
        highVolume:
          "Devido ao alto volume resultante do COVID-19, não ligue para nós, so se você tenha dúvidas sobre algo que enviamos a você.",
        createAccount: "Crie uma conta ",
        trackInfo:
          "para acompanhar o estado do seu caso, ver os documentos que enviamos, envie documentos, verifique seu saldo e muito mais!",
        needInterview:
          "Só precisamos entrevistá-lo se precisarmos de seus documentos. ",
        list: "Aqui está uma lista",
        commonDocuments: " dos documentos mais comuns que precisamos.",
        submitDocuments:
          "Você pode enviar seus documentos agora criando uma conta online! Você não tem seus documentos? Não se preocupe! Você pode esperar para enviar os documentos até que possamos dizer o que precisamos.",
        youMayLabelOne: "Baixe a sua solicitação ",
        youMayLabeltwo: "caso precise dela no futuro.",
        youMayLabelThree: "for future reference.",
        whatisNextLabel: "Qual é a próxima etapa?       ",
        dtaReachLabel: "O DHR entrará em contato com você em 5 dias.",
        sendDocLabel:
          "Envie os documentos para que o DHR possa confirmar a sua situação.",
        howDoSpeedLabel: "Como eu posso acelerar o processo?",
        donotNeedLabel:
          "Você não precisa esperar que o DHR envie uma lista de verificações.",
        sendLabelOne: "Você pode nos enviar seus",
        sendLabelTwo: " documentos",
        sendLabelThree: "de diversas formas.",
        toLearnOneLabel:
          "Para saber mais sobre os documentos que você pode enviar agora,",
        toLearnOneLabelTwo: "clique aqui",
        manyWayLabel:
          "Há muitas outras formas de nos enviar os seus documentos.",
        manyWayLabelTwo: "Saiba mais",
        howDoSubmitLabel: "Como eu envio os documentos?",
        weSuggestLabel: "Sugerimos que você crie uma conta",
        weSuggestLabelOne: " on-line para fazer o upload dos ",
        weSuggestLabelTwo:
          "documentos, verificar o andamento da sua solicitação, checar o seu saldo e muito mais!",
        findOutMoreLabel: "Saiba mais",
        createOnlineLabel: "Crie uma conta on-line",
        orExistLabel: "Ou saia sem fazer o upload de nenhum documento",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Changing this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "Ótimo! É isso que recebemos de você.",
        lookIncorrectLabel:
          "Se algo estiver incorreto, sinta-se à vontade para editar.",
        anotherHouseholdIncomeLabel:
          "Adicionar a renda de outro membro da família",
        applicationSummaryTitle: "Resumo da aplicação",
        applicationSummaryHeader: "Resumo da aplicação",
        viewApplicationLinkLabel: "Visualizar o resumo da sua solicitação",
        iagreeTermsLabel: "Concordo com os termos",
        agreeTermsErrorMsg: "Concorde com os termos",
        signatureErrorMsg: "Por favor assine sua inscrição",
        signYourAppllication:
          "Assine a sua solicitação digitando o seu nome completo abaixo",
        continueLabel: "Continuar",
        getStartedLabel: "Começar",
        submitButtonLabel: "Enviar a solicitação",
        expenseFieldLabel: "Esta última seção é sobre",
        expenseHouseutility: "Custos residenciais e utilitários",
        expenseCaring: "Cuidados com crianças e adultos",
        expenseChsupport: "Suporte infantil",
        expenseMed: "Despesas médicas",
        expenseAnyinfo:
          "Qualquer informação que você prestar aqui irá fornecer um valor mais preciso do benefício.",
        youMayWantToDownSnap: "download your completed SNAP application",
        ifAnyoneHouseholdEarnedInc:
          "If anyone in the household has earned income, send us proof of income for the last four weeks, such as:",
        proofsForEarnedInc: [
          "Pay stubs or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms with all schedules attached, if self-employed",
        ],
        proofsForEarnedInc1: "Pay stubs or record of payment",
        proofsForEarnedInc2: "Proof of any pay you got and hours worked",
        proofsForEarnedInc3:
          "Completed tax forms with all schedules attached, if self-employed",
        ifAnyoneHouseholdUnEarnedInc:
          "If anyone in the household has unearned income, send us proof of the monthly amount, such as:",
        proofsForUnEarnedInc: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        note: "NOTE: ",
        noteDetails:
          "We can usually verify the amount of Social Security, SSI, Child Support, or Unemployment Compensation benefits ourselves. We will tell you if you need to verify any of these items.",
        snapYouMayEligible:
          "You may be eligible for TANF. If you wish to apply click the Continue button.",
      }; //return End

    case ZH:
      return {
        heading: "在开始之前",
        howApplyLabel: "申请如何进行？",
        fieldLabel1:
          "此申请表是关于您，您的家庭，您的收入和您的开支。只需20分钟。",
        fieldLabel2: "如果对您适用，请就近填写这些数字！",
        fieldLabel3:
          "申请 SNAP 不需要固定地址或电话号码，但 DHR 需要一种您的联系方式来完成您的在线 SNAP 申请。如果您没有可供我们寄邮件的地址，请拨打 DHR 援助热线",
        fieldLabel4: "或前往您当地的",
        fieldLabel3Link: "DHR",
        fieldLabel5: "办公室。",
        fieldFactors: ["出生日期", "社保号码", "收入", "开支"],
        tanfFieldLabel1:
          "This application is about you and your household. It should only take about 20 minutes.",
        tanfFieldLabel2: "If they apply to you, have these numbers ready!",
        tanfFieldFactors: [
          "Birth dates",
          "Social Security Number (SSN)",
          "Incomes",
        ],
        tanfInstruction:
          "To use this app to apply for Family Assistance or report changes, you must at least enter your name, address, and signature for us to process your application. If you don’t have one or don’t want to enter it, you will need to contact your local office. Click below to get an application form to print, fill out, and sign. Then mail or take it to your local office.",
        tanfApplicationFormLink: "Application Form - English",
        tanfClickHereRulesText:
          "Click here to look at information about the rules to get help:",
        tanfEligibilityLink: "Summarized Eligibility Requirements - English",
        tanfDOHLink: "Dept. of Human Resources",
        tanfImportantInfo:
          "IMPORTANT INFORMATION ABOUT USE and DISCLOSURE of YOUR ENTRIES",
        tanfInformationYouEnterText:
          "Information you enter can only be used by our staff. Once you click submit, your information will be sent to the office serving the area where you live for action.",
        tanfTellingOthersText:
          "Telling others about the information you give us is restricted to what is allowed by program rules. It includes sharing within our department as well as with other state and federal offices and law enforcement.",
        tanfTermsAndConditions: "Terms and Conditions",
        boBackButtonLabel: "返回",
        sIncome: "收入",
        getStartButtonLabel: "开始",
        editButtonLabel: "编辑",
        perLabel: "每",
        ofLabel: "的",
        //new label:
        your: "您",
        renderAppNoLabel: "的申请号码: ",
        reviewDta:
          "DHR正在审查您的SNAP申请。如果我们需要更多资料，我们会打电话给您，并向您发送我们需要的资料清单。",
        highVolume:
          "由于COVID-19造成的高流量电话查询，除非您对我们发送给您的邮件有疑问，否则请不要致电给我们。",
        createAccount: "建立一",
        trackInfo:
          "个帐户以追踪案件状态，查看我们发送给您的文件，上传文件，检查余额等！",
        needInterview: "仅在需要您提供文件的情况下，我们才需要访问您。",
        list: "这是我",
        commonDocuments: " 们的最常见需要的文件列表。",
        submitDocuments:
          "您现在可以通过建立在线帐户来提交文件！没有您的文件吗？不用担心!您可以等待提交相关内容，直到我们告诉您我们需要什么为止。",
        youMayLabelOne: "您可能想要",
        youMayLabeltwo: "下载申请表",
        youMayLabelThree: "下载申请表以备日后查阅。",
        whatisNextLabel: "下一步",
        dtaReachLabel: "DHR将在接下来5个工作日内与您联系。",
        sendDocLabel: "发送文件以便DHR能够确认您的状况。",
        howDoSpeedLabel: "如何加速审核流程？",
        donotNeedLabel: "您无需等待DHR向您发送证明文件清单。",
        sendLabelOne: "您可通过多种方式",
        sendLabelTwo: " 向我们发送文件 ",
        sendLabelThree: "",
        toLearnOneLabel: "要详细了解您现在可以提交的文件，请",
        toLearnOneLabelTwo: "单击这里",
        manyWayLabel: "还有很多其他方式可供您提交资料。",
        manyWayLabelTwo: "了解更多",
        howDoSubmitLabel: "如何提交文件？",
        weSuggestLabel: "我们建议您",
        weSuggestLabelOne: "创建一个在线账户",
        weSuggestLabelTwo:
          "您可以通过此账户上传资料，跟踪申请进度，查看余额等等！",
        findOutMoreLabel: "了解更多",
        createOnlineLabel: "创建在线账户",
        orExistLabel: "或退出而不上传任何资料",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Changing this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "这是我们从你那里了解到的情况",
        lookIncorrectLabel: "如果有任何错误之处，敬请改正。",
        anotherHouseholdIncomeLabel: "添加其他家庭成员的收入",
        applicationSummaryTitle: "應用摘要",
        applicationSummaryHeader: "應用摘要",
        viewApplicationLinkLabel: "请查看您的申请摘要",
        iagreeTermsLabel: "我同意这些条款",
        agreeTermsErrorMsg: "请同意条款",
        signatureErrorMsg: "請簽署您的申請",
        signYourAppllication: "请在下方键入您的全名，以代表您在申请表上签字",
        continueLabel: "继续",
        getStartedLabel: "开始",
        submitButtonLabel: "提交申请表",
        expenseFieldLabel: "最后一部分是关于",
        expenseHouseutility: "住房和通用开支",
        expenseCaring: "子女和成人照护费",
        expenseChsupport: "子女抚养费",
        expenseMed: "医疗费",
        expenseAnyinfo:
          "您在此提供的任何信息都将有助于我们确定更准确的补助金额。",
        youMayWantToDownSnap: "download your completed SNAP application",
        ifAnyoneHouseholdEarnedInc:
          "If anyone in the household has earned income, send us proof of income for the last four weeks, such as:",
        proofsForEarnedInc: [
          "Pay stubs or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms with all schedules attached, if self-employed",
        ],
        ifAnyoneHouseholdUnEarnedInc:
          "If anyone in the household has unearned income, send us proof of the monthly amount, such as:",
        proofsForUnEarnedInc: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        note: "NOTE: ",
        noteDetails:
          "We can usually verify the amount of Social Security, SSI, Child Support, or Unemployment Compensation benefits ourselves. We will tell you if you need to verify any of these items.",
        snapYouMayEligible:
          "You may be eligible for TANF. If you wish to apply click the Continue button.",
      }; //return End
    case VI:
      return {
        heading: "Trước khi quý vị bắt đầu",
        howApplyLabel: "Việc nộp đơn diễn ra như thế nào",
        fieldLabel1:
          "Đơn xin này là về quý vị, hộ gia đình của quý vị, thu nhập của quý vị và các chi phí của quý vị. Chỉ mất khoảng 20 phút để hoàn tất.",
        fieldLabel2:
          "Nếu họ nộp đơn cho quý vị, hãy chuẩn bị sẵn những thông tin này!",
        fieldLabel3:
          "Bạn không cần địa chỉ hoặc số điện thoại cố định để đăng ký SNAP, nhưng DHR cần có cách liên hệ với bạn để hoàn thành đơn đăng ký SNAP trực tuyến của bạn. Nếu bạn không có địa chỉ mà chúng tôi có thể gửi thư, vui lòng gọi cho Đường Dây Hỗ Trợ DHR tại số",
        fieldLabel4: "hoặc tới",
        fieldLabel3Link: "văn phòng DHR tại địa phương của bạn.",
        fieldLabel5: "",
        fieldFactors: [
          "Ngày tháng năm sinh",
          "Ngày tháng năm sinh",
          " Thu nhập",
          " Chi phí",
        ],
        tanfFieldLabel1:
          "This application is about you and your household. It should only take about 20 minutes.",
        tanfFieldLabel2: "If they apply to you, have these numbers ready!",
        tanfFieldFactors: [
          "Birth dates",
          "Social Security Number (SSN)",
          "Incomes",
        ],
        tanfInstruction:
          "To use this app to apply for Family Assistance or report changes, you must at least enter your name, address, and signature for us to process your application. If you don’t have one or don’t want to enter it, you will need to contact your local office. Click below to get an application form to print, fill out, and sign. Then mail or take it to your local office.",
        tanfApplicationFormLink: "Application Form - English",
        tanfClickHereRulesText:
          "Click here to look at information about the rules to get help:",
        tanfEligibilityLink: "Summarized Eligibility Requirements - English",
        tanfDOHLink: "Dept. of Human Resources",
        tanfImportantInfo:
          "IMPORTANT INFORMATION ABOUT USE and DISCLOSURE of YOUR ENTRIES",
        tanfInformationYouEnterText:
          "Information you enter can only be used by our staff. Once you click submit, your information will be sent to the office serving the area where you live for action.",
        tanfTellingOthersText:
          "Telling others about the information you give us is restricted to what is allowed by program rules. It includes sharing within our department as well as with other state and federal offices and law enforcement.",
        tanfTermsAndConditions: "Terms and Conditions",
        boBackButtonLabel: "Quay lại",
        sIncome: "'thu nhập của",
        getStartButtonLabel: "Bắt đầu",
        editButtonLabel: "Chỉnh sửa",
        perLabel: "Mỗi",
        ofLabel: "Của",

        //new label:
        your: "So ",
        reviewDta:
          "DHR dang nhan Don xin SNAP cua ban. Neu chung-toi can them thong-tin, chung-toi se goi cho ban va chung-toi cung we gui cho ban mot danh-sach nhung gi chung-toi can.",
        highVolume:
          "Do khoi-luong lon la ket-qua cua COVID-19, vui-long khong goi cho chung-toi, tru khi ban co thac-mac ve dieu gi do chung-toi gui cho ban.",
        createAccount: "Ao mot tai- ",
        trackInfo:
          "khoan de theo-doi trinh-trang truong-hop, xem tai-lieu chung-toi gui cho ban, tai len cac tai-lieu, kiem-tra so du cua ban, va nhieu hon nua!",
        needInterview:
          "Chung-toi chi can phong-van ban neu chung-toi can tai-lieu tu ban. ",
        list: "Duoi day la ",
        commonDocuments:
          "danh-sach cac tau-lieu pho-bien nhat ma chung-toi can.",
        submitDocuments:
          "Ban co-the gui tai-lieu cua minh ngay bay-gio bang-cach Tao Tai-Khoan Truc-Tuyen! Khong co tai-lieu cua ban? Dung lo-lang! Ban co the cho-doi de gui nhung thu ay cho den khi chung toi noi voi ban nhung gi chung-toi can.",
        renderAppNoLabel: "Số Đơn xin: ",
        youMayLabelOne: "Có thể Quý vị muốn ",
        youMayLabeltwo: " tải đơn xin của quý vị về",
        youMayLabelThree: "để tham khảo trong tương lai.",
        whatisNextLabel: "Điều gì diễn ra kế tiếp?",
        dtaReachLabel: "DHR sẽ liên lạc với quý vị trong vòng 5 ngày tới.",
        sendDocLabel:
          "Gửi các tài liệu để DHR có thể xác nhận tình trạng của quý vị. ",
        howDoSpeedLabel: "Làm thế nào để đẩy nhanh tiến trình?",
        donotNeedLabel:
          "Quý vị không cần đợi DHR gửi cho quý vị danh sách xác minh.",
        sendLabelOne: "Quý vị có thể ",
        sendLabelTwo: " gửi cho chúng tôi giấy tờ của quý vị",
        sendLabelThree: " bằng nhiều cách",
        toLearnOneLabel:
          "Để tìm hiểu thêm về các giấy tờ mà quý vị có thể gửi ngay bây giờ,",
        toLearnOneLabelTwo: "hãy nhấp vào đây",
        manyWayLabel: "Có nhiều cách khác để gửi giấy tờ cho chúng tôi.",
        manyWayLabelTwo: "Tìm hiểu thêm",
        howDoSubmitLabel: "Làm thế nào để tôi gửi giấy tờ?",
        weSuggestLabel: "Chúng tôi khuyên quý vị nên",
        weSuggestLabelOne: "tạo một tài khoản trực tuyến",
        weSuggestLabelTwo:
          "để quý vị có thể tải lên giấy tờ, theo dõi tiến trình đơn xin của quý vị, kiểm tra số dư của quý vị và những việc khác nữa!",
        findOutMoreLabel: "Tìm hiểu thêm",
        createOnlineLabel: "Tạo tài khoản trực tuyến",
        orExistLabel: "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Changing this value will delete the previous entered values in upcoming sections.",
        greatHereLabel:
          "Tuyệt vời, đây là những gì chúng tôi thu thập được từ quý vị",
        lookIncorrectLabel:
          "Nếu có bất cứ điều nào trông có vẻ không chính xác, quý vị cứ tự nhiên chỉnh sửa",
        anotherHouseholdIncomeLabel:
          "Bổ sung thu nhập của thành viên khác trong hộ gia đình",
        applicationSummaryTitle: "Tóm tắt ứng dụng",
        applicationSummaryHeader: "Tóm tắt ứng dụng",
        viewApplicationLinkLabel: "Xem bảng Tóm tắt Đơn xin của quý vị",
        iagreeTermsLabel: "Tôi đồng ý với các điều khoản",
        agreeTermsErrorMsg: "Vui lòng đồng ý với các điều khoản",
        signatureErrorMsg: "Vui lòng ký vào đơn của bạn",
        signYourAppllication:
          "Ký tên vào đơn xin của quý vị bằng cách nhập tên đầy đủ của quý vị bên dưới",
        continueLabel: "Tiếp tục",
        getStartedLabel: "Bắt đầu",
        submitButtonLabel: "Gửi Đơn",
        expenseFieldLabel: "Phần cuối cùng này là về",
        expenseHouseutility: "Chi phí nhà ở và tiện ích",
        expenseCaring: "Chăm sóc trẻ em và người lớn",
        expenseChsupport: "Trợ cấp nuôi con",
        expenseMed: "Chi phí y tế",
        expenseAnyinfo:
          "Bất kỳ thông tin nào quý vị cung cấp ở đây sẽ đưa ra số tiền trợ cấp chính xác hơn.",
        youMayWantToDownSnap: "download your completed SNAP application",
        ifAnyoneHouseholdEarnedInc:
          "If anyone in the household has earned income, send us proof of income for the last four weeks, such as:",
        proofsForEarnedInc: [
          "Pay stubs or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms with all schedules attached, if self-employed",
        ],
        ifAnyoneHouseholdUnEarnedInc:
          "If anyone in the household has unearned income, send us proof of the monthly amount, such as:",
        proofsForUnEarnedInc: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        note: "NOTE: ",
        noteDetails:
          "We can usually verify the amount of Social Security, SSI, Child Support, or Unemployment Compensation benefits ourselves. We will tell you if you need to verify any of these items.",
        snapYouMayEligible:
          "You may be eligible for TANF. If you wish to apply click the Continue button.",
      }; //return End

    case HI:
      return {
        heading: "Anvan w kòmanse",
        howApplyLabel: "Kijan pwosesis aplikasyon an travay?",
        fieldLabel1:
          "Aplikasyon sa a konsène oumenm, fwaye w, revni w, ak depans ou yo. Li ta dwe pran apeprè 20 minit sèlman.",
        fieldLabel2: "Si sa yo aplike a oumenm, gen nimewo sa yo sou lamen!",
        fieldLabel3:
          "Ou pa bezwen yon adrès fiks oswa yon nimewo telefòn pou aplike pou SNAP, men DHR bezwen yon fason pou kontakte w pou ranpli aplikasyon SNAP ou a sou entènèt. Si ou pa gen yon adrès kote nou ka voye kourye ba ou pa lapòs, tanpri rele Liy Asistans DHR a nan",
        fieldLabel4: "oswa ale nan biwo lokal ",
        fieldLabel3Link: "DHR w la.",
        fieldLabel5: "",
        fieldFactors: [
          "Dat nesans",
          "Nimewo Sekirite Sosyal",
          "Revni",
          "Depans",
        ],
        tanfFieldLabel1:
          "This application is about you and your household. It should only take about 20 minutes.",
        tanfFieldLabel2: "If they apply to you, have these numbers ready!",
        tanfFieldFactors: [
          "Birth dates",
          "Social Security Number (SSN)",
          "Incomes",
        ],
        tanfInstruction:
          "To use this application to apply for Family Assistance, you must at least enter your name, address, and signature for us to process your application. If you don't want to enter this information, you will need to contact your local office. Click below to get an application form to print, fill out, and sign. Then mail or take it to your local office.",
        tanfApplicationFormLink: "Application Form - English",
        tanfClickHereRulesText:
          "Click here to look at information about the rules to get help:",
        tanfEligibilityLink: "Summarized Eligibility Requirements - English",
        tanfDOHLink: "Dept. of Human Resources",
        tanfImportantInfo:
          "Important information about use and disclosure of your entries.",
        tanfInformationYouEnterText:
          "Information you enter can only be used by our staff. Once you click submit, your information will be sent to the office serving the area where you live for action.",
        tanfTellingOthersText:
          "Telling others about the information you give us is restricted to what is allowed by program rules. It includes sharing within our department as well as with other state and federal offices and law enforcement.",
        tanfTermsAndConditions: "Terms and Conditions",
        boBackButtonLabel: "Tounen",
        getStartButtonLabel: "Kòmanse",
        sIncome: "'Revni",
        editButtonLabel: "Edite",
        perLabel: "Pa",
        ofLabel: "",
        your: "Ou ",
        renderAppNoLabel: "Nimewo Aplikasyon: ",
        reviewDta:
          "DHR ap revize Aplikasyon SNAP ou a. Si nou bezwen plis enfòmasyon, nou pral rele w epi nou pral voye yon lis sa nou bezwen yo ba ou.",
        highVolume:
          "Pou rezon gwo volim akoz COVID-19 la, tanpri pa rele nou sof si ou gen kesyon sou yon bagay nou voye ba ou.",
        createAccount: "Kreye yon kont ",
        trackInfo:
          "pou suiv estati dosye a, gade dokiman nou voye ba ou yo, telechaje dokiman yo, tcheke balans ou, ak plis ankò!",
        needInterview:
          "Nou bezwen fè entèvyou ak ou sèlman si nou bezwen dokiman nan men w. ",
        list: "Men yon lis",
        commonDocuments: " dokiman ki pi komen nou bezwen.",
        submitDocuments:
          "Ou ka soumèt dokiman w yo kounye a nan Kreye yon Kont an Liy! Ou pa gen dokiman w yo? Pa enkyete w! Ou ka tann pou soumèt kèk bagay jiskaske nou di w kisa nou bezwen.",
        youMayLabelOne: "Ou ka vle ",
        youMayLabeltwo: "telechaje aplikasyon w lan",
        youMayLabelThree: "pou plis referans.",
        whatisNextLabel: "Kisa k ap vini apre?",
        dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
        sendDocLabel: "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
        howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
        donotNeedLabel: "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
        sendLabelOne: "Ou ka ",
        sendLabelTwo: "voye dokiman w yo ban nou ",
        sendLabelThree: "nan plizyè fason",
        toLearnOneLabel:
          "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
        toLearnOneLabelTwo: "klike la a",
        manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman ban nou.",
        manyWayLabelTwo: "Jwenn plis enfòmasyon",
        howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
        weSuggestLabel: "Nou sijere",
        weSuggestLabelOne: "kreye yon kont an liy",
        weSuggestLabelTwo:
          "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
        findOutMoreLabel: "Jwenn plis enfòmasyon",
        createOnlineLabel: "Kreye yon kont an Liy",
        orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
        renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
        laterOnLabel:
          "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
        orExitLabel: "Oswa sòti san ou pa konsève travay ou",
        changingLabel:
          "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
        greatHereLabel: "Ekselan! Men sa nou te resevwa nan men w.",
        lookIncorrectLabel:
          "Si nenpòt ladan li parèt pa kòrèk, pa ezite edite.",
        anotherHouseholdIncomeLabel: "Ajoute revni yon lòt manm fwaye a",
        applicationSummaryTitle: "Rezime aplikasyon",
        applicationSummaryHeader: "Rezime aplikasyon",
        viewApplicationLinkLabel: "Gade rezime aplikasyon w lan",
        iagreeTermsLabel: "Mdakò ak kondisyon yo",
        agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
        signatureErrorMsg: "Tanpri siyen aplikasyon w lan",
        signYourAppllication:
          "Siyen aplikasyon w lan nan tape non konplè ou anba a",
        continueLabel: "Kontinye",
        getStartedLabel: "Kòmanse",
        submitButtonLabel: "Soumèt aplikasyon an",
        expenseFieldLabel: "Dènye seksyon sa a se konsènan",
        expenseHouseutility: "Depans pou lojman ak sèvis piblik",
        expenseCaring: "Pran swen timoun ak adilt",
        expenseChsupport: "Sipò Timoun",
        expenseMed: "Depans medikal",
        expenseAnyinfo:
          "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
        youMayWantToDownSnap: "download your completed SNAP application",
        ifAnyoneHouseholdEarnedInc:
          "If anyone in the household has earned income, send us proof of income for the last four weeks, such as:",
        proofsForEarnedInc: [
          "Pay stubs or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms with all schedules attached, if self-employed",
        ],
        ifAnyoneHouseholdUnEarnedInc:
          "If anyone in the household has unearned income, send us proof of the monthly amount, such as:",
        proofsForUnEarnedInc: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        note: "NOTE: ",
        noteDetails:
          "We can usually verify the amount of Social Security, SSI, Child Support, or Unemployment Compensation benefits ourselves. We will tell you if you need to verify any of these items.",
        snapYouMayEligible:
          "You may be eligible for TANF. If you wish to apply click the Continue button.",
      }; //return End

    default:
      return;
  } // Switch End
} // function End

export function childDependnMultilanguageLabels(name, nextName, language) {
  switch (language) {
    case EN:
      return {
        depedentCostHeader:
          "Child or Adult Dependent Care Expenses For " + name,
        dependentCostsQoneLabel:
          "How much does " +
          name +
          " pay for provider care for " +
          nextName +
          "?",
        dependentCostsQtwoLabel:
          "Does " + name + " pay for transportation for " + nextName + "?",
        dependentDriveLabel: "How often does " +name + "drive" +nextName +"to the provider",
        dependentCoditionalQLabel:
          "How much does " +
          name +
          " pay for transportation for " +
          nextName +
          "?",
        driveToQLabel:
          "Does " +
          name +
          " drive " +
          nextName +
          " to and/or from the provider?",
        dependConQuestion:
          "How many providers does " + name + "  drive " + nextName + "  to? ",
        chAdultCareAddrHeader:
          name + "'s Child or Adult Dependent Care address",
        subSectionQuestionLabel:
          "How often does " +
          name +
          " drive " +
          nextName +
          " to the provider? To and from a provider should be counted as two trips.",
        childSupptcondQLabel:
          "What is the child support cost for " + name + " ?",
      };
    case ES:
      return {
        depedentCostHeader:
          "Gastos de cuidado de dependientes de niños o adultos para " + name,
        dependentCostsQoneLabel:
          "¿Cuánto paga " + name + "  por un cuidador para " + nextName + "?",
        dependentCostsQtwoLabel:
          "¿" + name + " paga el transporte de " + nextName + " ?",
        dependentCoditionalQLabel:
          "¿Cuánto paga " + name + " por transporte para " + nextName + "?",
        driveToQLabel:
          "¿ " +
          name +
          " transporta a " +
          nextName +
          " hacia y desde el proveedor?",
        dependConQuestion:
          "¿A cuántos proveedores " +
          name +
          "  transporta a " +
          nextName +
          "  ? ",
        chAdultCareAddrHeader:
          name + " Costos de cuidado de hijos o adultos dependientes",
        subSectionQuestionLabel:
          "¿Con qué frecuencia " +
          name +
          " transporta a " +
          nextName +
          " al proveedor? Hacia y desde un proveedor debe contarse como dos viajes.",
        childSupptcondQLabel:
          "¿Cuál es el costo de manutención para " + name + " ?",
      };
    case PT:
      return {
        depedentCostHeader:
          "Custos de cuidados para criança ou adulto dependente de " + name,
        dependentCostsQoneLabel:
          "Quanto " +
          name +
          "  paga ao prestador de serviço que cuida de " +
          nextName +
          ".?",
        dependentCostsQtwoLabel:
          "Quanto " + name + " paga pelo transporte de " + nextName + ".?",
        dependentCoditionalQLabel:
          "Quanto " + name + "  paga pelo transporte de " + nextName + " ?",
        driveToQLabel:
          name + " leva " + nextName + " até o prestador de serviço?",
        dependConQuestion:
          "A quantos prestadores de serviço " +
          name +
          "  leva" +
          nextName +
          " ?",
        chAdultCareAddrHeader: name + " Custos de crianças ou dependentes",
        subSectionQuestionLabel:
          "Com que frequência " +
          name +
          " leva " +
          nextName +
          " ao prestador de serviço? Levar e buscar conta como duas viagens",
        childSupptcondQLabel:
          "Quais são os custos de suporte infantil de " + name + " ?",
      };
    case ZH:
      return {
        depedentCostHeader: name + "的子女或成人照护费 ",
        dependentCostsQoneLabel:
          name + "向看护人支付多少 " + nextName + " 的照护费?",
        dependentCostsQtwoLabel:
          name + " 是否需要支付 " + nextName + " 的交通费?",
        dependentCoditionalQLabel:
          name + " 需要支付多少 " + nextName + " 的交通费?",
        driveToQLabel: name + " 是否开车接送 " + nextName + " 到看护人那里？",
        dependConQuestion:
          name + " 开车送 " + nextName + " 到多少个看护人那里？",
        chAdultCareAddrHeader: name + " 子女或成人照护费用",
        subSectionQuestionLabel:
          name +
          " 开车送" +
          nextName +
          " 到看护人那里的频率是多少？ 接送看护人的费用应计为两趟行程",
        childSupptcondQLabel: name + " 的子女抚养费是多少？",
      };
    case VI:
      return {
        depedentCostHeader:
          "Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc đối với " + name,
        dependentCostsQoneLabel:
          name +
          "chi trả cho nhà cung cấp dịch vụ chăm sóc cho " +
          nextName +
          " là bao nhiêu?",
        dependentCostsQtwoLabel:
          name + " có thanh toán cho việc chuyên chở " + nextName + " không?",
        dependentCoditionalQLabel:
          name +
          " thanh toán cho việc chuyên chở cho " +
          nextName +
          " bao nhiêu?",
        driveToQLabel:
          name +
          "có lái xe đưa " +
          nextName +
          " đến và / hoặc về từ nhà cung cấp dịch vụ không?",
        dependConQuestion:
          "Có bao nhiêu nhà cung cấp dịch vụ mà " +
          name +
          "  lái xe đưa " +
          nextName +
          " đến?",
        chAdultCareAddrHeader:
          name + " Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
        subSectionQuestionLabel:
          name +
          " lái xe đưa " +
          nextName +
          " đến nhà cung cấp  bao lâu một lần? Đến và về từ chỗ của nhà cung cấp phải được tính là hai chuyến",
        childSupptcondQLabel:
          "Khoản trợ cấp nuôi con đối với " + name + " là gì?",
      };
    case HI:
      return {
        depedentCostHeader:
          "Depans pou swen Timoun oswa Adilt Depandan pou " + name,
        dependentCostsQoneLabel:
          "konbyen " + name + " peye swen founisè pou " + nextName + ".?",
        dependentCostsQtwoLabel:
          "Èske " + name + " peye transpò pou " + nextName + ".?",
        dependentCoditionalQLabel:
          "konbyen " + name + " peye transpò pou " + nextName + ".?",
        driveToQLabel:
          "Èske " +
          name +
          " kondi " +
          nextName +
          " pou ale ak/oswa soti lakay founisè a?",
        dependConQuestion:
          "Lakay konbyen founisè " + name + "  kondi " + nextName + "  ale? ",
        chAdultCareAddrHeader:
          name + "'Adrès Swen pou Timoun oswa Adilt Depandan",
        subSectionQuestionLabel:
          "Konbyen fwa " +
          name +
          " kondi " +
          nextName +
          " lakay founisè a? Ale ak soti lakay yon founisè ta dwe konte kòm de vwayaj.",
        childSupptcondQLabel: "Ki frè sipò timoun pou " + name + " ?",
      };
    default:
      return;
  }
}

export function houseHoldMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        title: "Member " + name,
        header: "About Member " + name,
        conditionalLabel:
          "Who does " + name + " pay the dependent expenses for?",
        subHeader: "Enter for the next member in the household.",
      };
    case ES:
      return {
        title: "miembro " + name,
        header: "Sobre miembro " + name,
        conditionalLabel: "pagar los gastos dependientes por " + name + " ?",
      };
    case PT:
      return {
        title: "Membro " + name,
        header: "Sobre Membro " + name,
        conditionalLabel:
          "Quem paga pelo tratamento do dependente " + name + " ?",
      };
    case ZH:
      return {
        title: "會員 " + name,
        header: "关于會員 " + name,
        conditionalLabel: name + " 向谁支付亲属照护费？",
      };
    case VI:
      return {
        title: "thành viên " + name,
        header: "Thông tin về thành viên " + name,
        conditionalLabel: name + " trả chi phí chăm sóc phụ thuộc cho ai?",
      };
    case HI:
      return {
        title: "Manm " + name,
        header: "Konsènan Manm " + name,
        conditionalLabel: "Pou ki moun " + name + " peye swen depandan?",
      };

    default:
      return;
  }
}

export function aboutMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "About Me",
        header: "About Me",
        subHeader:
          "This section should be completed by/for the applicant. An applicant is generally an adult applying for benefits for themselves and/or other household members. <br/><br/> Note: The case and EBT card information will be in the name entered below.",
        tanfSubHeader:
          "The case will be in the name of the Grantee listed here.",
        comboSubHeader1:
          "This section should be completed by/for the applicant. An applicant is generally an adult applying for benefits for themselves and/or other household members. <br/><br/>",
        comboSubHeader2:
          "Note: The case and EBT card information will be in the name entered below.",
        labelFirstName: "What is your first name?",
        firstNameErrMsg: "Please enter your first name.",
        firstNameErrMsgLength:
          "Please enter first name less than or equal to 15 characters",
        middleName: "What is your middle name?",
        middleNameErrMsg: "Please enter middle name",
        middleNameErrMsgLength:
          "Please enter middle name less than or equal to 15 characters",
        lastNamelabel: "What is your last name?",
        lastNameErrMsg: "Please enter your last name.",
        lastNameErrMsgLength:
          "Please enter last name  less than or equal to 20 characters",
        labelSuffix: "Suffix",
        accOtherErrormsg: "Please enter your accommodation details.",
        accOtherLengthErrormsg: "Please enter at least 3 characters",
        accApplyErrormsg: "Please select an option.",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "What is your date of birth?",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "Please enter a valid date of birth.",
        ssnLabel: "What is your Social Security Number?",
        inputClearLabel: "Don’t have one",
        ssnErrMsg: "Please enter a valid Social Security Number.",
        headerMyContactInfo: "My Contact Info",
        contactInformation1:
          "To submit an online SNAP application you must provide a mailing address and phone number. If you have any questions please call  DHR Assistance Line at ",
        contactInformation2: "or go to your local",
        contacInformationLink: "DHR office.",
        contactInfo1:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit' or to continue your application, please click 'Save & Continue'.",
        contactInfo2: "",
        contactInfo4:
          "You have the right to file an application with only your name, address, and signature. Providing additional details now will help us determine eligibility and to automatically screen for SNAP expedited services. To submit your application click 'Sign & Submit' or continue the application by clicking 'Save & Continue'.",
        contacInfoLink: "Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "Is your household homeless?",
        interviewTypeLabel:"Do you prefer a in-office or telephone interview?",
        interviewTypeOptions: ["In-Office","Telephone"],
        accommodationLabel: "Does anyone in your household need assistance applying for SNAP benefits due to age, disability or other conditions that limit their ability to apply on their own?",
        houselessErrMsg: "Please select whether your having home or not",
        amountErrMsg: "Please enter a valid amount.",
        optionErrMsg: "Please select an option below.",
        houseLessOptions: ["Yes", "No"],
        accommodationOptions: ["Yes", "No"],
        addrQuestionlabel: "What is your home address?",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your marital Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "Please enter a valid Zip code.",
        alsoMailingLabel: "Is this also your mailing address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your mailing address?",
        zipCodeErrmsg: "Please enter a valid Zip code.",
        snapZipCodeErrmsg: "This SNAP application is for Alabama residents. If you live in Alabama, please enter your zip code. If you do not live in Alabama, you must apply in your state.",
        tanfZipCodeErrmsg: "This TANF application is for Alabama residents. If you live in Alabama, please enter your zip code. If you do not live in Alabama, you must apply in your state.",
        comboZipCodeErrmsg: "This SNAP/TANF application is for Alabama residents. If you live in Alabama, please enter your zip code. If you do not live in Alabama, you must apply in your state.",
        masZipCodeErrmsg:
          "This SNAP application is for Alabama residents. If you live in Alabama, please enter your Zip Code. If you do not live in Alabama, you must apply in your state.",
        poBoxErrmsg: "PO Box cannot be accepted as Home Address",
        citiErrmsg: "Please enter a valid City.",
        countyErrmsg: "Please enter a valid County.",
        addressErrmsg: "Please enter a valid Address.",
        mailingAddressErrmsg: "Please enter a mailing address.",
        homeAddressErrmsg: "Please enter a home address.",
        bestPhoneqlabel: "What is the best phone number to reach you?",
        appointmentPreferenceTitle: "<b>Appointment Preferences</b>",
        preferredPhoneQLabel: "What is your preferred phone number?",
        preferredDayQLabel: "Please select your preferred day(s).",
        preferredDayInlineLabel:
          "All appointments are subject to the availability of the ALDHR worker.",
        preferredDayOptions: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Same Day",
        ],
        preferredDayErrMessage: "Please select a day",
        preferredTimeQLabel: "Please select AM and/or PM.",
        preferredTimeOptions: ["AM", "PM"],
        preferredTimeErrMessage: "Please select AM or PM",
        inlineHelp:
          "Please enter a phone number if you have one. This will help us process your SNAP application faster.",
        validPhoneAlert: "Please enter a valid phone number.",
        emailAddressLabel: "What is your email address?",
        emailErrormsg: "Please enter a valid email address.",
        titleEmergency: "Emergency SNAP Benefits",
        titleAccommodation: "Accommodation(s)",
        headerEmergency: "Emergency SNAP Benefits",
        titleHouseholdInformation: "Expedited SNAP Services",
        headerAccommodation: "Accommodation(s)",
        accommodationDesc:
          "Accommodations are ways which Alabama DHR may help you to apply for benefits.",
        emergencySnap: "",
        incomeMoneylabel:
          "Does your income and liquid resources (cash, checking, or savings accounts) add up to less than your monthly housing expenses?",
        inlineHelpLabel:
          "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
        optionYesNo: ["Yes", "No"],
        alertSelectIncome: "Please select income you pay",
        incomeLesslabel:
          "Is your monthly income less than $150 and is your liquid resources (cash, checking, or savings accounts) less than or equal to $100?",
        monthlyIncomeoptions: ["Yes", "No"],
        selectIncomeErrmsg: "Please select income you pay",     
        migrantBefore: "Are you a ",
        migrantTrigger: "migrant worker",
        emerSnapSubHeader:
          "Most SNAP applications are processed within 30 days. However, in some cases a household may be entitled to expedited services. Please answer the questions below so we can decide if you are eligible to have your SNAP application processed sooner.",
        migrantAfter:
          " and is your money in the bank less than or equal to $100?",
        migrantHelp:
          "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.", 
        migrantOptions: ["Yes", "No"],
        migrantErrmsg: "Please select migrant you pay",
        headerMoreAbout: "More About Me",
        areUsBefore: "Are you a ",
        areUsTrigger: "US citizen",
        areUsafter: "?",
        immigrationStatus: "Do you hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        eligibleHelp:
          "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        eligibleHelpTANF:
          "To be eligible for TANF, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        eligibleHelpCOMBO:
          "To be eligible for SNAP/TANF, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        chooseErrmsg: "Please choose citizen",
        tanfEligibleHelp:
          "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        eligibleMemberHelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleMemberHelpCombo:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleOptions: ["Yes", "No"],
        laguageBefore: "What language do you ",
        LANGUAGES: {
          "Cambodian": "km",
          "English": "en",
          "French": "fr",
          "German": "de",
          "Haitian Creole": "ht",
          "Italian": "it",
          "Japanese": "ja",
          "Korean": "ko",
          "Laotian": "lo",
          "Portuguese": "pt",
          "Russian": "ru",
          "Simplified Chinese": "yue",
          "Spanish": "es",
          "Traditional Chinese": "zh",
          "Vietnamese": "vi",
          "Other": "OTHER"
        },
        languageTrigger: "prefer to speak ",
        languageAfter: "?",
        comfortLanHelp:
          "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
        languageErrMsg: "Please choose language",
        genderLabel: "What is your gender?",
        genderOptions: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        labelEthnicity: "What is your ethnicity?",
        helpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        snapHelpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        snapRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
          "Other",
        ],
        otherRaceErrmsg: "Please enter other race information",
        otherRaceErrMsgLength:
          "Please enter other race information greater than 3 characters or less than 250 characters",
        snpaNote:
          "Note: Only those who disclose their citizenship or establish satisfactory immigration status will receive benefits if otherwise eligible. The decision not to furnish this information will not affect the eligibility of the other household members. Information on the immigration status of those who do not apply will not be verified with USCIS. Parents who do not disclose their status may apply for their children. ALL HOUSEHOLD MEMBERS MUST BE LISTED AND ALL QUESTIONS ANSWERED, EVEN FOR THOSE WHO ARE NOT DISCLOSING CITIZENSHIP OR IMMIGRATION STATUS. The income of all members is considered regardless whether or not they apply or are eligible.",
        ebtNote: `<b>Lost, Stolen, or Damaged EBT Card</b>
        <p>Immediately call customer service at: 1-800-997-8888</p>
        <p>If your EBT card has been lost, stolen or is no longer working, please call the number listed above to request a replacement card. They will immediately disable the old card. A replacement card will be mailed to the address they have in their system. Please allow 5-7 business days for the card to arrive to you in the mail. If the address in their system is not correct, you will need to have your county office correct the address before a replacement card can be mailed. Your benefits will be protected as soon as you report your card as lost or stolen. </p>
        <p style="color: #18507F"><b>Reminder:</b> If you have an authorized representative for your SNAP benefits, their card will still work and can be used to make purchases from your account unless you or your authorized representative have requested that it be cancelled.
        </p>`,
        snapRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits. <br/><br/> Please select all that apply.",
        tanfRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive TANF benefits. <br/><br/> Please select all that apply.",
        comboRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP/TANF benefits. <br/><br/> Please select all that apply.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        accommodationAppliesLabel: "Select all that apply ",
        accommodationAppliesOptions: [
          "Telephone Interview",
          "Translation Services",
          "Information in Large Print",
          "Information in Braille",
          "Hearing Impaired Assistance",
          "Speech Impaired Assistance",
          "Other",
        ],
        targetValueOther: "7",
        OthersHelp: "Enter your accommodation details here...",
        OtherRace: "Enter your race here...",
        raceLabel: "What is your race?",
        raceHelp: "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits. <br/><br/> Please select all that apply.",
        optionAIdisplay: "American Indian",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Asian",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Black/African American",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Pacific Islander",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "White",
        declineToAnswerLabel: "Decline to answer",
        inlineHelpSSN:
          "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
        tanfInlineHelpSSN1:
          "SSNs are required for those in the assistance unit. You may provide the SSNs on this application or at the time of interview.",
        tanfInlineHelpSSN2:
          "NOTE: As a non-parent, providing your social security number or information about your citizenship/alienage is optional.",
        ebtCardSectionTitle: "EBT Card",
        needEbtCard: "Do You Need an EBT Card?",
        inlineHelpEbtcard:
          "If you already have an EBT card in your name, you do not need a new one.",
        elsePayExpenses: "Does anyone else pay all or part of this expense?",
        enterPyeesName:
          "Enter the name of the person who pays all or part of this expense.",
        expensesOptions: ["Yes", "No"],
        enterPyeesNameErrMsg:
          "Please enter the name of the person who pays all or part of this expense.",
        outsideHouseholdPayee:
          "Does anyone outside the household pay any of the rent / mortgage.",
        outsideHouseholdPayeesName:
          "Enter the name of the person who pays any of rent / mortgage. ",
        outsideHouseholdPayeesNameErrMsg:
          "Please enter the name of the person who pays any of rent / mortgage.",
        inOfficeLable:'In-Office',
        inOfficeValue:'F',
        telephoneLable:'Telephone',
        telephoneValue:'T'
      };
    case ES:
      return {
        title: "Sobre mí",
        header: "Sobre mí",
        subHeader:
          "Esta sección debe ser completada por/para el solicitante. Un solicitante es generalmente un adulto que solicita beneficios para sí mismo y / u otros miembros del hogar. <br/><br/> Nota: La información del caso y de la tarjeta EBT estará en el nombre ingresado a continuación.",
        tanfSubHeader:
          "El caso estará a nombre del concesionario que figura aquí.",
        comboSubHeader1:
          "Esta sección debe ser completada por/para el solicitante. Un solicitante es generalmente un adulto que solicita beneficios para sí mismo y / u otros miembros del hogar.<br/></br>",
        comboSubHeader2:
          "Nota: La información del caso y de la tarjeta EBT estará en el nombre ingresado a continuación.",
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Ingrese su nombre.",
        firstNameErrMsgLength:
          "Por favor, ingrese el primer nombre con 15 caracteres máximo",
        middleName: "¿Cuál es su segundo nombre?",
        middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        middleNameErrMsgLength:
          "Por favor, ingrese el segundo nombre con 15 caracteres máximo",
        lastNamelabel: "¿Cuál es su apellido?",
        lastNameErrMsg: "Ingrese su apellido.",
        lastNameErrMsgLength:
          "Por favor, ingrese los apellidos con 20 caracteres máximo",
        labelSuffix: "Sufijo",
        accOtherErrormsg: "Por favor introduce los datos de tu alojamiento.",
        accOtherLengthErrormsg: "Por favor ingrese al menos 3 caracteres",
        accApplyErrormsg: "Por favor seleccione una opción",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
        tanfContactInfoHelpLine:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Si elige hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si reúne los requisitos para recibir asistencia acelerada, ya que su solicitud no incluirá información sobre ingresos o recursos. Para firmar y enviar su solicitud ahora, haga clic en 'Firmar y enviar' y para continuar con su solicitud, haga clic en 'Guardar y continuar'",
        dobAlertMsg: "Ingrese una fecha de nacimiento válida.",
        ssnLabel: "¿Cuál es su número de seguro social?",
        inputClearLabel: "No tengo uno",
        ssnErrMsg: "Ingrese un número de Seguro Social válido.",
        headerMyContactInfo: "Mi información de contacto",
        contactInformation1:
          "Para enviar una solicitud SNAP en línea, debe proporcionar una dirección postal y un número de fono. Si tiene alguna pregunta, llame a la línea de asistencia del DHR al ",
        contactInformation2: "o acuda a su",
        contacInformationLink: "oficina local del DHR.",
        contactInfo1:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Si elige hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si reúne los requisitos para recibir asistencia acelerada, ya que su solicitud no incluirá información sobre ingresos o recursos. Para firmar y enviar su solicitud ahora, haga clic en 'Firmar y enviar' o para continuar con su solicitud, haga clic en 'Guardar y continuar'.",
        contactInfo2: "",
        contactInfo4:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Proporcionar detalles adicionales ahora nos ayudará a determinar la elegibilidad y a evaluar automáticamente los servicios acelerados de SNAP. Para enviar su solicitud, haga clic en 'Firmar y enviar' o continúe con la solicitud haciendo clic en 'Guardar y continuar'.",
        contacInfoLink: "Solicite beneficios SNAP (cupones de alimentos).",
        contactInfo3: "",
        houselessLabel: "¿Es su núcleo familiar indigente?",
        houselessErrMsg: "Seleccione si tiene o no su hogar",
        amountErrMsg: "Por favor ingrese una cantidad válida",
        optionErrMsg: "Por favor, seleccione una opción a continuación",
        houseLessOptions: ["Sí", "No"],
        interviewTypeLabel:"¿Prefiere una entrevista en la oficina o por teléfono?",
        interviewTypeOptions: ["En la oficina","Teléfono"],
        accommodationOptions: ["Sí", "No"],
        addrQuestionlabel: "¿Cuál es su dirección?",
        tanfAddrQuestionlabel: "¿Cuál es su dirección residencial?",
        maritalStatus: "¿Cuál es su estado material?",
        targetValueOther: "7",
        OthersHelp: "Introduce aquí los datos de tu alojamiento...",
        titleAccommodation: "Alojamiento(s)",
        headerAccommodation: "Alojamiento(s)",
        accommodationDesc:
          "Las adaptaciones son formas en las que Alabama DHR puede ayudarlo.",
        accommodationLabel: "¿Necesitas alguna adaptación?",
        accommodationAppliesLabel:
          "Seleccione todas las opciones que correspondan",
        accommodationAppliesOptions: [
          "Entrevista telefónica",
          "Servicios de traducción",
          "Información en letra grande",
          "Información en Braille",
          "Asistencia a personas con discapacidad auditiva",
          "Asistencia para discapacitados del habla",
          "Otro",
        ],
        materialStatusOptions: [
          "Soltero",
          "Casado",
          "Apartado",
          "Divorciado",
          "Viudo",
        ],
        showDOBForMaterialStatus: [
          "Casado",
          "Apartada",
          "Divorciada",
          "Viuda",
        ],
        appointmentPreferenceTitle: "<b>Preferencias de citas</b>",
        preferredPhoneQLabel: "¿Cuál es tu número de teléfono preferido?",
        preferredDayQLabel: "Por favor seleccione su(s) día(s) preferido(s).",
        preferredDayInlineLabel:
          "Las citas el mismo día están sujetas a la disponibilidad del trabajador de ALDHR.",
        preferredDayOptions: [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Mismo día",
        ],
        preferredDayErrMessage: "Por favor seleccione un día",
        preferredTimeQLabel: "Por favor seleccione AM y/o PM",
        preferredTimeOptions: ["AM", "PM"],
        preferredTimeErrMessage: "Por favor seleccione AM o PM",
        validZipcode: "Ingrese un código postal válido.",
        alsoMailingLabel: "¿Es esta su dirección postal?",
        optionsSendMail: "Sí, enviar correo allí. ",
        optionsNoDiffAddress:
          "No, utilizo una dirección diferente para el correo postal.",
        mailingQlabel: "¿Cuál es su dirección postal?",
        immigrationStatus:
          "¿Esta persona tiene un estatus migratorio satisfactorio?",
        immigrationOptions: ["Sí", "No"],
        zipCodeErrmsg: "Ingrese un código postal válido.",
        snapZipCodeErrmsg: "Esta solicitud SNAP es para residentes de Alabama. Si vive en Alabama, ingrese su código postal. Si no vive en Alabama, debe presentar la solicitud en su estado.",
        tanfZipCodeErrmsg: "Esta solicitud TANF es para residentes de Alabama. Si vive en Alabama, ingrese su código postal. Si no vive en Alabama, debe presentar la solicitud en su estado.",
        comboZipCodeErrmsg: "Esta solicitud SNAP/TANF es para residentes de Alabama. Si vive en Alabama, ingrese su código postal. Si no vive en Alabama, debe presentar la solicitud en su estado.",
        masZipCodeErrmsg:
          "Esta aplicacion de SNAP es para residents de Alabama. Si vive en Alabama, ingrese su codigo postal. Si no vive en Alabama, debe someter una solicitud en su estado.",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        countyErrmsg: "Por favor ingrese un condado válido.",
        addressErrmsg: "Por favor, ingrese una dirección válida.",
        mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
        homeAddressErrmsg: "Por favor ingrese una dirección particular.",
        bestPhoneqlabel:
          "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
        inlineHelp:
          "Por favor, ingrese un número de teléfono si lo tiene. Esto nos ayudará a procesar su aplicación SNAP más rápido.",
        validPhoneAlert: "Ingrese un número de teléfono válido",
        emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
        emailErrormsg: "Introduce una dirección de correo electrónico válida",
        titleEmergency: "Beneficios de SNAP de emergencia",
        headerEmergency: "Beneficios de SNAP de emergencia",
        emergencySnap: "",
        titleHouseholdInformation: "Servicios SNAP acelerados",
        incomeMoneylabel:
          "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
        inlineHelpLabel:
          "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
        optionYesNo: ["Sí", "No"],
        alertSelectIncome: "Por favor seleccione el ingreso que paga",
        incomeLesslabel:
          "¿Su ingreso mensual es inferior a $150 y su dinero en el banco es menor o igual a $100?",
        monthlyIncomeoptions: ["Sí", "No"],
        selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
        migrantLabel:"¿Es usted un trabajador migrante y su dinero en el banco es menor o igual a $100?",
        migrantBefore: "¿Es usted un ",
        migrantTrigger: " trabajador migrante",
        emerSnapSubHeader:
          "La mayoría de las solicitudes de SNAP se procesan dentro de los 30 días. Sin embargo, en algunos casos, un hogar puede tener derecho a servicios acelerados. Responda las preguntas a continuación para que podamos decidir si es elegible para que su solicitud de SNAP se procese antes.",
        migrantAfter: " ¿Y su dinero en el banco es menor o igual a $100?",
        migrantHelp:
          "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
        migrantOptions: ["Sí", "No"],
        migrantErrmsg: "Por favor selecciona migrante pagas",
        headerMoreAbout: "Más Sobre Mí",
        areUsBefore: "Eres una ",
        areUsTrigger: "Ciudadana estadounidense",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegible para SNAP, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        eligibleHelpTANF:
          "Para ser elegible para TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        eligibleHelpCOMBO:
          "Para ser elegible para SNAP/TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",

        chooseErrmsg: "Por favor elija ciudadano",
        eligibleMemberHelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        eligibleMemberHelpCombo:
          "Incluso si no es ciudadano estadounidense, usted u otros miembros de su hogar aún pueden ser elegibles.",
        eligibleOptions: ["Sí", "No"],
        LANGUAGES: {
          "Aleman": "de",
          "Ingles": "en",
          "Espanol": "es",
          "Frances": "fr",
          "Criollo haitiano": "ht",
          "Italiano": "it",
          "Japones": "ja",
          "Camboyano": "km",
          "Coreano": "ko",
          "Laosiano": "lo",
          "Portugues": "pt",
          "Ruso": "ru",
          "Vietnamita": "vi",
          "Chino - Cantones": "yue",
          "Chino": "zh",
          "Otro": "OTHER"
        },
        laguageBefore: "¿Qué idioma ",
        languageTrigger: " prefiere hablar",
        languageAfter: "?",
        comfortLanHelp:
          "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
        languageErrMsg: "Por favor, elija el idioma",
        genderLabel: "¿Cuál es su sexo?",
        genderOptions: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        labelEthnicity: "¿Cuál es tu origen étnico?",
        helpEthnicity:
          "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        tanfhelpEthnicity:
          "La recopilación de etnias/razas es solo para fines estadísticos y de informes federales. Proporcionar esta información no afectará su elegibilidad para los beneficios de TANF.",
        tanfRaceOptions: [
          "Indio americano/nativo de Alaska",
          "Asiático",
          "Negro / Afroamericano",
          "Hawaiano nativo/otro isleño del Pacífico",
          "Blanco",
          "Negarse a contestar",
        ],
        snapHelpEthnicity:
          "Pedimos su origen étnico para garantizar la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir beneficios de SNAP.",
        snapRaceOptions: [
          "Indio americano/nativo de Alaska",
          "Asiático",
          "Negro / Afroamericano",
          "Hawaiano nativo/otro isleño del Pacífico",
          "Blanco",
          "Negarse a contestar",
          "Otro",
        ],
        otherRaceErrmsg: "Por favor ingresa otra información de la carrera",
        otherRaceErrMsgLength:
          "Ingrese otra información de la carrera de más de 3 caracteres o menos de 250 caracteres",
        snpaNote:
          "Nota: Solo aquellos que revelen su ciudadanía o establezcan un estatus migratorio satisfactorio recibirán beneficios si son elegibles. La decisión de no proporcionar esta información no afectará la elegibilidad de los otros miembros del hogar. La información sobre el estatus migratorio de aquellos que no soliciten no será verificada con USCIS. Los padres que no revelan su estado pueden solicitar a sus hijos.  TODOS LOS MIEMBROS DEL HOGAR DEBEN ESTAR EN LA LISTA Y TODAS LAS PREGUNTAS RESPONDIDAS, INCLUSO PARA AQUELLOS QUE NO ESTÁN REVELANDO LA CIUDADANÍA O EL ESTADO MIGRATORIO. Los ingresos de todos los miembros se consideran independientemente de si solicitan o no o son elegibles.",
        ebtNote: `<b>Tarjeta EBT perdida, robada o dañada</b>
        <p>Llame inmediatamente al servicio al cliente al: 1-800-997-8888.</p>
        <p>Si su tarjeta EBT se ha perdido, ha sido robada o ya no funciona, llame al número mencionado anteriormente para solicitar una tarjeta de reemplazo. Inmediatamente desactivarán la tarjeta anterior. Se enviará una tarjeta de reemplazo por correo a la dirección que tienen en su sistema. Espere de 5 a 7 días hábiles para que la tarjeta le llegue por correo. Si la dirección en su sistema no es correcta, deberá hacer que la oficina de su condado corrija la dirección antes de que se pueda enviar una tarjeta de reemplazo por correo. Sus beneficios estarán protegidos tan pronto como reporte su tarjeta como perdida o robada.</p>
        <p style="color: #18507F"><b>Recordatorio:</b> Si tiene un representante autorizado para sus beneficios SNAP, su tarjeta seguirá funcionando y podrá usarse para realizar compras desde su cuenta a menos que usted o su representante autorizado hayan solicitado que se cancele.</p>`,
        snapRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP. <br/> <br/> Seleccione todo lo que corresponda.",
        tanfRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de TANF. <br/> <br/> Seleccione todo lo que corresponda.",
        comboRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP/TANF. <br/> <br/> Seleccione todo lo que corresponda.",
        hisPanicOption: "Hispano/Latino",
        nonhisPanicOption: "No hispano/Latino",
        OtherRace: "Ingresa tu carrera aquí...",
        raceLabel: "¿Cuál es tu raza?",
        raceHelp: "Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP. <br><br> Seleccione todo lo que corresponda.",
        optionAIdisplay: "Indio americano",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afroamericano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Isleño del Pacífico",
        optionwhitedisplay_VALUE: "Blanco",
        optionwhitedisplay: "Blanco",
        declineToAnswerLabel: "Me niego a responder",
        inlineHelpSSN:
          "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan dar su estado de SSN o ciudadanía.",
        tanfInlineHelpSSN1:
          "Se requieren SSN para aquellos en la unidad de asistencia. Puede proporcionar los SSN en esta solicitud o en el momento de la entrevista.",
        tanfInlineHelpSSN2:
          "NOTA: Como no padre, proporcionar su número de seguro social o información sobre su ciudadanía / extranjería es opcional.",
        ebtCardSectionTitle: "Tarjeta EBT",
        needEbtCard: "¿Necesita una tarjeta EBT?",
        inlineHelpEbtcard:
          "Aclaración: Si tiene una tarjeta EBT a su nombre de un caso anterior, puede volver a usar esa tarjeta si la aprueban.",
        poBoxErrmsg:
          "No se puede aceptar un apartado postal como dirección particular",
        elsePayExpenses: "¿Alguien más paga todo o parte de este gasto?",
        enterPyeesName:
          "Ingrese el nombre de la persona que paga todo o parte de este gasto.",
        expensesOptions: ["Sí", "No"],
        enterPyeesNameErrMsg:
          "Por favor ingrese el nombre de la persona que paga todo o parte de este gasto.",
        outsideHouseholdPayee:
          "¿Alguien fuera del hogar paga alguno de los alquiler/hipoteca.",
        outsideHouseholdPayeesName:
          "Ingrese el nombre de la persona que paga cualquiera de alquiler/hipoteca.",
        outsideHouseholdPayeesNameErrMsg:
          "Por favor ingrese el nombre de la persona que paga cualquiera de alquiler/hipoteca.",
        medicalExpenseType: "¿Qué tipo de gastos médicos tienes? (Seleccione todas las que correspondan)",
        doctorOrDentist: "Doctor o dentista",
        hospitalOrNursingHome: "Hospital o residencia de ancianos",
        prescriptionDrugs: "Medicamentos con receta",
        overTheCounterMedicine: "Medicina sin receta",
        healthInsurancePremiums: "Primas de seguro médico",
        medicalSupplies: " Suministros médicos",
        medicaidCostSharing: "Costo compartido de Medicaid",
        denturesOrHearingAids: "Dentaduras postizas o audífonos",
        prescriptionEyeGlases: "Anteojos recetados",
        transportationOrLoadingForMedicalServices: "Transporte o Carga para Servicios Médicos",
        HudHomeHealthAide: "Asistente de atención médica domiciliaria o cuidado infantil de HUD",
        serviceAnimal: "Animal de servicio",
        otherMedicalExpense: "Otro",
        inOfficeLable:'En la oficina',
        inOfficeValue:'F',
        telephoneLable:'Teléfono',
        telephoneValue:'T'
      };
    case PT: //portugeesh
      return {
        title: "Sobre mim",
        header: "Sobre mim",
        labelFirstName: "Qual é o seu primeiro nome?",
        firstNameErrMsg: "Insira o seu primeiro nome",
        firstNameErrMsgLength: "Digite o seu nome com até 15 caracteres",
        middleName: "Qual é o seu nome do meio?",
        middleNameErrMsg: "Digite o nome do meio",
        middleNameErrMsgLength:
          "Digite o seu nome do meio com até 15 caracteres",
        lastNamelabel: "Qual é o seu último nome?",
        lastNameErrMsg: "Insira o seu sobrenome",
        lastNameErrMsgLength: "Digite o seu sobrenome com até 20 caracteres",
        labelSuffix: "Sufixo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Qual é a sua data de nascimento?",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "Insira uma data de nascimento válida",
        ssnLabel: "Qual é o número do seu seguro social?",
        inputClearLabel: "Eu não tenho",
        ssnErrMsg: "Insira um número de seguro social válido",
        headerMyContactInfo: "Minhas informações de contato",
        contactInformation1:
          "Para enviar uma solicitação on-line do SNAP, forneça um endereço de correspondência e número de tfone. Se você tiver quaisquer perguntas, ligue para o Serviço de Atendimento DHR no número ",
        contactInforamtion2: "ou compareça ao seu",
        contacInformationLink: " escritório do DHR local.",
        contactInfo1:
          "Para enviar uma aplicação de SNAP on-line, você deve fornecer um endereço de correspondência, e-mail e número de telefone. Se você não tem isso, vá para ",
        contactInfo2: "",
        contacInfoLink: " Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "Você é desabrigado?",
        houselessErrMsg: "Selecione se você tem casa ou não",
        amountErrMsg: "Digite um valor válido",
        optionErrMsg: "por favor selecione uma opção abaixo",
        houseLessOptions: ["Sim", "Não"],
        accommodationOptions: ["Sim", "Não"],
        addrQuestionlabel: "Qual é o seu endereço?",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your material Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "Insira um Zip Code válido",
        alsoMailingLabel: "O seu endereço de correspondência é o mesmo?",
        optionsSendMail: "Sim, envie as correspondências para esse endereço. ",
        optionsNoDiffAddress:
          "Não, eu uso um endereço diferente para correspondência.",
        mailingQlabel: "Qual é o seu endereço de correspondência? ",
        zipCodeErrmsg: "Insira um Zip Code válido",
        masZipCodeErrmsg:
          "Esta aplicação de SNAP é para residentes de Alabama. Se você mora em Alabama, digite seu CEP. Se você não mora em Alabama, deve applicar no seu estado",
        citiErrmsg: "Digite uma cidade válida",
        addressErrmsg: "Digite um endereço válido",
        bestPhoneqlabel:
          "Qual é o melhor número de telefone para falar com você?",
        inlineHelp:
          "Digite um número de telefone, se tiver um. Isso nos ajudará a processar a sua solicitação do SNAP mais rapidamente.",
        validPhoneAlert: "Insira um número de telefone válido",
        emailAddressLabel: "Qual é o seu endereço de e-mail?",
        emailErrormsg: "Por favor, digite um e-mail válido",
        titleEmergency: "Benefício SNAP emergencial",
        headerEmergency: "Benefício SNAP emergencial",
        emergencySnap:
          "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
        incomeMoneylabel:
          "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
        inlineHelpLabel:
          'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
        optionYesNo: ["Sim", "Não"],
        alertSelectIncome: "Selecione a renda que você paga",
        incomeLesslabel:
          "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
        monthlyIncomeoptions: ["Sim", "Não"],
        selectIncomeErrmsg: "Selecione a renda que você paga",
        migrantBefore: "Você é um ",
        migrantTrigger: "trabalhador migrante",
        migrantAfter: " e tem US$ 100 ou menos no banco?",
        migrantHelp:
          "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
        migrantOptions: ["Sim", "Não"],
        migrantErrmsg: "Selecione o migrante que você paga",
        headerMoreAbout: "Mais sobre mim",
        areUsBefore: "Você é ",
        areUsTrigger: "cidadão americano",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        chooseErrmsg: "Escolha cidadão",
        eligibleMemberHelp:
          "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
        eligibleOptions: ["Sim", "Não"],
        laguageBefore: "Em qual idioma você ",
        LANGUAGES: {
          "Albanian": "sq",
          "American Sign Language User": "au",
          "Amharic": "am",
          "Arabic": "ar",
          "Armenian": "hy",
          "Khmer (Cambodian)": "km",
          "Cape Verdean Creole": "kea",
          "Chinese - Mandarin": "cmn",
          "Chinese": "zh-cn",
          "Chinese - Cantonese": "yue",
          "Croatian": "hr",
          "English": "en",
          "French": "fr",
          "Greek": "el",
          "Haitian Creole": "ht",
          "Italian": "it",
          "Laotian": "lo",
          "Nepali": "ne",
          "Polish": "pl",
          "Portuguese": "pt",
          "Russian": "ru",
          "Serbian Cyrillic": "sr",
          "Slovenian": "sl",
          "Somali": "so",
          "Spanish": "es",
          "Tagalog": "tl",
          "Vietnamese": "vi"
        },
        languageTrigger: "prefere se comunicar",
        languageAfter: "?",
        comfortLanHelp:
          "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
        languageErrMsg: "Escolha o idioma",
        genderLabel: "Qual é o seu gênero?",
        genderOptions: ["Masculino", "Feminino"],
        genderErrmsg: "Escolha o gênero",
        labelEthnicity: "Qual é a sua etnia?",
        helpEthnicity:
          "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        raceLabel: "Qual é a sua raça?",
        hisPanicOption: "Hispânico/Latino",
        nonhisPanicOption: "Não-Hispânico/Latino",
        raceHelp:
          "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        optionAIdisplay: "Americano nativo",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afro-americano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Nativo das ilhas do Pacífico",
        optionwhitedisplay_VALUE: "Branco",
        optionwhitedisplay: "Branco",
        declineToAnswerLabel: "Não quero responder",
        inlineHelpSSN:
          "De acordo com as leis federais, não cidadãos que não estiverem solicitando o SNAP não precisam informar o SSN ou o status de cidadania.",
        ebtCardSectionTitle: "Cartão EBT",
        needEbtCard: "Você precisa de um cartão EBT?",
        inlineHelpEbtcard:
          "Nota: se você foi aprovado e possui um cartão EBT de um caso anterior emitido em seu nome, você pode usá-lo novamente. A solicitação de um novo cartão EBT cancela automaticamente todos os cartões anteriores.",
      };
    case ZH: //China
      return {
        title: "关于我",
        header: "关于我",
        labelFirstName: "您的名字",
        firstNameErrMsg: "请输入您的名字",
        firstNameErrMsgLength: "请输入名字，不超过 15 个字符",
        middleName: "您的中间名",
        middleNameErrMsg: "請輸入中間名",
        middleNameErrMsgLength: "请输入中间名，不超过 15 个字符",
        lastNamelabel: "您的姓氏",
        lastNameErrMsg: "请输入您的姓氏",
        lastNameErrMsgLength: "请输入姓氏，不超过 20 个字符",
        labelSuffix: "后缀",
        suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
        dateOfBirthLabel: "您的出生日期？",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "请输入有效出生日期",
        ssnLabel: "你的社保号码？",
        inputClearLabel: "我没有社保",
        ssnErrMsg: "请输入有效社会安全号码",
        headerMyContactInfo: "我的联系信息",
        contactInformation1:
          "如需提交在线 SNAP 申请，您必须提供邮寄地址 和電話號碼。如果你有任何问题 请拨打 DHR 援助热线 ",
        contactInformation2: " 或前往您当地的 ",
        contacInformationLink: "DHR",
        contactInformation3: "办公室。",
        contactInfo1:
          "若要在网上提交申请SNAP 表格，您必须提供邮寄地址、电子邮件和电话号码。 如果您没有这些，请访问 ",
        contactInfo2: "",
        contacInfoLink: "Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "您是无家可归吗？",
        houselessErrMsg: "請選擇您是否有家",
        amountErrMsg: "請輸入有效金額",
        optionErrMsg: "请在下面选择一个选项",
        houseLessOptions: ["是", "否"],
        accommodationOptions: ["是", "否"],
        addrQuestionlabel: "您的家庭地址？",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your material Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "请输入有效邮编",
        alsoMailingLabel: "这也是您的邮箱地址吗？",
        optionsSendMail: "是，可以发送信件到这里。",
        optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
        mailingQlabel: "您的邮箱地址？",
        zipCodeErrmsg: "请输入有效邮编",
        masZipCodeErrmsg:
          "此SNAP申请只适用于马萨诸塞州居民。 如果您居住在马萨诸塞州，请输入邮政编码。如果您不是居住在马萨诸塞州，则必须在您所在的州提出申请。",
        citiErrmsg: "请输入有效城市",
        addressErrmsg: "请输入有效地址",
        bestPhoneqlabel: "您最常使用的电话号码？",
        inlineHelp:
          "请输入电话号码（如有）。这将帮助我们更快地处理您的 SNAP 申请。",
        validPhoneAlert: "请输入有效电话号码",
        emailAddressLabel: "您的邮箱地址？",
        emailErrormsg: "请输入有效电邮地址",
        titleEmergency: "紧急SNAP补助",
        headerEmergency: "紧急SNAP补助",
        emergencySnap:
          "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
        incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
        inlineHelpLabel:
          "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
        optionYesNo: ["是", "否"],
        alertSelectIncome: "請選擇您支付的收入",
        incomeLesslabel:
          "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
        monthlyIncomeoptions: ["是", "否"],
        selectIncomeErrmsg: "請選擇您支付的收入",
        migrantBefore: "您是否是 ",
        migrantTrigger: "外来务工人员",
        migrantAfter: " 您的银行存款是否少于100美元？",
        migrantHelp:
          "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
        migrantOptions: ["是 ", "否"],
        migrantErrmsg: "請選擇你支付的移民",
        headerMoreAbout: "关于我的更多信息",
        areUsBefore: "您是否是 ",
        areUsTrigger: "美国公民",
        areUsafter: "?",
        eligibleHelp:
          "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DHR将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        chooseErrmsg: "請選擇公民",
        eligibleMemberHelp:
          "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
        eligibleOptions: ["是", "否"],
        laguageBefore: "您 ",
        LANGUAGES: {
          "Albanian": "sq",
          "American Sign Language User": "au",
          "Amharic": "am",
          "Arabic": "ar",
          "Armenian": "hy",
          "Khmer (Cambodian)": "km",
          "Cape Verdean Creole": "kea",
          "Chinese - Mandarin": "cmn",
          "Chinese": "zh-cn",
          "Chinese - Cantonese": "yue",
          "Croatian": "hr",
          "English": "en",
          "French": "fr",
          "Greek": "el",
          "Haitian Creole": "ht",
          "Italian": "it",
          "Laotian": "lo",
          "Nepali": "ne",
          "Polish": "pl",
          "Portuguese": "pt",
          "Russian": "ru",
          "Serbian Cyrillic": "sr",
          "Slovenian": "sl",
          "Somali": "so",
          "Spanish": "es",
          "Tagalog": "tl",
          "Vietnamese": "vi"
        },
        languageTrigger: "日常使用哪种语言交流",
        languageAfter: "?",
        comfortLanHelp:
          "请告诉我们您最熟悉的语言，以便DHR可以确保使用您的语言进行面试。",
        languageErrMsg: "請選擇語言",
        genderLabel: "您的性别？",
        genderOptions: ["男", "女"],
        genderErrmsg: "請選擇性別",
        labelEthnicity: "您的民族？",
        helpEthnicity:
          "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        hisPanicOption: "西班牙/拉丁美洲",
        nonhisPanicOption: "非西班牙/拉丁美洲",
        raceLabel: "您的种族？",
        raceHelp:
          "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        optionAIdisplay: "美洲印第安人",
        optionAsiandisplay_VALUE: "亚洲人",
        optionAsiandisplay: "亚洲人",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "黑人/非裔美国人",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "太平洋岛民",
        optionwhitedisplay_VALUE: "白人",
        optionwhitedisplay: "白人",
        declineToAnswerLabel: "拒绝回答",
        inlineHelpSSN:
          "依据联邦法规，未申请 SNAP 的非公明身份人士无需提供其 SSN 或公民身份状态。",
        ebtCardSectionTitle: "EBT卡",
        needEbtCard: "您需要EBT卡吗？",
        inlineHelpEbtcard:
          "请注意：如果您在之前的案例中有您名下的EBT卡，您可在获批后再次使用该卡。 申请EBT卡将立即取消其他的卡。",
      };
    case VI: //Vietnam
      return {
        title: "Thông tin về tôi",
        header: "Thông tin về tôi",
        labelFirstName: "Tên của quý vị?",
        firstNameErrMsg: "Điền tên",
        firstNameErrMsgLength:
          "Vui lòng nhập vào tên gọi có ít hơn hoặc bằng 15 ký tự",
        middleName: "Tên lót của quý vị?",
        middleNameErrMsg: "Vui lòng nhập tên đệm",
        middleNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 15 ký tự",
        lastNamelabel: "Họ của quý vị?",
        lastNameErrMsg: "Điền họ",
        lastNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 20 ký tự",
        labelSuffix: "Tước hiệu",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "Điền ngày sinh",
        ssnLabel: "Số an sinh xã hội của quý vị?",
        inputClearLabel: "Tôi không có số ASXH",
        ssnErrMsg: "Điền mã an xinh xã hội",
        headerMyContactInfo: "Thông tin liên lạc",
        contactInformation1:
          "Để nộp đơn xin SNAP trực tuyến, bạn phải cung cấp địa chỉ gửi thư và số điện thoại. Nếu bạn có câu hỏi nào, vui lòng gọi cho Đường Dây Hỗ Trợ DHR tại số ",
        contactInformation2: "hoặc tới",
        contacInformationLink: "văn phòng DHR tại địa phương của bạn.",
        contactInfo1:
          "Để nộp đơn xin SNAP trực tuyến, bạn phải cung cấp địa chỉ gửi thư, email và số điện thoại. Nếu bạn không có những thứ đó, vui lòng truy cập ",
        contactInfo2: "",
        contacInfoLink: "Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "Hộ gia đình của quý vị có thuộc diện vô gia cư không?",
        houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        amountErrMsg: "Vui lòng nhập số tiền hợp lệ",
        optionErrMsg: "Vui lòng chọn một tùy chọn bên dưới",
        houseLessOptions: ["Có", "Không"],
        accommodationOptions: ["Có", "Không"],
        addrQuestionlabel: "Địa chỉ của quý vị?",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your material Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "Điền mã bưu điện",
        alsoMailingLabel: "Đây cũng là địa chỉ gởi thư của quý vị phải không?",
        optionsSendMail: "Phải, hãy gửi thư đến đó.",
        optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
        mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
        zipCodeErrmsg: "Điền mã bưu điện",
        masZipCodeErrmsg:
          "This SNAP application is for Alabama residents. If you live in Alabama, please enter your Zip Code. If you do not live in Alabama, you must apply in your state.",
        citiErrmsg: "Vui lòng nhập vào tên thành phố",
        addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
        bestPhoneqlabel: "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
        inlineHelp:
          "Vui lòng nhập vào số điện thoại nếu có. Việc này sẽ giúp chúng tôi xử lý đơn SNAP của bạn nhanh hơn.",
        validPhoneAlert: "Điền số điện thoại",
        emailAddressLabel: "Địa chỉ email của quý vị là gì?",
        emailErrormsg: "Xin vui lòng nhập email hợp lệ",
        titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        emergencySnap:
          "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
        incomeMoneylabel:
          "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
        inlineHelpLabel:
          "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
        optionYesNo: ["Có", "Không"],
        alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
        incomeLesslabel:
          "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
        monthlyIncomeoptions: ["Có", "Không"],
        selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
        migrantBefore: "Quý vị có phải là ",
        migrantTrigger: "công nhân di cư",
        migrantAfter:
          " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
        migrantHelp:
          "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
        migrantOptions: ["Có", "Không"],
        migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
        headerMoreAbout: "Thông tin thêm về tôi",
        areUsBefore: "Quý vị có là một ",
        areUsTrigger: "Công dân Hoa kỳ",
        areUsafter: "?",
        eligibleHelp:
          "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        chooseErrmsg: "Vui lòng chọn công dân",
        eligibleMemberHelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        eligibleOptions: ["Có", "Không"],
        laguageBefore: "Quý vị ",
        LANGUAGES: {
          "Albanian": "sq",
          "American Sign Language User": "au",
          "Amharic": "am",
          "Arabic": "ar",
          "Armenian": "hy",
          "Khmer (Cambodian)": "km",
          "Cape Verdean Creole": "kea",
          "Chinese - Mandarin": "cmn",
          "Chinese": "zh-cn",
          "Chinese - Cantonese": "yue",
          "Croatian": "hr",
          "English": "en",
          "French": "fr",
          "Greek": "el",
          "Haitian Creole": "ht",
          "Italian": "it",
          "Laotian": "lo",
          "Nepali": "ne",
          "Polish": "pl",
          "Portuguese": "pt",
          "Russian": "ru",
          "Serbian Cyrillic": "sr",
          "Slovenian": "sl",
          "Somali": "so",
          "Spanish": "es",
          "Tagalog": "tl",
          "Vietnamese": "vi"
        },
        languageTrigger: " thích nói ngôn ngữ nào hơn",
        languageAfter: "?",
        comfortLanHelp:
          "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
        languageErrMsg: "Vui lòng chọn ngôn ngữ",
        genderLabel: "Giới tính của quý vị là gì?",
        genderOptions: ["Nam", "Nữ"],
        genderErrmsg: "Vui lòng chọn giới tính",
        labelEthnicity: "Quý vị thuộc dân tộc nào?",
        hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
        nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
        helpEthnicity:
          "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        raceLabel: "Quý vị thuộc chủng tộc nào?",
        raceHelp:
          "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        optionAIdisplay: "Người Mỹ bản xứ",
        optionAsiandisplay_VALUE: "Người Châu Á",
        optionAsiandisplay: "Người Châu Á",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
        optionwhitedisplay_VALUE: "Người da trắng",
        optionwhitedisplay: "Người da trắng",
        declineToAnswerLabel: "Từ chối trả lời",
        inlineHelpSSN:
          "Theo quy định của Liên Bang, những người không phải là công dân mà không xin SNAP thì không cần cung cấp thông tin về tình trạng công dân hay SSN của họ.",
        ebtCardSectionTitle: "Thẻ EBT",
        needEbtCard: "Bạn có cần thẻ EBT không?",
        inlineHelpEbtcard:
          "Lưu ý: Nếu bạn có thẻ EBT mang tên của mình từ hồ sơ trước đó, bạn có thể sử dụng lại thẻ đó nếu bạn được chấp thuận. Yêu cầu thẻ EBT sẽ ngay lập tức hủy các thẻ khác.",
      };
    case HI:
      return {
        title: "Konsènan mwen menm",
        header: "Konsènan mwen menm",
        labelFirstName: "Ki prenon w?",
        firstNameErrMsg: "Tanpri antre prenon w",
        firstNameErrMsgLength:
          "Tanpri antre prenon ki mwens pase oswa egal a 15 karaktè",
        middleName: "Ki dezyèm prenon w?",
        middleNameErrMsg: "Tanpri antre dezyèm prenon",
        middleNameErrMsgLength:
          "Tanpri antre dezyèm prenon ki mwens pase oswa egal a 15 karaktè",
        lastNamelabel: "Ki siyati w?",
        lastNameErrMsg: "Tanpri antre siyati w",
        lastNameErrMsgLength:
          "Tanpri antre siyati ki mwens pase oswa egal a 20 karaktè",
        labelSuffix: "Sifiks",
        suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
        dateOfBirthLabel: "Ki dat nesans ou?",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
        ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
        inputClearLabel: "M pa genyen youn",
        ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
        headerMyContactInfo: "Enfòmasyon kontak mwen",
        contactInformation1:
          "Pou soumèt yon aplikasyon SNAP an liy, ou dwe bay yon adrès postal ak nimewo telefòn. Si w gen nenpòt kesyon, tanpri rele Liy Asistans DHR a nan ",
        contactInformation2: "oswa ale nan biwo lokal ",
        contacInformationLink: "DHR w la.",
        contactInfo1:
          "Pou ou voye yon aplikasyon pou pwogram manje DHR a sou entènèt, ou ap bezwen yon adrès lapòs, e-mail, epi yon nimewo telefòn. Si ou pa gen enfòmasyon sa yo, ou kapab ale sou sit entènèt sa a ",
        contactInfo2: "",
        contacInfoLink: "Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "Èske fwaye w la sanzabri?",
        houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
        amountErrMsg: "Tanpri antre yon montan ki valid",
        optionErrMsg: "Tanpri chwazi yon opsyon anba a",
        houseLessOptions: ["Wi", "Non"],
        accommodationOptions: ["Wi", "Non"],
        addrQuestionlabel: "Ki adrès ou?",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your material Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "Tanpri antre yon kòd postal ki valid",
        alsoMailingLabel: "Èske se adrès postal ou a tou?",
        optionsSendMail: "Wi, voye lapòs la.",
        optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
        mailingQlabel: "Ki adrès postal ou?",
        zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
        masZipCodeErrmsg:
          "Aplikasyon SNAP sa a se pou rezidan Alabama. Si w ap viv nan Alabama, tanpri antre Kòd Postal ou. Si w pa abite nan Alabama, ou dwe aplike nan Eta w la.",
        citiErrmsg: "Tanpri antre yon vil ki valid",
        addressErrmsg: "Tanpri antre yon adrès ki valid",
        bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
        inlineHelp:
          "Tanpri antre yon nimewo telefòn si ou genyen youn. Sa pral ede nou trete aplikasyon SNAP ou a pi vit.",
        validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
        emailAddressLabel: "Ki adrès imel ou?",
        emailErrormsg: "Tanpri souple rantre yon email adrès ki valid.",
        titleEmergency: "Benefis SNAP nan Ijans",
        headerEmergency: "Benefis SNAP nan Ijans",
        emergencySnap:
          "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
        incomeMoneylabel:
          "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
        inlineHelpLabel:
          "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
        optionYesNo: ["Wi", "Non"],
        alertSelectIncome: "Tanpri chwazi revni ou peye",
        incomeLesslabel:
          "Èske revni mansyèl ou mwens pase $150 epi èske lajan labank ou se $100 oswa mwens?",
        monthlyIncomeoptions: ["Wi", "Non"],
        selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
        migrantBefore: "Èske w se yon ",
        migrantTrigger: "travayè migran",
        migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
        migrantHelp:
          "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
        migrantOptions: ["Wi", "Non"],
        migrantErrmsg: "Tanpri chwazi migran ou peye",
        headerMoreAbout: "Plis konsènan mwen menm",
        areUsBefore: "Èske w se yon ",
        areUsTrigger: "sitwayen Ameriken",
        areUsafter: "?",
        eligibleHelp:
          "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        chooseErrmsg: "Tanpri chwazi sitwayen",
        eligibleMemberHelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        eligibleOptions: ["Wi", "Non"],
        laguageBefore: "Ki lang ou ",
        LANGUAGES: {
          "Albanian": "sq",
          "American Sign Language User": "au",
          "Amharic": "am",
          "Arabic": "ar",
          "Armenian": "hy",
          "Khmer (Cambodian)": "km",
          "Cape Verdean Creole": "kea",
          "Chinese - Mandarin": "cmn",
          "Chinese": "zh-cn",
          "Chinese - Cantonese": "yue",
          "Croatian": "hr",
          "English": "en",
          "French": "fr",
          "Greek": "el",
          "Haitian Creole": "ht",
          "Italian": "it",
          "Laotian": "lo",
          "Nepali": "ne",
          "Polish": "pl",
          "Portuguese": "pt",
          "Russian": "ru",
          "Serbian Cyrillic": "sr",
          "Slovenian": "sl",
          "Somali": "so",
          "Spanish": "es",
          "Tagalog": "tl",
          "Vietnamese": "vi"
        },
        languageTrigger: "prefere pale ",
        languageAfter: "?",
        comfortLanHelp:
          " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
        languageErrMsg: "Tanpri chwazi ki lang",
        genderLabel: "Ki sèks ou?",
        genderOptions: ["Gason", "Fi"],
        genderErrmsg: "Tanpri chwazi sèks",
        labelEthnicity: "Ki etnisite ou?",
        helpEthnicity:
          "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        hisPanicOption: "Ispanik/Latino",
        nonhisPanicOption: "Non-Ispanik/Latino",
        raceLabel: "Ki ras ou?",
        raceHelp:
          "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        optionAIdisplay: "Ameriken Endyen",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Azyatik",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Nwa/Afriken Ameriken",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "moun Zile Pasifik",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "Blan",
        declineToAnswerLabel: "Refize reponn",
        inlineHelpSSN:
          "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
        ebtCardSectionTitle: "Kat EBT",
        needEbtCard: "Èske w bezwen yon kat EBT?",
        inlineHelpEbtcard:
          "Si ou gen yon kat EBT nan non ou, ou pa bezwen youn nouvo. Si w klike sou wi, ansyen kat ou a pral anile tousuit.",
      };
    default:
      return;
  } //house hold ending ending
}

//houseHoldMultilanguageLabels

export function houseHoldSectionMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        houseTitle: "My Household",
        houseHeader: "About My Household",
        howManyLabel: "How many people live in your household, including you?",
        snapHouseHelp:"If you plan to purchase and prepare more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not purchase and prepare meals with them.",
        tanfHouseHelp:"TANF Benefits: Include the children for whom you want to apply (must be living with you and be under the age of 18 or under age 19  if s/he is a full-time student in a secondary school or equivalent level of vocational or technical training. Secondary school may include public, private, church and home schools for grades 9 through 12.) and their brothers and sisters. Also include any other parent of the children in your home and your spouse if living with you.",
        comboHouseHelp:"If you plan to purchase and prepare more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not purchase and prepare meals with them.",  
        numOfPplText: "Enter number of people below.",
        numOfPplErrMsg: "Please enter number of household.",
        houseErrMsg: "Please enter number of household",
        houseMemberTitle: "Member ",
        houseMemberHeader: "Your first household member is ",
        houseFirstName: "What is this member's first name?",
        firstErrmsg: "Please enter the first name.",
        firstNameErrMsgLength:
          "Please enter first name less than or equal to 15 characters",
        houseMiddleLabel: "What is this member's middle name?",
        houseMiddleName: "Please enter middle name",
        middleNameErrMsgLength:
          "Please enter middle name less than or equal to 15 characters",
        houseLastNameLabel: "What is this member's last name?",
        membersRace: "What is this member’s race?",
        membersEthnicity: "What is this member’s ethnicity?",
        enterLastname: "Please enter the last name.",
        lastNameErrMsgLength:
          "Please enter last name  less than or equal to 20 characters",
        suffixLabel: "Suffix",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "What is this member's relationship to you?",
        spouseDisplay: "Spouse",
        motherDisplay: "Mother",
        faterDisplay: "Father",
        daughterDisplay: "Daughter",
        sonDisplay: "Son",
        brotherDisplay: "Brother",
        sisterDisplay: "Sister",
        grandMaDisplay: "Grandmother",
        grandFaDisplay: "Grandfather",
        grandDaugther: "Granddaughter",
        grandSon: "Grandson",
        otherDisplay: "Other",
        houseDobLabel: "What is this member's date of birth?",
        maritialStatusDteLabel: "What is this member's date ",
        houseDobAlertMsg: "Please enter a valid date of birth.",
        maritialStatusDteAlertMsg: "Please enter a valid married date",
        personGenLabel: "What is this member's gender?",
        genderOptios: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        citiBeforeQLabel: "Is this Person a ",
        citiTriggerQLabel: "US citizen",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'To be eligible for SNAP, a person must be a U.S. citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        citizenHelpComboApplication:
          " 'To be eligible for SNAP, a person must be a U.S. citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        tanfCitizenHelp:
          "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        comboCitizenHelp:
          "To be eligible for SNAP/TANF, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        citizenInhelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        citizenOptions: ["Yes", "No"],
        ssnQLabel: "What is this member's Social Security Number?",
        ssnDntLabel: "Doesn't have one",
        ssnErrLabel: "Please enter a valid Social Security Number.",
        hholdHeader: "Household Details",
        disabilityQLabel:
          "Does anyone in the household have a physical or mental ",
        disabilityQlabel2: "",
        disabilityQTriggerLabel: "disability",
        disabilityHelp:
          "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
        disablilityCler: "No one",
        inlineHelpSSN:
          "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
        immigrationStatus:
          "Does this person hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        yesnoOptions: ["Yes", "No"],
      };
    case ES: //spanish
      return {
        houseTitle: "Mi núcleo familiar",
        houseHeader: "Sobre mi núcleo familiar",
        howManyLabel:
          "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
        numOfPplText: "Ingrese el número de personas a continuación.",
        numOfPplErrMsg: "Por favor ingrese el número de hogar.",
        snapHouseHelp:"Beneficios de SNAP: si planea comprar y preparar comidas con ellos más de 2/3 de sus comidas con otras personas, deben estar en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos. Incluya los niños para quienes desea presentar la solicitud y sus hermanos y hermanas.",
        tanfHouseHelp:"Beneficios TANF: incluya los niños para quienes desea solicitar (deben vivir con usted y ser menores de 18 años o menores de 19 años si es estudiante de tiempo completo en una escuela secundaria o nivel equivalente de educación vocacional o técnica). La escuela secundaria puede incluir escuelas públicas, privadas, religiosas y en el hogar para los grados 9 a 12.) y sus hermanos y hermanas. Incluya también a cualquier otro padre de los niños en su hogar y a su cónyuge si vive con usted.",
        comboHouseHelp: "Si planea comprar y preparar comidas con ellos más de 2/3 de sus comidas con otras personas, deben estar en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos. Incluya los niños para quienes desea presentar la solicitud y sus hermanos y hermanas.",
        houseHelp:
          "Si compra y prepara más de 2/3 de sus comidas con otras personas, deben ser en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos.",
        houseErrMsg: "Por favor, introduzca el número de hogar",
        houseMemberTitle: "miembro ",
        houseMemberHeader: "Su primer miembro del hogar es ",
        houseFirstName: "¿Cuál es el nombre de esta persona?",
        firstErrmsg: "Por favor, introduzca el primer nombre.",
        firstNameErrMsgLength:
          "Por favor, ingrese el primer nombre con 15 caracteres máximo  ",
        houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
        houseMiddleName: "Por favor ingrese un segundo nombre",
        middleNameErrMsgLength:
          "Por favor, ingrese el segundo nombre con 15 caracteres máximo",
        houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
        enterLastname: "Por favor ingrese el apellido.",
        lastNameErrMsgLength:
          "Por favor, ingrese los apellidos con 20 caracteres máximo",
        suffixLabel: "Sufijo",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "¿Cuál es la relación de esta persona con usted?",
        spouseDisplay: "Cónyuge",
        motherDisplay: "Madre",
        faterDisplay: "Padre",
        daughterDisplay: "Hija",
        sonDisplay: "Hijo",
        brotherDisplay: "Hermano",
        sisterDisplay: "Hermana",
        grandMaDisplay: "Abuela",
        grandFaDisplay: "Abuelo",
        grandDaugther: "Nieta",
        grandSon: "Nieto",
        otherDisplay: "Otro",
        houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        houseDobAlertMsg: "Por favor ingresa una fecha de nacimiento valida.",
        personGenLabel: "¿Cuál es el sexo de esta persona?",
        genderOptios: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        citiBeforeQLabel: "¿Es esta persona una ",
        citiTriggerQLabel: "Ciudadana estadounidense",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
          citizenHelpComboApplication:
          " 'Para ser elegible para SNAP, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede solicitar para miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        tanfCitizenHelp:
          "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        comboCitizenHelp:
          "Para ser elegible para SNAP/TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
          citizenInhelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        citizenOptions: ["Sí", "No"],
        ssnQLabel: "¿Cuál es el número de seguro social de esta persona?",
        ssnDntLabel: "No tiene uno",
        ssnErrLabel: "Por favor ingrese un número de seguro social válido.",
        hholdHeader: "Detalles de mi núcleo familiar",
        disabilityQLabel: "¿Alguna persona tiene una ",
        disabilityQlabel2: " mental o física ",
        disabilityQTriggerLabel: "discapacidad",
        disabilityHelp:
          "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
        disablilityCler: "Ninguna",
        inlineHelpSSN:
          "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan dar su SSN o estado de ciudadanía.",
        immigrationStatus:
          "¿Esta persona tiene un estatus migratorio satisfactorio?",
        immigrationOptions: ["Sí", "No"],
        yesnoOptions: ["Sí", "No"],
        membersRace: "¿Cuál es la raza de este miembro?",
        membersEthnicity: "¿Cuál es el origen étnico de este miembro?",
      };
    case PT: //portugeesh
      return {
        houseTitle: "Minha família",
        houseHeader: "Sobre a minha família",
        howManyLabel: "Quantas pessoas compõem a sua família, incluindo você?",
        houseHelp:
          "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
        houseErrMsg: "Por favor, escolha o número de casa",
        houseMemberTitle: "Membro ",
        houseMemberHeader: "Seu primeiro membro da família é ",
        houseFirstName: "Qual é o primeiro nome dessa pessoa?",
        firstErrmsg: "Por favor digite o primeiro nome",
        firstNameErrMsgLength: "Digite o seu nome com até 15 caracteres",
        houseMiddleLabel: "Qual é o nome do meio dessa pessoa?",
        houseMiddleName: "Digite o nome do meio",
        middleNameErrMsgLength:
          "Digite o seu nome do meio com até 15 caracteres",
        houseLastNameLabel: "Qual é o último nome dessa pessoa?",
        enterLastname: "Digite o sobrenome",
        lastNameErrMsgLength: "Digite o seu sobrenome com até 20 caracteres",
        suffixLabel: "Sufixo",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel:
          "Qual é o grau de relacionamento dessa pessoa com você?",
        spouseDisplay: "Cônjuge",
        motherDisplay: "Mãe",
        faterDisplay: "Pai",
        daughterDisplay: "Filha",
        sonDisplay: "Filho",
        brotherDisplay: "Irmão",
        sisterDisplay: "Irmã",
        grandMaDisplay: "Avó",
        grandFaDisplay: "Avô",
        grandDaugther: "Neta",
        grandSon: "Neto",
        otherDisplay: "Outros",
        houseDobLabel: "Qual é a data de nascimento dessa pessoa?",
        houseDobAlertMsg: "Por favor, insira uma data de nascimento válida",
        personGenLabel: "Qual é o sexo dessa pessoa?",
        genderOptios: ["Masculino", "Feminino"],
        citiBeforeQLabel: "Essa pessoa tem ",
        citiTriggerQLabel: "cidadania americana",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        citizenInhelp:
          "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
        citizenOptions: ["Sim", "Não"],
        ssnQLabel: "Qual é o número do seguro social dessa pessoa?",
        ssnDntLabel: "Não tem um",
        ssnErrLabel: "Digite um Número de Segurança Social válido",
        hholdHeader: "Informações da minha família",
        disabilityQLabel: "Alguém sofre de algum tipo de deficiência ",
        disabilityQlabel2: " física ou mental ",
        disabilityQTriggerLabel: "deficiência",
        disabilityHelp:
          "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
        disablilityCler: "Ninguém",
        inlineHelpSSN:
          "De acordo com as leis federais, não cidadãos que não estiverem solicitando o SNAP não precisam informar o SSN ou o status de cidadania.",
        immigrationStatus:
          "Does this person hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        yesnoOptions: ["Yes", "No"],
      };
    case ZH: //China
      return {
        houseTitle: "我的家庭",
        houseHeader: "关于我的家庭",
        howManyLabel: "包括您自己在内，您家庭中有几人？",
        houseHelp:
          "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭",
        houseErrMsg: "請選擇家庭數量",
        houseMemberTitle: "會員 ",
        houseMemberHeader: "你的第一個家庭成員是 ",
        houseFirstName: "此人的名字?",
        firstErrmsg: "請輸入名字",
        firstNameErrMsgLength: "请输入名字，不超过 15 个字符",
        houseMiddleLabel: "此人的中间名?",
        houseMiddleName: "請輸入中間名",
        middleNameErrMsgLength: "请输入中间名，不超过 15 个字符",
        houseLastNameLabel: "此人的姓氏?",
        lastNameErrMsgLength: "请输入姓氏，不超过 20 个字符",
        enterLastname: "請輸入姓氏",
        suffixLabel: "后缀",
        SuffixOptions: ["一世", "II", "III", "IV", "V", "小", "高級"],
        relationQlabel: "此人与您的关系?",
        spouseDisplay: "配偶",
        motherDisplay: "母亲",
        faterDisplay: "父亲",
        daughterDisplay: "女儿 ",
        sonDisplay: "儿子 ",
        brotherDisplay: "兄弟",
        sisterDisplay: "姐妹",
        grandMaDisplay: "祖母",
        grandFaDisplay: "祖父",
        grandDaugther: "（外）孙女",
        grandSon: "（外）孙子",
        otherDisplay: "其他",
        houseDobLabel: "此人的出生日期? ",
        houseDobAlertMsg: "請輸入有效的出生日期",
        personGenLabel: "此人的性别?",
        genderOptios: ["男", "女"],
        citiBeforeQLabel: "此人是否是 ",
        citiTriggerQLabel: "美国公民",
        citiAfterQLabel: "?",
        citizenHelp:
          " '要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DHR将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        citizenInhelp: "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
        citizenOptions: ["是", "否"],
        ssnQLabel: "此人的社保号?",
        ssnDntLabel: "沒有一個",
        ssnErrLabel: "請輸入有效的社會安全號碼",
        hholdHeader: "家庭详细情况",
        disabilityQLabel: "是否有人患有身体或精神 ",
        disabilityQlabel2: "",
        disabilityQTriggerLabel: "残疾",
        disabilityHelp:
          "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾",
        disablilityCler: "无人",
        inlineHelpSSN:
          "依据联邦法规，未申请 SNAP 的非公明身份人士无需提供其 SSN 或公民身份状态。",
        immigrationStatus:
          "Does this person hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        yesnoOptions: ["Yes", "No"],
      };
    case VI: //Vietnam
      return {
        houseTitle: "Hộ gia đình của tôi",
        houseHeader: "Thông tin về Hộ gia đình của tôi",
        howManyLabel:
          "Có bao nhiêu người sống trong hộ gia đình của quý vị, kể cả quý vị?",
        houseHelp:
          "Nếu quý vị mua và nấu hơn 2/3 bữa ăn của quý vị với những người khác, họ phải ở trong hộ gia đình của quý vị. Nếu vợ / chồng hoặc con của quý vị dưới 22 tuổi sống chung với quý vị, họ phải ở trong hộ gia đình của quý vị ngay cả khi quý vị không mua và nấu bữa ăn cùng với họ",
        houseErrMsg: "Vui lòng chọn số hộ gia đình",
        houseMemberTitle: "thành viên ",
        houseMemberHeader: "Thành viên gia đình đầu tiên của bạn là ",
        houseFirstName: "Tên của người này là gì?",
        firstErrmsg: "Vui lòng nhập tên",
        firstNameErrMsgLength:
          "Vui lòng nhập vào tên gọi có ít hơn hoặc bằng 15 ký tự",
        houseMiddleLabel: "Tên lót của người này là gì?",
        houseMiddleName: "Vui lòng nhập tên đệm",
        middleNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 15 ký tự",
        houseLastNameLabel: "Họ của người này là gì?",
        lastNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 20 ký tự",
        enterLastname: "Vui lòng nhập họ",
        suffixLabel: "Tước hiệu",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "Mối quan hệ của người này với quý vị là gì?",
        spouseDisplay: "Vợ / chồng",
        motherDisplay: "Mẹ",
        faterDisplay: "Cha",
        daughterDisplay: "Con gái",
        sonDisplay: "Con trai",
        brotherDisplay: "Anh, em trai",
        sisterDisplay: "Chị, em gái",
        grandMaDisplay: "Bà ",
        grandFaDisplay: "Ông ",
        grandDaugther: "Cháu gái",
        grandSon: "Cháu trai (nội, ngoại)",
        otherDisplay: "Quan hệ khác",
        houseDobLabel: "Ngày sinh của người là gì?",
        houseDobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
        personGenLabel: "Giới tính của người này là gì?",
        genderOptios: ["Nam", "Nữ"],
        citiBeforeQLabel: "Người này có phải là ",
        citiTriggerQLabel: "Công dân Hoa Kỳ không",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        citizenInhelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        citizenOptions: ["Có", "Không"],
        ssnQLabel: "Số An sinh Xã hội của người này là gì?",
        ssnDntLabel: "Không có",
        ssnErrLabel: "Vui lòng nhập Số an sinh xã hội hợp lệ",
        hholdHeader: "Chi tiết về hộ gia đình",
        disabilityQLabel: "Có ai bị ",
        disabilityQlabel2: " về thể chất hoặc tinh thần không ",
        disabilityQTriggerLabel: "khuyết tật",
        disabilityHelp:
          "Một người khuyết tật phải được liên bang xác nhận để được xem xét hưởng trợ cấp SNAP. Ví dụ, người người nhận SSI là người khuyết tật được liên bang xác nhận",
        disablilityCler: "Không có ai",
        inlineHelpSSN:
          "Theo quy định của Liên Bang, những người không phải là công dân mà không xin SNAP thì không cần cung cấp thông tin về tình trạng công dân hay SSN của họ.",
        immigrationStatus:
          "Does this person hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        yesnoOptions: ["Yes", "No"],
      };
    case HI:
      return {
        houseTitle: "Fwaye m",
        houseHeader: "Konsènan Kay mwen an",
        howManyLabel: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
        houseHelp:
          "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
        houseErrMsg: "Tanpri chwazi gwosè fwaye a",
        houseMemberTitle: "Manm",
        houseMemberHeader: "Premye manm nan fwaye w la se ",
        houseFirstName: "Ki prenon moun sa a?",
        firstErrmsg: "Tanpri antre prenon",
        firstNameErrMsgLength:
          "Tanpri antre prenon ki mwens pase oswa egal a 15 karaktè",
        houseMiddleLabel: "Ki dezyèm prenon moun sa a?",
        houseMiddleName: "Tanpri antre dezyèm prenon",
        middleNameErrMsgLength:
          "Tanpri antre dezyèm prenon ki mwens pase oswa egal a 15 karaktè",
        houseLastNameLabel: "Ki siyati moun sa a?",
        enterLastname: "Tanpri antre siyati",
        lastNameErrMsgLength:
          "Tanpri antre siyati ki mwens pase oswa egal a 20 karaktè",
        suffixLabel: "Sifiks",
        SuffixOptions: ["I", "II", "III", "4", "5", "Fis", "Papa"],
        relationQlabel: "Ki relasyon moun sa genyen ak ou?",
        spouseDisplay: "Konjwen",
        motherDisplay: "Manman",
        faterDisplay: "Papa",
        daughterDisplay: "Pitit fi",
        sonDisplay: "Pitit gason",
        brotherDisplay: "Frè",
        sisterDisplay: "Sè",
        grandMaDisplay: "Grann",
        grandFaDisplay: "Granpè",
        grandDaugther: "Pitit pitit fi",
        grandSon: "Pitit pitit gason",
        otherDisplay: "Lòt",
        houseDobLabel: "Ki dat nesans moun sa a?",
        houseDobAlertMsg: "Tanpri antre yon dat nesans ki valid",
        personGenLabel: "Ki sèks moun sa a?",
        genderOptios: ["Gason", "Fi"],
        citiBeforeQLabel: "Èske Moun sa a se yon ",
        citiTriggerQLabel: "Sitwayen Ameriken",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        citizenInhelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        citizenOptions: ["Wi", "Non"],
        ssnQLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
        ssnDntLabel: "Li pa gen youn",
        ssnErrLabel: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
        hholdHeader: "Detay Fwaye",
        disabilityQLabel: "Èske nenpòt moun nan fwaye a gen yon ",
        disabilityQlabel2: " fizik oswa mantal",
        disabilityQTriggerLabel: "andikap",
        disabilityHelp:
          "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
        disablilityCler: "Pesonn",
        inlineHelpSSN:
          "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
        immigrationStatus:
          "Does this person hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        yesnoOptions: ["Yes", "No"],
      };
    default:
      return;
  } //house hold ending
}

export function incomeSectionMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        costprovidercarelabel: "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
        Transportationpaylabel: "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
        Transportationlabel: "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
        householddrivelabel: "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
        householddrivelabel: "How often does your household drive Marty Smith (2.Jun.1994) to the provider? To and from a provider should be counted as two trips.",
        incomeTitle: "Income",
        incomeHeader: "About Your Household’s Income",
        resourceTitle: "Resources",
        resourceHeader: "About Your Household’s Resources",
        aboutResourceBefore: "Please provide information on the amount of available cash you and your household have.",
        resourceHelp:
          "Select all that apply.",
        aboutIncomeBefore: "Does anyone in the household receive any ",
        aboutIncomeTrigger: "income or benefits",
        incomeHelp:
          "Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.",
        incomeHelpCombo:
          "Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.",
        clearOption: "No one",
        combineIncomeoptions: [
          "TANF",
          "Self-Employment",
          "Job Income",
          "Social Security (SSA / RSDI)",
          "Supplemental Security Income",
          "Veteran's Benefits",
          "Unemployment Compensation",
          "Workman's Compensation",
          "Interest Dividends from Stock and Bonds",
          "Military Allotment",
          "Pension or Retirement Income",
          "Railroad Retirement",
          "HUD Utility Assistance",
          "Income from Land Rental or Rental Property",
          "Child Support",
          "Alimony",
          "Contributions from Friends or Relatives",
          "Roomer Income",
          "Boarder Income",
          "Work Study",
          "Other",
        ],
        wageIncomeBeforeLabel: "What is the ",
        wageIncomeTriggerLabel: "gross income",
        tanfTargetValue: "TANF",
        interestTargetValue: "Interest Dividends from Stock and Bonds",
        pensionTargetValue: "Pension or Retirement Income",
        jobTargetValue: "Job Income",
        socialTargetValue: "Social Security (SSA / RSDI)",
        veteranTargetValue: "Veteran's Benefits",
        militaryTargetValue: "Military Allotment",
        railroadTargetValue: "Railroad Retirement",
        hudUtilityTargetValue: "HUD Utility Assistance",
        incomeLandRentalTargetValue:
          "Income from Land Rental or Rental Property",
        alimonyTargetValue: "Alimony",
        cotributionsTargetValue: "Contributions from Friends or Relatives",
        roomerTargetValue: "Roomer Income",
        boaderTargetValue: "Boader Income",
        workmanTargetValue: "Workman's Compensation",
        wagesTargetValue: "Wages",
        selfemeploymentTargetValue: "Self-Employment",
        workStudyTargetValue: "Work Study",
        rsdiTargetValue:
          "RSDI (Retirement, Survivors, and Disability Insurance)",
        SSITargetValue: "Supplemental Security Income",
        unEmploymentTargetValue: "Unemployment Compensation",
        childSuppTargetValue: "Child Support",
        veteranBenefitsTargetValue: "Veterans Benefits",
        rentalIncomeTargetValue: "Rental Income",
        workerCompTargetValue: "Workers Compensation",
        otherTargetValue: "Other",
        noneTargetValue: "None",

        mortageInlineHelpText:
          "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
        grossIncomeHelp:
          "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
        unitsPerLabel: "per",
        unitsOptions: ["Annual",
          "Bimonthly(every two months)",
          "Biweekly(every two weeks)",
          "Monthly",
          "Quarterly",
          "Semi-annual(Twice a year)",
          "Semi-monthly(Twice a month)",
          "Weekly"],
        addAnotherButtonLabel: "+ Add Another",
        addAdditionalButtonLabel: "+ Add Additional",
        labelOtherwage: "+ Add Another Wage",
        adsSelfEmployee: "+ Add Another Self-Employment",
        condWorkStudy: "Work Study",
        workStudyLabel: "+ Add Another Work Study",
        anotherSSILabel: "+ Add Another SSI",
        addRDILabel: "+ Add Another RSDI",
        unEmpLabel: "+ Add Another Unemployment",
        childSuppLabel: "Child Support",
        anotherChildsupport: "+ Add Another Child Support",
        pensionLabel: "Pension",
        anotherPensionLabel: "+ Add Another Pension",
        workerCompensationLabel: "+ Add Another Worker's Compensation",
        veteranBenefit: "Veteran's Benefits",
        addAnotherVBenefitLabel: "+ Add Another Veteran's Benefits",
        rentIncomeLabel: "Rental Income",
        repeatQuestionLabel: "+ Add Another Rental Income",
        repeatincomeQuestionLabel: "+ Add Another Rental Hours",
        unitsRentalOptions: ["Month", "Week"],
        questionButtonLabel: "+ Add Another Other",
        incomeSummaryTitle: "Income Summary",
        incomeSummaryHeader: "Income Summary",
        expenseTitle: "Expenses",
        almostLabel: "Expenses",
        houseUtlilityLabel: "Shelter & Utility Expenses",
        expenseHeader: "Tell Us About Your Shelter Expenses",
        expenseSubHeader:
          "Please include all the expenses you are responsible for paying even if you are behind or not able to pay them.",
        houseHoldQLabel: "Is your household responsible for any utilities?",
        utilityQOptions: [
          "Heat (oil, gas, electricity or propane, etc.)",
          "Electricity for an air conditioner",
          "A fee to use an air conditioner",
          "Electricity and/or gas",
          "Phone or cell phone service",
          "Water and Sewage",
          "Garbage",
          "Energy Assistance (LIHEAP)",
          "I do not pay for any utility expenses"
        ],
        noUtilityQOptionSelected:"I do not pay for any utility expenses",
        utilitiesClearOption: "None",
        houseQLabel: "What type of shelter expense does your household have?",
        interimShelterQlabel:
          "What type(s) of shelter expenses your household have?",
        interimShelterLabel: {
            textBefore: "What type(s) of ",
            triggerText: "shelter expenses",
            textAfter: " does your household has?",
            helpText: "",
        },
        interimUtilityQlabel:
          "What type(s) of utility expenses your household have?",
        interimUtilityLabel: {
            textBefore: "What type(s) of ",
            triggerText: "utility expenses",
            textAfter: " your household pay?",
            helpText: "",
        },
        houseQOptions: [
          "Rent",
          "Mortgage",
          "Property taxes",
          "Home insurance",
          "Condo fee",
          "Property insurance",
          "Second Mortgage",
          "Homeless Expenses",
          "Home Equity Loan",
          "Homeowner Association Fees",
          "Other",
          "I do not pay for any shelter expenses"
        ],
        houseRentQLabel: "How much is your household's rent?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MORTGAGE",
        propertyTaxTargetValue: "PROPERTY_TAX",
        homeInsurTargetValue: "HOME_INSRANCE",
        condoFeeTargetValue: "CONDO_FEE",

        unitBetween: "per",
        houseUnitOptions: ["Week", "Month", "Year"],
        mortageQLabel: "How much is your household's mortgage?",
        mortageunitBetween: "per",
        mortageQoptions: ["Week", "Month", "Year"],
        mortageProptaxLabel: "How much is your household's property taxes?",
        mortagepropBetween: "per",
        mortagepropoptions: ["Week", "Month", "Year"],
        insuranceCostQLabel:
          "How much is your household's home insurance costs?",
        insuranceCostpropBetween: "per",
        insuranceCostoptions: ["Week", "Month", "Year"],
        insuranceClearOption: "No. I don't need to pay for this.",
        condofeeQLabel: "How much is your household's condo fee?",
        condofeepropBetween: "per",
        condofeeCostoptions: ["Week", "Month", "Year"],
        condofeeClearOption: "No. I don't need to pay for this.",
        propTaxQLabel: "Does your household have property taxes?",
        propTaxBetween: "per",
        propTaxOptions: ["Week", "Month", "Year"],
        propTaxClearOption: "No. I don't need to pay for this.",
        homeInsuranceQLabel: "Does your household have home insurance costs?",
        homeInsuranceBetween: "per",
        homeInsuranceUnits: ["Week", "Month", "Year"],
        homeInsuranceClearOption: "No. I don't need to pay for this.",
        condoFeeQLabel: "Does your household have a condo fee?",
        condoFeeBeweenLabel: "per",
        condoFeeUnits: ["Week", "Month", "Year"],
        condoFeeClearOption: "No. I don't need to pay for this.",

        //new labels:
        anyUtilQcost: "Does your household have any utility expenses?",
        chooseHoldErrmsg: "Please choose household utilities",
        houseCostErrmsg: "Please choose housing costs",
        rentErrMsg: "Please enter rent",
        anotherRentLabel: "+ Add Another Rent",
        mortageErrLabel: "Please enter mortgage",
        propTaxLabel: "Please enter property taxes",
        propTaxButtonLabel: "+ Add Another Property Taxes",
        homeInsurbutton: "+ Add Another Home Insurance",
        homeInsurErrmsg: "Please enter home insurance",
        condoButtonLabel: "+ Add Another Condo Fee",
        condoFeeErrmsg: "Please enter condo fee",
        addMortageButtonLabel: "+ Add Another Mortgage",
        childTitle: "Child or Adult Dependent Care Expenses",
        childCostHeader:
          "Tell Us About Your Child or Adult Dependent Care Expenses",
        childCostSubHeader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        childExpenseSubHeader:
          "Please include all the expenses you are responsible for paying even if you are behind or not able to pay them.",
        careGiveObjbefore: `Does anyone in your household pay for someone to babysit or care for a child or disabled adult so that a household member can go to work, look for work, go to school or training? <br/><br/> This includes expenses to `,
        careGiveObjtrigger: "transport",
        careGiveObjAfter: " the dependent(s) to and/or from a care provider.",
        careGiveHelp:
          "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
        outHHNameLabel:
          "If you pay the dependent expenses for someone outside the household, enter their names.",
        outHHNameErrorMsg: "Please enter your outside household name.",
        questionClearOption: "No one",
        kidsandadultSubheader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        costBetween: "per",
        costBetween1: " ",
        weekMonthOptions: ["Month", "Week"],
        costOptions: ["Week", "Month", "Year"],
        yesnoOptions: ["Yes", "No"],
        targetValueYes: "Yes",
        noofAppointment: "Please enter number of appointments",
        nameProvider: "What is the name of the provider?",
        addressProvider: "What is the address of the provider?",
        childSupportTripLabel:
          "How often does your household drive Marty Smith (2.Jun.1994) to the provider? To and from a provider should be counted as two trips.",
        freqTripoptions: ["Week", "Month", "Year"],
        unitMonthTargetValue: { unit: "Monthly" },
        childSupTitle: "Child Support Expenses",
        supportCostHeader: "Child Support Expenses",
        childSupportQlegalbefore:
          "Does anyone in the household have a legal obligation (court order) to pay ",
        forWhomPaid: "For whom is the child support paid?",
        toWhomPaid: "To whom is the child support paid?",
        childSupportQlegaltrigger: "child support",
        childSupportQlegalAfter: " to a child not living with you?",
        childSupportHelp:
          "Child support paid can be counted as an expense when it is legally required, and payments are being made. This includes payments for arrears.",
        medicalTitle: "Medical Expenses",
        medicalInlineSeethisFull: "",
        medicalInlineEligibleMedical: "",
        medicalHeader: "Tell Us About Your Medical Expenses",
        medicalSubHeader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        medicalExpLabel:
          "Is anyone listed below billed or paying out-of-pocket medical expenses?",
        medicalExpBefore:
          `Is anyone listed below billed or paying out-of-pocket medical expenses?`,
        medicalExpTrigger: " medical expenses.",
        medicalExpAfter: 'See this full list of eligible ',
        medicalExphelp: `<ul><li> Prescription drugs (a printout from the Pharmacy for the past two full months or longer)</li>
            <li>Hospital bill(s), including nursing home care (any current outstanding bills)</li>
            <li>Doctor, dentist, or other health care professional visit(s) and doctor’s statement of how
            often you are expected to see him/her and how much you pay for each visit</li>
            <li>Over-the-counter medications prescribed by a doctor (statement from doctor, 2 months of
              receipts and how often paid)</li>
              <li>Medicare premium - statement from SSA stating the amount withheld from monthly benefit</li>
              <li>Hospital insurance premium (current statement/coupon from provider with your name on
              the information)</li>
              <li>Insurance for prescription drug coverage (current statement/coupon from provider with your
              name on the information)</li>
              <li>Transportation expenses for travel to doctors, hospitals, drug stores (number of trips each
                month verified by your doctor and a statement from the person you pay, verifying the amount
                charged for transportation. If you drive, the number of miles you travel one way)</li>
              <li>Medical supplies or equipment (hearing aids, wheelchairs, artificial limbs, eyeglasses,
                contact lens, dentures, etc.)</li>
              <li>Attendant care or homemaker services (verification from the doctor regarding the need for
                these services and a statement from the person providing the service to verify cost)</li>
              <li>Service animal (includes food, veterinary care and other related costs</li>
              <li>Other medical related expenses</li>
                                

          </ul>`,
        medicalInhelp: "See this full list of",
        medicalInhelpEligible: "eligible medical expenses",
        medicalAddress:
          "What is the address of the medical appointment or pharmacy?",
        zipErrmsg: "Please enter a valid Zip code.",
        signSubmitLabel: "Sign & Submit",
        signSubmitHeader: "Sign & Submit",
        signSubmitSub:
          "You completed the application questions. To submit the application, read and sign.",
        tanfSignSubmitSub:
          "You completed the application questions. To submit the application, read the Notice of Rights, Responsibilities and Penalties section,  agree to the terms, and sign.",
        subSectionLabel: "Submitted",
        submitLabelFinal: "Your SNAP Application Has Been Submitted!",
        tanfSubmitLabelFinal: "Your TANF Application Has Been Submitted!",
        comboSubmitLabelFinal:
          "Your SNAP & TANF Applications Have Been Submitted!",
        tellusAbout: "Tell Us About Your Child Support Expenses",
        moneyYougettitle:
          "This includes money you may get from a job, the government, or other sources.",
        tripCountErrMsg: "Please enter valid trip count",
        tripCountErrMsgLen:
          "Please enter less than/equal to 6 characters for trip count",
        tripCostErrMsgLen:
          "Please enter less than/equal to 6 characters for trip cost",
        optionErrMsg: "Please select an option below.",
        amountErrMsg: "Please enter a valid amount.",
        medicalExpenseType:
          "What type of medical expenses do you have? (Select all that apply)",
        doctorOrDentistOptions: "Doctor or Dentist",
        doctorOrDentist:
          "What is the amount namePlaceholder spends on Doctor or Dentist?",
        hospitalOrNursingHomeOptions: "Hospital or Nursing Home",
        hospitalOrNursingHome:
          "What is the amount namePlaceholder spends on Hospital or Nursing Home?",
        prescriptionDrugsOptions: "Prescription Drugs ",
        prescriptionDrugs:
          "What is the amount namePlaceholder spends on Prescription Drugs?",
        overTheCounterMedicineOptions: "Over the Counter Medicine",
        overTheCounterMedicine:
          "What is the amount namePlaceholder spends on Over the Counter Medicine?",
        healthInsurancePremiumsOptions: "Health Insurance Premiums",
        healthInsurancePremiums:
          "What is the amount namePlaceholder spends on Health Insurance Premiums?",
        medicalSuppliesOptions: " Medical Supplies",
        medicalSupplies:
          "What is the amount namePlaceholder spends on Medical Supplies?",
        medicaidCostSharingOptions: "Medicaid Cost-Sharing",
        medicaidCostSharing:
          "What is the amount namePlaceholder spends on Medicaid Cost-Sharing?",
        denturesOrHearingAidsOptions: "Dentures or Hearing Aids",
        denturesOrHearingAids:
          "What is the amount namePlaceholder spends on Dentures or Hearing Aids?",
        prescriptionEyeGlasesOptions: "Prescription Eye Glasses",
        prescriptionEyeGlases:
          "What is the amount namePlaceholder spends on Prescription Eye Glasses?",
        transportationOrLoadingForMedicalServicesOptions:
          "Transportation or Loading for Medical Services",
        transportationOrLoadingForMedicalServices:
          "What is the amount namePlaceholder spends on Transportation or Loading for Medical Services?",
        HudHomeHealthAideOptions: "HUD Home Health Aide or Child Care",
        HudHomeHealthAide:
          "What is the amount namePlaceholder spends on HUD Home Health Aide or Child Care?",
        serviceAnimalOptions: "Service Animal",
        serviceAnimal:
          "What is the amount namePlaceholder spends on Service Animal?",
        otherMedicalExpenseOptions: "Other",
        otherMedicalExpense:
          "What is the amount namePlaceholder spends on Other?",
        prepareInterview: "Please be prepared to discuss and verify this information in your interview.",
        medicalAppointmentsOrPharmacy:
          "Does namePlaceholder drive to medical appointments or the pharmacy?",
        howManyMedicalAppointmentsOrPharmacy:
          "How many addresses, does namePlaceholder to medical appointments or the pharmacy?",
        tellUsPocketMedicalExpense:
          "Tell us about any out-of-pocket medical expenses.",
        beSureLegally:
          "Be sure to tell your worker if you are legally responsible for any medical expenses for an elderly or disabled person who was a former member of your household.",
        // fulllist: "See full of eligible medical expenses.",
        fulllist: "",
        allMedicalExpenseVerified: "All medical expenses have to be verified.",
        verfiedExpenseMay:
          "Verified expenses may increase the amount of your benefits.",
        acceptableForms:
          "Your worker will discuss with you acceptable forms of verification.",
        obtainVerificationNeeded:
          "We will help you obtain verification when needed.",
        fulllistEligible:"See this full list of eligible",
        summaryLabel: "Summary",
      };
    case ES: //SPANISH
      return {
        incomeTitle: "Ingreso",
        resourceTitle: "Recursos",
        resourceHeader: "Acerca de los recursos de su hogar",
        aboutResourceBefore: "Proporcione información sobre la cantidad de efectivo disponible que usted y su hogar tienen.",
        resourceHelp:
          "Seleccione todas las que correspondan.",
        householddrivelabel: "¿Con qué frecuencia su hogar lleva a Marty Smith (2 de junio de 1994) al proveedor? Hacia y desde un proveedor deben contarse como dos viajes.",
        costprovidercarelabel: "¿Cuál es el costo de la atención del proveedor para Marty Smith (2 de junio de 1994)?",
        Transportationpaylabel: "¿John Doe (8 de agosto de 1967) paga el transporte de Marty Smith (2 de junio de 1994)?",
        Transportationlabel: "¿Cuánto paga John Doe (8 de agosto de 1967) por el transporte de Marty Smith (2 de junio de 1994)?",
        householddrivelabel: "¿Alguien en su hogar lleva a Marty Smith (2 de junio de 1994) hacia y/o desde el proveedor?",
        incomeHeader: "Sobre el ingreso de su núcleo familiar",
        aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
        aboutIncomeTrigger: "ingresos o beneficios",
        incomeHelpCombo:
        "Los ingresos son dinero que recibes por trabajar. Los beneficios y contribuciones son dinero que recibe de una fuente distinta al empleo.",
        incomeHelp:
          "Los ingresos son dinero que recibes por trabajar. Los beneficios y contribuciones son dinero que recibe de una fuente distinta al empleo.",
        clearOption: "Ninguna",
        combineIncomeoptions: [
          "TANF",
          "Autoempleo",
          "Ingresos laborales",
          "Seguridad Social (SSA/RSDI)",
          "Seguridad de Ingreso Suplementario",
          "Beneficios para veteranos",
          "Compensación por desempleo",
          "Compensación laboral",
          "Dividendos de intereses de acciones y bonos",
          "Asignación militar",
          "Ingresos de Pensión o Jubilación",
          "Júbilación ferroviaria",
          "Asistencia de servicios públicos de HUD",
          "Ingresos por arrendamiento de terrenos o propiedades en alquiler",
          "Manutención de los hijos",
          "Pensión alimentaria",
          "Aportes de Amigos o Familiares",
          "Ingresos del inquilino",
          "Ingresos fronterizos",
          "Estudio y trabajo",
          "Otro",
        ],
        wageIncomeBeforeLabel: "Cuál es el ",
        wageIncomeTriggerLabel: "ingresos brutos",
        tanfTargetValue: "TANF",
        interestTargetValue: "Dividendos de intereses de acciones y bonos",
        pensionTargetValue: "Ingresos de pensión o jubilación",
        jobTargetValue: "Ingresos laborales",
        socialTargetValue: "Seguridad Social (SSA/RSDI)",
        veteranTargetValue: "Beneficios para veteranos",
        militaryTargetValue: "Asignación militar",
        railroadTargetValue: "Jubilación ferroviaria",
        hudUtilityTargetValue: "Asistencia de servicios públicos de HUD",
        incomeLandRentalTargetValue:
          "Ingresos por alquiler de terrenos o propiedades en alquiler",
        alimonyTargetValue: "Pensión alimenticia",
        cotributionsTargetValue: "Contribuciones de amigos o familiares",
        roomerTargetValue: "Ingresos del inquilino",
        boaderTargetValue: "Ingresos fronterizos",
        workmanTargetValue: "Compensación laboral",
        wagesTargetValue: "Salarios",
        selfemeploymentTargetValue: "Trabajo independiente",
        workStudyTargetValue: "Trabajo estudio",
        childExpenseSubHeader:
          "Por favor, incluya todos los gastos que usted es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        rsdiTargetValue:
          "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
        SSITargetValue: "Ingreso de seguridad suplementario",
        unEmploymentTargetValue: "Compensacion por desempleo",
        childSuppTargetValue: "Manutención",
        pensionTargetValue: "Pensión",
        veteranBenefitsTargetValue: "Beneficios de veteranos",
        rentalIncomeTargetValue: "Ingreso por alquiler",
        workerCompTargetValue: "Compensación a los trabajadores",
        otherTargetValue: "Otro",
        noneTargetValue: "Ninguno",
        mortageInlineHelpText:
          "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
        grossIncomeHelp:
          "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
        unitsPerLabel: "por",
        unitsOptions: ["Anual",
          "Bimestral (cada dos meses)",
          "Bisemanal (cada dos semanas)",
          "Mensual",
          "Trimestral",
          "Semestral (dos veces al año)",
          "Quincenal (dos veces al mes)",
          "Semanalmente"],
        addAnotherButtonLabel: "+ Añadir otro",
        addAdditionalButtonLabel: "+ Agregar adicional",
        labelOtherwage: "+ Añadir otro salario",
        adsSelfEmployee: "+ Agrega otro Trabajo independiente",
        condWorkStudy: "Trabajo estudio",
        workStudyLabel: "+ Añadir otro estudio de trabajo",
        anotherSSILabel: "+ Añadir otro SSI",
        addRDILabel: "+ Añadir otro RSDI",
        unEmpLabel: "+ Añadir otro desempleo",
        childSuppLabel: "Manutención",
        anotherChildsupport: "+ Agregar otra manutención infantil",
        pensionLabel: "Pensión",
        anotherPensionLabel: "+ Añadir otra pensión",
        workerCompensationLabel: "+ Agregar otra compensación laboral",
        veteranBenefit: "Beneficios de veteranos",
        addAnotherVBenefitLabel: "+ Agregue los beneficios de otro veterano",
        rentIncomeLabel: "Ingreso por alquiler",
        repeatQuestionLabel: "+ Añadir otro ingreso por alquiler",
        repeatincomeQuestionLabel: "+ Añadir otro horario de alquiler",
        unitsRentalOptions: ["Mes", "Semana"],
        questionButtonLabel: "+ Añadir otro",
        incomeSummaryTitle: "Resumen de ingresos",
        incomeSummaryHeader: "Resumen de ingresos",
        expenseTitle: "Gastos",
        almostLabel: "¡Casi termina!",
        houseUtlilityLabel: " Gastos de refugio y servicios públicos",
        expenseHeader: "Díganos sobre los costos de su vivienda",
        expenseSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        houseHoldQLabel:
          "¿Es su hogar es responsable de pagar los servicios públicos?",
        utilityQOptions: [
          "Calefacción (aceite, gas, electricidad o propano, etc.)",
          "Electricidad para un aire acondicionado",
          "Una tarifa por uso de un aire acondicionado ",
          "Electricidad y/o gas",
          "Servicio de teléfono o celular",
          "Agua y alcantarillado",
          "Basura",
          "Asistencia Energética (LIHEAP)",
          "No pago ningún gasto de servicios públicos"
        ],
        noUtilityQOptionSelected:"No pago ningún gasto de servicios públicos",
        utilitiesClearOption: "Ninguno",
        houseQLabel: "¿Qué tipo de gastos de vivienda tiene su hogar?",
        houseQOptions: [
          "Alquilar",
          "Hipoteca",
          "Impuestos de propiedad",
          "Seguro de hogar",
          "Tarifa de condominio",
          "Seguro de propiedad",
          "Segunda hipoteca",
          "Gastos para personas sin hogar",
          "Préstamo con garantía hipotecaria",
          "Tarifas de la asociación de propietarios",
          "Otro",
          "No pago ningún gasto de alojamiento."
        ],
        houseRentQLabel: "¿Cuál es el alquiler de su núcleo familiar?",
        rentTargetValue: "Alquiler",
        mortageTargetValue: "Hipoteca",
        propertyTaxTargetValue: "Impuestos a la propiedad",
        homeInsurTargetValue: "Seguro de la casa",
        condoFeeTargetValue: "Tasa de condominio",
        unitBetween: "por",
        houseUnitOptions: ["Semana", "Mes", "Año"],
        mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
        mortageunitBetween: "por",
        mortageQoptions: ["Semana", "Mes", "Año"],
        mortageProptaxLabel:
          "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
        mortagepropBetween: "por",
        mortagepropoptions: ["Semana", "Mes", "Año"],
        insuranceCostQLabel:
          "¿Cuál es el seguro de la casa de su núcleo familiar?",
        insuranceCostpropBetween: "por",
        insuranceCostoptions: ["Semana", "Mes", "Año"],
        insuranceClearOption: "No. No necesito pagar por esto.",
        condofeeQLabel:
          "¿Cuál es el la tasa de condominio de su núcleo familiar?",
        condofeepropBetween: "por",
        condofeeCostoptions: ["Semana", "Mes", "Año"],
        condofeeClearOption: "No. No necesito pagar por esto.",
        propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mes", "Año"],
        propTaxClearOption: "No. No necesito pagar por esto.",
        homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mes", "Año"],
        homeInsuranceClearOption: "No. No necesito pagar por esto.",
        condoFeeQLabel: "¿Su hogar tiene una tarifa de condominio?",
        condoFeeBeweenLabel: "por",
        condoFeeUnits: ["Semana", "Mes", "Año"],
        condoFeeClearOption: "No. No necesito pagar por esto.",

        //new labels:
        anyUtilQcost:
          "¿Su núcleo familiar tiene costos de servicios públicos? ",
        chooseHoldErrmsg: "Por favor elija servicios domésticos",
        houseCostErrmsg: "Por favor elija los costos de la vivienda",
        rentErrMsg: "Por favor ingrese renta",
        anotherRentLabel: "+ Añadir otro alquiler",
        mortageErrLabel: "Por favor ingrese la hipoteca",
        propTaxLabel: "Ingrese los impuestos a la propiedad",
        propTaxButtonLabel: "+ Añadir otros impuestos a la propiedad",
        homeInsurbutton: "+ Añadir otro seguro de hogar",
        homeInsurErrmsg: "Por favor ingrese seguro de la casa",
        condoButtonLabel: "+ Agregar otra tarifa de condominio",
        condoFeeErrmsg: "Por favor ingrese el costo",
        addMortageButtonLabel: "+ Añadir otra hipoteca",
        childTitle: "Costos de cuidado de hijos o adultos dependientes",
        childCostHeader:
          "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
        childCostSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        careGiveObjbefore: `¿Alguien en su hogar paga para que alguien cuide o cuide a un niño o adulto discapacitado para que un miembro del hogar pueda ir a trabajar, buscar trabajo, ir a la escuela o capacitarse? <br/><br/> Esto incluye gastos a `,
        careGiveObjtrigger: "transporte",
        careGiveObjAfter: "del dependiente hacia y desde un cuidador.",
        careGiveHelp:
          "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
        outHHNameLabel:
          "Si paga los gastos de dependientes de alguien fuera del hogar, ingrese sus nombres.",
        outHHNameErrorMsg: "Por favor ingrese el nombre de su hogar externo.",
        questionClearOption: "Ninguna",
        kidsandadultSubheader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        costBetween: "por",
        costBetween1: " ",
        weekMonthOptions: ["Semana", "Mes"],
        costOptions: ["Semana", "Mes", "Año"],
        yesnoOptions: ["Sí", "No"],
        targetValueYes: "Sí",
        noofAppointment: "Por favor ingrese el número de citas",
        nameProvider: "¿Cuál es el nombre del proveedor?",
        addressProvider: "¿Cuál es la dirección del proveedor?",
        freqTripoptions: ["Semana", "Mes", "Año"],
        unitMonthTargetValue: { unit: "Mensual" },
        childSupTitle: "Costos de manutención",
        supportCostHeader: "Costos de manutención",
        childSupportTripLabel:
          "¿Con qué frecuencia su hogar lleva a Marty Smith (2 de junio de 1994) al proveedor? Hacia y desde un proveedor deben contarse como dos viajes.",
        childSupportQlegalbefore:
          "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
        forWhomPaid: "¿Para quién se paga la manutención de los hijos?",
        toWhomPaid: "¿A quién se paga la manutención de los hijos?",
        childSupportQlegaltrigger: "manutención a un niño",
        childSupportQlegalAfter: " que non viva con usted?",
        childSupportHelp:
          "La manutención de los hijos pagada puede contarse como un gasto cuando sea legalmente requerida y se estén realizando los pagos. Esto incluye pagos por atrasos.",
        medicalTitle: "Costos médicos",
        medicalInlineSeethisFull: "",
        medicalInlineEligibleMedical: "",
        medicalHeader: "Díganos sobre sus costos médicos",
        medicalSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        medicalExpBefore:
        `¿Alguien que figura a continuación recibe facturas o paga gastos médicos de su bolsillo?`,
        medicalExpTrigger: "transporte",
        medicalExpAfter: `Vea esta lista completa de gastos médicos elegibles `,
        medicalExphelp: `<ul>
        <li>Medicamentos recetados (una copia impresa de la farmacia de los últimos dos meses completos o más)</li>
        <li>Factura(s) del hospital, incluida la atención en un asilo de ancianos (cualquier factura pendiente actual)</li>
        <li>Visita(s) al médico, dentista u otro profesional de la salud y declaración del médico sobre cómo
        con qué frecuencia se espera que lo vea y cuánto paga por cada visita</li>
        <li>Medicamentos de venta libre recetados por un médico (declaración del médico, 2 meses de
          recibos y frecuencia de pago)</li>
          <li>Prima de Medicare: declaración de la SSA que indica el monto retenido del beneficio mensual</li>
          <li>Prima de seguro hospitalario (estado de cuenta/cupón actual del proveedor con su nombre
          la información)</li>
          <li>Seguro para cobertura de medicamentos recetados (estado de cuenta/cupón actual del proveedor con su
          nombre en la información)</li>
          <li>Gastos de transporte para viajes a médicos, hospitales, farmacias (número de viajes cada uno
            mes verificado por su médico y una declaración de la persona a la que paga, verificando el monto
            cobrado por el transporte. Si conduce, la cantidad de millas que recorre en un solo sentido)</li>
          <li>Suministros o equipos médicos (audífonos, sillas de ruedas, extremidades artificiales, anteojos,
            lentes de contacto, dentaduras postizas, etc.)</li>
          <li>Atención de asistente o servicios de ama de casa (verificación del médico sobre la necesidad de
            estos servicios y una declaración de la persona que proporciona el servicio para verificar el costo)</li>
          <li>Animal de servicio (incluye comida, atención veterinaria y otros costos relacionados)</li>
          <li>Otros gastos médicos relacionados</li>
      </ul>`,
        medicalInhelp: "Consulte la lista completa de",
        medicalInhelpEligible: " gastos médicos elegibles",
        medicalAddress: "¿Cuál es la dirección de la cita médica o farmacia?",
        zipErrmsg: "Por favor ingrese un código postal válido.",
        signSubmitLabel: "Firmar y enviar",
        signSubmitHeader: "Firmar y enviar",
        signSubmitSub:
          "Ha completado las preguntas de la solicitud. Para presentar la solicitud, leer y firmar.",
        tanfSignSubmitSub:
          "Ha completado las preguntas de la solicitud. Para enviar la solicitud, lea la sección Aviso de derechos, responsabilidades y sanciones, acepte los términos y firme.",
        subSectionLabel: "Presentada",
        submitLabelFinal: "Su solicitud para SNAP a sido enviada!",
        comboSubmitLabelFinal:
          "¡Sus solicitudes de SNAP y TANF han sido enviadas!",
        tellusAbout: "Cuéntenos sobre sus gastos de manutención infantil",
        moneyYougettitle:
          "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
        tripCountErrMsg: "Ingrese un conteo válido de desconexiones",
        tripCountErrMsgLen:
          "Ingrese 6 caracteres como máximo por conteo de desconexiones",
        tripCostErrMsgLen:
          "Ingrese 6 caracteres o menos para el costo del viaje",
        optionErrMsg: "Por favor, seleccione una opción a continuación.",
        amountErrMsg: "Por favor ingrese una cantidad válida.",
        interimShelterQlabel:
        "What type(s) of shelter expenses your household have?",
      interimShelterLabel: {
          textBefore: "¿Qué tipo(s) de ",
          triggerText: "gastos de alojamiento",
          textAfter: " tiene su hogar?",
          helpText: "",
      },
      interimUtilityQlabel:
        "¿Qué tipo(s) de gastos de servicios públicos tiene su hogar?",
      interimUtilityLabel: {
          textBefore: "¿Qué tipo(s) de ",
          triggerText: "gastos de servicios públicos",
          textAfter: " el pago de su hogar?",
          helpText: "",
      },
        summaryLabel: "Resumen",
        medicalExpenseType:
          "¿Qué tipo de gastos médicos tienes? (Seleccione todas las que correspondan)",
        doctorOrDentistOptions: "Doctora o dentista",
        doctorOrDentist: "¿Cuánta cantidad gasta namePlaceholder en médico o dentista?",
        hospitalOrNursingHomeOptions: "Hospital o residencia de ancianos",
        hospitalOrNursingHome:
          "¿Cuánta cantidad gasta namePlaceholder en un hospital o una residencia de ancianos?",
        prescriptionDrugsOptions: "Medicamentos con receta ",
        prescriptionDrugs:
          "¿Cuánto gasta namePlaceholder en medicamentos recetados?",
        overTheCounterMedicineOptions: "Medicina sin receta",
        overTheCounterMedicine:
          "¿Cuánta cantidad gasta namePlaceholder en medicamentos de venta libre?",
        healthInsurancePremiumsOptions: "Primas de seguro médico",
        healthInsurancePremiums: "¿Cuánta cantidad gasta namePlaceholder en primas de seguro médico?",
        medicalSuppliesOptions: "Suministros médicos",
        medicalSupplies: "¿Cuánta cantidad gasta namePlaceholder en suministros médicos?",
        medicaidCostSharingOptions: "Costo compartido de Medicaid",
        medicaidCostSharing: "¿Cuánto gasta namePlaceholder en costos compartidos de Medicaid?",
        denturesOrHearingAidsOptions: "Dentaduras postizas o audífonos",
        denturesOrHearingAids: "¿Cuánta cantidad gasta namePlaceholder en dentaduras postizas o audífonos?",
        prescriptionEyeGlasesOptions: "Anteojos recetados",
        prescriptionEyeGlases: "¿Cuánta cantidad gasta namePlaceholder en anteojos recetados?",
        transportationOrLoadingForMedicalServicesOptions: "Transporte o Carga para Servicios Médicos",
        transportationOrLoadingForMedicalServices: "¿Cuánta cantidad gasta namePlaceholder en transporte o carga para servicios médicos?",
        HudHomeHealthAideOptions: "Asistente de atención médica domiciliaria o cuidado infantil de HUD",
        HudHomeHealthAide:
          "¿Cuánta cantidad gasta namePlaceholder en asistente de atención médica domiciliaria o cuidado infantil de HUD?",
        serviceAnimalOptions: "Animal de servicio",
        serviceAnimal: "¿Cuánta cantidad gasta namePlaceholder en animales de servicio?",
        otherMedicalExpenseOptions: "Otra",
        otherMedicalExpense: "¿Cuánta cantidad gasta namePlaceholder en Otros?",
        prepareInterview: "Esté preparado para discutir y verificar esta información en su entrevista.",
        medicalAppointmentsOrPharmacy: "¿NamePlaceholder conduce a las citas médicas o a la farmacia?",
        howManyMedicalAppointmentsOrPharmacy: "¿Cuántas direcciones tiene nombrePlaceholder para las citas médicas o la farmacia?",
        tellUsPocketMedicalExpense:
          "Cuéntenos sobre cualquier gasto médico de bolsillo.",
        beSureLegally:
          "Asegúrese de informarle a su trabajador si usted es legalmente responsable de los gastos médicos de una persona mayor o discapacitada que fuera miembro de su hogar.",
        fulllist: "",
        allMedicalExpenseVerified: "Todos los gastos médicos deben ser verificados.",
        verfiedExpenseMay:
          "Los gastos verificados pueden aumentar el monto de sus beneficios.",
        acceptableForms:
          "Su trabajador discutirá con usted las formas aceptables de verificación.",
        obtainVerificationNeeded:
          "Le ayudaremos a obtener la verificación cuando sea necesario.",
        fulllistEligible: "Vea esta lista completa de elegibles",
      };
    case PT: //PORTUGES
      return {
        incomeTitle: "Renda",
        incomeHeader: "Sobre a renda da sua família",
        aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
        aboutIncomeTrigger: " renda ou benefício",
        incomeHelp:
          "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
        clearOption: "Ninguém",
        combineIncomeoptions: [
          "Salário",
          "Trabalho autônomo",
          "Estudo",
          "SSI (Renda de segurança suplementar)",
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
          "Desemprego",
          "Suporte infantil",
          "Pensão",
          "Benefício de veterano",
          "Renda de aluguel",
          "Compensação do trabalhador",
          "Outros",
        ],
        wageIncomeBeforeLabel: "Qual é o ",
        wageIncomeTriggerLabel: "rendimento bruto",
        wagesTargetValue: "Salário",
        selfemeploymentTargetValue: "Trabalho autônomo",
        workStudyTargetValue: "Estudo",
        rsdiTargetValue:
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
        SSITargetValue: "SSI (Renda de segurança suplementar)",
        unEmploymentTargetValue: "Desemprego",
        childSuppTargetValue: "Suporte infantil",
        pensionTargetValue: "Pensão",
        veteranBenefitsTargetValue: "Benefício de veterano",
        rentalIncomeTargetValue: "Renda de aluguel",
        workerCompTargetValue: "Compensação do trabalhador",
        otherTargetValue: "Outros",
        noneTargetValue: "Nenhum",
        mortageInlineHelpText:
          "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
        grossIncomeHelp:
          "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
        unitsPerLabel: "por",
        unitsOptions: ["Annual",
          "Bimonthly(every two months)",
          "Biweekly(every two weeks)",
          "Monthly",
          "Quarterly",
          "Semi-annual(Twice a year)",
          "Semi-monthly(Twice a month)",
          "Weekly"],
        labelOtherwage: "+ Adicionar outro Salário",
        adsSelfEmployee: "+ Adicionar outro Trabalho autônomo",
        condWorkStudy: "Estudo",
        workStudyLabel: "+ Adicionar outro Estudo",
        anotherSSILabel: "+ Adicionar outro SSI",
        addRDILabel: "+ Adicione outro RSDI",
        unEmpLabel: "+ Adicionar outro Desemprego",
        childSuppLabel: "Suporte infantil",
        anotherChildsupport: "+ Adicionar outro Suporte infantil",
        pensionLabel: "Pensão",
        anotherPensionLabel: "+ Adicionar outro Pensão",
        workerCompensationLabel: "+ adicionar compensação de outro trabalhador",
        veteranBenefit: "Benefício de veterano",
        addAnotherVBenefitLabel: "+ Adicionar outro Benefício de veterano",
        rentIncomeLabel: "Renda de aluguel",
        repeatQuestionLabel: "+ Adicionar outro Renda de aluguel",
        repeatincomeQuestionLabel: "+ Adicionar outro Renda de aluguel",
        unitsRentalOptions: ["Mês", "Semana"],
        questionButtonLabel: "+ Adicionar outro Outros",
        incomeSummaryTitle: "Resumo da renda",
        incomeSummaryHeader: "Resumo da renda",
        expenseTitle: "Despesas",
        almostLabel: "Quase lá!",
        houseUtlilityLabel: "Custos residenciais e utilitários",
        expenseHeader: "Fale sobre os seus custos residenciais",
        expenseSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        houseHoldQLabel: "A sua casa é atendida por algum serviço público?",
        utilityQOptions: [
          "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
          "Eletricidade para ar condicionado",
          "Taxa para o uso de ar condicionado",
          "Eletricidade e/ou gás",
          "Serviço de telefone fixo ou celular",
        ],
        utilitiesClearOption: "Nenhum",
        houseQLabel: "Que tipo de custos residenciais a sua família tem?",
        houseQOptions: [
          "Aluguel",
          "Financiamento",
          "Impostos da propriedade",
          "Seguro residencial",
          "Condomínio",
        ],
        houseRentQLabel: "Qual é o valor do seu aluguel?",
        rentTargetValue: "Aluguel",
        mortageTargetValue: "Financiamento",
        propertyTaxTargetValue: "Impostos da propriedade",
        homeInsurTargetValue: "Seguro residencial",
        condoFeeTargetValue: "Condomínio",
        unitBetween: "por",
        houseUnitOptions: ["Semana", "Mês", "Ano"],
        mortageQLabel: "Qual é o valor do seu financiamento?",
        mortageunitBetween: "por",
        mortageQoptions: ["Semana", "Mês", "Ano"],
        mortageProptaxLabel: "Qual é o valor dos impostos da sua propriedade?",
        mortagepropBetween: "por",
        mortagepropoptions: ["Semana", "Mês", "Ano"],
        insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
        insuranceCostpropBetween: "por",
        insuranceCostoptions: ["Semana", "Mês", "Ano"],
        insuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condofeeQLabel: "Qual é o valor do seu condomínio?",
        condofeepropBetween: "por",
        condofeeCostoptions: ["Semana", "Mês", "Ano"],
        condofeeClearOption: "Não. Eu não preciso pagar por isso.",
        propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mês", "Ano"],
        propTaxClearOption: "Não. Eu não preciso pagar por isso.",
        homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mês", "Ano"],
        homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condoFeeQLabel: "A sua casa possui uma taxa de condomínio?",
        condoFeeBeweenLabel: "por",
        condoFeeUnits: ["Semana", "Mês", "Ano"],
        condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

        //new labels:
        anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
        chooseHoldErrmsg: "Escolha utilitários domésticos",
        houseCostErrmsg: "Escolha os custos de habitação",
        rentErrMsg: "Entre com aluguel",
        anotherRentLabel: "+ Adicionar aluguel",
        mortageErrLabel: "Insira uma hipoteca",
        propTaxLabel: "Digite os impostos sobre a propriedade",
        propTaxButtonLabel: "+ Adicionar impostos da propriedade",
        homeInsurbutton: "+ Adicionar seguro residencial",
        homeInsurErrmsg: "Digite o seguro de casa",
        condoButtonLabel: "+ Adicionar condomínio",
        condoFeeErrmsg: "Entre com taxa de condomínio",
        addMortageButtonLabel: "+ Adicionar financiamento",
        childTitle: "Custos de crianças ou dependentes",
        childCostHeader:
          "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
        childCostSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        careGiveObjbefore:
          "Alguém na sua família tem custos com cuidados para crianças ou adultos dependentes? Incluindo os custos de ",
        careGiveObjtrigger: "transporte do(s)",
        careGiveObjAfter: "dependente(s) até o prestador de serviços.",
        careGiveHelp:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        questionClearOption: "Ninguém",
        kidsandadultSubheader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        costBetween: "por",
        costBetween1: " ",
        weekMonthOptions: ["Semana", "Mês"],
        costOptions: ["Semana", "Mês", "Ano"],
        yesnoOptions: ["Sim", "Não"],
        targetValueYes: "Sim",
        noofAppointment: "Por favor, escolha o número de compromissos",
        addressProvider: "Qual é o endereço do prestador de serviço?",
        freqTripoptions: ["Semana", "Mês", "Ano"],
        unitMonthTargetValue: { unit: "Mês" },
        childSupTitle: "Custos de suporte infantil",
        supportCostHeader: "Custos de suporte infantil",
        childSupportQlegalbefore:
          "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar  ",
        childSupportQlegaltrigger: "pelo suporte de uma",
        childSupportQlegalAfter: " criança que não vive com você?",
        childSupportHelp:
          "O pagamento de suporte infantil pode ser contado como uma despesa quando for exigido legalmente e quando os pagamentos estiverem sendo feitos. Incluindo pagamentos em atraso.",
        medicalTitle: "Custos médicos",
        medicalInlineSeethisFull: "Veja esta lista completa de",
        medicalInlineEligibleMedical: " despesas médicas elegíveis.",
        medicalHeader: "Fale sobre os seus custos médicos",
        medicalSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        medicalExpBefore:
          "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
        medicalExpTrigger: "transporte",
        medicalExpAfter:
          ", medicamentos de farmácia, dentistas, oftalmologistas, fraldas geriátricas, etc.?",
        medicalExphelp:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        medicalInhelp: "Veja esta lista completa de",
        medicalInhelpEligible: "despesas médicas elegíveis",
        medicalAddress: "Qual é o endereço da consulta médica ou da farmácia?",
        zipErrmsg: "Por favor insira um código postal válido",
        signSubmitLabel: "Assinar e enviar",
        signSubmitHeader: "Assinar e enviar",
        signSubmitSub:
          "Você chegou ao fim do formulário! Agora vamos à parte fácil.",
        subSectionLabel: "Submetido",
        submitLabelFinal: "Sua aplicação do SNAP foi enviada!",
        tellusAbout: "Fale sobre os custos de suporte infantil",
        moneyYougettitle:
          "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
        tripCountErrMsg: "Digite um valor de medidor válido",
        tripCountErrMsgLen: "Digite um valor de medidor com até 6 caracteres",
        tripCostErrMsgLen:
          "Insira 6 ou menos caracteres para o custo da viagem",
        optionErrMsg: "por favor selecione uma opção abaixo",
        amountErrMsg: "Digite um valor válido",
      };
    case ZH: //China
      return {
        incomeTitle: "收入",
        incomeHeader: "关于您的家庭收入",
        aboutIncomeBefore: "您家中是否有人获得任何 ",
        aboutIncomeTrigger: "收入或补助",
        incomeHelp:
          "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
        clearOption: "无人",
        combineIncomeoptions: [
          "工资",
          "个体经营",
          "工作研究",
          "SSI（补充保障收入）",
          "RSDI（退休，幸存者和残疾保险）",
          "失业",
          "子女抚养费",
          "养老金",
          "退伍军人补贴",
          "租金收入",
          "工伤赔偿其",
          "其他",
        ],
        wageIncomeBeforeLabel: "是什麼 ",
        wageIncomeTriggerLabel: "總收入",
        wagesTargetValue: "工资",
        selfemeploymentTargetValue: "个体经营",
        workStudyTargetValue: "工作研究",
        SSITargetValue: "SSI（补充保障收入）",
        unEmploymentTargetValue: "失业",
        rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
        childSuppTargetValue: "子女抚养费",
        pensionTargetValue: "养老金",
        veteranBenefitsTargetValue: "退伍军人补贴",
        rentalIncomeTargetValue: "租金收入",
        workerCompTargetValue: "工伤赔偿其",
        otherTargetValue: "其他",
        noneTargetValue: "没有",
        mortageInlineHelpText:
          "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
        grossIncomeHelp:
          "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
        unitsPerLabel: "每",
        unitsOptions: ["Annual",
          "Bimonthly(every two months)",
          "Biweekly(every two weeks)",
          "Monthly",
          "Quarterly",
          "Semi-annual(Twice a year)",
          "Semi-monthly(Twice a month)",
          "Weekly"],
        labelOtherwage: "+ 添加另一個工資",
        adsSelfEmployee: "+ 添加另一個自僱",
        condWorkStudy: "工作研究",
        workStudyLabel: "+ 添加另一個工作研究",
        anotherSSILabel: "+ 添加另一個SSI",
        addRDILabel: "+ 添加另一個RSDI",
        unEmpLabel: "+ 再增加一個失業",
        childSuppLabel: "子女撫養費",
        anotherChildsupport: "+ 添加另一個子支持",
        pensionLabel: "養老金",
        anotherPensionLabel: "+ 再加一個養老金",
        workerCompensationLabel: "添加另一个工人的赔偿",
        veteranBenefit: "老兵的好处",
        addAnotherVBenefitLabel: "+ 添加另一個退伍軍人的好處",
        rentIncomeLabel: "贴租金收",
        repeatQuestionLabel: "+ 添加另一个租金收入",
        repeatincomeQuestionLabel: "+ 添加另一个租金收入",
        unitsRentalOptions: ["月", "周"],
        questionButtonLabel: "+ 添加另一個租金收入",
        incomeSummaryTitle: "收入摘要",
        incomeSummaryHeader: "收入摘要",
        expenseTitle: "开支",
        almostLabel: "还差一步！",
        houseUtlilityLabel: "住房和通用开支",
        expenseHeader: "请告诉我们您的家庭开支",
        expenseSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        houseHoldQLabel: "您的家庭是否负责任何公用事业设备？",
        utilityQOptions: [
          "取暖费（石油，天然气，电力或丙烷等）",
          "空调用电费",
          "空调使用费",
          "电费和/或燃气费",
          "电话费或手机费",
        ],
        utilitiesClearOption: "没有",
        houseQLabel: "您的家庭有哪些住房开支？",
        houseQOptions: [
          "房租",
          "抵押贷款",
          "财产税",
          "家庭保险",
          "物业管理费用",
        ],
        houseRentQLabel: "您的住房的月租金有多少？",
        rentTargetValue: "房租",
        mortageTargetValue: "抵押贷款",
        propertyTaxTargetValue: "财产税",
        homeInsurTargetValue: "家庭保险",
        condoFeeTargetValue: "物业管理费用",
        unitBetween: "每",
        houseUnitOptions: ["周", "月", "年"],
        mortageQLabel: "您家的房贷有多少？",
        mortageunitBetween: "每",
        mortageQoptions: ["周", "月", "年"],
        mortageProptaxLabel: "您的住房的财产税有多少？",
        mortagepropBetween: "每",
        mortagepropoptions: ["周", "月", "年"],
        insuranceCostQLabel: "您的住房的房屋保险有多少？",
        insuranceCostpropBetween: "每",
        insuranceCostoptions: ["周", "月", "年"],
        insuranceClearOption: "不，我不需要为此付费。",
        condofeeQLabel: "您的住房的物业管理费有多少？",
        condofeepropBetween: "每",
        condofeeCostoptions: ["周", "月", "年"],
        condofeeClearOption: "不，我不需要为此付费。",
        propTaxQLabel: "你家有財產稅嗎？",
        propTaxBetween: "每",
        propTaxOptions: ["周", "月", "年"],
        propTaxClearOption: "不，我不需要为此付费。",
        homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
        homeInsuranceBetween: "每",
        homeInsuranceUnits: ["周", "月", "年"],
        homeInsuranceClearOption: "不，我不需要为此付费。",
        condoFeeQLabel: "你家有公寓費嗎？",
        condoFeeBeweenLabel: "每",
        condoFeeUnits: ["周", "月", "年"],
        condoFeeClearOption: "不，我不需要为此付费。",

        //new labels:
        anyUtilQcost: "您的家庭有哪些通用开支？",
        chooseHoldErrmsg: "请选择家用电器",
        houseCostErrmsg: "请选择住房费用",
        rentErrMsg: "请输入租金",
        anotherRentLabel: "+ 添加另一個租金",
        mortageErrLabel: "请输入抵押",
        propTaxLabel: "请输入财产税",
        propTaxButtonLabel: "+ 添加另一個財產稅",
        homeInsurbutton: "+ 添加另一個家庭保險",
        homeInsurErrmsg: "请输入家庭保险",
        condoButtonLabel: "+ 添加另一個公寓費用",
        condoFeeErrmsg: "请输入公寓费用",
        addMortageButtonLabel: "+ 添加另一個抵押",
        childTitle: "子女或成人照护费用",
        childCostHeader: "告诉我们您的子女或成人的照护费",
        childCostSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        careGiveObjbefore: "您的家人有子女或成人照护开支吗？这包括 ",
        careGiveObjtrigger: "接送",
        careGiveObjAfter: "被照护人到其看护人的费用。",
        careGiveHelp:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        questionClearOption: "无人",
        kidsandadultSubheader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        costBetween: "每",
        costBetween1: " ",
        weekMonthOptions: ["周", "月"],
        costOptions: ["周", "月", "年"],
        yesnoOptions: ["是", "否"],
        targetValueYes: "是",
        noofAppointment: "请选择任命数量",
        addressProvider: "看护人的地址？",
        freqTripoptions: ["周", "月", "年"],
        unitMonthTargetValue: { unit: "月" },
        childSupTitle: "子女抚养费用",
        supportCostHeader: "子女抚养费用",
        childSupportQlegalbefore:
          "您家中是否有人必须向不与您同住的子女支付法定 ",
        childSupportQlegaltrigger: "子女抚养费",
        childSupportQlegalAfter: " ?",
        childSupportHelp:
          "在法定情况下以及正在支付的情况下，子女抚养费可以算作一项开支。这包括拖欠款项",
        medicalTitle: "医疗费用",
        medicalInlineSeethisFull: "查看有关",
        medicalInlineEligibleMedical: " 合格医疗开支的完整清单。",
        medicalHeader: "请告诉我们您的医疗费",
        medicalSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        medicalExpBefore:
          "下列任何人是否支付健康保险或其他医疗费用，包括门诊，",
        medicalExpTrigger: "交通",
        medicalExpAfter: ", 非处方药，牙科或眼睛护理，成人尿片等？",
        medicalExphelp:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        medicalInhelp: "查看有关",
        medicalInhelpEligible: "合格医疗开支的完整清单。",
        medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
        medicalAddress: "医疗机构或药房地址？",
        zipErrmsg: "請輸入有效的郵政編碼",
        signSubmitLabel: "签名并提交",
        signSubmitHeader: "签名并提交",
        signSubmitSub: "您已填写完全部表格！现在更简单。",
        subSectionLabel: "提交",
        submitLabelFinal: "您的SNAP申请已提交",
        tellusAbout: "请告诉我们您的子女抚养费",
        moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
        tripCountErrMsg: "请输入有效行程计数",
        tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
        tripCostErrMsgLen: "请输入少于/等于 6 个字符的旅程费用",
        optionErrMsg: "请在下面选择一个选项",
        amountErrMsg: "請輸入有效金額",
      };
    case VI: //Vietnam
      return {
        incomeTitle: "Thu nhập",
        incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
        aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
        aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không",
        incomeHelp:
          "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
        clearOption: "Không có ai",
        combineIncomeoptions: [
          "Lương",
          "Tự làm chủ",
          "Nghiên cứu việc làm",
          "SSI (Thu Nhập An Sinh Bổ Sung)",
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
          "Thất nghiệp",
          "Trợ cấp nuôi con",
          "Lương hưu",
          "Trợ cấp cho Cựu chiến binh",
          "Thu nhập cho thuê",
          "Bồi thường lao động",
          "Khác",
        ],
        wageIncomeBeforeLabel: "Cái gì là ",
        wageIncomeTriggerLabel: "Tổng thu nhập",
        wagesTargetValue: "Lương",
        selfemeploymentTargetValue: "Tự làm chủ",
        workStudyTargetValue: "Nghiên cứu việc làm",
        SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
        rsdiTargetValue:
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
        unEmploymentTargetValue: "Thất nghiệp",
        childSuppTargetValue: "Trợ cấp nuôi con",
        pensionTargetValue: "Lương hưu",
        veteranBenefitsTargetValue: "Trợ cấp cho Cựu chiến binh",
        rentalIncomeTargetValue: "Thu nhập cho thuê",
        workerCompTargetValue: "Bồi thường lao động",
        otherTargetValue: "Khác",
        noneTargetValue: "Khôngcó thu nhập và trợ cấp",
        mortageInlineHelpText:
          "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
        grossIncomeHelp:
          "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
        unitsPerLabel: "theo",
        workerCompensationLabel: "thêm bồi thường của công nhân khác",
        unitsOptions: ["Annual",
          "Bimonthly(every two months)",
          "Biweekly(every two weeks)",
          "Monthly",
          "Quarterly",
          "Semi-annual(Twice a year)",
          "Semi-monthly(Twice a month)",
          "Weekly"],
        labelOtherwage: "+ Thêm một mức lương khác",
        adsSelfEmployee: "+ Thêm một việc tự làm",
        condWorkStudy: "Nghiên cứu việc làm",
        workStudyLabel: "+ Thêm nghiên cứu việc làm khác",
        anotherSSILabel: "+ Thêm SSI khác",
        addRDILabel: "+ Thêm một RSDI khác",
        unEmpLabel: "+ Thêm một Thất nghiệp",
        childSuppLabel: "Trợ cấp nuôi con",
        anotherChildsupport: "+ Thêm một khoản Hỗ trợ Nuôi con khác",
        pensionLabel: "Lương hưu",
        anotherPensionLabel: "+ Thêm tiền trợ cấp khác",
        veteranBenefit: "Trợ cấp cho Cựu chiến binh",
        addAnotherVBenefitLabel: "+ Thêm một lợi ích của cựu chiến binh",
        rentIncomeLabel: "Thu nhập cho thuê",
        repeatQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
        repeatincomeQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
        unitsRentalOptions: ["Tháng", "Tuần"],
        questionButtonLabel: "+ Thêm một Khác",
        incomeSummaryTitle: "Tóm tắt Thu nhập",
        incomeSummaryHeader: "Tóm tắt Thu nhập",
        expenseTitle: "Các chi phí",
        almostLabel: "Gần xong rồi!",
        houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
        expenseHeader: "Hãy nói cho chúng tôi biết về chi phí nhà ở của quý vị",
        expenseSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        houseHoldQLabel:
          "Hộ gia đình của bạn có phải trả các dịch vụ công như điện, nước, ga không?",
        utilityQOptions: [
          "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
          "Điện cho máy điều hòa không khí",
          "Chi phí sử dụng máy điều hòa không khí",
          "Điện và / hoặc khí đốt",
          "Dịch vụ điện thoại hoặc điện thoại di động",
        ],
        utilitiesClearOption: "Khôngcó thu nhập và trợ cấp",
        houseQLabel: "Loại chi phí nhà ở mà hộ gia đình có?",
        houseQOptions: [
          "Thuê",
          "Thế chấp",
          "Thuế bất động sản",
          "Bảo hiểm nhà ở",
          "Chi phí công quản",
        ],
        houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
        rentTargetValue: "Thuê",
        mortageTargetValue: "Thế chấp",
        propertyTaxTargetValue: "Thuế bất động sản",
        homeInsurTargetValue: "Bảo hiểm nhà ở",
        condoFeeTargetValue: "Chi phí công quản",
        unitBetween: "theo",
        houseUnitOptions: ["Tuần", "Tháng", "Năm"],
        mortageQLabel: "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
        mortageunitBetween: "theo",
        mortageQoptions: ["Tuần", "Tháng", "Năm"],
        mortageProptaxLabel:
          "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
        mortagepropBetween: "theo",
        mortagepropoptions: ["Tuần", "Tháng", "Năm"],
        insuranceCostQLabel:
          "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
        insuranceCostpropBetween: "theo",
        insuranceCostoptions: ["Tuần", "Tháng", "Năm"],
        insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condofeeQLabel:
          "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
        condofeepropBetween: "theo",
        condofeeCostoptions: ["Tuần", "Tháng", "Năm"],
        condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
        propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
        propTaxBetween: "theo",
        propTaxOptions: ["Tuần", "Tháng", "Năm"],
        propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
        homeInsuranceQLabel:
          "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
        homeInsuranceBetween: "theo",
        homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
        homeInsuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
        condoFeeBeweenLabel: "theo",
        condoFeeUnits: ["Tuần", "Tháng", "Năm"],
        condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

        //new labels:
        anyUtilQcost:
          "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
        chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
        houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
        rentErrMsg: "Hãy nhập tiền thuê",
        anotherRentLabel: "+ Thêm tiền thuê khác",
        mortageErrLabel: "Hãy nhập khoản thế chấp",
        propTaxLabel: "Vui lòng nhập thuế bất động sản",
        propTaxButtonLabel: "+ Thêm tiền thuê khác",
        homeInsurbutton: "+ Thêm một bảo hiểm khác",
        homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
        condoButtonLabel: "+ Thêm một khoản phí chung",
        condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
        addMortageButtonLabel: "+ Thêm thế chấp khác",
        childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
        childCostHeader:
          "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
        childCostSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        careGiveObjbefore:
          "Có ai trong hộ gia đình có các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc không? Chi phí này bao gồm các chi phí ",
        careGiveObjtrigger: "chuyên chở",
        careGiveObjAfter:
          "(những) người phụ thuộc đến và/về từ nhà cung cấp dịch vụ chăm sóc.",
        careGiveHelp:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
        questionClearOption: "Không có ai",
        kidsandadultSubheader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        costBetween: "theo",
        costBetween1: " ",
        weekMonthOptions: ["Tuần", "Tháng"],
        costOptions: ["Tuần", "Tháng", "Năm"],
        yesnoOptions: ["Có", "Không"],
        targetValueYes: "Có",
        noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
        addressProvider: "Địa chỉ của nhà cung cấp là gì?",
        freqTripoptions: ["Tuần", "Tháng", "Năm"],
        unitMonthTargetValue: { unit: "Tháng" },
        childSupTitle: "Chi phí Trợ cấp nuôi Con",
        supportCostHeader: "Chi phí Trợ cấp nuôi Con",
        childSupportQlegalbefore:
          "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
        childSupportQlegaltrigger: "trợ cấp nuôi con",
        childSupportQlegalAfter:
          " cho một đứa trẻ không sống chung với quý vị?",
        childSupportHelp:
          "Khoản tiền trợ cấp nuôi con được thanh toán có thể được tính là một khoản chi phí khi khoản này được bắt buộc một cách hợp pháp và các khoản thanh toán đang được thực hiện. Khoản này bao gồm các khoản thanh toán cho các khoản nợ",
        medicalTitle: "Chi phí Y tế",
        medicalInlineSeethisFull: "Xem danh sách đầy đủ về",
        medicalInlineEligibleMedical: " chi phí y tế hợp lệ",
        medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
        medicalSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        medicalExpBefore:
          "Có ai được liệt kê dưới đây chi trả tiền bảo hiểm y tế hay các chi phí y tế khác bao gồmtoa thuốc,",
        medicalExpTrigger: " chuyên chở",
        medicalExpAfter:
          ", thuốc không cần toa, chăm sóc răng hoặc mắt, tã người lớn, v.v.?",
        medicalExphelp:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
        medicalInhelp: "Xem danh sách đầy đủ về",
        medicalInhelpEligible: " chi phí y tế hợp lệ",
        medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
        zipErrmsg: "Please enter a valid Zip code.",
        signSubmitLabel: "Ký tên & Gửi",
        signSubmitHeader: "Ký tên & Gửi",
        signSubmitSub:
          "Quý vị đã làm xong mẫu đơn! Bây giờ đến những việc dễ dàng.",
        subSectionLabel: "Đã gửi",
        submitLabelFinal: "Don SNAP cua ban da duoc gui!",
        tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
        moneyYougettitle:
          "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
        tripCountErrMsg: "Vui lòng nhập vào số chuyến đi có giá trị",
        tripCountErrMsgLen:
          "Vui lòng nhập vào bằng hoặc ít hơn 6 ký tự cho chuyến đi",
        tripCostErrMsgLen:
          "Vui lòng nhập ít hơn/bằng 6 ký tự cho chi phí chuyến đi",
        optionErrMsg: "Vui lòng chọn một tùy chọn bên dưới",
        amountErrMsg: "Vui lòng nhập số tiền hợp lệ",
      };
    case HI:
      return {
        incomeTitle: "Revni",
        incomeHeader: "Konsènan revni fwaye w la",
        aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
        aboutIncomeTrigger: "revni oswa benefis",
        incomeHelp:
          "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
        clearOption: "Pesonn",
        combineIncomeoptions: [
          "Salè",
          "Travay Endepandan",
          "Travay Etidyan",
          "SSI (Revni Sekirite Siplemantè)",
          "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
          "Chomaj",
          "Sipò Timoun",
          "Pansyon",
          "Benefis pou Veteran",
          "Revni Lwaye",
          "Konpansasyon Travayè",
          "Lòt",
        ],
        wageIncomeBeforeLabel: "Ki ",
        wageIncomeTriggerLabel: "montan",
        wagesTargetValue: "Salè",
        selfemeploymentTargetValue: "Travay Endepandan",
        workStudyTargetValue: "Travay Etidyan",
        rsdiTargetValue: "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
        SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
        unEmploymentTargetValue: "Chomaj",
        childSuppTargetValue: "Sipò Timoun",
        pensionTargetValue: "Pansyon",
        veteranBenefitsTargetValue: "Benefis pou Veteran",
        rentalIncomeTargetValue: "Revni Lwaye",
        workerCompTargetValue: "Konpansasyon Travayè",
        otherTargetValue: "Lòt",
        noneTargetValue: "Okenn",

        mortageInlineHelpText:
          "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
        grossIncomeHelp:
          "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
        unitsPerLabel: "pa",
        unitsOptions: ["Annual",
          "Bimonthly(every two months)",
          "Biweekly(every two weeks)",
          "Monthly",
          "Quarterly",
          "Semi-annual(Twice a year)",
          "Semi-monthly(Twice a month)",
          "Weekly"],
        labelOtherwage: "+ Ajoute yon lòt Salè",
        adsSelfEmployee: "+ Ajoute yon lòt travay endepandan",
        condWorkStudy: "Travay Etidyan",
        workStudyLabel: "+ Ajoute yon lòt travay etidyan",
        anotherSSILabel: "+ Ajoute yon lòt SSI",
        addRDILabel: "+ Ajoute yon lòt RSDI",
        unEmpLabel: "+ Ajoute yon lòt Chomaj ",
        childSuppLabel: "Sipò Timoun",
        anotherChildsupport: "+ Ajoute yon lòt Sipò Timoun",
        pensionLabel: "Pansyon",
        anotherPensionLabel: "+ Ajoute yon lòt Pansyon",
        workerCompensationLabel: "+ Ajoute yon lòt Konpansasyon Travayè",
        veteranBenefit: "Benefis pou Veteran",
        addAnotherVBenefitLabel: "+ Ajoute yon lòt benefis pou veteran",
        rentIncomeLabel: "Revni Lwaye",
        repeatQuestionLabel: "+ Ajoute yon lòt revni lwaye",
        repeatincomeQuestionLabel: "+ Ajoute èdtan pou yon lòt lwaye",
        unitsRentalOptions: ["Mwa", "Semèn"],
        questionButtonLabel: "+ Ajoute yon lòt Lòt",
        incomeSummaryTitle: "Rezime revni",
        incomeSummaryHeader: "Rezime revni",
        expenseTitle: "Depans",
        almostLabel: "Prèske fini!",
        houseUtlilityLabel: "Depans pou lojman ak sèvis piblik",
        expenseHeader: "Pale nou konsènan depans ou yo pou lojman",
        expenseSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        houseHoldQLabel: "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
        utilityQOptions: [
          " Chofaj (luil, gaz, elektrisite oswa pwopàn, elatriye)",
          " Elektrisite pou yon klimatizè",
          "Yon frè pou itilize yon klimatizè",
          "Elektrisite ak/oswa gaz",
          "Telefòn oswa sèvis telefòn selilè",
        ],
        utilitiesClearOption: "Okenn",
        houseQLabel: "Ki tip depans pou lojman fwaye w la genyen?",
        houseQOptions: [
          "Lwaye",
          "Prè Ipotèk",
          "Taks sou Pwopriyete",
          "Asirans Kay",
          "Frè Kondo",
        ],
        houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
        rentTargetValue: "Lwaye",
        mortageTargetValue: "Prè Ipotèk",
        propertyTaxTargetValue: "Taks sou Pwopriyete",
        homeInsurTargetValue: "Asirans Kay",
        condoFeeTargetValue: "Frè Kondo",

        unitBetween: "pa",
        houseUnitOptions: ["Semèn", "Mwa", "Ane"],
        mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
        mortageunitBetween: "pa",
        mortageQoptions: ["Semèn", "Mwa", "Ane"],
        mortageProptaxLabel: "Konbyen taks sou pwopriete fwaye w la ye?",
        mortagepropBetween: "pa",
        mortagepropoptions: ["Semèn", "Mwa", "Ane"],
        insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
        insuranceCostpropBetween: "pa",
        insuranceCostoptions: ["Semèn", "Mwa", "Ane"],
        insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
        condofeepropBetween: "pa",
        condofeeCostoptions: ["Semèn", "Mwa", "Ane"],
        condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
        propTaxBetween: "pa",
        propTaxOptions: ["Semèn", "Mwa", "Ane"],
        propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
        homeInsuranceBetween: "pa",
        homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
        homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
        condoFeeBeweenLabel: "pa",
        condoFeeUnits: ["Semèn", "Mwa", "Ane"],
        condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

        //new labels:
        anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
        chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
        houseCostErrmsg: "Tanpri chwazi depans pou lojman",
        rentErrMsg: "Tanpri antre lwaye",
        anotherRentLabel: "+ Ajoute yon lòt lwaye",
        mortageErrLabel: "Tanpri antre prè ipotèk",
        propTaxLabel: "Tanpri antre taks sou pwopriyete",
        propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
        homeInsurbutton: "+ Ajoute yon lòt asirans kay",
        homeInsurErrmsg: "Tanpri antre asirans kay",
        condoButtonLabel: "+ Ajoute yon lòt frè kondo",
        condoFeeErrmsg: "Tanpri antre frè kondo",
        addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk ",
        childTitle: "Depans pou swen Timoun oswa Adilt Depandan",
        childCostHeader:
          "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
        childCostSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        careGiveObjbefore:
          "Èske gen yon moun nan Kay la ki gen depans pou swen pou timoun oswa adilt? Sa enkli depans pou ",
        careGiveObjtrigger: "transpòte",
        careGiveObjAfter:
          " depandan(yo) pou ale ak/oswa soti lakay yon founisè swen.",
        careGiveHelp:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        questionClearOption: "Pesonn",
        kidsandadultSubheader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        costBetween: "pa",
        costBetween1: " ",
        weekMonthOptions: ["Mwa", "Semèn"],
        costOptions: ["Semèn", "Mwa", "Ane"],
        yesnoOptions: ["Wi", "Non"],
        targetValueYes: "Wi",
        noofAppointment: "Tanpri chwazi kantite randevou",
        addressProvider: "Ki adrès founisè a?",
        freqTripoptions: ["Semèn", "Mwa", "Ane"],
        unitMonthTargetValue: { unit: "Mwa" },
        childSupTitle: "Depans pou Sipò Timoun",
        supportCostHeader: "Depans pou Sipò Timoun",
        childSupportQlegalbefore:
          "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
        childSupportQlegaltrigger: "Sipò Timoun",
        childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
        childSupportHelp:
          "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
        medicalTitle: "Depans medikal",
        medicalInlineSeethisFull: "Gade lis konplè sa a",
        medicalInlineEligibleMedical: " depans medikal ki kalifye",
        medicalHeader: "Pale nou konsènan depans medikal ou yo",
        medicalSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        medicalExpBefore:
          "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
        medicalExpTrigger: " transpò",
        medicalExpAfter:
          ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
        medicalExphelp:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        medicalInhelp: "Gade lis konplè sa a",
        medicalInhelpEligible: " depans medikal ki kalifye",
        medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
        zipErrmsg: "Tanpri antre yon kòd postal ki valid",
        signSubmitLabel: "Siyen epi soumèt",
        signSubmitHeader: "Siyen epi soumèt",
        signSubmitSub: "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
        subSectionLabel: "Soumèt",
        submitLabelFinal: "Aplikasyon SNAP ou a te soumèt!",
        tellusAbout: "Pale nou konsènan depans ou yo pou Sipò Timoun",
        moneyYougettitle:
          "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
        tripCountErrMsg: "Tanpri antre kantite vwayaj ki valid",
        tripCountErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
        tripCostErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou frè vwayaj la",
        optionErrMsg: "Tanpri chwazi yon opsyon anba a",
        amountErrMsg: "Tanpri antre yon montan ki valid",
      };
    default:
      return;
  } //switch END
}

export function applySectionStaticMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        aboutTitle: "About " + name,
        aboutMemberTitle: "About Member " + name,
        labelMiddle: "What is " + name + "'s middle name?",
        labelLastName: "What is " + name + "'s last name?",
        labelRelation: "What is " + name + "'s relationship to you?",
        dateOfBirthQLabel: "What is " + name + "'s date of birth?",
        genderQLabel: "What is " + name + "'s gender?",
        UScitizenLabelForTanf: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        UScitizenLabelForSnap: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for SNAP assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        UScitizenLabelForCombo: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for SNAP/TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: "What is " + name + "'s Social Security Number?",
        immigrationStatusLabel:
          "Do " + name + " hold a satisfactory immigration status?",
        personMiddleName: "What is this member's middle name?",
        personLastName: "What is this member's last name?",
        personRelation: "What is this member's relationship to you?",
        personDobLabel: "What is this member's date of birth?",
        personGenderLabel: "What is this member's gender?",
        personSSNLabel: "What is this member's Social Security Number?",
        personEthnicityLabel: "What is your ethnicity?",
        personRaceLabel: "What is your race?",
        personUScitizenLabel: {
          textBefore: "Is this Person a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        personImmigrationStatusLabel:
          "Do you hold a satisfactory immigration status?",
      };
    case ES:
      return {
        aboutTitle: "Sobre " + name,
        aboutMemberTitle: "Acerca de miembro " + name,
        labelMiddle: "¿Cuál es el segundo nombre de  " + name + "?",
        labelLastName: "¿Cuál es el apellido de " + name + "?",
        labelRelation: "¿Cuál es la relación de " + name + "con usted?",
        dateOfBirthQLabel: "¿Cuál es la fecha de nacimiento de " + name + "?",
        genderQLabel: "¿Cuál es el sexo de " + name + "?",
        UScitizenLabelForTanf: {
          textBefore: "¿Es " + name + " un ",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
          },
          UScitizenLabelForSnap: {
            textBefore: "es" + name + " uno ",
            triggerText: "Ciudadana estadounidense",
            textAfter: "?",
            helpText:
              "Para ser elegible para recibir asistencia SNAP, la persona debe ser ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
          },
          UScitizenLabelForCombo: {
            textBefore: "es " + name + " uno ",
            triggerText: "Ciudadana estadounidense",
            textAfter: "?",
            helpText:
              "Para ser elegible para recibir asistencia SNAP/TANF, la persona debe ser ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
          },
        ethnicityLabel: "¿Cuál es el origen étnico de " + name + "?",
        raceLabel: "¿Cuál es la raza de " + name + "?",
        ssnQLabel: "¿Cuál es el número de seguro social de " + name + "?",
        immigrationStatusLabel:
          "¿Tiene " + name + " un estatus migratorio satisfactorio?",
        personMiddleName: "¿Cuál es el segundo nombre de esta persona?",
        personLastName: "¿Cuál es el apellido de esta persona?",
        personRelation: "¿Cuál es la relación de esta persona con usted?",
        personDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        personGenderLabel: "¿Cuál es el sexo de esta persona?",
        personSSNLabel: "¿Cuál es el número de seguro social de esta persona?",
        personEthnicityLabel: "Cuál es tu etnia?",
        personRaceLabel: "¿Cuál es su raza?",
        personUScitizenLabel: {
          textBefore: "¿Es esta persona un",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        },
        personImmigrationStatusLabel:
          "¿Tiene un estatus migratorio satisfactorio?",
      };
    case PT:
      return {
        aboutTitle: "Sobre " + name,
        aboutMemberTitle: "Sobre o Membro " + name,
        labelMiddle: "Qual é o nome do meio de " + name + "?",
        labelLastName: "Qual é o último nome de " + name + "?",
        labelRelation:
          "Qual é o grau de relacionamento de " + name + " com você?",
        dateOfBirthQLabel: "Qual é a data de nascimento de" + name + "?",
        genderQLabel: "Qual é o gênero de " + name + "?",
        UScitizenLabel: name + " tem cidadania americana?",
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: "Qual é o número do seguro social de " + name + "?",
        personMiddleName: "Qual é o nome do meio dessa pessoa?",
        personLastName: "Qual é o último nome dessa pessoa?",
        personRelation:
          "Qual é o grau de relacionamento dessa pessoa com você?",
        personDobLabel: "Qual é a data de nascimento dessa pessoa?",
        personGenderLabel: "Qual é o gênero dessa pessoa?",
        personSSNLabel: "Qual é o número do seguro social dessa pessoa?",
      };
    case ZH: //chinees
      return {
        aboutTitle: "关于 " + name,
        aboutMemberTitle: "关于會員 " + name,
        labelMiddle: name + "的中间名?",
        labelLastName: name + "的姓氏?",
        labelRelation: name + " 与您的关系?",
        dateOfBirthQLabel: name + " 的出生日期? ",
        genderQLabel: name + " 的性别?",
        UScitizenLabel: name + " 是否是美国公民？",
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: name + " 的社保号?",
        personMiddleName: "此人的中间名?",
        personLastName: "此人的姓氏?",
        personRelation: "此人与您的关系?",
        personDobLabel: "此人的出生日期? ",
        personGenderLabel: "此人的性别是？",
        personSSNLabel: "此人的社会安全号码是？",
      };
    case VI:
      return {
        aboutTitle: "Thông tin về  " + name,
        aboutMemberTitle: "Thông tin về thành viên " + name,
        labelMiddle: "Tên lót của  " + name + " là gì?",
        labelLastName: "Họ của " + name + " là gì?",
        labelRelation: "Mối quan hệ của " + name + " với quý vị là gì?",
        dateOfBirthQLabel: "Ngày sinh của " + name + " là gì?",
        genderQLabel: "Giới tính của " + name + " là gì?",
        UScitizenLabel: name + " có là Công dân Hoa Kỳ không?",
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: "Số An sinh Xã hội của " + name + " là gì?",
        personMiddleName: "Tên lót của người này là gì?",
        personLastName: "Họ của người này là gì?",
        personRelation: "Mối quan hệ của người này với quý vị là gì?",
        personDobLabel: "Ngày sinh của người là gì?",
        personGenderLabel: "Giới tính của người này là gì?",
        personSSNLabel: "Số An sinh Xã hội của người này là gì?",
      };
    case HI:
      return {
        aboutTitle: "Konsènan " + name,
        aboutMemberTitle: "Konsènan Manm " + name,
        labelMiddle: "Ki " + name + " (yo)dezyèm prenon?",
        labelLastName: "Ki siyati " + name + "?",
        labelRelation: "Ki relasyon " + name + " genyen ak ou?",
        dateOfBirthQLabel: "Ki dat nesans " + name + "?",
        genderQLabel: "Ki sèks " + name + "?",
        UScitizenLabel: "Èske " + name + " se yon sitwayen Ameriken?",
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: "Ki Nimewo Sekirite Sosyal " + name + "?",
        personMiddleName: "Ki dezyèm prenon moun sa a?",
        personLastName: "Ki siyati moun sa a?",
        personRelation: "Ki relasyon moun sa genyen ak ou?",
        personDobLabel: "Ki dat nesans moun sa a?",
        personGenderLabel: "Ki sèks moun sa a?",
        personSSNLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
      };
    default:
      return;
  }
}

export function screeningMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Am I eligible for SNAP",
        header: "Am I eligible for SNAP",
        subHeader: "Find out if you may be eligible in 10 seconds.",
        subsections: [
          {
            title: "You may be eligible for SNAP benefits.",
            header: "You may be eligible for SNAP benefits.",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL:
              "How many people live in your household, including you?",
            propsINLINEHELP:
              "If you plan to purchase and prepare meals with them more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not purchase and prepare meals with them. Include the children for whom you want to apply and their brothers and sisters.",
            PropsERROR_MESSAGE:
              "Please select how many people live in your household",
          },
          {
            propsLABELAge: "Is anyone age 60 or older?",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Yes",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "No",
              },
            ],

            propsERROR_MESSAGE: "Please answer if anyone is age 60 or older",
          },
          {
            [Props.NAME]: "disabilities",

            propsLABEL_OBJ_BEFORE:
              "Does anyone in the household have a physical or mental ",
            propsLABEL_OBJ_TRIGGER: "disability",
            propsLABEL_OBJ_AFTER: "?",
            propsLABEL_OBJ_HELP:
              "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Yes",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "No",
              },
            ],
            propsERROR_MESSAGE:
              "Please answer if anyone in the household has a physical or mental disability",
          },
          {
            propsLABEL_OBJ_BEFORE: "What is the total ",
            propsLABEL_OBJ_TRIGGER: "gross income",
            propsLABEL_OBJ_AFTER: " for your household?",
            propsLABEL_OBJ_HELP:
              "Gross income is the total income before anything is taken out, such as taxes or health insurance.",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "per",
            unitsPropsUNITS: ["Week", "Month", "Year"],
            optionsPropsTARGET_VALUE: { unit: "Monthly" },
            propsERROR_MESSAGE:
              "Please enter the total gross income for your household and gross income frequency",
            propsERROR_MESSAGE_FREQ: "Please select the gross income frequency",
            propsERROR_MESSAGE_INCM:
              "Please enter the total gross income for your household",
          },
        ],
      };

    case ES:
      return {
        title: "¿Soy elegible para SNAP?",
        header: "¿Soy elegible para SNAP?",
        subHeader: "Averigüe si es posible que sea elegible en 10 segundos.",
        subsections: [
          {
            title:
              "Es posible que sea elegible para recibir beneficios de SNAP.",
            header:
              "Es posible que sea elegible para recibir beneficios de SNAP.",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL:
              "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
            propsINLINEHELP:
              "Si planea comprar y preparar comidas con ellos más de 2/3 de sus comidas con otros, deben estar en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar, incluso si usted no compra y prepara comidas con ellos. Incluya a los niños para quienes desea postularse y a sus hermanos y hermanas",
            PropsERROR_MESSAGE:
              "Seleccione cuántas personas viven en su núcleo familiar",
          },
          {
            propsLABELAge: "¿Alguna persona tiene 60 años o más? ",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Sí",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "No",
              },
            ],
            propsERROR_MESSAGE:
              "Responda si alguna de ellas tiene 60 años o más",
          },
          {
            [Props.NAME]: " discapacidad",

            propsLABEL_OBJ_BEFORE: "¿Alguna persona tiene una ",
            propsLABEL_OBJ_TRIGGER: " discapacidad",
            propsLABEL_OBJ_AFTER: " mental o física ?",
            propsLABEL_OBJ_HELP:
              "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Sí",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "No",
              },
            ],
            propsERROR_MESSAGE:
              "Responda si alguna persona del núcleo familiar tiene una discapacidad física o mental",
          },
          {
            propsLABEL_OBJ_BEFORE: "¿Cuál es el ",
            propsLABEL_OBJ_TRIGGER: " ingreso bruto",
            propsLABEL_OBJ_AFTER: " de su hogar? ",
            propsLABEL_OBJ_HELP:
              "El ingreso bruto es el ingreso total antes de cualquier deducción, como impuestos o seguro médico.",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "por",
            unitsPropsUNITS: ["Semana", "Mes", "Año"],
            optionsPropsTARGET_VALUE: { unit: "Mensual" },
            propsERROR_MESSAGE:
              "Ingrese el ingreso total bruto y la frecuencia del ingreso bruto de su núcleo familiar",
            propsERROR_MESSAGE_FREQ:
              "Seleccione la frecuencia del ingreso bruto",
            propsERROR_MESSAGE_INCM:
              "Ingrese el ingreso total bruto de su núcleo familiar",
          },
        ],
      };

    case PT:
      return {
        title: "Sou elegível para SNAP?",
        header: "Sou elegível para SNAP?",
        subHeader: "Descubra se você é elegível em 10 segundos.",
        subsections: [
          {
            title: "Você é elegível ao benefício SNAP.",
            header: "Você é elegível ao benefício SNAP.",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL: "Quantas pessoas vivem na sua casa, incluindo você?",
            propsINLINEHELP:
              "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
            PropsERROR_MESSAGE: "Selecione quantas pessoas vivem na sua casa",
          },
          {
            propsLABELAge: "Alguém tem 60 anos de idade ou mais? ",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Sim",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Não",
              },
            ],
            propsERROR_MESSAGE: "Responda se alguém tem 60 anos ou mais",
          },
          {
            [Props.NAME]: "disabilities",

            propsLABEL_OBJ_BEFORE: "Alguém sofre de algum tipo de deficiência ",
            propsLABEL_OBJ_TRIGGER: "física",
            propsLABEL_OBJ_AFTER: " ou mental?",
            propsLABEL_OBJ_HELP:
              "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Sim",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Não",
              },
            ],
            propsERROR_MESSAGE:
              "Responda se alguém na casa tem algum tipo de deficiência física ou mental",
          },
          {
            propsLABEL_OBJ_BEFORE: "Qual é a total ",
            propsLABEL_OBJ_TRIGGER: "renda bruta",
            propsLABEL_OBJ_AFTER: " da sua família?",
            propsLABEL_OBJ_HELP:
              "Renda bruta é o valor total sem descontos, como impostos ou plano de saúde.",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "por",
            unitsPropsUNITS: ["Semana", "Mês", "Ano"],
            optionsPropsTARGET_VALUE: { unit: "Mês" },
            propsERROR_MESSAGE:
              "Insira a renda bruta da sua casa e a frequência dela.",
            propsERROR_MESSAGE_FREQ: "Insira a frequência da renda bruta",
            propsERROR_MESSAGE_INCM: "Insira a renda bruta da sua casa",
          },
        ],
      };

    case ZH:
      return {
        title: "我有资格参加补充营养资助计划SNAP吗？",
        header: "我有资格参加补充营养资助计划SNAP吗？",
        subHeader: "只需10秒即可获知您是否有申请资格.",
        subsections: [
          {
            title: "您符合SNAP补助资格。",
            header: "您符合SNAP补助资格。",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL: "包括您自己在内，您家庭中有几人？",
            propsINLINEHELP:
              "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭。",
            PropsERROR_MESSAGE: "请选择您的家庭里生活了多少人",
          },
          {
            propsLABELAge: "是否有60岁以上的家人？ ",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "是",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "否",
              },
            ],
            propsERROR_MESSAGE: "请回答是否有任何人年满 60",
          },
          {
            [Props.NAME]: "disabilities",

            propsLABEL_OBJ_BEFORE: "是否有人患有身体或精神 ",
            propsLABEL_OBJ_TRIGGER: "残疾",
            propsLABEL_OBJ_AFTER: "?",
            propsLABEL_OBJ_HELP:
              "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾。",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "是",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "否",
              },
            ],
            propsERROR_MESSAGE: "请回答家庭中是否有人存在生理或心理残疾",
          },
          {
            propsLABEL_OBJ_BEFORE: "您的家庭 ",
            propsLABEL_OBJ_TRIGGER: "总收入",
            propsLABEL_OBJ_AFTER: " 是多少？",
            propsLABEL_OBJ_HELP:
              "总收入是指所有扣费之前的收入，如税收或健康保险。",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "每",
            unitsPropsUNITS: ["周", "月", "年"],
            optionsPropsTARGET_VALUE: { unit: "月" },
            propsERROR_MESSAGE: "请输入您的家庭的合计总收入和总收入频率。",
            propsERROR_MESSAGE_FREQ: "请选择总收入频率。",
            propsERROR_MESSAGE_INCM: "请输入您的家庭合计总收入。",
          },
        ],
      };

    case VI:
      return {
        title: "Tim-hieu neu ban co-the du dieu-kien.",
        header: "Tim-hieu neu ban co-the du dieu-kien.",
        subHeader: "Tìm hiểu xem quý vị có đủ điều kiện không trong 10 giây.",
        subsections: [
          {
            title:
              "Quý vị có thể có đủ điều kiện để nhận các khoản trợ cấp SNAP.",
            header:
              "Quý vị có thể có đủ điều kiện để nhận các khoản trợ cấp SNAP.",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL:
              "Có bao nhiêu người sống trong hộ gia đình của quý vị, bao gồm cả quý vị?",
            propsINLINEHELP:
              "Nếu quý vị mua hoặc nấu hơn 2/3 bữa ăn của mình cùng với những người khác, họ phải ở chung hộ gia đình của quý vị. Nếu vợ/chồng quý vị hoặc con cái dưới 22 tuổi sống cùng quý vị, họ phải ở cùng hộ gia đình ngay cả khi nếu quý vị không mua và nấu các bữa ăn với họ.",
            PropsERROR_MESSAGE: "Chọn số hộ thành viên",
          },
          {
            propsLABELAge: "Có ai ở độ tuổi 60 hoặc già hơn không?",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Có",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Không",
              },
            ],
            propsERROR_MESSAGE: "Ghi nếu có thành viên 60 tuổi trở lên", //not there in excel
          },
          {
            [Props.NAME]: "disabilities",

            propsLABEL_OBJ_BEFORE: "Có ai bị ", //not there in excel
            propsLABEL_OBJ_TRIGGER: "khuyết tật",
            propsLABEL_OBJ_AFTER: " về thể chất hoặc tinh thần không?",
            propsLABEL_OBJ_HELP:
              "SNAP đòi hỏi một người khuyết tật phải được liên bang xác nhận để được xem xét. Ví dụ, những người nhận SSI là những người khuyết tật được liên bang xác nhận.",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Có",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Không",
              },
            ],
            propsERROR_MESSAGE:
              "Ghi nếu có thành viên bị khuyết tật hoặc tâm thần",
          },
          {
            propsLABEL_OBJ_BEFORE: "Tổng ",
            propsLABEL_OBJ_TRIGGER: "thu nhập gộp",
            propsLABEL_OBJ_AFTER: " cho hộ gia đình của quý vị là gì?",
            propsLABEL_OBJ_HELP:
              "Tổng thu nhập gộp là tổng thu nhập trước khi trừ các khoản khác, chẳng hạn như thuế hoặc bảo hiểm y tế.",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "mỗi",
            unitsPropsUNITS: ["Tuần", "Tháng", "Năm"],
            optionsPropsTARGET_VALUE: { unit: "Tháng" },
            propsERROR_MESSAGE:
              "Điền tổng thu nhập của hộ và tần số tổng thu nhập.",
            propsERROR_MESSAGE_FREQ: "Chọn tần số tổng thu nhập",
            propsERROR_MESSAGE_INCM: "Điền tổng thu nhập của hộ",
          },
        ],
      };

    case HI:
      return {
        title: "Èske m kalifye pou SNAP?",
        header: "Èske m kalifye pou SNAP?",
        subHeader: "Chache konnen si w ka elijib nan 10 segonn.",
        subsections: [
          {
            title: "Ou ka kalifye pou benefis SNAP.",
            header: "Ou ka kalifye pou benefis SNAP.",
          },
        ],
        completed: false,
        questions: [
          {
            propsLABEL: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
            propsINLINEHELP:
              "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
            PropsERROR_MESSAGE:
              "Tanpri chwazi konbyen moun k ap viv nan fwaye w la",
          },
          {
            propsLABELAge: "Èske gen nenpòtn moun ki gen laj 60 an oswa plis?",
            [Props.TYPE]: Types.RADIO,
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Wi",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Non",
              },
            ],

            propsERROR_MESSAGE:
              "Tanpri reponn si nenpòt moun gen laj 60 an oswa plis",
          },
          {
            [Props.NAME]: "andikap",

            propsLABEL_OBJ_BEFORE: "Èske nenpòt moun nan fwaye a gen yon ",
            propsLABEL_OBJ_TRIGGER: "andikap",
            propsLABEL_OBJ_AFTER: " fizik oswa mantal?",
            propsLABEL_OBJ_HELP:
              "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
            optionsPropsOPTIONS: [
              {
                [OptionsProps.OPTION_VALUE]: "Yes",
                [OptionsProps.OPTION_DISPLAY]: "Wi",
              },
              {
                [OptionsProps.OPTION_VALUE]: "No",
                [OptionsProps.OPTION_DISPLAY]: "Non",
              },
            ],
            propsERROR_MESSAGE:
              "Tanpri reponn si nenpòt moun nan fwaye a gen yon andikap fizik oswa mantal",
          },
          {
            propsLABEL_OBJ_BEFORE: "Ki ",
            propsLABEL_OBJ_TRIGGER: "revni brit",
            propsLABEL_OBJ_AFTER: " total pou fwaye w la?",
            propsLABEL_OBJ_HELP:
              "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",

            propsTYPE: "units",
            unitsPropsINBETWEEN: "Pa",
            unitsPropsUNITS: ["Semèn", "Mwa", "Ane"],
            optionsPropsTARGET_VALUE: { unit: "Mwa" },
            propsERROR_MESSAGE:
              "Tanpri antre revni brit total pou fwaye w la ak frekans revni brit ou",
            propsERROR_MESSAGE_FREQ: "Tanpri chwazi frekans revni brit la",
            propsERROR_MESSAGE_INCM:
              "Tanpri antre revni brit total pou fwaye w la",
          },
        ],
      };

    default:
      return;
  } //Switch End
}


function selectLabel(code){
  switch(code){
    case "MDENT":
      return incomeSectionMultilanguageLabels(selectedLanguage).doctorOrDentistOptions;
    case "HSPTL":
      return incomeSectionMultilanguageLabels(selectedLanguage).hospitalOrNursingHomeOptions;
    case "MEDCTN":
      return incomeSectionMultilanguageLabels(selectedLanguage).prescriptionDrugsOptions;
    case "OTCMED":
      return incomeSectionMultilanguageLabels(selectedLanguage).overTheCounterMedicineOptions;
    case "HINSPR":
      return incomeSectionMultilanguageLabels(selectedLanguage).healthInsurancePremiumsOptions;
    case "MSUPPL":
      return incomeSectionMultilanguageLabels(selectedLanguage).medicalSuppliesOptions;
    case "MDCOSH":
      return incomeSectionMultilanguageLabels(selectedLanguage).medicaidCostSharingOptions; 
    case "DHP":
      return incomeSectionMultilanguageLabels(selectedLanguage).medicaidCostSharingOptions; 
    case "GLSSES":
      return incomeSectionMultilanguageLabels(selectedLanguage).prescriptionEyeGlasesOptions; 
    case "TRSPRT":
      return incomeSectionMultilanguageLabels(selectedLanguage).transportationOrLoadingForMedicalServicesOptions; 
    case "HHACC":
      return incomeSectionMultilanguageLabels(selectedLanguage).HudHomeHealthAideOptions; 
    case "DOG":
      return incomeSectionMultilanguageLabels(selectedLanguage).serviceAnimalOptions;
    case "OTHER":
      return incomeSectionMultilanguageLabels(selectedLanguage).otherMedicalExpenseOptions;         
    default:
      return "-"
  }
}
export function resourcesSectionMultilanguageLabels(language,name) {
  switch (language) {
    case EN:
      return {
        resourceTitle: "Resources",
        resourceHeader: "About Your Household’s Resources",
        aboutResourceBefore: "Does anyone in your household have available ",
        aboutResourceTrigger: "resources",
        aboutResourceAfter:" ?",
        resourceSubtitle:"Resources include cash on hand, bank accounts, stocks, savings bonds, lottery, and gambling winnings of $4,250 or more, etc.",
        resourceSubSectiontitle:"Please provide information on the amount of available resources you and your household have.",
        resourceHelp:
          "Select all that apply.",
        questionClearOption: "Decline to answer",
        noResourcesToReportOption: "No resources to report",
        heading: name + " " + "- Resource Details",
        combineResourcesoptions: [
          "Cash on Hand",
          "Savings Accounts",
          "Checking Accounts",
          "Stocks",
          "Bonds",
          "Retirement Accounts",
          "Trust Funds",
          "Prepaid Burial Account",
          "Other Liquid Resources"
        ],
        addcombineResourcesoptions: [
          "Cash on Hand",
          "Savings Account",
          "Checking Account",
          "Stocks",
          "Bonds",
          "Retirement Account",
          "Trust Fund",
          "Prepaid Burial Account",
          "Liquid Resource"
        ],
        combineResourcesOptionsLabelsSrs: [
          {line1 : "of cash on hand ",line2: " has access to?"},
          {line1 :" ",line2:" has in the savings account"},
          {line1 :" ",line2:" has in the checking account"},
          {line1 :"of stocks ",line2: " has?"},
          {line1 :"of bonds ",line2: " has?"},
          {line1 :" ",line2:" has in the retirement account?"},
          {line1 :" ",line2:" has in the trust funds?"},
          {line1 :" ",line2:" has in the prepaid burial account?"},
          {line1 :"of other liquid resources ",line2:" has?"}
        ],
        combineResourcesOptionsLabels: [
          "of cash on hand you have access to? (Required)",
          "savings account",
          "checking account",
          "of stocks you have? (Required)",
          "of bonds you have? (Required)",
          "you have in the retirement account? (Required)",
          "of trust funds you have? (Required)",
          "you have in the prepaid burial account? (Required)",
          "of other liquid resources you have? (Required)"
        ],
        amountLabel : "What is the amount ",
        templateOneLabel1 : "What is the amount " + name,
        templateOneLabel2 : "Additional Information",
        templateOneLabel3 : "Enter additional information here...",
        templateTwoLabel1 : "What is the amount you have in the " + name + " ? (Required)",
        templateTwoLabel2 : "What is the bank name? (Required)",
        templateTwoLabel3 : "What is the account number?",
        templateTwoLabel4 : "Is this a joint account? (Required)",
        templateTwoLabel5 : "What is the name of the joint account holder? (Required)",
        templateOneLabe31 : "What is the amount " + name,
        templateOneLabe32 : "What is the name of the bank/brokerage? (Required)",
        templateTwoLabelNotReq1 : "What is the amount " + name + " ?",
        templateTwoLabelNotReq2 : "What is the bank name?",
        templateTwoLabel3 : "What is the account number?",
        templateTwoLabelNotReq4 : "Is this a joint account?",
        templateTwoLabelNotReq5 : "What is the name of the joint account holder?",
        templateOneLabeNotReq32 : "What is the name of the bank/brokerage?",
        optionErrMsg: "Please select an option below.",
        amountErrMsg: "Please enter an amount.",
        bankErrMsg: "Please enter the bank name.",
        bankBrokageErrMsg: "Please enter the name of the bank/brokerage.",
        jointAccounterrMsg: "Please select an option.",
        jointAccountHolderErrMsg : "Please enter the name of the joint account holder.",
        optionYesNo: ["Yes", "No"],
        resourcesSummaryTitle: "Resources Summary",
        resourcesSummaryHeader: "Resources Summary",
        resourcesChangedQuestion: "Have your household resources changed? (Required)",
        resourcesDetailsHeader: name + " - Resource Details",
        resourcesDetailsLabel: "What type(s) of resources does " + name +  " have?",
        tempalteOneAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : "",datachangeCode: "NEW"},
        templateTwoAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",datachangeCode: "NEW"},
        templateThreeAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",datachangeCode: "NEW"},
        dataChangeLabels: {
          "NEW" : "New",
          "NOCHNG" : "NOCHNG",
          "CHANGE" : "Changed",
          "REMOVE" : "Removed"
        },
        templateToSave : {
          amount: "",
          additionalInfo: "",
          bankBrokerageName: "",
          accountNumber: "",
          isJointAccount: "",
          datachangeCode: "",
          jointAcntHolderName: ""
        },
        addAnotherResourcesButton : "Add the resources of another household member",
        resourcesDescLabel : "Resources include cash on hand, bank accounts, stocks, savings bonds, lottery, and gambling winnings of $4,250 or more, etc. ",
        noResourcesText: "The total amount of resources used in your monthly SNAP budget is $0."
      };
    case ES: //SPANISH
      return {
        resourceTitle: "Recursos",
        resourceHeader: "Acerca de los recursos de su hogar",
        noResourcesToReportOption: "No hay recursos para informar",
        aboutResourceBefore: "¿Alguien en su hogar tiene ",
        aboutResourceTrigger: "recursos",
        aboutResourceAfter:" disponibles?",
        resourceSubtitle:"Los recursos incluyen efectivo disponible, cuentas bancarias, acciones, bonos de ahorro, ganancias de lotería y juegos de azar de $4250 o más, etc.",
        resourceSubSectiontitle:"Proporcione información sobre la cantidad de recursos disponibles que usted y su hogar tienen.",
        resourceHelp:
          "Seleccione todas las que correspondan.",
        questionClearOption: "Negarse a contestar",
        heading: name + " " + "- Detalles del recurso",
        combineResourcesoptions: [
          "Dinero en mano",
          "Guardando cuentas",
          "Revisando cuentas",
          "Cepo",
          "Cautiverio",
          "Cuentas de jubilación",
          "Fondos fiduciarios",
          "Cuenta de entierro prepaga",
          "Otros recursos líquidos"
        ],
        addcombineResourcesoptions: [
          "Dinero en mano",
          "Guardando cuentas",
          "Revisando cuentas",
          "Cepo",
          "Cautiverio",
          "Cuentas de jubilación",
          "Fondos fiduciarios",
          "Cuenta de entierro prepaga",
          "Otros recursos líquidos"
        ],
        combineResourcesOptionsLabelsSrs: [
          {line1 : "de efectivo en mano ",line2: " tiene acceso a?"},
          {line1 :" ",line2:" tiene en las Cuentas de Ahorro"},
          {line1 :" ",line2:" tiene en las Cuentas Corrientes"},
          {line1 :"de acciones ",line2: " tiene acceso a?"},
          {line1 :"de bonos ",line2: " tiene acceso a?"},
          {line1 :" ",line2:" tiene en la cuenta de jubilación?"},
          {line1 :" ",line2:" tiene en los fondos fiduciarios?"},
          {line1 :" ",line2:" tiene en la cuenta de entierro prepago?"},
          {line1 :"de otros recursos líquidos ",line2:" ¿tiene?"}
        ],
        combineResourcesOptionsLabels: [
          "¿Cuánto dinero en efectivo tiene disponible? (Requerido)",
          "Guardando cuentas",
          "Revisando cuentas",
          "de acciones que tienes? (Requerido)",
          "de bonos que tienes? (Requerido)",
          "tienes en la cuenta de jubilación? (Requerido)",
          "tienes en los fondos fiduciarios? (Requerido)",
          "tienes en la cuenta de entierro prepago? (Requerido)",
          "¿Qué otros recursos líquidos tienes? (Requerido)"
        ],
        amountLabel : "¿Cuál es la cantidad ",
        templateOneLabel1 : "¿Cuál es la cantidad " + name,
        templateOneLabel2 : "información adicional",
        templateOneLabel3 : "Ingrese información adicional aquí...",
        templateTwoLabel1 : "¿Cuál es la cantidad que tienes en el " + name + " ? (Requerido)",
        templateTwoLabel2 : "¿Cuál es el nombre del banco? (Requerido)",
        templateTwoLabel3 : "¿Cuál es el número de cuenta?",
        templateTwoLabel4 : "¿Es esta una cuenta conjunta? (Requerido)",
        templateTwoLabel5 : "¿Cuál es el nombre del titular de la cuenta conjunta? (Requerido)",
        templateOneLabe31 : "¿Cuál es la cantidad " + name,
        templateOneLabe32 : "¿Cuál es el nombre del banco/correduría? (Requerido)",
        optionErrMsg: "Por favor, seleccione una opción a continuación.",
        amountErrMsg: "Por favor ingrese una cantidad.",
        bankErrMsg: "Por favor ingrese el nombre del banco.",
        bankBrokageErrMsg: "Por favor ingrese el nombre del banco/correduría.",
        jointAccounterrMsg: "Por favor seleccione una opción.",
        jointAccountHolderErrMsg : "Ingrese el nombre del titular de la cuenta conjunta.",
        optionYesNo: ["Sí", "No"],
        resourcesSummaryTitle: "Resumen de recursos",
        resourcesSummaryHeader: "Resumen de recursos",
        resourcesChangedQuestion: "¿Han cambiado los recursos de su hogar? (Requerido)",
        resourcesDetailsHeader: name + " - Detalles de recursos",
        resourcesDetailsLabel: "¿Qué tipo(s) de recursos " + name +  " ¿tener?",
        tempalteOneAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : "",datachangeCode: "NEW"},
        templateTwoAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",datachangeCode: "NEW"},
        templateThreeAnswerObj: { type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",datachangeCode: "NEW"},
        dataChangeLabels: {
          "NEW" : "NEW",
          "NOCHNG" : "NOCHNG",
          "CHANGE" : "CHANGE",
          "REMOVE" : "REMOVE"
        },
        templateToSave : {
          amount: "",
          additionalInfo: "",
          bankBrokerageName: "",
          accountNumber: "",
          isJointAccount: "",
          datachangeCode: "",
          jointAcntHolderName: ""
        },
        addAnotherResourcesButton : "Agregar los recursos de otro miembro del hogar",
        resourcesDescLabel : "Los recursos incluyen efectivo disponible, cuentas bancarias, acciones, bonos de ahorro, ganancias de lotería y juegos de azar de $4250 o más, etc.",
        templateTwoLabelNotReq4 : "¿Es esta una cuenta conjunta?",
        templateTwoLabelNotReq2: "¿Cuál es el nombre del banco?",
        templateOneLabeNotReq32: "¿Cuál es el nombre del banco/correduría?",
        noResourcesText: "La cantidad total de recursos utilizados en su presupuesto mensual de SNAP es $0."
      };
    default:
      return;
  } //switch END
}
