import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { Link } from "react-router-dom";
import Icon from "../../../utils/components/dtaIcon";
import DTASelect from "../../../utils/components/dtaSelect";
import { mhProviderSNAPGAP } from "../../provider/providerText";
import { removeMultilanguageSelect } from "../../../redux/provider/providerMyClientsActions";
import {
  getMhproviderClientInfo,
  mhproviderClientInfo,
  updateSnapOutReachClientInfo,
  removePropsValues,
  memberSearchNamesClientInfo,
  cancelClientCaseInfo,
  refreshMhproviderClientInfo,
} from "../../../redux/mhProvider/mhProviderAction";
import * as formatters from "../../../utils/components/utilities/formatters";
import MemberSearch from "./memberSearch";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as roleProps from "../../../utils/constants/rolesConstants";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import * as types from "../../../utils/constants/types";
import * as TextProps from "../../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class SnapOutreach extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      isSnapForm: TextProps.VALUE_FALSE,
      disableSubmit: TextProps.VALUE_FALSE,
      outReachStatus: TextProps.VALUE_FALSE,
      statusError: TextProps.VALUE_FALSE,
      commentError: TextProps.VALUE_FALSE,
      memberSearchPage: TextProps.VALUE_FALSE,
      firstName: "",
      lastName: "",
      status: "",
      relationStatus: "",
      comments: "",
      households: [],
      nonHouseHolds: [],
      clientInfo: "",
      getClientInfoError: TextProps.VALUE_FALSE,
      mhSubmitError: TextProps.VALUE_TRUE,
      mhSubmitErrorMessage: "",
      clientInfoNotFound: TextProps.VALUE_FALSE,
      hideCloseIcon: TextProps.VALUE_FALSE,
      relatedCase: TextProps.VALUE_TRUE,
      relatedClient: TextProps.VALUE_FALSE,
      householdsMembers: [],
      outRachHomePage: TextProps.VALUE_TRUE,
    };
  }

  componentDidMount() {
    this.props.removeMultilanguageSelect();
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    if (nextProps.mhProvider !== undefined) {
      if (nextProps.mhProvider.resetClientStatus === undefined) {
        if (
          nextProps.mhProvider.clientInfo !== undefined &&
          nextProps.mhProvider.clientInfo !== "client info not found"
        ) {
          this.setState({
            isSnapForm: TextProps.VALUE_TRUE,
            clientInfo: nextProps.mhProvider.clientInfo,
            comments: "",
            householdsMembers: [],
            households: [],
            nonHouseHolds: [],
            clientInfoNotFound: TextProps.VALUE_FALSE,
          });
        }
        if (nextProps.mhProvider.clientInfo === "client-info-not-found") {
          this.setState({
            isSnapForm: TextProps.VALUE_FALSE,
            clientInfoNotFound: TextProps.VALUE_TRUE,
            getClientInfoError: TextProps.VALUE_FALSE,
            status: "",
            comments: "",
          });
        }
        if (nextProps.mhProvider.updatedClientStatus !== undefined) {
          if (
            nextProps.mhProvider.updatedClientStatus === "client-info-updated"
          ) {
            this.setState({
              disableSubmit: TextProps.VALUE_FALSE,
              outReachStatus: TextProps.VALUE_TRUE,
              memberSearchPage: TextProps.VALUE_FALSE,
              hideCloseIcon: TextProps.VALUE_TRUE,
              mhSubmitError: TextProps.VALUE_FALSE,
              relationStatus: "",
              householdsMembers: [],
              households: [],
              nonHouseHolds: [],
              clientInfoNotFound: TextProps.VALUE_FALSE,
            });
            setTimeout(() => {
              this.mhProviderModal.showBlur(
                "success",
                mhProviderSNAPGAP(types.ENGLISH).outreachSuccess
              );
            }, 5);

            setTimeout(() => {
              this.setState({
                outReachStatus: TextProps.VALUE_FALSE,
                isSnapForm: TextProps.VALUE_TRUE,
                memberSearchPage: TextProps.VALUE_FALSE,
                householdsMembers: [],
                households: [],
                nonHouseHolds: [],
                clientInfoNotFound: TextProps.VALUE_FALSE,
              });
            }, 5000);

            setTimeout(() => {
              window.scrollTo(0, 0);
              window.location.reload();
            }, 5001);
          }
        }
        if (
          nextProps.mhProvider.updatedClientStatus === "client-info-not-updated"
        ) {
          this.setState({
            disableSubmit: TextProps.VALUE_FALSE,
            outReachStatus: TextProps.VALUE_TRUE,
            mhSubmitError: TextProps.VALUE_TRUE,
            hideCloseIcon: TextProps.VALUE_FALSE,
            mhSubmitErrorMessage: mhProviderSNAPGAP(types.ENGLISH)
              .outreachFailed,
            relationStatus: "",
            householdsMembers: [],
            households: [],
            nonHouseHolds: [],
          });
        }
      } else if (
        nextProps.mhProvider.resetClientStatus === "cancel-client-info"
      ) {
        this.setState({
          disableSubmit: TextProps.VALUE_FALSE,
          isSnapForm: TextProps.VALUE_FALSE,
          clientInfo: "",
          householdsMembers: [],
          status: "",
          comments: "",
          relationStatus: "",
        });
      }
    }
  }

  getClientInfo = () => {
    this.setState({
      relatedCase: TextProps.VALUE_TRUE,
      relatedClient: TextProps.VALUE_FALSE,
    });
    this.props.getMhproviderClientInfo();
    this.props.mhproviderClientInfo();
  };

  memberSearchButton = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    this.setState({
      memberSearchPage: TextProps.VALUE_TRUE,
      isSnapForm: TextProps.VALUE_FALSE,
    });
    this.props.removePropsValues();
  };

  updateSnapOutReach = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    this.setState({ disableSubmit: TextProps.VALUE_TRUE });
    this.snapOutReachValidations();
  };

  goBack = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const { clientInfo } = this.state;
    if (clientInfo !== undefined) {
      var outreachIds = [];
      outreachIds.push(clientInfo.outreachId);
      clientInfo.relatedCaseInfo.map((data) => {
        outreachIds.push(data.outreachId);
      });
      var payload = {
        outreachId: outreachIds,
      };
      this.props.cancelClientCaseInfo(payload);
    }
    this.setState({
      isSnapForm: TextProps.VALUE_FALSE,
      memberSearchPage: TextProps.VALUE_FALSE,
      clientInfo: "",
      householdsMembers: [],
      status: "",
      comments: "",
      relationStatus: "",
      clientInfoNotFound: TextProps.VALUE_FALSE,
      mhSubmitError: TextProps.VALUE_FALSE,
      statusError: TextProps.VALUE_FALSE,
      commentError: TextProps.VALUE_FALSE,
    });
  };

  goBackToMember = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const { clientInfo } = this.state;
    if (clientInfo !== undefined) {
      var outreachIds = [];
      outreachIds.push(clientInfo.outreachId);
      clientInfo.relatedCaseInfo.map((data) => {
        outreachIds.push(data.outreachId);
      });
      var payload = {
        outreachId: outreachIds,
      };
      this.props.cancelClientCaseInfo(payload);
    }
    this.setState({
      isSnapForm: TextProps.VALUE_FALSE,
      memberSearchPage: TextProps.VALUE_TRUE,
      clientInfo: "",
      householdsMembers: [],
      status: "",
      comments: "",
      relationStatus: "",
      clientInfoNotFound: TextProps.VALUE_FALSE,
      mhSubmitError: TextProps.VALUE_FALSE,
      statusError: TextProps.VALUE_FALSE,
      commentError: TextProps.VALUE_FALSE,
    });
  };

  goBackToHomePage = (event) => {
    event.preventDefault();
    this.setState({
      isSnapForm: TextProps.VALUE_FALSE,
      memberSearchPage: TextProps.VALUE_FALSE,
      outRachHomePage: TextProps.VALUE_TRUE,
    });
  };

  snapOutReachValidations = () => {
    const {
      status,
      comments,
      households,
      nonHouseHolds,
      clientInfo,
      householdsMembers,
    } = this.state;
    var { clientRelationInfo } = this.props.mhProvider;
    if (status === "") {
      this.setState({
        statusError: TextProps.VALUE_TRUE,
        disableSubmit: TextProps.VALUE_FALSE,
      });
    } else if (
      status === mhProviderSNAPGAP(types.ENGLISH).outreachRelationStatus[0] &&
      comments === ""
    ) {
      this.setState({
        commentError: TextProps.VALUE_TRUE,
        disableSubmit: TextProps.VALUE_FALSE,
      });
    } else {
      // construct householdmembers details
      if (householdsMembers.length > 0) {
        householdsMembers.forEach((data) => {
          if (
            data.relationStatus ===
            mhProviderSNAPGAP(types.ENGLISH).outreachRelationStatus[1]
          ) {
            households.push({
              outreachId: data.outreachId,
              mdmId: data.mdmId,
            });
          } else if (
            data.relationStatus ===
              mhProviderSNAPGAP(types.ENGLISH).outreachRelationStatus[2] ||
            data.relationStatus === undefined ||
            data.relationStatus === ""
          ) {
            nonHouseHolds.push({
              outreachId: data.outreachId,
              mdmId: data.mdmId,
            });
          }
        });
      } else if (clientRelationInfo !== null) {
        if (clientRelationInfo.length > 0)
          clientRelationInfo.map((data) => {
            nonHouseHolds.push({
              outreachId: data.outreachId,
              mdmId: data.mdmId,
            });
          });
      }
      var payload = {
        outreachId: clientInfo.outreachId,
        mdmId: clientInfo.mdmId,
        households: households,
        nonHouseHolds: nonHouseHolds,
        status: status,
        comments: comments,
      };
      this.props.updateSnapOutReachClientInfo(payload);
    }
  };

  handleChangeForClientStatus = (newValue) => {
    this.setState(
      {
        status: newValue,
        statusError: TextProps.VALUE_FALSE,
        commentError: TextProps.VALUE_FALSE,
      },
      () => this.state.status
    );
    if (
      newValue === mhProviderSNAPGAP(types.ENGLISH).applicationStatus[0] ||
      newValue === mhProviderSNAPGAP(types.ENGLISH).applicationStatus[1]
    ) {
      this.setState({ relatedCase: TextProps.VALUE_FALSE });
    } else {
      this.setState({
        relatedCase: TextProps.VALUE_TRUE,
        relatedClient: TextProps.VALUE_FALSE,
      });
    }
  };

  handleChangeForClientRelationStatus = (newValue, obj, clientRelationInfo) => {
    this.setState({
      relationStatus: newValue,
      statusError: TextProps.VALUE_FALSE,
      commentError: TextProps.VALUE_FALSE,
    });
    if (clientRelationInfo.length > 0) {
      for (let relationStatus of clientRelationInfo)
        if (relationStatus.outreachId === obj.outreachId) {
          relationStatus.relationStatus = newValue;
          break;
        }
      this.setState({ householdsMembers: clientRelationInfo });
    }
  };

  handleChangeForComment = (newValue) => {
    const value = newValue.target.value;
    this.setState({ comments: value, commentError: TextProps.VALUE_FALSE });
  };

  closeSubmitModal = () => {
    // Cancel any actions then...
    this.setState({
      outReachStatus: TextProps.VALUE_FALSE,
      status: "",
      relationStatus: "",
    });
  };

  closeClientInfoModal = () => {
    // Cancel any actions then...
    this.setState({
      clientInfoNotFound: TextProps.VALUE_FALSE,
    });
  };

  render() {
    const {
      isSnapForm,
      memberSearchPage,
      outReachStatus,
      statusError,
      commentError,
      status,
      clientInfoNotFound,
      outRachHomePage,
    } = this.state;
    const { firstName } = this.props.consumer.loginDetails;
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const userName = firstName ? firstName : userInfo.firstName;
    const openModal = outReachStatus ? outReachStatus : TextProps.VALUE_FALSE;
    var { clientInfo, clientRelationInfo, isLoading } = this.props.mhProvider;
    if (Object.keys(this.props.mhProvider).length === 0) {
      const reloadSnapForm = JSON.parse(sessionStorage.getItem("isSnapForm"));
      const mhClientInfo = JSON.parse(sessionStorage.getItem("mhClientInfo"));
      if (reloadSnapForm && mhClientInfo !== null) {
        this.props.getMhproviderClientInfo();
        this.props.refreshMhproviderClientInfo();
      }
    }

    if (isLoading) return helperFunction.isLoading(isLoading);

    var showGetCaseInfoButton, showMemberSearchButton;
    if (userInfo.authorities.includes(roleProps.HEALTH_VIEW_CASE))
      showGetCaseInfoButton = TextProps.VALUE_TRUE;
    if (userInfo.authorities.includes(roleProps.HEALTH_MEMBER_SEARCH))
      showMemberSearchButton = TextProps.VALUE_TRUE;

    let memberSearchPageVal = "";
    if (memberSearchPage) {
      <Link
        to="#"
        onClick={this.goBackToMember}
        className="back-to-search-btn dta-button dta-button--outline-secondary dta-button--text-with-icon benefit-detail__back-button"
      >
        <Icon name="arrow-back" ariaHidden={TextProps.VALUE_TRUE} />
        {mhProviderSNAPGAP(types.ENGLISH).backToSearch}
      </Link>;
    }

    let clientRelationValue;
    if (clientRelationInfo) {
      clientRelationValue = clientRelationInfo.map((obj) => {
        return (
          <div className="mh-case-info-container">
            <div className="mh-provider-form-group border-btm-none">
              <div className="pure-u-12-24 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-12-24 pure-u-xl-12-24">
                {formatters.fullName(obj)}
              </div>
              <div className="pure-u-12-24 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-9-24 pure-u-xl-9-24 relation-status">
                <DTASelect
                  name={"relationStatus"}
                  options={["Same Household", "Different Household"]}
                  className="dta-units__control"
                  id={this.lastUniqueId()}
                  aria-labelledby={this.lastUniqueId()}
                  value={this.state.relationStatus}
                  error={TextProps.VALUE_FALSE}
                  onChange={(e) =>
                    this.handleChangeForClientRelationStatus(
                      e,
                      obj,
                      clientRelationInfo
                    )
                  }
                />
              </div>
            </div>
          </div>
        );
      });
    } else {
      clientRelationValue = (
        <div className="mh-provider-outreach">
          {mhProviderSNAPGAP(types.ENGLISH).clientRelatedCase}
        </div>
      );
    }

    return (
      <div className="mh-provider-container">
        <div
          className={
            memberSearchPage && !isSnapForm
              ? "max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content-ie"
              : "mh-provider-display-none"
          }
        >
          <Link
            to="#"
            onClick={this.goBackToHomePage}
            className="dta-button dta-button--outline-secondary dta-button--text-with-icon benefit-detail__back-button"
          >
            <Icon name="arrow-back" ariaHidden={TextProps.VALUE_TRUE} />
            {mhProviderSNAPGAP(types.ENGLISH).back}
          </Link>
        </div>
        {isSnapForm ? (
          <div className="mh-provider-container">
            <div className="max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content-ie">
              {memberSearchPageVal}
              <h1 className="text--gray mh-provider-heading">
                {mhProviderSNAPGAP(types.ENGLISH).MAInfo}
              </h1>
              <div className="pure-g mh-case-info-container">
                <form className="max-width pure-u-1-1">
                  <div className="mh-provider-form-group pure-u-1-1">
                    <div className="pure-u-12-24">
                      <label className="text--gray">
                        {mhProviderSNAPGAP(types.ENGLISH).mhDetails[0]}
                      </label>
                    </div>
                    <div className="pure-u-12-24">
                      {formatters.fullName(clientInfo)}
                    </div>
                  </div>
                  <div className="mh-provider-form-group">
                    <div className="pure-u-12-24">
                      <label className="text--gray">
                        {mhProviderSNAPGAP(types.ENGLISH).mhDetails[1]}
                      </label>
                    </div>
                    <div className="pure-u-12-24">
                      {`${clientInfo.residentialAddress.line1} `}
                      {clientInfo.residentialAddress.line2}
                      <br /> {clientInfo.residentialAddress.city}{" "}
                      {clientInfo.residentialAddress.state}{" "}
                      {clientInfo.residentialAddress.zip}
                    </div>
                  </div>
                  <div className="mh-provider-update-data">
                    <div className="pure-u-12-24">
                      <label className="text--gray">
                        {mhProviderSNAPGAP(types.ENGLISH).mhDetails[2]}
                      </label>
                    </div>
                    <div className="pure-u-12-24">
                      {formatters.phone(clientInfo.cellPhone)}
                    </div>
                    <div className="mh-provider-phone-group border-btm-none">
                      <div className="pure-u-12-24">
                        <label className="text--gray">
                          {mhProviderSNAPGAP(types.ENGLISH).mhDetails[3]}
                        </label>
                      </div>
                      <div className="pure-u-12-24">
                        {formatters.phone(clientInfo.dayPhone)}
                      </div>
                    </div>
                    <div className="mh-provider-phone-group border-btm-none">
                      <div className="pure-u-12-24">
                        <label className="text--gray">
                          {mhProviderSNAPGAP(types.ENGLISH).mhDetails[4]}
                        </label>
                      </div>
                      <div className="pure-u-12-24">
                        {formatters.phone(clientInfo.nightPhone)}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="max-width max-width--three-quarters pad-all--double pad-top-none snap-oureact-content-ie">
              <form>
                <div className="pure-u-24-24">
                  <p className="text--gray mh-provider-case-info">
                    {mhProviderSNAPGAP(types.ENGLISH).SNAPoutReach}{" "}
                  </p>
                </div>
                <div className="mh-case-info-container section-border-btm">
                  <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-12-24 pure-u-lg-12-24 pure-u-xl-12-24"></div>
                  <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-12-24 pure-u-lg-12-24 pure-u-xl-12-24 new-snap-app">
                    <a
                      className="mh-new-snap dta-button dta-button--primary dta-button--text-with-icon"
                      target="_self"
                      href={routeProps.APPLY_PATH}
                    >
                      <Icon name="application" />{" "}
                      {mhProviderSNAPGAP(types.ENGLISH).newSNAPApp}
                    </a>
                  </div>
                  <div className="mh-provider-update-data">
                    <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-11-24 pure-u-lg-11-24 pure-u-xl-11-24">
                      <label className="text--gray">
                        {mhProviderSNAPGAP(types.ENGLISH).status}
                      </label>
                    </div>
                    <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-13-24 pure-u-lg-13-24 pure-u-xl-13-24 outreach-status-container">
                      {statusError && (
                        <div className="pure-g">
                          <div className="pure-u-3-5 peat-case-error-msg mh-status-error">
                            <span className="dta-form__error-message">
                              {mhProviderSNAPGAP(types.ENGLISH).statusErrorMsg}
                            </span>
                          </div>
                        </div>
                      )}
                      <DTASelect
                        name={"status"}
                        options={[
                          "Application submitted",
                          "Try again later",
                          "Wrong number",
                          "Number out of service",
                          "Did not want to apply",
                        ]}
                        className="dta-units__control"
                        id={this.lastUniqueId()}
                        aria-labelledby={this.lastUniqueId()}
                        value={status}
                        error={TextProps.VALUE_FALSE}
                        onChange={this.handleChangeForClientStatus}
                        ariaLabel={"status"}
                      />
                    </div>
                  </div>
                  <div className="mh-provider-update-data border-btm-none">
                    <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-12-24 pure-u-lg-12-24 pure-u-xl-12-24">
                      <label className="text--gray">
                        {mhProviderSNAPGAP(types.ENGLISH).comments}
                      </label>
                    </div>
                    <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-12-24 pure-u-lg-12-24 pure-u-xl-12-24 comments-content">
                      {commentError && (
                        <div className="pure-g">
                          <div className="peat-case-error-msg pure-u-5-5">
                            <span className="dta-form__error-message">
                              {
                                mhProviderSNAPGAP(types.ENGLISH)
                                  .commentsErrorMsg
                              }
                            </span>
                          </div>
                        </div>
                      )}
                      <textarea
                        name="textValue"
                        onChange={this.handleChangeForComment}
                        aria-label={"comments"}
                      />
                    </div>
                  </div>
                </div>
                {
                  <div>
                    <div>
                      <p className="text--gray mh-provider-case-info">
                        {mhProviderSNAPGAP(types.ENGLISH).clientAddress}
                      </p>
                    </div>
                    {clientRelationValue}
                  </div>
                }
                <br></br>
                <div className="mh-provider-submit">
                  <div className="dta-modal__footer--inline-buttons">
                    <button
                      className="dta-button dta-button--large dta-button--outline-primary"
                      onClick={this.goBack}
                    >
                      {mhProviderSNAPGAP(types.ENGLISH).cancel}
                    </button>
                    <button
                      className="dta-button dta-button--large dta-button--primary"
                      disabled={this.state.disableSubmit}
                      onClick={this.updateSnapOutReach}
                    >
                      {mhProviderSNAPGAP(types.ENGLISH).submit}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {modalHelperFunction.dtaHelperModal(
              (instance) => (this.mhProviderModal = instance),
              openModal,
              this.closeSubmitModal,
              "rivl-modal",
              "mh-provider",
              this.state.mhSubmitError,
              this.state.hideCloseIcon
            )}
          </div>
        ) : memberSearchPage ? (
          <MemberSearch />
        ) : outRachHomePage ? (
          <div className="max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content">
            <h1>
              {mhProviderSNAPGAP(types.ENGLISH).hi} {userName},
            </h1>
            <p
              className={
                showMemberSearchButton
                  ? "mh-provider-paragraph"
                  : "mh-provider-display-none"
              }
            >
              {" "}
              {mhProviderSNAPGAP(types.ENGLISH).memberSearchDesc}
            </p>
            <div
              className={
                showMemberSearchButton
                  ? "max-width max-width--three-quarters pad-all--double pad-top-none mh-provider-snapoutreach"
                  : "mh-provider-display-none"
              }
            >
              <button
                onClick={this.memberSearchButton}
                className="dta-button dta-button--large dta-button--primary snap-outreach-btn"
              >
                {mhProviderSNAPGAP(types.ENGLISH).memberSearch}
              </button>
            </div>
            <p
              className={
                showGetCaseInfoButton
                  ? "mh-provider-paragraph"
                  : "mh-provider-display-none"
              }
            >
              {mhProviderSNAPGAP(types.ENGLISH).getCaseInfoDesc}
            </p>
            <div
              className={
                showGetCaseInfoButton
                  ? "max-width max-width--three-quarters pad-all--double pad-top-none mh-provider-snapoutreach"
                  : "mh-provider-display-none"
              }
            >
              <button
                onClick={() => this.getClientInfo()}
                className="dta-button dta-button--large dta-button--primary snap-outreach-btn"
              >
                {mhProviderSNAPGAP(types.ENGLISH).getCaseInfo}
              </button>
            </div>
            {clientInfoNotFound && (
              <span
                className="dta-form__error-message"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                {mhProviderSNAPGAP(types.ENGLISH).clientNotFound}
              </span>
            )}
            <p className="mh-provider-paragraph">
              {mhProviderSNAPGAP(types.ENGLISH).snapApplyDesc}
            </p>
            <div className="max-width max-width--three-quarters pad-all--double pad-top-none mh-provider-snapoutreach">
              <a
                target="_self"
                href={routeProps.APPLY_PATH}
                className="dta-button dta-button--primary dta-button--large dta-button--text-with-icon"
              >
                <Icon name="application" />{" "}
                {mhProviderSNAPGAP(types.ENGLISH).snapApply}
              </a>
            </div>
            <br></br>
            <hr />
            <p style={{ textAlign: "center" }}>
              {mhProviderSNAPGAP(types.ENGLISH).thankYouSNAPGap}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMhproviderClientInfo: getMhproviderClientInfo,
      mhproviderClientInfo: mhproviderClientInfo,
      updateSnapOutReachClientInfo: updateSnapOutReachClientInfo,
      memberSearchNamesClientInfo: memberSearchNamesClientInfo,
      cancelClientCaseInfo: cancelClientCaseInfo,
      removePropsValues: removePropsValues,
      refreshMhproviderClientInfo: refreshMhproviderClientInfo,
      removeMultilanguageSelect: removeMultilanguageSelect,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SnapOutreach);
