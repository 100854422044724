import sections from "../../pages/applySnap/applySnapSectionsMapData";

const actionTypes = {
  getScreeningQuestionsSuccess: "GET_SCREENING_QUESTIONS_SUCCESS",
  getScreeningQuestionsFailed: "GET_SCREENING_QUESTIONS_FAILED",
  serviceFailedAction: "SERVICE_FAIL_REDUX",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  updateScreeningQuestion: "UPDATE_SCREENING_SECTION",
};

//get Question Service
export function getScreeningQuestions() {
  return (dispatch) => {
    return dispatch(getScreeningQuestionsSuccessAction(sections));
  };
}

//Get Question Success Action
function getScreeningQuestionsSuccessAction(payload) {
  return {
    type: actionTypes.getScreeningQuestionsSuccess,
    payload,
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}

function questionsInputValuesAction(data) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: data,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

function dispatchScreeningQuestions(section) {
  return {
    type: actionTypes.updateScreeningQuestion,
    payload: section,
  };
}

export function updateScreeningQuestions(section) {
  return (dispatch) => {
    return dispatch(dispatchScreeningQuestions(section));
  };
}
