import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLanguage } from "../../redux/language/languageActions";
import LanguageSelect from "../../redux/language/languageSelect";
import logo from "../resources/images/alabama-aces-logo.png";

class Header extends Component {
  render() {
    const { home } = this.props;
    return (
      <div className="App-header">
        <LanguageSelect />
        <img src={logo} className="App-logo" alt="logo" />
        <h2>{home.mainHeading}</h2>
      </div>
    );
  }
}

//maping State with Store
function mapStateToProps(state) {
  return {
    home: state.home,
    language: state.language.constantStrings,
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onGetLanguage: getLanguage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
