import * as Props from "../../utils/components/shared";
import { ALABAMA_COUNTY_OFFICES } from "../../utils/constants/constants";
const EN = "en-US";
const ES = "es-ES";


let selectedLanguage = localStorage.getItem("selectedLanguage");
if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

export function incomeMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        heading: name + " " + "- Income & Benefit Details",
        mainQuestion:
          "What type(s) of income and benefits does " + name + " receive?",
        questionClearOption: "No one",
        noneTargetValue: "None",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay",
              [Props.LABEL_OBJ_AFTER]: "  for TANF?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay",
              [Props.LABEL_OBJ_AFTER]: "  for Self-Employment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: "  for Job Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                "  for Social Security (SSA / RSDI)?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                " for Supplemental Security Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: "  for Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: "  for Unemployment Compensation?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: "  for Workman's Compensation?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                "  for Interest Dividends from Stock and Bonds?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: " for Military Allotment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                " for Pension or Retirement Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the  take home pay",
              [Props.LABEL_OBJ_AFTER]: "  for Railroad Retirement?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: " for HUD Utility Assistance?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                " for Income from Land Rental or Rental Property?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: " for Child Support?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: "  for Alimony?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]:
                "  for Contributions from Friends or Relatives?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the  take home pay ",

              [Props.LABEL_OBJ_AFTER]: " for Roomer Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the  take home pay ",
              [Props.LABEL_OBJ_AFTER]: " for Boarder Income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the take home pay ",
              [Props.LABEL_OBJ_AFTER]: " for Other Income or Benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
        ],
      };
    case ES:
      return {
        heading: name + " " + "Detalles de ingresos y beneficios",
        mainQuestion:
          "¿Qué tipo(s) de ingresos y beneficios ofrece" + name + " ¿recibir?",
        questionClearOption: "Nadie",
        noneTargetValue: "Ninguna",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "para TANF?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para el trabajo por cuenta propia?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para obtener ingresos laborales?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para la Seguridad Social (SSA / RSDI)?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para la Seguridad de Ingreso Suplementario?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para los beneficios de veteranos?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para la Compensación por Desempleo?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para la Compensación Laboral?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para dividendos de intereses de acciones y bonos?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para asignación militar?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para Pensión o Ingreso de Jubilación?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para la jubilación ferroviaria?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para asistencia de servicios públicos de HUD?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para ingresos por alquiler de terrenos o propiedades en alquiler?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para la manutención de los hijos?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para la pensión alimenticia?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]:
                "¿Para contribuciones de amigas o familiares?",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",

              [Props.LABEL_OBJ_AFTER]: "¿Para ingresos de Roomer?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para ingresos de internado?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el salario neto?",
              [Props.LABEL_OBJ_AFTER]: "¿Para otros ingresos o beneficios?",
            },
          },
        ],
      };
    default:
      return;
  }
}

export function dymanicMultilanguageLabels(benefitStartDate,benefitEndDate,formattedDate,disasterDate,language) {
  switch (language) {
    case EN:
      return {
        foodBenefitsLabel: "Did your household receive SNAP benefits for the month of " + formattedDate + " ?",
        dueToDisasterLabel: "Does your household have any cash or money in the bank which you cannot get to because the bank is or was closed for 16 or more days between " + benefitStartDate + " and " + benefitEndDate +  " due to the disaster?",
        subHeaderExpense: "List all liquid resources your household will able to get to during the disaster period " + "("+ benefitStartDate + " and " + benefitEndDate + ").",
        descriptionLabel:  "Your household must have disaster related expense in order to qualify for D-SNAP."+ "<br>"+"</br>"+ "I certify under penalty of perjury that my household has paid or expects to pay out of pocket disaster-related expense(which include food loss) as a result of the hurricance that occured in my country of residence on " + disasterDate +"."+ "I Will not be reimbursed for these expense during the period of " + benefitStartDate + " through " + benefitEndDate +"." +"<br>"+"</br>"+"Sign by typing your full name below.",
      };
    case ES:
      return {
        foodBenefitsLabel: "¿Recibió su hogar beneficios de SNAP para el mes de " + formattedDate + " ?",
        dueToDisasterLabel: "¿Tiene su hogar algún efectivo o dinero en el banco al que no puede acceder porque el banco está o estuvo cerrado durante 16 o más días entre " + benefitStartDate + " y " + benefitEndDate + " debido al desastre?",
        subHeaderExpense: "Enumere todos los recursos líquidos a los que su hogar podrá acceder durante el período del desastre " + "(" + benefitStartDate + " y " + benefitEndDate + ").",
        descriptionLabel: "Tu hogar debe tener gastos relacionados con desastres para calificar para D-SNAP."+"<br>"+"</br>"+"Certifico bajo pena de perjurio que mi hogar ha pagado o espera pagar de su bolsillo gastos relacionados con el desastre (que incluyen pérdida de alimentos) como resultado del huracán que ocurrió en mi país de residencia el " + disasterDate + ". No seré reembolsado por estos gastos durante el período del " + benefitStartDate + " al " + benefitEndDate + "."+"<br>"+"</br>"+"Firma escribiendo tu nombre completo a continuación.",
      };      
    default:
      return;
  }
}

export function beforeYoustartDsnapMlanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        heading: "Before You Start",
        howApplyLabel: "How Does Applying Work?",
        snapFieldLabel1:
          "You may complete your DSNAP Pre-Screener using the following methods:",
        fieldFactors: [
          "Online by clicking Get Started. This is the fastest way!",
          "You can submit your form to your local county DHR office during D-SNAP operations.",
          "You may only pre-register during the designated disaster benefit period.",
          "You may be asked to provide additional proof to determine eligibility."
        ],
        snapFieldLabel2:
           "Before you continue, please read the following.",
        fieldFactors2: [
           "You have the right to file an application the same day you contact your county office.",
           "To file an application, you are only required to provide your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information.",
           "Mail, fax, e-mail or take this application to the SNAP Office in the county where you live. You may also apply online at https://acesselfservice.dhr.alabama.gov.",
           "To get the address or phone number of your local county office, call toll-free: 1-833-822- 2202 or online at www.dhr.alabama.gov."
        ],
        snapFieldLabel3:
           "You may receive expedited assistance within 7 days if:",
        fieldFactors3: [
           "Your household’s monthly gross income is less than $150 and your liquid resources (cash, checking, or savings) are less than $100 OR",
           "Your household’s monthly rent/mortgage and utilities are more than your household’s income and liquid resources OR",
           "Your household is a destitute migrant or seasonal farmworker household and your liquid resources are less than $100."
        ],
        snapFieldLabel4:
           "Additional things you need to know before submitting a SNAP application consist of:",
        fieldFactors4: [
           "If you submit your application using this website after 5:00 pm CST or on a weekend or holiday, it will be received the next business day.",
           "When a resident of an institution is jointly applying for SSI and SNAP prior to leaving the institution, the filing date on the application is the date of release of the applicant from the institution.",
           "You must be interviewed after your application is received. You may be notified of your appointment time and date.",
           "We must approve or deny your application within 30 days. Applications must be processed in accordance with SNAP procedures, including timeliness, notice, and fair hearing requirements regardless of whether the application is for SNAP and other programs.",
           "Also, a household may not be denied SNAP benefits solely because it has been denied benefits from other programs."
        ],
        snapFieldLabel5:
            "If you are eligible, your benefits will be determined from the day we receive your signed application. This means your first month of benefits will be prorated from the day we receive your application.",
        completingPreReglabel: "Complete the Pre-Registration:",
        completingPreRegSub: " You may file a Pre-Registration with just your name, address and signature at any point in the pre-registration by clicking on the “Sign and Submit” button. We encourage you to answer as many questions as you can and sign your Pre-Registration today. This will allow us to help you more quickly.",
        informationLabel: "Please be prepared to provide the following information:",
        memberText: "Name, date of birth, and Social Security Number (if applicable) for each household member.",
        permanentText: "Permanent residential address, temporary address, and /or mailing address",
        proofText: "Proof of identification, such as driver’s license, or state-issued ID.",
        proofResidencyText: "Proof of residency, such as household bill or any other document that verifies address during the disaster period",
        amountIncomeText: "Amount of income for each household member",
        amountHouseholdText: "Amount of household resources",
        preRegistrationText: "Pre-registering for D-SNAP online is only the first step. You must also complete an interview to determine your eligibility and finalize the D-SNAP application process.",
        stateLabelText: "If you live or work within the disaster area, please select your county below to validate you are in an eligible disaster area to receive D-SNAP benefits",
        county: [],
        disasterLabel: "Select a disaster that impacted your county.",
        goBack: "Go Back",
        getStartedLabel: "Get Started",
        submitButtonLabel: "Sign and Submit",
        continueLabel: "Continue",
        countyErrorMsg: "Please select the county.",
        confirmDelete: "Confirm Delete",
        areYouSureWarningLabel: "Are you sure ?",
        languageWarningLabel:"If you want to change the langauge your application will get restarted.",
        CancelButtonLabel: "Cancel",
        deleteButtonLabel: "Delete",
        addressValidHeaderText: "Address Validation",
        addressValidLabelText: "Please select from the results below.",
        enteredHomeAddressLabelText: "Entered home address:",
        suggestedHomeAddressLabelText: "Suggested home address:",
        enteredMailingAddressLabelText: "Entered mailing address:",
        suggestedMailingAddressLabelText: "Suggested mailing address:",
        enteredTempAddressLabelText: "Entered temporary address:",
        suggestedTempAddressLabelText: "Suggested temporary address:",
        isHomelessHouseholdheader : "Homeless Household",
        isHomelessHouseholdContent : [
          {
          line1:`If you do not have a mailing address,please submit your`,
          },
          {
          line2:`application at your local county DHR office.Click `
          },
          {
          line3:`for more details.`
          }
        ],
        confirmButtonLabel: "Confirm",
        okText: "Ok"
      };
    case ES:
      return {
        heading: "Antes de Empezar",
        howApplyLabel: "¿Cómo funciona la solicitud?",
        snapFieldLabel1: "Puede completar su Preseleccionador de DSNAP de las siguientes formas:",
        fieldFactors: [
          "En línea haciendo clic en Comenzar. ¡Esta es la forma más rápida!",
          "Puede enviar su formulario a la oficina local de DHR de su condado durante las operaciones de D-SNAP.",
          "Solo puede preinscribirse durante el período designado de beneficios por desastre.",
          "Es posible que se le pida proporcionar pruebas adicionales para determinar la elegibilidad."
        ],
        snapFieldLabel2:
        "Antes de continuar, lea lo siguiente.",
        fieldFactors2: [
        "Tiene derecho a presentar una solicitud el mismo día que se comunica con la oficina de su condado.",
        "Para presentar una solicitud, solo debe proporcionar su nombre, dirección y firma. Si decide hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si califica para asistencia acelerada, ya que su solicitud no contendrá información sobre ingresos o recursos.",
        "Envíe por correo, fax, correo electrónico o lleve esta solicitud a la oficina de SNAP en el condado donde vive. También puede presentar su solicitud en línea en https://acesselfservice.dhr.alabama.gov.",
        "Para obtener la dirección o el número de teléfono de la oficina local de su condado, llame gratis al: 1-833-822-2202 o en línea en www.dhr.alabama.gov."
        ],
        snapFieldLabel3:
        "Puede recibir asistencia acelerada dentro de los 7 días si:",
        fieldFactors3: [
        "El ingreso bruto mensual de su hogar es inferior a $150 y sus recursos líquidos (efectivo, cheques o ahorros) son inferiores a $100 O",
        "El alquiler/hipoteca mensual y los servicios públicos de su hogar son mayores que los ingresos y recursos líquidos de su hogar O",
        "Su hogar es un hogar de inmigrantes indigentes o trabajadores agrícolas estacionales y sus recursos líquidos son menos de $100."
        ],
        snapFieldLabel4:
        "Las cosas adicionales que necesita saber antes de enviar una solicitud SNAP incluyen:",
        fieldFactors4: [
        "Si envía su solicitud a través de este sitio web después de las 5:00 p. m. CST o en un fin de semana o feriado, se recibirá el siguiente día hábil.",
        "Cuando un residente de una institución solicita conjuntamente SSI y SNAP antes de abandonar la institución, la fecha de presentación de la solicitud es la fecha de salida del solicitante de la institución.",
        "Debe ser entrevistado después de recibir su solicitud. Es posible que se le notifique la fecha y hora de su cita.",
        "Debemos aprobar o rechazar su solicitud dentro de los 30 días. Las solicitudes deben procesarse de acuerdo con los procedimientos de SNAP, incluidos los requisitos de puntualidad, notificación y audiencia justa, independientemente de si la solicitud es para SNAP y otros programas.",
        "Además, a un hogar no se le pueden negar los beneficios de SNAP únicamente porque se le han negado beneficios de otros programas."
        ],
        snapFieldLabel5:
         "Si es elegible, sus beneficios se determinarán a partir del día en que recibamos su solicitud firmada. Esto significa que su primer mes de beneficios se prorrateará desde el día en que recibamos su solicitud.",
        completingPreReglabel: "Complete la Preinscripción:",
        completingPreRegSub: "Puede presentar una Preinscripción con solo su nombre, dirección y firma en cualquier momento durante la preinscripción haciendo clic en el botón 'Firmar y Enviar'. Le recomendamos que responda tantas preguntas como pueda y firme su Preinscripción hoy. Esto nos permitirá ayudarlo más rápidamente.",
        informationLabel: "Esté preparado para proporcionar la siguiente información:",
        memberText: "Nombre, fecha de nacimiento y Número de Seguro Social (si corresponde) de cada miembro del hogar.",
        permanentText: "Dirección residencial permanente, dirección temporal y/o dirección postal.",
        proofText: "Prueba de identificación, como licencia de conducir o identificación emitida por el estado.",
        proofResidencyText: "Prueba de residencia, como factura del hogar u otro documento que verifique la dirección durante el período de desastre.",
        amountIncomeText: "Cantidad de ingresos para cada miembro del hogar.",
        amountHouseholdText: "Cantidad de recursos del hogar.",
        preRegistrationText: "Preinscribirse en línea para D-SNAP es solo el primer paso. También debe completar una entrevista para determinar su elegibilidad y finalizar el proceso de solicitud de D-SNAP.",
        stateLabelText: "Si vive o trabaja dentro del área de desastre, seleccione su condado a continuación para validar que se encuentra en un área de desastre elegible para recibir beneficios de D-SNAP.",
        county: [],
        disasterLabel: "Seleccione un desastre que haya impactado su condado.",
        goBack: "Regresar",
        getStartedLabel: "Comenzar",
        submitButtonLabel: "Firmar y Enviar",
        continueLabel: "Continuar",
        countyErrorMsg: "Por favor, seleccione el condado.",
        confirmDelete: "Confirmar eliminación",
        areYouSureWarningLabel: "Estas seguro ?",
        languageWarningLabel:"Si desea cambiar el idioma, su aplicación se reiniciará.",
        CancelButtonLabel: "Cancelar",
        deleteButtonLabel: "Borrar",
        addressValidHeaderText: "Validación de dirección",
        addressValidLabelText:
          "Seleccione entre los resultados a continuación.",
        enteredHomeAddressLabelText: "Dirección de casa introducida:",
        suggestedHomeAddressLabelText: "Dirección de casa sugerida:",
        enteredMailingAddressLabelText: "Dirección postal introducida:",
        suggestedMailingAddressLabelText: "Dirección postal sugerida:",
        enteredTempAddressLabelText: "Dirección temporal introducida:",
        suggestedTempAddressLabelText: "Dirección temporal sugerida:",
        isHomelessHouseholdheader : "Hogar sin hogar",
        isHomelessHouseholdContent : [
          {
          line1:`Si no tiene una dirección, envíe su`,
          },
          {
          line2:`solicitud en la oficina local del DHR de su condado. Haga clic `
          },
          {
          line3:`para más detalles.`
          }
        ],
        confirmButtonLabel: "Confirmar",
        okText: "De acuerdo"
      };
  }
}
export function aboutMeDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Head of Household Information",
        header: "Head of Household Information",
        subHeader: "The case will be in the name of the Head of Household entered below. <br/><br/>",
        labelFirstName: "What is your First Name?",
        firstNameErrMsg: "Please enter your First Name.",
        middleName: "What is your Middle Name?",
        lastNameLabel: "What is your Last Name?",
        lastNameErrMsg: "Please enter your Last Name.",
        labelSuffix: "Suffix",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        addressQuestionlabel: "What is your Home Address?",
        addressTemporaryLabel: "What is your Temporary Address? (Do not use a P.O.Box)",
        PhoneNoLabel: "What is the best phone number to reach you?",
        emailAddressLabel: "Do you have an email address that you would like to provide for communication?",
        emailErrormsg: "Please enter a valid email address.",
        houseHoldLabel: "Are you or any member of your household an employee of the State or County Department of Human Resources?",
        memberHouseholdLabel: "Is any member of your household employed by the State of Alabama?",
        isHomelessLabel: "Are you homeless?",
        poBoxErrmsg: "Do not use a P.O.Box.",
        isDisabilityLabel: "Do you have a disability that requires reasonable modification or communication assistance?",
        isAuthorizedLabel: "Would you like to appoint an Authorized Representative?",
        licenseLabel: "What is your Driver’s License/State ID Number?",
        yesNoOptions: ["Yes", "No"],
        yesOption: ["YES"],
        validPhoneAlert: "Please enter a valid phone number",
        citiErrmsg: "Please enter a valid City.",
        homeErrmsg: "Please enter  your Home Address.",
        zipCodeErrmsg: "Please enter a valid Zip code.",
        masZipCodeErrmsg: "This SNAP application is for Alabama residents. If you live in Alabama, please enter your Zip Code. If you do not live in Alabama, you must apply in your state.",
        poBoxErrmsg: "PO Box cannot be accepted as Home Address",
        doNotAddress: "Do not have an address.",
        alsoMailingLabel: "Is this also your Mailing Address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your Mailing Address? (It can be a P.O.Box.)",
        addressErrmsg: "Please enter a valid Address.",
        mailingAddressErrmsg: "Please enter a mailing address.",
        homeAddressErrmsg: "Please enter a home address.",
        citiErrmsg: "Please enter a valid City.",
        zipCodeErrmsg: "Please enter a valid Zip code.",
        alabamaSublabelText: "Your D-SNAP Pre-screener will be sent to the State of Alabama Department of Human Resources for Interview and Processing. If you choose to submit it to your local County Director or on-site Director.",
        authRepname: "What is the full name of the Authorized Representative?",
        authrepPhnNo: "What is the phone number of the Authorized Representative?",
        drivingLicenseErrorText: "Please enter valid  Driver’s License/State ID Number",
        countyErrmsg: "Please enter a valid County."
      };
    case ES:
      return {
        title: "Información del Jefe de Hogar",
        header: "Información del Jefe de Hogar",
        subHeader: "El caso estará a nombre del Jefe de Hogar ingresado a continuación. <br/><br/>",
        labelFirstName: "¿Cuál es tu Nombre?",
        firstNameErrMsg: "Por favor, ingresa tu nombre.",
        middleName: "¿Cuál es tu Segundo Nombre?",
        lastNameLabel: "¿Cuál es tu Apellido?",
        lastNameErrMsg: "Por favor, ingresa tu apellido.",
        labelSuffix: "Sufijo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        addressQuestionlabel: "¿Cuál es tu Dirección de Casa?",
        addressTemporaryLabel: "¿Cuál es tu Dirección Temporal?",
        PhoneNoLabel: "¿Cuál es el mejor número de teléfono para comunicarse contigo?",
        emailAddressLabel: "¿Tienes una dirección de correo electrónico que te gustaría proporcionar para comunicación?",
        emailErrormsg: "Por favor, ingresa una dirección de correo electrónico válida.",
        houseHoldLabel: "¿Eres tú o algún miembro de tu hogar empleado del Departamento de Recursos Humanos del Estado o del Condado?",
        memberHouseholdLabel: "¿Algún miembro de tu hogar está empleado por el Estado de Alabama?",
        isHomelessLabel: "¿Eres sin hogar?",
        poBoxErrmsg: "No utilices un apartado postal.",
        isDisabilityLabel: "¿Tienes una discapacidad que requerirá modificaciones razonables o asistencia de comunicación?",
        isAuthorizedLabel: "¿Te gustaría nombrar a un Representante Autorizado?",
        licenseLabel: "¿Cuál es el número de tu Licencia de Conducir / Identificación Estatal?",
        yesNoOptions: ["Sí", "No"],
        yesOption: ["Sí"],
        validPhoneAlert: "Por favor, ingresa un número de teléfono válido.",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        homeErrmsg: "Por favor ingrese su dirección particular.",
        zipCodeErrmsg: "Por favor, ingresa un código postal válido.",
        masZipCodeErrmsg: "Esta solicitud de SNAP es para residentes de Alabama. Si vives en Alabama, ingresa tu código postal. Si no vives en Alabama, debes solicitar en tu estado.",
        poBoxErrmsg: "El apartado postal no puede ser aceptado como dirección de casa.",
        doNotAddress: "No tengo una dirección.",
        alsoMailingLabel: "¿Es esta también tu Dirección de Correspondencia?",
        optionsSendMail: "Sí, envía correo allí.",
        optionsNoDiffAddress: "No, uso una dirección diferente para el correo.",
        mailingQlabel: "¿Cuál es tu dirección de correspondencia?",
        addressErrmsg: "Por favor, ingrese una dirección válida.",
        mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
        homeAddressErrmsg: "Por favor ingrese una dirección particular.",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        zipCodeErrmsg: "Por favor, ingrese un código postal válido.",
        alabamaSublabelText: "Su Preseleccionador de D-SNAP será enviado al Departamento de Recursos Humanos del Estado de Alabama para la entrevista y procesamiento. Si elige presentarlo ante su Director del Condado local o Director en el lugar.",
        authRepname: "¿Cuál es el nombre completo del Representante Autorizado?",
        authrepPhnNo: "¿Cuál es el número de teléfono del Representante Autorizado?",
        drivingLicenseErrorText: "Ingrese una licencia de conducir válida o un número de identificación estatal",
        countyErrmsg: "Por favor seleccione un condado"
      };

  }
}
export function householdDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Household Situation",
        header: "Household Situation",
        yesNoOptions: ["Yes", "No"],
        yesOption: ["YES"],
        noOption: "No",
        timeDisasterLabel: "Was your household living in the disaster area at the time of the disaster?",
        selfEmployementLabel: "Did the disaster damage or destroy your home or place of work including self-employment property? ",
        resultDisaster: "Does your household have any out of pocket expenses as a result of the disaster?",
        buyingFood: "While the effects of the disaster are being cleaned up, will your household be buying food?",
        houseHoldIncome: "Did the disaster delay, reduce or stop your household's income?",
        dueToDisaster: "Does your household have any cash or money in the bank which you cannot get to because the bank is or was closed for 16 or more days between October 28, 2020 and November 26, 2020 due to the disaster?",
        foodAssistance: "Does your household currently receive SNAP?",
        foodBenefits: "Did your household receive SNAP benefits for the month of November 2020?",
        workingDiasterLabel: "Was your household working in the disaster area at the time of the disaster?",
        HouseholdSitutaionErrMsg: "Please choose an option.",
        workEmployementLabel: "Did the disaster damage or destory your place of work or self-employement property?",
        labelstate: "State",
        labelcounty: "County(Required)",
        stateLabel: "Please enter the",
        CountyLabel: "state and county.",
        pleaseSelectcounty: "Please select the county",
        stateCountyLabel: "Please enter the state and county.",
        countycode: [
          "Autauga",
          "Baldwin",
          "Barbour",
          "Bibb",
          "Blount",
          "Bullock",
          "Butler",
          "Calhoun",
          "Chambers",
          "Cherokee",
          "Chilton",
          "Choctaw",
          "Clarke",
          "Clay",
          "Cleburne",
          "Coffee",
          "Colbert",
          "Conecuh",
          "Coosa",
          "Covington",
          "Crenshaw",
          "Cullman",
          "Dale",
          "Dallas",
          "DeKalb",
          "Elmore",
          "Escambia",
          "Etowah",
          "Fayette",
          "Franklin",
          "Geneva",
          "Greene",
          "Hale",
          "Henry",
          "Houston",
          "Jackson",
          "Jefferson",
          "Lamar",
          "Lauderdale",
          "Lawrence",
          "Lee",
          "Limestone",
          "Lowndes",
          "Macon",
          "Madison",
          "Marengo",
          "Marion",
          "Marshall",
          "Mobile",
          "Monroe",
          "Montgomery",
          "Morgan",
          "Perry",
          "Pickens",
          "Pike",
          "Randolph",
          "Russell",
          "Saint Clair",
          "Shelby",
          "Sumter",
          "Talladega",
          "Tallapoosa",
          "Tuscaloosa",
          "Walker",
          "Washington",
          "Wilcox",
          "Winston"
        ],
        statecode: ["AL"],
        subHeader: "The case will be in the name of the Head of Household enterred below. <br/><br/> INSTRUCTION: Complete the Pre-Registration honestly and to the best of your knowledge. If your household knows but refuses to give any required information, it will not be eligible to receive D-SNAP benefits. When you are interviewed, you must verify your identity. You may be required to verify your residency or place of work in the disasterarea at the time of the disaster, household composition, and any disater-related expenses. You can authorized someone outside of your household to apply for, receive, or use your D-SNAP benefits.",
        instructionText: "INSTRUCTION: Complete the Pre-Registration honestly and to the best of your knowledge. If your household knows but refuses to give any required information, it will not be eligible to receive D-SNAP benefits. When you are interviewed, you must verify your identity. You may be required to verify your residency or place of work in the disasterarea at the time of the disaster, household composition, and any disater-related expenses. You can authorized someone outside of your household to apply for, receive, or use your D-SNAP benefits.",
        disclaimerText: "Disclaimer: If you received SNAP benefits at the time of the disaster, then you may not be eligible for D-SNAP benefits.",
        situtationSubHeaderText: "The case will be in the name of the Head of Household enterred below. <br/><br/>",
        situtationSubHeaderText2: "INSTRUCTION: Complete the Pre-Registration honestly and to the best of your knowledge. If your household knows but refuses to give any required information, it will not be eligible to receive D-SNAP benefits. When you are interviewed, you must verify your identity. You may be required to verify your residency or place of work in the disaster area at the time of the disaster, household composition, and any disater-related expenses. You can authorized someone outside of your household to apply for, receive, or use your D-SNAP benefits."
      };
    case ES:
      return {
        title: "Situación del hogar",
        header: "Situación del hogar",
        yesNoOptions: ["Sí", "No"],
        timeDisasterLabel: "¿Su hogar vivía en el área del desastre en el momento del desastre?",
        selfEmployementLabel: "¿El desastre dañó o destruyó su casa o propiedad de trabajo por cuenta propia?",
        resultDisaster: "¿Tiene su hogar algún gasto de bolsillo como resultado del desastre?",
        buyingFood: "Mientras se limpian los efectos del desastre, ¿su hogar comprará alimentos?",
        houseHoldIncome: "¿El desastre retrasó, redujo o detuvo los ingresos de su hogar?",
        dueToDisaster: "¿Tiene su hogar dinero en efectivo o dinero en el banco al que no puede acceder porque el banco estuvo o estuvo cerrado durante 16 días o más entre el 28 de octubre de 2020 y el 26 de noviembre de 2020 debido al desastre?",
        foodAssistance: "¿Su hogar recibe actualmente SNAP?",
        foodBenefits: "¿Recibió beneficios SNAP en noviembre de 2020?",
        workingDiasterLabel: "¿Su hogar estaba trabajando en el área del desastre en el momento del desastre?",
        HouseholdSitutaionErrMsg: "Por favor, elija una opción.",
        workEmployementLabel: "¿El desastre dañó o destruyó su lugar de trabajo o propiedad de trabajo por cuenta propia?",
        labelstate: "Estado",
        labelcounty: "Condado",
        stateCountyLabel: "Por favor ingrese el estado y el condado.",
        countycode: [
          "Autauga",
          "Baldwin",
          "Barbour",
          "Bibb",
          "Blount",
          "Bullock",
          "Butler",
          "Calhoun",
          "Chambers",
          "Cherokee",
          "Chilton",
          "Choctaw",
          "Clarke",
          "Clay",
          "Cleburne",
          "Coffee",
          "Colbert",
          "Conecuh",
          "Coosa",
          "Covington",
          "Crenshaw",
          "Cullman",
          "Dale",
          "Dallas",
          "DeKalb",
          "Elmore",
          "Escambia",
          "Etowah",
          "Fayette",
          "Franklin",
          "Geneva",
          "Greene",
          "Hale",
          "Henry",
          "Houston",
          "Jackson",
          "Jefferson",
          "Lamar",
          "Lauderdale",
          "Lawrence",
          "Lee",
          "Limestone",
          "Lowndes",
          "Macon",
          "Madison",
          "Marengo",
          "Marion",
          "Marshall",
          "Mobile",
          "Monroe",
          "Montgomery",
          "Morgan",
          "Perry",
          "Pickens",
          "Pike",
          "Randolph",
          "Russell",
          "Saint Clair",
          "Shelby",
          "Sumter",
          "Talladega",
          "Tallapoosa",
          "Tuscaloosa",
          "Walker",
          "Washington",
          "Wilcox",
          "Winston"
        ],
        statecode: ["AL"],
        subHeader: "El caso estará a nombre del Jefe del Hogar ingresado a continuación. <br/><br/> INSTRUCCIÓN: Complete la Preinscripción honesta y según su mejor conocimiento. Si su hogar sabe pero se niega a proporcionar cualquier información requerida, no será elegible para recibir beneficios de D-SNAP. Cuando sea entrevistado, deberá verificar su identidad. Es posible que se le solicite verificar su residencia o lugar de trabajo en el área de desastre en el momento del desastre, la composición del hogar y cualquier gasto relacionado con el desastre. Puede autorizar a alguien fuera de su hogar para solicitar, recibir o utilizar sus beneficios de D-SNAP.",
        instructionText: "INSTRUCCIÓN: Complete la Preinscripción honesta y según su mejor conocimiento. Si su hogar sabe pero se niega a proporcionar cualquier información requerida, no será elegible para recibir beneficios de D-SNAP. Cuando sea entrevistado, deberá verificar su identidad. Es posible que se le solicite verificar su residencia o lugar de trabajo en el área de desastre en el momento del desastre, la composición del hogar y cualquier gasto relacionado con el desastre. Puede autorizar a alguien fuera de su hogar para solicitar, recibir o utilizar sus beneficios de D-SNAP.",
        disclaimerText: "Aviso legal: Si recibió beneficios de SNAP en el momento del desastre, es posible que no sea elegible para recibir beneficios de D-SNAP.",
        situtationSubHeaderText: "El caso estará a nombre del Jefe del Hogar ingresado a continuación. <br/><br/>",
        situtationSubHeaderText2: "INSTRUCCIÓN: Complete la Preinscripción honesta y según su mejor conocimiento. Si su hogar sabe pero se niega a proporcionar cualquier información requerida, no será elegible para recibir beneficios de D-SNAP. Cuando sea entrevistado, deberá verificar su identidad. Es posible que se le solicite verificar su residencia o lugar de trabajo en el área de desastre en el momento del desastre, la composición del hogar y cualquier gasto relacionado con el desastre. Puede autorizar a alguien fuera de su hogar para solicitar, recibir o utilizar sus beneficios de D-SNAP."

      };
  }
}

export function MyhouseholdDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "My Household ",
        header: "About My Household ",
        howManyLabel: "Select how many people in your household, including yourself, who are living and eating with you that were affected by the disaster. ",
        numOfPplText: "Enter number of people below.",
        numOfPplErrMsg: "Please enter number the number of people.",
        dsnapHouseHelp: "If you are temporarily staying with another household because of the disater, do not list members of that household.",
        memberTitle: "Member 1",
        memberSuffixLabel: "Suffix",
        memberSuffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dsnapRaceOptions: [
          "American Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
          "Other",
        ],
        genderErrmsg: "Please choose gender",
        raceLabel: "What is this member's race?",
        raceHelp:
          "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits. <br/><br/> Please select all that apply.",
        otherRaceErrmsg: "Please enter other race information",
        otherRaceErrMsgLength:
          "Please enter other race information greater than 3 characters or less than 250 characters",
        snpaNote:
          "Note: Only those who disclose their citizenship or establish satisfactory immigration status will receive benefits if otherwise eligible. The decision not to furnish this information will not affect the eligibility of the other household members. Information on the immigration status of those who do not apply will not be verified with United States Citizen and Immigration Services (USCIS). Parents who do not disclose their status may apply for their children. ALL HOUSEHOLD MEMBERS MUST BE LISTED AND ALL QUESTIONS ANSWERED, EVEN FOR THOSE WHO ARE NOT DISCLOSING CITIZENSHIP OR IMMIGRATION STATUS. The income of all members is considered regardless whether or not they apply or are eligible.",
        incomeOptionLabel:[
          "GRANTEE",
          "No income to report",
          "Decline to answer"
        ]

      };
    case ES:
      return {
        title: "Mi Hogar",
        header: "Acerca de Mi Hogar",
        howManyLabel: "Seleccione cuántas personas en su hogar, incluyéndose a usted mismo, están viviendo y comiendo con usted y fueron afectadas por el desastre.",
        numOfPplText: "Ingrese el número de personas a continuación.",
        numOfPplErrMsg: "Por favor, ingrese el número de personas.",
        dsnapHouseHelp: "Si está alojado temporalmente con otro hogar debido al desastre, no incluya a los miembros de ese hogar.",
        memberTitle: "Miembro 1",
        memberSuffixLabel: "Sufijo",
        memberSuffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dsnapRaceOptions: [
          "Indígena americano / Nativo de Alaska",
          "Asiático",
          "Negro / Afroamericano",
          "Nativo de Hawai / Otro Isla del Pacífico",
          "Blanco",
          "Prefiero no responder",
          "Otro",
        ],
        genderErrmsg: "Por favor, elija el género.",
        raceLabel: "¿Cuál es la raza de este miembro?",
        raceHelp: "Pedimos su raza para garantizar un trato igualitario y acceso para todos. Su respuesta no afectará su elegibilidad para recibir beneficios de SNAP. <br/><br/> Por favor, seleccione todas las opciones que correspondan.",
        otherRaceErrmsg: "Por favor, ingrese otra información de raza.",
        otherRaceErrMsgLength: "Por favor, ingrese otra información de raza con más de 3 caracteres o menos de 250 caracteres.",
        snpaNote: "Nota: Solo aquellos que divulguen su ciudadanía o establezcan un estado migratorio satisfactorio recibirán beneficios si son elegibles de otra manera. La decisión de no proporcionar esta información no afectará la elegibilidad de los demás miembros del hogar. La información sobre el estado migratorio de aquellos que no apliquen no se verificará con el Servicio de Ciudadanía e Inmigración de los Estados Unidos (USCIS). Los padres que no divulguen su estado pueden aplicar para sus hijos. TODOS LOS MIEMBROS DEL HOGAR DEBEN SER ENUMERADOS Y TODAS LAS PREGUNTAS DEBEN SER RESPONDIDAS, INCLUSO PARA AQUELLOS QUE NO DIVULGAN SU CIUDADANÍA O ESTADO MIGRATORIO. El ingreso de todos los miembros se considera independientemente de si aplican o son elegibles.",
        incomeOptionLabel:[
          "GRANTEE",
          "No income to report",
           "Decline to answer"
        ]
      };
  }
}
export function incomeSectionMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Income",
        header: "About Your Household's Income",
        aboutIncomeBefore: "Does anyone in the household have any source/type of ",
        moneyYougettitle:
          "This includes income you receive from employment, government sources, or other sources.",
        aboutIncomeTrigger: "income or benefits",
        incomeHelp:
          "Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.",
        questionClearOption: "Decline to answer",
        incomeOptions: [
          "TANF",
          "Self-Employment",
          "Job Income",
          "Social Security (SSA / RSDI)",
          "Supplemental Security Income",
          "Veteran's Benefits",
          "Unemployment Compensation",
          "Workman's Compensation",
          "Interest Dividends from Stock and Bonds",
          "Military Allotment",
          "Pension or Retirement Income",
          "Railroad Retirement",
          "HUD Utility Assistance",
          "Income from Land Rental or Rental Property",
          "Child Support",
          "Alimony",
          "Contributions from Friends or Relatives",
          "Roomer Income",
          "Boarder Income",
          "Other",
        ],
        tanfTargetValue: "TANF",
        amountErrMsg: "Please enter an amount.",
        costBetween: "per",
        unitsOptions: ["Month", "Every two weeks", "Week"],
        addAnotherButtonLabel: "+ Add Another",
        selfemeploymentTargetValue: "Self-Employment",
        socialTargetValue: "Social Security (SSA / RSDI)",
        jobTargetValue: "Job Income",
        SSITargetValue: "Supplemental Security Income",
        veteranTargetValue: "Veteran's Benefits",
        unEmploymentTargetValue: "Unemployment Compensation",
        childSuppTargetValue: "Child Support",
        veteranBenefitsTargetValue: "Veterans Benefits",
        rentalIncomeTargetValue: "Rental Income",
        workerCompTargetValue: "Workers Compensation",
        otherTargetValue: "Other",
        alimonyTargetValue: "Alimony",
        cotributionsTargetValue: "Contributions from Friends or Relatives",
        roomerTargetValue: "Roomer Income",
        boaderTargetValue: "Boader Income",
        workmanTargetValue: "Workman's Compensation",
        wagesTargetValue: "Wages",
        selfemeploymentTargetValue: "Self-Employment",
        interestTargetValue: "Interest Dividends from Stock and Bonds",
        pensionTargetValue: "Pension or Retirement Income",
        militaryTargetValue: "Military Allotment",
        railroadTargetValue: "Railroad Retirement",
        hudUtilityTargetValue: "HUD Utility Assistance",
        incomeLandRentalTargetValue:
          "Income from Land Rental or Rental Property",
        weekMonthOptions: ["Month", "Week"],
        unitsPerLabel: "per",
        unitsOptions: ["Month", "Every two weeks", "Week"],
        anotherChildsupport: "+ Add Another Child Support",
        questionButtonLabel: "+ Add Another Other",
        summaryIncomeTitle: "Income Summary",
        summaryIncomeHeader: "Income Summary",
        optionErrMsg: "Please select an option below.",
        combineIncomeoptions: [
          "TANF",
          "Self-Employment",
          "Job Income",
          "Social Security (SSA / RSDI)",
          "Supplemental Security Income",
          "Veteran's Benefits",
          "Unemployment Compensation",
          "Workman's Compensation",
          "Interest Dividends from Stock and Bonds",
          "Military Allotment",
          "Pension or Retirement Income",
          "Railroad Retirement",
          "HUD Utility Assistance",
          "Income from Land Rental or Rental Property",
          "Child Support",
          "Alimony",
          "Contributions from Friends or Relatives",
          "Roomer Income",
          "Boarder Income",
          "Other",

        ],
        lookIncorrectLabel:
          "Please verify the Income below is correct. If any income is incorrect click edit to correct.",
        anotherHouseholdIncomeLabel:
          "Add the income of another household member",
        incomeOf: "Income of ",
        editButtonLabel: "Edit",
        perLabel: "Per",
        anotherHouseholdIncomeLabel:
          "Add the income of another household member",
        questionClearOptionTwo: "No income to report",
        monthlyAmount: "Monthly Amount",
        weeklyAmount: "Weekly Amount",
        everyTwoWeeks: "Every two weeks",
       incomeBenefitLabel: "- Income & Benefit Details"

      };
    case ES:
      return {
        title: "Ingreso",
        header: "Acerca de los ingresos de su hogar",
        aboutIncomeBefore: "¿Alguien en el hogar tiene alguna fuente/tipo de",
        moneyYougettitle: "Esto incluye los ingresos que recibe del empleo, fuentes gubernamentales u otras fuentes.",
        aboutIncomeTrigger: "ingresos o beneficios",
        incomeHelp:
          "Los ingresos son dinero que recibes por trabajar. Los beneficios y contribuciones son dinero que recibes de una fuente distinta al empleo.",
        questionClearOption: "Negarse a contestar",
        incomeOptions: [
          "TANF",
          "Auto-empleo",
          "Ingresos laborales",
          "Seguridad Social (SSA/RSDI)",
          "Seguridad de Ingreso Suplementario",
          "Beneficios para veteranos",
          "Compensacion por desempleo",
          "Compensación laboral",
          "Dividendos de intereses de acciones y bonos",
          "Asignación militar",
          "Ingresos de Pensión o Jubilación",
          "Jubilación ferroviaria",
          "Asistencia de servicios públicos de HUD",
          "Ingresos por arrendamiento de terrenos o propiedades en alquiler",
          "Manutención de los hijos",
          "Pensión alimenticia",
          "Aportes de Amigos o Familiares",
          "Ingresos del inquilino",
          "Ingresos fronterizos",
          "Otro",
        ],
        tanfTargetValue: "TANF",
        amountErrMsg: "Por favor ingrese una cantidad.",
        costBetween: "por",
        unitsOptions: ["Mes", "Cada dos semanas", "Semana"],
        addAnotherButtonLabel: "+ Agregar otro",
        selfemeploymentTargetValue: "Auto-empleo",
        socialTargetValue: "Seguridad Social (SSA / RSDI)",
        jobTargetValue: "Ingresos laborales",
        SSITargetValue: "Seguridad de Ingreso Suplementario",
        veteranTargetValue: "Beneficios para veteranos",
        unEmploymentTargetValue: "Compensacion por desempleo",
        childSuppTargetValue: "Manutención de los hijos",
        veteranBenefitsTargetValue: "Beneficios para veteranas",
        rentalIncomeTargetValue: "Ingresos de alquiler",
        workerCompTargetValue: "Compensación para trabajadores",
        otherTargetValue: "Otra",
        alimonyTargetValue: "Pensión alimenticia",
        cotributionsTargetValue: "Contribuciones de amigas o familiares",
        roomerTargetValue: "Ingresos del inquilino",
        boaderTargetValue: "Ingresos fronterizos",
        workmanTargetValue: "Compensación laboral",
        wagesTargetValue: "Salarios",
        selfemeploymentTargetValue: "Auto-empleo",
        interestTargetValue: "Dividendos de intereses de acciones y bonos",
        pensionTargetValue: "Ingresos de Pensión o Jubilación",
        militaryTargetValue: "Asignación militar",
        railroadTargetValue: "Jubilación ferroviaria",
        hudUtilityTargetValue: "Asistencia de servicios públicos de HUD",
        incomeLandRentalTargetValue:
          "Rentas por arrendamiento de terrenos o propiedades en alquiler",
        weekMonthOptions: ["Mes ,Semana"],
        unitsPerLabel: "por",
        unitsOptions: ["Mes", "Cada dos semanas", "Semana"],
        anotherChildsupport: "+ Agregar otra manutención infantil",
        questionButtonLabel: "+ Agregar otra otra",
        summaryIncomeTitle: "Resumen de ingresos",
        summaryIncomeHeader: "Resumen de ingresos",
        optionErrMsg: "Por favor, seleccione una opción a continuación.",
        combineIncomeoptions: [
          "TANF",
          "Auto-empleo",
          "Ingresos laborales",
          "Seguridad Social (SSA/RSDI)",
          "Seguridad de Ingreso Suplementario",
          "Beneficios para veteranos",
          "Compensacion por desempleo",
          "Compensación laboral",
          "Dividendos de intereses de acciones y bonos",
          "Asignación militar",
          "Ingresos de Pensión o Jubilación",
          "Jubilación ferroviaria",
          "Asistencia de servicios públicos de HUD",
          "Ingresos por arrendamiento de terrenos o propiedades en alquiler",
          "Manutención de los hijos",
          "Pensión alimenticia",
          "Aportes de Amigos o Familiares",
          "Ingresos del inquilino",
          "Ingresos fronterizos",
          "Otro",
        ],
        lookIncorrectLabel:
          "Verifique que los ingresos a continuación sean correctos. Si algún ingreso es incorrecto, haga clic en editar para corregirlo.",
        anotherHouseholdIncomeLabel:
          "Suma los ingresos de otro miembro del hogar",
        incomeOf: "Ingresos de ",
        editButtonLabel: "Editar",
        perLabel: "Por",
        anotherHouseholdIncomeLabel:
          "Suma los ingresos de otro miembro del hogar",
        questionClearOptionTwo: "No hay ingresos que reportar",
        monthlyAmount: "Cantidad mensual",
        weeklyAmount: "Cantidad Semanal",
        everyTwoWeeks: "Cada dos semanas",
        incomeBenefitLabel: "- Detalles de ingresos y beneficios"

      }
  }
}

export function resourcesDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Resources",
        header: "About Your Household's Resource",
        subHeader: "List all liquid resources your household will able to get to during the disaster period (october 28,2020 and november 26, 20220)",
        combineResourcesoptions: [
          "Cash on Hand",
          "Saving Accounts",
          "Checking Accounts",
        ],
        optionErrMsg: "Please select an option below.",
        addAnotherButtonLabel: "+ Add Another",
        cashOnHand: "What is the account for cash on hand ?",
        CheckingAccounts: "What is the account for checking accounts?",
        SavingAccounts: "What is the account for saving accounts?",
        enterAmount: "Please enter an Amount.",
        summaryheader: "Resources Summary",
        editButtonLabel: "Edit",
        amountLabel: "Amount",
        subHeadersummary: "Please verify the Resources below is correct. If any Resources is Incorrect click edit to correct.",
        totalResources: "Total Resources"
      };
    case ES:
      return {
        title: "Recursos",
        header: "Acerca de los recursos de tu hogar",
        subHeader: "Enumera todos los recursos líquidos a los que tu hogar podrá acceder durante el período de desastre (28 de octubre de 2020 y 26 de noviembre de 20220)",
        combineResourcesoptions: [
          "Efectivo en mano",
          "Cuentas de ahorro",
          "Cuentas corrientes"
        ],
        optionErrMsg: "Por favor, selecciona una opción a continuación.",
        addAnotherButtonLabel: "+ Agregar Otro",
        cashOnHand: "¿Cuál es la cuenta para efectivo en mano?",
        CheckingAccounts: "¿Cuál es la cuenta para cuentas corrientes?",
        SavingAccounts: "¿Cuál es la cuenta para cuentas de ahorro?",
        enterAmount: "Por favor ingrese una cantidad",
        summaryheader: "Resumen de Recursos",
        editButtonLabel: "Editar",
        amountLabel: "Cantidad",
        subHeaderSummary: "Por favor, verifica que los recursos a continuación sean correctos. Si algún recurso es incorrecto, haz clic en Editar para corregirlo.",
        totalResources: "Total de Recursos"
      };
  }
}
export function expensesDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Expenses",
        header: "Tell Us about Your Expenses",
        typesDisaster: "What type of disaster related  expenses do you have ?(select all that apply)",
        combineExpensesoptions: [
          "Expenses to protect property during disaster",
          "Expense to repair or replace items for home or self -employement property",
          "Dependent care due to disaster",
          "Food destoryed in disaster",
          "Funeral/medical expenses due to disaster",
          "Moving and storage expenses due to disaster",
          "Other disaster-related expenses",
          "Temporary shelter expenses",
        ],
        property: "What is the expenses to protect property during disaster?",
        selfEmployement: " What is the expense to repair or replace items for home or self -employement property?",
        dependentCare: " What is the dependent care due to disaster ? ",
        foodDestoryed: " What is the food destoryed in disaster ?",
        funeralMedical: " What is the funeral/medical expenses due to disaster?",
        movingStorage: " What is the Moving and storage expenses due to disaster?",
        disaterRelated: " What is the other disaster-related expenses?",
        temporary: "What is the Temporary shelter expenses ?",
        expenses: "Please enter the expense.",
        dsnap: "Your household must have disaster related expense in order to qualify for D-SNAP.",
        description: " I certify under penalty of perjury that my household has paid or expects to pay out of pocket disaster-related expense(which include food loss) as a result of the hurricance that occured in my country of residence on October 28, 2020. I Will not be reimbursed for these expense during the period of october 28, 2020 through November 26,2020.",
        SignIn: "Sign by typing your full name below.",
        subHeadersummary: "Please verify the Expenses below is correct. If any Expenses is Incorrect click edit to correct.",
        totalExpenses: "Total Expenses",
        editButtonLabel: "Edit",
        summaryheader: "Expenses Summary",
        amountLabel: "Amount",
      };
    case ES:
      return {
        title: "Gastos",
        header: "Cuéntanos sobre tus Gastos",
        typesDisaster: "¿Qué tipos de gastos relacionados con desastres tienes? (selecciona todos los que correspondan)",
        combineExpensesoptions: [
          "Gastos para proteger la propiedad durante el desastre",
          "Gastos para reparar o reemplazar artículos para el hogar o propiedad de autoempleo",
          "Cuidado de dependientes debido al desastre",
          "Alimentos destruidos en el desastre",
          "Gastos funerarios/médicos debido al desastre",
          "Gastos de mudanza y almacenamiento debido al desastre",
          "Otros gastos relacionados con el desastre",
          "Gastos de refugio temporal"
        ],
        property: "¿Cuáles son los gastos para proteger la propiedad durante el desastre?",
        selfEmployement: "¿Cuáles son los gastos para reparar o reemplazar artículos para el hogar o propiedad de autoempleo?",
        dependentCare: "¿Cuáles son los gastos de cuidado de dependientes debido al desastre?",
        foodDestoryed: "¿Cuáles son los alimentos destruidos en el desastre?",
        funeralMedical: "¿Cuáles son los gastos funerarios/médicos debido al desastre?",
        movingStorage: "¿Cuáles son los gastos de mudanza y almacenamiento debido al desastre?",
        disaterRelated: "¿Cuáles son los otros gastos relacionados con el desastre?",
        temporary: "¿Cuáles son los gastos de refugio temporal?",
        expenses: "Por favor, ingresa los gastos.",
        dsnap: "Tu hogar debe tener gastos relacionados con desastres para calificar para D-SNAP.",
        description: "Certifico bajo pena de perjurio que mi hogar ha pagado o espera pagar gastos relacionados con desastres (que incluyen pérdidas de alimentos) como resultado del huracán que ocurrió en mi país de residencia el 28 de octubre de 2020. No seré reembolsado por estos gastos durante el período del 28 de octubre de 2020 al 26 de noviembre de 2020.",
        SignIn: "Firma escribiendo tu nombre completo a continuación.",
        subHeadersummary: "Por favor, verifica que los gastos a continuación sean correctos. Si algún gasto es incorrecto, haz clic en Editar para corregirlo.",
        totalExpenses: "Total de Gastos",
        editButtonLabel: "Editar",
        summaryheader: "Resumen de Gastos",
        amountLabel: "Cantidad",
      };
  }
}
export function signAndSubmitDsnapMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Sign & Submit",
        header: "Sign & Submit",
        signatureErrorMsg:
          "Please sign the DSNAP Pre-Registration form.",
        signatureErrorMsg1:"Signature needs to match the name in the application.",
        signYourAppllication:
          "Sign your D-SNAP Pre-Registration form by typing your full name below. (Required)",
        subSectionLabel: "Submitted",
        submitLabelFinal: "Your SNAP Application Has Been Submitted!",
        signSubmitSub:
          "You completed the D-SNAP Pre-screener questions. To submit the pre-screener, read and sign.",
        sucessText: "Your DSNAP Pre-screener is submitted successfully.",
        headerText: "Which Household Member Do You Wish To Delete",
        householdDeleteLabel: "Delete this household member",
        changingLabel:
          "Changing this value will delete the previous entered values in upcoming sections.",
        submittedHeaderText: "Submitted Successfully",
        congratulationText: "Congratulation!",
        successfullText: "Your D-SNAP Pre-Registration has been submitted successfully.",
        registrationText: "Your D-SNAP Pre-Registration number is ",
        okText: "Ok"
      };
    case ES:
      return {
        title: "Firmar y enviar",
        header: "Firmar y enviar",
        signatureErrorMsg: "Por favor firme el formulario de preinscripción de DSNAP.",
        signatureErrorMsg1:"La firma debe coincidir con el nombre en la solicitud.",
        signYourAppllication:
          "Firme su formulario de preinscripción de D-SNAP escribiendo su nombre completo a continuación.(Obligatorio)",
        subSectionLabel: "Enviada",
        submitLabelFinal: "Su solicitud SNAP ha sido enviada!",
        signSubmitSub:
          "Usted completó las preguntas previas a la evaluación de D-SNAP. Para presentar el preselección leer y firmar.",
        sucessText: "Su preevaluación DSNAP se envió correctamente.",
        headerText: "Qué miembro del hogar desea eliminar?",
        householdDeleteLabel: "Eliminar esta miembro del hogar",
        changingLabel:
          "Cambiar este valor eliminará los valores ingresados ​​anteriormente en las próximas secciones.",
        submittedHeaderText: "Enviado satisfactoriamente",
        congratulationText: "Felicitaciones!",
        successfullText: "Su preinscripción D-SNAP se ha enviado correctamente.",
        registrationText: "Su número de preinscripción de D-SNAP es",
        okText: "De acuerdo"
      };
  }
}
export function houseHoldSectionMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        houseTitle: "My Household",
        houseHeader: "About My Household",
        howManyLabel: "How many people live in your household, including you?",
        snapHouseHelp: "If you plan to purchase and prepare more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not purchase and prepare meals with them.",
        tanfHouseHelp: "TANF Benefits: Include the children for whom you want to apply (must be living with you and be under the age of 18 or under age 19  if s/he is a full-time student in a secondary school or equivalent level of vocational or technical training. Secondary school may include public, private, church and home schools for grades 9 through 12.) and their brothers and sisters. Also include any other parent of the children in your home and your spouse if living with you.",
        comboHouseHelp: "If you plan to purchase and prepare more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not purchase and prepare meals with them.",
        numOfPplText: "Enter number of people below.",
        numOfPplErrMsg: "Please enter number of household.",
        houseErrMsg: "Please enter number of household",
        houseMemberTitle: "Member ",
        houseFirstName: "What is this member's First name?",
        firstErrmsg: "Please enter the First Name.",
        firstNameErrMsgLength:
          "Please enter First Name less than or equal to 15 characters",
        houseMiddleLabel: "What is this member's Middle Name?",
        houseMiddleName: "Please enter Middle Name",
        middleNameErrMsgLength:
          "Please enter Middle Name less than or equal to 15 characters",
        houseLastNameLabel: "What is this member's Last Name?",
        membersRace: "What is this member’s race?",
        membersEthnicity: "What is this member’s ethnicity?",
        enterLastname: "Please enter the Last Name.",
        lastNameErrMsgLength:
          "Please enter Last Name  less than or equal to 20 characters",
        suffixLabel: "Suffix",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        houseDobLabel: "What is this member's Date of Birth?",
        maritialStatusDteLabel: "What is this member's date ",
        houseDobAlertMsg: "Please enter a valid date of birth.",
        personGenLabel: "What is this member's gender?",
        genderOptios: ["Male", "Female"],
        ssnQLabel: "What is this member's Social Security Number?",
        ssnDntLabel: "Don't have one",
        ssnErrLabel: "Please enter a valid Social Security Number.",
        inlineHelpSSN:
          "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
        yesnoOptions: ["Yes", "No"],
      };
    case ES: //spanish
      return {
        houseTitle: "Mi núcleo familiar",
        houseHeader: "Sobre mi núcleo familiar",
        howManyLabel:
          "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
        numOfPplText: "Ingrese el número de personas a continuación.",
        numOfPplErrMsg: "Por favor ingrese el número de hogar.",
        snapHouseHelp: "Beneficios de SNAP: si planea comprar y preparar comidas con ellos más de 2/3 de sus comidas con otras personas, deben estar en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos. Incluya los niños para quienes desea presentar la solicitud y sus hermanos y hermanas.",
        tanfHouseHelp: "Beneficios TANF: incluya los niños para quienes desea solicitar (deben vivir con usted y ser menores de 18 años o menores de 19 años si es estudiante de tiempo completo en una escuela secundaria o nivel equivalente de educación vocacional o técnica). La escuela secundaria puede incluir escuelas públicas, privadas, religiosas y en el hogar para los grados 9 a 12.) y sus hermanos y hermanas. Incluya también a cualquier otro padre de los niños en su hogar y a su cónyuge si vive con usted.",
        comboHouseHelp: "Si planea comprar y preparar comidas con ellos más de 2/3 de sus comidas con otras personas, deben estar en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos. Incluya los niños para quienes desea presentar la solicitud y sus hermanos y hermanas.",
        houseHelp:
          "Si compra y prepara más de 2/3 de sus comidas con otras personas, deben ser en su hogar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su hogar incluso si usted no compra ni prepara comidas con ellos.",
        houseErrMsg: "Por favor, introduzca el número de hogar",
        houseMemberTitle: "miembro ",
        houseMemberHeader: "Su primer miembro del hogar es ",
        houseFirstName: "¿Cuál es el nombre de esta persona?",
        firstErrmsg: "Por favor, introduzca el primer nombre.",
        firstNameErrMsgLength:
          "Por favor, ingrese el primer nombre con 15 caracteres máximo  ",
        houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
        houseMiddleName: "Por favor ingrese un segundo nombre",
        middleNameErrMsgLength:
          "Por favor, ingrese el segundo nombre con 15 caracteres máximo",
        houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
        enterLastname: "Por favor ingrese el apellido.",
        lastNameErrMsgLength:
          "Por favor, ingrese los apellidos con 20 caracteres máximo",
        suffixLabel: "Sufijo",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "¿Cuál es la relación de esta persona con usted?",
        spouseDisplay: "Cónyuge",
        motherDisplay: "Madre",
        faterDisplay: "Padre",
        daughterDisplay: "Hija",
        sonDisplay: "Hijo",
        brotherDisplay: "Hermano",
        sisterDisplay: "Hermana",
        grandMaDisplay: "Abuela",
        grandFaDisplay: "Abuelo",
        grandDaugther: "Nieta",
        grandSon: "Nieto",
        otherDisplay: "Otro",
        houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        houseDobAlertMsg: "Por favor ingresa una fecha de nacimiento valida.",
        personGenLabel: "¿Cuál es el sexo de esta persona?",
        genderOptios: ["Masculino", "Femenino"],
        citiBeforeQLabel: "¿Es esta persona una ",
        citiTriggerQLabel: "Ciudadana estadounidense",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
        citizenHelpComboApplication:
          " 'Para ser elegible para SNAP, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede solicitar para miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        tanfCitizenHelp:
          "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        comboCitizenHelp:
          "Para ser elegible para SNAP/TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        citizenInhelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        citizenOptions: ["Sí", "No"],
        ssnQLabel: "¿Cuál es el número de seguro social de esta persona?",
        ssnDntLabel: "No tiene uno",
        ssnErrLabel: "Por favor ingrese un número de seguro social válido.",
        hholdHeader: "Detalles de mi núcleo familiar",
        disabilityQLabel: "¿Alguna persona tiene una ",
        disabilityQlabel2: " mental o física ",
        disabilityQTriggerLabel: "discapacidad",
        disabilityHelp:
          "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
        disablilityCler: "Ninguna",
        inlineHelpSSN:
          "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan dar su SSN o estado de ciudadanía.",
        immigrationStatus:
          "¿Esta persona tiene un estatus migratorio satisfactorio?",
        immigrationOptions: ["Sí", "No"],
        yesnoOptions: ["Sí", "No"],
        membersRace: "¿Cuál es la raza de este miembro?",
        membersEthnicity: "¿Cuál es el origen étnico de este miembro?",
      };

    default:
      return;
  } //house hold ending
}
export function applySectionStaticMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        aboutTitle: "About " + name,
        aboutMemberTitle: "About Member " + name,
        labelMiddle: "What is " + name + "'s middle name?",
        labelLastName: "What is " + name + "'s last name?",
        labelRelation: "What is " + name + "'s relationship to you?",
        dateOfBirthQLabel: "What is " + name + "'s date of birth?",
        genderQLabel: "What is " + name + "'s gender?",
        UScitizenLabelForTanf: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        UScitizenLabelForSnap: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for SNAP assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        UScitizenLabelForCombo: {
          textBefore: "Is " + name + " a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for SNAP/TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        ethnicityLabel: "What is " + name + "'s ethnicity?",
        raceLabel: "What is " + name + "'s race?",
        ssnQLabel: "What is " + name + "'s Social Security Number?",
        immigrationStatusLabel:
          "Do " + name + " hold a satisfactory immigration status?",
        personMiddleName: "What is this member's middle name?",
        personLastName: "What is this member's last name?",
        personRelation: "What is this member's relationship to you?",
        personDobLabel: "What is this member's date of birth?",
        personGenderLabel: "What is this member's gender?",
        personSSNLabel: "What is this member's Social Security Number?",
        personEthnicityLabel: "What is your ethnicity?",
        personRaceLabel: "What is your race?",
        personUScitizenLabel: {
          textBefore: "Is this Person a ",
          triggerText: "US citizen",
          textAfter: "?",
          helpText:
            "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        },
        personImmigrationStatusLabel:
          "Do you hold a satisfactory immigration status?",
      };
    case ES:
      return {
        aboutTitle: "Sobre " + name,
        aboutMemberTitle: "Acerca de miembro " + name,
        labelMiddle: "¿Cuál es el segundo nombre de  " + name + "?",
        labelLastName: "¿Cuál es el apellido de " + name + "?",
        labelRelation: "¿Cuál es la relación de " + name + "con usted?",
        dateOfBirthQLabel: "¿Cuál es la fecha de nacimiento de " + name + "?",
        genderQLabel: "¿Cuál es el sexo de " + name + "?",
        UScitizenLabelForTanf: {
          textBefore: "¿Es " + name + " un ",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        },
        UScitizenLabelForSnap: {
          textBefore: "es" + name + " uno ",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia SNAP, la persona debe ser ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        },
        UScitizenLabelForCombo: {
          textBefore: "es " + name + " uno ",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia SNAP/TANF, la persona debe ser ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        },
        ethnicityLabel: "¿Cuál es el origen étnico de " + name + "?",
        raceLabel: "¿Cuál es la raza de " + name + "?",
        ssnQLabel: "¿Cuál es el número de seguro social de " + name + "?",
        immigrationStatusLabel:
          "¿Tiene " + name + " un estatus migratorio satisfactorio?",
        personMiddleName: "¿Cuál es el segundo nombre de esta persona?",
        personLastName: "¿Cuál es el apellido de esta persona?",
        personRelation: "¿Cuál es la relación de esta persona con usted?",
        personDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        personGenderLabel: "¿Cuál es el sexo de esta persona?",
        personSSNLabel: "¿Cuál es el número de seguro social de esta persona?",
        personEthnicityLabel: "Cuál es tu etnia?",
        personRaceLabel: "¿Cuál es su raza?",
        personUScitizenLabel: {
          textBefore: "¿Es esta persona un",
          triggerText: "Ciudadana estadounidense",
          textAfter: "?",
          helpText:
            "Para ser elegible para recibir asistencia TANF, la persona debe ser un ciudadano natural o naturalizado de los Estados Unidos o un extranjero calificado. Si no está seguro de su estado de ciudadanía o el de otro miembro de su hogar, el DHR trabajará con usted para determinar su elegibilidad.",
        },
        personImmigrationStatusLabel:
          "¿Tiene un estatus migratorio satisfactorio?",
      };

    default:
      return;
  }
}
export function houseHoldMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        title: "Member " + name,
        header: "About Member " + name,
        conditionalLabel:
          "Who does " + name + " pay the dependent expenses for?",
        subHeader: "List each household member’s name, social security number (SSN), date of birth, race and sex.",
      };
    case ES:
      return {
        title: "miembro " + name,
        header: "Sobre miembro " + name,
        conditionalLabel: "pagar los gastos dependientes por " + name + " ?",
      };

    default:
      return;
  }
}

export function aboutMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "About Me",
        header: "About Me",
        subHeader:
          "This section should be completed by/for the applicant. An applicant is generally an adult applying for benefits for themselves and/or other household members. <br/><br/> Note: The case and EBT card information will be in the name entered below.",
        tanfSubHeader:
          "The case will be in the name of the Grantee listed here.",
        comboSubHeader1:
          "This section should be completed by/for the applicant. An applicant is generally an adult applying for benefits for themselves and/or other household members. <br/><br/>",
        comboSubHeader2:
          "Note: The case and EBT card information will be in the name entered below.",
        labelFirstName: "What is your first name?",
        firstNameErrMsg: "Please enter your first name.",
        firstNameErrMsgLength:
          "Please enter first name less than or equal to 15 characters",
        middleName: "What is your middle name?",
        middleNameErrMsg: "Please enter middle name",
        middleNameErrMsgLength:
          "Please enter middle name less than or equal to 15 characters",
        lastNamelabel: "What is your last name?",
        lastNameErrMsg: "Please enter your last name.",
        lastNameErrMsgLength:
          "Please enter last name  less than or equal to 20 characters",
        labelSuffix: "Suffix",
        accOtherErrormsg: "Enter your accommodation details",
        accOtherLengthErrormsg: "Please enter at least 3 characters",
        accApplyErrormsg: "Please select an option",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "What is your date of birth?",
        tanfContactInfoHelpLine:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit’ and to continue your application, please click 'Save & Continue’",
        dobAlertMsg: "Please enter a valid date of birth.",
        ssnLabel: "What is your Social Security Number?",
        inputClearLabel: "Don’t have one",
        ssnErrMsg: "Please enter a valid Social Security Number.",
        headerMyContactInfo: "My Contact Info",
        contactInformation1:
          "To submit an online SNAP application you must provide a mailing address and phone number. If you have any questions please call  DHR Assistance Line at ",
        contactInformation2: "or go to your local",
        contacInformationLink: "DHR office.",
        contactInfo1:
          "You have the right to file an application with only your name, address, and signature. If you choose to do so, please note we will be unable to automatically screen your application to determine if you qualify for expedited assistance as your application will not contain income or resource information. To sign and submit your application now, click 'Sign & Submit' or to continue your application, please click 'Save & Continue'.",
        contactInfo2: "",
        contactInfo4:
          "You have the right to file an application with only your name, address, and signature. Providing additional details now will help us determine eligibility and to automatically screen for SNAP expedited services. To submit your application click 'Sign & Submit' or continue the application by clicking 'Save & Continue'.",
        contacInfoLink: "Apply for SNAP benefits (food stamps).",
        contactInfo3: "",
        houselessLabel: "Is your household homeless?",
        interviewTypeLabel: "Do you prefer a face-to-face or telephone interview?",
        interviewTypeOptions: ["Face-to-Face", "Telephone"],
        accommodationLabel: "Does anyone in your household need assistance applying for SNAP benefits due to age, disability or other conditions that limit their ability to apply on their own?",
        houselessErrMsg: "Please select whether your having home or not",
        amountErrMsg: "Please enter a valid amount.",
        optionErrMsg: "Please select an option below.",
        houseLessOptions: ["Yes", "No"],
        yesOption: ["YES"],
        accommodationOptions: ["Yes", "No"],
        addrQuestionlabel: "What is your home address?",
        tanfAddrQuestionlabel: "What is your Home Address?",
        maritalStatus: "What is your marital Status?",
        materialStatusOptions: [
          "Single",
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        showDOBForMaterialStatus: [
          "Married",
          "Separated",
          "Divorced",
          "Widowed",
        ],
        validZipcode: "Please enter a valid Zip code.",
        alsoMailingLabel: "Is this also your mailing address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your mailing address?",
        zipCodeErrmsg: "Please enter a valid Zip code.",
        masZipCodeErrmsg:
          "This SNAP application is for Alabama residents. If you live in Alabama, please enter your Zip Code. If you do not live in Alabama, you must apply in your state.",
        poBoxErrmsg: "PO Box cannot be accepted as Home Address",
        citiErrmsg: "Please enter a valid City.",
        addressErrmsg: "Please enter a valid Address.",
        mailingAddressErrmsg: "Please enter a mailing address.",
        homeAddressErrmsg: "Please enter a home address.",
        bestPhoneqlabel: "What is the best phone number to reach you?",
        appointmentPreferenceTitle: "<b>Appointment Preferences</b>",
        preferredPhoneQLabel: "What is your preferred phone number?",
        preferredDayQLabel: "Please select your preferred day(s).",
        preferredDayInlineLabel:
          "All appointments are subject to the availability of the ALDHR worker.",
        preferredDayOptions: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Same Day",
        ],
        preferredDayErrMessage: "Please select a day",
        preferredTimeQLabel: "Please select AM and/or PM.",
        preferredTimeOptions: ["AM", "PM"],
        preferredTimeErrMessage: "Please select AM or PM",
        inlineHelp:
          "Please enter a phone number if you have one. This will help us process your SNAP application faster.",
        validPhoneAlert: "Please enter a valid phone number",
        emailAddressLabel: "What is your email address?",
        emailErrormsg: "Please enter a valid email address",
        titleEmergency: "Emergency SNAP Benefits",
        titleAccommodation: "Accommodation(s)",
        headerEmergency: "Emergency SNAP Benefits",
        titleHouseholdInformation: "Expedited SNAP Services",
        headerAccommodation: "Accommodation(s)",
        accommodationDesc:
          "Accommodations are ways which Alabama DHR may help you to apply for benefits.",
        emergencySnap: "",
        incomeMoneylabel:
          "Does your income and liquid resources (cash, checking, or savings accounts) in the bank add up to less than your monthly housing expenses?",
        inlineHelpLabel:
          "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
        optionYesNo: ["Yes", "No"],
        alertSelectIncome: "Please select income you pay",
        incomeLesslabel:
          "Is your monthly income less than $150 and is your liquid resources (cash, checking, or savings accounts) in the bank less than or equal to $100?",
        monthlyIncomeoptions: ["Yes", "No"],
        selectIncomeErrmsg: "Please select income you pay",
        migrantBefore: "Are you a ",
        migrantTrigger: "migrant worker",
        emerSnapSubHeader:
          "Most SNAP applications are processed within 30 days. However, in some cases a household may be entitled to expedited services. Please answer the questions below so we can decide if you are eligible to have your SNAP application processed sooner.",
        migrantAfter:
          " and is your money in the bank less than or equal to $100?",
        migrantHelp:
          "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
        migrantOptions: ["Yes", "No"],
        migrantErrmsg: "Please select migrant you pay",
        headerMoreAbout: "More About Me",
        areUsBefore: "Are you a ",
        areUsTrigger: "US citizen",
        areUsafter: "?",
        immigrationStatus: "Do you hold a satisfactory immigration status?",
        immigrationOptions: ["Yes", "No"],
        eligibleHelp:
          "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        eligibleHelpTANF:
          "To be eligible for TANF, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        eligibleHelpCOMBO:
          "To be eligible for SNAP/TANF, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",

        chooseErrmsg: "Please choose citizen",
        tanfEligibleHelp:
          "To be eligible for TANF assistance, the individual must be either a natural-born or naturalized citizen of the United States or a qualified alien.  If you are not sure about your or another household member’s citizenship status DHR will work with you to determine eligibility.",
        eligibleMemberHelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleMemberHelpCombo:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleOptions: ["Yes", "No"],
        laguageBefore: "What language do you ",
        LANGUAGES: [
          "English",
          "Spanish",
          "French",
          "Haitian Creole",
          "Italian",
          "Laotian",
          "Portuguese",
          "Russian",
          "Vietnamese",
          "Other",
        ],
        languageTrigger: "prefer to speak ",
        languageAfter: "?",
        comfortLanHelp:
          "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
        languageErrMsg: "Please choose language",
        genderLabel: "What is your gender?",
        genderOptions: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        labelEthnicity: "What is your ethnicity?",
        helpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        tanfhelpEthnicity:
          "Collection of ethnicities/race is for statistical use and Federal reporting purposes only.  Providing this information will not affect your eligibility for TANF benefits.",
        tanfRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
        ],
        snapHelpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        snapRaceOptions: [
          "America Indian/ Alaskan Native",
          "Asian",
          "Black / African American",
          "Native Hawaiian/ Other Pacific Islander",
          "White",
          "Decline to answer",
          "Other",
        ],
        otherRaceErrmsg: "Please enter other race information",
        otherRaceErrMsgLength:
          "Please enter other race information greater than 3 characters or less than 250 characters",
        snpaNote:
          "Note: Only those who disclose their citizenship or establish satisfactory immigration status will receive benefits if otherwise eligible. The decision not to furnish this information will not affect the eligibility of the other household members. Information on the immigration status of those who do not apply will not be verified with USCIS. Parents who do not disclose their status may apply for their children. ALL HOUSEHOLD MEMBERS MUST BE LISTED AND ALL QUESTIONS ANSWERED, EVEN FOR THOSE WHO ARE NOT DISCLOSING CITIZENSHIP OR IMMIGRATION STATUS. The income of all members is considered regardless whether or not they apply or are eligible.",
        ebtNote: `<b>Lost, Stolen, or Damaged EBT Card</b>
        <p>Immediately call customer service at: 1-800-997-8888</p>
        <p>If your EBT card has been lost, stolen or is no longer working, please call the number listed above to request a replacement card. They will immediately disable the old card. A replacement card will be mailed to the address they have in their system. Please allow 5-7 business days for the card to arrive to you in the mail. If the address in their system is not correct, you will need to have your county office correct the address before a replacement card can be mailed. Your benefits will be protected as soon as you report your card as lost or stolen. </p>
        <p style="color: #18507F"><b>Reminder:</b> If you have an authorized representative for your SNAP benefits, their card will still work and can be used to make purchases from your account unless you or your authorized representative have requested that it be cancelled.
        </p>`,
        snapRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits. <br/><br/> Please select all that apply.",
        tanfRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive TANF benefits. <br/><br/> Please select all that apply.",
        comboRaceLabel:
          "Title VI of the Civil Rights Act of 1964 allows us to ask for racial/ethnic information. You do not have to give us this information. If you do not want to give us this information, it will have no effect on your case and your worker will enter an answer. <br/><br/>We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP/TANF benefits. <br/><br/> Please select all that apply.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        accommodationAppliesLabel: "Select all that apply ",
        accommodationAppliesOptions: [
          "Telephone Interview",
          "Translation Services",
          "Information in Large Print",
          "Information in Braille",
          "Hearing Impaired Assistance",
          "Speech Impaired Assistance",
          "Other",
        ],
        targetValueOther: "7",
        OthersHelp: "Enter your accommodation details here...",
        raceLabel: "What is this member's race?",
        raceHelp:
          "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits. <br/><br/> Please select all that apply.",
        optionAIdisplay: "American Indian",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Asian",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Black/African American",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Pacific Islander",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "White",
        declineToAnswerLabel: "Decline to answer",
        inlineHelpSSN:
          "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
        tanfInlineHelpSSN1:
          "SSNs are required for those in the assistance unit. You may provide the SSNs on this application or at the time of interview.",
        tanfInlineHelpSSN2:
          "NOTE: As a non-parent, providing your social security number or information about your citizenship/alienage is optional.",
        dsnapInlineHelpSSN: "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status",
        ebtCardSectionTitle: "EBT Card",
        needEbtCard: "Do You Need an EBT Card?",
        inlineHelpEbtcard:
          "If you already have an EBT card in your name, you do not need a new one.",
        elsePayExpenses: "Does anyone else pay all or part of this expense?",
        enterPyeesName:
          "Enter the name of the person who pays all or part of this expense.",
        expensesOptions: ["Yes", "No"],
        enterPyeesNameErrMsg:
          "Please enter the name of the person who pays all or part of this expense.",
        outsideHouseholdPayee:
          "Does anyone outside the household pay any of the rent / mortgage.",
        outsideHouseholdPayeesName:
          "Enter the name of the person who pays any of rent / mortgage. ",
        outsideHouseholdPayeesNameErrMsg:
          "Please enter the name of the person who pays any of rent / mortgage.",
      };
    case ES:
      return {
        title: "Sobre mí",
        header: "Sobre mí",
        subHeader:
          "Esta sección debe ser completada por/para el solicitante. Un solicitante es generalmente un adulto que solicita beneficios para sí mismo y / u otros miembros del hogar. <br/><br/> Nota: La información del caso y de la tarjeta EBT estará en el nombre ingresado a continuación.",
        tanfSubHeader:
          "El caso estará a nombre del concesionario que figura aquí.",
        comboSubHeader1:
          "Esta sección debe ser completada por/para el solicitante. Un solicitante es generalmente un adulto que solicita beneficios para sí mismo y / u otros miembros del hogar.<br/></br>",
        comboSubHeader2:
          "Nota: La información del caso y de la tarjeta EBT estará en el nombre ingresado a continuación.",
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Ingrese su nombre.",
        firstNameErrMsgLength:
          "Por favor, ingrese el primer nombre con 15 caracteres máximo",
        middleName: "¿Cuál es su segundo nombre?",
        middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        middleNameErrMsgLength:
          "Por favor, ingrese el segundo nombre con 15 caracteres máximo",
        lastNamelabel: "¿Cuál es su apellido?",
        lastNameErrMsg: "Ingrese su apellido.",
        lastNameErrMsgLength:
          "Por favor, ingrese los apellidos con 20 caracteres máximo",
        labelSuffix: "Sufijo",
        accOtherErrormsg: "Introduce los datos de tu alojamiento",
        accOtherLengthErrormsg: "Por favor ingrese al menos 3 caracteres",
        accApplyErrormsg: "Por favor seleccione una opción",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
        tanfContactInfoHelpLine:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Si elige hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si reúne los requisitos para recibir asistencia acelerada, ya que su solicitud no incluirá información sobre ingresos o recursos. Para firmar y enviar su solicitud ahora, haga clic en 'Firmar y enviar' y para continuar con su solicitud, haga clic en 'Guardar y continuar'",
        dobAlertMsg: "Ingrese una fecha de nacimiento válida.",
        ssnLabel: "¿Cuál es su número de seguro social?",
        inputClearLabel: "No tengo uno",
        ssnErrMsg: "Ingrese un número de Seguro Social válido.",
        headerMyContactInfo: "Mi información de contacto",
        contactInformation1:
          "Para enviar una solicitud SNAP en línea, debe proporcionar una dirección postal y un número de fono. Si tiene alguna pregunta, llame a la línea de asistencia del DHR al ",
        contactInformation2: "o acuda a su",
        contacInformationLink: "oficina local del DHR.",
        contactInfo1:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Si elige hacerlo, tenga en cuenta que no podremos examinar automáticamente su solicitud para determinar si reúne los requisitos para recibir asistencia acelerada, ya que su solicitud no incluirá información sobre ingresos o recursos. Para firmar y enviar su solicitud ahora, haga clic en 'Firmar y enviar' o para continuar con su solicitud, haga clic en 'Guardar y continuar'.",
        contactInfo2: "",
        contactInfo4:
          "Tiene derecho a presentar una solicitud solo con su nombre, dirección y firma. Proporcionar detalles adicionales ahora nos ayudará a determinar la elegibilidad y a evaluar automáticamente los servicios acelerados de SNAP. Para enviar su solicitud, haga clic en 'Firmar y enviar' o continúe con la solicitud haciendo clic en 'Guardar y continuar'.",
        contacInfoLink: "Solicite beneficios SNAP (cupones de alimentos).",
        contactInfo3: "",
        houselessLabel: "¿Es su núcleo familiar indigente?",
        houselessErrMsg: "Seleccione si tiene o no su hogar",
        amountErrMsg: "Por favor ingrese una cantidad válida",
        optionErrMsg: "Por favor, seleccione una opción a continuación",
        houseLessOptions: ["Sí", "No"],
        yesOption: ["Sí"],
        interviewTypeLabel: "¿Prefieres una entrevista presencial o telefónica?",
        interviewTypeOptions: ["Cara a cara", "Teléfono"],
        accommodationOptions: ["Sí", "No"],
        addrQuestionlabel: "¿Cuál es su dirección?",
        tanfAddrQuestionlabel: "¿Cuál es su dirección residencial?",
        maritalStatus: "¿Cuál es su estado material?",
        targetValueOther: "7",
        OthersHelp: "Introduce aquí los datos de tu alojamiento...",
        titleAccommodation: "Alojamiento(s)",
        headerAccommodation: "Alojamiento(s)",
        accommodationDesc:
          "Las adaptaciones son formas en las que Alabama DHR puede ayudarlo.",
        accommodationLabel: "¿Necesitas alguna adaptación?",
        accommodationAppliesLabel:
          "Seleccione todas las opciones que correspondan",
        accommodationAppliesOptions: [
          "Entrevista telefónica",
          "Servicios de traducción",
          "Información en letra grande",
          "Información en Braille",
          "Asistencia a personas con discapacidad auditiva",
          "Asistencia para discapacitados del habla",
          "Otro",
        ],
        materialStatusOptions: [
          "Soltero",
          "Casado",
          "Apartado",
          "Divorciado",
          "Viudo",
        ],
        showDOBForMaterialStatus: [
          "Casado",
          "Apartada",
          "Divorciada",
          "Viuda",
        ],
        appointmentPreferenceTitle: "<b>Preferencias de citas</b>",
        preferredPhoneQLabel: "¿Cuál es tu número de teléfono preferido?",
        preferredDayQLabel: "Por favor seleccione su(s) día(s) preferido(s).",
        preferredDayInlineLabel:
          "Las citas el mismo día están sujetas a la disponibilidad del trabajador de ALDHR.",
        preferredDayOptions: [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Mismo día",
        ],
        preferredDayErrMessage: "Por favor seleccione un día",
        preferredTimeQLabel: "Por favor seleccione AM y/o PM",
        preferredTimeOptions: ["AM", "PM"],
        preferredTimeErrMessage: "Por favor seleccione AM o PM",
        validZipcode: "Ingrese un código postal válido.",
        alsoMailingLabel: "¿Es esta su dirección postal?",
        optionsSendMail: "Sí, enviar correo allí. ",
        optionsNoDiffAddress:
          "No, utilizo una dirección diferente para el correo postal.",
        mailingQlabel: "¿Cuál es su dirección postal?",
        immigrationStatus:
          "¿Esta persona tiene un estatus migratorio satisfactorio?",
        immigrationOptions: ["Sí", "No"],
        zipCodeErrmsg: "Ingrese un código postal válido.",
        masZipCodeErrmsg:
          "Esta aplicacion de SNAP es para residents de Alabama. Si vive en Alabama, ingrese su codigo postal. Si no vive en Alabama, debe someter una solicitud en su estado.",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        addressErrmsg: "Por favor, ingrese una dirección válida.",
        mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
        homeAddressErrmsg: "Por favor ingrese una dirección particular.",
        bestPhoneqlabel:
          "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
        inlineHelp:
          "Por favor, ingrese un número de teléfono si lo tiene. Esto nos ayudará a procesar su aplicación SNAP más rápido.",
        validPhoneAlert: "Ingrese un número de teléfono válido",
        emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
        emailErrormsg: "Introduce una dirección de correo electrónico válida",
        titleEmergency: "Beneficios de SNAP de emergencia",
        headerEmergency: "Beneficios de SNAP de emergencia",
        emergencySnap: "",
        titleHouseholdInformation: "Servicios SNAP acelerados",
        incomeMoneylabel:
          "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
        inlineHelpLabel:
          "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
        optionYesNo: ["Sí", "No"],
        alertSelectIncome: "Por favor seleccione el ingreso que paga",
        incomeLesslabel:
          "¿Su ingreso mensual es inferior a $150 y su dinero en el banco es menor o igual a $100?",
        monthlyIncomeoptions: ["Sí", "No"],
        selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
        migrantLabel: "¿Es usted un trabajador migrante y su dinero en el banco es menor o igual a $100?",
        migrantBefore: "¿Es usted un ",
        migrantTrigger: " trabajador migrante",
        emerSnapSubHeader:
          "La mayoría de las solicitudes de SNAP se procesan dentro de los 30 días. Sin embargo, en algunos casos, un hogar puede tener derecho a servicios acelerados. Responda las preguntas a continuación para que podamos decidir si es elegible para que su solicitud de SNAP se procese antes.",
        migrantAfter: " ¿Y su dinero en el banco es menor o igual a $100?",
        migrantHelp:
          "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
        migrantOptions: ["Sí", "No"],
        migrantErrmsg: "Por favor selecciona migrante pagas",
        headerMoreAbout: "Más Sobre Mí",
        areUsBefore: "Eres una ",
        areUsTrigger: "Ciudadana estadounidense",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegible para SNAP, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        eligibleHelpTANF:
          "Para ser elegible para TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        eligibleHelpCOMBO:
          "Para ser elegible para SNAP/TANF, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",

        chooseErrmsg: "Por favor elija ciudadano",
        eligibleMemberHelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        eligibleMemberHelpCombo:
          "Incluso si no es ciudadano estadounidense, usted u otros miembros de su hogar aún pueden ser elegibles.",
        eligibleOptions: ["Sí", "No"],
        LANGUAGES: [
          "Inglés",
          "Español",
          "Francés",
          "Criollo haitiano",
          "Italiano",
          "laosiano",
          "Portugués",
          "Ruso",
          "Vietnamita",
          "Otro",
        ],
        laguageBefore: "¿Qué idioma ",
        languageTrigger: " prefiere hablar",
        languageAfter: "?",
        comfortLanHelp:
          "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
        languageErrMsg: "Por favor, elija el idioma",
        genderLabel: "¿Cuál es su sexo?",
        genderOptions: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        labelEthnicity: "¿Cuál es tu origen étnico?",
        helpEthnicity:
          "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        tanfhelpEthnicity:
          "La recopilación de etnias/razas es solo para fines estadísticos y de informes federales. Proporcionar esta información no afectará su elegibilidad para los beneficios de TANF.",
        tanfRaceOptions: [
          "Indio americano/nativo de Alaska",
          "Asiático",
          "Negro / Afroamericano",
          "Hawaiano nativo/otro isleño del Pacífico",
          "Blanco",
          "Negarse a contestar",
        ],
        snapHelpEthnicity:
          "Pedimos su origen étnico para garantizar la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir beneficios de SNAP.",
        snapRaceOptions: [
          "Indio americano/nativo de Alaska",
          "Asiático",
          "Negro / Afroamericano",
          "Hawaiano nativo/otro isleño del Pacífico",
          "Blanco",
          "Negarse a contestar",
          "Otro",
        ],
        otherRaceErrmsg: "Por favor ingresa otra información de la carrera",
        otherRaceErrMsgLength:
          "Ingrese otra información de la carrera de más de 3 caracteres o menos de 250 caracteres",
        snpaNote:
          "Nota: Solo aquellos que revelen su ciudadanía o establezcan un estatus migratorio satisfactorio recibirán beneficios si son elegibles. La decisión de no proporcionar esta información no afectará la elegibilidad de los otros miembros del hogar. La información sobre el estatus migratorio de aquellos que no soliciten no será verificada con USCIS. Los padres que no revelan su estado pueden solicitar a sus hijos.  TODOS LOS MIEMBROS DEL HOGAR DEBEN ESTAR EN LA LISTA Y TODAS LAS PREGUNTAS RESPONDIDAS, INCLUSO PARA AQUELLOS QUE NO ESTÁN REVELANDO LA CIUDADANÍA O EL ESTADO MIGRATORIO. Los ingresos de todos los miembros se consideran independientemente de si solicitan o no o son elegibles.",
        ebtNote: `<b>Tarjeta EBT perdida, robada o dañada</b>
        <p>Llame inmediatamente al servicio al cliente al: 1-800-997-8888.</p>
        <p>Si su tarjeta EBT se ha perdido, ha sido robada o ya no funciona, llame al número mencionado anteriormente para solicitar una tarjeta de reemplazo. Inmediatamente desactivarán la tarjeta anterior. Se enviará una tarjeta de reemplazo por correo a la dirección que tienen en su sistema. Espere de 5 a 7 días hábiles para que la tarjeta le llegue por correo. Si la dirección en su sistema no es correcta, deberá hacer que la oficina de su condado corrija la dirección antes de que se pueda enviar una tarjeta de reemplazo por correo. Sus beneficios estarán protegidos tan pronto como reporte su tarjeta como perdida o robada.</p>
        <p style="color: #18507F"><b>Recordatorio:</b> Si tiene un representante autorizado para sus beneficios SNAP, su tarjeta seguirá funcionando y podrá usarse para realizar compras desde su cuenta a menos que usted o su representante autorizado hayan solicitado que se cancele.</p>`,
        snapRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP. <br/> <br/> Seleccione todo lo que corresponda.",
        tanfRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de TANF. <br/> <br/> Seleccione todo lo que corresponda.",
        comboRaceLabel:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP/TANF. <br/> <br/> Seleccione todo lo que corresponda.",
        hisPanicOption: "Hispano/Latino",
        nonhisPanicOption: "No hispano/Latino",
        raceLabel: "¿Cuál es tu raza?",
        raceHelp:
          "El Título VI de la Ley de Derechos Civiles de 1964 nos permite solicitar información racial/étnica. No es necesario que nos proporcione esta información. Si no desea brindarnos esta información, no tendrá ningún efecto en su caso y su trabajador ingresará una respuesta. <br><br>Pedimos que su carrera garantice la igualdad de trato y acceso para todos. Su respuesta no afectará su elegibilidad para recibir los beneficios de SNAP. <br><br> Seleccione todo lo que corresponda.",
        optionAIdisplay: "Indio americano",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afroamericano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Isleño del Pacífico",
        optionwhitedisplay_VALUE: "Blanco",
        optionwhitedisplay: "Blanco",
        declineToAnswerLabel: "Me niego a responder",
        inlineHelpSSN:
          "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan dar su estado de SSN o ciudadanía.",
        tanfInlineHelpSSN1:
          "Se requieren SSN para aquellos en la unidad de asistencia. Puede proporcionar los SSN en esta solicitud o en el momento de la entrevista.",
        tanfInlineHelpSSN2:
          "NOTA: Como no padre, proporcionar su número de seguro social o información sobre su ciudadanía / extranjería es opcional.",
        dsnapInlineHelpSSN: "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan proporcionar su SSN o su estado de ciudadanía.",
        ebtCardSectionTitle: "Tarjeta EBT",
        needEbtCard: "¿Necesita una tarjeta EBT?",
        inlineHelpEbtcard:
          "Aclaración: Si tiene una tarjeta EBT a su nombre de un caso anterior, puede volver a usar esa tarjeta si la aprueban.",
        poBoxErrmsg:
          "No se puede aceptar un apartado postal como dirección particular",
        elsePayExpenses: "¿Alguien más paga todo o parte de este gasto?",
        enterPyeesName:
          "Ingrese el nombre de la persona que paga todo o parte de este gasto.",
        expensesOptions: ["Sí", "No"],
        enterPyeesNameErrMsg:
          "Por favor ingrese el nombre de la persona que paga todo o parte de este gasto.",
        outsideHouseholdPayee:
          "¿Alguien fuera del hogar paga alguno de los alquiler/hipoteca.",
        outsideHouseholdPayeesName:
          "Ingrese el nombre de la persona que paga cualquiera de alquiler/hipoteca.",
        outsideHouseholdPayeesNameErrMsg:
          "Por favor ingrese el nombre de la persona que paga cualquiera de alquiler/hipoteca.",
        medicalExpenseType: "¿Qué tipo de gastos médicos tienes? (Seleccione todas las que correspondan)",
        doctorOrDentist: "Doctor o dentista",
        hospitalOrNursingHome: "Hospital o residencia de ancianos",
        prescriptionDrugs: "Medicamentos con receta",
        overTheCounterMedicine: "Medicina sin receta",
        healthInsurancePremiums: "Primas de seguro médico",
        medicalSupplies: " Suministros médicos",
        medicaidCostSharing: "Costo compartido de Medicaid",
        denturesOrHearingAids: "Dentaduras postizas o audífonos",
        prescriptionEyeGlases: "Anteojos recetados",
        transportationOrLoadingForMedicalServices: "Transporte o Carga para Servicios Médicos",
        HudHomeHealthAide: "Asistente de atención médica domiciliaria o cuidado infantil de HUD",
        serviceAnimal: "Animal de servicio",
        otherMedicalExpense: "Otro",
      };

    default:
      return;
  } //house hold ending ending
}