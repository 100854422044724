import React from "react";
import DTAModal from "../modal/dtaModal";

export const dtaHelperModal = (
  modalNode,
  isOpen,
  onClose,
  modalClass,
  titleText,
  error,
  errorMessage,
  hideCloseIcon,
  headerText,
  children,
  iconHide
) => {
  return (
    <DTAModal
      ref={modalNode}
      isOpen={isOpen}
      onClose={onClose}
      modalClass={modalClass}
      errorMessage={errorMessage}
      titleText={titleText}
      error={error}
      hideCloseIcon={hideCloseIcon}
      headerText={headerText}
      iconHide={iconHide}
    >
      {!error && !children ? (
        <div className="dta-modal__body pad-all mh-provider-success">
          <button className="hide-close-btn"></button>
        </div>
      ) : (
        <div className="pure-g">
          <div className="pure-u-5-5 peat-case-error-msg mh-update-error mar-btm">
            <button
              className="hide-close-btn close-btn"
              aria-label="close-button"
            ></button>
          </div>
        </div>
      )}
      {children ? children : null}
    </DTAModal>
  );
};
