import PropTypes from "prop-types";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import { recertificationPageLabels } from "../recertification/recertificationText";
import * as languageConstants from "../../utils/constants/constants";
import * as formatters from "../../utils/components/utilities/formatters";



const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
    
export function toValidateHouseholdSection(sections, activeId,originalSections) {
    let isFormValid = true;
    let modifiedSections;
    if (sections !== null) {
      if (sections.questions !== undefined) {
        if (activeId === 'household-member') {
            [isFormValid,modifiedSections] = checkForHouseholdErrors(sections.questions,originalSections);
          }
      }
    }
    return [isFormValid,modifiedSections];
  }

function checkForHouseholdErrors(questions,originalSections){
    let isHouseHoldValid;
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].name === "firstName") {
            if (questions[i].Answer === "") {
                questions[i].error = true;
                questions[i].errorMessage = questions[i].errorMessage;
                isHouseHoldValid = false;
            }else{
                isHouseHoldValid = true;
            }
        }
        if (questions[i].name === "lastName") {
            if (questions[i].Answer === "") {
                questions[i].error = true;
                questions[i].errorMessage = questions[i].errorMessage;
                isHouseHoldValid = false;
            }else{
                isHouseHoldValid = true;
            }
        }
        if (questions[i].type === "date") {
            if (
              (questions[i].Answer === "" || questions[i].Answer === null) &&
              questions[i].isRequired === true
            ) {
                questions[i].error = true;
                isHouseHoldValid = false;
            } else if (
              (questions[i].Answer !== "" || questions[i].Answer !== null) &&
              questions[i].isRequired === true
            ) {
              let inputDate = new Date(questions[i].Answer);
              let currentDate = new Date();
              if (inputDate.getTime() > currentDate.getTime()) {
                questions[i].error = true;
                isHouseHoldValid = false;
              } else {
                questions[i].error = false;
                isHouseHoldValid = true;
              }
            } else {
              questions[i].error = false;
              isHouseHoldValid = true;
            }
          }
    }
    originalSections[0].subsections[0].questions = questions
    return [isHouseHoldValid,originalSections]
}
  
export function AuthRepValidator(answers, errorObj, ebtPhoneValid, snpPhoneValid, signedNameValid) {
  const ebtRequiredFields = ['ebtFrstNam', 'ebtLstNam', 'ebtPhNum'];
  const snpRequiredFields = ['snpFrstNam', 'snpLstNam', 'snpPhNum'];
  let requiredFields = [];

  const authRepLabels = recertificationPageLabels(language, null, languageConstants.authRep);
  const isAuthorizeEBT = answers.isAuthorizeEBT === authRepLabels.yesnoOptions[0];
  const isAuthorizeCert = answers.isAuthorizeCert === authRepLabels.yesnoOptions[0];

  if (isAuthorizeEBT) {
    requiredFields.push(...ebtRequiredFields)
  }
  if (isAuthorizeCert) {
    requiredFields.push(...snpRequiredFields)
  }
  requiredFields = requiredFields.length > 0 ? [...requiredFields, 'signedName', 'agree'] : [];
  let isFormValid = true;
  let userDetails = sessionStorage.getItem("clientDetails");
  let parseUserDetails = JSON.parse(userDetails);
  let clientInfo = parseUserDetails.clientInfo;
  let userName = clientInfo.name;
  let HOH = userName.first + " " + userName.last;
  requiredFields.length > 0 && requiredFields.forEach(field => {
    if (field === "agree" && answers[field].length === 0 || answers[field] === null || answers[field] === "") {
      errorObj[`${field}Error`] = true;
      isFormValid = false;
    }
    else if (answers[field].length !== 0) {
      errorObj[`${field}Error`] = false;
      // isFormValid = true;
      }
    if(answers["signedName"]) {
      if(answers["signedName"].toLowerCase() !== HOH.toLowerCase()) {
        errorObj[`signedNameError`] = true;
        isFormValid = false;
        signedNameValid(true);
      }
      else {
        errorObj[`signedNameError`] = false;
        signedNameValid(false);
      }
    }
    else {
      signedNameValid(false);
    }
    if (!answers[field]) {
      errorObj[`${field}Error`] = true;
      isFormValid = false;
        }
      if(answers["ebtPhNum"]){
        if(answers["ebtPhNum"].replace(/\D/g, "").length<10 || answers["ebtPhNum"] === null){
          if(answers["ebtPhNum"].replace(/\D/g, "").length != 0 || answers["ebtPhNum"] === null) {
            ebtPhoneValid(true);
          }
          else {
            ebtPhoneValid(false);
          }
          errorObj["ebtPhNumError"] = true;
          isFormValid = false;
        }else{
          errorObj["ebtPhNumError"] = false;
          ebtPhoneValid(false);
        }
      }
      else {
        ebtPhoneValid(false);
      }

      if(answers["snpPhNum"]){
        if(answers["snpPhNum"].replace(/\D/g, "").length<10 || answers["snpPhNum"] === null){
          if(answers["snpPhNum"].replace(/\D/g, "").length != 0 || answers["snpPhNum"] === null) {
            snpPhoneValid(true);
          }
          else {
            snpPhoneValid(false);
          }
          errorObj["snpPhNumError"] = true;
          isFormValid = false;
        }else{
          errorObj["snpPhNumError"] = false;
          snpPhoneValid(false);
        }
      }
      else {
        snpPhoneValid(false);
      }
  });

  return [isFormValid, errorObj];
}

export function AttendanceValidator(answers) {
  let isFormValid = true;

  answers.forEach(obj => {
    const { effectiveDate, attandanceStatus, changesSclFlag, currentGradeLevel, schoolName } = obj;

    obj.effectiveDateError = !effectiveDate;
    // obj.attandanceStatusError = !attandanceStatus;
    // obj.changesSclFlagError = changesSclFlag === null || changesSclFlag === undefined;
    // obj.currentGradeLevelError = !currentGradeLevel;
    // obj.schoolNameError = !schoolName;

    if (  obj.effectiveDateError || obj.currentGradeLevelError || obj.schoolNameError) {
      isFormValid = false;
    }
  });

  return [isFormValid, answers];
}




export function checkForAttendanceChange(newData, oldData) {
  const comparedData = [];
  newData.forEach(newObj => {
    const matchingOldObj = oldData.find(oldObj => oldObj.agencyID === newObj.agencyID);
    if (matchingOldObj) {
      const changes = {};
      if (newObj.effectiveDate !== matchingOldObj.effectiveDate ||
        newObj.attandanceStatus !== matchingOldObj.attandanceStatus ||
        // newObj.changesSclFlag !== matchingOldObj.changesSclFlag ||
        newObj.currentGradeLevel !== matchingOldObj.currentGradeLevel ||
        newObj.schoolName !== matchingOldObj.schoolName) {
        changes.dataChangeCode = "CHANGE";
      } else {
        changes.dataChangeCode = "NOCHNG";
      }
      comparedData.push({ ...newObj, ...changes });
    } else {
      newObj.dataChangeCode = "NEW";
      delete newObj.disabilityFlag;
      delete newObj.granteeFlag;
      comparedData.push(newObj);
    }
  });
  return comparedData;
}
