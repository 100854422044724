import * as Types from "../../utils/constants/listItemTypes";

import * as BenefitProps from "../../utils/constants/benefit";
import * as DeadlineProps from "../../utils/constants/deadline";
import * as DocumentProps from "../../utils/constants/document";
import * as EventProps from "../../utils/constants/event";
import * as NotificationProps from "../../utils/constants/notification";
import * as SharedProps from "../../utils/constants/shared";

export const RECEIVED_BENEFITS = [
  {
    [SharedProps.TYPE]: Types.BENEFIT,
    [SharedProps.ID]: "1",
    [BenefitProps.AMOUNT]: 345.12,
    [BenefitProps.DATE]: new Date(2017, 8, 12),
  },
  {
    [SharedProps.TYPE]: Types.BENEFIT,
    [SharedProps.ID]: "2",
    [BenefitProps.AMOUNT]: 347.13,
    [BenefitProps.DATE]: new Date(2017, 7, 12),
  },
  {
    [SharedProps.TYPE]: Types.BENEFIT,
    [SharedProps.ID]: "3",
    [BenefitProps.AMOUNT]: 341.14,
    [BenefitProps.DATE]: new Date(2017, 6, 12),
  },
  {
    [SharedProps.TYPE]: Types.BENEFIT,
    [SharedProps.ID]: "4",
    [BenefitProps.AMOUNT]: 339.15,
    [BenefitProps.DATE]: new Date(2017, 5, 12),
  },
  {
    [SharedProps.TYPE]: Types.BENEFIT,
    [SharedProps.ID]: "5",
    [BenefitProps.AMOUNT]: 342.16,
    [BenefitProps.DATE]: new Date(2017, 4, 12),
  },
];

export const ACTIVITY = [
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "1",
    [NotificationProps.URL]: "#",
    [NotificationProps.NAME]: "Verification Checklist Notice",
    [NotificationProps.STATUS]: NotificationProps.STATUS_UNREAD,
    [NotificationProps.DATE]: new Date(Date.now()),
  },
  {
    [SharedProps.TYPE]: Types.EVENT,
    [SharedProps.ID]: "2",
    [EventProps.NAME]: "Re-Evaluation In-Office: SNAP",
    [EventProps.URL]: "webcal://calendars.uefa.com/files/ucl-en.ics",
    [EventProps.STATUS]: EventProps.STATUS_CLOSE,
    [EventProps.DATE]: new Date(2017, 11, 5, 9, 30),
    [EventProps.LOCATION]: "Malden TAO DHR",
  },
  {
    [SharedProps.TYPE]: Types.DEADLINE,
    [SharedProps.ID]: "3",
    [DeadlineProps.NAME]: "SNAP Re-Evaluation Due",
    [DeadlineProps.URL]: "webcal://calendars.uefa.com/files/ucl-en.ics",
    [DeadlineProps.STATUS]: DeadlineProps.STATUS_NOT_URGENT,
    [DeadlineProps.DATE]: new Date(2018, 0, 13),
    [DeadlineProps.DETAILS]:
      "It's time to recertify your SNAP benefits. Your Supplemental Nutrition Assistance Program (SNAP) Benefits are due to end on 13.Jan.18.",
  },
  {
    [SharedProps.TYPE]: Types.DEADLINE,
    [SharedProps.ID]: "4",
    [DeadlineProps.NAME]: "TANFDC Re-Evaluation Due",
    [DeadlineProps.URL]: "webcal://calendars.uefa.com/files/ucl-en.ics",
    [DeadlineProps.STATUS]: DeadlineProps.STATUS_NOT_URGENT,
    [DeadlineProps.DATE]: new Date(2018, 0, 15),
    [DeadlineProps.DETAILS]:
      "Your Transitional Aid to Families with Dependent Children (TANFDC) benefits are due to end on 15.Jan.18.",
  },
  {
    [SharedProps.TYPE]: Types.DEADLINE,
    [SharedProps.ID]: "5",
    [DeadlineProps.NAME]: "SNAP Re-Evaluation Due",
    [DeadlineProps.URL]: "webcal://calendars.uefa.com/files/ucl-en.ics",
    [DeadlineProps.STATUS]: DeadlineProps.STATUS_NOT_URGENT,
    [DeadlineProps.DATE]: new Date(2017, 10, 13),
    [DeadlineProps.DETAILS]:
      "It's time to recertify your SNAP benefits. Your Supplemental Nutrition Assistance Program (SNAP) Benefits are due to end on 13.Nov.17.",
  },
  {
    [SharedProps.TYPE]: Types.DEADLINE,
    [SharedProps.ID]: "6",
    [DeadlineProps.NAME]: "TANFDC Re-Evaluation Due",
    [DeadlineProps.URL]: "webcal://calendars.uefa.com/files/ucl-en.ics",
    [DeadlineProps.STATUS]: DeadlineProps.STATUS_NOT_URGENT,
    [DeadlineProps.DATE]: new Date(2017, 10, 15),
    [DeadlineProps.DETAILS]:
      "Your Transitional Aid to Families with Dependent Children (TANFDC) benefits are due to end on 15.Nov.17.",
  },
];

export const DTA_NOTIFICATIONS = [
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "1",
    [NotificationProps.NAME]: "Verification Checklist Notice",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_UNREAD,
    [NotificationProps.DATE]: new Date(Date.now()),
  },
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "2",
    [NotificationProps.NAME]: "FINI Welcome Notice",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_UNREAD,
    [NotificationProps.DATE]: new Date(Date.now()),
  },
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "3",
    [NotificationProps.NAME]: "EBC Notice with FS Calc",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_READ,
    [NotificationProps.DATE]: new Date(
      new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 1)
    ),
  },
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "4",
    [NotificationProps.NAME]: "Web Apps FS App Info Notice",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_READ,
    [NotificationProps.DATE]: new Date(2017, 7, 1),
  },
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "5",
    [NotificationProps.NAME]: "EBS Notice with FS Calc",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_READ,
    [NotificationProps.DATE]: new Date(2017, 6, 28),
  },
  {
    [SharedProps.TYPE]: Types.NOTIFICATION,
    [SharedProps.ID]: "6",
    [NotificationProps.NAME]: "Verification Notice",
    [NotificationProps.URL]: "#",
    [NotificationProps.STATUS]: NotificationProps.STATUS_READ,
    [NotificationProps.DATE]: new Date(2017, 6, 28),
  },
];

export const DOCUMENTS = [
  {
    [SharedProps.TYPE]: Types.DOCUMENT,
    [SharedProps.ID]: "1",
    [DocumentProps.DOCUMENT_NAME]: "Verification",
    [DocumentProps.CLIENT_NAME]: "Taylor Lee (9.Jan.1994)",
    [DocumentProps.STATUS]: DocumentProps.STATUS_PROCESSING,
  },
  {
    [SharedProps.TYPE]: Types.DOCUMENT,
    [SharedProps.ID]: "2",
    [DocumentProps.DOCUMENT_NAME]: "Verification",
    [DocumentProps.CLIENT_NAME]: "Sharon Lee (12.May.1988)",
    [DocumentProps.STATUS]: DocumentProps.STATUS_PROCESSING,
  },
  {
    [SharedProps.TYPE]: Types.DOCUMENT,
    [SharedProps.ID]: "3",
    [DocumentProps.DOCUMENT_NAME]: "Request for Authorized Representative",
    [DocumentProps.CLIENT_NAME]: "Taylor Lee (9.Jan.1994)",
    [DocumentProps.RECEIVED_DATE]: new Date(Date.now()),
    [DocumentProps.STATUS]: DocumentProps.STATUS_ACCEPTED,
    [DocumentProps.STATUS_CHANGED_DATE]: new Date(Date.now()),
  },
  {
    [SharedProps.TYPE]: Types.DOCUMENT,
    [SharedProps.ID]: "4",
    [DocumentProps.DOCUMENT_NAME]: "Verification",
    [DocumentProps.CLIENT_NAME]: "Grace Lee (20.Jun.1999)",
    [DocumentProps.RECEIVED_DATE]: new Date(2017, 7, 11),
    [DocumentProps.STATUS]: DocumentProps.STATUS_ACCEPTED,
    [DocumentProps.STATUS_CHANGED_DATE]: new Date(2017, 7, 11),
  },
  {
    [SharedProps.TYPE]: Types.DOCUMENT,
    [SharedProps.ID]: "5",
    [DocumentProps.DOCUMENT_NAME]: "Verification",
    [DocumentProps.CLIENT_NAME]: "Grace Lee (20.Jun.1999)",
    [DocumentProps.RECEIVED_DATE]: new Date(2017, 7, 10),
    [DocumentProps.STATUS]: DocumentProps.STATUS_DENIED,
    [DocumentProps.STATUS_CHANGED_DATE]: new Date(2017, 7, 10),
  },
];
