import axios from "axios";
var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
const actionTypes = {
  getBenefitFormRequested: "GET_BENEFIT_FORM_REQUESTED",
  serviceFailedAction: "FETCH_ERROR_BENEFIT",
};

export function loadBenefitDetails() {
  return (dispatch) => {
    var url = BaseUrl + "/provider";
    var options = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };

    axios
      .get(url, {}, options)
      .then(checkStatus)
      .then(function (benefitDetails) {
        dispatch(sendLoadBenefitData(benefitDetails.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function sendLoadBenefitData(benefitDetailsData) {
  return {
    type: actionTypes.getBenefitFormRequested,
    payload: benefitDetailsData,
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
