let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function consumerConst(language) {
  switch (language) {
    case EN:
      return {
        accountConnectType:
          "How would you like to connect to your ACES DHR Self-Service Portal?",
        somethingwentWrong: "Oops! Something went wrong please try again later",
        accountConnectTypeHelpText:
          "If you are the head of household, please select one of the following below and enter the requested information.",
        web: "SNAP Web Application Number",
        tanf: "TANF Web Application Number",
        dta: "DHR Agency ID",
        labelFirstName: "First Name?",
        firstNameErrMsg: "Please enter your first name.",
        lastNamelabel: "Last Name?",
        lastNameErrMsg: "Please enter your last name.",
        dateOfBirthLabel: "Date of Birth?",
        ssn: "Social Security Number",
        ssnErrMsg: "Please enter a valid Social Security Number.",
        ebt: "EBT Card Number",
        clientID: "Client ID",
        applicationNumber: "What is your SNAP Web Application Number?",
        tanfNumber: "What is your TANF Web Application Number?",
        dtaId: "What is your DHR Agency ID?",
        ssnNumber: "What is your Social Security Number?",
        ebtNumber: "What is your EBT Card Number?",
        whatIsClientId: "What is your Client ID?",
        myInfo: "My Info",
        activity: "Activities",
        // documents: "Documents",
        documents: "Communications",
        myAccount: "My Account",
        consumerconcernfair:"Client Concerns/Fair Hearings",
        consumerconcern:"Client Concerns/Fair Hearings",
        consumerfair:"",
        residentialAddress: "Home Address",
        mailingAddress: "Mailing address",
        phoneNumber: "Primary Phone Number",
        phoneInlineText:
          "I want to get SMS Text Messages. I give Alabama DHR permission to use my cell phone number to send text messages. I understand that data charges may apply. I can stop SMS Text Messaging Notifications by changing allow text messaging notifications to no.",
        allowTxtMsg: "Allow Text Messsage Notifications?",
        sameResidentail: "Same as residential",
        diffrentAddress: "Different address for mail.",
        editAddress: "Edit my address details",
        editPhnone: "Edit my phone details",
        yes: "Yes",
        no: "No",
        editEmail: "Edit my email address",
        emailAddress: "Email Address",
        reply:"Reply",
        sendMsg:"Sent Messages",
        send:"Send",
        print:"Print",
        from:"From",
        fromMultiLang: " from ",
        to:"To",
        received:"Received",
        emailHelpText:
          "I want to participate in eNotification. I give DHR permission to use my email address. I can stop eNotification at any time by telling DHR.",
        emailNotification: "Allow Email Notifications?",
        verificationList: "REQUEST BENEFIT VERIFICATION LETTER",
        uploadDocumentType: "What kind of document(s) are you sending today?",
        VERIFC: "Verification document",
        VOLWIT: "Voluntary request to stop benefits",
        STIDEN$SRFAREP: "Request for authorized representative",
        HOUMIS: "Request for Replacement Due to Household Misfortune",
        SAPPL$SRECERT: "SNAP Recertification",
        SAPPL$SRECERTAPPL: "SNAP Recertification",
        uploadDocumentAbout: "Who is the document about?",
        dateRange: "Date Range:",
        search: "Search",
        sendTxt:"Send to County Distribution List",
        inbox:"Inbox",
        importantMsg:"Important Message",
        all:"All",
        unRead:"Unread",
        docType: "Document Type:",
        selectDate: "select a date",
        nothingYet: "Nothing here yet!",
        addToCalender: "Add to Calendar",
        filters: "Filters",
        active: "active",
        archive:"Archive messages",
        archives:"Archive",
        validProgram:"Program Area is Required",
        validTo:"To Email is Required",
        invalidTo:"Invalid Email",
        validSub:"Subject is Required",
        validBody:"Please type a message",
        byDate:"By Date",
        pending: "pending",
        ineligible: "ineligible",
        denied: "denied",
        closed: "closed",
        phoneInLineHelp:
          "I want to get SMS Text Messages. I give DHR permission to use my cell phone number to send me text messages. I understand that data charges may apply. I can stop SMS Text Messaging at any time by telling DHR.",
        emailInLineHelp:
          "I want to participate in eNotification. I give DHR permission to use my email address. I can stop eNotification at any time by telling DHR.",
        upcomingActivities: "UPCOMING ACTIVITIES",
        pastActivities: "PAST ACTIVITIES",
        requestPending: "Your appointment change request is pending.",
        requestCompleted: "Your appointment change request has been completed.",
        requestNotProcessed:
          "Your appointment change request cannot be processed, please contact your case manager.",
        recertify:
          "Its time to recertify your SNAP benefits. Your Supplemental Nutrition Assistance Program (SNAP) Benefits are due to end on",
        transAid: "Your Transitional Aid to Families with Dependent Children",
        benefits: "benefits are due to end on",
        emergency: "Your Emergency Aid to Elderly, Disabled, Child",
        recertification: "Recertification Due",
        revaluation: "Revaluation Due",
        loadingLabel: "Loading...",
        houseHoldSuccess:"Thank you for updating your household details. This change will be effective once the change request has been approved by the Case Worker.",
        incomeSuccess:"Thank you for updating your income. This change will be effective once the change request has been approved by the Case Worker.",
        shelterSuccess:"Thank you for updating your shelter & utility expenses. This change will be effective once the change request has been approved by the Case Worker.",
        childSuccess:"Thank you for updating your child support expenses. This change will be effective once the change request has been approved by the Case Worker.",
        childOrAdultSuccess:"Thank you for updating your child or adult care expenses. This change will be effective once the change request has been approved by the Case Worker.",
        medicalSuccess:"Thank you for updating your medical expenses. This change will be effective once the change request has been approved by the Case Worker.",
        authRepSuccess:"Thank you for updating your authorized representative. This change will be effective once the change request has been approved by the Case Worker.",
        weeklyFrequencyLabel: "Weekly",
        monthlyFrequencyLabel: "Monthly",
        annualFrequencyLabel: "Annual",
        biweeklyFrequencyLabel: "Biweekly (every two weeks)",
        quarterlyFrequencyLabel: "Quarterly",
        semiannualFrequencyLabel: "Semiannual (twice a year)",
        bimonthlyFrequencyLabel: "Bimonthly (every two months)",
        semimonthlyFrequencyLabel: "Semimonthly (twice a month)",
        yearFrequencyLabel: "Year",
        NoDataFound : "No Data Found !!!",
        cancelPending: "Your request to cancel an appointment is pending.",
        cancelCompleted: "Cancelled",
        resourceSuccess:"Thank you for updating your resources. This change will be effective once the change request has been approved by the Case Worker.",

        typeLabel:"Type(Required)",
        reasonLabel:"Reason(Required)",
        programLabel:"Program(Required)",
        dateLabel:"Date of Action or Incident",
        clientNameLabel:"Client Name",
        clientIdLabel:"Client ID",
        withdrawnCommentLabel:"Reason for Withdrawal",
        checkboxLabel:"I would like to withdraw the appeal request",
        signLabel:"Sign by entering you full name below(Required)",
        checkboxErrorMsg:"Please select the checkbox below.",
        signErrorMsg:"Please sign below.",
        withdrawplaceHolder:"Please provide a brief description for withdrawing your appeal.",
        NoDataFound: "No Data Found !!!",
        concernsHeaderText: "Client Concerns",
        concernTypeLabel: "Type (Required)",
        concernLabel: "Select a category (Required)",
        concernsAppealTypeOptions: [
          "Client Concerns",
          "Fair Hearing",
        ],

        concernsTypeOptions: [
          "Inquiry",
          "Complaint",
          "Fraud",
          "Conference",
          "State Office Review"
        ],
        providerTypeOptions: [
          "Inquiry",
          "Complaint",
          "Fraud",
        ],
      
        appealType: "Fair Hearing",
        concernsInquiry: "INQ",
        concernsComplaint: "CMPLNT",
        concernsFraud: "FRAUD",
        concernsConference: "CONF",
        concernsStateOffice: "STRVW",
        concernTypeErrorText: "Please select a Type.",
        concernErrorText: "Please select the category.",
        concernsReasonLabel: "Reason (Required)",
        concernsReasonOptions:{
          "ABAWD":"ABAWD",
          "ALTISS":"Allotment Issue",
          "APPSTA":"Application Status",
          "CLSDNL":"Case Closure",
          "CRCOMP":"Civil Rights Complaint",
          "DSGDEC":"Disagreement with decision",
          "EBT":"EBT",
          "ELGINF":"Eligibility Information",
          "INTERP":"Interpreter",
          "INTAPP":"Interview appointment",
          "JOBISS":"Jobs Participation Issue",
          "LFTMSG":"Left Message/No Return call",
          "INTCAL":"No Interview Call",
          "ONLAPP":"Online Application",
          "RECISS":"Overpayment/Claim Issue",
          "RPTFRD":"Reporting Fraud",
          "UPRCDT":"Rude Treatment",
          "MR":"Six- Month Report",
          "CLOTH":"Special Payment",
          "REST":"Status Of A-Reset Reimbursements",
          "CCAUTH":"Supportive Services",
          "SYSISS":"Systems Issue",
          "LSTVER":"Verification Issue",
          "VMMSG":"Worker Not available",
        },
        concernsABAWD: "ABAWD",
        concernsAllotment: "ALTISS",
        concernsApplication: "APPSTA",
        concernsCase: "CLSDNL",
        concernsCivilRights: "CRCOMP",
        concernsDisagreement: "DSGDEC",
        concernsEBT: "EBT",
        concernsEligibility: "ELGINF",
        concernsInterpreter: "INTERP",
        concernsInterview: "INTAPP",
        concernsJob: "JOPAIS",
        concernsLeftMessage: "LFTMSG",
        concernsNoInterview: "INTCAL",
        concernsOnlineApplication: "ONLAPP",
        concernsOverPayment: "OVCLIS",
        concernsReportingFraud: "RPTFRD",
        concernsRudeTreatment: "UPRCDT",
        concernsSixMonthReport: "MR",
        concernsSpecialPayment: "CLOTH",
        concernsStatus: "REST",
        concernsSupportiveServices: "CCAUTH",
        concernsSystemIssue: "SYSISS",
        concernsVerificationIssue: "VLSTVER",
        concernsWorker: "VMMSG",
        concernsReasonErrorText: "Please select the Reason.",
        concernsProgramLabel: "Program (Required)",
        providerProgramOptions: [
          "SNAP",
          "TANF",
          "JOBS",
          "A-Reset",
          "Non-DHR Enquiry"
        ],

        concernsSNAP: "SNAP",
        concernsTANF: "TANF",
        concernsJOBS: "JBS",
        concernsReset: "ARESET",
        concernsNonDHR: "NONDTA",
        concernsProgramErrorText: "Please select the Program.",
        concernsDateLabel: "Date of Action or Incident",
        concernsAnonymousLabel: "Stay Anonymous",
        concernsclientText: "Client",
        concernsclientNameLabel: "Name",
        concernsPhoneNumberLabel: "Phone Number",
        concernsEmailLabel: "Email Address",
        concernsClientIdLabel: "Client ID",
        concernsubHeaderText: "Authorized Representative ",
        concernsOfficeLabel: "County Office",
        concernsManagerLabel: "Case Worker",
        concernsManagerPhoneNumberLabel: "Case Worker Phone Number",
        concernsCommentsLabel: "Comments",
        concernsCommentsPlaceHolder: "Please provide a brief description of the concern here...",
        concernsGoback: "Ok",
        concernCancel: "Cancel",
        concernSubmit: "Submit",
        concernsEmailError: "Invalid Email",
        concernSubmitHeaderText: "Your Concern Has Been Submitted!",
        appealSubmitHeaderText:"Your Request For An Appeal Has Been Submitted!",
        appealWithDrawsSubmitText:"Your withdraw Appeal  Has Been Submitted",
        appealsNumber:"Your Web Appeal ID:",
        widthDrawAppealNumber:"Your withdrawAppeal Number is:",
        concernNumber:"Your Web Concern ID",
        concernsCancelHeader: "Are You Sure?",
        concernsCancelText: "Click on 'Cancel' to remove all information on your concern.", 
        concernsCancelTextBack: "Click on 'Go Back' to go back to the Concern screen.",
        concernsLocationOptions:[
          "AL-AESAP Office",
          "AL-Autauga Office",
          "AL-Baldwin Office",
          "AL-Barbour Office",
          "AL-Bibb Office",
          "AL-Blount Office",
          "AL-Bullock Office",
          "AL-Butler Office",
          "AL-Calhoun Office",
          "AL-Chambers Office",
          "AL-Cherokee Office",
          "AL-Chilton Office",
          "AL-Choctaw Office",
          "AL-Clarke Office",
          "AL-Clay Office",
          "AL-Cleburne Office",
          "AL-Coffee Office",
          "AL-Colbert Office",
          "AL-Conecuh Office",
          "AL-Coosa Office",
          "AL-Covington Office",
          "AL-Crenshaw Office",
          "AL-Cullman Office",
          "AL-Dale Office",
          "AL-Dallas Office",
          "AL-Dekalb Office",
          "AL-Elmore Office",
          "AL-Escambia Office",
          "AL-Etowah Office",
          "AL-Fayette Office",
          "AL-Franklin Office",
          "AL-Geneva Office",
          "AL-Greene Office",
          "AL-Hale Office",
          "AL-Henry Office",
          "AL-Houston Office",
          "AL-Jackson Office",
          "AL-Jefferson-Bessemer Office ",
          "AL-Jefferson-Birmingham Office",
          "AL-Lamar Office",
          "AL-Lauderdale Office",
          "AL-Lawrence Office",
          "AL-Lee Office",
          "AL-Limestone Office",
          "AL-Lowndes Office",
          "AL-Macon Office",
          "AL-Madison Office",
          "AL-Marengo Office",
          "AL-Marion Office",
          "AL-Marshall Office"
      ],
      concernsManagerPhoneNumberError: "Invalid Phone Number",
      concernsDateError: "Invalid Date",      
        concernlabel:"Client Concerns:",
        forlabel:"for",
        anonymusUserlabel:"Anonymous,",
        receivedlabel:"received",
        StatusChangedlabel:"Status Changed:",
        withdraw:"Withdraw",
        nothinghereYetlabel:"Nothing here Yet!",
        createNewCustomerLabel:"Create a new Client Concerns/Fair Hearings",
        continueLabel:"Continue",
        submitLabel:"Submit",
        exitLabel:"Exit",
        closedLabel:"Closed",
        pendingLabel:"Pending",
        AppealsType: "Type(Required)",
        ReasonLabelText: "Reason(Required)",
        ProgramLabelText: "Program(Required)",
        DateSelectorLabel: "Date of Action or Incident",
        RequestBenefitLabel: "Do you want to continue receiving the amount of SNAP/TANF benefits you now receive until the hearing is over?",
        RequestBenefitLabel2:"  You may request that your SNAP benefits not be changed until the hearing is held. However, if the action being taken is  correct, you will have to repay the benefits you received because of the request. Repayment may be made by withholding current or future benefits.",
        LegalRepresentatiseLabel: "Do you have Legal Representative?",
        CommentsLabel: "Comments",
        reasonErrorMsg: "Please select the Reason.",
        programErrorMsg: "Please select the Program.",
        nameErrorMsg: "Please enter a Name.",
        phoneNumberErrorMsg: "Please enter a phone number.",
        emailAddressErrorMsg: "Please enter an email address.",
        ClientNameLabel: "Name(Required)",
        ClientPhoneNumberLabel: "Phone Number(Required)",
        ClientEmailAddressLabel: "Email Address(Required)",
        authorizedClientNameLabel: "Name",
        authorizedClientPhoneNumberLabel: "Phone Number",
        authorizedClientEmailAddressLabel: "Email Address",
        ClientIdLabel: "Client ID",
        errorMessage:"Invalid Date",
        appealsProgramOptions:[
          "SNAP", "TANF", "JOBS"
        ],
        appealsReasonOptions:{
          "ANLRPT":"Recertification/Redetermination",
          "BENCLO":"Closed Case",
          "EMPNP":"JOBS - Failure to Participate",
          "TERECO":"TANF Recoupment",
          "APVAPP":"Allotment",
          "RTLAMT":"Dispute Deductions",
          "HHSIZE":"Household Composition",
          "OVRPAY":"Claims Related",
          "ADA":"Americans with Disabilities Act",
          "ADMDSQ":"Administrative Disqualification",
          "INTXIC":"Income Tax Intercept",
          "DAMINB":"Dispute Budgeted Income",
          "DABAWD":"Denial/Termination - Able-Bodied Adults Without Dependents (ABAWD)",
          "JBSSER":"JOBS - Supportive Services",
          "CSNNCM":"Child Support – Noncompliance",
          "DFPVER":"Denial/Termination - Failure to Provide Verification",
          "DNTMAP":"Denial/Termination - Missed Appointment",
          "DNIERW":"Denial/Termination - Resources/Gaming Winnings",
          "DNIEVQ":"Denial/Termination - Voluntary Quit ",
          "RETAMT":"Replacement Allotment/Restoration",
          "DISNAP":"D-SNAP/Disaster",
          "24MEND":"60 Month Time Limit Expiration",
          "OVRLMT":"Denial/Termination - Over the Income Limit",
          "INTRPT":"Denial/Termination-Failure to Submit or Incomplete Semi-Annual Review/Six-month Report",
          "DNIEST":"Denial/Termination - Ineligible Student",
          "OTER":"Other"
        },
        appealFirst:"APLPRO",
        appealsHeaderText:"Fair Hearings",
        emailFormateErrMsg:"Invalid Email",
        phoneNumberFormateErrMsg:"Invalid Phone Number",
        legalRepPhoneNumberFormateErrMsg:" Invalid Phone Number",
        legalRepEmailFormateErrMsg:"Invalid Email",
        appealsSubHeaderText:"Authorized Representative(Optional)",
        clientSubLebel:"Client",
        commentsPlaceHolder:"Please provide additional details regarding your request.",
        anonymusUserHeading:"ACES DHR Self-Service",
        anonymousConcernNumber:"Your Concern Number is:",
        withdrawCancelText: "To remove all information on your appeal, click Ok. To", 
        withdrawCancelTextBack: "stay on the appeal screen, click Cancel.",
        SAPPL: "SNAP Recertification",
        TELIRE$TRECER: "TANF Redetermination",
        SSIXRP$SSIXRF: "Six-Month Report",
        STIDEN$SRFAREP: "Request for Authorized Representative form",
        STEINV$STEINVGEN: "Employment Information",
        STRTAD$STRTAD: "Address Change",
        STRTSB$STRTSB: "Request to Stop Benefit(s)",
        STRTIE$STRTIE: "Income and/or Expense Changes",
        STRTOT$STRTOT: "Other",
        uploadDocumentVerificationType: "Please select a document.",
        STEINV$SWGVFM: "Wage Verification",
        TUNINV$TUNINVGEN: "Unearned Income Verification",
        STSHEX$STSHEXGEN: "Shelter Verification",
        SCHSUP$SCHSUPGEN: "Child Support Verification",
        SMEDEX$SMEDEXGEN: "Medical Support Verification",
        STIDEN$STIDENGEN: "Identification/Relationship Verification",
        TJACTR$TJACTRGEN: "Participation/Activity for JOBS",
        TJACTR$TATTDPROGREP: "TANF JOBS Attendance",
        documentProgramText: "The document is associated with which program below?",
        SNAP: "SNAP",
        TANF: "TANF",
        appealCancelText: "To remove all information on your appeal, click Ok. To", 
        appealCancelTextBack: "stay on the appeal screen, click Cancel.",
        FairhearingDays:"If you request a hearing within the ten (10) day adverse action period has not expired, you may be eligible to continue receiving benefits at the basis authorized prior  to the  notice of adverse action.",
        clientEmailAddressErrMsg:"Invalid Email",
        clientPhoneNumberErrMsg:"Invalid Phone Number",
        withdrawSuccessPopup:"You request to withdraw the Fair Hearing has been submitted successfully.",
        ebtCardHeader: "Lost, Stolen, or Damaged EBT Card",
        ebtCardUpdateContact: "Immediately call customer service at: 1-800-997-8888",
        ebtCardRemainder:"Reminder: If you have an authorized representative for your SNAP benefits, their card will still work and can be used to make purchases from your account unless you or your authorized representative have requested that it be cancelled.",
        okButton:"Ok",
        ebtCardInfo:"If your EBT card has been lost, stolen or is no longer working, please call the number listed above to request a replacement card. They will immediately disable the old card. A replacement card will be mailed to the address they have in their system. Please allow 5-7 business days for the card to arrive to you in the mail. If the address in their system is not correct, you will need to have your county office correct the address before a replacement card can be mailed. Your benefits will be protected as soon as you report your card as lost or stolen.",
        concernSubmitTextStatement: "Statements of Understanding / Fair Hearing documents",
        concernSubmitYouText: "You can",
        concernSubmitPrintText: "print",
        concernSubmitSaveText: "save",
        concernSubmitRefText: "the concern you have just submitted for further reference.",
        cancelText: "To remove all information on your concern, click OK. To",
        cancelTextMain: "stay on the concern screen, click Cancel.",
        anonymousSuccessText: "Thank you for submitting your concern. If you have ",
        anonymousSuccessTextTwo: "provided contact information, a DHR case  worker will ", 
        anonymousSuccessTextThree: "contact you within  72 hours.",
        anonymousOk: "Ok",
        anonymousSubmittedText: "Submitted Successfully",
        appealSubmitPrintText:"print",
        appealSubmitSaveText:"save",
        appealSubmitorText:"or",
        appealsYouCanText:"You can",
        appealsStatementText:"Statement of Understanding / Fair Hearing documents.",
        appealsRequestText:"the appeal request you have just submitted for future reference.",
        concernSubmitText: "or",
        appealReasonANLRPT:"ANLRPT",
        appealReasonBENCLO:"BENCLO",
        appealReasonEMPNP:"EMPNP",
        appealReasonTERECO:"TERECO",
        appealReasonAPVAPP:"APVAPP",
        appealReasonRTLAMT:"RTLAMT",
        appealReasonHHSIZE:"HHSIZE",
        appealReasonOVERPAY:"OVRPAY",
        appealReasonADA:"ADA",
        appealReasonADMDSQ:"ADMDSQ",
        appealReasonINTXIC:"INTXIC",
        appealReasonREQPRB:"REQPRB",
        appealReasonSF:"STLBEN",
        appealReasonDNRNOA:"DNRNOA",
        appealReasonSASUB:"SASB",
        appealReasonDAMINB:"DAMINB",
        appealReasonDABAWD:"DABAWD",
        appealReasonJBSSER:"JBSSER",
        appealReasonCSNNCM:"CSNNCM",
        appealReasonDFPVER:"DFPVER",
        appealReasonDNTMAP:"DNTMAP",
        appealReasonDNIERW:"DNIERW",
        appealReasonDNIEVQ:"DNIEVQ",
        appealReasonRETAMT:"RETAMT",
        appealReasonDISNAP:"DISNAP",
        appealReasonOVRLMT:"OVRLMT",
        appealReasonINTRPT:"INTRPT",
        appealReasonDNIEST:"DNIEST",
        appealReasonOTER:"OTER",
        appealsProgramSNAP: "SNAP",
        appealsProgramTANF: "TANF",
        appealsProgramJOBS: "JBS",
        legalRepHeading:"Legal Representative",
        cancelRequestOptions: [
          "Conflict with Appointment Date/Time",
          "Family Emergency",
          "Sickness",
          "Work/Class or Required Activity",
          "Lack of Childcare",
          "Lack of Transportation",
          "Other"
        ],
        commonCONWAD: "CONWAD",
        commonFLYEMG: "FLYEMG",
        commonSICKNS: "SICKNS",
        commonWCREQA: "WCREQA",
        commonLACKCC: "LACKCC",
        commonLACKTS: "LACKTS",
        commonOTHER: "OTHER",
         days :[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
      ],
     amLabelText : "AM", 
     pmLabelText : "PM",
     mondayFlagText:"mondayFlag",
     mondayAMFlagText:"mondayAMFlag",
     mondayPMFlagText:"mondayPMFlag",
     tuesdayFlag:"tuesdayFlag",
     tuesdayAMFlag:"tuesdayAMFlag",
     tuesdayPMFlag:"tuesdayPMFlag",
     wednesdayFlag:"wednesdayFlag",
     wednesdayAMFlag:"wednesdayAMFlag",
     wednesdayPMFlag:"wednesdayPMFlag",
     thursdayFlag:"thursdayFlag",
     thursdayAMFlag:"thursdayAMFlag",
     thursdayPMFlag:"thursdayPMFlag",
     fridayFlag:"fridayFlag",
     fridayAMFlag:"fridayAMFlag",
     fridayPMFlag:"fridayPMFlag",
     amValue:"AM" ,
     pmValue:"PM",
     remove:"Remove",
     done:"Done",
     isHomelessHouseholdheader : "Homeless Household",
     isHomelessHouseholdContent : [
      {
      line1:`If you do not have a mailing address,please submit your`,
      },
      {
      line2:`change(s) at your local county DHR office.Click `
      },
      {
      line3:`for more details.`
      }
     ],
     concernType:"Select the concern type.(Required)",
     concernTypeOptions: [
      "Client Concerns",
      "Provider Concerns",
    ],
    client:"Client Concerns",
    provider:"Provider Concerns",
    concernNext: "Next",
    concernTypeErrorText: "Please select an option.",
    providerSubmit: "Submit",
    providerGoback:"Go Back",
    providerText:"Provider",
    providerType:[
      "Inquiry",
      "Complaint",
      "Fraud",
      "Conference",
      "State Office Review"
    ],
    concernsText:"Concerns",
    ProviderClientText: "Provider",
    snapProgramCode:"SNAP",
    tanfProgramCode:"TANF",
    jobsProgramCode:"JBS",
    ArestProgramCode:"ARESET",
    noDHRProgramCode:"NONDTA",
    providerTypeErrorText:"Please select the Type.",
    concernsProgramOptions: [
      "SNAP",
      "TANF",
      "JOBS",
      "A-Reset",
      "Non-DHR Enquiry"
    ],
    providerReasonList:[
      "Contract Amendment",
      "Disbursement of payment",
      "Enrolled Client Issues",
      "Question About PaperWork",
      "Provider Portal System Issues"
    ],
     appealBoxText: "An Alabama SNAP participant or their authorized representative may request an administrative fair hearing if he/she disagrees with an action that affects his/her SNAP participation. A fair hearing can be requested by mail, fax, email, in person or through the ACES Self-Service Portal. The request must be made within 90 days from the date of the action. Within 60 days of the hearing request, a hearing will be conducted, a decision rendered, and the household will be notified of the decision. The hearing request may be withdrawn by the household verbally or in writing prior to the hearing. The standards for participation in SNAP are the same for everyone without regard to race, color, religious creed, national origin, sex (including gender identity and sexual orientation), political beliefs, age or disability.",
     appealLanguageText : "Hearing Packet",
    };
    case ES:
      return {
        accountConnectType: "¿Cómo le gustaría conectarse a su portal de autoservicio ACES DHR?",
        accountConnectTypeHelpText:
          "Si usted es el cabeza de familia, seleccione uno de los siguientes e ingrese la información solicitada.",
        web: "Número de solicitud web a SNAP",
        tanf: "Número de solicitud web de TANF",
        dta: "Identificación de la agencia del DHR",
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Ingrese su nombre.",
        lastNamelabel: "¿Cuál es su apellido?",
        lastNameErrMsg: "Ingrese su apellido.",
        dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
        ssnLabel: "¿Cuál es su número de seguro social?",
        ssnErrMsg: "Ingrese un número de Seguro Social válido.",
        ssn: "Número del Seguro Social",
        somethingwentWrong:
          "¡Oh! Algo salió mal, vuelva a intentarlo más tarde.",
        ebt: "Número de tarjeta de Transferencia Electrónica de Beneficios (EBT)",
        clientID: "Client ID",
        applicationNumber: "¿Cuál es su número de solicitud web a SNAP?",
        tanfNumber: "¿Cuál es su número de solicitud web de TANF?",
        dtaId: "¿Cuál es su identificación de la agencia del DHR?",
        ssnNumber: "¿Cuál es su número de Seguro Social?",
        whatIsClientId: "What is your Client ID?",
        myInfo: "Mi información",
        activity: "Actividad",
        // documents: "Documentos",
        documents: "Comunicaciones",
        myAccount: "My Account",
        residentialAddress: "Dirección residencials",
        mailingAddress: "Dirección postal",
        phoneNumber: "Número de teléfono principal",
        phoneInlineText:
          "Deseo recibir mensaje de texto SMS. Doy permiso para que el DHR use mi número de teléfono celular para enviarme mensajes de texto. Comprendo que es posible que se apliquen cargos de datos. Puedo pedirle al DHR que interrumpa los mensajes de texto SMS en cualquier momento.",
        allowTxtMsg: "¿Permitir notificaciones por mensaje de texto?",
        sameResidentail: "Igual que la dirección residencial",
        diffrentAddress: "Dirección postal diferente",
        editAddress: "Editar los detalles de mi dirección",
        editPhnone: "Editar detalles de mi teléfono",
        yes: "Sí",
        no: "No",
        editEmail: "Editar mi dirección de correo electrónico",
        emailAddress: "Dirección de correo electrónico",
        emailHelpText:
          "Deseo participar en eNotification (notificación electrónica). Doy permiso para que el DHR use mi dirección de correo electrónico. Puedo pedirle al DHR que interrumpa la notificación electrónica en cualquier momento.",
        emailNotification: "¿Permitir notificaciones por correo electrónico?",
        verificationList: "SOLICITAR CARTA DE VERIFICACIÓN DE INGRESOS",
        uploadDocumentType: "1. ¿Qué tipo de documento enviará hoy?",
        VERIFC: "Documento de verificación",
        VOLWIT: "Solicitud voluntaria de interrupción de los beneficios",
        STIDEN$SRFAREP: "Solicitar un representante autorizado",
        HOUMIS: "Solicitar reemplazo debido a desgracia del núcleo familiar",
        SAPPL$SRECERT: "Recertificación SNAP",
        SAPPL$SRECERTAPPL: "Recertificación SNAP",
        uploadDocumentAbout: "¿De quién se trata el documento?",
        dateRange: "Rango de fechas:",
        search: "Buscar",
        townHall:"Reunión municipal a las 3:00 p. m.",
        from:"Desde",
        fromMultiLang: " de ",
        to:"hasta",
        invalidTo:"Email inválido",
        reply:"Bandeja de entrada",
        archives:"Archivo",
        inbox:"Bandeja de entrada",
        all:"Toda",
        sendTxt:"Enviar a la lista de distribución del condado",
        importantMsg:"Mensaje importante",
        send:"Enviar",
        print:"Imprimir",
        reply:"Responder",
        archive:"Artículos Archivados",
        unRead:"No leída",
        byDate:"Por fecha",
        validProgram:"Se requiere área del programa",
        validTo:"Enviar correo electrónico es obligatorio",
        validSub:"El asunto es obligatorio",
        validBody:"Por favor escribe un mensaje",
        sendMsg:"Mensajes enviados",
        received:"Recibida",
        docType: "Tipo de documento:",
        selectDate: "seleccionar una fecha",
        nothingYet: "Nada aquí todavía!",
        addToCalender: "Agregar al calendario",
        filters: "Filtros",
        active: "Activo",
        pending: "Pendiente",
        ineligible: "Ilegible",
        denied: "Rechazado",
        closed: "Cerrado",
        phoneInLineHelp:
          "Deseo recibir mensaje de texto SMS. Doy permiso para que el DHR use mi número de teléfono celular para enviarme mensajes de texto. Comprendo que es posible que se apliquen cargos de datos. Puedo pedirle al DHR que interrumpa los mensajes de texto SMS en cualquier momento.",
        emailInLineHelp:
          "Deseo participar en eNotification (notificación electrónica). Doy permiso para que el DHR use mi dirección de correo electrónico. Puedo pedirle al DHR que interrumpa la notificación electrónica en cualquier momento.",
        upcomingActivities: "PRÓXIMAS ACTIVIDADES",
        pastActivities: "ACTIVIDADES ANTERIORES",
        requestPending: "Su solicitud de cambio de cita está pendiente.",
        requestCompleted: "Su solicitud de cambio de cita se completó.",
        requestNotProcessed:
          "No es posible procesar su solicitud de cambio de cita. Comuníquese con el administrador de su caso.",
        recertify:
          "Es hora de recertificar sus beneficios del SNAP. Sus beneficios del Programa de asistencia nutricional suplementaria (SNAP) finalizan el",
        transAid:
          "Sus beneficios de Ayuda transitoria para familias con menores dependientes",
        benefits: "finalizarán el",
        emergency:
          "Los beneficios de la Ayuda de emergencia para ancianos, discapacitados y niños",
        recertification: "Fecha límite de recertificación",
        revaluation: "Fecha límite de reevaluación",
        loadingLabel: "Cargando...",
        NoDataFound : "Datos no encontrados!!!",
        cancelPending: "Su solicitud de cancelación de cita está pendiente",
        cancelCompleted: "Cancelada",
        houseHoldSuccess:"Gracias por actualizar los datos de su hogar. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        incomeSuccess:"Gracias por actualizar tus ingresos. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        shelterSuccess:"Gracias por actualizar sus gastos de alojamiento y servicios públicos. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        childOrAdultSuccess:"Gracias por actualizar sus gastos de cuidado de niños o adultos. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        childSuccess:"Gracias por actualizar sus gastos de cuidado de niños o adultos. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        medicalSuccess:"Gracias por actualizar sus gastos médicos. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
        authRepSuccess:"Gracias por actualizar a su representante autorizado. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
 resourceSuccess:"Thank you for updating your resources. This change will be effective once the change request has been approved by the Case Worker.",
        typeLabel:"Tipo(Requerida)",
        reasonLabel:"Razón(Requerida)",
        programLabel:"Programa(Requerida)",
        dateLabel:"Fecha de acción o incidente",
        clientNameLabel:"nombre del cliente",
        clientIdLabel:"Identificación del cliente",
        withdrawnCommentLabel:"Motivo de retiro",
        checkboxLabel:" Me gustaría retirar la solicitud de apelación",
        signLabel:"Firme ingresando su nombre completo a continuación",
        signLabel:"Firme ingresando su nombre completo a continuación(Requerida)",
        checkboxErrorMsg:"por favor seleccione la casilla de verificación a continuación.",
        signErrorMsg:"por favor firme a continuación.",
        withdrawplaceHolder:"Proporcione una breve descripción para retirar su apelación.",
        concernsHeaderText: "Preocupaciones del cliente",
        concernTypeLabel: "Tipo (Obligatorio)",
        concernLabel: "Selecciona una categoría (Obligatorio)",
        concernsAppealTypeOptions: [
          "Preocupaciones del cliente",
          "Audiencia justa",
        ],

        concernsTypeOptions: [
          "Consulta",
          "Queja",
          "Fraude",
          "Conferencia",
          "Revisión de la oficina estatal"
        ],
        appealType: "Audiencia justa",
        concernsInquiry: "INQ",
        concernsComplaint: "CMPLNT",
        concernsFraud: "FRAUD",
        concernsConference: "CONF",
        concernsStateOffice: "STRVW",
        concernTypeErrorText: "Por favor seleccione el tipo",
        concernErrorText: "Por favor seleccione la categoría",
        concernsReasonLabel: "Motivo (obligatorio)",
        concernsReasonOptions:{
          "ABAWD": "ABAWD",
          "ALTISS": "cuestión de adjudicación",
          "APPSTA": "Estado de la aplicación",
          "CLSDNL": "cierre del caso",
          "CRCOMP": "denuncia de derechos civiles",
          "DSGDEC": "Desacuerdo con la decisión",
          "EBT": "EBT",
          "ELGINF": "Información de elegibilidad",
          "INTERP": "Intérprete",
          "INTAPP": "Cita para la entrevista",
          "JOBISS": "Cuestión de participación en el empleo",
          "LFTMSG": "Mensaje dejado/Sin devolución de llamada",
          "INTCAL": "Sin llamada de entrevista",
          "ONLAPP": "Aplicaciones en linea",
          "RECISS": "Problema de sobrepago/reclamación",
          "RPTFRD": "Denunciar fraude",
          "UPRCDT": "trato grosero",
          "MR": "Informe Semestral",
          "CLOTH": "pago especial",
          "REST": "Estado de los reembolsos de A-Reset",
          "CCAUTH": "servicios de apoyo",
          "SYSISS": "problema de sistemas",
          "LSTVER": "Problema de verificación",
          "VMMSG": "Trabajador No disponible"
      },
        concernsABAWD: "ABAWD",
        concernsAllotment: "ALTISS",
        concernsApplication: "APPSTA",
        concernsCase: "CLSDNL",
        concernsCivilRights: "CRCOMP",
        concernsDisagreement: "DSGDEC",
        concernsEBT: "EBT",
        concernsEligibility: "ELGINF",
        concernsInterpreter: "INTERP",
        concernsJob: "JOPAIS",
        concernsInterview: "INTAPP",
        concernsLeftMessage: "LFTMSG",
        concernsNoInterview: "INTCAL",
        concernsOnlineApplication: "ONLAPP",
        concernsOverPayment: "OVCLIS",
        concernsReportingFraud: "RPTFRD",
        concernsRudeTreatment: "UPRCDT",
        concernsSixMonthReport: "MR",
        concernsSpecialPayment: "CLOTH",
        concernsStatus: "REST",
        concernsSupportiveServices: "CCAUTH",
        concernsSystemIssue: "SYSISS",
        concernsVerificationIssue: "VLSTVER",
        concernsWorker: "VMMSG",
        concernsReasonErrorText: "Por favor seleccione el motivo",
        concernsProgramLabel: "Programa (requerido)",
        concernsProgramOptions: [
          "QUEBRAR",
          "TANF",
          "TRABAJOS",
          "Se establecen",
          "Consulta no perteneciente al DRH"
        ],
     
        providerProgramOptions: [
          "QUEBRAR",
          "TANF",
          "TRABAJOS",
          "Se establecen",
          "Consulta"
        ],

        concernsSNAP: "SNAP",
        concernsTANF: "TANF",
        concernsJOBS: "JBS",
        concernsReset: "ARESET",
        concernsNonDHR: "NONDTA",
        concernsProgramErrorText: "Por favor seleccione el programa",
        concernsDateLabel: "Fecha de acción o incidente",
        concernsAnonymousLabel: "Permanecer anónimo",
        concernsclientText: "Cliente",
        concernsclientNameLabel: "Nombre",
        concernsPhoneNumberLabel: "Número de teléfono",
        concernsEmailLabel: "Dirección de correo electrónico",
        concernsClientIdLabel: "Identificación del cliente",
        concernsubHeaderText: "Representante autorizada",
        concernsOfficeLabel: "Oficina de País",
        concernsManagerLabel: "El asistente social",
        concernsManagerPhoneNumberLabel: "Número de teléfono del trabajador social",
        concernsCommentsLabel: "Comentarios",
        concernsCommentsPlaceHolder: "Proporcione una breve descripción de las inquietudes/apelación aquí...",
        concernsGoback: "Regresa",
        concernCancel: "Cancelar",
        concernSubmit: "Entregar",
        concernsEmailError: "Email inválido",
        concernSubmitHeaderText: "¡Se ha enviado su inquietud como cliente!",
        concernsCancelHeader: "Estas seguro",
        concernsCancelText: "Haga clic en 'Cancelar' para eliminar toda la información sobre su inquietud.",
        concernsCancelTextBack: "Haga clic en 'Regresar' para regresar a la pantalla de Preocupación.",
        concernsLocationOptions:[
          "AL-AESAP Office",
          "AL-Autauga Office",
          "AL-Baldwin Office",
          "AL-Barbour Office",
          "AL-Bibb Office",
          "AL-Blount Office",
          "AL-Bullock Office",
          "AL-Butler Office",
          "AL-Calhoun Office",
          "AL-Chambers Office",
          "AL-Cherokee Office",
          "AL-Chilton Office",
          "AL-Choctaw Office",
          "AL-Clarke Office",
          "AL-Clay Office",
          "AL-Cleburne Office",
          "AL-Coffee Office",
          "AL-Colbert Office",
          "AL-Conecuh Office",
          "AL-Coosa Office",
          "AL-Covington Office",
          "AL-Crenshaw Office",
          "AL-Cullman Office",
          "AL-Dale Office",
          "AL-Dallas Office",
          "AL-Dekalb Office",
          "AL-Elmore Office",
          "AL-Escambia Office",
          "AL-Etowah Office",
          "AL-Fayette Office",
          "AL-Franklin office",
          "AL-Geneva Office",
          "AL-Greene Office",
          "AL-Hale Office",
          "AL-Henry Office",
          "AL-Houston Office",
          "AL-Jackson Office",
          "AL-Jefferson-Bessemer Office",
          "AL-Jefferson-Birmingham Office",
          "AL-Lamar Office",
          "AL-Lauderdale Office",
          "AL-Lawrence Office",
          "AL-Lee Office",
          "AL-Limestone Office",
          "AL-Lowndes Office",
          "AL-Macon Office",
          "AL-Madison Office",
          "AL-Marengo Office",
          "AL-Marion Office",
          "AL-Marshall Office"
      ],
      concernsManagerPhoneNumberError: "Numero de telefono invalido",
      concernsDateError: "Fecha invalida",      
        concernsCancelText: "Haga clic en 'Cancelar' para eliminar toda la información sobre su inquietud.",
        concernsCancelTextBack: "Haga clic en 'Regresar' para regresar a la pantalla de Preocupación.",
        concernlabel:"Preocupaciones del cliente:",
        forlabel:"para",
        anonymusUserlabel:"anónimo,",
        receivedlabel:"recibió",
        StatusChangedlabel:"Estado cambiado:",
        withdraw:"retirar",
        nothinghereYetlabel:"¡Nada aquí todavía!",
        createNewCustomerLabel:"Crear una nueva audiencia justa/preocupaciones del cliente",
        continueLabel:"Continuar",
        submitLabel:"Entregar",
        exitLabel:"Salida",
        closedLabel:"Cerrado",
        pendingLabel:"Pendiente",
        consumerconcern:"Preocupaciones del cliente/audiencias justas",
        consumerfair:"/audiencias justas",
        consumerconcernfair:"Preocupaciones del cliente",
        AppealsType:"Tipo(Obligatorio)",
        ReasonLabelText:"Motivo (obligatorio)",
        ProgramLabelText:"Programa(Requerido)",
        DateSelectorLabel:"Fecha de acción o incidente",
        authorizedClientNameLabel: "Nombre",
        authorizedClientPhoneNumberLabel: "Número de teléfono",
        authorizedClientEmailAddressLabel: "Dirección de correo electrónico",
        ClientNameLabel:"Nombre (Requerido)",
        ClientPhoneNumberLabel:"Número de teléfono (Requerida)",
        ClientEmailAddressLabel:"Dirección de correo electrónico (Requerida)",
        ClientIdLabel:"Identificación del cliente",
        RequestBenefitLabel:"Solicitud de continuación del beneficio.",
        LegalRepresentatiseLabel:"¿Tiene Representante Legal?",
        CommentsLabel:"Comentarios",
        reasonErrorMsg:"Seleccione el motivo.",
        programErrorMsg:"Por favor seleccione el programa.",
        nameErrorMsg:"Por favor ingrese un Nombre.",
        phoneNumberErrorMsg:"Por favor ingrese un Nombre.",
        emailAddressErrorMsg:"Por favor introduzca una dirección de correo eléctronico.",
        LegalRepresentativeHeading:"Ingrese los datos del Representante Legal",
        errorMessage:"Fecha invalida",
        appealsProgramOptions:[
          "QUEBRAR", "TANF", "TRABAJOS"
        ],
        appealsReasonOptions:{
          "ANLRPT": "Recertificación/Redeterminación",
          "BENCLO": "Caso cerrado",
          "EMPNP": "EMPLEOS - No participar",
          "TERECO": "Recuperación de TANF",
          "APVAPP": "Asignación",
          "RTLAMT": "Deducciones por disputas",
          "HHSIZE": "Composición del hogar",
          "OVRPAY": "Reclamaciones relacionadas",
          "ADA": "Acta de Americanos con Discapacidades",
          "ADMDSQ": "Inhabilitación Administrativa",
          "INTXIC": "Interceptación del impuesto sobre la renta",
          "DAMINB": "Ingresos presupuestados en disputa",
          "DABAWD": "Denegación/Despido - Adultos sanos sin dependientes (ABAWD)",
          "JBSSER": "EMPLEOS - Servicios de apoyo",
          "CSNNCM": "Manutención de menores – Incumplimiento",
          "DFPVER": "Denegación/Terminación - No proporcionar verificación",
          "DNTMAP": "Denegación/Terminación - Cita perdida",
          "DNIERW": "Denegación/Terminación - Recursos/Ganancias de juegos",
          "DNIEVQ": "Denegación/Terminación - Renuncia voluntaria",
          "RETAMT": "Asignación de reemplazo/Restauración",
          "DISNAP": "D-SNAP/Desastre",
          "24MEND": "Vencimiento del límite de tiempo de 60 meses",
          "OVRLMT": "Denegación/Terminación - Por encima del límite de ingresos",
          "INTRPT": "Denegación/Terminación-No presentación o Revisión semestral/Informe semestral incompleto",
          "DNIEST": "Denegación/Terminación - Estudiante no elegible",
          "OTER": "Otro"
        },
        appealFirst:"APLPRO",
        appealsHeaderText:"Audiencias justas",
        emailFormateErrMsg:"Email inválido",
        phoneNumberFormateErrMsg:"Numero de telefono invalido",
        appealsSubHeaderText: "Representante Autorizada (Opcional)",
        clientSubLebel:"cliente",
        commentsPlaceHolder:"Proporcione una breve descripción de la inquietud/apelación aquí...",
        SAPPL: "Recertificación SNAP",
        TELIRE$TRECER: "Redeterminación de TANF",
        SSIXRP$SSIXRF: "Informe semestral",
        STIDEN$SRFAREP: "Formulario de solicitud de representante autorizado",
        STEINV$STEINVGEN: "informacion de Empleo",
        STRTAD$STRTAD: "Cambio de dirección",
        STRTSB$STRTSB: "Solicitud para suspender los beneficios",
        STRTIE$STRTIE: "Cambios de ingresos y/o gastos",
        STRTOT$STRTOT: "Otro",
        uploadDocumentVerificationType: "Por favor seleccione un documento.",
        STEINV$SWGVFM: "Verificación de salario",
        TUNINV$TUNINVGEN: "Verificación de ingresos no derivados del trabajo",
        STSHEX$STSHEXGEN: "Verificación de refugio",
        SCHSUP$SCHSUPGEN: "Verificación de manutención infantil",
        SMEDEX$SMEDEXGEN: "Verificación de soporte médico",
        STIDEN$STIDENGEN: "Verificación de identificación/relación",
        TJACTR$TJACTRGEN: "Participación/Actividad para JOBS",
        TJACTR$TATTDPROGREP: "EMPLEOS- Actividades /Seguimiento",
        documentProgramText: "¿A qué programa a continuación está asociado el documento?",
        SNAP: "SNAP",
        TANF: "TANF",
        legalRepPhoneNumberFormateErrMsg:"Numero de telefono invalido",
        legalRepEmailFormateErrMsg:"Email inválido",
        anonymusUserHeading:"Autoservicio ACES DHR",
        anonymousConcernNumber:"Su número de preocupación es:",
        withdrawCancelText:"Para eliminar toda la información de su apelación, haga clic en Aceptar. A",
        withdrawCancelTextBack:"permanezca en la pantalla de apelación, haga clic en Cancelar.",
        appealCancelText:"Para eliminar toda la información de su apelación, haga clic en Aceptar. A",
        appealCancelTextBack:"permanezca en la pantalla de apelación, haga clic en Cancelar.",
        FairhearingDays:"Si solicita una audiencia dentro del período de diez (10) días de acción adversa que no ha expirado, puede ser elegible para continuar recibiendo beneficios según lo autorizado antes del aviso de acción adversa." ,     
        appealSubmitHeaderText:"¡Su solicitud de apelación ha sido enviada!",
        appealWithDrawsSubmitText:"Su apelación de retiro ha sido enviada",
        appealsNumber:"Su ID de apelación web:",
        widthDrawAppealNumber:"Su número de apelación de retiro es:",
        weeklyFrequencyLabel: "Semanalmente",
        monthlyFrequencyLabel: "Mensualmente",
        annualFrequencyLabel: "Anualmente",
        biweeklyFrequencyLabel: "Quincenalmente (cada dos semanas)",
        quarterlyFrequencyLabel: "Trimestralmente",
        semiannualFrequencyLabel: "Bianual (dos veces al año)",
        bimonthlyFrequencyLabel: "Bimestralmente (cada dos meses)",
        semimonthlyFrequencyLabel: "semi-mensal (dos veces al mes)",
        yearFrequencyLabel: "Año",
        NoDataFound : "Datos no encontrados !!!",
        ebtCardHeader: "Tarjeta EBT perdida, robada o dañada",
        ebtCardUpdateContact: "Llame inmediatamente a servicio al cliente al: 1-800-997-8888",
        ebtCardRemainder:"Recordatorio: si tiene un representante autorizado para sus bancos SNAP, su tarjeta seguirá funcionando y podrá usarse para realizar compras desde su cuenta a menos que usted o su representante autorizado haya solicitado que se cancele.",
        okButton:"De acuerdo",
        ebtCardInfo:"Si su tarjeta EBT se perdió, se la robaron o ya no funciona, llame al número que figura arriba para solicitar una tarjeta de reemplazo. Inmediatamente desactivarán la tarjeta anterior. Se enviará una tarjeta de reemplazo por correo a la dirección que tienen en su sistema. Espere entre 5 y 7 días hábiles para que le llegue la tarjeta por correo. Si la dirección en su sistema no es correcta, deberá pedirle a la oficina de su condado que corrija la dirección antes de que se pueda enviar por correo una tarjeta de reemplazo. Sus beneficios estarán protegidos tan pronto como los informe como perdidos o robados.",
        appealSubmitPrintText:"imprimir",
        appealSubmitSaveText:"ahorrar",
        appealSubmitorText:"o",
        appealsYouCanText:"Puede",
        appealsStatementText:"Declaración de entendimiento / Documentos de audiencia justa.",
        appealsRequestText:"la solicitud de apelación que acaba de enviar para referencia futura.",
        concernSubmitText: "o",
        anonymousSuccessText: "Gracias por enviar su inquietud. Si usted tiene ",
        anonymousSuccessTextTwo: "información de contacto proporcionada, un asistente social del DHR ", 
        anonymousSuccessTextThree: "contactarlo dentro de las 72 horas.",
        anonymousOk: "De acuerdo",
        anonymousSubmittedText: "Enviado satisfactoriamente",
        cancelText: "Para eliminar toda la información sobre su inquietud, haga clic en Aceptar. A",
        cancelTextMain: "permanezca en la pantalla de inquietudes, haga clic en Cancelar",
        concernNumber:"Su ID de preocupación web",
        concernSubmitYouText: "Puede",
        concernSubmitPrintText: "imprimir",
        concernSubmitSaveText: "ahorrar",
        concernSubmitRefText: "la inquietud que acaba de enviar para mayor referencia.",
        concernSubmitTextStatement: "Declaraciones de entendimiento/Documentos de audiencia imparcial",
        appealReasonANLRPT:"ANLRPT",
        appealReasonBENCLO:"BENCLO",
        appealReasonEMPNP:"EMPNP",
        appealReasonTERECO:"TERECO",
        appealReasonAPVAPP:"APVAPP",
        appealReasonRTLAMT:"RTLAMT",
        appealReasonHHSIZE:"HHSIZE",
        appealReasonOVERPAY:"OVRPAY",
        appealReasonADA:"ADA"  ,
        appealReasonADMDSQ:"ADMDSQ",
        appealReasonINTXIC:"INTXIC",
        appealReasonREQPRB:"REQPRB",
        appealReasonSF:"STLBEN",
        appealReasonDNRNOA:"DNRNOA",
        appealReasonSASUB:"SASB",
        appealReasonDAMINB:"DAMINB",
        appealReasonDABAWD:"DABAWD",
        appealReasonJBSSER:"JBSSER",
        appealReasonCSNNCM:"CSNNCM",
        appealReasonDFPVER:"DFPVER",
        appealReasonDNTMAP:"DNTMAP",
        appealReasonDNIERW:"DNIERW",
        appealReasonDNIEVQ:"DNIEVQ",
        appealReasonRETAMT:"RETAMT",
        appealReasonDISNAP:"DISNAP",
        appealReasonOVRLMT:"OVRLMT",
        appealReasonINTRPT:"INTRPT",
        appealReasonDNIEST:"DNIEST",
        appealReasonOTER:"OTER",
        appealsProgramSNAP: "SNAP",
        appealsProgramTANF: "TANF",
        appealsProgramJOBS: "JBS",
        withdrawSuccessPopup:"Su solicitud de retiro de la audiencia imparcial se ha presentado exitosamente.",
        legalRepHeading:"Representante legal",
	    cancelRequestOptions: [
          "Conflicto con la fecha de la cita",
          "Emergencia familiar",
          "Enfermedad",
          "Trabajo/Clase o Actividad Requerida",
          "Falta de cuidado infantil",
          "Falta de transporte",
          "Otro"
        ],
        commonCONWAD: "CONWAD",
        commonFLYEMG: "FLYEMG",
        commonSICKNS: "SICKNS",
        commonWCREQA: "WCREQA",
        commonLACKCC: "LACKCC",
        commonLACKTS: "LACKTS",
        commonOTHER: "OTHER",
        days: [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes"
      ],
      amLabelText : "SOY", 
      pmLabelText : "PM",
      mondayFlagText:"lunesFlag",
      mondayAMFlagText:"lunesAMFlag",
      mondayPMFlagText:"lunesPMFlag",
      tuesdayFlag:"martesFlag",
      tuesdayAMFlag:"martesAMFlag",
      tuesdayPMFlag:"martesPMFlag",
      wednesdayFlag:"miércolesFlag",
      wednesdayAMFlag:"miércolesAMFlag",
      wednesdayPMFlag:"miércolesPMFlag",
      thursdayFlag:"juevesFlag",
      thursdayAMFlag:"juevesAMFlag",
      thursdayPMFlag:"juevesPMFlag",
      fridayFlag:"viernesFlag",
      fridayAMFlag:"viernesAMFlag",
      fridayPMFlag:"viernesPMFlag",
      amValue:"SOY" ,
      pmValue:"PM",
      remove:"Eliminar",
      done:"Hecho",
      isHomelessHouseholdheader : "Hogar sin hogar",
      isHomelessHouseholdContent : [
        {
        line1:`Si no tiene una dirección, envíe su`,
        },
        {
        line2:`solicitud en la oficina local del DHR de su condado. Haga clic `
        },
        {
        line3:`para más detalles.`
        }
      ],
     concernType:"Seleccione el tipo de inquietud. (Obligatorio)",
     concernTypeOptions: [
      "Preocupaciones del cliente",
      "Preocupaciones del proveedor",
    ],
    client:"Client Concerns",
    provider:"Provider Concerns",
    concernNext: "Próxima",
    concernTypeErrorText: "Please select an option.",
    providerSubmit: "Entregar",
    providerGoback:"Regresa",
    providerText:"Provider",
    concernsText:"Preocupaciones",
    concernTypeErrorText: "Por favor seleccione una opción.",
    providerSubmit: "Entregar",
    providerText:"Provider",
    snapProgramCode:"SNAP",
    tanfProgramCode:"TANF",
    jobsProgramCode:"JBS",
    ArestProgramCode:"ARESET",
    noDHRProgramCode:"NONDTA",
    ProviderClientText: "Proveedora",
    providerTypeOptions: [
      "Consulta",
      "Queja",
      "Fraude",
    ],
    providerTypeErrorText:"Por favor seleccione el tipo.",
    providerReasonList:[
     "Enmienda del contrato",
      "Desembolso de pago", 
      "Problemas de clientes inscritos",
      "Pregunta sobre el papeleo",
      "Problemas con el sistema del portal del proveedor"
    ],
      appealBoxText: "Un participante de Alabama SNAP o su representante autorizado puede solicitar una audiencia administrativa imparcial si no está de acuerdo con una acción que afecta su participación en SNAP. Se puede solicitar una audiencia imparcial por correo, fax, correo electrónico, en persona o mediante el Portal de autoservicio de ACES. La solicitud deberá realizarse dentro de los 90 días siguientes a la fecha de la actuación. Dentro de los 60 días posteriores a la solicitud de audiencia, se llevará a cabo una audiencia, se tomará una decisión y se notificará al hogar sobre la decisión. El hogar puede retirar la solicitud de audiencia verbalmente o por escrito antes de la audiencia. Los estándares de participación en SNAP son los mismos para todos, sin distinción de raza, color, credo religioso, origen nacional, sexo (incluida la identidad de género y la orientación sexual), creencias políticas, edad o discapacidad.",
      appealLanguageText: "Paquete de Audiencia",
      };
    case PT:
      return {
        accountConnectType:
          "Como você gostaria de se conectar à sua conta DHR?",
        accountConnectTypeHelpText:
          "Se você for o chefe de família, insira suas informações para acessar os seus casos na DHR.",
        web: "Número do aplicativo SNAP da Web",
        dta: "ID da agência DHR",
        ssn: "Número da Segurança Social",
        somethingwentWrong: "Êpa! Algo deu errado, tente novamente mais tarde",
        ebt: "Número do cartão EBT",
        applicationNumber: "Qual é o seu número de solicitação SNAP Web?",
        dtaId: "Qual é o seu ID de Agência DHR?",
        ssnNumber: "Qual é o seu número de seguro social?",
        ebtNumber: "Qual é o seu número de cartão EBT?",
        myInfo: "Minhas informações",
        activity: "Atividade",
        documents: "Documentos",
        myAccount: "My Account",
        residentialAddress: "Endereço residencial",
        mailingAddress: "Endereço de correspondência",
        phoneNumber: "Número de telefone principal",
        phoneInlineText:
          "Quero receber mensagens de SMS. Autorizo a DHR a usar o meu número de telefone para enviar mensagens de texto. Compreendo que podem ser cobrados valores pela transmissão dos dados. Posso interromper o envio das mensagens a qualquer momento avisando à DHR.",
        allowTxtMsg: "Permitir notificações por mensagem de texto",
        sameResidentail: "O mesmo da residência",
        diffrentAddress: "Endereço de correspondência diferente",
        editAddress: "Editar o meu endereço",
        editPhnone: "Editar detalhes do meu telefone",
        yes: "Sim",
        no: "Não",
        editEmail: "Editar o meu endereço de o email",
        emailAddress: "Endereço de e-mail",
        emailHelpText:
          "Quero participar do E-notificação. Autorizo a DHR a usar o meu endereço de e-mail. Posso interromper o E-notificação a qualquer momento avisando à DHR.",
        emailNotification: "Permitir notificações por e-mail",
        verificationList: "SOLICITAR CARTA DE VERIFICAÇÃO DE RENDA",
        uploadDocumentType: "1. Que tipo de documento você vai enviar hoje?",
        VERIFC: "Documento de verificação",
        VOLWIT: "Requisição voluntária para interrupção dos benefícios",
        STIDEN$SRFAREP: "Requisição para representante autorizado",
        HOUMIS: "Requisição para substituição devido à problemas na casa",
        SAPPL: "Formulário de recertificação",
        uploadDocumentAbout: "De quem é este documento?",
        dateRange: "Intervalo de datas:",
        search: "Pesquisar",
        docType: "Tipo de documento:",
        selectDate: "selecionar uma data",
        nothingYet: "Nada aqui ainda!",
        addToCalender: "Adicionar ao calendário",
        filters: "Filtro",
        active: "Ativo",
        pending: "Pendente",
        ineligible: "Ilegível",
        denied: "Negado",
        closed: "Encerrado",
        phoneInLineHelp:
          "Quero receber mensagens de SMS. Autorizo a DHR a usar o meu número de telefone para enviar mensagens de texto. Compreendo que podem ser cobrados valores pela transmissão dos dados. Posso interromper o envio das mensagens a qualquer momento avisando à DHR.",
        emailInLineHelp:
          "Quero participar do eNotification. Autorizo a DHR a usar o meu endereço de e-mail. Posso interromper o eNotification a qualquer momento avisando à DHR.",
        upcomingActivities: "PRÓXIMAS MEDIDAS",
        pastActivities: "MEDIDAS PASSADAS",
        requestPending:
          "A sua solicitação de mudança de agendamento está pendente.",
        requestCompleted:
          "A sua solicaitação de mudança de agendamento está completa.",
        requestNotProcessed:
          "A sua solicitação de mudança de agendamento não foi aprovada, por favor entre em contado com o gerente do seu caso.",
        recertify:
          "É hora de recertificar seus benefícios SNAP. Seu benefícios do Programa de Assistência Nutricional Suplementar (SNAP) terminam em",
        transAid:
          "Seus benefícios de ajuda transitória para famílias com filhos dependentes",
        benefits: "terminam em",
        emergency:
          "Seus benefícios de auxílio de emergência a idosos, portadores de deficiência e crianças",
        recertification: "Prazo para recertificação",
        revaluation: "Prazo para reavaliação",
        loadingLabel: "Carregando...",
      };
    case ZH:
      return {
        accountConnectType: "您想如何连接到您的DTA帐户？",
        somethingwentWrong: "糟糕！发生错误，请稍后再试",
        accountConnectTypeHelpText:
          "如果您是户主，请输入您的信息以便访问您在 DHR 的案例.",
        web: "SNAP Web应用程序号",
        dta: "DTA代理商编号",
        ssn: "社会安全号码",
        ebt: "EBT卡号",
        applicationNumber: "您的 SNAP 网络申请编号是多少？",
        dtaId: "您的 DHR 代理 ID 是什么？",
        ssnNumber: "您的社会安全号码是什么？",
        ebtNumber: "您的 EBT 卡号是多少？",
        myInfo: "我的信息",
        activity: "活动",
        documents: "文件",
        myAccount: "My Account",
        residentialAddress: "居住地址",
        mailingAddress: "邮寄地址",
        phoneNumber: "常用电话号码",
        phoneInlineText:
          "我希望接收短信。我允许 DHR 使用我的手机号码向我发送短信。我理解，这样的操作可能收取数据传输费用。我可以随时告知 DHR 停止短信服务。",
        allowTxtMsg: "允许短信通知？",
        sameResidentail: "与居住地址相同",
        diffrentAddress: "另一个邮寄地址",
        editAddress: "编辑我的地址详细信息",
        editPhnone: "编辑我的手机详情",
        yes: "是",
        no: "否",
        editEmail: "编辑我的电子邮件地址",
        emailAddress: "邮寄地址",
        emailHelpText:
          "我希望参加电子通知 (eNotification)。我允许 DHR 使用我的电子邮箱地址。我可以随时告知 DHR 停止电子通知。",
        emailNotification: "允许电子邮件通知？",
        verificationList: "收入验证申请函",
        uploadDocumentType: "1. 您今天发送的文件是哪种类型？",
        VERIFC: "验证文件",
        VOLWIT: "自愿停止福利请求",
        STIDEN$SRFAREP: "授权代表请求",
        HOUMIS: "因家庭不幸而提出的替换请求",
        SAPPL: "再认证表格",
        uploadDocumentAbout: "文件内容描述对象是谁？",
        dateRange: "日期范围：",
        search: "搜索",
        docType: "文件类型:",
        selectDate: "选择日期",
        nothingYet: "这里还没有!",
        addToCalender: "添加至日历",
        filters: "过滤器",
        active: "活跃",
        pending: "未决",
        ineligible: "不合格",
        denied: "拒绝",
        closed: "关闭",
        phoneInLineHelp:
          "我希望接收短信。我允许 DHR 使用我的手机号码向我发送短信。我理解，这样的操作可能收取数据传输费用。我可以随时告知 DHR 停止短信服务。",
        emailInLineHelp:
          "我希望参加电子通知 (eNotification)。我允许 DHR 使用我的电子邮箱地址。我可以随时告知 DHR 停止电子通知。",
        upcomingActivities: "即将举行的活动",
        pastActivities: "已经发生的活动",
        requestPending: "您的预约变更请求目前是待定。",
        requestCompleted: "您的预约变更请求已经完成。",
        requestNotProcessed: "您的预约变更请求无法被处理，请联系您的专案经理。",
        recertify:
          "是时间重新验证您的 SNAP 福利了。您的补充营养援助计划 (SNAP) 将于以下日期到期",
        transAid: "您的受抚养子女的家庭的过渡性援助",
        benefits: "福利将于以下日期到期",
        emergency: "您的老人、残疾人、儿童紧急援助 ",
        recertification: "重新验证到期",
        revaluation: "重新评估到期",
        loadingLabel: "加载...",
      };
    case VI:
      return {
        accountConnectType:
          "Bạn muốn kết nối với Tài khoản DHR của mình như thế nào?",
        somethingwentWrong: "Úi chà! Đã xảy ra sự cố vui lòng thử lại sau",
        accountConnectTypeHelpText:
          "Nếu là chủ hộ, nhập thông tin để xem xét trường hợp với DHR.",
        web: "Số ứng dụng web SNAP",
        dta: "ID đại lý DHR",
        ssn: "Số an sinh xã hội",
        ebt: "Số thẻ EBT",
        applicationNumber: "Mã ứng dụng web SNAP?",
        dtaId: "Mã cơ quan DHR?",
        ssnNumber: "Mã an xinh xã hội?",
        ebtNumber: "Số thẻ EBT?",
        myInfo: "Thông tin cá nhân",
        activity: "Hoạt động",
        documents: "Tài liệu",
        myAccount: "My Account",
        residentialAddress: "Quê quán",
        mailingAddress: "Địa chỉ hòm thư",
        phoneNumber: "Điện thoại chính",
        phoneInlineText:
          "Tôi muốn nhận thông báo SMS. Tôi cho phép DHR dùng số di động đã cấp để gửi nhắn tin cho tôi. Tôi biết có thể chịu phí. Tôi có thể ngừng SMS mọi lúc khi thông báo DHR.",
        allowTxtMsg: "Cần thông báo tin nhắn không?",
        sameResidentail: "Như quê quán",
        diffrentAddress: "Địa chỉ hòm thư khác",
        editAddress: "Địa chỉ",
        editPhnone: "Chỉnh sửa thông tin trong Điện thoại của tôi",
        yes: "Có",
        no: "Không",
        editEmail: "Sửa địa chỉ e-mail",
        emailAddress: "Địa chỉ thư điện tử",
        emailHelpText:
          "Tôi muốn tham gia thông báo điện. Tôi cho phép DHR dùng thư điện tử đã cấp. Tôi có thể ngừng Thông báo điện mọi lúc khi thông báo DHR.",
        emailNotification: "Cần thông báo thư điện tử không?",
        verificationList: "YÊU CẦU THƯ XÁC MINH THU NHẬP",
        uploadDocumentType: "1. Cần gửi tài liệu gì?",
        VERIFC: "Giấy tờ xác minh",
        VOLWIT: "Đơn tự nguyện ngừng trợ cấp",
        STIDEN$SRFAREP: "Yêu cầu đại diện ủy quyền",
        HOUMIS: "Yêu cầu thay thế do hoàn cảnh gia đình",
        SAPPL: "Biểu mẫu cấp lại chứng nhận",
        uploadDocumentAbout: "Tài liệu về ai? (bắt buộc)",
        dateRange: "Khoảng thời gian:",
        search: "Tìm",
        docType: "Dạng giấy tờ :",
        selectDate: "chọn",
        nothingYet: "Chưa có gì ở đây!",
        addToCalender: "Bổ sung vào Lịch",
        filters: "Chỉ tiêu",
        active: "Đang hoạt động",
        pending: "Đọng",
        ineligible: "Không đủ tư cách",
        denied: "Từ chối",
        closed: "Đóng",
        phoneInLineHelp:
          "Tôi muốn nhận thông báo SMS. Tôi cho phép DHR dùng số di động đã cấp để gửi nhắn tin cho tôi. Tôi biết có thể chịu phí. Tôi có thể ngừng SMS mọi lúc khi thông báo DHR.",
        emailInLineHelp:
          "Tôi muốn tham gia thông báo điện. Tôi cho phép DHR dùng thư điện tử đã cấp. Tôi có thể ngừng Thông báo điện mọi lúc khi thông báo DHR.",
        upcomingActivities: "CÁC HOẠT ĐỘNG SẮP TỚI",
        pastActivities: "CÁC HOẠT ĐỘNG ĐÃ QUA",
        requestPending: "Yêu cầu thay đổi cuộc hẹn của bạn đang chờ xử lý.",
        requestCompleted:
          "Yêu cầu thay đổi cuộc hẹn của bạn đã được hoàn thành.",
        requestNotProcessed:
          "Yêu cầu thay đổi cuộc hẹn của bạn không thể được xử lý, vui lòng liên hệ với người quản lý hồ sơ của bạn.",
        recertify:
          "Đã đến lúc xác nhận lại các quyền lợi SNAP của bạn. Quyền lợi của chương trình hỗ trợ dinh dưỡng bổ sung (SNAP) của bạn sẽ kết thúc vào",
        transAid:
          "Các quyền lợi Hỗ trợ chuyển tiếp cho các gia đình có trẻ em phụ thuộc",
        benefits: "đến hạn kết thúc vào",
        emergency:
          "Các quyền lợi Hỗ trợ khẩn cấp cho người già, người khuyết tật, trẻ em",
        recertification: "Đến hạn chứng nhận lại",
        revaluation: "Đến hạn đánh giá lại",
        loadingLabel: "Đang tải...",
      };
    case HI:
      return {
        accountConnectType: "Kijan ou ta renmen konekte nan kont DHR w la?",
        somethingwentWrong:
          "Oops! Gen yon bagay ki pa mache, tanpri eseye ankò pita",
        accountConnectTypeHelpText:
          "Si ou se chèf fwaye a, tanpri antre enfòmasyon w yo pou jwenn aksè nan dosye w yo ak DHR.",
        web: "Nimewo Aplikasyon Wèb SNAP",
        dta: "ID Ajans DHR",
        ssn: "Nimewo Sekirite Sosyal",
        ebt: "Nimewo Kat EBT",
        applicationNumber: "Ki Nimewo Aplikasyon An Liy SNAP ou a?",
        dtaId: "Ki Iandifikasyon Ajans DHR w la?",
        ssnNumber: "Ki nimewo Sekirite Sosyal ou?",
        ebtNumber: "Ki Nimewo Kat EBT w la?",
        myInfo: "Enfòmasyon konsènan mwen menm",
        activity: "Aktivite",
        documents: "Dokiman",
        myAccount: "Kont Mwen",
        residentialAddress: "Adrès rezidansyèl",
        mailingAddress: "Adrès postal",
        phoneNumber: "Nimewo telefòn prensipal la",
        phoneInlineText:
          "Mwen vle resevwa SMS mesaj tèks. Mwen bay DHR pèmisyon pou itilize nimewo telefòn selilè mwen pou voye mesaj tèks ban mwen. M konprann ke chaj pou done ka aplike. M ka sispann mesaj tèks SMS nenpòt lè nan enfòme DHR.",
        allowTxtMsg: "Pèmèt notifikasyon mesaj tèks?",
        sameResidentail: "Menm ak Adrès Rezidansyèl?",
        diffrentAddress: "Diferan adrès pou lapòs.",
        editAddress: "Edite detay adrès mwen ",
        editPhnone: "Edite detay telefòn mwen yo",
        yes: "Wi",
        no: "Non",
        editEmail: "Modifye adrès imel mwen",
        emailAddress: "Adrès Imel",
        emailHelpText:
          "M ta renmen patisipe nan Notifikasyon elektwonik. Mwen bay DHR pèmisyon pou itilize adrès imel mwen. M ka sispann Notifikasyon elektwonik nenpòt lè nan enfòme DHR.",
        emailNotification: "Pèmèt notifikasyon imel?",
        verificationList: "MANDE LÈT VERIFIKASYON BENEFIS",
        uploadDocumentType: "1. Ki tip dokiman w ap voye jodi a?",
        VERIFC: "Dokiman verifikasyon",
        VOLWIT: "Demann volontè pou sispann benefis",
        STIDEN$SRFAREP: "Demann pou reprezantan otorize",
        HOUMIS: "Demann pou Ranplasman akòz yon Malè nan Fwaye a",
        SAPPL: "Fòm resètifikasyon",
        uploadDocumentAbout: "Ki moun dokiman an konsène? ",
        dateRange: "Dat Ranje:",
        search: "Chèche",
        docType: "Kalite Dokiman:",
        selectDate: "chwazi yon dat",
        nothingYet: "Poko gen anyen la a!",
        addToCalender: "Ajoute nan Kalandriye w",
        filters: "Filtè",
        active: "Aktif",
        pending: "Annatant",
        ineligible: "Pa kalifye",
        denied: "Refize",
        closed: "Fèmen",
        phoneInLineHelp:
          "Mwen vle resevwa SMS mesaj tèks. Mwen bay DHR pèmisyon pou itilize nimewo telefòn selilè mwen pou voye mesaj tèks ban mwen. M konprann ke chaj pou done ka aplike. M ka sispann mesaj tèks SMS nenpòt lè nan enfòme DHR.",
        emailInLineHelp:
          "M ta renmen patisipe nan Notifikasyon elektwonik. Mwen bay DHR pèmisyon pou itilize adrès imel mwen. M ka sispann Notifikasyon elektwonik nenpòt lè nan enfòme DHR.",
        upcomingActivities: "AKTIVITE KI PRAL VINI",
        pastActivities: "AKTIVITE PASE",
        requestPending: "Demann pou chanje randevou w la annatant.",
        requestCompleted: "Demann pou chanje randevou w la fini",
        requestNotProcessed:
          "Demann pou chanje randevou w la pa ka trete, tanpri kontakte manadjè dosye w la.",
        recertify:
          "Li lè pou resètifye avantaj SNAP ou yo. Benefis Pwogram Asistans Nitrisyonèl Siplemantè (SNAP) w la pwograme pou fini nan dat",
        transAid: "Èd Tranzisyonèl pou Fanmi ak Timoun Depandan w lan",
        benefits: "benefis yo pwograme pou fini nan dat",
        emergency: "Èd Ijans pou Granmoun Aje, andikape, Timoun ou an",
        recertification: "Delè pou Resètifikasyon ",
        revaluation: "Delè pou Re-evalyasyon",
        loadingLabel: "Chajman...",
      };
    default:
      return {
        accountConnectType: "How would you like to search for a client?",
        accountConnectTypeHelpText:
          "If you are the head of household, please select one of the following below and enter the requested information.",
        web: "SNAP Web Application Number",
        dta: "DHR Agency ID",
        ssn: "Social Security Number",
        ebt: "EBT Card Number",
        clientID: "Client ID",
        applicationNumber: "What is your SNAP Web Application Number?",
        dtaId: "What is your DHR Agency ID?",
        ssnNumber: "What is your Social Security Number?",
        ebtNumber: "What is your EBT Card Number?",
        whatIsClientId: "What is your Client ID?",
        myInfo: "My Info",
        activity: "Activity",
        documents: "Documents",
        myAccount: "My Account",
        residentialAddress: "Home Address",
        mailingAddress: "Mailing address",
        phoneNumber: "Primary Phone Number",
        phoneInlineText:
        "I want to get SMS Text Messages. I give Alabama DHR permission to use my cell phone number to send text messages. I understand that data charges may apply. I can stop SMS Text Messaging Notifications by changing allow text messaging notifications to no.",
        allowTxtMsg: "Allow Text Messsage Notifications?",
        sameResidentail: "Same as residential",
        diffrentAddress: "Different address for mail.",
        editAddress: "Edit my address details",
        editPhnone: "Edit my Phone details",
        yes: "Yes",
        no: "No",
        editEmail: "Edit my mailing address",
        emailAddress: "Email Address",
        emailHelpText:
          "I want to participate in eNotification. I give DHR permission to use my email address. I can stop eNotification at any time by telling DHR.",
        emailNotification: "Allow Email Notifications?",
        verificationList: "REQUEST BENEFIT VERIFICATION LETTER",
        uploadDocumentType: "1. What kind of document are you sending today?",
        VERIFC: "Verification document",
        VOLWIT: "Voluntary request to stop benefits",
        STIDEN$SRFAREP: "Request for authorized representative",
        HOUMIS: "Request for Replacement Due to Household Misfortune",
        SAPPL: "Recertificación SNAP",
        uploadDocumentAbout: "Who is the document about?",
        dateRange: "Date Range:",
        search: "Search",
        docType: "Document Type:",
        selectDate: "select a date",
        nothingYet: "Nothing here yet!",
        addToCalender: "Add to Calendar",
        filters: "Filters",
        active: "active",
        pending: "pending",
        ineligible: "ineligible",
        denied: "denied",
        closed: "closed",
        phoneInLineHelp:
          "I want to get SMS Text Messages. I give DHR permission to use my cell phone number to send me text messages. I understand that data charges may apply. I can stop SMS Text Messaging at any time by telling DHR.",
        emailInLineHelp:
          "I want to participate in eNotification. I give DHR permission to use my email address. I can stop eNotification at any time by telling DHR.",
        upcomingActivities: "UPCOMING ACTIVITIES",
        pastActivities: "PAST ACTIVITIES",
        requestPending: "Your appointment change request is pending.",
        requestCompleted: "Your appointment change request has been completed.",
        requestNotProcessed:
          "Your appointment change request cannot be processed, please contact your case manager.",
        recertify:
          "Its time to recertify your SNAP benefits. Your Supplemental Nutrition Assistance Program (SNAP) Benefits are due to end on",
        transAid: "Your Transitional Aid to Families with Dependent Children",
        benefits: "benefits are due to end on",
        emergency: "Your Emergency Aid to Elderly, Disabled, Child",
        recertification: "Recertification Due",
        revaluation: "Revaluation Due",
        loadingLabel: "Loading...",
        weeklyFrequencyLabel: "Weekly",
        monthlyFrequencyLabel: "Monthly",
        annualFrequencyLabel: "Annual",
        biweeklyFrequencyLabel: "Biweekly (every two weeks)",
        quarterlyFrequencyLabel: "Quarterly",
        semiannualFrequencyLabel: "Semiannual (twice a year)",
        bimonthlyFrequencyLabel: "Bimonthly (every two months)",
        semimonthlyFrequencyLabel: "Semimonthly (twice a month)",
        yearFrequencyLabel: "Year"
      };
  }
}

export function activityGetDescription(typeCode) {
  const activityAlertList = {
    appnmt_code_1: "Assessment",
    appnmt_code_11: "24-Month Ext. Agreement",
    appnmt_code_12: "Ext. Appt. In-Office",
    appnmt_code_13: "Ext. Appt. Telephone",
    appnmt_code_15: "Income Match",
    appnmt_code_17: "Case Maint In-Office: Any Program",
    appnmt_code_18: "Reevaluation In-Office: EAEDC only",
    appnmt_code_19: "Application In-Office: SNAP",
    appnmt_code_190: "Client Assistance Coordinator - In-Office",
    appnmt_code_2: "Reassessment",
    appnmt_code_20: "Application Telephone: SNAP",
    appnmt_code_200: "Client Assistance Coordinator - Telephone",
    appnmt_code_201: "PSS Appointment In-Office",
    appnmt_code_202: "PSS Appointment Telephone",
    appnmt_code_90: "PSS Appointment Special Intensive Evaluation",
    appnmt_code_21: "Application Telephone: SNAP - Web Unit",
    appnmt_code_210: "Vendor Payment - Rent",
    appnmt_code_211: "Vendor Payment - Utility",
    appnmt_code_22: "Reevaluation In-Office: TANF only",
    appnmt_code_23: "Reevaluation: EA",
    appnmt_code_24: "Reevaluation In-Office: SNAP only",
    appnmt_code_25: "Reevaluation In-Office: EAEDC and SNAP",
    appnmt_code_26: "Reevaluation In-Office: TANF and SNAP",
    appnmt_code_3: "Cont Assessment",
    appnmt_code_4: "Cont Reassessment",
    appnmt_code_51: "Case Maint Telephone: Any Program",
    appnmt_code_52: "Reevaluation Telephone: EAEDC only",
    appnmt_code_53: "Reevaluation Telephone: SNAP only",
    appnmt_code_54: "Reevaluation Telephone: TANF only",
    appnmt_code_55: "EBT Photo Card",
    appnmt_code_57: "TANF Orientation",
    appnmt_code_58: "Reevaluation Telephone: EAEDC and SNAP",
    appnmt_code_59: "Reevaluation Telephone: TANF and SNAP",
    appnmt_code_9: "Good Cause Expiration",
    appnmt_code_203: "Family Cap - In-Office",
    appnmt_code_204: "Family Cap - Telephone",
  };

  const map = new Map(Object.entries(activityAlertList));
  return map.get(typeCode);
}

export function connecttodtaLogoutPopupLabels(language) {
  switch (language) {
    case EN:
      return {
        connected: "Connected!",
        connectingAcc: "Connecting account...",
        uploadSuccess:
          "Thank you for using ACES DHR Self-Service Portal to upload your document. The status of your upload will appear on the Communications tab under the My Documents tab within 1 business day.",
        messageSuccess: "Your message to ALDHR has been recieved. Please allow up to 72 hours for the ACES Help Desk to Respond.",
        errorMessage: "Please try again later.",
        connectToDtaLogout:
          "Your case is now linked to your ACES DHR Self-Service Portal account. You must log back in for this change to take place.",

          firstNameErrMsg: "Please enter your first name.",
          lastNameErrMsg: "Please enter your last name.",
          ssnErrMsg: "Please enter a valid Social Security Number.",
				  dobErrMsg: "Please enter a valid Date of Birth.",
      };
    case ES:
      return {
        connected: "Conectado!",
        uploadSuccess:
          "Gracias por usar ACES DHR Self-Service Portal para subir su documento. El estado de su documento aparecerá en la pantalla “Case Activity” (actividad del caso) en 1 día hábil. ID de referencia:",
        messageSuccess: "Su mensaje a ALDHR ha sido recibido. Espere hasta 72 horas para que la mesa de ayuda de ACES responda.",
        errorMessage: "Por favor, inténtelo de nuevo más tarde",
        connectingAcc: "Conectando cuenta...",
        connectToDtaLogout:
          "Su caso ahora está vinculado con su cuenta de ACES DHR Self-Service Portal. Debe volver a iniciar sesión para que se aplique este cambio.",
          firstNameErrMsg: "Por favor, introduzca su nombre de pila.",
          lastNameErrMsg: "Por favor ingrese su apellido.",
          ssnErrMsg: "Ingrese un número de Seguro Social válido.",
				  dobErrMsg: "Por favor ingresa una fecha de nacimiento valida.",
      };
    case PT:
      return {
        connected: "Conectado!",
        uploadSuccess:
          "Obrigado por usar o ACES DHR Self-Service Portal para enviar seu documento. O status do seu documento aparecerá na tela Atividade do caso em até um dia útil. Id de referência:",
        connectingAcc: "Conectando a conta...",
        connectToDtaLogout:
          "Agora, seu caso está vinculado à sua conta do ACES DHR Self-Service Portal. Faça o login novamente para que suas alterações tenham efeito.",
      };
    case ZH:
      return {
        connected: "已连接!",
        connectingAcc: "连接账户...",
        uploadSuccess:
          "感谢使用 ACES DHR Self-Service Portal 上传您的文件。您的文件状态将在 1 个工作日内显示在案例活动 (Case Activity) 屏幕上。参考 ID：",
        connectToDtaLogout:
          "您的案例现已链接至您的 ACES DHR Self-Service Portal 账户，您必须重新登录才可进行更改。",
      };
    case VI:
      return {
        connected: "Đã kết nối!",
        uploadSuccess:
          "Cảm ơn bạn đã sử dụng ACES DHR Self-Service Portal để tải lên tài liệu của mình. Tình trạng tài liệu của bạn sẽ xuất hiện trên màn hình Hoạt động của hồ sơ trong vòng 1 ngày làm việc. Số ID tham chiếu:",
        connectingAcc: "Đang kết nối tài khoản...",
        connectToDtaLogout:
          "Hồ sơ của bạn hiện được liên kết với tài khoản ACES DHR Self-Service Portal của bạn. Bạn phải đăng nhập lại để thay đổi này bắt đầu có hiệu lực.",
      };
    case HI:
      return {
        connected: "Konekte",
        connectingAcc: "Kont Koneksyon...",
        uploadSuccess:
          "Mèsi paske w itilize ACES DHR Self-Service Portal pou telechaje dokiman w lan. Estati dokiman w lan pral parèt sou ekran Aktivite Dosye a nan 1 jou ouvrab. Id Referans:",
        connectToDtaLogout:
          "Dosye w la konekte kounye a ak kont ACES DHR Self-Service Portal ou a. Ou dwe konekte ankò pou chanjman sa a ka fèt.",
      };
  }
}


export function determinationDescriptionMapper(language) {
  switch (language) {
    case EN:
      return {
        "SSA": "SSA",
        "VAVA": "Veteran",
        "APSTMT": "Medical Professional",
        
      };
    case ES:
     return {
        "SSA": "SSA",
        "VAVA": "Veterano",
        "APSTMT": "Profesional médico"
      };
    
    default:
      return {
        "SSA": "SSA",
        "VAVA": "Veteran",
        "APSTMT": "Medical Professional",
      };
  }
}


export function determinationCodeMapper(language) {
  switch (language) {
    case EN:
      return {
        "SSA": "SSA",
        "Veteran": "VAVA",
        "Medical Professional": "APSTMT",
        
      };
    case ES:
     return {
        "SSA": "SSA",
        "Veterano": "VAVA",
        "Profesional médico": "APSTMT",
        
      };
    
    default:
      return {
        "SSA": "SSA",
        "Veteran": "VAVA",
        "Medical Professional": "APSTMT",
      };
  }
}