import React, { Component } from "react";
// DHR Components
import Icon from "../../../utils/components/dtaIcon";
import DTATabs from "../../../utils/components/dtaTabs";
import ControlWrapper from "../../../utils/components/controlWrappers/controlWrapper";
import DTAFileInput from "../../../utils/components/dtaFileInput";
import HelpTip from "../../../utils/components/helpTip";
import Filter from "../../../utils/components/searchFilters/filter";
import ListItem from "../../../utils/components/list/listItem";
import ListWrapper from "../../../utils/components/list/listWrapper";
import SearchFilters from "../../../utils/components/searchFilters/searchFilters";
import * as formatters from "../../../utils/components/utilities/formatters";
import * as roleProps from "../../../utils/constants/rolesConstants";
import { consumerConst, connecttodtaLogoutPopupLabels } from "../consumerText";
import { formatDateToCustomFormat } from '../../../utils/helper/DateConversion';
import moment from "moment";
// MessageCenter Components
import TabBar from "../../../utils/components/TabBar";
import * as consumerLabels from "../consumerText";
import SelectDropdown from "../../../utils/components/SelectDropdown";
import DTAModal from "../../../utils/components/modal/dtaModal";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DynamicTable from "../secureMessage/DynamicTable";
import searchLogo from "../../../utils/resources/images/search-blue.png";
import { showBellIcon } from "../../../utils/helper/accountNavigationActions";
import DTATextArea from "../../../utils/components/dtaTextArea";
import _ from "lodash";

// Constants
import * as Props from "../../../utils/components/shared";
import * as SharedProps from "../../../utils/constants/shared";
// Static data
import * as FiltersData from "../../../utils/constants/searchFilters";
import {
  uploadDocumentAboutQuestion,
  uploadDocumentVerificationTypeQuestion,
  uploadDocumentProgramQuestion,defaultUploadDocumentTypeQuestion,applicationUploadDocumentTypeQuestion
} from "../../../utils/constants/consumerQuestions";
// User-specific data (fake)
// Image assets
import uploadDocumentIllustrationImg from "../../../utils/resources/images/upload-document-illustration.png";
import uploadDocumentIllustrationMobileImg from "../../../utils/resources/images/upload-document-illustration-mobile.png";
import uploadDocumentFramingImg from "../../../utils/resources/images/upload-document-framing.png";
import uploadDocumentLightingImg from "../../../utils/resources/images/upload-document-lighting.png";
import { uploadBase64 } from "../../../redux/consumer/uploadFileAction";
import { mapStateToProps } from "../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getConsumerDocumentList,
  getDTANotificationList,
  getClientDocumentsList,
  getSecureMessageData,
  getCaseManagerDetail,
  noticesUpdatePostCall,
  getDocumentsDetails,
  getMessageStatus,
  getErrorMessageStatus
} from "../../../redux/consumer/consumerDocumentsActions";
import Loading from "../../../utils/components/loading";
import { getDocumentTypeDescription } from "../../../utils/components/utilities/utils";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as OptionsProps from "../../../utils/constants/options";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import { hideUrgentApmntAlert } from "../../../redux/consumer/consumerActivityActions";
import * as TextProps from "../../../utils/constants/text";
import { USER } from "../../../utils/constants/rolesConstants";
import DOMPurify from "dompurify";

const selectedLanguages =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
var consumerDataUserObj = JSON.parse(
  sessionStorage.getItem("loadConsumerUser")
);
var consumerNOMATUserObj = JSON.parse(
  sessionStorage.getItem("consumerAddrData")
);
var providerUser = JSON.parse(sessionStorage.getItem("providerData"));

let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ConsumerDocuments extends Component {
  constructor(props) {
    super(...arguments);
    // const chosenLanguage = localStorage.getItem("selectedLanguage")
    this.fileIncrementer = 0;
    this.fileSize = 0;
    this.state = {
      notificationList: [],
      documentsList: [],
      messageList: [],
      uploadDocumentModalOpen: TextProps.VALUE_FALSE,
      noticeDocumentModalOpen: TextProps.VALUE_FALSE,
      activeUploadModalStep: 1,
      totalUploadModalSteps: 4,
      filesToUpload: [],
      error: TextProps.VALUE_FALSE,
      errorMessage: null,
      docuemntTypeFilterd: TextProps.VALUE_FALSE,
      loading: TextProps.VALUE_TRUE,
      selectDocTypeError: TextProps.VALUE_FALSE,
      selectDocVerifyTypeError: TextProps.VALUE_FALSE,
      isSelected: TextProps.VALUE_FALSE,
      isSelectedVerification: TextProps.VALUE_FALSE,
      filterText: consumerLabels.consumerConst(selectedLanguages).inbox,
      searchText: "",
      isModalOpen: false,
      editorState: EditorState.createEmpty(),
      setContent: "",
      isLoading: false,
      application: "",
      editorLength:0,
      to: "",
      cc: "",
      subject: "",
      sendToCountyList: false,
      importantMessage: false,
      isApplicationSelected: false,
      isToEmailSelected: false,
      isToEmailSelectedValid:false,
      isSubjectSelected: false,
      isEditorSelected:false,
      isCcEmailSelected:false,
      closeReplyModal: false,
      toInputDisabed: false,
      caseManagerEmails: "",
      verificationType: '',
      programType: '',
      selectDocProgramError: false,
      selectErrorSNAP: false,
      selectErrorTANF: false,
      documentComments:"",
      showWarningModal: TextProps.VALUE_FALSE,
      fileTypeValidation: TextProps.VALUE_TRUE,
      isFiltered: false,
      isRangeFiltered: [],
      isTypeFiltered: "",
      dateFilterNoticesError: false,
      filterNoticesData:[],
      isNoticesFiltered:false,
      filterData:[],
      dateFilterError:false,
      allowedTypes: ["jpg", "jpeg", "tif", "tiff", "png", "gif", "bmp", "wav", "pdf", "doc", "docx"]
    };
    this.applicationRef = React.createRef();
    this.editRef = React.createRef();
    this.renderDocumentsTabPanel = this.renderDocumentsTabPanel.bind(this);
    this.nextUploadModalStep = this.nextUploadModalStep.bind(this);
    this.closeUploadDocumentModal = this.closeUploadDocumentModal.bind(this);
    this.noticeUploadDocumentModal = this.noticeUploadDocumentModal.bind(this);
    this.previousUploadModalStep = this.previousUploadModalStep.bind(this);
    this.onUploadDocumentQuestionChange =
      this.onUploadDocumentQuestionChange.bind(this);
    sessionStorage.setItem("documentTypeId", JSON.stringify(""));
    sessionStorage.setItem("docTypeValue", JSON.stringify(""));
    sessionStorage.setItem("verificationTypeValue", JSON.stringify(""));
    sessionStorage.setItem("docProgramType", JSON.stringify(""));
    sessionStorage.setItem("documentClientName", JSON.stringify(""));

    this.onuploadDocumentVerificationTypeQuestion =
      this.onuploadDocumentVerificationTypeQuestion.bind(this);
    this.onUploadDocumentProgramChange = this.onUploadDocumentProgramChange.bind(this);
  }

  handleSearchChange = (e) => {
    const searchText = e.target.value;
  this.setState({ searchText }, this.filterMessageList);
    
  };
  filterMessageList = () => {
    const { messageList } = this.props.consumer;
    const searchText = this.state.searchText;
    if (searchText.trim() !== "") {
      const filteredList = messageList.filter(
        (res) =>
        res.clientNam.toLowerCase().includes(searchText.toLowerCase()) ||
        res.subject.toLowerCase().includes(searchText.toLowerCase()) ||
          res.dsc.toLowerCase().includes(searchText.toLowerCase())||
          formatDateToCustomFormat(res.createTs).includes(searchText)
      );
      this.setState({ messageList: filteredList });
    } else {
      this.setState({ messageList: this.props.consumer.messageList });
    }
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
      to: "",
      cc: "",
      application: "",
      subject: "",
      setContent:"",
      toInputDisabed: false,
      importantMessage: false,
      sendToCountyList: false,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false, isApplicationSelected: false,
      editorState: EditorState.createEmpty(),
      isCcEmailSelected:false,
      isToEmailSelected: false,
      isToEmailSelectedValid:false,
      isSubjectSelected: false,
      isEditorSelected: false,
    });
  };
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    this.setState({
      setContent: `${draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      )}`,
    });
    const check = this.state.setContent;
const text = check.replace(/<[^>]*>/g, '');
this.setState({editorLength:text.length});
    if(text){
      this.setState({ isEditorSelected: false });
    }
  };

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    this.setState({ [name]: newValue });
    const userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    if (name === "application") {
      this.setState({ toInputDisabed: true, to: "" });
      this.props.onCaseManagerEmail(userDetails.email, "consumer");
    }
     if(name === "application" && userDetails.application !== ""){
      this.setState({ isApplicationSelected: false });
    }
    if(this.state.application == "TANF"){
      this.setState({sendToCountyList:false})
    }
    if(name === "subject" && userDetails.subject !== ""){
      this.setState({ isSubjectSelected: false });
    }
  };

  handleSelectChange = (selectedValue) => {
    this.setState({ application: selectedValue });
  };

  handleToInputChange = (event) => {
    this.setState({ to: event.target.value });
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(event.target.value){
      this.setState({ isToEmailSelected: false });
    }
    if(emailRegEx.test(event.target.value)){
      this.setState({ isToEmailSelectedValid: false });
    }
  };
  handleCcInputChange = (event) => {
    this.setState({ cc: event.target.value });
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(emailRegEx.test(event.target.value)){
      this.setState({ isCcEmailSelected: false });
    }
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
      to:  this.state.to,
      isToEmailSelected: false,
    });
  };
  onSubmitMessage = (e) => {
    e.preventDefault();
    this.setState({
      isApplicationSelected: false,
      isToEmailSelectedValid:false,
      isEditorSelected:false,
      isCcEmailSelected:false,
      isSubjectSelected: false,
      isToEmailSelected: false,
    });

    const {
      application,
      to,
      cc,
      sendToCountyList,
      importantMessage,
      subject,
      setContent,
    } = this.state;
    const userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    const { agencyID, email } = userDetails;
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (application === "") {
      this.setState({ isApplicationSelected: true });
      // const errorInput = this.getErrorInputRef();
      // if (errorInput) {
      //   errorInput.scrollIntoView({ behavior: "smooth", block: "start" });
      // }
    }
    else if ((!sendToCountyList && to === "")) {
      this.setState({ isToEmailSelected: true });
    } 
    else if((sendToCountyList && to === "")){
      this.setState({ isToEmailSelected: true });
    } 
    else if (!emailRegEx.test(to)) {
      this.setState({ isToEmailSelectedValid: true });
    } 
    else if (this.state.cc !== "" && !emailRegEx.test(cc)) {
      this.setState({ isCcEmailSelected: true });
    }
    else if (this.state.subject === "" ) {
      this.setState({ isSubjectSelected: true });
    }
    else if (this.state.editorLength <= 1 || this.state.setContent === "" ) {
      this.setState({ isEditorSelected: true });
    } else {
      const msgPayload = {
        clientId: agencyID,
        sourceNam: "Client",
        msgFrom: email,
        msgTo: to,
        msgCc: cc,
        msgDlCc: cc,
        recvrType: "Worker",
        subject: subject,
        dsc: setContent,
        lastUpdtUidNam: "SECUREMSG",
        programType: application,
        msgImp: importantMessage ? "Y" : "N",
        isMsgDl: sendToCountyList && application == "SNAP" ? "Y" : "N",
      };
      this.props.onSecureNewMessage(msgPayload, "compose");
    }


  };

  getErrorInputRef() {
    if (this.state.isApplicationSelected && this.applicationRef.current) {
      return this.applicationRef.current;
    } else if (this.state.isSubjectSelected && this.subjectRef.current) {
      return this.subjectRef.current;
    } else if (this.state.isToEmailSelected && this.toRef.current) {
      return this.toRef.current;
    }
  }

  handleFilterChange = (selectedFilter) => {
    this.setState({ filterText: selectedFilter }, () => {
      let listPayload = {};
      switch (selectedFilter) {
        case consumerLabels.consumerConst(selectedLanguages).inbox:
          listPayload = { type: "INBOX", isAllMsg: "Y" };
          break;
        case consumerLabels.consumerConst(selectedLanguages).sendMsg:
          listPayload = { type: "SENT" };
          break;
        case consumerLabels.consumerConst(selectedLanguages).archives:
          listPayload = { type: "ARCHIVE" };
          break;
        default:
          break;
      }
      this.setState({ isLoading: true,messageList:[] });
      this.props.onSecureMessageList(listPayload, "list");
    });
  };

  getConsumerDocumentData = () => {
    let consumerDataUserObject = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (consumerDataUserObject !== null) {
      const agencyID = consumerDataUserObject.clientInfo.agencyID;
      if (agencyID !== "") {
        this.props.onDTANotificationList(agencyID);
      this.props.onConsumerDocumentList(agencyID);      
      }
      this.props.hideUrgentApmntAlert();
      this.props.onSecureMessageList({ type: "INBOX", isAllMsg: "Y" }, "list");
    }else if(selectedConsumerEmail || (userInfo.roleList && userInfo.roleList.length && userInfo.roleList[0].roleName === roleProps.USER)){
      this.props.onConsumerDocumentList();
    }
  }

  componentWillMount() {
    // Get the family members whom a document could pertain to and add them to the modal question.
    //QUERY FOR COMSUMER DOCUMENT LIST AND NOTIFICATION LIST ON PAGE LOAD / REFRESH.
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    let userAuthToken = "";
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }

    setTimeout(() => this.setState({ loading: TextProps.VALUE_FALSE }), 1500);
    this.getConsumerDocumentData();
  }
  componentDidUpdate(prevProps) {

    if (this.props.consumer.messageList !== prevProps.consumer.messageList) {
      this.setState({
        messageList: this.props.consumer.messageList,
        isLoading: false,
      });
    }
    if (this.props.consumer.newMessage !== prevProps.consumer.newMessage) {
      if (this.props.consumer.newMessage.code === "200") {
        this.closeModal();
      }
    }

    if (this.props.consumer.caseManager !== prevProps.consumer.caseManager) {
      this.setState({ toInputDisabed: false, to: "" });
      const { benefitInfo } = this.props.consumer.caseManager;
      const caseManagerEmails = benefitInfo.length > 0 ? (benefitInfo[0].caseManagerEmail ? benefitInfo[0].caseManagerEmail.emailAddress : "") : "";
      this.setState({ caseManagerEmails, to: caseManagerEmails});
    }

    if (
      this.props.consumer.replyMessage !== prevProps.consumer.replyMessage &&
      !this.state.isLoading
    ) {
      this.setState({
        isLoading: true,
        closeReplyModal: false,
      });
      this.handleFilterChange(this.state.filterText);
    }

  }
  componentDidMount(){
   if ((this.props.consumer.documentDetailList !== "")){
      this.props.getDocumentsDetails();
    } 
  }
  
  handleSecureMessageUpdate = (msgPayload, action) => {
    this.setState({ isLoading: false });
    this.props.onSecureMessageReply(msgPayload, action);
  };
  setActiveChange = (index, tableState) => {
    this.setState({ messageList: [], isLoading: true });
    if(tableState === 'Sent Messages'){
      this.props.onSecureMessageList({ type: "SENT" }, "list");
    }else if(tableState === 'Archive'){
      this.props.onSecureMessageList({ type: "ARCHIVE" }, "list");
    }else{
      if (index === 1) {
        this.props.onSecureMessageList({ type: "INBOX", isAllMsg: "N" }, "list");
      } else {
        this.props.onSecureMessageList({ type: "INBOX", isAllMsg: "Y" }, "list");
      }
    }
  };

  componentWillUpdate(currentProps, currentState) {
    //SET THE DOCUMENT RESPONSE LIST TO THE CURRENT STATE
    if (currentProps.consumer.notificationList !== undefined) {
      var arrOfObj = currentProps.consumer.notificationList;
      var notificationListData = arrOfObj.map(function (el) {
        var o = Object.assign({}, el);
        o.type = "notification";
        return o;
      });
      currentState.notificationList = notificationListData;
    }

    if (currentProps.consumer.documentsList !== undefined) {
      let arrOfObject = currentProps.consumer.documentsList;
      let documentsListData = arrOfObject.map(function (el) {
        var o = Object.assign({}, el);
        o.type = "document";
        if("subTypeCode" in o && o.typeCode !== "SAPPL"){
          o.typeCode = o.typeCode+"$"+o.subTypeCode;        
        }
        return o;
      });
      currentState.documentsList = documentsListData;
      if(FiltersData.FILTER_DOCUMENT_TYPE.options.length === 0){
        this.setDocumentTypeFilter(documentsListData)
      }
    }
    if (
      currentProps.consumer.documentUploaded !== undefined &&
      currentProps.consumer.documentUploaded === TextProps.VALUE_TRUE &&
      currentProps.consumer.consumerUploadDocument !== undefined
    ) {
      this.uploadDocumentModal.showBlur(
        "success",
        connecttodtaLogoutPopupLabels(selectedLanguage).uploadSuccess 
      );
      currentProps.consumer.documentUploaded = TextProps.VALUE_FALSE;
    } else if (
      currentProps.consumer.documentUploaded !== undefined &&
      currentProps.consumer.documentUploaded === "error" &&
      currentProps.consumer.consumerUploadDocument === undefined
    ) {
      this.uploadDocumentModal.hideBlur();
      this.setState({
        errorMessage: consumerConst(selectedLanguage).somethingwentWrong,
        error: TextProps.VALUE_TRUE,
      });
      currentProps.consumer.documentUploaded = TextProps.VALUE_FALSE;
    } else if (currentProps.consumer.documentFilteredList !== undefined) {
      var documentFilteredList = currentProps.consumer.documentFilteredList;
      var docList = currentState.documentsList;
      const filteredDocsList = docList.filter(
        (obj) => obj.typeCode === documentFilteredList
      );
      if (filteredDocsList.length > 0) {
        currentState.documentsList = filteredDocsList;
      }
    }
  }

  onUploadDocumentQuestionChange = (value) => {
    this.setState({ isSelectedVerification: TextProps.VALUE_TRUE })
    var rex = /^[A-Za-z$']+$/;
    if (rex.test(value) === TextProps.VALUE_TRUE && value !== "no") {
      sessionStorage.setItem("docTypeValue", JSON.stringify(value));
      if (
        this.props.consumer.consumerData !== undefined &&
        this.props.consumer.consumerData.user !== undefined
      ) {
        this.props.consumer.consumerData.documentType = value;
      } else if (
        consumerDataUserObj !== null &&
        consumerDataUserObj.accountId > 0
      ) {
        consumerDataUserObj.documentType = value;
      } else if (
        consumerNOMATUserObj !== null &&
        consumerNOMATUserObj.accountId > 0
      ) {
        consumerNOMATUserObj.documentType = value;
      } else if (providerUser !== null && providerUser.accountId > 0) {
        providerUser.documentType = value;
      }
    } else {
      sessionStorage.setItem("documentTypeId", JSON.stringify(value));
      if (
        this.props.consumer.consumerData !== undefined &&
        this.props.consumer.consumerData.user !== undefined
      ) {
        this.props.consumer.consumerData.documentTypeId = value;
      } else if (
        consumerDataUserObj !== null &&
        consumerDataUserObj.accountId > 0
      ) {
        consumerDataUserObj.documentTypeId = value;
      } else if (
        consumerNOMATUserObj !== null &&
        consumerNOMATUserObj.accountId > 0
      ) {
        consumerNOMATUserObj.documentTypeId = value;
      } else if (providerUser !== null && providerUser.accountId > 0) {
        providerUser.documentTypeId = value;
      }
    }
  };

  onUploadDocumentProgramChange = (value) => {
    this.setState({ isSelectedVerification: TextProps.VALUE_TRUE })
    var rex = /^[A-Za-z$']+$/;
    if (rex.test(value) === TextProps.VALUE_TRUE && value !== "no") {
      sessionStorage.setItem("docProgramType", JSON.stringify(value));
      if (
        this.props.consumer.consumerData !== undefined &&
        this.props.consumer.consumerData.user !== undefined
      ) {
        this.props.consumer.consumerData.docProgramType = value;
      } else if (
        consumerDataUserObj !== null &&
        consumerDataUserObj.accountId > 0
      ) {
        consumerDataUserObj.docProgramType = value;
      } else if (
        consumerNOMATUserObj !== null &&
        consumerNOMATUserObj.accountId > 0
      ) {
        consumerNOMATUserObj.docProgramType = value;
      } else if (providerUser !== null && providerUser.accountId > 0) {
        providerUser.docProgramType = value;
      }
    }
  };

  onuploadDocumentVerificationTypeQuestion = (value) => {
    this.setState({ isSelected: TextProps.VALUE_FALSE });
    var rex = /^[A-Za-z$']+$/;
    if (rex.test(value) === TextProps.VALUE_TRUE && value !== "no") {
      this.setState({ verificationType: value })
      sessionStorage.setItem("verificationTypeValue", JSON.stringify(value));
      if (
        this.props.consumer.consumerData !== undefined &&
        this.props.consumer.consumerData.user !== undefined
      ) {
        this.props.consumer.consumerData.verificationType = value;
      } else if (
        consumerDataUserObj !== null &&
        consumerDataUserObj.accountId > 0
      ) {
        consumerDataUserObj.verificationType = value;
      } else if (
        consumerNOMATUserObj !== null &&
        consumerNOMATUserObj.accountId > 0
      ) {
        consumerNOMATUserObj.verificationType = value;
      } else if (providerUser !== null && providerUser.accountId > 0) {
        providerUser.verificationType = value;
      }
    } else {
      sessionStorage.setItem("documentTypeId", JSON.stringify(value));
      if (
        this.props.consumer.consumerData !== undefined &&
        this.props.consumer.consumerData.user !== undefined
      ) {
        this.props.consumer.consumerData.documentTypeId = value;
      } else if (
        consumerDataUserObj !== null &&
        consumerDataUserObj.accountId > 0
      ) {
        consumerDataUserObj.documentTypeId = value;
      } else if (
        consumerNOMATUserObj !== null &&
        consumerNOMATUserObj.accountId > 0
      ) {
        consumerNOMATUserObj.documentTypeId = value;
      } else if (providerUser !== null && providerUser.accountId > 0) {
        providerUser.documentTypeId = value;
      }
    }
  }

  openUploadDocumentModal = () => {
    this.props.consumer.showDocumentLoading = TextProps.VALUE_FALSE;
    this.setState({
      uploadDocumentModalOpen: TextProps.VALUE_TRUE,
      errorMessage: null,
      error: TextProps.VALUE_FALSE,
    });
  };

  openNoticeDocumentModal = () => {
    this.setState({
      noticeDocumentModalOpen: TextProps.VALUE_TRUE,
      selectDocTypeError: TextProps.VALUE_FALSE,
    });
  };

  closeUploadDocumentModal = () => {
    // Cancel any actions then...
    let _this = this;
    if (_this.props.consumer.consumerData !== undefined) {
      _this.props.consumer.consumerData.documentType = "";
      _this.props.consumer.consumerData.documentTypeId = "";
      _this.props.consumer.consumerData.verificationType = "";
      _this.props.consumer.consumerData.docProgramType = "";
      sessionStorage.setItem("documentTypeId", JSON.stringify(""));
      sessionStorage.setItem("docTypeValue", JSON.stringify(""));
      sessionStorage.setItem("verificationTypeValue", JSON.stringify(""));
      sessionStorage.setItem("docProgramType", JSON.stringify(""));
      sessionStorage.setItem("documentClientName", JSON.stringify(""));
    }
    _this.state.filesToUpload = [];
    this.getConsumerDocumentData();
    this.setState({ uploadDocumentModalOpen: TextProps.VALUE_FALSE, verificationType: '',documentComments: '' }, () => {
      // After the modal is out of sight, return modal states to original.
      // Includes resetting modal step as below, but also clearing any input values, etc
      setTimeout(() => {
        this.setState({ activeUploadModalStep: 1 });
      }, 500);
    });
  };

  noticeUploadDocumentModal = () => {
    // Cancel any actions then...
    this.setState({ noticeDocumentModalOpen: TextProps.VALUE_FALSE }, () => {
      // After the modal is out of sight, return modal states to original.
      // Includes resetting modal step as below, but also clearing any input values, etc
      setTimeout(() => {
        this.setState({ activeUploadModalStep: 1 });
      }, 500);
    });
  };

  nextUploadModalStep = () => {
    const nextStep = this.state.activeUploadModalStep + 1;
    this.setState({ selectDocTypeError: TextProps.VALUE_FALSE });
    if (nextStep <= this.state.totalUploadModalSteps) {
      if (nextStep === 2 && !this.state.isSelected) {
        this.clientDetailsAPI();
        this.setState({ activeUploadModalStep: nextStep });
      } else if (nextStep === 3 && !this.state.isSelected) {
        if (
          (this.props.consumer.consumerData.documentType !== undefined &&
            this.props.consumer.consumerData.documentType !== "") ||
          (JSON.parse(sessionStorage.getItem("docTypeValue")) !== undefined &&
            JSON.parse(sessionStorage.getItem("docTypeValue")) !== "")
        ) {
          this.setState({ selectDocTypeError: TextProps.VALUE_FALSE });
          if (this.props.consumer.consumerData.documentType === "VERIFC" ||
            JSON.parse(sessionStorage.getItem("docTypeValue")) === "VERIFC") {
            if (
              this.state.verificationType !== ''
            ) {
              this.setState({ 
                selectDocVerifyTypeError: TextProps.VALUE_FALSE,
                activeUploadModalStep: nextStep  
              });
          return;
        } else {
              this.setState({ selectDocVerifyTypeError: TextProps.VALUE_TRUE });
              return;
            }
          }
        } else {
          this.setState({ selectDocTypeError: TextProps.VALUE_TRUE });
          return;
        }
      }
      if (this.props.consumer.documentDetailList.isLinkedClient === "Y") {
      if (nextStep === 4 && !this.state.isSelected) {
        if (
          (this.props.consumer.consumerData.documentTypeId !== undefined &&
            this.props.consumer.consumerData.documentTypeId !== "") ||
          (JSON.parse(sessionStorage.getItem("documentTypeId")) !== undefined &&
            JSON.parse(sessionStorage.getItem("documentTypeId")) !== "")
        ) {
          this.setState({ 
            selectDocTypeError: TextProps.VALUE_FALSE,
            activeUploadModalStep: nextStep 
          });
          return;
        } else {
          this.setState({ 
            selectDocTypeError: TextProps.VALUE_TRUE,
            isSelected: TextProps.VALUE_FALSE 
          });
          return;
        }
      }
      }
      if (this.props.consumer.documentDetailList.isLinkedClient === "N") {
        if (nextStep === 4 && !this.state.isSelected) {
          if (
            (this.props.consumer.consumerData.docProgramType !== undefined &&
              this.props.consumer.consumerData.docProgramType !== "") ||
            (JSON.parse(sessionStorage.getItem("docProgramType")) !== undefined &&
              JSON.parse(sessionStorage.getItem("docProgramType")) !== "")
          ) {
            this.setState({ selectDocProgramError: TextProps.VALUE_FALSE });
          } else {
            this.setState({
              selectDocProgramError: TextProps.VALUE_TRUE,
              isSelected: TextProps.VALUE_FALSE
            });
            return;
          }
          if ((JSON.parse(sessionStorage.getItem("docProgramType")) === "TANF" ||
            this.props.consumer.consumerData.docProgramType === "TANF") &&
            this.props.consumer.documentDetailList.tanfFlag === "N"
          ) {
            this.setState({
              selectErrorTANF: true,
              selectDocProgramError: TextProps.VALUE_FALSE,
              selectErrorSNAP: false
            })
            return;
          }
          if ((JSON.parse(sessionStorage.getItem("docProgramType")) === "TANF" ||
            this.props.consumer.consumerData.docProgramType === "TANF") &&
            this.props.consumer.documentDetailList.tanfFlag === "Y"
          ) {
            this.setState({
              selectErrorTANF: false,
              selectDocProgramError: TextProps.VALUE_FALSE,
              selectErrorSNAP: false,
              activeUploadModalStep: nextStep
            })
            return;
          }
          if ((JSON.parse(sessionStorage.getItem("docProgramType")) === "SNAP" ||
            this.props.consumer.consumerData.docProgramType === "SNAP") &&
            this.props.consumer.documentDetailList.snapFlag === "Y") {
            this.setState({
              selectErrorSNAP: false,
              selectDocProgramError: TextProps.VALUE_FALSE,
              selectErrorTANF: false,
              activeUploadModalStep: nextStep
            })
            return;
          }
          if ((JSON.parse(sessionStorage.getItem("docProgramType")) === "SNAP" ||
            this.props.consumer.consumerData.docProgramType === "SNAP") &&
            this.props.consumer.documentDetailList.snapFlag === "N") {
            this.setState({
              selectErrorSNAP: true,
              selectDocProgramError: TextProps.VALUE_FALSE,
              selectErrorTANF: false
            })
            return;
          }
        }
      }
      this.setState({ activeUploadModalStep: nextStep });
      this.setState({ isSelected: TextProps.VALUE_FALSE });
    }
  };
  previousUploadModalStep = () => {
    this.setState({ selectDocTypeError: TextProps.VALUE_FALSE });
    // this.setState({ isSelected: TextProps.VALUE_TRUE });
    const previousStep = this.state.activeUploadModalStep - 1;
    if (previousStep >= 1) {
      this.setState({ activeUploadModalStep: previousStep });
    }
  };

  clientDetailsAPI = () => {
    if (this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.isLinkedClient === "Y") {
    let consumerDataUserObject = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let agencyID = consumerDataUserObject.clientInfo.agencyID;
    if (agencyID !== "") {
      this.props.getClientDocumentsList(agencyID);
    }
    }
  };

  handleFileInputChange = (event) => {
    const inputEl = event.target,
      files = Array.from(inputEl.files);

    files.forEach((file) => {
      //Converting Base64 and Appending to Array
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        file.fileType = file.type;
        file.inboundfilename = file.name;
        file.filesize = file.size;
        file.base64 = this.result;
      };

      file[SharedProps.ID] = `upload-file-${this.fileIncrementer}`;
      this.fileIncrementer += 1;
    });

    this.setState(
      {
        filesToUpload: this.state.filesToUpload.concat(files),
      },
      () => {
        inputEl.value = null;
      }
    );
  };

  handleRemoveFile = (fileToRemove) => () => {
    const newFileList = this.state.filesToUpload.filter(
      (file) => file[SharedProps.ID] !== fileToRemove[SharedProps.ID]
    );
    this.setState({ filesToUpload: newFileList });
  };

  uploadDocuments = (fileInfo) => {
    var docTypeValue = JSON.parse(sessionStorage.getItem("docTypeValue"));
    var documentTypeId = JSON.parse(sessionStorage.getItem("documentTypeId"));
    let verificationType = JSON.parse(sessionStorage.getItem("verificationTypeValue"));
    let documentSubTypeCode = null;
    if(docTypeValue === "VERIFC"){
      docTypeValue = verificationType;
    }
    if(docTypeValue.includes("$")){
      documentSubTypeCode = docTypeValue.split("$")[1];
      docTypeValue = docTypeValue.split("$")[0];
    }
    var clientDocumentsList = JSON.parse(
      sessionStorage.getItem("ClientDocumentsList")
    );
    const memberList = clientDocumentsList && clientDocumentsList.householdMembers && clientDocumentsList.householdMembers.length>0 && clientDocumentsList.householdMembers.filter(member=>{
      return member.agencyID == documentTypeId;
    });

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var attachmentsList = [];
    var fileTypeError = TextProps.VALUE_TRUE;
    fileInfo.map((obj) => {
      var filename = obj.name.split(".");
      var fileType = filename[filename.length - 1];
      if(this.state.allowedTypes.includes(fileType.toLowerCase())){
        attachmentsList.push({
          fileName: obj.name,
          extension: fileType,
          size: obj.filesize,
          stream: obj.base64,
        });
      }else{
        fileTypeError = TextProps.VALUE_FALSE;
        this.setState({
          fileTypeValidation: TextProps.VALUE_FALSE
        },() => {
          this.showWarningModal();
        })
      }
      return attachmentsList;
    });
    if(fileTypeError){
      if (this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.isLinkedClient === "Y") {
        var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
        let snapCase = [],
        tanfCase = [],
        snapCaseId = [],
        tanfCaseId = [];
        if(userDetails && userDetails.benefitInfo && userDetails.benefitInfo.length){
          snapCase = userDetails.benefitInfo.filter(item => item.typeCode === "SNAP" && item.statusCode === "ACTIVE");
          tanfCase = userDetails.benefitInfo.filter(item => item.typeCode === "TANF" && item.statusCode === "ACTIVE");
          snapCaseId = snapCase.length ? snapCase : userDetails.benefitInfo.filter(item => item.typeCode === "SNAP")
          tanfCaseId = tanfCase.length ? tanfCase : userDetails.benefitInfo.filter(item => item.typeCode === "TANF")
        }
        
        let clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        let countryCode = clientDetails.clientAddress[0].countyCode;
      const payload = {
        attachments: attachmentsList,
        documentInfo: {
          agencyID: documentTypeId,
            // city: "",
          dateOfBirth: memberList && memberList[0].dateOfBirth + "T00:00:00",
          documentTypeCode: docTypeValue,
          documentSubTypeCode: documentSubTypeCode,
          firstName: memberList && memberList[0].firstName,
          lastName:  memberList && memberList[0].lastName,
            // source: "DTAWEB",
            // webAppID: "",
            countyCode: countryCode, 
           comments: this.state.documentComments,
           snapCaseId: snapCaseId.length ? snapCaseId[0].id : "",
           tanfCaseId: tanfCaseId.length ? tanfCaseId[0].id : "", 
          },
          uploadBy: {
            emailID: userInfo.email,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          },
        };
        let validationflag = this.validatesizeoffiles(fileInfo);
        if (validationflag) {
          const { info } = this.props.language.consumer;
          this.uploadDocumentModal.showBlur("loading", info.sending, true);
          // Can now read files from this.state.filesToUpload and POST them to server
          // in whatever manner desired
          this.props.uploadDocument(payload);
        }
      }
      if (this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.isLinkedClient === "N") {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        let applicationType = JSON.parse(sessionStorage.getItem("docProgramType"));
        let tanfId = this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.tanfId
        let snapId = this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.snapId
        const payload = {
          attachments: attachmentsList,
          documentInfo: {
            agencyID: 0,
            // city: "",
            dateOfBirth: userInfo.dateOfBirth + "T00:00:00",
            documentTypeCode: docTypeValue,
            documentSubTypeCode: documentSubTypeCode,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            // source: "DTAWEB",
            webAppID: applicationType === 'TANF' ? tanfId : snapId,
            countyCode: this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.countyCode ? this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.countyCode : null,
            applicationType: applicationType,
            comments: this.state.documentComments
        },
        uploadBy: {
          emailID: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        },
      };
  
      let validationflag = this.validatesizeoffiles(fileInfo);
  
      if (validationflag) {
        const { info } = this.props.language.consumer;
        this.uploadDocumentModal.showBlur("loading", info.sending, true);
        // Can now read files from this.state.filesToUpload and POST them to server
        // in whatever manner desired
  
        this.props.uploadDocument(payload);
      }
      }
    }
  };

  validatesizeoffiles = (file) => {
    const { consumer } = this.props.language;
    for (let value of file) {
      this.fileSize = +value.filesize;
      // total size of all files uploaded
      var totalSize = this.fileSize / 1024 / 1000;
      if (totalSize > 10.1) {
        this.showWarningModal();
        return TextProps.VALUE_FALSE;
      }
    }
    return TextProps.VALUE_TRUE;
  };

  renderDocumentUploadPreview = (file) => {
    return (
      <li key={file[SharedProps.ID]}>
        <span>{file.name}</span>
        <button
          className="dta-button dta-button--secondary dta-button--circle"
          onClick={this.handleRemoveFile(file)}
        >
          <Icon className = 'dta-button--cancel-icon' style={{marginRight:'0px !important'}} name="close" label={`Remove the file: ${file.name}`} />
        </button>
      </li>
    );
  };

  renderDocumentUploadHelp = () => {
    const { consumer } = this.props.language;
    return (
      <div>
        {consumer.documentupload.stepfourattachdocumentnote}
        <ul className="list--unstyled upload-document-help-tip">
          <li>
            <div className="upload-document-help-tip__img-wrapper">
              <img src={uploadDocumentFramingImg} aria-hidden={TextProps.VALUE_STR_TRUE} alt="" />
            </div>
            <span>{consumer.documentupload.stepfourPosition} <strong>{consumer.documentupload.filltheEntire}</strong>.</span>
          </li>
          <li>
            <div className="upload-document-help-tip__img-wrapper">
              <img src={uploadDocumentLightingImg} aria-hidden={TextProps.VALUE_STR_TRUE} alt="" />
            </div>
            <span>{consumer.documentupload.stepFourlighting} <strong> {consumer.documentupload.goodLighting} </strong> {consumer.documentupload.oruseFlash}</span>
          </li>
        </ul>
      </div>
    )
  }

  handleDocumentComments = (value) =>{
    this.setState({
     documentComments : value
    })
 }
 modalContent = () => {
  const { consumer } = this.props.language;
  return (
    <div style={{ padding: '1rem 1rem 1rem 1rem' }}>
      {!this.state.fileTypeValidation ? 
      <span >
      {consumer.documentupload.documentError}
      <br />
      {this.state.allowedTypes.join(", ")}
    </span> :
      <span >
        {consumer.documentupload.uploadError}
        <br />
        {consumer.documentupload.uploadErrorTwo}
      </span>}
      
      <div style={{ display: "flex", float: "right", padding: "2rem 1rem 1rem 1rem" }}>
        <button className="dta-button dta-button--outline-primary" style={{ marginRight: "20px", backgroundColor: 'white', color: '#002a57' }} onClick={this.closeWarningModal}>{consumer.documentupload.uploadOk}</button>
      </div>
    </div>
  )
}

renderWarningPopup = () => {
  return modalHelperFunction.dtaHelperModal(
    (instance) => (this.warningModal = instance),
    this.state.showWarningModal,
    this.closeWarningModal,
    "",
    "document-upload",
    false, false, false,
    <div></div>
    , this.modalContent(),
    true
  );
};

showWarningModal = () => {
  this.setState({ showWarningModal: TextProps.VALUE_TRUE });
};

closeWarningModal = () => {
  this.setState({
    showWarningModal: TextProps.VALUE_FALSE,
    fileTypeValidation: TextProps.VALUE_TRUE
  })
};
  renderUploadModalBody = () => {
    const { documentupload } = this.props.language.consumer;
    let docTypeValue = JSON.parse(sessionStorage.getItem("docTypeValue"));
    let documentTypeId = JSON.parse(sessionStorage.getItem("documentTypeId"));
    let verificationType = JSON.parse(sessionStorage.getItem("verificationTypeValue"));
    let docProgramType = JSON.parse(sessionStorage.getItem("docProgramType"));
    switch (this.state.activeUploadModalStep) {
      case 1:
        return (
          <main>
            <div className="dta-modal__body pad-all">
              {/* <p>{documentupload.stepOnedescription}</p> */}
              <p className="text--gray">
                {documentupload.steponeeDtaaddress1}
                <br />
                {documentupload.steponeeDtapobox}
                <br />
                {documentupload.steponeeDtapoboxaddress2}
              </p>
              <p className="text--gray">
                {documentupload.steponeeDtapoboxaddressfax}
              </p>
              <p >
              {documentupload.stepAddingDocumentText}
                {documentupload.steponeinternetconnection}
                <strong>{documentupload.steponeinternetstrong}</strong>{" "}
                {documentupload.usingtheDoucment}
                {documentupload.willuseyourdevice}
                {documentupload.acesText}
                <strong>{documentupload.youaccept}</strong>
              </p>
              <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                <button
                  className="dta-button dta-button--large dta-button--outline-primary"
                  onClick={this.closeUploadDocumentModal}
                >
                  {documentupload.cancelButton}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--primary"
                  onClick={this.nextUploadModalStep}
                >
                  {documentupload.continuetoStep2}
                </button>
              </div>
            </div>
          </main>
        );
      case 2:
        var documentTypeQuestion = _.cloneDeep(defaultUploadDocumentTypeQuestion);
        if(this.props.consumer.documentDetailList.isLinkedClient === "Y" && this.props.consumer.consumerData && this.props.consumer.consumerData.benefitInfo && this.props.consumer.consumerData.benefitInfo.length){
          var benefitInfo = this.props.consumer.consumerData.benefitInfo;
          var recert_types = benefitInfo.filter(item => (item.alertMessages.RECERT_TYPE && (item.alertMessages.TAFDC_FLAG === "Yes" || item.alertMessages.SNAP_FLAG === "Yes"))).map(item => item.alertMessages.RECERT_TYPE )
          documentTypeQuestion[OptionsProps.OPTIONS].unshift(applicationUploadDocumentTypeQuestion["LINKEDCLIENT"]);
          recert_types.map(value =>{
            documentTypeQuestion[OptionsProps.OPTIONS].unshift(applicationUploadDocumentTypeQuestion[value]);
          })
        }
        return (
          <main>
            <div className="dta-modal__body pad-all">
              <ControlWrapper
                type={documentTypeQuestion[Props.TYPE]}
                data={documentTypeQuestion}
                onChange={this.onUploadDocumentQuestionChange}
                value={
                  this.props.consumer.consumerData.documentType
                    ? this.props.consumer.consumerData.documentType
                    : docTypeValue
                }
              />
              {this.state.selectDocTypeError && (
                <div className="pure-u-1 dta-form__error-message">
                  {this.state.selectDocTypeError
                    ? documentupload.documentError
                    : ""}
                </div>
              )}
              {this.props.consumer.consumerData.documentType
                ? this.props.consumer.consumerData.documentType
                : docTypeValue === "VERIFC" && (
                  <div>
                    <ControlWrapper
                      type={uploadDocumentVerificationTypeQuestion[Props.TYPE]}
                      data={uploadDocumentVerificationTypeQuestion}
                      onChange={this.onuploadDocumentVerificationTypeQuestion}
                      value={
                        this.props.consumer.consumerData.verificationType
                          ? this.props.consumer.consumerData.verificationType
                          : verificationType
                      }
                    />
                    {this.state.selectDocVerifyTypeError && (
                      <div className="pure-u-1 dta-form__error-message">
                        {this.state.selectDocVerifyTypeError
                          ? documentupload.verificationDocumentError
                          : ""}
                      </div>
                    )}
                  </div>
                )}
              <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                <button
                  className="dta-button dta-button--large dta-button--outline-primary"
                  onClick={this.previousUploadModalStep}
                >
                  {documentupload.steponebackButtontext}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--primary"
                  onClick={this.nextUploadModalStep}
                >
                  {documentupload.steptwoContinuebutton}
                </button>
              </div>
            </div>
          </main>
        );
      case 3:
        if (this.props.consumer && this.props.consumer.documentDetailList && this.props.consumer.documentDetailList.isLinkedClient === "Y") {
        let memeberInfo = {};
        uploadDocumentAboutQuestion.options = [];
        var consumerAboutMeData = {};
        let consumerDocumentData = JSON.parse(
          sessionStorage.getItem("loadConsumerUser")
        );
        let consumerDocumentHouseholdData = JSON.parse(
          sessionStorage.getItem("ClientDocumentsList")
        );
        let consumerNOMATObj = JSON.parse(
          sessionStorage.getItem("consumerAddrData")
        );
        let providerUserdata = JSON.parse(
          sessionStorage.getItem("providerData")
        );
        let documentClientName = JSON.parse(
          sessionStorage.getItem("documentClientName")
        );
        
        if (
          consumerDocumentData !== null &&
          consumerDocumentData.clientInfo.agencyID > 0
        ) {
          consumerAboutMeData = consumerDocumentHouseholdData;
        } else if (consumerNOMATObj !== null) {
          consumerAboutMeData = consumerNOMATObj;
        } else if (providerUserdata !== null) {
          consumerAboutMeData.user = providerUserdata;
        }
        if (consumerAboutMeData !== null) {
          consumerAboutMeData.householdMembers.map((member) => {
            memeberInfo = { optionValue: "", optionDisplay: "" };
            memeberInfo.optionValue = member.agencyID;
            var myDate = new Date(member.dateOfBirth);
            memeberInfo.optionDisplay =
              member.firstName +
              " " +
              member.lastName +
              "  (" +
              formatters.date(myDate) +
              ")";
            uploadDocumentAboutQuestion.options.push(memeberInfo);
          });
        }
        if (
          this.props.consumer.benefitHouseHoldInfo.householdMembers !==
            undefined &&
          this.props.consumer.benefitHouseHoldInfo.householdMembers.length > 0
        ) {
          for (let [
            index,
            hhMember,
          ] of this.props.consumer.benefitHouseHoldInfo.householdMembers.entries()) {
            memeberInfo.optionValue = hhMember.id;
            memeberInfo.optionDisplay =
              hhMember.firstName + " " + hhMember.lastName;
            if (uploadDocumentAboutQuestion.options[0] !== undefined) {
              uploadDocumentAboutQuestion.options[index] = memeberInfo;
            } else {
              uploadDocumentAboutQuestion.options.push(memeberInfo);
            }
          }
        }
        return (
          <main>
            <div className="dta-modal__body pad-all">
              <ControlWrapper
                type={uploadDocumentAboutQuestion[Props.TYPE]}
                data={uploadDocumentAboutQuestion}
                onChange={this.onUploadDocumentQuestionChange}
                value={
                  this.props.consumer.consumerData.documentTypeId
                    ? this.props.consumer.consumerData.documentTypeId
                    : documentTypeId
                }
              />
              {this.state.selectDocTypeError && (
                <div className="pure-u-1 dta-form__error-message">
                  {this.state.selectDocTypeError
                    ? documentupload.householdmemberError
                    : ""}
                </div>
              )}
              <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                <button
                  className="dta-button dta-button--large dta-button--outline-primary"
                  onClick={this.previousUploadModalStep}
                >
                  {documentupload.steponebackButtontext}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--primary"
                  onClick={this.nextUploadModalStep}
                >
                  {documentupload.stepthreeContinuebutton}
                </button>
              </div>
            </div>
          </main>
        );
        }

        if (this.props.consumer && 
          this.props.consumer.documentDetailList && 
          this.props.consumer.documentDetailList.isLinkedClient === "N") {
          return (
            <main>
              <div className="dta-modal__body pad-all">
                <ControlWrapper
                  type={uploadDocumentProgramQuestion[Props.TYPE]}
                  data={uploadDocumentProgramQuestion}
                  onChange={this.onUploadDocumentProgramChange}
                  value={
                    this.props.consumer.consumerData.docProgramType
                      ? this.props.consumer.consumerData.docProgramType
                      : docProgramType
                  }
                />
                {this.state.selectDocProgramError && (
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state.selectDocProgramError
                      ? documentupload.selectOption
                      : ""}
                  </div>
                )}
                {this.state.selectErrorTANF && (
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state.selectErrorTANF
                      ? documentupload.programErrorTANFText
                      : ""}
                  </div>
                )}
                {this.state.selectErrorSNAP && (
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state.selectErrorSNAP
                      ? documentupload.programErrorSNAPText
                      : ""}
                  </div>
                )}
                <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                  <button
                    className="dta-button dta-button--large dta-button--outline-primary"
                    onClick={this.previousUploadModalStep}
                  >
                    {documentupload.steponebackButtontext}
                  </button>
                  <button
                    className="dta-button dta-button--large dta-button--primary"
                    onClick={this.nextUploadModalStep}
                  >
                    {documentupload.stepthreeContinuebutton}
                  </button>
                </div>
              </div>
            </main>
          );
        }
      case 4:
        return (
          <main>
            <div className="dta-modal__body pad-all">
              <p>
                <b>{documentupload.stepfourAttachdocument}</b>
              </p>
              <HelpTip
                textBefore={documentupload.stepfouAttachdocumentdesc}
                triggerText={documentupload.photoInstructions}
                textAfter={documentupload.forMore}
                helpText={() => this.renderDocumentUploadHelp()}
                bypassMobileStyle={TextProps.VALUE_TRUE}
              />
              <p>{documentupload.stepfourAllinonefile}</p>
              {this.state.filesToUpload.length ? (
                <ul className="list--document-preview">
                  {this.state.filesToUpload.map((file) =>
                    this.renderDocumentUploadPreview(file)
                  )}
                </ul>
              ) : null}

              {this.state.filesToUpload.length ? (
                <DTATextArea
                  className="dta-form__control"
                  placeholder={documentupload.documentUploadComments}
                  maxLength={4500}
                  type="alpha-text"
                  value={this.state.documentComments}
                  onChange={this.handleDocumentComments}
                />
              ):null}
              <DTAFileInput
                id="uploadDocumentFileInput"
                labelText={
                  this.state.filesToUpload.length
                    ? documentupload.addAnotherFile
                    : documentupload.stepfourchooseafilebutton
                }
                onChange={this.handleFileInputChange}
              />
              <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                <button
                  className="dta-button dta-button--large dta-button--outline-primary"
                  onClick={this.previousUploadModalStep}
                >
                  {documentupload.steponebackButtontext}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--primary"
                  disabled={!this.state.filesToUpload.length}
                  onClick={(e) =>
                    this.uploadDocuments(this.state.filesToUpload)
                  }
                >
                  {documentupload.stepfourContinuebutton}
                </button>
              </div>
            </div>
            {this.renderWarningPopup()}
          </main>
        );
      default:
        return <div>{documentupload.wentWrong}</div>;
    }
  };

  filterNoticesByDate = (valueFrom, ValueTo) => {
    let notificationList = this.state.notificationList
    let filteredData = [];
    if(!valueFrom || !ValueTo){
      filteredData = notificationList;
      this.setState({dateFilterNoticesError:true});
    }else{
      this.setState({dateFilterNoticesError:false});
      const dateFrom = moment(valueFrom).startOf('day').unix();
      const dateTo = moment(ValueTo).endOf('day').unix();
      notificationList.map(item => {
        if(item.sentDate){
          const creactedDtinSec = moment(item.sentDate).unix();
          if(dateFrom <= creactedDtinSec && creactedDtinSec < dateTo){
            filteredData.push(item);
          }
        }
      })
    }
    this.setState({
      filterNoticesData: filteredData,
      isNoticesFiltered: true
    },() => {
      this.renderNotificationsTabPanel()
    })
  }

  clearNoticesFilter = () => {
    this.setState({
      filterNoticesData: [],
      isNoticesFiltered: false,
    },() => {
      this.renderNotificationsTabPanel()
    })
  }

  clearFilter = () => {
    this.setDocumentTypeFilter(this.state.documentsList)
    this.setState({
      filterData: [],
      isFiltered: false,
      isRangeFiltered: [],
      isTypeFiltered: ""
    },() => {
      this.renderDocumentsTabPanel()
    })
  }

  onFilterChangeType = (value) => {
    let documentsList = this.state.documentsList
    let filteredData = [];
    if(!value){
      value = "All";
    }
    documentsList.map(item => {
      if(value === getDocumentTypeDescription(item.typeCode) || value === item.subTypeDesc || value === "All"){
        if( this.state.isRangeFiltered.length){
          const dateFrom = moment(this.state.isRangeFiltered[0]).startOf('day').unix();
          const dateTo = moment(this.state.isRangeFiltered[1]).endOf('day').unix();
          if(item.createdDt){
            const creactedDtinSec = moment(item.createdDt).unix();
            if(dateFrom < creactedDtinSec && creactedDtinSec < dateTo){
              filteredData.push(item);
            }
          }
        }else{
          filteredData.push(item);
        }
      }
    })
    this.setState({
      filterData: filteredData,
      isTypeFiltered: value,
      isFiltered: true
    },() => {
      this.renderDocumentsTabPanel()
    })
  }

  filterAppointmentByDate = (valueFrom, ValueTo) => {
    let documentsList = this.state.documentsList
    let filteredData = [];
    if(!valueFrom || !ValueTo){
      filteredData = documentsList;
      this.setState({dateFilterError:true});
    }else{
      this.setState({dateFilterError:false});
      const dateFrom = moment(valueFrom).startOf('day').unix();
      const dateTo = moment(ValueTo).endOf('day').unix();
      documentsList.map(item => {
        if(item.createdDt){
          const creactedDtinSec = moment(item.createdDt).unix();
          if(dateFrom < creactedDtinSec && creactedDtinSec < dateTo){
            if( this.state.isTypeFiltered.length){
              if(this.state.isTypeFiltered === getDocumentTypeDescription(item.typeCode) || this.state.isTypeFiltered === item.subTypeDesc || this.state.isTypeFiltered === "All"){
                filteredData.push(item);
              }
            }else{
              filteredData.push(item);
            }
          }
        }
      })
    }
    // this.setDocumentTypeFilter(filteredData)
    this.setState({
      filterData: filteredData,
      isRangeFiltered: [valueFrom, ValueTo],
      isFiltered: true
    },() => {
      this.renderDocumentsTabPanel()
    })
  }

  setDocumentTypeFilter = (documentsList) => {
        let documentTypes = [];
        let documentTypeDescription = "";
        for (let documentItem of documentsList) {
          if (
            documentItem.typeCode &&
            documentTypes.indexOf(documentItem.typeCode) === -1
          ) {
            documentTypeDescription = getDocumentTypeDescription(
              documentItem.typeCode
            );
            if (!documentTypeDescription){
              documentTypeDescription = documentItem.subTypeDesc;
            }
            documentTypes.push(documentTypeDescription);
          }
        }
        documentTypes.push("All");
        documentTypes = documentTypes.filter(function (
          item,
          index,
          inputArray
        ) {
          return inputArray.indexOf(item) === index;
        });
        FiltersData.FILTER_DOCUMENT_TYPE.options = documentTypes;
  }

  renderDocumentsTabPanel = () => {
      return (
        <div>
          <SearchFilters 
            clearFilter={this.clearFilter}
          >
            <Filter 
              data={FiltersData.FILTER_DATE_DOCUMENT}
              filterAppointmentByDate={this.filterAppointmentByDate}
              error={this.state.dateFilterError} 
            />
            <Filter
              className="pure-u-1-4"
              data={FiltersData.FILTER_DOCUMENT_TYPE}
              onFilterChangeType={this.onFilterChangeType}
            />
          </SearchFilters>

          <ListWrapper
            total={100}
            doLoadMore={this._doLoadMore.bind(
              this,
              "documentsList",
              this.state.isFiltered ? this.state.filterData : this.state.documentsList
            )}
            items={this.state.isFiltered ? this.state.filterData : this.state.documentsList}
          />
        </div>
      );
  };

  renderSizeAlertPanel = () => {
    return (
      <div id="myModal" class="modal">
        <div class="modal-content">
          <span class="close">&times;</span>
          <p>File upload size is more than 5 MB </p>
        </div>
      </div>
    );
  };

  renderNotificationsTabPanel = () => {
    if (this.state.notificationList !== undefined) {
      return (
        <div>
          <SearchFilters 
            clearFilter={this.clearNoticesFilter}
          >
            <Filter
              className="pure-u-3-5 dta-filter--no-grow"
              data={FiltersData.FILTER_DATE_NOTIFICATION}
              filterAppointmentByDate={this.filterNoticesByDate}
              error={this.state.dateFilterNoticesError} 
            />
          </SearchFilters>
          <ListWrapper
            total={100}
            doLoadMore={this._doLoadMore.bind(
              this,
              "notificationList",
              this.state.isNoticesFiltered ? this.state.filterNoticesData : this.state.notificationList
            )}
            items={this.state.isNoticesFiltered ? this.state.filterNoticesData : this.state.notificationList}
          />
        </div>
      );
    }
  };
  renderSuccessMessage = () => {
    setTimeout(() => {
      this.props.getMessageStatus(TextProps.VALUE_FALSE)
    }, 3600);
    return (
      <React.Fragment>
      <div className="dta-modal" style={{opacity: 1}}></div>
      <div
        className="rivl-modal replace-pebt-modal"
        style={{ display: "flex", flexDirection: "column", background: "#fff", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: "auto", padding: "30px", zIndex: "301" }
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <svg
            className="checkmark-success"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            aria-hidden={TextProps.VALUE_STR_TRUE}
          >
            <circle
              className="checkmark-success__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark-success__check"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              fill="none"
            />
          </svg>
        </div>
        <p style={{textAlign: "center"}}>
        {connecttodtaLogoutPopupLabels(selectedLanguage).messageSuccess}
          </p>
      </div>
    </React.Fragment>
    )
  }
  renderErrorMessage = () => {
    this.closeModal();
    setTimeout(() => {
      this.props.getErrorMessageStatus(TextProps.VALUE_FALSE);
    }, 3600);
    return (
      <React.Fragment>
      <div className="dta-modal" style={{opacity: 1}}></div>
      <div
        className="rivl-modal replace-pebt-modal"
        style={{ display: "flex", flexDirection: "column", background: "#fff", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", height: "auto", padding: "30px", zIndex: "301" }
        }
      >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="118"
              height="118"
              viewBox="0 0 118 118"
            >
              <g
                id="Group_425"
                data-name="Group 425"
                transform="translate(-624 -265)"
              >
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="59"
                  cy="59"
                  r="59"
                  transform="translate(624 265)"
                  fill="#c23434"
                />
                <path
                  id="Path_174"
                  data-name="Path 174"
                  d="M121.887,217.882H75.9a6.552,6.552,0,0,1-6.532-6.532v-.109a6.552,6.552,0,0,1,6.532-6.532h45.982a6.552,6.552,0,0,1,6.532,6.532v.109a6.552,6.552,0,0,1-6.532,6.532Z"
                  transform="translate(583.629 114.292)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        <p style={{textAlign: "center"}}>
        {connecttodtaLogoutPopupLabels(selectedLanguage).errorMessage}
          </p>
        </div>
    </React.Fragment>
    )
  }

  renderMessageCenter = () => {
    const { messageCenter } = this.props.language.consumer;
    const { editorState } = this.state;
    let userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    const dropdownOptions = {
      inbox: `${messageCenter.inbox}`,
      sentMessages: `${messageCenter.sentMessages}`,
      archive: `${messageCenter.archieve}`,
    };

    let userInfoList = JSON.parse(sessionStorage.getItem("userInfo"));
    return (
      <React.Fragment>
      <div className="row">
        <div className="flex">
          <div className="col-6">
            {userInfoList && userInfoList.roleList && userInfoList.roleList[0].roleName !== USER && (
              <button
                onClick={this.openModal}
                className="dta-button dta-button--primary"
                style={{ padding: "3px 10px" }}
              >
                <Icon ariaHidden name="plus" />
                <span style={{ verticalAlign: "super" }}>
                  {" "}
                  {messageCenter.newMessage}
                </span>
              </button>
            )}
            <DTAModal
              ref={(instance) => (this.successMsgModal = instance)}
              isOpen={this.state.isModalOpen}
              onClose={this.closeModal}
              modalClass={``}
              titleText="New Message"
              headerText={messageCenter.newMessage}
              error={false}
              errorMessage={"this.state.exampleModalErrorMessage"}
            >
              <div className="dta-modal__body pad-all">
                {/* {(this.state.isApplicationSelected ||
                  this.state.isToEmailSelected ||
                  this.state.isSubjectSelected) && (
                  <div className="dta-form__error-message">
                    {this.state.isApplicationSelected &&
                      "Application Type is Required"}
                    {this.state.isToEmailSelected && "To Email is Required"}
                    {this.state.isSubjectSelected && "Subject is Required"}
                  </div>
                )} */}
                <form id="secureMessage">
                  <div className="form-group flex">
                    <label htmlFor="from">{messageCenter.from}</label>
                    <input
                      type="text"
                      id="from"
                      name="from"
                      style={{ opacity: "0.85", cursor: "not-allowed" }}
                      readOnly
                      value={
                        userDetails.firstName + " " + userDetails.lastName + `(${userDetails.agencyID})`
                      }
                    />
                  </div>
                  <div className="form-group flex">
                    <label htmlFor="application">{messageCenter.application}</label>
                    {/* <SelectDropdown
                      option={['SNAP','TANF']}
                      onChange={this.handleSelectChange}
                      defaultValue="SNAP"
                      name="application"
                      customCss={true}
                    /> */}
                    <select
                      id="application"
                      name="application"
                      value={this.state.application}
                      onChange={this.handleInputChange}
                    >
                      <option selected value="" disabled>
                      {messageCenter.application}
                      </option>
                      <option value="SNAP">SNAP</option>
                      <option value="TANF">TANF</option>
                    </select>
                  </div>
                  {this.state.isApplicationSelected && (
                    <div className="form-group flex">
                      <label></label>
                    <div className="dta-form__error-message row">
                      {this.state.isApplicationSelected &&
                        consumerLabels.consumerConst(selectedLanguage).validProgram}
                    </div>
                    </div>
                  )}
                 
                  <div
                    className="form-group flex"
                    style={{ marginBottom: "7px" }}
                  >
                    <label className="d-inblock" htmlFor="to">
                    {messageCenter.to}
                    </label>
                    <input
                      className="d-inblock"
                      type="text"
                      id="to"
                      name="to"
                      value={this.state.to}
                      onChange={this.handleToInputChange}
                    />
                  </div>
                  {
                  this.state.isToEmailSelected &&
                  <div className="form-group flex">
                      <label></label>
                    <div className="dta-form__error-message row">
                    {this.state.isToEmailSelected && consumerLabels.consumerConst(selectedLanguage).validTo}
                  </div>
                  </div>
                  }
                  {
                    this.state.isToEmailSelectedValid && 
                  <div className="form-group flex">
                      <label></label>
                    <div className="dta-form__error-message row">
                    {this.state.isToEmailSelectedValid && consumerLabels.consumerConst(selectedLanguage).invalidTo}
                  </div>
                  </div>
                }
                   {this.state.application == "" || this.state.application == "SNAP"?
                  <div className="form-group flex">
                    <span className="span-width"></span>
                      <div className="d-inblock message-checkbox-label">
                      <input
                        type="checkbox"
                        id="sendToCountyList"
                        name="sendToCountyList"
                        checked={this.state.sendToCountyList}
                        onChange={this.handleCheckboxChange}
                      />
                      <label className="w-100" for="sendToCountyList">
                      {messageCenter.sendText}
                      </label>
                    </div>
                  </div>:null
  }
                  <div className="form-group flex">
                    <label className="d-inblock" htmlFor="cc">
                      Cc
                    </label>
                    <input
                      className="d-inblock"
                      type="text"
                      id="cc"
                      name="cc"
                      value={this.state.cc}
                      onChange={this.handleCcInputChange}
                    />
                  </div>
                  {
                    this.state.isCcEmailSelected ? 
                    <div className="form-group flex">
                    <label></label>
                  <div className="dta-form__error-message row">
                    {this.state.isCcEmailSelected && consumerLabels.consumerConst(selectedLanguage).invalidTo}
                  </div></div>:null
                }
                  <div
                    className="form-group flex"
                    style={{ marginBottom: "7px" }}
                  >
                    <label className="d-inblock" htmlFor="subject">
                    {messageCenter.subject}
                    </label>
                    <input
                      className="d-inblock"
                      type="text"
                      maxLength={30}
                      id="subject"
                      name="subject"
                      value={this.state.subject}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  {this.state.isSubjectSelected && (
                    <div className="form-group flex">
                    <label></label>
                  <div className="dta-form__error-message row">
                      {this.state.isSubjectSelected && consumerLabels.consumerConst(selectedLanguage).validSub}
                    </div>
                    </div>
                  )}
                  <div className="form-group flex">
                    <span className="span-width"></span>
                    <div className="d-inblock message-checkbox-label">
                      <input
                        type="checkbox"
                        id="importantMessage"
                        name="importantMessage"
                        checked={this.state.importantMessage}
                        onChange={this.handleInputChange}
                      />
                      <label htmlFor="importantMessage">
                      {messageCenter.important}
                      </label>
                    </div>
                  </div>
                  {(
                  this.state.isEditorSelected ) && (
                  <div className="dta-form__error-message">
                    {this.state.isEditorSelected && consumerLabels.consumerConst(selectedLanguage).validBody}
                  </div>
                )}
                  <div>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      placeholder={consumerLabels.consumerConst(selectedLanguage).validBody}
                      toolbar={{
                          fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                            "Roboto",
                            "Source Sans Pro",
                          ],
                          },
                        }}
                      editorStyle={{
                        border: "1px solid #ccc",
                        height: "200px",
                        padding: "15px",
                      }}
                      toolbarStyle={{
                        border: "1px solid #ccc",
                        paddingRight: "10%",
                      }}
                      onEditorStateChange={this.onEditorStateChange}
                    />
                  </div>
                </form>
                <div className="dta-modal__footer">
                  <div
                    className="float-right secure"
                    style={{ paddingBottom: "10px" }}
                  >
                    {/* <button className="dta-button dta-button--outline-primary" onClick={this.closeExampleModal}>Print</button> */}
                   <button
                      onClick={this.onSubmitMessage}
                      className="dta-button dta-button--primary"
                    >
                                          {messageCenter.send}
                    </button>
                  </div>
                </div>
              </div>
            </DTAModal>

          </div>
          <div className="col-6 float-right mt-8 position-relative">
            <div className="input-with-image">
              <img src={searchLogo} alt="search" />
              <input
                type="text"
                className="input-search"
                maxLength={40}
                aria-label={messageCenter.search}
                placeholder={messageCenter.search}
                value={this.state.searchText}
                onChange={this.handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div
          className="row bg-white"
          style={{
            padding: "12px 10px",
            margin: "10px 0",
            position: "relative",
          }}
        >
          <div >
            {this.state.filterText === `${consumerLabels.consumerConst(selectedLanguage).inbox}` && (
              <TabBar 
              searchText={this.state.searchText} 
              replyModal={this.state.closeReplyModal} 
              data={this.state.messageList || []}
              loading={this.state.isLoading}
              setActiveTab={this.setActiveChange}
                lang = {selectedLanguage}
                from={this.state.filterText}
              onSecureMessageReply={this.handleSecureMessageUpdate}
              updateGrid={this.setActiveChange}
              />
            )}
          </div>
          <div style={{ position: "absolute", top: "10px", right: "7px" }}>
            <SelectDropdown
              language={messageCenter}
              option={Object.values(dropdownOptions)}
              onChange={this.handleFilterChange}
              defaultValue="Inbox"
            />
          </div>
          {this.state.filterText !== `${consumerLabels.consumerConst(selectedLanguage).inbox}` && (
            <div style={{ marginTop: "50px" }}>
              <DynamicTable
                loading={this.state.isLoading}
                data={this.state.messageList || []}
                from={this.state.filterText}
                replyModal={this.state.closeReplyModal}
                onSecureMessageReply={this.handleSecureMessageUpdate}
                updateGrid={this.setActiveChange}
                lang={selectedLanguage}
              />
            </div>
          )}
        </div>
      </div>
        {this.props.consumer.messageStatus === TextProps.VALUE_TRUE && (
          this.renderSuccessMessage()
        )}
        {this.props.consumer.errormessageStatus === TextProps.VALUE_TRUE && (
          this.renderErrorMessage()
        )}
      </React.Fragment>
    );
  };

  toShowPDFinWindow(data) {
    let objbuilder = "";
    objbuilder += "<html><body>"
    objbuilder +=
      '<object width="100%" height="100%"  data="data:application/pdf;base64,';
    objbuilder += data.data.base64;
    objbuilder += '" type="application/pdf" class="internal">';
    objbuilder += '<embed src="data:application/pdf;base64,';

    objbuilder += data.base64;
    objbuilder += '" type="application/pdf" />';
    objbuilder += "</object>";
    objbuilder += "</body></html>";
    var win = window.open("", "_blank", "titlebar=yes");
    win.document.title = "SNAP Application";
    const sanitizedHTML = DOMPurify.sanitize(objbuilder)
    win.document.write(sanitizedHTML);
  }

  handleUpdateNotices = () => {
    if (
      this.props.consumer.consumerData.clientInfo && this.props.consumer.consumerData.clientInfo.agencyID
    ) {
      const agencyID = this.props.consumer.consumerData.clientInfo.agencyID;
      const payload = {
        agencyID: agencyID,
      };
      this.props.noticesUpdatePostCall(payload);
    }

  };


  render() {
    this.props.consumer.consumerData.benefitInfo
    console.log(this.props.consumer,"this.props.consumer.consumerData.benefitInfo");
    const { documentupload, messageCenter } = this.props.language.consumer;
    if (this.state.loading) return helperFunction.isLoading(this.state.loading);
    let isShowBellIcon =  showBellIcon (this.props.consumer.notificationList)

    const tabs = [
      {
        id: "tabDocuments",
        title: documentupload.mainpageydocument,
        content: this.renderDocumentsTabPanel,
      },
      {
        id: "tabNotifications",
        title: documentupload.DTANotices,
        content: this.renderNotificationsTabPanel,
        isShowBellIcon: isShowBellIcon,
      },
      {
        id: "messageCenter",
        title: messageCenter.messageCenter,
        content: this.renderMessageCenter,
      },

    ];
    if (
      this.props.consumer.showDocumentLoading === undefined ||
      this.props.consumer.showDocumentLoading === TextProps.VALUE_FALSE
    ) {
      return (
        <div>
          <div className="upload-document-image__outer">
            <div className="max-width-myinfo max-width--three-quarters pad-all--double upload-document-image__inner">
              <button
                className="dta-button dta-button--primary upload-document-image__button"
                onClick={this.openUploadDocumentModal}
              >
                {documentupload.mainpageSenddta}
              </button>
              <p className="upload-document-image__text text--secondary">
                {documentupload.mainpageSenddtainfo}{" "}
                <strong>{documentupload.smartPhone}</strong>{" "}
                {documentupload.orScandocument}
                <strong>{documentupload.constComputer}</strong>{" "}
              </p>
              <img
                src={uploadDocumentIllustrationMobileImg}
                className="upload-document-image__background hidden-desktop"
                alt="People uploading documents from mobile device and desktop computer"
              />
              <img
                src={uploadDocumentIllustrationImg}
                className="upload-document-image__background hidden-touch"
                alt="People uploading documents from mobile device and desktop computer"
              />
            </div>
          </div>
          <div className="max-width-myinfo max-width--three-quarters pad-all--double">
            
            <DTATabs tabs={tabs} postNotification = {this.handleUpdateNotices}  alertIcon ={this.props.consumer.noticesUpdateSuccess == true} />
            {modalHelperFunction.dtaHelperModal(
              (instance) => (this.uploadDocumentModal = instance),
              this.state.uploadDocumentModalOpen,
              this.closeUploadDocumentModal,
              "upload-document-modal",
              `Send a document to DHR (${this.state.activeUploadModalStep}/${this.state.totalUploadModalSteps})`,
              this.state.error,
              this.state.errorMessage,
              TextProps.VALUE_FALSE,
              documentupload.steponepagetitle +
                `(${this.state.activeUploadModalStep}/${this.state.totalUploadModalSteps})`,
              this.renderUploadModalBody()
            )}
          </div>
          <div id="dta_notice"> </div>
        </div>
      );
      // -- render complete
    } else if (
      this.props.consumer.isDocuemntLoaded === TextProps.VALUE_TRUE &&
      this.props.consumer.showDocumentLoading === TextProps.VALUE_TRUE
    ) {
      this.props.consumer.isDocuemntLoaded = TextProps.VALUE_FALSE;
      this.props.consumer.showDocumentLoading = TextProps.VALUE_FALSE;
      this.toShowPDFinWindow(this.props.consumer.noticeData);
    } else {
      return <Loading />;
    }
  }

  // Events
  // ------

  // doLoadMore function fakes getting more results for list.
  // Can be done through appropriate production logic instead
  _doLoadMore = (stateKey, toInsert) => {
    var array = new Uint32Array(1);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState({
          [stateKey]: [
            ...this.state[stateKey],
            ...toInsert.map((item) => {
              return {
                ...item,
                id: `${window.crypto.getRandomValues(array)[0]}`,
              };
            }),
          ],
        });
        resolve();
      }, 2000);
    });
  };
}
//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientDocumentsList: getClientDocumentsList,
      uploadDocument: uploadBase64,
      onDTANotificationList: getDTANotificationList,
      onConsumerDocumentList: getConsumerDocumentList,
      hideUrgentApmntAlert: hideUrgentApmntAlert,
      onSecureMessageList: getSecureMessageData,
      onSecureNewMessage: getSecureMessageData,
      onSecureMessageReply: getSecureMessageData,
      onCaseManagerEmail: getCaseManagerDetail,
      noticesUpdatePostCall: noticesUpdatePostCall,
      getMessageStatus: getMessageStatus,
      getErrorMessageStatus: getErrorMessageStatus,
      getDocumentsDetails: getDocumentsDetails
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerDocuments);
