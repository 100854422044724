let storageName = "sdstate";
function getDefaultState() {
  let session = sessionStorage.getItem(storageName);
  if (session) {
    return JSON.parse(session);
  } else {
    return {};
  }
}
function saveState(state) {
  sessionStorage.setItem(storageName, JSON.stringify(state));
}
export function selfDeclareReducer(state = getDefaultState(), action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SD_SNAP_PAGES_FULLFILLED": {
      newState.sections = action.payload;
      break;
    }

    case "APPEND_INCOME_DATA_SELF": {
      newState.sections[0] = action.payload;
      break;
    }
    case "APPEND_EXPENSE_DATA": {
      newState.sections[4] = action.payload;
      break;
    }
    case "FILL_INPUT_VALUES_STATE": {
      break;
    }

    case "DOWNLOAD_PDF_SD_APPLICATION": {
      break;
    }

    case "@@redux/INIT": {
      newState.sections = [];
      newState.AnswerObject = {};
      newState.dalReference = {};
      break;
    }

    //To set yes no selection status
    case "SD_ADD_DATA_CHANGE_STATUS": {
      newState.sections[action.index].selectionMade = action.payload;
      break;
    }

    //Earned income

    case "APPEND_DECLAREEXPENSE_SUBSEC": {
      newState.sections[0].subsections = action.payload;
      break;
    }

    case "SET_SELFDECLARE_RESPONSE": {
      newState.responseObject = action.payload;
      break;
    }

    case "POST_SELF_DECLARE_DATA_FULFILLED": {
      newState.referenceNumber = action.payload;
      break;
    }
  }
  saveState(newState);
  return newState;
}
