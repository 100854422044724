import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import DTACustomizedSelect from "./dtaCustomizedSelect";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { tryCall } from "./utilities/controls";
import _ from "lodash";
import { screeningStaticLabels } from "../../pages/screening/screeningText";
import {
  INPUT_ALPHA_TEXT,
  INPUT_ALPHANUMERIC_SPACE_TEXT,
  STATES,
  STATES_FULL,
  AL_STATES,
  AL_STATE,
  COUNTRY,
} from "../constants/controls";
import { ZIPCODE } from "../constants/zipCode";
import Radio from "./radio";
import DTAModal from "./modal/dtaModal";
import { interimReportPageLabels } from "../../pages/recertInterimReport/recertInterimReportText";
import * as languageConstants from "../constants/constants";
import { isMailMyInfo } from "../../redux/consumer/consumerMyInfoActions";
import { addressValidation } from "../../redux/applySnap/applySnapActions";
import { COUNTYCODE } from "../constants/countyCode";
import { ALL_STATES_COUNTY } from "../constants/statesCountyCode";
//applysnapInfoAction
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../constants/text";
import * as languageConsts from "../constants/types";

import { langOptSel } from "../constants/constants";
import DtaSelect from "./dtaSelect";
import DtaCustomizedSelect from "./dtaCustomizedSelect";
let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (selectedLanguage === null || selectedLanguage === langOptSel ) {
    selectedLanguage = "en-US";
  }

let countyIdArray = [];
let countyValueArray = [];
class Statecounty extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onZipCodeChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      county: PropTypes.array,
      verified:PropTypes.string,
      state: PropTypes.oneOf(["", ...STATES]),
      }),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    //setting Default MA State
    let copyProps = _.cloneDeep(props);
    this.state = this._buildStateFromProps(copyProps);
    countyIdArray =[];
    countyValueArray =[];
    ALL_STATES_COUNTY.find((obj) => {
      if (obj.state == AL_STATE) {
        countyValueArray.push(obj.value);
        countyIdArray.push(obj.code);
      }
    });
    this.state.countyIdArray = countyValueArray;
    this.state.countyValueArray = countyValueArray.sort();
    let stateFull = [];
    ALL_STATES_COUNTY.forEach((c) => {
      stateFull.push(c.state);
    });
    let uniqueState = stateFull.filter(
      (item, index) => stateFull.indexOf(item) === index
    ); // remove duplicate
    this.state.stateFull = uniqueState.sort();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this._buildStateFromProps(nextProps));
    }
  }



  render() {
    const { error, disabled, required, name, className, isResidentialAddress } =
        this.props,
      ariaLabels = this.props["aria-labelledby"];
    const { resAddrMatchFlag } = this.props.data;
    return (
      <div className={className}>
      
        {this._buildFirstLine(
          name,
          ariaLabels,
          error,
          disabled,
          required,
          isResidentialAddress
        )}
        
      </div>
    );
  }

  // Rendering
  _buildFirstLine(name, ariaLabels, error, disabled, required,state) {
    if (this.state._value.state === "" ) {
      this.state._value.state = TextProps.DEFAULT_STATE;
    }
    return (
        <div>
        {
          <div className="pure-g">
            <div className="pure-u-2-3">
              <div className="pure-g">
                <div className="pure-u-1-5 pad-right-half">
                  <label
                    className="dta-form__label dta-form__label--size-small"
                    htmlFor={this.nextUniqueId()}
                  >
                    {screeningStaticLabels(selectedLanguage).stateLabel}
                  </label>
                  <DtaCustomizedSelect
                    name={name}
                    options={
                      this.state.stateFull
                    }
                    className="dta-select--expand-width"
                    id={this.lastUniqueId()}
                    aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                    value={
                       this.state._value.state
                    }
                    onChange={this._handleChangeForState}
                    error={error}
                    required={required}
                    disabled={ false}
                    state={state}
                  />
                </div>
                <div className="pure-u-2-5 pad-right-half">
                <label
                  className="dta-form__label dta-form__label--size-small"
                  htmlFor={this.nextUniqueId()}
                >
                  {screeningStaticLabels(selectedLanguage).county}
                </label>
                <DtaSelect
                  name={name}
                  options={this.state.countyValueArray}
                  onChange={this._handleChangeForCounty}
                  className="dta-select--expand-width"
                  id={this.lastUniqueId()}
                  aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                  value={this.state._value.county}
                  error={error}
                  required={required}
                />
              </div>             
              </div>
            </div>        
            </div>
        }
      </div>
    );
  }



 

 

  _handleChangeForState = (state) => {
    console.log(state,"state+++");
    let stateCounty  =[];
    let stateCountyCode = [];
    ALL_STATES_COUNTY.find((obj) => {
      console.log(obj,"obj+++");
      if (obj.state == state) {
        stateCounty.push(obj.value);
        stateCountyCode.push(obj.code);
        console.log(stateCounty,"stateCounty++++");
        console.log(stateCountyCode,"stateCountyCode+++");
      }
    });
    countyIdArray = stateCounty;
    countyValueArray = stateCountyCode;
    this.state.countyIdArray = stateCounty;
    this.state.countyValueArray = stateCounty;
     const newValue = {
      county: '',
      state: state,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForCounty = (countyValue) => {
    let county, countyCode;
    ALL_STATES_COUNTY.find((obj) => {
      if (obj.value == countyValue && obj.state == this.state._value.state) {
        county = obj.value;
        countyCode = obj.code;
      }
    });
    const newValue = {
      county: countyValue,
      countyCode:countyCode,
      state: this.state._value.state,     
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  // Helpers
  _buildStateFromProps(props) {
    if (props.value) {
      return {
        _value: props.value,
      };
    } else {
      return {
        _value: {        
          state: "",        
          county: "",
          countyCode: "",        
          stateFull: [],        
          countyIdArray: [],
          countyValueArray: [],
        },
      };
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
   
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Statecounty);
