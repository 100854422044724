import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import * as languageConstants from "../../utils/constants/constants";
import { interimReportPageLabels } from "./recertInterimReportText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import { incomeSectionMultilanguageLabels } from "../applySnap/applySnapText";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === languageConstants.langOptSel) {
    language = "en-US";
  }

export const sections = [
  {
    id: "before-you-start",
    title: interimReportPageLabels(language, languageConstants.beforeYouStart)
      .howApplyLabel, //'How does applying work?',
    header: interimReportPageLabels(language, languageConstants.beforeYouStart)
      .heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "contact-info",
    title: interimReportPageLabels(
      language,
      languageConstants.contactInfo,
      language
    ).title,
    header: interimReportPageLabels(language, languageConstants.contactInfo)
      .header,
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "my-household",
    title: interimReportPageLabels(language, languageConstants.householdStatic)
      .houseTitle, // 'My household',
    header: interimReportPageLabels(language, languageConstants.householdStatic)
      .houseHeader,
    completed: null,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "combined-income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "WAGES",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SELEMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BOARD",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[2],
              },
            ],

            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "job-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeTargetValue,
                [OptionsProps.TABLE_DSC]: "WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "job-income-0",

                  amountLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeAmountLabel,
                  employerLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeEmployerLabel,
                  unitLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeUnitLabel,
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeLabel,

                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).labelOtherJobIncome, //'Add another Wage'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Self-Employment?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfGross_Label,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).adsSelfEmployee, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "room-and-board-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).roomAndBoardIncomeTargetValue,
                [OptionsProps.TABLE_DSC]: "BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Room and Board Income?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Room and Board Income?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Room and Board Income?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Room and Board Income?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).roomAndBoardIncomeLabel,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherRoomAndBoardIncome, //'Add another Self-Employment'
              }
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        // title: interimReportPageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).earnedIncomeMenuTitle, //'Income summary',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).earnedIncomeTitle, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "unearned-income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "unearned-combined-income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "TAFDCS",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RSDI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SSI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "VETBEN",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UNEMPL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WRKCMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "INTINC",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MILPE",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETFND",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RRRET",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "LIHEAP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RENTAL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[11],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHSDOR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[12],
              },
              {
                [OptionsProps.OPTION_VALUE]: "ALIMNY",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[13],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONTR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[14],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unearnedIncomeoptions[15],
              }
            ],

            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "unearned-combined-income-subsection",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "tanf-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).TANFTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "TAFDCS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) TANF?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) TANF?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these TANF?",
                  unitLabel: "What is the frequency of John Doe's (8.Aug.1967) TANF?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherTANFLabel, //'Add another SSI'
              },
              {
                [Props.NAME]: "social-security-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).rsdiTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addRDILabel, //'Add another RSDI'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherSSILabel, //'Add another SSI'
              },
              {
                [Props.NAME]: "veterans-benefits-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).veteranBenefitsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Unemployment?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmpCompensationLabel, //'Add another Unemployment'
              },
              {
                [Props.NAME]: "WRKCMP-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workerCompTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Workman's Compensation?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Workman's Compensation??",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Workman's Compensation??",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Workman's Compensation??",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmpCompensationLabel, //'Add another Unemployment'
              },
              {
                [Props.NAME]: "interest-dividends-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).interestDividendTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).interestDividendTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).interestDividendTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).interestDividendTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).militaryTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).militaryTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).militaryTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).militaryTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).pensionTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).pensionTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).pensionTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).pensionTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "railroad-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).railroadTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).railroadTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).railroadTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).railroadTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "hud-assistance-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).hudTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).hudTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).hudTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).hudTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).landTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).landTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).landTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).landTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).landCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "child-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSupportTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).childSupportTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).childSupportTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).childSupportTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).childSupportTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSupportCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).alimonyTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).alimonyTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).alimonyTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).alimonyTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "contribution-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967)" +
                      interimReportPageLabels(
                        language,
                        languageConstants.earnedIncomeStatic
                      ).contributionTargetValue +
                      "?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).contributionTargetValue +
                    "?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).contributionTargetValue +
                    "?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) " +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).contributionTargetValue +
                    "?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) other income or benefits?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).questionButtonLabel, //"Add another Other"
              },
            ]
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "unearned-income-summary",
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).unEarnedIncomeTitle, //"About your household's income",
        completed: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        hiddenFromNav: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "childsupport-expenses",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).childSupportSideBar, //'Sign & submit',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).childSupportSummaryHeader, //'Sign & submit',
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    disabled: TextProps.VALUE_FALSE,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "child-support-amount",
        title: "John Doe (8.Aug.1967)",
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).childSupportSummaryHeader, //'Tell us about your Child support costs',
        questions: [
          {
            [Props.NAME]: "child-support-cost-1",
            [Props.LABEL]:
              "What is the child support expense for John Doe (8.Aug.1967)?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).perLabel,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).freqTripoptions,
            [OptionsProps.TARGET_VALUE]: { unit: "" },
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            enabledClickAction: "",
            enabledDoneAction: "",
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "childsupport-expenses",
        // title: interimReportPageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).earnedIncomeMenuTitle, //'Income summary',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).childSupportSummaryHeader,
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
  },
  {
    id: "shelter-utility-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.shelterAndutility
    ).shelterAndUtilityMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.shelterAndutility
    ).shelterAndUtilityHeading, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "shelter-expenses-section",
        title: interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).shelterExpenseLabel,
        header:  interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).shelterExpenseLabel,
        subHeader:
        interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).shelterSubTitle,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "type",
            [Props.LABEL]:{
              [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textBefore,
              [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.triggerText,
              [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textAfter,
              [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimShelterLabel.helpText
            },
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "RENT",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MTGPRN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPINS",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPTAX",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SECMTG",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEXP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEEQ",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONFEE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOASFE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[10],
              },
            ],
            [UnitsProps.CLEAR_OPTION]: "I do not pay for any shelter expenses",
            // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
          },
        ],
        subsections: [
          {
            id: "shelter-expenses-subsection",
            title:  interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).shelterExpenseLabel,
            header:  interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).shelterExpenseLabel,
            subHeader:
            interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).shelterSubTitle,
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "rent",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).rentTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).rentQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherRentLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "mortgage",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).mortgageTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).mortageQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).mortageErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anothermortgageLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "property-insurance",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propInsTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propInsQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propInsErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherpropInsLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "property-tax",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propTaxTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propTaxQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propTaxErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherpropTaxLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "second-mortgage",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).secondMortgageTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).secMortgageQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).secondMortgageErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anothersecondMortgageLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "homeless-expense",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homelessExpTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homelessQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homelessExpErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherhomelessExpLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "home-equity",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homeEquityTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).equityLoanQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homeEquityErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherhomeEquityLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "condo-fee",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).condoTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).condoQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).condoErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anothercondoLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "house-owner-association",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseOwnerTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseAssQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseOwnerErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherhouseOwnerLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "other",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).otherTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).otherQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseUnitOptions, //['Week', 'Month', 'Year'],
  
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).otherErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).anotherOtherLabel, //"Add another rent"
              },
            ],
          },
          {
            id: "utility-expenses-section",
            title: interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).utilityExpensesLabel,
            header:  interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).utilityExpensesLabel,
            subHeader:interimReportPageLabels(
              language,
              languageConstants.shelterAndutility
            ).utilitySubTitle,
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "type",
                [Props.LABEL]:{
                  [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textBefore,
                  [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.triggerText,
                  [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textAfter,
                  [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.helpText
                },
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: [
                  {
                    [OptionsProps.OPTION_VALUE]: "heatFlag",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[0],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "acFlag",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[1],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "electricOrGasFlag",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[3],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "phoneFlag",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[4],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "payWaterSewage",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[5],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "payGarbage",
                    [OptionsProps.OPTION_DISPLAY]:
                      incomeSectionMultilanguageLabels(language)
                        .utilityQOptions[6],
                  },
                ],
                [UnitsProps.CLEAR_OPTION]: "I do not pay for any utility expenses",
                // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
              },
              {
                [Props.NAME]: "is-received-liheap",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.householdStatic
                ).receivedLieapLabel,
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]: interimReportPageLabels(
                  language,
                  languageConstants.householdStatic,
                  null,
                  null,
                  null
                ).receivedLieapOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "shelter-utility-summary",
        // title: "Shelter & Utility Expenses", //'Income',
        header: interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).shelterAndUtilityHeading, //"About your household's income",
        completed: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        hiddenFromNav: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "summary",
    parentsId: "summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).summaryLabel,
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).summaryLabel,
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "sign-and-submit",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).signSubmitLabel, //'Sign & submit',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).signSubmitHeader, //'Sign & submit',
    subsections: [
      {
        id: "submitted",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).subSectionLabel, //'Submitted',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).submitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
];

export default sections;

export const householdTemplate = {
  id: "household-member",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseMemberTitle,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandMaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandFaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandDaugther,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citizenHelp,
      },
      [Props.INLINE_HELP]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citizenInhelp,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [InputProps.CLEAR_LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnErrLabel,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const householdDisabilityTemplate = {
  id: "household-details",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .hholdHeader,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseHeader,
  questions: [
    {
      [Props.NAME]: "disability",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQTriggerLabel,
        [Props.LABEL_OBJ_AFTER]:
          interimReportPageLabels(language, languageConstants.householdStatic)
            .disabilityQlabel2 + "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityHelp,
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).disablilityCler,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const IncomeSectionTemplate = {
  id: "combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "WAGES",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SELEMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BOARD",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[2],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "job-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).jobIncomeTargetValue,
          [OptionsProps.TABLE_DSC]: "WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "job-income-0",

            amountLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeAmountLabel,
            employerLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeEmployerLabel,
            unitLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeUnitLabel,
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeLabel,

            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).labelOtherJobIncome, //'Add another Wage'
        },
        {
          [Props.NAME]: "self-gross-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "job-income-0",
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).selfGross_Label,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).adsSelfEmployee, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "room-and-board-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).roomAndBoardIncomeTargetValue,
          [OptionsProps.TABLE_DSC]: "BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "room-and-board-gross-incom-0",
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) room and board income?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these room and board income?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) room and board income?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).roomAndBoardIncomeLabel,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherRoomAndBoardIncome, //'Add another Work Study'
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const incomeSummaryTemplate = {
  id: "income-summary",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const unearnedIncomeSectionTemplate = {
  id: "unearned-combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "TAFDCS",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RSDI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SSI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "VETBEN",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UNEMPL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WRKCMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "INTINC",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MILPE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETFND",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RRRET",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "LIHEAP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RENTAL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[11],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHSDOR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[12],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ALIMNY",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[13],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONTR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[14],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[15],
        }
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "unearned-combined-income-subsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "tanf-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).TANFTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) TANF?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) TANF?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these TANF?",
            unitLabel: "What is the frequency of John Doe's (8.Aug.1967) TANF?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherTANFLabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "social-security-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).rsdiTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these wages?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addRDILabel, //'Add another RSDI'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherSSILabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "veterans-benefits-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).veteranBenefitsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "VETBEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Unemployment?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unEmpCompensationLabel, //'Add another Unemployment'
        },
        {
          [Props.NAME]: "WRKCMP-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).workerCompTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Workman's Compensation?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Workman's Compensation??",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Workman's Compensation??",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Workman's Compensation??",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unEmpCompensationLabel, //'Add another Unemployment'
        },
        {
          [Props.NAME]: "interest-dividends-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).interestDividendTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).interestDividendCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).militaryCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).pensionCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "railroad-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).railroadCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "hud-assistance-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).hudTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).hudCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).landTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).landCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "child-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).childSupportTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSupportTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).childSupportCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).alimonyCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "contribution-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).contributionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).contributionCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) other income or benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).questionButtonLabel, //"Add another Other"
        },
      ]
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const shelterUtilitySectionTemplate = {
  id: "shelter-utility-summary",
  title: interimReportPageLabels(
    language,
    languageConstants.shelterAndutility
  ).shelterAndUtilityMenuTitle, //'Income',
  header: interimReportPageLabels(
    language,
    languageConstants.shelterAndutility
  ).shelterAndUtilityHeading, //"About your household's income",
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  subsections: [
    {
      id: "shelter-expenses-section",
      title:  interimReportPageLabels(
        language,
        languageConstants.shelterAndutility
      ).shelterExpenseLabel,
      header:  interimReportPageLabels(
        language,
        languageConstants.shelterAndutility
      ).shelterExpenseLabel,
      subHeader:
      interimReportPageLabels(
        language,
        languageConstants.shelterAndutility
      ).shelterSubTitle,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "type",
          [Props.LABEL]: {
            [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textBefore,
            [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.triggerText,
            [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimShelterLabel.textAfter,
            [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimShelterLabel.helpText
          },
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "RENT",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "MTGPRN",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "PRPINS",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[5],
            },
            {
              [OptionsProps.OPTION_VALUE]: "PRPTAX",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "SECMTG",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[6],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOMEXP",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[7],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOMEEQ",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[8],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CONFEE",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[4],
            },
            {
              [OptionsProps.OPTION_VALUE]: "HOASFE",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[9],
            },
            {
              [OptionsProps.OPTION_VALUE]: "OTHER",
              [OptionsProps.OPTION_DISPLAY]:
                incomeSectionMultilanguageLabels(language).houseQOptions[10],
            },
          ],
          [UnitsProps.CLEAR_OPTION]: "I do not pay for any shelter expenses",
          // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
        },
      ],
      subsections: [
        {
          id: "shelter-expenses-subsection",
          title:  interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).shelterExpenseLabel,
          header:  interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).shelterExpenseLabel,
          subHeader:
          interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).shelterSubTitle,
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "rent",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).rentTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).rentQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).rentErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              interimReportPageLabels(
                language,
                languageConstants.shelterAndutility,
                null,
                null
              ).anotherRentLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "mortgage",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).mortgageTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).mortageQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).mortageErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anothermortgageLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "property-insurance",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propInsTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propInsQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propInsErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherpropInsLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "property-tax",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propTaxTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propTaxQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propTaxErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherpropTaxLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "second-mortgage",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).secondMortgageTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).secMortgageQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).secondMortgageErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anothersecondMortgageLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "homeless-expense",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homelessExpTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homelessQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homelessExpErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherhomelessExpLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "home-equity",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homeEquityTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).equityLoanQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homeEquityErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherhomeEquityLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "condo-fee",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).condoTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).condoQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).condoErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anothercondoLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "house-owner-association",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseOwnerTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseAssQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseOwnerErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherhouseOwnerLabel, //"Add another rent"
            },
            {
              [Props.NAME]: "other",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).otherTargetValue, //"Rent",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).otherQuestion, //"How much is your household's rent?",
                [Props.TYPE]: Types.SHELTER_EXPENSE,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).perLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseUnitOptions, //['Week', 'Month', 'Year'],

                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).otherErrMsg, // 'Please enter rent'
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).anotherOtherLabel, //"Add another rent"
            },
          ],
        },
        {
          id: "utility-expenses-section",
          title:  interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).utilityExpensesLabel,
          header:  interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).utilityExpensesLabel,
          subHeader: interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).utilitySubTitle,
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "type",
              [Props.LABEL]:{
                [Props.LABEL_OBJ_BEFORE]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textBefore,
                [Props.LABEL_OBJ_TRIGGER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.triggerText,
                [Props.LABEL_OBJ_AFTER]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.textAfter,
                [Props.LABEL_OBJ_HELP]: incomeSectionMultilanguageLabels(language).interimUtilityLabel.helpText
              },
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: [
                {
                  [OptionsProps.OPTION_VALUE]: "heatFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[0],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "acFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[1],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "electricOrGasFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[3],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "phoneFlag",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[4],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "payWaterSewage",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[5],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "payGarbage",
                  [OptionsProps.OPTION_DISPLAY]:
                    incomeSectionMultilanguageLabels(language)
                      .utilityQOptions[6],
                }
                // {
                //   [OptionsProps.OPTION_VALUE]: "liheapFlag",
                //   [OptionsProps.OPTION_DISPLAY]:
                //     incomeSectionMultilanguageLabels(language)
                //       .utilityQOptions[7],
                // },
              ],
              [UnitsProps.CLEAR_OPTION]: "I do not pay for any utility expenses",
              // incomeSectionMultilanguageLabels(language).utilitiesClearOption,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
            },
            {
              [Props.NAME]: "is-received-liheap",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).receivedLieapLabel,
              [Props.TYPE]: Types.RADIO,
              [OptionsProps.OPTIONS]: interimReportPageLabels(
                language,
                languageConstants.householdStatic,
                null,
                null,
                null
              ).receivedLieapOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
    },
    {
      id: "shelter-utility-summary",
      // title: "Shelter & Utility Expenses", //'Income',
      header: interimReportPageLabels(
        language,
        languageConstants.shelterAndutility
      ).shelterAndUtilityHeading, //"About your household's income",
      completed: TextProps.VALUE_FALSE,
      skipsubSectionNavigation: TextProps.VALUE_TRUE,
      hiddenFromNav: TextProps.VALUE_TRUE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
};

export const expenseHousingCostTemplate = {
  id: "housing-costs",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .houseUtlilityLabel,
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).expenseHeader,
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).expenseSubHeader,
  questions: [
    {
      [Props.NAME]: "utilities",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).anyUtilQcost, //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,

      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "payHeat",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payAC",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payACFee",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "elgas",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "hasPhone",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[4],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
    {
      [Props.NAME]: "type",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseQLabel, //'What type of housing cost does your household have?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "RENT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MORTGAGE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PROPERTY_TAX",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "HOME_INSURANCE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONDO_FEE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[4],
        },
      ],
      [UnitsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseCostErrmsg, //'Please choose housing costs'
    },
  ],
  subsections: [
    {
      id: "housing-cost-detail",
      title: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseUtlilityLabel, //'Housing and Utility Costs',
      header: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseHeader, //'Tell us about your housing costs',
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
      completed: TextProps.VALUE_FALSE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).rentTargetValue, //"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.SHELTER_EXPENSE,
            [UnitsProps.IN_BETWEEN]:
            incomeSectionMultilanguageLabels(language).unitsPerLabel,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).houseUnitOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).mortageTargetValue, //"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.SHELTER_EXPENSE,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageunitBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageQoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).propertyTaxTargetValue, //"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.SHELTER_EXPENSE,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).homeInsurTargetValue, //"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.SHELTER_EXPENSE,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).condoFeeTargetValue, //"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.SHELTER_EXPENSE,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condofeeCostoptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoButtonLabel, //"Add another condo fee"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseKidsAndAdultsTemplate = {
  id: "kids-and-adults",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .childTitle, //'Child or Adult Dependent Care Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostSubHeader, // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjbefore,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjtrigger, //'transport',
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).careGiveHelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption, // 'No one',
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).questionClearOption, //'No one'
            },
          ],
        },
      ],
    },
  ],
  subsections: [
    {
      id: "kids-and-adults-marty",
      title: "Marty Smith (2.Jun.1994)",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
            "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).costBetween,
          [UnitsProps.UNITS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).freqTripoptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Month" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).freqTripoptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      subsections: [
        {
          id: "kids-adult-multiple-address",
          title: "Marty Smith (2.Jun.1994)",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).expenseSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does your household drive Marty Smith (2.Jun.1994) to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseChildSupportTemplate = {
  id: "child-support-amount",
  title: "John Doe (8.Aug.1967)",
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childSupportSummaryHeader, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "child-support-cost-1",
      [Props.LABEL]:
        "What is the child support expense for John Doe (8.Aug.1967)?",
      [Props.ADD_DELETE_AND_DONE]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.UNITS,
      [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).perLabel,
      [UnitsProps.UNITS]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).freqTripoptions,
      [OptionsProps.TARGET_VALUE]: { unit: "" },
      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseMedicalExpenseTemplate = {
  id: "medical-expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .medicalTitle, //'Medical Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).medicalHeader, //"Tell us about your medical costs",
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).medicalSubHeader,
  // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "with-medical-expenses",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpBefore,
        //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpTrigger,
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpAfter,
        // ', over the counter medications, dental or eye care, adult diapers, etc.?',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExphelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]:
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .medicalInlineSeethisFull +
        ' <a href="https://www.mass.gov/files/documents/2017/10/02/SNAP-MEB-English-0815.pdf" target="_blank" rel="noopener noreferrer">' +
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .medicalInlineEligibleMedical +
        "</a>.",
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption,
    },
  ],
  subsections: [
    {
      id: "medical-expenses-1",
      title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "driving-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy??",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
      subsections: [
        {
          id: "medical-address-1",
          title: "Medical Appointment for John Doe",
          header: "Medical Appointment for John Doe",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).medicalAddress,
              // 'What is the address of the medical appointment or pharmacy?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).zipErrmsg, //'Please enter a valid Zipcode'
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
            {
              [Props.NAME]: "cost-trips-1",
              [Props.LABEL]:
                "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const ExpenseSectionTemplate = {
  id: "expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .expenseTitle, //'Expenses',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).almostLabel,
  subsections: [
    expenseHousingCostTemplate,
    expenseKidsAndAdultsTemplate,
    expenseChildSupportTemplate,
    expenseMedicalExpenseTemplate,
  ],
  completed: TextProps.VALUE_FALSE,
};

export const childSummaryTemplate = {
  id: "childsupport-expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const contactInfoSummaryTemplate = {
  id: "contact-info",
  title: interimReportPageLabels(
    language,
    languageConstants.contactInfo,
    language
  ).title,
  header: interimReportPageLabels(language, languageConstants.contactInfo)
    .header,
  completed: TextProps.VALUE_FALSE,
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  disabled: TextProps.VALUE_FALSE,
  subsections: [
    {
      //edit the address
      id: "edit-contact",
      header: interimReportPageLabels(language, languageConstants.contactInfo)
        .header,
      questions: [
        {
          [Props.NAME]: "head-of-household",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).headhouseholdlabel,
          [Props.TYPE]: Types.INPUT_TEXT,
          Answer: "John Smith",
          targetValue: "John Smith",
        },
        {
          [Props.NAME]: "is-homeless",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).Homelesslable,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ["Yes", "No"],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: "Yes",
          targetValue: "Yes",
        },
        {
          [Props.NAME]: "address",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo). Homeaddresslabel,
          [Props.TYPE]: Types.ADDRESS,
          [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
          Answer: {
            line1: "Thomas st",
            city: "auburn",
            state: "MA",
            zip: "57657",
          },
          targetValue: {
            line1: "Thomas st",
            city: "auburn",
            state: "MA",
            zip: "57657",
          },
        },
        {
          [Props.NAME]: "is-also-mailing",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).Mailingaddresslabel,
          [Props.TYPE]: Types.RADIO,
          Answer: "Yes",
          targetValue: "Yes",
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_YES,
              [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_YES,
            },
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_NO,
              [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_NO,
            },
          ],
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                TextProps.VALUE_STR_FALSE,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "mailingAddress",
                  [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo). Whatismailindaddresslabel,
                  [Props.TYPE]: Types.ADDRESS,
                  [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                  Answer: {
                    line1: "Thomas st",
                    city: "auburn",
                    state: "MA",
                    zip: "57657",
                  },
                  targetValue: {
                    line1: "Thomas st",
                    city: "auburn",
                    state: "MA",
                    zip: "57657",
                  },
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "phoneNumber",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo). Primaryphonenumberlabel,
          [Props.TYPE]: Types.INPUT_PHONE,
          Answer: "87378373",
          targetValue: "87378373",
        },
        {
          [Props.NAME]: "phone-number-notification",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).Textmessagelabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ["Yes", "No"],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: ["Yes", "No"],
          targetValue: ["Yes", "No"],
        },
        {
          [Props.NAME]: "emailAddress",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).Emaillable,
          [Props.TYPE]: Types.INPUT_TEXT,
          Answer: "test@gmail.com",
          targetValue: "test@gmail.com",
        },
        {
          [Props.NAME]: "email-address-notification",
          [Props.LABEL]: interimReportPageLabels(language, languageConstants.contactInfo).Emailnotificationlabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: ["Yes", "No"],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          Answer: ["Yes", "No"],
          targetValue: ["Yes", "No"],
        },
      ],
    },
  ],
};

export const householdSummaryTemplate = {
  id: "my-household",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseMemberTitle,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  subsections: [
    {
      id: "household-member-index-template",
      title: interimReportPageLabels(language, languageConstants.householdStatic).householdDetailslabel,
      questions: [
        {
          [Props.NAME]: "firstName",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).houseFirstName, //"What is this person's first name?",
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).firstErrmsg, //'Please enter first name'
          [Props.MAX_LENGTH]: 15,
        },
        {
          [Props.NAME]: "middleName",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).houseMiddleLabel, //"What is this person's middle name?",
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).houseMiddleName, //'Please enter middle name'
          [Props.MAX_LENGTH]: 15,
        },
        {
          [Props.NAME]: Names.LAST_NAME,
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).houseLastNameLabel, //"What is this person's last name?",
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).enterLastname, //'Please enter last name'
          [Props.MAX_LENGTH]: 20,
        },
        {
          [Props.NAME]: Names.SUFFIX,
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).suffixLabel, //'Suffix',
          [Props.TYPE]: Types.SELECT,
          [SelectProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
        },
        {
          [Props.NAME]: "relationship",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).relationQlabel, // "What is this person's relationship to you?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "SPOUSE",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).spouseDisplay, //'Spouse'
            },
            {
              [OptionsProps.OPTION_VALUE]: "MO",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).motherDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "FA",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).faterDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "DAUGH",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).daughterDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "SON",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).sonDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "BROTH",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).brotherDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "SIST",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).sisterDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "GRMOT",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).grandMaDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "GRFATH",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).grandFaDisplay,
            },
            {
              [OptionsProps.OPTION_VALUE]: "GRDAU",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).grandDaugther,
            },
            {
              [OptionsProps.OPTION_VALUE]: "GRSON",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).grandSon,
            },
            {
              [OptionsProps.OPTION_VALUE]: "OTHREL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).otherDisplay,
            },
          ],
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "dateOfBirth",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).houseDobLabel, //"What is this person's date of birth?",
          [Props.TYPE]: Types.DATE,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).enterDOB,
        },
        {
          [Props.NAME]: "biological-sex",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).personGenLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).genderOptios,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "ethnicity",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).labelEthnicity,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "HSPLTN",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).hisPanicOption, //'Hispanic/Latino'
            },
            {
              [OptionsProps.OPTION_VALUE]: "NNHSPL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).nonhisPanicOption, //'Non-Hispanic/Latino'
            },
          ],
          [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).declineToAnswerLabel,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: "Please choose ethnicity",
        },
        {
          [Props.NAME]: "race",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).raceLabel,
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "AMIALN",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).raceOptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "ASIAN",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).raceOptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "BLCAFR",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).raceOptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "NTHPCI",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).raceOptions[3],
            },
            {
              [OptionsProps.OPTION_VALUE]: "WHITE",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).raceOptions[4],
            },
          ],
          [UnitsProps.CLEAR_OPTION]: {
            [OptionsProps.OPTION_VALUE]: "declineToAnswer",
            [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).raceOptions[5],
          },
          [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: "Please choose race",
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "text-area",
                  [Props.TYPE]: Types.INPUT_TEXT_AREA,
                  [Props.MAX_LENGTH]: 250,
                  [Props.MIN_LENGTH]: 3,
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.householdStatic
                  ).otherRaceErrmsg,
                  [Props.ERROR_MESSAGE_LENGTH]: interimReportPageLabels(
                    language,
                    languageConstants.householdStatic
                  ).otherRaceErrMsgLength,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "is-citizen",
          [Props.LABEL]: {
            [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).citiBeforeQLabel,
            [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).citiTriggerQLabel,
            [Props.LABEL_OBJ_AFTER]: "?",
            [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).citizenHelp,
          },
          [Props.INLINE_HELP]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).citizenInhelp,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).citizenOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "ssn",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).ssnQLabel,
          [Props.INLINE_HELP]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).inlineHelpSSN,
          [Props.TYPE]: Types.INPUT_SSN,
          [Props.CHECKED]: TextProps.VALUE_FALSE,
          [InputProps.CLEAR_LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).ssnDntLabel,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).ssnErrLabel,
        },
        {
          [Props.NAME]: "buy-food",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).buyFoodLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).buyFoodOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "person-income",
          [Props.LABEL]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).personIncomLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.householdStatic
          ).personIncomeOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const ethinicityCodeMapper = {
  HSPLTN: "Hispanic/Latino",
  NNHSPL: "Non-Hispanic/Latino",
  DCL: "Decline to answer",
};

export const relationshipCodeMapper = {
  SPOUSE: "Spouse",
  MO: "Mother",
  FA: "Father",
  DAUGH: "Daughter",
  SON: "Son",
  BROTH: "Brother",
  SIST: "Sister",
  GRMOT: "GrandMother",
  GRFATH: "Grandfather",
  GRDAU: "Granddaughter",
  GRSON: "Grandson",
  OTHREL: "Other",
};

export const raceCodeMapper = {
  AMIALN: "American Indian",
  NTHPCI: "Pacific Islander",
  WHITE: "White",
  BLCAFR: "Black/African American",
  ASIAN: "Asian",
  Declinetoanswer: "",
};

export const genderCodeMapper = {
  MALE: "Male",
  FEMALE: "Female",
};

export const unearnedIncomeSummaryTemplate = {
  id: "unearned-income-summary",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .unEarnedIncomeMenuTitle, //'Income',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).unEarnedIncomeTitle, //"About your household's income",
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  subsections: [
    {
      id: "unearned-combined-income",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      questions: [
        {
          [Props.NAME]: "types",
          [Props.LABEL]:
            "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "TAFDCS",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RSDI",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "SSI",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "VETBEN",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[3],
            },
            {
              [OptionsProps.OPTION_VALUE]: "UNEMPL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[4],
            },
            {
              [OptionsProps.OPTION_VALUE]: "WRKCMP",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[5],
            },
            {
              [OptionsProps.OPTION_VALUE]: "INTINC",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[6],
            },
            {
              [OptionsProps.OPTION_VALUE]: "MILPE",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[7],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RETFND",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[8],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RRRET",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[9],
            },
            {
              [OptionsProps.OPTION_VALUE]: "LIHEAP",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[10],
            },
            {
              [OptionsProps.OPTION_VALUE]: "RENTAL",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[11],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CHSDOR",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[12],
            },
            {
              [OptionsProps.OPTION_VALUE]: "ALIMNY",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[13],
            },
            {
              [OptionsProps.OPTION_VALUE]: "CONTR",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[14],
            },
            {
              [OptionsProps.OPTION_VALUE]: "OTHER",
              [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unearnedIncomeoptions[15],
            }
          ],

          [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).utilitiesClearOption,
        },
      ],
      subsections: [
        {
          id: "unearned-combined-income-subsection",
          title: "John Doe (8.Aug.1967)",
          header: "John Doe's (8.Aug.1967) income and benefits",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "tanf-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).TANFTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "TAFDCS",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) TANF?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) TANF?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these TANF?",
                unitLabel: "What is the frequency of John Doe's (8.Aug.1967) TANF?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherTANFLabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "social-security-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).rsdiTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RSDI",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addRDILabel, //'Add another RSDI'
            },
            {
              [Props.NAME]: "ssi-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).SSITargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "SSI",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherSSILabel, //'Add another SSI'
            },
            {
              [Props.NAME]: "veterans-benefits-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBEN", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).veteranBenefitsTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "VETBEN",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
            },
            {
              [Props.NAME]: "unemployment-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unEmploymentTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "UNEMPL",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) Unemployment?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmpCompensationLabel, //'Add another Unemployment'
            },
            {
              [Props.NAME]: "WRKCMP-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).workerCompTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "WRKCMP",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) Workman's Compensation?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) Workman's Compensation??",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these Workman's Compensation??",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) Workman's Compensation??",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unEmpCompensationLabel, //'Add another Unemployment'
            },
            {
              [Props.NAME]: "interest-dividends-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "INTINC",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).interestDividendTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).interestDividendTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "military-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "MILPE",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).militaryTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).militaryTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "pension-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RETFND",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).pensionTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "railroad-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RRRET",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).railroadTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).railroadTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "hud-assistance-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "LIHEAP",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).hudTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).hudTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "land-rental-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "RENTAL",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).landTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).landTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).landTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).landTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "child-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CHSDOR",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).childSupportTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSupportTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSupportTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSupportTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSupportCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "alimony-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "ALIMNY",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).alimonyTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).alimonyTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "contribution-gross-income-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue,
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]: "CONTR",
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967)" +
                    interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).contributionTargetValue +
                    "?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967)" +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionTargetValue +
                  "?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionTargetValue +
                  "?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) " +
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).contributionTargetValue +
                  "?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionCompensationLabel, //"Add another Worker's compensation"
            },
            {
              [Props.NAME]: "other-income-benified-0",
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).otherTargetValue,
              [OptionsProps.TABLE_DSC]: "OTHER",
              [Props.TYPE]: Types.REPEATABLE_QUESTION,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of John Doe's (8.Aug.1967) other income or benefits?",
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                employerLabel:
                  "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                unitLabel:
                  "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                [Props.TYPE]: Types.UNEARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsPerLabel,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions, //['Month','Every two weeks', 'Week'],
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).questionButtonLabel, //"Add another Other"
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
};

export const interimHouseHoldQuestions = [
  {
    [Props.NAME]: "firstName",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).houseFirstName, //"What is this person's first name?",
    [Props.TYPE]: Types.SPECIAL_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).firstErrmsg, //'Please enter first name'
    [Props.MAX_LENGTH]: 15,
  },
  {
    [Props.NAME]: "middleName",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).houseMiddleLabel, //"What is this person's middle name?",
    [Props.TYPE]: Types.SPECIAL_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_FALSE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).houseMiddleName, //'Please enter middle name'
    [Props.MAX_LENGTH]: 15,
  },
  {
    [Props.NAME]: Names.LAST_NAME,
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).houseLastNameLabel, //"What is this person's last name?",
    [Props.TYPE]: Types.SPECIAL_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).enterLastname, //'Please enter last name'
    [Props.MAX_LENGTH]: 20,
  },
  {
    [Props.NAME]: Names.SUFFIX,
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).suffixLabel, //'Suffix',
    [Props.TYPE]: Types.SELECT,
    [SelectProps.OPTIONS]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
  },
  {
    [Props.NAME]: "relationship",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).relationQlabel, // "What is this person's relationship to you?",
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: [
      {
        [OptionsProps.OPTION_VALUE]: "SPOUSE",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).spouseDisplay, //'Spouse'
      },
      {
        [OptionsProps.OPTION_VALUE]: "MO",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).motherDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "FA",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).faterDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "DAUGH",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).daughterDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "SON",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).sonDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "BROTH",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).brotherDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "SIST",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).sisterDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "GRMOT",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).grandMaDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "GRFATH",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).grandFaDisplay,
      },
      {
        [OptionsProps.OPTION_VALUE]: "GRDAU",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).grandDaugther,
      },
      {
        [OptionsProps.OPTION_VALUE]: "GRSON",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).grandSon,
      },
      {
        [OptionsProps.OPTION_VALUE]: "OTHREL",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).otherDisplay,
      },
    ],
    [OptionsProps.OPTIONS_PER_ROW]: 2,
  },
  {
    [Props.NAME]: "dateOfBirth",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).houseDobLabel, //"What is this person's date of birth?",
    [Props.TYPE]: Types.DATE,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).enterDOB,
  },
  {
    [Props.NAME]: "biological-sex",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).personGenLabel,
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).genderOptios,
    [OptionsProps.OPTIONS_PER_ROW]: 2,
  },
  {
    [Props.NAME]: "ethnicity",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).labelEthnicity,
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: [
      {
        [OptionsProps.OPTION_VALUE]: "HSPLTN",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).hisPanicOption, //'Hispanic/Latino'
      },
      {
        [OptionsProps.OPTION_VALUE]: "NNHSPL",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).nonhisPanicOption, //'Non-Hispanic/Latino'
      },
    ],
    [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).declineToAnswerLabel,
    [Props.REQUIRED]: TextProps.VALUE_FALSE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: "Please choose ethnicity",
  },
  {
    [Props.NAME]: "race",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).raceLabel,
    [Props.TYPE]: Types.CHECKBOX,
    [OptionsProps.OPTIONS]: [
      {
        [OptionsProps.OPTION_VALUE]: "AMIALN",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).raceOptions[0],
      },
      {
        [OptionsProps.OPTION_VALUE]: "ASIAN",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).raceOptions[1],
      },
      {
        [OptionsProps.OPTION_VALUE]: "BLCAFR",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).raceOptions[2],
      },
      {
        [OptionsProps.OPTION_VALUE]: "NTHPCI",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).raceOptions[3],
      },
      {
        [OptionsProps.OPTION_VALUE]: "WHITE",
        [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).raceOptions[4],
      }
    ],
    [UnitsProps.CLEAR_OPTION]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).raceOptions[5],
    [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
    [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
    [Props.REQUIRED]: TextProps.VALUE_FALSE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: "Please choose race",
    [OptionsProps.CONDITIONALS]: [
      {
        [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
        [OptionsProps.CONDITIONAL_QUESTIONS]: [
          {
            [Props.NAME]: "text-area",
            [Props.TYPE]: Types.INPUT_TEXT_AREA,
            [Props.MAX_LENGTH]: 250,
            [Props.MIN_LENGTH]: 3,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).otherRaceErrmsg,
            [Props.ERROR_MESSAGE_LENGTH]: interimReportPageLabels(
              language,
              languageConstants.householdStatic
            ).otherRaceErrMsgLength,
          },
        ],
      },
    ],
  },
  {
    [Props.NAME]: "is-citizen",
    [Props.LABEL]: {
      [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citiBeforeQLabel,
      [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citiTriggerQLabel,
      [Props.LABEL_OBJ_AFTER]: "?",
      [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citizenHelp,
    },
    [Props.INLINE_HELP]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).citizenInhelp,
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).citizenOptions,
    [OptionsProps.OPTIONS_PER_ROW]: 2,
  },
  {
    [Props.NAME]: "ssn",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).ssnQLabel,
    [Props.INLINE_HELP]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).inlineHelpSSN,
    [Props.TYPE]: Types.INPUT_SSN,
    [Props.CHECKED]: TextProps.VALUE_FALSE,
    [InputProps.CLEAR_LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).ssnDntLabel,
    [Props.REQUIRED]: TextProps.VALUE_FALSE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).ssnErrLabel,
  },
  {
    [Props.NAME]: "buy-food",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).buyFoodLabel,
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).buyFoodOptions,
    [OptionsProps.OPTIONS_PER_ROW]: 2,
  },
  {
    [Props.NAME]: "person-income",
    [Props.LABEL]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).personIncomLabel,
    [Props.TYPE]: Types.RADIO,
    [OptionsProps.OPTIONS]: interimReportPageLabels(
      language,
      languageConstants.householdStatic
    ).personIncomeOptions,
    [OptionsProps.OPTIONS_PER_ROW]: 2,
  },
  // {
  //   [Props.TYPE]: Types.TEXT,
  //   isCustomHTML: true,
  //   [TextProps.VALUE]:
  //     "<span>" +
  //     interimReportPageLabels(language, languageConstants.householdStatic)
  //       .helpTextSSN1 +
  //     "</span>" +
  //     "<span>" +
  //     interimReportPageLabels(language, languageConstants.householdStatic)
  //       .helpTextSSN2 +
  //     "</span>" +
  //     "<span><b>" +
  //     interimReportPageLabels(language, languageConstants.householdStatic)
  //       .helpTextSSN3 +
  //     "</b></span>" +
  //     "<span>" +
  //     interimReportPageLabels(language, languageConstants.householdStatic)
  //       .helpTextSSN4 +
  //     "</span>",
  // },
  // {
  //   [Props.TYPE]: Types.TEXT,
  //   [TextProps.VALUE]: interimReportPageLabels(
  //     language,
  //     languageConstants.householdStatic
  //   ).helpTextSSN5,
  // },
  // {
  //   [Props.TYPE]: Types.TEXT,
  //   [TextProps.VALUE]: interimReportPageLabels(
  //     language,
  //     languageConstants.householdStatic
  //   ).helpTextSSN6,
  // },
];
