import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import Cleave from "cleave.js";
import _ from "lodash";

import * as Constants from "../../utils/constants/controls";
import OptionItem from "./optionItem";
import {
  addStringIf,
  mergeIfPresent,
  tryCall,
  validateAria,
  isNone,
} from "./utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import * as TextProps from "../../utils/constants/text";

class DTAInputSubmit extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    // container
    id: PropTypes.string,
    type: PropTypes.oneOf([
      Constants.INPUT_TYPE_SSN,
      Constants.INPUT_ALPHA_TEXT,
      Constants.SPECIAL_TEXT,
      Constants.INPUT_TYPE_MONEY,
      ...Constants.INPUT_TYPES,
    ]),
    placeholder: PropTypes.string,
    // input masking styles
    blocks: PropTypes.arrayOf(PropTypes.number),
    delimiter: PropTypes.string,
    numericOnly: PropTypes.bool,
    prefix: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // clear
    clearLabel: PropTypes.string,
    showClear: PropTypes.bool,
  };
  static defaultProps = {
    ...CommonDefaults,
    // container
    type: "text",
    placeholder: "",
    // one-way data bindings
    value: "", // make always controlled, to prevent uncontrolled <-> controlled switching errors
    // clear
    clearLabel: "None",
    showClear: TextProps.VALUE_FALSE,
    maxLength: PropTypes.number,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    this.state = {
      _isCleared: TextProps.VALUE_FALSE,
      _value: props.value,
    };
    this._handleChangeHelper = this._handleChangeHelper.bind(this);
  }

  render() {
    const {
        name,
        className,
        error,
        disabled,
        required,
        showClear,
        clearLabel,
        id: inputId,
        type,
        maxLength,
        placeholder,
        value,
      } = this.props,
      clearInfoId = this.nextUniqueId(),
      ariaLabels = this.props["aria-labelledby"],
      inputAriaLabels = ariaLabels,
      // use provided aria labels, but falls back to the input id because the clear option item
      // always needs to have an aria-labelled by associating it with the label for the input
      clearAriaLabels = ariaLabels || inputId;
    return (
      <div className={addStringIf(className, "dta-custom-input")}>
        {showClear && (
          <span id={clearInfoId} className="sr-only">
            Check this checkbox in order to clear the previous input.
          </span>
        )}
        {this._buildControl(
          name,
          inputId,
          type,
          placeholder,
          inputAriaLabels,
          error,
          disabled,
          required,
          maxLength,
          value
        )}
        {showClear &&
          this._buildClear(
            name,
            clearAriaLabels,
            clearInfoId,
            clearLabel,
            error,
            disabled
          )}
      </div>
    );
  }

  componentDidMount() {
    this.initializeElement(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // we do not rebuild when the blocks prop changes because the
    // blocks prop is an array. Since the === operator in javascript checks
    // for identity of the objects, we need to implement custom dirty-checking
    // to ensure that we do not unnecessarily rebuild. Since this seems to be
    // a relatively uncommon case, we will defer on this additional complexity
    const didAnyFormattingChange = _.some(
      ["type", "delimiter", "numericOnly", "prefix"],
      (property) => {
        return this.props[property] !== nextProps[property];
      }
    );
    if (didAnyFormattingChange) {
      this.rebuildElement(nextProps);
    }
    if (this.props.value !== nextProps.value) {
      this.setState({
        _isCleared: !nextProps.value,
      });
      // consider this separately from the previous conditional so do NOT do else if
      // props are very frequently updated, even while the user is typing. We only
      // want to update the raw value when we are trying to clear the input
      // Also, the case where we have an initial value is taken care of by the fact
      // that we do set the raw value when initializing
      if (isNone(nextProps.value)) {
        this.setValue("");
      }
    }
  }

  componentWillUnmount() {
    this.cleanupElement();
  }

  _buildControl(
    name,
    id,
    type,
    placeholder,
    ariaLabels,
    isError,
    isDisabled,
    required,
    maxLength,
    value
  ) {
    const componentOptions = this._componentOptionsForType(type);
    const zipvalue =
      name === "address" ||
      name === "mailingAddress" ||
      name === "residentialAddress" ||
      name === "is-also-mailing"
        ? value
        : undefined;
    const className = addStringIf(
      isError,
      "dta-input dta-input--expand-width",
      "dta-input--error"
    );
    return (
      <div
        className={
          "dta-custom-input__item" +
          (type === Constants.INPUT_TYPE_MONEY
            ? " dta-custom-input__item--is-money"
            : "") +
          (type === "search" ? " dta-custom-input__item--is-search" : "")
        }
      >
        <input
          type={type}
          {
            ...componentOptions /* put after type to allow overriding */
          }
          id={id}
          name={name}
          placeholder={placeholder}
          className={className}
          aria-invalid={
            isError ? TextProps.VALUE_STR_TRUE : TextProps.VALUE_STR_FALSE
          }
          aria-labelledby={validateAria(ariaLabels)}
          required={required}
          disabled={isDisabled}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          onInput={this._handleInput}
          onBlur={this._handleBlur}
          onFocus={this._handleFocus}
          maxLength={maxLength}
          value={zipvalue}
          ref={(el) => (this.inputEl = el)}
        />
      </div>
    );
  }

  _buildClear(name, ariaLabels, clearInfoId, clearLabel, isError, isDisabled) {
    return (
      <ul className="dta-custom-input__item dta-custom-input__item--auto-width pad-left">
        <OptionItem
          {...Constants.RADIO_CLASSES}
          name={name}
          key={this.nextUniqueId()}
          aria-labelledby={ariaLabels}
          aria-describedby={clearInfoId}
          value=""
          text={clearLabel}
          type={
            "checkbox" /* use a checkbox for better screen reader 'checked' description */
          }
          onChange={this._handleClear}
          checked={
            this.props.data.isChecked
              ? TextProps.VALUE_TRUE
              : this.state._isCleared
          }
          disabled={isDisabled}
          error={isError}
          className={Constants.RADIO_CLASSES.optionClass}
          controlClass={`dta-radio__control--without-indicator ${Constants.RADIO_CLASSES.controlClass}`}
        />
      </ul>
    );
  }

  _handleClear = () => {
    if (
      this.props !== undefined &&
      this.props.data !== undefined &&
      this.props.data.isChecked !== undefined
    ) {
      this.setState({ _isCleared: TextProps.VALUE_TRUE });
    }
    if (this.props.type === Constants.INPUT_TYPE_SSN) {
      PropTypes.ssnError = 0;
    }
    this.props.data.isChecked = TextProps.VALUE_FALSE;
    this.setValue("");
    this._handleChangeHelper("");
  };

  _handleInput = (evt) => {
    const inputValue = evt.target.value;
    if (
      this.props !== undefined &&
      this.props.data !== undefined &&
      this.props.data.isChecked !== undefined
    ) {
      this.props.data.isChecked = TextProps.VALUE_FALSE;
    }
    let value;
    if (
      this.props.type === Constants.INPUT_ALPHA_TEXT ||
      this.props.type === Constants.INPUT_ALPHANUMERIC_TEXT || this.props.type === Constants.SPECIAL_TEXT
    ) {
      value = !evt.target.validity.patternMismatch
        ? this.getValue()
        : this.state.lastValue;
      this.setValue(value);
    } else if (this.props.type === Constants.INPUT_ALPHANUMERIC_SPACE_TEXT) {
      value = inputValue.replace(/[^\x19-\x7f]*/g, "");
      this.setValue(value);
    } else if (this.props.type === Constants.INPUT_TYPE_SSN) {
      PropTypes.ssnError = 1;
      if (!evt.target.validity.patternMismatch) {
        PropTypes.ssnError = 0;
      }
      value = this.getValue();
    } else if (this.props.type === "email") {
      PropTypes.emailError = 1;
      if (!evt.target.validity.patternMismatch) {
        PropTypes.emailError = 0;
      }
      value = this.getValue();
    } else if (this.props.type === "pebt") {
      value = this.getValue();
    } else {
      value = this.getValue();
    }
    this.setState({ _isCleared: value === "" });
    this._handleChangeHelper(value);
  };

  // this is the debounced helper function
  _handleChangeHelper(value) {
    tryCall(this.props.onChange, value);
  }

  _handleFocus = () => {
    tryCall(this.props.onFocus, this.getValue());
  };

  _handleBlur = () => {
    tryCall(this.props.onBlur, this.getValue());
  };

  // Helpers
  // -------

  _componentOptionsForType(type) {
    // We use the pattern attribute to force some mobile browsers to show a numberic keypad.
    // And we add the delimiter to the pattern to avoid the screen saying "invalid data"
    const delimiter = this.props.delimiter,
      delimiterRegex = delimiter ? _.escapeRegExp(delimiter) : "",
      pattern = delimiterRegex
        ? `[0-9|,|\\s|\\-|${delimiterRegex}]*`
        : "[0-9|,|\\s|\\-]*",
      forNumberKeyboard = { type: "tel", pattern: pattern },
      alphaTextType = { pattern: "^[a-zA-Z][a-zA-Z ]*" },
      specialTextType = { pattern: "^(?! )([a-zA-Z.'\\- ]+)$" },
      alphanumericTextType = { pattern: "^[a-zA-Z0-9]*" },
      ssnType = {
        pattern:
          "^(?!219-09-9999|078-05-1120)(?!666|000)\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$",
      },
      emailType = {
        pattern:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
      };
    switch (type) {
      case "number":
        // need to override to tel because of a WebKit bug for number type input
        // https://bugs.chromium.org/p/chromium/issues/detail?id=346270
        return forNumberKeyboard;
      case "tel":
        return { pattern: pattern };
      case Constants.INPUT_TYPE_SSN:
        return ssnType;
      case Constants.INPUT_TYPE_MONEY:
        return forNumberKeyboard;
      case Constants.INPUT_ALPHA_TEXT:
        return alphaTextType;
        case Constants.SPECIAL_TEXT:
          return specialTextType;
      case "email":
        return emailType;
      case Constants.INPUT_ALPHANUMERIC_TEXT:
        return alphanumericTextType;
      case Constants.INPUT_ALPHANUMERIC_SPACE_TEXT:
        return alphanumericTextType;
      default:
        return {};
    }
  }

  _formatOptionsForType(type) {
    let options;
    switch (type) {
      case "number":
        options = { numericOnly: TextProps.VALUE_TRUE };
        break;
      case "tel":
        options = {
          delimiter: " ",
          blocks: [3, 3, 4],
          numericOnly: TextProps.VALUE_TRUE,
        };
        break;
      case "pebt":
        options = {
          blocks: [10, 8],
          prefix: "6008751375",
          numericOnly: TextProps.VALUE_TRUE,
        };
        break;
      case Constants.INPUT_TYPE_SSN:
        options = {
          delimiter: "-",
          blocks: [3, 2, 4],
          numericOnly: TextProps.VALUE_TRUE,
        };
        break;
      case Constants.INPUT_TYPE_MONEY:
        options = {
          numericOnly: TextProps.VALUE_TRUE,
          numeralPositiveOnly: TextProps.VALUE_TRUE,
          numeral: TextProps.VALUE_TRUE,
          numeralThousandsGroupStyle: "thousand",
        };
        break;
      default:
        options = {};
    }
    mergeIfPresent(this.props.blocks, "blocks", options);
    mergeIfPresent(this.props.delimiter, "delimiter", options);
    mergeIfPresent(this.props.numericOnly, "numericOnly", options);
    mergeIfPresent(this.props.prefix, "prefix", options);
    return options;
  }

  // Library wrappers
  // ----------------

  rebuildElement(props) {
    const oldCleave = this._cleave;
    this._cleave = this._initializeElementHelper(props);
    this._cleanupElementHelper(oldCleave);
  }

  initializeElement(props) {
    this._cleave = this._initializeElementHelper(props);
  }

  cleanupElement() {
    this._cleanupElementHelper(this._cleave);
  }

  setValue(value) {
    this._setValue(value, this._cleave);
  }

  getValue() {
    return this._getValue(this._cleave);
  }

  _initializeElementHelper(props) {
    let cleave;
    if (this.inputEl) {
      const options = this._formatOptionsForType(props.type);
      // just use a vanilla input element if no need for formatting because Cleave does not
      // correctly process values for display when no formatting is required of it
      if (!_.isEmpty(options)) {
        cleave = new Cleave(this.inputEl, options);
      }
      this._setValue(props.value, cleave);
    }
    return cleave;
  }

  _cleanupElementHelper(cleaveToDestroy) {
    if (cleaveToDestroy) {
      cleaveToDestroy.destroy();
    }
  }

  _setValue(value, cleave) {
    if (cleave) {
      cleave.setRawValue(value);
    } else {
      this.inputEl.value = value;
    }
    this.setState({
      lastValue: value,
    });
  }

  _getValue(cleave) {
    return cleave ? cleave.getRawValue() : this.inputEl.value;
  }
}

export default DTAInputSubmit;
