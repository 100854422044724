import _ from "lodash";
import * as TextProps from "../../utils/constants/text";
import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import * as Types from "../../utils/constants/types";

import { ReportChangePageLabels } from "./reportChangeText";
import * as languageConstants from "../../utils/constants/constants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import { interimHouseHoldQuestions } from "../recertInterimReport/recertInterimReportMapData";
import { recertificationPageLabels } from "../recertification/recertificationText";
import { interimReportPageLabels } from "../recertInterimReport/recertInterimReportText";
import {
  incomeSectionMultilanguageLabels,
  resourcesSectionMultilanguageLabels
} from "../applySnap/applySnapText";


let language = helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

if(language === null || language=== languageConstants.langOptSel) {
  language = Types.ENGLISH;
}

export function retriveSections(module) {
  let sections = [];
  switch (module) {
    case "household":
      sections = houseHoldSections;
      break;
    case "income":
      sections = incomeSections;
      break;
    case "child-support":
      sections = childSupportSections;
      break;
    case "shelter":
      sections = shelterSections;
      break;
    case "medical":
      sections = expenseMedicalExpenseSections;
      break;
    case "Dependentcarecosts":
      sections = dependentCareSections;
      break;
    case "authrep":
      sections = authRepSections;
      break;
    case "attendance":
      sections = attendanceSections;
      break;
    case "resources-summary":
      sections = resourceSections;  
      break;
    default:
      sections = [];
  }
  return sections;
}

const houseHoldSections = [
  {
    id: "household",
    title: "Household Members",
    header: "About My Household",
    completed: null,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  }
]

export const signInSubmit = {
  id: "sign-and-submit",
  title: ReportChangePageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).signSubmitLabel, //'Sign & submit',
  header: ReportChangePageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).signSubmitHeader, //'Sign & submit',
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE
}

export const householdAddMemberTemplate = [
  {
    id: "household-member",
    completed: TextProps.VALUE_FALSE,
    title: ReportChangePageLabels(language, languageConstants.householdStatic)
      .houseMemberTitle,
    header: ReportChangePageLabels(language, languageConstants.householdStatic)
      .houseMemberTitle,
    subHeader:
      "<p>" +
      ReportChangePageLabels(language, languageConstants.householdStatic)
        .houseMemberSubHeader1 +
      "</p>" +
      "<p>" +
      ReportChangePageLabels(language, languageConstants.householdStatic)
        .houseMemberSubHeader2 +
      "</p>",
    hiddenFromNav: TextProps.VALUE_TRUE,
    navigateToPrimaryScreen: TextProps.VALUE_TRUE,
    questions: _.cloneDeep(interimHouseHoldQuestions),
  },
];

export const incomeSections = [
  {
    id: "income",
    title: ReportChangePageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeMenuTitle, //'Income',
    header: ReportChangePageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    subsections: [
      {
        id: "combined-income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "WAGES",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SELEMP",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BOARD",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[2],
              },
            ],
            [OptionsProps.TARGET_VALUE]: [],
            [OptionsProps.CLEAR_OPTION]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "combined-incomesubsection",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "job-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).jobIncomeTargetValue,
                [OptionsProps.TABLE_DSC]: "WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "job-income-0",

                  amountLabel: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeAmountLabel,
                  employerLabel: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeEmployerLabel,
                  unitLabel: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeUnitLabel,
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).jobIncomeLabel,

                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).labelOtherJobIncome, //'Add another Wage'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Self-Employment?",
                    [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfGross_Label,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).adsSelfEmployee, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "room-and-board-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workStudyTargetValue,
                [OptionsProps.TABLE_DSC]: "BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Room and Board Income?",
                    [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Room and Board Income?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Room and Board Income?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Room and Board Income?",
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).roomAndBoardIncomeLabel,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherRoomAndBoardIncome, //'Add another Work Study'
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        // title: ReportChangePageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).earnedIncomeMenuTitle, //'Income summary',
        header: ReportChangePageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).earnedIncomeTitle, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
];

export const incomeSummaryTemplate = {
  id: "income-summary",
  // title: ReportChangePageLabels(
  //   language,
  //   languageConstants.earnedIncomeStatic
  // ).earnedIncomeMenuTitle, //'Income summary',
  header: ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
    .earnedIncomeTitle, //'Income summary',
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
};

export const IncomeSectionTemplate = {
  id: "combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "TAFDCS",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SELEMP",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WAGES",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RSDI",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SSI",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "VETBPR",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UNEMPL",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WRKCMP",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "INTINC",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MILPE",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETFND",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RRRET",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "LIHEAP",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RENTAL",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[11],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHSDOR",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[12],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ALIMNY",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[13],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONTR",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[14],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BOARD",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unearnedIncomeoptions[15],
        },
      ],
      [OptionsProps.TARGET_VALUE]: [],
      [OptionsProps.ANSWER_VALUE]: [],
    },
  ],
  subsections: [
    {
      id: "combined-incomesubsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "job-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).jobIncomeTargetValue,
          [OptionsProps.TABLE_DSC]: "WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "job-income-0",

            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeAmountLabel,
            employerLabel: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeEmployerLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeUnitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).jobIncomeLabel,

            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).labelOtherJobIncome, //'Add another Wage'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Self-Employment?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).selfGross_Label,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).adsSelfEmployee, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "room-and-board-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BOARD", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).workStudyTargetValue,
          [OptionsProps.TABLE_DSC]: "BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) room and board income?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) room and board income?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these room and board income?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) room and board income?",
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).roomAndBoardIncomeLabel,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherRoomAndBoardIncome, //'Add another Work Study'
        },
        {
          [Props.NAME]: "tanf-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TAFDCS", // ,ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).TANFTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) TANF?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) TANF?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these TANF?",
            unitLabel: "What is the frequency of John Doe's (8.Aug.1967) TANF?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherTANFLabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "social-security-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).rsdiTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these wages?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addRDILabel, //'Add another RSDI'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherSSILabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "veterans-benefits-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBPR", // ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).veteranBenefitsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "VETBPR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Workman's Compensation?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Workman's Compensation??",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Workman's Compensation??",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Workman's Compensation??",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unEmpCompensationLabel, //'Add another Unemployment'
        },
        {
          [Props.NAME]: "workers-compensation-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).workmanTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).workmanTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).workmanTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).workmanTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).workmanTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workmanCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "interest-dividends-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "INTINC", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).interestDividendTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).interestDividendTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).interestDividendTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).interestDividendCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MILPE", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).militaryTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).militaryTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).militaryCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETFND", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).pensionTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).pensionTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).pensionCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "railroad-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RRRET", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).railroadTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).railroadTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).railroadCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "hud-assistance-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "LIHEAP", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).hudTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).hudTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).hudTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).hudCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).landTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).landTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).landTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).landCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "child-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).childSupportTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).childSupportTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).childSupportCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ALIMNY", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).alimonyTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).alimonyTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).alimonyCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "contribution-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONTR", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).contributionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967)" +
                ReportChangePageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).contributionTargetValue +
                "?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967)" +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) " +
              ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).contributionTargetValue +
              "?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).contributionCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //ReportChangePageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) other income or benefits?",
              [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).questionButtonLabel, //"Add another Other"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

// Child support

export const childSupportSections = [
  {
    id: "child-support",
    title: ReportChangePageLabels(language, languageConstants.childSupport)
      .childSupportSideBar, //'Sign & submit',
    header: ReportChangePageLabels(language, languageConstants.childSupport)
      .childSupportSummaryHeader, //'Sign & submit',
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    disabled: TextProps.VALUE_FALSE,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "child-support-amount",
        title: "John Doe (8.Aug.1967)",
        header: ReportChangePageLabels(language, languageConstants.childSupport)
          .childSupportSummaryHeader, //'Tell us about your Child support costs',
        questions: [
          {
            [Props.NAME]: "child-support-cost-1",
            [Props.LABEL]:
              "What is the child support expense for John Doe (8.Aug.1967)?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.childSupport,
              null,
              null,
              null
            ).perLabel,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.childSupport,
              null,
              null,
              null
            ).unitsOptions,
            [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            enabledClickAction: "",
            enabledDoneAction: "",
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "childsupport-expenses",
        // title:ReportChangePageLabels(
        //   language,
        //   languageConstants.earnedIncomeStatic
        // ).earnedIncomeMenuTitle, //'Income summary',
        header: ReportChangePageLabels(language, languageConstants.childSupport)
          .childSupportSummaryHeader,
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
  },
];

export const childSummaryTemplate = {
  id: "childsupport-expenses",
  title: ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryTitle, //'Income summary',
  header: ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const expenseChildSupportTemplate = {
  id: "child-support-amount",
  title: "John Doe (8.Aug.1967)",
  header: ReportChangePageLabels(language, languageConstants.childSupport)
    .childSupportSummaryHeader, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "child-support-cost-1",
      [Props.LABEL]:
        "What is the child support expense for John Doe (8.Aug.1967)?",
      [Props.ADD_DELETE_AND_DONE]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.UNITS,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.childSupport,
        null,
        null,
        null
      ).perLabel,
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.childSupport,
        null,
        null,
        null
      ).unitsOptions,
      [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const shelterSections = [
  {
    id: "shelter",
    title: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).shelterAndUtilitySideMenuTitle,
    header: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).shelterAndUtilityHeading,
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "shelter-expenses-section",
        title: "Shelter Expenses",
        header: "Shelter Expenses",
        subHeader:
          "Please include all shelter expenses that you are responsible for even if you are behind or not able to pay.",
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "type",
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.shelterAndutility,
              null,
              null
            ).interimShelterQlabel,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "RENT",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MTGPRN",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPINS",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPTAX",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SECMTG",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEXP",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEEQ",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONFEE",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOASFE",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseQOptions[10],
              },
            ],
            [UnitsProps.CLEAR_OPTION]: ReportChangePageLabels(
              language,
              languageConstants.shelterAndutility,
              null,
              null
            ).idontPayShelter,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.shelterAndutility,
              null,
              null
            ).houseCostErrmsg, //'Please choose housing costs'
          },
        ],
        subsections: [
          {
            id: "shelter-expense-subsection",
            title: "Shelter Expenses",
            header: "Shelter Expenses",
            subHeader:
              "Please include all shelter expenses that you are responsible for even if you are behind or not able to pay.",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "rent",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).rentTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).rentQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherRentLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "mortgage",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).mortgageTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).mortageQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).mortageErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anothermortgageLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "property-insurance",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propInsTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propInsQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).propInsErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherpropInsLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "property-tax",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).propTaxTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).propTaxQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).propTaxErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherpropTaxLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "second-mortgage",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).secondMortgageTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).secMortgageQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).secondMortgageErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anothersecondMortgageLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "homeless-expense",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homelessExpTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).homelessQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).homelessExpErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherhomelessExpLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "home-equity",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).homeEquityTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).equityLoanQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).homeEquityErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherhomeEquityLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "condo-fee",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).condoTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).condoQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).condoErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anothercondoLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "house-owner-association",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseOwnerTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).houseAssQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).houseOwnerErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherhouseOwnerLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "other",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).otherTargetValue, //"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).otherQuestion, //"How much is your household's rent?",
                  [Props.TYPE]: Types.SHELTER_EXPENSE,
                  [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null
                  ).perLabel, //'per',
                  [UnitsProps.UNITS]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).otherErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  ReportChangePageLabels(
                    language,
                    languageConstants.shelterAndutility,
                    null,
                    null,
                    null
                  ).anotherOtherLabel, //"Add another rent"
                canBeDeleted: TextProps.VALUE_TRUE,
                isInterimShelter: TextProps.VALUE_TRUE,
              },
            ],
          },
          {
            id: "utility-expenses-section",
            title: "Utility Expenses",
            header: "Utility Expenses",
            subHeader:
              "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them.",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "type",
                [Props.LABEL]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).interimUtilityQlabel,
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: [
                  {
                    [OptionsProps.OPTION_VALUE]: "heatFlag",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[0],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "acFlag",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[1],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "electricOrGasFlag",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[3],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "phoneFlag",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[4],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "payWaterSewage",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[5],
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: "payGarbage",
                    [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
                      language,
                      languageConstants.shelterAndutility,
                      null,
                      null
                    ).utilityQOptions[6],
                  }
                ],
                [UnitsProps.CLEAR_OPTION]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).idontPayUtility,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                  language,
                  languageConstants.shelterAndutility,
                  null,
                  null
                ).houseCostErrmsg, //'Please choose housing costs'
              },
              {
                [Props.NAME]: "is-received-liheap",
                [Props.LABEL]: ReportChangePageLabels(
                  language,
                  languageConstants.householdStatic
                ).receivedLieapLabel,
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]: ReportChangePageLabels(
                  language,
                  languageConstants.householdStatic,
                  null,
                  null,
                  null
                ).receivedLieapOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "shelter-utility-summary",
        // title: "Shelter & Utility Expenses", //'Income',
        header: "Shelter & Utility Expenses Summary", //"About your household's income",
        completed: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        hiddenFromNav: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
];

export const expenseHousingCostTemplate = {
  id: "housing-costs",
  title: ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
    .houseUtlilityLabel,
  header: ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
    .expenseHeader,
  subHeader: ReportChangePageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).expenseSubHeader,
  questions: [
    {
      [Props.NAME]: "utilities",
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).anyUtilQcost, //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,

      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "payHeat",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payAC",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payACFee",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "elgas",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "hasPhone",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[4],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
    {
      [Props.NAME]: "type",
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseQLabel, //'What type of housing cost does your household have?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "RENT",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MORTGAGE",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PROPERTY_TAX",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "HOME_INSURANCE",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONDO_FEE",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[4],
        },
      ],
      [UnitsProps.CLEAR_OPTION]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseCostErrmsg, //'Please choose housing costs'
    },
  ],
  subsections: [
    {
      id: "housing-cost-detail",
      title: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseUtlilityLabel, //'Housing and Utility Costs',
      header: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseHeader, //'Tell us about your housing costs',
      subHeader: ReportChangePageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
      completed: TextProps.VALUE_FALSE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).rentTargetValue, //"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).mortageTargetValue, //"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageunitBetween, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageQoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).propertyTaxTargetValue, //"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropBetween, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).homeInsurTargetValue, //"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).condoFeeTargetValue, //"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condofeeCostoptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            ReportChangePageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoButtonLabel, //"Add another condo fee"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const shelterBaseQuestions = [
  {
    [Props.NAME]: "rent",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).rentTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).rentQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).rentErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherRentLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "mortgage",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).mortgageTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).mortageQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).mortageErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anothermortgageLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "property-insurance",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).propInsTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).propInsQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).propInsErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherpropInsLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "property-tax",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).propTaxTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).propTaxQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).propTaxErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherpropTaxLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "second-mortgage",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).secondMortgageTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).secMortgageQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).secondMortgageErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anothersecondMortgageLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "homeless-expense",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).homelessExpTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).homelessQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).homelessExpErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherhomelessExpLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "home-equity",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).homeEquityTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).equityLoanQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).homeEquityErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherhomeEquityLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "condo-fee",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).condoTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).condoQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).condoErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anothercondoLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "house-owner-association",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).houseOwnerTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).houseAssQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).houseOwnerErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherhouseOwnerLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
  {
    [Props.NAME]: "other",
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null
    ).otherTargetValue, //"Rent",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).otherQuestion, //"How much is your household's rent?",
      [Props.TYPE]: Types.SHELTER_EXPENSE,
      [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null
      ).perLabel, //'per',
      [UnitsProps.UNITS]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).unitsOptions, //['Week', 'Month', 'Year'],

      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: ReportChangePageLabels(
        language,
        languageConstants.shelterAndutility,
        null,
        null,
        null
      ).otherErrMsg, // 'Please enter rent'
    },
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]: ReportChangePageLabels(
      language,
      languageConstants.shelterAndutility,
      null,
      null,
      null
    ).anotherOtherLabel, //"Add another rent"
    canBeDeleted: TextProps.VALUE_TRUE,
    isInterimShelter: TextProps.VALUE_TRUE,
  },
];

export const expenseMedicalExpenseSections = [
  {
  id: "medical",
  title: ReportChangePageLabels(
    language,
    languageConstants.medicalExpenses,
    null,
    null,
    null
  ).medicalTitle, //'Medical Costs',
  header: ReportChangePageLabels(
    language,
    languageConstants.medicalExpenses,
    null,
    null,
    null
  ).medicalHeader, //"Tell us about your medical costs",
  selectionMade: null,
  skipsubSectionNavigation: TextProps.VALUE_TRUE,
  }
];

export const medicalExpenseKeys = {
  MDENT: {
    flag: "medicalDentalFlag",
    amount: "medicalDentalAmt",
    unit: "medicalDentalFreqCd",
    code: "medicalDentalDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).doctorOrDentistOptions,
  },
  HSPTL: {
    flag: "hospitalFlag",
    amount: "hospitalAmt",
    unit: "hospitalFreqCd",
    code: "hospitalDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).hospitalOrNursingHomeOptions,
  },
  MEDCTN: {
    flag: "medicationFlag",
    amount: "medicationAmt",
    unit: "medicationFreqCd",
    code: "medicationDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).prescriptionDrugsOptions,
  },
  OTCMED: {
    flag: "otcmedFlag",
    amount: "otcmedAmt",
    unit: "otcmedFreqCd",
    code: "otcmedDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).overTheCounterMedicineOptions,
  },
  HINSPR: {
    flag: "healthInsuranceFlag",
    amount: "healthInsurancePremAmt",
    unit: "healthInsurancePremFreqCd",
    code: "healthInsuranceDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).healthInsurancePremiumsOptions,
  },
  MSUPPL: {
    flag: "medsupplyFlag",
    amount: "medsupplyAmt",
    unit: "medsupplyFreqCd",
    code: "medsupplyDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).medicalSuppliesOptions,
  },
  MDCOSH: {
    flag: "medCoShareFlag",
    amount: "medCoShareAmt",
    unit: "medCoShareFreqCd",
    code: "medCoshareDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).medicaidCostSharingOptions,
  },
  DHP: {
    flag: "dentureHearingAidFlag",
    amount: "dentureHearingAidAmt",
    unit: "dentureHearingAidFreqCd",
    code: "dentureHearingAidDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).denturesOrHearingAidsOptions,
  },
  GLSSES: {
    flag: "glassesFlag",
    amount: "glassesAmt",
    unit: "glassesFreqCd",
    code: "glassesDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).prescriptionEyeGlasesOptions,
  },
  TRSPRT: {
    flag: "transportFlag",
    amount: "transportAmt",
    unit: "transportFreqCd",
    code: "transportDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).transportationOrLoadingForMedicalServicesOptions,
  },
  HHACC: {
    flag: "hhaccFlag",
    amount: "hhaccAmt",
    unit: "hhaccFreqCd",
    code: "hhaccDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).HudHomeHealthAideOptions,
  },
  DOG: {
    flag: "dogFlag",
    amount: "dogAmt",
    unit: "dogFreqCd",
    code: "dogDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).serviceAnimalOptions,
  },
  OTHER: {
    flag: "otherFlag",
    amount: "otherAmt",
    unit: "otherFreqCd",
    code: "otherDataChangeCode",
    desc: ReportChangePageLabels(
      language,
      languageConstants.medicalExpenses,
      null,
      null,
      null
    ).otherMedicalExpenseOptions,
  },
};

export const medicalExpenseObj = {
  medicalDentalFlag: false,
  hospitalFlag: false,
  medicationFlag: false,
  otherFlag: false,
  medicalExpenseAmount: null,
  medicalExpenseFrequencyCode: null,
  providerCareAmount: null,
  providerCareFrequencyCode: null,
  transportationPayFlag: false,
  transportationAmount: null,
  transportationFrequencyCode: null,
  drivetoApptPharmFlag: false,
  drivetoApptPharmCount: null,
  transportDetails: null,
  dataChangeCode: null,
  otcmedFlag: false,
  healthInsuranceFlag: false,
  medsupplyFlag: false,
  medCoShareFlag: false,
  dentureHearingAidFlag: false,
  glassesFlag: false,
  transportFlag: false,
  hhaccFlag: false,
  dogFlag: false,
  otcmedAmt: null,
  healthInsurancePremAmt: null,
  medsupplyAmt: null,
  medCoShareAmt: null,
  dentureHearingAidAmt: null,
  glassesAmt: null,
  transportAmt: null,
  hhaccAmt: null,
  dogAmt: null,
  medicalDentalAmt: null,
  hospitalAmt: null,
  otherAmt: null,
  otcmedFreqCd: null,
  healthInsurancePremFreqCd: null,
  medsupplyFreqCd: null,
  medCoShareFreqCd: null,
  dentureHearingAidFreqCd: null,
  glassesFreqCd: null,
  transportFreqCd: null,
  hhaccFreqCd: null,
  dogFreqCd: null,
  medicationFreqCd: null,
  medicalDentalFreqCd: null,
  hospitalFreqCd: null,
  otherFreqCd: null,
  medicationAmt: null,
  hospitalDataChangeCode: null,
  medicationDataChangeCode: null,
  otcmedDataChangeCode: null,
  healthInsuranceDataChangeCode: null,
  medsupplyDataChangeCode: null,
  medCoshareDataChangeCode: null,
  dentureHearingAidDataChangeCode: null,
  glassesDataChangeCode: null,
  transportDataChangeCode: null,
  hhaccDataChangeCode: null,
  dogDataChangeCode: null,
  otherDataChangeCode: null,
  medicalDentalDataChangeCode: null,
};

export const dependentCareSections = [
  {
    //newIndex 7
    //index 5
    id: "Dependentcarecosts",
    parentId: "Dependentcarecosts",
    title: ReportChangePageLabels(
      language,
      languageConstants.dependentCareCostStatic
    ).dependentCareCostTitle, //'Expenses',
    header: ReportChangePageLabels(
      language,
      languageConstants.dependentCareCostStatic
    ).dependentCareCostHeader,
    subHeader: ReportChangePageLabels(
      language,
      languageConstants.dependentCareCostStatic
    ).dependentCareCostSubHeader,
    questions: [
      {
        [Props.NAME]: "caregivers",
        [Props.LABEL]: [
          {
            [Props.LABEL_OBJ_BEFORE]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjbefore1,
            [Props.LABEL_OBJ_TRIGGER]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjtrigger1, //'transport',
            [Props.LABEL_OBJ_AFTER]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjAfter1, //' the dependent(s) to and/or from a care provider.',
            [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveHelp1,
            // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
          },
          {
            [Props.LABEL_OBJ_BEFORE]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjbefore2,
            [Props.LABEL_OBJ_TRIGGER]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjtrigger2, //'transport',
            [Props.LABEL_OBJ_AFTER]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveObjAfter2, //' the dependent(s) to and/or from a care provider.',
            [Props.LABEL_OBJ_HELP]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).careGiveHelp2,
            // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
          },
        ],
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).questionClearOption, // 'None',
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "recipient-of-care-1",
                [Props.LABEL]:
                  "Who does John Doe (8.Aug.1967) pay dependent care for?",
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
              },
            ],
          },
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "recipient-of-care-2",
                [Props.LABEL]:
                  "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
                [OptionsProps.CLEAR_OPTION]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).questionClearOption, //'None'
              },
            ],
          },
        ],
      },
    ],
    subsections: [
      {
        id: "kids-and-adults-marty",
        parentId: "Dependentcarecosts",
        title: "Marty Smith (2.Jun.1994)",
        header: "Caring for Marty Smith (2.Jun.1994)",
        subHeader: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
        questions: [
          {
            [Props.NAME]: "caring-kids-adults-cost-1",
            [Props.LABEL]:
              "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).costBetween,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).freqTripoptions,
            [OptionsProps.TARGET_VALUE]: { unit: "Month" },
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          {
            [Props.NAME]: "if-pays-transportation-1",
            [Props.LABEL]:
              "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).yesnoOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).targetValueYes,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "transportation-cost-1",
                    [Props.LABEL]:
                      "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                      language,
                      languageConstants.dependentCareCostStatic
                    ).costBetween,
                    [UnitsProps.UNITS]: ReportChangePageLabels(
                      language,
                      languageConstants.dependentCareCostStatic
                    ).freqTripoptions,
                    [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "drive-to-provider-1",
            [Props.LABEL]:
              "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).yesnoOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).targetValueYes,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "driving-address-1",
                    [Props.LABEL]:
                      "How many addresses, does John drive to medical appointments or the pharmacy?",
                    [Props.TYPE]: Types.NUMBERS,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                      language,
                      languageConstants.dependentCareCostStatic
                    ).noofAppointment,
                  },
                ],
              },
            ],
          },
        ],
        subsections: [
          {
            id: "kids-adult-multiple-address",
            parentId: "Dependentcarecosts",
            title: "Marty Smith (2.Jun.1994)",
            header: "Caring for Marty Smith (2.Jun.1994)",
            subHeader: ReportChangePageLabels(
              language,
              languageConstants.dependentCareCostStatic
            ).expenseSubHeader,
            //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: "address",
                [Props.LABEL]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).addressProvider, //'What is the address of the provider?',
                [Props.TYPE]: Types.ADDRESS,
                [Props.ERROR_MESSAGE]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).addressErrmsg,
                [Props.ERROR_MESSAGE_FOR_CHAR]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).citiErrmsg,
                [Props.ERROR_MESSAGE_FOR_ADDRESS]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).addressErrmsg,
                [Props.ERROR_MESSAGE_FOR_ZIPCODE]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).zipCodeErrmsg,
              },
              {
                [Props.NAME]: "frequency-trips-1",
                [Props.LABEL]:
                  "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).costBetween, //'per',
                [UnitsProps.UNITS]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).freqTripoptions, //['Week', 'Month', 'Year'],
                [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE_LENGTH]: ReportChangePageLabels(
                  language,
                  languageConstants.dependentCareCostStatic
                ).tripCountErrMsgLen,
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
    active: TextProps.VALUE_TRUE,
  },
];
export const dependentCareAddress = [
  {
    id: "kids-adult-multiple-address",
    parentId: "Dependentcarecosts",
    title: "Marty Smith (2.Jun.1994)",
    header: "Caring for Marty Smith (2.Jun.1994)",
    subHeader: ReportChangePageLabels(
      language,
      languageConstants.dependentCareCostStatic
    ).expenseSubHeader,
    //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
    hiddenFromNav: TextProps.VALUE_TRUE,
    questions: [
      {
        [Props.NAME]: "address",
        [Props.LABEL]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).addressProvider, //'What is the address of the provider?',
        [Props.TYPE]: Types.ADDRESS,
        [Props.ERROR_MESSAGE]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_CHAR]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).citiErrmsg,
        [Props.ERROR_MESSAGE_FOR_ADDRESS]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_ZIPCODE]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).zipCodeErrmsg,
      },
      {
        [Props.NAME]: "frequency-trips-1",
        [Props.LABEL]:
          "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
        [Props.TYPE]: Types.UNITS,
        [UnitsProps.IN_BETWEEN]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).costBetween, //'per',
        [UnitsProps.UNITS]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).freqTripoptions, //['Week', 'Month', 'Year'],
        [OptionsProps.TARGET_VALUE]: { unit: "Month" },
        [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE_LENGTH]: ReportChangePageLabels(
          language,
          languageConstants.dependentCareCostStatic
        ).tripCountErrMsgLen,
      },
    ],
  }
]
export const  medicalExpenseTemplate = {
  id: "medical-expenses-type",
  parentsId: "medical-expenses",
  title: "John Doe (8.Aug.1967)",
  header: "Medical cost for John Doe (8.Aug.1967)",
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions: [
    {
      [Props.NAME]: 'medicalExpenseType',
      [Props.LABEL]: ReportChangePageLabels(
        language,
        languageConstants.medicalExpenses,
        null,
        null,
        null
      ).medicalExpenseType,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: 'MDENT',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).doctorOrDentistOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HSPTL',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).hospitalOrNursingHomeOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MEDCTN',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionDrugsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'OTCMED',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).overTheCounterMedicineOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HINSPR',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).healthInsurancePremiumsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MSUPPL',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicalSuppliesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'MDCOSH',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicaidCostSharingOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'DHP',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).denturesOrHearingAidsOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'GLSSES',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionEyeGlasesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'TRSPRT',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).transportationOrLoadingForMedicalServicesOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'HHACC',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).HudHomeHealthAideOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'DOG',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).serviceAnimalOptions,
        },
        {
          [OptionsProps.OPTION_VALUE]: 'OTHER',
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).otherMedicalExpenseOptions,
        }
      ]
    }
  ],
  subsections: [
    {
      id: "medical-expenses-filter",
      parentsId: "medical-expenses",
      title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
      subHeader:
        ReportChangePageLabels(
          language,
          languageConstants.medicalExpenses,
          null,
          null,
          null
        ).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "MDENT",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDENT",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).doctorOrDentistOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).doctorOrDentistOptions,
          [OptionsProps.TABLE_DSC]: "MDENT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MDENT-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).doctorOrDentistOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HSPTL",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HSPTL",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).hospitalOrNursingHomeOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).hospitalOrNursingHomeOptions,
          [OptionsProps.TABLE_DSC]: "HSPTL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HSPTL-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).hospitalOrNursingHomeOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MEDCTN",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MEDCTN",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionDrugsOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionDrugsOptions,
          [OptionsProps.TABLE_DSC]: "MEDCTN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MEDCTN-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).prescriptionDrugsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "OTCMED",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTCMED",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).overTheCounterMedicineOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).overTheCounterMedicineOptions,
          [OptionsProps.TABLE_DSC]: "OTCMED",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "OTCMED-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).overTheCounterMedicineOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HINSPR",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HINSPR",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).healthInsurancePremiumsOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).healthInsurancePremiumsOptions,
          [OptionsProps.TABLE_DSC]: "HINSPR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HINSPR-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).healthInsurancePremiumsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MSUPPL",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MSUPPL",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicalSuppliesOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicalSuppliesOptions,
          [OptionsProps.TABLE_DSC]: "MSUPPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MSUPPL-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).medicalSuppliesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "MDCOSH",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDCOSH",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicaidCostSharingOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).medicaidCostSharingOptions,
          [OptionsProps.TABLE_DSC]: "MDCOSH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "MDCOSH-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).medicaidCostSharingOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "DHP",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DHP",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).denturesOrHearingAidsOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).denturesOrHearingAidsOptions,
          [OptionsProps.TABLE_DSC]: "DHP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "DHP-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).denturesOrHearingAidsOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "GLSSES",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "GLSSES",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionEyeGlasesOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).prescriptionEyeGlasesOptions,
          [OptionsProps.TABLE_DSC]: "GLSSES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "GLSSES-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).prescriptionEyeGlasesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "TRSPRT",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRSPRT",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).transportationOrLoadingForMedicalServicesOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).transportationOrLoadingForMedicalServicesOptions,
          [OptionsProps.TABLE_DSC]: "TRSPRT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "TRSPRT-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).transportationOrLoadingForMedicalServicesOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "HHACC",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HHACC",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).HudHomeHealthAideOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).HudHomeHealthAideOptions,
          [OptionsProps.TABLE_DSC]: "HHACC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "HHACC-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).HudHomeHealthAideOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "DOG",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DOG",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).serviceAnimalOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).serviceAnimalOptions,
          [OptionsProps.TABLE_DSC]: "DOG",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "DOG-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).serviceAnimalOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        },
        {
          [Props.NAME]: "OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TARGET_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.VALUE]: [{ amount: '', unit: '' }],
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).otherMedicalExpenseOptions,
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: ReportChangePageLabels(
            language,
            languageConstants.medicalExpenses,
            null,
            null,
            null
          ).otherMedicalExpenseOptions,
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "OTHER-medical-expenses",
            amountLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).amountLabel,
            unitLabel: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitLabel,
            [Props.LABEL]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).otherMedicalExpenseOptions,
            [Props.TYPE]: Types.MEDICAL_EXPENSES,
            [UnitsProps.UNITS]: ReportChangePageLabels(
              language,
              languageConstants.medicalExpenses,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON]: TextProps.VALUE_TRUE
        }
      ],
      completed: TextProps.VALUE_FALSE,
    },
    {
      id: "medical-expenses",
      parentId: "medical-expenses",
      title: ReportChangePageLabels(
        language,
        languageConstants.medicalExpenses,
        null,
        null,
        null
      ).medicalTitle, //'Medical Costs',
      header: ReportChangePageLabels(
        language,
        languageConstants.medicalExpenses,
        null,
        null,
        null
      ).medicalHeader, //"Tell us about your medical costs",
      selectionMade: null,
      completed: TextProps.VALUE_FALSE,
      disabled:TextProps.VALUE_FALSE,
      skipsubSectionNavigation: true,
    }
  ],
  completed: TextProps.VALUE_FALSE,
  disabled: TextProps.VALUE_FALSE,
  active: TextProps.VALUE_TRUE,
}
    
export const iDontPayUtility = ['I do not pay for any utility expenses','No pago ning�n gasto de servicios p�blicos']
export const iDontPayShelter = ['I do not pay for any shelter expenses','No pago ning�n gasto de alojamiento']    

export const resourceSections = [
  {
    id: "resources-summary",
    title: resourcesSectionMultilanguageLabels(language).resourceTitle, //'Resources',
    header: resourcesSectionMultilanguageLabels(language).resourcesSummaryHeader, //"About your household's Resources",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    srApplication: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "combined-resources",
        title: "namePlaceholder",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "CASH",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SAVING",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHECK",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "STOCKS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BONDS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETMNT",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "TRUST",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PPDFUN",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
              }
            ],
            // [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-resources-subsection",
            title: "namePlaceholder",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel  +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
            originalQuestions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel  +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_TRUE,
                [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
          },
        ],
          completed: TextProps.VALUE_FALSE,
        },
        {
          id: "resources-summary",
        header: resourcesSectionMultilanguageLabels(language).resourcesSummaryTitle,
          completed: TextProps.VALUE_FALSE,
          hiddenFromNav: TextProps.VALUE_TRUE,
          skipsubSectionNavigation: TextProps.VALUE_TRUE,
        },
        ],
  }  
]

export const ResourcesSectionTemplate =   {
  id: "combined-resources",
  title: "namePlaceholder",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]: resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "CASH",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SAVING",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHECK",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "STOCKS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BONDS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETMNT",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "TRUST",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PPDFUN",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
        }
      ],
      // [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
    },
  ],
  subsections: [
    {
      id: "combined-resources-subsection",
      title: "namePlaceholder",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
      originalQuestions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_TRUE,
          [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
}

export const resourcesSummaryTemplate = {
  id: "resources-summary",
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader,
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
};

export const authRepSections = [
  {
    id: "authrep",
    title:recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
  ).authTitle,
    header:recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
  ).authTitle, 
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections:[],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  }
]
export const attendanceSections = [
  {
    id: "attendance",
    title: "School Attendance", 
    header: "School Attendance",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections:[],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  }
]

export const resourceBaseQuestions = [
  {
    [Props.NAME]: "cash-on-hand-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[0],
    [OptionsProps.TABLE_DSC]: "CASH",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_ONE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[0],
  },
  {
    [Props.NAME]: "saving-accounts-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[1],
    [OptionsProps.TABLE_DSC]: "SAVING",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
      bankErrMsg:
      resourcesSectionMultilanguageLabels(language).bankErrMsg,
      jointAccounterrMsg:
      resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
      jointAccountHolderErrMsg:
      resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_TWO,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[1], 
  },
  {
    [Props.NAME]: "checking-accounts-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[2],
    [OptionsProps.TABLE_DSC]: "CHECK",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
      bankErrMsg:
      resourcesSectionMultilanguageLabels(language).bankErrMsg,
      jointAccounterrMsg:
      resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
      jointAccountHolderErrMsg:
      resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_TWO,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[2], 
  },
  {
    [Props.NAME]: "stocks-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[3],
    [OptionsProps.TABLE_DSC]: "STOCKS",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
      bankBrokageErrMsg:
      resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_THREE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAdditionalButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[3],
  },
  {
    [Props.NAME]: "bonds-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[4],
    [OptionsProps.TABLE_DSC]: "BONDS",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
      bankBrokageErrMsg:
      resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_THREE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[4],
  },
  {
    [Props.NAME]: "retirement-accounts-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[5],
    [OptionsProps.TABLE_DSC]: "RETMNT",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
      bankBrokageErrMsg:
      resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_THREE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[5],
  },
  {
    [Props.NAME]: "trust-funds-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[6],
    [OptionsProps.TABLE_DSC]: "TRUST",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_ONE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[6],
  },
  {
    [Props.NAME]: "prepaid-burial-account-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[7],
    [OptionsProps.TABLE_DSC]: "PPDFUN",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_ONE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[7],
  },
  {
    [Props.NAME]: "other-liquid-resources-repeatable",
    [Props.TYPE]: Types.REPEATABLE_QUESTION,
    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
    [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
    resourcesSectionMultilanguageLabels(language)
        .combineResourcesoptions[8],
    [OptionsProps.TABLE_DSC]: "OTHER",
    [ControlConstants.REPEATABLE_QUESTION]: {
      [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).amountErrMsg,
    },
    resourcesSection: TextProps.VALUE_TRUE,
    templateType: Types.RESOURCES_TEMP_ONE,
    srForms: TextProps.VALUE_TRUE,
    [OptionsProps.TARGET_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [OptionsProps.ANSWER_VALUE]: [resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj],
    [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
      incomeSectionMultilanguageLabels(language)
        .addAnotherButtonLabel +
      " " +
      resourcesSectionMultilanguageLabels(language)
        .addcombineResourcesoptions[8],
  },
]