//Language constants for interim report pages
export const aboutMe = "aboutMePageLabels";
export const beforeYouStart = "beforeYouStartLabels";
export const contactInfo = "contactInfoLabels";
export const expeditedSnap = "expeditedSnap";
export const householdStatic = "houseHoldMembersLabels";
export const householdDynamic = "dynamicHouseHoldMembersLabels";
export const earnedIncomeStatic = "staticIncomeLabels";
export const earnedIncomeDynamic = "dynamicIncomeLabels";
export const earnedIncomeTypedCode = "dynamicTypeCodeIncomeLabels";
export const unearnedIncomeDynamic = "dynamicUnearnedIncomeLabels";
export const childSupportDynamic = "childSupportDynamic";
export const unearnedIncomeTypeCode = "dynamicTypeCodeUnearnedIncomeLabels";
export const childSupport = "childSupportExpenseLabels";
export const childSupportExpense = "childSupportExpense";
export const medicalExpenses = "medicalExpenses";
export const shelterAndutility = "shelterAndutilityLabels";
export const signAndSubmitSummary = "signAndSubmitSummaryLabels";
export const otherInfoPage = "dynamicOtherInfoPageLabels";
export const langOptSel = "select";
//Language constants for self-declare pages
export const selfDeclareExpenses = "selfDeclareExpenses";
export const utilityCosts = "utilityCosts";
export const shelterCosts = "shelterCosts";
export const medicalCosts = "medicalCosts";
export const selfDeclareExpensesSummary = "selfDeclareExpensesSummary";
// Language constants for recertification pages
export const nonCitizen = "nonCitizenPageLabels";
export const studentStatic = "studentsStaticPageLabels";
export const studentDynamic = "studentsDynamicPageLabels";
export const householdStaticRecert = "householdStaticRecertPageLabels";
export const householdInformation = "householdInformation";
export const householdDynamicRecert = "householdDynamicRecertPageLabels";
export const medicalCostDynamic = "medicalCostDynamicPageLabels";
export const dependentCareCostStatic = "dependentCareCostStaticPageLabels";
export const incomeExpensesRecert = "incomeExpensesRecertDynamicPageLabels";
export const signAndSubmitSummaryRecert =
  "signAndSubmitSummaryRecertPageLabels";

export const connectToDtaLogout =
  "Your case is now linked to your ACES DHR Self-Service Portal account. You must log back in for this change to take place.";
export const expensesRecert = "expenses"
export const summaryRecert = "summary"
export const authRep = "authRep"
// URL constants for pages
//pebhome
export const SNAP_BENEFITS_FORMERLY_FOOD_STAMPS =
  "https://www.mass.gov/snap-benefits-formerly-food-stamps";
export const MAP_EBT = "https://www.map-ebt.org";
export const DEPT_OF_TRANS_ASS_LOCATIONS =
  "https://dhr.alabama.gov/county-office-contact/";
export const HOW_CONNECT_DTA = "https://dhr.alabama.gov/county-office-contact/";
export const SNAP_VERIFICATION =
  "https://www.mass.gov/service-details/snap-verifications-what-information-you-need-to-provide";
export const APPLY_SNAP =
  "https://www.mass.gov/how-to/apply-for-snap-benefits-food-stamps";
//consumerInfo
export const SNAP_HOMEPAGE =
  "https://www.mass.gov/eohhs/consumer/basic-needs/food/snap/";
export const MAS_GOV = "https://www.mass.gov";
export const NEED_HELP = "https://feedingalabama.org/need-help/";
export const SUBMIT_DOCS_DEPT_TRANS_ASS =
  "https://www.mass.gov/how-to/submit-documents-to-the-department-of-transitional-assistance";
export const SUPPL_NUTRITION =
  "https://www.fns.usda.gov/snap/supplemental-nutrition-assistance-program-snap";
export const ALABAMA_URL = "https://dhr.alabama.gov/";
export const SITE_POLICIES =
  "https://oit.alabama.gov/resources/privacy-statements/";

export const TANF_Eligibility_en =
    "https://dhr.alabama.gov/wp-content/uploads/2022/07/2022_07_27_09_36_12.pdf";

export const TANF_Eligibility_es =
    "https://dhr.alabama.gov/wp-content/uploads/2019/10/DHR-FAD-2077.pdf";

export const FOOD_ASSISTANCE = "https://dhr.alabama.gov/food-assistance/";

export const FAMILY_ASSISTANCE = "https://dhr.alabama.gov/family-assistance/";

//logo constants

export const list_icon = "/list.png";
export const interview_icon = "/interview.png";
export const docs_icon = "/docs.png";
export const feeding_img = "/feeding.png";
export const groceries_icon = "/groceries.png";
export const arrow_icon = "/arrow.png";
export const carousel1_img = "/carousel1.png";
export const carousel2_img = "/carousel2.png";
export const carousel3_img = "/carousel3.png";
export const carousel4_img = "/carousel4.png";
export const carousel5_img = "/carousel5.png";
export const right_icon = "/right.png";
export const logo_icon = "/alabama-logo.png";
export const bell_icon = "/bell.png";
export const left_icon = "/left.png";
export const children_icon = "/children.png";
export const snap_icon = "/snap.png";
export const alabama = "/alabama-aces-logo.png";
export const footerDHRlogo = "/DHR Logo for Dark Backgrounds.png";
export const notification_icon = "/notification-bell.png";
export const DRAFT = "DRAFT";

// For Appointments
export const PREFERRED_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const PREFERRED_DAYS_WITH_SAME_DAY = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday","Same Day"];
export const PREFERRED_SPANISHDAYS_WITH_SAME_DAY = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes","Mismo día"];
export const PREFERRED_TIME_MERIDIANS = ["AM","PM"]

export const ALABAMA_COUNTY_OFFICES = "https://dhr.alabama.gov/county-office-contact/";