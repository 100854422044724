//Consumer Info Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";
const config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  providerMyClientsAction: "PROVIDER_MYCLIENTS_INFO_DATA",
  removeMultilanguageSelect: "PROVIDER_NO_LANGUAGE_SELECT",
  removeMultilanguageSelectGreeter: "PROVIDER_NO_LANGUAGE_SELECT_GREETER",
  multilanguageSelect: "PROVIDER_LANGUAGE_SELECT",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
};
/**
 * here getting providerMyClients details
 */
export function providerMyClientDetailsInfo(email) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        authToken: "authToken",
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
      timeout: 300000,
    };
    var payload = {
      authToken: "authToken",
      email: email,
      agencyID: 0,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.TigerContextURL + "/providerclients",
          payload,
          TigerOptions
        )
        .then((response) => {
          var clientsRespnse = formatClientsListForFilter(response.data);
          dispatch(providerMyClientDetailsAction(clientsRespnse));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          // console.log(error);
          // dispatch(serviceFailedAction(error.response.status));
          // reject(TextProps.VALUE_FALSE);
          var clientsRespnse = {};
          dispatch(providerMyClientDetailsAction(clientsRespnse));
          resolve(TextProps.VALUE_TRUE);
        });
    });
  };
}

function formatClientsListForFilter(clients) {
  if (clients !== null && clients.clientsList.length > 0) {
    let expiresCount = 0;
    let urgentCount = 0;
    let deterEligCount = 0;
    let unSubmittedCount = 0;
    clients.clientsList.map((client) => {
      if (client.expiringPSIFilterFlag === TextProps.VALUE_Y) {
        expiresCount++;
        client.filterExpiring = "expiring";
      }
      if (client.outstandingTaskFilterFlag === TextProps.VALUE_Y) {
        urgentCount++;
        client.filterUrgent = "urgent";
      }
      if (client.pendingCaseFilterFlag === TextProps.VALUE_Y) {
        deterEligCount++;
        client.filterElibility = "elibility";
      }
      if (client.documentStatusFilterFlag === TextProps.VALUE_Y) {
        unSubmittedCount++;
        client.filterUnsubmitted = "unsubmitted";
      }
    });
    let appCountObj = {
      options: [
        {
          optionValue: "expiring",
          applicationTypeCount: expiresCount,
          optionDisplay: "Expiring PSI",
        },
        {
          optionValue: "urgent",
          applicationTypeCount: urgentCount,
          optionDisplay: "Outstanding Tasks",
        },
        {
          optionValue: "elibility",
          applicationTypeCount: deterEligCount,
          optionDisplay: "Pending Cases",
        },
        {
          optionValue: "unsubmitted",
          applicationTypeCount: unSubmittedCount,
          optionDisplay: "Document Status",
        },
      ],
    };
    clients.applicationsCount = appCountObj;
    return clients;
  } else return clients;
}

// sending/storing to redux store
function providerMyClientDetailsAction(myClients) {
  return {
    type: actionTypes.providerMyClientsAction,
    payload: myClients,
  };
}

// if service failure to getting data
function serviceFailedAction(data) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: data,
  };
}

export function removeMultilanguageSelect() {
  return {
    type: actionTypes.removeMultilanguageSelect,
  };
}

export function removeMultilanguageSelectGreeter() {
  return {
    type: actionTypes.removeMultilanguageSelectGreeter,
  };
}

export function multilanguageSelect() {
  return {
    type: actionTypes.multilanguageSelect,
  };
}

export function saveRequestAccountAccessUserInfo(userObj) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        authToken: "authToken",
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(
        config.TigerContextURL + "/requestclientaccountaccess",
        userObj,
        TigerOptions
      )
      .then(function (data) {
        console.log(
          "requestclientaccountaccess success" + JSON.stringify(data)
        );
        // dispatch is not required because of no need render to UI
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}
