//Consumer Info Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import { filterData } from "../../utils/components/utilities/utils";
import * as TextProps from "../../utils/constants/text";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerActivityRequested: "CONSUMER_ACTIVITY_REQUESTED",
  getConsumerActivityRejected: "CONSUMER_ACTIVITY_REJECTED",
  getConsumerActivityFulfilled: "CONSUMER_ACTIVITY_FULFILLED",
  showSchedularPage: "SHOW_SCEDULAR_PAGE",
  showSchedularSnapPage: "SHOW_SCEDULAR_SNAP_PAGE",
  showSchedularCaseModelPage: "SHOW_SCEDULAR_CASE_MODEL_PAGE",
  hideSchedularPage: "HIDE_SCEDULAR_PAGE",
  appointmentReschedulePostSuccess: "APPOINTMENT_RESCHEDULE_FAW_SUCCESS",
  appointmentRescheduleCaseModelSuccess:
    "APPOINTMENT_RESCHEDULE_CASE_MODEL_SUCCESS",
  aptReschedulerServiceFailure: "APPOINTMENT_RESCHEDULER_SERVICE_FAILED",
  cancelAppointmentSuccess: "CANCEL_APPOINTMENT_SUCCESS",
  cancelAppointmentFailure: "CANCEL_APPOINTMENT_FAILURE",
  cancelDataLoading: "CANCEL_APPOINTMENT_DATA_LOADING",
  getAvailableDateAndTimesFailiure: "GET_DATE_TIMES_FAILIURE",
  getAvailableDateAndTimesSuccess: "GET_DATE_TIMES_SUCCESS",
  showApmntAlert: "SHOW_URGENT_APPOINTMENT_ALERT",
  hideApmntAlert: "HIDE_URGENT_APPOINTMENT_ALERT",
};

export function getConsumerActivityList(agencyId, startDate, endDate) {
  return (dispatch, getState) => {
    dispatch(getConsumerActivityRequestedAction());
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(config.TigerContextURL + "/activities/" + agencyId, TigerOptions)
      .then(function (activityListData) {
        if (agencyId !== null && agencyId !== undefined) {
          var consumerDataUserObj = JSON.parse(
            sessionStorage.getItem("loadConsumerUser")
          );
          sessionStorage.setItem("activityTabResp", TextProps.VALUE_STR_FALSE);
          if (
            consumerDataUserObj !== null &&
            consumerDataUserObj !== undefined
          ) {
            consumerDataUserObj["alerts"] = activityListData.data.alerts;
            sessionStorage.setItem(
              "loadConsumerUser",
              JSON.stringify(consumerDataUserObj)
            );
          }
          if (agencyId != null && agencyId != undefined) {
            dispatch(getConsumerActivityFulfilledAction(activityListData));
            if (startDate !== undefined && endDate !== undefined) {
              var filteredData = filterData(
                activityListData.data.alerts.appointments,
                startDate,
                endDate,
                "endTime"
              );
              var filteredDataRecert = filterData(
                activityListData.data.alerts.recertifications,
                startDate,
                endDate,
                "dueDate"
              );
              activityListData.data.alerts.appointments = filteredData;
              activityListData.data.alerts.recertifications =
                filteredDataRecert;
              dispatch(getConsumerActivityFulfilledAction(activityListData));
            }
          }
        }
      })
      .catch(function (error) {
        dispatch(getConsumerActivityRejectedAction());
      });
  };
}

//Action Dispatch
function getConsumerActivityRequestedAction() {
  return {
    type: actionTypes.getConsumerActivityRequested,
  };
}

function getConsumerActivityRejectedAction() {
  return {
    type: actionTypes.getConsumerActivityRejected,
  };
}

function getConsumerActivityFulfilledAction(userData) {
  return {
    type: actionTypes.getConsumerActivityFulfilled,
    payload: userData,
  };
}

export function showSchedularPage() {
  return {
    type: actionTypes.showSchedularPage,
  };
}

export function showSchedularSnapPage() {
  return {
    type: actionTypes.showSchedularSnapPage,
  };
}

export function showSchedularCaseModelPage() {
  return {
    type: actionTypes.showSchedularCaseModelPage,
  };
}

export function hideSchedularPage() {
  return {
    type: actionTypes.hideSchedularPage,
  };
}

export function showUrgentApmntAlert() {
  return {
    type: actionTypes.showApmntAlert,
  };
}

export function hideUrgentApmntAlert() {
  return {
    type: actionTypes.hideApmntAlert,
  };
}

//Appointment reschedular post call
export function appointmentReschedulePostCall(payload, caseType) {
  const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/appointment/rescheduleappointment";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (data) {
          if (caseType === "FAW") {
            dispatch(appointmentReschedulePostSuccess(data));
          } else if (caseType === "CASE_MODEL") {
            dispatch(appointmentRescheduleCaseModelSuccess(data));
          }
          resolve(data);
        })
        .catch(function (error) {
          dispatch(aptReschedulerServiceFailure(error));
          reject(error);
        });
    });
  };
}

function appointmentReschedulePostSuccess(data) {
  return {
    type: actionTypes.appointmentReschedulePostSuccess,
    payload: data,
  };
}

function appointmentRescheduleCaseModelSuccess(data) {
  return {
    type: actionTypes.appointmentRescheduleCaseModelSuccess,
    payload: data,
  };
}

function aptReschedulerServiceFailure(error) {
  return {
    type: actionTypes.aptReschedulerServiceFailure,
    payload: error,
  };
}

//cancel appointment post call
export function cancelAppoinmentPostCall(payload) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.cancelDataLoading,
    })
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/appointment/cancelappointment";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(cancelAppoinmentPostCallSuccess(response));
        })
        .catch(function (error) {
          dispatch(cancelAppoinmentPostCallSuccessFailure(error));
        });
    });
  };
}

function cancelAppoinmentPostCallSuccess(data) {
  return {
    type: actionTypes.cancelAppointmentSuccess,
    payload: data,
  };
}

function cancelAppoinmentPostCallSuccessFailure(error) {
  return {
    type: actionTypes.cancelAppointmentFailure,
    payload: error,
  };
}

// get appointment date and time slots
export function getAvailableDateAndTimes(agencyID, dueDate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url =
        config.TigerContextURL +
        "/appointment/availableappointmentslot/" +
        agencyID +
        "/" +
        `${dueDate}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(getAvailableDateAndTimesSuccess(response));
          return response;
        })
        .catch(function (error) {
          dispatch(getAvailableDateAndTimesFailiure(error));
        });
    });
  };
}

function getAvailableDateAndTimesSuccess(data) {
  return {
    type: actionTypes.getAvailableDateAndTimesSuccess,
    payload: data,
  };
}

function getAvailableDateAndTimesFailiure(error) {
  return {
    type: actionTypes.getAvailableDateAndTimesFailiure,
    payload: error,
  };
}
