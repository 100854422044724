import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import * as Props from "../../components/shared";
import * as ControlConstants from "../../constants/controls";
import { CommonTypes, CommonDefaults } from "../propTypes";
import ControlWrapper from "./controlWrapper";
import * as types from "../../constants/types";


const uniqueIdPrefix = "repeatable_q_";

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

/*
  RepeatableQuestion
  ------------------

  # Purpose:
  Creates a wrapper around a control type enabling the user to add addtional fields
  of the same type to provide multiple responses for a given question.
 */

class RepeatableQuestion extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ...CommonDefaults,
    // one-way data bindings
    value: [{}], // make always controlled, to prevent uncontrolled <-> controlled switching errors
  };

  constructor(props) {
    super(...arguments);

    enableUniqueIds(this);

    this.state = {
      value: this.props.value || [{}],
    };
  }

  componentWillMount() {
    this.state.value.forEach((val) => {
      if (!val.id) {
        val.id = _.uniqueId(uniqueIdPrefix);
      }
      // if month not selected in dropdown by defalut keeping month in various languages
      if (!val.unit) {
        val.unit = this.monthLabel(language);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  addQuestion = () => {
    let updatedValue = _.cloneDeep(this.props.value);
    if(updatedValue[0] && updatedValue[0].type && updatedValue[0].type === "resources"){
      switch(updatedValue[0].templateType){
        case types.RESOURCES_TEMP_ONE:
          updatedValue.push({
            amount: "",
            additionalInformation: "",
            id: _.uniqueId(uniqueIdPrefix),
          });
          break;
        case types.RESOURCES_TEMP_TWO:
          updatedValue.push({
            amount: "",
            bankName: "",
            accountnumber: "",
            jointAccount:"",
            jointAccountHolder:"",
            id: _.uniqueId(uniqueIdPrefix),
          });
          break;
        case types.RESOURCES_TEMP_THREE:
          updatedValue.push({
            amount: "",
            bankName: "",
            id: _.uniqueId(uniqueIdPrefix),
          });
          break;
        default:
          updatedValue.push({
            amount: "",
            id: _.uniqueId(uniqueIdPrefix),
            unit: this.monthLabel(language),
          });
          break;
      }

    }else{
      updatedValue.push({
        amount: "",
        id: _.uniqueId(uniqueIdPrefix),
        unit: this.monthLabel(language),
      });
    }

    this.setState({ value: updatedValue });
    this.props.onChange(updatedValue, {
      action: ControlConstants.REPEATABLE_QUESTION_ACTION_ADD,
      index: updatedValue.length - 1,
    });
  };

  removeQuestion = (index) => () => {
    let updatedValue = _.clone(this.props.value);
    let removed = updatedValue[index]
    const getModule = sessionStorage.getItem('module');
    let recertType = sessionStorage.getItem("recertType");

    let isNewValueRemoved = removed.code === ("new"||"NEW") ? true : false;
    if(getModule && getModule === "resources-summary"){
      removed.code = removed.datachangeCode === "NEW" ? "NEW" :"CHANGE";
      removed.datachangeCode = "REMOVE";
      removed.amount = null;
      if (removed.templateType === "ResourcesTemplateOne") {
        removed.additionalInformation = null;
      } else {
        removed.bankName = null;
        if (removed.templateType === "ResourcesTemplateTwo") {
          removed.accountnumber = null;
          removed.jointAccount = null;
          removed.jointAccountHolder = null;
        }
      }
    } else {
    removed.code = "REMOVE";
    }
    let combined = _.cloneDeep(updatedValue);
    //to remove the values alone
    if(getModule !== "resources-summary"){
    updatedValue[index] = combined;
    }
    // to remove whole question
    if(!recertType){
      updatedValue.splice(index, 1);
    }

    this.setState({ value: updatedValue });
    this.props.onChange(updatedValue, {
      action: ControlConstants.REPEATABLE_QUESTION_ACTION_REMOVE,
      index: index,
      combinedOj:isNewValueRemoved ? {} : combined
    });
  };

  onChange = (index) => (val) => {
    let updatedValue = _.clone(this.props.value);
    const savedId = this.props.value[index].id;

    updatedValue[index] = val;
    updatedValue[index].id = savedId;

    this.setState({ value: updatedValue });
    this.props.onChange(updatedValue, {
      action: ControlConstants.REPEATABLE_QUESTION_ACTION_UPDATE,
      index: index,
    });
  };

  renderQuestionRow(i, val) {
    const { error, errorMessage } = this.props.data.repeatedQuestion;
    return (
      <div>
        {!this.props.data.templateType && <div key={val.id} className={"repeatable-question__row "+this.props.data[ControlConstants.REPEATABLE_QUESTION_CLASS]}>
        <div
            className={
              i > 0 && !this.props.data.resourcesSection || this.props.data.canBeDeleted
                ? "width-85-container repeatable-question__row-control"
                : "repeatable-question__row-control"
            }
          >
            <ControlWrapper
              // labelHidden={i > 0 || this.props.data.employer !== undefined} //unhiding heading of add another question sets
              type={
                this.props.data[ControlConstants.REPEATABLE_QUESTION][
                  Props.TYPE
                ]
              }
              data={this.props.data[ControlConstants.REPEATABLE_QUESTION]}
              onChange={this.onChange(i)}
              value={val}
              error={error}
              errorMessage={errorMessage}
            />
          </div>
          {i > 0 && !this.props.data.resourcesSection || this.props.data.canBeDeleted ? (
            <div className="repeatable-question__row-remove">
              {i === 0 && this.props.data.isInterimShelter &&
                <label className="dta-form__label mining_list" style={{minHeight: '18px'}}></label>
              }
              <button
                type="button"
                className="dta-button dta-delete-button dta-button--outline-secondary"
                onClick={this.removeQuestion(i)}
              >
                <span>{this.removeLabel(language)}</span>
              </button>
            </div>
          ) : null}
        </div>}
        {this.props.data.templateType && <div key={val.id} className={"repeatable-question__row "+this.props.data[ControlConstants.REPEATABLE_QUESTION_CLASS]}>
        <div
            className={"repeatable-question__row-control"}
          >
            <div className="pure-g">
            <div className="pure-u-1-1 dta-units__item">
              <div className="pure-u-2-3 margin-top-half theme-colour">
              <b>
                {this.props.data.displayIncomeName}
              </b>
              </div>
              <div className="pure-u-1-3 text--right margin-bottom-half">
              {this.props.data.srForms && <button
                type="button"
                className="dta-button dta-delete-button dta-button--outline-secondary"
                onClick={this.removeQuestion(i)}
              >
                <span>{this.removeLabel(language)}</span>
              </button>}
              {i > 0 && !this.props.data.srForms && <button
                type="button"
                className="dta-button dta-delete-button dta-button--outline-secondary"
                onClick={this.removeQuestion(i)}
              >
                <span>{this.removeLabel(language)}</span>
              </button>}
            </div>
            </div>
            </div>
            <ControlWrapper
              type={
                this.props.data[ControlConstants.REPEATABLE_QUESTION][
                  Props.TYPE
                ]
              }
              data={this.props.data[ControlConstants.REPEATABLE_QUESTION]}
              onChange={this.onChange(i)}
              value={val}
              error={error}
              errorMessage={errorMessage}
            />
          </div>
        </div>}
        {this.props.data.canBeDeleted ? <hr className="divider" /> : null}
      </div>
    );
  }

  render() {
    return (
      <div className="repeatable-question">
        {this.state.value && this.state.value.length
          ? this.state.value.map((val, i) => {
              return this.renderQuestionRow(i, val);
            })
          : null}
        {!this.props.data[ControlConstants.HIDE_REPEATABLE_QUESTION_BUTTON] ? <button
          type="button"
          className="dta-button dta-button--link"
          onClick={this.addQuestion}
        >
          {this.props.data[ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]}
        </button> : ""}
      </div>
    );
  }
  /**
   * loading default as month in all languages
   */
  monthLabel(languageKey) {
    var months = ["Monthly", "Mensual", "Mês", "月", "Tháng", "Mwa"];
    switch (languageKey) {
      case EN:
        return months[0];
      case types.SPANISH:
        return months[1];
      case types.PORTUGUESE:
        return months[2];
      case types.CHINESE:
        return months[3];
      case types.VIETNAMESE:
        return months[4];
      case HI:
        return months[5];
      default:
        return months[0];
    }
  }

  removeLabel(languageKey) {
    var removeLabels = [
      "Remove",
      "Eliminar",
      "Excluir",
      "删除",
      "Xóa",
      "Retire",
    ];
    switch (languageKey) {
      case EN:
        return removeLabels[0];
      case types.SPANISH:
        return removeLabels[1];
      case types.PORTUGUESE:
        return removeLabels[2];
      case types.CHINESE:
        return removeLabels[3];
      case types.VIETNAMESE:
        return removeLabels[4];
      case HI:
        return removeLabels[5];
      default:
        return;
    }
  }
}

export default RepeatableQuestion;
