import * as languageConsts from "../../utils/constants/types";
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function otherInfoCodeMapper(language, name) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        FE04PR: name + " is pregnant",
        FE04UM:
          name +
          " still has a disability or illness that makes it hard to work",
        FE04ST:
          name +
          " is student at an approved school or a training program halftime or more.",
        FE04RT:
          name +
          " is participating in an approved refugee training program halftime or more.",
        FE04UT:
          "illness or disability may be temporary or permanent " +
          name +
          " still has a disability or illness that makes it hard to work",
        FE04US: name + " is homeless with no stable nighttime residence.",
        FE04CT:
          name +
          " is responsible for the care of a dependent child under age 6 or an incapacitated person.",
        FE04AD: name + " is participating in substance abuse treatment",
        FN14: name + " is participating in training at {Training_Provider}.",
      };
    case languageConsts.SPANISH:
      return {
        FE04PR: name + " está embarazada.",
        FE04UM:
          name +
          " aún tiene una discapacidad o enfermedad que le dificulta trabajar.",
        FE04ST:
          name +
          " es estudiante en una escuela autorizada o en un programa de capacitación medio tiempo o más.",
        FE04RT:
          name +
          " está participando en un programa de capacitación para refugiados autorizado durante medio tiempo o más.",
        FE04UT:
          "la enfermedad o discapacidad puede ser temporal o permanente " +
          name +
          " aún tiene una discapacidad o enfermedad que le dificulta trabajar.",
        FE04US: name + " no tiene vivienda ni una residencia nocturna estable.",
        FE04CT:
          name +
          "  es responsable del cuidado de un/a hijo/a dependiente menor de 6 años o de una persona discapacitada.",
        FE04AD:
          name +
          " está participando en un tratamiento para el abuso de sustancias.",
        FN14:
          name + " está participando en entrenamiento en {Training_Provider}.",
      };
    case languageConsts.PORTUGUESE:
      return {
        FE04PR: name + " está grávida",
        FE04UM:
          name +
          " ainda tem uma deficiência ou doença que dificulta o trabalho",
        FE04ST:
          name +
          " é aluno(a) de uma escola aprovada ou de um programa de treinamento meio período ou mais.",
        FE04RT:
          name +
          " está participando de um programa de treinamento de refugiados aprovado meio período ou mais.",
        FE04UT:
          "doença ou incapacidade pode ser temporária ou permanente " +
          name +
          " ainda tem uma deficiência ou doença que dificulta o trabalho",
        FE04US: name + "  não tem moradia, nem residência noturna estável.",
        FE04CT:
          name +
          " é responsável pelo cuidado de uma criança dependente com menos de 6 anos ou uma pessoa incapacitada.",
        FE04AD:
          name +
          " está participando do tratamento contra o abuso de substâncias.",
        FN14:
          name + "  está participando de treinamento no {Training_Provider}.",
      };

    case languageConsts.CHINESE: //chinees
      return {
        FE04PR: name + " 已怀孕",
        FE04UM: name + " 依旧残疾或生病，这令其很难工作",
        FE04ST:
          name + " 是在经批准学校就读的学生或有一半或更多时间在参加培训计划。",
        FE04RT: name + " 有一半或更多时间在参加经批准的难民培训计划。",
        FE04UT:
          "疾病或残疾可能是暂时的或永久性的 " +
          name +
          " 依旧残疾或生病，这令其很难工作",
        FE04US: name + " 无家可归，无稳定的夜间居所。",
        FE04CT: name + " 负责照顾未满 6 岁的受抚养子女或法律上无行为能力的人。",
        FE04AD: name + " 正在接受药物滥用治疗",
        FN14: name + " 在{Training_Provider}接受培训。",
      };
    case languageConsts.VIETNAMESE:
      return {
        FE04PR: name + " đang mang thai",
        FE04UM:
          name +
          " vẫn bị khuyết tật hoặc bệnh tật khiến cho khả năng làm việc khó khăn",
        FE04ST:
          name +
          " là sinh viên đang học được nửa thời gian hoặc hơn tại một trường hoặc chương trình đào tạo đã được phê duyệt",
        FE04RT:
          name +
          " đang tham gia được nửa thời gian hoặc hơn chương trình đào tạo cho người tị nạn đã được phê duyệt.",
        FE04UT:
          "bệnh hoặc khuyết tật có thể là tạm thời hoặc vĩnh viễn " +
          name +
          " vẫn bị khuyết tật hoặc bệnh tật khiến cho khả năng làm việc khó khăn.",
        FE04US:
          name + " là người vô gia cư, không có nơi ở vào ban đêm ổn định",
        FE04CT:
          name +
          " chịu trách nhiệm chăm sóc một đứa trẻ phụ thuộc dưới 6 tuổi hoặc một người mất năng lực.",
        FE04AD: name + " đang tham gia điều trị lạm dụng chất gây nghiện",
        FN14: name + " đang tham gia khóa đào tạo tại {Training_Provider}.",
      };
    case HI:
      return {
        FE04PR: name + " ansent",
        FE04UM:
          name +
          " toujou gen yon andikap oswa yon maladi ki fè li difisil pou l travay",
        FE04ST:
          name +
          " se elèv nan yon lekòl ki apwouve oswa yon pwogram fòmasyon mwatye tan oswa plis.",
        FE04RT:
          name +
          " ap patisipe nan yon pwogram fòmasyon pou refijye ki apwouve mwatye tan oswa plis.",
        FE04UT:
          "Maladi oswa andikap gen dwa tanporè oswa pèmanan " +
          name +
          " toujou gen yon andikap oswa yon maladi ki fè li difisil pou l travay.",
        FE04US: name + " sanzabri san yon rezidans lannwit ki estab.",
        FE04CT:
          name +
          " responsab pou swen yon timoun depandan ki poko gen 6 an oswa yon moun ki enkapab.",
        FE04AD: name + " ap patisipe nan tretman pou abi sibstans.",
        FN14: name + " ap patisipe nan fòmasyon nan {Founisè_Fòmasyon}.",
      };
    default:
      return;
  }
}
