import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

import icons from "../../utils/resources/images/icons.svg";
import * as TextProps from "../constants/text";

class DTAIcon extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    ariaHidden: PropTypes.bool,
  };

  constructor() {
    super();

    enableUniqueIds(this);
  }

  render() {
    const { name, label, className, ariaHidden } = this.props;
    const baseAttrs = {};

    if (ariaHidden) {
      baseAttrs["aria-hidden"] = TextProps.VALUE_STR_TRUE;
      baseAttrs["role"] = "presentation";
    }

    return (
      <span
        {...baseAttrs}
        className={`icon-wrapper icon-${name} ${className || ""}`}
      >
        <span
          className="sr-only"
          aria-hidden={TextProps.VALUE_STR_TRUE}
          id={this.nextUniqueId()}
        >
          {label || name}
        </span>
        <svg
          role="img"
          aria-labelledby={this.lastUniqueId()}
          className={`icon icon-${name} ${className || ""}`}
        >
          <use xlinkHref={`${icons}#${name}`} />
        </svg>
      </span>
    );
  }
}

export default DTAIcon;
