export const ZIPCODE = [
    {
        "CITY": "Moody",
        "ZIPCODE": "35004"
    },
    {
        "CITY": "Adamsville",
        "ZIPCODE": "35005"
    },
    {
        "CITY": "Adger",
        "ZIPCODE": "35006"
    },
    {
        "CITY": "Alabaster",
        "ZIPCODE": "35007"
    },
    {
        "CITY": "Alexander City",
        "ZIPCODE": "35010"
    },
    {
        "CITY": "Alexander City",
        "ZIPCODE": "35011"
    },
    {
        "CITY": "Allgood",
        "ZIPCODE": "35013"
    },
    {
        "CITY": "Alpine",
        "ZIPCODE": "35014"
    },
    {
        "CITY": "Alton",
        "ZIPCODE": "35015"
    },
    {
        "CITY": "Arab",
        "ZIPCODE": "35016"
    },
    {
        "CITY": "Baileyton",
        "ZIPCODE": "35019"
    },
    {
        "CITY": "Bessemer",
        "ZIPCODE": "35020"
    },
    {
        "CITY": "Bessemer",
        "ZIPCODE": "35021"
    },
    {
        "CITY": "Bessemer",
        "ZIPCODE": "35022"
    },
    {
        "CITY": "Hueytown",
        "ZIPCODE": "35023"
    },
    {
        "CITY": "Blountsville",
        "ZIPCODE": "35031"
    },
    {
        "CITY": "Bon Air",
        "ZIPCODE": "35032"
    },
    {
        "CITY": "Bremen",
        "ZIPCODE": "35033"
    },
    {
        "CITY": "Brent",
        "ZIPCODE": "35034"
    },
    {
        "CITY": "Brierfield",
        "ZIPCODE": "35035"
    },
    {
        "CITY": "Brookside",
        "ZIPCODE": "35036"
    },
    {
        "CITY": "Burnwell",
        "ZIPCODE": "35038"
    },
    {
        "CITY": "Calera",
        "ZIPCODE": "35040"
    },
    {
        "CITY": "Cardiff",
        "ZIPCODE": "35041"
    },
    {
        "CITY": "Centreville",
        "ZIPCODE": "35042"
    },
    {
        "CITY": "Chelsea",
        "ZIPCODE": "35043"
    },
    {
        "CITY": "Childersburg",
        "ZIPCODE": "35044"
    },
    {
        "CITY": "Clanton",
        "ZIPCODE": "35045"
    },
    {
        "CITY": "Clanton",
        "ZIPCODE": "35046"
    },
    {
        "CITY": "Clay",
        "ZIPCODE": "35048"
    },
    {
        "CITY": "Cleveland",
        "ZIPCODE": "35049"
    },
    {
        "CITY": "Columbiana",
        "ZIPCODE": "35051"
    },
    {
        "CITY": "Cook Springs",
        "ZIPCODE": "35052"
    },
    {
        "CITY": "Crane Hill",
        "ZIPCODE": "35053"
    },
    {
        "CITY": "Cropwell",
        "ZIPCODE": "35054"
    },
    {
        "CITY": "Cullman",
        "ZIPCODE": "35055"
    },
    {
        "CITY": "Cullman",
        "ZIPCODE": "35056"
    },
    {
        "CITY": "Cullman",
        "ZIPCODE": "35057"
    },
    {
        "CITY": "Cullman",
        "ZIPCODE": "35058"
    },
    {
        "CITY": "Docena",
        "ZIPCODE": "35060"
    },
    {
        "CITY": "Dolomite",
        "ZIPCODE": "35061"
    },
    {
        "CITY": "Dora",
        "ZIPCODE": "35062"
    },
    {
        "CITY": "Empire",
        "ZIPCODE": "35063"
    },
    {
        "CITY": "Fairfield",
        "ZIPCODE": "35064"
    },
    {
        "CITY": "Fultondale",
        "ZIPCODE": "35068"
    },
    {
        "CITY": "Garden City",
        "ZIPCODE": "35070"
    },
    {
        "CITY": "Gardendale",
        "ZIPCODE": "35071"
    },
    {
        "CITY": "Goodwater",
        "ZIPCODE": "35072"
    },
    {
        "CITY": "Graysville",
        "ZIPCODE": "35073"
    },
    {
        "CITY": "Green Pond",
        "ZIPCODE": "35074"
    },
    {
        "CITY": "Hanceville",
        "ZIPCODE": "35077"
    },
    {
        "CITY": "Harpersville",
        "ZIPCODE": "35078"
    },
    {
        "CITY": "Hayden",
        "ZIPCODE": "35079"
    },
    {
        "CITY": "Helena",
        "ZIPCODE": "35080"
    },
    {
        "CITY": "Hollins",
        "ZIPCODE": "35082"
    },
    {
        "CITY": "Holly Pond",
        "ZIPCODE": "35083"
    },
    {
        "CITY": "Jemison",
        "ZIPCODE": "35085"
    },
    {
        "CITY": "Joppa",
        "ZIPCODE": "35087"
    },
    {
        "CITY": "Kellyton",
        "ZIPCODE": "35089"
    },
    {
        "CITY": "Kimberly",
        "ZIPCODE": "35091"
    },
    {
        "CITY": "Leeds",
        "ZIPCODE": "35094"
    },
    {
        "CITY": "Lincoln",
        "ZIPCODE": "35096"
    },
    {
        "CITY": "Locust Fork",
        "ZIPCODE": "35097"
    },
    {
        "CITY": "Logan",
        "ZIPCODE": "35098"
    },
    {
        "CITY": "Mc Calla",
        "ZIPCODE": "35111"
    },
    {
        "CITY": "Margaret",
        "ZIPCODE": "35112"
    },
    {
        "CITY": "Alabaster",
        "ZIPCODE": "35114"
    },
    {
        "CITY": "Montevallo",
        "ZIPCODE": "35115"
    },
    {
        "CITY": "Morris",
        "ZIPCODE": "35116"
    },
    {
        "CITY": "Mount Olive",
        "ZIPCODE": "35117"
    },
    {
        "CITY": "Mulga",
        "ZIPCODE": "35118"
    },
    {
        "CITY": "New Castle",
        "ZIPCODE": "35119"
    },
    {
        "CITY": "Odenville",
        "ZIPCODE": "35120"
    },
    {
        "CITY": "Oneonta",
        "ZIPCODE": "35121"
    },
    {
        "CITY": "Palmerdale",
        "ZIPCODE": "35123"
    },
    {
        "CITY": "Pelham",
        "ZIPCODE": "35124"
    },
    {
        "CITY": "Pell City",
        "ZIPCODE": "35125"
    },
    {
        "CITY": "Pinson",
        "ZIPCODE": "35126"
    },
    {
        "CITY": "Pleasant Grove",
        "ZIPCODE": "35127"
    },
    {
        "CITY": "Pell City",
        "ZIPCODE": "35128"
    },
    {
        "CITY": "Quinton",
        "ZIPCODE": "35130"
    },
    {
        "CITY": "Ragland",
        "ZIPCODE": "35131"
    },
    {
        "CITY": "Remlap",
        "ZIPCODE": "35133"
    },
    {
        "CITY": "Riverside",
        "ZIPCODE": "35135"
    },
    {
        "CITY": "Rockford",
        "ZIPCODE": "35136"
    },
    {
        "CITY": "Saginaw",
        "ZIPCODE": "35137"
    },
    {
        "CITY": "Sayre",
        "ZIPCODE": "35139"
    },
    {
        "CITY": "Shannon",
        "ZIPCODE": "35142"
    },
    {
        "CITY": "Shelby",
        "ZIPCODE": "35143"
    },
    {
        "CITY": "Siluria",
        "ZIPCODE": "35144"
    },
    {
        "CITY": "Springville",
        "ZIPCODE": "35146"
    },
    {
        "CITY": "Sterrett",
        "ZIPCODE": "35147"
    },
    {
        "CITY": "Sumiton",
        "ZIPCODE": "35148"
    },
    {
        "CITY": "Sycamore",
        "ZIPCODE": "35149"
    },
    {
        "CITY": "Sylacauga",
        "ZIPCODE": "35150"
    },
    {
        "CITY": "Sylacauga",
        "ZIPCODE": "35151"
    },
    {
        "CITY": "Talladega",
        "ZIPCODE": "35160"
    },
    {
        "CITY": "Talladega",
        "ZIPCODE": "35161"
    },
    {
        "CITY": "Thorsby",
        "ZIPCODE": "35171"
    },
    {
        "CITY": "Trafford",
        "ZIPCODE": "35172"
    },
    {
        "CITY": "Trussville",
        "ZIPCODE": "35173"
    },
    {
        "CITY": "Union Grove",
        "ZIPCODE": "35175"
    },
    {
        "CITY": "Vandiver",
        "ZIPCODE": "35176"
    },
    {
        "CITY": "Vincent",
        "ZIPCODE": "35178"
    },
    {
        "CITY": "Vinemont",
        "ZIPCODE": "35179"
    },
    {
        "CITY": "Warrior",
        "ZIPCODE": "35180"
    },
    {
        "CITY": "Watson",
        "ZIPCODE": "35181"
    },
    {
        "CITY": "Wattsville",
        "ZIPCODE": "35182"
    },
    {
        "CITY": "Weogufka",
        "ZIPCODE": "35183"
    },
    {
        "CITY": "West Blocton",
        "ZIPCODE": "35184"
    },
    {
        "CITY": "Westover",
        "ZIPCODE": "35185"
    },
    {
        "CITY": "Wilsonville",
        "ZIPCODE": "35186"
    },
    {
        "CITY": "Wilton",
        "ZIPCODE": "35187"
    },
    {
        "CITY": "Woodstock",
        "ZIPCODE": "35188"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35201"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35202"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35203"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35204"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35205"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35206"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35207"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35208"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35209"
    },
    {
        "CITY": "Homewood",
        "ZIPCODE": "35209"
    },
    {
        "CITY": "Irondale",
        "ZIPCODE": "35210"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35211"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35212"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35213"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35214"
    },
    {
        "CITY": "Center Point",
        "ZIPCODE": "35215"
    },
    {
        "CITY": "Vestavia Hills",
        "ZIPCODE": "35216"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35217"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35218"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35219"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35220"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35221"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35222"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35223"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35224"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35225"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35226"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35228"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35229"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35230"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35231"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35232"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35233"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35234"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35235"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35236"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35237"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35238"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35240"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35242"
    },
    {
        "CITY": "Vestavia",
        "ZIPCODE": "35243"
    },
    {
        "CITY": "Hoover",
        "ZIPCODE": "35244"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35245"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35246"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35249"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35253"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35254"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35255"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35259"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35260"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35261"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35263"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35266"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35270"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35277"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35278"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35279"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35280"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35281"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35282"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35283"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35285"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35286"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35287"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35288"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35289"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35290"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35291"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35292"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35293"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35294"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35295"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35296"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35297"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35298"
    },
    {
        "CITY": "Birmingham",
        "ZIPCODE": "35299"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35401"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35402"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35403"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35404"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35405"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35406"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35407"
    },
    {
        "CITY": "Abernant",
        "ZIPCODE": "35440"
    },
    {
        "CITY": "Akron",
        "ZIPCODE": "35441"
    },
    {
        "CITY": "Aliceville",
        "ZIPCODE": "35442"
    },
    {
        "CITY": "Boligee",
        "ZIPCODE": "35443"
    },
    {
        "CITY": "Brookwood",
        "ZIPCODE": "35444"
    },
    {
        "CITY": "Buhl",
        "ZIPCODE": "35446"
    },
    {
        "CITY": "Carrollton",
        "ZIPCODE": "35447"
    },
    {
        "CITY": "Clinton",
        "ZIPCODE": "35448"
    },
    {
        "CITY": "Coaling",
        "ZIPCODE": "35449"
    },
    {
        "CITY": "Coker",
        "ZIPCODE": "35452"
    },
    {
        "CITY": "Cottondale",
        "ZIPCODE": "35453"
    },
    {
        "CITY": "Duncanville",
        "ZIPCODE": "35456"
    },
    {
        "CITY": "Echola",
        "ZIPCODE": "35457"
    },
    {
        "CITY": "Elrod",
        "ZIPCODE": "35458"
    },
    {
        "CITY": "Emelle",
        "ZIPCODE": "35459"
    },
    {
        "CITY": "Epes",
        "ZIPCODE": "35460"
    },
    {
        "CITY": "Ethelsville",
        "ZIPCODE": "35461"
    },
    {
        "CITY": "Eutaw",
        "ZIPCODE": "35462"
    },
    {
        "CITY": "Fosters",
        "ZIPCODE": "35463"
    },
    {
        "CITY": "Gainesville",
        "ZIPCODE": "35464"
    },
    {
        "CITY": "Gordo",
        "ZIPCODE": "35466"
    },
    {
        "CITY": "Kellerman",
        "ZIPCODE": "35468"
    },
    {
        "CITY": "Knoxville",
        "ZIPCODE": "35469"
    },
    {
        "CITY": "Livingston",
        "ZIPCODE": "35470"
    },
    {
        "CITY": "Mc Shan",
        "ZIPCODE": "35471"
    },
    {
        "CITY": "Northport",
        "ZIPCODE": "35473"
    },
    {
        "CITY": "Moundville",
        "ZIPCODE": "35474"
    },
    {
        "CITY": "Northport",
        "ZIPCODE": "35475"
    },
    {
        "CITY": "Northport",
        "ZIPCODE": "35476"
    },
    {
        "CITY": "Panola",
        "ZIPCODE": "35477"
    },
    {
        "CITY": "Peterson",
        "ZIPCODE": "35478"
    },
    {
        "CITY": "Ralph",
        "ZIPCODE": "35480"
    },
    {
        "CITY": "Reform",
        "ZIPCODE": "35481"
    },
    {
        "CITY": "Samantha",
        "ZIPCODE": "35482"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35485"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35486"
    },
    {
        "CITY": "Tuscaloosa",
        "ZIPCODE": "35487"
    },
    {
        "CITY": "Vance",
        "ZIPCODE": "35490"
    },
    {
        "CITY": "West Greene",
        "ZIPCODE": "35491"
    },
    {
        "CITY": "Jasper",
        "ZIPCODE": "35501"
    },
    {
        "CITY": "Jasper",
        "ZIPCODE": "35502"
    },
    {
        "CITY": "Jasper",
        "ZIPCODE": "35503"
    },
    {
        "CITY": "Jasper",
        "ZIPCODE": "35504"
    },
    {
        "CITY": "Addison",
        "ZIPCODE": "35540"
    },
    {
        "CITY": "Arley",
        "ZIPCODE": "35541"
    },
    {
        "CITY": "Bankston",
        "ZIPCODE": "35542"
    },
    {
        "CITY": "Bear Creek",
        "ZIPCODE": "35543"
    },
    {
        "CITY": "Beaverton",
        "ZIPCODE": "35544"
    },
    {
        "CITY": "Belk",
        "ZIPCODE": "35545"
    },
    {
        "CITY": "Berry",
        "ZIPCODE": "35546"
    },
    {
        "CITY": "Brilliant",
        "ZIPCODE": "35548"
    },
    {
        "CITY": "Carbon Hill",
        "ZIPCODE": "35549"
    },
    {
        "CITY": "Cordova",
        "ZIPCODE": "35550"
    },
    {
        "CITY": "Delmar",
        "ZIPCODE": "35551"
    },
    {
        "CITY": "Detroit",
        "ZIPCODE": "35552"
    },
    {
        "CITY": "Double Springs",
        "ZIPCODE": "35553"
    },
    {
        "CITY": "Eldridge",
        "ZIPCODE": "35554"
    },
    {
        "CITY": "Fayette",
        "ZIPCODE": "35555"
    },
    {
        "CITY": "Glen Allen",
        "ZIPCODE": "35559"
    },
    {
        "CITY": "Goodsprings",
        "ZIPCODE": "35560"
    },
    {
        "CITY": "Guin",
        "ZIPCODE": "35563"
    },
    {
        "CITY": "Hackleburg",
        "ZIPCODE": "35564"
    },
    {
        "CITY": "Haleyville",
        "ZIPCODE": "35565"
    },
    {
        "CITY": "Hamilton",
        "ZIPCODE": "35570"
    },
    {
        "CITY": "Hodges",
        "ZIPCODE": "35571"
    },
    {
        "CITY": "Houston",
        "ZIPCODE": "35572"
    },
    {
        "CITY": "Kansas",
        "ZIPCODE": "35573"
    },
    {
        "CITY": "Kennedy",
        "ZIPCODE": "35574"
    },
    {
        "CITY": "Lynn",
        "ZIPCODE": "35575"
    },
    {
        "CITY": "Millport",
        "ZIPCODE": "35576"
    },
    {
        "CITY": "Natural Bridge",
        "ZIPCODE": "35577"
    },
    {
        "CITY": "Nauvoo",
        "ZIPCODE": "35578"
    },
    {
        "CITY": "Oakman",
        "ZIPCODE": "35579"
    },
    {
        "CITY": "Parrish",
        "ZIPCODE": "35580"
    },
    {
        "CITY": "Phil Campbell",
        "ZIPCODE": "35581"
    },
    {
        "CITY": "Red Bay",
        "ZIPCODE": "35582"
    },
    {
        "CITY": "Sipsey",
        "ZIPCODE": "35584"
    },
    {
        "CITY": "Spruce Pine",
        "ZIPCODE": "35585"
    },
    {
        "CITY": "Sulligent",
        "ZIPCODE": "35586"
    },
    {
        "CITY": "Townley",
        "ZIPCODE": "35587"
    },
    {
        "CITY": "Vernon",
        "ZIPCODE": "35592"
    },
    {
        "CITY": "Vina",
        "ZIPCODE": "35593"
    },
    {
        "CITY": "Winfield",
        "ZIPCODE": "35594"
    },
    {
        "CITY": "Decatur",
        "ZIPCODE": "35601"
    },
    {
        "CITY": "Decatur",
        "ZIPCODE": "35602"
    },
    {
        "CITY": "Decatur",
        "ZIPCODE": "35603"
    },
    {
        "CITY": "Decatur",
        "ZIPCODE": "35609"
    },
    {
        "CITY": "Anderson",
        "ZIPCODE": "35610"
    },
    {
        "CITY": "Athens",
        "ZIPCODE": "35611"
    },
    {
        "CITY": "Athens",
        "ZIPCODE": "35612"
    },
    {
        "CITY": "Athens",
        "ZIPCODE": "35613"
    },
    {
        "CITY": "Athens",
        "ZIPCODE": "35614"
    },
    {
        "CITY": "Belle Mina",
        "ZIPCODE": "35615"
    },
    {
        "CITY": "Cherokee",
        "ZIPCODE": "35616"
    },
    {
        "CITY": "Cloverdale",
        "ZIPCODE": "35617"
    },
    {
        "CITY": "Courtland",
        "ZIPCODE": "35618"
    },
    {
        "CITY": "Danville",
        "ZIPCODE": "35619"
    },
    {
        "CITY": "Elkmont",
        "ZIPCODE": "35620"
    },
    {
        "CITY": "Eva",
        "ZIPCODE": "35621"
    },
    {
        "CITY": "Falkville",
        "ZIPCODE": "35622"
    },
    {
        "CITY": "Florence",
        "ZIPCODE": "35630"
    },
    {
        "CITY": "Florence",
        "ZIPCODE": "35631"
    },
    {
        "CITY": "Florence",
        "ZIPCODE": "35632"
    },
    {
        "CITY": "Florence",
        "ZIPCODE": "35633"
    },
    {
        "CITY": "Florence",
        "ZIPCODE": "35634"
    },
    {
        "CITY": "Hartselle",
        "ZIPCODE": "35640"
    },
    {
        "CITY": "Hillsboro",
        "ZIPCODE": "35643"
    },
    {
        "CITY": "Killen",
        "ZIPCODE": "35645"
    },
    {
        "CITY": "Leighton",
        "ZIPCODE": "35646"
    },
    {
        "CITY": "Lester",
        "ZIPCODE": "35647"
    },
    {
        "CITY": "Lexington",
        "ZIPCODE": "35648"
    },
    {
        "CITY": "Mooresville",
        "ZIPCODE": "35649"
    },
    {
        "CITY": "Moulton",
        "ZIPCODE": "35650"
    },
    {
        "CITY": "Mount Hope",
        "ZIPCODE": "35651"
    },
    {
        "CITY": "Rogersville",
        "ZIPCODE": "35652"
    },
    {
        "CITY": "Russellville",
        "ZIPCODE": "35653"
    },
    {
        "CITY": "Russellville",
        "ZIPCODE": "35654"
    },
    {
        "CITY": "Sheffield",
        "ZIPCODE": "35660"
    },
    {
        "CITY": "Muscle Shoals",
        "ZIPCODE": "35661"
    },
    {
        "CITY": "Muscle Shoals",
        "ZIPCODE": "35662"
    },
    {
        "CITY": "Somerville",
        "ZIPCODE": "35670"
    },
    {
        "CITY": "Tanner",
        "ZIPCODE": "35671"
    },
    {
        "CITY": "Town Creek",
        "ZIPCODE": "35672"
    },
    {
        "CITY": "Trinity",
        "ZIPCODE": "35673"
    },
    {
        "CITY": "Tuscumbia",
        "ZIPCODE": "35674"
    },
    {
        "CITY": "Waterloo",
        "ZIPCODE": "35677"
    },
    {
        "CITY": "Decatur",
        "ZIPCODE": "35699"
    },
    {
        "CITY": "Ardmore",
        "ZIPCODE": "35739"
    },
    {
        "CITY": "Bridgeport",
        "ZIPCODE": "35740"
    },
    {
        "CITY": "Brownsboro",
        "ZIPCODE": "35741"
    },
    {
        "CITY": "Capshaw",
        "ZIPCODE": "35742"
    },
    {
        "CITY": "Dutton",
        "ZIPCODE": "35744"
    },
    {
        "CITY": "Estillfork",
        "ZIPCODE": "35745"
    },
    {
        "CITY": "Fackler",
        "ZIPCODE": "35746"
    },
    {
        "CITY": "Grant",
        "ZIPCODE": "35747"
    },
    {
        "CITY": "Gurley",
        "ZIPCODE": "35748"
    },
    {
        "CITY": "Harvest",
        "ZIPCODE": "35749"
    },
    {
        "CITY": "Hazel Green",
        "ZIPCODE": "35750"
    },
    {
        "CITY": "Hollytree",
        "ZIPCODE": "35751"
    },
    {
        "CITY": "Hollywood",
        "ZIPCODE": "35752"
    },
    {
        "CITY": "Laceys Spring",
        "ZIPCODE": "35754"
    },
    {
        "CITY": "Langston",
        "ZIPCODE": "35755"
    },
    {
        "CITY": "Madison",
        "ZIPCODE": "35756"
    },
    {
        "CITY": "Madison",
        "ZIPCODE": "35757"
    },
    {
        "CITY": "Madison",
        "ZIPCODE": "35758"
    },
    {
        "CITY": "Meridianville",
        "ZIPCODE": "35759"
    },
    {
        "CITY": "New Hope",
        "ZIPCODE": "35760"
    },
    {
        "CITY": "New Market",
        "ZIPCODE": "35761"
    },
    {
        "CITY": "Normal",
        "ZIPCODE": "35762"
    },
    {
        "CITY": "Owens Cross Roads",
        "ZIPCODE": "35763"
    },
    {
        "CITY": "Paint Rock",
        "ZIPCODE": "35764"
    },
    {
        "CITY": "Pisgah",
        "ZIPCODE": "35765"
    },
    {
        "CITY": "Princeton",
        "ZIPCODE": "35766"
    },
    {
        "CITY": "Ryland",
        "ZIPCODE": "35767"
    },
    {
        "CITY": "Scottsboro",
        "ZIPCODE": "35768"
    },
    {
        "CITY": "Scottsboro",
        "ZIPCODE": "35769"
    },
    {
        "CITY": "Section",
        "ZIPCODE": "35771"
    },
    {
        "CITY": "Stevenson",
        "ZIPCODE": "35772"
    },
    {
        "CITY": "Toney",
        "ZIPCODE": "35773"
    },
    {
        "CITY": "Trenton",
        "ZIPCODE": "35774"
    },
    {
        "CITY": "Valhermoso Springs",
        "ZIPCODE": "35775"
    },
    {
        "CITY": "Woodville",
        "ZIPCODE": "35776"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35801"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35802"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35803"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35804"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35805"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35806"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35807"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35808"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35809"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35810"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35811"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35812"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35813"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35814"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35815"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35816"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35824"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35893"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35894"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35895"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35896"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35897"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35898"
    },
    {
        "CITY": "Huntsville",
        "ZIPCODE": "35899"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35901"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35902"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35903"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35904"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35905"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35906"
    },
    {
        "CITY": "Gadsden",
        "ZIPCODE": "35907"
    },
    {
        "CITY": "Albertville",
        "ZIPCODE": "35950"
    },
    {
        "CITY": "Albertville",
        "ZIPCODE": "35951"
    },
    {
        "CITY": "Altoona",
        "ZIPCODE": "35952"
    },
    {
        "CITY": "Ashville",
        "ZIPCODE": "35953"
    },
    {
        "CITY": "Attalla",
        "ZIPCODE": "35954"
    },
    {
        "CITY": "Boaz",
        "ZIPCODE": "35956"
    },
    {
        "CITY": "Boaz",
        "ZIPCODE": "35957"
    },
    {
        "CITY": "Bryant",
        "ZIPCODE": "35958"
    },
    {
        "CITY": "Cedar Bluff",
        "ZIPCODE": "35959"
    },
    {
        "CITY": "Centre",
        "ZIPCODE": "35960"
    },
    {
        "CITY": "Collinsville",
        "ZIPCODE": "35961"
    },
    {
        "CITY": "Crossville",
        "ZIPCODE": "35962"
    },
    {
        "CITY": "Dawson",
        "ZIPCODE": "35963"
    },
    {
        "CITY": "Douglas",
        "ZIPCODE": "35964"
    },
    {
        "CITY": "Flat Rock",
        "ZIPCODE": "35966"
    },
    {
        "CITY": "Fort Payne",
        "ZIPCODE": "35967"
    },
    {
        "CITY": "Fort Payne",
        "ZIPCODE": "35968"
    },
    {
        "CITY": "Fyffe",
        "ZIPCODE": "35971"
    },
    {
        "CITY": "Gallant",
        "ZIPCODE": "35972"
    },
    {
        "CITY": "Gaylesville",
        "ZIPCODE": "35973"
    },
    {
        "CITY": "Geraldine",
        "ZIPCODE": "35974"
    },
    {
        "CITY": "Groveoak",
        "ZIPCODE": "35975"
    },
    {
        "CITY": "Guntersville",
        "ZIPCODE": "35976"
    },
    {
        "CITY": "Henagar",
        "ZIPCODE": "35978"
    },
    {
        "CITY": "Higdon",
        "ZIPCODE": "35979"
    },
    {
        "CITY": "Horton",
        "ZIPCODE": "35980"
    },
    {
        "CITY": "Ider",
        "ZIPCODE": "35981"
    },
    {
        "CITY": "Leesburg",
        "ZIPCODE": "35983"
    },
    {
        "CITY": "Mentone",
        "ZIPCODE": "35984"
    },
    {
        "CITY": "Rainsville",
        "ZIPCODE": "35986"
    },
    {
        "CITY": "Steele",
        "ZIPCODE": "35987"
    },
    {
        "CITY": "Sylvania",
        "ZIPCODE": "35988"
    },
    {
        "CITY": "Valley Head",
        "ZIPCODE": "35989"
    },
    {
        "CITY": "Walnut Grove",
        "ZIPCODE": "35990"
    },
    {
        "CITY": "Autaugaville",
        "ZIPCODE": "36003"
    },
    {
        "CITY": "Banks",
        "ZIPCODE": "36005"
    },
    {
        "CITY": "Billingsley",
        "ZIPCODE": "36006"
    },
    {
        "CITY": "Booth",
        "ZIPCODE": "36008"
    },
    {
        "CITY": "Brantley",
        "ZIPCODE": "36009"
    },
    {
        "CITY": "Brundidge",
        "ZIPCODE": "36010"
    },
    {
        "CITY": "Cecil",
        "ZIPCODE": "36013"
    },
    {
        "CITY": "Chapman",
        "ZIPCODE": "36015"
    },
    {
        "CITY": "Clayton",
        "ZIPCODE": "36016"
    },
    {
        "CITY": "Clio",
        "ZIPCODE": "36017"
    },
    {
        "CITY": "Coosada",
        "ZIPCODE": "36020"
    },
    {
        "CITY": "Deatsville",
        "ZIPCODE": "36022"
    },
    {
        "CITY": "East Tallassee",
        "ZIPCODE": "36023"
    },
    {
        "CITY": "Eclectic",
        "ZIPCODE": "36024"
    },
    {
        "CITY": "Elmore",
        "ZIPCODE": "36025"
    },
    {
        "CITY": "Equality",
        "ZIPCODE": "36026"
    },
    {
        "CITY": "Eufaula",
        "ZIPCODE": "36027"
    },
    {
        "CITY": "Dozier",
        "ZIPCODE": "36028"
    },
    {
        "CITY": "Fitzpatrick",
        "ZIPCODE": "36029"
    },
    {
        "CITY": "Forest Home",
        "ZIPCODE": "36030"
    },
    {
        "CITY": "Fort Davis",
        "ZIPCODE": "36031"
    },
    {
        "CITY": "Fort Deposit",
        "ZIPCODE": "36032"
    },
    {
        "CITY": "Georgiana",
        "ZIPCODE": "36033"
    },
    {
        "CITY": "Glenwood",
        "ZIPCODE": "36034"
    },
    {
        "CITY": "Goshen",
        "ZIPCODE": "36035"
    },
    {
        "CITY": "Grady",
        "ZIPCODE": "36036"
    },
    {
        "CITY": "Greenville",
        "ZIPCODE": "36037"
    },
    {
        "CITY": "Gantt",
        "ZIPCODE": "36038"
    },
    {
        "CITY": "Hardaway",
        "ZIPCODE": "36039"
    },
    {
        "CITY": "Hayneville",
        "ZIPCODE": "36040"
    },
    {
        "CITY": "Highland Home",
        "ZIPCODE": "36041"
    },
    {
        "CITY": "Honoraville",
        "ZIPCODE": "36042"
    },
    {
        "CITY": "Hope Hull",
        "ZIPCODE": "36043"
    },
    {
        "CITY": "Kent",
        "ZIPCODE": "36045"
    },
    {
        "CITY": "Lapine",
        "ZIPCODE": "36046"
    },
    {
        "CITY": "Letohatchee",
        "ZIPCODE": "36047"
    },
    {
        "CITY": "Louisville",
        "ZIPCODE": "36048"
    },
    {
        "CITY": "Luverne",
        "ZIPCODE": "36049"
    },
    {
        "CITY": "Marbury",
        "ZIPCODE": "36051"
    },
    {
        "CITY": "Matthews",
        "ZIPCODE": "36052"
    },
    {
        "CITY": "Midway",
        "ZIPCODE": "36053"
    },
    {
        "CITY": "Millbrook",
        "ZIPCODE": "36054"
    },
    {
        "CITY": "Mount Meigs",
        "ZIPCODE": "36057"
    },
    {
        "CITY": "Perote",
        "ZIPCODE": "36061"
    },
    {
        "CITY": "Petrey",
        "ZIPCODE": "36062"
    },
    {
        "CITY": "Pike Road",
        "ZIPCODE": "36064"
    },
    {
        "CITY": "Pine Level",
        "ZIPCODE": "36065"
    },
    {
        "CITY": "Prattville",
        "ZIPCODE": "36066"
    },
    {
        "CITY": "Prattville",
        "ZIPCODE": "36067"
    },
    {
        "CITY": "Prattville",
        "ZIPCODE": "36068"
    },
    {
        "CITY": "Ramer",
        "ZIPCODE": "36069"
    },
    {
        "CITY": "Rutledge",
        "ZIPCODE": "36071"
    },
    {
        "CITY": "Eufaula",
        "ZIPCODE": "36072"
    },
    {
        "CITY": "Shorter",
        "ZIPCODE": "36075"
    },
    {
        "CITY": "Tallassee",
        "ZIPCODE": "36078"
    },
    {
        "CITY": "Troy",
        "ZIPCODE": "36079"
    },
    {
        "CITY": "Titus",
        "ZIPCODE": "36080"
    },
    {
        "CITY": "Troy",
        "ZIPCODE": "36081"
    },
    {
        "CITY": "Troy",
        "ZIPCODE": "36082"
    },
    {
        "CITY": "Tuskegee",
        "ZIPCODE": "36083"
    },
    {
        "CITY": "Tuskegee Institute",
        "ZIPCODE": "36087"
    },
    {
        "CITY": "Tuskegee Inst.",
        "ZIPCODE": "36088"
    },
    {
        "CITY": "Union Springs",
        "ZIPCODE": "36089"
    },
    {
        "CITY": "Verbena",
        "ZIPCODE": "36091"
    },
    {
        "CITY": "Wetumpka",
        "ZIPCODE": "36092"
    },
    {
        "CITY": "Wetumpka",
        "ZIPCODE": "36093"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36101"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36102"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36103"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36104"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36105"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36106"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36107"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36108"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36109"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36110"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36111"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36112"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36113"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36114"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36115"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36116"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36117"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36118"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36119"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36120"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36121"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36123"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36124"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36125"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36130"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36131"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36132"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36133"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36134"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36135"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36140"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36141"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36142"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36177"
    },
    {
        "CITY": "Montgomery",
        "ZIPCODE": "36191"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36201"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36202"
    },
    {
        "CITY": "Oxford",
        "ZIPCODE": "36203"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36204"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36205"
    },
    {
        "CITY": "Annison",
        "ZIPCODE": "36206"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36207"
    },
    {
        "CITY": "Anniston",
        "ZIPCODE": "36210"
    },
    {
        "CITY": "Alexandria",
        "ZIPCODE": "36250"
    },
    {
        "CITY": "Ashland",
        "ZIPCODE": "36251"
    },
    {
        "CITY": "Bynum",
        "ZIPCODE": "36253"
    },
    {
        "CITY": "Choccolocco",
        "ZIPCODE": "36254"
    },
    {
        "CITY": "Cragford",
        "ZIPCODE": "36255"
    },
    {
        "CITY": "Daviston",
        "ZIPCODE": "36256"
    },
    {
        "CITY": "De Armanville",
        "ZIPCODE": "36257"
    },
    {
        "CITY": "Delta",
        "ZIPCODE": "36258"
    },
    {
        "CITY": "Eastaboga",
        "ZIPCODE": "36260"
    },
    {
        "CITY": "Edwardsville",
        "ZIPCODE": "36261"
    },
    {
        "CITY": "Fruithurst",
        "ZIPCODE": "36262"
    },
    {
        "CITY": "Graham",
        "ZIPCODE": "36263"
    },
    {
        "CITY": "Heflin",
        "ZIPCODE": "36264"
    },
    {
        "CITY": "Jacksonville",
        "ZIPCODE": "36265"
    },
    {
        "CITY": "Lineville",
        "ZIPCODE": "36266"
    },
    {
        "CITY": "Millerville",
        "ZIPCODE": "36267"
    },
    {
        "CITY": "Munford",
        "ZIPCODE": "36268"
    },
    {
        "CITY": "Muscadine",
        "ZIPCODE": "36269"
    },
    {
        "CITY": "Ohatchee",
        "ZIPCODE": "36271"
    },
    {
        "CITY": "Piedmont",
        "ZIPCODE": "36272"
    },
    {
        "CITY": "Ranburne",
        "ZIPCODE": "36273"
    },
    {
        "CITY": "Roanoke",
        "ZIPCODE": "36274"
    },
    {
        "CITY": "Spring Garden",
        "ZIPCODE": "36275"
    },
    {
        "CITY": "Wadley",
        "ZIPCODE": "36276"
    },
    {
        "CITY": "Weaver",
        "ZIPCODE": "36277"
    },
    {
        "CITY": "Wedowee",
        "ZIPCODE": "36278"
    },
    {
        "CITY": "Wellington",
        "ZIPCODE": "36279"
    },
    {
        "CITY": "Woodland",
        "ZIPCODE": "36280"
    },
    {
        "CITY": "Dothan",
        "ZIPCODE": "36301"
    },
    {
        "CITY": "Dothan",
        "ZIPCODE": "36302"
    },
    {
        "CITY": "Dothan",
        "ZIPCODE": "36303"
    },
    {
        "CITY": "Dothan",
        "ZIPCODE": "36304"
    },
    {
        "CITY": "Dothan",
        "ZIPCODE": "36305"
    },
    {
        "CITY": "Abbeville",
        "ZIPCODE": "36310"
    },
    {
        "CITY": "Ariton",
        "ZIPCODE": "36311"
    },
    {
        "CITY": "Ashford",
        "ZIPCODE": "36312"
    },
    {
        "CITY": "Bellwood",
        "ZIPCODE": "36313"
    },
    {
        "CITY": "Black",
        "ZIPCODE": "36314"
    },
    {
        "CITY": "Chancellor",
        "ZIPCODE": "36316"
    },
    {
        "CITY": "Clopton",
        "ZIPCODE": "36317"
    },
    {
        "CITY": "Coffee Springs",
        "ZIPCODE": "36318"
    },
    {
        "CITY": "Columbia",
        "ZIPCODE": "36319"
    },
    {
        "CITY": "Cottonwood",
        "ZIPCODE": "36320"
    },
    {
        "CITY": "Cowarts",
        "ZIPCODE": "36321"
    },
    {
        "CITY": "Daleville",
        "ZIPCODE": "36322"
    },
    {
        "CITY": "Elba",
        "ZIPCODE": "36323"
    },
    {
        "CITY": "Enterprise",
        "ZIPCODE": "36330"
    },
    {
        "CITY": "Enterprise",
        "ZIPCODE": "36331"
    },
    {
        "CITY": "Geneva",
        "ZIPCODE": "36340"
    },
    {
        "CITY": "Gordon",
        "ZIPCODE": "36343"
    },
    {
        "CITY": "Hartford",
        "ZIPCODE": "36344"
    },
    {
        "CITY": "Headland",
        "ZIPCODE": "36345"
    },
    {
        "CITY": "Jack",
        "ZIPCODE": "36346"
    },
    {
        "CITY": "Malvern",
        "ZIPCODE": "36349"
    },
    {
        "CITY": "Midland City",
        "ZIPCODE": "36350"
    },
    {
        "CITY": "New Brockton",
        "ZIPCODE": "36351"
    },
    {
        "CITY": "Newton",
        "ZIPCODE": "36352"
    },
    {
        "CITY": "Newville",
        "ZIPCODE": "36353"
    },
    {
        "CITY": "Ozark",
        "ZIPCODE": "36360"
    },
    {
        "CITY": "Ozark",
        "ZIPCODE": "36361"
    },
    {
        "CITY": "Fort Novosel",
        "ZIPCODE": "36362"
    },
    {
        "CITY": "Pansey",
        "ZIPCODE": "36370"
    },
    {
        "CITY": "Pinckard",
        "ZIPCODE": "36371"
    },
    {
        "CITY": "Shorterville",
        "ZIPCODE": "36373"
    },
    {
        "CITY": "Skipperville",
        "ZIPCODE": "36374"
    },
    {
        "CITY": "Slocomb",
        "ZIPCODE": "36375"
    },
    {
        "CITY": "Webb",
        "ZIPCODE": "36376"
    },
    {
        "CITY": "Evergreen",
        "ZIPCODE": "36401"
    },
    {
        "CITY": "Andalusia",
        "ZIPCODE": "36420"
    },
    {
        "CITY": "Andalusia",
        "ZIPCODE": "36421"
    },
    {
        "CITY": "Beatrice",
        "ZIPCODE": "36425"
    },
    {
        "CITY": "Brewton",
        "ZIPCODE": "36426"
    },
    {
        "CITY": "Brewton",
        "ZIPCODE": "36427"
    },
    {
        "CITY": "Brooklyn",
        "ZIPCODE": "36429"
    },
    {
        "CITY": "Castleberry",
        "ZIPCODE": "36432"
    },
    {
        "CITY": "Coy",
        "ZIPCODE": "36435"
    },
    {
        "CITY": "Dickinson",
        "ZIPCODE": "36436"
    },
    {
        "CITY": "Excel",
        "ZIPCODE": "36439"
    },
    {
        "CITY": "Flomaton",
        "ZIPCODE": "36441"
    },
    {
        "CITY": "Florala",
        "ZIPCODE": "36442"
    },
    {
        "CITY": "Franklin",
        "ZIPCODE": "36444"
    },
    {
        "CITY": "Frisco City",
        "ZIPCODE": "36445"
    },
    {
        "CITY": "Fulton",
        "ZIPCODE": "36446"
    },
    {
        "CITY": "Uriah",
        "ZIPCODE": "36449"
    },
    {
        "CITY": "Grove Hill",
        "ZIPCODE": "36451"
    },
    {
        "CITY": "Kinston",
        "ZIPCODE": "36453"
    },
    {
        "CITY": "Lenox",
        "ZIPCODE": "36454"
    },
    {
        "CITY": "Lockhart",
        "ZIPCODE": "36455"
    },
    {
        "CITY": "Mc Kenzie",
        "ZIPCODE": "36456"
    },
    {
        "CITY": "Megargel",
        "ZIPCODE": "36457"
    },
    {
        "CITY": "Mexia",
        "ZIPCODE": "36458"
    },
    {
        "CITY": "Monroeville",
        "ZIPCODE": "36460"
    },
    {
        "CITY": "Monroeville",
        "ZIPCODE": "36461"
    },
    {
        "CITY": "Monroeville",
        "ZIPCODE": "36462"
    },
    {
        "CITY": "Opp",
        "ZIPCODE": "36467"
    },
    {
        "CITY": "Perdue Hill",
        "ZIPCODE": "36470"
    },
    {
        "CITY": "Peterman",
        "ZIPCODE": "36471"
    },
    {
        "CITY": "Range",
        "ZIPCODE": "36473"
    },
    {
        "CITY": "Red Level",
        "ZIPCODE": "36474"
    },
    {
        "CITY": "Repton",
        "ZIPCODE": "36475"
    },
    {
        "CITY": "River Falls",
        "ZIPCODE": "36476"
    },
    {
        "CITY": "Samson",
        "ZIPCODE": "36477"
    },
    {
        "CITY": "Uriah",
        "ZIPCODE": "36480"
    },
    {
        "CITY": "Vredenburgh",
        "ZIPCODE": "36481"
    },
    {
        "CITY": "Whatley",
        "ZIPCODE": "36482"
    },
    {
        "CITY": "Wing",
        "ZIPCODE": "36483"
    },
    {
        "CITY": "Jackson",
        "ZIPCODE": "36501"
    },
    {
        "CITY": "Atmore",
        "ZIPCODE": "36502"
    },
    {
        "CITY": "Atmore",
        "ZIPCODE": "36503"
    },
    {
        "CITY": "Atmore",
        "ZIPCODE": "36504"
    },
    {
        "CITY": "Axis",
        "ZIPCODE": "36505"
    },
    {
        "CITY": "Bay Minette",
        "ZIPCODE": "36507"
    },
    {
        "CITY": "Bayou La Batre",
        "ZIPCODE": "36509"
    },
    {
        "CITY": "Bon Secour",
        "ZIPCODE": "36511"
    },
    {
        "CITY": "Bucks",
        "ZIPCODE": "36512"
    },
    {
        "CITY": "Calvert",
        "ZIPCODE": "36513"
    },
    {
        "CITY": "Carlton",
        "ZIPCODE": "36515"
    },
    {
        "CITY": "Chatom",
        "ZIPCODE": "36518"
    },
    {
        "CITY": "Chunchula",
        "ZIPCODE": "36521"
    },
    {
        "CITY": "Citronelle",
        "ZIPCODE": "36522"
    },
    {
        "CITY": "Coden",
        "ZIPCODE": "36523"
    },
    {
        "CITY": "Coffeeville",
        "ZIPCODE": "36524"
    },
    {
        "CITY": "Creola",
        "ZIPCODE": "36525"
    },
    {
        "CITY": "Daphne",
        "ZIPCODE": "36526"
    },
    {
        "CITY": "Daphne",
        "ZIPCODE": "36527"
    },
    {
        "CITY": "Dauphin Island",
        "ZIPCODE": "36528"
    },
    {
        "CITY": "Deer Park",
        "ZIPCODE": "36529"
    },
    {
        "CITY": "Elberta",
        "ZIPCODE": "36530"
    },
    {
        "CITY": "Fairhope",
        "ZIPCODE": "36532"
    },
    {
        "CITY": "Fairhope",
        "ZIPCODE": "36533"
    },
    {
        "CITY": "Foley",
        "ZIPCODE": "36535"
    },
    {
        "CITY": "Foley",
        "ZIPCODE": "36536"
    },
    {
        "CITY": "Frankville",
        "ZIPCODE": "36538"
    },
    {
        "CITY": "Fruitdale",
        "ZIPCODE": "36539"
    },
    {
        "CITY": "Gainestown",
        "ZIPCODE": "36540"
    },
    {
        "CITY": "Grand Bay",
        "ZIPCODE": "36541"
    },
    {
        "CITY": "Gulf Shores",
        "ZIPCODE": "36542"
    },
    {
        "CITY": "Huxford",
        "ZIPCODE": "36543"
    },
    {
        "CITY": "Irvington",
        "ZIPCODE": "36544"
    },
    {
        "CITY": "Jackson",
        "ZIPCODE": "36545"
    },
    {
        "CITY": "Gulf Shores",
        "ZIPCODE": "36547"
    },
    {
        "CITY": "Leroy",
        "ZIPCODE": "36548"
    },
    {
        "CITY": "Lillian",
        "ZIPCODE": "36549"
    },
    {
        "CITY": "Little River",
        "ZIPCODE": "36550"
    },
    {
        "CITY": "Loxley",
        "ZIPCODE": "36551"
    },
    {
        "CITY": "Mc Intosh",
        "ZIPCODE": "36553"
    },
    {
        "CITY": "Magnolia Springs",
        "ZIPCODE": "36555"
    },
    {
        "CITY": "Malcolm",
        "ZIPCODE": "36556"
    },
    {
        "CITY": "Millry",
        "ZIPCODE": "36558"
    },
    {
        "CITY": "Montrose",
        "ZIPCODE": "36559"
    },
    {
        "CITY": "Mount Vernon",
        "ZIPCODE": "36560"
    },
    {
        "CITY": "Orange Beach",
        "ZIPCODE": "36561"
    },
    {
        "CITY": "Perdido",
        "ZIPCODE": "36562"
    },
    {
        "CITY": "Point Clear",
        "ZIPCODE": "36564"
    },
    {
        "CITY": "Robertsdale",
        "ZIPCODE": "36567"
    },
    {
        "CITY": "Saint Elmo",
        "ZIPCODE": "36568"
    },
    {
        "CITY": "Saint Stephens",
        "ZIPCODE": "36569"
    },
    {
        "CITY": "Saraland",
        "ZIPCODE": "36571"
    },
    {
        "CITY": "Satsuma",
        "ZIPCODE": "36572"
    },
    {
        "CITY": "Semmes",
        "ZIPCODE": "36575"
    },
    {
        "CITY": "Silverhill",
        "ZIPCODE": "36576"
    },
    {
        "CITY": "Spanish Fort",
        "ZIPCODE": "36577"
    },
    {
        "CITY": "Stapleton",
        "ZIPCODE": "36578"
    },
    {
        "CITY": "Stockton",
        "ZIPCODE": "36579"
    },
    {
        "CITY": "Summerdale",
        "ZIPCODE": "36580"
    },
    {
        "CITY": "Sunflower",
        "ZIPCODE": "36581"
    },
    {
        "CITY": "Theodore",
        "ZIPCODE": "36582"
    },
    {
        "CITY": "Tibbie",
        "ZIPCODE": "36583"
    },
    {
        "CITY": "Vinegar Bend",
        "ZIPCODE": "36584"
    },
    {
        "CITY": "Wagarville",
        "ZIPCODE": "36585"
    },
    {
        "CITY": "Wilmer",
        "ZIPCODE": "36587"
    },
    {
        "CITY": "Theodore",
        "ZIPCODE": "36590"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36601"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36602"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36603"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36604"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36605"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36606"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36607"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36608"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36609"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36610"
    },
    {
        "CITY": "Chickasaw",
        "ZIPCODE": "36611"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36612"
    },
    {
        "CITY": "Eight Mile",
        "ZIPCODE": "36613"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36615"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36616"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36617"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36618"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36619"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36621"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36622"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36625"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36628"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36630"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36633"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36640"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36641"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36644"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36652"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36660"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36663"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36670"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36671"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36675"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36685"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36688"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36689"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36690"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36691"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36693"
    },
    {
        "CITY": "Mobile",
        "ZIPCODE": "36695"
    },
    {
        "CITY": "Selma",
        "ZIPCODE": "36701"
    },
    {
        "CITY": "Selma",
        "ZIPCODE": "36702"
    },
    {
        "CITY": "Selma",
        "ZIPCODE": "36703"
    },
    {
        "CITY": "Alberta",
        "ZIPCODE": "36720"
    },
    {
        "CITY": "Annemanie",
        "ZIPCODE": "36721"
    },
    {
        "CITY": "Arlington",
        "ZIPCODE": "36722"
    },
    {
        "CITY": "Boykin",
        "ZIPCODE": "36723"
    },
    {
        "CITY": "Camden",
        "ZIPCODE": "36726"
    },
    {
        "CITY": "Campbell",
        "ZIPCODE": "36727"
    },
    {
        "CITY": "Catherine",
        "ZIPCODE": "36728"
    },
    {
        "CITY": "Demopolis",
        "ZIPCODE": "36732"
    },
    {
        "CITY": "Dixons Mills",
        "ZIPCODE": "36736"
    },
    {
        "CITY": "Faunsdale",
        "ZIPCODE": "36738"
    },
    {
        "CITY": "Forkland",
        "ZIPCODE": "36740"
    },
    {
        "CITY": "Furman",
        "ZIPCODE": "36741"
    },
    {
        "CITY": "Gallion",
        "ZIPCODE": "36742"
    },
    {
        "CITY": "Greensboro",
        "ZIPCODE": "36744"
    },
    {
        "CITY": "Jefferson",
        "ZIPCODE": "36745"
    },
    {
        "CITY": "Linden",
        "ZIPCODE": "36748"
    },
    {
        "CITY": "Jones",
        "ZIPCODE": "36749"
    },
    {
        "CITY": "Maplesville",
        "ZIPCODE": "36750"
    },
    {
        "CITY": "Lower Peach Tree",
        "ZIPCODE": "36751"
    },
    {
        "CITY": "Lowndesboro",
        "ZIPCODE": "36752"
    },
    {
        "CITY": "Magnolia",
        "ZIPCODE": "36754"
    },
    {
        "CITY": "Marion",
        "ZIPCODE": "36756"
    },
    {
        "CITY": "Plantersville",
        "ZIPCODE": "36758"
    },
    {
        "CITY": "Marion Junction",
        "ZIPCODE": "36759"
    },
    {
        "CITY": "Minter",
        "ZIPCODE": "36761"
    },
    {
        "CITY": "Morvin",
        "ZIPCODE": "36762"
    },
    {
        "CITY": "Myrtlewood",
        "ZIPCODE": "36763"
    },
    {
        "CITY": "Nanafalia",
        "ZIPCODE": "36764"
    },
    {
        "CITY": "Newbern",
        "ZIPCODE": "36765"
    },
    {
        "CITY": "Oak Hill",
        "ZIPCODE": "36766"
    },
    {
        "CITY": "Orrville",
        "ZIPCODE": "36767"
    },
    {
        "CITY": "Pine Apple",
        "ZIPCODE": "36768"
    },
    {
        "CITY": "Pine Hill",
        "ZIPCODE": "36769"
    },
    {
        "CITY": "Safford",
        "ZIPCODE": "36773"
    },
    {
        "CITY": "Sardis",
        "ZIPCODE": "36775"
    },
    {
        "CITY": "Sawyerville",
        "ZIPCODE": "36776"
    },
    {
        "CITY": "Sweet Water",
        "ZIPCODE": "36782"
    },
    {
        "CITY": "Thomaston",
        "ZIPCODE": "36783"
    },
    {
        "CITY": "Thomasville",
        "ZIPCODE": "36784"
    },
    {
        "CITY": "Tyler",
        "ZIPCODE": "36785"
    },
    {
        "CITY": "Uniontown",
        "ZIPCODE": "36786"
    },
    {
        "CITY": "Stanton",
        "ZIPCODE": "36790"
    },
    {
        "CITY": "Randolph",
        "ZIPCODE": "36792"
    },
    {
        "CITY": "Lawley",
        "ZIPCODE": "36793"
    },
    {
        "CITY": "Opelika",
        "ZIPCODE": "36801"
    },
    {
        "CITY": "Opelika",
        "ZIPCODE": "36802"
    },
    {
        "CITY": "Opelika",
        "ZIPCODE": "36803"
    },
    {
        "CITY": "Opelika",
        "ZIPCODE": "36804"
    },
    {
        "CITY": "Auburn",
        "ZIPCODE": "36830"
    },
    {
        "CITY": "Auburn",
        "ZIPCODE": "36831"
    },
    {
        "CITY": "Auburn",
        "ZIPCODE": "36832"
    },
    {
        "CITY": "Auburn Univ.",
        "ZIPCODE": "36849"
    },
    {
        "CITY": "Camp Hill",
        "ZIPCODE": "36850"
    },
    {
        "CITY": "Cottonton",
        "ZIPCODE": "36851"
    },
    {
        "CITY": "Cusseta",
        "ZIPCODE": "36852"
    },
    {
        "CITY": "Dadeville",
        "ZIPCODE": "36853"
    },
    {
        "CITY": "Valley",
        "ZIPCODE": "36854"
    },
    {
        "CITY": "Five Points",
        "ZIPCODE": "36855"
    },
    {
        "CITY": "Fort Mitchell",
        "ZIPCODE": "36856"
    },
    {
        "CITY": "Hatchechubbee",
        "ZIPCODE": "36858"
    },
    {
        "CITY": "Holy Trinity",
        "ZIPCODE": "36859"
    },
    {
        "CITY": "Hurtsboro",
        "ZIPCODE": "36860"
    },
    {
        "CITY": "Jacksons Gap",
        "ZIPCODE": "36861"
    },
    {
        "CITY": "Lafayette",
        "ZIPCODE": "36862"
    },
    {
        "CITY": "Lanett",
        "ZIPCODE": "36863"
    },
    {
        "CITY": "Loachapoka",
        "ZIPCODE": "36865"
    },
    {
        "CITY": "Notasulga",
        "ZIPCODE": "36866"
    },
    {
        "CITY": "Phenix City",
        "ZIPCODE": "36867"
    },
    {
        "CITY": "Phenix City",
        "ZIPCODE": "36868"
    },
    {
        "CITY": "Phenix City",
        "ZIPCODE": "36869"
    },
    {
        "CITY": "Phenix City",
        "ZIPCODE": "36870"
    },
    {
        "CITY": "Pittsview",
        "ZIPCODE": "36871"
    },
    {
        "CITY": "Valley",
        "ZIPCODE": "36872"
    },
    {
        "CITY": "Salem",
        "ZIPCODE": "36874"
    },
    {
        "CITY": "Seale",
        "ZIPCODE": "36875"
    },
    {
        "CITY": "Smiths Station",
        "ZIPCODE": "36877"
    },
    {
        "CITY": "Waverly",
        "ZIPCODE": "36879"
    },
    {
        "CITY": "Bellamy",
        "ZIPCODE": "36901"
    },
    {
        "CITY": "Butler",
        "ZIPCODE": "36904"
    },
    {
        "CITY": "Cuba",
        "ZIPCODE": "36907"
    },
    {
        "CITY": "Gilbertown",
        "ZIPCODE": "36908"
    },
    {
        "CITY": "Jachin",
        "ZIPCODE": "36910"
    },
    {
        "CITY": "Lisman",
        "ZIPCODE": "36912"
    },
    {
        "CITY": "Melvin",
        "ZIPCODE": "36913"
    },
    {
        "CITY": "Needham",
        "ZIPCODE": "36915"
    },
    {
        "CITY": "Pennington",
        "ZIPCODE": "36916"
    },
    {
        "CITY": "Silas",
        "ZIPCODE": "36919"
    },
    {
        "CITY": "Toxey",
        "ZIPCODE": "36921"
    },
    {
        "CITY": "Ward",
        "ZIPCODE": "36922"
    },
    {
        "CITY": "York",
        "ZIPCODE": "36925"
    }
];