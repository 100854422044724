import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
var TigerURL = require("../../utils/constants/appConfigConstants").TigerURL;

const actionTypes = {
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  registerPassed: "REGISTER_SUCCESS",
  registerFailed: "REGISTER_FAILED",
  bindEmail: "BIND_EMAIL_APPLY_INPROGRESS",
  resetSignupState: "RESET_SIGNUP_STATE",
};

//Common Fetch Support Functions
export function onRegister(regData) {
  const audit = getAudit();
  // Axios Http Options
  const Headers = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      audit: JSON.stringify(audit),
    },
    method: "POST",
    mode: "no-cors",
  };

  return (dispatch) => {
    axios
      .post(TigerURL + "/apipublic/security/register", regData, Headers)
      .then(function (response) {
        dispatch(onRegisterSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(onRegisterFailed(error.response.data));
      });
  };
}

function onRegisterSuccess(data) {
  return {
    type: actionTypes.registerPassed,
    payload: data,
  };
}

function onRegisterFailed(data) {
  return {
    type: actionTypes.registerFailed,
    payload: data,
  };
}

export function onBindEmail(email) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.bindEmail,
      payload: email,
    });
  };
}

export function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

export function resetSignupState() {
  return {
    type: actionTypes.resetSignupState,
  };
}
