import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import { langOptSel } from "../../utils/constants/constants";
import * as languageConstants from "../../utils/constants/constants";
import { recertificationPageLabels } from "../recertification/recertificationText";



import {
  aboutMeMultilanguageLabels,
  houseHoldSectionMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  beforeYoustartMlanguageLabels,
  resourcesSectionMultilanguageLabels
} from "./applySnapText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";



let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}
//Constant for additional question "other" is selected
export const accommodationConditionalQuestion = {
  [Props.NAME]: "other",
  // [Props.LABEL]: aboutMeMultilanguageLabels(language).OthersHelp,
  [Props.TYPE]: Types.INPUT_TEXT_AREA,
  [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OthersHelp,
  [Props.MAX_LENGTH]: 250,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [Props.ERROR]: TextProps.VALUE_FALSE,
  [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).accOtherErrormsg,
  [Props.ERROR_MESSAGE_LENGTH]:
    aboutMeMultilanguageLabels(language).accOtherLengthErrormsg,
};
//Constant for SNAP application section data
export const snapSections = [
  {
    //index:0
    id: "before-you-start",
    title: beforeYoustartMlanguageLabels(language).howApplyLabel, //'How does applying work?',
    header: beforeYoustartMlanguageLabels(language).heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    errorRedirect: TextProps.VALUE_FALSE,
    activeSectionId: "before-you-start",
    signAndSubmit: TextProps.VALUE_FALSE,
  },
  {
    //index:1
    id: "about-me",
    parentsId: "about-me",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    subHeader: aboutMeMultilanguageLabels(language).subHeader,
    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelFirstName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: Names.LAST_NAME,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: Names.SUFFIX,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelSuffix,
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).suffixOption,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "choose suffix",
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        // [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "ssn",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).ssnLabel,
        [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).inlineHelpSSN,
        [Props.TYPE]: Types.INPUT_SSN,
        [Props.CHECKED]: TextProps.VALUE_FALSE,
        [InputProps.CLEAR_LABEL]:
          aboutMeMultilanguageLabels(language).inputClearLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).ssnErrMsg,
      },
    ],
    subsections: [
      {
        id: "contact-info",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        header: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        hiddenFromNav: TextProps.VALUE_TRUE,

        questions: [
          {
            [Props.TYPE]: Types.CHECKBOX,
            [Props.LABEL]: aboutMeMultilanguageLabels(language).contactInfo1,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [],
          },
          {
            [Props.TYPE]: Types.DIVIDER,
          },
          {
            [Props.NAME]: "is-homeless",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).houselessLabel,
            [Props.TYPE]: Types.RADIO,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).houselessErrMsg,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).houseLessOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "address",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).addrQuestionlabel,
            [Props.TYPE]: Types.ADDRESS,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_CHAR]:
              aboutMeMultilanguageLabels(language).citiErrmsg,
            [Props.ERROR_MESSAGE_FOR_COUNTY]:
              aboutMeMultilanguageLabels(language).countyErrmsg,
            [Props.ERROR_MESSAGE_FOR_ADDRESS]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
              aboutMeMultilanguageLabels(language).homeAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
              aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
              aboutMeMultilanguageLabels(language).zipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_AL_ZIPCODE]:
              aboutMeMultilanguageLabels(language).snapZipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
              aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
            [Props.ERROR_MESSAGE_PO_BOX]:
              aboutMeMultilanguageLabels(language).poBoxErrmsg,
          },
          {
            [Props.NAME]: "is-also-mailing",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).alsoMailingLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_TRUE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsSendMail,
              },
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_FALSE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsNoDiffAddress,
              },
            ],
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  TextProps.VALUE_STR_FALSE,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "mailingAddress",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).mailingQlabel,
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_COUNTY]:
                      aboutMeMultilanguageLabels(language).countyErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).zipCodeErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "phoneNumber",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
            [Props.TYPE]: Types.INPUT_PHONE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).validPhoneAlert,
          },
          {
            [Props.NAME]: "emailAddress",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).emailAddressLabel,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.MAX_LENGTH]: 50,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).emailErrormsg,
            [Props.ERROR_MESSAGE_FOR_CHAR]:
              aboutMeMultilanguageLabels(language).citiErrmsg,
            [Props.ERROR_MESSAGE_FOR_ADDRESS]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
              aboutMeMultilanguageLabels(language).homeAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
              aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
          },
          {
            [Props.TYPE]: Types.CHECKBOX,
            [Props.LABEL]: aboutMeMultilanguageLabels(language).appointmentPreferenceTitle,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [],
          },
        
          // {
          //   [Props.NAME]: "preferred-phone-number",
          //   [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredPhoneQLabel,
          //   [Props.TYPE]: Types.INPUT_PHONE,
          //   [Props.ERROR]: TextProps.VALUE_FALSE,
          //   [Props.ERROR_MESSAGE]:
          //     aboutMeMultilanguageLabels(language).validPhoneAlert,
          // },
          {
            [Props.NAME]: "interviewType",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).interviewTypeLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).interviewTypeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-day",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredDayQLabel,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).preferredDayInlineLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredDayOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-time-option",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredTimeQLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredTimeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
        ],
        melisaAddressValidated: TextProps.VALUE_FALSE,
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "emergency-snap",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).titleHouseholdInformation,
        header: aboutMeMultilanguageLabels(language).titleHouseholdInformation,
        subHeader: aboutMeMultilanguageLabels(language).emerSnapSubHeader,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          // {
          //   [Props.TYPE]: Types.TEXT,
          //   [TextProps.VALUE]:
          //     aboutMeMultilanguageLabels(language).emergencySnap,
          // },
          // {
          //   [Props.TYPE]: Types.DIVIDER,
          // },
          {
            [Props.NAME]: "less-monthly-housing-expenses",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).incomeMoneylabel,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).inlineHelpLabel,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).optionYesNo,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).alertSelectIncome,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 20,
          },
          {
            [Props.NAME]: "less-monthly-income",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).incomeLesslabel,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).monthlyIncomeoptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).selectIncomeErrmsg,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 21,
          },
          {
            [Props.NAME]: "migrant-worker",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).migrantBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).migrantTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).migrantAfter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).migrantHelp,
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).migrantOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).migrantErrmsg,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 22,
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        /** accommodation sections data*/
        id: "accommodation",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).titleAccommodation,
        header: aboutMeMultilanguageLabels(language).headerAccommodation,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.TYPE]: Types.TEXT,
            [TextProps.VALUE]:
              aboutMeMultilanguageLabels(language).accommodationDesc,
            [Props.NAME]: "accommondation-divider",
          },
          {
            [Props.TYPE]: Types.DIVIDER,
          },
          {
            [Props.NAME]: "is-accommondation",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).accommodationLabel,
            [Props.TYPE]: Types.RADIO,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).accommodationOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).accommodationOptions[0],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "accommodationApplies",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language)
                        .accommodationAppliesLabel,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).accApplyErrormsg,
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: [
                      {
                        [OptionsProps.OPTION_VALUE]: "1",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[0],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "2",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[1],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "3",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[2],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "4",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[3],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "5",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[4],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "6",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[5],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "7",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[6],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "more-info",
        parentsId: "about-me",
        title: "More info",
        header: aboutMeMultilanguageLabels(language).headerMoreAbout,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "is-citizen",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).areUsBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).areUsTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).areUsafter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).eligibleHelp,
            },
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).eligibleMemberHelpCombo,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).eligibleOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).optionErrMsg,
          },
          {
            [Props.NAME]: "language-preference",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).laguageBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).languageTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).languageAfter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).comfortLanHelp,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).languageErrMsg,
            },
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]:
            Object.keys(aboutMeMultilanguageLabels(language).LANGUAGES),
          },
          {
            [Props.TYPE]: Types.TEXT,
            [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
          }
            // {
            //   [Props.NAME]: "biological-sex",
            //   [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
            //   [Props.TYPE]: Types.RADIO,
            //   [OptionsProps.OPTIONS]:
            //     aboutMeMultilanguageLabels(language).genderOptions,
            //   [OptionsProps.OPTIONS_PER_ROW]: 2,
            //   [Props.REQUIRED]: TextProps.VALUE_FALSE,
            //   [Props.ERROR]: TextProps.VALUE_FALSE,
            //   [Props.ERROR_MESSAGE]:
            //     aboutMeMultilanguageLabels(language).genderErrmsg,
            // },
            // {
            //   [Props.NAME]: "ethnicity",
            //   [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
            //   [Props.TYPE]: Types.RADIO,
            //   [Props.INLINE_HELP]:
            //     aboutMeMultilanguageLabels(language).helpEthnicity,
            //   [OptionsProps.OPTIONS]: [
            //     {
            //       [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
  
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
            //     },
            //   ],
            //   [OptionsProps.CLEAR_OPTION]:
            //     aboutMeMultilanguageLabels(language).declineToAnswerLabel,
            //   [Props.REQUIRED]: TextProps.VALUE_FALSE,
            //   [Props.ERROR]: TextProps.VALUE_FALSE,
            //   [Props.ERROR_MESSAGE]: "Please choose ethnicity",
            // },
            // {
            //   [Props.NAME]: "race",
            //   [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
            //   [Props.TYPE]: Types.CHECKBOX,
            //   [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).snapRaceLabel,
            //   [OptionsProps.OPTIONS]: [
            //     {
            //       [OptionsProps.OPTION_VALUE]: "AMIALN",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[0],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "ASIAN",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[1],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "BLCAFR",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[2],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "NTHPCI",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[3],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "WHITE",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[4],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "Decline to answer",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[5],
            //     },
            //     {
            //       [OptionsProps.OPTION_VALUE]: "OTHER",
            //       [OptionsProps.OPTION_DISPLAY]:
            //         aboutMeMultilanguageLabels(language).snapRaceOptions[6],
            //     },
            //   ],
            //   [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            //   [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            //   [Props.REQUIRED]: TextProps.VALUE_FALSE,
            //   [Props.ERROR]: TextProps.VALUE_FALSE,
            //   [Props.ERROR_MESSAGE]: "Please choose race",
            //   [OptionsProps.CONDITIONALS]: [
            //     {
            //       [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
            //       [OptionsProps.CONDITIONAL_QUESTIONS]: [
            //         {
            //           [Props.NAME]: "text-area",
            //           [Props.TYPE]: Types.INPUT_TEXT_AREA,
            //           [Props.MAX_LENGTH]: 250,
            //           [Props.MIN_LENGTH]: 3,
            //           [Props.REQUIRED]: TextProps.VALUE_TRUE,
            //           [Props.ERROR]: TextProps.VALUE_FALSE,
            //           [Props.ERROR_MESSAGE]:
            //             aboutMeMultilanguageLabels(language).otherRaceErrmsg,
            //           [Props.ERROR_MESSAGE_LENGTH]:
            //             aboutMeMultilanguageLabels(language)
            //               .otherRaceErrMsgLength,
            //         },
            //       ],
            //     },
            //   ],
            // },
            // {
            //   [Props.TYPE]: Types.TEXT,
            //   [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
            // },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "optional",
        title: "More info",
        header: aboutMeMultilanguageLabels(language).headerMoreAbout,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "biological-sex",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).genderOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).genderErrmsg,
          },
          {
            [Props.NAME]: "ethnicity",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).helpEthnicity,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'

                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
              },
              {
                [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
              },
            ],
            [OptionsProps.CLEAR_OPTION]:
              aboutMeMultilanguageLabels(language).declineToAnswerLabel,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose ethnicity",
          },
          {
            [Props.NAME]: "race",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).snapRaceLabel,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "AMIALN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "ASIAN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BLCAFR",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "NTHPCI",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WHITE",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Decline to answer",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[6],
              },
            ],
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose race",
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "text-area",
                    [Props.TYPE]: Types.INPUT_TEXT_AREA,
                    [Props.MAX_LENGTH]: 250,
                    [Props.MIN_LENGTH]: 3,
                    [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OtherRace,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).otherRaceErrmsg,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      aboutMeMultilanguageLabels(language)
                        .otherRaceErrMsgLength,
                  },
                ],
              },
            ],
          }
        ],
        completed: TextProps.VALUE_FALSE,
      },
      // {
      //   id: "optional",
      //   title: "More info",
      //   header: aboutMeMultilanguageLabels(language).headerMoreAbout,
      //   hiddenFromNav: TextProps.VALUE_TRUE,
      //   questions: [
      //     {
      //       [Props.NAME]: "biological-sex",
      //       [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
      //       [Props.TYPE]: Types.RADIO,
      //       [OptionsProps.OPTIONS]:
      //         aboutMeMultilanguageLabels(language).genderOptions,
      //       [OptionsProps.OPTIONS_PER_ROW]: 2,
      //       [Props.REQUIRED]: TextProps.VALUE_FALSE,
      //       [Props.ERROR]: TextProps.VALUE_FALSE,
      //       [Props.ERROR_MESSAGE]:
      //         aboutMeMultilanguageLabels(language).genderErrmsg,
      //     },
      //     {
      //       [Props.NAME]: "ethnicity",
      //       [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
      //       [Props.TYPE]: Types.RADIO,
      //       [Props.INLINE_HELP]:
      //         aboutMeMultilanguageLabels(language).helpEthnicity,
      //       [OptionsProps.OPTIONS]: [
      //         {
      //           [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'

      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
      //         },
      //       ],
      //       [OptionsProps.CLEAR_OPTION]:
      //         aboutMeMultilanguageLabels(language).declineToAnswerLabel,
      //       [Props.REQUIRED]: TextProps.VALUE_FALSE,
      //       [Props.ERROR]: TextProps.VALUE_FALSE,
      //       [Props.ERROR_MESSAGE]: "Please choose ethnicity",
      //     },
      //     {
      //       [Props.NAME]: "race",
      //       [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
      //       [Props.TYPE]: Types.CHECKBOX,
      //       [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).snapRaceLabel,
      //       [OptionsProps.OPTIONS]: [
      //         {
      //           [OptionsProps.OPTION_VALUE]: "AMIALN",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[0],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "ASIAN",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[1],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "BLCAFR",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[2],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "NTHPCI",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[3],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "WHITE",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[4],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "Decline to answer",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[5],
      //         },
      //         {
      //           [OptionsProps.OPTION_VALUE]: "OTHER",
      //           [OptionsProps.OPTION_DISPLAY]:
      //             aboutMeMultilanguageLabels(language).snapRaceOptions[6],
      //         },
      //       ],
      //       [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      //       [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      //       [Props.REQUIRED]: TextProps.VALUE_FALSE,
      //       [Props.ERROR]: TextProps.VALUE_FALSE,
      //       [Props.ERROR_MESSAGE]: "Please choose race",
      //       [OptionsProps.CONDITIONALS]: [
      //         {
      //           [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
      //           [OptionsProps.CONDITIONAL_QUESTIONS]: [
      //             {
      //               [Props.NAME]: "text-area",
      //               [Props.TYPE]: Types.INPUT_TEXT_AREA,
      //               [Props.MAX_LENGTH]: 250,
      //               [Props.MIN_LENGTH]: 3,
      //               [Props.REQUIRED]: TextProps.VALUE_TRUE,
      //               [Props.ERROR]: TextProps.VALUE_FALSE,
      //               [Props.ERROR_MESSAGE]:
      //                 aboutMeMultilanguageLabels(language).otherRaceErrmsg,
      //               [Props.ERROR_MESSAGE_LENGTH]:
      //                 aboutMeMultilanguageLabels(language)
      //                   .otherRaceErrMsgLength,
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       [Props.TYPE]: Types.TEXT,
      //       [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
      //     },
      //   ],
      //   completed: TextProps.VALUE_FALSE,
      // },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:2
    id: "my-household",
    parentsId: "my-household",
    title: houseHoldSectionMultilanguageLabels(language).houseTitle, // 'My household',
    header: houseHoldSectionMultilanguageLabels(language).houseHeader,
    questions: [
      {
        [Props.NAME]: "numberInHousehold",
        [Props.LABEL]:
          houseHoldSectionMultilanguageLabels(language).howManyLabel, //'How many people live in your household, including you?',
        [Props.TYPE]: Types.NUMBERS,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          houseHoldSectionMultilanguageLabels(language).numOfPplErrMsg,
        [Props.INLINE_HELP]:
          houseHoldSectionMultilanguageLabels(language).snapHouseHelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,  
        [Props.NUMBER_TEXTBOX_LABEL]:
          houseHoldSectionMultilanguageLabels(language).numOfPplText,
        //'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 4
    /** Household Information sections data*/
    id: "household-information",
    parentsId: "household-information",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).titleHouseholdInformation,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).headerHouseholdInformation,
    questions: [
      {
        [Props.NAME]: "is-enrolled",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isEnrolledHigherLearningLabel,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isEnrolledHigherLearning",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "is-enrolled-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isEnrolledHigherLearning",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isanyone-striker",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isAnyoneStriker,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isAnyoneStriker",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isanyone-striker-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isAnyoneStriker",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasvoluntarily-quitjob",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasVoluntarilyQuitJob,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasVoluntarilyQuitJob",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasvoluntarily-quitjob-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasVoluntarilyQuitJob",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "ishousehold-boarder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isHouseholdBoarder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isHouseholdBoarder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "ishousehold-boarder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isHouseholdBoarder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isdisqualified-intlprgvltn",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isDisqualifiedIntlPrgVltn,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isDisqualifiedIntlPrgVltn",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isdisqualified-intlprgvltn-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isDisqualifiedIntlPrgVltn",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isguiltybuy-sellfoodasst",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuySellFoodAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuySellFoodAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isguiltybuy-sellfoodasst-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuySellFoodAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isGgiltybuy-firearms",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuyFirearms,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuyFirearms",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isGgiltybuy-firearms-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuyFirearms",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasmisrepresent-identity",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasMisrepresentIdentity,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasMisrepresentIdentity",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasmisrepresent-identity-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasMisrepresentIdentity",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasconvicted-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasConvictedFelonyDrugs,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasConvictedFelonyDrugs",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasconvicted-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasConvictedFelonyDrugs",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplying-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingFelonyDrugsTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingFelonyDrugsTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplying-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingFelonyDrugsTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedSexualAbuseMurder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedSexualAbuseMurder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedSexualAbuseMurder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplyingsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingSexualAbuseMurderTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingSexualAbuseMurderTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplyingsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingSexualAbuseMurderTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isfleeing-felonparole",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isFleeingFelonParole,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isFleeingFelonParole",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isfleeing-felonparole-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isFleeingFelonParole",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedfraud-duplicate",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedFraudDuplicateAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedFraudDuplicateAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedfraud-duplicate-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedFraudDuplicateAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasreceived-lottery",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasReceivedLottery,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasReceivedLottery",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasreceived-lottery-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasReceivedLottery",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
    skipsubSectionNavigation: TextProps.VALUE_TRUE
  },
  {
    //newIndex 3
    //index 2
    id: "auth-rep",
    parentsId: "auth-rep",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
    ).authSidebarTitle, // 'My household',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
    ).authTitle,
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
    authorizedRepresentatives:{
      ebtFrstNam : null,
          ebtLstNam : null,
          ebtMdlNam : null,
          ebtPhNum : null,
          ebtSuffix : null,
          ebtWebDataChangeCode : null,
          snpFrstNam : null,
          snpLstNam : null,
          snpMdlNam : null,
          snpPhNum : null,
          snpSuffix : null,
          snpWebDataChangeCode : null,
          ebtAuthRepFlg: null,
          snapAuthRepFlg: null,
    }
    // hiddenFromNav: TextProps.VALUE_TRUE,
  },
  {
    //index:3
    id: "resources",
    parentsId: "resources",
    title: resourcesSectionMultilanguageLabels(language).resourceTitle, //'Resources',
    header: resourcesSectionMultilanguageLabels(language).resourceHeader, //"About your household's Resources",
    questions: [
      {
        [Props.NAME]: "resources",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
          resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
          [Props.LABEL_OBJ_TRIGGER]:
          resourcesSectionMultilanguageLabels(language).aboutResourceTrigger,
          [Props.LABEL_OBJ_AFTER]: resourcesSectionMultilanguageLabels(language).aboutResourceAfter,
          [Props.LABEL_OBJ_HELP]:
          resourcesSectionMultilanguageLabels(language).resourceSubtitle,
        },
        [Props.INLINE_HELP]:
        resourcesSectionMultilanguageLabels(language).resourceHelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        // 'This includes money you may get from a job, the government, or other sources.',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]:
        resourcesSectionMultilanguageLabels(language).questionClearOption,
      },
    ],
    subsections: [
      {
        id: "combined-resources",
        parentsId: "resources",
        title: "",
        header: "John Doe's (8.Aug.1967) - Resource Details",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: resourcesSectionMultilanguageLabels(language).resourceSubSectiontitle,
            [Props.INLINE_HELP]:
            resourcesSectionMultilanguageLabels(language).resourceHelp,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "CASH",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SAVING",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHECK",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "STOCKS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BONDS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETMNT",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "TRUST",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PPDFUN",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
              }
            ],
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-resources-subsection",
            parentsId: "resources",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            originalQuestions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  [UnitsProps.MAX_LENGTH]: 10,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
            questions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  [UnitsProps.MAX_LENGTH]: 10,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "resources-summary",
        parentsId: "resources",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: resourcesSectionMultilanguageLabels(language).resourcesSummaryTitle, //'Resources summary',
        header: resourcesSectionMultilanguageLabels(language).resourcesSummaryHeader, //'Resources summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromSummary: true,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:4  old:3
    id: "income",
    parentsId: "income",
    title: incomeSectionMultilanguageLabels(language).incomeTitle, //'Income',
    header: incomeSectionMultilanguageLabels(language).incomeHeader, //"About your household's income",
    questions: [
      {
        [Props.NAME]: "income",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            incomeSectionMultilanguageLabels(language).aboutIncomeBefore, //'Does anyone in the household receive any ',
          [Props.LABEL_OBJ_TRIGGER]:
            incomeSectionMultilanguageLabels(language).aboutIncomeTrigger, //'income or benefits',
          [Props.LABEL_OBJ_AFTER]: "?",
          [Props.LABEL_OBJ_HELP]:
            incomeSectionMultilanguageLabels(language).incomeHelp,
          //'Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.'
        },
        [Props.INLINE_HELP]:
          incomeSectionMultilanguageLabels(language).moneyYougettitle,
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        // 'This includes money you may get from a job, the government, or other sources.',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]:
          incomeSectionMultilanguageLabels(language).questionClearOption,
      },
    ],
    subsections: [
      {
        id: "combined-income",
        parentsId: "income",
        title: "",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "UI-TAFDCS",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-WAGES",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RSDI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-SSI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-INTINC",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-MILPE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RETFND",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RRRET",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[11],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[12],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[13],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[14],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[15],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CONTR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[16],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[17],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-BOARD",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[18],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[20],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            parentsId: "income",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            originalQuestions: [
              {
                [Props.NAME]: "tanf-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "tanf-gross-income-0",
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
            questions: [
              {
                [Props.NAME]: "tanf-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "tanf-gross-income-0",
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        parentsId: "income",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: incomeSectionMultilanguageLabels(language).incomeSummaryTitle, //'Income summary',
        header: incomeSectionMultilanguageLabels(language).incomeSummaryHeader, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromSummary: true,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:5  old:4
    id: "expenses",
    parentsId: "expenses",
    title: incomeSectionMultilanguageLabels(language).expenseTitle, //'Expenses',
    header: incomeSectionMultilanguageLabels(language).almostLabel,
    subsections: [
      {
        id: "housing-costs",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).houseUtlilityLabel,
        header: incomeSectionMultilanguageLabels(language).expenseHeader,
        subHeader: incomeSectionMultilanguageLabels(language).expenseSubHeader,
        questions: [
          {
            [Props.NAME]: "utilities",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).anyUtilQcost, //'Does your household have any utility costs?',
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "payHeat",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "payAC",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "payACFee",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "elgas",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "hasPhone",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "waterSwgFlg",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "garbageFlg",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[6],
              },
            ],

            [OptionsProps.CLEAR_OPTION]:
              incomeSectionMultilanguageLabels(language).utilitiesClearOption,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).chooseHoldErrmsg, //'Please choose household utilities'
          },
          {
            [Props.NAME]: "utlOtherPayer",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).elsePayExpenses,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).expensesOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).expensesOptions[0],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "utlPayerName",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).enterPyeesName,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).enterPyeesNameErrMsg,
                    [Props.MAX_LENGTH]: 15,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "type",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).houseQLabel, //'What type of housing cost does your household have?',
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "RENT",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MTGPRN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPTAX",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEIN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONFEE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[4],
              },
            ],
            [UnitsProps.CLEAR_OPTION]:
              incomeSectionMultilanguageLabels(language).utilitiesClearOption,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
          },
        ],
        subsections: [
          {
            id: "housing-cost-detail",
            parentsId: "expenses",
            title:
              incomeSectionMultilanguageLabels(language).houseUtlilityLabel, //'Housing and Utility Costs',
            header: incomeSectionMultilanguageLabels(language).expenseHeader, //'Tell us about your housing costs',
            subHeader:
              incomeSectionMultilanguageLabels(language).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
            completed: TextProps.VALUE_FALSE,
            hiddenFromNav: TextProps.VALUE_TRUE,
            originalQuestions: [
              {
                [Props.NAME]: "RENT",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT", //incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "MORTGAGE",

                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN", //incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
                  [Props.TYPE]: Types.UNITS,
                  [Props.INLINE_HELP]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageInlineHelpText,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageunitBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addMortageButtonLabel, //"Add another mortgage"
              },
              {
                [Props.NAME]: "PROPERTY_TAX",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX", //incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageProptaxLabel, //"How much is your household's property taxes?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortagepropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
              },
              {
                [Props.NAME]: "HOME_INSURANCE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN", //incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostQLabel, //"How much is your household's home insurance costs?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostpropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
              },
              {
                [Props.NAME]: "CONDO_FEE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE", // incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .condoFeeBeweenLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
              },
            ],
            questions: [
              {
                [Props.NAME]: "RENT",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT", //incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "MORTGAGE",

                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN", //incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
                  [Props.TYPE]: Types.UNITS,
                  [Props.INLINE_HELP]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageInlineHelpText,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageunitBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addMortageButtonLabel, //"Add another mortgage"
              },
              {
                [Props.NAME]: "PROPERTY_TAX",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX", //incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageProptaxLabel, //"How much is your household's property taxes?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortagepropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
              },
              {
                [Props.NAME]: "HOME_INSURANCE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN", //incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostQLabel, //"How much is your household's home insurance costs?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostpropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
              },
              {
                [Props.NAME]: "CONDO_FEE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE", // incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .condoFeeBeweenLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "kids-and-adults",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).childTitle, //'Child or Adult Dependent Care Costs',
        header: incomeSectionMultilanguageLabels(language).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
        subHeader:
          incomeSectionMultilanguageLabels(language).childExpenseSubHeader, //incomeSectionMultilanguageLabels(language).childCostSubHeader,// 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        questions: [
          {
            [Props.NAME]: "caregivers",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language).careGiveObjbefore,
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language).careGiveObjtrigger, //'transport',
              [Props.LABEL_OBJ_AFTER]:
                incomeSectionMultilanguageLabels(language).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).careGiveHelp,
              // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
            //Enable Again once Clear Value Fixed
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "John Doe (8.Aug.1967)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-1",
                    [Props.LABEL]:
                      "Who does John Doe (8.Aug.1967) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "Marty Smith (2.Jun.1994)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-2",
                    [Props.LABEL]:
                      "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "outHHName",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).outHHNameLabel,
            [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).outHHNameErrorMsg,
            [Props.MAX_LENGTH]: 250,
          },
        ],
        subsections: [
          {
            id: "kids-and-adults-marty",
            parentsId: "expenses",
            title: "Marty Smith (2.Jun.1994)",
            header: "Caring for Marty Smith (2.Jun.1994)",
            subHeader:
              incomeSectionMultilanguageLabels(language).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "caring-kids-adults-cost-1",
                [Props.LABEL]:
                incomeSectionMultilanguageLabels(language). costprovidercarelabel,
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]:
                  incomeSectionMultilanguageLabels(language).costBetween,
                [UnitsProps.UNITS]:
                  incomeSectionMultilanguageLabels(language).unitsOptions,
                [OptionsProps.TARGET_VALUE]:
                  incomeSectionMultilanguageLabels(language)
                    .unitMonthTargetValue,
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "if-pays-transportation-1",
                [Props.LABEL]:
                incomeSectionMultilanguageLabels(language). Transportationpaylabel,
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "transportation-cost-1",
                        [Props.LABEL]:
                        incomeSectionMultilanguageLabels(language).Transportationlabel,
                        [Props.TYPE]: Types.UNITS,
                        [UnitsProps.IN_BETWEEN]:
                          incomeSectionMultilanguageLabels(language)
                            .costBetween,
                        [UnitsProps.UNITS]:
                          incomeSectionMultilanguageLabels(language).unitsOptions,
                        [OptionsProps.TARGET_VALUE]:
                          incomeSectionMultilanguageLabels(language)
                            .unitMonthTargetValue,
                        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      },
                    ],
                  },
                ],
              },
              {
                [Props.NAME]: "drive-to-provider-1",
                [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).householddrivelabel,
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "driving-address-1",
                        [Props.LABEL]:
                          "How many addresses, does John drive to medical appointments or the pharmacy?",
                        [Props.TYPE]: Types.NUMBERS,
                        [Props.ERROR]: TextProps.VALUE_FALSE,
                        [Props.ERROR_MESSAGE]:
                          incomeSectionMultilanguageLabels(language)
                            .noofAppointment,
                      },
                    ],
                  },
                ],
              },
            ],
            subsections: [
              {
                id: "kids-adult-multiple-address",
                parentsId: "expenses",
                title: "Marty Smith (2.Jun.1994)",
                header: "Caring for Marty Smith (2.Jun.1994)",
                subHeader:
                  incomeSectionMultilanguageLabels(language).expenseSubHeader,
                //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
                hiddenFromNav: TextProps.VALUE_TRUE,
                questions: [
                  {
                    [Props.NAME]: "firstName",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).nameProvider,
                      [Props.TYPE]: Types.SPECIAL_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 30,
                  },
                  {
                    [Props.NAME]: "address",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language)
                        .addressProvider, //'What is the address of the provider?',
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).zipCodeErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                  },
                  {
                    [Props.NAME]: "frequency-trips-1",
                    [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).householddrivelabel,
                    [Props.TYPE]: Types.UNITS,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween, //'per',
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions, //['Week', 'Month', 'Year'],
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "child-support",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).childSupTitle, //'Child Support Costs',
        header: incomeSectionMultilanguageLabels(language).tellusAbout, //'Tell us about your Child support costs',
        questions: [
          {
            [Props.NAME]: "with-child-support",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegalbefore, // 'Does anyone in the household have a legal obligation (court order) to pay ',
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegaltrigger, //'child support',
              [Props.LABEL_OBJ_AFTER]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegalAfter, //' to a child not living with you?',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).childSupportHelp,
              //'Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.'
            },
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
            //Enable Again once Clear Value Fixed
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "John Doe (8.Aug.1967)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "child-support-cost-1",
                    [Props.LABEL]:
                      "What is the child support cost for John Doe (8.Aug.1967)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  {
                    [Props.NAME]: "for-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).forWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                  {
                    [Props.NAME]: "to-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).toWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "Marty Smith (2.Jun.1994)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "child-support-cost-2",
                    [Props.LABEL]:
                      "What is the child support cost for Marty Smith (2.Jun.1994)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  {
                    [Props.NAME]: "for-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).forWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                  {
                    [Props.NAME]: "to-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).toWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "medical-expenses",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).medicalTitle, //'Medical Costs',
        header: incomeSectionMultilanguageLabels(language).medicalHeader, //"Tell us about your medical costs",
        subHeader: "", //incomeSectionMultilanguageLabels(language).medicalSubHeader,
        // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        questions: [
          {
            [Props.NAME]: "with-medical-expenses",
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpBefore,
            [Props.SUB_LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language)
            .medicalInlineSeethisFull +
              ' <a href="https://dhr.alabama.gov/county-office-contact/" target="_blank" rel="noopener noreferrer">' +
              incomeSectionMultilanguageLabels(language).medicalInlineEligibleMedical +
              "</a>" +
              '<p>' +  incomeSectionMultilanguageLabels(language).fulllist + '</p>' +
              '<p>' +  incomeSectionMultilanguageLabels(language).tellUsPocketMedicalExpense + '</p>' +  
              '<p>' +  incomeSectionMultilanguageLabels(language).beSureLegally + '</p>' +
              '<ul><li>' +  incomeSectionMultilanguageLabels(language).allMedicalExpenseVerified  + '</li>' +
              '<li>' +  incomeSectionMultilanguageLabels(language).verfiedExpenseMay    + '</li>' +
              '<li>' +   incomeSectionMultilanguageLabels(language).acceptableForms + '</li>' +
              '<li>' +   incomeSectionMultilanguageLabels(language).obtainVerificationNeeded + '</li></ul>'
                  + incomeSectionMultilanguageLabels(language).medicalExpAfter,
              //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language).medicalExpTrigger,
                // [Props.LABEL_OBJ_AFTER]:
                // incomeSectionMultilanguageLabels(language).medicalExpAfter,
              // ', over the counter medications, dental or eye care, adult diapers, etc.?',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).medicalExphelp,                
              // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'            
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
          }
        
        ],
        subsections: [
          {
            id: "medical-expenses-type-1",
            parentsId: "expenses",
            title: "John Doe (8.Aug.1967)",
            header: "Medical cost for John Doe (8.Aug.1967)",
            // subHeader:
            //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
            // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: 'medicalExpenseType',
                [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpenseType,
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: [
                  {
                    [OptionsProps.OPTION_VALUE]: 'MDENT',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).doctorOrDentist,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HSPTL',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHome,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MEDCTN',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionDrugs,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'OTCMED',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).overTheCounterMedicine,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HINSPR',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).healthInsurancePremiums,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MSUPPL',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicalSupplies,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MDCOSH',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicaidCostSharing,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'DHP',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).denturesOrHearingAids,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'GLSSES',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlases,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'TRSPRT',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServices,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HHACC',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).HudHomeHealthAide,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'DOG',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).serviceAnimal,
          },
                  {
                    [OptionsProps.OPTION_VALUE]: 'OTHER',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).otherMedicalExpense,
                  }
                ]
              }
        ],
        subsections: [
          {
            id: "medical-expenses-filter-1",
            parentsId: "expenses",
            title: "John Doe (8.Aug.1967)",
            header: "Medical cost for John Doe (8.Aug.1967)",
                // subHeader:
                //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
                // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
                hiddenFromNav: TextProps.VALUE_TRUE,
                questions: [
                  {
                    [Props.NAME]: 'MDENT',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).doctorOrDentist,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDENT"
                  },
                  {
                    [Props.NAME]: 'HSPTL',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHome,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HSPTL"
                  },
                  {
                    [Props.NAME]: 'MEDCTN',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionDrugs,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MEDCTN"
                  },
                  {
                    [Props.NAME]: 'OTCMED',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).overTheCounterMedicine,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTCMED"
                  },
                  {
                    [Props.NAME]: 'HINSPR',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).healthInsurancePremiums,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HINSPR"
                  },
                  {
                    [Props.NAME]: 'MSUPPL',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalSupplies,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MSUPPL"
                  },
                  {
                    [Props.NAME]: 'MDCOSH',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicaidCostSharing,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDCOSH"
                  },
                  {
                    [Props.NAME]: 'DHP',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).denturesOrHearingAids,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DHP"
                  },
                  {
                    [Props.NAME]: 'GLSSES',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlases,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "GLSSES"
                  },
                  {
                    [Props.NAME]: 'TRSPRT',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServices,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRSPRT"
                  },
                  {
                    [Props.NAME]: 'HHACC',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).HudHomeHealthAide,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HHACC"
                  },
                  {
                    [Props.NAME]: 'DOG',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).serviceAnimal,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DOG"
                  },
                  {
                    [Props.NAME]: 'OTHER',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).otherMedicalExpense,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER"
                  }
                ],
                completed: TextProps.VALUE_FALSE,
                subsections: [
                  {
                    id: "medical-driving-1",
                    parentsId: "expenses",
                    title: "John Doe (8.Aug.1967)",
                    header: "Medical Expenses For John Doe (8.Aug.1967)",
            subHeader:
              incomeSectionMultilanguageLabels(language).expenseSubHeader,
            // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: "driving-1",
                [Props.LABEL]:
                  "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "driving-address-1",
                        [Props.LABEL]:
                          "How many addresses, does John drive to medical appointments or the pharmacy??",
                        [Props.TYPE]: Types.NUMBERS,
                        [Props.ERROR]: TextProps.VALUE_FALSE,
                        [Props.ERROR_MESSAGE]:
                          incomeSectionMultilanguageLabels(language)
                            .noofAppointment,
                      },
                    ],
                  },
                ],
                      }
                    ]
              },
              {
                id: "medical-address-1",
                parentsId: "expenses",
                title: "Medical Appointment for John Doe",
                header: "Medical Appointment for John Doe",
                hiddenFromNav: TextProps.VALUE_TRUE,
                completed: TextProps.VALUE_FALSE,
                questions: [
                  {
                    [Props.NAME]: "address",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).medicalAddress,
                    // 'What is the address of the medical appointment or pharmacy?',
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language).zipErrmsg, //'Please enter a valid Zip code'
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                  },
                  {
                    [Props.NAME]: "frequency-trips-1",
                    [Props.LABEL]:
                      "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
                    [Props.TYPE]: Types.UNITS,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .freqTripoptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                  },
                  {
                    [Props.NAME]: "cost-trips-1",
                    [Props.LABEL]:
                      "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .freqTripoptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCostErrMsgLen,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCostErrMsgLen,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:6  old:5
    id: "ebt-card-request",
    parentsId: "ebt-card-request",
    title: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    header: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    questions: [
      {
        [Props.NAME]: "need-ebt-card",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).needEbtCard,
        [Props.INLINE_HELP]:
          aboutMeMultilanguageLabels(language).inlineHelpEbtcard,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]:
              aboutMeMultilanguageLabels(language).houseLessOptions[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.TYPE]: Types.HTML_TEXT,
                [TextProps.VALUE]: aboutMeMultilanguageLabels(language).ebtNote,
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:7  old:6
    id: "summary",
    parentsId: "summary",
    title: incomeSectionMultilanguageLabels(language).summaryLabel,
    header: incomeSectionMultilanguageLabels(language).summaryLabel,
    completed: TextProps.VALUE_FALSE,
  },
  {
    //index:8  old:7
    id: "sign-and-submit",
    parentsId: "sign-and-submit",
    title: incomeSectionMultilanguageLabels(language).signSubmitLabel, //'Sign & submit',
    header: incomeSectionMultilanguageLabels(language).signSubmitHeader, //'Sign & submit',
    subHeader: incomeSectionMultilanguageLabels(language).signSubmitSub, //'You made it through the form! Now for the easy stuff.',
    subsections: [
      {
        id: "submitted",
        title: incomeSectionMultilanguageLabels(language).subSectionLabel, //'Submitted',
        header: incomeSectionMultilanguageLabels(language).submitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];

//Constant for TANF application section data
export const tanfSections = [
  {
    id: "before-you-start",
    title: beforeYoustartMlanguageLabels(language).howApplyLabel, //'How does applying work?',
    header: beforeYoustartMlanguageLabels(language).heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    errorRedirect: TextProps.VALUE_FALSE,
    dataPopulated: TextProps.VALUE_FALSE,
    activeSectionId: "before-you-start",
    signAndSubmit: TextProps.VALUE_FALSE,
  },
  {
    id: "about-me",
    parentsId: "about-me",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    subHeader: aboutMeMultilanguageLabels(language).tanfSubHeader,
    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelFirstName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: Names.LAST_NAME,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: Names.SUFFIX,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelSuffix,
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).suffixOption,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "choose suffix",
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "ssn",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).ssnLabel,
        [Props.INLINE_HELP]:
          aboutMeMultilanguageLabels(language).tanfInlineHelpSSN1 +
          "<br/>" +
          aboutMeMultilanguageLabels(language).tanfInlineHelpSSN2,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.TYPE]: Types.INPUT_SSN,
        [Props.CHECKED]: TextProps.VALUE_FALSE,
        [InputProps.CLEAR_LABEL]:
          aboutMeMultilanguageLabels(language).inputClearLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).ssnErrMsg,
      },
    ],
    subsections: [
      {
        id: "contact-info",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        header: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        subHeader: aboutMeMultilanguageLabels(language).tanfContactInfoHelpLine,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "address",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).addrQuestionlabel,
            [Props.TYPE]: Types.ADDRESS,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_CHAR]:
              aboutMeMultilanguageLabels(language).citiErrmsg,
            [Props.ERROR_MESSAGE_FOR_COUNTY]:
              aboutMeMultilanguageLabels(language).countyErrmsg,
            [Props.ERROR_MESSAGE_FOR_ADDRESS]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
              aboutMeMultilanguageLabels(language).homeAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
              aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
              aboutMeMultilanguageLabels(language).zipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_AL_ZIPCODE]:
              aboutMeMultilanguageLabels(language).tanfZipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
              aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
            [Props.ERROR_MESSAGE_PO_BOX]:
              aboutMeMultilanguageLabels(language).poBoxErrmsg,
          },
          {
            [Props.NAME]: "is-also-mailing",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).alsoMailingLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_TRUE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsSendMail,
              },
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_FALSE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsNoDiffAddress,
              },
            ],
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  TextProps.VALUE_STR_FALSE,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "mailingAddress",
                    [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).mailingQlabel,
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_COUNTY]:
                      aboutMeMultilanguageLabels(language).countyErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).zipCodeErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "phoneNumber",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
            [Props.TYPE]: Types.INPUT_PHONE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).validPhoneAlert,
          },
          {
            [Props.NAME]: "emailAddress",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).emailAddressLabel,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.MAX_LENGTH]: 50,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).emailErrormsg,
          },
          {
            [Props.TYPE]: Types.CHECKBOX,
            [Props.LABEL]: aboutMeMultilanguageLabels(language).appointmentPreferenceTitle,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [],
          },
        
          // {
          //   [Props.NAME]: "preferred-phone-number",
          //   [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredPhoneQLabel,
          //   [Props.TYPE]: Types.INPUT_PHONE,
          //   [Props.ERROR]: TextProps.VALUE_FALSE,
          //   [Props.ERROR_MESSAGE]:
          //     aboutMeMultilanguageLabels(language).validPhoneAlert,
          // },
          {
            [Props.NAME]: "interviewType",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).interviewTypeLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).interviewTypeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-day",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredDayQLabel,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).preferredDayInlineLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredDayOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-time-option",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredTimeQLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredTimeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
        ],
        melisaAddressValidated: TextProps.VALUE_FALSE,
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "more-info",
        parentsId: "about-me",
        title: "More About Me",
        header: aboutMeMultilanguageLabels(language).headerMoreAbout,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "is-citizen",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).areUsBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).areUsTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).areUsafter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).eligibleHelpTANF,
            },
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).eligibleMemberHelpCombo,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).eligibleOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).optionErrMsg,
              [OptionsProps.CONDITIONALS]: [
                {
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                    aboutMeMultilanguageLabels(language).eligibleOptions[1],
                  [OptionsProps.CONDITIONAL_QUESTIONS]: [
                    {
                      [Props.NAME]: "immigration-status",
                      [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).immigrationStatus,
                      [Props.TYPE]: Types.RADIO,
                      [OptionsProps.OPTIONS]:
                        aboutMeMultilanguageLabels(language).immigrationOptions,
                      [OptionsProps.OPTIONS_PER_ROW]: 2,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                    },
                  ],
                },
              ],
          },
          {
            [Props.NAME]: "language-preference",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).laguageBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).languageTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).languageAfter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).comfortLanHelp,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).languageErrMsg,
            },
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]:
            Object.keys(aboutMeMultilanguageLabels(language).LANGUAGES),
          },
          {
            [Props.NAME]: "biological-sex",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).genderOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).genderErrmsg,
          },
          {
            [Props.NAME]: "marital-status",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).maritalStatus,
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]: "",
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[0],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose material status",
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[1] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[2] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[3] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[4] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "ethnicity",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).tanfhelpEthnicity,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]:  aboutMeMultilanguageLabels(language).hisPanicOption,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
              },
              {
                [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
              },
            ],
            [OptionsProps.CLEAR_OPTION]:
              aboutMeMultilanguageLabels(language).declineToAnswerLabel,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose ethnicity",
          },
          {
            [Props.NAME]: "race",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).tanfRaceLabel,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "AMIALN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "ASIAN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BLCAFR",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "NTHPCI",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WHITE",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Decline to answer",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[6],
              },
            ],
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose race",
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "text-area",
                    [Props.TYPE]: Types.INPUT_TEXT_AREA,
                    [Props.MAX_LENGTH]: 250,
                    [Props.MIN_LENGTH]: 3,
                    [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OtherRace,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).otherRaceErrmsg,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      aboutMeMultilanguageLabels(language)
                        .otherRaceErrMsgLength,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "my-household",
    parentsId: "my-household",
    title: houseHoldSectionMultilanguageLabels(language).houseTitle, // 'My household',
    header: houseHoldSectionMultilanguageLabels(language).houseHeader,
    questions: [
      {
        [Props.NAME]: "numberInHousehold",
        [Props.LABEL]:
          houseHoldSectionMultilanguageLabels(language).howManyLabel, //'How many people live in your household, including you?',
        [Props.TYPE]: Types.NUMBERS,
        [Props.INLINE_HELP]: houseHoldSectionMultilanguageLabels(language).tanfHouseHelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,  
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          houseHoldSectionMultilanguageLabels(language).numOfPplErrMsg,
        //'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "income",
    parentsId: "income",
    title: incomeSectionMultilanguageLabels(language).incomeTitle, //'Income',
    header: incomeSectionMultilanguageLabels(language).incomeHeader, //"About your household's income",
    questions: [
      {
        [Props.NAME]: "income",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            incomeSectionMultilanguageLabels(language).aboutIncomeBefore, //'Does anyone in the household receive any ',
          [Props.LABEL_OBJ_TRIGGER]:
            incomeSectionMultilanguageLabels(language).aboutIncomeTrigger, //'income or benefits',
          [Props.LABEL_OBJ_AFTER]: "?",
          [Props.LABEL_OBJ_HELP]:
            incomeSectionMultilanguageLabels(language).incomeHelp,
          //'Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.'
        },
        [Props.INLINE_HELP]:
          incomeSectionMultilanguageLabels(language).moneyYougettitle,
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        // 'This includes money you may get from a job, the government, or other sources.',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]:
          incomeSectionMultilanguageLabels(language).questionClearOption,
      },
    ],
    subsections: [
      {
        id: "combined-income",
        parentsId: "income",
        title: "John Doe's (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-WAGES",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RSDI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-SSI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-INTINC",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-MILPE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RETFND",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RRRET",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[11],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[12],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[13],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[14],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[15],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CONTR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[16],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[17],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-BOARD",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[18],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-FEDWRK",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[19],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[20],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            parentsId: "income",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "work-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-FEDWRK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Work Study'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
            originalQuestions: [
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "work-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-FEDWRK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        parentsId: "income",
        active:TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: incomeSectionMultilanguageLabels(language).incomeSummaryTitle, //'Income summary',
        header: incomeSectionMultilanguageLabels(language).incomeSummaryHeader, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromSummary: true,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "ebt-card-request",
    parentsId: "ebt-card-request",
    title: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    header: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    questions: [
      {
        [Props.NAME]: "need-ebt-card",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).needEbtCard,
        [Props.INLINE_HELP]:
          aboutMeMultilanguageLabels(language).inlineHelpEbtcard,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]:
              aboutMeMultilanguageLabels(language).houseLessOptions[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.TYPE]: Types.HTML_TEXT,
                [TextProps.VALUE]: aboutMeMultilanguageLabels(language).ebtNote,
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "summary",
    parentsId: "summary",
    title: incomeSectionMultilanguageLabels(language).summaryLabel,
    header: incomeSectionMultilanguageLabels(language).summaryLabel,
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "sign-and-submit",
    parentsId: "sign-and-submit",
    title: incomeSectionMultilanguageLabels(language).signSubmitLabel, //'Sign & submit',
    header: incomeSectionMultilanguageLabels(language).signSubmitHeader, //'Sign & submit',
    subHeader: incomeSectionMultilanguageLabels(language).tanfSignSubmitSub, //'You made it through the form! Now for the easy stuff.',
    subsections: [
      {
        id: "submitted",
        title: incomeSectionMultilanguageLabels(language).subSectionLabel, //'Submitted',
        header: incomeSectionMultilanguageLabels(language).tanfSubmitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];
//Constant for COMBO application section data
export const comboSections = [
  {
    id: "before-you-start",
    title: beforeYoustartMlanguageLabels(language).howApplyLabel, //'How does applying work?',
    header: beforeYoustartMlanguageLabels(language).heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    errorRedirect: TextProps.VALUE_FALSE,
    activeSectionId: "before-you-start",
    signAndSubmit: TextProps.VALUE_FALSE,
  },
  {
    id: "about-me",
    parentsId: "about-me",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    subHeader:
      aboutMeMultilanguageLabels(language).comboSubHeader1 +
      aboutMeMultilanguageLabels(language).comboSubHeader2,
    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelFirstName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: Names.LAST_NAME,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.SPECIAL_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: Names.SUFFIX,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelSuffix,
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).suffixOption,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "choose suffix",
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        // [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "ssn",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).ssnLabel,
        [Props.TYPE]: Types.INPUT_SSN,
        [Props.CHECKED]: TextProps.VALUE_FALSE,
        [InputProps.CLEAR_LABEL]:
          aboutMeMultilanguageLabels(language).inputClearLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).ssnErrMsg,
      },
    ],
    subsections: [
      {
        id: "contact-info",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        header: aboutMeMultilanguageLabels(language).headerMyContactInfo,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.TYPE]: Types.CHECKBOX,
            [Props.LABEL]: aboutMeMultilanguageLabels(language).contactInfo4,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [],
          },
          {
            [Props.TYPE]: Types.DIVIDER,
          },
          {
            [Props.NAME]: "is-homeless",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).houselessLabel,
            [Props.TYPE]: Types.RADIO,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).houselessErrMsg,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).houseLessOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "address",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).addrQuestionlabel,
            [Props.TYPE]: Types.ADDRESS,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_CHAR]:
              aboutMeMultilanguageLabels(language).citiErrmsg,
            [Props.ERROR_MESSAGE_FOR_COUNTY]:
              aboutMeMultilanguageLabels(language).countyErrmsg,
            [Props.ERROR_MESSAGE_FOR_ADDRESS]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
              aboutMeMultilanguageLabels(language).homeAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
              aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
              aboutMeMultilanguageLabels(language).zipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_AL_ZIPCODE]:
              aboutMeMultilanguageLabels(language).comboZipCodeErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
              aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
            [Props.ERROR_MESSAGE_PO_BOX]:
              aboutMeMultilanguageLabels(language).poBoxErrmsg,
          },
          {
            [Props.NAME]: "is-also-mailing",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).alsoMailingLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_TRUE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsSendMail,
              },
              {
                [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_FALSE,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).optionsNoDiffAddress,
              },
            ],
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  TextProps.VALUE_STR_FALSE,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "mailingAddress",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).mailingQlabel,
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_COUNTY]:
                      aboutMeMultilanguageLabels(language).countyErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).zipCodeErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
                      aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "phoneNumber",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
            [Props.TYPE]: Types.INPUT_PHONE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).validPhoneAlert,
          },
          {
            [Props.NAME]: "emailAddress",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).emailAddressLabel,
            [Props.TYPE]: Types.INPUT_TEXT,
            [Props.MAX_LENGTH]: 50,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).emailErrormsg,
            [Props.ERROR_MESSAGE_FOR_CHAR]:
              aboutMeMultilanguageLabels(language).citiErrmsg,
            [Props.ERROR_MESSAGE_FOR_ADDRESS]:
              aboutMeMultilanguageLabels(language).addressErrmsg,
            [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
              aboutMeMultilanguageLabels(language).homeAddressErrmsg,
            [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
              aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
          },
          {
            [Props.TYPE]: Types.CHECKBOX,
            [Props.LABEL]: aboutMeMultilanguageLabels(language).appointmentPreferenceTitle,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [],
          },
        
          // {
          //   [Props.NAME]: "preferred-phone-number",
          //   [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredPhoneQLabel,
          //   [Props.TYPE]: Types.INPUT_PHONE,
          //   [Props.ERROR]: TextProps.VALUE_FALSE,
          //   [Props.ERROR_MESSAGE]:
          //     aboutMeMultilanguageLabels(language).validPhoneAlert,
          // },
           {
            [Props.NAME]: "interviewType",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).interviewTypeLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).interviewTypeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-day",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredDayQLabel,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).preferredDayInlineLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredDayOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
          {
            [Props.NAME]: "preferred-time-option",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).preferredTimeQLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).preferredDayErrMessage,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).preferredTimeOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
          },
        ],
        melisaAddressValidated: TextProps.VALUE_FALSE,
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "household-information-base",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).titleHouseholdInformation,
        header: aboutMeMultilanguageLabels(language).titleHouseholdInformation,
        subHeader: aboutMeMultilanguageLabels(language).emerSnapSubHeader,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          // {
          //   [Props.TYPE]: Types.TEXT,
          //   [TextProps.VALUE]:
          //     aboutMeMultilanguageLabels(language).emergencySnap,
          // },
          // {
          //   [Props.TYPE]: Types.DIVIDER,
          // },
          {
            [Props.NAME]: "less-monthly-housing-expenses",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).incomeMoneylabel,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).inlineHelpLabel,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).optionYesNo,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).alertSelectIncome,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 20,
          },
          {
            [Props.NAME]: "less-monthly-income",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).incomeLesslabel,
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).monthlyIncomeoptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).selectIncomeErrmsg,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 21,
          },
          {
            [Props.NAME]: "migrant-worker",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).migrantBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).migrantTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).migrantAfter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).migrantHelp,
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).migrantOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).migrantErrmsg,
            [OptionsProps.IMM_NEEDS_PGRM_CODE]: 22,
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "accommodation",
        parentsId: "about-me",
        title: aboutMeMultilanguageLabels(language).titleAccommodation,
        header: aboutMeMultilanguageLabels(language).headerAccommodation,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.TYPE]: Types.TEXT,
            [TextProps.VALUE]:
              aboutMeMultilanguageLabels(language).accommodationDesc,
            [Props.NAME]: "accommondation-divider",
          },
          {
            [Props.TYPE]: Types.DIVIDER,
          },
          {
            [Props.NAME]: "is-accommondation",
            [Props.LABEL]:
              aboutMeMultilanguageLabels(language).accommodationLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).accommodationOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).accommodationOptions[0],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "accommodationApplies",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language)
                        .accommodationAppliesLabel,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).accApplyErrormsg,
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: [
                      {
                        [OptionsProps.OPTION_VALUE]: "1",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[0],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "2",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[1],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "3",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[2],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "4",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[3],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "5",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[4],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "6",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[5],
                      },
                      {
                        [OptionsProps.OPTION_VALUE]: "7",
                        [OptionsProps.OPTION_DISPLAY]:
                          aboutMeMultilanguageLabels(language)
                            .accommodationAppliesOptions[6],
                      },
                      // {
                      //   [OptionsProps.OPTION_VALUE]: "8",
                      //   [OptionsProps.OPTION_DISPLAY]:
                      //     aboutMeMultilanguageLabels(language)
                      //       .accommodationAppliesOptions[7],
                      // },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "more-info",
        parentsId: "about-me",
        title: "More About Me",
        header: aboutMeMultilanguageLabels(language).headerMoreAbout,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "is-citizen",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).areUsBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).areUsTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).areUsafter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).eligibleHelpCOMBO,
            },
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).eligibleMemberHelpCombo,
              [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).eligibleOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).optionErrMsg,
              [OptionsProps.CONDITIONALS]: [
                {
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                    aboutMeMultilanguageLabels(language).eligibleOptions[1],
                  [OptionsProps.CONDITIONAL_QUESTIONS]: [
                    {
                      [Props.NAME]: "immigration-status",
                      [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).immigrationStatus,
                      [Props.TYPE]: Types.RADIO,
                      [OptionsProps.OPTIONS]:
                        aboutMeMultilanguageLabels(language).immigrationOptions,
                      [OptionsProps.OPTIONS_PER_ROW]: 2,
                      [Props.REQUIRED]: TextProps.VALUE_FALSE,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                    },
                  ],
                },
              ],
          },
         
          {
            [Props.NAME]: "language-preference",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                aboutMeMultilanguageLabels(language).laguageBefore,
              [Props.LABEL_OBJ_TRIGGER]:
                aboutMeMultilanguageLabels(language).languageTrigger,
              [Props.LABEL_OBJ_AFTER]:
                aboutMeMultilanguageLabels(language).languageAfter,
              [Props.LABEL_OBJ_HELP]:
                aboutMeMultilanguageLabels(language).comfortLanHelp,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).languageErrMsg,
            },
            [Props.TYPE]: Types.SELECT,
            [SelectProps.OPTIONS]:
            Object.keys(aboutMeMultilanguageLabels(language).LANGUAGES),
          },
          {
            [Props.NAME]: "biological-sex",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).genderOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              aboutMeMultilanguageLabels(language).genderErrmsg,
          },
          {
            [Props.NAME]: "marital-status",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).maritalStatus,
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]: "",
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[0],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose material status",
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[1],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[1] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[2],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[2] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[3],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[3] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).materialStatusOptions[4],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "dateOfBirth",
                    [Props.LABEL]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteLabel +
                      aboutMeMultilanguageLabels(language)
                        .materialStatusOptions[4] +
                      "?", //"What is this person's date of birth?",
                    [Props.TYPE]: Types.DATE,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      houseHoldSectionMultilanguageLabels(language)
                        .maritialStatusDteAlertMsg,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "ethnicity",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
            [Props.TYPE]: Types.RADIO,
            [Props.INLINE_HELP]:
              aboutMeMultilanguageLabels(language).helpEthnicity,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]:  aboutMeMultilanguageLabels(language).hisPanicOption,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
              },
              {
                [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
              },
            ],
            [OptionsProps.CLEAR_OPTION]:
              aboutMeMultilanguageLabels(language).declineToAnswerLabel,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose ethnicity",
          },
          {
            [Props.NAME]: "race",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).comboRaceLabel,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "AMIALN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "ASIAN",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BLCAFR",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "NTHPCI",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WHITE",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).tanfRaceOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Decline to answer",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  aboutMeMultilanguageLabels(language).snapRaceOptions[6],
              },
            ],
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: "Please choose race",
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "text-area",
                    [Props.TYPE]: Types.INPUT_TEXT_AREA,
                    [Props.MAX_LENGTH]: 250,
                    [Props.MIN_LENGTH]: 3,
                    [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OtherRace,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).otherRaceErrmsg,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      aboutMeMultilanguageLabels(language)
                        .otherRaceErrMsgLength,
                  },
                ],
              },
            ],
          },
          {
            [Props.TYPE]: Types.TEXT,
            [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "my-household",
    parentsId: "my-household",
    title: houseHoldSectionMultilanguageLabels(language).houseTitle, // 'My household',
    header: houseHoldSectionMultilanguageLabels(language).houseHeader,
    questions: [
      {
        [Props.NAME]: "numberInHousehold",
        [Props.LABEL]:
          houseHoldSectionMultilanguageLabels(language).howManyLabel, //'How many people live in your household, including you?',
        [Props.TYPE]: Types.NUMBERS,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          houseHoldSectionMultilanguageLabels(language).numOfPplErrMsg,
        [Props.INLINE_HELP]:      
          houseHoldSectionMultilanguageLabels(language).snapHouseHelp +'<br/><br/>' + houseHoldSectionMultilanguageLabels(language).tanfHouseHelp,          
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
          [Props.NUMBER_TEXTBOX_LABEL]:      
          houseHoldSectionMultilanguageLabels(language).numOfPplText,
        //'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    //newIndex 4
    /** Household Information sections data*/
    id: "household-information",
    parentsId: "household-information",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).titleHouseholdInformation,
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.householdInformation
    ).headerHouseholdInformation,
    questions: [
      {
        [Props.NAME]: "is-enrolled",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isEnrolledHigherLearningLabel,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isEnrolledHigherLearning",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "is-enrolled-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isEnrolledHigherLearning",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isanyone-striker",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isAnyoneStriker,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isAnyoneStriker",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isanyone-striker-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isAnyoneStriker",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasvoluntarily-quitjob",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasVoluntarilyQuitJob,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasVoluntarilyQuitJob",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasvoluntarily-quitjob-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasVoluntarilyQuitJob",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "ishousehold-boarder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isHouseholdBoarder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isHouseholdBoarder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "ishousehold-boarder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isHouseholdBoarder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isdisqualified-intlprgvltn",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isDisqualifiedIntlPrgVltn,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isDisqualifiedIntlPrgVltn",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isdisqualified-intlprgvltn-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isDisqualifiedIntlPrgVltn",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isguiltybuy-sellfoodasst",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuySellFoodAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuySellFoodAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isguiltybuy-sellfoodasst-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuySellFoodAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isGgiltybuy-firearms",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isGuiltyBuyFirearms,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isGuiltyBuyFirearms",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isGgiltybuy-firearms-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isGuiltyBuyFirearms",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasmisrepresent-identity",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasMisrepresentIdentity,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasMisrepresentIdentity",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasmisrepresent-identity-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasMisrepresentIdentity",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasconvicted-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasConvictedFelonyDrugs,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasConvictedFelonyDrugs",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasconvicted-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasConvictedFelonyDrugs",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplying-felonydrugs",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingFelonyDrugsTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingFelonyDrugsTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplying-felonydrugs-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingFelonyDrugsTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedSexualAbuseMurder,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedSexualAbuseMurder",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedSexualAbuseMurder",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "iscomplyingsexual-abusemurder",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isComplyingSexualAbuseMurderTS,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isComplyingSexualAbuseMurderTS",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "iscomplyingsexual-abusemurder-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isComplyingSexualAbuseMurderTS",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isfleeing-felonparole",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isFleeingFelonParole,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isFleeingFelonParole",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isfleeing-felonparole-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isFleeingFelonParole",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "isconvictedfraud-duplicate",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).isConvictedFraudDuplicateAsst,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "isConvictedFraudDuplicateAsst",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "isconvictedfraud-duplicate-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "isConvictedFraudDuplicateAsst",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
      {
        [Props.NAME]: "hasreceived-lottery",
        [Props.LABEL]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).hasReceivedLottery,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]: recertificationPageLabels(
          language,
          null,
          languageConstants.householdInformation
        ).options,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.ID]: "hasReceivedLottery",
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "hasreceived-lottery-cond",
                [Props.LABEL]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMem,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.householdInformation
                ).selectHouseholdMemErr,
                [Props.TYPE]: Types.CHECKBOX,
                [Props.ID]: "hasReceivedLottery",
                [OptionsProps.OPTIONS]: [],
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
    skipsubSectionNavigation: TextProps.VALUE_TRUE
  },
  {
    //newIndex 3
    //index 2
    id: "auth-rep",
    parentsId: "auth-rep",
    title: recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
    ).authSidebarTitle, // 'My household',
    header: recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
    ).authTitle,
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
    authorizedRepresentatives:{
      ebtFrstNam : null,
          ebtLstNam : null,
          ebtMdlNam : null,
          ebtPhNum : null,
          ebtSuffix : null,
          ebtWebDataChangeCode : null,
          snpFrstNam : null,
          snpLstNam : null,
          snpMdlNam : null,
          snpPhNum : null,
          snpSuffix : null,
          snpWebDataChangeCode : null,
          ebtAuthRepFlg: null,
          snapAuthRepFlg: null,
    }
    // hiddenFromNav: TextProps.VALUE_TRUE,
  },
  {
    //index:3
    id: "resources",
    parentsId: "resources",
    title: resourcesSectionMultilanguageLabels(language).resourceTitle, //'Resources',
    header: resourcesSectionMultilanguageLabels(language).resourceHeader, //"About your household's Resources",
    questions: [
      {
        [Props.NAME]: "resources",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
          resourcesSectionMultilanguageLabels(language).aboutResourceBefore,
          [Props.LABEL_OBJ_TRIGGER]:
          resourcesSectionMultilanguageLabels(language).aboutResourceTrigger,
          [Props.LABEL_OBJ_AFTER]: resourcesSectionMultilanguageLabels(language).aboutResourceAfter,
          [Props.LABEL_OBJ_HELP]:
          resourcesSectionMultilanguageLabels(language).resourceSubtitle,
        },
        [Props.INLINE_HELP]:
        resourcesSectionMultilanguageLabels(language).resourceHelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        // 'This includes money you may get from a job, the government, or other sources.',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]:
        resourcesSectionMultilanguageLabels(language).questionClearOption,
      },
    ],
    subsections: [
      {
        id: "combined-resources",
        parentsId: "resources",
        title: "",
        header: "John Doe's (8.Aug.1967) - Resource Details",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: resourcesSectionMultilanguageLabels(language).resourceSubSectiontitle,
            [Props.INLINE_HELP]:
            resourcesSectionMultilanguageLabels(language).resourceHelp,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "CASH",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SAVING",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHECK",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "STOCKS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "BONDS",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RETMNT",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "TRUST",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PPDFUN",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
              }
            ],
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-resources-subsection",
            parentsId: "resources",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            originalQuestions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
            questions: [
              {
                [Props.NAME]: "cash-on-hand-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[0],
                [OptionsProps.TABLE_DSC]: "CASH",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[0],
              },
              {
                [Props.NAME]: "saving-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[1],
                [OptionsProps.TABLE_DSC]: "SAVING",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[1], 
              },
              {
                [Props.NAME]: "checking-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[2],
                [OptionsProps.TABLE_DSC]: "CHECK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankErrMsg,
                  jointAccounterrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
                  jointAccountHolderErrMsg:
                  resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_TWO,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[2], 
              },
              {
                [Props.NAME]: "stocks-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[3],
                [OptionsProps.TABLE_DSC]: "STOCKS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[3],
              },
              {
                [Props.NAME]: "bonds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[4],
                [OptionsProps.TABLE_DSC]: "BONDS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAdditionalButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[4],
              },
              {
                [Props.NAME]: "retirement-accounts-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[5],
                [OptionsProps.TABLE_DSC]: "RETMNT",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                  bankBrokageErrMsg:
                  resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_THREE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[5],
              },
              {
                [Props.NAME]: "trust-funds-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[6],
                [OptionsProps.TABLE_DSC]: "TRUST",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[6],
              },
              {
                [Props.NAME]: "prepaid-burial-account-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[7],
                [OptionsProps.TABLE_DSC]: "PPDFUN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[7],
              },
              {
                [Props.NAME]: "other-liquid-resources-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                resourcesSectionMultilanguageLabels(language)
                    .combineResourcesoptions[8],
                [OptionsProps.TABLE_DSC]: "OTHER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
                  [Props.ERROR_MESSAGE]:
                  resourcesSectionMultilanguageLabels(language).amountErrMsg,
                },
                resourcesSection: TextProps.VALUE_TRUE,
                templateType: Types.RESOURCES_TEMP_ONE,
                srForms: TextProps.VALUE_FALSE,
                [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  resourcesSectionMultilanguageLabels(language)
                    .addcombineResourcesoptions[8],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "resources-summary",
        parentsId: "resources",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: resourcesSectionMultilanguageLabels(language).resourcesSummaryTitle, //'Resources summary',
        header: resourcesSectionMultilanguageLabels(language).resourcesSummaryHeader, //'Resources summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromSummary: true,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "income",
    parentsId: "income",
    title: incomeSectionMultilanguageLabels(language).incomeTitle, //'Income',
    header: incomeSectionMultilanguageLabels(language).incomeHeader, //"About your household's income",
    questions: [
      {
        [Props.NAME]: "income",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            incomeSectionMultilanguageLabels(language).aboutIncomeBefore, //'Does anyone in the household receive any ',
          [Props.LABEL_OBJ_TRIGGER]:
            incomeSectionMultilanguageLabels(language).aboutIncomeTrigger, //'income or benefits',
          [Props.LABEL_OBJ_AFTER]: "?",
          [Props.LABEL_OBJ_HELP]:
            incomeSectionMultilanguageLabels(language).incomeHelpCombo,
          //'Income is money you receive from working. Benefits and contributions are money you receive from a source other than employment.'
        },
        [Props.INLINE_HELP]:
          incomeSectionMultilanguageLabels(language).moneyYougettitle,
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        // 'This includes money you may get from a job, the government, or other sources.',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          "John Doe (8.Aug.1967)",
          "Marty Smith (2.Jun.1994)",
        ],
        [OptionsProps.CLEAR_OPTION]:
          incomeSectionMultilanguageLabels(language).questionClearOption,
      },
    ],
    subsections: [
      {
        id: "combined-income",
        parentsId: "income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "UI-TAFDCS",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-WAGES",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RSDI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-SSI",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-INTINC",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-MILPE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RETFND",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RRRET",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[11],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[12],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[13],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[14],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[15],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-CONTR",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[16],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[17],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-BOARD",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[18],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EI-FEDWRK",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[19],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UI-OTHER",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                    .combineIncomeoptions[20],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            parentsId: "income",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            originalQuestions: [
              {
                [Props.NAME]: "tanf-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "tanf-gross-income-0",
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "work-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-FEDWRK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Work Study'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
            questions: [
              {
                [Props.NAME]: "tanf-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "tanf-gross-income-0",
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .selfemeploymentTargetValue, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "job-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                [OptionsProps.TABLE_DSC]: "EI-WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
              },
              {
                [Props.NAME]: "social-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
              },
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
              },
              {
                [Props.NAME]: "veteran-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .unEmploymentTargetValue, //'Add another Unemployment Compensation'
              },
              {
                [Props.NAME]: "workman-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
              },
              {
                [Props.NAME]: "interest-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-INTINC",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
              },
              {
                [Props.NAME]: "military-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-MILPE",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .militaryTargetValue, //"Add another Military Allotment"
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RETFND",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.MAX_LENGTH]: 12,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
              },
              {
                [Props.NAME]: "railroad-retirement-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RRRET",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .railroadTargetValue, //"Add another Railroad Retirement"
              },
              {
                [Props.NAME]: "hud-utility-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
              },
              {
                [Props.NAME]: "income-land-rental-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "alimony-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
              },
              {
                [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UI-CONTR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language)
                    .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
              },
              {
                [Props.NAME]: "room-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
              },
              {
                [Props.NAME]: "board-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-BOARD",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
              },
              {
                [Props.NAME]: "work-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EI-FEDWRK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addAnotherButtonLabel +
                  " " +
                  incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Work Study'
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "UI-OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).optionErrMsg,
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .questionButtonLabel, //"Add another Other"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        parentsId: "income",
        active:TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: incomeSectionMultilanguageLabels(language).incomeSummaryTitle, //'Income summary',
        header: incomeSectionMultilanguageLabels(language).incomeSummaryHeader, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromSummary: true,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "expenses",
    parentsId: "expenses",
    title: incomeSectionMultilanguageLabels(language).expenseTitle, //'Expenses',
    header: incomeSectionMultilanguageLabels(language).almostLabel,
    subsections: [
      {
        id: "housing-costs",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).houseUtlilityLabel,
        header: incomeSectionMultilanguageLabels(language).expenseHeader,
        subHeader: incomeSectionMultilanguageLabels(language).expenseSubHeader,
        questions: [
          {
            [Props.NAME]: "utilities",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).anyUtilQcost, //'Does your household have any utility costs?',
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "payHeat",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "payAC",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "payACFee",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "elgas",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "hasPhone",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "waterSwgFlg",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "garbageFlg",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).utilityQOptions[6],
              },
            ],

            [OptionsProps.CLEAR_OPTION]:
              incomeSectionMultilanguageLabels(language).utilitiesClearOption,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).chooseHoldErrmsg, //'Please choose household utilities'
          },
          {
            [Props.NAME]: "utlOtherPayer",
            [Props.LABEL]: aboutMeMultilanguageLabels(language).elsePayExpenses,
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]:
              aboutMeMultilanguageLabels(language).expensesOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  aboutMeMultilanguageLabels(language).expensesOptions[0],
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "utlPayerName",
                    [Props.LABEL]:
                      aboutMeMultilanguageLabels(language).enterPyeesName,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).enterPyeesNameErrMsg,
                    [Props.MAX_LENGTH]: 15,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "type",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).houseQLabel, //'What type of housing cost does your household have?',
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "RENT",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "MTGPRN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "PRPTAX",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "HOMEIN",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CONFEE",
                [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language).houseQOptions[4],
              },
            ],
            [UnitsProps.CLEAR_OPTION]:
              incomeSectionMultilanguageLabels(language).utilitiesClearOption,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
          },
        ],
        subsections: [
          {
            id: "housing-cost-detail",
            parentsId: "expenses",
            title:
              incomeSectionMultilanguageLabels(language).houseUtlilityLabel, //'Housing and Utility Costs',
            header: incomeSectionMultilanguageLabels(language).expenseHeader, //'Tell us about your housing costs',
            subHeader:
              incomeSectionMultilanguageLabels(language).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
            completed: TextProps.VALUE_FALSE,
            hiddenFromNav: TextProps.VALUE_TRUE,
            originalQuestions: [
              {
                [Props.NAME]: "RENT",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT", //incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "MORTGAGE",

                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN", //incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
                  [Props.TYPE]: Types.UNITS,
                  [Props.INLINE_HELP]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageInlineHelpText,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageunitBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addMortageButtonLabel, //"Add another mortgage"
              },
              {
                [Props.NAME]: "PROPERTY_TAX",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX", //incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageProptaxLabel, //"How much is your household's property taxes?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortagepropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
              },
              {
                [Props.NAME]: "HOME_INSURANCE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN", //incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostQLabel, //"How much is your household's home insurance costs?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostpropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
              },
              {
                [Props.NAME]: "CONDO_FEE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE", // incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .condoFeeBeweenLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, // ['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
              },
            ],
            questions: [
              {
                [Props.NAME]: "RENT",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT", //incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
              },
              {
                [Props.NAME]: "MORTGAGE",

                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN", //incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
                  [Props.TYPE]: Types.UNITS,
                  [Props.INLINE_HELP]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageInlineHelpText,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageunitBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                    .addMortageButtonLabel, //"Add another mortgage"
              },
              {
                [Props.NAME]: "PROPERTY_TAX",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX", //incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .mortageProptaxLabel, //"How much is your household's property taxes?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .mortagepropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
              },
              {
                [Props.NAME]: "HOME_INSURANCE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN", //incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostQLabel, //"How much is your household's home insurance costs?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .insuranceCostpropBetween, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, //['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
              },
              {
                [Props.NAME]: "CONDO_FEE",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE", // incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language)
                      .condoFeeBeweenLabel, //'per',
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language)
                      .unitsOptions, // ['Week', 'Month', 'Year'],

                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  [Props.REQUIRED]: TextProps.VALUE_FALSE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "kids-and-adults",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).childTitle, //'Child or Adult Dependent Care Costs',
        header: incomeSectionMultilanguageLabels(language).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
        subHeader:
          incomeSectionMultilanguageLabels(language).childExpenseSubHeader, //incomeSectionMultilanguageLabels(language).childCostSubHeader,// 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        questions: [
          {
            [Props.NAME]: "caregivers",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language).careGiveObjbefore,
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language).careGiveObjtrigger, //'transport',
              [Props.LABEL_OBJ_AFTER]:
                incomeSectionMultilanguageLabels(language).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).careGiveHelp,
              // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
            //Enable Again once Clear Value Fixed
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "John Doe (8.Aug.1967)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-1",
                    [Props.LABEL]:
                      "Who does John Doe (8.Aug.1967) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "Marty Smith (2.Jun.1994)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "recipient-of-care-2",
                    [Props.LABEL]:
                      "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
                    [Props.TYPE]: Types.CHECKBOX,
                    [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "outHHName",
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).outHHNameLabel,
            [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).outHHNameErrorMsg,
            [Props.MAX_LENGTH]: 250,
          },
        ],
        subsections: [
          {
            id: "kids-and-adults-marty",
            parentsId: "expenses",
            title: "Marty Smith (2.Jun.1994)",
            header: "Caring for Marty Smith (2.Jun.1994)",
            subHeader:
              incomeSectionMultilanguageLabels(language).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "caring-kids-adults-cost-1",
                [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).costprovidercarelabel,
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]:
                  incomeSectionMultilanguageLabels(language).costBetween,
                [UnitsProps.UNITS]:
                  incomeSectionMultilanguageLabels(language).unitsOptions,
                [OptionsProps.TARGET_VALUE]:
                  incomeSectionMultilanguageLabels(language)
                    .unitMonthTargetValue,
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              {
                [Props.NAME]: "if-pays-transportation-1",
                [Props.LABEL]:
                  "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "transportation-cost-1",
                        [Props.LABEL]:
                          "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                        [Props.TYPE]: Types.UNITS,
                        [UnitsProps.IN_BETWEEN]:
                          incomeSectionMultilanguageLabels(language)
                            .costBetween,
                        [UnitsProps.UNITS]:
                          incomeSectionMultilanguageLabels(language)
                            .unitsOptions,
                        [OptionsProps.TARGET_VALUE]:
                          incomeSectionMultilanguageLabels(language)
                            .unitMonthTargetValue,
                        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                      },
                    ],
                  },
                ],
              },
              {
                [Props.NAME]: "drive-to-provider-1",
                [Props.LABEL]:
                  "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "driving-address-1",
                        [Props.LABEL]:
                          "How many addresses, does John drive to medical appointments or the pharmacy?",
                        [Props.TYPE]: Types.NUMBERS,
                        [Props.ERROR]: TextProps.VALUE_FALSE,
                        [Props.ERROR_MESSAGE]:
                          incomeSectionMultilanguageLabels(language)
                            .noofAppointment,
                      },
                    ],
                  },
                ],
              },
            ],
            subsections: [
              {
                id: "kids-adult-multiple-address",
                parentsId: "expenses",
                title: "Marty Smith (2.Jun.1994)",
                header: "Caring for Marty Smith (2.Jun.1994)",
                subHeader:
                  incomeSectionMultilanguageLabels(language).expenseSubHeader,
                //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
                hiddenFromNav: TextProps.VALUE_TRUE,
                questions: [
                  {
                    [Props.NAME]: "firstName",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).nameProvider,
                      [Props.TYPE]: Types.SPECIAL_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 30,
                  },
                  {
                    [Props.NAME]: "address",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language)
                        .addressProvider, //'What is the address of the provider?',
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      aboutMeMultilanguageLabels(language).zipCodeErrmsg,
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                  },
                  {
                    [Props.NAME]: "frequency-trips-1",
                    [Props.LABEL]:
                    incomeSectionMultilanguageLabels(language).householddrivelabel,
                    [Props.TYPE]: Types.UNITS,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween, //'per',
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions, //['Week', 'Month', 'Year'],
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "child-support",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).childSupTitle, //'Child Support Costs',
        header: incomeSectionMultilanguageLabels(language).tellusAbout, //'Tell us about your Child support costs',
        questions: [
          {
            [Props.NAME]: "with-child-support",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegalbefore, // 'Does anyone in the household have a legal obligation (court order) to pay ',
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegaltrigger, //'child support',
              [Props.LABEL_OBJ_AFTER]:
                incomeSectionMultilanguageLabels(language)
                  .childSupportQlegalAfter, //' to a child not living with you?',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).childSupportHelp,
              //'Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.'
            },
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
            //Enable Again once Clear Value Fixed
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "John Doe (8.Aug.1967)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "child-support-cost-1",
                    [Props.LABEL]:
                      "What is the child support cost for John Doe (8.Aug.1967)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  {
                    [Props.NAME]: "for-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).forWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                  {
                    [Props.NAME]: "to-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).toWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                ],
              },
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  "Marty Smith (2.Jun.1994)",
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "child-support-cost-2",
                    [Props.LABEL]:
                      "What is the child support cost for Marty Smith (2.Jun.1994)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .unitsOptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  {
                    [Props.NAME]: "for-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).forWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                  {
                    [Props.NAME]: "to-who-paid",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).toWhomPaid,
                    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.MAX_LENGTH]: 20,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "medical-expenses",
        parentsId: "expenses",
        title: incomeSectionMultilanguageLabels(language).medicalTitle, //'Medical Costs',
        header: incomeSectionMultilanguageLabels(language).medicalHeader, //"Tell us about your medical costs",
        subHeader: "", //incomeSectionMultilanguageLabels(language).medicalSubHeader,
        // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        questions: [
          {
            [Props.NAME]: "with-medical-expenses",
            [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpBefore,
            [Props.SUB_LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                incomeSectionMultilanguageLabels(language)
            .medicalInlineSeethisFull +
              ' <a href="https://dhr.alabama.gov/county-office-contact/" target="_blank" rel="noopener noreferrer">' +
              incomeSectionMultilanguageLabels(language).medicalInlineEligibleMedical +
              "</a>" +
              '<p>' +  incomeSectionMultilanguageLabels(language).fulllist + '</p>' +
              '<p>' +  incomeSectionMultilanguageLabels(language).tellUsPocketMedicalExpense + '</p>' +  
              '<p>' +  incomeSectionMultilanguageLabels(language).beSureLegally + '</p>' +
              '<ul><li>' +  incomeSectionMultilanguageLabels(language).allMedicalExpenseVerified  + '</li>' +
              '<li>' +  incomeSectionMultilanguageLabels(language).verfiedExpenseMay    + '</li>' +
              '<li>' +   incomeSectionMultilanguageLabels(language).acceptableForms + '</li>' +
              '<li>' +   incomeSectionMultilanguageLabels(language).obtainVerificationNeeded + '</li></ul>'
                  + incomeSectionMultilanguageLabels(language).medicalExpAfter,
              //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
              [Props.LABEL_OBJ_TRIGGER]:
                incomeSectionMultilanguageLabels(language).medicalExpTrigger,
                // [Props.LABEL_OBJ_AFTER]:
                // incomeSectionMultilanguageLabels(language).medicalExpAfter,
              // ', over the counter medications, dental or eye care, adult diapers, etc.?',
              [Props.LABEL_OBJ_HELP]:
                incomeSectionMultilanguageLabels(language).medicalExphelp,                
              // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'            
            },
            [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [Props.TYPE]: Types.CHECKBOX,
            [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
            [OptionsProps.OPTIONS]: [
              "John Doe (8.Aug.1967)",
              "Marty Smith (2.Jun.1994)",
            ],
          }
        ],
        subsections: [
          {
            id: "medical-expenses-type-1",
            parentsId: "expenses",
            title: "John Doe (8.Aug.1967)",
            header: "Medical cost for John Doe (8.Aug.1967)",
            // subHeader:
            //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
            // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: 'medicalExpenseType',
                [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpenseType,
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: [
                  {
                    [OptionsProps.OPTION_VALUE]: 'MDENT',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).doctorOrDentist,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HSPTL',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHome,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MEDCTN',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionDrugs,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'OTCMED',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).overTheCounterMedicine,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HINSPR',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).healthInsurancePremiums,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MSUPPL',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicalSupplies,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'MDCOSH',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicaidCostSharing,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'DHP',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).denturesOrHearingAids,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'GLSSES',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlases,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'TRSPRT',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServices,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'HHACC',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).HudHomeHealthAide,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'DOG',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).serviceAnimal,
                  },
                  {
                    [OptionsProps.OPTION_VALUE]: 'OTHER',
                    [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).otherMedicalExpense,
                  }
                ]
              }
            ],
            subsections: [
              {
                id: "medical-expenses-filter-1",
                parentsId: "expenses",
                title: "John Doe (8.Aug.1967)",
                header: "Medical cost for John Doe (8.Aug.1967)",
                // subHeader:
                //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
                // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
                hiddenFromNav: TextProps.VALUE_TRUE,
                questions: [
                  {
                    [Props.NAME]: 'MDENT',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).doctorOrDentist,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDENT"
                  },
                  {
                    [Props.NAME]: 'HSPTL',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHome,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HSPTL"
                  },
                  {
                    [Props.NAME]: 'MEDCTN',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionDrugs,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MEDCTN"
                  },
                  {
                    [Props.NAME]: 'OTCMED',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).overTheCounterMedicine,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]: incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTCMED"
                  },
                  {
                    [Props.NAME]: 'HINSPR',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).healthInsurancePremiums,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HINSPR"
                  },
                  {
                    [Props.NAME]: 'MSUPPL',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalSupplies,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MSUPPL"
                  },
                  {
                    [Props.NAME]: 'MDCOSH',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicaidCostSharing,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDCOSH"
                  },
                  {
                    [Props.NAME]: 'DHP',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).denturesOrHearingAids,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DHP"
                  },
                  {
                    [Props.NAME]: 'GLSSES',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlases,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]: incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "GLSSES"
                  },
                  {
                    [Props.NAME]: 'TRSPRT',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServices,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRSPRT"
                  },
                  {
                    [Props.NAME]: 'HHACC',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).HudHomeHealthAide,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HHACC"
                  },
                  {
                    [Props.NAME]: 'DOG',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).serviceAnimal,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DOG"
                  },
                  {
                    [Props.NAME]: 'OTHER',
                    [Props.LABEL]: incomeSectionMultilanguageLabels(language).otherMedicalExpense,
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.UNITS]: incomeSectionMultilanguageLabels(language).freqTripoptions,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
                    [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER"
                  }
                ],
                completed: TextProps.VALUE_FALSE,
                subsections: [
                  {
                    id: "medical-driving-1",
            parentsId: "expenses",
            title: "John Doe (8.Aug.1967)",
            header: "Medical cost for John Doe (8.Aug.1967)",
            subHeader:
              incomeSectionMultilanguageLabels(language).expenseSubHeader,
            // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: "driving-1",
                [Props.LABEL]:
                  "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
                [Props.TYPE]: Types.RADIO,
                [OptionsProps.OPTIONS]:
                  incomeSectionMultilanguageLabels(language).yesnoOptions,
                [OptionsProps.OPTIONS_PER_ROW]: 2,
                [OptionsProps.CONDITIONALS]: [
                  {
                    [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language).targetValueYes,
                    [OptionsProps.CONDITIONAL_QUESTIONS]: [
                      {
                        [Props.NAME]: "driving-address-1",
                        [Props.LABEL]:
                          "How many addresses, does John drive to medical appointments or the pharmacy??",
                        [Props.TYPE]: Types.NUMBERS,
                        [Props.ERROR]: TextProps.VALUE_FALSE,
                        [Props.ERROR_MESSAGE]:
                          incomeSectionMultilanguageLabels(language)
                            .noofAppointment,
                      },
                    ],
                  },
                ],
                      }
                    ]
              },
              {
                id: "medical-address-1",
                parentsId: "expenses",
                title: "Medical Appointment for John Doe",
                header: "Medical Appointment for John Doe",
                hiddenFromNav: TextProps.VALUE_TRUE,
                completed: TextProps.VALUE_FALSE,
                questions: [
                  {
                    [Props.NAME]: "address",
                    [Props.LABEL]:
                      incomeSectionMultilanguageLabels(language).medicalAddress,
                    // 'What is the address of the medical appointment or pharmacy?',
                    [Props.TYPE]: Types.ADDRESS,
                    [Props.REQUIRED]: TextProps.VALUE_FALSE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language).zipErrmsg, //'Please enter a valid Zip code'
                    [Props.ERROR_MESSAGE_FOR_CHAR]:
                      aboutMeMultilanguageLabels(language).citiErrmsg,
                    [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                      aboutMeMultilanguageLabels(language).addressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                      aboutMeMultilanguageLabels(language).homeAddressErrmsg,
                    [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                      aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
                  },
                  {
                    [Props.NAME]: "frequency-trips-1",
                    [Props.LABEL]:
                      "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
                    [Props.TYPE]: Types.UNITS,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCountErrMsgLen,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .freqTripoptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                  },
                  {
                    [Props.NAME]: "cost-trips-1",
                    [Props.LABEL]:
                      "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                      incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                      incomeSectionMultilanguageLabels(language)
                        .freqTripoptions,
                    [OptionsProps.TARGET_VALUE]:
                      incomeSectionMultilanguageLabels(language)
                        .unitMonthTargetValue,
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                    [Props.ERROR_MESSAGE_LENGTH]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCostErrMsgLen,
                    [Props.ERROR_MESSAGE]:
                      incomeSectionMultilanguageLabels(language)
                        .tripCostErrMsgLen,
                  },
                ],
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "ebt-card-request",
    parentsId: "ebt-card-request",
    title: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    header: aboutMeMultilanguageLabels(language).ebtCardSectionTitle,
    questions: [
      {
        [Props.NAME]: "need-ebt-card",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).needEbtCard,
        [Props.INLINE_HELP]:
          aboutMeMultilanguageLabels(language).inlineHelpEbtcard,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]:
              aboutMeMultilanguageLabels(language).houseLessOptions[0],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.TYPE]: Types.HTML_TEXT,
                [TextProps.VALUE]: aboutMeMultilanguageLabels(language).ebtNote,
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "summary",
    parentsId: "summary",
    title: incomeSectionMultilanguageLabels(language).summaryLabel,
    header: incomeSectionMultilanguageLabels(language).summaryLabel,
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "sign-and-submit",
    parentsId: "sign-and-submit",
    title: incomeSectionMultilanguageLabels(language).signSubmitLabel, //'Sign & submit',
    header: incomeSectionMultilanguageLabels(language).signSubmitHeader, //'Sign & submit',
    subHeader: incomeSectionMultilanguageLabels(language).signSubmitSub, //'You made it through the form! Now for the easy stuff.',
    subsections: [
      {
        id: "submitted",
        title: incomeSectionMultilanguageLabels(language).subSectionLabel, //'Submitted',
        header:
          incomeSectionMultilanguageLabels(language).comboSubmitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];
export default snapSections;
//Constant for SNAP application household template.
export const snapHouseholdTemplate = {
  id: "household-member",
  parentsId: "my-household",
  title: houseHoldSectionMultilanguageLabels(language).houseMemberTitle,
  header: houseHoldSectionMultilanguageLabels(language).submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseFirstName, //"What is this person's first name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).firstErrmsg, //'Please enter first name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).firstNameErrMsgLength,
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleLabel, //"What is this person's middle name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleName, //'Please enter middle name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).middleNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseLastNameLabel, //"What is this person's last name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).enterLastname, //'Please enter last name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).lastNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandMaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandFaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandDaugther,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseDobAlertMsg,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).genderErrmsg,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          houseHoldSectionMultilanguageLabels(language).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]:
          houseHoldSectionMultilanguageLabels(language).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]:
          houseHoldSectionMultilanguageLabels(language).citizenHelpComboApplication,
      },
      [Props.INLINE_HELP]:
        houseHoldSectionMultilanguageLabels(language).citizenInhelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).optionErrMsg,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).ssnQLabel,
      [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).inlineHelpSSN,
      [Props.TYPE]: Types.INPUT_SSN,
      [Props.CHECKED]: TextProps.VALUE_FALSE,
      [InputProps.CLEAR_LABEL]:
        houseHoldSectionMultilanguageLabels(language).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).ssnErrLabel,
    },
    {
      [Props.NAME]: "ethnicity",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).membersEthnicity,
      [Props.TYPE]: Types.RADIO,
      [Props.INLINE_HELP]:
        aboutMeMultilanguageLabels(language).snapHelpEthnicity,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]:  aboutMeMultilanguageLabels(language).hisPanicOption,
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
        },
        {
          [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
        },
      ],
      [OptionsProps.CLEAR_OPTION]:
        aboutMeMultilanguageLabels(language).declineToAnswerLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose ethnicity",
    },
    {
      [Props.NAME]: "race",
      [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).raceHelp,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "AMIALN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ASIAN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BLCAFR",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "NTHPCI",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WHITE",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "Decline to answer",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[6],
        },
      ],
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose race",
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "text-area",
              [Props.TYPE]: Types.INPUT_TEXT_AREA,
              [Props.MAX_LENGTH]: 250,
              [Props.MIN_LENGTH]: 3,
              [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OtherRace,
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).otherRaceErrmsg,
              [Props.ERROR_MESSAGE_LENGTH]:
                aboutMeMultilanguageLabels(language)
                  .otherRaceErrMsgLength,
            },
          ],
        },
      ],
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for TANF application hosehold template.
export const tanfHouseholdTemplate = {
  id: "household-member",
  parentsId: "my-household",
  title: houseHoldSectionMultilanguageLabels(language).houseMemberTitle,
  header: houseHoldSectionMultilanguageLabels(language).submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseFirstName, //"What is this person's first name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).firstErrmsg, //'Please enter first name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).firstNameErrMsgLength,
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleLabel, //"What is this person's middle name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleName, //'Please enter middle name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).middleNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseLastNameLabel, //"What is this person's last name?",
        [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).enterLastname, //'Please enter last name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).lastNameErrMsgLength,
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandMaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandFaDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandDaugther,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]:
            houseHoldSectionMultilanguageLabels(language).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseDobAlertMsg,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).genderErrmsg,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          houseHoldSectionMultilanguageLabels(language).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]:
          houseHoldSectionMultilanguageLabels(language).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]:
          houseHoldSectionMultilanguageLabels(language).tanfCitizenHelp,
      },
      [Props.INLINE_HELP]:
        houseHoldSectionMultilanguageLabels(language).citizenInhelp,
        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).optionErrMsg,
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]:
            aboutMeMultilanguageLabels(language).eligibleOptions[1],
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "immigration-status",
              [Props.LABEL]:
              aboutMeMultilanguageLabels(language).immigrationStatus,
              [Props.TYPE]: Types.RADIO,
              [OptionsProps.OPTIONS]:
                aboutMeMultilanguageLabels(language).immigrationOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [Props.CHECKED]: TextProps.VALUE_FALSE,
      [InputProps.CLEAR_LABEL]:
        houseHoldSectionMultilanguageLabels(language).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).ssnErrLabel,
    },
    {
      [Props.NAME]: "ethnicity",
      [Props.LABEL]: aboutMeMultilanguageLabels(language).labelEthnicity,
      [Props.TYPE]: Types.RADIO,
      [Props.INLINE_HELP]:
        aboutMeMultilanguageLabels(language).tanfhelpEthnicity,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]:  aboutMeMultilanguageLabels(language).hisPanicOption,
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).hisPanicOption, //'Hispanic/Latino'
        },
        {
          [OptionsProps.OPTION_VALUE]: aboutMeMultilanguageLabels(language).nonhisPanicOption,
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).nonhisPanicOption, //'Non-Hispanic/Latino'
        },
      ],
      [OptionsProps.CLEAR_OPTION]:
        aboutMeMultilanguageLabels(language).declineToAnswerLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose ethnicity",
    },
    {
      [Props.NAME]: "race",
      [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).tanfRaceLabel,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "AMIALN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ASIAN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BLCAFR",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "NTHPCI",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WHITE",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "Decline to answer",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[6],
        },
      ],
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose race",
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "text-area",
              [Props.TYPE]: Types.INPUT_TEXT_AREA,
              [Props.MAX_LENGTH]: 250,
              [Props.MIN_LENGTH]: 3,
              [InputProps.PLACEHOLDER]: aboutMeMultilanguageLabels(language).OtherRace,
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).otherRaceErrmsg,
              [Props.ERROR_MESSAGE_LENGTH]:
                aboutMeMultilanguageLabels(language)
                  .otherRaceErrMsgLength,
            },
          ],
        },
      ],
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for household disability tempalte for all applications.
export const householdDisabilityTemplate = {
  id: "household-details",
  parentsId: "my-household",
  title: houseHoldSectionMultilanguageLabels(language).hholdHeader,
  header: houseHoldSectionMultilanguageLabels(language).houseHeader,
  questions: [
    {
      [Props.NAME]: "disability",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          houseHoldSectionMultilanguageLabels(language).disabilityQLabel,
        [Props.LABEL_OBJ_TRIGGER]:
          houseHoldSectionMultilanguageLabels(language).disabilityQTriggerLabel,
        [Props.LABEL_OBJ_AFTER]:
          houseHoldSectionMultilanguageLabels(language).disabilityQlabel2 + "?",
        [Props.LABEL_OBJ_HELP]:
          houseHoldSectionMultilanguageLabels(language).disabilityHelp,
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],

      [OptionsProps.CLEAR_OPTION]:
        houseHoldSectionMultilanguageLabels(language).disablilityCler,
    },
  ],
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_FALSE,
  completed: TextProps.VALUE_FALSE,
};
//Constant for income section template
export const IncomeSectionTemplate = {
  id: "combined-income",
  parentsId: "income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "UI-TAFDCS",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-WAGES",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RSDI",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-SSI",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-INTINC",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-MILPE",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RETFND",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RRRET",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[11],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[12],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[13],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[14],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[15],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-CONTR",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[16],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[17],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-BOARD",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[18],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-FEDWRK",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language)
              .combineIncomeoptions[19],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-OTHER",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[20],
        },
      ],
      [OptionsProps.CLEAR_OPTION]:
        incomeSectionMultilanguageLabels(language).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      parentsId: "income",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      originalQuestions: [
        {
          [Props.NAME]: "tanf-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).tanfTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "tanf-gross-income-0",
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "job-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).jobTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
        },
        {
          [Props.NAME]: "social-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).socialTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
        },
        {
          [Props.NAME]: "veteran-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).veteranTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-VETBEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue, //'Add another Unemployment Compensation'
        },
        {
          [Props.NAME]: "workman-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workmanTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
        },
        {
          [Props.NAME]: "interest-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).interestTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).militaryTargetValue, //"Add another Military Allotment"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.MAX_LENGTH]: 12,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
        },
        {
          [Props.NAME]: "railroad-retirement-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).railroadTargetValue, //"Add another Railroad Retirement"
        },
        {
          [Props.NAME]: "hud-utility-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue, //"Add another HUD Utility Assistance"
        },
        {
          [Props.NAME]: "income-land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).childSuppTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
        },
        {
          [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
        },
        {
          [Props.NAME]: "room-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).roomerTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-ROOMER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
        },
        {
          [Props.NAME]: "board-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).boaderTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
        },
        {
          [Props.NAME]: "work-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workStudyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "FEDWRK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Work Study'
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).questionButtonLabel, //"Add another Other"
        },
      ],
      questions: [
        {
          [Props.NAME]: "tanf-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).tanfTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "tanf-gross-income-0",
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "job-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).jobTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
        },
        {
          [Props.NAME]: "social-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).socialTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
        },
        {
          [Props.NAME]: "veteran-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).veteranTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-VETBEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue, //'Add another Unemployment Compensation'
        },
        {
          [Props.NAME]: "workman-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workmanTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
        },
        {
          [Props.NAME]: "interest-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).interestTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).militaryTargetValue, //"Add another Military Allotment"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.MAX_LENGTH]: 12,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
        },
        {
          [Props.NAME]: "railroad-retirement-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).railroadTargetValue, //"Add another Railroad Retirement"
        },
        {
          [Props.NAME]: "hud-utility-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue, //"Add another HUD Utility Assistance"
        },
        {
          [Props.NAME]: "income-land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).childSuppTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
        },
        {
          [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
        },
        {
          [Props.NAME]: "room-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).roomerTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-ROOMER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
        },
        {
          [Props.NAME]: "board-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).boaderTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
        },
        {
          [Props.NAME]: "work-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "FEDWRK", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workStudyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "FEDWRK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workStudyTargetValue, //'Add another Work Study'
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).questionButtonLabel, //"Add another Other"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for resources section template
export const ResourcesSectionTemplate = {
  id: "combined-resources",
  parentsId: "resources",
  title: "",
  header: "John Doe's (8.Aug.1967) - Resource Details",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]: resourcesSectionMultilanguageLabels(language).resourceSubSectiontitle,
      [Props.INLINE_HELP]:
      resourcesSectionMultilanguageLabels(language).resourceHelp,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "CASH",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SAVING",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHECK",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "STOCKS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BONDS",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RETMNT",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "TRUST",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PPDFUN",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
        }
      ],
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
      resourcesSectionMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
    },
  ],
  subsections: [
    {
      id: "combined-resources-subsection",
      parentsId: "resources",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      originalQuestions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
      questions: [
        {
          [Props.NAME]: "cash-on-hand-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CASH",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[0],
          [OptionsProps.TABLE_DSC]: "CASH",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[0],
        },
        {
          [Props.NAME]: "saving-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SAVING", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[1],
          [OptionsProps.TABLE_DSC]: "SAVING",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[1], 
        },
        {
          [Props.NAME]: "checking-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHECK", 
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[2],
          [OptionsProps.TABLE_DSC]: "CHECK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_TWO,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankErrMsg:
            resourcesSectionMultilanguageLabels(language).bankErrMsg,
            jointAccounterrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccounterrMsg,
            jointAccountHolderErrMsg:
            resourcesSectionMultilanguageLabels(language).jointAccountHolderErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_TWO,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_TWO, amount: "",bankName:"",accountnumber:"",jointAccount:"",jointAccountHolder:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "",bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : "",jointAccountRequired: TextProps.VALUE_FALSE, jointAccountError: TextProps.VALUE_FALSE, jointAccountErrorMessage : "",jointAccountHolderRequired: TextProps.VALUE_FALSE, jointAccountHoldertError: TextProps.VALUE_FALSE, jointAccountHolderErrorMessage : "",}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[2], 
        },
        {
          [Props.NAME]: "stocks-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "STOCKS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[3],
          [OptionsProps.TABLE_DSC]: "STOCKS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[3],
        },
        {
          [Props.NAME]: "bonds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "BONDS",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[4],
          [OptionsProps.TABLE_DSC]: "BONDS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAdditionalButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[4],
        },
        {
          [Props.NAME]: "retirement-accounts-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RETMNT",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[5],
          [OptionsProps.TABLE_DSC]: "RETMNT",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_THREE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
            bankBrokageErrMsg:
            resourcesSectionMultilanguageLabels(language).bankBrokageErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_THREE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "" , bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_THREE, amount: "",bankName:"", amountRequired: TextProps.VALUE_FALSE, amountError: TextProps.VALUE_FALSE, amountErrorMessage : "", bankNameRequired: TextProps.VALUE_FALSE, bankNameError: TextProps.VALUE_FALSE, bankNameErrorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[5],
        },
        {
          [Props.NAME]: "trust-funds-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRUST",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[6],
          [OptionsProps.TABLE_DSC]: "TRUST",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[6],
        },
        {
          [Props.NAME]: "prepaid-burial-account-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PPDFUN",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[7],
          [OptionsProps.TABLE_DSC]: "PPDFUN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[7],
        },
        {
          [Props.NAME]: "other-liquid-resources-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
          resourcesSectionMultilanguageLabels(language)
              .combineResourcesoptions[8],
          [OptionsProps.TABLE_DSC]: "OTHER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.TYPE]: Types.RESOURCES_TEMP_ONE,
            [Props.ERROR_MESSAGE]:
            resourcesSectionMultilanguageLabels(language).amountErrMsg,
          },
          resourcesSection: TextProps.VALUE_TRUE,
          templateType: Types.RESOURCES_TEMP_ONE,
          srForms: TextProps.VALUE_FALSE,
          [OptionsProps.TARGET_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [OptionsProps.ANSWER_VALUE]: [{ type:"resources",templateType: Types.RESOURCES_TEMP_ONE, amount: "",additionalInformation:"", required: TextProps.VALUE_FALSE, error: TextProps.VALUE_FALSE, errorMessage : ""}],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language)
              .addAnotherButtonLabel +
            " " +
            resourcesSectionMultilanguageLabels(language)
              .addcombineResourcesoptions[8],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
}
//Constant for income summary template
export const incomeSummaryTemplate = {
  id: "income-summary",
  parentsId: "income",
  active:TextProps.VALUE_FALSE,
  hiddenFromNav: true,
  title: incomeSectionMultilanguageLabels(language).incomeSummaryTitle, //'Income summary',
  header: incomeSectionMultilanguageLabels(language).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
  hiddenFromSummary: true,
};
//Constant for expense housingCost template
export const expenseHousingCostTemplate = {
  id: "housing-costs",
  parentsId: "expenses",
  title: incomeSectionMultilanguageLabels(language).houseUtlilityLabel,
  header: incomeSectionMultilanguageLabels(language).expenseHeader,
  subHeader: incomeSectionMultilanguageLabels(language).expenseSubHeader,
  questions: [
    {
      [Props.NAME]: "utilities",
      [Props.LABEL]: incomeSectionMultilanguageLabels(language).anyUtilQcost, //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,

      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "payHeat",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).utilityQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payAC",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).utilityQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payACFee",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).utilityQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "elgas",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).utilityQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "hasPhone",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).utilityQOptions[4],
        },
      ],

      [OptionsProps.CLEAR_OPTION]:
        incomeSectionMultilanguageLabels(language).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        incomeSectionMultilanguageLabels(language).chooseHoldErrmsg, //'Please choose household utilities'
    },
    {
      [Props.NAME]: "type",
      [Props.LABEL]: incomeSectionMultilanguageLabels(language).houseQLabel, //'What type of housing cost does your household have?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "RENT",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).houseQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MTGPRN",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).houseQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PRPTAX",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).houseQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "HOMEIN",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).houseQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONFEE",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).houseQOptions[4],
        },
      ],
      [UnitsProps.CLEAR_OPTION]:
        incomeSectionMultilanguageLabels(language).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        incomeSectionMultilanguageLabels(language).houseCostErrmsg, //'Please choose housing costs'
    },
  ],
  subsections: [
    {
      id: "housing-cost-detail",
      parentsId: "expenses",
      title: incomeSectionMultilanguageLabels(language).houseUtlilityLabel, //'Housing and Utility Costs',
      header: incomeSectionMultilanguageLabels(language).expenseHeader, //'Tell us about your housing costs',
      subHeader: incomeSectionMultilanguageLabels(language).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
      completed: TextProps.VALUE_FALSE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      originalQuestions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).mortageunitBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).mortagepropBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language)
                .insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
        },
      ],
      questions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).rentTargetValue,//"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).mortageTargetValue,//"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).mortageunitBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).propertyTaxTargetValue,//"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).mortagepropBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).homeInsurTargetValue,//"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language)
                .insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE",
          // [OptionsProps.CONDITIONAL_TARGET_VALUE]: incomeSectionMultilanguageLabels(language).condoFeeTargetValue,//"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              incomeSectionMultilanguageLabels(language).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).condoButtonLabel, //"Add another condo fee"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for KidsAndAdults expense template
export const expenseKidsAndAdultsTemplate = {
  id: "kids-and-adults",
  parentsId: "expenses",
  title: incomeSectionMultilanguageLabels(language).childTitle, //'Child or Adult Dependent Care Costs',
  header: incomeSectionMultilanguageLabels(language).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
  subHeader: incomeSectionMultilanguageLabels(language).childExpenseSubHeader, //incomeSectionMultilanguageLabels(language).childCostSubHeader,// 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          incomeSectionMultilanguageLabels(language).careGiveObjbefore,
        [Props.LABEL_OBJ_TRIGGER]:
          incomeSectionMultilanguageLabels(language).careGiveObjtrigger, //'transport',
        [Props.LABEL_OBJ_AFTER]:
          incomeSectionMultilanguageLabels(language).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
        [Props.LABEL_OBJ_HELP]:
          incomeSectionMultilanguageLabels(language).careGiveHelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]:
                incomeSectionMultilanguageLabels(language).questionClearOption, //'No one'
            },
          ],
        },
      ],
    },
  ],
  subsections: [
    {
      id: "kids-and-adults-marty",
      parentsId: "expenses",
      title: "Marty Smith (2.Jun.1994)",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader:
        incomeSectionMultilanguageLabels(language).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
          incomeSectionMultilanguageLabels(language).costprovidercarelabel,
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]:
            incomeSectionMultilanguageLabels(language).costBetween,
          [UnitsProps.UNITS]:
            incomeSectionMultilanguageLabels(language).unitsOptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]:
            incomeSectionMultilanguageLabels(language).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                incomeSectionMultilanguageLabels(language).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                  [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]:
            incomeSectionMultilanguageLabels(language).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                incomeSectionMultilanguageLabels(language).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      subsections: [
        {
          id: "kids-adult-multiple-address",
          parentsId: "expenses",
          title: "Marty Smith (2.Jun.1994)",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader:
            incomeSectionMultilanguageLabels(language).expenseSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "firstName",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).nameProvider,
                [Props.TYPE]: Types.SPECIAL_TEXT,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.MAX_LENGTH]: 30,
            },
            {
              [Props.NAME]: "address",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).zipCodeErrmsg,
              [Props.ERROR_MESSAGE_FOR_CHAR]:
                aboutMeMultilanguageLabels(language).citiErrmsg,
              [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                aboutMeMultilanguageLabels(language).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                aboutMeMultilanguageLabels(language).homeAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
              incomeSectionMultilanguageLabels(language)
              .childSupportTripLabel,
              // "How often does your household drive Marty Smith (2.Jun.1994) to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [Props.ERROR_MESSAGE]:
                incomeSectionMultilanguageLabels(language).tripCountErrMsgLen,
              [Props.ERROR_MESSAGE_LENGTH]:
                incomeSectionMultilanguageLabels(language).tripCountErrMsgLen,
              [UnitsProps.IN_BETWEEN]:
                incomeSectionMultilanguageLabels(language).costBetween, //'per',
              [UnitsProps.UNITS]:
                incomeSectionMultilanguageLabels(language).unitsOptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for ChildSupport expense template
export const expenseChildSupportTemplate = {
  id: "child-support",
  parentsId: "expenses",
  title: incomeSectionMultilanguageLabels(language).childSupTitle, //'Child Support Costs',
  header: incomeSectionMultilanguageLabels(language).tellusAbout, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "with-child-support",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          incomeSectionMultilanguageLabels(language).childSupportQlegalbefore, // 'Does anyone in the household have a legal obligation (court order) to pay ',
        [Props.LABEL_OBJ_TRIGGER]:
          incomeSectionMultilanguageLabels(language).childSupportQlegaltrigger, //'child support',
        [Props.LABEL_OBJ_AFTER]:
          incomeSectionMultilanguageLabels(language).childSupportQlegalAfter, //' to a child not living with you?',
        [Props.LABEL_OBJ_HELP]:
          incomeSectionMultilanguageLabels(language).childSupportHelp,
        //'Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-1",
              [Props.LABEL]:
                "What is the child support cost for John Doe (8.Aug.1967)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]:
                incomeSectionMultilanguageLabels(language).costBetween,
              [UnitsProps.UNITS]:
                incomeSectionMultilanguageLabels(language).unitsOptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "for-who-paid",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).forWhomPaid,
              [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.MAX_LENGTH]: 20,
            },
            {
              [Props.NAME]: "to-who-paid",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).toWhomPaid,
              [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.MAX_LENGTH]: 20,
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-2",
              [Props.LABEL]:
                "What is the child support cost for Marty Smith (2.Jun.1994)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]:
                incomeSectionMultilanguageLabels(language).costBetween,
              [UnitsProps.UNITS]:
                incomeSectionMultilanguageLabels(language).unitsOptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Monthly" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
            {
              [Props.NAME]: "for-who-paid",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).forWhomPaid,
              [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.MAX_LENGTH]: 20,
            },
            {
              [Props.NAME]: "to-who-paid",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).toWhomPaid,
              [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.MAX_LENGTH]: 20,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for Medical expense template
export const expenseMedicalExpenseTemplate = {
  id: "medical-expenses",
  parentsId: "expenses",
  title: incomeSectionMultilanguageLabels(language).medicalTitle, //'Medical Costs',
  header: incomeSectionMultilanguageLabels(language).medicalHeader, //"Tell us about your medical costs",
  subHeader: "", //incomeSectionMultilanguageLabels(language).medicalSubHeader,
  // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "with-medical-expenses",
      [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpBefore,
      [Props.SUB_LABEL]: {
        [Props.LABEL_OBJ_BEFORE]:
          incomeSectionMultilanguageLabels(language)
      .medicalInlineSeethisFull +
        ' <a href="https://dhr.alabama.gov/county-office-contact/" target="_blank" rel="noopener noreferrer">' +
        incomeSectionMultilanguageLabels(language).medicalInlineEligibleMedical +
        "</a>" +
        '<p>' +  incomeSectionMultilanguageLabels(language).fulllist + '</p>' +
        '<p>' +  incomeSectionMultilanguageLabels(language).tellUsPocketMedicalExpense + '</p>' +  
        '<p>' +  incomeSectionMultilanguageLabels(language).beSureLegally + '</p>' +
        '<ul><li>' +  incomeSectionMultilanguageLabels(language).allMedicalExpenseVerified  + '</li>' +
        '<li>' +  incomeSectionMultilanguageLabels(language).verfiedExpenseMay    + '</li>' +
        '<li>' +   incomeSectionMultilanguageLabels(language).acceptableForms + '</li>' +
        '<li>' +   incomeSectionMultilanguageLabels(language).obtainVerificationNeeded + '</li></ul>'
            + incomeSectionMultilanguageLabels(language).medicalExpAfter,
        //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
        [Props.LABEL_OBJ_TRIGGER]:
          incomeSectionMultilanguageLabels(language).medicalExpTrigger,
          // [Props.LABEL_OBJ_AFTER]:
          // incomeSectionMultilanguageLabels(language).medicalExpAfter,
        // ', over the counter medications, dental or eye care, adult diapers, etc.?',
        [Props.LABEL_OBJ_HELP]:
          incomeSectionMultilanguageLabels(language).medicalExphelp,                
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'            
      },
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
    }
  ],
  subsections: [
    {
      id: "medical-expenses-type-1",
      parentsId: "expenses",
      title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
      // subHeader:
      //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: 'medicalExpenseType',
          [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalExpenseType,
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: 'MDENT',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).doctorOrDentistOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'HSPTL',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHomeOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'MEDCTN',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionDrugsOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'OTCMED',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).overTheCounterMedicineOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'HINSPR',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).healthInsurancePremiumsOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'MSUPPL',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicalSuppliesOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'MDCOSH',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).medicaidCostSharingOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'DHP',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).denturesOrHearingAidsOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'GLSSES',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlasesOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'TRSPRT',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServicesOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'HHACC',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).HudHomeHealthAideOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'DOG',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).serviceAnimalOptions,
            },
            {
              [OptionsProps.OPTION_VALUE]: 'OTHER',
              [OptionsProps.OPTION_DISPLAY]: incomeSectionMultilanguageLabels(language).otherMedicalExpenseOptions,
            }
          ]
        }
      
      ],
      subsections: [
        {
          id: "medical-expenses-filter-1",
          parentsId: "expenses",
          title: "John Doe (8.Aug.1967)",
          header: "Medical cost for John Doe (8.Aug.1967)",
          // subHeader:
          //   incomeSectionMultilanguageLabels(language).expenseSubHeader,
          // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: 'MDENT',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).doctorOrDentist,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDENT"
            },
            {
              [Props.NAME]: 'HSPTL',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).hospitalOrNursingHome,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]: incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HSPTL"
            },
            {
              [Props.NAME]: 'MEDCTN',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionDrugs,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MEDCTN"
            },
            {
              [Props.NAME]: 'OTCMED',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).overTheCounterMedicine,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTCMED"
            },
            {
              [Props.NAME]: 'HINSPR',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).healthInsurancePremiums,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HINSPR"
            },
            {
              [Props.NAME]: 'MSUPPL',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicalSupplies,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MSUPPL"
            },
            {
              [Props.NAME]: 'MDCOSH',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).medicaidCostSharing,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MDCOSH"
            },
            {
              [Props.NAME]: 'DHP',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).denturesOrHearingAids,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DHP"
            },
            {
              [Props.NAME]: 'GLSSES',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).prescriptionEyeGlases,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "GLSSES"
            },
            {
              [Props.NAME]: 'TRSPRT',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).transportationOrLoadingForMedicalServices,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "TRSPRT"
            },
            {
              [Props.NAME]: 'HHACC',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).HudHomeHealthAide,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HHACC"
            },
            {
              [Props.NAME]: 'DOG',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).serviceAnimal,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "DOG"
            },
            {
              [Props.NAME]: 'OTHER',
              [Props.LABEL]: incomeSectionMultilanguageLabels(language).otherMedicalExpense,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.UNITS]:  incomeSectionMultilanguageLabels(language).freqTripoptions,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [OptionsProps.TARGET_VALUE]: [{ amount: '' }],
              [OptionsProps.ANSWER_VALUE]: [{ amount: '' }],
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER"
            },
            {
              [Props.NAME]: "driving-1",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).medicalAppointmentsOrPharmacy,
              [Props.TYPE]: Types.RADIO,
              [OptionsProps.OPTIONS]:
                incomeSectionMultilanguageLabels(language).yesnoOptions,
              [OptionsProps.OPTIONS_PER_ROW]: 2,
              [OptionsProps.CONDITIONALS]: [
                {
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                    incomeSectionMultilanguageLabels(language).targetValueYes,
                  [OptionsProps.CONDITIONAL_QUESTIONS]: [
                    {
                      [Props.NAME]: "driving-address-1",
                      [Props.LABEL]:
                        incomeSectionMultilanguageLabels(language).howManyMedicalAppointmentsOrPharmacy,
                      [Props.TYPE]: Types.NUMBERS,
                      [Props.ERROR]: TextProps.VALUE_FALSE,
                      [Props.ERROR_MESSAGE]:
                        incomeSectionMultilanguageLabels(language)
                          .noofAppointment,
                    },
                  ],
                },
              ],
            }
          ],
          completed: TextProps.VALUE_FALSE,
          subsections: [
            {
              id: "medical-driving-1",
              parentsId: "expenses",
              title: "John Doe (8.Aug.1967)",
              header: "Medical cost for John Doe (8.Aug.1967)",
              subHeader:
                incomeSectionMultilanguageLabels(language).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "driving-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]:
            incomeSectionMultilanguageLabels(language).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                incomeSectionMultilanguageLabels(language).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy??",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                            incomeSectionMultilanguageLabels(language)
                              .noofAppointment,
                },
              ],
            },
          ],
                }
      ],
      subsections: [
        {
          id: "medical-address-1",
          parentsId: "expenses",
          title: "Medical Appointment for John Doe",
          header: "Medical Appointment for John Doe",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]:
                incomeSectionMultilanguageLabels(language).medicalAddress,
              // 'What is the address of the medical appointment or pharmacy?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                incomeSectionMultilanguageLabels(language).zipErrmsg, //'Please enter a valid Zip code'
              [Props.ERROR_MESSAGE_FOR_CHAR]:
                aboutMeMultilanguageLabels(language).citiErrmsg,
              [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                aboutMeMultilanguageLabels(language).addressErrmsg,
              [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                aboutMeMultilanguageLabels(language).homeAddressErrmsg,
              [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                aboutMeMultilanguageLabels(language).mailingAddressErrmsg,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [Props.ERROR_MESSAGE]:
                        incomeSectionMultilanguageLabels(language)
                          .tripCountErrMsgLen,
              [Props.ERROR_MESSAGE_LENGTH]:
                        incomeSectionMultilanguageLabels(language)
                          .tripCountErrMsgLen,
              [UnitsProps.IN_BETWEEN]:
                incomeSectionMultilanguageLabels(language).costBetween,
              [UnitsProps.UNITS]:
                        incomeSectionMultilanguageLabels(language)
                          .freqTripoptions,
                      [OptionsProps.TARGET_VALUE]:
                        incomeSectionMultilanguageLabels(language)
                          .unitMonthTargetValue,
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
            {
              [Props.NAME]: "cost-trips-1",
              [Props.LABEL]:
                "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]:
                incomeSectionMultilanguageLabels(language).costBetween,
              [UnitsProps.UNITS]:
                        incomeSectionMultilanguageLabels(language)
                          .freqTripoptions,
                      [OptionsProps.TARGET_VALUE]:
                        incomeSectionMultilanguageLabels(language)
                          .unitMonthTargetValue,
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.ERROR_MESSAGE_LENGTH]:
                        incomeSectionMultilanguageLabels(language)
                          .tripCostErrMsgLen,
              [Props.ERROR_MESSAGE]:
                        incomeSectionMultilanguageLabels(language)
                          .tripCostErrMsgLen,
            },
          ],
                }
              ]
            }
          
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
//Constant for expense template
export const ExpenseSectionTemplate = {
  id: "expenses",
  parentsId: "expenses",
  title: incomeSectionMultilanguageLabels(language).expenseTitle, //'Expenses',
  header: incomeSectionMultilanguageLabels(language).almostLabel,
  subsections: [
    expenseHousingCostTemplate,
    expenseKidsAndAdultsTemplate,
    expenseChildSupportTemplate,
    expenseMedicalExpenseTemplate,
  ],
  completed: TextProps.VALUE_FALSE,
};
//Shelter Template
export const ShelterSectionTemplate = {
  [Props.NAME]: "shelterOtherPayer",
  [Props.LABEL]: aboutMeMultilanguageLabels(language).outsideHouseholdPayee,
  [Props.TYPE]: Types.RADIO,
  [OptionsProps.OPTIONS]: aboutMeMultilanguageLabels(language).expensesOptions,
  [OptionsProps.OPTIONS_PER_ROW]: 2,
  [OptionsProps.CONDITIONALS]: [
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]:
        aboutMeMultilanguageLabels(language).expensesOptions[0],
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "shelterPayerName",
          [Props.LABEL]:
            aboutMeMultilanguageLabels(language).outsideHouseholdPayeesName,
          [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeMultilanguageLabels(language)
              .outsideHouseholdPayeesNameErrMsg,
          [Props.MAX_LENGTH]: 15,
        },
      ],
    },
  ],
};


export const medicalDriving = {
  [Props.NAME]: "driving-1",
  [Props.LABEL]:
    "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
  [Props.TYPE]: Types.RADIO,
  [OptionsProps.OPTIONS]:
    incomeSectionMultilanguageLabels(language).yesnoOptions,
  [OptionsProps.OPTIONS_PER_ROW]: 2,
  [OptionsProps.CONDITIONALS]: [
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]:
        incomeSectionMultilanguageLabels(language).targetValueYes,
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "driving-address-1",
          [Props.LABEL]:
            "How many addresses, does John drive to medical appointments or the pharmacy??",
          [Props.TYPE]: Types.NUMBERS,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            incomeSectionMultilanguageLabels(language)
              .noofAppointment,
        },
      ],
    },
  ],
};

export const medicalOtherCostQuestion = [{
  [Props.NAME]: "otherMedical",
  [Props.LABEL]: aboutMeMultilanguageLabels(language).OthersMedicalHelp,
  [Props.TYPE]: Types.INPUT_TEXT_AREA,
  [Props.MAX_LENGTH]: 250,
  [Props.ERROR]: TextProps.VALUE_FALSE,
  [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).medicalOtherErrormsg,
  [Props.ERROR_MESSAGE_LENGTH]:
    aboutMeMultilanguageLabels(language).medicalOtherLengthErrormsg,
},
{
  [Props.TYPE]: Types.TEXT,
  [TextProps.VALUE]:
  incomeSectionMultilanguageLabels(language).prepareInterview,
}
];


export const houseHoldInformationTemplate = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  agencyID: "",
  isEnrolledHigherLearning: TextProps.VALUE_N,
  isAnyoneStriker: TextProps.VALUE_N,
  hasVoluntarilyQuitJob: TextProps.VALUE_N,
  isHouseholdBoarder: TextProps.VALUE_N,
  isDisqualifiedIntlPrgVltn: TextProps.VALUE_N,
  isGuiltyBuySellFoodAsst: TextProps.VALUE_N,
  isGuiltyBuyFirearms: TextProps.VALUE_N,
  hasMisrepresentIdentity: TextProps.VALUE_N,
  hasConvictedFelonyDrugs: TextProps.VALUE_N,
  isComplyingFelonyDrugsTS: TextProps.VALUE_N,
  isConvictedSexualAbuseMurder: TextProps.VALUE_N,
  isComplyingSexualAbuseMurderTS: TextProps.VALUE_N,
  isFleeingFelonParole: TextProps.VALUE_N,
  isConvictedFraudDuplicateAsst: TextProps.VALUE_N,
  hasReceivedLottery: TextProps.VALUE_N,
};
