import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { getMainSections } from "./selfdeclareSummary";
import { INPUT_ALPHA_TEXT } from "../../../utils/constants/controls";
import Checkbox from "../../../utils/components/checkbox";
import DTAInputSubmit from "../../../utils/components/dtaInputSubmit";
import * as languageConstants from "../../../utils/constants/constants";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText";
import { covidRecertPageLabels } from "../../recertCovid/recertCovidText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import * as TextProps from "../../../utils/constants/text";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === languageConstants.langOptSel) {
    language = "en-US";
  }

class SignAndSubmit extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
    };
  }

  render() {
    const { sections } = this.props.selfDeclare;
    var covidRecert = JSON.parse(sessionStorage.getItem("covidRecert"));
    let signatureErrorVal;
    if (covidRecert) {
      if (this.props.signatureError) {
        signatureErrorVal = (
          <b>
            <p style={{ color: "red" }}>
              {
                covidRecertPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signatureErrorMsg
              }
            </p>
          </b>
        );
      } else {
        signatureErrorVal = <p></p>;
      }
    } else {
      if (this.props.signatureError) {
        signatureErrorVal = (
          <b>
            <p className="error-text-clr">{this.props.signatureErrorMsg}</p>
          </b>
        );
      } else {
        signatureErrorVal = <p></p>;
      }
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.viewApplicationDataPopupOpen,
          this.closeModal,
          "save-progress-modal summary-progress-modal",
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryTitle,
          null,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryHeader,
          <div className="dta-modal__body pad-all">
            {getMainSections(sections, this.state.viewApplicationDataPopupOpen)}
          </div>
        )}
        <form id={this.props.submitFormId}>
          <strong>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).agreeContent[0]
            }
          </strong>
          <ul>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[1]
              }
            </li>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[2]
              }
            </li>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[3]
              }
            </li>
          </ul>
          <div>
            {this.props.agreeError ? (
              <b>
                <p className="error-text-clr">{this.props.agreeErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.props.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.props.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            {covidRecert ? (
              <p className="interim-recert-heading">
                {
                  covidRecertPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).signYourAppllication
                }
              </p>
            ) : (
              <p className="interim-recert-heading">
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).signYourAppllication
                }
              </p>
            )}
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>{signatureErrorVal}</div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            value={this.props.signature}
            ref="applicanSign"
            onChange={this.props.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.props.signatureError}
          />
        </form>
      </div>
    );
  }
}

export default SignAndSubmit;
