import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";

import * as Constants from "../../constants/controls";
import * as InputProps from "../../constants/input";
import * as OptionsProps from "../../constants/options";
import * as Props from "../../components/shared";
import * as SelectProps from "../../constants/select";
import * as Types from "../../constants/types";
import * as UnitsProps from "../../constants/units";
import DTACustomizedSelect from "../dtaCustomizedSelect";
import Address from "../address";
import Statecounty from "../stateCounty";
import AddressCommon from "../addressCommon";
import Checkbox from "../checkbox";
import DTADate from "../dtaDate";
import DTADateRange from "../dtaDateRange";
import DTAInput from "../dtaInput";
import DTAInputTwo from "../dtaInputTwo";
import DTASelect from "../dtaSelect";
import Numbers from "../numbers";
import Radio from "../radio";
import Units from "../units";
import ResourcesTemplateOne from "../resourcesTemplateOne";
import ResourcesTemplateTwo from "../resourcesTemplateTwo";
import ResourcesTemplateThree from "../resourceTemplateThree";
import NoFreqUnits from "../noFreqUnits";
import EarnedIncome from "../earnedIncome";
import MedicalExpenses from "../medicalExpenses";
import DTAButton from "../dtaButton";

import Grouped from "./grouped";
import Nongrouped from "./nongrouped";
import RepeatableQuestion from "./repeatableQuestion";
import {
  addStringIf,
  extractFromObject,
  isNone,
} from "../../components/utilities/controls";
import UnEarnedIncome from "../unEarnedIncome";
import ShelterExpense from "../shelterExpense";
import DTATextArea from "../dtaTextArea";
import * as TextProps from "../../constants/text";
import {AL_STATES  } from "../../constants/controls";
/*
  ControlWrapper
  --------------

  # Purpose:
  To act as a bridge between the question data object and the JSX markup that needs to be
  rendered from the question data object. This component acts as a resolver that takes in a
  data object that is one of the valid question types (not a non-question type) and generates
  complete markup for that data object. This component will also pass through all properties
  to the control components themselves, so you can set data-binding props on this component
  and those props will be propagated down to the control component itself.

  NOTE: you do not need to manage `id`s. The Grouped and Nongrouped component used by this
    ControlWrapper component take care of generating unique ids and passing those unique ids
    and appropriate aria attributes to the question components.

  # Props:
  type: Required, string value representing one of the valid question types. Note that some types
    included in the question data object are valid, but are not question types so are not handled
    by this component. This component focuses on rendering markup for questions exclusively.
  data: Required, object containing all of the information to render the markup. For the provided
    question type, see the `types.js` file for documentation on which proerties are required
    and which properties are optional for the question type provided.
  className: Optional, class to be applied to the wrapper component
  questionClass: Optional, class to applied to the `legend` if this question type needs to be
    rendered in a `fieldset` and to the `label` otherwise.
  controlContainerClass: Optional, class to be applied to the container of the control
  disabled: Optional, boolean indicating whether or not this question should be disabled. Note
    that the disabled state takes precedence over the error state.
  error: Optional, boolean indicating whether or not this question has an error
  errorMessage: Optional, string error message that should be displayed upon an error

  # Example:
  <ControlWrapper
    {...additionalDataBindingProperties}
    questionClass={this._resolveLabelClassFromName(questionData[Props.NAME])}
    type={questionData[Props.TYPE]}
    data={questionData}
  />
 */

class ControlWrapper extends Component {
  static propTypes = {
    // required
    type: PropTypes.oneOf(_.values(Types)).isRequired,
    data: PropTypes.object.isRequired,
    // container
    className: PropTypes.string,
    questionClass: PropTypes.string,
    controlContainerClass: PropTypes.string,
    labelHidden: PropTypes.bool,
    // states
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  };
  static defaultProps = {
    // container
    className: "",
    questionClass: "",
    controlContainerClass: "",
    labelHidden: TextProps.VALUE_FALSE,
    // states
    disabled: TextProps.VALUE_FALSE,
    error: TextProps.VALUE_FALSE,
    errorMessage: "",
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
  }

  render() {
    const {
        error,
        errorMessage,
        disabled,
        data,
        type,
        className,
        controlContainerClass,
        questionClass,
        labelHidden,
      } = this.props,
      name = data[Props.NAME],
      required = data[Props.REQUIRED],
      maxLength = data[Props.MAX_LENGTH],
      isResidentialAddress = data[Props.IS_RESIDENTIAL_ADDRESS],
      wrapperOptions = {
        ...this._extractWrapperOptions(data),
        error,
        disabled,
        errorMessage,
        className,
        questionClass,
        labelHidden,
        name
      };

    switch (type) {
      case Types.REPEATABLE_QUESTION:
        return (
          <RepeatableQuestion
            {
              ...this
                .props /* For passing through value bindings and change handlers*/
            }
          />
        );

      case Types.INPUT_ALPHA_TEXT_SUBMIT:
      case Types.INPUT_ALPHA_TEXT:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={Constants.INPUT_ALPHA_TEXT}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={maxLength || 30}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
        case Types.SPECIAL_TEXT:
          return (
            <Nongrouped {...wrapperOptions}>
              <DTAInput
                {
                  ...this
                    .props /* For passing through value bindings and change handlers*/
                }
                name={name}
                type={Constants.SPECIAL_TEXT}
                className={addStringIf(
                  controlContainerClass,
                  "dta-form__control"
                )}
                error={error}
                disabled={disabled}
                required={required}
                maxLength={maxLength || 30}
                placeholder={data[InputProps.PLACEHOLDER]}
                showClear={!!data[InputProps.CLEAR_LABEL]}
                clearLabel={data[InputProps.CLEAR_LABEL]}
              />
            </Nongrouped>
          );
  
      case Types.INPUT_EMAIL:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="email"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={50}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_TEXT:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="text"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={maxLength || 30}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_SEARCH:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="search"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={30}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_PHONE:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="tel"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );

      case Types.INPUT_TEXT_AREA:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTATextArea
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name="textValue"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={maxLength}
              placeholder={data[InputProps.PLACEHOLDER]}
            />
          </Nongrouped>
        );

      case Types.INPUT_ALPHANUMERIC_TEXT:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={Constants.INPUT_ALPHANUMERIC_TEXT}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={maxLength}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_NUMERIC_ONLY:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="number"
              numericOnly={TextProps.VALUE_TRUE}
              blocks={[maxLength]}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_NUMERIC_ONLY_TWO:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInputTwo
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={Constants.INPUT_TYPE_DOLLAR}
              numericOnly={TextProps.VALUE_TRUE}
              blocks={[maxLength]}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control input_numberic_two"
              )}
              error={error}
              disabled={disabled}
              required={required}
              placeholder={"$"}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
              inputLabel={data[InputProps.INPUT_LABEL]}
            />
          </Nongrouped>
        );
        
        case Types.INPUT_DOLLAR:       
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={Constants.INPUT_TYPE_MONEY}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={maxLength || 10}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );

      case Types.INPUT_SSN:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={Constants.INPUT_TYPE_SSN}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={11}
              placeholder={data[InputProps.PLACEHOLDER]}
              showClear={!!data[InputProps.CLEAR_LABEL]}
              clearLabel={data[InputProps.CLEAR_LABEL]}
            />
          </Nongrouped>
        );
      case Types.INPUT_EBT:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAInput
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type="ebt"
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
              maxLength={23}
            />
          </Nongrouped>
        );
      case Types.SELECT:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTASelect
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              options={data[SelectProps.OPTIONS]}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.SELECT_TWO:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTASelect
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              options={data[SelectProps.OPTIONS]}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control source-income-select"
              )}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
        case Types.DROPDOWN_STATE_COUNTY:
          return (
            <Grouped {...wrapperOptions}>
            <Statecounty
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              error={error}
              disabled={disabled}
              required={required}
              className={controlContainerClass}
            />
          </Grouped>     
          );      
      case Types.BUTTON:
        return (
          <Nongrouped {...wrapperOptions}>
            <DTAButton
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.UNITS:
        return (
          <Nongrouped {...wrapperOptions}>
            <Units
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              inBetween={data[UnitsProps.IN_BETWEEN]}
              options={data[UnitsProps.UNITS]}
              isNotEmptyOption={
                data[UnitsProps.ISNOTEMPTYOPTION] || TextProps.VALUE_FALSE
              }
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              error={error}
              maxLength={data[UnitsProps.MAX_LENGTH] || 12}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.RESOURCES_TEMP_ONE:
        return (
          <Nongrouped {...wrapperOptions}>
            <ResourcesTemplateOne
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
            />
          </Nongrouped>
        );
        case Types.RESOURCES_TEMP_TWO:
          return (
            <Nongrouped {...wrapperOptions}>
              <ResourcesTemplateTwo
                {
                  ...this
                    .props /* For passing through value bindings and change handlers*/
                }
              />
            </Nongrouped>
          );
        case Types.RESOURCES_TEMP_THREE:
          return (
            <Nongrouped {...wrapperOptions}>
              <ResourcesTemplateThree
                {
                  ...this
                    .props /* For passing through value bindings and change handlers*/
                }
              />
            </Nongrouped>
          );
      case Types.NOFREQ_UNITS:
        return (
          <Nongrouped {...wrapperOptions}>
            <NoFreqUnits
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              inBetween={data[UnitsProps.IN_BETWEEN]}
              options={data[UnitsProps.UNITS]}
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.EARNED_INCOME:
        return (
          <Nongrouped {...wrapperOptions}>
            <EarnedIncome
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              options={data[UnitsProps.UNITS]}
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              unitLabel={data.unitLabel}
              amountLabel={data.amountLabel}
              employerLabel={data.employerLabel}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.UNEARNED_INCOME:
        return (
          <Nongrouped {...wrapperOptions}>
            <UnEarnedIncome
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              options={data[UnitsProps.UNITS]}
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              unitLabel={data.unitLabel}
              amountLabel={data.amountLabel}
              employerLabel={data.employerLabel}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.SHELTER_EXPENSE:
        return (
          <Nongrouped {...wrapperOptions}>
            <ShelterExpense
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              inBetween={data[UnitsProps.IN_BETWEEN]}
              options={data[UnitsProps.UNITS]}
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              data={data}
              unitLabel={data.unitLabel}
              amountLabel={data.amountLabel}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.MEDICAL_EXPENSES:
        return (
          <Nongrouped {...wrapperOptions}>
            <MedicalExpenses
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__control"
              )}
              options={data[UnitsProps.UNITS]}
              showClear={!isNone(data[UnitsProps.CLEAR_OPTION])}
              clearLabel={data[UnitsProps.CLEAR_OPTION]}
              money={data[UnitsProps.MONEY]}
              unitLabel={data.unitLabel}
              amountLabel={data.amountLabel}
              employerLabel={data.employerLabel}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Nongrouped>
        );
      case Types.DATE:
        return (
          <Grouped {...wrapperOptions}>
            <DTADate
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              error={error}
              disabled={disabled}
              required={required}
              className={controlContainerClass}
            />
          </Grouped>
        );
      case Types.DATE_RANGE:
        return (
          <Grouped {...wrapperOptions}>
            <DTADateRange
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              error={error}
              disabled={disabled}
              required={required}
              className={controlContainerClass}
            />
          </Grouped>
        );
      case Types.ADDRESS:
        return (
          <Grouped {...wrapperOptions}>
            <Address
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              error={error}
              disabled={disabled}
              required={required}
              className={controlContainerClass}
              isResidentialAddress={isResidentialAddress}
            />
          </Grouped>
        );
      case Types.ADDRESS_COMMON:
        return (
          <Grouped {...wrapperOptions}>
            <AddressCommon
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              type={type}
              error={error}
              disabled={disabled}
              required={required}
              className={controlContainerClass}
            />
          </Grouped>
        );
      case Types.NUMBERS:
        return (
          <Grouped {...wrapperOptions}>
            <Numbers
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__option-list"
              )}
              optionClass="dta-form__option"
              error={error}
              disabled={disabled}
              required={required}
            />
          </Grouped>
        );
      case Types.RADIO:
        return (
          <Grouped {...wrapperOptions}>
            <Radio
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__option-list"
              )}
              optionClass={addStringIf(
                this.props.optionClass,
                `dta-form__option dta-form__option--width-1-of-${
                  data[OptionsProps.OPTIONS_PER_ROW]
                }`
              )}
              options={data[OptionsProps.OPTIONS]}
              showClear={!isNone(data[OptionsProps.CLEAR_OPTION])}
              clearLabel={data[OptionsProps.CLEAR_OPTION]}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Grouped>
        );
      case Types.CHECKBOX:
        return (
          <Grouped {...wrapperOptions}>
            <Checkbox
              {
                ...this
                  .props /* For passing through value bindings and change handlers*/
              }
              name={name}
              className={addStringIf(
                controlContainerClass,
                "dta-form__option-list"
              )}
              optionClass={addStringIf(
                this.props.optionClass,
                `dta-form__option dta-form__option--width-1-of-${
                  data[OptionsProps.OPTIONS_PER_ROW]
                }`
              )}
              options={data[OptionsProps.OPTIONS]}
              clearPosition={data[OptionsProps.CLEAR_POSITION] || "bottom"}
              showClear={!isNone(data[OptionsProps.CLEAR_OPTION])}
              clearLabel={data[OptionsProps.CLEAR_OPTION]}
              error={error}
              disabled={disabled}
              required={required}
            />
          </Grouped>
        );
      default:
        throw Error(
          `${type} may be a valid type, but is not a valid QUESTION type.`
        );
    }
  }

  // Helpers
  // -------

  _extractWrapperOptions(data) {
    const {
      NAME,
      LABEL,
      LABEL_HAS_MARKUP,
      INLINE_HELP,
      INLINE_HELP_HAS_MARKUP,
      REQUIRED,
      SUB_LABEL
    } = Props;
    return extractFromObject(
      [
        NAME,
        LABEL,
        LABEL_HAS_MARKUP,
        INLINE_HELP,
        INLINE_HELP_HAS_MARKUP,
        REQUIRED,
        SUB_LABEL
      ],
      data
    );
  }
}

export default ControlWrapper;
