import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import { CommonTypes, CommonDefaults } from "./propTypes";
import {
  STATES,
  STATES_FULL,
  INPUT_ALPHA_TEXT,
  INPUT_ALPHANUMERIC_SPACE_TEXT,
} from "../../utils/constants/controls";
import { tryCall } from "../components/utilities/controls";
import _ from "lodash";
import { screeningStaticLabels } from "../../pages/screening/screeningText";
import { ZIPCODE } from "../constants/zipCode";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class AddressCommon extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onZipCodeChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      county: PropTypes.string,
      state: PropTypes.oneOf(["", ...STATES]),
      zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    //setting Default MA State
    let copyProps = _.cloneDeep(props);
    this.state = this._buildStateFromProps(copyProps);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this._buildStateFromProps(nextProps));
    }
  }

  render() {
    const { error, disabled, required, name, className } = this.props,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={className}>
        {this._buildFirstLine(name, ariaLabels, error, disabled, required)}
        {this._buildSecondLine(name, ariaLabels, error, disabled, required)}
      </div>
    );
  }

  // Rendering
  // ---------

  _buildFirstLine(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-g margin-bottom-half">
        <div className="pure-u-1">
          <label
            className="dta-form__label dta-form__label--size-small"
            htmlFor={this.nextUniqueId()}
          >
            {screeningStaticLabels(selectedLanguage).streetAddr}
          </label>
          <DTAInput
            name={name}
            type={INPUT_ALPHANUMERIC_SPACE_TEXT}
            className="dta-input--expand-width"
            id={this.lastUniqueId()}
            aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
            onChange={this._handleChangeForStreet}
            value={this.state._value.street}
            error={error}
            disabled={disabled}
            required={required}
            maxLength={40}
            placeholder={
              screeningStaticLabels(selectedLanguage).streetAddrPlaceholder
            }
          />
        </div>
      </div>
    );
  }

  _buildSecondLine(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-g">
        <div className="pure-u-2-5 pad-right-half">
          <label
            className="dta-form__label dta-form__label--size-small"
            htmlFor={this.nextUniqueId()}
          >
            {screeningStaticLabels(selectedLanguage).zipCode}
          </label>
          <DTAInput
            name={name}
            type="number"
            blocks={[5]}
            numericOnly={TextProps.VALUE_TRUE}
            className="dta-input--expand-width"
            id={this.lastUniqueId()}
            aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
            onChange={this._handleChangeForZipCode}
            value={this.state._value.zipCode}
            error={error}
            disabled={disabled}
            required={required}
          />
        </div>
        <div className="pure-u-2-5 pad-right-half">
          <label
            className="dta-form__label dta-form__label--size-small"
            htmlFor={this.nextUniqueId()}
          >
            {screeningStaticLabels(selectedLanguage).cityLabel}
          </label>
          <DTAInput
            name={name}
            type={INPUT_ALPHA_TEXT}
            className="dta-input--expand-width"
            id={this.lastUniqueId()}
            aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
            onChange={this._handleChangeForCity}
            value={this.state._value.city}
            error={error}
            disabled={disabled}
            required={required}
            maxLength={30}
          />
        </div>
        <div className="pure-u-1-5">
          <label
            className="dta-form__label dta-form__label--size-small"
            htmlFor={this.nextUniqueId()}
          >
            {screeningStaticLabels(selectedLanguage).stateLabel}
          </label>
          <DTASelect
            name={name}
            type={this.props.type}
            options={STATES_FULL}
            className="dta-select--expand-width"
            id={this.lastUniqueId()}
            aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
            onChange={this._handleChangeForState}
            value={this.state._value.state}
            error={error}
            disabled={disabled}
            required={required}
          />
        </div>
      </div>
    );
  }

  // Events
  // ------

  _handleChangeForStreet = (streetValue) => {
    const newValue = {
      street: streetValue,
      city: this.state._value.city,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      county: this.state._value.county,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForZipCode = (zipValue) => {
    let city,
      cityArr = [],
      maZipCode;
    ZIPCODE.find((obj) => {
      if (obj.ZIPCODE == zipValue) {
        if (obj.ZIPCODE == zipValue) {
          cityArr.push(obj.CITY);
          if (cityArr.length > 1) {
            city = "";
          } else {
            city = obj.CITY;
            maZipCode = TextProps.DEFAULT_STATE;
          }
        }
      }
    });
    const newValue = {
      street: this.state._value.street,
      city: city === undefined ? this.state._value.city : city,
      state: maZipCode === undefined ? this.state._value.state : maZipCode,
      zipCode: zipValue,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
    tryCall(this.props.onZipCodeChange, zipValue);
  };

  _handleChangeForCity = (cityValue) => {
    const newValue = {
      street: this.state._value.street,
      city: cityValue,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      county: this.state._value.county,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForCounty = (countyValue) => {
    const newValue = {
      street: this.state._value.street,
      city: this.state._value.city,
      state: this.state._value.state,
      county: countyValue,
      zipCode: this.state._value.zipCode,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForState = (stateValue) => {
    const newValue = {
      street: this.state._value.street,
      city: this.state._value.city,
      state: stateValue,
      zipCode: this.state._value.zipCode,
      county: this.state._value.county,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  // Helpers
  // -------

  _buildStateFromProps(props) {
    if (props.value) {
      return {
        _value: props.value,
      };
    } else {
      return {
        _value: { street: "", city: "", state: "", zipCode: "" },
      };
    }
  }
}

export default AddressCommon;
