import axios from "axios";
import { sections } from "../../pages/applyPebt/applyPebtMapData";
import { getAudit } from "../../utils/constants/audit";
var TigerURL = require("../../utils/constants/appConfigConstants").TigerURL;

const actionTypes = {
  activatePEBTPagesAction: "ACTIVATE_PEBT_PAGES_FULLFILLED",
  postApplyData: "POST_APPLY_PEBT_INFO_DATA",
  fillInputValuesAction: "FILL_PEBT_INPUT_VALUES_STATE",
  postActivePEBTData: "ACTIVE_PEBT_CASE_SUCCESS",
  serviceFailedAction: "ACTIVE_PEBT_CASE_FAILED",
  getPEBTCaseNumber: "GET_ACTIVE_PEBT_CASE",
};

export function activatePEBTQuestionsSections() {
  return (dispatch) => {
    return dispatch(activatePEBTSections(sections));
  };
}

function activatePEBTSections(data) {
  return {
    type: actionTypes.activatePEBTPagesAction,
    payload: data,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

function questionsInputValuesAction(payload) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: payload,
  };
}

export function submitReplacePEBT(application, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = TigerURL + "/apipublic/security/pebtcardreplacerequest";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
        },
      };
      axios
        .post(url, application, options)
        .then(function (data) {
          dispatch(saveApplyData(data));
          resolve(data);
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}

function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}

export function updateDefault() {
  return (dispatch) => {
    return dispatch({
      type: "default",
    });
  };
}
export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_PEBT_APPLY_ANSWER",
      payload: data,
    });
  };
}

export function submitPEBTCaseNumber(pebtNumber, language) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const audit = getAudit();
      var url = TigerURL + "/apipublic/security/pebtcaseinfo";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
        },
      };
      var payload = {
        panNumber: pebtNumber,
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(submitPEBTData(response));
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
        });
    });
  };
}

function submitPEBTData(data) {
  return {
    type: actionTypes.postActivePEBTData,
    payload: data,
  };
}

function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}

export function getPEBTCaseNumber() {
  return {
    type: actionTypes.getPEBTCaseNumber,
  };
}
