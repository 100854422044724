import { aboutMeMultilanguageLabels } from '../../pages/applySnap/applySnapText';
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

const moment = require('moment');

export function formatDateToCustomFormat(inputDate) {
    const formattedDate  = moment(inputDate).format("MM/DD/YYYY hh:mm A");
    return formattedDate;
}

export function getFlagVariableNameForDay(dayName) {
  let flagName = "";

  switch (dayName) {
    case "Monday":
      flagName = "mondayFlag";
      break;
    case "Tuesday":
      flagName = "tuesdayFlag";
      break;
    case "Wednesday":
      flagName = "wednesdayFlag";
      break;
    case "Thursday":
      flagName = "thursdayFlag";
      break;
    case "Friday":
      flagName = "fridayFlag";
      break;
    case "Same Day":
      flagName = "samedayFlag";
      break;
    default:
      break;
  }

  return flagName;
}

export function getSpanishFlagVariableNameForDay(dayName) {
  let flagName = "";
  switch (dayName) {
    case "Lunes":
      flagName = "mondayFlag";
      break;
    case "Martes":
      flagName = "tuesdayFlag";
      break;
    case "Miércoles":
      flagName = "wednesdayFlag";
      break;
    case "Jueves":
      flagName = "thursdayFlag";
      break;
    case "Viernes":
      flagName = "fridayFlag";
      break;
    case "Mismo día":
      flagName = "samedayFlag";
      break;
    default:
      break;
  }

  return flagName;
}

const selectedLanguage =
helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const getDaysFlagObject = (daysLookup, selectedDays) => {
  let flagsObject = null;

  flagsObject = daysLookup.reduce((acc, current) => {
    if(selectedLanguage === "es-ES") {
      const flagVarName = getSpanishFlagVariableNameForDay(current);
      let flagValue = "";
      if (Array.isArray(selectedDays) && selectedDays.length) {
        flagValue = selectedDays.includes(current) ? "Y" : "";
      }
      return { ...acc, ...(flagVarName ? { [flagVarName]: flagValue } : null) };
    }
    else {
      const flagVarName = getFlagVariableNameForDay(current);
      let flagValue = "";
      if (Array.isArray(selectedDays) && selectedDays.length) {
        flagValue = selectedDays.includes(current) ? "Y" : "";
      }
      return { ...acc, ...(flagVarName ? { [flagVarName]: flagValue } : null) };
    }
  }, {});

  return flagsObject;
};

export const getTimeMeridienFlagObject = (
  meridienLookup,
  selectedMeridiens
) => {
  let flagsObject = null;

  flagsObject = meridienLookup.reduce((acc, current) => {
    let flagVarName = null;
    let flagValue = "";
    if (current === "AM") {
      flagVarName = "amFlag";
    } else if (current === "PM") {
      flagVarName = "pmFlag";
    } else {
      flagVarName = "";
    }

    if (Array.isArray(selectedMeridiens) && selectedMeridiens.length) {
      flagValue = selectedMeridiens.includes(current) ? "Y" : "";
    }

    return { ...acc, ...(flagVarName ? { [flagVarName]: flagValue } : null) };
  }, {});

  return flagsObject;
};
export const getInterviewType =(interviewType,language) => {
  switch (interviewType) {
    case aboutMeMultilanguageLabels(language).inOfficeLable:
      return aboutMeMultilanguageLabels(language).inOfficeValue;
    case aboutMeMultilanguageLabels(language).telephoneLable:
      return aboutMeMultilanguageLabels(language).telephoneValue
    default:
      return interviewType;
  }
}