import React from "react";
import Pagination from "react-js-pagination";

function pagination(props) {
  const {
    displayPage,
    activePage,
    itemsCount,
    pageRangeDisplayed,
    handlePageChange,
  } = props;

  return (
    <div className="provider-client-list-header__label">
      <div className="provider-client-list-header__label-text text--gray"></div>
      <div className="provider-client-list-header__help-button">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={displayPage}
          totalItemsCount={itemsCount}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
}

export default pagination;
