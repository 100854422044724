import React, { Component } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  addStringIf,
  tryCall,
  validateAria,
  isNone,
} from "../components/utilities/controls";
import { getDocumentTypeCode } from "../components/utilities/utils";
import { CommonTypes, CommonDefaults } from "./propTypes";
import * as SelectProps from "../constants/select";
import {
  getConsumerDocumentList,
  getConsumerSelectedDocumentList,
} from "../../redux/consumer/consumerDocumentsActions";
import * as TextProps from "../constants/text";
import {AL_STATES,STATES_FULL } from "../constants/controls";
class DTACustomizedSelect extends Component {
  static propTypes = {
    ...CommonTypes,
    // required
    options: PropTypes.array.isRequired,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  static defaultProps = {
    ...CommonDefaults,
    // container
    id: "",
  };

  constructor(props) {
    super(...arguments);
    this._handleChange = this._handleChange.bind(this);
    this.state = {
      _value: isNone(props.value) ? [] : props.value,
    };
    sessionStorage.setItem("selectFilter", JSON.stringify(this.state._value));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state._value && props.type === "addresscommon") {
      return {
        _value: props.value,
      };
    }
    return null;
  }

  render() {
    const {
        error,
        disabled,
        required,
        name,
        className,
        options,
        dob,
        months,
        isNotEmptyOption,
        ariaLabel,
        data
      } = this.props,
      selectId = this.props.id,
      // use the passed in aria labels if present, otherwise, fall back on the select id so that
      // focusing on the option will read the label
      ariaLabels = validateAria(this.props["aria-labelledby"] || selectId);
    return (
      <select
        name={name}
        id={selectId}
        className={addStringIf(
          error,
          addStringIf(className, "dta-select"),
          
        )}
        value={this.state._value ? this.state._value : this.props.value}
        disabled={disabled}
        required={required}
        onChange={this._handleChange}
        aria-label={ariaLabel}
      >
        {isNotEmptyOption ? (
          ""
        ) : (
          <option value="" aria-labelledby={ariaLabels} />
        )}
        {(() => {
          if (dob === TextProps.VALUE_STR_TRUE) {
            return this._buildOptionsForMonth(options, months, ariaLabels);
          } else {
            return this._buildOptions(options, ariaLabels);
          }
        })()}
      </select>
    );
  }

  // Rendering
  _buildOptions(options, ariaLabels) {
    return options.map((option, index) => {
      return (
        <option key={index} value={option} aria-labelledby={ariaLabels}>
          {option}
        </option>
      );
    });
  }

 

  _buildOptionsForMonth(options, values, ariaLabels) {
    return options.map((option, index) => {
      return (
        <option key={index} value={values[index]} aria-labelledby={ariaLabels}>
          {option}
        </option>
      );
    });
  }

  // Events
  _handleChange = ({ target: { value } }) => {
    var theCode = "";
    if (this.props.name === "filter-document-type") {
      theCode = getDocumentTypeCode(value);
      var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      var consumerDocListObj = JSON.parse(
        sessionStorage.getItem("consumerAccountData")
      );
      var consumerDataUserObj = JSON.parse(
        sessionStorage.getItem("loadConsumerUser")
      );
      let providerUserdata = JSON.parse(sessionStorage.getItem("providerData"));
      let userAuthToken = "";
      if (this.props.consumer.mfaDetails.userAuth !== undefined) {
        userAuthToken = this.props.consumer.mfaDetails.userAuth;
      } else if (userInfo !== null) {
        userAuthToken = userInfo.userAuth;
      }
      let dateRangeFilter = JSON.parse(
        sessionStorage.getItem("dateRangeFilter")
      );
      let fromDate =
        dateRangeFilter && dateRangeFilter.from ? dateRangeFilter.from : null;
      let fromTo =
        dateRangeFilter && dateRangeFilter.to ? dateRangeFilter.to : null;
      if (
        this.props.consumer.consumerData.accountId !== undefined &&
        this.props.consumer.consumerData.accountId !== null &&
        this.props.consumer.consumerData.accountId
      ) {
        this.props.getConsumerDocumentList(
          this.props.consumer.consumerData.accountId,
          0,
          fromDate,
          fromTo,
          theCode,
          userAuthToken
        );
      } else if (
        this.props.consumer.consumerData.webAppId !== undefined &&
        this.props.consumer.consumerData.webAppId !== null
      ) {
        this.props.getConsumerDocumentList(
          0,
          this.props.consumer.consumerData.webAppId,
          fromDate,
          fromTo,
          theCode,
          userAuthToken
        );
      } else if (
        this.props.consumer.consumerData.user !== undefined &&
        this.props.consumer.consumerData.user !== null &&
        this.props.consumer.consumerData.user.accountId !== undefined &&
        this.props.consumer.consumerData.user.accountId !== null &&
        this.props.consumer.consumerData.user.accountId
      ) {
        this.props.getConsumerDocumentList(
          this.props.consumer.consumerData.user.accountId,
          0,
          fromDate,
          fromTo,
          theCode,
          userAuthToken
        );
      } else if (
        this.props.consumer.consumerData.user !== undefined &&
        this.props.consumer.consumerData.user !== null &&
        this.props.consumer.consumerData.user.webAppId !== undefined &&
        this.props.consumer.consumerData.user.webAppId !== null &&
        this.props.consumer.consumerData.user.webAppId
      ) {
        this.props.getConsumerDocumentList(
          0,
          this.props.consumer.consumerData.user.webAppId,
          fromDate,
          fromTo,
          theCode,
          userAuthToken
        );
      } else if (
        consumerDocListObj !== null &&
        consumerDocListObj !== undefined
      ) {
        if (providerUserdata !== undefined && providerUserdata !== null) {
          if (
            providerUserdata.accountId !== undefined &&
            providerUserdata.accountId
          ) {
            this.props.getConsumerDocumentList(
              providerUserdata.accountId,
              0,
              fromDate,
              fromTo,
              theCode,
              userAuthToken
            );
          } else if (
            providerUserdata.webAppId !== undefined &&
            providerUserdata.webAppId
          ) {
            this.props.getConsumerDocumentList(
              0,
              providerUserdata.webAppId,
              fromDate,
              fromTo,
              theCode,
              userAuthToken
            );
          }
        } else if (
          consumerDataUserObj !== undefined &&
          consumerDataUserObj != null
        ) {
          if (
            consumerDataUserObj.accountId !== undefined &&
            consumerDataUserObj.accountId
          ) {
            this.props.getConsumerDocumentList(
              consumerDataUserObj.accountId,
              0,
              fromDate,
              fromTo,
              theCode,
              userAuthToken
            );
          } else if (
            consumerDataUserObj.webAppId !== undefined &&
            consumerDataUserObj.webAppId
          ) {
            this.props.getConsumerDocumentList(
              0,
              consumerDataUserObj.webAppId,
              fromDate,
              fromTo,
              theCode,
              userAuthToken
            );
          }
        }
      }
    }
    this.setState({ _value: value });
    sessionStorage.setItem("selectFilter", value);
    tryCall(this.props.onChange, value);
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsumerDocumentList: getConsumerDocumentList,
      getConsumerSelectedDocumentList: getConsumerSelectedDocumentList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DTACustomizedSelect);