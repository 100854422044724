/*
  Question names
  --------------

  Names of questions requiring special attention in the rendering process, most often
  because they share a row with another question
 */

export const LAST_NAME = "lastName";
export const SUFFIX = "suffix";
export const STATE = "state";
export const COUNTY = "county";
export const COUNTY_ASSISTANCE = "county_Assistance";

