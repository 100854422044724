// Vendor / components
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

// Constants / Formatters
import * as SharedProps from "../../../constants/shared";
import { date, fullName, lastFourDigitSSN } from "../../utilities/formatters";
import { updateClientInfo, unlinkAccountStateClear } from "../../../../redux/consumer/consumerMyInfoActions";
import { push } from "react-router-redux";
import * as roleProps from "../../../constants/rolesConstants";
import * as TextProps from "../../../constants/text";
// Static data
import * as FiltersData from "../../../../pages/provider/providerSearchFiltersMapData";

import Icon from "../../dtaIcon";

/*
  SearchedClient ListItem contents
  --------------------------

  # Purpose:
  To display a client in Provider search client list

 */

class SearchedClientContents extends Component {
  static propTypes = {
    [SharedProps.ID]: PropTypes.string.isRequired,
    [SharedProps.USER]: PropTypes.shape({
      [SharedProps.FIRST_NAME]: PropTypes.string.isRequired,
      [SharedProps.LAST_NAME]: PropTypes.string.isRequired,
      [SharedProps.SUFFIX]: PropTypes.string,
      [SharedProps.DOB]: PropTypes.instanceOf(Date).isRequired,
      [SharedProps.PHONE]: PropTypes.string.isRequired,
      [SharedProps.ACCOUNT_ID]: PropTypes.string.isRequired,
    }).isRequired,
    onRequestAccess: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    enableUniqueIds(this);
  }

  redirectToConsumerClientInfo = (user) => {
    //e.preventDefault();
    //const user = this.props[SharedProps.USER];
    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    sessionStorage.setItem("selectedConsumer", user.clientId);
    sessionStorage.setItem("isConsumerInfoPage", true);
    if(!user.clientId){
      sessionStorage.setItem("selectedConsumerEmail", user.clientEmailAddr);
    }
    this.props.clearUnlinkState();
    let roleList;
    if (this.props.consumer.loginDetails.roleList != undefined) {
      roleList = this.props.consumer.loginDetails.roleList;
    } else {
      roleList = loggedInUser.roleList;
    }
    let superUser;
    if (roleList != undefined || roleList != null) {
      superUser = roleList.filter(
        (role) => role.roleName === roleProps.PROVIDER_NO_PSI
      );
      if (superUser.length == 0 && user[SharedProps.PSI_FLAG] === "NO") {
        return;
      } else {
          sessionStorage.setItem("isSuperAdmin", true);
      }
    }
    let newUserObj = null;
    if (user) {
      newUserObj = {
        accountID: user.accountId,
        clientID: user.clientId,
        webAppID: user.webAppId,
        personName: { first: user.firstName, last: user.lastName },
      };
    }
    sessionStorage.removeItem("loadConsumerUser");
    sessionStorage.removeItem("clientDetails");
    // updating user information in Redux store and send to consumer account.
    this.props.updateClientInfo(newUserObj);
    // redirect to consumer myinfo page
    this.props.navigateToConsumer();
  };

  onRequestAccess = () => {
    this.props.onRequestAccess(this.props[SharedProps.USER]);
  };

  renderUserInfo = (user) => {
    const type = this.props.type;
    switch (type) {
      case 'searchedClient': return (
        <span aria-labelledby={this.lastUniqueId()}>
          Case Number: {user[SharedProps.CASENUMBER]}
        </span>
      )
    }
  }

  renderUsersList = (roleList) => {
    const users = this.props.multiUserDetails;
    const usersList = users.map((user) => {
      return (
        <div className="pure-u-1 pure-u-lg-2-5">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <span aria-labelledby={this.lastUniqueId()}>
              Case Number: {user[SharedProps.CASENUMBER]}
            </span> */}
            <div style={{ flexDirection: 'column' }}>
              <span>
                {user[SharedProps.FIRST_NAME]} {user[SharedProps.LAST_NAME]}
              </span>
              <span>
                {user[SharedProps.DOB]}
              </span>
            </div>
          </div>
          {roleList.length > 0 &&
            roleList[0].roleName ===
            roleProps.PROVIDER_ROLE_SPVUSR ? null : (
            <div>
              {user[SharedProps.PSI_FLAG] === "YES"
                ? "PSI: Yes"
                : "PSI: No"}
            </div>
          )}
        </div>
      )
    })
    return usersList;
  }

  render() {
    const user = this.props.multiUserDetails;
    console.log('hey this.props', this.props)
    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    let roleList;
    if (this.props.consumer.loginDetails.roleList != undefined) {
      roleList = this.props.consumer.loginDetails.roleList;
    } else {
      roleList = loggedInUser.roleList;
    }
    let superUser;
    if (roleList != undefined || roleList != null) {
      superUser = roleList.filter(
        (role) => role.roleName === roleProps.PROVIDER_ROLE_SPVUSR
      );
    }

    const { search } = this.props.language.provider;
    return (
              <div class="table" id="results">
                <div class='theader'>
                  <div class='table_header' >
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_CLIENT_INFORMATION_DISPLAY}
                    </span>
                  </div>
                  <div class='table_header'  style={{maxWidth:'121px'}}>
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_SSN_DISPLAY}
                    </span>
                  </div>
                  <div class='table_header' style={{paddingLeft: '48px'}}>
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_EMAIL_DISPLAY}
                    </span>
                  </div>
                  <div class='table_header' style={{paddingLeft: '5px'}}>
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_SNAP_DISPLAY}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_TANF_DISPLAY}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                      {FiltersData.CLIENT_FILTER_CLIENTID_DISPLAY}
                    </span>
                  </div>
                </div>
                {user.map((userDetail) => {
                  return (
                    <div class='table_row' style={{cursor: "pointer"}} onClick={(event) => { this.redirectToConsumerClientInfo(userDetail) }}>
                    <div class='table_small'>
                      <div class='table_cell'>Header One</div>
                      <div class='table_cell'>
                      <div className="margin-bottom-quarter searched-client__name">
                        <span className="sr-only" id={this.nextUniqueId()} style={{fontWeight: "bold"}}>
                          Client name:
                        </span>
                        <span aria-labelledby={this.lastUniqueId()}  style={{fontWeight: "bolder", color: "black"}} >
                          <b>{fullName(userDetail, TextProps.VALUE_TRUE)}</b>
                       </span>
                       </div>
                         <div className="margin-bottom-quarter">
                           <span className="sr-only" id={this.nextUniqueId()}>
                             Client date of birth:
                           </span>
                           <span aria-labelledby={this.lastUniqueId()}>
                              {userDetail[SharedProps.DOB] && userDetail[SharedProps.DOB].length > 0 ? `(${date(new Date(userDetail[SharedProps.DOB]))})` : ''}
                          </span>
                         </div>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Two</div>
                      <div class='table_cell'>
                        <span className=" ">
                           {userDetail.ssnLastFour > 0 ? `xxx-xx-${userDetail.ssnLastFour}`: <div>&nbsp;</div>}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Three</div>
                      <div class='table_cell'>
                        <span className=" ">
                           {userDetail.clientEmailAddr && userDetail.clientEmailAddr.length > 0 ? userDetail.clientEmailAddr : <div>&nbsp;</div>}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Four</div>
                      <div class='table_cell' style={{paddingLeft: '5px'}}>
                        <span className=" ">
                           {userDetail.snapCaseNumber > 0 ? userDetail.snapCaseNumber: <div>&nbsp;</div>}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Four</div>
                      <div class='table_cell'>
                        <span className=" ">
                           {userDetail.tanfCaseNumber > 0 ? userDetail.tanfCaseNumber:  <div>&nbsp;</div>}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Four</div>
                      <div class='table_cell'>
                        <span className=" ">
                           {userDetail.clientId > 0 ? userDetail.clientId:  <div>&nbsp;</div>}
                         </span>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </div>  
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateClientInfo: updateClientInfo,
      navigateToConsumer: () => push("/consumer?provider=TextProps.VALUE_TRUE"),
      clearUnlinkState: unlinkAccountStateClear,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedClientContents);
