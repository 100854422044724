import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import TermDSnap from "../../../utils/components/termDsnap";
import Checkbox from "../../../utils/components/checkbox";
import DTAInputSubmit from "../../../utils/components/dtaInputSubmit";
import {signAndSubmitDsnapMultilanguageLabels} from "../applyDsnapText";
import * as TextProps from "../../../utils/constants/text";
import { langOptSel } from "../../../utils/constants/constants";
//end code for google analytics
let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

class SignAndSubmit extends Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div>
        <div key={this.nextUniqueId()} className="pad-all--double">
       

          <form id={this.props.submitFormId}>
            <TermDSnap id="submit-1" className="dta-form__label" />
           

            {/* <hr className="divider" />  */}
            <label className="dta-form__label" htmlFor="sign-app">
              <p>
                {signAndSubmitDsnapMultilanguageLabels(language).signYourAppllication}
              </p>
            </label>
            {/* Clicking will submit the application and route user to follow up page */}
            <div>
              {this.props.signatureError ? (
                  <span className="dta-form__error-message">
                    {this.props.signatureErrorMsg}
                  </span>
              ) : (
                <p></p>
              )}
            </div>
            <DTAInputSubmit
              className="dta-form__control"
              id="sign-app"
              type="specialTextType"
              maxLength={30}
              value={this.props.signature}
              ref="applicanSign"
              onChange={this.props.updateSignature}
              required={TextProps.VALUE_TRUE}
              error={this.props.signatureError}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default SignAndSubmit;
