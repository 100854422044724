import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import Icon from "../../../utils/components/dtaIcon";
import * as Props from "../../../utils/components/shared";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import HelpTip from "../../../utils/components/helpTip";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import * as formatters from "../../../utils/components/utilities/formatters";
import {
  setDataChangeStatusForExpense,
  // setUnearnedIncomeChangeStatus,
  setShelterUtilityChangeStatus
} from "../../../redux/recertification/recertificationActions";

import { recertificationPageLabels } from "../recertificationText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ShelterExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelterUtilitySectionTemplate: null,
      unearnedIncomeSummaryTemplate: null,
      respData: null,
      shelterUIValuesPopulated: TextProps.VALUE_FALSE,
      shelterUtilitySubSectionCleared: TextProps.VALUE_FALSE,
      shelterUtilityConfirmationChanged: TextProps.VALUE_FALSE,
      shelterSummaryEdit: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      sections: [],
      renderedSections: [],
      houseMemberLabels: [],
    };
    this.houseHoldHead = "";
    enableUniqueIds(this);
  }

  //Life cycle methods
  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.recert.sections,
        respData: this.props.recert.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.recert.setOldUIncomeData === TextProps.VALUE_TRUE) {
      this.onUnearnedEarnedIncomeChanged(
        recertificationPageLabels(language, null, languageConstants.aboutMe)
          .optionYesNo[1]
      );
    }
  }

  // Toggle whether adding new income members is open on state
  toggleAddUnearnedIncomeMembers = () => {
    this.setState({
      addUnearnedMembersOpen: !this.state.addUnearnedMembersOpen,
    });
  };

  componentDidMount() {
    this.setInitialData();
    if (
      this.props.recert.hasOwnProperty(
        "shelterUtilityConfirmationChanged"
      ) &&
      this.props.recert.shelterUtilityConfirmationChanged !== null
    ) {
      const shelterUtilSectionIndex = this.props.recertType === "IR" ?
      this.props.recert.sections.indexOf(
        this.filterData(this.props.recert.sections, "shelter-utility-summary")
      )
      :  this.filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        this.filterData(
          this.filterData(this.props.recert.sections, "expenses").subsections,
         "shelter-utility-summary"
        )
      );
      this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, shelterUtilSectionIndex);
    }
  }


  setInitialData = () => {
    let utilityAnswers = [];

    if (this.props.shelterResponseData && this.props.shelterResponseData.utilityExpenses) {
      let utilityOldAnswers = this.props.shelterResponseData.utilityExpenses;
      for (let key in utilityOldAnswers) {
        if (utilityOldAnswers.hasOwnProperty(key)) {
          let val = utilityOldAnswers[key];
          if (val) {
            utilityAnswers.push(key);
          }
        }
      }
    }

    this.props.setShelterData({
      householdMembers: _.cloneDeep(
        this.props.shelterResponseData.householdMembers
      ),
      shelterData: _.cloneDeep(this.props.shelterResponseData.shelterExpenses),
      utilityData: _.cloneDeep(utilityAnswers),
    });

    //Retain all the removed sections
    // if (
    //   this.props.householdData.sections.length < 5 &&
    //   sessionStorage.getItem("fullSectionsIR")
    // ) {
    //   let sections = JSON.parse(sessionStorage.getItem("fullSectionsIR"));
    //   let removedSections = sections.slice(3, sections.length);
    //   let existingSections = this.props.householdData.sections;
    //   existingSections = existingSections.slice(0, 3);
    //   let sectionsRefreshed = existingSections.concat(removedSections);
    //   this.props.snapRefreshQuestionsSections(_.cloneDeep(sectionsRefreshed));
    //   this.props.householdData.sections = sectionsRefreshed;
    //   sessionStorage.removeItem("fullSectionsIR");
    // }

    // this.setState({ removeHeadModalOpen: TextProps.VALUE_FALSE });
  };

  onShelterConfirmationChanged = (value) => {
    const shelterUtilSectionIndex = this.props.recertType === "IR" ?
              this.props.recert.sections.indexOf(
                this.filterData(this.props.recert.sections, "shelter-utility-summary")
              )
              :  this.filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.indexOf(
                this.filterData(
                  this.filterData(this.props.recert.sections, "expenses").subsections,
                 "shelter-utility-summary"
                )
              );
    if (
      value ===
      recertificationPageLabels(language, null, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState(
        {
          shelterUIValuesPopulated: TextProps.VALUE_FALSE,
          shelterUtilitySubSectionCleared: TextProps.VALUE_FALSE,
          shelterUtilityConfirmationChanged: !this.state.shelterUtilityConfirmationChanged,
        },
        () => {
          // this.onUnearnedIncomeSubSectionRetain();
          this.props.shleterUtilityNochange();
          this.props.setShelterUtilityChangeStatus(TextProps.VALUE_FALSE);
          this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, shelterUtilSectionIndex);
          this.props.setShelterUtilityInfo(TextProps.VALUE_FALSE);
        }
      );
    } else {
      this.setState(
        {
          shelterUtilityConfirmationChanged: !this.state.shelterUtilityConfirmationChanged,
        },
        () => {
          this.props.setShelterUtilityChangeStatus(TextProps.VALUE_TRUE);
          this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, shelterUtilSectionIndex);
          this.props.setShelterUtilityInfo(TextProps.VALUE_TRUE);
        }
      );
    }
  };

  // onUnearnedIncomeSubSectionClean = () => {
  //   if (this.state.shelterUtilitySubSectionCleared === TextProps.VALUE_FALSE) {
  //     this.setState(
  //       {
  //         shelterUtilitySubSectionCleared: TextProps.VALUE_TRUE,
  //         shelterUtilitySectionTemplate: _.cloneDeep(
  //           shelterUtilitySectionTemplate
  //         ),
  //         unearnedIncomeSummaryTemplate:
  //           this.props.interimReport.sections[4].subsections[
  //           this.props.interimReport.sections[4].subsections.length - 1
  //           ],
  //       },
  //       () => {
  //         this.props.clearMemberUIIncome(
  //           this.state.shelterUtilitySectionTemplate,
  //           this.state.unearnedIncomeSummaryTemplate,
  //           this.props.interimReport.sections[4]
  //         );
  //         this.props.shelterSubsectionRender();
  //       }
  //     );
  //   }
  // };

  // onUnearnedIncomeSubSectionRetain = () => {
  //   if (this.state.shelterUtilitySubSectionCleared === TextProps.VALUE_FALSE) {
  //     this.setState(
  //       {
  //         shelterUtilitySubSectionCleared: TextProps.VALUE_TRUE,
  //         shelterUtilitySectionTemplate: _.cloneDeep(
  //           shelterUtilitySectionTemplate
  //         ),
  //         unearnedIncomeSummaryTemplate:
  //           this.props.interimReport.sections[4].subsections[
  //           this.props.interimReport.sections[4].subsections.length - 1
  //           ],
  //       },
  //       () => {
  //         this.props.clearMemberUIIncome(
  //           this.state.shelterUtilitySectionTemplate,
  //           this.state.unearnedIncomeSummaryTemplate,
  //           this.props.interimReport.sections[4],
  //           TextProps.VALUE_FALSE
  //         );
  //         this.props.shelterSubsectionRender(
  //           this.state.shelterUtilitySectionTemplate
  //         );
  //       }
  //     );
  //   }
  // };

  getShelterLabel = (type) => {
    let shelterExpenseLabel;
    switch (type) {
      case 'RENT':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).rent;
        return shelterExpenseLabel;
      case 'MTGPRN':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).mortgage;
        return shelterExpenseLabel;
      case 'PRPTAX':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).propertyTaxes;
        return shelterExpenseLabel;
      case 'HOMEIN':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).homeInsurance;
        return shelterExpenseLabel;
      case 'CONFEE':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).condoFee;
        return shelterExpenseLabel;
      case 'PRPINS':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).propertyInsurance;
        return shelterExpenseLabel;
      case 'SECMTG':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).secondMortage;
        return shelterExpenseLabel;
      case 'HOMEXP':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).homelessExpenses;
        return shelterExpenseLabel;
      case 'HOMEEQ':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).homeEquityLoan;
        return shelterExpenseLabel;
      case 'HOASFE':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).homeOwnerAssociationFees;
        return shelterExpenseLabel;
      case 'OTHER':
        shelterExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).other;
        return shelterExpenseLabel;
      default:
        shelterExpenseLabel = 'None';
        return shelterExpenseLabel;
    }
  }
  getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case 'Week':
      case 'WEEK':
      case 'Semana':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).week;
        return shelterExpenseFrequency;
      case 'Month':
      case 'MONTH':
      case 'Mes':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'Year':
      case 'ANNUAL':
      case 'Año':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;
      case 'BIWEEK':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).biweek;
        return shelterExpenseFrequency;
      case 'SEMANL':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).semanl;
        return shelterExpenseFrequency;
      case 'ONETME':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).onetime;
        return shelterExpenseFrequency;
      case 'BIMON':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).bimonthly;
        return shelterExpenseFrequency;
      case 'SEMMON':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).semimonthly;
        return shelterExpenseFrequency;
      case 'Monthly':
        shelterExpenseFrequency = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).monthly;
        return shelterExpenseFrequency;
      default: break;
    }
  }
  getutilityLabel = (type) => {
    let utilityExpenseLabel;
    switch (type) {
      case 'heatFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).heatOil;
        return utilityExpenseLabel;
      case 'acFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).electricityairConditioner;
        return utilityExpenseLabel;
      case 'electricOrGasFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).electricityGas;
        return utilityExpenseLabel;
      case 'phoneFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).cellPhoneService;
        return utilityExpenseLabel;
      case 'noUtilityFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).noneLable;
        return utilityExpenseLabel;
      case 'payWaterSewage':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).waterAndSewage;
        return utilityExpenseLabel;
      case 'payGarbage':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).garbage;
        return utilityExpenseLabel;
      case 'liheapFlag':
        utilityExpenseLabel = recertificationPageLabels(
          language,
          null,
          languageConstants.shelterAndutility
        ).energyAssistance;
        return utilityExpenseLabel;
      default:
        utilityExpenseLabel = '';
        return utilityExpenseLabel;
    }
  }
  // this.getUtilityExpense(utilityItem)
  renderShelterUtilitySummary = (shelterSummary) => {
    let summary = shelterSummary.shelterExpense && shelterSummary.shelterExpense[0].section;
    let utilitySummary = shelterSummary.utilityExpenses;
    
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}>{this.houseHoldHead + "'s"}</p>

        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >

        </p>
        {this.props.recert.shelterUtilityConfirmationChanged &&
          this.props.recert.shelterUtilityConfirmationChanged ===
          TextProps.VALUE_TRUE ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.shelterSummaryEditClick(summary)}
          >
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}

        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility
              ).shelterExpenseLabel
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                "",
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).after
            }
            helpText={""}
            // helpText={
            //   recertificationPageLabels(
            //     language,
        //   null,
            //     languageConstants.beforeYouStart
            //   ).sUIncomeHelp
            // }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <ul>
          {this.props.shelterSummary && this.props.shelterSummary.shelterExpense && this.props.shelterSummary.shelterExpense[0].incomes.map((shelterSumm)=>{
              if(shelterSumm.Answer)
              return shelterSumm.Answer.map((oneAmount)=>{
                let label = "";
                if (oneAmount.code !== undefined) {
                  if (oneAmount.code === "new" || oneAmount.code === "NEW") {
                    label = "newLabel";
                  } else if (oneAmount.code === "changed" || oneAmount.code === "CHANGE") {
                    label = "changedLabel";
                  } else if (oneAmount.code === "removed" || oneAmount.code === "REMOVE") {
                    label = "removedLabel";
                  }
                }
                if(oneAmount.amount && oneAmount.unit){
                  return <li key={Math.random()}>
                <span className="pure-u-12-24">
                  {this.getShelterLabel(shelterSumm.typeCode)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
                <div  style={{ paddingTop: "10px" }}>
                  <span className="pure-u-12-24">
                    { this.getShelterExpenseFrequency(oneAmount.unit) + ' ' +'Amount'}
                  </span>
                  <span className="pure-u-12-24">
                    {"$" + oneAmount.amount}
                  </span>
                </div>
              </li>
                }
              })
          })}
        </ul>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              recertificationPageLabels(
                language,
                null,
                languageConstants.shelterAndutility
              ).utilityExpensesLabel
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                "",
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).after
            }
            helpText={""}
            // helpText={
            //   recertificationPageLabels(
            //     language,
            //     languageConstants.beforeYouStart
            //   ).sUIncomeHelp
            // }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <ul>
          {utilitySummary && utilitySummary.map((utilityItem) => {
            // let removedUtil = this.props.interimReport.responseObject.additionalProperties.removedUtility ? this.props.interimReport.responseObject.additionalProperties.removedUtility : [];
            // let newUtility = this.props.interimReport.responseObject.additionalProperties.newUtility ? this.props.interimReport.responseObject.additionalProperties.newUtility : [];
             let label = "";
             if (utilityItem.code !== undefined) {
               if (utilityItem.code === "new" || utilityItem.code === "NEW") {
                 label = "newLabel";
               } else if (utilityItem.code === "removed" || utilityItem.code === "REMOVE") {
                 label = "removedLabel";
               }
             }
            return (
              <li key={Math.random()}>
                <span className="pure-u-12-24">                 
                  {this.getutilityLabel(utilityItem.type)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  //Edit Button in Income Summary
  shelterSummaryEditClick = (section) => {
    this.setState({ shelterSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };
  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };
  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  returnShelterExpenseConfirmationLabel() {
    if (
      this.props.recert.shelterUtilityConfirmationChanged === TextProps.VALUE_TRUE
    ) {
      return recertificationPageLabels(language,null, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
      if (
        this.props.recert.shelterUtilityConfirmationChanged === TextProps.VALUE_FALSE
      ) {
        return recertificationPageLabels(language,null, languageConstants.aboutMe)
          .optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  render() {
    let head = [];
    if (this.props.recert.shelterUtilityData) {
      this.props.recert.shelterUtilityData.householdMembers.forEach((member) => {
        if (member.granteeFlag === TextProps.VALUE_TRUE) {
          head.push(member);
        }
      });
    }
    if (head && head.length > 0) {
      this.houseHoldHead = head[0].firstName + " " + head[0].lastName + " " + formatters.formatDate(head[0].dateOfBirth)
    }
    let shelterUtilSection = this.props.recertType === "IR" ? 
                           this.filterData(this.props.recert.sections, "shelter-utility-summary")
                         : this.filterData(this.filterData(this.props.recert.sections,"expenses").subsections,"shelter-utility-summary");
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {this.renderShelterUtilitySummary(this.props.shelterSummary)}
        <span>
          {
            recertificationPageLabels(language,null, languageConstants.beforeYouStart)
              .shelterExpense_ChangeQuestion
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {shelterUtilSection.selectionMade ===
                TextProps.VALUE_FALSE && (
                  <span className="dta-form__error-message">
                    {
                      recertificationPageLabels(
                        language,
                        null,
                        languageConstants.contactInfo
                      ).requiredErrorMessage
                    }
                  </span>
                )}
              <Radio
                name="shelter-expense-changed"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  recertificationPageLabels(language,null, languageConstants.aboutMe)
                    .optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnShelterExpenseConfirmationLabel()}
                onChange={(value) => this.onShelterConfirmationChanged(value)}
              />
            </fieldset>
          </div>
          <div>
            <p>
              { this.props.recertType === "IR" &&
                recertificationPageLabels(language,null, languageConstants.beforeYouStart)
                  .shelterExpenseSubText
              }
            </p>

          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDataChangeStatusForExpense: setDataChangeStatusForExpense,
      setShelterUtilityChangeStatus: setShelterUtilityChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShelterExpenses);
