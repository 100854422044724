import React, { Component } from "react";

import { interimReportPageLabels } from "../recertInterimReportText";
import { displayPDFData } from "../../../redux/recertInterimReport/recertInterimReportActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { getAudit } from "../../../utils/constants/audit";
import { push } from "react-router-redux";
import * as languageConstants from "../../../utils/constants/constants";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class SubmitSummary extends Component {
  constructor(props) {
    super(props);
    this.displayPDFData = this.displayPDFData.bind(this);
  }

  redirectToSelfDeclare = () => {
    window.location.assign(routeProps.DECLARE_EXPENSES_PATH);
  };
  redirectToHomePage = () => {
    window.location.assign("/consumer/myInfo");
  };
  render() {
    return (
      <div className="pad-all--double">
        <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).referenceNumberText
          }
          {this.props.referenceNumber}
        </p>
        <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).downloadBeforeText
          }{" "}
          <a onClick={this.displayPDFData}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).downloadLinkText
            }
          </a>{" "}
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).downloadAfterText
          }
        </p>
        <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).householdBoldMovedText
          }
        </b>
        <ul>
          <li>
          <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryList1
          }{" "}
          <a onClick={() => window.location.href = "/consumer/documents"}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).textHere
            }
          </a>{" "}
          </p>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
              ).summaryListExample
            }
          </p>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign: "center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample1
          }
          </p>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign: "center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample2
          }
          </p>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign:"center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample3
          }
          </p>
          </div>
          </li>
          <li>
          <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryList2
          }{" "}
          <a onClick={() => window.open("https://dhr.alabama.gov/county-office-contact/", '_blank')}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).summaryCountryLive
            }
          </a>{" "}
          </p>
          </li>
        </ul>
        <br />
        <br />
        <button
              type="button"
              className="dta-button dta-button--primary dta-button--small"
              onClick={this.redirectToHomePage}
            >
             {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).summaryExit
            }
            </button>
        {/* {this.props.interimReport.contactInfoScreen.contactInfoChanged ||
        this.props.interimReport.householdMembers.membersListUpdated ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).householdMovedText
            }
            <a href={routeProps.DECLARE_EXPENSES_PATH}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).houseHoldMoveLinkText
              }
            </a>
          </p>
        ) : null} */}

        {/* {this.props.interimReport.sections.length > 4 &&
        this.props.interimReport.earnedIncomeChanged &&
        this.props.interimReport.earnedIncomeChanged ===
          TextProps.VALUE_TRUE ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).earnedIncomeChangedText
            }
            <ul>
              {interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).earnedIncomeSteps.map((text) => {
                return <li>{text}</li>;
              })}
            </ul>
          </p>
        ) : null}

        {this.props.interimReport.sections.length > 4 &&
        this.props.interimReport.unearnedIncomeChanged !== undefined &&
        this.props.interimReport.unearnedIncomeChanged ===
          TextProps.VALUE_TRUE ? (
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).unearnedIncomeChangedText
            }
            <ul>
              {interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).unearnedIncomeSteps.map((text) => {
                return <li>{text}</li>;
              })}
            </ul>
          </p>
        ) : null}
        {this.props.interimReport.sections.length > 5 &&
        this.props.interimReport.childSupportChanged !== undefined &&
        this.props.interimReport.childSupportChanged ===
          TextProps.VALUE_TRUE ? (
          <p>
            <p>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).uneanrednoteBulletDetailText
              }
            </p>
            <b>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).uneanrednoteText
              }
              :
            </b>{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).uneanrednoteDetailText
            }
          </p>
        ) : null} */}
        {/* {this.props.interimReport.sections.length > 4 ? (
          <div>
            <hr className="divider" />
            <p className="interim-recert-heading">
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesTitle
              }
            </p>
            <p>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesQuestion
              }
            </p>
            <button
              type="submit"
              className="dta-button dta-button--outline-secondary no-underline-link"
              onClick={this.redirectToSelfDeclare}
            >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).expensesBtnLabel
              }
            </button>
            <br />
            <br />
          </div>
        ) : null}
        <hr className="divider" />
        <button
          className="dta-button dta-button--primary dta-button--large dta-button--block no-underline-link"
          onClick={() => {
            window.location.href = routeProps.CONSUMER_PATH;
          }}
        >
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).sendDocumentBtnLabel
          }
        </button>
        <p>
          <center>
            <a onClick={() => window.location.assign(routeProps.CONSUMER_PATH)}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).dontSendDocumentBtnLabel
              }
            </a>
          </center>
          <p>
            <span>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).otherDocWays
              }{" "}
            </span>
            <a href={languageConstants.SUBMIT_DOCS_DEPT_TRANS_ASS}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.signAndSubmitSummary
                ).findMoreText
              }
            </a>
          </p>
        </p> */}
      </div>
    );
  }

  displayPDFData() {
    var agencyId = this.props.agencyID;
    var auditData = getAudit();
    this.props.displayPDFData(
      this.props.docReferenceNumber,
      auditData,
      agencyId
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayPDFData: displayPDFData,
      goToDeclare: () => push(routeProps.DECLARE_EXPENSES_PATH),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSummary);
