import * as languageConsts from "../../utils/constants/types";
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function unearnedIncomeCodeMapper(language) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        AMRCRP: "Americorps OJT",
        ANNUIT: "Annuities",
        CAPGN: "Capital Gains",
        CHSDOR: "Child Support/DOR",
        DEFRA: "DEFRA Child Support Payment",
        DONAT: "Donation",
        EDUCAT: "Education Assistance",
        ELDNUT: "Elderly Nutrition Program",
        EMPPEN: "Employment Related Pension",
        ENGAST: "Energy Assistance",
        EXPHOU: "Experimental Housing Allowance",
        FEDPEN: "Federal Civil Service Pension",
        FEDWRK: "Federal Work Study",
        FEP: "Full Employment Income",
        FOSCAR: "Foster Care",
        FOSPRT: "Foster Parent",
        FREEHH: "Free Household Expenses",
        GAMBL: "Gambling Winnings",
        GOVGRT: "Government Grant Program",
        HSGSUB: "Housing Subsidy",
        INDCWA: "Indian Child Welfare Assist.",
        INDLTR: "Indian Land Trust",
        INDOLD: "Indian Old Age Assistance",
        INDRLD: "Indian Restricted Land",
        INTINC: "Interest Income",
        INTXRF: "Income Tax Refund",
        JTPA: "JTPA",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "Workman's Compensation",
        RENTAL:"Income from Land Rental or Rental Property",
        OTHER: "Other",
        LGPEN: "Web Local Government Pension",
        LOAN: "Loan (Non Educational)",
        LOANL: "Loan (Non Ed Lump Sum)",
        LTDINS: "Long Term Disability Insurance",
        MILPE: "Military Pension",
        NGINC: "National Guard Income",
        NONFED: "Non-Federal Work Study",
        OLDAMR: "Older-American Act Funds",
        OTHINC: "Other Income",
        OTHPEN: "Web Other Pensions",
        PASS: "PASS",
        PMTSEN: "Payments to Seneca Nation",
        PRPEN: "Web Private Pension",
        RECPCS: "Recouped Child Support",
        RECPMO: "Recouped Monies",
        REFUND: "Refund",
        RETFND: "Retirement Funds",
        REVMTG: "Reverse Mortgage",
        ROOMER: "Roomer Income",
        ROYLTY: "Royalties",
        RRRET: "Railroad Retirement",
        SHELWS: "Sheltered Workshop",
        SOCSVC: "Social Service Agency Payment",
        SPMNAL: "Spousal Maint Needs Allowance",
        SSIEMG: "SSI Emergency Advance",
        STPEN: "Web State Pension",
        STRIKE: "Strike Benefits",
        STUDNT: "Student Earnings",
        SUMYTH: "Summer Youth E & T",
        SUPPRT: "Support",
        SVCPUB: "Service to Public Housing",
        TAFDCS: "TAFDC Supplemental",
        TRNALL: "Training Allowance",
        TRNSTI: "Training Stipend",
        TRUST: "Trust Funds",
        UNEMPL: "Unemployment Compensation",
        VETBEN: "Veterans Benefits",
        VANSCM: "VA Federal Non Service Related",
        VETSVC: "VA State",
        VOLPMT: "Volunteer Payment",
        WEAEDC: "Web EAEDC",
        WFMNA: "Web Fam Maint Needs Allowance",
        WTAFDC: "Web TAFDC",
        WVACOM: "Web VA Compensation",
        WVAPEN: "Web VA Pension",
        WVASCT: "Web VA State/City Needs Based",
        WVETAA: "Web VA Aid and Attendance",
        WVETEB: "Web VA Enhanced Benefits",
        WVETHB: "Web VA Household Benefits",
        WVETME: "Web VA Unreimbursed Medical Ex",
        WVETPR: "Web VA Benefits Pay Reduction",
        YTHBLD: "Youthbuild",
        STLWRD: "Settlements and Awards",

        ALIMNY: "Alimony",
        ADOPTN: "Adoption Assistance",
        ASTPAY: "Assistance Payments",
        BLCKLG: "Black Lung",
        BOARD: "Boarder Income",
        CHCARE: "Child Care Value",
        CONTR: "Contribution",
        DISAB: "Disability Benefits",
        DIVSEC: "Dividends from Securities",
        PFML: "Paid Family Medical Leave",

        // New Labels
        TANF:"TANF",
        UNEMPL:"Unemployment Compensation",
        WRKCMP:"Workman's Compensation",
        INTDIV:"Interest Dividends from Stock and Bonds",
        MILALL:"Military Allotment",
        PENS:"Pension or Retirement Income",
        RRRET:"Railroad Retirement",
        LIHEAP:"HUD Utility Assistance",
        LANDREN:"Income from Land Rental or Rental Property",
        CHILD:"Child Support",
        ALIMON:"Alimony",
        CONFRI:"Contributions from Friends and Relatives",
      };
    case languageConsts.SPANISH:
      return {
        ALIMNY: "Cuota alimentaria",
        ADOPTN: "Asistencia para la adopción",
        ASTPAY: "Pagos de asistencia",
        BLCKLG: "Neumoconiosis",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "Compensación del trabajador",
        RENTAL: "Ingreso por alquiler",
        OTHER: "Otro",
        BOARD: "Ingresos del pensionista",
        CHCARE: "Costo de guardería",
        CHSDOR: "Manutención infantil/Departamento de Hacienda",
        CONTR: "Contribución",
        DEFRA: "Pago de asistencia infantil DEFRA",
        DISAB: "Beneficios por discapacidad",
        DIVSEC: "Dividendos de acciones",
        AMRCRP: "Capacitación en el trabajo de Americorps",
        ANNUIT: "Rentas",
        CAPGN: "Ganancias de capital",
        DONAT: "Donación",
        EDUCAT: "Asistencia educativa",
        ELDNUT: "Programa nutricional para adultos mayores",
        EMPPEN: "Pensión laboral",
        ENGAST: "Asistencia energética",
        EXPHOU: "Subsidio para vivienda experimental",
        FEDPEN: "Pensión federal por servicio civil",
        FEDWRK: "Programa federal de estudio-trabajo",
        FEP: "Ingresos por empleo de tiempo completo",
        FOSCAR: "Hogares de acogida",
        FOSPRT: "Adopción temporal",
        FREEHH: "Gastos de vivienda gratis",
        GAMBL: "Ingresos del juego",
        GOVGRT: "Programa de becas del gobierno",
        HSGSUB: "Subsidio para vivienda",
        INDCWA: "Asistencia para bienestar de niños indígenas",
        INDLTR: "Fideicomiso de tierras indígenas",
        INDOLD: "Asistencia para adultos mayores indígenas",
        INDRLD: "Tierras indígenas restringidas",
        INTINC: "Ingresos por intereses",
        INTXRF: "Reembolso de impuestos a las ganancias",
        JTPA: "JTPA",
        LGPEN: "Web, Pensión gubernamental local",
        LOAN: "Préstamo (no educativo)",
        LOANL: "Préstamo (monto global no ed.)",
        LTDINS: "Seguro por discapacidad a largo plazo",
        MILPE: "Pensión militar",
        NGINC: "Ingresos de la Guardia Nacional",
        NONFED: "Programa de estudio-trabajo no federal",
        OLDAMR: "Fondos de la Ley de Adultos Mayores Estadounidenses",
        OTHINC: "Otros ingresos",
        OTHPEN: "Web, otras pensiones",
        PASS: "PASS",
        PMTSEN: "Pagos a la Nación Séneca",
        PRPEN: "Web, pensión privada",
        RECPCS: "Manutención infantil recuperada",
        RECPMO: "Dinero recuperado",
        REFUND: "Reembolso",
        RETFND: "Fondos de jubilación",
        REVMTG: "Hipoteca inversa",
        ROOMER: "Ingresos del inquilino",
        ROYLTY: "Regalías",
        RRRET: "Jubilación ferroviaria",
        SHELWS: "Taller de inmersión",
        SOCSVC: "Pago de agencia de servicio social",
        SPMNAL: "Asignación para cubrir necesidades de manutención del cónyuge",
        SSIEMG: "Adelanto de emergencia de los SSI",
        STPEN: "Web, Pensión estatal",
        STRIKE: "Indemnización por huelga",
        STUDNT: "Ingresos de estudiante",
        SUMYTH: "Educación y capacitación para jóvenes en verano",
        SUPPRT: "Asistencia",
        SVCPUB: "Servicio para vivienda pública",
        TAFDCS: "TANF",
        TRNALL: "Asignación para capacitación",
        TRNSTI: "Estipendio para capacitación",
        TRUST: "Fondos fiduciarios",
        UNEMPL: "Seguro de desempleo",
        VETBEN: "Beneficios de veteranos",
        VANSCM:
          "Indemnización federal para veteranos por discapacidad no relacionada con el servicio",
        VETSVC: "Indemnización estatal para veteranos",
        VOLPMT: "Pago voluntario",
        WEAEDC: "Web, EAEDC",
        WFMNA:
          "Web, Asignación para cubrir necesidades de manutención familiar",
        WTAFDC: "Web, TAFDC",
        WVACOM: "Web, Indemnización de veterano",
        WVAPEN: "Web, Pensión de veterano",
        WVASCT:
          "Web, Asistencia estatal/local para veteranos según las necesidades",
        WVETAA: "Web, Ayuda y asistencia para veteranos",
        WVETEB: "Web, Beneficios mejorados para veteranos",
        WVETHB: "Web, Beneficios familiares para veteranos",
        WVETME: "Web, Gastos médicos no reembolsados para veteranos",
        WVETPR: "Web, Reducción del pago a veteranos",
        YTHBLD: "Youthbuild",
        PFML: "Licencia paga por motivos familiares/médicos",
      };
    case languageConsts.PORTUGUESE:
      return {
        STLWRD: "Assentamentos e prêmios",
        ALIMNY: "Pensão alimentícia",
        ADOPTN: "Assistência de adoção",
        ASTPAY: "Pagamentos de assistência",
        BLCKLG: "Black lung (doenças pulmonares)",
        BOARD: "Renda de pensionista",
        CHCARE: "Valor com cuidados infantis",
        CONTR: "Contribuição",
        DISAB: "Beneficios por invalidez",
        DIVSEC: "Dividendos de valores mobiliários",
        AMRCRP: "Americorps OJT",
        ANNUIT: "Anuidades",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "Compensação do trabalhador",
        RENTAL: "Renda de aluguel",
        OTHER: "Outros",
        CAPGN: "Ganhos de capital",
        CHSDOR: "Apoio à criança/DOR",
        DEFRA: "Pagamento de pensão alimentícia DEFRA",
        DONAT: "Doação",
        EDUCAT: "Assistência educacional",
        ELDNUT: "Programa de nutrição do idoso",
        EMPPEN: "Pensão relacionada ao emprego",
        ENGAST: "Assistência energética",
        EXPHOU: "Provisão experimental de habitação",
        FEDPEN: "Pensão do servidor público federal",
        FEDWRK: "Estudo de trabalho federal",
        FEP: "Renda pleno emprego",
        FOSCAR: "Cuidados relacionados a adoção",
        FOSPRT: "Pais adotivos",
        FREEHH: "Despesas domésticas gratuitas",
        GAMBL: "Ganhos de jogo",
        GOVGRT: "Programa de subsídios do governo",
        HSGSUB: "Subsídio de habitação",
        INDCWA: "Assistência ao bem-estar da criança indígena.",
        INDLTR: "Fundo para aquisição de terras indígenas",
        INDOLD: "Assistência aos idosos indígenas",
        INDRLD: "Terras indígenas restritas",
        INTINC: "Rendimentos de juros",
        INTXRF: "Restituição do imposto de renda",
        JTPA: "JTPA",
        LGPEN: "Pensão do governo local Web",
        LOAN: "Empréstimo (não educacional)",
        LOANL: "Empréstimo (valor total não ed)",
        LTDINS: "Seguro de invalidez a longo prazo",
        MILPE: "Pensão militar",
        NGINC: "Renda da guarda nacional",
        NONFED: "Estudo de trabalho não federal",
        OLDAMR: "Fundos de lei para americanos idosos",
        OTHINC: "Outros rendimentos",
        OTHPEN: "Outras pensões Web",
        PASS: "PASS",
        PMTSEN: "Pagamentos ao Seneca Nation",
        PRPEN: "Pensão privada Web",
        RECPCS: "Suporte para crianças recuperadas",
        RECPMO: "Verbas recuperadas",
        REFUND: "Reembolso",
        RETFND: "Fundos de aposentadoria",
        REVMTG: "Hipoteca reversa",
        ROOMER: "Renda de pensionista",
        ROYLTY: "Royalties",
        RRRET: "Aposentadoria ferroviária",
        SHELWS: "Workshop protegido",
        SOCSVC: "Pagamento da agência de serviço social",
        SPMNAL: "Provisão para manutenção de necessidades do cônjuge",
        SSIEMG: "Avanço de emergência de SSI",
        STPEN: "Pensão do estado Web",
        STRIKE: "Benefícios de greve",
        STUDNT: "Ganhos do estudante",
        SUMYTH: "Entretenimento e viagem de jovens no verão",
        SUPPRT: "Apoio, suporte",
        SVCPUB: "Serviço para habitação pública",
        TAFDCS: "Complemento TAFDC",
        TRNALL: "Subsídio de treinamento",
        TRNSTI: "Bolsa de treinamento",
        TRUST: "Fundos fiduciários",
        UNEMPL: "Seguro desemprego",
        VETBEN: "Benefício de veterano",
        VANSCM: "Serviço federal não relacionado a assuntos de veteranos",
        VETSVC: "Assuntos de veteranos estaduais",
        VOLPMT: "Pagamento voluntário",
        WEAEDC: "EAEDC Web",
        WFMNA: "Provisão de necessidades de manutenção da família Web",
        WTAFDC: "TAFDC Web",
        WVACOM: "Compensação de assuntos de veteranos Web",
        WVAPEN: "Pensão de assuntos de veteranos Web",
        WVASCT:
          "Necessidades baseadas no estado/cidade de assuntos de veteranos Web",
        WVETAA: "Ajuda e atenção a assuntos de veteranos Web",
        WVETEB: "Benefícios aprimorados de assuntos de veteranos Web",
        WVETHB: "Benefícios domésticos de assuntos de veteranos Web",
        WVETME: "Ex médico não reembolsado de assuntos de veteranos Web",
        WVETPR:
          "Redução do pagamento de benefícios de assuntos de veteranos Web",
        YTHBLD: "Programas Youthbuild",
        PFML: "Licença médica familiar remunerada",
      };

    case languageConsts.CHINESE: //chinees
      return {
        STLWRD: "定居与奖励",
        ALIMNY: "赡养费",
        ADOPTN: "领养补助",
        ASTPAY: "补助付款",
        BLCKLG: "黑肺病",
        BOARD: "寄宿者收入",
        CHCARE: "儿童保健价值",
        CONTR: "捐献",
        DISAB: "残障福利",
        DIVSEC: "证券红利",
        AMRCRP: "美国志愿队 OJT",
        ANNUIT: "年金",
        CAPGN: "资本收益",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "劳动者报酬",
        RENTAL: "租金收入",
        OTHER: "其他",
        CHSDOR: "子女抚养费/DOR",
        DEFRA: "DEFRA 子女抚养费付款",
        DONAT: "捐款",
        EDUCAT: "教育援助",
        ELDNUT: "老人营养餐食计划",
        EMPPEN: "就业相关退休金",
        ENGAST: "能源补助",
        EXPHOU: "实验性房屋津贴",
        FEDPEN: "联邦公务员退休金",
        FEDWRK: "联邦工作补助金",
        FEP: "全职工作收入",
        FOSCAR: "寄养照顾",
        FOSPRT: "养父母",
        FREEHH: "免费家庭开支",
        GAMBL: "赌博收入",
        GOVGRT: "政府补助计划",
        HSGSUB: "住房津贴",
        INDCWA: "印第安儿童福利援助",
        INDLTR: "印第安土地信托",
        INDOLD: "印第安老年人援助",
        INDRLD: "印第安受限土地",
        INTINC: "利息收入",
        INTXRF: "所得税退税",
        JTPA: "JTPA",
        LGPEN: "网络地方政府养老金",
        LOAN: "贷款（非教育类）",
        LOANL: "贷款（非教育类一次性付款）",
        LTDINS: "长期残障保险",
        MILPE: "军人养老金",
        NGINC: "国民警卫队收入",
        NONFED: "非联邦工作补助",
        OLDAMR: "美国老年人法案基金",
        OTHINC: "其他收入",
        OTHPEN: "网络其他养老金",
        PASS: "PASS",
        PMTSEN: "支付给塞内卡王国的款项",
        PRPEN: "网络私人养老金",
        RECPCS: "子女津贴退款",
        RECPMO: "退回款项",
        REFUND: "退款",
        RETFND: "退休基金",
        REVMTG: "反向抵押贷款",
        ROOMER: "房客收入",
        ROYLTY: "版税",
        RRRET: "铁路退休",
        SHELWS: "庇护工场",
        SOCSVC: "社会服务机构付款",
        SPMNAL: "配偶赡养费需求津贴",
        SSIEMG: "SSI 预先紧急赔付",
        STPEN: "网络州政府养老金",
        STRIKE: "罢工福利",
        STUDNT: "学生收入",
        SUMYTH: "Summer Youth E & T",
        SUPPRT: "援助",
        SVCPUB: "公共房屋服务",
        TAFDCS: "TAFDC 补充费用",
        TRNALL: "培训津贴",
        TRNSTI: "培训津贴",
        TRUST: "信托基金",
        UNEMPL: "失业补偿金",
        VETBEN: "退伍军人补贴",
        VANSCM: "VA 联邦非服务相关",
        VETSVC: "VA 州",
        VOLPMT: "志愿者付款",
        WEAEDC: "网络 EAEDC",
        WFMNA: "网络家庭维持需求津贴",
        WTAFDC: "网络 TAFDC",
        WVACOM: "网络 VA 补偿",
        WVAPEN: "网络 VA 养老金",
        WVASCT: "网络 VA 州/城市需求基础",
        WVETAA: "网络 VA 援助与考勤",
        WVETEB: "网络 VA 增强福利",
        WVETHB: "网络 VA 家庭福利",
        WVETME: "网络 VA 未报销医疗费",
        WVETPR: "网络 VA 福利支付扣款",
        YTHBLD: "Youthbuild",
        PFML: "带薪家庭病假",
      };
    case languageConsts.VIETNAMESE:
      return {
        STLWRD: "Định cư và Giải thưởng",
        ALIMNY: "Cấp dưỡng",
        ADOPTN: "Hỗ trợ nuôi con nuôi",
        ASTPAY: "Thanh toán hỗ trợ",
        BLCKLG: "Phổi đen",
        BOARD: "Thu nhập nội trú",
        CHCARE: "Giá trị chăm sóc nuôi con",
        CONTR: "Khoản đóng góp",
        DISAB: "Phúc lợi do khuyết tật",
        DIVSEC: "证券Cổ tức từ Chứng khoán红利",
        AMRCRP: "Americorps OJT",
        ANNUIT: "Niên kim",
        CAPGN: "Lãi vốn",
        CHSDOR: "Trợ cấp nuôi con / DOR",
        DEFRA: "Thanh toán trợ cấp nuôi con DEFRA",
        DONAT: "Quyên góp",
        EDUCAT: "Hỗ trợ giáo dục",
        ELDNUT: "Chương trình dinh dưỡng cho người cao tuổi",
        EMPPEN: "Việc làm liên quan đến lương hưu",
        ENGAST: "Hỗ trợ năng lượng",
        EXPHOU: "Trợ cấp nhà ở thí nghiệm",
        FEDPEN: "Lương hưu trí của công chức liên bang",
        FEDWRK: "Vừa học vừa làm liên bang",
        FEP: "Thu nhập từ việc làm toàn thời gian",
        FOSCAR: "Chăm sóc nuôi dưỡng",
        FOSPRT: "Cha / mẹ nuôi",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "Bồi thường lao động",
        RENTAL: "Thu nhập cho thuê",
        OTHER: "Khác",
        FREEHH: "Chi phí cho hộ gia đình miễn phí",
        GAMBL: "Tiền thắng cược",
        GOVGRT: "Chương trình tài trợ của chính phủ",
        HSGSUB: "Trợ cấp nhà ở",
        INDCWA: "Hỗ trợ phúc lợi trẻ em dân Da đỏ.",
        INDLTR: "Quỹ đất dân Da đỏ",
        INDOLD: "Hỗ trợ người già dân Da đỏ",
        INDRLD: "Đất hạn chế dân Da đỏ",
        INTINC: "Thu nhập lãi",
        INTXRF: "Hoàn thuế thu nhập",
        JTPA: "JTPA",
        LGPEN: "Web Hưu trí của chính quyền địa phương",
        LOAN: "Khoản vay (Phi giáo dục)",
        LOANL: "Khoản vay (Không thanh toán một lần)",
        LTDINS: "Bảo hiểm khuyết tật dài hạn",
        MILPE: "Lương hưu trí của quân đội",
        NGINC: "Thu nhập bảo vệ quốc gia",
        NONFED: "Vừa học vừa làm phi liên bang",
        OLDAMR: "Quỹ Đạo luật Người Mỹ già",
        OTHINC: "Thu nhập khác",
        OTHPEN: "Web lương hưu khác",
        PASS: "ĐẠT",
        PMTSEN: "Thanh toán cho Seneca Nation",
        PRPEN: "Web Hưu trí Tư nhân",
        RECPCS: "Hỗ trợ nuôi con",
        RECPMO: "Tiền bù đắp",
        REFUND: "Hoàn tiền",
        RETFND: "Quỹ hưu trí",
        REVMTG: "Khoản vay vô thời hạn (Thế chấp địa ốc ngược)",
        ROOMER: "Thu nhập ngời ở trọ",
        ROYLTY: "Tiền bản quyền",
        RRRET: "Hưu trí ngành hỏa xa",
        SHELWS: "Nhà xưởng có mái che",
        SOCSVC: "Khoản Thanh toán của Cơ quan dịch vụ xã hội ",
        SPMNAL: "Tiền cấp dưỡng cho người phối ngẫu",
        SSIEMG: "Tạm ứng khẩn cấp SSI",
        STPEN: "Web Hưu trí nhà nước",
        STRIKE: "Lợi ích đình công",
        STUDNT: "Thu nhập của sinh viên",
        SUMYTH: "Summer Youth E & T",
        SUPPRT: "Hỗ trợ",
        SVCPUB: "Dịch vụ về nhà ở công cộng",
        TAFDCS: "Bổ sung TAFDC",
        TRNALL: "Phụ cấp đào tạo",
        TRNSTI: "Lương đào tạo",
        TRUST: "Quỹ Tín thác",
        UNEMPL: "Trợ cấp thất nghiệp",
        VETBEN: "Trợ cấp cho cựu chiến binh",
        VANSCM: "VA Liên bang không liên quan đến dịch vụ",
        VETSVC: "Tiểu Bang VA",
        VOLPMT: "Thanh toán tình nguyện",
        WEAEDC: "Web EAEDC",
        WFMNA: "Web Trợ cấp nhu cầu cấp dưỡng chính trong gia đình",
        WTAFDC: "Web TAFDC",
        WVACOM: "Web Đền bù VA",
        WVAPEN: "Web Hưu trí VA",
        WVASCT: "Web Nhu cầu dựa vào Tiểu bang/Thành phố VA",
        WVETAA: "Web Trợ cấp Phục vụ và Hỗ trợ VA",
        WVETEB: "Web Lợi ích nâng cao VA",
        WVETHB: "Web Phúc lợi hộ gia đình VA",
        WVETME: "Web Chi phí y tế VA không được hoàn",
        WVETPR: "Web Giảm thanh toán Phúc lợi VA",
        YTHBLD: "Tổ chức phi lợi nhuận Youthbuild",
        PFML: "Nghỉ Phép Y Tế Gia Đình Có Trả Tiền",
      };
    case HI:
      return {
        AMRCRP: "Americorps OJT",
        ANNUIT: "Rant",
        CAPGN: "Gen nan Kapital",
        CHSDOR: "Sipò Timoun/DOR",
        DEFRA: "Peman pou Sipò Timoun DEFRA",
        DONAT: "Donasyon",
        EDUCAT: "Asistans nan Edikasyon",
        ELDNUT: "Pwogram Nitrisyon pou Granmoun Aje",
        EMPPEN: "Pansyon ki Lye ak Travay",
        ENGAST: "Asistans nan Enèji",
        EXPHOU: "Alokasyon pou Lojman Eksperimantal",
        FEDPEN: "Pansyon Sèvis Sivil Federal",
        FEDWRK: "Travay Etidyan Federal",
        FEP: "Revni Travay Konplè",
        FOSCAR: "Fanmi Akèy",
        FOSPRT: "Paran Akèy",
        FREEHH: "Depans Fwaye Gratis",
        GAMBL: "Gen nan Jwèt Aza",
        GOVGRT: "Pwogram Sibvansyon Gouvènman",
        HSGSUB: "Sibvansyon pou Lojman",
        INDCWA: "Asistans nan Byennèt Timoun Endyen.",
        INDLTR: "Fidisi Fonsyè Endyen",
        INDOLD: "Asistans pou Granmoun Endyen Aje ",
        INDRLD: "Restriksyon Tè Endyen",
        INTINC: "Revni Enterè",
        INTXRF: "Ranbousman Taks sou Revni",
        JTPA: "JTPA",
        SSI: "SSI",
        RSDI: "RSDI",
        WRKCMP: "Konpansasyon Travayè",
        RENTAL: "Revni Lwaye",
        OTHER: "Lòt",
        LGPEN: "Pansyon Gouvènman Wèb",
        LOAN: "Prè (ki Pa Edikatif)",
        LOANL: "Prè (Montan Total ki Pa Ed)",
        LTDINS: "Asirans Andikap Alontèm",
        MILPE: "Pansyon Militè",
        NGINC: "Revni Gad Nasyonal",
        NONFED: "Travay Etidyan ki Pa-Federal",
        OLDAMR: "Lwa sou Fon pou Granmoun Ameriken",
        OTHINC: "Lòt Revni",
        OTHPEN: "Lòt Pansyon Wèb",
        PASS: "PASS",
        PMTSEN: "Peman bay Nasyon Seneca",
        PRPEN: "Pansyon Prive Wèb",
        RECPCS: "Sipò Timoun Ratrape",
        RECPMO: "Lajan Ratrape",
        REFUND: "Ranbousman",
        RETFND: "Fon Retrèt",
        REVMTG: "Prè ipotèk Envèse",
        ROOMER: "Revni Chanbrè",
        ROYLTY: "Redevans",
        RRRET: "Retrèt Chemen Fè",
        SHELWS: "Atelye Pwoteje",
        SOCSVC: "Peman Ajans Sèvis Sosyal",
        SPMNAL: "Alokasyon pou Bezwen Antretyen Konjwen ",
        SSIEMG: "Avans SSI nan Ijans",
        STPEN: "Pansyon Eta Wèb",
        STRIKE: "Benefis Grèv",
        STUDNT: "Salè Etidyan",
        SUMYTH: "Summer Youth E & T",
        SUPPRT: "Sipò",
        SVCPUB: "Sèvis nan Lojman Piblik",
        TAFDCS: "TAFDC Siplemantè",
        TRNALL: "Alokasyon pou Fòmasyon",
        TRNSTI: "Bous pou Fòmasyon",
        TRUST: "Fon",
        UNEMPL: "Konpansasyon Chomaj",
        VETBEN: "Benefis pou Veteran",
        VANSCM: "VA Federal ki pa Gen Rapò ak sèvis",
        VETSVC: "Eta VA",
        VOLPMT: "Peman Volontè",
        WEAEDC: "EAEDC Wèb",
        WFMNA: "Alokasyon pou Bezwen Antretyen Fam Wèb",
        WTAFDC: "TAFDC Wèb",
        WVACOM: "Konpansasyon VA Wèb",
        WVAPEN: "Pansyon VA Wèb",
        WVASCT: "Baze sou Bezwen VA Eta/Vil Wèb",
        WVETAA: "Èd ak Prezans VA Wèb",
        WVETEB: "Benefis Amelyore VA Wèb",
        WVETHB: "Benefis Fwaye VA Wèb",
        WVETME: "VA Medikal Ex ki pa Ranbouse Wèb",
        WVETPR: "Rediksyon Peman Benefis VA Wèb",
        YTHBLD: "Youthbuild",
        STLWRD: "Règleman ak Prim",

        ALIMNY: "Alimoni",
        ADOPTN: "Asistans Adopsyon",
        ASTPAY: "Peman Asistans",
        BLCKLG: "Poumon Nwa",
        BOARD: "Revni Pansyonè",
        CHCARE: "Valè Swen Timoun",
        CONTR: "Kontribisyon",
        DISAB: "Benefis Andikap",
        DIVSEC: "Dividann nan Aksyon",
        PFML: "Konje Medikal Familyal Peye",
      };
    default:
      return;
  }
}
