import React, { useEffect, useState } from "react";
import { BroadcastService, BroadcastServiceInitialize } from "@microsoft/omnichannel-chat-components";
import { LiveChatWidget } from "@microsoft/omnichannel-chat-widget";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
// import { ICustomEvent } from "@microsoft/omnichannel-chat-components";
import { ElementType } from "@microsoft/omnichannel-chat-components";
import { fetchToken, sendChatData } from "../../../redux/chat/chatActions";
import axios from "axios";
import "../chatBox/chatBot.scss";

import {DeliveryMode, MessageContentType, MessageType, PersonType} from '@microsoft/omnichannel-chat-sdk';

// Below version numbers will help us to troubleshoot issues with specific package
import { version as chatSdkVersion } from "@microsoft/omnichannel-chat-sdk/package.json";
import { version as chatWidgetVersion } from "@microsoft/omnichannel-chat-widget/package.json";
import { version as chatComponentVersion } from "@microsoft/omnichannel-chat-components/package.json";

function LiveChat({ }){
    const [chatBox, setChatBox] = useState(null)
    const [handleTimeout, setHandleTimeout] = useState(0);
    const [ hideSendBox, setHideSendBox ] = useState(true);
    const [chatConfig, setChatConfig] = useState(null);
    const [chatSDK, setChatSDK] = useState(null);
    const omnichannelConfig = {
        orgId: "5ba55014-88f1-ee11-9048-000d3a141822",
        orgUrl: "https://unq5ba5501488f1ee119048000d3a141-crm.omnichannelengagementhub.com",
        widgetId: "15255e46-0102-44b7-b5a7-187bbd12050b"
    };
    const chatSDKConfig = {
        dataMasking: {
            disable: false,
            maskingCharacter: '#'
        }
    }
    BroadcastServiceInitialize(chatSDK?.omnichannelConfig?.widgetId); // Initialize the broadcast service with widgetid

    useEffect(()=> {
        renderChatBox();        
    },[]);

    useEffect(() => {
        const subscription = BroadcastService.getMessageByEventName("CloseChatCall").subscribe(() => {
            var chatHistory = localStorage.getItem('userChatHistory');
            if(chatHistory != null){
                chatHistory = JSON.parse(chatHistory);
                sendChatData(chatHistory);
                localStorage.removeItem('userChatHistory');
            }
            resetTimerSessionWarningMsg();
            resetTimerSessionEndMsg();
        });

        const subscription2 = BroadcastService.getMessageByEventName("StartProactiveChatEventReceived").subscribe(() => {
            console.log('timer test NewMessageReceived');
        });

        const subscription1 = BroadcastService.getMessageByEventName("WebChatLoaded").subscribe(() => {
            setCustomContext();
            console.log('timer test WebChatLoaded');
            let widgetProps = getLiveChatWidgetProps(true, chatSDK, chatConfig);
            setChatBox({...widgetProps});
            setHideSendBox((prev) => true);
        });

        return () => {
            subscription.unsubscribe();
            subscription1.unsubscribe();
            subscription2.unsubscribe();
        };
    }, [chatConfig, chatSDK]);
    useEffect(()=> {
        if(!hideSendBox) {
            let widgetProps = getLiveChatWidgetProps(false, chatSDK, chatConfig);
            setChatBox((prev) => {return {...widgetProps}});
        }
    }, [hideSendBox]);

    let myTimeout = 0;
    let sessionTimeout = 0;
    const ENG = 'ENG';
    const ESP = 'ESP';
    const engSessionWarning = "Your session will expire after 10 minutes of inactivity.";
    const espSessionWarning = "Su sesión caducará después de 10 minutos de inactividad.";
    const engSessionEnd = "Thank you for contacting us. Have a great day.";
    const espSessionEnd = "Gracias por contactarnos. Qué tengas un lindo día.";
    const engRewordmsg = 'Please reword your question or if you want to be connected to a live agent, please click the link below.';
    const espRewordmsg = 'Lo siento, no entendí su pregunta. Por favor repita su pregunta o solicite un chat en vivo con un agente.';
    const engdNotUnderstandmsg = 'Sorry, I did not understand your question. Please ask your question again or request to live chat with an agent.';
    const espNotUnderstandmsg = 'Vuelva a redactar su pregunta o, si desea conectarse con Live Agent, haga clic en el enlace a continuación.';

    const getUserSelectedLang = () => {
        return localStorage.getItem('userChatSelectedLang') ?? ENG;
    }

    const getBotMsg = (msgType) => {
        const selectedLang = getUserSelectedLang();
        if(msgType == 'reword'){
            return selectedLang == ENG ? engRewordmsg : espRewordmsg;
        }
        else if(msgType == 'notUnderstand'){
            return selectedLang == ENG ? engdNotUnderstandmsg : espNotUnderstandmsg;
        }
    }
    const getSessionMsg = (isSessionWarning) => {
        const selectedLang = getUserSelectedLang();
        if(isSessionWarning){
            return selectedLang === ENG ? engSessionWarning : espSessionWarning;
        }
        else{
            return selectedLang === ENG ? engSessionEnd : espSessionEnd;
        }
    }

    function sendSessionWarningMsg() {
        //console.log('timer test');
        chatSDK.sendMessage( {
            content: getSessionMsg(true)
        });
    }

    function setTimerSendSessionWarningMsg(){
        if(myTimeout){
            resetTimerSessionWarningMsg();
        }
        console.log('timer set');
        myTimeout = setTimeout(sendSessionWarningMsg, 120000);
    }

    function resetTimerSessionWarningMsg() {
        if(myTimeout){
            console.log('timer reset')
            clearTimeout(myTimeout);
        }
    }

    function sendSessionEndMsg() {
        console.log('timer test 2');
        chatSDK.sendMessage( {
            content: getSessionMsg(false)
        });

        //close chat
        setTimeout(() => {
            document.getElementById('lcw-header-close-button')?.click();
        }, 2000);

        setTimeout(() => {
            document.getElementById('oc-lcw-confirmation-pane-confirmbutton')?.click();      
        }, 2000);
    }

    function setTimerSendSessionEndMsg(){
        if(sessionTimeout){
            resetTimerSessionEndMsg();
        }
        console.log('timer set 2');
        sessionTimeout = setTimeout(sendSessionEndMsg, 720000);
    }

    function resetTimerSessionEndMsg() {
        if(sessionTimeout){
            console.log('timer reset 2ssss');
            clearTimeout(sessionTimeout);
        }
    }

    const sampleActivityMiddleware = () => (next) => (card) => {
        console.log('timer timer cardcard', card);
        if(card.activity.text == "An agent will be with you in a moment.")
        {
            card.activity.text = '';
        }
        if(card.activity.text == "Your selected language is English, Please ask the question in English")
        {
            localStorage.setItem('userChatSelectedLang', ENG);
            hideSendBox && setHideSendBox((prev) => false);
        }
        else if(card.activity.text == "Su idioma seleccionado es español, por favor haga la pregunta en español"){
            localStorage.setItem('userChatSelectedLang', ESP);
            hideSendBox && setHideSendBox((prev) => false);
        }
        
        if(card.activity && card.activity.type == 'message' && card.activity.text) {
            var chatHistory = JSON.parse(localStorage.getItem('userChatHistory'));
            var currentMsg = {
                msgId: card.activity.messageid,
                msg: `${card.activity.from.role}:${card.activity.text}`,
                //conversationId: card.activity.conversation.id
            };

            if(chatHistory == null && card.activity.messageid) {
                chatHistory = [];
                chatHistory.push(currentMsg);
                setTimerSendSessionWarningMsg();
                setTimerSendSessionEndMsg();
                console.log('timer cardcard', card);
            }
            else
            {
                //chatHistory = JSON.parse(chatHistory);
                if(chatHistory && chatHistory.length && card.activity.messageid) {
                    var isExist = chatHistory.filter(a=> a.msgId === currentMsg.msgId);
                    if(isExist.length === 0){
                        chatHistory.push(currentMsg);
                        // console.log('cardcard', card);
                        if(currentMsg.msg !== `user:${getSessionMsg(true)}` && 
                            currentMsg.msg !== `user:${getSessionMsg(false)}` && 
                            currentMsg.msg !== '' && 
                            currentMsg.msg !== `bot:${getBotMsg('reword')}` &&
                            currentMsg.msg !== `bot:${getBotMsg('notUnderstand')}`
                        )
                        {
                            setTimerSendSessionWarningMsg();
                            setTimerSendSessionEndMsg();
                        }
                        console.log('timer cardcard', card);
                    }
                }
            }

            localStorage.setItem('userChatHistory', JSON.stringify(chatHistory));

            if(currentMsg.msg == `bot:${getBotMsg('reword')}` ||
            currentMsg.msg == `bot:${getBotMsg('notUnderstand')}`){
                if(chatHistory && chatHistory.length) {
                    var sessionMsgList = [`user:${getSessionMsg(true)}`, `user:${getSessionMsg(false)}`];
                    var isExits = chatHistory.some(r=> sessionMsgList.includes(r.msg));
                    if(isExits){
                        card.activity.text = '';
                    }
                }
            }

            if(card.activity.text == getSessionMsg(true) || card.activity.text == getSessionMsg(false)){
                card.activity.from.role = 'bot';
                card.activity.from.name = 'ACE';
            }
        }
        
        return next(card);
    };
    const setCustomContext = () => {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        console.log("Inside customContext", userInfo);
        if(userInfo) {
            const setCustomContextEvent = {
                eventName: "SetCustomContext",
                payload: {
                    "First Name": {"value": userInfo.firstName, "isDisplayable": true},
                    "Last Name": {"value": userInfo.lastName, "isDisplayable": true},
                    "Email": {"value": userInfo.email, "isDisplayable": true},
                    "Client Id": {"value": userInfo.agencyID, isDisplayable: true},
                    "User Type": {"value": "LoggedIn User", "isDisplayable": true},
                }
            };
            BroadcastService.postMessage(setCustomContextEvent);
        }
        else {
            const setCustomContextEvent = {
                eventName: "SetCustomContext",
                payload: {
                    "UserType": {"value": "Anonymous User", "isDisplayable": true},
                }
            };
            BroadcastService.postMessage(setCustomContextEvent);
        }
        
    };

    let getLiveChatWidgetProps = (hideSendBox, chatSDK, chatConfig) => {
        return {
            webChatContainerProps: {
                webChatStyles: {
                    hideUploadButton: true,
                    backgroundColor: "white",
                    hideSendBox: hideSendBox,
                    avatarSize: 0,
                    bubbleBackground: "#f5f5f5",
                    bubbleTextColor: "black",
                    bubbleFromUserBackground: "#f5f5f5",
                    bubbleFromUserTextColor: "black"
                },
                adaptiveCardStyles: {
                    background: "#f5f5f5",
                },
                renderingMiddlewareProps: {
                    disableAvatarMiddleware: true
                },
                webChatProps: {
                    activityMiddleware: sampleActivityMiddleware,
                    overrideLocalizedStrings:{ 
                        TEXT_INPUT_PLACEHOLDER: 'Please ask your question.'
                    }
                }
            },

            styleProps: {
                generalStyles: {
                    width: "440px",
                    height: "600px",
                    bottom: "30px",
                    right: "30px"
                }
            },
            
            loadingPaneProps: {
                controlProps: {
                    hideLoadingPane: false,
                    hideIcon: true,
                    hideTitle: true,
                    hideSubtitle: true,
                    spinnerSize: 3,
                    hideSpinnerText: true
                },
                styleProps: {
                    generalStyleProps: {
                        backgroundColor: "white"
                    }
                }
            },
            chatButtonProps: {
                controlProps: {
                    hideChatSubtitle: true,
                    titleText: "Need help? Ask ACE"
                },
              
                styleProps: {
                    generalStyleProps: {
                        display: "flex",
                        backgroundColor: "#c1e0ff",
                        color: "#000",
                        alignItems: "center",
                        borderRadius: "5px"

                    },
                    iconStyleProps: {
                        backgroundColor: "none",
                        borderStyle: "none",
                        height: "64px",
                        width: "50px",
                        borderRadius: "0px", 
                        backgroundSize: "cover",
						backgroundImage: "url(https://publicaruze15062023.s3.us-east-2.amazonaws.com/dhrlogin/al/chatboticon.png)",
                    },
                    titleStyleProps: {
                        fontSize: "14px",
                        fontWeight: "none",
                        margin: "0px",
                        maxWidth: "130px"
                    }
                }
            },
            confirmationPaneProps: {
                controlProps: {
                    hideSubtitle: true,
                }
            },
            draggableChatWidgetProps:{
                disabled: true
            },
            headerProps: {
                controlProps: {
                    headerTitleProps: {
                        text: "Ask ACE",
                    },
					headerIconProps: {
                        src: "https://publicaruze15062023.s3.us-east-2.amazonaws.com/dhrlogin/al/chatboticon.png"
                    },
                },
                styleProps: {
                    generalStyleProps: {
                        height: "72px",
                        background: "#d1dce5"
                    },
                    titleStyleProps: {
                        fontSize: "22px",
                        color: "#000"
                    },
                    iconStyleProps: {
                        height: "75px",
                        width: "75px",
                        margin: "0px"
                    },
                    minimizeButtonStyleProps: {
                        color: "black"
                    },
                    closeButtonStyleProps: {
                        color: "black"
                    },
                }
            },
            footerProps: {
                controlProps: {
                    hideDownloadTranscriptButton: true,
                    hideAudioNotificationButton: true,
                    hideEmailTranscriptButton: true
                }
            },
          chatSDK: chatSDK, // mandatory
          chatConfig: chatConfig, // mandatory
          telemetryConfig: {
              chatWidgetVersion: chatWidgetVersion,
              chatComponentVersion: chatComponentVersion,
              OCChatSDKVersion: chatSdkVersion
          }
        };
    }

    let renderChatBox = async () => {
        const chatSDK = new OmnichannelChatSDK(omnichannelConfig, chatSDKConfig);
        await chatSDK.initialize(); // mandatory
        setChatSDK((prev) => chatSDK);
        const chatConfig = await chatSDK.getLiveChatConfig();
        setChatConfig((prev) => chatConfig);
        const liveChatWidgetProps = getLiveChatWidgetProps(true, chatSDK, chatConfig);
        setChatBox({...liveChatWidgetProps});
    };
    return (chatBox && <LiveChatWidget {...chatBox}  /> )
}
export default LiveChat;