import React, { Component } from "react";
import PropTypes from "prop-types";
import AddToCalendar from "react-add-to-calendar";
import moment from "moment";

import * as EventProps from "../../../constants/event";
import {
  date as formatDate,
  MonthDateYearFormat,
} from "../../utilities/formatters";
import Icon from "../../dtaIcon";
import {
  activityGetDescription,
  consumerConst,
} from "../../../../pages/consumer/consumerText";
import * as helperFunction from "../../helperFunctions/helperFunctions";
import {
  showSchedularPage,
  showSchedularSnapPage,
  showSchedularCaseModelPage,
  getAvailableDateAndTimes,
  cancelAppoinmentPostCall
} from "../../../../redux/consumer/consumerActivityActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as TextProps from "../../../../utils/constants/text";
import DTAModal from "../../modal/dtaModal";
import DTATextArea from "../../dtaTextArea";
import DTASelect from "../../dtaSelect";
import * as modalHelperFunction from "../../../../utils/components/helperFunctions/modalHelperFunction";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class EventContents extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      cancelRequestModalOpen: TextProps.VALUE_FALSE,
      reasonSelectError: TextProps.VALUE_FALSE,
      cancelCommentError: TextProps.VALUE_FALSE,
      showCancelSuccessModal: TextProps.VALUE_FALSE,
      reasonSelect: "",
      cancelComment: "",
    }
  }
  static propTypes = {
    [EventProps.TYPE]: PropTypes.string.isRequired,
    [EventProps.DATE]: PropTypes.instanceOf(Date).isRequired,
    [EventProps.LOCATION]: PropTypes.string.isRequired,
    [EventProps.URL]: PropTypes.string.isRequired,
    [EventProps.ACTIVITY_TYPE]: PropTypes.string.isRequired,
    [EventProps.NEWTYPECODE]: PropTypes.string.isRequired,
    [EventProps.NEW_BU_OFFICE]: PropTypes.string.isRequired,
  };

  showModal = () => {
    this.setState({
      cancelRequestModalOpen: TextProps.VALUE_TRUE,
    });
  }
  closeCancelModal = () => {
    this.setState({
      cancelRequestModalOpen: TextProps.VALUE_FALSE,
      cancelCommentError: TextProps.VALUE_FALSE,
      reasonSelectError: TextProps.VALUE_FALSE,
      reasonSelect: "",
      cancelComment: ""
    });
  }
  _commentHandler = (value) => {
    this.state.reasonSelect === consumerConst(selectedLanguage).commonOTHER && this.state.reasonSelect == "" ? this.setState({
      cancelCommentError: TextProps.VALUE_TRUE
    }) : this.setState({
      cancelCommentError: TextProps.VALUE_FALSE
    })
    this.setState({
      cancelComment: value,
    })
  }
  _selectHandler = (code) => {
    this.setState({
      reasonSelect: this.getSelect(code),
      reasonSelectError: TextProps.VALUE_FALSE
    })
  }
  getSelect = (code) => {
    switch (code) {
      case consumerConst(selectedLanguage).cancelRequestOptions[0]:
        return consumerConst(selectedLanguage).commonCONWAD
      case consumerConst(selectedLanguage).cancelRequestOptions[1]:
        return consumerConst(selectedLanguage).commonFLYEMG
      case consumerConst(selectedLanguage).cancelRequestOptions[2]:
        return consumerConst(selectedLanguage).commonSICKNS
      case consumerConst(selectedLanguage).cancelRequestOptions[3]:
        return consumerConst(selectedLanguage).commonWCREQA
      case consumerConst(selectedLanguage).cancelRequestOptions[4]:
        return consumerConst(selectedLanguage).commonLACKCC
      case consumerConst(selectedLanguage).cancelRequestOptions[5]:
        return consumerConst(selectedLanguage).commonLACKTS
      case consumerConst(selectedLanguage).cancelRequestOptions[6]:
        return consumerConst(selectedLanguage).commonOTHER
      default:
        return ''
    }
  }
  SubmitValidate = () => {
    const { common } = this.props.language.consumer;
    const userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
    const agencyID = userDetails.agencyID;
    let payload = {
      agencyID: agencyID,
      clientSchApptID: this.props.id,
      providerSchApptID: this.props.pvrId,
      comments: this.state.cancelComment,
      apptCancelReasonCode: this.state.reasonSelect
    };
    if (this.state.cancelComment === "" && this.state.reasonSelect === consumerConst(selectedLanguage).commonOTHER) {
      this.setState({
        cancelCommentError: TextProps.VALUE_TRUE
      })
    }
    else if (this.state.reasonSelect === "") {
      this.setState({
        reasonSelectError: TextProps.VALUE_TRUE
      })
    }
    else if (this.state.reasonSelect !== consumerConst(selectedLanguage).commonOTHER) {
      this.props.cancelAppoinmentPostCall(payload);
      this.setState({
        cancelRequestModalOpen: TextProps.VALUE_FALSE,
        reasonSelect: "",
        cancelComment: ""
      })
      this.showSuccessModal();
    }
    else if (this.state.reasonSelect === consumerConst(selectedLanguage).commonOTHER && this.state.cancelComment) {
      this.props.cancelAppoinmentPostCall(payload);
      this.setState({
        cancelRequestModalOpen: TextProps.VALUE_FALSE,
        reasonSelect: "",
        cancelComment: ""
      })
      this.showSuccessModal();
    }
    else {
    }
  }
  componentWillReceiveProps (nextProps){
    const { common } = this.props.language.consumer;
    if (this.successModal) {
      if (nextProps && nextProps.consumer.cancelDataLoading) {
        this.successModal.showBlur("loading", null);
      }
      if (nextProps && nextProps.consumer.cancelAppointmentSuccess) {
        this.successModal.showBlur(
          "success",
          <div style={{padding:'0 0 1.5rem 0'}}>{common.cancelModalSucces}</div>)
        setTimeout(() => {
          window.location.assign("/consumer/activity");
        }, 3500);
      }
      if (nextProps && nextProps.consumer.cancelAppointmentFailure) {
        this.successModal.showBlur(
          "error",
          <div>Please try again later.</div>
        );
        setTimeout(() => {
          window.location.assign("/consumer/activity");
        }, 2000);
      }
    }
  }
  showSuccessModal = () => {
    this.setState({ showCancelSuccessModal: TextProps.VALUE_TRUE });
  };

  closeCancelSuccessModal = () => {
    this.setState({ showCancelSuccessModal: TextProps.VALUE_FALSE });
  };
  renderCancelSubmitPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.successModal = instance),
      this.state.showCancelSuccessModal,
      this.closeCancelSuccessModal,
      "cancel-appointment-modal",
      "cancel-appointment",
      false
    );
  };
  showNextPage = async (
    appointmentType,
    agencyID,
    caseWorkerId,
    id,
    pvrId,
    dueDate,
    appointmentTypeCode
  ) => {
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    sessionStorage.setItem('appointmentTypeCode', appointmentTypeCode);
    if (
      consumerDataUserObj &&
      consumerDataUserObj.alerts &&
      consumerDataUserObj.alerts.appointments.length > 0
    ) {
      let apmntDetails;
      apmntDetails = consumerDataUserObj.alerts.appointments.filter(
        (val) => val.id === id
      );
      sessionStorage.setItem("apmntDetails", JSON.stringify(apmntDetails));
      sessionStorage.setItem("apmntDetailPvrID", pvrId);
    }
    if (appointmentType === TextProps.VALUE_Y) {
      await this.props.showSchedularSnapPage();
      await this.props.getAvailableDateAndTimes(
        agencyID,
        MonthDateYearFormat(dueDate)
      );
    } else {
      this.props.showSchedularCaseModelPage();
    }
    this.props.showSchedularPage();
  };

  _handleAddCalendar = (value, activityData) => {
    if (value !== undefined && value !== null) {
      var today = moment(new Date());
      if (moment(value).isBefore(today)) {
        return null;
      } else {
        //invalid date
        return this._buildLink(activityData);
      }
    } else {
      return null;
    }
  };

  _handleRescheduleStatus = (status) => {
    if (status && status !== "RESCHD") {
      let statusText = "";
      if (status === "REQSTD") {
        statusText = consumerConst(selectedLanguage).requestPending;
      } else if (status === "DISMIS") {
        statusText = consumerConst(selectedLanguage).requestCompleted;
      } else if (status === "DENIED") {
        statusText = consumerConst(selectedLanguage).requestNotProcessed;
      }
      return <p className="appt-status-text">{statusText}</p>;
    } else {
      return null;
    }
  };

  _handleCancelStatus = (status) => {
    let statusText = "";
    if (status === "REQCAN") {
      statusText = consumerConst(selectedLanguage).cancelPending;
    } else if (status === "CANCEL") {
      statusText = consumerConst(selectedLanguage).cancelCompleted;
    }
    return <p className="appt-status-text">{statusText}</p>;
  };

  handleTypeCode() {
    // snap application
    let getSnapSubmitDate =
      this.props &&
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.snapSubmitDt;
     


    // snap Recertification
    let recertDueDate =
      this.props &&
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.recertInfo &&
      this.props.consumer.consumerData.recertInfo[0].NEXT_RECERT_DUEDATE;


    let getRecertInfo =
      this.props &&
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData;

    // Tanf
    let getTanfSubmitDate =
      this.props &&
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.tanfSubmitDt;


    // Tanf Retermination
    let getReterminationDate =
      this.props &&
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.recertInfo &&
      this.props.consumer.consumerData.recertInfo[0].NEXT_RECERT_DUEDATE;
    


    const moment = require('moment');
    let currentDate = moment();

    // snap Application
    let getSnapSubmitDt = (getSnapSubmitDate);
    let snapSubmitDate = moment(getSnapSubmitDt);
    let handleAddDay = snapSubmitDate.clone().add(30, 'days');
    let formateSnapDate = handleAddDay.format("YYYY-MM-DD");
   


    // snap recertification
    let recertDate = (recertDueDate);
    let recertificationDate = moment(recertDate);
    let dueDate = recertificationDate.clone().add(30, 'days');
    let formattedRecertDate = dueDate.format("YYYY-MM-DD");


    // tanf application
    let getTanfSubmitDt = (getTanfSubmitDate);
    let tanfSubmitedDate = moment(getTanfSubmitDt);
    let handleTanfDate = tanfSubmitedDate.clone().add(30, "days");
    let formattedTanfDate = handleTanfDate.format("YYYY-MM-DD");


    // Tanf Retermination

    let getTanfReterminationDate = getReterminationDate;
    let date = moment(getTanfReterminationDate);
    let getNextMonthLastDate = date.add(1, 'months').endOf('month').format('YYYY-MM-DD');
   


    if (this.props.consumer.consumerData.alerts) {
      return this.props.consumer.consumerData.alerts.appointments.some((data) => {
        //snap application
        if (data.typeCode === "20" || data.typeCode === "19") {
          if (currentDate.isSameOrBefore(formateSnapDate)) {
            return true;
          } else {
            return false;
          }
        } else if (data.typeCode === "24") {
          //snap recertification  
          if (getRecertInfo.recertInfo && getRecertInfo.recertInfo[0].SNAP_FLAG === "Yes" && getRecertInfo.recertInfo[0].RECERT_TYPE === "RECERT") {
            if (currentDate.isSameOrBefore(formattedRecertDate)) {
              return true;
            } else {
              return false;
            }
          }
        } else if (data.typeCode === "17" || data.typeCode === "94" || data.typeCode === "98") {
          // tanf Application
          if (currentDate.isSameOrBefore(formattedTanfDate)) {
            return true;
          } else {
            return false;
          }
        }
         else if (data.typeCode === "22" || data.typeCode === "54") {
          // Tanf Retermination
          if ( getReterminationDate.TAFDC_FLAG === "Yes" && getReterminationDate.RECERT_TYPE === "REEVAL")   { 
            if (currentDate.isSameOrBefore(getNextMonthLastDate)) {
              return true;
            } else {
              return false;
            }
          }
        }
      });
    }
  }


  render() {
    const { common } = this.props.language.consumer;
    var recertStartTime = moment(this.props[EventProps.REEVAL_END_DATE]);
    const activityData = {
      title: `${this.props[EventProps.PROGRAM_TYPE]} Reevaluation Due`,
      date:
        typeof this.props[EventProps.DATE] === "string"
          ? new Date(this.props[EventProps.DATE])
          : this.props[EventProps.DATE],
      startTime: recertStartTime,
      endTime: recertStartTime,
      type: this.props[EventProps.TYPE],
      location: this.props[EventProps.LOCATION],
      eventStatus: this.props[EventProps.STATUS],
      activityType: this.props[EventProps.ACTIVITY_TYPE],
      description: "",
    };

    const newActivityData = {
      title: this.props.appointmentDescription,
      location: this.props[EventProps.NEW_BU_OFFICE],
      startTime: moment(this.props[EventProps.NEW_START_TIME]),
      endTime: moment(this.props[EventProps.NEW_END_TIME]),
      description: "",
      type: this.props[EventProps.TYPE],
      readStatus: this.props[EventProps.STATUS],
    };

    var viewStartDate = moment
      .utc(this.props[EventProps.NEW_START_TIME])
      .local()
      .format("MM/DD/YYYY");



    if (this.props[EventProps.NEWTYPECODE] === "PRVAPP") {
      var scheduletime = this.props['schTime']
      var viewstartTime = scheduletime.split('-').map(time => time.trim());
      var formattedTime = viewstartTime.map(time => {
        return moment(time, 'hh A').format('hh:mm A');
      });
      var viewTime = formattedTime.join(' to ');
    }
    else
    {
      var viewStartTime = moment
        .utc(this.props[EventProps.NEW_START_TIME])
        .local()
        .format("hh:mm A");

      var viewEndTime = moment
        .utc(this.props[EventProps.NEW_END_TIME])
        .local()
        .format("hh:mm A");

      var viewTime = `${viewStartTime} to ${viewEndTime}`;
    }

    if (this.props[EventProps.ACTIVITY_TYPE] === "REVAL") {
      let eventDesc = null;
      if (this.props[EventProps.PROGRAM_TYPE] === "SNAP") {
        // eventDesc = <div>It's time to recertify your SNAP benefits. Your Supplemental Nutrition Assistance Program (SNAP) Benefits are due to end on  {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}</div>
        eventDesc = (
          <div>
            {consumerConst(selectedLanguage).recertify}{" "}
            {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}
          </div>
        );
      } else if (this.props[EventProps.PROGRAM_TYPE] === "TAFDC") {
        // eventDesc = <div>Your Transitional Aid to Families with Dependent Children ({this.props[EventProps.PROGRAM_TYPE]}) benefits are due to end on   {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}</div>
        eventDesc = (
          <div>
            {consumerConst(selectedLanguage).transAid} (
            {this.props[EventProps.PROGRAM_TYPE]}){" "}
            {consumerConst(selectedLanguage).benefits}{" "}
            {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}
          </div>
        );
      } else {
        // eventDesc = <div>Your Emergency Aid to Elderly, Disabled, Child  ({this.props[EventProps.PROGRAM_TYPE]}) benefits are due to end on   {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}</div>
        eventDesc = (
          <div>
            {consumerConst(selectedLanguage).emergency} (
            {this.props[EventProps.PROGRAM_TYPE]}){" "}
            {consumerConst(selectedLanguage).benefits}{" "}
            {formatDate(new Date(this.props[EventProps.REEVAL_END_DATE]))}
          </div>
        );
      }

      return (
        <div>
          {/* icon decoration */}
          <div className="show-large show-slightly-larger show-extra-large dta-list-item__decoration">
            <Icon name="warning" className="dta-list-item__icon" />
          </div>
          {/* list item heading */}
          <div className="pure-g">
            <div className="pure-u-4-5">
              <div className="text--bold text--medium">
                {this.props[EventProps.PROGRAM_TYPE]} {"Reevaluation Due"}
              </div>
            </div>
            <div className="pure-u-1-5 text--right">
              <div className="show-mobile show-small show-medium">
                <Icon name="warning" className="dta-list-item__icon" />
              </div>
              <div className="show-large show-slightly-larger show-extra-large">
                {this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" && (
                  <div>
                    {this._handleAddCalendar(
                      new Date(this.props[EventProps.NEW_START_TIME]),
                      newActivityData
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pure-g">
            <div className="pure-u-4-5">
              <div className="text--medium">
                {this.props[EventProps.PROGRAM_TYPE] === "SNAP"
                  ? consumerConst(selectedLanguage).recertification
                  : consumerConst(selectedLanguage).revaluation}
                :{" "}
                <span className="text--bold">
                  {" "}
                  {formatDate(new Date(recertStartTime))}{" "}
                </span>
              </div>
            </div>
          </div>
          {/* spacer for small screens */}
          <div className="show-mobile show-small show-medium pure-u-1 pad-top-half" />
          {/* details */}
          {eventDesc}
          <div className="show-mobile show-small show-medium margin-top-half">
            {this._buildLink(activityData)}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {/* icon decoration */}
          <div className="show-large show-slightly-larger show-extra-large dta-list-item__decoration">
            <Icon name="event" className="dta-list-item__icon" />
          </div>
          {/* list item heading */}
          <div className="pure-g">
            <div className="pure-u-4-5">
              <div className="text--bold text--medium">
                {this.props[EventProps.NEWTYPECODE] === "PRVAPP" ? this.props.appointmentDescription : activityGetDescription(
                  "appnmt_code_" + this.props[EventProps.NEWTYPECODE]
                )}
              </div>
              <div className="pure-g">
                <div className="pure-u-4-5">
                  <div className="text--medium">
                    {" "}
                    {/* {viewStartDate} {viewStartTime} to {viewEndTime} */}
                    {viewStartDate} {viewTime}
                  </div>
                  {this.props[EventProps.NEWTYPECODE]  === "PRVAPP" && (
                    <div>{this.props[EventProps.PROVIDER_NAME].first} {this.props[EventProps.PROVIDER_NAME].last}</div>
                  )}
                </div>
              </div>
              {/* spacer for small screens */}
              <div className="show-mobile show-small show-medium pure-u-1 pad-top-half" />
              {/* details */}
              <div>{this.props[EventProps.NEW_BU_OFFICE]}</div>
              {this._handleRescheduleStatus(
                this.props[EventProps.RESCHEDULE_STATUS]
              )}
              <div className="show-mobile show-small show-medium pure-u-1 pad-top-half" />
              <div >{this._handleCancelStatus(
                this.props[EventProps.RESCHEDULE_STATUS]
              )}
              </div>
              <div className="show-mobile show-small show-medium margin-top-half">
                {this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" && (
                  <div>
                    {this._handleAddCalendar(
                      new Date(this.props[EventProps.NEW_START_TIME]),
                      newActivityData
                    )}
                  </div>
                )}
                {this.props[EventProps.CHANGE_ALLOWED_FLAG] === TextProps.VALUE_Y &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "DENIED"  &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "REQCAN" &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" ? (
                  <p
                    className="change-apt-link"
                    style={{marginTop:'10px'}}
                    onClick={() =>
                      this.showNextPage(
                        this.props.fawClientApptFlg,
                        this.props.agencyID,
                        this.props.caseWorkerId,
                        this.props.id,
                        this.props.pvrId,
                        this.props[EventProps.DUE_DATE],
                        this.props[EventProps.NEWTYPECODE]
                      )
                    }
                  >
                    {common.change}
                  </p>
                ) : null}
                {
                  this.props[EventProps.RESCHEDULE_STATUS] !== "REQCAN" &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" &&
                  this.props[EventProps.CHANGE_ALLOWED_FLAG] !== "N" &&  (
                    <p
                      className="change-apt-link"
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        this.setState({
                          cancelRequestModalOpen: TextProps.VALUE_TRUE
                        })
                      }
                    >
                      {common.cancelConsumer}
                    </p>
                  )
                }
              </div>
            </div>
            <div className="pure-u-1-5 text--right">
              <div className="show-mobile show-small show-medium">
                <Icon name="warning" className="dta-list-item__icon" />
              </div>
              <div className="show-large show-slightly-larger show-extra-large">
                {this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" && (
                  <div>
                    {this._handleAddCalendar(
                      new Date(this.props[EventProps.NEW_START_TIME]),
                      newActivityData
                    )}
                  </div>
                )}
                {this.props[EventProps.CHANGE_ALLOWED_FLAG] ===
                  TextProps.VALUE_Y &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "DENIED" &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "REQCAN" &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL"   ? (
                  <p
                    className="change-apt-link"
                    style={{marginTop:'10px'}}
                    onClick={() =>
                      this.showNextPage(
                        this.props.fawClientApptFlg,
                        this.props.agencyID,
                        this.props.caseWorkerId,
                        this.props.id,
                        this.props.pvrId,
                        this.props[EventProps.DUE_DATE],
                        this.props[EventProps.NEWTYPECODE]
                      )
                    }
                  >
                    {common.change}
                  </p>
                ) : null}

                {this.props[EventProps.RESCHEDULE_STATUS] === "MISSED" && this.handleTypeCode() ? (
                  <div>
                    <p
                      className="change-apt-link"
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        this.showNextPage(
                          this.props.fawClientApptFlg,
                          this.props.agencyID,
                          this.props.caseWorkerId,
                          this.props.id,
                          this.props.pvrId,
                          this.props[EventProps.DUE_DATE],
                          this.props[EventProps.NEWTYPECODE]
                        )
                      }
                    >
                      {common.change}
                    </p>
                    <p
                      className="change-apt-link"
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        this.setState({
                          cancelRequestModalOpen: TextProps.VALUE_TRUE
                        })
                      }
                    >
                      {common.cancelConsumer}
                    </p>
                  </div>
                ) : null}

                {
                  this.props[EventProps.RESCHEDULE_STATUS] !== "REQCAN" &&
                  this.props[EventProps.RESCHEDULE_STATUS] !== "CANCEL" &&
                  this.props[EventProps.CHANGE_ALLOWED_FLAG] !== "N" && (
                    <p
                      className="change-apt-link"
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        this.setState({
                          cancelRequestModalOpen: TextProps.VALUE_TRUE
                        })
                      }
                    >
                      {common.cancelConsumer}
                    </p>
                  )
                }
              </div>
            </div>
            <DTAModal
              isOpen={this.state.cancelRequestModalOpen}
              onClose={this.closeCancelModal}
              modalClass="message-modal"
              titleText={common.cancelRequestHeader}
              headerText={
                <h2 style={{ color: "black", padding: '0px 0px 0px 24px' }}>
                  {common.cancelRequestHeader}
                </h2>
              }
            >
              <div className="dta-modal__body pad-all--double">
                <form id="cancel-modal">
                  <div className="pure-u-1">
                    <label for="cancel">{common.reasonForCancel}</label>
                    {this.state.reasonSelectError && (
                      <div>
                        <div className="peat-case-error-msg">
                          <span className="dta-form__error-message">
                            {common.reasonError}
                          </span>
                        </div>
                      </div>
                    )}
                    <DTASelect
                      name={"cancel"}
                      options={common.cancelRequestOptions}
                      className="dta-units__control"
                      id="cancel"
                      // aria-labelledby={this.lastUniqueId()}
                      value={this.state.reasonSelect}
                      error={this.state.reasonSelectError}
                      onChange={this._selectHandler}
                    />
                  </div>
                  <div className="pure-u-1">
                    <label className="dta-form__label" for="comment">{
                      this.state.reasonSelect === consumerConst(selectedLanguage).commonOTHER ?
                        common.cmntForCancelRequired : common.cmntForCancel
                    }</label>
                    {this.state.cancelCommentError && (
                      <div>
                        <div className="peat-case-error-msg">
                          <span className="dta-form__error-message">
                            {common.cmntForCancelError}
                          </span>
                        </div>
                      </div>
                    )}
                    <DTATextArea
                      className="dta-form__control"
                      id="comment"
                      placeholder={common.cancelPlaceHolder}
                      disabled={TextProps.VALUE_FALSE}
                      maxLength={250}
                      value={this.state.cancelComment}
                      error={this.state.cancelCommentError}
                      onChange={this._commentHandler}
                    />
                  </div>
                </form>
                <div >
                  <div className="float-right secure" style={{ paddingBottom: "10px", marginBottom: '20px' }}>
                    <button className="dta-button dta-button--primary" style={{ backgroundColor: 'white', color: '#002a57' }} onClick={this.closeCancelModal}>{common.cancelModalbtn}</button>
                    <button className="dta-button dta-button--primary" onClick={this.SubmitValidate}>{common.cancelModalSubmitbtn}</button>
                  </div>
                </div>
              </div>
            </DTAModal>

            {this.renderCancelSubmitPopup()}
          </div>


          <DTAModal
            // ref={(instance) => (this.successMsgModal = instance)}
            isOpen={this.state.cancelRequestModalOpen}
            onClose={this.closeCancelModal}
            modalClass="message-modal"
            titleText={common.cancelRequestHeader}
            headerText={
              <h2 style={{ color: "black", padding: '0px 0px 0px 24px' }}>
                {common.cancelRequestHeader}
              </h2>
            }
          >
            <div className="dta-modal__body pad-all--double">
              <form id="cancel-modal">
                <div className="pure-u-1">
                  <label className="dta-form__label">{common.reasonForCancel}</label>
                  {this.state.reasonSelectError && (
                    <div>
                      <div className="peat-case-error-msg">
                        <span className="dta-form__error-message">
                          {common.reasonError}
                        </span>
                      </div>
                    </div>
                  )}
                  <DTASelect
                    name={"cancel"}
                    options={common.cancelRequestOptions}
                    className="dta-form__control dta-select--expand-width"
                    id="cancel"
                    // aria-labelledby={this.lastUniqueId()}
                    value={this.state.reasonSelect}
                    onChange={this._selectHandler}
                    error={this.state.reasonSelectError}
                  />
                </div>
                <div className="pure-u-1">
                  <label className="dta-form__label" for="cmntForCancel">{
                    this.state.reasonSelect === consumerConst(selectedLanguage).commonOTHER ?
                      common.cmntForCancelRequired : common.cmntForCancel
                  }</label>
                  {this.state.cancelCommentError && (
                    <div>
                      <div className="peat-case-error-msg">
                        <span className="dta-form__error-message">
                          {common.cmntForCancelError}
                        </span>
                      </div>
                    </div>
                  )}
                  <DTATextArea
                    className="dta-form__control"
                    id="cmntForCancel"
                    placeholder={common.cancelPlaceHolder}
                    disabled={TextProps.VALUE_FALSE}
                    required={TextProps.VALUE_TRUE}
                    maxLength={250}
                    value={this.state.cancelComment}
                    error={this.state.cancelCommentError}
                    onChange={this._commentHandler}
                  />
                </div>
              </form>
              <div >
                <div className="float-right secure" style={{ paddingBottom: "10px", marginBottom: '20px' }}>
                  <button className="dta-button dta-button--primary" style={{ backgroundColor: 'white', color: '#002a57', marginRight: '20px' }} onClick={this.closeCancelModal}>{common.cancelModalbtn}</button>
                  <button className="dta-button dta-button--primary" onClick={this.SubmitValidate}>{common.cancelModalSubmitbtn}</button>
                </div>
              </div>
            </div>
          </DTAModal>
          {this.renderCancelSubmitPopup()}
        </div>
      );
    }
  }

  handleListClickAction = (activityItemData) => {
    <AddToCalendar event={activityItemData} />;
  };

  _buildLink(activityItemData) {
    let items = [{ outlook: "Outlook" }, { google: "Google" }];
    return (
      <AddToCalendar
        event={activityItemData}
        listItems={items}
        buttonLabel={consumerConst(selectedLanguage).addToCalender}
      />
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSchedularPage: showSchedularPage,
      showSchedularSnapPage: showSchedularSnapPage,
      showSchedularCaseModelPage: showSchedularCaseModelPage,
      getAvailableDateAndTimes: getAvailableDateAndTimes,
      cancelAppoinmentPostCall: cancelAppoinmentPostCall
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(EventContents);