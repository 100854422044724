import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, NavLink, withRouter } from "react-router-dom";
import _ from "lodash";

import Icon from "./dtaIcon";
import LanguageSelect from "../../redux/language/languageSelect";
import DTADropdown from "./dtaDropdown";
import logo from "../resources/images/alabama-aces-logo.png";

import * as SharedProps from "../../utils/constants/shared";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { mapStateToProps } from "../../redux/rootReducer";
import { onLogOut, showBellIcon } from "../helper/accountNavigationActions";
import * as routeProps from "../../utils/constants/routesConstants";
import * as TextProps from "../../utils/constants/text";
import * as logos from "../../utils/constants/constants";
import {
  DEPT_OF_TRANS_ASS_LOCATIONS,
  SITE_POLICIES,
  alabama,
  langOptSel
} from "../constants/constants";

//azure
import { withMsal } from "@azure/msal-react";
import {
  onAzureLogOut,
  onAzureManageAccount,
  onAzureResetPassword,
} from "../helper/accountNavigationActions";
import * as config from "../../utils/constants/appConfigConstants";
import {
  screeningStaticLabels,
  copyrightLabels,
} from "../../pages/screening/screeningText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import DTAModal from "./modal/dtaModal";
import { addStringIf } from "./utilities/controls";
import textSpanish from "../appText/appSpanishText.json";
import textEnglish from "../appText/appEnglishText.json";
let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (selectedLanguage === null || selectedLanguage === langOptSel) {
    selectedLanguage = "en-US";
  }

/*
  DTANavigation
  ------

  # Purpose:
  To build ACES DHR Self-Service Portal account navigation header for Consumer and Provider account types.

  # Props:
  navItems: (Array) Required, array of navigation objects with the following shape:
    {
      [SharedProps.ID]:                   (String) Required, the unique ID for this
                                                   item (also used as url)
      [SharedProps.TITLE]:                (String) Required, the displayed title of
                                                   the item.
      [SharedProps.ICON]:                 (String) Required, the name of the icon
                                                   to use.
      [SharedProps.NUM_OF_NOTIFICATIONS]: (Integer) Optional, the number of notifications
                                                    related to this item.
    }
  activeItemId:          (String)   Required, the ID of the currently active section.
  skipToAnchor:          (String)   Required, the HTML ID selector of the content that
                                    is to be focused on if the user decides to skip
                                    the navigation menu.
  onNavChange:           (Function) Optional, called when navigation changes, passes
                                    the new active navigation item.
  provider:              (Boolean)  Optional, is this navigation being used in the
                                    Provider Account context? Defaults to false.
  providerViewingClient: (Boolean)  Optional, is this navigation being used in the
                                    Client Account context but the user is a
                                    provider. Defaults to false.

  # Example:
  <DTANavigation
    navItems={ this.state.navItems }
    activeItemId={ this.state.activeNavItemId }
    skipToAnchor="#content"
    onNavChange={ this.onNavigationChange }
    provider={ false }
    providerViewingClient={ false }
  />
*/

class DTANavigation extends Component {
  static propTypes = {
    navItems: PropTypes.array.isRequired,
    activeItemId: PropTypes.string.isRequired,
    provider: PropTypes.bool,
    providerViewingClient: PropTypes.bool,
    onNavChange: PropTypes.func,
    skipToAnchor: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.gotoConsumerSignup = this.gotoConsumerSignup.bind(this);
    this.state = {
      navMenuOpen: TextProps.VALUE_FALSE,
      navMenuVisible: TextProps.VALUE_FALSE,
      hideNavigation: false,
      helpVideoModalOpen: TextProps.VALUE_FALSE,
      selectedHelpValue: "",
    };
  }

  openNavMenu = () => {
    this.setState(
      {
        navMenuOpen: TextProps.VALUE_TRUE,
      },
      () => {
        // Need to set a timeout here so CSS knows to transition.
        // With no timeout, both classes are applied basically simultaneously,
        // and the transition is moot.
        setTimeout(
          () => this.setState({ navMenuVisible: TextProps.VALUE_TRUE }),
          50
        );
      }
    );
  };

  closeNavMenu = () => {
    this.setState({ navMenuVisible: TextProps.VALUE_FALSE });
  };

  // In order to set content of menu to "display: none;" CSS when closed,
  // but still show transition, a transition event listener is needed
  onNavTransitionEnd = (e) => {
    if (e.target.className.includes("dta-nav__menu")) {
      if (!this.state.navMenuVisible) {
        this.setState({ navMenuOpen: TextProps.VALUE_FALSE });
      }
    }
  };
  componentDidMount(){
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("userInfo")
    );
   if(consumerDataUserObj && consumerDataUserObj.agencyID  == 0  ) {
    this.setState({
      hideNavigation: true
    })
   }

  }
  getTotalNotifications = () => {
    let count = 0;
    this.props.navItems.forEach((item) => {
      if (item[SharedProps.NUM_OF_NOTIFICATIONS]) {
        count += item[SharedProps.NUM_OF_NOTIFICATIONS];
      }
    });

    return count;
  };



  renderNavItem = (item, index) => {
    let isShowBellIcon =  showBellIcon (this.props.consumer.notificationList)

    return (
      <li
        key={item[SharedProps.ID]}
        className={
          "list--sidenavigation" +
          (item[SharedProps.TYPE] === "mh-provider-nav"
            ? " mh-Provider-title"
            : "")
        }
      >
        <NavLink
          to={`/${item[SharedProps.ID]}`}
          activeClassName="is-active"
          onClick={this._handleNavChange}
          data-index={index}
          role="link"
          aria-label={item[SharedProps.ID]}
        >
         
            {item[SharedProps.NUM_OF_NOTIFICATIONS] ? (
              <span className="notification-bubble">
                {item[SharedProps.NUM_OF_NOTIFICATIONS]}{" "}
                <span className="sr-only">notifications</span>
              </span>
            ) : null}
          {this.props.providerViewingClient &&
          item[SharedProps.ID] === "myInfo" &&
          this.props.user.personName !== undefined
            ? this.props.user.personName[SharedProps.FIRST]
            : item[SharedProps.TITLE]}
      
        
        {(item[SharedProps.ID] === "documents" &&  isShowBellIcon === "N" && !this.props.consumer.noticesUpdateSuccess  ) &&
        <img style={{width:"20px", height: "15px",paddingLeft:"5px"}} src={logos.notification_icon} alt="alert"/> 
        }
        </NavLink>
      </li>
    );
  };

  openHelpVideoModal = () => {
    this.setState({helpVideoModalOpen : TextProps.VALUE_TRUE})
  }

  closeHelpVideoModal = () => {
    this.setState({helpVideoModalOpen : TextProps.VALUE_FALSE})
  }

  selectedHelpCategory = (e) => {
    this.setState({
      selectedHelpValue: e.target.value
    })
  }

  // Example Link = "https://www.youtube.com/embed/4JDu69Jy41Y?si=gGDfB_eSZPRuU2C6"
  returnHelpVideoLink = () => {
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[0]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[1]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[2]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[3]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[0]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[1]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[2]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[3]) {
      return ""
    }
  }


  render() {
    const { common } = this.props.language.security;
    const msalInstance = this.props.msalContext.instance;
    const {home} = this.props.language;
    if(this.state.selectedHelpValue === "") {
      this.setState({
        selectedHelpValue: home.helpVideoDropdown && home.helpVideoDropdown[0]
      })
    }

    return (

      <div>
      <nav
        className={`nav dta-nav ${this.state.navMenuOpen ? "nav-open" : ""} ${
          this.state.navMenuVisible ? "nav-visible" : ""
        } ${this.props.provider ? "dta-nav--provider" : "dta-nav--client"} `}
        onTransitionEnd={this.onNavTransitionEnd}
      >
        <div className="navbar">
          <div className="navbar__mobile-bar-content">
            <button
              className="dta-button dta-button--icon"
              onClick={this.openNavMenu}
            >
              <Icon name="menu" />
              {this.getTotalNotifications() > 0 ? (
                <span className="notification-bubble">
                  {this.getTotalNotifications()}
                </span>
              ) : null}
            </button>
            {/* Need to conditionally render entire block here so SVG image will update when active item changes */}
            {this.props.navItems.map((item) => {
              return this.props.activeItemId === item[SharedProps.ID] ? (
                <div
                  key={item[SharedProps.ID]}
                  className="navbar__current-page-display"
                >
                  <Icon name={item[SharedProps.ICON]} />
                  {item[SharedProps.TITLE]}
                </div>
              ) : null;
            })}
          </div>
          <div className="dta-nav__menu-underlay" onClick={this.closeNavMenu} />
          <div className="nav__menu dta-nav__menu">
            <div className="navbar__logo">
            <Link to={routeProps.HOME_PATH} className="navbar__logo" onClick={this.onHomeClick}>
                <img src={logo} alt="ACES DHR Self-Service Portal Home" />
              </Link>
            </div>
            <button
              className="dta-button dta-button--icon dta-nav__close"
              onClick={this.closeNavMenu}
            >
              <Icon name="close" />
              <div className="list--myinfo--button ">
              {this.state.hideNavigation ? 
                this.props.navItems
                .filter(item => !item.hidetoUnLinkedUser)
                .map((item, index) => {
                  return this.renderNavItem(item, index);
                })
                : 
                this.props.navItems
                .filter(item => (item.id !== 'myAccount'))
                .map((item, index) => {
                return this.renderNavItem(item, index);
            })
          }
            </div>
            </button>     
            <div className="dta-nav__options">
            <span className="lang-trans">{home.translatePageText}</span>
              <LanguageSelect />
              {this.props.providerViewingClient ? (
                <div className="provider-spacer" />
              ) : (
                <div className="dta-nav__options-account">
                  <div className="hidden-desktop">
                    <ul className="list--unstyled">
                      <li className="pad-all--half">
                        {!this.props.mhprovider ? (
                          <Link to="/myAccount" className="display-block">
                            {common.manageAcct}
                          </Link>
                        ) : (
                          ""
                        )}
                      </li>
                      {/* <li className="pad-all--half">
                        <Link
                          to="#"
                          className="display-block"
                          onClick={() =>
                            this.props.onAzureResetPassword(msalInstance)
                          }
                        >
                          {common.forgotPwd}
                        </Link>
                      </li> */}
                      <li className="pad-all--half">
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={() =>
                                this.props.onAzureLogOut(msalInstance)
                              }
                            >
                              {common.logout}
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={this._handleLogoutSession.bind(this)}
                            >
                              {common.logout}
                            </Link>
                          )}
                        </React.Fragment>
                      </li>
                    </ul>
                  </div>
                  <div className="nav__dropdown hidden-mobile">
                        <button
                          onClick={() => this.openHelpVideoModal()}
                          className="custom-help-btn dta-button dta-button--outline-secondary dta-button--medium"
                          style={{ width: "100%" }}
                        >
                          {home.helpBtntext}
                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                    {common.hi}&nbsp;
                    <DTADropdown
                      buttonText={this.props.user[SharedProps.FIRST_NAME]}
                      buttonClass="dta-button dta-button--link"
                      menuClass="list--bordered"
                      caret={TextProps.VALUE_TRUE}
                      caretClass="color-primary"
                    >
                      {!this.props.mhprovider ? (
                        <li className="pad-all--half">
                          <Link to="/myAccount" className="display-block">
                            {common.manageAcct}
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="pad-all--half border-top">
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={() =>
                                this.props.onAzureLogOut(msalInstance)
                              }
                            >
                              {common.logout}
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={this._handleLogoutSession.bind(this)}
                            >
                              {common.logout}
                            </Link>
                          )}
                        </React.Fragment>
                      </li>
                    </DTADropdown>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.providerViewingClient &&
        (this.props.activeItemId === "myInfo" ||
          this.props.activeItemId === "activity" ||
          this.props.activeItemId === "documents") ? (
          <button
            className="dta-button provider-return"
            onClick={this.gotoProvier}
          >
            {/* <a href="/provider/searchConsumer"> */}
            <span className="provider-return__text">
              {common.provider}
              <br />
              <Icon name="home" />
            </span>
            {/*  </a> */}
          </button>
        ) : null}
      </nav>
      <div>
      <div className="sidenav">
        
        <div className="list--myinfo">
        {this.state.hideNavigation ? 
          this.props.navItems
          .filter(item => !item.hidetoUnLinkedUser)
          .map((item, index) => {
            return this.renderNavItem(item, index);
          })
          : 
          this.props.navItems
          .filter(item => (item.id !== 'myAccount'))
          .map((item, index) => {
            return this.renderNavItem(item, index);
      })
    }
      </div>
        <div className="spacing">
      <div className="nav__footer">
            <div className="nav__footer-content">
              {window.location.pathname !== routeProps.GREETER_ROLE && (
                <p className="text--small">
                  {screeningStaticLabels(selectedLanguage).needHelpFeedback}
                  <br />
                  <a
                    href={DEPT_OF_TRANS_ASS_LOCATIONS}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dta-link"
                  >
                    {screeningStaticLabels(selectedLanguage).ContactUs}
                    <span className="sr-only">
                      {screeningStaticLabels(selectedLanguage).OpensNewTab}
                    </span>
                  </a>
                </p>
              )}
              {window.location.pathname !== routeProps.GREETER_ROLE && (
                <p className="text--small">
                  <a
                    href={SITE_POLICIES}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dta-link"
                  >
                    {screeningStaticLabels(selectedLanguage).sitePolicies}
                    <span className="sr-only">
                      {screeningStaticLabels(selectedLanguage).OpensNewTab}
                    </span>
                  </a>
                </p>
              )}
              <p className="text--small">
                {
                  /*   &copy; 2018 Commonwealth of Alabama */
                  copyrightLabels(selectedLanguage)
                }
              </p>
            </div>
            </div>
          </div> 
      </div>   
    </div>
        <DTAModal
          ref={(instance) => (this.accountConnectModal = instance)}
          isOpen={this.state.helpVideoModalOpen}
          onClose={this.closeHelpVideoModal}
          modalClass="account-connect-modal consumer-search-client custom_width"
          titleText={home.helpBtntext}
        >
          <div className="dta-modal__body pad-all">
          <h1 style={{position: "absolute", top:"-10px"}}>{home.helpBtntext}</h1>
            <span><b>{home.helpChooseCategory}</b></span>&nbsp;&nbsp;
            <select
              onChange={this.selectedHelpCategory}
              className={addStringIf(this.props.className, "language-select")}
              aria-label="Translate"
              value={selectedLanguage}
            >
              {home.helpVideoDropdown ? home.helpVideoDropdown.map((lang) => {
                return (
                  <option key={lang} value={lang}>
                    {lang}
                  </option>
                );
              }) : ""}
            </select>
            <br />
            <br />
            <iframe width="100%" height="480px" src={this.returnHelpVideoLink()} title={this.returnHelpVideoLink()}/>
          </div>
        </DTAModal>
    </div>
     
    );
  }

  gotoConsumerSignup() {
    this.props.onConsumerSignup();
  }

  gotoProvier = () => {
    this.props.gotoProvider();
  };

  _handleNavChange = (event) => {
    const { index } = event.target.dataset,
      item = this.props.navItems[index];
    this.closeNavMenu();
    _.attempt(this.props.onNavChange, item);
  };

  _handleLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onConsumerSignup: () => push(routeProps.CONSUMER_LOGIN_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      //onAzureManageAccount:onAzureManageAccount,
      onAzureResetPassword: onAzureResetPassword,
      gotoHome: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}
export default withMsal(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DTANavigation))
);
