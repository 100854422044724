import * as languageConstants from "../../utils/constants/constants";

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

// All the Label translations for Self-Declare Pages
export function selfDeclarePageLabels(language, pageType) {
  switch (pageType) {
    case languageConstants.selfDeclareExpenses: //1st section page
      {
        switch (language) {
          case EN:
            return {
              selfDeclareHeading: "Self-declare expenses",
              selfDeclareSummaryHeading: "Self-declare expenses summary",
              anyUtilQcost: [
                "Tell us if you have any of the costs below, even if you are not able to pay them. <b>You do not need to tell us about expenses we already have on record </b>. For more information, visit <a target='_blank' href='http://www.mass.gov/service-details/snap-verifications'>www.mass.gov/service-details/snap-verifications</a>",
                "Your own signed statement(self-declare) can verify certain expenses. We will let you know if we need more information",
                "Do you have any of these expenses?",
              ],
              houseUtlilityLabel: "Shelter costs",
              utilityCostSelf: "Utility costs",
              childTitle: "Dependent Care Costs",
              medicalTitle: "Medical Costs",
              childSupTitle: "Child Support Payments",
              supportCostHeader: "Child Support Costs",
              clearOptionSelfDeclareExpenses: "None of the Above",
              submitLabelFinal: "Self-declare expenses submitted!!!",
              medicalBoldTitle: "Medical costs",
              medicalSummarySenior:
                "Seniors(at least age 60) or clients with disabilities can claim medical expenses. Medical cost include co-pays, prescriptions, over-the-counter medications, health insurance, medical bills, transportation and more. To verify medical costs, send us proof of the cost such as a bill, invoice, or receipt. Transportation costs for medical reasons can be self-declared.",
              childSupportBoldTitle: "Child Support payments",
              childSummary:
                "To verify child support payments, send us verification of the legal obligation to pay the child support (such as a court order) and proof of recent payments.",
            };

          case ES:
            return {
              selfDeclareSummaryHeading:
                "Resumen de la autodeclaración de gastos",
              selfDeclareHeading: "Autodeclaración de gastos",
              anyUtilQcost: [
                "Infórmenos si tiene alguno de los costos que se indican a continuación, aunque no pueda pagarlos. <b>No necesita informarnos los gastos que ya tenemos registrados</b>. Para obtener más información, visite www.mass.gov/service-details/snap-verifications",
                "Su declaración (autodeclaración) firmada puede verificar ciertos gastos. Le informaremos si necesitamos más información.",
                "¿Tiene alguno de estos gastos?",
              ],
              houseUtlilityLabel: " Costos de refugio",
              utilityCostSelf: "Costos de servicios",
              childTitle: "Costos de cuidado de dependientes",
              medicalTitle: "Costos médicos",
              childSupTitle: "Pagos de manutención infantil",
              supportCostHeader: "Pagos de manutención infantil",
              clearOptionSelfDeclareExpenses: "Nada de lo anterior",
              submitLabelFinal: "Gastos de autodeclaración presentados!!!",
              medicalBoldTitle: "Costos médicos",
              medicalSummarySenior:
                "Los adultos mayores (al menos 60 años de edad) o los clientes con discapacidades pueden reclamar gastos médicos. Los costos médicos incluyen copagos, recetas, medicamentos de venta libre, seguro médico, facturas médicas, transporte y mucho más. Para verificar costos médicos, envíenos un comprobante del costo, como una factura o un recibo. Los costos de transporte por motivos médicos se pueden autodeclarar.",
              childSupportBoldTitle: "Pago de asistencia infantil",
              childSummary:
                "Para verificar los pagos de manutención infantil, envíenos una verificación de la obligación legal de pagar manutención infantil (como una orden judicial) y comprobantes de los pagos recientes.",
            };

          case PT:
            return {
              selfDeclareHeading: "Auto-declarar despesas",
              selfDeclareSummaryHeading:
                "Resumen de la autodeclaración de gastos",
              anyUtilQcost: [
                "Informe se você tem algum dos custos abaixo, mesmo que ainda não possa pagá-los. <b>Você não precisa nos informar sobre as despesas que já temos registradas</b>. Para mais informações, visite www.mass.gov/service-details/snap-verifications",
                "Sua própria declaração assinada (autodeclaração) pode verificar certas despesas. Vamos avisá-lo se precisarmos de mais informações",
                "Você tem alguma dessas despesas?",
              ],
              houseUtlilityLabel: "Custos de abrigo",
              utilityCostSelf: "Custos com serviços públicos ",
              childTitle: "Custos de cuidados dependentes",
              medicalTitle: "Custos médicos",
              childSupTitle: "Pagamentos de pensão alimentícia",
              supportCostHeader: "Pagamentos de pensão alimentícia",
              clearOptionSelfDeclareExpenses: "Nenhuma das opções acima",
              submitLabelFinal: "Despesas de autodeclaração enviadas!!!",
              medicalBoldTitle: "Custos médicos",
              medicalSummarySenior:
                "Idosos (a partir dos 60 anos) ou clientes com deficiências podem deduzir despesas médicas, como copagamentos, receitas, medicamentos básicos, seguro saúde, gastos clínicos, transporte e muito mais. Para verificar os custos médicos, envie-nos uma comprovação do custo, como nota, fatura ou recibo. Os custos de transporte por motivos médicos podem ser declarados automaticamente.",
              childSupportBoldTitle: "Pagamento de pensão alimentícia",
              childSummary:
                "Para verificar pagamentos de pensão alimentícia, envie-nos a verificação da obrigação legal de pagar a pensão alimentícia (como uma ordem judicial) e a comprovação de pagamentos recentes.",
            };

          case ZH:
            return {
              selfDeclareSummaryHeading: "自我申报费用汇总",
              selfDeclareHeading: "自行声明的费用",
              anyUtilQcost: [
                "如果您有下列任一费用，请告知我们，<b>即使您无法完成付款也须告知我们。您无需将我们已经记录在案的费用告知我们。</b> 如需了解更多信息，请访问 www.mass.gov/service-details/snap-verifications",
                "您自行签署的声明（自我声明）可以验证某些费用。如果我们需要更多信息，我们会告知您。",
                "您是否有任何此类费用？",
              ],
              houseUtlilityLabel: "住房费用",
              utilityCostSelf: "公用设施费用 ",
              childTitle: "亲属护理费用",
              medicalTitle: "医疗费用",
              childSupTitle: "子女撫養費",
              supportCostHeader: "子女抚养费",
              clearOptionSelfDeclareExpenses: "以上都不是",
              submitLabelFinal: "提交的自我申报费用!!!",
              medicalBoldTitle: "的医疗费",
              medicalSummarySenior:
                "老年人（60 岁以上）或残障客户可申请报销医疗费用。医疗费用包括定额手续费、处方药、医疗保险、医疗账单、交通，等等。 如需验证医疗费用，请向我们寄送费用证据，例如账单、发票或收据。出于医疗原因的交通费用可自行声明。",
              childSupportBoldTitle: "子女抚养费付款",
              childSummary:
                "如需验证子女抚养费的付款情况，请向我们寄送支付子女抚养费之合法义务的验证文件（例如法院命令）以及近期付款的证据。",
            };

          case VI:
            return {
              selfDeclareSummaryHeading: "Tóm tắt phần tự kê khai chi phí",
              selfDeclareHeading: "Chi phí tự kê khai",
              anyUtilQcost: [
                "Hãy cho chúng tôi nếu  quý vị có bất kỳ chi phí nào dưới đây, ngay cả khi quý vị không thể thanh toán chúng. <b>Quý vị không cần cho chúng tôi biết về các chi phí chúng tôi đã có trong hồ sơ</b>. Để biết thêm thông tin, hãy truy cập www.mass.gov/service-details/snap-verifications",
                "Báo cáo đã ký của chính quý vị  (tự khai) có thể xác minh các chi phí nhất định. Chúng tôi sẽ cho quý vị biết nếu chúng tôi cần thêm thông tin",
                "Quý vị có bất kỳ chi phí nào trong các chi phí này không?",
              ],
              houseUtlilityLabel: "Chi phí nơi ở",
              utilityCostSelf: "Chi phí tiện ích",
              childTitle: "Chi phí chăm sóc người phụ thuộc",
              medicalTitle: "Chi phí Y tế",
              childSupTitle: "Thanh toán hỗ trợ trẻ em",
              supportCostHeader: "Các khoản thanh toán trợ cấp nuôi con",
              clearOptionSelfDeclareExpenses: "Không có điều gì bên trên",
              submitLabelFinal: "Chi phí tự khai!!!",
              medicalBoldTitle: "Các chi phí y tế đối với",
              medicalSummarySenior:
                "Người cao niên (ít nhất 60 tuổi) hoặc thân chủ khuyết tật được phép khai chi phí y tế. Chi phí y tế gồm các khoản tiền đồng thanh toán, thuốc theo toa, thuốc không cần toa, lệ phí bảo hiểm y tế, hóa đơn chi phí y tế, chuyên chở và những chi phí khác. Để xác minh chi phí y tế, hãy gửi cho chúng tôi bằng chứng về chi phí như hóa đơn hoặc biên lai. Chi phí vận chuyển vì lý do y tế có thể được tự kê khai.",
              childSupportBoldTitle: "Thanh toán trợ cấp nuôi con",
              childSummary:
                "Để xác minh các khoản thanh toán khoản trợ cấp nuôi con, hãy gửi cho chúng tôi xác minh nghĩa vụ pháp lý phải trả khoản trợ cấp nuôi con (như lệnh của tòa án) và bằng chứng về các khoản thanh toán gần đây.",
            };
          case HI:
            return {
              selfDeclareHeading: "Depans ou deklare oumenm ",
              selfDeclareSummaryHeading: "Rezime depans ou deklare oumenm",
              anyUtilQcost: [
                "Di nou si ou gen nenpòt nan depans ki anba yo, menm si ou pa ka peye yo. <b>Ou pa bezwen di nou konsènan depans nou deja genyen nan fichye </b>. Pou plis enfòmasyon, vizite <a target='_blank' href='http://www.mass.gov/service-details/snap-verifications'>www.mass.gov/service-details/snap-verifications</a>",
                "Pwòp deklarasyon ou siyen (ou deklare oumenm) ka verifye sèten depans. Nou pral fè w konnen si nou bezwen plis enfòmasyon",
                "Èske w gen nenpòt nan depans sa yo?",
              ],
              houseUtlilityLabel: "Depans pou Lojman",
              utilityCostSelf: "Depans pou sèvis piblik",
              childTitle: "Depans pou swen depandan ",
              medicalTitle: "Depans Medikal",
              childSupTitle: "Peman pou Sipò Timoun",
              supportCostHeader: "Depans pou Sipò Timoun",
              clearOptionSelfDeclareExpenses: "Okenn nan sa ki anlè yo",
              submitLabelFinal: "Depans yo ou deklare oumenm te soumèt!!!",
              medicalBoldTitle: "Depans medikal",
              medicalSummarySenior:
                "Granmoun aje yo (omwen laj 60 an) oswa kliyan ki gen andikap yo ka reklame depans medikal. Depans medikal yo enkli ko-peman, preskripsyon, medikaman san preskripsyon, asirans sante, bòdwo medikal, transpò ak plis ankò. Pou verifye depans medikal yo, voye ban nou prèv depans lan tankou yon bòdwo, fakti, oswa resi. Depans pou transpò pou rezon medikal yo ou ka deklare yo oumenm.",
              childSupportBoldTitle: "Peman pou Sipò Timoun",
              childSummary:
                "Pou verifye peman pou sipò timoun, voye verifikasyon obligasyon legal pou peye sipò timoun nan (tankou yon òdonans tribinal) ak prèv peman resan yo.",
            };
        }
      }
      break;
    case languageConstants.utilityCosts: //1st section's subsection page 1
      {
        switch (language) {
          case EN:
            return {
              utilityCostSelf: "Utility costs",
              expenseSelfHeader: "Tell us about your utility costs",
              utilitiesClearOption: "I do not pay for any utility costs",
              expenseSubHeader:
                "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
              utilityQOptions: [
                "Heat (oil, gas, electricity or propane, etc.)",
                "Electricity for an air conditioner",
                "A fee to use an air conditioner",
                "Electricity and/or gas (other than heating use)",
                "Phone or cell phone service",
              ],
              houseQLabel:
                "What type(s) of shelter costs does your household has?",
            };

          case ES:
            return {
              utilityCostSelf: "Costos de servicios",
              houseQLabel:
                "¿Qué tipo(s) de costos de refugio tiene su grupo familiar?",
              expenseSelfHeader: "Infórmenos sobre los costos de los servicios",
              utilityQOptions: [
                "Calefacción (nafta, gas, electricidad o propano, etc.)",
                "Electricidad para un aire acondicionado",
                "Cargo por usar un aire acondicionado ",
                "Electricidad y/o gas (aparte del uso de calefacción)",
                "Servicio de teléfono o celular",
              ],
              utilitiesClearOption: "No pago servicios",
              expenseSubHeader:
                "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
            };

          case PT:
            return {
              utilityCostSelf: "Custos com serviços públicos ",
              expenseSelfHeader:
                "Conte-nos sobre seus gastos com serviços públicos",
              utilityQOptions: [
                "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
                "Eletricidade para ar condicionado",
                "Uma taxa para usar um ar condicionado",
                "Eletricidade e/ou gás (que não seja aquecimento)",
                "Serviço de telefone fixo ou celular",
              ],
              utilitiesClearOption: "Eu não pago por serviços públicos",
              houseQLabel: "Que tipo(s) de custo de abrigo tem seu domicílio?",
              expenseSubHeader:
                "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            };

          case ZH:
            return {
              utilityCostSelf: "公用设施费用 ",
              expenseSelfHeader: "告诉我们您的公用设施费用",
              utilityQOptions: [
                "取暖费（石油，天然气，电力或丙烷等）",
                "空调用电费",
                "空调使用费",
                "电费和/或燃气费 (除了供熱)",
                "电话费或手机费",
              ],
              utilitiesClearOption: "我不支付任何公用设施费用",
              houseQLabel: "您的家庭有哪一类的住房费用？",
              expenseSubHeader:
                "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。",
            };

          case VI:
            return {
              utilityCostSelf: "Chi phí tiện ích",
              expenseSelfHeader:
                "Hãy cho chúng tôi biết về chi phí tiện ích của bạn",
              utilityQOptions: [
                "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
                "Điện cho máy điều hòa không khí",
                "Một khoản phí để sử dụng điều hòa không khí",
                "Điện và / hoặc khí đốt (ngoài việc sử dụng sưởi ấm)",
                "Dịch vụ điện thoại hoặc điện thoại di động",
              ],
              utilitiesClearOption: "Tôi không trả bất kỳ chi phí tiện ích nào",
              houseQLabel:
                "(Những) loại chi phí nhà cửa mà hộ gia đình của quý vị có?",
              expenseSubHeader:
                "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
            };
          case HI:
            return {
              utilityCostSelf: "Depans pou sèvis piblik",
              expenseSelfHeader:
                "Pale nou konsènan depans ou yo pou sèvis piblik",
              utilitiesClearOption:
                "Mwen pa peye okenn depans pou sèvis piblik",
              expenseSubHeader:
                "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo. ",
              utilityQOptions: [
                "Chofaj (luil, gaz, elektrisite oswa pwopàn, elatriye)",
                "Elektrisite pou yon klimatizè",
                "Yon frè pou itilize yon klimatizè",
                "Elektrisite ak/oswa gaz (ki itilize pou chofaj)",
                "Telefòn oswa sèvis telefòn selilè",
              ],
              houseQLabel: "Ki tip(yo) depans pou lojman fwaye w la genyen?",
            };
        }
      }
      break;

    case languageConstants.shelterCosts: //1st section's subsection page 2
      {
        switch (language) {
          case EN:
            return {
              expenseHeader: "Tell us about your shelter costs",
              expenseSubHeader:
                "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
              shelterClearOption: "I do not pay for any shelter costs",
              houseQOptions: [
                "Rent",
                "Mortgage",
                "Property taxes",
                "Home insurance",
                "Condo fees",
              ],
            };

          case ES:
            return {
              expenseHeader: "Indíquenos sus costos de refugio",
              expenseSubHeader:
                "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
              shelterClearOption: "No pago costos de refugio",
              houseQOptions: [
                "Alquiler",
                "Hipoteca",
                "Impuestos a la propiedad",
                "Seguro de la casa",
                "Tasa de condominio",
              ],
            };

          case PT:
            return {
              expenseHeader: "Informe sobre seus custos de abrigo",
              expenseSubHeader:
                "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
              shelterClearOption: "Eu não pago por nenhum custo de abrigo",
              houseQOptions: [
                "Aluguel",
                "Financiamento",
                "Impostos da propriedade",
                "Seguro residencial",
                "Condomínio",
              ],
            };

          case ZH:
            return {
              expenseHeader: "告诉我们关于您的住房费用的情况",
              shelterClearOption: "我不支付任何住房费用",
              expenseSubHeader:
                "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。",
              houseQOptions: [
                "房租",
                "抵押贷款",
                "财产税",
                "家庭保险",
                "物业管理费用",
              ],
            };

          case VI:
            return {
              expenseHeader: "Hãy cho chúng tôi về chi phí nơi ở của quý vị",
              expenseSubHeader:
                "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
              shelterClearOption:
                "Tôi không trả cho bất kỳ chi phí nhà cửa nào",
              houseQOptions: [
                "Thuê",
                "Thế chấp",
                "Thuế bất động sản",
                "Bảo hiểm nhà ở",
                "Chi phí công quản",
              ],
            };
          case HI:
            return {
              expenseHeader: "Pale nou konsènan depans ou yo pou abri",
              expenseSubHeader:
                "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo. ",
              shelterClearOption: "Mwen pa peye depans pou abri",
              houseQOptions: [
                "Lwaye",
                "Prè ipotèk",
                "Taks sou pwopriyete",
                "Asirans Kay",
                "Frè kondo",
              ],
            };
        }
      }
      break;

    case languageConstants.medicalCosts: //1st section's subsection page 3
      {
        switch (language) {
          case EN:
            return {
              childSupTitle: "Child Support Payments",
              // supportCostHeader:"Child Support Costs",
              childSupportQlegalbefore:
                "Does anyone in the household have a legal obligation (court order) to pay ",
              childSupportQlegaltrigger: "child support",
              childSupportQlegalAfter: " to a child not living with you?",
              childSupportHelp:
                "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
              medicalTitle: "Medical Costs",
              medicalInlineSeethisFull: "See this full list of",
              medicalInlineEligibleMedical: " eligible medical expenses",
              medicalHeader: "Tell us about your medical costs",
              medicalSubHeader:
                "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
              medicalExpBefore:
                "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
              medicalExpTrigger: " transportation",
              medicalExpAfter:
                ", over the counter medications, dental or eye care, adult diapers, etc.?",
              medicalExphelp:
                "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
              medicalInhelp: "See this full list of",
              medicalInhelpEligible: "eligible medical expenses",
              medicalAddress:
                "What is the address of the medical appointment or pharmacy?",
              zipErrmsg: "Please enter a valid Zipcode.",
            };

          case ES:
            return {
              childSupTitle: "Pagos de manutención infantil",
              childSupportQlegalbefore:
                "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
              childSupportQlegaltrigger: "manutención a un niño",
              childSupportQlegalAfter: " que non viva con usted?",
              childSupportHelp:
                "La manutención pagada puede contarse como un gasto cuando se exige legalmente y los pagos se están haciendo. Esto incluye pagos por mora",
              medicalTitle: "Costos médicos",
              medicalInlineSeethisFull: "Consulte la lista completa de",
              medicalInlineEligibleMedical: " gastos médicos elegibles.",
              medicalHeader: "Díganos sobre sus costos médicos",
              medicalSubHeader:
                "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos. ",
              medicalExpBefore:
                "¿Alguna de las personas enumeradas a continuación paga seguro médico u otros costos médicos, incluso medicamentos con receta,",
              medicalExpTrigger: "transporte",
              medicalExpAfter:
                ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
              medicalExphelp:
                "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
              medicalInhelp: "Consulte la lista completa de",
              medicalInhelpEligible: " gastos médicos elegibles",
              medicalAddress:
                "¿Cuál es la dirección de la cita médica o farmacia?",
              zipErrmsg: "Por favor ingrese un código postal válido.",
            };

          case PT:
            return {
              childSupTitle: "Pagamentos de pensão alimentícia",
              childSupportQlegalbefore:
                "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar pelo ",
              childSupportQlegaltrigger: " suporte de uma criança",
              childSupportQlegalAfter: " que não vive com você?",
              childSupportHelp:
                "O pagamento de suporte infantil pode ser contado como uma despesa quando for exigido legalmente e quando os pagamentos estiverem sendo feitos. Incluindo pagamentos em atraso.",
              medicalTitle: "Custos médicos",
              medicalInlineSeethisFull: "Veja esta lista completa de",
              medicalInlineEligibleMedical: " despesas médicas elegíveis.",
              medicalHeader: "Fale sobre os seus custos médicos",
              medicalSubHeader:
                "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
              medicalExpBefore:
                "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
              medicalExpTrigger: "transporte",
              medicalExpAfter:
                ", medicamentos de farmácia, dentistas, oftalmologistas, fraldas geriátricas, etc?",
              medicalExphelp:
                "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
              medicalInhelp: "Veja esta lista completa de",
              medicalInhelpEligible: "despesas médicas elegíveis",
              medicalAddress:
                "Qual é o endereço da consulta médica ou da farmácia?",
              zipErrmsg: "Por favor insira um código postal válido",
            };

          case ZH:
            return {
              childSupTitle: "子女撫養費",
              supportCostHeader: "子女抚养费",
              childSupportQlegalbefore:
                "您家中是否有人必须向不与您同住的子女支付法定 ",
              childSupportQlegaltrigger: "子女抚养费",
              childSupportQlegalAfter: " ?",
              childSupportHelp:
                "在法定情况下以及正在支付的情况下，子女抚养费可以算作一项开支。这包括拖欠款项",
              medicalTitle: "医疗费用",
              medicalInlineSeethisFull: "参阅此的完整列表。",
              medicalInlineEligibleMedical: "合格的医疗费用",
              medicalHeader: "请告诉我们您的医疗费",
              medicalSubHeader:
                "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。",
              medicalExpBefore:
                "下列任何人是否支付健康保险或其他医疗费用，包括门诊，",
              medicalExpTrigger: "交通",
              medicalExpAfter: ", 非处方药，牙科或眼睛护理，成人尿片等？",
              medicalExphelp:
                "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
              medicalInhelp: "请参阅此完整列表",
              medicalInhelpEligible: "合格医疗开支的完整清单。",
              medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
              medicalAddress: "医疗机构或药房地址？",
              zipErrmsg: "請輸入有效的郵政編碼",
            };

          case VI:
            return {
              childSupTitle: "Thanh toán hỗ trợ trẻ em",
              supportCostHeader: "Các khoản thanh toán trợ cấp nuôi con",
              childSupportQlegalbefore:
                "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
              childSupportQlegaltrigger: "trợ cấp nuôi con",
              childSupportQlegalAfter:
                " cho một đứa trẻ không sống chung với quý vị?",
              childSupportHelp:
                "Khoản tiền trợ cấp nuôi con được thanh toán có thể được tính là một khoản chi phí khi khoản này được bắt buộc một cách hợp pháp và các khoản thanh toán đang được thực hiện. Khoản này bao gồm các khoản thanh toán cho các khoản nợ",
              medicalTitle: "Chi phí Y tế",
              medicalInlineSeethisFull: "Xem danh sách đầy đủ các",
              medicalInlineEligibleMedical: " chi phí y tế đủ điều kiện.",
              medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
              medicalSubHeader:
                "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
              medicalExpBefore:
                "Có ai được liệt kê dưới đây chi trả tiền bảo hiểm y tế hay các chi phí y tế khác bao gồmtoa thuốc,",
              medicalExpTrigger: " chuyên chở",
              medicalExpAfter:
                ", thuốc không cần toa, chăm sóc răng hoặc mắt, tã người lớn, v.v.?",
              medicalExphelp:
                "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
              medicalInhelp: "Xem danh sách đầy đủ các",
              medicalInhelpEligible: " chi phí y tế hợp lệ",
              medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
              zipErrmsg: "Please enter a valid Zipcode.",
            };
          case HI:
            return {
              childSupTitle: "Peman pou Sipò Timoun ",
              // supportCostHeader:"Child Support Costs",
              childSupportQlegalbefore:
                "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
              childSupportQlegaltrigger: "sipò timoun",
              childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
              childSupportHelp:
                "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
              medicalTitle: "Depans medikal",
              medicalInlineSeethisFull: "Gade lis konplè sa a",
              medicalInlineEligibleMedical: " depans medikal ki kalifye",
              medicalHeader: "Pale nou konsènan depans medikal ou yo",
              medicalSubHeader:
                "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
              medicalExpBefore:
                "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
              medicalExpTrigger: " transpò",
              medicalExpAfter:
                ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
              medicalExphelp:
                "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
              medicalInhelp: "Gade lis konplè sa a",
              medicalInhelpEligible: " depans medikal ki kalifye",
              medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
              zipErrmsg: "Tanpri antre yon kòd postal ki valid",
            };
        }
      }
      break;

    case languageConstants.selfDeclareExpensesSummary: { //2nd section page
      switch (language) {
        case EN:
          return {
            expenseSummaryLabel: "Expenses summary",
            expenseShelterSubHeader:
              "Please include Expenses that you are responsible for paying even if you are behind or not able to pay them.",
            unitBetween: "per",
            houseUnitOptions: ["Week", "Month", "Year"],
            mortageQLabel: "How much is your household's mortgage?",
            mortageunitBetween: " ",
            mortageQoptions: ["Week", "Month", "Year"],
            mortageProptaxLabel: "How much is your household's property taxes?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Week", "Month", "Year"],
            insuranceCostQLabel:
              "How much is your household's home insurance costs?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Week", "Month", "Year"],
            insuranceClearOption: "No. I don't need to pay for this.",
            condofeeQLabel: "How much is your household's condo fee?",
            condofeepropBetween: "per",
            condofeeCostoptions: ["Week", "Month", "Year"],
            condofeeClearOption: "No. I don't need to pay for this.",
            propTaxQLabel: "Does your household have property taxes?",
            propTaxBetween: "per",
            propTaxOptions: ["Week", "Month", "Year"],
            propTaxClearOption: "No. I don't need to pay for this.",
            homeInsuranceQLabel:
              "Does your household have home insurance costs?",
            homeInsuranceBetween: "per",
            homeInsuranceUnits: ["Week", "Month", "Year"],
            homeInsuranceClearOption: "No. I don't need to pay for this.",

            //new labels:
            anyUtilQcost: "Does your household have any utility costs?",
            chooseHoldErrmsg: "Please choose household utilities",
            houseCostErrmsg: "Please choose housing costs",
            rentErrMsg: "Please enter rent",
            anotherRentLabel: "+ Add another rent",
            mortageErrLabel: "Please enter mortgage",
            propTaxLabel: "Please enter property taxes",
            propTaxButtonLabel: "+ Add another property taxes",
            homeInsurbutton: "+ Add another home insurance",
            homeInsurErrmsg: "Please enter home insurance",
            condoButtonLabel: "+ Add another condo fee",
            condoFeeErrmsg: "Please enter condo fee",
            addMortageButtonLabel: "+ Add another mortgage",
          };

        case ES:
          return {
            expenseSummaryLabel: "Resumen de gastos",
            expenseShelterSubHeader:
              "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
            unitBetween: "por",
            houseUnitOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            mortageQLabel: "¿Cuánto es el hipoteca de su grupo familiar?",
            mortageunitBetween: " ",
            mortageQoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            mortageProptaxLabel:
              "¿Cuánto es el impuestos a la propiedad de su grupo familiar?",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            insuranceCostQLabel:
              "¿Cuánto es el seguro de la casa de su grupo familiar?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            insuranceClearOption: "No. No necesito pagar por esto.",
            condofeeQLabel:
              "¿Cuánto es el tasa de condominio de su grupo familiar?",
            condofeepropBetween: "por",
            condofeeCostoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            condofeeClearOption: "No. No necesito pagar por esto.",
            propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
            propTaxBetween: "por",
            propTaxOptions: ["Semana", "Mes", "Año"],
            propTaxClearOption: "No. No necesito pagar por esto.",
            homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
            homeInsuranceBetween: "por",
            homeInsuranceUnits: ["Semana", "Mes", "Año"],
            homeInsuranceClearOption: "No. No necesito pagar por esto.",
            condoFeeQLabel: "¿Su hogar tiene una tarifa de condominio?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semana", "Mes", "Año"],
            condoFeeClearOption: "No. No necesito pagar por esto.",

            //new labels:
            anyUtilQcost: [
              "Infórmenos si tiene alguno de los costos que se indican a continuación, aunque no pueda pagarlos. No necesita informarnos los gastos que ya tenemos registrados. Para obtener más información, visite www.mass.gov/service-details/snap-verifications",
              "Su declaración (autodeclaración) firmada puede verificar ciertos gastos. Le informaremos si necesitamos más información.",
              "¿Tiene alguno de estos gastos?",
            ],
            chooseHoldErrmsg: "Por favor elija servicios domésticos",
            houseCostErrmsg: "Por favor elija los costos de la vivienda",
            rentErrMsg: "Por favor ingrese alquiler",
            anotherRentLabel: "+ Agregar otro Alquiler",
            mortageErrLabel: "Por favor ingrese la hipoteca",
            propTaxLabel: "Ingrese los impuestos a la propiedad",
            propTaxButtonLabel: "+ Agregar otro impuestos a la propiedad",
            homeInsurbutton: "+ Agregar otro Seguro de la casa",
            homeInsurErrmsg: "Por favor ingrese Seguro de la casa",
            condoButtonLabel: "+ Agregar otro tasa de condominio",
            condoFeeErrmsg: "Por favor ingrese el costo",
            addMortageButtonLabel: "+ Agregar otro hipoteca",
          };

        case PT:
          return {
            expenseSummaryLabel: "Sumário das despesas",
            expenseShelterSubHeader:
              "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
            unitBetween: "por",
            houseUnitOptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            mortageQLabel: "Quanto custa o Financiamento do seu domicílio ?",
            mortageunitBetween: " ",
            mortageQoptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            mortageProptaxLabel:
              "Quanto custa o Impostos da propriedade do seu domicílio?",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            insuranceCostQLabel:
              "Quanto custa o seguro residencial do seu domicílio?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            insuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condofeeQLabel: "Quanto custa o condomínio do seu domicílio?",
            condofeepropBetween: "por",
            condofeeCostoptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            condofeeClearOption: "Não. Eu não preciso pagar por isso.",
            propTaxQLabel: "A sua casa tem impostos sobre a propriedade?",
            propTaxBetween: "por",
            propTaxOptions: ["Semana", "Mês", "Ano"],
            propTaxClearOption: "Não. Eu não preciso pagar por isso.",
            homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
            homeInsuranceBetween: "por",
            homeInsuranceUnits: ["Semana", "Mês", "Ano"],
            homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condoFeeQLabel: "A sua casa possui uma condominio?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semana", "Mês", "Ano"],
            condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

            //new labels:
            anyUtilQcost: [
              "Informe se você tem algum dos custos abaixo, mesmo que ainda não possa pagá-los. Você não precisa nos informar sobre as despesas que já temos registradas. Para mais informações, visite www.mass.gov/service-details/snap-verifications",
              "Sua própria declaração assinada (autodeclaração) pode verificar certas despesas. Vamos avisá-lo se precisarmos de mais informações",
              "Você tem alguma dessas despesas?",
            ],
            chooseHoldErrmsg: "Escolha utilitários domésticos",
            houseCostErrmsg: "Escolha os custos de habitação",
            rentErrMsg: "Entre com aluguel",
            anotherRentLabel: "+ Adicionar outra aluguel",
            mortageErrLabel: "Insira uma hipoteca",
            propTaxLabel: "Digite os impostos sobre a propriedade",
            propTaxButtonLabel: "+ Adicionar outra Impostos da propriedade",
            homeInsurbutton: "+ Adicionar outra Seguro residencial",
            homeInsurErrmsg: "Digite o seguro de casa",
            condoButtonLabel: "+ Adicionar outra Condomínio",
            condoFeeErrmsg: "Entre com condominio",
            addMortageButtonLabel: "+ Adicionar outra Financiamento",
          };

        case ZH:
          return {
            expenseSummaryLabel: "费用摘要",
            expenseShelterSubHeader:
              "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
            unitBetween: "每",
            houseUnitOptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            mortageQLabel: "您家的房贷有多少？",
            mortageunitBetween: " ",
            mortageQoptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            mortageProptaxLabel: "您的家庭的财产税是多少？",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            insuranceCostQLabel: "您的家庭的家庭保险是多少？",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            insuranceClearOption: "不，我不需要为此付费。",
            condofeeQLabel: "您的家庭的物业管理费用是多少？",
            condofeepropBetween: "每",
            condofeeCostoptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            condofeeClearOption: "不，我不需要为此付费。",
            propTaxQLabel: "你家有財產稅嗎？",
            propTaxBetween: "每",
            propTaxOptions: ["周", "月", "年"],
            propTaxClearOption: "不，我不需要为此付费。",
            homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
            homeInsuranceBetween: "每",
            homeInsuranceUnits: ["周", "月", "年"],
            homeInsuranceClearOption: "不，我不需要为此付费。",
            condoFeeQLabel: "你家有公寓費嗎？",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["周", "月", "年"],
            condoFeeClearOption: "不，我不需要为此付费。",

            //new labels:
            anyUtilQcost: [
              "如果您有下列任一费用，请告知我们，即使您无法完成付款也须告知我们。您无需将我们已经记录在案的费用告知我们。如需了解更多信息，请访问 www.mass.gov/service-details/snap-verifications",
              "您自行签署的声明（自我声明）可以验证某些费用。如果我们需要更多信息，我们会告知您。",
              "您是否有任何此类费用？",
            ],
            chooseHoldErrmsg: "请选择家用电器",
            houseCostErrmsg: "请选择住房费用",
            rentErrMsg: "请输入租金",
            anotherRentLabel: "+ 添加另一项房租",
            mortageErrLabel: "请输入抵押",
            propTaxLabel: "请输入财产税",
            propTaxButtonLabel: "+ 添加另一项财产税",
            homeInsurbutton: "+ 添加另一项家庭保险",
            homeInsurErrmsg: "请输入家庭保险",
            condoButtonLabel: "+ 添加另一项物业管理费用",
            condoFeeErrmsg: "请输入公寓费用",
            addMortageButtonLabel: "+ 添加另一项抵押贷款",
          };

        case VI:
          return {
            expenseSummaryLabel: "Tóm tắt tất cả các chi phí",
            expenseShelterSubHeader:
              "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
            unitBetween: "theo",
            houseUnitOptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            mortageQLabel: "Thế chấp của gia đình quý vị là bao nhiêu?",
            mortageunitBetween: " ",
            mortageQoptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            mortageProptaxLabel:
              "Thuế bất động sản của gia đình quý vị là bao nhiêu?",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            insuranceCostQLabel:
              "Bảo hiểm nhà ở của gia đình quý vị là bao nhiêu?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
            condofeeQLabel:
              "Chi phí công quản của gia đình quý vị là bao nhiêu?",
            condofeepropBetween: "theo",
            condofeeCostoptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
            propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
            propTaxBetween: "theo",
            propTaxOptions: ["Tuần", "Tháng", "Năm"],
            propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
            homeInsuranceQLabel:
              "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
            homeInsuranceBetween: "theo",
            homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
            homeInsuranceClearOption:
              "Không, tôi không cần trả tiền cho việc này.",
            condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Tuần", "Tháng", "Năm"],
            condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

            //new labels:
            anyUtilQcost: [
              "Hãy cho chúng tôi nếu  quý vị có bất kỳ chi phí nào dưới đây, ngay cả khi quý vị không thể thanh toán chúng. Quý vị không cần cho chúng tôi biết về các chi phí chúng tôi đã có trong hồ sơ. Để biết thêm thông tin, hãy truy cập www.mass.gov/service-details/snap-verifications",
              "Báo cáo đã ký của chính quý vị  (tự khai) có thể xác minh các chi phí nhất định. Chúng tôi sẽ cho quý vị biết nếu chúng tôi cần thêm thông tin",
              "Quý vị có bất kỳ chi phí nào trong các chi phí này không?",
            ],
            chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
            houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
            rentErrMsg: "Hãy nhập tiền thuê",
            anotherRentLabel: "+ Bổ sung Thuê khác",
            mortageErrLabel: "Hãy nhập khoản thế chấp",
            propTaxLabel: "Vui lòng nhập thuế bất động sản",
            propTaxButtonLabel: "+ Bổ sung thuế bất động sản khác",
            homeInsurbutton: "+ Bổ sung Bảo hiểm nhà ở khác",
            homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
            condoButtonLabel: "+ Bổ sung Chi phí công quản khác",
            condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
            addMortageButtonLabel: "+ Bổ sung thế chấp khác",
          };
        case HI:
          return {
            expenseSummaryLabel: "Rezime Depans pou",
            expenseShelterSubHeader:
              "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
            unitBetween: "pa",
            houseUnitOptions: ["Semèn", "Mwa", "Year"],
            mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semèn", "Mwa", "Ane"],
            mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semèn", "Mwa", "Ane"],
            insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semèn", "Mwa", "Ane"],
            insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
            condofeepropBetween: "pa",
            condofeeCostoptions: ["Semèn", "Mwa", "Ane"],
            condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
            propTaxBetween: "pa",
            propTaxOptions: ["Semèn", "Mwa", "Ane"],
            propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
            homeInsuranceBetween: "pa",
            homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
            homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",

            //new labels:
            anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
            chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
            houseCostErrmsg: "Tanpri chwazi depans pou lojman",
            rentErrMsg: "Tanpri antre lwaye",
            anotherRentLabel: "+ Ajoute yon lòt lwaye",
            mortageErrLabel: "Tanpri antre prè ipotèk",
            propTaxLabel: "Tanpri antre taks sou pwopriyete",
            propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
            homeInsurbutton: "+ Ajoute yon lòt asirans kay",
            homeInsurErrmsg: "Tanpri antre asirans kay",
            condoButtonLabel: "+ Ajoute yon lòt frè kondo",
            condoFeeErrmsg: "Tanpri antre frè kondo",
            addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
          };
      }
    }
  }
}

export function getFrequencyCodeSelfDeclare(language) {
  switch (language) {
    case EN:
      return {
        Year: "ANNUAL",
        "Bimonthly (every two months)": "BIMON",
        "Biweekly (every two weeks)": "BIWEEK",
        Month: "MONTH",
        Quarterly: "QRTRLY",
        "Semiannual (twice a year)": "SEMANL",
        "Semimonthly (twice a month)": "SEMMON",
        Week: "WEEK",
      };
    case ES:
      return {
        Anualmente: "ANNUAL",
        "Bimestralmente (cada dos meses)": "BIMON",
        "Quincenalmente (cada dos semanas)": "BIWEEK",
        Mensualmente: "MONTH",
        Trimestralmente: "QRTRLY",
        "Bianual (dos veces al año)": "SEMANL",
        "semi-mensal (dos veces al mes)": "SEMMON",
        Semanalmente: "WEEK",
      };
    case PT:
      return {
        Anual: "ANNUAL",
        "Bimestral (a cada duas semanas)": "BIMON",
        "Quinzenal (a cada duas semanas)": "BIWEEK",
        Mensal: "MONTH",
        Trimestral: "QRTRLY",
        "Semestral (duas vezes ao ano)": "SEMANL",
        "semi-mensal (duas vezes por mês)": "SEMMON",
        Semanal: "WEEK",
      };
    case ZH:
      return {
        每年: "ANNUAL",
        "每半月（两周一次）": "BIMON",
        "每两周（两周一次）": "BIWEEK",
        每月: "MONTH",
        每季度: "QRTRLY",
        "每半年（一年两次）": "SEMANL",
        "每半月（每月两次）": "SEMMON",
        每周: "WEEK",
      };
    case VI:
      return {
        "hàng năm": "ANNUAL",
        "Hai tháng một lần (hai tuần một lần)": "BIMON",
        "Hai tuần một lần": "BIWEEK",
        "Hàng tháng": "MONTH",
        "Hàng quý": "QRTRLY",
        "Nửa năm (Hai lần một năm)": "SEMANL",
        "nửa tháng (hai lần một tháng)": "SEMMON",
        "Hàng tuần": "WEEK",
      };
    case HI:
      return {
        Year: "ANNUAL",
        "Bimonthly (every two months)": "BIMON",
        "Biweekly (every two weeks)": "BIWEEK",
        Month: "MONTH",
        Quarterly: "QRTRLY",
        "Semiannual (twice a year)": "SEMANL",
        "Semimonthly (twice a month)": "SEMMON",
        Semèn: "WEEK",
      };
  }
}
