import React from "react";
import { withMsal } from "@azure/msal-react";
import _ from "lodash";
import { connect }  from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { formSummaryObj } from "../../../utils/components/utilities/utils";
import SectionSummary from "../../applySnap/components/SectionSummary";
import { getSection } from "../../../utils/helper/pageRoutingHelper";

const RecertSummaryContainer = (props) => {
  const { sections,authorizedRepresentatives, householdInformation} = props.recert;
  const {ebtAuthRepFlg, snapAuthRepFlg} = props.recert.responseObject;
  const {ebtStateFlg, snpStateFlg} = props;

  // const recertSections = _.cloneDeep(sections);
  const recertSections = _.cloneDeep(sections.filter((item) => !item.hiddenFromNav));
  const summarySection = props.recert.summaryData;

  summarySection && summarySection.length > 0 && summarySection.forEach(section => {
    const matchedSection = getSection(section.id, recertSections);
    if(matchedSection) {
      matchedSection.subsections = section.subsections;
    }
  })

  const summaryData = formSummaryObj(recertSections.slice(1));


  return (
    <div>
       {summaryData.map((section) => {
            return <SectionSummary section={section} isSubsection={false}  key={section.sectionId} authorizedRepresentatives={authorizedRepresentatives} ebtAuthRepFlg={ebtAuthRepFlg} snapAuthRepFlg={snapAuthRepFlg} snpStateFlg={snpStateFlg} ebtStateFlg={ebtStateFlg} householdInformation={householdInformation} applicationType="recert"/>;
        })
        }
     
    </div>
  );
}

export default withMsal(connect(mapStateToProps)(RecertSummaryContainer));
