import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import moment from "moment";
import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import { addStringIf, isNone, tryCall } from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { MONTHS, GetMonths } from "../../utils/constants/controls";
import { datelanguageLabel } from "../../pages/screening/screeningText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

const _historyYear = 125;
const _futureYear = 100;

class DTADate extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.instanceOf(Date),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    this.state = {
      ...this._convertDateToState(props.value),
      _isDayTouched: !isNone(props.value),
      _isMonthTouched: !isNone(props.value),
      _isYearTouched: !isNone(props.value),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value && PropTypes.date_error === 0) {
      this.setState(this._convertDateToState(nextProps.value));
    }
  }

  render() {
    const { error, disabled, required, className, name } = this.props,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={addStringIf(className, "pure-g")}>
        {this._buildMonth(name, ariaLabels, error, disabled, required)}
        {this._buildDay(name, ariaLabels, error, disabled, required)}
        {this._buildYear(name, ariaLabels, error, disabled, required)}
      </div>
    );
  }

  _buildDay(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-u-1-5 pad-right-half">
        <label
          className="dta-form__label dta-form__label--size-small"
          htmlFor={this.nextUniqueId()}
        >
          {/* Day */ datelanguageLabel(language).dayLabel}
        </label>
        <DTAInput
          name={name}
          type="number"
          blocks={[2]}
          numericOnly={TextProps.VALUE_TRUE}
          className="dta-input--expand-width"
          id={this.lastUniqueId()}
          aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
          onChange={this._handleBlurForDay}
          value={this.state._isDayTouched ? this.state._dayValue<10 ? `0${this.state._dayValue}`:this.state._dayValue : ""}
          error={error}
          disabled={disabled}
          required={required}
        />
      </div>
    );
  }
  _getMonth = () => {
    return GetMonths(language);
  };
  _buildMonth(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-u-2-5 pad-right-half">
        <label
          className="dta-form__label dta-form__label--size-small"
          htmlFor={this.nextUniqueId()}
        >
          {datelanguageLabel(language).monthLabel}
        </label>
        <DTASelect
          name={name}
          options={this._getMonth()}
          className="dta-select--expand-width"
          id={this.lastUniqueId()}
          aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
          value={this.state._isMonthTouched ? this.state._monthValue : ""}
          onChange={this._handleChangeForMonth}
          error={error}
          disabled={disabled}
          required={required}
          months={MONTHS}
          dob={TextProps.VALUE_STR_TRUE}
        />
      </div>
    );
  }

  _buildYear(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-u-2-5">
        <label
          className="dta-form__label dta-form__label--size-small"
          htmlFor={this.nextUniqueId()}
        >
          {/*  Year */ datelanguageLabel(language).yearLabel}
        </label>
        <DTAInput
          name={name}
          type="number"
          blocks={[4]}
          numericOnly={TextProps.VALUE_TRUE}
          className="dta-input--expand-width"
          id={this.lastUniqueId()}
          aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
          onChange={this._handleBlurForYear}
          value={this.state._isYearTouched ? this.state._yearValue : ""}
          error={error}
          disabled={disabled}
          required={required}
        />
      </div>
    );
  }

  // Events
  // ------

  _handleBlurForDay = (value) => {
    const day = value,
      monthString = this.state._monthValue,
      year = this.state._yearValue;
    this.setState({ _isDayTouched: TextProps.VALUE_TRUE, _dayValue: value });
    if (this.props.onChange) {
      tryCall(
        this.props.onChange,
        this._convertStateToDate(day, monthString, year)
      );
    }
  };

  _handleChangeForMonth = (value) => {
    const day = this.state._dayValue,
      monthString = value,
      year = this.state._yearValue;
    this.setState({
      _isMonthTouched: TextProps.VALUE_TRUE,
      _monthValue: value,
    });
    if (this.props.onChange) {
      tryCall(
        this.props.onChange,
        this._convertStateToDate(day, monthString, year)
      );
    }
  };

  _handleBlurForYear = (value) => {
    const day = this.state._dayValue,
      monthString = this.state._monthValue,
      year = value;
    this.setState({ _isYearTouched: TextProps.VALUE_TRUE, _yearValue: value });
    if (this.props.onChange) {
      tryCall(
        this.props.onChange,
        this._convertStateToDate(day, monthString, year)
      );
    }
  };

  // Helpers
  // -------

  _convertDateToState(date) {
    const obj = { _dayValue: "", _monthValue: "", _yearValue: "" };
    if (typeof date === "string") {
      date = moment(date, 'YYYY-MM-DD');
      let dateTemp = date.format('MM/DD/YYYY')
      date = new Date(dateTemp);
    }
    if (isNone(date)) {
      return obj;
    }
    // uses local timezone for display
    obj["_dayValue"] = date.getDate();
    obj["_monthValue"] = this._convertMonthNumberToString(date.getMonth());
    obj["_yearValue"] = date.getFullYear();
    return obj;
  }

  _convertStateToDate(day, monthString, year) {
    PropTypes.date_error = 0;
    if (
      (day !== "" || monthString !== "" || year !== "") &&
      (day === "" || monthString === "" || year === "")
    ) {
      PropTypes.date_error = 1;
    } else if (day !== "" && monthString !== "" && year !== "") {
      if (
        !this._isValidDate(
          day,
          this._convertMonthStringToNumber(monthString) + 1,
          year
        )
      ) {
        PropTypes.date_error = 1;
      } else {
        PropTypes.date_error = 0;
        return new Date(
          year,
          this._convertMonthStringToNumber(monthString),
          day || 1
        );
      }
    }
  }

  // note that month number is 0-indexed for javascript dates
  _convertMonthStringToNumber(monthString) {
    return MONTHS.indexOf(monthString);
  }

  // note that month number is 0-indexed for javascript dates
  _convertMonthNumberToString(monthNumber) {
    return isNone(MONTHS[monthNumber]) ? MONTHS[0] : MONTHS[monthNumber];
  }

  // Date validation method
  _isValidDate(tempDate, tempMonth, tempYear) {
    var enteredMon = tempMonth;
    var enteredDay = tempDate;
    var enteredYear = tempYear;
    if (isNaN(enteredMon) || enteredMon < 1 || enteredMon > 12) {
      return TextProps.VALUE_FALSE;
    } else {
      enteredMon = parseInt(enteredMon, 10);
    }
    if (isNaN(enteredDay) || enteredDay < 1 || enteredDay > 31) {
      return TextProps.VALUE_FALSE;
    } else {
      enteredDay = parseInt(enteredDay, 10);
    }
    if (enteredYear) {
      enteredYear = "" + enteredYear + "";
      if (enteredYear.length !== 4) {
        return TextProps.VALUE_FALSE;
      }
    }

    if (isNaN(enteredYear) || enteredYear < 1000) {
      return TextProps.VALUE_FALSE;
    } else {
      enteredYear = parseInt(enteredYear, 10);
    }
    if (
      (enteredMon === 1 ||
        enteredMon === 3 ||
        enteredMon === 5 ||
        enteredMon === 7 ||
        enteredMon === 8 ||
        enteredMon === 10 ||
        enteredMon === 12) &&
      (enteredDay > 31 || enteredDay < 1)
    ) {
      return TextProps.VALUE_FALSE;
    }
    if (
      (enteredMon === 4 ||
        enteredMon === 6 ||
        enteredMon === 9 ||
        enteredMon === 11) &&
      (enteredDay > 30 || enteredDay < 1)
    ) {
      return TextProps.VALUE_FALSE;
    }
    if (enteredMon === 2) {
      if (enteredDay < 1) {
        return TextProps.VALUE_FALSE;
      }
      if (this._isLeapYear(enteredYear)) {
        if (enteredDay > 29) {
          return TextProps.VALUE_FALSE;
        }
      } else {
        if (enteredDay > 28) {
          return TextProps.VALUE_FALSE;
        }
      }
    }
    var historyDte = new Date();
    historyDte.setFullYear(historyDte.getFullYear() - _historyYear);
    if (enteredYear < historyDte.getFullYear()) {
      return TextProps.VALUE_FALSE;
    } else {
      var futureDte = new Date();
      futureDte.setFullYear(futureDte.getFullYear() + _futureYear);
      if (enteredYear > futureDte.getFullYear()) {
        return TextProps.VALUE_FALSE;
      }
    }
    return TextProps.VALUE_TRUE;
  }

  /**
   * Returns TextProps.VALUE_TRUE if the passed in year is leap year. Else returns TextProps.VALUE_FALSE.
   */
  _isLeapYear(inYear) {
    return inYear % 100 === 0 ? inYear % 400 === 0 : inYear % 4 === 0;
  }
}

export default DTADate;
