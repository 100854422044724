export const DOCUMENT_NAME = "typeCode";
export const CLIENT_NAME = "clientName";
export const CLIENT_NAME_FIRST = "first";
export const CLIENT_NAME_LAST = "last";
export const STATUS = "statusCode";
export const DISPOSITION = "dispositionCode";
export const EXT_DOC_ID = "externalID";
export const DOCUMENT_SOURCE = "source";
export const STATUS_INADEQUATE = "INADQT"; //Denied
export const STATUS_NOT_ENTERED = "NOTENT";
export const STATUS_AVAILABLE = "AVALBL"; //1
export const STATUS_ENTERED = "ENTERD"; //2
export const STATUS_REVIEWED = "REVIWD"; //3
export const STATUS_UNIDENTIFIABLE = "UNIDFB";

export const STATUS_PROCESSING = "documentStatusProcessing";
export const STATUS_ACCEPTED = "documentStatusAccepted";
export const STATUS_DENIED = "documentStatusDenied";
export const RECEIVED_DATE = "receivedDate";
export const STATUS_CHANGED_DATE = "statusChangedDate";
export const CREATED_DATE = "createdDt";
export const DATE_OF_BIRTH = "dateOfBirth";

//Disposition codes

export const DISPOSITION_PENDING_REVIEW = "PNDREV";
export const DISPOSITION_PENDING_AGENCY_REVIEW = "APNDRW";
export const DISPOSITION_REVIEWED_PENDING_INTERVIEW = "RWPNIN";
export const DISPOSITION_REVIEWED_AND_ENTERED = "REVENT";
export const DISPOSITION_REVIEWED_DUPLICATE = "REVDUP";
export const DISPOSITION_REVIEWED_NOTRELEVANT = "REVNRE";
export const DISPOSITION_NOTENTERED_DUE_TO_CASESTATUS = "NEDTCS";
export const DISPOSITION_UNIDENTIFIABLE = "UNIDEN";
export const DISPOSITION_UNREADABLE = "UNREAD";
export const DISPOSITION_UNSIGNED = "UNSIGN";
export const DISPOSITION_INCOMPLETE = "INCOMP";
export const DISPOSITION_INCOMPLETE_AND_UNSIGNED = "INUNSN";
