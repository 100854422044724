import React, { Component } from "react";
import Radio from "../../../utils/components/radio";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText";
import * as TextProps from "../../../utils/constants/text";
import * as languageConstants from "../../../utils/constants/constants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { recertificationPageLabels } from "../../recertification/recertificationText";
import { setLastQuestionChange, appendIRIncomeDetails, clearMemberIncome, setDataChangeStatus, setEarnedIncomeChangeStatus, setGrossEarnedIncomeChangeStatus, setHouseholdMembers, setQuestionOneChange, setRecertDataChangeStatus, setQuestionTwoChange, setQuestionThreeChange, setCommentText, setChangeAddressChange, setRegisterVoteFlgChange, setNoRegisterVoteFlgChange, setIfAnswerYesChange } from "../../../redux/recertification/recertificationActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import DTATextArea from "../../../utils/components/dtaTextArea";


let language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class HouseholdInfoTanf extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onQuestionOneLabelChange = (value) => {
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.props.setQuestionOneChange("N");
        } else {
            this.props.setQuestionOneChange("Y");
        }
    };

    onQuestionTwoLabelChange = (value) => {
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.props.setQuestionTwoChange("N");
        } else {
            this.props.setQuestionTwoChange("Y");
        }
    };

    onQuestionThreeLabelChange = (value) => {
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.props.setQuestionThreeChange("N");
        } else {
            this.props.setQuestionThreeChange("Y");
        }
    };

    onLastQuestionLabelChange = (value) => {
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.props.setLastQuestionChange("N");
        } else {
            this.props.setLastQuestionChange("Y");
        }
    }

    onIfAnswerYesLabelChange = (value) => {
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.props.setIfAnswerYesChange("N");
        } else {
            this.props.setIfAnswerYesChange("Y");
        }
    };

    OnDataChange = (value) => {
        if(recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart,
        ).householdOptions[0] === value) {
            this.props.setRegisterVoteFlgChange("Y");
        }
        else {
            this.props.setRegisterVoteFlgChange("N");
        }
        if(recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart,
        ).householdOptions[1] === value) {
            this.props.setChangeAddressChange("Y");
        }
        else {
            this.props.setChangeAddressChange("N");
        }
        if(recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart,
        ).householdOptions[2] === value) {
            this.props.setNoRegisterVoteFlgChange("Y");
        }
        else {
            this.props.setNoRegisterVoteFlgChange("N");
        }
    }

    returnSingleOptionSelectedValue() {
        if(this.props.recert.householdInformation && this.props.recert.householdInformation.registerVoteFlg === "Y") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[0]
        }
        if(this.props.recert.householdInformation && this.props.recert.householdInformation.changeAddressFlg === "Y") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[1]
        }
        if(this.props.recert.householdInformation && this.props.recert.householdInformation.noRegisterToVoteFlg === "Y") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[2]
        }
    }

    returnQuestionOneLabel() {
        if (
            this.props.recert.householdInformation && this.props.recert.householdInformation.hurtFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.recert.householdInformation && this.props.recert.householdInformation.hurtFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }

    returnQuestionTwoLabel() {
        if (
            this.props.recert.householdInformation && this.props.recert.householdInformation.hurtingNowFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.recert.householdInformation && this.props.recert.householdInformation.hurtingNowFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }

    returnQuestionThreeLabel() {
        if (
            this.props.recert.householdInformation && this.props.recert.householdInformation.afraidOfFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.recert.householdInformation && this.props.recert.householdInformation.afraidOfFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }

    returnLastQuestionLabel() {
        if (
            this.props.recert.householdInformation && this.props.recert.householdInformation.otherInfoFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.recert.householdInformation && this.props.recert.householdInformation.otherInfoFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }

    returnIfAnsweredYesLabel() {
        if (
            this.props.recert.householdInformation && this.props.recert.householdInformation.domesticRefFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.recert.householdInformation && this.props.recert.householdInformation.domesticRefFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }



    handleCommentsChange = (value) => {
        this.props.setCommentText(value);
    }

    render() {
        return (
            <div className="pad-all--double">
                <div className="pure-u-1-1">
                <h3>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfheadingtwo}
                        </h3>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfquestionfour}
                        </p>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="lastQuestion"
                                name="lastQuestion"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                            value={this.returnLastQuestionLabel()}
                            onChange={(value) => this.onLastQuestionLabelChange(value)}
                            />
                        </fieldset>
                    {this.props.recert.householdInformation && this.props.recert.householdInformation.otherInfoFlg === "Y" && (
                        <div>
                        <p>
                            {
                            recertificationPageLabels(
                                language,
                                null,
                                languageConstants.beforeYouStart,
                            ).tanfPleaseExplain
                        }
                        </p>
                    <DTATextArea
                        name={"HouseholdInfo"}
                        className="dta-form__control dta-select--expand-width"
                        placeholder={
                            recertificationPageLabels(
                                language,
                                null,
                                languageConstants.beforeYouStart,
                            ).tanfPleaseExplain
                        }
                        value={this.props.recert.householdInformation.comments}
                            onChange={(value) => this.handleCommentsChange(value)} 
                    />
                    </div>
                    )}
                    
                    <h3>
                        {
                            recertificationPageLabels(
                                language,
                                null,
                                languageConstants.beforeYouStart,
                            ).voterHeading
                        }
                    </h3>
                    <p>
                        {
                            recertificationPageLabels(
                                language,
                                null,
                                languageConstants.beforeYouStart,
                            ).voterQuestion
                        }
                    </p>
                    <Radio
                        id="selectedOptionValue"
                        name="selectedOptionValue"
                        className="dta-form__option-list"
                        optionClass="dta-form__option dta-form__option--width"
                        options={recertificationPageLabels(
                            language,
                            null,
                            languageConstants.beforeYouStart,
                        ).householdOptions}
                        required={TextProps.VALUE_TRUE}
                    value={this.returnSingleOptionSelectedValue()}
                    onChange={(value) => this.OnDataChange(value)}
                    />
                    <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).voterHeadone}
                        </p>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).voterParaone}
                        </p>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).voterParatwo}
                        </p>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).voterParathree}
                        </p>
                    <div>
                        <h3>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfheadingone}
                        </h3>
                            <span>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfheadingonesub}
                            </span>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfquestionone}
                        </p>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="questionOne"
                                name="questionOne"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                                value={this.returnQuestionOneLabel()}
                                onChange={(value) => this.onQuestionOneLabelChange(value)}
                            />
                        </fieldset>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfquestiontwo}
                        </p>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="questionTwo"
                                name="questionTwo"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                            value={this.returnQuestionTwoLabel()}
                            onChange={(value) => this.onQuestionTwoLabelChange(value)}
                            />
                        </fieldset>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).tanfquestionthree}
                        </p>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="questionThree"
                                name="questionThree"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                            value={this.returnQuestionThreeLabel()}
                            onChange={(value) => this.onQuestionThreeLabelChange(value)}
                            />
                        </fieldset>
                        <p>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).ifansweryes}
                        </p>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="questionIfAnswerYes"
                                name="questionIfAnswerYes"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                            value={this.returnIfAnsweredYesLabel()}
                            onChange={(value) => this.onIfAnswerYesLabelChange(value)}
                            />
                        </fieldset>
                        <p>
                            <b>
                            {interimReportPageLabels(
                                language,
                                languageConstants.aboutMe,
                                null,
                                null,
                                null
                            ).finalnote}
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        clearMemberIncome: clearMemberIncome,
        appendIRIncomeDetails: appendIRIncomeDetails,
        setHouseholdMembers: setHouseholdMembers,
        setRecertDataChangeStatus: setRecertDataChangeStatus,
        setGrossEarnedIncomeChangeStatus: setGrossEarnedIncomeChangeStatus,
        setEarnedIncomeChangeStatus: setEarnedIncomeChangeStatus,
        setDataChangeStatus: setDataChangeStatus,
        setQuestionOneChange:setQuestionOneChange,
        setQuestionTwoChange:setQuestionTwoChange,
        setQuestionThreeChange: setQuestionThreeChange,
        setLastQuestionChange: setLastQuestionChange,
        setIfAnswerYesChange: setIfAnswerYesChange,
        setCommentText: setCommentText,
        setChangeAddressChange: setChangeAddressChange,
        setRegisterVoteFlgChange: setRegisterVoteFlgChange,
        setNoRegisterVoteFlgChange: setNoRegisterVoteFlgChange,
      },
      dispatch
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdInfoTanf);