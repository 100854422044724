import PropTypes from "prop-types";
import { ZIPCODE } from "../../utils/constants/zipCode";
import { ALL_STATES_COUNTY } from "../../utils/constants/statesCountyCode";
import { aboutMeMultilanguageLabels } from "./applySnapText";
import { ENGLISH } from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";
import _ from "lodash";

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select") {
  language = ENGLISH;
}

let applicationType = sessionStorage.getItem("applicationType");

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId] === undefined) {
      if (question.Answer === undefined && question.type !== "text") {
        question.Answer = "";
        question.targetValue = "";
        question.value = "";
      }
    } else if (
      AnswerObj[activeId][question.name] !== undefined ||
      (question.conditionals &&
        AnswerObj[activeId][
          question.conditionals[0].conditionalQuestions[0].name
        ])
    ) {
      if (AnswerObj[activeId][question.name] !== undefined) {
        question.Answer = AnswerObj[activeId][question.name];
        question.targetValue = AnswerObj[activeId][question.name];
        question.value = AnswerObj[activeId][question.name];
      }
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.value =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
              conditionQuestion.value = "";
            }
          });
        });
      }
    } else {
      if (
        (question.Answer === undefined ||
        (AnswerObj[activeId][question.name] === undefined &&
          question.name === "dateOfBirth")) && question.type !== "text"
      ) {
        question.Answer = "";
        question.targetValue = "";
        question.value = "";
      }
    }
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  let forceInvalid = TextProps.VALUE_FALSE;
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "about-me") {
        buildApplyAboutMeValidations(inputSections);
      } else if (activeId === "contact-info") {
        buildApplyContactInfoValidations(inputSections);
      } else if (activeId === "emergency-snap") {
        buildApplyEmergencyValidations(inputSections);
      } else if (activeId === "accommodation") {
        forceInvalid = buildAccommodationsValidations(inputSections);
      } else if (activeId === "more-info") {
        forceInvalid = buildApplyMoreInfoValidations(inputSections);
      } else if (activeId === "optional") {
        buildApplyOptionalValidations(inputSections);
      } else if (activeId === "my-household") {
        buildApplyMyHouseValidations(inputSections);
      } 
      else if (activeId === "household-information") {
       forceInvalid = buildApplyHouseholdInformationValidations(inputSections);
      } 
      else if (activeId.substring(0, 16) === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (/combined-resources-\d/.test(activeId)) {
        forceInvalid = buildResourcesValidations(inputSections);
      } else if (/combined-income-\d/.test(activeId)) {
        forceInvalid = buildIncomeValidations(inputSections);
      } else if (activeId.includes("combined-income-subsection")) {
        forceInvalid = buildIncomeValidationsSubsection(inputSections);
      } else if (activeId.includes("combined-resources-subsection")) {
        forceInvalid = buildResourcesValidationsSubsection(inputSections);
      }else if (activeId.includes("housing-costs")) {
        buildApplyHousingCostsValidations(inputSections);
      } else if (activeId.substring(0, 15) === "medical-address") {
        buildApplyMediacalAddressValidations(inputSections);
      } else if (activeId.substring(0, 27) === "kids-adult-multiple-address") {
        buildApplyKidsAdultAddressValidations(inputSections);
      } else if (
        activeId != "kids-and-adults" &&
        activeId.substring(0, 15) == "kids-and-adults"
      ) {
        forceInvalid = buildApplyKidsAdultValidations(inputSections);
      }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (var j = 0; j < dataSections.length; j++) {
      var answerNotElected = dataSections[j];
      if (
        answerNotElected.type === "date" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputSSN" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputPhone" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputText" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "emailAddress"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "address"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (answerNotElected.isRequired === TextProps.VALUE_TRUE) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (dataID.substring(0, 27) === "kids-adult-multiple-address") {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (dataID.substring(0, 15) === "medical-address") {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (
        dataID === "contact-info" &&
        answerNotElected.Answer === TextProps.VALUE_STR_FALSE
      ) {
        var answerNotElectedMailingAddress = "";
        let mailingAddrObj = dataSections.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0];
        if (
          mailingAddrObj !== undefined &&
          mailingAddrObj.conditionals[0] !== undefined
        ) {
          answerNotElectedMailingAddress =
            mailingAddrObj.conditionals[0].conditionalQuestions[0];
        }
        if (
          answerNotElectedMailingAddress.type === "address" &&
          (answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE ||
            answerNotElectedMailingAddress.Answer !== "") &&
          answerNotElectedMailingAddress.name === "mailingAddress"
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        (answerNotElected.type === "inputText" ||
          answerNotElected.type === "inputAlphaText") &&
        answerNotElected.error === TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.name === "race" &&
        answerNotElected.Answer.includes("Other") &&
        (answerNotElected.conditionals[0].conditionalQuestions[0].error ===
          TextProps.VALUE_TRUE ||
          answerNotElected.conditionals[0].conditionalQuestions[0].Answer !==
            "")
      ) {
        totalReqFields.push(
          answerNotElected.conditionals[0].conditionalQuestions[0].Answer
        );
      } else if (
        (answerNotElected.name === "utlOtherPayer" ||
          answerNotElected.name === "shelterOtherPayer") &&
        answerNotElected.conditionals[0].conditionalQuestions[0].error ===
          TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(
          answerNotElected.conditionals[0].conditionalQuestions[0].Answer
        );
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnswers = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (var b = 0; b < dataSections.length; b++) {
      var answerElected = dataSections[b];
      if (
        (answerElected.type === "date" ||
          answerElected.type === "inputSSN" ||
          answerElected.type === "inputPhone") &&
        answerElected.Answer !== "" &&
        answerElected.error === TextProps.VALUE_FALSE
      ) {
        totalAnswers.push(answerElected.Answer);
      } else if (
        answerElected.type === "inputText" &&
        answerElected.Answer !== "" &&
        answerElected.error === TextProps.VALUE_FALSE &&
        answerElected.name === "emailAddress"
      ) {
        totalAnswers.push(answerElected.Answer);
      } else if (
        answerElected.name === "race" &&
        answerElected.Answer.includes("Other") &&
        answerElected.conditionals[0].conditionalQuestions[0].Answer !== "" &&
        answerElected.conditionals[0].conditionalQuestions[0].error ===
          TextProps.VALUE_FALSE
      ) {
        totalAnswers.push(
          answerElected.conditionals[0].conditionalQuestions[0].Answer
        );
      } else if (
        answerElected.type === "address" &&
        answerElected.Answer !== "" &&
        answerElected.error === TextProps.VALUE_FALSE &&
        answerElected.name === "address"
      ) {
        totalAnswers.push(answerElected.Answer);
      } else if (
        answerElected.Answer !== "" &&
        answerElected.isRequired === TextProps.VALUE_TRUE &&
        dataSections[b].type !== "address" &&
        answerElected.error === TextProps.VALUE_FALSE
      ) {
        totalAnswers.push(answerElected.Answer);
      } else if (
        dataID === "contact-info" &&
        answerElected.Answer === TextProps.VALUE_STR_FALSE
      ) {
        let answerNotElectedMailingAddress = "";
        let mailingAddrObj = dataSections.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0];
        if (
          mailingAddrObj !== undefined &&
          mailingAddrObj.conditionals[0] !== undefined
        ) {
          answerNotElectedMailingAddress =
            mailingAddrObj.conditionals[0].conditionalQuestions[0];
        }
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_FALSE &&
          answerNotElectedMailingAddress.Answer !== "" &&
          answerNotElectedMailingAddress.name === "mailingAddress"
        ) {
          totalAnswers.push(answerNotElectedMailingAddress.Answer);
        }
      }
    }
  }
  // checking required fields and total touched fields
  if (
    forceInvalid ||
    (sections !== undefined && totalReqFields.length !== totalAnswers.length)
  ) {
    return TextProps.VALUE_FALSE;
  } else {
    return TextProps.VALUE_TRUE;
  }
}

function buildApplyKidsAdultValidations(inputSections) {
  let isInvalid = TextProps.VALUE_FALSE;
  inputSections.forEach((section) => {
    if (section.name.indexOf("drive-to-provider") == 0) {
      const conditional = section.conditionals[0];
      if (section.Answer == conditional.valueToMatch) {
        const conditionalQuestion = conditional.conditionalQuestions[0];
        if (!conditionalQuestion.Answer) {
          conditionalQuestion.error = TextProps.VALUE_TRUE;
          isInvalid = TextProps.VALUE_TRUE;
        }
      }
    }
  });
  return isInvalid;
}

function buildApplyMyHouseValidations(dataSections) {
  if (dataSections !== undefined) {
    const questionsObj = dataSections[0];
    if (questionsObj.type === "numbers") {
      if (
        (questionsObj.Answer < 1 || questionsObj.Answer === "") &&
        questionsObj.isRequired === TextProps.VALUE_TRUE
      ) {
        questionsObj.error = TextProps.VALUE_TRUE;
      } else {
        questionsObj.error = TextProps.VALUE_FALSE;
      }
    }
  }
}

function buildApplyHouseholdInformationValidations(dataSections) {
  let isHouseholdInformationValidated = false;
  if (dataSections !== undefined) {
    dataSections.map((section) => {
        const questionsObj = section.conditionals[0].conditionalQuestions[0];
        const radioQuestionObj = section.Answer;
        if (
          radioQuestionObj ===
          aboutMeMultilanguageLabels(language).houseLessOptions[0]
        ) {
          if (
            questionsObj.Answer == undefined ||
            questionsObj.Answer === "" ||
            questionsObj.Answer.length < 1
          ) {
            questionsObj.error = TextProps.VALUE_TRUE;
            questionsObj.isRequired = TextProps.VALUE_TRUE;
            isHouseholdInformationValidated = TextProps.VALUE_TRUE;

          } else {
            questionsObj.error = TextProps.VALUE_FALSE;
            questionsObj.isRequired = TextProps.VALUE_FALSE;
          }
        }
      });
  }
  return isHouseholdInformationValidated;
}

function buildIncomeValidations(dataSections) {
  if (dataSections !== undefined) {
    const questionsObj = dataSections[0];
    if (
      questionsObj.Answer == undefined ||
      questionsObj.Answer === "" ||
      questionsObj.Answer.length < 1
    ) {
      questionsObj.error = TextProps.VALUE_TRUE;
      return TextProps.VALUE_TRUE;
    } else {
      questionsObj.error = TextProps.VALUE_FALSE;
      return TextProps.VALUE_FALSE;
    }
  }
}
function buildResourcesValidations(dataSections) {
  if (dataSections !== undefined) {
    const questionsObj = dataSections[0];
    if (
      questionsObj.Answer == undefined ||
      questionsObj.Answer === "" ||
      questionsObj.Answer.length < 1
    ) {
      questionsObj.error = TextProps.VALUE_TRUE;
      return TextProps.VALUE_TRUE;
    } else {
      questionsObj.error = TextProps.VALUE_FALSE;
      return TextProps.VALUE_FALSE;
    }
  }
}

// Income and benefit validation for individual member
function buildIncomeValidationsSubsection(dataSections) {
  if (dataSections !== undefined) {
    // To filter only required fields
    const incomeHoursRequired = dataSections
      .filter((obj) => obj.name !== "rental-income-hours-worked-0")
      .map((obj) => obj.Answer);
    const income = [];
    const incomeHoursRequiredNew = income.concat(...incomeHoursRequired);
    const incomeColumn = incomeHoursRequiredNew.find(
      (obj) => obj.amount === ""
    );

    if (incomeColumn !== undefined) {
      dataSections.map((obj) => {
        if (obj.Answer.find((x) => x.amount === "")) {
          obj.repeatedQuestion.error = TextProps.VALUE_TRUE;
          if (obj.name.includes("rental-income-hours-worked")) {
            obj.repeatedQuestion.error = TextProps.VALUE_FALSE;
          }
        } else {
          obj.repeatedQuestion.error = TextProps.VALUE_FALSE;
        }
      });
      return TextProps.VALUE_TRUE;
    } else {
      dataSections.map(
        (obj) => (obj.repeatedQuestion.error = TextProps.VALUE_FALSE)
      );
      return TextProps.VALUE_FALSE;
    }
  }
}

// Income and benefit validation for individual member
function buildResourcesValidationsSubsection(dataSections) {
  if (dataSections !== undefined) {
    // To filter only required fields
  const resourcesRequired = dataSections.map((obj) => obj.Answer);
  const resources = [];
  const resourcesRequiredNew = resources.concat(...resourcesRequired);
  let validationStaus;
  dataSections.map((data) => {
  if(data.templateType === "ResourcesTemplateOne"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => obj.amount === ""
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateOneResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateOneResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }else if(data.templateType === "ResourcesTemplateTwo"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => (obj.amount === "" || obj.bankName === "" || obj.jointAccount === "" || (obj.jointAccount === "Yes" && obj.jointAccountHolder === "" ) )
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateTwoResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateTwoResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }else if(data.templateType === "ResourcesTemplateThree"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => (obj.amount === "" || obj.bankName === "" )
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateThreeResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateThreeResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }
  });
  return validationStaus;
  }
}
function validateTemplateOneResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
      ans.error = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.required = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.errorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
  });
  return copyObj;
}
function validateTemplateTwoResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
      ans.amountError = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.amountRequired = ans.amount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.amountErrorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
      ans.bankNameError = ans.bankName === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.bankNameRequired = ans.bankName === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.bankNameErrorMessage = ans.bankName === "" ? obj.repeatedQuestion.bankErrMsg : "";
      ans.jointAccountError = ans.jointAccount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.jointAccountRequired = ans.jointAccount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.jointAccountErrorMessage = ans.jointAccount === "" ? obj.repeatedQuestion.jointAccounterrMsg : "";
      ans.jointAccountHoldertError = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.jointAccountHolderRequired = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.jointAccountHolderErrorMessage = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? obj.repeatedQuestion.jointAccountHolderErrMsg : "";
  });
  return copyObj;
}
function validateTemplateThreeResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
    ans.amountError = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
    ans.amountRequired = ans.amount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
    ans.amountErrorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
    ans.bankNameError = ans.bankName === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
    ans.bankNameRequired = ans.bankName === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
    ans.bankNameErrorMessage = ans.bankName === "" ? obj.repeatedQuestion.bankBrokageErrMsg : "";
  });
  return copyObj;
}
function buildApplyHouseholdMembersValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "select") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].Answer !== null &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "inputText" ||
        dataSections[k].type === "inputAlphaText" ||
        dataSections[k].type === "specialText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexNumber = /\d+/;
        var rexSpace = /^(?=.*\S).+$/;
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          dataSections[k].Answer.length >= 1 &&
          (rexNumber.test(dataSections[k].Answer) === TextProps.VALUE_TRUE ||
            rexSpace.test(dataSections[k].Answer) === TextProps.VALUE_FALSE)
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "date") {
        if (PropTypes.date_error === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer === "" || dataSections[k].Answer === null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer !== "" || dataSections[k].Answer !== null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          var input = dataSections[k].Answer;
          var currentDate = new Date();
          let inputDate = new Date(input);
          if (inputDate.getTime() > currentDate.getTime()) {
            dataSections[k].error = TextProps.VALUE_TRUE;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputSSN") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (PropTypes.ssnError === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "radio") {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyOptionalValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var e = 0; e < dataSections.length; e++) {
      if (dataSections[e].type === "radio") {
        if (
          dataSections[e].Answer === "" &&
          dataSections[e].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[e].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[e].type === "checkbox") {
        if (
          dataSections[e].Answer === "" &&
          dataSections[e].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[e].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}
function buildApplyHousingCostsValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var e = 0; e < dataSections.length; e++) {
      if (
        dataSections[e].name === "utlOtherPayer" ||
        dataSections[e].name === "shelterOtherPayer"
      ) {
        if (
          dataSections[e].Answer === "Yes" &&
          dataSections[e].conditionals[0].conditionalQuestions[0].Answer === ""
        ) {
          dataSections[e].conditionals[0].conditionalQuestions[0].error =
            TextProps.VALUE_TRUE;
        } else {
          dataSections[e].conditionals[0].conditionalQuestions[0].error =
            TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyAboutMeValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "select") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].Answer !== null &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "inputText" ||
        dataSections[k].type === "inputAlphaText" || 
        dataSections[k].type === "specialText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          dataSections[k].Answer.length >= 0 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(dataSections[k].Answer) ===
              TextProps.VALUE_TRUE ||
            rexAnySpace.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          dataSections[k].Answer.length > 0 &&
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(dataSections[k].Answer) ===
              TextProps.VALUE_TRUE ||
            rexAnySpace.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          dataSections[k].name === "lastName" &&
          dataSections[k].Answer.length > 20
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          dataSections[k].name !== "lastName" &&
          dataSections[k].Answer.length > 15
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "date") {
        if (PropTypes.date_error === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer === "" || dataSections[k].Answer === null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (
          (dataSections[k].Answer !== "" || dataSections[k].Answer !== null) &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          var inputDate = new Date(dataSections[k].Answer);
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            dataSections[k].error = TextProps.VALUE_TRUE;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputSSN") {
        if (
          dataSections[k].Answer.length < 1 &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else if (PropTypes.ssnError === 1) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyContactInfoValidations(dataSections) {
  const resZipCode = dataSections.find((obj) => obj.name === "address").Answer
    .zipCode;
  const alabamaResZipCode = ZIPCODE.find((obj) => obj.ZIPCODE === resZipCode);

  if (dataSections !== undefined) {
    for (var k = 0; k < dataSections.length; k++) {
      // Validation for mailing Address

      let isAlsoMailingAddIndex = dataSections.findIndex(
        (i) => i.name === "is-also-mailing"
      );

      if (dataSections[k].type === "radio" && k === isAlsoMailingAddIndex) {
        var mailingAddressObj = {};
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        if (dataSections[k].Answer === TextProps.VALUE_STR_FALSE) {
          mailingAddressObj =
            dataSections[k].conditionals[0].conditionalQuestions[0];
          let alabamaMailingZipCodeValidated = TextProps.VALUE_TRUE;
          if (mailingAddressObj.Answer && mailingAddressObj.Answer.state && mailingAddressObj.Answer.state === TextProps.DEFAULT_STATE) {
            let alabamaMailingZipCode = ZIPCODE.filter(
              (obj) => obj.ZIPCODE === mailingAddressObj.Answer.zipCode
            );
            alabamaMailingZipCodeValidated = alabamaMailingZipCode.length
              ? TextProps.VALUE_TRUE
              : TextProps.VALUE_FALSE;
          } else {
            alabamaMailingZipCodeValidated = TextProps.VALUE_TRUE;
          }
          if (mailingAddressObj.Answer) {
            if (
              mailingAddressObj.Answer === "" ||
              mailingAddressObj.Answer.street === ""
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
            } else if (
              mailingAddressObj.addressLine1 === "" ||
              mailingAddressObj.Answer.addressLine1 === ""
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
            } else if (
              mailingAddressObj.Answer.zipCode === "" ||
              (mailingAddressObj.Answer.zipCode &&
                mailingAddressObj.Answer.zipCode.length < 5) ||
              !alabamaMailingZipCodeValidated
            ) {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.zipCodeErrmsg;
            } else if (mailingAddressObj.Answer.city === "") {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.citiErrmsg;
            } else if (mailingAddressObj.Answer.county === "") {
              mailingAddressObj.error = TextProps.VALUE_TRUE;
              mailingAddressObj.errorMessage = mailingAddressObj.countyErrmsg;
            } else {
              mailingAddressObj.error = TextProps.VALUE_FALSE;
            }
          } else {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
          }
        }
      }

      if (dataSections[k].type === "radio" && k !== 4) {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "inputPhone" &&
        dataSections[k].Answer.length > 0 &&
        dataSections[k].Answer.length < 10
      ) {
        dataSections[k].error = TextProps.VALUE_TRUE;
      } else if (dataSections[k].type === "inputText") {
        if (
          dataSections[k].Answer.length > 0 ||
          dataSections[k].Answer !== "" ||
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          var emailRegEx =
            /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if (
            dataSections[k].Answer.length &&
            emailRegEx.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "text" &&
        dataSections[k].name === "emailAddress" &&
        dataSections[k].Answer !== undefined
      ) {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].name === "address") {
        // The below condition is when user is household homeless
        let poBoxRegEx = /\bP\.?\s*O\.?\s*Box\b/i;
        if (
          !(
            dataSections[2].Answer ===
              aboutMeMultilanguageLabels(language).houseLessOptions[0] &&
            dataSections[4].Answer === TextProps.VALUE_STR_FALSE
          )
        ) {
          if (
            dataSections[k].Answer === "" ||
            dataSections[k].Answer.street === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            dataSections[k].addressLine1 === "" ||
            dataSections[k].Answer.addressLine1 === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            poBoxRegEx.test(dataSections[k].addressLine1) ===
              TextProps.VALUE_TRUE ||
            poBoxRegEx.test(dataSections[k].Answer.addressLine1) ===
              TextProps.VALUE_TRUE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].poBoxErrmsg;
          } else if (
            dataSections[k].Answer.zipCode === "" ||
            (dataSections[k].Answer.zipCode &&
              dataSections[k].Answer.zipCode.length < 5) ||
            !alabamaResZipCode
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].zipCodeAlErrmsg;
          } else if (dataSections[k].Answer.city === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          } else if (dataSections[k].Answer.county === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].countyErrmsg;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMediacalAddressValidations(dataSections) {
  for (var k = 0; k < dataSections.length; k++) {
    if (dataSections[k].type === "address") {
      let errorFlag = TextProps.VALUE_FALSE;
      if (
        dataSections[k].Answer.zipCode === "" ||
        dataSections[k].Answer.city === "" ||
        dataSections[k].Answer.state === "" ||
        dataSections[k].Answer.street === ""
      ) {
        dataSections[k].error = TextProps.VALUE_TRUE;
        dataSections[k].errorMessage = dataSections[k].addressErrmsg;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (
        dataSections[k].Answer.zipCode !== "" &&
        dataSections[k].isRequired === TextProps.VALUE_FALSE &&
        dataSections[k].Answer.zipCode !== undefined
      ) {
        if (dataSections[k].Answer.zipCode.length < 5) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          dataSections[k].Answer = "";
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        dataSections[k].Answer.city !== "" &&
        dataSections[k].isRequired === TextProps.VALUE_FALSE &&
        dataSections[k].Answer.city !== undefined
      ) {
        var rex = /^[A-Za-z' ]+$/;
        if (rex.test(dataSections[k].Answer.city) === TextProps.VALUE_FALSE) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          dataSections[k].Answer = "";
          dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        dataSections[k].error = TextProps.VALUE_FALSE;
      }
    }
    if (
      dataSections[k].Answer.amount !== "" &&
      dataSections[k].Answer.amount !== undefined
    ) {
      let errorFlag = TextProps.VALUE_FALSE;
      if (dataSections[k].Answer.amount.length > 6) {
        dataSections[k].error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        dataSections[k].error = TextProps.VALUE_FALSE;
      }
    }
  }
}

function buildApplyKidsAdultAddressValidations(dataSections) {
  for (var k = 0; k < dataSections.length; k++) {
    if (dataSections[k].type === "address") {
      let errorFlag = TextProps.VALUE_FALSE;
      dataSections[k].error = TextProps.VALUE_TRUE;
      if (
        dataSections[k].Answer.zipCode === "" ||
        dataSections[k].Answer.city === "" ||
        dataSections[k].Answer.state === "" ||
        dataSections[k].Answer.street === ""
      ) {
        dataSections[k].error = TextProps.VALUE_TRUE;
        dataSections[k].errorMessage = dataSections[k].addressErrmsg;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (
        dataSections[k].Answer.zipCode !== "" &&
        (dataSections[k].isRequired === TextProps.VALUE_FALSE ||
          dataSections[k].Answer.zipCode !== undefined)
      ) {
        if (dataSections[k].Answer.zipCode.length < 5) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          dataSections[k].Answer = "";
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        dataSections[k].Answer.city !== "" &&
        (dataSections[k].isRequired === TextProps.VALUE_FALSE ||
          dataSections[k].Answer.city !== undefined)
      ) {
        var alphaRex = /^[A-Za-z' ]+$/;
        if (
          alphaRex.test(dataSections[k].Answer.city) === TextProps.VALUE_FALSE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
          dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        dataSections[k].error = TextProps.VALUE_FALSE;
      }
    }
    if (
      dataSections[k].Answer.amount !== "" &&
      dataSections[k].Answer.amount !== undefined
    ) {
      var rex = /^[0-9]+$/;
      let errorFlag = TextProps.VALUE_FALSE;
      if (rex.test(dataSections[k].Answer.amount) === TextProps.VALUE_FALSE) {
        dataSections[k].error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (dataSections[k].Answer.amount.length > 6) {
        dataSections[k].error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        dataSections[k].error = TextProps.VALUE_FALSE;
      }
    }
  }
}
function buildApplyEmergencyValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var e = 0; e < dataSections.length; e++) {
      if (dataSections[e].type === "radio") {
        if (
          dataSections[e].Answer === "" &&
          dataSections[e].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[e].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildAccommodationsValidations(dataSections) {
  let isAccommodationValid = false;
  if (dataSections !== undefined) {
    const questionsObj = dataSections.filter(
      (data) => data.name === "is-accommondation"
    )[0].conditionals[0].conditionalQuestions[0];
    const radioQuestionObj = dataSections.filter(
      (data) => data.name === "is-accommondation"
    )[0].Answer;
    if (
      radioQuestionObj ===
      aboutMeMultilanguageLabels(language).accommodationOptions[0]
    ) {
      if (
        questionsObj.Answer == undefined ||
        questionsObj.Answer === "" ||
        questionsObj.Answer.length < 1
      ) {
        questionsObj.error = TextProps.VALUE_TRUE;
        questionsObj.isRequired = TextProps.VALUE_TRUE;
        isAccommodationValid = TextProps.VALUE_TRUE;
      } else {
        questionsObj.error = TextProps.VALUE_FALSE;
        questionsObj.isRequired = TextProps.VALUE_FALSE;
        isAccommodationValid = TextProps.VALUE_FALSE;
      }
      const otherquestionsObj = dataSections.filter(
        (data) => data.name === "is-accommondation"
      )[0].conditionals[0].conditionalQuestions[1];
      if (otherquestionsObj) {
        if (
          otherquestionsObj.Answer == undefined ||
          otherquestionsObj.Answer === "" ||
          otherquestionsObj.Answer.length < 1
        ) {
          otherquestionsObj.error = TextProps.VALUE_TRUE;
          otherquestionsObj.errorMessage = aboutMeMultilanguageLabels(language).accOtherErrormsg;
          otherquestionsObj.isRequired = TextProps.VALUE_TRUE;
          isAccommodationValid = TextProps.VALUE_TRUE;
        } else if (
          otherquestionsObj.Answer.length >= 1 &&
          otherquestionsObj.Answer.length < 3
        ) {
          otherquestionsObj.error = TextProps.VALUE_TRUE;
          otherquestionsObj.isRequired = TextProps.VALUE_TRUE;
          otherquestionsObj.errorMessage = aboutMeMultilanguageLabels(language).accOtherLengthErrormsg;
          isAccommodationValid = TextProps.VALUE_TRUE;
        } else {
          otherquestionsObj.error = TextProps.VALUE_FALSE;
          otherquestionsObj.isRequired = TextProps.VALUE_FALSE;
          isAccommodationValid = TextProps.VALUE_FALSE;
        }
      }
    }
  }
  return isAccommodationValid;
}
function buildApplyMoreInfoValidations(dataSections) {
  if (dataSections !== undefined) {
    let isInValid = TextProps.VALUE_FALSE;
    for (var e = 0; e < dataSections.length; e++) {
      if (dataSections[e].type === "radio") {
        if (
          dataSections[e].Answer === "" &&
          dataSections[e].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[e].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }

        if(dataSections[e].name === "marital-status"){
          if(
            dataSections[e].Answer.length > 0 &&
            dataSections[e].Answer !== null &&
            dataSections[e].Answer !== "Single"
            ) {
              dataSections[e].conditionals.forEach(condition => {
                if(condition.valueToMatch === dataSections[e].Answer && condition.conditionalQuestions.length > 0){
                  let conditionQuestion = condition.conditionalQuestions[0];
                  if (
                    conditionQuestion.Answer === "" &&
                    conditionQuestion.isRequired === TextProps.VALUE_TRUE
                  ) {
                    conditionQuestion.error = TextProps.VALUE_TRUE;
                    isInValid = TextProps.VALUE_TRUE;
                  } else {
                    conditionQuestion.error = TextProps.VALUE_FALSE;
                    isInValid = TextProps.VALUE_FALSE;
                  }
                }
              });
            }
        }
      } else if (dataSections[e].type === "select") {
        if (
          dataSections[e].Answer.length < 1 &&
          dataSections[e].Answer !== null &&
          dataSections[e].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[e].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[e].name === "race") {
        if (dataSections[e].Answer.includes("Other")) {
          let otherValue =
            dataSections[e].conditionals[0].conditionalQuestions[0].Answer;
          if (otherValue === "") {
            dataSections[e].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            dataSections[
              e
            ].conditionals[0].conditionalQuestions[0].errorMessage =
              aboutMeMultilanguageLabels(language).otherRaceErrmsg;
          } else if (otherValue && otherValue.length < 3) {
            dataSections[e].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            dataSections[
              e
            ].conditionals[0].conditionalQuestions[0].errorMessage =
              aboutMeMultilanguageLabels(language).otherRaceErrMsgLength;
          } else {
            dataSections[e].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[e].error = TextProps.VALUE_FALSE;
        }
      }
    }
    return isInValid;
  }
}

export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions
) {
  let selectedQuestions = [];
  for (
    var answerIndex = 0;
    answerIndex < activePageAnswers.length;
    answerIndex++
  ) {
    for (
      var questionIndex = 0;
      questionIndex < nextPageQuestions.length;
      questionIndex++
    ) {
      if (
        nextPageQuestions[questionIndex] &&
        activePageAnswers[answerIndex] ===
          nextPageQuestions[questionIndex].valueToMatch
      ) {
        selectedQuestions.push(nextPageQuestions[questionIndex]);
      }
    }
  }
  return selectedQuestions;
}
export function nameWithoutWhiteSpace(name) {
  return name.replace(/\s/g, "");
}
export function deleteHouseholdMems(
  sections,
  confirmedHouseholdMemForDeletion
) {
  let updatedHouseholdMems =
    Number(sections[2].questions[0].Answer) -
    confirmedHouseholdMemForDeletion.length;
  sections[2].questions[0].Answer = updatedHouseholdMems.toString();
  sections[2].questions[0].targetValue = updatedHouseholdMems.toString();
  confirmedHouseholdMemForDeletion.map((memDeleted) => {
    let deletedMem = nameWithoutWhiteSpace(memDeleted);
    //Delete household members
    if (sections[2].completed) {
      sections[2].subsections = sections[2].subsections.filter(
        (deleteHousehold) =>
          nameWithoutWhiteSpace(deleteHousehold.title) !== deletedMem
      );
      sections[2].subsections[
        sections[2].subsections.length - 1
      ].questions[0].options = sections[2].subsections[
        sections[2].subsections.length - 1
      ].questions[0].options.filter(
        (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
      );
      let answeredDisabilityOptions = [];
      if (
        sections[2].subsections[sections[2].subsections.length - 1].questions[0]
          .Answer &&
        sections[2].subsections[sections[2].subsections.length - 1].questions[0]
          .Answer.length
      ) {
        sections[2].subsections[
          sections[2].subsections.length - 1
        ].questions[0].Answer.map((ans) => {
          let valuExists = sections[2].subsections[
            sections[2].subsections.length - 1
          ].questions[0].options.filter((opt) => opt.optionValue === ans);
          if (valuExists.length) {
            answeredDisabilityOptions.push(ans);
          }
        });
      }
      sections[2].subsections[
        sections[2].subsections.length - 1
      ].questions[0].Answer = answeredDisabilityOptions;
      sections[2].subsections[
        sections[2].subsections.length - 1
      ].questions[0].targetValue = answeredDisabilityOptions;
      // sections[2].memberOptions = sections[2].memberOptions.filter(mem => nameWithoutWhiteSpace(mem) !== deletedMem);
    }
    //Delete income
    if (sections[3].completed) {
      sections[3].memberOptions = sections[3].memberOptions.filter(
        (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
      );
      sections[3].questions[0].options =
        sections[3].questions[0].options.filter(
          (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
        );
      sections[3].questions[0].memberOptions =
        sections[3].questions[0].memberOptions.filter(
          (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
        );
      let answeredOptions = [];
      if (sections[3].questions[0].Answer.length) {
        sections[3].questions[0].Answer.map((ans) => {
          let valuExists = sections[3].questions[0].options.filter(
            (opt) => opt.optionValue === ans
          );
          if (valuExists.length) {
            answeredOptions.push(ans);
          }
        });
      }
      sections[3].questions[0].Answer = answeredOptions;
      sections[3].questions[0].targetValue = answeredOptions;
      let incomeSubsection = [];
      sections[3].subsections.forEach((sub) => {
        if (sub && nameWithoutWhiteSpace(sub.title) !== deletedMem) {
          incomeSubsection.push(sub);
        }
      });
      sections[3].subsections = incomeSubsection;
    }
    //Delete Expenseses kids-and-adults section
    if (sections[4].completed && sections[4].subsections[1].completed) {
      // delete sections[4].subsections[1].active;
      sections[4].subsections[1].dependentCare =
        sections[4].subsections[1].dependentCare.filter(
          (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
        );
      sections[4].subsections[1].memberOptions =
        sections[4].subsections[1].memberOptions.filter(
          (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
        );
      sections[4].subsections[1].questions[0].options =
        sections[4].subsections[1].questions[0].options.filter(
          (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
        );
      let answeredExpOptions = [];
      if (sections[4].subsections[1].questions[0].Answer.length) {
        sections[4].subsections[1].questions[0].Answer.map((ans) => {
          let valuExists =
            sections[4].subsections[1].questions[0].options.filter(
              (opt) => opt.optionValue === ans
            );
          if (valuExists.length) {
            answeredExpOptions.push(ans);
          }
        });
      }
      sections[4].subsections[1].questions[0].Answer = answeredExpOptions;
      sections[4].subsections[1].questions[0].targetValue = answeredExpOptions;
      let valueMatched = [];
      if (sections[4].subsections[1].questions[0].conditionals.length) {
        sections[4].subsections[1].questions[0].conditionals.map((ans) => {
          let valuExists =
            sections[4].subsections[1].questions[0].options.filter(
              (opt) => opt.optionValue === ans.valueToMatch
            );
          if (valuExists.length) {
            valueMatched.push(ans);
          }
        });
      }
      sections[4].subsections[1].questions[0].conditionals = valueMatched;
      sections[4].subsections[1].subsections =
        sections[4].subsections[1].subsections.filter(
          (mem) => nameWithoutWhiteSpace(mem.title) !== deletedMem
        );
    }
    //Delete expenses child support
    if (sections[4].completed && sections[4].subsections[2].completed) {
      sections[4].subsections[2].memberOptions =
        sections[4].subsections[2].memberOptions.filter(
          (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
        );
      sections[4].subsections[2].questions[0].options =
        sections[4].subsections[2].questions[0].options.filter(
          (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
        );
      let answeredChildSupportExpOptions = [];
      if (sections[4].subsections[2].questions[0].Answer.length) {
        sections[4].subsections[2].questions[0].Answer.map((ans) => {
          let valuExists =
            sections[4].subsections[2].questions[0].options.filter(
              (opt) => opt.optionValue === ans
            );
          if (valuExists.length) {
            answeredChildSupportExpOptions.push(ans);
          }
        });
      }
      sections[4].subsections[2].questions[0].Answer =
        answeredChildSupportExpOptions;
      sections[4].subsections[2].questions[0].targetValue =
        answeredChildSupportExpOptions;
      let valuechildSupportMatched = [];
      if (sections[4].subsections[2].questions[0].conditionals.length) {
        sections[4].subsections[2].questions[0].conditionals.map((ans) => {
          let valuExists =
            sections[4].subsections[2].questions[0].options.filter(
              (opt) => opt.optionValue === ans.valueToMatch
            );
          if (valuExists.length) {
            valuechildSupportMatched.push(ans);
          }
        });
      }
      sections[4].subsections[2].questions[0].conditionals =
        valuechildSupportMatched;
    }
    //expenses delete medical
    if (sections[4].completed && sections[4].subsections[3].completed) {
      sections[4].subsections[3].memberOptions =
        sections[4].subsections[3].memberOptions.filter(
          (mem) => nameWithoutWhiteSpace(mem) !== deletedMem
        );
      sections[4].subsections[3].questions[0].options =
        sections[4].subsections[3].questions[0].options.filter(
          (opt) => nameWithoutWhiteSpace(opt.optionDisplay) !== deletedMem
        );
      let answeredMedicalOptions = [];
      if (sections[4].subsections[3].questions[0].Answer.length) {
        sections[4].subsections[3].questions[0].Answer.map((ans) => {
          let valuExists =
            sections[4].subsections[3].questions[0].options.filter(
              (opt) => opt.optionValue === ans
            );
          if (valuExists.length) {
            answeredMedicalOptions.push(ans);
          }
        });
      }
      sections[4].subsections[3].questions[0].Answer = answeredMedicalOptions;
      sections[4].subsections[3].questions[0].targetValue =
        answeredMedicalOptions;
      let incomeSubsection = [];
      sections[3].subsections.forEach((sub) => {
        if (sub && nameWithoutWhiteSpace(sub.title) !== deletedMem) {
          incomeSubsection.push(sub);
        }
      });
      sections[3].subsections = incomeSubsection;
    }
  });
  return sections;
}

export function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}

function zeroFormatter(value) {
  let formattedValue = value;
  if (formattedValue < 10) {
    formattedValue = "0" + formattedValue;
  }
  return formattedValue;
}
export function todaysDateOrTime(date, epochDate, timezon) {
  let today = new Date(epochDate);

  if (date) {
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    return zeroFormatter(mm) + "/" + zeroFormatter(dd) + "/" + yyyy;
  } else if (today) {
    let hh = today.getHours();
    let min = today.getMinutes();
    return zeroFormatter(hh) + ":" + zeroFormatter(min);
  }
}

function createAddressObj(addressObj, addrType) {
  return {
    line1: addressObj.addressLine1 ? addressObj.addressLine1 : "",
    line2: addressObj.addressLine2 ? addressObj.addressLine2 : "",
    city: addressObj.city ? addressObj.city : "",
    state: addressObj.state ? addressObj.state : "",
    zipCode: addressObj.zipCode ? addressObj.zipCode : "",
    county: addressObj.county ? addressObj.county : "",
    countyCode: addressObj.countyCode ? addressObj.countyCode : "",
    verified: addressObj.verified ? addressObj.verified : TextProps.VALUE_N,
    type: addrType,
  };
}
export function addressValidationObj(questions) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0].Answer;
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0].Answer;
  let addresses = [];
  if (residentialAddress) {
    addresses.push(createAddressObj(residentialAddress, TextProps.VALUE_RES));
  }
  if (mailingAddress) {
    addresses.push(createAddressObj(mailingAddress, TextProps.VALUE_MAIL));
  }
  return addresses;
}
export function formAddressDisplayValue(addressArray) {
  addressArray.filter((answer) => {
    let zipCode = answer.zipCode ? answer.zipCode : answer.zip;
    let line2 = answer.line2 ? answer.line2 : "";
    let county = answer.county ? "County: " + answer.county : "";
    answer.formattedAddress =
      answer.line1 +
      " " +
      line2 +
      " " +
      answer.city +
      " " +
      answer.state +
      " " +
      zipCode +
      "\n" +
      county +
      " ";
    answer.verified = answer.verified ? answer.verified : TextProps.VALUE_N;
  });
  return addressArray;
}
export function formatAddressDisplay(addressArray) {
  let formattedAddressToDisplay = [];
  addressArray.map((address) => {
    formattedAddressToDisplay.push(address.formattedAddress);
  });
  return formattedAddressToDisplay;
}
function mapAddress(enteredAddrs, selectedAddrs) {
  let result = _.isEqual(enteredAddrs, selectedAddrs);
  let zip = selectedAddrs.zip ? selectedAddrs.zip : selectedAddrs.zipCode;
  if (!result) {
    enteredAddrs.addressLine1 = selectedAddrs.line1;
    enteredAddrs.addressLine2 = selectedAddrs.line2 ? selectedAddrs.line2 : "";
    enteredAddrs.city = selectedAddrs.city;
    enteredAddrs.county = selectedAddrs.county
      ? selectedAddrs.county
      : selectedAddrs.county;
    enteredAddrs.countyCode = selectedAddrs.countyCode
      ? selectedAddrs.countyCode
      : "";
    enteredAddrs.state = selectedAddrs.state;
    enteredAddrs.verified = selectedAddrs.verified;
    enteredAddrs.zipCode = zip;
  }
  return enteredAddrs;
}
export function appendUpdatedAdrress(
  questions,
  selectedResAddress,
  selectedMailingAddress
) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0];
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0];
  if (residentialAddress.Answer) {
    residentialAddress.Answer = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
    residentialAddress.targetValue = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
  }
  if (mailingAddress.Answer) {
    mailingAddress.Answer = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
    mailingAddress.targetValue = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
  }
  return questions;
}
export function checkIfAddressIsUpdated(addr1, addr2) {
  let result = _.isEqual(addr1, addr2);
  return result ? TextProps.VALUE_FALSE : TextProps.VALUE_TRUE;
}
export function filterData(section, id) {
  return section.filter((sec) => sec.id === id)[0];
};
