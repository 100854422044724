
import { convertDOBForDataPost, getSuffixValue } from "./helper";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as languageConstants from "../../utils/constants/constants";
import * as TextProps from "../../utils/constants/text";

import { ReportChangePageLabels } from "./reportChangeText";
import { convertDOBObjectToString } from '../../utils/components/helperFunctions/helperFunctions';
const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const processHouseholdData = (household) =>{
    let newMembers = [];
    household.forEach((member)=>{
    if(member.agencyID === 0){
        if (member.allAnswers !== undefined) {
            let genderCodeVal = "";
            let buyFoodFlagVal = null;
            let incomeFlagVal = null;
            if (
                member.allAnswers[6].Answer ===
                ReportChangePageLabels(language, languageConstants.householdStatic)
                  .genderOptios[0]
              ) {
                genderCodeVal = "MALE";
              } else if (
                member.allAnswers[6].Answer ===
                ReportChangePageLabels(language, languageConstants.householdStatic)
                  .genderOptios[1]
              ) {
                genderCodeVal = "FEMALE";
              }
              let citizenFlagVal = null;
          if (
            member.allAnswers[9].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .citizenOptions[0]
          ) {
            citizenFlagVal = TextProps.VALUE_TRUE;
          } else if (
            member.allAnswers[9].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .citizenOptions[1]
          ) {
            citizenFlagVal = TextProps.VALUE_FALSE;
          }

          if (
            member.allAnswers[11].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[0]
          ) {
            buyFoodFlagVal = TextProps.VALUE_TRUE;
          } else if (
            member.allAnswers[11].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[1]
          ) {
            buyFoodFlagVal = TextProps.VALUE_FALSE;
          }
          if (
            member.allAnswers[12].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[0]
          ) {
            incomeFlagVal = TextProps.VALUE_TRUE;
          } else if (
            member.allAnswers[12].Answer ===
            ReportChangePageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[1]
          ) {
            incomeFlagVal = TextProps.VALUE_FALSE;
          }
          const raceAnswers = member.allAnswers[8].Answer && member.allAnswers[8].Answer.length && member.allAnswers[8].Answer.map(raceAns => {
            let raceObj = {};
            raceObj["raceCode"] = raceAns;
            return raceObj;
          })

          const isRaceDeclineAns = raceAnswers && raceAnswers.length > 0 && raceAnswers.filter(oneAns => oneAns.raceCode === "Decline to answer");

          let processedMem = {
            agencyID: 0,
            granteeFlag: TextProps.VALUE_FALSE,
            firstName: member.allAnswers[0].Answer,
            middleName: member.allAnswers[1].Answer,
            lastName: member.allAnswers[2].Answer,
            suffix: getSuffixValue(member.allAnswers[3].Answer),
            relationshipCode: member.allAnswers[4].Answer.toUpperCase(),
            dateOfBirth: convertDOBObjectToString(member.dateOfBirth),
            genderCode: genderCodeVal,
            ethnicity: member.allAnswers[7].Answer !== "Decline to answer" ? member.allAnswers[7].Answer : "DCL",
            race: (raceAnswers && isRaceDeclineAns && isRaceDeclineAns.length > 0) ? [{raceCode: "DCL"}] : raceAnswers ? raceAnswers : [],
            citizenFlag: citizenFlagVal,
            SSN: member.allAnswers[10].Answer,
            buyFoodFlag: buyFoodFlagVal,
            incomeFlag: incomeFlagVal,
            dataChangeCode: "NEW"
          };
          newMembers.push(processedMem);
        }
    }else{
      let changeCode = ""
      if(member.dataChangeCode){
        switch(member.dataChangeCode){
          case "removed":
            changeCode = "REMOVE";
            break;
          case "changed":
            changeCode = "CHANGE";
            break; 
            default:
            changeCode = member.dataChangeCode;
            break;
        }
        member.dataChangeCode = changeCode;
      }else{
        member.dataChangeCode = "NOCHNG";
      }
        member.dateOfBirth = convertDOBForDataPost(member.dateOfBirth);
        newMembers.push(member)
    }
    })
    return newMembers;
}