import * as Types from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";
import { langOptSel } from "../../utils/constants/constants";
import { 
  beforeYoustartDsnapMlanguageLabels,
  aboutMeDsnapMultilanguageLabels,
  householdDsnapMultilanguageLabels,
  MyhouseholdDsnapMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  resourcesDsnapMultilanguageLabels,
  expensesDsnapMultilanguageLabels,
  signAndSubmitDsnapMultilanguageLabels,
  houseHoldSectionMultilanguageLabels,
  aboutMeMultilanguageLabels
 } from "./applyDsnapText";
import * as Props from "../../utils/components/shared";
import * as Names from "../../utils/constants/names";
import * as SelectProps from "../../utils/constants/select";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

export const DsnapSections = [
    {
      //index:0
      id: "before-you-start",
      title: beforeYoustartDsnapMlanguageLabels(language).howApplyLabel, //'How does applying work?',
      header: beforeYoustartDsnapMlanguageLabels(language).heading, //'Before you start',
      completed: TextProps.VALUE_FALSE,
      webClientId: "GRANTEE",
      webAppId: "",
      webPgrmId: "",
      isSubmitted: TextProps.VALUE_FALSE,
      isSubmitInitiated: TextProps.VALUE_FALSE,
      errorRedirect: TextProps.VALUE_FALSE,
      activeSectionId: "before-you-start",
      signAndSubmit: TextProps.VALUE_FALSE,
      questions: [
        {
        [Props.NAME]: "before-you-start",
        [Props.INLINE_HELP]:
        "<h3>"+beforeYoustartDsnapMlanguageLabels(language).snapFieldLabel1 + "</h3>"+
        '<ul><li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).fieldFactors[0] + '</li>' +
        '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).fieldFactors[1] + '</li></ul>' + 
        "<p style='font-size: 16px;'>"+"<b>" + beforeYoustartDsnapMlanguageLabels(language).completingPreReglabel + "</b>"+ beforeYoustartDsnapMlanguageLabels(language).completingPreRegSub + "</p>" +       
        '<ul><li><p style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).fieldFactors[2] + "</p></li>" +
        '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).fieldFactors[3] + '</li></ul>' + 
        "<p style='font-size: 16px;'>" + beforeYoustartDsnapMlanguageLabels(language).informationLabel + "</p>" +
        '<ul>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).memberText + '</li>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).permanentText + '</li>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).proofText + '</li>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).proofResidencyText + '</li>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).amountIncomeText + '</li>' +
          '<li style="font-size: 16px;">' + beforeYoustartDsnapMlanguageLabels(language).amountHouseholdText + '</li>' +
        '</ul>' + "</br>" +
        "<p style='font-size: 16px;'>" + beforeYoustartDsnapMlanguageLabels(language).preRegistrationText + "</p>" + 
        "<p style='font-size: 16px;'>" + beforeYoustartDsnapMlanguageLabels(language).stateLabelText + "</p>",


        [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [Props.TYPE]: Types.SELECT,
        [Props.NAME]: "before-you-start-county",
        [SelectProps.OPTIONS]: beforeYoustartDsnapMlanguageLabels(language).county,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
        beforeYoustartDsnapMlanguageLabels(language).countyErrorMsg,
      }
      ],
 
    },
    {
      //index:1
      id: "head-of-household-info",
      title: aboutMeDsnapMultilanguageLabels(language).title,
      header: aboutMeDsnapMultilanguageLabels(language).header,
      subHeader: 
      aboutMeDsnapMultilanguageLabels(language).subHeader + 
      householdDsnapMultilanguageLabels(language).situtationSubHeaderText2,
      questions: [
        {
          [Props.NAME]: "is-alabama-department",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).houseHoldLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [OptionsProps.OPTIONS]:
          aboutMeDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.INITIAL_VALUE]: TextProps.VALUE_YES,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:  aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0],            
              [OptionsProps.CONDITIONAL_QUESTIONS]: [      
                {      
                  [Props.TYPE]: Types.TEXT,   
                  [TextProps.VALUE]:aboutMeDsnapMultilanguageLabels(language).alabamaSublabelText,                
                }, 
              ],
            },
          ],
        },
        {
          [Props.NAME]: "firstName",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).labelFirstName,
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          aboutMeDsnapMultilanguageLabels(language).firstNameErrMsg,
          [Props.MAX_LENGTH]: 15,
        },
        {
          [Props.NAME]: "middleName",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).middleName,
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          aboutMeDsnapMultilanguageLabels(language).middleNameErrMsg,
          [Props.MAX_LENGTH]: 15,
        },
        {
          [Props.NAME]: Names.LAST_NAME,
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).lastNameLabel,
          [Props.TYPE]: Types.SPECIAL_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).lastNameErrMsg,
          [Props.MAX_LENGTH]: 20,
        },
        {
          [Props.NAME]: Names.SUFFIX,
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).labelSuffix,
          [Props.TYPE]: Types.SELECT,
          [SelectProps.OPTIONS]:
            aboutMeDsnapMultilanguageLabels(language).suffixOption,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: "choose suffix",
        },
        {
          [Props.NAME]: "dateOfBirth",
          [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
          [Props.TYPE]: Types.DATE,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
        },
        {
          [Props.NAME]: "ssn",
          [Props.LABEL]: aboutMeMultilanguageLabels(language).ssnLabel,
          [Props.INLINE_HELP]:aboutMeMultilanguageLabels(language).dsnapInlineHelpSSN,
          [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
          [Props.TYPE]: Types.INPUT_SSN,
          [Props.CHECKED]: TextProps.VALUE_FALSE,
          [InputProps.CLEAR_LABEL]:
            aboutMeMultilanguageLabels(language).inputClearLabel,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).ssnErrMsg,
        },
        {
          [Props.NAME]: "biological-sex",
          [Props.LABEL]: aboutMeMultilanguageLabels(language).genderLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]:
            aboutMeMultilanguageLabels(language).genderOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeMultilanguageLabels(language).genderErrmsg,
        },
        { 
          //old : 5
          //new : 8
          [Props.NAME]: "is-homeless",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).isHomelessLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [OptionsProps.OPTIONS]:
          aboutMeDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          //old : 6
          //new : 9
          [Props.NAME]: 'do-not-have-address',
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "YES",
            [OptionsProps.OPTION_DISPLAY]:
            aboutMeDsnapMultilanguageLabels(language).doNotAddress,
          },
        ]
        },
        {
          //old : 7
          //new : 10
          [Props.NAME]: "address",
          [Props.LABEL]:
            aboutMeDsnapMultilanguageLabels(language).addressQuestionlabel,
          [Props.TYPE]: Types.ADDRESS,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
          [Props.ERROR_MESSAGE_FOR_CHAR]:
            aboutMeDsnapMultilanguageLabels(language).citiErrmsg,
          [Props.ERROR_MESSAGE_FOR_COUNTY]:
            aboutMeDsnapMultilanguageLabels(language).countyErrmsg,
          [Props.ERROR_MESSAGE_FOR_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
          [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).homeAddressErrmsg,
          [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).mailingAddressErrmsg,
          [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
            aboutMeDsnapMultilanguageLabels(language).zipCodeErrmsg,
          [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
            aboutMeDsnapMultilanguageLabels(language).masZipCodeErrmsg,
          [Props.ERROR_MESSAGE_PO_BOX]:
            aboutMeDsnapMultilanguageLabels(language).poBoxErrmsg,
        },
        {
          //old : 8
          //new : 11
          [Props.NAME]: "is-also-mailing",
          [Props.LABEL]:
          aboutMeDsnapMultilanguageLabels(language).alsoMailingLabel,
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_TRUE,
              [OptionsProps.OPTION_DISPLAY]:
              aboutMeDsnapMultilanguageLabels(language).optionsSendMail,
            },
            {
              [OptionsProps.OPTION_VALUE]: TextProps.VALUE_STR_FALSE,
              [OptionsProps.OPTION_DISPLAY]:
              aboutMeDsnapMultilanguageLabels(language).optionsNoDiffAddress,
            },
          ],
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                TextProps.VALUE_STR_FALSE,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "mailingAddress",
                  [Props.LABEL]:
                  aboutMeDsnapMultilanguageLabels(language).mailingQlabel,
                  [Props.TYPE]: Types.ADDRESS,
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                  aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
                  [Props.ERROR_MESSAGE_FOR_CHAR]:
                  aboutMeDsnapMultilanguageLabels(language).citiErrmsg,
                  [Props.ERROR_MESSAGE_FOR_COUNTY]:
                  aboutMeDsnapMultilanguageLabels(language).countyErrmsg,
                  [Props.ERROR_MESSAGE_FOR_ADDRESS]:
                  aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
                  [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
                  aboutMeDsnapMultilanguageLabels(language).homeAddressErrmsg,
                  [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
                  aboutMeDsnapMultilanguageLabels(language).mailingAddressErrmsg,
                  [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
                  aboutMeDsnapMultilanguageLabels(language).zipCodeErrmsg,
                },
              ],
            },
          ],
        },
        {
          //old : 9
          //new : 12
          [Props.NAME]: "temporary",
          [Props.LABEL]:
            aboutMeDsnapMultilanguageLabels(language). addressTemporaryLabel,
          [Props.TYPE]: Types.ADDRESS,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
          [Props.ERROR_MESSAGE_FOR_CHAR]:
            aboutMeDsnapMultilanguageLabels(language).citiErrmsg,
          [Props.ERROR_MESSAGE_FOR_COUNTY]:
            aboutMeDsnapMultilanguageLabels(language).countyErrmsg,
          [Props.ERROR_MESSAGE_FOR_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).addressErrmsg,
          [Props.ERROR_MESSAGE_FOR_HOME_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).homeAddressErrmsg,
          [Props.ERROR_MESSAGE_FOR_MAILING_ADDRESS]:
            aboutMeDsnapMultilanguageLabels(language).mailingAddressErrmsg,
          [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
            aboutMeDsnapMultilanguageLabels(language).zipCodeErrmsg,
          [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
            aboutMeDsnapMultilanguageLabels(language).masZipCodeErrmsg,
          [Props.ERROR_MESSAGE_PO_BOX]:
            aboutMeDsnapMultilanguageLabels(language).poBoxErrmsg,
        },
        {
          //old : 10
          //new : 13
          [Props.NAME]: "is-disability-response",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).isDisabilityLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [OptionsProps.OPTIONS]:
          aboutMeDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          //old : 11
          //new : 14
          [Props.NAME]: "is-Authorized",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).isAuthorizedLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [OptionsProps.OPTIONS]:
          aboutMeDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:  aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0],            
              [OptionsProps.CONDITIONAL_QUESTIONS]: [      
                {
                  [Props.NAME]: "auth-rep-name",           
                  [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).authRepname,
                  [Props.TYPE]: Types.SPECIAL_TEXT,                  
                  [Props.REQUIRED]: TextProps.VALUE_FALSE, // Adjust this as needed
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                }, 
                {
                  [Props.NAME]: "auth-phone-number",           
                  [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).authrepPhnNo,
                  [Props.TYPE]: Types.INPUT_PHONE,                  
                  [Props.REQUIRED]: TextProps.VALUE_FALSE, // Adjust this as needed
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                  aboutMeDsnapMultilanguageLabels(language).validPhoneAlert

                }, 
              ],
            },
          ],
        },
        {
          //old : 12
          //new : 15
          [Props.NAME]: "license",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).licenseLabel,
          [Props.TYPE]: Types.INPUT_ALPHANUMERIC_TEXT,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).drivingLicenseErrorText,
          [Props.MAX_LENGTH]: 13,
        },
        {
          //old : 13
          //new : 16
          [Props.NAME]: "phoneNumber",
          [Props.LABEL]: aboutMeDsnapMultilanguageLabels(language).PhoneNoLabel,
          [Props.TYPE]: Types.INPUT_PHONE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).validPhoneAlert,
        },
        {
          //old : 14
          //new : 17
          [Props.NAME]: "emailAddress",
          [Props.LABEL]:
            aboutMeDsnapMultilanguageLabels(language).emailAddressLabel,
          [Props.TYPE]: Types.INPUT_TEXT,
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.MAX_LENGTH]: 100,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeDsnapMultilanguageLabels(language).emailErrormsg,
        },
       
      ],
      melisaAddressValidated: TextProps.VALUE_FALSE
    },
    {
      //index:2
      id: "household-situation",
      title: householdDsnapMultilanguageLabels(language).title,
      header: householdDsnapMultilanguageLabels(language).header,
      questions: [
        {
          [Props.NAME]: "time-of-disaster",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).timeDisasterLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "household-working-disaster",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).workingDiasterLabel,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "self-employement-property",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).selfEmployementLabel ,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "result-disaster ",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).resultDisaster,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "buying-food ",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).buyingFood,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "houseHold-Income",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).houseHoldIncome ,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "due-to-Disaster",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).dueToDisaster,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
        },
        {
          [Props.NAME]: "food-assistance",
          [Props.LABEL]: householdDsnapMultilanguageLabels(language).foodAssistance,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]:
          householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.INITIAL_VALUE]: TextProps.VALUE_YES,
          [Props.SUB_LABEL]:  householdDsnapMultilanguageLabels(language).disclaimerText,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:  householdDsnapMultilanguageLabels(language).yesNoOptions[0],            
              [OptionsProps.CONDITIONAL_QUESTIONS]: [      
                {
                  [Props.NAME]: "stateCountyAssistance",           
                  [Props.LABEL]: householdDsnapMultilanguageLabels(language).stateCountyLabel,
                  [Props.TYPE]: Types.DROPDOWN_STATE_COUNTY,
                  [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
                  [SelectProps.OPTIONS]:householdDsnapMultilanguageLabels(language).countycode,                  
                  [Props.REQUIRED]: TextProps.VALUE_TRUE, // Adjust this as needed
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                }, 
              ],
            },
          ],
        },    
        {
          [Props.NAME]: "food-benefits",
          [Props.LABEL]:
          householdDsnapMultilanguageLabels(language).foodBenefits,
          [Props.TYPE]: Types.RADIO,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [OptionsProps.INITIAL_VALUE]: TextProps.VALUE_YES,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
          householdDsnapMultilanguageLabels(language).HouseholdSitutaionErrMsg,
          [OptionsProps.OPTIONS]: householdDsnapMultilanguageLabels(language).yesNoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [Props.SUB_LABEL]: householdDsnapMultilanguageLabels(language).disclaimerText,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:  householdDsnapMultilanguageLabels(language).yesNoOptions[0],            
              [OptionsProps.CONDITIONAL_QUESTIONS]: [    
                  {
                  [Props.NAME]: "stateCountyBenefits",           
                  [Props.LABEL]: householdDsnapMultilanguageLabels(language).stateCountyLabel,
                  [Props.TYPE]: Types.DROPDOWN_STATE_COUNTY,
                  [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
                  [SelectProps.OPTIONS]:householdDsnapMultilanguageLabels(language).countycode,                  
                  [Props.REQUIRED]: TextProps.VALUE_TRUE, // Adjust this as needed
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                },                
              ],
            },
          ],
        },
      ],
    },
    {
      //index:3
      id: "my-household",
      title: MyhouseholdDsnapMultilanguageLabels(language).title,
      header: MyhouseholdDsnapMultilanguageLabels(language).header,
      subHeader:
      MyhouseholdDsnapMultilanguageLabels(language).memberSubHeader,
        questions: [
          {
            [Props.NAME]: "numberInHousehold",
            [Props.LABEL]:
            MyhouseholdDsnapMultilanguageLabels(language).howManyLabel,
            [Props.TYPE]: Types.NUMBERS,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
            MyhouseholdDsnapMultilanguageLabels(language).numOfPplErrMsg,
            [Props.INLINE_HELP]:
            MyhouseholdDsnapMultilanguageLabels(language).dsnapHouseHelp,
            [Props.NUMBER_TEXTBOX_LABEL]:
            MyhouseholdDsnapMultilanguageLabels(language).numOfPplText,
            //'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
          },
         
      ],
      completed: TextProps.VALUE_FALSE,
      subsections: [
        // {
        //     id: "member",
        //     title: MyhouseholdDsnapMultilanguageLabels(language).memberTitle,
        //     header: MyhouseholdDsnapMultilanguageLabels(language).memberHeader,
        //     // subHeader: MyhouseholdDsnapMultilanguageLabels(language).memberSubHeader,
        //     subHeader:
        //     MyhouseholdDsnapMultilanguageLabels(language).memberSubHeader,
        //     questions: [
        //       // firstName
        //       {
        //         [Props.NAME]: "firstName",
        //         [Props.LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).MemberFirstName, //"What is this person's first name?",
        //         [Props.TYPE]: Types.SPECIAL_TEXT,
        //         [Props.ERROR]: TextProps.VALUE_FALSE,
        //         [Props.REQUIRED]: TextProps.VALUE_TRUE,
        //         [Props.ERROR_MESSAGE]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberFirstNameErrmsg,
        //       },
        //       // middleName--
        //       {
        //         [Props.NAME]: "middleName",
        //         [Props.LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).MemebrMiddleName, 
        //         [Props.TYPE]: Types.SPECIAL_TEXT,
        //         [Props.REQUIRED]: TextProps.VALUE_FALSE,
        //         [Props.ERROR]: TextProps.VALUE_FALSE,
        //       },
        //       // lastName--
        //       {
        //         [Props.NAME]: Names.LAST_NAME,
        //         [Props.LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).MemberLastName, //"What is this person's last name?",
        //         [Props.TYPE]: Types.SPECIAL_TEXT,
        //         [Props.REQUIRED]: TextProps.VALUE_TRUE,
        //         [Props.ERROR]: TextProps.VALUE_FALSE,
        //         [Props.ERROR_MESSAGE]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberLastNameErrmsg,
        //       },
        //   //  suffix --  
        //       {
        //         [Props.NAME]: Names.SUFFIX,
        //         [Props.LABEL]: MyhouseholdDsnapMultilanguageLabels(language).memberSuffixLabel,
        //         [Props.TYPE]: Types.SELECT,
        //         [SelectProps.OPTIONS]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberSuffixOption,
        //       },
        //       //radio
             
        //       // dob--
        //       {
        //         [Props.NAME]: "dateOfBirth",
        //         [Props.LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).meberHouseholddob, //"What is this person's date of birth?",
        //         [Props.TYPE]: Types.DATE,
        //         [Props.ERROR]: TextProps.VALUE_FALSE,
        //         [Props.REQUIRED]: TextProps.VALUE_TRUE,
        //         [Props.ERROR_MESSAGE]:
        //         MyhouseholdDsnapMultilanguageLabels(language).meberHouseholddobError,
        //       },
        //       // genderOptions--
        //       {
        //         [Props.NAME]: "biological-sex",
        //         [Props.LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberGenderLabel,
        //         [Props.TYPE]: Types.RADIO,
        //         [OptionsProps.OPTIONS]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberGenderOption,
        //         [OptionsProps.OPTIONS_PER_ROW]: 2,
        //       },

        //       // ssn--
        //       {
        //         [Props.NAME]: "ssn",
        //         [Props.LABEL]: MyhouseholdDsnapMultilanguageLabels(language).memberSSNLabel,
        //         [Props.TYPE]: Types.INPUT_SSN,
        //         [Props.CHECKED]: TextProps.VALUE_FALSE,
        //         [InputProps.CLEAR_LABEL]:
        //         MyhouseholdDsnapMultilanguageLabels(language).memberDntLabel,
        //       },
        //       {
        //         [Props.NAME]: "race",
        //         [Props.LABEL]: MyhouseholdDsnapMultilanguageLabels(language).memberRaceLabel,
        //         [Props.TYPE]: Types.CHECKBOX,
        //         [Props.INLINE_HELP]: MyhouseholdDsnapMultilanguageLabels(language).memberRaceHelp,
        //         [OptionsProps.OPTIONS]: [
        //           {
        //             [OptionsProps.OPTION_VALUE]: "AMIALN",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[0],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "ASIAN",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[1],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "BLCAFR",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[2],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "NTHPCI",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[3],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "WHITE",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[4],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "Decline to answer",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[5],
        //           },
        //           {
        //             [OptionsProps.OPTION_VALUE]: "OTHER",
        //             [OptionsProps.OPTION_DISPLAY]:
        //             MyhouseholdDsnapMultilanguageLabels(language).dsnapRaceOptions[6],
        //           },
        //         ],
        //         [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
        //         [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
        //         [Props.REQUIRED]: TextProps.VALUE_FALSE,
        //         [Props.ERROR]: TextProps.VALUE_FALSE,
        //         [Props.ERROR_MESSAGE]: "Please choose race",
        //         // [OptionsProps.CONDITIONALS]: [
        //         //   {
        //         //     [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
        //         //     [OptionsProps.CONDITIONAL_QUESTIONS]: [
        //         //       {
        //         //         [Props.NAME]: "text-area",
        //         //         [Props.TYPE]: Types.INPUT_TEXT_AREA,
        //         //         [Props.MAX_LENGTH]: 250,
        //         //         [Props.MIN_LENGTH]: 3,
        //         //       },
        //         //     ],
        //         //   },
        //         // ],
        //       },

        //       // notes--
        //       {
        //         [Props.TYPE]: Types.TEXT,
        //         [TextProps.VALUE]: MyhouseholdDsnapMultilanguageLabels(language).dSnapNotes,
        //       },
        //     ],
        //   },
      ]
    },
    {
      //index:4
      id: "income",
      title: incomeSectionMultilanguageLabels(language).title,
      header: incomeSectionMultilanguageLabels(language).header,
      questions: [
        {
          [Props.NAME]: "income",
          [Props.LABEL]: {
            [Props.LABEL_OBJ_BEFORE]:
            incomeSectionMultilanguageLabels(language).aboutIncomeBefore,
            [Props.LABEL_OBJ_TRIGGER]:
            incomeSectionMultilanguageLabels(language).aboutIncomeTrigger, 
            [Props.LABEL_OBJ_AFTER]: "?",
            [Props.LABEL_OBJ_HELP]:
            incomeSectionMultilanguageLabels(language).incomeHelp,
          },
          [Props.INLINE_HELP]:
          incomeSectionMultilanguageLabels(language).moneyYougettitle,
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
           "Shweta Mishra(08/11/1994)",
            "Leo Han(09/22/1992)",
            "No income to report",
          ],
          [OptionsProps.CLEAR_OPTION]:
          incomeSectionMultilanguageLabels(language).questionClearOption,
        },
      ],
      subsections:[
        
        {
          id: "combined-income",
          title: "Shweta Mishra(08/11/1994)",
          header:"Shweta Mishra(08/11/1994)  income & Benifit Details",
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "types",
              [Props.LABEL]:
                "What type(s) of income or benefits does Shweta Mishra(08/11/1994) have?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: [
                {
                  [OptionsProps.OPTION_VALUE]: "UI-TAFDCS",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[0],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[1],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "EI-WAGES",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[2],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-RSDI",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[3],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-SSI",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[4],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[5],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[6],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[7],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-INTINC",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[8],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-MILPE",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[9],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-RETFND",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[10],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-RRRET",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[11],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[12],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[13],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[14],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[15],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-CONTR",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[16],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[17],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "EI-BOARD",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[18],
                },
                {
                  [OptionsProps.OPTION_VALUE]: "UI-OTHER",
                  [OptionsProps.OPTION_DISPLAY]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeOptions[19],
                },
              ],
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).optionErrMsg, 
            },
            
          ],
          subsections:[
            {
              id: "combined-income-subsection",
              parentsId: "income",
              title: "Shweta Mishra(08/11/1994)",
              header: "Shweta Mishra(08/11/1994) income and benefits",
              hiddenFromNav: TextProps.VALUE_TRUE,
              completed: TextProps.VALUE_FALSE,
              originalQuestions: [
                {
                  [Props.NAME]: "tanf-income-repeatable",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.NAME]: "tanf-gross-income-0",
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
                },
                {
                  [Props.NAME]: "self-gross-income-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .selfemeploymentTargetValue,
                  [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .selfemeploymentTargetValue, //'Add another Self-Employment'
                },
                {
                  [Props.NAME]: "job-gross-incom-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                  [OptionsProps.TABLE_DSC]: "EI-WAGES",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
                },
                {
                  [Props.NAME]: "social-gross-incom-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-RSDI",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
                },
                {
                  [Props.NAME]: "ssi-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-SSI",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
                },
                {
                  [Props.NAME]: "veteran-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
                },
                //7
                {
                  [Props.NAME]: "unemployment-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .unEmploymentTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .unEmploymentTargetValue, //'Add another Unemployment Compensation'
                },
                {
                  [Props.NAME]: "workman-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
                },
                {
                  [Props.NAME]: "interest-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .interestTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-INTINC",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
                },
                {
                  [Props.NAME]: "military-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .militaryTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-MILPE",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .militaryTargetValue, //"Add another Military Allotment"
                },
                {
                  [Props.NAME]: "pension-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RETFND",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.MAX_LENGTH]: 12,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).weekMonthOptions, //['Month', 'Week'],
                      [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
                },
                {
                  [Props.NAME]: "railroad-retirement-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .railroadTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RRRET",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .railroadTargetValue, //"Add another Railroad Retirement"
                },
                {
                  [Props.NAME]: "hud-utility-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .hudUtilityTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
                },
                {
                  [Props.NAME]: "income-land-rental-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeLandRentalTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
                },
                {
                  [Props.NAME]: "child-support-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .childSuppTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .anotherChildsupport, //'Add another Child Support'
                },
                {
                  [Props.NAME]: "alimony-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
                },
                {
                  [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .cotributionsTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-CONTR",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
                },
                {
                  [Props.NAME]: "room-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
                },
                {
                  [Props.NAME]: "board-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "EI-BOARD",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
                },
                {
                  [Props.NAME]: "other-income-benified-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-OTHER",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .questionButtonLabel, //"Add another Other"
                },
              
              ],
              questions:[
                {
                  [Props.NAME]: "tanf-income-repeatable",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).tanfTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.NAME]: "tanf-gross-income-0",
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
                },
                {
                  [Props.NAME]: "self-gross-income-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .selfemeploymentTargetValue,
                  [OptionsProps.TABLE_DSC]: "EI-SELEMP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .selfemeploymentTargetValue, //'Add another Self-Employment'
                },
                {
                  [Props.NAME]: "job-gross-incom-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).jobTargetValue,
                  [OptionsProps.TABLE_DSC]: "EI-WAGES",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
                },
                {
                  [Props.NAME]: "social-gross-incom-0",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).socialTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-RSDI",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
                },
                {
                  [Props.NAME]: "ssi-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).SSITargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-SSI",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
                },
                {
                  [Props.NAME]: "veteran-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).veteranTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-VETBEN",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
                },
                //7
                {
                  [Props.NAME]: "unemployment-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .unEmploymentTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .unEmploymentTargetValue, //'Add another Unemployment Compensation'
                },
                {
                  [Props.NAME]: "workman-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).workmanTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
                },
                {
                  [Props.NAME]: "interest-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .interestTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-INTINC",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
                },
                {
                  [Props.NAME]: "military-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .militaryTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-MILPE",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .militaryTargetValue, //"Add another Military Allotment"
                },
                {
                  [Props.NAME]: "pension-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).pensionTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RETFND",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.MAX_LENGTH]: 12,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).weekMonthOptions, //['Month', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
                },
                {
                  [Props.NAME]: "railroad-retirement-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .railroadTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RRRET",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .railroadTargetValue, //"Add another Railroad Retirement"
                },
                {
                  [Props.NAME]: "hud-utility-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .hudUtilityTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .hudUtilityTargetValue, //"Add another HUD Utility Assistance"
                },
                {
                  [Props.NAME]: "income-land-rental-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .incomeLandRentalTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-RENTAL",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
                },
                {
                  [Props.NAME]: "child-support-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .childSuppTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .anotherChildsupport, //'Add another Child Support'
                },
                {
                  [Props.NAME]: "alimony-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).alimonyTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
                },
                {
                  [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language)
                      .cotributionsTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "UI-CONTR",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language)
                      .cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
                },
                {
                  [Props.NAME]: "room-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).roomerTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "EI-ROOMER",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
                },
                {
                  [Props.NAME]: "board-gross-income-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).boaderTargetValue,
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [OptionsProps.TABLE_DSC]: "EI-BOARD",
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).costBetween,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .addAnotherButtonLabel +
                    " " +
                    incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
                },
                {
                  [Props.NAME]: "other-income-benified-0",
                  [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
                  [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  incomeSectionMultilanguageLabels(language).otherTargetValue,
                  [OptionsProps.TABLE_DSC]: "UI-OTHER",
                  [Props.TYPE]: Types.REPEATABLE_QUESTION,
                  [ControlConstants.REPEATABLE_QUESTION]: {
                    [Props.REQUIRED]: TextProps.VALUE_TRUE,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]:
                    incomeSectionMultilanguageLabels(language).amountErrMsg,
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]:
                    incomeSectionMultilanguageLabels(language).unitsPerLabel,
                    [UnitsProps.UNITS]:
                    incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                  [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
                  [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
                  [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  incomeSectionMultilanguageLabels(language)
                      .questionButtonLabel, //"Add another Other"
                },
              ]
            },
          ],
          completed: TextProps.VALUE_FALSE,
        },
        {
          id: "income-summary",
          title: incomeSectionMultilanguageLabels(language).summaryIncomeTitle,
          header:  incomeSectionMultilanguageLabels(language).summaryIncomeHeader,
          subHeader:"Please verify the Income below is correct. If any income is incorrect click edit to correct",
          hiddenFromNav:  TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
       }
      ],
      completed: TextProps.VALUE_FALSE,
    },      // index:5
  {

    id: "resources-landing",
    parentsId: "resources-landing",
    title: resourcesDsnapMultilanguageLabels(language).title,
    header: resourcesDsnapMultilanguageLabels(language).header,
    questions: [
      {
        [Props.NAME]: "types",
        [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).subHeader,
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "Cash on Hand",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: "Saving Accounts",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[1],
          },
          {
            [OptionsProps.OPTION_VALUE]: "Checking Accounts",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[2],
          },
        ],
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          resourcesDsnapMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
      },
    ],

    subsections: [
      {
        id: "combined-resources",
        parentsId: "resources-landing",
        title: resourcesDsnapMultilanguageLabels(language).title,
        header: resourcesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [

          {
            [Props.NAME]: "Cash on Hand",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).cashOnHand,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Checking Accounts",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).CheckingAccounts,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Saving Accounts",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).SavingAccounts,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
        ]

       },
       {
        id: "resources-summary",
        parentsId: "resources",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: resourcesDsnapMultilanguageLabels(language).title, //'Resources summary',
        header: resourcesDsnapMultilanguageLabels(language).summaryheader, //'Resources summary',
        subHeader: resourcesDsnapMultilanguageLabels(language).subHeadersummary,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,

  },        //index:6
  {
    id: "expense-landing-page",
    parentsId: "expense-landing-page",
    title: expensesDsnapMultilanguageLabels(language).title,
    header: expensesDsnapMultilanguageLabels(language).header,
    questions: [
      {
      [Props.NAME]: "landing",
      [Props.INLINE_HELP]:
        expensesDsnapMultilanguageLabels(language).dsnap + "<br />" + "<br />"
        + expensesDsnapMultilanguageLabels(language).description +
        "<br />" + "<br />" +
        expensesDsnapMultilanguageLabels(language).SignIn,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        expensesDsnapMultilanguageLabels(language).expenses,
      [Props.MAX_LENGTH]: 35,
    }
    ],
    subsections: [
      {
        id: "expenses-combined",
        parentsId: "expense-landing-page",
        title: expensesDsnapMultilanguageLabels(language).title,
        header: expensesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).typesDisaster,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "Expenses to protect property during disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Expense to repair or replace items for home or self -employement property",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Dependent care due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Food destoryed in disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Funeral/medical expenses due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Moving and storage expenses due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Other disaster-related expenses",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Temporary shelter expenses",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[7],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },

        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "combined-expenses",
        parentsId: "expense-landing-page",
        title: expensesDsnapMultilanguageLabels(language).title,
        header: expensesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [

          {
            [Props.NAME]: "Expenses to protect property during disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).property,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Expense to repair or replace items for home or self -employement property",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).selfEmployement,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },

          {
            [Props.NAME]: "Dependent care due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).dependentCare,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Food destoryed in disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).foodDestoryed,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]:  "Funeral/medical expenses due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).funeralMedical,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Moving and storage expenses due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).movingStorage,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Other disaster-related expenses",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).disaterRelated,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Temporary shelter expenses",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).temporary,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },

        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "expenses-summary",
        parentsId: "expense-landing-page",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: expensesDsnapMultilanguageLabels(language).title, 
        header: expensesDsnapMultilanguageLabels(language).summaryheader, 
        subHeader: expensesDsnapMultilanguageLabels(language).subHeadersummary,
        completed: TextProps.VALUE_FALSE,

      }      
    ],
    completed: TextProps.VALUE_FALSE,
   
  },
    {
      //index:7
      id: "sign-and-submit",
      title: signAndSubmitDsnapMultilanguageLabels(language).title,
      header: signAndSubmitDsnapMultilanguageLabels(language).header,
     //'Sign & submit',
     subHeader: signAndSubmitDsnapMultilanguageLabels(language).signSubmitSub,
      //'You made it through the form! Now for the easy stuff.',
     
    }
]

export default DsnapSections;

export const IncomeSectionTemplate = {
  id: "combined-income",
  parentsId: "income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  hiddenFromNav: TextProps.VALUE_FALSE,
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "UI-TAFDCS",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-WAGES",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RSDI",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-SSI",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-VETBEN",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-INTINC",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-MILPE",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RETFND",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RRRET",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[11],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-LIHEAP",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[12],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[13],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[14],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-ALIMNY",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[15],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-CONTR",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[16],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-ROOMER",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[17],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-BOARD",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[18],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-OTHER",
          [OptionsProps.OPTION_DISPLAY]:
            incomeSectionMultilanguageLabels(language).combineIncomeoptions[19],
        },
      ],
      [OptionsProps.CLEAR_OPTION]:
        incomeSectionMultilanguageLabels(language).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      parentsId: "income",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      originalQuestions: [
        {
          [Props.NAME]: "tanf-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).tanfTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "tanf-gross-income-0",
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "job-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).jobTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
        },
        {
          [Props.NAME]: "social-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).socialTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
        },
        {
          [Props.NAME]: "veteran-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).veteranTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-VETBEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue, //'Add another Unemployment Compensation'
        },
        {
          [Props.NAME]: "workman-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workmanTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
        },
        {
          [Props.NAME]: "interest-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).interestTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).militaryTargetValue, //"Add another Military Allotment"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.MAX_LENGTH]: 12,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).weekMonthOptions, //['Month', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
        },
        {
          [Props.NAME]: "railroad-retirement-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).railroadTargetValue, //"Add another Railroad Retirement"
        },
        {
          [Props.NAME]: "hud-utility-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue, //"Add another HUD Utility Assistance"
        },
        {
          [Props.NAME]: "income-land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).childSuppTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
        },
        {
          [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
        },
        {
          [Props.NAME]: "room-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).roomerTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-ROOMER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
        },
        {
          [Props.NAME]: "board-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).boaderTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).questionButtonLabel, //"Add another Other"
        },
      ],
      questions: [
        {
          [Props.NAME]: "tanf-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-TAFDCS", // incomeSectionMultilanguageLabels(language).tanfTargetValue,//'TANF', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).tanfTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-TAFDCS",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "tanf-gross-income-0",
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).tanfTargetValue, //'Add another TANF'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //incomeSectionMultilanguageLabels(language).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .selfemeploymentTargetValue, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "job-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //incomeSectionMultilanguageLabels(language).jobTargetValue,//'Job Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).jobTargetValue,
          [OptionsProps.TABLE_DSC]: "EI-WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).jobTargetValue, //'Add another Job Income'
        },
        {
          [Props.NAME]: "social-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //incomeSectionMultilanguageLabels(language).socialTargetValue,//'Social Security (SSA / RSDI)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).socialTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).socialTargetValue, //'Add another Social Security (SSA / RSDI)'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // ,incomeSectionMultilanguageLabels(language).SSITargetValue,//'Supplemental Security Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).SSITargetValue, //'Add another SSI'
        },
        {
          [Props.NAME]: "veteran-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBEN", //incomeSectionMultilanguageLabels(language).veteranTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).veteranTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-VETBEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).veteranTargetValue, //'Add another Veteran's Benefits'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,//'Unemployment Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).unEmploymentTargetValue, //'Add another Unemployment Compensation'
        },
        {
          [Props.NAME]: "workman-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //incomeSectionMultilanguageLabels(language).workmanTargetValue, //'Workman's Compensation',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).workmanTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).workmanTargetValue, // 'Add another Workman's Compensation'
        },
        {
          [Props.NAME]: "interest-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-INTINC", // incomeSectionMultilanguageLabels(language).interestTargetValue,//"Interest Dividends from Stock and Bonds",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).interestTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-INTINC",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).interestTargetValue, //"Add another Interest Dividends from Stock and Bonds"
        },
        {
          [Props.NAME]: "military-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-MILPE", //incomeSectionMultilanguageLabels(language).militaryTargetValue,//'Military Allotment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).militaryTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-MILPE",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).militaryTargetValue, //"Add another Military Allotment"
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RETFND", //incomeSectionMultilanguageLabels(language).pensionTargetValue,//"Pension or Retirement Income",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RETFND",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.MAX_LENGTH]: 12,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).weekMonthOptions, //['Month', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).pensionTargetValue, //Add another Pension or Retirement Income
        },
        {
          [Props.NAME]: "railroad-retirement-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RRRET", //incomeSectionMultilanguageLabels(language).railroadTargetValue,//"Railroad Retirement",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).railroadTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RRRET",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).railroadTargetValue, //"Add another Railroad Retirement"
        },
        {
          [Props.NAME]: "hud-utility-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-LIHEAP", //incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,//"HUD Utility Assistance",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-LIHEAP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).hudUtilityTargetValue, //"Add another HUD Utility Assistance"
        },
        {
          [Props.NAME]: "income-land-rental-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //incomeSectionMultilanguageLabels(language).incomeLandRentalTargetValue,//"Income from Land Rental or Rental Property",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel, //'per',
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language)
              .incomeLandRentalTargetValue, //"Add another Income from Land Rental or Rental Property"
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //incomeSectionMultilanguageLabels(language).childSuppTargetValue,//'Child Support',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).childSuppTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "alimony-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-ALIMNY", //incomeSectionMultilanguageLabels(language).alimonyTargetValue,//'Alimony',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).alimonyTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-ALIMNY",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).alimonyTargetValue, //'Add another Alimony'
        },
        {
          [Props.NAME]: "contribution-friends-ralatives-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CONTR", //incomeSectionMultilanguageLabels(language).cotributionsTargetValue,//'Contributions from Friends or Relatives',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CONTR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).cotributionsTargetValue, //'Add another Contributions from Friends or Relatives'
        },
        {
          [Props.NAME]: "room-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-ROOMER", //incomeSectionMultilanguageLabels(language).roomerTargetValue,//'Room and Board Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).roomerTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-ROOMER",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).roomerTargetValue, //'Add another Room and Board Income'
        },
        {
          [Props.NAME]: "board-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-BOARD", //incomeSectionMultilanguageLabels(language).boaderTargetValue,//'Boarder Income',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).boaderTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EI-BOARD",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).costBetween,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).addAnotherButtonLabel +
            " " +
            incomeSectionMultilanguageLabels(language).boaderTargetValue, //'Add another Boarder Income'
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //incomeSectionMultilanguageLabels(language).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
            incomeSectionMultilanguageLabels(language).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "UI-OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              incomeSectionMultilanguageLabels(language).amountErrMsg,
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]:
              incomeSectionMultilanguageLabels(language).unitsPerLabel,
            [UnitsProps.UNITS]:
              incomeSectionMultilanguageLabels(language).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ amount: "" }],
          [OptionsProps.ANSWER_VALUE]: [{ amount: "" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            incomeSectionMultilanguageLabels(language).questionButtonLabel, //"Add another Other"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const resourcesSectionTemplate = {
    id: "resources-landing",
    parentsId: "resources-landing",
    title: resourcesDsnapMultilanguageLabels(language).title,
    header: resourcesDsnapMultilanguageLabels(language).header,
    questions: [
      {
        [Props.NAME]: "types",
        [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).subHeader,
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "Cash on Hand",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: "Saving Accounts",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[1],
          },
          {
            [OptionsProps.OPTION_VALUE]: "Checking Accounts",
            [OptionsProps.OPTION_DISPLAY]:
              resourcesDsnapMultilanguageLabels(language)
                .combineResourcesoptions[2],
          },
        ],
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          resourcesDsnapMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
      },
    ],

    subsections: [
      {
        id: "combined-resources",
        parentsId: "resources-landing",
        title: resourcesDsnapMultilanguageLabels(language).title,
        header: resourcesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [

          {
            [Props.NAME]: "Cash on Hand",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).cashOnHand,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Checking Accounts",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).CheckingAccounts,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Saving Accounts",
            [Props.LABEL]: resourcesDsnapMultilanguageLabels(language).SavingAccounts,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).enterAmount,
            [Props.MAX_LENGTH]: 13,
          },
        ]

       },
       {
        id: "resources-summary",
        parentsId: "resources",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: resourcesDsnapMultilanguageLabels(language).title, //'Resources summary',
        header: resourcesDsnapMultilanguageLabels(language).summaryheader, //'Resources summary',
        subHeader: resourcesDsnapMultilanguageLabels(language).subHeadersummary,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_TRUE

  
};

export const expensesSectionTemplate = {
  
    id: "expense-landing-page",
    parentsId: "expense-landing-page",
    title: expensesDsnapMultilanguageLabels(language).title,
    header: expensesDsnapMultilanguageLabels(language).header,
    questions: [
      {
      [Props.NAME]: "landing",
      [Props.INLINE_HELP]:
        expensesDsnapMultilanguageLabels(language).dsnap + "<br />" + "<br />"
        + expensesDsnapMultilanguageLabels(language).description +
        "<br />" + "<br />" +
        expensesDsnapMultilanguageLabels(language).SignIn,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        expensesDsnapMultilanguageLabels(language).expenses,
      [Props.MAX_LENGTH]: 35,
    }
    ],
    subsections: [
      {
        id: "expenses-combined",
        parentsId: "expense-landing-page",
        title: expensesDsnapMultilanguageLabels(language).title,
        header: expensesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).typesDisaster,
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "Expenses to protect property during disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Expense to repair or replace items for home or self -employement property",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Dependent care due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[2],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Food destoryed in disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Funeral/medical expenses due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Moving and storage expenses due to disaster",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Other disaster-related expenses",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "Temporary shelter expenses",
                [OptionsProps.OPTION_DISPLAY]:
                  expensesDsnapMultilanguageLabels(language)
                    .combineExpensesoptions[7],
              },
            ],
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              resourcesDsnapMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
          },

        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "combined-expenses",
        parentsId: "expense-landing-page",
        title: expensesDsnapMultilanguageLabels(language).title,
        header: expensesDsnapMultilanguageLabels(language).header,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [

          {
            [Props.NAME]: "Expenses to protect property during disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).property,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Expense to repair or replace items for home or self -employement property",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).selfEmployement,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },

          {
            [Props.NAME]: "Dependent care due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).dependentCare,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Food destoryed in disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).foodDestoryed,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]:  "Funeral/medical expenses due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).funeralMedical,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Moving and storage expenses due to disaster",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).movingStorage,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Other disaster-related expenses",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).disaterRelated,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },
          {
            [Props.NAME]: "Temporary shelter expenses",
            [Props.LABEL]: expensesDsnapMultilanguageLabels(language).temporary,
            [Props.TYPE]: Types.INPUT_DOLLAR,
            [Props.REQUIRED]: TextProps.VALUE_TRUE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]:
              expensesDsnapMultilanguageLabels(language).expenses,
            [Props.MAX_LENGTH]: 13,
          },

        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "expenses-summary",
        active: TextProps.VALUE_FALSE,
        hiddenFromNav: true,
        title: expensesDsnapMultilanguageLabels(language).title, 
        header: expensesDsnapMultilanguageLabels(language).summaryheader, 
        subHeader: expensesDsnapMultilanguageLabels(language).subHeadersummary,
        completed: TextProps.VALUE_FALSE,

      }      
    ],
    completed: TextProps.VALUE_TRUE,  
  
};

export const emptyExpensesSectionTemplate = {
  id: "expense-landing-page",
  parentsId: "expense-landing-page",
  title: expensesDsnapMultilanguageLabels(language).title,
  header: expensesDsnapMultilanguageLabels(language).header,
  questions: [
    {
    [Props.NAME]: "landing",
    [Props.INLINE_HELP]:
      expensesDsnapMultilanguageLabels(language).dsnap + "<br />" + "<br />"
      + expensesDsnapMultilanguageLabels(language).description +
      "<br />" + "<br />" +
      expensesDsnapMultilanguageLabels(language).SignIn,
    [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
    [Props.TYPE]: Types.SPECIAL_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_FALSE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]:
      expensesDsnapMultilanguageLabels(language).expenses,
    [Props.MAX_LENGTH]: 35,
  }
  ],
  subsections: [
    {
      id: "expenses-combined",
      parentsId: "expense-landing-page",
      title: expensesDsnapMultilanguageLabels(language).title,
      header: expensesDsnapMultilanguageLabels(language).header,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "types",
          [Props.LABEL]: expensesDsnapMultilanguageLabels(language).typesDisaster,
          [Props.TYPE]: Types.CHECKBOX,
          [OptionsProps.OPTIONS]: [
            {
              [OptionsProps.OPTION_VALUE]: "Expenses to protect property during disaster",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[0],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Expense to repair or replace items for home or self -employement property",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[1],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Dependent care due to disaster",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[2],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Food destoryed in disaster",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[3],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Funeral/medical expenses due to disaster",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[4],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Moving and storage expenses due to disaster",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[5],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Other disaster-related expenses",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[6],
            },
            {
              [OptionsProps.OPTION_VALUE]: "Temporary shelter expenses",
              [OptionsProps.OPTION_DISPLAY]:
                expensesDsnapMultilanguageLabels(language)
                  .combineExpensesoptions[7],
            },
          ],
          [Props.REQUIRED]: TextProps.VALUE_FALSE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            resourcesDsnapMultilanguageLabels(language).optionErrMsg, //'Please select an option below'
        },

      ],
     
    },
  
],
  completed: TextProps.VALUE_TRUE,
};

export const snapHouseholdTemplate = {
  id: "household-member",
  parentsId: "My-household",
  title: houseHoldSectionMultilanguageLabels(language).houseMemberTitle,
  header: houseHoldSectionMultilanguageLabels(language).submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).firstErrmsg, //'Please enter first name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).firstNameErrMsgLength,
      [Props.MAX_LENGTH]: 15,
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseMiddleName, //'Please enter middle name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).middleNameErrMsgLength,
        [Props.MAX_LENGTH]: 15,
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.SPECIAL_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_TRUE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).enterLastname, //'Please enter last name'
      [Props.ERROR_MESSAGE_LENGTH]:
        houseHoldSectionMultilanguageLabels(language).lastNameErrMsgLength,
        [Props.MAX_LENGTH]: 15,
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: MyhouseholdDsnapMultilanguageLabels(language).memberSuffixLabel,
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]:
      MyhouseholdDsnapMultilanguageLabels(language).memberSuffixOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).houseDobAlertMsg,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]:
        houseHoldSectionMultilanguageLabels(language).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]:
        houseHoldSectionMultilanguageLabels(language).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).genderErrmsg,
    },
    
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: houseHoldSectionMultilanguageLabels(language).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [Props.CHECKED]: TextProps.VALUE_FALSE,
      [InputProps.CLEAR_LABEL]:
        houseHoldSectionMultilanguageLabels(language).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]:
        houseHoldSectionMultilanguageLabels(language).ssnErrLabel,
        [Props.INLINE_HELP]:houseHoldSectionMultilanguageLabels(language).inlineHelpSSN

    },
   
    {
      [Props.NAME]: "race",
      [Props.LABEL]: aboutMeMultilanguageLabels(language).raceLabel,
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).raceHelp,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "AMIALN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "ASIAN",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "BLCAFR",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "NTHPCI",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WHITE",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "Decline to answer",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]:
            aboutMeMultilanguageLabels(language).snapRaceOptions[6],
        },
      ],
      [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: "Please choose race",
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "text-area",
              [Props.TYPE]: Types.INPUT_TEXT_AREA,
              [Props.MAX_LENGTH]: 250,
              [Props.MIN_LENGTH]: 3,
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]:
                aboutMeMultilanguageLabels(language).otherRaceErrmsg,
              [Props.ERROR_MESSAGE_LENGTH]:
                aboutMeMultilanguageLabels(language)
                  .otherRaceErrMsgLength,
            },
          ],
        },
      ],
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: aboutMeMultilanguageLabels(language).snpaNote,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};
  

export const incomeSummaryTemplate = {
  id: "income-summary",
  parentsId: "income",
  active:TextProps.VALUE_FALSE,
  hiddenFromNav: true,
  title: incomeSectionMultilanguageLabels(language).incomeSummaryTitle, //'Income summary',
  header: incomeSectionMultilanguageLabels(language).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const myHouseHoldTemplate = {
  id: "my-household",
  title: MyhouseholdDsnapMultilanguageLabels(language).title,
  header: MyhouseholdDsnapMultilanguageLabels(language).header,
  subHeader:
  MyhouseholdDsnapMultilanguageLabels(language).memberSubHeader,

    questions: [
      {
        [Props.NAME]: "numberInHousehold",
        [Props.LABEL]:
        MyhouseholdDsnapMultilanguageLabels(language).howManyLabel,
        [Props.TYPE]: Types.NUMBERS,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
        MyhouseholdDsnapMultilanguageLabels(language).numOfPplErrMsg,
        [Props.INLINE_HELP]:
        MyhouseholdDsnapMultilanguageLabels(language).dsnapHouseHelp,
        [Props.NUMBER_TEXTBOX_LABEL]:
        MyhouseholdDsnapMultilanguageLabels(language).numOfPplText,
        //'If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.',
      },
     
  ],
}