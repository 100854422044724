import * as Types from "../../utils/constants/types";
import * as Props from "../../utils/components/shared";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as providerConst from "./providerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import * as SelectProps from "../../utils/constants/select";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

const CLIENT_FILTER_URGENT_VALUE =
  providerConst.providerConst(selectedLanguage).urgent,
  CLIENT_FILTER_URGENT_DISPLAY =
    providerConst.providerConst(selectedLanguage).needsAttention,
  CLIENT_FILTER_ELIBILITY_VALUE =
    providerConst.providerConst(selectedLanguage).elibility,
  CLIENT_FILTER_ELIBILITY_DISPLAY =
    providerConst.providerConst(selectedLanguage).determiningEligibility,
  CLIENT_FILTER_UNSUBMITTED_VALUE =
    providerConst.providerConst(selectedLanguage).unsubmitted,
  CLIENT_FILTER_UNSUBMITTED_DISPLAY =
    providerConst.providerConst(selectedLanguage).unsubmittedApplications,
  CLIENT_FILTER_EXPIRING_ACCESS_VALUE =
    providerConst.providerConst(selectedLanguage).expiring,
  CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY =
    providerConst.providerConst(selectedLanguage).expiringAccountAccess,
  CLIENT_FILTER_CLIENT_INFORMATION_DISPLAY =
    providerConst.providerConst(selectedLanguage).clientInfo,
  CLIENT_FILTER_CASE_STATUS_DISPLAY =
    providerConst.providerConst(selectedLanguage).caseStatus,
  CLIENT_FILTER_SSN_DISPLAY =
    providerConst.providerConst(selectedLanguage).ssn,
  CLIENT_FILTER_EMAIL_DISPLAY =
    providerConst.providerConst(selectedLanguage).email,
  CLIENT_FILTER_CASENO_DISPLAY =
    providerConst.providerConst(selectedLanguage).caseNumber,
  CLIENT_FILTER_CLIENTID_DISPLAY =
    providerConst.providerConst(selectedLanguage).dta,
  CLIENT_FILTER_SNAP_DISPLAY =
    providerConst.providerConst(selectedLanguage).snapLabel,
  CLIENT_FILTER_TANF_DISPLAY =
    providerConst.providerConst(selectedLanguage).tanfLabel;

export const CLIENT_FILTERS = {
  [Props.NAME]: "clientFilters",
  [Props.LABEL]: providerConst.providerConst(selectedLanguage).clientFilters,
  [Props.TYPE]: Types.CHECKBOX,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]:
        providerConst.providerConst(selectedLanguage).urgent,
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).needsAttention,
    },
    {
      [OptionsProps.OPTION_VALUE]:
        providerConst.providerConst(selectedLanguage).elibility,
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).determiningEligibility,
    },
    {
      [OptionsProps.OPTION_VALUE]:
        providerConst.providerConst(selectedLanguage).unsubmitted,
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).unsubmittedApplications,
    },
    {
      [OptionsProps.OPTION_VALUE]:
        providerConst.providerConst(selectedLanguage).expiring,
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).expiringAccountAccess,
    },
  ],
};

export const SEARCH_CLIENTS_HOME = {
  [Props.NAME]: "searchClients",
  [Props.LABEL]: providerConst.providerConst(selectedLanguage).searchClients,
  [InputProps.PLACEHOLDER]:
    providerConst.providerConst(selectedLanguage).searchClients,
  [Props.TYPE]: Types.INPUT_SEARCH,
};

export const SEARCH_CLIENTS_ALL = {
  [Props.NAME]: "searchAllClients",
  [Props.LABEL]: providerConst.providerConst(selectedLanguage).searchAllClients,
  [InputProps.PLACEHOLDER]:
    providerConst.providerConst(selectedLanguage).searchAllClients,
  [Props.TYPE]: Types.INPUT_SEARCH,
};

export const accountSearchQuestion = {
  [Props.NAME]: "accountSearchType",
  [Props.LABEL]:
    providerConst.providerConst(selectedLanguage).accountSearchType,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]: "web",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).web,
    },
    {
      [OptionsProps.OPTION_VALUE]: "tanfweb",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).tanf,
    },
    {
      [OptionsProps.OPTION_VALUE]: "clientname",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).clientName,
    },
    {
      [OptionsProps.OPTION_VALUE]: "ssn",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).ssn,
    },
    {
      [OptionsProps.OPTION_VALUE]: "clientemail",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).email,
    },
    {
      [OptionsProps.OPTION_VALUE]: "applicationdate",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).applicationdate,
    },
    {
      [OptionsProps.OPTION_VALUE]: "dtaid",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).dta,
    },
    {
      [OptionsProps.OPTION_VALUE]: "casenumber",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).caseNumber,
    },
    {
      [OptionsProps.OPTION_VALUE]: "ebt",
      [OptionsProps.OPTION_DISPLAY]:
        providerConst.providerConst(selectedLanguage).ebt,
    },
  ],
  value: "",
  [OptionsProps.OPTIONS_PER_ROW]: 1,
  [OptionsProps.CONDITIONALS]: [
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "web",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "web",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).applicationNumber,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "tanfweb",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "tanfweb",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).tanfapplicationnumber,
          [Props.TYPE]: Types.INPUT_TEXT,
        },

      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "clientname",
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.TYPE]: Types.TEXT,
          [TextProps.VALUE]:providerConst.providerConst(selectedLanguage).clientTitle,
        },
        {
          [Props.NAME]: "firstName",
          [Props.LABEL]: providerConst.providerConst(selectedLanguage).clientFirstName,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
        {
          [Props.NAME]: "lastName",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).clientLastName,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "applicationdate",
      [OptionsProps.OPTIONS_PER_ROW]: 2,
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.TYPE]: Types.TEXT,
          [TextProps.VALUE]:providerConst.providerConst(selectedLanguage).applicationTitle,
        },
        {
          [Props.NAME]: "applicationNameday",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).applicationNameday,
          [Props.TYPE]: Types.INPUT_TEXT,
          [Props.TYPE]: Types.INPUT_TEXT,
          /*   [SelectProps.OPTIONS]: [
              "All",
              "Verification document",
              "Voluntary request to stop benefits",
              "Request for authorized representative",
              "Request for replacement due to a household misfortune",
            ],  */
        },
        {
          [Props.NAME]: "applicationNameMonth",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).applicationNameMonth,
          [Props.TYPE]: Types.SELECT,
          [SelectProps.OPTIONS]:  providerConst.providerConst(selectedLanguage).monthList,
        },
        {
          [Props.NAME]: "applicationNameyear",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).applicationNameYear,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "clientemail",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "clientemail",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).emailaddress,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },

    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "casenumber",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "casenumber",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).clientcasenumber,
          [Props.TYPE]: Types.INPUT_TEXT,
        },

      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "dtaid",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "dtaid",
          [Props.LABEL]: providerConst.providerConst(selectedLanguage).dtaId,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ssn",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "ssnNumber",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).ssnNumber,
          [Props.TYPE]: Types.INPUT_SSN,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ebt",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "ebtNumber",
          [Props.LABEL]:
            providerConst.providerConst(selectedLanguage).ebtNumber,
          [Props.TYPE]: Types.INPUT_EBT,
        },
      ],
    },
  ],
};

export {
  CLIENT_FILTER_URGENT_VALUE,
  CLIENT_FILTER_URGENT_DISPLAY,
  CLIENT_FILTER_ELIBILITY_VALUE,
  CLIENT_FILTER_ELIBILITY_DISPLAY,
  CLIENT_FILTER_UNSUBMITTED_VALUE,
  CLIENT_FILTER_UNSUBMITTED_DISPLAY,
  CLIENT_FILTER_EXPIRING_ACCESS_VALUE,
  CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY,
  CLIENT_FILTER_CLIENT_INFORMATION_DISPLAY,
  CLIENT_FILTER_CASE_STATUS_DISPLAY,
  CLIENT_FILTER_SSN_DISPLAY,
  CLIENT_FILTER_EMAIL_DISPLAY,
  CLIENT_FILTER_CASENO_DISPLAY,
  CLIENT_FILTER_CLIENTID_DISPLAY,
  CLIENT_FILTER_SNAP_DISPLAY,
  CLIENT_FILTER_TANF_DISPLAY,
};
