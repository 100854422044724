import React, { Component } from "react";

//Main App Class
class APIError extends Component {
  render() {
    return (
      <div>
        <h1>Something went wrong. Please contact dhrapphelpdesk@mass.gov</h1>
      </div>
    );
  }
}

export default APIError;
