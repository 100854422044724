import { object } from "prop-types";
import * as TextProps from "../../utils/constants/text";
import { loginScreenData_pwd } from "../../utils/constants/appConfigConstants";

export function applyDsnapReducer(state = {}, action) {
  switch (action.type) {

    case "APPLY_DSNAP_PAGES_FULLFILLED": {
      var newState = Object.assign({}, state);
      newState.sections = action.payload;
      newState.activeSectionId = action.payload[0].activeSectionId;
      newState.prevSections = {
        activeSectionId: "before-you-start",
        id: 0,
        date: 0,
        sections: action.payload,
      };
      return newState;
    }

    // Assiging values for applySnapPagesActionValidated from applysnapAction 
    case "APPLY_DSNAP_PAGES_FULLFILLED_VALIDATED": {
      var newState = Object.assign({}, state);
      newState.sections = action.payload;
      newState.activeSectionId = action.payload[0].activeSectionId;
      newState.snapSaveContinue = TextProps.VALUE_FALSE;
      newState.prevSections = {
        activeSectionId: "before-you-start",
        id: newState.prevSections ? newState.prevSections.id : 0,
        date: newState.prevSections ? newState.prevSections.date : 0,
        sections: action.payload,
      };
      return newState;
    }

    case "FILL_INPUT_DSNAPVALUES_STATE": {
      return Object.assign({}, state);
    }
   
    case "APPEND_INCOME":{
      const newState = { ...state };
      newState.sections = newState.sections.map(sec =>
        sec.id === "income" ? action.payload : sec
      );
      return newState;
    }
  
    case "APPEND_RESOURCES": {     
      const newState = { ...state };   
      newState.sections = newState.sections.map(sec =>
        sec.id === "resources-landing" ? action.payload : sec
      );
      return newState;
    }

    case "APPEND_EXPENSES": {  
      const newState = { ...state };    
      newState.sections = newState.sections.map(sec =>
        sec.id === "expense-landing-page" || sec.id === "expenses-combined" ? action.payload : sec
      );
      return newState;
    }

    case "APPEND_DSNAP_HOME_HOLD_DATA": {
      const newState = Object.assign({}, state);
      if (action.payload && action.payload.length){
      action.payload.forEach((element) => {
        element.questions.forEach((question) => {
         
          if (question.name === "firstName") {
            question.maxLength = 15;
          }
          if (question.name === "middleName") {
            question.maxLength = 15;
          }
          if (question.name === "lastName") {
            question.maxLength = 20;
          }
          
        });
      });
    
      newState.sections.filter((sec) => sec.id === "my-household")[0].subsections = action.payload;
    }
      return newState;
    }
  
    case "SET_HOUSEHOLD_DATA": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "income")[0].questions[0].options = action.payload;
      return newState;
    }

    case "DSNAP_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.dsnapDataLoading = TextProps.VALUE_TRUE;
      newState.dsnapApplyFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "DSNAP_DATA_SUCCESS": {
      let newState = Object.assign({}, state);
      newState.dsnapApplySuccess = action.payload.data;
      newState.dsnapDataLoading = TextProps.VALUE_FALSE;
      newState.dsnapApplyFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "DSNAP_DATA_FAILURE": {
      const newState = Object.assign({}, state);
      newState.dsnapApplyFailure = TextProps.VALUE_TRUE;
      newState.dsnapDataLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "APPEND_LAST_KNOWN_LOCATION_DSNAP": {
      const newState = Object.assign({}, state);
      newState.sections.filter(sec => sec.id === "sign-and-submit")[0].partialSave = TextProps.VALUE_TRUE;
      newState.sections.filter(sec => sec.id === "sign-and-submit")[0].lastKnownLocation = action.payload;
      return newState;
    }

    case "COUNTY_LIST_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.countyList = action.payload.data;
      // newState.sections.filter((sec) => sec.id === "before-you-start").questions[0].options = countyDesc;
      return newState;
    }
  
    case "APPEND_REQUIRED_FIELD_DSNAP": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) =>sec.id ==="my-household")[0].questions = action.payload;
      return newState;
    }

    case "LANGUAGE_MODIFIED": {
      var newState = Object.assign({}, state);
      newState.islanguageChanged = action.payload.value;
      newState.isDelete = action.payload.isDelete,
      newState.isCancel = action.payload.isCancel,
      newState.allowLanguageChange = action.payload.allowLanguageChange

      return newState;
    }
    // Assiging values for applyAddressStorageAction from applyDsnapAction  
    case "DSNAP_ADDRESS_STORAGE": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "head-of-household-info")[0].residentailAddressStored = action.payload[0];
      newState.sections.filter((sec) => sec.id === "head-of-household-info")[0].mailingAddressStored = action.payload[1];
      newState.sections.filter((sec) => sec.id === "head-of-household-info")[0].temporaryAddressStored = action.payload[2];
      return newState;
    }

    // Assiging values for checkAddressValidation from applysnapAction 
    case "ADDRESS_VALIDATION": {
      const newState = Object.assign({}, state);
      newState.melissaAddress = action.payload.data;
      return newState;
    }
    
     case "APPEND_INCOME_SECTION_DSNAP": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) =>sec.id ==="income")[0].subsections = action.payload;
      return newState;
    }
    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      applyInit.inProgressSaveErrorState = TextProps.VALUE_FALSE;
      applyInit.inProgressSaveErrorMessage = "";
      return applyInit;
    }

    default:
      return Object.assign({}, state);
  }
}
