import * as Types from "../../utils/constants/types";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SharedProps from "../../utils/constants/shared";
import * as BenefitProps from "../../utils/constants/benefit";
import * as consumerLabels from "./consumerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const consumerAboutMeInfo = [
  {
    [SharedProps.ID]: "aboutMeRowAddress",
    [SharedProps.EDITING_LABEL]:
      consumerLabels.consumerConst(selectedLanguage).editAddress,
    [SharedProps.FIELDS]: [
      {
        [Props.NAME]: "residentialAddress",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).residentialAddress,
        [Props.TYPE]: Types.ADDRESS,
        [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_TRUE,
        value: {
          addressLine1: "",
          addressLine2: "",
          street: "",
          city: "",
          state: "",
          zipCode: "",
          verified: "",
          countyCode: "",
          county: ""
        },
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          consumerLabels.consumerConst(selectedLanguage).zipCodeErrmsg,
      },
      {
        [Props.NAME]: "is-also-mailing",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).mailingAddress,
        [Props.TYPE]: Types.ADDRESS,
        [Props.IS_RESIDENTIAL_ADDRESS]: TextProps.VALUE_FALSE,
        value: {
          addressLine1: "",
          addressLine2: "",
          street: "",
          city: "",
          state: "",
          zipCode: "",
          verified: "",
          countyCode: "",
          county: ""
        },
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          consumerLabels.consumerConst(selectedLanguage).zipCodeErrmsg,
      },
    ],
  },
  {
    [SharedProps.ID]: "aboutMeRowPhone",
    [SharedProps.EDITING_LABEL]:
      consumerLabels.consumerConst(selectedLanguage).editPhnone,
    [SharedProps.FIELDS]: [
      {
        [Props.NAME]: "phoneNumber",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).phoneNumber,
        [Props.TYPE]: Types.INPUT_PHONE,
        [Props.INLINE_HELP]:
          consumerLabels.consumerConst(selectedLanguage).phoneInlineText,
        value: "6175555678",
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          consumerLabels.consumerConst(selectedLanguage).phoneNumErrmsg,
      },
      {
        [Props.NAME]: "allowTextNotification",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).allowTxtMsg,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          consumerLabels.consumerConst(selectedLanguage).yes,
          consumerLabels.consumerConst(selectedLanguage).no,
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        value: TextProps.VALUE_YES,
        [Props.ERROR]: TextProps.VALUE_FALSE,
      },
    ],
  },
  {
    [SharedProps.ID]: "aboutMeRowEmail",
    [SharedProps.EDITING_LABEL]:
      consumerLabels.consumerConst(selectedLanguage).editEmail,
    [SharedProps.FIELDS]: [
      {
        [Props.NAME]: "emailAddress",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).emailAddress,
        [Props.TYPE]: Types.INPUT_TEXT,
        [Props.MAX_LENGTH]: 100,
        [Props.INLINE_HELP]:
          consumerLabels.consumerConst(selectedLanguage).emailHelpText,
        value: "joseph.soosai@conduent.com",
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          consumerLabels.consumerConst(selectedLanguage).emailAddressErrmsg,
      },
      {
        [Props.NAME]: "allowEmailNotification",
        [Props.LABEL]:
          consumerLabels.consumerConst(selectedLanguage).emailNotification,
        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          consumerLabels.consumerConst(selectedLanguage).yes,
          consumerLabels.consumerConst(selectedLanguage).no,
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        value: TextProps.VALUE_YES,
        [Props.ERROR]: TextProps.VALUE_FALSE,
      },
    ],
  },
];

export const consumerBenefits = [
  {
    [SharedProps.ID]: "sharonCash",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_CASH,
    [BenefitProps.AMOUNT]: 30.03,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
    [BenefitProps.EFFECTIVE_DATE]: new Date(2017, 11, 12),
    [BenefitProps.START_DATE]: new Date(2015, 9, 15),
    [BenefitProps.RE_EVAL_DATE]: new Date(2018, 8, 12),
    [BenefitProps.NEXT_BENEFIT_DATE]: new Date(2017, 9, 10),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonCash-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
      {
        [SharedProps.ID]: "sharonCash-member-2",
        [SharedProps.FIRST_NAME]: "Sonia",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1958, 7, 14),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
      },
      {
        [SharedProps.ID]: "sharonCash-member-3",
        [SharedProps.FIRST_NAME]: "Victor",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1998, 4, 3),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_INELIGIBLE,
        [BenefitProps.STATUS_REASON]: "Family Cap Child",
      },
    ],
    [BenefitProps.CASE_MANAGER]: {
      [SharedProps.FIRST_NAME]: "Stacey",
      [SharedProps.LAST_NAME]: "Socialworker",
      [SharedProps.PHONE]: "8773821212",
    },
  },
  {
    [SharedProps.ID]: "sharonSnap",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_SNAP,
    [BenefitProps.AMOUNT]: 217.38,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
    [BenefitProps.EFFECTIVE_DATE]: new Date(2017, 11, 12),
    [BenefitProps.START_DATE]: new Date(2015, 9, 15),
    [BenefitProps.RE_EVAL_DATE]: new Date(2017, 11, 15),
    [BenefitProps.NEXT_BENEFIT_DATE]: new Date(2017, 9, 10),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonSnap-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
      {
        [SharedProps.ID]: "sharonSnap-member-2",
        [SharedProps.FIRST_NAME]: "Sonia",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1958, 7, 14),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
      },
      {
        [SharedProps.ID]: "sharonSnap-member-3",
        [SharedProps.FIRST_NAME]: "Victor",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1998, 4, 3),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_INELIGIBLE,
        [BenefitProps.STATUS_REASON]: "Family Cap Child",
      },
    ],
    [BenefitProps.CASE_MANAGER]: {
      [SharedProps.FIRST_NAME]: "Stacey",
      [SharedProps.LAST_NAME]: "Socialworker",
      [SharedProps.PHONE]: "8773821212",
    },
  },
  {
    [SharedProps.ID]: "sharonSnapRecert",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_SNAP,
    [BenefitProps.AMOUNT]: 217.38,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
    [BenefitProps.EFFECTIVE_DATE]: new Date(2017, 11, 12),
    [BenefitProps.START_DATE]: new Date(2015, 9, 15),
    [BenefitProps.RE_CERT_DATE]: new Date(2017, 9, 19),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonSnapRecert-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
      {
        [SharedProps.ID]: "sharonSnapRecert-member-2",
        [SharedProps.FIRST_NAME]: "Sonia",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1958, 7, 14),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
      },
      {
        [SharedProps.ID]: "sharonSnapRecert-member-3",
        [SharedProps.FIRST_NAME]: "Victor",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1998, 4, 3),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_INELIGIBLE,
        [BenefitProps.STATUS_REASON]: "Family Cap Child",
      },
    ],
    [BenefitProps.CASE_MANAGER]: {
      [SharedProps.FIRST_NAME]: "Stacey",
      [SharedProps.LAST_NAME]: "Socialworker",
      [SharedProps.PHONE]: "8773821212",
    },
  },
  {
    [SharedProps.ID]: "sharonSnapPending",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_SNAP,
    [BenefitProps.AMOUNT]: 94.2,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
    [BenefitProps.APP_SUBMITTED_DATE]: new Date(2017, 8, 5),
    [BenefitProps.APP_EXPIRATION_DATE]: new Date(2018, 0, 1),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonSnapRecert-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
      {
        [SharedProps.ID]: "sharonSnapRecert-member-2",
        [SharedProps.FIRST_NAME]: "Sonia",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1958, 7, 14),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
      },
      {
        [SharedProps.ID]: "sharonSnapRecert-member-3",
        [SharedProps.FIRST_NAME]: "Victor",
        [SharedProps.LAST_NAME]: "User",
        [SharedProps.DOB]: new Date(1998, 4, 3),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
      },
    ],
    [BenefitProps.CASE_MANAGER]: {
      [SharedProps.FIRST_NAME]: "Stacey",
      [SharedProps.LAST_NAME]: "Socialworker",
      [SharedProps.PHONE]: "8773821212",
    },
  },
  {
    [SharedProps.ID]: "sharonSnapDenied",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_SNAP,
    [BenefitProps.AMOUNT]: 0,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_DENIED,
    [BenefitProps.STATUS_REASON]: "Didn't qualify to receive benefits",
    [BenefitProps.CLOSED_DATE]: new Date(2017, 8, 10),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonSnapDenied-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_DENIED,
        [BenefitProps.STATUS_REASON]: "Didn't qualify to receive benefits",
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
    ],
  },
  {
    [SharedProps.ID]: "sharonSnapClosed",
    [BenefitProps.TYPE]: BenefitProps.BENEFIT_TYPE_SNAP,
    [BenefitProps.AMOUNT]: 0,
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_CLOSED,
    [BenefitProps.STATUS_REASON]: "Voluntary request for stop of benefits",
    [BenefitProps.CLOSED_DATE]: new Date(2017, 8, 10),
    [BenefitProps.HOUSEHOLD_MEMBERS]: [
      {
        [SharedProps.ID]: "sharonSnapClosed-member-1",
        [SharedProps.FIRST_NAME]: "Sharon",
        [SharedProps.LAST_NAME]: "Lee",
        [SharedProps.DOB]: new Date(1988, 0, 9),
        [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_CLOSED,
        [BenefitProps.STATUS_REASON]: "Voluntary request for stop of benefits",
        [BenefitProps.HEAD_OF_HOUSEHOLD]: TextProps.VALUE_TRUE,
      },
    ],
    [BenefitProps.CASE_MANAGER]: {
      [SharedProps.FIRST_NAME]: "Stacey",
      [SharedProps.LAST_NAME]: "Socialworker",
      [SharedProps.PHONE]: "8773821212",
    },
  },
];

export const uploadDocumentAboutOtherMembers = [
  {
    [OptionsProps.OPTION_VALUE]: "householdMemberId1",
    [OptionsProps.OPTION_DISPLAY]: "Sharon Lee (9.Jan.1988)",
  },
  {
    [OptionsProps.OPTION_VALUE]: "householdMemberId2",
    [OptionsProps.OPTION_DISPLAY]: "Michael Lee (12.Mar.1954)",
  },
  {
    [OptionsProps.OPTION_VALUE]: "householdMemberId3",
    [OptionsProps.OPTION_DISPLAY]: "Nora Lee (16.Dec.1955)",
  },
];
