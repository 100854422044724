import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ListItem from "./listItem";
import { addStringIf, tryCall } from "../../components/utilities/controls";
import { componentOfType } from "../../components/utilities/proptypes";
import { consumerConst } from "../../../pages/consumer/consumerText";
import * as helperFunction from "../../components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

/*
  List
  ----

  # Purpose:
  To render a `List` of `ListItems` and provide an interface for trigger loading of additional items

  # Props:
  doLoadMore: Optional, function to triggered when more items need to be added to the list,
              called without arguments and returns a promise without a payload on either
              success or failure
  className: Optional, string classes for the container
  noneLabel: Optional, string label for when the list has no items
  noneClass: Optional, string classes for the none label
  moreLabel: Optional, string label for the control to load more items
  moreClass: Optional, string classes for the more label
  loadingLabel: Optional, string label for the loading message
  loadingClass: Optional, string classes for the loading message
  errorLabel: Optional, string label for the error message
  errorClass: Optional, string classes for the error message
  disabled: Optional, boolean indicating whether or not thi list should be disabled
  total: Optional, number indicating total number of items to be expected in this list. If the number
         of items currently in the list is greater than or equal to the total, then more trigger is hidden
  children: Optional, zero or more `ListItem`s

  # Example:
  <List {...otherProps}>
    <ListItem key={item[SharedProps.ID]} className={className}>
      <BenefitContents {...props} />
    </ListItem>
    <ListItem key={item[SharedProps.ID]} className={className}>
      <DocumentContents {...props} />
    </ListItem>
  </List>
 */

class List extends Component {
  static propTypes = {
    // handlers
    doLoadMore: PropTypes.func,
    // container
    className: PropTypes.string,
    // none
    noneLabel: PropTypes.string,
    noneClass: PropTypes.string,
    // more
    moreLabel: PropTypes.string,
    moreClass: PropTypes.string,
    // loading
    loadingLabel: PropTypes.string,
    loadingClass: PropTypes.string,
    // error
    errorLabel: PropTypes.string,
    errorClass: PropTypes.string,
    // disabled
    disabled: PropTypes.bool,
    // items
    total: PropTypes.number,
    children: componentOfType(ListItem),
  };

  static defaultProps = {
    // container
    className: "",
    // none
    noneLabel: consumerConst(selectedLanguage).nothingYet,
    noneClass: "",
    // more
    moreLabel: "More",
    moreClass: "",
    // loading
    loadingLabel: consumerConst(selectedLanguage).loadingLabel,
    loadingClass: "",
    // error
    errorLabel: "Oops something went wrong.",
    errorClass: "",
    // disabled
    disabled: TextProps.VALUE_FALSE,
  };

  state = {
    _isLoading: TextProps.VALUE_FALSE,
    _didLoad: TextProps.VALUE_FALSE,
    _isError: TextProps.VALUE_FALSE,
  };

  constructor(props) {
    super(...arguments);

    this._doLoadMoreHelper = _.debounce(this._doLoadMoreHelper, 500);
  }

  render() {
    const {
        doLoadMore,
        noneLabel,
        noneClass,
        moreLabel,
        moreClass,
        loadingLabel,
        loadingClass,
        errorLabel,
        errorClass,
        total,
        children,
        className,
      } = this.props,
      numItems = this._countChildren(children);
    return (
      <div>
        {this.state._didLoad ? (
          <span className="sr-only" aria-live="polite">
            Finished loading more
          </span>
        ) : null}
        <ul className={addStringIf(className, "dta-list")}>
          {numItems <= 0 && this._buildNone(noneLabel, noneClass)}
          {children}
          {_.isFunction(doLoadMore) &&
            total > numItems &&
            this._buildMore(
              moreLabel,
              moreClass,
              loadingLabel,
              loadingClass,
              errorLabel,
              errorClass
            )}
        </ul>
      </div>
    );
  }

  // Rendering
  // ---------

  _buildNone(noneLabel, noneClass) {
    return (
      <ListItem
        className={addStringIf(
          noneClass,
          "dta-list__none dta-list-item--style-plain"
        )}
      >
        {noneLabel}
      </ListItem>
    );
  }

  _buildMore(
    moreLabel,
    moreClass,
    loadingLabel,
    loadingClass,
    errorLabel,
    errorClass
  ) {
    if (this.state._isError) {
      return (
        <ListItem
          error
          className={addStringIf(
            errorClass,
            "dta-list__control dta-list-item--style-plain"
          )}
        >
          <span className="control-text">{errorLabel}</span>
        </ListItem>
      );
    } else if (this.state._isLoading) {
      return (
        <ListItem
          className={addStringIf(
            loadingClass,
            "dta-list__control dta-list-item--style-plain"
          )}
        >
          <span className="control-text" aria-live="polite">
            {loadingLabel}
          </span>
        </ListItem>
      );
    } else {
      return (
        <ListItem
          className={addStringIf(
            moreClass,
            "dta-list__more dta-list-item--style-plain"
          )}
        >
          <button
            type="button"
            className="dta-button dta-button--expand-width dta-button--outline-primary"
            onClick={this._doLoadMore}
          >
            {moreLabel}
          </button>
        </ListItem>
      );
    }
  }

  // Events
  // ------

  _doLoadMore = () => {
    this._doLoadMoreHelper();
  };

  // this is a debounced helper method
  _doLoadMoreHelper = () => {
    const result = tryCall(this.props.doLoadMore);
    // set the loading state if is a promise
    if (result && _.isFunction(result.then)) {
      this.setState({
        _isLoading: TextProps.VALUE_TRUE,
        _isError: TextProps.VALUE_FALSE,
      });
      result.then(
        () => {
          this._showLoadedConfirmation();
          this.setState({
            _isLoading: TextProps.VALUE_FALSE,
            _isError: TextProps.VALUE_FALSE,
          });
        },
        () => {
          this.setState({
            _isLoading: TextProps.VALUE_FALSE,
            _isError: TextProps.VALUE_TRUE,
          });
        }
      );
    }
  };

  _showLoadedConfirmation = () => {
    this.setState(
      {
        _didLoad: TextProps.VALUE_TRUE,
      },
      () => {
        setTimeout(() => {
          this.setState({ _didLoad: TextProps.VALUE_FALSE });
        }, 5000);
      }
    );
  };

  // Helpers
  // -------

  _countChildren(children) {
    return _.filter(Children.toArray(children), ({ props }) => !props.ignore)
      .length;
  }
}

export default List;
