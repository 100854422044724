import * as Props from "../../utils/components/shared";
import * as languageConstants from "../../utils/constants/constants";
import * as types from "../../utils/constants/types";
import React from "react";

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

//All the Label translations for Recertification Pages
export function recertificationPageLabels(language, name, pageType) {
  if (pageType === languageConstants.beforeYouStart) {
    //1st section page
    switch (language) {
      case EN:
        return {
          heading: "SNAP Recertification",
          tanfHeading: "TANF Redetermination",
          addtionalTanfHouseholdInfo: "Additional Household Information",
          addtionalTanfHouseholdInfoSubHeader: "The following questions are optional.",
          howApplyLabel: "Recertification",
          aesapHeading: "SNAP AESAP Recertification",
          aesapfieldLabel: "This application is for persons applying for Food Assistance when:",
          aesaplist1: "Everyone in the Food Assistance household is age 60 or older; or",
          aesaplist2: "All household members are age 60 or older and purchase and prepare food separately from the other people in the home; and",
          aesaplist3: "No Food Assistance household member receives earnings from work.",
          aesapfinaltext: "You may file this application by completing at least your name, address, and signing the form. If you need help completing this application, call toll free 1-800-438-2958. To get the address or phone number of your local office, call toll free 1-833-822-2202 or on line at ",
          aesaplink: "www.dhr.alabama.gov",
          tanfRedeterminationText: "Online by clicking Redetermination. This is the fastest way!",
          tanfRedeterminationDesc: "If you do not wish to complete you TANF form online, you may download and print your paper form",
          tanfRedeterminationDesc2:"You can submit your form by:",
          tanfmailtext: "Mail",
          tanffaxmailtext: "Take your paper form to your local county DHR office.",
          tanfclicktext: "Click",
          tanfAddresstext: "for the address.",
          tanfhere:"here",
          tanffieldlabel:"You may complete your Redetermination using the following methods:",
          //tanffirstpage
          tanffirstlabel1: "A review of your Family Assistance case is due during the month of ",
          tanffirstlabel2: " you are receiving assistance for children that are not your children or stepchildren, such as grandchildren, nieces, nephews, etc., OR you are receiving assistance for your children or stepchildren and you currently receive SSI.",
          tanffirstlabel3: "Call your worker, Zach Adams, at 781 836 2902 by the first of your review month to make an appointment for an interview. If you do not call your worker or keep your appointment by the end of your review month, your FA benefits for the next month will be held until the 10th and then will be available. If your review is not completed by the end of that month, your FA case will close. Sections 1115, 1125, 1135 of the Assistance Payments Manual.",
          tanffirsthead2: "If you have changes to report, you may complete your Redetermination using the following methods:",
          tanffirstcontinue1: "Continue to report changes within ten (10) days.",
          tanffirstcontinue2: "Failure to give correct information or to report changes within ten days may result in a reduction or termination of benefits. Contact your FA worker if you have questions.",
          fieldLabel1:
            "You may complete your Recertification using the following methods:",
          recertMethods: [
            "Online by clicking Start Recertification. This is the fastest way!",
            {
              before:
                "If you do not wish to complete your SNAP application online, you may download and print your paper application ",
              hereLinkText: "here.",
              after: " You can submit your form by:",
            },
            "Fax, mail, email or take your paper application to your local county DHR office by clicking here.",
          ],
          boBackButtonLabel: "Go Back",
          getStartButtonLabel: "Start SNAP Recertification",
          getStartAESAPButtonLabel: "Start SNAP AESAP Recertification",
          getStartTafdcButtonLabel:"Start TANF Redetermination",
          sIncome: "'s Income",
          editButtonLabel: "Edit",
          undoButtonLabel: "Undo",
          perLabel: "Per",
          ofLabel: "of",

          renderAppNoLabel: "Your application no:",
          youMayLabelOne: "You may want to ",
          youMayLabeltwo: "download your application",
          youMayLabelThree: "for future reference.",
          whatisNextLabel: "What's Next?",
          dtaReachLabel:
            "The DHR will reach out to you within the next 5 days.",
          sendDocLabel:
            "Send in documents so that DHR can confirm your situation.",
          howDoSpeedLabel: "How do I speed up the process?",
          donotNeedLabel:
            "You do not need to wait for DHR to send you a list of verifications",
          sendLabelOne: "You can ",
          sendLabelTwo: "send us your documents ",
          sendLabelThree: "in many ways",
          toLearnOneLabel:
            "To learn more about documents that you may submit now,",
          toLearnOneLabelTwo: "click here",
          manyWayLabel: "There are many other ways to submit documents to us.",
          manyWayLabelTwo: "Find out more",
          howDoSubmitLabel: "How do I submit documents?",
          weSuggestLabel: "We suggest",
          weSuggestLabelOne: "creating an online account",
          weSuggestLabelTwo:
            "so you can upload documents, track the progress of your application, check your balance, and more!",
          findOutMoreLabel: "Find out more",
          createOnlineLabel: "Create an online account",
          orExistLabel: "Or exit without uploading any documents",
          renderSaveLabel: "To save your progress, create an account here.",
          laterOnLabel:
            "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
          orExitLabel: "Or exit without saving your work",
          changingLabel:
            "Chaning this value will delete the previous entered values in upcoming sections.",
          greatHereLabel: "Great! Here's what we got from you.",
          lookIncorrectLabel:
            "If any of it looks incorrect, feel free to edit.",
          anotherHouseholdIncomeLabel: "Add another household member's income",
          applicationSummaryTitle: "Application Summary",
          applicationSummaryHeader: "Application Summary",
          viewApplicationLinkLabel: "view as pdf",
          iagreeTermsLabel: "I agree to the terms and conditions.",
          iagreetanfTermsLabel: "I agree to the terms.",
          signYourAppllication:
            "Sign your Recertification by typing your full name below.",
            tanfSignyourApplication:"Sign your application by typing your full name below. (Required)",
          tanfPleaseSign: "Please sign the application",
          continueLabel: "Continue",
          saveAndContinue: "Save & Continue",
          saveAndExit: "Save & Exit",
          getStartedLabel: "Get Started",
          submitButtonLabel: "Submit Recertification",
          submitSnapAesapLabel: "Submit SNAP AESAP Recertification",
          submitTafdcLabel: "Sign and Submit",
          expenseFieldLabel: "This last section is about",
          expenseHouseutility: "Housing and utility costs",
          expenseCaring: "Caring for kids and adults",
          expenseChsupport: "Child Support",
          expenseMed: "Medical expenses",
          expenseAnyinfo:
            "Any information you provide here will provide a more accurate benefit amount.",
          removeHoHTitle: "Warning",
          removeHoHLabel:
            "You are attempting to delete the head of the household. If this is correct, you may hit Continue now and DHR will contact you for more information. If this was a mistake, please hit “Go Back”",
          agreeContent: [
            "I did report changes",
            "I have read this entire form (or have had it read to me in a language that I understand), including the section about rights and responsibilities, and understand that I must comply with these rules;",
            "The information I am giving is true and complete to the best of my knowledge;",
            "I could go to prison or be required to pay fines if I knowingly give wrong or incomplete information; and DHR and other federal, state, and local officials may verify (check) any information I give",
          ],
          didReportChanges: "I did report changes",
          didNotReportChanges: "I did not report changes",
          agreeTermsErrorMsg: "Please agree to the terms.",
          signatureErrorMsg: "Please sign your Recertification.",
          tanfsignatureErrorMsg: "Please sign your Application.",
          signatureUserNameErrorMsg:
          "Signature needs to match the name in the application.",
          titleEmergency: "Emergency SNAP Benefits",
          headerEmergency: "Emergency SNAP Benefits",
          emergencySnap:
            "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
          incomeMoneylabel:
            "Does your income and money in the bank add up to less than your monthly housing expenses?",
          inlineHelpLabel:
            "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
          optionYesNo: ["Yes", "No"],
          tanfPleaseExplain : "Please Explain",
          householdOptions: ["Yes, I would like to register to vote.", "Yes, I am registered but would like to change my address for voting purposes.", "No, I do not want to apply to register to vote."],
          voterQuestion: "If you are not registered to vote where you live now, would you like to apply to register to vote here today?",
          voterHeading: "Voter Registration",
          alertSelectIncome: "Please select income you pay",
          incomeLesslabel:
            "Is your monthly income less than $150 and is your money in the bank $100 or less?",
          monthlyIncomeoptions: ["Yes", "No"],
          selectIncomeErrmsg: "Please select income you pay",
          migrantLabel:"Are you a migrant worker and is your money in the bank less than or equal to $100?", 
          migrantOptions: ["Yes", "No"],
          migrantErrmsg: "Please select migrant you pay",
          shelterExpense_ChangeQuestion:
            "Has your household shelter or utility expenses changed by more than or less than $100 per month? (Required)",
          shelterExpenseSubText:
            "Note: If you have moved, you must submit proof of your shelter expenses. If you have not moved but report an increase in the following shelter expenses (rent, mortgage, property taxes or home insurance) and do not provide verification, the change will not be made until recertification.",
          //sIncome:"'s gross earned income",
          sIncomebefore: "'s ",
          sIncometrigger: "Gross Earned Income",
          sIncomeAfter: " ",
          sIncomeHelp:
            "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. insurance",
          //sUIIncome:"'s gross unearned income",
          sAuthRepHelp: "An authorized representative is an individual who can be appointed to act on behalf of a SNAP applicant or recipient. This will allow the authorized representative to apply for benefits, complete eligibility interviews, and manage SNAP benefits on behalf of the household.",
          sUIncomebefore: "'s ",
          sUIncometrigger: "Gross Unearned Income",
          sUIncomeAfter: " ",
          sUIncomeHelp:
            "Gross Unearned Income is money you receive from a source other than employment  before anything is taken out, such as taxes or health insurance",
          //sUIIncome:"'s gross unearned income",
        }; //return End

      case ES:
        return {
          heading: "Recertificación SNAP",
          tanfHeading: "Redeterminación de TANF",
          addtionalTanfHouseholdInfo: "Información adicional del hogar",
          addtionalTanfHouseholdInfoSubHeader: "Las siguientes preguntas son opcionales.",
          householdOptions: ["Sí, me gustaría registrarme para votar.", "Sí, estoy registrado pero me gustaría cambiar mi dirección para efectos de votación.", "No, no quiero solicitar el registro para votar."],
          howApplyLabel: "Recertificación",
          aesapHeading: "Recertificación SNAP AESAP",
          aesapfieldLabel: "Esta solicitud es para personas que solicitan Asistencia Alimentaria cuando:",
          aesaplist1: "Todos los miembros del hogar de Food Assistance tienen 60 años o más; o",
          aesaplist2: "Todos los miembros del hogar tienen 60 años o más y compran y preparan alimentos por separado del resto de personas del hogar; y",
          aesaplist3: "Ningún miembro del hogar que recibe Asistencia Alimentaria recibe ingresos del trabajo.",
          aesapfinaltext: "Puede presentar esta solicitud completando al menos su nombre, dirección y firmando el formulario. Si necesita ayuda para completar esta solicitud, llame gratis al 1-800-438-2958. Para obtener la dirección o el número de teléfono de su oficina local, llame gratis al 1-833-822-2202 o en línea en ",
          aesaplink: "www.dhr.alabama.gov",
          tanfRedeterminationText: "En línea haciendo clic en Redeterminación. ¡Esta es la forma más rápida!",
          tanfRedeterminationDesc: "Si no desea completar su formulario TANf en línea, puede descargar e imprimir su formulario en papel.",
          tanfRedeterminationDesc2:"Puede enviar su formulario por:",
          tanfmailtext: "Correo",
          tanffaxmailtext: "Lleve su formulario impreso a la oficina local del DHR de su condado.",
          tanfclicktext: "Hacer clic",
          tanfAddresstext: "para la dirección.",
          tanfhere:"aquí",
          tanffieldlabel:"Puede completar su Redeterminación utilizando los siguientes métodos:",
          //tanffirstpage
          tanffirstlabel1: "Se debe realizar una revisión de su caso de Asistencia Familiar durante el mes de ",
          tanffirstlabel2: " está recibiendo asistencia para hijos que no son sus hijos o hijastros, como nietos, sobrinas, sobrinos, etc., O está recibiendo asistencia para sus hijos o hijastros y actualmente recibe SSI.",
          tanffirstlabel3: "Llame a su trabajador, Zach Adams, al 781 836 2902 antes del primero de su mes de revisión para programar una cita para una entrevista. Si no llama a su trabajador ni asiste a su cita antes del final del mes de revisión, sus beneficios de FA para el próximo mes se retendrán hasta el día 10 y luego estarán disponibles. Si su revisión no se completa antes de fin de ese mes, su caso de FA se cerrará. Artículos 1115, 1125, 1135 del Manual de Pagos de Asistencia.",
          tanffirsthead2: "Si tiene cambios que informar, puede completar su Redeterminación utilizando los siguientes métodos:",
          tanffirstcontinue1: "Continuar informando cambios dentro de los diez (10) días.",
          tanffirstcontinue2: "No proporcionar información correcta o informar cambios dentro de los diez días puede resultar en una reducción o terminación de los beneficios. Comuníquese con su trabajador de FA si tiene preguntas.",
          fieldLabel1:
            "Puede completar su Recertificación con los siguientes métodos:",
          recertMethods: [
            "En línea, haciendo clic en comenzar Recertificación. ¡Esta es la forma más rápida!",
            {
              before:
                "Aceptaremos un formulario impreso si incluye su nombre, dirección y firma. Esta opción no está disponible para realizar la presentación en línea. Descargue e imprima su formulario ",
              hereLinkText: "aquí.",
              after:
                " Después de completar el formulario, puede presentarlo de las siguientes maneras: ",
            },
            "Tomar una foto de cada página y subirlas a través de ACES DHR Self-Service Portal",
            "Por fax al (617) 887-8765",
            "Por correo a P.O. Box 4406, Taunton, MA 02780",
            "Llevarlo la oficina local de DHR para escanearlo en el área de autoservicio",
          ],
          boBackButtonLabel: "Volver",
          sIncome: "'Ingresos",
          getStartButtonLabel: "Comenzar Recertificación SNAP",
          getStartAESAPButtonLabel: "Iniciar Recertificación SNAP AESAP",
          getStartTafdcButtonLabel:"Iniciar la redeterminación de TANF",
          editButtonLabel: "Editar",
          undoButtonLabel: "Undo",
          perLabel: "Por",
          ofLabel: "De",
          //new label:

          renderAppNoLabel: "El número de su solicitud es:",
          youMayLabelOne: "Puede",
          youMayLabeltwo: "descargar su solicitud",
          youMayLabelThree: "para tenerla como referencia en el futuro.",
          whatisNextLabel: "¿Cuál es el siguiente paso?",
          dtaReachLabel:
            "El DHR se comunicará con usted en los próximos 5 días.",
          sendDocLabel:
            "Envíe los documentos para que el DHR pueda confirmar su situación.",
          howDoSpeedLabel: "¿Cómo puedo acelerar el proceso?",
          donotNeedLabel:
            "No es necesario que espere a que el DHR le envíe una lista de verificaciones. ",
          sendLabelOne: "Puede",
          sendLabelTwo: "enviarnos sus documentos",
          sendLabelThree: "de muchas maneras. ",
          toLearnOneLabel:
            "Para saber más acerca de los documentos que puede enviar ahora, ",
          toLearnOneLabelTwo: "haga clic aquí",
          manyWayLabel: "Hay muchas otras maneras de presentar documentos.",
          manyWayLabelTwo: "Obtenga más información.",
          howDoSubmitLabel: "¿Cómo envío documentos?",
          weSuggestLabel: "¡Le sugerimos que",
          weSuggestLabelOne: " cree una cuenta en línea",
          weSuggestLabelTwo:
            "para que pueda subir documentos, darle seguimiento a su solicitud, consultar su saldo y más!",
          findOutMoreLabel: "Averiguar más",
          createOnlineLabel: "Crear una cuenta en línea",
          orExistLabel: "O salir sin subir documentos.", //
          renderSaveLabel: "To save your progress, create an account here.",
          laterOnLabel:
            "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
          orExitLabel: "Or exit without saving your work",
          changingLabel:
            "Chaning this value will delete the previous entered values in upcoming sections.",
          greatHereLabel: "Excelente. Esto es lo que tenemos de usted",
          lookIncorrectLabel: "Si algo parece incorrecto, no dude en editarlo.",
          anotherHouseholdIncomeLabel:
            "Agregar el ingreso de otro miembro del núcleo familiar",
          applicationSummaryTitle: "Resumen de la aplicación",
          applicationSummaryHeader: "Resumen de la aplicación",
          viewApplicationLinkLabel: "Ver como PDF",
          iagreeTermsLabel: "Estoy de acuerdo con los términos y condiciones.",
          iagreetanfTermsLabel: "I agree to the terms.",
          signYourAppllication:
            "Firme su recertificación escribiendo su nombre completo a continuación",
            tanfSignyourApplication:"Firme su solicitud escribiendo su nombre completo a continuación. (Requerido)",
          tanfPleaseSign: "Por favor firme la solicitud",
          continueLabel: "Continuar",
          saveAndContinue: "Guardar y continuar",
          saveAndExit: "Guardar y salir",
          getStartedLabel: "Comenzar",
          submitButtonLabel: "Presentar Recertificación",
          submitSnapAesapLabel: "Enviar recertificación SNAP AESAP",
          submitTafdcLabel: "Firme y envíe",
          expenseFieldLabel: "Esta última sección trata sobre",
          expenseHouseutility: "Costos de vivienda y servicios públicos",
          expenseCaring: "Cuidado de niños y adultos",
          expenseChsupport: "Manutención",
          expenseMed: "Gastos médicos",
          expenseAnyinfo:
            "Toda la información que proporcione aquí dará un monto más exacto del beneficio.",
          removeHoHTitle: "Advertencia",
          removeHoHLabel:
            "Intenta eliminar al jefe/la jefa de familia. Si es correcto, puede hacer clic en Presentar ahora y DHR se comunicará con usted para obtener más información. Si es un error, haga clic en 'Volver'. ",
          agreeContent: [
            "Reporté cambios",
            "Leí todo el formulario (o alguien lo leyó para mí en un idioma que comprendo), incluida la sección sobre derechos y responsabilidades, y entiendo que debo cumplir con esas reglas;",
            "A mi leal saber y entender, la información que proporciono es verdadera y completa;",
            "Podría ir a prisión o se me podría exigir el pago de multas si proporciono intencionalmente información errónea o incompleta; y DHR y otros funcionarios federales, estatales y locales podrán verificar (controlar) la información que proporciono.",
          ],
          didReportChanges: "Informé cambios.",
          didNotReportChanges: "No informé cambios.",
          agreeTermsErrorMsg: "Por favor, acepte los términos.",
          signatureErrorMsg: "Firme su recertificación",
          tanfsignatureErrorMsg: "Por favor firme su solicitud.",
          signatureUserNameErrorMsg:
          "La firma debe coincidir con el nombre en la solicitud.",
          titleEmergency: "Beneficios de SNAP de emergencia",
          headerEmergency: "Beneficios de SNAP de emergencia",
          emergencySnap:
            "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
          incomeMoneylabel:
            "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
          inlineHelpLabel:
            "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
          optionYesNo: ["Sí", "No"],
          tanfPleaseExplain : "Por favor explique",
          alertSelectIncome: "Por favor seleccione el ingreso que paga",
          incomeLesslabel:
            "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
          monthlyIncomeoptions: ["Sí", "No"],
          selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
          migrantLabel:"¿Es usted un trabajador migrante y su dinero en el banco es menor o igual a $100?",
          // migrantBefore: "¿Es usted un ",
          // migrantTrigger: " trabajador migrante",
          // migrantAfter: " y su dinero en el banco es $100 o menos?",
          // migrantHelp:
          //   "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
          sAuthRepHelp: "Un representante autorizado es una persona que puede ser designada para actuar en en nombre de un solicitante o destinatario de SNAP. Esto permitirá a los autorizados representante para solicitar beneficios, completar entrevistas de elegibilidad y administrar los beneficios de SNAP en nombre del hogar.",
          migrantOptions: ["Sí", "No"],
          migrantErrmsg: "Por favor selecciona migrante pagas",
          sUIncomebefore: "'s ",
          sUIncomeAfter: " ",
          shelterExpense_ChangeQuestion:
            "¿Han cambiado los gastos de alojamiento o servicios públicos de su hogar en más o menos de $100 por mes? (Requerido)",
          shelterExpenseSubText:
            "Nota: Si se ha mudado, debe presentar prueba de sus gastos de alojamiento. Si no se ha mudado pero informa un aumento en los siguientes gastos de alojamiento (alquiler, hipoteca, impuestos a la propiedad o seguro de hogar) y no proporciona verificación, el cambio no se realizará hasta la recertificación.",
        }; //return End

      case PT:
        return {
          heading: "Recertificação",
          howApplyLabel: "Recertificação",
          fieldLabel1:
            "Você pode preencher sua Recertificação usando os seguintes métodos: ",
          recertMethods: [
            "Online clicando em <b>iniciar Recertificação.</b> Essa é a forma mais rápida!",
            {
              before:
                "Aceitaremos um formulário impresso se você incluir seu nome, endereço e assinatura. Esta opção não está disponível para envio online. Baixe e imprima seu formulário ",
              hereLinkText: "aqui.",
              after: " Após preencher esse formulário, você pode enviá-lo por:",
            },
            "Foto, tirando uma foto de cada página e carregando via ACES DHR Self-Service Portal",
            "Fax para (617) 887-8765",
            "Correio para P.O. Box 4406, Taunton, MA 02780",
            "Pessoalmente, trazendo ao seu escritório DHR local para digitalizar na área de autoatendimento",
          ],
          boBackButtonLabel: "Voltar",
          sIncome: " Renda",
          getStartButtonLabel: "Iniciar Recertificação SNAP",
          getStartAESAPButtonLabel: "Iniciar a recertificação SNAP AESAP",
          editButtonLabel: "Editar",
          perLabel: "Por",
          ofLabel: "do",

          //new label:

          renderAppNoLabel: "O número da sua solicitação é :",
          youMayLabelOne: "Baixe a sua solicitação ",
          youMayLabeltwo: "caso precise dela no futuro.",
          youMayLabelThree: "for future reference.",
          whatisNextLabel: "Qual é a próxima etapa?",
          dtaReachLabel: "O DHR entrará em contato com você em 5 dias.",
          sendDocLabel:
            "Envie os documentos para que o DHR possa confirmar a sua situação.",
          howDoSpeedLabel: "Como eu posso acelerar o processo?",
          donotNeedLabel:
            "Você não precisa esperar que o DHR envie uma lista de verificações.",
          sendLabelOne: "Você pode nos enviar seus",
          sendLabelTwo: " documentos",
          sendLabelThree: "de diversas formas.",
          toLearnOneLabel:
            "Para saber mais sobre os documentos que você pode enviar agora,",
          toLearnOneLabelTwo: "clique aqui",
          manyWayLabel:
            "Existem muitas outras maneiras de enviar documentos para nós.",
          manyWayLabelTwo: "Saiba mais",
          howDoSubmitLabel: "Como eu envio os documentos?",
          weSuggestLabel: "Sugerimos que você crie uma conta",
          weSuggestLabelOne: " on-line para fazer o upload dos ",
          weSuggestLabelTwo:
            "documentos, verificar o andamento da sua solicitação, checar o seu saldo e muito mais!",
          findOutMoreLabel: "Saiba mais",
          createOnlineLabel: "Crie uma conta on-line",
          orExistLabel: "Ou saia sem fazer o upload de nenhum documento",
          renderSaveLabel: "To save your progress, create an account here.",
          laterOnLabel:
            "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
          orExitLabel: "Or exit without saving your work",
          changingLabel:
            "Chaning this value will delete the previous entered values in upcoming sections.",
          greatHereLabel: "Ótimo! É isso que recebemos de você.",
          lookIncorrectLabel:
            "Se algo estiver incorreto, sinta-se à vontade para editar.",
          anotherHouseholdIncomeLabel:
            "Adicionar a renda de outro membro da família",
          applicationSummaryTitle: "Resumo da aplicação",
          applicationSummaryHeader: "Resumo da aplicação",
          viewApplicationLinkLabel: "Visualizar como PDF",
          iagreeTermsLabel: "Concordo",
          signYourAppllication:
            "Assine sua Recertificação digitando seu nome completo abaixo",
          continueLabel: "Continuar",
          getStartedLabel: "Vamos Começar",
          submitButtonLabel: "Enviar Recertificação",
          expenseFieldLabel: "Esta última seção é sobre",
          expenseHouseutility: "Custos residenciais e utilitários",
          expenseCaring: "Cuidados com crianças e adultos",
          expenseChsupport: "Suporte infantil",
          expenseMed: "Despesas médicas",
          expenseAnyinfo:
            "Qualquer informação que você prestar aqui irá fornecer um valor mais preciso do benefício.",
          removeHoHTitle: "Aviso",
          removeHoHLabel:
            "Você está tentando excluir o chefe do domicílio. Se isso estiver correto, você pode clicar em enviar agora e o DHR entrará em contato para obter mais informações. Se isso foi um erro, clique em 'Voltar'. ",
          agreeContent: [
            "Ao assinar, concordo que:",
            "Li todo este formulário (ou o leram para mim em um idioma que compreendo), incluindo a seção sobre direitos e responsabilidades, e entendo que devo cumprir essas regras;",
            "As informações que estou dando são verdadeiras e completas, de acordo com o meu conhecimento; ",
            "Eu poderia ir para a prisão ou ser obrigado a pagar multas se conscientemente fornecer informações erradas ou incompletas; e o DHR e outras autoridades federais, estaduais e locais podem verificar (conferir) qualquer informação que eu der.",
          ],
          didReportChanges: "Eu relatei as alterações.",
          didNotReportChanges: "Eu não relatei nenhuma alteração.",
          agreeTermsErrorMsg: "Concorde com os termos",
          signatureErrorMsg: "Assine sua Recertificação",
        }; //return End

      case ZH:
        return {
          heading: "重新认证",
          howApplyLabel: "重新认证",
          fieldLabel1: "您可使用下列方法填写您的《重新认证》表格：",
          recertMethods: [
            "在线填写，点击开始填写《重新认证》。这是最快的填写方式！",
            {
              before:
                "如果您在纸质表格中填写您的姓名、地址并签名，我们也接受纸质表格。此选项针对在线提交不可用。此处下载并打印您的纸质 ",
              hereLinkText: "表格。",
              after: " 您填写好表格后. 可以通过下列方式提交:",
            },
            "将表格各页拍照并利用 ACES DHR Self-Service Portal 上传",
            "传真发送至 (617)887-8765",
            "邮寄至 P.O. Box 4406, Taunton, MA 02780",
            "携带表格前往您当地的 DHR 办事处，在自助区域进行扫描",
          ],
          boBackButtonLabel: "返回",
          sIncome: "收入",
          getStartButtonLabel: "开始 SNAP 重新认证",
          getStartAESAPButtonLabel: "开始 SNAP AESAP 重新认证",
          editButtonLabel: "编辑",
          perLabel: "每",
          ofLabel: "的",
          //new label:
          renderAppNoLabel: "您的申请编号是",
          youMayLabelOne: "您可能想要",
          youMayLabeltwo: "下载申请表",
          youMayLabelThree: "下载申请表以备日后查阅。",
          whatisNextLabel: "下一步",
          dtaReachLabel: "DTA将在接下来5个工作日内与您联系。",
          sendDocLabel: "发送文件以便DTA能够确认您的状况。",
          howDoSpeedLabel: "如何加速审核流程？",
          donotNeedLabel: "您无需等待DTA向您发送证明文件清单。",
          sendLabelOne: "您可通过多种方式",
          sendLabelTwo: "向我们发送文件 ",
          sendLabelThree: "in many ways",
          toLearnOneLabel: "要详细了解您现在可以提交的文件，请",
          toLearnOneLabelTwo: "单击这里",
          manyWayLabel: "向我们提交文件可通过很多其他方式。",
          manyWayLabelTwo: "了解更多",
          howDoSubmitLabel: "如何提交文件？",
          weSuggestLabel: "我们建议您",
          weSuggestLabelOne: "创建一个在线账户",
          weSuggestLabelTwo:
            "您可以通过此账户上传资料，跟踪申请进度，查看余额等等！",
          findOutMoreLabel: "了解更多",
          createOnlineLabel: "创建在线账户",
          orExistLabel: "或退出而不上传任何资料",
          renderSaveLabel: "To save your progress, create an account here.",
          laterOnLabel:
            "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
          orExitLabel: "Or exit without saving your work",
          changingLabel:
            "Chaning this value will delete the previous entered values in upcoming sections.",
          greatHereLabel: "这是我们从你那里了解到的情况",
          lookIncorrectLabel: "如果有任何错误之处，敬请改正。",
          anotherHouseholdIncomeLabel: "添加其他家庭成员的收入",
          applicationSummaryTitle: "應用摘要",
          applicationSummaryHeader: "應用摘要",
          viewApplicationLinkLabel: "查看 PDF 文件",
          iagreeTermsLabel: "我同意",
          signYourAppllication: "在下方输入您的全名即为签署您的重新认证文件",
          continueLabel: "继续",
          getStartedLabel: "开始",
          submitButtonLabel: "提交重新认证",
          expenseFieldLabel: "最后一部分是关于",
          expenseHouseutility: "住房和通用开支",
          expenseCaring: "子女和成人照护费",
          expenseChsupport: "子女抚养费",
          expenseMed: "医疗费",
          expenseAnyinfo:
            "您在此提供的任何信息都将有助于我们确定更准确的补助金额。",
          removeHoHTitle: "警告",
          removeHoHLabel:
            "您正在尝试删除户主。如果这一操作正确，您可现在点击提交，DHR 会联系您获取更多信息。如果这一操作为误操作，请点击“返回”。 ",
          agreeContent: [
            "我签名即表示同意：",
            "我已经阅读此表格完整内容（或让人以我理解的语言为我朗读此表格完整内容），包括关于权利和责任的部分，而且我理解，我必须遵守这些规定；",
            "我提供的信息据我所知均真实而且完整。 ",
            "如果我在知情的前提下给出错误或不完整的信息，我可能入狱或按要求支付罚款；DHR 及其他联邦、州和地方官员可验证（检查）我提供的所有信息。",
          ],
          didReportChanges: "我已经报告信息 更改。s",
          didNotReportChanges: "我并未报告任何更改。",
          agreeTermsErrorMsg: "请同意条款",
          signatureErrorMsg: "请签署您的重新认证文件",
        }; //return End
      case VI:
        return {
          heading: "Chứng nhận lại",
          howApplyLabel: "Chứng nhận lại",
          fieldLabel1:
            "Quý vị có thể hoàn thành Chứng nhận lại của mình bằng các phương pháp sau: ",
          recertMethods: [
            "Trực tuyến bằng cách nhấp vào nút <b>bắt đầu mẫu Chứng nhận lại.</b> Đây là cách nhanh nhất!",
            {
              before:
                "Chúng tôi sẽ chấp nhận đăng ký bằng biểu mẫu trên giấy nếu trên đó có tên, địa chỉ và chữ ký của quý vị. Tùy chọn này không có sẵn khi nộp trực tuyến. Tải về và in biểu mẫu ở ",
              hereLinkText: "đây.",
              after:
                " Sau khi quý vị hoàn thành biểu mẫu đó, quý vị có thể gửi bằng cách:",
            },
            "Chụp ảnh từng trang và tải lên qua ACES DHR Self-Service Portal",
            "Gửi fax theo số (617) 887-8765",
            "Gửi thư đến Hộp thư bưu điện 4406, Taunton, MA 02780",
            "Mang đến văn phòng DHR địa phương của quý vị để scan tại khu vực tự phục vụ",
          ],
          boBackButtonLabel: "Quay lại",
          sIncome: "'thu nhập của",
          getStartButtonLabel: "Bắt đầu mẫu Chứng nhận lại SNAP",
          getStartAESAPButtonLabel: "Bắt đầu chứng nhận lại SNAP AESAP",
          editButtonLabel: "Chỉnh sửa",
          perLabel: "Mỗi",
          ofLabel: "Của",

          //new label:

          renderAppNoLabel: "Số Đơn xin:",
          youMayLabelOne: "Có thể Quý vị muốn ",
          youMayLabeltwo: " tải đơn xin của quý vị về",
          youMayLabelThree: "để tham khảo trong tương lai.",
          whatisNextLabel: "Điều gì diễn ra kế tiếp?",
          dtaReachLabel: "DHR sẽ liên lạc với quý vị trong vòng 5 ngày tới.",
          sendDocLabel:
            "Gửi các tài liệu để DHR có thể xác nhận tình trạng của quý vị. ",
          howDoSpeedLabel: "Làm thế nào để đẩy nhanh tiến trình?",
          donotNeedLabel:
            "Quý vị không cần đợi DHR gửi cho quý vị danh sách xác minh.",
          sendLabelOne: "Quý vị có thể ",
          sendLabelTwo: " gửi cho chúng tôi giấy tờ của quý vị",
          sendLabelThree: " bằng nhiều cách",
          toLearnOneLabel:
            "Để tìm hiểu thêm về các giấy tờ mà quý vị có thể gửi ngay bây giờ,",
          toLearnOneLabelTwo: "hãy nhấp vào đây",
          manyWayLabel: "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
          manyWayLabelTwo: "Tìm hiểu thêm",
          howDoSubmitLabel: "Làm thế nào để tôi gửi giấy tờ?",
          weSuggestLabel: "Chúng tôi khuyên quý vị nên",
          weSuggestLabelOne: "tạo một tài khoản trực tuyến",
          weSuggestLabelTwo:
            "để quý vị có thể tải lên giấy tờ, theo dõi tiến trình đơn xin của quý vị, kiểm tra số dư của quý vị và những việc khác nữa!",
          findOutMoreLabel: "Tìm hiểu thêm",
          createOnlineLabel: "Tạo tài khoản trực tuyến",
          orExistLabel: "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
          renderSaveLabel: "To save your progress, create an account here.",
          laterOnLabel:
            "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
          orExitLabel: "Or exit without saving your work",
          changingLabel:
            "Chaning this value will delete the previous entered values in upcoming sections.",
          greatHereLabel:
            "Tuyệt vời, đây là những gì chúng tôi thu thập được từ quý vị",
          lookIncorrectLabel:
            "Nếu có bất cứ điều nào trông có vẻ không chính xác, quý vị cứ tự nhiên chỉnh sửa",
          anotherHouseholdIncomeLabel:
            "Bổ sung thu nhập của thành viên khác trong hộ gia đình",
          applicationSummaryTitle: "Tóm tắt ứng dụng",
          applicationSummaryHeader: "Tóm tắt ứng dụng",
          viewApplicationLinkLabel: "Xem dưới dạng PDF",
          iagreeTermsLabel: "Tôi đồng ý",
          signYourAppllication:
            "Ký xác nhận của bạn bằng cách nhập tên đầy đủ của bạn dưới đây",
          continueLabel: "Tiếp tục",
          getStartedLabel: "Bắt đầu",
          submitButtonLabel: "Gửi đi chứng nhận lại",
          expenseFieldLabel: "Phần cuối cùng này là về",
          expenseHouseutility: "Chi phí nhà ở và tiện ích",
          expenseCaring: "Chăm sóc trẻ em và người lớn",
          expenseChsupport: "Trợ cấp nuôi con",
          expenseMed: "Chi phí y tế",
          expenseAnyinfo:
            "Bất kỳ thông tin nào quý vị cung cấp ở đây sẽ đưa ra số tiền trợ cấp chính xác hơn.",
          removeHoHTitle: "Cảnh báo",
          removeHoHLabel:
            "Quý vị đang cố gắng xóa mục chủ hộ. Nếu đúng, quý vị có thể nhấp vào gửi ngay bây giờ và DHR sẽ liên hệ với quý vị để biết thêm thông tin. Nếu đây là lỗi, xin vui lòng bấm nút 'quay lại.'",
          agreeContent: [
            "Bằng cách ký vào mẫu này, tôi đồng ý rằng:",
            "Tôi đã đọc toàn bộ biểu mẫu này (hoặc biểu mẫu này đã được đọc cho tôi bằng ngôn ngữ mà tôi hiểu) bao gồm cả phần về quyền và trách nhiệm và hiểu rằng tôi phải tuân thủ các quy tắc này;",
            "Theo sự hiểu biết tốt nhất của tôi, thông tin tôi đang cung cấp là đúng sự thật và đầy đủ;",
            "Tôi có thể vào tù hoặc bị buộc phải nộp tiền phạt nếu tôi cố tình cung cấp thông tin sai hoặc không đầy đủ; và DHR và các cán bộ liên bang, tiểu bang và địa phương khác có thể xác minh (kiểm tra) bất kỳ thông tin nào tôi cung cấp",
          ],
          didReportChanges: "Tôi đã báo cáo những thay dổi.",
          didNotReportChanges: "Tôi đã không báo cáo bất kỳ thay đổi nào. ",
          agreeTermsErrorMsg: "Vui lòng đồng ý với các điều khoản",
          signatureErrorMsg: "Vui lòng ký xác nhận lại",
        }; //return End
      case HI:
        return {
          heading: "Resètifikasyon",
          howApplyLabel: "Resètifikasyon",
          fieldLabel1: "Ou ku Rapò Pwovizwa w lan nan itilize metòd sa yo:",
          recertMethods: [
            "An liy nan klike sou <b>Kòmanse Rapò Pwovizwa.</b> Se fason ki pi rapid la!",
            {
              before:
                "Nou pral aksepte yon fòm papye si w mete non, adrès ak siyati w. Opsyon sa a pa disponib pou soumèt an liy. Telechaje epi enprime fòm ",
              hereLinkText: "papye w la la a.",
              after: " Ou ka soumèt fòm ou an nan:",
            },
            "Pran yon foto chak paj epi telechaje atravè ACES DHR Self-Service Portal",
            "Fakse nan (617) 887-8765",
            "Poste nan P.O. Box 4406, Taunton, MA 02780.",
          ],
          boBackButtonLabel: "Tounen",
          getStartButtonLabel: "Kòmanse Resètifikasyon SNAP",
          getStartAESAPButtonLabel: "Kòmanse resètifikasyon SNAP AESAP",
          sIncome: " Revni",
          editButtonLabel: "Edite",
          perLabel: "Pa",
          ofLabel: "of",

          renderAppNoLabel: "Nimewo aplikasyon w lan:",
          youMayLabelOne: "Ou ka vle ",
          youMayLabeltwo: "telechaje aplikasyon w lan",
          youMayLabelThree: "pou plis referans.",
          whatisNextLabel: "Kisa k ap vini apre?",
          dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
          sendDocLabel: "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
          howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
          donotNeedLabel:
            "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
          sendLabelOne: "Ou ka ",
          sendLabelTwo: "voye dokiman w yo ban nou ",
          sendLabelThree: "nan plizyè fason",
          toLearnOneLabel:
            "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
          toLearnOneLabelTwo: "klike la a",
          manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman yo ban nou.",
          manyWayLabelTwo: "Jwenn plis enfòmasyon",
          howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
          weSuggestLabel: "Nou sijere",
          weSuggestLabelOne: "kreye yon kont an liy",
          weSuggestLabelTwo:
            "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
          findOutMoreLabel: "Jwenn plis enfòmasyon",
          createOnlineLabel: "Kreye yon kont an Liy",
          orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
          renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
          laterOnLabel:
            "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
          orExitLabel: "Oswa sòti san ou pa konsève travay ou",
          changingLabel:
            "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
          greatHereLabel: "Ekselan! Men sa nou te jwenn na men w.",
          lookIncorrectLabel:
            "Si nenpòt nan yo sanble pa kòrèk, pa ezite modifye.",
          anotherHouseholdIncomeLabel: "Ajoute revni yon lòt manm fwaye a",
          applicationSummaryTitle: "Rezime aplikasyon",
          applicationSummaryHeader: "Rezime aplikasyon",
          viewApplicationLinkLabel: "gade sou fòma pdf",
          iagreeTermsLabel: "Mwen dakò",
          signYourAppllication:
            "Siyen Re-Sètifikasyon w lan nan tape non konplè w anba a",
          continueLabel: "Kontinye",
          getStartedLabel: "Kòmanse",
          submitButtonLabel: "Soumèt Resètifikasyon",
          expenseFieldLabel: "Dènye seksyon sa a se konsènan",
          expenseHouseutility: "Depans pou lojman ak sèvis piblik",
          expenseCaring: "Pran swen timoun ak adilt",
          expenseChsupport: "Sipò Timoun",
          expenseMed: "Depans medikal",
          expenseAnyinfo:
            "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
          removeHoHTitle: "Avètisman",
          removeHoHLabel:
            "W ap eseye efase chèf fwaye a. Si sa kòrèk, ou ka klike sou Kontinye kounye a epi DHR pral kontakte w pou plis enfòmasyon. Si se te yon erè, tanpri klike sou  “Tounen”",
          agreeContent: [
            "Nan siyen, mwen dakò ke:",
            "Mwen te li tout fòm sa a (oswa mwen te fè li l pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite, epi m konprann ke mwen dwe respekte règleman sa yo;",
            "Enfòmasyon m ap bay yo vre epi konplè dapre sa m byen konnen.",
            "M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) nenpòt enfòmasyon mwen bay yo",
          ],
          didReportChanges: "Mwen te rapòte chanjman",
          didNotReportChanges: "Mwen pa t rapòte chanjman ",
          agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
          signatureErrorMsg: "Tanpri siyen Resètifikasyon w lan",
        }; //return End
      default:
        return;
    }
  }
  else if (pageType === languageConstants.authRep){
    switch(language) {
      case EN:
        return  {
          authSidebarTitle: "Authorized Representative",
          authTitle: "Authorized Representative Summary",
          authQuestionone: "Authorization for a Household Representative",
          authQuestiononedesc: "You have the right to appoint someone to act on your household's behalf to use your EBT card to purchase food and/or to complete the application process. It is your responsibility to inform this individual that he/she has been appointed as your household's representative. You are responsible for your representative's action in the use of your EBT card, even if the card is used without your specific knowledge or consent. You are responsible for any information given by your representative in the certification of your household for SNAP benefits. It is also your responsibility to inform your worker if you do not want this person to continue to act as a representative for your household. You may appoint one person for each responsibility or one person for both. You are not required to have a representative for either.",
          authQuestiontwo: "Authorization to Use EBT Card to Purchase Food",
          authQuestiontwodesc1: "An additional EBT card in you representative's name along with a unique personal identification number (PIN) will allow",
          underlineText1: "complete access",
          underlineText2: "WILL NOT",
          authQuestiontwodesc2: "to all of your household's SNAP benefits. It is possbile for this individual to spend your household's benefits with or without your specific consent or knowledge. If your representative uses your benefits inappropriately, benefits",
          authQuestiontwodesc3: "be replaced.", 
          authQuestionOptions: "Do you want to authorize someone or change your current representative to use the EBT card to purchase food for you?",
          authQuestiontwoQues: "Do you want to authorize someone to be your representative for certification purposes?",
          authCertificationTitle: "Authorization for Certification of Household Eligibility",
          authCertificationDesc: "This individual must know your household's situation well enough to give any information needed to determine eligibility for SNAP and is authorized to make application, be interviewed, give information on behalf of your household for the purpose of determining eligibility for the SNAP program. You are responsible for any incorrect information given by your representative.",
          authCertificationQuestionOption: " Do you want to authorize someone or change your current representative for certification purposes?",
          authCertificationText: " I understand that an electronic signature has the same legal effect and can be enforced in the same way as a written signature. By checking this box and typing my name below, I am electronically signing and consenting to having an authorized representative for my case.",
          authSnapTanfQuestion: "Do you want to authorize someone to use the EBT card to purchase food for you?",
          authSignText: "Sign by typing your full name below. (Required)",
          firstName: "First Name (Required)",
          firstNametext: "First Name",
          middleName: "Middle Name",
          lastName: "Last Name (Required)",
          lastNametext: "Last Name",
          suffixLabel: "Suffix",
          phoneNumber: "Phone Number (Required)",
          phoneNumbertext: "Phone Number",
          triggerText: "Authorized Representative",
          summaryQuestion: "Has your Authorized Representative information changed?",
          iunderstandText: "I understand that an electronic signature has the same legal effect and can be enforced in the same way as a written signature. By checking this box and typing my name below, I am electronically signing and consenting to have an authorized representative for my case.",
          iagreeText: "I agree to the terms and conditions.",
          signByText: "Sign by typing your full name below. (Required)",
          firstNameError:"Please enter a first name.",
          lastNameError:"Please enter a last name.",
          phoneError:"Please enter a phone number.",
          signatureErrorMsg:"Please sign.",
          signatureValidMsg: "Signature needs to match the name in the application.",
          yesnoOptions:["Yes","No"],
          // Add Text Here for Summary
        }
        case ES: 
        return  {
          authSidebarTitle: "Representante autorizada",
          authTitle: "Resumen del representante autorizado",
          authQuestionone: "Autorización para un representante del hogar",
          authQuestiononedesc: "Tiene derecho a designar a alguien para que actúe en nombre de su hogar para usar su tarjeta EBT para comprar alimentos y/o completar el proceso de solicitud. Es su responsabilidad informar a esta persona que ha sido designada como representante de su hogar. Usted es responsable de la acción de su representante en el uso de su tarjeta EBT, incluso si la tarjeta se usa sin su conocimiento o consentimiento específico. Usted es responsable de cualquier información proporcionada por su representante en la certificación de su hogar para los beneficios SNAP. También es su responsabilidad informar a su trabajador si no desea que esta persona continúe actuando como representante de su hogar. Podrá designar una persona para cada responsabilidad o una persona para ambas. No es necesario que tenga un representante para ninguno de los dos.",
          authQuestiontwo: "Autorización para utilizar la tarjeta EBT para comprar alimentos",
          authQuestiontwodesc1: "Una tarjeta EBT adicional a nombre de su representante junto con un número de identificación personal (PIN) único le permitirá",
          underlineText1: " acceso completo ",
          underlineText2: " NO LO HARA ",
          authQuestiontwodesc2: "a todos los beneficios SNAP de su hogar. Es posible que esta persona gaste los beneficios de su hogar con o sin su consentimiento o conocimiento específico. Si su representante usa sus beneficios de manera inapropiada, los beneficios",
          authQuestiontwodesc3: "ser reemplazado", 
          authQuestionOptions: "¿Quiere autorizar a alguien o cambiar a su representante actual para que use la tarjeta EBT para comprarle alimentos?",
          authQuestiontwoQues: "¿Quiere autorizar a alguien para que sea su representante a efectos de certificacións?",
          authCertificationTitle: "Autorización para la certificación de elegibilidad del hogar",
          authCertificationDesc: "Esta persona debe conocer la situación de su hogar lo suficientemente bien como para brindar cualquier información necesaria para determinar la elegibilidad para SNAP y está autorizada para presentar solicitudes, ser entrevistado y brindar información en nombre de su hogar con el fin de determinar la elegibilidad para el programa SNAP. Usted es responsable de cualquier información incorrecta proporcionada por su representante.",
          authCertificationQuestionOption: " ¿Quiere autorizar a alguien o cambiar su representante actual para fines de certificación?",
          authCertificationText: " Entiendo que una firma electrónica tiene el mismo efecto legal y puede hacerse cumplir de la misma manera que una firma escrita. Al marcar esta casilla y escribir mi nombre a continuación, firmo electrónicamente y doy mi consentimiento para tener un representante autorizado para mi caso.",
          authSnapTanfQuestion: "¿Quiere autorizar a alguien a usar la tarjeta EBT para comprarle alimentos?",
          authSignText: "Firme escribiendo su nombre completo a continuación. (Requerido)",
          firstName: "Primer nombre (requerido)",
          middleName: "Segundo nombre",
          lastName: "Apellido Necesario)",
          suffixLabel: "Sufijo",
          phoneNumber: "Número de teléfono (obligatorio)",
          triggerText: "Representante autorizada",
          summaryQuestion: "¿Ha cambiado la información de su representante autorizado?",
          iunderstandText: "Entiendo que una firma electrónica tiene el mismo efecto legal y puede hacerse cumplir de la misma manera que una firma escrita. Al marcar esta casilla y escribir mi nombre a continuación, firmo electrónicamente y doy mi consentimiento para tener un representante autorizado para mi caso.",
          iagreeText: "Estoy de acuerdo con los términos y condiciones.",
          signByText: "Firme escribiendo su nombre completo a continuación. (Requerido)",
          firstNameError:"Por favor ingrese un nombre.",
          lastNameError:"Por favor, introduzca un apellido.",
          phoneError:"Por favor, ingrese un número de teléfono.",
          signatureErrorMsg:"Por favor firma.",
          signatureValidMsg: "La firma debe coincidir con el nombre en la solicitud.",
          yesnoOptions:["Sí","No"],
        }
    }
  }
   else if (pageType === languageConstants.aboutMe) {
    switch (language) {
      case EN:
        return {
          title: "About me",
          header: "About me",
          labelFirstName: "What is your first name?",
          firstNameErrMsg: "Required - Please enter the first name.",
          middleName: "What is your middle name?",
          middleNameErrMsg: "Please enter middle name.",
          lastNamelabel: "What is your last name?",
          lastNameErrMsg: "Required - Please enter the last name.",
          labelSuffix: "Suffix",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "What is your date of birth?",
          dobAlertMsg: "Please enter a valid Date of Birth",
          ssnLabel: "What is your Social Security Number?",
          inputClearLabel: "I don't have one",
          ssnErrMsg: "Please enter a valid Social Security Number.",
          headerMyContactInfo: "My Contact Info",
          houselessLabel: "Is your household homeless?",
          houselessErrMsg: "Please select whether your having home or not",
          houseLessOptions: ["Yes", "No"],
          addrQuestionlabel: "What is your address?",
          validZipcode: "Please enter a valid Zipcode.",
          alsoMailingLabel: "Is this also your mailing address?",
          optionsSendMail: "Yes, send mail there.",
          optionsNoDiffAddress: "No, I use a different address for mail.",
          mailingQlabel: "What is your mailing address?",
          zipCodeErrmsg: "Please enter a valid Zipcode.",
          citiErrmsg: "Please enter a valid City.",
          countyErrmsg: "Please enter a valid County.",
          addressErrmsg: "Please enter a valid Address.",
          mailingAddressErrmsg: "Please enter a mailing address.",
          homeAddressErrmsg: "Please enter a home address.",
          bestPhoneqlabel: "What is the best phone number to reach you?",
          inlineHelp: "This is the number the DHR will use to reach you.",
          validPhoneAlert: "Please enter a valid phone number.",
          emailAddressLabel: "What is your email address?",
          emailErrormsg: "Please enter a valid email address.",
          titleEmergency: "Emergency SNAP benefits",
          headerEmergency: "Emergency SNAP benefits",
          emergencySnap:
            "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
          incomeMoneylabel:
            "Does your income and money in the bank add up to less than your monthly housing expenses?",
          inlineHelpLabel:
            "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
          optionYesNo: ["Yes", "No"],
          alertSelectIncome: "Please select income you pay",
          incomeLesslabel:
            "Is your monthly income less than $150 and is your money in the bank $100 or less?",
          monthlyIncomeoptions: ["Yes", "No"],
          selectIncomeErrmsg: "Please select income you pay",
          migrantBefore: "Are you a ",
          migrantTrigger: "migrant worker",
          migrantAfter: " and is your money in the bank $100 or less?",
          migrantHelp:
            "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
          migrantOptions: ["Yes", "No"],
          migrantErrmsg: "Please select migrant you pay",
          headerMoreAbout: "More about me",
          areUsBefore: "Are you a ",
          areUsTrigger: "US citizen",
          areUsafter: "?",
          eligibleHelp:
            "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
          chooseErrmsg: "Please choose citizen",
          eligibleMemberHelp:
            "Even if you are not a US Citizen, you or other household members may still be eligible.",
          eligibleOptions: ["Yes", "No"],
          laguageBefore: "What language do you ",
          LANGUAGES: [
            "English",
            "Spanish",
            "Portuguese",
            "French",
            "Haitian Creole",
            "Chinese",
            "Vietnamese",
            "Khmer",
            "Korean",
            "Russian",
            "Italian",
            "Polish",
            "Arabic",
          ],
          languageTrigger: "prefer to speak",
          languageAfter: "?",
          comfortLanHelp:
            "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
          languageErrMsg: "Please choose language",
          genderLabel: "What is your gender?",
          genderOptions: ["Male", "Female"],
          genderErrmsg: "Please choose gender",
          labelEthnicity: "What is your ethnicity?",
          helpEthnicity:
            "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          raceLabel: "What is your race?",
          raceHelp:
            "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
          optionAIdisplay: "American Indian",
          optionAsiandisplay_VALUE: "ASIAN",
          optionAsiandisplay: "Asian",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Black/African American",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Pacific Islander",
          optionwhitedisplay_VALUE: "WHITE",
          optionwhitedisplay: "White",
          declineToAnswerLabel: "Decline to answer",
        };

      case ES:
        return {
          title: "Sobre mí",
          header: "Sobre mí",
          labelFirstName: "¿Cuál es su nombre?",
          firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          middleName: "¿Cuál es su segundo nombre?",
          middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          lastNamelabel: "¿Cuál es su apellido?",
          lastNameErrMsg: "Requerido: ingrese el apellido",
          labelSuffix: "Sufijo",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
          dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
          ssnLabel: "¿Cuál es su número de seguro social?",
          inputClearLabel: "No tengo",
          ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
          headerMyContactInfo: "Mi información de contacto",
          houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
          houselessErrMsg: "Seleccione si tiene o no su hogar",
          houseLessOptions: ["Sí", "No"],
          addrQuestionlabel: "¿Cuál es su dirección?",
          validZipcode: "Por favor ingrese un código postal válido",
          alsoMailingLabel: "¿Es igual a su dirección postal?",
          optionsSendMail: "Sí, enviar correo allí. ",
          optionsNoDiffAddress:
            "No, utilizo una dirección diferente para el correo postal.",
          mailingQlabel: "¿Cuál es su dirección postal?",
          zipCodeErrmsg: "Ingrese un código postal válido",
          citiErrmsg: "Por favor introduzca una ciudad válida.",
          countyErrmsg: "Por favor ingrese un condado válido.",
          addressErrmsg: "Por favor, ingrese una dirección válida",
          mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
          homeAddressErrmsg: "Por favor ingrese una dirección particular.",
          bestPhoneqlabel:
            "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
          inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
          validPhoneAlert: "Por favor ingrese un número de teléfono válido",
          emailAddressLabel: "Dirección de correo electrónico?",
          emailErrormsg:
            "Por favor, introduce una dirección de correo electrónico válida",
          titleEmergency: "Beneficios de SNAP de emergencia",
          headerEmergency: "Beneficios de SNAP de emergencia",
          emergencySnap:
            "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
          incomeMoneylabel:
            "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
          inlineHelpLabel:
            "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
          optionYesNo: ["Sí", "No"],
          alertSelectIncome: "Por favor seleccione el ingreso que paga",
          incomeLesslabel:
            "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
          monthlyIncomeoptions: ["Sí", "No"],
          selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
          migrantBefore: "¿Es usted un ",
          migrantTrigger: " trabajador migrante",
          migrantAfter: " y su dinero en el banco es $100 o menos?",
          migrantHelp:
            "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
          migrantOptions: ["Sí", "No"],
          migrantErrmsg: "Por favor selecciona migrante pagas",
          headerMoreAbout: "Más sobre mí",
          areUsBefore: "¿Es usted ",
          areUsTrigger: "ciudadano de EE. UU.",
          areUsafter: "?",
          eligibleHelp:
            "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
          chooseErrmsg: "Por favor elija ciudadano",
          eligibleMemberHelp:
            "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
          eligibleOptions: ["Sí", "No"],
          LANGUAGES: [
            "Inglés",
            "Español",
            "Portugués",
            "Francés",
            "Criollo haitiano",
            "Chino",
            "Vietnamita",
            "Camboyano",
            "Coreano",
            "Ruso",
            "Italiano",
            "Polaco",
            "Árabe",
          ],
          laguageBefore: "¿Qué idioma ",
          languageTrigger: " prefiere hablar",
          languageAfter: "?",
          comfortLanHelp:
            "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
          languageErrMsg: "Por favor, elija el idioma",
          genderLabel: "¿Cuál es su sexo?",
          genderOptions: ["Masculino", "Femenino"],
          genderErrmsg: "Por favor elija género",
          labelEthnicity: "¿Cuál es tu origen étnico?",
          helpEthnicity:
            "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
          hisPanicOption: "Hispano/Latino",
          nonhisPanicOption: "No hispano/Latino",
          raceLabel: "¿Cuál es tu raza?",
          raceHelp:
            "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
          optionAIdisplay: "Indio americano",
          optionAsiandisplay_VALUE: "Asiático",
          optionAsiandisplay: "Asiático",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Negro/Afroamericano",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Isleño del Pacífico",
          optionwhitedisplay_VALUE: "Blanco",
          optionwhitedisplay: "Blanco",
          declineToAnswerLabel: "Me niego a responder",
        };

      case PT: //portugeesh
        return {
          title: "Sobre mim",
          header: "Sobre mim",
          labelFirstName: "Qual é o seu primeiro nome?",
          firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
          middleName: "Qual é o seu nome do meio?",
          middleNameErrMsg: "Digite o nome do meio",
          lastNamelabel: "Qual é o seu último nome?",
          lastNameErrMsg: "Obrigatório - Digite o sobrenome",
          labelSuffix: "Sufixo",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "Qual é a sua data de nascimento?",
          dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
          ssnLabel: "Qual é o número do seu seguro social?",
          inputClearLabel: "Eu não tenho",
          ssnErrMsg: "Digite um Número de Segurança Social válido",
          headerMyContactInfo: "Minhas informações de contato",
          houselessLabel: "Sua família não tem moradia?",
          houselessErrMsg: "Selecione se você tem casa ou não",
          houseLessOptions: ["Sim", "Não"],
          addrQuestionlabel: "Qual é o seu endereço?",
          validZipcode: "Por favor insira um código postal válido",
          alsoMailingLabel: "Esse também é o seu endereço de correspondência?",
          optionsSendMail:
            "Sim, envie as correspondências para esse endereço. ",
          optionsNoDiffAddress:
            "Não, eu uso um endereço diferente para correspondência.",
          mailingQlabel: "Qual é o seu endereço de correspondência? ",
          zipCodeErrmsg: "Insira um Zip Code válido",
          citiErrmsg: "Digite uma cidade válida",
          addressErrmsg: "Digite um endereço válido",
          bestPhoneqlabel:
            "Qual é o melhor número de telefone para falar com você?",
          inlineHelp:
            "Esse será o número que o DHR irá usar para falar com você.",
          validPhoneAlert: "Por favor insira um número de telefone válido",
          emailAddressLabel: "Endereço de e-mail",
          emailErrormsg: "Por favor insira um endereço de e-mail válido",
          titleEmergency: "Benefício SNAP emergencial",
          headerEmergency: "Benefício SNAP emergencial",
          emergencySnap:
            "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
          incomeMoneylabel:
            "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
          inlineHelpLabel:
            'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
          optionYesNo: ["Sim", "Não"],
          alertSelectIncome: "Selecione a renda que você paga",
          incomeLesslabel:
            "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
          monthlyIncomeoptions: ["Sim", "Não"],
          selectIncomeErrmsg: "Selecione a renda que você paga",
          migrantBefore: "Você é um ",
          migrantTrigger: "trabalhador migrante",
          migrantAfter: " e tem US$ 100 ou menos no banco?",
          migrantHelp:
            "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
          migrantOptions: ["Sim", "Não"],
          migrantErrmsg: "Selecione o migrante que você paga",
          headerMoreAbout: "Mais sobre mim",
          areUsBefore: "Você é ",
          areUsTrigger: "cidadão americano",
          areUsafter: "?",
          eligibleHelp:
            "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
          chooseErrmsg: "Escolha cidadão",
          eligibleMemberHelp:
            "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
          eligibleOptions: ["Sim", "Não"],
          laguageBefore: "Em qual idioma você ",
          LANGUAGES: [
            "Inglês",
            "Espanhol",
            "Português",
            "Francês",
            "Créole haitiano",
            "Chinês",
            "Vietnamita",
            "Kmè",
            "Coreano",
            "Russo",
            "Italiano",
            "Polonês",
            "Árabe",
          ],
          languageTrigger: "prefere se comunicar",
          languageAfter: "?",
          comfortLanHelp:
            "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
          languageErrMsg: "Escolha o idioma",
          genderLabel: "Qual é o seu gênero?",
          genderOptions: ["Masculino", "Feminino"],
          genderErrmsg: "Escolha o gênero",
          labelEthnicity: "Qual é a sua etnia?",
          helpEthnicity:
            "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
          raceLabel: "Qual é a sua raça?",
          hisPanicOption: "Hispânico/Latino",
          nonhisPanicOption: "Não-Hispânico/Latino",
          raceHelp:
            "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
          optionAIdisplay: "Americano nativo",
          optionAsiandisplay_VALUE: "Asiático",
          optionAsiandisplay: "Asiático",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Negro/Afro-americano",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Nativo das ilhas do Pacífico",
          optionwhitedisplay_VALUE: "Branco",
          optionwhitedisplay: "Branco",
          declineToAnswerLabel: "Não quero responder",
        };

      case ZH: //China
        return {
          title: "关于我",
          header: "关于我",
          labelFirstName: "您的名字",
          firstNameErrMsg: "必填 - 請輸入名字",
          middleName: "您的中间名",
          middleNameErrMsg: "請輸入中間名",
          lastNamelabel: "您的姓氏",
          lastNameErrMsg: "必填 - 請輸入姓氏",
          labelSuffix: "后缀",
          suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
          dateOfBirthLabel: "您的出生日期？",
          dobAlertMsg: "請輸入有效的出生日期",
          ssnLabel: "你的社保号码？",
          inputClearLabel: "我没有社保",
          ssnErrMsg: "請輸入有效的社會安全號碼",
          headerMyContactInfo: "我的联系信息",
          houselessLabel: "您的家庭是否无家可归？",
          houselessErrMsg: "請選擇您是否有家",
          houseLessOptions: ["是", "否"],
          addrQuestionlabel: "您的家庭地址？",
          validZipcode: "請輸入有效的郵政編碼",
          alsoMailingLabel: "这是否也是您的邮寄地址？",
          optionsSendMail: "是，可以发送信件到这里。",
          optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
          mailingQlabel: "您的邮箱地址？",
          zipCodeErrmsg: "请输入有效邮编",
          citiErrmsg: "请输入有效城市",
          addressErrmsg: "请输入有效地址",
          bestPhoneqlabel: "您最常使用的电话号码？",
          inlineHelp: "这将是DTA与您联络所使用的电话号码。",
          validPhoneAlert: "請輸入一個有效的電話號碼",
          emailAddressLabel: "电子邮箱地址？",
          emailErrormsg: "請輸入有效的電子郵件地址",
          titleEmergency: "紧急SNAP补助",
          headerEmergency: "紧急SNAP补助",
          emergencySnap:
            "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
          incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
          inlineHelpLabel:
            "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
          optionYesNo: ["是", "否"],
          alertSelectIncome: "請選擇您支付的收入",
          incomeLesslabel:
            "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
          monthlyIncomeoptions: ["是", "否"],
          selectIncomeErrmsg: "請選擇您支付的收入",
          migrantBefore: "您是否是 ",
          migrantTrigger: "外来务工人员",
          migrantAfter: " 您的银行存款是否少于100美元？",
          migrantHelp:
            "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
          migrantOptions: ["是 ", "否"],
          migrantErrmsg: "請選擇你支付的移民",
          headerMoreAbout: "关于我的更多信息",
          areUsBefore: "您是否是 ",
          areUsTrigger: "美国公民",
          areUsafter: "?",
          eligibleHelp:
            "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
          chooseErrmsg: "請選擇公民",
          eligibleMemberHelp:
            "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
          eligibleOptions: ["是", "否"],
          laguageBefore: "您 ",
          LANGUAGES: [
            "英语",
            "西班牙语",
            "葡萄牙语",
            "法语",
            "海地克里奥尔语",
            "中文",
            "越南语",
            "柬埔寨语",
            "朝鮮的",
            "俄语",
            "意大利语",
            "波兰语",
            "阿拉伯语",
          ],
          languageTrigger: "日常使用哪种语言交流",
          languageAfter: "?",
          comfortLanHelp:
            "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
          languageErrMsg: "請選擇語言",
          genderLabel: "您的性别？",
          genderOptions: ["男", "女"],
          genderErrmsg: "請選擇性別",
          labelEthnicity: "您的民族？",
          helpEthnicity:
            "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
          hisPanicOption: "西班牙/拉丁美洲",
          nonhisPanicOption: "非西班牙/拉丁美洲",
          raceLabel: "您的种族？",
          raceHelp:
            "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
          optionAIdisplay: "美洲印第安人",
          optionAsiandisplay_VALUE: "亚洲人",
          optionAsiandisplay: "亚洲人",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "黑人/非裔美国人",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "太平洋岛民",
          optionwhitedisplay_VALUE: "白人",
          optionwhitedisplay: "白人",
          declineToAnswerLabel: "拒绝回答",
        };

      case VI: //Vietnam
        return {
          title: "Thông tin về tôi",
          header: "Thông tin về tôi",
          labelFirstName: "Tên của quý vị?",
          firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
          middleName: "Tên lót của quý vị?",
          middleNameErrMsg: "Vui lòng nhập tên đệm",
          lastNamelabel: "Họ của quý vị?",
          lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
          labelSuffix: "Tước hiệu",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
          dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
          ssnLabel: "Số an sinh xã hội của quý vị?",
          inputClearLabel: "Tôi không có số ASXH",
          ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
          headerMyContactInfo: "Thông tin liên lạc",
          houselessLabel: "Gia đình quý vị có chỗ ở không?",
          houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          houseLessOptions: ["Có", "Không"],
          addrQuestionlabel: "Địa chỉ của quý vị là gì?",
          validZipcode: "Vui lòng nhập một mã zip hợp lệ",
          alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
          optionsSendMail: "Phải, hãy gửi thư đến đó.",
          optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
          mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
          zipCodeErrmsg: "Điền mã bưu điện",
          citiErrmsg: "Vui lòng nhập vào tên thành phố",
          addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
          bestPhoneqlabel: "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
          inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
          validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
          emailAddressLabel: "Địa chỉ email?",
          emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
          titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
          headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
          emergencySnap:
            "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
          incomeMoneylabel:
            "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
          inlineHelpLabel:
            "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
          optionYesNo: ["Có", "Không"],
          alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
          incomeLesslabel:
            "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
          monthlyIncomeoptions: ["Có", "Không"],
          selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
          migrantBefore: "Quý vị có phải là ",
          migrantTrigger: "công nhân di cư",
          migrantAfter:
            " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
          migrantHelp:
            "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
          migrantOptions: ["Có", "Không"],
          migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
          headerMoreAbout: "Thông tin thêm về tôi",
          areUsBefore: "Quý vị có là một ",
          areUsTrigger: "Công dân Hoa kỳ",
          areUsafter: "?",
          eligibleHelp:
            "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
          chooseErrmsg: "Vui lòng chọn công dân",
          eligibleMemberHelp:
            "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
          eligibleOptions: ["Có", "Không"],
          laguageBefore: "Quý vị ",
          LANGUAGES: [
            "Tiếng Anh",
            "Tiếng Tây Ban Nha",
            "Tiếng Bồ Đào nha",
            "Tiếng Pháp",
            "Tiếng Haitian Creole",
            "Tiếng Trung quốc",
            "Tiếng Việt",
            "Tiếng Campuchia",
            "Hàn Quốc",
            "Tiếng Nga",
            "Tiếng Ý",
            "Tiếng Ba Lan",
            "Tiếng Ả rập",
          ],
          languageTrigger: " thích nói ngôn ngữ nào hơn",
          languageAfter: "?",
          comfortLanHelp:
            "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
          languageErrMsg: "Vui lòng chọn ngôn ngữ",
          genderLabel: "Giới tính của quý vị là gì?",
          genderOptions: ["Nam", "Nữ"],
          genderErrmsg: "Vui lòng chọn giới tính",
          labelEthnicity: "Quý vị thuộc dân tộc nào?",
          hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
          nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
          helpEthnicity:
            "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
          raceLabel: "Quý vị thuộc chủng tộc nào?",
          raceHelp:
            "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
          optionAIdisplay: "Người Mỹ bản xứ",
          optionAsiandisplay_VALUE: "Người Châu Á",
          optionAsiandisplay: "Người Châu Á",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
          optionwhitedisplay_VALUE: "Người da trắng",
          optionwhitedisplay: "Người da trắng",
          declineToAnswerLabel: "Từ chối trả lời",
        };
      case HI:
        return {
          title: "Konsènan mwen menm",
          header: "Konsènan mwen menm",
          labelFirstName: "Ki prenon w?",
          firstNameErrMsg: "Obligatwa - Please enter first name",
          middleName: "Ki dezyèm prenon w?",
          middleNameErrMsg: "Tanpri antre dezyèm prenon",
          lastNamelabel: "Ki siyati w?",
          lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
          labelSuffix: "Sifiks",
          suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
          dateOfBirthLabel: "Ki dat nesans ou?",
          dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
          ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
          inputClearLabel: "M pa genyen youn",
          ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
          headerMyContactInfo: "Enfòmasyon kontak mwen",
          houselessLabel: "Èske fwaye w la sanzabri?",
          houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
          houseLessOptions: ["Wi", "Non"],
          addrQuestionlabel: "Ki adrès ou?",
          validZipcode: "Tanpri antre yon kòd postal ki valid",
          alsoMailingLabel: "Èske se adrès postal ou a tou?",
          optionsSendMail: "Wi, voye lapòs la.",
          optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
          mailingQlabel: "Ki adrès postal ou?",
          zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
          citiErrmsg: "Tanpri antre yon vil ki valid",
          addressErrmsg: "Tanpri antre yon adrès ki valid",
          bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
          inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
          validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
          emailAddressLabel: "Ki adrès imel ou?",
          emailErrormsg: "Tanpri antre yon adrès imel ki valid",
          titleEmergency: "Benefis SNAP nan Ijans",
          headerEmergency: "Benefis SNAP nan Ijans",
          emergencySnap:
            "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
          incomeMoneylabel:
            "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
          inlineHelpLabel:
            "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
          optionYesNo: ["Wi", "Non"],
          alertSelectIncome: "Tanpri chwazi revni ou peye",
          incomeLesslabel:
            "Èske revni mansyèl ou mwens pase $150epi èske lajan labank ou se $100 oswa mwens?",
          monthlyIncomeoptions: ["Wi", "Non"],
          selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
          migrantBefore: "Èske w se yon ",
          migrantTrigger: "travayè migran",
          migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
          migrantHelp:
            "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
          migrantOptions: ["Wi", "Non"],
          migrantErrmsg: "Tanpri chwazi migran ou peye",
          headerMoreAbout: "Plis konsènan mwen menm",
          areUsBefore: "Èske w se yon ",
          areUsTrigger: "sitwayen Ameriken",
          areUsafter: "?",
          eligibleHelp:
            "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
          chooseErrmsg: "Tanpri chwazi sitwayen",
          eligibleMemberHelp:
            "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
          eligibleOptions: ["Wi", "Non"],
          LANGUAGES: [
            "Angle",
            "Panyòl",
            "Pòtigè",
            "Franse",
            "Kreyòl Ayisyen",
            "Chinwa",
            "Vyetnamyen",
            "Kmè",
            "Korean",
            "Ris",
            "Italyen",
            "Polonè",
            "Arabik",
          ],
          languageBefore: "Ki lang ou ",
          languageTrigger: "prefere pale ",
          languageAfter: "?",
          comfortLanHelp:
            " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
          languageErrMsg: "Tanpri chwazi ki lang",
          genderLabel: "Ki sèks ou?",
          genderOptions: ["Gason", "Fi"],
          genderErrmsg: "Tanpri chwazi sèks",
          labelEthnicity: "Ki etnisite ou?",
          helpEthnicity:
            "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          raceLabel: "Ki ras ou?",
          raceHelp:
            "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
          optionAIdisplay: "Ameriken Endyen",
          optionAsiandisplay_VALUE: "ASIAN",
          optionAsiandisplay: "Azyatik",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Nwa/Afriken Ameriken",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "moun Zile Pasifik",
          optionwhitedisplay_VALUE: "WHITE",
          optionwhitedisplay: "Blan",
          declineToAnswerLabel: "Refize reponn",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.expeditedSnap) {
    switch (language) {
      case EN:
        return {
          titleEmergency: "Expedited SNAP Services",
          headerEmergency: "Expedited SNAP Services",
          emergencySnap:
            "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
          incomeMoneylabel:
            "Does your income and money in the bank add up to less than your monthly housing expenses?",
          inlineHelpLabel:
            "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
          optionYesNo: ["Yes", "No"],
          alertSelectIncome: "Please select income you pay",
          incomeLesslabel:
            "Is your monthly income less than $150 and is your money in the bank less than or equal to $100?",
          monthlyIncomeoptions: ["Yes", "No"],
          selectIncomeErrmsg: "Please select income you pay",
          migrantLabel:'Are you a migrant worker and is your money in the bank less than or equal to $100?',
          migrantOptions: ["Yes", "No"],
          migrantErrmsg: "Please select migrant you pay",
        }; //return End

      case ES:
        return {
          titleEmergency: "Beneficios de SNAP de emergencia",
          headerEmergency: "Beneficios de SNAP de emergencia",
          emergencySnap:
            "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
          incomeMoneylabel:
            "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
          inlineHelpLabel:
            "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
          optionYesNo: ["Sí", "No"],
          alertSelectIncome: "Por favor seleccione el ingreso que paga",
          incomeLesslabel:
            "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
          monthlyIncomeoptions: ["Sí", "No"],
          selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
          migrantBefore: "¿Es usted un ",
          migrantTrigger: " trabajador migrante",
          migrantAfter: " y su dinero en el banco es $100 o menos?",
          migrantLabel: "¿Es usted un trabajador migrante y su dinero en el banco es menor o igual a $100?",
          migrantHelp:
            "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
          migrantOptions: ["Sí", "No"],
          migrantErrmsg: "Por favor selecciona migrante pagas",
        }; //return End
      default:
        return;
    }
  } else if (pageType === languageConstants.householdInformation) {
    switch (language) {
      case EN:
        return {
          titleHouseholdInformation: "Household Information",
          tanftitleHouseholdInformation: "Household Information",
          headerHouseholdInformation: "Household Information",
          isEnrolledHigherLearningLabel:
            "Is anyone enrolled in an institution of higher learning at least half time?",
          isAnyoneStriker: "Is anyone a striker?",
          hasVoluntarilyQuitJob:
            "Has anyone voluntarily quit a job within the last 30 days?",
          isHouseholdBoarder: "Is anyone in the household a boarder?",
          isDisqualifiedIntlPrgVltn:
            "Has anyone in your household been disqualified for an intentional program violation?",
          isGuiltyBuySellFoodAsst:
            "Has anyone been found guilty by a court of buying or selling SNAP of $500 or more?",
          isGuiltyBuyFirearms:
            "Has anyone been found guilty by a court of using SNAP to buy firearms, ammunition, or explosives?",
          hasMisrepresentIdentity:
            "Has anyone fraudulently misrepresented identity or residence to get SNAP benefits?",
          hasConvictedFelonyDrugs:
            "Has anyone been convicted of a felony involving drugs after 8/22/1996?",
          isComplyingFelonyDrugsTS:
            "If yes, is the convicted member complying with the terms of sentence?",
          isConvictedSexualAbuseMurder:
            "Have you or any member of your household been convicted as an adult of aggravated sexual abuse, murder, sexual exploitation and other abuse of children, a Federal or State offense involving sexual assault, or an offense under State law determined by the Attorney General to be substantially similar to such an offense, after February 7, 2014?",
          isComplyingSexualAbuseMurderTS:
            "If yes, is the convicted member complying with the terms of the sentence?",
          isFleeingFelonParole:
            "Is anyone a fleeing felon or parole/probation violation?",
          isConvictedFraudDuplicateAsst:
            "Have you or any member of your household been convicted of fraudulently receiving duplicate SNAP benefits in any state?",
          hasReceivedLottery:
            "Have you or anyone in your household received lottery or gambling winnings of $4,250 or more this month?",
          options: ["Yes", "No"],
          selectHouseholdMem: "Select household members.",
          selectHouseholdMemErr: "Please Select household members.",
        };

      case ES:
        return {
          titleHouseholdInformation: "Información del hogar",
          headerHouseholdInformation: "Información del hogar",
          isEnrolledHigherLearningLabel:
            "¿Alguien está matriculado en una institución de educación superior al menos a tiempo parcial?",
          isAnyoneStriker: "¿Alguien es delantero?",
          hasVoluntarilyQuitJob:
            "¿Alguien ha renunciado voluntariamente a un trabajo en los últimos 30 días?",
          isHouseholdBoarder: "¿Alguien en el hogar es huésped?",
          isDisqualifiedIntlPrgVltn:
            "¿Alguien en su hogar ha sido descalificado por una violación intencional del programa?",
          isGuiltyBuySellFoodAsst:
            "¿Alguien ha sido declarado culpable por un tribunal de comprar o vender SNAP por $500 o más?",
          isGuiltyBuyFirearms:
            "¿Alguien ha sido declarado culpable por un tribunal de utilizar SNAP para comprar armas de fuego, municiones o explosivos?",
          hasMisrepresentIdentity:
            "¿Alguien ha tergiversado fraudulentamente su identidad o residencia para obtener beneficios de SNAP?",
          hasConvictedFelonyDrugs:
            "¿Alguien ha sido condenado por un delito grave relacionado con drogas después del 22/8/1996?",
          isComplyingFelonyDrugsTS:
            "En caso afirmativo, ¿el miembro condenado cumple con los términos de la sentencia?",
          isConvictedSexualAbuseMurder:
            "¿Usted o algún miembro de su hogar ha sido condenado como adulto por abuso sexual agravado, asesinato, explotación sexual y otros abusos de niños, un delito federal o estatal que involucre agresión sexual, o un delito bajo la ley estatal que el Fiscal General determine que es sustancialmente similar a tal delito, después del 7 de febrero de 2014?",
          isComplyingSexualAbuseMurderTS:
            "En caso afirmativo, ¿el miembro condenado está cumpliendo con los términos de la sentencia?",
          isFleeingFelonParole:
            "¿Es alguien un delincuente que ha huido o ha violado su libertad condicional?",
          isConvictedFraudDuplicateAsst:
            "¿Usted o algún miembro de su hogar ha sido condenado por recibir de manera fraudulenta beneficios SNAP duplicados en algún estado?",
          hasReceivedLottery:
            "¿Usted o alguien en su hogar recibió ganancias de lotería o juegos de azar de $4,250 o más este mes?",
          options: ["Sí", "No"],
          selectHouseholdMem: "Seleccione los miembros del hogar.",
          selectHouseholdMemErr: "Seleccione los miembros del hogar.",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.contactInfo) {
    //2nd section page
    switch (language) {
      case EN:
        return {
          title: "Contact Information",
          header: "My Contact Info",
          addContactInfoTitle: "Change my address",
          addContactInfoHeader: "Change my address",
          contactChangedQuestion:
            "Has there been any change in your contact information? (Required)",
          required: "Required",
          requiredErrorMessage: "Please choose an option.",
          addContactInfoSubHeader:
            'To submit an online SNAP Recertification you must provide a mailing address. If you do not have an address DHR can send mail to, please call Project Bread Food Source Hotline at <a href="tel:18006458333">1-800-645-8333</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR office</a>',
          labelFirstName: "What is your first name?",
          firstNameErrMsg: "Required - Please enter the first name",
          middleName: "What is your middle name?",
          middleNameErrMsg: "Please enter the middle name",
          lastNamelabel: "What is your last name?",
          lastNameErrMsg: "Required - Please enter last name",
          labelSuffix: "Suffix",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "What is your date of birth?",
          dobAlertMsg: "Please enter a valid Date of Birth",
          ssnLabel: "What is your Social Security Number?",
          inputClearLabel: "I don't have one",
          ssnErrMsg: "Please enter a valid Social Security Number.",
          headerMyContactInfo: "My Contact Info",
          houselessLabel: "Is your household homeless?",
          homeAddressLabel: "What is your home address?",
          resAddressLabel: "Home Address",
          resSameAddressLabel: "Same as home address",
          mailingAddresssLabel: "Mailing address",
          houselessErrMsg: "Please select whether your having home or not",
          houseLessOptions: ["Yes", "No"],
          addrQuestionlabel: "What is your address?",
          validZipcode: "Please enter a valid Zipcode.",
          alsoMailingLabel: "Is this also your mailing address?",
          optionsSendMail: "Yes, send mail there.",
          optionsNoDiffAddress: "No, I use a different address for mail.",
          mailingQlabel: "What is your mailing address?",
          zipCodeErrmsg: "Please enter a valid Zipcode.",
          countyErrmsg: "Please enter a valid County.",
          bestPhoneqlabel: "What is the best phone number to reach you?",
          inlineHelp: "This is the number the DHR will use to reach you.",
          validPhoneAlert: "Please enter a valid phone number.",
          editButtonLabel: "Edit",
          doneButtonlabel: "Done",
          phoneEditTitle: "Edit my phone details",
          phoneLabel: "Primary phone number",
          phoneNotificationQuestion: "Allow text message notifications?",
          phoneNotificationAgreeLabel:
            "DHR may be able to send you text messages about due dates, case information, office closings, and other important information.  Click yes if you would like to opt-in",
          emailEditTitle: "E-mail",
          emailLabel: "Edit my e-mail address",
          emailNotificationQuestion: "Allow email notifications?",
          emailNotificationAgreeLabel:
            "DHR may be able to send you email notifications about due dates, case information, office closings, and other important information.  Click yes if you would like to opt-in",
          emailAddressLabel: "What is your email address?",
          emailErrormsg: "Please enter a valid email address.",
          titleEmergency: "Emergency SNAP benefits",
          headerEmergency: "Emergency SNAP benefits",
          emergencySnap:
            "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
          incomeMoneylabel:
            "Does your income and money in the bank add up to less than your monthly housing expenses?",
          inlineHelpLabel:
            "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
          optionYesNo: ["Yes", "No"],
          alertSelectIncome: "Please select income you pay",
          incomeLesslabel:
            "Is your monthly income less than $150 and is your money in the bank $100 or less?",
          monthlyIncomeoptions: ["Yes", "No"],
          selectIncomeErrmsg: "Please select income you pay",
          migrantLabel:'Are you a migrant worker and is your money in the bank less than or equal to $100?',
          migrantOptions: ["Yes", "No"],
          migrantErrmsg: "Please select migrant you pay",
          headerMoreAbout: "More about me",
          areUsBefore: "Are you a ",
          areUsTrigger: "US citizen",
          areUsafter: "?",
          eligibleHelp:
            "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
          chooseErrmsg: "Please choose citizen",
          eligibleMemberHelp:
            "Even if you are not a US Citizen, you or other household members may still be eligible.",
          eligibleOptions: ["Yes", "No"],
          laguageBefore: "What language do you ",
          LANGUAGES: [
            "English",
            "Spanish",
            "Portuguese",
            "French",
            "Haitian Creole",
            "Chinese",
            "Vietnamese",
            "Khmer",
            "Korean",
            "Russian",
            "Italian",
            "Polish",
            "Arabic",
          ],
          languageTrigger: "prefer to speak",
          languageAfter: "?",
          comfortLanHelp:
            "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
          languageErrMsg: "Please choose language",
          genderLabel: "What is your gender?",
          genderOptions: ["Male", "Female"],
          genderErrmsg: "Please choose gender",
          labelEthnicity: "What is your ethnicity?",
          helpEthnicity:
            "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          raceLabel: "What is your race?",
          raceHelp:
            "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
          optionAIdisplay: "American Indian",
          optionAsiandisplay_VALUE: "ASIAN",
          optionAsiandisplay: "Asian",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Black/African American",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Pacific Islander",
          optionwhitedisplay_VALUE: "WHITE",
          optionwhitedisplay: "White",
          declineToAnswerLabel: "Decline to answer",
        };

      case ES:
        return {
          title: "Información de contacto",
          header: "Mi información de contacto",
          addContactInfoTitle: "Cambiar mi dirección",
          addContactInfoHeader: "Cambiar mi dirección",
          contactChangedQuestion:
            "¿Se han producido cambios en su información de contacto? (Obligatorio)",
          required: "Obligatorio",
          requiredErrorMessage: "Escoja una opción",
          addContactInfoSubHeader:
            'Para presentar un Recertificación del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de la Fuente de alimentos de Project Bread al<a href="tel:18006458333">1-800-645-8333</a> o diríjase a la oficina local de DHR.',
          labelFirstName: "¿Cuál es su nombre?",
          firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          middleName: "¿Cuál es su segundo nombre?",
          middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          lastNamelabel: "¿Cuál es su apellido?",
          lastNameErrMsg: "Requerido: ingrese el apellido",
          labelSuffix: "Sufijo",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
          dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
          ssnLabel: "¿Cuál es su número de seguro social?",
          inputClearLabel: "No tengo",
          ssnErrMsg: "Por favor ingrese un número de seguro social válido",
          headerMyContactInfo: "Mi información de contacto",
          houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
          homeAddressLabel: "¿Cual es la dirección de tu casa?",
          resAddressLabel: "Dirección residencial",
          resSameAddressLabel: "Igual que el domicilio",
          mailingAddresssLabel: "Dirección postal",
          houselessErrMsg: "Seleccione si tiene o no su hogar",
          houseLessOptions: ["Sí", "No"],
          addrQuestionlabel: "¿Cuál es su dirección?",
          validZipcode: "Por favor ingrese un código postal válido.",
          alsoMailingLabel: "¿Es igual a su dirección postal?",
          optionsSendMail: "Sí, enviar correo allí. ",
          optionsNoDiffAddress:
            "No, utilizo una dirección diferente para el correo postal.",
          mailingQlabel: "¿Cuál es su dirección postal?",
          zipCodeErrmsg: "Por favor ingrese un código postal válido.",
          bestPhoneqlabel:
            "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
          inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
          validPhoneAlert: "Por favor ingrese un número de teléfono válido",
          editButtonLabel: "Editar",
          doneButtonlabel: "Listo",
          phoneEditTitle: "Editar los detalles de mi teléfono",
          phoneLabel: "Teléfono principal",
          phoneNotificationQuestion:
            "Permitir notificaciones por mensaje de texto?",
          phoneNotificationAgreeLabel:
            "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
          emailEditTitle: "Dirección de correo electrónico",
          emailLabel: "Editar mi dirección de correo electrónico",
          emailNotificationQuestion:
            "Permitir notificaciones por correo electrónico?",
          emailNotificationAgreeLabel:
            "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
          emailAddressLabel: "Dirección de correo electrónico?",
          emailErrormsg:
            "Por favor, introduce una dirección de correo electrónico válida",
          titleEmergency: "Beneficios de SNAP de emergencia",
          headerEmergency: "Beneficios de SNAP de emergencia",
          emergencySnap:
            "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
          incomeMoneylabel:
            "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
          inlineHelpLabel:
            "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
          optionYesNo: ["Sí", "No"],
          alertSelectIncome: "Por favor seleccione el ingreso que paga",
          incomeLesslabel:
            "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
          monthlyIncomeoptions: ["Sí", "No"],
          selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
          migrantBefore: "¿Es usted un ",
          migrantTrigger: " trabajador migrante",
          migrantAfter: " y su dinero en el banco es $100 o menos?",
          migrantHelp:
            "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
          migrantOptions: ["Sí", "No"],
          migrantErrmsg: "Por favor selecciona migrante pagas",
          headerMoreAbout: "Más sobre mí",
          areUsBefore: "¿Es usted ",
          areUsTrigger: "ciudadano de EE. UU.",
          areUsafter: "?",
          eligibleHelp:
            "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
          chooseErrmsg: "Por favor elija ciudadano",
          eligibleMemberHelp:
            "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
          eligibleOptions: ["Sí", "No"],
          LANGUAGES: [
            "Inglés",
            "Español",
            "Portugués",
            "Francés",
            "Criollo haitiano",
            "Chino",
            "Vietnamita",
            "Camboyano",
            "Coreano",
            "Ruso",
            "Italiano",
            "Polaco",
            "Árabe",
          ],
          laguageBefore: "¿Qué idioma ",
          languageTrigger: " prefiere hablar",
          languageAfter: "?",
          comfortLanHelp:
            "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
          languageErrMsg: "Por favor, elija el idioma",
          genderLabel: "¿Cuál es su sexo?",
          genderOptions: ["Masculino", "Femenino"],
          genderErrmsg: "Por favor elija género",
          labelEthnicity: "¿Cuál es tu origen étnico?",
          helpEthnicity:
            "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
          hisPanicOption: "Hispano/Latino",
          nonhisPanicOption: "No hispano/Latino",
          raceLabel: "¿Cuál es tu raza?",
          raceHelp:
            "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
          optionAIdisplay: "Indio americano",
          optionAsiandisplay_VALUE: "Asiático",
          optionAsiandisplay: "Asiático",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Negro/Afroamericano",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Isleño del Pacífico",
          optionwhitedisplay_VALUE: "Blanco",
          optionwhitedisplay: "Blanco",
          declineToAnswerLabel: "Me niego a responder",
        };

      case PT: //portugeesh
        return {
          title: "Dados de contato",
          header: "Minhas informações de contato",
          addContactInfoTitle: "Alterar meu endereço",
          addContactInfoHeader: "Alterar meu endereço",
          contactChangedQuestion:
            "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
          required: "Obrigatório",
          requiredErrorMessage: "Por favor escolha uma opção",
          addContactInfoSubHeader:
            'Para enviar um Recertificação SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para a Linha Direta do projeto Project Bread Food Source no número <a href="tel:18006458333">1-800-645-8333</a> ou acesse o escritório local do DHR.',
          labelFirstName: "Qual é o seu primeiro nome?",
          firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
          middleName: "Qual é o seu nome do meio?",
          middleNameErrMsg: "Digite o nome do meio",
          lastNamelabel: "Qual é o seu último nome?",
          lastNameErrMsg: "Obrigatório - Digite o sobrenome",
          labelSuffix: "Sufixo",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "Qual é a sua data de nascimento?",
          dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
          ssnLabel: "Qual é o número do seu seguro social?",
          inputClearLabel: "Eu não tenho",
          ssnErrMsg: "Digite um Número de Segurança Social válido",
          headerMyContactInfo: "Minhas informações de contato",
          houselessLabel: "Sua família não tem moradia?",
          resAddressLabel: "Endereço residencial",
          resSameAddressLabel: "O mesmo endereço residencial",
          mailingAddresssLabel: "Endereço de correspondência",
          houselessErrMsg: "Selecione se você tem casa ou não",
          houseLessOptions: ["Sim", "Não"],
          addrQuestionlabel: "Qual é o seu endereço?",
          validZipcode: "Por favor insira um código postal válido",
          alsoMailingLabel: "Esse também é o seu endereço de correspondência?",
          optionsSendMail:
            "Sim, envie as correspondências para esse endereço. ",
          optionsNoDiffAddress:
            "Não, eu uso um endereço diferente para correspondência.",
          mailingQlabel: "Qual é o seu endereço de correspondência? ",
          zipCodeErrmsg: "Por favor insira um código postal válido",
          bestPhoneqlabel:
            "Qual é o melhor número de telefone para falar com você?",
          inlineHelp:
            "Esse será o número que o DHR irá usar para falar com você.",
          validPhoneAlert: "Por favor insira um número de telefone válido",
          editButtonLabel: "Editar",
          doneButtonlabel: "Concluir",
          phoneEditTitle: "Editar of detalhes do meu telefone",
          phoneLabel: "Número de telefone principal",
          phoneNotificationQuestion:
            "Permitir notificações por mensagens de texto?",
          phoneNotificationAgreeLabel:
            "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
          emailEditTitle: "Endereço de e-mail",
          emailLabel: "Editar of detalhes do meu endereçol",
          emailNotificationQuestion: "Permitir notificações por e-mail?",
          emailNotificationAgreeLabel:
            "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
          emailAddressLabel: "Endereço de e-mail",
          emailErrormsg: "Por favor insira um endereço de e-mail válido",
          titleEmergency: "Benefício SNAP emergencial",
          headerEmergency: "Benefício SNAP emergencial",
          emergencySnap:
            "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
          incomeMoneylabel:
            "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
          inlineHelpLabel:
            'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
          optionYesNo: ["Sim", "Não"],
          alertSelectIncome: "Selecione a renda que você paga",
          incomeLesslabel:
            "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
          monthlyIncomeoptions: ["Sim", "Não"],
          selectIncomeErrmsg: "Selecione a renda que você paga",
          migrantBefore: "Você é um ",
          migrantTrigger: "trabalhador migrante",
          migrantAfter: " e tem US$ 100 ou menos no banco?",
          migrantHelp:
            "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
          migrantOptions: ["Sim", "Não"],
          migrantErrmsg: "Selecione o migrante que você paga",
          headerMoreAbout: "Mais sobre mim",
          areUsBefore: "Você é ",
          areUsTrigger: "cidadão americano",
          areUsafter: "?",
          eligibleHelp:
            "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
          chooseErrmsg: "Escolha cidadão",
          eligibleMemberHelp:
            "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
          eligibleOptions: ["Sim", "Não"],
          laguageBefore: "Em qual idioma você ",
          LANGUAGES: [
            "Inglês",
            "Espanhol",
            "Português",
            "Francês",
            "Créole haitiano",
            "Chinês",
            "Vietnamita",
            "Kmè",
            "Coreano",
            "Russo",
            "Italiano",
            "Polonês",
            "Árabe",
          ],
          languageTrigger: "prefere se comunicar",
          languageAfter: "?",
          comfortLanHelp:
            "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
          languageErrMsg: "Escolha o idioma",
          genderLabel: "Qual é o seu gênero?",
          genderOptions: ["Masculino", "Feminino"],
          genderErrmsg: "Escolha o gênero",
          labelEthnicity: "Qual é a sua etnia?",
          helpEthnicity:
            "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
          raceLabel: "Qual é a sua raça?",
          hisPanicOption: "Hispânico/Latino",
          nonhisPanicOption: "Não-Hispânico/Latino",
          raceHelp:
            "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
          optionAIdisplay: "Americano nativo",
          optionAsiandisplay_VALUE: "Asiático",
          optionAsiandisplay: "Asiático",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Negro/Afro-americano",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Nativo das ilhas do Pacífico",
          optionwhitedisplay_VALUE: "Branco",
          optionwhitedisplay: "Branco",
          declineToAnswerLabel: "Não quero responder",
        };

      case ZH: //China
        return {
          title: "联系信息",
          header: "我的联系信息",
          addContactInfoTitle: "更改我的地址",
          addContactInfoHeader: "更改我的地址",
          contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
          required: "必填",
          requiredErrorMessage: "请选择一个选项",
          addContactInfoSubHeader:
            '如需在线提交 SNAP《开始重新认证》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请致电面包项目食物来源热线 (Project Bread Food Source Hotline) <a href="tel:18006458333">1-800-645-8333</a> 或前往您当地的 DHR 办事处.',
          labelFirstName: "您的名字",
          firstNameErrMsg: "必填 - 請輸入名字",
          middleName: "您的中间名",
          middleNameErrMsg: "請輸入中間名",
          lastNamelabel: "您的姓氏",
          lastNameErrMsg: "必填 - 請輸入姓氏",
          labelSuffix: "后缀",
          suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
          dateOfBirthLabel: "您的出生日期？",
          dobAlertMsg: "請輸入有效的出生日期",
          ssnLabel: "你的社保号码？",
          inputClearLabel: "我没有社保",
          ssnErrMsg: "請輸入有效的社會安全號碼",
          headerMyContactInfo: "我的联系信息",
          houselessLabel: "您的家庭是否无家可归？",
          resAddressLabel: "住宅地址",
          resSameAddressLabel: "与居住地址相同",
          mailingAddresssLabel: "邮寄地址",
          houselessErrMsg: "請選擇您是否有家",
          houseLessOptions: ["是", "否"],
          addrQuestionlabel: "您的家庭地址？",
          validZipcode: "請輸入有效的郵政編碼",
          alsoMailingLabel: "这是否也是您的邮寄地址？",
          optionsSendMail: "是，可以发送信件到这里。",
          optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
          mailingQlabel: "您的邮箱地址？",
          zipCodeErrmsg: "請輸入有效的郵政編碼",
          bestPhoneqlabel: "您最常使用的电话号码？",
          inlineHelp: "这将是DTA与您联络所使用的电话号码。",
          validPhoneAlert: "請輸入一個有效的電話號碼",
          editButtonLabel: "编辑",
          doneButtonlabel: "完成",
          phoneEditTitle: "编辑我的手机详情",
          phoneLabel: "主要电话号码",
          phoneNotificationQuestion: "允许短信通知?",
          phoneNotificationAgreeLabel:
            "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
          emailEditTitle: "电子邮箱地址",
          emailLabel: "编辑我的电子邮件地址",
          emailNotificationQuestion: "允许电子邮件通知?",
          emailNotificationAgreeLabel:
            "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
          emailAddressLabel: "电子邮箱地址？",
          emailErrormsg: "請輸入有效的電子郵件地址",
          titleEmergency: "紧急SNAP补助",
          headerEmergency: "紧急SNAP补助",
          emergencySnap:
            "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
          incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
          inlineHelpLabel:
            "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
          optionYesNo: ["是", "否"],
          alertSelectIncome: "請選擇您支付的收入",
          incomeLesslabel:
            "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
          monthlyIncomeoptions: ["是", "否"],
          selectIncomeErrmsg: "請選擇您支付的收入",
          migrantBefore: "您是否是 ",
          migrantTrigger: "外来务工人员",
          migrantAfter: " 您的银行存款是否少于100美元？",
          migrantHelp:
            "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
          migrantOptions: ["是 ", "否"],
          migrantErrmsg: "請選擇你支付的移民",
          headerMoreAbout: "关于我的更多信息",
          areUsBefore: "您是否是 ",
          areUsTrigger: "美国公民",
          areUsafter: "?",
          eligibleHelp:
            "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
          chooseErrmsg: "請選擇公民",
          eligibleMemberHelp:
            "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
          eligibleOptions: ["是", "否"],
          laguageBefore: "您 ",
          LANGUAGES: [
            "英语",
            "西班牙语",
            "葡萄牙语",
            "法语",
            "海地克里奥尔语",
            "中文",
            "越南语",
            "柬埔寨语",
            "朝鮮的",
            "俄语",
            "意大利语",
            "波兰语",
            "阿拉伯语",
          ],
          languageTrigger: "日常使用哪种语言交流",
          languageAfter: "?",
          comfortLanHelp:
            "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
          languageErrMsg: "請選擇語言",
          genderLabel: "您的性别？",
          genderOptions: ["男", "女"],
          genderErrmsg: "請選擇性別",
          labelEthnicity: "您的民族？",
          helpEthnicity:
            "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
          hisPanicOption: "西班牙/拉丁美洲",
          nonhisPanicOption: "非西班牙/拉丁美洲",
          raceLabel: "您的种族？",
          raceHelp:
            "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
          optionAIdisplay: "美洲印第安人",
          optionAsiandisplay_VALUE: "亚洲人",
          optionAsiandisplay: "亚洲人",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "黑人/非裔美国人",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "太平洋岛民",
          optionwhitedisplay_VALUE: "白人",
          optionwhitedisplay: "白人",
          declineToAnswerLabel: "拒绝回答",
        };

      case VI: //Vietnam
        return {
          title: "Thông tin liên lạc",
          header: "Thông tin liên lạc",
          addContactInfoTitle: "Thay đổi địa chỉ của tôi",
          addContactInfoHeader: "Thay đổi địa chỉ của tôi",
          contactChangedQuestion:
            "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
          required: "Bắt buộc",
          requiredErrorMessage: "Vui lòng chọn một lựa chọn",
          addContactInfoSubHeader:
            'Để gửi Bắt đầu mẫu Chứng nhận lại SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, xin vui lòng gọi Đường dây nóng của Project Bread Food Source theo sốat <a href="tel:18006458333">1-800-645-8333</a> hoặc đến Văn phòng DHR địa phương của quý vị.',
          labelFirstName: "Tên của quý vị?",
          firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
          middleName: "Tên lót của quý vị?",
          middleNameErrMsg: "Vui lòng nhập tên đệm",
          lastNamelabel: "Họ của quý vị?",
          lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
          labelSuffix: "Tước hiệu",
          suffixOption: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
          dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
          ssnLabel: "Số an sinh xã hội của quý vị?",
          inputClearLabel: "Tôi không có số ASXH",
          ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
          headerMyContactInfo: "Thông tin liên lạc",
          houselessLabel: "Gia đình quý vị có chỗ ở không?",
          resAddressLabel: "Địa chỉ cư trú",
          mailingAddresssLabel: "Địa chỉ gửi thư",
          resSameAddressLabel: "Giống như địa chỉ nhà ở",
          houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
          houseLessOptions: ["Có", "Không"],
          addrQuestionlabel: "Địa chỉ của quý vị là gì?",
          validZipcode: "Vui lòng nhập một mã zip hợp lệ",
          alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
          optionsSendMail: "Phải, hãy gửi thư đến đó.",
          optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
          mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
          zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
          bestPhoneqlabel: "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
          inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
          validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
          editButtonLabel: "Chỉnh sửa",
          doneButtonlabel: "Hoàn tất",
          phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
          phoneLabel: "Số điện thoại chính",
          phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
          phoneNotificationAgreeLabel:
            "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
          emailEditTitle: "Địa chỉ email",
          emailLabel: "Chỉnh sửa địa chỉ email của tôi",
          emailNotificationQuestion: "Cho phép thông báo bằng email ?",
          emailNotificationAgreeLabel:
            "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
          emailAddressLabel: "Địa chỉ email?",
          emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
          titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
          headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
          emergencySnap:
            "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
          incomeMoneylabel:
            "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
          inlineHelpLabel:
            "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
          optionYesNo: ["Có", "Không"],
          alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
          incomeLesslabel:
            "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
          monthlyIncomeoptions: ["Có", "Không"],
          selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
          migrantBefore: "Quý vị có phải là ",
          migrantTrigger: "công nhân di cư",
          migrantAfter:
            " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
          migrantHelp:
            "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
          migrantOptions: ["Có", "Không"],
          migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
          headerMoreAbout: "Thông tin thêm về tôi",
          areUsBefore: "Quý vị có là một ",
          areUsTrigger: "Công dân Hoa kỳ",
          areUsafter: "?",
          eligibleHelp:
            "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
          chooseErrmsg: "Vui lòng chọn công dân",
          eligibleMemberHelp:
            "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
          eligibleOptions: ["Có", "Không"],
          laguageBefore: "Quý vị ",
          LANGUAGES: [
            "Tiếng Anh",
            "Tiếng Tây Ban Nha",
            "Tiếng Bồ Đào nha",
            "Tiếng Pháp",
            "Tiếng Haitian Creole",
            "Tiếng Trung quốc",
            "Tiếng Việt",
            "Tiếng Campuchia",
            "Hàn Quốc",
            "Tiếng Nga",
            "Tiếng Ý",
            "Tiếng Ba Lan",
            "Tiếng Ả rập",
          ],
          languageTrigger: " thích nói ngôn ngữ nào hơn",
          languageAfter: "?",
          comfortLanHelp:
            "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
          languageErrMsg: "Vui lòng chọn ngôn ngữ",
          genderLabel: "Giới tính của quý vị là gì?",
          genderOptions: ["Nam", "Nữ"],
          genderErrmsg: "Vui lòng chọn giới tính",
          labelEthnicity: "Quý vị thuộc dân tộc nào?",
          hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
          nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
          helpEthnicity:
            "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
          raceLabel: "Quý vị thuộc chủng tộc nào?",
          raceHelp:
            "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
          optionAIdisplay: "Người Mỹ bản xứ",
          optionAsiandisplay_VALUE: "Người Châu Á",
          optionAsiandisplay: "Người Châu Á",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
          optionwhitedisplay_VALUE: "Người da trắng",
          optionwhitedisplay: "Người da trắng",
          declineToAnswerLabel: "Từ chối trả lời",
        };
      case HI:
        return {
          title: "Enfòmasyon sou kontak",
          header: "Enfòmasyon kontak mwen",
          addContactInfoTitle: "Chanje adrès mwen",
          addContactInfoHeader: "Chanje adrès mwen",
          contactChangedQuestion:
            "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",
          required: "Obligatwa",
          requiredErrorMessage: "Tanpri chwazi yon opsyon",
          addContactInfoSubHeader:
            'Pou soumèt yon Resètifikasyon SNAP an liy ou dwe bay yon adrès postal. Si w pa gen yon adrès kote DHR ka voye lapòs, tanpri rele Liy Asistans Project Bread Food Source nan <a href="tel:18006458333">1-800-645-8333</a> oswa ale nan <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank"> biwo lokal DHR </a> lokal w la.',
          labelFirstName: "Ki prenon w?",
          firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
          middleName: "Ki dezyèm prenon w?",
          middleNameErrMsg: "Tanpri antre dezyèm prenon",
          lastNamelabel: "Ki siyati w?",
          lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
          labelSuffix: "Sifiks",
          suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
          dateOfBirthLabel: "Ki dat nesans ou?",
          dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
          ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
          inputClearLabel: "M pa genyen youn",
          ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
          headerMyContactInfo: "Enfòmasyon kontak mwen",
          houselessLabel: "Èske fwaye w la sanzabri?",
          resAddressLabel: "Adrès rezidansyèl",
          resSameAddressLabel: "Menm ak Adrès Rezidansyèl",
          mailingAddresssLabel: "Adrès postal",
          houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
          houseLessOptions: ["Wi", "Non"],
          addrQuestionlabel: "Ki adrès ou?",
          validZipcode: "Tanpri antre yon kòd postal ki valid",
          alsoMailingLabel: "Èske se adrès postal ou a tou?",
          optionsSendMail: "Wi, voye lapòs la.",
          optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
          mailingQlabel: "Ki adrès postal ou?",
          zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
          bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
          inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
          validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
          editButtonLabel: "Edite",
          doneButtonlabel: "Fini",
          phoneEditTitle: "Edite detay telefòn mwen yo",
          phoneLabel: "Nimewo telefòn prensipal la",
          phoneNotificationQuestion: "Pèmèt notifikasyon mesaj tèks?",
          phoneNotificationAgreeLabel:
            "DHR ka anmezi pou voye mesaj tèks ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe.",
          emailEditTitle: "Imel",
          emailLabel: "Korije adrès imel mwen",
          emailNotificationQuestion: "Pèmèt notifikasyon imel?",
          emailNotificationAgreeLabel:
            "DHR ka anmezi pou voye imèl notifikasyon ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe",
          emailAddressLabel: "Ki adrès imel ou?",
          emailErrormsg: "Tanpri antre yon adrès imel ki valid",
          titleEmergency: "Benefis SNAP nan Ijans",
          headerEmergency: "Benefis SNAP nan Ijans",
          emergencySnap:
            "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
          incomeMoneylabel:
            "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
          inlineHelpLabel:
            "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
          optionYesNo: ["Wi", "Non"],
          alertSelectIncome: "Tanpri chwazi revni ou peye",
          incomeLesslabel:
            "Èske revni mansyèl ou mwens pase $150epi èske lajan labank ou se $100 oswa mwens?",
          monthlyIncomeoptions: ["Wi", "Non"],
          selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
          migrantBefore: "Èske w se yon ",
          migrantTrigger: "travayè migran",
          migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
          migrantHelp:
            "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
          migrantOptions: ["Wi", "Non"],
          migrantErrmsg: "Tanpri chwazi migran ou peye",
          headerMoreAbout: "Plis konsènan mwen menm",
          areUsBefore: "Èske w se yon ",
          areUsTrigger: "sitwayen Ameriken",
          areUsafter: "?",
          eligibleHelp:
            "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
          chooseErrmsg: "Tanpri chwazi sitwayen",
          eligibleMemberHelp:
            "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
          eligibleOptions: ["Wi", "Non"],
          LANGUAGES: [
            "Angle",
            "Panyòl",
            "Pòtigè",
            "Franse",
            "Kreyòl Ayisyen",
            "Chinwa",
            "Vyetnamyen",
            "Kmè",
            "Korean",
            "Ris",
            "Italyen",
            "Polonè",
            "Arabik",
          ],
          languageBefore: "Ki lang ou ",
          languageTrigger: "prefere pale ",
          languageAfter: "?",
          comfortLanHelp:
            " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
          languageErrMsg: "Tanpri chwazi ki lang",
          genderLabel: "Ki sèks ou?",
          genderOptions: ["Gason", "Fi"],
          genderErrmsg: "Tanpri chwazi sèks",
          labelEthnicity: "Ki etnisite ou?",
          helpEthnicity:
            "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          raceLabel: "Ki ras ou?",
          raceHelp:
            "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
          optionAIdisplay: "Ameriken Endyen",
          optionAsiandisplay_VALUE: "Azyatik",
          optionAsiandisplay: "Azyatik",
          optionBlackdisplay_VALUE: "BLCAFR",
          optionBlackdisplay: "Nwa/Afriken Ameriken",
          optionPacificdisplay_VALUE: "NTHPCI",
          optionPacificdisplay: "moun Zile Pasifik",
          optionwhitedisplay_VALUE: "WHITE",
          optionwhitedisplay: "Blan",
          declineToAnswerLabel: "Refize reponn",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.householdStaticRecert) {
    //3rd section page
    switch (language) {
      case EN:
        return {
          houseTitle: "Household Members",
          authRepTitle: "Authorized Representative",
          houseHeader: "About My Household",
          howManyLabel:
            "How many people live in your household, including you?",
          houseHelp:
            "If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.",
          houseErrMsg: "Please choose no.of household",
          houseMemberTitle: "Add a New Household Member",
          houseMemberSubHeader: "To add new members to your TANF case an application must be received. You can submit an application along with this document.",
          additionalhouseMemberTitle: "Additional Household Member Details",
          houseMemberHeader: "Your first household member is ",
          houseFirstName: "What is this member's first name?",
          householdDetaillabel: "Household Details",
          firstErrmsg: "Please enter the first name.",
          houseMiddleLabel: "What is this member's middle name?",
          houseMiddleName: "Please enter middle name",
          houseLastNameLabel: "What is this member's last name?",
          enterLastname: "Please enter the last name.",
          enterDOB: "Please enter a valid date of birth.",
          suffixLabel: "Suffix",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "What is this member's relationship to you?",
          spouseDisplay: "Spouse",
          motherDisplay: "Mother",
          faterDisplay: "Father",
          daughterDisplay: "Daughter",
          sonDisplay: "Son",
          brotherDisplay: "Brother",
          sisterDisplay: "Sister",
          grandMaDisplay: "Grandmother",
          grandFaDisplay: "Grandfather",
          grandDaugther: "Granddaughter",
          grandSon: "Grandson",
          otherDisplay: "Other",
          houseDobLabel: "What is this member's date of birth?",
          personGenLabel: "What is this member's gender?",
          genderOptios: ["Male", "Female"],
          citiBeforeQLabel: "Is this member a ",
          citiTriggerQLabel: "US citizen",
          citiAfterQLabel: "? ***",
          citizenHelp:
            " 'To be eligible for SNAP, a member must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
          citizenInhelp:
            "Even if you are not a US Citizen, you or other household members may still be eligible.",
          addingNewMemberFootNotes: () => {
            return (<div>
              <div>*This information is voluntary. List all races that apply only if the person is asking for benefits. Your benefits will not be affected if you donʼt answer the ethnicity or race items (the agency will choose for you if you do not answer). Giving us this information will help ensure program benefits are distributed without regard to race, color, or national origin. </div> 
              <div>**Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member.</div> 
              <div>***Providing citizenship /immigration information is voluntary. Failure to provide this information for each household member will result in disqualification of that member.</div>
            </div>)
          },
          citizenOptions: ["Yes", "No"],
          ssnQLabel: "What is this member's Social Security Number? **",
          ssnQLabelHelp: "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
          inlineHelpSSN:
            "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
          ssnDntLabel: "Doesn't have one",
          ssnErrLabel: "Please enter a valid Social Security Number.",
          hholdHeader: "Household details",
          disabilityQLabel:
            "Does anyone in the house have a physical or mental ",
          disabilityQlabel2: "",
          disabilityQTriggerLabel: "disability",
          disabilityHelp:
            "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
          disablilityCler: "None",
          buyFoodLabel: "Do you purchase and prepare food together?",
          buyFoodOptions: ["Yes", "No"],
          personIncomLabel: "Does this person have any income?",
          personIncomeOptions: ["Yes", "No"],
          headTitle: "Head of Household",
          removeButtonLabel: "Remove",
          undoButtonLabel: "Undo",
          changedLabel: "Changed",
          removedLabel: "Removed",
          newLabel: "New",
          membersTitle: "Members of household",
          addMemberButtonLabel: "Add a new household member",
          householdChangedQuestionLabel:
            "Has anyone joined or left your household?",
          labelEthnicity: "What is member's ethnicity? *",
          raceLabel: "What is member's race? *",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          declineToAnswerLabel: "Decline to answer",
          raceOptions: [
            "America Indian/ Alaskan Native",
            "Asian",
            "Black / African American",
            "Native Hawaiian/ Other Pacific Islander",
            "White",
            "Decline to answer",
            "Other",
          ],
          otherRaceErrmsg: "Please enter other race information",
          otherRaceErrMsgLength:
            "Please enter other race information greater than 3 characters or less than 250 characters",
          helpTextSSN1:
            "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
          helpTextSSN2:
            "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
          helpTextSSN3:
            "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
          helpTextSSN4:
            "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
          helpTextSSN5:
            "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
          helpTextSSN6:
            "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
          receivedLieapOptions: ["Yes", "No"],
          receivedLieapLabel:
            "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          enterDOB: "Please enter a valid date of birth.",
        };

      case ES: //spanish
        return {
          houseTitle: "Miembros del grupo familiar ",
          membersTitle: "Miembros del grupo familiar",
          houseHeader: "Acerca de mi grupo familiar",
          householdDetaillabel: "Detalles del hogar",
          howManyLabel:
            "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
          houseHelp:
            "Si compra y prepara más de 2/3 de sus comidas con otras personas, esas personas deben estar en su núcleo familiar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su núcleo familiar aunque usted no compre y prepare comidas con ellos.",
          houseErrMsg: "Por favor, elija no.of hogar",
          houseMemberTitle: "Agregar un nuevo miembro al grupo familiar",
          houseMemberHeader: "Su primer miembro del hogar es ",
          houseMemberSubHeader: "Para agregar nuevos miembros a su caso TANF se debe recibir una solicitud. Puede enviar una solicitud junto con este documento.",
          houseFirstName: "¿Cuál es el nombre de esta persona?",
          firstErrmsg: "Por favor, introduzca el primer nombre",
          buyFoodOptions: ["Sí", "No"],
          personIncomLabel: "¿Esta persona tiene ingresos?",
          personIncomeOptions: ["Sí", "No"],
          headTitle: "Jefe/a de familia",
          houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
          houseMiddleName: "Por favor ingrese un segundo nombre",
          houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
          enterLastname: "Por favor ingrese el apellido",
          enterDOB: "Ingrese una fecha de nacimiento válida.",
          suffixLabel: "Sufijo",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "¿Cuál es la relación entre usted y de esta persona?",
          spouseDisplay: "Cónyuge",
          motherDisplay: "Madre",
          faterDisplay: "Padre",
          daughterDisplay: "Hija",
          sonDisplay: "Hijo",
          brotherDisplay: "Hermano",
          sisterDisplay: "Hermana",
          grandMaDisplay: "Abuela",
          grandFaDisplay: "Abuelo",
          grandDaugther: "Nieta",
          grandSon: "Nieto",
          addMemberButtonLabel: "Agregar un nuevo miembro al grupo familiar",
          otherDisplay: "Otro",
          houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
          personGenLabel: "¿Cuál es el género de esta persona?",
          genderOptios: ["Masculino", "Femenino"],
          citiBeforeQLabel: "¿Es esta persona ",
          citiTriggerQLabel: "ciudadano/a estadounidense",
          citiAfterQLabel: "? ***",
          citizenHelp:
            " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
          citizenInhelp:
            "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
          addingNewMemberFootNotes: () => {
            return (<div>
              <div>*Esta información es voluntaria. Enumere todas las carreras que se aplican solo si la persona solicita beneficios. Sus beneficios no se verán afectados si no responde las preguntas sobre origen étnico o raza (la agencia elegirá por usted si no responde). Brindarnos esta información ayudará a garantizar que los beneficios del programa se distribuyan sin importar raza, color u origen nacional. </div> 
              <div>**Proporcionar un SSN para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro.</div> 
              <div>***Proporcionar información de ciudadanía/inmigración es voluntario. No proporcionar esta información para cada miembro del hogar resultará en la descalificación de ese miembro.</div>
            </div>)
          },
          citizenOptions: ["Sí", "No"],
          ssnQLabel: "¿Cuál es el número de seguro social de esta persona? **",
          ssnQLabelHelp: "Según las regulaciones federales, los no ciudadanos que no solicitan SNAP no necesitan proporcionar su SSN o su estado de ciudadanía.",
          ssnDntLabel: "No tiene",
          ssnErrLabel: "Please enter a valid Social Security Number.",
          hholdHeader: "Detalles de mi núcleo familiar",
          disabilityQLabel: "¿Alguna persona tiene una ",
          disabilityQlabel2: " mental o física ",
          disabilityQTriggerLabel: "discapacidad",
          disabilityHelp:
            "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
          disablilityCler: "Ninguno",
          inlineHelpSSN:
            "Según las regulaciones federales, los no ciudadanos que no están solicitando SNAP no necesitan dar su SSN o estado de ciudadanía.",
          removeButtonLabel: "Eliminar",
          undoButtonLabel: "Deshacer",
          changedLabel: "Modificadoe",
          removedLabel: "Eliminado",
          newLabel: "Nuevo",
          householdChangedQuestionLabel:
            "¿Alguien se ha unido o ha dejado su hogar?",
          buyFoodLabel: "¿Normalmente compran alimentos y cocinan juntos?",
          labelEthnicity: "Cuál es tu etnia? *",
          raceLabel: "¿Cuál es su raza? *",
          hisPanicOption: "Hispano/latino",
          nonhisPanicOption: "No hispano/latino",
          declineToAnswerLabel: "Negarse a contestar",
          raceOptions: [
            "Indio americano/nativo de Alaska",
            "Asiático",
            "Negro / afroamericano",
            "Nativa hawaiana / otra isleña del Pacífico",
            "Blanca",
            "Negarse a contestar",
            "Otro",
          ],
          otherRaceErrmsg: "Por favor ingresa otra información de la carrera",
          otherRaceErrMsgLength:
            "Ingrese otra información de carrera de más de 3 caracteres o menos de 250 caracteres",
          helpTextSSN1:
            "La recopilación de un Número de Seguro Social (SSN) para cada miembro del hogar está autorizada según la Ley de Alimentos y Nutrición de 2008, modificada por la P.L.96-58 (7 U.S. C.2025F). La información se utilizará para determinar si su hogar es elegible o continúa siendo elegible para participar en el Programa de Asistencia Alimentaria. ",
          helpTextSSN2:
            "Su SSN se utilizará para monitorear el cumplimiento de las regulaciones del programa y para la gestión del programa. Su SSN se utilizará en comparaciones por computadora y revisiones o auditorías de programas para garantizar que su hogar sea elegible para recibir asistencia alimentaria. ",
          helpTextSSN3:
            "Proporcionar un SSN para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro. Aún deberá proporcionar información como los ingresos de este miembro. ",
          helpTextSSN4:
            "Cualquier SSN proporcionado se utilizará y divulgará de la misma manera que los SSN de los miembros elegibles del hogar.",
          helpTextSSN5:
            "El uso o divulgación de información se realizará únicamente para ciertos fines limitados permitidos según las leyes y regulaciones estatales y federales. También se puede revelar información a funcionarios encargados de hacer cumplir la ley con el fin de detener a personas que huyen para eludir la ley.",
          helpTextSSN6:
            "Si surge un reclamo de asistencia alimentaria contra su hogar, la información contenida en este informe semestral, incluidos todos los números de seguro social, puede remitirse a agencias federales y estatales, así como a agencias privadas de cobro de reclamos, para que se tomen medidas de cobro de reclamos.",
          receivedLieapOptions: ["Sí", "No"],
          receivedLieapLabel:
            "¿Ha recibido un pago del Programa de asistencia energética para hogares de bajos ingresos o espera recibir LIHEAP?",
          enterDOB: "Por favor ingresa una fecha de nacimiento valida.",
        };

      case PT: //portugeesh
        return {
          houseTitle: "Membros do domicílio",
          houseHeader: "Sobre o meu domicílio",
          howManyLabel:
            "Quantas pessoas compõem a sua família, incluindo você?",
          houseHelp:
            "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
          houseErrMsg: "Por favor, escolha o número de casa",
          houseMemberTitle: "Adicionar um novo membro ao domicílio",
          houseMemberHeader: "Seu primeiro membro da família é ",
          houseFirstName: "Qual é o primeiro nome dessa pessoa?",
          firstErrmsg: "Digite o primeiro nome",
          houseMiddleLabel: "Qual é o nome do meio dessa pessoa?",
          houseMiddleName: "Digite o nome do meio",
          houseLastNameLabel: "Qual é o último nome dessa pessoa?",
          enterLastname: "Digite o sobrenome",
          enterDOB: "Insira uma data de nascimento válida",
          suffixLabel: "Sufixo",
          buyFoodOptions: ["Sim", "Não"],
          personIncomLabel: "Essa pessoa tem algum rendimento?",
          personIncomeOptions: ["Sim", "Não"],
          headTitle: "Chefe do domicílio",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "Qual é o relacionamento dessa pessoa com você?",
          spouseDisplay: "Cônjuge",
          motherDisplay: "Mãe",
          faterDisplay: "Pai",
          daughterDisplay: "Filha",
          sonDisplay: "Filho",
          brotherDisplay: "Irmão",
          sisterDisplay: "Irmã",
          grandMaDisplay: "Avó",
          addMemberButtonLabel: "Adicionar um novo membro ao domicílio",
          grandFaDisplay: "Avô",
          grandDaugther: "Neta",
          grandSon: "Neto",
          otherDisplay: "Outros",
          houseDobLabel: "Qual é a data de nascimento desta pessoa?",
          personGenLabel: "Qual é o sexo dessa pessoa?",
          genderOptios: ["Masculino", "Feminino"],
          citiBeforeQLabel: "Esta pessoa é um ",
          citiTriggerQLabel: "cidadão americano ",
          citiAfterQLabel: "?",
          citizenHelp:
            " 'Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
          citizenInhelp:
            "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
          citizenOptions: ["Sim", "Não"],
          ssnQLabel: "Qual é o número do seguro social dessa pessoa?",
          ssnDntLabel: "Não tem",
          ssnErrLabel: "Digite um Número de Segurança Social válido",
          hholdHeader: "Informações da minha família",
          disabilityQLabel: "Alguém sofre de algum tipo de deficiência ",
          disabilityQlabel2: " física ou mental ",
          disabilityQTriggerLabel: "deficiência",
          disabilityHelp:
            "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
          disablilityCler: "Nenhum",
          inlineHelpSSN:
            "De acordo com as regulamentações federais, os não-cidadãos que não solicitam o SNAP não precisam fornecer seu status de SSN ou de cidadania.",
          removeButtonLabel: "Excluir",
          membersTitle: "Membros da família",
          undoButtonLabel: "Desfazer",
          changedLabel: "Alterado",
          removedLabel: "excluído",
          newLabel: "Novo",
          householdChangedQuestionLabel:
            "Alguém se juntou ou saiu da sua casa?",
          buyFoodLabel: "Vocês compram e fazem comida juntos regularmente?",
        };

      case ZH: //China
        return {
          houseTitle: "家庭成员",
          houseHeader: "关于我的家庭",
          howManyLabel: "包括您自己在内，您家庭中有几人？",
          houseHelp:
            "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭",
          houseErrMsg: "請選擇家庭數量",
          houseMemberTitle: "新增家庭成员",
          houseMemberHeader: "你的第一個家庭成員是 ",
          houseFirstName: "此人的名字？",
          firstErrmsg: "請輸入名字",
          houseMiddleLabel: "此人的中间名?",
          membersTitle: "家庭成员",
          buyFoodOptions: ["是", "否"],
          personIncomLabel: "此人是否有收入?",
          personIncomeOptions: ["是", "否"],
          headTitle: "户主",
          houseMiddleName: "請輸入中間名",
          houseLastNameLabel: "此人的姓氏？",
          addMemberButtonLabel: "新增家庭成员",
          enterLastname: "請輸入姓氏",
          enterDOB: "请输入有效出生日期",
          suffixLabel: "后缀",
          SuffixOptions: ["一世", "II", "III", "IV", "V", "小", "高級"],
          relationQlabel: "此人与您的关系是？",
          spouseDisplay: "配偶",
          motherDisplay: "母亲",
          faterDisplay: "父亲",
          daughterDisplay: "女儿",
          sonDisplay: "儿子",
          brotherDisplay: "兄弟",
          sisterDisplay: "姐妹",
          grandMaDisplay: "祖母",
          grandFaDisplay: "祖父",
          grandDaugther: "（外）孙女",
          grandSon: "（外）孙子",
          otherDisplay: "其他",
          houseDobLabel: "此人的出生日期是？ ",
          personGenLabel: "此人的性别是？",
          genderOptios: ["男", "女"],
          citiBeforeQLabel: "此人是否是 ",
          citiTriggerQLabel: "美国公民",
          citiAfterQLabel: "?",
          citizenHelp:
            " '要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
          citizenInhelp: "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
          citizenOptions: ["是", "否"],
          ssnQLabel: "此人的社会安全号码是？",
          ssnDntLabel: "无社会安全号码",
          ssnErrLabel: "請輸入有效的社會安全號碼",
          hholdHeader: "家庭详细情况",
          disabilityQLabel: "是否有人患有身体或精神 ",
          disabilityQlabel2: "",
          disabilityQTriggerLabel: "残疾",
          disabilityHelp:
            "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾",
          disablilityCler: "无",
          inlineHelpSSN:
            "根据联邦法规，未申请SNAP的非公民不需要提供其SSN或公民身份",
          removeButtonLabel: "删除",
          undoButtonLabel: "撤销",
          changedLabel: "已变化",
          removedLabel: "已搬迁",
          newLabel: "新添内容",
          householdChangedQuestionLabel: "有没有人加入或离开你的家庭？ ",
          buyFoodLabel: "你们是否定期一起购买并制作食物？",
        };

      case VI: //Vietnam
        return {
          houseTitle: "Các thành viên trong hộ gia đình",
          houseHeader: "Về hộ gia đình của tôi",
          howManyLabel:
            "Có bao nhiêu người sống trong hộ gia đình của quý vị, kể cả quý vị?",
          houseHelp:
            "Nếu quý vị mua và nấu hơn 2/3 bữa ăn của quý vị với những người khác, họ phải ở trong hộ gia đình của quý vị. Nếu vợ / chồng hoặc con của quý vị dưới 22 tuổi sống chung với quý vị, họ phải ở trong hộ gia đình của quý vị ngay cả khi quý vị không mua và nấu bữa ăn cùng với họ",
          houseErrMsg: "Vui lòng chọn số hộ gia đình",
          houseMemberTitle: "Bổ sung một thành viên mới trong gia đình",
          houseMemberHeader: "Thành viên gia đình đầu tiên của bạn là ",
          houseFirstName: "Tên của người này là gì?",
          firstErrmsg: "Vui lòng nhập tên",
          houseMiddleLabel: "Tên lót của người này là gì?",
          houseMiddleName: "Vui lòng nhập tên đệm",
          houseLastNameLabel: "Họ của người này là gì?",
          enterLastname: "Vui lòng nhập họ",
          enterDOB: "Điền ngày sinh",
          suffixLabel: "Tước hiệu",
          addMemberButtonLabel: "Bổ sung một thành viên mới trong gia đình",
          membersTitle: "Thành viên hộ gia đình",
          buyFoodOptions: ["Có", "Không"],
          personIncomLabel: "Người này có thu nhập không",
          personIncomeOptions: ["Có", "Không"],
          headTitle: "Chủ hộ",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "Mối quan hệ của người này với quý vị là gì?",
          spouseDisplay: "Vợ / chồng",
          motherDisplay: "Mẹ",
          faterDisplay: "Cha",
          daughterDisplay: "Con gái",
          sonDisplay: "Con trai",
          brotherDisplay: "Em trai",
          sisterDisplay: "Em gái",
          grandMaDisplay: "Bà ",
          grandFaDisplay: "Ông ",
          grandDaugther: "Cháu gái",
          grandSon: "Cháu trai (nội, ngoại)",
          otherDisplay: "Khác",
          houseDobLabel: "Ngày tháng năm sinh của người này là gì?",
          personGenLabel: "Giới tính của người này là gì?",
          genderOptios: ["Nam", "Nữ"],
          citiBeforeQLabel: "Người này có phải là ",
          citiTriggerQLabel: "Công dân Hoa Kỳ không",
          citiAfterQLabel: "?",
          citizenHelp:
            " 'Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
          citizenInhelp:
            "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
          citizenOptions: ["Có", "Không"],
          ssnQLabel: "Số An sinh Xã hội của người này là gì?",
          ssnDntLabel: "Không có",
          ssnErrLabel: "Vui lòng nhập Số an sinh xã hội hợp lệ",
          hholdHeader: "Chi tiết về hộ gia đình",
          disabilityQLabel: "Có ai bị ",
          disabilityQlabel2: " về thể chất hoặc tinh thần không ",
          disabilityQTriggerLabel: "khuyết tật",
          disabilityHelp:
            "Một người khuyết tật phải được liên bang xác nhận để được xem xét hưởng trợ cấp SNAP. Ví dụ, người người nhận SSI là người khuyết tật được liên bang xác nhận",
          disablilityCler: "Không có",
          inlineHelpSSN:
            "Theo quy định của Liên bang, những người không phải là công dân không đăng ký SNAP không cần phải cung cấp SSN hoặc tư cách công dân của họ.",
          removeButtonLabel: "Xóa",
          undoButtonLabel: "Hủy bỏ",
          changedLabel: "thay đổi",
          removedLabel: "xóa bỏ",
          newLabel: "mới",
          buyFoodLabel:
            "Quý vị có thường xuyên mua thức ăn và nấu ăn cùng nhau không?",
          householdChangedQuestionLabel:
            "Có ai tham gia hoặc rời khỏi hộ gia đình của bạn?",
        };
      case HI:
        return {
          houseTitle: "Manm fwaye a",
          houseHeader: "Konsènan Kay mwen an",
          howManyLabel: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
          houseHelp:
            "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
          houseErrMsg: "Tanpri chwazi kantite moun nan fwaye",
          houseMemberTitle: "Ajoute yon nouvo manm fwaye",
          houseMemberHeader: "Premye manm nan fwaye w la se ",
          houseFirstName: "Ki prenon moun sa a?",
          firstErrmsg: "Tanpri antre prenon",
          houseMiddleLabel: "Ki dezyèm prenon moun sa a?",
          houseMiddleName: "Tanpri antre dezyèm prenon",
          houseLastNameLabel: "Ki siyati moun sa a?",
          enterLastname: "Tanpri antre siyati",
          enterDOB: "Tanpri antre yon dat nesans ki valid",
          suffixLabel: "Sifiks",
          SuffixOptions: ["I", "II", "III", "4", "5", "Fis", "Papa"],
          relationQlabel: "Ki relasyon moun sa genyen ak ou?",
          spouseDisplay: "Konjwen",
          motherDisplay: "Manman",
          faterDisplay: "Papa",
          daughterDisplay: "Pitit fi",
          sonDisplay: "Pitit gason",
          brotherDisplay: "Frè",
          sisterDisplay: "Sè",
          grandMaDisplay: "Grann",
          grandFaDisplay: "Granpè",
          grandDaugther: "Pitit pitit fi",
          grandSon: "Pitit pitit gason",
          otherDisplay: "Lòt",
          houseDobLabel: "Ki dat nesans moun sa a?",
          personGenLabel: "Ki sèks moun sa a?",
          genderOptios: ["Gason", "Fi"],
          citiBeforeQLabel: "Èske Moun sa a se yon ",
          citiTriggerQLabel: "Sitwayen Ameriken",
          citiAfterQLabel: "?",
          citizenHelp:
            "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
          citizenInhelp:
            "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
          citizenOptions: ["Wi", "Non"],
          ssnQLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
          inlineHelpSSN:
            "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
          ssnDntLabel: "Li pa gen youn",
          ssnErrLabel: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
          hholdHeader: "Detay Fwaye",
          disabilityQLabel: "Èske nenpòt moun nan fwaye a gen yon ",
          disabilityQlabel2: " fizik oswa mantal",
          disabilityQTriggerLabel: "andikap",
          disabilityHelp:
            "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
          disablilityCler: "Okenn",
          buyFoodLabel: "Èske ou regilyèman achte epi prepare repa ansanm?",
          buyFoodOptions: ["Wi", "Non"],
          personIncomLabel: "Èske moun sa a gen revni?",
          personIncomeOptions: ["Wi", "Non"],
          headTitle: "Chèf Fwaye a",
          removeButtonLabel: "Retire",
          undoButtonLabel: "Defèt",
          changedLabel: "Chanje",
          removedLabel: "Retire",
          newLabel: "Nouvo",
          membersTitle: "Manm fwaye a",
          addMemberButtonLabel: "Ajoute yon nouvo manm fwaye",
          householdChangedQuestionLabel:
            "Èske nenpòt moun vin jwenn oswa kite fwaye w la?",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.householdDynamicRecert) {
    switch (language) {
      case EN:
        return {
          title: "Member " + name,
          header: "About Member " + name,
          conditionalLabel: "Who does " + name + " pay dependent care for?",
        };
      case ES:
        return {
          title: "miembro " + name,
          header: "Acerca de los miembros " + name,
          conditionalLabel:
            "Por quién paga " + name + " el cuidado de dependiente ?",
        };
      case PT:
        return {
          title: "miembro " + name,
          header: "Sobre o Membro " + name,
          conditionalLabel:
            "Para quem " + name + " paga pelos cuidados de dependentes ?",
        };
      case ZH:
        return {
          title: "會員 " + name,
          header: "關於會員 " + name,
          conditionalLabel: name + " 为谁支付亲属护理费用？",
        };
      case VI:
        return {
          title: "thành viên " + name,
          header: "Giới thiệu Thành viên " + name,
          conditionalLabel:
            name + " trả tiền chi phí chăm sóc người phụ thuộc cho ai?",
        };
      case HI:
        return {
          title: "Manm " + name,
          header: "Konsènan Manm " + name,
          conditionalLabel:
            "Pou ki moun Mary Smith " + name + " peye swen depandan?",
        };

      default:
        return;
    }
  } else if (pageType === languageConstants.nonCitizen) {
    //4th section page
    switch (language) {
      case EN:
        return {
          title: "Noncitizen Status",
          header: "Noncitizen status",
        };

      case ES:
        return {
          title: "Estado de no ciudadano",
          header: "Estado de no ciudadano",
        };

      case PT: //portugeesh
        return {
          title: "Status de não cidadão",
          header: "Status de não cidadão",
        };

      case ZH: //China
        return {
          title: "非公民状态",
          header: "非公民状态",
        };

      case VI: //Vietnam
        return {
          title: "Tình trạng không phải là công dân",
          header: "Tình trạng không phải là công dân",
        };
      case HI:
        return {
          title: "Estati Non-sitwayen Ameriken",
          header: "Estati Non-sitwayen Ameriken",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.studentStatic) {
    //5th section page
    switch (language) {
      case EN:
        return {
          hschool: "High School",
          college: "College",
          studentsTitle: "Students",
          studentsHeader: "Students",
          studentsDesc:
            "If anyone in the household is attending high school or college, please select their name(s) below:",
          noneLabel: "None",
          schoolTypeQ: "Which type of school type " + name + " is attending?",
          schoolNameQ: "What is name of " + name + "'s school?",
          collegeNameQ: "What is name of " + name + "'s college?",
          sasidQ: "What is " + name + "'s SASID?",
        };
      case ES:
        return {
          hschool: "Escuela secundaria",
          college: "Escuela universitaria",
          studentsTitle: "Estudiantes",
          studentsHeader: "Estudiantes",
          studentsDesc:
            "Si algún miembro del grupo familiar asiste a la escuela o la universidad, seleccione su(s) nombre(s) a continuación:",
          noneLabel: "Ninguno",
          schoolTypeQ: "¿A qué tipo de escuela asiste " + name + "?",
          schoolNameQ: "¿Cuál es el nombre de la escuela de " + name + "?",
          collegeNameQ:
            "¿Cuál es el nombre de la escuela universitaria de " + name + " ?",
          sasidQ: "¿Cuál es la SASID de " + name + "?",
        };
      case PT:
        return {
          hschool: "Escola",
          college: "Faculdade",
          studentsTitle: "Estudantes",
          studentsHeader: "Estudantes",
          studentsDesc:
            "Se alguém do domicílio está frequentando o ensino médio ou faculdade, selecione seu(s) nome(s) abaixo:",
          noneLabel: "Nenhum",
          schoolTypeQ: "Que tipo de escola " + name + " está frequentando?",
          schoolNameQ: "Qual é o nome da escola de " + name + "?",
          collegeNameQ: "Qual é o nome da faculdade de " + name + "?",
          sasidQ: "Qual é o SASID de " + name + "?",
        };
      case ZH:
        return {
          hschool: "高中",
          college: "大学",
          studentsTitle: "学生",
          studentsHeader: "学生",
          studentsDesc:
            "如果家庭中有任何人在高中或大学就读，请在下方选择他们的姓名：",
          noneLabel: "无",
          schoolTypeQ: name + "在哪个类型的学校就读?",
          schoolNameQ: name + "的学校名称？",
          collegeNameQ: "的大学名称是" + name + "?",
          sasidQ: name + "的 SASID？",
        };
      case VI:
        return {
          hschool: "Trung học",
          college: "Trường đại học",
          studentsTitle: "Sinh viên",
          studentsHeader: "Sinh viên",
          studentsDesc:
            "Nếu bất cứ thành viên nào trong gia đình đang học trung học hoặc đại học, xin vui lòng chọn (các) tên của họ",
          noneLabel: "Không có",
          schoolTypeQ: "Loại trường nào mà " + name + " đang theo học?",
          schoolNameQ: "Tên của trường mà " + name + " đang học là gì?",
          collegeNameQ: "Tên của trường đại học của " + name + "là gì?",
          sasidQ:
            "Số Nhận Diện Học Sinh Được Tiểu Bang Chỉ Định (SASID) của " +
            name +
            " là gì?",
        };
      case HI:
        return {
          hschool: "Lekòl Segondè",
          college: "Kolèj",
          studentsTitle: "Etidyan",
          studentsHeader: "Etidyan",
          studentsDesc:
            "Si yon moun nan fwaye nan lekòl segondè oswa kolèj, tanpri chwazi non(yo) anba a:",
          noneLabel: "Okenn",
          schoolTypeQ: "Ki tip lekòl " + name + " frekante?",
          schoolNameQ: "Ki non lekòl " + name + " la?",
          collegeNameQ: "Ki non kolèj " + name + " la?",
          sasidQ: "Ki SASID " + name + "?",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.studentDynamic) {
    switch (language) {
      case EN:
        return {
          heading: name + "'s school details",
          questions: [
            {
              [Props.LABEL]: "Which type of school " + name + " is attending?",
            },
            {
              [Props.LABEL]: "What is the name of " + name + "'s school?",
            },
          ],
        };
      case ES:
        return {
          heading: name + "Información académica de",
          questions: [
            {
              [Props.LABEL]: "¿A qué tipo de escuela asiste " + name,
            },
            {
              [Props.LABEL]: "¿Cuál es el nombre de la escuela de " + name,
            },
          ],
        };
      case PT:
        return {
          heading: name + "Dados da escola",
          questions: [
            {
              [Props.LABEL]:
                "Que tipo de escola " + name + " está frequentando?",
            },
            {
              [Props.LABEL]: "Qual é o nome da escola de " + name,
            },
          ],
        };
      case ZH:
        return {
          heading: name + "的学校详情",
          questions: [
            {
              [Props.LABEL]: name + " 在哪个类型的学校就读",
            },
            {
              [Props.LABEL]: name + "的学校名称？",
            },
          ],
        };
      case VI:
        return {
          heading: name + "Thông tin về trường học của",
          questions: [
            {
              [Props.LABEL]: "Loại trường nào mà " + name + " đang theo học",
            },
            {
              [Props.LABEL]: "Tên của trường mà " + name + "đang học là gì?",
            },
          ],
        };
      case HI:
        return {
          heading: "Detay lekòl pou" + name,
          questions: [
            {
              [Props.LABEL]: "Ki tip lekòl " + name + " frekante?",
            },
            {
              [Props.LABEL]: "Ki non lekòl " + name + " la?",
            },
          ],
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.dependentCareCostStatic) {
    //6th section page
    switch (language) {
      case EN:
        return {
          dependentCareCostTitle: "Child or Adult Dependent Care Expenses",
          dependentCareCostHeader: "Tell Us About Your Dependent Care Expenses",
          dependentCareCostSubHeader:
            "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
        };
      case ES:
        return {
          dependentCareCostTitle: "Gastos de cuidado de niños o adultos dependientes",
          dependentCareCostHeader:
            "Infórmenos sobre los costos del cuidado de dependientes",
          dependentCareCostSubHeader:
            "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
        };
      case PT:
        return {
          dependentCareCostTitle: "Custos de cuidados dependentes",
          dependentCareCostHeader:
            "Conte-nos sobre seus gastos com cuidados de dependentes",
          dependentCareCostSubHeader:
            "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
        };
      case ZH:
        return {
          dependentCareCostTitle: "亲属护理费用",
          dependentCareCostHeader: "告诉我们您的亲属护理费用",
          dependentCareCostSubHeader:
            "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
        };
      case VI:
        return {
          dependentCareCostTitle: "Chi phí chăm sóc người phụ thuộc",
          dependentCareCostHeader:
            "Hãy cho chúng tôi biết về chi phí chăm sóc người phụ thuộc của bạn",
          dependentCareCostSubHeader:
            "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
        };
      case HI:
        return {
          dependentCareCostTitle: "Depans pou swen depandan",
          dependentCareCostHeader:
            "Pale nou konsènan depans pou swen depandan w yo",
          dependentCareCostSubHeader:
            "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
        };
      default:
    }
  } else if (pageType === languageConstants.childSupportExpense) {
    //1st section page
    switch (language) {
      case EN:
        return {
          childSupportSideBar: "Child Support Expenses",
          childSupportSummaryHeader: "Child Support Expenses Summary",
          tellusAbout: "Tell us about your Child support costs",
          perLabel: "Per",
          freqTripoptions: ["Week", "Month", "Year"],
          earnedIncomeMenuTitle: "Earned income",
          mainQuestion:
            "What type(s) of income and benefits does " + name + " have?",
          heading: "Child Support Expenses Summary",
          mainQuestion: "How much is the child support expense for " + name + "?",
          week: "Weekly",
          month: "Monthly",
          year: "Yearly",
          BIMON:"Bimonthly (every two months)",
          BIWEEK:"Biweekly (every two weeks)",
          SEMANL:"Semiannual (twice a year)",
          SEMMON:"Semimonthly (twice a month)",
          ANNUAL:"Annual",
          QRTRLY:"Quarterly",
          sIncomebefore: "'s ",
          amountLabel: "amount",
          childSupport_AddHouseHold:
            "Add another household member's child support",
          childSupportExpenses_Question:
          "Has any household member had a change in his/her legal obligation to pay child support? (Required)",
          courtOrderedChildSupport:
          "Is any other household member now paying court-ordered child support?",
        }; //return End

      case ES:
        return {
          childSupportSideBar: "Gastos de manutención infantil",
          childSupportSummaryHeader:
            "Resumen de gastos de manutención infantil",
          tellusAbout: "Díganos sobre sus costos de manutención",
          perLabel: "Por",
          freqTripoptions: ["Semana", "Mes", "Año"],
          earnedIncomeMenuTitle: "Ingresos del trabajo",
          childSupportSummaryHeader:
            "Resumen de gastos de manutención infantil",
          mainQuestion:
            "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
          heading: "Resumen de gastos de manutención infantil",
          mainQuestion: "¿A cuánto asciende el gasto de manutención infantil? " + name + "?",
          week: "Semanalmente",
          month: "Mensualmente",
          year: "Anualmente",
          BIMON:"Bimestralmente (cada dos meses)",
          BIWEEK:"Quincenalmente (cada dos semanas)",
          SEMANL:"Bianual (dos veces al año)",
          SEMMON:"semi-mensal (dos veces al mes)",
          ANNUAL:"Anualmente",
          QRTRLY:"Trimestralmente",
          sIncomebefore: " ",
          amountLabel: "monto",
          childSupport_AddHouseHold:
            "Agregar manutención infantil de otros miembros del grupo familiar",
          childSupportExpenses_Question:
          "¿Ocurrió algún cambio en su información sobre gastos de manutención infantil? (Obligatorio)",
          courtOrderedChildSupport:
          "¿Algún otro miembro del hogar está pagando manutención infantil ordenada por el tribunal?",
        }; //return End

        return {
          heading: "Resètifikasyon",
          howApplyLabel: "Resètifikasyon",
          fieldLabel1: "Ou ku Rapò Pwovizwa w lan nan itilize metòd sa yo:",
          recertMethods: [
            "An liy nan klike sou <b>Kòmanse Rapò Pwovizwa.</b> Se fason ki pi rapid la!",
            {
              before:
                "Nou pral aksepte yon fòm papye si w mete non, adrès ak siyati w. Opsyon sa a pa disponib pou soumèt an liy. Telechaje epi enprime fòm ",
              hereLinkText: "papye w la la a.",
              after: " Ou ka soumèt fòm ou an nan:",
            },
            "Pran yon foto chak paj epi telechaje atravè ACES DHR Self-Service Portal",
            "Fakse nan (617) 887-8765",
            "Poste nan P.O. Box 4406, Taunton, MA 02780.",
          ],
          boBackButtonLabel: "Tounen",
          getStartButtonLabel: "Kòmanse Resètifikasyon",
          sIncome: " Revni",
          editButtonLabel: "Edite",
          perLabel: "Pa",
          ofLabel: "of",

          renderAppNoLabel: "Nimewo aplikasyon w lan:",
          youMayLabelOne: "Ou ka vle ",
          youMayLabeltwo: "telechaje aplikasyon w lan",
          youMayLabelThree: "pou plis referans.",
          whatisNextLabel: "Kisa k ap vini apre?",
          dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
          sendDocLabel: "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
          howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
          donotNeedLabel:
            "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
          sendLabelOne: "Ou ka ",
          sendLabelTwo: "voye dokiman w yo ban nou ",
          sendLabelThree: "nan plizyè fason",
          toLearnOneLabel:
            "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
          toLearnOneLabelTwo: "klike la a",
          manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman yo ban nou.",
          manyWayLabelTwo: "Jwenn plis enfòmasyon",
          howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
          weSuggestLabel: "Nou sijere",
          weSuggestLabelOne: "kreye yon kont an liy",
          weSuggestLabelTwo:
            "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
          findOutMoreLabel: "Jwenn plis enfòmasyon",
          createOnlineLabel: "Kreye yon kont an Liy",
          orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
          renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
          laterOnLabel:
            "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
          orExitLabel: "Oswa sòti san ou pa konsève travay ou",
          changingLabel:
            "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
          greatHereLabel: "Ekselan! Men sa nou te jwenn na men w.",
          lookIncorrectLabel:
            "Si nenpòt nan yo sanble pa kòrèk, pa ezite modifye.",
          anotherHouseholdIncomeLabel: "Ajoute revni yon lòt manm fwaye a",
          applicationSummaryTitle: "Rezime aplikasyon",
          applicationSummaryHeader: "Rezime aplikasyon",
          viewApplicationLinkLabel: "gade sou fòma pdf",
          iagreeTermsLabel: "Mwen dakò",
          signYourAppllication:
            "Siyen Re-Sètifikasyon w lan nan tape non konplè w anba a",
          continueLabel: "Kontinye",
          getStartedLabel: "Kòmanse",
          submitButtonLabel: "Soumèt Resètifikasyon",
          expenseFieldLabel: "Dènye seksyon sa a se konsènan",
          expenseHouseutility: "Depans pou lojman ak sèvis piblik",
          expenseCaring: "Pran swen timoun ak adilt",
          expenseChsupport: "Sipò Timoun",
          expenseMed: "Depans medikal",
          expenseAnyinfo:
            "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
          removeHoHTitle: "Avètisman",
          removeHoHLabel:
            "W ap eseye efase chèf fwaye a. Si sa kòrèk, ou ka klike sou Kontinye kounye a epi DHR pral kontakte w pou plis enfòmasyon. Si se te yon erè, tanpri klike sou  “Tounen”",
          agreeContent: [
            "Nan siyen, mwen dakò ke:",
            "Mwen te li tout fòm sa a (oswa mwen te fè li l pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite, epi m konprann ke mwen dwe respekte règleman sa yo;",
            "Enfòmasyon m ap bay yo vre epi konplè dapre sa m byen konnen.",
            "M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) nenpòt enfòmasyon mwen bay yo",
          ],
          didReportChanges: "Mwen te rapòte chanjman",
          didNotReportChanges: "Mwen pa t rapòte chanjman ",
          agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
          signatureErrorMsg: "Tanpri siyen Resètifikasyon w lan",
        }; //return End
      default:
        return;
    }
  } else if (pageType === languageConstants.medicalExpenses) {
    //1st section page
    switch (language) {
      case EN:
        return {
          childSupportSideBar: "Child Support Expenses",
          childSupportSummaryHeader: "Child support expenses summary",
          tellusAbout: "Tell us about your Child support costs",
          perLabel: "Per",
          freqTripoptions: ["Week", "Month", "Year"],
          earnedIncomeMenuTitle: "Earned income",
          childSupportSummaryHeader: "Child support expenses summary",
          mainQuestion:
          "What type of medical expenses do you have? (Select all that apply)",
          heading: "Medical Expenses for " + name,
          week: 'Weekly',
          month: 'Monthly',
          year: 'Yearly', 
          sIncomebefore: "'s ",
          sIncomeAfter: " ",
          amountLabel: "amount",
          childSupport_AddHouseHold:
          "Add another household member's child support",
          childSupportExpenses_Question:
          "Has any household member had a change in his/her legal obligation to pay child support? (Required)",
          courtOrderedChildSupport: "Is any other household member now paying court-ordered child support?",
          medicalExpenses_Question: "Has your Medical Expenses changed by more than or less than $100/month?",
          medicalExpenses: "medical expenses",
          medicalExpenses_AddHouseHold: "Add another household member’s medical expenses",
        }; //return End

      case ES:
        return {
          childSupportSideBar: "Gastos de manutención infantil",
          childSupportSummaryHeader:
          "Resumen de gastos de manutención infantil",
          tellusAbout: "Díganos sobre sus costos de manutención",
          perLabel: "Por",
          freqTripoptions: ["Semana", "Mes", "Año"],
          earnedIncomeMenuTitle: "Ingresos del trabajo",
          childSupportSummaryHeader:
          "Resumen de gastos de manutención infantil",
          mainQuestion:
          "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
          heading: "Gastos médicos por " + name,
          mainQuestion:
            "¿Para qué es el gasto de manutención infantil? " + name + "?",
          week: 'Weekly',
          month: 'Monthly',
          year: 'Yearly', 
          sIncomebefore: " ",
          sIncomeAfter: " ",
          amountLabel: "monto",
          childSupport_AddHouseHold:
          "Agregar manutención infantil de otros miembros del grupo familiar",
          childSupportExpenses_Question:
          "¿Ocurrió algún cambio en su información sobre gastos de manutención infantil? (Obligatorio)",
          courtOrderedChildSupport: "Is any other household member now paying court-ordered child support?",
          medicalExpenses_Question: "¿Han cambiado sus gastos médicos en más o menos de $100/mes?",
          medicalExpenses: "Gastos médicos",
          medicalExpenses_AddHouseHold: "Agregar los gastos médicos de otro miembro del hogar",
        }; //return End
      default:
        return;
    }
  }else if (pageType === languageConstants.householdStatic){
    switch (language) {
      case EN:
        return {
          houseTitle: "Household members",
          houseHeader: "About My Household",
          howManyLabel:
            "How many people live in your household, including you?",
          houseHelp:
            "If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.",
          houseErrMsg: "Please choose no.of household",
          houseMemberTitle: "Add a new household member",
          houseMemberHeader: "Your first household member is ",
          houseMemberSubHeader1:
            "Please fill in the information for anyone that has moved into your household. If a new member has income, please attach proof of the income such as check stubs or statement from employer, award letter, and written statements to verify the income.",
          houseMemberSubHeader2:
            "We can give Food Assistance benefits only to people who give us their social security number or proof that they have applied for a social security number. You don't have to give us the social security number or citizenship status for the people you do not want Food Assistance benefits for. (We have to ask for ethnicity and race, but you don't have to answer. Your answer won't affect how many benefits you get or how soon you get them).",
          houseFirstName: "What is this member's first name?",
          firstErrmsg: "Please enter first name.",
          houseMiddleLabel: "What is this member's middle name?",
          houseMiddleName: "Please enter middle name",
          houseLastNameLabel: "What is this member's last name?",
          enterLastname: "Please enter last name",
          enterDOB: "Please enter a valid date of birth.",
          suffixLabel: "Suffix",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "What is this member's relationship to you?",
          spouseDisplay: "Spouse",
          motherDisplay: "Mother",
          faterDisplay: "Father",
          daughterDisplay: "Daughter",
          sonDisplay: "Son",
          brotherDisplay: "Brother",
          sisterDisplay: "Sister",
          grandMaDisplay: "Grandmother",
          grandFaDisplay: "Grandfather",
          grandDaugther: "Granddaughter",
          grandSon: "Grandson",
          otherDisplay: "Other",
          houseDobLabel: "What is this member's date of birth?",
          personGenLabel: "What is this member's gender?",
          genderOptios: ["Male", "Female"],
          citiBeforeQLabel: "Is this member a ",
          citiTriggerQLabel: "US Citizen",
          citiAfterQLabel: "?",
          citizenHelp:
            " 'To be eligible for SNAP, a member must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
          citizenInhelp:
            "Even if you are not a US Citizen, you or other household members may still be eligible.",
          citizenOptions: ["Yes", "No"],
          ssnQLabel: "What is this member's Social Security Number?",
          ssnDntLabel: "Doesn't have one",
          ssnErrLabel: "Please enter a valid Social Security Number.",
          hholdHeader: "Household details",
          disabilityQLabel:
            "Does anyone in the house have a physical or mental ",
          disabilityQlabel2: "",
          disabilityQTriggerLabel: "disability",
          disabilityHelp:
            "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
          disablilityCler: "No one",
          buyFoodLabel: "Do you regularly buy and make food together?",
          buyFoodOptions: ["Yes", "No"],
          personIncomLabel: "Does this member have any income?",
          personIncomeOptions: ["Yes", "No"],
          headTitle: "Head of Household",
          removeButtonLabel: "Remove",
          undoButtonLabel: "Undo",
          changedLabel: "Changed",
          removedLabel: "Removed",
          newLabel: "New",
          membersTitle: "Members of Household",
          additionalMembersTitle: "Additional Household Members ",
          addMemberButtonLabel: "Add a new household member",
          householdChangedQuestionLabel:
            "Has anyone joined or left your household?",
          inlineHelpSSN:
            "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
          labelEthnicity: "What is your ethnicity?",
          raceLabel: "What is your race?",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          declineToAnswerLabel: "Decline to answer",
          raceOptions: [
            "America Indian/ Alaskan Native",
            "Asian",
            "Black / African American",
            "Native Hawaiian/ Other Pacific Islander",
            "White",
            "Decline to answer",
            "Other",
          ],
          otherRaceErrmsg: "Please enter other race information",
          otherRaceErrMsgLength:
            "Please enter other race information greater than 3 characters or less than 250 characters",
          helpTextSSN1:
            "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
          helpTextSSN2:
            "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
          helpTextSSN3:
            "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
          helpTextSSN4:
            "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
          helpTextSSN5:
            "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
          helpTextSSN6:
            "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
          receivedLieapOptions: ["Yes", "No"],
          receivedLieapLabel:
            "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          lifelineProvidesOptions: ["Yes", "No"],
          lifelineProvides:
            "Lifeline provides a discount on the cost of monthly telephone service if you qualify for the service. If you need help paying for phone service and are approved for food assistance, we can share your name, address, telephone number, and the last four digits of your social security number with the Alabama Public Service Commission (PSC) and telecommunication companies for the purpose of potential LIFELINE enrollment. Do you agree for Food Assistance to furnish your information to PSC?",
          personIncomLabel: "Does this person have any income?",
          personIncomeOptions: ["Yes", "No"],
          declineToAnswer: 'Decline to answer'
        };

      case ES: //spanish
        return {
          houseTitle: "Miembros del grupo familiar",
          houseHeader: "Acerca de mi grupo familiar",
          howManyLabel:
            "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
          houseHelp:
            "Si compra y prepara más de 2/3 de sus comidas con otras personas, esas personas deben estar en su núcleo familiar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su núcleo familiar aunque usted no compre y prepare comidas con ellos.",
          houseErrMsg: "Por favor, elija no.of hogar",
          headTitle: "Jefe/a de familia",
          houseMemberTitle: "Agregar un nuevo miembro al grupo familiar",
          houseMemberHeader: "Su primer miembro del hogar es ",
          houseFirstName: "¿Cuál es el nombre de esta persona?",
          firstErrmsg: "Por favor, introduzca el primer nombre",
          houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
          houseMiddleName: "Por favor ingrese un segundo nombre",
          houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
          enterLastname: "Por favor ingrese el apellido",
          enterDOB: "Ingrese una fecha de nacimiento válida.",
          suffixLabel: "Sufijo",
          SuffixOptions: [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Jr.",
            "Sr.",
          ],
          relationQlabel: "¿Cuál es la relación entre usted y de esta persona?",
          spouseDisplay: "Cónyuge",
          motherDisplay: "Madre",
          faterDisplay: "Padre",
          daughterDisplay: "Hija",
          sonDisplay: "Hijo",
          brotherDisplay: "Hermano",
          sisterDisplay: "Hermana",
          grandMaDisplay: "Abuela",
          grandFaDisplay: "Abuelo",
          grandDaugther: "Nieta",
          grandSon: "Nieto",
          otherDisplay: "Otro",
          houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
          personGenLabel: "¿Cuál es el género de esta persona?",
          genderOptios: ["Masculino", "Femenino"],
          citiBeforeQLabel: "¿Es esta persona ",
          citiTriggerQLabel: "ciudadano/a estadounidense",
          citiAfterQLabel: "?",
          addMemberButtonLabel: "Agregar un nuevo miembro al grupo familiar",
          citizenHelp:
            " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
          citizenInhelp:
            "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
          citizenOptions: ["Sí", "No"],
          ssnQLabel: "¿Cuál es el Número de Seguro Social de esta persona?",
          ssnDntLabel: "No tiene",
          ssnErrLabel: "Ingrese un Número de Seguro Social válido.",
          hholdHeader: "Detalles de mi núcleo familiar",
          disabilityQLabel: "¿Alguna persona tiene una ",
          disabilityQlabel2: " mental o física ",
          disabilityQTriggerLabel: "discapacidad",
          disabilityHelp:
            "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
          disablilityCler: "Ninguno",
          inlineHelpSSN:
            "Según las regulaciones federales, los no ciudadanos que no están solicitando SNAP no necesitan dar su SSN o estado de ciudadanía",
          removeButtonLabel: "Eliminar",
          undoButtonLabel: "Deshacer",
          changedLabel: "Modificado",
          removedLabel: "Eliminado",
          newLabel: "Nuevo",
          membersTitle: "Miembros del grupo familiar",
          additionalMembersTitle: "Miembros adicionales del hogar ",
          householdChangedQuestionLabel:
            "¿Alguien se ha unido o ha dejado su hogar?",
          buyFoodLabel: "¿Normalmente compran alimentos y cocinan juntos?",
          buyFoodOptions: ["Sí", "No"],
          personIncomeOptions: ["Sí", "No"],
          personIncomLabel: "¿Esta persona tiene ingresos?",
          labelEthnicity: "What is your ethnicity?",
          raceLabel: "What is your race?",
          hisPanicOption: "Hispanic/Latino",
          nonhisPanicOption: "Non-Hispanic/Latino",
          declineToAnswerLabel: "Decline to answer",
          raceOptions: [
            "America Indian/ Alaskan Native",
            "Asian",
            "Black / African American",
            "Native Hawaiian/ Other Pacific Islander",
            "White",
            "Decline to answer",
            "Other",
          ],
          otherRaceErrmsg: "Please enter other race information",
          otherRaceErrMsgLength:
            "Please enter other race information greater than 3 characters or less than 250 characters",
          helpTextSSN1:
            "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
          helpTextSSN2:
            "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
          helpTextSSN3:
            "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
          helpTextSSN4:
            "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
          helpTextSSN5:
            "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
          helpTextSSN6:
            "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
          receivedLieapOptions: ["Sí", "No"],
          receivedLieapLabel:
            "¿Su hogar ha recibido o espera recibir un pago del programa de Asistencia de Energía para Hogares de Bajos Ingresos (LIHEAP)?",
          lifelineProvidesOptions: ["Sí", "No"],
          lifelineProvides:
            "Lifeline provides a discount on the cost of monthly telephone service if you qualify for the service. If you need help paying for phone service and are approved for food assistance, we can share your name, address, telephone number, and the last four digits of your social security number with the Alabama Public Service Commission (PSC) and telecommunication companies for the purpose of potential LIFELINE enrollment. Do you agree for Food Assistance to furnish your information to PSC?",
          personIncomLabel: "Does this person have any income?",
          personIncomeOptions: ["Yes", "No"],
          declineToAnswer: 'Negarse a contestar'
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.medicalCostDynamic) {
    //7th section page
    switch (language) {
      case EN:
        return {
          heading: "Medical costs for " + name,
          question:
            "Does " + name + " drive to medical appointments or the pharmacy?",
          typeQuestion:
            "What type of medical expenses are there for " + name + "?",
          totalCostQuestion:
            "What is total cost of " + name + "'s medical expenses?",
          typeOptions: [
            "Medical/Dental Care",
            "Medications",
            "Health Insurance",
            "Other (such as OTCs, medical supplies, etc.)",
          ],
          conditional: {
            label1:
              "How often does " +
              name +
              " drive to this address? To and from a provider should be counted as two trips.",
            label2:
              "How much does " +
              name +
              " pay for transportation, parking to get to medical appointments or the pharmacy?",
          },
          drivetoLabel:
            "How many medical appointments or pharmacies does " +
            name +
            " drive to?",
          kitHeaderLabel: "Caring for " + name,
          medConTitle: "'s medical appointment/pharmacy Address  " + name,
          medConHeader: "'s medical appointment/pharmacy Address  " + name,
          medQoneLabel:
            "How often does " +
            name +
            " drive to this address? To and from a provider should be counted as two trips.",
          medQtwoLabel:
            "How much does " +
            name +
            " pay for transportation, parking to get to medical appointments or the pharmacy ?",
        };
      case ES:
        return {
          heading: "Costos médicos para " + name,
          question: "¿Conduce" + name + " a las citas médicas o a la farmacia?",
          typeQuestion:
            "¿Qué tipo de gastos médicos existen para " + name + "?",
          totalCostQuestion:
            "¿Cuál es el costo total de los gastos médicos de " + name + "?",
          typeOptions: [
            "Atención médica/dental",
            "Medicamentos",
            "Seguro de salud",
            "Otros (como OTC, insumos médicos, etc.)",
          ],
          conditional: {
            label1:
              "¿Con qué frecuencia " +
              name +
              " conduce a citas médicas o a la farmacia? Hacia y desde un proveedor debe contarse como dos viajes ",
            label2:
              "¿Cuánto paga " +
              name +
              "  por transporte y estacionamiento para ir a citas médicas o la farmacia?",
          },
          drivetoLabel:
            "¿A cuántas citas médicas o farmacias conduce " +
            name +
            " conduce?",
          kitHeaderLabel: "Caring for " + name,
          medConTitle: "Dirección de la cita médica o farmacia de ",
          medConHeader: "Dirección de la cita médica o farmacia de ",
          medQoneLabel:
            "¿Con qué frecuencia " +
            name +
            " conduce a citas médicas o a la farmacia? Hacia y desde un proveedor debe contarse como dos viajes ",
          medQtwoLabel:
            "¿Cuánto paga " +
            name +
            " por transporte y estacionamiento para ir a citas médicas o la farmacia?",
        };
      case PT:
        return {
          heading: "Custos médicos de " + name,
          question: name + " dirige para as consultas médicas ou à farmácia?",
          typeQuestion: "Que tipo de despesas médicas há para " + name + "?",
          totalCostQuestion:
            "Qual é o custo total das despesas médicas de " + name + "?",
          typeOptions: [
            "Custos médicos/odontológicos",
            "Medicamentos",
            "Plano de saúde",
            "Outros (compra de balcão, suprimentos médicos, etc)",
          ],
          conditional: {
            label1:
              "Com que frequência " +
              name +
              "  vai até a consulta médica/farmácia? Ir e voltar de um prestador de serviço deve contar como duas viagens",
            label2:
              "Quanto " +
              name +
              "  paga pelo transporte e estacionamento quando vai à consulta médica ou à farmácia?",
          },
          drivetoLabel:
            "A quantas farmácias ou consultas médicas " + name + " vai?",
          kitHeaderLabel: "Caring for " + name,
          medConTitle: "Endereço da farmácia ou consulta médica de ",
          medConHeader: "Endereço da farmácia ou consulta médica de ",
          medQoneLabel:
            "Com que frequência " +
            name +
            "  vai até a consulta médica/farmácia? Ir e voltar de um prestador de serviço deve contar como duas viagens",
          medQtwoLabel:
            "Quanto " +
            name +
            " paga pelo transporte e estacionamento quando vai à consulta médica ou à farmácia?",
        };
      case ZH:
        return {
          heading: name + " 的医疗费 ",
          question: name + " 是否开车去看医生或去药店？",
          typeQuestion: name + " 有哪些类型的医疗费用 ?",
          totalCostQuestion: name + "的医疗费用总额是多少？",
          typeOptions: [
            "医疗护理 / 牙科护理",
            "药物",
            "健康保险",
            "其他（例如 OTC、医疗用品等）",
          ],
          conditional: {
            label1:
              name +
              " 开车到医疗机构或药房的频率？接送看护人的费用应计为两趟行程。",
            label2: name + " 到医疗机构或药房所支付的交通费和停车费是多少？",
          },
          drivetoLabel: name + " 开车到几个医疗机构或药房?",
          kitHeaderLabel: "Caring for " + name,
          medConTitle: name + " 的医疗机构或药房地址",
          medConHeader: name + " 的医疗机构或药房地址",
          medQoneLabel:
            " " +
            name +
            " 开车到医疗机构或药房的频率？接送看护人的费用应计为两趟行程。",
          medQtwoLabel:
            " " + name + " 到医疗机构或药房所支付的交通费和停车费是多少？",
        };
      case VI:
        return {
          heading: "Các chi phí y tế đối với " + name,
          question:
            name + "  có lái xe đến các cuộc hẹn y tế hoặc nhà thuốc không?",
          typeQuestion: name + "Loại chi phí y tế nào dành cho?",
          totalCostQuestion: "Tổng chi phí ý tế của " + name + "là bao nhiêu?",
          typeOptions: [
            "Chăm sóc y tế / răng miệng",
            "Thuốc",
            "Bảo hiểm y tế",
            "Những chi phí khác (như thuốc không cần kê đơn, vật tư y tế….)",
          ],
          conditional: {
            label1:
              name +
              " lái xe đưa  đến nhà cung cấp  bao lâu một lần?  Đến và về từ chỗ của nhà cung cấp phải được tính là hai chuyến",
            label2:
              name +
              " trả tiền chuyên chở và đậu xe cho các cuộc hẹn y tế hoặc hiệu thuốc là bao nhiêu?",
          },
          drivetoLabel:
            "Có bao nhiêu cuộc hẹn y tế hoặc nhà thuốc mà " +
            name +
            " lái xe đến?",
          kitHeaderLabel: "Caring for " + name,
          medConTitle:
            "Địa chỉ của cuộc hẹn y tế hoặc địa chỉ nhà thuốc của  " + name,
          medConHeader:
            "Địa chỉ của cuộc hẹn y tế hoặc địa chỉ nhà thuốc của  " + name,
          medQoneLabel:
            name +
            " lái xe đưa đến nhà cung cấp  bao lâu một lần? Đến và về từ chỗ của nhà cung cấp phải được tính là hai chuyến",
          medQtwoLabel:
            name +
            " trả tiền chuyên chở và đậu xe cho các cuộc hẹn y tế hoặc hiệu thuốc là bao nhiêu?",
        };
      case HI:
        return {
          heading: "Depans medikal pou " + name,
          question:
            "Èske " +
            name +
            " kondii pou ale nan randevou medikal oswa famasi?",
          typeQuestion: "Ki tip depans medikal ki la pou " + name + "?",
          totalCostQuestion: "Ki pri depans medikal total yo " + name + "?",
          typeOptions: [
            "Swen Medikal/Dantè",
            "Medikaman",
            "Asirans Sante",
            "Lòt (tankou medikaman san prescription, materyèl medikal, elatriye)",
          ],
          conditional: {
            label1:
              "Konbyen fwa " +
              name +
              " kondi pou ale nan adrès sa a? Pou ale ak soti lakay founisè ta dwe konte kòm de vwayaj.",
            label2:
              "Konbyen " +
              name +
              " peye pou transpò, pakin pou ale nan randevou medikal oswa famasi?",
          },
          drivetoLabel:
            "Nan konbyen randevou medikal oswa famasi " + name + " kondi ale?.",
          kitHeaderLabel: "Pran swen " + name,
          medConTitle: " Adrès Randevou medikal/famasi  " + name,
          medConHeader: " Adrès Randevou medikal/famasi  " + name,
          medQoneLabel:
            "Konbyen fwa " +
            name +
            " kondi pou ale nan adrès sa a? Pou ale ak soti lakay founisè ta dwe konte kòm de vwayaj.",
          medQtwoLabel:
            "Konbyen " +
            name +
            " peye pou transpò, pakin pou ale nan randevou medikal oswa famasi ?",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.incomeExpensesRecert) {
    //8th section page
    switch (language) {
      case EN:
        return {
          incomeTitle: "Noncitizen status",
          incomeHeader: "About your household's income",
          aboutIncomeBefore: "Does anyone in the household receive any ",
          aboutIncomeTrigger: "income or benefits",
          incomeHelp:
            "Income is money you receive from working. Benefits are money you receive from a source other than employment.",
          clearOption: "None",
          combineIncomeoptions: [
            "Wages",
            "Self-Employment",
            "Work Study",
            "SSI (Supplemental Security Income)",
            "RSDI (Retirement, Survivors, and Disability Insurance)",
            "Unemployment",
            "Child Support",
            "Pension",
            "Veterans Benefits",
            "Rental Income",
            "Workers Compensation",
            "Other",
          ],
          employmentTitle: "Employment",
          employmentHeader: "Employment Summary",
          wageIncomeBeforeLabel: "What is the ",
          wageIncomeTriggerLabel: "gross income",
          wagesTargetValue: "Wages",
          selfemeploymentTargetValue: "Self-Employment",
          workStudyTargetValue: "Work Study",
          rsdiTargetValue:
            "RSDI (Retirement, Survivors, and Disability Insurance)",
          SSITargetValue: "SSI (Supplemental Security Income)",
          unEmploymentTargetValue: "Unemployment",
          childSuppTargetValue: "Child Support",
          pensionTargetValue: "Pension",
          veteranBenefitsTargetValue: "Veterans Benefits",
          rentalIncomeTargetValue: "Income from Land Rental or Rental Property",
          workerCompTargetValue: "Workers Compensation",
          otherTargetValue: "Other",
          noneTargetValue: "None",

          mortageInlineHelpText:
            "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
          grossIncomeHelp:
            "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
          unitsPerLabel: " ",
          unitsOptions: ["Month", "Every two weeks", "Week"],
          labelOtherwage: "Add another Wage",
          adsSelfEmployee: "Add another Self-Employment",
          condWorkStudy: "Work Study",
          workStudyLabel: "Add another Work Study",
          anotherSSILabel: "Add another SSI",
          addRDILabel: "Add another RSDI",
          unEmpLabel: "Add another Unemployment",
          childSuppLabel: "Child Support",
          anotherChildsupport: "Add another Child Support",
          pensionLabel: "Pension",
          anotherPensionLabel: "Add another Pension",
          workerCompensationLabel: "Add another Worker's Compensation",
          veteranBenefit: "Veteran's Benefits",
          addAnotherVBenefitLabel: "Add another Veteran's Benefits",
          rentIncomeLabel: "Rental Income",
          repeatQuestionLabel: "Add another Rental Income",
          repeatincomeQuestionLabel: "Add another Rental Hours",
          unitsRentalOptions: ["Month", "Week"],
          questionButtonLabel: "Add another Other",
          incomeSummaryTitle: "Income summary",
          incomeSummaryHeader: "Income summary",
          expenseTitle: "Expenses",
          almostLabel: "Almost there!",
          housingLabel: "Housing costs",
          utilityLabel: "Utility costs",
          utilityHeader: "Tell us about your utility costs",
          expenseHeader: "Tell us about your shelter costs",
          expenseSubHeader:
            "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
          houseHoldQLabel: "Is your household responsible for any utilities?",
          utilityQOptions: [
            "Heat (oil, gas, electricity or propane, etc.)",
            "Electricity for an air conditioner",
            "A fee to use an air conditioner",
            "Electricity and/or gas",
            "Phone or cell phone service",
          ],
          utilitiesClearOption: "None",
          utilitiesDoNotPayOption: "I do not pay for any utility costs",
          shelterDoNotPayOption: "I do not pay for any shelter costs",
          houseQLabelBefore: "What type(s) of ",
          houseQLabelTrigger: "shelter costs",
          houseQLabelAfter: " does your household has?",
          houseQLabelHelp:
            "Examples of shelter costs include rent, mortgage, property taxes, condo fees, and/or home insurance.",
          utilityQLabelBefore: "What type(s) of ",
          utilityQLabelTrigger: "utility costs",
          utilityQLabelAfter: " does your household has?",
          utilityQLabelHelp:
            "Utility costs include heat, electricity, and/or phone services.",
          houseQOptions: [
            "Rent",
            "Mortgage",
            "Property taxes",
            "Home insurance",
            "Condo fees",
          ],
          houseRentQLabel: "How much is your household's rent?",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "per",
          houseUnitOptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          mortageQLabel: "How much is your household's mortgage?",
          mortageunitBetween: " ",
          mortageQoptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          mortageProptaxLabel: "How much is your household's property taxes?",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          insuranceCostQLabel:
            "How much is your household's home insurance costs?",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          insuranceClearOption: "No. I don't need to pay for this.",
          condofeeQLabel: "How much is your household's condo fee?",
          condofeepropBetween: "per",
          condofeeCostoptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          condofeeClearOption: "No. I don't need to pay for this.",
          propTaxQLabel: "Does your household have property taxes?",
          propTaxBetween: "per",
          propTaxOptions: ["Week", "Month", "Year"],
          propTaxClearOption: "No. I don't need to pay for this.",
          homeInsuranceQLabel: "Does your household have home insurance costs?",
          homeInsuranceBetween: "per",
          homeInsuranceUnits: ["Week", "Month", "Year"],
          homeInsuranceClearOption: "No. I don't need to pay for this.",
          condoFeeQLabel: "Does your household have a condo fee?",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["Week", "Month", "Year"],
          condoFeeClearOption: "No. I don't need to pay for this.",

          //new labels:
          anyUtilQcost: "Does your household have any utility costs?",
          chooseHoldErrmsg: "Please choose household utilities",
          houseCostErrmsg: "Please choose housing costs",
          rentErrMsg: "Please enter rent",
          anotherRentLabel: "+ Add another rent",
          mortageErrLabel: "Please enter mortgage",
          propTaxLabel: "Please enter property taxes",
          propTaxButtonLabel: "+ Add another property taxes",
          homeInsurbutton: "+ Add another home insurance",
          homeInsurErrmsg: "Please enter home insurance",
          condoButtonLabel: "+ Add another condo fee",
          condoFeeErrmsg: "Please enter condo fee",
          addMortageButtonLabel: "+ Add another mortgage",
          childTitle: "Child or Adult Dependent Care Costs",
          childCostHeader:
            "Tell us about your Child or Adult Dependent Care costs",
          childCostSubHeader:
            "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
          careGiveObjbefore1:
            "Does anyone in the household pay child or adult ",
          careGiveObjtrigger1: "dependent care expenses",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "This includes the costs to ",
          careGiveObjtrigger2: "transport",
          careGiveObjAfter2:
            " the dependent(s) to and/or from a care provider.",
          careGiveHelp1:
            "Dependent care costs include any payment you make for someone to watch and/or transport a child or adult who requires care.",
          careGiveHelp2:
            "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
          questionClearOption: "None",
          kidsandadultSubheader:
            "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
          costBetween: " ",
          weekMonthOptions: ["Month", "Week"],
          costOptions: ["Week", "Month", "Year"],
          yesnoOptions: ["Yes", "No"],
          targetValueYes: "Yes",
          noofAppointment: "Please choose no.of appointments",
          addressProvider: "What is the address of the provider?",
          freqMonthoption: ["Monthly"],
          freqTripoptions: [
            "Annual",
            "Bimonthly (every two months)",
            "Biweekly (every two weeks)",
            "Monthly",
            "Quarterly",
            "Semiannual (twice a year)",
            "Semimonthly (twice a month)",
            "Weekly",
          ],
          unitMonthTargetValue: { unit: "Monthly" },
          tripCountErrMsgLen:
            "Please enter less than/equal to 6 characters for trip count",
          tripCostErrMsgLen:
            "Please enter less than/equal to 6 characters for trip cost",
          childSupTitle: "Child Support Costs",
          supportCostHeader: "Child Support Costs",
          childSupportQlegalbefore:
            "Does anyone in the household have a legal obligation (court order) to pay ",
          childSupportQlegaltrigger: "child support",
          childSupportQlegalAfter: " to a child not living with you?",
          childSupportHelp:
            "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
          medicalTitle: "Medical Expenses",
          medicalInlineSeethisFull: "See this full list of",
          medicalInlineEligibleMedical: " eligible medical expenses",
          medicalHeader: "Medical Expenses Summary",
          medicalSubHeader:
            "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
          medicalLabel1:
            "If no one in your household is disabled or at least 60 years old, please skip this section.",
          medicalExpBefore:
            "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
          medicalExpTrigger: " transportation",
          medicalExpAfter:
            ", over the counter medications, dental or eye care, adult diapers, etc.?",
          medicalExphelp:
            "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
          medicalInhelp: "See this full list of",
          medicalInhelpEligible: "eligible medical expenses",
          medicalAddress:
            "What is the address of the medical appointment or pharmacy?",
          zipErrmsg: "Please enter a valid Zipcode.",
          signSubmitLabel: "Sign & Submit",
          signSubmitHeader: "Sign & Submit",
          signSubmitSub:
            "You made it through the form! Now for the easy stuff.",
          subSectionLabel: "Submitted",
          submitLabelFinal: "Your application has been submitted!",
          tellusAbout: "Tell us about your Child support costs",
          moneyYougettitle:
            "This includes money you may get from a job, the government, or other sources.",
          submitSummaryHeader: "Your SNAP Recertification Application Has Been Submitted Successfully!",
          submitSummaryAESAPHeader: "Your SNAP AESAP Recertification Application Has Been Submitted Successfully!",
          submitSummaryTANFHeader: "Your TANF Redetermination Has Been Submitted Successfully!",
          heading: name + "'s income and benefits",
          mainQuestion:
            "What type(s) of income and benefits does " + name + " have?",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]: " amount of " + name + " wages?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " self-employment?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]: " amount of " + name + " work study?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " SSI (Supplemental Security Income)?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " +
                  name +
                  " RSDI (Retirement, Survivor's, and Disability Insurance)?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " unemployment?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " child support?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]: " amount of " + name + " pension?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " veteran's benefits?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " rental income?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]:
                "How many hours does " +
                name +
                " work on the rental income property?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " Worker's Compensation?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "What is the ",
                [Props.LABEL_OBJ_TRIGGER]: "gross income",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " other income or benefits?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
          ],
          summaryLabel: "Summary"
        };
      case ES:
        return {
          incomeTitle: "Estado de no ciudadano",
          incomeHeader: "Sobre el ingreso de su núcleo familiar",
          aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
          aboutIncomeTrigger: "ingresos o beneficios?",
          incomeHelp:
            "El ingreso es dinero que usted recibe por trabajar. Los beneficios son dinero que usted recibe de una fuente distinta a un empleo.",
          clearOption: "Ninguno",
          combineIncomeoptions: [
            "Salarios",
            "Trabajo independiente",
            "Trabajo estudio",
            "SSI (Ingreso de seguridad suplementario)",
            "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
            "Desempleo",
            "Manutención",
            "Pensión",
            "Beneficios de veteranos",
            "Ingreso por alquiler",
            "Compensación a los trabajadores",
            "Otro",
          ],
          employmentTitle: "Empleo",
          employmentHeader: "Resumen de empleo",
          wageIncomeBeforeLabel: "Cuál es el ",
          wageIncomeTriggerLabel: "ingresos brutos",
          wagesTargetValue: "Salarios",
          selfemeploymentTargetValue: "Trabajo independiente",
          workStudyTargetValue: "Trabajo estudio",
          rsdiTargetValue:
            "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
          SSITargetValue: "SSI (Ingreso de seguridad suplementario)",
          unEmploymentTargetValue: "Desempleo",
          childSuppTargetValue: "Manutención",
          pensionTargetValue: "Pensión",
          veteranBenefitsTargetValue: "Beneficios de veteranos",
          rentalIncomeTargetValue: "Ingreso por alquiler",
          workerCompTargetValue: "Compensación a los trabajadores",
          otherTargetValue: "Otro",
          noneTargetValue: "Ninguno",
          mortageInlineHelpText:
            "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
          grossIncomeHelp:
            "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
          unitsPerLabel: " ",
          unitsOptions: ["Mes", "Cada dos semanas", "Semana"],
          labelOtherwage: "Agrega otro Salarios",
          adsSelfEmployee: "Agrega otro Trabajo independiente",
          condWorkStudy: "Trabajo estudio",
          workStudyLabel: "Agrega otro Trabajo estudio",
          anotherSSILabel: "Agrega otro SSI",
          addRDILabel: "Agregue otro RSDI",
          unEmpLabel: "Agrega otro Desempleo",
          childSuppLabel: "Manutención",
          anotherChildsupport: "Agrega otro Manutención",
          pensionLabel: "Pensión",
          anotherPensionLabel: "Agrega otro Pensión",
          workerCompensationLabel:
            "Agregue otra Compensación para Trabajadores",
          veteranBenefit: "Beneficios de veteranos",
          addAnotherVBenefitLabel: "Agrega otro Beneficios de veteranos",
          rentIncomeLabel: "Ingreso por alquiler",
          repeatQuestionLabel: "Agrega otro Ingreso por alquiler",
          repeatincomeQuestionLabel: "Agrega otro Ingreso por alquiler",
          unitsRentalOptions: ["Mes", "Semana"],
          questionButtonLabel: "Agrega otro Otro",
          incomeSummaryTitle: "Resumen de ingresos",
          incomeSummaryHeader: "Resumen de ingresos",
          expenseTitle: "Gastos",
          almostLabel: "¡Casi termina!",
          houseUtlilityLabel: " Costos de vivienda y servicios públicos",
          housingLabel: "Costos de vivienda",
          utilityLabel: "Costos de servicios",
          utilityHeader: "Indíquenos sus costos de servicios",
          expenseHeader: "Díganos sobre los costos de su vivienda",
          expenseSubHeader:
            "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
          houseHoldQLabel: "Is your household responsible for any utilities?",
          utilityQOptions: [
            "Calefacción (nafta, gas, electricidad o propano, etc.)",
            "Electricidad para un aire acondicionado",
            "Cargo por usar un aire acondicionado ",
            "Electricidad y/o gas",
            "Servicio de teléfono o celular",
          ],
          utilitiesClearOption: "Ninguno",
          utilitiesDoNotPayOption: "No pago servicios",
          shelterDoNotPayOption: "No pago costos de refugio",
          houseQLabelBefore: "¿Qué tipos de ",
          houseQLabelTrigger: "Costos de refugio",
          houseQLabelAfter: " tiene su grupo familiar?",
          houseQLabelHelp:
            "Algunos ejemplos de costos de vivienda incluyen alquiler, hipoteca, impuestos a la propiedad, tasas de condominio o seguro de la vivienda.",
          utilityQLabelBefore: "¿Qué tipos de ",
          utilityQLabelTrigger: "costos de servicios",
          utilityQLabelAfter: " tiene su grupo familiar?",
          utilityQLabelHelp:
            "Los costos de servicios públicos incluyen servicios de calefacción, electricidad y/o teléfono.",
          houseQOptions: [
            "Alquiler",
            "Hipoteca",
            "Impuestos a la propiedad",
            "Seguro de la casa",
            "Tasa de condominio",
          ],
          houseRentQLabel: "¿Cuánto es el alquiler de su grupo familiar?",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "por",
          houseUnitOptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
          mortageunitBetween: " ",
          mortageQoptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          mortageProptaxLabel:
            "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          insuranceCostQLabel:
            "¿Cuál es el seguro de la casa de su núcleo familiar?",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          insuranceClearOption: "No. No necesito pagar por esto.",
          condofeeQLabel:
            "¿Cuál es el tasa de condominio de su núcleo familiar?",
          condofeepropBetween: "por",
          condofeeCostoptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          condofeeClearOption: "No. No necesito pagar por esto.",
          propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
          propTaxBetween: "por",
          propTaxOptions: ["Semana", "Mes", "Año"],
          propTaxClearOption: "No. No necesito pagar por esto.",
          homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
          homeInsuranceBetween: "por",
          homeInsuranceUnits: ["Semana", "Mes", "Año"],
          homeInsuranceClearOption: "No. No necesito pagar por esto.",
          condoFeeQLabel: "¿Su hogar tiene una tarifa de condominio?",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["Semana", "Mes", "Año"],
          condoFeeClearOption: "No. No necesito pagar por esto.",

          //new labels:
          anyUtilQcost:
            "¿Su núcleo familiar tiene costos de servicios públicos? ",
          chooseHoldErrmsg: "Por favor elija servicios domésticos",
          houseCostErrmsg: "Por favor elija los costos de la vivienda",
          rentErrMsg: "Por favor ingrese alquiler",
          anotherRentLabel: "+ Agregar otra alquiler",
          mortageErrLabel: "Por favor ingrese la hipoteca",
          propTaxLabel: "Ingrese los impuestos a la propiedad",
          propTaxButtonLabel: "+ Agregue otros impuestos a la propiedad",
          homeInsurbutton: "+ Agregue otro seguro de la casa",
          homeInsurErrmsg: "Por favor ingrese seguro de la casa",
          condoButtonLabel: "+ Agregar otra tarifa de condominio",
          condoFeeErrmsg: "Por favor ingrese el costo",
          addMortageButtonLabel: "+ Agregar otra hipoteca",
          childTitle: "Costos de cuidado de hijos o adultos dependientes",
          childCostHeader:
            "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
          childCostSubHeader:
            "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos. ",
          careGiveObjbefore1: "¿Alguien en el hogar paga servicios de niño o adulto? ",
          careGiveObjtrigger1:
            "costos de cuidado infantil o de adultos dependientes",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "Esto incluye costos de ",
          careGiveObjtrigger2: "transporte",
          careGiveObjAfter2:
            " del dependiente hacia y desde un proveedor de atención.",

          careGiveHelp1:
            "Los costos de cuidado de dependientes incluyen cualquier pago que usted efectúe por alguien que cuide y/o transporte a un niño o adulto que requiera cuidado.",
          careGiveHelp2:
            "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
          questionClearOption: "Ninguno",
          kidsandadultSubheader:
            "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
          costBetween: " ",
          weekMonthOptions: ["Semana", "Mes"],
          costOptions: ["Semana", "Mes", "Año"],
          yesnoOptions: ["Sí", "No"],
          targetValueYes: "Sí",
          noofAppointment: "Por favor, elija no.of citas",
          addressProvider: "¿Cuál es la dirección del proveedor?",
          freqTripoptions: [
            "Anualmente",
            "Bimestralmente (cada dos meses)",
            "Quincenalmente (cada dos semanas)",
            "Mensualmente",
            "Trimestralmente",
            "Bianual (dos veces al año)",
            "semi-mensal (dos veces al mes)",
            "Semanalmente",
          ],
          unitMonthTargetValue: { unit: "Mes" },
          freqMonthoption: ["Mensualmente"],
          tripCountErrMsgLen:
            "Ingrese 6 caracteres como máximo por conteo de desconexiones",
          tripCostErrMsgLen:
            "Ingrese 6 caracteres o menos para el costo del viaje",
          childSupTitle: "Costo de la manutención infanti",
          supportCostHeader: "Costo de la manutención infanti",
          childSupportQlegalbefore:
            "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
          childSupportQlegaltrigger: "manutención a un niño",
          childSupportQlegalAfter: " que non viva con usted?",
          childSupportHelp:
            "La manutención pagada puede contarse como un gasto cuando se exige legalmente y los pagos se están haciendo. Esto incluye pagos por mora",
          medicalTitle: "Costos médicos",
          medicalInlineSeethisFull: "Consulte la lista completa de",
          medicalInlineEligibleMedical: " gastos médicos elegibles.",
          medicalHeader: "Resumen de gastos médicos",
          medicalSubHeader:
            "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
          medicalLabel1:
            "Si ningún miembro del grupo familiar es discapacitado o tiene al menos 60 años, omita esta sección.",
          medicalExpBefore:
            "¿Alguna de las personas que se indican a continuación paga un seguro de salud u otros costos médicos, incluidos medicamentos de venta con receta,",
          medicalExpTrigger: "transporte",
          medicalExpAfter:
            ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
          medicalExphelp:
            "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
          medicalInhelp: "Consulte la lista completa de",
          medicalInhelpEligible: " gastos médicos elegibles",
          medicalAddress: "¿Cuál es la dirección de la cita médica o farmacia?",
          zipErrmsg: "Por favor ingrese un código postal válido.",
          signSubmitLabel: "Firmar y enviar",
          signSubmitHeader: "Firmar y Enviar",
          signSubmitSub:
            "¡Completo todo el formulario! Ahora, pasemos a lo fácil.",
          subSectionLabel: "Presentada",
          submitLabelFinal: "¡Su solicitud se ha enviado!",
          tellusAbout: "Díganos sobre sus costos de manutención",
          moneyYougettitle:
            "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
          submitSummaryHeader: "¡Su solicitud de recertificación SNAP se envió con éxito!",
          submitSummaryAESAPHeader: "¡Su solicitud de recertificación SNAP AESAP se ha enviado con éxito!",
          submitSummaryTANFHeader: "¡Su redeterminación de TANF se ha enviado con éxito!",

          heading: "Ingreso y beneficios de " + name,
          mainQuestion:
            "¿Qué tipos de ingresos o beneficios tiene " + name + "?",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
                [Props.LABEL_OBJ_AFTER]: " amount of " + name + " Salarios?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]:
                  " amount of " + name + " Trabajo independiente?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]: " de " + name + " Trabajo estudio?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
                [Props.LABEL_OBJ_AFTER]:
                  " de " + name + " SSI (Ingreso de seguridad suplementario)?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]:
                  " de " +
                  name +
                  " RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
                [Props.LABEL_OBJ_AFTER]: " de " + name + " Desempleo?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]: " de " + name + " Manutención?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]: " de " + name + " Pensión?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
                [Props.LABEL_OBJ_AFTER]:
                  " de " + name + " Beneficios de veteranos?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]:
                  " de " + name + " Ingreso por alquiler?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]:
                "¿Cuántas horas " +
                name +
                " trabaja en la propiedad de ingreso por alquiler?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
                [Props.LABEL_OBJ_AFTER]:
                  " de " + name + " Compensación a los trabajadores?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
                [Props.LABEL_OBJ_AFTER]: " de " + name + " Otro?",
                [Props.LABEL_OBJ_HELP]:
                  "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
              },
            },
          ],
          summaryLabel: "Resumen"
        };
      case PT:
        return {
          incomeTitle: "Status de não cidadão",
          incomeHeader: "Sobre a renda da sua família",
          aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
          aboutIncomeTrigger: " renda ou benefício?",
          incomeHelp:
            "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
          clearOption: "Nenhum",
          combineIncomeoptions: [
            "Salário",
            "Trabalho autônomo",
            "Estudo",
            "SSI (Renda de segurança suplementar)",
            "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
            "Desemprego",
            "Suporte infantil",
            "Pensão",
            "Benefício de veterano",
            "Renda de aluguel",
            "Compensação do trabalhador",
            "Outros",
          ],
          wageIncomeBeforeLabel: "Qual é o ",
          wageIncomeTriggerLabel: "rendimento bruto",
          wagesTargetValue: "Salário",
          selfemeploymentTargetValue: "Trabalho autônomo",
          workStudyTargetValue: "Estudo",
          rsdiTargetValue:
            "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
          SSITargetValue: "SSI (Renda de segurança suplementar)",
          unEmploymentTargetValue: "Desemprego",
          childSuppTargetValue: "Suporte infantil",
          pensionTargetValue: "Pensão",
          veteranBenefitsTargetValue: "Benefício de veterano",
          rentalIncomeTargetValue: "Renda de aluguel",
          workerCompTargetValue: "Compensação do trabalhador",
          otherTargetValue: "Outros",
          noneTargetValue: "Nenhum",
          mortageInlineHelpText:
            "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
          grossIncomeHelp:
            "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
          unitsPerLabel: " ",
          unitsOptions: ["Mês", "A cada duas semanas", "Semana"],
          labelOtherwage: "Adicionar outro Salário",
          adsSelfEmployee: "Adicionar outro Trabalho autônomo",
          condWorkStudy: "Estudo",
          workStudyLabel: "Adicionar outro Estudo",
          anotherSSILabel: "Adicionar outro SSI",
          addRDILabel: "Adicione outro RSDI",
          unEmpLabel: "Adicionar outro Desemprego",
          childSuppLabel: "Suporte infantil",
          anotherChildsupport: "Adicionar outro Suporte infantil",
          pensionLabel: "Pensão",
          anotherPensionLabel: "Adicionar outro Pensão",
          workerCompensationLabel: "adicionar compensação de outro trabalhador",
          veteranBenefit: "Benefício de veterano",
          addAnotherVBenefitLabel: "Adicionar outro Benefício de veterano",
          rentIncomeLabel: "Renda de aluguel",
          repeatQuestionLabel: "Adicionar outro Renda de aluguel",
          repeatincomeQuestionLabel: "Adicionar outro Renda de aluguel",
          unitsRentalOptions: ["Mês", "Semana"],
          questionButtonLabel: "Adicionar outro Outros",
          incomeSummaryTitle: "Resumo da renda",
          incomeSummaryHeader: "Resumo da renda",
          expenseTitle: "Despesas",
          almostLabel: "Quase lá!",
          houseUtlilityLabel: "Custos residenciais e utilitários",
          housingLabel: "Custos com alojamento",
          utilityLabel: "Custos com serviços públicos",
          utilityHeader: "Conte-nos sobre seus gastos com serviços públicos",
          expenseHeader: "Fale sobre os seus custos residenciais",
          expenseSubHeader:
            "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
          houseHoldQLabel: "Is your household responsible for any utilities?",
          utilityQOptions: [
            "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
            "Eletricidade para ar condicionado",
            "Uma taxa para usar um ar condicionado",
            "Eletricidade e/ou gás",
            "Serviço de telefone fixo ou celular",
          ],
          utilitiesClearOption: "Nenhum",
          utilitiesDoNotPayOption: "Eu não pago por serviços públicos",
          shelterDoNotPayOption: "Eu não pago por nenhum custo de abrigo",
          houseQLabelBefore: "Que tipo(s) de ",
          houseQLabelTrigger: "Custos de abrigo",
          houseQLabelAfter: " tem seu domicílio?",
          houseQLabelHelp:
            "Exemplos de gastos com moradia incluem aluguel, hipoteca, impostos do imóvel, taxas de condomínio e/ou seguro residencial.",
          utilityQLabelBefore: "Que tipo(s) de ",
          utilityQLabelTrigger: "gastos com serviços públicos",
          utilityQLabelAfter: " tem seu domicílio?",
          utilityQLabelHelp:
            "Os custos habitacionais incluem aquecimento, eletricidade e/ou serviços de telefone.",
          houseQOptions: [
            "Aluguel",
            "Financiamento",
            "Impostos da propriedade",
            "Seguro residencial",
            "Condomínio",
          ],
          houseRentQLabel: "Qual é o valor do seu aluguel?",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "por",
          houseUnitOptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          mortageQLabel: "Qual é o valor do seu financiamento?",
          mortageunitBetween: " ",
          mortageQoptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          mortageProptaxLabel:
            "Qual é o valor dos impostos da sua propriedade?",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          insuranceClearOption: "Não. Eu não preciso pagar por isso.",
          condofeeQLabel: "Qual é o valor do seu condomínio?",
          condofeepropBetween: "por",
          condofeeCostoptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          condofeeClearOption: "Não. Eu não preciso pagar por isso.",
          propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
          propTaxBetween: "por",
          propTaxOptions: ["Semana", "Mês", "Ano"],
          propTaxClearOption: "Não. Eu não preciso pagar por isso.",
          homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
          homeInsuranceBetween: "por",
          homeInsuranceUnits: ["Semana", "Mês", "Ano"],
          homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
          condoFeeQLabel: "A sua casa possui uma condominio?",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["Semana", "Mês", "Ano"],
          condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

          //new labels:
          anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
          chooseHoldErrmsg: "Escolha utilitários domésticos",
          houseCostErrmsg: "Escolha os custos de habitação",
          rentErrMsg: "Entre com aluguel",
          anotherRentLabel: "+ Adicionar outro aluguel",
          mortageErrLabel: "Insira uma hipoteca",
          propTaxLabel: "Digite os impostos sobre a propriedade",
          propTaxButtonLabel: "+ Adicione outros impostos sobre a propriedade",
          homeInsurbutton: "+ Adicione outro seguro de casa",
          homeInsurErrmsg: "Digite o seguro de casa",
          condoButtonLabel: "+ Adicionar outra condominio",
          condoFeeErrmsg: "Entre com condominio",
          addMortageButtonLabel: "+ Adicionar outros condominio",
          childTitle: "Custos de crianças ou dependentes",
          childCostHeader:
            "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
          childCostSubHeader:
            "Informe todos os serviços públicos pelos quais você é responsável pelo pagamento, mesmo que já os tenhamos em arquivo. Você pode incluir despesas mesmo se estiver atrasado ou não puder pagá-las.",
          careGiveObjbefore1: "Alguém na sua casa tem despesas de dependente ",
          careGiveObjtrigger1: "Gastos com cuidados de dependentes",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "Incluindo os custos de ",
          careGiveObjtrigger2: "transporte do(s)",
          careGiveObjAfter2: " dependente(s) e/ou de um provedor de cuidados.",

          // careGiveObjbefore:
          //   "Alguém mais no domicílio tem quaisquer despesas adicionais de manutenção de filhos ou adultos dependentes? Incluindo os custos de ",
          // careGiveObjtrigger: "transporte do(s)",
          // careGiveObjAfter: "dependente(s) e/ou de um provedor de cuidados.",

          careGiveHelp1:
            "As despesas de dependente com um prestador de serviços incluem qualquer pagamento que você fizer a alguém para que essa pessoa cuide e/ou transporte uma criança ou adulto que precise de cuidado.",
          careGiveHelp2:
            "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
          questionClearOption: "Nenhum",
          kidsandadultSubheader:
            "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
          costBetween: " ",
          weekMonthOptions: ["Semana", "Mês"],
          costOptions: ["Semana", "Mês", "Ano"],
          yesnoOptions: ["Sim", "Não"],
          targetValueYes: "Sim",
          noofAppointment: "Por favor, escolha o número de compromissos",
          addressProvider: "Qual é o endereço do prestador de serviço?",
          freqTripoptions: [
            "Anual",
            "Bimestralmente (a cada dois meses)",
            "Quinzenal (a cada duas semanas)",
            "Mensal",
            "Trimestral",
            "Semestral (duas vezes ao ano)",
            "semi-mensal (duas vezes por mês)",
            "Semanal",
          ],
          unitMonthTargetValue: { unit: "Mês" },
          freqMonthoption: ["Mensal"],
          tripCountErrMsgLen: "Digite um valor de medidor com até 6 caracteres",
          tripCostErrMsgLen:
            "Insira 6 ou menos caracteres para o custo da viagem",
          childSupTitle: "Custos de suporte infantil",
          supportCostHeader: "Custos de suporte infantil",
          childSupportQlegalbefore:
            "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar pelo ",
          childSupportQlegaltrigger: " suporte de uma criança",
          childSupportQlegalAfter: " que não vive com você?",
          childSupportHelp:
            "O pagamento de suporte infantil pode ser contado como uma despesa quando for exigido legalmente e quando os pagamentos estiverem sendo feitos. Incluindo pagamentos em atraso.",
          medicalTitle: "Custos médicos",
          medicalInlineSeethisFull: "Veja esta lista completa de",
          medicalInlineEligibleMedical: " despesas médicas elegíveis.",
          medicalHeader: "Fale sobre os seus custos médicos",
          medicalSubHeader:
            "Informe todos os serviços públicos pelos quais você é responsável pelo pagamento, mesmo que já os tenhamos em arquivo. Você pode incluir despesas mesmo se estiver atrasado ou não puder pagá-las.",
          medicalLabel1:
            "Se ninguém em seu domicílio for incapacitado ou tiver pelo menos 60 anos, pule esta seção.",
          medicalExpBefore:
            "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
          medicalExpTrigger: "transporte",
          medicalExpAfter:
            ", medicamentos de venda livre (sem receita), cuidados odontológicos ou oftalmológios, fraldas para adultos, etc.?",
          medicalExphelp:
            "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
          medicalInhelp: "Veja esta lista completa de",
          medicalInhelpEligible: "despesas médicas elegíveis",
          medicalAddress:
            "Qual é o endereço da consulta médica ou da farmácia?",
          zipErrmsg: "Por favor insira um código postal válido",
          signSubmitLabel: "Assinar e enviar",
          signSubmitHeader: "Assinar e Enviar",
          signSubmitSub:
            "Você chegou ao fim do formulário! Agora vamos à parte fácil.",
          subSectionLabel: "Submetido",
          submitLabelFinal: "A sua solicitação foi enviada!",
          tellusAbout: "Fale sobre os custos de suporte infantil",
          moneyYougettitle:
            "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
          submitSummaryHeader: "Sua Recertificação foi enviada",

          heading: "Renda e benefícios recebidos por " + name,
          mainQuestion:
            "Qual(is) tipo(s) de renda ou benefícios " + name + " recebe?",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]: name + " recebe como Salário?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  " " + name + " recebe como Trabalho autônomo?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]: " " + name + "  recebe como Estudo?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  " " +
                  name +
                  " recebe como SSI (Renda de segurança suplementar)?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  "  " +
                  name +
                  " recebe como RSDI (Seguro de aposentadoria, sobreviventes e deficiências)?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  "" + name + "  recebe como Desemprego?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  " " + name + " recebe como Suporte infantil?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]: " " + name + " recebe como Pensão?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  " " + name + " recebe como Benefício de veterano?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  name + " recebe como Renda de aluguel?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]:
                "Quantas horas " +
                name +
                " trabalha para pagar o aluguel da propriedade?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
                [Props.LABEL_OBJ_AFTER]:
                  name + " recebe como Compensação do trabalhador?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
                [Props.LABEL_OBJ_TRIGGER]: "renda bruta que ",
                [Props.LABEL_OBJ_AFTER]:
                  name + "recebe como outros rendimentos ou benefícios?",
                [Props.LABEL_OBJ_HELP]:
                  "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
              },
            },
          ],
          summaryLabel: "Summary"
        };
      case ZH:
        return {
          incomeTitle: "非公民状态",
          incomeHeader: "关于您的家庭收入",
          aboutIncomeBefore: "您家中是否有人获得任何 ",
          aboutIncomeTrigger: "收入或补助?",
          incomeHelp:
            "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
          clearOption: "无",
          combineIncomeoptions: [
            "工资",
            "个体经营",
            "工作研究",
            "SSI（补充保障收入）",
            "RSDI（退休，幸存者和残疾保险）",
            "失业",
            "子女抚养费",
            "养老金",
            "退伍军人补贴",
            "租金收入",
            "工伤赔偿其",
            "其他",
          ],
          wageIncomeBeforeLabel: "是什麼 ",
          wageIncomeTriggerLabel: "總收入",
          wagesTargetValue: "工资",
          selfemeploymentTargetValue: "个体经营",
          workStudyTargetValue: "工作研究",
          SSITargetValue: "SSI（补充保障收入）",
          unEmploymentTargetValue: "失业",
          rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
          childSuppTargetValue: "子女抚养费",
          pensionTargetValue: "养老金",
          veteranBenefitsTargetValue: "退伍军人补贴",
          rentalIncomeTargetValue: "租金收入",
          workerCompTargetValue: "工伤赔偿其",
          otherTargetValue: "其他",
          noneTargetValue: "无",
          mortageInlineHelpText:
            "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
          grossIncomeHelp: "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
          unitsPerLabel: " ",
          unitsOptions: ["月", "每两周", "周"],
          labelOtherwage: "添加另一個工資",
          adsSelfEmployee: "添加另一個自僱",
          condWorkStudy: "工作研究",
          workStudyLabel: "添加另一個工作研究",
          anotherSSILabel: "添加另一個SSI",
          addRDILabel: "添加另一個RSDI",
          unEmpLabel: "再增加一個失業",
          childSuppLabel: "子女撫養費",
          anotherChildsupport: "添加另一個子支持",
          pensionLabel: "養老金",
          anotherPensionLabel: "再加一個養老金",
          workerCompensationLabel: "添加另一个工人的赔偿",
          veteranBenefit: "老兵的好处",
          addAnotherVBenefitLabel: "添加另一個退伍軍人的好處",
          rentIncomeLabel: "贴租金收",
          repeatQuestionLabel: "添加另一个租金收入",
          repeatincomeQuestionLabel: "添加另一个租金收入",
          unitsRentalOptions: ["月", "周"],
          questionButtonLabel: "添加另一個租金收入",
          incomeSummaryTitle: "收入摘要",
          incomeSummaryHeader: "收入摘要",
          expenseTitle: "开支",
          almostLabel: "还差一步！",
          houseUtlilityLabel: "住房和通用开支",
          housingLabel: "住房费用",
          utilityLabel: "支付哪种类型的",
          utilityHeader: "告诉我们您的公用设施费用",
          expenseHeader: "请告诉我们您的家庭开支",
          expenseSubHeader:
            "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
          houseHoldQLabel: "你家是否负责任何公用事业？?",
          utilityQOptions: [
            "取暖费（石油，天然气，电力或丙烷等）",
            "空调用电费",
            "空调使用费",
            "电费和/或燃气费",
            "电话费或手机费",
          ],
          utilitiesClearOption: "无",
          utilitiesDoNotPayOption: "我不支付任何公用设施费用",
          shelterDoNotPayOption: "我不支付任何住房费用",
          houseQLabelBefore: "您的家庭 ",
          houseQLabelTrigger: "住房费用",
          houseQLabelAfter: " 公用设施费用？",
          houseQLabelHelp:
            "住房费用示例包括房租、抵押贷款、财产税、物业管理费用和/或家庭保险。",
          utilityQLabelBefore: "您的家庭 ",
          utilityQLabelTrigger: "支付哪种类型的",
          utilityQLabelAfter: " 公用设施费用？",
          utilityQLabelHelp: "公用事业费用包括采暖、供电和/或电话服务。",
          houseQOptions: [
            "房租",
            "抵押贷款",
            "财产税",
            "家庭保险",
            "物业管理费用",
          ],
          houseRentQLabel: "您的住房的月租金有多少？",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "每",
          houseUnitOptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          mortageQLabel: "您家的房贷有多少？",
          mortageunitBetween: " ",
          mortageQoptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          mortageProptaxLabel: "您的住房的财产税有多少？",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          insuranceCostQLabel: "您的住房的房屋保险有多少？",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          insuranceClearOption: "不，我不需要为此付费。",
          condofeeQLabel: "您的住房的物业管理费有多少？",
          condofeepropBetween: "每",
          condofeeCostoptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          condofeeClearOption: "不，我不需要为此付费。",
          propTaxQLabel: "你家有財產稅嗎？",
          propTaxBetween: "每",
          propTaxOptions: ["周", "月", "年"],
          propTaxClearOption: "不，我不需要为此付费。",
          homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
          homeInsuranceBetween: "每",
          homeInsuranceUnits: ["周", "月", "年"],
          homeInsuranceClearOption: "不，我不需要为此付费。",
          condoFeeQLabel: "你家有公寓費嗎？",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["周", "月", "年"],
          condoFeeClearOption: "不，我不需要为此付费。",

          //new labels:
          anyUtilQcost: "您的家庭有哪些通用开支？",
          chooseHoldErrmsg: "请选择家用电器",
          houseCostErrmsg: "请选择住房费用",
          rentErrMsg: "请输入租金",
          anotherRentLabel: "+ 添加另一個租金",
          mortageErrLabel: "请输入抵押",
          propTaxLabel: "请输入财产税",
          propTaxButtonLabel: "+ 添加另一個財產稅",
          homeInsurbutton: "+ 添加另一個家庭保險",
          homeInsurErrmsg: "请输入家庭保险",
          condoButtonLabel: "+ 添加另一個公寓費用",
          condoFeeErrmsg: "请输入公寓费用",
          addMortageButtonLabel: "+ 添加另一個抵押",
          childTitle: "子女或成人照护费用",
          childCostHeader: "告诉我们您的子女或成人的照护费",
          childCostSubHeader:
            "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。",
          careGiveObjbefore1: "家庭成员中是否有儿童或成人",
          careGiveObjtrigger1: "亲属护理费用",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "此类费用包括 ",
          careGiveObjtrigger2: "交通",
          careGiveObjAfter2: " 交给和/或收自医疗服务提供者的受扶养人费用。",
          careGiveHelp1:
            "受扶养人照顾费用包括您请人照看和/或运送需要护理的儿童或成人而支付的任何费用.",
          careGiveHelp2:
            "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
          questionClearOption: "无",
          kidsandadultSubheader:
            "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
          costBetween: " ",
          weekMonthOptions: ["周", "月"],
          costOptions: ["周", "月", "年"],
          yesnoOptions: ["是", "否"],
          targetValueYes: "是",
          noofAppointment: "请选择任命数量",
          addressProvider: "看护人的地址？",
          freqTripoptions: [
            "全年",
            "每两月（每两个月一次）",
            "每两周（每两周一次）",
            "每月",
            "每季度",
            "每半年（一年两次）",
            "每半月（每月两次）",
            "每周",
          ],
          unitMonthTargetValue: { unit: "月" },
          freqMonthoption: ["每月"],
          tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
          tripCostErrMsgLen: "请输入少于/等于 6 个字符的旅程费用",
          childSupTitle: "子女抚养费用",
          supportCostHeader: "子女抚养费用",
          childSupportQlegalbefore:
            "您家中是否有人必须向不与您同住的子女支付法定 ",
          childSupportQlegaltrigger: "子女抚养费",
          childSupportQlegalAfter: " ?",
          childSupportHelp:
            "在法定情况下以及正在支付的情况下，子女抚养费可以算作一项开支。这包括拖欠款项",
          medicalTitle: "医疗费用",
          medicalInlineSeethisFull: "参阅此的完整列表。",
          medicalInlineEligibleMedical: "合格医疗费用",
          medicalHeader: "请告诉我们您的医疗费",
          medicalSubHeader:
            "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。 ",
          medicalLabel1: "如果您的家庭中无人残疾或年满 60 岁，请跳过此部分。",
          medicalExpBefore:
            "下方所列任何人是否支付健康保险或其他医疗费用，包括处方、",
          medicalExpTrigger: "交通",
          medicalExpAfter: "、非处方药物、牙科或眼科护理、成年人纸尿裤等？",
          medicalExphelp:
            "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
          medicalInhelp: "请参阅此完整列表",
          medicalInhelpEligible: "合格医疗开支的完整清单。",
          medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
          medicalAddress: "医疗机构或药房地址？",
          zipErrmsg: "請輸入有效的郵政編碼",
          signSubmitLabel: "签名并提交",
          signSubmitHeader: "签名并提交",
          signSubmitSub: "您已填写完全部表格！现在更简单。",
          subSectionLabel: "提交",
          submitLabelFinal: "您的申请已提交！",
          tellusAbout: "请告诉我们您的子女抚养费",
          moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
          submitSummaryHeader: "您的《重新认证》已提交",
          submitSummaryAESAPHeader: "您的 SNAP AESAP 重新认证申请已成功提交！",

          heading: name + "的收入和补助",
          mainQuestion: name + " 拥有哪种收入或补助？",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: " 工资 总收入 是多少",
                [Props.LABEL_OBJ_AFTER]: " ?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "个体经营 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: " 工作研究 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "  SSI（补充保障收入） 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]:
                  "RSDI（退休，幸存者和残疾保险） 是多少 ?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "失业 是多少 ?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "子女抚养费 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "  养老 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "工伤赔偿其 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: "租金收入 是多少  ?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: name + "在租赁收入资产上的工作时数?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: " 工伤赔偿其 是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: name + " 的",
                [Props.LABEL_OBJ_TRIGGER]: "总收入",
                [Props.LABEL_OBJ_AFTER]: " 其他  是多少?",
                [Props.LABEL_OBJ_HELP]:
                  "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
              },
            },
          ],
          summaryLabel: "Summary"
        };
      case VI:
        return {
          incomeTitle: "Tình trạng không phải là công dân",
          incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
          aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
          aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không?",
          incomeHelp:
            "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
          clearOption: "Không có",
          combineIncomeoptions: [
            "Lương",
            "Tự làm chủ",
            "Nghiên cứu việc làm",
            "SSI (Thu Nhập An Sinh Bổ Sung)",
            "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
            "Thất nghiệp",
            "Trợ cấp nuôi con",
            "Lương hưu",
            "Trợ cấp cho cựu chiến binh",
            "Thu nhập cho thuê",
            "Bồi thường lao động",
            "Khác",
          ],
          wageIncomeBeforeLabel: "Cái gì là ",
          wageIncomeTriggerLabel: "Tổng thu nhập",
          wagesTargetValue: "Lương",
          selfemeploymentTargetValue: "Tự làm chủ",
          workStudyTargetValue: "Nghiên cứu việc làm",
          SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
          rsdiTargetValue:
            "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
          unEmploymentTargetValue: "Thất nghiệp",
          childSuppTargetValue: "Trợ cấp nuôi con",
          pensionTargetValue: "Lương hưu",
          veteranBenefitsTargetValue: "Trợ cấp cho cựu chiến binh",
          rentalIncomeTargetValue: "Thu nhập cho thuê",
          workerCompTargetValue: "Bồi thường lao động",
          otherTargetValue: "Khác",
          noneTargetValue: "Không có",
          mortageInlineHelpText:
            "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
          grossIncomeHelp:
            "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
          unitsPerLabel: " ",
          workerCompensationLabel: "thêm bồi thường của công nhân khác",
          unitsOptions: ["Tháng", "Mỗi hai tuần", "Tuần"],
          labelOtherwage: "Thêm một mức lương khác",
          adsSelfEmployee: "Thêm một việc tự làm",
          condWorkStudy: "Nghiên cứu việc làm",
          workStudyLabel: "Thêm nghiên cứu việc làm khác",
          anotherSSILabel: "Thêm SSI khác",
          addRDILabel: "Thêm một RSDI khác",
          unEmpLabel: "Thêm một Thất nghiệp",
          childSuppLabel: "Trợ cấp nuôi con",
          anotherChildsupport: "Thêm một khoản Hỗ trợ Nuôi con khác",
          pensionLabel: "Lương hưu",
          anotherPensionLabel: "Thêm tiền trợ cấp khác",
          veteranBenefit: "Trợ cấp cho cựu chiến binh",
          addAnotherVBenefitLabel: "Thêm một lợi ích của cựu chiến binh",
          rentIncomeLabel: "Thu nhập cho thuê",
          repeatQuestionLabel: "Thêm Thu nhập Cho thuê khác",
          repeatincomeQuestionLabel: "Thêm Thu nhập Cho thuê khác",
          unitsRentalOptions: ["Tháng", "Tuần"],
          questionButtonLabel: "Thêm một Khác",
          incomeSummaryTitle: "Tóm tắt Thu nhập",
          incomeSummaryHeader: "Tóm tắt Thu nhập",
          expenseTitle: "Các chi phí",
          almostLabel: "Gần xong rồi!",
          houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
          housingLabel: "Chi phí nhà ở",
          utilityLabel: "Chi phí tiện ích gì",
          utilityHeader: "Hãy cho chúng tôi biết về chi phí tiện ích của bạn",
          expenseHeader: "Hãy cho chúng tôi về chi phí nơi ở của quý vị",
          expenseSubHeader:
            "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
          houseHoldQLabel: "Is your household responsible for any utilities?",
          utilityQOptions: [
            "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
            "Điện cho máy điều hòa không khí",
            "Một khoản phí để sử dụng điều hòa không khí",
            "Điện và / hoặc khí đốt",
            "Dịch vụ điện thoại hoặc điện thoại di động",
          ],
          utilitiesClearOption: "Không có",
          utilitiesDoNotPayOption: "Tôi không trả bất kỳ chi phí tiện ích nào",
          shelterDoNotPayOption: "Tôi không trả cho bất kỳ chi phí nhà cửa nào",
          houseQLabelBefore: "(Những) ",
          houseQLabelTrigger: "chi phí nhà cửa mà",
          houseQLabelAfter: " gia đình của quý vị có?",
          houseQLabelHelp:
            "Ví dụ về chi phí chỗ ở bao gồm tiền thuê nhà, thế chấp, thuế bất động sản, phí chung cư và/hoặc bảo hiểm nhà.",
          utilityQLabelBefore: "(Những) ",
          utilityQLabelTrigger: "chi phí tiện ích gì",
          utilityQLabelAfter: " gia đình của quý vị có?",
          utilityQLabelHelp:
            " Chi phí điện nước gồm có sưởi, điện và/hoặc dịch vụ điện thoại.",
          houseQOptions: [
            "Thuê",
            "Thế chấp",
            "Thuế bất động sản",
            "Bảo hiểm nhà ở",
            "Chi phí công quản",
          ],
          houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "theo",
          houseUnitOptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          mortageQLabel: "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
          mortageunitBetween: " ",
          mortageQoptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          mortageProptaxLabel:
            "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          insuranceCostQLabel:
            "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
          condofeeQLabel:
            "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
          condofeepropBetween: "theo",
          condofeeCostoptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
          propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
          propTaxBetween: "theo",
          propTaxOptions: ["Tuần", "Tháng", "Năm"],
          propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
          homeInsuranceQLabel:
            "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
          homeInsuranceBetween: "theo",
          homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
          homeInsuranceClearOption:
            "Không, tôi không cần trả tiền cho việc này.",
          condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["Tuần", "Tháng", "Năm"],
          condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

          //new labels:
          anyUtilQcost:
            "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
          chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
          houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
          rentErrMsg: "Hãy nhập tiền thuê",
          anotherRentLabel: "+ Thêm tiền thuê khác",
          mortageErrLabel: "Hãy nhập khoản thế chấp",
          propTaxLabel: "Vui lòng nhập thuế bất động sản",
          propTaxButtonLabel: "+ Thêm tiền thuê khác",
          homeInsurbutton: "+ Thêm một bảo hiểm khác",
          homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
          condoButtonLabel: "+ Thêm một khoản phí chung",
          condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
          addMortageButtonLabel: "+ Thêm thế chấp khác",
          childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
          childCostHeader:
            "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
          childCostSubHeader:
            "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
          careGiveObjbefore1: "Có ai trong gia đình có , ",
          careGiveObjtrigger1: "Chi phí chăm sóc người phụ thuộc",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "Những chi phí này bao gồm chi phí cho ",
          careGiveObjtrigger2: "chuyên chở",
          careGiveObjAfter2:
            " việc đưa đón người phụ thuộc đến nhà cung cấp dịch vụ chăm sóc.",
          careGiveHelp1:
            "Chi phí chăm sóc người phụ thuộc bao gồm tất cả những khoản tiền quý vị trả cho một người nào đó để trông coi và/hoặc chuyên chở trẻ em hay người lớn cần được chăm sóc.",
          careGiveHelp2:
            "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v.",
          questionClearOption: "Không có",
          kidsandadultSubheader:
            "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
          costBetween: " ",
          weekMonthOptions: ["Tuần", "Tháng"],
          costOptions: ["Tuần", "Tháng", "Năm"],
          yesnoOptions: ["Có", "Không"],
          targetValueYes: "Có",
          noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
          addressProvider: "Địa chỉ của nhà cung cấp là gì?",
          freqTripoptions: [
            "hàng năm",
            "Hai tháng một lần (hai tháng một lần)",
            "Hai tuần một lần",
            "Hàng tháng",
            "Hàng quý",
            "Nửa năm (Hai lần một năm)",
            "nửa tháng (hai lần một tháng)",
            "Hàng tuần",
          ],
          unitMonthTargetValue: { unit: "Tháng" },
          freqMonthoption: ["Hàng tháng"],
          tripCountErrMsgLen:
            "Vui lòng nhập vào bằng hoặc ít hơn 6 ký tự cho chuyến đi",
          tripCostErrMsgLen:
            "Vui lòng nhập ít hơn/bằng 6 ký tự cho chi phí chuyến đi",
          childSupTitle: "Chi phí Trợ cấp nuôi Con",
          supportCostHeader: "Chi phí Trợ cấp nuôi Con",
          childSupportQlegalbefore:
            "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
          childSupportQlegaltrigger: "trợ cấp nuôi con",
          childSupportQlegalAfter:
            " cho một đứa trẻ không sống chung với quý vị?",
          childSupportHelp:
            "Khoản tiền trợ cấp nuôi con được thanh toán có thể được tính là một khoản chi phí khi khoản này được bắt buộc một cách hợp pháp và các khoản thanh toán đang được thực hiện. Khoản này bao gồm các khoản thanh toán cho các khoản nợ",
          medicalTitle: "Chi phí Y tế",
          medicalInlineSeethisFull: "Xem danh sách đầy đủ các",
          medicalInlineEligibleMedical: " chi phí y tế đủ điều kiện.",
          medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
          medicalSubHeader:
            "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
          medicalLabel1:
            "Nếu không có thành viên nào trong gia đình của quý vị bị khuyết tật hoặc ở độ tuổi ít nhất là 60, xin vui lòng bỏ qua phần này.",
          medicalExpBefore:
            "Có thành viên nào được liệt kê dưới đây trả tiền bảo hiểm y tế hoặc các chi phí y tế khác bao gồm đơn thuốc,",
          medicalExpTrigger: " vận chuyển",
          medicalExpAfter:
            ", thuốc không cần kê đơn, chăm sóc răng hoặc mắt, tã người lớn…..?",
          medicalExphelp:
            "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
          medicalInhelp: "Xem danh sách đầy đủ các",
          medicalInhelpEligible: " chi phí y tế hợp lệ",
          medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
          zipErrmsg: "Please enter a valid Zipcode.",
          signSubmitLabel: "Ký tên & Gửi",
          signSubmitHeader: "Ký tên & Gửi",
          signSubmitSub:
            "Quý vị đã làm xong mẫu đơn! Bây giờ đến những việc dễ dàng.",
          subSectionLabel: "Đã gửi",
          submitLabelFinal: "Đơn của quý vị đã được gửi!",
          tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
          moneyYougettitle:
            "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
          submitSummaryHeader: "Mẫu Chứng nhận lại của quý vị đã được gửi",

          heading: name + "Thu nhập và trợ cấp của",
          mainQuestion: "Loại thu nhập hoặc trợ cấp nào mà " + name + " có?",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Tự làm chủ",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: "của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]:
                  "Tổng thu nhập từ Nghiên cứu việc làm",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]:
                  "Tổng thu nhập từ  SSI (Thu Nhập An Sinh Bổ Sung)",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]:
                  "Tổng thu nhập từ RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)?",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thất nghiệp",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  Trợ cấp nuôi con",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương hưu",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]:
                  "Tổng thu nhập từ  Trợ cấp cho cựu chiến binh",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thu nhập cho thu ",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
            {
              [Props.LABEL]:
                name +
                "  làm việc bao nhiêu giờ trên tài sản thu nhập cho thuê?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  ",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]:
                  "Tổng thu nhập từ  Khôngcó thu nhập và trợ cấp",
                [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
                [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
                [Props.LABEL_OBJ_HELP]:
                  "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
              },
            },
          ],
          summaryLabel: "Summary"
        };
      case HI:
        return {
          incomeTitle: "Estati Non-sitwayen Ameriken",
          incomeHeader: "Konsènan revni fwaye w la",
          aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
          aboutIncomeTrigger: "revni oswa benefis",
          incomeHelp:
            "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
          clearOption: "Okenn",
          combineIncomeoptions: [
            "Salè",
            "Travay Endepandan",
            "Travay Etidyan",
            "SSI (Revni Sekirite Siplemantè)",
            "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
            "Chomaj",
            "Sipò Timoun",
            "Pansyon",
            "Benefis pou Veteran",
            "Revni Lwaye",
            "Konpansasyon Travayè",
            "Lòt",
          ],
          wageIncomeBeforeLabel: "Ki ",
          wageIncomeTriggerLabel: "montan",
          wagesTargetValue: "Salè",
          selfemeploymentTargetValue: "Travay Endepandan",
          workStudyTargetValue: "Travay Etidyan",
          rsdiTargetValue: "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
          SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
          unEmploymentTargetValue: "Chomaj",
          childSuppTargetValue: "Sipò Timoun",
          pensionTargetValue: "Pansyon",
          veteranBenefitsTargetValue: "Benefis pou Veteran",
          rentalIncomeTargetValue: "Revni Lwaye",
          workerCompTargetValue: "Konpansasyon Travayè",
          otherTargetValue: "Lòt",
          noneTargetValue: "Okenn",

          mortageInlineHelpText:
            "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
          grossIncomeHelp:
            "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
          unitsPerLabel: " ",
          unitsOptions: ["Mwa", "Chak de semèn)", "Semèn"],
          labelOtherwage: "Ajoute yon lòt salè",
          adsSelfEmployee: "Ajoute yon lòt travay endepandan",
          condWorkStudy: "Travay Etidyan",
          workStudyLabel: "Ajoute yon lòt travay etidyan",
          anotherSSILabel: "Ajoute yon lòt SSI",
          addRDILabel: "Ajoute yon lòt RSDI",
          unEmpLabel: "Ajoute yon lòt Chomaj",
          childSuppLabel: "Sipò Timoun",
          anotherChildsupport: "Ajoute yon lòt Sipò Timoun",
          pensionLabel: "Pansyon",
          anotherPensionLabel: "Ajoute yon lòt Pansyon",
          workerCompensationLabel: " Ajoute yon lòt Konpansasyon Travayè",
          veteranBenefit: "Benefis pou Veteran",
          addAnotherVBenefitLabel: "Ajoute yon lòt benefis pou veteran",
          rentIncomeLabel: "Revni Lwaye",
          repeatQuestionLabel: "Ajoute yon lòt revni lwaye",
          repeatincomeQuestionLabel: "Ajoute èdtan pou yon lòt lwaye",
          unitsRentalOptions: ["Mwa", "Semèn"],
          questionButtonLabel: "Ajoute yon lòt Lòt",
          incomeSummaryTitle: "Rezime revni",
          incomeSummaryHeader: "Rezime revni",
          expenseTitle: "Depans",
          almostLabel: "Prèske fini!",
          housingLabel: "Depans pou lojman",
          utilityLabel: "Depans pou sèvis piblik",
          utilityHeader: "Pale nou konsènan depans ou yo pou sèvis piblik",
          expenseHeader: "Pale nou konsènan depans ou yo pou abri",
          expenseSubHeader:
            "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
          houseHoldQLabel: "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
          utilityQOptions: [
            "Chofaj luil, gaz, elektrisite oswa pwopaàn, elatriye.)",
            "Elektrisite pou yon klimatizè",
            "Yon frè pou itilize yon klimatizè",
            "Elektrisite ak/oswa gaz",
            "Telefòn oswa sèvis telefòn selilè",
          ],
          utilitiesClearOption: "Okenn",
          utilitiesDoNotPayOption: "Mwen pa peye okenn frè pou sèvis piblik",
          shelterDoNotPayOption: "Mwen pa peye depans pou abri",
          houseQLabelBefore: "Ki tip(yo) ",
          houseQLabelTrigger: "depans pou lojman",
          houseQLabelAfter: " fwaye w la genyen?",
          houseQLabelHelp:
            "Egzanp depans pou lojman yo enkli lwaye, prè ipotèk, taks sou pwopriyete, frè kondo, ak/oswa asirans kay.",
          utilityQLabelBefore: "Ki tip(yo) ",
          utilityQLabelTrigger: "depans pou sèvis piblik",
          utilityQLabelAfter: " fwaye w la genyen?",
          utilityQLabelHelp:
            "Depans pou sèvis piblik yo enkli chofaj, elektrisite, ak/oswa sèvis telefòn.",
          houseQOptions: [
            "Lwaye",
            "Prè ipotèk",
            "Taks sou pwopriyete",
            "Asirans kay",
            "Frè kondo",
          ],
          houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
          rentTargetValue: "RENT",
          mortageTargetValue: "MTGPRN",
          propertyTaxTargetValue: "PRPTAX",
          homeInsurTargetValue: "HOMEIN",
          condoFeeTargetValue: "CONFEE",
          unitBetween: "pa",
          houseUnitOptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
          mortageunitBetween: " ",
          mortageQoptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
          mortagepropBetween: " ",
          mortagepropoptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
          insuranceCostpropBetween: " ",
          insuranceCostoptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
          condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
          condofeepropBetween: "pa",
          condofeeCostoptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
          propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
          propTaxBetween: "pa",
          propTaxOptions: ["Semèn", "Mwa", "Ane"],
          propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
          homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
          homeInsuranceBetween: "pa",
          homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
          homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
          condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
          condoFeeBeweenLabel: " ",
          condoFeeUnits: ["Semèn", "Mwa", "Ane"],
          condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

          //new labels:
          anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
          chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
          houseCostErrmsg: "Tanpri chwazi depans pou lojman",
          rentErrMsg: "Tanpri antre lwaye",
          anotherRentLabel: "+ Ajoute yon lòt lwaye",
          mortageErrLabel: "Tanpri antre prè ipotèk",
          propTaxLabel: "Tanpri antre taks sou pwopriyete",
          propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
          homeInsurbutton: "+ Ajoute yon lòt asirans kay",
          homeInsurErrmsg: "Tanpri antre asirans kay",
          condoButtonLabel: "+ Ajoute yon lòt frè kondo",
          condoFeeErrmsg: "Tanpri antre frè kondo",
          addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
          childTitle: "Depans pou Swen Timoun oswa Adilt",
          childCostHeader:
            "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
          childCostSubHeader:
            "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo.  ",
          careGiveObjbefore1:
            "Èske nenpòt moun nan fwaye a gen timoun oswa granmoun ",
          careGiveObjtrigger1: "depans pou swen depandan",
          careGiveObjAfter1: "? ",
          careGiveObjbefore2: "Sa enkli depans pou ",
          careGiveObjtrigger2: "transpòte",
          careGiveObjAfter2:
            " depandan(yo) pou ale ak/oswa soti lakay yon founisè swen.",
          careGiveHelp1:
            "Depans pou swen depandan enkli nenpòt peman ou fè pou yon moun gade ak/oswa transpòte yon timoun oswa adilt ki bezwen swen.",
          careGiveHelp2:
            "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
          questionClearOption: "Okenn",
          kidsandadultSubheader:
            "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
          costBetween: " ",
          weekMonthOptions: ["Mwa", "Semèn"],
          costOptions: ["Semèn", "Mwa", "Ane"],
          yesnoOptions: ["Wi", "Non"],
          targetValueYes: "Wi",
          noofAppointment: "Please choose no.of appointments",
          addressProvider: "Ki adrès founisè a?",
          freqMonthoption: ["Mansyèl"],
          freqTripoptions: [
            "Anyèl",
            "Bimansyèl (chak de mwa)",
            "Biebdomadè (chak de semèn)",
            "Mansyèl",
            "Chak trimès",
            "Semianyèl (de fwa pa ane)",
            "Semimansyèl (de fwa pa mwa)",
            "Chak semèn",
          ],
          unitMonthTargetValue: { unit: "Mansyèl" },
          tripCountErrMsgLen:
            "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
          tripCostErrMsgLen:
            "Tanpri antre mwens pase/egal a 6 karaktè pou frè vwayaj la",
          childSupTitle: "Depans pou Sipò Timoun",
          supportCostHeader: "Depans pou Sipò Timoun",
          childSupportQlegalbefore:
            "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
          childSupportQlegaltrigger: "sipò timoun",
          childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
          childSupportHelp:
            "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
          medicalTitle: "Depans medikal",
          medicalInlineSeethisFull: "Gade lis konplè sa a",
          medicalInlineEligibleMedical: " depans medikal ki kalifye",
          medicalHeader: "Pale nou konsènan depans medikal ou yo",
          medicalSubHeader:
            "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo.  ",
          medicalLabel1:
            "Si Moun nan fwaye w la andikape oswa gen omwen 60 an, tanpri sote seksyon sa a.",
          medicalExpBefore:
            "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
          medicalExpTrigger: " transpò",
          medicalExpAfter:
            ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
          medicalExphelp:
            "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
          medicalInhelp: "Gade lis konplè sa a",
          medicalInhelpEligible: " depans medikal ki kalifye",
          medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
          zipErrmsg: "Tanpri antre yon kòd postal ki valid",
          signSubmitLabel: "Siyen epi soumèt",
          signSubmitHeader: "Siyen epi soumèt",
          signSubmitSub: "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
          subSectionLabel: "Soumèt",
          submitLabelFinal: "Aplikasyon w lan te soumèt!",
          tellusAbout: "Pale nou konsènan Depans pou sipò timoun ou yo",
          moneyYougettitle:
            "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
          submitSummaryHeader: "Resètifikasyon w lan te soumèt",
          submitSummaryAESAPHeader: "Yo te soumèt aplikasyon pou resètifikasyon SNAP AESAP ou avèk siksè!",

          heading: name + " revni ak benefis",
          mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
          subQuestions: [
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " Salè?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " Travay-Endepandan?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " Travay Etidyan?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + "  SSI (Revni Sekirite Siplemantè)?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " +
                  name +
                  " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " Chomaj?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " Sipò Timoun?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " Pansyon?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " benefis pou veteran?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " revni Lwaye?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]:
                "Konbyen èdtan " +
                name +
                " travay sou pwopriyete ak revni lwaye a?",
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " Konpansasyon Travayè?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
            {
              [Props.LABEL]: {
                [Props.LABEL_OBJ_BEFORE]: "Ki ",
                [Props.LABEL_OBJ_TRIGGER]: "montan",
                [Props.LABEL_OBJ_AFTER]:
                  " revni brit " + name + " lòt revni oswa benefis?",
                [Props.LABEL_OBJ_HELP]:
                  "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
              },
            },
          ],
          summaryLabel: "Summary"
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.signAndSubmitSummaryRecert) {
    //9th section page
    switch (language) {
      case EN:
        return {
          tanfSubmithead: "What’s Next?",
          tanfSubmitListing: [
            "Upload documents", 
            "Track your case status",
            "See documents we send you",
            "Check monthly benefit amount", 
            "And more"
          ], 
          tanfSubmitpara: [
            "You may want to ",
            "print",
            " or ",
            "save",
            " the form you have just submitted for future reference.",
          ],
          tanfSubmitparatwo: [
            "Here is a ",
            "list of the most common documents ",
            "we might need.",
            "You can",
            "submit your documents.",
            "now through your online account. However, we will request at a later date what is needed to determine if you are eligible for TANF",
          ],
          tanfListHead: "With your online account you can: ",
          tanfFinalPara: [
            "There are other ways to submit documents to us.",
            "Find out more."
          ],
          rightsText: "Rights, Responsibilities and Penalties",
          referenceNumberText: "Your SNAP AESAP Web Application Number: ",
          downloadBeforeText: "You may want to",
          downloadAfterText: "for future reference.",
          downloadLinkText: "download your completed SNAP Recertification application",
          downloadAESAPLinkText: "download your completed SNAP AESAP Recertification application",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          earnedIncomeBold: "If anyone in the household has earned income, ",
          earnedIncomeNormal:
            "send us proof of income for the last four weeks, such as:",
          earnedIncomeSteps: [
            "Pay stubs, or record of payment",
            "Proof of any pay you got and hours worked",
            "Completed tax forms will all schedules attached, if self-employed",
          ],
          unearnedIncomeBold:
            "If anyone in the household has unearned income, ",
          unearnedIncomeNormal: "send us proof of the monthly amount, such as:",
          unearnedIncomeSteps: [
            "Benefit or award letter",
            "Statement from agency making payments",
          ],
          uneanrednoteText: "NOTE",
          uneanrednoteDetailText:
            "We can usually verify the amount of Social Security, SSI, DOR Child Support, or MA Unemployment Compensation benefits ourselves.  We will tell you if you need to verify any of these items.",
          leftJobBold:
            "If anyone in the household left a job since your last certification, ",
          // uneanrednoteSubDetailText: "If child support expenses has been changed display the following",
          leftJobNormal: "send us proof of the separation date, such as:",
          leftJobSteps: [
            "Layoff notice or letter from employer",
            "Unemployment claim or unemployment benefit information",
            "Other document showing when and why your job stopped",
          ],
          leftJobNoteDetailText: "Tell us the reason for leaving your job",
          householdAddressMovedBold: "If your household address has moved ",
          householdAddressMovedNormal:
            "and you did not report your new housing costs in the online Recertification form",
          householdAddressMovedSteps: [
            "Rent receipt, lease or land lord verification form",
            "Deed or mortgage statement.",
            "Shared housing verification form, or statement from someone you live with.",
          ],
          householdMoveNormal:
            "If your household has moved. You can report your new housing costs by submitting one of the following items:",
          householdMoveSteps: [
            "Rent receipt, lease, or Landlord Verification form",
            "Deed or mortgage statement",
            "Shared Housing Verification form, or statement from someone you live with",
          ],
          nonCitizenBoldText:
            "If a household member’s noncitizen status has changed ",
          nonCitizenNormalText: "please submit proof of the status, such as:",
          nonCitizenSteps: [
            "Permanent Resident Card (“green card”)",
            "Employment Authorization Card",
            "Temporary Resident Card",
            "Arrival‐Departure Record (I‐94)",
            "Stamp in passport",
            "Other document showing current or pending immigration status",
            "Statement from an immigration attorney about current or pending status",
          ],
          expenseTextBold:
            "If the total medical expenses have changed by at least $25, ",
          expenseTextNormal:
            "we will request proof of expenses (such as bills, invoices, or receipts).",
          expenseNoteDetailText:
            "You can be credited for the costs you are responsible for paying even if you are behind or not able to pay them. Medical costs include co‐pays, prescriptions ,over‐the‐counter medications, health insurance, medical bills, transportation, and more. Transportation costs for medical reasons can be self-declared.",
          expenseText:
            "If anyone in your house is senior (at least age 60) or has a disability, and the medical expenses have changed by at least $25, please send us proof of expenses (such as bills, invoices, or receipts).",
          childBoldText:
            "If anyone in the household is making payments for child support, ",
          childNormalText: "please send us:",
          childSteps:
            "Verification of the legal obligation to pay the child support (such as a court order) and proof of recent Payments.",
          childNoteDetailText:
            "Child support payments cannot be credited unless they are legally obligated and being paid.",
          voterTitle: "Voter Registration",
          voterTextBefore:
            "The National Voter Registration Act of 1993 requires DHR to give you the opportunity to register to vote. DHR mailed you a Voter Registration application with your SNAP Recertification form. Let DHR know if you need help to register. You may also register to vote online by clicking ",
          voterTextLink: "here.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency.  If you are not a U.S. citizen, you are not eligible to vote, and you should not register to vote.",
          sendDocumentBtnLabel: "Send a document to Alabama DHR",
          dontSendDocumentBtnLabel: "Or exit without uploading any documents",
          otherDocWays: "There are many other ways to submit documents to us. ",
          findMoreText: "Find out more",
          rightsHHRemovedText:
            "Important: Clicking submit does not start the Recertification process. DHR must speak with you to confirm changes.",
        };
      case ES:
        return {
          tanfSubmithead: "¿Que sigue?",
          tanfSubmitListing: [
            "Subir documentos", 
            "Seguimiento del estado de su caso",
            "Ver documentos que te enviamos",
            "Verifique el monto del beneficio mensual", 
            "Y más"
          ],
          tanfSubmitpara: [
            "Es posible que desee ",
            "imprimir", 
            " o ", 
            "guardar",
            " el formulario que acaba de enviar para referencia futura.",
          ],
          tanfSubmitparatwo: [
            "Aquí hay un ",
            "lista de los documentos más comunes ",
            "Nosotros podríamos necesitar",
            "Puede",
            "presenta tus documentos.",
            "ahora a través de su cuenta en línea. Sin embargo, solicitaremos en una fecha posterior lo que se necesita para determinar si usted es elegible para TANF.",
          ],
          tanfListHead: "Con tu cuenta en línea podrás: ",
          tanfFinalPara: [
            "Hay otras formas de enviarnos documentos.",
            "Saber más."
          ],
          referenceNumberText: "Su número de referencia:",
          downloadBeforeText: "Le recomendamos que",
          downloadAfterText: "para futuras consultas",
          rightsText: "Derechos, Responsabilidades y Penalidades",
          downloadLinkText: "descargue su solicitud de recertificación SNAP completa",
          downloadAESAPLinkText: "descargue su solicitud de recertificación SNAP AESAP completa",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          earnedIncomeBold:
            "Si alguien en el grupo familiar tiene ingresos provenientes del trabajo, ",
          earnedIncomeNormal:
            "envíenos un comprobante de ingresos de las últimas cuatro semanas",
          earnedIncomeSteps: [
            "Recibos de pago o registro de pago",
            "Comprobante de cualquier pago que haya recibido y las horas trabajadas",
            "Formularios tributarios completos con todas las escalas adjuntas, si es trabajador/a independiente",
          ],
          unearnedIncomeBold:
            "Si alguien en el grupo familiar tiene ingresos no provenientes del trabajo, ",
          unearnedIncomeNormal:
            "envíenos un comprobante del monto mensual, como:",
          unearnedIncomeSteps: [
            "Carta de concesión o beneficio",
            "Declaración de la agencia que realiza los pagos",
          ],
          uneanrednoteText: "Aclaración",
          uneanrednoteDetailText:
            "Generalmente podremos verificar nosotros mismos los montos de los beneficios del Seguro social, SSI, manutención infantil DOR o Compensación por desempleo de MA. Le informaremos si necesita verificar alguno de estos puntos.",
          leftJobBold:
            "Si algún miembro de su grupo familiar dejó de trabajar desde su última certificación, ",
          leftJobNormal:
            "envíenos un comprobante de la fecha de desvinculación, como:",
          leftJobSteps: [
            "Aviso de despido o carta del empleador",
            "Reclamación por desempleo o información sobre beneficios de desempleo",
            "Otros documentos que muestren cuándo y por qué dejó de trabajar. ",
          ],
          leftJobNoteDetailText:
            "Indique el motivo por el que dejó de trabajar.",
          householdAddressMovedBold: "Si la dirección de su hogar ha cambiado ",
          expenseText:
            "Si hay personas mayores en su hogar (60 años o más) o alguien es discapacitado, y los gastos médicos se modificaron en al menos $25, envíenos un comprobante de los gastos (como facturas o recibos).",
          householdAddressMovedNormal:
            "y usted no reportó sus nuevos costos de vivienda en el formulario de Recertificación",
          householdAddressMovedSteps: [
            "Formulário de recibo de aluguel",
            "locação ou comprovante de propriedade, Escritura o constancia de hipoteca",
            "Formulario de Verificación de vivienda compartida o declaración de alguna persona que viva con usted.",
          ],
          householdMoveNormal:
            "If your household has moved. You can report your new housing costs by submitting one of the following items:",
          householdMoveSteps: [
            "Rent receipt, lease, or Landlord Verification form",
            "Deed or mortgage statement",
            "Shared Housing Verification form, or statement from someone you live with",
          ],
          nonCitizenBoldText:
            "Si ha cambiado el estado de no ciudadano de un miembro del grupo familiar, ",
          nonCitizenNormalText: "presente un comprobante del estado, como:",
          nonCitizenSteps: [
            "Tarjeta de residente permanente ('tarjeta verde')",
            "Tarjeta de autorización de empleo",
            "Tarjeta de residente temporal",
            "Registro de llegada-salida (I-94)",
            "Sello en el pasaporte",
            "Otro documento que muestre el estado migratorio actual o pendiente",
            "Declaración de un abogado de migraciones sobre el estado actual o pendiente",
          ],
          expenseTextBold:
            "Si el total de gastos médicos cambió en al menos $25, ",
          expenseTextNormal:
            "solicitaremos comprobante de los gastos (como facturas o recibos)",
          expenseNoteDetailText:
            "Se le pueden abonar los costos que deba pagar, aunque esté atrasado/a o no pueda pagarlos.  Los costos médicos incluyen copagos, medicamentos de venta con receta, medicamentos de venta libre, seguro de salud, facturas médicas, transporte, etc.  Los costos de transporte por motivos médicos se pueden autodeclarar.",
          childBoldText:
            "Si algún miembro de su grupo familiar realiza pagos de manutención infantil, ",
          childNormalText: "envíenos lo siguiente:",
          rightsHHRemovedText:
            "Importante: Hacer clic en Presentar no inicia el proceso de Recertificación. DHR debe hablar con usted para confirmar los cambios.",
          childSteps:
            " Verificación de la obligación legal de pagar manutención infantil (como una orden judicial) y comprobantes de los pagos recientes.",
          childNoteDetailText:
            "Los pagos de manutención infantil no se pueden acreditar a menos que sean una obligación legal y se estén realizando.",
          voterTitle: "Registro de votantes",
          voterTextBefore:
            "La Ley Nacional de Registro de Votantes de 1993 exige que DHR le brinde la oportunidad de registrarse para votar. DHR le envió por correo una solicitud de Registro como votante con su formulario de Recertificación para el SNAP. Avise a DHR si necesita ayuda para registrarse. También puede registrarse para votar en línea haciendo clic ",
          voterTextLink: "aquí.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " La solicitud de registro o el rechazo del registro como votante no afectará el monto de la asistencia que esta agencia le proporcionará.  Si no es ciudadano/a estadounidense, no es elegible para votar y no debe registrarse como votante",
          sendDocumentBtnLabel: "Enviar documento a DHR",
          dontSendDocumentBtnLabel: "O salir sin subir documentos.",
          otherDocWays:
            "Hay muchas otras maneras de enviarnos los documentos. ",
          findMoreText: "Obtenga más información.",
        };
      case PT:
        return {
          referenceNumberText: "Seu número de referência: ",
          downloadBeforeText: "Você pode querer",
          downloadAfterText: "para referência futura",
          rightsText: "Direitos, Responsabilidades e Penalidades",
          downloadLinkText: "baixe seu aplicativo de recertificação SNAP preenchido",
          downloadAESAPLinkText: "baixe seu aplicativo de recertificação SNAP AESAP preenchido",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          expenseText:
            "Se alguém em sua casa é idoso  (pelo menos 60 anos) ou tem uma deficiência, e as despesas médicas mudaram em pelo menos US =$25, envie-nos comprovantes de despesas (como contas, faturas ou recibos).",
          earnedIncomeBold:
            "Se alguém no seu domicílio tem rendimento proveniente de trabalho, ",
          earnedIncomeNormal:
            "envie-nos um comprovante de renda das últimas quatro semanas",
          earnedIncomeSteps: [
            "Comprovantes ou registro de pagamento",
            "Comprovante de qualquer pagamento que você recebeu e horas trabalhadas",
            "Formulários de impostos preenchidos com todos os horários anexados, se autônomo",
          ],
          unearnedIncomeBold:
            "Se alguém do domicílio tiver rendimento proveniente de trabalho, ",
          unearnedIncomeNormal:
            "envie-nos um comprovante do valor mensal, como:",
          unearnedIncomeSteps: [
            "Carta de benefícios ou gratificações",
            "Declaração da agência que faz pagamentos",
          ],
          uneanrednoteText: "Nota:",
          rightsHHRemovedText:
            "Importante: Clicar em enviar não inicia o processo de Recertificação. O DHR deve falar com você para confirmar as alterações.",
          uneanrednoteDetailText:
            "Geralmente, podemos verificar a quantia de benefícios de Seguro Social, SSI, DOR Child Support ou MA Unemployment Compensation nós mesmos. Nós informaremos se você precisar verificar algum desses itens.",
          leftJobBold:
            "Se alguém no domicílio saiu de um emprego desde a sua última certificação, ",
          leftJobNormal: "envie-nos um comprovante da data de separação, como:",
          leftJobSteps: [
            "Aviso de dispensa ou carta do empregador",
            "Reclamação de desemprego ou informações sobre subsídio de desemprego",
            "Outro documento mostrando quando e por que seu vínculo empregatício foi encerrado",
          ],
          leftJobNoteDetailText:
            "Informe o motivo para ter saído do seu emprego",
          householdAddressMovedBold:
            "Caso o seu endereço residencial tenha mudado ",
          householdAddressMovedNormal:
            "e você não informou os seus novos custos habitacionais no formulário on-line de recertificação",
          householdAddressMovedSteps: [
            "Formulário de recibo de aluguel",
            "locação ou comprovante de propriedade, Escritura ou declaração de hipoteca",
            "Formulário de verificação de domicílio compartilhado ou declaração de alguém com quem você mora.",
          ],
          householdMoveNormal:
            "If your household has moved. You can report your new housing costs by submitting one of the following items:",
          householdMoveSteps: [
            "Rent receipt, lease, or Landlord Verification form",
            "Deed or mortgage statement",
            "Shared Housing Verification form, or statement from someone you live with",
          ],
          nonCitizenBoldText:
            "Se o status de não cidadão de um membro do domicílio foi alterado ",
          nonCitizenNormalText: "envie um comprovante do novo status, como:",
          nonCitizenSteps: [
            "Cartão de residência permanente (“green card”)",
            "Cartão de autorização de emprego",
            "Cartão de residência temporária",
            "Registro de Entrada-Saída no País (I-94)",
            "Carimbo no passaporte",
            "Outro documento mostrando o status de imigração atual ou pendente",
            "Declaração de um advogado de imigração sobre o status atual ou pendente",
          ],
          expenseTextBold:
            "Se as despesas médicas totais tiverem mudado em pelo menos $25, ",
          expenseTextNormal:
            "solicitaremos comprovação de despesas (como faturas, notas ou recibos).",
          expenseNoteDetailText:
            "Você pode ser reembolsado pelos custos pelos quais é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.   Os custos médicos incluem co-pagamentos, receitas, medicamentos de venda livre, seguro de saúde, contas médicas, transporte e muito mais.  Os custos de transporte por motivos médicos podem ser declarados automaticamente.",
          childBoldText:
            "Se alguém no domicílio está fazendo pagamentos de pensão alimentícia, ",
          childNormalText: "envie-nos:",
          childSteps:
            "Verificação da obrigação legal de pagar a pensão alimentícia (como uma ordem judicial) e a comprovação de pagamentos recentes.",
          childNoteDetailText:
            " Os pagamentos de pensão alimentícia não podem ser reembolsados a menos que sejam legalmente obrigados e pagos.",
          voterTitle: "Registro de eleitor",
          voterTextBefore:
            "O National Registro de eleitor Act de 1993 exige que o DHR lhe dê a oportunidade de se cadastrar para votar. O DHR lhe enviou um formulário de registro de eleitores com o formulário de recertificação SNAP. Informe DHR se precisar de ajuda para se cadastrar. Você também pode se cadastrar para votar on-line clicando ",
          voterTextLink: "aqui.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " Cadastrar-se ou não para votar não afetará a quantidade de assistência que será fornecida por esta agência.  Se você não for cidadão americano, não poderá votar e não deverá se cadastrar para votar.",
          sendDocumentBtnLabel: "Envie um documento para o DHR",
          dontSendDocumentBtnLabel:
            "Ou saia sem fazer o upload de nenhum documento",
          otherDocWays:
            "Existem muitas outras maneiras de enviar documentos para nós. ",
          findMoreText: " Saiba mais",
        };

      case ZH: //chinees
        return {
          referenceNumberText: "您的参考编号：",
          downloadBeforeText: "您可能想要",
          downloadAfterText: "以供未来参考",
          downloadLinkText: "下载您填写好的中间报告",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          earnedIncomeBold: "如果家庭中任何人有劳动所得收入, ",
          earnedIncomeNormal: "请向我们寄送过去四周的收入证据",
          rightsText: "权利，责任和惩罚",
          earnedIncomeSteps: [
            "工资单或付款记录",
            "您得到的任何付款和工时证据",
            "填写好的纳税申请书（附上所有税率表）（如果您为自雇）",
          ],
          unearnedIncomeBold: "如果家庭中任何人有非劳动所得收入, ",
          unearnedIncomeNormal: "请向我们寄送每月收入证据，例如：",
          unearnedIncomeSteps: ["福利或授予函", "付款机构提供的声明"],
          uneanrednoteText: "注意",
          uneanrednoteDetailText:
            "我们可以经常自己验证社会保障、SSI、DOR 子女抚养费或 MA 失业赔偿福利的金额。如果您需要验证任何此项，我们会告知您相关结果。",
          leftJobBold: "如果在您上次认证之后家庭中任何人离职, ",
          leftJobNormal: "请向我们寄送离职日期的证据，例如：",
          leftJobSteps: [
            "雇主的裁员通知或信函",
            "失业索赔或失业救济信息",
            "表明您的工作结束日期和原因的其他文件",
          ],
          leftJobNoteDetailText: "告知我们您的离职原因",
          householdAddressMovedBold: "如果您的家庭地址搬迁过 ",
          householdAddressMovedNormal:
            "且您并未通过在线填写重新认证表格来报告您的最新住房费用",
          householdAddressMovedSteps: [
            "房租收据、租约或《房东验证》表格",
            "契约或按揭贷款月结单。",
            "《共享住房验证》表格或与您同居的某人提供的声明。",
          ],
          householdMoveNormal:
            "If your household has moved. You can report your new housing costs by submitting one of the following items:",
          householdMoveSteps: [
            "Rent receipt, lease, or Landlord Verification form",
            "Deed or mortgage statement",
            "Shared Housing Verification form, or statement from someone you live with",
          ],
          nonCitizenBoldText: "如果某个家庭成员的非公民状态发生变化 ",
          nonCitizenNormalText: "请提交状态证据，例如：",
          nonCitizenSteps: [
            "永久居留卡（“绿卡”）",
            "工作许可证",
            "临时居留卡",
            "出入境记录 (I‐94)",
            "盖章护照",
            "表明当前或未决移民状态的其他文件",
            "移民律师提供的关于当前或未决状态的声明",
          ],
          expenseTextBold: "如果医疗费用总额变动达到 $25 美元, ",
          expenseTextNormal: "我们会请求得到费用证据（例如账单、发票或收据）",
          expenseNoteDetailText:
            "对于您负责支付的费用，您可被入账，即使您延期支付或无力支付此等费用也会被入账。医疗费用包括共付额、处方、非处方药物、健康保险、医疗账单、交通等等。出于医疗原因的交通费用可自行声明。",
          childBoldText: "如果家庭中任何人支付子女抚养费, ",
          expenseText:
            "如果您家任何人为老年人（至少年满 60 岁）或有残障情况 而医疗费用的变化超过至少 $25 美元, 请向我们寄送费用证据（例如账单、发票或收据）。",
          childNormalText: "请向我们寄送：",
          childSteps:
            "支付子女抚养费之合法义务的验证文件（例如法院命令）以及近期付款的证据。",
          childNoteDetailText:
            "即使子女抚养费按合法义务支付，此等费用的付款也无法入账。",
          voterTitle: "选民登记",
          voterTextBefore:
            "《1993 年全国选民登记法》要求 DHR 让您有机会完成选民登记。DAT 向您邮寄《选民登记》申请表以及您的 SNAP《重新认证》表格。如果您在登记时需要帮助，请告知 DHR。您也可以点击此处在线完成选民登记。",
          voterTextLink: "here.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " 申请选民登记或拒绝选民登记均不会影响此机构向您提供的援助金额。如果您并非美国公民，您不符合选举投票资格，则您不应该进行选民登记。",
          sendDocumentBtnLabel: "向 DHR 寄送文件",
          dontSendDocumentBtnLabel: "或退出而不上传任何资料",
          otherDocWays: "向我们提交文件可通过很多其他方式。",
          findMoreText: "了解更多",
          rightsHHRemovedText:
            "重要提示: 点击提交并未开始《重新认证》流程。DHR 必须与您交谈从而确认更改。",
        };
      case VI:
        return {
          referenceNumberText: "Số tham chiếu của quý vị: ",
          downloadBeforeText: "Bạn có muốn",
          downloadAfterText: "để tham khảo trong tương lai",
          downloadLinkText: "tải về báo cáo tạm thời đã hoàn thành của mình",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          earnedIncomeBold:
            "Nếu bất kỳ thành viên nào trong hộ gia đình có thu nhập đã thu, ",
          earnedIncomeNormal:
            "vui lòng gửi cho chúng tôi bằng chứng về thu nhập trong bốn tuần qua:",
          earnedIncomeSteps: [
            "Bảng kê thu nhập hoặc hồ sơ thanh toán",
            "Bằng chứng về bất kỳ khoản lương nào quý vị nhận được và số giờ làm việc",
            "Các biểu mẫu về thuế đã được hoàn tất với tất cả các lịch đóng thuế đính kèm, nếu quý vị là chủ",
          ],
          unearnedIncomeBold:
            "Nếu bất kỳ thành viên nào trong hộ gia đình đã có thu nhập chưa thu, ",
          unearnedIncomeNormal:
            "vui lòng gửi cho chúng tôi bằng chứng về số tiền hàng tháng:",
          unearnedIncomeSteps: [
            "Thư về lợi ích hoặc giải thưởng",
            "Báo cáo từ các cơ quan thanh toán",
          ],
          uneanrednoteText: "LƯU Ý",
          rightsText: "Quyền, Trách nhiệm & Hình phạt",
          uneanrednoteDetailText:
            "Chúng tôi thường có thể tự xác minh số tiền từ các lợi ích về An sinh Xã hội, Thu Nhập An Sinh Bổ Sung (SSI), Trợ cấp nuôi con theo yêu cầu của Sở Thuế Vụ (DOR) hoặc các lợi ích về trợ cấp thất nghiệp của Massachusets. Chúng tôi sẽ cho quý vị biết nếu quý vị cần xác minh bất kỳ mục nào trong số này.",
          leftJobBold:
            "Nếu bất cứ thành viên nào trong gia đình nghỉ  việc kể từ lần chứng nhận cuối cùng của quý vị, ",
          leftJobNormal:
            "hãy gửi cho chúng tôi bằng chứng về ngày nghỉ việc, chẳng hạn như:",
          leftJobSteps: [
            "Thông báo sa thải hoặc thư từ người sử dụng lao động",
            "Yêu cầu nhận trợ cấp thất nghiệp hoặc thông tin trợ cấp thất nghiệp",
            "Giấy tờ khác cho biết khi nào và tại sao quý vị nghỉ việc",
          ],
          leftJobNoteDetailText:
            "Hãy cho chúng tôi biết lý do quý vị nghỉ việc",
          householdAddressMovedBold: "Nếu gia đình của quý vị đã dời địa chỉ  ",
          householdAddressMovedNormal:
            "và quý vị không báo cáo chi phí nhà ở mới trong đơn Tái xác định trên mạng",
          householdAddressMovedSteps: [
            "Biên lai thuê, hợp đồng thuê",
            "hoặc mẫu Xác minh chủ nhà, Chứng thư hoặc báo cáo về thế chấp",
            "Biểu mẫu Xác minh Nhà ở được chia sẻ hoặc báo cáo từ người quý vị sống cùng",
          ],
          householdMoveNormal:
            "If your household has moved. You can report your new housing costs by submitting one of the following items:",
          householdMoveSteps: [
            "Biên lai thuê, hợp đồng thuê, hoặc mẫu Xác minh chủ nhà, Chứng thư hoặc báo cáo về thế chấp, Biểu mẫu Xác minh Nhà ở được chia sẻ hoặc báo cáo từ người quý vị sống cùng",
          ],
          nonCitizenBoldText:
            "Nếu tình trạng không phải công dân của thành viên trong hộ gia đình đã thay đổi, ",
          nonCitizenNormalText:
            "vui lòng gửi bằng chứng về tình trạng này, ví dụ như:",
          expenseText:
            "Nếu bất cứ ai trong nhà bạn đều là người cao tuổi (ít nhất 60 tuổi) hoặc bị khuyết tật, và chi phí y tế đã thay đổi ít nhất 25 đô la, vui lòng gửi cho chúng tôi bằng chứng về các chi phí (như hóa đơn, biên lai hoặc biên nhận).",
          nonCitizenSteps: [
            "Thẻ thường trú nhân (“Thẻ xanh”)",
            "Giấy phép làm việc",
            "Thẻ tạm trú",
            "Hồ sơ nhập-xuất cảnh (I-94)",
            "Con dấu trên hộ chiếu",
            "Giấy tờ khác cho thấy tình trạng nhập cư hiện tại hoặc đang chờ xử lý",
            "Báo cáo từ luật sư di trú về tình trạng hiện tại hoặc đang chờ xử lý",
          ],
          expenseTextBold:
            "Nếu tổng chi phí y tế đã thay đổi ít nhất $25 Đô la,",
          expenseTextNormal:
            "chúng tôi sẽ yêu cầu bằng chứng về chi phí (như hóa đơn hoặc biên lai).",
          expenseNoteDetailText:
            " Quý vị có thể trả sau cho các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi quý vị đã quá hạn thanh toán hoặc không có khả năng thanh toán. Các chi phí y tế bao gồm các khoản đồng thanh toán, đơn thuốc, thuốc không kê đơn, bảo hiểm y tế, hóa đơn y tế, vận chuyển và các khoản khác. Chi phí vận chuyển vì lý do y tế có thể được tự kê khai.",
          childBoldText:
            "Nếu bất kỳ thành viên nào trong hộ gia đình đang thanh toán khoản trợ cấp nuôi con, ",
          childNormalText: "xin vui lòng gửi cho chúng tôi:",
          childSteps:
            "Xác minh nghĩa vụ pháp lý chi trả khoản trợ cấp nuôi con (ví dụ như lệnh tòa án) và bằng chứng của các khoản thanh toán gần đây.",
          childNoteDetailText:
            "Các khoản trợ cấp nuôi con không được trả sau nếu chúng không bị bắt buộc về mặt pháp lý và đang được chi trả.",
          voterTitle: "Đăng ký cử tri",
          voterTextBefore:
            "The National Đăng ký cử tri Act of 1993 requires DHR to give you the opportunity to register to vote. DHR mailed you a Voter Registration application with your SNAP Recertification form. Let DHR know if you need help to register. You may also register to vote online by clicking ",
          voterTextLink: "here.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency.  If you are not a U.S. citizen, you are not eligible to vote, and you should not register to vote.",
          sendDocumentBtnLabel: "Gửi giấy tờ tới DHR",
          dontSendDocumentBtnLabel:
            "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
          otherDocWays:
            "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi. ",
          findMoreText: "Tìm hiểu thêm",
          rightsHHRemovedText:
            "Nhập khẩu: Việc nhấp vào nút gửi sẽ không khởi động quá trình Chứng  nhận lại. DHR phải nói chuyện với quý vị để xác nhận những thay đổi.",
        };
      case HI:
        return {
          rightsText: "Dwa, Responsablite ak Sanksyon",
          referenceNumberText: "Nimewo referans ou: ",
          downloadBeforeText: "Ou ka vle",
          downloadAfterText: "pou referans alavni.",
          downloadLinkText: "telechaje Resètifikasyon fini w lan",
          //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
          //houseHoldMoveLinkText: "here",
          earnedIncomeBold: "Si nenpòt moun nan fwaye a gen revni, ",
          earnedIncomeNormal:
            "voye ban nou prèv revni pou kat dènye semèn yo, tankou:",
          earnedIncomeSteps: [
            "Souch chèk, oswa dosye peman",
            "Prèv nenpòt salè ou te resevwa ak konbyen lè ou te travay",
            "Fòm taks ranpli ak tout orè yo atache, si ou se travayè endepandan",
          ],
          unearnedIncomeBold:
            "Si nenpòt moun nan fwaye gen revni ki pa fèt nan travay, ",
          unearnedIncomeNormal: "voye ban nou prèv montan mansyèl la, tankou:",
          unearnedIncomeSteps: [
            "Lèt benefis oswa kalifikasyon",
            "Deklarasyon ajans ki fè peman yo",
          ],
          uneanrednoteText: "REMAK::",
          uneanrednoteDetailText:
            "Anjeneral nou ka verifye montan benefis Sekirite Sosyal, SSI, Sipò Timoun DOR, oswa MA Konpansasyon Chomaj. Nou pral di w si w bezwen verifye nenpòt nan bagay sa yo.",
          leftJobBold:
            "Si nenpòt moun nan fwaye a kite yon travay depi dènye sètifikasyon w lan, ",
          // uneanrednoteSubDetailText: "If child support expenses has been changed display the following",
          leftJobNormal: "voye ban nou prèv dat separasyon an, tankou:",
          leftJobSteps: [
            "Avi revokasyon oswa lèt patwon",
            "Enfòmasyon sou demann chomaj oswa benefis chomaj",
            "Lòt dokiman ki montre ki lè ak poukisa travay ou te sispann",
          ],
          leftJobNoteDetailText: "Di nou rezon ki fè ou kite travay ou",
          householdAddressMovedBold: "Si adrès fwaye w la te chanje ",
          householdAddressMovedNormal:
            "epi ou pa rapòte nouvo depans ou yo pou lojman nan fòm Resètifikasyon an liy lan",
          householdAddressMovedSteps: [
            "Resi lwaye, kontra lwaye oswa fòm verifikasyon mèt kay",
            "Tit pwopriete oswa deklarasyon prè ipotèk.",
            "Fòm verifikasyon lojman pataje, oswa deklarasyon yon moun w ap viv ak li.",
          ],
          householdMoveNormal:
            "Si fwaye w la demenaje. Ou ka rapòte nouvo depans ou yo pou lojman nan soumèt youn nan eleman sa yo:",
          householdMoveSteps: [
            "Resi lwaye, kontra lwaye oswa fòm verifikasyon mèt kay",
            "Tit pwopriete oswa deklarasyon prè ipotèk.",
            "Fòm verifikasyon lojman pataje, oswa deklarasyon yon moun w ap viv ak li",
          ],
          nonCitizenBoldText:
            "Si estati yon manm non-sitwayen fwaye a chanje, ",
          nonCitizenNormalText: "tanpri soumèt prèv estati a, tankou:",
          nonCitizenSteps: [
            "Kat rezidan pèmanan (“kat vèt”)",
            "Kat Otorizasyon Travay",
            "Kat Rezidan Tanporè",
            "Dosye Rive-Depa (I-94)",
            "Tenb nan paspò",
            "Lòt dokiman ki montre estati imigrasyon aktyèl oswa annatant",
            "Deklarasyon yon avoka imigrasyon sou estati aktyèl oswa annatant",
          ],
          expenseTextBold: "Si depans medikal total yo te chanje pa omwen $25 ",
          expenseTextNormal:
            "nou pral mande prèv depans (tankou bòdwo, fakti, oswa resi).",
          expenseNoteDetailText:
            "Ou ka resevwa kredi pou depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo. Depans medikal yo enkli ko-peman, preskripsyon, medikaman san preskripsyon, asirans sante, bòdwo medikal, transpò, ak plis ankò. Ou ka deklare oumenm depans pou transpò pou rezon medikal.",
          expenseText:
            "Si nenpòt moun lakay ou se granmoun aje (omwen laj 60 an) oswa gen yon andikap, epi depans medikal yo te chanje pa omwen $25, tanpri voye ban nou prèv depans yo (tankou bòdwo, fakti, oswa resi).",
          childBoldText:
            "Si yon moun nan fwaye a ap fè peman pou sipò timoun, ",
          childNormalText: "tanpri voye ban nou:",
          childSteps:
            "Verifikasyon obligasyon legal pou peye sipò timoun nan (tankou yon òdonans tribinal) ak prèv Peman resan yo.",
          childNoteDetailText:
            "Peman pou sipò timoun yo pa ka resevwa kredi sof si yo anba obligasyon legal epi peman yo ap fèt.",
          voterTitle: "Enskripsyon Elektè",
          voterTextBefore:
            "Lwa Nasyonal Enskripsyon Elektè 1993 la egzije DHR pou ba ou opòtinite pou enskri pou vote. DHR te voye yon aplikasyon pou Enskripsyon Elektè ba ou ansanm ak fòm Resètifikasyon SNAP ou a. Fè DHR konnen si w bezwen èd pou enskri. Ou ka enskri tou pou vote an liy nan klike ",
          voterTextLink: "la a.",
          voterLink: "https://www.sec.state.ma.us/ovr/",
          voterTextAfter:
            " Aplike pou enskri oswa refize enskri pou vote pa pral afekte montan asistans ajans sa a pral ba w la. Si ou pa yon Sitwayen Ameriken, ou pa kalifye pou vote, epi ou pa ta dwe enskri pou vote.",
          sendDocumentBtnLabel: "Voye yon dokiman bay DHR",
          dontSendDocumentBtnLabel:
            "Oswa sòti san w pa telechaje okenn dokiman",
          otherDocWays: "Gen plizyè lòt fason pou soumèt dokiman yo ban nou. ",
          findMoreText: "Jwenn plis enfòmasyon",
          rightsHHRemovedText:
            "Enpòtan: Klike sou soumèt pa kòmanse pwosesis Resètifikasyon an. DHR dwe pale ak ou pou konfime chanjman yo.",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.shelterAndutility) {
    switch (language) {
      case EN:
        return {
          shelterAndUtilityMenuTitle: "Shelter & Utility Expenses",
          shelterAndUtilityHeading: "Shelter & Utility Expenses Summary",
          rentTargetValue: "RENT",
          mortgageTargetValue: "MTGPRN",
          propInsTargetValue: "PRPINS",
          propTaxTargetValue: "PRPTAX",
          secondMortgageTargetValue: "SECMTG",
          homelessExpTargetValue: "HOMEXP",
          shelterExpenseLabel : "Shelter Expenses",
          utilityExpensesLabel: "Utility Expenses",
          shelterSubTitle:"Please include expenses that you are responsible for paying, even if you are behind or not able to pay them.",
          utilitySubTitle: "Please tell us all the utilities you are responsible for paying, even if we already have them on file. You may include expenses even if you are behind or not able to pay them.",
          homeEquityTargetValue: "HOMEEQ",
          condoTargetValue: "CONFEE",
          houseOwnerTargetValue: "HOASFE",
          otherTargetValue: "OTHER",
          rentQuestion: "How much is your household's rent?",
          mortageQuestion: "How much is your household's mortage?",
          propInsQuestion: "How much is your household's property insurance?",
          propTaxQuestion: "How much is your household's property taxes?",
          secMortgageQuestion: "How much is your household's second mortage?",
          homelessQuestion: "How much is your household's homeless expenses?",
          equityLoanQuestion: "How much is your household's home equity loan?",
          condoQuestion: "How much is your household's condo fee?",
          houseAssQuestion:
            "How much is your household's house owner association fees?",
          otherQuestion: "How much is your household's other?",
          rentErrMsg: "Please enter rent",
          mortageErrMsg: "Please enter mortage",
          propInsErrMsg: "Please enter property insurance",
          propTaxErrMsg: "Please enter property taxes",
          secondMortgageErrMsg: "Please enter second mortage",
          homelessExpErrMsg: "Please enter homeless expenses",
          homeEquityErrMsg: "Please enter home equity loan",
          condoErrMsg: "Please enter condo fee",
          houseOwnerErrMsg: "Please enter house owner association fees",
          otherErrMsg: "Please enter other amount",
          perLabel: "Per",
          anotherRentLabel: "+ Add another rent",
          anothermortgageLabel: "+ Add another mortage",
          anotherpropInsLabel: "+ Add another property insurance",
          anotherpropTaxLabel: "+ Add another property taxes",
          anothersecondMortgageLabel: "+ Add another second mortage",
          anotherhomelessExpLabel: "+ Add another homeless expenses",
          anotherhomeEquityLabel: "+ Add another home equity loan",
          anothercondoLabel: "+ Add another condo fee",
          anotherhouseOwnerLabel: "+ Add another house owner association fees",
          anotherOtherLabel: "+ Add another other amount",
          houseUnitOptions: ["Week", "Month", "Year"],
          rent: "Rent",
          mortgage: "Mortgage",
          propertyTaxes: "Property Taxes",
          homeInsurance: "Home Insurance",
          condoFee: "Condo Fee",
          propertyInsurance: "Property Insurance",
          secondMortage: "Second Mortgage",
          homelessExpenses: "Homeless Expenses",
          homeEquityLoan: "Home Equity Loan",
          homeOwnerAssociationFees: "Homeowner Association Fees",
          other: "Other",
          week: "Weekly",
          quarter: "Quarter",
          month: "Monthly",
          year: "Yearly",
          weekly: "Week",
          monthly: "Month",
          yearly: "Yearly",
          semanl: "Semi-yearly",
          biweek: "Biweekly",
          onetime: "One-time only",
          bimonthly: "Bimonthly",
          semimonthly: "Semimonthly",
          //utility
          heatTargetValue: "payHeat",
          eletricityTargetValue: "payAC",
          elgasTargetValue: "elgas",
          phoneTargetValue: "hasPhone",
          waterTargetValue: "water",
          garbageTargetValue: "garbage",
          energyTargetValue: "liheap",
          heading: "Shelter & Utility",
          heatOil: "Heat (oil, gas, electricity or propane, etc.)",
          electricityairConditioner: "Electricity for an air conditioner",
          feeairConditioner: "A fee to use an air conditioner",
          electricityGas: "Electricity and/or gas",
          cellPhoneService: "Phone or cell phone service",
          none: "None",
          noneLable:"I do not pay for any utility expenses",
          waterAndSewage: "Water and Sewage",
          garbage: "Garbage",
          energyAssistance: "Energy Assistance (LIHEAP)",
        };
      case ES:
        return {
          shelterAndUtilityMenuTitle: "Refugio y utilidad",
          shelterAndUtilityHeading: "Resumen de gastos de alojamiento y servicios públicos",
          rentTargetValue: "RENT",
          mortgageTargetValue: "MTGPRN",
          propInsTargetValue: "PRPINS",
          propTaxTargetValue: "PRPTAX",
          secondMortgageTargetValue: "SECMTG",
          homelessExpTargetValue: "HOMEXP",
          homeEquityTargetValue: "HOMEEQ",
          shelterExpenseLabel : "Gastos de alojamiento",
          utilityExpensesLabel: "Gastos de servicios públicos",
          shelterSubTitle:"Incluya los gastos que usted es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
          utilitySubTitle: "Indíquenos todos los servicios públicos que usted es responsable de pagar, incluso si ya los tenemos archivados. Puede incluir gastos incluso si está atrasado o no puede pagarlos.",
          condoTargetValue: "CONFEE",
          houseOwnerTargetValue: "HOASFE",
          otherTargetValue: "OTRO",
          rentQuestion: "¿A cuánto asciende el alquiler de su hogar?",
          mortageQuestion: "¿A cuánto asciende la hipoteca de su hogar?",
          propInsQuestion: "¿A cuánto asciende el seguro de propiedad de su hogar?",
          propTaxQuestion: "¿A cuánto ascienden los impuestos a la propiedad de su hogar?",
          secMortgageQuestion: "¿A cuánto asciende la segunda hipoteca de su hogar?",
          homelessQuestion: "¿A cuánto ascienden los gastos de personas sin hogar de su hogar?",
          equityLoanQuestion: "¿A cuánto asciende el préstamo con garantía hipotecaria de su hogar?",
          condoQuestion: "¿A cuánto asciende la tarifa de condominio de su hogar?",
          houseAssQuestion:
            "¿A cuánto ascienden las cuotas de la asociación de propietarios de su hogar?",
          otherQuestion: "¿Cuánto cuesta el otro de su hogar?",
          rentErrMsg: "Por favor ingrese el alquiler",
          mortageErrMsg: "Por favor ingrese la hipoteca",
          propInsErrMsg: "Por favor ingrese seguro de propiedad",
          propTaxErrMsg: "Por favor ingrese los impuestos a la propiedad",
          secondMortgageErrMsg: "Por favor introduzca segunda hipoteca",
          homelessExpErrMsg: "Por favor ingrese los gastos de personas sin hogar",
          homeEquityErrMsg: "Por favor ingrese préstamo sobre el valor líquido de la vivienda",
          condoErrMsg: "Por favor ingrese la tarifa del condominio",
          houseOwnerErrMsg: "Por favor, introduzca las tarifas de la asociación de propietarios de viviendas.",
          otherErrMsg: "Por favor ingresa otra cantidad",
          perLabel: "Por",
          anotherRentLabel: "+ Añadir otro alquiler",
          anothermortgageLabel: "+ Añadir otra hipoteca",
          anotherpropInsLabel: "+ Agregar otro seguro de propiedad",
          anotherpropTaxLabel: "+ Agregar otros impuestos a la propiedad",
          anothersecondMortgageLabel: "+ Añadir otra segunda hipoteca",
          anotherhomelessExpLabel: "+ Agregue otro gasto para personas sin hogars",
          anotherhomeEquityLabel: "+ Agregar otro préstamo sobre el valor líquido de la vivienda",
          anothercondoLabel: "+ Agregar otra tarifa de condominio",
          anotherhouseOwnerLabel: "+ Agregar otras tarifas de asociación de propietarios de casas",
          anotherOtherLabel: "+ Añade otra cantidad",
          houseUnitOptions: ["Semana", "Mes", "Año"],
          rent: "Alquilar",
          mortgage: "Hipoteca",
          propertyTaxes: "Impuestos de propiedad",
          homeInsurance: "Seguro de hogar",
          condoFee: "Tarifa de condominio",
          propertyInsurance: "Seguro de propiedad",
          secondMortage: "Segunda hipoteca",
          homelessExpenses: "Gastos para personas sin hogar",
          homeEquityLoan: "Préstamo con garantía hipotecaria",
          homeOwnerAssociationFees: "Tarifas de la asociación de propietarios",
          other: "Otro",
          week: "Semanalmente",
          quarter: "Cuarto",
          month: "Mensual",
          year: "Anual",
          weekly: "Semana",
          monthly: "Mes",
          yearly: "Anual",
          semanl: "Semestral",
          biweek: "Quincenal",
          onetime: "Solo una vez",
          bimonthly: "Bimensual",
          semimonthly: "Semi mensual",
          //utility
          heatTargetValue: "pagarCalor",
          eletricityTargetValue: "pagarAC",
          elgasTargetValue: "vivido",
          phoneTargetValue: "tiene teléfono",
          waterTargetValue: "agua",
          garbageTargetValue: "basura",
          energyTargetValue: "montón",
          heading: "Refugio y utilidad",
          heatOil: "Calor (petróleo, gas, electricidad o propano, etc.)",
          electricityairConditioner: "Electricidad para un aire acondicionado.",
          feeairConditioner: "Una tarifa por usar un aire acondicionado.",
          electricityGas: "Electricidad y/o gas",
          cellPhoneService: "Servicio de telefonía o celular",
          none: "Ninguno",
          noneLable:"No pago ningún gasto de servicios públicos",
          waterAndSewage: "Agua y Alcantarillado",
          garbage: "Basura",
          energyAssistance: "Asistencia Energética (LIHEAP)",
        };
      default:
        return;
    }
  } else if (pageType === languageConstants.expensesRecert) {
    switch (language) {
      case EN :
        return {
          expensesTitle : "Expenses"
        };
      case ES:
        return {
          expensesTitle : "Gastos"
        }
    }
  } else if (pageType === languageConstants.summaryRecert) {
    switch (language) {
      case EN :
        return {
          summaryTitle : "Summary"
        };
      case ES:
        return {
          summaryTitle : "Resumen"
        }
    }
  }
}

export function recertWarningPopupMLanguageLabels(language, date) {
  switch (language) {
    case EN:
      return {
        title: "Info",
        languageWarningLabel:"If you want to change the langauge your application will get restarted.",
        popupBodyTexts: [
          "You have reported a change but did not enter anything. If you have a change, select “Yes”. If you do not have a change, select “No”",
          "You must complete this section. If you cannot complete this now, you may complete your Interim Report online later or submit the paper form. If you do not complete this process by " +
            date +
            ", your case will close. If you need help, contact Alabama DHR at 877-382-2363.",
        ],
        saveAndExitHeader: "Saved Successfully",
        aesapSaveExitLabel1: "The SNAP AESAP Recertification has been saved successfully on",
        saveExitLabel1: "The SNAP Recertification has been saved successfully on",
        tanfSaveExitLabel1: "The TANF Redetermination has been saved successfully on",
        saveExitLabel2: "at",
        saveExitLabel3:
          "and can be accessed from the home screen. It will be available for the next ",
        saveExitLabel4: " hours for you to make any changes.",
        CancelButtonLabel: "Cancel",
        areYouSureWarningLabel: "Are you sure ?",
        saveAndExit: "Save",
        saveOrExitLabel:
          "You might lose the unsaved information in the SNAP Recertification. Do you want to save the information before you exit?",
          savetanfOrExitLabel:
          "You might lose the unsaved information in the Tanf Redetermination. Do you want to save the information before you exit?",
        okLabel: "Ok",
        deleteWarningLabel: "WARNING!",
        deleteExitLabel:
          "The information you have entered will be permanently deleted. Are you sure you want to delete?",
        deletePopUpLabel:
          "Deleting the SNAP Recertification Report will not affect any current services you are receiving through the Department of Human Resources.",
          deletePopUpLabelAesap:
          "Deleting the SNAP AESAP Recertification Report will not affect any current services you are receiving through the Department of Human Resources.",
          deletePopUpLabelTanf:
          "Deleting the TANF Redetermination Report will not affect any current services you are receiving through the Department of Human Resources.",
        CancelButtonLabel: "Cancel",
        confirmButtonLabel: "Confirm",
        deleteButtonLabel: "Delete",
        confirmDelete: "Confirm Delete",
      };
    case ES:
      return {
        title: "Información",
        areYouSureWarningLabel: "Estas segura ?",
        languageWarningLabel:"Si desea cambiar el idioma, su aplicación se reiniciará.",
        popupBodyTexts: [
          "Usted ha comunicado un cambio pero no ha introducido nada. Si tiene un cambio haga seleccionar en “Sí”. Si no tiene un cambio haga seleccionar en “No”",
          "Debe completar esta sección. Si no puede completar esto ahora, puede completar su Informe provisional en línea más adelante, enviar el formulario en papel o dirigirse a una oficina local del DHR.",
          "Si no completa este proceso antes del " +
            date +
            ", su caso se cerrará. ",
          "Si necesita ayuda, contacte al DHR en el 877-382-2363.",
        ],
        saveAndExitHeader: "Guardar y salir de la aplicación",
        saveExitLabel1: "Esta aplicación se ha guardado correctamente en",
        savetanfOrExitLabel: "Es posible que pierda la información no guardada en la Redeterminación de Tanf. ¿Quieres guardar la información antes de salir?",
        tanfSaveExitLabel1: "La redeterminación de TANF se ha guardado exitosamente en",
        saveExitLabel2: "en",
        saveExitLabel3:
          "y se puede acceder desde la pantalla de inicio. Estará disponible para la próxima ",
        saveExitLabel4: " horas para que usted pueda realizar cualquier cambio.",
        CancelButtonLabel: "Cancelar",
        okLabel: "De acuerdo",
        deleteWarningLabel: "¡ADVERTENCIA!",
        deleteExitLabel:
          "¡ADVERTENCIA! La información que ha ingresado se eliminará permanentemente. ¿Estas seguro que quieres borrarlo?",
        deletePopUpLabel:
          "Eliminar el Informe de recertificación de SNAP no afectará los servicios actuales que esté recibiendo a través del Departamento de Recursos Humanos.",
        deletePopUpLabelTanf: "Eliminar el Informe de redeterminación de TANF no afectará ningún servicio actual que esté recibiendo a través del Departamento de Recursos Humanos.",
        CancelButtonLabel: "Cancelar",
        confirmButtonLabel: "Confirmar",
        deleteButtonLabel: "Borrar",
        confirmDelete: "Confirmar eliminación",
      };
    case PT:
      return {
        title: "Info",
        popupBodyTexts: [
          "Você informou que houve uma alteração, mas não digitou nada. Se realmente houve uma alteração, clique em “Sim”. Se não houve alteração, clique em “Não”",
          "É preciso preencher esta seção. Se não puder fazer isso agora, preencha o seu formulário on-line interino depois, envie o formulário de papel ou compareça a um escritório local do DHR.",
          "Se você não concluir esse processo até " +
            date +
            ", o seu caso será encerrado. ",
          "Se precisar de ajuda, entre em contato com o DHR pelo número 877-382-2363.",
        ],
      };
    case ZH:
      return {
        title: "信息",
        popupBodyTexts: [
          "您报告了变更，但未输入任何信息。如果您有变更，请点击“是”。如果您没有变更，请点击“否”。",
          "必须填写此部分。如果现在无法填写，您可以稍后在线填写临时报告，提交纸质表格，或前往当地的 DHR 办公室。",
          "如果您未在 " + date + ", 之前完成这一流程，则您的案例将会关闭 ",
          "如果您需要帮助，请拨打 877-382-2363 与 DHR 联系。",
        ],
      };
    case VI:
      return {
        title: "Thông tin",
        popupBodyTexts: [
          "Quý vị đã báo cáo một sự thay đổi nhưng không nhập điều gì cả. Nếu quý vị có điều gì thay đổi, xin nhấp vào Có. Nếu quý vị không có điều gì thay đổi, xin nhấp vào 'Không'",
          "Quý vị phải điền phần này. Nếu quý vị không thể điền xong giấy này ngay bây giờ, quý vị có thể điền bản Báo cáo tạm thời trên mạng sau, gửi giấy này đi hoặc đến tại văn phòng DHR địa phương.",
          "Nếu quý vị không hoàn tất thủ tục này hạn chót là ngày " +
            date +
            ",  hồ sơ của quý vị sẽ bị đóng. ",
          "Nếu quý vị cần giúp đỡ, vui lòng liên lạc với DHR tại 877-382-2363.",
        ],
      };
    case HI:
      return {
        title: "Enfòmasyon",
        popupBodyTexts: [
          'Ou te rapòte yon chanjman men ou pa t antre anyen. Si w gen yon chanjman klike sou "Wi". Si ou pa gen yon chanjman klike sou "Non"',
          "Ou dwe ranpli seksyon sa a. Si ou pa kapab fè sa kounye a, ou ka ranpli Rapò Pwovizwa ou an liy pita oswa soumèt fòm papye a. Si w pa fini pwosesis sa a anvan " +
            date +
            ", Dosye w la pral fèmen. Si w bezwen èd, kontakte DHR nan 877-382-2363.",
        ],
      };
    default:
      return;
  }
}

export function incomeMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        heading: name + "'s income and benefits",
        mainQuestion:
          "What type(s) of income and benefits does " + name + " have?",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " wages?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " + name + " self-employment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " work study?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " + name + " SSI (Supplemental Security Income)?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " +
                name +
                " RSDI (Retirement, Survivor's, and Disability Insurance)?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " unemployment?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " child support?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " pension?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " + name + " veteran's benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]: " amount of " + name + " rental income?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]:
              "How many hours does " +
              name +
              " work on the rental income property?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " + name + " Worker's Compensation?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of " + name + " other income or benefits?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
        ],
      };
    case ES:
      return {
        heading: "Ingreso y beneficios de " + name,
        mainQuestion: "¿Qué tipos de ingresos o beneficios tiene " + name + "?",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " cantidad de " + name + " Salarios?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " cantidad de " + name + " Trabajo independiente?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Trabajo estudio?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " de " + name + " SSI (Ingreso de seguridad suplementario)?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " de " +
                name +
                " RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Desempleo?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Manutención?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Pensión?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]:
                " de " + name + " Beneficios de veteranos?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Ingreso por alquiler?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]:
              "¿Cuántas horas " +
              name +
              " trabaja en la propiedad de ingreso por alquiler?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de ",
              [Props.LABEL_OBJ_AFTER]:
                " de " + name + " Compensación a los trabajadores?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
              [Props.LABEL_OBJ_TRIGGER]: "ingreso bruto de",
              [Props.LABEL_OBJ_AFTER]: " de " + name + " Otro?",
              [Props.LABEL_OBJ_HELP]:
                "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
            },
          },
        ],
      };
    case PT:
      return {
        heading: "Renda e benefícios recebidos por " + name,
        mainQuestion:
          "Qual(is) tipo(s) de renda ou benefícios " + name + " recebe?",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: name + " recebe como Salário?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Trabalho autônomo?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: " " + name + "  recebe como Estudo?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " +
                name +
                " recebe como SSI (Renda de segurança suplementar)?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                "  " +
                name +
                " recebe como RSDI (Seguro de aposentadoria, sobreviventes e deficiências)?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: "" + name + "  recebe como Desemprego?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Suporte infantil?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: " " + name + " recebe como Pensão?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                " " + name + " recebe como Benefício de veterano?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]: name + " recebe como Renda de aluguel?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]:
              "Quantas horas " +
              name +
              " trabalha para pagar o aluguel da propriedade?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que",
              [Props.LABEL_OBJ_AFTER]:
                name + " recebe como Compensação do trabalhador?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Qual é a  ",
              [Props.LABEL_OBJ_TRIGGER]: "renda bruta que ",
              [Props.LABEL_OBJ_AFTER]:
                name + "recebe como outros rendimentos ou benefícios?",
              [Props.LABEL_OBJ_HELP]:
                "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
            },
          },
        ],
      };
    case ZH:
      return {
        heading: name + "的收入和补助",
        mainQuestion: name + " 拥有哪种收入或补助？",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: " 工资 总收入 是多少",
              [Props.LABEL_OBJ_AFTER]: " ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "个体经营 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 工作研究 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "  SSI（补充保障收入） 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]:
                "RSDI（退休，幸存者和残疾保险） 是多少 ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "失业 是多少 ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "子女抚养费 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "  养老 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "工伤赔偿其 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: "租金收入 是多少  ?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: name + "在租赁收入资产上的工作时数?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 工伤赔偿其 是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: name + " 的",
              [Props.LABEL_OBJ_TRIGGER]: "总收入",
              [Props.LABEL_OBJ_AFTER]: " 其他  是多少?",
              [Props.LABEL_OBJ_HELP]:
                "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
            },
          },
        ],
      };
    case VI:
      return {
        heading: name + "Thu nhập và trợ cấp của",
        mainQuestion: "Loại thu nhập hoặc trợ cấp nào mà " + name + " có?",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Tự làm chủ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: "của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Nghiên cứu việc làm",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  SSI (Thu Nhập An Sinh Bổ Sung)",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)?",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thất nghiệp",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  Trợ cấp nuôi con",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Lương hưu",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  Trợ cấp cho cựu chiến binh",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ Thu nhập cho thu ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của" + name + "là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
          {
            [Props.LABEL]:
              name + "  làm việc bao nhiêu giờ trên tài sản thu nhập cho thuê?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Tổng thu nhập từ  ",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]:
                "Tổng thu nhập từ  Khôngcó thu nhập và trợ cấp",
              [Props.LABEL_OBJ_TRIGGER]: " thu nhập gộp",
              [Props.LABEL_OBJ_AFTER]: " của " + name + " là bao nhiêu?",
              [Props.LABEL_OBJ_HELP]:
                "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
            },
          },
        ],
      };
    case HI:
      return {
        heading: name + "'s revni ak benefis",
        mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
        subQuestions: [
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " Salè?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " travay endepandan?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " travay etidyan?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " SSI (Revni Sekirite Siplemantè)?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " +
                name +
                " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " chomaj?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " sipò timoun?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " pansyon?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " benefis pou veteran?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " revni lwaye?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]:
              "Konbyen èdtan " +
              name +
              " travay sou pwopriyete ak revni lwaye a?",
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " konpansasyon travayè",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
          {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "Ki ",
              [Props.LABEL_OBJ_TRIGGER]: "montan",
              [Props.LABEL_OBJ_AFTER]:
                " revni brit " + name + " lòt revni oswa benefis?",
              [Props.LABEL_OBJ_HELP]:
                "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            },
          },
        ],
      };
    default:
      return;
  }
}

export function studentMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        heading: name + "'s school details",
        questions: [
          {
            [Props.LABEL]: "Which type of school " + name + " is attending?",
          },
          {
            [Props.LABEL]: "What is the name of " + name + "'s school?",
          },
        ],
      };
    case ES:
      return {
        heading: name + "Información académica de",
        questions: [
          {
            [Props.LABEL]: "¿A qué tipo de escuela asiste " + name,
          },
          {
            [Props.LABEL]: "¿Cuál es el nombre de la escuela de " + name,
          },
        ],
      };
    case PT:
      return {
        heading: name + "Dados da escola",
        questions: [
          {
            [Props.LABEL]: "Que tipo de escola " + name + " está frequentando?",
          },
          {
            [Props.LABEL]: "Qual é o nome da escola de " + name,
          },
        ],
      };
    case ZH:
      return {
        heading: name + "的学校详情",
        questions: [
          {
            [Props.LABEL]: name + " 在哪个类型的学校就读",
          },
          {
            [Props.LABEL]: name + "的学校名称？",
          },
        ],
      };
    case VI:
      return {
        heading: name + "Thông tin về trường học của",
        questions: [
          {
            [Props.LABEL]: "Loại trường nào mà " + name + " đang theo học",
          },
          {
            [Props.LABEL]: "Tên của trường mà " + name + "đang học là gì?",
          },
        ],
      };
    case HI:
      return {
        heading: "Detay lekòl pou" + name,
        questions: [
          {
            [Props.LABEL]: "Ki tip lekòl " + name + " frekante?",
          },
          {
            [Props.LABEL]: "Ki non lekòl " + name + " la?",
          },
        ],
      };
    default:
      return;
  }
}

export function medicalExpenseMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"Yearly"},
          {key:"MONTH", value:"Monthly"},
          {key:"WEEK", value:"Weekly"},
        ],
        amountLabel: "What is the amount client spends on Medical Expenses?",
        unitLabel: "What is the frequency for Medical Expenses?"
      };
    case ES:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"Anual"},
          {key:"MONTH", value:"Mensual"},
          {key:"WEEK", value:"Semanalmente"},
        ],
        amountLabel: "¿Cuánto gasta el cliente en gastos médicos?",
        unitLabel: "¿Cuál es la frecuencia de Gastos Médicos?"
      };
    case PT:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"Anual"},
          {key:"MONTH", value:"Por mês"},
          {key:"WEEK", value:"Semanalmente"},
        ],
        amountLabel: "Quanto o cliente gasta com despesas médicas?",
        unitLabel: "Qual a periodicidade das Despesas Médicas?"
      };
    case ZH:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"每年"},
          {key:"MONTH", value:"每月"},
          {key:"WEEK", value:"每周"},
        ],
        amountLabel: "客户在医疗费用上花费了多少？",
        unitLabel: "医疗费用的频率是多少？"
      };
    case VI:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"Hàng năm"},
          {key:"MONTH", value:"hàng tháng"},
          {key:"WEEK", value:"hàng tuần"},
        ],
        amountLabel: "Khách hàng chi bao nhiêu tiền cho Chi phí Y tế?",
        unitLabel: "Tần suất chi phí y tế là bao nhiêu?"
      };
    case HI:
      return {
        unitsOptions: [
          {key:"ANNUAL", value:"Chak ane"},
          {key:"MONTH", value:"Chak mwa"},
          {key:"WEEK", value:"Chak semèn"},
        ],
        amountLabel: "Konbyen montan kliyan depanse pou Depans Medikal yo?",
        unitLabel: "Ki frekans pou Depans Medikal yo?"
      };
    default:
      return;
  }
}

export function beforeYoustartMlanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        heading: "SNAP Recertification",
        aesapHeading: "SNAP AESAP Recertification",
        howApplyLabel: "Recertification",
        aesapfieldLabel: "This application is for persons applying for Food Assistance when",
        aesaplist1: "Everyone in the Food Assistance household is age 60 or older; or",
        aesaplist2: "All household members are age 60 or older and purchase and prepare food separately from the other people in the home; and",
        aesaplist3: "No Food Assistance household member receives earnings from work.",
        aesapfinaltext: "You may file this application by completing at least your name, address, and signing the form. If you need help completing this application, call toll free 1-800-438-2958. To get the address or phone number of your local office, call toll free 1-833-822-2202 or on line at www.dhr.alabama.gov.",
        fieldLabel1:
          "You may complete your Recertification using the following methods:",
        recertMethods: [
          "Online by clicking Start Recertification. This is the fastest way!",
          {
            before:
              "If you do not wish to complete your SNAP application online, you may download and print your paper application ",
            hereLinkText: "here.",
            after: " You can submit your form by:",
          },
          "Fax, mail, email or take your paper application to your local county DHR office by clicking here.",
        ],
        boBackButtonLabel: "Go Back",
        getStartButtonLabel: "Start Recertification",
        sIncome: "'s Income",
        editButtonLabel: "Edit",
        perLabel: "Per",
        ofLabel: "of",

        renderAppNoLabel: "Your application no:",
        youMayLabelOne: "You may want to ",
        youMayLabeltwo: "download your application",
        youMayLabelThree: "for future reference.",
        whatisNextLabel: "What's Next?",
        dtaReachLabel: "The DHR will reach out to you within the next 5 days.",
        sendDocLabel:
          "Send in documents so that DHR can confirm your situation.",
        howDoSpeedLabel: "How do I speed up the process?",
        donotNeedLabel:
          "You do not need to wait for DHR to send you a list of verifications",
        sendLabelOne: "You can ",
        sendLabelTwo: "send us your documents ",
        sendLabelThree: "in many ways",
        toLearnOneLabel:
          "To learn more about documents that you may submit now,",
        toLearnOneLabelTwo: "click here",
        manyWayLabel: "There are many other ways to submit documents to us.",
        manyWayLabelTwo: "Find out more",
        howDoSubmitLabel: "How do I submit documents?",
        weSuggestLabel: "We suggest",
        weSuggestLabelOne: "creating an online account",
        weSuggestLabelTwo:
          "so you can upload documents, track the progress of your application, check your balance, and more!",
        findOutMoreLabel: "Find out more",
        createOnlineLabel: "Create an online account",
        orExistLabel: "Or exit without uploading any documents",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Chaning this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "Great! Here's what we got from you.",
        lookIncorrectLabel: "If any of it looks incorrect, feel free to edit.",
        anotherHouseholdIncomeLabel: "Add another household member's income",
        applicationSummaryTitle: "Application Summary",
        applicationSummaryHeader: "Application Summary",
        viewApplicationLinkLabel: "view as pdf",
        iagreeTermsLabel: "I agree",
        signYourAppllication:
          "Sign your Recertification by typing your full name below.",
        continueLabel: "Continue",
        getStartedLabel: "Start Recertification",
        submitButtonLabel: "Submit Recertification",
        expenseFieldLabel: "This last section is about",
        expenseHouseutility: "Housing and utility costs",
        expenseCaring: "Caring for kids and adults",
        expenseChsupport: "Child Support",
        expenseMed: "Medical expenses",
        expenseAnyinfo:
          "Any information you provide here will provide a more accurate benefit amount.",
        removeHoHTitle: "Warning",
        removeHoHLabel:
          "You are attempting to delete the head of the household. If this is correct, you may hit Continue now and DHR will contact you for more information. If this was a mistake, please hit “Go Back”",
        agreeContent: [
          "By signing, I agree that:",
          "I have read this entire form (or have had it read to me in a language that I understand), including the section about rights and responsibilities, and understand that I must comply with these rules;",
          "The information I am giving is true and complete to the best of my knowledge;",
          "I could go to prison or be required to pay fines if I knowingly give wrong or incomplete information; and DHR and other federal, state, and local officials may verify (check) any information I give",
        ],
        didReportChanges: "I did report changes",
        didNotReportChanges: "I did not report changes",
        agreeTermsErrorMsg: "Please agree to the terms.",
        signatureErrorMsg: "Please sign your Recertification.",
      }; //return End

    case ES:
      return {
        heading: "Recertificación SNAP",
        aesapHeading: "Recertificación SNAP AESAP",
        howApplyLabel: "Recertificación",
        aesapfieldLabel: "Esta solicitud es para personas que solicitan Asistencia Alimentaria cuando",
        aesaplist1: "Todos los miembros del hogar de Food Assistance tienen 60 años o más; o",
        aesaplist2: "Todos los miembros del hogar tienen 60 años o más y compran y preparan alimentos por separado del resto de personas del hogar; y",
        aesaplist3: "Ningún miembro del hogar que recibe Asistencia Alimentaria recibe ingresos del trabajo.",
        aesapfinaltext: "Puede presentar esta solicitud completando al menos su nombre, dirección y firmando el formulario. Si necesita ayuda para completar esta solicitud, llame gratis al 1-800-438-2958. Para obtener la dirección o el número de teléfono de su oficina local, llame gratis al 1-833-822-2202 o en línea en www.dhr.alabama.gov.",
        fieldLabel1:
          "Puede completar su Recertificación con los siguientes métodos:",
        recertMethods: [
          "En línea, haciendo clic en comenzar Recertificación. ¡Esta es la forma más rápida!",
          {
            before:
              "Aceptaremos un formulario impreso si incluye su nombre, dirección y firma. Esta opción no está disponible para realizar la presentación en línea. Descargue e imprima su formulario ",
            hereLinkText: "aquí.",
            after:
              " Después de completar el formulario, puede presentarlo de las siguientes maneras: ",
          },
          "Tomar una foto de cada página y subirlas a través de ACES DHR Self-Service Portal",
          "Por fax al (617) 887-8765",
          "Por correo a P.O. Box 4406, Taunton, MA 02780",
          "Llevarlo la oficina local de DHR para escanearlo en el área de autoservicio",
        ],
        boBackButtonLabel: "Volver",
        sIncome: "'Ingresos",
        getStartButtonLabel: "Comenzar Recertificación",
        editButtonLabel: "Editar",
        perLabel: "Por",
        ofLabel: "De",
        //new label:

        renderAppNoLabel: "El número de su solicitud es:",
        youMayLabelOne: "Puede",
        youMayLabeltwo: "descargar su solicitud",
        youMayLabelThree: "para tenerla como referencia en el futuro.",
        whatisNextLabel: "¿Cuál es el siguiente paso?",
        dtaReachLabel: "El DHR se comunicará con usted en los próximos 5 días.",
        sendDocLabel:
          "Envíe los documentos para que el DHR pueda confirmar su situación.",
        howDoSpeedLabel: "¿Cómo puedo acelerar el proceso?",
        donotNeedLabel:
          "No es necesario que espere a que el DHR le envíe una lista de verificaciones. ",
        sendLabelOne: "Puede",
        sendLabelTwo: "enviarnos sus documentos",
        sendLabelThree: "de muchas maneras. ",
        toLearnOneLabel:
          "Para saber más acerca de los documentos que puede enviar ahora, ",
        toLearnOneLabelTwo: "haga clic aquí",
        manyWayLabel: "Hay muchas otras maneras de presentar documentos.",
        manyWayLabelTwo: "Obtenga más información.",
        howDoSubmitLabel: "¿Cómo envío documentos?",
        weSuggestLabel: "¡Le sugerimos que",
        weSuggestLabelOne: " cree una cuenta en línea",
        weSuggestLabelTwo:
          "para que pueda subir documentos, darle seguimiento a su solicitud, consultar su saldo y más!",
        findOutMoreLabel: "Averiguar más",
        createOnlineLabel: "Crear una cuenta en línea",
        orExistLabel: "O salir sin subir documentos.", //
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Chaning this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "Excelente. Esto es lo que tenemos de usted",
        lookIncorrectLabel: "Si algo parece incorrecto, no dude en editarlo.",
        anotherHouseholdIncomeLabel:
          "Agregar el ingreso de otro miembro del núcleo familiar",
        applicationSummaryTitle: "Resumen de la aplicación",
        applicationSummaryHeader: "Resumen de la aplicación",
        viewApplicationLinkLabel: "Ver como PDF",
        iagreeTermsLabel: "Acepto",
        signYourAppllication:
          "Firme su recertificación escribiendo su nombre completo a continuación",
        continueLabel: "Continuar",
        getStartedLabel: "Comenzar Recertificación",
        submitButtonLabel: "Presentar Recertificación",
        expenseFieldLabel: "Esta última sección trata sobre",
        expenseHouseutility: "Costos de vivienda y servicios públicos",
        expenseCaring: "Cuidado de niños y adultos",
        expenseChsupport: "Manutención",
        expenseMed: "Gastos médicos",
        expenseAnyinfo:
          "Toda la información que proporcione aquí dará un monto más exacto del beneficio.",
        removeHoHTitle: "Advertencia",
        removeHoHLabel:
          "Intenta eliminar al jefe/la jefa de familia. Si es correcto, puede hacer clic en Presentar ahora y DHR se comunicará con usted para obtener más información. Si es un error, haga clic en 'Volver'. ",
        agreeContent: [
          "Al firmar, acepto lo siguiente:",
          "Leí todo el formulario (o alguien lo leyó para mí en un idioma que comprendo), incluida la sección sobre derechos y responsabilidades, y entiendo que debo cumplir con esas reglas;",
          "A mi leal saber y entender, la información que proporciono es verdadera y completa;",
          "Podría ir a prisión o se me podría exigir el pago de multas si proporciono intencionalmente información errónea o incompleta; y DHR y otros funcionarios federales, estatales y locales podrán verificar (controlar) la información que proporciono.",
        ],
        didReportChanges: "Informé cambios.",
        didNotReportChanges: "No informé cambios.",
        agreeTermsErrorMsg: "Por favor, acepte los términos.",
        signatureErrorMsg: "Firme su recertificación",
      }; //return End

    case PT:
      return {
        heading: "Recertificação",
        howApplyLabel: "Recertificação",
        fieldLabel1:
          "Você pode preencher sua Recertificação usando os seguintes métodos: ",
        recertMethods: [
          "Online clicando em <b>iniciar Recertificação.</b> Essa é a forma mais rápida!",
          {
            before:
              "Aceitaremos um formulário impresso se você incluir seu nome, endereço e assinatura. Esta opção não está disponível para envio online. Baixe e imprima seu formulário ",
            hereLinkText: "aqui.",
            after: " Após preencher esse formulário, você pode enviá-lo por:",
          },
          "Foto, tirando uma foto de cada página e carregando via ACES DHR Self-Service Portal",
          "Fax para (617) 887-8765",
          "Correio para P.O. Box 4406, Taunton, MA 02780",
          "Pessoalmente, trazendo ao seu escritório DHR local para digitalizar na área de autoatendimento",
        ],
        boBackButtonLabel: "Voltar",
        sIncome: " Renda",
        getStartButtonLabel: "Iniciar Recertificação",
        editButtonLabel: "Editar",
        perLabel: "Por",
        ofLabel: "do",

        //new label:

        renderAppNoLabel: "O número da sua solicitação é :",
        youMayLabelOne: "Baixe a sua solicitação ",
        youMayLabeltwo: "caso precise dela no futuro.",
        youMayLabelThree: "for future reference.",
        whatisNextLabel: "Qual é a próxima etapa?",
        dtaReachLabel: "O DHR entrará em contato com você em 5 dias.",
        sendDocLabel:
          "Envie os documentos para que o DHR possa confirmar a sua situação.",
        howDoSpeedLabel: "Como eu posso acelerar o processo?",
        donotNeedLabel:
          "Você não precisa esperar que o DHR envie uma lista de verificações.",
        sendLabelOne: "Você pode nos enviar seus",
        sendLabelTwo: " documentos",
        sendLabelThree: "de diversas formas.",
        toLearnOneLabel:
          "Para saber mais sobre os documentos que você pode enviar agora,",
        toLearnOneLabelTwo: "clique aqui",
        manyWayLabel:
          "Existem muitas outras maneiras de enviar documentos para nós.",
        manyWayLabelTwo: "Saiba mais",
        howDoSubmitLabel: "Como eu envio os documentos?",
        weSuggestLabel: "Sugerimos que você crie uma conta",
        weSuggestLabelOne: " on-line para fazer o upload dos ",
        weSuggestLabelTwo:
          "documentos, verificar o andamento da sua solicitação, checar o seu saldo e muito mais!",
        findOutMoreLabel: "Saiba mais",
        createOnlineLabel: "Crie uma conta on-line",
        orExistLabel: "Ou saia sem fazer o upload de nenhum documento",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Chaning this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "Ótimo! É isso que recebemos de você.",
        lookIncorrectLabel:
          "Se algo estiver incorreto, sinta-se à vontade para editar.",
        anotherHouseholdIncomeLabel:
          "Adicionar a renda de outro membro da família",
        applicationSummaryTitle: "Resumo da aplicação",
        applicationSummaryHeader: "Resumo da aplicação",
        viewApplicationLinkLabel: "Visualizar como PDF",
        iagreeTermsLabel: "Concordo",
        signYourAppllication:
          "Assine sua Recertificação digitando seu nome completo abaixo",
        continueLabel: "Continuar",
        getStartedLabel: "Iniciar Recertificação",
        submitButtonLabel: "Enviar Recertificação",
        expenseFieldLabel: "Esta última seção é sobre",
        expenseHouseutility: "Custos residenciais e utilitários",
        expenseCaring: "Cuidados com crianças e adultos",
        expenseChsupport: "Suporte infantil",
        expenseMed: "Despesas médicas",
        expenseAnyinfo:
          "Qualquer informação que você prestar aqui irá fornecer um valor mais preciso do benefício.",
        removeHoHTitle: "Aviso",
        removeHoHLabel:
          "Você está tentando excluir o chefe do domicílio. Se isso estiver correto, você pode clicar em enviar agora e o DHR entrará em contato para obter mais informações. Se isso foi um erro, clique em 'Voltar'. ",
        agreeContent: [
          "Ao assinar, concordo que:",
          "Li todo este formulário (ou o leram para mim em um idioma que compreendo), incluindo a seção sobre direitos e responsabilidades, e entendo que devo cumprir essas regras;",
          "As informações que estou dando são verdadeiras e completas, de acordo com o meu conhecimento; ",
          "Eu poderia ir para a prisão ou ser obrigado a pagar multas se conscientemente fornecer informações erradas ou incompletas; e o DHR e outras autoridades federais, estaduais e locais podem verificar (conferir) qualquer informação que eu der.",
        ],
        didReportChanges: "Eu relatei as alterações.",
        didNotReportChanges: "Eu não relatei nenhuma alteração.",
        agreeTermsErrorMsg: "Concorde com os termos",
        signatureErrorMsg: "Assine sua Recertificação",
      }; //return End

    case ZH:
      return {
        heading: "重新认证",
        howApplyLabel: "重新认证",
        fieldLabel1: "您可使用下列方法填写您的《重新认证》表格：",
        recertMethods: [
          "在线填写，点击开始填写《重新认证》。这是最快的填写方式！",
          {
            before:
              "如果您在纸质表格中填写您的姓名、地址并签名，我们也接受纸质表格。此选项针对在线提交不可用。此处下载并打印您的纸质 ",
            hereLinkText: "表格。",
            after: " 您填写好表格后. 可以通过下列方式提交:",
          },
          "将表格各页拍照并利用 ACES DHR Self-Service Portal 上传",
          "传真发送至 (617)887-8765",
          "邮寄至 P.O. Box 4406, Taunton, MA 02780",
          "携带表格前往您当地的 DHR 办事处，在自助区域进行扫描",
        ],
        boBackButtonLabel: "返回",
        sIncome: "收入",
        getStartButtonLabel: "开始重新认证",
        editButtonLabel: "编辑",
        perLabel: "每",
        ofLabel: "的",
        //new label:
        renderAppNoLabel: "您的申请编号是",
        youMayLabelOne: "您可能想要",
        youMayLabeltwo: "下载申请表",
        youMayLabelThree: "下载申请表以备日后查阅。",
        whatisNextLabel: "下一步",
        dtaReachLabel: "DTA将在接下来5个工作日内与您联系。",
        sendDocLabel: "发送文件以便DTA能够确认您的状况。",
        howDoSpeedLabel: "如何加速审核流程？",
        donotNeedLabel: "您无需等待DTA向您发送证明文件清单。",
        sendLabelOne: "您可通过多种方式",
        sendLabelTwo: "向我们发送文件 ",
        sendLabelThree: "in many ways",
        toLearnOneLabel: "要详细了解您现在可以提交的文件，请",
        toLearnOneLabelTwo: "单击这里",
        manyWayLabel: "向我们提交文件可通过很多其他方式。",
        manyWayLabelTwo: "了解更多",
        howDoSubmitLabel: "如何提交文件？",
        weSuggestLabel: "我们建议您",
        weSuggestLabelOne: "创建一个在线账户",
        weSuggestLabelTwo:
          "您可以通过此账户上传资料，跟踪申请进度，查看余额等等！",
        findOutMoreLabel: "了解更多",
        createOnlineLabel: "创建在线账户",
        orExistLabel: "或退出而不上传任何资料",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Chaning this value will delete the previous entered values in upcoming sections.",
        greatHereLabel: "这是我们从你那里了解到的情况",
        lookIncorrectLabel: "如果有任何错误之处，敬请改正。",
        anotherHouseholdIncomeLabel: "添加其他家庭成员的收入",
        applicationSummaryTitle: "應用摘要",
        applicationSummaryHeader: "應用摘要",
        viewApplicationLinkLabel: "查看 PDF 文件",
        iagreeTermsLabel: "我同意",
        signYourAppllication: "在下方输入您的全名即为签署您的重新认证文件",
        continueLabel: "继续",
        getStartedLabel: "开始重新认证",
        submitButtonLabel: "提交重新认证",
        expenseFieldLabel: "最后一部分是关于",
        expenseHouseutility: "住房和通用开支",
        expenseCaring: "子女和成人照护费",
        expenseChsupport: "子女抚养费",
        expenseMed: "医疗费",
        expenseAnyinfo:
          "您在此提供的任何信息都将有助于我们确定更准确的补助金额。",
        removeHoHTitle: "警告",
        removeHoHLabel:
          "您正在尝试删除户主。如果这一操作正确，您可现在点击提交，DHR 会联系您获取更多信息。如果这一操作为误操作，请点击“返回”。 ",
        agreeContent: [
          "我签名即表示同意：",
          "我已经阅读此表格完整内容（或让人以我理解的语言为我朗读此表格完整内容），包括关于权利和责任的部分，而且我理解，我必须遵守这些规定；",
          "我提供的信息据我所知均真实而且完整。 ",
          "如果我在知情的前提下给出错误或不完整的信息，我可能入狱或按要求支付罚款；DHR 及其他联邦、州和地方官员可验证（检查）我提供的所有信息。",
        ],
        didReportChanges: "我已经报告信息 更改。s",
        didNotReportChanges: "我并未报告任何更改。",
        agreeTermsErrorMsg: "请同意条款",
        signatureErrorMsg: "请签署您的重新认证文件",
      }; //return End
    case VI:
      return {
        heading: "Chứng nhận lại",
        howApplyLabel: "Chứng nhận lại",
        fieldLabel1:
          "Quý vị có thể hoàn thành Chứng nhận lại của mình bằng các phương pháp sau: ",
        recertMethods: [
          "Trực tuyến bằng cách nhấp vào nút <b>bắt đầu mẫu Chứng nhận lại.</b> Đây là cách nhanh nhất!",
          {
            before:
              "Chúng tôi sẽ chấp nhận đăng ký bằng biểu mẫu trên giấy nếu trên đó có tên, địa chỉ và chữ ký của quý vị. Tùy chọn này không có sẵn khi nộp trực tuyến. Tải về và in biểu mẫu ở ",
            hereLinkText: "đây.",
            after:
              " Sau khi quý vị hoàn thành biểu mẫu đó, quý vị có thể gửi bằng cách:",
          },
          "Chụp ảnh từng trang và tải lên qua ACES DHR Self-Service Portal",
          "Gửi fax theo số (617) 887-8765",
          "Gửi thư đến Hộp thư bưu điện 4406, Taunton, MA 02780",
          "Mang đến văn phòng DHR địa phương của quý vị để scan tại khu vực tự phục vụ",
        ],
        boBackButtonLabel: "Quay lại",
        sIncome: "'thu nhập của",
        getStartButtonLabel: "Bắt đầu mẫu Chứng nhận lại",
        editButtonLabel: "Chỉnh sửa",
        perLabel: "Mỗi",
        ofLabel: "Của",

        //new label:

        renderAppNoLabel: "Số Đơn xin:",
        youMayLabelOne: "Có thể Quý vị muốn ",
        youMayLabeltwo: " tải đơn xin của quý vị về",
        youMayLabelThree: "để tham khảo trong tương lai.",
        whatisNextLabel: "Điều gì diễn ra kế tiếp?",
        dtaReachLabel: "DHR sẽ liên lạc với quý vị trong vòng 5 ngày tới.",
        sendDocLabel:
          "Gửi các tài liệu để DHR có thể xác nhận tình trạng của quý vị. ",
        howDoSpeedLabel: "Làm thế nào để đẩy nhanh tiến trình?",
        donotNeedLabel:
          "Quý vị không cần đợi DHR gửi cho quý vị danh sách xác minh.",
        sendLabelOne: "Quý vị có thể ",
        sendLabelTwo: " gửi cho chúng tôi giấy tờ của quý vị",
        sendLabelThree: " bằng nhiều cách",
        toLearnOneLabel:
          "Để tìm hiểu thêm về các giấy tờ mà quý vị có thể gửi ngay bây giờ,",
        toLearnOneLabelTwo: "hãy nhấp vào đây",
        manyWayLabel: "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
        manyWayLabelTwo: "Tìm hiểu thêm",
        howDoSubmitLabel: "Làm thế nào để tôi gửi giấy tờ?",
        weSuggestLabel: "Chúng tôi khuyên quý vị nên",
        weSuggestLabelOne: "tạo một tài khoản trực tuyến",
        weSuggestLabelTwo:
          "để quý vị có thể tải lên giấy tờ, theo dõi tiến trình đơn xin của quý vị, kiểm tra số dư của quý vị và những việc khác nữa!",
        findOutMoreLabel: "Tìm hiểu thêm",
        createOnlineLabel: "Tạo tài khoản trực tuyến",
        orExistLabel: "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
        renderSaveLabel: "To save your progress, create an account here.",
        laterOnLabel:
          "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
        orExitLabel: "Or exit without saving your work",
        changingLabel:
          "Chaning this value will delete the previous entered values in upcoming sections.",
        greatHereLabel:
          "Tuyệt vời, đây là những gì chúng tôi thu thập được từ quý vị",
        lookIncorrectLabel:
          "Nếu có bất cứ điều nào trông có vẻ không chính xác, quý vị cứ tự nhiên chỉnh sửa",
        anotherHouseholdIncomeLabel:
          "Bổ sung thu nhập của thành viên khác trong hộ gia đình",
        applicationSummaryTitle: "Tóm tắt ứng dụng",
        applicationSummaryHeader: "Tóm tắt ứng dụng",
        viewApplicationLinkLabel: "Xem dưới dạng PDF",
        iagreeTermsLabel: "Tôi đồng ý",
        signYourAppllication:
          "Ký xác nhận của bạn bằng cách nhập tên đầy đủ của bạn dưới đây",
        continueLabel: "Tiếp tục",
        getStartedLabel: "Bắt đầu mẫu Chứng nhận lại",
        submitButtonLabel: "Gửi đi chứng nhận lại",
        expenseFieldLabel: "Phần cuối cùng này là về",
        expenseHouseutility: "Chi phí nhà ở và tiện ích",
        expenseCaring: "Chăm sóc trẻ em và người lớn",
        expenseChsupport: "Trợ cấp nuôi con",
        expenseMed: "Chi phí y tế",
        expenseAnyinfo:
          "Bất kỳ thông tin nào quý vị cung cấp ở đây sẽ đưa ra số tiền trợ cấp chính xác hơn.",
        removeHoHTitle: "Cảnh báo",
        removeHoHLabel:
          "Quý vị đang cố gắng xóa mục chủ hộ. Nếu đúng, quý vị có thể nhấp vào gửi ngay bây giờ và DHR sẽ liên hệ với quý vị để biết thêm thông tin. Nếu đây là lỗi, xin vui lòng bấm nút 'quay lại.'",
        agreeContent: [
          "Bằng cách ký vào mẫu này, tôi đồng ý rằng:",
          "Tôi đã đọc toàn bộ biểu mẫu này (hoặc biểu mẫu này đã được đọc cho tôi bằng ngôn ngữ mà tôi hiểu) bao gồm cả phần về quyền và trách nhiệm và hiểu rằng tôi phải tuân thủ các quy tắc này;",
          "Theo sự hiểu biết tốt nhất của tôi, thông tin tôi đang cung cấp là đúng sự thật và đầy đủ;",
          "Tôi có thể vào tù hoặc bị buộc phải nộp tiền phạt nếu tôi cố tình cung cấp thông tin sai hoặc không đầy đủ; và DHR và các cán bộ liên bang, tiểu bang và địa phương khác có thể xác minh (kiểm tra) bất kỳ thông tin nào tôi cung cấp",
        ],
        didReportChanges: "Tôi đã báo cáo những thay dổi.",
        didNotReportChanges: "Tôi đã không báo cáo bất kỳ thay đổi nào. ",
        agreeTermsErrorMsg: "Vui lòng đồng ý với các điều khoản",
        signatureErrorMsg: "Vui lòng ký xác nhận lại",
      }; //return End
    case HI:
      return {
        heading: "Resètifikasyon",
        howApplyLabel: "Resètifikasyon",
        fieldLabel1:
          "Ou ka ranpli Resètifikasyon w lan nan itilize metòd sa yo:",
        recertMethods: [
          "An liy nan klike sou <b>Kòmanse Resètifikasyon.</b> Se fason ki pi rapid la!",
          {
            before:
              "Nou pral aksepte yon fòm papye si w mete non, adrès ak siyati w. Opsyon sa a pa disponib pou soumèt an liy. Telechaje epi enprime fòm ",
            hereLinkText: "papye w la la a.",
            after: " Ou ka soumèt fòm ou an nan:",
          },
          "Pran yon foto chak paj epi telechaje atravè ACES DHR Self-Service Portal",
          "Fakse nan (617) 887-8765",
          "Lapòs nan P.O. Box 4406, Taunton, MA 02780.",
        ],
        boBackButtonLabel: "Tounen",
        getStartButtonLabel: "Kòmanse Resètifikasyon",
        sIncome: "Revni",
        editButtonLabel: "Edite",
        perLabel: "Pa",
        ofLabel: "of",

        renderAppNoLabel: "Nimewo aplikasyon w lan:",
        youMayLabelOne: "Ou ka vle ",
        youMayLabeltwo: "telechaje aplikasyon w lan",
        youMayLabelThree: "pou plis referans.",
        whatisNextLabel: "Kisa k ap vini apre?",
        dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
        sendDocLabel: "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
        howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
        donotNeedLabel: "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
        sendLabelOne: "Ou ka ",
        sendLabelTwo: "voye dokiman w yo ban nou ",
        sendLabelThree: "nan plizyè fason",
        toLearnOneLabel:
          "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
        toLearnOneLabelTwo: "klike la a",
        manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman yo ban nou.",
        manyWayLabelTwo: "Jwenn plis enfòmasyon",
        howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
        weSuggestLabel: "Nou sijere",
        weSuggestLabelOne: "kreye yon kont an liy",
        weSuggestLabelTwo:
          "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
        findOutMoreLabel: "Jwenn plis enfòmasyon",
        createOnlineLabel: "Kreye yon kont an Liy",
        orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
        renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
        laterOnLabel:
          "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
        orExitLabel: "Oswa sòti san ou pa konsève travay ou",
        changingLabel:
          "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
        greatHereLabel: "Ekselan! Men sa nou te jwenn na men w.",
        lookIncorrectLabel:
          "Si nenpòt nan yo sanble pa kòrèk, pa ezite modifye.",
        anotherHouseholdIncomeLabel: "Ajoute revni yon lòt manm fwaye a fè",
        applicationSummaryTitle: "Rezime aplikasyon",
        applicationSummaryHeader: "Rezime aplikasyon",
        viewApplicationLinkLabel: "gade sou fòma pdf",
        iagreeTermsLabel: "Mwen dakò",
        signYourAppllication:
          "Siyen Re-Sètifikasyon w lan nan tape non konplè w anba a",
        continueLabel: "Kontinye",
        getStartedLabel: "Kòmanse Resètifikasyon",
        submitButtonLabel: "Soumèt Resètifikasyon",
        expenseFieldLabel: "Dènye seksyon sa a se konsènan",
        expenseHouseutility: "Depans pou lojman ak sèvis piblik",
        expenseCaring: "Pran swen timoun ak adilt",
        expenseChsupport: "Sipò Timoun",
        expenseMed: "Depans medikal",
        expenseAnyinfo:
          "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
        removeHoHTitle: "Avètisman",
        removeHoHLabel:
          "W ap eseye efase chèf fwaye a. Si sa kòrèk, ou ka klike sou Kontinye kounye a epi DHR pral kontakte w pou plis enfòmasyon. Si se te yon erè, tanpri klike sou 'Tounen'",
        agreeContent: [
          "Nan siyen, mwen dakò ke:",
          "Mwen te li tout fòm sa a (oswa mwen te fè li l pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite, epi m konprann ke mwen dwe respekte règleman sa yo;",
          "Enfòmasyon m ap bay yo vre epi konplè dapre sa m byen konnen;",
          "M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) nenpòt enfòmasyon mwen bay yo",
        ],
        didReportChanges: "Mwen te rapòte chanjman",
        didNotReportChanges: "Mwen pa t rapòte chanjman ",
        agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
        signatureErrorMsg: "Tanpri siyen Resètifikasyon w lan",
      }; //return End

    default:
      return;
  } // Switch End
} // function End

export function childDependnMultilanguageLabels(name, nextName, language) {
  switch (language) {
    case EN:
      return {
        depedentCostHeader: "Dependent Care Expenses For " + name,
        dependentCostsQoneLabel:
          "How much does " +
          name +
          " pay to the care provider for " +
          nextName +
          "?",
        dependentDriveLabel: "How often does " +name + " drive " +nextName +" to the provider",
        dependentCostsQtwoLabel:
          "Does " + name + " pay transportation cost for " + nextName + "?",
        dependentCoditionalQLabel:
          "How much does " +
          name +
          " pay in transportation costs for " +
          nextName +
          "?",
        driveToQLabel:
          "Does " +
          name +
          " drive " +
          nextName +
          " to and/or from the provider?",
        dependConQuestion:
          "How many providers does " + name + "  drive " + nextName + "  to? ",
        chAdultCareAddrHeader:
          name + "'s Child or Adult Dependent Care address",
        subSectionQuestionLabel:
          "How often does " +
          name +
          " pay for transportation for " +
          nextName +
          " ?",
        childSupptcondQLabel:
          "What is the child support expense for " + name + " ?",
        subSectionQuestionTransportLabel:
          "How often does " + name + " drive " + nextName + " to the provider?",
        providerAddress: "What is the address of the provider ?",
      };
    case ES:
      return {
        depedentCostHeader: "Costos de cuidado de dependiente para " + name,
        dependentCostsQoneLabel:
          "¿Cuánto paga " +
          name +
          "  al proveedor de atención por " +
          nextName +
          "?",
        dependentCostsQtwoLabel:
          "¿" + name + " pagar el costo de transporte por " + nextName + " ?",
        dependentCoditionalQLabel:
          "¿Cuánto paga " + name + " pagar los costos de transporte por " + nextName + "?",
        driveToQLabel:
          "¿ " +
          name +
          " transporta a " +
          nextName +
          " hacia y desde el proveedor?",
        dependConQuestion:
          "¿A cuántos proveedores lleva " + name + "  a " + nextName + "  ? ",
        chAdultCareAddrHeader:
          name + " Costos de cuidado de hijos o adultos dependientes",
        subSectionQuestionTransportLabel:
          "¿Con qué frecuencia " +
          name +
          " lleva a " +
          nextName +
          "al proveedor?",
        subSectionQuestionLabel:
          "¿Con qué frecuencia " +
          name +
          " lleva a " +
          nextName +
          "al proveedor?",
        childSupptcondQLabel:
          "¿Cuál es el costo de manutención para " + name + " ?",
        providerAddress: "¿Cuál es la dirección del proveedor ?",
      };
    case PT:
      return {
        depedentCostHeader: "Custos de Cuidados de Dependentes para " + name,
        dependentCostsQoneLabel:
          "Quanto custa " +
          name +
          "  paga ao prestador de cuidados para " +
          nextName +
          "?",
        dependentCostsQtwoLabel:
          "Quanto " + name + " paga pelo transporte de " + nextName + "?",
        dependentCoditionalQLabel:
          "Quanto " + name + "  paga pelo transporte de " + nextName + " ?",
        driveToQLabel:
          name + " leva " + nextName + " até o prestador de serviço?",
        dependConQuestion:
          "A quantos prestadores de serviço " +
          name +
          "  leva" +
          nextName +
          " ?",
        chAdultCareAddrHeader: name + " Custos de crianças ou dependentes",
        subSectionQuestionTransportLabel:
          "Com qual frequência " +
          name +
          " leva " +
          nextName +
          " para o provedor?",
        subSectionQuestionLabel:
          "Com qual frequência " +
          name +
          " leva " +
          nextName +
          " para o provedor?",
        childSupptcondQLabel:
          "Quais são os custos de suporte infantil de " + name + " ?",
        providerAddress: "Qual é o endereço do provedor ?",
      };
    case ZH:
      return {
        depedentCostHeader: "针对" + name + "的亲属护理费用 ",
        dependentCostsQoneLabel:
          name + "为 " + nextName + " 向护理提供商支付多少费用？",
        dependentCostsQtwoLabel:
          name + " 是否需要支付 " + nextName + " 的交通费？",
        dependentCoditionalQLabel:
          name + " 需要支付多少 " + nextName + " 的交通费？",
        driveToQLabel: name + " 是否开车接送 " + nextName + " 到看护人那里？",
        dependConQuestion:
          name + " 开车送 " + nextName + " 前往多少个提供商处 ？",
        chAdultCareAddrHeader: name + " 子女或成人照护费用",
        subSectionQuestionTransportLabel:
          name + "）开车送 " + nextName + "前往提供商处的频率如何？",
        subSectionQuestionLabel:
          name + "开车送 " + nextName + "前往提供商处的频率如何？",
        childSupptcondQLabel: name + " 的子女抚养费是多少？",
        providerAddress: "该提供商的地址 ？",
      };
    case VI:
      return {
        depedentCostHeader: "Chi phí Chăm sóc Người phụ thuộc cho " + name,
        dependentCostsQoneLabel:
          name +
          "trả nhà cung cấp dịch vụ chăm sóc cho  " +
          nextName +
          " là bao nhiêu?",
        dependentCostsQtwoLabel:
          name + " có thanh toán cho việc chuyên chở " + nextName + " không?",
        dependentCoditionalQLabel:
          name +
          " thanh toán cho việc chuyên chở cho " +
          nextName +
          " bao nhiêu?",
        driveToQLabel:
          name +
          "có lái xe đưa " +
          nextName +
          " đến và / hoặc về từ nhà cung cấp dịch vụ không?",
        dependConQuestion:
          "Có bao nhiêu nhà cung cấp dịch vụ mà " +
          name +
          "  lái xe đưa " +
          nextName +
          " đến?",
        chAdultCareAddrHeader:
          name + " Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
        subSectionQuestionLabel:
          "Bao lâu thì " + name + " chở " + nextName + " đến nhà cung cấp?",
        subSectionQuestionTransportLabel:
          "Bao lâu thì " + name + " chở " + nextName + " đến nhà cung cấp?",
        childSupptcondQLabel:
          "Khoản trợ cấp nuôi con đối với " + name + " là gì?",
        providerAddress: "Địa chỉ của nhà cung cấp là gì ?",
      };
    case HI:
      return {
        depedentCostHeader: "Depans pou Swen Depandan pou " + name,
        dependentCostsQoneLabel:
          "Konbyen " + name + " peye founisè swen pou " + nextName + "?",
        dependentCostsQtwoLabel:
          "Èske " + name + " peye pou transpò pou " + nextName + "?",
        dependentCoditionalQLabel:
          "Konbyen " + name + " peye pou transpò pou " + nextName + "?",
        driveToQLabel:
          "Èske " +
          name +
          " kondi " +
          nextName +
          " pou ale ak/oswa soti lakay founisè a?",
        dependConQuestion:
          "Lakay konbyen founisè " + name + "  kondi " + nextName + "  ale? ",
        chAdultCareAddrHeader:
          name + "Adrès Swen pou Timoun oswa Adilt Depapndan",
        subSectionQuestionLabel:
          "How often does " + name + " peye transpò pou " + nextName + " ?",
        childSupptcondQLabel: "Ki depans sipò timoun " + name + " ?",
        subSectionQuestionTransportLabel:
          "How often does " + name + " drive " + nextName + " to the provider?",
        providerAddress: "Ki adrès founisè a?",
      };
    default:
      return;
  }
}

export function houseHoldMultilanguageLabelsForDependent(name, language) {
  switch (language) {
    case EN:
      return {
        title: "Member " + name,
        header: "About Member " + name,
        conditionalLabel: "Who does " + name + " pay dependent care for?",
      };
    case ES:
      return {
        title: "miembro " + name,
        header: "Acerca de los miembros " + name,
        conditionalLabel:
          "Por quién paga " + name + " el cuidado de dependiente ?",
      };
    case PT:
      return {
        title: "miembro " + name,
        header: "Sobre o Membro " + name,
        conditionalLabel:
          "Para quem " + name + " paga pelos cuidados de dependentes ?",
      };
    case ZH:
      return {
        title: "會員 " + name,
        header: "關於會員 " + name,
        conditionalLabel: name + " 为谁支付亲属护理费用？",
      };
    case VI:
      return {
        title: "thành viên " + name,
        header: "Giới thiệu Thành viên " + name,
        conditionalLabel:
          name + " trả tiền chi phí chăm sóc người phụ thuộc cho ai?",
      };
    case HI:
      return {
        title: "Manm " + name,
        header: "Konsènan Manm " + name,
        conditionalLabel: "Pou ki moun " + name + " peye swen depandan?",
      };

    default:
      return;
  }
}

export function contactMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Contact information",
        header: "My Contact Info",
        addContactInfoTitle: "Change my address",
        addContactInfoHeader: "Change my address",
        contactChangedQuestion:
          "Has there been any change in your contact information? (Required)",
        required: "Required",
        requiredErrorMessage: "Please choose an option.",
        addContactInfoSubHeader:
          'To submit an online SNAP Recertification you must provide a mailing address. If you do not have an address DHR can send mail to, please call Project Bread Food Source Hotline at <a href="tel:18006458333">1-800-645-8333</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR office</a>',
        labelFirstName: "What is your first name?",
        firstNameErrMsg: "Required - Please enter the first name.",
        middleName: "What is your middle name?",
        middleNameErrMsg: "Please enter middle name.",
        lastNamelabel: "What is your last name?",
        lastNameErrMsg: "Required - Please enter the last name.",
        labelSuffix: "Suffix",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "What is your date of birth?",
        dobAlertMsg: "Please enter a valid Date of Birth",
        ssnLabel: "What is your Social Security Number?",
        inputClearLabel: "I don't have one",
        ssnErrMsg: "Please enter a valid Social Security Number.",
        headerMyContactInfo: "My Contact Info",
        houselessLabel: "Is your household homeless?",
        resAddressLabel: "Home Address",
        resSameAddressLabel: "Same as home address",
        mailingAddresssLabel: "Mailing address",
        houselessErrMsg: "Please select whether your having home or not",
        houseLessOptions: ["Yes", "No"],
        addrQuestionlabel: "What is your address?",
        validZipcode: "Please enter a valid Zipcode.",
        alsoMailingLabel: "Is this also your mailing address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your mailing address?",
        zipCodeErrmsg: "Please enter a valid Zipcode.",
        countyErrmsg: "Please enter a valid County.",
        bestPhoneqlabel: "What is the best phone number to reach you?",
        inlineHelp: "This is the number the DHR will use to reach you.",
        validPhoneAlert: "Please enter a valid phone number.",
        editButtonLabel: "Edit",
        doneButtonlabel: "Done",
        phoneEditTitle: "Edit my phone details",
        phoneLabel: "Primary phone number",
        phoneNotificationQuestion: "Allow text message notifications?",
        phoneNotificationAgreeLabel:
          "DHR may be able to send you text messages about due dates, case information, office closings, and other important information.  Click here if you would like to opt-in",
        emailEditTitle: "E-mail",
        emailLabel: "Edit my e-mail address",
        emailNotificationQuestion: "Allow email notifications?",
        emailNotificationAgreeLabel:
          "DHR may be able to send you email about due dates, case information, office closings, and other important information.  Click here if you would like to opt-in",
        emailAddressLabel: "What is your email address?",
        emailErrormsg: "Please enter a valid email address.",
        titleEmergency: "Emergency SNAP benefits",
        headerEmergency: "Emergency SNAP benefits",
        emergencySnap:
          "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
        incomeMoneylabel:
          "Does your income and money in the bank add up to less than your monthly housing expenses?",
        inlineHelpLabel:
          "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
        optionYesNo: ["Yes", "No"],
        alertSelectIncome: "Please select income you pay",
        incomeLesslabel:
          "Is your monthly income less than $150 and is your money in the bank $100 or less?",
        monthlyIncomeoptions: ["Yes", "No"],
        selectIncomeErrmsg: "Please select income you pay",
        migrantLabel:'Are you a migrant worker and is your money in the bank less than or equal to $100?',
        // migrantBefore: "Are you a ",
        // migrantTrigger: "migrant worker",
        // migrantAfter: " and is your money in the bank $100 or less?",
        // migrantHelp:
        //   "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
        migrantOptions: ["Yes", "No"],
        migrantErrmsg: "Please select migrant you pay",
        headerMoreAbout: "More about me",
        areUsBefore: "Are you a ",
        areUsTrigger: "US citizen",
        areUsafter: "?",
        eligibleHelp:
          "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        chooseErrmsg: "Please choose citizen",
        eligibleMemberHelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleOptions: ["Yes", "No"],
        laguageBefore: "What language do you ",
        LANGUAGES: [
          "English",
          "Spanish",
          "Portuguese",
          "French",
          "Haitian Creole",
          "Chinese",
          "Vietnamese",
          "Khmer",
          "Korean",
          "Russian",
          "Italian",
          "Polish",
          "Arabic",
        ],
        languageTrigger: "prefer to speak",
        languageAfter: "?",
        comfortLanHelp:
          "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
        languageErrMsg: "Please choose language",
        genderLabel: "What is your gender?",
        genderOptions: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        labelEthnicity: "What is your ethnicity?",
        helpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "What is your race?",
        raceHelp:
          "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        optionAIdisplay: "American Indian",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Asian",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Black/African American",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Pacific Islander",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "White",
        declineToAnswerLabel: "Decline to answer",
      };

    case ES:
      return {
        title: "Información de contacto",
        header: "Mi información de contacto",
        addContactInfoTitle: "Cambiar mi dirección",
        addContactInfoHeader: "Cambiar mi dirección",
        contactChangedQuestion:
          "¿Se han producido cambios en su información de contacto? (Obligatorio)",
        required: "Obligatorio",
        requiredErrorMessage: "Escoja una opción",
        addContactInfoSubHeader:
          'Para presentar un Recertificación del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de la Fuente de alimentos de Project Bread al<a href="tel:18006458333">1-800-645-8333</a> o diríjase a la oficina local de DHR.',
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        middleName: "¿Cuál es su segundo nombre?",
        middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        lastNamelabel: "¿Cuál es su apellido?",
        lastNameErrMsg: "Requerido: ingrese el apellido",
        labelSuffix: "Sufijo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
        dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
        ssnLabel: "¿Cuál es su número de seguro social?",
        inputClearLabel: "No tengo",
        ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
        headerMyContactInfo: "Mi información de contacto",
        houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
        resAddressLabel: "Dirección residencial",
        resSameAddressLabel: "Igual que el domicilio",
        mailingAddresssLabel: "Dirección postal",
        houselessErrMsg: "Seleccione si tiene o no su hogar",
        houseLessOptions: ["Sí", "No"],
        addrQuestionlabel: "¿Cuál es su dirección?",
        validZipcode: "Por favor ingrese un código postal válido",
        alsoMailingLabel: "¿Es igual a su dirección postal?",
        optionsSendMail: "Sí, enviar correo allí. ",
        optionsNoDiffAddress:
          "No, utilizo una dirección diferente para el correo postal.",
        mailingQlabel: "¿Cuál es su dirección postal?",
        zipCodeErrmsg: "Por favor ingrese un código postal válido",
        bestPhoneqlabel:
          "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
        inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
        validPhoneAlert: "Por favor ingrese un número de teléfono válido",
        editButtonLabel: "Editar",
        doneButtonlabel: "Listo",
        phoneEditTitle: "Editar los detalles de mi teléfono",
        phoneLabel: "Teléfono principal",
        phoneNotificationQuestion:
          "Permitir notificaciones por mensaje de texto?",
        phoneNotificationAgreeLabel:
          "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
        emailEditTitle: "Dirección de correo electrónico",
        emailLabel: "Editar mi dirección de correo electrónico",
        emailNotificationQuestion:
          "Permitir notificaciones por correo electrónico?",
        emailNotificationAgreeLabel:
          "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
        emailAddressLabel: "Dirección de correo electrónico?",
        emailErrormsg:
          "Por favor, introduce una dirección de correo electrónico válida",
        titleEmergency: "Beneficios de SNAP de emergencia",
        headerEmergency: "Beneficios de SNAP de emergencia",
        emergencySnap:
          "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
        incomeMoneylabel:
          "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
        inlineHelpLabel:
          "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
        optionYesNo: ["Sí", "No"],
        alertSelectIncome: "Por favor seleccione el ingreso que paga",
        incomeLesslabel:
          "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
        monthlyIncomeoptions: ["Sí", "No"],
        selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
        migrantBefore: "¿Es usted un ",
        migrantTrigger: " trabajador migrante",
        migrantAfter: " y su dinero en el banco es $100 o menos?",
        migrantHelp:
          "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
        migrantOptions: ["Sí", "No"],
        migrantErrmsg: "Por favor selecciona migrante pagas",
        headerMoreAbout: "Más sobre mí",
        areUsBefore: "¿Es usted ",
        areUsTrigger: "ciudadano de EE. UU.",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
        chooseErrmsg: "Por favor elija ciudadano",
        eligibleMemberHelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        eligibleOptions: ["Sí", "No"],
        LANGUAGES: [
          "Inglés",
          "Español",
          "Portugués",
          "Francés",
          "Criollo haitiano",
          "Chino",
          "Vietnamita",
          "Camboyano",
          "Coreano",
          "Ruso",
          "Italiano",
          "Polaco",
          "Árabe",
        ],
        laguageBefore: "¿Qué idioma ",
        languageTrigger: " prefiere hablar",
        languageAfter: "?",
        comfortLanHelp:
          "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
        languageErrMsg: "Por favor, elija el idioma",
        genderLabel: "¿Cuál es su sexo?",
        genderOptions: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        labelEthnicity: "¿Cuál es tu origen étnico?",
        helpEthnicity:
          "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        hisPanicOption: "Hispano/Latino",
        nonhisPanicOption: "No hispano/Latino",
        raceLabel: "¿Cuál es tu raza?",
        raceHelp:
          "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        optionAIdisplay: "Indio americano",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afroamericano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Isleño del Pacífico",
        optionwhitedisplay_VALUE: "Blanco",
        optionwhitedisplay: "Blanco",
        declineToAnswerLabel: "Me niego a responder",
      };

    case PT: //portugeesh
      return {
        title: "Dados de contato",
        header: "Minhas informações de contato",
        addContactInfoTitle: "Alterar meu endereço",
        addContactInfoHeader: "Alterar meu endereço",
        contactChangedQuestion:
          "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
        required: "Obrigatório",
        requiredErrorMessage: "Por favor escolha uma opção",
        addContactInfoSubHeader:
          'Para enviar um Recertificação SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para a Linha Direta do projeto Project Bread Food Source no número <a href="tel:18006458333">1-800-645-8333</a> ou acesse o escritório local do DHR.',
        labelFirstName: "Qual é o seu primeiro nome?",
        firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
        middleName: "Qual é o seu nome do meio?",
        middleNameErrMsg: "Digite o nome do meio",
        lastNamelabel: "Qual é o seu último nome?",
        lastNameErrMsg: "Obrigatório - Digite o sobrenome",
        labelSuffix: "Sufixo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Qual é a sua data de nascimento?",
        dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
        ssnLabel: "Qual é o número do seu seguro social?",
        inputClearLabel: "Eu não tenho",
        ssnErrMsg: "Digite um Número de Segurança Social válido",
        headerMyContactInfo: "Minhas informações de contato",
        houselessLabel: "Sua família não tem moradia?",
        resAddressLabel: "Endereço residencial",
        resSameAddressLabel: "O mesmo endereço residencial",
        mailingAddresssLabel: "Endereço de correspondência",
        houselessErrMsg: "Selecione se você tem casa ou não",
        houseLessOptions: ["Sim", "Não"],
        addrQuestionlabel: "Qual é o seu endereço?",
        validZipcode: "Por favor insira um código postal válido",
        alsoMailingLabel: "Esse também é o seu endereço de correspondência?",
        optionsSendMail: "Sim, envie as correspondências para esse endereço. ",
        optionsNoDiffAddress:
          "Não, eu uso um endereço diferente para correspondência.",
        mailingQlabel: "Qual é o seu endereço de correspondência? ",
        zipCodeErrmsg: "Por favor insira um código postal válido",
        bestPhoneqlabel:
          "Qual é o melhor número de telefone para falar com você?",
        inlineHelp:
          "Esse será o número que o DHR irá usar para falar com você.",
        validPhoneAlert: "Por favor insira um número de telefone válido",
        editButtonLabel: "Editar",
        doneButtonlabel: "Concluir",
        phoneEditTitle: "Editar of detalhes do meu telefone",
        phoneLabel: "Número de telefone principal",
        phoneNotificationQuestion:
          "Permitir notificações por mensagens de texto?",
        phoneNotificationAgreeLabel:
          "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
        emailEditTitle: "Endereço de e-mail",
        emailLabel: "Editar of detalhes do meu endereçol",
        emailNotificationQuestion: "Permitir notificações por e-mail?",
        emailNotificationAgreeLabel:
          "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
        emailAddressLabel: "Endereço de e-mail",
        emailErrormsg: "Por favor insira um endereço de e-mail válido",
        titleEmergency: "Benefício SNAP emergencial",
        headerEmergency: "Benefício SNAP emergencial",
        emergencySnap:
          "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
        incomeMoneylabel:
          "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
        inlineHelpLabel:
          'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
        optionYesNo: ["Sim", "Não"],
        alertSelectIncome: "Selecione a renda que você paga",
        incomeLesslabel:
          "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
        monthlyIncomeoptions: ["Sim", "Não"],
        selectIncomeErrmsg: "Selecione a renda que você paga",
        migrantBefore: "Você é um ",
        migrantTrigger: "trabalhador migrante",
        migrantAfter: " e tem US$ 100 ou menos no banco?",
        migrantHelp:
          "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
        migrantOptions: ["Sim", "Não"],
        migrantErrmsg: "Selecione o migrante que você paga",
        headerMoreAbout: "Mais sobre mim",
        areUsBefore: "Você é ",
        areUsTrigger: "cidadão americano",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        chooseErrmsg: "Escolha cidadão",
        eligibleMemberHelp:
          "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
        eligibleOptions: ["Sim", "Não"],
        laguageBefore: "Em qual idioma você ",
        LANGUAGES: [
          "Inglês",
          "Espanhol",
          "Português",
          "Francês",
          "Créole haitiano",
          "Chinês",
          "Vietnamita",
          "Khmer",
          "Coreano",
          "Russo",
          "Italiano",
          "Polonês",
          "Árabe",
        ],
        languageTrigger: "prefere se comunicar",
        languageAfter: "?",
        comfortLanHelp:
          "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
        languageErrMsg: "Escolha o idioma",
        genderLabel: "Qual é o seu gênero?",
        genderOptions: ["Masculino", "Feminino"],
        genderErrmsg: "Escolha o gênero",
        labelEthnicity: "Qual é a sua etnia?",
        helpEthnicity:
          "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        raceLabel: "Qual é a sua raça?",
        hisPanicOption: "Hispânico/Latino",
        nonhisPanicOption: "Não-Hispânico/Latino",
        raceHelp:
          "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        optionAIdisplay: "Americano nativo",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afro-americano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Nativo das ilhas do Pacífico",
        optionwhitedisplay_VALUE: "Branco",
        optionwhitedisplay: "Branco",
        declineToAnswerLabel: "Não quero responder",
      };

    case ZH: //China
      return {
        title: "联系信息",
        header: "我的联系信息",
        addContactInfoTitle: "更改我的地址",
        addContactInfoHeader: "更改我的地址",
        contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
        required: "必填",
        requiredErrorMessage: "请选择一个选项",
        addContactInfoSubHeader:
          '如需在线提交 SNAP《开始重新认证》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请致电面包项目食物来源热线 (Project Bread Food Source Hotline) <a href="tel:18006458333">1-800-645-8333</a> 或前往您当地的 DHR 办事处.',
        labelFirstName: "您的名字",
        firstNameErrMsg: "必填 - 請輸入名字",
        middleName: "您的中间名",
        middleNameErrMsg: "請輸入中間名",
        lastNamelabel: "您的姓氏",
        lastNameErrMsg: "必填 - 請輸入姓氏",
        labelSuffix: "后缀",
        suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
        dateOfBirthLabel: "您的出生日期？",
        dobAlertMsg: "請輸入有效的出生日期",
        ssnLabel: "你的社保号码？",
        inputClearLabel: "我没有社保",
        ssnErrMsg: "請輸入有效的社會安全號碼",
        headerMyContactInfo: "我的联系信息",
        houselessLabel: "您的家庭是否无家可归？",
        resAddressLabel: "住宅地址",
        resSameAddressLabel: "与居住地址相同",
        mailingAddresssLabel: "邮寄地址",
        houselessErrMsg: "請選擇您是否有家",
        houseLessOptions: ["是", "否"],
        addrQuestionlabel: "您的家庭地址？",
        validZipcode: "請輸入有效的郵政編碼",
        alsoMailingLabel: "这是否也是您的邮寄地址？",
        optionsSendMail: "是，可以发送信件到这里。",
        optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
        mailingQlabel: "您的邮箱地址？",
        zipCodeErrmsg: "請輸入有效的郵政編碼",
        bestPhoneqlabel: "您最常使用的电话号码？",
        inlineHelp: "这将是DTA与您联络所使用的电话号码。",
        validPhoneAlert: "請輸入一個有效的電話號碼",
        editButtonLabel: "编辑",
        doneButtonlabel: "完成",
        phoneEditTitle: "编辑我的手机详情",
        phoneLabel: "主要电话号码",
        phoneNotificationQuestion: "允许短信通知?",
        phoneNotificationAgreeLabel:
          "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
        emailEditTitle: "电子邮箱地址",
        emailLabel: "编辑我的电子邮件地址",
        emailNotificationQuestion: "允许电子邮件通知?",
        emailNotificationAgreeLabel:
          "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
        emailAddressLabel: "电子邮箱地址？",
        emailErrormsg: "請輸入有效的電子郵件地址",
        titleEmergency: "紧急SNAP补助",
        headerEmergency: "紧急SNAP补助",
        emergencySnap:
          "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
        incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
        inlineHelpLabel:
          "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
        optionYesNo: ["是", "否"],
        alertSelectIncome: "請選擇您支付的收入",
        incomeLesslabel:
          "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
        monthlyIncomeoptions: ["是", "否"],
        selectIncomeErrmsg: "請選擇您支付的收入",
        migrantBefore: "您是否是 ",
        migrantTrigger: "外来务工人员",
        migrantAfter: " 您的银行存款是否少于100美元？",
        migrantHelp:
          "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
        migrantOptions: ["是 ", "否"],
        migrantErrmsg: "請選擇你支付的移民",
        headerMoreAbout: "关于我的更多信息",
        areUsBefore: "您是否是 ",
        areUsTrigger: "美国公民",
        areUsafter: "?",
        eligibleHelp:
          "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        chooseErrmsg: "請選擇公民",
        eligibleMemberHelp:
          "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
        eligibleOptions: ["是", "否"],
        laguageBefore: "您 ",
        LANGUAGES: [
          "英语",
          "西班牙语",
          "葡萄牙语",
          "法语",
          "海地克里奥尔语",
          "中文",
          "越南语",
          "柬埔寨语",
          "朝鮮的",
          "俄语",
          "意大利语",
          "波兰语",
          "阿拉伯语",
        ],
        languageTrigger: "日常使用哪种语言交流",
        languageAfter: "?",
        comfortLanHelp:
          "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
        languageErrMsg: "請選擇語言",
        genderLabel: "您的性别？",
        genderOptions: ["男", "女"],
        genderErrmsg: "請選擇性別",
        labelEthnicity: "您的民族？",
        helpEthnicity:
          "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        hisPanicOption: "西班牙/拉丁美洲",
        nonhisPanicOption: "非西班牙/拉丁美洲",
        raceLabel: "您的种族？",
        raceHelp:
          "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        optionAIdisplay: "美洲印第安人",
        optionAsiandisplay_VALUE: "亚洲人",
        optionAsiandisplay: "亚洲人",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "黑人/非裔美国人",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "太平洋岛民",
        optionwhitedisplay_VALUE: "白人",
        optionwhitedisplay: "白人",
        declineToAnswerLabel: "拒绝回答",
      };

    case VI: //Vietnam
      return {
        title: "Thông tin liên lạc",
        header: "Thông tin liên lạc",
        addContactInfoTitle: "Thay đổi địa chỉ của tôi",
        addContactInfoHeader: "Thay đổi địa chỉ của tôi",
        contactChangedQuestion:
          "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
        required: "Bắt buộc",
        requiredErrorMessage: "Vui lòng chọn một lựa chọn",
        addContactInfoSubHeader:
          'Để gửi Bắt đầu mẫu Chứng nhận lại SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, xin vui lòng gọi Đường dây nóng của Project Bread Food Source theo sốat <a href="tel:18006458333">1-800-645-8333</a> hoặc đến Văn phòng DHR địa phương của quý vị.',
        labelFirstName: "Tên của quý vị?",
        firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
        middleName: "Tên lót của quý vị?",
        middleNameErrMsg: "Vui lòng nhập tên đệm",
        lastNamelabel: "Họ của quý vị?",
        lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
        labelSuffix: "Tước hiệu",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
        dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
        ssnLabel: "Số an sinh xã hội của quý vị?",
        inputClearLabel: "Tôi không có số ASXH",
        ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
        headerMyContactInfo: "Thông tin liên lạc",
        houselessLabel: "Gia đình quý vị có chỗ ở không?",
        resAddressLabel: "Địa chỉ cư trú",
        mailingAddresssLabel: "Địa chỉ gửi thư",
        resSameAddressLabel: "Giống như địa chỉ nhà ở",
        houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        houseLessOptions: ["Có", "Không"],
        addrQuestionlabel: "Địa chỉ của quý vị là gì?",
        validZipcode: "Vui lòng nhập một mã zip hợp lệ",
        alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
        optionsSendMail: "Phải, hãy gửi thư đến đó.",
        optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
        mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
        zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
        bestPhoneqlabel: "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
        inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
        validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
        editButtonLabel: "Chỉnh sửa",
        doneButtonlabel: "Hoàn tất",
        phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
        phoneLabel: "Số điện thoại chính",
        phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
        phoneNotificationAgreeLabel:
          "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
        emailEditTitle: "Địa chỉ email",
        emailLabel: "Chỉnh sửa địa chỉ email của tôi",
        emailNotificationQuestion: "Cho phép thông báo bằng email ?",
        emailNotificationAgreeLabel:
          "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
        emailAddressLabel: "Địa chỉ email?",
        emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
        titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        emergencySnap:
          "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
        incomeMoneylabel:
          "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
        inlineHelpLabel:
          "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
        optionYesNo: ["Có", "Không"],
        alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
        incomeLesslabel:
          "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
        monthlyIncomeoptions: ["Có", "Không"],
        selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
        migrantBefore: "Quý vị có phải là ",
        migrantTrigger: "công nhân di cư",
        migrantAfter:
          " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
        migrantHelp:
          "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
        migrantOptions: ["Có", "Không"],
        migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
        headerMoreAbout: "Thông tin thêm về tôi",
        areUsBefore: "Quý vị có là một ",
        areUsTrigger: "Công dân Hoa kỳ",
        areUsafter: "?",
        eligibleHelp:
          "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        chooseErrmsg: "Vui lòng chọn công dân",
        eligibleMemberHelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        eligibleOptions: ["Có", "Không"],
        laguageBefore: "Quý vị ",
        LANGUAGES: [
          "Tiếng Anh",
          "Tiếng Tây Ban Nha",
          "Tiếng Bồ Đào nha",
          "Tiếng Pháp",
          "Tiếng Haitian Creole",
          "Tiếng Trung quốc",
          "Tiếng Việt",
          "Tiếng Campuchia",
          "Hàn Quốc",
          "Tiếng Nga",
          "Tiếng Ý",
          "Tiếng Ba Lan",
          "Tiếng Ả rập",
        ],
        languageTrigger: " thích nói ngôn ngữ nào hơn",
        languageAfter: "?",
        comfortLanHelp:
          "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
        languageErrMsg: "Vui lòng chọn ngôn ngữ",
        genderLabel: "Giới tính của quý vị là gì?",
        genderOptions: ["Nam", "Nữ"],
        genderErrmsg: "Vui lòng chọn giới tính",
        labelEthnicity: "Quý vị thuộc dân tộc nào?",
        hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
        nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
        helpEthnicity:
          "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        raceLabel: "Quý vị thuộc chủng tộc nào?",
        raceHelp:
          "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        optionAIdisplay: "Người Mỹ bản xứ",
        optionAsiandisplay_VALUE: "Người Châu Á",
        optionAsiandisplay: "Người Châu Á",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
        optionwhitedisplay_VALUE: "Người da trắng",
        optionwhitedisplay: "Người da trắng",
        declineToAnswerLabel: "Từ chối trả lời",
      };
    case HI:
      return {
        title: "Enfòmasyon sou kontak",
        header: "Enfòmasyon kontak mwen",
        addContactInfoTitle: "Chanje adrès mwen",
        addContactInfoHeader: "Chanje adrès mwen",
        contactChangedQuestion:
          "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",
        required: "Obligatwa",
        requiredErrorMessage: "Tanpri chwazi yon opsyon",
        addContactInfoSubHeader:
          'Pou soumèt yon Resètifikasyon SNAP an liy ou dwe bay yon adrès postal. Si w pa gen yon adrès kote DHR ka voye lapòs, tanpri rele Liy Asistans Project Bread Food Source nan <a href="tel:18006458333">1-800-645-8333</a> oswa ale nan <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">biwo lokal DHR </a> lokal w la.',
        labelFirstName: "Ki prenon w?",
        firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
        middleName: "Ki dezyèm prenon w?",
        middleNameErrMsg: "Tanpri antre dezyèm prenon",
        lastNamelabel: "Ki siyati w?",
        lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
        labelSuffix: "Sifiks",
        suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
        dateOfBirthLabel: "Ki dat nesans ou?",
        dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
        ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
        inputClearLabel: "M pa genyen youn",
        ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
        headerMyContactInfo: "Enfòmasyon kontak mwen",
        houselessLabel: "Èske fwaye w la sanzabri?",
        resAddressLabel: "Adrès rezidansyèl",
        resSameAddressLabel: "Menm ak Adrès Rezidansyèl?",
        mailingAddresssLabel: "Adrès postal",
        houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
        houseLessOptions: ["Wi", "Non"],
        addrQuestionlabel: "Ki adrès ou?",
        validZipcode: "Tanpri antre yon kòd postal ki valid",
        alsoMailingLabel: "Èske se adrès postal ou a tou?",
        optionsSendMail: "Wi, voye lapòs la.",
        optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
        mailingQlabel: "Ki adrès postal ou?",
        zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
        bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
        inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
        validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
        editButtonLabel: "Edite",
        doneButtonlabel: "Fini",
        phoneEditTitle: "Edite detay telefòn mwen yo",
        phoneLabel: "Nimewo telefòn prensipal la",
        phoneNotificationQuestion: "Pèmèt notifikasyon mesaj tèks?",
        phoneNotificationAgreeLabel:
          "DHR ka anmezi pou voye mesaj tèks ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe.",
        emailEditTitle: "Imel",
        emailLabel: "Korije adrès imel mwen ",
        emailNotificationQuestion: "Pèmèt notifikasyon imel?",
        emailNotificationAgreeLabel:
          "DHR may be able to send you email about due dates, case information, office closings, and other important information.  Click here if you would like to opt-in",
        emailAddressLabel: "Ki adrès imel ou?",
        emailErrormsg: "Tanpri antre yon adrès imel ki valid",
        titleEmergency: "Benefis SNAP nan Ijans",
        headerEmergency: "Benefis SNAP nan Ijans",
        emergencySnap:
          "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
        incomeMoneylabel:
          "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
        inlineHelpLabel:
          "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
        optionYesNo: ["Wi", "Non"],
        alertSelectIncome: "Tanpri chwazi revni ou peye",
        incomeLesslabel:
          "Èske revni mansyèl ou mwens pase $150 epi èske lajan labank ou se $100 oswa mwens?",
        monthlyIncomeoptions: ["Wi", "Non"],
        selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
        migrantBefore: "Are you a ",
        migrantTrigger: "migrant worker",
        migrantAfter: " and is your money in the bank $100 or less?",
        migrantHelp:
          "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
        migrantOptions: ["Wi", "Non"],
        migrantErrmsg: "Tanpri chwazi migran ou peye",
        headerMoreAbout: "Plis konsènan mwen menm",
        areUsBefore: "Are you a ",
        areUsTrigger: "Sitwayen Ameriken",
        areUsafter: "?",
        eligibleHelp:
          "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        chooseErrmsg: "Tanpri chwazi sitwayen",
        eligibleMemberHelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        eligibleOptions: ["Wi", "Non"],
        LANGUAGES: [
          "English",
          "Panyòl",
          "Pòtigè",
          "Franse",
          "Kreyòl Ayisyen",
          "Chinwa",
          "Vyetnamyen",
          "Kmè",
          "Korean",
          "Ris",
          "Italyen",
          "Polonè",
          "Arabik",
        ],
        languageBefore: "Ki lang ou ",
        languageTrigger: "prefere pale ",
        languageAfter: "?",
        comfortLanHelp:
          " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
        languageErrMsg: "Tanpri chwazi ki lang",
        genderLabel: "Ki sèks ou?",
        genderOptions: ["Gason", "Fi"],
        genderErrmsg: "Tanpri chwazi sèks",
        labelEthnicity: "Ki etnisite ou?",
        helpEthnicity:
          "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "Ki ras ou?",
        raceHelp:
          "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        optionAIdisplay: "Ameriken Endyen",
        optionAsiandisplay_VALUE: "Azyatik",
        optionAsiandisplay: "Azyatik",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Nwa/Afriken Ameriken",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "moun Zile Pasifik",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "Blan",
        declineToAnswerLabel: "Refize reponn",
      };
    default:
      return;
  } //house hold ending ending
}

export function nonCitizenMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "Noncitizen status",
        header: "Noncitizen status",
      };

    case ES:
      return {
        title: "Estado de no ciudadano",
        header: "Estado de no ciudadano",
      };

    case PT: //portugeesh
      return {
        title: "Status de não cidadão",
        header: "Status de não cidadão",
      };

    case ZH: //China
      return {
        title: "非公民状态",
        header: "非公民状态",
      };

    case VI: //Vietnam
      return {
        title: "Tình trạng không phải là công dân",
        header: "Tình trạng không phải là công dân",
      };
    case HI:
      return {
        title: "Estati Non-sitwayen Ameriken",
        header: "Estati Non-sitwayen Ameriken",
      };
    default:
      return;
  } //house hold ending ending
}

export function studentsMultilanguageLabels(language, name) {
  switch (language) {
    case EN:
      return {
        hschool: "High School",
        college: "College",
        studentsTitle: "Students",
        studentsHeader: "Students",
        studentsDesc:
          "If anyone in the household is attending high school or college, please select their name(s) below:",
        noneLabel: "None",
        schoolTypeQ: "Which type of school type " + name + " is attending?",
        schoolNameQ: "What is name of " + name + "'s school?",
        collegeNameQ: "What is name of " + name + "'s college?",
        sasidQ: "What is " + name + "'s SASID?",
      };
    case ES:
      return {
        hschool: "Escuela secundaria",
        college: "Escuela universitaria",
        studentsTitle: "Estudiantes",
        studentsHeader: "Estudiantes",
        studentsDesc:
          "Si algún miembro del grupo familiar asiste a la escuela o la universidad, seleccione su(s) nombre(s) a continuación:",
        noneLabel: "Ninguno",
        schoolTypeQ: "¿A qué tipo de escuela asiste " + name + "?",
        schoolNameQ: "¿Cuál es el nombre de la escuela de " + name + "?",
        collegeNameQ:
          "¿Cuál es el nombre de la escuela universitaria de " + name + " ?",
        sasidQ: "¿Cuál es la SASID de " + name + "?",
      };
    case PT:
      return {
        hschool: "Escola",
        college: "Faculdade",
        studentsTitle: "Estudantes",
        studentsHeader: "Estudantes",
        studentsDesc:
          "Se alguém do domicílio está frequentando o ensino médio ou faculdade, selecione seu(s) nome(s) abaixo:",
        noneLabel: "Nenhum",
        schoolTypeQ: "Que tipo de escola " + name + " está frequentando?",
        schoolNameQ: "Qual é o nome da escola de " + name + "?",
        collegeNameQ: "Qual é o nome da faculdade de " + name + "?",
        sasidQ: "Qual é o SASID de " + name + "?",
      };
    case ZH:
      return {
        hschool: "高中",
        college: "大学",
        studentsTitle: "学生",
        studentsHeader: "学生",
        studentsDesc:
          "如果家庭中有任何人在高中或大学就读，请在下方选择他们的姓名：",
        noneLabel: "无",
        schoolTypeQ: name + "在哪个类型的学校就读?",
        schoolNameQ: name + "的学校名称？",
        collegeNameQ: "的大学名称是" + name + "?",
        sasidQ: name + "的 SASID？",
      };
    case VI:
      return {
        hschool: "Trung học",
        college: "Trường đại học",
        studentsTitle: "Sinh viên",
        studentsHeader: "Sinh viên",
        studentsDesc:
          "Nếu bất cứ thành viên nào trong gia đình đang học trung học hoặc đại học, xin vui lòng chọn (các) tên của họ",
        noneLabel: "Không có",
        schoolTypeQ: "Loại trường nào mà " + name + " đang theo học?",
        schoolNameQ: "Tên của trường mà " + name + " đang học là gì?",
        collegeNameQ: "Tên của trường đại học của " + name + "là gì?",
        sasidQ:
          "Số Nhận Diện Học Sinh Được Tiểu Bang Chỉ Định (SASID) của " +
          name +
          " là gì?",
      };
    case HI:
      return {
        hschool: "Lekòl Segondè",
        college: "Kolèj",
        studentsTitle: "Etidyan",
        studentsHeader: "Etidyan",
        studentsDesc:
          "Si yon moun nan fwaye nan lekòl segondè oswa kolèj, tanpri chwazi non(yo) anba a:",
        noneLabel: "Okenn",
        schoolTypeQ: "Ki tip lekòl " + name + " frekante?",
        schoolNameQ: "Ki non lekòl " + name + " la?",
        collegeNameQ: "Ki non kolèj " + name + " la?",
        sasidQ: "Ki SASID " + name + "?",
      };
    default:
      return;
  }
}

export function houseHoldMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        houseTitle: "Household members",
        houseHeader: "About My Household",
        howManyLabel: "How many people live in your household, including you?",
        houseHelp:
          "If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.",
        houseErrMsg: "Please choose no.of household",
        houseMemberTitle: "Add a new household member",
        houseMemberHeader: "Your first household member is ",
        houseFirstName: "What is this person's first name?",
        firstErrmsg: "Please enter the first name.",
        houseMiddleLabel: "What is this person's middle name?",
        houseMiddleName: "Please enter middle name",
        houseLastNameLabel: "What is this person's last name?",
        enterLastname: "Please enter the last name.",
        enterDOB: "Please enter a valid date of birth.",
        suffixLabel: "Suffix",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "What is this person's relationship to you?",
        spouseDisplay: "Spouse",
        motherDisplay: "Mother",
        faterDisplay: "Father",
        daughterDisplay: "Daughter",
        sonDisplay: "Son",
        brotherDisplay: "Brother",
        sisterDisplay: "Sister",
        grandMaDisplay: "Grandmother",
        grandFaDisplay: "Grandfather",
        grandDaugther: "Granddaughter",
        grandSon: "Grandson",
        otherDisplay: "Other",
        houseDobLabel: "What is this person's date of birth?",
        personGenLabel: "What is this person's gender?",
        genderOptios: ["Male", "Female"],
        citiBeforeQLabel: "Is this person a ",
        citiTriggerQLabel: "US citizen",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        citizenInhelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        citizenOptions: ["Yes", "No"],
        ssnQLabel: "What is this person's Social Security Number?",
        inlineHelpSSN:
          "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
        ssnDntLabel: "Doesn't have one",
        ssnErrLabel: "Please enter a valid Social Security Number.",
        hholdHeader: "Household details",
        disabilityQLabel: "Does anyone in the house have a physical or mental ",
        disabilityQlabel2: "",
        disabilityQTriggerLabel: "disability",
        disabilityHelp:
          "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
        disablilityCler: "None",
        buyFoodLabel: "Do you regularly buy and make food together?",
        buyFoodOptions: ["Yes", "No"],
        personIncomLabel: "Does this person have any income?",
        personIncomeOptions: ["Yes", "No"],
        headTitle: "Head of Household",
        removeButtonLabel: "Remove",
        undoButtonLabel: "Undo",
        changedLabel: "Changed",
        removedLabel: "Removed",
        newLabel: "New",
        membersTitle: "Members of household",
        addMemberButtonLabel: "Add a new household member",
        householdChangedQuestionLabel:
          "Has anyone joined or left your household?",
      };

    case ES: //spanish
      return {
        houseTitle: "Miembros del grupo familiar ",
        membersTitle: "Miembros del grupo familiar",
        houseHeader: "Acerca de mi grupo familiar",
        howManyLabel:
          "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
        houseHelp:
          "Si compra y prepara más de 2/3 de sus comidas con otras personas, esas personas deben estar en su núcleo familiar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su núcleo familiar aunque usted no compre y prepare comidas con ellos.",
        houseErrMsg: "Por favor, elija no.of hogar",
        houseMemberTitle: "Agregar un nuevo miembro al grupo familiar",
        houseMemberHeader: "Su primer miembro del hogar es ",
        houseFirstName: "¿Cuál es el nombre de esta persona?",
        firstErrmsg: "Por favor, introduzca el primer nombre",
        buyFoodOptions: ["Sí", "No"],
        personIncomLabel: "¿Esta persona tiene ingresos?",
        personIncomeOptions: ["Sí", "No"],
        headTitle: "Jefe/a de familia",
        houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
        houseMiddleName: "Por favor ingrese un segundo nombre",
        houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
        enterLastname: "Por favor ingrese el apellido",
        enterDOB: "Ingrese una fecha de nacimiento válida",
        suffixLabel: "Sufijo",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "¿Cuál es la relación entre usted y de esta persona?",
        spouseDisplay: "Cónyuge",
        motherDisplay: "Madre",
        faterDisplay: "Padre",
        daughterDisplay: "Hija",
        sonDisplay: "Hijo",
        brotherDisplay: "Hermano",
        sisterDisplay: "Hermana",
        grandMaDisplay: "Abuela",
        grandFaDisplay: "Abuelo",
        grandDaugther: "Nieta",
        grandSon: "Nieto",
        addMemberButtonLabel: "Agregar un nuevo miembro al grupo familiar",
        otherDisplay: "Otro",
        houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        personGenLabel: "¿Cuál es el género de esta persona?",
        genderOptios: ["Masculino", "Femenino"],
        citiBeforeQLabel: "¿Es esta persona ",
        citiTriggerQLabel: "ciudadano/a estadounidense",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
        citizenInhelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        citizenOptions: ["Sí", "No"],
        ssnQLabel: "¿Cuál es el número de seguro social de esta persona?",
        ssnDntLabel: "No tiene",
        ssnErrLabel: "Ingrese un Número de Seguro Social válido.",
        hholdHeader: "Detalles de mi núcleo familiar",
        disabilityQLabel: "¿Alguna persona tiene una ",
        disabilityQlabel2: " mental o física ",
        disabilityQTriggerLabel: "discapacidad",
        disabilityHelp:
          "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
        disablilityCler: "Ninguno",
        inlineHelpSSN:
          "Según las regulaciones federales, los no ciudadanos que no están solicitando SNAP no necesitan dar su SSN o estado de ciudadanía.",
        removeButtonLabel: "Eliminar",
        undoButtonLabel: "Deshacer",
        changedLabel: "Modificadoe",
        removedLabel: "Eliminado",
        newLabel: "Nuevo",
        householdChangedQuestionLabel:
          "¿Alguien se ha unido o ha dejado su hogar?",
        buyFoodLabel: "¿Normalmente compran alimentos y cocinan juntos?",
      };

    case PT: //portugeesh
      return {
        houseTitle: "Membros do domicílio",
        houseHeader: "Sobre o meu domicílio",
        howManyLabel: "Quantas pessoas compõem a sua família, incluindo você?",
        houseHelp:
          "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
        houseErrMsg: "Por favor, escolha o número de casa",
        houseMemberTitle: "Adicionar um novo membro ao domicílio",
        houseMemberHeader: "Seu primeiro membro da família é ",
        houseFirstName: "Qual é o primeiro nome dessa pessoa?",
        firstErrmsg: "Digite o primeiro nome",
        houseMiddleLabel: "Qual é o nome do meio dessa pessoa?",
        houseMiddleName: "Digite o nome do meio",
        houseLastNameLabel: "Qual é o último nome dessa pessoa?",
        enterLastname: "Digite o sobrenome",
        enterDOB: "Insira uma data de nascimento válida",
        suffixLabel: "Sufixo",
        buyFoodOptions: ["Sim", "Não"],
        personIncomLabel: "Essa pessoa tem algum rendimento?",
        personIncomeOptions: ["Sim", "Não"],
        headTitle: "Chefe do domicílio",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "Qual é o relacionamento dessa pessoa com você?",
        spouseDisplay: "Cônjuge",
        motherDisplay: "Mãe",
        faterDisplay: "Pai",
        daughterDisplay: "Filha",
        sonDisplay: "Filho",
        brotherDisplay: "Irmão",
        sisterDisplay: "Irmã",
        grandMaDisplay: "Avó",
        addMemberButtonLabel: "Adicionar um novo membro ao domicílio",
        grandFaDisplay: "Avô",
        grandDaugther: "Neta",
        grandSon: "Neto",
        otherDisplay: "Outros",
        houseDobLabel: "Qual é a data de nascimento desta pessoa?",
        personGenLabel: "Qual é o sexo dessa pessoa?",
        genderOptios: ["Masculino", "Feminino"],
        citiBeforeQLabel: "Esta pessoa é um ",
        citiTriggerQLabel: "cidadão americano ",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        citizenInhelp:
          "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
        citizenOptions: ["Sim", "Não"],
        ssnQLabel: "Qual é o número do seguro social dessa pessoa?",
        ssnDntLabel: "Não tem",
        ssnErrLabel: "Digite um Número de Segurança Social válido",
        hholdHeader: "Informações da minha família",
        disabilityQLabel: "Alguém sofre de algum tipo de deficiência ",
        disabilityQlabel2: " física ou mental ",
        disabilityQTriggerLabel: "deficiência",
        disabilityHelp:
          "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
        disablilityCler: "Nenhum",
        inlineHelpSSN:
          "De acordo com as regulamentações federais, os não-cidadãos que não solicitam o SNAP não precisam fornecer seu status de SSN ou de cidadania.",
        removeButtonLabel: "Excluir",
        membersTitle: "Membros da família",
        undoButtonLabel: "Desfazer",
        changedLabel: "Alterado",
        removedLabel: "excluído",
        newLabel: "Novo",
        householdChangedQuestionLabel: "Alguém se juntou ou saiu da sua casa?",
        buyFoodLabel: "Vocês compram e fazem comida juntos regularmente?",
      };

    case ZH: //China
      return {
        houseTitle: "家庭成员",
        houseHeader: "关于我的家庭",
        howManyLabel: "包括您自己在内，您家庭中有几人？",
        houseHelp:
          "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭",
        houseErrMsg: "請選擇家庭數量",
        houseMemberTitle: "新增家庭成员",
        houseMemberHeader: "你的第一個家庭成員是 ",
        houseFirstName: "此人的名字？",
        firstErrmsg: "請輸入名字",
        houseMiddleLabel: "此人的中间名?",
        membersTitle: "家庭成员",
        buyFoodOptions: ["是", "否"],
        personIncomLabel: "此人是否有收入?",
        personIncomeOptions: ["是", "否"],
        headTitle: "户主",
        houseMiddleName: "請輸入中間名",
        houseLastNameLabel: "此人的姓氏？",
        addMemberButtonLabel: "新增家庭成员",
        enterLastname: "請輸入姓氏",
        enterDOB: "请输入有效出生日期",
        suffixLabel: "后缀",
        SuffixOptions: ["一世", "II", "III", "IV", "V", "小", "高級"],
        relationQlabel: "此人与您的关系是？",
        spouseDisplay: "配偶",
        motherDisplay: "母亲",
        faterDisplay: "父亲",
        daughterDisplay: "女儿",
        sonDisplay: "儿子",
        brotherDisplay: "兄弟",
        sisterDisplay: "姐妹",
        grandMaDisplay: "祖母",
        grandFaDisplay: "祖父",
        grandDaugther: "（外）孙女",
        grandSon: "（外）孙子",
        otherDisplay: "其他",
        houseDobLabel: "此人的出生日期是？ ",
        personGenLabel: "此人的性别是？",
        genderOptios: ["男", "女"],
        citiBeforeQLabel: "此人是否是 ",
        citiTriggerQLabel: "美国公民",
        citiAfterQLabel: "?",
        citizenHelp:
          " '要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        citizenInhelp: "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
        citizenOptions: ["是", "否"],
        ssnQLabel: "此人的社会安全号码是？",
        ssnDntLabel: "无社会安全号码",
        ssnErrLabel: "請輸入有效的社會安全號碼",
        hholdHeader: "家庭详细情况",
        disabilityQLabel: "是否有人患有身体或精神 ",
        disabilityQlabel2: "",
        disabilityQTriggerLabel: "残疾",
        disabilityHelp:
          "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾",
        disablilityCler: "无",
        inlineHelpSSN:
          "根据联邦法规，未申请SNAP的非公民不需要提供其SSN或公民身份",
        removeButtonLabel: "删除",
        undoButtonLabel: "撤销",
        changedLabel: "已变化",
        removedLabel: "已搬迁",
        newLabel: "新添内容",
        householdChangedQuestionLabel: "有没有人加入或离开你的家庭？ ",
        buyFoodLabel: "你们是否定期一起购买并制作食物？",
      };

    case VI: //Vietnam
      return {
        houseTitle: "Các thành viên trong hộ gia đình",
        houseHeader: "Về hộ gia đình của tôi",
        howManyLabel:
          "Có bao nhiêu người sống trong hộ gia đình của quý vị, kể cả quý vị?",
        houseHelp:
          "Nếu quý vị mua và nấu hơn 2/3 bữa ăn của quý vị với những người khác, họ phải ở trong hộ gia đình của quý vị. Nếu vợ / chồng hoặc con của quý vị dưới 22 tuổi sống chung với quý vị, họ phải ở trong hộ gia đình của quý vị ngay cả khi quý vị không mua và nấu bữa ăn cùng với họ",
        houseErrMsg: "Vui lòng chọn số hộ gia đình",
        houseMemberTitle: "Bổ sung một thành viên mới trong gia đình",
        houseMemberHeader: "Thành viên gia đình đầu tiên của bạn là ",
        houseFirstName: "Tên của người này là gì?",
        firstErrmsg: "Vui lòng nhập tên",
        houseMiddleLabel: "Tên lót của người này là gì?",
        houseMiddleName: "Vui lòng nhập tên đệm",
        houseLastNameLabel: "Họ của người này là gì?",
        enterLastname: "Vui lòng nhập họ",
        enterDOB: "Điền ngày sinh",
        suffixLabel: "Tước hiệu",
        addMemberButtonLabel: "Bổ sung một thành viên mới trong gia đình",
        membersTitle: "Thành viên hộ gia đình",
        buyFoodOptions: ["Có", "Không"],
        personIncomLabel: "Người này có thu nhập không",
        personIncomeOptions: ["Có", "Không"],
        headTitle: "Chủ hộ",
        SuffixOptions: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        relationQlabel: "Mối quan hệ của người này với quý vị là gì?",
        spouseDisplay: "Vợ / chồng",
        motherDisplay: "Mẹ",
        faterDisplay: "Cha",
        daughterDisplay: "Con gái",
        sonDisplay: "Con trai",
        brotherDisplay: "Em trai",
        sisterDisplay: "Em gái",
        grandMaDisplay: "Bà ",
        grandFaDisplay: "Ông ",
        grandDaugther: "Cháu gái",
        grandSon: "Cháu trai (nội, ngoại)",
        otherDisplay: "Khác",
        houseDobLabel: "Ngày tháng năm sinh của người này là gì?",
        personGenLabel: "Giới tính của người này là gì?",
        genderOptios: ["Nam", "Nữ"],
        citiBeforeQLabel: "Người này có phải là ",
        citiTriggerQLabel: "Công dân Hoa Kỳ không",
        citiAfterQLabel: "?",
        citizenHelp:
          " 'Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        citizenInhelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        citizenOptions: ["Có", "Không"],
        ssnQLabel: "Số An sinh Xã hội của người này là gì?",
        ssnDntLabel: "Không có",
        ssnErrLabel: "Vui lòng nhập Số an sinh xã hội hợp lệ",
        hholdHeader: "Chi tiết về hộ gia đình",
        disabilityQLabel: "Có ai bị ",
        disabilityQlabel2: " về thể chất hoặc tinh thần không ",
        disabilityQTriggerLabel: "khuyết tật",
        disabilityHelp:
          "Một người khuyết tật phải được liên bang xác nhận để được xem xét hưởng trợ cấp SNAP. Ví dụ, người người nhận SSI là người khuyết tật được liên bang xác nhận",
        disablilityCler: "Không có",
        inlineHelpSSN:
          "Theo quy định của Liên bang, những người không phải là công dân không đăng ký SNAP không cần phải cung cấp SSN hoặc tư cách công dân của họ.",
        removeButtonLabel: "Xóa",
        undoButtonLabel: "Hủy bỏ",
        changedLabel: "thay đổi",
        removedLabel: "xóa bỏ",
        newLabel: "mới",
        buyFoodLabel:
          "Quý vị có thường xuyên mua thức ăn và nấu ăn cùng nhau không?",
        householdChangedQuestionLabel:
          "Có ai tham gia hoặc rời khỏi hộ gia đình của bạn?",
      };
    case HI:
      return {
        houseTitle: "Manm fwaye a",
        houseHeader: "Konsènan Kay mwen an",
        howManyLabel: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
        houseHelp:
          "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
        houseErrMsg: "Tanpri chwazi kantite moun nan fwaye",
        houseMemberTitle: "Ajoute yon nouvo manm fwaye",
        houseMemberHeader: "Premye manm nan fwaye w la se ",
        houseFirstName: "Ki prenon moun sa a?",
        firstErrmsg: "Tanpri antre prenon",
        houseMiddleLabel: "Ki dezyèm prenon moun sa a?",
        houseMiddleName: "Tanpri antre dezyèm prenon",
        houseLastNameLabel: "Ki siyati moun sa a?",
        enterLastname: "Tanpri antre siyati",
        enterDOB: "Tanpri antre yon dat nesans ki valid",
        suffixLabel: "Sifiks",
        SuffixOptions: ["I", "II", "III", "4", "5", "Fis", "Papa"],
        relationQlabel: "Ki relasyon moun sa genyen ak ou?",
        spouseDisplay: "Konjwen",
        motherDisplay: "Manman",
        faterDisplay: "Papa",
        daughterDisplay: "Pitit fi",
        sonDisplay: "Pitit gason",
        brotherDisplay: "Frè",
        sisterDisplay: "Sè",
        grandMaDisplay: "Grann",
        grandFaDisplay: "Granpè",
        grandDaugther: "Pitit pitit fi",
        grandSon: "Pitit pitit gason",
        otherDisplay: "Lòt",
        houseDobLabel: "Ki dat nesans moun sa a?",
        personGenLabel: "Ki sèks moun sa a?",
        genderOptios: ["Gason", "Fi"],
        citiBeforeQLabel: "Èske Moun sa a se yon ",
        citiTriggerQLabel: "Sitwayen Ameriken",
        citiAfterQLabel: "?",
        citizenHelp:
          "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        citizenInhelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        citizenOptions: ["Wi", "Non"],
        ssnQLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
        inlineHelpSSN:
          "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
        ssnDntLabel: "Li pa gen youn",
        ssnErrLabel: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
        hholdHeader: "Detay Fwaye",
        disabilityQLabel: "Èske nenpòt moun nan fwaye a gen yon ",
        disabilityQlabel2: " fizik oswa mantal",
        disabilityQTriggerLabel: "andikap",
        disabilityHelp:
          "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
        disablilityCler: "Okenn",
        buyFoodLabel: "Èske ou regilyèman achte epi prepare repa ansanm?",
        buyFoodOptions: ["Wi", "Non"],
        personIncomLabel: "Èske moun sa a gen revni?",
        personIncomeOptions: ["Wi", "Non"],
        headTitle: "Chèf Fwaye a",
        removeButtonLabel: "Retire",
        undoButtonLabel: "Defèt",
        changedLabel: "Chanje",
        removedLabel: "Retire",
        newLabel: "Nouvo",
        membersTitle: "Manm fwaye a",
        addMemberButtonLabel: "Ajoute yon nouvo manm fwaye",
        householdChangedQuestionLabel:
          "Èske nenpòt moun vin jwenn oswa kite fwaye w la?",
      };
    default:
      return;
  } //house hold ending
}

export function dependentCareCostMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        dependentCareCostTitle: "Child or Adult Dependent Care Expenses",
        dependentCareCostHeader: "Tell us about your dependent care Expenses",
        dependentCareCostSubHeader:
          "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
      };
    case ES:
      return {
        dependentCareCostTitle: "Gastos de cuidado de niños o adultos dependientes",
        dependentCareCostHeader:
          "Infórmenos sobre los costos del cuidado de dependientes",
        dependentCareCostSubHeader:
          "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
      };
    case PT:
      return {
        dependentCareCostTitle: "Custos de cuidados dependentes",
        dependentCareCostHeader:
          "Conte-nos sobre seus gastos com cuidados de dependentes",
        dependentCareCostSubHeader:
          "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
      };
    case ZH:
      return {
        dependentCareCostTitle: "亲属护理费用",
        dependentCareCostHeader: "告诉我们您的亲属护理费用",
        dependentCareCostSubHeader:
          "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
      };
    case VI:
      return {
        dependentCareCostTitle: "Chi phí chăm sóc người phụ thuộc",
        dependentCareCostHeader:
          "Hãy cho chúng tôi biết về chi phí chăm sóc người phụ thuộc của bạn",
        dependentCareCostSubHeader:
          "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
      };
    case HI:
      return {
        dependentCareCostTitle: "Depans pou swen depandan",
        dependentCareCostHeader:
          "Pale nou konsènan depans pou swen depandan w yo",
        dependentCareCostSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
      };
    default:
  }
}

//incomeMultilanguageLabels
export function incomeSectionMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        incomeTitle: "Noncitizen status",
        incomeHeader: "About your household's income",
        aboutIncomeBefore: "Does anyone in the household receive any ",
        aboutIncomeTrigger: "income or benefits",
        incomeHelp:
          "Income is money you receive from working. Benefits are money you receive from a source other than employment.",
        clearOption: "None",
        combineIncomeoptions: [
          "Wages",
          "Self-Employment",
          "Work Study",
          "SSI (Supplemental Security Income)",
          "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
          "Chomaj",
          "Sipò Timoun",
          "Pansyon",
          "Benefis pou Veteran",
          "Revni Lwaye",
          "Konpansasyon Travayè",
          "Other",
        ],
        wageIncomeBeforeLabel: "What is the ",
        wageIncomeTriggerLabel: "gross income",
        wagesTargetValue: "Wages",
        selfemeploymentTargetValue: "Self-Employment",
        workStudyTargetValue: "Work Study",
        rsdiTargetValue:
          "RSDI (Retirement, Survivors, and Disability Insurance)",
        SSITargetValue: "SSI (Supplemental Security Income)",
        unEmploymentTargetValue: "Unemployment",
        childSuppTargetValue: "Child Support",
        pensionTargetValue: "Pension",
        veteranBenefitsTargetValue: "Veterans Benefits",
        rentalIncomeTargetValue: "Income from Land Rental or Rental Property",
        workerCompTargetValue: "Workers Compensation",
        otherTargetValue: "Other",
        noneTargetValue: "None",

        mortageInlineHelpText:
          "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
        grossIncomeHelp:
          "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
        unitsPerLabel: " ",
        unitsOptions: ["Month", "Every two weeks", "Week"],
        labelOtherwage: "Add another Wage",
        adsSelfEmployee: "Add another Self-Employment",
        condWorkStudy: "Work Study",
        workStudyLabel: "Add another Work Study",
        anotherSSILabel: "Add another SSI",
        addRDILabel: "Add another RSDI",
        unEmpLabel: "Add another Unemployment",
        childSuppLabel: "Child Support",
        anotherChildsupport: "Add another Child Support",
        pensionLabel: "Pension",
        anotherPensionLabel: "Add another Pension",
        workerCompensationLabel: "Add another Worker's Compensation",
        veteranBenefit: "Veteran's Benefits",
        addAnotherVBenefitLabel: "Add another Veteran's Benefits",
        rentIncomeLabel: "Rental Income",
        repeatQuestionLabel: "Add another Rental Income",
        repeatincomeQuestionLabel: "Add another Rental Hours",
        unitsRentalOptions: ["Month", "Week"],
        questionButtonLabel: "Add another Other",
        incomeSummaryTitle: "Income summary",
        incomeSummaryHeader: "Income summary",
        expenseTitle: "Expenses",
        almostLabel: "Almost there!",
        housingLabel: "Housing costs",
        utilityLabel: "Utility costs",
        utilityHeader: "Tell us about your utility costs",
        expenseHeader: "Tell us about your shelter costs",
        expenseSubHeader:
          "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
        houseHoldQLabel: "Is your household responsible for any utilities?",
        utilityQOptions: [
          "Heat (oil, gas, electricity or propane, etc.)",
          "Electricity for an air conditioner",
          "A fee to use an air conditioner",
          "Electricity and/or gas",
          "Phone or cell phone service",
        ],
        utilitiesClearOption: "None",
        utilitiesDoNotPayOption: "I do not pay for any utility costs",
        shelterDoNotPayOption: "I do not pay for any shelter costs",
        houseQLabelBefore: "What type(s) of ",
        houseQLabelTrigger: "shelter costs",
        houseQLabelAfter: " does your household has?",
        houseQLabelHelp:
          "Examples of shelter costs include rent, mortgage, property taxes, condo fees, and/or home insurance.",
        utilityQLabelBefore: "What type(s) of ",
        utilityQLabelTrigger: "utility costs",
        utilityQLabelAfter: " does your household has?",
        utilityQLabelHelp:
          "Utility costs include heat, electricity, and/or phone services.",
        houseQOptions: [
          "Rent",
          "Mortgage",
          "Property taxes",
          "Home insurance",
          "Condo fees",
        ],
        houseRentQLabel: "How much is your household's rent?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "per",
        houseUnitOptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        mortageQLabel: "How much is your household's mortgage?",
        mortageunitBetween: " ",
        mortageQoptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        mortageProptaxLabel: "How much is your household's property taxes?",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        insuranceCostQLabel:
          "How much is your household's home insurance costs?",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        insuranceClearOption: "No. I don't need to pay for this.",
        condofeeQLabel: "How much is your household's condo fee?",
        condofeepropBetween: "per",
        condofeeCostoptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        condofeeClearOption: "No. I don't need to pay for this.",
        propTaxQLabel: "Does your household have property taxes?",
        propTaxBetween: "per",
        propTaxOptions: ["Week", "Month", "Year"],
        propTaxClearOption: "No. I don't need to pay for this.",
        homeInsuranceQLabel: "Does your household have home insurance costs?",
        homeInsuranceBetween: "per",
        homeInsuranceUnits: ["Week", "Month", "Year"],
        homeInsuranceClearOption: "No. I don't need to pay for this.",
        condoFeeQLabel: "Does your household have a condo fee?",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["Week", "Month", "Year"],
        condoFeeClearOption: "No. I don't need to pay for this.",

        //new labels:
        anyUtilQcost: "Does your household have any utility costs?",
        chooseHoldErrmsg: "Please choose household utilities",
        houseCostErrmsg: "Please choose housing costs",
        rentErrMsg: "Please enter rent",
        anotherRentLabel: "+ Add another rent",
        mortageErrLabel: "Please enter mortgage",
        propTaxLabel: "Please enter property taxes",
        propTaxButtonLabel: "+ Add another property taxes",
        homeInsurbutton: "+ Add another home insurance",
        homeInsurErrmsg: "Please enter home insurance",
        condoButtonLabel: "+ Add another condo fee",
        condoFeeErrmsg: "Please enter condo fee",
        addMortageButtonLabel: "+ Add another mortgage",
        childTitle: "Child or Adult Dependent Care Costs",
        childCostHeader:
          "Tell us about your Child or Adult Dependent Care costs",
        childCostSubHeader:
          "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
        careGiveObjbefore1: "Does anyone in the household pay child or adult ",
        careGiveObjtrigger1: "dependent care Expenses",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "This includes the costs to ",
        careGiveObjtrigger2: "transport",
        careGiveObjAfter2: " the dependent(s) to and/or from a care provider.",
        careGiveHelp1:
          "Dependent care costs include any payment you make for someone to watch and/or transport a child or adult who requires care.",
        careGiveHelp2:
          "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
        questionClearOption: "None",
        kidsandadultSubheader:
          "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
        costBetween: " ",
        weekMonthOptions: ["Month", "Week"],
        costOptions: ["Week", "Month", "Year"],
        yesnoOptions: ["Yes", "No"],
        targetValueYes: "Yes",
        noofAppointment: "Please choose no.of appointments",
        addressProvider: "What is the address of the provider?",
        freqMonthoption: ["Monthly"],
        freqTripoptions: [
          "Annual",
          "Bimonthly (every two months)",
          "Biweekly (every two weeks)",
          "Monthly",
          "Quarterly",
          "Semiannual (twice a year)",
          "Semimonthly (twice a month)",
          "Weekly",
        ],
        unitMonthTargetValue: { unit: "Monthly" },
        tripCountErrMsgLen:
          "Please enter less than/equal to 6 characters for trip count",
        tripCostErrMsgLen:
          "Please enter less than/equal to 6 characters for trip cost",
        childSupTitle: "Child Support Costs",
        supportCostHeader: "Child Support Costs",
        childSupportQlegalbefore:
          "Does anyone in the household have a legal obligation (court order) to pay ",
        childSupportQlegaltrigger: "child support",
        childSupportQlegalAfter: " to a child not living with you?",
        childSupportHelp:
          "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
        medicalTitle: "Medical costs",
        medicalInlineSeethisFull: "See this full list of",
        medicalInlineEligibleMedical: " eligible medical expenses",
        medicalHeader: "Tell us about your medical costs",
        medicalSubHeader:
          "Please tell us all of the utilities you are responsible to pay for, even we already have them on file. You may include expenses even if you are behind or not able to pay them. ",
        medicalLabel1:
          "If no one in your household is disabled or at least 60 years old, please skip this section.",
        medicalExpBefore:
          "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
        medicalExpTrigger: " transportation",
        medicalExpAfter:
          ", over the counter medications, dental or eye care, adult diapers, etc.?",
        medicalExphelp:
          "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
        medicalInhelp: "See this full list of",
        medicalInhelpEligible: "eligible medical expenses",
        medicalAddress:
          "What is the address of the medical appointment or pharmacy?",
        zipErrmsg: "Please enter a valid Zipcode.",
        signSubmitLabel: "Sign & submit",
        signSubmitHeader: "Sign & submit",
        signSubmitSub: "You made it through the form! Now for the easy stuff.",
        subSectionLabel: "Submitted",
        submitLabelFinal: "Your application has been submitted!",
        tellusAbout: "Tell us about your Child support costs",
        moneyYougettitle:
          "This includes money you may get from a job, the government, or other sources.",
        submitSummaryHeader: "Your Recertification has been submitted",
      };
    case ES: //SPANISH
      return {
        incomeTitle: "Estado de no ciudadano",
        incomeHeader: "Sobre el ingreso de su núcleo familiar",
        aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
        aboutIncomeTrigger: "ingresos o beneficios?",
        incomeHelp:
          "El ingreso es dinero que usted recibe por trabajar. Los beneficios son dinero que usted recibe de una fuente distinta a un empleo.",
        clearOption: "Ninguno",
        combineIncomeoptions: [
          "Salarios",
          "Trabajo independiente",
          "Trabajo estudio",
          "SSI (Ingreso de seguridad suplementario)",
          "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
          "Desempleo",
          "Manutención",
          "Pensión",
          "Beneficios de veteranos",
          "Ingreso por alquiler",
          "Compensación a los trabajadores",
          "Otro",
        ],
        wageIncomeBeforeLabel: "Cuál es el ",
        wageIncomeTriggerLabel: "ingresos brutos",
        wagesTargetValue: "Salarios",
        selfemeploymentTargetValue: "Trabajo independiente",
        workStudyTargetValue: "Trabajo estudio",
        rsdiTargetValue:
          "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
        SSITargetValue: "SSI (Ingreso de seguridad suplementario)",
        unEmploymentTargetValue: "Desempleo",
        childSuppTargetValue: "Manutención",
        pensionTargetValue: "Pensión",
        veteranBenefitsTargetValue: "Beneficios de veteranos",
        rentalIncomeTargetValue: "Ingreso por alquiler",
        workerCompTargetValue: "Compensación a los trabajadores",
        otherTargetValue: "Otro",
        noneTargetValue: "Ninguno",
        mortageInlineHelpText:
          "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
        grossIncomeHelp:
          "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
        unitsPerLabel: " ",
        unitsOptions: ["Mes", "Cada dos semanas", "Semana"],
        labelOtherwage: "Agrega otro Salarios",
        adsSelfEmployee: "Agrega otro Trabajo independiente",
        condWorkStudy: "Trabajo estudio",
        workStudyLabel: "Agrega otro Trabajo estudio",
        anotherSSILabel: "Agrega otro SSI",
        addRDILabel: "Agregue otro RSDI",
        unEmpLabel: "Agrega otro Desempleo",
        childSuppLabel: "Manutención",
        anotherChildsupport: "Agrega otro Manutención",
        pensionLabel: "Pensión",
        anotherPensionLabel: "Agrega otro Pensión",
        workerCompensationLabel: "Agregue otra Compensación para Trabajadores",
        veteranBenefit: "Beneficios de veteranos",
        addAnotherVBenefitLabel: "Agrega otro Beneficios de veteranos",
        rentIncomeLabel: "Ingreso por alquiler",
        repeatQuestionLabel: "Agrega otro Ingreso por alquiler",
        repeatincomeQuestionLabel: "Agrega otro Ingreso por alquiler",
        unitsRentalOptions: ["Mes", "Semana"],
        questionButtonLabel: "Agrega otro Otro",
        incomeSummaryTitle: "Resumen de ingresos",
        incomeSummaryHeader: "Resumen de ingresos",
        expenseTitle: "Gastos",
        almostLabel: "¡Casi termina!",
        houseUtlilityLabel: " Costos de vivienda y servicios públicos",
        housingLabel: "Costos de vivienda",
        utilityLabel: "Costos de servicios",
        utilityHeader: "Indíquenos sus costos de servicios",
        expenseHeader: "Díganos sobre los costos de su vivienda",
        expenseSubHeader:
          "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
        houseHoldQLabel: "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
        utilityQOptions: [
          "Calefacción (nafta, gas, electricidad o propano, etc.)",
          "Electricidad para un aire acondicionado",
          "Cargo por usar un aire acondicionado ",
          "Electricidad y/o gas",
          "Servicio de teléfono o celular",
        ],
        utilitiesClearOption: "Ninguno",
        utilitiesDoNotPayOption: "No pago servicios",
        shelterDoNotPayOption: "No pago costos de refugio",
        houseQLabelBefore: "¿Qué tipos de ",
        houseQLabelTrigger: "Costos de refugio",
        houseQLabelAfter: " tiene su grupo familiar?",
        houseQLabelHelp:
          "Algunos ejemplos de costos de vivienda incluyen alquiler, hipoteca, impuestos a la propiedad, tasas de condominio o seguro de la vivienda.",
        utilityQLabelBefore: "¿Qué tipos de ",
        utilityQLabelTrigger: "costos de servicios",
        utilityQLabelAfter: " tiene su grupo familiar?",
        utilityQLabelHelp:
          "Los costos de servicios públicos incluyen servicios de calefacción, electricidad y/o teléfono.",
        houseQOptions: [
          "Alquiler",
          "Hipoteca",
          "Impuestos a la propiedad",
          "Seguro de la casa",
          "Tasa de condominio",
        ],
        houseRentQLabel: "¿Cuánto es el alquiler de su grupo familiar?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "por",
        houseUnitOptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
        mortageunitBetween: " ",
        mortageQoptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        mortageProptaxLabel:
          "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        insuranceCostQLabel:
          "¿Cuál es el seguro de la casa de su núcleo familiar?",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        insuranceClearOption: "No. No necesito pagar por esto.",
        condofeeQLabel: "¿Cuál es el tasa de condominio de su núcleo familiar?",
        condofeepropBetween: "por",
        condofeeCostoptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        condofeeClearOption: "No. No necesito pagar por esto.",
        propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mes", "Año"],
        propTaxClearOption: "No. No necesito pagar por esto.",
        homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mes", "Año"],
        homeInsuranceClearOption: "No. No necesito pagar por esto.",
        condoFeeQLabel: "¿Su hogar tiene una tarifa de condominio?",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["Semana", "Mes", "Año"],
        condoFeeClearOption: "No. No necesito pagar por esto.",

        //new labels:
        anyUtilQcost:
          "¿Su núcleo familiar tiene costos de servicios públicos? ",
        chooseHoldErrmsg: "Por favor elija servicios domésticos",
        houseCostErrmsg: "Por favor elija los costos de la vivienda",
        rentErrMsg: "Por favor ingrese alquiler",
        anotherRentLabel: "+ Agregar otra alquiler",
        mortageErrLabel: "Por favor ingrese la hipoteca",
        propTaxLabel: "Ingrese los impuestos a la propiedad",
        propTaxButtonLabel: "+ Agregue otros impuestos a la propiedad",
        homeInsurbutton: "+ Agregue otro seguro de la casa",
        homeInsurErrmsg: "Por favor ingrese seguro de la casa",
        condoButtonLabel: "+ Agregar otra tarifa de condominio",
        condoFeeErrmsg: "Por favor ingrese el costo",
        addMortageButtonLabel: "+ Agregar otra hipoteca",
        childTitle: "Costos de cuidado de hijos o adultos dependientes",
        childCostHeader:
          "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
        childCostSubHeader:
          "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos. ",
        careGiveObjbefore1: "¿Alguien en el hogar paga servicios de niño o adulto? ",
        careGiveObjtrigger1:
          "costos de cuidado infantil o de adultos dependientes",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "Esto incluye costos de ",
        careGiveObjtrigger2: "transporte",
        careGiveObjAfter2:
          " del dependiente hacia y desde un proveedor de atención.",

        careGiveHelp1:
          "Los costos de cuidado de dependientes incluyen cualquier pago que usted efectúe por alguien que cuide y/o transporte a un niño o adulto que requiera cuidado.",
        careGiveHelp2:
          "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
        questionClearOption: "Ninguno",
        kidsandadultSubheader:
          "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
        costBetween: " ",
        weekMonthOptions: ["Semana", "Mes"],
        costOptions: ["Semana", "Mes", "Año"],
        yesnoOptions: ["Sí", "No"],
        targetValueYes: "Sí",
        noofAppointment: "Por favor, elija no.of citas",
        addressProvider: "¿Cuál es la dirección del proveedor?",
        freqTripoptions: [
          "Anualmente",
          "Bimestralmente (cada dos meses)",
          "Quincenalmente (cada dos semanas)",
          "Mensualmente",
          "Trimestralmente",
          "Bianual (dos veces al año)",
          "semi-mensal (dos veces al mes)",
          "Semanalmente",
        ],
        unitMonthTargetValue: { unit: "Mes" },
        freqMonthoption: ["Mensualmente"],
        tripCountErrMsgLen:
          "Ingrese 6 caracteres como máximo por conteo de desconexiones",
        tripCostErrMsgLen:
          "Ingrese 6 caracteres o menos para el costo del viaje",
        childSupTitle: "Costo de la manutención infanti",
        supportCostHeader: "Costo de la manutención infanti",
        childSupportQlegalbefore:
          "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
        childSupportQlegaltrigger: "manutención a un niño",
        childSupportQlegalAfter: " que non viva con usted?",
        childSupportHelp:
          "La manutención pagada puede contarse como un gasto cuando se exige legalmente y los pagos se están haciendo. Esto incluye pagos por mora",
        medicalTitle: "Costos médicos",
        medicalInlineSeethisFull: "Consulte la lista completa de",
        medicalInlineEligibleMedical: " gastos médicos elegibles.",
        medicalHeader: "Díganos sobre sus costos médicos",
        medicalSubHeader:
          "Indique todos los servicios que usted debe pagar, aunque ya los tengamos registrados. Puede incluir los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
        medicalLabel1:
          "Si ningún miembro del grupo familiar es discapacitado o tiene al menos 60 años, omita esta sección.",
        medicalExpBefore:
          "¿Alguna de las personas que se indican a continuación paga un seguro de salud u otros costos médicos, incluidos medicamentos de venta con receta,",
        medicalExpTrigger: "transporte",
        medicalExpAfter:
          ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
        medicalExphelp:
          "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
        medicalInhelp: "Consulte la lista completa de",
        medicalInhelpEligible: " gastos médicos elegibles",
        medicalAddress: "¿Cuál es la dirección de la cita médica o farmacia?",
        zipErrmsg: "Por favor ingrese un código postal válido.",
        signSubmitLabel: "Firmar y enviar",
        signSubmitHeader: "Firmar y enviar",
        signSubmitSub:
          "¡Completo todo el formulario! Ahora, pasemos a lo fácil.",
        subSectionLabel: "Presentada",
        submitLabelFinal: "¡Su solicitud se ha enviado!",
        tellusAbout: "Díganos sobre sus costos de manutención",
        moneyYougettitle:
          "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
        submitSummaryHeader: "Se presentó su Recertificación.",
      };
    case PT: //PORTUGES
      return {
        incomeTitle: "Status de não cidadão",
        incomeHeader: "Sobre a renda da sua família",
        aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
        aboutIncomeTrigger: " renda ou benefício?",
        incomeHelp:
          "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
        clearOption: "Nenhum",
        combineIncomeoptions: [
          "Salário",
          "Trabalho autônomo",
          "Estudo",
          "SSI (Renda de segurança suplementar)",
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
          "Desemprego",
          "Suporte infantil",
          "Pensão",
          "Benefício de veterano",
          "Renda de aluguel",
          "Compensação do trabalhador",
          "Outros",
        ],
        wageIncomeBeforeLabel: "Qual é o ",
        wageIncomeTriggerLabel: "rendimento bruto",
        wagesTargetValue: "Salário",
        selfemeploymentTargetValue: "Trabalho autônomo",
        workStudyTargetValue: "Estudo",
        rsdiTargetValue:
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
        SSITargetValue: "SSI (Renda de segurança suplementar)",
        unEmploymentTargetValue: "Desemprego",
        childSuppTargetValue: "Suporte infantil",
        pensionTargetValue: "Pensão",
        veteranBenefitsTargetValue: "Benefício de veterano",
        rentalIncomeTargetValue: "Renda de aluguel",
        workerCompTargetValue: "Compensação do trabalhador",
        otherTargetValue: "Outros",
        noneTargetValue: "Nenhum",
        mortageInlineHelpText:
          "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
        grossIncomeHelp:
          "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
        unitsPerLabel: " ",
        unitsOptions: ["Mês", "A cada duas semanas", "Semana"],
        labelOtherwage: "Adicionar outro Salário",
        adsSelfEmployee: "Adicionar outro Trabalho autônomo",
        condWorkStudy: "Estudo",
        workStudyLabel: "Adicionar outro Estudo",
        anotherSSILabel: "Adicionar outro SSI",
        addRDILabel: "Adicione outro RSDI",
        unEmpLabel: "Adicionar outro Desemprego",
        childSuppLabel: "Suporte infantil",
        anotherChildsupport: "Adicionar outro Suporte infantil",
        pensionLabel: "Pensão",
        anotherPensionLabel: "Adicionar outro Pensão",
        workerCompensationLabel: "adicionar compensação de outro trabalhador",
        veteranBenefit: "Benefício de veterano",
        addAnotherVBenefitLabel: "Adicionar outro Benefício de veterano",
        rentIncomeLabel: "Renda de aluguel",
        repeatQuestionLabel: "Adicionar outro Renda de aluguel",
        repeatincomeQuestionLabel: "Adicionar outro Renda de aluguel",
        unitsRentalOptions: ["Mês", "Semana"],
        questionButtonLabel: "Adicionar outro Outros",
        incomeSummaryTitle: "Resumo da renda",
        incomeSummaryHeader: "Resumo da renda",
        expenseTitle: "Despesas",
        almostLabel: "Quase lá!",
        houseUtlilityLabel: "Custos residenciais e utilitários",
        housingLabel: "Custos com alojamento",
        utilityLabel: "Custos com serviços públicos",
        utilityHeader: "Conte-nos sobre seus gastos com serviços públicos",
        expenseHeader: "Fale sobre os seus custos residenciais",
        expenseSubHeader:
          "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
        houseHoldQLabel: "Is your household responsible for any utilities?",
        utilityQOptions: [
          "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
          "Eletricidade para ar condicionado",
          "Uma taxa para usar um ar condicionado",
          "Eletricidade e/ou gás",
          "Serviço de telefone fixo ou celular",
        ],
        utilitiesClearOption: "Nenhum",
        utilitiesDoNotPayOption: "Eu não pago por serviços públicos",
        shelterDoNotPayOption: "Eu não pago por nenhum custo de abrigo",
        houseQLabelBefore: "Que tipo(s) de ",
        houseQLabelTrigger: "Custos de abrigo",
        houseQLabelAfter: " tem seu domicílio?",
        houseQLabelHelp:
          "Exemplos de gastos com moradia incluem aluguel, hipoteca, impostos do imóvel, taxas de condomínio e/ou seguro residencial.",
        utilityQLabelBefore: "Que tipo(s) de ",
        utilityQLabelTrigger: "gastos com serviços públicos",
        utilityQLabelAfter: " tem seu domicílio?",
        utilityQLabelHelp:
          "Os custos habitacionais incluem aquecimento, eletricidade e/ou serviços de telefone.",
        houseQOptions: [
          "Aluguel",
          "Financiamento",
          "Impostos da propriedade",
          "Seguro residencial",
          "Condomínio",
        ],
        houseRentQLabel: "Qual é o valor do seu aluguel?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "por",
        houseUnitOptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        mortageQLabel: "Qual é o valor do seu financiamento?",
        mortageunitBetween: " ",
        mortageQoptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        mortageProptaxLabel: "Qual é o valor dos impostos da sua propriedade?",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        insuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condofeeQLabel: "Qual é o valor do seu condomínio?",
        condofeepropBetween: "por",
        condofeeCostoptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        condofeeClearOption: "Não. Eu não preciso pagar por isso.",
        propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mês", "Ano"],
        propTaxClearOption: "Não. Eu não preciso pagar por isso.",
        homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mês", "Ano"],
        homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condoFeeQLabel: "A sua casa possui uma condominio?",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["Semana", "Mês", "Ano"],
        condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

        //new labels:
        anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
        chooseHoldErrmsg: "Escolha utilitários domésticos",
        houseCostErrmsg: "Escolha os custos de habitação",
        rentErrMsg: "Entre com aluguel",
        anotherRentLabel: "+ Adicionar outro aluguel",
        mortageErrLabel: "Insira uma hipoteca",
        propTaxLabel: "Digite os impostos sobre a propriedade",
        propTaxButtonLabel: "+ Adicione outros impostos sobre a propriedade",
        homeInsurbutton: "+ Adicione outro seguro de casa",
        homeInsurErrmsg: "Digite o seguro de casa",
        condoButtonLabel: "+ Adicionar outra condominio",
        condoFeeErrmsg: "Entre com condominio",
        addMortageButtonLabel: "+ Adicionar outros condominio",
        childTitle: "Custos de crianças ou dependentes",
        childCostHeader:
          "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
        childCostSubHeader:
          "Informe todos os serviços públicos pelos quais você é responsável pelo pagamento, mesmo que já os tenhamos em arquivo. Você pode incluir despesas mesmo se estiver atrasado ou não puder pagá-las.",
        careGiveObjbefore1: "Alguém na sua casa tem despesas de dependente ",
        careGiveObjtrigger1: "Gastos com cuidados de dependentes",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "Incluindo os custos de ",
        careGiveObjtrigger2: "transporte do(s)",
        careGiveObjAfter2: " dependente(s) e/ou de um provedor de cuidados.",

        // careGiveObjbefore:
        //   "Alguém mais no domicílio tem quaisquer despesas adicionais de manutenção de filhos ou adultos dependentes? Incluindo os custos de ",
        // careGiveObjtrigger: "transporte do(s)",
        // careGiveObjAfter: "dependente(s) e/ou de um provedor de cuidados.",

        careGiveHelp1:
          "As despesas de dependente com um prestador de serviços incluem qualquer pagamento que você fizer a alguém para que essa pessoa cuide e/ou transporte uma criança ou adulto que precise de cuidado.",
        careGiveHelp2:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        questionClearOption: "Nenhum",
        kidsandadultSubheader:
          "Inclua os custos que você é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.",
        costBetween: " ",
        weekMonthOptions: ["Semana", "Mês"],
        costOptions: ["Semana", "Mês", "Ano"],
        yesnoOptions: ["Sim", "Não"],
        targetValueYes: "Sim",
        noofAppointment: "Por favor, escolha o número de compromissos",
        addressProvider: "Qual é o endereço do prestador de serviço?",
        freqTripoptions: [
          "Anual",
          "Bimestralmente (a cada dois meses)",
          "Quinzenal (a cada duas semanas)",
          "Mensal",
          "Trimestral",
          "Semestral (duas vezes ao ano)",
          "semi-mensal (duas vezes por mês)",
          "Semanal",
        ],
        unitMonthTargetValue: { unit: "Mês" },
        freqMonthoption: ["Mensal"],
        tripCountErrMsgLen: "Digite um valor de medidor com até 6 caracteres",
        tripCostErrMsgLen:
          "Insira 6 ou menos caracteres para o custo da viagem",
        childSupTitle: "Custos de suporte infantil",
        supportCostHeader: "Custos de suporte infantil",
        childSupportQlegalbefore:
          "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar pelo ",
        childSupportQlegaltrigger: " suporte de uma criança",
        childSupportQlegalAfter: " que não vive com você?",
        childSupportHelp:
          "O pagamento de suporte infantil pode ser contado como uma despesa quando for exigido legalmente e quando os pagamentos estiverem sendo feitos. Incluindo pagamentos em atraso.",
        medicalTitle: "Custos médicos",
        medicalInlineSeethisFull: "Veja esta lista completa de",
        medicalInlineEligibleMedical: " despesas médicas elegíveis.",
        medicalHeader: "Fale sobre os seus custos médicos",
        medicalSubHeader:
          "Informe todos os serviços públicos pelos quais você é responsável pelo pagamento, mesmo que já os tenhamos em arquivo. Você pode incluir despesas mesmo se estiver atrasado ou não puder pagá-las.",
        medicalLabel1:
          "Se ninguém em seu domicílio for incapacitado ou tiver pelo menos 60 anos, pule esta seção.",
        medicalExpBefore:
          "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
        medicalExpTrigger: "transporte",
        medicalExpAfter:
          ", medicamentos de venda livre (sem receita), cuidados odontológicos ou oftalmológios, fraldas para adultos, etc.?",
        medicalExphelp:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        medicalInhelp: "Veja esta lista completa de",
        medicalInhelpEligible: "despesas médicas elegíveis",
        medicalAddress: "Qual é o endereço da consulta médica ou da farmácia?",
        zipErrmsg: "Por favor insira um código postal válido",
        signSubmitLabel: "Assinar e enviar",
        signSubmitHeader: "Assinar e enviar",
        signSubmitSub:
          "Você chegou ao fim do formulário! Agora vamos à parte fácil.",
        subSectionLabel: "Submetido",
        submitLabelFinal: "A sua solicitação foi enviada!",
        tellusAbout: "Fale sobre os custos de suporte infantil",
        moneyYougettitle:
          "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
        submitSummaryHeader: "Sua Recertificação foi enviada",
      };
    case ZH: //China
      return {
        incomeTitle: "非公民状态",
        incomeHeader: "关于您的家庭收入",
        aboutIncomeBefore: "您家中是否有人获得任何 ",
        aboutIncomeTrigger: "收入或补助?",
        incomeHelp:
          "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
        clearOption: "无",
        combineIncomeoptions: [
          "工资",
          "个体经营",
          "工作研究",
          "SSI（补充保障收入）",
          "RSDI（退休，幸存者和残疾保险）",
          "失业",
          "子女抚养费",
          "养老金",
          "退伍军人补贴",
          "租金收入",
          "工伤赔偿其",
          "其他",
        ],
        wageIncomeBeforeLabel: "是什麼 ",
        wageIncomeTriggerLabel: "總收入",
        wagesTargetValue: "工资",
        selfemeploymentTargetValue: "个体经营",
        workStudyTargetValue: "工作研究",
        SSITargetValue: "SSI（补充保障收入）",
        unEmploymentTargetValue: "失业",
        rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
        childSuppTargetValue: "子女抚养费",
        pensionTargetValue: "养老金",
        veteranBenefitsTargetValue: "退伍军人补贴",
        rentalIncomeTargetValue: "租金收入",
        workerCompTargetValue: "工伤赔偿其",
        otherTargetValue: "其他",
        noneTargetValue: "无",
        mortageInlineHelpText:
          "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
        grossIncomeHelp: "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
        unitsPerLabel: " ",
        unitsOptions: ["月", "每两周", "周"],
        labelOtherwage: "添加另一個工資",
        adsSelfEmployee: "添加另一個自僱",
        condWorkStudy: "工作研究",
        workStudyLabel: "添加另一個工作研究",
        anotherSSILabel: "添加另一個SSI",
        addRDILabel: "添加另一個RSDI",
        unEmpLabel: "再增加一個失業",
        childSuppLabel: "子女撫養費",
        anotherChildsupport: "添加另一個子支持",
        pensionLabel: "養老金",
        anotherPensionLabel: "再加一個養老金",
        workerCompensationLabel: "添加另一个工人的赔偿",
        veteranBenefit: "老兵的好处",
        addAnotherVBenefitLabel: "添加另一個退伍軍人的好處",
        rentIncomeLabel: "贴租金收",
        repeatQuestionLabel: "添加另一个租金收入",
        repeatincomeQuestionLabel: "添加另一个租金收入",
        unitsRentalOptions: ["月", "周"],
        questionButtonLabel: "添加另一個租金收入",
        incomeSummaryTitle: "收入摘要",
        incomeSummaryHeader: "收入摘要",
        expenseTitle: "开支",
        almostLabel: "还差一步！",
        houseUtlilityLabel: "住房和通用开支",
        housingLabel: "住房费用",
        utilityLabel: "支付哪种类型的",
        utilityHeader: "告诉我们您的公用设施费用",
        expenseHeader: "请告诉我们您的家庭开支",
        expenseSubHeader:
          "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
        houseHoldQLabel: "你家是否负责任何公用事业？?",
        utilityQOptions: [
          "取暖费（石油，天然气，电力或丙烷等）",
          "空调用电费",
          "空调使用费",
          "电费和/或燃气费",
          "电话费或手机费",
        ],
        utilitiesClearOption: "无",
        utilitiesDoNotPayOption: "我不支付任何公用设施费用",
        shelterDoNotPayOption: "我不支付任何住房费用",
        houseQLabelBefore: "您的家庭 ",
        houseQLabelTrigger: "住房费用",
        houseQLabelAfter: " 公用设施费用？",
        houseQLabelHelp:
          "住房费用示例包括房租、抵押贷款、财产税、物业管理费用和/或家庭保险。",
        utilityQLabelBefore: "您的家庭 ",
        utilityQLabelTrigger: "支付哪种类型的",
        utilityQLabelAfter: " 公用设施费用？",
        utilityQLabelHelp: "公用事业费用包括采暖、供电和/或电话服务。",
        houseQOptions: [
          "房租",
          "抵押贷款",
          "财产税",
          "家庭保险",
          "物业管理费用",
        ],
        houseRentQLabel: "您的住房的月租金有多少？",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "每",
        houseUnitOptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        mortageQLabel: "您家的房贷有多少？",
        mortageunitBetween: " ",
        mortageQoptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        mortageProptaxLabel: "您的住房的财产税有多少？",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        insuranceCostQLabel: "您的住房的房屋保险有多少？",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        insuranceClearOption: "不，我不需要为此付费。",
        condofeeQLabel: "您的住房的物业管理费有多少？",
        condofeepropBetween: "每",
        condofeeCostoptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        condofeeClearOption: "不，我不需要为此付费。",
        propTaxQLabel: "你家有財產稅嗎？",
        propTaxBetween: "每",
        propTaxOptions: ["周", "月", "年"],
        propTaxClearOption: "不，我不需要为此付费。",
        homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
        homeInsuranceBetween: "每",
        homeInsuranceUnits: ["周", "月", "年"],
        homeInsuranceClearOption: "不，我不需要为此付费。",
        condoFeeQLabel: "你家有公寓費嗎？",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["周", "月", "年"],
        condoFeeClearOption: "不，我不需要为此付费。",

        //new labels:
        anyUtilQcost: "您的家庭有哪些通用开支？",
        chooseHoldErrmsg: "请选择家用电器",
        houseCostErrmsg: "请选择住房费用",
        rentErrMsg: "请输入租金",
        anotherRentLabel: "+ 添加另一個租金",
        mortageErrLabel: "请输入抵押",
        propTaxLabel: "请输入财产税",
        propTaxButtonLabel: "+ 添加另一個財產稅",
        homeInsurbutton: "+ 添加另一個家庭保險",
        homeInsurErrmsg: "请输入家庭保险",
        condoButtonLabel: "+ 添加另一個公寓費用",
        condoFeeErrmsg: "请输入公寓费用",
        addMortageButtonLabel: "+ 添加另一個抵押",
        childTitle: "子女或成人照护费用",
        childCostHeader: "告诉我们您的子女或成人的照护费",
        childCostSubHeader:
          "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。",
        careGiveObjbefore1: "家庭成员中是否有儿童或成人",
        careGiveObjtrigger1: "亲属护理费用",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "此类费用包括 ",
        careGiveObjtrigger2: "交通",
        careGiveObjAfter2: " 交给和/或收自医疗服务提供者的受扶养人费用。",
        careGiveHelp1:
          "受扶养人照顾费用包括您请人照看和/或运送需要护理的儿童或成人而支付的任何费用.",
        careGiveHelp2:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        questionClearOption: "无",
        kidsandadultSubheader:
          "请将您负责支付的费用包括在内，即使您延期支付或无力支付此等费用也须告知我们。",
        costBetween: " ",
        weekMonthOptions: ["周", "月"],
        costOptions: ["周", "月", "年"],
        yesnoOptions: ["是", "否"],
        targetValueYes: "是",
        noofAppointment: "请选择任命数量",
        addressProvider: "看护人的地址？",
        freqTripoptions: [
          "全年",
          "每两月（每两个月一次）",
          "每两周（每两周一次）",
          "每月",
          "每季度",
          "每半年（一年两次）",
          "每半月（每月两次）",
          "每周",
        ],
        unitMonthTargetValue: { unit: "月" },
        freqMonthoption: ["每月"],
        tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
        tripCostErrMsgLen: "请输入少于/等于 6 个字符的旅程费用",
        childSupTitle: "子女抚养费用",
        supportCostHeader: "子女抚养费用",
        childSupportQlegalbefore:
          "您家中是否有人必须向不与您同住的子女支付法定 ",
        childSupportQlegaltrigger: "子女抚养费",
        childSupportQlegalAfter: " ?",
        childSupportHelp:
          "在法定情况下以及正在支付的情况下，子女抚养费可以算作一项开支。这包括拖欠款项",
        medicalTitle: "医疗费用",
        medicalInlineSeethisFull: "参阅此的完整列表。",
        medicalInlineEligibleMedical: "合格医疗费用",
        medicalHeader: "请告诉我们您的医疗费",
        medicalSubHeader:
          "请将您负责付费的所有公用设施告知我们，即使我们已经对相关资料记录在案，您也须告知我们此等信息。即使您延期支付或无力支付此等费用，您也须将此等费用包括在内。 ",
        medicalLabel1: "如果您的家庭中无人残疾或年满 60 岁，请跳过此部分。",
        medicalExpBefore:
          "下方所列任何人是否支付健康保险或其他医疗费用，包括处方、",
        medicalExpTrigger: "交通",
        medicalExpAfter: "、非处方药物、牙科或眼科护理、成年人纸尿裤等？",
        medicalExphelp:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        medicalInhelp: "请参阅此完整列表",
        medicalInhelpEligible: "合格医疗开支的完整清单。",
        medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
        medicalAddress: "医疗机构或药房地址？",
        zipErrmsg: "請輸入有效的郵政編碼",
        signSubmitLabel: "签名并提交",
        signSubmitHeader: "签名并提交",
        signSubmitSub: "您已填写完全部表格！现在更简单。",
        subSectionLabel: "提交",
        submitLabelFinal: "您的申请已提交！",
        tellusAbout: "请告诉我们您的子女抚养费",
        moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
        submitSummaryHeader: "您的《重新认证》已提交",
      };
    case VI: //Vietnam
      return {
        incomeTitle: "Tình trạng không phải là công dân",
        incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
        aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
        aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không?",
        incomeHelp:
          "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
        clearOption: "Không có",
        combineIncomeoptions: [
          "Lương",
          "Tự làm chủ",
          "Nghiên cứu việc làm",
          "SSI (Thu Nhập An Sinh Bổ Sung)",
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
          "Thất nghiệp",
          "Trợ cấp nuôi con",
          "Lương hưu",
          "Trợ cấp cho cựu chiến binh",
          "Thu nhập cho thuê",
          "Bồi thường lao động",
          "Khác",
        ],
        wageIncomeBeforeLabel: "Cái gì là ",
        wageIncomeTriggerLabel: "Tổng thu nhập",
        wagesTargetValue: "Lương",
        selfemeploymentTargetValue: "Tự làm chủ",
        workStudyTargetValue: "Nghiên cứu việc làm",
        SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
        rsdiTargetValue:
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
        unEmploymentTargetValue: "Thất nghiệp",
        childSuppTargetValue: "Trợ cấp nuôi con",
        pensionTargetValue: "Lương hưu",
        veteranBenefitsTargetValue: "Trợ cấp cho cựu chiến binh",
        rentalIncomeTargetValue: "Thu nhập cho thuê",
        workerCompTargetValue: "Bồi thường lao động",
        otherTargetValue: "Khác",
        noneTargetValue: "Không có",
        mortageInlineHelpText:
          "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
        grossIncomeHelp:
          "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
        unitsPerLabel: " ",
        workerCompensationLabel: "thêm bồi thường của công nhân khác",
        unitsOptions: ["Tháng", "Mỗi hai tuần", "Tuần"],
        labelOtherwage: "Thêm một mức lương khác",
        adsSelfEmployee: "Thêm một việc tự làm",
        condWorkStudy: "Nghiên cứu việc làm",
        workStudyLabel: "Thêm nghiên cứu việc làm khác",
        anotherSSILabel: "Thêm SSI khác",
        addRDILabel: "Thêm một RSDI khác",
        unEmpLabel: "Thêm một Thất nghiệp",
        childSuppLabel: "Trợ cấp nuôi con",
        anotherChildsupport: "Thêm một khoản Hỗ trợ Nuôi con khác",
        pensionLabel: "Lương hưu",
        anotherPensionLabel: "Thêm tiền trợ cấp khác",
        veteranBenefit: "Trợ cấp cho cựu chiến binh",
        addAnotherVBenefitLabel: "Thêm một lợi ích của cựu chiến binh",
        rentIncomeLabel: "Thu nhập cho thuê",
        repeatQuestionLabel: "Thêm Thu nhập Cho thuê khác",
        repeatincomeQuestionLabel: "Thêm Thu nhập Cho thuê khác",
        unitsRentalOptions: ["Tháng", "Tuần"],
        questionButtonLabel: "Thêm một Khác",
        incomeSummaryTitle: "Tóm tắt Thu nhập",
        incomeSummaryHeader: "Tóm tắt Thu nhập",
        expenseTitle: "Các chi phí",
        almostLabel: "Gần xong rồi!",
        houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
        housingLabel: "Chi phí nhà ở",
        utilityLabel: "Chi phí tiện ích gì",
        utilityHeader: "Hãy cho chúng tôi biết về chi phí tiện ích của bạn",
        expenseHeader: "Hãy cho chúng tôi về chi phí nơi ở của quý vị",
        expenseSubHeader:
          "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
        houseHoldQLabel: "Is your household responsible for any utilities?",
        utilityQOptions: [
          "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
          "Điện cho máy điều hòa không khí",
          "Một khoản phí để sử dụng điều hòa không khí",
          "Điện và / hoặc khí đốt",
          "Dịch vụ điện thoại hoặc điện thoại di động",
        ],
        utilitiesClearOption: "Không có",
        utilitiesDoNotPayOption: "Tôi không trả bất kỳ chi phí tiện ích nào",
        shelterDoNotPayOption: "Tôi không trả cho bất kỳ chi phí nhà cửa nào",
        houseQLabelBefore: "(Những) ",
        houseQLabelTrigger: "chi phí nhà cửa mà",
        houseQLabelAfter: " gia đình của quý vị có?",
        houseQLabelHelp:
          "Ví dụ về chi phí chỗ ở bao gồm tiền thuê nhà, thế chấp, thuế bất động sản, phí chung cư và/hoặc bảo hiểm nhà.",
        utilityQLabelBefore: "(Những) ",
        utilityQLabelTrigger: "chi phí tiện ích gì",
        utilityQLabelAfter: " gia đình của quý vị có?",
        utilityQLabelHelp:
          " Chi phí điện nước gồm có sưởi, điện và/hoặc dịch vụ điện thoại.",
        houseQOptions: [
          "Thuê",
          "Thế chấp",
          "Thuế bất động sản",
          "Bảo hiểm nhà ở",
          "Chi phí công quản",
        ],
        houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "theo",
        houseUnitOptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        mortageQLabel: "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
        mortageunitBetween: " ",
        mortageQoptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        mortageProptaxLabel:
          "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        insuranceCostQLabel:
          "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condofeeQLabel:
          "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
        condofeepropBetween: "theo",
        condofeeCostoptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
        propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
        propTaxBetween: "theo",
        propTaxOptions: ["Tuần", "Tháng", "Năm"],
        propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
        homeInsuranceQLabel:
          "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
        homeInsuranceBetween: "theo",
        homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
        homeInsuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["Tuần", "Tháng", "Năm"],
        condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

        //new labels:
        anyUtilQcost:
          "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
        chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
        houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
        rentErrMsg: "Hãy nhập tiền thuê",
        anotherRentLabel: "+ Thêm tiền thuê khác",
        mortageErrLabel: "Hãy nhập khoản thế chấp",
        propTaxLabel: "Vui lòng nhập thuế bất động sản",
        propTaxButtonLabel: "+ Thêm tiền thuê khác",
        homeInsurbutton: "+ Thêm một bảo hiểm khác",
        homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
        condoButtonLabel: "+ Thêm một khoản phí chung",
        condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
        addMortageButtonLabel: "+ Thêm thế chấp khác",
        childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
        childCostHeader:
          "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
        childCostSubHeader:
          "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
        careGiveObjbefore1: "Có ai trong gia đình có , ",
        careGiveObjtrigger1: "Chi phí chăm sóc người phụ thuộc",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "Những chi phí này bao gồm chi phí cho ",
        careGiveObjtrigger2: "chuyên chở",
        careGiveObjAfter2:
          " việc đưa đón người phụ thuộc đến nhà cung cấp dịch vụ chăm sóc.",
        careGiveHelp1:
          "Chi phí chăm sóc người phụ thuộc bao gồm tất cả những khoản tiền quý vị trả cho một người nào đó để trông coi và/hoặc chuyên chở trẻ em hay người lớn cần được chăm sóc.",
        careGiveHelp2:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v.",
        questionClearOption: "Không có",
        kidsandadultSubheader:
          "Vui lòng cho biết các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi chúng đã quá hạn hoặc quý vị không thể thanh toán chúng.",
        costBetween: " ",
        weekMonthOptions: ["Tuần", "Tháng"],
        costOptions: ["Tuần", "Tháng", "Năm"],
        yesnoOptions: ["Có", "Không"],
        targetValueYes: "Có",
        noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
        addressProvider: "Địa chỉ của nhà cung cấp là gì?",
        freqTripoptions: [
          "hàng năm",
          "Hai tháng một lần (hai tháng một lần)",
          "Hai tuần một lần",
          "Hàng tháng",
          "Hàng quý",
          "Nửa năm (Hai lần một năm)",
          "nửa tháng (hai lần một tháng)",
          "Hàng tuần",
        ],
        unitMonthTargetValue: { unit: "Tháng" },
        freqMonthoption: ["Hàng tháng"],
        tripCountErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
        tripCostErrMsgLen:
          "Vui lòng nhập ít hơn/bằng 6 ký tự cho chi phí chuyến đi",
        childSupTitle: "Chi phí Trợ cấp nuôi Con",
        supportCostHeader: "Chi phí Trợ cấp nuôi Con",
        childSupportQlegalbefore:
          "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
        childSupportQlegaltrigger: "trợ cấp nuôi con",
        childSupportQlegalAfter:
          " cho một đứa trẻ không sống chung với quý vị?",
        childSupportHelp:
          "Khoản tiền trợ cấp nuôi con được thanh toán có thể được tính là một khoản chi phí khi khoản này được bắt buộc một cách hợp pháp và các khoản thanh toán đang được thực hiện. Khoản này bao gồm các khoản thanh toán cho các khoản nợ",
        medicalTitle: "Chi phí Y tế",
        medicalInlineSeethisFull: "Xem danh sách đầy đủ các",
        medicalInlineEligibleMedical: " chi phí y tế đủ điều kiện.",
        medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
        medicalSubHeader:
          "Vui lòng cho chúng tôi biết tất cả các chi phí tiện ích quý vị có trách nhiệm thanh toán, thậm chí chúng tôi đã có chúng trong hồ sơ. Quý vị có thể bao gồm các chi phí ngay cả khi quý vị để quá hạn thanh toán hoặc không thể thanh toán chúng.",
        medicalLabel1:
          "Nếu không có thành viên nào trong gia đình của quý vị bị khuyết tật hoặc ở độ tuổi ít nhất là 60, xin vui lòng bỏ qua phần này.",
        medicalExpBefore:
          "Có thành viên nào được liệt kê dưới đây trả tiền bảo hiểm y tế hoặc các chi phí y tế khác bao gồm đơn thuốc,",
        medicalExpTrigger: " vận chuyển",
        medicalExpAfter:
          ", thuốc không cần kê đơn, chăm sóc răng hoặc mắt, tã người lớn…..?",
        medicalExphelp:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
        medicalInhelp: "Xem danh sách đầy đủ các",
        medicalInhelpEligible: " chi phí y tế hợp lệ",
        medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
        zipErrmsg: "Por favor ingrese un código postal válido.",
        signSubmitLabel: "Ký tên & Gửi",
        signSubmitHeader: "Ký tên & Gửi",
        signSubmitSub:
          "Quý vị đã làm xong mẫu đơn! Bây giờ đến những việc dễ dàng.",
        subSectionLabel: "Đã gửi",
        submitLabelFinal: "Đơn của quý vị đã được gửi!",
        tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
        moneyYougettitle:
          "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
        submitSummaryHeader: "Mẫu Chứng nhận lại của quý vị đã được gửi",
      };
    case HI:
      return {
        incomeTitle: "Estati Non-sitwayen Ameriken",
        incomeHeader: "Konsènan revni fwaye w la",
        aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
        aboutIncomeTrigger: "revni oswa benefis",
        incomeHelp:
          "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
        clearOption: "Okenn",
        combineIncomeoptions: [
          "Salè",
          "Travay-Endepandan",
          "Travay Etidyan",
          "SSI (Revni Sekirite Siplemantè)",
          "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
          "Chomaj",
          "Sipò Timoun",
          "Pansyon",
          "Benefis",
          "pou Veteran",
          "Revni Lwaye",
          "Konpansasyon Travayè",
          "Lòt",
        ],
        wageIncomeBeforeLabel: "Ki  ",
        wageIncomeTriggerLabel: "montan",
        wagesTargetValue: "Salè",
        selfemeploymentTargetValue: "Travay Endepandan",
        workStudyTargetValue: "Travay Etidyan",
        rsdiTargetValue: "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
        SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
        unEmploymentTargetValue: "Chomaj",
        childSuppTargetValue: "Sipò Timoun",
        pensionTargetValue: "Pansyon",
        veteranBenefitsTargetValue: "Benefis pou Veteran",
        rentalIncomeTargetValue: "Revni Lwaye",
        workerCompTargetValue: "Konpansasyon Travayè",
        otherTargetValue: "Lòt",
        noneTargetValue: "Okenn",

        mortageInlineHelpText:
          "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
        grossIncomeHelp:
          "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
        unitsPerLabel: " ",
        unitsOptions: ["Mwa", "Chak de semèn", "Semèn"],
        labelOtherwage: "Ajoute yon lòt Salè",
        adsSelfEmployee: "Ajoute yon lòt travay endepandan",
        condWorkStudy: "Travay Etidyan",
        workStudyLabel: "Ajoute yon lòt travay etidyan",
        anotherSSILabel: "Ajoute yon lòt SSI",
        addRDILabel: "Ajoute yon lòt RSDI",
        unEmpLabel: "Ajoute yon lòt Chomaj ",
        childSuppLabel: "Sipò Timoun",
        anotherChildsupport: "Ajoute yon lòt Sipò Timoun",
        pensionLabel: "Pansyon",
        anotherPensionLabel: "Ajoute yon lòt Pansyon",
        workerCompensationLabel: "Ajoute yon lòt Konpansasyon Travayè",
        veteranBenefit: "Benefis pou Veteran",
        addAnotherVBenefitLabel: " Ajoute yon lòt benefis pou veteran",
        rentIncomeLabel: "Revni Lwaye",
        repeatQuestionLabel: "Ajoute yon lòt revni lwaye",
        repeatincomeQuestionLabel: "Ajoute èdtan pou yon lòt lwaye",
        unitsRentalOptions: ["Mwa", "Semèn"],
        questionButtonLabel: "Ajoute yon lòt Lòt",
        incomeSummaryTitle: "Rezime revni",
        incomeSummaryHeader: "Rezime revni",
        expenseTitle: "Depans",
        almostLabel: "Prèske fini!",
        housingLabel: "Depans pou lojman",
        utilityLabel: "Depans pou sèvis piblik",
        utilityHeader: "Pale nou konsènan depans ou yo pou sèvis piblik",
        expenseHeader: "Pale nou konsènan depans ou yo pou abri",
        expenseSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
        houseHoldQLabel: "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
        utilityQOptions: [
          "Chofaj luil, gaz, elektrisite oswa pwopaàn, elatriye.)",
          "Elektrisite pou yon klimatizè",
          "Yon frè pou itilize yon klimatizè",
          "Elektrisite ak/oswa gaz",
          "Telefòn oswa sèvis telefòn selilè",
        ],
        utilitiesClearOption: "Okenn",
        utilitiesDoNotPayOption: "Mwen pa peye okenn frè pou sèvis piblik",
        shelterDoNotPayOption: "Mwen pa peye okenn frè pou abri",
        houseQLabelBefore: "Ki tip(yo) ",
        houseQLabelTrigger: "depans pou lojman",
        houseQLabelAfter: " fwaye w la genyen?",
        houseQLabelHelp:
          "Egzanp depans pou lojman yo enkli lwaye, prè ipotèk, taks sou pwopriyete, frè kondo, ak/oswa asirans kay.",
        utilityQLabelBefore: "Ki tip(yo) ",
        utilityQLabelTrigger: "Depans pou sèvis piblik",
        utilityQLabelAfter: " fwaye w la genyen?",
        utilityQLabelHelp:
          "Depans pou sèvis piblik yo enkli chofaj, elektrisite, ak/oswa sèvis telefòn.",
        houseQOptions: [
          "Lwaye",
          "Prè ipotèk",
          "Taks sou pwopriyete",
          "Asirans kay",
          "Frè kondo",
        ],
        houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MTGPRN",
        propertyTaxTargetValue: "PRPTAX",
        homeInsurTargetValue: "HOMEIN",
        condoFeeTargetValue: "CONFEE",
        unitBetween: "pa",
        houseUnitOptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
        mortageunitBetween: " ",
        mortageQoptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
        mortagepropBetween: " ",
        mortagepropoptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
        insuranceCostpropBetween: " ",
        insuranceCostoptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
        condofeepropBetween: "pa",
        condofeeCostoptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
        propTaxBetween: "pa",
        propTaxOptions: ["Semèn", "Mwa", "Ane"],
        propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
        homeInsuranceBetween: "pa",
        homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
        homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
        condoFeeBeweenLabel: " ",
        condoFeeUnits: ["Semèn", "Mwa", "Ane"],
        condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

        //new labels:
        anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
        chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
        houseCostErrmsg: "Tanpri chwazi depans pou lojman",
        rentErrMsg: "Tanpri antre lwaye",
        anotherRentLabel: "+ Ajoute yon lòt lwaye",
        mortageErrLabel: "Tanpri antre prè ipotèk",
        propTaxLabel: "Tanpri antre taks sou pwopriyete",
        propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
        homeInsurbutton: "+ Ajoute yon lòt asirans kay",
        homeInsurErrmsg: "Tanpri antre asirans kay",
        condoButtonLabel: "+ Ajoute yon lòt frè kondo",
        condoFeeErrmsg: "Tanpri antre frè kondo",
        addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
        childTitle: "Depans pou Swen Timoun oswa Adilt",
        childCostHeader:
          "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
        childCostSubHeader:
          "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo.  ",
        careGiveObjbefore1:
          "Èske nenpòt moun nan fwaye a gen timoun oswa granmoun ",
        careGiveObjtrigger1: "depans pou swen depandan",
        careGiveObjAfter1: "? ",
        careGiveObjbefore2: "Sa enkli depans pou ",
        careGiveObjtrigger2: "transpòte",
        careGiveObjAfter2:
          " depandan(yo) pou ale ak/oswa soti lakay yon founisè swen.",
        careGiveHelp1:
          "Depans pou swen depandan enkli nenpòt peman ou fè pou yon moun gade ak/oswa transpòte yon timoun oswa adilt ki bezwen swen.",
        careGiveHelp2:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        questionClearOption: "Okenn",
        kidsandadultSubheader:
          "Tanpri enkli depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo.",
        costBetween: " ",
        weekMonthOptions: ["Mwa", "Semèn"],
        costOptions: ["Semèn", "Mwa", "Ane"],
        yesnoOptions: ["Wi", "Non"],
        targetValueYes: "Wi",
        noofAppointment: "Please choose no.of appointments",
        addressProvider: "Ki adrès founisè a?",
        freqMonthoption: ["Mansyèl"],
        freqTripoptions: [
          "Anyèl",
          "Bimansyèl (chak de mwa)",
          "Biebdomadè (chak de semèn)",
          "Mansyèl",
          "Chak trimès",
          "Semianyèl (de fwa pa ane)",
          "Semimansyèl (de fwa pa mwa)",
          "Chak semèn",
        ],
        unitMonthTargetValue: { unit: "Mansyèl" },
        tripCountErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
        tripCostErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou depans vwayaj",
        childSupTitle: "Depans pou Sipò Timoun",
        supportCostHeader: "Depans pou Sipò Timoun",
        childSupportQlegalbefore:
          "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
        childSupportQlegaltrigger: "sipò timoun",
        childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
        childSupportHelp:
          "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
        medicalTitle: "Depans medikal",
        medicalInlineSeethisFull: "Gade lis konplè sa a",
        medicalInlineEligibleMedical: " depans medikal ki kalifye",
        medicalHeader: "Pale nou konsènan depans medikal ou yo",
        medicalSubHeader:
          "Tanpri pale nou konsènan tout sèvis piblik ou responsab pou peye pou yo, menm si nou deja genyen yo nan fichye. Ou ka enkli depans yo, menm si ou an reta oswa ou pa kapab peye yo.  ",
        medicalLabel1:
          "If Pesonn in your household is disabled or at least 60 years old, please skip this section.",
        medicalExpBefore:
          "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
        medicalExpTrigger: " transpò",
        medicalExpAfter:
          ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
        medicalExphelp:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        medicalInhelp: "Gade lis konplè sa a",
        medicalInhelpEligible: " depans medikal ki kalifye",
        medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
        zipErrmsg: "Tanpri antre yon kòd postal ki valid",
        signSubmitLabel: "Siyen epi soumèt",
        signSubmitHeader: "Siyen epi soumèt",
        signSubmitSub: "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
        subSectionLabel: "Soumèt",
        submitLabelFinal: "Aplikasyon w lan te soumèt!",
        tellusAbout: "Pale nou konsènan depans pou sipò timoun",
        moneyYougettitle:
          "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
        submitSummaryHeader: "Resètifikasyon w lan te soumèt",
      };
    default:
      return;
  } //switch END
}

export function aboutMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "About me",
        header: "About me",
        labelFirstName: "What is your first name?",
        firstNameErrMsg: "Required - Please enter first name",
        middleName: "What is your middle name?",
        middleNameErrMsg: "Please enter middle name",
        lastNamelabel: "What is your last name?",
        lastNameErrMsg: "Required - Please enter last name",
        labelSuffix: "Suffix",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "What is your date of birth?",
        dobAlertMsg: "Please enter a valid Date of Birth",
        ssnLabel: "What is your Social Security Number?",
        inputClearLabel: "I don't have one",
        ssnErrMsg: "Please enter a valid Social Security Number.",
        headerMyContactInfo: "My Contact Info",
        houselessLabel: "Is your household homeless?",
        houselessErrMsg: "Please select whether your having home or not",
        houseLessOptions: ["Yes", "No"],
        addrQuestionlabel: "What is your address?",
        validZipcode: "Please enter a valid Zipcode.",
        alsoMailingLabel: "Is this also your mailing address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your mailing address?",
        zipCodeErrmsg: "Please enter a valid Zipcode.",
        citiErrmsg: "Please enter a valid City.",
        countyErrmsg: "Please enter a valid County.",
        addressErrmsg: "Please enter a valid Address.",
        mailingAddressErrmsg: "Please enter a mailing address.",
        homeAddressErrmsg: "Please enter a home address.",
        bestPhoneqlabel: "What is the best phone number to reach you?",
        inlineHelp: "This is the number the DHR will use to reach you.",
        validPhoneAlert: "Please enter a valid phone number.",
        emailAddressLabel: "What is your email address?",
        emailErrormsg: "Please enter a valid email address.",
        titleEmergency: "Emergency SNAP benefits",
        headerEmergency: "Emergency SNAP benefits",
        emergencySnap:
          "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
        incomeMoneylabel:
          "Does your income and money in the bank add up to less than your monthly housing expenses?",
        inlineHelpLabel:
          "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
        optionYesNo: ["Yes", "No"],
        alertSelectIncome: "Please select income you pay",
        incomeLesslabel:
          "Is your monthly income less than $150 and is your money in the bank $100 or less?",
        monthlyIncomeoptions: ["Yes", "No"],
        selectIncomeErrmsg: "Please select income you pay",
        migrantLabel:'Are you a migrant worker and is your money in the bank less than or equal to $100?',
        // migrantBefore: "Are you a ",
        // migrantTrigger: "migrant worker",
        // migrantAfter: " and is your money in the bank $100 or less?",
        // migrantHelp:
        //   "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
        migrantOptions: ["Yes", "No"],
        migrantErrmsg: "Please select migrant you pay",
        headerMoreAbout: "More about me",
        areUsBefore: "Are you a ",
        areUsTrigger: "US citizen",
        areUsafter: "?",
        eligibleHelp:
          "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        chooseErrmsg: "Please choose citizen",
        eligibleMemberHelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleOptions: ["Yes", "No"],
        laguageBefore: "What language do you ",
        LANGUAGES: [
          "English",
          "Spanish",
          "Portuguese",
          "French",
          "Haitian Creole",
          "Chinese",
          "Vietnamese",
          "Khmer",
          "Korean",
          "Russian",
          "Italian",
          "Polish",
          "Arabic",
        ],
        languageTrigger: "prefer to speak",
        languageAfter: "?",
        comfortLanHelp:
          "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
        languageErrMsg: "Please choose language",
        genderLabel: "What is your gender?",
        genderOptions: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        labelEthnicity: "What is your ethnicity?",
        helpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "What is your race?",
        raceHelp:
          "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        optionAIdisplay: "American Indian",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Asian",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Black/African American",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Pacific Islander",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "White",
        declineToAnswerLabel: "Decline to answer",
        citiErrmsg: "Please enter a valid City.",
        addressErrmsg: "Please enter a valid Address.",
        mailingAddressErrmsg: "Please enter a mailing address.",
        homeAddressErrmsg: "Please enter a home address.",
      };

    case ES:
      return {
        title: "Sobre mí",
        header: "Sobre mí",
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        middleName: "¿Cuál es su segundo nombre?",
        middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        lastNamelabel: "¿Cuál es su apellido?",
        lastNameErrMsg: "Requerido: ingrese el apellido",
        labelSuffix: "Sufijo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
        dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
        ssnLabel: "¿Cuál es su número de seguro social?",
        inputClearLabel: "No tengo",
        ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
        headerMyContactInfo: "Mi información de contacto",
        houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
        houselessErrMsg: "Seleccione si tiene o no su hogar",
        houseLessOptions: ["Sí", "No"],
        addrQuestionlabel: "¿Cuál es su dirección?",
        validZipcode: "Por favor ingrese un código postal válido",
        alsoMailingLabel: "¿Es igual a su dirección postal?",
        optionsSendMail: "Sí, enviar correo allí. ",
        optionsNoDiffAddress:
          "No, utilizo una dirección diferente para el correo postal.",
        mailingQlabel: "¿Cuál es su dirección postal?",
        zipCodeErrmsg: "Ingrese un código postal válido",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        addressErrmsg: "Por favor, ingrese una dirección válida",
        mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
        homeAddressErrmsg: "Por favor ingrese una dirección particular.",
        bestPhoneqlabel:
          "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
        inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
        validPhoneAlert: "Por favor ingrese un número de teléfono válido",
        emailAddressLabel: "Dirección de correo electrónico?",
        emailErrormsg:
          "Por favor, introduce una dirección de correo electrónico válida",
        titleEmergency: "Beneficios de SNAP de emergencia",
        headerEmergency: "Beneficios de SNAP de emergencia",
        emergencySnap:
          "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
        incomeMoneylabel:
          "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
        inlineHelpLabel:
          "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
        optionYesNo: ["Sí", "No"],
        alertSelectIncome: "Por favor seleccione el ingreso que paga",
        incomeLesslabel:
          "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
        monthlyIncomeoptions: ["Sí", "No"],
        selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
        migrantBefore: "¿Es usted un ",
        migrantTrigger: " trabajador migrante",
        migrantAfter: " y su dinero en el banco es $100 o menos?",
        migrantHelp:
          "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
        migrantOptions: ["Sí", "No"],
        migrantErrmsg: "Por favor selecciona migrante pagas",
        headerMoreAbout: "Más sobre mí",
        areUsBefore: "¿Es usted ",
        areUsTrigger: "ciudadano de EE. UU.",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
        chooseErrmsg: "Por favor elija ciudadano",
        eligibleMemberHelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        eligibleOptions: ["Sí", "No"],
        LANGUAGES: [
          "Inglés",
          "Español",
          "Portugués",
          "Francés",
          "Criollo haitiano",
          "Chino",
          "Vietnamita",
          "Camboyano",
          "Coreano",
          "Ruso",
          "Italiano",
          "Polaco",
          "Árabe",
        ],
        laguageBefore: "¿Qué idioma ",
        languageTrigger: " prefiere hablar",
        languageAfter: "?",
        comfortLanHelp:
          "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
        languageErrMsg: "Por favor, elija el idioma",
        genderLabel: "¿Cuál es su sexo?",
        genderOptions: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        labelEthnicity: "¿Cuál es tu origen étnico?",
        helpEthnicity:
          "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        hisPanicOption: "Hispano/Latino",
        nonhisPanicOption: "No hispano/Latino",
        raceLabel: "¿Cuál es tu raza?",
        raceHelp:
          "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        optionAIdisplay: "Indio americano",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afroamericano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Isleño del Pacífico",
        optionwhitedisplay_VALUE: "Blanco",
        optionwhitedisplay: "Blanco",
        declineToAnswerLabel: "Me niego a responder",
        citiErrmsg: "Por favor introduzca una ciudad válida.",
        addressErrmsg: "Por favor, ingrese una dirección válida.",
        mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
        homeAddressErrmsg: "Por favor ingrese una dirección particular.",
      };

    case PT: //portugeesh
      return {
        title: "Sobre mim",
        header: "Sobre mim",
        labelFirstName: "Qual é o seu primeiro nome?",
        firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
        middleName: "Qual é o seu nome do meio?",
        middleNameErrMsg: "Digite o nome do meio",
        lastNamelabel: "Qual é o seu último nome?",
        lastNameErrMsg: "Obrigatório - Digite o sobrenome",
        labelSuffix: "Sufixo",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Qual é a sua data de nascimento?",
        dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
        ssnLabel: "Qual é o número do seu seguro social?",
        inputClearLabel: "Eu não tenho",
        ssnErrMsg: "Digite um Número de Segurança Social válido",
        headerMyContactInfo: "Minhas informações de contato",
        houselessLabel: "Sua família não tem moradia?",
        houselessErrMsg: "Selecione se você tem casa ou não",
        houseLessOptions: ["Sim", "Não"],
        addrQuestionlabel: "Qual é o seu endereço?",
        validZipcode: "Por favor insira um código postal válido",
        alsoMailingLabel: "Esse também é o seu endereço de correspondência?",
        optionsSendMail: "Sim, envie as correspondências para esse endereço. ",
        optionsNoDiffAddress:
          "Não, eu uso um endereço diferente para correspondência.",
        mailingQlabel: "Qual é o seu endereço de correspondência? ",
        zipCodeErrmsg: "Insira um Zip Code válido",
        citiErrmsg: "Digite uma cidade válida",
        addressErrmsg: "Digite um endereço válido",
        bestPhoneqlabel:
          "Qual é o melhor número de telefone para falar com você?",
        inlineHelp:
          "Esse será o número que o DHR irá usar para falar com você.",
        validPhoneAlert: "Por favor insira um número de telefone válido",
        emailAddressLabel: "Endereço de e-mail",
        emailErrormsg: "Por favor insira um endereço de e-mail válido",
        titleEmergency: "Benefício SNAP emergencial",
        headerEmergency: "Benefício SNAP emergencial",
        emergencySnap:
          "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
        incomeMoneylabel:
          "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
        inlineHelpLabel:
          'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
        optionYesNo: ["Sim", "Não"],
        alertSelectIncome: "Selecione a renda que você paga",
        incomeLesslabel:
          "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
        monthlyIncomeoptions: ["Sim", "Não"],
        selectIncomeErrmsg: "Selecione a renda que você paga",
        migrantBefore: "Você é um ",
        migrantTrigger: "trabalhador migrante",
        migrantAfter: " e tem US$ 100 ou menos no banco?",
        migrantHelp:
          "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
        migrantOptions: ["Sim", "Não"],
        migrantErrmsg: "Selecione o migrante que você paga",
        headerMoreAbout: "Mais sobre mim",
        areUsBefore: "Você é ",
        areUsTrigger: "cidadão americano",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        chooseErrmsg: "Escolha cidadão",
        eligibleMemberHelp:
          "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
        eligibleOptions: ["Sim", "Não"],
        laguageBefore: "Em qual idioma você ",
        LANGUAGES: [
          "Inglês",
          "Espanhol",
          "Português",
          "Francês",
          "Créole haitiano",
          "Chinês",
          "Vietnamita",
          "Khmer",
          "Coreano",
          "Russo",
          "Italiano",
          "Polonês",
          "Árabe",
        ],
        languageTrigger: "prefere se comunicar",
        languageAfter: "?",
        comfortLanHelp:
          "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
        languageErrMsg: "Escolha o idioma",
        genderLabel: "Qual é o seu gênero?",
        genderOptions: ["Masculino", "Feminino"],
        genderErrmsg: "Escolha o gênero",
        labelEthnicity: "Qual é a sua etnia?",
        helpEthnicity:
          "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        raceLabel: "Qual é a sua raça?",
        hisPanicOption: "Hispânico/Latino",
        nonhisPanicOption: "Não-Hispânico/Latino",
        raceHelp:
          "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        optionAIdisplay: "Americano nativo",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afro-americano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Nativo das ilhas do Pacífico",
        optionwhitedisplay_VALUE: "Branco",
        optionwhitedisplay: "Branco",
        declineToAnswerLabel: "Não quero responder",
      };

    case ZH: //China
      return {
        title: "关于我",
        header: "关于我",
        labelFirstName: "您的名字",
        firstNameErrMsg: "必填 - 請輸入名字",
        middleName: "您的中间名",
        middleNameErrMsg: "請輸入中間名",
        lastNamelabel: "您的姓氏",
        lastNameErrMsg: "必填 - 請輸入姓氏",
        labelSuffix: "后缀",
        suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
        dateOfBirthLabel: "您的出生日期？",
        dobAlertMsg: "請輸入有效的出生日期",
        ssnLabel: "你的社保号码？",
        inputClearLabel: "我没有社保",
        ssnErrMsg: "請輸入有效的社會安全號碼",
        headerMyContactInfo: "我的联系信息",
        houselessLabel: "您的家庭是否无家可归？",
        houselessErrMsg: "請選擇您是否有家",
        houseLessOptions: ["是", "否"],
        addrQuestionlabel: "您的家庭地址？",
        validZipcode: "請輸入有效的郵政編碼",
        alsoMailingLabel: "这是否也是您的邮寄地址？",
        optionsSendMail: "是，可以发送信件到这里。",
        optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
        mailingQlabel: "您的邮箱地址？",
        zipCodeErrmsg: "请输入有效邮编",
        citiErrmsg: "请输入有效城市",
        addressErrmsg: "请输入有效地址",
        bestPhoneqlabel: "您最常使用的电话号码？",
        inlineHelp: "这将是DTA与您联络所使用的电话号码。",
        validPhoneAlert: "請輸入一個有效的電話號碼",
        emailAddressLabel: "电子邮箱地址？",
        emailErrormsg: "請輸入有效的電子郵件地址",
        titleEmergency: "紧急SNAP补助",
        headerEmergency: "紧急SNAP补助",
        emergencySnap:
          "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
        incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
        inlineHelpLabel:
          "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
        optionYesNo: ["是", "否"],
        alertSelectIncome: "請選擇您支付的收入",
        incomeLesslabel:
          "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
        monthlyIncomeoptions: ["是", "否"],
        selectIncomeErrmsg: "請選擇您支付的收入",
        migrantBefore: "您是否是 ",
        migrantTrigger: "外来务工人员",
        migrantAfter: " 您的银行存款是否少于100美元？",
        migrantHelp:
          "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
        migrantOptions: ["是 ", "否"],
        migrantErrmsg: "請選擇你支付的移民",
        headerMoreAbout: "关于我的更多信息",
        areUsBefore: "您是否是 ",
        areUsTrigger: "美国公民",
        areUsafter: "?",
        eligibleHelp:
          "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        chooseErrmsg: "請選擇公民",
        eligibleMemberHelp:
          "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
        eligibleOptions: ["是", "否"],
        laguageBefore: "您 ",
        LANGUAGES: [
          "英语",
          "西班牙语",
          "葡萄牙语",
          "法语",
          "海地克里奥尔语",
          "中文",
          "越南语",
          "柬埔寨语",
          "朝鮮的",
          "俄语",
          "意大利语",
          "波兰语",
          "阿拉伯语",
        ],
        languageTrigger: "日常使用哪种语言交流",
        languageAfter: "?",
        comfortLanHelp:
          "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
        languageErrMsg: "請選擇語言",
        genderLabel: "您的性别？",
        genderOptions: ["男", "女"],
        genderErrmsg: "請選擇性別",
        labelEthnicity: "您的民族？",
        helpEthnicity:
          "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        hisPanicOption: "西班牙/拉丁美洲",
        nonhisPanicOption: "非西班牙/拉丁美洲",
        raceLabel: "您的种族？",
        raceHelp:
          "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        optionAIdisplay: "美洲印第安人",
        optionAsiandisplay_VALUE: "亚洲人",
        optionAsiandisplay: "亚洲人",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "黑人/非裔美国人",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "太平洋岛民",
        optionwhitedisplay_VALUE: "白人",
        optionwhitedisplay: "白人",
        declineToAnswerLabel: "拒绝回答",
      };

    case VI: //Vietnam
      return {
        title: "Thông tin về tôi",
        header: "Thông tin về tôi",
        labelFirstName: "Tên của quý vị?",
        firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
        middleName: "Tên lót của quý vị?",
        middleNameErrMsg: "Vui lòng nhập tên đệm",
        lastNamelabel: "Họ của quý vị?",
        lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
        labelSuffix: "Tước hiệu",
        suffixOption: [
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
          "X",
          "Jr.",
          "Sr.",
        ],
        dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
        dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
        ssnLabel: "Số an sinh xã hội của quý vị?",
        inputClearLabel: "Tôi không có số ASXH",
        ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
        headerMyContactInfo: "Thông tin liên lạc",
        houselessLabel: "Gia đình quý vị có chỗ ở không?",
        houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        houseLessOptions: ["Có", "Không"],
        addrQuestionlabel: "Địa chỉ của quý vị là gì?",
        validZipcode: "Vui lòng nhập một mã zip hợp lệ",
        alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
        optionsSendMail: "Phải, hãy gửi thư đến đó.",
        optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
        mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
        zipCodeErrmsg: "Điền mã bưu điện",
        citiErrmsg: "Vui lòng nhập vào tên thành phố",
        addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
        bestPhoneqlabel: "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
        inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
        validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
        emailAddressLabel: "Địa chỉ email?",
        emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
        titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        emergencySnap:
          "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
        incomeMoneylabel:
          "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
        inlineHelpLabel:
          "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
        optionYesNo: ["Có", "Không"],
        alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
        incomeLesslabel:
          "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
        monthlyIncomeoptions: ["Có", "Không"],
        selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
        migrantBefore: "Quý vị có phải là ",
        migrantTrigger: "công nhân di cư",
        migrantAfter:
          " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
        migrantHelp:
          "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
        migrantOptions: ["Có", "Không"],
        migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
        headerMoreAbout: "Thông tin thêm về tôi",
        areUsBefore: "Quý vị có là một ",
        areUsTrigger: "Công dân Hoa kỳ",
        areUsafter: "?",
        eligibleHelp:
          "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        chooseErrmsg: "Vui lòng chọn công dân",
        eligibleMemberHelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        eligibleOptions: ["Có", "Không"],
        laguageBefore: "Quý vị ",
        LANGUAGES: [
          "Tiếng Anh",
          "Tiếng Tây Ban Nha",
          "Tiếng Bồ Đào nha",
          "Tiếng Pháp",
          "Tiếng Haitian Creole",
          "Tiếng Trung quốc",
          "Tiếng Việt",
          "Tiếng Campuchia",
          "Hàn Quốc",
          "Tiếng Nga",
          "Tiếng Ý",
          "Tiếng Ba Lan",
          "Tiếng Ả rập",
        ],
        languageTrigger: " thích nói ngôn ngữ nào hơn",
        languageAfter: "?",
        comfortLanHelp:
          "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
        languageErrMsg: "Vui lòng chọn ngôn ngữ",
        genderLabel: "Giới tính của quý vị là gì?",
        genderOptions: ["Nam", "Nữ"],
        genderErrmsg: "Vui lòng chọn giới tính",
        labelEthnicity: "Quý vị thuộc dân tộc nào?",
        hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
        nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
        helpEthnicity:
          "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        raceLabel: "Quý vị thuộc chủng tộc nào?",
        raceHelp:
          "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        optionAIdisplay: "Người Mỹ bản xứ",
        optionAsiandisplay_VALUE: "Người Châu Á",
        optionAsiandisplay: "Người Châu Á",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
        optionwhitedisplay_VALUE: "Người da trắng",
        optionwhitedisplay: "Người da trắng",
        declineToAnswerLabel: "Từ chối trả lời",
      };
    case HI:
      return {
        title: "Konsènan mwen menm",
        header: "Konsènan mwen menm",
        labelFirstName: "Ki prenon w?",
        firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
        middleName: "Ki dezyèm prenon w?",
        middleNameErrMsg: "Tanpri antre dezyèm prenon",
        lastNamelabel: "Ki siyati w?",
        lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
        labelSuffix: "Sifiks",
        suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
        dateOfBirthLabel: "Ki dat nesans ou?",
        dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
        ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
        inputClearLabel: "M pa genyen youn",
        ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
        headerMyContactInfo: "Enfòmasyon kontak mwen",
        houselessLabel: "Èske fwaye w la sanzabri?",
        houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
        houseLessOptions: ["Wi", "Non"],
        addrQuestionlabel: "Ki adrès ou?",
        validZipcode: "Tanpri antre yon kòd postal ki valid",
        alsoMailingLabel: "Èske se adrès postal ou a tou?",
        optionsSendMail: "Wi, voye lapòs la.",
        optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
        mailingQlabel: "Ki adrès postal ou?",
        zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
        citiErrmsg: "Tanpri antre yon vil ki valid",
        addressErrmsg: "Tanpri antre yon adrès ki valid",
        bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
        inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
        validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
        emailAddressLabel: "Ki adrès imel ou?",
        emailErrormsg: "Tanpri antre yon adrès imel ki valid",
        titleEmergency: "Benefis SNAP nan Ijans",
        headerEmergency: "Benefis SNAP nan Ijans",
        emergencySnap:
          "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
        incomeMoneylabel:
          "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
        inlineHelpLabel:
          "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
        optionYesNo: ["Wi", "Non"],
        alertSelectIncome: "Tanpri chwazi revni ou peye",
        incomeLesslabel:
          "Èske revni mansyèl ou mwens pase $150epi èske lajan labank ou se $100 oswa mwens?",
        monthlyIncomeoptions: ["Wi", "Non"],
        selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
        migrantBefore: "Èske w se yon ",
        migrantTrigger: "travayè migran",
        migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
        migrantHelp:
          "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
        migrantOptions: ["Wi", "Non"],
        migrantErrmsg: "Tanpri chwazi migran ou peye",
        headerMoreAbout: "Plis konsènan mwen menm",
        areUsBefore: "Èske w se yon ",
        areUsTrigger: "sitwayen Ameriken",
        areUsafter: "?",
        eligibleHelp:
          "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        chooseErrmsg: "Tanpri chwazi sitwayen",
        eligibleMemberHelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        eligibleOptions: ["Wi", "Non"],
        LANGUAGES: [
          "English",
          "Panyòl",
          "Pòtigè",
          "Franse",
          "Kreyòl Ayisyen",
          "Chinwa",
          "Vyetnamyen",
          "Kmè",
          "Korean",
          "Ris",
          "Italyen",
          "Polonè",
          "Arabik",
        ],
        languageBefore: "Ki lang ou ",
        languageTrigger: "prefere pale ",
        languageAfter: "?",
        comfortLanHelp:
          " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
        languageErrMsg: "Tanpri chwazi ki lang",
        genderLabel: "Ki sèks ou?",
        genderOptions: ["Gason", "Fi"],
        genderErrmsg: "Tanpri chwazi sèks",
        labelEthnicity: "Ki etnisite ou?",
        helpEthnicity:
          "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "Ki ras ou?",
        raceHelp:
          "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        optionAIdisplay: "Ameriken Endyen",
        optionAsiandisplay_VALUE: "Azyatik",
        optionAsiandisplay: "Azyatik",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Nwa/Afriken Ameriken",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "moun Zile Pasifik",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "Blan",
        declineToAnswerLabel: "Refize reponn",
      };
    default:
      return;
  } //house hold ending ending
}

export function applySectionStaticMultilanguageLabels(name, language) {
  switch (language) {
    case EN:
      return {
        aboutTitle: "About " + name,
        aboutMemberTitle: "About Member " + name,
        labelMiddle: "What is " + name + "'s middle name?",
        labelLastName: "What is " + name + "'s last name?",
        labelRelation: "What is " + name + "'s relationship to you?",
        dateOfBirthQLabel: "What is " + name + "'s date of birth?",
        genderQLabel: "What is " + name + "'s gender?",
        ssnQLabel: "What is " + name + "'s Social Security Number?",
        personMiddleName: "What is this person's middle name?",
        personLastName: "What is this person's last name?",
        personRelation: "What is this person's relationship to you?",
        personDobLabel: "What is this person's date of birth?",
        personGenderLabel: "What is this person's gender?",
        personSSNLabel: "What is this person's Social Security Number?",
      };
    case ES:
      return {
        aboutTitle: "Sobre " + name,
        aboutMemberTitle: "Acerca de los miembros " + name,
        labelMiddle: "¿Cuál es el segundo nombre de  " + name + "?",
        labelLastName: "¿Cuál es el apellido de " + name + "?",
        labelRelation: "¿Cuál es la relación de " + name + "con usted?",
        dateOfBirthQLabel: "¿Cuál es la fecha de nacimiento de " + name + "?",
        genderQLabel: "¿Cuál es el sexo de " + name + "?",
        ssnQLabel: "¿Cuál es el número de seguro social de " + name + "?",
        personMiddleName: "¿Cuál es el segundo nombre de esta persona?",
        personLastName: "¿Cuál es el apellido de esta persona?",
        personRelation: "¿Cuál es la relación entre usted y de esta persona?",
        personDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
        personGenderLabel: "¿Cuál es el género de esta persona?",
        personSSNLabel: "¿Cuál es el número de seguro social de esta persona?",
      };
    case PT:
      return {
        aboutTitle: "Sobre " + name,
        aboutMemberTitle: "Sobre o Membro " + name,
        labelMiddle: "Qual é o nome do meio de " + name + "?",
        labelLastName: "Qual é o último nome de " + name + "?",
        labelRelation:
          "Qual é o grau de relacionamento de " + name + " com você?",
        dateOfBirthQLabel: "Qual é a data de nascimento de" + name + "?",
        genderQLabel: "Qual é o gênero de " + name + "?",
        ssnQLabel: "Qual é o número do seguro social de " + name + "?",
        personMiddleName: "Qual é o nome do meio dessa pessoa?",
        personLastName: "Qual é o último nome dessa pessoa?",
        personRelation: "Qual é o relacionamento dessa pessoa com você?",
        personDobLabel: "Qual é a data de nascimento desta pessoa?",
        personGenderLabel: "Qual é o sexo dessa pessoa?",
        personSSNLabel: "Qual é o número do seguro social dessa pessoa?",
      };

    case ZH: //chinees
      return {
        aboutTitle: "关于" + name + "。",
        aboutMemberTitle: "關於會員 " + name,
        labelMiddle: name + "的中间名?",
        labelLastName: name + "的姓氏?",
        labelRelation: name + " 与您的关系?",
        dateOfBirthQLabel: name + " 的出生日期? ",
        genderQLabel: name + " 的性别?",
        ssnQLabel: name + " 的社保号?",
        personMiddleName: "此人的中间名?",
        personLastName: "此人的姓氏？",
        personRelation: "此人与您的关系是？",
        personDobLabel: "此人的出生日期是？ ",
        personGenderLabel: "此人的性别是？",
        personSSNLabel: "此人的社会安全号码是？",
      };
    case VI:
      return {
        aboutTitle: "Thông tin về  " + name + ".",
        aboutMemberTitle: "Giới thiệu Thành viên " + name,
        labelMiddle: "Tên lót của  " + name + " là gì?",
        labelLastName: "Họ của " + name + " là gì?",
        labelRelation: "Mối quan hệ của " + name + " với quý vị là gì?",
        dateOfBirthQLabel: "Ngày sinh của " + name + " là gì?",
        genderQLabel: "Giới tính của " + name + " là gì?",
        ssnQLabel: "Số An sinh Xã hội của " + name + " là gì?",
        personMiddleName: "Tên lót của người này là gì?",
        personLastName: "Họ của người này là gì?",
        personRelation: "Mối quan hệ của người này với quý vị là gì?",
        personDobLabel: "Ngày tháng năm sinh của người này là gì? (Bắt buộc)",
        personGenderLabel: "Giới tính của người này là gì?",
        personSSNLabel: "Số An sinh Xã hội của người này là gì?",
      };
    case HI:
      return {
        aboutTitle: "Konsènan " + name,
        aboutMemberTitle: "Konsènan Manm " + name,
        labelMiddle: "Ki " + name + " (yo)dezyèm prenon?",
        labelLastName: "Ki siyati " + name + "non?",
        labelRelation: "Ki relasyon " + name + " genyen ak ou?",
        dateOfBirthQLabel: "Ki dat nesans " + name + "?",
        genderQLabel: "Ki  sèks " + name + "?",
        ssnQLabel: "Ki Nimewo Sekirite Sosyal " + name + "?",
        personMiddleName: "Ki dezyèm prenon moun sa a?",
        personLastName: "Ki siyati moun sa a?",
        personRelation: "Ki relasyon moun sa genyen ak ou?",
        personDobLabel: "Ki dat nesans moun sa a?",
        personGenderLabel: "Ki sèks moun sa a?",
        personSSNLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
      };
    default:
      return;
  }
}

export function summaryStaticMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        rightsText: "Rights, Responsibilities and Penalties",
        referenceNumberText: "Your reference number: ",
        downloadBeforeText: "You may want to",
        downloadAfterText: "for future reference.",
        downloadLinkText: "download your completed Recertification",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        earnedIncomeBold: "If anyone in the household has earned income, ",
        earnedIncomeNormal:
          "send us proof of income for the last four weeks, such as:",
        earnedIncomeSteps: [
          "Pay stubs, or record of payment",
          "Proof of any pay you got and hours worked",
          "Completed tax forms will all schedules attached, if self-employed",
        ],
        unearnedIncomeBold: "If anyone in the household has unearned income, ",
        unearnedIncomeNormal: "send us proof of the monthly amount, such as:",
        unearnedIncomeSteps: [
          "Benefit or award letter",
          "Statement from agency making payments",
        ],
        uneanrednoteText: "NOTE",
        uneanrednoteDetailText:
          "We can usually verify the amount of Social Security, SSI, DOR Child Support, or MA Unemployment Compensation benefits ourselves.  We will tell you if you need to verify any of these items.",
        leftJobBold:
          "If anyone in the household left a job since your last certification, ",
        // uneanrednoteSubDetailText: "If child support expenses has been changed display the following",
        leftJobNormal: "send us proof of the separation date, such as:",
        leftJobSteps: [
          "Layoff notice or letter from employer",
          "Unemployment claim or unemployment benefit information",
          "Other document showing when and why your job stopped",
        ],
        leftJobNoteDetailText: "Tell us the reason for leaving your job",
        householdAddressMovedBold: "If your household address has moved ",
        householdAddressMovedNormal:
          "and you did not report your new housing costs in the online Recertification form",
        householdAddressMovedSteps: [
          "Rent receipt, lease or land lord verification form",
          "Deed or mortgage statement.",
          "Shared housing verification form, or statement from someone you live with.",
        ],
        householdMoveNormal:
          "If your household has moved. You can report your new housing costs by submitting one of the following items:",
        householdMoveSteps: [
          "Rent receipt, lease, or Landlord Verification form",
          "Deed or mortgage statement",
          "Shared Housing Verification form, or statement from someone you live with",
        ],
        nonCitizenBoldText:
          "If a household member’s noncitizen status has changed ",
        nonCitizenNormalText: "please submit proof of the status, such as:",
        nonCitizenSteps: [
          "Permanent Resident Card (“green card”)",
          "Employment Authorization Card",
          "Temporary Resident Card",
          "Arrival‐Departure Record (I‐94)",
          "Stamp in passport",
          "Other document showing current or pending immigration status",
          "Statement from an immigration attorney about current or pending status",
        ],
        expenseTextBold:
          "If the total medical expenses have changed by at least $25, ",
        expenseTextNormal:
          "we will request proof of expenses (such as bills, invoices, or receipts).",
        expenseNoteDetailText:
          "You can be credited for the costs you are responsible for paying even if you are behind or not able to pay them. Medical costs include co‐pays, prescriptions ,over‐the‐counter medications, health insurance, medical bills, transportation, and more. Transportation costs for medical reasons can be self-declared.",
        expenseText:
          "If anyone in your house is senior (at least age 60) or has a disability, and the medical expenses have changed by at least $25, please send us proof of expenses (such as bills, invoices, or receipts).",
        childBoldText:
          "If anyone in the household is making payments for child support, ",
        childNormalText: "please send us:",
        childSteps:
          "Verification of the legal obligation to pay the child support (such as a court order) and proof of recent Payments.",
        childNoteDetailText:
          "Child support payments cannot be credited unless they are legally obligated and being paid.",
        voterTitle: "Voter Registration",
        voterTextBefore:
          "The National Voter Registration Act of 1993 requires DHR to give you the opportunity to register to vote. DHR mailed you a Voter Registration application with your SNAP Recertification form. Let DHR know if you need help to register. You may also register to vote online by clicking ",
        voterTextLink: "here.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency.  If you are not a U.S. citizen, you are not eligible to vote, and you should not register to vote.",
        sendDocumentBtnLabel: "Send a document to DHR",
        dontSendDocumentBtnLabel: "Or exit without uploading any documents",
        otherDocWays: "There are many other ways to submit documents to us.",
        findMoreText: "Find out more",
        rightsHHRemovedText:
          "Important: Clicking submit does not start the Recertification process. DHR must speak with you to confirm changes.",
      };
    case ES:
      return {
        referenceNumberText: "Su número de referencia:",
        downloadBeforeText: "Le recomendamos que",
        downloadAfterText: "para futuras consultas",
        rightsText: "Derechos, Responsabilidades y Penalidades",
        downloadLinkText: "descargue su informe preliminar completo",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        earnedIncomeBold:
          "Si alguien en el grupo familiar tiene ingresos provenientes del trabajo, ",
        earnedIncomeNormal:
          "envíenos un comprobante de ingresos de las últimas cuatro semanas",
        earnedIncomeSteps: [
          "Recibos de pago o registro de pago",
          "Comprobante de cualquier pago que haya recibido y las horas trabajadas",
          "Formularios tributarios completos con todas las escalas adjuntas, si es trabajador/a independiente",
        ],
        unearnedIncomeBold:
          "Si alguien en el grupo familiar tiene ingresos no provenientes del trabajo, ",
        unearnedIncomeNormal:
          "envíenos un comprobante del monto mensual, como:",
        unearnedIncomeSteps: [
          "Carta de concesión o beneficio",
          "Declaración de la agencia que realiza los pagos",
        ],
        uneanrednoteText: "Aclaración",
        uneanrednoteDetailText:
          "Generalmente podremos verificar nosotros mismos los montos de los beneficios del Seguro social, SSI, manutención infantil DOR o Compensación por desempleo de MA. Le informaremos si necesita verificar alguno de estos puntos.",
        leftJobBold:
          "Si algún miembro de su grupo familiar dejó de trabajar desde su última certificación, ",
        leftJobNormal:
          "envíenos un comprobante de la fecha de desvinculación, como:",
        leftJobSteps: [
          "Aviso de despido o carta del empleador",
          "Reclamación por desempleo o información sobre beneficios de desempleo",
          "Otros documentos que muestren cuándo y por qué dejó de trabajar. ",
        ],
        leftJobNoteDetailText: "Indique el motivo por el que dejó de trabajar.",
        householdAddressMovedBold: "Si la dirección de su hogar ha cambiado ",
        expenseText:
          "Si hay personas mayores en su hogar (60 años o más) o alguien es discapacitado, y los gastos médicos se modificaron en al menos $25, envíenos un comprobante de los gastos (como facturas o recibos).",
        householdAddressMovedNormal:
          "y usted no reportó sus nuevos costos de vivienda en el formulario de Recertificación",
        householdAddressMovedSteps: [
          "Formulário de recibo de aluguel",
          "locação ou comprovante de propriedade, Escritura o constancia de hipoteca",
          "Formulario de Verificación de vivienda compartida o declaración de alguna persona que viva con usted.",
        ],
        householdMoveNormal:
          "If your household has moved. You can report your new housing costs by submitting one of the following items:",
        householdMoveSteps: [
          "Rent receipt, lease, or Landlord Verification form",
          "Deed or mortgage statement",
          "Shared Housing Verification form, or statement from someone you live with",
        ],
        nonCitizenBoldText:
          "Si ha cambiado el estado de no ciudadano de un miembro del grupo familiar, ",
        nonCitizenNormalText: "presente un comprobante del estado, como:",
        nonCitizenSteps: [
          "Tarjeta de residente permanente ('tarjeta verde')",
          "Tarjeta de autorización de empleo",
          "Tarjeta de residente temporal",
          "Registro de llegada-salida (I-94)",
          "Sello en el pasaporte",
          "Otro documento que muestre el estado migratorio actual o pendiente",
          "Declaración de un abogado de migraciones sobre el estado actual o pendiente",
        ],
        expenseTextBold:
          "Si el total de gastos médicos cambió en al menos $25, ",
        expenseTextNormal:
          "solicitaremos comprobante de los gastos (como facturas o recibos)",
        expenseNoteDetailText:
          "Se le pueden abonar los costos que deba pagar, aunque esté atrasado/a o no pueda pagarlos.  Los costos médicos incluyen copagos, medicamentos de venta con receta, medicamentos de venta libre, seguro de salud, facturas médicas, transporte, etc.  Los costos de transporte por motivos médicos se pueden autodeclarar.",
        childBoldText:
          "Si algún miembro de su grupo familiar realiza pagos de manutención infantil, ",
        childNormalText: "envíenos lo siguiente:",
        rightsHHRemovedText:
          "Importante: Hacer clic en Presentar no inicia el proceso de Recertificación. DHR debe hablar con usted para confirmar los cambios.",
        childSteps:
          " Verificación de la obligación legal de pagar manutención infantil (como una orden judicial) y comprobantes de los pagos recientes.",
        childNoteDetailText:
          "Los pagos de manutención infantil no se pueden acreditar a menos que sean una obligación legal y se estén realizando.",
        voterTitle: "Registro de votantes",
        voterTextBefore:
          "La Ley Nacional de Registro de Votantes de 1993 exige que DHR le brinde la oportunidad de registrarse para votar. DHR le envió por correo una solicitud de Registro como votante con su formulario de Recertificación para el SNAP. Avise a DHR si necesita ayuda para registrarse. También puede registrarse para votar en línea haciendo clic ",
        voterTextLink: "aquí.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " La solicitud de registro o el rechazo del registro como votante no afectará el monto de la asistencia que esta agencia le proporcionará.  Si no es ciudadano/a estadounidense, no es elegible para votar y no debe registrarse como votante",
        sendDocumentBtnLabel: "Enviar documento a DHR",
        dontSendDocumentBtnLabel: "O salir sin subir documentos.",
        otherDocWays: "Hay muchas otras maneras de enviarnos los documentos.",
        findMoreText: "Obtenga más información.",
      };
    case PT:
      return {
        referenceNumberText: "Seu número de referência: ",
        downloadBeforeText: "Você pode querer",
        downloadAfterText: "para referência futura",
        rightsText: "Direitos, Responsabilidades e Penalidades",
        downloadLinkText: "baixar seu relatório provisório concluído",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        expenseText:
          "Se alguém em sua casa é idoso  (pelo menos 60 anos) ou tem uma deficiência, e as despesas médicas mudaram em pelo menos US =$25, envie-nos comprovantes de despesas (como contas, faturas ou recibos).",
        earnedIncomeBold:
          "Se alguém no seu domicílio tem rendimento proveniente de trabalho, ",
        earnedIncomeNormal:
          "envie-nos um comprovante de renda das últimas quatro semanas",
        earnedIncomeSteps: [
          "Comprovantes ou registro de pagamento",
          "Comprovante de qualquer pagamento que você recebeu e horas trabalhadas",
          "Formulários de impostos preenchidos com todos os horários anexados, se autônomo",
        ],
        unearnedIncomeBold:
          "Se alguém do domicílio tiver rendimento proveniente de trabalho, ",
        unearnedIncomeNormal: "envie-nos um comprovante do valor mensal, como:",
        unearnedIncomeSteps: [
          "Carta de benefícios ou gratificações",
          "Declaração da agência que faz pagamentos",
        ],
        uneanrednoteText: "Nota:",
        rightsHHRemovedText:
          "Importante: Clicar em enviar não inicia o processo de Recertificação. O DHR deve falar com você para confirmar as alterações.",
        uneanrednoteDetailText:
          "Geralmente, podemos verificar a quantia de benefícios de Seguro Social, SSI, DOR Child Support ou MA Unemployment Compensation nós mesmos. Nós informaremos se você precisar verificar algum desses itens.",
        leftJobBold:
          "Se alguém no domicílio saiu de um emprego desde a sua última certificação, ",
        leftJobNormal: "envie-nos um comprovante da data de separação, como:",
        leftJobSteps: [
          "Aviso de dispensa ou carta do empregador",
          "Reclamação de desemprego ou informações sobre subsídio de desemprego",
          "Outro documento mostrando quando e por que seu vínculo empregatício foi encerrado",
        ],
        leftJobNoteDetailText: "Informe o motivo para ter saído do seu emprego",
        householdAddressMovedBold:
          "Caso o seu endereço residencial tenha mudado ",
        householdAddressMovedNormal:
          "e você não informou os seus novos custos habitacionais no formulário on-line de recertificação",
        householdAddressMovedSteps: [
          "Formulário de recibo de aluguel",
          "locação ou comprovante de propriedade, Escritura ou declaração de hipoteca",
          "Formulário de verificação de domicílio compartilhado ou declaração de alguém com quem você mora.",
        ],
        householdMoveNormal:
          "If your household has moved. You can report your new housing costs by submitting one of the following items:",
        householdMoveSteps: [
          "Rent receipt, lease, or Landlord Verification form",
          "Deed or mortgage statement",
          "Shared Housing Verification form, or statement from someone you live with",
        ],
        nonCitizenBoldText:
          "Se o status de não cidadão de um membro do domicílio foi alterado ",
        nonCitizenNormalText: "envie um comprovante do novo status, como:",
        nonCitizenSteps: [
          "Cartão de residência permanente (“green card”)",
          "Cartão de autorização de emprego",
          "Cartão de residência temporária",
          "Registro de Entrada-Saída no País (I-94)",
          "Carimbo no passaporte",
          "Outro documento mostrando o status de imigração atual ou pendente",
          "Declaração de um advogado de imigração sobre o status atual ou pendente",
        ],
        expenseTextBold:
          "Se as despesas médicas totais tiverem mudado em pelo menos $25, ",
        expenseTextNormal:
          "solicitaremos comprovação de despesas (como faturas, notas ou recibos).",
        expenseNoteDetailText:
          "Você pode ser reembolsado pelos custos pelos quais é responsável por pagar, mesmo se estiver atrasado ou não puder pagá-los.   Os custos médicos incluem co-pagamentos, receitas, medicamentos de venda livre, seguro de saúde, contas médicas, transporte e muito mais.  Os custos de transporte por motivos médicos podem ser declarados automaticamente.",
        childBoldText:
          "Se alguém no domicílio está fazendo pagamentos de pensão alimentícia, ",
        childNormalText: "envie-nos:",
        childSteps:
          "Verificação da obrigação legal de pagar a pensão alimentícia (como uma ordem judicial) e a comprovação de pagamentos recentes.",
        childNoteDetailText:
          " Os pagamentos de pensão alimentícia não podem ser reembolsados a menos que sejam legalmente obrigados e pagos.",
        voterTitle: "Registro de eleitor",
        voterTextBefore:
          "O National Registro de eleitor Act de 1993 exige que o DHR lhe dê a oportunidade de se cadastrar para votar. O DHR lhe enviou um formulário de registro de eleitores com o formulário de recertificação SNAP. Informe DHR se precisar de ajuda para se cadastrar. Você também pode se cadastrar para votar on-line clicando ",
        voterTextLink: "aqui.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " Cadastrar-se ou não para votar não afetará a quantidade de assistência que será fornecida por esta agência.  Se você não for cidadão americano, não poderá votar e não deverá se cadastrar para votar.",
        sendDocumentBtnLabel: "Envie um documento para o DHR",
        dontSendDocumentBtnLabel:
          "Ou saia sem fazer o upload de nenhum documento",
        otherDocWays:
          "Existem muitas outras maneiras de enviar documentos para nós.",
        findMoreText: " Saiba mais",
      };

    case ZH: //chinees
      return {
        referenceNumberText: "您的参考编号：",
        downloadBeforeText: "您可能想要",
        downloadAfterText: "以供未来参考",
        downloadLinkText: "下载您填写好的中间报告",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        earnedIncomeBold: "如果家庭中任何人有劳动所得收入, ",
        earnedIncomeNormal: "请向我们寄送过去四周的收入证据",
        rightsText: "权利，责任和惩罚",
        earnedIncomeSteps: [
          "工资单或付款记录",
          "您得到的任何付款和工时证据",
          "填写好的纳税申请书（附上所有税率表）（如果您为自雇）",
        ],
        unearnedIncomeBold: "如果家庭中任何人有非劳动所得收入, ",
        unearnedIncomeNormal: "请向我们寄送每月收入证据，例如：",
        unearnedIncomeSteps: ["福利或授予函", "付款机构提供的声明"],
        uneanrednoteText: "注意",
        uneanrednoteDetailText:
          "我们可以经常自己验证社会保障、SSI、DOR 子女抚养费或 MA 失业赔偿福利的金额。如果您需要验证任何此项，我们会告知您相关结果。",
        leftJobBold: "如果在您上次认证之后家庭中任何人离职, ",
        leftJobNormal: "请向我们寄送离职日期的证据，例如：",
        leftJobSteps: [
          "雇主的裁员通知或信函",
          "失业索赔或失业救济信息",
          "表明您的工作结束日期和原因的其他文件",
        ],
        leftJobNoteDetailText: "告知我们您的离职原因",
        householdAddressMovedBold: "如果您的家庭地址搬迁过 ",
        householdAddressMovedNormal:
          "且您并未通过在线填写重新认证表格来报告您的最新住房费用",
        householdAddressMovedSteps: [
          "房租收据、租约或《房东验证》表格",
          "契约或按揭贷款月结单。",
          "《共享住房验证》表格或与您同居的某人提供的声明。",
        ],
        householdMoveNormal:
          "If your household has moved. You can report your new housing costs by submitting one of the following items:",
        householdMoveSteps: [
          "Rent receipt, lease, or Landlord Verification form",
          "Deed or mortgage statement",
          "Shared Housing Verification form, or statement from someone you live with",
        ],
        nonCitizenBoldText: "如果某个家庭成员的非公民状态发生变化 ",
        nonCitizenNormalText: "请提交状态证据，例如：",
        nonCitizenSteps: [
          "永久居留卡（“绿卡”）",
          "工作许可证",
          "临时居留卡",
          "出入境记录 (I‐94)",
          "盖章护照",
          "表明当前或未决移民状态的其他文件",
          "移民律师提供的关于当前或未决状态的声明",
        ],
        expenseTextBold: "如果医疗费用总额变动达到 $25 美元, ",
        expenseTextNormal: "我们会请求得到费用证据（例如账单、发票或收据）",
        expenseNoteDetailText:
          "对于您负责支付的费用，您可被入账，即使您延期支付或无力支付此等费用也会被入账。医疗费用包括共付额、处方、非处方药物、健康保险、医疗账单、交通等等。出于医疗原因的交通费用可自行声明。",
        childBoldText: "如果家庭中任何人支付子女抚养费, ",
        expenseText:
          "如果您家任何人为老年人（至少年满 60 岁）或有残障情况 而医疗费用的变化超过至少 $25 美元, 请向我们寄送费用证据（例如账单、发票或收据）。",
        childNormalText: "请向我们寄送：",
        childSteps:
          "支付子女抚养费之合法义务的验证文件（例如法院命令）以及近期付款的证据。",
        childNoteDetailText:
          "即使子女抚养费按合法义务支付，此等费用的付款也无法入账。",
        voterTitle: "选民登记",
        voterTextBefore:
          "《1993 年全国选民登记法》要求 DHR 让您有机会完成选民登记。DAT 向您邮寄《选民登记》申请表以及您的 SNAP《重新认证》表格。如果您在登记时需要帮助，请告知 DHR。您也可以点击此处在线完成选民登记。",
        voterTextLink: "here.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " 申请选民登记或拒绝选民登记均不会影响此机构向您提供的援助金额。如果您并非美国公民，您不符合选举投票资格，则您不应该进行选民登记。",
        sendDocumentBtnLabel: "向 DHR 寄送文件",
        dontSendDocumentBtnLabel: "或退出而不上传任何资料",
        otherDocWays: "向我们提交文件可通过很多其他方式。",
        findMoreText: "了解更多",
        rightsHHRemovedText:
          "重要提示: 点击提交并未开始《重新认证》流程。DHR 必须与您交谈从而确认更改。",
      };
    case VI:
      return {
        referenceNumberText: "Số tham chiếu của quý vị: ",
        downloadBeforeText: "Bạn có muốn",
        downloadAfterText: "để tham khảo trong tương lai",
        downloadLinkText: "tải về báo cáo tạm thời đã hoàn thành của mình",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        earnedIncomeBold:
          "Nếu bất kỳ thành viên nào trong hộ gia đình có thu nhập đã thu, ",
        earnedIncomeNormal:
          "vui lòng gửi cho chúng tôi bằng chứng về thu nhập trong bốn tuần qua:",
        earnedIncomeSteps: [
          "Bảng kê thu nhập hoặc hồ sơ thanh toán",
          "Bằng chứng về bất kỳ khoản lương nào quý vị nhận được và số giờ làm việc",
          "Các biểu mẫu về thuế đã được hoàn tất với tất cả các lịch đóng thuế đính kèm, nếu quý vị là chủ",
        ],
        unearnedIncomeBold:
          "Nếu bất kỳ thành viên nào trong hộ gia đình đã có thu nhập chưa thu, ",
        unearnedIncomeNormal:
          "vui lòng gửi cho chúng tôi bằng chứng về số tiền hàng tháng:",
        unearnedIncomeSteps: [
          "Thư về lợi ích hoặc giải thưởng",
          "Báo cáo từ các cơ quan thanh toán",
        ],
        uneanrednoteText: "LƯU Ý",
        rightsText: "Quyền, Trách nhiệm & Hình phạt",
        uneanrednoteDetailText:
          "Chúng tôi thường có thể tự xác minh số tiền từ các lợi ích về An sinh Xã hội, Thu Nhập An Sinh Bổ Sung (SSI), Trợ cấp nuôi con theo yêu cầu của Sở Thuế Vụ (DOR) hoặc các lợi ích về trợ cấp thất nghiệp của Massachusets. Chúng tôi sẽ cho quý vị biết nếu quý vị cần xác minh bất kỳ mục nào trong số này.",
        leftJobBold:
          "Nếu bất cứ thành viên nào trong gia đình nghỉ  việc kể từ lần chứng nhận cuối cùng của quý vị, ",
        leftJobNormal:
          "hãy gửi cho chúng tôi bằng chứng về ngày nghỉ việc, chẳng hạn như:",
        leftJobSteps: [
          "Thông báo sa thải hoặc thư từ người sử dụng lao động",
          "Yêu cầu nhận trợ cấp thất nghiệp hoặc thông tin trợ cấp thất nghiệp",
          "Giấy tờ khác cho biết khi nào và tại sao quý vị nghỉ việc",
        ],
        leftJobNoteDetailText: "Hãy cho chúng tôi biết lý do quý vị nghỉ việc",
        householdAddressMovedBold: "Nếu gia đình của quý vị đã dời địa chỉ  ",
        householdAddressMovedNormal:
          "và quý vị không báo cáo chi phí nhà ở mới trong đơn Tái xác định trên mạng",
        householdAddressMovedSteps: [
          "Biên lai thuê, hợp đồng thuê",
          "hoặc mẫu Xác minh chủ nhà, Chứng thư hoặc báo cáo về thế chấp",
          "Biểu mẫu Xác minh Nhà ở được chia sẻ hoặc báo cáo từ người quý vị sống cùng",
        ],
        householdMoveNormal:
          "If your household has moved. You can report your new housing costs by submitting one of the following items:",
        householdMoveSteps: [
          "Biên lai thuê, hợp đồng thuê, hoặc mẫu Xác minh chủ nhà, Chứng thư hoặc báo cáo về thế chấp, Biểu mẫu Xác minh Nhà ở được chia sẻ hoặc báo cáo từ người quý vị sống cùng",
        ],
        nonCitizenBoldText:
          "Nếu tình trạng không phải công dân của thành viên trong hộ gia đình đã thay đổi, ",
        nonCitizenNormalText:
          "vui lòng gửi bằng chứng về tình trạng này, ví dụ như:",
        expenseText:
          "Nếu bất cứ ai trong nhà bạn đều là người cao tuổi (ít nhất 60 tuổi) hoặc bị khuyết tật, và chi phí y tế đã thay đổi ít nhất 25 đô la, vui lòng gửi cho chúng tôi bằng chứng về các chi phí (như hóa đơn, biên lai hoặc biên nhận).",
        nonCitizenSteps: [
          "Thẻ thường trú nhân (“Thẻ xanh”)",
          "Giấy phép làm việc",
          "Thẻ tạm trú",
          "Hồ sơ nhập-xuất cảnh (I-94)",
          "Con dấu trên hộ chiếu",
          "Giấy tờ khác cho thấy tình trạng nhập cư hiện tại hoặc đang chờ xử lý",
          "Báo cáo từ luật sư di trú về tình trạng hiện tại hoặc đang chờ xử lý",
        ],
        expenseTextBold: "Nếu tổng chi phí y tế đã thay đổi ít nhất $25 Đô la,",
        expenseTextNormal:
          "chúng tôi sẽ yêu cầu bằng chứng về chi phí (như hóa đơn hoặc biên lai).",
        expenseNoteDetailText:
          " Quý vị có thể trả sau cho các chi phí mà quý vị phải chịu trách nhiệm thanh toán ngay cả khi quý vị đã quá hạn thanh toán hoặc không có khả năng thanh toán. Các chi phí y tế bao gồm các khoản đồng thanh toán, đơn thuốc, thuốc không kê đơn, bảo hiểm y tế, hóa đơn y tế, vận chuyển và các khoản khác. Chi phí vận chuyển vì lý do y tế có thể được tự kê khai.",
        childBoldText:
          "Nếu bất kỳ thành viên nào trong hộ gia đình đang thanh toán khoản trợ cấp nuôi con, ",
        childNormalText: "xin vui lòng gửi cho chúng tôi:",
        childSteps:
          "Xác minh nghĩa vụ pháp lý chi trả khoản trợ cấp nuôi con (ví dụ như lệnh tòa án) và bằng chứng của các khoản thanh toán gần đây.",
        childNoteDetailText:
          "Các khoản trợ cấp nuôi con không được trả sau nếu chúng không bị bắt buộc về mặt pháp lý và đang được chi trả.",
        voterTitle: "Đăng ký cử tri",
        voterTextBefore:
          "The National Đăng ký cử tri Act of 1993 requires DHR to give you the opportunity to register to vote. DHR mailed you a Voter Registration application with your SNAP Recertification form. Let DHR know if you need help to register. You may also register to vote online by clicking ",
        voterTextLink: "here.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency.  If you are not a U.S. citizen, you are not eligible to vote, and you should not register to vote.",
        sendDocumentBtnLabel: "Gửi giấy tờ tới DHR",
        dontSendDocumentBtnLabel:
          "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
        otherDocWays: "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
        findMoreText: "Tìm hiểu thêm",
        rightsHHRemovedText:
          "Nhập khẩu: Việc nhấp vào nút gửi sẽ không khởi động quá trình Chứng  nhận lại. DHR phải nói chuyện với quý vị để xác nhận những thay đổi.",
      };
    case HI:
      return {
        rightsText: "Dwa, Responsablite ak Sanksyon",
        referenceNumberText: "Nimewo referans ou: ",
        downloadBeforeText: "Ou ka vle",
        downloadAfterText: "pou referans alavni.",
        downloadLinkText: "telechaje Resètifikasyon fini w lan",
        //householdMovedText: "You have reported that your household has moved. You can report your new housing costs ",
        //houseHoldMoveLinkText: "here",
        earnedIncomeBold: "Si nenpòt moun nan fwaye a gen revni, ",
        earnedIncomeNormal:
          "voye ban nou prèv revni pou kat dènye semèn yo, tankou:",
        earnedIncomeSteps: [
          "Souch chèk, oswa dosye peman",
          "Prèv nenpòt salè ou te resevwa ak konbyen lè ou te travay",
          "Fòm taks ranpli ak tout orè yo atache, si ou se travayè endepandan",
        ],
        unearnedIncomeBold:
          "Si nenpòt moun nan fwaye gen revni ki pa fèt nan travay, ",
        unearnedIncomeNormal: "voye ban nou prèv montan mansyèl la, tankou:",
        unearnedIncomeSteps: [
          "Lèt benefis oswa kalifikasyon",
          "Deklarasyon ajans ki fè peman yo",
        ],
        uneanrednoteText: "REMAK",
        uneanrednoteDetailText:
          "Anjeneral nou ka verifye montan benefis Sekirite Sosyal, SSI, Sipò Timoun DOR, oswa MA Konpansasyon Chomaj. Nou pral di w si w bezwen verifye nenpòt nan bagay sa yo.",
        leftJobBold:
          "Si nenpòt moun nan fwaye a kite yon travay depi dènye sètifikasyon w lan, ",
        // uneanrednoteSubDetailText: "If child support expenses has been changed display the following",
        leftJobNormal: "voye ban nou prèv dat separasyon an, tankou:",
        leftJobSteps: [
          "Avi revokasyon oswa lèt patwon",
          "Enfòmasyon sou demann chomaj oswa benefis chomaj",
          "Lòt dokiman ki montre ki lè ak poukisa travay ou te sispann",
        ],
        leftJobNoteDetailText: "Di nou rezon ki fè ou kite travay ou",
        householdAddressMovedBold: "Si adrès fwaye w la te chanje ",
        householdAddressMovedNormal:
          "epi ou pa rapòte nouvo depans ou yo pou lojman nan fòm Resètifikasyon an liy lan",
        householdAddressMovedSteps: [
          "Resi lwaye, kontra lwaye oswa fòm verifikasyon mèt kay",
          "Tit pwopriete oswa deklarasyon prè ipotèk.",
          "Fòm verifikasyon lojman pataje, oswa deklarasyon yon moun w ap viv ak li.",
        ],
        householdMoveNormal:
          "Si fwaye w la demenaje. Ou ka rapòte nouvo depans ou yo pou lojman nan soumèt youn nan eleman sa yo:",
        householdMoveSteps: [
          "Resi lwaye, kontra lwaye oswa fòm verifikasyon mèt kay",
          "Tit pwopriete oswa deklarasyon prè ipotèk.",
          "Fòm verifikasyon lojman pataje, oswa deklarasyon yon moun w ap viv ak li",
        ],
        nonCitizenBoldText: "Si estati yon manm non-sitwayen fwaye a chanje, ",
        nonCitizenNormalText: "tanpri soumèt prèv estati a, tankou:",
        nonCitizenSteps: [
          "Kat rezidan pèmanan (“kat vèt”)",
          "Kat Otorizasyon Travay",
          "Kat Rezidan Tanporè",
          "Dosye Rive-Depa (I-94)",
          "Tenb nan paspò",
          "Lòt dokiman ki montre estati imigrasyon aktyèl oswa annatant",
          "Deklarasyon yon avoka imigrasyon sou estati aktyèl oswa annatant",
        ],
        expenseTextBold: "Si depans medikal total yo te chanje pa omwen $25, ",
        expenseTextNormal:
          "nou pral mande prèv depans (tankou bòdwo, fakti, oswa resi).",
        expenseNoteDetailText:
          "Ou ka resevwa kredi pou depans yo ou responsab pou peye menm si ou an reta oswa ou pa kapab peye yo. Depans medikal yo enkli ko-peman, preskripsyon, medikaman san preskripsyon, asirans sante, bòdwo medikal, transpò, ak plis ankò. Ou ka deklare oumenm depans pou transpò pou rezon medikal.",
        expenseText:
          "Si nenpòt moun lakay ou se granmoun aje (omwen laj 60 an) oswa gen yon andikap, epi depans medikal yo te chanje pa omwen $25, tanpri voye ban nou prèv depans yo (tankou bòdwo, fakti, oswa resi).",
        childBoldText: "Si yon moun nan fwaye a ap fè peman pou sipò timoun, ",
        childNormalText: "tanpri voye ban nou:",
        childSteps:
          "Verifikasyon obligasyon legal pou peye sipò timoun nan (tankou yon òdonans tribinal) ak prèv Peman resan yo.",
        childNoteDetailText:
          "Peman pou sipò timoun yo pa ka resevwa kredi sof si yo anba obligasyon legal epi peman yo ap fèt.",
        voterTitle: "Enskripsyon Elektè",
        voterTextBefore:
          "Lwa Nasyonal Enskripsyon Elektè 1993 la egzije DHR pou ba ou opòtinite pou enskri pou vote. DHR te voye yon aplikasyon pou Enskripsyon Elektè ba ou ansanm ak fòm Resètifikasyon SNAP ou a. Fè DHR konnen si w bezwen èd pou enskri. Ou ka enskri tou pou vote an liy nan klike ",
        voterTextLink: "la a.",
        voterLink: "https://www.sec.state.ma.us/ovr/",
        voterTextAfter:
          " Aplike pou enskri oswa refize enskri pou vote pa pral afekte montan asistans ajans sa a pral ba w la. Si ou pa yon Sitwayen Ameriken, ou pa kalifye pou vote, epi ou pa ta dwe enskri pou vote.",
        sendDocumentBtnLabel: "Voye yon dokiman bay DHR",
        dontSendDocumentBtnLabel: "Oswa sòti san w pa telechaje okenn dokiman",
        otherDocWays: "Gen plizyè lòt fason pou soumèt dokiman yo ban nou.",
        findMoreText: "Jwenn plis enfòmasyon",
        rightsHHRemovedText:
          "Enpòtan: Klike sou soumèt pa kòmanse pwosesis Resètifikasyon an. DHR dwe pale ak ou pou konfime chanjman yo.",
      };
    default:
      return;
  }
}

export function nonCitizenStaticMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        question:
          "Has citizenship status changed for anyone in your household? (Required)",
        LPR: "Legal permanent resident",
      };
    case ES:
      return {
        question:
          "¿ha cambiado el estado de ciudadanía de alguna de las personas de su grupo familiar? (Obligatorio)",
      };
    case PT:
      return {
        question:
          "O status de cidadania mudou para qualquer pessoa em seu agregador familiar? (Obrigatório)",
      };

    case ZH: //chinees
      return {
        question: "您的家庭中是否有任何人的公民身份状态发生变化？ (必填)",
      };
    case VI:
      return {
        question:
          "Có bất cứ thay đổi nào về tình trạng công dân đối với bất kỳ ai trong gia đình của quý vị không? (Bắt buộc)",
      };
    case HI:
      return {
        question:
          "Has citizenship status changed for anyone in your household? (Required)",
        LPR: "Rezidan Pèmanan Legal",
      };
    default:
      return;
  }
}
 
  
 

export function termsLanguageTranslation(language) {
  switch (language) {
    case types.ENGLISH:
      return {
        tanfheadone: "Department of Human Resources (DHR)",  
        tanfheadtwo: "Notice of Rights, Responsibilities and Penalties",
        tanftermheadone: "HHS Nondiscrimination Statement",
        tanftermheadtwo: "Right to Register to Vote:",
        tanfParaone: "The Alabama Department of Human Resources complies with applicable Federal civil rights laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex. The Alabama Department of Human Resources does not exclude people or treat them differently because of race, color, national origin, age, disability, or sex. ",
        tanfParatwo: "The Alabama Department of Human Resources provides: ",
        tanfParathree: "If you need these services, please contact your local county office.",
        tanfParafour: "If you believe that this agency has failed to provide these services or discriminated in another way on the basis of race, color, national origin, age, disability, or sex, you can file a grievance with: The Alabama Department of Human Resources Office of Civil Rights, Gordon Persons Building 50 N. Ripley Street, Montgomery, Al. 36130-4000, (334)242-1550, oeecr@dhr.alabama.gov. You can file a grievance in person, by mail, or email. If you need help filing a grievance, The Office of Civil Rights is available to help you at (334) 242-1550. ",
        tanfParafive: "You can also file a civil rights complaint with the U.S. Department of Health and Human Services, Office for Civil Rights electronically through the Office for Civil Rights Complaint Portal, available at https://ocrportal.hhs.gov/ocr/portal/lobby.jsf, or by mail or phone at: U.S. Department of Health and Human Services, 200 Independence Avenue SW., Room 509F, HHH Building, Washington, DC 20201, 1-800-868-1019, 800-537-7697 (TDD). Complaint forms are available at http://www.hhs.gov/ocr/office/file/index.html",
        tanfParasix: "https://sos.alabama.gov/alabama-votes/voter/register-to-vote ",
        tanfParaseven: "The National Voter Registration Act (NVRA) requires the Department of Human Resources to provide you with an opportunity to register to vote. ",
        tanfParaeight: "Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency. ",
        tanfParanine: "If you are already registered to vote at your current address, you do not need to re-register. If you would like to register to vote or would like to change your address for voting purposes, you may do so through the Secretary of State’s office at the following link: ",
        tanfParaten: "Secretary of State",
        tanfParaeleven: "If you would like help in filling out the voter registration form, we will help you. You may seek assistance with the application form at the time of your interview or by calling your local Department of Human Resources office located within your county. The decision to seek or accept help is yours. You may fill out the application form in private.",
        tanfParatwelve: "If you choose to apply to register to vote or if you decline to register to vote, the information on your application form will remain confidential and will be used for voter registration purposes only.",
        tanfParathirteen: "If you believe that someone has interfered with your right to register or to decline to register to vote, your right to privacy in deciding whether to register or in applying to register to vote, or your right to choose your own political party or other political preference, you may file a complaint with the Secretary of State at State Capitol, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 or by calling 334-242-7210 or 1-800-274-VOTE (1-800-274-8683).",
        tanfListing: [
          "Free aids and services to people with disabilities to communicate effectively with us, such as: Braille, large print, audiotape, American Sign Language, etc.",
          "Qualified sign language interpreters ",
          "Written information in other formats (large print, audio, accessible electronic formats, other formats) ",
          "Free language services to people whose primary language is not English, such as: Qualified interpreters and Information written in other languages",
        ],
        tanfCertify: "I certify that the information provided in this redetermination is true and complete to the best of my knowledge. I understand that if my completed, signed form is not received by ALDHR on time, my benefits will be held and my case may be terminated.",
        termsHeader: "Rights, Responsibilities and Penalties",
        headerLabel:
          "Statement of Understanding",
        certifyContents:
          "When your household receives food assistance benefits, you must follow all the rules. You must provide true and complete information about everyone in your household and you must provide documents to prove what you say if you are asked to by the worker. Any member of your household who breaks any of these rules on purpose can be barred from SNAP for 1 year for first offense, 2 years for second offense, and permanently for third offense; fined up to $250,000, imprisoned up to 20 years or both; and subject to prosecution under other federal laws. She/he may also be barred from the Food Assistance Program for an additional 18 months if court ordered. DO NOT give false information or hide information to get or continue to get SNAP benefits. DO NOT trade or sell EBT cards. DO NOT alter EBT cards to get SNAP benefits you are not entitled to receive. DO NOT use SNAP benefits to buy ineligible items such as alcohol and tobacco or to pay on credit accounts. DO NOT use someone else’s SNAP benefits or EBT card for your household. Individuals determined by a court to have committed the following program violations will be subject to the following penalties:",
        signingApplication:
          "I certify under penalty of perjury that my answers to all questions about each household member, including those about citizenship or alien status, are correct and complete.",
        penaltiesList: [
          "If you are found to have used or received benefits in a transaction involving the sale of a controlled substance, you will be ineligible to receive SNAP benefits for a period of two years for the first offense and permanently upon the second such offense.",
          "If you are found to have used or received benefits in a transaction involving the sale of firearms, ammunition or explosives, you will be permanently ineligible to receive SNAP benefits upon the first occasion of such violation.",
          "If you have been found guilty of having trafficked benefits for an aggregate amount of $500 or more, you will be permanently ineligible to receive SNAP benefits upon the first occasion of such violation.",
          "If you have been found to have made a fraudulent statement or representation with respect to your identity or place of residence in order to receive multiple SNAP benefits simultaneously, you will be ineligible to participate in the program for a period of 10 years.",
          "If you are fleeing to avoid prosecution, custody, or confinement, after conviction for a crime or an attempt to commit a crime, which is a felony, or are in violation of probation or parole imposed under a federal or state law, you are ineligible for food assistance.",
          "If you are convicted of using or receiving food assistance benefits in a transaction involving the sale of a controlled substance, you will be ineligible 24 months for the first violation and permanently for the second violation.",
          "If you are convicted of a federal or state felony that has an element the possession, use, or distribution of a controlled substance, you may be ineligible for food assistance."
        ],
        understandList: [
          "I understand that information provided by me in connection with this application for food assistance benefits will be subject to verification by federal, state and local officials to determine if such information is true. I further understand that if any such information is found to be untrue or incorrect, my application for food assistance benefits may be denied or terminated and I may be subject to criminal prosecution for knowingly providing incorrect information.",
          "I understand the information given is needed to determine my eligibility for food assistance. I understand all information is confidential and any use or disclosure without my permission will be made only for certain limited purposes allowed under State or Federal laws and regulations. Such purposes include, but are not limited to establishing eligibility, determining benefit amounts, and for providing services to applicants and recipients.",
          "I agree to provide information needed to determine eligibility for my household. I understand that I may have to provide documents to prove what I have said. I understand that if documents are not available, I have to give the name of a person or organization my food stamp worker may contact to obtain the necessary proof.",
          "I certify to the best of my knowledge, under penalty of perjury, that all household members for whom benefits are being sought are U.S. citizens or aliens legally admitted to the United States.",
          "I understand the information given on this application will be checked by using the State Income and Eligibility Verification System, other computer matching systems, program reviews and audits. In addition, any information given may also be checked by other Federal Aid Programs and Federally Aided State Programs such as school lunch, Family Assistance, and Medicaid. If any information is incorrect, my household may be denied food assistance benefits. If I give false information on purpose, legal or administrative action may be taken against me. I may have to repay food assistance benefits I receive to which I am not entitled.",
          "I understand that my household will be ineligible for benefits if I refuse to cooperate in completing the application process or in subsequent reviews of my eligibility including reviews resulting from reported changes, recertification, or as part of a State or Federal Quality Control Review.",
          "I understand that my signature on this application will serve as my authorization for State and Federal Quality Control Reviewers to verify my household circumstances for food assistance eligibility purposes.",
          "I understand the work requirements and that my household or a household member may be disqualified from receiving food assistance benefits for failure to cooperate.",
          "I understand that if any member of my household voluntarily quits a job or reduces the number of hours s/he works without good cause, my household or a household member may be disqualified from receiving food assistance benefits.",
          "I understand that I may request a conference or a fair hearing if I am not satisfied with any decisions of the County Department. This request may be made orally or in writing. My case may be presented at the hearing by any person I choose.",
          "I understand that my household will not receive an increase in food assistance benefits if anyone in my household fails to comply with the requirements of another means tested program (Family Assistance, SSI).",
          "I understand that some able bodied food stamp recipients will only be eligible to receive food assistance for 3 months in a 36 month period, unless they work 20 hours a week or participate in certain work and training programs. I understand my worker will advise me if this provision applies to anyone in my household.",
          "I understand that I am not to: give false information or hide information to get or continue to get food assistance benefits; use someone else's food assistance benefits; or use food assistance benefits to buy ineligible items such as alcoholic drinks or tobacco.",
          "I declare under penalty of perjury, that I have read or had read to me all statements on the application and the information is true and correct to the best of my knowledge."
        ],
        understandDta:
          "I understand that the Department of Transitional Assistance (DHR) administers SNAP. I understand that DHR has 30 days from the date of application to process my application. Further, I understand that:",
        foodNutritionact:
          "The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social Security Number (SSN) and the SSN of each household member I apply for. DHR uses this information to determine my household’s eligibility for SNAP. DHR verifies this information through computer matching programs. I understand that DHR uses it to monitor compliance with program regulations.",
        reportingRulesDtachanges:
          "Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR)  and recertification with the exception of:",
        incomeExceeds:
          "If my household’s income exceeds the gross income threshold",
        workRequirementlabel:
          "If I am under the able-bodied adult without dependents (ABAWD) work requirements and my work hours drop below 20 hours weekly",
        verifiedInfomation:
          "If DHR receives verified information about my household, my benefit amount may change",
        tbaRulesone:
          "If I am not under the SNAP Simplified Reporting rules or Transitional Benefits Alternative (TBA) rules, I must report to DHR changes to my household that may affect our eligibility. I understand that I must report these changes to DHR in person, in writing or by phone ",
        tbaRuletwo: " within 10 days of the change.",
        tbaExample:
          " For example, you must report changes in your household’s income, size, or address.",
        findmeIneligible:
          "I have a right to speak to a supervisor if DHR finds me ineligible for emergency SNAP benefits and I disagree. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my benefits by the seventh calendar day after I applied for SNAP. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my Electronic Benefit Transfer (EBT) card by the seventh calendar day after I applied for SNAP.",
        verificationtoDta:
          "I may receive more SNAP benefits if I report and give verification to DHR of:",
        childorOtherTitile:
          "child or other dependent care costs, shelter costs, and/or utility costs",
        legallyObligatedlabel:
          "legally-obligated child support to a nonhousehold member",
        disabledMedicalcost:
          "If I am 60 years or older or if I am disabled and I pay for medical costs, I can report and give verification of these costs to DHR. This may make me eligible for a deduction and increase my SNAP benefits.",
        meetExemptionlabel:
          "Unless they meet an exemption, all SNAP recipients between the ages of 16 and 59 are work registered and subject to General SNAP Work Requirements. SNAP recipients between the ages of 18 and 49 may also be subject to the ABAWD Work Program requirements. DHR will inform nonexempt household members of the work requirements. DHR will inform nonexempt household members of exceptions and penalties for noncompliance.",
        voluntarilyParticipatelabel:
          "Most SNAP recipients may voluntarily participate in education and employment training services through the SNAP Path to Work program. DHR will give referrals to the SNAP Path to Work program if appropriate",
        dtaNamescontact:
          "DHR may also share the names and contact information of SNAP recipients with SNAP Path to Work providers for recruitment purposes. I understand that members of my household may be contacted by DHR SNAP Path to Work specialists or contracted providers to explore SNAP Path to Work participation options. For more information about the SNAP Path to Work program, visit ",
        subjectToverification:
          "I understand that the information I give with my application will be subject to verification to determine if it is true. If any information is false, DHR may deny my SNAP benefits. I may also be subject to criminal prosecution for providing false information.",
        proveInformation:
          "Get documents to prove information on this application with other state agencies, federal agencies, local housing authorities, out-of-state welfare departments, financial institutions and from Equifax Workforce Solutions. I also give permission to these agencies to give DHR information about my household that concerns my SNAP benefits.",
        immigrationStatusLabel:
          "If applicable, verify my immigration status through the United States Citizenship and Immigration Services (USCIS). I understand that DHR may check information from my SNAP application with USCIS. Any information received from USCIS may affect my household’s eligibility and amount of SNAP benefits. ",
        secondaryEducation:
          "Share information about me and my dependents under age 19 with the Department of Elementary and Secondary Education (DESE). DESE will certify my dependents for school breakfast and lunch programs. ",
        publicHealth:
          "Share information about me, my dependents under age 5 and anyone pregnant in my household with the Department of Public Health (DPH). DPH refers these individuals to the Women,  Infants and Children (WIC) Program for nutrition services.",
        executiveOfficeHealth:
          "Share information, along with the Alabama Executive Office of Health and Human Services, about my eligibility for SNAP with electric companies, gas companies and eligible phone and cable carriers to certify my eligibility for discount utility rates.",
        housingCommuntiy:
          "Share my information with the Department of Housing and Community Development (DHCD) for the purpose of enrolling me in the Heat & Eat Program. ",
        lowerBenefitslabel:
          "DHR may deny, stop or lower my benefits based on information from Equifax Workforce Solutions. I have the right to a free copy of my report from Equifax if I request it within 60 days of DHR’s decision. I have the right to question the accuracy or completeness of the information in my report. I may contact Equifax at: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
        tollFreeLabel: "",
        rightoKnowbrochure:
          "I understand that I will get a copy of the “Your Right to Know” brochure and the SNAP Program brochure. I will read or have read to me the brochures and I must understand their contents and my rights and responsibilities. If I have any questions about the brochures or any of this information, I will contact DHR. If I have trouble reading or understanding any of this information, I will contact DHR. DHR can be reached at:",
        lawfullyResiding:
          "I swear that all members of my SNAP household requesting SNAP benefits are either U.S. citizens or lawfully residing noncitizens.",
        registerVoteTitle: "Right to Register to Vote",
        registerVoteFirstLabel:
          "I understand I have the right to register to vote at DHR. I understand that DHR will help me fill out the voter registration application form if I want help. I am allowed to fill out the voter registration application form in private.",
        registerVoteSecondLabel:
          "I understand that applying to register or declining to register to vote will not affect the amount of benefits I get from DHR. ",
        penaltyWarningTitle: "SNAP Penalty Warning",
        penaltyWarningContent:
          "I understand that if I or any member of my SNAP household intentionally breaks any of the rules listed below, that person will not be eligible for SNAP for one year after the first violation, two years after the second violation and forever after the third violation. That person may also be fined up to $250,000, imprisoned up to 20 years or both. S/he may also be subject to prosecution under other applicable Federal and State laws. These rules are:",
        penaltyWarningOne:
          "Do not give false information or hide information to get SNAP benefits.",
        penaltyWarningTwo: "Do not trade or sell SNAP benefits.",
        penaltyWarningThree:
          "Do not alter EBT cards to get SNAP benefits you are not eligible to get.",
        penaltyWarningFour:
          "Do not use SNAP benefits to buy ineligible items, such as alcoholic drinks and tobacco.",
        penaltyWarningFive:
          "Do not use someone else’s SNAP benefits or EBT card, unless you are an authorized representative.",

        penaltiesTitle: "I also understand the following penalties: ",
        individualLabel: "Individuals who commit a",
        cashProgram: "cash program",
        internationalProgram:
          " Intentional Program Violation (IPV) will be ineligible for SNAP for the same period the individual is ineligible from cash assistance.",
        penalitiesTwo:
          "Individuals who make a fraudulent statement about their identity or residency to get multiple SNAP benefits at the same time will be ineligible for SNAP for  ",
        tenYearLabel: "ten years",
        penalitiesThree:
          "Individuals who trade (buy or sell) SNAP benefits for a controlled substance/illegal drug(s), will be ineligible for SNAP for ",
        twoYearLabel: "two years ",
        penalitiesThreeMiddle: "for the first finding, and",
        foreverText: "forever ",
        penalitiesThreeLast: "for the second finding.",
        penalitiesFour:
          "Individuals who trade (buy or sell) SNAP benefits for firearms, ammunition or explosives, will be ineligible for SNAP",
        penalitiesFive:
          "Individuals who trade (buy or sell) SNAP benefits having a value of $500 or more, will be ineligible for SNAP",
        penalitiesSix:
          "The State may pursue an IPV against an individual who makes an offer to sell SNAP benefits or an EBT card online or in person",
        penalitiesSeven:
          "Individuals who are fleeing to avoid prosecution, custody or confinement after conviction for a felony, or are violating probation or parole, are ineligible for SNAP.",
        penalitiesEight:
          "Paying for food purchased on credit is not allowed and can result in disqualification from SNAP.",
        penaltiesNine:
          "Individuals may not buy products with SNAP benefits with the intent to discard the contents and return containers for cash.",

        interpreterHeader: "Right to an Interpreter",
        interpreterContent:
          "I understand that I have a right to an interpreter provided by DHR if no adult in my SNAP household is able to speak or understand English. I also understand that I can get an interpreter for any DHR fair hearing or bring one of my own. If I need an interpreter for a hearing, I must call the Division of Hearings at least one week before the hearing date.",
        nonDiscriminationHeader: "Nondiscrimination Statement",
        nonDiscriminationContent:
          "In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, sex, religious creed, disability, age, political beliefs, or reprisal or retaliation for prior civil rights activity in any program or activity conducted or funded by USDA.",
        personDisabilities:
          "Persons with disabilities who require alternative means of communication for program information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should contact the Agency (State or local) where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at ",
        programInformation:
          "Additionally, program information may be made available in languages other than English.,",

        programComplaint:
          "To file a program complaint of discrimination, complete the USDA Program Discrimination Complaint Form, (AD-3027) found online at: ",
        usdaOffice:
          "and at any USDA office, or write a letter addressed to USDA and provide in the letter all of the information requested in the form. To request a copy of the complaint form, call ",
        sumbitYourLabel: "Submit your completed form or letter to USDA by:",

        mailAddress: "mail: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " or",
        eamilTitle: "email: ",
        thisInstution: "This institution is an equal opportunity provider.",
      };

    case types.SPANISH:
      return {
        tanfheadone: "Departamento de Recursos Humanos (DRH)",  
        tanfheadtwo: "Aviso de Derechos, Responsabilidades y Sanciones",
        tanftermheadone: "Declaración de no discriminación del HHS",
        tanftermheadtwo: "Derecho a registrarse para votar:",
        tanfParaone: "El Departamento de Recursos Humanos de Alabama cumple con las leyes federales de derechos civiles aplicables y no discrimina por motivos de raza, color, origen nacional, edad, discapacidad o sexo. El Departamento de Recursos Humanos de Alabama no excluye a las personas ni las trata de manera diferente por motivos de raza, color, origen nacional, edad, discapacidad o sexo.",
        tanfParatwo: "El Departamento de Recursos Humanos de Alabama proporciona:",
        tanfParathree: "Si necesita estos servicios, comuníquese con la oficina local de su condado.",
        tanfParafour: "Si cree que esta agencia no le proporcionó estos servicios o lo discriminó de otra manera por motivos de raza, color, origen nacional, edad, discapacidad o sexo, puede presentar una queja ante: La Oficina de Recursos Humanos del Departamento de Alabama. Derechos Civiles, Gordon Persons Building 50 N. Ripley Street, Montgomery, Alabama. 36130-4000, (334)242-1550, oeecr@dhr.alabama.gov. Puede presentar una queja en persona, por correo postal o correo electrónico. Si necesita ayuda para presentar una queja, la Oficina de Derechos Civiles está disponible para ayudarle al (334) 242-1550.",
        tanfParafive: "También puede presentar una queja de derechos civiles ante la Oficina de Derechos Civiles del Departamento de Salud y Servicios Humanos de EE. UU. electrónicamente a través del Portal de Quejas de la Oficina de Derechos Civiles, disponible en https://ocrportal.hhs.gov/ocr/portal/lobby. jsf, o por correo o por teléfono a: Departamento de Salud y Servicios Humanos de EE. UU., 200 Independence Avenue SW., Room 509F, HHH Building, Washington, DC 20201, 1-800-868-1019, 800-537-7697 (TDD) . Los formularios de quejas están disponibles en http://www.hhs.gov/ocr/office/file/index.html",
        tanfParasix: "https://sos.alabama.gov/alabama-votes/voter/register-to-vote",
        tanfParaseven: "La Ley Nacional de Registro de Votantes (NVRA) exige que el Departamento de Recursos Humanos le brinde la oportunidad de registrarse para votar.",
        tanfParaeight: "Solicitar registrarse o negarse a registrarse para votar no afectará la cantidad de asistencia que le brindará esta agencia.",
        tanfParanine: "Si ya está registrado para votar en su dirección actual, no necesita volver a registrarse. Si desea registrarse para votar o desea cambiar su dirección para fines de votación, puede hacerlo a través de la oficina del Secretario de Estado en el siguiente enlace:",
        tanfParaten: "Secretaria de estado",
        tanfParaeleven: "Si desea ayuda para completar el formulario de registro de votantes, lo ayudaremos. Puede buscar ayuda con el formulario de solicitud en el momento de su entrevista o llamando a la oficina local del Departamento de Recursos Humanos ubicada dentro de su condado. La decisión de buscar o aceptar ayuda es suya. Puede completar el formulario de solicitud en privado.",
        tanfParatwelve: "Si decide solicitar registrarse para votar o si se niega a registrarse para votar, la información en su formulario de solicitud permanecerá confidencial y se utilizará únicamente con fines de registro de votantes.",
        tanfParathirteen: "Si cree que alguien ha interferido con su derecho a registrarse o a negarse a registrarse para votar, su derecho a la privacidad al decidir si registrarse o solicitar registrarse para votar, o su derecho a elegir su propio partido político u otra preferencia política. , puede presentar una queja ante el Secretario de Estado en el Capitolio del Estado, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 o llamando al 334-242-7210 o al 1-800-274-VOTE (1-800-274- 8683).",
        tanfListing: [
          "Ayudas y servicios gratuitos para que personas con discapacidades se comuniquen efectivamente con nosotros, tales como: Braille, letra grande, cintas de audio, lenguaje de señas americano, etc.",
          "Intérpretes cualificados de lengua de signos ",
          "Información escrita en otros formatos (letra grande, audio, formatos electrónicos accesibles, otros formatos)",
          "Servicios de idiomas gratuitos para personas cuyo idioma principal no es el inglés, como: Intérpretes calificados e información escrita en otros idiomas.",
        ],
        tanfCertify: "Certifico que la información proporcionada en esta redeterminación es verdadera y completa a mi leal saber y entender. Entiendo que si ALDHR no recibe a tiempo mi formulario completo y firmado, mis beneficios se retendrán y mi caso puede terminarse.",
        termsHeader: "Derechos, responsabilidades y multas",
        headerLabel:
          "Declaración de entendimiento",
        certifyContents:
          "Cuando su hogar recibe beneficios de asistencia alimentaria, debe seguir todas las reglas. Debe proporcionar información verdadera y completa sobre todas las personas en su hogar y debe proporcionar documentos que prueben lo que dice si el trabajador se lo solicita. Cualquier miembro de su hogar que infrinja cualquiera de estas reglas a propósito puede ser excluido de SNAP por 1 año por la primera infracción, 2 años por la segunda infracción y permanentemente por la tercera infracción; multa de hasta 250.000 dólares, prisión de hasta 20 años o ambas; y sujeto a procesamiento bajo otras leyes federales. También se le puede excluir del Programa de Asistencia Alimentaria por 18 meses adicionales si así lo ordena el tribunal. NO proporcione información falsa ni oculte información para obtener o continuar recibiendo beneficios de SNAP. NO intercambie ni venda tarjetas EBT. NO altere las tarjetas EBT para obtener beneficios SNAP a los que no tiene derecho. NO utilice los beneficios de SNAP para comprar artículos no elegibles, como alcohol y tabaco, ni para pagar con cuentas de crédito. NO utilice los beneficios SNAP o la tarjeta EBT de otra persona para su hogar. Las personas que un tribunal determine que han cometido las siguientes violaciones del programa estarán sujetas a las siguientes sanciones:",
        signingApplication:
          "Certifico bajo pena de perjurio que mis respuestas a todas las preguntas sobre cada miembro del hogar, incluidas aquellas sobre ciudadanía o condición de extranjero, son correctas y completas.",
        penaltiesList: [
          "Si se descubre que ha utilizado o recibido beneficios en una transacción que involucra la venta de una sustancia controlada, no será elegible para recibir beneficios SNAP por un período de dos años por la primera infracción y de forma permanente tras la segunda infracción.",
          "Si se descubre que ha utilizado o recibido beneficios en una transacción que involucra la venta de armas de fuego, municiones o explosivos, no será permanentemente elegible para recibir beneficios SNAP en la primera ocasión de dicha violación.",
          "Si ha sido declarado culpable de haber traficado con beneficios por un monto total de $500 o más, no será permanentemente elegible para recibir beneficios SNAP en la primera ocasión de dicha violación.",
          "Si se descubre que ha hecho una declaración o representación fraudulenta con respecto a su identidad o lugar de residencia para recibir múltiples beneficios SNAP simultáneamente, no será elegible para participar en el programa por un período de 10 años.",
          "Si está huyendo para evitar el procesamiento, la custodia o el confinamiento, después de una condena por un delito o un intento de cometer un delito, que es un delito grave, o está en violación de la libertad condicional o libertad condicional impuesta bajo una ley federal o estatal, está no es elegible para recibir asistencia alimentaria.",
          "Si es condenado por usar o recibir beneficios de asistencia alimentaria en una transacción que involucra la venta de una sustancia controlada, no será elegible durante 24 meses por la primera infracción y permanentemente por la segunda infracción.",
          "Si es condenado por un delito grave federal o estatal que tiene como elemento la posesión, el uso o la distribución de una sustancia controlada, es posible que no sea elegible para recibir asistencia alimentaria."
        ],
        understandList: [
          "Entiendo que la información proporcionada por mí en relación con esta solicitud de beneficios de asistencia alimentaria estará sujeta a verificación por parte de funcionarios federales, estatales y locales para determinar si dicha información es verdadera. Además, entiendo que si se determina que dicha información no es cierta, o incorrecta, mi solicitud de beneficios de asistencia alimentaria puede ser denegada o cancelada y puedo estar sujeto a un proceso penal por proporcionar información incorrecta a sabiendas.",
          "Entiendo que la información proporcionada es necesaria para determinar mi elegibilidad para recibir asistencia alimentaria. Entiendo que toda la información es confidencial y que cualquier uso o divulgación sin mi permiso se realizará solo para ciertos propósitos limitados permitidos según las leyes y regulaciones estatales o federales. Dichos propósitos incluyen , pero no se limitan a establecer la elegibilidad, determinar los montos de los beneficios y brindar servicios a los solicitantes y beneficiarios.",
          "Acepto proporcionar la información necesaria para determinar la elegibilidad para mi hogar. Entiendo que es posible que tenga que proporcionar documentos para probar lo que he dicho. Entiendo que si los documentos no están disponibles, debo dar el nombre de una persona u organización. mi trabajador de cupones de alimentos puede contactar para obtener la prueba necesaria.",
          "Certifico, a mi leal saber y entender, bajo pena de perjurio, que todos los miembros del hogar para quienes se solicitan beneficios son ciudadanos estadounidenses o extranjeros legalmente admitidos en los Estados Unidos.",
          "Entiendo que la información proporcionada en esta solicitud será verificada mediante el Sistema de Verificación de Elegibilidad e Ingresos del Estado, otros sistemas de comparación por computadora, revisiones y auditorías de programas. Además, cualquier información proporcionada también puede ser verificada por otros Programas de Ayuda Federal y Ayuda Federal. Programas estatales como almuerzo escolar, Asistencia familiar y Medicaid. Si alguna información es incorrecta, a mi hogar se le pueden negar los beneficios de asistencia alimentaria. Si doy información falsa a propósito, se pueden tomar acciones legales o administrativas en mi contra. Es posible que tenga que hacerlo. reembolsar los beneficios de asistencia alimentaria que recibo a los que no tengo derecho.",
          "Entiendo que mi hogar no será elegible para recibir beneficios si me niego a cooperar para completar el proceso de solicitud o en revisiones posteriores de mi elegibilidad, incluidas revisiones resultantes de cambios informados, recertificación o como parte de una revisión de control de calidad estatal o federal.",
          "Entiendo que mi firma en esta solicitud servirá como mi autorización para que los revisores de control de calidad estatales y federales verifiquen las circunstancias de mi hogar para fines de elegibilidad para recibir asistencia alimentaria.",
          "Entiendo los requisitos laborales y que mi hogar o un miembro del hogar pueden ser descalificados de recibir beneficios de asistencia alimentaria por no cooperar.",
          "Entiendo que si algún miembro de mi hogar renuncia voluntariamente a un trabajo o reduce la cantidad de horas que trabaja sin una buena causa, mi hogar o un miembro del hogar puede ser descalificado de recibir beneficios de asistencia alimentaria.",
          "Entiendo que puedo solicitar una conferencia o una audiencia imparcial si no estoy satisfecho con alguna decisión del Departamento del Condado. Esta solicitud puede hacerse oralmente o por escrito. Mi caso puede ser presentado en la audiencia por cualquier persona que yo elija.",
          "Entiendo que mi hogar no recibirá un aumento en los beneficios de asistencia alimentaria si alguien en mi hogar no cumple con los requisitos de otro programa con prueba de recursos (Asistencia Familiar, SSI).",
          "Entiendo que algunos beneficiarios de cupones para alimentos sin discapacidad solo serán elegibles para recibir asistencia alimentaria durante 3 meses en un período de 36 meses, a menos que trabajen 20 horas a la semana o participen en ciertos programas de trabajo y capacitación. Entiendo que mi trabajador me aconsejará si esta disposición se aplica a alguien en mi hogar.",
          "Entiendo que no debo: dar información falsa u ocultar información para obtener o continuar recibiendo beneficios de asistencia alimentaria; utilizar los beneficios de asistencia alimentaria de otra persona; o utilizar los beneficios de asistencia alimentaria para comprar artículos no elegibles, como bebidas alcohólicas o tabaco.",
          "Declaro, bajo pena de perjurio, que he leído o me han leído todas las declaraciones en la solicitud y que la información es verdadera y correcta a mi leal saber y entender."
        ],
        understandDta:
          "Entiendo que el Departamento de Asistencia Transicional (DHR) administra SNAP. Entiendo que el DHR tiene 30 días desde la fecha de la solicitud para procesar mi solicitud. Más aún, entiendo que:",
        foodNutritionact:
          "La Ley de Alimentos y Nutrición del 2008 (7 U.S.C. 2011-2036) permite que el DHR use mi Número de Seguro Social (SSN) y el SSN de cada miembro del hogar para quien presente la solicitud. El DHR usa esta información para determinar la elegibilidad de mi hogar para SNAP. El DHR verifica esta información por medio de programas informáticos de comparación de datos. Entiendo que el DHR los usa para monitorear el cumplimiento de los reglamentos del programa.",
        reportingRulesDtachanges:
          "La mayor parte del tiempo, los hogares bajo las reglas de Informe Simplificado de SNAP deben informar al DHR sobre cambios en el Informe Provisional (IR) y la recertificación con excepción de:",
        incomeExceeds:
          "Si los ingresos de mi hogar superan el umbral de ingresos brutos",
        workRequirementlabel:
          " Si yo estoy bajo los requisitos de trabajo de adultos sanos sin dependientes a cargo (ABAWD) y mis horas de trabajo disminuyeran por debajo de 20 horas por semana",
        verifiedInfomation:
          "Si el DHR recibe información verificada sobre mi hogar, la cantidad de mi beneficio podría cambiar",
        tbaRulesone:
          " Si yo no estuviera bajo las reglas del Informe Simplificado de SNAP o de la Alternativa de Beneficios Transicionales (TBA), debo informar al DHR sobre cambios en mi hogar que puedan afectar nuestra elegibilidad. Entiendo que debo informar sobre estos cambios al DHR en persona, por escrito o por teléfono ",
        tbaRuletwo: " dentro de los 10 días del cambio.",
        tbaExample:
          " Por ejemplo, usted debe informar sobre cambios en los ingresos, tamaño o dirección de su hogar.",
        findmeIneligible:
          "Tengo derecho a hablar con un supervisor si el DHR no me considerara elegible para beneficios de emergencia de SNAP y yo estuviera en desacuerdo. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mis beneficios al séptimo día calendario después de haber presentado la solicitud para SNAP. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mi tarjeta de Transferencia Electrónica de Beneficios (EBT) al séptimo día calendario después de haber presentado la solicitud para SNAP.",
        verificationtoDta:
          "Podría recibir más beneficios de SNAP si yo informara y presentara verificación al DHR de:",
        childorOtherTitile:
          "costos para niños o de otros servicios de atención de dependientes, costos de albergue, y/o costos de servicios públicos",
        legallyObligatedlabel:
          "Manutención infantil de obligación legal para un miembro que no esté en el hogar.",
        disabledMedicalcost:
          "Si tuviera 60 años de edad o más o si estuviera discapacitado y pagara por costos médicos, puedo informar y presentar verificación de estos costos al DHR. Esto podría hacerme elegible para una deducción y aumentar mis beneficios de SNAP.",
        meetExemptionlabel:
          "A menos que cumplan con una exención, todos los beneficiarios de SNAP entre los 16 y 59 años de edad están registrados para trabajar y sujetos a los Requisitos Generales de Trabajo de SNAP. Los beneficiarios de SNAP entre los 18 y 49 años de edad también podrían estar sujetos a los requisitos del Programa de Trabajo de ABAWD. El DHR le informará a los miembros no exentos del hogar sobre los requisitos de trabajo. El DHR le informará a los miembros no exentos del hogar sobre las excepciones y multas por incumplimiento.",
        voluntarilyParticipatelabel:
          "La mayoría de los beneficiarios de SNAP pueden participar voluntariamente en servicios de capacitación para el empleo y educación por medio del programa Camino al Trabajo de SNAP. El DHR entregará remisiones para el programa Camino al Trabajo de SNAP si correspondiera.",
        dtaNamescontact:
          "El DHR también podría compartir los nombres e información de contacto de los beneficiarios de SNAP con los proveedores de Camino al Trabajo de SNAP para propósitos de contratación. Entiendo que los miembros de mi hogar podrían ser contactados por especialistas de Camino al Trabajo de SNAP del DHR o por proveedores contratados para explorar las opciones de participación en Camino al Trabajo de SNAP. Para obtener más información sobre el programa Camino al Trabajo de SNAP, visite ",
        subjectToverification:
          "Entiendo que la información que entregue con mi solicitud estará sujeta a verificación para determinar si es verdadera. Si cualquier información fuera falsa, el DHR podría negarme mis beneficios de SNAP. También yo podría estar sujeto a juicio por proporcionar información falsa.",
        proveInformation:
          "Obtener documentos para probar información en esta solicitud con otras agencias estatales y federales, autoridades locales de vivienda, departamentos de bienestar fuera del estado, instituciones financieras y de Equifax Workforce Solutions. También doy permiso a estas agencias para que le den información sobre mi hogar al DHR concerniente a mis beneficios de SNAP.",
        immigrationStatusLabel:
          "Si correspondiera, verificar mi estado inmigratorio por medio de los Servicios de Ciudadanía e Inmigración de Estados Unidos (USCIS). Entiendo que el DHR podría verificar la información de mi solicitud para SNAP con USCIS. Cualquier información recibida de USCIS podría afectar la elegibilidad de mi hogar y la cantidad de los beneficios de SNAP.",
        secondaryEducation:
          "Compartir información sobre mí y mis dependientes menores de 19 años con el Departamento de Educación Primaria y Secundaria (DESE). El DESE certificará a mis dependientes para programas escolares de desayuno y almuerzo.",
        publicHealth:
          "Compartir información sobre mí, mis dependientes menores de 5 años y cualquier persona embarazada en mi hogar con el Departamento de Salud Pública (DPH). El DPH remite a estas personas para servicios de nutrición al Programa para Mujeres, Infantes y Niños (WIC).",
        executiveOfficeHealth:
          "Compartir información, junto con la Oficina Ejecutiva de Salud y Servicios Humanos de Alabama, sobre mi elegibilidad para SNAP con compañías de electricidad, gas y proveedores de teléfono y cable elegibles para certificar mi elegibilidad para descuentos en las tarifas de servicios públicos.",
        housingCommuntiy:
          "Compartir mi información con el Departamento de Vivienda y Desarrollo Comunitario (DHCD) para el fin de inscribirme en el programa de Calefacción y Alimentación.",
        lowerBenefitslabel:
          "El DHR podría negar, suspender o disminuir mis beneficios basado en la información de Equifax Workforce Solutions. Tengo derecho a una copia gratuita de mi informe de Equifax si lo solicitara dentro de los 60 días de la decisión del DHR. Tengo derecho a cuestionar la precisión o integridad de la información en mi informe. Podría comunicarme con Equifax a: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
        tollFreeLabel: "(línea gratuita).",
        rightoKnowbrochure:
          "Entiendo que obtendré una copia del folleto “Su derecho a saber” y del folleto del programa de SNAP. Leeré o me leerán los folletos, debiendo entender su contenido y mis derechos y responsabilidades. Si tuviera preguntas sobre los folletos o cualquier parte de esta información, me comunicaré con el DHR. Si tuviera dificultad para leer o entender cualquier parte de esta información, me comunicaré con el DHR. Puede comunicarse con el DHR al: ",
        lawfullyResiding:
          "Juro que todos los miembros de mi hogar SNAP que solicitan beneficios de SNAP son ciudadanos de EE. UU. o no ciudadanos con residencia legal.",
        registerVoteTitle: "Derecho a inscribirse para votar",
        registerVoteFirstLabel:
          "Entiendo que tengo derecho a inscribirme para votar en el DHR. Entiendo que el DHR me ayudará a completar el Formulario de Inscripción de Votante si deseara ayuda. Tengo derecho a completar el Formulario de Inscripción de Votante en privado.",
        registerVoteSecondLabel:
          "Entiendo que solicitar o rechazar la inscripción para votar no afectará la cantidad de beneficios que obtenga del DHR. ",
        penaltyWarningTitle: "Advertencia de multa de SNAP",
        penaltyWarningContent:
          "Entiendo que, si yo o cualquier miembro de mi hogar SNAP intencionalmente no cumpliera cualquiera de las reglas indicadas a continuación, esa persona no será elegible para SNAP durante un año después de la primera infracción, dos años después de la segunda infracción y para siempre después de la tercera infracción. Esa persona también puede ser multada hasta con $250,000, ir a prisión hasta 20 años o ambas. También puede estar sujeta a juicio bajo otras leyes federales y estatales aplicables. Estas reglas son:",
        penaltyWarningOne:
          "No proporcionar información falsa u ocultar información para obtener beneficios de SNAP.",
        penaltyWarningTwo: "No intercambiar o vender beneficios de SNAP.",
        penaltyWarningThree:
          "No alterar las tarjetas EBT para obtener beneficios de SNAP para los cuales usted no es elegible.",
        penaltyWarningFour:
          "No usar los beneficios de SNAP para comprar artículos inelegibles, como bebidas alcohólicas y tabaco.",
        penaltyWarningFive:
          "No usar los beneficios de SNAP o la tarjeta EBT de otra persona, a menos que sea un Representante autorizado.",

        penaltiesTitle: "También entiendo las siguientes multas: ",
        individualLabel:
          "Las personas que cometan una Infracción Intencional del Programa (IPV)",
        cashProgram: "de efectivo ",
        internationalProgram:
          " no serán elegibles para SNAP durante el mismo período en que la persona no sea elegible para la asistencia con efectivo.",
        penalitiesTwo:
          "Las personas que hagan una declaración fraudulenta sobre su identidad o residencia para obtener múltiples beneficios de SNAP en el mismo período no serán elegibles para SNAP durante   ",
        tenYearLabel: "diez años",
        penalitiesThree:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP por drogas ilegales/sustancias controladas, no serán elegibles para SNAP durante dos años para la primera falta y para siempre para la segunda falta",
        twoYearLabel: "dos años ",
        penalitiesThreeMiddle: "para la primera falta ",
        foreverText: "para siempre ",
        penalitiesThreeLast: "para la segunda falta.",
        penalitiesFour:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP por armas de fuego, municiones o explosivos, no serán elegibles para SNAP",
        penalitiesFive:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP que tengan un valor de $500 o más, no serán elegibles para SNAP",
        penalitiesSix:
          "El estado podría enjuiciar por una IPV a una persona que haga una oferta para vender beneficios de SNAP o una tarjeta EBT en línea o en persona",
        penalitiesSeven:
          "Las personas que escapen para evitar el juicio, detención o reclusión después de la convicción por un delito, o que estén violando la libertad bajo palabra, no son elegibles para SNAP.",
        penalitiesEight:
          "No se permite pagar por alimentos comprados a crédito, y esto pudiera resultar en la descalificación de SNAP.",
        penaltiesNine:
          "Las personas no pueden comprar productos con beneficios de SNAP con la intención de descartar el contenido y devolver los envases por efectivo.",

        interpreterHeader: "Derecho a un intérprete",
        interpreterContent:
          "Entiendo que tengo derecho a un intérprete proporcionado por el DHR si ningún adulto en mi hogar SNAP pudiera hablar o entender inglés. También entiendo que puedo obtener un intérprete para cualquier audiencia imparcial del DHR o llevar a un intérprete por mi cuenta. Si necesitara un intérprete para una audiencia, debo llamar a la División de Audiencias al menos una semana antes de la fecha de la audiencia.",
        nonDiscriminationHeader: "Declaración de no discriminación",
        nonDiscriminationContent:
          "De acuerdo con la ley federal de derechos civiles y los reglamentos y políticas de derechos civiles del Departamento de Agricultura de EE. UU. (USDA), se prohíbe al USDA, sus agencias, oficinas, empleados e instituciones que participen o administren programas de USDA discriminar basado en raza, color, nacionalidad, sexo, credo religioso, discapacidad, edad, creencias políticas, represalia o venganza por actividades de derechos civiles en cualquier programa o actividad realizado o patrocinado por USDA.",
        personDisabilities:
          "Las personas con discapacidades que requieran medios alternativos de comunicación para obtener información sobre el programa (por ej. braille, letras grandes, cintas de audio, Lenguaje de Señas Americano, etc.), deben comunicarse con la Agencia (estatal o local) donde presentaron la solicitud para beneficios. Las personas sordas, con dificultad auditiva o discapacidades del habla pueden comunicarse con el USDA por medio del Servicio de Retransmisión Federal al ",
        programInformation:
          "La información sobre el programa también puede ponerse a disposición en otros idiomas además del inglés.,",

        programComplaint:
          "Para presentar una queja de discriminación para el programa, complete el Formulario de Queja por Discriminación del Programa del USDA, (AD-3027) que se encuentra en línea en: ",
        usdaOffice:
          "y en cualquier oficina del USDA, o escriba una carta dirigida al USDA y proporcione en la carta toda la información solicitada en el formulario. Para solicitar una copia del formulario de quejas, llame al ",
        sumbitYourLabel: "Envíe su carta o formulario completado al USDA por:",

        mailAddress: "correo: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " o por",
        eamilTitle: "correo electrónico: ",
        thisInstution:
          "Esta institución es un proveedor que ofrece igualdad de oportunidades.",
      };

    case types.PORTUGUESE: //portugeesh
      return {
        termsHeader: "Direitos, responsabilidades e penalidades",
        headerLabel:
          "Notificação de direitos, responsabilidades e penalidades. Leia com atenção!",
        certifyContents:
          "Certifico de que li, ou de que alguém leu para mim, as informações contidas neste formulário. Atesto que minhas respostas às perguntas deste formulário são verdadeiras e completas, segundo o meu conhecimento. Também certifico de que todas as informações fornecidas por mim ao departamento, tanto durante a entrevista de inscrição bem como em ocasiões futuras, serão verdadeiras e completas, de acordo com o meu conhecimento. Entendo que fornecer informações falsas ou deturpadas constituem fraude. Também compreendo constituir fraude qualquer fornecimento de informações deturpadas ou a omissão de fatos com o objetivo de garantir a qualificação no programa SNAP. Tais atos se enquadram em Violação Intencional do Programa (IPV, Intentional Program Violation), sendo passíveis de punições civis e criminais.",
        understandDta:
          "Eu entendo que o Departamento de Auxílio Transitório (DHR) administra o SNAP. Eu entendo que o DHR tem um prazo de 30 dias a contar da data de inscrição para processar o meu caso. Entendo ainda que:",
        foodNutritionact:
          "De acordo com o Food and Nutrition Act de 2008 (7 U.S.C. 2011-2036), o DHR pode utilizar o número do meu seguro social (SSN) e o de qualquer membro da minha família que esteja se inscrevendo neste programa. O DHR utiliza esta informação a fim de determinar a qualificação da minha família ao programa SNAP. Além disso, o DHR verifica tais informações por meio de programas de comparação por computador. Entendo que o DHR faz uso desta prática para monitorar o cumprimento das normas do programa.",
        reportingRulesDtachanges:
          "Na maioria das vezes, as famílias ou grupos familiares que se encontram sujeitas às regras de fornecimento de Informações Simplificadas do SNAP precisam informar ao DHR sobre quaisquer mudanças que afetem o Relatório Provisório (IR, Interim Report), bem como em casos de re-certificação, com as seguintes exceções:",
        incomeExceeds:
          "Caso a minha renda familiar ultrapasse o limite de renda bruta",
        workRequirementlabel:
          "Caso eu me encaixe nos requisitos de trabalho de adulto fisicamente capaz sem dependentes (ABAWD, able-bodied adult without dependents,) e as minhas horas de trabalho forem reduzidas para menos de 20 horas semanais",
        verifiedInfomation:
          "O valor do meu benefício pode ser alterado, caso o DHR receba informações previamente verificadas sobre a minha família.",
        tbaRulesone:
          "Caso eu não esteja sujeito às regras de Informações Simplificadas (Simplified Reporting) do SNAP, nem às regras da Alternativa de Benefícios Transitórios (TBA, Transitional Benefits Alternative) é obrigatório que eu informe o DHR sobre quaisquer mudanças em minha família, ou agregado familiar, que altere nossa qualificação. Eu entendo que preciso informar o DHR sobre tais alterações pessoalmente, por escrito ou por telefone ",
        tbaRuletwo: " dentro de um prazo de 10 dias da mudança.",
        tbaExample:
          " aPor exemplo, é obrigatório informar sobre alterações de renda, tamanho ou endereço da família.",
        findmeIneligible:
          "Tenho direito a falar com um supervisor, caso o DHR negue a minha qualificação para os benefícios de emergência do SNAP e eu não concorde com esta decisão. Também poderei entrar em contato com um supervisor, caso seja qualificado para os benefícios de emergência do SNAP, mas não tenha recebido nenhum benefício depois de sete dias corridos após a inscrição no programa SNAP. O candidato poderá falar com um supervisor, caso se qualifique para os benefícios, mas não tenha recebido o cartão de Transferência Eletrônica de Benefícios (EBT) depois de sete dias corridos após ter realizado a inscrição.",
        verificationtoDta:
          "Poderei receber benefícios SNAP adicionais caso eu informe e comprove ao DHR:",
        childorOtherTitile:
          "possuir custos relativos a cuidados de crianças e outros dependentes, custos de asilo e/ou custos de serviços de utilidade pública",
        legallyObligatedlabel:
          "estar obrigado legalmente a fornecer pensão alimentícia a um membro que não pertença a minha família",
        disabledMedicalcost:
          "Se eu tiver 60 anos ou mais, ou no caso de ser portador de deficiência e pagar por despesas médicas, posso informar e enviar comprovação ao DHR dos custos relativos a estas despesas, Talvez isso qualifique o candidato para uma redução ou aumento dos benefícios do SNAP.",
        meetExemptionlabel:
          "Todos os beneficiários do SNAP com idades de 16 a 59 anos devem possuir registro de trabalho de acordo com os Requisitos Gerais de Trabalho do SNAP (General SNAP Work Requirements), a não ser que eles atendam alguma categoria de isenção. Os beneficiários do SNAP com idades entre 18 e 49 anos podem também estar sujeitos aos requisitos do programa de trabalho ABAWD. O DHR se encarregará de informar os membros da família não-isentos sobre os requisitos de trabalho necessários. O DHR também informará os membros não-isentos da família sobre as exceções e as penalidades caso as exigências solicitadas não forem cumpridas.",
        voluntarilyParticipatelabel:
          "A maior parte dos beneficiários do SNAP poderá participar voluntariamente em serviços de ensino e formação profissionais por meio do programa Caminho para Trabalhar do SNAP (SNAP Path to Work). O DHR poderá encaminhar beneficiários para o programa SNAP Path to Work, se necessário.",
        dtaNamescontact:
          "Para fins de recrutamento, o DHR poderá também compartilhar nomes e informações de contato dos beneficiários com os organizadores do SNAP Path to Work. Entendo que os membros da minha família poderão ser contatados por especialistas do programa SNAP Path to Work do DHR, ou pelos fornecedores de serviço contratados para o programa, a fim de analisar opções de participação no programa SNAP Path to Work. Para obter mais informações sobre o programa SNAP Path to Work, visite ",
        subjectToverification:
          "Entendo que as informações fornecidas com a minha inscrição estão sujeitas à verificação para determinar sua veracidade. Se alguma informação for falsa, o DHR poderá negar-me os benefícios do SNAP. Eu posso estar sujeito a julgamento criminal por fornecimento de informações falsas.",
        signingApplication:
          "Entendo que ao assinar este formulário eu autorizo a verificação e a investigação pelo DHR dos dados informados relativos à minha qualificação para os benefícios do SNAP, incluindo permissão para:",
        proveInformation:
          "Obter documentos que comprovem a informação deste formulário com outras agências estaduais e federais, autoridades locais de moradia, departamentos de assistência social de outros estados, instituições financeiras e da Equifax Worforce Solutions. Também autorizo que estas agências forneçam ao DHR dados sobre minha família referentes aos meus benefícios do SNAP.",
        immigrationStatusLabel:
          "Se for o caso, verificar minha situação de imigração junto aos Serviços de Imigração e Cidadania dos Estados Unidos (USCIS, United States Citizenship and Immigration Services). Entendo que o DHR poderá verificar dados da minha inscrição no SNAP com o USCIS. Qualquer informação recebida do USCIS poderá afetar a qualificação da minha família, bem como o valor dos benefícios do SNAP.",
        secondaryEducation:
          "Compartilhar meus dados pessoais e de meus dependentes menores de 19 anos de idade com o Departamento de Ensino Fundamental e Médio (DESE, Department of Elementary and Secondary Education). O DESE irá aprovar meus dependentes para os programas de alimentação escolar (café da manhã e almoço).",
        publicHealth:
          "Compartilhar meus dados pessoais, de meus dependentes menores de cinco anos de idade e de qualquer gestante em minha família com o Departamento de Saúde Pública (DPH, Department of Public Health). O DPH encaminha estes indivíduos para o Programa para Mulheres, Bebês e Crianças (WIC, Women, Infants and Children Program) para auxílio nutricional.",
        executiveOfficeHealth:
          "Compartilhar informações, junto ao Escritório Executivo de Serviços Humanos e Saúde de Alabama (Alabama Executive Office of Health and Human Services), sobre minha qualificação no programa SNAP, com empresas fornecedoras de eletricidade, gás e de serviços de telefonia e TV a cabo qualificadas para certificar-se da minha qualificação para receber descontos nas taxas de serviços de utilidade pública.",
        housingCommuntiy:
          "Compartilhar meus dados com o Departamento de Desenvolvimento de Habitação e Comunidade (DHCD, Department of Housing and Community Development) com o objetivo de inscrever-me no Programa Aquecer e Alimentar (Heat & Eat Program).",
        lowerBenefitslabel:
          "DHR poderá negar, interromper ou diminuir meus benefícios de acordo com as informações recebidas do Equifax Workforce Solutions. Tenho o direito de receber uma cópia gratuita do meu relatório do Equifax caso eu solicite o mesmo dentro do prazo de 60 dias a partir da decisão do DHR. Tenho o direito de questionar se as informações no meu relatório estão precisas ou completas. Eu posso entrar em contato com a Equifax em: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
        tollFreeLabel: "(toll free).",
        rightoKnowbrochure:
          "Entendo que irei receber uma cópia dos folhetos “Seu Direito de Saber” ('Your Right to Know') e do SNAP. Confirmo que vou ler ou já li os folhetos e preciso entender o conteúdo dos mesmos, bem como meus direitos e responsabilidades. Caso tenha qualquer pergunta sobre estes folhetos ou sobre quaisquer destas informações, entrarei em contato com o DHR. Caso eu tenha dificuldade de ler ou compreender quaisquer informações, entrarei em contato com o DHR. O DHR pode ser contactado pelo número:",
        lawfullyResiding:
          "Eu asseguro que todos os membros da minha família solicitantes dos benefícios do SNAP são cidadãos dos Estados Unidos ou não-cidadãos residentes legais.",
        registerVoteTitle: "Direito de registro eleitoral",
        registerVoteFirstLabel:
          "Entendo que no DHR tenho o direito de registrar-me para votar. Entendo que o DHR vai me ajudar no preenchimento do formulário de inscrição eleitoral caso necessário. Posso optar por preencher o formulário de registro eleitoral em particular.",
        registerVoteSecondLabel:
          "Entendo que o ato de inscrição ou desistência ao registro eleitoral não afetará o valor dos benefícios que eu receber do DHR. ",
        penaltyWarningTitle: "Aviso de penalidade do SNAP",
        penaltyWarningContent:
          "Entendo que se eu mesmo, ou quaisquer membros da minha família beneficiários do SNAP, não cumprirmos qualquer uma das regras listadas abaixo, perderemos a qualificação ao SNAP por um ano após a primeira infração, dois anos após a segunda infração e permanentemente após a terceira infração. Este indivíduo poderá ser multado em até US$ 250.000,00 (duzentos e cinquenta mil dólares), ficar preso por até vinte anos ou ambos. O beneficiário também poderá ser processado sob outras leis federais e estaduais aplicáveis. As regras a serem seguidas são:",
        penaltyWarningOne:
          "Não ocultar ou fornecer informações falsas com o objetivo de receber benefícios do SNAP.",
        penaltyWarningTwo: "Não negociar ou vender benefícios do SNAP.",
        penaltyWarningThree:
          "Não falsificar cartões de EBT ou receber benefícios do SNAP para os quais você não se qualifica.",
        penaltyWarningFour:
          "Não usar os benefícios do SNAP para comprar itens ilegais como bebidas alcoólicas, cigarros e outros produtos de tabaco.",
        penaltyWarningFive:
          "Não usar os benefícios do SNAP ou cartões de EBT de terceiros, a não ser que você seja um representante autorizado.",

        penaltiesTitle: "Também entendo as penalidades a seguir:",
        individualLabel:
          "Os beneficiários, que porventura cometam uma Violação Intencional do Programa (IPV, Intentional Program Violation) relativa ao ",
        cashProgram: "recebimento em dinheiro",
        internationalProgram:
          ", ficarão desqualificados do SNAP. Durante esse mesmo período eles não poderão receber o auxílio em dinheiro.",
        penalitiesTwo:
          "O beneficiário que fizer uma declaração fraudulenta sobre sua identidade ou residência visando receber diversos benefícios do SNAP ficará desqualificado do programa por um prazo de ",
        tenYearLabel: "dez anos.",
        penalitiesThree:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de substância(s)/droga(s) ilegal(is) ficarão desqualificados por ",
        twoYearLabel: "dois anos ",
        penalitiesThreeMiddle: "para a primeira violação, e",
        foreverText: "permanentemente ",
        penalitiesThreeLast: "para a segunda.",
        penalitiesFour:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de armas de fogo, munição ou explosivos ficarão permanentemente desqualificados do SNAP",
        penalitiesFive:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP no valor de US$ 500,00 (quinhentos dólares) ou mais ficarão permanentemente desqualificados do SNAP.",
        penalitiesSix:
          "O Estado poderá prosseguir com uma IPV contra o beneficiário que fizer uma oferta para vender on-line ou pessoalmente os benefícios do SNAP ou os cartões de EBT.",
        penalitiesSeven:
          "Indivíduos que estiverem fugindo para evitar processo, custódia ou prisão após condenação por crime ou que estejam violando a liberdade condicional ou em liberdade provisória não se qualificam para o programa SNAP.",
        penalitiesEight:
          "Não é permitido pagar por alimento comprado a crédito e isso poderá resultar em desqualificação pelo SNAP.",
        penaltiesNine:
          "Os beneficiários não poderão comprar produtos com os benefícios do SNAP com a intenção de descartar seus conteúdos e retornar as embalagens em troca de dinheiro.",

        interpreterHeader: "Direito a um intérprete",
        interpreterContent:
          "Entendo ter o direito de solicitar um intérprete fornecido pelo DHR, caso nenhum membro adulto pertencente ao meu agregado familiar do SNAP falar ou entender inglês. Também entendo poder solicitar um intérprete para qualquer sessão de audiência com o DHR, ou trazer um intérprete de minha confiança. Caso eu precise de um intérprete para uma audiência, preciso ligar para a Division of Hearings, com pelo menos uma semana de antecedência da data da minha audiência.",
        nonDiscriminationHeader: "Declaração de não-discriminação",
        nonDiscriminationContent:
          "De acordo com as leis federais de direitos civis e os regulamentos e as políticas de direitos civis do Departamento de Agricultura dos EUA (USDA, U.S. Department of Agriculture), o USDA, suas agências, escritórios e empregados, assim como as instituições que participam ou administram programas do USDA são proibidas de fazer qualquer tipo de discriminação com base em raça, cor, nacionalidade de origem, sexo, credo religioso, deficiência, idade, convicções políticas. Também são proibidas em todos os programas ou atividades conduzidos ou custeados pelo USDA qualquer tipo de represálias ou retaliações relativas a atividades de direitos civis anteriores.",
        personDisabilities:
          "Os indivíduos portadores de deficiências que precisam de formas alternativas de comunicação para obter informações sobre o programa (por exemplo, braile, publicações com letras maiores, fitas de áudio, linguagem americana de sinais etc.), devem entrar em contato com a agência (estadual ou local) onde foi feita a inscrição para os benefícios. Pessoas deficiente auditivas, com dificuldades de audição ou que possuem distúrbios da fala devem entrar em contato com o USDA por meio do Federal Relay Service no telefone  ",
        programInformation:
          "Adicionalmente, as informações do programa podem estar disponíveis em outros idiomas, que não somente o inglês.",

        programComplaint:
          "Para fazer uma reclamação ou relatar um caso de discriminação, preencha o formulário AD-3027 (USDA Program Discrimination Complaint Form), disponível on-line no site: ",
        usdaOffice:
          "e em qualquer escritório do USDA. Você pode também enviar uma carta para o USDA, fornecendo todas as informações solicitadas no formulário. Para solicitar uma cópia do formulário de reclamação, ligue para ",
        sumbitYourLabel:
          "Envie o formulário preenchido ou a carta para o USDA por:",

        mailAddress: "Correio: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " ou",
        eamilTitle: "Email: ",
        thisInstution: "Esta instituição oferece igualdade de oportunidades.",
      };

    case types.CHINESE: //China
      return {
        termsHeader: "《权利、责任和处罚》",
        headerLabel: "权利、责任和处罚通知 — 请细心阅读",
        certifyContents:
          "我确认，我已经阅读或有人向我读出本申请表中的资讯。据我所知所信，我对本申请表中问题的回答是真实和完整的。我还确认，据我所知所信，我在申请面谈期间向过渡援助部提供的资料是真实和完整的，将来提供的资料也会真实和完整。我理解，提供虚假或误导性信息是欺诈行为。我还理解，为了符合 SNAP 资格而歪曲或隐瞒事实是欺诈行为。这等行为将导致蓄意违反计划行为（IPV），本人亦因此受到民事和刑事处罚。",
        understandDta:
          "我理解，过渡援助部（DHR）负责管理 SNAP。我理解，DHR 将在申请日期开始的 30 天内处理我的申请。此外，我理解：",
        foodNutritionact:
          "《2008 年食品与营养法案》（7 U.S.C. 2011-2036）允许 DHR 使用我的社会安全号码（SSN）以及我为其提交申请的每一名家庭成员的 SSN。DTA会 使用此类资料确定我的家庭是否有获取 SNAP 的资格。DHR 通过电脑匹配程序确认此类资料的准确性。我理解， DHR 会利用此类资讯监管申请是否符合计划规章。",
        reportingRulesDtachanges:
          "在大多数情况下，符合 SNAP 简化报告规定的家庭必须在临时报告（IR）中将所有变化通知 DHR，并重新认证，但以下情况例外：",
        incomeExceeds: "我的家庭收入超过总收入限额",
        workRequirementlabel:
          "我受「无家属健全成人」（ABAWD）的工作要求制约，而我的工作时数降低到每周 20 小时以下",
        verifiedInfomation:
          "如果 DHR 收到已确认的有关我家庭的资讯，我的福利数额可能会有改变。",
        tbaRulesone:
          "如果我没有受制於SNAP 简化报告规定或过渡福利替代（TBA）的规定，则我必须将有可能影响我们获得批准的资格之家庭变化向DTA 报告。我理解我必须 ",
        tbaRuletwo: "在变更发生后 10 天内",
        tbaExample:
          " 亲自到办事处、写信或打电话向 DHR 报告这些变更。例如，您必须报告您的家庭的收入、人口或地址的改变。",
        findmeIneligible:
          " 如果 DTA认为我没有资格享受紧急 SNAP 福利，而我不同意他们的决定，我有权向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未有收到我的福利，我可以向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未收到我的电子福利转账（EBT）卡，我可以向直属主管反映。",
        verificationtoDta:
          "如果我向 DHR 报告以下情况并提供证明，我可能享受更多的 SNAP 福利：",
        childorOtherTitile:
          "子女或其他家属护理费用、庇护所费用、及水、电、煤气、电话等费用",
        legallyObligatedlabel: "付给非家庭成员法律规定支付的子女赡养费",
        disabledMedicalcost:
          "如果我年满 60 岁，又或者我是残障人仕并自付医疗费用，我可以向 DHR 申报这些费用，并提供证明。这可能会使我有资格获得税收减免，并增加我的 SNAP 福利。",
        meetExemptionlabel:
          "•	除非符合豁免条件，否则所有年龄在 16 岁至 59 岁之间的 SNAP 受惠者均须进行工作注册，且须符合SNAP的普通工作要求 (General SNAP Work Requirement)。年龄在 18 岁至 49 岁之间的 SNAP 受惠者亦可能须要符合 ABAWD 工作计划要求。DHR 将会通知非豁免的家庭成员有关工作要求的事宜。DHR 亦通知非豁免的家庭成员有关例外情况和不遵守此项规定的处罚。",
        voluntarilyParticipatelabel:
          "大多数 SNAP 受惠者会自愿通过 SNAP 工作之路（SNAP Path to Work）计划参与教育和就业培训。DHR 将在有需要时提供 SNAP 工作之路计划的转介服务。",
        dtaNamescontact:
          "DHR 还可能与 SNAP 工作之路提供服务者分享 SNAP 受惠者的姓名和联系资料，以作招募之用。我理解，DHR SNAP 工作之路计划专家或特约提供服务者可能与我的家庭成员联系，讨论参加 SNAP 工作之路计划的各种选择。如欲得到更多有关 SNAP 工作之路计划的详情，请查阅网站 ",
        subjectToverification:
          "我理解，我在申请表中提供的资料必须接受验证，以确定其真实性。如果有任何虚假资讯，DHR 有可能拒绝提供我的 SNAP 福利。我还可能因提供虚假资讯而受到刑事起诉。",
        signingApplication:
          "我理解，我在本申请表中的签名表示我允许 DHR 确认和调查我所提供的、与我获得 SNAP 福利资格相关的资料，包括允许他们：",
        proveInformation:
          "向其他州立机构、联邦机构、地方住宅管理机构、外州福利部门、金融机构和 Equifax Workforce Solutions 索取文件，以确定本申请表中的资讯。我还允许这些机构向 DHR 提供与我的 SNAP 福利相关的、有关我的家庭的资料。",
        immigrationStatusLabel:
          " 如果适用的话，通过美国公民与移民服务部（USCIS）确认我的移民身份。我理解，DHR 可能与 USCIS 核对我的 SNAP 申请表中的资料。从 USCIS 收到的任何资讯都可能影响我的家庭获 SNAP 福利的资格和福利数额。",
        secondaryEducation:
          " 与小学和中学教育部（DESE）分享有关我和我的 19 岁以下的家属的资料。DESE 将证明我的家属可参加学校早餐和午餐计划",
        publicHealth:
          "与公共卫生部（DPH）分享有关我、我的 5 岁以下的家属和我的家中任何孕妇的资料。DPH 会将这些人转介给妇女、婴儿和儿童（WIC）计划，接受营养服务。",
        executiveOfficeHealth:
          "与马萨诸塞州健康与公众服务执行办公室合作，与电力公司、煤气公司和合格的电话和有线电视运营商分享有关我参加 SNAP 资格的资讯，以确认我获得水、电、煤气、电话等优惠费率的资格。",
        housingCommuntiy:
          "与住宅与社区开发部（DHCD）分享有关我的资料，从而使我得以注册参加取暖与食品计划（Heat & Eat Program）。",
        lowerBenefitslabel:
          "DHR 可能根据从 Equifax Workforce Solutions 获得的讯息拒绝、终止或减低我的福利。如果我在 DHR 作出决定后 60 天内提出请求，我有权免费获得一份我的 Equifax 报告。我有权质疑我的报告中的资料的准确性或完整性。我可以与 Equifax 联系，地址：Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146；免费电话：",
        tollFreeLabel: " ",
        rightoKnowbrochure:
          "我理解，我会收到一份《您的知情权利》（Your Right to Know）手册和《补充营养援助计划》（SNAP Program）手册。我将阅读或请人向我读出这两份手册的内容，我必须理解手册的内容以及我的权利和责任。如果我对手册或手册中的任何资讯有任何疑问，我将与 DHR 联系。如果我在阅读或理解任何此类资讯时遇到困难，我将与 DHR 联系。DHR 的电话号码：",
        lawfullyResiding:
          "我宣誓，所有与我一同申请 SNAP 福利的 SNAP 家庭成员都是美国公民或合法居住非公民。",
        registerVoteTitle: "登记投票的权利",
        registerVoteFirstLabel:
          "我理解，我有权在 DHR 登记投票。我理解，如果我需要帮助，DHR 将帮助我填写选民登记申请表。我亦可以私下填写选民登记申请表。",
        registerVoteSecondLabel:
          "我理解，申请登记投票或拒绝登记投票不会影响我从 DHR 获得的福利数额。 ",
        penaltyWarningTitle: "SNAP 处罚警告",
        penaltyWarningContent:
          "我理解，如果我或我的任何SNAP 家庭的成员有意违反下列任何规定，该人仕将在首次违规后一年内没有资格享用SNAP；在第二次违规后两年内没有资格享用 SNAP；在第三次违规后将永远没有资格享用 SNAP。该人仕亦可能被处以高达 $250,000 的罚款、高达 20 年的监禁或二者并罚。根据其他相关联邦法律和州法律的，该人仕还可能被起诉。此类规定是：",
        penaltyWarningOne: "不得为了获得 SNAP 福利提供虚假资料或隐瞒资讯。",
        penaltyWarningTwo: "不得买卖或出售 SNAP 福利。",
        penaltyWarningThree:
          "不得更改 EBT 卡，以便获取您没有资格享用的 SNAP 福利。",
        penaltyWarningFour:
          "不得使用 SNAP 福利购买不合格的物品，例如酒精饮料和烟草产品。",
        penaltyWarningFive:
          "	除非您是经授权代表，否则不得使用他人的 SNAP 福利或 EBT 卡。",

        penaltiesTitle: "我还理解以下处罚 ",
        individualLabel: "有蓄意违反",
        cashProgram: "现金计划",
        internationalProgram:
          " 	行为（IPV）者，在其失去获得现金援助资格的同时，亦会被取消获得SNAP 福利的资格。",
        penalitiesTwo:
          "•	为了同时获得多项 SNAP 福利而以自己的身份或居住地作出欺骗性陈述者将在  ",
        tenYearLabel: "十年",
        penalitiesThree:
          "意图获得受控物质/毒品而买卖 SNAP 福利者，首次发现将在两年内被取消获得SNAP 福利的资格，第二次发现将永远被取消获得 SNAP 福利的资格。 ",
        twoYearLabel: "两年 ",
        penalitiesThreeMiddle: "",
        foreverText: "永远 ",
        penalitiesThreeLast: "第二次发现将永远被取消获得  SNAP 福利的资格。",
        penalitiesFour:
          "意图获取武器、弹药或爆炸品而买卖 SNAP 福利者，将永远失去获得SNAP 福利的资格。",
        penalitiesFive:
          "买卖价值达 $500 的 SNAP 福利者，将永远被取消获得 SNAP 福利的资格。",
        penalitiesSix:
          "州政府有可能向在网上或亲自出售 SNAP 福利或 EBT 卡者 提出蓄意违反计划行为（IPV）的起诉。",
        penalitiesSeven:
          "避免被起诉而逃走者、已被定罪而逃避羁押或监禁者、及违反缓刑或假释规定者，均没有资格获得 SNAP 福利。",
        penalitiesEight:
          "严禁用获得的福利以支付购买粮食的信贷，违者有可能被取消 SNAP 资格。",
        penaltiesNine:
          "严禁以 SNAP 福利购买物品后，意图丢弃物品内装物再退回容器以套取现金。",

        interpreterHeader: "接受口译员协助的权利",
        interpreterContent:
          "我理解，如果我的 SNAP 家庭成员中没有任何成年人会讲或听懂英语，我有权获得 DHR 提供的口译员协助。我还理解，出席任何 DHR 公平听证时，我可以请求口译员协助，或者自带口译员。如果我因为出席听证而需要请求口译员协助，我必须在听证日期至少一周前打电话给听证分部（Division of Hearings）。",
        nonDiscriminationHeader: "不歧视声明",
        nonDiscriminationContent:
          "依照联邦民权法和美国农业部（USDA）民权法规章和政策的规定，USDA 及其代理机构、办事处和雇员以及参加或管理 USDA 计划的机构不得在 USDA 开展或资助的任何计划或活动中，有任何对种族、肤色、原国籍、性别、宗教信仰、残障、年龄、政治信仰的歧视行动或行为，或因以前的民权活动进行报复。",
        personDisabilities:
          "要求其他计划信息交流方法（例如，盲文、大印刷体、录音带、美国手语等）的残障人士应当与申请福利的（州或地方）代理机构联系。失聪者、听力或语言障碍者，均可通过联邦电话中转服务与 USDA 联系。电话号码为",
        programInformation:
          "此外，本计划是以用英语之外的其他语言提供计划资讯的。",

        programComplaint:
          "如需提交计划歧视申诉，请填写 USDA 计划歧视申诉表（AD-3027 表），请在以下网站查找该表格：",
        usdaOffice:
          "或到任何 USDA 办事处索取；您亦可写信给 USDA 提出申诉，请在信函中提供申诉表中要求的所有资料。如需索取申诉表，请电洽  ",
        sumbitYourLabel: "请通过以下方法向 USDA 提交填妥的申诉表或信函：",

        mailAddress: "邮寄: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " 传真:",
        orLabel: " 或",
        eamilTitle: "电子邮件: ",
        thisInstution: "本机构是公平机会服务提供者。",
      };

    case types.VIETNAMESE: //Vietnam
      return {
        termsHeader: "Quyền, Trách Nhiệm và Mức Phạt",
        headerLabel:
          "Thông Báo về Quyền, Trách Nhiệm và Mức Phạt - Vui lòng Đọc Kỹ",
        certifyContents:
          "Tôi xác nhận rằng tôi đã đọc, hoặc đã được đọc cho nghe, những dữ kiện thông tin trong đơn này. Các câu trả lời của tôi cho các câu hỏi trong đơn xin này là đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi cũng xác nhận rằng các dữ kiện thông tin mà tôi cung cấp cho Sở này trong buổi phỏng vấn xin trợ cấp và trong tương lai cũng sẽ đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi hiểu rằng việc khai man hoặc cung cấp các dữ kiện thông tin không đúng là gian lận. Tôi cũng hiểu rằng việc khai man hoặc che giấu các dữ kiện thông tin để hợp lệ nhận trợ cấp SNAP là gian lận. Việc này sẽ có hậu quả là tội Cố Ý Vi Phạm Chương Trình (IPV) và sẽ bị phạt bởi các hình phạt dân sự và hình sự.",
        understandDta:
          "Tôi hiểu rằng Sở Trợ Cấp Chuyển Tiếp (DHR) quản lý trợ cấp SNAP. Tôi hiểu rằng DHR có 30 ngày kể từ ngày nộp đơn xin để xử lý đơn xin của tôi. Ngoài ra, tôi cũng hiểu rằng:",
        foodNutritionact:
          "Đạo Luật Thực Phẩm và Dinh Dưỡng ban hành năm 2008 (7 U.S.C. 2011-2036) cho phép DHR sử dụng Số An Sinh Xã Hội (SSN) của tôi và SSN của mỗi thành viên hộ gia đình được tôi nộp đơn cho. DHR sử dụng thông tin này để xác định sự hợp lệ nhận trợ cấp SNAP của hộ gia đình tôi. DHR xác minh dữ kiện thông tin này qua các chương trình đối chiếu trên máy điện toán. Tôi hiểu rằng DHR sử dụng các dữ kiện thông tin này để giám sát việc tuân thủ nội quy chương trình.",
        reportingRulesDtachanges:
          "Trong đa số các trường hợp, các hộ gia đình thuộc diện quy định Báo Cáo Đơn Giản của SNAP phải thông báo cho DHR biết về các thay đổi trong bản Báo Cáo Tạm Thời (IR) và tái chứng nhận ngoại trừ :",
        incomeExceeds:
          "Nếu thu nhập của hộ gia đình tôi vượt quá mức ngưỡng tổng thu nhập",
        workRequirementlabel:
          "o	Nếu tôi là người lớn khỏe mạnh không có người phụ thuộc (ABAWD) tuân thủ các yêu cầu về làm việc và số giờ làm việc của tôi giảm xuống dưới 20 giờ mỗi tuần",
        verifiedInfomation:
          "Nếu DHR nhận được thông tin đã xác minh về hộ gia đình tôi, số tiền trợ cấp của tôi có thể thay đổi",
        tbaRulesone:
          "Nếu tôi không thuộc diện qui định trong các quy chế Báo Cáo Đơn Giản của SNAP hoặc các quy chế Thay Thế Trợ Cấp Chuyển Tiếp (TBA), tôi phải báo cáo cho DHR về các thay đổi liên quan đến hộ gia đình tôi mà có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của chúng tôi. Tôi hiểu rằng tôi phải báo cáo các thay đổi này cho DHR bằng cách đích thân tới gặp, qua thư viết hoặc qua điện thoại ",
        tbaRuletwo: " trong vòng 10 ngày kể từ khi có thay đổi đó.",
        tbaExample:
          " Ví dụ, quý vị phải báo cáo các thay đổi về thu nhập của hộ gia đình của quý vị, số người trong gia đình, hoặc địa chỉ.",
        findmeIneligible:
          "Tôi có quyền nói chuyện với một giám sát viên nếu DHR thấy tôi không hợp lệ nhận trợ cấp SNAP khẩn cấp và tôi không đồng ý với quyết định đó. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được trợ cấp của tôi trong vòng 7 ngày tính theo lịch sau khi nộp đơn xin trợ cấp SNAP. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được Thẻ Chuyển Trợ Cấp qua Mạng Điện Tử (EBT) của tôi trong vòng 7 ngày tính theo lịch sau khi tôi nộp đơn xin trợ cấp SNAP.",
        verificationtoDta:
          "Tôi có thể được nhận thêm trợ cấp SNAP nếu tôi báo cáo và cung cấp chứng từ cho DHR về:",
        childorOtherTitile:
          "chi phí giữ trẻ hoặc chi phí chăm sóc người phụ thuộc khác, chi phí nơi tạm trú, và/hoặc chi phí dịch vụ điện nước",
        legallyObligatedlabel:
          "tiền chu cấp nuôi con theo nghĩa vụ pháp lý đối với một thành viên không ở trong hộ gia đình",
        disabledMedicalcost:
          "Nếu tôi 60 tuổi hoặc già hơn và hoặc nếu tôi bị khuyết tật và tôi trả chi phí y tế, tôi có thể báo cáo và cung cấp chứng từ về các khoản chi phí này cho DHR. Việc này có thể giúp tôi hợp lệ được hưởng một khoản khấu trừ và làm tăng trợ cấp SNAP của tôi.",
        meetExemptionlabel:
          "Trừ khi hội đủ điều kiện được miễn, tất cả những người nhận SNAP tuổi từ 16 đến 59 nào đều phải đăng ký làm việc và phải tuân thủ các Yêu Cầu Làm Việc Tổng Quát của SNAP. Những người nhận SNAP tuổi từ 18 đến 49 cũng có thể phải tuân thủ các yêu cầu của Chương Trình Làm Việc ABAWD. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các yêu cầu làm việc nói trên. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các trường hợp ngoại lệ và các mức phạt trong trường hợp không tuân thủ.",
        voluntarilyParticipatelabel:
          "Đa số những người nhận SNAP có thể tự nguyện tham gia các dịch vụ huấn luyện việc làm và giáo dục qua chương trình Path to Work của SNAP. DHR sẽ giới thiệu hồ sơ đến chương trình SNAP Path to Work nếu thích hợp.",
        dtaNamescontact:
          "DHR cũng sẽ chia sẻ tên và thông tin liên lạc của những người nhận SNAP với các cơ sở cung cấp dịch vụ SNAP Path to Work để tuyển dụng. Tôi hiểu rằng các thành viên trong hộ gia đình của tôi có thể được các chuyên gia SNAP Path to Work hoặc các nhà thầu của DHR liên lạc để tìm hiểu về các lựa chọn tham gia SNAP Path to Work. Để biết thêm tin tức về chương trình SNAP Path to Work, thăm mạng ",
        subjectToverification:
          "Tôi hiểu rằng các dữ kiện thông tin mà tôi cung cấp cùng với đơn xin của tôi sẽ được kiểm tra xác minh để xác định là có trung thực hay không. Nếu bất kỳ dữ kiện thông tin nào là giả mạo, DHR có thể từ chối trợ cấp SNAP của tôi. Tôi cũng có thể bị truy tố hình sự vì cung cấp dữ kiện thông tin giả mạo.",
        signingApplication:
          "Tôi hiểu rằng khi ký đơn xin này, tôi cho phép DHR kiểm tra xác minh và điều tra các dữ kiện thông tin mà tôi cung cấp liên quan đến sự hợp lệ hưởng trợ cấp SNAP của tôi, trong đó bao gồm cả việc cho phép:",
        proveInformation:
          "Có được các giấy tờ để chứng minh dữ kiện thông tin trong đơn xin này với các cơ quan khác của tiểu bang, các cơ quan liên bang, các cơ quan gia cư địa phương, các sở phúc lợi ngoài tiểu bang, các tổ chức tài chánh và từ Equifax Workforce Solutions. Tôi cũng cho phép các cơ quan này cung cấp cho DHR thông tin về hộ gia đình tôi liên quan đến trợ cấp SNAP của tôi.",
        immigrationStatusLabel:
          "Nếu ứng dụng, kiểm tra xác minh diện di trú của tôi qua Sở Di Trú và Nhập Tịch Hoa Kỳ (USCIS). Tôi hiểu rằng DHR có thể xác minh dữ kiện thông tin trong đơn xin SNAP của tôi với USCIS. Bất kỳ dữ kiện thông tin nào nhận được từ USICIS có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của gia đình tôi và số tiền trợ cấp SNAP.",
        secondaryEducation:
          "Chia sẻ dữ kiện thông tin về tôi và những người phụ thuộc dưới 19 tuổi của tôi với Sở Giáo Dục Tiểu Học và Trung Học Phổ Thông (DESE). DESE sẽ chứng nhận những người phụ thuộc của tôi cho các chương trình bữa sáng và bữa trưa học đường.",
        publicHealth:
          "Chia sẻ dữ kiện thông tin về tôi, những người phụ thuộc dưới 5 tuổi của tôi và bất kỳ ai hiện đang mang thai trong gia đình tôi với Sở Sức Khỏe Công Cộng (DPH). DPH giới thiệu những người này tới Chương Trình Phụ Nữ, Trẻ Sơ Sinh và Trẻ Em (WIC) để nhận các dịch vụ dinh dưỡng.",
        executiveOfficeHealth:
          "Chia sẻ dữ kiện thông tin, cùng với Văn Phòng Điều Hành Dịch Vụ Sức Khỏe và Nhân Sự Alabama, về sự hợp lệ nhận trợ cấp SNAP của tôi với các hãng điện, hãng khí đốt, và các hãng cung cấp dịch vụ truyền hình cáp và điện thoại hợp lệ để chứng nhận sự hợp lệ được giảm giá dịch vụ điện nước của tôi.",
        housingCommuntiy:
          "Chia sẻ dữ kiện thông tin của tôi với Sở Gia Cư và Phát Triển Cộng Đồng (DHCD) để ghi danh cho tôi tham gia Chương Trình Sưởi Ấm và Thực Phẩm (Heat & Eat Program).",
        lowerBenefitslabel:
          "DHR có thể từ chối, ngừng hoặc giảm trợ cấp của tôi dựa trên dữ kiện thông tin từ Equifax Workforce Solutions. Tôi có quyền nhận được một bản miễn phí báo cáo của tôi từ Equifax nếu tôi yêu cầu trong vòng 60 ngày kể từ khi có quyết định của DHR. Tôi có quyền chất vấn mức độ chính xác hoặc đầy đủ của các dữ kiện thông tin trong báo cáo của tôi. Tôi có thể liên lạc với Equifax tại: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
        tollFreeLabel: "(đường dây miễn phí).",
        rightoKnowbrochure:
          "Tôi hiểu rằng tôi sẽ nhận được một bản sao tờ thông tin 'Quyền Được Biết của Quý Vị' và tờ thông tin về Chương Trình SNAP. Tôi sẽ đọc hoặc sẽ nhờ người đọc các tờ thông tin và tôi phải hiểu nội dung cũng như các quyền và trách nhiệm của tôi. Nếu có thắc mắc về các tờ thông tin hoặc bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Nếu gặp khó khăn trong việc đọc hoặc hiểu bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Có thể liên lạc với DHR tại: ",
        lawfullyResiding:
          "Tôi tuyên thề rằng tất cả các thành viên trong hộ gia đình SNAP hiện đang xin trợ cấp SNAP của tôi là công dân Hoa Kỳ hoặc ngoại kiều cư trú hợp pháp.",
        registerVoteTitle: "Quyền Đăng Ký để Bỏ Phiếu",
        registerVoteFirstLabel:
          "Tôi hiểu rằng tôi có quyền đăng ký bỏ phiếu tại DHR. Tôi hiểu rằng DHR sẽ giúp tôi điền mẫu đơn xin đăng ký cử tri nếu tôi cần giúp đỡ. Tôi được phép điền mẫu đơn xin đăng ký cử tri một cách kín đáo.",
        registerVoteSecondLabel:
          "Tôi hiểu rằng việc xin đăng ký hoặc từ chối đăng ký bỏ phiếu sẽ không ảnh hưởng đến số tiền trợ cấp mà tôi nhận được từ DHR. ",
        penaltyWarningTitle: "Cảnh Báo về Mức Phạt SNAP",
        penaltyWarningContent:
          "Tôi hiểu rằng nếu tôi hoặc bất kỳ thành viên nào trong hộ gia đình SNAP của tôi cố ý vi phạm bất kỳ quy chế nào ghi dưới đây, người đó sẽ không hợp lệ nhận trợ cấp SNAP cho một năm sau lần vi phạm đầu tiên, hai năm sau lần vi phạm thứ hai và vĩnh viễn sau lần vi phạm thứ ba. Người đó cũng có thể bị phạt tiền tới mức tối đa là $250.000 (250 ngàn Mỹ kim), phạt tù tới mức tối đa 20 năm hoặc cả hai. Người đó cũng có thể bị truy tố theo các điều luật hiện hành khác của Tiểu Bang và Liên Bang. Các quy chế này là:",
        penaltyWarningOne:
          "Không khai man hoặc che giấu đữ kiện thông tin để được nhận trợ cấp SNAP.",
        penaltyWarningTwo: "Không mua bán trao đổi hoặc bán trợ cấp SNAP.",
        penaltyWarningThree:
          "Không sửa đổi thẻ EBT để được nhận tiền trợ cấp SNAP mà quý vị không hợp lệ nhận.",
        penaltyWarningFour:
          "Không sử dụng trợ cấp SNAP để mua các vật dụng không hợp lệ, chẳng hạn như đồ uống có chất rượu và thuốc lá.",
        penaltyWarningFive:
          "Không sử dụng trợ cấp SNAP hoặc thẻ EBT của người khác, trừ khi quý vị là người được ủy quyền hợp pháp.",

        penaltiesTitle: "Tôi cũng hiểu các mức phạt sau đây: ",
        individualLabel:
          "	Những người phạm tội Cố Ý Vi Phạm Chương Trình (IPV) của ",
        cashProgram: "chương trình trợ cấp tiền mặt ",
        internationalProgram:
          " sẽ không hợp lệ nhận SNAP trong cùng một giai đoạn mà người đó không hợp lệ nhận trợ cấp tiền mặt.",
        penalitiesTwo:
          "Những người khai man về lý lịch hoặc nơi cư trú để được nhận nhiều khoản trợ cấp SNAP cùng một lúc sẽ không hợp lệ nhận trợ cấp SNAP trong  ",
        tenYearLabel: "mười năm",
        penalitiesThree:
          "Những người trao đổi (mua hay bán) trợ cấp SNAP để lấy chất kích thích bất hợp pháp/chất bị kiểm soát, sẽ không hợp lệ nhận SNAP trong  ",
        twoYearLabel: "hai năm  ",
        penalitiesThreeMiddle: "đối với lần tuyên án đầu tiên và ",
        foreverText: "vĩnh viễn  ",
        penalitiesThreeLast: " đối với lần tuyên án thứ hai.",
        penalitiesFour:
          "Những người trao đổi (mua hay bán) trợ cấp SNAP để đổi lấy vũ khí, đạn dược, hoặc chất nổ, sẽ không hợp lệ nhận SNAP",
        penalitiesFive:
          "Những người trao đổi mua bán trợ cấp SNAP có giá trị từ $500 trở lên sẽ không hội đủ điều kiện nhận trợ cấp SNAP ",
        penalitiesSix:
          "Tiểu Bang có thể áp dụng IPV đối với một người mời chào bán trợ cấp SNAP hoặc thẻ EBT trên mạng trực tuyến hoặc gặp trực diện.",
        penalitiesSeven:
          "Những người bỏ trốn để tránh bị truy tố, quản chế hoặc giam giữ sau khi bị kết án phạm trọng tội, hoặc vi phạm điều kiện quản chế hoặc tha bổng có điều kiện, đều không hợp lệ nhận trợ cấp SNAP.",
        penalitiesEight:
          "Không được phép dùng để trả đồ ăn mua nợ và nếu làm như vậy có thể bị mất điều kiện nhận trợ cấp SNAP.",
        penaltiesNine:
          "Các cá nhân không được mua sản phẩm bằng trợ cấp SNAP với mục đích lấy sản phẩm bên trong ra và trả lại hộp đựng để lấy tiền mặt.",

        interpreterHeader: "Quyền có Người Thông Dịch",
        interpreterContent:
          "Tôi hiểu rằng tôi có quyền dùng một thông dịch viên do DHR cung cấp nếu trong hộ gia đình tôi không có người lớn nào có thể nói hoặc hiểu tiếng Anh. Tôi cũng hiểu rằng tôi có thể có một thông dịch viên cho bất kỳ buổi điều trần công bằng DHR nào hoặc mang theo người thông dịch riêng. Nếu cần người thông dịch cho một buổi điều trần, tôi phải gọi Ban Điều Trần ít nhất một tuần trước ngày điều trần.",
        nonDiscriminationHeader: "Tuyền Bố về Không Phân Biệt Đối Xử",
        nonDiscriminationContent:
          "Chiếu theo luật dân quyền Liên Bang và các chính sách và quy chế về dân quyền của Bộ Nông Nghiệp Hoa Kỳ (USDA), USDA, các Cơ Quan, văn phòng, và nhân viên của bộ, cũng như các tổ chức tham gia hoặc điều hành các chương trình của USDA thì bị nghiêm cấm sự phân biệt đối xử vì lý do sắc tộc, màu da, nguồn gốc quốc gia, giới tính, tín ngưỡng, tôn giáo, tình trạng khuyết tật, tuổi, lý tưởng chính trị, hoặc trả đũa hoặc trả thù vì đã từng tham gia hoạt động dân quyền trong bất kỳ chương trình hay hoạt động nào do USDA tổ chức hoặc đài thọ..",
        personDisabilities:
          "Những người khuyết tật cần phương tiện giao tiếp thay thế khác để nhận tin tức về chương trình (ví dụ như chữ nổi Braille, bản in bằng chữ khổ lớn, băng ghi âm, Ngôn ngữ ký hiệu Hoa Kỳ, v.v...), cần liên lạc với văn phòng Cơ quan (Tiểu bang hoặc địa phương) nơi họ nộp đơn xin trợ cấp. Người bị điếc, khiếm thính hoặc có khuyết tật về nói có thể liên lạc với USDA qua Dịch vụ Tiếp Âm Liên Bang tại số ",
        programInformation:
          "Thêm vào đó, tin tức về chương trình có thể được cung cấp bằng các ngôn ngữ khác không phải là tiếng Anh.,",

        programComplaint:
          "Để nộp khiếu nại về phân biệt đối xử liên quan đến chương trình, điền Mẫu Đơn Khiếu Nại về Phân Biệt Đối Xử trong Chương Trình của USDA, (AD-3027), có trên mạng trực tuyến tại: ",
        usdaOffice:
          "và tại bất kỳ văn phòng nào của USDA, hoặc viết thư gửi cho USDA và trong thư cung cấp tất cả các dữ kiện thông tin yêu cầu trong mẫu đơn khiếu nại. Để yêu cầu cung cấp mẫu đơn khiếu nại, vui lòng gọi số ",
        sumbitYourLabel:
          "Nộp mẫu đơn đã điền hoàn tất hoặc thư của quý vị tới cho USDA qua:",

        mailAddress: "qua thư bưu điện: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " hoặc",
        eamilTitle: "email: ",
        thisInstution: "Tổ chức này là một cơ quan cung cấp cơ hội bình đẳng.",
      };
    case HI:
      return {
        termsHeader: "Avi sou Dwa, Responsablite ak Sanksyon",
        headerLabel:
          "Avi sou Dwa, Responsablite ak Sanksyon – Tanpri Li ak anpil Atansyon",
        certifyContents:
          "Nan siyen, mwen dakò ke:  Mwen te li tout fòm sa a (oswa mwen te fè li li pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite yo, epi m konprann ke mwen dwe respekte règleman sa yo. Enfòmasyon m ap bay yo vre epi yo konplè dapre sa m byen konnen. M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) tout enfòmasyon m bay yo",
        understandDta:
          "M konprann ke Depatman Asistans Tranzisyonèl la (DHR) administre SNAP. M konprann ke DHR gen 30 jou apati dat aplikasyon an pou trete aplikasyon mwen an. Anplis, m konprann ke:",
        foodNutritionact:
          "Lwa sou Manje ak Nitrisyon 2008 la (7 U.S.C. 2011-2036) pèmèt DHR itilize Nimewo Sekirite Sosyal mwen (NSS) ak NSS chak manm fwaye mwen aplike pou yo. DHR itilize enfòmasyon sa a pou detèmine kalifikasyon fwaye mwen pou SNAP. DHR verifye enfòmasyon sa yo atravè pwogram enfòmatik pou matche. M konprann ke DHR itilize li pou kontwole konfòmite ak règleman pwogram yo.",
        reportingRulesDtachanges:
          "Anpil fwa, fwaye yo ki anba règleman SNAP Rapò Senplifye dwe enfòme DHR konsènan chanjman nan Rapò Pwovizwa (IR) ak resètifikasyon eksepte:",
        incomeExceeds: "Si revni fwaye mwen depase limit revni brit la",
        workRequirementlabel:
          "Si mwen anba kondisyon travay Adilt kapab san depandan yo (ABAWD) epi kantite lè travay mwen an desann pi ba pase 20 èdtan pa semèn.",
        verifiedInfomation:
          "Si DHR resevwa enfòmasyon ki verifye konsènan fwaye mwen, montan benefis mwen ka chanje",
        tbaRulesone:
          "Si mwen pa anba règleman SNAP Simplified Reporting oswa Altènatif Benefis Tranzisyonèl (TBA), mwen dwe rapòte bay DHR chanjman ki fèt nan fwaye m ki ka afekte kalifikasyon nou. M konprann ke mwen dwe rapòte chanjman sa yo bay DHR an pèsòn, alekri oswa pa telefòn nan",
        tbaRuletwo: " 10 jou apre chanjman an.",
        tbaExample:
          " Pa egzanp, ou dwe rapòte chanjman nan revni, gwosè, oswa adrès fwaye w la.",
        findmeIneligible:
          "Mwen gen yon dwa pou pale ak yon sipèvizè si DHR jwenn mwen pa kalifye pou benefis Ijans SNAP epi m pa dakò. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis SNAP ijans men m pa resevwa benefis mwen nan setyèm jou kalandriye apre mwen te aplike pou SNAP la. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis ijans SNAP men m pa resevwa kat Benefis Transfè Elektwonik (EBT) mwen nan setyèm jou Kalandriye apre mwen te aplike pou SNAP la.",
        verificationtoDta:
          "Mwen ka resevwa plis benefis SNAP si mwen rapòte epi bay DHR verifikasyon pou:",
        childorOtherTitile:
          "depans pou swen timoun oswa lòt depandan, depans pou lojman, ak/oswa depans pou sèvis piblik",
        legallyObligatedlabel:
          "sipò timoun legalman-obligatwa a yon manm ki pa nan fwaye a",
        disabledMedicalcost:
          "Si m gen 60 an oswa plis oswa si mwen andikape epi mwen peye pou depans medikal, mwen ka rapòte epi bay DHR verifikasyon depans sa yo. Sa ka fè m kalifye pou yon dediksyon epi ogmante benefis SNAP mwen.",
        meetExemptionlabel:
          "Sòf si yo satisfè yon egzanpsyon, tout benefisyè SNAP yo ki genyen ant laj 16 ak 59 an enskri pou travay epi anba Kondisyon Jeneral Travay SNAP. Benefisyè SNAP ki genyen ant laj 18 ak 49 an kapab tou anba kondisyon Pwogram Travay ABAWD. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan kondisyon travay yo. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan eksepsyon ak sanksyon pou non-konfòmite.",
        voluntarilyParticipatelabel:
          "Pifò benefisyè SNAP yo ka volontèman patisipe nan sèvis edikasyon ak fòmasyon pou travay atravè pwogram Path to Work SNAP la. DHR pral bay referans nan pwogram Path to Work SNAP la si sa apwopriye",
        dtaNamescontact:
          "DHR ka pataje tou non ak enfòmasyon kontak benefisyè SNAP yo ak founisè Path to Work yo pou rezon rekritman. M konprann ke manm fwaye mwen yo ka kontakte pa espesyalis DHR SNAP Path to Work oswa founisè kontra pou eksplore opsyon patisipasyon nan SNAP Path to Work. Pou plis enfòmasyon konsènan SNAP Path to Work la, vizite ",
        subjectToverification:
          "M konprann ke enfòmasyon mwen bay ak aplikasyon m nan pral sijè a verifikasyon pou detèmine si li vre. Si nenpòt enfòmasyon fo, DHR ka refize benefis SNAP mwen yo. Mwen ka tou sijè a pwosekisyon kriminèl paske m te bay fo enfòmasyon.",
        signingApplication:
          "M konprann ke nan siyen aplikasyon sa a, mwen bay DHR pèmisyon pou verifye epi mennen ankèt sou enfòmasyon mwen bay ki lye ak kalifikasyon m pou benefis SNAP, ki enkli pèmisyon pou:",
        proveInformation:
          "Jwenn dokiman pou pwouve enfòmasyon nan aplikasyon sa a ak lòt ajans etatik, ajans federal, otorite lojman lokal, depatman byennèt andeyò eta a, enstitisyon finansye ak soti nan Equifax Workforce Solutions. Mwen bay ajans sa yo pèmisyon tou pou bay DHR enfòmasyon sou fwaye m nan ki konsène benefis SNAP mwen yo.",
        immigrationStatusLabel:
          "Si sa aplikab, verifye estati imigrasyon mwen atravè Sèvis Sitwayènte ak Imigrasyon Etazini (USCIS). M konprann ke DHR ka verifye enfòmasyon ki nan aplikasyon SNAP mwen ak USCIS. Tout enfòmasyon yo resevwa nan men USCIS ka afekte kalifikasyon fwaye m ak montan benefis SNAP. ",
        secondaryEducation:
          "Pataje enfòmasyon konsènan mwen menm ak depandan mwen yo ki poko gen 19 an ak Depatman Edikasyon Elemantè ak Segondè (DESE). DESE pral sètifye depandan mwen yo pou pwogram manje maten ak manje midi nan lekòl. ",
        publicHealth:
          "Pataje enfòmasyon konsènan mwen, depandan mwen ki poko gen laj 5 an ak nenpòt moun ki ansent nan fwaye m nan ak Depatman Sante Piblik (DPH). DPH refere moun sa yo bay Pwogram Fanm, Tibebe ak Timoun (WIC) pou sèvis nitrisyon.",
        executiveOfficeHealth:
          "Pataje enfòmasyon, ansanm ak Biwo Egzekitif Sante ak Sèvis Imen Alabama la, sou kalifikasyon m pou SNAP ak konpayi elektrik, konpayi gaz ak konpayi telefòn ak kab ki kalifye pou sètifye kalifikasyon m nan tarif redui pou rabè sèvis piblik.",
        housingCommuntiy:
          "Pataje enfòmasyon m ak Depatman Lojman ak Devlopman Kominotè (DHCD) nan objektif pou enskri m nan Pwogram Heat & Eat a. ",
        lowerBenefitslabel:
          "DHR ka refize, sispann oswa diminye benefis mwen yo baze sou enfòmasyon ki soti nan Equifax Workforce Solutions. M gen dwa pou jwenn yon kopi rapò mwen an gratis nan men Equifax si mwen mande li nan 60 jou apre desizyon DHR a. M gen dwa pou poze kesyon sou presizyon oswa eta konplè enfòmasyon ki nan rapò mwen an. Mwen ka kontakte Equifax nan: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, 1-800-996-7566 ",
        tollFreeLabel: "",
        rightoKnowbrochure:
          "M konprann ke m pral jwenn yon kopi bwochi “Your Right to Know” a ak bwochi Pwogram SNAP la. M pral li oswa te fè li bwochi yo epi mwen dwe konprann kontni yo ak dwa ak responsablite mwen yo. Si m gen nenpòt kesyon konsènan bwochi yo oswa nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Si m gen pwoblèm pou li oswa konprann nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Ou ka kontakte DHR nan:",
        lawfullyResiding:
          "Mwen fè sèman ke tout manm fwaye SNAP mwen yo k ap mande benefis SNAP se swa Sitwayen Ameriken swa Non-sitwayen Ameriken ki rezide legalman.",
        registerVoteTitle: "Dwa pou Enskri pou Vote",
        registerVoteFirstLabel:
          "M konprann ke mwen gen dwa enskri pou vote nan DHR. Mwen konprann ke  DHR pral ede m ranpli fòm aplikasyon pou enskripsyon elektè a si mwen bezwen èd. Mwen gen dwa ranpli fòm aplikasyon pou enskripsyon elektè a an prive.",
        registerVoteSecondLabel:
          "M konprann ke nan aplike pou m enskri oswa refize enskri pou vote pa pral afekte montan benefis mwen resevwa nan men DHR.",
        penaltyWarningTitle: "Avètisman sou Sanksyon SNAP",
        penaltyWarningContent:
          "M konprann ke si mwen menm oswa nenpòt manm fwaye SNAP mwen vyole nenpòt nan règleman ki anba yo, moun sa a pa pral kalifye pou SNAP pou yon ane apre premye vyolasyon an, dezan apre dezyèm vyolasyon an epi pou tout tan apre twazyèm vyolasyon an. Moun sa a ka peye tou yon amann jiska $250,000, ale nan prizon jiska 20 an, oswa toulede. Yo kapab tou sijè a pousuit anba lwa Federal ak Etatik. Règleman sa yo se:",
        penaltyWarningOne:
          "Pa bay fo enfòmasyon oswa kache enfòmasyon pou resevwa benefis SNAP.",
        penaltyWarningTwo: "Pa fè echanj ak oswa vann benefis SNAP.",
        penaltyWarningThree:
          "Pa chanje kat EBT pou resevwa benefis SNAP ou pa kalifye pou yo.",
        penaltyWarningFour:
          "Pa itilize benefis SNAP pou achte atik ki pa kalifye, tankou bwason alkolize ak tabak.",
        penaltyWarningFive:
          "Pa itilize SNAP oswa kat EBT yon lòt moun, sof si ou se yon reprezantan otorize.",

        penaltiesTitle: "M konprann tou sanksyon ki anba yo: ",
        individualLabel: "Moun yo ki komèt yon",
        cashProgram: "pwogram kach",
        internationalProgram:
          " Pwogram Vyolasyon Entansyonèl (IPV), yo pa pral kalifye pou SNAP pou menm peryòd moun nan pa kalifye pou asistans lajan kach.",
        penalitiesTwo:
          "Moun yo ki fè yon deklarasyon fwòd sou idantite oswa rezidans yo pou resevwa plizyè benefis SNAP alafwa pa pral kalifye pou SNAP pou ",
        tenYearLabel: "dizan",
        penalitiesThree:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou yon sibstans kontwole/dwòg ilegal (yo), yo pa pral kalifye pou SNAP pou ",
        twoYearLabel: "dezan ",
        penalitiesThreeMiddle: "pou premye dekouvèt la, epi",
        foreverText: "pou tout tan ",
        penalitiesThreeLast: "pou dezyèm dekouvèt la.",
        penalitiesFour:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou zam afe, minisyon oswa eksplozif, yo pa pral kalifye pou SNAP",
        penalitiesFive:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP ki gen yon valè $500 oswa plis, pa pral kalifye pou SNAP",
        penalitiesSix:
          "Eta a ka pousuiv yon IPV kont yon moun ki fè yon òf pou vann benefis SNAP oswa yon kat EBT an liy oswa an pèsòn",
        penalitiesSeven:
          "Moun ki sove pou evite pousuit, detansyon oswa prizon apre yo fin kondane pou yon krim, oswa ki vyole pwobasyon oswa libète sou kondisyon, yo pa kalifye pou SNAP.",
        penalitiesEight:
          "Peye pou manje achte kredi pa otorize epi ka menmen nan diskalifikasyon pou SNAP.",
        penaltiesNine:
          "Moun pa gen dwa achte pwodui ak benefis SNAP ak entansyon pou jete kontni yo epi retounen resipyan pou lajan kach.",

        interpreterHeader: "Dwa a yon Entèprèt",
        interpreterContent:
          "M konprann ke mwen gen dwa pou jwenn yon entèprèt DHR bay si okenn adilt nan fwaye SNAP mwen pa kapab pale oswa konprann angle. Mwen konprann tou ke mwen ka jwenn yon entèprèt pou nenpòt odyans DHR san patipri oswa mennen pwòp entèprèt mwen. Si m bezwen yon entèprèt pou yon odyans, mwen dwe rele Divizyon Odyans lan omwen yon semèn anvan dat odyans lan.",
        nonDiscriminationHeader: "Deklarasyon Non-diskriminasyon",
        nonDiscriminationContent:
          "Nan konfòmite ak lwa sou dwa sivil federal yo ak règleman ak politik Depatman Agrikilti Ameriken (USDA), USDA, ajans li yo, biwo, ak anplwaye li yo, ak enstitisyon k ap patisipe nan oswa ap administre pwogram USDA yo, yo entèdi pou fè diskriminasyon ki baze sou ras, koulè, orijin nasyonal, sèks, kwayans relijye, andikap, laj, konviksyon politik, oswa reprezay oswa vanjans pou aktivite dwa sivil anvan nan nenpòt pwogram oswa aktivite ki fèt oswa ki finanse pa USDA.",
        personDisabilities:
          "Moun andikape ki bezwen lòt mwayen kominikasyon pou enfòmasyon sou pwogram yo (pa egzanp Bray, gwo lèt, kasèt odyo, Lang Siy Ameriken, elatriye), ta dwe kontakte Ajans (Etatik oswa lokal) la kote yo te aplike pou benefis yo. Moun ki soud, ki pa tande byen oswa ki gen andikap pou pale ka kontakte USDA atravè Sèvis Federal Relay la nan ",
        programInformation:
          "Anplis, enfòmasyon sou pwogram yo ka disponib nan lòt lang ki pa Angle.",

        programComplaint:
          "Pou depoze yon plent diskriminasyon kont yon pwogram, ranpli Fòm Program Discrimination Complaint USDA a, (AD-3027) ke w ap jwenn an liy nan:  http://www.ascr.usda.gov/complaint_filing_cust.html, ak nan nenpòt biwo USDA, oswa ekri yon lèt ki adrese bay USDA epi mete nan lèt la tout enfòmasyon yo mande nan fòm nan. Pou mande yon kopi fòm plent lan, rele (866) 632-9992. Soumèt fòm oswa lèt ranpli w la bay USDA nan: ",
        usdaOffice:
          "ak nan nenpòt biwo USDA, oswa ekri yon lèt ki adrese bay USDA epi mete nan lèt la tout enfòmasyon yo mande nan fòm nan. Pou mande yon kopi fòm plent lan, rele (866) 632-9992. Soumèt fòm oswa lèt ranpli w la bay USDA nan: ",
        sumbitYourLabel: "Soumèt fòm ranpli a oswa lèt la bay USDA pa:",

        mailAddress: "lapòs: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " faks: (202) 690-7442;",
        orLabel: " oswa",
        eamilTitle: "imel:  program.intake@usda.gov.",
        thisInstution: "Enstitisyon sa a se yon founisè opòtinite egal.",
        receivedLieapOptions: ["Yes", "No"],
        receivedLieapLabel:
          "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
        lifelineProvidesOptions: ["Yes", "No"],
        lifelineProvides:
          "Lifeline provides a discount on the cost of monthly telephone service if you qualify for the service. If you need help paying for phone service and are approved for food assistance, we can share your name, address, telephone number, and the last four digits of your social security number with the Alabama Public Service Commission (PSC) and telecommunication companies for the purpose of potential LIFELINE enrollment. Do you agree for Food Assistance to furnish your information to PSC?",
      };
    default:
      return;
  } //terms tranlation ending
}
