import React, { Component } from "react";
import { interimReportPageLabels } from "../../../pages/recertCovid/recertCovidText";
import * as languageConstants from "../../constants/constants";
import { getAudit } from "../../constants/audit";
import * as helperFunction from "../../components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class CovidRecertStartForm extends Component {
  handleListClickAction = (event) => {
    event.preventDefault();
    var documentId = this.props.startFormData.responseObject.documentID;
    var agencyId = this.props.startFormData.responseObject.agencyID;
    var auditData = getAudit();
    this.props.onNotificationItemClick(documentId, auditData, agencyId);
  };

  render() {
    return (
      <div className="pad-all--double">
        {
          <p className="interim-recert-heading">
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).fieldLabel1
            }
            <br />
          </p>
        }
        <ul>
          {
            <li
              dangerouslySetInnerHTML={{
                __html: interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[0],
              }}
            />
          }
          <li style={{ marginTop: "1.4rem" }}>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).recertMethods[1].before
            }
            <a onClick={this.handleListClickAction}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[1].hereLinkText
              }
            </a>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).recertMethods[1].after
            }
          </li>
          <li style={{ listStyle: "none" }}>
            <ul style={{ marginTop: "1.4rem" }}>
              <li style={{ listStyle: "disc" }}>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).recertMethods[2]
                }
              </li>
              <li style={{ listStyle: "disc" }}>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).recertMethods[3]
                }
              </li>
              <li style={{ listStyle: "disc" }}>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).recertMethods[4]
                }
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default CovidRecertStartForm;
