import PropTypes, { func } from "prop-types";
import { recertificationPageLabels } from "./recertificationText";
import * as languageConstants from "../../utils/constants/constants";
import * as TextProps from "../../utils/constants/text";
import { ZIPCODE } from "../../utils/constants/zipCode";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as formatters from "../../utils/components/utilities/formatters";
import {
  IncomeSectionTemplate,
  unearnedIncomeSectionTemplate,
  expenseChildSupportTemplate,
  houseHoldInformationTemplate,
  utilityExpensesTemplate,
  utilityObj,
  medicalExpenseKeys,
  medicalExpenseObj,
  ResourcesSectionTemplate
} from "./recertificationMapData";
import {
  incomeDynamicMultilanguageLabels,
  interimReportPageLabels,
  unearnedIncomeDynamicMultilanguageLabels,
} from "../recertInterimReport/recertInterimReportText";
import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import { earnedIncomeCodeMapper } from "./earnedIncomeMapData";
import * as types from "../../utils/constants/types";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import {
  getFrequency,
  getFrequencyCode
} from "../../utils/components/utilities/utils";
import { unearnedIncomeCodeMapper } from "./unearnedIncomemapData";
import {
  incomeSectionMultilanguageLabels,
  resourcesSectionMultilanguageLabels
} from "../applySnap/applySnapText";
import _ from "lodash";
const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];
      question.value = AnswerObj[activeId][question.name];
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.value =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              if(section.parentId !== "Dependentcarecosts"){
                conditionQuestion.Answer = "";
                conditionQuestion.targetValue = "";
                conditionQuestion.value = "";
              }
            }
            return conditionQuestion;
          });
          return condition;
        });
      }
    } else if (question.conditionals) {
      question.conditionals.map((condition) => {
        //Question Itration
        condition.conditionalQuestions.map((conditionQuestion) => {
          if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
            conditionQuestion.Answer =
              AnswerObj[activeId][conditionQuestion.name];
            conditionQuestion.targetValue =
              AnswerObj[activeId][conditionQuestion.name];
            conditionQuestion.value =
              AnswerObj[activeId][conditionQuestion.name];
          }
          return conditionQuestion;
        });
        return condition;
      });
    }else {
      if (
        (AnswerObj[activeId] === undefined && question.Answer === undefined) ||
        question.Answer === undefined
      ) {
        question.Answer = "";
        question.targetValue = "";
        question.value = "";
      }
    }
    return question;
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  let forceInvalid = TextProps.VALUE_FALSE;
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "about-me") {
        buildApplyAboutMeValidations(inputSections);
      } else if (activeId === "add-contact-info") {
        buildApplyContactInfoValidations(inputSections);
      } else if (activeId === "emergency-snap") {
        buildApplyEmergencyValidations(inputSections);
      } else if (activeId === "more-info") {
        buildApplyMoreInfoValidations(inputSections);
      } else if (activeId === "optional") {
        buildApplyOptionalValidations(inputSections);
      } else if (activeId === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId.substring(0, 16) === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (
        activeId.substring(0, 15) === "medical-address" ||
        activeId.substring(0, 27) === "kids-adult-multiple-address"
      ) {
        buildApplyMediacalAddressValidations(inputSections);
      } else if (activeId === "household-information") {
        forceInvalid = buildHouseholdInformationValidations(inputSections);
      }
      //  else if (/combined-resources-\d/.test(activeId)) {
      //   forceInvalid = buildResourcesValidations(inputSections);
      // } else if (activeId.includes("combined-resources-subsection")) {
      //   forceInvalid = buildResourcesValidationsSubsection(inputSections);
      // }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (let answerNotElected of dataSections) {
      if (
        answerNotElected.type === "date" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputSSN" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputPhone" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputText" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "emailAddress"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "address"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (answerNotElected.isRequired === TextProps.VALUE_TRUE) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        dataID.substring(0, 27) === "kids-adult-multiple-address" ||
        dataID.substring(0, 15) === "medical-address"
      ) {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (dataID === "add-contact-info") {
        var answerNotElectedMailingAddress =
          dataSections[2].conditionals[0].conditionalQuestions[0];
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        (answerNotElected.type === "inputText" ||
          answerNotElected.type === "inputAlphaText") &&
        answerNotElected.error === TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(answerNotElected.Answer);
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnwsers = [];
  if (sections !== undefined) {
    const dataSections = sections.questions;
    for (let answerElected of dataSections) {
      if (
        (answerElected.type === "date" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputSSN" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputPhone" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputText" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "emailAddress") ||
        (answerElected.type === "address" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "address") ||
        (answerElected.Answer !== "" &&
          answerElected.isRequired === TextProps.VALUE_TRUE &&
          answerElected.type !== "address" &&
          answerElected.error === TextProps.VALUE_FALSE)
      ) {
        totalAnwsers.push(answerElected.Answer);
      }
    }
  }
  // checking required fields and total touched fileds
  if (
    forceInvalid ||
    (sections !== undefined && totalReqFields.length !== totalAnwsers.length)
  ) {
    return TextProps.VALUE_FALSE;
  } else {
    return TextProps.VALUE_TRUE;
  }
}

function buildApplyHouseholdMembersValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionValue of dataSections) {
      if (sectionValue.type === "select") {
        if (
          sectionValue.Answer.length < 1 &&
          sectionValue.Answer !== null &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (
        sectionValue.type === "inputText" ||
        sectionValue.type === "inputAlphaText" || sectionValue.type === "specialText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        if(sectionValue.type === "specialText") {
          var rexNumber = "^([a-zA-Z_.']*)+$";
        }
        else {
          var rexNumber = /\d+/;
        }
        var rexSpace = /^(?=.*\S).+$/;
        sectionValue.Answer = sectionValue.Answer.trim();
        if (
          sectionValue.Answer.length < 1 &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          sectionValue.Answer.length >= 1 &&
          (rexNumber.test(sectionValue.Answer) === TextProps.VALUE_TRUE ||
            rexSpace.test(sectionValue.Answer) === TextProps.VALUE_FALSE)
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionValue.type === "date") {
        if (PropTypes.date_error === 1) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionValue.Answer === "" || sectionValue.Answer === null) &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionValue.Answer !== "" || sectionValue.Answer !== null) &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          var inputDate = sectionValue.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            sectionValue.error = TextProps.VALUE_TRUE;
          } else {
            sectionValue.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionValue.type === "inputSSN") {
        if (
          sectionValue.Answer.length < 1 &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          sectionValue.Answer.length > 0 &&
          sectionValue.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (regSSN.test(sectionValue.Answer) === TextProps.VALUE_FALSE) {
            sectionValue.error = TextProps.VALUE_TRUE;
          } else {
            sectionValue.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionValue.type === "radio") {
        if (
          sectionValue.Answer === "" &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyOptionalValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionVal of dataSections) {
      if (sectionVal.type === "radio" || sectionVal.type === "checkbox") {
        if (
          sectionVal.Answer === "" &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyAboutMeValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionVal of dataSections) {
      if (sectionVal.type === "select") {
        if (
          sectionVal.Answer.length < 1 &&
          sectionVal.Answer !== null &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      } else if (
        sectionVal.type === "inputText" ||
        sectionVal.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          sectionVal.Answer.length >= 0 &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(sectionVal.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(sectionVal.Answer) === TextProps.VALUE_FALSE
          ) {
            sectionVal.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          sectionVal.Answer.length > 0 &&
          sectionVal.isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(sectionVal.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(sectionVal.Answer) === TextProps.VALUE_FALSE
          ) {
            sectionVal.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (sectionVal.name === "lastName" && sectionVal.Answer.length > 20) {
          sectionVal.error = TextProps.VALUE_TRUE;
          sectionVal.errorMessage = sectionVal.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (sectionVal.name !== "lastName" && sectionVal.Answer.length > 15) {
          sectionVal.error = TextProps.VALUE_TRUE;
          sectionVal.errorMessage = sectionVal.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionVal.type === "date") {
        if (PropTypes.date_error === 1) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionVal.Answer === "" || sectionVal.Answer === null) &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionVal.Answer !== "" || sectionVal.Answer === null) &&
          sectionVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var inputDate = sectionVal.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            sectionVal.error = TextProps.VALUE_TRUE;
          } else {
            sectionVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionVal.type === "inputSSN") {
        if (
          sectionVal.Answer.length < 1 &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else if (
          sectionVal.Answer.length > 0 &&
          sectionVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (regSSN.test(sectionVal.Answer) === TextProps.VALUE_FALSE) {
            sectionVal.error = TextProps.VALUE_TRUE;
          } else {
            sectionVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyContactInfoValidations(dataSections) {
  const resZipCode = dataSections.find((obj) => obj.name === "address").Answer
    .zipCode;
  const masResZipCode = ZIPCODE.find((obj) => obj.ZIPCODE === resZipCode);

  if (dataSections !== undefined) {
    if (
      dataSections[0].Answer ===
        recertificationPageLabels(language, null, languageConstants.aboutMe)
          .optionYesNo[0] &&
      dataSections[1].Answer.addressLine1 === "" &&
      dataSections[1].Answer.city === "" &&
      dataSections[1].Answer.zipCode === ""
    ) {
      dataSections[2].Answer = TextProps.VALUE_STR_FALSE;
      dataSections[2].value = TextProps.VALUE_STR_FALSE;
      dataSections[2].targetValue = TextProps.VALUE_STR_FALSE;
    }
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "radio" && k === 0) {
        if (
          dataSections[k].Answer ===
          recertificationPageLabels(language, null, languageConstants.aboutMe)
            .optionYesNo[0]
        )
          dataSections[1].isRequired = TextProps.VALUE_FALSE;
        else dataSections[1].isRequired = TextProps.VALUE_TRUE;
      }
      if (
        k === 1 &&
        (dataSections[k].Answer.addressLine1 !== "" ||
          dataSections[k].Answer.zipCode !== "" ||
          dataSections[k].Answer.city !== "")
      ) {
        dataSections[k].isRequired = TextProps.VALUE_TRUE;
      }
      // Validation for mailing Address
      if (dataSections[k].type === "radio" && k === 2) {
        var mailingAddressObj = {};
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        if (dataSections[k].targetValue === TextProps.VALUE_STR_FALSE) {
          mailingAddressObj =
            dataSections[k].conditionals[0].conditionalQuestions[0];
          const mailingZipCode = mailingAddressObj.targetValue.zipCode;
          const masMailingZipCode = ZIPCODE.find(
            (obj) => obj.ZIPCODE === mailingZipCode
          );

          if (
            mailingAddressObj.Answer === "" ||
            mailingAddressObj.Answer.street ===
              "" /* && mailingAddressObj.isRequired === TextProps.VALUE_TRUE */
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
          } else if (
            mailingAddressObj.addressLine1 === "" ||
            mailingAddressObj.Answer.addressLine1 === ""
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
          } else if (
            mailingAddressObj.Answer.zipCode === "" ||
            mailingAddressObj.Answer.zipCode.length < 5
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.zipCodeErrmsg;
          }
          //  else if (!masMailingZipCode) {
          //   mailingAddressObj.error = TextProps.VALUE_TRUE;
          //   mailingAddressObj.errorMessage = mailingAddressObj.masZipCodeErrmsg;
          // }
           else if (mailingAddressObj.Answer.city === "") {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.citiErrmsg;
          }else if (mailingAddressObj.Answer.county === "" || !mailingAddressObj.Answer.county) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.countyErrmsg;
          }else {
            mailingAddressObj.error = TextProps.VALUE_FALSE;
          }
        }
      }

      if (
        (dataSections[k].type === "radio" && k !== 2) ||
        (dataSections[k].type === "text" &&
          dataSections[k].name === "emailAddress" &&
          dataSections[k].Answer !== undefined)
      ) {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputText") {
        if (
          (dataSections[k].Answer.length > 0 ||
            dataSections[k].Answer !== "") &&
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          var emailRegEx =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          if (
            emailRegEx.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputPhone") {
        if (
          (dataSections[k].Answer.length > 0 ||
            dataSections[k].Answer !== "") &&
          !isNaN(dataSections[k].Answer) &&
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          if (phoneno.test(dataSections[k].Answer) === TextProps.VALUE_FALSE) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "address") {
        // The below condition is when user is household homeless
        if (
          !(
            dataSections[0].Answer ===
              recertificationPageLabels(
                language,
                null,
                languageConstants.contactInfo
              ).houseLessOptions[0] &&
            dataSections[2].Answer === TextProps.VALUE_STR_FALSE
          )
        ) {
          if (
            dataSections[k].Answer === "" ||
            dataSections[k].Answer.street === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            dataSections[k].addressLine1 === "" ||
            dataSections[k].Answer.addressLine1 === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            dataSections[k].Answer.zipCode === "" ||
            dataSections[k].Answer.zipCode.length < 5
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].zipCodeErrmsg;
          } else if (!masResZipCode) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].masZipCodeErrmsg;
          } else if (dataSections[k].Answer.city === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          } else if (dataSections[k].Answer.county === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].countyErrmsg;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMediacalAddressValidations(dataSections) {
  for (let sectionVal of dataSections) {
    if (sectionVal.type === "address") {
      sectionVal.error = TextProps.VALUE_TRUE;
      if (
        sectionVal.Answer === "" ||
        sectionVal.Answer.addressLine1 === "" ||
        sectionVal.Answer.addressLine1 === undefined ||
        sectionVal.Answer.state === "" ||
        sectionVal.Answer.state === undefined
      ) {
        sectionVal.error = TextProps.VALUE_TRUE;
        sectionVal.errorMessage = sectionVal.addressErrmsg;
      } else if (
        sectionVal.Answer.zipCode === "" ||
        sectionVal.Answer.zipCode === undefined ||
        sectionVal.Answer.zipCode.length < 5
      ) {
        sectionVal.error = TextProps.VALUE_TRUE;
        sectionVal.errorMessage = sectionVal.zipCodeErrmsg;
      } else if (
        sectionVal.Answer.city === "" ||
        sectionVal.Answer.city === undefined
      ) {
        var alphaRex = /^[A-Za-z' ]+$/;
        if (alphaRex.test(sectionVal.Answer.city) === TextProps.VALUE_FALSE) {
          sectionVal.error = TextProps.VALUE_TRUE;
          sectionVal.errorMessage = sectionVal.citiErrmsg;
        }
      } else {
        sectionVal.error = TextProps.VALUE_FALSE;
        sectionVal.errorMessage = "";
      }
    }
    if (
      sectionVal.Answer.amount !== "" &&
      sectionVal.Answer.amount !== undefined
    ) {
      var rex = /^[0-9]+$/;
      let errorFlag = TextProps.VALUE_FALSE;
      if (rex.test(sectionVal.Answer.amount) === TextProps.VALUE_FALSE) {
        sectionVal.error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (sectionVal.Answer.amount.length > 6) {
        sectionVal.error = TextProps.VALUE_TRUE;
        sectionVal.errorMessage = sectionVal.errorMessageLength;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        sectionVal.error = TextProps.VALUE_FALSE;
      }
    }
  }
}

function buildApplyEmergencyValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionVal of dataSections) {
      if (sectionVal.type === "radio") {
        if (
          sectionVal.Answer === "" &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMoreInfoValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionVal of dataSections) {
      if (sectionVal.type === "radio") {
        if (
          sectionVal.Answer === "" &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionVal.type === "select") {
        if (
          sectionVal.Answer.length < 1 &&
          sectionVal.Answer !== null &&
          sectionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionVal.error = TextProps.VALUE_TRUE;
        } else {
          sectionVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildHouseholdInformationValidations(dataSections) {
  let isHouseholdInformationValidated = false;
  if (dataSections !== undefined) {
    dataSections.map((section) => {
        const questionsObj = section.conditionals[0].conditionalQuestions[0];
        const radioQuestionObj = section.Answer;
        if (
          radioQuestionObj ===
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdInformation
          ).options[0]
        ) {
          if (
            questionsObj.Answer == undefined ||
            questionsObj.Answer === "" ||
            questionsObj.Answer.length < 1
          ) {
            questionsObj.error = TextProps.VALUE_TRUE;
            questionsObj.isRequired = TextProps.VALUE_TRUE;
            isHouseholdInformationValidated = TextProps.VALUE_TRUE;
          } else {
            questionsObj.error = TextProps.VALUE_FALSE;
            questionsObj.isRequired = TextProps.VALUE_FALSE;
            // isHouseholdInformationValidated = TextProps.VALUE_FALSE;
          }
        }
      });
  }
  return isHouseholdInformationValidated;
}
function buildResourcesValidations(dataSections) {
  if (dataSections !== undefined) {
    const questionsObj = dataSections[0];
    if (
      questionsObj.Answer == undefined ||
      questionsObj.Answer === "" ||
      questionsObj.Answer.length < 1
    ) {
      questionsObj.error = TextProps.VALUE_TRUE;
      return TextProps.VALUE_TRUE;
    } else {
      questionsObj.error = TextProps.VALUE_FALSE;
      return TextProps.VALUE_FALSE;
    }
  }
}
function buildResourcesValidationsSubsection(dataSections) {
  if (dataSections !== undefined) {
    // To filter only required fields
  const resourcesRequired = dataSections.map((obj) => obj.Answer);
  const resources = [];
  const resourcesRequiredNew = resources.concat(...resourcesRequired);
  let validationStaus;
  dataSections.map((data) => {
  if(data.templateType === "ResourcesTemplateOne"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => obj.amount === ""
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateOneResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateOneResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }else if(data.templateType === "ResourcesTemplateTwo"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => (obj.amount === "" || obj.bankName === "" || obj.jointAccount === "" || (obj.jointAccount === "Yes" && obj.jointAccountHolder === "" ) )
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateTwoResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateTwoResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }else if(data.templateType === "ResourcesTemplateThree"){
    let resourcesColumn = resourcesRequiredNew.find(
      (obj) => (obj.amount === "" || obj.bankName === "" )
    );
    if (resourcesColumn !== undefined) {
        let copyObj = validateTemplateThreeResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_TRUE;
        return TextProps.VALUE_TRUE;
    } else {
        let copyObj = validateTemplateThreeResources(data);
        data.Answer = copyObj;
        data.targetValue = copyObj;
        validationStaus = TextProps.VALUE_FALSE;
        return TextProps.VALUE_FALSE;
    }
  }
  });
  return validationStaus;
  }
}
function validateTemplateOneResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
      ans.error = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.required = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.errorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
  });
  return copyObj;
}
function validateTemplateTwoResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
      ans.amountError = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.amountRequired = ans.amount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.amountErrorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
      ans.bankNameError = ans.bankName === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.bankNameRequired = ans.bankName === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.bankNameErrorMessage = ans.bankName === "" ? obj.repeatedQuestion.bankErrMsg : "";
      ans.jointAccountError = ans.jointAccount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.jointAccountRequired = ans.jointAccount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.jointAccountErrorMessage = ans.jointAccount === "" ? obj.repeatedQuestion.jointAccounterrMsg : "";
      ans.jointAccountHoldertError = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
      ans.jointAccountHolderRequired = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
      ans.jointAccountHolderErrorMessage = ans.jointAccount === "Yes" && ans.jointAccountHolder === "" ? obj.repeatedQuestion.jointAccountHolderErrMsg : "";
  });
  return copyObj;
}
function validateTemplateThreeResources(obj) {
  let copyObj = _.cloneDeep(obj.Answer);
  copyObj.map(ans => {
    ans.amountError = ans.amount === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
    ans.amountRequired = ans.amount === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
    ans.amountErrorMessage = ans.amount === "" ? obj.repeatedQuestion.errorMessage : "";
    ans.bankNameError = ans.bankName === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE;
    ans.bankNameRequired = ans.bankName === "" ? TextProps.VALUE_TRUE: TextProps.VALUE_FALSE;
    ans.bankNameErrorMessage = ans.bankName === "" ? obj.repeatedQuestion.bankBrokageErrMsg : "";
  });
  return copyObj;
}
export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions
) {
  let selectedQuestions = [];
  for (let answerVal of activePageAnswers) {
    for (let questionVal of nextPageQuestions) {
      if (answerVal === questionVal.valueToMatch) {
        selectedQuestions.push(questionVal);
      }
    }
  }
  return selectedQuestions;
}
export function ImmediateNeeds() {
  this.needTypeCode = "";
  this.answer = "";
}
function createAddressObj(addressObj, addrType) {
  return {
    line1: addressObj.addressLine1 ? addressObj.addressLine1 : "",
    line2: addressObj.addressLine2 ? addressObj.addressLine2 : "",
    city: addressObj.city ? addressObj.city : "",
    state: addressObj.state ? addressObj.state : "",
    zipCode: addressObj.zipCode ? addressObj.zipCode : "",
    county: addressObj.county ? addressObj.county : "",
    verified: addressObj.verified ? addressObj.verified : TextProps.VALUE_N,
    countyCode: addressObj.countyCode ? addressObj.countyCode : "",
    type: addrType
  };
}
export function addressValidationObj(questions) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0].Answer;
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0].Answer;
  let addresses = [];
  if (residentialAddress) {
    addresses.push(createAddressObj(residentialAddress, TextProps.VALUE_RES));
  }
  if (mailingAddress && mailingAddress.state) {
    addresses.push(createAddressObj(mailingAddress, TextProps.VALUE_MAIL));
  }
  return addresses;
}
function mapAddress(enteredAddrs, selectedAddrs) {
  let result = _.isEqual(enteredAddrs, selectedAddrs);
  let zip = selectedAddrs.zip ? selectedAddrs.zip : selectedAddrs.zipCode;
  if (!result) {
    enteredAddrs.addressLine1 = selectedAddrs.line1;
    enteredAddrs.addressLine2 = selectedAddrs.line2 ? selectedAddrs.line2 : "";
    enteredAddrs.city = selectedAddrs.city;
    enteredAddrs.county = selectedAddrs.county
      ? selectedAddrs.county
      : selectedAddrs.county;
    enteredAddrs.countyCode = selectedAddrs.countyCode ? selectedAddrs.countyCode : "" ;
    enteredAddrs.state = selectedAddrs.state;
    enteredAddrs.verified = selectedAddrs.verified;
    enteredAddrs.zipCode = zip;
  }
  return enteredAddrs;
}
export function appendUpdatedAdrress(
  questions,
  selectedResAddress,
  selectedMailingAddress
) {
  let residentialAddress = questions.filter(
    (addr) => addr.name === "address"
  )[0];
  let mailingAddress = questions.filter(
    (addr) => addr.name === "is-also-mailing"
  )[0].conditionals[0].conditionalQuestions[0];
  if (residentialAddress.Answer) {
    residentialAddress.Answer = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
    residentialAddress.targetValue = mapAddress(
      residentialAddress.Answer,
      selectedResAddress
    );
  }
  if (mailingAddress.Answer && mailingAddress.Answer.state) {
    mailingAddress.Answer = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
    mailingAddress.targetValue = mapAddress(
      mailingAddress.Answer,
      selectedMailingAddress
    );
  }
  return questions;
}

export function setContactInitData(responseObject,contactInfoChangedCopy){
  let addressArray = [];
  let { additionalProperties } = responseObject;

  responseObject.clientAddress.map((address) => {
    addressArray.push({
      typeCode: address.typeCode,
      line1: address.line1,
      line2:
        address.line2 && address.line2 !== undefined ? address.line2 : "",
      city: address.city,
      state: address.state,
      zip: address.zip,
      resAddrMatchFlag: address.resAddrMatchFlag,
      homelessFlag: address.homelessFlag,
    });
    return addressArray;
  });

  let initialObj = {
    email: responseObject.clientEmail.emailAddress
      ? responseObject.clientEmail.emailAddress
      : null,
    phoneNumber: responseObject.clientPhone.phoneNumber
      ? responseObject.clientPhone.phoneNumber
      : null,
    emailOptIn: responseObject.clientEmail.optInFlag,
    textOptIn: responseObject.clientPhone.textOptInFlag,
    editPhone: TextProps.VALUE_FALSE,
    editEmail: TextProps.VALUE_FALSE,
    editAddress: TextProps.VALUE_FALSE,
    sameMailingAddress:
      responseObject.clientAddress.resAddrMatchFlag,
    contactInfoChanged: contactInfoChangedCopy,
    addressList: {
      homeless:
        responseObject.clientAddress[0].homelessFlag,
      address: addressArray,
    },
    changeFlags: {
      emailCode:
        additionalProperties &&
        additionalProperties.emailCode &&
        additionalProperties.emailCode
          ? additionalProperties.emailCode
          : null,
      phoneCode:
        additionalProperties &&
        additionalProperties.phoneCode &&
        additionalProperties.phoneCode
          ? additionalProperties.phoneCode
          : null,
      mailingAddressCode:
        additionalProperties &&
        additionalProperties.mailingAddressCode &&
        additionalProperties.mailingAddressCode
          ? additionalProperties.mailingAddressCode
          : null,
      resAddressCode:
        additionalProperties &&
        additionalProperties.resAddressCode &&
        additionalProperties.resAddressCode
          ? additionalProperties.resAddressCode
          : null,
      homelssFlagCode:
        additionalProperties &&
        additionalProperties.homelssFlagCode &&
        additionalProperties.homelssFlagCode
          ? additionalProperties.homelssFlagCode
          : null,
    },
  };
  return initialObj
}
export function setAddressValidatedToContactInfoHelper(dataSections,contactInfoScreen){
  let contactInfoObj = contactInfoScreen;
  contactInfoObj.melisaAddressValidated = TextProps.VALUE_TRUE;
  contactInfoObj.addressList.homeless =
    dataSections.questions[0].Answer ===
    recertificationPageLabels(language, null, languageConstants.aboutMe)
      .optionYesNo[0]
      ? TextProps.VALUE_TRUE
      : TextProps.VALUE_FALSE;
  let street = dataSections.questions[1].Answer.street
    ? dataSections.questions[1].Answer.street
    : dataSections.questions[1].Answer.addressLine1;
  let line2 = dataSections.questions[1].Answer.addressLine2
    ? dataSections.questions[1].Answer.addressLine2
    : "";
  contactInfoObj.addressList.address[0].line1 =
    dataSections.questions[1].Answer.addressLine1;
  contactInfoObj.addressList.address[0].line2 = line2;
  contactInfoObj.addressList.address[0].city =
    dataSections.questions[1].Answer.city;
  contactInfoObj.addressList.address[0].state =
    street &&
    dataSections.questions[1].Answer.city &&
    dataSections.questions[1].Answer.zipCode
      ? dataSections.questions[1].Answer.state
      : "";
  contactInfoObj.addressList.address[0].zip =
    dataSections.questions[1].Answer.zipCode;
  contactInfoObj.addressList.address[0].county =
    dataSections.questions[1].Answer.county;
  contactInfoObj.addressList.address[0].countyCode =
    dataSections.questions[1].Answer.countyCode;
  contactInfoObj.addressList.address[0].verified =
    dataSections.questions[1].Answer.verified;
  contactInfoObj.addressList.address[0].typeCode = "RES";
  contactInfoObj.addressList.address[0].resAddrMatchFlag = TextProps.VALUE_Y;

  contactInfoObj.sameMailingAddress = TextProps.VALUE_TRUE;
  if (dataSections.questions[2].Answer === TextProps.VALUE_STR_FALSE) {
    contactInfoObj.sameMailingAddress = TextProps.VALUE_FALSE;
    if (
      contactInfoScreen.addressList.address.length === 1
    ) {
      let addressMailing = {};
      let street = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.street
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.street
        : dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.addressLine1;
      let line2 = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.addressLine2
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.addressLine2
        : "";

      addressMailing.line1 =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.addressLine1;
      addressMailing.line2 = line2;
      addressMailing.city = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.city
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.city
        : "";
      addressMailing.state = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.state
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.state
        : "";
      addressMailing.zip = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.zipCode
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.zipCode
        : "";
      addressMailing.county =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.county;
      addressMailing.countyCode =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.countyCode;
      addressMailing.verified =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.verified;
      addressMailing.resAddrMatchFlag = TextProps.VALUE_FALSE;
      addressMailing.typeCode = "MAIL";
      contactInfoObj.addressList.address.push(addressMailing);
      contactInfoObj.addressList.address[0].resAddrMatchFlag =
        TextProps.VALUE_FALSE;
    } else {
      let street = dataSections.questions[2].conditionals[0]
        .conditionalQuestions[0].Answer.street
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.street
        : dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.addressLine1;
      let line2 = contactInfoObj.addressList.address[1].addressLine2
        ? contactInfoObj.addressList.address[1].addressLine2
        : "";
      let line02 = contactInfoObj.addressList.address[0].addressLine2
        ? contactInfoObj.addressList.address[0].Answer.addressLine2
        : "";
      contactInfoObj.addressList.address[1].line1 =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.addressLine1;
      contactInfoObj.addressList.address[1].city = dataSections.questions[2]
        .conditionals[0].conditionalQuestions[0].Answer.city
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.city
        : "";
      contactInfoObj.addressList.address[1].state = dataSections.questions[2]
        .conditionals[0].conditionalQuestions[0].Answer.state
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.state
        : "";
      contactInfoObj.addressList.address[1].zip = dataSections.questions[2]
        .conditionals[0].conditionalQuestions[0].Answer.zipCode
        ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
            .Answer.zipCode
        : "";
      contactInfoObj.addressList.address[1].county =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.county;
      contactInfoObj.addressList.address[1].countyCode =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.countyCode;
      contactInfoObj.addressList.address[1].verified =
        dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.verified;
      contactInfoObj.addressList.address[1].resAddrMatchFlag =
        TextProps.VALUE_FALSE;
      contactInfoObj.addressList.address[1].line2 = line2;
      contactInfoObj.addressList.address[1].typeCode = "MAIL";
      contactInfoObj.addressList.address[0].resAddrMatchFlag =
        TextProps.VALUE_FALSE;
      contactInfoObj.addressList.address[0].line2 = line02;
    }
  } else if (dataSections.questions[2].Answer === TextProps.VALUE_STR_TRUE) {
    contactInfoObj.addressList.address[0].resAddrMatchFlag =
      TextProps.VALUE_TRUE;
    contactInfoObj.addressList.address.splice(1, 1);
  } else {
    contactInfoObj.addressList.address[0].resAddrMatchFlag =
      TextProps.VALUE_FALSE;
  }
  return contactInfoObj
}
export function filterData (section, id) {
  return section.filter((sec) => sec.id === id)[0];
};
export function incomeSubsectionRenderHelper(memberTemplate,responseObject,sections,incomeSummaryTemplateOrg,recert_type){
  let answer = [];
  responseObject.earnedIncome.map((income) => {
    let usersDetails = [income["firstName"],income["lastName"],formatters.formatDate(income["dateOfBirth"])].join(" ").replace(/ +(?= )/g, "");
    answer.push(usersDetails);
  });
  let existingtypes = ["WAGES", "SELEMP", "BOARD"];
  let incomeMemberMultipleTemplate = [];
  let incomeMeTemplate = _.cloneDeep(IncomeSectionTemplate);
  if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
  if (
    incomeMeTemplate &&
    incomeMeTemplate.subsections === undefined &&
    answer.length > 0
  ) {
    incomeMeTemplate.subsections = IncomeSectionTemplate.subsections;
  }
  let incomeSummaryTemplate = incomeSummaryTemplateOrg;
  let incomeCopy = filterData(
    sections,
    "income-summary"
  );
  let retainData = filterData(
    sections,
    "income-summary"
  ).retainData;
  if (answer[0] !== "") {
    let prevClient = "";
    let prevClientIndex = 0;
    for (let i = 0; i < answer.length; i++) {
      if (prevClient !== answer[i]) {
        prevClient = answer[i];
        if (i > 0) {
          prevClientIndex++;
        }
        incomeMeTemplate.id = "combined-income-" + prevClientIndex;
        incomeMeTemplate.header = interimReportPageLabels(
          language,
          languageConstants.earnedIncomeDynamic,
          answer[i],
          null,
          null
        ).heading;
        incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
          language,
          languageConstants.earnedIncomeDynamic,
          answer[i],
          null,
          null
        ).mainQuestion;
        incomeMeTemplate.subsections[0].id =
          "combined-income-subsection-" + prevClientIndex;
        incomeMeTemplate.subsections[0].header = interimReportPageLabels(
          language,
          languageConstants.earnedIncomeDynamic,
          answer[i],
          null,
          null
        ).heading;
        if(recert_type === "REEVAL"){
          incomeMeTemplate.header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).headingTafdc
          incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainTanfdcQuestion;
          incomeMeTemplate.questions[0].options = incomeMeTemplate.questions[0].options.filter(item=> item.optionValue != "BOARD");
          incomeMeTemplate.subsections[0].header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).headingTafdc;
        }
        incomeMeTemplate.title = answer[i];
        incomeMeTemplate.subsections[0].title = answer[i];
        incomeMeTemplate.clientId = answer[i];
        if (retainData === TextProps.VALUE_TRUE || retainData === null) {
          if (
            existingtypes.indexOf(
              responseObject.earnedIncome[i].typeCode
            ) >= 0
          ) {
            incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
              responseObject.earnedIncome[i].typeCode,
            ];
            incomeMeTemplate.questions[0].Answer = [
              responseObject.earnedIncome[i].typeCode,
            ];
          } else {
            let optionsObj = {};
            optionsObj.optionValue =
              responseObject.earnedIncome[i].typeCode;
            optionsObj.optionDisplay =
              earnedIncomeCodeMapper(language)[
                responseObject.earnedIncome[i].typeCode
              ];
            incomeMeTemplate.questions[0][OptionsProps.OPTIONS].push(
              optionsObj
            );
            incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
              responseObject.earnedIncome[i].typeCode,
            ];
            incomeMeTemplate.questions[0].Answer = [
              responseObject.earnedIncome[i].typeCode,
            ];
            existingtypes.push(
              responseObject.earnedIncome[i].typeCode
            );
            let newType = {
              [Props.NAME]:
                responseObject.earnedIncome[i].typeCode,
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                responseObject.earnedIncome[i].typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                earnedIncomeCodeMapper(language)[
                  responseObject.earnedIncome[i].typeCode
                ],
              [Props.TYPE]: types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]:
                responseObject.earnedIncome[i].typeCode,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "",
                  [Props.LABEL_OBJ_AFTER]:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  " " +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                employerLabel:
                  "" +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                unitLabel:
                  " " +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                [Props.TYPE]: types.EARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions,
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherLabel +
                earnedIncomeCodeMapper(language)[
                  responseObject.earnedIncome[i].typeCode
                ], //'Add another SSI'
            };
            incomeMeTemplate.subsections[0].questions.push(newType);
          }
        } else if (
          retainData === TextProps.VALUE_FALSE ||
          retainData === null
        ) {
          if (
            incomeCopy.subsections[i] !== undefined &&
            incomeCopy.subsections[i].questions !== undefined &&
            incomeMeTemplate !== undefined
          ) {
            incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
              incomeCopy.subsections[i].questions[0].targetValue;
            incomeMeTemplate.questions[0].Answer =
              incomeCopy.subsections[i].questions[0].Answer;
          }
        }
        var incomeReturn = interimReportPageLabels(
          language,
          languageConstants.earnedIncomeDynamic,
          answer[i],
          null,
          null
        );
        let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
          answer[i],
          earnedIncomeCodeMapper(language)[
            responseObject.earnedIncome[i].typeCode
          ],
          language
        );
        incomeReturn.subQuestions.push(dynamicQuestionLabels);
        for (
          let j = 0;
          j < incomeMeTemplate.subsections[0].questions.length;
          j++
        ) {
          incomeMeTemplate.subsections[0].questions[j].amountLabel =
            incomeReturn.subQuestions[j].amountLabel;
          incomeMeTemplate.subsections[0].questions[j].employerLabel =
            incomeReturn.subQuestions[j].employerLabel;
          incomeMeTemplate.subsections[0].questions[j].unitLabel =
            incomeReturn.subQuestions[j].unitLabel;
          incomeMeTemplate.subsections[0].questions[
            j
          ].repeatedQuestion.amountLabel =
            incomeReturn.subQuestions[j].amountLabel;
          incomeMeTemplate.subsections[0].questions[
            j
          ].repeatedQuestion.employerLabel =
            incomeReturn.subQuestions[j].employerLabel;
          incomeMeTemplate.subsections[0].questions[
            j
          ].repeatedQuestion.unitLabel =
            incomeReturn.subQuestions[j].unitLabel;
          incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
            TextProps.VALUE_TRUE;
          let typeText =
            earnedIncomeCodeMapper(language)[
              incomeMeTemplate.subsections[0].questions[j].valueToMatch
            ];
          if (retainData === TextProps.VALUE_TRUE || retainData === null) {
            if (
              incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
              responseObject.earnedIncome[i].typeCode
            ) {
              let amountObj = [];
              if (
                responseObject.earnedIncome[i].amounts !==
                undefined
              ) {
                responseObject.earnedIncome[i].amounts.map(
                  (amount, index) => {
                    amountObj.push(amount.amount);
                  }
                );
              }
              incomeMeTemplate.subsections[0].questions[j].Answer = [
                {
                  fullIncomeObj:
                    responseObject.earnedIncome[i],
                  originalTypeCode:
                    responseObject.earnedIncome[i].typeCode,
                  // code: "existing",
                  code: responseObject.earnedIncome[i]
                    .dataChangeCode,
                  amount: amountObj,
                  unit: getFrequency(language)[
                    responseObject.earnedIncome[i]
                      .frequencyCode
                  ],
                  employer:
                    responseObject.earnedIncome[i]
                      .employerName,
                  employerLabel:
                    typeText +
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).fromMultiLang +
                    responseObject.earnedIncome[i]
                      .employerName,
                  id: "repeatable_id_" + _.uniqueId(),
                },
              ];
              incomeMeTemplate.subsections[0].questions[j][
                OptionsProps.TARGET_VALUE
              ] = [
                {
                  fullIncomeObj:
                    responseObject.earnedIncome[i],
                  originalTypeCode:
                    responseObject.earnedIncome[i].typeCode,
                  // code: "existing",
                  code: responseObject.earnedIncome[i]
                    .dataChangeCode,
                  amount: amountObj,
                  unit: getFrequency(language)[
                    responseObject.earnedIncome[i]
                      .frequencyCode
                  ],
                  employer:
                    responseObject.earnedIncome[i]
                      .employerName,
                  employerLabel:
                    typeText +
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).fromMultiLang +
                    responseObject.earnedIncome[i]
                      .employerName,
                  id: "repeatable_id_" + _.uniqueId(),
                },
              ];
              incomeMeTemplate.subsections[0].questions[j].value = [
                {
                  fullIncomeObj:
                    responseObject.earnedIncome[i],
                  originalTypeCode:
                    responseObject.earnedIncome[i].typeCode,
                  // code: "existing",
                  code: responseObject.earnedIncome[i]
                    .dataChangeCode,
                  amount: amountObj,
                  unit: getFrequency(language)[
                    responseObject.earnedIncome[i]
                      .frequencyCode
                  ],
                  employer:
                    responseObject.earnedIncome[i]
                      .employerName,
                  employerLabel:
                    typeText +
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).fromMultiLang +
                    responseObject.earnedIncome[i]
                      .employerName,
                  id: "repeatable_id_" + _.uniqueId(),
                },
              ];
              incomeMeTemplate.subsections[0].questions[j].completed =
                TextProps.VALUE_FALSE;
            } else {
              incomeMeTemplate.subsections[0].questions[j].Answer = "";
              incomeMeTemplate.subsections[0].questions[j][
                OptionsProps.TARGET_VALUE
              ] = "";
              incomeMeTemplate.subsections[0].questions[j].completed =
                TextProps.VALUE_FALSE;
            }
          } else if (
            retainData === TextProps.VALUE_FALSE ||
            retainData === null
          ) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.subsections[0].questions[j].Answer =
                incomeCopy.subsections[i].subsections[j].questions[j].Answer;
              incomeMeTemplate.subsections[0].questions[j][
                OptionsProps.TARGET_VALUE
              ] =
                incomeCopy.subsections[i].subsections[j].questions[
                  j
                ].targetValue;
              incomeMeTemplate.subsections[0].questions[j].completed =
                TextProps.VALUE_FALSE;
            }
          }
        }
        incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
      } // if prevClient check ends
      else {
        if (retainData === TextProps.VALUE_TRUE || retainData === null) {
          if (
            existingtypes.indexOf(
              responseObject.earnedIncome[i].typeCode
            ) >= 0
          ) {
            if (
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.TARGET_VALUE
              ].indexOf(
                responseObject.earnedIncome[i].typeCode
              ) < 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].length > 0
              ) {
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].push(
                  responseObject.earnedIncome[i].typeCode
                );
                incomeMemberMultipleTemplate[
                  prevClientIndex
                ].questions[0].Answer.push(
                  responseObject.earnedIncome[i].typeCode
                );
              } else {
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ] = [
                  responseObject.earnedIncome[i].typeCode,
                ];
                incomeMemberMultipleTemplate[
                  prevClientIndex
                ].questions[0].Answer = [
                  responseObject.earnedIncome[i].typeCode,
                ];
              }
            }
          } else {
            let optObj = {};
            optObj.optionValue =
              responseObject.earnedIncome[i].typeCode;
            optObj.optionDisplay =
              earnedIncomeCodeMapper(language)[
                responseObject.earnedIncome[i].typeCode
              ];
            incomeMemberMultipleTemplate[prevClientIndex].questions[0][
              OptionsProps.OPTIONS
            ].push(optObj);
            incomeMemberMultipleTemplate[prevClientIndex].questions[0][
              OptionsProps.TARGET_VALUE
            ].push(responseObject.earnedIncome[i].typeCode);
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].questions[0].Answer.push(
              responseObject.earnedIncome[i].typeCode
            );
            existingtypes.push(
              responseObject.earnedIncome[i].typeCode
            );
            let newType = {
              [Props.NAME]:
                responseObject.earnedIncome[i].typeCode,
              [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                responseObject.earnedIncome[i].typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
              [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                earnedIncomeCodeMapper(language)[
                  responseObject.earnedIncome[i].typeCode
                ],
              [Props.TYPE]: types.REPEATABLE_QUESTION,
              [OptionsProps.TABLE_DSC]:
                responseObject.earnedIncome[i].typeCode,
              [ControlConstants.REPEATABLE_QUESTION]: {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "",
                  [Props.LABEL_OBJ_AFTER]:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).grossIncomeHelp,
                  // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                },
                amountLabel:
                  " " +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                employerLabel:
                  "" +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                unitLabel:
                  " " +
                  earnedIncomeCodeMapper(language)[
                    responseObject.earnedIncome[i].typeCode
                  ],
                [Props.TYPE]: types.EARNED_INCOME,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).costBetween,
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).unitsOptions,
                [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              },
              [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).anotherLabel +
                earnedIncomeCodeMapper(language)[
                  responseObject.earnedIncome[i].typeCode
                ], //'Add another SSI'
            };
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions.push(newType);
          }
          let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
            answer[i],
            earnedIncomeCodeMapper(language)[
              responseObject.earnedIncome[i].typeCode
            ],
            language
          );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);
          for (
            let j = 0;
            j <
            incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
              .questions.length;
            j++
          ) {
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].repeatedQuestion.employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
            let typeText =
              earnedIncomeCodeMapper(language)[
                incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                  .questions[j].valueToMatch
              ];

            if (retainData === TextProps.VALUE_TRUE || retainData === null) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                  .questions[j].valueToMatch ===
                responseObject.earnedIncome[i].typeCode
              ) {
                let amountObj = [];
                if (
                  responseObject.earnedIncome[i].amounts !==
                  undefined
                ) {
                  responseObject.earnedIncome[
                    i
                  ].amounts.map((amount, index) => {
                    amountObj.push(amount.amount);
                  });
                }
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].Answer !== undefined &&
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].Answer.length > 0
                ) {
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].Answer.push({
                    fullIncomeObj:
                      responseObject.earnedIncome[i],
                    originalTypeCode:
                      responseObject.earnedIncome[i]
                        .typeCode,
                    // code: "existing",
                    code: responseObject.earnedIncome[i]
                      .dataChangeCode,
                    amount: amountObj,
                    unit: getFrequency(language)[
                      responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + _.uniqueId(),
                  });
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ].push({
                    fullIncomeObj:
                      responseObject.earnedIncome[i],
                    originalTypeCode:
                      responseObject.earnedIncome[i]
                        .typeCode,
                    // code: "existing",
                    code: responseObject.earnedIncome[i]
                      .dataChangeCode,
                    amount: amountObj,
                    unit: getFrequency(language)[
                      responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + _.uniqueId(),
                  });
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].value.push({
                    fullIncomeObj:
                      responseObject.earnedIncome[i],
                    originalTypeCode:
                      responseObject.earnedIncome[i]
                        .typeCode,
                    // code: "existing",
                    code: responseObject.earnedIncome[i]
                      .dataChangeCode,
                    amount: amountObj,
                    unit: getFrequency(language)[
                      responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + _.uniqueId(),
                  });
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                } else {
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].Answer = [
                    {
                      fullIncomeObj:
                        responseObject.earnedIncome[i],
                      originalTypeCode:
                        responseObject.earnedIncome[i]
                          .typeCode,
                      // code: "existing",
                      code: responseObject.earnedIncome[i]
                        .dataChangeCode,
                      amount: amountObj,
                      unit: getFrequency(language)[
                        responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + _.uniqueId(),
                    },
                  ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] = [
                    {
                      fullIncomeObj:
                        responseObject.earnedIncome[i],
                      originalTypeCode:
                        responseObject.earnedIncome[i]
                          .typeCode,
                      // code: "existing",
                      code: responseObject.earnedIncome[i]
                        .dataChangeCode,
                      amount: amountObj,
                      unit: getFrequency(language)[
                        responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + _.uniqueId(),
                    },
                  ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].value = [
                    {
                      fullIncomeObj:
                        responseObject.earnedIncome[i],
                      originalTypeCode:
                        responseObject.earnedIncome[i]
                          .typeCode,
                      // code: "existing",
                      code: responseObject.earnedIncome[i]
                        .dataChangeCode,
                      amount: amountObj,
                      unit: getFrequency(language)[
                        responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + _.uniqueId(),
                    },
                  ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                }
              }
            }
          }
        } //If income populated check ends
      }
    }
  }

  if (incomeSummaryTemplate)
    incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
  incomeCopy.subsections = [];
  incomeCopy.subsections = incomeMemberMultipleTemplate;
  return incomeCopy;
}
const groupBy = keys => array =>
array && array.reduce((objectsByKeyValue, obj) => {
  const value = keys.map(key => obj[key]).join('-');
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});
export function resourcesSubsectionRenderHelper(memberTemplate,responseObject,sections,resourcesSummaryTemplateOrg){
  let answer = [];
  let existingtypes = ["WAGES", "SELEMP", "BOARD"];
  let resourcesMemberMultipleTemplate = [];
  let resourcesSummaryTemplate = resourcesSummaryTemplateOrg;
  let resourcesCopy = filterData(
    sections,
    "resources-summary"
  );
  let retainData = filterData(
    sections,
    "resources-summary"
  ).retainData;
  
  const groupByName = groupBy(['firstName', 'lastName','dateOfBirth']);
  let resourcesGroupedByName = groupByName(responseObject.resources);
  if(resourcesGroupedByName){
    // resourcesMeTemplate.subsections = ResourcesSectionTemplate.subsections;
    for(let resourcesObj in resourcesGroupedByName){
      let memberIndex = resourcesMemberMultipleTemplate.length;
      let resourcesMeTemplate = _.cloneDeep(ResourcesSectionTemplate);
      if (memberTemplate !== null) resourcesMeTemplate = memberTemplate;
      if (
        resourcesMeTemplate &&
        resourcesMeTemplate.subsections === undefined 
      ) {
        resourcesMeTemplate.subsections = ResourcesSectionTemplate.subsections;
      }
      console.log(resourcesGroupedByName[resourcesObj]);
      let resource = resourcesGroupedByName[resourcesObj][0];
      // resource["dateOfBirth"] ? formatters.formatDate(resource["dateOfBirth"]) : null,
      let name = [
        resource["firstName"],
        resource["lastName"],
        resource["dateOfBirth"] ? formatters.formatDate(resource["dateOfBirth"]) : null,
      ]
        .join(" ")
        .replace(/ +(?= )/g, "");
      resourcesMeTemplate.id = "combined-resources-" + memberIndex;
      resourcesMeTemplate.completed = TextProps.VALUE_FALSE;
      resourcesMeTemplate.header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
      resourcesMeTemplate.questions[0][Props.LABEL] = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsLabel;
      resourcesMeTemplate.subsections[0].id = "combined-resources-subsection-" + memberIndex;
      resourcesMeTemplate.subsections[0].completed = TextProps.VALUE_FALSE;
      resourcesMeTemplate.subsections[0].header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
      resourcesMeTemplate.title = name;
      resourcesMeTemplate.subsections[0].title = name;
      resourcesMeTemplate.clientId = name;
      let resourceTypeList = [];
      let resourcesReturn = resourcesSectionMultilanguageLabels(language,name);
      let resourcesMeTemplateSubsections = [];
      resourcesGroupedByName[resourcesObj].map(resources => {
        if(resources.datachangeCode !== "REMOVE"){
        resourceTypeList.push(resources.resourceType);
        }
        resourcesMeTemplate.subsections[0].questions.map((ques,index) => {
          if(ques.valueToMatch === resources.resourceType){
            let resourcesReturnObj = resourcesReturn.combineResourcesOptionsLabelsSrs[index];
            ques.repeatedQuestion.subLabel = resourcesReturnObj.line1 + name + resourcesReturnObj.line2;
            ques.repeatedQuestion.subHeader = resourcesReturn.combineResourcesoptions[index];
            ques.canBeDeleted = TextProps.VALUE_TRUE;
            let resourceDetailsArr = [];
            switch(resources.resourceType){
              case "CASH": case "TRUST": case "PPDFUN": case "OTHER":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.additionalInformation = resDet.additionalInfo;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              case "SAVING": case "CHECK":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.bankName = resDet.bankBrokerageName;
                  resourceDetailsObj.accountnumber = resDet.accountNumber;
                  resourceDetailsObj.jointAccount = resDet.isJointAccount === TextProps.VALUE_Y ? TextProps.VALUE_YES : TextProps.VALUE_NO;
                  resourceDetailsObj.jointAccountHolder = resDet.jointAcntHolderName;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              case "STOCKS": case "BONDS": case "RETMNT":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.bankName = resDet.bankBrokerageName;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              default:
              break;
            }
          }
        });
      });
      resourcesMeTemplate.questions[0].Answer = resourceTypeList;
      resourcesMeTemplate.questions[0].targetValue = resourceTypeList;
      resourcesMeTemplate.questions[0].value = resourceTypeList;
      resourcesMeTemplate.subsections[0].questions = resourcesMeTemplateSubsections;
      let resourcesMeTemplateCopy = _.cloneDeep(resourcesMeTemplate);
      resourcesMemberMultipleTemplate.push(resourcesMeTemplateCopy);
    }
  }

  if (resourcesSummaryTemplate)
  resourcesMemberMultipleTemplate.push(resourcesSummaryTemplate);
  resourcesCopy.subsections = [];
  resourcesCopy.subsections = resourcesMemberMultipleTemplate;
  return resourcesCopy;
}
export function onUnearnedIncomeSubsectionRenderHelper(memberTemplate,responseObject,sections,unearnedIncomeSummaryTemplateCopy,recert_type){
  let answer = [];
  let existingtypes = [
    "TAFDCS",
    "RSDI",
    "SSI",
    "VETBEN",
    "UNEMPL",
    "WRKCMP",
    "INTINC",
    "MILPE",
    "RETFND",
    "RRRET",
    "LIHEAP",
    "RENTAL",
    "CHSDOR",
    "ALIMNY",
    "CONTR",
    "OTHER",
  ];
  responseObject.unearnedIncome.map((income) => {
    answer.push(
      [
        income["firstName"],
        income["lastName"],
        formatters.formatDate(income["dateOfBirth"]),
      ]
        .join(" ")
        .replace(/ +(?= )/g, "")
    );
  });
  let incomeMemberMultipleTemplate = [];
  let incomeMeTemplate = unearnedIncomeSectionTemplate;
  if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
  if (
    incomeMeTemplate &&
    incomeMeTemplate.subsections === undefined &&
    answer.length > 0
  ) {
    incomeMeTemplate.subsections = unearnedIncomeSectionTemplate.subsections;
  }
  let unearnedIncomeSummaryTemplate = unearnedIncomeSummaryTemplateCopy;
  let incomeCopy = filterData(
    sections,
    "unearned-income-summary"
  );
  let retainData = filterData(
    sections,
    "unearned-income-summary"
  ).retainData;
  if (answer[0] !== "") {
    let prevClient = "";
    let prevClientIndex = 0;
    for (let i = 0; i < answer.length; i++) {
      if (prevClient !== answer[i]) {
        prevClient = answer[i];
        if (i > 0) {
          prevClientIndex++;
        }
        incomeMeTemplate.id = "unearned-combined-income-" + prevClientIndex;
        incomeMeTemplate.header = recert_type === "REEVAL" ? interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        ).tanfHeading : interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        ).heading;
        incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        ).mainQuestion;
        if(recert_type === "REEVAL"){
          incomeMeTemplate.questions[0].options = incomeMeTemplate.questions[0].options.filter(item=> !["TAFDCS","INTINC","LIHEAP"].includes(item.optionValue));
        }
        incomeMeTemplate.subsections[0].id =
          "unearned-combined-income-subsection-" + prevClientIndex;
        incomeMeTemplate.subsections[0].header = recert_type === "REEVAL" ? interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        ).tanfHeading : interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        ).heading;
        incomeMeTemplate.title = answer[i];
        incomeMeTemplate.subsections[0].title = answer[i];
        incomeMeTemplate.clientId = answer[i];
        if (retainData === TextProps.VALUE_TRUE || retainData === null) {
          if (
            existingtypes.indexOf(
              responseObject.unearnedIncome[i].typeCode
            ) >= 0
          ) {
            incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
              responseObject.unearnedIncome[i].typeCode,
            ];
            incomeMeTemplate.questions[0].Answer = [
              responseObject.unearnedIncome[i].typeCode,
            ];
          }
        } else if (retainData === TextProps.VALUE_FALSE) {
          if (
            incomeCopy.subsections[i] !== undefined &&
            incomeCopy.subsections[i].questions !== undefined &&
            incomeMeTemplate !== undefined
          ) {
            incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
              incomeCopy.subsections[i].questions[0].targetValue;
            incomeMeTemplate.questions[0].Answer =
              incomeCopy.subsections[i].questions[0].Answer;
          }
        }
        var incomeReturn = interimReportPageLabels(
          language,
          languageConstants.unearnedIncomeDynamic,
          answer[i],
          null,
          null
        );
        let dynamicQuestionLabels = unearnedIncomeDynamicMultilanguageLabels(
          answer[i],
          unearnedIncomeCodeMapper(language)[
            responseObject.unearnedIncome[i].typeCode
          ],
          language
        );
        incomeReturn.subQuestions.push(dynamicQuestionLabels);
        for (
          let j = 0;
          j < incomeMeTemplate.subsections[0].questions.length;
          j++
        ) {
          incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
            incomeReturn.subQuestions[j][Props.LABEL];
          incomeMeTemplate.subsections[0].questions[j].amountLabel =
            incomeReturn.subQuestions[j].amountLabel;
          incomeMeTemplate.subsections[0].questions[j].unitLabel =
            incomeReturn.subQuestions[j].unitLabel;
          // incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
          //   Props.LABEL
          // ] = incomeReturn.subQuestions[j][Props.LABEL];
          incomeMeTemplate.subsections[0].questions[
            j
          ].repeatedQuestion.amountLabel =
            incomeReturn.subQuestions[j].amountLabel;
          incomeMeTemplate.subsections[0].questions[
            j
          ].repeatedQuestion.unitLabel =
            incomeReturn.subQuestions[j].unitLabel;
          incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
            TextProps.VALUE_TRUE;
          if (retainData === TextProps.VALUE_TRUE || retainData === null) {
            if (
              existingtypes.indexOf(
                responseObject.unearnedIncome[i].typeCode
              ) >= 0
            ) {
              if (
                incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                responseObject.unearnedIncome[i].typeCode
              ) {
                let amountArray = [];
                if (
                  responseObject.unearnedIncome[i]
                    .amounts !== undefined
                ) {
                  responseObject.unearnedIncome[
                    i
                  ].amounts.map((amount) => {
                    amountArray.push(amount.amount);
                  });
                }
                incomeMeTemplate.subsections[0].questions[j].Answer = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].value = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              } else {
                incomeMeTemplate.subsections[0].questions[j].Answer = "";
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = "";
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            } else {
              if (
                incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                  "OTHER" &&
                responseObject.unearnedIncome[i]
                  .typeCode === "OTHER"
              ) {
                let amountArray = [];
                if (
                  responseObject.unearnedIncome[i]
                    .amounts !== undefined
                ) {
                  responseObject.unearnedIncome[
                    i
                  ].amounts.map((amount) => {
                    amountArray.push(amount.amount);
                  });
                }
                incomeMeTemplate.subsections[0].questions[j].Answer = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].value = [
                  {
                    fullIncomeObj:
                      responseObject.unearnedIncome[i],
                    originalTypeCode:
                      responseObject.unearnedIncome[i]
                        .typeCode,
                    code: responseObject.unearnedIncome[i]
                    .dataChangeCode,
                    
                    amount: amountArray,
                    unit: getFrequency(language)[
                      responseObject.unearnedIncome[i]
                        .frequencyCode
                    ],
                    id: "repeatable_id_" + _.uniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              } else {
                incomeMeTemplate.subsections[0].questions[j].Answer = "";
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = "";
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            }
          } else if (retainData === TextProps.VALUE_FALSE) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.subsections[0].questions[j].Answer =
                incomeCopy.subsections[i].subsections[j].questions[j].Answer;
              incomeMeTemplate.subsections[0].questions[j][
                OptionsProps.TARGET_VALUE
              ] =
                incomeCopy.subsections[i].subsections[j].questions[
                  j
                ].targetValue;
              incomeMeTemplate.subsections[0].questions[j].completed =
                TextProps.VALUE_FALSE;
            }
          }
        }
        incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
      } else {
        if (retainData === TextProps.VALUE_TRUE || retainData === null) {
          if (
            existingtypes.indexOf(
              responseObject.unearnedIncome[i].typeCode
            ) >= 0
          ) {
            if (
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.TARGET_VALUE
              ].indexOf(
                responseObject.unearnedIncome[i].typeCode
              ) < 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].length > 0
              ) {
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].push(
                  responseObject.unearnedIncome[i].typeCode
                );
                incomeMemberMultipleTemplate[
                  prevClientIndex
                ].questions[0].Answer.push(
                  responseObject.unearnedIncome[i].typeCode
                );
              } else {
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ] = [
                  responseObject.unearnedIncome[i].typeCode,
                ];
                incomeMemberMultipleTemplate[
                  prevClientIndex
                ].questions[0].Answer = [
                  responseObject.unearnedIncome[i].typeCode,
                ];
              }
            }
          }
          let dynamicQuestionLabels =
            unearnedIncomeDynamicMultilanguageLabels(
              answer[i],
              unearnedIncomeCodeMapper(language)[
                responseObject.unearnedIncome[i].typeCode
              ],
              language
            );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);

          for (
            let j = 0;
            j <
            incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
              .questions.length;
            j++
          ) {
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j][Props.LABEL] =
              incomeReturn.subQuestions[j][Props.LABEL];
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            // incomeMemberMultipleTemplate[
            //   prevClientIndex
            // ].subsections[0].questions[j].repeatedQuestion[Props.LABEL] =
            //   incomeReturn.subQuestions[j][Props.LABEL];
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMemberMultipleTemplate[
              prevClientIndex
            ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
            if (retainData === TextProps.VALUE_TRUE || retainData === null) {
              if (
                existingtypes.indexOf(
                  responseObject.unearnedIncome[i].typeCode
                ) >= 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].valueToMatch ===
                  responseObject.unearnedIncome[i].typeCode
                ) {
                  let amountArray = [];
                  if (
                    responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex]
                      .subsections[0].questions[j].Answer !== undefined &&
                    incomeMemberMultipleTemplate[prevClientIndex]
                      .subsections[0].questions[j].Answer.length > 0
                  ) {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer.push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][
                      OptionsProps.TARGET_VALUE
                    ].push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value.push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  } else {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer = [
                      {
                        fullIncomeObj:
                          responseObject.unearnedIncome[i],
                        originalTypeCode:
                          responseObject.unearnedIncome[i]
                            .typeCode,
                        code: responseObject.unearnedIncome[i]
                        .dataChangeCode,
                        
                        amount: amountArray,
                        unit: getFrequency(language)[
                          responseObject.unearnedIncome[i]
                            .frequencyCode
                        ],
                        id: "repeatable_id_" + _.uniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                      [
                        {
                          fullIncomeObj:
                            responseObject.unearnedIncome[
                              i
                            ],
                          originalTypeCode:
                            responseObject.unearnedIncome[i]
                              .typeCode,
                          code: responseObject.unearnedIncome[i]
                          .dataChangeCode,
                          
                          amount: amountArray,
                          unit: getFrequency(language)[
                            responseObject.unearnedIncome[i]
                              .frequencyCode
                          ],
                          id: "repeatable_id_" + _.uniqueId(),
                        },
                      ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value = [
                      {
                        fullIncomeObj:
                          responseObject.unearnedIncome[i],
                        originalTypeCode:
                          responseObject.unearnedIncome[i]
                            .typeCode,
                        code: responseObject.unearnedIncome[i]
                        .dataChangeCode,
                        
                        amount: amountArray,
                        unit: getFrequency(language)[
                          responseObject.unearnedIncome[i]
                            .frequencyCode
                        ],
                        id: "repeatable_id_" + _.uniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  }
                }
              } else {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].valueToMatch === "OTHER" &&
                  responseObject.unearnedIncome[i]
                    .typeCode === "OTHER"
                ) {
                  let amountArray = [];
                  if (
                    responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex]
                      .subsections[0].questions[j].Answer !== undefined &&
                    incomeMemberMultipleTemplate[prevClientIndex]
                      .subsections[0].questions[j].Answer.length > 0
                  ) {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer.push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][
                      OptionsProps.TARGET_VALUE
                    ].push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value.push({
                      fullIncomeObj:
                        responseObject.unearnedIncome[i],
                      originalTypeCode:
                        responseObject.unearnedIncome[i]
                          .typeCode,
                      code: responseObject.unearnedIncome[i]
                      .dataChangeCode,
                      
                      amount: amountArray,
                      unit: getFrequency(language)[
                        responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + _.uniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  } else {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer = [
                      {
                        fullIncomeObj:
                          responseObject.unearnedIncome[i],
                        originalTypeCode:
                          responseObject.unearnedIncome[i]
                            .typeCode,
                        code: responseObject.unearnedIncome[i]
                        .dataChangeCode,
                        
                        amount: amountArray,
                        unit: getFrequency(language)[
                          responseObject.unearnedIncome[i]
                            .frequencyCode
                        ],
                        id: "repeatable_id_" + _.uniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                      [
                        {
                          fullIncomeObj:
                            responseObject.unearnedIncome[
                              i
                            ],
                          originalTypeCode:
                            responseObject.unearnedIncome[i]
                              .typeCode,
                          code: responseObject.unearnedIncome[i]
                          .dataChangeCode,
                          
                          amount: amountArray,
                          unit: getFrequency(language)[
                            responseObject.unearnedIncome[i]
                              .frequencyCode
                          ],
                          id: "repeatable_id_" + _.uniqueId(),
                        },
                      ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value = [
                      {
                        fullIncomeObj:
                          responseObject.unearnedIncome[i],
                        originalTypeCode:
                          responseObject.unearnedIncome[i]
                            .typeCode,
                        code: responseObject.unearnedIncome[i]
                        .dataChangeCode,
                        
                        amount: amountArray,
                        unit: getFrequency(language)[
                          responseObject.unearnedIncome[i]
                            .frequencyCode
                        ],
                        id: "repeatable_id_" + _.uniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  }
                }
              }
            }
          }
        } // If income populated check ends
      }
    }
  }
  incomeMemberMultipleTemplate.push(
    filterData(
      filterData(sections, "unearned-income-summary")
        .subsections,
      "unearned-income-summary"
    )
  );
  incomeCopy.subsections = [];
  incomeCopy.subsections = incomeMemberMultipleTemplate;
  return incomeCopy;
}
export function getShelterExpenseFrequency(frequencyCode){
  let shelterExpenseFrequency;
  switch (frequencyCode) {
    case "Week":
    case "WEEK":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).week;
      return shelterExpenseFrequency;
    case "Month":
    case "MONTH":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).month;
      return shelterExpenseFrequency;
    case "Year":
    case "Annual":
    case "ANNUAL":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).ANNUAL;
      return shelterExpenseFrequency;
    case "QRTRLY":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).QRTRLY;
      return shelterExpenseFrequency;
    case "BIMON":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).BIMON;
      return shelterExpenseFrequency;
    case "BIWEEK":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).BIWEEK;
      return shelterExpenseFrequency;
    case "SEMANL":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).SEMANL;
      return shelterExpenseFrequency;
    case "SEMMON":
      shelterExpenseFrequency = recertificationPageLabels(
        language,
        null,
        languageConstants.childSupportExpense
      ).SEMMON;
      return shelterExpenseFrequency;
    default:
      break;
  }
};
export function getDataChangeCode(code){
  let changeCode = "NOCHNG";
  switch (code) {
    case "NEW":
      changeCode = "new";
      break;
    case "CHANGE":
      changeCode = "changed";
      break;
    case "REMOVE":
      changeCode = "removed";
      break;
    default:
      break;
  }
  return changeCode;
};
export function getSuffixValue(answer){
  switch (language) {
    case types.ENGLISH:
      return answer;
    case types.SPANISH:
      return answer;
    case types.PORTUGUESE:
      return answer;
    case types.CHINESE: //chinees
      if (
        answer ===
        recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).SuffixOptions[0]
      )
        return "I";
      else if (
        answer ===
        recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).SuffixOptions[10]
      )
        return "Jr.";
      else if (
        answer ===
        recertificationPageLabels(
          language,
          null,
          languageConstants.householdStaticRecert
        ).SuffixOptions[11]
      )
        return "Sr.";
      else return answer;
    case types.VIETNAMESE:
      return answer;
    default:
      return answer;
  }
};
export function onChildSupportSubsectionRenderHelper(memberTemplate,responseObject,sections,handleRemove,handleDone, recertType){
  let answer = [];
  let respObjChildSupportExpense = _.cloneDeep(
    responseObject
  );
  respObjChildSupportExpense &&
  respObjChildSupportExpense.childSupportExpense &&
  respObjChildSupportExpense.childSupportExpense.length > 0 &&
  respObjChildSupportExpense.childSupportExpense.map((support) => {
    answer.push(
      support.firstName +
        " " +
        support.lastName +
        formatters.formatDate(support.dateOfBirth)
    );
  });
  let childSupportMultipleTemplate = [];
  let childSupportTemplate = _.cloneDeep(expenseChildSupportTemplate);
  let childSupportCopy = recertType === "IR" ? 
      filterData(sections, "childsupport-expenses")
      : filterData(
    filterData(sections, "expenses").subsections,
    "childsupport-expenses"
  );

  let childSupportSummaryTemplate = recertType === "IR" ?
      _.cloneDeep(filterData(
        filterData(sections, "childsupport-expenses").subsections,
         "childsupport-expenses"
        ))
      : _.cloneDeep(
    filterData(
      filterData(sections, "expenses").subsections,
      "childsupport-expenses"
    ).subsections[
      filterData(
        filterData(sections, "expenses").subsections,
        "childsupport-expenses"
      ).subsections.length - 1
    ]
  );
  if (answer[0] !== "") {
    let prevClient = "";
    let prevClientIndex = 0;
    for (let i = 0; i < answer.length; i++) {
      if (prevClient !== answer[i]) {
        prevClient = answer[i];
        if (i > 0) {
          prevClientIndex++;
        }
        if (childSupportTemplate && childSupportTemplate.questions[0]) {
          childSupportTemplate.questions[0][Props.LABEL] =
            recertificationPageLabels(
              language,
              answer[i],
              languageConstants.childSupportExpense
            ).mainQuestion;
          childSupportTemplate.title = answer[i];
          // childSupportTemplate.subsections[0].title = answer[i];
          childSupportTemplate.clientId = answer[i];
          childSupportTemplate.id = "child-support-amount-" + i;
          childSupportTemplate.isRemoved = TextProps.VALUE_FALSE;
          childSupportTemplate.questions[0].id = "child-support-amount-" + i;
          childSupportTemplate.questions[0].clientId = answer[i];
          childSupportTemplate.questions[0].enabledClickAction =
            handleRemove;
          childSupportTemplate.questions[0].enabledDoneAction =
            handleDone;
          childSupportTemplate.questions[0].Answer = {
            amount: respObjChildSupportExpense.childSupportExpense[i].amount,
            unit: getShelterExpenseFrequency(
              respObjChildSupportExpense.childSupportExpense[i].frequencyCode
            ),
            code: respObjChildSupportExpense.childSupportExpense[i]
              .dataChangeCode
              ? getDataChangeCode(
                  respObjChildSupportExpense.childSupportExpense[i]
                    .dataChangeCode
                )
              : "NOCHNG",
          };
          childSupportTemplate.questions[0].targetValue = {
            amount: respObjChildSupportExpense.childSupportExpense[i].amount,
            unit: getShelterExpenseFrequency(
              respObjChildSupportExpense.childSupportExpense[i].frequencyCode
            ),
            code: respObjChildSupportExpense.childSupportExpense[i]
              .dataChangeCode
              ? getDataChangeCode(
                  respObjChildSupportExpense.childSupportExpense[i]
                    .dataChangeCode
                )
              : "NOCHNG",
          };
          if (
            respObjChildSupportExpense.childSupportExpense[i].dataChangeCode
          ) {
            childSupportTemplate.completed = TextProps.VALUE_TRUE;
          }
        }
        childSupportMultipleTemplate.push(_.cloneDeep(childSupportTemplate));
      } else {
        childSupportMultipleTemplate[prevClientIndex].questions[0][
          OptionsProps.TARGET_VALUE
        ] = {
          amount: respObjChildSupportExpense.childSupportExpense[i].amount,
          unit: getShelterExpenseFrequency(
            respObjChildSupportExpense.childSupportExpense[i].frequencyCode
          ),
          code: respObjChildSupportExpense.childSupportExpense[i]
            .dataChangeCode
            ? getDataChangeCode(
                respObjChildSupportExpense.childSupportExpense[i]
                  .dataChangeCode
              )
            : "NOCHNG",
        };
        childSupportMultipleTemplate[prevClientIndex].questions[0].Answer = {
          amount: respObjChildSupportExpense.childSupportExpense[i].amount,
          unit: getShelterExpenseFrequency(
            respObjChildSupportExpense.childSupportExpense[i].frequencyCode
          ),
          code: respObjChildSupportExpense.childSupportExpense[i]
            .dataChangeCode
            ? getDataChangeCode(
                respObjChildSupportExpense.childSupportExpense[i]
                  .dataChangeCode
              )
            : "NOCHNG",
        };
        if (
          respObjChildSupportExpense.childSupportExpense[i].dataChangeCode
        ) {
          childSupportMultipleTemplate[prevClientIndex].completed =
            TextProps.VALUE_TRUE;
        }
      }
    }
  }
  childSupportMultipleTemplate.push(childSupportSummaryTemplate);
  childSupportCopy.subsections = [];
  childSupportCopy.subsections = childSupportMultipleTemplate;
  return childSupportCopy;
}
export function checkForAddressChange(source, dest){
  let sourceLine2 = "";
  if (source.line2 !== undefined) {
    sourceLine2 = source.line2;
  }
  let streetSource = source
    ? (source.line1 + sourceLine2).replace(/ /g, "")
    : null;
  let streetDest = dest ? (dest.line1 + dest.line2).replace(/ /g, "") : null;
  if (
    source !== null &&
    dest !== null &&
    ((source.zip !== "" && dest.zip === "") ||
      (source.city !== "" && dest.city === "") ||
      (streetSource !== "" && streetDest === ""))
  ) {
    return "removed";
  } else if (streetSource !== streetDest) {
    return "changed";
  } else if (source !== null && dest !== null && source.city !== dest.city) {
    return "changed";
  } else if (source !== null && dest !== null && source.zip !== dest.zip) {
    return "changed";
  } else if (source !== null && dest !== null && source.county !== dest.county) {
    return "changed";
  }
};
export function convertDOBForDataPost(dobFrom){
  let retrunValue = null;
  if (dobFrom.indexOf("/") !== -1) {
    let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
    let dobArray = dob.split("/");

    let month = dobArray[0];
    // if (month < 10) {
    //   month = "0" + month;
    // }
    let date = dobArray[1];
    // if (date < 10) {
    //   date = "0" + date;
    // }
    retrunValue = dobArray[2] + "-" + month + "-" + date;
  } else {
    retrunValue = dobFrom;
  }

  return retrunValue;
};
export function getMemberDataObjectByNameNew(fn, ln, listToCheck){
  let returnMember = null;
  listToCheck.map((member) => {
    if (fn === member.firstName && ln === member.lastName) {
      returnMember = member;
    }
  });
  return returnMember;
};
export function getMemberDataObjectByNameAndDOB(name, listToCheck){
  let returnMember = null;
  listToCheck.map((member) => {
    let dob = member.dateOfBirth;
    if (dob.indexOf("-") > 0) {
      dob = formatters.formatDate(dob);
    }
    if (
      name.trim() ===
      member.firstName.trim() + " " + member.lastName.trim() + dob
    ) {
      returnMember = member;
    }
  });
  return returnMember;
};
export function returnMemberId(member, incomeSub){
  let membId;
  if (member.code === "new") {
    membId = 0;
  } else {
    if (incomeSub.fullIncomeObj !== undefined && incomeSub.fullIncomeObj.id) {
      membId = incomeSub.fullIncomeObj.id;
    } else {
      membId = 0;
    }
  }
  return membId;
}
export function returnAgencyID(memberObj, incomeSub) {
  let agencyID;
  if (
    incomeSub.fullIncomeObj !== undefined &&
    incomeSub.fullIncomeObj.agencyID
  ) {
    agencyID = incomeSub.fullIncomeObj.agencyID;
  } else {
    if (memberObj && "agencyID" in memberObj) {
      agencyID = memberObj.agencyID;
    } else {
      agencyID = 0;
    }
  }

  return agencyID;
}
export function addNewResourcesMethod(newResources,resourceType){
  let resourceDetailsArr = [];
  switch(resourceType){
    case "CASH": case "TRUST": case "PPDFUN": case "OTHER":
      newResources.Answer.map(resDet => {
        let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
        resourceDetailsObj.amount = resDet.amount;
        resourceDetailsObj.additionalInfo = resDet.additionalInformation;
        resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
        resourceDetailsArr.push(resourceDetailsObj);
      })
    break;
    case "SAVING": case "CHECK":
      newResources.Answer.map(resDet => {
        let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
        resourceDetailsObj.amount = resDet.amount;
        resourceDetailsObj.bankBrokerageName = resDet.bankName;
        resourceDetailsObj.accountNumber = resDet.accountnumber;
        resourceDetailsObj.isJointAccount = resDet.jointAccount === TextProps.VALUE_YES ? TextProps.VALUE_Y : TextProps.VALUE_N;
        resourceDetailsObj.jointAcntHolderName = resDet.jointAccountHolder;
        resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
        resourceDetailsArr.push(resourceDetailsObj);
      })
    break;
    case "STOCKS": case "BONDS": case "RETMNT":
      newResources.Answer.map(resDet => {
        let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
        resourceDetailsObj.amount = resDet.amount;
        resourceDetailsObj.bankBrokerageName = resDet.bankName;
        resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
        resourceDetailsArr.push(resourceDetailsObj);
      })
    break;
    default:
    break;
  }
  return resourceDetailsArr;
}
function checkJointAccountChanged(isJointAccount,jointAccount){
  if((isJointAccount === TextProps.VALUE_Y && jointAccount === TextProps.VALUE_YES) || (isJointAccount === TextProps.VALUE_N && jointAccount === TextProps.VALUE_NO)){
    return false
  }else{
    return isJointAccount === null ? false : true
  }

}
export function prepareDataForSubmissionHelper(activeId, responseBody,recertObj,shelterSummary,incomeSummary,medicalSummary,refs, ebtFlag, snapFlag, recertType){
  let reportedChanges = TextProps.VALUE_FALSE;
  let requestBody = _.cloneDeep(responseBody);
  if(activeId === "add-auth-rep") {
      requestBody.snapAuthRepFlg = snapFlag,
      requestBody.ebtAuthRepFlg =  ebtFlag,
      requestBody.authorizedRepresentatives = recertObj.authorizedRepresentatives;
  }
  if (recertObj.contactInfoScreen.contactInfoChanged) {
    let respAddress = responseBody.clientAddress;
    let respMailAddress = null;
    let respResAddress = null;

    for (let respAddressValue of respAddress) {
      if (respAddressValue.typeCode === "RES")
        respResAddress = respAddressValue;
      else if (respAddressValue.typeCode === "MAIL")
        respMailAddress = respAddressValue;
    }

    let updatedAddress =
      recertObj.contactInfoScreen.addressList.address;
    let updatedMailAddress = null;
    let updatedResAddress = null;

    for (let updatedAddressValue of updatedAddress) {
      if (
        updatedAddressValue.typeCode === "RES" &&
        updatedAddressValue.city &&
        updatedAddressValue.line1 &&
        updatedAddressValue.zip
      )
        updatedResAddress = updatedAddressValue;
      else if (updatedAddressValue.typeCode === "MAIL")
        updatedMailAddress = updatedAddressValue;
    }

    let finalAddressArray = [];
    if (respResAddress !== null && updatedResAddress !== null) {
      let newAddress = {};
      newAddress.line1 = updatedResAddress.line1;
      newAddress.line2 = updatedResAddress.line2;
      newAddress.city = updatedResAddress.city;
      newAddress.state = updatedResAddress.state;
      newAddress.zip = updatedResAddress.zip;
      newAddress.county = updatedResAddress.county;
      newAddress.countyCode = updatedResAddress.countyCode;
      newAddress.verified = updatedResAddress.verified;
      newAddress.homelessFlag =
        recertObj.contactInfoScreen.addressList.homeless;
      newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
      newAddress.typeCode = "RES";
      if (
        checkForAddressChange(respResAddress, updatedResAddress) ===
        "changed"
      ) {
        newAddress.dataChangeCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        checkForAddressChange(respResAddress, updatedResAddress) ===
        "removed"
      ) {
        newAddress.dataChangeCode = "REMOVE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respResAddress.dataChangeCode) {
        newAddress.dataChangeCode = respResAddress.dataChangeCode;
      } else {
        newAddress.dataChangeCode = "NOCHNG";
      }
      //Homeless flag check
      if (
        recertObj.contactInfoScreen.addressList.homeless !==
        respResAddress.homelessFlag
      ) {
        newAddress.dataChangeCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respResAddress.dataChangeCode &&
        recertObj.contactInfoScreen.changeFlags.homelssFlagCode ===
          "changedLabel"
      ) {
        newAddress.dataChangeCode = respResAddress.dataChangeCode;
        reportedChanges = TextProps.VALUE_TRUE;
      }
      finalAddressArray.push(newAddress);
    } else if (respResAddress === null && updatedResAddress !== null) {
      let newAddress = {};
      newAddress.line1 = updatedResAddress.line1;
      newAddress.line2 = updatedResAddress.line2;
      newAddress.city = updatedResAddress.city;
      newAddress.state = updatedResAddress.state;
      newAddress.zip = updatedResAddress.zip;
      newAddress.county = updatedResAddress.county;
      newAddress.countyCode = updatedResAddress.countyCode;
      newAddress.verified = updatedResAddress.verified;
      newAddress.homelessFlag =
        recertObj.contactInfoScreen.addressList.homeless;
      newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
      newAddress.typeCode = "RES";
      newAddress.dataChangeCode = "NEW";
      finalAddressArray.push(newAddress);
      reportedChanges = TextProps.VALUE_TRUE;
    }

    //Mail Addre check
    if (respMailAddress !== null && updatedMailAddress !== null) {
      let newAddress = {};
      newAddress.line1 = updatedMailAddress.line1;
      newAddress.line2 = updatedMailAddress.line2;
      newAddress.city = updatedMailAddress.city;
      newAddress.state = updatedMailAddress.state;
      newAddress.zip = updatedMailAddress.zip;
      newAddress.county = updatedMailAddress.county;
      newAddress.countyCode = updatedMailAddress.countyCode;
      newAddress.verified = updatedMailAddress.verified;
      newAddress.homelessFlag =
        recertObj.contactInfoScreen.addressList.homeless;
      newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
      newAddress.typeCode = "MAIL";
      if (
        checkForAddressChange(respMailAddress, updatedMailAddress) ===
        "changed" && respMailAddress.dataChangeCode !== "NEW"
      ) {
        newAddress.dataChangeCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        checkForAddressChange(respMailAddress, updatedMailAddress) ===
        "removed"
      ) {
        newAddress.dataChangeCode = "REMOVE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respMailAddress.dataChangeCode) {
        newAddress.dataChangeCode = respMailAddress.dataChangeCode;
      } else {
        newAddress.dataChangeCode = "NOCHNG";
      }
      if (
        recertObj.contactInfoScreen.addressList.homeless !==
        respMailAddress.homelessFlag
      ) {
        newAddress.dataChangeCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respMailAddress.dataChangeCode &&
        recertObj.contactInfoScreen.changeFlags.homelssFlagCode ===
          "changedLabel"
      ) {
        newAddress.dataChangeCode = respMailAddress.dataChangeCode;
        reportedChanges = TextProps.VALUE_TRUE;
      }
      if (finalAddressArray.length > 0)
        finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
      finalAddressArray.push(newAddress);
    } else if (respMailAddress === null && updatedMailAddress !== null) {
      let newAddress = {};
      newAddress.line1 = updatedMailAddress.line1;
      newAddress.line2 = updatedMailAddress.line2;
      newAddress.city = updatedMailAddress.city;
      newAddress.state = updatedMailAddress.state;
      newAddress.zip = updatedMailAddress.zip;
      newAddress.county = updatedMailAddress.county;
      newAddress.countyCode = updatedMailAddress.countyCode;
      newAddress.verified = updatedMailAddress.verified;
      newAddress.homelessFlag =
        recertObj.contactInfoScreen.addressList.homeless;
      newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
      newAddress.typeCode = "MAIL";
      newAddress.dataChangeCode = "NEW";
      reportedChanges = TextProps.VALUE_TRUE;

      if (finalAddressArray.length > 0)
        finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
      finalAddressArray.push(newAddress);
    } else if (respMailAddress !== null && updatedMailAddress === null) {
      if (finalAddressArray.length > 0) {
        finalAddressArray[0].dataChangeCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      }
    }

    requestBody.clientAddress = finalAddressArray;

    //Email change
    let respEmail = responseBody.clientEmail;
    let updatedEmail = recertObj.contactInfoScreen;
    let emailCode = null;
    if ((!respEmail.emailAddress && updatedEmail.email) || (respEmail.dataChangeCode && respEmail.dataChangeCode === "NEW")) {
      emailCode = "NEW";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (respEmail.emailAddress && !updatedEmail.email) {
      emailCode = "REMOVE";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (
      respEmail.emailAddress &&
      (respEmail.emailAddress !== updatedEmail.email ||
        respEmail.optInFlag !== updatedEmail.emailOptIn)
    ) {
      emailCode = "CHANGE";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (respEmail.dataChangeCode) {
      emailCode = respEmail.dataChangeCode;
    } else {
      emailCode = "NOCHNG";
    }

    requestBody.clientEmail.dataChangeCode = emailCode;
    requestBody.clientEmail.emailAddress = updatedEmail.email;
    requestBody.clientEmail.optInFlag =
      updatedEmail.emailOptIn === TextProps.VALUE_Y ||
      updatedEmail.emailOptIn === TextProps.VALUE_TRUE
        ? TextProps.VALUE_TRUE
        : TextProps.VALUE_FALSE;

    //Phone change
    let respPhone = responseBody.clientPhone;
    let updatedPhone = recertObj.contactInfoScreen;
    let phoneNumber = updatedPhone.phoneNumber
    ? updatedPhone.phoneNumber.replace(/\D/g, "")
    : "";
    let phoneCode = null;
    if ((!respPhone.phoneNumber && phoneNumber) || (respPhone.dataChangeCode && respPhone.dataChangeCode === "NEW")) {
      phoneCode = "NEW";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (respPhone.phoneNumber && !phoneNumber) {
      phoneCode = "REMOVE";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (
      respPhone.phoneNumber &&
      (respPhone.phoneNumber !== phoneNumber ||
        respPhone.textOptInFlag !== updatedPhone.textOptIn)
    ) {
      phoneCode = "CHANGE";
      reportedChanges = TextProps.VALUE_TRUE;
    } else if (respPhone.dataChangeCode) {
      phoneCode = respPhone.dataChangeCode;
    } else {
      phoneCode = "NOCHNG";
    }

    requestBody.clientPhone.dataChangeCode = phoneCode;
    requestBody.clientPhone.phoneNumber = phoneNumber;
    requestBody.clientPhone.textOptInFlag =
      updatedPhone.textOptIn === TextProps.VALUE_Y ||
      updatedPhone.textOptIn === TextProps.VALUE_TRUE
        ? TextProps.VALUE_TRUE
        : TextProps.VALUE_FALSE;
  }
  if (requestBody.clientAddress.length > 0) {
    let newAddressArray = [];
    requestBody.clientAddress.map((address) => {
      if (address.dataChangeCode === undefined) {
        address.dataChangeCode = "NOCHNG";
      }
      newAddressArray.push(address);
    });
    requestBody.clientAddress = newAddressArray;
  }

  if (requestBody.clientPhone.dataChangeCode === undefined) {
    requestBody.clientPhone.dataChangeCode = "NOCHNG";
  }

  if (requestBody.clientEmail.dataChangeCode === undefined) {
    requestBody.clientEmail.dataChangeCode = "NOCHNG";
  }
  
  if (
    recertType !== "IR" && recertType !== "REEVAL" &&
    recertObj.sections.filter((sec) => sec.id === "expedited-snap")
      .length
  ) {
    /** imm needs sections data */
    requestBody.needs = [];
    let immediateNeeds = recertObj.sections.filter(
      (sec) => sec.id === "expedited-snap"
    )[0].questions;

    let needsHousing = new ImmediateNeeds();

    needsHousing.needTypeCode = immediateNeeds[0].pgrmCode;
    if (
      immediateNeeds[0].Answer !== null &&
      immediateNeeds[0].Answer !== undefined &&
      immediateNeeds[0].Answer !== ""
    ) {
      if (
        immediateNeeds[0].Answer === "Yes" ||
        immediateNeeds[0].Answer === "Sí"
      ) {
        needsHousing.answer = TextProps.VALUE_Y;
      } else {
        needsHousing.answer = TextProps.VALUE_N;
      }
    }
    requestBody.needs.push(needsHousing);

    let needsIncome = new ImmediateNeeds();
    needsIncome.needTypeCode = immediateNeeds[1].pgrmCode;
    if (
      immediateNeeds[1].Answer !== null &&
      immediateNeeds[1].Answer !== undefined &&
      immediateNeeds[1].Answer !== ""
    ) {
      if (
        immediateNeeds[1].Answer === "Yes" ||
        immediateNeeds[1].Answer === "Sí"
      ) {
        needsIncome.answer = TextProps.VALUE_Y;
      } else {
        needsIncome.answer = TextProps.VALUE_N;
      }
    }
    requestBody.needs.push(needsIncome);

    let needsWorker = new ImmediateNeeds();
    needsWorker.needTypeCode = immediateNeeds[2].pgrmCode;
    if (
      immediateNeeds[2].Answer !== null &&
      immediateNeeds[2].Answer !== undefined &&
      immediateNeeds[2].Answer !== ""
    ) {
      if (
        immediateNeeds[2].Answer === "Yes" ||
        immediateNeeds[2].Answer === "Sí"
      ) {
        needsWorker.answer = TextProps.VALUE_Y;
      } else {
        needsWorker.answer = TextProps.VALUE_N;
      }
    }
    requestBody.needs.push(needsWorker);
  }
  //HOUSEHOLD CHANGE
  let removedHOH = [];
  if ((recertObj.householdMembers &&
    recertObj.householdMembers.membersListUpdated) || (recertObj.householdMembers && recertObj.householdMembers.membersList && JSON.stringify(recertObj.householdMembers.membersList) !== JSON.stringify(recertObj.responseObject.householdMembers))
  ) {
    let newHouseholdList = [];
    for (let memberList of recertObj.householdMembers.membersList) {
      if (memberList.allAnswers !== undefined) {
        let genderCodeVal = "";
        if (
          memberList.allAnswers[6].Answer ===
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).genderOptios[0]
        ) {
          genderCodeVal = "MALE";
        } else if (
          memberList.allAnswers[6].Answer ===
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdStaticRecert
          ).genderOptios[1]
        ) {
          genderCodeVal = "FEMALE";
        }

        let citizenFlagVal = null;
        if(recertType !== "REEVAL") {
          if (
            memberList.allAnswers[9].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).citizenOptions[0]
          ) {
            citizenFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[9].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).citizenOptions[1]
          ) {
            citizenFlagVal = TextProps.VALUE_FALSE;
          }
          let buyFoodFlagVal = null;
          if (
            memberList.allAnswers[11].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).buyFoodOptions[0]
          ) {
            buyFoodFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[11].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).buyFoodOptions[1]
          ) {
            buyFoodFlagVal = TextProps.VALUE_FALSE;
          }
          let incomeFlagVal = null;
          if (
            memberList.allAnswers[12].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).personIncomeOptions[0]
          ) {
            incomeFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[12].Answer ===
            recertificationPageLabels(
              language,
              null,
              languageConstants.householdStaticRecert
            ).personIncomeOptions[1]
          ) {
            incomeFlagVal = TextProps.VALUE_FALSE;
          }
          const raceAnswers =
            memberList.allAnswers[8].Answer &&
            memberList.allAnswers[8].Answer.length &&
            memberList.allAnswers[8].Answer.map((raceAns) => {
              let raceObj = {};
              if (
                raceAns === "Decline to answer" ||
                raceAns === "DCL" ||
                raceAns === "Me niego a responder"
              ) {
                raceObj["raceCode"] = "DCL";
              } else {
                raceObj["raceCode"] = raceAns;
              }
              if (raceAns === "OTHER") {
                raceObj["otherDesc"] =
                  memberList.allAnswers[8].conditionals[0].conditionalQuestions[0].Answer;
              }
              return raceObj;
            });
          let ethnicityCode = memberList.allAnswers[7].Answer;
          if(memberList.allAnswers[7].Answer === "Decline to answer" ||
          memberList.allAnswers[7].Answer === "Negarse a contestar"){
            ethnicityCode = "DCL";
          }
        }

        // Need to check the commented object values test
        let newMemberObj = {
          agencyID: 0,
          granteeFlag: TextProps.VALUE_FALSE,
          firstName: memberList.allAnswers[0].Answer,
          middleName: memberList.allAnswers[1].Answer,
          lastName: memberList.allAnswers[2].Answer,
          suffix: getSuffixValue(memberList.allAnswers[3].Answer),
          relationshipCode: memberList.allAnswers[4].Answer.toUpperCase(),
          dateOfBirth: convertDOBForDataPost(memberList.dateOfBirth),
          genderCode: genderCodeVal,
          // ethnicity: ethnicityCode,
          // race: raceAnswers ? raceAnswers : [],
          // citizenFlag: citizenFlagVal,
          // SSN: memberList.allAnswers[10].Answer,
          // buyFoodFlag: buyFoodFlagVal,
          // incomeFlag: incomeFlagVal,
          dataChangeCode: "NEW",
        };
        newHouseholdList.push(newMemberObj);
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        memberList.dataChangeCode &&
        memberList.dataChangeCode === "removed"
      ) {
        let removedMember = memberList;
        removedMember.dataChangeCode = "REMOVE";
        if (
          removedMember.granteeFlag === TextProps.VALUE_Y ||
          removedMember.granteeFlag === TextProps.VALUE_TRUE
        ) {
          removedMember.granteeFlag = TextProps.VALUE_TRUE;
          removedHOH.push(removedMember);
          // requestBody.removedHOH = removedHOH;
        } else {
          removedMember.granteeFlag = TextProps.VALUE_FALSE;
        }
        removedMember.dateOfBirth = convertDOBForDataPost(
          removedMember.dateOfBirth
        );
        newHouseholdList.push(removedMember);
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (memberList.agencyID > 1) {
        let existingMember = memberList;
        if (
          existingMember.granteeFlag === TextProps.VALUE_Y ||
          existingMember.granteeFlag === TextProps.VALUE_TRUE
        ) {
          existingMember.granteeFlag = TextProps.VALUE_TRUE;
        } else {
          existingMember.granteeFlag = TextProps.VALUE_FALSE;
        }
        existingMember.dateOfBirth = convertDOBForDataPost(
          existingMember.dateOfBirth
        );
        newHouseholdList.push(existingMember);
      }
    }
    requestBody.householdMembers = newHouseholdList;
  }

  if (requestBody.householdMembers.length > 0) {
    let newHouseholdArray = [];
    requestBody.householdMembers.map((member) => {
      member.dateOfBirth = convertDOBForDataPost(member.dateOfBirth);
      if (member.granteeFlag === TextProps.VALUE_Y) {
        member.granteeFlag = TextProps.VALUE_TRUE;
      } else if (member.granteeFlag === TextProps.VALUE_N) {
        member.granteeFlag = TextProps.VALUE_FALSE;
      }

      if (member.dataChangeCode === undefined) {
        member.dataChangeCode = "NOCHNG";
      }
      newHouseholdArray.push(member);
    });
    requestBody.householdMembers = newHouseholdArray;
  }
  //NONCITIZEN CHANGES
  if(recertType !== "REEVAL") {
    if (
      recertObj.noncitizenStatusUpdated !== null &&
      recertObj.noncitizenStatusUpdated === TextProps.VALUE_TRUE
    ) {
      reportedChanges = TextProps.VALUE_TRUE;
  
      requestBody.noncitizenStatus = {
        dataChangeCode: "CHANGE",
      };
    } else if (
      recertObj.noncitizenStatusUpdated !== null &&
      recertObj.noncitizenStatusUpdated === TextProps.VALUE_FALSE
    ) {
      requestBody.noncitizenStatus = {
        dataChangeCode: "NOCHNG",
      };
    } else {
      requestBody.noncitizenStatus = null;
    }
  
    if (
      responseBody.noncitizenStatus &&
      requestBody.noncitizenStatus !== null &&
      responseBody.noncitizenStatus.length > 0
    ) {
      let nonCitMembers = [];
      responseBody.noncitizenStatus.map((member) => {
        member.dateOfBirth = convertDOBForDataPost(member.dateOfBirth);
        nonCitMembers.push(member);
      });
      requestBody.noncitizenStatus.members = nonCitMembers;
    }
  }
  //HOUSEHOLD INFORMATION
  if(recertType !== "IR"){
    let householdInformation = filterData(
      recertObj.sections,
      "household-information"
    );
    if (householdInformation !== undefined) {
      let webHouseholdInfo = [];
      householdInformation.questions.map((section) => {
        const questionsObj = section.conditionals[0].conditionalQuestions[0];
        const radioQuestionObj = section.Answer;
        if (
          radioQuestionObj ===
          recertificationPageLabels(
            language,
            null,
            languageConstants.householdInformation
          ).options[0]
        ) {
          if (
            questionsObj.Answer !== undefined ||
            questionsObj.Answer !== "" ||
            questionsObj.Answer.length > 1
          ) {
            questionsObj &&
              questionsObj.Answer &&
              questionsObj.Answer.map((ans) => {
                let ansObj = _.cloneDeep(houseHoldInformationTemplate);
                let selectedMem = questionsObj.options.filter(
                  (opt) => opt.optionValue == ans
                )[0];
                let existingMem = webHouseholdInfo.filter(
                  (household) =>
                    household.agencyID === selectedMem.memInfo.agencyID
                );
                if (webHouseholdInfo.length && existingMem.length) {
                  existingMem[0][questionsObj.id] = TextProps.VALUE_Y;
                } else {
                  ansObj.firstName = selectedMem.memInfo.firstName;
                  ansObj.lastName = selectedMem.memInfo.lastName;
                  ansObj.dateOfBirth = convertDOBForDataPost(
                    selectedMem.memInfo.dateOfBirth
                  );
                  ansObj.agencyID = selectedMem.memInfo.agencyID;
                  ansObj[questionsObj.id] = TextProps.VALUE_Y;
                  webHouseholdInfo.push(ansObj);
                }
              });
          }
        }
      });
      if (webHouseholdInfo.length) {
        requestBody.webHouseholdInfo = webHouseholdInfo;
      } else {
        requestBody.webHouseholdInfo = [];
      }
    }
  }
  //RESOURCES
  if(filterData(recertObj.sections, "resources-summary") && recertObj.householdResourcesChanged !== undefined && recertObj.householdResourcesChanged === TextProps.VALUE_TRUE){
    const groupByName = groupBy(['firstName', 'lastName','dateOfBirth']);
    let resourcesGroupedByName = groupByName(requestBody.resources);
    let updatedResources = filterData(recertObj.sections, "resources-summary");
    if(updatedResources.subsections.length > 1){
      let resourcesArr = [];
      let gropedResources = [];
      updatedResources.subsections.map(resources => {
        let existingResourcesUpdated = TextProps.VALUE_FALSE;
        if(requestBody.resources && resources.id !== "resources-summary"){
          for(let resourcesObj in resourcesGroupedByName){
            let resourceName = resourcesGroupedByName[resourcesObj][0];
            let resourceFullName = [
              resourceName["firstName"],
              resourceName["lastName"],
              resourceName["dateOfBirth"] ? formatters.formatDate(resourceName["dateOfBirth"]) : null,
            ]
              .join(" ")
              .replace(/ +(?= )/g, "");
            if(resourceFullName === resources.title){
              existingResourcesUpdated = TextProps.VALUE_TRUE;
              resourcesGroupedByName[resourcesObj].map(resourcesSubObj => {
                let parentdatachangeCode = _.cloneDeep(resourcesSubObj.datachangeCode);
                let isResourceTypeMatched = resources.subsections && resources.subsections[0].questions.filter(ques => (ques.valueToMatch === resourcesSubObj.resourceType) && ques.datachangeCode !== "REMOVE")[0];
                if(isResourceTypeMatched && isResourceTypeMatched.Answer.length){
                  for(let j=0; j<isResourceTypeMatched.Answer.length;j++){
                    switch(resourcesSubObj.resourceType){
                      case "CASH": case "TRUST": case "PPDFUN": case "OTHER":
                        if(!resourcesSubObj.resourceDetails.length && isResourceTypeMatched.Answer.length){
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.additionalInfo = resDet.additionalInformation;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else if(resourcesSubObj.resourceDetails[j] && (resourcesSubObj.resourceDetails[j].amount !== isResourceTypeMatched.Answer[j].amount || resourcesSubObj.resourceDetails[j].additionalInfo !== isResourceTypeMatched.Answer[j].additionalInformation)){
                          resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                          parentdatachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                          resourcesSubObj.resourceDetails[j].amount = isResourceTypeMatched.Answer[j].amount;
                          resourcesSubObj.resourceDetails[j].additionalInfo = isResourceTypeMatched.Answer[j].additionalInformation;
                        }else if(isResourceTypeMatched.Answer.length !== resourcesSubObj.resourceDetails.length){
                          resourcesSubObj.resourceDetails = [];
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.additionalInfo = resDet.additionalInformation;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else{
                          if(resourcesSubObj.resourceDetails[j])
                          resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.resourceDetails[j].datachangeCode ? resourcesSubObj.resourceDetails[j].datachangeCode : "NOCHNG";
                        }
                      break;
                      case "SAVING": case "CHECK":
                        if(!resourcesSubObj.resourceDetails.length && isResourceTypeMatched.Answer.length){
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.bankBrokerageName = resDet.bankName;
                            resourceDetailsObj.accountNumber = resDet.accountnumber;
                            resourceDetailsObj.isJointAccount = resDet.jointAccount === TextProps.VALUE_YES ? TextProps.VALUE_Y : TextProps.VALUE_N;
                            resourceDetailsObj.jointAcntHolderName = resDet.jointAccountHolder;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else if(resourcesSubObj.resourceDetails[j] && (resourcesSubObj.resourceDetails[j].amount !== isResourceTypeMatched.Answer[j].amount || resourcesSubObj.resourceDetails[j].bankBrokerageName !== isResourceTypeMatched.Answer[j].bankName || resourcesSubObj.resourceDetails[j].accountNumber !== isResourceTypeMatched.Answer[j].accountnumber || checkJointAccountChanged(resourcesSubObj.resourceDetails[j].isJointAccount,isResourceTypeMatched.Answer[j].jointAccount) || resourcesSubObj.resourceDetails[j].jointAcntHolderName !== isResourceTypeMatched.Answer[j].jointAccountHolder)){
                         resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                         parentdatachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                         resourcesSubObj.resourceDetails[j].amount = isResourceTypeMatched.Answer[j].amount;
                         resourcesSubObj.resourceDetails[j].bankBrokerageName = isResourceTypeMatched.Answer[j].bankName;
                         resourcesSubObj.resourceDetails[j].accountNumber = isResourceTypeMatched.Answer[j].accountnumber;
                         resourcesSubObj.resourceDetails[j].isJointAccount = isResourceTypeMatched.Answer[j].jointAccount === TextProps.VALUE_YES ? TextProps.VALUE_Y : TextProps.VALUE_N;
                         resourcesSubObj.resourceDetails[j].jointAcntHolderName = isResourceTypeMatched.Answer[j].jointAccount === TextProps.VALUE_NO ? "" : isResourceTypeMatched.Answer[j].jointAccountHolder;
                        }else if(isResourceTypeMatched.Answer.length !== resourcesSubObj.resourceDetails.length){
                          resourcesSubObj.resourceDetails = [];
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.bankBrokerageName = resDet.bankName;
                            resourceDetailsObj.accountNumber = resDet.accountnumber;
                            resourceDetailsObj.isJointAccount = resDet.jointAccount === TextProps.VALUE_YES ? TextProps.VALUE_Y : TextProps.VALUE_N;
                            resourceDetailsObj.jointAcntHolderName = resDet.jointAccountHolder;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else{
                          if(resourcesSubObj.resourceDetails[j])
                          resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.resourceDetails[j].datachangeCode ? resourcesSubObj.resourceDetails[j].datachangeCode : "NOCHNG";
                        }
                      break;
                      case "STOCKS": case "BONDS": case "RETMNT":
                        if(!resourcesSubObj.resourceDetails.length && isResourceTypeMatched.Answer.length){
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.bankBrokerageName = resDet.bankName;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else if(!resourcesSubObj.resourceDetails.length && isResourceTypeMatched.Answer.length){
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.additionalInfo = resDet.additionalInformation;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else if(resourcesSubObj.resourceDetails[j] && (resourcesSubObj.resourceDetails[j].amount !== isResourceTypeMatched.Answer[j].amount || resourcesSubObj.resourceDetails[j].bankBrokerageName !== isResourceTypeMatched.Answer[j].bankName)){
                          console.log(resourcesSubObj.resourceDetails);
                          resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                          parentdatachangeCode = resourcesSubObj.datachangeCode !== "NEW" ? "CHANGE" : "NEW";
                          resourcesSubObj.resourceDetails[j].amount = isResourceTypeMatched.Answer[j].amount;
                          resourcesSubObj.resourceDetails[j].bankBrokerageName = isResourceTypeMatched.Answer[j].bankName;
                         }else if(isResourceTypeMatched.Answer.length !== resourcesSubObj.resourceDetails.length){
                          resourcesSubObj.resourceDetails = [];
                          isResourceTypeMatched.Answer.map(resDet => {
                            let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateToSave);
                            resourceDetailsObj.amount = resDet.amount;
                            resourceDetailsObj.bankBrokerageName = resDet.bankName;
                            resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NEW";
                            resourcesSubObj.resourceDetails.push(resourceDetailsObj);
                          })
                        }else{
                          if(resourcesSubObj.resourceDetails[j])
                          resourcesSubObj.resourceDetails[j].datachangeCode = resourcesSubObj.resourceDetails[j].datachangeCode ? resourcesSubObj.resourceDetails[j].datachangeCode : "NOCHNG";
                         }
                      break;
                      default:
                      break;
                    }
                  }
                  resourcesSubObj.datachangeCode = parentdatachangeCode && parentdatachangeCode !== "REMOVE" ? parentdatachangeCode : "NOCHNG";
                  isResourceTypeMatched.datachangeCode = parentdatachangeCode && parentdatachangeCode !== "REMOVE"  ? parentdatachangeCode : "NOCHNG";
                }else{
                  // resourcesSubObj.resourceDetails = [];
                  resourcesSubObj.datachangeCode = resourcesSubObj.datachangeCode === "NEW" ? "NEWREMOVED" : "REMOVE";
                }
              });
              let finalResourcesGroupedByName = resourcesGroupedByName[resourcesObj].filter(resourceObj => resourceObj.datachangeCode !== "NEWREMOVED");
              if(resources.subsections){
                let newResourcesArr = [];
                let memberName = resources.title.split(" ");
                resources.subsections[0].questions.map(newResources => {
                  let noNewResourcesAdded = resourcesGroupedByName[resourcesObj].filter(ques => ques.resourceType === newResources.valueToMatch)[0];
                  if(!noNewResourcesAdded){
                    let newResourcesObj = {};
                    newResourcesObj.resourceType = newResources.valueToMatch;
                    newResourcesObj.firstName = memberName[0];
                    newResourcesObj.lastName = memberName[1];
                    newResourcesObj.datachangeCode = "NEW";
                    newResourcesObj.dateOfBirth = convertDOBForDataPost(memberName[2]);
                    newResourcesObj.resourceDetails = addNewResourcesMethod(newResources,resources.resourceType);
                    newResourcesArr.push(newResourcesObj);
                  }
                })
                finalResourcesGroupedByName = _.concat(finalResourcesGroupedByName,newResourcesArr);
              }
              gropedResources = _.concat(gropedResources,finalResourcesGroupedByName);
            }
          }
        }
        if(!existingResourcesUpdated && resources.id !== "resources-summary"){
          let newResourcesArr = [];
          let memberName = resources.title.split(" ");
          if(memberName.length > 1){
            resources.subsections && resources.questions[0].Answer && resources.questions[0].Answer.length && resources.subsections[0].questions.map(newResources => {
            let newResourcesObj = {};
            newResourcesObj.resourceType = newResources.valueToMatch;
            newResourcesObj.firstName = memberName[0];
            newResourcesObj.lastName = memberName[1];
            newResourcesObj.datachangeCode = "NEW";
            newResourcesObj.dateOfBirth = convertDOBForDataPost(memberName[2]);
            newResourcesObj.resourceDetails = addNewResourcesMethod(newResources,resources.resourceType);
            newResourcesArr.push(newResourcesObj);
            })
          gropedResources = _.concat(gropedResources,newResourcesArr);
        }
      }
      });
      if(gropedResources.length){
        requestBody.resources = gropedResources;
      }else{
        requestBody.resources = requestBody.resources.filter(resourceObj => resourceObj.datachangeCode !== "NEWREMOVED")
      }
    }
  }
  //add hasResourcesFlg recertObj.householdMembers.membersList
  if(filterData(recertObj.sections, "resources-summary")){
    const groupByName = groupBy(['firstName', 'lastName','dateOfBirth']);
    let resourcesGroupedByName = groupByName(requestBody.resources);
    for(let resourcesObj in resourcesGroupedByName){
      let resourceName = resourcesGroupedByName[resourcesObj][0];
      let resourceFullName = [
        resourceName["firstName"],
        resourceName["lastName"],
        resourceName["dateOfBirth"] ? formatters.formatDate(resourceName["dateOfBirth"]) : null,
      ]
        .join(" ")
        .replace(/ +(?= )/g, "");
        requestBody.householdMembers.map((member) => {
          let dob = member.dateOfBirth;
          if (dob.indexOf("-") > 0) {
            dob = formatters.formatDate(dob);
          }
          if (
            resourceFullName.trim() ===
            member.firstName.trim() + " " + member.lastName.trim() + dob
          ) {
            member.hasResourcesFlg = TextProps.VALUE_Y;
          }else{
            member.hasResourcesFlg = null;
          }
        });
    }
  }
  //STUDENT CHANGE
  if(recertType !== "IR" && recertType !== "REEVAL"){
  if (
    filterData(recertObj.sections, "students") &&
    filterData(recertObj.sections, "students").subsections !==
      undefined &&
    filterData(recertObj.sections, "students").subsections !==
      null
  ) {
    reportedChanges = TextProps.VALUE_TRUE;

    let membersArray = [];
    let newStudentArray = [];
    responseBody.householdMembers.map((member) => {
      membersArray.push(member.firstName + " " + member.lastName);
    });

    for (let subsectionValue of filterData(
      recertObj.sections,
      "students"
    ).subsections) {
      let memberData = subsectionValue.title.split("'")[0];
      memberData = memberData.split(" ");
      let memName = null;
      for (let currentVal of memberData) {
        if (currentVal.trim() === "") continue;
        if (memName != null) memName += " " + currentVal;
        else memName = currentVal;
      }
      let memObj = getMemberDataObjectByNameNew(
        memberData[0],
        memberData[1],
        recertObj.householdMembers.membersList
      );
      let newStudentObj = {
        agencyID: memObj ? memObj.agencyID : 0,
        firstName: memObj.firstName,
        lastName: memObj.lastName,
        dateOfBirth: memObj.dateOfBirth,
        schoolTypeCode: subsectionValue.questions[0].Answer,
        schoolName:
          subsectionValue.questions[0].Answer === "COLLGE"
            ? subsectionValue.questions[0].conditionals[1]
                .conditionalQuestions[0].Answer
            : subsectionValue.questions[0].conditionals[0]
                .conditionalQuestions[0].Answer,
        SASID:
          subsectionValue.questions[0].Answer === "HGHSCH" &&
          subsectionValue.questions[0].conditionals[0].conditionalQuestions
            .length > 1 &&
          subsectionValue.questions[0].conditionals[0].conditionalQuestions[1]
            .Answer
            ? subsectionValue.questions[0].conditionals[0]
                .conditionalQuestions[1].Answer
            : "",
      };
      newStudentArray.push(newStudentObj);
    }

    requestBody.students = newStudentArray;

    if (requestBody.students && requestBody.students.length > 0) {
      let newStudents = [];
      requestBody.students.map((student) => {
        student.dateOfBirth = convertDOBForDataPost(student.dateOfBirth);
        newStudents.push(student);
      });
      if (
        filterData(recertObj.sections, "students") &&
        filterData(recertObj.sections, "students").questions[0]
          .Answer &&
        filterData(recertObj.sections, "students").questions[0]
          .Answer.length > 0 &&
        filterData(recertObj.sections, "students").questions[0]
          .Answer[0] ===
          recertificationPageLabels(
            language,
            null,
            languageConstants.studentStatic
          ).noneLabel
      ) {
        requestBody.students = [{}];
      } else {
        requestBody.students = newStudents;
      }
    } else {
      requestBody.students = [];
    }
  } else if (
    filterData(recertObj.sections, "students") &&
    filterData(recertObj.sections, "students").questions[0]
      .Answer &&
    filterData(recertObj.sections, "students").questions[0]
      .Answer.length > 0 &&
    filterData(recertObj.sections, "students").questions[0]
      .Answer[0] ===
      recertificationPageLabels(
        language,
        null,
        languageConstants.studentStatic
      ).noneLabel
  ) {
    requestBody.students = [{}];
  } else {
    requestBody.students = [];
  }
  }

  //Shelter Expenses
  let shelterExpSection = recertType === "IR" ?
   filterData(recertObj.sections, "shelter-utility-summary")
   : filterData(recertObj.sections, "expenses") ;
  if (
    shelterExpSection &&
    recertObj.shelterUtilityConfirmationChanged
  ) {
    let shelterExpNew = _.cloneDeep(
      shelterSummary.shelterExpense[0].incomes
    );
    let shelterExpJSON = recertType === "IR" ?
      _.cloneDeep(
        filterData(
        filterData(
          filterData(recertObj.sections, "shelter-utility-summary")
            .subsections,
            "shelter-expenses-section"
          ).subsections,
          "shelter-expense-subsection"
        ).questions
      )
      : _.cloneDeep(
      filterData(
        filterData(
          filterData(
            filterData(recertObj.sections, "expenses")
              .subsections,
            "shelter-utility-summary"
          ).subsections,
          "shelter-expenses-section"
        ).subsections,
        "shelter-expense-subsection"
      ).questions
    );
    let { responseObject } = recertObj;
    let shelterUtilResObj = recertType === "IR" ? responseObject.shelterExpense : responseObject.shelterExpenses;
    let shelterExpExisting = _.cloneDeep(
      shelterUtilResObj
    );

    let oldShelterCodes =
      shelterUtilResObj.map((exp) => {
        return exp.typeCode;
      });
    let newShelterCodes = shelterExpNew.map((exp) => {
      return exp.typeCode;
    });

    let finalShelterArray = [];
    shelterExpJSON.forEach((exp) => {
      if (exp.Answer && exp.Answer.length) {
        let shelterObj = {};
        exp.Answer.forEach((oneExp) => {
          let changeCode = "NOCHNG";
          switch (oneExp.code) {
            case "NEW":
            case "new":
              changeCode = "NEW";
              break;
            case "CHANGE":
            case "changed":
              changeCode = "CHANGE";
              break;
            case "REMOVE":
            case "removed":
              changeCode = "REMOVE";
              break;
            default:
              break;
          }
          if (changeCode !== "NOCHNG") reportedChanges = TextProps.VALUE_TRUE;
          shelterObj = {
            typeCode: exp.valueToMatch,
            frequencyCode: getFrequencyCode(language)[oneExp.unit],
            amount: oneExp.amount,
            dataChangeCode: changeCode,
          };
          finalShelterArray.push(shelterObj);
        });
      } else {
        let shelterObj = {};
        let changeCode = "NOCHNG";
        shelterObj = {
          typeCode: exp.valueToMatch,
          frequencyCode: null,
          amount: null,
          dataChangeCode: changeCode,
        };
        finalShelterArray.push(shelterObj);
      }
    });
    const shelterSection = recertType === "IR" ? "shelterExpense" : "shelterExpenses"
    requestBody[shelterSection] = finalShelterArray;
    //Utility Expense
    let utilityExpensesTemplateData = _.cloneDeep(utilityExpensesTemplate);
    let utilityExpenseObj = _.cloneDeep(utilityObj);
    
    let utlExpJSON = recertType === "IR" ? 
      _.cloneDeep(
        filterData(
          filterData(
           filterData(recertObj.sections, "shelter-utility-summary")
            .subsections,
            "shelter-expenses-section"
          ).subsections,
          "utility-expenses-section"
         ).questions
      )
      : _.cloneDeep(
      filterData(
        filterData(
          filterData(
            filterData(recertObj.sections, "expenses")
              .subsections,
            "shelter-utility-summary"
          ).subsections,
          "shelter-expenses-section"
        ).subsections,
        "utility-expenses-section"
      ).questions
    );
    let newUtilityTypes = utlExpJSON[0].Answer;
    if (shelterSummary.utilityExpenses) {
      shelterSummary.utilityExpenses.map((util) => {
        let utilExpTemp = utilityExpensesTemplateData.filter(
          (utilTemp) => utilTemp.key === util.type
        )[0];
        if (utilExpTemp) {
          if (
            newUtilityTypes.includes(util.type) &&
            utilExpTemp.key === util.type
          ) {
            utilityExpenseObj[util.type] = TextProps.VALUE_TRUE;
            let changeCode = "NOCHNG";
            switch (util.code) {
              case "NEW":
              case "new":
                changeCode = "NEW";
                break;
              case "CHANGE":
              case "changed":
                changeCode = "CHANGE";
                break;
              case "REMOVE":
              case "removed":
                changeCode = "NOCHNG";
                break;
              default:
                break;
            }
            utilityExpenseObj[utilExpTemp.value] = changeCode;
          } else if (
            !newUtilityTypes.includes(util.type) &&
            utilExpTemp.key === util.type &&
            (util.code === "removed" || util.code === "existing")
          ) {
            utilityExpenseObj[utilExpTemp.key] = TextProps.VALUE_FALSE;
            utilityExpenseObj[utilExpTemp.value] = "REMOVE";
          } else if (newUtilityTypes.includes(utilExpTemp.key)) {
            utilityExpenseObj[utilExpTemp.key] = TextProps.VALUE_TRUE;
            utilityExpenseObj[utilExpTemp.value] = "NEW";
          }
        }
      });
      newUtilityTypes && newUtilityTypes.length &&
        newUtilityTypes.map((util) => {
          let sumExpTemp = shelterSummary.utilityExpenses.filter(
            (sumutil) => sumutil.type === util
          )[0];
          if (
            !sumExpTemp &&
            util !==
              incomeSectionMultilanguageLabels(language)
                .noUtilityQOptionSelected
          ) {
            let utilExpTemp = utilityExpensesTemplateData.filter(
              (utilTemp) =>  {
                if(utilTemp.key === util){
                  return utilTemp;
                } else if(util.includes('ac')) {
                  return utilTemp;
                }
              }
            )[0];
            utilityExpenseObj[utilExpTemp.key] = TextProps.VALUE_TRUE;
            utilityExpenseObj[utilExpTemp.value] = "NEW";
          }
          if (
            util ===
            incomeSectionMultilanguageLabels(language)
              .noUtilityQOptionSelected
          ) {
            let utilExpTemp = utilityExpensesTemplateData.filter(
              (utilTemp) => utilTemp.key === "noUtilityFlag"
            )[0];
            utilityExpenseObj[utilExpTemp.key] = TextProps.VALUE_TRUE;
            utilityExpenseObj[utilExpTemp.value] = "NEW";
          }
        });
    }
    utilityExpenseObj.liheapFlag = utlExpJSON[1].Answer
      ? utlExpJSON[1].Answer === TextProps.VALUE_YES
        ? true
        : false
      : null;
      if(recertType !== "IR"){
        utilityExpenseObj.lifelineFlag = utlExpJSON[2].Answer
        ? utlExpJSON[2].Answer === TextProps.VALUE_YES
          ? true
          : false
        : null;
      }
    const utilitySection = recertType === "IR" ? 'utilityExpense' : 'utilityExpenses';
    requestBody[utilitySection] = utilityExpenseObj;
  }


  //DEPENDENT CARE CHANGE
  if(recertType !== "IR"){
  if (
    filterData(recertObj.sections, "expenses") &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts").length &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .active === TextProps.VALUE_TRUE &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .subsections &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .subsections.length > 0
  ) {
    reportedChanges = TextProps.VALUE_TRUE;

    let newDepententCostArray = [];
    let dependentSection = _.cloneDeep(
      filterData(
        recertObj.sections,
        "expenses"
      ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
    );

    if (
      dependentSection.subsections !== undefined &&
      dependentSection.subsections.length > 0
    ) {
      //payee iterator
      if (
        dependentSection.questions[0].value !== undefined &&
        dependentSection.questions[0].value !== ""
      ) {
        let payees = dependentSection.questions[0].value;
        for (let index = 0; index < payees.length; index++) {
          let payee = payees[index];
          let subSectionData = dependentSection.subsections[index];
          let memberData = payee.split("'")[0];
          memberData = memberData.split(" ");
          let name = null;
          for (let currentVal of memberData) {
            if (currentVal.trim() === "") continue;
            if (name != null) name += " " + currentVal;
            else name = currentVal;
          }
          let memberObj = getMemberDataObjectByNameNew(
            memberData[0],
            memberData[1],
            recertObj.householdMembers
              ? recertObj.householdMembers.membersList
              : []
          );
          let indexOfPayee =
            dependentSection.questions[0].options.indexOf(payee);
          if (
            dependentSection.questions[0].conditionals[indexOfPayee]
              .conditionalQuestions[0].Answer
          ) {
            let dependents =
              dependentSection.questions[0].conditionals[indexOfPayee]
                .conditionalQuestions[0].Answer;
            for (let dependent of dependents) {
              let dependentName = null;
              let dependentArray = dependent.split(" ");
              for (let currentVal of dependentArray) {
                if (currentVal.trim() === "") continue;
                if (dependentName != null) dependentName += " " + currentVal;
                else dependentName = currentVal;
              }
              let dependentObj = getMemberDataObjectByNameNew(
                dependentArray[0],
                dependentArray[1],
                recertObj.householdMembers.membersList
              );

              let transportDetail = [];
              if (
                subSectionData.questions &&
                subSectionData.questions[2].Answer ===
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo[0]
              ) {
                for (
                  let k = 0;
                  k <
                  parseInt(
                    subSectionData.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer
                  );
                  k++
                ) {
                  let transportData = subSectionData.subsections[k];
                  if (transportData.questions[0].Answer) {
                    let transportObj = {
                      address: {
                        typeCode: "CARE",
                        line1: transportData.questions[0].Answer.addressLine1,
                        line2: transportData.questions[0].Answer.addressLine2,
                        city: transportData.questions[0].Answer.city,
                        state: transportData.questions[0].Answer.state,
                        zip: transportData.questions[0].Answer.zipCode,
                      },
                      driveTripCount:
                        transportData.questions[1].Answer.amount,
                      driveFrequencyCode:
                        transportData.questions[1].Answer.unit !== undefined
                          ? getFrequencyCode(language)[
                              transportData.questions[1].Answer.unit
                            ]
                          : "",
                    };
                    transportDetail.push(transportObj);
                  }
                }
              }

              let newDependentCost = {
                payee: {
                  agencyID: memberObj.agencyID,
                  firstName: memberObj.firstName,
                  lastName: memberObj.lastName,
                  dateOfBirth: convertDOBForDataPost(
                    memberObj.dateOfBirth
                  ),
                },
                dependent: {
                  agencyID: dependentObj.agencyID,
                  firstName: dependentObj.firstName,
                  lastName: dependentObj.lastName,
                  dateOfBirth: convertDOBForDataPost(
                    dependentObj.dateOfBirth
                  ),
                },
                providerCareAmount: subSectionData.questions[0].Answer
                  ? subSectionData.questions[0].Answer.amount
                  : "",
                providerCareFrequencyCode:
                  subSectionData.questions[0].Answer &&
                  subSectionData.questions[0].Answer.unit !== undefined
                    ? getFrequencyCode(language)[
                        subSectionData.questions[0].Answer.unit
                      ]
                    : "",
                transportationPayFlag:
                  subSectionData.questions[1].Answer ===
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo[0]
                    ? TextProps.VALUE_TRUE
                    : TextProps.VALUE_FALSE,
                transportationAmount:
                  subSectionData.questions[1].conditionals[0]
                    .conditionalQuestions[0].Answer &&
                  subSectionData.questions[1].conditionals[0]
                    .conditionalQuestions[0].Answer.amount !== undefined
                    ? subSectionData.questions[1].conditionals[0]
                        .conditionalQuestions[0].Answer.amount
                    : "",
                transportationFrequencyCode:
                  subSectionData.questions[1].conditionals[0]
                    .conditionalQuestions[0].Answer &&
                  subSectionData.questions[1].conditionals[0]
                    .conditionalQuestions[0].Answer.unit !== undefined
                    ? getFrequencyCode(language)[
                        subSectionData.questions[1].conditionals[0]
                          .conditionalQuestions[0].Answer.unit
                      ]
                    : "",
                drivetoProviderFlag:
                  subSectionData.questions[2].Answer ===
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo[0]
                    ? TextProps.VALUE_TRUE
                    : TextProps.VALUE_FALSE,
                drivetoProviderCount: parseInt(
                  subSectionData.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer
                ),
                transportDetails: transportDetail,
              };
              newDepententCostArray.push(newDependentCost);
            }
          }
        }
      }
    }

    requestBody.dependentCareExpenses = newDepententCostArray;
  } else if (
    filterData(recertObj.sections, "expenses") &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts").length &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .active === TextProps.VALUE_FALSE &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .hiddenFromNav === TextProps.VALUE_TRUE
  ) {
    requestBody.dependentCareExpenses = null;
  } else if (
    filterData(recertObj.sections, "expenses") &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts").length &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .questions[0].Answer &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .questions[0].Answer.length > 0 &&
    filterData(
      recertObj.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .questions[0].Answer[0] ===
      recertificationPageLabels(
        language,
        null,
        languageConstants.incomeExpensesRecert
      ).questionClearOption
  ) {
    requestBody.dependentCareExpenses = [{}];
  } else {
    requestBody.dependentCareExpenses = [];
  }
  }
  //CHILE EXP CHANGE
  let childSupportSection = recertType === "IR" ? 
  filterData(recertObj.sections, "childsupport-expenses")
  : filterData(recertObj.sections, "expenses");
  if (
    (childSupportSection &&
      recertObj.courtOrderedChildSupportChanged !== undefined &&
      recertObj.courtOrderedChildSupportChanged ===
        TextProps.VALUE_TRUE) ||
    (childSupportSection &&
      recertObj.obligationChildSupportChanged !== undefined &&
      recertObj.obligationChildSupportChanged ===
        TextProps.VALUE_TRUE)
  ) {
    // let childExpenseNew =_.cloneDeep(
    //   recertObj.childExpScreen.tempChildExpArray
    // );
    let childExpenseNew = _.cloneDeep(
      incomeSummary.childSupportIncomeMembers
    );
    let childExpenseExisting = _.cloneDeep(
      recertObj.responseObject.childSupportExpense
    );
    let ChildArrayNew = [];
    if (childExpenseNew) {
      childExpenseNew.map((member) => {
        let memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          childExpenseExisting
        );
        if (memberObj === null) {
          memberObj = getMemberDataObjectByNameAndDOB(
            member.firstName,
            recertObj.householdMembers.membersList
          );
        }
        let changeCode = "NOCHNG";
        switch (member.incomes[0].Answer.code) {
          case "new":
            changeCode = "NEW";
            break;
          case "changed":
            changeCode = "CHANGE";
            break;
          case "removed":
            changeCode = "REMOVE";
            break;
          default:
            break;
        }
        if (changeCode !== "NOCHNG") reportedChanges = TextProps.VALUE_TRUE;
        let childObj = {
          id: memberObj.id ? memberObj.id : 0,
          setID: memberObj.setID ? memberObj.setID : 0,
          agencyID: memberObj.agencyID ? memberObj.agencyID : 0,
          firstName: memberObj.firstName,
          lastName: memberObj.lastName,
          dateOfBirth: convertDOBForDataPost(memberObj.dateOfBirth),
          typeCode: memberObj.typeCode ? memberObj.typeCode : "CHDSPT",
          frequencyCode:
            getFrequencyCode(language)[member.incomes[0].Answer.unit],
          amount: member.incomes[0].Answer.amount,
          dataChangeCode: changeCode,
        };
        ChildArrayNew.push(childObj);
      });
    }

    let finalArray = _.cloneDeep(ChildArrayNew);
    childExpenseExisting.map((existing) => {
      existing.dateOfBirth = convertDOBForDataPost(existing.dateOfBirth);
      let found = TextProps.VALUE_FALSE;
      for (let newChildExp of ChildArrayNew) {
        if (
          newChildExp.id === existing.id &&
          newChildExp.agencyID === existing.agencyID &&
          newChildExp.setID === existing.setID
        ) {
          found = TextProps.VALUE_TRUE;
        }
      }
      if (
        found === TextProps.VALUE_FALSE &&
        existing.dataChangeCode !== "NEW"
      ) {
        existing.dataChangeCode = "REMOVE";
        finalArray.push(existing);
      }
    });
    if(recertType !== "REEVAL") {
      requestBody.childSupportExpense = finalArray;
    }
  } else {
    if(recertType !== "REEVAL") {
    requestBody.childSupportExpense = recertObj.responseObject
      .childSupportExpense
      ? recertObj.responseObject.childSupportExpense
      : [];
    }
  }

  //Data Submission for EarnedIncome and UnearnedIncome
  //EARNED INCOME CHANGE
  if (
    (filterData(recertObj.sections, "income-summary") &&
      recertObj.earnedIncomeChanged !== undefined &&
      recertObj.earnedIncomeChanged === TextProps.VALUE_TRUE) ||
    (recertObj.grossEarnedIncomeChanged !== undefined &&
      recertObj.grossEarnedIncomeChanged === TextProps.VALUE_TRUE)
  ) {
    let allIncome = _.cloneDeep(incomeSummary.incomeMembers);
    let incomeArrayExisting = _.cloneDeep(
      recertObj.responseObject.earnedIncome
    );

    let incomeArrayNew = [];
    allIncome.map((member) => {
      let memberObj = getMemberDataObjectByNameAndDOB(
        member.firstName,
        incomeArrayExisting
      );
      if (memberObj === null) {
        memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          recertObj.householdMembers.membersList
        );
      }
      if (member.incomes !== undefined) {
        member.incomes.map((income) => {
          let typeCode = null;
          if (
            income !== undefined &&
            income.Answer !== undefined &&
            income.Answer.length > 0 &&
            member.incomeType.includes(income.typeCode)
          ) {
            income.Answer.map((incomeSub) => {
              let amountArray = [];
              let amountIterator = 1;
              if (incomeSub.amount) {
                if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                  amountIterator = 4;
                } else if (
                  getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                ) {
                  amountIterator = 2;
                }
                for (let i = 0; i < amountIterator; i++) {
                  amountArray.push({
                    amount: incomeSub.amount[i],
                  });
                }
              }

              let changeCode = "NOCHNG";
              switch (incomeSub.code) {
                case "new":
                  changeCode = "NEW";
                  break;
                case "changed":
                  changeCode = "CHANGE";
                  break;
                case "removed":
                  changeCode = "REMOVE";
                  break;
                default:
                  break;
              }

              typeCode = income.typeCode;

              let incomeObj = {
                id: returnMemberId(member, incomeSub),
                setID:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.setID
                    ? incomeSub.fullIncomeObj.setID
                    : 0,
                agencyID: returnAgencyID(memberObj, incomeSub),
                firstName:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.firstName
                    ? incomeSub.fullIncomeObj.firstName
                    : memberObj.firstName,
                lastName:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.lastName
                    ? incomeSub.fullIncomeObj.lastName
                    : memberObj.lastName,
                dateOfBirth: convertDOBForDataPost(
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.dateOfBirth
                    ? incomeSub.fullIncomeObj.dateOfBirth
                    : memberObj.dateOfBirth
                ),
                typeCode: typeCode,
                employerName: incomeSub.employer,
                frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                amounts: amountArray,
                dataChangeCode: changeCode,
              };

              incomeArrayNew.push(incomeObj);
            }); // income sub ends
          } else if (member.incomeType.includes(income.typeCode)) {
            let incomeObj = {
              id: 0,
              setID: 0,
              agencyID: memberObj.agencyID,
              firstName: memberObj.firstName,
              lastName: memberObj.lastName,
              dateOfBirth: memberObj.dateOfBirth,
              typeCode: income.typeCode,
              employerName: "",
              frequencyCode: "",
              amounts: [
                {
                  amount: "",
                },
              ],
              dataChangeCode: "NEW",
            };
            incomeArrayNew.push(incomeObj);
          }
        }); // income ends
      }
    }); // member ends

    var finalArray = _.cloneDeep(incomeArrayNew);
    incomeArrayExisting.map((existing) => {
      existing.dateOfBirth = convertDOBForDataPost(existing.dateOfBirth);

      let found = TextProps.VALUE_FALSE;
      for (let newIncome of incomeArrayNew) {
        if (
          newIncome.id === existing.id &&
          newIncome.agencyID === existing.agencyID &&
          newIncome.typeCode === existing.typeCode
        ) {
          found = TextProps.VALUE_TRUE;
        }
      }
      if (
        found === TextProps.VALUE_FALSE &&
        existing.dataChangeCode === "NOCHNG"
      ) {
        existing.dataChangeCode = "REMOVE";
        finalArray.push(existing);
      }
    });
    requestBody.earnedIncome = finalArray;
  }

  if (requestBody.earnedIncome.length > 0) {
    let newHouseholdArray = [];
    requestBody.earnedIncome.map((member) => {
      member.dateOfBirth = convertDOBForDataPost(member.dateOfBirth);
      if (member.dataChangeCode === undefined) {
        member.dataChangeCode = "NOCHNG";
      } else if (member.dataChangeCode !== "NOCHNG") {
        reportedChanges = TextProps.VALUE_TRUE;
      }
      if (member.id <= 1) {
        member.dataChangeCode = "NEW";
      }
      newHouseholdArray.push(member);
    });
    requestBody.earnedIncome = newHouseholdArray;
  }

  //UNEARNED INCOME CHANGE
  if (
    filterData(recertObj.sections, "unearned-income-summary") &&
    recertObj.unearnedIncomeChanged !== undefined &&
    recertObj.unearnedIncomeChanged === TextProps.VALUE_TRUE
  ) {
    let allIncome = _.cloneDeep(incomeSummary.unearnedIncomeMembers);
    let incomeArrayExisting = _.cloneDeep(
      recertObj.responseObject.unearnedIncome
    );

    let incomeArrayNew = [];
    allIncome.map((member) => {
      let memberObj = getMemberDataObjectByNameAndDOB(
        member.firstName,
        incomeArrayExisting
      );
      if (memberObj === null) {
        memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          recertObj.householdMembers.membersList
        );
      }
      if (member.incomes !== undefined) {
        member.incomes.map((income, index) => {
          let typeCode = null;
          if (
            income !== undefined &&
            income.Answer !== undefined &&
            income.Answer.length > 0 &&
            member.incomeType.includes(income.typeCode)
          ) {
            income.Answer.map((incomeSub) => {
              let amountArray = [];
              let amountIterator = 1;
              if (incomeSub.amount) {
                if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                  amountIterator = 4;
                } else if (
                  getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                ) {
                  amountIterator = 2;
                }

                for (let i = 0; i < amountIterator; i++) {
                  amountArray.push({
                    amount: incomeSub.amount[i],
                  });
                }
              }
              let changeCode = "NOCHNG";
              switch (incomeSub.code) {
                case "new":
                  changeCode = "NEW";
                  break;
                case "changed":
                  changeCode = "CHANGE";
                  break;
                case "removed":
                  changeCode = "REMOVE";
                  break;
                default:
                  break;
              }
              typeCode = income.typeCode;
              let incomeObj = {
                id: returnMemberId(member, incomeSub),
                setID:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.setID
                    ? incomeSub.fullIncomeObj.setID
                    : 0,
                agencyID: returnAgencyID(memberObj, incomeSub),
                firstName:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.firstName
                    ? incomeSub.fullIncomeObj.firstName
                    : memberObj.firstName,
                lastName:
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.lastName
                    ? incomeSub.fullIncomeObj.lastName
                    : memberObj.lastName,
                dateOfBirth: convertDOBForDataPost(
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.dateOfBirth
                    ? incomeSub.fullIncomeObj.dateOfBirth
                    : memberObj.dateOfBirth
                ),
                typeCode: typeCode,
                frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                amounts: amountArray,
                dataChangeCode: changeCode,
              };
              incomeArrayNew.push(incomeObj);
            }); // income sub ends
          } else if (member.incomeType.includes(income.typeCode)) {
            let incomeObj = {
              id: 0,
              setID: 0,
              agencyID: memberObj.agencyID,
              firstName: memberObj.firstName,
              lastName: memberObj.lastName,
              dateOfBirth: memberObj.dateOfBirth,
              typeCode: income.typeCode,
              frequencyCode: "",
              amounts: [
                {
                  amount: "",
                },
              ],
              dataChangeCode: "NEW",
            };
            incomeArrayNew.push(incomeObj);
          }
        }); // income ends
      }
    }); // member ends

    console.log(incomeArrayNew);
    var finalArray = _.cloneDeep(incomeArrayNew);
    incomeArrayExisting.map((existing) => {
      existing.dateOfBirth = convertDOBForDataPost(existing.dateOfBirth);
      let found = TextProps.VALUE_FALSE;
      for (let newIncome of incomeArrayNew) {
        if (
          newIncome.id === existing.id &&
          newIncome.agencyID === existing.agencyID &&
          newIncome.setID === existing.setID
        ) {
          found = TextProps.VALUE_TRUE;
        }
      }
      if (
        found === TextProps.VALUE_FALSE &&
        existing.dataChangeCode === "NOCHNG"
      ) {
        existing.dataChangeCode = "REMOVE";
        finalArray.push(existing);
      }
    });
    requestBody.unearnedIncome = finalArray;
  }

  if (requestBody.unearnedIncome.length > 0) {
    let newHouseholdArray = [];
    for (let member of requestBody.unearnedIncome) {
      member.dateOfBirth = convertDOBForDataPost(member.dateOfBirth);
      if (member.dataChangeCode === undefined) {
        member.dataChangeCode = "NOCHNG";
      } else if (member.dataChangeCode !== "NOCHNG") {
        reportedChanges = TextProps.VALUE_TRUE;
      }
      if (member.id <= 1) {
        member.dataChangeCode = "NEW";
      }
      newHouseholdArray.push(member);
    }
    requestBody.unearnedIncome = newHouseholdArray;
  }

  // MEDICAL INFO CHANGE
  if(recertType !== "REEVAL" && recertType !== "IR") {
    if (
      recertObj.medicalExpensesChanged === TextProps.VALUE_TRUE &&
      Object.keys(medicalSummary).length &&
      medicalSummary.summaryData.length
    ) {
      let summaryData = medicalSummary.summaryData;
      let medicalSummaryKey = _.cloneDeep(medicalExpenseKeys);
      let medicalExpensesData = [];
      summaryData.map((item) => {
        let medicalSummaryDataMap = _.cloneDeep(medicalExpenseObj);
        recertObj.householdMembers.membersList.map((mem) => {
          let user = [
            mem["firstName"],
            mem["lastName"],
            formatters.formatDate(mem["dateOfBirth"]),
          ]
            .join(" ")
            .replace(/ +(?= )/g, "");
          if (user == item.name) {
            medicalSummaryDataMap = Object.assign(medicalSummaryDataMap, {
              dateOfBirth: convertDOBForDataPost(mem.dateOfBirth),
              agencyID: mem.agencyID,
              firstName: mem.firstName,
              lastName: mem.lastName,
              dataChangeCode: mem.dataChangeCode,
            });
          }
        });
        item.medicalExpenses.map((exp) => {
          let expensesKeys = medicalSummaryKey[exp.typeCode];
          let expensesData = {
            [expensesKeys.flag]: true,
            [expensesKeys.amount]: exp.amount,
            [expensesKeys.unit]: exp.unit,
            [expensesKeys.code]: exp.lable,
          };
          medicalSummaryDataMap = Object.assign(
            medicalSummaryDataMap,
            expensesData
          );
        });
        medicalExpensesData.push(medicalSummaryDataMap);
      });
      requestBody.medicalExpenses = medicalExpensesData;
      }else{
        requestBody.medicalExpenses = [];
    }
  }
  if (reportedChanges) {
    requestBody.dataChangeCode = "CHANGE";
    requestBody.languageCode = language.split("-")[0];
    requestBody.signedName =
      refs.applicanSign && refs.applicanSign.inputEl.value;
    requestBody.webAppTypeCode = recertType === "REEVAL" ? "TNFRCT" : recertType === "IR" ? "SNAPIR"  : "SNPRCT";
  } else {
    requestBody.dataChangeCode = "NOCHNG";
    requestBody.languageCode = language.split("-")[0];
    requestBody.signedName =
      refs.applicanSign && refs.applicanSign.inputEl.value;
    requestBody.webAppTypeCode = recertType === "REEVAL" ? "TNFRCT" : recertType === "IR" ? "SNAPIR"  : "SNPRCT";
  }
  return requestBody;
}