export const NAME = "name";
export const DATE = "date";
export const LOCATION = "location";
export const STATUS = "eventStatus";
export const STATUS_CLOSE = "READ_STATUS";
export const STATUS_NOT_CLOSE = "READ_STATUS";
export const URL = "eventUrl";
export const TYPE = "type";
export const ACTIVITY_TYPE = "activityType";
export const PROGRAM_TYPE = "programTypeCode";
export const REEVAL_END_DATE = "dueDate";
export const START_TIME = "startTime";
export const END_TIME = "endTime";
export const BU_OFFICE = "buOffice";
export const RESCHEDULE_STATUS = "appointmentChangeRequestStatusCode";

export const NEWSTATUS = "readStatus";
export const NEWURL = "eventUrl";
export const NEWTYPECODE = "typeCode"; //typecode
export const NEW_START_TIME = "startTime";
export const NEW_END_TIME = "endTime";
export const NEW_BU_OFFICE = "location";
export const CASE_TYPE = "caseType";
export const CHANGE_ALLOWED_FLAG = "changeAllowedFlg";
export const DUE_DATE = "caseDueDate";
export const PROVIDER_NAME = "caseManagerName";
