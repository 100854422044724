import * as Types from "./types";
import * as Props from "../components/shared";
import * as InputProps from "./input";
import * as SelectProps from "./select";
import * as OptionsProps from "./options";
import * as consumerLabels from "../../pages/consumer/consumerText";
import * as helperFunction from "../components/helperFunctions/helperFunctions";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const SEARCH = {
  [Props.NAME]: "search-default",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).search,
  [Props.TYPE]: Types.INPUT_SEARCH,
  [InputProps.PLACEHOLDER]: "Search",
};

export const SEARCH_MAP = {
  [Props.NAME]: "search-map",
  [Props.LABEL]: "Search any address in Alabama",
  [Props.TYPE]: Types.INPUT_SEARCH,
  [InputProps.PLACEHOLDER]: "Search any address in Alabama",
};

export const FILTER_DOCUMENT_TYPE = {
  [Props.NAME]: "filter-document-type",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).docType,
  [Props.TYPE]: Types.SELECT,
  [SelectProps.OPTIONS]: [],
};

const DTA_OFFICE_DISPLAY = "DHR Offices",
  ATM_DISPLAY = "A.T.M.s that accept E.B.T.",
  MARKET_DISPLAY = "Farmers' Markets that accept E.B.T.",
  DTA_OFFICE_VALUE = "dta-offices",
  ATM_VALUE = "atms",
  MARKET_VALUE = "farmers-markets";

export const FILTER_LOCATIONS = {
  [Props.NAME]: "filter-locations",
  [Props.LABEL]: "Search for...",
  [Props.TYPE]: Types.CHECKBOX,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]: DTA_OFFICE_VALUE,
      [OptionsProps.OPTION_DISPLAY]: DTA_OFFICE_DISPLAY,
    },
    {
      [OptionsProps.OPTION_VALUE]: ATM_VALUE,
      [OptionsProps.OPTION_DISPLAY]: ATM_DISPLAY,
    },
    {
      [OptionsProps.OPTION_VALUE]: MARKET_VALUE,
      [OptionsProps.OPTION_DISPLAY]: MARKET_DISPLAY,
    },
  ],
};

export const FILTER_DATE = {
  [Props.NAME]: "filter-date-range",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).dateRange,
  [Props.TYPE]: Types.DATE_RANGE,
  [Props.BENEFIT_TARGET]: "Benefits",
};

export const FILTER_DATE_ACTIVITY = {
  [Props.NAME]: "filter-date-range",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).dateRange,
  [Props.TYPE]: Types.DATE_RANGE,
  [Props.BENEFIT_TARGET]: "Activity",
};

export const FILTER_DATE_DOCUMENT = {
  [Props.NAME]: "filter-date-range",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).dateRange,
  [Props.TYPE]: Types.DATE_RANGE,
  [Props.BENEFIT_TARGET]: "Document",
};

export const FILTER_DATE_NOTIFICATION = {
  [Props.NAME]: "filter-date-range",
  [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).dateRange,
  [Props.TYPE]: Types.DATE_RANGE,
  [Props.BENEFIT_TARGET]: "Notification",
};
export {
  DTA_OFFICE_DISPLAY,
  ATM_DISPLAY,
  MARKET_DISPLAY,
  DTA_OFFICE_VALUE,
  ATM_VALUE,
  MARKET_VALUE,
};
