import React, { Component } from "react";
import PropTypes from "prop-types";

import Options from "./options";
import {
  addStringIf,
  buildObjectFromArray,
  tryCall,
} from "../components/utilities/controls";
import { OptionsTypes, OptionsDefaults } from "./propTypes";
import { RADIO_CLASSES } from "../../utils/constants/controls";
import * as TextProps from "../../utils/constants/text";
class Radio extends Component {
  static propTypes = {
    ...OptionsTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  static defaultProps = {
    ...OptionsDefaults,
  };

  constructor(props) {
    super(...arguments);

    this.state = {
      _value: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        _value: nextProps.value,
      });
    }
  }

  render() {
    if (this.state._value === undefined || this.state._value === "") {
      this.state._value = TextProps.VALUE_TRUE;
    }
    return (
      <Options
        {...RADIO_CLASSES}
        {...this.props} // must be after default classes to allow overriding
        value={this.state._value}
        onChange={this._handleChange}
        doBuildState={this._doBuildState}
        doBuildValue={this._doBuildValue}
        doInitializeCleared={this._checkIsCleared}
        inputType="radio"
        optionClass={addStringIf(
          this.props.optionClass,
          RADIO_CLASSES.optionClass
        )}
      />
    );
  }

  // Rendering
  // ---------

  _doBuildState = (selected, clearLabel, options) => {
    return this._checkIsCleared(selected, clearLabel)
      ? buildObjectFromArray(options, TextProps.VALUE_FALSE)
      : {
          ...buildObjectFromArray(options, TextProps.VALUE_FALSE),
          ...(selected ? { [selected]: TextProps.VALUE_TRUE } : {}),
        };
  };

  _doBuildValue(value) {
    return value;
  }

  _checkIsCleared(value, clearLabel) {
    return value === clearLabel;
  }
  // Events
  // ------

  _handleChange = (value) => {
    this.setState({ _value: value });
    tryCall(this.props.onChange, value);
  };
}

export default Radio;
