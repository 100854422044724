import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { tryCall } from "./utilities/controls";
import _ from "lodash";
import { screeningStaticLabels } from "../../pages/screening/screeningText";
import {
  INPUT_ALPHA_TEXT,
  INPUT_ALPHANUMERIC_SPACE_TEXT,
  STATES,
  STATES_FULL,
  AL_STATES,
  AL_STATE,
  COUNTRY,
} from "../constants/controls";
import { ZIPCODE } from "../constants/zipCode";
import Radio from "./radio";
import DTAModal from "../../utils/components/modal/dtaModal";
import { interimReportPageLabels } from "../../pages/recertInterimReport/recertInterimReportText";
import * as languageConstants from "../constants/constants";
import { isMailMyInfo } from "../../redux/consumer/consumerMyInfoActions";
import { addressValidation } from "../../redux/applySnap/applySnapActions";
import { COUNTYCODE } from "../../utils/constants/countyCode";
import { ALL_STATES_COUNTY } from "../../utils/constants/statesCountyCode";
//applysnapInfoAction
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import * as languageConsts from "../../utils/constants/types";

import { langOptSel } from "../constants/constants";
let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (selectedLanguage === null || selectedLanguage === langOptSel ) {
    selectedLanguage = "en-US";
  }

let countyIdArray = [];
let countyValueArray = [];
class Address extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onZipCodeChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      county: PropTypes.array,
      verified:PropTypes.string,
      state: PropTypes.oneOf(["", ...STATES]),
      zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    //setting Default MA State
    let copyProps = _.cloneDeep(props);
    this.state = this._buildStateFromProps(copyProps);
    countyIdArray =[];
    countyValueArray =[];
    let stateValue = this.state._value.state ? this.state._value.state : AL_STATE;
    ALL_STATES_COUNTY.find((obj) => {
      if (obj.state == stateValue) {
        countyValueArray.push(obj.value);
        countyIdArray.push(obj.code);
      }
    });
    this.state.countyIdArray = countyValueArray;
    this.state.countyValueArray = countyValueArray.sort();
    let stateFull = [];
    ALL_STATES_COUNTY.forEach((c) => {
      stateFull.push(c.state);
    });
    let uniqueState = stateFull.filter(
      (item, index) => stateFull.indexOf(item) === index
    ); // remove duplicate
    this.state.stateFull = uniqueState.sort();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this._buildStateFromProps(nextProps));
    }
  }

  isMail(resAddrMatchFlag) {
    var mailMyInfo = sessionStorage.getItem("mailMyInfo");
    if (mailMyInfo === null && resAddrMatchFlag === undefined) {
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_FALSE);
      this.props.isMailMyInfo();
    } else if (
      (mailMyInfo === null && !resAddrMatchFlag) ||
      (mailMyInfo === TextProps.VALUE_STR_FALSE && !resAddrMatchFlag) ||
      (mailMyInfo === TextProps.VALUE_STR_FALSE && resAddrMatchFlag)
    ) {
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_TRUE);
      this.props.isMailMyInfo();
    } else if (
      (mailMyInfo === "true" && resAddrMatchFlag) ||
      (mailMyInfo === null && resAddrMatchFlag) ||
      (mailMyInfo === TextProps.VALUE_STR_FALSE && !resAddrMatchFlag)
    ) {
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_FALSE);
      this.props.isMailMyInfo();
    }
  }

  render() {
    const { error, disabled, required, name, className, isResidentialAddress } =
        this.props,
      ariaLabels = this.props["aria-labelledby"];
    const { resAddrMatchFlag } = this.props.data;
    return (
      <div className={className}>
        {this._buildFirstLine(name, ariaLabels, error, disabled, required)}
        {this._buildSecondLine(
          name,
          ariaLabels,
          error,
          disabled,
          required,
          isResidentialAddress
        )}
        {name === "residentialAddress"
          ? this._buildThirdLine(name, resAddrMatchFlag)
          : null}
      </div>
    );
  }

  // Rendering
  _buildFirstLine(name, ariaLabels, error, disabled, required) {
    return (
      <div className="pure-g margin-bottom-half">
        {
          <div className="pure-u-1-1">
            <div>
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {screeningStaticLabels(selectedLanguage).streetAddr}
              </label>
              <DTAInput
                name={"addressLine1"}
                type={INPUT_ALPHANUMERIC_SPACE_TEXT}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForAddressLine1}
                value={this.state._value.addressLine1}
                error={error}
                disabled={disabled}
                required={required}
                maxLength={40}
              />
            </div>
            <br />
            <div className="pure-u-2-3">
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {screeningStaticLabels(selectedLanguage).addrLine2}
              </label>
              <DTAInput
                name={name}
                type={INPUT_ALPHANUMERIC_SPACE_TEXT}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForAddressLine2}
                value={this.state._value.addressLine2}
                error={error}
                disabled={disabled}
                required={required}
                maxLength={40}
              />
            </div>
            <div className="pure-u-1-3 pad-left-half">
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {screeningStaticLabels(selectedLanguage).cityLabel}
              </label>
              <DTAInput
                name={name}
                type={INPUT_ALPHA_TEXT}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForCity}
                value={this.state._value.city}
                error={error}
                disabled={disabled}
                required={required}
                maxLength={30}
              />
            </div>
          </div>
        }
      </div>
    );
  }

  _buildSecondLine(
    name,
    ariaLabels,
    error,
    disabled,
    required,
    isResidentialAddress
  ) {
    if (this.state._value.state === "" || isResidentialAddress) {
      this.state._value.state = TextProps.DEFAULT_STATE;
    }
    return (
      <div>
        {
          <div className="pure-g">
            <div className="pure-u-2-3">
              <div className="pure-g">
                <div className="pure-u-1-5 pad-right-half">
                  <label
                    className="dta-form__label dta-form__label--size-small"
                    htmlFor={this.nextUniqueId()}
                  >
                    {screeningStaticLabels(selectedLanguage).stateLabel}
                  </label>
                  <DTASelect
                    name={name}
                    options={
                      isResidentialAddress ? AL_STATES : this.state.stateFull
                    }
                    className="dta-select--expand-width"
                    id={this.lastUniqueId()}
                    aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                    value={
                      isResidentialAddress ? TextProps.DEFAULT_STATE : this.state._value.state
                    }
                    onChange={this._handleChangeForState}
                    error={error}
                    required={required}
                    disabled={isResidentialAddress ? true : false}
                  />
                </div>
                <div className="pure-u-2-5 pad-right-half">
                  <label
                    className="dta-form__label dta-form__label--size-small"
                    htmlFor={this.nextUniqueId()}
                  >
                    {screeningStaticLabels(selectedLanguage).county}
                  </label>
                  <DTASelect
                    name={name}
                    options={this.state.countyValueArray}
                    onChange={this._handleChangeForCounty}
                    className="dta-select--expand-width"
                    id={this.lastUniqueId()}
                    aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                    value={this.state._value.county}
                    error={error}
                    required={required}
                  />
                </div>
                <div className="pure-u-2-5">
                  <label
                    className="dta-form__label dta-form__label--size-small"
                    htmlFor={this.nextUniqueId()}
                  >
                    {screeningStaticLabels(selectedLanguage).zipCode}
                  </label>
                  <DTAInput
                    name={name}
                    type="number"
                    blocks={[5]}
                    numericOnly={TextProps.VALUE_TRUE}
                    className="dta-input--expand-width"
                    id={this.lastUniqueId()}
                    aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                    onChange={this._handleChangeForZipCode}
                    value={this.state._value.zipCode}
                    error={error}
                    disabled={disabled}
                    required={required}
                  />
                </div>
              </div>
            </div>
            <div className="pure-u-1-3 pad-left-half">
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {screeningStaticLabels(selectedLanguage).country}
              </label>
              <DTAInput
                name={name}
                type="text"
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                value="USA"
                error={error}
                disabled={true}
                required={required}
              />
            </div>
          </div>
        }
      </div>
    );
  }

  _buildThirdLine(name, resAddrMatchFlag) {
    var yes;
    if (
      (name === "residentialAddress" && resAddrMatchFlag) ||
      resAddrMatchFlag === undefined
    ) {
      yes = 0;
    } else if (name === "residentialAddress" && !resAddrMatchFlag) {
      yes = 1;
    }
    return (
      <div className="pure-g margin-bottom-half is-mail-address">
        <div className="pure-u-1">
          <label
            className="dta-form__label dta-form__label--size-small"
            htmlFor={this.nextUniqueId()}
          >
            {
              interimReportPageLabels(
                selectedLanguage,
                languageConstants.aboutMe
              ).alsoMailingLabel
            }
          </label>
          <Radio
            name="contact-info-changed"
            className="dta-form__option-list"
            optionClass="dta-form__option dta-form__option--width-1-of-2"
            options={
              interimReportPageLabels(
                selectedLanguage,
                languageConstants.aboutMe
              ).mailOptions
            }
            required={TextProps.VALUE_TRUE}
            value={
              interimReportPageLabels(
                selectedLanguage,
                languageConstants.aboutMe
              ).mailOptions[yes]
            }
            onChange={() => {
              this.isMail(resAddrMatchFlag);
            }}
          />
        </div>
      </div>
    );
  }

  // Events
  _handleChangeForStreet = (streetValue) => {
    const newValue = {
      addressLine1: streetValue,
      addressLine2: this.state._value.addressLine2,
      city: this.state._value.city,
      county: this.state._value.county,
      verified:this.state._value.verified,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      countyCode: this.state._value.countyCode,
      //verified:'N',
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForAddressLine1 = (addressLine1Value) => {
    const newValue = {
      addressLine1: addressLine1Value,
      addressLine2: this.state._value.addressLine2,
      city: this.state._value.city,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      county: this.state._value.county,
      verified: this.state._value.verified,
      countyCode: this.state._value.countyCode,
      //verified: 'N',
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForAddressLine2 = (addressLine2Value) => {
    const newValue = {
      addressLine1: this.state._value.addressLine1,
      addressLine2: addressLine2Value,
      city: this.state._value.city,
      county: this.state._value.county,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      verified: this.state._value.verified,
      countyCode: this.state._value.countyCode,
      //verified: 'N',
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForZipCode = (zipValue) => {
    let city,
      cityArr = [];
    ZIPCODE.find((obj) => {
      if (obj.ZIPCODE === zipValue) {
        if (obj.CITY.length > 0) {
          city = obj.CITY;
        } else {
          city = "";
        }
      }
    });
    const newValue = {
      addressLine1: this.state._value.addressLine1,
      city: city === undefined ? this.state._value.city : city,
      county: this.state._value.county,
      addressLine1: this.state._value.addressLine1,
      addressLine2: this.state._value.addressLine2,
      state: this.state._value.state,
      verified: this.state._value.verified,
      countyCode: this.state._value.countyCode,
      zipCode: zipValue,
      //verified: 'N',
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
    tryCall(this.props.onZipCodeChange, zipValue);
  };

  _handleChangeForCity = (cityValue) => {
    const newValue = {
      addressLine1: this.state._value.addressLine1,
      addressLine2: this.state._value.addressLine2,
      county: this.state._value.county,
      city: cityValue,
      state: this.state._value.state,
      countyCode: this.state._value.countyCode,
      zipCode: this.state._value.zipCode,
      verified: this.state._value.verified,
      //verified: "N",
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForState = (state) => {
    let stateCounty  =[];
    let stateCountyCode = [];
    ALL_STATES_COUNTY.find((obj) => {
      if (obj.state == state) {
        stateCounty.push(obj.value);
        stateCountyCode.push(obj.code);
      }
    });
    countyIdArray = stateCounty;
    countyValueArray = stateCountyCode;
    this.state.countyIdArray = stateCounty;
    this.state.countyValueArray = stateCounty;
    //this.setState({ countyIdArray: countyIdArray });
    //this.setState({ countyValueArray: countyValueArray });
    const newValue = {
      addressLine1: this.state._value.addressLine1,
      addressLine2: this.state._value.addressLine2,
      city: this.state._value.city,
      county: '',
      state: state,
      zipCode: this.state._value.zipCode,
      verified: this.state._value.verified,
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForCounty = (countyValue) => {
    let county, countyCode;
    ALL_STATES_COUNTY.find((obj) => {
      if (obj.value == countyValue && obj.state == this.state._value.state) {
        county = obj.value;
        countyCode = obj.code;
      }
    });
    const newValue = {
      addressLine1: this.state._value.addressLine1,
      addressLine2: this.state._value.addressLine2,
      city: this.state._value.city,
      county: countyValue,
      countyCode:countyCode,
      state: this.state._value.state,
      zipCode: this.state._value.zipCode,
      verified: this.state._value.verified,
      //verified: 'N',
      //stepVerified:'N'
    };
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  // Helpers
  _buildStateFromProps(props) {
    if (props.value) {
      return {
        _value: props.value,
      };
    } else {
      return {
        _value: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
          county: "",
          countyCode: "",
          userStreet: "",
          userCity: "",
          userState: "",
          userZipCode: "",
          userCounty: "",
          melisaline1: "",
          melisaline2: "",
          melisaCity: "",
          melisaState: "",
          melisaZipCode: "",
          melisaCounty: "",
          verified: "N",
          userEnteredAddress: "",
          suggestedResAddress: "",
          stateFull: [],
          addressOptions: [],
          countyIdArray: [],
          countyValueArray: [],
          stepVerified: "N",
        },
      };
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      isMailMyInfo: isMailMyInfo,
      addressValidation: addressValidation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Address);
