import "babel-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import App from "./app";
import registerServiceWorker from "./utils/helper/registerServiceWorkerHelper";
import { snapStore, history } from "./redux/configureStore";

// azure integration
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config/appConfig";

// azure instance initialization
const msalInstance = new PublicClientApplication(msalConfig);

//Main DOM Render
const root = createRoot(document.getElementById("root"));
//Main DOM Render
root.render(
  <Provider store={snapStore}>
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ConnectedRouter>
  </Provider>
);
registerServiceWorker();
