import React from 'react';
import { recertificationPageLabels } from './recertificationText';
import * as languageConstants from "../../utils/constants/constants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as formatters from "../../utils/components/utilities/formatters";
const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
export const SummaryData = (authorizedRepresentatives, ebtStateFlg, snpStateFlg, ebtAuthRepFlg, snapAuthRepFlg) => {
    const returnFlags = (question) => {
        if (question === "ebtQuestion") {
            if (ebtAuthRepFlg === "N" || ebtStateFlg === "N") {
                return "No"
            }
            if (ebtAuthRepFlg === "Y" || ebtStateFlg === "Y") {
                return "Yes"
            }
        }
        if (question === "snpQuestion") {
            if (snapAuthRepFlg === "N" || snpStateFlg === "N") {
                return "No"
            }
            if (snapAuthRepFlg === "Y" || snpStateFlg === "Y") {
                return "Yes"
            }
        }
    }
    return (
        <React.Fragment>
            {authorizedRepresentatives && (authorizedRepresentatives["ebtFrstNam"] !== "" || authorizedRepresentatives["snpFrstNam"] !== "") && (ebtStateFlg || snpStateFlg || ebtAuthRepFlg || snapAuthRepFlg) && (
                <React.Fragment>
                    <ul className='description-list__itemContainer'>
                        {/* {authorizedRepresentatives} */}
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).authQuestionOptions
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnFlags("ebtQuestion")}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).firstNametext
                                }
                            </div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.ebtFrstNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).middleName
                                }
                            </div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.ebtMdlNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).lastNametext
                                }
                            </div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.ebtLstNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).suffixLabel
                                }
                            </div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.ebtSuffix}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).phoneNumbertext
                                }
                            </div>
                            <div className='description-list__itemContent'>{formatters.formatPhoneNumber(authorizedRepresentatives.ebtPhNum)}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).authCertificationQuestionOption
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnFlags("snpQuestion")}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>{
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).firstNametext
                            }</div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.snpFrstNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>{
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).middleName
                            }</div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.snpMdlNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>{
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).lastNametext
                            }</div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.snpLstNam}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>{
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).suffixLabel
                            }</div>
                            <div className='description-list__itemContent'>{authorizedRepresentatives.snpSuffix}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>{
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).phoneNumbertext
                            }</div>
                            <div className='description-list__itemContent'>{formatters.formatPhoneNumber(authorizedRepresentatives.snpPhNum)}</div>
                        </li>
                    </ul>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}