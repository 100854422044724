import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _, { constant,filter,unionBy  } from "lodash";
import { push } from "react-router-redux";
import Collapse from "react-collapse";
import CSSTransition from "react-transition-group/CSSTransition";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import Icon from "../../utils/components/dtaIcon";
import LanguageSelect from "../../redux/language/languageSelect";
import Modal from "../../utils/components/modal/dtaModal";
import * as Props from "../../utils/components/shared";
import DTAInput from "../../utils/components/dtaInput";
import * as OptionsProps from "../../utils/constants/options";
import * as SharedProps from "../../utils/constants/shared";
import { MONTHSHORT } from "../../utils/constants/controls";
import { isNone } from "../../utils/components/utilities/controls";
import CommonDocuments from "../../utils/resources/pdfs/Common-documents.pdf";
// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import ApplySection from "./components/applySnapSection";
import { convertToDTOObj } from "./applySnapConverter";
// Redux implementations
import { connect } from "react-redux";
import {
  serviceFailedAction,
  addApplyContactInfoSubSection,
  applyAddressValidationSection,
  applySanpQuestionsSections,
  applySanpQuestionsSectionsNew,
  saveSnapApply,
  addressValidation,
  submitSnapApply,
  appendHouseHoldMembers,
  clearHouseHoldMembers,
  appendIncomeDetails,
  clearMemberIncome,
  appendExpenseDetails,
  updateResolveQuestionsData,
  displayPDFData,
  updateCurrentSectionData,
  selectMaxOfIdsComesBack,
  saveMyProgressInfoLater,
  saveFinalSignAndSubmit,
  getSaveMyProgessDataByWebAppId,
  updateLastKnownLocation,
  getSavedDataForUser,
  onPrimaryActionSaveClicked,
  deleteWebappData,
  applyAddressStorageAPI,
  acesButtonClicked,
  appendMedicalExpenseSections,
  appendMedicalCostOtherQuestion,
  appendResourcesDetails,
  clearMemberResources,
  languageChanged,
  prePopulationDataInfo
} from "../../redux/applySnap/applySnapActions";
      import {setAuthRepResponseBodyData} from "../../redux/recertification/recertificationActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  toValidateInputErros,
  filterQuestionsBySelection,
  bindAnswers,
  deleteHouseholdMems,
  nameWithoutWhiteSpace,
  todaysDateOrTime,
  addressValidationObj,
  formAddressDisplayValue,
  formatAddressDisplay,
  appendUpdatedAdrress,
  checkIfAddressIsUpdated,
  filterData
} from "./applySnapHelper";
import {
  incomeMultilanguageLabels,
  medicalExpenseMultilanguageLabels,
  houseHoldMultilanguageLabels,
  beforeYoustartMlanguageLabels,
  childDependnMultilanguageLabels,
  aboutMeMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  resourcesSectionMultilanguageLabels,
  houseHoldSectionMultilanguageLabels
} from "./applySnapText";
import {
  snapHouseholdTemplate,
  tanfHouseholdTemplate,
  householdDisabilityTemplate,
  incomeSummaryTemplate,
  IncomeSectionTemplate,
  ResourcesSectionTemplate,
  expenseKidsAndAdultsTemplate,
  expenseMedicalExpenseTemplate,
  expenseHousingCostTemplate,
  accommodationConditionalQuestion,
  snapSections,
  tanfSections,
  comboSections,
  ShelterSectionTemplate,
  medicalOtherCostQuestion,
  houseHoldInformationTemplate
} from "./applySnapMapData";
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import {
  getConsumerLoginContents,
  onLogin,
  onMFASubmit,
} from "../../redux/user/loginActions";
import {
  onRegister,
  onBindEmail,
} from "../../redux/user/consumerSignUpActions";
import DTADate from "../../utils/components/dtaDate";
import DTADropdown from "../../utils/components/dtaDropdown";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import { updateConsumerInfo } from "../../redux/consumer/consumerMyInfoActions";
import PropTypes from "prop-types";
import BeforeYouStart from "./components/beforeYouStart";
import SignAndSubmit from "./components/signAndSubmit";
import { consumerConst } from "../consumer/consumerText";
import * as roleProps from "../../utils/constants/rolesConstants";
import { withMsal } from "@azure/msal-react";
import { forgotPasswordRequest, signUp } from "config/appConfig";
import { saveRequestAccountAccessUserInfo } from "../../redux/provider/providerSearchConsumerActions";
import RequestAccountAccessModal from "../provider/components/requestAccountAccessModal";
import * as TextProps from "../../utils/constants/text";
import TanfBeforeYouStart from "./components/tanfBeforeYouStart";
import Checkbox from "../../utils/components/checkbox";
// import * as azureLoginFlag from '../../utils/constants/appConfigConstants';
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import Radio from "../../utils/components/radio";
import SummaryContainer from "./components/SummaryContainer";
import AuthRep from "./AuthRep";
import { interimWarningPopupMLanguageLabels } from "../recertInterimReport/recertInterimReportText";
import { ALABAMA_COUNTY_OFFICES } from "../../utils/constants/constants";
import { ALL_STATES_COUNTY } from "../../utils/constants/statesCountyCode";
import { setSectionBasedApplicationType } from "../../redux/applySnap/applySnapActions";
import * as formatters from "../../utils/components/utilities/formatters";
import { recertificationPageLabels } from "../recertification/recertificationText";
import * as languageConstants from "../../utils/constants/constants";
import { convertDOBForDataPost } from "../recertification/recertificationHelper";



var ua = require("universal-analytics");
var crypto = require("crypto");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);

//end code for google analytics
let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select" ) {
  language = "en-US";
}

let applicationType = "";
let householdTemplate = {};

var config = require("../../utils/constants/appConfigConstants");

// if (applicationType === TextProps.MATCH_SNAP) {
//   householdTemplate = snapHouseholdTemplate;
// } else if(applicationType === TextProps.MATCH_TANF) {
//   householdTemplate = tanfHouseholdTemplate;
// }

// For tesing resume apply feautre
var resumeApply = TextProps.VALUE_FALSE;

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class Apply extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    sessionStorage.setItem("saveExitShowModalEnabled", false);
    sessionStorage.setItem("_stepVerifiedR", "N");
    sessionStorage.setItem("_stepVerifiedM", "N");
    sessionStorage.setItem("_isMailingAddress", "N");

    this.confirmAddressValidationAction =
      this.confirmAddressValidationAction.bind(this);
    this.cancelAddressValidationAction =
      this.cancelAddressValidationAction.bind(this);
    this._handleAddressSelection = this._handleAddressSelection.bind(this);
    this._saveHandler = this._saveHandler.bind(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.onSignSubmitActionClick = this.onSignSubmitActionClick.bind(this);
    this.onSaveExitClick = this.onSaveExitClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.onPrimaryActionClickSaveContinue =
      this.onPrimaryActionClickSaveContinue.bind(this);
    this.onSecondaryActionClick = this.onSecondaryActionClick.bind(this);
    this.onHouseHoldSubsectionRender =
      this.onHouseHoldSubsectionRender.bind(this);
    this.onHouseHoldSubSectionClean =
      this.onHouseHoldSubSectionClean.bind(this);
    this.onIncomeSubSectionClean = this.onIncomeSubSectionClean.bind(this);
    this.onResourcesSubSectionClean = this.onResourcesSubSectionClean.bind(this);
    this.incomefilterQuestionsBySelection =
      this.incomefilterQuestionsBySelection.bind(this);
    this.resourcesfilterQuestionsBySelection =
      this.resourcesfilterQuestionsBySelection.bind(this);
    this.updateHouseHoldIncomeDetails =
      this.updateHouseHoldIncomeDetails.bind(this);
    this.updateHouseHoldResourcesDetails =
      this.updateHouseHoldResourcesDetails.bind(this);
    this.updateHouseHoldExpenseDetails =
      this.updateHouseHoldExpenseDetails.bind(this);
    this.updateHouseHoldMedicalDetails =
      this.updateHouseHoldMedicalDetails.bind(this);
    this.onIncomeSubsectionRender = this.onIncomeSubsectionRender.bind(this);
    this.onResourcesSubsectionRender = this.onResourcesSubsectionRender.bind(this);
    this.updateHouseHoldDetails = this.updateHouseHoldDetails.bind(this);
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    //    this.confirmAddressValidate = this.confirmAddressValidate.bind(this);
    this.downloadApplicationAsPdf = this.downloadApplicationAsPdf.bind(this);
    this.displayPDFData = this.displayPDFData.bind(this);
    this.renderIncomeToAddNewMember =
      this.renderIncomeToAddNewMember.bind(this);
    this.goToSignup = this.goToSignup.bind(this);
    this.goSignup = this.goSignup.bind(this);
    this.beforeGoToSignupPage = this.beforeGoToSignupPage.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.confirmDeleteOfHousehold = this.confirmDeleteOfHousehold.bind(this);
    this.incomeSubSections = [];
    this.housingCostsSubSections = [];
    this.dependentsCareCostsSubSections = [];
    this.incomeSummary = {};
    this.resourcesSummary = {};
    this.providerAddressTemplate = [];
    this.renderedMedicalSectionsCopy = "";
    this.renderCounter = 0;
    this.nonRenderCounter = 0;
    this.base64Data = "";
    this.state = {
      authRepCancel: TextProps.VALUE_FALSE,
        flags: {
        },
        authRepDataChanged: TextProps.VALUE_FALSE,
        ebtPhoneValid: TextProps.VALUE_FALSE,
        snpPhoneValid: TextProps.VALUE_FALSE,
        addAuthRepData: {
          ebtFrstNam : null,
          ebtLstNam : null,
          ebtMdlNam : null,
          ebtPhNum : null,
          ebtSuffix : null,
          ebtWebDataChangeCode : null,
          snpFrstNam : null,
          snpLstNam : null,
          snpMdlNam : null,
          snpPhNum : null,
          snpSuffix : null,
          snpWebDataChangeCode : null,
          ebtAuthRepFlg: null,
          snapAuthRepFlg: null,
        },
      sections: [],
      sectionsCopy:[],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      expandNvraToggle: TextProps.VALUE_FALSE,
      expandSnapImpInfoToggle: TextProps.VALUE_FALSE,
      expandSnapInfoToggle: TextProps.VALUE_FALSE,
      expandSnapSummarizedToggle: TextProps.VALUE_FALSE,
      expandTanfImpInfoToggle: TextProps.VALUE_FALSE,
      expandTanfInfoToggle: TextProps.VALUE_FALSE,
      expandTanfSummarizedToggle: TextProps.VALUE_FALSE,
      expandVoterToggle: TextProps.VALUE_FALSE,
      addResourcesMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      addressValidationModalOpen: TextProps.VALUE_FALSE,
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      houseHoldSubMemberTemplate: null,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: null,
      incomeSubSectionCleared: TextProps.VALUE_FALSE,
      resourcesMemberTemplate: null,
      resourcesSummaryTemplate: null,
      resourcesSubSectionCleared: TextProps.VALUE_FALSE,
      houseMemberLabels: [],
      dependentsParentsLabels: [],
      dependentsChildsLabels: [],
      dependentsCareCostsMemberLabels: [],
      dependentCareCostsTemplate: null,
      dependentCareCostsSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseMemberTemplate: null,
      kidsAdultExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseMemberTemplate: null,
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      resourcesSummaryEdit: TextProps.VALUE_FALSE,
      rights: 0,
      signature: "",
      AnswerObject: [],
      oldHouseHoldSizeValue: 0,
      lastKnownSection: "",
      isLoggedin: TextProps.VALUE_FALSE,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      CFDHR:'',
      consentForDHRVerifyErrorMsg:'',
      consentForDHRVerifyError: TextProps.VALUE_FALSE,
      dataSheetObj: { appId: "", clientId: "", documentId: "" },
      enabledMembersforMedical: [],
      RAAModalOpen: TextProps.VALUE_FALSE,
      requestAccessForUser: {},
      filesToUpload: [],
      onPrimaryActionSaveClicked: TextProps.VALUE_FALSE,
      prevSectionsToBeSaved: [],
      prevIncomingQuestions: [],
      sectionOnLoad: [],
      defaultaddress: "",
      addressRadioOptions1: [],
      enteredAddressValue: "",
      selectedAdressValue: "",
      addressRadioOptions2: [],
      residentialAddress: {},
      mailingAddress: {},
      //addressValidateModel: false,
      melisaline1: "",
      melisaline2: "",
      melisaCity: "",
      melisaState: "",
      melisaZipCode: "",
      melisaCounty: "",
      melisaCountyCode: "",
      melisaVerified: "",
      verified: "N",
      userEnteredAddress: "",
      suggestedAddress: "",
      randomId: this.getRandomeId(),
      user: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1988, 9, 27),
        [SharedProps.AGENCY_ID]: "2000001",
      },
      confirmedHouseholdMemForDeletion: [],
      sectionDataUpdated: TextProps.VALUE_FALSE,
      saveExitShowModal: TextProps.VALUE_FALSE,
      resetShowModal: TextProps.VALUE_FALSE,
      resetSection: {},
      saveExitRedirectToHomePage: TextProps.VALUE_FALSE,
      deleteWarningShowModal: TextProps.VALUE_FALSE,
      deleteWarningRedirectToHomePage: TextProps.VALUE_FALSE,
      addressValidateResp: {},
      saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      saveORExitWarningRedirectPage: TextProps.VALUE_FALSE,
      acesButtonClickWarningShowModal: TextProps.VALUE_FALSE,
      languageChangeWarning:TextProps.VALUE_FALSE,
      acesButtonClickWarningRedirectPage: TextProps.VALUE_FALSE,
      createdDateTime: "",
      enteredResAddressValue: "",
      selectedResAdressValue: "",
      enteredMailingAddressValue: "",
      selectedMailingAddressValue: "",
      addressObj: {},
      finalResValueSelected: "",
      finalMailingValueSelected: "",
      mailingAddressFlag: TextProps.VALUE_FALSE,
      resAddressFlag: TextProps.VALUE_FALSE,
      updateDateTime: "",
      serverTimeZone: "",
      pendingHours: "",
      appId: "",
      MelissaApiResponded: true,
      selectedHouseholdMemCount: 0,
      isDeleteValidCount: 0,
      isHomelessModalOpen: TextProps.VALUE_FALSE,
      setHomelessModalOpen: TextProps.VALUE_FALSE
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.appInanonymousMode = JSON.parse(sessionStorage.getItem('applicationInanonymousMode').toLowerCase());
  }
  openRAAModal = (user) => {
    this.setState({
      requestAccessForUser: user,
      RAAModalOpen: TextProps.VALUE_TRUE,
      gotSuccess: TextProps.VALUE_FALSE,
    });
  };
  closeRAAModal = (data) => {
    this.setState({
      RAAModalOpen: TextProps.VALUE_FALSE,
      filesToUpload: [],
    });
  };

  handleFileInputChange = (event) => {
    const inputEl = event.target,
      files = Array.from(inputEl.files);

    files.forEach((file) => {
      file[SharedProps.ID] = `upload-file-${this.fileIncrementer}`;
      this.fileIncrementer += 1;
    });

    this.setState(
      {
        filesToUpload: this.state.filesToUpload.concat(files),
      },
      () => {
        inputEl.value = null;
      }
    );
  };

  handleRemoveFile = (fileToRemove) => () => {
    const newFileList = this.state.filesToUpload.filter(
      (file) => file[SharedProps.ID] !== fileToRemove[SharedProps.ID]
    );

    this.setState({ filesToUpload: newFileList });
  };

  submitRAA = (
    ref,
    startDate,
    endDate,
    clientId,
    webAppId,
    webApplicationArray
  ) => {
    const { search } = this.props.language.provider;
    ref.showBlur("loading", "Submitting...");
    var consumerDataUserObj = JSON.parse(sessionStorage.getItem("userInfo"));
    var requestAccountAccessObj = {};
    requestAccountAccessObj.webApplicationArray = webApplicationArray;
    requestAccountAccessObj.startDate = startDate;
    requestAccountAccessObj.endDate = endDate;
    if (
      this.props.consumer.consumerData.user !== null &&
      this.props.consumer.consumerData.user !== undefined
    ) {
      if (this.props.consumer.consumerData.user.userAuth !== undefined) {
        requestAccountAccessObj.userObj = this.props.consumer.consumerData.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      } else {
        requestAccountAccessObj.userObj = consumerDataUserObj.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      }
    } else if (this.props.consumer.centrifyData.user !== undefined) {
      requestAccountAccessObj.userObj = this.props.consumer.centrifyData.user;
      this.props.saveRequestAccountAccessUserInfo(
        webAppId,
        clientId,
        requestAccountAccessObj
      );
      setTimeout(() => {
        this.showMessage(ref, "success", search.submitSuccess);
      }, 3000);
    } else {
      if (consumerDataUserObj !== null) {
        requestAccountAccessObj.userObj = consumerDataUserObj.user;
        this.props.saveRequestAccountAccessUserInfo(
          webAppId,
          clientId,
          requestAccountAccessObj
        );
        setTimeout(() => {
          this.showMessage(ref, "success", search.submitSuccess);
        }, 3000);
      } else {
        window.location.assign("/login");
      }
    }
  };

  showMessage(ref, type, message) {
    if (type === "success") {
      this.setState({
        RAAError: TextProps.VALUE_FALSE,
        RAAErrorMessage: "",
        gotSuccess: TextProps.VALUE_TRUE,
      });
      ref.showBlur(type, message);
    } else if (type === "error") {
      ref.hideBlur();
      this.setState({
        RAAError: TextProps.VALUE_TRUE,
        RAAErrorMessage: message,
        gotSuccess: TextProps.VALUE_FALSE,
      });
    }
  }

  componentWillReceiveProps(nextProps) {

    if (
      nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
      nextProps.apply.islanguageChanged === true
    ) {
        // if (this.state.sectionDataUpdated) {
        this.setState({
          languageChangeWarning: TextProps.VALUE_TRUE,
        });
      }
    let mineData = filterData(this.props.apply.sections, "auth-rep");
    let authRep = {...mineData}
    this.state.addAuthRepData = this.state.addAuthRepData ? this.state.addAuthRepData : authRep.authorizedRepresentatives;
    this.setState(
      {
        sections: this.props.apply.sections,
        sectionsCopy: this.props.apply.sections,
      },
      () => {
        if (
          this.state.renderedSections.length < 1 &&
          this.props.apply.sections.length
        ) {
          this.setState({
            renderedSections: [
              this._getSection(this.props.apply.activeSectionId),
            ],
            submitFormId: this.nextUniqueId(),
            activeSectionId: this.props.apply.activeSectionId,
            prevSectionsToBeSaved: this.props.apply.sections,
            prevIncomingQuestions: [
              this._getSection(this.props.apply.activeSectionId),
            ][0],
          });
          this.onLoadPrePopulateValue();
        }
        // applicationType is set based on user selection in the Home page.
        applicationType = sessionStorage.getItem("applicationType");
        if (applicationType === TextProps.MATCH_SNAP) {
          householdTemplate = snapHouseholdTemplate; //applicationType = "SNAP"
        } else if (applicationType === TextProps.MATCH_TANF) {
          householdTemplate = tanfHouseholdTemplate; //applicationType = "TANF"
        } else {
          householdTemplate = tanfHouseholdTemplate;
          householdTemplate.questions[7]['label']['helpText']= houseHoldSectionMultilanguageLabels(language).comboCitizenHelp //applicationType = "COMBO"
        }
      }
    );
    if (nextProps.consumer.applicationSubmited === TextProps.VALUE_TRUE) {
      this.setState({ applicationSubmited: TextProps.VALUE_TRUE });
    }
    //Login Logic
    if (
      nextProps.consumer.loginSuccessful === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_TRUE
    ) {
      this.setState({
        loginSectionContents: TextProps.VALUE_FALSE,
        loginSuccessful: TextProps.VALUE_TRUE,
        loginErrorState: TextProps.VALUE_FALSE,
      });
      if (
        nextProps.consumer.loginDetails.MFAObjectFlag === TextProps.VALUE_TRUE
      ) {
        this.setState({
          loginSectionContents: TextProps.VALUE_FALSE,
          mfaModel: TextProps.VALUE_TRUE,
          loginSucessDetails: nextProps.consumer.loginDetails,
        });
      }
    } else if (
      nextProps.consumer.loginErrorState === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_TRUE
    ) {
      this.setState({
        loginErrorState: TextProps.VALUE_TRUE,
        loginErrorMessage: nextProps.consumer.loginErrorMessage,
      });
    }
    if (
      nextProps.consumer.mfaSuccessful === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_FALSE &&
      this.state.isLoggedin === TextProps.VALUE_FALSE
    ) {
      this.setState(
        {
          mfaErrorState: TextProps.VALUE_FALSE,
          MFASuccessDetails: nextProps.consumer.mfaDetails,
        },
        () => {
          if (nextProps.consumer.mfaDetails !== undefined) {
            if (
              nextProps.consumer.mfaDetails.email !== undefined &&
              nextProps.consumer.mfaDetails.email !== ""
            ) {
              this.setState({
                isLoggedin: TextProps.VALUE_TRUE,
                mfaModel: TextProps.VALUE_FALSE,
              });
            } else {
              this.setState({ isLoggedin: TextProps.VALUE_FALSE });
            }
          }
        }
      );
    } else if (
      nextProps.consumer.mfaErrorState === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_FALSE
    ) {
      this.setState({
        mfaErrorState: TextProps.VALUE_TRUE,
        mfaErrorMessage: nextProps.consumer.mfaErrorMessage,
      });
    }

    //Register Logic
    if (nextProps.consumer.registrationSuccessful === TextProps.VALUE_TRUE) {
      this.setState({
        signupSectionContents: TextProps.VALUE_FALSE,
        registrationSuccessful: TextProps.VALUE_TRUE,
        registrationErrorState: TextProps.VALUE_FALSE,
      });
    } else if (
      nextProps.consumer.registrationErrorState === TextProps.VALUE_TRUE
    ) {
      this.setState({
        registrationErrorState: TextProps.VALUE_TRUE,
        registrationErrorMessage: nextProps.consumer.registrationErrorMessage,
      });
    }
    // Save and Continue / save and Exit logic
    // For all three applications when save and continue button is clicked snapSaveContinue value is set to true.
    // For all three applications when save and exit button is clicked saveExitShowModalEnabled value is set to true.
    if (
      nextProps.apply.snapSaveContinue &&
      this.state.activeSectionId !== "sign-and-submit" &&
      this.state.activeSectionId !== "before-you-start" &&
      !this.appInanonymousMode
    ) {
      this.triggerSaveSnap(this.state.activeSectionId);
    }

    //aces button click show warning model when user update data
    if (
      nextProps.apply.isAcesButtonClicked != undefined &&
      nextProps.apply.isAcesButtonClicked === true
    ) {
      if (((this.state.sectionDataUpdated && !this.appInanonymousMode) || this.appInanonymousMode) && (this.state.activeSectionId !== "submitted" && this.state.activeSectionId !== "before-you-start")) {
        this.setState({
          acesButtonClickWarningShowModal: TextProps.VALUE_TRUE,
        });
      } else {
        this.redirectToHomePage();
      }
    }
  // Prepopulation For SNAP And TANF
  let prePopulateApp = sessionStorage.getItem("prePopulateApp");
  if (prePopulateApp && (applicationType === TextProps.MATCH_TANF || applicationType === TextProps.MATCH_SNAP)) {
    this.setState({
      sectionDataUpdated: TextProps.VALUE_TRUE,
    })
    this.handlePrepopulation(nextProps);
  }
  if (nextProps.apply && nextProps.apply.applicantInfo ||
    nextProps.apply.applicantInfo === null) {
    this.setState({ isLoading: TextProps.VALUE_FALSE });
  }
}

handlePrepopulation = (nextProps) => {
  let snapDetailObj = nextProps.apply &&
    nextProps.apply.applicantInfo &&
    nextProps.apply.applicantInfo.SNAP_APPLY &&
    nextProps.apply.applicantInfo.SNAP_APPLY.application;
  let aboutMeSection = filterData(nextProps.apply.sections, "about-me");
  if (aboutMeSection && snapDetailObj) {
    let dataInfo = snapDetailObj.applicant;
    aboutMeSection.questions[0].Answer = dataInfo.firstName;
    aboutMeSection.questions[0].targetValue = dataInfo.firstName;
    aboutMeSection.questions[1].Answer = dataInfo.middleName;
    aboutMeSection.questions[1].targetValue = dataInfo.middleName;
    aboutMeSection.questions[2].Answer = dataInfo.lastName;
    aboutMeSection.questions[2].targetValue = dataInfo.lastName;
    aboutMeSection.questions[3].Answer = dataInfo.nameSuffix;
    aboutMeSection.questions[3].targetValue = dataInfo.nameSuffix;
    aboutMeSection.questions[4].Answer = dataInfo.birthDate;
    aboutMeSection.questions[4].targetValue = dataInfo.birthDate;
    aboutMeSection.questions[5].Answer = dataInfo.SSN;
    aboutMeSection.questions[5].targetValue = dataInfo.SSN;
    if (dataInfo.SSN === "") {
      aboutMeSection.questions[5].isChecked = true;
    } else {
      aboutMeSection.questions[5].isChecked = false;
    }
    if (applicationType === TextProps.MATCH_TANF) {
      if (aboutMeSection.subsections && aboutMeSection.subsections.length > 0 && aboutMeSection.subsections[0]) {
        //is mailing Address
        const addressMailQuestion = aboutMeSection.subsections[0].questions.find(question => question.name === "is-also-mailing");
        if (addressMailQuestion && dataInfo.addresses && dataInfo.addresses[1]) {
          let countyMailCode = ALL_STATES_COUNTY.filter(
            county => county.code === dataInfo.addresses[1].countyCode && county.state === dataInfo.addresses[1].state
          );
          let countyMailName = countyMailCode.length > 0 ? countyMailCode[0].county : "";
          if (dataInfo.addresses[0].isSameMailAndPhysical === "N") {
            aboutMeSection.subsections[0].questions[1].Answer = "false";
            aboutMeSection.subsections[0].questions[1].targetValue = "false";
          }
          addressMailQuestion.conditionals[0].conditionalQuestions[0].Answer = {
            addressLine1: dataInfo.addresses[1].street,
            addressLine2: dataInfo.addresses[1].aptOrUnit,
            city: dataInfo.addresses[1].city,
            county: countyMailName,
            state: dataInfo.addresses[1].state,
            zipCode: dataInfo.addresses[1].postal,
            verified: "N",
            countyCode: dataInfo.addresses[1].countyCode
          };
          addressMailQuestion.conditionals[0].conditionalQuestions[0].targetValue = addressMailQuestion.conditionals[0].conditionalQuestions[0].Answer;
        }
        //Home Address
        const addressQuestion = aboutMeSection.subsections[0].questions.find(question => question.name === "address");
        if (addressQuestion && dataInfo.addresses && dataInfo.addresses[0]) {
          let countyCode = ALL_STATES_COUNTY.filter(
            county => county.code === dataInfo.addresses[0].countyCode && county.state === dataInfo.addresses[0].state
          );
          let countyName = countyCode.length > 0 ? countyCode[0].county : "";
          addressQuestion.Answer = {
            addressLine1: dataInfo.addresses[0].street,
            addressLine2: dataInfo.addresses[0].aptOrUnit,
            city: dataInfo.addresses[0].city,
            county: countyName,
            state: dataInfo.addresses[0].state,
            zipCode: dataInfo.addresses[0].postal,
            verified: "N",
            countyCode: dataInfo.addresses[0].countyCode
          };
          addressQuestion.targetValue = addressQuestion.Answer;
          aboutMeSection.subsections[0].questions[2].Answer = dataInfo.areaCode + dataInfo.exchangeNumber + dataInfo.lineNumber
          aboutMeSection.subsections[0].questions[2].targetValue = dataInfo.areaCode + dataInfo.exchangeNumber + dataInfo.lineNumber
          aboutMeSection.subsections[0].questions[3].Answer = dataInfo.emailAddress
          aboutMeSection.subsections[0].questions[3].targetValue = dataInfo.emailAddress
          //Appointment Preference
          if (dataInfo.appointmentPreference.interviewType === "F") {
            aboutMeSection.subsections[0].questions[5].Answer = "In-Office"
            aboutMeSection.subsections[0].questions[5].targetValue = "In-Office"
          } else if (dataInfo.appointmentPreference.interviewType === "T") {
            aboutMeSection.subsections[0].questions[5].Answer = "Telephone"
            aboutMeSection.subsections[0].questions[5].targetValue = "Telephone"
          }
          const appointmentPeferenceDayObj = aboutMeSection.subsections[0].questions[6];
          const appointmentPeferenceDay = [];
          if (dataInfo.appointmentPreference.mondayFlag === "Y") {
            appointmentPeferenceDay.push("Monday");
          } if (dataInfo.appointmentPreference.tuesdayFlag === "Y") {
            appointmentPeferenceDay.push("Tuesday");
          } if (dataInfo.appointmentPreference.wednesdayFlag === "Y") {
            appointmentPeferenceDay.push("Wednesday");
          } if (dataInfo.appointmentPreference.thursdayFlag === "Y") {
            appointmentPeferenceDay.push("Thursday");
          } if (dataInfo.appointmentPreference.fridayFlag === "Y") {
            appointmentPeferenceDay.push("Friday");
          } if (dataInfo.appointmentPreference.samedayFlag === "Y") {
            appointmentPeferenceDay.push("Same Day");
          }
          appointmentPeferenceDayObj.Answer = appointmentPeferenceDay;
          appointmentPeferenceDayObj.targetValue = appointmentPeferenceDay;
          const appointmentPreferenceQuestion = aboutMeSection.subsections[0].questions[7];
          const appointmentPreferences = [];
          if (dataInfo.appointmentPreference.amFlag === "Y") {
            appointmentPreferences.push("AM");
          } if (dataInfo.appointmentPreference.pmFlag === "Y") {
            appointmentPreferences.push("PM");
          }
          appointmentPreferenceQuestion.Answer = appointmentPreferences;
          appointmentPreferenceQuestion.targetValue = appointmentPreferences;
        }
      }
    }
    if (applicationType === TextProps.MATCH_SNAP) {
      if (aboutMeSection.subsections && aboutMeSection.subsections[0]) {
        //is Mailing Address
        const addressMailQuestion = aboutMeSection.subsections[0].questions.find(question => question.name === "is-also-mailing");
        if (addressMailQuestion && dataInfo.addresses && dataInfo.addresses[1]) {
          let countyMailCode = ALL_STATES_COUNTY.filter(
            county => county.code === dataInfo.addresses[1].countyCode && county.state === dataInfo.addresses[1].state
          );
          let countyMailName = countyMailCode.length > 0 ? countyMailCode[0].county : "";
          if (dataInfo.addresses[0].isSameMailAndPhysical === "N") {
            aboutMeSection.subsections[0].questions[4].Answer = "false";
            aboutMeSection.subsections[0].questions[4].targetValue = "false";
          }
          addressMailQuestion.conditionals[0].conditionalQuestions[0].Answer = {
            addressLine1: dataInfo.addresses[1].street,
            addressLine2: dataInfo.addresses[1].aptOrUnit,
            city: dataInfo.addresses[1].city,
            county: countyMailName,
            state: dataInfo.addresses[1].state,
            zipCode: dataInfo.addresses[1].postal,
            verified: "N",
            countyCode: dataInfo.addresses[1].countyCode
          };
          addressMailQuestion.conditionals[0].conditionalQuestions[0].targetValue = addressMailQuestion.conditionals[0].conditionalQuestions[0].Answer;
        }
        //Home Address
        const addressQuestion = aboutMeSection.subsections[0].questions.find(question => question.name === "address");
        if (addressQuestion && dataInfo.addresses && dataInfo.addresses[0]) {
          let countyCode = ALL_STATES_COUNTY.filter(
            county => county.code === dataInfo.addresses[0].countyCode && county.state === dataInfo.addresses[0].state
          );
          let countyName = countyCode.length > 0 ? countyCode[0].county : "";
          addressQuestion.Answer = {
            addressLine1: dataInfo.addresses[0].street,
            addressLine2: dataInfo.addresses[0].aptOrUnit,
            city: dataInfo.addresses[0].city,
            county: countyName,
            state: dataInfo.addresses[0].state,
            zipCode: dataInfo.addresses[0].postal,
            verified: "N",
            countyCode: dataInfo.addresses[0].countyCode
          };
          addressQuestion.targetValue = addressQuestion.Answer;
          aboutMeSection.subsections[0].questions[5].Answer = dataInfo.areaCode + dataInfo.exchangeNumber + dataInfo.lineNumber
          aboutMeSection.subsections[0].questions[5].targetValue = dataInfo.areaCode + dataInfo.exchangeNumber + dataInfo.lineNumber
          aboutMeSection.subsections[0].questions[6].Answer = dataInfo.emailAddress
          aboutMeSection.subsections[0].questions[6].targetValue = dataInfo.emailAddress
          //Appointment Preference
          if (dataInfo.appointmentPreference.interviewType === "F") {
            aboutMeSection.subsections[0].questions[8].Answer = "In-Office"
            aboutMeSection.subsections[0].questions[8].targetValue = "In-Office"
          } else if (dataInfo.appointmentPreference.interviewType === "T") {
            aboutMeSection.subsections[0].questions[8].Answer = "Telephone"
            aboutMeSection.subsections[0].questions[8].targetValue = "Telephone"
          }
          const appointmentPeferenceDayObj = aboutMeSection.subsections[0].questions[9];
          const appointmentPeferenceDay = [];
          if (dataInfo.appointmentPreference.mondayFlag === "Y") {
            appointmentPeferenceDay.push("Monday");
          } if (dataInfo.appointmentPreference.tuesdayFlag === "Y") {
            appointmentPeferenceDay.push("Tuesday");
          } if (dataInfo.appointmentPreference.wednesdayFlag === "Y") {
            appointmentPeferenceDay.push("Wednesday");
          } if (dataInfo.appointmentPreference.thursdayFlag === "Y") {
            appointmentPeferenceDay.push("Thursday");
          } if (dataInfo.appointmentPreference.fridayFlag === "Y") {
            appointmentPeferenceDay.push("Friday");
          } if (dataInfo.appointmentPreference.samedayFlag === "Y") {
            appointmentPeferenceDay.push("Same Day");
          }
          appointmentPeferenceDayObj.Answer = appointmentPeferenceDay;
          appointmentPeferenceDayObj.targetValue = appointmentPeferenceDay;
          const appointmentPreferenceQuestion = aboutMeSection.subsections[0].questions[10];
          const appointmentPreferences = [];
          if (dataInfo.appointmentPreference.amFlag === "Y") {
            appointmentPreferences.push("AM");
          } if (dataInfo.appointmentPreference.pmFlag === "Y") {
            appointmentPreferences.push("PM");
          }
          appointmentPreferenceQuestion.Answer = appointmentPreferences;
          appointmentPreferenceQuestion.targetValue = appointmentPreferences;
        }
      }
    }
  }
}


  autoClearSections = () => {
    const activeId = this.state.activeSectionId;
    if(activeId === "auth-rep") {
      this.setState({addAuthRepData : filterData(this.props.apply.sections, "auth-rep").authorizedRepresentatives});
      this.state.addAuthRepData = filterData(this.props.apply.sections, "auth-rep").authorizedRepresentatives;
      this.setFlag("ebtFrstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtLstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtPhNumError", TextProps.VALUE_FALSE);
      this.setFlag("snpFrstNamError", TextProps.VALUE_FALSE);
      this.setFlag("snpLstNamError", TextProps.VALUE_FALSE);
      this.setFlag("snpPhNumError", TextProps.VALUE_FALSE);
    }
  }

  // redux apply sections loading
  async componentDidMount() {
    const app = window.sessionStorage.getItem('applicationType');
    if(this.appInanonymousMode){
      this.props.setSectionBasedApplicationType(app)
    }else{
    let mineData = filterData(this.props.apply.sections, "auth-rep");
    let authRep = {...mineData};
    this.setState({addAuthRepData : authRep.authorizedRepresentatives})
    if (this.props.match.params.webAppId !== undefined) {
      this.props.getSaveMyProgessDataByWebAppId(
        this.props.match.params.webAppId
      );
    } else {
      //Uncomment below lines if the application doesn't need save and continue functionality
      // this.props.applySanpQuestionsSections();

      //This function prepopulates the saved data in raw form
      this.onLoadPrePopulateValue();
    }
  }
    //Login Logic dependencies

    this.setState({
      loginSectionContents: TextProps.VALUE_FALSE,
      loginSuccessful: TextProps.VALUE_FALSE,
      loginErrorState: TextProps.VALUE_FALSE,
      mfaSuccessful: TextProps.VALUE_FALSE,
      mfaModel: TextProps.VALUE_FALSE,
      signupSectionContents: TextProps.VALUE_FALSE,
      registrationSuccessful: TextProps.VALUE_FALSE,
      registrationErrorState: TextProps.VALUE_FALSE,
    });

    //Check Login Status
    if (Object.keys(this.props.consumer.consumerData).length > 0) {
      if (this.props.consumer.consumerData.user !== undefined) {
        if (
          this.props.consumer.consumerData.user.email !== undefined &&
          this.props.consumer.consumerData.user.email !== ""
        ) {
          this.setState({ isLoggedin: TextProps.VALUE_TRUE });
        } else {
          this.setState({ isLoggedin: TextProps.VALUE_FALSE });
        }
      }
    }

    this.updateDimensions();
    window.addEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }

  updateDimensions() {
    let mainElems = document.getElementsByClassName("apply__content");
    let applyCont = document.getElementsByClassName("apply_free_comm");
    let appHeight = document.getElementsByClassName("app");
    let bodyTag = document.getElementsByTagName("body");
    let modalHeight = document.getElementsByClassName("dta-modal");

    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    var contentHeight = 0;
    var applyfreeCommHeight = 0;
    var modalPopupHeight = 0;

    if (bodyTag) {
      for (let elems of bodyTag) {
        if (elems.style) {
          elems.style.height = "auto";
        }
      }
    }

    if (isFreeComOpen === "false") {
      let applyCont = document.getElementsByClassName("apply_free_comm");
      let appHeight = document.getElementsByClassName("app");
      var applyfreeCommHeight = 0;

      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${applyfreeCommHeight}px`;
          }
        }
      }
    } else {
      if (mainElems) {
        for (let elems of mainElems) {
          contentHeight = elems.scrollHeight;
        }
      }
      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (modalHeight) {
        for (let elems of modalHeight) {
          modalPopupHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${
              contentHeight + applyfreeCommHeight + modalPopupHeight
            }px`;
          }
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }
  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    // let mineData = filterData(this.props.apply.sections, "auth-rep");
    // let authRep = {...mineData};
    // if(!_.isEqual(this.state.addAuthRepData, authRep.authorizedRepresentatives)  && !_.isEqual(this.state.addAuthRepData, prevState.prevProps)){
    //   this.setState({addAuthRepData : (this.state.addAuthRepData ? this.state.addAuthRepData : authRep.authorizedRepresentatives)})
    // }
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      if (prevState.activeSectionId === "sign-and-submit")
        this.removeSection(prevState.activeSectionId);
      animateScroll.scrollToTop({
        containerId: "content",
        duration: 500, // Should be roughly the same as CSS transition time
        smooth: TextProps.VALUE_TRUE,
      });
    }
  }


  //When any section in the applications are changed this function is called.
  onSectionChange = (section, event = null) => {
    const previousSectionId = this.state.activeSectionId;
    // const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const renderedSectionsCopy = [];
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);
    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
          prevIncomingQuestions: _.cloneDeep(incomingSection),
        });
      }
    );
  };
  //Data pre population logic
  onLoadPrePopulateValue = () => {
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    this.props
      .getSavedDataForUser(TextProps.VALUE_APP_SAVED)
      .then((data) => {
        if (data.data.application) {
          let rawApplication = JSON.parse(data.data.application);
          this._renderNextSection(rawApplication.activeId);
        }
        this.setState({ isLoading: TextProps.VALUE_FALSE });
      })
      .catch((error) => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
      });
  };
  _convertMonthNumberToString = (monthNumber) => {
    return isNone(MONTHSHORT[monthNumber])
      ? MONTHSHORT[0]
      : MONTHSHORT[monthNumber];
  };

  _convertDateToState = (date) => {
    console.log("poa"+date)
    if (typeof date === "string") {
      date = new Date(date);
    }
    if (isNone(date)) {
      return "";
    }
    if (date) {
      let dd = date.getDate();
      let mm = date.getMonth() + 1;
      let yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      console.log("pop1"," (" + mm + "/" + dd + "/" + yyyy + ")")
      return " (" + mm + "/" + dd + "/" + yyyy + ")";
    }
  };
  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.Authorization = this.props.consumer.mfaDetails.userAuth;

    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };
  //go to Home logic.
  _handleApplyNavigateHome = () => {
    this.props.gotoHome();
  };
  //go back logic
  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };
  //Clearing HouseHold Cache Template
  onHouseHoldSubSectionClean = () => {
    if (applicationType === TextProps.MATCH_TANF) {
      householdDisabilityTemplate.hiddenFromNav = TextProps.VALUE_TRUE;
      householdDisabilityTemplate.completed = TextProps.VALUE_TRUE;
    } else {
      householdDisabilityTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
      householdDisabilityTemplate.completed = TextProps.VALUE_FALSE;
    }
    if (filterData(this.props.apply.sections, "my-household").questions[0].Answer !== undefined) {
      let houseHoldSize = filterData(this.props.apply.sections, "my-household").questions[0].Answer;
      if (houseHoldSize > 0) {
        if (this.state.houseHoldSubSectionCleared === TextProps.VALUE_FALSE) {
          this.setState({
            houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
            houseHoldSubMemberTemplate: householdTemplate,
            houseHoldSubDetailTemplate: householdDisabilityTemplate,
          });
        }
      }
    } else {
      if (this.state.houseHoldSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState({
          houseHoldSubSectionCleared: TextProps.VALUE_TRUE,
          houseHoldSubMemberTemplate: householdTemplate,
          houseHoldSubDetailTemplate: householdDisabilityTemplate,
        });
      }
    }
  };


  //Rendering Dynamic Household Members
  onHouseHoldSubsectionRender = (member) => {
    //clearning Income Template
    this.onIncomeSubSectionClean();
    if (
      applicationType === TextProps.MATCH_SNAP ||
      applicationType === TextProps.MATCH_COMBO
    ) {
      this.onResourcesSubSectionClean();
      filterData(this.props.apply.sections, "expenses").subsections[0].completed =
        TextProps.VALUE_FALSE;
      filterData(this.props.apply.sections, "expenses").subsections[1].completed =
        TextProps.VALUE_FALSE;
      filterData(this.props.apply.sections, "expenses").subsections[2].completed =
        TextProps.VALUE_FALSE;
      filterData(this.props.apply.sections, "expenses").subsections[3].completed =
        TextProps.VALUE_FALSE;
    }
    let houseHoldSubSection = this.state.houseHoldSubMemberTemplate
      ? this.state.houseHoldSubMemberTemplate
      : householdTemplate;
    let houseHoldSubDetailTemplate = this.state.houseHoldSubDetailTemplate
      ? this.state.houseHoldSubDetailTemplate
      : householdDisabilityTemplate;
    if (applicationType === TextProps.MATCH_TANF) {
      houseHoldSubDetailTemplate.hiddenFromNav = TextProps.VALUE_TRUE;
      householdDisabilityTemplate.completed = TextProps.VALUE_TRUE;
    } else {
      houseHoldSubDetailTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
      householdDisabilityTemplate.completed = TextProps.VALUE_FALSE;
    }
    /** ---end ---- */
    /** ---prePolpulate the data */
    if (
      filterData(this.props.apply.sections, "my-household").subsections &&
      filterData(this.props.apply.sections, "my-household").subsections.length.toString() ===
      filterData(this.props.apply.sections, "my-household").questions[0].Answer
    ) {
      let houseHoldMultipleTemplates = filterData(this.props.apply.sections, "my-household").subsections;
      this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);
    } else if (
      filterData(this.props.apply.sections, "my-household").subsections &&
      filterData(this.props.apply.sections, "my-household").subsections.length.toString() <
      filterData(this.props.apply.sections, "my-household").questions[0].Answer
    ) {
      let newHouseholdMems = [];
      let existingHouseholdMems = _.cloneDeep(
        filterData(this.props.apply.sections, "my-household").subsections
      );
      let existingDeatils = existingHouseholdMems.splice(-1)[0];
      let newlyAddedMems = Number(
        filterData(this.props.apply.sections, "my-household").questions[0].Answer
      );
      newlyAddedMems--;
      let existingHouseholdMemsFiltered = [];
      existingHouseholdMems.map((exstMem) => {
        let firstName = exstMem.questions.filter(
          (qus) => qus.name === "firstName"
        )[0];
        let lastName = exstMem.questions.filter(
          (qus) => qus.name === "lastName"
        )[0];
        if (firstName.Answer && firstName.Answer) {
          existingHouseholdMemsFiltered.push(exstMem);
        }
      });
      let addNewMem = newlyAddedMems;
      if (existingHouseholdMemsFiltered.length) {
        addNewMem = newlyAddedMems - existingHouseholdMemsFiltered.length;
      }
      for (var j = 0; j < addNewMem; j++) {
        let k = existingHouseholdMemsFiltered.length + (j + 1);
        houseHoldSubSection.id = "household-member-" + k;
        houseHoldSubSection.clientId = "DEP-" + k;
        houseHoldSubSection.title = houseHoldMultilanguageLabels(
          k+1,
          language
        ).title;
        houseHoldSubSection.header = houseHoldMultilanguageLabels(
          k+1,
          language
        ).header;
        houseHoldSubSection.subHeader = houseHoldMultilanguageLabels(
          k+1,
          language
        ).subHeader;
        let optionObj = {};
        optionObj[OptionsProps.OPTION_VALUE] = "DEP-" + k;
        optionObj[OptionsProps.OPTION_DISPLAY] = houseHoldSubSection.title;
        optionObj.isGrantee = TextProps.VALUE_N;
        existingDeatils.questions[0].options.push(optionObj);
        newHouseholdMems.push(_.cloneDeep(houseHoldSubSection));
      }
      let householdMems = [];
      existingHouseholdMemsFiltered.forEach((mem) => householdMems.push(mem));
      householdMems = [...householdMems, ...newHouseholdMems];
      householdMems.push(existingDeatils);
      this.props.appendHouseHoldMembers(householdMems);
    } else {
      /** ---end of PrePopulation */
      member--;
      if (
        (this.state.oldHouseHoldSizeValue === 0 ||
          this.state.oldHouseHoldSizeValue > member) &&
        this.props.match.params.webAppId === undefined
      ) {
        let houseHoldMultipleTemplate = [];
        houseHoldSubDetailTemplate.questions[0].options = [];
        for (var i = 1; i <= member; i++) {
          houseHoldSubSection.id = "household-member-" + i;
          houseHoldSubSection.title = houseHoldMultilanguageLabels(
            i+1,
            language
          ).title;
          houseHoldSubSection.header = houseHoldMultilanguageLabels(
            i+1,
            language
          ).header;
          houseHoldSubSection.subHeader = houseHoldMultilanguageLabels(
            i+1,
            language
          ).subHeader;
          houseHoldSubSection.clientId = "DEP-" + i;
          houseHoldMultipleTemplate.push(_.cloneDeep(houseHoldSubSection));
          let optionObj = {};
          optionObj[OptionsProps.OPTION_VALUE] = "DEP-" + i;
          optionObj[OptionsProps.OPTION_DISPLAY] = houseHoldSubSection.title;
          optionObj.isGrantee = TextProps.VALUE_N;
          houseHoldSubDetailTemplate.questions[0].options.push(optionObj);
        }
        houseHoldMultipleTemplate.push(houseHoldSubDetailTemplate);

        this.setState({
          houseHoldMembersCount: member,
          oldHouseHoldSizeValue: member,
        });
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplate);
      } else if (
        this.state.oldHouseHoldSizeValue < member &&
        this.props.match.params.webAppId === undefined
      ) {
        let houseHoldMultipleTemplates =
        filterData(this.props.apply.sections, "my-household").subsections;
        let houseHoldDetailTemplate = houseHoldMultipleTemplates.splice(
          -1,
          1
        )[0];
        for (var j = this.state.oldHouseHoldSizeValue; j < member; j++) {
          let k = j + 1;
          houseHoldSubSection.id = "household-member-" + k;
          houseHoldSubSection.clientId = "DEP-" + k;
          houseHoldMultipleTemplates.push(_.cloneDeep(houseHoldSubSection));
        }
        houseHoldMultipleTemplates.push(houseHoldDetailTemplate);
        this.setState({
          houseHoldMembersCount: member,
          oldHouseHoldSizeValue: member,
        });
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);
      } else {
        let houseHoldMultipleTemplates =
        filterData(this.props.apply.sections, "my-household").subsections;
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);
      }
    }
  };
  //clearning Income Template
  onIncomeSubSectionClean = () => {
    if (this.props.match.params.webAppId !== undefined) {
      if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState({
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: filterData(this.props.apply.sections, "income").subsections,
          incomeSummaryTemplate: filterData(this.props.apply.sections, "income").subsections,
        });
      }
    } else {
      if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState(
          {
            incomeSubSectionCleared: TextProps.VALUE_TRUE,
            incomeMemberTemplate: filterData(this.props.apply.sections, "income").subsections[0],
            incomeSummaryTemplate:
            filterData(this.props.apply.sections, "income").subsections[
            filterData(this.props.apply.sections, "income").subsections.length - 1
            ],
          },
          async () => {
            await this.props.clearMemberIncome(
              this.state.incomeMemberTemplate,
              this.state.incomeSummaryTemplate,
              filterData(this.props.apply.sections, "income")
            );
          }
        );
      }
    }
  };
  //clearing Resources Template
  onResourcesSubSectionClean = () => {
    if (this.props.match.params.webAppId !== undefined) {
      if (this.state.resourcesSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState({
          resourcesSubSectionCleared: TextProps.VALUE_TRUE,
          resourcesMemberTemplate: filterData(this.props.apply.sections, "resources").subsections,
          resourcesSummaryTemplate: filterData(this.props.apply.sections, "resources").subsections,
        });
      }
    } else {
      if (this.state.resourcesSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState(
          {
            resourcesSubSectionCleared: TextProps.VALUE_TRUE,
            resourcesMemberTemplate: filterData(this.props.apply.sections, "resources").subsections[0],
            resourcesSummaryTemplate:
            filterData(this.props.apply.sections, "resources").subsections[
            filterData(this.props.apply.sections, "resources").subsections.length - 1
            ],
          },
          async () => {
            await this.props.clearMemberResources(
              this.state.resourcesMemberTemplate,
              this.state.resourcesSummaryTemplate,
              filterData(this.props.apply.sections, "resources")
            );
          }
        );
      }
    }
  };
  //Render Names on Main Care and Kids Page
  renderCaringKidsAdults = () => {
    let houseMemberList = this.mapMemberListOptions();
    let expenseSection = filterData(this.props.apply.sections, "expenses");
    //LOAD THE MEMEBER DETAILS IF FRESH APPLY PROCESS
    if (!resumeApply) {
      expenseSection.subsections[1].questions[0].options = [];
      expenseSection.subsections[1].questions[0].options = houseMemberList;
    }
    // if (expenseSection.subsections[1].questions[0].conditionals.length !== expenseSection.subsections[1].questions[0].length) {
    if (!resumeApply) {
      let conditionalQuestionTemplate =
        expenseSection.subsections[1].questions[0].conditionals[0];
      expenseSection.subsections[1].questions[0].conditionals = [];
      for (var memIndex = 0; memIndex < houseMemberList.length; memIndex++) {
        // let alreadyFilled = expenseSection.subsections[1].questions[0].conditionals.filter(cond => cond.valueToMatch === houseMemberList[memIndex].optionValue)
        // if (!alreadyFilled.length) {
        expenseSection.subsections[1].questions[0].conditionals[memIndex] =
          _.cloneDeep(conditionalQuestionTemplate);
        expenseSection.subsections[1].questions[0].conditionals[
          memIndex
        ].valueToMatch = houseMemberList[memIndex].optionValue;
        expenseSection.subsections[1].questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].label = houseHoldMultilanguageLabels(
          houseMemberList[memIndex].optionDisplay+1,
          language
        ).conditionalLabel;
        expenseSection.subsections[1].questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].name = "recipient-of-care-" + [memIndex];
        expenseSection.subsections[1].questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].options = [];
        for (let houseMemberListVal of houseMemberList) {
          if (
            houseMemberListVal.optionValue !==
            houseMemberList[memIndex].optionValue
          ) {
            let members = {};
            members[OptionsProps.OPTION_VALUE] = houseMemberListVal.optionValue;
            members[OptionsProps.OPTION_DISPLAY] =
              houseMemberListVal.optionDisplay;
            expenseSection.subsections[1].questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].options.push(members);
          }
        }
        // }
      }
    }
    if (
      this.state.kidsAdultExpenseSubSectionCleared === TextProps.VALUE_FALSE
    ) {
      this.setState({
        kidsAdultExpenseSubSectionCleared: TextProps.VALUE_TRUE,
        kidsAdultExpenseMemberTemplate: filterData(this.props.apply.sections, "expenses")
          .subsections[1].subsections
          ? filterData(this.props.apply.sections, "expenses").subsections[1].subsections[0]
          : expenseKidsAndAdultsTemplate.subsections[0],
      });
    }
    // }
    if (!resumeApply) {
      expenseSection.subsections[2].questions[0].options = [];
      expenseSection.subsections[2].questions[0].options = houseMemberList;
    }
    this.props.appendExpenseDetails(expenseSection);
  };
  //clearning Medical Expense Tempalte
  onMedicalExpenseSectionClean = () => {
    let houseMemberList = this.mapMemberListOptions();
    let expenseData = filterData(this.props.apply.sections, "expenses");
    //appending Names
    if (!resumeApply) {
      expenseData.subsections[3].questions[0].options = [];
      expenseData.subsections[3].questions[0].options = houseMemberList;
    }
    if (this.state.medicalExpenseSubSectionCleared === TextProps.VALUE_FALSE) {
      //medicalExpenseMemberTemplate
      this.setState(
        {
          medicalExpenseSubSectionCleared: TextProps.VALUE_TRUE,
          medicalExpenseMemberTemplate:
          filterData(this.props.apply.sections, "expenses").subsections[3].subsections !==
            undefined
              ? filterData(this.props.apply.sections, "expenses").subsections[3].subsections[0]
              : null,
        },
        () => {
          this.props.appendExpenseDetails(expenseData);
        }
      );
    }
  };
  //Rendering Dynamic Medical Expense Template for Members
  renderMedicalExpenseSubTemplates = (membersList) => {
    let expenseSection = filterData(this.props.apply.sections, "expenses");
    let memberOptions =
    filterData(this.props.apply.sections, "expenses").subsections[3].questions[0].options;
    for (let member = 0; member < memberOptions.length; member++) {
      if (membersList.length > 0 && membersList[0] !== "") {
        for (let memIndex = 0; memIndex < membersList.length; memIndex++) {
          if (memberOptions[member].optionValue === membersList[memIndex]) {
            let memAlreayExists = expenseSection.subsections[3].subsections
              ? expenseSection.subsections[3].subsections.filter(
                  (sub) => sub.clientId === membersList[memIndex]
                )
              : [];
            if (!memAlreayExists.length) {
              // expenseSection.subsections[3].subsections = [];
              let currentMember = memberOptions[member].optionDisplay;
              expenseSection.subsections[3].subsections[memIndex] = _.cloneDeep(
                expenseMedicalExpenseTemplate.subsections[0]
              );
              expenseSection.subsections[3].subsections[memIndex].id =
                "medical-expenses-type" + [memIndex];
                expenseSection.subsections[3].subsections[memIndex].subsections[0].id =
                "medical-expenses-filter" + [memIndex];
                expenseSection.subsections[3].subsections[memIndex].subsections[0].subsections[0].id =
                "medical-driving-" + [memIndex];
              expenseSection.subsections[3].subsections[memIndex].clientId =
                memberOptions[member].optionValue;
              expenseSection.subsections[3].subsections[memIndex].title =
                currentMember;
              expenseSection.subsections[3].subsections[memIndex].header =
                medicalExpenseMultilanguageLabels(
                  currentMember,
                  language
                ).headingExpense;
                expenseSection.subsections[3].subsections[memIndex].subsections[0].header =
                medicalExpenseMultilanguageLabels(
                  currentMember,
                  language
                ).headingExpense;
              expenseSection.subsections[3].subsections[
                memIndex
              ].subsections[0].questions[0].label = medicalExpenseMultilanguageLabels(
                currentMember,
                language
              ).question;
              expenseSection.subsections[3].subsections[memIndex].subsections[0].subsections[0].header = 
              medicalExpenseMultilanguageLabels(
                currentMember,
                language
              ).headingExpense;
       
              //medical section drive question
              expenseSection.subsections[3].subsections[
                memIndex
              ].subsections[0].subsections[0].title = currentMember;


              expenseSection.subsections[3].subsections[
                memIndex
              ].subsections[0].subsections[0].questions[0].label =    medicalExpenseMultilanguageLabels(
                currentMember,
                language
              ).question;
              expenseSection.subsections[3].subsections[
                memIndex
              ].subsections[0].subsections[0].questions[0].conditionals[0].conditionalQuestions[0].label =
                medicalExpenseMultilanguageLabels(
                  currentMember,
                  language
                ).drivetoLabel;
              if (resumeApply) {
                expenseSection.subsections[3].questions[0].options.forEach(
                  function (member) {
                    expenseSection.subsections[3].subsections[0].Answer.forEach(
                      function (selectedMember) {
                        if (selectedMember.id === member.optionValue) {
                          expenseSection.subsections[3].subsections[
                            memIndex
                          ].questions[0].Answer = selectedMember.drive;
                          expenseSection.subsections[3].subsections[
                            memIndex
                          ].questions[0].targetValue = selectedMember.drive;
                          expenseSection.subsections[3].subsections[
                            memIndex
                          ].questions[0].conditionals[0].conditionalQuestions[0].Answer =
                            selectedMember.count;
                          expenseSection.subsections[3].subsections[
                            memIndex
                          ].questions[0].conditionals[0].conditionalQuestions[0].targetValue =
                            selectedMember.count;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[0].Answer = selectedMember.address;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[1].Answer = selectedMember.cost;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[2].Answer = selectedMember.frequency;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[0].targetValue = selectedMember.address;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[1].targetValue = selectedMember.cost;
                          // expenseSection.subsections[3].subsections[memIndex].subsections[0].questions[2].targetValue = selectedMember.frequency;
                        }
                      },
                      this
                    );
                  }
                );
              }
            }
          }
        }
      } else {
        delete expenseSection.subsections[3].subsections;
      }
    }
    this.props.appendExpenseDetails(expenseSection);
  };
  //Rendering Dynamic Income Template for Members
  onIncomeSubsectionRender = (dataSections) => {
    if (filterData(this.props.apply.sections, "income")) {
    }
    let existingIncome = _.cloneDeep(filterData(this.props.apply.sections, "income").subsections);
    let existingInconeDeatils = existingIncome.splice(-1)[0];
    const answer = dataSections.questions[0].Answer;
    const options = dataSections.questions[0].options;
    const subsections = dataSections.subsections;
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate =
      this.state.incomeMemberTemplate &&
      this.state.incomeMemberTemplate.subsections
        ? this.state.incomeMemberTemplate
        : IncomeSectionTemplate;
    var incomeSummaryTemplate = this.state.incomeSummaryTemplate
      ? this.state.incomeSummaryTemplate
      : filterData(this.props.apply.sections, "income").subsections[
        filterData(this.props.apply.sections, "income").subsections.length - 1
        ];
    var incomeCopy = filterData(this.props.apply.sections, "income");
    let questionClearOption = incomeMultilanguageLabels(
      "",
      language
    ).questionClearOption;
    if (answer[0] === "" || answer[0] === questionClearOption) {
      // nothing to retrun
    } else if (incomeMeTemplate.length === undefined) {
      for (var i = 0; i < answer.length; i++) {
        let memExists = existingIncome.filter(
          (income) => income.clientId === answer[i]
        );
        if (!memExists.length) {
          let MemberName = "";
          for (
            var optionIndex = 0;
            optionIndex < incomeCopy.questions[0].options.length;
            optionIndex++
          ) {
            if (
              incomeCopy.questions[0].options[optionIndex].optionValue ===
              answer[i]
            ) {
              MemberName =
                incomeCopy.questions[0].options[optionIndex].optionDisplay;
            }
          }
          incomeMeTemplate.id = "combined-income-" + i;
          incomeMeTemplate.header = incomeMultilanguageLabels(
            MemberName,
            language
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] =
            incomeMultilanguageLabels(MemberName, language).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "combined-income-subsection-" + i;
          incomeMeTemplate.subsections[0].header = incomeMultilanguageLabels(
            MemberName,
            language
          ).heading;
          incomeMeTemplate.subsections[0].title = MemberName;
          incomeMeTemplate.title = MemberName;
          incomeMeTemplate.clientId = answer[i];
          let incomeReturn = incomeMultilanguageLabels(MemberName, language);
            this.incomeRenderSectionLabelMapping(
              5,
              incomeMeTemplate,
              incomeReturn
            );
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        }
        // else {
        //   incomeMemberMultipleTemplate.push(_.cloneDeep(memExists[0]));
        // }
      }
    }
    if (existingIncome.length) {
      existingIncome.forEach((income) => {
        let incomeFilled = answer.filter((ans) => ans === income.clientId);
        if (incomeFilled.length) {
          incomeMemberMultipleTemplate.push(income);
        }
      });
    }
    if (this.props.match.params.webAppId !== undefined) {
      incomeCopy.subsections = [];
      incomeCopy.subsections = incomeMeTemplate;
    } else {
      if (answer[0] !== questionClearOption && answer.length > 0) {
        incomeSummaryTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
        incomeSummaryTemplate.active = TextProps.VALUE_TRUE;
        incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
      }
      incomeCopy.subsections = [];
      incomeCopy.subsections = incomeMemberMultipleTemplate;
    }
    const optionsObj = options.find((item) => item.optionValue == answer);
    if (
      optionsObj &&
      subsections[0] &&
      subsections[0].title == optionsObj.optionDisplay &&
      incomeCopy.subsections[0]
    ) {
      incomeCopy.subsections[0].questions[0].Answer =
        subsections[0].questions[0].Answer;
      incomeCopy.subsections[0].questions[0].targetValue =
        subsections[0].questions[0].targetValue;
      // incomeCopy.subsections[0].subsections[0].questions = subsections[0].subsections[0].questions
    }
    this.props.appendIncomeDetails(incomeCopy);
  };
  //Rendering Dynamic Income Template for Members
  onResourcesSubsectionRender = (dataSections) => {
    let existingResources = _.cloneDeep(filterData(this.props.apply.sections, "resources").subsections);
    let existingResourcesDeatils = existingResources.splice(-1)[0];
    const answer = dataSections.questions[0].Answer && dataSections.questions[0].Answer.filter(opt => opt !== resourcesSectionMultilanguageLabels(language).noResourcesToReportOption);
    const options = dataSections.questions[0].options.filter(opt => opt.optionValue !== resourcesSectionMultilanguageLabels(language).noResourcesToReportOption);
    const subsections = dataSections.subsections;
    let resourcesMemberMultipleTemplate = [];
    let resourcesMeTemplate =
      this.state.resourcesMemberTemplate &&
      this.state.resourcesMemberTemplate.subsections
        ? this.state.resourcesMemberTemplate
        : ResourcesSectionTemplate;
    let resourcesSummaryTemplate = this.state.resourcesSummaryTemplate
      ? this.state.resourcesSummaryTemplate
      : filterData(this.props.apply.sections, "resources").subsections[
        filterData(this.props.apply.sections, "resources").subsections.length - 1
        ];
    let resourcesCopy = filterData(this.props.apply.sections, "resources");
    let questionClearOption1 = resourcesSectionMultilanguageLabels(
      language,
      null
    ).noResourcesToReportOption;
    let questionClearOption2 = resourcesSectionMultilanguageLabels(
      language,
      null
    ).questionClearOption;
    if (answer[0] === "" || answer[0] === questionClearOption1 || answer[0] === questionClearOption2) {
      // nothing to retrun
    } else if (resourcesMeTemplate.length === undefined) {
      for (let i = 0; i < answer.length; i++) {
        let memExists = existingResources.filter(
          (income) => income.clientId === answer[i]
        );
        if (!memExists.length) {
          let MemberName = "";
          for (
            let optionIndex = 0;
            optionIndex < resourcesCopy.questions[0].options.length;
            optionIndex++
          ) {
            if (
              resourcesCopy.questions[0].options[optionIndex].optionValue ===
              answer[i]
            ) {
              MemberName =
              resourcesCopy.questions[0].options[optionIndex].optionDisplay;
            }
          }
          resourcesMeTemplate.id = "combined-resources-" + i;
          resourcesMeTemplate.header = resourcesSectionMultilanguageLabels(
            language,
            MemberName
          ).heading;
          resourcesMeTemplate.questions[0][Props.LABEL] = resourcesSectionMultilanguageLabels(language).resourceSubSectiontitle
          resourcesMeTemplate.subsections[0].id =
            "combined-resources-subsection-" + i;
          resourcesMeTemplate.subsections[0].header = resourcesSectionMultilanguageLabels(
            language,
            MemberName
          ).heading;
          resourcesMeTemplate.subsections[0].title = MemberName;
          resourcesMeTemplate.title = MemberName;
          resourcesMeTemplate.clientId = answer[i];
          let resourcesReturn = resourcesSectionMultilanguageLabels(language,MemberName);
          if (applicationType === TextProps.MATCH_TANF) {
            this.resourcesRenderSectionLabelMapping(
              1,
              resourcesMeTemplate,
              resourcesReturn
            );
          } else {
            this.resourcesRenderSectionLabelMapping(
              0,
              resourcesMeTemplate,
              resourcesReturn
            );
          }
          resourcesMemberMultipleTemplate.push(_.cloneDeep(resourcesMeTemplate));
        }
        // else {
        //   resourcesMemberMultipleTemplate.push(_.cloneDeep(memExists[0]));
        // }
      }
    }
    if (existingResources.length) {
      existingResources.forEach((resource) => {
        let incomeFilled = answer.filter((ans) => ans === resource.clientId);
        if (incomeFilled.length) {
          resourcesMemberMultipleTemplate.push(resource);
        }
      });
    }
    if (this.props.match.params.webAppId !== undefined) {
      resourcesCopy.subsections = [];
      resourcesCopy.subsections = resourcesMeTemplate;
    } else {
      if (answer[0] !== questionClearOption1 && answer[0] !== questionClearOption2 && answer.length > 0  && resourcesSummaryTemplate) {
        resourcesSummaryTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
        resourcesSummaryTemplate.active = TextProps.VALUE_TRUE;
        resourcesMemberMultipleTemplate.push(resourcesSummaryTemplate);
      }
      resourcesCopy.subsections = [];
      resourcesCopy.subsections = resourcesMemberMultipleTemplate;
    }
    const optionsObj = options.find((item) => item.optionValue == answer);
    if (
      optionsObj &&
      subsections[0] &&
      subsections[0].title == optionsObj.optionDisplay &&
      resourcesCopy.subsections[0]
    ) {
      resourcesCopy.subsections[0].questions[0].Answer =
        subsections[0].questions[0].Answer;
      resourcesCopy.subsections[0].questions[0].targetValue =
        subsections[0].questions[0].targetValue;
      // incomeCopy.subsections[0].subsections[0].questions = subsections[0].subsections[0].questions
    }
    this.props.appendResourcesDetails(resourcesCopy);
  };
  //Function to render kids-and-adults section when new sata is entered.
  addMultipleKidAddress = (activeId) => {
    let kidsAdultsForMember =
      filterData(this.props.apply.sections, "expenses").subsections[1].subsections;
    for (
      var memberIndex = 0;
      memberIndex < kidsAdultsForMember.length;
      memberIndex++
    ) {
      let mainQuestionAnswer =
        kidsAdultsForMember[memberIndex].questions[2].Answer;
      if (
        mainQuestionAnswer ===
        kidsAdultsForMember[memberIndex].questions[2].conditionals[0]
          .valueToMatch
      ) {
        if (kidsAdultsForMember[memberIndex].id === activeId) {
          let providerAddressNo =
            kidsAdultsForMember[memberIndex].questions[2].conditionals[0]
              .conditionalQuestions[0].Answer;
          if (
            kidsAdultsForMember[memberIndex].subsections &&
            kidsAdultsForMember[memberIndex].subsections[0].parentId
          ) {
            let providerCount = Number(providerAddressNo);
            if (
              kidsAdultsForMember[memberIndex].subsections.length !==
              Number(providerAddressNo)
            ) {
              let additionalAddedMem =
                providerCount -
                kidsAdultsForMember[memberIndex].subsections.length;
              for (
                var bindAddressIndex = 0;
                bindAddressIndex < additionalAddedMem;
                bindAddressIndex++
              ) {
                let originalLength =
                  kidsAdultsForMember[memberIndex].subsections.length +
                  bindAddressIndex;
                kidsAdultsForMember[memberIndex].subsections[originalLength] =
                  _.cloneDeep(
                    expenseKidsAndAdultsTemplate.subsections[0].subsections[0]
                  );
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].id =
                  "kids-adult-multiple-address-" +
                  memberIndex +
                  "-" +
                  originalLength;
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].header =
                  childDependnMultilanguageLabels(
                    kidsAdultsForMember[memberIndex].dependentMembers.split(
                      "-"
                    )[0],
                    "",
                    language
                  ).chAdultCareAddrHeader +
                  " " +
                  (originalLength + 1);
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].questions[2].label = childDependnMultilanguageLabels(
                  kidsAdultsForMember[memberIndex].dependentMembers.split(
                    "-"
                  )[0],
                  "",
                  language
                ).subSectionQuestionLabel;
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].title =
                  kidsAdultsForMember[memberIndex].dependentMembers.split(
                    "-"
                  )[1];
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].questions[2].label = childDependnMultilanguageLabels(
                  kidsAdultsForMember[memberIndex].dependentMembers.split(
                    "-"
                  )[0],
                  "",
                  language
                ).subSectionQuestionLabel;
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].providerAddressNo = providerAddressNo;
                kidsAdultsForMember[memberIndex].subsections[
                  originalLength
                ].parentId = activeId;
              }
            }
          } else {
            kidsAdultsForMember[memberIndex].subsections = [];
            for (
              var bindAddressIndex = 0;
              bindAddressIndex < providerAddressNo;
              bindAddressIndex++
            ) {
              kidsAdultsForMember[memberIndex].subsections[bindAddressIndex] =
                _.cloneDeep(
                  expenseKidsAndAdultsTemplate.subsections[0].subsections[0]
                );
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].id =
                "kids-adult-multiple-address-" +
                memberIndex +
                "-" +
                bindAddressIndex;
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].header =
                childDependnMultilanguageLabels(
                  kidsAdultsForMember[memberIndex].dependentMembers.split(
                    "-"
                  )[0],
                  "",
                  language
                ).chAdultCareAddrHeader +
                " " +
                (bindAddressIndex + 1);
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].questions[2].label = childDependnMultilanguageLabels(
                kidsAdultsForMember[memberIndex].dependentMembers.split("-")[0],
                "",
                language
              ).subSectionQuestionLabel;
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].title =
                kidsAdultsForMember[memberIndex].dependentMembers.split("-")[1];
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].label =
                incomeSectionMultilanguageLabels(language).addressProvider;
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].providerAddressNo = providerAddressNo;
              kidsAdultsForMember[memberIndex].subsections[
                bindAddressIndex
              ].parentId = activeId;
            }
          }
        }
        this.props.appendExpenseDetails(filterData(this.props.apply.sections, "expenses"));
      } else {
        delete kidsAdultsForMember[memberIndex].subsections;
        this.props.appendExpenseDetails(filterData(this.props.apply.sections, "expenses"));
      }
    }
    // for (var bindAddressIndex = 0; bindAddressIndex < kidsAdultsForMember; bindAddressIndex++) {

    // }
    // let mainQuestionAnswer = kidsAdultsForMember.questions[2].Answer;

    // if (this.providerAddressTemplate[memberIndex] === undefined) {
    //   this.providerAddressTemplate[memberIndex] = _.cloneDeep(kidsAdultsForMember.subsections ? kidsAdultsForMember.subsections : expenseKidsAndAdultsTemplate.subsections);
    //   // if(kidsAdultsForMember.questions[2].conditionals)
    //   // this.providerAddressTemplate[memberIndex][0].providerAddressNo = kidsAdultsForMember.questions[2].conditionals[0].conditionalQuestions[0].Answer;
    // } else {
    //   kidsAdultsForMember.subsections = _.cloneDeep(this.providerAddressTemplate[memberIndex]);
    // }
    // if (mainQuestionAnswer === kidsAdultsForMember.questions[2].conditionals[0].valueToMatch) {
    //   let providerAddressNo = kidsAdultsForMember.questions[2].conditionals[0].conditionalQuestions[0].Answer;
    //   let kidsAdultsForMemberAlreadyExists = TextProps.VALUE_FALSE;
    //   if(kidsAdultsForMember.subsections && kidsAdultsForMember.subsections[0].providerAddressNo && kidsAdultsForMember.subsections[0].providerAddressNo === providerAddressNo){
    //     kidsAdultsForMemberAlreadyExists = TextProps.VALUE_TRUE;
    //   }
    //   if (!kidsAdultsForMemberAlreadyExists) {
    //     kidsAdultsForMember.subsections = [];
    //     for (var bindAddressIndex = 0; bindAddressIndex < providerAddressNo; bindAddressIndex++) {
    //       kidsAdultsForMember.subsections[bindAddressIndex] = _.cloneDeep(this.providerAddressTemplate[memberIndex][0].subsections[0]);
    //       kidsAdultsForMember.subsections[bindAddressIndex].id = "kids-adult-multiple-address-" + memberIndex + "-" + bindAddressIndex;
    //       kidsAdultsForMember.subsections[bindAddressIndex].header = kidsAdultsForMember.subsections[bindAddressIndex].header + " " + (bindAddressIndex + 1);
    //       kidsAdultsForMember.subsections[bindAddressIndex].providerAddressNo = providerAddressNo;
    //     }
    //   }
    // } else {
    //   delete kidsAdultsForMember.subsections;
    // }
  };
  //Function to Manipulate Details on Income page
  updateHouseHoldIncomeDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    var memberListInfo = [];
    var incomeAboutMe = filterData(this.props.apply.sections, "about-me").questions;
    var incomeAboutMeName =
      incomeAboutMe[0].Answer + " " + incomeAboutMe[2].Answer;
    var incomeAboutMeFull;
    // binding DOB
    let aboutMeDob = incomeAboutMe[4].Answer;
    if (aboutMeDob !== "" && aboutMeDob !== undefined) {
      incomeAboutMeFull =
        incomeAboutMeName + " " + this._convertDateToState(aboutMeDob);
    } else {
      incomeAboutMeFull = incomeAboutMeName;
    }
    memberObject[OptionsProps.OPTION_DISPLAY] = incomeAboutMeFull;
    memberObject.id = filterData(this.props.apply.sections, "before-you-start").webClientId;
    memberObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.apply.sections, "before-you-start").webClientId;
    memberObject.isGrantee = TextProps.VALUE_Y;
    incomeNameList.push(incomeAboutMeFull);
    memberListInfo.push(memberObject);
    var houseHoldMembersCopy = filterData(this.props.apply.sections, "my-household").subsections;
    var incomeCopy = filterData(this.props.apply.sections, "income");
    for (
      var memberListIndex = 0;
      memberListIndex <
      houseHoldMembersCopy[houseHoldMembersCopy.length - 1].questions[0].options
        .length -
        1;
      memberListIndex++
    ) {
      let childMemberObject = _.cloneDeep(memberObject);
      var incomeHouseHoldName =
        houseHoldMembersCopy[memberListIndex].questions[0].Answer;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName =
          incomeHouseHoldName +
          " " +
          houseHoldMembersCopy[memberListIndex].questions[2].Answer;
        let incomeHouseHoldDOB = this._convertDateToState(
          houseHoldMembersCopy[memberListIndex].questions[5].Answer
        );
        if (houseHoldMembersCopy[memberListIndex].questions[5].Answer !== "") {
          incomeCombinedName = incomeCombinedName + " " + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = houseHoldMembersCopy[memberListIndex].title;
      }
      // binding the dependents clientIds
      childMemberObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.apply.sections, "my-household").subsections[memberListIndex].clientId;
      childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
      childMemberObject.id =
      filterData(this.props.apply.sections, "my-household").subsections[memberListIndex].dependentId;
      childMemberObject.isGrantee = TextProps.VALUE_N;
      memberListInfo.push(childMemberObject);
      incomeNameList.push(incomeCombinedName);
    }
    //ignoring income manipulation and taking from disability menu
    memberListInfo =
    filterData(this.props.apply.sections, "my-household").subsections[
      filterData(this.props.apply.sections, "my-household").subsections.length - 1
      ].questions[0].options;
    this.setState({ houseMemberLabels: incomeNameList });
    incomeCopy.questions[0].options = memberListInfo;
    incomeCopy.questions[0].memberOptions = incomeNameList;
    this.props.appendIncomeDetails(incomeCopy);
  };
  //Function to Manipulate Details on Income page
  updateHouseHoldResourcesDetails = () => {
      let memberObject = {};
      let resourcesNameList = [];
      let memberListInfo = [];
      let resourcesAboutMe = filterData(this.props.apply.sections, "about-me").questions;
      let resourcesAboutMeName =
      resourcesAboutMe[0].Answer + " " + resourcesAboutMe[2].Answer;
      let resourcesAboutMeFull;
      // binding DOB
      let aboutMeDob = resourcesAboutMe[4].Answer;
      if (aboutMeDob !== "" && aboutMeDob !== undefined) {
        resourcesAboutMeFull =
        resourcesAboutMeName + " " + this._convertDateToState(aboutMeDob);
      } else {
        resourcesAboutMeFull = resourcesAboutMeName;
      }
      memberObject[OptionsProps.OPTION_DISPLAY] = resourcesAboutMeFull;
      memberObject.id = filterData(this.props.apply.sections, "before-you-start").webClientId;
      memberObject[OptionsProps.OPTION_VALUE] =
        filterData(this.props.apply.sections, "before-you-start").webClientId;
      memberObject.isGrantee = TextProps.VALUE_Y;
      resourcesNameList.push(resourcesAboutMeFull);
      memberListInfo.push(memberObject);
      let houseHoldMembersCopy = filterData(this.props.apply.sections, "my-household").subsections;
      let resourcesCopy = filterData(this.props.apply.sections, "resources");
      for (
        let memberListIndex = 0;
        memberListIndex <
        houseHoldMembersCopy[houseHoldMembersCopy.length - 1].questions[0].options
          .length -
          1;
        memberListIndex++
      ) {
        let childMemberObject = _.cloneDeep(memberObject);
        let resourcesHouseHoldName =
          houseHoldMembersCopy[memberListIndex].questions[0].Answer;
        let resourcesCombinedName = "";
        if (resourcesHouseHoldName !== "") {
          resourcesCombinedName =
          resourcesHouseHoldName +
            " " +
            houseHoldMembersCopy[memberListIndex].questions[2].Answer;
          let resourcesHouseHoldDOB = this._convertDateToState(
            houseHoldMembersCopy[memberListIndex].questions[5].Answer
          );
          if (houseHoldMembersCopy[memberListIndex].questions[5].Answer !== "") {
            resourcesCombinedName = resourcesCombinedName + " " + resourcesHouseHoldDOB;
          }
        } else if (resourcesHouseHoldName === "") {
          resourcesCombinedName = houseHoldMembersCopy[memberListIndex].title;
        }
        // binding the dependents clientIds
        childMemberObject[OptionsProps.OPTION_VALUE] =
        filterData(this.props.apply.sections, "my-household").subsections[memberListIndex].clientId;
        childMemberObject[OptionsProps.OPTION_DISPLAY] = resourcesCombinedName;
        childMemberObject.id =
        filterData(this.props.apply.sections, "my-household").subsections[memberListIndex].dependentId;
        childMemberObject.isGrantee = TextProps.VALUE_N;
        memberListInfo.push(childMemberObject);
        resourcesNameList.push(resourcesCombinedName);
      }
      //ignoring income manipulation and taking from disability menu
      // memberListInfo =
      // _.cloneDeep(filterData(this.props.apply.sections, "my-household").subsections[
      //   filterData(this.props.apply.sections, "my-household").subsections.length - 1
      //   ].questions[0].options);
      // this.setState({ houseMemberLabels: resourcesNameList });
      if(!memberListInfo.some(options => options.optionValue === resourcesSectionMultilanguageLabels(language).noResourcesToReportOption)){
        let childMemberObject = _.cloneDeep(memberObject);
        childMemberObject[OptionsProps.OPTION_VALUE] = resourcesSectionMultilanguageLabels(language).noResourcesToReportOption;
        childMemberObject[OptionsProps.OPTION_DISPLAY] = resourcesSectionMultilanguageLabels(language).noResourcesToReportOption;
        childMemberObject.isGrantee = TextProps.VALUE_N;
        memberListInfo.push(childMemberObject);
      }
      resourcesCopy.questions[0].options = memberListInfo;
      resourcesCopy.questions[0].memberOptions = resourcesNameList;
      this.props.appendResourcesDetails(resourcesCopy);
    };
  //Function to Manipulate Details on HouseHold page
  updateHouseHoldDetails = () => {
    var houseHoldMembers = filterData(this.props.apply.sections, "my-household").subsections;
    let aboutMeSectionQuestions = filterData(this.props.apply.sections, "about-me").questions;
    let aboutMeName =
      aboutMeSectionQuestions[0].Answer +
      " " +
      aboutMeSectionQuestions[2].Answer;
    // binding DOB
    let aboutMeDob = aboutMeSectionQuestions[4].Answer;
    if (aboutMeDob !== "" && aboutMeDob !== undefined) {
      aboutMeName =
        aboutMeName +
        " " +
        this._convertDateToState(aboutMeSectionQuestions[4].Answer);
    }
    let houseHoldMemberOptions = [];
    let beforeStartPage = filterData(this.props.apply.sections, "before-you-start");
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options = [];
    let aboutMeOption = {};
    aboutMeOption[OptionsProps.OPTION_VALUE] = beforeStartPage.webClientId;
    aboutMeOption[OptionsProps.OPTION_DISPLAY] = aboutMeName;
    aboutMeOption.isGrantee = TextProps.VALUE_Y;
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
      aboutMeOption
    );
    for (
      var memberIndex = 0;
      memberIndex < houseHoldMembers.length - 1;
      memberIndex++
    ) {
      // changed if member first name not entered , taking count of index namespace title
      var memName = houseHoldMembers[memberIndex].questions[0].Answer;
      if (memName !== "" && memName !== undefined) {
        memName =
          houseHoldMembers[memberIndex].questions[0].Answer +
          " " +
          houseHoldMembers[memberIndex].questions[2].Answer;
        // binding DOB
        let memDob = houseHoldMembers[memberIndex].questions[5].Answer;
        if (memDob !== "" && memDob !== undefined) {
          memName =
            memName +
            " " +
            this._convertDateToState(
              houseHoldMembers[memberIndex].questions[5].Answer
            );
        }
      } else {
        memName = houseHoldMembers[memberIndex].title;
      }
      houseHoldMemberOptions[memberIndex] = {};
      houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_VALUE] =
        "DEP-" + [memberIndex + 1];
      houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_DISPLAY] =
        memName;
      houseHoldMemberOptions[memberIndex].isGrantee = TextProps.VALUE_N;
      houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
        houseHoldMemberOptions[memberIndex]
      );
    }
    this.props.appendHouseHoldMembers(houseHoldMembers);
  };
  //Function to render kidsAdultsTemplate
  renderCaringKidsAdultsTemplate = (answerObj) => {
    //Still this function not completed until answer sent
    let expenseSection = filterData(this.props.apply.sections, "expenses");
    expenseSection.subsections[1].subsections = [];
    if (answerObj.Answer.length > 0 && answerObj.Answer[0] !== "") {
      for (let memIndex = 0; memIndex < answerObj.Answer.length; memIndex++) {
        expenseSection.subsections[1].subsections[memIndex] = _.cloneDeep(
          this.state.kidsAdultExpenseMemberTemplate
        );
        expenseSection.subsections[1].subsections[memIndex].id =
          "kid-adult-expenses-" + [memIndex];
        expenseSection.subsections[1].subsections[memIndex].header =
          medicalExpenseMultilanguageLabels(
            answerObj.Answer[memIndex],
            language
          ).kitHeaderLabel;
      }
    } else {
      delete expenseSection.subsections[1].subsections;
    }
    this.props.appendExpenseDetails(expenseSection);
  };
  // rendering Income Dynamic Questions.
  householdMemUpdated = (memIndex) => {
    let houseHoldSection = filterData(this.props.apply.sections, "my-household");
    let houseHoldMemberIndex = houseHoldSection.subsections.findIndex(
      (sub) => sub.id === memIndex
    );
    houseHoldSection.subsections[
      houseHoldSection.subsections.length - 1
    ].questions[0].options.map((opt) => {
      if (
        houseHoldSection.subsections[houseHoldMemberIndex].clientId ===
        opt.optionValue
      )
        opt.optionDisplay =
          houseHoldSection.subsections[houseHoldMemberIndex].title;
    });
    let incomeSection = filterData(this.props.apply.sections, "income");
    let existingMem = incomeSection.questions[0].options.filter(
      (opt) =>
        opt.optionValue ===
        houseHoldSection.subsections[houseHoldMemberIndex].clientId
    );
    if (!existingMem.length) {
      let optionObj = {};
      optionObj[OptionsProps.OPTION_VALUE] =
        houseHoldSection.subsections[houseHoldMemberIndex].clientId;
      optionObj[OptionsProps.OPTION_DISPLAY] =
        houseHoldSection.subsections[houseHoldMemberIndex].title;
      optionObj.isGrantee = TextProps.VALUE_N;
      filterData(this.props.apply.sections, "income").questions[0].options.push(optionObj);
    }
  };
  //Function to filter questions by selection.
  incomefilterQuestionsBySelection = (memIndex) => {
    let incomeSection = filterData(this.props.apply.sections, "income");
    let memberIndex = incomeSection.subsections.findIndex(
      (sub) => sub.id === memIndex
    );
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    let oldQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    // if (this.incomeSubSections[memberIndex] === undefined) {
    //   this.incomeSubSections[memberIndex] = _.cloneDeep(incomeSection.subsections[memberIndex]);
    // } else {
    if (
      incomeSection.subsections[memberIndex] &&
      incomeSection.subsections[memberIndex].subsections &&
      incomeSection.subsections[memberIndex].subsections[0]
    ) {
      const allQuestions =
        incomeSection.subsections[memberIndex].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      incomeSection.subsections[memberIndex].subsections[0].questions =
        questions;
    }
    // // incomeSection.subsections[memberIndex] = _.cloneDeep(this.incomeSubSections[memberIndex]);
    // incomeSection.subsections[memberIndex].questions[0].Answer = incomePageAnswers;
    // incomeSection.subsections[memberIndex].questions[0].targetValue = incomePageAnswers;
    // if (oldQuestions.length) {
    //   incomeSection.subsections[memberIndex].subsections[0].questions.forEach((item) => {
    //     const existing = oldQuestions.find((oldItem) => item.name == oldItem.name)
    //     if (existing) {
    //       item.Answer = existing.Answer
    //       item.targetValue = existing.targetValue
    //     }
    //   })
    // }
    // }
    // filterData(this.props.apply.sections, "income").currentMember = this.incomeSubSections[memberIndex].title;
    filterData(this.props.apply.sections, "income").currentMember =
      incomeSection.subsections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    let noneTargetValue = incomeMultilanguageLabels(
      "",
      language
    ).noneTargetValue;
    if (
      incomePageAnswers &&
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !== noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (
        let filterQuestionIndex = 0;
        filterQuestionIndex < incomeFilteredQuestions.length;
        filterQuestionIndex++
      ) {
        if (oldQuestions !== undefined) {
          for (
            let oldQuestionIndex = 0;
            oldQuestionIndex < oldQuestions.length;
            oldQuestionIndex++
          ) {
            if (
              oldQuestions[oldQuestionIndex].valueToMatch ===
                incomeFilteredQuestions[filterQuestionIndex].valueToMatch &&
              oldQuestions[oldQuestionIndex].name ===
                incomeFilteredQuestions[filterQuestionIndex].name
            ) {
              incomeFilteredQuestions[filterQuestionIndex].Answer =
                oldQuestions[oldQuestionIndex].Answer;
              incomeFilteredQuestions[filterQuestionIndex].targetValue =
                oldQuestions[oldQuestionIndex].targetValue;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.props.appendIncomeDetails(incomeSection);
    }
  }
  //Function to filter questions by selection.
  resourcesfilterQuestionsBySelection = (memIndex,dataSections) => {
    let resourcesSection = filterData(this.props.apply.sections, "resources");
    let memberIndex = resourcesSection.subsections.findIndex(
      (sub) => sub.id === memIndex
    );
    resourcesSection.subsections[memberIndex] = dataSections;
    let resourcesPageAnswers =
    resourcesSection.subsections[memberIndex].questions[0].Answer;
    let resourcesFilteredQuestions = [];
    let oldQuestions = [];
    if (resourcesSection.subsections[memberIndex].subsections !== undefined) {
      oldQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].questions;
    }
    if (
      resourcesSection.subsections[memberIndex] &&
      resourcesSection.subsections[memberIndex].subsections &&
      resourcesSection.subsections[memberIndex].subsections[0]
    ) {
      const allQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        questions;
    }
    filterData(this.props.apply.sections, "income").currentMember =
    resourcesSection.subsections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    let noneTargetValue = incomeMultilanguageLabels(
      "",
      language
    ).noneTargetValue;
    if (
      resourcesPageAnswers[0] !== "" &&
      resourcesPageAnswers[0] !== undefined &&
      resourcesPageAnswers[0] !== noneTargetValue
    ) {
      resourcesFilteredQuestions = filterQuestionsBySelection(
        resourcesPageAnswers,
        resourcesSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (
        let filterQuestionIndex = 0;
        filterQuestionIndex < resourcesFilteredQuestions.length;
        filterQuestionIndex++
      ) {
        if (oldQuestions !== undefined) {
          for (
            let oldQuestionIndex = 0;
            oldQuestionIndex < oldQuestions.length;
            oldQuestionIndex++
          ) {
            if (
              oldQuestions[oldQuestionIndex].valueToMatch ===
              resourcesFilteredQuestions[filterQuestionIndex].valueToMatch &&
              oldQuestions[oldQuestionIndex].name ===
              resourcesFilteredQuestions[filterQuestionIndex].name
            ) {
              resourcesFilteredQuestions[filterQuestionIndex].Answer =
                oldQuestions[oldQuestionIndex].Answer;
              resourcesFilteredQuestions[filterQuestionIndex].targetValue =
                oldQuestions[oldQuestionIndex].targetValue;
            }
          }
        }
      }
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(resourcesFilteredQuestions);
      this.props.appendResourcesDetails(resourcesSection);
    } else {
      delete resourcesSection.subsections[memberIndex].subsections;
      this.props.appendResourcesDetails(resourcesSection);
    }
  };
  //Funtion to map the selected members to option object
  mapMemberListOptions = () => {
    // Update Expense template with HouseHold Member details
    var memberObject = {};
    var memberListInfo = [];
    var aboutMember = filterData(this.props.apply.sections, "about-me").questions;
    var memberName = aboutMember[0].Answer + " " + aboutMember[2].Answer;
    var expenseAboutMeDOB = aboutMember[4].Answer ? this._convertDateToState(aboutMember[4].Answer) : null;
    if (aboutMember[4].Answer !== "") {
      var memberFullName = memberName + expenseAboutMeDOB;
    } else {
      var memberFullName = memberName;
    }

    memberObject[OptionsProps.OPTION_DISPLAY] = memberFullName;
    memberObject[OptionsProps.OPTION_VALUE] =
    filterData(this.props.apply.sections, "before-you-start").webClientId;
    memberObject.isGrantee = TextProps.VALUE_Y;
    memberObject.firstName = aboutMember[0].Answer;
    memberObject.lastName = aboutMember[2].Answer;
    memberObject.dateOfBirth = expenseAboutMeDOB ? expenseAboutMeDOB : null;
    memberListInfo.push(memberObject);

    var houseHoldMembersCopy = filterData(this.props.apply.sections, "my-household").subsections;
    for (
      var memberListIndex = 0;
      memberListIndex <
      houseHoldMembersCopy[houseHoldMembersCopy.length - 1].questions[0].options
        .length -
        1;
      memberListIndex++
    ) {
      let childMemberObject = _.cloneDeep(memberObject);
      var expenseHouseHoldName =
        houseHoldMembersCopy[memberListIndex].questions[0].Answer;
      var expenseCombinedName = "";
      var expenseHouseHoldDate = "";
      if (expenseHouseHoldName !== "") {
        expenseCombinedName =
          expenseHouseHoldName +
          " " +
          houseHoldMembersCopy[memberListIndex].questions[2].Answer;
        expenseHouseHoldDate = houseHoldMembersCopy[memberListIndex].questions[5].Answer ? this._convertDateToState(
          houseHoldMembersCopy[memberListIndex].questions[5].Answer
        ) : null;
        if (houseHoldMembersCopy[memberListIndex].questions[5].Answer !== "") {
          expenseCombinedName = expenseCombinedName + expenseHouseHoldDate;
        }
      } else if (expenseHouseHoldName === "") {
        expenseCombinedName = houseHoldMembersCopy[memberListIndex].title;
      }
      // binding the dependents clientIds
      childMemberObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.apply.sections, "my-household").subsections[memberListIndex].clientId;
      childMemberObject[OptionsProps.OPTION_DISPLAY] = expenseCombinedName;
      childMemberObject.firstName = expenseHouseHoldName;
      childMemberObject.lastName = houseHoldMembersCopy[memberListIndex].questions[2].Answer;
      childMemberObject.dateOfBirth = houseHoldMembersCopy[memberListIndex].questions[5].Answer ? this._convertDateToState(
        houseHoldMembersCopy[memberListIndex].questions[5].Answer
      ): null;
      childMemberObject.isGrantee = TextProps.VALUE_N;
      memberListInfo.push(childMemberObject);
    } 
    return memberListInfo;
  };
  //Function to update household expense details.
  updateHouseHoldExpenseDetails = () => {
    let memberListInfo = this.mapMemberListOptions();
    var expenseCopy = filterData(this.props.apply.sections, "expenses");
    expenseCopy.subsections[2].questions[0].options = memberListInfo;
    let conditionalQuestions =
      expenseCopy.subsections[2].questions[0].conditionals;
    for (
      let index = 0;
      index < expenseCopy.subsections[2].questions[0].options.length;
      index++
    ) {
      if (conditionalQuestions[index]) {
        conditionalQuestions[index].valueToMatch =
          expenseCopy.subsections[2].questions[0].options[index].optionValue;
        conditionalQuestions[index].clientId =
          expenseCopy.subsections[2].questions[0].options[index].id;
      }
    }
    this.props.appendExpenseDetails(expenseCopy);
  };
  //Function to update household medical details.
  updateHouseHoldMedicalDetails = () => {
    let memberListInfo = this.mapMemberListOptions();
    var expenseCopy = filterData(this.props.apply.sections, "expenses");
    expenseCopy.subsections[
      expenseCopy.subsections.length - 1
    ].questions[0].options = memberListInfo;
    this.props.appendExpenseDetails(expenseCopy);
  };
  //Get Language and Appened to Answer
  setLanguageInScreen = () => {
    let currentLanguageCode = this.props.selectedLanguage;
    let currentLanguage;
    let selectedAnswer = "";
    if (
      applicationType === TextProps.MATCH_SNAP ||
      applicationType === TextProps.MATCH_COMBO
    ) {
      if (
        filterData(this.props.apply.sections, "about-me") &&
        filterData(this.props.apply.sections, "about-me").subsections[2] &&
        filterData(this.props.apply.sections, "about-me").subsections[2].questions[1]
      ) {
        selectedAnswer =
        filterData(this.props.apply.sections, "about-me").subsections[2].questions[1].Answer;
      }
    }
    if (applicationType === TextProps.MATCH_TANF) {
      if (
        filterData(this.props.apply.sections, "about-me") &&
        filterData(this.props.apply.sections, "about-me").subsections[1] &&
        filterData(this.props.apply.sections, "about-me").subsections[1].questions[1]
      ) {
        selectedAnswer =
        filterData(this.props.apply.sections, "about-me").subsections[1].questions[1].Answer;
      }
    }
    if (selectedAnswer === undefined) {
      switch (currentLanguageCode) {
        case "en-US":
          currentLanguage = "English";
          break;
        case "es-ES":
          currentLanguage = "Inglés";
          break;
        case "pt-BR":
          currentLanguage = "Inglês";
          break;
        case "zh-Hans":
          currentLanguage = "英语";
          break;
        case "vi-VI":
          currentLanguage = "Tiếng Anh";
          break;
        case "ht":
          currentLanguage = "Angle";
          break;
        default:
          currentLanguage = "English";
          break;
      }
      if (
        applicationType === TextProps.MATCH_SNAP ||
        applicationType === TextProps.MATCH_COMBO
      ) {
        filterData(this.props.apply.sections, "about-me").subsections[2].questions[1].Answer =
          currentLanguage;
        filterData(this.props.apply.sections, "about-me").subsections[2].questions[1].targetValue =
          currentLanguage;
      } else if (applicationType === TextProps.MATCH_TANF) {
        filterData(this.props.apply.sections, "about-me").subsections[1].questions[1].Answer =
          currentLanguage;
        filterData(this.props.apply.sections, "about-me").subsections[1].questions[1].targetValue =
          currentLanguage;
      }
    }
  };

  _dynamicSectionIdMatch = (activeId) => {
    switch (activeId) {
      case "my-household":
        return TextProps.VALUE_TRUE;
      case "income":
        return TextProps.VALUE_TRUE;
      default:
        return TextProps.VALUE_FALSE;
    }
  };

  //in this method 'value' it either 0 or 1 purpose is to skip first label for income options of TANF application
  incomeRenderSectionLabelMapping(value, incomeMeTemplate, incomeReturn) {
    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
        incomeReturn.subQuestions[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
    }
    for (
      let j = 0;
      j < incomeMeTemplate.subsections[0].originalQuestions.length;
      j++
    ) {
      incomeMeTemplate.subsections[0].originalQuestions[j][Props.LABEL] =
        incomeReturn.subQuestions[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
      incomeMeTemplate.subsections[0].originalQuestions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
    }
  }
  
  //in this method 'value' it either 0 or 1 purpose is to skip first label for income options of TANF application
  resourcesRenderSectionLabelMapping(value, resourcesMeTemplate, resourcesReturn) {
    for (var j = 0; j < resourcesMeTemplate.subsections[0].questions.length; j++) {
      // resourcesMeTemplate.subsections[0].questions[j][Props.LABEL] =
      // resourcesReturn.combineResourcesoptions[j];
      // resourcesMeTemplate.subsections[0].questions[j].repeatedQuestion[
      //   Props.LABEL
      // ] = resourcesReturn.combineResourcesoptions[j];
      resourcesMeTemplate.subsections[0].questions[j].repeatedQuestion.subLabel = resourcesReturn.combineResourcesOptionsLabels[j];
      resourcesMeTemplate.subsections[0].questions[j].repeatedQuestion.subHeader = resourcesReturn.combineResourcesoptions[j];
    }
    for (
      let j = 0;
      j < resourcesMeTemplate.subsections[0].originalQuestions.length;
      j++
    ) {
      // resourcesMeTemplate.subsections[0].originalQuestions[j][Props.LABEL] =
      // resourcesReturn.combineResourcesoptions[j];
      // resourcesMeTemplate.subsections[0].originalQuestions[j].repeatedQuestion[
      //   Props.LABEL
      // ] = resourcesReturn.combineResourcesoptions[j];
      resourcesMeTemplate.subsections[0].originalQuestions[j].repeatedQuestion.subLabel = resourcesReturn.combineResourcesOptionsLabels[j];
      resourcesMeTemplate.subsections[0].originalQuestions[j].repeatedQuestion.subHeader = resourcesReturn.combineResourcesoptions[j];
    }
  }

  _calculateAge(dateOfBirth) {
    var ageDifMs = Date.now() - dateOfBirth.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  //Function to save all the applications.
  //isBack is true only if go back button is clicked.
  //isBack is false in case of final save.
  _saveHandler = (activeId, actionButtonType) => {
    //Final save logic
    if (this.state.sectionDataUpdated) {
      this.setState({ sectionDataUpdated: TextProps.VALUE_FALSE });
      this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
      this.props.updateCurrentSectionData(this.props.apply);
    }
    if (actionButtonType === TextProps.CONTINUE) {
      this._renderNextSection(activeId);
    }else if (
      actionButtonType === TextProps.SAVECONTINE ||
      actionButtonType === undefined
    ) {
      this._renderNextSection(activeId);
    } else if (actionButtonType === TextProps.BACK) {
      this.goBackHandler();
    }
  };
  _findTheObj = (array, find) => {
    return array.filter((value) => value.name === find)[0];
  };

  triggerSaveSnap = (activeSectionId) => {
    this.props.onPrimaryActionSaveClicked(TextProps.VALUE_FALSE);
    let userDetails = sessionStorage.getItem("userInfo");
    let userDetailsObj = JSON.parse(userDetails);
    let sectionsToBeSaved = _.cloneDeep(this.state.sections);
    if (applicationType !== TextProps.MATCH_TANF) {
      filterData(sectionsToBeSaved, "auth-rep").authorizedRepresentatives = this.state.addAuthRepData;  
    }
    let previousSectionId = getPreviousSection(
      activeSectionId,
      this.state.sections
    );
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    let saveObject = {
      date: this.props.apply.prevSections.date,
      id: this.props.apply.prevSections.id,
      status: activeSectionId === "sign-and-submit" ? "RECVED" : "DRAFT",
      userId: userDetailsObj.email,
      programCode: applicationType,
      application: sectionsToBeSaved,
      activeId: activeSectionId,
    };
    this.props
      .saveSnapApply(saveObject, language)
      .then((data) => {
        if (this.state.saveExitRedirectToHomePage) {
          this.redirectToHomePage();
          this.setState({
            saveExitRedirectToHomePage: TextProps.VALUE_FALSE,
          });
        }
        setTimeout(() => {
          this.setState({
            isLoading: TextProps.VALUE_FALSE,
            onPrimaryActionSaveClicked: TextProps.VALUE_FALSE,
          });
        }, 2000);
        if (sessionStorage.getItem("saveExitShowModalEnabled") === "true") {
          setTimeout(
            () =>
              this.setState({
                saveExitShowModal: TextProps.VALUE_TRUE,
                createdDateTime: data.data.date,
                updateDateTime: data.data.updatedDt,
                serverTimeZone: data.data.timeZone,
                pendingHours: data.data.pendingHours,
                appId:data.data.id,
              }),
            3000
          );
        }
        if (sessionStorage.getItem("acesButtonClickedFlag") === "true") {
          sessionStorage.setItem("acesButtonClickedFlag", false);
          this.redirectToHomePage();
        }
      })
      .catch((error) => {
        // this.setState({
        //   isLoading: TextProps.VALUE_FALSE,
        //   onPrimaryActionSaveClicked: TextProps.VALUE_FALSE,
        // });
      });
  };
  //Primary Save and Continue Click / go Back click / submit click.
  // type => save/submit/reset/back
  onPrimaryActionClick = (event, actionButtonType) => {
    if (event) {
      event.preventDefault(); // allow react to take care of routing instead of browser
    }
    let activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    let dalReference = this.props.apply.dalReference;
    //Logic to bind answers to sections JSON
    this.handleAuthRepDataChanged(TextProps.VALUE_FALSE);
    if(actionButtonType === "Redirecting") {
      this.props.history.push(`${this.props.apply.acesRedictPath}`);
    }
    if (
      activeId === "about-me" &&
      typeof this.state.AnswerObject !== "undefined"
    ) {
      if (
        typeof this.state.AnswerObject[activeId] !== "undefined" &&
        typeof this.state.AnswerObject[activeId].ssn !== "undefined"
      ) {
        if (this.state.AnswerObject[activeId].ssn === "") {
          filterData(this.props.apply.sections, "about-me").questions[5].isChecked =
            TextProps.VALUE_TRUE;
        } else if (this.state.AnswerObject[activeId].ssn !== "") {
          filterData(this.props.apply.sections, "about-me").questions[5].isChecked =
            TextProps.VALUE_FALSE;
        }
      }
    }
      if (
        activeId.includes("household-member")  &&
        typeof this.state.AnswerObject !== "undefined"
      ) {
        if (
          typeof this.state.AnswerObject[activeId] !== "undefined" &&
          typeof this.state.AnswerObject[activeId].ssn !== "undefined"
        ) {
          const householdSsnQuestion = filterData(this.props.apply.sections, "my-household").subsections.find(item => item.id === activeId)
          if (this.state.AnswerObject[activeId].ssn === "") {
            householdSsnQuestion.questions[8].isChecked =
              TextProps.VALUE_TRUE;
          } else if (this.state.AnswerObject[activeId].ssn !== "") {
            householdSsnQuestion.questions[8].isChecked =
              TextProps.VALUE_FALSE;
          }
        }
      }
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      let _this = this;
      _this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      if (
        filterData(this.props.apply.sections, "income").questions[0].memberOptions !== undefined
      ) {
        dataSections.memberOptions =
          filterData(this.props.apply.sections, "income").questions[0].memberOptions;
      }
      if (activeId === "contact-info") {
        const subsection = filterData(this.props.apply.sections, "about-me").subsections[0];
        if(dataSections.questions[2].Answer ===
          aboutMeMultilanguageLabels(language).houseLessOptions[1]){
            
            subsection.questions[4].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_FALSE;
            subsection.questions[4].conditionals[0].conditionalQuestions[0].errorMessage =''
            subsection.questions[4].value = TextProps.VALUE_STR_TRUE;
            subsection.questions[4].Answer = TextProps.VALUE_STR_TRUE;
            subsection.questions[4].targetValue = TextProps.VALUE_STR_TRUE;
            let answer = this.state.AnswerObject
            this.setState({
              activeSectionId: activeId,
              activeId: activeId,
              renderedSections: [dataSections],
              AnswerObject: answer,
            });
            this.props.addApplyContactInfoSubSection(subsection);
          }
        if (
          dataSections.questions[2].Answer ===
            aboutMeMultilanguageLabels(language).houseLessOptions[0] &&
          (dataSections.questions[4].Answer === "" ||
            dataSections.questions[4].Answer === TextProps.VALUE_STR_TRUE) &&
          (dataSections.questions[3].Answer === "" ||
            (dataSections.questions[3].Answer.addressLine1 === "" &&
              dataSections.questions[3].Answer.zipCode === "" &&
              dataSections.questions[3].Answer.city === ""))
        ) {
          if (subsection !== undefined) {
            subsection.questions[4].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            subsection.questions[4].conditionals[0].conditionalQuestions[0].errorMessage =
              aboutMeMultilanguageLabels(language).addressErrmsg;
            subsection.questions[4].value = TextProps.VALUE_STR_FALSE;
            subsection.questions[4].Answer = TextProps.VALUE_STR_FALSE;
            subsection.questions[4].targetValue = TextProps.VALUE_STR_FALSE;

            filterData(this.props.apply.sections, "about-me").subsections[0].questions[2].isChecked =
              TextProps.VALUE_FALSE;
            let answer = this.state.AnswerObject;
            answer["contact-info"]["is-also-mailing"] =
              TextProps.VALUE_STR_FALSE;

            let mailingAddressSame =
              document.getElementsByName("is-also-mailing");
            // mailingAddressSame[1].click();
            mailingAddressSame[0].checked = TextProps.VALUE_FALSE;
            mailingAddressSame[1].checked = TextProps.VALUE_TRUE;
            this.setState({
              activeSectionId: activeId,
              activeId: activeId,
              renderedSections: [dataSections],
              AnswerObject: answer,
            });
            this.props.addApplyContactInfoSubSection(subsection);
          }
        } else if (
          dataSections.questions[2].Answer ===
            aboutMeMultilanguageLabels(language).houseLessOptions[0] &&
          (dataSections.questions[4].Answer === "" ||
            dataSections.questions[4].Answer === TextProps.VALUE_STR_TRUE) &&
          (dataSections.questions[3].Answer.addressLine1 !== "" ||
            dataSections.questions[3].Answer.zipCode !== "" ||
            dataSections.questions[3].Answer.city !== "")
        ) {
          this.props.addApplyContactInfoSubSection(subsection);
          let answer = this.state.AnswerObject;
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
            AnswerObject: answer,
          });
        } else if (
          dataSections.questions[2].Answer ===
            aboutMeMultilanguageLabels(language).houseLessOptions[1] &&
          (dataSections.questions[3].Answer === "" ||
            dataSections.questions[3].Answer.addressLine1 === "" ||
            dataSections.questions[3].Answer.zipCode === "" ||
            dataSections.questions[3].Answer.city === "")
        ) {
          this.props.addApplyContactInfoSubSection(subsection);
          let answer = this.state.AnswerObject;
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
            AnswerObject: answer,
          });
        } else if (
          dataSections.questions[3].Answer === "" ||
          dataSections.questions[3].Answer.addressLine1 === "" ||
          dataSections.questions[3].Answer.zipCode === "" ||
          dataSections.questions[3].Answer.city === ""
        ) {
          this.props.addApplyContactInfoSubSection(subsection);
          let answer = this.state.AnswerObject;
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
            AnswerObject: answer,
          });
        }
      }
      let isValid = toValidateInputErros(dataSections, activeId);
      // store address in the store for validating if the address as changed
      if (activeId === "contact-info" && isValid) {
        if (config.doAddressValidate) {
          const address = dataSections;
          let resAddrUpdated = TextProps.VALUE_FALSE;
          let isHomeless = address.questions.filter(
            (addr) => addr.name === "is-homeless"
          )[0];
          if (isHomeless && isHomeless.Answer === TextProps.VALUE_YES) {
            resAddrUpdated = TextProps.VALUE_FALSE;
          } else if (this.props.apply.residentailAddressStored) {
            resAddrUpdated = checkIfAddressIsUpdated(
              address.questions.filter((addr) => addr.name === "address")[0]
                .Answer,
              this.props.apply.residentailAddressStored
            );
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (this.props.apply.mailingAddressStored) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                mailingaddr.Answer,
                this.props.apply.mailingAddressStored
              );
            }
          }
          if (
            !filterData(this.props.apply.sections, "about-me").subsections[0]
              .melisaAddressValidated ||
            resAddrUpdated ||
            mailingAddrUpdated
          ) {
            let melissaResponse = this.validateAddressesCallingMellisa(
              addressValidationObj(address.questions),
              address
            );
            if (melissaResponse) {
              return;
            }
          } else {
            filterData(this.props.apply.sections, "before-you-start").signAndSubmit = TextProps.VALUE_TRUE;
          }
        } else {
          filterData(this.props.apply.sections, "before-you-start").signAndSubmit = TextProps.VALUE_TRUE;
        }
      } else if (activeId === "my-household") {
        var memberCount = filterData(this.props.apply.sections, "my-household").questions[0].Answer;
        if (memberCount < 2) {
          if (
            applicationType === TextProps.MATCH_SNAP ||
            applicationType === TextProps.MATCH_COMBO
          ) {
            filterData(this.props.apply.sections, "expenses").subsections[1].active =
              TextProps.VALUE_FALSE;
            filterData(this.props.apply.sections, "expenses").subsections[1].hiddenFromNav =
              TextProps.VALUE_TRUE;
          }
        } else if (memberCount > 1) {
          if (
            applicationType === TextProps.MATCH_SNAP ||
            applicationType === TextProps.MATCH_COMBO
          ) {
            filterData(this.props.apply.sections, "expenses").subsections[1].active =
              TextProps.VALUE_TRUE;
            filterData(this.props.apply.sections, "expenses").subsections[1].hiddenFromNav =
              TextProps.VALUE_FALSE;
          }
        }
      } else if (activeId === "household-details") {
        //Populate household members from user answers
        let houseHolds = filterData(this.props.apply.sections, "my-household").subsections;
        let houseHoldMembersCopy = [];
        let head = {
          personId: 0,
          disabilityFlag: TextProps.VALUE_FALSE,
          dateOfBirth: null,
          firstName: null,
          lastName: null,
        };
        let aboutMe = filterData(this.props.apply.sections, "about-me").questions;
        if (
          aboutMe[4].Answer !== "" &&
          aboutMe[4].Answer !== undefined &&
          aboutMe[4].Answer !== null
        ) {
          head.dateOfBirth = new Date(aboutMe[4].Answer);
          console.log("dhkn"+JSON.stringify(head.dateOfBirth))
          head.firstName = aboutMe[0].Answer;
          head.lastName = aboutMe[2].Answer;
        }
        head.personId = filterData(this.props.apply.sections, "before-you-start").webClientId;
        head.disabilityFlag = TextProps.VALUE_FALSE;
        houseHoldMembersCopy.push(head);
        if (houseHolds !== undefined) {
          for (let member = 0; member < houseHolds.length - 1; member++) {
            let dependentInfo = houseHolds[member].questions;
            let hhMember = {
              personId: 0,
              disabilityFlag: TextProps.VALUE_FALSE,
              dateOfBirth: null,
              firstName: null,
              lastName: null,
            };
            hhMember.personId = houseHolds[member].clientId;
            hhMember.disabilityFlag = TextProps.VALUE_FALSE;
            if (
              dependentInfo[5].Answer !== "" &&
              dependentInfo[5].Answer !== undefined &&
              dependentInfo[5].Answer !== null
            ) {

              hhMember.dateOfBirth = new Date(dependentInfo[5].Answer);
              console.log("hshd"+hhMember.dateOfBirth)
            }
            hhMember.firstName = dependentInfo[0].Answer;
            hhMember.lastName = dependentInfo[2].Answer;
            houseHoldMembersCopy.push(hhMember);
          }
        }
        let houseHoldsDisabilities = filterData(this.props.apply.sections, "my-household").subsections;
        if (houseHoldsDisabilities !== undefined) {
          let houseHoldDetails =
            houseHoldsDisabilities[houseHoldsDisabilities.length - 1].questions;
          // seeting disability to grantee
          if (houseHoldDetails !== undefined) {
            if (houseHoldDetails[0].options[0].optionValue === "GRANTEE") {
              if (
                houseHoldDetails[0].Answer !== undefined &&
                houseHoldDetails[0].Answer !== ""
              ) {
                if (
                  houseHoldDetails[0].Answer[0] ===
                  houseHoldMembersCopy[0].personId
                ) {
                  houseHoldMembersCopy[0].disabilityFlag = TextProps.VALUE_TRUE;
                }
              }
            }
            if (
              houseHoldMembersCopy.length > 0 &&
              houseHoldDetails[0].Answer !== undefined
            ) {
              for (
                let applicantDep = 0;
                applicantDep < houseHoldDetails[0].Answer.length;
                applicantDep++
              ) {
                for (
                  let houseHold = 0;
                  houseHold < houseHoldMembersCopy.length;
                  houseHold++
                ) {
                  if (houseHoldMembersCopy[houseHold].personId == "GRANTEE")
                    continue;
                  if (
                    houseHoldDetails[0].Answer[applicantDep] ===
                    houseHoldMembersCopy[houseHold].personId
                  ) {
                    houseHoldMembersCopy[houseHold].disabilityFlag =
                      TextProps.VALUE_TRUE;
                  }
                }
              }
            }
          }
        }
        //Remove medical cost section if no member is above 60 years old
        let count = 0;
        let enabledMembersList = [];

        for (
          var memberListIndex = 0;
          memberListIndex < houseHoldMembersCopy.length;
          memberListIndex++
        ) {
          let enabledMember = {
            isGrantee: "Y",
            optionDisplay: "dfas sdfa (12.Apr.1990)",
            optionValue: "GRANTEE",
          };
          let incomeHouseHoldName =
            houseHoldMembersCopy[memberListIndex].firstName;
          let incomeCombinedName = "";
          if (incomeHouseHoldName !== "") {
            incomeCombinedName =
              incomeHouseHoldName +
              " " +
              houseHoldMembersCopy[memberListIndex].lastName;
            let incomeHouseHoldDOB = this._convertDateToState(
              houseHoldMembersCopy[memberListIndex].dateOfBirth
            );
            if (houseHoldMembersCopy[memberListIndex].dateOfBirth !== "") {
              incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
            }
          }
          if (houseHoldMembersCopy[memberListIndex].dateOfBirth) {
            let date = new Date(
              houseHoldMembersCopy[memberListIndex].dateOfBirth
            );
            let age = this._calculateAge(date);
            if (
              age >= 60 ||
              houseHoldMembersCopy[memberListIndex].disabilityFlag ===
                TextProps.VALUE_TRUE
            ) {
              count++;
              if (houseHoldMembersCopy[memberListIndex].personId == "GRANTEE") {
                enabledMember.isGrantee = "Y";
              } else {
                enabledMember.isGrantee = "N";
              }
              enabledMember.optionDisplay = incomeCombinedName;
              enabledMember.optionValue =
                houseHoldMembersCopy[memberListIndex].personId;

              enabledMembersList.push(enabledMember);
            }
          } else if (
            houseHoldMembersCopy[memberListIndex].disabilityFlag ===
            TextProps.VALUE_TRUE
          ) {
            count++;
            if (houseHoldMembersCopy[memberListIndex].personId == "GRANTEE") {
              enabledMember.isGrantee = "Y";
            } else {
              enabledMember.isGrantee = "N";
            }
            enabledMember.optionDisplay = incomeCombinedName;
            enabledMember.optionValue =
              houseHoldMembersCopy[memberListIndex].personId;

            enabledMembersList.push(enabledMember);
          }
        }
        if (enabledMembersList.length > 0) {
          this.setState({ enabledMembersforMedical: enabledMembersList });
        }
        if (count === 0) {
          if (
            applicationType === TextProps.MATCH_SNAP ||
            applicationType === TextProps.MATCH_COMBO
          ) {
            filterData(this.props.apply.sections, "expenses").subsections[3].active =
              TextProps.VALUE_FALSE;
            filterData(this.props.apply.sections, "expenses").subsections[3].hiddenFromNav =
              TextProps.VALUE_TRUE;
          }
        } else {
          if (
            applicationType === TextProps.MATCH_SNAP ||
            applicationType === TextProps.MATCH_COMBO
          ) {
            filterData(this.props.apply.sections, "expenses").subsections[3].active =
              TextProps.VALUE_TRUE;
            filterData(this.props.apply.sections, "expenses").subsections[3].hiddenFromNav =
              TextProps.VALUE_FALSE;
            filterData(this.props.apply.sections, "expenses").subsections[
              filterData(this.props.apply.sections, "expenses").subsections.length - 1
            ].questions[0].options = enabledMembersList;
          }
        }
      }else if(activeId.includes("medical-expenses-type")){
        const selectedAnswer = dataSections.questions[0].Answer;
        const initialMedExTypes = expenseMedicalExpenseTemplate.subsections[0].subsections[0].questions.filter(el=>!el.name.includes("driving-"));
        const previousAnswers = filterData(this.props.apply.sections, "expenses").subsections[3].subsections[Number(activeId.slice(-1))].subsections[0].questions;
        const drivingQuestion = filterData(this.props.apply.sections, "expenses").subsections[3].subsections[Number(activeId.slice(-1))].subsections[0].questions.slice(-1);
        const removed =  previousAnswers.filter(el=>!el.name.includes("driving-"))
        let result = unionBy(removed, initialMedExTypes, 'name');
        const mergedQuestions = [...result]
        const filteredQuestions = filterQuestionsBySelection(selectedAnswer,mergedQuestions);
        const finalQuestions = [...filteredQuestions]

        // change dynamic labels for medical type questions 
        let currentMember = filterData(this.props.apply.sections, "income").questions[0].memberOptions[Number(activeId.slice(-1))]
        for(let j=0; j<finalQuestions.length;j++){
          finalQuestions[j].label = medicalExpenseMultilanguageLabels(currentMember,language, finalQuestions[j].name).medicalExpenseAmountLabel;
        }
        this.props.appendMedicalExpenseSections(finalQuestions,activeId.slice(-1));
      }

      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        this.props.applySanpQuestionsSectionsNew(this.props.apply.sections);
        return;
      } else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          //Clearing HouseHold Cache Template
          case "optional":
            this.onHouseHoldSubSectionClean();
            break;
          //clearning Income Template
          case "household-details":
            // setTimeout(() => {
            this.onIncomeSubSectionClean();
            if (
              applicationType === TextProps.MATCH_SNAP ||
              applicationType === TextProps.MATCH_COMBO
            ) {
            this.onResourcesSubSectionClean();
            }
            // },5);
            // return () => clearTimeout(onIncomeSubSectionCleanTimer);
            break;
          //clearning Medical Expense Tempalte
          case "child-support":
            this.onMedicalExpenseSectionClean();
            break;
          //Rendering Dynamic Household Members
          case "my-household":
            if (applicationType === TextProps.MATCH_TANF) {
              setTimeout(() => {
                this.onIncomeSubSectionClean();
                if (
                  applicationType === TextProps.MATCH_SNAP ||
                  applicationType === TextProps.MATCH_COMBO
                ) {
                this.onResourcesSubSectionClean();
                }
              }, 5);
            }
            this.onHouseHoldSubsectionRender(dataSections.questions[0].Answer);
            break;
          //Rendering Dynamic Income Template for Members
          case "income":
            this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE });
            if (this.props.match.params.webAppId !== undefined) {
              this.onIncomeSubSectionClean();
            }
            this.onIncomeSubsectionRender(dataSections);
            break;
          //Rendering Dynamic Income Template for Members
          case "resources":
            this.setState({ resourcesSummaryEdit: TextProps.VALUE_FALSE });
            if (this.props.match.params.webAppId !== undefined) {
              this.onResourcesSubSectionClean();
            }
            this.onResourcesSubsectionRender(dataSections);
            break;           
            
          //Clearing Caring Kids
          case "housing-costs":
            this.renderCaringKidsAdults();
            this.renderHousingCostsBySelection();
            this.renderChildSupport();
            break;
          //Rendering Dynamic Medical Expense Template for Members
          case "medical-expenses":
            this.renderMedicalExpenseSubTemplates(
              dataSections.questions[0].Answer
            );
            break;
          case "kids-and-adults":
            this.renderDependentsCareCosts();
            break;
          default:
            //Nothing
            break;
        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        switch (activeIdSubString) {
          // rendering Income Dynamic Questions.
          case "household-member":
            this.householdMemUpdated(activeId);
            break;
          case "combined-income":
            this.incomefilterQuestionsBySelection(activeId);
            break;
          case "combined-resources":
            this.resourcesfilterQuestionsBySelection(activeId,dataSections);
            break;
          case "combined-income-subsection":
            dataSections.currentMember =
              filterData(this.props.apply.sections, "income").currentMember;
            if (this.state.incomeSummaryEdit) {
              this.gotoIncomeSummary(activeId);
              return;
            }
            break;
          case "combined-resources-subsection":
            dataSections.currentMember =
              filterData(this.props.apply.sections, "resources").currentMember;
            if (this.state.resourcesSummaryEdit) {
              this.gotoResourcesSummary(activeId);
              return;
            }
            break;
          case "medical-driving":
            this.renderMedicalCostSections(activeId)
            break;
          case "kids-and-adults":
            this.addMultipleKidAddress(activeId);
            if (
              filterData(this.props.apply.sections, "expenses").subsections[1].dependentCare !==
              undefined
            ) {
              let careIndex = dataSections.id.split("-")[3];
              if (
                filterData(this.props.apply.sections, "expenses").subsections[1].dependentCare[
                  careIndex
                ] !== undefined &&
                filterData(this.props.apply.sections, "expenses").subsections[1].dependentCare[
                  careIndex
                ] !== null
              ) {
                dataSections.dependentCare =
                  filterData(this.props.apply.sections, "expenses").subsections[1].dependentCare[
                    careIndex
                  ].split("-")[1];
                for (var member of dataSections.memberOptions) {
                  if (member.name === dataSections.title) {
                    dataSections.clientId = member.id;
                  }
                  if (member.name === dataSections.dependentCare) {
                    dataSections.dependentCareId = member.id;
                  }
                }
              }
            }
            break;
          //CHECK IF CHILD EXPENSE. ITERATE TO GET THE COST AND UNIT.
          case "child":
            var childCareArray = [];
            var childCareObj = { name: "", id: "", amount: 0, freq: "" };
            for (var memberObj of filterData(this.props.apply.sections, "income").questions[0]
              .options) {
              let clonedchildCare = Object.assign({}, childCareObj);
              for (var answer of dataSections.questions[0].Answer) {
                if (answer === memberObj.name) {
                  clonedchildCare.id = memberObj.id;
                  clonedchildCare.name = memberObj.name;
                  for (var conditional of dataSections.questions[0]
                    .conditionals) {
                    if (conditional.valueToMatch === answer) {
                      clonedchildCare.amount = parseFloat(
                        conditional.conditionalQuestions[0].Answer.amount
                      );
                      clonedchildCare.freq =
                        conditional.conditionalQuestions[0].Answer.unit;
                    }
                  }
                  break;
                }
              }
              childCareArray.push(clonedchildCare);
            }
            // SEND THE CREATED OBJECT WITH THE REQUEST
            dataSections.childSupportInfo = childCareArray;
            break;
          default:
            //CHECK IF KIDS AND ADULT ADDRESS. MAP THE MEMBER ID AND SEQUENCE
            if (
              activeIdSubString.indexOf("kids-adult-multiple-address-") > -1
            ) {
              // for (let adultMember of dataSections.memberOptions) {
              //   if (adultMember.name === dataSections.title) {
              //     dataSections.clientId = adultMember.id;
              //   }
              for (let adultMember of filterData(this.props.apply.sections, "income").questions[0]
                .options) {
                if (adultMember.optionDisplay === dataSections.title) {
                  dataSections.clientId = adultMember.optionValue;
                }
                if (
                  filterData(this.props.apply.sections, "expenses").currentExpSeq !== undefined &&
                  filterData(this.props.apply.sections, "expenses").currentExpSeq !== null
                ) {
                  dataSections.currentExpSeq =
                    filterData(this.props.apply.sections, "expenses").currentExpSeq;
                }
              }
            } else if (activeIdSubString.indexOf("medical-address-") > -1) {
              let currentChild = dataSections.title.split("'s ")[0];

              for (var memdicalMember of dataSections.memberOptions) {
                if (memdicalMember.name === currentChild) {
                  dataSections.clientId = memdicalMember.id;
                  break;
                }
              }
            }
            break;
        }
        if (
          activeId !== "income" &&
          activeIdSubString !== "combined-income" &&
          activeId !== "kids-and-adults" &&
          activeId !== "medical-expenses" &&
          !(activeId.indexOf("medical-expenses-") > -1)
        ) {
          if (activeId.indexOf("medical-address-") > -1) {
            if (
              filterData(this.props.apply.sections, "expenses").currentExpSeq !== undefined &&
              filterData(this.props.apply.sections, "expenses").currentExpSeq !== null
            ) {
              dataSections.currentExpSeq =
                filterData(this.props.apply.sections, "expenses").currentExpSeq;
            }
          }
        } else {
          if (actionButtonType === TextProps.SIGNSUBMIT && isValid) {
            this.redirectToSignAndSubmit();
            return;
          } else {
            // this._renderNextSection(activeId);
            let AnswerObjectCopy = this.state.AnswerObject;
            AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
            this.setState({ AnswerObject: AnswerObjectCopy });
          }
        }
      }
      if (actionButtonType === TextProps.SIGNSUBMIT && isValid) {
        this.redirectToSignAndSubmit();
        return;
      }
    } else if (
      (actionButtonType === TextProps.SAVECONTINE || actionButtonType === TextProps.CONTINUE)  &&
      activeId === "sign-and-submit"
    ) {
      //start google analytics code for getting number of people applying application group by language
      let currentLanguageCode = localStorage.getItem("selectedLanguage");
      let currentLanguage;
      if (currentLanguageCode) {
        switch (currentLanguageCode) {
          case "en-US":
            currentLanguage = "English";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.ENGLISH,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case "es-ES":
            currentLanguage = "Spanish";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.SPANISH,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case "pt-BR":
            currentLanguage = "Portuguese";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.PORTUGUESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();

            break;
          case "zh-Hans":
            currentLanguage = "Chinese";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.CHINESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case "vi-VI":
            currentLanguage = "Vietnamese";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.VIETNAMESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case "ht":
            currentLanguage = "Haitian Creole";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.HAITIANCREOLE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          default:
            currentLanguage = "English";
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.ENGLISH,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
        }
      }
      //end google analytics code for getting number of people applying application group by language
      //Loading Dynamic SubSections , HouseHold Details
      let userName = "";
      if (filterData(this.props.apply.sections, "about-me").questions[0].Answer !== "") {
        userName =
          userName + filterData(this.props.apply.sections, "about-me").questions[0].Answer + " ";
      }
      if (filterData(this.props.apply.sections, "about-me").questions[1].Answer !== "") {
        userName =
          userName + filterData(this.props.apply.sections, "about-me").questions[1].Answer + " ";
      }
      if (filterData(this.props.apply.sections, "about-me").questions[2].Answer !== "") {
        userName = userName + filterData(this.props.apply.sections, "about-me").questions[2].Answer;
      }
      userName = userName.trim().replace(/ +(?= )/g,'');
      if (
        this.state.rights < 1 ||
        this.state.signature.length < 1 ||
        this.state.signature.toLowerCase() !== userName.toLowerCase()
      ) {
        if (this.state.rights < 1) {
          this.setState({ agreeError: TextProps.VALUE_TRUE });
          this.agreeErrorMsg =
            beforeYoustartMlanguageLabels(language).agreeTermsErrorMsg;
        } else {
          this.setState({ agreeError: TextProps.VALUE_FALSE });
          this.agreeErrorMsg = "";
        }

        if (
          this.state.signature.length < 1 ||
          this.state.signature.toLowerCase() !== userName.toLowerCase()
        ) {
          this.setState({ signatureError: TextProps.VALUE_TRUE });
          this.signatureErrorMsg =
            beforeYoustartMlanguageLabels(language).signatureErrorMsg;
        } else {
          this.setState({ signatureError: TextProps.VALUE_FALSE });
          this.signatureErrorMsg = "";
        }
      
          if (this.state.CFDHR.length < 1) {
            this.setState({ consentForDHRVerifyError: TextProps.VALUE_TRUE });
            this.state.consentForDHRVerifyErrorMsg =
              beforeYoustartMlanguageLabels(language).consentForDHRVerifyErrorMsg;
          } else {
            this.setState({ consentForDHRVerifyError: TextProps.VALUE_FALSE });
            this.state.consentForDHRVerifyErrorMsg= "";
          }
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
          });
        }
        return;
      } else {
        //Sending obj to service side for saving
        // application having object kind of data
        let application;
        let userDetails = sessionStorage.getItem("userInfo");
        if (
          userDetails === null ||
          userDetails === "" ||
          userDetails === undefined
        ) {
          application = convertToDTOObj(
            this.state.sections,
            this.state.signature,
            null
          );
        } else {
          let userDetailsObj = JSON.parse(userDetails);
          let inProgressObj = {};
          inProgressObj.status = "RECVED";
          inProgressObj.userId = userDetailsObj.email;
          application = convertToDTOObj(
            this.state.sections,
            this.state.signature,
            inProgressObj
          );
        }
        this.saveToDatabase(application, activeId, dalReference);
      }
      return;
    } else if (
      actionButtonType === TextProps.SAVEEXIT &&
      activeId === "sign-and-submit"
    ) {
      this.setState({
        rights: 0,
      });
      this.triggerSaveSnap("ebt-card-request");
    }
    //Logic for all other action items(save and continue,go Back,save and Exit) other than submit button
    if (actionButtonType === TextProps.SIGNSUBMIT) {
      this.redirectToSignAndSubmit();
      return;
    } else if (activeId !== "before-you-start") {
      if(activeId === "auth-rep") {
        this.CheckForQuestionsDataModified();
        if (this.verifyFieldValidationOnSave() === TextProps.VALUE_FALSE) {
          //statuschangecode 
            this.props.setAuthRepResponseBodyData(this.state.addAuthRepData);
            filterData(this.props.apply.sections, "auth-rep").authorizedRepresentatives = this.state.addAuthRepData
          this.setState({sectionDataUpdated: TextProps.VALUE_TRUE});
          // this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
          setTimeout(() => this._saveHandler(activeId, actionButtonType));
        }
      }
      else if (activeId !== "auth-rep") {
          setTimeout(() => this._saveHandler(activeId, actionButtonType));
      }
      sessionStorage.removeItem("prePopulateApp");
    } else {
      this.props.updateCurrentSectionData(this.props.apply);
      if(activeId !== "auth-rep") {
        this._renderNextSection(activeId);
      }
    }
    let prePopulateApp = sessionStorage.getItem("prePopulateApp");
    if (prePopulateApp && activeId === "before-you-start" && !this.appInanonymousMode) {
      this.setState({ isLoading: TextProps.VALUE_TRUE });
      let applicationType = sessionStorage.getItem("applicationType");
      this.props.prePopulationDataInfo(applicationType,language);
      // setTimeout(() => {
      //  let storeAddrObj = this.updateStoreAddr();
      //  this.props.applyAddressStorageAPI(storeAddrObj);
      //  filterData(this.props.apply.sections, "about-me").subsections[0].melisaAddressValidated =
      //  TextProps.VALUE_TRUE;
      // },500);
     }
  };


  //Function to save data after validation is done.
  saveToDatabase = (dataSections, activeId, dalReference) => {
    dataSections.SNAP_APPLY.application.id = this.props.apply.prevSections.id;
    dataSections.SNAP_APPLY.application.createdTime =
      this.props.apply.prevSections.date;
      
  
    if (dataSections.SNAP_APPLY.application.applicant.birthDate) {
      var dateOfBirth = dataSections.SNAP_APPLY.application.applicant.birthDate;
      var now = new Date();
      var age = now.getFullYear() - dateOfBirth.getFullYear();
      var month = now.getMonth() - dateOfBirth.getMonth();
      if (month < 0 || (month === 0 && now.getDate() < dateOfBirth.getDate())) {
        age--;
      }
      if (age < 60) {
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_AGE,
          dt: googleAnalyticsConstVar.DT_AGE,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.EVENT_CATEGORY_AGE_Less,
            googleAnalyticsConstVar.EVENT_ACTION_AGE,
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
      } else if (age > 60) {
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_AGE,
          dt: googleAnalyticsConstVar.DT_AGE,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.EVENT_CATEGORY_AGE_More,
            googleAnalyticsConstVar.EVENT_ACTION_AGE,
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
      }
    }
    if (dataSections.SNAP_APPLY.application.applicant.hasDisability) {
      if (
        dataSections.SNAP_APPLY.application.applicant.hasDisability ===
        TextProps.VALUE_Y
      ) {
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_DISABILITY,
          dt: googleAnalyticsConstVar.DT_DISABILITY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.EVENT_CATEGORY_DISABILITY_Y,
            googleAnalyticsConstVar.EVENT_ACTION_DISABILITY,
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
      } else if (
        dataSections.SNAP_APPLY.application.applicant.hasDisability ===
        TextProps.VALUE_N
      ) {
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_DISABILITY,
          dt: googleAnalyticsConstVar.DT_DISABILITY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.EVENT_CATEGORY_DISABILITY_N,
            googleAnalyticsConstVar.EVENT_ACTION_DISABILITY,
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
      }
    }
    if (dataSections.SNAP_APPLY.application.applicant.addresses[0]) {
      if (dataSections.SNAP_APPLY.application.applicant.addresses[0].postal) {
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_DEMOGRAPHICS,
          dt: googleAnalyticsConstVar.DT_DEMOGRAPHICS,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            dataSections.SNAP_APPLY.application.applicant.addresses[0].postal,
            googleAnalyticsConstVar.EVENT_ACTION_DEMOGRAPHICS,
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
      }
    }
    filterData(this.props.apply.sections, "before-you-start").isSubmitInitiated = TextProps.VALUE_TRUE;
    this.setState({ isLoading: TextProps.VALUE_TRUE });

    // dateOfBirth in string format
    if (dateOfBirth) {
      const stringDOB = this.convertDOBObjectToString(dateOfBirth);
      dataSections.SNAP_APPLY.application.applicant.birthDate = stringDOB;
    }

    if (dataSections.SNAP_APPLY.application.applicant.ethnicity){
      if(dataSections.SNAP_APPLY.application.applicant.ethnicity === 'Hispanic/Latino'
      || dataSections.SNAP_APPLY.application.applicant.ethnicity === 'Hispano/Latino'
      ){
        dataSections.SNAP_APPLY.application.applicant.ethnicity = 'HSPLTN'
      }
      else if(dataSections.SNAP_APPLY.application.applicant.ethnicity === 'Non-Hispanic/Latino'
      || dataSections.SNAP_APPLY.application.applicant.ethnicity === 'No hispano/Latino'
      ){
        dataSections.SNAP_APPLY.application.applicant.ethnicity = 'NNHSPL'
      }
    }
    

        // Household dateOfBirth in string format and ethnicity should be send in codes
    dataSections.SNAP_APPLY.application.applicant.household.map((data) => {
      let stringHouseholdDOB = null;
      if (data.birthDate) {
        stringHouseholdDOB = this.convertDOBObjectToString(data.birthDate);
      }
      data.birthDate = stringHouseholdDOB;
      if(data.ethnicity && data.ethnicity === 'Hispanic/Latino'
      || data.ethnicity && data.ethnicity === 'Hispano/Latino'
      ){
        data.ethnicity = 'HSPLTN'
      }
      else if(data.ethnicity && data.ethnicity === 'Non-Hispanic/Latino'
      || data.ethnicity && data.ethnicity === 'No hispano/Latino'
      ){
        data.ethnicity = 'NNHSPL'
      }
    });
    if(applicationType !== TextProps.MATCH_TANF) {
      if(this.state.addAuthRepData.ebtWebDataChangeCode === null) {
        this.state.addAuthRepData["ebtWebDataChangeCode"] = "NOCHNG";
      }
      if(this.state.addAuthRepData.snpWebDataChangeCode === null) {
        this.state.addAuthRepData["snpWebDataChangeCode"] = "NOCHNG";
      }
      dataSections.SNAP_APPLY.application.ebtAuthRepFlg = this.state.addAuthRepData.ebtAuthRepFlg ? this.state.addAuthRepData.ebtAuthRepFlg : null;
      dataSections.SNAP_APPLY.application.snapAuthRepFlg = this.state.addAuthRepData.snapAuthRepFlg ? this.state.addAuthRepData.snapAuthRepFlg : null;
      delete this.state.addAuthRepData["ebtAuthRepFlg"];
      delete this.state.addAuthRepData["snapAuthRepFlg"];
      this.state.addAuthRepData.ebtPhNum = this.removeExtraSpaceFromPhNum(this.state.addAuthRepData["ebtPhNum"]);
      this.state.addAuthRepData.snpPhNum = this.removeExtraSpaceFromPhNum(this.state.addAuthRepData["snpPhNum"]);
      dataSections.SNAP_APPLY.application.authorizedRepresentatives = this.state.addAuthRepData;
    }
    dataSections.SNAP_APPLY.application.thirdPartyVerification = this.state.CFDHR
    this.props
      .submitSnapApply(dataSections, language, this.appInanonymousMode)
      .then((data) => {
        if (activeId === "sign-and-submit") {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
          this._renderNextSection(activeId);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
      });
  };
  //Function to update addresses.
  updateStoreAddr() {
    const subsectionData = filterData(this.props.apply.sections, "about-me").subsections[0];
    let storeAddrObj = [];
    let residentialAddr = this._findTheObj(subsectionData.questions, "address");
    let mailingAddr = this._findTheObj(
      subsectionData.questions,
      "is-also-mailing"
    );
    storeAddrObj.push(residentialAddr.Answer ? residentialAddr.Answer : {});
    storeAddrObj.push(
      mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        ? mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        : {}
    );
    return storeAddrObj;
  }

  convertDOBObjectToString(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  convertJSON = (dataSections) => {
    const oldJSON = dataSections["SNAP_APPLY"];
    let newJSON = {
      documentID: null,
      agencyID: null,
      programTypeCode: TextProps.MATCH_SNAP,
      programID: 4795042,
      languageCode: "EN",
      dataChangeCode: "CHANGE",
      webAppTypeCode: "SNPAPPLY",
    };
    const { application } = oldJSON;
    const { applicant } = application;
    const households = [applicant].concat(applicant.household);
    const ethnicity = [];
    const earnedIncome = [],
      otherIncome = [];
    newJSON["signedName"] = application["signedName"];
    newJSON["CFDHR"] = this.state.CFDHR
    newJSON["householdMembers"] = households.map((item, index) => {
      return this.convertHousehold(item, index);
    });
    households.forEach((item, index) => {
      if (item.ethnicity) {
        console.log("hjnnj"+item.ethnicity)
        ethnicity.push({
          ethnicityCode: item.ethnicity,
          race: item.race,
          personId: index == 0 ? "GRANTEE" : "DEP-" + index,
        });
      }
      const dob = this.convertBirthDate(item.birthDate);
      const birthDay = this.convertDOBObjectToString(dob);
      item.income.forEach((incomeItem) => {
        const obj = {
          firstName: item["firstName"],
          middleName: item["middleName"],
          lastName: item["lastName"],
          dateOfBirth: birthDay,
          personId: index == 0 ? "GRANTEE" : "DEP-" + index,
          frequencyCode: incomeItem["frequencyCode"],
          amounts: [{ amount: incomeItem["grossIncome"] }],
        };
        if (incomeItem["wageTypeCode"]) {
          obj["typeCode"] = incomeItem["wageTypeCode"];
          earnedIncome.push(obj);
        } else {
          obj["typeCode"] = incomeItem["otherIncomeTypeCode"];
          otherIncome.push(obj);
        }
      });
    });
    newJSON["ethnicity"] = ethnicity;
    newJSON["earnedIncome"] = earnedIncome;
    newJSON["otherIncome"] = otherIncome;
    newJSON["needs"] = application["needs"];
    newJSON["dependentCareExpense"] = applicant["dependentCareExpense"];
    newJSON["shelterExpenses"] = (application["shelterExpenses"] || []).map(
      ({ householdExpenseTypeCode, frequencyCode, amount }) => {
        return { typeCode: householdExpenseTypeCode, frequencyCode, amount };
      }
    );
    newJSON["utilityExpenses"] = {
      heatFlag: application["payHeat"] == TextProps.VALUE_Y,
      acFlag: application["payAC"] == TextProps.VALUE_Y,
      acFeeFlag: application["payACFee"] == TextProps.VALUE_Y,
      electricOrGasFlag: application["elgas"] == TextProps.VALUE_Y,
      phoneFlag: application["hasPhone"] == TextProps.VALUE_Y,
    };
    const utilityExpenses = newJSON["utilityExpenses"];
    utilityExpenses["noUtilityFlag"] = !(
      utilityExpenses["heatFlag"] ||
      utilityExpenses["acFlag"] ||
      utilityExpenses["acFeeFlag"] ||
      utilityExpenses["electricOrGasFlag"] ||
      utilityExpenses["phoneFlag"]
    );
    newJSON["medicalExpenses"] = applicant["medicalExpenses"].map(
      ({
        id,
        clientId,
        address,
        driveTripCount,
        driveFrequencyCode,
        transportationAmount,
        transportationFrequencyCode,
      }) => {
        return {
          agencyID: null,
          personId: clientId,
          medicalDentalFlag: null,
          medicationFlag: null,
          healthInsuranceFlag: null,
          otherFlag: null,
          transportDetails: [
            {
              address,
              driveTripCount,
              driveFrequencyCode,
              transportationAmount,
              transportationFrequencyCode,
            },
          ],
        };
      }
    );
    return { snapapply: newJSON };
  };
  convertHousehold = (applicant, index) => {
    return {
      agencyID: null,
      granteeFlag: applicant.isGrantee == TextProps.VALUE_Y,
      firstName: applicant["firstName"],
      middleName: applicant["middleName"],
      lastName: applicant["lastName"],
      suffix: applicant["nameSuffix"],
      dateOfBirth: this.convertBirthDate(applicant["birthDate"]),
      SSN: applicant["SSN"],
      relationshipCode: applicant["relationshipCode"] || "",
      genderCode: applicant["gender"],
      personId: index == 0 ? "GRANTEE" : "DEP-" + index,
      buyFoodFlag: null,
      citizenFlag: applicant["isUSCitizen"] == TextProps.VALUE_Y,
      homelessFlag: applicant["isHomeless"] == TextProps.VALUE_Y,
      disabilityFlag: applicant["hasDisability"] == TextProps.VALUE_Y,
      dataChangeCode: "NEW",
    };
  };
  convertBirthDate = (date) => {
    date = new Date(date);
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    return [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  };

  _renderHouseholdInfo = () => {
    let houseMemberList = this.mapMemberListOptions();
    let householdInformationList = _.cloneDeep(
      filterData(this.props.apply.sections, "household-information")
    );
    householdInformationList && householdInformationList.questions.map((info) => {
      info.conditionals[0].conditionalQuestions[0].options = [];
      houseMemberList 
        .map((mem, indx) => {
          let optionObj = {};
          optionObj.optionValue = indx + 1;
          optionObj.optionDisplay =
            mem.firstName +
            " " +
            mem.lastName +
            formatters.formatDate(mem.dateOfBirth);
          optionObj.memInfo = mem;
          info.conditionals[0].conditionalQuestions[0].options.push(optionObj.memInfo);
        });
    });
    this.props.apply.sections[
      this.props.apply.sections.indexOf(
        filterData(this.props.apply.sections, "household-information")
      )
    ] = householdInformationList;
  };

  //Function to navigate sections once the validation is completed.
  _renderNextSection = (activeId) => {
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.apply.sections;
    }
    let nextSectionId = getNextSection(activeId, section);
    //Function to Execute before Next page renders
    switch (nextSectionId) {
      // rendering Income Dynamic Questions.
      case "income-summary":
        this.prepareIncomeSummary();
        break;
      case "resources-summary":
        this.prepareResourcesSummary();
        break;
      //Function to Manipulate Details on HouseHoldPage page
      case "household-details":
        this.updateHouseHoldDetails();
        if (applicationType === TextProps.MATCH_TANF) {
          nextSectionId = "income";
          this.updateHouseHoldIncomeDetails();
        }
        break;
      //Function to Manipulate Details on Income page
      case "income":
        this.updateHouseHoldIncomeDetails();
        break;
      case "resources":
        this.updateHouseHoldResourcesDetails();
        this.setState({addAuthRepData : this.state.addAuthRepData});
        break;

        case "auth-rep":
          this.setState({addAuthRepData : this.state.addAuthRepData});
        break;
      //Function to Manipulate Details on Expense child support page
      case "child-support":
        this.mapDependentCostSection(filterData(this.props.apply.sections, "expenses"));
        this.updateHouseHoldExpenseDetails();
        break;
      case "kids-and-adults":
        // this.renderCaringKidsAdults();
        if (
          filterData(this.props.apply.sections, "expenses").subsections[1].active ===
          TextProps.VALUE_FALSE
        ) {
          nextSectionId = "child-support";
          this.updateHouseHoldExpenseDetails();
          break;
        }
        break;
      //Function to Manipulate Details on Expense medical page
      case "medical-expenses":
        if (
          filterData(this.props.apply.sections, "expenses").subsections[3].active ===
          TextProps.VALUE_FALSE
        ) {
          nextSectionId = "ebt-card-request";
        }
        this.updateHouseHoldMedicalDetails();
        break;
      //Clearing HouseHold Cache Template
      case "more-info":
        this.setLanguageInScreen();
        break;
      case "ebt-card-request":
        if (filterData(this.props.apply.sections, "ebt-card-request").active === TextProps.VALUE_FALSE) {
          nextSectionId = "sign-and-submit";
        }
        break;
      default:
        //Nothing
        break;
    }
    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState({ prevIncomingQuestions: _.cloneDeep(nextSection) });
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };
  //Function to goBack to previous section.
  goBackHandler = () => {
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.apply;
    let previousSectionId;
    // if(this.state.activeSectionId === "auth-rep") {
    //   this.setState({addAuthRepData : this.props.apply.sections[3].authorizedRepresentatives});
    // }
    if (activeId === "about-me" || activeId !== "") {
      PropTypes.date_error = 0;
    }
    let signAndSubmitSection = this.props.apply.sections.filter(
      (sec) => sec.id === "sign-and-submit"
    )[0];
    if (signAndSubmitSection && signAndSubmitSection.partialSave) {
      this.props.apply.sections.filter(
        (sec) => sec.id === "sign-and-submit"
      )[0].partialSave = TextProps.VALUE_FALSE;
      previousSectionId = signAndSubmitSection.lastKnownLocation;
    } else {
      previousSectionId = getPreviousSection(activeId, sections);
    }

    if (previousSectionId !== undefined) {
      if(activeId === "ebt-card-request" && applicationType === TextProps.MATCH_TANF) {
        previousSectionId = "income"
      }
      if(activeId === "ebt-card-request" && applicationType === TextProps.MATCH_SNAP) {
        previousSectionId = "expenses"
      }
      if(activeId === "expenses" && applicationType === TextProps.MATCH_COMBO){
        previousSectionId = "income"
      }
      if(activeId === "expenses" && applicationType === TextProps.MATCH_SNAP){
        previousSectionId = "income"
      }
      if (
        (previousSectionId.indexOf("kids-and-adults-") >= 0 ||
          previousSectionId.indexOf("kids-adult-multiple-address") >= 0 ||
          previousSectionId.indexOf("kids-and-adults") >= 0) &&
        filterData(this.props.apply.sections, "expenses").subsections[1].active ===
          TextProps.VALUE_FALSE
      ) {
        previousSectionId = "housing-costs";
      }
      if (
        (previousSectionId.indexOf("medical-expenses") >= 0 ||
          previousSectionId.indexOf("medical-expenses-") >= 0 ||
          previousSectionId.indexOf("medical-address-") >= 0) &&
        filterData(this.props.apply.sections, "expenses").subsections[3].active ===
          TextProps.VALUE_FALSE
      ) {
        previousSectionId = "child-support";
      }
      if (
        applicationType === TextProps.MATCH_TANF &&
        previousSectionId === "household-details"
      ) {
        previousSectionId = getPreviousSection(previousSectionId, sections);
      }
    }

    if (previousSectionId) {
      const previousSection = this._getSection(previousSectionId);
      this.setState({ prevIncomingQuestions: _.cloneDeep(previousSection) });
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    } else {
      this.redirectToHome();
    }
  };

  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    if (!this.appInanonymousMode && this.state.sectionDataUpdated) {
      this.onSaveOrExitClick(event);
    }
    else if (this.state.activeSectionId === "auth-rep") {
      if(this.state.authRepDataChanged) {
      this.onSaveOrExitClick(event);
      this.handleAuthRepDataChanged(TextProps.VALUE_FALSE);
      }
      else {
        this.goBackHandler();
      }
    } 
    else {
      this.goBackHandler();
    }
  };
  //Function to render Medical expenses section
  renderMedicalCostSections = (activeId) => {
    //Get a copy of medical cost expense question address template
    if (this.renderedMedicalSectionsCopy === "") {
      this.renderedMedicalSectionsCopy = _.cloneDeep(
        filterData(this.props.apply.sections, "expenses").subsections[3].subsections[Number(activeId.slice(-1))].subsections[0].subsections[0]
      );
    }

    let memberOptions =
      filterData(this.props.apply.sections, "expenses").subsections[3].questions[0].options;
    let selectedMembers =
      filterData(this.props.apply.sections, "expenses").subsections[3].questions[0].Answer;
    let expenseMedicalAppAdressOptions =
      filterData(this.props.apply.sections, "expenses").subsections[3].subsections[Number(activeId.slice(-1))].subsections[0].subsections;
    let expenseMedicalAddressAnswer = expenseMedicalAppAdressOptions[0].questions;
    let previousSectionsCount = Array.isArray(expenseMedicalAppAdressOptions) &&  expenseMedicalAppAdressOptions[0].subsections && expenseMedicalAppAdressOptions[0].subsections.length;

    for (
      var expenseInd = 0;
      expenseInd < expenseMedicalAppAdressOptions.length;
      expenseInd++
    ) {
      let existingMedAddrFlag = TextProps.VALUE_FALSE;
      if (activeId.includes('medical-driving')) {
        const expenseCountQuestions = expenseMedicalAppAdressOptions[expenseInd].questions;
        let expenseCount = Number(
          expenseCountQuestions[expenseCountQuestions.length - 1].conditionals[0].conditionalQuestions[0].Answer
        );
        let previousSectionsCount = expenseMedicalAppAdressOptions[0].subsections && expenseMedicalAppAdressOptions[0].subsections.length;

        if (
          expenseMedicalAppAdressOptions[expenseInd].subsections &&
          expenseMedicalAppAdressOptions[expenseInd].subsections.length === 1 &&
          !expenseMedicalAppAdressOptions[expenseInd].subsections[0].clientId
        ) {
          existingMedAddrFlag = TextProps.VALUE_FALSE;
        } else if (
          expenseMedicalAppAdressOptions[expenseInd].subsections &&
          expenseMedicalAppAdressOptions[expenseInd].subsections.length === expenseCount
        ) {
          if (
            expenseMedicalAddressAnswer[expenseMedicalAddressAnswer.length - 1].Answer ===
              "" ||
            expenseMedicalAddressAnswer[expenseMedicalAddressAnswer.length - 1].Answer ===
              "No"
          ) {
            delete expenseMedicalAppAdressOptions[expenseInd].subsections;
          }
          existingMedAddrFlag = TextProps.VALUE_TRUE;
        } else {
          existingMedAddrFlag = TextProps.VALUE_FALSE;
        }
        if (!existingMedAddrFlag) {
          if (
            expenseMedicalAppAdressOptions[expenseInd].questions[expenseMedicalAddressAnswer.length - 1].Answer ===
              "" ||
            expenseMedicalAppAdressOptions[expenseInd].questions[expenseMedicalAddressAnswer.length - 1].Answer ===
              "No"
          ) {
            delete expenseMedicalAppAdressOptions[expenseInd].subsections;
          }
          for (var addressInd = 0; addressInd < expenseCount; addressInd++) {
            if (expenseMedicalAppAdressOptions[expenseInd] && expenseMedicalAppAdressOptions[expenseInd].subsections) {
              expenseMedicalAppAdressOptions[expenseInd].subsections[addressInd] = previousSectionsCount && previousSectionsCount > addressInd ? expenseMedicalAppAdressOptions[expenseInd].subsections[addressInd] :  _.cloneDeep(expenseMedicalExpenseTemplate.subsections[0].subsections[0].subsections[0].subsections[0]);
              expenseMedicalAppAdressOptions[expenseInd].subsections[addressInd].id =  "medical-address-" + Number(activeId.slice(-1)) + "-" + addressInd;
              // expenseMedicalAppAdressOptions[expenseInd].subsections[addressInd] =  _.cloneDeep(expenseMedicalExpenseTemplate.subsections[0].subsections[0].subsections[0].subsections[0]);
              // expenseMedicalAppAdressOptions[expenseInd].subsections[addressInd].id = "medical-address-" + Number(activeId.slice(-1)) + "-" + addressInd;
            let medicalCostMember = "";
            // selected members iteration
            for (let mem = 0; mem < memberOptions.length; mem++) {
              // checking with total household members and selected members list
              if (
                memberOptions[mem].optionValue ===
                expenseMedicalAppAdressOptions[expenseInd].clientId
              ) {
                medicalCostMember = memberOptions[mem].optionDisplay;
              }
            }
            if (language === "es-ES" || language === "pt-BR") {
              expenseMedicalAppAdressOptions[expenseInd].subsections[
                addressInd
              ].title =
                medicalExpenseMultilanguageLabels(addressInd + 1, language)
                  .medConTitle +
                " " +
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))];
              expenseMedicalAppAdressOptions[expenseInd].subsections[
                addressInd
              ].header =
                medicalExpenseMultilanguageLabels(addressInd + 1, language)
                  .medConHeader +
                " " +
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))];
                if(expenseMedicalAppAdressOptions[expenseInd] && expenseMedicalAppAdressOptions[expenseInd].subsections){
                expenseMedicalAppAdressOptions[expenseInd].subsections[
                  addressInd
                ].questions[1].label = medicalExpenseMultilanguageLabels(
                  expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))],
                  language,
                ).medQoneLabel;
                expenseMedicalAppAdressOptions[expenseInd].subsections[
                  addressInd
                ].questions[2].label = medicalExpenseMultilanguageLabels(
                  expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))],
                  language
                ).medQtwoLabel;
                }
            } else if (expenseMedicalAppAdressOptions[expenseInd] && expenseMedicalAppAdressOptions[expenseInd].subsections) {
              expenseMedicalAppAdressOptions[expenseInd].subsections[
                addressInd
              ].title =
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))] +
                medicalExpenseMultilanguageLabels(addressInd + 1, language)
                  .medConTitle;
              expenseMedicalAppAdressOptions[expenseInd].subsections[
                addressInd
              ].header =
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))] +
                medicalExpenseMultilanguageLabels(addressInd + 1, language)
                  .medConHeader;

            expenseMedicalAppAdressOptions[expenseInd].subsections[
              addressInd
            ].questions[1].label = medicalExpenseMultilanguageLabels(
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))],
                language,
            ).medQoneLabel;
            expenseMedicalAppAdressOptions[expenseInd].subsections[
              addressInd
            ].questions[2].label = medicalExpenseMultilanguageLabels(
                expenseMedicalAppAdressOptions[expenseInd].memberOptions[Number(activeId.slice(-1))],
              language
            ).medQtwoLabel;
          }
        }
            this.props.appendExpenseDetails(filterData(this.props.apply.sections, "expenses"));
          }
        }
      }
    }
    this.props.appendExpenseDetails(filterData(this.props.apply.sections, "expenses"));
  };

  gotoIncomeSummary = () => {
    this.prepareIncomeSummary();
    this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.incomeSummaryTemplate);
    });
  };
  gotoResourcesSummary = () => {
    this.prepareResourcesSummary();
    this.setState({ resourcesSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.resourcesSummaryTemplate ? this.state.resourcesSummaryTemplate : filterData(this.props.apply.sections, "resources").subsections[
        filterData(this.props.apply.sections, "resources").subsections.length - 1
        ]);
    });
  };
  //Function to remove form section which have validated and saved/submitted
  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText = beforeYoustartMlanguageLabels(language).getStartedLabel;
        return returnText;
      case "sign-and-submit":
        returnText = beforeYoustartMlanguageLabels(language).submitButtonLabel;
        return returnText;
      default:
        if(!this.appInanonymousMode){
          returnText = beforeYoustartMlanguageLabels(language).saveAndContinue;
        }else{
          returnText = beforeYoustartMlanguageLabels(language).continueLabel;
        }
        return returnText;
    }
  };

  //Clearing Caring Kids
  renderHousingCostsBySelection = () => {
    let housingCostsSection = filterData(this.props.apply.sections, "expenses");
    let housingCostsPageAnswers =
      housingCostsSection.subsections[0].questions[2].Answer;
    let innerAnswers =
      housingCostsSection.subsections[0] &&
      housingCostsSection.subsections[0].subsections &&
      housingCostsSection.subsections[0].subsections[0] &&
      housingCostsSection.subsections[0].subsections[0].questions;
    innerAnswers = innerAnswers || [];
    let housingCostsFilteredQuestions = [];
    if (
      housingCostsSection.subsections[0] &&
      !housingCostsSection.subsections[0].subsections
    ) {
      housingCostsSection.subsections[0].subsections = _.cloneDeep(
        expenseHousingCostTemplate.subsections
      );
    }
    //Swap full section for first time and it should not be manipulated!
    if (
      housingCostsSection.subsections[0] &&
      housingCostsSection.subsections[0].subsections &&
      housingCostsSection.subsections[0].subsections[0]
    ) {
      const allQuestions =
        housingCostsSection.subsections[0].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = innerAnswers.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });

      housingCostsSection.subsections[0].subsections[0].questions = questions;
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    let noneTargetValue = incomeMultilanguageLabels(
      "",
      language
    ).noneTargetValue;
    if (
      housingCostsPageAnswers &&
      housingCostsPageAnswers[0] !== "" &&
      housingCostsPageAnswers[0] !== undefined &&
      housingCostsPageAnswers[0] !== noneTargetValue &&
      housingCostsSection.subsections[0] &&
      housingCostsSection.subsections[0].subsections &&
      housingCostsSection.subsections[0].subsections[0]
    ) {
      housingCostsFilteredQuestions = filterQuestionsBySelection(
        housingCostsPageAnswers,
        housingCostsSection.subsections[0].subsections[0].questions
      );
      housingCostsSection.subsections[0].subsections[0].questions = _.cloneDeep(
        housingCostsFilteredQuestions
      );
      this.props.appendExpenseDetails(housingCostsSection);
    } else {
      delete housingCostsSection.subsections[0].subsections;
      this.props.appendExpenseDetails(housingCostsSection);
    }
  };
  //Clearing Caring Kids
  renderChildSupport = () => {
    let dependentCosts = filterData(this.props.apply.sections, "expenses");
    this.mapDependentCostSection(dependentCosts);
  };
  //Function called when the section is "kids-and-adults"
  renderDependentsCareCosts = () => {
    let dependentCosts = filterData(this.props.apply.sections, "expenses");
    let parentIds = dependentCosts.subsections[1].questions[0].Answer || [];
    let parentOptions = dependentCosts.subsections[1].questions[0].options || [];
    //Filtering Answers using IDs
    let parentMembers = [];
    let dependentMembers = [];

    for (
      let parentIdIndex = 0;
      parentIdIndex < parentIds.length;
      parentIdIndex++
    ) {
      for (
        let optionIndex = 0;
        optionIndex < parentOptions.length;
        optionIndex++
      ) {
        if (
          parentIds[parentIdIndex] === parentOptions[optionIndex].optionValue
        ) {
          parentMembers.push(parentOptions[optionIndex].optionDisplay);
        }
      }
    }
    let parentNameMembers = {};
    let parentConditionals =
      dependentCosts.subsections[1].questions[0].conditionals;
    for (var p = 0; p < parentIds.length; p++) {
      parentNameMembers[parentIds[p]] = [];
      let conditionalDependentsCount = [];
      for (var v = 0; v < parentConditionals.length; v++) {
        if (parentConditionals[v].valueToMatch === parentIds[p]) {
          let selectAnswer =
            parentConditionals[v].conditionalQuestions[0].Answer; // selected members
          let selectOptions =
            parentConditionals[v].conditionalQuestions[0].options; // optional members for parents

          for (let dep = 0; dep < selectOptions.length; dep++) {
            let dependents = {};
            if (selectAnswer !== undefined) {
              for (let d = 0; d < selectAnswer.length; d++) {
                if (selectAnswer[d] === selectOptions[dep].optionValue) {
                  dependents[OptionsProps.OPTION_DISPLAY] =
                    selectOptions[dep].optionDisplay;
                  dependents[OptionsProps.OPTION_VALUE] =
                    selectOptions[dep].optionValue;
                  // adding to the dependents data and pushing to the dependents object
                  conditionalDependentsCount.push(dependents);
                }
              }
            }
          }
          // looping the dependents based on parent selected dependents
          for (var d = 0; d < conditionalDependentsCount.length; d++) {
            parentNameMembers[parentIds[p]].push(
              conditionalDependentsCount[d].optionDisplay
            );
            dependentMembers.push(
              parentMembers[p] +
                "-" +
                conditionalDependentsCount[d].optionDisplay
            );
          }
        } else {
          let isExisting = parentIds.filter(
            (value) => value === parentConditionals[v].valueToMatch
          );
          if (!isExisting.length) {
            parentConditionals[v].conditionalQuestions[0].Answer = [];
            parentConditionals[v].conditionalQuestions[0].targetValue = [];
          }
        }
      }
    }
    filterData(this.props.apply.sections, "expenses").subsections[1].dependentCare =
      dependentMembers;
    this.mapDependentCostSection(dependentCosts);

    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (dependentMembers[0] !== "" && dependentMembers.length > 0) {
      for (let memIndex = 0; memIndex < dependentMembers.length; memIndex++) {
        let dependentMembersExists = TextProps.VALUE_FALSE;
        if (dependentCosts.subsections[1].subsections) {
          let dependentMembersExisting =
            dependentCosts.subsections[1].subsections.filter(
              (sub) => sub.dependentMembers === dependentMembers[memIndex]
            );
          if (dependentMembersExisting.length) {
            dependentCosts.subsections[1].subsections.map((sub, index) => {
              if (sub.dependentMembers) {
                let dependentExists = dependentMembers.filter(
                  (dep) => dep === sub.dependentMembers
                );
                if (dependentExists.length) {
                  dependentMembersExists = TextProps.VALUE_TRUE;
                } else {
                  dependentCosts.subsections[1].subsections =
                    dependentCosts.subsections[1].subsections.filter(
                      (inner) => inner.id === sub.id
                    );
                }
              } else {
                dependentMembersExists = TextProps.VALUE_FALSE;
              }
            });
          } else {
            if (
              dependentCosts.subsections[1].subsections.length === 1 &&
              !dependentCosts.subsections[1].subsections[0].dependentMembers
            ) {
              dependentMembersExists = TextProps.VALUE_FALSE;
            } else {
              dependentMembersExists = TextProps.VALUE_TRUE;
              let addedElementIndex =
                dependentMembers.length -
                dependentCosts.subsections[1].subsections.length;
              for (
                let addedmemIndex = 0;
                addedmemIndex < addedElementIndex;
                addedmemIndex++
              ) {
                let newMemIndex =
                  dependentCosts.subsections[1].subsections.length;
                newMemIndex = newMemIndex++;
                dependentCosts.subsections[1].subsections[newMemIndex] =
                  _.cloneDeep(expenseKidsAndAdultsTemplate.subsections[0]);
                dependentCosts.subsections[1].subsections[newMemIndex].id =
                  "kids-and-adults-" + [newMemIndex];
                // dependentCosts.subsections[1].subsections[memIndex].title = parentMembers[0];
                dependentCosts.subsections[1].subsections[newMemIndex].title =
                  dependentMembers[newMemIndex].split("-")[0];
                dependentCosts.subsections[1].subsections[
                  newMemIndex
                ].dependentMembers = dependentMembers[newMemIndex];
                dependentCosts.subsections[1].subsections[newMemIndex].header =
                  // childDependnMultilanguageLabels(
                  //   dependentMembers[newMemIndex].split("-")[1],
                  //   "",
                  //   language
                  // ).depedentCostHeader;
                  // dependentCosts.subsections[1].subsections[newMemIndex].questions[2].label =
                  childDependnMultilanguageLabels(
                    dependentMembers[newMemIndex].split("-")[1],
                    "",
                    language
                  ).depedentCostHeader;
                // set lables in conditionals questions.
                for (
                  var q = 0;
                  q <
                  dependentCosts.subsections[1].subsections[newMemIndex]
                    .questions.length;
                  q++
                ) {
                  if (
                    dependentCosts.subsections[1].subsections[newMemIndex]
                      .questions[q].type === "units" &&
                    q === 0
                  ) {
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].questions[q].label = childDependnMultilanguageLabels(
                      dependentMembers[newMemIndex].split("-")[0],
                      dependentMembers[newMemIndex].split("-")[1],
                      language
                    ).dependentCostsQoneLabel;
                  } else if (
                    dependentCosts.subsections[1].subsections[newMemIndex]
                      .questions[q].type === "radio" &&
                    q === 1
                  ) {
                    /** Translation start */
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].questions[q].label = childDependnMultilanguageLabels(
                      dependentMembers[newMemIndex].split("-")[0],
                      dependentMembers[newMemIndex].split("-")[1],
                      language
                    ).dependentCostsQtwoLabel;
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].questions[
                      q
                    ].conditionals[0].conditionalQuestions[0].label =
                      childDependnMultilanguageLabels(
                        dependentMembers[newMemIndex].split("-")[0],
                        dependentMembers[newMemIndex].split("-")[1],
                        language
                      ).dependentCoditionalQLabel;
                    /** Translation end */
                  } else if (
                    dependentCosts.subsections[1].subsections[newMemIndex]
                      .questions[q].type === "radio" &&
                    q === 2
                  ) {
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].questions[q].label = childDependnMultilanguageLabels(
                      dependentMembers[newMemIndex].split("-")[0],
                      dependentMembers[newMemIndex].split("-")[1],
                      language
                    ).driveToQLabel;
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].questions[
                      q
                    ].conditionals[0].conditionalQuestions[0].label =
                      childDependnMultilanguageLabels(
                        dependentMembers[newMemIndex].split("-")[0],
                        dependentMembers[newMemIndex].split("-")[1],
                        language
                      ).dependConQuestion;
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].subsections[0].header = childDependnMultilanguageLabels(
                      dependentMembers[newMemIndex].split("-")[0],
                      "",
                      language
                    ).chAdultCareAddrHeader;
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].subsections[0].title =
                      dependentMembers[newMemIndex].split("-")[1];
                    dependentCosts.subsections[1].subsections[
                      newMemIndex
                    ].subsections[0].questions[1].label =
                      incomeSectionMultilanguageLabels(
                        language
                      ).addressProvider;
                  }
                }
              }
            }
          }
        }
        if (!dependentMembersExists) {
          if (!dependentCosts.subsections[1].subsections)
            dependentCosts.subsections[1].subsections = [];
          dependentCosts.subsections[1].subsections[memIndex] = _.cloneDeep(
            expenseKidsAndAdultsTemplate.subsections[0]
          );
          dependentCosts.subsections[1].subsections[memIndex].id =
            "kids-and-adults-" + [memIndex];
          // dependentCosts.subsections[1].subsections[memIndex].title = parentMembers[0];
          dependentCosts.subsections[1].subsections[memIndex].title =
            dependentMembers[memIndex].split("-")[0];
          dependentCosts.subsections[1].subsections[memIndex].dependentMembers =
            dependentMembers[memIndex];
          dependentCosts.subsections[1].subsections[memIndex].header =
            // childDependnMultilanguageLabels(
            //   dependentMembers[memIndex].split("-")[1],
            //   "",
            //   language
            // ).depedentCostHeader;
            // dependentCosts.subsections[1].subsections[memIndex].questions[2].label =
            childDependnMultilanguageLabels(
              dependentMembers[memIndex].split("-")[1],
              "",
              language
            ).depedentCostHeader;
          dependentCosts.subsections[1].subsections[memIndex].subHeader = "";
          // set lables in conditionals questions.
          for (
            var q = 0;
            q <
            dependentCosts.subsections[1].subsections[memIndex].questions
              .length;
            q++
          ) {
            if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "units" &&
              q === 0
            ) {
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).dependentCostsQoneLabel;
            } else if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "radio" &&
              q === 1
            ) {
              /** Translation start */
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).dependentCostsQtwoLabel;
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCoditionalQLabel;
              /** Translation end */
            } else if (
              dependentCosts.subsections[1].subsections[memIndex].questions[q]
                .type === "radio" &&
              q === 2
            ) {
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].label = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                dependentMembers[memIndex].split("-")[1],
                language
              ).driveToQLabel;
              dependentCosts.subsections[1].subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependConQuestion;
              dependentCosts.subsections[1].subsections[
                memIndex
              ].subsections[0].header = childDependnMultilanguageLabels(
                dependentMembers[memIndex].split("-")[0],
                "",
                language
              ).chAdultCareAddrHeader;
              dependentCosts.subsections[1].subsections[
                memIndex
              ].subsections[0].title = dependentMembers[memIndex].split("-")[1];
              dependentCosts.subsections[1].subsections[
                memIndex
              ].subsections[0].questions[1].label =
                incomeSectionMultilanguageLabels(language).addressProvider;
            }
          }
        }
      }
      // }
      this.props.appendExpenseDetails(dependentCosts);
    } else {
      delete dependentCosts.subsections[1].subsections;
      this.props.appendExpenseDetails(dependentCosts);
    }
  };
  //Function to Manipulate Details on Expense child support page
  mapDependentCostSection = (dependents) => {
    let memberListInfo = this.mapMemberListOptions();
    dependents.subsections[2].questions[0].options = memberListInfo;

    //clone the dependent conditional object
    let dependentConditional = _.cloneDeep(
      dependents.subsections[2].questions[0].conditionals[0]
    );
    dependents.subsections[2].questions[0].conditionals.map((cond) => {
      let alreadyExists = dependents.subsections[2].questions[0].options.filter(
        (opt) => opt.optionValue === cond.valueToMatch
      ).length;
      if (!alreadyExists) {
        dependents.subsections[2].questions[0].conditionals = [];
        dependents.subsections[2].questions[0].options.map(function (
          member,
          index
        ) {
          dependents.subsections[2].questions[0].conditionals.push(
            _.cloneDeep(dependentConditional)
          );
          dependents.subsections[2].questions[0].conditionals[
            index
          ].conditionalQuestions[0].Answer = [];
          dependents.subsections[2].questions[0].conditionals[
            index
          ].conditionalQuestions[0].name = "child-support-cost-" + index;
          dependents.subsections[2].questions[0].conditionals[
            index
          ].conditionalQuestions[1].name = "for-who-paid-" + index;
          dependents.subsections[2].questions[0].conditionals[
            index
          ].conditionalQuestions[2].name = "to-who-paid-" + index;
          dependents.subsections[2].questions[0].conditionals[
            index
          ].conditionalQuestions[0].label = childDependnMultilanguageLabels(
            member.optionDisplay,
            "",
            language
          ).childSupptcondQLabel;
        });
      }
    });
  };

  // Toggle whether adding new income members is open on state
  toggleAddIncomeMembers = () => {
    this.setState({ addIncomeMembersOpen: !this.state.addIncomeMembersOpen });
  };
  // Toggle whether adding new income members is open on state
  toggleAddResourcesMembers = () => {
    this.setState({ addResourcesMembersOpen: !this.state.addResourcesMembersOpen });
  };

  // Functions for setting state for if save progress modal is open or not
  presentSaveProgressModal = (e) => {
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.EVENT_CATEGORY_PARTIAL,
        googleAnalyticsConstVar.EVENT_ACTION_PARTIAL,
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();

    e.preventDefault();
    this.setState({ saveProgressModalOpen: TextProps.VALUE_TRUE });
    // saving the json for GUEST User on clicking save my progress later
  };

  closeSaveProgressModal = () => {
    this.setState({ saveProgressModalOpen: TextProps.VALUE_FALSE });
  };

  // Functions for setting state for if warning progress modal is open or not
  presentWarningProgressModal = (val) => {
    let isDeleteValidCount =
      filterData(this.props.apply.sections, "my-household").questions[0].Answer - val;
    this.setState({
      warningProgressModalOpen: TextProps.VALUE_TRUE,
      selectedHouseholdMemCount: val,
      isDeleteValidCount: isDeleteValidCount,
    });
  };

  closeWarningProgressModal = () => {
    this.setState({ warningProgressModalOpen: TextProps.VALUE_FALSE });
    let Updatedsections;
    console.log(this.state.selectedHouseholdMemCount);
    if (
      this.state.confirmedHouseholdMemForDeletion.length &&
      filterData(this.props.apply.sections, "my-household").questions[0].Answer !==
        this.state.confirmedHouseholdMemForDeletion.length.toString()
    ) {
      // this.state.AnswerObject['income'] = {};
      if (this.incomeSummary.incomeMembers) {
        this.state.confirmedHouseholdMemForDeletion.map((deletedMem) => {
          this.incomeSummary.incomeMembers =
            this.incomeSummary.incomeMembers.filter(
              (deleteHousehold) =>
                nameWithoutWhiteSpace(deleteHousehold.firstName) !==
                nameWithoutWhiteSpace(deletedMem)
            );
        });
      }
      Updatedsections = deleteHouseholdMems(
        this.state.sections,
        this.state.confirmedHouseholdMemForDeletion
      );
      this.setDeletedHouseholdDetails(Updatedsections);
    } else if (!this.state.confirmedHouseholdMemForDeletion.length) {
      filterData(this.props.apply.sections, "my-household").questions[0].Answer =
        filterData(this.props.apply.sections, "my-household").subsections.length.toString();
      this.setDeletedHouseholdDetails(this.props.apply.sections);
      this.setState({ isLoading: TextProps.VALUE_TRUE });
    }
  };
  setDeletedHouseholdDetails = (Updatedsections) => {
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    this.setState(
      {
        sections: Updatedsections,
      },
      () => {
        //window.location.reload();
        this.setState({ isLoading: TextProps.VALUE_FALSE });
        this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
      }
    );
  };
  // Markup for the body of apply page when user is starting application.
  renderBeforeYouStart = () => {
    let component = null;
    if (
      applicationType === TextProps.MATCH_SNAP ||
      applicationType === TextProps.MATCH_COMBO
    ) {
      component = <BeforeYouStart />;
    } else if (applicationType === TextProps.MATCH_TANF) {
      component = <TanfBeforeYouStart />;
    } else {
      component = <BeforeYouStart />;
    }
    return component;
  };

  //Edit Button in Income Summary
  incomeSummaryEdidClick = (section) => {
    this.setState({ incomeSummaryEdit: TextProps.VALUE_TRUE });
    this.onSectionChange(section);
  };

  // Markup for a summary item in "Income Summary" section,
  // Used in below renderIncomeSummary
  renderIncomeSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p className="apply__heading apply__body-header">
          {beforeYoustartMlanguageLabels(language).incomeOf}
          {summary.firstName}
        </p>
        {/*
          The edit button should route the user to the appropriate apply section
          to edit income for this household member.
          Later, when the user clicks continue on that section, they are returned to the
          "Income Summary" section again.
        */}
        <button
          className="dta-button dta-bordered-button income-edit-btn"
          onClick={() => this.incomeSummaryEdidClick(summary.section)}
        >
          {beforeYoustartMlanguageLabels(language).editButtonLabel}
        </button>
        <ul>
          {summary.incomes.map((incomeItem) => {
            if (incomeItem.Answer) {
              return incomeItem.Answer.map((answer) => {
                return (
                  <li key={answer.id}>
                    {incomeItem.type} - ${answer.amount}{" "}
                    {beforeYoustartMlanguageLabels(language).perLabel}{" "}
                    {answer.unit}
                  </li>
                );
              });
            }
          })}
        </ul>
      </div>
    );
  };

  //prepareIncomeSummary
  prepareIncomeSummary = () => {
    let incomeSection = filterData(this.props.apply.sections, "income");
    // let houseMemberList = this.state.houseMemberLabels && this.state.houseMemberLabels.length ? this.state.houseMemberLabels : incomeSection.memberOptions;
    let houseMemberList = filterData(this.props.apply.sections, "income").questions[0].options;
    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      // currentIncomeMembers[memberIncomeIndex] = incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].clientId;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];

      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    if (incomeSection.subsections.length > 1) {
      for (var j = 0; j < houseMemberList.length; j++) {
        let present = false;
        currentIncomeMembers.map((cur) => {
          if (cur === houseMemberList[j].optionValue) {
            present = !present;
          }
        });
        if (!present) {
          let nonMember = {};
          nonMember.id = nonMemberCount;
          nonMember.clientId = houseMemberList[j].optionValue;
          nonMember.firstName = houseMemberList[j].optionDisplay;
          this.incomeSummary.nonIncomeMembers.push(nonMember);
          nonMemberCount++;
        }
      }
    }
  };

  // Markup for the body of apply page when at "Income Summary" section
  renderIncomeSummary = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p className="text--small">
          {beforeYoustartMlanguageLabels(language).lookIncorrectLabel}
        </p>
        <hr className="divider" style={{ borderColor: "#828282" }} />
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.incomeSummary.incomeMembers.map((summary) =>
          this.renderIncomeSummaryItem(summary)
        )}
        <button
          className={`dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon add-new-button ${
            this.state.addIncomeMembersOpen ? "is-active" : ""
          }`}
          onClick={this.toggleAddIncomeMembers}
          aria-expanded={this.state.addIncomeMembersOpen}
        >
          {/* should the icon be hidden from screen readers altogether when expand the data*/}
          <Icon
            ariaHidden
            name={this.state.addIncomeMembersOpen ? "minus" : "plus"}
          />
          {beforeYoustartMlanguageLabels(language).anotherHouseholdIncomeLabel}
        </button>
        <Collapse isOpened={this.state.addIncomeMembersOpen}>
          <span className="add-income-summary__label"></span>
          <ul className="list--unstyled add-income-summary__list">
            {this.incomeSummary.nonIncomeMembers.map((member) => {
              return (
                <li key={member.id}>
                  {/*
                    Clicking the following button routes the user to a
                    new child Income section to fill out income info for this
                    household member (e.g. "Listing John's Income"). When user
                    is finished and clicks 'Continue', they will be returned to Income Summary page
                  */}
                  <span className="sr-only">Add income for </span>
                  <button
                    className="full-width dta-button text--small interim-recert-outline-button"
                    onClick={() => {
                      this.renderIncomeToAddNewMember(member.firstName);
                    }}
                  >
                    <b>{member.firstName}</b>
                  </button>
                </li>
              );
            })}
          </ul>
        </Collapse>
      </div>
    );
  };

  renderIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = this.state.incomeMemberTemplate
      ? this.state.incomeMemberTemplate
      : IncomeSectionTemplate;
    var incomeCopy = filterData(this.props.apply.sections, "income");
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);

    incomeMeTemplate.id = "combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = incomeMultilanguageLabels(name, language).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = incomeMultilanguageLabels(
      name,
      language
    ).mainQuestion;
    incomeMeTemplate.subsections[0].id =
      "combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = incomeMultilanguageLabels(
      name,
      language
    ).heading;
    incomeMeTemplate.subsections[0].title = name;
    incomeMeTemplate.title = name;
    if (
      incomeCopy !== undefined &&
      incomeCopy.questions !== undefined &&
      incomeCopy.questions.length > 0
    ) {
      for (let income = 0; income < incomeCopy.questions.length; income++) {
        for (
          let incomeOpt = 0;
          incomeOpt < incomeCopy.questions[income].options.length;
          incomeOpt++
        ) {
          if (
            incomeCopy.questions[income].Answer[income] ===
              incomeCopy.questions[income].options[incomeOpt].optionValue &&
            name ===
              incomeCopy.questions[income].options[incomeOpt].optionDisplay
          ) {
            incomeMeTemplate.clientId =
              incomeCopy.questions[income].options[incomeOpt].optionValue;
          } else if (
            name ===
            incomeCopy.questions[income].options[incomeOpt].optionDisplay
          ) {
            incomeMeTemplate.clientId =
              incomeCopy.questions[income].options[incomeOpt].optionValue;
          }
        }
      }
    }
    let incomeReturn = incomeMultilanguageLabels(name, language); //name
    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
        incomeReturn.subQuestions1[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions1[incomeMeTemplate.questions[0].options[j].optionValue][Props.LABEL];
    }
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendIncomeDetails(incomeCopy);
    this.onSectionChange(incomeMeTemplate);
  };

  //Edit Button in Resources Summary
  resourcesSummaryEditClick = (section) => {
    this.setState({ resourcesSummaryEdit: TextProps.VALUE_TRUE });
    this.onSectionChange(section);
  };

  // Markup for a summary item in "Resources Summary" section,
  // Used in below renderResourcesSummary
  renderResourcesSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p className="apply__heading apply__body-header">
          {beforeYoustartMlanguageLabels(language).resourcesOf}
          {summary.firstName}
        </p>
        <button
          className="dta-button dta-bordered-button income-edit-btn"
          onClick={() => this.resourcesSummaryEditClick(summary.section)}
        >
          {beforeYoustartMlanguageLabels(language).editButtonLabel}
        </button>
        <ul>
          {summary.resources.map((resourceItem) => {
            if (resourceItem.Answer) {
              return resourceItem.Answer.map((answer,id) => {
                return (
                  <div className="pure-g">
                    <div className="pure-u-1-1">
                    {id === 0 && <div className="margin-bottom-half theme-colour">{resourceItem.type}<br/></div>}
                    <div className="pure-u-2-3 margin-bottom-half">{beforeYoustartMlanguageLabels(language).amountLabel}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half">${answer.amount}</div>
                    {answer.bankName && <div><div className="pure-u-2-3 margin-bottom-half">{beforeYoustartMlanguageLabels(language).bankNameLabel}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half" style={{ wordBreak: "break-word" }}>{answer.bankName}</div></div> }
                    </div>
                  </div>
                  // <li key={answer.id}>
                  //   {resourceItem.type} - ${answer.amount}{" "}
                  //   <li key={answer.id}> {resourceItem.type} - ${answer.amount}{" "}</li>
                  //   {answer.bankName && <li key={answer.id}> {resourceItem.type} - ${answer.bankName}{" "}</li>}
                  // </li>
                );
              });
            }
          })}
        </ul>
      </div>
    );
  };
  //prepareResourcesSummary
  prepareResourcesSummary = () => {
    let resourcesSection = filterData(this.props.apply.sections, "resources");
    let houseMemberList = filterData(this.props.apply.sections, "resources").questions[0].options;
    this.resourcesSummary.resourcesMembers = [];
    this.resourcesSummary.nonResourcesMembers = [];
    let currentResourcesMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < resourcesSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.resourcesSummary.resourcesMembers[memberIncomeIndex] = {};
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].section =
      resourcesSection.subsections[memberIncomeIndex];
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].id =
      resourcesSection.subsections[memberIncomeIndex].id;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].firstName =
      resourcesSection.subsections[memberIncomeIndex].title;
      // currentResourcesMembers[memberIncomeIndex] = resourcesSection.subsections[memberIncomeIndex].title;
      currentResourcesMembers[memberIncomeIndex] =
      resourcesSection.subsections[memberIncomeIndex].clientId;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourceType =
      resourcesSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources = [];

      if (
        resourcesSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs =
        resourcesSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs
            .length;
          questionIndex++
        ) {
          if (
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ] = {};
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].type =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].displayIncomeName;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].Answer =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    if (resourcesSection.subsections.length > 1) {
      for (var j = 0; j < houseMemberList.length; j++) {
        let present = false;
        currentResourcesMembers.map((cur) => {
          if (cur === houseMemberList[j].optionValue) {
            present = !present;
          }
        });
        if (!present && houseMemberList[j].optionValue !== resourcesSectionMultilanguageLabels(language).noResourcesToReportOption) {
          let nonMember = {};
          nonMember.id = nonMemberCount;
          nonMember.clientId = houseMemberList[j].optionValue;
          nonMember.firstName = houseMemberList[j].optionDisplay;
          this.resourcesSummary.nonResourcesMembers.push(nonMember);
          nonMemberCount++;
        }
      }
    }
  };
  // Markup for the body of apply page when at "Income Summary" section
  renderResourcesSummary = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p className="text--small">
          {beforeYoustartMlanguageLabels(language).lookResourcesCorrectLabel}
        </p>
        <hr className="divider" style={{ borderColor: "#828282" }} />
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.resourcesSummary.resourcesMembers.map((summary) =>
          this.renderResourcesSummaryItem(summary)
        )}
        <button
          className={`dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon add-new-button ${
            this.state.addResourcesMembersOpen ? "is-active" : ""
          }`}
          onClick={this.toggleAddResourcesMembers}
          aria-expanded={this.state.addResourcesMembersOpen}
        >
          {/* should the icon be hidden from screen readers altogether when expand the data*/}
          <Icon
            ariaHidden
            name={this.state.addResourcesMembersOpen ? "minus" : "plus"}
          />
          {beforeYoustartMlanguageLabels(language).anotherHouseholdResourcesLabel}
        </button>
        <Collapse isOpened={this.state.addResourcesMembersOpen}>
          <span className="add-income-summary__label"></span>
          <ul className="list--unstyled add-income-summary__list">
            {this.resourcesSummary.nonResourcesMembers.map((member) => {
              return (
                <li key={member.id}>
                  <span className="sr-only">Add resources for </span>
                  <button
                    className="full-width dta-button text--small interim-recert-outline-button"
                    onClick={() => {
                      this.renderResourcesToAddNewMember(member.firstName);
                    }}
                  >
                    <b>{member.firstName}</b>
                  </button>
                </li>
              );
            })}
          </ul>
        </Collapse>
      </div>
    );
  };

  renderResourcesToAddNewMember = (name) => {
    let resourcesMeTemplate = this.state.resourcesMemberTemplate
      ? this.state.resourcesMemberTemplate
      : ResourcesSectionTemplate;
    let resourcesCopy = filterData(this.props.apply.sections, "resources");
    let resourcesSubsectionCopy = resourcesCopy.subsections;

    let resourcesSummaryCopy = _.cloneDeep(
      resourcesCopy.subsections[resourcesCopy.subsections.length - 1]
    );
    resourcesCopy.subsections.splice(resourcesCopy.subsections.length - 1, 1);

    resourcesMeTemplate.id = "combined-resources-" + resourcesCopy.subsections.length;
    resourcesMeTemplate.header = resourcesSectionMultilanguageLabels(
      language,
      name
    ).heading;
    resourcesMeTemplate.questions[0][Props.LABEL] = resourcesSectionMultilanguageLabels(language).resourceSubSectiontitle;
    resourcesMeTemplate.subsections[0].id =
      "combined-resources-subsection-" + resourcesCopy.subsections.length;
    resourcesMeTemplate.subsections[0].header = resourcesSectionMultilanguageLabels(
      language,
      name
    ).heading;
    resourcesMeTemplate.subsections[0].title = name;
    resourcesMeTemplate.title = name;
    if (
      resourcesCopy !== undefined &&
      resourcesCopy.questions !== undefined &&
      resourcesCopy.questions.length > 0
    ) {
      for (let resource = 0; resource < resourcesCopy.questions.length; resource++) {
        for (
          let resourceOpt = 0;
          resourceOpt < resourcesCopy.questions[resource].options.length;
          resourceOpt++
        ) {
          if (
            resourcesCopy.questions[resource].Answer[resource] ===
            resourcesCopy.questions[resource].options[resourceOpt].optionValue &&
            name ===
            resourcesCopy.questions[resource].options[resourceOpt].optionDisplay
          ) {
            resourcesMeTemplate.clientId =
            resourcesCopy.questions[resource].options[resourceOpt].optionValue;
          } else if (
            name ===
            resourcesCopy.questions[resource].options[resourceOpt].optionDisplay
          ) {
            resourcesMeTemplate.clientId =
            resourcesCopy.questions[resource].options[resourceOpt].optionValue;
          }
        }
      }
    }
    resourcesSubsectionCopy.push(_.cloneDeep(resourcesMeTemplate));
    resourcesSubsectionCopy.push(resourcesSummaryCopy);
    resourcesCopy.subsections = [];
    resourcesCopy.subsections = resourcesSubsectionCopy;
    this.props.appendResourcesDetails(resourcesCopy);
    this.onSectionChange(resourcesMeTemplate);
  };

  // Markup for the body of apply page when at first "Expenses" section
  renderExpensesIndex = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p>{beforeYoustartMlanguageLabels(language).expenseFieldLabel}</p>
        <ul>
          <li>
            {beforeYoustartMlanguageLabels(language).expenseHouseutility}{" "}
          </li>
          <li>{beforeYoustartMlanguageLabels(language).expenseCaring}</li>
          <li>{beforeYoustartMlanguageLabels(language).expenseChsupport}</li>
          <li>{beforeYoustartMlanguageLabels(language).expenseMed}</li>
        </ul>
        <p className="text">
          {beforeYoustartMlanguageLabels(language).expenseAnyinfo}
        </p>
      </div>
    );
  };

  openModal = () => {
    this.setState({ viewApplicationDataPopupOpen: TextProps.VALUE_TRUE });
  };

  closeModal = () => {
    this.setState({ viewApplicationDataPopupOpen: TextProps.VALUE_FALSE });
  };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: event }, () => {
      let userName = "";
      if (filterData(this.props.apply.sections, "about-me").questions[0].Answer !== "") {
        userName =
          userName + filterData(this.props.apply.sections, "about-me").questions[0].Answer + " ";
      }
      if (filterData(this.props.apply.sections, "about-me").questions[1].Answer !== "") {
        userName =
          userName + filterData(this.props.apply.sections, "about-me").questions[1].Answer + " ";
      }
      if (filterData(this.props.apply.sections, "about-me").questions[2].Answer !== "") {
        userName = userName + filterData(this.props.apply.sections, "about-me").questions[2].Answer;
      }
      // if (
      //   this.state.signature.length < 1 ||
      //   this.state.signature !== userName
      // ) {
      //   this.setState({ signatureError: TextProps.VALUE_TRUE });
      //   this.signatureErrorMsg =
      //     beforeYoustartMlanguageLabels(language).signatureErrorMsg;
      // } else {
      //   this.setState({ signatureError: TextProps.VALUE_FALSE });
      //   this.signatureErrorMsg = "";
      // }
    });
  };
  //Function called on SignAndSubmit section
  onConsentDHRChange = (event) => {
    this.setState({CFDHR:event === beforeYoustartMlanguageLabels(language).optionYesNo[0]?'Y':'N'}, () => {
      if (!this.state.CFDHR) {
        this.setState({ consentForDHRVerifyError: TextProps.VALUE_TRUE });
        this.state.consentForDHRVerifyErrorMsg =
          beforeYoustartMlanguageLabels(language).consentForDHRVerifyErrorMsg;
      } else {
        this.setState({ consentForDHRVerifyError: TextProps.VALUE_FALSE });
        this.state.consentForDHRVerifyErrorMsg = "";
      }
    })
  }
  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agreeErrorMsg =
          beforeYoustartMlanguageLabels(language).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agreeErrorMsg = "";
      }
    });
  };

  renderSummary = () => {
    return(
      <SummaryContainer />
    );
  }

  handleebtAuthRepFlg = (value) => {
    this.setState((prevState) => ({
      addAuthRepData: {
          ...prevState.addAuthRepData,
          ebtAuthRepFlg: value
      }
  }));
  // this.state.addAuthRepData.ebtAuthRepFlg = value;
  }

  handlesnpAuthRepFlg = (value) => {
    this.setState((prevState) => ({
      addAuthRepData: {
          ...prevState.addAuthRepData,
          snapAuthRepFlg: value
      }
  }));
  }

  setFlag = (flagName, value) => {
    this.setState((prevState) => ({
        flags: {
            ...prevState.flags,
            [flagName]: value
        }
    }));
}

verifyFieldValidationOnSave = () => {

  this.state.flags = {};

  let fieldErrorsFound = TextProps.VALUE_FALSE;
  if (this.state.addAuthRepData.ebtAuthRepFlg === "Y") {
    if (this.state.addAuthRepData.ebtFrstNam === "" || this.state.addAuthRepData.ebtFrstNam === null || this.state.addAuthRepData.ebtFrstNam === undefined) {
      this.setFlag("ebtFrstNamError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
    }
    if (this.state.addAuthRepData.ebtLstNam === "" || this.state.addAuthRepData.ebtLstNam === undefined || this.state.addAuthRepData.ebtLstNam === null) {
      this.setFlag("ebtLstNamError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
    }
    if (this.state.addAuthRepData.ebtPhNum === "" || this.state.addAuthRepData.ebtPhNum === undefined || this.state.addAuthRepData.ebtPhNum === null || this.state.addAuthRepData.ebtPhNum.length < 14) {
      this.setFlag("ebtPhNumError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
      if(this.state.addAuthRepData.ebtPhNum && this.state.addAuthRepData.ebtPhNum.length < 14) {
        this.setState({ebtPhoneValid: TextProps.VALUE_TRUE})
      }
      if(this.state.addAuthRepData.ebtPhNum === "") {
        this.setState({ebtPhoneValid: TextProps.VALUE_FALSE})
      }
    }
  }
  if (this.state.addAuthRepData.snapAuthRepFlg === "Y") {
    if (this.state.addAuthRepData.snpFrstNam === "" || this.state.addAuthRepData.snpFrstNam === null || this.state.addAuthRepData.snpFrstNam === undefined) {
      this.setFlag("snpFrstNamError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
    }
    if (this.state.addAuthRepData.snpLstNam === "" || this.state.addAuthRepData.snpLstNam === null || this.state.addAuthRepData.snpLstNam === undefined) {
      this.setFlag("snpLstNamError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
    }
    if (this.state.addAuthRepData.snpPhNum === "" || this.state.addAuthRepData.snpPhNum === null || this.state.addAuthRepData.snpPhNum === undefined || this.state.addAuthRepData.snpPhNum.length < 14) {
      this.setFlag("snpPhNumError", TextProps.VALUE_TRUE);
      fieldErrorsFound = true;
      if(this.state.addAuthRepData.snpPhNum && this.state.addAuthRepData.snpPhNum.length < 14) {
        this.setState({snpPhoneValid: TextProps.VALUE_TRUE})
      }
      if(this.state.addAuthRepData.snpPhNum === "") {
        this.setState({snpPhoneValid: TextProps.VALUE_FALSE})
      }
    }
  }
  return fieldErrorsFound;
}

clearFieldsByQuestion = (question) => {
  if(question === "ebtQuestion") {
    this.inputCheck(null, "ebtFrstNam");
    this.inputCheck(null, "ebtLstNam");
    this.inputCheck(null, "ebtMdlNam");
    this.inputCheck(null, "ebtPhNum");
    this.inputCheck(null, "ebtSuffix");
    this.inputCheck(null, "ebtWebDataChangeCode");
  }
  if(question === "snapQuestion") {
    this.inputCheck(null, "snpFrstNam");
    this.inputCheck(null, "snpLstNam");
    this.inputCheck(null, "snpMdlNam");
    this.inputCheck(null, "snpPhNum");
    this.inputCheck(null, "snpSuffix");
    this.inputCheck(null, "snpWebDataChangeCode");
  }

}

compareObjects = (ntwObj, localObj) => {
  const updatedFields = [];
  for (const key in ntwObj) {
    if(ntwObj.hasOwnProperty(key) && localObj.hasOwnProperty(key)){
      if(ntwObj[key] !== localObj[key]){
        updatedFields.push(key);
      }
    }
  }
  return updatedFields;
}

CheckForQuestionsDataModified = () => {
  let networkObj = filterData(this.props.apply.sections, "auth-rep").authorizedRepresentatives;
  let localObj = this.state.addAuthRepData;
  const updatedFields = this.compareObjects(networkObj, localObj);
  if(updatedFields.length >= 1) {
    let theQuestion = "";
    if(updatedFields.includes("ebtFrstNam") || updatedFields.includes("ebtLstNam") || updatedFields.includes("ebtMdlNam") || updatedFields.includes("ebtPhNum") ) {
      theQuestion = "ebtQuestion";
      this.statusChangeCode(theQuestion);
    }
    if(updatedFields.includes("snpFrstNam") || updatedFields.includes("snpLstNam") || updatedFields.includes("snpMdlNam") || updatedFields.includes("snpPhNum") ) {
      theQuestion = "snapQuestion";
      this.statusChangeCode(theQuestion);
    }
  }else{
    return;
  }
}

statusChangeCode = (question) => {
  if(question === "ebtQuestion"){
    if((this.state.addAuthRepData.ebtAuthRepFlg === "Y" || this.state.addAuthRepData.ebtWebDataChangeCode === "NEW") && (this.state.addAuthRepData.ebtWebDataChangeCode !== "NOCHNG" && this.state.addAuthRepData.ebtWebDataChangeCode !== "CHANGE")){
      this.setState(prevState => ({
        addAuthRepData: {
            ...prevState.addAuthRepData,
            ebtWebDataChangeCode: "NEW",
        }
    })
    );
    this.state.addAuthRepData.ebtWebDataChangeCode = "NEW"
  }
    if(this.state.addAuthRepData.ebtWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.ebtWebDataChangeCode === "CHANGE"){
        //If data is updated. 
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              ebtWebDataChangeCode: "CHANGE",
          }}))
    this.state.addAuthRepData.ebtWebDataChangeCode = "CHANGE"

    }
  }
  if(question === "snapQuestion"){ 
    if((this.state.addAuthRepData.snapAuthRepFlg === "Y" || this.state.addAuthRepData.snpWebDataChangeCode  === "NEW") && (this.state.addAuthRepData.snpWebDataChangeCode !== "NOCHNG" && this.state.addAuthRepData.snpWebDataChangeCode  !== "CHANGE")) {
      this.setState(prevState => ({
        addAuthRepData: {
            ...prevState.addAuthRepData,
            snpWebDataChangeCode: "NEW"
        }
      })
      );
      this.state.addAuthRepData.snpWebDataChangeCode = "NEW";
  }
    if(this.state.addAuthRepData.snpWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.snpWebDataChangeCode  === "CHANGE"){
      this.setState((prevState) => ({
        addAuthRepData: {
            ...prevState.addAuthRepData,
            snpWebDataChangeCode: "CHANGE",
        }}))
    this.state.addAuthRepData.snpWebDataChangeCode = "CHANGE"

    }
  }
}

handleAuthRepDataChanged = (value) => {
  this.setState({authRepDataChanged: value})
}

inputCheck = (event, key) => {
  this.setState((prevState) => ({
    addAuthRepData: {
        ...prevState.addAuthRepData,
        [key]: event
    }
}));
this.setState({authRepDataUpdated: TextProps.VALUE_TRUE})
this.handleAuthRepDataChanged(TextProps.VALUE_TRUE);
  };

  OnRemoveButton = (question) => {
    if(question === "ebtQuestion"){
      if(this.state.addAuthRepData.ebtAuthRepFlg === "N" || this.state.addAuthRepData.ebtAuthRepFlg === "Y" || this.state.addAuthRepData.ebtAuthRepFlg === null || this.state.addAuthRepData.ebtWebDataChangeCode === "NEW" || this.state.addAuthRepData.ebtAuthRepFlg === null) {
          this.clearFieldsByQuestion("ebtQuestion");
          this.state.addAuthRepData.ebtAuthRepFlg = null;
          this.setState((prevState) => ({
            addAuthRepData: {
                ...prevState.addAuthRepData,
                ebtWebDataChangeCode: null
            }
        }));
        this.setFlag("ebtFrstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtLstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtPhNumError", TextProps.VALUE_FALSE);
      }
      if(this.state.addAuthRepData.ebtWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.ebtWebDataChangeCode === "CHANGE") {
        // this.setState({ebtAuthRepFlg: null});
        this.state.addAuthRepData.ebtAuthRepFlg = null;
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              ebtWebDataChangeCode: "REMOVE"
          }
      }));
      }
    }
    if(question === "snapQuestion"){
      if(this.state.addAuthRepData.snapAuthRepFlg === "N" || this.state.addAuthRepData.snapAuthRepFlg === "Y" || this.state.addAuthRepData.snapAuthRepFlg === null || this.state.addAuthRepData.snpWebDataChangeCode === "NEW" || this.state.addAuthRepData.snapAuthRepFlg === null) {
        this.clearFieldsByQuestion("snapQuestion");
          this.state.addAuthRepData.snapAuthRepFlg = null;
          this.setState((prevState) => ({
            addAuthRepData: {
                ...prevState.addAuthRepData,
                snpWebDataChangeCode: null
            }
        }));
        this.setFlag("snpFrstNamError", TextProps.VALUE_FALSE);
        this.setFlag("snpLstNamError", TextProps.VALUE_FALSE);
        this.setFlag("snpPhNumError", TextProps.VALUE_FALSE);
      }
      if(this.state.addAuthRepData.snpWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.snpWebDataChangeCode === "CHANGE") {
        // this.setState({snapAuthRepFlg: null});
        this.state.addAuthRepData.snapAuthRepFlg = null;
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              snpWebDataChangeCode: "REMOVE"
          }
      }));
      }
    }
  }

  removeExtraSpaceFromPhNum = (phone) => {
    if(phone) {
      return phone.replace(/[\s()-]/g, '');
    }
  }

  validatePhoneNumber = (phone) => {
    let phoneNumberString = phone;
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let { textOptIn } = this.state;
    if (
      (cleaned === "" && !textOptIn) ||
      (cleaned !== "" && cleaned.length === 10)
    )
    return TextProps.VALUE_TRUE;
  }; 

  renderAuthRep = () => {
    return(
      <AuthRep
      handleebtAuthRepFlg={this.handleebtAuthRepFlg}
      handlesnpAuthRepFlg={this.handlesnpAuthRepFlg}
      ebtAuthRepFlg={this.state.ebtAuthRepFlg}
      snapAuthRepFlg={this.state.snapAuthRepFlg}
      setFlag={this.setFlag}
      flags={this.state.flags}
      ebtPhoneValid={this.state.ebtPhoneValid}
      snpPhoneValid={this.state.snpPhoneValid}
      addAuthRepData={this.state.addAuthRepData}
      onSectionChange={this.onSectionChange}
      clearFieldsByQuestion={this.clearFieldsByQuestion}
      inputCheck={this.inputCheck}
      handleAuthRepDataChanged={this.handleAuthRepDataChanged}
      OnRemoveButton={this.OnRemoveButton}
      validatePhoneNumber={this.validatePhoneNumber}
      />
    )
  }

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmit = () => {
    return (
      <SignAndSubmit
        submitFormId={this.state.submitFormId}
        agreeError={this.state.agreeError}
        agreeErrorMsg={this.agreeErrorMsg}
        signatureError={this.state.signatureError}
        signatureErrorMsg={this.signatureErrorMsg}
        updateSignature={this.updateSignature}
        signature={this.state.signature}
        acceptTermsConditions={this.acceptTermsConditions}
        onConsentDHRChange ={this.onConsentDHRChange}
        consentForDHRVerifyError ={this.state.consentForDHRVerifyError}
        consentForDHRVerifyErrorMsg ={this.state.consentForDHRVerifyErrorMsg}
      />
    );
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    const msalInstance = this.props.msalContext.instance;
    const { search } = this.props.language.provider;
    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    let roleName = [];
    if (loggedInUser !== null && loggedInUser !== undefined) {
      const roleList = loggedInUser.roleList;
      if (roleList !== undefined && roleList !== null) {
        roleName = roleList.map((role) => role.roleName);
      }
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'download your application' button will download PDF application summary */}
        {applicationType === TextProps.MATCH_SNAP ? (
          <div>
            <p className="margin-top-none">
              {beforeYoustartMlanguageLabels(language).your}
              {beforeYoustartMlanguageLabels(language).renderAppNoLabel}
              {filterData(this.props.apply.sections, "before-you-start").webAppId}
            </p>
            {filterData(this.props.apply.sections, "before-you-start").webAppId ? (
              <p className="margin-top-none">
                {beforeYoustartMlanguageLabels(language).youMayLabelOne}
                {beforeYoustartMlanguageLabels(language).youMayLabeltwo}
                <button
                  className="dta-button dta-button--link"
                  onClick={() =>
                    this.displayPDFDataForSNAP(this.props.apply.application)
                  }
                >
                  {" "}
                  {beforeYoustartMlanguageLabels(language).youMayWantToDownSnap}
                </button>{" "}
                {beforeYoustartMlanguageLabels(language).youMayLabelThree}
              </p>
            ) : (
              <span> </span>
            )}
            <p className="margin-top-none">
              {
                beforeYoustartMlanguageLabels(language)
                  .ifAnyoneHouseholdEarnedInc
              }
            </p>
            <ul>
              {beforeYoustartMlanguageLabels(language).proofsForEarnedInc.map(
                (label) => (
                  <li key={label}>{label}</li>
                )
              )}
            </ul>
            <p className="margin-top-none">
              {
                beforeYoustartMlanguageLabels(language)
                  .ifAnyoneHouseholdUnEarnedInc
              }
            </p>
            <ul>
              {beforeYoustartMlanguageLabels(language).proofsForUnEarnedInc.map(
                (label) => (
                  <li key={label}>{label}</li>
                )
              )}
            </ul>
            <p className="margin-top-none">
              <strong>{beforeYoustartMlanguageLabels(language).note}</strong>
              {beforeYoustartMlanguageLabels(language).noteDetails}
            </p>
            <p className="margin-top-none">
              {!this.appInanonymousMode ? beforeYoustartMlanguageLabels(language).snapYouMayEligible : beforeYoustartMlanguageLabels(language).snapYouMayEligibleAnon}
            </p>
            <br />
            <button
              className="dta-button dta-button--outline-primary"
              style={{ width: "100px", marginRight: "1rem" }}
              onClick={() => this.redirectToHome()}
            >
              {beforeYoustartMlanguageLabels(language).exitLabel}
            </button>
            {!this.appInanonymousMode ? <button
              className="dta-button dta-button--primary"
              style={{ width: "150px" }}
              onClick={() => this.redirectToTanf()}
            >
              {beforeYoustartMlanguageLabels(language).continueLabel}
            </button> : ''}
          </div>
        ) : (
          [
            applicationType === TextProps.MATCH_TANF && (
              <div>
                <p className="margin-top-none">
                  {beforeYoustartMlanguageLabels(language).your}
                  {beforeYoustartMlanguageLabels(language).renderAppNoLabel}
                  {filterData(this.props.apply.sections, "before-you-start").webAppId}
                </p>
                <p className="margin-top-none">
                  {beforeYoustartMlanguageLabels(language).youMayLabelOne}
                  <button
                    className="dta-button dta-button--link"
                    onClick={() =>
                      this.displayPDFDataForTANF(this.props.apply.application)
                    }
                  >
                    {" "}
                    {
                      beforeYoustartMlanguageLabels(language)
                        .tanfYouMayLabelPrint
                    }{" "}
                  </button>
                  {beforeYoustartMlanguageLabels(language).tanfYouMayLabelOr}
                  <button
                    className="dta-button dta-button--link"
                    onClick={() =>
                      this.displayPDFDataForTANF(this.props.apply.application)
                    }
                  >
                    {" "}
                    {
                      beforeYoustartMlanguageLabels(language)
                        .tanfYouMayLabelSave
                    }{" "}
                  </button>
                  {
                    beforeYoustartMlanguageLabels(language)
                      .tanfYouMayLabelForFuture
                  }
                </p>
                <p className="margin-top-none">
                  {beforeYoustartMlanguageLabels(language).tanfDHRReview}
                </p>
                <p className="apply__heading margin-top-double margin-bottom-half">
                  {beforeYoustartMlanguageLabels(language).whatisNextLabel}
                </p>
                <p>
                  {
                    beforeYoustartMlanguageLabels(language)
                      .tanfYouCanSubmitLabel1
                  }
                  <button
                    className="dta-button dta-button--link"
                    onClick={() => this.redirectTodocumentsPage()}
                  >
                    {" "}
                    {
                      beforeYoustartMlanguageLabels(language)
                        .tanfYouCanSubmitLabel2
                    }{" "}
                  </button>
                  {
                    beforeYoustartMlanguageLabels(language)
                      .tanfYouCanSubmitLabel3
                  }
                  <Link
                    to={CommonDocuments}
                    target="_blank"
                    className="dta-button dta-button--link"
                  >
                    {" "}
                    {
                      beforeYoustartMlanguageLabels(language)
                        .tanfYouCanSubmitLabel4
                    }{" "}
                  </Link>
                  {
                    beforeYoustartMlanguageLabels(language)
                      .tanfYouCanSubmitLabel5
                  }
                </p>
                <p>
                  {
                    beforeYoustartMlanguageLabels(language)
                      .tanfWithYourOnlineAccount
                  }
                </p>
                <ul>
                  {beforeYoustartMlanguageLabels(
                    language
                  ).tanfWithYourOnlineAccountOptions.map((label) => (
                    <li key={label}>{label}</li>
                  ))}
                </ul>
                <p>
                  {beforeYoustartMlanguageLabels(language).tanfSubmitDocuments}
                  <button className="dta-button dta-button--link">
                    {
                      beforeYoustartMlanguageLabels(language)
                        .tanfSubmitFindOutMore
                    }{" "}
                  </button>
                </p>
                <p>
                  {beforeYoustartMlanguageLabels(language).tanfYoueMayEligible}
                </p>
                <br />
                <button
                  className="dta-button dta-button--outline-primary"
                  style={{ width: "100px", marginRight: "1rem" }}
                  onClick={() => this.redirectToHome()}
                >
                  {beforeYoustartMlanguageLabels(language).exitLabel}
                </button>
                <button
                  className="dta-button dta-button--primary"
                  style={{ width: "150px" }}
                  onClick={() => this.redirectToSnap()}
                >
                  {beforeYoustartMlanguageLabels(language).continueLabel}
                </button>
              </div>
            ),
            applicationType === TextProps.MATCH_COMBO && (
              <div>
                <p className="margin-top-none">
                  {beforeYoustartMlanguageLabels(language).renderSnapAppNoLabel()}
                  {filterData(this.props.apply.sections, "before-you-start").snapSubmittedDetails.appId}
                </p>
                <p className="margin-top-none">
                  {beforeYoustartMlanguageLabels(language).rendertanfAppNoLabel()}
                  {filterData(this.props.apply.sections, "before-you-start").tnafSubmittedDetails.appId}
                </p>
                <br/>
                {filterData(this.props.apply.sections, "before-you-start").tnafSubmittedDetails.appId ? ( 
                  <p className="margin-top-none">
                    {beforeYoustartMlanguageLabels(language).youMayLabelOne}
                    {beforeYoustartMlanguageLabels(language).youMayLabeltwo}
                    <button
                      className="dta-button dta-button--link"
                      style={{textDecorationLine: "underline"}}
                      onClick={() =>
                        this.displayPDFDataForComboSNAP(
                          filterData(this.props.apply.sections, "before-you-start").snapSubmittedDetails
                        )
                      }
                    >
                      {" "}
                      {
                        beforeYoustartMlanguageLabels(language)
                          .youMaySnapLabeltwo
                      }
                    </button>
                    {beforeYoustartMlanguageLabels(language).youMaySnapLabelAnd}
                    <button
                      className="dta-button dta-button--link"
                      style={{textDecorationLine: "underline"}}
                      onClick={() =>
                        this.displayPDFDataForComboTANF(
                          filterData(this.props.apply.sections, "before-you-start").tnafSubmittedDetails
                        )
                      }
                    >
                      {" "}
                      {
                        beforeYoustartMlanguageLabels(language)
                          .youMayTnafLabeltwo
                      }
                    </button>{" "}
                    {beforeYoustartMlanguageLabels(language).youMayLabelThree}
                  </p>
                ) : (
                  <span> </span>
                )}

                <p>{beforeYoustartMlanguageLabels(language).comboDocText} <button
                      className="dta-button dta-button--link"
                      style={{textDecorationLine: "underline"}}
                      onClick={() =>
                        this.displayPDFDataForComboSNAP(
                          filterData(this.props.apply.sections, "before-you-start").snapSubmittedDetails
                        )
                      }
                    >
                     {beforeYoustartMlanguageLabels(language).hereLabel}
                    </button>.</p>
                    <br />
                <p className="margin-top-none">
                  <strong>
                  {beforeYoustartMlanguageLabels(language).whatNextLabel}
                  </strong>{" "}
                  <p>{beforeYoustartMlanguageLabels(language).notifyInterviewText}</p>
                   <p>{beforeYoustartMlanguageLabels(language).reviewImpInfoSnafText}</p>
                </p>
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandSnapInfoToggle: !this.state.expandSnapInfoToggle })
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).snapInfoLabel}
                    <Icon
                      ariaHidden
                      name={this.state.expandSnapInfoToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandSnapInfoToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderSnapInfoApp()}
                    </div>
                  </Collapse>
                </p>
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandSnapImpInfoToggle: !this.state.expandSnapImpInfoToggle })
                    }
                  >
                     {beforeYoustartMlanguageLabels(language).snapImpInfoLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandSnapImpInfoToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandSnapImpInfoToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderSnapImpInfo()}
                    </div>
                  </Collapse>
                </p>
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandSnapSummarizedToggle: !this.state.expandSnapSummarizedToggle })
                    }
                  >
                     {beforeYoustartMlanguageLabels(language).snapSummarizedLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandSnapSummarizedToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandSnapSummarizedToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderSnapSummarizedInfo()}
                    </div>
                  </Collapse>
                </p>
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandNvraToggle: !this.state.expandNvraToggle })
                    }
                  >
                   {beforeYoustartMlanguageLabels(language).snapNvraLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandNvraToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandNvraToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderSnapNVRAInfo()}
                    </div>
                  </Collapse>
                </p>
               
                  <p>{beforeYoustartMlanguageLabels(language).reviewImpInfoTanfText}</p>
                  {/* <p>
                  <button
                    className="dta-button dta-button--link"
                    onClick={() =>
                      this.setState({ expandTanfInfoToggle: !this.state.expandTanfInfoToggle })
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).tanfInfoLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandTanfInfoToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                </p> */}
                {/* <p>
                  <button
                    className="dta-button dta-button--link"
                    onClick={() =>
                      this.setState({ expandTanfImpInfoToggle: !this.state.expandTanfImpInfoToggle })
                    }
                  >
                   {beforeYoustartMlanguageLabels(language).tanfImpInfoLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandTanfImpInfoToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                </p> */}
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandTanfSummarizedToggle: !this.state.expandTanfSummarizedToggle })
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).tanfSummarizedLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandTanfSummarizedToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandTanfSummarizedToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderTanfSummarizedInfo()}
                    </div>
                  </Collapse>
                </p>
                <p>
                  <button
                    className="dta-button dta-button--link dta-button--full"
                    onClick={() =>
                      this.setState({ expandVoterToggle: !this.state.expandVoterToggle })
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).tanfVoterLabel}
                    {"                                "}
                    <Icon
                      ariaHidden
                      name={this.state.expandVoterToggle ? "minus" : "plus"}
                      className='plus-icon-position'
                    />

                  </button>
                  <Collapse isOpened={this.state.expandVoterToggle}>
                    <div className={"dta-submitted-terms"}>
                      {beforeYoustartMlanguageLabels(language).renderTanfVoterInfo()}
                    </div>
                  </Collapse>
                </p>
               
                <br />
               
                <button
                  className="dta-button dta-button--primary"
                  style={{ width: "100px" }}
                  onClick={() => this.redirectToHome()}
                >
                  {beforeYoustartMlanguageLabels(language).exitLabel}
                </button>
              </div>
            ),
          ]
        )}
        {roleName.length > 0 && (
          <RequestAccountAccessModal
            requestAccessForUser={this.state.requestAccessForUser}
            isOpen={this.state.RAAModalOpen}
            onClose={this.closeRAAModal}
            filesToUpload={this.state.filesToUpload}
            onSelectFile={this.handleFileInputChange}
            onRemoveFile={this.handleRemoveFile}
            onSubmit={this.submitRAA}
            modalClass="raa-modal"
            error={this.state.RAAError}
            errorMessage={this.state.RAAErrorMessage}
            selection={this.state.questionData}
          />
        )}
      </div>
    );
  };

  // download pdf files link
  downloadApplicationAsPdf() {
    window.open(
      "http://dta-connect-service.azurewebsites.net/snapapplication.pdf",
      "_blank"
    );
  }
  // Render a isHomeless Modal
  renderIsHomelessModal = () => {
    return (
      <Modal
        isOpen={this.state.isHomelessModalOpen}
        onClose={() =>
          this.closeRenderIsHomelessModal()
        }
        modalClass="homeless-modal"
        titleText="homeless Warning"
        headerText={
          beforeYoustartMlanguageLabels(language).isHomelessHouseholdheader
        }
      >
      <div className="dta-modal__body pad-all">
        <div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContent[0].line1}</div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContent[1].line2} <a target="_blank"  href={ALABAMA_COUNTY_OFFICES}>here</a></div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContent[2].line3}</div>
        </div>
        <p> </p>
        <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeRenderIsHomelessModal()
              }
            >
            {beforeYoustartMlanguageLabels(language).okLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  closeRenderIsHomelessModal = () => {
    this.setState({isHomelessModalOpen : TextProps.VALUE_FALSE});
  }
  // Render a DHR modal for the save progress dialog
  renderSaveProgressModal = () => {
    return (
      <Modal
        isOpen={this.state.saveProgressModalOpen}
        onClose={this.closeSaveProgressModal}
        modalClass="save-progress-modal"
        titleText="Save progress"
        headerText="SAVE PROGRESS"
      >
        <div className="dta-modal__body pad-all">
          {this.state.isLoggedin ? (
            <span>
              <button
                className="dta-button dta-button--primary dta-button--block"
                onClick={this.saveInProgessToDatabase.bind(this)}
              >
                Save
              </button>
              {(() => {
                if (
                  this.props.apply.inProgressSaveErrorState ===
                  TextProps.VALUE_TRUE
                ) {
                  return (
                    <div className="pure-g">
                      <div className="pure-u-1 dta-form__error-message">
                        {this.props.apply.inProgressSaveErrorMessage}
                      </div>
                    </div>
                  );
                } else if (
                  this.state.applicationSubmited === TextProps.VALUE_TRUE
                ) {
                  return (
                    <div className="pure-g">
                      <div className="pure-u-1">Application Saved.</div>
                    </div>
                  );
                }
              })()}
            </span>
          ) : (
            <span>
              <p>
                <b>{beforeYoustartMlanguageLabels(language).renderSaveLabel}</b>
              </p>
              <p className="text--small">
                {beforeYoustartMlanguageLabels(language).laterOnLabel}
              </p>
              <button
                className="dta-button dta-button--primary dta-button--block"
                onClick={() =>
                  this.setState({ loginSectionContents: TextProps.VALUE_TRUE })
                }
              >
                {beforeYoustartMlanguageLabels(language).createOnlineLabel}
              </button>
            </span>
          )}
          <p className="text--center">
            <Link to="/">
              {beforeYoustartMlanguageLabels(language).orExitLabel}
            </Link>
          </p>
        </div>
      </Modal>
    );
  };
  //Function to confirm the deletion of household memebers
  confirmDeleteOfHousehold = (event) => {
    this.setState({ confirmedHouseholdMemForDeletion: event });
  };

  // Render a DHR modal for the save progress dialog
  renderDataDeleteWarningModal = () => {
    let memberOptions = [];
    filterData(this.props.apply.sections, "my-household").subsections &&
      filterData(this.props.apply.sections, "my-household").subsections.forEach((household) => {
        if (household.id !== "household-details")
          memberOptions.push(household.title);
      });
    return (
      <Modal
        isOpen={this.state.warningProgressModalOpen}
        onClose={this.closeWarningProgressModal}
        modalClass="delete-household-modal"
        titleText="Save progress"
        headerText={beforeYoustartMlanguageLabels(language).headerText}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).changingLabel}
          </p>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={memberOptions}
            ref="isAcceptTerms"
            onChange={this.confirmDeleteOfHousehold}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <p> </p>
          <button
            className="dta-button dta-button--primary dta-button--block"
            disabled={
              this.state.isDeleteValidCount !==
              this.state.confirmedHouseholdMemForDeletion.length
            }
            onClick={() => this.closeWarningProgressModal()}
          >
            {beforeYoustartMlanguageLabels(language).householdDeleteLabel}
          </button>
        </div>
      </Modal>
    );
  };
  	 //Function to handle aces button close popup actions(close/cancel)
     closeAcesButtonClickWarningModal = (status, isClose, event) => {
      if (status) {
        sessionStorage.setItem("acesButtonClickedFlag", true);
        this.onPrimaryActionClick(event, "Redirecting"); 
      }
      this.setState({
        acesButtonClickWarningShowModal: TextProps.VALUE_FALSE,
        acesButtonClickWarningRedirectPage: TextProps.VALUE_TRUE,
      });
      if (isClose) {
        sessionStorage.setItem("acesButtonClickedFlag", false);
        this.setState({
          sectionDataUpdated: TextProps.VALUE_FALSE,
        });
      }
      this.props.acesButtonClicked({value:false, path:""})
    };
    //Render A DHR modal for ACES button click updated info
    renderACESButtonClickWarningModal = () => {
      return (
        <Modal
          isOpen={this.state.acesButtonClickWarningShowModal}
          onClose={() =>
            this.closeAcesButtonClickWarningModal(TextProps.VALUE_FALSE, true)
          }
          modalClass="save-exit-modal"
          titleText="save or exit Warning"
          headerText={
            beforeYoustartMlanguageLabels(language).areYouSureWarningLabel
          }
        >
          <div className="dta-modal__body pad-all">
            <p className="text--small">
              {!this.appInanonymousMode ? beforeYoustartMlanguageLabels(language).saveOrExitLabel : beforeYoustartMlanguageLabels(language).saveOrExitLabelAnon}
            </p>
            <p> </p>
            <div className="dta-modal__footer--inline-buttons pure-u-1">
              <button
                className="dta-button dta-button--outline-primary"
                onClick={(event) =>
                  this.closeAcesButtonClickWarningModal(
                    TextProps.VALUE_FALSE,
                    false,
                    event
                  )
                }
              >
                {beforeYoustartMlanguageLabels(language).cancelLabel}
              </button>
              <button
                className="dta-button dta-button--primary"
                onClick={(event) =>{
                  if(this.appInanonymousMode){
                    window.location.assign("/");
                  }else{
                    this.closeAcesButtonClickWarningModal(
                      TextProps.VALUE_TRUE,
                      true,
                      event
                    )
                  }}
                }
              >
                {!this.appInanonymousMode ? beforeYoustartMlanguageLabels(language).saveAndExit : beforeYoustartMlanguageLabels(language).okLabel}
              </button>
            </div>
          </div>
        </Modal>
      );
  };
  renderLanguageSwitchWarningModal = () => {
    return (
      <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
      <Modal
        isOpen={this.state.languageChangeWarning}
        onClose={() =>{this.props.languageChanged({ isCancel: true}),
         this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={interimWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
      >
        <div className="dta-modal__body pad-all">
          {/* <p className="text--small">
          {interimWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
          </p> */}
          <p className="text--small"> 
          {interimWarningPopupMLanguageLabels(language).languageWarningLabel}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>{
                this.props.languageChanged({ isCancel: true})
                this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}
              }
            >
              {interimWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => {
                this.closeDeleteWarningModal(TextProps.VALUE_TRUE),
                this.props.languageChanged({ isDelete: true})}}
            >
              {interimWarningPopupMLanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      </div>
    );
  };

  //Function to handle Save or Exit popup actions(close/cancel)
  closeSaveOrExitWarningModal = (status, isClose, event) => {
    if (status) {
      this.onPrimaryActionClick(event, TextProps.BACK);
      this.handleAuthRepDataChanged(TextProps.VALUE_FALSE);
    }
    this.setState({
      saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      saveORExitWarningRedirectPage: TextProps.VALUE_TRUE,
    });
    if (!isClose) {
      if(this.state.activeSectionId === "auth-rep") {
        this.state.authRepCancel = TextProps.VALUE_TRUE;
      }
      this.handleAuthRepDataChanged(TextProps.VALUE_FALSE);
      this.autoClearSections();
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
      });
      this.goBackHandler();
    }
  };
  //Function to handle Save&Exit popup actions(save)
  onSaveOrExitClick = (event) => {
    this.setState({ saveOrExitWarningShowModal: TextProps.VALUE_TRUE });
    // alert("onDeleteClick");
  };

  //Render A DHR modal for Save or Exit updated info
  renderSaveOrExitWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.saveOrExitWarningShowModal}
        onClose={() =>
          this.closeSaveOrExitWarningModal(TextProps.VALUE_FALSE, true)
        }
        modalClass="save-exit-modal"
        titleText="save or exit Warning"
        headerText={
          beforeYoustartMlanguageLabels(language).areYouSureWarningLabel
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).saveOrExitLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeSaveOrExitWarningModal(
                  TextProps.VALUE_FALSE,
                  false,
                  event
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeSaveOrExitWarningModal(
                  TextProps.VALUE_TRUE,
                  true,
                  event
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).saveLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  //Function to handle delete popup actions(close/cancel)
  closeDeleteWarningModal = (status) => {
    if (status) {
      this.props
        .deleteWebAppData({
          id: this.props.apply.prevSections.id,
          createTs: this.props.apply.prevSections.date,
        })
        .then((data) => {
            window.location.assign("/consumer/myInfo");
        });
    }
    this.setState({
      deleteWarningShowModal: TextProps.VALUE_FALSE,
      deleteWarningRedirectToHomePage: TextProps.VALUE_TRUE,
    });
  };
  //Function to handle delete popup actions(confirm)
  onDeleteClick = (event) => {
    event.preventDefault();
    this.setState({ deleteWarningShowModal: TextProps.VALUE_TRUE });
    // alert("onDeleteClick");
  };
  renderIcon = (completed) => {
    return completed === TextProps.VALUE_Y ? (
      <div className="verified-box">
        <div><Icon
        name="check-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Verified</div>
      </div>
    ) : (
      <div className="verified-box"><div><Icon
        name="cross-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Not Verified</div>
      </div>
    );
  };
  ///******************************************// *//
  //Render DHR modal for address validation
  renderAddressValidationModal = () => {
    return (
      <Modal
        isOpen={this.state.addressValidationModalOpen}
        onClose={this.cancelAddressValidationAction}
        modalClass="address-validation-modal"
        titleText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
        headerText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
      >
        <div className="dta-modal__body pad-all">
          {
            <div className="pure-g margin-bottom-half is-mail-address">
              {this.state.resAddressFlag && (
                <div className="pure-u-1">
                  <label className="dta-form__label">
                    {
                      beforeYoustartMlanguageLabels(language)
                        .addressValidLabelText
                    }
                  </label>
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label ">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredHomeAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedResAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-1"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredResAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue:
                            this.state.addressObj.enteredResAddress[0],
                          selectedResAdressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedHomeAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-2"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedResAdressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue: "",
                          selectedResAdressValue:
                            this.state.addressObj.suggestedResAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.mailingAddressFlag && (
                <div className="pure-u-1">
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredMailingAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedMailingAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-3"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue:
                            this.state.addressObj.enteredMailingAddress[0],
                          selectedMailingAddressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedMailingAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-4"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue: "",
                          selectedMailingAddressValue:
                            this.state.addressObj.suggestedMailingAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="dta-modal__footer dta-modal__footer--inline-buttons address-row-footer pure-u-1">
                <button
                  className="dta-button dta-button--large dta-button--primary address-row-footer-buttons address-row-footer-buttons-confirm"
                  onClick={this.confirmAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).confirmButtonLabel}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--outline-primary address-row-footer-buttons address-row-footer-buttons-cancel"
                  onClick={this.cancelAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };
  //Function to validate address by calling Mellisa API
  validateAddressesCallingMellisa(answerAddress, address) {
    let status = TextProps.VALUE_TRUE;
    this.props
      .addressValidation(answerAddress, language)
      .then((data) => {
        if (data.data.length) {
          status = TextProps.VALUE_TRUE;
          let suggestedResAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let suggestedMailingAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          let enteredResAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let enteredMailingAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          // const address = filterData(this.props.apply.sections, "about-me").subsections[0];
          let resAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.apply.residentailAddressStored &&
            enteredResAddress.length
          ) {
            resAddrUpdated = checkIfAddressIsUpdated(
              address.questions.filter((addr) => addr.name === "address")[0]
                .Answer,
              this.props.apply.residentailAddressStored
            );
          } else if (enteredResAddress.length) {
            resAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            resAddrUpdated = TextProps.VALUE_FALSE;
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.apply.mailingAddressStored &&
            enteredMailingAddress.length
          ) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                mailingaddr.Answer,
                this.props.apply.mailingAddressStored
              );
            }
          } else if (enteredMailingAddress.length) {
            mailingAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            mailingAddrUpdated = TextProps.VALUE_FALSE;
          }
          this.setState(
            {
              addressObj: {
                originalEnteredResAddress: enteredResAddress,
                originalSuggestedResAddress: suggestedResAddress,
                originalEnteredMailingAddress: enteredMailingAddress,
                originalSuggestedMailingAddress: suggestedMailingAddress,
                enteredResAddress: formatAddressDisplay(enteredResAddress),
                suggestedResAddress: formatAddressDisplay(suggestedResAddress),
                enteredMailingAddress: formatAddressDisplay(
                  enteredMailingAddress
                ),
                suggestedMailingAddress: formatAddressDisplay(
                  suggestedMailingAddress
                ),
              },
              enteredResAddressValue: enteredResAddress.length
                ? enteredResAddress[0].formattedAddress
                : "",
              enteredMailingAddressValue: enteredMailingAddress.length
                ? enteredMailingAddress[0].formattedAddress
                : "",
              selectedResAdressValue: "",
              selectedMailingAddressValue: "",
              finalResValueSelected: enteredResAddress.length
                ? enteredResAddress[0]
                : "",
              finalMailingValueSelected: enteredMailingAddress.length
                ? enteredMailingAddress[0]
                : "",
              mailingAddressFlag: mailingAddrUpdated,
              resAddressFlag: resAddrUpdated,
              MelissaApiResponded: true,
            },
            () => {
              this.setState({
                addressValidationModalOpen: TextProps.VALUE_TRUE,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          MelissaApiResponded: TextProps.VALUE_FALSE,
        });
        status = TextProps.VALUE_FALSE;
      });
    return status;
  }
  //Function to handle address validation popup actions(close/cancel)
  cancelAddressValidationAction = () => {
    this.confirmAddressValidationAction();
    this.setState({ addressValidationModalOpen: false });
  };
  //Function to handle confirmAddressValidationAction popup actions(confirm)
  confirmAddressValidationAction = () => {
    let selectedResAddress = this.state.finalResValueSelected
      ? this.state.finalResValueSelected
      : this.state.addressObj.originalEnteredResAddress[0];
    let selectedMailingAddress = this.state.finalMailingValueSelected
      ? this.state.finalMailingValueSelected
      : this.state.addressObj.originalEnteredMailingAddress[0];
    filterData(this.props.apply.sections, "about-me").subsections[0].questions =
      appendUpdatedAdrress(
        filterData(this.props.apply.sections, "about-me").subsections[0].questions,
        selectedResAddress,
        selectedMailingAddress
      );
    filterData(this.props.apply.sections, "about-me").subsections[0].melisaAddressValidated =
      TextProps.VALUE_TRUE;
    const renderedSectionsCopy = [];
    const incomingSection = this._getSection(this.state.activeSectionId);
    renderedSectionsCopy.push(incomingSection);
    this.setState({
      addressValidationModalOpen: TextProps.VALUE_FALSE,
    });
    let AnswerObjectCopy = this.state.AnswerObject;
    const address = filterData(this.props.apply.sections, "about-me").subsections[0];
    if (AnswerObjectCopy && AnswerObjectCopy["contact-info"] && AnswerObjectCopy["contact-info"].address) {
      AnswerObjectCopy["contact-info"].address = address.questions.filter(
        (addr) => addr.name === "address"
      )[0].Answer;
    }
    if (AnswerObjectCopy && AnswerObjectCopy["contact-info"].mailingAddress) {
      AnswerObjectCopy["contact-info"].mailingAddress =
        address.questions.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0].conditionals[0].conditionalQuestions[0].Answer;
    }
    this.setState({ AnswerObject: AnswerObjectCopy });
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    setTimeout(() => {
      this.setState({ renderedSections: renderedSectionsCopy });
      let storeAddrObj = this.updateStoreAddr();
      this.props.applyAddressStorageAPI(storeAddrObj);
      this.props.updateCurrentSectionData(this.props.apply);
      this.setState({ isLoading: TextProps.VALUE_FALSE });
      // this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
    }, 100);
  };
  //Function to handle address selection
  _handleAddressSelection = (option, value) => {
    let selectedResAddress = {};
    let selectedMailingAddress = {};
    if (option === "suggestedResAddress") {
      selectedResAddress = this.state.addressObj.originalSuggestedResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "enteredResAddress") {
      selectedResAddress = this.state.addressObj.originalEnteredResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "suggestedMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalSuggestedMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    } else if (option === "enteredMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalEnteredMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    }
  };

  //Render A DHR modal for delete web application
  renderDeleteWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.deleteWarningShowModal}
        onClose={() => this.closeDeleteWarningModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={beforeYoustartMlanguageLabels(language).confirmDelete}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).deleteExitLabel}
          </p>
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).deletePopUpLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>
                this.closeDeleteWarningModal(TextProps.VALUE_FALSE)
              }
            >
              {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => this.closeDeleteWarningModal(TextProps.VALUE_TRUE)}
            >
              {beforeYoustartMlanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  // Render a DHR modal for the save and exist dialog
  renderSaveExitWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.saveExitShowModal}
        onClose={() => this.closeSaveExitModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="Save progress"
        headerText={beforeYoustartMlanguageLabels(language).saveAndExitHeader}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).saveExitLabel0}{""}
            {beforeYoustartMlanguageLabels(language).saveExitLabel1}{" "}
            {todaysDateOrTime(TextProps.VALUE_TRUE, this.state.updateDateTime)}{" "}
            {beforeYoustartMlanguageLabels(language).saveExitLabel2}{" "}
            {todaysDateOrTime(TextProps.VALUE_FALSE, this.state.updateDateTime)}{" "}
            {beforeYoustartMlanguageLabels(language).saveExitLabel3}
            {this.state.pendingHours
            ? this.state.pendingHours.toString().split(":")[0] 
            : this.state.pendingHours}
            {beforeYoustartMlanguageLabels(language).saveExitLabel4}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_FALSE, event)
              }
            >
              {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_TRUE, event)
              }
            >
              {beforeYoustartMlanguageLabels(language).okLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  // redirect to Home page if no previous pages
  redirectToHomePage = () => {
    window.location.assign("/consumer/myInfo");
  };

  redirectTodocumentsPage = () => {
    window.location.assign("/consumer/documents");
  };
  //Function to handle closeSaveExitModal popup actions(close/cancel)
  closeSaveExitModal = (status, event) => {
    sessionStorage.setItem("saveExitShowModalEnabled", false);
    this.setState({
      saveExitShowModal: TextProps.VALUE_FALSE,
    });
    if (status) {
      this.redirectToHomePage();
    }
  };
  // save & exit action iteam
  onSaveExitClick = (event) => {
    event.preventDefault();
    sessionStorage.setItem("saveExitShowModalEnabled", true);
    if (this.state.sectionDataUpdated) {
      this.onPrimaryActionClick(event, TextProps.SAVEEXIT);
    } else {
      this.props
        .getSavedDataForUser(TextProps.VALUE_APP_EXIT)
        .then((data) => {
          this.setState({
            saveExitShowModal: TextProps.VALUE_TRUE,
            createdDateTime: data[0].date,
            updateDateTime: data[0].updatedDt,
            serverTimeZone: data[0].timeZone,
            pendingHours: data[0].pendingHours,
            appId: data[0].id,
          });
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        })
        .catch((error) => {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        });
      this.exitFromTheApplication();
    }
  };
  exitFromTheApplication = (createdDateTime) => {
    if (sessionStorage.getItem("saveExitShowModalEnabled") === "true") {
      setTimeout(
        () =>
          this.setState({
            saveExitShowModal: TextProps.VALUE_TRUE,
            createdDateTime: createdDateTime,
          }),
        3000
      );
    }
  };
  //Reset Action Iteam
  onResetClick = (event) => {
    event.preventDefault();
    this.setState({
      resetShowModal: TextProps.VALUE_TRUE,
      resetSection: this._getSection(this.state.activeSectionId),
    });
  };
  // Render a DHR modal for the save and exist dialog
  renderResetWarningModal = () => {
    let parentsHeader = this.state.sections.filter(
      (section) => section.id === this.state.resetSection.parentsId
    )[0];
    return (
      <Modal
        isOpen={this.state.resetShowModal}
        onClose={() =>
          this.closeRestModal(
            TextProps.VALUE_FALSE,
            this.state.resetSection.parentsId
          )
        }
        modalClass="save-exit-modal"
        titleText="Save progress"
        headerText={beforeYoustartMlanguageLabels(language).resetHeader}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {beforeYoustartMlanguageLabels(language).resetBodyLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeRestModal(
                  TextProps.VALUE_FALSE,
                  this.state.resetSection.parentsId
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeRestModal(
                  TextProps.VALUE_TRUE,
                  this.state.resetSection.parentsId
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).resetOk}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  //Function to handle closeSaveExitModal popup actions(close/cancel)
  closeRestModal = (status, parentsId) => {
    this.setState({
      resetShowModal: TextProps.VALUE_FALSE,
    });
    if (status) {
      this.redirectToResettedPage(parentsId);
    }
  };
  redirectToResettedPage = (parentsId) => {
    this.setState({
      activeSectionId: parentsId,
      renderedSections: [],
    });
    let applicationSection = [];
    let applicationType = sessionStorage.getItem("applicationType");
    if (applicationType === TextProps.MATCH_SNAP) {
      applicationSection = _.cloneDeep(snapSections); //applicationType = "SNAP"
    } else if (applicationType === TextProps.MATCH_TANF) {
      applicationSection = _.cloneDeep(tanfSections); //applicationType = "TANF"
    } else {
      applicationSection = _.cloneDeep(comboSections); //applicationType = "COMBO"
    }
    if (parentsId === "my-household") {
      setTimeout(() => {
        // this.props.apply.sections = _.cloneDeep(this.props.apply.sections.slice(0,2)).concat(_.cloneDeep(applicationSection.slice(2)));
        filterData(this.props.apply.sections, "my-household").completed = TextProps.VALUE_TRUE;
        this.resetMyHouseholdSection(applicationSection);
        if (applicationType !== TextProps.MATCH_TANF) {
        filterData(this.props.apply.sections, "auth-rep").completed = TextProps.VALUE_FALSE;
        this.resetResourcesSection();
        filterData(this.props.apply.sections, "resources").completed = TextProps.VALUE_FALSE;
        this.resetResourcesSection(applicationSection);
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        }
        filterData(this.props.apply.sections, "income").completed = TextProps.VALUE_FALSE;
        this.resetIncomeSection(applicationSection);
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
        this.setState({
          oldHouseHoldSizeValue: 0,
        });
      }, 100);
    }
    else if (parentsId === "auth-rep") {
      setTimeout(() => {
        filterData(this.props.apply.sections, "auth-rep").completed = TextProps.VALUE_TRUE;
        this.resetAuthRepSection();
        filterData(this.props.apply.sections, "resources").completed = TextProps.VALUE_FALSE;
        this.resetResourcesSection(applicationSection);
        filterData(this.props.apply.sections, "income").completed = TextProps.VALUE_FALSE;
        this.resetIncomeSection(applicationSection);
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    }
    else if (parentsId === "resources") {
      setTimeout(() => {
        filterData(this.props.apply.sections, "resources").completed = TextProps.VALUE_TRUE;
        this.resetResourcesSection(applicationSection);
        filterData(this.props.apply.sections, "income").completed = TextProps.VALUE_FALSE;
        this.resetIncomeSection(applicationSection);
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    }else if (parentsId === "income") {
      setTimeout(() => {
        filterData(this.props.apply.sections, "income").completed = TextProps.VALUE_TRUE;
        this.resetIncomeSection(applicationSection);
        if (applicationType !== TextProps.MATCH_TANF) {
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        }
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    } else if (parentsId === "expenses") {
      setTimeout(() => {
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    } else if (parentsId === "ebt-card-request") {
      setTimeout(() => {
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    } else if (parentsId === "sign-and-submit") {
      setTimeout(() => {
        this.props.apply.sections.filter(
          (section) => section.id === "sign-and-submit"
        ).completed = TextProps.VALUE_FALSE;
        this.resetSignSection(applicationSection);
        this.updateResettedData();
      }, 100);
    } else {
      // this.props.applySanpQuestionsSections();
      setTimeout(() => {
        filterData(this.props.apply.sections, "before-you-start").completed = TextProps.VALUE_TRUE;
        filterData(this.props.apply.sections, "about-me").completed = TextProps.VALUE_TRUE;
        this.resetAboutMeSection(applicationSection);
        filterData(this.props.apply.sections, "my-household").completed = TextProps.VALUE_FALSE;
        this.resetMyHouseholdSection(applicationSection);
        if (applicationType !== TextProps.MATCH_TANF) {
        filterData(this.props.apply.sections, "resources").completed = TextProps.VALUE_FALSE;
        this.resetResourcesSection(applicationSection);
        filterData(this.props.apply.sections, "expenses").completed = TextProps.VALUE_FALSE;
        this.resetExpenseSection(applicationSection);
        }
        filterData(this.props.apply.sections, "income").completed = TextProps.VALUE_FALSE;
        this.resetIncomeSection(applicationSection);
        filterData(this.props.apply.sections, "ebt-card-request").completed = TextProps.VALUE_FALSE;
        this.resetEbtSection(applicationSection);
        if (filterData(this.props.apply.sections, "summary")) {
          filterData(this.props.apply.sections, "summary").completed = TextProps.VALUE_FALSE;
        }
        this.resetSignSection(applicationSection);
        this.updateResettedData();
        this.setState({
          oldHouseHoldSizeValue: 0,
        });
      }, 100);
    }
  };
  resetAboutMeSection = (applicationSection) => {
    let aboutMe = this.props.apply.sections.filter(
      (section) => section.id === "about-me"
    );
    if (aboutMe.length) {
      filterData(this.props.apply.sections, "about-me").questions = _.cloneDeep(
        filterData(applicationSection, "about-me").questions
      );
      filterData(this.props.apply.sections, "about-me").subsections = _.cloneDeep(
        filterData(applicationSection, "about-me").subsections
      );
    }
  };
  resetMyHouseholdSection = (applicationSection) => {
    let myHousehold = this.props.apply.sections.filter(
      (section) => section.id === "my-household"
    );
    if (myHousehold.length) {
      filterData(this.props.apply.sections, "my-household").questions = _.cloneDeep(
        filterData(applicationSection, "my-household").questions
      );
      delete filterData(this.props.apply.sections, "my-household").subsections;
    }
  };

  resetAuthRepSection = () => {
    this.clearFieldsByQuestion("ebtQuestion");
    this.clearFieldsByQuestion("snapQuestion");
    this.state.addAuthRepData.ebtAuthRepFlg = null;
    this.state.addAuthRepData.snapAuthRepFlg = null;
  }

  resetResourcesSection = (applicationSection) => {
    let resources = this.props.apply.sections.filter(
      (section) => section.id === "resources"
    );
    if (resources.length && resources[0].completed) {
      let resourcesTemplate = _.cloneDeep(filterData(applicationSection, "resources").questions);
      if (filterData(this.props.apply.sections, "resources").questions[0].options) {
        resourcesTemplate[0].options =
          filterData(this.props.apply.sections, "resources").questions[0].options;
      }
      if (filterData(this.props.apply.sections, "resources").questions[0].memberOptions) {
        resourcesTemplate[0].memberOptions =
          filterData(this.props.apply.sections, "resources").questions[0].memberOptions;
      }
      filterData(this.props.apply.sections, "resources").questions = resourcesTemplate;
      let resourcesSummary = [];
      if (
        filterData(applicationSection, "resources").subsections.filter(
          (sub) => sub.id === "resources-summary"
        )[0]
      ) {
        resourcesSummary.push(
          _.cloneDeep(
            filterData(applicationSection, "resources").subsections.filter(
              (sub) => sub.id === "resources-summary"
            )[0]
          )
        );
      }
      filterData(this.props.apply.sections, "resources").subsections = resourcesSummary;
    }
  };
  resetIncomeSection = (applicationSection) => {
    let income = this.props.apply.sections.filter(
      (section) => section.id === "income"
    );
    if (income.length && income[0].completed) {
      let incomeTemplate = _.cloneDeep(filterData(applicationSection, "income").questions);
      if (filterData(this.props.apply.sections, "income").questions[0].options) {
        incomeTemplate[0].options =
          filterData(this.props.apply.sections, "income").questions[0].options;
      }
      if (filterData(this.props.apply.sections, "income").questions[0].memberOptions) {
        incomeTemplate[0].memberOptions =
          filterData(this.props.apply.sections, "income").questions[0].memberOptions;
      }
      filterData(this.props.apply.sections, "income").questions = incomeTemplate;
      let incomeSummary = [];
      if (
        filterData(applicationSection, "income").subsections.filter(
          (sub) => sub.id === "income-summary"
        )[0]
      ) {
        incomeSummary.push(
          _.cloneDeep(
            filterData(applicationSection, "income").subsections.filter(
              (sub) => sub.id === "income-summary"
            )[0]
          )
        );
      }
      filterData(this.props.apply.sections, "income").subsections = incomeSummary;
    }
  };
  resetExpenseSection = (applicationSection) => {
    let expenses = this.props.apply.sections.filter(
      (section) => section.id === "expenses"
    );
    if (expenses.length) {
      filterData(this.props.apply.sections, "expenses").subsections = _.cloneDeep(
        filterData(applicationSection, "expenses").subsections
      );
    }
  };
  resetEbtSection = (applicationSection) => {
    let ebt = this.props.apply.sections.filter(
      (section) => section.id === "ebt-card-request"
    );
    if (ebt.length) {
      this.props.apply.sections.filter(
        (section) => section.id === ebt[0].id
      )[0].questions = _.cloneDeep(
        applicationSection.filter((section) => section.id === ebt[0].id)[0]
          .questions
      );
    }
  };
  resetSignSection = (applicationSection) => {
    let sign = this.props.apply.sections.filter(
      (section) => section.id === "sign-and-submit"
    );
    if (sign.length) {
      this.props.apply.sections.filter(
        (section) => section.id === sign[0].id
      )[0].subsections = _.cloneDeep(
        applicationSection.filter((section) => section.id === sign[0].id)[0]
          .subsections
      );
    }
  };
  updateResettedData = () => {
    const renderedSectionsCopy = [];
    const incomingSection = this._getSectionReset(this.state.activeSectionId);
    renderedSectionsCopy.push(incomingSection);
    this.setState({
      renderedSections: renderedSectionsCopy,
      AnswerObject: [],
    });
    this.props.updateCurrentSectionData(this.props.apply);
    if (this.state.activeSectionId !== "sign-and-submit") {
      this.triggerSaveSnap(this.state.activeSectionId);
    } else {
      this.setState({ signature: "" });
    }
  };
  onPrimaryActionClickSaveContinue = (event) => {
    event.preventDefault();
    sessionStorage.setItem("saveExitShowModalEnabled", false);
    this.onPrimaryActionClick(event, TextProps.SAVECONTINE);
  };
  //
  onSignSubmitActionClick = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event, TextProps.SIGNSUBMIT);
  };
  // Set all the question and conditional questions answers for accommodation on save and continue
  setAccomadtion = (answer) => {
    if (answer.accommodation.accommodationApplies.includes("7")) {
      let accommodationOther = {};
      accommodationOther = accommodationConditionalQuestion;
      if (
        filterData(this.props.apply.sections, "about-me").subsections[2].questions.filter(
          (data) => data.name === "is-accommondation"
        )[0].conditionals[0].conditionalQuestions &&
        filterData(this.props.apply.sections, "about-me").subsections[2].questions.filter(
          (data) => data.name === "is-accommondation"
        )[0].conditionals[0].conditionalQuestions.length < 2
      ) {
        filterData(this.props.apply.sections, "about-me").subsections[2].questions
          .filter((data) => data.name === "is-accommondation")[0]
          .conditionals[0].conditionalQuestions.push(
            accommodationConditionalQuestion
          );
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.apply);
        }, 3);
      }
    } else {
      if (
        filterData(this.props.apply.sections, "about-me").subsections[2].questions.filter(
          (data) => data.name === "is-accommondation"
        )[0].conditionals[0].conditionalQuestions.length >= 2
      ) {
        filterData(this.props.apply.sections, "about-me").subsections[2].questions
          .filter((data) => data.name === "is-accommondation")[0]
          .conditionals[0].conditionalQuestions.splice(-1);
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.apply);
        }, 3);
      }
    }
  };
  setShelterData = (answer) => {
    if (
      answer["housing-costs"].type.includes("RENT") ||
      answer["housing-costs"].type.includes("MTGPRN")
    ) {
      if (filterData(this.props.apply.sections, "expenses").subsections[0].questions.length === 3) {
        filterData(this.props.apply.sections, "expenses").subsections[0].questions.push(
          _.cloneDeep(ShelterSectionTemplate)
        );
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.apply);
        }, 3);
      }
    } else {
      if (filterData(this.props.apply.sections, "expenses").subsections[0].questions.length === 4) {
        filterData(this.props.apply.sections, "expenses").subsections[0].questions.pop();
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.apply);
        }, 3);
      }
    }
  };
  setResources = (answer) => {
    if (answer.resources.resources) {
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.apply);
        }, 3);
    }
  };
  setHomelessMailingAddress = (answer) => {
    if (answer["contact-info"] && answer["contact-info"]["is-homeless"]) {
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        // renderedSectionsCopy.push(incomingSection);
        this.setState({ renderedSections: renderedSectionsCopy },
        () => {
          renderedSectionsCopy.push(incomingSection);
          if(answer["contact-info"]["is-homeless"] === TextProps.VALUE_YES && !this.state.setHomelessModalOpen){
            this.setState({isHomelessModalOpen: TextProps.VALUE_TRUE,setHomelessModalOpen: TextProps.VALUE_TRUE});
          }else if(answer["contact-info"]["is-homeless"] === TextProps.VALUE_NO){
            this.setState({isHomelessModalOpen: TextProps.VALUE_FALSE,setHomelessModalOpen: TextProps.VALUE_FALSE});
          }
          this.setState({ renderedSections: renderedSectionsCopy});
          this.props.updateCurrentSectionData(this.props.apply);
        });
        // setTimeout(() => {
        //   this.setState({ renderedSections: renderedSectionsCopy });
        //   this.props.updateCurrentSectionData(this.props.apply);
        // }, 3);
    }
  };
  setMedicalCostOtherOption = (index,action) => {
    this.props.appendMedicalCostOtherQuestion(medicalOtherCostQuestion,index,action);
  };
  _bindAnswerObject = (answer) => {
    console.log("answer object: " + answer);
    if (answer && answer.resources && answer.resources.resources && answer.resources.resources.length > 1 && answer.resources.resources.includes(resourcesSectionMultilanguageLabels(language).noResourcesToReportOption)) {
      let resourcesCopy = filterData(this.props.apply.sections, "resources");
      if(resourcesCopy.questions[0].Answer.includes(resourcesSectionMultilanguageLabels(language).noResourcesToReportOption)){
        answer.resources.resources = answer.resources.resources.filter(ans => ans !== resourcesSectionMultilanguageLabels(language).noResourcesToReportOption);
      }else{
        answer.resources.resources = answer.resources.resources.filter(ans => ans === resourcesSectionMultilanguageLabels(language).noResourcesToReportOption);
      }
    }
    // if (answer && answer["contact-info"] && answer["contact-info"]["is-homeless"]) {
    //   // if((answer["contact-info"]["is-also-mailing"] === undefined && answer["contact-info"]["is-homeless"] === TextProps.VALUE_YES) || (answer["contact-info"]["is-also-mailing"] && answer["contact-info"]["is-homeless"] === TextProps.VALUE_YES)){
    //     answer["contact-info"]["is-also-mailing"] = answer["contact-info"]["is-homeless"] === TextProps.VALUE_YES ? TextProps.VALUE_STR_FALSE : TextProps.VALUE_STR_TRUE;
    //   // }
    // }
    this.setState(
      {
        AnswerObject: answer,
        sectionDataUpdated: TextProps.VALUE_TRUE,
        addressValidationModalOpen: TextProps.VALUE_FALSE,
      },
      () => {
        if (
          answer !== undefined &&
          answer.accommodation !== undefined &&
          answer.accommodation.accommodationApplies !== undefined
        ) {
          this.setAccomadtion(answer);
        }
        if (
          answer !== undefined &&
          answer["housing-costs"] !== undefined &&
          answer["housing-costs"].type !== undefined
        ) {
          this.setShelterData(answer);
        }
        if(answer !== undefined){
          Object.keys(answer).forEach((key)=>{
            if(key.includes('medical-expenses-type') && this.state.activeSectionId.includes('medical-expenses-type')){
              if(answer[key].medicalExpenseType.includes('OTHER')){
                this.setMedicalCostOtherOption(key.slice(-1),'add')
              }else{
                this.setMedicalCostOtherOption(key.slice(-1),'remove')
              }
            }
          })
        }
        if (answer && answer.resources && answer.resources.resources) {
          this.setResources(answer);
        }
        if (answer && answer["contact-info"] && answer["contact-info"]["is-homeless"] && (answer["contact-info"]["is-homeless"] !== this.state.homelessCheck || answer["contact-info"]["is-also-mailing"] !== this.state.isMailCheck)) {
            if(answer["contact-info"]["is-homeless"] === TextProps.VALUE_YES){
              answer["contact-info"]["is-also-mailing"] = TextProps.VALUE_STR_FALSE;
            }
            if(answer["contact-info"]["is-homeless"] !== this.state.homelessCheck && answer["contact-info"]["is-homeless"] === TextProps.VALUE_NO){
              answer["contact-info"]["is-also-mailing"] = TextProps.VALUE_STR_TRUE;
            }
            this.setState({homelessCheck : _.cloneDeep(answer["contact-info"]["is-homeless"]),isMailCheck : _.cloneDeep(answer["contact-info"]["is-also-mailing"])});
            this.setHomelessMailingAddress(answer);
        }
      }
    );
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.

  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div
          className={`slide-animation ${this.state.directionClass} ${
            section.id === this.state.activeSectionId
              ? this.state.directionClass
              : "exit-done"
          }`}
        >
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <ApplySection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            // onPrimaryActionClick={this.onPrimaryActionClick}
            onPrimaryActionClick={this.onPrimaryActionClickSaveContinue}
            onSaveExitClick={ !this.appInanonymousMode ? this.onSaveExitClick : null}
            onResetClick={!this.appInanonymousMode ? this.onResetClick : null}
            onSignSubmitActionClick={this.onSignSubmitActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.beforeGoToSignupPage}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
            signAndSubmit={filterData(this.props.apply.sections, "before-you-start").signAndSubmit}
            appInanonymousMode={this.appInanonymousMode}
          >
            
            {!section.questions ? this.getSectionBody(section.id) : null}
            {(section.id === "household-information" || section.id === "household-details") && this.getSectionBody("household-information")}
          </ApplySection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    this._updateCurrentSection(previousSectionId);
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    if(this.state.activeSectionId === "summary") {
      const incomingSection = this._getSection("sign-and-submit");
      renderedSectionsCopy.push(incomingSection);
      }
      else {
        const incomingSection = this._getSection("summary");
        renderedSectionsCopy.push(incomingSection);
      }

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          lastKnownSection: previousSectionId,
          activeSectionId: this.state.activeSectionId === "summary" ? "sign-and-submit" : "summary",
        });
      }
    );
    this.props.updateLastKnownLocation(previousSectionId);
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
        return this.renderBeforeYouStart();
        case "household-information":
          return this._renderHouseholdInfo();
      case "income-summary":
        if (
          !this.incomeSummary.incomeMembers ||
          !this.incomeSummary.incomeMembers.length
        ) {
          this.prepareIncomeSummary();
        }
        return this.renderIncomeSummary();
      case "resources-summary":
        if (
          !this.resourcesSummary.resourcesMembers ||
          !this.resourcesSummary.resourcesMembers.length
        ) {
          this.prepareResourcesSummary();
        }
        return this.renderResourcesSummary();
      case "expenses":
        return this.renderExpensesIndex();
      case "summary":
        return this.renderSummary();
      case "auth-rep":
        return this.renderAuthRep(); 
      case "sign-and-submit":
        return this.renderSignAndSubmit();
      case "submitted":
        this.disableApplySections();
        return this.renderSubmitted();
      default:
        return <div>Something went wrong</div>;
    }
  };

  disableApplySections = () => {
    let sections = this.props.apply.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.apply.sections = sections;
    }
  };

  componentWillMount() {
    var generate_key = function () {
      return crypto.randomBytes(16).toString("base64");
    };

    // Create new session ID
    sessionStorage.setItem("sessionID", JSON.stringify(generate_key()));
  }
  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.apply;
    if (this.state.isLoading) {
      return <Loading />;
    }
    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      const newSections = [...sections];
      if (sessionStorage.getItem("applicationType") === TextProps.MATCH_SNAP) {
        const isProvider = sessionStorage.getItem("isProvider");
        if (isProvider) {
          newSections[1].subsections[0].questions[0].label =
            isProvider === "true"
              ? aboutMeMultilanguageLabels(language).contactInformation1 +
                ' <a href="tel:8773822363">877-382-2363 </a>' +
                aboutMeMultilanguageLabels(language).contactInformation2 +
                ' <a href="https://dhr.alabama.gov/county-office-contact/" target="_blank" rel="noopener noreferrer">' +
                aboutMeMultilanguageLabels(language).contacInformationLink +
                "</a>" +
                aboutMeMultilanguageLabels(language).contactInfo3
              : aboutMeMultilanguageLabels(language).contactInfo1;
        }
      }
      return this.renderApplyQuestions(newSections);
    } else {
      if (sections.length > 0 && sections[0].errorRedirect) {
        return (
          <div>
            <h1>
              Something went wrong. Please contact{" "}
              <Link href="https://dhr.alabama.gov/county-office-contact/">
                DHR County Office
              </Link>
            </h1>
          </div>
        );
      }
      return <Loading />;
    }
  }
  //Funtion to render questions
  renderApplyQuestions(questionsSections) {
    if (this.props.apply !== undefined) {
      const section = this.props.apply.sections;
    }
    const { common } = this.props.language.security;
    const msalInstance = this.props.msalContext.instance;

    var userInfo;
    var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (loginUserInfo !== null && loginUserInfo !== undefined) {
      userInfo = loginUserInfo;
    } else {
      if (
        this.props.consumer.mfaDetails !== undefined &&
        this.props.consumer.mfaDetails !== null
      ) {
        userInfo = this.props.consumer.mfaDetails;
      }
    }
    var providerRoleFlag = TextProps.VALUE_FALSE;
    var mhProviderRoleFlag = TextProps.VALUE_FALSE;
    if (
      this.props.consumer.loginDetails !== undefined &&
      this.props.consumer.loginDetails.roleList !== undefined
    ) {
      var roleList = this.props.consumer.loginDetails.roleList;
      for (var i = 0; i <= roleList.length; i++) {
        let roleListObj = roleList[i];
        if (roleListObj !== undefined && roleListObj.roleName !== undefined) {
          let Name = roleListObj.roleName;
          if (Name === roleProps.PROVIDER) {
            providerRoleFlag = TextProps.VALUE_TRUE;
          } else if (Name === roleProps.MH_PROVIDER_ROLE) {
            mhProviderRoleFlag = TextProps.VALUE_TRUE;
          }
        }
      }
    } else {
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        var roleList = loginUserInfo.roleList;
        for (var i = 0; i <= roleList.length; i++) {
          let roleListObj = roleList[i];
          if (roleListObj !== undefined && roleListObj.roleName !== undefined) {
            let Name = roleListObj.roleName;
            if (Name === roleProps.PROVIDER) {
              providerRoleFlag = TextProps.VALUE_TRUE;
            } else if (Name === roleProps.MH_PROVIDER_ROLE) {
              mhProviderRoleFlag = TextProps.VALUE_TRUE;
            }
          }
        }
      }
    }
    var loginFlag = TextProps.VALUE_FALSE;
    if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
      loginFlag = TextProps.VALUE_TRUE;
    } else {
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        if (loginUserInfo.success === TextProps.VALUE_TRUE) {
          loginFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        loginFlag === TextProps.VALUE_FALSE &&
        mhProviderRoleFlag === TextProps.VALUE_TRUE
      ) {
        loginFlag = TextProps.VALUE_TRUE;
        //sessionStorage.removeItem("isSnapForm");
      }
    }
    if (questionsSections !== undefined) {
      this.updateDimensions();
      const isFreeComOpen = sessionStorage.getItem("openFreeComm");
      if (isFreeComOpen === "true") {
        return (
          <div style={{ height: "100%" }}>
            <div className="full-height apply" style={{ overflowY: "unset" }}>
              <div style={{ display: "contents" }}>
                <SidebarNavigation
                  sections={questionsSections}
                  activeSectionId={this.state.activeSectionId}
                  inProgressSectionId={this.state.inProgressSectionId}
                  numSectionsRemaining={4}
                  onSectionClick={this.onSectionChange}
                  onSaveProgress={this.presentSaveProgressModal}
                  canSaveProgress={TextProps.VALUE_TRUE}
                  skipToAnchor="#content"
                  onDeleteClick={!this.appInanonymousMode ? this.onDeleteClick : null}
                />
                <main
                  className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
                  id="content"
                  tabIndex="-1"
                  style={{ overflowY: "hidden" }}
                >
                  {this.state.renderedSections.map((section) =>
                    this.renderSection(section)
                  )}
                  {loginFlag === TextProps.VALUE_TRUE ? (
                    <div className="interim-recert__options">
                      <div className="interim-recert__options__hidden">
                        <LanguageSelect className="interim-recert__language-select" />
                        <div className="interim-recert__options-account">
                          <span className="interim-recert__headertext">
                            {common.hi}
                          </span>
                          &nbsp;
                          <DTADropdown
                            buttonText={userInfo.firstName}
                            buttonClass="dta-button dta-button--link"
                            menuClass="list--bordered"
                            caret={TextProps.VALUE_TRUE}
                            caretClass="color-primary"
                          >
                            {providerRoleFlag === TextProps.VALUE_FALSE &&
                            mhProviderRoleFlag === TextProps.VALUE_FALSE ? (
                              <li className="pad-all--half">
                                <Link
                                  to="/consumer/myInfo"
                                  className="display-block"
                                  onClick={this._handleApplyGoBack.bind(this)}
                                >
                                  {common.home}
                                </Link>
                              </li>
                            ) : null}
                            {providerRoleFlag === TextProps.VALUE_TRUE &&
                            mhProviderRoleFlag === TextProps.VALUE_FALSE ? (
                              <li className="pad-all--half">
                                <Link
                                  to="/provider/searchConsumer"
                                  className="display-block"
                                  onClick={this._handleApplyGoBack.bind(this)}
                                >
                                  {common.home}
                                </Link>
                              </li>
                            ) : null}
                            {providerRoleFlag === TextProps.VALUE_FALSE &&
                            mhProviderRoleFlag === TextProps.VALUE_TRUE ? (
                              <li className="pad-all--half">
                                <Link
                                  to="/mhprovider/snapOutreach"
                                  className="display-block"
                                  onClick={this._handleApplyGoBack.bind(this)}
                                >
                                  {common.home}
                                </Link>
                              </li>
                            ) : null}
                            <li className="pad-all--half border-top">
                              {/* <React.Fragment> 
                            {azureLoginFlag.AZURE_LOGIN_FLAG ?
                              <Link to="#" className="display-block" onClick={()=>this.props.onAzureLogOut(msalInstance)} >
                                {common.logout}
                              </Link> 
                              : 
                              */}
                              <Link
                                to="#"
                                className="display-block"
                                onClick={this._handleApplyLogoutSession.bind(
                                  this
                                )}
                              >
                                {common.logout}
                              </Link>
                              {/* }
                          </React.Fragment>  */}
                            </li>
                          </DTADropdown>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <LanguageSelect className="language-select--dark mh-provider-display-none" />
                  )}
                </main>
                {this.renderSaveProgressModal()}
                {this.renderLoginModal()}
                {this.renderMfaModal()}
                {this.renderRegisterModal()}
                {this.renderRegistrationSuccessfull()}
                {this.renderDataDeleteWarningModal()}
                {this.renderSaveExitWarningModal()}
                {this.renderDeleteWarningModal()}
                {this.renderSaveOrExitWarningModal()}
                {this.renderAddressValidationModal()}
                {this.renderResetWarningModal()}
                {this.renderACESButtonClickWarningModal()}
                {this.renderLanguageSwitchWarningModal()}
                {this.renderIsHomelessModal()}
              </div>
            </div>
          </div>
        );
      }
    }
  }

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.apply.sections;
    }

    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }
  _getSectionReset(sectionId) {
    let targetSection = {};
    let section = this.props.apply.sections;
    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }
  //Updating current sections
  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj && sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj && sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  displayPDFDataForSNAP(dataSheetObj) {
    this.displayPDFData(dataSheetObj);
  }

  displayPDFDataForTANF(dataSheetObj) {
    this.displayPDFData(dataSheetObj);
  }

  displayPDFDataForComboTANF(dataSheetObj) {
    this.displayPDFData(dataSheetObj);
  }

  displayPDFDataForComboSNAP(dataSheetObj) {
    this.displayPDFData(dataSheetObj);
  }

  displayPDFData(dataSheetObj) {
    // const dataSheetObj = this.props.apply.application;
    this.props.displayPDFData(dataSheetObj).catch((error) => {
      if (error !== undefined && error.response !== undefined) {
        if (
          error.response.status !== undefined &&
          error.response.status === 403
        ) {
          this._handleApplyNavigateHome();
        }
      }
    });
  }

  // redirect to Home page if no previous pages
  redirectToHome = () => {
    window.location.assign("/consumer/myInfo");
  };

  // redirect to tanf if snap is submitted
  redirectToTanf = () => {
    sessionStorage.setItem("applicationType", "TANF");
    sessionStorage.setItem("prePopulateApp", "TANFAPP");
    window.location.assign("/apply");
  };

  // redirect to tanf if tanf is submitted
  redirectToSnap = () => {
    sessionStorage.setItem("applicationType", "SNAP");
    sessionStorage.setItem("prePopulateApp", "SNAPAPP");
    window.location.assign("/apply");
  };

  beforeGoToSignupPage() {
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );
      if (
        filterData(this.props.apply.sections, "income").questions[0].memberOptions !== undefined
      ) {
        dataSections.memberOptions =
          filterData(this.props.apply.sections, "income").questions[0].memberOptions;
      }
      let isValid = toValidateInputErros(dataSections, activeId);
      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        return;
      } else {
        //gotoSignupPage
        this.redirectToSignAndSubmit();
      }
    } else {
      //gotoSignupPage
      this.redirectToSignAndSubmit();
    }
  }

  //Login Section
  // Render Login Popup Modal
  renderLoginModal = () => {
    const { common } = this.props.language.consumer;
    return (
      <Modal
        isOpen={this.state.loginSectionContents}
        onClose={this.closeLoginModal}
        modalClass="login-modal"
        titleText="Log in"
      >
        <div className="dta-modal__body pad-all dataLoginModel">
          <div className="model-header">
            <h1>{common.Login}</h1>
          </div>
          <form name="dtaloginform" onSubmit={this.submitValidation.bind(this)}>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">{common.email}</label>
                <DTAInput
                  className="dta-form__control"
                  id="signin-email"
                  maxLength={100}
                  ref="email"
                  onChange={(val) => this.setState({ email: val })}
                  type="email"
                  required={TextProps.VALUE_TRUE}
                  error={this.inputUsernameError}
                />
              </div>
            </div>
            <div className="pure-g">
              <div className="pure-u-1">
                <div className="pure-g">
                  <div className="pure-u-1-2">
                    <label className="dta-form__label">{common.Password}</label>
                  </div>
                  <div className="pure-u-1-2">
                    <a className="forgetPasswordLink" href="/forgotPassword">
                      {common.forgetPassword}
                    </a>
                  </div>
                </div>
                <DTAInput
                  className="dta-form__control"
                  id="signin-pass"
                  maxLength={30}
                  ref="password"
                  onChange={(val) => this.setState({ password: val })}
                  type="password"
                  required={TextProps.VALUE_TRUE}
                  error={this.passwordError}
                />
              </div>
            </div>
            {(() => {
              if (this.state.loginErrorState === TextProps.VALUE_TRUE) {
                return (
                  <div className="pure-g">
                    <div className="pure-u-1 dta-form__error-message">
                      {this.state.loginErrorMessage}
                    </div>
                  </div>
                );
              }
            })()}
            <div>
              <button
                className="dta-button dta-button--primary dta-button--large dta-login"
                type="submit"
              >
                {common.Login}
              </button>
            </div>
          </form>
          <div className="pure-g">
            <div className="pure-u-1">
              <hr />
              <h4 className="margin-bottom-half register-bottom-text">
                {common.signupMsg}
              </h4>
              <br />
              <button
                className="dta-button dta-button--outline-primary dta-button--large dta-login-create-button"
                onClick={this.goToSignup}
              >
                {common.signupbutton}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderMfaModal = () => {
    const { common } = this.props.language.consumer;
    return (
      <Modal
        isOpen={this.state.mfaModel}
        onClose={this.closeMfaModal}
        modalClass="login-modal"
      >
        <div className="dta-modal__body pad-all dataLoginModel">
          <div className="model-header">
            <h1>{common.verifyAccount}</h1>
          </div>
          <form name="dtaloginMfaform" onSubmit={this.submitMFA.bind(this)}>
            <div className="pure-g">
              <div className="pure-u-1">
                <p>{common.mfatokenText}</p>
                <DTAInput
                  className="dta-form__control"
                  id="signin-pass"
                  maxLength={30}
                  placeholder={common.enterVerificationCode}
                  ref="mfaCode"
                  required={TextProps.VALUE_TRUE}
                  onChange={(val) => this.setState({ mfaToken: val })}
                  error={this.passwordError}
                />
              </div>
            </div>
            {(() => {
              if (this.state.mfaErrorState === TextProps.VALUE_TRUE) {
                return (
                  <div className="pure-g">
                    <div className="pure-u-1 dta-form__error-message">
                      {this.state.mfaErrorMessage}
                    </div>
                  </div>
                );
              }
            })()}
            <div>
              <button
                className="dta-button dta-button--primary dta-button--large dta-login"
                type="submit"
              >
                {common.submitCode}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  getRandomeId() {
    return Math.random() * 100000000000000000;
  }

  closeMfaModal = () => {
    this.setState({ mfaModel: TextProps.VALUE_FALSE });
  };

  closeLoginModal = () => {
    this.setState({ loginSectionContents: TextProps.VALUE_FALSE });
  };

  //Validation before sending
  submitValidation = (e) => {
    e.preventDefault();
    //Trigger Submit Request
    this.loginSubmit();
  };

  loginSubmit = () => {
    let loginDetails = {};
    loginDetails.email = this.state.email;
    loginDetails.password = this.state.password;

    const pwd = new Buffer(this.state.password).toString("base64");
    loginDetails.password = pwd;
    this.props.onLogin(loginDetails);
  };

  submitMFA = (e) => {
    e.preventDefault();
    let MFADetails = {};
    MFADetails.MFA = this.state.mfaToken;
    MFADetails.Auth = this.state.loginSucessDetails.Auth;
    MFADetails.MechanismId =
      this.state.loginSucessDetails.loginAuthObj.MechanismId;
    MFADetails.sessionID = this.state.loginSucessDetails.loginAuthObj.sessionID;
    MFADetails.UserName = this.state.loginSucessDetails.UserName;
    this.props.onMFASubmit(MFADetails);
  };

  goToSignup = () => {
    this.setState({
      signupSectionContents: TextProps.VALUE_TRUE,
      loginSectionContents: TextProps.VALUE_FALSE,
    });
  };

  goSignup = () => {
    window.location.assign("/signup");
    this.setState({
      signupSectionContents: TextProps.VALUE_TRUE,
      loginSectionContents: TextProps.VALUE_FALSE,
    });
  };

  saveInProgessToDatabase = () => {
    let inProgressObj = {};
    inProgressObj.status = "INPROG";
    inProgressObj.userId = this.props.consumer.mfaDetails.email;
    let application = convertToDTOObj(this.state.sections, null, inProgressObj);
    this.saveToDatabase(application, null, null);
    this.closeSaveProgressModal();
  };

  // Register Section
  renderRegistrationSuccessfull = () => {
    const { common } = this.props.language.consumer;
    return (
      <Modal
        isOpen={this.state.registrationSuccessful}
        onClose={this.closeRegisterSuccessfulModal}
        modalClass="signup-modal"
        titleText="SignUp"
      >
        <div className="dta-modal__body pad-all dtaRegisterModel">
          <div className="model-header">
            <h1>{common.signupbutton}</h1>
          </div>
          <p>Registration Successfull</p>
          <button
            className="dta-button dta-button--primary dta-button--large dta-login"
            onClick={this.closeRegisterSuccessfulModal.bind(this)}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  };

  handleClick = () => {
    // this.setState({ addressValidationModalOpen: TextProps.VALUE_TRUE });
  };

  closeModal = () => {
    // this.setState({ addressValidationModalOpen: TextProps.VALUE_FALSE });
    const subsection = filterData(this.props.apply.sections, "about-me").subsections[0];
    //SNAP
    if (
      filterData(this.props.apply.sections, "about-me").subsections[0].questions[3] !== undefined
    ) {
      if (
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[3].Answer !==
          undefined &&
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[3].type ===
          "address"
      ) {
        //filterData(this.props.apply.sections, "about-me").subsections[0].questions[3].Answer.stepResidenceAddressValidated =
        // "No";
        // if mailing address is mentioned
        // if (
        //   subsection !== undefined &&
        //   subsection.questions[4] !== undefined &&
        //   subsection.questions[4].conditionals[0] !== undefined &&
        //   subsection.questions[4].conditionals[0].conditionalQuestions[0]
        //     .Answer !== undefined
        // ) {
        //   subsection.questions[3].Answer.stepMailAddressValidated = "Yes";
        // }
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[3].verified = "N";
      }
    }

    //TANF
    if (
      filterData(this.props.apply.sections, "about-me").subsections[0].questions[0] !== undefined
    ) {
      if (
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[0].Answer !==
          undefined &&
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[0].type ===
          "address"
      ) {
        //filterData(this.props.apply.sections, "about-me").subsections[0].questions[0].Answer.stepResidenceAddressValidated =
        //"No";
        // if mailing address is mentioned
        // if (
        //   subsection !== undefined &&
        //   subsection.questions[1] !== undefined &&
        //   subsection.questions[1].conditionals[0] !== undefined &&
        //   subsection.questions[1].conditionals[0].conditionalQuestions[0]
        //     .Answer !== undefined
        // ) {
        //   subsection.questions[0].Answer.stepMailAddressValidated = "Yes";
        // }
        filterData(this.props.apply.sections, "about-me").subsections[0].questions[0].verified = "N";
      }
    }

    this.props.updateCurrentSectionData(this.props.apply);
  };

  XXstayModal = () => {
    // this.setState({ addressValidationModalOpen: TextProps.VALUE_FALSE });
  };
  showModal = () => {
    return (
      <div className="dta-modal__body pad-all">
        <p>
          You'r being timed out due to inactivity.Please choose
          <br />
          to stay signed in or to logoff.
        </p>
        <p> </p>
        <div className="apply__footer pad-all--double">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() => this.closeModal()}
          >
            Log Off
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={() => this.stayModal()}
          >
            Stay Logged In
          </button>
        </div>
      </div>
    );
  };

  XXaddressValidationModal = () => {
    return (
      <div className="dta-modal__body pad-all">
        <div className="pure-g margin-bottom-half is-mail-address">
          <div className="pure-u-1">
            <label
              className="dta-form__label dta-form__label--size-small"
              htmlFor={this.nextUniqueId()}
            >
              Please select the below option.
            </label>

            <label
              className="dta-form__label dta-form__label--size-small"
              htmlFor={this.nextUniqueId()}
            >
              Entered Address:
            </label>
            <Radio
              name="contact-info-changed-1"
              className="dta-form__option-list"
              optionClass="dta-form__option dta-form__option--width-1-of-2"
              options={this.state.defaultaddress}
              required={TextProps.VALUE_TRUE}
              value={"entered"}
              onChange={(value) => {
                this._handleAddressSelection("entered");
              }}
            />

            <label
              className="dta-form__label dta-form__label--size-small"
              htmlFor={this.nextUniqueId()}
            >
              Suggested Address:
            </label>
            <Radio
              name="contact-info-changed-2"
              className="dta-form__option-list"
              optionClass="dta-form__option dta-form__option--width-1-of-2"
              options={this.state.addressRadioOptions2}
              required={TextProps.VALUE_TRUE}
              value={"suggested"}
              onChange={(value) => {
                this._handleAddressSelection("suggested");
              }}
            />
          </div>

          <div className="apply__footer pad-all--double">
            <button
              className="dta-button dta-button--large dta-button--outline-primary"
              onClick={this.closeModal}
            >
              Cancel
            </button>
            <button
              className="dta-button dta-button--large dta-button--primary"
              onClick={this.confirmAddressValidate}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderRegisterModal = () => {
    const { common } = this.props.language.consumer;
    return (
      <Modal
        isOpen={this.state.signupSectionContents}
        onClose={this.closeRegisterModal}
        modalClass="signup-modal"
        titleText="SignUp"
      >
        <div className="dta-modal__body pad-all dtaRegisterModel">
          <div className="model-header">
            <h1>{common.signupbutton}</h1>
          </div>
          <form name="dtaregisterform" onSubmit={this.onRegister.bind(this)}>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">
                  {common.regEmailAddressLabel}
                </label>
                <DTAInput
                  className="dta-form__control"
                  id="regEmail"
                  maxLength={100}
                  placeholder=""
                  ref="regEmail"
                  onChange={(val) => this.setState({ email: val })}
                  type="email"
                  required={TextProps.VALUE_TRUE}
                  error={this.inputUsernameError}
                />
              </div>
            </div>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">
                  {common.regPasswordLabel}
                </label>
                <DTAInput
                  className="dta-form__control"
                  id="regPassword"
                  maxLength={30}
                  placeholder=""
                  ref="regPassword"
                  onChange={(val) => this.setState({ password: val })}
                  type="password"
                  required={TextProps.VALUE_TRUE}
                  error={this.passwordError}
                />
              </div>
            </div>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">
                  {common.regFirstNameLabel}
                </label>
                <DTAInput
                  className="dta-form__control"
                  id="regFirstName"
                  maxLength={15}
                  placeholder=""
                  ref="regFirstName"
                  type="alpha-text"
                  onChange={(val) => this.setState({ firstName: val })}
                  required={TextProps.VALUE_TRUE}
                  error={this.firstNameError}
                />
              </div>
            </div>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">
                  {common.regLastNameLabel}
                </label>
                <DTAInput
                  className="dta-form__control"
                  id="regLastName"
                  maxLength={20}
                  placeholder=""
                  ref="regLastName"
                  type="alpha-text"
                  onChange={(val) => this.setState({ lastName: val })}
                  required={TextProps.VALUE_TRUE}
                  error={this.lastNameError}
                />
              </div>
            </div>
            <div className="pure-g">
              <div className="pure-u-1">
                <label className="dta-form__label">{common.regDOBLabel}</label>
                <DTADate
                  {
                    ...this
                      .props /* For passing through value bindings and change handlers*/
                  }
                  name="regDob"
                  error={this.dobError}
                  required={TextProps.VALUE_TRUE}
                  onChange={(val) => {
                    console.log("dskdn",val)
                    this.setState({ dob: val })}}
                />
              </div>
            </div>
            {(() => {
              if (this.state.registrationErrorState === TextProps.VALUE_TRUE) {
                return (
                  <div className="pure-g">
                    <div className="pure-u-1 dta-form__error-message">
                      {this.state.registrationErrorMessage}
                    </div>
                  </div>
                );
              }
            })()}
            <div className=" apply__footer pad-all">
              <button
                className="dta-button dta-button--outline-primary dta-button--large"
                onClick={this.goToLogin.bind(this)}
              >
                {common.regGoBackButton}
              </button>
              <button
                className="dta-button dta-button--primary dta-button--large"
                type="submit"
              >
                {common.regMainHeading}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  closeRegisterModal = () => {
    this.setState({ signupSectionContents: TextProps.VALUE_FALSE });
  };

  closeRegisterSuccessfulModal = () => {
    this.setState({
      registrationSuccessful: TextProps.VALUE_FALSE,
      isLoggedin: TextProps.VALUE_TRUE,
    });
    this.props.onBindEmail(this.state.email);
  };

  onSignUp = (msalIns) => {
    try {
      // const loginRes =msalIns.loginRedirect(signUp);
      this.props.onConsumerSignup();
    } catch (err) {
      if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
        msalIns
          .loginRedirect(forgotPasswordRequest)
          .then(() => {
            console.log(
              "Password has been reset successfully. \nPlease sign-in with your new password."
            );
          })
          .catch(() => {
            console.log("Error in Changing password");
          });
      }
    }
  };

  onRegister(e) {
    e.preventDefault();
    let registerDetails = {};
    registerDetails.grant_type = "client_credentials";
    registerDetails.scope = "UserReg";
    registerDetails.email = this.state.email;
    registerDetails.password = this.state.password;
    registerDetails.firstname = this.state.firstName;
    registerDetails.lastname = this.state.lastName;
    registerDetails.dob = this.state.dob.toLocaleDateString();
    this.props.onRegister(registerDetails);
  }

  goToLogin() {
    this.setState({
      signupSectionContents: TextProps.VALUE_FALSE,
      loginSectionContents: TextProps.VALUE_TRUE,
    });
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthRepResponseBodyData: setAuthRepResponseBodyData,
      applySanpQuestionsSections: applySanpQuestionsSections,
      applySanpQuestionsSectionsNew: applySanpQuestionsSectionsNew,
      addressValidation: addressValidation,
      addApplyContactInfoSubSection: addApplyContactInfoSubSection,
      applyAddressValidationSection: applyAddressValidationSection,
      saveSnapApply: saveSnapApply,
      submitSnapApply: submitSnapApply,
      onPrimaryActionSaveClicked: onPrimaryActionSaveClicked,
      appendHouseHoldMembers: appendHouseHoldMembers,
      clearHouseHoldMembers: clearHouseHoldMembers,
      appendIncomeDetails: appendIncomeDetails,
      appendResourcesDetails:appendResourcesDetails,
      appendExpenseDetails: appendExpenseDetails,
      clearMemberIncome: clearMemberIncome,
      clearMemberResources:clearMemberResources,
      updateResolveQuestionsData: updateResolveQuestionsData,
      displayPDFData: displayPDFData,
      updateCurrentSectionData: updateCurrentSectionData,
      applyAddressStorageAPI: applyAddressStorageAPI,
      selectMaxOfIdsComesBack: selectMaxOfIdsComesBack,
      saveMyProgressInfoLater: saveMyProgressInfoLater,
      saveFinalSignAndSubmit: saveFinalSignAndSubmit,
      getSaveMyProgessDataByWebAppId: getSaveMyProgessDataByWebAppId,
      serviceFailedAction: serviceFailedAction,
      updateLastKnownLocation: updateLastKnownLocation,
      onGetConsumerLogin: getConsumerLoginContents,
      updateConsumerInfo: updateConsumerInfo,
      saveRequestAccountAccessUserInfo: saveRequestAccountAccessUserInfo,
      getSavedDataForUser: getSavedDataForUser,
      onConsumerLogin: () => push("/consumer/home"),
      onConsumerSignup: () => push("/signup"),
      gotoConsumer: () => push("/consumer"),
      gotoProvider: () => push("/provider"),
      onLogin: onLogin,
      onMFASubmit: onMFASubmit,
      onRegister: onRegister,
      onBindEmail: onBindEmail,
      goToLogin: () => push("/login"),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      gotoHome: () => push("/"),
      deleteWebAppData: deleteWebappData,
      acesButtonClicked: acesButtonClicked,
      appendMedicalExpenseSections: appendMedicalExpenseSections,
      appendMedicalCostOtherQuestion:appendMedicalCostOtherQuestion,
      languageChanged:languageChanged,
      prePopulationDataInfo:prePopulationDataInfo,
      setSectionBasedApplicationType:setSectionBasedApplicationType
    },
    dispatch
  );
}

export default withMsal(connect(mapStateToProps, mapDispatchToProps)(Apply));
