import PropTypes from "prop-types";
import * as Props from "../../utils/components/shared";
import * as TextProps from "../../utils/constants/text";

// Common to all controls
// ----------------------

export const CommonTypes = {
  /*
    string that will become the name attribute of all controls within this component
   */
  name: PropTypes.string,
  /*
    string list of ids that will become the accessibility attribute of the same name
   */
  "aria-labelledby": PropTypes.string,
  /*
    class value that will be applied to the container class of this component
   */
  className: PropTypes.string,
  /*
    boolean value indicating if this component should be disabled.
    This value will override the error state. That is, if a component is both disabled and
    has an error, then the component will display as disabled.
   */
  disabled: PropTypes.bool,
  /*
    boolean value indicating if this component is in an invalid state.
   */
  error: PropTypes.bool,
  /*
    boolean value indicating if this component is required
   */
  required: PropTypes.bool,
};

export const CommonDefaults = {
  // container
  name: "",
  "aria-labelledby": "",
  className: "",
  // states
  disabled: TextProps.VALUE_FALSE,
  error: TextProps.VALUE_FALSE,
  required: TextProps.VALUE_FALSE,
};

// For options controls (options, radio, checkbox)
// -----------------------------------------------

export const OptionsTypes = {
  ...CommonTypes,
  /*
    REQUIRED. An array of possible options. Can be a list of strings, a list of objects, or a
    list of both objects and strings. For documentation of what attributes the object should
    have, refer to the `types.js` file that contains all of the possible question types and also
    documentation of what attributes are required and optional for each question type. Specifically,
    in the `types.js` file, look for the RADIO or CHECKBOX input types.
   */
  options: PropTypes.array.isRequired,
  /*
    function that will be called when the value of this component changes. Will be passed a string
    or number if this is a Radio component. Will be passed an array of strings if this is a
    Checkbox component
   */
  onChange: PropTypes.func,
  /*
    class that will be passed to the OptionItem component and will be applied to the `li` element
    of the OptionItem itself. This class can be customized to allow for displaying multiple
    OptionItems next to each other on a single row.
   */
  optionClass: PropTypes.string,
  /*
    string value for what should be displayed on the option to clear the value
   */
  clearLabel: PropTypes.string,
  /*
    boolean indicating if whether or not this option to clear the value should be shown
   */
  showClear: PropTypes.bool,
  /*
    Radio and Checkbox components will also render children. This is intended to allow for
    the addition of custom OptionItems. See the Numbers component for an example of passing in
    a child component (in a list of elements) with custom behavior attached to it.
   */
  children: PropTypes.arrayOf(PropTypes.element),
};

export const OptionsDefaults = {
  ...CommonDefaults,
  // classes
  optionClass: "",
  // clear
  clearLabel: "None",
  showClear: TextProps.VALUE_FALSE,
};

// For control wrappers
// --------------------

export const WrapperTypes = {
  /*
    string value representing the name attribute
   */
  [Props.NAME]: PropTypes.string,
  /*
    label value representing either a string or an object, if we need to specify a HelpTip.
    See `types.js` for documentation on what properties the object needs to have if you want
    to render a HelpTip in the label
   */
  [Props.LABEL]: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /*
    boolean value indicating if the provided label has HTML markup and should be dangerously
    rendered into the DOM
   */
  [Props.LABEL_HAS_MARKUP]: PropTypes.bool,
  /*
    string value representing inline help that will be rendered underneath the label
   */
  [Props.INLINE_HELP]: PropTypes.string,
  /*
    boolean value indicating if the provided inline help string has HTML markup that should
    be dangerously rendered into the DOM
   */
  [Props.INLINE_HELP_HAS_MARKUP]: PropTypes.bool,
  /*
    boolean value indicating whether or not this question is required. Indicating that a question
    is required with automatically append '(Required)' to the end of the label. See the Label
    component to see how this is done.
   */
  [Props.REQUIRED]: PropTypes.bool,
  /*
    class to be applied to container element of the wrapper
   */
  className: PropTypes.string,
  /*
    class to be applied to the `label` if it is an nongrouped wrapper and to the `legend` within
    a `fieldset` if it is a grouped wrapper
   */
  questionClass: PropTypes.string,
  /*
    boolean indicating if the label should be hidden
   */
  labelHidden: PropTypes.bool,
  /*
    boolean indicating if this wrapper should be marked as disabled.
   */
  disabled: PropTypes.bool,
  /*
    boolean indicating if this wrapper should be display an error message
   */
  error: PropTypes.bool,
  /*
    string specifying what the error message is
   */
  errorMessage: PropTypes.string,
};

export const WrapperDefaults = {
  [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_FALSE,
  // container
  className: "",
  questionClass: "",
  labelHidden: TextProps.VALUE_FALSE,
  // states
  disabled: TextProps.VALUE_FALSE,
  error: TextProps.VALUE_FALSE,
  errorMessage: "Oops. Something went wrong.",
};
