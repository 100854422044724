import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import KdTree from "kd.tree";
import haversine from "haversine-distance";

import DTAMap from "./dtaMap";
import DTAMarker from "./dtaMarker";
import { componentOfType } from "../../components/utilities/proptypes";
import { isCoord } from "../../components/utilities/formatters";
import { tryCall } from "../../components/utilities/controls";
import * as TextProps from "../../constants/text";

/*
  MapWrapper
  ----------

  # Purpose:
  Wraps the `DTAMap` component, managing rendering of markers according to the passed in data
  and maintaining a KDTree index of the points displayed on the map for accessibility and search

  # Props:
  doBuildMarker: Required, function that is passed the data object and returns a renderable node
  data: Optional, array of coordinate objects with keys `lat` and `lng`
  onZoomChange: Optional, function passed the zoom value whenever the zoom changes
  value: Optional, one-way data binding coordinate object with `lat` and `lng` keyed entries
  className: Optional, string classes for the map container element
  zoomToFitOnCenterChange: Optional, boolean to indicate whether the map viewport should be adjusted
                           to show at least the closest marker when the center of the map is moved
  screenReaderNumResults: Optional, number of results that should be rendered into the screen reader
                          verbal interface after a search
  screenReaderLabel: Optional, string additional text for describing the purpose of this map
  children: Optional, zero or more children that must be a `DTAMarker`

  # Example:
  <MapWrapper
    findCurrentLocation
    apiKey={MAP_API_KEY}
    onChange={this._handleChangeForMap}
    onZoomChange={this._handleZoomChangeForMap}
    doBuildMarker={this._buildMarker}
    value={center}
    zoom={zoom}
    data={this._buildData()}
  >
    {isCoord(center) && <DTAMarker hasDetail={TextProps.VALUE_FALSE} position={center} />}
  </MapWrapper>
 */

class MapWrapper extends Component {
  static propTypes = {
    // required
    doBuildMarker: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      })
    ).isRequired,
    // handlers
    onZoomChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    // container
    className: PropTypes.string,
    zoomToFitOnCenterChange: PropTypes.bool,
    // screen reader
    screenReaderNumResults: PropTypes.number,
    screenReaderLabel: PropTypes.string,
    // children
    children: componentOfType(DTAMarker),
  };

  static defaultProps = {
    // container
    className: "",
    zoomToFitOnCenterChange: TextProps.VALUE_TRUE,
    // screen reader
    screenReaderNumResults: 10,
    screenReaderLabel:
      "This map displays the results, filtered by the selected types and ordered from nearest to farthest, for the search entered above.",
  };

  state = {
    orderedResults: [],
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
    this._buildTree(props.data);
  }

  componentDidMount() {
    const {
      value,
      screenReaderNumResults,
      zoomToFitOnCenterChange: shouldFit,
    } = this.props;
    this._orderFromTree(value, screenReaderNumResults, shouldFit);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data.length !== nextProps.data.length) {
      this._buildTree(nextProps.data);
    }
    if (this.props.value !== nextProps.value) {
      const {
        value,
        screenReaderNumResults,
        zoomToFitOnCenterChange: shouldFit,
      } = nextProps;
      this._orderFromTree(value, screenReaderNumResults, shouldFit);
    }
  }

  render() {
    const {
      className,
      data,
      doBuildMarker,
      children,
      screenReaderLabel,
      ...otherProps
    } = this.props;
    return (
      <div className={className}>
        <span
          aria-hidden={TextProps.VALUE_STR_TRUE}
          className="sr-only"
          id={this.getUniqueId("label")}
        >
          {screenReaderLabel}
        </span>
        <ol
          className="sr-only"
          tabIndex="0"
          aria-labelledby={this.getUniqueId("label")}
        >
          {this.state.orderedResults.map((dataObj) => {
            const result = doBuildMarker(dataObj);
            return result && React.isValidElement(result) ? (
              <li key={result.key}>{result.props.children}</li>
            ) : null;
          })}
        </ol>
        <DTAMap
          {...otherProps}
          mapRef={(el) => (this._map = el)}
          containerProps={{
            "aria-hidden": TextProps.VALUE_TRUE,
            role: "presentation",
          }}
        >
          {data.map(doBuildMarker)}
          {children}
        </DTAMap>
      </div>
    );
  }

  // KD Tree
  // -------

  _buildTree(data) {
    this._tree = KdTree.createKdTree(data, this._calculateDistance, [
      "lat",
      "lng",
    ]);
  }

  _calculateDistance(p1, p2) {
    return isCoord(p1) && isCoord(p2)
      ? haversine({ lat: p1.lat, lng: p1.lng }, { lat: p2.lat, lng: p2.lng })
      : Number.MAX_SAFE_INTEGER;
  }

  // Perform a nearest-neighbor search using the KD tree. If zooming to fit at least the
  // nearest neighbor is enabled, we have a default zoom set in order to allow the map
  // to update to its new center and bounds before performing the fitting
  _orderFromTree(
    position,
    numResults,
    zoomToFit,
    { delayFit } = { delayFit: 1000 }
  ) {
    if (this._tree && isCoord(position)) {
      const results = this._tree
        .nearest(position, numResults)
        .sort(([i1, d1], [i2, d2]) => d1 - d2)
        .map((result) => result[0]);
      this.setState({ orderedResults: results });
      if (zoomToFit) {
        if (delayFit) {
          setTimeout(() => this._fitToPoint(results[0]), delayFit);
        } else {
          this._fitToPoint(results[0]);
        }
      }
    }
  }

  // Helpers
  // -------

  _fitToPoint(point) {
    if (!this._map) {
      return;
    }
    const bounds = this._map.getBounds();
    // do not need to modify the bounds if the the point is already contained within
    // the current map area. Fitting bounds seems to zoom out each time, so we want
    // to fit bounds only when necessary
    if (bounds && !bounds.contains(point)) {
      const newBounds = bounds.extend(point),
        zoomBefore = this._map.getZoom();
      this._map.fitBounds(newBounds, 0); // 0 for zero padding need around the bounds
      // trigger zoom handler if the zoom changes from the fitting
      const zoomAfter = this._map.getZoom();
      if (zoomBefore !== zoomAfter) {
        tryCall(this.props.onZoomChange, zoomAfter);
      }
    }
  }
}

export default MapWrapper;
