// Vendor / components
import React, { Component } from "react";
import { matchPath } from "react-router";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// DHR Components
import DTANavigation from "../../../utils/components/accountNavigation";
import DTAFooter from "../../../utils/components/dtaFooter";
import NotFound from "../../../utils/components/notFound";

// Provider Components
import ProviderHome from "./myClients";
import ProviderDTAInfo from "./providerDTAInfo";
import SnapOutreach from "../../mhProvider/components/snapOutreach";
import ProviderSearchConsumer from "./searchAllClientsConsumer";

// Constants
import * as SharedProps from "../../../utils/constants/shared";

// Nav section data
import {
  providerNavSections,
  providerSNAPGAPNavSections,
  providerNOPSIRoleNavSections,
} from "../providerMapData";
import { mapStateToProps } from "../../../redux/rootReducer";
import ConsumerMyAccount from "../../consumer/components/myAccount";
import { push } from "react-router-redux";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as roleProps from "../../../utils/constants/rolesConstants";
import * as TextProps from "../../../utils/constants/text";

class ProviderApp extends Component {
  constructor(props) {
    super(...arguments);

    this.state = {
      // User should really come from redux or elsewhere
      [SharedProps.USER]: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1989, 9, 13),
      },
      navItems:
        JSON.parse(sessionStorage.getItem("loadConsumerUser")) &&
        Object.keys(JSON.parse(sessionStorage.getItem("loadConsumerUser")))
          .length <= 0
          ? providerNOPSIRoleNavSections
          : providerNavSections,
      activeItemId: providerNavSections[0][SharedProps.ID],
    };
  }

  // Start:  routing helpers
  componentDidMount() {
    this.setActiveItemId();
  }

  componentWillMount() {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userObject !== null) {
      var UserObj = userObject;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }

    if (
      userObject.authorities !== undefined &&
      userObject.authorities.length > 0
    ) {
      userObject.authorities.forEach((code) => {
        if (
          code === roleProps.HEALTH_VIEW_CASE ||
          code === roleProps.HEALTH_MEMBER_SEARCH
        ) {
          this.setState({ navItems: providerSNAPGAPNavSections });
        }
      });
    }

    if (this.props !== undefined) {
      if (this.props.consumer.mfaDetails.email !== undefined) {
        let isUserObj = this.props.consumer.mfaDetails;
        if (isUserObj.email !== "") {
          this.setState({ user: isUserObj });
        } else {
          this.setState({ user: UserObj });
        }
        if (this.props.consumer.mfaDetails.roleList !== undefined) {
          if (this.props.consumer.mfaDetails.roleList.length > 0) {
            this.props.consumer.mfaDetails.roleList.forEach((role) => {
              if (role.roleName === "DTA_CONNECT_NOPSI") {
                this.setState({ navItems: providerNOPSIRoleNavSections });
              }
            });
          }
        } else if (userObject.roleList !== undefined) {
          if (userObject.roleList.length > 0) {
            userObject.roleList.forEach((obj) => {
              if (obj.roleName === "DTA_CONNECT_NOPSI") {
                this.setState({ navItems: providerNOPSIRoleNavSections });
              }
            });
          }
        } else if (Object.keys(this.props.consumer.consumerData).length > 0) {
          if (this.props.consumer.consumerData.user !== undefined) {
            let isConsumerUserObject = this.props.consumer.consumerData.user;
            this.setState({ user: isConsumerUserObject });
          }
        } else {
          this.setState({ user: this.state.user });
        }
      } else {
        if (userObject !== undefined && userObject !== null)
          this.setState({ user: userObject });
      }
    }
  }

  componentDidUpdate() {
    this.setActiveItemId();
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      userObject.authorities !== undefined &&
      userObject.authorities.length > 0
    ) {
      if (
        (userObject.authorities.includes(roleProps.HEALTH_VIEW_CASE) ||
          userObject.authorities.includes(roleProps.HEALTH_MEMBER_SEARCH)) &&
        window.location.pathname === routeProps.PROVIDER_SNAPOUTREACH_PATH
      ) {
        return <Redirect to={routeProps.PROVIDER_SNAPOUTREACH_PATH} />;
      } else if (
        !userObject.authorities.includes(roleProps.HEALTH_VIEW_CASE) &&
        !userObject.authorities.includes(roleProps.HEALTH_MEMBER_SEARCH) &&
        window.location.pathname === routeProps.PROVIDER_SNAPOUTREACH_PATH
      ) {
        window.location.assign(routeProps.HOME_PATH);
      }
    }
  }

  getActiveItemId = () => {
    let found = undefined;

    this.state.navItems.forEach((item) => {
      const match = matchPath(this.props.location.pathname, {
        path: `/${item[SharedProps.ID]}`,
        exact: TextProps.VALUE_TRUE,
        strict: TextProps.VALUE_FALSE,
      });
      if (match) {
        found = item[SharedProps.ID];
      }
    });

    return found;
  };

  setActiveItemId = () => {
    const activeItemId = this.getActiveItemId();

    if (activeItemId && activeItemId !== this.state.activeItemId) {
      this.setState({ activeItemId: activeItemId });

      // Scroll back to the top of the page
      window.scrollTo(0, 0);
    }
  };
  // End:  routing helpers

  resolveComponentFromId(id) {
    switch (id) {
      case "searchConsumer":
        return ProviderSearchConsumer;
      case "home":
        return ProviderHome;
      case "dtaInfo":
        return ProviderDTAInfo;
      case "myAccount":
        return ConsumerMyAccount;
      case "snapOutreach":
        return SnapOutreach;
      default:
        return NotFound;
    }
  }

  render() {
    const {
      location: { state },
    } = this.props;
    return (
      <div className="provider page-wrapper">
        <DTANavigation
          navItems={this.state.navItems}
          activeItemId={this.state.activeItemId}
          user={this.state.user}
          provider={TextProps.VALUE_TRUE}
          providerViewingClient={TextProps.VALUE_FALSE}
          skipToAnchor="#content"
        />
        <main className="main" id="content">
          <DocumentTitle
            title={state && state.title ? state.title : "DHR Client Portal"}
          >
            <Switch>
              <Redirect
                exact
                from={routeProps.HOME_PATH}
                to={routeProps.SEARCH_CONSUMER_PATH}
              />
              {providerSNAPGAPNavSections.map(({ id }) => {
                return (
                  <Route
                    key={id}
                    exact
                    path={`/${id}`}
                    component={this.resolveComponentFromId(id)}
                  />
                );
              })}
              <Route component={NotFound} />
            </Switch>
          </DocumentTitle>
        </main>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      gotoLogin: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProviderApp)
);
