import * as OptionsProps from "../../constants/options";
import * as TextProps from "../../constants/text";

// Options
// -------

/**
 * Takes in a either a string or an option with predetermined keys and outputs
 * the normalized option for consumption by the `Option` component
 * @param  {object|string} obj  Object or string before normalization
 * @return {object}             Normalized object
 */
export function normalizeOption(obj) {
  if (isString(obj) || isNumber(obj)) {
    return {
      thisValue: obj,
      thisDisplay: obj,
      displayClass: "",
      thisCount: obj,
    };
  } else {
    const size = obj[OptionsProps.OPTION_DISPLAY_SIZE];
    return {
      thisValue: obj[OptionsProps.OPTION_VALUE],
      thisDisplay: obj[OptionsProps.OPTION_DISPLAY],
      thisCount: obj[OptionsProps.OPTION_COUNT],
      displayClass: size ? `text--${size}` : "",
    };
  }
}

// Help tip
// --------

/**
 * Determines if a label requires rendering via the `HelpTip`component or if the label is
 * simply a string
 * @param  {object|string}  label   label object or label string contents
 * @return {Boolean}                TextProps.VALUE_TRUE or TextProps.VALUE_FALSE determining if a `HelpTip` component is needed for rendering
 */
export function hasHelpTip(label) {
  return isObject(label);
}

// Type checking
// -------------

/**
 * Determines if the passed-in value is a string
 * @param  {any}  obj    value to inspect
 * @return {Boolean}     TextProps.VALUE_TRUE if the passed-in value is a string, TextProps.VALUE_FALSE otherwise
 */
export function isString(obj) {
  return typeof obj === "string" || obj instanceof String;
}

/**
 * Determines if the passed-in value is a number
 * @param  {any}  obj     value to inspect
 * @return {Boolean}      TextProps.VALUE_TRUE if the passed-in value is a number, TextProps.VALUE_FALSE otherwise
 */
export function isNumber(obj) {
  return isNaN(obj) === TextProps.VALUE_FALSE;
}

/**
 * Determines if the passed-in value is falsy
 * @param  {any}  obj     value to inspect
 * @return {Boolean}      TextProps.VALUE_TRUE if the passed-in value is falsy, TextProps.VALUE_FALSE otherwise
 */
export function isNone(obj) {
  return (
    obj === null ||
    obj === undefined ||
    obj === "" ||
    (typeof obj !== "function" && obj.length === 0)
  );
}

/**
 * Determines if the passed-in value is a function
 * @param  {any}  obj     value to inspect
 * @return {Boolean}      TextProps.VALUE_TRUE if the passed-in value is a function, TextProps.VALUE_FALSE otherwise
 */
export function isCallable(obj) {
  return !isNone(obj) && typeof obj === "function";
}

/**
 * Determines if the passed-in value is an object
 * @param  {any}  obj     value to inspect
 * @return {Boolean}      TextProps.VALUE_TRUE if the passed-in value is an object, TextProps.VALUE_FALSE otherwise
 */
export function isObject(obj) {
  return !isNone(obj) && (typeof obj === "object" || obj instanceof Object);
}

// Objects
// -------

/**
 * Builds an object using the array element as keys and the value as the value for all entries
 * @param  {array} array  array of items to use as keys in the object
 * @param  {any} value    value of all of the entries of the resulting object
 * @return {object}       object built from the passed-in array and value
 */
export function buildObjectFromArray(array, value) {
  const obj = {};
  if (array) {
    array.forEach((arrayEl) => (obj[arrayEl] = value));
  }
  return obj;
}

/**
 * Extract entires with the specified key names from the source object
 * @param  {array} keys           array of strings representing the keys of the object to extract
 * @param  {object} sourceObject  source object to extract the keys from
 * @return {object}               new object with extracted entries
 */
export function extractFromObject(keys, sourceObject) {
  const obj = {};
  keys.forEach((option) => (obj[option] = sourceObject[option]));
  return obj;
}

// Strings
// -------

/**
 * Trims whitespace on both sides of provided string
 * @param  {string} str   string to trim whitespace
 * @return {string}       string with whitespace on both ends trimmed, or what was passed-in if not string
 */
export function trimWhitespace(str) {
  return isString(str) ? str.replace(/^[\s]+|[\s]+$/g, "") : str;
}

/**
 * Validat4es aria DOM attribute
 * @param  {string} attribute   string value of the aria attribute
 * @return {string}             formatted, validated string that is a valid attribute value
 */
export function validateAria(attribute) {
  const trimmed = trimWhitespace(attribute);
  // return null if none so that aria attribute is not rendered because
  // an aria attribute with no valid is invalid
  return isNone(trimmed) ? null : trimmed;
}

// Conditions
// ----------

/**
 * Append the condition or modified class to the provided string if truthy
 * @param {boolean|string} condition      if truthy, appending will happen. No appending if falsy
 * @param {string} original               original string to append to, if appending occurs
 * @param {string} modifierClass          Optional, will be appended if condition is truthy. If this
 *                                        argument is not provided, the condition itself will be appended if
 *                                        the condition is truthy
 * @return {string}                       newly appended string
 */
export function addStringIf(condition, original, modifierClass = undefined) {
  const toBeAdded = modifierClass ? modifierClass : condition;
  return condition ? `${original} ${toBeAdded}` : original;
}

/**
 * Merge the provided value at the specified key into the object if truthy
 * @param  {any} toBeMerged     what will be merged if this is truthy
 * @param  {string} key         key of the object to merge at
 * @param  {object} obj         object to merge into
 * @return {object}             passed-in object with merged-in entry, if merging happened
 */
export function mergeIfPresent(toBeMerged, key, obj) {
  if (!isNone(toBeMerged)) {
    obj[key] = toBeMerged;
  }
  return obj;
}

/**
 * Attempt to call the provided function with the following arguments, if any
 * @param  {function}    func     function to attempt ot call
 * @param  {...any} args vararg   array of arguments to passed into the function
 * @return {any}         return   value of the function, if any
 */
export function tryCall(func, ...args) {
  return isCallable(func) ? func(...args) : null;
}
