import React from "react";

import { addStringIf } from "./utilities/controls";
import TermsDSnap from "./termsDsnap";


export default function TermDSnap({ id, className }) {
  return (
    <div id={id} className={addStringIf(className,"dta-terms")}>
      <TermsDSnap></TermsDSnap>
    </div>

   
  );
}