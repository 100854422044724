import { formatDateNew } from "../../utils/components/utilities/formatters";
import { aboutMeMultilanguageLabels } from "./greeterScreeningText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

export function convertToDTOObj(sections, inProgressObj) {
  const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  //from UI answers and questions
  let aboutMe = sections[0].questions;

  let applicant = new Person();

  applicant.firstName = aboutMe[0].Answer;
  applicant.middleName = aboutMe[1].Answer;
  applicant.lastName = aboutMe[2].Answer;
  if (
    aboutMe[3].Answer !== "" &&
    aboutMe[3].Answer !== undefined &&
    aboutMe[4].Answer !== null
  ) {
    applicant.dob = formatDateNew(aboutMe[3].Answer);
  }
  applicant.agencyID = aboutMe[4].Answer;
  if (
    aboutMe[5].Answer !== undefined &&
    aboutMe[5].Answer !== null &&
    aboutMe[5].Answer !== ""
  ) {
    const phone = aboutMe[5].Answer.replace(/[^0-9]/g, "");
    applicant.phone = phone;
  }

  if (aboutMe[6].Answer !== undefined || aboutMe[6].Answer !== null) {
    if (
      aboutMe[6].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).accessOptions[0]
    ) {
      applicant.safeAcessFlg = TextProps.VALUE_Y;
    } else {
      applicant.safeAcessFlg = TextProps.VALUE_N;
    }
  }

  if (aboutMe[7].Answer !== undefined || aboutMe[7].Answer !== null) {
    if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[0]
    ) {
      applicant.reasonForVisit = "APPLYFORSNAP";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[1]
    ) {
      applicant.reasonForVisit = "APPLYFORCASHCOMBO";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[2]
    ) {
      applicant.reasonForVisit = "DOCDROPOFFSCAN";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[3]
    ) {
      applicant.reasonForVisit = "NEEDACCESSTODOC";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[4]
    ) {
      applicant.reasonForVisit = "RECERTREEVALUATION";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[5]
    ) {
      applicant.reasonForVisit = "EBT";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[6]
    ) {
      applicant.reasonForVisit = "SPEAKTOSTAFF";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[7]
    ) {
      applicant.reasonForVisit = "PEBT";
    } else if (
      aboutMe[7].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).visitReasonOptions[8]
    ) {
      applicant.reasonForVisit = "OTHERAGENCY";
    }
  }

  if (aboutMe[8].Answer !== undefined || aboutMe[8].Answer !== null) {
    if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[0]
    ) {
      applicant.serviceUtilized = "OPTFOROFFSITE";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[1]
    ) {
      applicant.serviceUtilized = "KIOSK";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[2]
    ) {
      applicant.serviceUtilized = "PHONE";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[3]
    ) {
      applicant.serviceUtilized = "SCANNERPRINTER";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[4]
    ) {
      applicant.serviceUtilized = "COLOCATEDAGENCY";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[5]
    ) {
      applicant.serviceUtilized = "DTACONECTTECHCOACHING";
    } else if (
      aboutMe[8].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).serviceOptions[6]
    ) {
      if (aboutMe[8].conditionals !== undefined) {
        if (
          aboutMe[8].conditionals[0].conditionalQuestions[0].Answer !== "" &&
          aboutMe[8].conditionals[0].conditionalQuestions[0].Answer !==
            undefined
        ) {
          applicant.serviceUtilized = "OTHER";
          applicant.otherServiceUtilized =
            aboutMe[8].conditionals[0].conditionalQuestions[0].Answer;
        }
      }
    }
  }
  return applicant;
}

function Person() {
  this.firstName = "";
  this.middleName = "";
  this.lastName = "";
  this.dob = null;
  this.agencyID = "";
  this.phone = "";
  this.safeAcessFlg = "";
  this.serviceUtilized = "";
  this.reasonForVisit = "";
  this.otherServiceUtilized = "";
}
