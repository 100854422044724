export function getAudit() {
  var systemInfo = JSON.parse(sessionStorage.getItem("systemInfo"));
  var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
  var consumerDataUserObj = JSON.parse(
    sessionStorage.getItem("loadConsumerUser")
  );
  var consumerNewUserObj = JSON.parse(
    sessionStorage.getItem("consumerAddrData")
  );
  var providerAddress = JSON.parse(sessionStorage.getItem("providerData"));
  var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
  var appSessionID = JSON.parse(sessionStorage.getItem("sessionID"));
  var userPhoneNumber;
  if (providerAddress !== null) {
    userPhoneNumber = providerAddress.phone;
  } else if (clientDetails !== null && clientDetails.clientPhone !== null) {
    if (
      clientDetails !== undefined &&
      clientDetails.clientPhone !== undefined
    ) {
      userPhoneNumber = clientDetails.clientPhone.phoneNumber
        ? clientDetails.clientPhone.phoneNumber
        : "";
    }
  }
  var audit = {
    source: systemInfo && systemInfo.source ? systemInfo.source : "",
    ipAddress: systemInfo && systemInfo.ip ? systemInfo.ip : "",
    deviceID: systemInfo && systemInfo.deviceId ? systemInfo.deviceId : "",
    phoneNumber: userPhoneNumber ? userPhoneNumber : "",
    emailID:
      userObject && userObject.userDetails && userObject.userDetails.email
        ? userObject.userDetails.email
        : userObject && userObject.email
        ? userObject.email
        : "",
    sessionId: appSessionID ? appSessionID : "",
  };
  return audit;
}
