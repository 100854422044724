import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import { UnmountClosed as Collapse } from "react-collapse";
import Filter from "./filter";
import Icon from "../dtaIcon";
import { addStringIf } from "../../components/utilities/controls";
import { componentOfType } from "../../components/utilities/proptypes";
import { consumerConst } from "../../../pages/consumer/consumerText";
import * as helperFunction from "../../components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

/*
  SearchFilters
  -------------

  # Purpose:
  An accessible component for rendering a search bar (or section title in place of the search bar)
  with an accompanying slide-down tray of filters used to modify the search

  # Props:
  search: Required, React element that will be rendered in the position of the search bar spanning
          the majority of the horizontal space next to the trigger for the filters tray
  startOpen: Optional, boolean indicating whether or not the filters tray should start open
  hideToggle: Optional, boolean indicating whether or not to show the toggle for the filters tray
  children: Optional, zero or more `Filter`s to render inside the filters tray

  # Example:
  <SearchFilters
    search={
      <ControlWrapper type={FiltersData.SEARCH[Props.TYPE]} data={FiltersData.SEARCH} />
    }
  >
    <Filter data={FiltersData.FILTER_DATE} />
    <Filter className="pure-u-1-4" data={FiltersData.FILTER_DOCUMENT_TYPE} />
  </SearchFilters>
 */

class SearchFilters extends Component {
  static propTypes = {
    search: PropTypes.element.isRequired,
    startOpen: PropTypes.bool,
    hideToggle: PropTypes.bool,
    children: componentOfType(Filter),
    clearFilter: PropTypes.func,
  };

  static defaultProps = {
    startOpen: TextProps.VALUE_FALSE,
    hideToggle: TextProps.VALUE_FALSE,
  };

  state = {
    isOpen: this.props.startOpen,
  };

  render() {
    const { hideToggle, children } = this.props,
      numChildren = Children.count(children),
      { isOpen } = this.state;
    return (
      <div
        className={addStringIf(
          isOpen,
          "dta-search-filters",
          "dta-search-filters--has-filters"
        )}
      >
        <div className="dta-search-filters__container">
          <div className="dta-search-filters__item"></div>
          <div
            className={addStringIf(
              hideToggle || numChildren <= 0,
              "pad-left-half dta-search-filters__item dta-search-filters__item--minimum",
              "sr-only"
            )}
          >
            <button
              type="button"
              className="dta-button dta-button--minor dta-button--text-with-icon"
              onClick={this._handleClickForButton}
              aria-expanded={isOpen}
            >
              {consumerConst(selectedLanguage).filters}
              <Icon ariaHidden name={isOpen ? "minus" : "plus"} />
            </button>
          </div>
        </div>
        <Collapse isOpened={isOpen}>
          <div className="dta-search-filters__drawer-wrapper">
            <div className="dta-search-filters__drawer">{children}</div>
          </div>
        </Collapse>
      </div>
    );
  }

  // Events
  // ------

  _handleClickForButton = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if(this.props.clearFilter){
      this.props.clearFilter();
    }
  };
}

export default SearchFilters;
