import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import * as languageConstants from "../../utils/constants/constants";
import { interimReportPageLabels } from "../recertInterimReport/recertInterimReportText";
import { selfDeclarePageLabels } from "./selfdeclareText";
import { formatDate } from "../../utils/components/utilities/formatters";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === languageConstants.langOptSel) {
    language = "en-US";
  }

let memberList = [];
let head = null;
if (sessionStorage.getItem("householdMembers") !== null) {
  let members = JSON.parse(sessionStorage.getItem("householdMembers"));
  let memberName = null;
  members.map((member) => {
    memberName =
      member.firstName + " " + member.lastName + formatDate(member.dateOfBirth);
    if (member.granteeFlag === TextProps.VALUE_TRUE) {
      memberList.push(memberName);
      head = memberName;
      return head;
    }
    if (member.dataChangeCode !== "REMOVE") {
      memberList.push(memberName);
      return memberList;
    }
  });
}

let questions = null;
if (memberList.length > 1) {
  questions = [
    {
      [Props.TYPE]: Types.HTML_TEXT,
      [TextProps.VALUE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[0],
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[1],
    },
    {
      [Props.TYPE]: Types.DIVIDER,
    },
    {
      [Props.NAME]: "expenses-type",
      [Props.LABEL]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[2], //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "shelter-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).houseUtlilityLabel,
        },
        {
          [OptionsProps.OPTION_VALUE]: "utility-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).utilityCostSelf,
        },
        {
          [OptionsProps.OPTION_VALUE]: "dependent-care-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).childTitle,
        },
        {
          [OptionsProps.OPTION_VALUE]: "medical-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).medicalTitle,
        },
        {
          [OptionsProps.OPTION_VALUE]: "child-support",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).childSupTitle,
        },
      ],

      [OptionsProps.CLEAR_OPTION]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).clearOptionSelfDeclareExpenses,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpensesSummary
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
  ];
} else {
  questions = [
    {
      [Props.TYPE]: Types.HTML_TEXT,
      [TextProps.VALUE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[0],
    },
    {
      [Props.TYPE]: Types.TEXT,
      [TextProps.VALUE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[1],
    },
    {
      [Props.TYPE]: Types.DIVIDER,
    },
    {
      [Props.NAME]: "expenses-type",
      [Props.LABEL]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost[2], //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "shelter-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).houseUtlilityLabel,
        },
        {
          [OptionsProps.OPTION_VALUE]: "utility-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).utilityCostSelf,
        },
        {
          [OptionsProps.OPTION_VALUE]: "medical-costs",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).medicalTitle,
        },
        {
          [OptionsProps.OPTION_VALUE]: "child-support",
          [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpenses
          ).childSupTitle,
        },
      ],

      [OptionsProps.CLEAR_OPTION]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).clearOptionSelfDeclareExpenses,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpensesSummary
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
  ];
}
export const sections = [
  {
    id: "self-declare-expenses",
    title: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).selfDeclareHeading, //'Expenses',
    header: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).selfDeclareHeading,
    questions: questions,
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "summary",
    title: "Summary", //'Sign & submit',
    header:
      head +
      interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
        .emptyLabel +
      selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpensesSummary
      ).expenseSummaryLabel, //'Sign & submit',
    subHeader: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpensesSummary
    ).expenseShelterSubHeader,
    hiddenFromNav: TextProps.VALUE_TRUE,
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "sign-and-submit",
    title: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).selfDeclareSummaryHeading, //'Sign & submit',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).signSubmitHeader, //'Sign & submit',
    subsections: [
      {
        id: "submitted",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).subSectionLabel, //'Submitted',
        header: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpenses
        ).submitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];

export default sections;

export const shelterCostDetailTemplate = {
  id: "shelter-cost-detail",
  title: selfDeclarePageLabels(language, languageConstants.selfDeclareExpenses)
    .houseUtlilityLabel, //'Housing and Utility Costs',
  header: selfDeclarePageLabels(language, languageConstants.shelterCosts)
    .expenseHeader, //'Tell us about your housing costs',
  subHeader: selfDeclarePageLabels(
    language,
    languageConstants.selfDeclareExpensesSummary
  ).expenseShelterSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
  completed: TextProps.VALUE_FALSE,
  hiddenFromNav: TextProps.VALUE_TRUE,
  questions: [
    {
      [Props.NAME]: "rent",
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).rentTargetValue, //"Rent",
      [Props.TYPE]: Types.REPEATABLE_QUESTION,
      [ControlConstants.REPEATABLE_QUESTION]: {
        [Props.LABEL]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).houseRentQLabel, //"How much is your household's rent?",
        [Props.TYPE]: Types.UNITS,
        [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).unitsPerLabel, //'per',
        [UnitsProps.UNITS]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions, //['Week', 'Month', 'Year'],
        [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
        [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions[3],
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).rentErrMsg, // 'Please enter rent'
      },
      canBeDeleted: TextProps.VALUE_TRUE,
      [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
        selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).anotherRentLabel, //"Add another rent"
    },
    {
      [Props.NAME]: "mortgage",

      [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).mortageTargetValue, //"Mortgage",
      [Props.TYPE]: Types.REPEATABLE_QUESTION,
      [ControlConstants.REPEATABLE_QUESTION]: {
        [Props.LABEL]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).mortageQLabel, // "How much is your household's mortgage?",
        [Props.TYPE]: Types.UNITS,
        [Props.INLINE_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortageInlineHelpText,
        [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortageunitBetween, //'per',
        [UnitsProps.UNITS]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortageQoptions, //['Week', 'Month', 'Year'],
        [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
        [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions[3],
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).mortageErrLabel, //'Please enter mortgage'
      },
      canBeDeleted: TextProps.VALUE_TRUE,
      [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
        selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).addMortageButtonLabel, //"Add another mortgage"
    },
    {
      [Props.NAME]: "property-taxes",
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).propertyTaxTargetValue, //"Property Taxes",
      [Props.TYPE]: Types.REPEATABLE_QUESTION,
      [ControlConstants.REPEATABLE_QUESTION]: {
        [Props.LABEL]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortageProptaxLabel, //"How much is your household's property taxes?",
        [Props.TYPE]: Types.UNITS,
        [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortagepropBetween, //'per',
        [UnitsProps.UNITS]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).mortagepropoptions, //['Week', 'Month', 'Year'],
        [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
        [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions[3],
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).propTaxLabel, //'Please enter property taxes'
      },
      canBeDeleted: TextProps.VALUE_TRUE,
      [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
        selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).propTaxButtonLabel, //"Add another property taxes"
    },
    {
      [Props.NAME]: "home-insurance-costs",
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).homeInsurTargetValue, //"Home Insurance",
      [Props.TYPE]: Types.REPEATABLE_QUESTION,
      [ControlConstants.REPEATABLE_QUESTION]: {
        [Props.LABEL]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
        [Props.TYPE]: Types.UNITS,
        [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).insuranceCostpropBetween, //'per',
        [UnitsProps.UNITS]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).insuranceCostoptions, //['Week', 'Month', 'Year'],
        [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
        [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions[3],
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).homeInsurErrmsg, //'Please enter home insurance'
      },
      canBeDeleted: TextProps.VALUE_TRUE,
      [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
        selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).homeInsurbutton, //"Add another home insurance"
    },
    {
      [Props.NAME]: "condo-fee",
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).condoFeeTargetValue, //"Condo Fee",
      [Props.TYPE]: Types.REPEATABLE_QUESTION,
      [ControlConstants.REPEATABLE_QUESTION]: {
        [Props.LABEL]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).condofeeQLabel, //"How much is your household's condo fee",
        [Props.TYPE]: Types.UNITS,
        [UnitsProps.IN_BETWEEN]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).condoFeeBeweenLabel, //'per',
        [UnitsProps.UNITS]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).condofeeCostoptions, // ['Week', 'Month', 'Year'],
        [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
        [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseUnitOptions[3],
        [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).condoFeeErrmsg, //'Please enter condo fee'
      },
      canBeDeleted: TextProps.VALUE_TRUE,
      [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
        selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).condoButtonLabel, //"Add another condo fee"
    },
  ],
};

export const selfDecExpensSubsections = [
  {
    id: "shelter-costs",
    title: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).houseUtlilityLabel,
    header: selfDeclarePageLabels(language, languageConstants.shelterCosts)
      .expenseHeader,
    subHeader: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpensesSummary
    ).expenseShelterSubHeader,
    questions: [
      {
        [Props.NAME]: "type",
        [Props.LABEL]: selfDeclarePageLabels(
          language,
          languageConstants.utilityCosts
        ).houseQLabel,
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.CLEAR_POSITION]: "top",
        [UnitsProps.CLEAR_OPTION]: selfDeclarePageLabels(
          language,
          languageConstants.shelterCosts
        ).shelterClearOption,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "RENT",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.shelterCosts
            ).houseQOptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: "MTGPRN",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.shelterCosts
            ).houseQOptions[1],
          },
          {
            [OptionsProps.OPTION_VALUE]: "PRPTAX",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.shelterCosts
            ).houseQOptions[2],
          },
          {
            [OptionsProps.OPTION_VALUE]: "HOMEIN",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.shelterCosts
            ).houseQOptions[3],
          },
          {
            [OptionsProps.OPTION_VALUE]: "CONFEE",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.shelterCosts
            ).houseQOptions[4],
          },
        ],
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).houseCostErrmsg, //'Please choose housing costs'
      },
    ],
    subsections: [
      {
        id: "housing-cost-detail",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).housingLabel, //'Housing and Utility Costs',
        header: selfDeclarePageLabels(language, languageConstants.shelterCosts)
          .expenseHeader, //'Tell us about your housing costs',
        subHeader: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).expenseShelterSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        questions: [
          {
            [Props.NAME]: "rent",
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENT",
            [Props.TYPE]: Types.REPEATABLE_QUESTION,
            [ControlConstants.REPEATABLE_QUESTION]: {
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).houseRentQLabel, //"How much is your household's rent?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).unitsPerLabel, //'per',
              [UnitsProps.UNITS]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions, //['Week', 'Month', 'Year'],
              [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
              [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions[3],
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).rentErrMsg, // 'Please enter rent'
              [ControlConstants.REPEATABLE_QUESTION_ACTION_REMOVE]:
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpensesSummary
                ).anotherRentLabel, //"Add another rent"
            },
            canBeDeleted: TextProps.VALUE_TRUE,
            [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).anotherRentLabel, //"Add another rent"
          },
          {
            [Props.NAME]: "mortgage",

            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "MTGPRN",
            [Props.TYPE]: Types.REPEATABLE_QUESTION,
            [ControlConstants.REPEATABLE_QUESTION]: {
              [Props.LABEL]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).mortageQLabel, // "How much is your household's mortgage?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).mortageunitBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).mortageQoptions, //['Week', 'Month', 'Year'],
              [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
              [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions[3],
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).mortageErrLabel, //'Please enter mortgage'
            },
            canBeDeleted: TextProps.VALUE_TRUE,
            [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).addMortageButtonLabel, //"Add another mortgage"
          },
          {
            [Props.NAME]: "property-taxes",
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "PRPTAX",
            [Props.TYPE]: Types.REPEATABLE_QUESTION,
            [ControlConstants.REPEATABLE_QUESTION]: {
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).mortageProptaxLabel, //"How much is your household's property taxes?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).mortagepropBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).mortagepropoptions, //['Week', 'Month', 'Year'],
              [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
              [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions[3],
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).propTaxLabel, //'Please enter property taxes'
            },
            canBeDeleted: TextProps.VALUE_TRUE,
            [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).propTaxButtonLabel, //"Add another property taxes"
          },
          {
            [Props.NAME]: "home-insurance-costs",
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "HOMEIN",
            [Props.TYPE]: Types.REPEATABLE_QUESTION,
            [ControlConstants.REPEATABLE_QUESTION]: {
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).insuranceCostpropBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).insuranceCostoptions, //['Week', 'Month', 'Year'],
              [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
              [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions[3],
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).homeInsurErrmsg, //'Please enter home insurance'
            },
            canBeDeleted: TextProps.VALUE_TRUE,
            [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).homeInsurbutton, //"Add another home insurance"
          },
          {
            [Props.NAME]: "condo-fee",
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CONFEE",
            [Props.TYPE]: Types.REPEATABLE_QUESTION,
            [ControlConstants.REPEATABLE_QUESTION]: {
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).condofeeQLabel, //"How much is your household's condo fee",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).condoFeeBeweenLabel, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).condofeeCostoptions, // ['Week', 'Month', 'Year'],
              [UnitsProps.ISNOTEMPTYOPTION]: TextProps.VALUE_TRUE,
              [OptionsProps.INITIAL_VALUE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).houseUnitOptions[3],
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).condoFeeErrmsg, //'Please enter condo fee'
            },
            canBeDeleted: TextProps.VALUE_TRUE,
            [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
              selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).condoButtonLabel, //"Add another condo fee"
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "dependent-care-costs",
    title: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).childTitle, //'Child or Adult Dependent Care Costs',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
    subHeader: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpensesSummary
    ).expenseShelterSubHeader, // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
    questions: [
      {
        [Props.NAME]: "caregivers",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).careGiveObjbefore,
          [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).careGiveObjtrigger, //'transport',
          [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
          [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).careGiveHelp,
          // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
        },
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: memberList,
        [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).questionClearOption, // 'None',
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "recipient-of-care-1",
                [Props.LABEL]:
                  "Who does John Doe (8.Aug.1967) pay dependent care for?",
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
              },
            ],
          },
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "recipient-of-care-2",
                [Props.LABEL]:
                  "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
                [Props.TYPE]: Types.CHECKBOX,
                [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
                [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).questionClearOption, //'None'
              },
            ],
          },
        ],
      },
    ],

    subsections: [
      {
        id: "kids-and-adults-marty",
        title: "Marty Smith (2.Jun.1994)",
        header: "Caring for Marty Smith (2.Jun.1994)",
        subHeader: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).expenseShelterSubHeader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
        questions: [
          {
            [Props.NAME]: "caring-kids-adults-cost-1",
            [Props.LABEL]:
              "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).freqTripoptions,
            [OptionsProps.TARGET_VALUE]: { unit: "Month" },
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          {
            [Props.NAME]: "if-pays-transportation-1",
            [Props.LABEL]:
              "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).yesnoOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).targetValueYes,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "transportation-cost-1",
                    [Props.LABEL]:
                      "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                    [Props.TYPE]: Types.UNITS,
                    [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).costBetween,
                    [UnitsProps.UNITS]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic,
                      null,
                      null,
                      null
                    ).freqTripoptions,
                    [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                    [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                  },
                ],
              },
            ],
          },
          {
            [Props.NAME]: "drive-to-provider-1",
            [Props.LABEL]:
              "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
            [Props.TYPE]: Types.RADIO,
            [OptionsProps.OPTIONS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).yesnoOptions,
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            [OptionsProps.CONDITIONALS]: [
              {
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).targetValueYes,
                [OptionsProps.CONDITIONAL_QUESTIONS]: [
                  {
                    [Props.NAME]: "driving-address-1",
                    [Props.LABEL]:
                      "How many addresses, does John drive to medical appointments or the pharmacy?",
                    [Props.TYPE]: Types.NUMBERS,
                    [Props.ERROR]: TextProps.VALUE_FALSE,
                    [Props.ERROR_MESSAGE]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).noofAppointment,
                  },
                ],
              },
            ],
          },
        ],

        subsections: [
          {
            id: "kids-adult-multiple-address",
            title: "Marty Smith (2.Jun.1994)",
            header: "Caring for Marty Smith (2.Jun.1994)",
            subHeader: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).expenseShelterSubHeader,
            //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
            hiddenFromNav: TextProps.VALUE_TRUE,
            questions: [
              {
                [Props.NAME]: "address",
                [Props.LABEL]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic,
                  null,
                  null,
                  null
                ).addressProvider, //'What is the address of the provider?',
                [Props.TYPE]: Types.ADDRESS,
                [OptionsProps.TARGET_VALUE]: { state: TextProps.DEFAULT_STATE },
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]: interimReportPageLabels(
                  language,
                  languageConstants.aboutMe
                ).addressErrmsg,
                [Props.ERROR_MESSAGE_FOR_CHAR]: interimReportPageLabels(
                  language,
                  languageConstants.aboutMe
                ).citiErrmsg,
                [Props.ERROR_MESSAGE_FOR_ADDRESS]: interimReportPageLabels(
                  language,
                  languageConstants.aboutMe
                ).addressErrmsg,
                [Props.ERROR_MESSAGE_FOR_ZIPCODE]: interimReportPageLabels(
                  language,
                  languageConstants.aboutMe
                ).zipCodeErrmsg,
              },
              {
                [Props.NAME]: "frequency-trips-1",
                [Props.LABEL]:
                  "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
                [Props.TYPE]: Types.UNITS,
                [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic,
                  null,
                  null,
                  null
                ).costBetween, //'per',
                [UnitsProps.UNITS]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic,
                  null,
                  null,
                  null
                ).freqTripoptions, //['Week', 'Month', 'Year'],
                [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE_LENGTH]: interimReportPageLabels(
                  language,
                  languageConstants.aboutMe
                ).tripCountErrMsgLen,
              },
            ],
          },
        ],
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
  {
    id: "utility-costs",
    title: selfDeclarePageLabels(
      language,
      languageConstants.selfDeclareExpenses
    ).utilityCostSelf,
    header: selfDeclarePageLabels(language, languageConstants.utilityCosts)
      .expenseSelfHeader,
    questions: [
      {
        [Props.NAME]: "utilities",
        [Props.LABEL]: selfDeclarePageLabels(
          language,
          languageConstants.utilityCosts
        ).expenseSubHeader, //'Does your household have any utility costs?',
        [Props.TYPE]: Types.CHECKBOX,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: "payHeat",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.utilityCosts
            ).utilityQOptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: "payAC",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.utilityCosts
            ).utilityQOptions[1],
          },
          {
            [OptionsProps.OPTION_VALUE]: "payACFee",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.utilityCosts
            ).utilityQOptions[2],
          },
          {
            [OptionsProps.OPTION_VALUE]: "elgas",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.utilityCosts
            ).utilityQOptions[3],
          },
          {
            [OptionsProps.OPTION_VALUE]: "hasPhone",
            [OptionsProps.OPTION_DISPLAY]: selfDeclarePageLabels(
              language,
              languageConstants.utilityCosts
            ).utilityQOptions[4],
          },
        ],
        [OptionsProps.CLEAR_POSITION]: "top",
        [OptionsProps.CLEAR_OPTION]: selfDeclarePageLabels(
          language,
          languageConstants.utilityCosts
        ).utilitiesClearOption,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
          language,
          languageConstants.selfDeclareExpensesSummary
        ).chooseHoldErrmsg, //'Please choose household utilities'
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];

export const dependentCareTemplate = {
  id: "dependent-care-costs",
  title: selfDeclarePageLabels(language, languageConstants.selfDeclareExpenses)
    .childTitle, //'Child or Adult Dependent Care Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
  subHeader: selfDeclarePageLabels(
    language,
    languageConstants.selfDeclareExpensesSummary
  ).expenseShelterSubHeader, // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjbefore,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjtrigger, //'transport',
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveHelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: memberList,
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption, // 'None',
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).questionClearOption, //'None'
            },
          ],
        },
      ],
    },
  ],

  subsections: [
    {
      id: "kids-and-adults-marty",
      title: "Marty Smith (2.Jun.1994)",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).expenseShelterSubHeader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
            "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).costBetween,
          [UnitsProps.UNITS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).freqTripoptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Month" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).freqTripoptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],

      subsections: [
        {
          id: "kids-adult-multiple-address",
          title: "Marty Smith (2.Jun.1994)",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader: selfDeclarePageLabels(
            language,
            languageConstants.selfDeclareExpensesSummary
          ).expenseShelterSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
              [OptionsProps.TARGET_VALUE]: { state: TextProps.DEFAULT_STATE },
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).zipCodeErrmsg,
              [Props.ERROR_MESSAGE_FOR_CHAR]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).citiErrmsg,
              [Props.ERROR_MESSAGE_FOR_ADDRESS]: interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addressErrmsg,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const householdTemplate = {
  id: "household-member",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseMemberTitle,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandMaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandFaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandDaugther,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citizenHelp,
      },
      [Props.INLINE_HELP]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citizenInhelp,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [InputProps.CLEAR_LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnErrLabel,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const householdDisabilityTemplate = {
  id: "household-details",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .hholdHeader,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseHeader,
  questions: [
    {
      [Props.NAME]: "disability",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQTriggerLabel,
        [Props.LABEL_OBJ_AFTER]:
          interimReportPageLabels(language, languageConstants.householdStatic)
            .disabilityQlabel2 + "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityHelp,
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).disablilityCler,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const IncomeSectionTemplate = {
  id: "combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "EI-WAGES",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-SELEMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EI-FEDWRK",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-SSI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RSDI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-UNEMPL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-CHSDOR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-EMPPEN",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-VETBPR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-RENTAL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-WRKCMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UI-OTHER",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).combineIncomeoptions[11],
        },
      ],
      [OptionsProps.CLEAR_OPTION]: selfDeclarePageLabels(
        language,
        languageConstants.utilityCosts
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "wages-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-WAGES", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
          [OptionsProps.TABLE_DSC]: "EI-WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "wages-gross-income-0",
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Wages?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).labelOtherwage, //'Add another Wage'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.TABLE_DSC]: "EI-SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Self-Employmentgfgf?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).adsSelfEmployee, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "work-study-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EI-FEDWRK", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
          [OptionsProps.TABLE_DSC]: "EI-FEDWRK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Work Study?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workStudyLabel, //'Add another Work Study'
        },
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-SSI", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherSSILabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "social-security-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addRDILabel, //'Add another RSDI'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Unemployment?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unEmpLabel, //'Add another Unemployment'
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Child Support?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-EMPPEN", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-EMPPEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) pension?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherPensionLabel, // 'Add another Pension'
        },
        {
          [Props.NAME]: "veterans-benefits-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-VETBPR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-VETBPR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
        },
        {
          [Props.NAME]: "rental-income-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//'Rental Income',
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Rental Income?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).repeatQuestionLabel, //"Add another Rental Income"
        },
        {
          [Props.NAME]: "rental-income-hours-worked-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]:
              "How many hours does John Doe (8.Aug.1967) work on the Rental Income property?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).weekMonthOptions, //['Month', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).repeatincomeQuestionLabel,
        },
        {
          [Props.NAME]: "workers-compensation-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UI-WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Worker's Compensation?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workerCompensationLabel,
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UI-OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
          [OptionsProps.TABLE_DSC]: "UI-OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) other income or benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).questionButtonLabel, //"Add another Other"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const incomeSummaryTemplate = {
  id: "income-summary",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const expenseHousingCostTemplate = {
  id: "housing-costs",
  title: selfDeclarePageLabels(language, languageConstants.selfDeclareExpenses)
    .houseUtlilityLabel,
  header: selfDeclarePageLabels(language, languageConstants.shelterCosts)
    .expenseHeader,
  subHeader: selfDeclarePageLabels(language, languageConstants.utilityCosts)
    .expenseSubHeader,
  questions: [
    {
      [Props.NAME]: "utilities",
      [Props.LABEL]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).anyUtilQcost, //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,

      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "payHeat",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payAC",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payACFee",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "elgas",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "hasPhone",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[4],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: selfDeclarePageLabels(
        language,
        languageConstants.utilityCosts
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpensesSummary
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
    {
      [Props.NAME]: "type",
      [Props.LABEL]: selfDeclarePageLabels(
        language,
        languageConstants.utilityCosts
      ).houseQLabel, //'What type of housing cost does your household have?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "RENT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MORTGAGE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PROPERTY_TAX",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "HOME_INSURANCE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONDO_FEE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[4],
        },
      ],
      [UnitsProps.CLEAR_OPTION]: selfDeclarePageLabels(
        language,
        languageConstants.utilityCosts
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpensesSummary
      ).houseCostErrmsg, //'Please choose housing costs'
    },
  ],
  subsections: [
    {
      id: "housing-cost-detail",
      title: selfDeclarePageLabels(
        language,
        languageConstants.selfDeclareExpenses
      ).houseUtlilityLabel, //'Housing and Utility Costs',
      header: selfDeclarePageLabels(language, languageConstants.shelterCosts)
        .expenseHeader, //'Tell us about your housing costs',
      subHeader: selfDeclarePageLabels(language, languageConstants.utilityCosts)
        .expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
      completed: TextProps.VALUE_FALSE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).rentTargetValue, //"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).houseUnitOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).mortageTargetValue, //"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortageunitBetween, //'per',
            [UnitsProps.UNITS]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortageQoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).propertyTaxTargetValue, //"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortagepropBetween, //'per',
            [UnitsProps.UNITS]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).mortagepropoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).homeInsurTargetValue, //"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).insuranceCostoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).condoFeeTargetValue, //"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condofeeCostoptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpensesSummary
            ).condoButtonLabel, //"Add another condo fee"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseKidsAndAdultsTemplate = {
  id: "kids-and-adults",
  title: selfDeclarePageLabels(language, languageConstants.selfDeclareExpenses)
    .childTitle, //'Child or Adult Dependent Care Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostSubHeader, // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjbefore,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjtrigger, //'transport',
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).careGiveHelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption, // 'None',
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).questionClearOption, //'None'
            },
          ],
        },
      ],
    },
  ],
  subsections: [
    {
      id: "kids-and-adults-marty",
      title: "Marty Smith (2.Jun.1994)",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
            "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).costBetween,
          [UnitsProps.UNITS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).freqTripoptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Month" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).freqTripoptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      subsections: [
        {
          id: "kids-adult-multiple-address",
          title: "Marty Smith (2.Jun.1994)",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader: selfDeclarePageLabels(
            language,
            languageConstants.utilityCosts
          ).expenseSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.REQUIRED]: TextProps.VALUE_TRUE,
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseChildSupportTemplate = {
  id: "child-support",
  title: selfDeclarePageLabels(language, languageConstants.medicalCosts)
    .childSupTitle, //'Child Support Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).tellusAbout, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "with-child-support",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).childSupportQlegalbefore, // 'Does anyone in the household have a legal obligation (court order) to pay ',
        [Props.LABEL_OBJ_TRIGGER]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).childSupportQlegaltrigger, //'child support',
        [Props.LABEL_OBJ_AFTER]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).childSupportQlegalAfter, //' to a child not living with you?',
        [Props.LABEL_OBJ_HELP]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).childSupportHelp,
        //'Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption,
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-1",
              [Props.LABEL]:
                "What is the child support expense for John Doe (8.Aug.1967)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-2",
              [Props.LABEL]:
                "What is the child support expense for Marty Smith (2.Jun.1994)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseMedicalExpenseTemplate = {
  id: "medical-expenses",
  title: selfDeclarePageLabels(language, languageConstants.selfDeclareExpenses)
    .medicalTitle, //'Medical Costs',
  header: selfDeclarePageLabels(language, languageConstants.medicalCosts)
    .medicalHeader, //"Tell us about your medical costs",
  subHeader: selfDeclarePageLabels(language, languageConstants.medicalCosts)
    .medicalSubHeader,
  // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "with-medical-expenses",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).medicalExpBefore,
        //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
        [Props.LABEL_OBJ_TRIGGER]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).medicalExpTrigger,
        [Props.LABEL_OBJ_AFTER]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).medicalExpAfter,
        // ', over the counter medications, dental or eye care, adult diapers, etc.?',
        [Props.LABEL_OBJ_HELP]: selfDeclarePageLabels(
          language,
          languageConstants.medicalCosts
        ).medicalExphelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]:
        selfDeclarePageLabels(language, languageConstants.medicalCosts)
          .medicalInlineSeethisFull +
        ' <a href="https://www.mass.gov/files/documents/2017/10/02/SNAP-MEB-English-0815.pdf" target="_blank" rel="noopener noreferrer">' +
        selfDeclarePageLabels(language, languageConstants.medicalCosts)
          .medicalInlineEligibleMedical +
        "</a>.",
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption,
    },
  ],
  subsections: [
    {
      id: "medical-expenses-1",
      title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
      subHeader: selfDeclarePageLabels(language, languageConstants.utilityCosts)
        .expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "driving-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy??",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
      subsections: [
        {
          id: "medical-address-1",
          title: "Medical Appointment for John Doe",
          header: "Medical Appointment for John Doe",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: selfDeclarePageLabels(
                language,
                languageConstants.medicalCosts
              ).medicalAddress,
              // 'What is the address of the medical appointment or pharmacy?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: selfDeclarePageLabels(
                language,
                languageConstants.medicalCosts
              ).zipErrmsg, //'Please enter a valid Zipcode'
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
            {
              [Props.NAME]: "cost-trips-1",
              [Props.LABEL]:
                "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const ExpenseSectionTemplate = {
  id: "expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .expenseTitle, //'Expenses',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).almostLabel,
  subsections: [
    expenseHousingCostTemplate,
    expenseKidsAndAdultsTemplate,
    expenseChildSupportTemplate,
    expenseMedicalExpenseTemplate,
  ],
  completed: TextProps.VALUE_FALSE,
};
