import PropTypes from "prop-types";
import * as TextProps from "../../utils/constants/text";

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];

      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
            return conditionQuestion;
          });
          return condition;
        });
      }
    } else {
      if (
        (AnswerObj[activeId] === undefined && question.Answer === undefined) ||
        question.Answer === undefined
      ) {
        question.Answer = "";
        question.targetValue = "";
      }
    }
    return question;
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "about-me") {
        buildApplyAboutMeValidations(inputSections);
      } else if (activeId === "add-contact-info") {
        buildApplyContactInfoValidations(inputSections);
      } else if (activeId === "emergency-snap") {
        buildApplyEmergencyValidations(inputSections);
      } else if (activeId === "more-info") {
        buildApplyMoreInfoValidations(inputSections);
      } else if (activeId === "optional") {
        buildApplyOptionalValidations(inputSections);
      } else if (activeId === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId.substring(0, 16) === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId.substring(0, 15) === "medical-address") {
        buildApplyMediacalAddressValidations(inputSections);
      } else if (activeId.substring(0, 27) === "kids-adult-multiple-address") {
        buildApplyKidsAdultAddressValidations(inputSections);
      }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (let answerNotElected of dataSections) {
      if (
        (answerNotElected.type === "date" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "")) ||
        (answerNotElected.type === "inputSSN" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "")) ||
        (answerNotElected.type === "inputPhone" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "")) ||
        (answerNotElected.type === "inputText" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "") &&
          answerNotElected.name === "emailAddress") ||
        (answerNotElected.type === "address" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "") &&
          answerNotElected.name === "address") ||
        answerNotElected.isRequired === TextProps.VALUE_TRUE ||
        (answerNotElected.type === "address" &&
          (answerNotElected.error === TextProps.VALUE_TRUE ||
            answerNotElected.Answer !== "")) ||
        ((answerNotElected.type === "inputText" ||
          answerNotElected.type === "inputAlphaText") &&
          answerNotElected.error === TextProps.VALUE_TRUE)
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        dataID.substring(0, 27) === "kids-adult-multiple-address" ||
        dataID.substring(0, 15) === "medical-address"
      ) {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (dataID === "add-contact-info") {
        var answerNotElectedMailingAddress =
          dataSections[2].conditionals[0].conditionalQuestions[0];
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnwsers = [];
  if (sections !== undefined) {
    const dataSections = sections.questions;
    for (let answerElected of dataSections) {
      if (
        (answerElected.type === "date" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputSSN" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputPhone" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputText" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "emailAddress") ||
        (answerElected.type === "address" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "address") ||
        (answerElected.Answer !== "" &&
          answerElected.isRequired === TextProps.VALUE_TRUE &&
          answerElected.type !== "address" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.Answer !== "" &&
          answerElected.isRequired === TextProps.VALUE_TRUE &&
          answerElected.type === "address" &&
          answerElected.name === "address" &&
          answerElected.Answer !== undefined &&
          answerElected.Answer.state !== "" &&
          answerElected.Answer.city !== "" &&
          answerElected.Answer.street !== "" &&
          answerElected.Answer.zipCode !== "")
      ) {
        totalAnwsers.push(answerElected.Answer);
      }
    }
  }
  // checking required fields and total touched fileds
  if (sections !== undefined && totalReqFields.length !== totalAnwsers.length) {
    return TextProps.VALUE_FALSE;
  } else {
    return TextProps.VALUE_TRUE;
  }
}

function buildApplyHouseholdMembersValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let householdMembersVal of dataSections) {
      console.log(householdMembersVal);
      if (householdMembersVal.type === "select") {
        if (
          householdMembersVal.Answer.length < 1 &&
          householdMembersVal.Answer !== null &&
          householdMembersVal.isRequired === TextProps.VALUE_TRUE
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
        } else {
          householdMembersVal.error = TextProps.VALUE_FALSE;
        }
      } else if (
        householdMembersVal.type === "inputText" ||
        householdMembersVal.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexNumber = /\d+/;
        var rexSpace = /^(?=.*\S).+$/;
        if (
          householdMembersVal.Answer.length < 1 &&
          householdMembersVal.isRequired === TextProps.VALUE_TRUE
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          householdMembersVal.Answer.length >= 1 &&
          (rexNumber.test(householdMembersVal.Answer) ===
            TextProps.VALUE_TRUE ||
            rexSpace.test(householdMembersVal.Answer) === TextProps.VALUE_FALSE)
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          householdMembersVal.error = TextProps.VALUE_FALSE;
        }
      } else if (householdMembersVal.type === "date") {
        if (PropTypes.date_error === 1) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
        } else if (
          (householdMembersVal.Answer === "" ||
            householdMembersVal.Answer === null) &&
          householdMembersVal.isRequired === TextProps.VALUE_TRUE
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
        } else if (
          (householdMembersVal.Answer !== "" ||
            householdMembersVal.Answer === null) &&
          householdMembersVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var inputDate = householdMembersVal.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            householdMembersVal.error = TextProps.VALUE_TRUE;
          } else {
            householdMembersVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          householdMembersVal.error = TextProps.VALUE_FALSE;
        }
      } else if (householdMembersVal.type === "inputSSN") {
        if (
          householdMembersVal.Answer.length < 1 &&
          householdMembersVal.isRequired === TextProps.VALUE_TRUE
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
        } else if (
          householdMembersVal.Answer.length > 0 &&
          householdMembersVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (
            regSSN.test(householdMembersVal.Answer) === TextProps.VALUE_FALSE
          ) {
            householdMembersVal.error = TextProps.VALUE_TRUE;
          } else {
            householdMembersVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          householdMembersVal.error = TextProps.VALUE_FALSE;
        }
      } else if (householdMembersVal.type === "radio") {
        if (
          householdMembersVal.Answer === "" &&
          householdMembersVal.isRequired === TextProps.VALUE_TRUE
        ) {
          householdMembersVal.error = TextProps.VALUE_TRUE;
        } else {
          householdMembersVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyOptionalValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let optionalVal of dataSections) {
      if (optionalVal.type === "radio" || optionalVal.type === "checkbox") {
        if (
          optionalVal.Answer === "" &&
          optionalVal.isRequired === TextProps.VALUE_TRUE
        ) {
          optionalVal.error = TextProps.VALUE_TRUE;
        } else {
          optionalVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyAboutMeValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let aboutMeVal of dataSections) {
      if (aboutMeVal.type === "select") {
        if (
          aboutMeVal.Answer.length < 1 &&
          aboutMeVal.Answer !== null &&
          aboutMeVal.isRequired === TextProps.VALUE_TRUE
        ) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
        } else {
          aboutMeVal.error = TextProps.VALUE_FALSE;
        }
      } else if (
        aboutMeVal.type === "inputText" ||
        aboutMeVal.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          aboutMeVal.Answer.length >= 0 &&
          aboutMeVal.isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(aboutMeVal.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(aboutMeVal.Answer) === TextProps.VALUE_FALSE
          ) {
            aboutMeVal.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          aboutMeVal.Answer.length > 0 &&
          aboutMeVal.isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(aboutMeVal.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(aboutMeVal.Answer) === TextProps.VALUE_FALSE
          ) {
            aboutMeVal.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (aboutMeVal.name === "lastName" && aboutMeVal.Answer.length > 20) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
          aboutMeVal.errorMessage = aboutMeVal.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (aboutMeVal.name !== "lastName" && aboutMeVal.Answer.length > 15) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
          aboutMeVal.errorMessage = aboutMeVal.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          aboutMeVal.error = TextProps.VALUE_FALSE;
        }
      } else if (aboutMeVal.type === "date") {
        if (PropTypes.date_error === 1) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
        } else if (
          (aboutMeVal.Answer === "" || aboutMeVal.Answer === null) &&
          aboutMeVal.isRequired === TextProps.VALUE_TRUE
        ) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
        } else if (
          (aboutMeVal.Answer !== "" || aboutMeVal.Answer === null) &&
          aboutMeVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var inputDate = aboutMeVal.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            aboutMeVal.error = TextProps.VALUE_TRUE;
          } else {
            aboutMeVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          aboutMeVal.error = TextProps.VALUE_FALSE;
        }
      } else if (aboutMeVal.type === "inputSSN") {
        if (
          aboutMeVal.Answer.length < 1 &&
          aboutMeVal.isRequired === TextProps.VALUE_TRUE
        ) {
          aboutMeVal.error = TextProps.VALUE_TRUE;
        } else if (
          aboutMeVal.Answer.length > 0 &&
          aboutMeVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (regSSN.test(aboutMeVal.Answer) === TextProps.VALUE_FALSE) {
            aboutMeVal.error = TextProps.VALUE_TRUE;
          } else {
            aboutMeVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          aboutMeVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyContactInfoValidations(dataSections) {
  if (dataSections !== undefined) {
    let errorFlag = TextProps.VALUE_FALSE;
    for (let [index, applyContactInfoVal] of dataSections.entries()) {
      if (applyContactInfoVal.type === "radio" && index === 2) {
        var mailingAddressObj = {};
        applyContactInfoVal.conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        if (applyContactInfoVal.targetValue === TextProps.VALUE_STR_FALSE) {
          mailingAddressObj =
            applyContactInfoVal.conditionals[0].conditionalQuestions[0];
          if (
            mailingAddressObj.Answer ===
              "" /* && mailingAddressObj.isRequired === TextProps.VALUE_TRUE */ ||
            mailingAddressObj.Answer.zipCode === "" ||
            mailingAddressObj.Answer.zipCode.length < 5 ||
            mailingAddressObj.Answer.state === "" ||
            mailingAddressObj.Answer.street === ""
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
            errorFlag = TextProps.VALUE_TRUE;
          } else if (mailingAddressObj.Answer.city === "") {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.citiErrmsg;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
      }

      if (
        (applyContactInfoVal.type === "radio" && index !== 2) ||
        (applyContactInfoVal.type === "text" &&
          applyContactInfoVal.name === "emailAddress" &&
          applyContactInfoVal.Answer !== undefined)
      ) {
        if (
          applyContactInfoVal.Answer === "" &&
          applyContactInfoVal.isRequired === TextProps.VALUE_TRUE
        ) {
          applyContactInfoVal.error = TextProps.VALUE_TRUE;
        } else {
          applyContactInfoVal.error = TextProps.VALUE_FALSE;
        }
      } else if (applyContactInfoVal.type === "inputText") {
        if (
          (applyContactInfoVal.Answer.length > 0 ||
            applyContactInfoVal.Answer !== "") &&
          applyContactInfoVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var emailRegEx =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          if (
            emailRegEx.test(applyContactInfoVal.Answer) ===
            TextProps.VALUE_FALSE
          ) {
            applyContactInfoVal.error = TextProps.VALUE_TRUE;
            applyContactInfoVal.Answer = "";
          } else {
            applyContactInfoVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          applyContactInfoVal.error = TextProps.VALUE_FALSE;
        }
      } else if (applyContactInfoVal.type === "inputPhone") {
        if (
          (applyContactInfoVal.Answer.length > 0 ||
            applyContactInfoVal.Answer !== "") &&
          !isNaN(applyContactInfoVal.Answer) &&
          applyContactInfoVal.isRequired === TextProps.VALUE_FALSE
        ) {
          var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          if (
            phoneno.test(applyContactInfoVal.Answer) === TextProps.VALUE_FALSE
          ) {
            applyContactInfoVal.error = TextProps.VALUE_TRUE;
            applyContactInfoVal.Answer = "";
          } else {
            applyContactInfoVal.error = TextProps.VALUE_FALSE;
          }
        } else {
          applyContactInfoVal.error = TextProps.VALUE_FALSE;
        }
      } else if (
        applyContactInfoVal.type === "address" &&
        applyContactInfoVal.name === "address"
      ) {
        if (
          applyContactInfoVal.Answer === "" ||
          applyContactInfoVal.Answer.zipCode === "" ||
          applyContactInfoVal.Answer.city === "" ||
          applyContactInfoVal.Answer.state === "" ||
          applyContactInfoVal.Answer.street === ""
        ) {
          applyContactInfoVal.error = TextProps.VALUE_TRUE;
          applyContactInfoVal.errorMessage = applyContactInfoVal.addressErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        } else if (
          applyContactInfoVal.isRequired === TextProps.VALUE_TRUE &&
          applyContactInfoVal.Answer.zipCode !== undefined &&
          applyContactInfoVal.Answer.city !== undefined
        ) {
          var regExCity = /^[A-Za-z' ]+$/;
          if (applyContactInfoVal.Answer.zipCode.length < 5) {
            applyContactInfoVal.error = TextProps.VALUE_TRUE;
            applyContactInfoVal.Answer = "";
            errorFlag = TextProps.VALUE_TRUE;
          }
          if (
            regExCity.test(applyContactInfoVal.Answer.city) ===
            TextProps.VALUE_FALSE
          ) {
            applyContactInfoVal.error = TextProps.VALUE_TRUE;
            applyContactInfoVal.Answer = "";
            applyContactInfoVal.errorMessage = applyContactInfoVal.citiErrmsg;
            errorFlag = TextProps.VALUE_TRUE;
          }
        } else {
          applyContactInfoVal.error = TextProps.VALUE_FALSE;
        }
        if (!errorFlag) {
          applyContactInfoVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMediacalAddressValidations(dataSections) {
  for (let medicalAddrVal of dataSections) {
    if (medicalAddrVal.type === "address") {
      let errorFlag = TextProps.VALUE_FALSE;
      if (
        medicalAddrVal.Answer === "" ||
        medicalAddrVal.Answer.zipCode === "" ||
        medicalAddrVal.Answer.city === "" ||
        medicalAddrVal.Answer.state === "" ||
        medicalAddrVal.Answer.street === ""
      ) {
        medicalAddrVal.error = TextProps.VALUE_TRUE;
        medicalAddrVal.errorMessage = medicalAddrVal.addressErrmsg;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (
        medicalAddrVal.Answer.zipCode !== "" &&
        medicalAddrVal.isRequired === TextProps.VALUE_FALSE &&
        medicalAddrVal.Answer.zipCode !== undefined
      ) {
        if (medicalAddrVal.Answer.zipCode.length < 5) {
          medicalAddrVal.error = TextProps.VALUE_TRUE;
          medicalAddrVal.Answer = "";
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        medicalAddrVal.Answer.city !== "" &&
        medicalAddrVal.isRequired === TextProps.VALUE_FALSE &&
        medicalAddrVal.Answer.city !== undefined
      ) {
        var rex = /^[A-Za-z' ]+$/;
        if (rex.test(medicalAddrVal.Answer.city) === TextProps.VALUE_FALSE) {
          medicalAddrVal.error = TextProps.VALUE_TRUE;
          medicalAddrVal.Answer = "";
          medicalAddrVal.errorMessage = medicalAddrVal.citiErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        medicalAddrVal.error = TextProps.VALUE_FALSE;
      }
    }
    if (
      medicalAddrVal.Answer.amount !== "" &&
      medicalAddrVal.Answer.amount !== undefined
    ) {
      let errorFlag = TextProps.VALUE_FALSE;
      var numRegEx = /^[0-9]+$/;
      if (
        numRegEx.test(medicalAddrVal.Answer.amount) === TextProps.VALUE_FALSE
      ) {
        medicalAddrVal.error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (medicalAddrVal.Answer.amount.length > 6) {
        medicalAddrVal.error = TextProps.VALUE_TRUE;
        medicalAddrVal.errorMessage = medicalAddrVal.errorMessageLength;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        medicalAddrVal.error = TextProps.VALUE_FALSE;
      }
    }
  }
}

function buildApplyKidsAdultAddressValidations(dataSections) {
  for (let addressVal of dataSections) {
    if (addressVal.type === "address") {
      addressVal.error = TextProps.VALUE_TRUE;
      if (
        addressVal.Answer === "" ||
        addressVal.Answer.street === "" ||
        addressVal.Answer.street === undefined ||
        addressVal.Answer.state === "" ||
        addressVal.Answer.state === undefined
      ) {
        addressVal.error = TextProps.VALUE_TRUE;
        addressVal.errorMessage = addressVal.addressErrmsg;
      } else if (
        addressVal.Answer.zipCode === "" ||
        addressVal.Answer.zipCode === undefined ||
        addressVal.Answer.zipCode.length < 5
      ) {
        addressVal.error = TextProps.VALUE_TRUE;
        addressVal.errorMessage = addressVal.zipCodeErrmsg;
      } else if (
        addressVal.Answer.city === "" ||
        addressVal.Answer.city === undefined
      ) {
        var alphaRex = /^[A-Za-z' ]+$/;
        if (alphaRex.test(addressVal.Answer.city) === TextProps.VALUE_FALSE) {
          addressVal.error = TextProps.VALUE_TRUE;
          addressVal.errorMessage = addressVal.citiErrmsg;
        }
      } else {
        addressVal.error = TextProps.VALUE_FALSE;
        addressVal.errorMessage = "";
      }
    }
    if (
      addressVal.Answer.amount !== "" &&
      addressVal.Answer.amount !== undefined
    ) {
      var rex = /^[0-9]+$/;
      let errorFlag = TextProps.VALUE_FALSE;
      if (rex.test(addressVal.Answer.amount) === TextProps.VALUE_FALSE) {
        addressVal.error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (addressVal.Answer.amount.length > 6) {
        addressVal.error = TextProps.VALUE_TRUE;
        addressVal.errorMessage = addressVal.errorMessageLength;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        addressVal.error = TextProps.VALUE_FALSE;
      }
    }
  }
}
function buildApplyEmergencyValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let emergencyVal of dataSections) {
      if (emergencyVal.type === "radio") {
        if (
          emergencyVal.Answer === "" &&
          emergencyVal.isRequired === TextProps.VALUE_TRUE
        ) {
          emergencyVal.error = TextProps.VALUE_TRUE;
        } else {
          emergencyVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMoreInfoValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let applyMoreInfoVal of dataSections) {
      if (applyMoreInfoVal.type === "radio") {
        if (
          applyMoreInfoVal.Answer === "" &&
          applyMoreInfoVal.isRequired === TextProps.VALUE_TRUE
        ) {
          applyMoreInfoVal.error = TextProps.VALUE_TRUE;
        } else {
          applyMoreInfoVal.error = TextProps.VALUE_FALSE;
        }
      } else if (applyMoreInfoVal.type === "select") {
        if (
          applyMoreInfoVal.Answer.length < 1 &&
          applyMoreInfoVal.Answer !== null &&
          applyMoreInfoVal.isRequired === TextProps.VALUE_TRUE
        ) {
          applyMoreInfoVal.error = TextProps.VALUE_TRUE;
        } else {
          applyMoreInfoVal.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions
) {
  let selectedQuestions = [];
  for (let answer of activePageAnswers) {
    for (let question of nextPageQuestions) {
      if (answer === question.valueToMatch) {
        selectedQuestions.push(question);
      }
    }
  }
  return selectedQuestions;
}
