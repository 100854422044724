import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../../utils/components/header";
import { mapStateToProps } from "../../../redux/rootReducer";

class ConsumerAccount extends Component {
  constructor() {
    super();
    this.postConsumerContactInfo = this.postConsumerContactInfo.bind(this);
  }

  postConsumerContactInfo(event) {
    event.preventDefault();
    this.props.consumer.consumerData[0].CON_RES_ADDR =
      document.getElementById("consumer_res_addr").value;
    this.props.consumer.consumerData[0].CON_MAIL_ADDR =
      document.getElementById("consumer_mail_addr").value;
    this.props.consumer.consumerData[0].CON_PHONE_NUM =
      document.getElementById("consumer_phone").value;
    this.props.consumer.consumerData[0].CON_EMAIL_ADDR =
      document.getElementById("consumer_email").value;
    this.props.postConsumerContactInfo(this.props.consumer.consumerData[0]);
  }

  componentDidMount() {
    this.props.onGetConsumerContactInfo();
  }

  render() {
    const { consumer } = this.props.language;
    const { consumerData } = this.props.consumer;
    return (
      <div className="App">
        <Header />
        <form id="consumer-contact-info-container">
          <table>
            <br></br>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>{consumer.account.household_head}</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <label>
                  {consumerData[0] !== undefined
                    ? consumerData[0].CON_HOUSE_HEAD
                    : ""}
                </label>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>DHR ID</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <label>
                  {consumerData[0] !== undefined
                    ? consumerData[0].CON_DTA_ID
                    : ""}
                </label>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>{consumer.account.res_addr}</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <textarea
                  rows="3"
                  cols="30"
                  id="consumer_res_addr"
                  type="text"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_RES_ADDR
                      : ""
                  }
                ></textarea>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>{consumer.account.mail_addr}</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <textarea
                  rows="3"
                  cols="30"
                  id="consumer_mail_addr"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_MAIL_ADDR
                      : ""
                  }
                ></textarea>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>{consumer.account.phone_num}</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <textarea
                  rows="3"
                  cols="30"
                  type="text"
                  id="consumer_phone"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_PHONE_NUM
                      : ""
                  }
                ></textarea>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>
                <label>
                  <b>{consumer.account.email_addr}</b>
                </label>
              </td>
              <td>&emsp; &emsp;</td>
              <td>
                <textarea
                  rows="3"
                  cols="30"
                  type="text"
                  id="consumer_email"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_EMAIL_ADDR
                      : ""
                  }
                ></textarea>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>&emsp; &emsp;</td>
              <td>&emsp; &emsp;</td>
              <td>
                <input
                  type="checkbox"
                  id="consumer_sms_notify"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_SMS_NOTIFICATION
                      : ""
                  }
                />
                <label> {consumer.account.sms_notification} </label>
              </td>
            </tr>
            <br></br>
            <tr>
              <td>&emsp; &emsp;</td>
              <td>&emsp; &emsp;</td>
              <td>
                <input
                  type="checkbox"
                  id="consumer_email_notify"
                  value={
                    consumerData[0] !== undefined
                      ? consumerData[0].CON_EMAIL_NOTIFICATION
                      : ""
                  }
                />
                <label> {consumer.account.email_notification} </label>
              </td>
            </tr>
            <br></br> <br></br>
            <tr>
              <td>&emsp; &emsp;</td>
              <td>
                <button onClick={this.postConsumerContactInfo}>Update</button>
              </td>
              <td>&emsp; &emsp;</td>
            </tr>
          </table>
        </form>
        <div></div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerAccount);
