import React, { Component } from "react";
import ReactPasswordStrength from "react-password-strength";
import { CommonMultiLanguage } from "../appText/commonMultiLanguage";
import * as TextProps from "../constants/text";
import { langOptSel } from "../constants/constants";
let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null || selectedLanguage === langOptSel) {
  selectedLanguage = "en-US";
}

class PasswordValidator extends Component {
  constructor(props) {
    super(props);
    this.checkPasswordValidity = this.checkPasswordValidity.bind(this);
    this.showHidePass = this.showHidePass.bind(this);
    this.passwordStrengthRef = null;
    this.state = {
      type: "password",
      pwdLowerCaseInvalid: TextProps.VALUE_TRUE,
      pwdUpperCaseInvalid: TextProps.VALUE_TRUE,
      pwdHasNumberInvalid: TextProps.VALUE_TRUE,
      pwdMinLengthInvalid: TextProps.VALUE_TRUE,
      hideSubmitButton: TextProps.VALUE_FALSE,
      password: "",
    };
  }
  componentDidMount() {
    this.setState({
      type: "password",
      pwdLowerCaseInvalid: TextProps.VALUE_TRUE,
      pwdUpperCaseInvalid: TextProps.VALUE_TRUE,
      pwdHasNumberInvalid: TextProps.VALUE_TRUE,
      pwdMinLengthInvalid: TextProps.VALUE_TRUE,
      hideSubmitButton: TextProps.VALUE_FALSE,
      password: "",
    });
  }

  checkPasswordValidity(appState) {
    let value = appState.password;
    // Validate lowercase letters
    let lowerCaseLetters = /[a-z]/g;
    let inValidLower = !value.match(lowerCaseLetters);

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    let invalidUpper = !value.match(upperCaseLetters);

    // Validate numbers
    let numbers = /[0-9]/g;
    let invalidNumber = !value.match(numbers);

    // Validate length
    let inValidLength = value.length < 8;
    this.setState({
      pwdMinLengthInvalid: inValidLength,
      pwdHasNumberInvalid: invalidNumber,
      pwdLowerCaseInvalid: inValidLower,
      pwdUpperCaseInvalid: invalidUpper,
    });

    // disable submit button if error
    let inValid =
      inValidLower || invalidUpper || invalidNumber || inValidLength;
    this.props.onChange({ value: value, isInvalid: inValid });
    this.setState({ password: value });
  }
  showHidePass = (event) => {
    event.preventDefault();
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  };
  render() {
    const {
      type,
      pwdLowerCaseInvalid,
      pwdUpperCaseInvalid,
      pwdHasNumberInvalid,
      pwdMinLengthInvalid,
    } = this.state;
    return (
      <div className="pwd-validator">
        <div
          className={
            "pwd-input" +
            (!this.state.password ? " custom-password-strength" : "")
          }
          style={{ position: "relative" }}
        >
          <ReactPasswordStrength
            changeCallback={this.checkPasswordValidity}
            minLength={8}
            minScore={0}
            scoreWords={[
              CommonMultiLanguage(selectedLanguage).veryWeak,
              CommonMultiLanguage(selectedLanguage).weak,
              CommonMultiLanguage(selectedLanguage).medium,
              CommonMultiLanguage(selectedLanguage).strong,
              CommonMultiLanguage(selectedLanguage).veryStrong,
            ]}
            tooShortWord={CommonMultiLanguage(selectedLanguage).tooShort}
            inputProps={{ className: "dta-input", type: this.state.type }}
          />
          <span
            className="forget-pass pass-toggle"
            style={{ top: "6px" }}
            onClick={this.showHidePass}
          >
            {type === "text"
              ? CommonMultiLanguage(selectedLanguage).hide
              : CommonMultiLanguage(selectedLanguage).show}
          </span>
        </div>
        <div className="pwd-criterias">
          <div className="pwd-message">
            <h4> {CommonMultiLanguage(selectedLanguage).validPassword} </h4>
            <ul className="pwd-criterias-list">
              <li className={pwdLowerCaseInvalid ? "pwd-invalid" : "pwd-valid"}>
                <span>{CommonMultiLanguage(selectedLanguage).lowerCase}</span>
              </li>
              <li className={pwdUpperCaseInvalid ? "pwd-invalid" : "pwd-valid"}>
                <span>{CommonMultiLanguage(selectedLanguage).upperCase}</span>
              </li>
              <li className={pwdHasNumberInvalid ? "pwd-invalid" : "pwd-valid"}>
                <span>{CommonMultiLanguage(selectedLanguage).digit}</span>
              </li>
              <li className={pwdMinLengthInvalid ? "pwd-invalid" : "pwd-valid"}>
                <span>{CommonMultiLanguage(selectedLanguage).eightChar}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default PasswordValidator;
