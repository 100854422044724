import * as TextProps from "../../utils/constants/text";

export function mhproviderReducer(state = {}, action) {
  switch (action.type) {
    case "GET_MHPROVIDER_CLIENT_INFO": {
      const newState = Object.assign({}, state);
      newState.isLoading = TextProps.VALUE_TRUE;
      newState.updatedClientStatus = undefined;
      newState.resetClientStatus = undefined;
      sessionStorage.setItem("isSnapForm", TextProps.VALUE_TRUE);
      return newState;
    }

    case "REFRESH_MHPROVIDER_CLIENT_INFO": {
      const newState = Object.assign({}, state);
      newState.isLoading = TextProps.VALUE_FALSE;
      const mhClientInfo = JSON.parse(sessionStorage.getItem("mhClientInfo"));
      const mhClientRelationInfo = JSON.parse(
        sessionStorage.getItem("mhClientRelationInfo")
      );
      if (mhClientInfo !== null) {
        newState.clientInfo = mhClientInfo;
        newState.clientRelationInfo = mhClientRelationInfo;
        newState.updatedClientStatus = undefined;
        newState.resetClientStatus = undefined;
      }
      return newState;
    }

    case "MHPROVIDER_CLIENT_INFO_SUCCESS":
    case "MHPROVIDER_GET_CASE_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.clientInfo = action.payload.data;
      if (action.payload.data !== undefined) {
        sessionStorage.setItem(
          "mhClientInfo",
          JSON.stringify(action.payload.data)
        );
        if (action.payload.data.relatedCaseInfo !== undefined) {
          sessionStorage.setItem(
            "mhClientRelationInfo",
            JSON.stringify(action.payload.data.relatedCaseInfo)
          );
          newState.clientRelationInfo = action.payload.data.relatedCaseInfo;
        } else {
          newState.clientRelationInfo = null;
        }
      }
      newState.resetClientStatus = undefined;
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "MEMBER_SEARCH_ZIPCODE_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.clientInfo = action.payload.data;
      if (action.payload.data !== undefined) {
        sessionStorage.setItem(
          "mhClientInfo",
          JSON.stringify(action.payload.data)
        );
        if (action.payload.data.relatedCaseInfo !== undefined) {
          sessionStorage.setItem(
            "mhClientRelationInfo",
            JSON.stringify(action.payload.data.relatedCaseInfo)
          );
          newState.clientRelationInfo = action.payload.data.relatedCaseInfo;
        } else {
          newState.clientRelationInfo = null;
        }
      }
      newState.resetClientStatus = undefined;
      newState.zipcodeErrorMsg = TextProps.VALUE_FALSE;
      newState.isLoading = TextProps.VALUE_FALSE;
      newState.radioToggle = "Zip";
      return newState;
    }

    case "MEMBER_SEARCH_ZIPCODE_INFO_FAILED": {
      const newState = Object.assign({}, state);
      newState.zipcodeErrorMsg = TextProps.VALUE_TRUE;
      newState.radioToggle = "Zip";
      return newState;
    }

    case "MEMBER_SEARCH_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.data !== undefined) {
        newState.memberInfo = action.payload.data;
        newState.memberSearchSuccess = TextProps.VALUE_TRUE;
        newState.memberSearchPage = TextProps.VALUE_FALSE;
        newState.memberErrorMsg = TextProps.VALUE_FALSE;
        newState.isSnapForm = TextProps.VALUE_FALSE;
        newState.radioToggle = "Name";
      }
      return newState;
    }

    case "MEMBER_SEARCH_INFO_FAILURE": {
      const newState = Object.assign({}, state);
      newState.memberErrorMsg = TextProps.VALUE_TRUE;
      newState.memberSearchSuccess = TextProps.VALUE_FALSE;
      newState.memberSearchPage = TextProps.VALUE_TRUE;
      newState.isLoading = TextProps.VALUE_FALSE;
      newState.radioToggle = "Name";
      return newState;
    }

    case "MHPROVIDER_CLIENT_INFO_FAILED":
    case "MMHPROVIDER_GET_CASE_INFO_FAILED": {
      const newState = Object.assign({}, state);
      newState.clientInfo = "client-info-not-found";
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "POST_SNAP_OUT_REACH_DATA_FULFILLED": {
      const newState = Object.assign({}, state);
      if (action.payload.data !== undefined) {
        newState.updatedClientStatus = "client-info-updated";
        sessionStorage.removeItem("isSnapForm");
        newState.resetClientStatus = undefined;
      }
      return newState;
    }
    case "POST_SNAP_OUT_REACH_DATA_FAILED": {
      const newState = Object.assign({}, state);
      newState.updatedClientStatus = "client-info-not-updated";
      return newState;
    }
    case "CANCEL_SNAP_OUT_REACH_DATA_FULFILLED": {
      const newState = Object.assign({}, state);
      if (action.payload.data !== undefined) {
        newState.resetClientStatus = "cancel-client-info";
        sessionStorage.removeItem("isSnapForm");
      }
      return newState;
    }

    case "CLIENT_RELATION_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.clientRelationInfo = action.payload.data;
      if (action.payload.data !== undefined) {
        sessionStorage.setItem(
          "mhClientRelationInfo",
          JSON.stringify(action.payload.data)
        );
      }
      newState.resetClientStatus = undefined;
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CLIENT_RELATION_INFO_FAILED": {
      const newState = Object.assign({}, state);
      newState.clientRelationInfo = null;
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "REMOVE_MH_PROVIDER_PROPS": {
      const newState = Object.assign({}, state);
      sessionStorage.removeItem("textfirstnamevalue");
      sessionStorage.removeItem("textlastnamevalue");
      sessionStorage.removeItem("textzipcodevalue");
      sessionStorage.removeItem("checkboxvalue");
      newState.radioToggle = "";
      return newState;
    }

    default:
      return Object.assign({}, state);
  }
}
