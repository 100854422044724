//Roles
export const CONSUMER = "DTACMR";
export const USER = "DEFUSR";
export const PROVIDER = "DTAPVR";
export const PROVIDER_SNAP_GAP = "DCPVSG";
export const PROVIDER_NO_PSI = "SPVUSR";
export const TAO_SCREEN_USER = "DCTAOS";
export const MH_SNAP_GAP = "DCMHUS";

export const DTAROLES = [
  CONSUMER,
  USER,
  PROVIDER,
  PROVIDER_SNAP_GAP,
  PROVIDER_NO_PSI,
  TAO_SCREEN_USER,
  MH_SNAP_GAP,
];

//Authorities
export const HEALTH_VIEW_CASE = "DCMHVC";
export const HEALTH_MEMBER_SEARCH = "DCMHMS";
export const PROVIDER_ROLE_SPVUSR = "SPVUSR";
export const PROVIDER_ROLE_DCPVSG = "DCPVSG";
export const MH_PROVIDER_ROLE = "DCMHUS";
export const GREETER_ROLE = "DCTAOS";
