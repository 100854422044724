import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { beforeYoustartMlanguageLabels } from "../applySnapText";
import {TANF_Eligibility_en, TANF_Eligibility_es} from "../../../utils/constants/constants";
import TANFApplicationEN from "../../../utils/resources/pdfs//TANF-ApplicationEN.pdf"
import TANFApplicationES from "../../../utils/resources/pdfs/TANF-ApplicationES.pdf"

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select") {
  language = "en-US";
}

class TanfBeforeYouStart extends Component {
  constructor(props) {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p>{beforeYoustartMlanguageLabels(language).tanfFieldLabel2}</p>
        <ul>
          <li>
            {" "}
            {beforeYoustartMlanguageLabels(language).tanfFieldFactors[0]}
          </li>
          <li>
            {" "}
            {beforeYoustartMlanguageLabels(language).tanfFieldFactors[1]}
          </li>
          <li>{beforeYoustartMlanguageLabels(language).tanfFieldFactors[2]}</li>
        </ul>
        <p>{beforeYoustartMlanguageLabels(language).tanfInstruction}</p>
        <p>

        <a target="_blank" href={language=="es-ES"?TANFApplicationES:TANFApplicationEN}>
            {beforeYoustartMlanguageLabels(language).tanfApplicationFormLink}
            </a>
        </p>
        <p>{beforeYoustartMlanguageLabels(language).tanfClickHereRulesText}</p>
        <a  href={
            language === "en-US"
                ? TANF_Eligibility_en
                : TANF_Eligibility_es
        } target="_blank" rel="noopener noreferrer">
          {beforeYoustartMlanguageLabels(language).tanfEligibilityLink}
        </a>
        <br />
        <p>{beforeYoustartMlanguageLabels(language).tanfImportantInfo}</p>
        <p>
          {beforeYoustartMlanguageLabels(language).tanfInformationYouEnterText}
        </p>
        <p>
          {beforeYoustartMlanguageLabels(language).tanfTellingOthersText}
        </p>
      </div>
    );
  }
}

export default TanfBeforeYouStart;
