import  _  from "lodash";
import { ReportChangePageLabels } from "./reportChangeText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as languageConstants from "../../utils/constants/constants";
import * as ControlConstants from "../../utils/constants/controls";
import * as types from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";
import * as Props from "../../utils/components/shared";
import { incomeCodeMapper } from "./incomeCodemapper";
import * as formatters from "../../utils/components/utilities/formatters";
import {
  resourcesSectionMultilanguageLabels
} from "../applySnap/applySnapText";
import { ResourcesSectionTemplate, resourceBaseQuestions } from './reportChangeMapData';

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  const EN = "en-US";
  const ES = "es-ES";
  const PT = "pt-BR";
  const ZH = "zh-Hans";
  const VI = "vi-VI";
  const HI = "ht";
export function prepareIncomeSummary (incomeSection,houseMemberList) {
    let incomeSummary = {}
    incomeSummary.incomeMembers = [];
    incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      incomeSummary.incomeMembers[memberIncomeIndex] = {};
      incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].valueToMatch;
            incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        incomeSummary.nonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }

    return incomeSummary;
  };


  export function getUtilityLabel(type){
    let utilityExpenseLabel;
    switch (type) {
      case 'heatFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).heatOil;
        return utilityExpenseLabel;
      case 'acFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityairConditioner;
        return utilityExpenseLabel;
      case 'electricOrGasFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityGas;
        return utilityExpenseLabel;
      case 'phoneFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).cellPhoneService;
        return utilityExpenseLabel;
      case 'noUtilityFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).none;
        return utilityExpenseLabel;
      case 'payWaterSewage':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).waterAndSewage;
        return utilityExpenseLabel;
      case 'payGarbage':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).garbage;
        return utilityExpenseLabel;
      case 'liheapFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).energyAssistance;
        return utilityExpenseLabel;
      default:
        utilityExpenseLabel = '';
        return utilityExpenseLabel;
    }
  }


  export function getIncomeCodes(type){
    return incomeCodeMapper(language)[type];
  }

  export  function getSummaryTitle (name, before, after) {
    let translationObj = null;

    switch (language) {
      case types.ENGLISH:
        translationObj = {
          before: name + before,
          after: after,
        };
        break;
      case types.CHINESE:
        translationObj = {
          before: name + before,
          after: after,
        };
        break;
      case types.SPANISH:
        translationObj = {
          before: name + before,
          after: after,
        };
        break;
      case types.PORTUGUESE:
      case types.VIETNAMESE:
        translationObj = {
          before: before,
          after: " " + name,
        };
      break;
      default:
        translationObj = {
          before: name + before,
          after: after,
        };
    }

    return translationObj;
}


export const convertDOBForDataPost = (dobFrom) => {
    let retrunValue = null;
    if (dobFrom.indexOf(".") !== -1) {
      let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
      let dobArray = dob.split(".");

      let month = ControlConstants.MONTHSHORT.indexOf(dobArray[1]) + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = parseInt(dobArray[0]);
      if (date < 10) {
        date = "0" + date;
      }
      retrunValue = dobArray[2] + "-" + month + "-" + date;
    } else {
      retrunValue = dobFrom;
    }

    return retrunValue;
  };


  export const getSuffixValue = (answer) => {
    switch (language) {
      case types.ENGLISH:
        return answer;
      case types.SPANISH:
        return answer;
      case types.PORTUGUESE:
        return answer;
      case types.CHINESE: //chinees
        if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[0]
        )
          return "I";
        else if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      case types.VIETNAMESE:
        return answer;
      case HI:
        if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[3]
        )
          return "IV";
        else if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[4]
        )
          return "V";
        else if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          ReportChangePageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      default:
        return answer;
    }
  };


export const setUtilityDataChangeCode = (newExp, utilityOldAnswers) => {
  let mappedData = {}
  switch (newExp) {
    case 'acFlag':
      mappedData = { type: newExp, 'code': utilityOldAnswers['acDataChangeCode'], flag: 'acDataChangeCode' }
      break;
    case 'electricOrGasFlag':
      mappedData = { type: newExp, 'code': utilityOldAnswers['electricOrGasDataChangeCode'], flag: 'electricOrGasDataChangeCode' }
      break;
    case 'phoneFlag':
      mappedData = { type: newExp, 'code': utilityOldAnswers['phoneDataChangeCode'], flag: 'phoneDataChangeCode' }
      break;
    case 'payGarbage':
      mappedData = { type: newExp, 'code': utilityOldAnswers['garbageDataChangeCode'], flag: 'garbageDataChangeCode' }
      break;
    case 'heatFlag':
      mappedData = { type: newExp, 'code': utilityOldAnswers['heatDataChangeCode'], flag: 'heatDataChangeCode' }
      break;
    case 'payWaterSewage':
      mappedData = { type: newExp, 'code': utilityOldAnswers['waterSewageDataChangeCode'], flag: 'waterSewageDataChangeCode' }
      break;
    case 'liheapFlag':
      mappedData = { type: newExp, 'code': utilityOldAnswers['liheapDataChangeCode'], flag: 'liheapDataChangeCode' }
      break;
    default:
  }
  return mappedData;
}


export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions,
) {
  let selectedQuestions = [];
  for (let answerValue of activePageAnswers) {
    for (let questionValue of nextPageQuestions) {
      if (answerValue === questionValue.valueToMatch) {
        selectedQuestions.push(questionValue);
      }
    }
  }
  console.log(selectedQuestions)
  return selectedQuestions;
}

export function getDataChangeCode(code){
  let changeCode = "NOCHNG";
  switch (code) {
    case "NEW":
    case "new":
      changeCode = "NEW";
      break;
    case "CHANGE":
    case "changed":
      changeCode = "CHANGE";
      break;
    case "REMOVE":
    case "removed":
      changeCode = "REMOVE";
      break;
    default:
      break;
  }
  return changeCode
}

export const  getMemberDataObjectByNameAndDOB = (name, listToCheck) => {
  let returnMember = null;
  listToCheck.map((member) => {
    let dob = member.dateOfBirth;
    if (dob && dob.indexOf("-") > 0) {
      dob = formatters.formatDate(dob);
    }
    if (
      name &&
      name.trim() ===
      member.firstName.trim() + " " + member.lastName.trim() + dob
    ) {
      returnMember = member;
    }
  });
  return returnMember;
};

const groupBy = keys => array =>
array.reduce((objectsByKeyValue, obj) => {
  const value = keys.map(key => obj[key]).join('-');
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

export function resourcesSubsectionRenderHelper(memberTemplate,responseObject,sections,resourcesSummaryTemplateOrg){
  let answer = [];
  let existingtypes = ["WAGES", "SELEMP", "BOARD"];
  let resourcesMemberMultipleTemplate = [];
  let resourcesSummaryTemplate = sections;
  let resourcesCopy = resourcesSummaryTemplateOrg[0];
 
  // let retainData = filterData(
  //   sections,
  //   "resources-summary"
  // ).retainData;
  
  const groupByName = groupBy(['firstName', 'lastName','dateOfBirth']);
  let resourcesGroupedByName = groupByName(responseObject.resources);
  if(resourcesGroupedByName){
    // resourcesMeTemplate.subsections = ResourcesSectionTemplate.subsections;
    for(let resourcesObj in resourcesGroupedByName){
      let memberIndex = resourcesMemberMultipleTemplate.length;
      let resourcesMeTemplate = _.cloneDeep(ResourcesSectionTemplate);
      if (memberTemplate !== null) resourcesMeTemplate = memberTemplate;
      if (
        resourcesMeTemplate &&
        resourcesMeTemplate.subsections === undefined 
      ) {
        resourcesMeTemplate.subsections = ResourcesSectionTemplate.subsections;
      }
      console.log("resourcesGroupedByName");
      console.log(resourcesGroupedByName[resourcesObj]);
      let resource = resourcesGroupedByName[resourcesObj][0];
      // resource["dateOfBirth"] ? formatters.formatDate(resource["dateOfBirth"]) : null,
      let name = [
        resource["firstName"],
        resource["lastName"],
        resource["dateOfBirth"] ? formatters.formatDate(resource["dateOfBirth"]) : null,
      ]
        .join(" ")
        .replace(/ +(?= )/g, "");
      resourcesMeTemplate.id = "combined-resources-" + memberIndex;
      resourcesMeTemplate.agencyID = resource.agencyID;
      resourcesMeTemplate.completed = TextProps.VALUE_TRUE;
      resourcesMeTemplate.header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
      resourcesMeTemplate.questions[0][Props.LABEL] = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsLabel;
      resourcesMeTemplate.subsections[0].id = "combined-resources-subsection-" + memberIndex;
      resourcesMeTemplate.subsections[0].completed = TextProps.VALUE_TRUE;
      resourcesMeTemplate.subsections[0].header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
      resourcesMeTemplate.title = name;
      resourcesMeTemplate.subsections[0].title = name;
      resourcesMeTemplate.clientId = name;
      let resourceTypeList = [];
      let resourcesReturn = resourcesSectionMultilanguageLabels(language,name);
      let resourcesMeTemplateSubsections = [];
      resourceBaseQuestions.forEach((ques, index) => {
        let resourcesLabelObj = resourcesReturn.combineResourcesOptionsLabelsSrs[index];
        ques.repeatedQuestion['subLabel'] = resourcesLabelObj.line1 + name + resourcesLabelObj.line2;
        ques.repeatedQuestion['subHeader'] = resourcesReturn.combineResourcesoptions[memberIndex];
        return ques;
      });
      resourcesGroupedByName[resourcesObj].map(resources => {
        if(resources.datachangeCode !== "REMOVE"){
        resourceTypeList.push(resources.resourceType);
        }
        resourcesMeTemplate.subsections[0].questions.map((ques,index) => {
          if(ques.valueToMatch === resources.resourceType){
            let resourcesReturnObj = resourcesReturn.combineResourcesOptionsLabelsSrs[index];
            ques.repeatedQuestion.subLabel = resourcesReturnObj.line1 + name + resourcesReturnObj.line2;
            ques.repeatedQuestion.subHeader = resourcesReturn.combineResourcesoptions[index];
            ques.canBeDeleted = TextProps.VALUE_TRUE;
            let resourceDetailsArr = [];
            switch(resources.resourceType){
              case "CASH": case "TRUST": case "PPDFUN": case "OTHER":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).tempalteOneAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.additionalInformation = resDet.additionalInfo;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsObj.setID = resDet.setID;
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              case "SAVING": case "CHECK":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateTwoAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.bankName = resDet.bankBrokerageName;
                  resourceDetailsObj.accountnumber = resDet.accountNumber;
                  resourceDetailsObj.jointAccount = resDet.isJointAccount === TextProps.VALUE_Y ? TextProps.VALUE_YES : TextProps.VALUE_NO;
                  resourceDetailsObj.jointAccountHolder = resDet.jointAcntHolderName;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsObj.setID = resDet.setID;
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              case "STOCKS": case "BONDS": case "RETMNT":
                resources.resourceDetails.map(resDet => {
                  let resourceDetailsObj = _.cloneDeep(resourcesSectionMultilanguageLabels(language).templateThreeAnswerObj);
                  resourceDetailsObj.amount = resDet.amount;
                  resourceDetailsObj.bankName = resDet.bankBrokerageName;
                  resourceDetailsObj.datachangeCode = resDet.datachangeCode ? resDet.datachangeCode : "NOCHNG";
                  resourceDetailsObj.setID = resDet.setID;
                  resourceDetailsArr.push(resourceDetailsObj);
                });
                ques.Answer = resourceDetailsArr;
                ques.targetValue = resourceDetailsArr;
                ques.value = resourceDetailsArr;
                ques.datachangeCode = resources.datachangeCode ? resources.datachangeCode : "NOCHNG";
                resourcesMeTemplateSubsections.push(ques);
              break;
              default:
              break;
            }
          }
        });
      });
      let oldResourceQuestions = _.uniqBy([...resourcesMeTemplateSubsections,...resourceBaseQuestions],(q)=>q.name);
      resourcesMeTemplate.questions[0].Answer = resourceTypeList;
      resourcesMeTemplate.questions[0].targetValue = resourceTypeList;
      resourcesMeTemplate.questions[0].value = resourceTypeList;
        // resourcesMeTemplate.subsections[0].questions = resourcesMeTemplateSubsections;
      resourcesMeTemplate.subsections[0].questions = oldResourceQuestions;
      resourcesMemberMultipleTemplate.push(resourcesMeTemplate);
    }
  }

  if (resourcesSummaryTemplate)
  resourcesMemberMultipleTemplate.push(resourcesSummaryTemplate);
  resourcesCopy.subsections = [];
  resourcesCopy.subsections = resourcesMemberMultipleTemplate;
  return resourcesCopy;
}







export const incomeExistingTypes = [
  "WAGES",
  "SELEMP",
  "BOARD",
  "TAFDCS", 
  "RSDI",
  "SSI",
  "VETBPR",
  "UNEMPL",
  "WRKCMP",
  "INTINC",
  "MILPE",
  "RETFND",
  "RRRET",
  "LIHEAP",
  "RENTAL",
  "CHSDOR",
  "ALIMNY",
  "CONTR",
  "OTHER"
];