import React, { Component } from "react";

import { copyrightLabels } from "../../pages/screening/screeningText";

import {
  footerDHRlogo,
  ALABAMA_URL,
  children_icon,
  SITE_POLICIES,
  snap_icon,
  SUPPL_NUTRITION,
} from "../constants/constants";
import * as routeProps from "../../utils/constants/routesConstants";

//end code for google analytics
let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

class DTAFooter extends Component {
  handleConcern = () =>{
    window.location.assign(routeProps.CUSTOMER_CONCERNS);
  }
  render() {
    return (
      <footer className="dta-footer">
        <div className="pure-g max-width pad-vertical pad-horizontal--double res__footer">
          <div
            className="pure-u-1 pure-u-lg-1-2 dta-footer__mission"
            style={{ flexDirection: "column" }}
          >
            <ul className="list--unstyled list--inline-block">
              <li>
              <a href="tel:18773822363" className="dta-link--light">(877)382-2363</a>
              </li>
              <li>
                <a
                  href="https://dhr.alabama.gov/county-office-contact/"
                  target="_blank"
                  className="dta-link--light"
                >
                  {this.props.language.footerContactText}
                </a>
              </li>
              <li>
                <a
                  href={SITE_POLICIES}
                  className="dta-link--light"
                  target="_blank"
                >
                  {this.props.language.footerPoliciesText}
                </a>
              </li>
              <li>
                <div className="customer-concerns--light" style={{textDecoration:'underline',cursor:'pointer',textDecorationColor:"$color-link-light"}}
                onClick={this.handleConcern}
                >
                  {this.props.language.footerCustomerConcerns}
                </div>
              </li>
            </ul>
            <p>{this.props.language.footerTextFull}</p>
            <p>{copyrightLabels(language)}</p>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2 dta-footer__logos">
            <a
              href={ALABAMA_URL}
              target="_blank"
              className="text--center display-block"
            >
              <img src={footerDHRlogo} alt="The Seal of Alabama" role="link" />
              <span className="sr-only">Alabaama</span>
            </a>
            <a
                href="https://www.acf.hhs.gov/ofa"
                target={"_blank"}
                className="text--center display-block"
            >
              <img
                  src={children_icon}
                  alt="The Seal of Alabama"
                  role="link"
              />
              <span className="sr-only">Children</span>
            </a>
            <a
              href={SUPPL_NUTRITION}
              target="_blank"
              className="text--center display-block"
            >
              <img
                src={snap_icon}
                alt="Supplemental Nutrition Assistance Program logo"
                role="link"
              />
              <span className="sr-only">SNAP</span>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default DTAFooter;
