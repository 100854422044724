import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Collapse from "react-collapse";
import Icon from "../../../utils/components/dtaIcon";

import _ from "lodash";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import * as TextProps from "../../../utils/constants/text";
import DTAInput from "../../../utils/components/dtaInput";
import DTADate from "../../../utils/components/dtaDate.js";
// import DtaSelect from "../../../utils/components/dtaSelect";
// import Checkbox from "../../../utils/components/checkbox";
import { recertificationPageLabels } from "../../recertification/recertificationText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as languageConstants from "../../../utils/constants/constants";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import { formatDate,convertTimestampToToDataFormat,formatDateNew } from "../../../utils/components/utilities/formatters";
import DtaSelect from "../../../utils/components/dtaSelect.js";
import { enrollmentStatusCodeMapper,gradeCodeMapper,gradeDescMapper,enrollmentStatusdescMapper } from "../reportChangeText.js";
const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class Attendance extends Component {

    constructor(props) {
        super(props);
        const userData = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
        this.state = {
            addAttendanceOpen: false,
            selectedAttendanceMembers: [],
            nonAttendanceMembers: [],
            attendanceViewMode: true,
            isChangeInAttendance: ""
        }
    }


    componentDidMount() {
        const {selectedAttendanceMembers,nonAttendanceMembers} = this.props.data;
        this.setState({
            selectedAttendanceMembers,
            nonAttendanceMembers
        },()=>{
            this.props.attendanceDataHandler(selectedAttendanceMembers,this.state.isChangeInAttendance)
        });

    }


    toggleAddAttendanceMembers = () => {
        this.setState({ addAttendanceOpen: !this.state.addAttendanceOpen });
    };

    renderAttendance = (mem) => {
        let filteredMembers = this.state.nonAttendanceMembers.filter((member) => {
            return member.agencyID !== mem.agencyID
        })
        if (!this.state.selectedAttendanceMembers.some(item => item.agencyID === mem.agencyID)) {
            mem['attandanceStatus'] = ''
            mem['changesSclFlag'] = ''
            mem['currentGradeLevel'] = ''
            mem['schoolName'] = ''
            mem['effectiveDate'] = ''
            //errors
            mem['attandanceStatusError'] = false;
            mem['changesSclFlagError'] = false;
            mem['currentGradeLevelError'] = false;
            mem['schoolNameError'] = false;
            mem['effectiveDateError'] = false;
            mem['dataType'] = "NEW"
            this.setState({
                selectedAttendanceMembers: [...this.state.selectedAttendanceMembers, mem],
                addAttendanceOpen: !this.state.addAttendanceOpen,
                nonAttendanceMembers: filteredMembers,
            },()=>{
                this.props.attendanceDataHandler(this.state.selectedAttendanceMembers,this.state.isChangeInAttendance)

            })
        }
    }

    onAttendanceFieldsChange = (member, key, value) => {
            let updated = this.state.selectedAttendanceMembers.map((selectedMem)=>{
                    if(member.agencyID === selectedMem.agencyID){
                        if(key === "effectiveDate" && value){
                            value = formatDateNew(convertTimestampToToDataFormat(value))
                          }
                          if(key === "currentGradeLevel" && value){
                            value = gradeCodeMapper(language)[value]
                          }
                          if(key === "attandanceStatus" && value){
                            value = enrollmentStatusCodeMapper(language)[value]
                          }
                        selectedMem[key] = value;
                        return selectedMem
                    }else{
                        return selectedMem
                    }
            });
            this.setState({
                selectedAttendanceMembers:updated
            },()=>{
                this.props.attendanceDataHandler(this.state.selectedAttendanceMembers,this.state.isChangeInAttendance)
            })
    }

    onAttendanceChange=(val,info)=>{
        if(val === info.YesNoOptions[1]){
            const {consumer} = this.props;
            let consumerDetails = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
            let schoolAttendance = [];
            let householdMembers = [];
            if (consumer && Object.keys(consumer.consumerData).length > 0) {
                ({ schoolAttendance, householdMembers } = consumer.consumerData);
            } else if (consumerDetails && consumerDetails.schoolAttendance) {
                ({ schoolAttendance, householdMembers } = consumerDetails);
            }else{
                return false;
            }

            let selMem = this.state.selectedAttendanceMembers.filter((mem)=>mem.dataType!=="NEW");
            let personNotHaveAttData = householdMembers.filter(person => !schoolAttendance.some(attPerson => attPerson.agencyID === person.agencyID));
            this.setState({selectedAttendanceMembers:selMem,nonAttendanceMembers:personNotHaveAttData},()=>{
                this.props.attendanceDataHandler(this.state.selectedAttendanceMembers,this.state.isChangeInAttendance)
            });
        }
        this.setState({ 'isChangeInAttendance': val },()=>{
            this.props.attendanceDataHandler(this.state.selectedAttendanceMembers,this.state.isChangeInAttendance)
            })
    }

    renderAttendanceUI = (info) => {
        return this.state.selectedAttendanceMembers && this.state.selectedAttendanceMembers.map((member) => {
            return <div>
                <h2>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s - School Attendance </h2>
                <p>{info.schoolName}</p>
                <DTAInput
                    className="dta-form__control"
                    id="schoolName"
                    // placeholder=""
                    type="alpha-text"
                    value={member.schoolName}
                    onChange={(value) => this.onAttendanceFieldsChange(member, "schoolName", value)}
                    required={TextProps.VALUE_TRUE}
                    error={member.schoolNameError}
                />
                <p>{info.selectApply}</p>

                <p>{info.grade}</p>
                <DtaSelect
                    name={"type"}
                    className="dta-units__item"
                    value={gradeDescMapper(language)[member.currentGradeLevel]}
                    options={info.educationGradeOptions.sort()}
                    onChange={(value) => this.onAttendanceFieldsChange(member, "currentGradeLevel", value)}
                    required={TextProps.VALUE_TRUE}
                    error={member.currentGradeLevelError}
                />
                 <p>{info.enrollmentStatus}</p>
                <DtaSelect
                    name={"type"}
                    className="dta-units__item"
                    value={enrollmentStatusdescMapper(language)[member.attandanceStatus]}
                    options={info.enrollmentStatusOptions}
                    onChange={(value) => this.onAttendanceFieldsChange(member, "attandanceStatus", value)}
                    required={TextProps.VALUE_TRUE}
                    error={member.attandanceStatusError}
                />
                <p>{info.effectiveDate}</p>
                {member.effectiveDateError && <span className="dta-form__error-message" style={{ marginTop: "15px" }}>{info.dateError}</span>}
                <DTADate
                    name="effectiveDate"
                    error={member.effectiveDateError}
                    options={info.determinationOptions}
                    required={TextProps.VALUE_TRUE}
                    value={member.effectiveDate}
                    onChange={(value) => this.onAttendanceFieldsChange(member, "effectiveDate", value)}
                />
                <br /> 
            </div>

        })
    }


    render() {
        const { info } = this.props.language.consumer;
        return (
            <div style={{ padding: "0 30px" }}>
                <div>
                    <p>{info.attendanceChange}</p>
                    <Radio
                        id="isChangeInAttendance"
                        name="isChangeInAttendance"
                        className="dta-form__option-list"
                        optionClass="dta-form__option dta-form__option--width-1-of-2"
                        options={info.YesNoOptions}
                        required={TextProps.VALUE_TRUE}
                        value={this.state.isChangeInAttendance}
                        onChange={(value) => this.onAttendanceChange(value,info)}
                    />
                    <br/>
                    {this.renderAttendanceUI(info, "EDIT")}
                    {this.state.isChangeInAttendance === info.YesNoOptions[0] ?
                        <div>
                            <button
                                className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left`}
                                onClick={this.toggleAddAttendanceMembers}
                                aria-expanded={this.state.addAttendanceOpen}
                            >
                                <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
                                {
                                    info.addAditionalMember
                                }
                            </button>
                            <Collapse isOpened={this.state.addAttendanceOpen}>
                                <span className="add-income-summary__label" />
                                <ul className="list--unstyled add-income-summary__list">
                                    {this.state.nonAttendanceMembers && this.state.nonAttendanceMembers.map((member) => {
                                        return (
                                            <li key={member.id}>
                                                <a
                                                    style={{ textDecoration: "none" }}
                                                    href="javascript:void(0);"
                                                    onClick={() => {
                                                        this.renderAttendance(member);
                                                    }}
                                                    className="dta-button dta-button--outline-secondary dta-button--block"
                                                >
                                                    <span className="truncate-text">{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}</span>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Collapse>
                        </div> : null}
                </div>

            </div>
        )
    }

}




function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);