import React, { Component } from "react";
import PropTypes from "prop-types";
import AriaModal from "react-aria-modal";
import { enableUniqueIds } from "react-html-id";
import { consumerConst } from "../../../pages/consumer/consumerText";

import Icon from "../dtaIcon";
import * as TextProps from "../../../utils/constants/text";

let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

/*
  DTAModal
  ------

  # Purpose:
  A component for building accessible modal windows using react-aria-modal.

  # Props:
  isOpen:        (Boolean)  Required, is the modal open or not?
  onClose:       (Function) Required, handler for when the modal is closed,
                            (like clicking 'X' button, outside click, 'back', etc)
  titleText:     (String)   Required, title of the modal for screen reader use
  headerText:    (String)   Optional, title to be displayed in modal header
  modalClass:    (String)   Optional, additional classes to add onto modal dialog
  error:         (Boolean)  Optional, does the modal have an error to display?
  errorMesssage: (String)   Optional, error message to display in top of modal body
  children:                 Optional, anything contained inside the DTAModal tag will
                            be rendered inside the modal body

  # Other API:
  showBlur: (Function)
    Shows can overlay which blurs the contents of the modal and displays the Loading
    or Success state animation and message.
    @param  {string} (one of '', 'loading' or 'success') Required, string marking the
                     blur animation desired.
                     '' option will show no animation until hideBlur is called or
                     showBlur is called again.
                     'loading' option will display loading animation until hideBlur
                     is called, or showBlur is called again.
                     'success' will display success animation then close the modal
                     automatically after a brief pause.
    @param  {string} String which is a brief message to be displayed below animation.

  hideBlur: (Function)
    Removes the blur overlay, including any animations and messages.

  To access the modal to call these functions, use a reference to it. Based on
  example below you could call it like this in your component:
  this.exampleModal.showBlur('loading', 'Loading results...');

  # Example:
  <DTAModal ref={instance => this.exampleModal = instance }
            isOpen={ this.state.exampleModalOpen }
            onClose={ this.closeExampleModal }
            modalClass="example-modal"
            titleText="Example Modal"
            headerText="EXAMPLE MODAL"
            error={ this.state.exampleModalError }
            errorMessage={ this.state.exampleModalErrorMessage }>
    <div className="dta-modal__body pad-all">
      <p>The path ahead is dangerous, are you sure you wish to proceed?</p>
      <div className="dta-modal__footer dta-modal__footer--inline-buttons">
        <button className="dta-button dta-button--large dta-button--outline-primary" onClick={ this.closeExampleModal }>Back</button>
        <button className="dta-button dta-button--large dta-button--primary" onClick={ this.continueOnwards }>Yes, Continue</button>
      </div>
    </div>
  </DTAModal>
*/

class DTAModal extends Component {
  static PropTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    titleText: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    modalClass: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      modalOpen: TextProps.VALUE_FALSE,
      modalHasEntered: TextProps.VALUE_FALSE,
      showBlur: TextProps.VALUE_FALSE,
      showLoading: TextProps.VALUE_FALSE,
      showSuccess: TextProps.VALUE_FALSE,
      showWarning: TextProps.VALUE_FALSE,
      showError: TextProps.VALUE_FALSE,
      blurMessage: "",
    };

    enableUniqueIds(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        this.activateModal();
      } else {
        this.deactivateModal();
      }
    }
  }

  activateModal = () => {
    this.setState({ modalOpen: TextProps.VALUE_TRUE });
  };

  deactivateModal = () => {
    this.setState(
      {
        modalHasEntered: TextProps.VALUE_FALSE,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalOpen: TextProps.VALUE_FALSE,
            showBlur: TextProps.VALUE_FALSE,
            showLoading: TextProps.VALUE_FALSE,
            showSuccess: TextProps.VALUE_FALSE,
            showWarning: TextProps.VALUE_FALSE,
            showError: TextProps.VALUE_FALSE,
          });
        }, 200); // Timeout here should be roughly same time as CSS transition for modal
      }
    );
  };

  onModalEnter = () => {
    this.setState({ modalHasEntered: TextProps.VALUE_TRUE });
  };

  showBlur = (type, blurMessage = null, noTimeOut = false) => {
    // Set the modal (if applicable) back to top so message shows clearly
    if(document.getElementById("react-aria-modal-dialog")){
    document.getElementById("react-aria-modal-dialog").scrollTop = 0;
    }

    this.setState(
      {
        showBlur: TextProps.VALUE_TRUE,
        showLoading:
          type === "loading" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        showSuccess:
          type === "success" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        showWarning:
          type === "warning" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        showError:
          type === "error" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        blurMessage: blurMessage
          ? blurMessage
          : type === "loading"
          ? consumerConst(selectedLanguage).loadingLabel
          : type === "success"
          ? "Success!"
          : "",
      },
      () => {
        if (
          (type === "success" || type === "warning" || type === "error") &&
          this.props.modalClass !== "rivl-modal replace-pebt-modal"
        ) {
          setTimeout(() => {
            this.props.onClose();
          }, 6000);
        } else if (!noTimeOut && this.props.modalClass === "account-connect-modal consumer-search-client") {
           {
            setTimeout(() => {
              this.props.onClose();
            }, 480000);
          } 
        } else if(!noTimeOut){
          setTimeout(() => {
            this.props.onClose();
          }, 45000); 
        }
      }
    );
  };

  hideBlur = () => {
    this.setState({
      showBlur: TextProps.VALUE_FALSE,
      showLoading: TextProps.VALUE_FALSE,
      showSuccess: TextProps.VALUE_FALSE,
      showWarning: TextProps.VALUE_FALSE,
      showError: TextProps.VALUE_FALSE,
    });
  };

  getApplicationNode = () => {
    return document.getElementById("root");
  };

  getBlrMsg = (stateVal) => {
    if (stateVal.showSuccess) {
      return (
        <div
          style={
            this.props.modalClass === "rivl-modal replace-pebt-modal"
              ? {
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "-2.5em",
                }
              : { display: "flex", flexDirection: "column" }
          }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              className="checkmark-success"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
              aria-hidden={TextProps.VALUE_STR_TRUE}
            >
              <circle
                className="checkmark-success__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark-success__check"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                fill="none"
              />
            </svg>
          </div>
          {stateVal.blurMessage}
        </div>
      );
    } else if (stateVal.showWarning) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="118"
              height="118"
              viewBox="0 0 118 118"
            >
              <g
                id="Group_422"
                data-name="Group 422"
                transform="translate(-624 -234)"
              >
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="59"
                  cy="59"
                  r="59"
                  transform="translate(624 234)"
                  fill="#f6c326"
                />
                <path
                  id="Union_5"
                  data-name="Union 5"
                  d="M11.621,53.669a5.514,5.514,0,0,0-5.71-5.514A5.423,5.423,0,1,0,5.911,59,5.481,5.481,0,0,0,11.621,53.669ZM10.86,38.6,12,5.7A5.666,5.666,0,0,0,6.292,0H5.531A5.4,5.4,0,0,0,.011,5.7L.963,38.6A4.971,4.971,0,0,0,6.1,43.193,4.633,4.633,0,0,0,10.86,38.6Z"
                  transform="translate(677 264)"
                />
              </g>
            </svg>
          </div>
          {stateVal.blurMessage}
        </div>
      );
    } else if (stateVal.showError) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="118"
              height="118"
              viewBox="0 0 118 118"
            >
              <g
                id="Group_425"
                data-name="Group 425"
                transform="translate(-624 -265)"
              >
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="59"
                  cy="59"
                  r="59"
                  transform="translate(624 265)"
                  fill="#c23434"
                />
                <path
                  id="Path_174"
                  data-name="Path 174"
                  d="M121.887,217.882H75.9a6.552,6.552,0,0,1-6.532-6.532v-.109a6.552,6.552,0,0,1,6.532-6.532h45.982a6.552,6.552,0,0,1,6.532,6.532v.109a6.552,6.552,0,0,1-6.532,6.532Z"
                  transform="translate(583.629 114.292)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          {stateVal.blurMessage}
        </div>
      );
    }
    return stateVal.showLoading ? (
      <div>
        <div className="loader" aria-hidden={TextProps.VALUE_STR_TRUE}>
          <div className="loader__spinner"></div>
        </div>
        <div>{stateVal.blurMessage}</div>
      </div>
    ) : null;
  };

  render() {
    let underlayClass = "dta-modal";
    let dialogContentClass = `dta-modal__content ${this.props.modalClass}`;
    const initialFocus =
      this.props.hideCloseIcon !== TextProps.VALUE_TRUE &&
      "#" + this.nextUniqueId();

      if (this.state.modalHasEntered) {
        if (this.props.modalClass === "address-validation-modal") 
          underlayClass += " z-index-9999";
      }
      
      if (this.state.modalHasEntered) {
      underlayClass += " has-entered";
      dialogContentClass += " has-entered";
    }

    if (this.state.showBlur) {
      {
        this.props.modalClass === "rivl-modal replace-pebt-modal"
          ? dialogContentClass
          : (dialogContentClass += " is-blurred");
      }
    }

    return (
      <AriaModal
        mounted={this.state.modalOpen}
        titleText={this.props.titleText}
        onEnter={this.onModalEnter}
        onExit={this.props.onClose}
        initialFocus={initialFocus}
        includeDefaultStyles={TextProps.VALUE_FALSE}
        underlayClass={underlayClass}
        underlayClickExits={TextProps.VALUE_FALSE}
        dialogClass={dialogContentClass}
        getApplicationNode={this.getApplicationNode}
        ref={(modal) => (this.modalNode = modal)}
      >
        {this.props.modalClass === "rivl-modal replace-pebt-modal" ? (
          <div className="dta-modal__blur-wrapper">
            {/* Condition for hiding close button in dta connnect modal */}
            {this.props.hideCloseIcon !== TextProps.VALUE_TRUE && (
              <div
                className={
                  this.props.modalClass === "rivl-modal replace-pebt-modal"
                    ? "dta-modal__noHeader"
                    : "dta-modal__header"
                }
              >
                <span className="dta-modal__title">
                  {this.props.headerText}
                </span>
                <button
                  className={
                    this.props.iconHide
                      ? "dta-modal-icon-hide"
                      : "dta-button--minor close-modal"
                  }
                  id={this.lastUniqueId()}
                  onClick={this.props.onClose}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
            )}
            {this.props.error && this.props.errorMessage ? (
              <div
                className="dta-modal__error-message"
                aria-expanded={this.props.error && this.props.errorMessage}
                aria-live="assertive"
              >
                <p className="notification-message notification-message--warning">
                  {this.props.errorMessage}
                </p>
              </div>
            ) : null}
            {this.props.children}
            {/* {
            this.state.showBlur ?
              <div className="dta-modal__blur-overlay"></div>
              : null
          } */}
          </div>
        ) : (
          <div
            className={`dta-modal__blur-wrapper ${
              this.state.showBlur ? "is-blurred" : ""
            }`}
          >
            {/* Condition for hiding close button in dta connnect modal */}
            {this.props.hideCloseIcon !== TextProps.VALUE_TRUE && (
              <div className="dta-modal__header">
                <span className="dta-modal__title">
                  {this.props.headerText}
                </span>
                <button
                  className={
                    this.props.iconHide
                      ? "dta-modal-icon-hide"
                      : "dta-button--minor close-modal"
                  }
                  id={this.lastUniqueId()}
                  onClick={this.props.onClose}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
            )}
            {this.props.error && this.props.errorMessage ? (
              <div className="dta-modal__error-message" aria-live="assertive">
                <p className="notification-message notification-message--warning">
                  {this.props.errorMessage}
                </p>
              </div>
            ) : null}
            {this.props.children}
            {this.state.showBlur ? (
              <div className="dta-modal__blur-overlay" style={{ background: "#ffffff"}}></div>
            ) : null}
          </div>
        )}
        {this.state.showLoading ||
        this.state.showSuccess ||
        this.state.showWarning ||
        this.state.showError ? (
          <div
            className={
              this.props.modalClass === "rivl-modal replace-pebt-modal"
                ? "dta-modal__noBlur-message"
                : "dta-modal__blur-message"
            }
            aria-live="assertive"
          >
            {this.getBlrMsg(this.state)}
          </div>
        ) : null}
      </AriaModal>
    );
  }
}

export default DTAModal;
