import PropTypes from "prop-types";
import { ZIPCODE } from "../../utils/constants/zipCode";
import * as TextProps from "../../utils/constants/text";

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
          });
        });
      }
    } else {
      if (
        (AnswerObj[activeId] === undefined && question.Answer === undefined) ||
        question.Answer === undefined
      ) {
        question.Answer = "";
        question.targetValue = "";
      }
    }
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  let forceInvalid = TextProps.VALUE_FALSE;
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "about-me") {
        forceInvalid = buildApplyAboutMeValidations(inputSections);
      }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (let answerNotElected of dataSections) {
      if (
        answerNotElected.type === "date" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputSSN" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputPhone" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputText" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "emailAddress"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "address"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (answerNotElected.isRequired === TextProps.VALUE_TRUE) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        dataID.substring(0, 27) === "kids-adult-multiple-address" ||
        dataID.substring(0, 15) === "medical-address"
      ) {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (dataID === "contact-info") {
        var answerNotElectedMailingAddress =
          dataSections[4].conditionals[0].conditionalQuestions[0];
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        (answerNotElected.type === "inputText" ||
          answerNotElected.type === "inputAlphaText") &&
        answerNotElected.error === TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(answerNotElected.Answer);
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnwsers = [];
  if (sections !== undefined) {
    const dataSections = sections.questions;
    for (let answerElected of dataSections) {
      if (
        (answerElected.type === "date" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputSSN" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputPhone" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputText" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "emailAddress") ||
        (answerElected.type === "address" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "address") ||
        (answerElected.type !== "address" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.isRequired === TextProps.VALUE_TRUE)
      ) {
        totalAnwsers.push(answerElected.Answer);
      }
    }
  }
  // checking required fields and total touched fileds
  if (
    forceInvalid ||
    (sections !== undefined && totalReqFields.length !== totalAnwsers.length)
  )
    return TextProps.VALUE_FALSE;
  return TextProps.VALUE_TRUE;
}

function buildApplyAboutMeValidations(dataSections) {
  const resZipCode = dataSections.find((obj) => obj.name === "address").Answer
    .zipCode;
  const masResZipCode = ZIPCODE.find((obj) => obj.ZIPCODE === resZipCode);
  var forceFlag = TextProps.VALUE_FALSE;
  const currentDate = new Date();

  if (dataSections) {
    for (let section of dataSections) {
      if (section.type === "select") {
        if (
          section.Answer.length < 1 &&
          section.Answer !== null &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (
        section.type === "inputText" ||
        section.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          section.Answer.length >= 0 &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(section.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(section.Answer) === TextProps.VALUE_FALSE
          ) {
            section.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          section.Answer.length > 0 &&
          section.isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(section.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(section.Answer) === TextProps.VALUE_FALSE
          ) {
            section.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (!errorFlag) {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.type === "date") {
        if (PropTypes.date_error === 1) {
          section.error = TextProps.VALUE_TRUE;
        } else if (
          (section.Answer === "" || section.Answer === null) &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else if (
          (section.Answer !== "" || section.Answer !== null) &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          var inputDate = section.Answer;
          if (inputDate.getTime() > currentDate.getTime()) {
            section.error = TextProps.VALUE_TRUE;
          } else {
            section.error = TextProps.VALUE_FALSE;
          }
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.type === "inputPhone") {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (
          section.Answer === undefined ||
          section.Answer === "" ||
          phoneno.test(section.Answer) === TextProps.VALUE_FALSE
        ) {
          section.error = TextProps.VALUE_TRUE;
          section.Answer = "";
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (
        (section.type === "select" && section.name === "language-preference") ||
        (section.type === "radio" &&
          section.name === "is-child-live-with-you") ||
        (section.type === "radio" && section.name === "is-have-money") ||
        (section.type === "radio" && section.name === "income-source")
      ) {
        if (
          section.Answer === "" &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (
        section.conditionals !== undefined &&
        section.conditionals !== ""
      ) {
        if (
          section.conditionals[0].conditionalQuestions[0] !== undefined &&
          section.conditionals[0].conditionalQuestions[0] !== ""
        ) {
          if (section.conditionals[0].conditionalQuestions[0].type === "date") {
            if (PropTypes.date_error === 1) {
              section.conditionals[0].conditionalQuestions[0].error =
                TextProps.VALUE_TRUE;
            } else if (
              (section.conditionals[0].conditionalQuestions[0].Answer === "" ||
                section.conditionals[0].conditionalQuestions[0].Answer ===
                  null) &&
              section.conditionals[0].conditionalQuestions[0].isRequired ===
                TextProps.VALUE_TRUE
            ) {
              section.conditionals[0].conditionalQuestions[0].error =
                TextProps.VALUE_TRUE;
            } else if (
              section.conditionals[0].conditionalQuestions[0].targetValue &&
              section.conditionals[0].conditionalQuestions[0].isRequired ===
                TextProps.VALUE_TRUE
            ) {
              var inputDateVal =
                section.conditionals[0].conditionalQuestions[0].targetValue;
              if (inputDateVal.getTime() > currentDate.getTime()) {
                section.conditionals[0].conditionalQuestions[0].error =
                  TextProps.VALUE_TRUE;
                section.conditionals[0].conditionalQuestions[0].errorMessage =
                  section.errorMessage;
              } else if (
                dataSections[4].Answer !== "" &&
                dataSections[4].Answer !== undefined &&
                dataSections[16].conditionals[0].conditionalQuestions[0]
                  .Answer !== "" &&
                dataSections[16].conditionals[0].conditionalQuestions[0]
                  .Answer !== undefined
              ) {
                if (
                  dataSections[4].Answer >
                  dataSections[16].conditionals[0].conditionalQuestions[0]
                    .Answer
                ) {
                  dataSections[16].conditionals[0].conditionalQuestions[0].error =
                    TextProps.VALUE_TRUE;
                  dataSections[16].conditionals[0].conditionalQuestions[0].errorMessage =
                    dataSections[16].conditionals[0].conditionalQuestions[0].errorlastEmployment;
                }
              }
            }
          }
        }
      }
      if (
        section.name === "emailAddress" &&
        section.isRequired === TextProps.VALUE_TRUE
      ) {
        const pattern =
          /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (
          pattern.test(section.Answer) === TextProps.VALUE_FALSE ||
          section.Answer === ""
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      }
      if (section.name === "ssn") {
        const pattern = /^[0-9]{9}$/;
        if (
          section.Answer &&
          pattern.test(section.Answer) === TextProps.VALUE_FALSE
        ) {
          section.error = TextProps.VALUE_TRUE;
          section.Answer = "";
          forceFlag = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.name === "address") {
        if (section.Answer === "" || section.Answer.street === "") {
          section.error = TextProps.VALUE_TRUE;
          section.errorMessage = section.addressErrmsg;
        } else if (
          section.Answer.zipCode === "" ||
          section.Answer.zipCode.length < 5
        ) {
          section.error = TextProps.VALUE_TRUE;
          section.errorMessage = section.zipCodeErrmsg;
        } else if (!masResZipCode) {
          section.error = TextProps.VALUE_TRUE;
          section.errorMessage = section.masZipCodeErrmsg;
        } else if (section.Answer.city === "") {
          section.error = TextProps.VALUE_TRUE;
          section.errorMessage = section.citiErrmsg;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
  return forceFlag;
}
