const TYPE_ATM = "atmType",
  TYPE_DTA_OFFICE = "dtaOfficeType",
  TYPE_FARMERS_MARKET = "farmersMarketType";

export const ATM_LOCATIONS = [
  {
    type: TYPE_ATM,
    id: "atm-1",
    info: {
      name: "Bank of Boston A.T.M. 1",
      address1: "123 Hitchcock A Way",
      address2: "Boston, MA 01923",
    },
    lat: 42.07457446,
    lng: -70.72793347,
  },
  {
    type: TYPE_ATM,
    id: "atm-2",
    info: {
      name: "Bank of Boston A.T.M. 2",
      address1: "124 Hitchcock B Way",
      address2: "Boston, MA 01924",
    },
    lat: 42.37041486,
    lng: -71.9985173,
  },
  {
    type: TYPE_ATM,
    id: "atm-3",
    info: {
      name: "Bank of Boston A.T.M. 3",
      address1: "125 Hitchcock C Way",
      address2: "Boston, MA 01925",
    },
    lat: 42.37942616,
    lng: -70.52496497,
  },
  {
    type: TYPE_ATM,
    id: "atm-4",
    info: {
      name: "Bank of Boston A.T.M. 4",
      address1: "126 Hitchcock D Way",
      address2: "Boston, MA 01926",
    },
    lat: 42.55977643,
    lng: -71.81741964,
  },
  {
    type: TYPE_ATM,
    id: "atm-5",
    info: {
      name: "Bank of Boston A.T.M. 5",
      address1: "127 Hitchcock E Way",
      address2: "Boston, MA 01927",
    },
    lat: 42.37887893,
    lng: -70.20941745,
  },
  {
    type: TYPE_ATM,
    id: "atm-6",
    info: {
      name: "Bank of Boston A.T.M. 6",
      address1: "128 Hitchcock F Way",
      address2: "Boston, MA 01928",
    },
    lat: 42.84996777,
    lng: -70.96534051,
  },
  {
    type: TYPE_ATM,
    id: "atm-7",
    info: {
      name: "Bank of Boston A.T.M. 7",
      address1: "129 Hitchcock G Way",
      address2: "Boston, MA 01929",
    },
    lat: 42.60396368,
    lng: -70.15859277,
  },
  {
    type: TYPE_ATM,
    id: "atm-8",
    info: {
      name: "Bank of Boston A.T.M. 8",
      address1: "130 Hitchcock H Way",
      address2: "Boston, MA 01930",
    },
    lat: 41.79921893,
    lng: -70.88152539,
  },
  {
    type: TYPE_ATM,
    id: "atm-9",
    info: {
      name: "Bank of Boston A.T.M. 9",
      address1: "131 Hitchcock I Way",
      address2: "Boston, MA 01931",
    },
    lat: 42.21897101,
    lng: -71.20695665,
  },
  {
    type: TYPE_ATM,
    id: "atm-10",
    info: {
      name: "Bank of Boston A.T.M. 10",
      address1: "132 Hitchcock J Way",
      address2: "Boston, MA 01932",
    },
    lat: 43.00479633,
    lng: -70.95367773,
  },
  {
    type: TYPE_ATM,
    id: "atm-11",
    info: {
      name: "Bank of Boston A.T.M. 11",
      address1: "133 Hitchcock K Way",
      address2: "Boston, MA 01933",
    },
    lat: 42.51218449,
    lng: -71.29120397,
  },
  {
    type: TYPE_ATM,
    id: "atm-12",
    info: {
      name: "Bank of Boston A.T.M. 12",
      address1: "134 Hitchcock L Way",
      address2: "Boston, MA 01934",
    },
    lat: 42.14661469,
    lng: -70.39909743,
  },
  {
    type: TYPE_ATM,
    id: "atm-13",
    info: {
      name: "Bank of Boston A.T.M. 13",
      address1: "135 Hitchcock M Way",
      address2: "Boston, MA 01935",
    },
    lat: 41.75419596,
    lng: -71.09857503,
  },
  {
    type: TYPE_ATM,
    id: "atm-14",
    info: {
      name: "Bank of Boston A.T.M. 14",
      address1: "136 Hitchcock N Way",
      address2: "Boston, MA 01936",
    },
    lat: 43.0128863,
    lng: -70.78078937,
  },
  {
    type: TYPE_ATM,
    id: "atm-15",
    info: {
      name: "Bank of Boston A.T.M. 15",
      address1: "137 Hitchcock O Way",
      address2: "Boston, MA 01937",
    },
    lat: 42.66979228,
    lng: -71.01381163,
  },
  {
    type: TYPE_ATM,
    id: "atm-16",
    info: {
      name: "Bank of Boston A.T.M. 16",
      address1: "138 Hitchcock P Way",
      address2: "Boston, MA 01938",
    },
    lat: 42.50381546,
    lng: -70.2233682,
  },
  {
    type: TYPE_ATM,
    id: "atm-17",
    info: {
      name: "Bank of Boston A.T.M. 17",
      address1: "139 Hitchcock Q Way",
      address2: "Boston, MA 01939",
    },
    lat: 42.63619376,
    lng: -70.28587042,
  },
  {
    type: TYPE_ATM,
    id: "atm-18",
    info: {
      name: "Bank of Boston A.T.M. 18",
      address1: "140 Hitchcock R Way",
      address2: "Boston, MA 01940",
    },
    lat: 41.79311812,
    lng: -71.33916804,
  },
  {
    type: TYPE_ATM,
    id: "atm-19",
    info: {
      name: "Bank of Boston A.T.M. 19",
      address1: "141 Hitchcock S Way",
      address2: "Boston, MA 01941",
    },
    lat: 42.30418171,
    lng: -71.62018685,
  },
  {
    type: TYPE_ATM,
    id: "atm-20",
    info: {
      name: "Bank of Boston A.T.M. 20",
      address1: "142 Hitchcock T Way",
      address2: "Boston, MA 01942",
    },
    lat: 42.07760922,
    lng: -71.22936685,
  },
  {
    type: TYPE_ATM,
    id: "atm-21",
    info: {
      name: "Bank of Boston A.T.M. 21",
      address1: "143 Hitchcock U Way",
      address2: "Boston, MA 01943",
    },
    lat: 42.20847634,
    lng: -71.45118748,
  },
  {
    type: TYPE_ATM,
    id: "atm-22",
    info: {
      name: "Bank of Boston A.T.M. 22",
      address1: "144 Hitchcock V Way",
      address2: "Boston, MA 01944",
    },
    lat: 42.25392887,
    lng: -71.41742848,
  },
  {
    type: TYPE_ATM,
    id: "atm-23",
    info: {
      name: "Bank of Boston A.T.M. 23",
      address1: "145 Hitchcock W Way",
      address2: "Boston, MA 01945",
    },
    lat: 42.20401288,
    lng: -71.40800157,
  },
  {
    type: TYPE_ATM,
    id: "atm-24",
    info: {
      name: "Bank of Boston A.T.M. 24",
      address1: "146 Hitchcock X Way",
      address2: "Boston, MA 01946",
    },
    lat: 42.00163027,
    lng: -71.49867121,
  },
  {
    type: TYPE_ATM,
    id: "atm-25",
    info: {
      name: "Bank of Boston A.T.M. 25",
      address1: "147 Hitchcock Y Way",
      address2: "Boston, MA 01947",
    },
    lat: 41.8031161,
    lng: -71.55166072,
  },
  {
    type: TYPE_ATM,
    id: "atm-26",
    info: {
      name: "Bank of Boston A.T.M. 26",
      address1: "148 Hitchcock Z Way",
      address2: "Boston, MA 01948",
    },
    lat: 42.27185079,
    lng: -70.48205824,
  },
  {
    type: TYPE_ATM,
    id: "atm-27",
    info: {
      name: "Bank of Boston A.T.M. 27",
      address1: "149 Hitchcock AA Way",
      address2: "Boston, MA 01949",
    },
    lat: 42.19597948,
    lng: -70.35582117,
  },
  {
    type: TYPE_ATM,
    id: "atm-28",
    info: {
      name: "Bank of Boston A.T.M. 28",
      address1: "150 Hitchcock AB Way",
      address2: "Boston, MA 01950",
    },
    lat: 43.00863482,
    lng: -70.92397187,
  },
  {
    type: TYPE_ATM,
    id: "atm-29",
    info: {
      name: "Bank of Boston A.T.M. 29",
      address1: "151 Hitchcock AC Way",
      address2: "Boston, MA 01951",
    },
    lat: 42.6251431,
    lng: -71.27675974,
  },
  {
    type: TYPE_ATM,
    id: "atm-30",
    info: {
      name: "Bank of Boston A.T.M. 30",
      address1: "152 Hitchcock AD Way",
      address2: "Boston, MA 01952",
    },
    lat: 41.81524905,
    lng: -71.46140924,
  },
  {
    type: TYPE_ATM,
    id: "atm-31",
    info: {
      name: "Bank of Boston A.T.M. 31",
      address1: "153 Hitchcock AE Way",
      address2: "Boston, MA 01953",
    },
    lat: 42.52640078,
    lng: -70.89116274,
  },
  {
    type: TYPE_ATM,
    id: "atm-32",
    info: {
      name: "Bank of Boston A.T.M. 32",
      address1: "154 Hitchcock AF Way",
      address2: "Boston, MA 01954",
    },
    lat: 42.80777642,
    lng: -71.38309079,
  },
  {
    type: TYPE_ATM,
    id: "atm-33",
    info: {
      name: "Bank of Boston A.T.M. 33",
      address1: "155 Hitchcock AG Way",
      address2: "Boston, MA 01955",
    },
    lat: 42.18777929,
    lng: -70.55239429,
  },
  {
    type: TYPE_ATM,
    id: "atm-34",
    info: {
      name: "Bank of Boston A.T.M. 34",
      address1: "156 Hitchcock AH Way",
      address2: "Boston, MA 01956",
    },
    lat: 41.7743734,
    lng: -71.35090168,
  },
  {
    type: TYPE_ATM,
    id: "atm-35",
    info: {
      name: "Bank of Boston A.T.M. 35",
      address1: "157 Hitchcock AI Way",
      address2: "Boston, MA 01957",
    },
    lat: 42.17027655,
    lng: -70.92603449,
  },
  {
    type: TYPE_ATM,
    id: "atm-36",
    info: {
      name: "Bank of Boston A.T.M. 36",
      address1: "158 Hitchcock AJ Way",
      address2: "Boston, MA 01958",
    },
    lat: 42.84599462,
    lng: -71.18526206,
  },
  {
    type: TYPE_ATM,
    id: "atm-37",
    info: {
      name: "Bank of Boston A.T.M. 37",
      address1: "159 Hitchcock AK Way",
      address2: "Boston, MA 01959",
    },
    lat: 42.74372849,
    lng: -71.2343831,
  },
  {
    type: TYPE_ATM,
    id: "atm-38",
    info: {
      name: "Bank of Boston A.T.M. 38",
      address1: "160 Hitchcock AL Way",
      address2: "Boston, MA 01960",
    },
    lat: 42.58039658,
    lng: -70.23654441,
  },
  {
    type: TYPE_ATM,
    id: "atm-39",
    info: {
      name: "Bank of Boston A.T.M. 39",
      address1: "161 Hitchcock AM Way",
      address2: "Boston, MA 01961",
    },
    lat: 42.93157312,
    lng: -71.03909343,
  },
  {
    type: TYPE_ATM,
    id: "atm-40",
    info: {
      name: "Bank of Boston A.T.M. 40",
      address1: "162 Hitchcock AN Way",
      address2: "Boston, MA 01962",
    },
    lat: 42.48625151,
    lng: -71.0026816,
  },
  {
    type: TYPE_ATM,
    id: "atm-41",
    info: {
      name: "Bank of Boston A.T.M. 41",
      address1: "163 Hitchcock AO Way",
      address2: "Boston, MA 01963",
    },
    lat: 42.51167354,
    lng: -72.0145594,
  },
  {
    type: TYPE_ATM,
    id: "atm-42",
    info: {
      name: "Bank of Boston A.T.M. 42",
      address1: "164 Hitchcock AP Way",
      address2: "Boston, MA 01964",
    },
    lat: 42.42302673,
    lng: -71.59776497,
  },
  {
    type: TYPE_ATM,
    id: "atm-43",
    info: {
      name: "Bank of Boston A.T.M. 43",
      address1: "165 Hitchcock AQ Way",
      address2: "Boston, MA 01965",
    },
    lat: 41.86105761,
    lng: -70.83180255,
  },
  {
    type: TYPE_ATM,
    id: "atm-44",
    info: {
      name: "Bank of Boston A.T.M. 44",
      address1: "166 Hitchcock AR Way",
      address2: "Boston, MA 01966",
    },
    lat: 42.66975277,
    lng: -70.24558572,
  },
  {
    type: TYPE_ATM,
    id: "atm-45",
    info: {
      name: "Bank of Boston A.T.M. 45",
      address1: "167 Hitchcock AS Way",
      address2: "Boston, MA 01967",
    },
    lat: 42.93404705,
    lng: -70.97841937,
  },
  {
    type: TYPE_ATM,
    id: "atm-46",
    info: {
      name: "Bank of Boston A.T.M. 46",
      address1: "168 Hitchcock AT Way",
      address2: "Boston, MA 01968",
    },
    lat: 42.87800956,
    lng: -71.54178413,
  },
  {
    type: TYPE_ATM,
    id: "atm-47",
    info: {
      name: "Bank of Boston A.T.M. 47",
      address1: "169 Hitchcock AU Way",
      address2: "Boston, MA 01969",
    },
    lat: 42.17901884,
    lng: -71.19373936,
  },
  {
    type: TYPE_ATM,
    id: "atm-48",
    info: {
      name: "Bank of Boston A.T.M. 48",
      address1: "170 Hitchcock AV Way",
      address2: "Boston, MA 01970",
    },
    lat: 42.59477545,
    lng: -70.68160384,
  },
  {
    type: TYPE_ATM,
    id: "atm-49",
    info: {
      name: "Bank of Boston A.T.M. 49",
      address1: "171 Hitchcock AW Way",
      address2: "Boston, MA 01971",
    },
    lat: 42.35137935,
    lng: -71.17590674,
  },
  {
    type: TYPE_ATM,
    id: "atm-50",
    info: {
      name: "Bank of Boston A.T.M. 50",
      address1: "172 Hitchcock AX Way",
      address2: "Boston, MA 01972",
    },
    lat: 42.04455372,
    lng: -71.35241097,
  },
];

export const OFFICE_LOCATIONS = [
  {
    type: TYPE_DTA_OFFICE,
    id: "office-1",
    info: {
      name: "DHR Branch 1",
      address1: "123 Benefits A Pkwy",
      address2: "Boston, MA 91238",
    },
    lat: 42.25815804,
    lng: -71.0596303,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-2",
    info: {
      name: "DHR Branch 2",
      address1: "123 Benefits B Pkwy",
      address2: "Boston, MA 91239",
    },
    lat: 42.22105815,
    lng: -71.8920367,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-3",
    info: {
      name: "DHR Branch 3",
      address1: "123 Benefits C Pkwy",
      address2: "Boston, MA 91240",
    },
    lat: 42.49060532,
    lng: -71.26550125,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-4",
    info: {
      name: "DHR Branch 4",
      address1: "123 Benefits D Pkwy",
      address2: "Boston, MA 91241",
    },
    lat: 41.96322081,
    lng: -71.32652313,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-5",
    info: {
      name: "DHR Branch 5",
      address1: "123 Benefits E Pkwy",
      address2: "Boston, MA 91242",
    },
    lat: 42.05140483,
    lng: -70.85948868,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-6",
    info: {
      name: "DHR Branch 6",
      address1: "123 Benefits F Pkwy",
      address2: "Boston, MA 91243",
    },
    lat: 42.60848359,
    lng: -71.13235637,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-7",
    info: {
      name: "DHR Branch 7",
      address1: "123 Benefits G Pkwy",
      address2: "Boston, MA 91244",
    },
    lat: 42.38802555,
    lng: -71.59668671,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-8",
    info: {
      name: "DHR Branch 8",
      address1: "123 Benefits H Pkwy",
      address2: "Boston, MA 91245",
    },
    lat: 42.28570021,
    lng: -71.35006756,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-9",
    info: {
      name: "DHR Branch 9",
      address1: "123 Benefits I Pkwy",
      address2: "Boston, MA 91246",
    },
    lat: 41.91502367,
    lng: -71.14204441,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-10",
    info: {
      name: "DHR Branch 10",
      address1: "123 Benefits J Pkwy",
      address2: "Boston, MA 91247",
    },
    lat: 42.30632268,
    lng: -70.92501354,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-11",
    info: {
      name: "DHR Branch 11",
      address1: "123 Benefits K Pkwy",
      address2: "Boston, MA 91248",
    },
    lat: 42.56284396,
    lng: -70.63354631,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-12",
    info: {
      name: "DHR Branch 12",
      address1: "123 Benefits L Pkwy",
      address2: "Boston, MA 91249",
    },
    lat: 42.50746033,
    lng: -70.35524029,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-13",
    info: {
      name: "DHR Branch 13",
      address1: "123 Benefits M Pkwy",
      address2: "Boston, MA 91250",
    },
    lat: 42.42093352,
    lng: -70.27999914,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-14",
    info: {
      name: "DHR Branch 14",
      address1: "123 Benefits N Pkwy",
      address2: "Boston, MA 91251",
    },
    lat: 42.11066158,
    lng: -70.30768903,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-15",
    info: {
      name: "DHR Branch 15",
      address1: "123 Benefits O Pkwy",
      address2: "Boston, MA 91252",
    },
    lat: 42.79697701,
    lng: -70.83169898,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-16",
    info: {
      name: "DHR Branch 16",
      address1: "123 Benefits P Pkwy",
      address2: "Boston, MA 91253",
    },
    lat: 41.83014018,
    lng: -70.70881448,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-17",
    info: {
      name: "DHR Branch 17",
      address1: "123 Benefits Q Pkwy",
      address2: "Boston, MA 91254",
    },
    lat: 41.76676314,
    lng: -70.65133272,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-18",
    info: {
      name: "DHR Branch 18",
      address1: "123 Benefits R Pkwy",
      address2: "Boston, MA 91255",
    },
    lat: 41.77026521,
    lng: -70.9644292,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-19",
    info: {
      name: "DHR Branch 19",
      address1: "123 Benefits S Pkwy",
      address2: "Boston, MA 91256",
    },
    lat: 42.43136077,
    lng: -70.52082393,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-20",
    info: {
      name: "DHR Branch 20",
      address1: "123 Benefits T Pkwy",
      address2: "Boston, MA 91257",
    },
    lat: 42.73379377,
    lng: -71.36003611,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-21",
    info: {
      name: "DHR Branch 21",
      address1: "123 Benefits U Pkwy",
      address2: "Boston, MA 91258",
    },
    lat: 41.80191479,
    lng: -70.55718654,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-22",
    info: {
      name: "DHR Branch 22",
      address1: "123 Benefits V Pkwy",
      address2: "Boston, MA 91259",
    },
    lat: 42.79430521,
    lng: -70.7066546,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-23",
    info: {
      name: "DHR Branch 23",
      address1: "123 Benefits W Pkwy",
      address2: "Boston, MA 91260",
    },
    lat: 42.85899358,
    lng: -70.35694338,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-24",
    info: {
      name: "DHR Branch 24",
      address1: "123 Benefits X Pkwy",
      address2: "Boston, MA 91261",
    },
    lat: 42.75248561,
    lng: -70.94094629,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-25",
    info: {
      name: "DHR Branch 25",
      address1: "123 Benefits Y Pkwy",
      address2: "Boston, MA 91262",
    },
    lat: 41.90765389,
    lng: -71.44893898,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-26",
    info: {
      name: "DHR Branch 26",
      address1: "123 Benefits Z Pkwy",
      address2: "Boston, MA 91263",
    },
    lat: 41.9432666,
    lng: -71.07177679,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-27",
    info: {
      name: "DHR Branch 27",
      address1: "123 Benefits AA Pkwy",
      address2: "Boston, MA 91264",
    },
    lat: 42.25211863,
    lng: -70.20816577,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-28",
    info: {
      name: "DHR Branch 28",
      address1: "123 Benefits AB Pkwy",
      address2: "Boston, MA 91265",
    },
    lat: 42.32327327,
    lng: -71.92194465,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-29",
    info: {
      name: "DHR Branch 29",
      address1: "123 Benefits AC Pkwy",
      address2: "Boston, MA 91266",
    },
    lat: 42.12397968,
    lng: -71.9148268,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-30",
    info: {
      name: "DHR Branch 30",
      address1: "123 Benefits AD Pkwy",
      address2: "Boston, MA 91267",
    },
    lat: 41.96222006,
    lng: -71.43347118,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-31",
    info: {
      name: "DHR Branch 31",
      address1: "123 Benefits AE Pkwy",
      address2: "Boston, MA 91268",
    },
    lat: 42.57155004,
    lng: -71.49015637,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-32",
    info: {
      name: "DHR Branch 32",
      address1: "123 Benefits AF Pkwy",
      address2: "Boston, MA 91269",
    },
    lat: 42.90386865,
    lng: -70.71276039,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-33",
    info: {
      name: "DHR Branch 33",
      address1: "123 Benefits AG Pkwy",
      address2: "Boston, MA 91270",
    },
    lat: 42.24884495,
    lng: -71.08320576,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-34",
    info: {
      name: "DHR Branch 34",
      address1: "123 Benefits AH Pkwy",
      address2: "Boston, MA 91271",
    },
    lat: 41.78146354,
    lng: -70.64324127,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-35",
    info: {
      name: "DHR Branch 35",
      address1: "123 Benefits AI Pkwy",
      address2: "Boston, MA 91272",
    },
    lat: 42.11994362,
    lng: -70.52435578,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-36",
    info: {
      name: "DHR Branch 36",
      address1: "123 Benefits AJ Pkwy",
      address2: "Boston, MA 91273",
    },
    lat: 42.81639602,
    lng: -70.39638122,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-37",
    info: {
      name: "DHR Branch 37",
      address1: "123 Benefits AK Pkwy",
      address2: "Boston, MA 91274",
    },
    lat: 42.39963659,
    lng: -71.52627316,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-38",
    info: {
      name: "DHR Branch 38",
      address1: "123 Benefits AL Pkwy",
      address2: "Boston, MA 91275",
    },
    lat: 42.37927491,
    lng: -71.74865266,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-39",
    info: {
      name: "DHR Branch 39",
      address1: "123 Benefits AM Pkwy",
      address2: "Boston, MA 91276",
    },
    lat: 42.69603239,
    lng: -70.36404091,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-40",
    info: {
      name: "DHR Branch 40",
      address1: "123 Benefits AN Pkwy",
      address2: "Boston, MA 91277",
    },
    lat: 42.47089695,
    lng: -70.58122087,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-41",
    info: {
      name: "DHR Branch 41",
      address1: "123 Benefits AO Pkwy",
      address2: "Boston, MA 91278",
    },
    lat: 42.14230674,
    lng: -70.37766629,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-42",
    info: {
      name: "DHR Branch 42",
      address1: "123 Benefits AP Pkwy",
      address2: "Boston, MA 91279",
    },
    lat: 42.80355814,
    lng: -71.60935001,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-43",
    info: {
      name: "DHR Branch 43",
      address1: "123 Benefits AQ Pkwy",
      address2: "Boston, MA 91280",
    },
    lat: 42.27772164,
    lng: -71.3883392,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-44",
    info: {
      name: "DHR Branch 44",
      address1: "123 Benefits AR Pkwy",
      address2: "Boston, MA 91281",
    },
    lat: 42.39215912,
    lng: -71.84141252,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-45",
    info: {
      name: "DHR Branch 45",
      address1: "123 Benefits AS Pkwy",
      address2: "Boston, MA 91282",
    },
    lat: 41.82152936,
    lng: -71.60838222,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-46",
    info: {
      name: "DHR Branch 46",
      address1: "123 Benefits AT Pkwy",
      address2: "Boston, MA 91283",
    },
    lat: 42.19043535,
    lng: -71.92058099,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-47",
    info: {
      name: "DHR Branch 47",
      address1: "123 Benefits AU Pkwy",
      address2: "Boston, MA 91284",
    },
    lat: 42.64472759,
    lng: -71.03195594,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-48",
    info: {
      name: "DHR Branch 48",
      address1: "123 Benefits AV Pkwy",
      address2: "Boston, MA 91285",
    },
    lat: 42.92586014,
    lng: -71.26130535,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-49",
    info: {
      name: "DHR Branch 49",
      address1: "123 Benefits AW Pkwy",
      address2: "Boston, MA 91286",
    },
    lat: 42.18958098,
    lng: -70.87317549,
  },
  {
    type: TYPE_DTA_OFFICE,
    id: "office-50",
    info: {
      name: "DHR Branch 50",
      address1: "123 Benefits AX Pkwy",
      address2: "Boston, MA 91287",
    },
    lat: 42.49185537,
    lng: -71.5401732,
  },
];

export const FARMERS_MARKET_LOCATIONS = [
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-1",
    info: {
      name: "Plentiful Food Market 1",
      address1: "1323 Harvest Lane",
      address2: "Boston, MA, 02912",
    },
    lat: 42.14614876,
    lng: -71.9171704,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-2",
    info: {
      name: "Plentiful Food Market 2",
      address1: "1324 Harvest Lane",
      address2: "Boston, MA, 02913",
    },
    lat: 42.26303758,
    lng: -70.28396869,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-3",
    info: {
      name: "Plentiful Food Market 3",
      address1: "1325 Harvest Lane",
      address2: "Boston, MA, 02914",
    },
    lat: 42.49168931,
    lng: -71.30380822,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-4",
    info: {
      name: "Plentiful Food Market 4",
      address1: "1326 Harvest Lane",
      address2: "Boston, MA, 02915",
    },
    lat: 42.97927081,
    lng: -70.82652544,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-5",
    info: {
      name: "Plentiful Food Market 5",
      address1: "1327 Harvest Lane",
      address2: "Boston, MA, 02916",
    },
    lat: 41.99544299,
    lng: -71.57126474,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-6",
    info: {
      name: "Plentiful Food Market 6",
      address1: "1328 Harvest Lane",
      address2: "Boston, MA, 02917",
    },
    lat: 42.04568385,
    lng: -71.45822524,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-7",
    info: {
      name: "Plentiful Food Market 7",
      address1: "1329 Harvest Lane",
      address2: "Boston, MA, 02918",
    },
    lat: 42.48647215,
    lng: -70.23527084,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-8",
    info: {
      name: "Plentiful Food Market 8",
      address1: "1330 Harvest Lane",
      address2: "Boston, MA, 02919",
    },
    lat: 42.33098934,
    lng: -70.73941088,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-9",
    info: {
      name: "Plentiful Food Market 9",
      address1: "1331 Harvest Lane",
      address2: "Boston, MA, 02920",
    },
    lat: 42.23852971,
    lng: -70.83957012,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-10",
    info: {
      name: "Plentiful Food Market 10",
      address1: "1332 Harvest Lane",
      address2: "Boston, MA, 02921",
    },
    lat: 41.80582701,
    lng: -71.25254741,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-11",
    info: {
      name: "Plentiful Food Market 11",
      address1: "1333 Harvest Lane",
      address2: "Boston, MA, 02922",
    },
    lat: 41.99496154,
    lng: -71.63740422,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-12",
    info: {
      name: "Plentiful Food Market 12",
      address1: "1334 Harvest Lane",
      address2: "Boston, MA, 02923",
    },
    lat: 42.97926359,
    lng: -71.32057039,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-13",
    info: {
      name: "Plentiful Food Market 13",
      address1: "1335 Harvest Lane",
      address2: "Boston, MA, 02924",
    },
    lat: 42.20781806,
    lng: -71.35267784,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-14",
    info: {
      name: "Plentiful Food Market 14",
      address1: "1336 Harvest Lane",
      address2: "Boston, MA, 02925",
    },
    lat: 42.19150043,
    lng: -71.38355803,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-15",
    info: {
      name: "Plentiful Food Market 15",
      address1: "1337 Harvest Lane",
      address2: "Boston, MA, 02926",
    },
    lat: 42.42048299,
    lng: -71.31843179,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-16",
    info: {
      name: "Plentiful Food Market 16",
      address1: "1338 Harvest Lane",
      address2: "Boston, MA, 02927",
    },
    lat: 42.91165897,
    lng: -70.46353608,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-17",
    info: {
      name: "Plentiful Food Market 17",
      address1: "1339 Harvest Lane",
      address2: "Boston, MA, 02928",
    },
    lat: 42.70799953,
    lng: -71.01923811,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-18",
    info: {
      name: "Plentiful Food Market 18",
      address1: "1340 Harvest Lane",
      address2: "Boston, MA, 02929",
    },
    lat: 42.44327206,
    lng: -71.34634105,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-19",
    info: {
      name: "Plentiful Food Market 19",
      address1: "1341 Harvest Lane",
      address2: "Boston, MA, 02930",
    },
    lat: 42.34454542,
    lng: -70.79143948,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-20",
    info: {
      name: "Plentiful Food Market 20",
      address1: "1342 Harvest Lane",
      address2: "Boston, MA, 02931",
    },
    lat: 42.77536049,
    lng: -71.16160913,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-21",
    info: {
      name: "Plentiful Food Market 21",
      address1: "1343 Harvest Lane",
      address2: "Boston, MA, 02932",
    },
    lat: 42.72682309,
    lng: -70.28762734,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-22",
    info: {
      name: "Plentiful Food Market 22",
      address1: "1344 Harvest Lane",
      address2: "Boston, MA, 02933",
    },
    lat: 42.04549988,
    lng: -71.65749745,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-23",
    info: {
      name: "Plentiful Food Market 23",
      address1: "1345 Harvest Lane",
      address2: "Boston, MA, 02934",
    },
    lat: 41.80842998,
    lng: -71.18763586,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-24",
    info: {
      name: "Plentiful Food Market 24",
      address1: "1346 Harvest Lane",
      address2: "Boston, MA, 02935",
    },
    lat: 42.13317243,
    lng: -70.402718,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-25",
    info: {
      name: "Plentiful Food Market 25",
      address1: "1347 Harvest Lane",
      address2: "Boston, MA, 02936",
    },
    lat: 41.66515015,
    lng: -71.24182199,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-26",
    info: {
      name: "Plentiful Food Market 26",
      address1: "1348 Harvest Lane",
      address2: "Boston, MA, 02937",
    },
    lat: 42.79578556,
    lng: -70.50339313,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-27",
    info: {
      name: "Plentiful Food Market 27",
      address1: "1349 Harvest Lane",
      address2: "Boston, MA, 02938",
    },
    lat: 42.40624512,
    lng: -70.77776173,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-28",
    info: {
      name: "Plentiful Food Market 28",
      address1: "1350 Harvest Lane",
      address2: "Boston, MA, 02939",
    },
    lat: 43.03781152,
    lng: -71.17084227,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-29",
    info: {
      name: "Plentiful Food Market 29",
      address1: "1351 Harvest Lane",
      address2: "Boston, MA, 02940",
    },
    lat: 42.88945462,
    lng: -71.49241957,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-30",
    info: {
      name: "Plentiful Food Market 30",
      address1: "1352 Harvest Lane",
      address2: "Boston, MA, 02941",
    },
    lat: 41.91625422,
    lng: -71.45942859,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-31",
    info: {
      name: "Plentiful Food Market 31",
      address1: "1353 Harvest Lane",
      address2: "Boston, MA, 02942",
    },
    lat: 42.28392356,
    lng: -70.44998669,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-32",
    info: {
      name: "Plentiful Food Market 32",
      address1: "1354 Harvest Lane",
      address2: "Boston, MA, 02943",
    },
    lat: 42.25707179,
    lng: -70.90325042,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-33",
    info: {
      name: "Plentiful Food Market 33",
      address1: "1355 Harvest Lane",
      address2: "Boston, MA, 02944",
    },
    lat: 42.23819241,
    lng: -71.1876064,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-34",
    info: {
      name: "Plentiful Food Market 34",
      address1: "1356 Harvest Lane",
      address2: "Boston, MA, 02945",
    },
    lat: 42.6022639,
    lng: -70.16610485,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-35",
    info: {
      name: "Plentiful Food Market 35",
      address1: "1357 Harvest Lane",
      address2: "Boston, MA, 02946",
    },
    lat: 43.03747311,
    lng: -70.96348304,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-36",
    info: {
      name: "Plentiful Food Market 36",
      address1: "1358 Harvest Lane",
      address2: "Boston, MA, 02947",
    },
    lat: 42.68355076,
    lng: -70.19943953,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-37",
    info: {
      name: "Plentiful Food Market 37",
      address1: "1359 Harvest Lane",
      address2: "Boston, MA, 02948",
    },
    lat: 42.75546201,
    lng: -70.51692379,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-38",
    info: {
      name: "Plentiful Food Market 38",
      address1: "1360 Harvest Lane",
      address2: "Boston, MA, 02949",
    },
    lat: 42.2097149,
    lng: -70.55768453,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-39",
    info: {
      name: "Plentiful Food Market 39",
      address1: "1361 Harvest Lane",
      address2: "Boston, MA, 02950",
    },
    lat: 41.80403344,
    lng: -71.50818721,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-40",
    info: {
      name: "Plentiful Food Market 40",
      address1: "1362 Harvest Lane",
      address2: "Boston, MA, 02951",
    },
    lat: 42.77003692,
    lng: -70.94610421,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-41",
    info: {
      name: "Plentiful Food Market 41",
      address1: "1363 Harvest Lane",
      address2: "Boston, MA, 02952",
    },
    lat: 42.20321735,
    lng: -71.11942488,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-42",
    info: {
      name: "Plentiful Food Market 42",
      address1: "1364 Harvest Lane",
      address2: "Boston, MA, 02953",
    },
    lat: 42.68205587,
    lng: -70.66712896,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-43",
    info: {
      name: "Plentiful Food Market 43",
      address1: "1365 Harvest Lane",
      address2: "Boston, MA, 02954",
    },
    lat: 42.0331976,
    lng: -70.19341597,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-44",
    info: {
      name: "Plentiful Food Market 44",
      address1: "1366 Harvest Lane",
      address2: "Boston, MA, 02955",
    },
    lat: 42.11906541,
    lng: -71.30211305,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-45",
    info: {
      name: "Plentiful Food Market 45",
      address1: "1367 Harvest Lane",
      address2: "Boston, MA, 02956",
    },
    lat: 42.5939971,
    lng: -70.82518645,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-46",
    info: {
      name: "Plentiful Food Market 46",
      address1: "1368 Harvest Lane",
      address2: "Boston, MA, 02957",
    },
    lat: 41.91535446,
    lng: -71.06137294,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-47",
    info: {
      name: "Plentiful Food Market 47",
      address1: "1369 Harvest Lane",
      address2: "Boston, MA, 02958",
    },
    lat: 41.69422854,
    lng: -71.42482104,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-48",
    info: {
      name: "Plentiful Food Market 48",
      address1: "1370 Harvest Lane",
      address2: "Boston, MA, 02959",
    },
    lat: 42.61803708,
    lng: -70.68824527,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-49",
    info: {
      name: "Plentiful Food Market 49",
      address1: "1371 Harvest Lane",
      address2: "Boston, MA, 02960",
    },
    lat: 42.40900101,
    lng: -70.44469117,
  },
  {
    type: TYPE_FARMERS_MARKET,
    id: "market-50",
    info: {
      name: "Plentiful Food Market 50",
      address1: "1372 Harvest Lane",
      address2: "Boston, MA, 02961",
    },
    lat: 42.18499847,
    lng: -70.59398452,
  },
];

export { TYPE_ATM, TYPE_DTA_OFFICE, TYPE_FARMERS_MARKET };
