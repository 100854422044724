import React from "react";
import { withMsal } from "@azure/msal-react";
import _ from "lodash";
import { connect }  from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { formSummaryObj } from "../../../utils/components/utilities/utils";
import SectionSummary from "../../applySnap/components/SectionSummary";
import { getSection } from "../../../utils/helper/pageRoutingHelper";

const SnapSixMonthReportContainer = (props) => {
  const { sections } = props.interimReport;
  const interimSections = _.cloneDeep(sections);
  const summarySection = props.interimReport.summaryData;

  summarySection && summarySection.length > 0 && summarySection.forEach(section => {
    const matchedSection = getSection(section.id, interimSections);
    if(matchedSection) {
      matchedSection.subsections = section.subsections;
    }
  })

  const summaryData = formSummaryObj(interimSections.slice(1));


  return (
    <div>
       {summaryData.map((section) => {
            return <SectionSummary section={section}  key={section.sectionId} applicationType="interim" />;
        })
        }
     
    </div>
  );
}

export default withMsal(connect(mapStateToProps)(SnapSixMonthReportContainer));



