import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
var config = require("../../utils/constants/appConfigConstants");


const actionTypes = {
  onLanguageCodeChange: "LANGUAGE_CHANGED_SUCCESS",
  serviceFailedAction: "SERVICE_FAILED",
};

export function languageCodeAction(languageCode) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const audit = getAudit();
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const Headers = {
        headers: {
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      console.log("language code" + languageCode);
      let langCode = languageCode.substring(0, 2);
      console.log("language code" + langCode);
      //setTimeout(() => {
        axios
          .get(
            config.TigerContextURL + "/languagecode/" + languageCode,
            Headers
          )
          .then(function (response) {
            console.log(response);
           // window.location.reload();
          //  langChangeSaved
             dispatch(onLanguageCodeChangedSuccess(true));
          })
          .catch(function (error) {
             dispatch(onLanguageCodeChangedSuccess(true));
          });
 //     }, 1500);
    });
  };
}

function onLanguageCodeChangedSuccess(data) {
  return {
    type: actionTypes.onLanguageCodeChange,
    payload: data,
  };
}

function serviceFailedAction(response) {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
