import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as languageConsts from "../constants/types";
import { langOptSel } from "../constants/constants";
//end code for google analytics

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === langOptSel) {
    language = "en-US";
  }
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

class TermsDSnap extends Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  render() {
    let appType = sessionStorage.getItem("applicationType");
    if(appType === "DSNAP"){
      switch(language) {
        case languageConsts.ENGLISH:
          return (
            <div>
             <p>
                 <strong>USDA Nondiscrimination Statement</strong>
             </p>
             <p>
                In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights
                regulations and policies, this institution is prohibited from discriminating on the basis of race, color,
                national origin, sex (including gender identity and sexual orientation), religious creed, disability, age,
                political beliefs, or reprisal or retaliation for prior civil rights activity.
                Program information may be made available in languages other than English. Persons with disabilities who
                require alternative means of communication to obtain program information (e.g., Braille, large print, audiotape,
                American Sign Language), should contact the agency (state or local) where they applied for benefits. Individuals who
                are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at (800) 877-8339.
                To file a program discrimination complaint, a Complainant should complete a Form AD-3027, USDA Program Discrimination Complaint
                Form which can be obtained online at:<a> https://www.usda.gov/sites/ default/files/documents/ad-3027.pdf</a>, from any USDA office, by calling (833) 620-1071,
                or by writing a letter addressed to USDA. The letter must contain the complainant's name, address, telephone number, and a written description of the
                alleged discriminatory action in sufficient detail to inform the Assistant Secretary for Civil Rights (ASCR) about the nature and date of
                an alleged civil rights violation. The completed AD-3027 form or letter must be submitted to:
             </p>
             <p>
              <strong>1.mail</strong>
                <p style={{marginTop:"-1px"}}>Food and Nutrition Service, USDA <br/>
                  1320 Braddock Place, Room 334  <br/>
                  Alexandria, VA 22314; or
                </p>
             </p>
             <p>
              <strong>2. fax:</strong>
              <p style={{marginTop:"-1px"}}>(833) 256-1665 or (202) 690-7442; or</p>
             </p>
             <p> 
              <strong>3. email:</strong><br/>
              <a style={{marginTop:"-1px"}}>ENSCIVILRIGHTSCOMPLAINTS@usda.gov</a>
             </p>

             <strong>Penalty Warning</strong>
              <p>If your household gets food assistance benefits, it must follow the rules listed below. We may choose your
                household for a Federal or State review sometime after you receive your food assistance benefits to make sure
                you were eligible for disaster aid. <br/>
                DO NOT give false information or hide information to get or to continue to get food assistance benefits.<br/>
                DO NOT give or sell food assistance benefits or authorization documents to anyone not authorized to use
                them.<br/>
                DO NOT alter any food assistance benefits or authorization documents to get food assistance benefits you
                are not entitled to.<br/>
                DO NOT use food assistance benefits to buy unauthorized items such as alcohol or tobacco.<br/>
                DO NOT use another household's food assistance benefits or authorization document for your household.</p>


                <strong>Certification & Signature</strong>
              <p>
                I understand the questions on this prescreening form and the penalties for hiding or giving false information.
                My household is in need of immediate food assistance as a result of the disaster. I certify, under penalty of 
                perjury, that the information I have given is correct and complete to the best of my knowledge. I also 
                authorize the release of any information necessary to determine the correctness of my certification. I 
                understand that if I disagree with any action taken on my case, I have the right to request a fair hearing orally
                or in writing.

              </p>
            
           </div>
          );

          case languageConsts.SPANISH:
            return (
              <div>
             <p>
                 <strong>Declaración de no discriminación del USDA</strong>
             </p>
             <p>
             De acuerdo con la ley federal de derechos civiles y los derechos civiles del Departamento de Agricultura de EE. UU. (USDA)
                regulaciones y políticas, esta institución tiene prohibido discriminar por motivos de raza, color,
                origen nacional, sexo (incluyendo identidad de género y orientación sexual), credo religioso, discapacidad, edad,
                creencias políticas, o represalias por actividades anteriores de derechos civiles.
                La información del programa puede estar disponible en otros idiomas además del inglés. Las personas con discapacidad que
                requieren medios de comunicación alternativos para obtener información del programa (por ejemplo, Braille, letra grande, cintas de audio,
                Lenguaje de señas americano), deben comunicarse con la agencia (estatal o local) donde solicitaron los beneficios. Individuos que
                son sordos, tienen problemas de audición o del habla pueden comunicarse con el USDA a través del Servicio Federal de Retransmisión al (800) 877-8339.
                Para presentar una queja por discriminación en el programa, el demandante debe completar un formulario AD-3027, Queja por discriminación en el programa del USDA.
                Formulario que se puede obtener en línea en:<a> https://www.usda.gov/sites/ default/files/documents/ad-3027.pdf</a>, desde cualquier oficina del USDA, llamando al (833) 620 -1071,
                o escribiendo una carta dirigida al USDA. La carta debe contener el nombre, dirección, número de teléfono y una descripción escrita del demandante.
                presunta acción discriminatoria con suficiente detalle para informar al Subsecretario de Derechos Civiles (ASCR) sobre la naturaleza y fecha de
                una supuesta violación de los derechos civiles. El formulario o carta AD-3027 completo debe enviarse a:
             </p>
             <p>
              <strong>1.correo</strong>
                <p style={{marginTop:"-1px"}}>Servicio de Alimentos y Nutrición, USDA<br/>
                1320 Braddock Place, Sala 334 <br/>
                Alexandria, VA 22314; o
                </p>
             </p>
             <p>
              <strong>2. fax:</strong>
              <p style={{marginTop:"-1px"}}>(833) 256-1665 o (202) 690-7442; o</p>
             </p>
             <p> 
              <strong> 3. correo electrónico:</strong><br/>
              <a style={{marginTop:"-1px"}}>ENSCIVILRIGHTSCOMPLAINTS@usda.gov</a>
             </p>

             <strong>Advertencia de penalización</strong>
              <p>Si su hogar recibe beneficios de asistencia alimentaria, debe seguir las reglas que se enumeran a continuación. Podemos elegir su
                hogar para una revisión federal o estatal en algún momento después de recibir sus beneficios de asistencia alimentaria para asegurarse
                usted era elegible para recibir ayuda por desastre. <br/>
                NO proporcione información falsa ni oculte información para obtener o continuar recibiendo beneficios de asistencia alimentaria.<br/>
                NO entregue ni venda beneficios de asistencia alimentaria ni documentos de autorización a nadie que no esté autorizado a utilizarlos.
                ellos.<br/>
                NO altere ningún beneficio de asistencia alimentaria ni documentos de autorización para obtener los beneficios de asistencia alimentaria que
                no tienen derecho a.<br/>
                NO utilice los beneficios de asistencia alimentaria para comprar artículos no autorizados como alcohol o tabaco.<br/>
                NO utilice los beneficios de asistencia alimentaria ni el documento de autorización de otro hogar para su hogar.</p>


                <strong>Certificación y firma</strong>
              <p>
              Entiendo las preguntas de este formulario de evaluación previa y las sanciones por ocultar o dar información falsa.
                Mi hogar necesita asistencia alimentaria inmediata como resultado del desastre. Certifico, bajo pena de 
                perjurio, que la información que he proporcionado es correcta y completa a mi leal saber y entender. Yo también 
                Autorizo ​​la divulgación de cualquier información necesaria para determinar la exactitud de mi certificación. I 
                Entiendo que si no estoy de acuerdo con cualquier acción tomada en mi caso, tengo derecho a solicitar una audiencia justa de forma oral.
                o por escrito.
              </p>
            
           </div>
            );

      }
    }
          
      }
    }
      export default TermsDSnap;