// Vendor / components
import React, { Component } from "react";
import { mapStateToProps } from "../../../redux/rootReducer";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
// DHR Components
import DTANavigation from "../../../utils/components/accountNavigation";
import DTAFooter from "../../../utils/components/dtaFooter";
import NotFound from "../../../utils/components/notFound";

// Constants
import * as SharedProps from "../../../utils/constants/shared";
import {
  providerNavSections,
  providerNOPSIRoleNavSections,
} from "../mhProviderMapData";
import SnapOutreach from "./snapOutreach";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as TextProps from "../../../utils/constants/text";

class MhProviderApp extends Component {
  constructor(props) {
    super(...arguments);

    this.state = {
      // User should really come from redux or elsewhere
      [SharedProps.USER]: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1989, 9, 13),
      },
      navItems:
        JSON.parse(sessionStorage.getItem("loadConsumerUser")) &&
        Object.keys(JSON.parse(sessionStorage.getItem("loadConsumerUser")))
          .length <= 0
          ? providerNOPSIRoleNavSections
          : providerNavSections,
      activeItemId: providerNavSections[0][SharedProps.ID],
    };
  }
  componentWillMount() {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userObject !== null) {
      this.setState({ user: userObject });
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }
  }
  resolveComponentFromId(id) {
    if (id === "snapOutreach") return SnapOutreach;
    return NotFound;
  }
  render() {
    return (
      <div className="provider page-wrapper">
        <DTANavigation
          navItems={this.state.navItems}
          activeItemId={this.state.activeItemId}
          user={this.state.user}
          mhprovider={TextProps.VALUE_TRUE}
          providerViewingClient={TextProps.VALUE_FALSE}
          skipToAnchor="#content"
        />
        <main className="main mh-provider-container" id="content">
          <Switch>
            <Redirect
              exact
              from={routeProps.HOME_PATH}
              to={routeProps.SNAP_OUTREACH_PATH}
            />
            {providerNavSections.map(({ id }) => {
              return (
                <Route
                  key={id}
                  exact
                  path={`/${id}`}
                  component={this.resolveComponentFromId(id)}
                />
              );
            })}
          </Switch>
        </main>
        <DTAFooter language={this.props.language.home} />
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      gotoLogin: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MhProviderApp)
);
