import React, { Component } from "react";
import { connect } from "react-redux";
import {
  onEmailSubmit,
  onMfaCodeSubmit,
  onPasswordSubmit,
} from "../../redux/user/forgotPasswordActions";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import { mapStateToProps } from "../../redux/rootReducer";
import dtaLogo from "../../utils/resources/images/alabama.png";
import LanguageSelect from "../../redux/language/languageSelect";
import { Link } from "react-router-dom";
import DTAFooter from "../../utils/components/dtaFooter";
import DTALoginModal from "../../utils/components/modal/dtaLoginModal";
import DTAInput from "../../utils/components/dtaInput";
import PasswordValidator from "../../utils/components/passwordValidator";
import Icon from "../../utils/components/dtaIcon";
import * as routeProps from "../../utils/constants/routesConstants";
import * as TextProps from "../../utils/constants/text";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.goToSignup = this.goToSignup.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onMFAChange = this.onMFAChange.bind(this);
    this.onPasswordvalidatorChange = this.onPasswordvalidatorChange.bind(this);
    this.state = {
      isAutErrorMsg: "",
      mfaModel: TextProps.VALUE_FALSE,
      forgotSpinner: TextProps.VALUE_FALSE,
      emailErrorMessage: TextProps.VALUE_FALSE,
      mfaError: TextProps.VALUE_FALSE,
      pwdError: TextProps.VALUE_FALSE,
      hideSubmitButton: TextProps.VALUE_TRUE,
    };
  }

  componentDidMount() {
    this.setState({
      forgetEmailModal: TextProps.VALUE_TRUE,
      forgetEmailErrorState: TextProps.VALUE_FALSE,
      forgetEmailErrorMessage: "",
      forgetMfaModal: TextProps.VALUE_FALSE,
      forgetMfaErrorMessage: "",
      forgetMfaErrorState: TextProps.VALUE_FALSE,
      forgetPasswordModal: TextProps.VALUE_FALSE,
      forgetPasswordErrorState: TextProps.VALUE_FALSE,
      forgetPasswordErrorMessage: "",
      forgotFullSuccess: TextProps.VALUE_FALSE,
      commonErrorState: TextProps.VALUE_FALSE,
      commonErrorMessage: "",
      hideSubmitButton: TextProps.VALUE_TRUE,
    });
    this.setState({ forgotSpinner: TextProps.VALUE_TRUE });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      emailErrorMessage: TextProps.VALUE_FALSE,
      mfaError: TextProps.VALUE_FALSE,
      pwdError: TextProps.VALUE_FALSE,
      password: "",
    });
    if (
      nextProps.consumer.forgetEmailSuccessful === TextProps.VALUE_TRUE &&
      this.state.forgetEmailModal === TextProps.VALUE_TRUE
    ) {
      this.setState({
        forgetEmailModal: TextProps.VALUE_FALSE,
        forgetMfaModal: TextProps.VALUE_TRUE,
      });
      this.forgetEMailModal.hideBlur();
    } else if (
      nextProps.consumer.forgetMfaSuccessful === TextProps.VALUE_TRUE &&
      this.state.forgetMfaModal === TextProps.VALUE_TRUE
    ) {
      this.setState({
        forgetEmailModal: TextProps.VALUE_FALSE,
        forgetMfaModal: TextProps.VALUE_FALSE,
        forgetPasswordModal: TextProps.VALUE_TRUE,
      });
      this.forgetMfaModal.hideBlur();
    } else if (
      nextProps.consumer.forgetPasswordSuccessful === TextProps.VALUE_TRUE &&
      this.state.forgetPasswordModal === TextProps.VALUE_TRUE
    ) {
      this.setState({
        forgetEmailModal: TextProps.VALUE_FALSE,
        forgetMfaModal: TextProps.VALUE_FALSE,
        forgetPasswordModal: TextProps.VALUE_FALSE,
        forgotFullSuccess: TextProps.VALUE_TRUE,
      });
      this.forgetPasswordModal.hideBlur();
    } else {
      this.setState({
        forgetMfaModal: TextProps.VALUE_FALSE,
        forgetPasswordModal: TextProps.VALUE_FALSE,
        forgotFullSuccess: TextProps.VALUE_FALSE,
      });
      if (nextProps.consumer.forgetEmailErrorState === TextProps.VALUE_TRUE) {
        this.setState({
          commonErrorState: TextProps.VALUE_TRUE,
          commonErrorMessage: nextProps.consumer.forgetEmailErrorMessage,
        });
      } else if (
        nextProps.consumer.forgetMfaErrorState === TextProps.VALUE_TRUE
      ) {
        this.setState({
          commonErrorState: TextProps.VALUE_TRUE,
          commonErrorMessage: nextProps.consumer.forgetMfaErrorMessage,
        });
      } else if (
        nextProps.consumer.forgetPasswordErrorState === TextProps.VALUE_TRUE
      ) {
        this.setState({
          commonErrorState: TextProps.VALUE_TRUE,
          commonErrorMessage: nextProps.consumer.forgetPasswordErrorMessage,
        });
      } else {
        this.setState({
          commonErrorState: TextProps.VALUE_FALSE,
        });
      }
      this.setState({
        forgetEmailModal: TextProps.VALUE_TRUE,
      });
      this.forgetEMailModal.hideBlur();
    }
  }

  goToLogin() {
    this.props.goToLogin();
  }

  submitEmailSpinner = (e) => {
    e.preventDefault();
    this.submitEmail();
  };

  submitEmail = () => {
    const { forgotSpinner } = this.state;
    if (!forgotSpinner) {
      this.forgetEMailModal.hideBlur();
    } else {
      this.forgetEMailModal.showBlur("loading", "");
    }
    let emailDetails = {};
    emailDetails.email = this.state.email;
    this.props.onEmailSubmit(emailDetails);
  };
  submitMfaSpinner = (e) => {
    e.preventDefault();
    this.submitMfa();
  };

  submitMfa = () => {
    const { forgotSpinner } = this.state;
    if (!forgotSpinner) {
      this.forgetMfaModal.hideBlur();
    } else {
      this.forgetMfaModal.showBlur("loading", "");
    }
    let mfaDetails = {};
    mfaDetails.Answer = this.state.mfaCode;
    mfaDetails.MechanismId = this.props.consumer.forgetEmailDetails.MechanismId;
    mfaDetails.SessionId = this.props.consumer.forgetEmailDetails.SessionId;
    mfaDetails.email = this.props.consumer.forgetEmailDetails.email;
    this.props.onMfaCodeSubmit(mfaDetails);
  };

  submitPasswordSpinner = (e) => {
    e.preventDefault();
    this.submitPassword();
  };
  submitPassword = (e) => {
    const { forgotSpinner } = this.state;
    if (!forgotSpinner) {
      this.forgetPasswordModal.hideBlur();
    } else {
      this.forgetPasswordModal.showBlur("loading", "");
    }
    let passwordDetails = {};
    passwordDetails.Answer = this.state.password;
    passwordDetails.MechanismId =
      this.props.consumer.forgetMfaDetails.MechanismId;
    passwordDetails.SessionId = this.props.consumer.forgetMfaDetails.SessionId;
    passwordDetails.email = this.props.consumer.forgetMfaDetails.email;
    this.props.onPasswordSubmit(passwordDetails);
  };

  onCloseModal = () => {
    window.location.assign(routeProps.HOME_PATH);
  };

  onEmailChange = (event) => {
    this.setState({ commonErrorState: TextProps.VALUE_FALSE });
    const { resetpassword } = this.props.language.security;
    this.setState({ email: this.refs.email.inputEl.value });
    let emailDetails = {};
    emailDetails.User = this.state.email;
    var emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (
      !emailRegEx.test(emailDetails.User) ||
      emailDetails.User === undefined
    ) {
      this.setState({ emailErrorMessage: TextProps.VALUE_TRUE });
      this.emailErrMSg = resetpassword.emailErrorMessage;
      event.preventDefault();
    } else {
      this.emailErrMSg = "";
    }
  };
  onMFAChange = (event) => {
    const { resetpassword } = this.props.language.security;
    this.setState({ mfaCode: this.refs.mfaCode.inputEl.value });
    let mfaDetails = {};
    mfaDetails.Answer = this.state.mfaCode;
    if (
      mfaDetails.Answer === null ||
      mfaDetails.Answer === undefined ||
      mfaDetails.Answer === ""
    ) {
      this.setState({ mfaError: TextProps.VALUE_TRUE });
      this.mfaErrorMessage = resetpassword.MFAErrorMessage;
      event.preventDefault();
    } else {
      this.mfaErrorMessage = "";
    }
  };
  onPasswordChange = (event) => {
    const { resetpassword } = this.props.language.security;
    let passwordDetails = {};
    passwordDetails.Answer = this.state.password;
    if (
      passwordDetails.Answer === null ||
      passwordDetails.Answer === undefined ||
      passwordDetails.Answer === ""
    ) {
      this.setState({ pwdError: TextProps.VALUE_TRUE });
      this.pswErrorMessage = resetpassword.passworErrorMessage;
      event.preventDefault();
    } else {
      this.pswErrorMessage = "";
    }
  };
  onPasswordvalidatorChange(validatorData) {
    this.setState({
      hideSubmitButton: validatorData && validatorData.isInvalid,
      password: validatorData.value,
    });
  }
  render() {
    const { resetpassword } = this.props.language.security;
    const { home } = this.props.language;
    let commonErrMsg = this.state.commonErrorMessage;
    let errMsg = {
      userNotExists: "User Not Exists",
      authFailed:
        "Authentication (login or challenge) has failed. Please try again or contact your system administrator.",
      exitsPsw: "New password is the same as the old password",
      Last3Psws: "New password has been used previously",
      pswCriteria: "New password does not meet complexity requirements",
    };

    if (this.state.email !== undefined && this.state.email !== null) {
      var maskEmail = this.state.email;
      var maskedEmail = obscure_email(maskEmail);
    }

    function obscure_email(email) {
      var parts = email.split("@");
      var result = "";
      for (let name of parts[0]) {
        if (name) {
          result += "*";
        }
      }
      result += "@";
      var domain = parts[1];
      result += domain;
      return result;
    }
    return (
      <div className="home page-wrapper">
        <nav className="nav homepage__nav">
          <div className="navbar navbar--white">
            <div className="max-width">
              <Link to={routeProps.HOME_PATH} className="navbar__logo">
                <img src={dtaLogo} alt="ACES DHR Self-Service Portal home" />
              </Link>
              <h1 className="nav__title hidden-touch">ACES DHR Self-Service Portal</h1>
              <ul className="nav-list navbar__nav-list">
              <li className="nav-list__item1">Translate Page</li>
                <li className="nav-list__item">
                  <LanguageSelect />
                </li>
                <li className="nav-list__item"></li>
              </ul>
            </div>
          </div>
        </nav>
        <main className="main login-modal">
          <section className="homepage__hero-container">
            <div className="hero homepage__hero"></div>
          </section>
          <DTALoginModal
            ref={(instance) => (this.forgetEMailModal = instance)}
            isOpen={this.state.forgetEmailModal}
            modalClass="login-modal"
            titleText="Forget Password"
          >
            <div className="dta-modal__body pad-all dataLoginModel">
              <div className="dta-modal__header">
                <h1 display="inline-block">{resetpassword.forgetPassword}</h1>
                <button
                  className="dta-button--minor close-modal"
                  onClick={this.onCloseModal}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>

              <form
                name="dtaForgetEmail"
                onSubmit={this.submitEmailSpinner.bind(this)}
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <p>{resetpassword.emailAddrs}</p>
                    <div>
                      {this.state.emailErrorMessage ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.emailErrMSg}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="forget-email"
                      maxLength={100}
                      value={this.state.email}
                      ref="email"
                      type="email"
                      onChange={(val) => this.setState({ email: val })}
                      required={TextProps.VALUE_TRUE}
                      error={this.emailErrMSg}
                      ariaLabel={"email"}
                    />
                  </div>
                </div>
                {(() => {
                  if (this.state.commonErrorState === TextProps.VALUE_TRUE) {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 dta-form__error-message">
                          {commonErrMsg === errMsg.userNotExists
                            ? resetpassword.userNotExists
                            : null}
                          {commonErrMsg === errMsg.authFailed
                            ? resetpassword.authFailed
                            : null}
                          {commonErrMsg === errMsg.exitsPsw
                            ? resetpassword.existingPsw
                            : null}
                          {commonErrMsg === errMsg.Last3Psws
                            ? resetpassword.Last3Psws
                            : null}
                          {commonErrMsg === errMsg.pswCriteria
                            ? resetpassword.pswCriteria
                            : null}
                        </div>
                      </div>
                    );
                  }
                })()}
                <div>
                  <button
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    type="submit"
                    onClick={this.onEmailChange}
                  >
                    {resetpassword.subject}
                  </button>
                </div>
              </form>
            </div>
          </DTALoginModal>

          <DTALoginModal
            ref={(instance) => (this.forgetMfaModal = instance)}
            isOpen={this.state.forgetMfaModal}
            modalClass="login-modal"
            titleText="Forget Password"
          >
            <div className="dta-modal__body pad-all dataLoginModel">
              <div className="header-links">
                <a
                  className=" start-over-link forgetPasswordLink"
                  href={routeProps.FORGOT_PASSWORD_PATH}
                >
                  Start Over
                </a>
                <button
                  className="dta-button--minor close-modal"
                  style={{ position: "absolute", right: "0px" }}
                  onClick={this.onCloseModal}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
              <div className="dta-modal__header" style={{ paddingLeft: "0px" }}>
                <h1 style={{ width: "60%" }}>{resetpassword.forgetPassword}</h1>
              </div>
              <form
                name="dtaForgetEmail"
                onSubmit={this.submitMfaSpinner.bind(this)}
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <p>
                      {resetpassword.authCode} {maskedEmail}.{" "}
                      {resetpassword.enterCode}.
                    </p>
                    <div>
                      {this.state.mfaError ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.mfaErrorMessage}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="forget-mfaCode"
                      maxLength={30}
                      ref="mfaCode"
                      onChange={(val) => this.setState({ mfaCode: val })}
                      type="text"
                      required={TextProps.VALUE_TRUE}
                      error={this.mfaErrorMessage}
                      ariaLabel={"code"}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    type="submit"
                    onClick={this.onMFAChange}
                  >
                    {resetpassword.submit}
                  </button>
                </div>
              </form>
            </div>
          </DTALoginModal>

          <DTALoginModal
            ref={(instance) => (this.forgetPasswordModal = instance)}
            isOpen={this.state.forgetPasswordModal}
            modalClass="login-modal"
            titleText="Forget Password"
          >
            <div className="dta-modal__body pad-all dataLoginModel">
              <div className="header-links">
                <a
                  className=" start-over-link forgetPasswordLink"
                  href={routeProps.FORGOT_PASSWORD_PATH}
                >
                  Start Over
                </a>
                <button
                  className="dta-button--minor close-modal"
                  style={{ position: "absolute", right: "0px" }}
                  onClick={this.onCloseModal}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
              <div className="dta-modal__header" style={{ paddingLeft: "0px" }}>
                <h1 className="header-h1">{resetpassword.resetYourPsw}</h1>
              </div>

              <form
                name="dtaForgetEmail"
                onSubmit={this.submitPasswordSpinner.bind(this)}
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <label>
                      {resetpassword.newPsw}
                      <span className="dta-form__help-text">
                        {resetpassword.passwordHint}
                      </span>
                      <PasswordValidator
                        onChange={this.onPasswordvalidatorChange}
                      ></PasswordValidator>
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    disabled={this.state.hideSubmitButton}
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    type="submit"
                    onClick={this.onPasswordChange}
                  >
                    {resetpassword.submit}
                  </button>
                </div>
              </form>
            </div>
          </DTALoginModal>
          <DTALoginModal
            isOpen={this.state.forgotFullSuccess}
            modalClass="login-modal"
            titleText="Forget Password"
          >
            <div className="dta-modal__body pad-all dtaRegisterModel">
              <div className="dta-modal__header">
                <h1>{resetpassword.signupbutton}</h1>
                <button
                  className="dta-button--minor close-modal"
                  /* id={ this.lastUniqueId() } */
                  onClick={this.onCloseModal}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
              <p>{resetpassword.passwordChanged}</p>
              <button
                className="dta-button dta-button--primary dta-button--large dta-login"
                onClick={this.goToLogin.bind(this)}
              >
                {resetpassword.login}
              </button>
            </div>
          </DTALoginModal>
        </main>
        {<DTAFooter language={home} />}
      </div>
    );
  }

  goToSignup = () => {
    this.props.onConsumerSignup();
  };

  _doRetrieve = () => {
    return "";
  };

  _handleChangeForQuestion = () => {
    return "";
  };

  _getUniqueId = () => {
    return "";
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onEmailSubmit: onEmailSubmit,
      onMfaCodeSubmit: onMfaCodeSubmit,
      onPasswordSubmit: onPasswordSubmit,
      goToLogin: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
