import React, { Component } from "react";
import { mapStateToProps } from "../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Radio from "./../../../utils/components/radio";
import { enableUniqueIds } from "react-html-id";
import { recertificationPageLabels } from "../recertificationText";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import {
  setDataChangeStatus,
  setNonCitizenStatusUpdate,
} from "../../../redux/recertification/recertificationActions";
import { nonCitizenCodeMapper } from "../nonCitizenMapData";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class NonCitizen extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      nonCitizenChange: null,
    };
  }

  componentDidMount() {
    if (this.props.recert.noncitizenStatusUpdated !== undefined) {
      this.setState(
        { nonCitizenChange: this.props.recert.noncitizenStatusUpdated },
        () => {
          this.props.setNonCitizenStatusUpdate(this.state.nonCitizenChange);
        }
      );
    } else {
      this.props.setNonCitizenStatusUpdate(this.state.nonCitizenChange);
    }
  }

  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };

  returnRecertificationPageLabel() {
    if (this.props.recert.noncitizenStatusUpdated === TextProps.VALUE_TRUE) {
      return recertificationPageLabels(
        language,
        null,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else {
      if (this.props.recert.noncitizenStatusUpdated === TextProps.VALUE_FALSE) {
        return recertificationPageLabels(
          language,
          null,
          languageConstants.aboutMe
        ).optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  render() {
    return (
      <div className="pad-all--double" key={this.nextUniqueId()}>
        {this.props.recert.responseObject.noncitizenStatus &&
          this.props.recert.responseObject.noncitizenStatus.map((member) => {
            return (
              <div
                key={member.agencyID + member.insDesigStatusCode}
                className="pure-u-1-1"
              >
                <div className="max-width pure-u-1-2 label-text-clr">
                  <span>
                    {member.firstName +
                      " " +
                      member.lastName +
                      " " +
                      member.dateOfBirth}
                    <br />
                    <br />
                  </span>
                </div>
                <div className="max-width pure-u-1-2">
                  <span>
                    {nonCitizenCodeMapper(language)[member.insDesigStatusCode]}
                  </span>
                </div>
                <hr className="divider" />
              </div>
            );
          })}

        <span style={{ position: "relative", top: "1rem" }}>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.nonCitizen
            ).question
          }
        </span>
        <br />
        <br />
        <div
          style={{ position: "relative", top: "2rem" }}
          className="pure-u-1-1"
        >
          <div>
            <fieldset className="dta-form__group">
              {this.filterData(this.props.recert.sections, "expedited-snap").selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    recertificationPageLabels(
                      language,
                      null,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                name="citienshipchanged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnRecertificationPageLabel()}
                onChange={(value) => this.handleCitizenshipStatusChange(value)}
              />
            </fieldset>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  handleCitizenshipStatusChange = (value) => {
    this.setState(
      {
        nonCitizenChange:
          value ===
          recertificationPageLabels(language, null, languageConstants.aboutMe)
            .optionYesNo[0]
            ? TextProps.VALUE_TRUE
            : TextProps.VALUE_FALSE,
      },
      () => {
        this.props.setNonCitizenStatusUpdate(
          value ===
            recertificationPageLabels(language, null, languageConstants.aboutMe)
              .optionYesNo[0]
            ? TextProps.VALUE_TRUE
            : TextProps.VALUE_FALSE
        );
        this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 3);
      }
    );
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDataChangeStatus: setDataChangeStatus,
      setNonCitizenStatusUpdate: setNonCitizenStatusUpdate,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NonCitizen);
