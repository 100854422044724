
// Vendor / components
import React, { Component } from "react";
import { debounce } from "lodash";
import { enableUniqueIds } from "react-html-id";
// DHR Components
import ControlWrapper from "../../../utils/components/controlWrappers/controlWrapper";
import MapWrapper from "../../../utils/components/map/mapWrapper";
import DTAMarker from "../../../utils/components/map/dtaMarker";
// Utilities
import { isCoord, phone } from "../../../utils/components/utilities/formatters";
// Static data
import * as FiltersData from "../../../utils/constants/searchFilters";
import atmIcon from "../../../utils/resources/images/atm-red-dot.png";
import redIcon from "../../../utils/resources/images/red-dot.png";
import marketIcon from "../../../utils/resources/images/farmers-market-red-dot.png";
// User-specific data
import * as MapData from "../../../utils/constants/mapData";
import * as Props from "../../../utils/components/shared";
import Icon from "../../../utils/components/dtaIcon";
import * as TextProps from "../../../utils/constants/text";
import { MAS_GOV, SNAP_HOMEPAGE } from "../../../utils/constants/constants";

const MAP_API_KEY = "AIzaSyD-Z7JCH4PpEhBRbN0OSgYO4HT523zQcbo";

class ConsumerDTAInfo extends Component {
  state = {
    selectedFilters: [
      FiltersData.DTA_OFFICE_VALUE,
      FiltersData.ATM_VALUE,
      FiltersData.MARKET_VALUE,
    ],
    searchValue: "",
    screenReaderSearchNotice: "",
    center: {},
    zoom: 13,
  };

  constructor() {
    super(...arguments);
    enableUniqueIds(this);

    this._handleSearchHelper = debounce(this._handleSearchHelper, 500);
  }

  render() {
    const { center, zoom } = this.state;
    return (
      <div>
        <div className="max-width-myinfo max-width--three-quarters pad-all--double pad-bottom-none">
          <h3 className="text--gray margin-top-none">QUICK LINKS</h3>
          <a href={SNAP_HOMEPAGE}>SNAP Homepage</a>
          <p>
            Lost or missing forms? Search the{" "}
            <a href="#">top 10 most common forms</a>.
          </p>
          <p>
            For more information about SNAP, TANF, DHR, and more, visit{" "}
            <a href={MAS_GOV}>mass.gov</a>.
          </p>
          <h3 className="text--gray margin-top-double">
            DHR CONTACT INFORMATION
          </h3>
          <ul className="list--sectioned-card">
            <li className="pure-g">
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="text--gray margin-top-none">Title</p>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="margin-top-none">
                  Commonwealth of Alabama,
                  <br />
                  Department of Transitional Assistance
                </p>
              </div>
            </li>
            <li className="pure-g">
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="text--gray margin-top-none">
                  DHR Assistance Line
                </p>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="margin-top-none">
                  <b>Need help? Call us here</b>
                  <br />
                  <a href="tel:18773822363">{phone("(877)382-2363")}</a>
                </p>
              </div>
            </li>
            <li className="pure-g">
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="text--gray margin-top-none">
                  DHR Document Processing Center
                </p>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="margin-top-none">
                  <b>Sending in forms? Send mail here</b>
                  <br />
                  P.O. Box 4406
                  <br />
                  Taunton, MA 02780-0420
                </p>
              </div>
            </li>
            <li className="pure-g">
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="text--gray margin-top-none">Fax</p>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">
                <p className="margin-top-none">{phone("(617) 887-8765")}</p>
              </div>
            </li>
          </ul>
          <ControlWrapper
            type={FiltersData.FILTER_LOCATIONS[Props.TYPE]}
            data={FiltersData.FILTER_LOCATIONS}
            className="margin-bottom margin-top-double"
            questionClass="text--bold text--uppercase text--medium text--gray"
            optionClass="dta-checkbox--button-with-icon"
            textClass="dta-checkbox--button-with-icon__text"
            doBuildOptionItemContents={this._buildFilterItem}
            value={this.state.selectedFilters}
            onChange={this._handleChangeForFilters}
          />
          <form className="inline-row-container" onSubmit={this._handleSearch}>
            <ControlWrapper
              labelHidden
              className="pure-u-1 margin-right-half"
              controlContainerClass="margin-bottom"
              type={FiltersData.SEARCH_MAP[Props.TYPE]}
              data={FiltersData.SEARCH_MAP}
              value={this.state.searchValue}
              onChange={this._handleChangeForSearch}
            />
            <button
              type="submit"
              className="dta-button dta-button--secondary dta-button--standard-height"
            >
              <Icon name="search" label="Search" />
            </button>
          </form>
          <div
            className="sr-only"
            aria-live="polite"
            aria-relevant="additions text"
          >
            {this.state.screenReaderSearchNotice}
          </div>
        </div>
        <MapWrapper
          findCurrentLocation
          apiKey={MAP_API_KEY}
          onChange={this._handleChangeForMap}
          onZoomChange={this._handleZoomChangeForMap}
          doBuildMarker={this._buildMarker}
          value={center}
          zoom={zoom}
          data={this._buildData()}
        >
          {isCoord(center) && (
            <DTAMarker hasDetail={TextProps.VALUE_FALSE} position={center} />
          )}
        </MapWrapper>
      </div>
    );
  }

  // Search
  // ------

  _handleChangeForSearch = (value) => this.setState({ searchValue: value });

  _handleSearch = (event) => {
    event.preventDefault();
    this._handleSearchHelper();
  };

  // debounced helper, fetch search value off of state in this debounced helper
  // to allow some time for the state to synchronize
  _handleSearchHelper() {
    const address = this.state.searchValue;
    // Maps Javascript API loaded into the google global by `MapWrapper`
    if (google) {
      const geocoder = new google.maps.Geocoder();
      // geocode within bounding box approximately encompassing Alabama
      geocoder.geocode(
        {
          address,
          bounds: {
            west: -73.5205078125,
            east: -69.8345947266,
            north: 42.8981006369,
            south: 41.1724519493,
          },
        },
        this._handleGeocode
      );
    }
  }

  // Events
  // ------

  _handleChangeForFilters = (newFilters) =>
    this.setState({ selectedFilters: newFilters });

  _handleChangeForMap = (newCenter) => this.setState({ center: newCenter });

  _handleZoomChangeForMap = (newZoom) => this.setState({ zoom: newZoom });

  _handleGeocode = (results, status) => {
    if (status === "OK") {
      this.setState({
        center: results[0].geometry.location.toJSON(),
        zoom: 13,
        screenReaderSearchNotice: `You have searched for ${this.state.searchValue}. Navigate to the below map for a list of results for your search.`,
      });
    } else {
      // global notification service to display an appropriate error message
      // to the user such as the 'ZERO_RESULTS' error
      const errorMsg = `Could not complete search. Error is: ${status}`;
      alert(errorMsg);
      this.setState({ screenReaderSearchNotice: errorMsg });
    }
  };

  // Rendering
  // ---------

  _buildFilterItem = (textClass, textContents) => {
    return (
      <div>
        {this._resolveFilterIconFromDisplay(textContents)}
        <span className={textClass}>{textContents}</span>
      </div>
    );
  };

  _buildData() {
    const data = [],
      filters = this.state.selectedFilters;
    if (filters.includes(FiltersData.ATM_VALUE)) {
      data.push(...MapData.ATM_LOCATIONS);
    }
    if (filters.includes(FiltersData.DTA_OFFICE_VALUE)) {
      data.push(...MapData.OFFICE_LOCATIONS);
    }
    if (filters.includes(FiltersData.MARKET_VALUE)) {
      data.push(...MapData.FARMERS_MARKET_LOCATIONS);
    }
    return data;
  }

  _buildMarker = ({
    type,
    id,
    info: { name, address1, address2 },
    lat,
    lng,
  }) => {
    if (
      this.state.selectedFilters.includes(this._resolveMapTypeToValue(type))
    ) {
      return this._buildMarkerHelper(id, name, address1, address2, {
        icon: this._resolveMapIconFromType(type),
        position: { lat, lng },
      });
    }
  };

  _buildMarkerHelper(id, name, address1, address2, props) {
    return (
      <DTAMarker key={id} {...props}>
        <div>
          <span className="sr-only" id={this.getUniqueId(`${id}-name`)}>
            Name:
          </span>
          <span
            aria-labelledby={this.getUniqueId(`${id}-name`)}
            className="display-block text text--bold margin-top-half margin-bottom-half"
          >
            {name}
          </span>
          <span className="sr-only" id={this.getUniqueId(`${id}-address`)}>
            Address:
          </span>
          <span
            aria-labelledby={this.getUniqueId(`${id}-address`)}
            className="display-block"
          >
            {address1}
            <br />
            {address2}
          </span>
        </div>
      </DTAMarker>
    );
  }

  // Helpers
  // -------

  _resolveMapTypeToValue(type) {
    switch (type) {
      case MapData.TYPE_ATM:
        return FiltersData.ATM_VALUE;
      case MapData.TYPE_DTA_OFFICE:
        return FiltersData.DTA_OFFICE_VALUE;
      case MapData.TYPE_FARMERS_MARKET:
        return FiltersData.MARKET_VALUE;
      default:
        throw Error(`${type} is not a valid map type`);
    }
  }

  _resolveMapIconFromType(type) {
    switch (type) {
      case MapData.TYPE_ATM:
        return atmIcon;
      case MapData.TYPE_DTA_OFFICE:
        return redIcon;
      case MapData.TYPE_FARMERS_MARKET:
        return marketIcon;
      default:
        throw Error(`${type} is not a valid map type`);
    }
  }

  _resolveFilterIconFromDisplay(displayValue) {
    if (displayValue === FiltersData.ATM_DISPLAY) {
      return (
        <Icon
          ariaHidden
          name="atm"
          className="dta-checkbox--button-with-icon__icon"
        />
      );
    } else if (displayValue === FiltersData.DTA_OFFICE_DISPLAY) {
      return (
        <Icon
          ariaHidden
          name="dta-office"
          className="dta-checkbox--button-with-icon__icon"
        />
      );
    } else {
      return (
        <Icon
          ariaHidden
          name="farmers-markets"
          className="dta-checkbox--button-with-icon__icon"
        />
      );
    }
  }
}

export default ConsumerDTAInfo;
