import React from "react";
import { isNone } from "../../../utils/components/utilities/controls";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";
var summaryConst = require("../../../utils/constants/SummaryPageConstant.js");

var mailingAddress = "";
/**
 * @param {*} sections
 * @param {*} openClickFlag - after clicking only popup modal will call
 * @description getting main sections to rendering data
 */
export function getMainSections(sections, openClickFlag) {
  if (openClickFlag) {
    return (
      <div className="dta-modal__body pad-all">
        {sections.map(function (section, index) {
          if (
            section.questions !== undefined ||
            section.subsections !== undefined
          ) {
            return (
              <div key={index}>
                <div className="summarySectionName">{section.title} </div>
                <table className="summaryTable">
                  <tbody>{getViewSummarySections(section)}</tbody>
                </table>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

/**
 * @param {*} section
 * @description iterating the sections
 */
function getViewSummarySections(section) {
  return (
    <div>
      {getSectionQuestionsOnly(section)}
      {getSubSectionQuestionsOnly(section)}
    </div>
  );
}

// function returns title of subsections and questions
function getSubSectionQuestionsOnly(section) {
  if (section.subsections !== undefined) {
    return (
      <div>
        {section.subsections.map(function (secSub, indSub) {
          return [
            <div>
              <div className="summarySectionName">{secSub.title} </div>
              {moreSectionQuestionsOnly(secSub)}
              {getConditionalSubSections(secSub)}
              {getIncomeConditionalSubSectionQuestions(secSub)}
            </div>,
          ];
        })}
      </div>
    );
  }
}

// returns answers of subsections data
function moreSectionQuestionsOnly(secSub) {
  if (secSub.questions !== undefined) {
    return [
      <div key={secSub}>
        <table className="summaryTable">
          <tbody>
            {secSub.questions.map(function (answersSub, dataK) {
              if (answersSub.name !== undefined) {
                var bindQuestion = _concateQuestionsLabels(answersSub.label);
                if (answersSub.type === "date") {
                  let dateOfBirth = helperFunction._convertDateToState(
                    answersSub.Answer
                  );
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{dateOfBirth}</td>
                    </tr>
                  );
                } else if (answersSub.type === "address") {
                  var addressObj = _convertPropsAddressToString(
                    answersSub.Answer
                  );
                  mailingAddress = addressObj;
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{addressObj}</td>
                    </tr>
                  );
                } else if (
                  answersSub.type === "radio" &&
                  answersSub.conditionals !== undefined &&
                  answersSub.name === "is-also-mailing"
                ) {
                  if (answersSub.Answer === TextProps.VALUE_STR_FALSE) {
                    var mailAddressObj = TextProps.VALUE_NO;
                    return (
                      <tr key={dataK} className="summaryTableRow">
                        <td className="summaryTableData">{bindQuestion}</td>{" "}
                        <td className="summaryTableData">{mailAddressObj}</td>
                      </tr>
                    );
                  }
                } else if (
                  (answersSub.type === "radio" &&
                    answersSub.conditionals !== undefined &&
                    answersSub.Answer === TextProps.VALUE_YES) ||
                  (answersSub.type === "radio" &&
                    answersSub.conditionals !== undefined)
                ) {
                  if (
                    answersSub.conditionals !== null &&
                    answersSub.conditionals !== undefined
                  ) {
                    return getDynamicalyConditionalQuestions(answersSub);
                  }
                } else if (
                  answersSub.type === "units" ||
                  answersSub.type === "repeatableQuestion"
                ) {
                  return [handleTypesIncomeOrExpenses(answersSub)];
                } else if (
                  (answersSub.type === "checkbox" &&
                    (answersSub.name === "income" ||
                      answersSub.name === "utilities" ||
                      answersSub.name === "types" ||
                      answersSub.name === "caregivers" ||
                      answersSub.name === "race")) ||
                  (answersSub.type === "radio" &&
                    (answersSub.name === "ethnicity" ||
                      answersSub.name === "relationship"))
                ) {
                  let commaSepValues = displayCommaValues(
                    answersSub.Answer,
                    answersSub.name,
                    answersSub.type
                  );
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{commaSepValues}</td>
                    </tr>
                  );
                } else if (
                  answersSub.type === "checkbox" &&
                  (answersSub.name === "disability" ||
                    answersSub.name === "type" ||
                    answersSub.name == "with-child-support")
                ) {
                  let commaSepValues =
                    displayCommaValuesOfIncome_disability(answersSub);
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{commaSepValues}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{answersSub.Answer}</td>
                    </tr>
                  );
                }
              }
            })}
          </tbody>
        </table>
      </div>,
    ];
  }
}

// displaying in UI with comma separated
function displayCommaValuesOfIncome_disability(answerObj) {
  var finalAns = "";
  var tempValues = "";
  var codeDesc = "";
  if (answerObj !== undefined) {
    if (answerObj.options !== undefined) {
      for (let valueOptions of answerObj.options) {
        if (answerObj.Answer !== undefined) {
          for (let valueAnswer of answerObj.Answer) {
            if (valueAnswer === valueOptions.optionValue) {
              codeDesc = valueOptions.optionDisplay;
              finalAns += codeDesc + ", ";
            }
          }
        }
      }
    }
  }
  if (finalAns !== undefined && finalAns.length > 1) {
    var lastIndex = finalAns.lastIndexOf(",");
    if (lastIndex > 1) {
      tempValues = finalAns.substring(0, lastIndex);
    }
  }

  return tempValues;
}

// displaying in UI with comma separated
function displayCommaValues(answerObj, name, type) {
  var finalAns = "";
  var tempValues = "";
  var codeDesc = "";
  if (answerObj !== undefined && answerObj !== null && answerObj !== "") {
    if (type === "radio") {
      if (name === "ethnicity")
        codeDesc = summaryConst.geEthnicityDesc[answerObj];
      else if (name === "relationship")
        codeDesc = summaryConst.getRelationDesc[answerObj];

      finalAns += codeDesc + ", ";
    } else if (type === "checkbox") {
      for (let raceVal of answerObj) {
        codeDesc = raceVal;
        if (name === "race") codeDesc = summaryConst.getraceDesc[codeDesc];
        else if (name === "types") {
          codeDesc = raceVal.replace("-", "");
          codeDesc = summaryConst.getIncomeTypeDesc[codeDesc];
        } else if (name === "type")
          codeDesc = summaryConst.getHouseCostDesc[codeDesc];
        else if (name === "utilities")
          codeDesc = summaryConst.getUtilityDesc[codeDesc];
        finalAns += codeDesc + ", ";
      }
    }
    if (finalAns !== undefined && finalAns.length > 1) {
      var lastIndex = finalAns.lastIndexOf(",");
      if (lastIndex > 1) {
        tempValues = finalAns.substring(0, lastIndex);
      }
    }
  }
  return tempValues;
}

// all sections units object hadling
function handleTypesIncomeOrExpenses(typesOfIncome) {
  if (
    typesOfIncome.Answer !== "" &&
    typesOfIncome.Answer !== undefined &&
    typesOfIncome.Answer.length > 0
  ) {
    return [
      <div>
        <table className="summaryTable">
          <tbody>
            {typesOfIncome.Answer.map(function (incomeType, dataInd) {
              let bindQuestion = "";
              if (typesOfIncome.repeatedQuestion !== undefined) {
                bindQuestion = _concateQuestionsLabels(
                  typesOfIncome.repeatedQuestion.label
                );
              } else {
                bindQuestion = _concateQuestionsLabels(typesOfIncome.label);
              }
              let unitsData = _handleChangeUnitsObj(
                incomeType,
                typesOfIncome.name
              );
              return (
                <tr key={dataInd} className="summaryTableRow">
                  <td className="summaryTableData">{bindQuestion}</td>{" "}
                  <td className="summaryTableData">{unitsData}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>,
    ];
  } else {
    let bindQuestion = _concateQuestionsLabels(typesOfIncome.label);
    let unitsData = _handleChangeUnitsObj(
      typesOfIncome.Answer,
      typesOfIncome.name
    );
    return (
      <tr key={0} className="summaryTableRow">
        <td className="summaryTableData">{bindQuestion}</td>{" "}
        <td className="summaryTableData">{unitsData}</td>
      </tr>
    );
  }
}

// income section conditional questions
function getIncomeConditionalSubSectionQuestions(secSub) {
  if (secSub !== undefined) {
    if (secSub.subsections !== undefined) {
      return (
        <div>
          {secSub.subsections.map(function (conditionalQues, condIndex) {
            return [
              <div>
                {moreSectionQuestionsOnly(conditionalQues)}
                {moreSubSectionQuestionsOnly(conditionalQues)}
              </div>,
            ];
          })}
        </div>
      );
    }
  }
}

/**
 *
 * @param {*} subSections
 * @return in medical expenses sub-sections of sub conditionals questions
 */
function moreSubSectionQuestionsOnly(subSections) {
  if (subSections.subsections !== undefined) {
    return (
      <div>
        {subSections.subsections.map(function (conditionalQues, condIndex) {
          return [<div>{moreSectionQuestionsOnly(conditionalQues)}</div>];
        })}
      </div>
    );
  }
}
// inside conditionals questions fetching data
function getConditionalSubSections(secSub) {
  if (secSub.questions !== undefined) {
    return (
      <div>
        {secSub.questions.map(function (conditionalQues, condIndex) {
          return [
            <div>{getDynamicalyConditionalQuestions(conditionalQues)}</div>,
          ];
        })}
      </div>
    );
  }
}

// conditionals questions dynamically conditions
function getDynamicalyConditionalQuestions(conditions) {
  if (conditions.conditionals !== undefined) {
    return (
      <div>
        <table className="summaryTable">
          <tbody>
            {conditions.conditionals.map(function (
              conditionsQuestions,
              condiIndex
            ) {
              return [
                <dd>{mappedConditionalSubQuestions(conditionsQuestions)}</dd>,
              ];
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

// questions lables if have conditions
function _concateQuestionsLabels(label) {
  if (label !== undefined) {
    var correctQName = "";
    if (label.textBefore !== undefined) {
      if (isNone(label)) {
        return correctQName;
      }
      var withQMark =
        label.textBefore + "" + label.triggerText + "" + label.textAfter;
      correctQName = withQMark;
      return correctQName;
    } else {
      if (isNone(label)) {
        return correctQName;
      }
      return label;
    }
  }
}

// handling units objects
function _handleChangeUnitsObj(units, name) {
  if (isNone(units)) {
    return "";
  }
  if (name === "rental-income-hours-worked-0" || name === "frequency-trips-1")
    return units.amount + " - " + units.unit + "";
  else return "$" + units.amount + " - " + units.unit + "";
}

// conditionals questions inside subsections questions
function mappedConditionalSubQuestions(conditionalQues) {
  if (conditionalQues !== undefined) {
    return (
      <table className="summaryTable">
        <tbody>
          {conditionalQues.conditionalQuestions.map(function (
            questionsData,
            condiIndex
          ) {
            if (questionsData.name !== undefined) {
              var bindQuestion = _concateQuestionsLabels(questionsData.label);
              if (questionsData.type === "address") {
                var addressObj = "";
                if (questionsData.Answer !== "") {
                  addressObj = _convertPropsAddressToString(
                    questionsData.Answer
                  );
                } else {
                  addressObj = mailingAddress;
                }
                return (
                  <tr key={condiIndex} className="summaryTableRow">
                    <td className="summaryTableData">{bindQuestion}</td>{" "}
                    <td className="summaryTableData">{addressObj}</td>
                  </tr>
                );
              } else if (
                questionsData.type === "units" ||
                questionsData.type === "repeatableQuestion"
              ) {
                var unitsData = _handleChangeUnitsObj(
                  questionsData.Answer,
                  questionsData.name
                );
                return (
                  <tr key={condiIndex} className="summaryTableRow">
                    <td className="summaryTableData">{bindQuestion}</td>{" "}
                    <td className="summaryTableData">{unitsData}</td>
                  </tr>
                );
              } else {
                return (
                  <tr key={condiIndex} className="summaryTableRow">
                    <td className="summaryTableData">{bindQuestion}</td>{" "}
                    <td className="summaryTableData">{questionsData.Answer}</td>
                  </tr>
                );
              }
            }
          })}
        </tbody>
      </table>
    );
  }
}

// rendering apply questions answers to bind the data to displaying purpose
function getSectionQuestionsOnly(section) {
  if (section.questions !== undefined) {
    return (
      <div>
        <table className="summaryTable">
          <tbody>
            {section.questions.map(function (answers, dataK) {
              if (answers.name !== undefined) {
                var bindQuestion = _concateQuestionsLabels(answers.label);
                if (answers.type === "date") {
                  var dateOfBirth = helperFunction._convertDateToState(
                    answers.Answer
                  );
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{dateOfBirth}</td>
                    </tr>
                  );
                } else if (
                  answers.type === "checkbox" &&
                  answers.name === "income"
                ) {
                  let commaSepValues =
                    displayCommaValuesOfIncome_disability(answers);
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{commaSepValues}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={dataK} className="summaryTableRow">
                      <td className="summaryTableData">{bindQuestion}</td>{" "}
                      <td className="summaryTableData">{answers.Answer}</td>
                    </tr>
                  );
                }
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

// address part making as string to display format
function _convertPropsAddressToString(address) {
  if (address !== undefined) {
    if (isNone(address)) {
      return "";
    }
    return (
      "" +
      address.street +
      "," +
      address.city +
      ", " +
      address.state +
      ", " +
      address.zipCode +
      ""
    );
  } else {
    return "";
  }
}
