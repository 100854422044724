let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function providerConst(language) {
  switch (language) {
    case EN:
      return {
        accountSearchType: "How would you like to search for a client?",
        web: "SNAP Web Application Number",
        snapLabel: "SNAP Case Number",
        tanfLabel: "TANF Case Number",
        dta: "Client ID",
        ssn: "Social Security Number",
        ebt: "EBT Card Number",
        clientName: "Client Name",
        caseNumber: "Case Number",
        email: "Email Address",
        applicationdate: "Application Date",
        tanf: "TANF Web Application Number",
        applicationNumber: "What is the Client's SNAP Web Application Number?",
        dtaId: "What is the Client ID?",
        clientTitle: "What is the Client's Name?",
        applicationTitle: "What is the Application Date?",
        ssnNumber: "What is the Client's Social Security Number?",
        dob: "What is your Date of Birth?",
        ebtNumber: "What is the Client's EBT Card Number?",
        tanfapplicationnumber: "What is the Client's TANF Web Application Number?",
        clientApplicationNumber: "What is the Client's Name?",
        applicationNameMonth: "Month",
        applicationNameday: "Day",
        applicationNameYear: "Year",
        clientFirstName: "First Name",
        clientLastName: "Last Name",
        emailaddress: "What is the Client's Email Address?",
        clientcasenumber: "What is the Client's Case Number?",
        searchAllClients: "Search All Clients",
        snapOutreach: "SNAP Outreach",
        memberSearch: "Member Search",
        searchClients: "Search this list",
        clientFilters: "Filter clients by",
        myClients: "My Clients",
        urgent: "urgent",
        needsAttention: "Outstanding Tasks",
        elibility: "elibility",
        determiningEligibility: "Pending Cases",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Document Status",
        expiring: "expiring",
        expiringAccountAccess: "Expiring PSI",
        clientInfo: "Client Information",
        caseStatus: "Case Status",
        ssn: "SSN (Social Security Number)",
        monthList: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      };
    case ES:
      return {
        accountSearchType: "¿De qué forma desea buscar el cliente?",
        web: "Número de solicitud web a SNAP",
        dta: "Identificación del cliente",
        snapLabel: "Número de caso SNAPr",
        tanfLabel: "Número de caso TANF",
        clientName: "Nombre del cliente",
        tanf:"Número de solicitud web de TANF",
        applicationdate:"Fecha de aplicacion",
        email:"dirección de correo electrónico",
        clientFirstName: "Nombre de pila",
        clientLastName: "Apellido",
        emailaddress:"¿Cuál es la dirección de correo electrónico del Cliente?",
        caseNumber:"Número de caso",
        clientcasenumber:"¿Cuál es el número de caso del Cliente?",
        applicationNameMonth: "Mes",
        applicationNameday: "Día",
        applicationNameYear: "Año",
        ssn: "Número del Seguro Social",
        ebt: "Número de tarjeta de Transferencia Electrónica de Beneficios (EBT)",
        applicationNumber:
          "¿Cuál es el número de la aplicación web SNAP del Cliente?",
        dtaId: "¿Cuál es la identificación del cliente?",
        clientTitle: "¿Cuál es el nombre del cliente?",
        applicationTitle: "¿Cuál es la fecha de solicitud?",
        ssnNumber: "¿Cuál es el número de seguro social del cliente?",
        dob: "¿Cuál es su fecha de nacimiento?",
        ebtNumber: "¿Cuál es el número de tarjeta EBT del cliente?",
        tanfapplicationnumber:"¿Cuál es el número de solicitud web TANF del cliente?",
        searchAllClients: "Buscar todos los clientes",
        snapOutreach: "SNAP Outreach",
        searchClients: "Buscar en esta lista",
        clientFilters: "Filter clients by",
        myClients: "Mis clientes",
        urgent: "urgent",
        needsAttention: "Tareas pendientes",
        elibility: "elibility",
        determiningEligibility: "Casos pendientes",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Estado del documento",
        expiring: "expiring",
        expiringAccountAccess: "PSI por vencer",
        clientInfo: "Información del cliente",
        caseStatus: "Estado del caso",
        monthList: [
          "ene",
          "feb",
          "mar",
          "Abr",
          "May",
          "Puede",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      };
    case PT:
      return {
        accountSearchType: "De que forma você gostaria de pesquisar o cliente?",
        web: "Número do aplicativo SNAP da Web",
        dta: "ID da agência DHR",
        ssn: "Número da Segurança Social",
        ebt: "Número do cartão EBT",
        applicationNumber:
          "Qual é o número do aplicativo SNAP da web do cliente?",
        dtaId: "Qual é o ID da agência DHR do cliente?",
        clientTitle: "Qual é o nome do cliente?",
        applicationTitle: "Qual é a data de inscrição?",
        ssnNumber: "Qual é o número do seguro social do cliente?",
        dob: "Qual é a sua data de nascimento?",
        ebtNumber: "Qual é o número do cartão EBT do cliente?",
        searchAllClients: "Pesquisar todos os clientes",
        snapOutreach: "SNAP Outreach",
        searchClients: "Pesquisar esta lista",
        clientFilters: "Filter clients by",
        myClients: "Meus clientes",
        urgent: "urgent",
        needsAttention: "Tarefas pendentes",
        elibility: "elibility",
        determiningEligibility: "Casos pendentes",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Status do documento",
        expiring: "expiring",
        expiringAccountAccess: "PSI a expirar",
        clientInfo: "Informações do cliente",
        caseStatus: "Status do caso",
      };
    case ZH:
      return {
        accountSearchType: "您想以哪种方式为客户搜索信息？",
        web: "SNAP Web应用程序号",
        dta: "DTA代理商编号",
        ssn: "社会安全号码",
        ebt: "EBT卡号",
        applicationNumber: "客户的SNAP Web应用程序号是什么？",
        dtaId: "客户的DTA代理商ID是什么？",
        clientTitle: "客户的名字是什么？",
        applicationTitle: "申请日期是哪一天？",
        ssnNumber: "客户的社会安全号码是多少？",
        dob: "您的出生日期是哪一天？",
        ebtNumber: "客户的EBT卡号是多少？",
        searchAllClients: "搜索所有客户",
        snapOutreach: "SNAP Outreach",
        searchClients: "搜索此列表",
        clientFilters: "Filter clients by",
        myClients: "我的客户",
        urgent: "urgent",
        needsAttention: "待完成任务",
        elibility: "elibility",
        determiningEligibility: "未决案例",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "文档状态",
        expiring: "expiring",
        expiringAccountAccess: "即将过期的信息共享许可 (PSI)",
        clientInfo: "客户信息",
        caseStatus: "案例状态",
      };
    case VI:
      return {
        accountSearchType: "Bạn muốn tìm kiếm khách hàng theo cách nào?",
        web: "Số ứng dụng web SNAP",
        dta: "ID đại lý DHR",
        ssn: "Số an sinh xã hội",
        ebt: "Số thẻ EBT",
        applicationNumber: "Số ứng dụng web SNAP của Khách hàng là gì?",
        dtaId: "ID đại lý DHR của Khách hàng là gì?",
        clientTitle: "Tên của khách hàng là gì?",
        applicationTitle: "Ngày nộp đơn là ngày nào?",
        ssnNumber: "Số An sinh Xã hội của Khách hàng là gì?",
        dob: "Ngày sinh?",
        ebtNumber: "Số thẻ EBT của Khách hàng là gì?",
        searchAllClients: "Tìm khách hàng",
        snapOutreach: "SNAP Outreach",
        searchClients: "Tìm trong danh sách này",
        clientFilters: "Filter clients by",
        myClients: "Khách hàng của tôi",
        urgent: "urgent",
        needsAttention: "Những công việc còn cần hoàn tất",
        elibility: "elibility",
        determiningEligibility: "Trường hợp tồn đọng",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Tình trạng giấy tờ",
        expiring: "expiring",
        expiringAccountAccess: "PSI hết hạn",
        clientInfo: "Thông tin khách hàng",
        caseStatus: "Tình trạng hồ sơ",
      };
    case HI:
      return {
        accountSearchType: "Kijan ou ta renmen chèche yon kliyan?",
        web: "Nimewo Aplikasyon Wèb SNAP",
        dta: "ID Ajans DHR",
        ssn: "Nimewo Sekirite Sosyal",
        ebt: "Nimewo Kat EBT",
        applicationNumber: "Ki Nimewo Aplikasyon Wèb SNAP Kliyan w lan?",
        dtaId: "Ki ID Ajans DHR Kliyan an?",
        clientTitle: "Ki non kliyan an?",
        applicationTitle: "Ki dat aplikasyon an?",
        ssnNumber: "Ki Nimewo Sekirite Sosyal Kliyan an?",
        dob: "Ki dat nesans ou?",
        ebtNumber: "Ki Nimewo Kat EBT Kliyan an?",
        searchAllClients: "Chèche Tout Kliyan ",
        snapOutreach: "SNAP Sansibilizasyon",
        memberSearch: "Chèche Manm",
        searchClients: "Chèche nan lis sa a",
        clientFilters: "Filter clients by",
        myClients: "Kliyan mwen yo",
        urgent: "urgent",
        needsAttention: "Tach ki poko fini",
        elibility: "elibility",
        determiningEligibility: "Dosye Annatant",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Estati dokiman an",
        expiring: "expiring",
        expiringAccountAccess: "PSI ap ekspire",
        clientInfo: "Enfòmasyon sou Kliyan",
        caseStatus: "Estati Dosye",
      };
    default:
      return {
        accountSearchType: "How would you like to search for a client?",
        web: "SNAP Web Application Number",
        dta: "DHR Agency ID",
        ssn: "Social Security Number",
        ebt: "EBT Card Number",
        applicationNumber: "What is the Client's SNAP Web Application Number? ",
        dtaId: "What is the Client's DHR Agency ID?",
        clientTitle: "What is the Client's Name?",
        applicationTitle: "What is the Application Date?",
        ssnNumber: "What is the Client's Social Security Number?",
        dob: "What is your Date of Birth?",
        ebtNumber: "What is the Client's EBT Card Number?",
        searchAllClients: "Search All Clients",
        snapOutreach: "SNAP Outreach",
        searchClients: "Search this list",
        clientFilters: "Filter clients by",
        myClients: "My Clients",
        urgent: "urgent",
        needsAttention: "Needs Attention",
        elibility: "elibility",
        determiningEligibility: "Determining Eligibility",
        unsubmitted: "unsubmitted",
        unsubmittedApplications: "Unsubmitted Applications",
        expiring: "expiring",
        expiringAccountAccess: "Expiring Account Access",
        clientInfo: "Client Information",
        caseStatus: "Case Status",
      };
  }
}

export function mhProviderSNAPGAP(language) {
  return {
    accountSearchType: "How would you like to search for a client? (Required)",
    mhProviderMemberSearch: ["First Name / Last Name", "Zip Code"],
    agedOver60: ["Aged over 60"],
    firstName: "First Name (Required)",
    lastName: "Last Name (Required)",
    firstNameErrorMsg: "Please enter a first name.",
    lastNameErrorMsg: "Please enter a last name.",
    phoneNumberErrorMsg: "Please enter a phone number.",
    phoneNumberLengthErrorMsg: "Please enter a valid phone number.",
    signedNameNotMatch: "Signature needs to match the name in the application.",
    signedNameErrorMsg: "Please sign.",
    noRecords: "No records found.",
    zipCode: "Zip Code (Required)",
    zipCode5DigitErrorMsg: "Please enter a valid 5-digit zip code",
    zipCodeNotMAErrorMsg: "Please enter a Alabama zip code",
    search: "Search",
    searchResults: "SEARCH RESULTS",
    mhDetails: [
      "Name",
      "Address",
      "Cell phone number",
      "Day phone number",
      "Night phone number",
    ],
    outreachSuccess: "Outreach Status Updated!",
    outreachFailed: "Client info not updated!",
    outreachFailedCaps: "Client Info not Updated!",
    outreachRelationStatus: [
      "Did not want to apply",
      "Same Household",
      "Different Household",
    ],
    applicationStatus: ["Application submitted", "Did not want to apply"],
    back: "Back",
    backToSearch: "Back to search",
    MAInfo: "MASSHEALTH CASE INFORMATION",
    SNAPoutReach: "SNAP OUTREACH ",
    newSNAPApp: "New SNAP Application",
    status: "Status",
    statusErrorMsg: "Please update the status",
    comments: "Comments",
    commentsErrorMsg:
      "Please write a comment indicating why the client did not want to apply.",
    clientAddress: "CLIENTS AT THE SAME ADDRESS",
    clientRelatedCase: "There are no related cases for the client.",
    cancel: "Cancel",
    submit: "Submit",
    hi: "Hi ",
    memberSearchDesc:
      "Check if a member is in the SNAP Gap before submitting a SNAP application.",
    memberSearch: "Member Search",
    getCaseInfoDesc:
      "Displays members in the SNAP Gap so you can conduct outreach.",
    getCaseInfo: "Get Case Info",
    snapApplyDesc: "Use after you have done a member search.",
    snapApply: "New SNAP Application",
    clientNotFound: "Client Info not found please try again!",
    thankYouSNAPGap: "Thank you for your help to reduce the SNAP Gap!",
    selectOption: "Please select an option below.",
  };
}
