import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import FocusTrap from "focus-trap-react";

import { addStringIf } from "./utilities/controls";
import Icon from "./dtaIcon";
import * as TextProps from "../../utils/constants/text";

class DTADropdown extends Component {
  static propTypes = {
    // required
    buttonText: PropTypes.string.isRequired,
    // container
    menuTagName: PropTypes.string,
    buttonProps: PropTypes.object,
    // class
    className: PropTypes.string,
    buttonClass: PropTypes.string,
    menuClass: PropTypes.string,
    // flags
    closeOnClickOutside: PropTypes.bool,
  };

  static defaultProps = {
    // container
    menuTagName: "ul",
    buttonProps: {},
    // class
    className: "",
    buttonClass: "",
    menuClass: "",
    // flags
    closeOnClickOutside: TextProps.VALUE_TRUE,
  };

  state = {
    isOpen: TextProps.VALUE_FALSE,
    menuAlignmentClass: "",
  };

  constructor() {
    super(...arguments);
    enableUniqueIds(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleClickOutside);
  }

  render() {
    const {
        className,
        menuClass,
        buttonClass,
        caretClass,
        menuTagName: MenuTagName,
        buttonProps,
        dropdownIcon,
      } = this.props,
      { menuAlignmentClass } = this.state;

    return (
      <FocusTrap
        focusTrapOptions={{
          escapeDeactivates: TextProps.VALUE_FALSE, // handle escape ourselves
        }}
        active={this.state.isOpen}
        className={addStringIf(
          this.state.isOpen,
          addStringIf(className, "dta-dropdown"),
          "is-open"
        )}
      >
        <div ref={(el) => (this._element = el)} onKeyUp={this._handleKeyUp}>
          <button
            {...buttonProps}
            className={addStringIf(this.state.isOpen, buttonClass, "is-active")}
            aria-expanded={this.state.isOpen}
            onClick={this._toggleOpen}
            aria-haspopup={TextProps.VALUE_STR_TRUE}
            aria-controls={this.nextUniqueId()}
          >
            {this.props.buttonText}
            {this.props.caret ? (
              <span
                className={`caret ${caretClass} ${
                  this.state.isOpen ? "up" : "down"
                }`}
              />
            ) : null}
            {dropdownIcon ? <Icon ariaHidden name={dropdownIcon} /> : null}
          </button>
          <MenuTagName
            id={this.lastUniqueId()}
            className={addStringIf(
              menuAlignmentClass,
              addStringIf(menuClass, "dta-dropdown__menu")
            )}
          >
            {this.props.children}
          </MenuTagName>
        </div>
      </FocusTrap>
    );
  }

  close() {
    this.setState({ isOpen: TextProps.VALUE_FALSE });
  }

  _toggleOpen = () => {
    const isAlreadyOpen = this.state.isOpen,
      newState = { isOpen: !isAlreadyOpen };
    // if not already open, then we are going to open
    if (!isAlreadyOpen) {
      newState.menuAlignmentClass = this._calculateMenuAlignment();
    }
    this.setState(newState);
  };

  _handleClickOutside = ({ target }) => {
    if (!this.props.closeOnClickOutside || !this.state.isOpen) {
      return;
    }
    if (
      this._element &&
      this._element.contains(target) === TextProps.VALUE_FALSE
    ) {
      this.close();
    }
  };

  _handleKeyUp = (event) => {
    if (!this.state.isOpen) {
      return;
    }
    if (event.which === 27) {
      // escape key
      this.close();
    }
  };

  _calculateMenuAlignment() {
    const el = this._element;
    let alignmentClass = "";
    if (el) {
      const { top, height, left, width } = el.getBoundingClientRect(),
        { innerHeight, innerWidth } = window,
        bottom = innerHeight - height - top,
        right = innerWidth - width - left;
      alignmentClass += ` dta-dropdown__menu--${
        top > bottom ? "above" : "below"
      }`;
      alignmentClass += ` dta-dropdown__menu--${
        right > left ? "right" : "left"
      }`;
    }
    return alignmentClass;
  }
}

export default DTADropdown;
