import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { beforeYoustartMlanguageLabels } from "../applyTafdcText";
import gettingStarted from "../../../utils/resources/images/getting-started.png";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class BeforeYouStart extends Component {
  constructor(props) {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <div className="text--center">
          <img
            src={gettingStarted}
            className="apply__img-getting-started"
            alt="Three icons, dipicting a group of people, money, and finanical documents"
          />
        </div>
        <p>
          {beforeYoustartMlanguageLabels(language).fieldLabel1}{" "}
          {beforeYoustartMlanguageLabels(language).fieldLabel3}
        </p>
        <hr className="divider" />
        <p className="apply__heading">
          {beforeYoustartMlanguageLabels(language).fieldLabel2}
        </p>
        <ul>
          <li> {beforeYoustartMlanguageLabels(language).fieldFactors[0]}</li>
          <li> {beforeYoustartMlanguageLabels(language).fieldFactors[1]}</li>
          <li>{beforeYoustartMlanguageLabels(language).fieldFactors[2]}</li>
        </ul>
      </div>
    );
  }
}

export default BeforeYouStart;
