export function screeningReducer(state = {}, action) {
  switch (action.type) {
    case "GET_SCREENING_QUESTIONS_SUCCESS": {
      const questionState = Object.assign({}, state);
      questionState.sections = action.payload;
      return questionState;
    }
    case "UPDATE_SCREENING_SECTION": {
      const screeningState = Object.assign({}, state);
      screeningState.sections[0] = action.payload;
      return screeningState;
    }
    case "SERVICE_FAIL_REDUX": {
      return state;
    }
    case "FILL_INPUT_VALUES_STATE": {
      return state;
    }

    case "@@redux/INIT": {
      const newState = Object.assign({}, state);
      newState.sections = [];
      return newState;
    }
    default:
      return state;
  }
}
