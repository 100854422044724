import axios from "axios"; // resume apply stufftanfSections
import snapSections, {
  snapHouseholdTemplate,
  tanfHouseholdTemplate,
  householdDisabilityTemplate,
  IncomeSectionTemplate,
  incomeSummaryTemplate,
  ExpenseSectionTemplate,
  tanfSections,
  comboSections,
} from "../../pages/applySnap/applySnapMapData";
import { aboutMeBinding } from "../../pages/applySnap/aboutMeMapData";
import { houseHoldBinding } from "../../pages/applySnap/householdMapData";
import { incomeBinding } from "../../pages/applySnap/incomeMapData";
import { expenseBinding } from "../../pages/applySnap/expenseMapData";
import { getAudit } from "../../utils/constants/audit";
import * as roleProps from "../../../src/utils/constants/rolesConstants";
import _, { constant } from "lodash";
import {
  populateAboutMeData,
  bindBothTargetAnswer,
} from "../../pages/applySnap/prePopulateData/populateAboutMeData";
import { populateHouseHoldData } from "../../pages/applySnap/prePopulateData/populateHouseHoldData";
import { populateIncomeData } from "../../pages/applySnap/prePopulateData/populateIncomeData";
import { populateExpenseBinding } from "../../pages/applySnap/prePopulateData/populateExpenseBinding";
import * as TextProps from "../../utils/constants/text";
import { getDocumentsDetails } from "../../redux/consumer/consumerDocumentsActions";
var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;

let config = require("../../utils/constants/appConfigConstants");

let sections = [];
let householdTemplate = {};

//setting up the application type from session storage
//assigning the sections for all application type
export function setSectionBasedApplicationType(applicationType) {
	sessionStorage.setItem("applicationType", applicationType)
  if (applicationType === TextProps.MATCH_SNAP) {
    sections = _.cloneDeep(snapSections);
    householdTemplate = snapHouseholdTemplate;
  } else if (applicationType === TextProps.MATCH_TANF) {
    sections = _.cloneDeep(tanfSections);
    householdTemplate = tanfHouseholdTemplate;
  } else {
    sections = _.cloneDeep(comboSections);
    householdTemplate = snapHouseholdTemplate;
  }
  return (dispatch) => {
    return dispatch(applySnapSections(sections,actionTypes.applySnapPagesAction));
  };
}
//get the application type from session storage
function getApplicationType() {
  let applicationType = sessionStorage.getItem("applicationType");
  return applicationType;
}

const actionTypes = {
  applySnapPagesAction: "APPLY_SNAP_PAGES_FULLFILLED",
  applySnapPagesActionValidated: "APPLY_SNAP_PAGES_FULLFILLED_VALIDATED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  downloadPdfResponse: "DOWNLOAD_PDF_ACTION",
  postApplyData: "POST_APPLY_INFO_DATA",
  checkAddressValidation: "ADDRESS_VALIDATION",
  appendHouseHold: "APPEND_HOME_HOLD_DATA",
  appendIncome: "APPEND_INCOME_DATA",
  appendResources: "APPEND_RESOURCES_DATA",
  appendExpense: "APPEND_EXPENSE_DATA",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  downloadPdfAction: "DOWNLOAD_PDF_APPLY_APPLICATION",
  applySnapSectionData: "APPLY_SNAP_SECTIONS_FULLFILLED",
  updateWebApplicationIdAction: "UPDATE_WEB_APPLICATION_ID",
  saveWebAppId: "APPLY_WEB_APP_ID_FULLFILLED",
  addContactInfoToSections: "APPLY_ADD_ADD_CONTACT_INFO_SECTION",
  applyAddressValidation: "APPLY_ADDRESS_VALIDATION",
  deleteWebApp: "DELETE_WEB_APP",
  prePopulateSnapJson: "APPLY_SNAP_PAGES_RETR",
  applySnapSaveAction: "APPLY_SNAP_PAGES_SAVE",
  applyAddressStorageAction: "APPLY_ADDRESS_STORAGE",
  acesButtonClick : "ACES_BUTTON_CLICK",
  appendMedicalExpenseSections:"APPEND_MEDICAL_EXPENSE_SECTIONS",
  appendMedicalCostOtherQuestion:"APPEND_MEDICAL_COST_OTHER_QUESTION",
  languageChanged: "LANGUAGE_MODIFIED",
  prePopulationDataSuccess:"PREPOPULATE_DATA_SUCCESS",
  prePopulationDataFailure:"PREPOPULATE_DATA_FAILURE"
};



export function addApplyContactInfoSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addApplyContactInfoToSections(subSectionObj));
  };
}


export function applyAddressValidationSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addApplyAddressDispatcher(subSectionObj));
  };
}

function addApplyAddressDispatcher(subSectionObj) {
  return {
    type: actionTypes.applyAddressValidation,
    payload: subSectionObj,
  };
}



function addApplyContactInfoToSections(subSectionObj) {
  return {
    type: actionTypes.addContactInfoToSections,
    payload: subSectionObj,
  };
}

export function applySanpQuestionsSections() {
  return (dispatch) => {
    return dispatch(applySnapSections(sections,actionTypes.applySnapPagesAction));
  };
}
// Created to send the section from applysnapcontainer to handle the required validation.
export function applySanpQuestionsSectionsNew(sections) {
  return (dispatch) => {
  return dispatch(applySnapSections(sections,actionTypes.applySnapPagesActionValidated));
  };
}
//Action triggered on clieck of save and continue / exit and go back buttons
export function onPrimaryActionSaveClicked(saveFlag) {
  return (dispatch) => {
    return dispatch(onPrimaryActionSaveClickedAction(saveFlag));
  };
}
//Sub menthod of onPrimaryActionSaveClicked to get the response
function onPrimaryActionSaveClickedAction(saveFlag) {
  return {
    type: actionTypes.applySnapSaveAction,
    payload: saveFlag,
  };
}
//set Section Based on Application Type for Initial json.
function applySnapSections(sections,action) {
  return {
    type: action,
    payload: sections,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}

function questionsInputValuesAction(sections) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sections,
  };
}

//Household Action
export function appendHouseHoldMembers(subMembers) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(houseHoldSuccess(subMembers));
  };
}

function houseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendHouseHold,
    payload: houseHold,
  };
}

//Appened Income
export function appendResourcesDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(resourcesSuccess(income));
  };
}

function resourcesSuccess(income) {
  return {
    type: actionTypes.appendResources,
    payload: income,
  };
}
//Appened Income
export function appendIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(incomeSuccess(income));
  };
}

function incomeSuccess(income) {
  return {
    type: actionTypes.appendIncome,
    payload: income,
  };
}

//Appened Expense
export function appendExpenseDetails(expense) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(expenseSuccess(expense));
  };
}

function expenseSuccess(expense) {
  return {
    type: actionTypes.appendExpense,
    payload: expense,
  };
}

export function clearHouseHoldMembers(
  houseHoldSubMemberTemplate,
  houseHoldSubDetailTemplate
) {
  return (dispatch) => {
    let detailTemplate = [];
    detailTemplate.push(houseHoldSubDetailTemplate);
    return dispatch(clearHouseHoldSuccess(detailTemplate));
  };
}

function clearHouseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendHouseHold,
    payload: houseHold,
  };
}

//Clearing Resources Template
export function clearMemberResources(
  resourcesMemberTemplate,
  resourcesSummaryTemplate,
  resourcesTemplate
) {
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(resourcesSummaryTemplate);
    resourcesTemplate.subsections = summaryTemplate;
    return dispatch(clearMemberResourcesSuccess(resourcesTemplate));
  };
}

function clearMemberResourcesSuccess(income) {
  return {
    type: actionTypes.appendResources,
    payload: income,
  };
}
//Clearing Income Template
export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate
) {
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(clearMemberIncomeSuccess(incomeTemplate));
  };
}

function clearMemberIncomeSuccess(income) {
  return {
    type: actionTypes.appendIncome,
    payload: income,
  };
}

export function updateHouseholdTextChanges(householdDetails) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(appendHouseholdTextChanges(householdDetails));
  };
}

function appendHouseholdTextChanges(householdDetails) {
  return {
    type: actionTypes.appendHouseHold,
    payload: householdDetails,
  };
}
//Triggering the API call to hit the Melissa for address validation 
export function addressValidation(addressObj, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let appInanonymousMode = JSON.parse(sessionStorage.getItem('applicationInanonymousMode').toLowerCase());
      var url = appInanonymousMode ? config.TigerURL +"/apipublic/address/validateDsnapAddr" : config.TigerContextURL+"/address/validate";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: appInanonymousMode ? {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit)
        } : {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, addressObj, options)
        .then(function (data) {
          dispatch(checkAddressValidation(data));
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}
// Triggering Submit API call for all three application type when user hit on Submit button.
export function submitSnapApply(application, language, isAnonymousUser) {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
  let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
  let isGrantedUsers = false;
  const grantedAppRoles = [roleProps.PROVIDER, roleProps.PROVIDER_SNAP_GAP, roleProps.PROVIDER_NO_PSI, roleProps.USER]

  if(loggedInUser)
  {
    const roleList = loggedInUser.roleList.map(a=> a.roleName);
    isGrantedUsers = roleList.some(
      (role) => grantedAppRoles.includes(role)
    );

    const isClient = loggedInUser.roleList.filter(a=> a.roleName === roleProps.USER);
    if(isClient && isClient.length > 0)
    {
      selectedConsumerEmail = loggedInUser.email;
    }
  }
  const audit = getAudit();
  let url = "";
  let applicationType = getApplicationType();
  if (applicationType === TextProps.MATCH_SNAP) {
    url =  isAnonymousUser ? config.TigerURL+"/apipublic/snapapply" : config.TigerContextURL+"/snapapply";
    application.SNAP_APPLY.application.user_id = selectedConsumerEmail;
    // application.SNAP_APPLY.application.userId = selectedConsumerEmail;
  } else if (applicationType === TextProps.MATCH_TANF) {
    url = config.TigerContextURL+"/tanfapply";
    application.SNAP_APPLY.application.user_id = selectedConsumerEmail;
    // application.SNAP_APPLY.application.userid = selectedConsumerEmail;
  } else {
    url = config.TigerContextURL + "/comboapply";
    application.SNAP_APPLY.application.user_id = selectedConsumerEmail;
    // application.SNAP_APPLY.application.userid = selectedConsumerEmail;
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
        },
      };

      if(!isAnonymousUser) {
        options['headers']['Authorization'] = oauthToken.token_type + " " + oauthToken.access_token
      }

      axios
        .post(url, application, options)
        .then(function (data) {
          dispatch(saveApplyData(data));
          resolve(data);
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}
// Triggering Save API call for all three application type when user hit on Save and continue / save and exit button.
export function saveSnapApply(application, language) {
  const audit = getAudit();
  const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
  let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
  const isProvider = sessionStorage.getItem("isProvider");
  let isGrantedUsers = false;
  const grantedAppRoles = [roleProps.PROVIDER, roleProps.PROVIDER_SNAP_GAP, roleProps.PROVIDER_NO_PSI, roleProps.USER]
  
  if(loggedInUser)
  {
    const roleList = loggedInUser.roleList.map(a=> a.roleName);
    isGrantedUsers = roleList.some(
      (role) => grantedAppRoles.includes(role)
    );

    const isClient = loggedInUser.roleList.filter(a=> a.roleName === roleProps.USER);
    if(isClient && isClient.length > 0)
    {
      selectedConsumerEmail = loggedInUser.email;
    }
    if(isProvider === 'true')
    {
      application.userId = selectedConsumerEmail;
    }
  }

  let url = "";
  let applicationType = getApplicationType();
  if (applicationType === TextProps.MATCH_SNAP) {
    url = config.TigerContextURL + "/snapapplySave";
    // if(isGrantedUsers && selectedConsumerEmail.length)
    // {
    //   url = config.TigerContextURL + `/snapapplySave/${selectedConsumerEmail}`;
    // }
  } else if (applicationType === TextProps.MATCH_TANF) {
    url = config.TigerContextURL + "/tanfapplySave";
    // if(isGrantedUsers && selectedConsumerEmail.length)
    // {
    //   url = config.TigerContextURL + `/tanfapplySave/${selectedConsumerEmail}`;
    // }
  } else {
    url = config.TigerContextURL + "/comboapplySave";
    // if(isGrantedUsers && selectedConsumerEmail.length)
    // {
    //   url = config.TigerContextURL + `/comboapplySave/${selectedConsumerEmail}`;
    // }
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, application, options)
        .then(function (response) {
          resolve(response);
          onPrimaryActionSaveClicked(TextProps.VALUE_FALSE);
          if (response.data.id != 0) {
            return dispatch(populateSnapApplyInfo(response));
          } else {
            return dispatch(applySnapSections(sections,actionTypes.applySnapPagesAction));
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}

//home button click

function acesButtonAction(data){
  return {
    type: actionTypes.acesButtonClick,
    payload: data
  }
}

function languageChangeAction(data){
  return {
    type: actionTypes.languageChanged,
    payload: data
  }
}

export function acesButtonClicked(data){
return (dispatch) => dispatch(acesButtonAction(data))
}
export function languageChanged(data){
  return (dispatch) => dispatch(languageChangeAction(data))
  }

//Assigning type of action and payload for ADDRESS_VALIDATION
function checkAddressValidation(data) {
  return {
    type: actionTypes.checkAddressValidation,
    payload: data,
  };
}
//Assigning type of action and payload for DELETE_WEB_APP
function deleteWebApp(data) {
  return {
    type: actionTypes.deleteWebApp,
    payload: data,
  };
}
// Triggering Delete API call for all three application type when user hit on Delete Application button
export function deleteWebappData(deleteReqData) {
  const audit = getAudit();
  let url = ""
  const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
  let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
  let user_name =  sessionStorage.getItem("user_name");
  const isProvider = sessionStorage.getItem("isProvider");
  let isGrantedUsers = false;
  const grantedAppRoles = [roleProps.PROVIDER, roleProps.PROVIDER_SNAP_GAP, roleProps.PROVIDER_NO_PSI, roleProps.USER]
  
  if(loggedInUser)
  {
    const roleList = loggedInUser.roleList.map(a=> a.roleName);
    isGrantedUsers = roleList.some(
      (role) => grantedAppRoles.includes(role)
    );

    const isClient = loggedInUser.roleList.filter(a=> a.roleName === roleProps.USER);
    if(isClient && isClient.length > 0)
    {
      selectedConsumerEmail = loggedInUser.email;
    }
    // if(isProvider === 'true')
    // {
    //   sessionStorage.setItem("user_name", selectedConsumerEmail);
    // //  deleteReqData.userId = selectedConsumerEmail;
    //  // deleteReqData.userId = user_name;
    // }
  }

  let applicationType = getApplicationType();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if(applicationType === TextProps.MATCH_SNAP){
		url = config.TigerContextURL+"/deletewebapp";
    deleteReqData = {...deleteReqData, webAppTypeCode: TextProps.SNAP_APP_TYPE_CODE}
	  }else if(applicationType === TextProps.MATCH_TANF){
		url = config.TigerContextURL+"/deletetanfapp";
    deleteReqData = {...deleteReqData, webAppTypeCode: TextProps.TANF_APP_TYPE_CODE}
	  }else{
		url = config.TigerContextURL+"/deletecomboapp";
	  }
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .put(url, deleteReqData, options)
        .then(function (response) {
          dispatch(deleteWebApp(response));
          sessionStorage.setItem("inprogressAppsDetails", JSON.stringify([]));
          resolve(response);
        })
        .catch(function (error) {
          console.log("error ", error);
          // dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}

export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}

export function downloadPDFRequestResponse(data) {
  return {
    type: actionTypes.downloadPdfResponse,
    payload: data,
  };
}

export function updateDefault() {
  return (dispatch) => {
    return dispatch({
      type: "default",
    });
  };
}

export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_APPLY_ANSER",
      payload: data,
    });
  };
}

export function updateLastKnownLocation(section) {
  return (dispatch) => {
    return dispatch({
      type: "APPEND_LAST_KNOWN_LOCATION",
      payload: section,
    });
  };
}

export function downloadPDFData(sectionsObj) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BaseUrl + "/apply/downloadPDFData", sectionsObj, HttpOptions)
        .then(function (data) {
          resolve(data);
          dispatch(downloadPDFRequestResponse(data));
        })
        .catch((error) => {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}

export function displayPDFData(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const audit = getAudit();
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      // Axios Http Options
      const Headers = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          audit: JSON.stringify(audit),
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      const appId = data.appId;
      const documentKeyValue = data.documentKey;
      axios
        .get(
          config.TigerURL + "/apipublic/notices/" + documentKeyValue,
          Headers
        )
        .then(function (response) {
          resolve(response);
          toShowPDFinWindow(response, appId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, appId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    window.navigator.msSaveOrOpenBlob(blob, appId + ".pdf");
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var byteCharacters = atob(data.data.stream);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export function selectMaxOfIdsComesBack(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/getMaxTbIds", sectionsObj, HttpOptions)
      .then(function (data) {
        dispatch(appendDatabaseIds(sectionsObj));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function appendDatabaseIds(response) {
  return {
    type: actionTypes.downloadPdfAction,
    payload: response,
  };
}

export function saveMyProgressInfoLater(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/onSaveMyProgressLater", sectionsObj, HttpOptions)
      .then(function (data) {
        // it's required to save in webAppid in json
        dispatch(updateWebApplicationIdInJson(data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function updateWebApplicationIdInJson(data) {
  return {
    type: actionTypes.updateWebApplicationIdAction,
    payload: data,
  };
}

/**
 *
 * @param {*} id - parameter comes from consumer click on continue my SNAP application
 * @return apply data from database
 */
export function getSaveMyProgessDataByWebAppId(id) {
  return (dispatch) => {
    var passingParams = "/apply/callSaveMyProgressData?userId=" + id;
    var options = {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };
    axios
      .post(BaseUrl + passingParams, {}, options)
      .then(function (applySectionsData) {
        return dispatch(resumeBackApplyInfo(applySectionsData.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}
export function savedDataPopulation(response) {
  if (response.data.id != 0) {
    populateSnapApplyInfo(response);
  }
}
//Get the saved json from API and populate that in UI before submit
export function getSavedDataForUser(status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const audit = getAudit();
      // const isSuperUser =  sessionStorage.getItem("isSuperAdmin");
      const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
      let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
      let isGrantedUsers = false;
      const grantedAppRoles = [roleProps.PROVIDER, roleProps.PROVIDER_SNAP_GAP, roleProps.PROVIDER_NO_PSI, roleProps.USER]

      if(loggedInUser)
      {
        const roleList = loggedInUser.roleList.map(a=> a.roleName);
        isGrantedUsers = roleList.some(
          (role) => grantedAppRoles.includes(role)
        );

        const isClient = loggedInUser.roleList.filter(a=> [roleProps.USER,roleProps.CONSUMER].includes(a.roleName));
        if(isClient && isClient.length > 0)
        {
          selectedConsumerEmail = loggedInUser.email;
        }
      }
      
      let url = "";
      let applicationType = getApplicationType();
      if (applicationType === TextProps.MATCH_SNAP) {
        url = config.TigerContextURL + "/snapapply";
        if(isGrantedUsers && selectedConsumerEmail.length)
        {
          url = config.TigerContextURL + `/snapapply/${selectedConsumerEmail}`;
        }
      } else if (applicationType === TextProps.MATCH_TANF) {
        url = config.TigerContextURL + "/tanfapply";
        if(isGrantedUsers && selectedConsumerEmail.length)
        {
          url = config.TigerContextURL + `/tanfapply/${selectedConsumerEmail}`;
        }
      } else if (applicationType === TextProps.MATCH_COMBO) {
        url = config.TigerContextURL + "/comboapply";
        if(isGrantedUsers && selectedConsumerEmail.length)
        {
          url = config.TigerContextURL + `/comboapply/${selectedConsumerEmail}`;
        }
      }
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      // Axios Http Options
      const Headers = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // 'locale': language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      if (status === TextProps.VALUE_APP_INPROGRESS || status === TextProps.VALUE_APP_EXIT) {
        // if(isGrantedUsers && selectedConsumerEmail && selectedConsumerEmail.length)
        // {
          url = config.TigerContextURL+`/inprogressApps/${selectedConsumerEmail}`;
        // }
        axios
          .get(url, Headers)
          .then(function (response) {
            let statusCheck = response.data;
            if(statusCheck)
            {
              sessionStorage.setItem("inprogressAppsDetails", JSON.stringify(statusCheck));
            }
            else{
              sessionStorage.setItem("inprogressAppsDetails", JSON.stringify([]));
            }
            dispatch(getDocumentsDetails(response.data));
            if (status === TextProps.VALUE_APP_INPROGRESS && statusCheck.appId != 0) {
              setSectionBasedApplicationType(statusCheck.appType);
              resolve(response);
              return dispatch(populateSnapApplyInfo(statusCheck));
            }
            if(status === TextProps.VALUE_APP_EXIT){
              resolve(statusCheck);
            }
          })
          .catch((error) => {
            sessionStorage.setItem("inprogressAppsDetails", JSON.stringify([]));
            reject(error);
          });
      } else {
        axios
          .get(url, Headers)
          .then(function (response) {
            resolve(response);
            setSectionBasedApplicationType(applicationType);
            if (response.data.id != 0) {
              return dispatch(populateSnapApplyInfo(response));
            } else {
              return dispatch(applySnapSections(sections,actionTypes.applySnapPagesAction));
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };
}

/**
 * storing to redux - with same action like when user click fresh
 * @param {*} applyData
 * @return object - having all seections data
 */
function resumeBackApplyInfo(applyData) {
  return {
    type: actionTypes.applySnapPagesAction,
    payload: bindServerJson(applyData),
  };
}
function populateSnapApplyInfo(responseData) {
  return {
    type: actionTypes.prePopulateSnapJson,
    payload: responseData,
  };
}

export function saveFinalSignAndSubmit(webId) {
  return (dispatch) => {
    dispatch(setWebAppID(webId));
  };
}
/**
 * storing to redux - Given address values for confirm address pop-up
 * @param {*} applyData
 * @return object - having all seections data
 */
export function applyAddressStorageAPI(responseData) {
  return {
    type: actionTypes.applyAddressStorageAction,
    payload: responseData,
  };
}
function setWebAppID(data) {
  return {
    type: actionTypes.saveWebAppId,
    payload: data,
  };
}

/**
 *
 * @param {*} applyData - comming from service call, it's having apply data
 * @return apply all sections data
 */
function bindServerJson(applyData) {
  let beforeYouStart = sections[0];
  let AboutMe = aboutMeBinding(sections[1], applyData);
  let HouseHold = houseHoldBinding(
    sections,
    householdTemplate,
    householdDisabilityTemplate,
    applyData
  );
  let income = incomeBinding(
    sections,
    applyData,
    IncomeSectionTemplate,
    incomeSummaryTemplate
  );
  let expense = expenseBinding(sections, applyData, ExpenseSectionTemplate);
  let signSubmit = sections[5];
  /**
   * pushing all sections for apply
   */
  const sections1 = [];
  sections1.push(beforeYouStart);
  sections1.push(AboutMe);
  sections1.push(HouseHold);
  sections1.push(income);
  sections1.push(expense);
  sections1.push(signSubmit);
  return sections1;
}
function prePopulateJson(applyData, webAppId, createdTime) {
  let activeSectionId = applyData.activeSectionId;
  sections[0].activeSectionId = activeSectionId;
  sections[0].dataPopulated = true;
  // sections[0].webAppId = applyData.application.id;
  sections[0].webAppId = webAppId;
  sections[0].createdTime = createdTime;
  // let beforeYouStart = sections[0];
  // let AboutMe = populateAboutMeData(sections[1], applyData);
  // if(AboutMe.completed){
  // 	sections[0].completed = TextProps.VALUE_TRUE;
  // }
  // let HouseHold = populateHouseHoldData(sections, householdTemplate, householdDisabilityTemplate, applyData);
  // let income = populateIncomeData(sections, applyData, IncomeSectionTemplate, incomeSummaryTemplate);
  // let expense = populateExpenseBinding(sections, applyData, ExpenseSectionTemplate);
  // bindBothTargetAnswer(sections[5].questions[0],applyData.application.applicant.requestEBTCard === TextProps.VALUE_Y ? TextProps.VALUE_YES : applyData.application.applicant.requestEBTCard === TextProps.VALUE_N? TextProps.VALUE_NO : '');
  // if(applyData.application.applicant.requestEBTCard){
  // 	sections[5].completed = TextProps.VALUE_TRUE;
  // }
  // let ebtCard = sections[5];
  // let signSubmit = sections[6];

  /**
   * pushing all sections for apply
   */
  // const prePopulatedData = [];
  // prePopulatedData.push(beforeYouStart);
  // prePopulatedData.push(AboutMe);
  // prePopulatedData.push(HouseHold);
  // prePopulatedData.push(income);
  // prePopulatedData.push(expense);
  // prePopulatedData.push(ebtCard);
  // prePopulatedData.push(signSubmit);

  // let prePopulatedDataValidated = validateActiveSession(prePopulatedData,activeSectionId);
  // return prePopulatedDataValidated;
  return sections;
}
// function validateActiveSession(prePopulatedData,activeSectionIdd){
// 	let activeSectionId = activeSectionIdd.includes('kids-adult-multiple-address') ? 'kids-and-adults' : activeSectionIdd;
// 	let breakLoop = false;
// 	for(let section of prePopulatedData){
// 		if(breakLoop){
//            break;
// 		}
// 		if(section.id === activeSectionId){
// 			section.completed = true;
// 			breakLoop = !breakLoop;
// 			break;
// 		}else if(section.subsections){
// 			section.completed = true;
//             for(let subsec of section.subsections){
// 			if(breakLoop){
// 				break;
// 			}
// 			subsec.completed = true;
// 			if(section.subsections.filter(sec => sec.id === activeSectionId).length){
// 				if(subsec.id === activeSectionId){
// 					breakLoop = !breakLoop;
// 					break;
// 				}
// 			}else if(subsec.subsections){
// 				subsec.completed = true;
// 				if(subsec.subsections.filter(sec => sec.id === activeSectionId).length){
// 					for(let subsubsec of subsec.subsections){
// 						subsubsec.completed = true;
// 						if(subsubsec.id === activeSectionId){
// 								breakLoop = !breakLoop;
// 								break;
// 						}
// 					}
// 				}else{
// 					for(let innersubsec of subsec.subsections){
// 						if(breakLoop){
// 							break;
// 						}
// 						innersubsec.completed = true;
// 						if(innersubsec.subsections && innersubsec.subsections.filter(sec => sec.id === activeSectionId).length){
// 							for(let ininnersub of innersubsec.subsections){
// 								ininnersub.completed = true;
// 								if(ininnersub.id === activeSectionId){
// 										breakLoop = !breakLoop;
// 										break;
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}else{
// 				subsec.completed = true;
// 			}
// 			}
// 		}else{
// 			section.completed = true;
// 		}
// 	};
// 	return prePopulatedData;
// }


export function appendMedicalExpenseSections(sections,index) {
	return dispatch => {
		return dispatch(expenseMedicalSectionSuccess(sections,index))
	}
}

function expenseMedicalSectionSuccess(sections,memIndex) {
	return {
		type: actionTypes.appendMedicalExpenseSections,
		payload: sections,
    memberIndex:memIndex
	};
}

export function appendMedicalCostOtherQuestion(question,index,action) {
	return dispatch => {
		return dispatch(
      {
        type: actionTypes.appendMedicalCostOtherQuestion,
        payload: question,
        memberIndex:index,
        action:action
      }
    )
	}
}

export function prePopulationDataInfo(applicationType) {
  return (dispatch) => {
    const audit = getAudit();
    var url = config.TigerURL + "/res/continueapplication/" + applicationType;
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(url, options)
      .then(function (response) {
        dispatch(prePopulationDataSuccess(response));
      })
      .catch(function (error) {
        dispatch(prePopulationDataFailure(error));
      });
  };
}
function prePopulationDataSuccess(data) {
  return {
    type: actionTypes.prePopulationDataSuccess,
    payload: data,
  };
}
function prePopulationDataFailure(error) {
  return {
    type: actionTypes.prePopulationDataFailure,
    payload: error,
  };
}