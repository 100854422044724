export function oauthTokenReducer(state = {}, action) {
  switch (action.type) {
    case "ACCESS_TOKEN_SUCCESS":
    case "REFRESH_TOKEN_SUCCESS": {
      let newState = Object.assign({}, state);
      newState.oauthToken = action.oauthToken;
      sessionStorage.setItem("oauthToken", JSON.stringify(action.oauthToken));
      return newState;
    }
    default:
      return Object.assign({}, state);
  }
}
