import React, { Component } from 'react';
import StepWizard from 'react-step-wizard';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as TextProps from "../../../../utils/constants/text";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { consumerConst } from "../../consumerText";
import ConcernsComponent from '../consumerConcernAppeal/concerns';
import ConcernAppealType from './concernAppealType';
import AppealComponent from './appeal';
import ConcernHomeComponent from './concernHome';
import {
  customerConcernsPostCall,
  getConcernAppeal,
  getConsumerAppealsList
} from "../../../../redux/concernsAppeals/concernsAppealsAction";
import ConcernSubmit from './concernSubmit';
import AppealsSubmit from "./appealsSubmit";
import AppealsGoBack from "./concernAppealsGoBack";
import AppealsCancel from "./concernAppealsCancel";
import * as modalHelperFunction from "../../../../utils/components/helperFunctions/modalHelperFunction";
import Loading from '../../../../utils/components/loading';


const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class concernsContainer extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;
    const anonymusUserEmail = clientDetails.clientEmail.emailAddress
    const anonymusUserName = clientDetails.clientInfo.name.first + clientDetails.clientInfo.name.last
    const anonymusUserPhoneNumber = clientDetails.clientPhone.phoneNumber
    // const phoneNumberFormate = anonymusUserPhoneNumber.replace(/\D/g, '');
    // const formattedPhoneNumber = `(${phoneNumberFormate.substring(0, 3)}) ${phoneNumberFormate.substring(3, 6)}-${phoneNumberFormate.substring(6, 10)}`;

    this.state = {
      section: 'LandingPage',
      transitionDirection: 'forward',
      typeconcern: " ",
      concerntype: {
        concernsTypeError: TextProps.VALUE_FALSE,
        customerType: "",
      },
      concerns: {
        concernType: "",
        concernsReason: "",
        concernsProgram: "",
        authRepName: "",
        authRepPhoneNumber: "",
        authRepEmail: "",
        OfficeLocation: "",
        ManagerName: "",
        comments: "",
        ManagerPhoneNumber: "",
        allegedIncidentDate: formattedDate,
        anonymusUserName: anonymusUserName,
        anonymusUserPhoneNumber: anonymusUserPhoneNumber,
        anonymusUserEmail: anonymusUserEmail,
        anonymusClientId: agencyID,
        checked: TextProps.VALUE_FALSE,
        isDisabled: TextProps.VALUE_TRUE,
        concernsTypeError: TextProps.VALUE_FALSE,
        concernsReasonError: TextProps.VALUE_FALSE,
        concernsProgramError: TextProps.VALUE_FALSE,
        ManagerPhoneNumberError: TextProps.VALUE_FALSE,
        showField: TextProps.VALUE_TRUE,
        dateError: TextProps.VALUE_FALSE,
        authRepEmailError: TextProps.VALUE_FALSE,
        authRepPhoneNumberError: TextProps.VALUE_FALSE,
      },
      showWarningModal: TextProps.VALUE_FALSE,
      appeals: {
        program: "",
        programError: TextProps.VALUE_FALSE,
        reason: "",
        reasonError: TextProps.VALUE_FALSE,
        appealsInputDisplayValue: "Fair Hearing",
        appealsInputValue: "FAIRHR",
        appealsAnonymusUserName: anonymusUserName,
        appealsAnonymusUserPhoneNumber: anonymusUserPhoneNumber,
        appealsAnonymusUserEmail: anonymusUserEmail,
        appealsAgencyId: agencyID,
        appealsAuthRepPhoneNumb: "",
        benifitContinuation: "",
        showInput: false,
        legalRepresentative: "",
        nameError: TextProps.VALUE_FALSE,
        name: "",
        phoneNumberError: TextProps.VALUE_FALSE,
        phoneNumber: "",
        emailAddressError: TextProps.VALUE_FALSE,
        emailAddress: "",
        LegalRepresentativeName: "",
        LegalRepresentativePhone: "",
        LegalRepresentativeEmail: "",
        comments: "",
        currentDateTime: new Date(),
        editedValue: new Date(),
        allegedIncidentDate: formattedDate,
        errorMessage: '',
        authRepAppealsEmail: "",
        authRepAppealsEmailError: TextProps.VALUE_FALSE,
        appealsAuthRepPhoneNumbError: TextProps.VALUE_FALSE,
        legalRepresentativeEmailErr: TextProps.VALUE_FALSE,
        anonymusUserPhoneNumber: "",
        clientPhoneNumber:"",
        appealsClientPhoneNumber:"",
        appealsClientEmailAddress:"",
        clientEmailAddressErrorMsg: TextProps.VALUE_FALSE,
        clientPhoneNumberError:TextProps.VALUE_FALSE,
        appealsAuthRepPhone:""
      },
    };
  }
  getTypeSelect = (value) => {
    switch (value) {
      case consumerConst(selectedLanguage).concernsTypeOptions[0]:
        return consumerConst(selectedLanguage).concernsInquiry
      case consumerConst(selectedLanguage).concernsTypeOptions[1]:
        return consumerConst(selectedLanguage).concernsComplaint
      case consumerConst(selectedLanguage).concernsTypeOptions[2]:
        return consumerConst(selectedLanguage).concernsFraud
      case consumerConst(selectedLanguage).concernsTypeOptions[3]:
        return consumerConst(selectedLanguage).concernsConference
      case consumerConst(selectedLanguage).concernsTypeOptions[4]:
        return consumerConst(selectedLanguage).concernsStateOffice
      default:
        return ''
    }
  }


  getReasonSelect = (value) => {
    var obj = consumerConst(selectedLanguage).concernsReasonOptions;
    var result = Object.keys(obj).filter((key) => obj[key] === value);
    return result.length ? result[0] : "";
  }


  getProgramSelect = (value) => {
    switch (value) {
      case consumerConst(selectedLanguage).concernsProgramOptions[0]:
        return consumerConst(selectedLanguage).concernsSNAP
      case consumerConst(selectedLanguage).concernsProgramOptions[1]:
        return consumerConst(selectedLanguage).concernsTANF
      case consumerConst(selectedLanguage).concernsProgramOptions[2]:
        return consumerConst(selectedLanguage).concernsJOBS
      case consumerConst(selectedLanguage).concernsProgramOptions[3]:
        return consumerConst(selectedLanguage).concernsReset
      case consumerConst(selectedLanguage).concernsProgramOptions[4]:
        return consumerConst(selectedLanguage).concernsNonDHR
      default:
        return ''
    }
  }


  formatDate(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let day = date.getDate();
    day = day < 10 ? '0' + day : day;
    return `${year}-${month}-${day}`;
  }


  handleAppeals = (Type, value) => {
    if (Type === "type") {
      const displayValue = value === "FAIRHR" ? "Fair Hearing" : value;
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          appealsInputDisplayValue: displayValue,
          appealsInputValue: value,
        }
      })
      );
    } else if (Type === "program") {
      if(value === ""){
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            program: this.getProgramList(value),
            programError: TextProps.VALUE_TRUE,
          }
        }))
      }else{
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          program: this.getProgramList(value),
          programError: TextProps.VALUE_FALSE,
        }
      })
      )
    }
  }
    else if (Type === "reason") {
      if(value === ""){
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            reason: this.getValueForKey(value),
            reasonError: TextProps.VALUE_TRUE
          }
        }))
      } else{

      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          reason: this.getValueForKey(value),
          reasonError: TextProps.VALUE_FALSE
        }
      }))
      }
  
    } else if (Type === "appealsAuthName") {
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          authRepName: value,
        }
      }));
    } else if (Type === "appealsAuthPhoneNo") {
      if (value === "") {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            appealsAuthRepPhoneNumbError: TextProps.VALUE_FALSE
          }
        }));
      } else {
        if (value.length < 14) {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              appealsAuthRepPhoneNumbError: TextProps.VALUE_TRUE,
              appealsAuthRepPhone: value.replace(/\D/g, ''),
              // phoneNumberError: TextProps.VALUE_FALSE,
            }
          }))
        } else {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              appealsAuthRepPhoneNumbError: TextProps.VALUE_FALSE,
              appealsAuthRepPhone: value.replace(/\D/g, ''),
              phoneNumberError: TextProps.VALUE_FALSE,
            }

          }))
        }
      }
    } else if (Type === "appealsAuthEmail") {
      if (value === "") {
        this.setState((prevState) => ({
          appeals: {
            ...prevState.appeals,
            authRepAppealsEmail: value,
            authRepEmailError: false,
          },
        }));
      } else {
        var emailRegEx = /\b\w*\.[a-zA-Z]\w*\b/;
        var isValidEmail = emailRegEx.test(value);

        this.setState((prevState) => ({
          appeals: {
            ...prevState.appeals,
            authRepAppealsEmail: value,
            authRepEmailError: !isValidEmail,
          },
        }));
      }
    } else if (Type === "benfit") {
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          benifitContinuation: value,
        }
      }));
    } else if (Type === "legalRep") {
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          showInput: value === 'Yes',
          legalRepresentative: value,
        }
      }));
    } else if (Type === "legalRepName") {
      if(value === ""){
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            LegalRepresentativeName: value,
            nameError: TextProps.VALUE_TRUE,
          }
        }))
      }else{
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          LegalRepresentativeName: value,
          nameError: TextProps.VALUE_FALSE,
        }
      }))
      }
     
    } else if (Type === "legalRepPhone") {
      if (value ===  "") {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            LegalRepresentativePhoneError: TextProps.VALUE_FALSE,
            phoneNumberError: TextProps.VALUE_FALSE,
          }
        }))
      }else{
        if (value.length < 14) {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              LegalRepresentativePhone: value.replace(/\D/g, ''),
              LegalRepresentativePhoneError: TextProps.VALUE_TRUE,
              phoneNumberError: TextProps.VALUE_FALSE,
            }
          }))
        } else {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              LegalRepresentativePhone: value.replace(/\D/g, ''),
              phoneNumberError: TextProps.VALUE_FALSE,
              LegalRepresentativePhoneError: TextProps.VALUE_FALSE
            }
  
          }))
        }
      }
    } else if (Type === "legalRepEmail") {
      var emailRegEx = /\b\w*\.[a-zA-Z]\w*\b/;
      var isValidEmail = emailRegEx.test(value);
      if(value === ""){
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            legalRepresentativeEmailErr: TextProps.VALUE_FALSE,
            emailAddressError: TextProps.VALUE_TRUE,
          }
        })
        )  
      }else{
      this.setState((prevState) => ({
        appeals: {
          ...prevState.appeals,
          LegalRepresentativeEmail: value,
          emailAddressError: TextProps.VALUE_FALSE,
          legalRepresentativeEmailErr: !isValidEmail
        },
      }));
    }
    } else if (Type === "comments") {
      this.setState(prevState => ({
        appeals: {
          ...prevState.appeals,
          comments: value,
        }
      }));
    } else if (Type === "date") {
      const currentDate = new Date();
      const date = new Date(value);
      if (date > currentDate) {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            allegedIncidentDate: this.formatDate(date),
            errorMessage: TextProps.VALUE_TRUE
          }
        }));
      } else {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            allegedIncidentDate: this.formatDate(date),
            errorMessage: TextProps.VALUE_FALSE
          }
        }));
      }
    } else if (Type === "clientPhoneNumber") {
      if (value === "") {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            appealsClientPhoneNumber: value.replace(/\D/g, ''),
            clientPhoneNumberError:TextProps.VALUE_FALSE

          }
        }))
      } else {
        if (value.length < 14) {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              appealsClientPhoneNumber: value.replace(/\D/g, ''),
              clientPhoneNumberError: TextProps.VALUE_TRUE
            }
          }))
        } else {
          this.setState(prevState => ({
            appeals: {
              ...prevState.appeals,
              appealsClientPhoneNumber: value.replace(/\D/g, ''),
              clientPhoneNumberError: TextProps.VALUE_FALSE
            }
          }))
        }
      }
    } else if (Type === "clientEmailAddress") {
      var emailRegEx = /\b\w*\.[a-zA-Z]\w*\b/;
      var formattedEmailAddress = emailRegEx.test(value);
      if (value === "") {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            clientEmailAddressErrorMsg: TextProps.VALUE_FALSE,
          }
        }))
      } else {
        this.setState(prevState => ({
          appeals: {
            ...prevState.appeals,
            appealsClientEmailAddress: value,
            clientEmailAddressErrorMsg:!formattedEmailAddress,
          }
        })
        )
      }
    }
  }


  handleConcerns = (handlerType, value) => {  
    if (handlerType === 'anonymusUserEmail'){
    if (value === "") {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          anonymusUserEmailError: true
        }
      }));
    } else {
      var emailRegEx =
      /\b\w*\.[a-zA-Z]\w*\b/
      var isValidEmail = emailRegEx.test(value);
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          anonymusUserEmail: value,
          anonymusUserEmailError: !isValidEmail
        }
      }));
    }
  }else if (handlerType === 'anonymusUserPhoneNumber'){
    if (value === "") {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          anonymusUserPhoneNumberError: TextProps.VALUE_TRUE,
        }
      }));
    } else{
      if (value.length < 14) {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            anonymusUserPhoneNumberError: TextProps.VALUE_TRUE
          }
        }));
      } else {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            anonymusUserPhoneNumber: value.replace(/\D/g, ''),
            anonymusUserPhoneNumberError: TextProps.VALUE_FALSE,
          }
        }));
      }
    }
  }else if (handlerType === 'type') {
    if(value === ""){
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          concernType: this.getTypeSelect(value),
          concernsTypeError: TextProps.VALUE_TRUE
        }
      }));
    }else{
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          concernType: this.getTypeSelect(value),
          concernsTypeError: TextProps.VALUE_FALSE
        }
      }));
    }
    } else if (handlerType === 'reason') {
      if(value === ""){
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            concernsReason: this.getReasonSelect(value),
            concernsReasonError: TextProps.VALUE_TRUE
          }
        }));
      } else {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          concernsReason: this.getReasonSelect(value),
          concernsReasonError: TextProps.VALUE_FALSE
        }
      }));
    }
    } else if (handlerType === 'program') {
      if(value === ""){
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            concernsProgram: this.getProgramSelect(value),
            concernsProgramError: TextProps.VALUE_TRUE
          }
        }));
      }
      else{
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          concernsProgram: this.getProgramSelect(value),
          concernsProgramError: TextProps.VALUE_FALSE
        }
      }));
    }
  }
    else if (handlerType === 'currentDate') {
      const currentDate = new Date();
      const date = new Date(value);
      if (date > currentDate) {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            allegedIncidentDate: this.formatDate(date),
            dateError: TextProps.VALUE_TRUE
          }
        }));
      } else {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            allegedIncidentDate: this.formatDate(date),
            dateError: TextProps.VALUE_FALSE
          }
        }));
      }
    } else if (handlerType === 'authName') {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          authRepName: value
        }
      }));
    } else if (handlerType === 'authPhoneNumber') {
      if (value === "") {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            authRepPhoneNumberError: TextProps.VALUE_FALSE,
          }
        }));
      } else {
        if (value.length < 14) {
          this.setState(prevState => ({
            concerns: {
              ...prevState.concerns,
              authRepPhoneNumberError: TextProps.VALUE_TRUE
            }
          }));
        } else {
          this.setState(prevState => ({
            concerns: {
              ...prevState.concerns,
              authRepPhoneNumber: value.replace(/\D/g, ''),
              authRepPhoneNumberError: TextProps.VALUE_FALSE,
            }
          }));
        }
      }
    } else if (handlerType === 'authEmail') {
      if (value === "") {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            authRepEmail: value,
            authRepEmailError: false
          }
        }));
      } else {
        var emailRegEx = /\b\w*\.[a-zA-Z]\w*\b/;
        var isValidEmail = emailRegEx.test(value);
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            authRepEmail: value,
            authRepEmailError: !isValidEmail
          }
        }));
      }
    } else if (handlerType === 'location') {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          OfficeLocation: value
        }
      }));
    } else if (handlerType === 'caseManager') {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          ManagerName: value
        }
      }));
    } else if (handlerType === 'caseManagerPhoneNumber') {
      if (value === "") {
        this.setState(prevState => ({
          concerns: {
            ...prevState.concerns,
            ManagerPhoneNumberError: TextProps.VALUE_FALSE,
          }
        }));
      } else {
        if (value.length < 14) {
          this.setState(prevState => ({
            concerns: {
              ...prevState.concerns,
              ManagerPhoneNumberError: TextProps.VALUE_TRUE
            }
          }));
        } else {
          this.setState(prevState => ({
            concerns: {
              ...prevState.concerns,
              ManagerPhoneNumberError: TextProps.VALUE_FALSE,
              ManagerPhoneNumber: value.replace(/\D/g, '')
            }
          }));
        }
      }
    } else if (handlerType === 'comments') {
      this.setState(prevState => ({
        concerns: {
          ...prevState.concerns,
          comments: value
        }
      }));
    }
  }

  _checkedHandler = (event) => {
    this.setState(prevState => ({
      concerns: {
        ...prevState.concerns,
        showField: !event.target.checked,
        checked: event.target.checked
      }
    }));
  };



  getValueForKey(value) {
    var obj = consumerConst(selectedLanguage).appealsReasonOptions;
    var result = Object.keys(obj).filter((key) => obj[key] === value);
    return result.length ? result[0] : "";
  }

  getProgramList(value) {
    switch (value) {
      case consumerConst(selectedLanguage).appealsProgramOptions[0]:
        return consumerConst(selectedLanguage).appealsProgramSNAP
      case consumerConst(selectedLanguage).appealsProgramOptions[1]:
        return consumerConst(selectedLanguage).appealsProgramTANF
      case consumerConst(selectedLanguage).appealsProgramOptions[2]:
        return consumerConst(selectedLanguage).appealsProgramJOBS
      default:
        return "";
    }
  }


  componentDidMount() {
    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;
    this.props.getConcernAppeal(agencyID, selectedLanguage);
  }

  componentWillReceiveProps(nextProps){
    let newSection, direction;
    if((nextProps.concernsAppeals && 
      nextProps.concernsAppeals.customerConcernsSuccess && 
      nextProps.concernsAppeals.customerConcernsSuccess.data && 
      nextProps.concernsAppeals.customerConcernsSuccess.data.concernReferenceNumber)){
        newSection = 'concernSubmit';
        direction = 'forward';
        this.setState({ section: newSection, transitionDirection: direction });
      }
      else if (nextProps.consumer && nextProps.consumer.consumerAppealsSuccess && nextProps.consumer.consumerAppealsSuccess.appealReferenceNumber) {
        newSection = 'appealSubmit';
        direction = 'forward';
        this.setState({ section: newSection, transitionDirection: direction });
  
      }
  }

  handleTypeSelection = () => {
    if (this.state.concerntype.customerType === "") {
      this.setState(prevState => ({
        concerntype: {
          ...prevState.concerntype,
          concernsTypeError: TextProps.VALUE_TRUE,
        }
      }))
    }

  }


  _typeSelectsection = (value) => {
    this.setState(prevState => ({
      concerntype: {
        ...prevState.concerntype,
        customerType: value,
        concernsTypeError: TextProps.VALUE_FALSE
      }
    }))
  }
  handleSubmitAppeals = () => {
    let newSection, direction;
    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;
    let payload = {
      appeals: {
        appealTypeCode: this.state.appeals.appealsInputValue,
        appealReasonCode: this.state.appeals.reason,
        appealProgramCode: this.state.appeals.program,
        benifitContinuationFlag: this.state.appeals.benifitContinuation === 'Yes' ? 'Y' : 'N',
        legalRepFalg: this.state.appeals.legalRepresentative === 'Yes' ? 'Y' : 'N',
        allegedIncidentDate: this.state.appeals.allegedIncidentDate,
        legalRepName: this.state.appeals.LegalRepresentativeName,
        legalRepPhoneNumber: this.state.appeals.LegalRepresentativePhone,
        legalRepEmail: this.state.appeals.LegalRepresentativeEmail,
        clientIdK: agencyID,
        authRepName: this.state.appeals.authRepName,
        authRepPhoneNumb: this.state.appeals.appealsAuthRepPhone,
        authRepEmail: this.state.appeals.authRepAppealsEmail,
        commentsDesc: this.state.appeals.comments,
        clientPhoneNumber: this.state.appeals.appealsClientPhoneNumber ? this.state.appeals.appealsClientPhoneNumber : this.state.appeals.appealsAnonymusUserPhoneNumber,
        clientEmail:this.state.appeals.appealsClientEmailAddress ?this.state.appeals.appealsClientEmailAddress :this.state.appeals.appealsAnonymusUserEmail,
        signedName: null,
      },
      concerns: {}
    }

    if (this.state.appeals.program === "" && this.state.appeals.reason === "") {
      this.setState({
        appeals: {
          ...this.state.appeals,
          programError: TextProps.VALUE_TRUE,
          reasonError: TextProps.VALUE_TRUE,
        }
      });
    }
    else if (this.state.appeals.legalRepresentative === 'Yes' && (this.state.appeals.LegalRepresentativeName === "" || this.state.appeals.LegalRepresentativePhone === "" || this.state.appeals.LegalRepresentativeEmail === "")) {
      this.setState({
        appeals: {
          ...this.state.appeals,
          nameError: this.state.appeals.LegalRepresentativeName === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
          phoneNumberError: this.state.appeals.LegalRepresentativePhone === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
          emailAddressError: this.state.appeals.LegalRepresentativeEmail === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
          LegalRepresentativePhoneError: TextProps.VALUE_FALSE,
          legalRepresentativeEmailErr: TextProps.VALUE_FALSE
        }
      });
    }
    else if (this.state.appeals.program === "" || this.state.appeals.reason === "") {
      this.setState({
        appeals: {
          ...this.state.appeals,
          programError: this.state.appeals.program === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
          reasonError: this.state.appeals.reason === "" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        }
      });
    }
    else if (this.state.appeals.program !== "" && this.state.appeals.reason !== "" && this.state.appeals.allegedIncidentDate !=="NaN-NaN-NaN") {
      this.setState({
        appeals: {
          ...this.state.appeals,
          programError: TextProps.VALUE_FALSE,
          reasonError: TextProps.VALUE_FALSE,
          errorMessage: TextProps.VALUE_FALSE
        }
      });
      this.props.getConsumerAppealsList(payload, selectedLanguage)
    }
    else if (this.state.appeals.legalRepresentative == 'Yes' && (this.state.appeals.LegalRepresentativeName !== "" && this.state.appeals.LegalRepresentativePhone !== "" && this.state.appeals.LegalRepresentativeEmail !== "") && this.state.appeals.allegedIncidentDate !=="NaN-NaN-NaN") {
      this.props.getConsumerAppealsList(payload)
    } else {
      this.setState({
        appeals: {
          ...this.state.appeals,
          nameError: TextProps.VALUE_FALSE,
          phoneNumberError: TextProps.VALUE_FALSE,
          emailAddressError: TextProps.VALUE_FALSE,
        }
      });
    }

     if(!this.state.appeals.allegedIncidentDate  || isNaN(new Date(this.state.appeals.allegedIncidentDate)) ){
      this.setState({
        appeals: {
          ...this.state.appeals,
          errorMessage: TextProps.VALUE_TRUE
        }
      })
    }
  }


  handleSubmitConcerns = () => {
    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;
    let payload = {
      concerns: {
        concernTypeCode: this.state.concerns.concernType,
        concernReasonCode: this.state.concerns.concernsReason,
        concernProgramCode: this.state.concerns.concernsProgram,
        anonymusFlag: this.state.concerns.checked === false ? "N" : "Y",
        allegedIncidentDate: this.state.concerns.allegedIncidentDate,
        anonymusUserName: null,
        clientPhoneNumber: this.state.concerns.anonymusUserPhoneNumber,
        clientEmail: this.state.concerns.anonymusUserEmail,
        clientIdK: agencyID,
        authRepName: this.state.concerns.authRepName,
        authRepPhoneNumb: this.state.concerns.authRepPhoneNumber,
        authRepEmail: this.state.concerns.authRepEmail,
        orgLocName: this.state.concerns.OfficeLocation,
        caseManagerName: this.state.concerns.ManagerName,
        caseManagerPhoneNumber: this.state.concerns.ManagerPhoneNumber,
        commentsDesc: this.state.concerns.comments
      },
      appeals: {},
    };
      this.setState({
        concerns: {
          ...this.state.concerns,
        concernsTypeError: this.state.concerns.concernType === "" ? TextProps.VALUE_TRUE : this.state.concerns.concernsTypeError,
        concernsReasonError: this.state.concerns.concernsReason === "" ? TextProps.VALUE_TRUE : this.state.concerns.concernsReasonError,
        concernsProgramError: this.state.concerns.concernsProgram === "" ? TextProps.VALUE_TRUE : this.state.concerns.concernsProgramError,
        }
    });
    if (!this.state.concerns.allegedIncidentDate || isNaN(new Date(this.state.concerns.allegedIncidentDate))) {
      this.setState({
        concerns: {
          ...this.state.concerns,
          dateError: TextProps.VALUE_TRUE
        }
      })
    }

    if (this.state.concerns.concernType !== "" && this.state.concerns.concernsReason !== "" && this.state.concerns.concernsProgram !== "" && this.state.concerns.allegedIncidentDate !=="NaN-NaN-NaN") {
      this.props.customerConcernsPostCall(payload, selectedLanguage);
    }
  }
  handleExist = () => {
    let newSection, direction;
    window.location.reload();
    newSection = 'LandingPage';
    direction = 'forward';
    this.setState({ section: newSection, transitionDirection: direction });
  }

  onPrimaryClick = () => {
    const { section, concerntype } = this.state;
    let newSection, direction;

    if (section === 'LandingPage') {
      newSection = 'typeconcern';
      direction = 'forward';
    } if (section === 'typeconcern') {
      this.handleTypeSelection();
      if (concerntype.customerType === consumerConst(selectedLanguage).concernsAppealTypeOptions[0]) {
        newSection = 'concern';
      } else if (concerntype.customerType === consumerConst(selectedLanguage).concernsAppealTypeOptions[1]) {
        newSection = 'appeal';
      }
    } if (section === 'concernSubmit' || section === 'appealSubmit') {
      this.handleExist();
      this.resetConcernsState();
    } if (section === 'concern') {
      this.handleSubmitConcerns();
    } if (section === "appeal") {
      this.handleSubmitAppeals();
    } if (newSection) {
      direction = 'forward';
      this.setState({ section: newSection, transitionDirection: direction });
    }
  };







  handlecancel = () => {
    const { section } = this.state;
    let newSection, direction;
    if (section === 'typeconcern') {
      newSection = 'LandingPage';
    } else if (section === 'appeal') {
      this.showWarningModal()
    } else if (section === 'appealCancel') {
      newSection = 'appeal';
    } else if (section === 'concernGoback') {
      newSection = 'concern';
    } else if (section === 'appealGoback') {
      newSection = 'appeal';
    } if (newSection) {
      direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    } if (section === 'concern') {
      this.showWarningModal()
    } if (section === 'appealCancel') {
      const newSection = 'appeal';
      const direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    } if (section === 'concernGoback') {
      const newSection = 'concern';
      const direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    } if (section === 'appealGoback') {
      const newSection = 'appeal';
      const direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    }
  }

  modalContent = () => {
    const { section } = this.state;
    return (
      <div style={{ padding: '1rem 1rem 1rem 1rem' }}>
        {section === 'concern' && (
          <span >
            {
              consumerConst(selectedLanguage).cancelText
            }<br />
            {
              consumerConst(selectedLanguage).cancelTextMain
            }
          </span>
        )}
        {section === 'appeal' && (
          <span >
            {
              consumerConst(selectedLanguage).appealCancelText
            }<br />
            {
              consumerConst(selectedLanguage).appealCancelTextBack
            }
          </span>
        )}
        <br />
        <div style={{ display: "flex", float: "right", padding: "2rem 1.5rem 1rem 1rem" }}>
          <button className="dta-button dta-button--outline-primary" style={{ marginRight: "20px", backgroundColor: 'white', color: '#002a57' }} onClick={this.closeWarningModal}>{consumerConst(selectedLanguage).concernCancel}</button>
          <button className="dta-button dta-button--primary" onClick={this.handleGoBackWarningModal}> {consumerConst(selectedLanguage).concernsGoback}</button>
        </div>
      </div>
    )
  }


  renderWarningPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.warningModal = instance),
      this.state.showWarningModal,
      this.closeWarningModal,
      "",
      "concerns-cancel",
      false, false, false,
      <div style={{ fontSize: '1.5rem', fontWeight: '700', padding: '0.5rem 0.5rem 0.5rem 0.5rem' }}>
        {consumerConst(selectedLanguage).concernsCancelHeader}</div>
      , this.modalContent(),
      false
    );
  };


  showWarningModal = () => {
    this.setState({ showWarningModal: TextProps.VALUE_TRUE });
  };


  closeWarningModal = () => {
    this.setState({
      showWarningModal: TextProps.VALUE_FALSE
    })
  };

  handleGoBackWarningModal = () => {
    this.closeWarningModal();
    this.resetConcernsState();
    this.resetAppealsData();
    const { section } = this.state;
    let newSection, direction;
    if (section === 'concern') {
      newSection = 'LandingPage';
    } if (newSection) {
      direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    }
    if (section === 'appeal') {
      newSection = 'LandingPage';
    } if (newSection) {
      direction = 'backward';
      this.setState({ section: newSection, transitionDirection: direction });
    }
  }
  resetConcernsState = () => {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    this.setState(prevState => ({
      concerntype: {
        ...prevState.concerntype,
        customerType: "",
      },
      concerns: {
        ...prevState.concerns,
        concernType: "",
        concernsReason: "",
        concernsProgram: "",
        authRepName: "",
        authRepPhoneNumber: "",
        authRepEmail: "",
        OfficeLocation: "",
        ManagerName: "",
        comments: "",
        ManagerPhoneNumber: "",
        showField: TextProps.VALUE_TRUE,
        checked: TextProps.VALUE_FALSE,
        allegedIncidentDate: formattedDate,
      }
    }))
  }
  resetAppealsData = () => {
    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;
    const anonymusUserEmail = clientDetails.clientEmail.emailAddress
    const anonymusUserName = clientDetails.clientInfo.name.first + clientDetails.clientInfo.name.last
    const anonymusUserPhoneNumber = clientDetails.clientPhone.phoneNumber
    this.setState(prevState => ({
      appealsType: {
        ...prevState.concerntype,
        customerType: "",
      },
      appeals: {
        program: "",
        reason: "",
        appealsInputDisplayValue: "Fair Hearing",
        appealsInputValue: "FAIRHR",
        appealsAnonymusUserName: anonymusUserName,
        appealsAnonymusUserPhoneNumber: anonymusUserPhoneNumber,
        appealsAnonymusUserEmail: anonymusUserEmail,
        appealsAgencyId: agencyID,
        appealsAuthRepPhoneNumb: "",
        benifitContinuation: "",
        showInput: false,
        legalRepresentative: "",
        name: "",
        phoneNumber: "",
        emailAddress: "",
        LegalRepresentativeName: "",
        LegalRepresentativePhone: "",
        LegalRepresentativeEmail: "",
        comments: "",
        currentDateTime: new Date(),
        editedValue: new Date(),
        errorMessage: '',
        authRepAppealsEmail: "",
        authRepPhoneNumber:""
      }
    }))
  }

  render() {
    const { section, transitionDirection } = this.state;
    return (
      <div className={
        this.state.section === 'concernSubmit'
          ? 'max-width-customer--concern-submit max-width-customer--slide-animations'
          : (this.state.section === 'LandingPage')
            ? 'max-width-customer--concern-fontend max-width-customer--slide-animations'
            : `max-width-customer--concern max-width-customer--slide-animations ${transitionDirection === 'forward' ? 'fadeInRight' : 'fadeOutLeft'}`
      }>
        <StepWizard activeStep={section} isHashEnabled={false}>
          {section === 'LandingPage' && (
            <div>
              <div>
                <span style={{ display: 'flex', alignItems: 'center', fontSize: '2rem', padding: '0.5rem 12rem 1rem 3rem' }}>{consumerConst(selectedLanguage).consumerconcern}</span>
              </div>
              <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px' }}>

              </div>
              <button className="dta-button dta-button--primary" style={{ marginLeft: '3.2rem', marginBottom: '1rem' }} onClick={this.onPrimaryClick}>
                {consumerConst(selectedLanguage).createNewCustomerLabel}
              </button>

              <ConcernHomeComponent />

            </div>
          )}

          {section === 'typeconcern' && (
            <ConcernAppealType typeSelectsection={this._typeSelectsection} concerntype={this.state.concerntype} />

          )}

          {section === 'concern' && (
            <div>
              <ConcernsComponent
                concerns={this.state.concerns}
                handleConcerns={this.handleConcerns}
                handleChecked={this._checkedHandler}
              />
            </div>
          )}
          {section === 'appeal' && (
            <div>
              <AppealComponent
                appeals={this.state.appeals}
                handleAppeals={this.handleAppeals}
              />

            </div>
          )}
          {section === 'concernSubmit' && (
            <div>
              {this.props.concernsAppeals.customerConcernsDataLoading === true ? (
                <Loading />
              ) : (
                <div>
                  <ConcernSubmit />
                    {(section === 'concernSubmit' || section === 'appealSubmit') && (
                      <div className="float-left secure" style={{ padding: '0px 12rem 1rem 3.5rem', marginTop: '10px', marginBottom: '2rem' }}>
                        <button className="dta-button dta-button--primary" onClick={this.onPrimaryClick}>
                          {consumerConst(selectedLanguage).exitLabel}
                        </button>
                      </div>
        )}
                </div>
              )}
            </div>
          )}

          {section === 'appealSubmit' && (
            <div>
              {this.props.consumer.consumerAppealsLoading === true ? (
                <Loading />
              ) : (
                <div>
                  <AppealsSubmit />
                    {(section === 'concernSubmit' || section === 'appealSubmit') && (
                      <div className="float-left secure" style={{ padding: '0px 12rem 1rem 3.5rem', marginTop: '10px', marginBottom: '2rem' }}>
                        <button className="dta-button dta-button--primary" onClick={this.onPrimaryClick}>
                          {consumerConst(selectedLanguage).exitLabel}
                        </button>
                      </div>
                    )}
                </div>
              )}
            </div>
          )}
          {section === 'appealCancel' && (
            <div>
              <AppealsCancel />
            </div>
          )}
          {this.renderWarningPopup()}
        </StepWizard>

        {/* Go Back and Continue/Submit Buttons */}
        {(section === 'typeconcern' || section === 'concern' || section === 'appeal' || section === 'appealGoback' || section === 'concernGoback' || section === 'appealCancel' || section === 'concernCancel') && (
          <div className="float-left secure" style={{ padding: '0px 12rem 1rem 30px' }}>

            <button className="dta-button dta-button--primary" style={{ backgroundColor: 'white', color: '#002a57', marginRight: '10px' }} onClick={this.handlecancel}>
              {consumerConst(selectedLanguage).concernCancel}
            </button>

            {(section === 'typeconcern') && <button className="dta-button dta-button--primary" onClick={this.onPrimaryClick}>
              {consumerConst(selectedLanguage).continueLabel}
            </button>}
            {(section === 'concern' || section === 'appeal') && <button className="dta-button dta-button--primary" onClick={this.onPrimaryClick}>
              {consumerConst(selectedLanguage).submitLabel}
            </button>}


          </div>
        )}
      </div>

    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      customerConcernsPostCall: customerConcernsPostCall,
      getConcernAppeal: getConcernAppeal,
      getConsumerAppealsList: getConsumerAppealsList
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(concernsContainer);