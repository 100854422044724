export const HOME_PATH = "/";
export const SCREENING_PATH = "/screening";
export const APPLY_PATH = "/apply";
export const APPLY_WEBAPP_ID_PATH = "/apply/:webAppId";
export const APPLY_TAFDC_PATH = "/applyTafdc";
export const APPLY_EAEDC_PATH = "/applyEaedc";
export const RECERTIFICATION_PATH = "/recertification";
export const INTERIM_REPORT_PATH = "/interimReport";
export const COVID_RECERT_PATH = "/covidRecert";
export const DECLARE_EXPENSES_PATH = "/declareExpenses";
export const PEBT_PATH = "/PEBT";
export const SIGNUP_PATH = "/signup";
export const LOGIN_PATH = "/login";
export const FORGOT_PASSWORD_PATH = "/forgotPassword";
export const CONSUMER_PATH = "/consumer";
export const CONSUMER_LOGIN_PATH = "/consumer/login";
export const CONSUMER_HOME_PATH = "/consumer/home";
export const CONSUMER_MY_INFO_PATH = "/consumer/myInfo";
export const CONSUMER_CONTACT_INFO_PATH = "/consumer/contactInfo";
export const CONSUMER_DOCUMENT_PATH = "/consumer/document";
export const CONSUMER_MY_ACC_PATH = "/consumer/myAccount";
export const PROVIDER_PATH = "/provider";
export const PROVIDER_HOME_PATH = "/provider/home";
export const SEARCH_CONSUMER_PATH = "/searchConsumer";
export const PROVIDER_SEARCH_CONSUMER_PATH = "/provider/searchConsumer";
export const PROVIDER_SNAPOUTREACH_PATH = "/provider/snapOutreach";
export const GREETER_ROLE = "/greeterScreening";
export const MH_PROVIDER_PATH = "/mhProvider";
export const MHPROVIDER_SNAPOUTREACH_PATH = "/mhProvider/snapOutreach";
export const SNAP_OUTREACH_PATH = "/snapOutreach";
export const BENEFIT_DETAILS_PATH = "/benefitDetails";
export const API_ERR_PATH = "/apiError";
export const PEBT_CASE_INFO = "/:pebtCaseInfo";
export const PEBT_REPLACEMENT = "/:replacepebt";
export const REPORTCHANGEPATH = "/reportchange";
export const SNAP_SIX_MONTH_REPORT_PATH = "/snapSixMonthReport";
export const TAFDC_PATH = "/tafdc";
export const APPEAL_WITHDRAW="/consumer/concernappeal/withdraw";
export const CUSTOMER_CONCERNS = "/concerns";
export const CUSTOMER_CONCERNS_SUBMIT = "/concerns/submit";
export const APPEAL_WITHDRAW_SUBMIT = "/appealWithDraw/submit"
export const APPLY_DSNAP = "/dSnap";  

