import * as TextProps from "../../utils/constants/text";

export function applyTafdcReducer(state = {}, action) {
  switch (action.type) {
    case "APPLY_TAFDC_PAGES_FULLFILLED": {
      let newState = Object.assign({}, state);
      newState.sections = action.payload;
      return newState;
    }
    case "APPEND_TAFDC_LAST_KNOWN_LOCATION": {
      let newState = Object.assign({}, state);
      newState.sections[5].partialSave = TextProps.VALUE_TRUE;
      newState.sections[5].lastKnownLocation = action.payload;
      return newState;
    }
    case "TAFDC_NETWORK_FAILED_LOAD_DATA": {
      let stateObj = Object.assign({}, state);
      if (stateObj.sections.length > 0) {
        if (stateObj.sections[0].errorRedirect !== undefined) {
          stateObj.sections[0].errorRedirect = TextProps.VALUE_TRUE;
          return stateObj;
        }
      }
      return Object.assign({}, state);
    }
    case "POST_APPLY_TAFDC_INFO_DATA": {
      let stateObj = Object.assign({}, state);
      //INSTEAD, CHECKING AND SETTING ONY ID.
      if (action.payload.data.appId !== undefined) {
        stateObj.sections[0].isSubmitted = TextProps.VALUE_TRUE;
        stateObj.sections[0].webAppId = action.payload.data.appId;
        stateObj.application = action.payload.data;
      }
      if (action.payload.status === 200) {
        stateObj.applicationSubmited = TextProps.VALUE_TRUE;
      }
      return stateObj;
    }
    case "FILL_TAFDC_INPUT_VALUES_STATE": {
      return Object.assign({}, state);
    }
    case "APPEND_TAFDC_APPLY_ANSER": {
      let newState = Object.assign({}, state);
      newState.AnswerObject = action.payload[0];
      newState.dalReference = action.payload[1];
      return newState;
    }
    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      applyInit.inProgressSaveErrorState = TextProps.VALUE_FALSE;
      applyInit.inProgressSaveErrorMessage = "";
      return applyInit;
    }

    default:
      return Object.assign({}, state);
  }
}
