import { formatDateNew } from "../../utils/components/utilities/formatters";
import { aboutMeMultilanguageLabels } from "./applyEaedcText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

export function convertToDTOObj(sections, userSignature, inProgressObj) {
  const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  //from UI answers and questions
  let aboutMe = sections[1].questions;

  let applicant = new Person();

  applicant.personId = sections[1].webClientId;
  applicant.firstName = aboutMe[2].Answer;
  applicant.middleName = aboutMe[3].Answer;
  applicant.lastName = aboutMe[4].Answer;

  if (
    aboutMe[5].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[0]
  ) {
    applicant.nameSuffix = "I";
  } else if (
    aboutMe[5].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[10]
  ) {
    applicant.nameSuffix = "Jr.";
  } else if (
    aboutMe[5].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[11]
  ) {
    applicant.nameSuffix = "Sr.";
  } else {
    applicant.nameSuffix = aboutMe[5].targetValue;
  }

  if (
    aboutMe[6].Answer !== "" &&
    aboutMe[6].Answer !== undefined &&
    aboutMe[6].Answer !== null
  ) {
    applicant.birthDate = formatDateNew(aboutMe[6].Answer);
  }
  {
    console.log(aboutMe[8].Answer);
  }
  applicant.sSN = aboutMe[7].Answer;
  applicant.clientId = aboutMe[8].Answer;
  applicant.street = aboutMe[9].Answer.street;
  applicant.city = aboutMe[9].Answer.city;
  applicant.state = aboutMe[9].Answer.state;
  applicant.postal = aboutMe[9].Answer.zipCode;
  // To split the phonenumber into area code, exchangeNumber, lineNumber
  if (
    aboutMe[10].Answer !== undefined &&
    aboutMe[10].Answer !== null &&
    aboutMe[10].Answer !== ""
  ) {
    const phoneNumber = aboutMe[10].Answer.replace(/[^0-9]/g, "");
    applicant.areaCode = phoneNumber.substring(0, 3);
    applicant.exchangeNumber = phoneNumber.substring(3, 6);
    applicant.lineNumber = phoneNumber.substring(6, 10);
  }
  applicant.emailAddress = aboutMe[11].Answer;
  if (aboutMe[12].Answer !== undefined && aboutMe[12].Answer !== "") {
    if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[0]
    ) {
      applicant.languageSpoken = "SQ";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[1]
    ) {
      applicant.languageSpoken = "AU";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[2]
    ) {
      applicant.languageSpoken = "AM";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[3]
    ) {
      applicant.languageSpoken = "AR";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[4]
    ) {
      applicant.languageSpoken = "HY";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[5]
    ) {
      applicant.languageSpoken = "KEA";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[6]
    ) {
      applicant.languageSpoken = "ZH";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[7]
    ) {
      applicant.languageSpoken = "YUE";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[8]
    ) {
      applicant.languageSpoken = "CMN";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[9]
    ) {
      applicant.languageSpoken = "HR";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[10]
    ) {
      applicant.languageSpoken = "EN";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[11]
    ) {
      applicant.languageSpoken = "FR";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[12]
    ) {
      applicant.languageSpoken = "EL";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[13]
    ) {
      applicant.languageSpoken = "HT";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[14]
    ) {
      applicant.languageSpoken = "LO";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[15]
    ) {
      applicant.languageSpoken = "KM";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[16]
    ) {
      applicant.languageSpoken = "IT";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[17]
    ) {
      applicant.languageSpoken = "NE";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[18]
    ) {
      applicant.languageSpoken = "OTHER";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[19]
    ) {
      applicant.languageSpoken = "PL";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[20]
    ) {
      applicant.languageSpoken = "PT";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[21]
    ) {
      applicant.languageSpoken = "RU";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[22]
    ) {
      applicant.languageSpoken = "SR";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[23]
    ) {
      applicant.languageSpoken = "SL";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[24]
    ) {
      applicant.languageSpoken = "SO";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[25]
    ) {
      applicant.languageSpoken = "ES";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[26]
    ) {
      applicant.languageSpoken = "TL";
    } else if (
      aboutMe[12].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[27]
    ) {
      applicant.languageSpoken = "VI";
    }
  }

  if (aboutMe[13].Answer !== undefined) {
    if (
      aboutMe[13].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.pregnant = TextProps.VALUE_TRUE;
    } else if (
      aboutMe[13].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.pregnant = TextProps.VALUE_FALSE;
    }
  }

  if (aboutMe[14].Answer !== undefined) {
    if (
      aboutMe[14].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.hasChildren = TextProps.VALUE_TRUE;
    } else if (
      aboutMe[14].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.hasChildren = TextProps.VALUE_FALSE;
    }
  }

  if (aboutMe[15].Answer !== undefined) {
    if (
      aboutMe[15].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.requireAssitiveTechnology = TextProps.VALUE_TRUE;
    } else if (
      aboutMe[15].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.requireAssitiveTechnology = TextProps.VALUE_FALSE;
    }
  }

  if (aboutMe[16].Answer !== undefined) {
    if (
      aboutMe[16].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.disabledClient = TextProps.VALUE_TRUE;
    } else if (
      aboutMe[16].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.disabledClient = TextProps.VALUE_FALSE;
    }
  }

  var incomes = [];
  var sourceOne = {};
  var sourceTwo = {};
  var sourceThree = {};
  var sourceFour = {};
  var sourceFive = {};

  if (aboutMe[17].Answer !== undefined && aboutMe[17].Answer !== "") {
    if (aboutMe[17].Answer === TextProps.VALUE_YES) {
      applicant.hasIncome = TextProps.VALUE_TRUE;
      var resultArray = aboutMe[17].conditionals[0].conditionalQuestions;
      if (
        (resultArray[0].Answer !== undefined && resultArray[0].Answer !== "") ||
        (resultArray[1].Answer !== undefined && resultArray[1].Answer !== "") ||
        (resultArray[2].Answer !== undefined && resultArray[2].Answer !== "") ||
        (resultArray[3].Answer !== undefined && resultArray[3].Answer !== "") ||
        (resultArray[4].Answer !== undefined && resultArray[4].Answer !== "") ||
        (resultArray[5].Answer !== undefined && resultArray[5].Answer !== "") ||
        (resultArray[6].Answer !== undefined && resultArray[6].Answer !== "") ||
        (resultArray[7].Answer !== undefined && resultArray[7].Answer !== "") ||
        (resultArray[8].Answer !== undefined && resultArray[8].Answer !== "") ||
        (resultArray[9].Answer !== undefined && resultArray[9].Answer !== "")
      ) {
        sourceOne = {
          source: resultArray[0].Answer,
          grossIncome: resultArray[1].Answer,
        };
        sourceTwo = {
          source: resultArray[2].Answer,
          grossIncome: resultArray[3].Answer,
        };
        sourceThree = {
          source: resultArray[4].Answer,
          grossIncome: resultArray[5].Answer,
        };
        sourceFour = {
          source: resultArray[6].Answer,
          grossIncome: resultArray[7].Answer,
        };
        sourceFive = {
          source: resultArray[8].Answer,
          grossIncome: resultArray[9].Answer,
        };
      }

      incomes.push(sourceOne, sourceTwo, sourceThree, sourceFour, sourceFive);
    } else if (aboutMe[17].Answer === TextProps.VALUE_NO) {
      applicant.hasIncome = TextProps.VALUE_FALSE;
    }
  }
  applicant.incomes = incomes;

  applicant.clientId = aboutMe[8].Answer;

  applicant.signedName = userSignature;

  if (aboutMe[18].Answer !== undefined) {
    if (
      aboutMe[18].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.requestEBTCard = TextProps.VALUE_Y;
    } else if (
      aboutMe[18].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.requestEBTCard = TextProps.VALUE_N;
    }
  }

  return applicant;
}

function Person() {
  this.firstName = "";
  this.middleName = "";
  this.lastName = "";
  this.nameSuffix = "";
  this.birthDate = null;
  this.pregnant = "";
  this.requireAssitiveTechnology = null;
  this.disabledClient = null;
  this.hasChildren = "";
  this.areaCode = "";
  this.exchangeNumber = "";
  this.lineNumber = "";
  this.emailAddress = "";
  this.street = "";
  this.city = "";
  this.state = "";
  this.postal = "";
  this.signedName = "";
  this.clientId = "";
  this.amountInBank = "";
  this.gender = "";
  this.lastDateOfEmployment = "";
  this.recievesChildSupport = null;
  this.householdChildren = [];
  this.incomes = [];
  this.medicalInfo = {};
  this.requestEBTCard = "";
}

//web_application
function Application() {
  //Properties For Read
  this.applicant = new Person();
}
