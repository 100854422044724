import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
import DTADropdown from "../../utils/components/dtaDropdown";
import * as roleProps from "../../utils/constants/rolesConstants";
import {bindAnswers} from '../../utils/helper/wizardHelper'
import ReportChangeSection from "./components/ReportChangeSection";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import { ReportChangePageLabels,incomeDynamicMultilanguageLabels } from "./reportChangeText";
import * as languageConstants from "../../utils/constants/constants";
import { formatDate, formatDateNew } from "../../utils/components/utilities/formatters";
import * as formatters from "../../utils/components/utilities/formatters";
import ChildSupport from "./components/ChildSupport";
import ShelterExpenses from "./components/ShelterExpenses";
import { processHouseholdData } from "./DTO";
import { shelterSections,shelterBaseQuestions,medicalExpenseKeys,medicalExpenseTemplate,medicalExpenseObj,iDontPayShelter } from "./reportChangeMapData";
import {setUtilityDataChangeCode,filterQuestionsBySelection,getDataChangeCode,getMemberDataObjectByNameAndDOB,incomeExistingTypes} from './helper'
import { getShelterExpenseFrequency } from "./components/ShelterExpenses";
import MedicalExpenses from "./components/MedicalExpenses";
import {retriveSections,dependentCareAddress} from './reportChangeMapData';
import Checkbox from "../../utils/components/checkbox";
import DTAInputSubmit from "../../utils/components/dtaInputSubmit";
import DocumentTitle from "react-document-title";
import Modal from '../../utils/components/modal/dtaModal';
import AuthRep from "./components/AuthRep";
import Attendance from './components/Attendance';

import { fetchSectionsByModule,
  addHouseholdIntoSections,
  setHouseholdMembersList,
  addHouseholdToList,
  deleteHouseholdIntoSections,
  setError,
  postData,
  clearMemberIncome,
  addRCIncomeSections,
  updateReportChangeSections,
  setShelterData,
  appendRCShelterFilteredQuestions,
  appendRCSections,
  appendChildSupportAnswer,
  appendSignInSections,
  clearMemberResources,
  appendResourcesDetails
 } from "./reportChangeActions";
 import {
  getConsumerAboutMeData,
} from "../../redux/consumer/consumerMyInfoActions";
 import { IncomeSectionTemplate,incomeSections,incomeSummaryTemplate, expenseChildSupportTemplate, resourcesSummaryTemplate, ResourcesSectionTemplate, resourceSections } from "./reportChangeMapData";

 import {consumerConst} from '../consumer/consumerText';
import * as ControlConstants from "../../utils/constants/controls";
import { toValidateHouseholdSection,AuthRepValidator,AttendanceValidator,checkForAttendanceChange } from "./validator";
//azure
import { withMsal } from "@azure/msal-react";

// apply specific imports
import {
  getNextSection,
  getPreviousSection,
  getSection,
  updateCurrentSectionCompletionStatus,
} from "../../utils/helper/pageRoutingHelper";
import {
  childDependnMultilanguageLabels,
  recertificationPageLabels,
  recertWarningPopupMLanguageLabels
} from "../recertification/recertificationText";
import {
  dependentCareTemplate,
} from "../recertification/recertificationMapData";
// Redux implementations
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";

import Household from "./components/Household"; 
import EarnedIncomeSummary from './components/IncomeSummary'
import * as OptionsProps from "../../utils/constants/options";

import * as TextProps from "../../utils/constants/text";

import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import Icon from "../../utils/components/dtaIcon";
import * as config from "../../utils/constants/appConfigConstants";
import {
  getFrequency,
  getFrequencyCode,
  getShelterandChildSupportFrequency
} from "../../utils/components/utilities/utils";

import { incomeCodeMapper } from "./incomeCodemapper";
import { convertDOBForDataPost,  resourcesSubsectionRenderHelper } from "./helper";

import * as Props from "../../utils/components/shared";
import * as types from "../../utils/constants/types";
import * as UnitsProps from "../../utils/constants/units";
import { appStorage } from "../../utils/helper/app-storage";
import { languageChanged } from "../../redux/applySnap/applySnapActions";
import ResourcesSummary from "./components/ResourcesSummary";
import { earnedIncomeCodeMapper } from "../recertification/earnedIncomeMapData";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");


/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class ReportChangeContainer extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.applicationSignRef = React.createRef();
    this.termsRef = React.createRef();
    this.updateHouseHoldIncomeDetails =
      this.updateHouseHoldIncomeDetails.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onResourcesSubsectionRender = this.onResourcesSubsectionRender.bind(this);
    this.resourcesfilterQuestionsBySelection =
      this.resourcesfilterQuestionsBySelection.bind(this);
    this.resourcesSummary = {};
    this.incomeSummary = {};
    this.shelterSummary={};
    this.medicalSummary={};
    this.incomeSubSections = [];
    this.providerAddressTemplate = [];
    this.responseData = {};
    this.finalPayload = {};
    this.resourcesSubSections = [];
    this.state = {
      sections: [],
      renderedSections: [],
      languageChangeWarning:TextProps.VALUE_FALSE,
      ebtPhoneValid: TextProps.VALUE_FALSE,
        snpPhoneValid: TextProps.VALUE_FALSE,
        signedNameValid: TextProps.VALUE_FALSE,
      activeSectionId: this.getPageConfig().activeSectionId,
      previousSectionId: null,
      inProgressSectionId: this.getPageConfig().inProgressSectionId,
      directionClass: "forward",
      showSuccessModal: TextProps.VALUE_FALSE,
      showWarningModal:TextProps.VALUE_FALSE,
      dependentCareDataUpdated:TextProps.VALUE_FALSE,
      dataChangeStatus:TextProps.VALUE_FALSE,
      warningModalOpenStatus: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      AnswerObject: [],
      requestBody: {},
      householdMemberSectionData: [],
      editingModuleSuccess: "",
      incomeSubSectionCleared: false,
      houseMemberLabels:[],
      continueLabelEnabled : false,
      medicalSubSectionCleared:false,
      houseMemberLabels:[],
      signature:"",
      authRepData:{},
      authRepError:{
      },
      attendanceData:[],
      isChangeInAttendance:null,
      resourcesSummaryTemplate:resourcesSummaryTemplate,
      resourcesValuesPopulated: TextProps.VALUE_FALSE,
      resourcesSubSectionCleared: TextProps.VALUE_FALSE,
      resourcesMemberTemplate: null,
    };
  }

  handleebtPhoneValid = (value) => {
    this.setState({ebtPhoneValid : value})
  }

  handlesnpPhoneValid = (value) => {
    this.setState({snpPhoneValid : value})
  }

  handleSignedNameValid = (value) => {
    this.setState({signedNameValid: value})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.consumer.reportChangeSections) {
      this.setState(
        {
          sections: this.props.consumer.reportChangeSections,
        },
        () => {
          if (this.state.renderedSections.length < 1) {
            this.setState({
              renderedSections: [
                this._getSection(this.getPageConfig().activeSectionId),
              ],
            });
          }
        }
      );
    }

    if(this.successModal){
    if (nextProps && nextProps.consumer.postDataLoading) {
        this.successModal.showBlur("loading", null);
    }
    if (
      nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
      nextProps.apply.islanguageChanged === true
    ) {
        this.setState({
          languageChangeWarning: TextProps.VALUE_TRUE,
        });
      }
    if (nextProps && nextProps.consumer.postDataSuccess) {
     setTimeout(
        this.successModal.showBlur(
          "success",
          <div>{this.state.editingModuleSuccess}</div>)
      , 32039197500);
      setTimeout(() => {
        this.redirectConsumerHome();
      }, 7500);
    }

    if (nextProps && nextProps.consumer.postDataError) {
      this.successModal.showBlur(
        "error",
        <div>Please try again later.</div>
      );
      setTimeout(() => {
        this.redirectConsumerHome();
      }, 2000);
    }
    if (
      nextProps.apply.isAcesButtonClicked != undefined &&
      nextProps.apply.isAcesButtonClicked === true
    ) {
        this.redirectToHomePage();
    }
  }
  }
  redirectToHomePage = () => {
    window.location.assign("/consumer/myInfo");
  };
  getPageConfig = () => {
    // const urlParams = new URLSearchParams(window.location.search);
    const pageModule = window.sessionStorage.getItem("module");
    if(pageModule){
    return {
      activeSectionId: pageModule,
      inProgressSectionId: pageModule,
    };
  }else{
    this.redirectConsumerHome();
  }
  };
  //Render Names on Main Care and Kids Page
  renderCaringKidsAdults = (module) => {
    let houseMemberList = this.newUpdateHouseHoldIncomeDetails();
    let expenseSection = retriveSections(module)[0];
    //LOAD THE MEMEBER DETAILS IF FRESH APPLY PROCESS
      expenseSection.questions[0].options = [];
      expenseSection.questions[0].options = houseMemberList;
      let conditionalQuestionTemplate =
        expenseSection.questions[0].conditionals[0];
      expenseSection.questions[0].conditionals = [];
      for (var memIndex = 0; memIndex < houseMemberList.length; memIndex++) {
        expenseSection.questions[0].conditionals[memIndex] = _.cloneDeep(
          conditionalQuestionTemplate
        );
        expenseSection.questions[0].conditionals[memIndex].valueToMatch =
          houseMemberList[memIndex];
        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].label = recertificationPageLabels(
          language,
          houseMemberList[memIndex],
          languageConstants.householdDynamicRecert
        ).conditionalLabel;
        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].name = "recipient-of-care-" + [memIndex];

        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].options = [];
        for (let houseMemberValue of houseMemberList) {
          if (houseMemberValue !== houseMemberList[memIndex]) {
            expenseSection.questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].options.push(houseMemberValue);
          }
        }
    }
      expenseSection.questions[0].options = [];
      expenseSection.questions[0].options = houseMemberList

    this.props.updateReportChangeSections([expenseSection]);
    return [expenseSection];
  };
  setDependentcarecosts = (dependentCareSec) => {
    let consumerDependantCare;
    if(this.props.consumer.consumerData.dependantCare){
      sessionStorage.setItem("consumerDependantCare", JSON.stringify(this.props.consumer.consumerData.dependantCare));
      consumerDependantCare = this.props.consumer.consumerData.dependantCare;
    }else{
      consumerDependantCare = JSON.parse(sessionStorage.getItem("consumerDependantCare"));
    }
    let depenObj = _.cloneDeep(dependentCareSec[0]); 
    depenObj.questions[0].options = this.newUpdateHouseHoldIncomeDetails();
    let payeeAnsObj = [];
    consumerDependantCare.map(dep=>{
      let payee = dep.payee.firstName + " " +  dep.payee.lastName + " " + formatters.formatDate(dep.payee.dateOfBirth);
      let dependent = dep.dependent.firstName + " " +  dep.dependent.lastName + " " + formatters.formatDate(dep.dependent.dateOfBirth);
      if(depenObj.questions[0].options.includes(payee)){
        payeeAnsObj.push(payee);
      }
      depenObj.questions[0].conditionals.map(cond => {
        if(cond.valueToMatch === payee){
          if(cond.conditionalQuestions[0].Answer && !cond.conditionalQuestions[0].Answer.includes(dependent)){
            let dependentAns = _.cloneDeep(cond.conditionalQuestions[0].Answer);
            dependentAns.push(dependent);
            cond.conditionalQuestions[0] = this.setAnswers(cond.conditionalQuestions[0],dependentAns);
          }else if(!cond.conditionalQuestions[0].Answer){
            let dependentList = [];
            dependentList.push(dependent);
            cond.conditionalQuestions[0] = this.setAnswers(cond.conditionalQuestions[0],dependentList);
          }
        }
      })
    })
    depenObj.questions[0] = this.setAnswers(depenObj.questions[0],payeeAnsObj);
    this.props.updateReportChangeSections([depenObj]);
    this.renderDependentsCareCosts([depenObj]);
    consumerDependantCare.map(dep=>{
      let payee = dep.payee.firstName + " " +  dep.payee.lastName + " " + formatters.formatDate(dep.payee.dateOfBirth);
      let dependent = dep.dependent.firstName + " " +  dep.dependent.lastName + " " + formatters.formatDate(dep.dependent.dateOfBirth);
      let dependentMember = payee + "-" + dependent;
      if(depenObj.subsections){
        depenObj.subsections.map(sub => {
        if(sub.dependentMember === dependentMember){
          if(dep.providerCareAmount){
            let providerDetails = {
              "amount": dep.providerCareAmount,
              "unit": getFrequency(language)[
                dep.providerCareFrequencyCode
              ]
            }
            sub.questions[0] = this.setAnswers(sub.questions[0],providerDetails);
          }
          if(dep.transportationPayFlag === TextProps.VALUE_TRUE){
            sub.questions[1] = this.setAnswers(sub.questions[1],TextProps.VALUE_YES);
            if(dep.transportationAmount){
              let transportation ={
                "amount": dep.transportationAmount,
                "unit": getFrequency(language)[
                  dep.transportationFrequencyCode
                ]
              }
              sub.questions[1].conditionals[0].conditionalQuestions[0] = this.setAnswers(sub.questions[1].conditionals[0].conditionalQuestions[0],transportation);
            }
          }else{
            sub.questions[1] = this.setAnswers(sub.questions[1],TextProps.VALUE_NO);
          }
          if(dep.drivetoProviderFlag === TextProps.VALUE_TRUE){
            sub.questions[2] = this.setAnswers(sub.questions[2],TextProps.VALUE_YES);
            sub.questions[2].conditionals[0].conditionalQuestions[0] = this.setAnswers(sub.questions[2].conditionals[0].conditionalQuestions[0],dep.drivetoProviderCount);
          }else{
            sub.questions[2] = this.setAnswers(sub.questions[2],TextProps.VALUE_NO);
          }
        }
      })
      }
    });
    this.props.updateReportChangeSections([depenObj]);
    consumerDependantCare.map(dep=>{
      let payee = dep.payee.firstName + " " +  dep.payee.lastName + " " + formatters.formatDate(dep.payee.dateOfBirth);
      if(depenObj.subsections){
        depenObj.subsections.map(sub => {
        if(sub.payee === payee){
          let activeId = _.cloneDeep(sub.id);
          let activeIdSplit = activeId.split("-");
          let activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          this.addAddressesForDepCare([depenObj],activeSplitMemberIndex);
          if(sub.subsections && sub.subsections.length === dep.drivetoProviderCount){
            dep.transportDetails.map((subsec,indx) => {
              let ansObj = {
                "addressLine1": subsec.address.line1,
                "addressLine2":subsec.address.line2,
                "city": subsec.address.city,
                "county": subsec.address.county,
                "state": subsec.address.state,
                "zipCode": subsec.address.zip,
                "verified": "N"
              }
              let driveTrip ={
                "amount": subsec.driveTripCount,
                "unit": getFrequency(language)[
                  subsec.driveFrequencyCode
                ]
              }
              sub.subsections[indx].questions[0] = this.setAnswers(sub.subsections[indx].questions[0],ansObj);
              sub.subsections[indx].questions[1] = this.setAnswers(sub.subsections[indx].questions[1],driveTrip);
            })
          }
        }
      })
      }
    });
    this.props.updateReportChangeSections([depenObj]);
  };
  setAnswers = (array,value) => {
    array.Answer = value;
    array.targetValue = value;
    array.value = value;
    return array;
  }


  renderLanguageSwitchWarningModal = () => {

    return (
      <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
      <Modal
        isOpen={this.state.languageChangeWarning}
        onClose={() =>{this.props.languageChanged({ isCancel: true}),
        this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={recertWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small"> 
          {recertWarningPopupMLanguageLabels(language).languageWarningLabel}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>{
                this.props.languageChanged({ isCancel: true})
                this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}
              }
            >
              {recertWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => {
                this.props.languageChanged({ isDelete: true})}}
            >
              {recertWarningPopupMLanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      </div>
    );
  };
  addAddressesForDepCare = (depenObj,memberIndex) => {
    let newDepend = _.cloneDeep(
      depenObj[0]
    );
    let kidsAdultsForMember = depenObj[0]
      .subsections[memberIndex];
    let mainQuestionAnswer = kidsAdultsForMember.questions[2].Answer;
    if (this.providerAddressTemplate[memberIndex] === undefined) {
      this.providerAddressTemplate[memberIndex] = _.cloneDeep(
        kidsAdultsForMember.subsections ? kidsAdultsForMember.subsections : _.cloneDeep(dependentCareAddress)
      );
    } else {
      kidsAdultsForMember.subsections = _.cloneDeep(
        this.providerAddressTemplate[memberIndex]
      );
    }

    if (
      mainQuestionAnswer ===
      kidsAdultsForMember.questions[2].conditionals[0].valueToMatch
    ) {
      let providerAddressNo =
        kidsAdultsForMember.questions[2].conditionals[0].conditionalQuestions[0]
          .Answer;
      if (providerAddressNo > 0) {
        kidsAdultsForMember.subsections = [];
        for (
          var bindAddressIndex = 0;
          bindAddressIndex < providerAddressNo;
          bindAddressIndex++
        ) {
          kidsAdultsForMember.subsections[bindAddressIndex] = _.cloneDeep(
            this.providerAddressTemplate[memberIndex][0]
          );
          kidsAdultsForMember.subsections[bindAddressIndex].id =
            "kids-adult-multiple-address-" +
            memberIndex +
            "-" +
            bindAddressIndex;
            kidsAdultsForMember.subsections[bindAddressIndex].immParentId =
            kidsAdultsForMember.id;
          kidsAdultsForMember.subsections[bindAddressIndex].header =
          childDependnMultilanguageLabels(
            kidsAdultsForMember.dependentMember.split("-")[1],
            "",
            language
          ).depedentCostHeader +
            " " +
            (bindAddressIndex + 1);
          if (
            newDepend.subsections[memberIndex].subsections !== undefined &&
            newDepend.subsections[memberIndex].subsections.length > 0 &&
            newDepend.subsections[memberIndex].subsections[bindAddressIndex] !==
              undefined
          ) {
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].Answer =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].value =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].targetValue =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].targetValue;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].Answer =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].value =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].targetValue =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].targetValue;
          }
        }
      } else {
        delete kidsAdultsForMember.subsections;
      }
    } else {
      delete kidsAdultsForMember.subsections;
    }
    this.props.updateReportChangeSections(depenObj);
  };
  componentDidMount() {
    if(this.getPageConfig().activeSectionId === "Dependentcarecosts"){
      let dependentCareSec =  this.renderCaringKidsAdults(this.getPageConfig().activeSectionId);
      this.setDependentcarecosts(dependentCareSec);
    }else{
      this.props.fetchSectionsByModule(this.getPageConfig().activeSectionId);
    }
    this.setInitialStateForModule(this.getPageConfig().activeSectionId);
    if(_.isEmpty(this.props.consumer.consumerData)){
      const userObject = appStorage.getUserInfo();
      if(userObject){
        const { email } = userObject;
        this.props.getConsumerAboutMeData(email);
      }
    }else{
      this.setInitialDataForModule(this.getPageConfig().activeSectionId);
    }
    this.updateDimensions();
    window.addEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
//attendance

    const { consumer } = this.props;
        let consumerDetails = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
        let schoolAttendance = [];
        let householdMembers = [];
        if (consumer && Object.keys(consumer.consumerData).length > 0) {
            ({ schoolAttendance, householdMembers } = consumer.consumerData);
        } else if (consumerDetails && consumerDetails.schoolAttendance) {
            ({ schoolAttendance, householdMembers } = consumerDetails);
        }

        schoolAttendance && schoolAttendance.forEach((oneAtt)=>{
            oneAtt['attandanceStatusError'] = false;
            oneAtt['changesSclFlagError'] = false;
            oneAtt['currentGradeLevelError'] = false;
            oneAtt['schoolNameError'] = false;
            oneAtt['effectiveDateError'] = false;
        })

        if (schoolAttendance && householdMembers && householdMembers.length > 0) {
            let personNotHaveAttData = householdMembers.filter(person => !schoolAttendance.some(attPerson => attPerson.agencyID === person.agencyID));
            this.setState({
                selectedAttendanceMembers: schoolAttendance,
                nonAttendanceMembers: personNotHaveAttData,
            });
        }
  }

  componentDidUpdate(prevProps){
    //On page Reload
    if(!_.isEmpty(this.props.consumer.consumerData) && _.isEmpty(prevProps.consumer.consumerData)){
      this.setInitialDataForModule(this.getPageConfig().activeSectionId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }

  redirectConsumerHome = () => {
    window.location.href = routeProps.CONSUMER_PATH
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  getIncomeSummaryTitle = (name, before, after) => {
    switch (language) {
      case types.ENGLISH:
      case types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case types.SPANISH:
      case types.PORTUGUESE:
      case types.VIETNAMESE:
        return {
          before: before,
          after: " " + name,
        };
    }
  };

  onSectionChange = (
    section,
    primaryButtonClick = TextProps.VALUE_FALSE,
    callback = null
  ) => {
    if (section.disabled === TextProps.VALUE_TRUE) return;
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
        });

        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  // Section Link click continue
  onSectionLinkClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    const activeId = this.state.activeSectionId;
    const  sections  = this.props.consumer.reportChangeSections;
    const nextSectionID = getNextSection(activeId, sections);
    if (nextSectionID) {
      const nextSection = this._getSection(nextSectionID);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
    }
  };

  setInitialDataForModule = (pageModule) =>{
    let clientData = JSON.parse(window.sessionStorage.getItem('loadConsumerUser'))
    switch (pageModule) {
      case "household":
        this.setHouseHoldInitialData(clientData);
        break;
      case "income":
        this.updateHouseHoldIncomeDetails();
        this.onIncomeSubSectionClean();
        this.setIncomeInitialData(clientData);
        break;
      case "shelter":
        this.setShelterInitialData(clientData);
        break;
      case "child-support":
        this.updateHouseHoldIncomeDetails();
        this.onChildSupportSubsectionRender();
        this.setChildSupportInitialData(clientData);
        break;
        case "Dependentcarecosts":
          this.updateHouseHoldIncomeDetails();
        break;
      case "medical":
        this.updateHouseHoldIncomeDetails();
        this.setMedicalInitialData(clientData);
        break;
      case "authrep":
        this.setAuthRepInitialData(clientData);
        break;
         case "attendance":
        this.setAttendanceInitialData(clientData);
        break;
      case "resources-summary":
        this.updateHouseHoldIncomeDetails();
        this.onResourcesSubSectionClean();
        this.setResourcesInitialData(clientData);
        break;
      default:
        break;
    }
  }

  setInitialStateForModule = (pageModule) =>{
    switch (pageModule) {
      case "household":
        this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).houseHoldSuccess,requestType:"HOUSHD"});
        break;
      case "resources-summary":
        this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).resourceSuccess,requestType:"RESRCE"});  
        break;  
      case "income":
        this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).incomeSuccess,requestType:"INCOME"});
        break;
      case "child-support":
        this.setState({
          editingSection: this.getPageConfig().activeSectionId,
          editingModuleSuccess: consumerConst(language).childSuccess,
          requestType:"CHSPEX"
        });
        break;
      case "Dependentcarecosts":
        this.setState({
          editingSection: this.getPageConfig().activeSectionId,
          editingModuleSuccess: consumerConst(language).childOrAdultSuccess,
          requestType:"CHADEX"
        });
        break;
        case "shelter":
          this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).shelterSuccess,requestType:"SHLTEX"});
          break;
        case "medical":
          this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).medicalSuccess,requestType:"MEDLEX"});
          break;
        case "authrep":
          this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:consumerConst(language).authRepSuccess,requestType:"AUTREP"});
          break;
 case "attendance":
          this.setState({ editingSection: this.getPageConfig().activeSectionId,editingModuleSuccess:ReportChangePageLabels(
            language,
            languageConstants.aboutMe
          ).attendanceSuccessMessage,requestType:"SCHATT"});
          break;
      default:
        break;
    }
  }


  disableSubmit = () => {
    this.setState({
      isInitiateSubmit: TextProps.VALUE_FALSE,
    });
  };

  gotoIncomeSummary = () => {
    this.prepareIncomeSummary();
    this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.incomeSummaryTemplate);
    });
  };

  filterQuestionsBySelection(activePageAnswers, nextPageQuestions) {
    let selectedQuestions = [];
    for (let answerValue of activePageAnswers) {
      for (let questionValue of nextPageQuestions) {
        if (answerValue === questionValue.valueToMatch) {
          questionValue.Answer = questionValue.Answer.length ? questionValue.Answer : "";
          questionValue.targetValue = questionValue.targetValue.length ? questionValue.targetValue : "";
          questionValue.value = questionValue.value && questionValue.value.length ? questionValue.value : "";
          selectedQuestions.push(questionValue);
        }
      }
    }
    return selectedQuestions;
  }

  gotoChildSupportSummary = (activeId) => {
    // this.prepareChildSupportSummary(this.props.consumer.reportChangeSections[0]);
    let childSupportSummaryTemplate = _.cloneDeep(this.props.consumer.reportChangeSections[0].subsections[
      this.props.consumer.reportChangeSections[0].subsections.length - 1
      ]);
    this.onSectionChange(childSupportSummaryTemplate);
  };

  incomefilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = this.props.consumer.reportChangeSections[0];
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.incomeSubSections[memberIndex] === undefined) {
      this.incomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.incomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    this.props.consumer.reportChangeSections[0].currentMember =
      this.incomeSubSections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
        ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
        ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      incomeFilteredQuestions = this.filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        let typeText =
        incomeCodeMapper(language)[filterQuestion.valueToMatch];
        filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
        filterQuestion[Props.LABEL] = typeText;
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer;
              filterQuestion.targetValue = oldQuestval.targetValue;
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.forEach(() => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.addRCIncomeSections(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareIncomeSummary(() => {
        this.props.addRCIncomeSections(incomeSection);
        this.gotoIncomeSummary();
      });
    }
  };


  shelterQuestionsBySelection = (memberIndex) => {
    let shelterSection = this.props.consumer.reportChangeSections[0];
    let incomePageAnswers = shelterSection.subsections[0].questions[0].Answer;
    let old = _.uniqBy([...this.props.consumer.reportChangeSections[0].subsections[0].subsections[0].questions,...shelterBaseQuestions],(q)=>q.name);
    let shelterFilteredQuestions = [];
    if (shelterSection.subsections[0].subsections !== undefined) {
        shelterFilteredQuestions = filterQuestionsBySelection(
          incomePageAnswers,
          old
        );
    }
    this.props.appendRCShelterFilteredQuestions(shelterFilteredQuestions);
  };

  authRepDataHandler = (authRepData) =>{
    this.setState({
      authRepData
    })
  }


  resetAuthRepError = (type,err) =>{
    if(type === "ebt"){
      err["ebtFrstNamError"]=false;
      err["ebtPhNumError"]= false;
      err["ebtLstNamError"]= false;
    }
    if(type === "snp"){
      err["snpPhNumError"]= false;
      err["snpFrstNamError"]=false;
      err["snpLstNamError"]= false;
    }

    err["signedNameError"]= false;
    err["agreeError"]= false;

    this.setState({
      authRepError:err
    })
  }

  attendanceDataHandler = (attendanceData,isChangeInAttendance) =>{
    this.setState({
      attendanceData,
      isChangeInAttendance
    })
  }

  setAttendanceData=()=>{
    const { consumer } = this.props;
        let consumerDetails = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
        let schoolAttendance = [];
        let householdMembers = [];
        if (consumer && Object.keys(consumer.consumerData).length > 0) {
            ({ schoolAttendance, householdMembers } = consumer.consumerData);
        } else if (consumerDetails && consumerDetails.schoolAttendance) {
            ({ schoolAttendance, householdMembers } = consumerDetails);
        }

        schoolAttendance && schoolAttendance.forEach((oneAtt)=>{
            oneAtt['attandanceStatusError'] = false;
            oneAtt['changesSclFlagError'] = false;
            oneAtt['currentGradeLevelError'] = false;
            oneAtt['schoolNameError'] = false;
            oneAtt['effectiveDateError'] = false;
        })

        if (schoolAttendance && householdMembers && householdMembers.length > 0) {
            let personNotHaveAttData = householdMembers.filter(person => !schoolAttendance.some(attPerson => attPerson.agencyID === person.agencyID));
            this.setState({
                selectedAttendanceMembers: schoolAttendance,
                nonAttendanceMembers: personNotHaveAttData,
            });
        }
  }


  generatePayloadData = (oldData,newData) =>{
    let userData = JSON.parse(window.sessionStorage.getItem("userInfo"));
    let payload = {
      "prevTxt": JSON.stringify(oldData),
      "chngTxt": JSON.stringify(newData),
      "orgLocIdK": "", // this value is taken from backend side
      "chngReqTypCdCodeK": this.state.requestType,
      "chngReqStatCdCodeK": "REQSTD",
      "clientIdK": userData.agencyID || ''
    }
    return payload;
  }
  checkForPrevData = (oldData,newData) =>{
    newData.map((newInnerData) => {
      oldData.filter((oldInnerData) => {
          if(this.matchPrevData(oldInnerData.payee,newInnerData.payee) && this.matchPrevData(oldInnerData.dependent,newInnerData.dependent)){
            newInnerData.previousProviderCareAmount = oldInnerData.providerCareAmount;
            newInnerData.previousProviderCareFrequencyCode = oldInnerData.providerCareFrequencyCode;
            newInnerData.dataChangeCode = "CHANGE";
          }
      })
      if(!newInnerData.dataChangeCode)
        newInnerData.dataChangeCode = "NEW";
    })
    return newData;
  }
  matchPrevData = (oldData,newData) => {
    if(oldData.firstName === newData.firstName && oldData.lastName === newData.lastName && oldData.dateOfBirth === newData.dateOfBirth){
      return true;
    }
   return false;
  }
  signInSubmitValidation = () =>{
    let isValid = true;
    let isSignatureValid = true;

    let signature = this.applicationSignRef.current.inputEl.value;
    let terms = this.termsRef.current;

    if(signature === "" || signature === null){
      isSignatureValid = false;
      this.setState({ signatureError: TextProps.VALUE_TRUE });
      this.signatureErrorMsg = ReportChangePageLabels(
        language,
        languageConstants.beforeYouStart
      ).signatureErrorMsg;
    }else {
      isSignatureValid = true;
      this.setState({ signatureError: TextProps.VALUE_FALSE });
      this.signatureErrorMsg = "";
    }

    if(terms.state._value.length<=0){
      isValid = false;
      this.setState({ agreeError: TextProps.VALUE_TRUE });
      this.agreeErrorMsg = ReportChangePageLabels(
        language,
        languageConstants.beforeYouStart
      ).agreeTermsErrorMsg;
    }else{
      isValid = true;
      this.setState({ agreeError: TextProps.VALUE_FALSE });
      this.agreeErrorMsg = ""
    }

    if(!isValid || !isSignatureValid){
      return false;
    }else{
      return true;
    }
    
  }

  hasChangesInEbt = (oldData, newData) => {
    const ebtKeys = Object.keys(oldData).filter(key => key.startsWith('ebt') && !key.includes('WebDataChangeCode'));
    return ebtKeys.some(key => oldData[key] !== newData[key] && !(oldData[key] === null && newData[key] === ""));
  }
  hasChangesInSnp = (oldData, newData) => {
    const snpKeys = Object.keys(oldData).filter(key => key.startsWith('snp') && !key.includes('WebDataChangeCode'));
    return snpKeys.some(key => oldData[key] !== newData[key] && !(oldData[key] === null && newData[key] === ""));
  }

  formatAuthRepPayload = (oldData, newData) => {
    const formatPhoneNumber = (number) => (number ? number.replace(/[-()\s]/g, '') : null);
    const convertYesNoToFlag = (value) => (value !== null ? (value === recertificationPageLabels(
      language,
      null,
      languageConstants.authRep
    ).yesnoOptions[0] ? "Y" : "N") : null);
    
    const ebtChanges = this.hasChangesInEbt(oldData.authorizedRepresentatives, newData);
    const snpChanges = this.hasChangesInSnp(oldData.authorizedRepresentatives, newData);

    if (ebtChanges && newData['ebtWebDataChangeCode'] !== "REMOVE") {
        newData['ebtWebDataChangeCode'] = oldData.authorizedRepresentatives && oldData.authorizedRepresentatives.ebtAssistorId !== null ? "CHANGE" : "NEW";
    }
    if (snpChanges && newData['snpWebDataChangeCode'] !== "REMOVE") {
        newData['snpWebDataChangeCode'] = oldData.authorizedRepresentatives && oldData.authorizedRepresentatives.snpAssistorId !== null ? "CHANGE" : "NEW";
    }

    return { 
        authorizedRepresentatives: {
            "ebtAssistorId": newData.ebtAssistorId,
            "snpAssistorId": newData.snpAssistorId,
            "ebtFrstNam": newData.ebtFrstNam,
            "ebtLstNam": newData.ebtLstNam,
            "ebtMdlNam": newData.ebtMdlNam,
            "ebtPhNum": formatPhoneNumber(newData.ebtPhNum),
            "ebtSuffix": newData.ebtSuffix,
            "ebtWebDataChangeCode": newData.ebtWebDataChangeCode,
            "snpFrstNam": newData.snpFrstNam,
            "snpLstNam": newData.snpLstNam,
            "snpMdlNam": newData.snpMdlNam,
            "snpPhNum": formatPhoneNumber(newData.snpPhNum),
            "snpSuffix": newData.snpSuffix,
            "snpWebDataChangeCode": newData.snpWebDataChangeCode,
            "signedName": newData.signedName,
        },
        "ebtAuthRepFlg": convertYesNoToFlag(newData.isAuthorizeEBT),
        "snapAuthRepFlg": convertYesNoToFlag(newData.isAuthorizeCert)
    };
}

  formatAttendancePayload = (newData) => {
    let filteredData = newData.map(item => {
      Object.keys(item).forEach(key => {
        if (key.endsWith("Error")) {
          delete item[key];
        }
      });
      return item;
    });
    return filteredData
  }


  //Primary Continue Click
  onPrimaryActionClick = (event,actionButtonType) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    let activeId = this.state.activeSectionId;
    const sections = this.props.consumer.reportChangeSections;
    let sectionsToValidate = _.cloneDeep(
      this.props.consumer.reportChangeSections
    );
    const incomingQuestions = this._getSection(activeId);
    let isFormValid = TextProps.VALUE_TRUE;
    let sectionId = "";
    let isDataUpdated = false;
    let dataSection = null;
    //Dal Reference

    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSection = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );
    }

    sectionId = getNextSection(activeId, sections);

    if(activeId === "authrep"){
      const [isValid ,errorObj] = AuthRepValidator(this.state.authRepData,this.state.authRepError, this.handleebtPhoneValid, this.handlesnpPhoneValid, this.handleSignedNameValid);
      if(!isValid){
        this.setState({
          authRepError:{
            ...errorObj
          }
        })
        return 
      }
    }

    if(activeId === "attendance"){
      //uncomment if need validation

      const [isValid ,errorObj] = AttendanceValidator(this.state.attendanceData,this.state.attendanceError);
      if(!isValid){
        this.setState({
          attendanceData:errorObj
        })
        return 
      }
      this.responseData.schoolAttendanceData = checkForAttendanceChange(this.state.attendanceData,this.responseData.oldSchoolAttendanceData);
    }

    // validation block
    if (activeId === "household-member") {
      const nextSection = this._getSection('household');
      const [isValid, erroredSections] = toValidateHouseholdSection(
        dataSection,
        activeId,
        sectionsToValidate
      );
      isFormValid = isValid;

      if (!isValid) {
        this.setState({
          activeSectionId: activeId,
          renderedSections: [dataSection],
        });
        this.props.setError(sections);
        return;
      }else{
        this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
      }
    }

    if(activeId === "sign-and-submit"){
      let isValid = this.signInSubmitValidation();
      if(!isValid){
        return;
      }
    }

    if (activeId !== "" && activeId !== undefined && activeId !== null) {
      var activeIdSplit = activeId.split("-");
      var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
      var activeIdSubString = activeIdSplit.join("-");
    }

    // section based logics
    switch (activeId) {
      case "household-member":
        this.props.deleteHouseholdIntoSections();
        this.updateHouseHoldMemberSection(activeId, dataSection);
        sectionId = "household";
        break;
      case "household":
        this.checkForDataChange(activeId);
        sectionId = null;
        break;
      case "resources-summary":
      sectionId = null;
      break;  
      case "income":
        sectionId = null;
        break;
      case "shelter":
        sectionId = null;
        break;
      case "utility-expenses-section":
        this.prepareShelterSummary();
        break;
      case "child-support":
        sectionId = null;
        break;
      case "Dependentcarecosts":
        if(this.props.consumer.reportChangeSections[0].questions[0].options.length > 1 || this.props.consumer.reportChangeSections[0].questions[0].Answer.includes("None")) {
          this.renderDependentsCareCosts(this.props.consumer.reportChangeSections);
          sectionId = getNextSection(activeId, this.props.consumer.reportChangeSections);
        }
        else {
          return
        }
        break;
      case "medical-expenses":
        sectionId = null;
        break;
      default:
        break;
    }
 
    //repeated questions logic and routing
    switch (activeIdSubString) {
       case "combined-resources":
         this.prepareResourcesSummary();
         this.resourcesfilterQuestionsBySelection(activeSplitMemberIndex);
         break;
      case "combined-resources-subsection":
        this.prepareResourcesSummary();
        this.resourcesfilterQuestionsBySelection(activeSplitMemberIndex);
        this.checkForDataChange(activeId);
        sectionId = "resources-summary";
        break;
      case "combined-income":
        this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
        break;
      case "combined-incomesubsection":
        this.prepareIncomeSummary();
        this.checkForDataChange(activeId)
        sectionId = "income-summary";
        break;
      case "shelter-expenses":
        let noShelterOptions = iDontPayShelter;
        if(dataSection.questions[0].Answer === undefined || !dataSection.questions[0].Answer[0] || dataSection.questions[0].Answer === "" || noShelterOptions.includes(dataSection.questions[0].Answer[0]) ){
          // this.props.consumer.reportChangeSections[0].subsections[0].subsections.splice(0, 1);
          const nextSection = this.props.consumer.reportChangeSections[0].subsections[0].subsections[this.props.consumer.reportChangeSections[0].subsections[0].subsections.length-1];
          setTimeout(()=>{
            this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
          },50)
          return
        }else{
          this.shelterQuestionsBySelection();
        }
        break;
      case "child-support-amount":
        sectionId = null;
        this.prepareChildSupportSummary(this.props.consumer.reportChangeSections[0]);
        this.processChildSupportSectionData();
        this.checkForDataChange(activeId)
        this.gotoChildSupportSummary(activeId);
        return;
     case "kids-and-adults":
      let reportChangeSections = _.cloneDeep(this.props.consumer.reportChangeSections);
      reportChangeSections[0].subsections[reportChangeSections[0].subsections.indexOf(reportChangeSections[0].subsections.filter(sub => sub.id === this.state.activeSectionId)[0])] = dataSection;
      this.addAddressesForDepCare(reportChangeSections,activeSplitMemberIndex);
      sectionId = getNextSection(activeId, reportChangeSections);
     break;
      case "medical-expenses-type":
        if(dataSection.questions[0].Answer === undefined || !dataSection.questions[0].Answer[0] || dataSection.questions[0].Answer === ""){
          const nextSection = this.props.consumer.reportChangeSections[0].subsections[0].subsections[this.props.consumer.reportChangeSections[0].subsections[0].subsections.length-1];
          setTimeout(()=>{
            this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
          },50)
          return
        }else{
        this.renderMedicalExpenseFilter(activeId);
        }


        return;
      case "medical-expenses-filter":
        this.prepareMedicalExpensesSummary();
        break;
      default:
        break;
    }

    if(activeId.includes("combined-incomesubsection-")){
      this.checkForDataChange(activeId);
      const nextSection = this.props.consumer.reportChangeSections[0].subsections[this.props.consumer.reportChangeSections[0].subsections.length - 1]
      this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
      return
    }

    if(activeId.includes("combined-resources-subsection-")){
      this.checkForDataChange(activeId);
      const nextSection = this.props.consumer.reportChangeSections[0].subsections[this.props.consumer.reportChangeSections[0].subsections.length - 1]
      this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
      return
    }

    // Section Change
    if (sectionId && isFormValid) {
      if(activeId.includes("kids-adult-multiple-address" || this.state.activeSectionId.includes("kids-adult-multiple-address"))){  
        let reportChangeSections = _.cloneDeep(this.props.consumer.reportChangeSections);
        reportChangeSections[0].subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections[reportChangeSections[0].subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections.indexOf(reportChangeSections[0].subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections.filter(sub => sub.id === this.state.activeSectionId)[0])] = dataSection;
        this.props.updateReportChangeSections(reportChangeSections);
      }
      const nextSection = this._getSection(sectionId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this._renderNextSection(activeId);
          this.checkForDataChange(activeId);
        }
      );
    } else {
      //Submission
      let oldData;
      let newData;
      let submissionData={};
      // let finalPayload={};
      if(activeId === "household" || activeId === "sign-and-submit"){
        submissionData = processHouseholdData(this.props.consumer.householdMembers?this.props.consumer.householdMembers :this.props.consumer.consumerData.householdMembers);
         oldData = {houseHoldMembers:this.responseData.oldHouseholdData}
         newData = {houseHoldMembers:submissionData}
         if(this.state.signature !==""){
          newData['signature'] = this.state.signature
         }
        this.finalPayload =   this.generatePayloadData(oldData,newData);
      } else if(activeId === "resources-summary"){
        submissionData = this.responseData.resourceAnswer? this.responseData.resourceAnswer: this.responseData.oldResourcesData;
        oldData = {resources:this.responseData.oldResourcesData}
        newData = {resources:submissionData}

        // datasheet changes
        const prevResourceMap = new Map();
        oldData.resources.forEach(resource => {
            resource.resourceDetails.forEach(detail => {
                prevResourceMap.set(detail.setID, detail);
            });
        });

        newData.resources.forEach(resource => {
          resource.resourceDetails.forEach(detail => {
              if (detail.templateType === "ResourcesTemplateOne") {
                  detail.additionalInfo = detail.additionalInformation;
                  delete detail.additionalInformation;
              } else {
                  detail.bankBrokerageName = detail.bankName;
                  delete detail.bankName;
                  
                  if (detail.templateType === "ResourcesTemplateTwo") {
                      detail.accountNumber = detail.accountnumber;
                      detail.isJointAccount = detail.jointAccount;
                      detail.jointAcntHolderName = detail.jointAccountHolder;
                      
                      delete detail.accountnumber;
                      delete detail.jointAccount;
                      delete detail.jointAccountHolder;
                  }
              }
      
              //set the previous values
              if (prevResourceMap && detail.datachangeCode === 'CHANGE') {
                  const prevDetail = prevResourceMap.get(detail.setID);
                  if (prevDetail) {
                      if (detail.templateType === "ResourcesTemplateOne") {
                          detail.previousAdditionalInfo = prevDetail.additionalInfo;
                      } else {
                          detail.previousBankBrokerageName = prevDetail.bankBrokerageName;
                          
                          if (detail.templateType === "ResourcesTemplateTwo") {
                              detail.previousAccountNumber = prevDetail.accountNumber;
                              detail.previousIsJointAccount = prevDetail.isJointAccount;
                              detail.previousJointAcntHolderName = prevDetail.jointAcntHolderName;
                          }
                      }
                      detail.previousAmount = prevDetail.amount;
                  }
              } else if(prevResourceMap && detail.datachangeCode === "REMOVE"){
                const prevDetail = prevResourceMap.get(detail.setID);
                if (prevDetail) {
                  detail.amount = prevDetail.amount;
                  if (detail.templateType === "ResourcesTemplateOne") {
                    detail.additionalInfo = prevDetail.additionalInfo;
                  } else {
                    detail.bankBrokerageName = prevDetail.bankBrokerageName;
                    
                    if (detail.templateType === "ResourcesTemplateTwo") {
                        detail.accountNumber = prevDetail.accountNumber;
                        detail.isJointAccount = prevDetail.isJointAccount;
                        detail.jointAcntHolderName = prevDetail.jointAcntHolderName;
                    }
                }
               }
              }
          });
      });
        this.finalPayload =   this.generatePayloadData(oldData,newData)
      }else if(activeId === "income-summary" || activeId === "income"){
        submissionData = this.responseData.incomeAnswer? this.responseData.incomeAnswer: this.responseData.oldIncomeData;
        oldData = {incomes:this.responseData.oldIncomeData}
        newData = {incomes:submissionData}

        // DataSheet Changes
        const prevIncomeMap = new Map();
        oldData.incomes.forEach(income => {
        prevIncomeMap.set(income.setID, income);
        });

        newData.incomes.forEach(income => {
        if (income.dataChangeCode === 'CHANGE') {
        const prevIncome = prevIncomeMap.get(income.setID);
        if (prevIncome) {
          income.previousFrequencyCode = prevIncome.frequencyCode;
          income.previousAmounts = prevIncome.amounts;
        }
      }
    });

        this.finalPayload =   this.generatePayloadData(oldData,newData)
      }else if(activeId === "shelter" || activeId === "shelter-utility-summary"){
        delete this.responseData.oldUtilityData.dataChangeCode;
        oldData = {
          shelterExpense:this.responseData.oldShelterData.map(obj=>{
            const oldShelter = { ...obj };
            delete oldShelter.dataChangeCode;
            return oldShelter;
          }),
          utilityExpense:this.responseData.oldUtilityData
        }
        const oldUtilObj = this.responseData.oldUtilityData;
        const newUtilObj = this.responseData.utilityAnswers;

        const finalUtilObj = {
          ...newUtilObj,
          acDataChangeCode: this.generateDataChangeCode(oldUtilObj.acFlag, newUtilObj.acFlag),
          electricOrGasDataChangeCode: this.generateDataChangeCode(oldUtilObj.electricOrGasFlag, newUtilObj.electricOrGasFlag),
          phoneDataChangeCode: this.generateDataChangeCode(oldUtilObj.phoneFlag, newUtilObj.phoneFlag),
          waterSewageDataChangeCode: this.generateDataChangeCode(oldUtilObj.payWaterSewage, newUtilObj.payWaterSewage),
          garbageDataChangeCode: this.generateDataChangeCode(oldUtilObj.payGarbage, newUtilObj.payGarbage),
          heatDataChangeCode: this.generateDataChangeCode(oldUtilObj.heatFlag, newUtilObj.heatFlag),
          noUtilityDataChangeCode: this.generateDataChangeCode(oldUtilObj.noUtilityFlag, newUtilObj.noUtilityFlag),
        };
        delete finalUtilObj["dataChangeCode"];

        
        newData = {
            shelterExpense:this.responseData.shelterAnswers? this.responseData.shelterAnswers:this.responseData.oldShelterData,
          utilityExpense:this.responseData.utilityAnswers?finalUtilObj:this.responseData.oldUtilityData
        }

         // DataSheet Changes
         const prevShelterMap = new Map();
         oldData.shelterExpense.forEach(exp => {
          prevShelterMap.set(exp.typeCode, exp);
         });

         newData.shelterExpense.forEach(exp => {
         if (exp.dataChangeCode === 'CHANGE') {
         const prevShelExp = prevShelterMap.get(exp.typeCode);
         if (prevShelExp) {
          exp.previousFrequencyCode = prevShelExp.frequencyCode;
          exp.previousAmount = prevShelExp.amount;
         }
       }
     });

        this.finalPayload =   this.generatePayloadData(oldData,newData);
      }else if(activeId === "childsupport-expenses" || activeId === "child-support"){
        oldData = {childSupportExpense:this.responseData.oldChildSupportData};
        newData = {childSupportExpense:this.responseData.childSupportExpense?this.responseData.childSupportExpense:this.responseData.oldChildSupportData};



         // DataSheet Changes
         const prevChildMap = new Map();
         oldData.childSupportExpense.forEach(exp => {
          prevChildMap.set(exp.setID, exp);
         });

         newData.childSupportExpense.forEach(exp => {
         if (exp.dataChangeCode === 'CHANGE') {
         const prevShelExp = prevChildMap.get(exp.setID);
         if (prevShelExp) {
          exp.previousFrequencyCode = prevShelExp.frequencyCode;
          exp.previousAmount = prevShelExp.amount;
         }
       }
     });



        this.finalPayload =   this.generatePayloadData(oldData,newData);
      }else if(activeId === "medical-expenses" || activeId === "medical"){
        oldData = {medicalExpenses:this.responseData.oldMedicalExpenseData};
        newData = {medicalExpenses:this.responseData.medicalExpenses?this.responseData.medicalExpenses:this.responseData.oldMedicalExpenseData};

         // DataSheet Changes
         const prevMedicalExpMap = new Map();
         oldData.medicalExpenses.forEach(med => {
          prevMedicalExpMap.set(med.agencyID, med);
         });
         newData.medicalExpenses.forEach(obj => {
          Object.keys(obj).forEach(key => {
              if (key.includes("DataChangeCode")) {
                  const prefix = key.split("DataChangeCode")[0]; // Get the prefix before "DataChangeCode"
                  const value = obj[key]; // Get the value of the key
                  if (value === "CHANGE") {
                    const prevMedicalExpData = prevMedicalExpMap.get(obj.agencyID);
                    if(prevMedicalExpData){
                      const prevMedExpAmountKey = "previous" +_.upperFirst(_.camelCase(prefix)) + "Amt";
                      const prevMedExpFreqCodeKey = "previous" +_.upperFirst(_.camelCase(prefix))  + "FreqCd";
                      const medicalExpAmountKey = prefix +"Amt";
                      const medicalExpFreqKey = prefix + "FreqCd";

                      if (!obj.hasOwnProperty(prevMedExpAmountKey)) {
                        obj[prevMedExpAmountKey] = prevMedicalExpData[medicalExpAmountKey]; 
                      }

                      if (!obj.hasOwnProperty(prevMedExpFreqCodeKey)) {
                        obj[prevMedExpFreqCodeKey] = prevMedicalExpData[medicalExpFreqKey];
                      }

                    }
                  }
              }
          });
        });
        this.finalPayload =   this.generatePayloadData(oldData,newData);
      }
      else if(activeId.includes('kids') || activeId === "Dependentcarecosts"){
        let reportChangeSections = _.cloneDeep(this.props.consumer.reportChangeSections[0]);
        if(activeId.includes("kids-adult-multiple-address")){
          reportChangeSections.subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections[reportChangeSections.subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections.indexOf(reportChangeSections.subsections.filter(sub => sub.id === dataSection.immParentId)[0].subsections.filter(sub => sub.id === this.state.activeSectionId)[0])] = dataSection;
        }
        let generateDepCareData = this.generateDependentCareData(reportChangeSections);
        let newDataWithPrevDataMapped = this.checkForPrevData(this.props.consumer.consumerData.dependantCare,generateDepCareData);
        oldData = {childDependentCareExpense:this.props.consumer.consumerData.dependantCare};
        newData = {childDependentCareExpense:newDataWithPrevDataMapped};
        this.finalPayload =   this.generatePayloadData(oldData,newData);
      }else if(activeId === "authrep"){
        oldData = this.responseData.oldAuthorizedRepresentativesData;
        newData = this.state.authRepData ? this.formatAuthRepPayload(oldData,this.state.authRepData) : this.responseData.oldAuthorizedRepresentativesData;

          // DataSheet Changes
        if(newData.authorizedRepresentatives.snpWebDataChangeCode === "CHANGE"){
          newData.authorizedRepresentatives.previousSnpFrstNam = oldData.authorizedRepresentatives.snpFrstNam
          newData.authorizedRepresentatives.previousSnpLstNam = oldData.authorizedRepresentatives.snpLstNam
          newData.authorizedRepresentatives.previousSnpMdlNam =  oldData.authorizedRepresentatives.snpMdlNam
          newData.authorizedRepresentatives.previousSnpPhNum =  oldData.authorizedRepresentatives.snpPhNum
          newData.authorizedRepresentatives.previousSnpSuffix =  oldData.authorizedRepresentatives.snpSuffix
        }else if(newData.authorizedRepresentatives.ebtWebDataChangeCode === "CHANGE"){
          newData.authorizedRepresentatives.previousEbtFrstNam = oldData.authorizedRepresentatives.ebtFrstNam
          newData.authorizedRepresentatives.previousEbtLstNam = oldData.authorizedRepresentatives.ebtLstNam
          newData.authorizedRepresentatives.previousEbtMdlNam = oldData.authorizedRepresentatives.ebtMdlNam
          newData.authorizedRepresentatives.previousEbtPhNum = oldData.authorizedRepresentatives.ebtPhNum
          newData.authorizedRepresentatives.previousEbtSuffix = oldData.authorizedRepresentatives.ebtSuffix
        }

        this.finalPayload = this.generatePayloadData(oldData,newData);
      }else if(activeId === "attendance"){
        oldData = {"schoolAttendance":this.responseData.oldSchoolAttendanceData};
        newData = {"schoolAttendance":this.formatAttendancePayload(this.responseData.schoolAttendanceData)};


        // DataSheet Changes
        const prevAttMap = new Map();
        oldData.schoolAttendance.forEach(att => {
          prevAttMap.set(att.setID, att);
        });

        newData.schoolAttendance.forEach(att => {
        if (att.dataChangeCode === 'CHANGE') {
        const prevAttData = prevAttMap.get(att.setID);
        if (prevAttData) {
          att.previousEffectiveDate = prevAttData.effectiveDate;
          att.previousAttandanceStatus = prevAttData.attandanceStatus;
          att.previousGradeLevel = prevAttData.currentGradeLevel;
          att.previousSchoolName = prevAttData.schoolName;
        }
      }
      });

        this.finalPayload = this.generatePayloadData(oldData,newData);
      }
      isDataUpdated = this.checkIsSectionUpdated(this.responseData,activeId,"SUBMIT");
      let onChangeAnswerList = this.state.AnswerObject;
      let onChangeDependentCare = onChangeAnswerList.Dependentcarecosts;
      let changeDependent = onChangeDependentCare && onChangeDependentCare.caregivers ? onChangeDependentCare.caregivers[0] : "";
      
      if (!isDataUpdated) {
          this.showWarningModal(false);
          return false;
        // }
      } 
      else {
        if(this.state.activeSectionId === "Dependentcarecosts" && this.props.consumer.reportChangeSections[0].questions[0].Answer.length > 0 && (changeDependent === "None" || changeDependent === "")) {
          this.showWarningModal(true);
          return;
        }
        else if (this.state.activeSectionId !== "Dependentcarecosts") {
          this.showSuccessModal();
        }
      //submit data to backend
      if(activeId === "resources-summary"){
        sessionStorage.setItem('module', '')
      }
        this.props.postData(this.finalPayload);
      }
    }
  };
  getMemberDataObjectByNameNew = (fn, ln, listToCheck) => {
    let returnMember = null;
    listToCheck.map((member) => {
      if (fn === member.firstName && ln === member.lastName) {
        returnMember = member;
      }
    });
    return returnMember;
  };

  generateDependentCareData = (dependentObj) => {
    let dependentCareExpenses;
    if (
      dependentObj &&
      dependentObj.subsections &&
      dependentObj.subsections.length > 0
    ) {
      let newDepententCostArray = [];
      let dependentSection = _.cloneDeep(
        dependentObj
      );

      if (
        dependentSection.subsections !== undefined &&
        dependentSection.subsections.length > 0
      ) {
        //payee iterator
        if (
          dependentSection.questions[0].value !== undefined &&
          dependentSection.questions[0].value !== ""
        ) {
          let payees = dependentSection.dependentCare;
          for (let index = 0; index < payees.length; index++) {
            let payee = payees[index].split("-")[0];
            let subSectionData = dependentSection.subsections[index];
            let memberData = payee.split("'")[0];
            memberData = memberData.split(" ");
            let name = null;
            for (let currentVal of memberData) {
              if (currentVal.trim() === "") continue;
              if (name != null) name += " " + currentVal;
              else name = currentVal;
            }
            // let memberObj = this.getMemberDataObjectByName(
            //   name,
            //   this.props.recert.householdMembers
            //     ? this.props.recert.householdMembers.membersList
            //     : []
            // );
            let memberObj = this.getMemberDataObjectByNameNew(
              memberData[0],
              memberData[1],
              this.props.consumer.consumerData.householdMembers
            );
            let indexOfPayee =
              dependentSection.questions[0].options.indexOf(payee);
            if (
              dependentSection.questions[0].conditionals[indexOfPayee]
                .conditionalQuestions[0].Answer
            ) {
              let dependents =
                dependentSection.questions[0].conditionals[indexOfPayee]
                  .conditionalQuestions[0].Answer;
              dependents.map(dependent=>{
              let dependentMember = payee + "-" + dependent;
              if(dependentMember === subSectionData.dependentMember) {  
              // for (let dependent of dependents) {
                let dependentName = null;
                let dependentArray = dependent.split(" ");
                for (let currentVal of dependentArray) {
                  if (currentVal.trim() === "") continue;
                  if (dependentName != null) dependentName += " " + currentVal;
                  else dependentName = currentVal;
                }
                let dependentObj = this.getMemberDataObjectByNameNew(
                  dependentArray[0],
                  dependentArray[1],
                  this.props.consumer.consumerData.householdMembers
                );

                let transportDetail = [];
                if (
                  subSectionData.questions[2].Answer ===
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.aboutMe
                  ).optionYesNo[0]
                ) {
                  for (
                    let k = 0;
                    k <
                    parseInt(
                      subSectionData.questions[2].conditionals[0]
                        .conditionalQuestions[0].Answer
                    );
                    k++
                  ) {
                    let transportData = subSectionData.subsections[k];
                    if (transportData.questions[0].Answer) {
                      let transportObj = {
                        address: {
                          typeCode: "CARE",
                          line1: transportData.questions[0].Answer.addressLine1,
                          line2: transportData.questions[0].Answer.addressLine2,
                          county: transportData.questions[0].Answer.county,
                          city: transportData.questions[0].Answer.city,
                          state: transportData.questions[0].Answer.state,
                          zip: transportData.questions[0].Answer.zipCode,
                        },
                        driveTripCount:
                          transportData.questions[1].Answer.amount,
                        driveFrequencyCode:
                          transportData.questions[1].Answer.unit !== undefined
                            ? getFrequencyCode(language)[
                                transportData.questions[1].Answer.unit
                              ]
                            : "",
                      };
                      transportDetail.push(transportObj);
                    }
                  }
                }

                let newDependentCost = {
                  payee: {
                    agencyID: memberObj.agencyID,
                    firstName: memberObj.firstName,
                    lastName: memberObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      memberObj.dateOfBirth
                    ),
                  },
                  dependent: {
                    agencyID: dependentObj.agencyID,
                    firstName: dependentObj.firstName,
                    lastName: dependentObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      dependentObj.dateOfBirth
                    ),
                  },
                  providerCareAmount: subSectionData.questions[0].Answer
                    ? parseInt(subSectionData.questions[0].Answer.amount)
                    : "",
                  providerCareFrequencyCode:
                    subSectionData.questions[0].Answer &&
                    subSectionData.questions[0].Answer.unit !== undefined
                      ? getFrequencyCode(language)[
                          subSectionData.questions[0].Answer.unit
                        ]
                      : "",
                  transportationPayFlag:
                    subSectionData.questions[1].Answer ===
                    recertificationPageLabels(
                      language,
                      null,
                      languageConstants.aboutMe
                    ).optionYesNo[0]
                      ? TextProps.VALUE_TRUE
                      : TextProps.VALUE_FALSE,
                  transportationAmount:
                    subSectionData.questions[1].conditionals[0]
                      .conditionalQuestions[0].Answer &&
                    subSectionData.questions[1].conditionals[0]
                      .conditionalQuestions[0].Answer.amount !== undefined
                      ? subSectionData.questions[1].conditionals[0]
                          .conditionalQuestions[0].Answer.amount
                      : "",
                  transportationFrequencyCode:
                    subSectionData.questions[1].conditionals[0]
                      .conditionalQuestions[0].Answer &&
                    subSectionData.questions[1].conditionals[0]
                      .conditionalQuestions[0].Answer.unit !== undefined
                      ? getFrequencyCode(language)[
                          subSectionData.questions[1].conditionals[0]
                            .conditionalQuestions[0].Answer.unit
                        ]
                      : "",
                  drivetoProviderFlag:
                    subSectionData.questions[2].Answer ===
                    recertificationPageLabels(
                      language,
                      null,
                      languageConstants.aboutMe
                    ).optionYesNo[0]
                      ? TextProps.VALUE_TRUE
                      : TextProps.VALUE_FALSE,
                  drivetoProviderCount: subSectionData.questions[2].conditionals[0]
                  .conditionalQuestions[0].Answer ?parseInt(
                    subSectionData.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer
                  ) : null,
                  transportDetails: transportDetail,
                };
                newDepententCostArray.push(newDependentCost);
              // }
              }
            });
            }
          }
        }
      }

      dependentCareExpenses = newDepententCostArray;
    }else if (
      dependentObj &&
      dependentObj.questions[0].Answer &&
      dependentObj.questions[0].Answer.length > 0 &&
      dependentObj.questions[0].Answer[0] ===
        recertificationPageLabels(
          language,
          null,
          languageConstants.incomeExpensesRecert
        ).questionClearOption
    ) {
      dependentCareExpenses = [{}];
    } else {
      dependentCareExpenses = [];
    }
    return dependentCareExpenses;
  }
  convertDOBForDataPost = (dobFrom) => {
    let retrunValue = null;
    if (dobFrom.indexOf(".") !== -1) {
      let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
      let dobArray = dob.split(".");

      let month = ControlConstants.MONTHSHORT.indexOf(dobArray[1]) + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = parseInt(dobArray[0]);
      if (date < 10) {
        date = "0" + date;
      }
      retrunValue = dobArray[2] + "-" + month + "-" + date;
    } else {
      retrunValue = dobFrom;
    }

    return retrunValue;
  };
  _renderNextSection = (activeId) => {
    let nextSectionId = getNextSection(activeId, this.props.consumer.reportChangeSections);

    //Function to Execute before Next page renders
    if(nextSectionId === 'medical-expenses'){
      this.prepareMedicalExpensesSummary();
    }

    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
        }
      );
      // this._updateCurrentSection(activeId);
      // updateCurrentSectionCompletionStatus(activeId,this.state.sections, true)
    }
  };

  onSecondaryActionClick = (event) => {
    event.preventDefault(); 
    const { activeSectionId } = this.state;
    const { consumer: { reportChangeSections } } = this.props;
    let sectionId = activeSectionId || '';
    let enableGoBack = true;
    if (activeSectionId) {
      const activeIdSplit = activeSectionId.split("-");
      const activeIdSubString = activeIdSplit.slice(0, -1).join("-");
  
    switch (activeIdSubString) {
      case "combined-incomesubsection":
      case "combined-income":
          sectionId = activeSectionId;
        break;
      case "combined-resources-subsection":
      case "combined-resources":  
          sectionId = activeSectionId;
          break;  
      case "child-support-amount":
          this.onSectionChange(this._getSection('childsupport-expenses'));
       return;
      default:
        break;
    }
    }
  
    if (activeSectionId && activeSectionId.includes("combined-income-")) {
      this.checkForDataChange(activeSectionId);
      const { subsections } = reportChangeSections[0];
      const nextSection = subsections[subsections.length - 1];
      this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
      return;
    }
    
    if (activeSectionId && activeSectionId.includes("combined-resources-") && !activeSectionId.includes("combined-resources-subsection")) {
      this.checkForDataChange(activeSectionId);
      const { subsections } = reportChangeSections[0];
      const nextSection =  subsections[subsections.length - 1];
      this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
      return;
    }
    
    let previousSectionId = getPreviousSection(sectionId, reportChangeSections);
    let isSummaryPage = this.checkForSummary(activeSectionId);

    if(isSummaryPage){
      previousSectionId = null;
      enableGoBack = this.checkIsSectionUpdated(this.responseData, activeSectionId,"GOBACK");
    }

    if (previousSectionId) {
      const previousSection = this._getSection(previousSectionId);
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    } else if(enableGoBack){
      this.redirectConsumerHome();
      const currentSection = sessionStorage.getItem('module');
      if(currentSection && currentSection === "resources-summary"){
       sessionStorage.setItem('module', '');
      }
    }
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  getPrimaryActionLabel = (sectionId) => {
    var returnText = ReportChangePageLabels(
      language,
      languageConstants.beforeYouStart,
      null,
      null,
      null
    ).submitLabel;
      if(sectionId && (sectionId === "Dependentcarecosts" ||  sectionId.includes('kids-'))){
        let nextSectionId = getNextSection(sectionId, this.props.consumer.reportChangeSections);
        if(nextSectionId === undefined && this.props.consumer.reportChangeSections[0].questions[0].Answer.length){
          return returnText;
        }
      }
    switch (sectionId) {
      case "household":
      case "sign-and-submit":
        return returnText;
      case "income":
      case "income-summary":
        return returnText;
      case "shelter":
      case "shelter-utility-summary":
        return returnText;
      case "child-support":
      case "childsupport-expenses":
        return returnText;
      case "medical":
      case "medical-expenses":
        return returnText;
      case "authrep":
      return returnText;
       case "resources-summary":
        return returnText;
       case "attendance":
        return returnText;
      default:
        return ReportChangePageLabels(
          language,
          languageConstants.beforeYouStart,
          null,
          null,
          null
        ).continueLabel;
    }
  };

  _bindAnswerObject = (answer) => {
    if(this.state.activeSectionId.includes('child-support-amount')){
      let filtered;
      const childArr = _.cloneDeep(this.props.consumer.childExpScreen.tempChildExpArray);
      this.props.consumer.reportChangeSections[0].subsections.forEach((sec)=>{
        if(sec.id === this.state.activeSectionId){
          if(sec && sec.clientId){
            filtered = _.findIndex(childArr, {
              firstName: sec.clientId.split(" ")[0],
              lastName: sec.clientId.split(" ")[1],
              dateOfBirth: sec.clientId.split(" ")[2],
            });
          }else{
            filtered = _.findIndex(childArr, {
              firstName: sec.title.split(" ")[0],
              lastName: sec.title.split(" ")[1],
              dateOfBirth: " "+sec.title.split(" ")[2],
            });
          }
          if (answer[this.state.activeSectionId]) {
            if (childArr[filtered]) {
            if (answer[this.state.activeSectionId]['child-support-cost-1'].amount) {
              childArr[filtered].amount = Number(answer[this.state.activeSectionId]['child-support-cost-1'].amount);
              childArr[filtered].originalAmount = Number(answer[this.state.activeSectionId]['child-support-cost-1'].amount);
            }
            if (answer[this.state.activeSectionId]['child-support-cost-1'].unit) {
              childArr[filtered].frequency = answer[this.state.activeSectionId]['child-support-cost-1'].unit;
              childArr[filtered].originalFrequency = answer[this.state.activeSectionId]['child-support-cost-1'].unit;
            }
              if (childArr[filtered].id !== 0) {
              childArr[filtered].dataChangeCode = "changed";
            } else {
              childArr[filtered].dataChangeCode = "new";
            }
          }
        }
        }
      })
      let newChildArr = childArr.filter((oneChild) => {
        return oneChild.id >= 0 
      })
      this.props.appendChildSupportAnswer(newChildArr)
    }
    if(this.state.activeSectionId.includes('kids-and-adults') && answer[this.state.activeSectionId]){
      //driving-address-1
      if (answer[this.state.activeSectionId]['driving-address-1']) {
        if (this.state.activeSectionId) {
          var activeIdSplit = this.state.activeSectionId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
        }
        let reportChangeSections = _.cloneDeep(this.props.consumer.reportChangeSections);
        let dataSection = bindAnswers(
          reportChangeSections[0].subsections.filter(sub => sub.id === this.state.activeSectionId)[0],
          this.state.activeSectionId,
          answer
        );
        reportChangeSections[0].subsections[reportChangeSections[0].subsections.indexOf(reportChangeSections[0].subsections.filter(sub => sub.id === this.state.activeSectionId)[0])] = dataSection;
        this.addAddressesForDepCare(reportChangeSections,activeSplitMemberIndex);
          // this.setState({
          //   renderedSections: this.props.consumer.reportChangeSections[0].subsections.filter(sub => sub.id === this.state.activeSectionId),
          //   sections:this.props.consumer.reportChangeSections
          // });
      }
    }
    this.setState({
      AnswerObject: answer,
      dependentCareDataUpdated: TextProps.VALUE_TRUE
    });
  };

  updateHouseHoldMemberSection = (activeId, dataSections) => {
    let membersList = this.props.consumer.householdMembers?this.props.consumer.householdMembers:this.props.consumer.consumerData.householdMembers;
    let dateOfB = new Date(dataSections.questions[5].Answer);
    let dateNumber =
      dateOfB.getDate() < 9 ? "0" + dateOfB.getDate() : dateOfB.getDate();

    let dobMonth = dateOfB.getMonth() < 10
        ? "0" + (dateOfB.getMonth() + 1)
        : dateOfB.getMonth();
    dateOfB = dobMonth + "-" +  dateNumber  + "-" + dateOfB.getFullYear();
    let newMember = {
      agencyID: 0, 
      firstName: dataSections.questions[0].Answer,
      lastName: dataSections.questions[2].Answer,
      dateOfBirth: dateOfB,
      granteeFlag: TextProps.VALUE_FALSE,
      allAnswers: dataSections.questions,
    };
    membersList.push(newMember);
    this.props.setHouseholdMembersList(membersList);
    let ansObjCopy = this.state.AnswerObject;
    ansObjCopy[activeId].firstName = "";
    ansObjCopy[activeId].lastName = "";
    ansObjCopy[activeId].dateOfBirth = "";
    ansObjCopy[activeId].ssn = "";
    ansObjCopy[activeId].relationship = "";
    ansObjCopy[activeId]["biological-sex"] = "";
    ansObjCopy[activeId]["is-citizen"] = "";
    ansObjCopy[activeId]["buy-food"] = "";
    ansObjCopy[activeId]["person-income"] = "";
    this.setState({
      AnswerObject: ansObjCopy,
    });
  };


  updateHouseholdToState = (members) => {
    this.setState({
      householdMemberSectionData: members,
    });
  };

  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          <ReportChangeSection
            key={section.id}
            section={section}
            activeSectionId={this.state.activeSectionId}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.redirectToSignAndSubmit}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </ReportChangeSection>
        </div>
      </CSSTransition>
    );
  };

  updateHouseHoldIncomeDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    var houseHoldMembersCopy =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers;

    if(houseHoldMembersCopy){
      for (let houseHoldMembers of houseHoldMembersCopy) {
        // if (houseHoldMembers.dataChangeCode) continue;
  
        let childMemberObject = _.cloneDeep(memberObject);
        var incomeHouseHoldName = houseHoldMembers.firstName;
        var incomeCombinedName = "";
        if (incomeHouseHoldName !== "") {
          incomeCombinedName =
            incomeHouseHoldName + " " + houseHoldMembers.lastName;
          let incomeHouseHoldDOB = houseHoldMembers.dateOfBirth;
          if (houseHoldMembers.dateOfBirth !== "") {
            incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
          }
        } else if (incomeHouseHoldName === "") {
          incomeCombinedName = houseHoldMembers.firstName;
        }
  
        // binding the dependents clientIds
        childMemberObject[OptionsProps.OPTION_VALUE] = houseHoldMembers.id;
        childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
        childMemberObject.id = houseHoldMembers.id;
        childMemberObject.isGrantee = TextProps.VALUE_N;
        incomeNameList.push(incomeCombinedName);
      }
    }

    this.setState({ houseMemberLabels: incomeNameList });
  };
  newUpdateHouseHoldIncomeDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    let houseHoldMembersCopy =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers ? this.props.consumer.consumerData.householdMembers : JSON.parse(sessionStorage.getItem("houseHoldMembersCopy"));
    if(houseHoldMembersCopy){
      sessionStorage.setItem("houseHoldMembersCopy",JSON.stringify(houseHoldMembersCopy));
      for (let houseHoldMembers of houseHoldMembersCopy) {
        // if (houseHoldMembers.dataChangeCode) continue;
  
        let childMemberObject = _.cloneDeep(memberObject);
        var incomeHouseHoldName = houseHoldMembers.firstName;
        var incomeCombinedName = "";
        if (incomeHouseHoldName !== "") {
          incomeCombinedName =
            incomeHouseHoldName + " " + houseHoldMembers.lastName;
          let incomeHouseHoldDOB = houseHoldMembers.dateOfBirth;
          if (houseHoldMembers.dateOfBirth !== "") {
            incomeCombinedName = incomeCombinedName + " " + formatters.formatDate(incomeHouseHoldDOB);
          }
        } else if (incomeHouseHoldName === "") {
          incomeCombinedName = houseHoldMembers.firstName;
        }
  
        // binding the dependents clientIds
        childMemberObject[OptionsProps.OPTION_VALUE] = houseHoldMembers.id;
        childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
        childMemberObject.id = houseHoldMembers.id;
        childMemberObject.isGrantee = TextProps.VALUE_N;
        incomeNameList.push(incomeCombinedName);
      }
    }

    return incomeNameList;
  };

  houseHoldDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    var houseHoldMembersCopy =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers;

    if(houseHoldMembersCopy){
      for (let houseHoldMembers of houseHoldMembersCopy) {
        // if (houseHoldMembers.dataChangeCode) continue;
  
        let childMemberObject = _.cloneDeep(memberObject);
        var incomeHouseHoldName = houseHoldMembers.firstName;
        var incomeCombinedName = "";
        if (incomeHouseHoldName !== "") {
          incomeCombinedName =
            incomeHouseHoldName + " " + houseHoldMembers.lastName;
          let incomeHouseHoldDOB = formatters.formatDate(houseHoldMembers.dateOfBirth);
          if (houseHoldMembers.dateOfBirth !== "") {
            incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
          }
        } else if (incomeHouseHoldName === "") {
          incomeCombinedName = houseHoldMembers.firstName;
        }
  
        // binding the dependents clientIds
        childMemberObject[OptionsProps.OPTION_VALUE] = houseHoldMembers.id;
        childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
        childMemberObject.id = houseHoldMembers.id;
        childMemberObject.isGrantee = TextProps.VALUE_N;
        incomeNameList.push(incomeCombinedName);
      }
    }

    return incomeNameList;
  };

  setHouseHoldInitialData=()=>{
    if(this.props.consumer &&
    this.props.consumer.consumerData &&
    this.props.consumer.consumerData.householdMembers){
      this.responseData.oldHouseholdData = _.cloneDeep(this.props.consumer.consumerData.householdMembers);
    }
  }

  setResourcesInitialData=(clientData)=>{
    if(this.props.consumer &&
    this.props.consumer.consumerData &&
    this.props.consumer.consumerData.resources){
      this.responseData.oldResourcesData = _.cloneDeep(this.props.consumer.consumerData.resources);
    }
  }
  setIncomeInitialData = (clientData) => {
    let earnedIncomes = [];
    let unEarnedIncomes = [];
    let incomeArray = [];
    if (this.props.consumer.consumerData && this.props.consumer.consumerData.earnedIncome && this.props.consumer.consumerData && this.props.consumer.consumerData.unearnedIncome) {
      earnedIncomes = this.props.consumer.consumerData && this.props.consumer.consumerData.earnedIncome ? _.cloneDeep(this.props.consumer.consumerData.earnedIncome) : [];
      unEarnedIncomes = this.props.consumer.consumerData && this.props.consumer.consumerData.unearnedIncome ? _.cloneDeep(this.props.consumer.consumerData.unearnedIncome) : [];
    } else if (clientData.earnedIncome && clientData.unearnedIncome) {
      earnedIncomes = clientData.earnedIncome ? _.cloneDeep(clientData.earnedIncome) : [];
      unEarnedIncomes = clientData.unearnedIncome ? _.cloneDeep(clientData.unearnedIncome) : [];
    }
    incomeArray = earnedIncomes.concat(unEarnedIncomes);
    incomeArray = incomeArray.sort((a, b) => {
      if (a.agencyID !== b.agencyID) {
        return a.agencyID - b.agencyID;
      }

      if (a.firstName !== b.firstName) {
        return a.firstName.localeCompare(b.firstName);
      }
      
      if (a.lastName !== b.lastName) {
        return a.lastName.localeCompare(b.lastName);
      }
      
      return new Date(a.dateOfBirth) - new Date(b.dateOfBirth);
    });
    this.responseData.oldIncomeData = incomeArray;
  }

  setShelterInitialData = (clientData) =>{
    if(this.props.consumer.consumerData && this.props.consumer.consumerData.shelterExpense){
      this.responseData.oldShelterData= _.cloneDeep(this.props.consumer.consumerData.shelterExpense);
      this.responseData.newShelterData= _.cloneDeep(this.props.consumer.consumerData.shelterExpense);
    }
    if(this.props.consumer.consumerData && this.props.consumer.consumerData.utilityExpense){
      let utilityExpense = _.cloneDeep(this.props.consumer.consumerData.utilityExpense);
      let oldUtility = {};
      Object.keys(utilityExpense).forEach((exp)=>{
        if(!exp.includes('DataChangeCode')){
          oldUtility[exp] = utilityExpense[exp]
        }
     })
     this.responseData.oldUtilityData=oldUtility;
     this.responseData.newUtilityData=oldUtility;
    }
  }

  setChildSupportInitialData=(clientData)=>{
    if(this.props.consumer &&
    this.props.consumer.consumerData &&
    this.props.consumer.consumerData.childSupportExpense){
      this.responseData.oldChildSupportData = _.cloneDeep(this.props.consumer.consumerData.childSupportExpense);
    }else if(clientData.childSupportExpense){
      this.responseData.oldChildSupportData = _.cloneDeep(clientData.childSupportExpense);
    }
  }

  setMedicalInitialData=(clientData)=>{
    if(this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.medicalExpenses){
        this.responseData.oldMedicalExpenseData = _.cloneDeep(this.props.consumer.consumerData.medicalExpenses);
      }else if(clientData.childSupportExpense){
        this.responseData.oldMedicalExpenseData = _.cloneDeep(clientData.medicalExpenses);
      }
  }

  setAuthRepInitialData=(clientData)=>{
    if(this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.authorizedRepresentatives){
        const {ebtAuthRepFlg,snapAuthRepFlg} = this.props.consumer.consumerData;
        this.responseData.oldAuthorizedRepresentativesData = { authorizedRepresentatives :{..._.cloneDeep(this.props.consumer.consumerData.authorizedRepresentatives)},ebtAuthRepFlg,snapAuthRepFlg};
      }else {
        const {ebtAuthRepFlg,snapAuthRepFlg} = clientData;
        this.responseData.oldAuthorizedRepresentativesData = { authorizedRepresentatives:{..._.cloneDeep(clientData.authorizedRepresentatives)},ebtAuthRepFlg,snapAuthRepFlg};
      }
  }

  setAttendanceInitialData=(clientData)=>{
    if(this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.schoolAttendance){
        this.responseData.oldSchoolAttendanceData = _.cloneDeep(this.props.consumer.consumerData.schoolAttendance);
      }else {
        this.responseData.oldSchoolAttendanceData = _.cloneDeep(clientData.schoolAttendance);
      }
  }
  onIncomeSubSectionClean = async () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          incomeSummaryTemplate: incomeSummaryTemplate,
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            incomeSections[0]
          );
          this.onIncomeSubsectionRender();
        }
      );
    }
  };


  onIncomeSubsectionRender = async () => {
    let existingtypes = incomeExistingTypes;
    let answer = [];
    let earnedIncomes = this.props.consumer.consumerData && this.props.consumer.consumerData.earnedIncome;
    let unEarnedIncomes = this.props.consumer.consumerData && this.props.consumer.consumerData.unearnedIncome;
    // Earned and Unearned Income Merge done here
    let incomeArray = earnedIncomes.concat(unEarnedIncomes);
    var incomeMeTemplate = _.cloneDeep(IncomeSectionTemplate);
    var incomeMemberMultipleTemplate = [];
    var incomeSummaryTemplate = this.state.incomeSummaryTemplate;
    var incomeCopy = this.props.consumer.reportChangeSections[0];

    incomeArray = incomeArray.sort((a, b) => {
      if (a.agencyID !== b.agencyID) {
        return a.agencyID - b.agencyID;
      }

      if (a.firstName !== b.firstName) {
        return a.firstName.localeCompare(b.firstName);
      }

      if (a.lastName !== b.lastName) {
        return a.lastName.localeCompare(b.lastName);
      }

      return new Date(a.dateOfBirth) - new Date(b.dateOfBirth);
    });
    incomeArray.forEach((income) => {
      let client = income.firstName + " " + income.lastName + formatDate(income.dateOfBirth);
        answer.push(client);
    });

    
    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          incomeMeTemplate.id = "combined-income-" + prevClientIndex;
          incomeMeTemplate.header = ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] = ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "combined-incomesubsection-" + prevClientIndex;
          incomeMeTemplate.subsections[0].header = ReportChangePageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.title = answer[i];
          incomeMeTemplate.clientId = answer[i];
          if (this.props.consumer.reportChangeSections[0]) {
            if (
              existingtypes.indexOf(
                incomeArray[i].typeCode
              ) >= 0
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                incomeArray[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                incomeArray[i]
                  .typeCode,
              ];
            }
          }

          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
              } else {
          if (
            this.props.consumer.reportChangeSections[0].retainData ===
            TextProps.VALUE_TRUE ||
            this.props.consumer.reportChangeSections[0].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                incomeArray[i].typeCode
              ) >= 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].indexOf(
                  incomeArray[i]
                    .typeCode
                ) < 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].length > 0
                ) {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].push(
                    incomeArray[i]
                      .typeCode
                  );
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer.push(
                    incomeArray[i]
                      .typeCode
                  );
                } else {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ] = [
                      incomeArray[i]
                        .typeCode,
                    ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer = [
                      incomeArray[i]
                        .typeCode,
                    ];
                }
              }
            }
          }
        }
      }
    }

    incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeMemberMultipleTemplate;
    this.setState({ incomeValuesPopulated: TextProps.VALUE_TRUE });
    this.incomeSubsectionLoader(incomeArray,incomeCopy,existingtypes);
  }

  incomeSubsectionLoader = (oldData, incomeSection, existingtypes) => {
    oldData && oldData.length && oldData.forEach((data) => {
      let client = data.firstName + " " + data.lastName + formatDate(data.dateOfBirth);
      let clientTypeCode = data.typeCode;
      // Iteration for members
      for (let i = 0; i < incomeSection.subsections.length - 1; i++) {
        if (incomeSection.subsections[i].clientId === client) {
          // dynamic label setting
          incomeSection.subsections[i].subsections[0].questions.forEach((oneQuestion, index) => {
            oneQuestion.repeatedQuestion.amountLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                incomeCodeMapper(language)[
              existingtypes[index]
              ],
              language,
            ).amountLabel;

            oneQuestion.repeatedQuestion.unitLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                  incomeCodeMapper(language)[
              existingtypes[index]
                  ],
              language
            ).unitLabel;

            oneQuestion.repeatedQuestion.employerLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                      incomeCodeMapper(language)[
              existingtypes[index]
                      ],
                      language,
              existingtypes[index]
            ).employerLabel;

            oneQuestion.amountLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                    incomeCodeMapper(language)[
              existingtypes[index]
                    ],
              language
            ).amountLabel;

            oneQuestion.unitLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                    incomeCodeMapper(language)[
              existingtypes[index]
                    ],
              language
            ).unitLabel;

            oneQuestion.employerLabel = incomeDynamicMultilanguageLabels(
              incomeSection.subsections[i].clientId,
                    incomeCodeMapper(language)[
              existingtypes[index]
                    ],
                    language,
              existingtypes[index]
            ).employerLabel;

            // pre-populate values 
            if (existingtypes[index] === clientTypeCode) {
                  let amountObj = [];
              if (data.amounts !== undefined) {
                data.amounts.map((amount, index) => {
                      amountObj.push(amount.amount + "");
                    });
                  }
              let typeText = incomeCodeMapper(language)[existingtypes[index]];
              oneQuestion.Answer = [
                {
                      fullIncomeObj:
                    data,
                        code: "existing",
                        amount: amountObj,
                      unit: getFrequency(language)[
                    data
                          .frequencyCode
                      ],
                      employer:
                    data.employerName,
                      employerLabel:
                        typeText +
                        ReportChangePageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                    data.employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                },
              ];
              oneQuestion[
                      OptionsProps.TARGET_VALUE
              ] = [
                  {
                      fullIncomeObj:
                      data,
                        code: "existing",
                        amount: amountObj,
                      unit: getFrequency(language)[
                      data
                          .frequencyCode
                      ],
                      employer:
                      data
                          .employerName,
                      employerLabel:
                        typeText +
                        ReportChangePageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                      data
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
              oneQuestion.value = [
                {
                      fullIncomeObj:
                    data,
                        code: "existing",
                        amount: amountObj,
                      unit: getFrequency(language)[
                    data
                          .frequencyCode
                      ],
                      employer:
                    data
                          .employerName,
                      employerLabel:
                        typeText +
                        ReportChangePageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                    data
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
            } else if (!oneQuestion.Answer) {
              oneQuestion.Answer = [];
              oneQuestion.targetValue = [];
              oneQuestion.value = [];
                  }
          })
                }
              }
    })
    this.prepareIncomeSummary();
  };


  prepareIncomeSummary = () => {
    let incomeSection = this.props.consumer.reportChangeSections[0];
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].valueToMatch;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.nonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  returnDataChangeCode=(code)=>{
    let changeCode = ""
    switch(code){
      case "existing":
        changeCode = "NOCHNG";
        break;
      case "new":
        changeCode = "NEW";
        break;
      case "changed":
        changeCode = "CHANGE"
        break;
      default:
        changeCode =code;
        break;
    }
    return changeCode;
  }


  returnMemberId(member, incomeSub) {
    let membId;
    if (member.code === "new") {
      membId = 0;
    } else {
      if (incomeSub.fullIncomeObj !== undefined && incomeSub.fullIncomeObj.id) {
        membId = incomeSub.fullIncomeObj.id;
      } else {
        membId = 0;
      }
    }
    return membId;
  }

  returnAgencyID(memberObj, incomeSub) {
    let agencyID;
    if (
      incomeSub.fullIncomeObj !== undefined &&
      incomeSub.fullIncomeObj.agencyID
    ) {
      agencyID = incomeSub.fullIncomeObj.agencyID;
    } else {
        agencyID = memberObj && memberObj.agencyID ? memberObj.agencyID : 0;
    }
    return agencyID;
  }

  updateSignature = (e) =>{
    this.setState({ signatureError: TextProps.VALUE_FALSE,signature:e });
    this.signatureErrorMsg = "";
  }


  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agreeErrorMsg = ReportChangePageLabels(
          language,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agreeErrorMsg = "";
      }
    });
  };

 compareArrays=(oldArray, newArray)=>{
    const newIds = new Set(newArray.map(obj => obj.id));
    const removedItems = [];
    oldArray.forEach(newObj => {
        if (!newIds.has(newObj.id)) {
            newObj.dataChangeCode = "REMOVE";
            removedItems.push(newObj);
        }
    });
    return removedItems;
}

  getRemovedResources = (oldResource, newResource) => {
    newResource.forEach(obj2 => {
    let matchingObj = oldResource.find(obj1 =>
        obj1.firstName === obj2.firstName &&
        obj1.lastName === obj2.lastName &&
        obj1.dateOfBirth === obj2.dateOfBirth &&
        obj1.resourceType === obj2.resourceType
    );
    if (matchingObj) {
        obj2.uniqueKey = matchingObj.resrcId;
    }
  });
  const removedResources = oldResource.filter(obj1 =>
    !newResource.some(obj2 =>
        obj1.resrcId === obj2.uniqueKey 
    )
  )
  removedResources.forEach(obj => {
    obj.datachangeCode = "REMOVE";
    obj.resourceDetails.forEach(resObj => resObj.datachangeCode = "REMOVE")
  });
  return removedResources;
  }

  checkForDataChange = (activeId) => {
    let clientData = JSON.parse(window.sessionStorage.getItem('loadConsumerUser'));
    if(activeId === "household"){
      if(this.props.consumer && this.props.consumer.householdMembers && this.props.consumer.householdMembers.length){
        this.props.consumer.householdMembers.forEach((household)=>{
          if(household.granteeFlag){
              if(this.state.signature === ""){
                household.dataChangeCode = "NOCHNG"
              }
          } 
        })
      }
    }  else if(activeId.includes('combined-resources-subsection-')){
      let resources = _.cloneDeep(this.resourcesSummary.resourcesMembers);
      let resourceArrayNew = [];
      let answer=[];
      resources.forEach((member) => {
        let memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          this.props.consumer.consumerData.resources
        );
        if (memberObj === null) {
          memberObj = getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.consumer.consumerData.householdMembers ? this.props.consumer.consumerData.householdMembers : clientData.householdMembers
          );
        }
        if (member.resources !== undefined) {
          member.resources.forEach((resrc) => {
            let typeCode = null;
            let memberDetails = member.firstName && member.firstName.split(' ');
            const firstName = memberDetails[0];
            const lastName = memberDetails[1];
            const dob = memberDetails[2] && memberDetails[2].slice(1, -1);
           
           let isResourceAns = resrc !== undefined && resrc.Answer !== undefined && resrc.Answer.length > 0 && 
                  resrc.Answer.filter(ans => ans.amount !== 0 && ans.amount !== "");
            if (
              resrc !== undefined &&
              resrc.Answer !== undefined &&
              resrc.Answer.length > 0 &&
              isResourceAns.length > 0
            ) {
              let resourceObj = {
                id: resrc.id,
                datachangeCode: resrc.datachangeCode,
                resourceType: resrc.typeCode,
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: formatDateNew(dob),
                resourceDetails: resrc.Answer,
                agencyID: resrc.agencyID
              };
              resourceArrayNew.push(resourceObj);
            }
          }); 
        }
      });
      const resourceArrayExisting = this.props.consumer.consumerData.resources;
      resourceArrayExisting.forEach((obj, index) => obj['resrcId'] = "resrc_"+index);
      
      const resultArray = this.getRemovedResources(resourceArrayExisting, resourceArrayNew);
      answer = [...resultArray, ...resourceArrayNew];
      let finalArray = _.cloneDeep(answer);
      this.responseData.resourceAnswer = finalArray;
  
    }else if(activeId.includes('combined-incomesubsection-')){
    let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
    let earnedIncomes = _.cloneDeep(this.props.consumer.consumerData.earnedIncome);
    let unEarnedIncomes = _.cloneDeep(this.props.consumer.consumerData.unearnedIncome);
    let incomeArrayExisting = earnedIncomes.concat(unEarnedIncomes);
    let incomeArrayNew = [];
    let answer=[];
    allIncome.forEach((member) => {
      let memberObj = getMemberDataObjectByNameAndDOB(
        member.firstName,
        incomeArrayExisting
      );
      if (memberObj === null) {
        memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          this.props.consumer.consumerData.householdMembers ? this.props.consumer.consumerData.householdMembers : clientData.householdMembers
        );
      }
      if (member.incomes !== undefined) {
        member.incomes.forEach((income) => {
          let typeCode = null;
          if (
            income !== undefined &&
            income.Answer !== undefined &&
            income.Answer.length > 0
          ) {
            income.Answer.forEach((incomeSub) => {
              let amountArray = [];
              let amountIterator = 1;
              if (incomeSub.amount) {
                if (incomeSub.unit === "Weekly") {
                  amountIterator = 4;
                } else if (
                  incomeSub.unit === "Biweekly (every two weeks)"
                ) {
                  amountIterator = 2;
                }

                for (let i = 0; i < amountIterator; i++) {
                  if(incomeSub.amount[i])
                  amountArray.push({
                    amount: Number(incomeSub.amount[i]),
                  });
                }
              }
              let changeCode = "NOCHNG";
              changeCode = getDataChangeCode(incomeSub.code)
              // if (changeCode !== "NOCHNG")
              typeCode = income.typeCode;

              let incomeObj = {
                id: this.returnMemberId(member, incomeSub),
                setID:
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                    ? incomeSub.fullIncomeObj.setID
                    : 0,
                agencyID: this.returnAgencyID(memberObj, incomeSub),
                firstName:
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                    ? incomeSub.fullIncomeObj.firstName
                    : memberObj.firstName,
                lastName:
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                    ? incomeSub.fullIncomeObj.lastName
                    : memberObj.lastName,
                dateOfBirth: convertDOBForDataPost(
                  incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.dateOfBirth
                    ? incomeSub.fullIncomeObj.dateOfBirth
                    : memberObj.dateOfBirth
                ),
                typeCode: typeCode,
                employerName: incomeSub.employer,
                frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                amounts: amountArray,
                dataChangeCode: changeCode,
              };

              incomeArrayNew.push(incomeObj);
            }); // income sub ends
          }
        }); // income ends
      }
    });
    const resultArray = this.compareArrays(incomeArrayExisting, incomeArrayNew);
    answer = [...resultArray,...incomeArrayNew]
    let finalArray = _.cloneDeep(answer);
    this.responseData.incomeAnswer = finalArray;

  }else if (activeId === "utility-expenses-section"){
    let changeCode = "NOCHNG";
    let finalShelterArray = [];
      //Shelter Changes
      let shelterExpNew = _.cloneDeep(this.shelterSummary.shelterExpense[0].incomes);
      const shelterExpNewCodes = shelterExpNew.map(newExp => {
        return newExp.typeCode;
      });

      let shelterExpExisting = _.cloneDeep(
        this.props.consumer.consumerData.shelterExpense
      );

      const removedShelterExp = shelterExpExisting.filter(oldExp => {
        if(!shelterExpNewCodes.includes(oldExp.typeCode)){
          return oldExp;
        }
      })
      const formRemovedShelterObj = removedShelterExp.map(removedExp => {
        const newObj = {};
        newObj["typeCode"] = removedExp.typeCode;
        newObj["Answer"] = [
          {
            amount: removedExp.amount,
            unit: getShelterExpenseFrequency(removedExp.frequencyCode),
            code: "REMOVE"
          }
        ]
        return newObj;
      });

     shelterExpNew = [...shelterExpNew, ...formRemovedShelterObj]
     this.shelterSummary.shelterExpense[0].incomes = shelterExpNew;

      if(shelterExpNew && shelterExpNew.length>0){
          shelterExpNew.forEach((newExp)=>{
            let shelterObj = {};
            if(newExp.Answer && Array.isArray(newExp.Answer))
            newExp.Answer.forEach((oneExp)=>{
              if(oneExp.code && !oneExp.hasOwnProperty('isNewValueRemoved')){
              if(oneExp.code){
                changeCode = getDataChangeCode(oneExp.code)
              }
              shelterObj = {
                "typeCode":newExp.typeCode,
                "frequencyCode": getFrequencyCode(language)[oneExp.unit],
                "amount":oneExp.amount,
                "dataChangeCode":changeCode,
              }
              if(shelterObj.amount && shelterObj.frequencyCode){
                finalShelterArray.push(shelterObj);
              }
            }
            })
          })
      }
      this.responseData.shelterAnswers = finalShelterArray;

      
      let liheapFlagValue = null;
      if (this.shelterSummary && this.shelterSummary.hasOwnProperty('isLiheapDataUpdated')) {
        if (this.shelterSummary.isLiheapDataUpdated === 'Yes') {
          liheapFlagValue = true;
        } else if (this.shelterSummary.isLiheapDataUpdated === 'No') {
          liheapFlagValue = false;
        }
      }

      // Utility Expense
      let utilityExpense = {
        "heatFlag": false,
        "acFlag": false,
        "electricOrGasFlag": false,
        "phoneFlag": false,
        "noUtilityFlag": false,
        "payWaterSewage":false,
        "payGarbage":false,
        "liheapFlag": liheapFlagValue,
       }
      let utilityExpNew = _.cloneDeep(this.shelterSummary.utilityExpenses);
      if(utilityExpNew && utilityExpNew.length>0){
        utilityExpNew.forEach((oneExp)=>{
          changeCode = getDataChangeCode(oneExp.code)
        })
    }
    Object.keys(utilityExpense).forEach((exp)=>{
      utilityExpNew.forEach((newExp)=>{
        utilityExpense["dataChangeCode"] = (newExp.code !== null && (newExp.code !== "removed" || newExp.code !== "existing")) ? "CHANGE" : "NOCHNG";
          if(newExp.type === exp && newExp.code !== "removed"){
            utilityExpense[exp] = true;
          }
      })
   })

   const isNoUtilityExpenses =  utilityExpNew && utilityExpNew.length > 0 && utilityExpNew.filter(oneUtilExp => oneUtilExp.type === "I do not pay for any utility expenses");
   if(isNoUtilityExpenses && isNoUtilityExpenses.length > 0){
    utilityExpense['noUtilityFlag'] = true;
   }
   this.responseData.utilityAnswers = utilityExpense;
  }
  else if(activeId.includes('child-support-amount-')){
    if(this.props.consumer.childExpScreen && this.props.consumer.childExpScreen.tempChildExpArray){
    let childExpenseNew =_.cloneDeep(
      this.props.consumer.childExpScreen.tempChildExpArray.filter((oneChild)=> oneChild.dataChangeCode !== null)
    );
    let childExpenseExisting = _.cloneDeep(this.props.consumer.consumerData.childSupportExpense);
    let ChildArrayNew = [];
    childExpenseNew.map((member) => {
      let memberObj = getMemberDataObjectByNameAndDOB(
        member.firstName,
        childExpenseExisting
      );
      if (memberObj === null) {
        memberObj = getMemberDataObjectByNameAndDOB(
          member.firstName,
          this.props.consumer.consumerData.householdMembers
        );
      }
      let changeCode = "NOCHNG";
      changeCode = getDataChangeCode(member.dataChangeCode);

      let childObj = {
        id: member.id,
        setID:member.setID? member.setID : 0,
        agencyID: member.agencyID,
        firstName:member.firstName,
        lastName:member.lastName,
        dateOfBirth: convertDOBForDataPost(member.dateOfBirth),
        typeCode: member.typeCode,
        frequencyCode: member.frequency ? getFrequencyCode(language)[member.frequency] : member.frequencyCode , 
        amount: member.amount,
        dataChangeCode: member.id === 0  ? "NEW" : changeCode,
      };
      ChildArrayNew.push(childObj)
    })
    let finalArray = _.cloneDeep(ChildArrayNew);
    childExpenseExisting.forEach((existing,index) => {
      existing.dateOfBirth = convertDOBForDataPost(existing.dateOfBirth);
      let found = TextProps.VALUE_FALSE;
      for (let newChildExp of ChildArrayNew) {
        if (
          newChildExp.id === existing.id &&
          newChildExp.agencyID === existing.agencyID &&
          newChildExp.setID === existing.setID
        ) {
          found = TextProps.VALUE_TRUE;
        }
      }
      if (found === TextProps.VALUE_FALSE) {
        if(existing.id === 0){
          childExpenseExisting.splice(index, 1);
        }else{
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
        }
      }
    });
    this.responseData.childSupportExpense = finalArray;
  }
}else  if(activeId.includes('medical-expenses-filter-')){
  let summaryData = this.medicalSummary.summaryData;
  let medicalSummaryKey = _.cloneDeep(medicalExpenseKeys);
  let medicalExpensesData = [];
      summaryData.map((item) => {
        let medicalSummaryDataMap = _.cloneDeep(medicalExpenseObj);
        this.props.consumer.consumerData.householdMembers.map((mem) => {
          let user = [mem["firstName"],mem["lastName"],formatters.formatDate(mem["dateOfBirth"])].join(' ').replace(/ +(?= )/g,'');
          if(user == item.name){
            medicalSummaryDataMap = Object.assign(medicalSummaryDataMap,{
            "dateOfBirth": convertDOBForDataPost(mem.dateOfBirth),
            "agencyID": mem.agencyID,
            "firstName": mem.firstName,
            "lastName": mem.lastName,
            "dataChangeCode": mem.dataChangeCode
          });
          }
        })
        item.medicalExpenses.map((exp) => {
          let expensesKeys = medicalSummaryKey[exp.typeCode];
          let expensesData = {
            [expensesKeys.flag]: true,
            [expensesKeys.amount]: Number(exp.amount),
            [expensesKeys.unit]: exp.unit,
            [expensesKeys.code]: this.returnDataChangeCode(exp.label)
          };
          medicalSummaryDataMap = Object.assign(medicalSummaryDataMap,expensesData);
        })
        medicalExpensesData.push(medicalSummaryDataMap);
        let medicalExpExisting =  _.cloneDeep(
          this.props.consumer.consumerData.medicalExpenses
        )
        const finalArray = this.updateDataChangeCodes(medicalExpExisting, medicalExpensesData);
        this.responseData.medicalExpenses = finalArray;
      })
}
  }


  renderHouseholdInfo = () => {
    return (
      <Household
        householdMembers={this.props.consumer.householdMembers||this.props.consumer.consumerData.householdMembers}
        convertStringTODate={convertDOBForDataPost}
        setHouseholdMembers={this.props.setHouseholdMembersList}
        addHouseholdAddSectionToList={this.props.addHouseholdToList}
        primaryActionClick={this.onSectionChange}
        // disableSubmit={this.disableSubmit}
        updateHouseholdToState={this.updateHouseholdToState}
        cleanAddMemberClean={this.props.deleteHouseholdIntoSections}
        sections={this.props.consumer.reportChangeSections}
        renderSignAndSubmit={this.renderSignAndSubmit}
        appendSignInSections={this.props.appendSignInSections}
      />
    );
  };


  renderAuthRep = () => {
    return (
      <AuthRep authRepData={this.props.consumer.consumerData.authorizedRepresentatives || "" } authRepDataHandler = {this.authRepDataHandler} errorData={JSON.stringify(this.state.authRepError)} resetErrorData={this.resetAuthRepError} ebtPhoneValid={this.state.ebtPhoneValid} snpPhoneValid={this.state.snpPhoneValid} signedNameValid={this.state.signedNameValid}/>
    );
  };

 renderResourcesSummary = () => {
    return (
      <ResourcesSummary
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareResourcesSummary={this.prepareResourcesSummary}
        resourcesSummary={this.resourcesSummary}
        onResourcesSubsectionRender={this.onResourcesSubsectionRender}
        onSectionChange={this.onSectionChange}
        setSectionDataUpdated={this.setSectionDataUpdated}
      />
    );
  };

 renderAttendance = () => {
    return (
      <Attendance attendanceDataHandler={this.attendanceDataHandler} data={{selectedAttendanceMembers:this.state.selectedAttendanceMembers,nonAttendanceMembers:this.state.nonAttendanceMembers}}/>
    );
  };

  renderIncomeSummary = () => {
    return (
      <EarnedIncomeSummary
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareIncomeSummary}
        incomeSummary={this.incomeSummary}
        onEarnedIncomeSubsectionRender={this.onIncomeSubsectionRender}
        onIncomeSubSectionClean={this.onIncomeSubSectionClean}
        onSectionChange={this.onSectionChange}
        updateHouseHoldIncomeDetails={this.updateHouseHoldIncomeDetails}

      />
    );
  };

  renderChildSupportExpenses = () => {
    return (
      <ChildSupport
        sections={this.props.consumer.reportChangeSections}
        householdMembers={this.props.consumer.consumerData.householdMembers || []}
        incomeSummary={this.incomeSummary}
        prepareChildSupportSummary={this.prepareChildSupportSummary}
        onChildSupportSubsectionRender={this.onChildSupportSubsectionRender}
        onSectionChange={this.onSectionChange}
        handleRemove={this.handleRemove}
        handleDone={this.handleDone}
      />
    );
  };

  renderShelterExpenses = () => {
    return (
      <ShelterExpenses
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareShelterSummary={this.prepareShelterSummary}
        shelterSummary={this.shelterSummary}
        shelterSubsectionRender={this.shelterSubsectionRender}
        onSectionChange={this.onSectionChange}
        setShelterData={this.props.setShelterData}
        shelterResponseData={this.props.consumer && this.props.consumer.consumerData ? this.props.consumer.consumerData : {}}
      />
    );
  };


  renderMedicalExpenses = () => {
    this.prepareMedicalExpensesSummary();
    return (
      <MedicalExpenses
        getSummaryTitle={this.getIncomeSummaryTitle}
        prepareMedicalExpensesSummary={this.prepareMedicalExpensesSummary}
        medicalSummary={this.medicalSummary}
        updateMedicalExpenseDetails={this.updateMedicalExpenseDetails}
        onSectionChange={this.onSectionChange}
      />
    );
  };

  renderSignAndSubmit = () => {
      return (
        <div key={this.nextUniqueId()} className="pad-all--double">
          <p>
            <b>
              {
                ReportChangePageLabels(
                  language,
                  languageConstants.householdStatic
                ).rightsHHRemovedText
              }
            </b>
            <br />
          </p>
          <form id={this.state.submitFormId}>
            <div>
              {this.state.agreeError ? (
                <b>
                  <p style={{ color: "red" }}>{this.agreeErrorMsg}</p>
                </b>
              ) : (
                <p></p>
              )}
            </div>
            <Checkbox
              className="dta-form__option-list"
              optionClass="dta-form__option"
              aria-labelledby="submit-1"
              options={[
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).iagreeTermsLabel,
              ]}
              ref={this.termsRef}
              onChange={this.acceptTermsConditions}
              required={TextProps.VALUE_TRUE}
              error={this.state.agreeError}
            />
            <hr className="divider" />
            <label className="dta-form__label" htmlFor="sign-app">
              <h3>
                {
                  ReportChangePageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).signYourApplication
                }
              </h3>
            </label>
            {/* Clicking will submit the application and route user to follow up page */}
            <div>
              {this.state.signatureError ? (
                <b>
                  <p className="error-text-clr">{this.signatureErrorMsg}</p>
                </b>
              ) : (
                <p></p>
              )}
            </div>
            <DTAInputSubmit
              className="dta-form__control"
              type="specialTextType"
              id="sign-app"
              maxLength={60}
              ref={this.applicationSignRef}
              onChange={this.updateSignature}
              required={TextProps.VALUE_TRUE}
              error={this.state.signatureError}
            />
          </form>
        </div>
      );
  };



  prepareChildSupportSummary = (childSupportSection) => {
    let houseMemberList = this.houseHoldDetails();

    this.incomeSummary.childSupportIncomeMembers = [];
    this.incomeSummary.childSupportNonIncomeMembers = [];
    let currentIncomeMembers = [];
    if (childSupportSection && childSupportSection.subsections) {
      for (
        var memberIncomeIndex = 0;
        memberIncomeIndex < childSupportSection.subsections.length - 1;
        memberIncomeIndex++
      ) {
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex] = {};
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].section =
          childSupportSection.subsections[memberIncomeIndex];
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].id =
          childSupportSection.subsections[memberIncomeIndex].id;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].firstName =
          childSupportSection.subsections[memberIncomeIndex].title;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].clientId =
          childSupportSection.subsections[memberIncomeIndex].title;
        currentIncomeMembers[memberIncomeIndex] =
          childSupportSection.subsections[memberIncomeIndex].title;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeType = [];
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes = [childSupportSection.subsections[memberIncomeIndex].questions[0].Answer];
        if (
          childSupportSection.subsections[memberIncomeIndex] !== undefined
        ) {
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].incomeInputs =
            childSupportSection.subsections[memberIncomeIndex].questions;
          for (
            var questionIndex = 0;
            questionIndex <
            this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeInputs
              .length;
            questionIndex++
          ) {
            this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "childExpense-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }

    let nonMemberCount = 0;

    if(houseMemberList){
      // loop through previous array
      for (let memberList of houseMemberList) {
        // look for same thing in new array
        if (currentIncomeMembers.indexOf(memberList) === -1) {
          let nonMember = {};
          nonMember.id = nonMemberCount;
          nonMember.firstName = memberList;
          this.incomeSummary.childSupportNonIncomeMembers.push(nonMember);
          nonMemberCount++;
        }
      }
    }
   
  };

  onChildSupportSubsectionRender = async (memberTemplate = null) => {
    let answer = [];
    let childSupportSummaryTemplate;
    let childSupportCopy;
    if(this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.childSupportExpense){
      this.props.consumer.consumerData.childSupportExpense.forEach((support) => {
        answer.push(
          support.firstName + " " + support.lastName + " " +support.dateOfBirth
        );
      });
    }
    
    let childSupportMultipleTemplate = [];
    let childSupportTemplate = _.cloneDeep(expenseChildSupportTemplate);
    if(this.props.consumer.reportChangeSections && this.props.consumer.reportChangeSections[0]){
    childSupportCopy = _.cloneDeep(this.props.consumer.reportChangeSections[0]);
    childSupportSummaryTemplate = _.cloneDeep(this.props.consumer.reportChangeSections[0].subsections[
      this.props.consumer.reportChangeSections[0].subsections.length - 1
    ]);

    this.setState({
      childSupportSummaryTemplate: childSupportSummaryTemplate
    })
    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        let memberDisplay = answer[i].split(" ");
        memberDisplay = memberDisplay[0] + " "+  memberDisplay[1] + " " + memberDisplay[2]
        const childSupportExpense = this.props.consumer.consumerData.childSupportExpense[i];
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          if (childSupportTemplate && childSupportTemplate.questions[0]) {
            childSupportTemplate.questions[0][Props.LABEL] = ReportChangePageLabels(
              language,
              languageConstants.childSupportDynamic,
              memberDisplay,
              null,
              null
            ).mainQuestion;
            childSupportTemplate.title = memberDisplay;
            childSupportTemplate.clientId = answer[i];
            childSupportTemplate.id = "child-support-amount-" + i;
            childSupportTemplate.questions[0].id = "child-support-amount-" + i;
            childSupportTemplate.questions[0].clientId = answer[i];
            childSupportTemplate.questions[0].enabledClickAction = (event) => this.handleRemove(event);
            childSupportTemplate.questions[0].enabledDoneAction = (event) => this.handleDone(event);
            childSupportTemplate.questions[0].Answer = {
              "amount": childSupportExpense
                .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                childSupportExpense
                  .frequencyCode
              ],
              "code": childSupportExpense
                .dataChangeCode
            };
            childSupportTemplate.questions[0].targetValue = {
              "amount": childSupportExpense
                .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                childSupportExpense
                  .frequencyCode
              ],
              "code": childSupportExpense
                .dataChangeCode
            };
          }
          childSupportMultipleTemplate.push(_.cloneDeep(childSupportTemplate));
        }
        else {
          childSupportMultipleTemplate[prevClientIndex].questions[0][
            OptionsProps.TARGET_VALUE
          ] = {
            "amount": childSupportExpense
              .amount,
            "unit": getShelterandChildSupportFrequency(language)[
              childSupportExpense
                .frequencyCode
            ],
            "code": childSupportExpense
              .dataChangeCode
          }
          childSupportMultipleTemplate[
            prevClientIndex
          ].questions[0].Answer = {
            "amount": childSupportExpense
              .amount,
            "unit": getShelterandChildSupportFrequency(language)[
              childSupportExpense
                .frequencyCode
            ],
            "code": childSupportExpense
              .dataChangeCode
          }
        }
      }
    }
    childSupportMultipleTemplate.push(childSupportSummaryTemplate);
    childSupportCopy.subsections = [];
    childSupportCopy.subsections = childSupportMultipleTemplate;
    this.prepareChildSupportSummary(childSupportCopy);
    this.props.updateReportChangeSections([childSupportCopy]);
  }
  }

  prepareShelterSummary = () => {
    let shelterSection = this.props.consumer && this.props.consumer.reportChangeSections && this.props.consumer.reportChangeSections[0];
    this.shelterSummary.shelterExpense = [];
    let currentIncomeMembers = [];
    let utilityOldAnswers = this.props.consumer.consumerData.utilityExpense;
    let utilityAnswers = this.props.consumer.reportChangeSections[0].subsections[0].subsections[this.props.consumer.reportChangeSections[0].subsections[0].subsections.length-1].questions[0].Answer || [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < shelterSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.shelterSummary.shelterExpense[memberIncomeIndex] = {};
      this.shelterSummary.shelterExpense[memberIncomeIndex].section =
      shelterSection.subsections[memberIncomeIndex];
      this.shelterSummary.shelterExpense[memberIncomeIndex].id =
      shelterSection.subsections[memberIncomeIndex].id;
      this.shelterSummary.shelterExpense[memberIncomeIndex].firstName =
      shelterSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
      shelterSection.subsections[memberIncomeIndex].title;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomeType =
      shelterSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomes = [];
      if (
        shelterSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.shelterSummary.shelterExpense[
          memberIncomeIndex
        ].incomeInputs =
        shelterSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.shelterSummary.shelterExpense[memberIncomeIndex]
            .incomeInputs.length;
          questionIndex++
        ) {
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "shelterSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
            this.shelterSummary.shelterExpense[
                memberIncomeIndex
              ].incomeInputs[questionIndex].valueToMatch;
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.shelterSummary.shelterExpense[
                memberIncomeIndex
              ].incomeInputs[questionIndex].Answer;
        }
      }
    }

    let utilityNewAnswer = [];
    let utilityOld = [];
    let oldRemoved = []

    if(this.props.consumer.reportChangeSections[0] && this.props.consumer.reportChangeSections[0].subsections && this.props.consumer.reportChangeSections[0].subsections[0] && this.props.consumer.reportChangeSections[0].subsections[0].subsections[this.props.consumer.reportChangeSections[0].subsections[0].subsections.length-1]){
      utilityNewAnswer = utilityAnswers.filter((key) => !key.toLowerCase().includes("datachangecode"));
    } 
    if (this.props.consumer.consumerData && this.props.consumer.consumerData.utilityExpense) {
      for (let key in utilityOldAnswers) {
        if (utilityOldAnswers.hasOwnProperty(key) && !key.toLowerCase().includes("datachangecode")) {
          let val = utilityOldAnswers[key];
          if (val) {
            utilityOld.push(key);
          }
        }
      }
    }
    
    if(this.props.consumer.consumerData && this.props.consumer.consumerData.utilityExpense['liheapFlag'] &&  !utilityNewAnswer.includes('liheapFlag') && !this.props.consumer.isLiheapDataUpdated){
      utilityNewAnswer.push('liheapFlag');
      this.shelterSummary.isLiheapDataUpdated = "Yes"
    }else if(this.props.consumer.isLiheapDataUpdated === "Yes" ){
      this.shelterSummary.isLiheapDataUpdated = "Yes"
    }else if( this.props.consumer.isLiheapDataUpdated === "No" ){
      this.shelterSummary.isLiheapDataUpdated = "No"
    }

    let dataChangeCodes = utilityOld.map((newExp) => {
      return setUtilityDataChangeCode(newExp, utilityOldAnswers)
    })

    let previousFlags = utilityAnswers.filter((el) => el.includes('DataChangeCode'));
    oldRemoved = previousFlags.filter(function (prevFlg) { return !this.has(prevFlg) }, new Set(dataChangeCodes.map((o) => o.flag)));

    var newUtilities = utilityNewAnswer.filter(function (utilityExp) { return !this.has(utilityExp) }, new Set(dataChangeCodes.map((o) => o.type)));
    let removedUtilities = [];
    Object.keys(utilityOldAnswers).forEach(function(oldUtil){
      if(utilityOldAnswers[oldUtil]){
        if (utilityNewAnswer.indexOf(oldUtil) <= -1 && !oldUtil.toLowerCase().includes("datachangecode")) {
          removedUtilities.push(oldUtil)
        }
      }
    })
    const finalUtilityObj = utilityNewAnswer.map((elm)=>{
      if(newUtilities.indexOf(elm)>=0){
        return {
          type:elm,
          code:"new"
        }
      }else{
        return {
          type:elm,
          code: dataChangeCodes.length > 0 ? dataChangeCodes.filter((e) => e.type === elm)[0].code : null
        }
      }
    })
    const removedUtilityObj = removedUtilities.map((elm) => {
      return {
        type: elm,
        code: "removed"
      }
    }
    )

    let retainedOld = [];
    oldRemoved.forEach((removedObj) => {
      let mappedData = {};
      switch (removedObj) {
        case 'acDataChangeCode':
          mappedData = { type: "acFlag", 'code': 'removed', flag: 'acDataChangeCode' }
          break;
        case 'electricOrGasDataChangeCode':
          mappedData = { type: "electricOrGasFlag", 'code': 'removed', flag: 'electricOrGasDataChangeCode' }
          break;
        case 'phoneDataChangeCode':
          mappedData = { type: "phoneFlag", 'code': 'removed', flag: 'phoneDataChangeCode' }
          break;
        case 'garbageDataChangeCode':
          mappedData = { type: "payGarbage", 'code': 'removed', flag: 'garbageDataChangeCode' }
          break;
        case 'heatDataChangeCode':
          mappedData = { type: "heatFlag", 'code': 'removed', flag: 'heatDataChangeCode' }
          break;
        case 'waterSewageDataChangeCode':
          mappedData = { type: "payWaterSewage", 'code': 'removed', flag: 'waterSewageDataChangeCode' }
          break;
        case 'liheapDataChangeCode':
          mappedData = { type: "liheapFlag", 'code': 'removed', flag: 'liheapDataChangeCode' }
          break;
        default:
      }
      retainedOld.push(mappedData)
    })
    retainedOld = _.uniqBy(retainedOld, (val) => val.type);
    let finalUtils = [...finalUtilityObj, ...removedUtilityObj, ...retainedOld];
    this.shelterSummary.utilityExpenses = finalUtils.filter((util)=>util.type!=="acFeeFlag");
    this.checkForDataChange('utility-expenses-section')
  }

  shelterSubsectionRender = async () => {
    let answer = [];
    let utilityAnswer = [];
    
    const question = shelterSections[0].subsections[0].subsections[1].questions[1];

    if (this.props.consumer.consumerData && this.props.consumer.consumerData.shelterExpense) {
      this.props.consumer.consumerData.shelterExpense.map((income) => {
        if(!answer.includes(income.typeCode)){
          answer.push(
            income.typeCode
          );
        }
      });
    }
    if (this.props.consumer.consumerData && this.props.consumer.consumerData.utilityExpense) {
      utilityAnswer = Object.keys(this.props.consumer.consumerData.utilityExpense).filter((key) => { if (this.props.consumer.consumerData.utilityExpense[key]) { return key } });
    }
    if (utilityAnswer.length > 0) {
      shelterSections[0].subsections[0].subsections[1].questions[0].Answer = utilityAnswer;
      shelterSections[0].subsections[0].subsections[1].questions[0].targetValue = utilityAnswer;
    }

    if(this.props.consumer.consumerData && this.props.consumer.consumerData.utilityExpense['liheapFlag'] === null){
      question.Answer = "";
      question.targetValue = "";
    }else if (this.props.consumer.isLiheapDataUpdated) {
      const isLiheapDataUpdated = this.props.consumer.isLiheapDataUpdated === 'Yes';
      question.Answer = isLiheapDataUpdated ? 'Yes' : 'No';
      question.targetValue = isLiheapDataUpdated ? 'Yes' : 'No';
    } else if(utilityAnswer.includes('liheapFlag')){
      question.Answer = 'Yes';
      question.targetValue = 'Yes';
    }else {
      question.Answer = 'No';
      question.targetValue = 'No';
    }

    if (answer.length > 0) {
      shelterSections[0].subsections[0].questions[0].Answer = answer;
      shelterSections[0].subsections[0].questions[0].targetValue = answer;
    }
  
    for (let i = 0; i < shelterSections[0].subsections[0].subsections[0].questions.length; i++) {
      let amountArr=[];
      for (
        let j = 0;
        j < this.props.consumer.consumerData.shelterExpense.length;
        j++
      ) {
        if (
          shelterSections[0].subsections[0].subsections[0].questions[i].valueToMatch ===
          this.props.consumer.consumerData.shelterExpense[j]
            .typeCode
        ) {
          if (
            this.props.consumer.consumerData.shelterExpense[j]
              .amount !== undefined
          ) {
            amountArr.push({
              fullIncomeObj:
              this.props.consumer.consumerData.shelterExpense[j],
              originalTypeCode:
                this.props.consumer.consumerData.shelterExpense[j].typeCode,
              code: this.props.consumer.consumerData.shelterExpense[j].dataChangeCode?this.props.consumer.consumerData.shelterExpense[j].dataChangeCode:"existing",
              unit: getShelterandChildSupportFrequency(language)[
                this.props.consumer.consumerData.shelterExpense[j].frequencyCode
              ],
              amount:this.props.consumer.consumerData.shelterExpense[j]
              .amount,
              id: "repeatable_id_" + this.nextUniqueId(),
            })
            shelterSections[0].subsections[0].subsections[0].questions[i].Answer = amountArr;
            shelterSections[0].subsections[0].subsections[0].questions[i][
              OptionsProps.TARGET_VALUE
            ] = amountArr;
            shelterSections[0].subsections[0].subsections[0].questions[i].value = amountArr;
            shelterSections[0].subsections[0].subsections[0].questions[i].completed =
              TextProps.VALUE_FALSE;
          } else {
            shelterSections[0].subsections[0].subsections[0].questions[i].Answer = [];
            shelterSections[0].subsections[0].subsections[0].questions[i][
              OptionsProps.TARGET_VALUE
            ] = [];
            shelterSections[0].subsections[0].subsections[0].questions[i].completed =
              TextProps.VALUE_FALSE;
          }
        }
      }
    }
    this.props.appendRCSections(shelterSections[0]);
    this.prepareShelterSummary();
  }



  
  processChildSupportSectionData = () => {
      let childExpExisting = _.cloneDeep(
        this.props.consumer.consumerData.childSupportExpense
      );
      let childExpNew = this.incomeSummary.childSupportIncomeMembers;
      childExpNew.forEach((newExp)=>{
        if (newExp.firstName) {
          let filtered = _.findIndex(childExpExisting, {
            firstName: newExp.firstName.split(" ")[0],
            lastName: newExp.firstName.split(" ")[1],
            dateOfBirth: newExp.firstName.split(" ")[2],
          });
          if (filtered < 0) {
              if (filtered < 0) {
            newExp.incomes[0].Answer.code = "new"
          }
          }
        }
        let newMem = newExp.firstName;
        childExpExisting.forEach((oldExp)=>{
          if(oldExp.firstName+ " " +oldExp.lastName+" "+oldExp.dateOfBirth === newMem){
              if((newExp.incomes[0].Answer.amount !== oldExp.amount || newExp.incomes[0].Answer.unit !== getShelterandChildSupportFrequency(language)[oldExp.frequencyCode]) && newExp.incomes[0].Answer.isRemoved !== true){
                newExp.incomes[0].Answer.code = "changed"
              } else if(newExp.incomes[0].Answer.isRemoved === true){
                newExp.incomes[0].Answer.code = "removed"
              } else{
                newExp.incomes[0].Answer.code = oldExp.dataChangeCode
              }
          }else if(newExp.firstName){
            let filtered = _.findIndex(childExpExisting, {
              firstName: newExp.firstName.split(" ")[0],
              lastName: newExp.firstName.split(" ")[1],
              dateOfBirth: newExp.firstName.split(" ")[2],
            });
            if(filtered < 0){
              const income = newExp.incomes[0];
              if(income.Answer){
              if (income.Answer.isRemoved) {
                income.Answer.code = "removed";
              } else if (income.Answer.code === "changed") {
                income.Answer.code = "new";
              } else {
                income.Answer.code = "new";
              }
            }
            }
          }
        })
      })
    
  }

  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };


  prepareMedicalExpensesSummary = () => {
    const medicalExpenses = this.props.consumer && this.props.consumer.consumerData;
    let medicalExpensesData = medicalExpenses.medicalExpenses;
    if(medicalExpensesData){
    let agencyIDME = medicalExpenses.agencyID;
    let updatedMedicalExpensesData = this.props.consumer.reportChangeSections[0];
    let usersData = {}
    this.medicalSummary.summaryData = []
    for(var i = 0; i < medicalExpensesData.length; i++){
      let user = [medicalExpensesData[i]["firstName"],medicalExpensesData[i]["lastName"],formatters.formatDate(medicalExpensesData[i]["dateOfBirth"])].join(' ').replace(/ +(?= )/g,'');
      if(user !== " "){
      if(!(user in usersData)){
        usersData[user] = {}
        usersData[user]["medicalExpenses"] = []
        usersData[user]["selectedOptions"] = []
      }
      Object.keys(medicalExpenseKeys).map((item) => {
        if(medicalExpensesData[i][medicalExpenseKeys[item]["flag"]]){
          usersData[user] = Object.assign(usersData[user],{"id": medicalExpensesData[i]["agencyID"],"name": user});
          usersData[user]["selectedOptions"].push(item);
          usersData[user]["medicalExpenses"].push({"amount": medicalExpensesData[i][medicalExpenseKeys[item]["amount"]],"unit": medicalExpensesData[i][medicalExpenseKeys[item]["unit"]],"typeCode": item,"desc": medicalExpenseKeys[item]["desc"],"label":medicalExpensesData[i][medicalExpenseKeys[item]["code"]]});
        }
      })
    }
    }

    if(updatedMedicalExpensesData.subsections){
      for(var i = 0; i < updatedMedicalExpensesData.subsections.length; i++){
        let updatedSummary = updatedMedicalExpensesData.subsections[i];
        let user = updatedSummary.title;
        if(!(user in usersData)){
          usersData[user] = {}
          usersData[user]["medicalExpenses"] = []
          usersData[user]["selectedOptions"] = []
          usersData[user] = Object.assign(usersData[user],{"id": agencyIDME,"name": user});
          if(updatedSummary.questions && updatedSummary.questions[0].Answer && updatedSummary.questions[0].Answer.length > 0){
            var selectedOptions = updatedSummary.subsections[0].questions
            for (var j = 0; j < selectedOptions.length; j++) {
              var ele = selectedOptions[j];
              usersData[user]["selectedOptions"].push(ele.name);
              usersData[user]["medicalExpenses"].push({"amount": ele.Answer[0].amount,"unit": ele.Answer[0].unit,"typeCode": ele.name,"desc": ele.optionDisplay,"label":"NEW"});
            }
          }
        }else{
          if(updatedSummary.questions && updatedSummary.questions[0].Answer && updatedSummary.questions[0].Answer.length > 0){
            let currentMedicalExpenses = {};
            usersData[user]["medicalExpenses"].map((exp) => {
              if(updatedSummary.questions[0].Answer.includes(exp.typeCode)){
                currentMedicalExpenses[exp.typeCode]={"amount": exp.amount,"unit": exp.unit,"desc": exp.desc, "label":exp.label}
              }
            })
            usersData[user]["medicalExpenses"] = [];
            usersData[user]["selectedOptions"] = [];
            var selectedOptions = updatedSummary.subsections[0].questions
            for (var j = 0; j < selectedOptions.length; j++) {
              var ele = selectedOptions[j];
              usersData[user]["selectedOptions"].push(ele.name);
              if(ele.name in currentMedicalExpenses){
                if(Array.isArray(ele.Answer[0])){
                  ele['Answer'] = ele.Answer.flat()
                }
                let label = currentMedicalExpenses[ele.name]["label"] === "NOCHNG" ? (currentMedicalExpenses[ele.name]["amount"] == ele.Answer[0].amount && currentMedicalExpenses[ele.name]["unit"] == ele.Answer[0].unit) ?  "NOCHNG":"CHANGE":currentMedicalExpenses[ele.name]["label"];
                usersData[user]["medicalExpenses"].push({"amount": ele.Answer[0].amount,"unit": ele.Answer[0].unit,"typeCode": ele.name,"desc": ele.optionDisplay,"label":ele.Answer[0].code});
              }else{
                usersData[user]["medicalExpenses"].push({"amount": ele.Answer[0].amount,"unit": ele.Answer[0].unit,"typeCode": ele.name,"desc": ele.optionDisplay,"label":"NEW"});
              }
            }
          }
        }
      }
    }
    for (const item in usersData) {
      this.medicalSummary.summaryData.push(usersData[item]);
    }
  }
  };




  updateMedicalExpenseDetails = (sec) => {
    this.props.appendRCSections(sec);
  }

  renderMedicalExpenseFilter = (activeId) => {
    let expenseSection = this.props.consumer.reportChangeSections[0];
    let medicalExpenseTypeTemplate = this.filterData(expenseSection.subsections,activeId);
    let index = activeId.split("-").splice(-1, 1).join()
    medicalExpenseTypeTemplate.subsections[1].selectionMade = expenseSection.selectionMade;
    medicalExpenseTypeTemplate.completed = TextProps.VALUE_TRUE;
    let medicalExpensesTemplate = _.cloneDeep(medicalExpenseTemplate.subsections[0]);
    medicalExpensesTemplate.id = medicalExpensesTemplate.id+"-"+index;
    medicalExpensesTemplate.header = medicalExpenseTypeTemplate.header;
    medicalExpensesTemplate.title = medicalExpenseTypeTemplate.title;
    let selectedOptions = medicalExpenseTypeTemplate.questions[0].Answer;
    medicalExpensesTemplate.questions = medicalExpensesTemplate.questions.filter((item) => selectedOptions.includes(item.name));
    var previousData = {};
    medicalExpenseTypeTemplate.subsections[0].questions.map((item) => {
      if(item.Answer[0].amount!=""){
        previousData[item.name] = item.Answer;
      }
    });
    medicalExpensesTemplate.questions.map((item) => {
      item.repeatedQuestion.unitLabel = incomeDynamicMultilanguageLabels(
        medicalExpenseTypeTemplate.title,
        item.optionDisplay,
        language
      ).unitLabel;
      item.repeatedQuestion.amountLabel = incomeDynamicMultilanguageLabels(
        medicalExpenseTypeTemplate.title,
        item.optionDisplay,
        language
      ).amountLabel1;
      item.canBeDeleted = TextProps.VALUE_TRUE;
      item[ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]= 
                  ReportChangePageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel + " " + item.optionDisplay;

      if(previousData[item.name]){
          if(!previousData[item.name][0]['code']){
            previousData[item.name][0]['code'] = "existing"
          }
      }
      if(item.name in previousData){
        item.Answer = previousData[item.name];
        item.targetValue = previousData[item.name];
        item.value = previousData[item.name];
      }
    })
    medicalExpenseTypeTemplate.subsections[0] = medicalExpensesTemplate;
    this.updateMedicalExpenseDetails(
      expenseSection
    );
        this.onSectionChange(medicalExpensesTemplate);
  }


  handleRemove = (event) => {
    event.preventDefault();
    let childSupportArray = _.cloneDeep(this.props.consumer.childExpScreen.tempChildExpArray);
    let isNewEntry = null
    const updatedChildSupportArray = childSupportArray.map(childSection => {
      let clientID = childSection.firstName + " " + childSection.lastName + " " + childSection.dateOfBirth;
      childSection['clientId'] = clientID;
      if (childSection.clientId === event.target.id) {
        if(childSection.id === 0){
          childSection.dataChangeCode = null;
          childSection.isRemoved=false;
          delete childSection['originalAmount']
          delete childSection['originalFrequency']
          childSection.frequency = "";
          childSection.amount = "";
          isNewEntry = childSection;
        }else{
          childSection.dataChangeCode = "REMOVE";
          childSection.isRemoved=true;
        }
      }
      return childSection;
    });
    let childExpenseSection = this.incomeSummary.childSupportIncomeMembers;
    childExpenseSection.forEach((expSec,index,object) => {
      if(expSec.clientId === event.target.id){
        if(expSec.incomes[0].Answer){
        expSec.incomes[0].Answer.isRemoved = true;
          expSec.incomes[0].Answer.code = 'removed';
        }
      }
      if(isNewEntry && isNewEntry.clientId === expSec.clientId){
        object.splice(index, 1);
        this.props.consumer.reportChangeSections[0].subsections.splice(index, 1);
      }
    });
    this.props.appendChildSupportAnswer(updatedChildSupportArray)
    this.processChildSupportSectionData();
    this.gotoChildSupportSummary();
    this.checkForDataChange(this.state.activeSectionId)
  }

  handleDone = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event);
  }
  
  // modal methods

  showSuccessModal = () => {
    this.setState({ showSuccessModal: TextProps.VALUE_TRUE });
  };

  closeSuccessModal = () => {
    this.setState({ showSuccessModal: TextProps.VALUE_FALSE });
  };

  showWarningModal = (status) => {
    this.setState({ showWarningModal: TextProps.VALUE_TRUE, dataChangeStatus: status });
  };

  closeWarningModal = () => {
    this.setState({ showWarningModal: TextProps.VALUE_FALSE,dataChangeStatus:false });
  };


  goToConsumerInfo = () =>{
    this.closeWarningModal();
    this.redirectConsumerHome();
  }

  modalContent = () => {
   
    return (
      <div style={{ padding: "10px" }}>
        {!this.state.dataChangeStatus ? <React.Fragment>
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).didNotMakeChange
          }
        </span>
        <br />
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).clickGoBack
          }
        </span>
        </React.Fragment> : <React.Fragment> 

        <span>
        {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).dataChangeWarning
          }
        </span>
        <br />
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).clickGoBack
          }
        </span>

        </React.Fragment> }
        <br />
        <div style={{ display: "flex", float: "right", padding: "10px" }}>
          <button className="dta-button dta-button--outline-primary" style={{ marginRight: "10px" }} onClick={this.closeWarningModal}>Cancel</button>
          <button className="dta-button  dta-button--primary" onClick={this.goToConsumerInfo}>Ok</button>
        </div>
      </div>
    )
  }

  renderSubmitPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.successModal = instance),
      this.state.showSuccessModal,
      this.closeSuccessModal,
      "report-change-modal",
      "mh-provider",
      false
    );
  };

  renderWarningPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.warningModal = instance),
      this.state.showWarningModal,
      this.closeWarningModal,
      "",
      "mh-provider",
      false,false,false,
      ReportChangePageLabels(
        language,
        languageConstants.aboutMe
      ).areYouSure
      ,this.modalContent(),
      true
    );
  };


  getSectionBody = (sectionId) => {
    let sectionBody = null;
    switch (sectionId) {
      case "household":
        sectionBody = this.renderHouseholdInfo();
        break;
      case "income":
      case "income-summary":
        sectionBody = this.renderIncomeSummary();
        break;
      case "child-support":
      case "childsupport-expenses":
        sectionBody = this.renderChildSupportExpenses();
        break;
      case "shelter":
      case "shelter-utility-summary":
        sectionBody = this.renderShelterExpenses();
        break;
        case "medical":
        case "medical-expenses":
        sectionBody = this.renderMedicalExpenses();
        break;
        case "authrep":
        sectionBody = this.renderAuthRep();
        break;
        case "attendance":
        sectionBody = this.renderAttendance();
        break;
         case "resources-summary":
        sectionBody = this.renderResourcesSummary();
        break;  
        case "sign-and-submit":
        return this.renderSignAndSubmit();

      default:
        sectionBody = <div>Something went wrong</div>;
    }

    return sectionBody;
  };

  renderDependentsCareCosts = (reportChangeSections) => {
    let dependentCosts = reportChangeSections[0];
    let dependentCopy = _.cloneDeep(dependentCosts);
    let parentIds = dependentCosts.questions[0].Answer;
    let parentOptions = dependentCosts.questions[0].options;

    let consumerDependantCare;
    if(this.props.consumer.consumerData.dependantCare){
      sessionStorage.setItem("consumerDependantCare", JSON.stringify(this.props.consumer.consumerData.dependantCare));
      consumerDependantCare = this.props.consumer.consumerData.dependantCare;
    }else{
      consumerDependantCare = JSON.parse(sessionStorage.getItem("consumerDependantCare"));
    }
    //Filtering Answers using IDs
    let parentMembers = [];
    let dependentMembers = [];
    if(parentIds)
    for (let parentIdValue of parentIds) {
      for (let optionValue of parentOptions) {
        if (parentIdValue === optionValue) {
          parentMembers.push(optionValue);
        }
      }
    }

    let parentNameMembers = {};
    let conditionalDependentsCount = {};
    let parentConditionals = dependentCosts.questions[0].conditionals;
    let childMembers = [];
    consumerDependantCare.map(dep=>{
      let payee = dep.payee.firstName + " " +  dep.payee.lastName + " " + formatters.formatDate(dep.payee.dateOfBirth);
      let dependent = dep.dependent.firstName + " " +  dep.dependent.lastName + " " + formatters.formatDate(dep.dependent.dateOfBirth);
      let dependentMember = payee + "-" + dependent;
      dependentMembers.push(dependentMember);
    });
    if(parentIds)
    for (var p = 0; p < parentIds.length; p++) {
      parentNameMembers[parentIds[p]] = [];
      for (let parentConditionalValue of parentConditionals) {
        if (parentConditionalValue.valueToMatch === parentIds[p]) {
          conditionalDependentsCount =
            parentConditionalValue.conditionalQuestions[0].Answer;
            if(conditionalDependentsCount){
              for (let conditionalDependentsValue of conditionalDependentsCount) {
                childMembers.push(conditionalDependentsValue);
                parentNameMembers[parentIds[p]].push(conditionalDependentsValue);
                let depMem = parentMembers[p] + "-" + conditionalDependentsValue;
                if(!dependentMembers.includes(depMem))
                dependentMembers.push(depMem);
              }
            }
        }
      }
    }

    reportChangeSections[0].dependentCare = dependentMembers;

    if (
      this.state.dependentCareCostsSubSectionCleared ===
        TextProps.VALUE_FALSE &&
      dependentCosts.length > 0
    ) {
      this.setState(
        {
          dependentCareCostsSubSectionCleared: TextProps.VALUE_TRUE,
          dependentCareCostsTemplate: dependentCosts.subsections[0],
        },
        () => {
          this.props.updateReportChangeSections([dependentCosts]);
        }
      );
    }
    dependentCosts.questions[0].value = parentIds;

    if (dependentCosts.subsections === undefined) {
      let depSubsection = _.cloneDeep(dependentCareTemplate.subsections);
      delete depSubsection[0].subsections
      dependentCosts.subsections = depSubsection;
    }
    let filteredDependentCosts = [];
    dependentCosts.subsections.map(sub =>{
      if(dependentMembers.includes(sub.dependentMember)){
        filteredDependentCosts.push(sub);
      }else if(sub.dependentMember === undefined){
        filteredDependentCosts.push(sub);
      }
    })
    dependentCosts.subsections = filteredDependentCosts;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (dependentMembers[0] !== "" && dependentMembers.length > 0) {
      for (var r = 0; r < dependentMembers.length; r++) {
        for (let memIndex = 0; memIndex < dependentMembers.length; memIndex++) {
          dependentCosts.subsections[memIndex] = _.cloneDeep(
            dependentCosts.subsections[0]
          );
          dependentCosts.subsections[memIndex].id =
            "kids-and-adults-" + [memIndex];
          dependentCosts.subsections[memIndex].title = parentMembers[0];
          dependentCosts.subsections[memIndex].payee = dependentMembers[memIndex].split("-")[0];
          dependentCosts.subsections[memIndex].header =
            childDependnMultilanguageLabels(
              dependentMembers[memIndex].split("-")[1],
              "",
              language
            ).depedentCostHeader;
          dependentCosts.subsections[memIndex].dependentMember = dependentMembers[memIndex];
          // set lables in conditionals questions.
          for (
            var q = 0;
            q < dependentCosts.subsections[memIndex].questions.length;
            q++
          ) {
            if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "units" &&
              q === 0
            ) {
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCostsQoneLabel;
            } else if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "radio" &&
              q === 1
            ) {
              /** Translation start */
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCostsQtwoLabel;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCoditionalQLabel;
              /** Translation end */
            } else if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "radio" &&
              q === 2
            ) {
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).driveToQLabel;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependConQuestion;
              if (dependentCosts.subsections[memIndex].subsections) {
                dependentCosts.subsections[memIndex].subsections[0].header =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[1],
                    "",
                    language
                  ).depedentCostHeader;
                dependentCosts.subsections[memIndex].subsections[0].title =
                  dependentMembers[memIndex].split("-")[1];
                dependentCosts.subsections[
                  memIndex
                ].subsections[0].questions[0].label =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[0],
                    "",
                    language
                  ).providerAddress;
                dependentCosts.subsections[
                  memIndex
                ].subsections[0].questions[1].label =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[0],
                    dependentMembers[memIndex].split("-")[1],
                    language
                  ).subSectionQuestionTransportLabel;
              }
            }
            if (
              childMembers.indexOf(dependentMembers[memIndex].split("-")[1]) >=
              0
            ) {
              if (
                dependentCopy.subsections &&
                dependentCopy.subsections[memIndex]
              ) {
                dependentCosts.subsections[memIndex].questions[q].Answer =
                  dependentCopy.subsections[memIndex].questions[q].Answer;
                dependentCosts.subsections[memIndex].questions[q].targetValue =
                  dependentCopy.subsections[memIndex].questions[q].targetValue;
                dependentCosts.subsections[memIndex].questions[q].value =
                  dependentCopy.subsections[memIndex].questions[q].Answer;
                if (q > 0) {
                  dependentCosts.subsections[memIndex].questions[
                    q
                  ].conditionals[0].conditionalQuestions[0].Answer =
                    dependentCopy.subsections[memIndex].questions[
                      q
                    ].conditionals[0].conditionalQuestions[0].Answer;
                  dependentCosts.subsections[memIndex].questions[
                    q
                  ].conditionals[0].conditionalQuestions[0].targetValue =
                    dependentCopy.subsections[memIndex].questions[
                      q
                    ].conditionals[0].conditionalQuestions[0].targetValue;
                  dependentCosts.subsections[memIndex].questions[
                    q
                  ].conditionals[0].conditionalQuestions[0].value =
                    dependentCopy.subsections[memIndex].questions[
                      q
                    ].conditionals[0].conditionalQuestions[0].Answer;
                  if (
                    dependentCopy.subsections[memIndex].questions[q]
                      .conditionals[0].conditionalQuestions[0].Answer !==
                      undefined &&
                    dependentCopy.subsections[memIndex].questions[q]
                      .conditionals[0].conditionalQuestions[0].Answer > 0
                  ) {
                    for (
                      let add = 0;
                      add <
                      dependentCopy.subsections[memIndex].questions[q]
                        .conditionals[0].conditionalQuestions[0].Answer;
                      add++
                    ) {
                      if (
                        dependentCopy.subsections[memIndex].subsections[
                          add
                        ] !== undefined
                      ) {
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ] = dependentCopy.subsections[memIndex].subsections[
                          add
                        ];
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[0].Answer =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[0].Answer;
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[0].value =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[0].Answer;
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[0].targetValue =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[0].targetValue;
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[1].Answer =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[1].Answer;
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[1].value =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[1].Answer;
                        dependentCosts.subsections[memIndex].subsections[
                          add
                        ].questions[1].targetValue =
                          dependentCopy.subsections[memIndex].subsections[
                            add
                          ].questions[1].targetValue;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.props.updateReportChangeSections([dependentCosts]);
    } else {
      delete dependentCosts.subsections;
      this.props.updateReportChangeSections([dependentCosts]);
    }
  };



  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      //banner and header changes
      if(Array.isArray(questionsSections) && Array.isArray(questionsSections[0].subsections) && questionsSections[0].subsections.lenth>0 && Array.isArray(questionsSections[0].subsections[0].subsections) && Array.isArray(questionsSections[0].subsections[0].subsections[0].questions) && questionsSections[0].subsections[0].subsections[0].id.includes("kids-adult-multiple-address"))
      {
        for (
          var q = 0;
          q <questionsSections[0].subsections[0].subsections.length;
          q++
        ) {
        questionsSections[0].subsections[0].subsections[q].questions[1].label= childDependnMultilanguageLabels(
          questionsSections[0].subsections[0].dependentMember.split("-")[0],
          questionsSections[0].subsections[0].dependentMember.split("-")[1],
          language
        ).dependentDriveLabel
      }
    }
      this.updateDimensions();
      const isFreeComOpen = sessionStorage.getItem("openFreeComm");

      const { common } = this.props.language.security;
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
      var loginFlag = TextProps.VALUE_FALSE;
      if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
        loginFlag = TextProps.VALUE_TRUE;
      } else {
        if (loginUserInfo !== null && loginUserInfo !== undefined) {
          if (loginUserInfo.firstName !== "") {
            loginFlag = TextProps.VALUE_TRUE;
          }
        }
      }
      var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
      var userRole = userObject.roleList[1]
        ? userObject.roleList[1].roleName
        : userObject.roleList[0].roleName;
      const msalInstance = this.props.msalContext.instance;
      if (isFreeComOpen === "true") {
      return (
        <div style={{ height: "100%" }}>
        <div className="full-height apply recert-container">
        <div style={{ display: "contents" }}>
          <DocumentTitle
            title={
              this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.title
                ? this.props.location.state.title
                : "Report A Change"
            }
          />
          {/* See applyNavigation.js for documentation on ApplyNavigation props */}
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            inProgressSectionId={this.state.inProgressSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            skipToAnchor="#content"
          />
          <main
            className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
            id="content"
            tabIndex="-1"
          >
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
            {loginFlag === TextProps.VALUE_TRUE ? (
              <div className="interim-recert__options">
                <LanguageSelect className="interim-recert__language-select" />
                {userRole !== roleProps.PROVIDER &&
                userRole !== roleProps.PROVIDER_ROLE_SPVUSR &&
                userRole !== roleProps.PROVIDER_ROLE_DCPVSG ? (
                  <div className="interim-recert__options-account">
                    <span className="interim-recert__headertext">
                      {common.hi}
                    </span>
                    &nbsp;
                    <DTADropdown
                      buttonText={userInfo.firstName}
                      buttonClass="dta-button dta-button--link"
                      menuClass="list--bordered"
                      caret={TextProps.VALUE_TRUE}
                      caretClass="color-primary"
                    >
                      <li className="pad-all--half">
                        <Link
                          to="/consumer/myInfo"
                          className="display-block"
                          onClick={this._handleApplyGoBack.bind(this)}
                        >
                          {common.home}
                        </Link>
                      </li>
                      <li className="pad-all--half border-top">
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={() =>
                                this.props.onAzureLogOut(msalInstance)
                              }
                            >
                              {common.logout}
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={this._handleApplyLogoutSession.bind(
                                this
                              )}
                            >
                              {common.logout}
                            </Link>
                          )}
                        </React.Fragment>
                      </li>
                    </DTADropdown>
                  </div>
                ) : (
                  <div className="interim-recert__options-account">
                    <div className="provider-spacer" />
                    <button
                      className="dta-button provider-return"
                      onClick={this.gotoProvier}
                    >
                      <span className="provider-return__text">
                        {common.provider}
                        <br />
                        <Icon name="home" />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <LanguageSelect className="language-select--dark" />
            )}
          </main>
          {this.renderSubmitPopup()}
          {this.renderWarningPopup()}
          {this.renderLanguageSwitchWarningModal()}
        </div>
        </div>
        </div>
      );
    }
  }
  }

  gotoProvier = () => {
    this.props.gotoProvider();
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    return getSection(sectionId,this.props.consumer.reportChangeSections)
  }

  updateDimensions() {
    let mainElems = document.getElementsByClassName("apply__content");
    let applyCont = document.getElementsByClassName("apply_free_comm");
    let appHeight = document.getElementsByClassName("app");
    let bodyTag = document.getElementsByTagName("body");
    let modalHeight = document.getElementsByClassName("dta-modal");

    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    var contentHeight = 0;
    var applyfreeCommHeight = 0;
    var modalPopupHeight = 0;

    if (bodyTag) {
      for (let elems of bodyTag) {
        if (elems.style) {
          elems.style.height = "auto";
        }
      }
    }

    if (isFreeComOpen === "false") {
      let applyCont = document.getElementsByClassName("apply_free_comm");
      let appHeight = document.getElementsByClassName("app");
      var applyfreeCommHeight = 0;

      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${applyfreeCommHeight}px`;
          }
        }
      }
    } else {
      if (mainElems) {
        for (let elems of mainElems) {
          contentHeight = elems.scrollHeight;
        }
      }
      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (modalHeight) {
        for (let elems of modalHeight) {
          modalPopupHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${
              contentHeight + applyfreeCommHeight + modalPopupHeight
            }px`;
          }
        }
      }
    }
  }

  checkForSummary = (id) => {
    switch (id) {
      case "medical-expenses":
        return true;
      case "childsupport-expenses":
        return true;
      case "shelter-utility-summary":
        return true;
      case "income-summary":
        return true;
      case "household":
        return true;
      case "resources-summary":
        return true;  
      case "attendance":
        return true;  
      default:
        return false;
    }
  }

  checkIsSectionUpdated = (payload,id,type) =>{
    let isDataChanged = false;
    if(id === "childsupport-expenses"){
      payload.childSupportExpense && payload.childSupportExpense.forEach((oneExp)=>{
        if(oneExp.dataChangeCode && oneExp.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
    }else if(id === "household"  || id === "sign-and-submit"){
      let submissionData = processHouseholdData(this.props.consumer.householdMembers?this.props.consumer.householdMembers :this.props.consumer.consumerData.householdMembers);
      submissionData && submissionData.forEach((oneExp)=>{
        if(oneExp.dataChangeCode && oneExp.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
    }else if(id === "medical-expenses"){
      payload.medicalExpenses && payload.medicalExpenses.forEach((oneExp)=>{
        for (let key in oneExp) {
          let keyLower = key.toLowerCase();
          if (keyLower.includes('datachangecode') && oneExp[key] && (oneExp[key] === "CHANGE" || oneExp[key] === "NEW" || oneExp[key] === "REMOVE")) {
            isDataChanged = true;
          }
        }
      })
    }else if(id === "shelter-utility-summary"){
      payload.shelterAnswers && payload.shelterAnswers.forEach((oneExp)=>{
        if(oneExp.dataChangeCode && oneExp.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
      if(payload && payload.utilityAnswers){
        if(payload.utilityAnswers.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      }
    }else if(id === "income-summary"){
      payload.incomeAnswer && payload.incomeAnswer.forEach((oneExp)=>{
        if(oneExp.dataChangeCode && oneExp.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
    }else if(id === "Dependentcarecosts" || id.includes('kids-')){
        if(this.state.dependentCareDataUpdated){
          isDataChanged = true;
        }
    }else if(id === "authrep"){
      if(this.state.authRepData.isAuthRepDataUpdated){
        isDataChanged = true;
      }
    }else if(id === "attendance"){
      payload.schoolAttendanceData && payload.schoolAttendanceData.forEach((oneExp)=>{
        if(oneExp.dataChangeCode && oneExp.dataChangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
    }
     else if(id === "resources-summary"){
      payload.resourceAnswer && payload.resourceAnswer.forEach((oneExp)=>{
        if(oneExp.datachangeCode && oneExp.datachangeCode !== "NOCHNG"){
          isDataChanged = true;
        }
      })
    }
   if (isDataChanged) {
      if (type !== "SUBMIT") {
        this.showWarningModal(true);
        return false;
      } else {
        return true; // type === "SUBMIT" && isDataChanged
      }
    } 
    else {
      if (type === "SUBMIT") {
        this.showWarningModal(true);
        return false;
      } 
      else {
        return true;
      }
    }
  }
  
  prepareResourcesSummary = () => {
    let resourcesSection = this.props.consumer.reportChangeSections[0];
    let houseMemberList = this.state.houseMemberLabels;
    this.resourcesSummary.resourcesMembers = [];
    this.resourcesSummary.nonResourcesMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < resourcesSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.resourcesSummary.resourcesMembers[memberIncomeIndex] = {};
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].section =
        resourcesSection.subsections[memberIncomeIndex];
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].id =
        resourcesSection.subsections[memberIncomeIndex].id;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].agencyID =
      resourcesSection.subsections[memberIncomeIndex].agencyID;  
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].firstName =
        resourcesSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        resourcesSection.subsections[memberIncomeIndex].title;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesType =
        resourcesSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources = [];
      if (
        resourcesSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs =
          resourcesSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs
            .length;
          questionIndex++
        ) {
          // if (
          //   this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
          //     questionIndex
          //   ].name !== "rental-income-hours-worked-0"
          // // ) {
            const resourceAnsFinal = this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
              questionIndex
            ].Answer;
            let resourceAns = resourceAnsFinal.map(removedRes => {
              if(Array.isArray(removedRes)){
                 let removeAnsObj = removedRes.filter(res => res.amount == null);
                 return removeAnsObj;
              } else {
                return removedRes;
              }
            }).flat();
            
            let isExist = resourceAns && resourceAns.length > 0 && resourceAns.filter(oneRes => oneRes.datachangeCode !== "NEW");
            let isChanged = resourceAns && resourceAns.length > 0  && resourceAns.filter(ans => (ans.datachangeCode && ans.datachangeCode.includes("CHANGE"))|| (ans.code && ans.code.includes("CHANGE")) || (isExist && isExist.length >= 1 && ans.datachangeCode && ans.datachangeCode.includes("NEW")));
            let isNew = resourceAns && resourceAns.length > 0 &&resourceAns.filter(ans => isExist && isExist.length < 1 && ans.datachangeCode && ans.datachangeCode.includes("NEW"));
            const dataChangeCode = (isChanged && isChanged.length > 0) ? "CHANGE" : (isNew && isNew.length > 0) ? "NEW" : "NOCHNG";
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ] = {};
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].id =
              "resourcesSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
                questionIndex
              ].agencyID =  this.resourcesSummary.resourcesMembers[memberIncomeIndex].agencyID;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].type =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].displayIncomeName;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
                questionIndex
              ].datachangeCode = dataChangeCode;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].typeCode =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].valueToMatch;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].Answer =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].Answer;
          // }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.resourcesSummary.nonResourcesMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  onResourcesSubsectionRender = async (memberTemplate = null) => {
    let resourceDetails = _.cloneDeep(resourcesSubsectionRenderHelper(memberTemplate,this.props.consumer.consumerData, resourcesSummaryTemplate, resourceSections));
    this.props.appendResourcesDetails(resourceDetails);
    this.setState({ resourcesValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareResourcesSummary();
  };

  onResourcesSubSectionClean = async () => {
    if (this.state.resourcesSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          resourcesSubSectionCleared: TextProps.VALUE_TRUE,
          resourcesMemberTemplate: _.cloneDeep(ResourcesSectionTemplate),
          resourcesSummaryTemplate:  resourceSections[0].subsections[
            resourceSections[0].subsections.length - 1
          ]
        },
        () => {
          this.props.clearMemberResources(
            this.state.resourcesMemberTemplate,
            this.state.resourcesSummaryTemplate,
            resourceSections[0]
          );
          this.onResourcesSubsectionRender();
        }
      );
    }
  };

  resourcesfilterQuestionsBySelection = (memberIndex) => {
    let resourcesSection = this.props.consumer.reportChangeSections[0];
    let resourcesPageAnswers =
    resourcesSection.subsections[memberIndex].questions[0].Answer;
    let resourcesFilteredQuestions = [];
    if (resourcesSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.resourcesSubSections[memberIndex] === undefined) {
      this.resourcesSubSections[memberIndex] = _.cloneDeep(
        resourcesSection.subsections[memberIndex]
      );
    } else {
      resourcesSection.subsections[memberIndex] = _.cloneDeep(
        this.resourcesSubSections[memberIndex]
      );
      resourcesSection.subsections[memberIndex].questions[0].Answer =
      resourcesPageAnswers;
      resourcesSection.subsections[memberIndex].questions[0].targetValue =
      resourcesPageAnswers;
    }
    this.props.consumer.reportChangeSections.currentMember = this.resourcesSubSections[memberIndex].title;
    if (
      resourcesSection.subsections[memberIndex] &&
      resourcesSection.subsections[memberIndex].subsections &&
      resourcesSection.subsections[memberIndex].subsections[0]
    ) {
      const allQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        questions;
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      resourcesPageAnswers[0] !== "" &&
      resourcesPageAnswers[0] !==
      ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      resourcesPageAnswers[0] !== undefined &&
      resourcesPageAnswers[0] !==
      ReportChangePageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      resourcesFilteredQuestions = filterQuestionsBySelection(
        resourcesPageAnswers,
        resourcesSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of resourcesFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer.length
                ? oldQuestval.Answer
                : "";
              filterQuestion.targetValue = oldQuestval.targetValue.length
                ? oldQuestval.targetValue
                : "";
              filterQuestion.value =
                oldQuestval.value && oldQuestval.value.length
                  ? oldQuestval.value
                  : "";
              let typeText =
                earnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(resourcesFilteredQuestions);
      this.props.appendResourcesDetails(resourcesSection);
    } else {
      delete resourcesSection.subsections[memberIndex].subsections;
      this.prepareResourcesSummary(() => {
        this.props.appendResourcesDetails(resourcesSection);
        this.gotoResourcesSummary();
      });
    }
  };

  generateDataChangeCode = (oldValue, newValue) => {
    if (oldValue !== newValue && newValue) {
        return "NEW";
    } else if (oldValue && !newValue) {
        return "REMOVE";
    } else {
        return "NOCHNG";
    }
}

 updateDataChangeCodes = (oldMedData, newMedData) => {
  const newMedDataMap = new Map();
  newMedData.forEach(item => newMedDataMap.set(item.agencyID, item)); 
  const excludedFields = new Set(['typeCode', 'desc', 'setID', 'id']);

  const getDataChangeCodeField = (key) => {
      const type = key.replace(/Amt|FreqCd$/, ''); 
      return `${type}DataChangeCode`; 
  };

  oldMedData.forEach(oldMedItem => {
      const newMedItem = newMedDataMap.get(oldMedItem.agencyID); 
  
      if (newMedItem) {
          Object.keys(oldMedItem).forEach(key => {
              if (oldMedItem.hasOwnProperty(key) && !excludedFields.has(key)) {
                  const oldMedValue = oldMedItem[key];
                  const newMedValue = newMedItem[key];
        
                  if (oldMedValue !== null && oldMedValue !== undefined && (newMedValue === null || newMedValue === undefined)) {
                      const dataChangeCodeField = getDataChangeCodeField(key);
                      if (dataChangeCodeField) {
                          newMedItem[dataChangeCodeField] = 'REMOVE';
                      }

                        if (key.endsWith('Amt') || key.endsWith('FreqCd')) {
                          newMedItem[key] = oldMedValue;
                      }
                  }
              }
          });
      }
  });
  return newMedData;
};


  // This is the main markup for the apply layout
  render() {
    const { reportChangeSections } = this.props.consumer;
    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);
    if (reportChangeSections && reportChangeSections.length > 0) {
      return this.renderApplyQuestions(reportChangeSections);
    } else {
      return <Loading />;
    }
  }

  
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsumerAboutMeData: getConsumerAboutMeData,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoHome: () => push(routeProps.HOME_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      displayError: () => push(routeProps.API_ERR_PATH),
      fetchSectionsByModule:fetchSectionsByModule,
      addHouseholdIntoSections:addHouseholdIntoSections,
      deleteHouseholdIntoSections:deleteHouseholdIntoSections,
      setHouseholdMembersList:setHouseholdMembersList,
      addHouseholdToList:addHouseholdToList,
      setError:setError,
      postData:postData,
      clearMemberIncome:clearMemberIncome,
      addRCIncomeSections:addRCIncomeSections,
      updateReportChangeSections: updateReportChangeSections,
      setShelterData:setShelterData,
      appendRCShelterFilteredQuestions:appendRCShelterFilteredQuestions,
      appendRCSections:appendRCSections,
      appendChildSupportAnswer:appendChildSupportAnswer,
      appendSignInSections:appendSignInSections,
      languageChanged: languageChanged,
      clearMemberResources: clearMemberResources,
      appendResourcesDetails: appendResourcesDetails
    },
    dispatch
  );
}

export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(ReportChangeContainer)
);