import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLanguage } from "./languageActions";
import { mapStateToProps } from "../rootReducer";
import PropTypes from "prop-types";
import { addStringIf } from "../../utils/components/utilities/controls";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as languageConsts from "../../utils/constants/types";
import { getCookie, setCookie } from "../../utils/helper/cookie";
import { languageCodeAction } from "../consumer/languageCodeAction";
import { langOptSel } from "../../utils/constants/constants";
import Modal from '../../utils/components/modal/dtaModal'
import { Fragment } from "react/cjs/react.production.min";
import {languageChanged} from '../applySnap/applySnapActions'
// import '../../css/_modal.scss'
// import '../../css/app.scss'

let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (selectedLanguage === null)  {
    selectedLanguage = languageConsts.ENGLISH;
  }

class LanguageSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  componentDidMount() {
    let language =
      helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
    if (language === null || language === langOptSel) {
      this.props.onGetLanguage(languageConsts.ENGLISH);
    } else {
      this.props.onGetLanguage(language);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.consumer.langPreSelection) {
      localStorage.setItem(
        "selectedLanguage",
        nextProps.consumer.langPreSelection
      );
      setCookie("selectedLanguage", nextProps.consumer.langPreSelection);
      selectedLanguage = nextProps.consumer.langPreSelection;
      window.location.reload();
    }
    if((nextProps.apply && nextProps.apply.isDelete != undefined && nextProps.apply.isDelete === true) || nextProps.apply.allowLanguageChange ===true){
      this.languageSelectRender()
    }
    if(nextProps.apply && nextProps.apply.isCancel != undefined && nextProps.apply.isCancel === true){
      selectedLanguage =selectedLanguage === languageConsts.SPANISH?languageConsts.ENGLISH:languageConsts.SPANISH

    }
    
    if (nextProps.consumer.langChangeSaved) {
      window.location.reload();
    }
  }

  languageSelectRender =() =>{
    if (sessionStorage.getItem("userInfo")) {
        let userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userDetails) {
          userDetails.languageCode = selectedLanguage;
          sessionStorage.setItem("userInfo", JSON.stringify(userDetails));
          this.props.languageCodeAction(selectedLanguage);
        }
      }else{
        window.location.reload();
      }
  }
  languageSelect = (e) => {
    selectedLanguage = e.target.value
    localStorage.setItem("selectedLanguage", e.target.value);
    setCookie("selectedLanguage", e.target.value);
    this.props.languageChanged({ value: true});
    // if(e.target.value === "select") {
    //   setCookie("selectedLanguage", languageConsts.ENGLISH);
    // } else {
    //   setCookie("selectedLanguage", e.target.value);
    // }
    
    //window.location.reload();
  };
  renderDeleteWarningModal = () => {
    return (
      <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
      <Modal
        isOpen={true}
        onclose={()=>console.log("dfhdjf")}
        // onClose={() => this.closeDeleteWarningModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText="Delere"
        // {interimWarningPopupMLanguageLabels(language).confirmDelete}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            sdjad
            {/* {interimWarningPopupMLanguageLabels(language).deleteWarningLabel} */}
          </p>
          <p className="text--small"> 
          dnskdnasjk
          </p>
          {/* {interimWarningPopupMLanguageLabels(language).deleteExitLabel} </p> */}
          <p className="text--small">
            sdjsjkdsajdhsjd
            {/* {interimWarningPopupMLanguageLabels(language).deletePopUpLabel} */}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              // onClick={() =>
              //   this.closeDeleteWarningModal(TextProps.VALUE_FALSE)
              // }
            >
              absjasdb
              {/* {interimWarningPopupMLanguageLabels(language).CancelButtonLabel} */}
            </button>
            <button
              className="dta-button dta-button--primary"
              // onClick={() => this.closeDeleteWarningModal(TextProps.VALUE_TRUE)}
            >
              dsdbsdbsjd
              {/* {interimWarningPopupMLanguageLabels(language).deleteButtonLabel} */}
            </button>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      </div>
    );
  };

  render() {
    const languages = [
      {
        value: languageConsts.ENGLISH,
        display: "English",
      },
      {
        value: languageConsts.SPANISH,
        display: "Spanish",
      },
    ];

    let languageSelection;
    if (this.props.provider.languageFlag === "EnglishOnly") {
      languageSelection = (
        <div className="language-select-English-only" aria-label="Translate">
          {"English"}
        </div>
      );
    } else if (this.props.provider.languageFlag === "EnglishOnly_Greeter") {
      languageSelection = (
        <div
          style={{ marginTop: "0.5rem" }}
          className="language-select-English-only"
          aria-label="Translate"
        >
          {"English"}
        </div>
      );
    } else {
      languageSelection = (
        <select
          onChange={this.languageSelect}
          className={addStringIf(this.props.className, "language-select")}
          aria-label="Translate"
          value={selectedLanguage}
        >
          {languages.map((lang) => {
            return (
              <option key={lang.value} value={lang.value}>
                {lang.display}
              </option>
            );
          })}
        </select>
      );
    }

    return languageSelection;
  }
}

// Mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onGetLanguage: getLanguage,
      languageCodeAction: languageCodeAction,
      languageChanged:languageChanged
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
