export function providerReducer(state = {}, action) {
  switch (action.type) {
    case "GET_BENEFIT_FORM_REQUESTED": {
      const newState = Object.assign({}, state);
      newState.benefitDetails = action.payload;
      return newState;
    }
    case "PROVIDER_ACCOUNT_FULFILLED": {
      const newState = Object.assign({}, state);
      newState.providerData = action.payload;
      return newState;
    }
    case "PROVIDER_MYCLIENTS_INFO_DATA": {
      const newState = Object.assign({}, state);

      newState.providerMyClients = action.payload.clientsList;
      newState.clientFilters = action.payload.applicationsCount;
      return newState;
    }
    case "PROVIDER_NO_LANGUAGE_SELECT": {
      const newState = Object.assign({}, state);
      newState.languageFlag = "EnglishOnly";
      return newState;
    }
    case "PROVIDER_NO_LANGUAGE_SELECT_GREETER": {
      const newState = Object.assign({}, state);
      newState.languageFlag = "EnglishOnly_Greeter";
      return newState;
    }

    case "PROVIDER_LANGUAGE_SELECT": {
      const newState = Object.assign({}, state);
      newState.languageFlag = "MultiLanguage";
      return newState;
    }

    case "CONSUMER_SEARCH_FULFILLED": {
      const newState = Object.assign({}, state);
      newState.clientDetail = action.payload;
      return newState;
    }
    case "CONSUMER_SEARCH_FAILED": {
      const newState = Object.assign({}, state);
      newState.clientDetail = {};
      if (action.payload !== undefined) {
        if (action.payload.response !== undefined) {
          newState.clientDetailFailedMessage = action.payload.response.data;
        }
      }
      return newState;
    }
    case "NETWORK_FAILED_LOAD_DATA": {
      const newState = Object.assign({}, state);
      newState.error = {
        ...newState,
        error: { isError: true, code: action.payload },
      };
      return newState;
    }
    case "@@redux/INIT": {
      let newState = Object.assign({}, state);
      newState.providerMyClients = [];
      newState.clientFilters = { options: [] };
      return newState;
    }
    default:
      return Object.assign({}, state);
  }
}
