import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import Radio from "../../../utils/components/radio";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import { formatMoney } from "../../../utils/components/utilities/formatters";
import {
  setDataChangeStatus,
  setHouseholdMembers,
  setObligationChildSupportChangeStatus,
  setCourtOrderedChildSupportChangeStatus,
  appendChildSupportDetails,
  clearMemberIncome,
  setChildSupportData
} from "../../../redux/recertInterimReport/recertInterimReportActions";
import { expenseChildSupportTemplate } from "../recertInterimReportMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import { interimReportPageLabels } from "../recertInterimReportText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { ENGLISH } from "../../../utils/constants/types";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === languageConstants.langOptSel) {
    language = ENGLISH;
  }

class ChildSupportExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      supportSummary: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addChildSupportMembersOpen: TextProps.VALUE_FALSE,
      isObligationChildSupportChanged: TextProps.VALUE_FALSE,
      isCourtOrderedChildSupportChanged: TextProps.VALUE_FALSE,
      subsectionCleared: TextProps.VALUE_FALSE,
      memberTemplate: null,
      summaryTemplate: null,
      valuesPopulated: TextProps.VALUE_FALSE,
      tempChildExpArray:[],
      dataQuestionChanged:null,
    };
    enableUniqueIds(this);
  }

  componentWillReceiveProps() {
      this.setState(
        {
        sections: this.props.interimReport.sections,
        respData: this.props.interimReport.responseObject,
        },
        () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.interimReport.setOldChildSupportData === TextProps.VALUE_TRUE) {
      this.onObligationChildSupportChanged(
        interimReportPageLabels(
          language,
          languageConstants.aboutMe,
          null,
          null,
          null
        ).optionYesNo[1]
      );
    }
  }


  componentDidMount() {
    let tempExistList = [];
    let tempNonExistList = [];

    if (this.props.interimReport.childExpScreen) {
    
    } else {
      this.setInitialData();
    }
  }

  setInitialData = () => {
    let childExpMembersNames = [];
    let tempExistList = [];
    let tempNonExistList = [];
    let childExpResponseObj = _.cloneDeep(
      this.props.interimReport.responseObject.childSupportExpense
    );
    if (childExpResponseObj && childExpResponseObj.length > 0) {
      childExpResponseObj.map((child) => {
        child.dataChangeCode = child.dataChangeCode;
        child.isOpen = TextProps.VALUE_FALSE;
        child.originalAmount = child.amount + "";
        child.originalFrequency = child.frequency;
        child.isComplete = TextProps.VALUE_TRUE;
        child.clientId= child.firstName+ " "+ child.lastName+child.dateOfBirth;
        tempExistList.push(child);
        childExpMembersNames.push(
          child.firstName + " " + child.lastName + child.dateOfBirth
        );
      });

      this.props.interimReport.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode) return;
        let memName =
          member.firstName + " " + member.lastName + member.dateOfBirth;

        if (memName.indexOf(childExpMembersNames) === -1) {
          let child = {
            id: 0,
            setID: 0,
            agencyID: member.agencyID,
            firstName: member.firstName,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth,
            typeCode: "CHDSPT",
            frequencyCode: "",
            amount: "",
            dataChangeCode: null,
            isOpen: TextProps.VALUE_FALSE,
            isComplete: TextProps.VALUE_FALSE,
            clientId:member.firstName+ " "+ member.lastName+member.dateOfBirth
          };
          tempNonExistList.push(child);
        }
      });

      let tempChildExpArray = _.concat(tempExistList, tempNonExistList);

      this.setState(
        {
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
    } else {
      this.props.interimReport.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode === "removed") return;

        let child = {
          id: 0,
          setID: 0,
          agencyID: member.agencyID,
          firstName: member.firstName,
          lastName: member.lastName,
          dateOfBirth: member.dateOfBirth,
          typeCode: "CHDSPT",
          frequencyCode: "",
          amount: "",
          dataChangeCode: null,
          isOpen: TextProps.VALUE_FALSE,
          isComplete: TextProps.VALUE_FALSE,
          clientId:member.firstName+ " "+ member.lastName+member.dateOfBirth
        };
        tempNonExistList.push(child);
      });

      let tempChildExpArray = tempNonExistList;

      this.setState(
        {
          childExpChanged: this.state.dataQuestionChanged,
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
    }
  };


  childSupportSummaryEditClick = (summary) => {
    const childSupportSummary = summary.incomes;
    childSupportSummary.forEach(childSummary => {
      if(childSummary.Answer &&  childSummary.Answer.code === "removed" && childSummary.Answer.isRemoved) {
        childSummary.Answer.isRemoved = false;
        childSummary.Answer.code = 'changed';
      }
    });
    this.setState({ supportSummary: TextProps.VALUE_TRUE });
    this.props.onSectionChange(summary.section);
  };

  toggleAddChildSupportMembers = () => {
    this.setState({ addChildSupportMembersOpen: !this.state.addChildSupportMembersOpen });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
    } else {
        return TextProps.VALUE_FALSE; // keep on searching
    }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
            }
            }
            }
    return null;
            }

  onSubsectionRetain = () => {
    if (this.state.subsectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
                {
          subsectionCleared: TextProps.VALUE_TRUE,
          memberTemplate: _.cloneDeep(expenseChildSupportTemplate),
          summaryTemplate:
            this.props.interimReport.sections[5].subsections[
              this.props.interimReport.sections[5].subsections.length - 1
            ],
        },
        () => {
          // this.props.clearMemberIncome(
          //   this.state.memberTemplate,
          //   this.state.summaryTemplate,
          //   this.props.interimReport.sections[5],
          //   TextProps.VALUE_FALSE
          // );
          this.props.onChildSupportSubsectionRender(
            this.state.memberTemplate
          );
                }
      );
          }
  };

  onObligationChildSupportChanged = (value) => {
    this.setState({isObligationChildSupportChanged: value});
    if (
      value ===
                    interimReportPageLabels(
                      language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1]
    ) {
      this.setState(
        {
          valuesPopulated: TextProps.VALUE_FALSE,
          subsectionCleared: TextProps.VALUE_FALSE,
        },
        () => {
          // this.onSubsectionRetain();
          this.props.setObligationChildSupportChangeStatus(TextProps.VALUE_FALSE);
          this.props.interimReport.obligationChildSupportChanged = TextProps.VALUE_FALSE;
          if(this.props.interimReport.obligationChildSupportChanged != undefined )
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 5);
        }
    );
    } else {
        this.props.setObligationChildSupportChangeStatus(TextProps.VALUE_TRUE);
        this.props.interimReport.obligationChildSupportChanged = TextProps.VALUE_TRUE;
        if(this.props.interimReport.obligationChildSupportChanged != undefined )
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 5);
  }
  };

  onCourtOrderedChildSupportChanged = (value) => {
    this.setState({isCourtOrderedChildSupportChanged: value});
          if (
      value ===
      interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1]
              ) {
      this.setState(
        {
          valuesPopulated: TextProps.VALUE_FALSE,
          subsectionCleared: TextProps.VALUE_FALSE,
        },
        () => {
          // this.onSubsectionRetain();
          this.props.setCourtOrderedChildSupportChangeStatus(TextProps.VALUE_FALSE);
          this.props.interimReport.courtOrderedChildSupportChanged = TextProps.VALUE_FALSE;
        }
                );
    } else {
        this.props.setCourtOrderedChildSupportChangeStatus(TextProps.VALUE_TRUE);
        this.props.interimReport.courtOrderedChildSupportChanged = TextProps.VALUE_TRUE;
    }
  };

  renderChildSupportAddNewMember = (name) => {
    var childSupportTemplate = _.cloneDeep(expenseChildSupportTemplate);
    if (childSupportTemplate.questions === undefined) {
      childSupportTemplate = expenseChildSupportTemplate;
              }
    var childSupportCopy = this.props.interimReport.sections[5];
    let childSupportSubsectionCopy = childSupportCopy.subsections;

    let childSupportSummaryCopy = _.cloneDeep(
      childSupportCopy.subsections[childSupportCopy.subsections.length - 1]
    );
    childSupportCopy.subsections.splice(childSupportCopy.subsections.length - 1, 1);

    childSupportTemplate.id = "child-support-amount-" + childSupportCopy.subsections.length;
    childSupportTemplate.header = interimReportPageLabels(
      language,
      languageConstants.childSupportDynamic,
      name,
      null,
      null
    ).heading;
    childSupportTemplate.questions[0][Props.LABEL] =  interimReportPageLabels(
      language,
      languageConstants.childSupportDynamic,
      name,
      null,
      null
    ).mainQuestion;

    childSupportTemplate.questions[0].Answer = "";
    childSupportTemplate.questions[0].value = "";
    childSupportTemplate.questions[0].targetValue = "";
    childSupportTemplate.title = name;
    childSupportTemplate.clientId = name;
    childSupportTemplate.questions[0].clientId = name;
    childSupportTemplate.questions[0].enabledClickAction =  (event) => this.props.handleRemove(event);
    childSupportTemplate.questions[0].enabledDoneAction =  (event) => this.props.handleDone(event);
    childSupportSubsectionCopy.push(_.cloneDeep(childSupportTemplate));
    childSupportSubsectionCopy.push(childSupportSummaryCopy);
    childSupportCopy.subsections = [];
    childSupportCopy.subsections = childSupportSubsectionCopy;
    // this.props.appendChildSupportDetails(childSupportCopy);
    this.props.prepareChildSupportSummary();
    setTimeout(()=>{
    this.props.onSectionChange(childSupportTemplate);
    },50)
  };

  getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case 'Week':
      case'WEEK':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).week;
        return shelterExpenseFrequency;
      case 'Month':
      case 'MONTH':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'Year':
      case 'ANNUAL':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;

      default: break;
    }
    }


  renderChildSupportSummaryItem = (summary) => {
    let summIncome= summary.incomes[0].Answer;
  let label = "";
    if (summIncome.code !== undefined) {
      if (summIncome.code === "new" || summIncome.code === "NEW") {
        label = "newLabel";
      } else if (summIncome.code === "changed" || summIncome.code === "CHANGE") {
        label = "changedLabel";
      } else if(summIncome.code === "removed" || summIncome.code === "REMOVE"){
        label = "removedLabel";
      }
    }
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <span
          className="interim-recert-heading pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
            >
          <HelpTip
            textBefore={
              this.props.getSummaryTitle (
                summary.firstName,
                  interimReportPageLabels(
                    language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                  interimReportPageLabels(
                    language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
                }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </span>
        <div>
          <span className="interim-recert-heading apply__body-header help-text-fix">
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).expenseChsupport
            }
          </span>
          {label !== "" ?
            <DataChangeIndicator
              text={
                interimReportPageLabels(
                  language,
                  languageConstants.householdStatic,
                  null,
                  null,
                  null
                )[label]
              }
            /> : null
                }
      </div>

        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
        {
        (this.props.interimReport.obligationChildSupportChanged === TextProps.VALUE_TRUE || 
         this.props.interimReport.courtOrderedChildSupportChanged === TextProps.VALUE_TRUE) ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.childSupportSummaryEditClick(summary)}
          >
            {summIncome.code === 'removed' ? 
              interimReportPageLabels(
                language,
              languageConstants.householdStatic
            ).undoButtonLabel
             :
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}
        <ul>
          {summary.incomes.map((incomeItem) => {
            if (
              incomeItem.Answer !== undefined 
            ) {
              let answer = incomeItem.Answer;
                if (answer.unit && answer.amount) {
                  return (
                    <li key={answer.id}>
                      <span className="pure-u-12-24">
                      {answer.unit + ' ' + 'amount'}
                      </span>
                        <span className="pure-u-12-24">
                        {"$" + answer.amount}
          </span>
                    </li>
                  );
              }
                }
          })}
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;
    if (getFrequencyCode(language)[unit] === "WEEK") {
      amountIterator = 4;
      classRowCount = 24;
    } else if (getFrequencyCode(language)[unit] === "BIWEEK") {
      amountIterator = 2;
      classRowCount = 24;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      if (amount[i]) {
        amountRows.push(
          <div className={"pure-u-" + classRowCount + "-24"}>
            <span>
              {amount !== undefined &&
              amount[i] !== undefined &&
              amount[i] !== ""
                ? formatMoney(amount[i])
                : ""}
            </span>
          </div>
    );
    }
      }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-11-24 pure-u-sm-18-24 pure-u-md-18-24 pure-u-lg-18-24">
          <span className="label-text-clr" style={{ wordBreak: "break-word" }}>
            {getFrequency(language)[getFrequencyCode(language)[unit]]}{" "}
      {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).amountLabel
      }
          </span>
        </div>
        <div
          className={"pure-u-8-24 pure-u-sm-6-24 pure-u-md-6-24 pure-u-lg-6-24"}
        >
          {amountRows}
        </div>
        <hr className="divider" />
      </div>
    );
  };

  returnObligationChildSupportLabel() {
      if (
      (this.props.interimReport.obligationChildSupportChanged === TextProps.VALUE_TRUE)
      ) {
      return interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
        if (
        (this.props.interimReport.obligationChildSupportChanged === TextProps.VALUE_FALSE)
      ) {
        return interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1];
      } else {
        return null;
      }
    }
      }

  returnCourtOrderedChildSupportLabel() {
      if (
      (this.props.interimReport.courtOrderedChildSupportChanged === TextProps.VALUE_TRUE)
      ) {
      return interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
      if (
        (this.props.interimReport.courtOrderedChildSupportChanged === TextProps.VALUE_FALSE)
      ) {
        return interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  renderHouseHoldHeadSummary = () =>{
    let HOH=[];
    let summary={};
    if(this.props.interimReport.householdMembers && this.props.interimReport.householdMembers.membersList){
      HOH = this.props.interimReport.householdMembers.membersList.filter((mem)=>{
        return mem.granteeFlag
    })
    summary = HOH[0]
    }
    let summaryTitle = summary.firstName + " " + summary.lastName + summary.dateOfBirth
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getSummaryTitle (
                summaryTitle,
                  interimReportPageLabels(
                    language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                  interimReportPageLabels(
                    language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
                }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        {
        (this.props.interimReport.obligationChildSupportChanged === TextProps.VALUE_TRUE || 
          this.props.interimReport.courtOrderedChildSupportChanged === TextProps.VALUE_TRUE)  ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.renderChildSupportAddNewMember(summaryTitle)}
          >
            {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel}
          </button>
        ) : null}
    
        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
  
       <p>
      { interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).nochildsupportPaymentText
       }
       </p>
      </div>
    );
  }


  render() {
    var listOfUpdatedHHMembers = [];
    var existingChildSupportMembers = [];
    let addChildSupportMembersActive = "";
    this.props.interimReport.householdMembers.membersList.map((memberHH) => {
      // if (memberHH.dataChangeCode !== undefined) return;
      var childSupportHouseHoldName = memberHH.firstName;
      var childSupportCombinedName = "";
      if (childSupportHouseHoldName !== "") {
        childSupportCombinedName = childSupportHouseHoldName + " " + memberHH.lastName;
        let childSupportHouseHoldDOB = memberHH.dateOfBirth;
        if (memberHH.dateOfBirth !== "") {
          childSupportCombinedName = childSupportCombinedName + childSupportHouseHoldDOB;
        }
      } else if (childSupportHouseHoldName === "") {
        childSupportCombinedName = memberHH.firstName;
      }
      listOfUpdatedHHMembers.push(childSupportCombinedName);

      if (this.props.incomeSummary.childSupportIncomeMembers !== undefined) {
        this.props.incomeSummary.childSupportIncomeMembers.map((mem) => {
          existingChildSupportMembers.push(mem.firstName);
        });
      }
        });
    if (this.state.addChildSupportMembersOpen) {
      addChildSupportMembersActive = "is-active";
      }
      return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.props.incomeSummary && this.props.incomeSummary.childSupportIncomeMembers.length > 0 ? this.props.incomeSummary.childSupportIncomeMembers.map((summary) => this.renderChildSupportSummaryItem(summary)) : this.renderHouseHoldHeadSummary()}
         {/* {this.renderChildSupportSummaryItem(this.props.interimReport.sections[5].subsections[0])} */}
        
        <hr className="divider" />
        <span>
          {interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSupportExpenses_Question}
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {(this.props.interimReport.sections[5].selectionMade === TextProps.VALUE_FALSE && this.props.interimReport.obligationChildSupportChanged == undefined) && (
                <span className="dta-form__error-message">
          {
            interimReportPageLabels(
              language,
                      languageConstants.contactInfo,
                      null,
                      null,
                      null
                    ).requiredErrorMessage
          }
                </span>
              )}
              <Radio
                id="earned-income"
                name="earned-income-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
              interimReportPageLabels(
                language,
                    languageConstants.aboutMe,
                    null,
                    null,
                    null
                  ).optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnObligationChildSupportLabel()}
                onChange={(value) => this.onObligationChildSupportChanged(value)}
              />
            </fieldset>
          </div>
        </div>
        <br />
        <br />
        <span>
        {interimReportPageLabels(language, languageConstants.earnedIncomeStatic).courtOrderedChildSupport}
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {/* {(this.props.interimReport.sections[5].selectionMade === TextProps.VALUE_FALSE && this.props.interimReport.courtOrderedChildSupportChanged == undefined) && (
                <span className="dta-form__error-message">
            {
              interimReportPageLabels(
                language,
                      languageConstants.contactInfo,
                    null,
                    null,
                    null
                    ).requiredErrorMessage
                  }
                </span>
              )} */}
              <Radio
                id="earnings"
                name="earnings-changed"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(
                    language,
                        languageConstants.aboutMe,
                        null,
                        null,
                        null
                      ).optionYesNo
                }
                // required={TextProps.VALUE_TRUE}
                value={this.returnCourtOrderedChildSupportLabel()}
                onChange={(value) => this.onCourtOrderedChildSupportChanged(value)}
              />
            </fieldset>
            {(this.props.interimReport.courtOrderedChildSupportChanged && this.props.interimReport.courtOrderedChildSupportChanged === TextProps.VALUE_TRUE)
          ? (
          <div>
        <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${addChildSupportMembersActive}`}
              onClick={this.toggleAddChildSupportMembers}
              aria-expanded={this.state.addChildSupportMembersOpen}
        >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSupport_AddHouseHold}
        </button>
            <Collapse isOpened={this.state.addChildSupportMembersOpen}>
              <span className="add-income-summary__label" />
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingChildSupportMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
      return (
                    <li key={member.id}>
                      {/*
                      Clicking the following button routes the user to a
                      new child Income section to fill out income info for this
                      household member (e.g. "Listing John's Income"). When user
                      is finished and clicks 'Continue', they will be returned to Income Summary page
                    */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.renderChildSupportAddNewMember(member);
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
        >
                        <span className="sr-only">Add income for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        ) : null}
          </div>
          <br/>
          <span>
        {interimReportPageLabels(language, languageConstants.earnedIncomeStatic).provideProof}
        </span>
            </div>
        </div>
      );
    }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberIncome: clearMemberIncome,
      appendChildSupportDetails: appendChildSupportDetails,
      setHouseholdMembers: setHouseholdMembers,
      setDataChangeStatus: setDataChangeStatus,
      setObligationChildSupportChangeStatus: setObligationChildSupportChangeStatus,
      setCourtOrderedChildSupportChangeStatus: setCourtOrderedChildSupportChangeStatus,
      setChildSupportData:setChildSupportData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildSupportExpenses);
