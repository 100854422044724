import React from 'react';
import DescriptionList from './DescriptionList';
import { uniqueId } from "lodash";

const SectionSummary = ({section, isSubsection, applicationType, authorizedRepresentatives, ebtAuthRepFlg, snapAuthRepFlg, snpStateFlg, ebtStateFlg, householdInformation}) => {
    const { header, title, items, subsections, completed, sectionId } = section;
    return(
        <React.Fragment>
            <DescriptionList title={isSubsection?title:header} list={items} completed={completed} applicationType={applicationType} authorizedRepresentatives={authorizedRepresentatives} sectionId={sectionId} ebtAuthRepFlg={ebtAuthRepFlg} snapAuthRepFlg={snapAuthRepFlg} snpStateFlg={snpStateFlg} ebtStateFlg={ebtStateFlg} householdInformation={householdInformation}/>
            {subsections && subsections.map(subsection => {
                    return <SectionSummary section={subsection} isSubsection={true} key={uniqueId()} applicationType={applicationType} authorizedRepresentatives={authorizedRepresentatives} sectionId={sectionId} ebtAuthRepFlg={ebtAuthRepFlg} snapAuthRepFlg={snapAuthRepFlg} snpStateFlg={snpStateFlg} ebtStateFlg={ebtStateFlg} householdInformation={householdInformation}/>
            })
            
        } 
        </React.Fragment>
     
    );
}

export default SectionSummary;