import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
import { MONTHSHORT } from "../../utils/constants/controls";
import * as roleProps from "../../utils/constants/rolesConstants";
import DTADropdown from "../../utils/components/dtaDropdown";
import { getAudit } from "../../utils/constants/audit";
import * as languageConstants from "../../utils/constants/constants";
import * as TextProps from "../../utils/constants/text";
// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import SelfDeclareSection from "./components/selfDeclareSection";
import {
  selfDecExpensSubsections,
  shelterCostDetailTemplate,
  dependentCareTemplate,
} from "./selfdeclareMapData";
import {
  getFrequencyCode,
  getFrequency,
} from "../../utils/components/utilities/utils";
// Redux implementations
import { connect } from "react-redux";
import {
  appendDeclareExpenseSubsections,
  setSDDataChangeStatus,
  applySanpQuestionsSections,
  appendIncomeDetailsSelf,
  appendExpenseDetails,
  downloadPDFData,
  updateCurrentSectionData,
  postSelfDeclareData,
} from "../../redux/selfdeclare/selfDeclareActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  filterQuestionsBySelection,
  toValidateInputErros,
  bindAnswers,
} from "./selfdeclareHelper";

//azure
import { withMsal } from "@azure/msal-react";

//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import SubmitSummary from "./components/submitSummary";
import SignAndSubmit from "./components/signAndSubmit";
import {
  interimReportPageLabels,
  selfDeclaredependentCareLabels,
} from "../recertInterimReport/recertInterimReportText";
import {
  selfDeclarePageLabels,
  getFrequencyCodeSelfDeclare,
} from "./selfdeclareText";
import {
  formatMoney,
  formatDate,
} from "../../utils/components/utilities/formatters";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as types from "../../utils/constants/types";
import * as config from "../../utils/constants/appConfigConstants";

const childSupport = "childSupport";
var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);
//end code for google analytics
var dependentMembers = [];
const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

// For tesing resume apply feautre
var resumeApply = TextProps.VALUE_FALSE;

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "self-declare-expenses",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class SelfDeclare extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.downloadApplicationAsPdf = this.downloadApplicationAsPdf.bind(this);
    this.designPdfAsHTMLTemplate = this.designPdfAsHTMLTemplate.bind(this);
    this.updateHouseHoldMedicalDetails =
      this.updateHouseHoldMedicalDetails.bind(this);
    this.downloadPDFData = this.downloadPDFData.bind(this);
    this.dependentsCareCostsSubSections = [];
    this.providerAddressTemplate = [];
    this.housingCostsSubSections = [];
    this.frequencyCode = {
      Month: "Monthly",
      Week: "Weekly",
      "Every two weeks": "Bi-weekly",
      Year: "Yearly",
    };
    this.householdMembers = [];
    this.state = {
      reportedChanges: TextProps.VALUE_FALSE,
      referenceNumber: null,
      requestBody: {},
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      selfDecExpensSubsectionsTemplate: selfDecExpensSubsections,
      shelterCostDetailTemplate: shelterCostDetailTemplate,
      houseHoldSubMemberTemplate: null,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: null,
      incomeSubSectionCleared: TextProps.VALUE_FALSE,
      houseMemberLabels: [],
      dependentsParentsLabels: [],
      dependentsChildsLabels: [],
      dependentsCareCostsMemberLabels: [],
      dependentCareCostsTemplate: null,
      dependentCareCostsSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseMemberTemplate: null,
      kidsAdultExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseMemberTemplate: null,
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      rights: 0,
      signature: "",
      AnswerObject: [],
      oldHouseHoldSizeValue: 0,
      //For contact info screens
      email: "John.doe@example.com",
      phoneNumber: "(413) 444-4444",
      emailOptIn: TextProps.VALUE_TRUE,
      textOptIn: TextProps.VALUE_FALSE,
      editPhone: TextProps.VALUE_FALSE,
      editEmail: TextProps.VALUE_FALSE,
      editAddress: TextProps.VALUE_FALSE,
      sameMailingAddress: TextProps.VALUE_FALSE,
      //For household info screens
      addHouseholdMember: TextProps.VALUE_FALSE,
      houseHoldMembers: [
        {
          id: 1,
          firstName: "John",
          lastName: "Smith",
          dob: "16.Aug.1982",
          isGrantee: TextProps.VALUE_TRUE,
          citizenship: "Legal permanent resident",
        },
        {
          id: 2,
          firstName: "Mary",
          lastName: "Smith",
          dob: "04.July.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Immigrant worker (H1B)",
        },
        {
          id: 3,
          firstName: "Bob",
          lastName: "Smith",
          dob: "01.Feb.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Noncitizen",
        },
      ],
      earnedIncomeChanged: TextProps.VALUE_FALSE,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
    };
  }

  componentWillMount = () => {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var recertFlag, benifitListInfo;
    if (
      userObject !== null &&
      userObject !== undefined &&
      userDetails !== null &&
      userDetails !== undefined
    ) {
      benifitListInfo = userDetails.benefitInfo;
      if (userObject && benifitListInfo) {
        for (let listType of benifitListInfo) {
          if (
            (listType.alertMessages.SNAP_FLAG &&
              listType.alertMessages.SNAP_FLAG === "Yes" &&
              listType.alertMessages.RECERT_TYPE === "IR") ||
            (listType.alertMessages.SNAP_FLAG &&
              listType.alertMessages.SNAP_FLAG === "Yes" &&
              listType.alertMessages.RECERT_TYPE === "COVIDRECERT")
          ) {
            recertFlag = "Yes";
          }
        }
        var userRole = userObject.roleList[1]
          ? userObject.roleList[1].roleName
          : userObject.roleList[0].roleName;
      }
    }
    if (
      userObject !== null &&
      recertFlag === TextProps.VALUE_YES &&
      (userRole === roleProps.CONSUMER ||
        userRole === roleProps.PROVIDER ||
        userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
        userRole === roleProps.PROVIDER_ROLE_DCPVSG)
    ) {
      //continue
    } else {
      this.props.gotoConsumer();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    let check =
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      this.props.match.params.webAppId !== undefined;
    if (check === TextProps.VALUE_TRUE) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }
    if (
      nextState.sections[0] !== undefined &&
      nextState.sections[0].isSubmitInitiated &&
      nextState.sections[0].isSubmitted
    ) {
      return this.state;
    }
    return check;
  }

  componentWillReceiveProps() {
    if (this.props.selfDeclare.sections.length > 0) {
      this.setState(
        {
          sections: this.props.selfDeclare.sections,
        },
        () => {
          if (this.state.renderedSections.length < 1) {
            this.setState({
              renderedSections: [this._getSection(pageConfig.activeSectionId)],
              submitFormId: this.nextUniqueId(),
            });
          }
        }
      );
    }
  }

  // redux apply sections loading
  componentDidMount() {
    let memberList = [];
    if (sessionStorage.getItem("householdMembers") !== null) {
      memberList = JSON.parse(sessionStorage.getItem("householdMembers"));
    }
    this.householdMembers = memberList;
    if (this.props.match.params.webAppId !== undefined) {
      this.props.getSaveMyProgessDataByWebAppId(
        this.props.match.params.webAppId
      );
    } else {
      this.props.applySanpQuestionsSections();
    }
  }

  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      helperFunction._animatedScroll();
    }
  }

  onSectionChange = (section, event = null) => {
    if (section.disabled === TextProps.VALUE_TRUE) {
      return;
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);
    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });
      }
    );
  };

  //Get Language and Appened to Answer
  setLanguageInScreen = () => {
    let currentLanguageCode = this.props.selectedLanguage;
    let currentLanguage;
    let selectedAnswer =
      this.props.selfDeclare.sections[1].subsections[2].questions[1].Answer;
    if (selectedAnswer === undefined) {
      switch (currentLanguageCode) {
        case types.ENGLISH:
          currentLanguage = "English";
          break;
        case types.SPANISH:
          currentLanguage = "Spanish";
          break;
        case types.PORTUGUESE:
          currentLanguage = "Portuguese";
          break;
        case types.CHINESE:
          currentLanguage = "Chinese";
          break;
        case types.VIETNAMESE:
          currentLanguage = "Vietnamese";
          break;
        default:
          currentLanguage = "English";
          break;
      }
      this.props.selfDeclare.sections[1].subsections[2].questions[1].Answer =
        currentLanguage;
      this.props.selfDeclare.sections[1].subsections[2].questions[1].targetValue =
        currentLanguage;
    }
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  _dynamicSectionIdMatch = (activeId) => {
    switch (activeId) {
      case "my-household":
        return TextProps.VALUE_TRUE;

      case "income":
        return TextProps.VALUE_TRUE;
      default:
        return TextProps.VALUE_FALSE;
    }
  };

  disableSelfDeclareSections = () => {
    let sections = this.props.selfDeclare.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.selfDeclare.sections = sections;
    }
  };

  // Section Link click continue
  onSectionLinkClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.selfDeclare;
    const nextSectionID = getNextSection(activeId, sections);
    if (nextSectionID) {
      const nextSection = this._getSection(nextSectionID);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
    }
  };

  //Primary Continue Click
  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    //Dal Reference
    let dalReference = this.props.selfDeclare.dalReference;
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }

    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      let isValid = toValidateInputErros(dataSections, activeId);

      if (
        isValid === TextProps.VALUE_FALSE ||
        incomingQuestions.selectionMade === null
      ) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        return;
      } else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          //Clearing HouseHold Cache Template
          case "optional":
            this.onHouseHoldSubSectionClean();
            break;
          //clearning Income Template
          case "household-details":
            this.onIncomeSubSectionClean();
            break;
          //clearning Medical Expense Tempalte
          case "child-support":
            this.renderMedicalExpenseSubTemplates();
            break;
          //Rendering Dynamic Household Members
          case "my-household":
            this.onHouseHoldSubsectionRender(dataSections.questions[0].Answer);
            break;
          //Rendering Dynamic Income Template for Members
          case "income":
            this.onIncomeSubsectionRender(dataSections.questions[0].Answer);
            break;
          case "self-declare-expenses":
            this.renderSelfDeclareExpensesSubSections(
              dataSections.questions[3].Answer
            );
            break;
          case "shelter-costs":
            this.renderShelterCostsBySelection(dataSections);
            break;
          case "shelter-cost-detail":
            this.renderDependentCareSection();
            break;
          //Clearing Caring Kids
          case "housing-costs":
            this.renderMedicalExpenseSubTemplates();
            break;
          //Rendering Dynamic Medical Expense Template for Members
          case "medical-expenses":
            this.renderMedicalExpenseSubTemplates();
            break;
          case "dependent-care-costs":
            this.renderDependentsCareCosts(dataSections);
            break;
          case "kids-and-adults":
            this.renderMedicalExpenseSubTemplates();
            break;
          default:
            //Nothing
            break;
        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        switch (activeIdSubString) {
          // rendering Income Dynamic Questions.
          case "combined-income":
            this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
            break;
          case "combined-income-subsection":
            dataSections.currentMember =
              this.props.selfDeclare.sections[3].currentMember;
            if (this.state.incomeSummaryEdit) {
              this.gotoIncomeSummary(activeSplitMemberIndex);
              return;
            }
            break;
          case "medical-expenses":
            this.renderMedicalCostSections(activeSplitMemberIndex);
            break;
          case "dependent-care-costs":
            this.addMultipleProviderdAddress(activeSplitMemberIndex);
            break;
          //CHECK IF CHILD EXPENSE. ITERATE TO GET THE COST AND UNIT.
          case "child":
            var childCareArray = [];
            var childCareObj = { name: "", id: "", amount: 0, freq: "" };
            for (var member of this.props.selfDeclare.sections[0].subsections[3]
              .questions[0].options) {
              let clonedchildCare = Object.assign({}, childCareObj);
              for (var answer of dataSections.questions[0].Answer) {
                if (answer === member.name) {
                  clonedchildCare.id = member.id;
                  clonedchildCare.name = member.name;
                  for (var conditional of dataSections.questions[0]
                    .conditionals) {
                    if (conditional.valueToMatch === answer) {
                      clonedchildCare.amount = parseFloat(
                        conditional.conditionalQuestions[0].Answer.amount
                      );
                      clonedchildCare.freq =
                        conditional.conditionalQuestions[0].Answer.unit;
                    }
                  }
                  break;
                }
              }
              childCareArray.push(clonedchildCare);
            }
            // SEND THE CREATED OBJECT WITH THE REQUEST
            dataSections.childSupportInfo = childCareArray;
            break;
          default:
            //CHECK IF KIDS AND ADULT ADDRESS. MAP THE MEMBER ID AND SEQUENCE
            if (
              activeIdSubString.indexOf("kids-adult-multiple-address-") >= 0
            ) {
              // This checks if kids and adult address.
            } else if (activeIdSubString.indexOf("medical-address-") >= 0) {
              let currentChild = dataSections.title.split("'s ")[0];

              for (var teammember of dataSections.memberOptions) {
                if (teammember.name === currentChild) {
                  dataSections.clientId = teammember.id;
                  break;
                }
              }
            }
            break;
        }
        if (
          activeId !== "income" &&
          activeIdSubString !== "combined-income" &&
          activeId !== "kids-and-adults" &&
          activeId !== "medical-expenses" &&
          activeId.indexOf("medical-expenses-") < 0
        ) {
          if (activeId.indexOf("medical-address-") >= 0) {
            if (
              this.props.selfDeclare.sections[4].currentExpSeq !== undefined &&
              this.props.selfDeclare.sections[4].currentExpSeq !== null
            ) {
              dataSections.currentExpSeq =
                this.props.selfDeclare.sections[4].currentExpSeq;
            }
          }
        } else {
          this._renderNextSection(activeId);
          let AnswerObjectCopy = this.state.AnswerObject;
          AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
          this.setState({ AnswerObject: AnswerObjectCopy });
        }
      }
    } else if (incomingQuestions.selectionMade === TextProps.VALUE_FALSE) {
      const presentSectionId = this._getSection(activeId, this.state.sections);
      if (presentSectionId) {
        this.setState({
          activeSectionId: activeId,
        });
      }
      return;
    } else if (incomingQuestions.selectionMade === null) {
      let index = 0;
      switch (activeId) {
        //Clearing HouseHold Cache Template
        case "contact-info":
          index = 1;
          break;

        case "my-household":
          index = 2;
          break;

        default:
          //Nothing
          break;
      }
      this.props.setSDDataChangeStatus(TextProps.VALUE_FALSE, index);
      return;
    } else if (activeId === "sign-and-submit") {
      //start google analytics code for getting number of people applying application group by language
      let currentLanguageCode =
        helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
      if (currentLanguageCode) {
        switch (currentLanguageCode) {
          case types.SPANISH:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationESAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.PORTUGUESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationBRAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.CHINESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationZHAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.VIETNAMESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationVIAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.ENGLISH:
          default:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationENAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
        }
      }
      //end google analytics code for getting number of people applying application group by language
      //Loading Dynamic SubSections , HouseHold Details
      if (this.state.rights < 1 || this.state.signature.length < 1) {
        if (this.state.rights < 1) {
          this.setState({ agreeError: TextProps.VALUE_TRUE });
          this.agreeErrorMsg = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).agreeTermsErrorMsg;
        } else {
          this.setState({ agreeError: TextProps.VALUE_FALSE });
          this.agreeErrorMsg = "";
        }

        if (this.state.signature.length < 1) {
          this.setState({ signatureError: TextProps.VALUE_TRUE });
          this.signatureErrorMsg = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).signatureErrorMsg;
        } else {
          this.setState({ signatureError: TextProps.VALUE_FALSE });
          this.signatureErrorMsg = "";
        }

        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
          });
        }
        return;
      } else {
        // TO DEMONSTARATE STATIC PAGE FLOW
        this.setState({ isLoading: TextProps.VALUE_TRUE }, () => {
          var auditData = getAudit();
          this.props
            .submitSelfDeclareData(this.state.requestBody, auditData)
            .then((result) => {
              if (result) {
                this.setState(
                  {
                    isLoading: TextProps.VALUE_FALSE,
                    referenceNumber: this.props.selfDeclare.referenceNumber,
                  },
                  () => {
                    sessionStorage.setItem("covidRecertContact", false);
                    sessionStorage.setItem("covidRecert", false);
                    this.disableSelfDeclareSections();
                    this._renderNextSection(activeId);
                  }
                );
              } else {
                this.setState({ isLoading: TextProps.VALUE_FALSE });
                this.props.displayError();
              }
            })
            .catch((error) => {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.displayError();
            });
        });
      }
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.selfDeclare);

    if (activeId !== "sign-and-submit") {
      let removeSummary = TextProps.VALUE_TRUE;
      if (
        this.props.selfDeclare.sections[0].questions[3].Answer &&
        (this.props.selfDeclare.sections[0].questions[3].Answer.indexOf(
          "shelter-costs"
        ) >= 0 ||
          this.props.selfDeclare.sections[0].questions[3].Answer.indexOf(
            "utility-costs"
          ) >= 0 ||
          this.props.selfDeclare.sections[0].questions[3].Answer.indexOf(
            "dependent-care-costs"
          ) >= 0)
      ) {
        if (
          this.props.selfDeclare.sections[
            this.props.selfDeclare.sections.length - 2
          ].id !== "summary"
        ) {
          let headName = "";
          if (this.householdMembers && this.householdMembers.length > 0) {
            this.householdMembers.forEach((houseHoldMember) => {
              if (houseHoldMember.granteeFlag === TextProps.VALUE_TRUE) {
                headName =
                  houseHoldMember.firstName +
                  " " +
                  houseHoldMember.lastName +
                  formatDate(houseHoldMember.dateOfBirth);
              }
              return headName;
            });
          }
          this.props.selfDeclare.sections
            .splice(1, 0, {
              id: "summary",
              title: "Summary", //'Sign & submit',
              header:
                headName +
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).emptyLabel +
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpensesSummary
                ).expenseSummaryLabel, //'Sign & submit',
              subHeader: selfDeclarePageLabels(
                language,
                languageConstants.selfDeclareExpensesSummary
              ).expenseShelterSubHeader,
              hiddenFromNav: TextProps.VALUE_TRUE,
              completed: TextProps.VALUE_FALSE,
            })
            .join();
        }
      } else {
        removeSummary = TextProps.VALUE_TRUE;
      }
      if (
        this.props.selfDeclare.sections[0].subsections &&
        this.props.selfDeclare.sections[0].subsections.length > 0
      ) {
        this.props.selfDeclare.sections[0].subsections.map((section) => {
          if (
            removeSummary !== TextProps.VALUE_FALSE &&
            section.id === "shelter-costs" &&
            section.questions[0].Answer &&
            section.questions[0].Answer[0] ===
              "I do not pay for any shelter costs"
          ) {
            removeSummary = TextProps.VALUE_TRUE;
          } else if (
            section.id === "shelter-costs" &&
            section.questions[0].Answer &&
            section.questions[0].Answer[0] !==
              "I do not pay for any shelter costs"
          ) {
            let valuesPresent = TextProps.VALUE_FALSE;
            if (section.subsections) {
              section.subsections[0].questions.map((shelterDetails) => {
                if (shelterDetails.Answer !== "") {
                  valuesPresent = TextProps.VALUE_TRUE;
                }
                return valuesPresent;
              });
            }
            if (valuesPresent) removeSummary = TextProps.VALUE_FALSE;
            else removeSummary = TextProps.VALUE_TRUE;
          }
          if (section.id === "dependent-care-costs" && section.subsections) {
            removeSummary = TextProps.VALUE_FALSE;
          }
          if (
            removeSummary !== TextProps.VALUE_FALSE &&
            section.id === "utility-costs" &&
            section.questions[0].Answer &&
            section.questions[0].Answer[0] ===
              "I do not pay for any utility costs"
          ) {
            removeSummary = TextProps.VALUE_TRUE;
          } else if (
            removeSummary !== TextProps.VALUE_FALSE &&
            section.id === "utility-costs" &&
            section.questions[0].Answer &&
            section.questions[0].Answer[0] !==
              "I do not pay for any utility costs"
          ) {
            removeSummary = TextProps.VALUE_FALSE;
          }
          return section;
        });
      }

      if (removeSummary) {
        if (
          this.props.selfDeclare.sections[
            this.props.selfDeclare.sections.length - 2
          ].id === "summary"
        )
          this.props.selfDeclare.sections.splice(1, 1);
      }
      this._renderNextSection(activeId);
    }

    if (
      incomingQuestions.navigateToPrimaryScreen !== undefined &&
      incomingQuestions.navigateToPrimaryScreen === TextProps.VALUE_TRUE
    ) {
      event.preventDefault(); // allow react to take care of routing instead of browser
      const activeSectionId = this.state.activeSectionId;
      const { sections } = this.props.selfDeclare;
      const previousSectionId = getPreviousSection(activeSectionId, sections);
      if (previousSectionId) {
        const previousSection = this._getSection(previousSectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(previousSection);
          }
        );
      }
    }

    if (
      incomingQuestions.skipsubSectionNavigation !== undefined &&
      incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
    ) {
      event.preventDefault(); // allow react to take care of routing instead of browser
      const activeQuestionId = incomingQuestions.id;
      const { sections } = this.props.selfDeclare;
      let sectionId = getNextSection(activeQuestionId, sections);

      switch (activeQuestionId) {
        case "contact-info":
          sectionId = "my-household";
          break;

        case "my-household":
          sectionId = "earned-income";
          break;

        default:
          //Nothing
          break;
      }

      if (sectionId) {
        const nextSection = this._getSection(sectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(nextSection);
          }
        );
      }
    }
  };

  saveToDatabase = (dataSections, activeId, dalReference) => {
    this.props.selfDeclare.sections[0].isSubmitInitiated = TextProps.VALUE_TRUE;

    this.props.saveSnapApply(dataSections);
  };

  _renderNextSection = (activeId) => {
    const nextSectionId = getNextSection(activeId, this.state.sections);
    //Function to Execute before Next page renders
    switch (nextSectionId) {
      // rendering Income Dynamic Questions.
      case "income-summary":
        this.prepareIncomeSummary();
        break;
      //Function to Manipulate Details on HouseHoldPage page
      case "household-details":
        this.updateHouseHoldDetails();
        break;
      case "dependent-care-costs":
        this.renderDependentCareSection();
        break;
      //Function to Manipulate Details on Expense child support page
      case "child-support":
        this.renderMedicalExpenseSubTemplates();
        break;
      //Function to Manipulate Details on Expense medical page
      case "medical-expenses":
        this.updateHouseHoldMedicalDetails();
        break;
      //Clearing HouseHold Cache Template
      case "more-info":
        this.setLanguageInScreen();
        break;
      default:
        //Nothing
        break;
    }
    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState({ directionClass: "forward" }, () => {
        this.onSectionChange(nextSection);
      });
      this._updateCurrentSection(activeId);
    }
  };

  updateHouseHoldMedicalDetails = () => {
    var expenseCopy = this.props.selfDeclare.sections[0];
    this.props.appendExpenseDetails(expenseCopy, 0);
  };

  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.selfDeclare;
    let previousSectionId = getPreviousSection(activeId, sections);
    if (previousSectionId) {
      const incomingQuestions = this._getSection(activeId);
      if (
        incomingQuestions.skipsubSectionNavigation !== undefined &&
        incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
      ) {
        const incomingQuestionId = incomingQuestions.id;
        switch (incomingQuestionId) {
          case "my-household":
            previousSectionId = "contact-info";
            break;

          case "earned-income":
            previousSectionId = "my-household";
            break;

          default:
            //Nothing
            break;
        }
      }

      let activeIdSubString = "";
      if (activeId !== "" && activeId !== undefined && activeId !== null) {
        var activeIdSplit = activeId.split("-");
        activeIdSubString = activeIdSplit.join("-");
      }

      if (
        activeIdSubString.indexOf("kids-adult-multiple-address-") >= 0 ||
        previousSectionId.indexOf("dependent-care-costs-") >= 0
      ) {
        this.retainDependentCareData();
      }

      const previousSection = this._getSection(previousSectionId);

      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    }
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  updateHouseHoldIncomeDetails = () => {
    var incomeNameList = [];
    var houseHoldMembersCopy = this.props.selfDeclare.householdMembers;
    for (let memberListVal of houseHoldMembersCopy) {
      var incomeHouseHoldName = memberListVal.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName = incomeHouseHoldName + " " + memberListVal.lastName;
        let incomeHouseHoldDOB = memberListVal.dateOfBirth;
        if (memberListVal.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName =
          memberListVal.firstName + " " + memberListVal.lastName;
      }

      incomeNameList.push(incomeCombinedName);
    }
    this.setState({ houseMemberLabels: incomeNameList });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).getStartedLabel;
        return returnText;
      case "sign-and-submit":
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).submitSelfButtonLabel;
        return returnText;
      default:
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).continueLabel;
        return returnText;
    }
  };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: event }, () => {
      if (this.state.signature.length < 1) {
        this.setState({ signatureError: TextProps.VALUE_TRUE });
        this.signatureErrorMsg = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).signatureErrorMsg;
      } else {
        this.setState({ signatureError: TextProps.VALUE_FALSE });
        this.signatureErrorMsg = "";
      }
    });
  };

  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agreeErrorMsg = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agreeErrorMsg = "";
      }
    });
  };

  designPdfAsHTMLTemplate(sections) {
    if (sections !== undefined) {
      this.props.downloadPDFData(sections);
    }
  }

  renderSelfDeclareExpensesSubSections = (selectedOptions) => {
    let selfDeclareSubSections = null;
    let sectionsCopy = this.props.selfDeclare.sections[0].subsections;
    let exisintingSectionIds = [];
    if (sectionsCopy && sectionsCopy.length > 0) {
      exisintingSectionIds.push(sectionsCopy.id);
    }
    if (selectedOptions.length > 0) {
      selfDeclareSubSections = [];
      let subSectionsTemplate = this.state.selfDecExpensSubsectionsTemplate;
      selectedOptions.map((selectedOption) => {
        subSectionsTemplate.forEach((section) => {
          if (section.id === selectedOption) {
            if (exisintingSectionIds.indexOf(section.id) >= 0) {
              selfDeclareSubSections.push(
                sectionsCopy[exisintingSectionIds.indexOf(section.id)]
              );
            } else {
              selfDeclareSubSections.push(section);
            }
            return selfDeclareSubSections;
          }
        });
      });
    }
    if (selfDeclareSubSections && selfDeclareSubSections.length > 0)
      this.props.appendDeclareExpenseSubsections(selfDeclareSubSections);
    else delete this.props.selfDeclare.sections[0].subsections;
  };

  renderShelterCostsBySelection = (currentSection) => {
    let selfDecExpenseSubsecsCopy =
      this.props.selfDeclare.sections[0].subsections;
    let housingCostsFilteredQuestions = [];
    let housingCostsSection = null;
    let housingCostsPageAnswers = null;
    let houseCopy = null;
    let shelterSectionIndex = 0;

    selfDecExpenseSubsecsCopy.map((section, index) => {
      if (currentSection.id === section.id) {
        housingCostsSection = section;
        housingCostsPageAnswers = housingCostsSection.questions[0].Answer;
        houseCopy = _.cloneDeep(section);
        shelterSectionIndex = index;
      }
    });
    //Swap full section for first time and it should not be manipulated!
    if (this.housingCostsSubSections[0] === undefined) {
      this.housingCostsSubSections[0] = _.cloneDeep(
        housingCostsSection.subsections[0]
      );
    } else {
      housingCostsSection.subsections = [];
      housingCostsSection.subsections[0] = _.cloneDeep(
        this.housingCostsSubSections[0]
      );
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      housingCostsPageAnswers[0] !== "" &&
      housingCostsPageAnswers[0] !== undefined &&
      housingCostsPageAnswers[0] !== "I do not pay for any shelter costs"
    ) {
      housingCostsFilteredQuestions = filterQuestionsBySelection(
        housingCostsPageAnswers,
        housingCostsSection.subsections[0].questions
      );
      housingCostsSection.subsections[0].questions = _.cloneDeep(
        housingCostsFilteredQuestions
      );
      housingCostsSection.subsections[0].questions.map((q, index) => {
        if (houseCopy.questions[0].Answer.indexOf(q.valueToMatch) >= 0) {
          if (
            houseCopy.subsections &&
            houseCopy.subsections[0].questions[index] &&
            houseCopy.subsections[0].questions[index].Answer !== undefined
          ) {
            housingCostsSection.subsections[0].questions[index].Answer =
              houseCopy.subsections[0].questions[index].Answer;
            housingCostsSection.subsections[0].questions[index].targetValue =
              houseCopy.subsections[0].questions[index].Answer;
            housingCostsSection.subsections[0].questions[index].value =
              houseCopy.subsections[0].questions[index].Answer;
          }
        }
      });
      selfDecExpenseSubsecsCopy[shelterSectionIndex] = housingCostsSection;
    } else {
      delete housingCostsSection.subsections;
      selfDecExpenseSubsecsCopy[shelterSectionIndex] = housingCostsSection;
    }
    this.props.appendDeclareExpenseSubsections(selfDecExpenseSubsecsCopy);
  };

  renderDependentCareSection = () => {
    let selfDecExpenseSubsecsCopy =
      this.props.selfDeclare.sections[0].subsections;

    let memberList = [];

    if (this.householdMembers && this.householdMembers.length > 0) {
      this.householdMembers.forEach((member) => {
        if (member.dataChangeCode !== "REMOVE") {
          memberList.push(
            member.firstName +
              " " +
              member.lastName +
              formatDate(member.dateOfBirth)
          );
        }
      });
    }

    selfDecExpenseSubsecsCopy.map((subSection) => {
      if (subSection.id === "dependent-care-costs") {
        let houseMemberList = memberList;
        let expenseSection = subSection;
        //LOAD THE MEMEBER DETAILS IF FRESH APPLY PROCESS
        if (!resumeApply) {
          expenseSection.questions[0].options = [];
          expenseSection.questions[0].options = houseMemberList;
        }

        if (!resumeApply) {
          let conditionalQuestionTemplate =
            expenseSection.questions[0].conditionals[0];
          expenseSection.questions[0].conditionals = [];
          for (
            var memIndex = 0;
            memIndex < houseMemberList.length;
            memIndex++
          ) {
            expenseSection.questions[0].conditionals[memIndex] = _.cloneDeep(
              conditionalQuestionTemplate
            );
            expenseSection.questions[0].conditionals[memIndex].valueToMatch =
              houseMemberList[memIndex];
            expenseSection.questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].label = interimReportPageLabels(
              language,
              languageConstants.householdDynamic,
              houseMemberList[memIndex],
              null,
              null
            ).conditionalLabel;
            expenseSection.questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].name = "recipient-of-care-" + [memIndex];
            expenseSection.questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].options = [];

            for (let houseMemberVal of houseMemberList) {
              if (houseMemberVal !== houseMemberList[memIndex]) {
                expenseSection.questions[0].conditionals[
                  memIndex
                ].conditionalQuestions[0].options.push(houseMemberVal);
              }
            }
          }
        }
        if (
          this.state.kidsAdultExpenseSubSectionCleared === TextProps.VALUE_FALSE
        ) {
          this.setState({
            kidsAdultExpenseSubSectionCleared: TextProps.VALUE_TRUE,
            kidsAdultExpenseMemberTemplate: subSection.subsections[0],
          });
        }
      }
    });

    this.props.appendDeclareExpenseSubsections(selfDecExpenseSubsecsCopy);
  };

  retainDependentCareData = () => {
    let selfDecExpenseSubsecsCopy =
      this.props.selfDeclare.sections[0].subsections;
    let dependentCosts = null;
    let dependentCopy = null;
    let dependentIndex = null;
    selfDecExpenseSubsecsCopy.map((subSection, index) => {
      if (subSection.id === "dependent-care-costs") {
        dependentCosts = subSection;
        dependentCopy = _.cloneDeep(subSection);
        dependentIndex = index;
      }
    });

    if (
      dependentCopy.subsections !== undefined &&
      dependentCopy.subsections.length > 0
    ) {
      for (
        let memIndex = 0;
        memIndex < dependentCopy.subsections.length;
        memIndex++
      ) {
        if (dependentCopy.subsections[memIndex]) {
          for (
            var q = 0;
            q < dependentCopy.subsections[memIndex].questions.length;
            q++
          ) {
            dependentCosts.subsections[memIndex].questions[q].Answer =
              dependentCopy.subsections[memIndex].questions[q].Answer;
            dependentCosts.subsections[memIndex].questions[q].targetValue =
              dependentCopy.subsections[memIndex].questions[q].targetValue;
            dependentCosts.subsections[memIndex].questions[q].value =
              dependentCopy.subsections[memIndex].questions[q].Answer;
            if (q > 0) {
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].Answer =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].targetValue =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].targetValue;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].value =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer;
              if (
                dependentCopy.subsections[memIndex].questions[q].conditionals[0]
                  .conditionalQuestions[0].Answer !== undefined &&
                dependentCopy.subsections[memIndex].questions[q].conditionals[0]
                  .conditionalQuestions[0].Answer > 0
              ) {
                for (
                  let add = 0;
                  add <
                  dependentCopy.subsections[memIndex].questions[q]
                    .conditionals[0].conditionalQuestions[0].Answer;
                  add++
                ) {
                  if (
                    dependentCosts.subsections[memIndex].subsections !==
                      undefined &&
                    dependentCosts.subsections[memIndex].subsections[add] !==
                      undefined
                  ) {
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].Answer =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].value =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].targetValue =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].targetValue;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].Answer =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].value =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].targetValue =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].targetValue;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.props.selfDeclare.sections[0].subsections[dependentIndex] =
      dependentCosts;
  };

  renderDependentsCareCosts = (selectedSection) => {
    let selfDecExpenseSubsecsCopy =
      this.props.selfDeclare.sections[0].subsections;
    selfDecExpenseSubsecsCopy.map((subSection) => {
      if (subSection.id === selectedSection.id) {
        let dependentCosts = subSection;
        let dependentCopy = _.cloneDeep(dependentCosts);
        let parentIds = dependentCosts.questions[0].Answer;
        let parentOptions = dependentCosts.questions[0].options;

        //Filtering Answers using IDs
        let parentMembers = [];
        dependentMembers = [];

        for (let parentIdVal of parentIds) {
          for (let optionVal of parentOptions) {
            if (parentIdVal === optionVal) {
              parentMembers.push(optionVal);
            }
          }
        }
        let parentNameMembers = {};
        let conditionalDependentsCount = {};
        let parentConditionals = dependentCosts.questions[0].conditionals;
        let childMembers = [];
        for (var p = 0; p < parentIds.length; p++) {
          parentNameMembers[parentIds[p]] = [];
          for (let parentConditionalVal of parentConditionals) {
            if (parentConditionalVal.valueToMatch === parentIds[p]) {
              conditionalDependentsCount =
                parentConditionalVal.conditionalQuestions[0].Answer;
              for (let conditionalDependentVal of conditionalDependentsCount) {
                childMembers.push(conditionalDependentVal);
                parentNameMembers[parentIds[p]].push(conditionalDependentVal);
                dependentMembers.push(
                  parentMembers[p] + "-" + conditionalDependentVal
                );
              }
            }
          }
        }
        subSection.dependentCare = dependentMembers;

        if (
          this.state.dependentCareCostsSubSectionCleared ===
          TextProps.VALUE_FALSE
        ) {
          this.setState(
            {
              dependentCareCostsSubSectionCleared: TextProps.VALUE_TRUE,
              dependentCareCostsTemplate: dependentCosts.subsections[0],
            },
            () => {
              this.props.appendDeclareExpenseSubsections(
                selfDecExpenseSubsecsCopy
              );
            }
          );
        }
        dependentCosts.questions[0].value = parentIds;
        if (dependentCosts.subsections === undefined) {
          dependentCosts.subsections = dependentCareTemplate.subsections;
        }
        //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
        if (dependentMembers[0] !== "" && dependentMembers.length > 0) {
          for (var r = 0; r < dependentMembers.length; r++) {
            for (let memIndex of dependentMembers.keys()) {
              dependentCosts.subsections[memIndex] = _.cloneDeep(
                dependentCosts.subsections[0]
              );
              dependentCosts.subsections[memIndex].id =
                "dependent-care-costs-" + [memIndex];
              dependentCosts.subsections[memIndex].title = parentMembers[0];
              dependentCosts.subsections[memIndex].header =
                selfDeclaredependentCareLabels(
                  language,
                  languageConstants.childSupport,
                  dependentMembers[memIndex].split("-")[1],
                  null
                ).depedentCostHeader;

              // set lables in conditionals questions.
              for (let q of dependentCosts.subsections[
                memIndex
              ].questions.keys()) {
                if (
                  dependentCosts.subsections[memIndex].questions[q].type ===
                    "units" &&
                  q === 0
                ) {
                  dependentCosts.subsections[memIndex].questions[q].label =
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      dependentMembers[memIndex].split("-")[0],
                      dependentMembers[memIndex].split("-")[1]
                    ).dependentCostsQoneLabel;
                } else if (
                  dependentCosts.subsections[memIndex].questions[q].type ===
                    "radio" &&
                  q === 1
                ) {
                  /** Translation start */
                  dependentCosts.subsections[memIndex].questions[q].label =
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      dependentMembers[memIndex].split("-")[0],
                      dependentMembers[memIndex].split("-")[1]
                    ).dependentCostsQtwoLabel;
                  dependentCosts.subsections[memIndex].questions[
                    q
                  ].conditionals[0].conditionalQuestions[0].label =
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      dependentMembers[memIndex].split("-")[0],
                      dependentMembers[memIndex].split("-")[1]
                    ).dependentCoditionalQLabel;
                  /** Translation end */
                } else if (
                  dependentCosts.subsections[memIndex].questions[q].type ===
                    "radio" &&
                  q === 2
                ) {
                  dependentCosts.subsections[memIndex].questions[q].label =
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      dependentMembers[memIndex].split("-")[0],
                      dependentMembers[memIndex].split("-")[1]
                    ).driveToQLabel;
                  dependentCosts.subsections[memIndex].questions[
                    q
                  ].conditionals[0].conditionalQuestions[0].label =
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      dependentMembers[memIndex].split("-")[0],
                      dependentMembers[memIndex].split("-")[1]
                    ).dependConQuestion;
                  if (dependentCosts.subsections[memIndex].subsections) {
                    dependentCosts.subsections[memIndex].subsections[0].header =
                      selfDeclaredependentCareLabels(
                        language,
                        languageConstants.childSupport,
                        dependentMembers[memIndex].split("-")[0],
                        null
                      ).chAdultCareAddrHeader;
                    dependentCosts.subsections[memIndex].subsections[0].title =
                      dependentMembers[memIndex].split("-")[1];
                    dependentCosts.subsections[
                      memIndex
                    ].subsections[0].questions[1].label =
                      selfDeclaredependentCareLabels(
                        language,
                        languageConstants.childSupport,
                        dependentMembers[memIndex].split("-")[0],
                        dependentMembers[memIndex].split("-")[1]
                      ).subSectionQuestionLabel;
                  }
                }

                if (
                  childMembers.indexOf(
                    dependentMembers[memIndex].split("-")[1]
                  ) >= 0
                ) {
                  if (
                    dependentCopy.subsections &&
                    dependentCopy.subsections[memIndex]
                  ) {
                    dependentCosts.subsections[memIndex].questions[q].Answer =
                      dependentCopy.subsections[memIndex].questions[q].Answer;
                    dependentCosts.subsections[memIndex].questions[
                      q
                    ].targetValue =
                      dependentCopy.subsections[memIndex].questions[
                        q
                      ].targetValue;
                    dependentCosts.subsections[memIndex].questions[q].value =
                      dependentCopy.subsections[memIndex].questions[q].Answer;
                    if (q > 0) {
                      dependentCosts.subsections[memIndex].questions[
                        q
                      ].conditionals[0].conditionalQuestions[0].Answer =
                        dependentCopy.subsections[memIndex].questions[
                          q
                        ].conditionals[0].conditionalQuestions[0].Answer;
                      dependentCosts.subsections[memIndex].questions[
                        q
                      ].conditionals[0].conditionalQuestions[0].targetValue =
                        dependentCopy.subsections[memIndex].questions[
                          q
                        ].conditionals[0].conditionalQuestions[0].targetValue;
                      dependentCosts.subsections[memIndex].questions[
                        q
                      ].conditionals[0].conditionalQuestions[0].value =
                        dependentCopy.subsections[memIndex].questions[
                          q
                        ].conditionals[0].conditionalQuestions[0].Answer;
                      if (
                        dependentCopy.subsections[memIndex].questions[q]
                          .conditionals[0].conditionalQuestions[0].Answer !==
                          undefined &&
                        dependentCopy.subsections[memIndex].questions[q]
                          .conditionals[0].conditionalQuestions[0].Answer > 0
                      ) {
                        for (
                          let add = 0;
                          add <
                          dependentCopy.subsections[memIndex].questions[q]
                            .conditionals[0].conditionalQuestions[0].Answer;
                          add++
                        ) {
                          if (
                            dependentCosts.subsections[memIndex].subsections !==
                              undefined &&
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ] !== undefined
                          ) {
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[0].Answer =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[0].Answer;
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[0].value =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[0].Answer;
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[0].targetValue =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[0].targetValue;
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[1].Answer =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[1].Answer;
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[1].value =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[1].Answer;
                            dependentCosts.subsections[memIndex].subsections[
                              add
                            ].questions[1].targetValue =
                              dependentCopy.subsections[memIndex].subsections[
                                add
                              ].questions[1].targetValue;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          delete dependentCosts.subsections;
        }
      }
    });
    this.props.appendDeclareExpenseSubsections(selfDecExpenseSubsecsCopy);
  };

  addMultipleProviderdAddress = (memberIndex) => {
    let newDepend = null;
    let selfDecExpenseSubsecsCopy =
      this.props.selfDeclare.sections[0].subsections;
    selfDecExpenseSubsecsCopy.map((subSection) => {
      if (subSection.id === "dependent-care-costs") {
        let kidsAdultsForMember = subSection.subsections[memberIndex];
        newDepend = _.cloneDeep(subSection);
        let mainQuestionAnswer = kidsAdultsForMember.questions[2].Answer;

        if (this.providerAddressTemplate[memberIndex] === undefined) {
          this.providerAddressTemplate[memberIndex] = _.cloneDeep(
            kidsAdultsForMember.subsections
          );
        } else {
          kidsAdultsForMember.subsections = _.cloneDeep(
            this.providerAddressTemplate[memberIndex]
          );
        }

        if (
          mainQuestionAnswer ===
          kidsAdultsForMember.questions[2].conditionals[0].valueToMatch
        ) {
          let providerAddressNo =
            kidsAdultsForMember.questions[2].conditionals[0]
              .conditionalQuestions[0].Answer;
          if (providerAddressNo > 0) {
            kidsAdultsForMember.subsections = [];
            for (
              var bindAddressIndex = 0;
              bindAddressIndex < providerAddressNo;
              bindAddressIndex++
            ) {
              kidsAdultsForMember.subsections[bindAddressIndex] = _.cloneDeep(
                this.providerAddressTemplate[memberIndex][0]
              );
              kidsAdultsForMember.subsections[bindAddressIndex].id =
                "kids-adult-multiple-address-" +
                memberIndex +
                "-" +
                bindAddressIndex;
              kidsAdultsForMember.subsections[bindAddressIndex].header =
                kidsAdultsForMember.subsections[bindAddressIndex].header +
                " " +
                (bindAddressIndex + 1);
              if (
                newDepend.subsections[memberIndex].subsections !== undefined &&
                newDepend.subsections[memberIndex].subsections.length > 0 &&
                newDepend.subsections[memberIndex].subsections[
                  bindAddressIndex
                ] !== undefined
              ) {
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[0].Answer =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[0].Answer;
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[0].value =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[0].Answer;
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[0].targetValue =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[0].targetValue;
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[1].Answer =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[1].Answer;
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[1].value =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[1].Answer;
                kidsAdultsForMember.subsections[
                  bindAddressIndex
                ].questions[1].targetValue =
                  newDepend.subsections[memberIndex].subsections[
                    bindAddressIndex
                  ].questions[1].targetValue;
              }
            }
          } else {
            delete kidsAdultsForMember.subsections;
          }
        } else {
          delete kidsAdultsForMember.subsections;
        }
      }
    });
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    return null;
  };

  // download pdf files link
  downloadApplicationAsPdf() {
    window.open(
      "https://dta-connect-service.azurewebsites.net/snapapplication.pdf",
      "_blank"
    );
  }

  _bindAnswerObject = (answer) => {
    this.setState({
      AnswerObject: answer,
    });
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <SelfDeclareSection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.redirectToSignAndSubmit}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </SelfDeclareSection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    //start of google analytics related code added in client side for  partial submit
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.DT_PARTIAL_APPLY,
        "submitApplicationPartially",
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();
    //end of google analytics related code added in client side for  partial submit

    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);

    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: "sign-and-submit",
        });
      }
    );
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "summary":
        this.prepareDataForSubmission();
        return this.renderSummaryOfExpenses();
      case "sign-and-submit":
        this.prepareDataForSubmission();
        return this.renderSignAndSubmit();
      case "submitted":
        return this.renderSubmitSummary();
      default:
        return <div>Something went wrong</div>;
    }
  };

  renderSummaryOfExpenses = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.state.requestBody.shelterExpenses &&
        this.state.requestBody.shelterExpenses.length > 0
          ? this.renderShelterExpenseSummaryItem(
              this.state.requestBody.shelterExpenses
            )
          : null}
        {this.state.requestBody.utilityExpenses &&
        this.state.requestBody.utilityExpenses.noUtilityFlag !== undefined
          ? this.renderUtilityExpenseSummaryItem(
              this.state.requestBody.utilityExpenses
            )
          : null}
        {this.state.requestBody.dependentCareExpenses &&
        this.state.requestBody.dependentCareExpenses.length > 0
          ? this.state.requestBody.dependentCareExpenses.map((summary) => {
              return this.renderDependentExpenseSummaryItem(summary);
            })
          : null}
      </div>
    );
  };

  renderUtilityExpenseSummaryItem = (summary) => {
    const nextSection = this._getSection("utility-costs");
    let heatFlagVal = null;
    if (summary.heatFlag === TextProps.VALUE_TRUE) {
      heatFlagVal = (
        <li>
          <span className="spaced-text utility-cost-section">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilityQOptions[0]
            }
          </span>
        </li>
      );
    }
    let acFlagVal = null;
    if (summary.acFlag === TextProps.VALUE_TRUE) {
      acFlagVal = (
        <li>
          <span className="spaced-text">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilityQOptions[1]
            }
          </span>
        </li>
      );
    }
    let acFeeFlagVal = null;
    if (summary.acFeeFlag === TextProps.VALUE_TRUE) {
      acFeeFlagVal = (
        <li>
          <span className="spaced-text">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilityQOptions[2]
            }
          </span>
        </li>
      );
    }
    let electricOrGasFlagVal = null;
    if (summary.electricOrGasFlag === TextProps.VALUE_TRUE) {
      electricOrGasFlagVal = (
        <li>
          <span className="spaced-text">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilityQOptions[3]
            }
          </span>
        </li>
      );
    }
    let phoneFlagVal = null;
    if (summary.phoneFlag === TextProps.VALUE_TRUE) {
      phoneFlagVal = (
        <li>
          <span className="spaced-text">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilityQOptions[4]
            }
          </span>
        </li>
      );
    }

    return (
      <div className="dta-content-box apply__summary-item">
        <p className="apply__heading pure-u-14-24 pure-u-sm-18-24 apply__body-header">
          {
            selfDeclarePageLabels(
              language,
              languageConstants.selfDeclareExpenses
            ).utilityCostSelf
          }
        </p>
        <button
          className="pure-u-10-24 pure-u-sm-6-24 dta-button dta-bordered-button"
          onClick={() => this.onSectionChange(nextSection)}
        >
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .editButtonLabel
          }
        </button>
        <br />
        {summary.noUtilityFlag === TextProps.VALUE_TRUE ? (
          <span className="spaced-text">
            {
              selfDeclarePageLabels(language, languageConstants.utilityCosts)
                .utilitiesClearOption
            }
          </span>
        ) : (
          <div key={this.nextUniqueId()}>
            <ul key={this.nextUniqueId()}>
              {heatFlagVal}
              {acFlagVal}
              {acFeeFlagVal}
              {electricOrGasFlagVal}
              {phoneFlagVal}
            </ul>
            <hr className="divider" />
          </div>
        )}
      </div>
    );
  };

  renderShelterExpenseSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item">
        <p className="apply__heading apply__body-header pure-u-14-24 pure-u-sm-18-24 form-title-alignment">
          {
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).shelterExpensesLabel
          }
        </p>
        <button
          className="pure-u-10-24 pure-u-sm-6-24 dta-button dta-bordered-button"
          onClick={() => this._renderNextSection("shelter-costs")}
        >
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .editButtonLabel
          }
        </button>
        <ul>
          {summary.map((incomeItem, index) => {
            let expTypeText = "";
            switch (incomeItem.typeCode) {
              case "RENT":
                expTypeText = selfDeclarePageLabels(
                  language,
                  languageConstants.shelterCosts
                ).houseQOptions[0];
                break;
              case "MTGPRN":
                expTypeText = selfDeclarePageLabels(
                  language,
                  languageConstants.shelterCosts
                ).houseQOptions[1];
                break;
              case "PRPTAX":
                expTypeText = selfDeclarePageLabels(
                  language,
                  languageConstants.shelterCosts
                ).houseQOptions[2];
                break;
              case "HOMEIN":
                expTypeText = selfDeclarePageLabels(
                  language,
                  languageConstants.shelterCosts
                ).houseQOptions[3];
                break;
              case "CONFEE":
                expTypeText = selfDeclarePageLabels(
                  language,
                  languageConstants.shelterCosts
                ).houseQOptions[4];
                break;

              default:
            }
            return (
              <li key={index}>
                <span className="spaced-text">{expTypeText}</span>
                <div className="max-width pure-u-1-1">
                  <div
                    className="pure-u-12-24 pure-u-sm-19-24"
                    style={{ paddingRight: "0.6rem" }}
                  >
                    <span
                      className="label-text-clr"
                      style={{ wordBreak: "break-word" }}
                    >
                      {getFrequency(language)[incomeItem.frequencyCode]}{" "}
                      {
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).selfDeclareAmount
                      }
                    </span>
                  </div>
                  <div className={"pure-u-12-24 pure-u-sm-5-24"}>
                    <span>{formatMoney(incomeItem.amount)}</span>
                  </div>
                  <hr className="divider" />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderDependentExpenseSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item">
        <h3 className="pure-u-14-24 pure-u-sm-18-24 apply__body-header">
          {selfDeclaredependentCareLabels(
            language,
            languageConstants.childSupport,
            null,
            null
          ).depedentCostSummaryHeader +
            " " +
            summary.dependent.firstName +
            " " +
            summary.dependent.lastName +
            formatDate(summary.dependent.dateOfBirth)}
        </h3>
        <button
          className="pure-u-10-24 pure-u-sm-6-24 dta-button dta-bordered-button"
          onClick={() => this._renderNextSection("dependent-care-costs")}
        >
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .editButtonLabel
          }
        </button>
        <ul>
          <li>
            <span className="spaced-text">
              {
                selfDeclaredependentCareLabels(
                  language,
                  languageConstants.childSupport,
                  null,
                  null
                ).careprovider
              }
            </span>
            <div className="max-width pure-u-1-1">
              <div
                className="pure-u-12-24 pure-u-sm-19-24"
                style={{ paddingRight: "0.6rem" }}
              >
                <span
                  className="label-text-clr"
                  style={{ wordBreak: "break-word" }}
                >
                  {getFrequency(language)[summary.providerCareFrequencyCode]}{" "}
                  {
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      null,
                      null
                    ).selfDeclareAmt
                  }
                </span>
              </div>
              <div className={"pure-u-12-24 pure-u-sm-5-24"}>
                <span>{formatMoney(summary.providerCareAmount)}</span>
              </div>
              <hr className="divider" />
            </div>
          </li>
          <li>
            <span className="spaced-text">
              {" "}
              {
                selfDeclaredependentCareLabels(
                  language,
                  languageConstants.childSupport,
                  null,
                  null
                ).transportation
              }{" "}
            </span>
            <div className="max-width pure-u-1-1">
              <div className="pure-u-12-24 pure-u-sm-19-24">
                <span
                  className="label-text-clr"
                  style={{ wordBreak: "break-word" }}
                >
                  {getFrequency(language)[summary.transportationFrequencyCode]}{" "}
                  {
                    selfDeclaredependentCareLabels(
                      language,
                      languageConstants.childSupport,
                      null,
                      null
                    ).selfDeclareAmt
                  }
                </span>
              </div>
              <div className={"pure-u-12-24 pure-u-sm-5-24"}>
                <span>{formatMoney(summary.transportationAmount)}</span>
              </div>
              <hr className="divider" />
            </div>
          </li>
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;

    if (unit === "Week") {
      amountIterator = 4;
    } else if (unit === "Every two weeks") {
      amountIterator = 2;
      classRowCount = 6;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      amountRows.push(
        <div className={"pure-u-" + classRowCount + "-24"}>
          <span>${amount[i]}</span>
        </div>
      );
    }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-12-24">
          <span style={{ color: "grey" }}>
            {getFrequency(language)[unit]} amount
          </span>
        </div>
        {amountRows}
        <hr className="divider" />
      </div>
    );
  };

  renderSubmitSummary = () => {
    return (
      <SubmitSummary
        referenceNumber={this.state.referenceNumber}
        selfDeclare={this.props.selfDeclare}
      />
    );
  };

  renderSignAndSubmit = () => {
    return (
      <SignAndSubmit
        selfDeclare={this.props.selfDeclare}
        acceptTermsConditions={this.acceptTermsConditions}
        submitFormId={this.state.submitFormId}
        agreeErrorMsg={this.agreeErrorMsg}
        signatureErrorMsg={this.signatureErrorMsg}
        updateSignature={this.updateSignature}
        signature={this.state.signature}
        signatureError={this.state.signatureError}
        agreeError={this.state.agreeError}
      />
    );
  };

  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.selfDeclare;

    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);

    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      return <Loading />;
    }
  }

  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      const { common } = this.props.language.security;
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
      var loginFlag = TextProps.VALUE_FALSE;
      if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
        loginFlag = TextProps.VALUE_TRUE;
      } else {
        if (loginUserInfo !== null && loginUserInfo !== undefined) {
          if (loginUserInfo.firstName !== "") {
            loginFlag = TextProps.VALUE_TRUE;
          }
        }
      }
      const msalInstance = this.props.msalContext.instance;

      return (
        <div className="full-height apply selfdeclare-container">
          {/* See applyNavigation.js for documentation on ApplyNavigation props */}
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            skipToAnchor="#content"
          />
          <main
            className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
            id="content"
            tabIndex="-1"
          >
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
            {loginFlag === TextProps.VALUE_TRUE ? (
              <div className="interim-recert__options">
                <LanguageSelect className="interim-recert__language-select" />
                <div className="interim-recert__options-account">
                  <span className="interim-recert__headertext">
                    {common.hi}
                  </span>
                  &nbsp;
                  <DTADropdown
                    buttonText={userInfo.firstName}
                    buttonClass="dta-button dta-button--link"
                    menuClass="list--bordered"
                    caret={TextProps.VALUE_TRUE}
                    caretClass="color-primary"
                  >
                    <li className="pad-all--half">
                      <Link
                        to={routeProps.CONSUMER_MY_INFO_PATH}
                        className="display-block"
                        onClick={this._handleApplyGoBack.bind(this)}
                      >
                        {common.home}
                      </Link>
                    </li>
                    <li className="pad-all--half border-top">
                      <React.Fragment>
                        {config.azureLoginFlag ? (
                          <Link
                            to="#"
                            className="display-block"
                            onClick={() =>
                              this.props.onAzureLogOut(msalInstance)
                            }
                          >
                            {common.logout}
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="display-block"
                            onClick={this._handleApplyLogoutSession.bind(this)}
                          >
                            {common.logout}
                          </Link>
                        )}
                      </React.Fragment>
                    </li>
                  </DTADropdown>
                </div>
              </div>
            ) : (
              <LanguageSelect className="language-select--dark" />
            )}
          </main>
        </div>
      );
    }
  }

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  downloadPDFData() {
    this.props.downloadPDFData(this.props.selfDeclare.sections);
  }

  convertDOBForDataPost = (dobFrom) => {
    let retrunValue = null;
    if (dobFrom.indexOf(".") !== -1) {
      let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
      let dobArray = dob.split(".");

      let month = MONTHSHORT.indexOf(dobArray[1]) + 1;
      if (month < 10) {
        month = "0" + month;
      }
      retrunValue = dobArray[2] + "-" + month + "-" + dobArray[0];
    } else {
      retrunValue = dobFrom;
    }

    return retrunValue;
  };

  getMemberDataObjectByName = (name, listToCheck) => {
    console.log("NAME TO CHECK : " + name);
    let returnMember = null;
    listToCheck.map((member) => {
      console.log(member);
      if (
        name === member.firstName + " " + member.lastName ||
        name ===
          member.firstName +
            " " +
            member.lastName +
            formatDate(member.dateOfBirth)
      ) {
        console.log("TRUE");
        returnMember = member;
      }
    });
    return returnMember;
  };

  prepareDataForSubmission = (activeId) => {
    let reportedChanges = TextProps.VALUE_FALSE;

    let agencyID = 0;

    this.householdMembers.forEach((member) => {
      if (member.granteeFlag === TextProps.VALUE_TRUE) {
        agencyID = member.agencyID;
      }
      return agencyID;
    });

    let requestBody = {
      agencyID: agencyID,
      programTypeCode: "SNAP",
      programID: localStorage.getItem("programID")
        ? localStorage.getItem("programID")
        : 0,
      languageCode: language ? language.split("-")[0] : "en",
      signedName: this.state.signature,
      webAppTypeCode: "SLFDEC",
      householdMembers: sessionStorage.getItem("householdMembers")
        ? JSON.parse(sessionStorage.getItem("householdMembers"))
        : [],
      shelterExpenses: [],
      utilityExpenses: {},
      dependentCareExpenses: [],
    };

    if (
      this.props.selfDeclare.sections[0].subsections !== undefined &&
      this.props.selfDeclare.sections[0].subsections.length > 0
    ) {
      this.props.selfDeclare.sections[0].subsections.map((section) => {
        if (section.id === "dependent-care-costs" && section.subsections) {
          reportedChanges = TextProps.VALUE_TRUE;
          let newDepententCostArray = [];
          //payee iterator
          if (
            section.questions[0].Answer !== undefined &&
            section.questions[0].Answer !== ""
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
            if (
              section.subsections !== undefined &&
              section.subsections.length > 0
            ) {
              //payee iterator
              if (
                section.questions[0].Answer !== undefined &&
                section.questions[0].Answer !== ""
              ) {
                section.questions[0].Answer.map((payee, index) => {
                  let subSectionData = section.subsections[index];
                  let memberData = payee.split("'")[0];
                  memberData = memberData.split(" ");
                  let name = "";
                  for (let ind = 0; ind < memberData.length; ind++) {
                    if (ind + 1 === memberData.length) name += memberData[ind];
                    else name += memberData[ind] + " ";
                  }
                  let memberObj = this.getMemberDataObjectByName(
                    name,
                    this.householdMembers
                  );
                  let indexOfPayee =
                    section.questions[0].options.indexOf(payee);
                  if (
                    section.questions[0].conditionals[indexOfPayee]
                      .conditionalQuestions[0].Answer !== ""
                  ) {
                    let dependentName =
                      section.questions[0].conditionals[indexOfPayee]
                        .conditionalQuestions[0].Answer;

                    dependentName.map((dependent) => {
                      let dependentObj = this.getMemberDataObjectByName(
                        dependent,
                        this.householdMembers
                      );
                      let transportDetail = [];
                      if (subSectionData.questions[2].Answer === "Yes") {
                        for (
                          let k = 0;
                          k <
                          parseInt(
                            subSectionData.questions[2].conditionals[0]
                              .conditionalQuestions[0].Answer
                          );
                          k++
                        ) {
                          let transportData = subSectionData.subsections[k];
                          let transportObj = {
                            address: {
                              typeCode: "CARE",
                              line1: transportData.questions[0].Answer.street,
                              city: transportData.questions[0].Answer.city,
                              state: transportData.questions[0].Answer.state,
                              zip: transportData.questions[0].Answer.zipCode,
                            },
                            driveTripCount:
                              transportData.questions[1].Answer.amount,
                            driveFrequencyCode:
                              transportData.questions[1].Answer.unit !==
                              undefined
                                ? getFrequencyCode(language)[
                                    transportData.questions[1].Answer.unit
                                  ]
                                : "",
                          };
                          transportDetail.push(transportObj);
                        }
                      }
                      let newDependentCost = {
                        payee: {
                          agencyID: memberObj.agencyID,
                          firstName: memberObj.firstName,
                          lastName: memberObj.lastName,
                          granteeFlag: memberObj.granteeFlag,
                          dateOfBirth: this.convertDOBForDataPost(
                            memberObj.dateOfBirth
                          ),
                        },
                        dependent: {
                          agencyID: dependentObj.agencyID,
                          firstName: dependentObj.firstName,
                          lastName: dependentObj.lastName,
                          granteeFlag: dependentObj.granteeFlag,
                          dateOfBirth: this.convertDOBForDataPost(
                            dependentObj.dateOfBirth
                          ),
                        },
                        providerCareAmount:
                          subSectionData.questions[0].Answer.amount,
                        providerCareFrequencyCode:
                          subSectionData.questions[0].Answer.unit !== undefined
                            ? getFrequencyCode(language)[
                                subSectionData.questions[0].Answer.unit
                              ]
                            : "",
                        transportationPayFlag:
                          subSectionData.questions[1].Answer === "Yes"
                            ? TextProps.VALUE_TRUE
                            : TextProps.VALUE_FALSE,
                        transportationAmount:
                          subSectionData.questions[1].conditionals[0]
                            .conditionalQuestions[0].Answer &&
                          subSectionData.questions[1].conditionals[0]
                            .conditionalQuestions[0].Answer.amount
                            ? subSectionData.questions[1].conditionals[0]
                                .conditionalQuestions[0].Answer.amount
                            : null,
                        transportationFrequencyCode:
                          subSectionData.questions[1].conditionals[0]
                            .conditionalQuestions[0].Answer &&
                          subSectionData.questions[1].conditionals[0]
                            .conditionalQuestions[0].Answer.unit !== undefined
                            ? getFrequencyCode(language)[
                                subSectionData.questions[1].conditionals[0]
                                  .conditionalQuestions[0].Answer.unit
                              ]
                            : "",
                        drivetoProviderFlag:
                          subSectionData.questions[2].Answer === "Yes"
                            ? TextProps.VALUE_TRUE
                            : TextProps.VALUE_FALSE,
                        drivetoProviderCount: parseInt(
                          subSectionData.questions[2].conditionals[0]
                            .conditionalQuestions[0].Answer
                        ),
                        transportDetails: transportDetail,
                      };
                      newDepententCostArray.push(newDependentCost);
                    });
                  }
                });
              }
            }
          }
          requestBody.dependentCareExpenses = newDepententCostArray;
        } else if (section.id === "shelter-costs" && section.subsections) {
          reportedChanges = TextProps.VALUE_TRUE;
          let newShelterCostArray = [];
          for (let questionArray of section.subsections[0].questions) {
            if (
              questionArray.Answer !== "" &&
              questionArray.Answer.length > 0
            ) {
              questionArray.Answer.map((ans) => {
                let newShelterCost = {
                  typeCode: questionArray.valueToMatch,
                  frequencyCode:
                    getFrequencyCodeSelfDeclare(language)[ans.unit],
                  amount: ans.amount,
                };
                newShelterCostArray.push(newShelterCost);
                return newShelterCostArray;
              });
            }
          }
          requestBody.shelterExpenses = newShelterCostArray;
        } else if (section.id === "utility-costs") {
          reportedChanges = TextProps.VALUE_TRUE;
          let utilityExp = {};
          if (
            section.questions[0].Answer &&
            section.questions[0].Answer[0].length > 0
          ) {
            utilityExp = {
              heatFlag:
                section.questions[0].Answer.indexOf("payHeat") >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
              acFlag:
                section.questions[0].Answer.indexOf("payAC") >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
              acFeeFlag:
                section.questions[0].Answer.indexOf("payACFee") >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
              electricOrGasFlag:
                section.questions[0].Answer.indexOf("elgas") >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
              phoneFlag:
                section.questions[0].Answer.indexOf("hasPhone") >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
              noUtilityFlag:
                section.questions[0].Answer.indexOf(
                  selfDeclarePageLabels(
                    language,
                    languageConstants.utilityCosts
                  ).utilitiesClearOption
                ) >= 0
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE,
            };
          }
          requestBody.utilityExpenses = utilityExp;
        }
      });
    }

    if (reportedChanges) {
      requestBody.dataChangeCode = "NEW";
      requestBody.languageCode = language.split("-")[0];
      requestBody.signedName = this.state.signature;
      requestBody.webAppTypeCode = "SLFDEC";
    }

    this.setState(
      {
        reportedChanges: reportedChanges,
        requestBody: requestBody,
      },
      () => {
        // This is intentional
      }
    );
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      applySanpQuestionsSections: applySanpQuestionsSections,
      appendDeclareExpenseSubsections: appendDeclareExpenseSubsections,
      appendIncomeDetailsSelf: appendIncomeDetailsSelf,
      appendExpenseDetails: appendExpenseDetails,
      downloadPDFData: downloadPDFData,
      updateCurrentSectionData: updateCurrentSectionData,
      setSDDataChangeStatus: setSDDataChangeStatus,
      submitSelfDeclareData: postSelfDeclareData,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoHome: () => push(routeProps.HOME_PATH),
      displayError: () => push(routeProps.API_ERR_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
    },
    dispatch
  );
}

export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(SelfDeclare)
);
