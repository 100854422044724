import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import Terms from "../../../utils/components/terms";
import Checkbox from "../../../utils/components/checkbox";
import DTAInputSubmit from "../../../utils/components/dtaInputSubmit";
import { beforeYoustartMlanguageLabels } from "../applySnapText";
import * as TextProps from "../../../utils/constants/text";
import { langOptSel } from "../../../utils/constants/constants";
import Radio from "../../../utils/components/radio";
//end code for google analytics
let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

class SignAndSubmit extends Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div>
        <div key={this.nextUniqueId()} className="pad-all--double">
          {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}

          {/* Clicking 'View your Application Summary' button will download PDF summary */}

          <form id={this.props.submitFormId}>
            <Terms id="submit-1" className="dta-form__label" />
            <div>
              {this.props.agreeError ? (
                  <span className="dta-form__error-message">{this.props.agreeErrorMsg}</span>
              ) : (
                <p></p>
              )}
            </div>
            <Checkbox
              className="dta-form__option-list"
              optionClass="dta-form__option"
              aria-labelledby="submit-1"
              options={[
                beforeYoustartMlanguageLabels(language).iagreeTermsLabel,
              ]}
              ref="isAcceptTerms"
              onChange={this.props.acceptTermsConditions}
              required={TextProps.VALUE_TRUE}
              error={this.props.agreeError}
            />
            <hr className="divider" />
            <p>{beforeYoustartMlanguageLabels(language).consentForDHRLabel}</p>
            <div>
              {this.props.consentForDHRVerifyError ? (
                  <span className="dta-form__error-message">{this.props.consentForDHRVerifyErrorMsg}</span>
              ) : (
                <p></p>
              )}
              </div>
            <Radio
              id="consentForDHR"
              name="consentForDHR"
              className="dta-form__option-list"
              optionClass="dta-form__option dta-form__option--width-1-of-2"
              options={beforeYoustartMlanguageLabels(language).optionYesNo}
              required={TextProps.VALUE_TRUE}
              value={''}
              onChange={(value) => this.props.onConsentDHRChange(value)}
            />
            <hr className="divider" />
            <label className="dta-form__label" htmlFor="sign-app">
              <p>
                {beforeYoustartMlanguageLabels(language).signYourAppllication}
              </p>
            </label>
            {/* Clicking will submit the application and route user to follow up page */}
            <div>
              {this.props.signatureError ? (
                  <span className="dta-form__error-message">
                    {this.props.signatureErrorMsg}
                  </span>
              ) : (
                <p></p>
              )}
            </div>
            <DTAInputSubmit
              className="dta-form__control"
              id="sign-app"
              type="specialTextType"
              maxLength={30}
              value={this.props.signature}
              ref="applicanSign"
              onChange={this.props.updateSignature}
              required={TextProps.VALUE_TRUE}
              error={this.props.signatureError}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default SignAndSubmit;
