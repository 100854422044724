import axios from "axios";
import sections from "../../pages/recertCovid/recertCovidMapData";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;
var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  applyIRSnapPagesAction: "COVID_RECERT_APPLY_SNAP_FULLFILLED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  appendIRHouseHold: "APPEND_COVID_HOME_HOLD_DATA",
  appendIRIncome: "APPEND_COVID_INCOME_DATA",
  appendUIIncome: "APPEND_UI_INCOME_DATA",
  appendIRExpense: "APPEND_COVID_EXPENSE_DATA",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  downloadIRPdfAction: "DOWNLOAD_PDF_COVID_APPLICATION",
  setContactInfo: "COVID_SET_CONTACT_INFO",
  setChildSupportData: "COVID_SET_CHILD_SUPPORT",
  setHouseholdMembers: "COVID_SET_HOUSEHOLD_MEMBERS",
  addStudentToList: "COVID_ADD_STUDENT_TO_LIST",
  addContactInfoToSections: "COVID_ADD_ADD_CONTACT_INFO_SECTION",
  addHouseholdInfoToSections: "COVID_ADD_ADD_HOUSEHOLD_INFO_SECTION",
  setDataChangeStatus: "COVID_ADD_DATA_CHANGE_STATUS",
  //Earned income
  setEarnedIncomeStatus: "SET_EARNED_INCOME_STATUS",
  setUnearnedIncomeStatus: "SET_UNEARNED_INCOME_STATUS",
  setChildSupportChanged: "SET_CHILD_SUPPORT_CHANGED",
  setInterimReportResponse: "SET_INTERIM_REPORT_RESPONSE",
  postInterimDataFulfilled: "POST_INTERIM_DATA_FULFILLED",
};

export function applyIRSanpQuestionsSections() {
  return (dispatch) => {
    return dispatch(applyIRSnapSections(sections));
  };
}

export function snapRefreshQuestionsSections(originalSections) {
  return (dispatch) => {
    return dispatch(applyIRSnapSections(originalSections));
  };
}

function applyIRSnapSections(sectionsVal) {
  return {
    type: actionTypes.applyIRSnapPagesAction,
    payload: sectionsVal,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}
function questionsInputValuesAction(sectionsValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsValue,
  };
}

//Household Action
export function appendIRHouseHoldMembers(subMembers) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(houseHoldSuccess(subMembers));
  };
}

function houseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendIRHouseHold,
    payload: houseHold,
  };
}

//Appened Income
export function appendIRIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(incomeSuccess(income));
  };
}

function incomeSuccess(income) {
  return {
    type: actionTypes.appendIRIncome,
    payload: income,
  };
}

//Appened Income
export function appendUIIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(uiIncomeSuccess(income));
  };
}

function uiIncomeSuccess(income) {
  return {
    type: actionTypes.appendUIIncome,
    payload: income,
  };
}

//Appened Expense
export function appendIRExpenseDetails(expense) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(expenseSuccess(expense));
  };
}

function expenseSuccess(expense) {
  return {
    type: actionTypes.appendIRExpense,
    payload: expense,
  };
}

export function clearHouseHoldMembers(
  houseHoldSubMemberTemplate,
  houseHoldSubDetailTemplate
) {
  return (dispatch) => {
    let detailTemplate = [];
    detailTemplate.push(houseHoldSubDetailTemplate);
    return dispatch(houseHoldSuccess(detailTemplate));
  };
}

//Clearing Income Template
export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(incomeSuccess(incomeTemplate));
  };
}

//Clearing Income Template
export function clearMemberUIIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(uiIncomeSuccess(incomeTemplate));
  };
}

export function updateHouseholdTextChanges(householdDetails) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(appendIRHouseHoldTextChanges(householdDetails));
  };
}

function appendIRHouseHoldTextChanges(householdDetails) {
  return {
    type: actionTypes.appendIRHouseHold,
    payload: householdDetails,
  };
}

export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_APPLY_ANSER",
      payload: data,
    });
  };
}

export function postInterimReportData(answerObject, auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/covid19recert";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, answerObject, TigerOptions)
        .then((response) => {
          dispatch(submitInterimReportDataAction(response.data));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function submitInterimReportDataAction(response) {
  return {
    type: actionTypes.postInterimDataFulfilled,
    payload: response,
  };
}

//
export function downloadIRPDFData(sectionsObj) {
  return (dispatch) => {
    axios
      .post(
        BaseUrl + "/interimReport/downloadPDFData",
        sectionsObj,
        HttpOptions
      )
      .then(function (data) {
        if (data !== undefined)
          toShowPDFinWindow(data, sectionsObj[0].webAppId);
      });
  };
}
/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, webAppId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    window.navigator.msSaveOrOpenBlob(blob, "SnapApply_" + webAppId + ".pdf");
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export function setContactInfo(contactInfoObj) {
  return (dispatch) => {
    dispatch(setContactInfoObjIR(contactInfoObj));
  };
}

function setContactInfoObjIR(contactInfoObj) {
  return {
    type: actionTypes.setContactInfo,
    payload: contactInfoObj,
  };
}

export function setChildSupportData(childObj) {
  return (dispatch) => {
    dispatch(setChildSupportObjIR(childObj));
  };
}

function setChildSupportObjIR(childObj) {
  return {
    type: actionTypes.setChildSupportData,
    payload: childObj,
  };
}

export function setHouseholdMembers(houseHoldObj) {
  return (dispatch) => {
    dispatch(setHouseholdMembersListIR(houseHoldObj));
  };
}

function setHouseholdMembersListIR(houseHoldObj) {
  return {
    type: actionTypes.setHouseholdMembers,
    payload: houseHoldObj,
  };
}

export function addStudentToList(studentObj) {
  return (dispatch) => {
    dispatch(addStudentObjIR(studentObj));
  };
}

function addStudentObjIR(studentObj) {
  return {
    type: actionTypes.addStudentToList,
    payload: studentObj,
  };
}

export function addContactInfoSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addContactInfoToSectionsIR(subSectionObj));
  };
}

function addContactInfoToSectionsIR(subSectionObj) {
  return {
    type: actionTypes.addContactInfoToSections,
    payload: subSectionObj,
  };
}

export function addHouseholdAddSectionToList(houseHoldAddObj) {
  return (dispatch) => {
    dispatch(addHouseholdToSectionsIR(houseHoldAddObj));
  };
}

function addHouseholdToSectionsIR(houseHoldAddObj) {
  return {
    type: actionTypes.addHouseholdInfoToSections,
    payload: houseHoldAddObj,
  };
}

export function setEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setEarnedIncomeStatus(status));
  };
}

function setEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setEarnedIncomeStatus,
    payload: status,
  };
}

export function setChildSupportChangedStatus(status) {
  return (dispatch) => {
    dispatch(setChildSupportStatus(status));
  };
}

function setChildSupportStatus(status) {
  return {
    type: actionTypes.setChildSupportChanged,
    payload: status,
  };
}

export function setUnearnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setUnearnedIncomeStatus(status));
  };
}

function setUnearnedIncomeStatus(status) {
  return {
    type: actionTypes.setUnearnedIncomeStatus,
    payload: status,
  };
}

export function setDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatusIR(status, index));
  };
}

function setChangeStatusIR(status, index) {
  return {
    type: actionTypes.setDataChangeStatus,
    payload: status,
    index: index,
  };
}

export function setInterimReportResponseObj(payload) {
  return (dispatch) => {
    dispatch(setIRResponseObjToState(payload));
  };
}

function setIRResponseObjToState(payload) {
  return {
    type: actionTypes.setInterimReportResponse,
    payload: payload,
  };
}

export function displayPDFData(noticeId, auditData, agencyId) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(
        config.TigerContextURL + "/notices/" + agencyId + "/" + noticeId,
        TigerOptions
      )
      .then(checkStatus)
      .then(function (data) {
        if (data !== undefined) {
          let stream = {
            data: {
              base64: data.data.stream,
            },
          };
          toShowPDFinWindow(stream, noticeId);
        }
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}
