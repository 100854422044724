import axios from "axios";
import { getAudit } from "../constants/audit";

var TigerURL = require("config/appConfig").TigerURL;

const request = axios.create({
  baseURL: TigerURL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

request.interceptors.request.use(
  (config) => {
    const audit = getAudit();
    config.headers["audit"] = JSON.stringify(audit);

    const idToken = window.sessionStorage.getItem("id_token");
    if (idToken) {
      config.headers["Authorization"] = "Bearer " + idToken;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log("request error", error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // Do something with response data
    console.log("response", response);
    return response.data;
  },
  (error) => {
    // Do something with response error
    // console.log("response error",error);

    return Promise.reject(error);
  }
);

export default request;
