//Global Routes
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
//azure provider
import { withMsal } from "@azure/msal-react";
import { setConfig } from '../../utils/components/utilities/utils';

import MainHome from "../home/home";
import Apply from "../applySnap/applySnapContainer";
import ApplyTafdc from "../applyTafdc/applyTafdcContainer";
import ApplyEaedc from "../applyEaedc/applyEaedcContainer";
import NotFound from "../../utils/components/notFound";
import Recertification from "../recertification/recertificationContainer";
import InterimReport from "../recertInterimReport/recertInterimReportContainer";
import CovidRecert from "../recertCovid/recertCovidContainer";
import selfDeclare from "../selfdeclare/selfdeclareContainer";
import APIError from "../../utils/components/apiError";
// import Login from './loginContainer';
import ConsumerSignUp from "./consumerSignUpContainer";
import ConsumerAccount from "../consumer/components/consumerAccount";
import BenefitDetails from "../provider/components/benefitDetails";
import Screening from "../screening/screeningContainer";
import PEBTHome from "../applyPebt/pebtHome";
import RoleBasedAccessControl from "./roleBasedAccessControl";
import ForgotPassword from "./forgotPasswordContainer";
import * as routesConstants from "../../utils/constants/routesConstants";
import pebtReplacementRequest from "../applyPebt/components/replacementRequest";
import AccountBalanceInfo from "../applyPebt/components/accountBalanceInfo";
import ReportChangeContainer from "../reportChange/ReportChangeContainer";
import Login from "./loginContainer";
//import anonymousConcernsSubmit from "../consumer/components/anonymousConcern/anonymousConcernSubmit";
import Dsnap from "../applyDsnap/applyDsnapContainer";
// import AnonymousConcernsContainer from"../consumer/components/anonymousConcern/anonymousConcernsContainer.js"

//Main App Class
class Router extends Component {
  componentDidMount() {
    setConfig();
  }
  render() {
    // if user already logged in and then tries to access login/signup page, they redirects to home page
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var path = window.location.pathname;
    if (userInfo && path === routesConstants.SIGNUP_PATH) {
      return <Redirect to={routesConstants.HOME_PATH} />;
    }
    const recertType = sessionStorage.getItem('recertType');
    return (
      <Switch>
        <Route exact path={routesConstants.HOME_PATH} component={MainHome} />
        <Route
          exact
          path={routesConstants.SCREENING_PATH}
          component={Screening}
        />
        <Route exact path={routesConstants.APPLY_PATH} component={Apply} />
        <Route
          exact
          path={routesConstants.APPLY_TAFDC_PATH}
          component={ApplyTafdc}
        />
        <Route
          exact
          path={routesConstants.APPLY_EAEDC_PATH}
          component={ApplyEaedc}
        />
        <Route
          exact
          path={recertType === "REEVAL" ? routesConstants.TAFDC_PATH : recertType === "IR" ? routesConstants.SNAP_SIX_MONTH_REPORT_PATH : routesConstants.RECERTIFICATION_PATH}
          component={Recertification}
        />
        <Route
          exact
          path={routesConstants.COVID_RECERT_PATH}
          component={CovidRecert}
        />
        <Route
          exact
          path={routesConstants.DECLARE_EXPENSES_PATH}
          component={selfDeclare}
        />
        <Route exact path={routesConstants.PEBT_PATH} component={PEBTHome} />
        <Route
          exact
          path={routesConstants.APPLY_WEBAPP_ID_PATH}
          component={Apply}
        />
        <Route
          exact
          path={routesConstants.SIGNUP_PATH}
          component={ConsumerSignUp}
        />
        <Route exact path="/home" component={MainHome} />
        <Route
          path={routesConstants.FORGOT_PASSWORD_PATH}
          component={ForgotPassword}
        />
        <Route
          path={routesConstants.CONSUMER_CONTACT_INFO_PATH}
          component={ConsumerAccount}
        />
        <Route
          exact
          path={routesConstants.BENEFIT_DETAILS_PATH}
          component={BenefitDetails}
        />
         <Route
          exact
          path={routesConstants.REPORTCHANGEPATH}
          component={ReportChangeContainer}
        />
        
      {/*  <Route
          exact
          path={routesConstants.CUSTOMER_CONCERNS}
          component={AnonymousConcernsContainer}
        />*/}

        {/* <Route
        exact
        path={routesConstants.CUSTOMER_CONCERNS_SUBMIT}
        component={anonymousConcernsSubmit}
      /> */}
        <Route path={routesConstants.API_ERR_PATH} component={APIError} />
        {window.location.pathname === "/pebtCaseInfo" && (
          <Route
            path={routesConstants.PEBT_CASE_INFO}
            component={AccountBalanceInfo}
          />
        )}
        {window.location.pathname === "/replacepebt" && (
          <Route
            path={routesConstants.PEBT_REPLACEMENT}
            component={pebtReplacementRequest}
          />
        )}
        <Route path={routesConstants.LOGIN_PATH} component={Login} />
        <Route path={routesConstants.APPLY_DSNAP} component={Dsnap} />
        <RoleBasedAccessControl />
        {/*NotFound Route should be at last*/}
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default withMsal(Router);