// Vendor / Components
import React, { Component } from "react";
import Collapse from "react-collapse";
import _ from "lodash";

// DHR Components
import Icon from "../../../utils/components/dtaIcon";
import ControlWrapper from "../../../utils/components/controlWrappers/controlWrapper";
import ListWrapper from "../../../utils/components/list/listWrapper";
import RequestAccountAccessModal from "./requestAccountAccessModal";
import * as formatters from "../../../utils/components/utilities/formatters";
import {
  providerMyClientDetailsInfo,
  saveRequestAccountAccessUserInfo,
  multilanguageSelect,
} from "../../../redux/provider/providerMyClientsActions";
// Constants
import * as Props from "../../../utils/components/shared";
import * as SharedProps from "../../../utils/constants/shared";
import * as roleProps from "../../../utils/constants/rolesConstants";

// Static data
import * as FiltersData from "../providerSearchFiltersMapData";

// redux implementations
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import Pagination from "../../../utils/components/pagination";
import * as TextProps from "../../../utils/constants/text";

// NOTE: RAA = Request Account Access

var consumerEmail = JSON.parse(sessionStorage.getItem("userInfo"));

class ProviderHome extends Component {
  constructor(props) {
    super(props);

    this.fileIncrementer = 0;

    this.state = {
      // User should really come from redux or elsewhere
      activePage: 1,
      startIndex: 0,
      endIndex: 20,
      displayPage: 20,
      [SharedProps.USER]: {
        [SharedProps.FIRST_NAME]: "Jennifer",
        [SharedProps.LAST_NAME]: "Patel",
        [SharedProps.DOB]: new Date(1989, 9, 13),
      },
      clients: [],
      backUpOfClients: [],
      selectedFilters: [],
      searchValue: "",
      helpOpen: TextProps.VALUE_FALSE,
      RAAModalOpen: TextProps.VALUE_FALSE,
      requestAccessForUser: {},
      filesToUpload: [],
      clientFilters: { options: [] },
      submitToDTA: TextProps.VALUE_FALSE,
      showCheckError: TextProps.VALUE_FALSE,
      isCheckRequired: TextProps.VALUE_FALSE,
      myClientsError: TextProps.VALUE_FALSE,
      loading: TextProps.VALUE_TRUE,
      sorting: 0,
    };
    this.closeRAAModal = this.closeRAAModal.bind(this);
    this.openRAAModal = this.openRAAModal.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
  }

  toggleHelp = () => {
    this.setState({ helpOpen: !this.state.helpOpen });
  };

  refreshList = () => {
    var arrOfObj = this.props.provider.providerMyClients;
    var providerClientListData = arrOfObj.map(function (el) {
      var o = Object.assign({}, el);
      o.type = "providerClient";
      return o;
    });
    this.setState({
      searchValue: "",
      startIndex: 0,
      endIndex: 20,
      activePage: 1,
      selectedFilters: [],
      sorting: 0,
      clients: providerClientListData,
    });
  };

  sortingClients = () => {
    if (this.state.sorting === 0 || this.state.sorting === 2) {
      const sortedList = this.state.clients.sort((a, b) =>
        a.user.personName.last.localeCompare(b.user.personName.last)
      );
      console.log(sortedList);
      this.setState({ sorting: 1, clients: sortedList });
    } else if (this.state.sorting === 1) {
      const sortedList = this.state.clients.sort((a, b) =>
        b.user.personName.last.localeCompare(a.user.personName.last)
      );
      this.setState({ sorting: 2, clients: sortedList });
    }
  };

  openRAAModal = (user) => {
    this.setState({
      requestAccessForUser: user,
      RAAModalOpen: TextProps.VALUE_TRUE,
      submitToDTA: TextProps.VALUE_FALSE,
      showCheckError: TextProps.VALUE_FALSE,
      isCheckRequired: TextProps.VALUE_FALSE,
    });
  };

  goToConsumer = () => {
    sessionStorage.setItem("consumerAccess", TextProps.VALUE_TRUE);
  };

  closeRAAModal = () => {
    this.setState({
      RAAModalOpen: TextProps.VALUE_FALSE,
      filesToUpload: [],
      submitToDTA: TextProps.VALUE_FALSE,
      showCheckError: TextProps.VALUE_FALSE,
      isCheckRequired: TextProps.VALUE_FALSE,
    });
  };

  handleFileInputChange = (event) => {
    const inputEl = event.target,
      files = Array.from(inputEl.files);

    files.forEach((file) => {
      file[SharedProps.ID] = `upload-file-${this.fileIncrementer}`;
      this.fileIncrementer += 1;
    });

    this.setState(
      {
        filesToUpload: this.state.filesToUpload.concat(files),
      },
      () => {
        inputEl.value = null;
      }
    );
  };

  handleRemoveFile = (fileToRemove) => () => {
    const newFileList = this.state.filesToUpload.filter(
      (file) => file[SharedProps.ID] !== fileToRemove[SharedProps.ID]
    );

    this.setState({ filesToUpload: newFileList });
  };

  hideLoader(flag) {
    this.setState({ loading: flag });
  }

  /**
   * @param {*} nextProps
   * @return clients - list of applications , clientFilters - based applications status counting the records
   */
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.provider.providerMyClients !== undefined &&
      nextProps.provider.providerMyClients.length > 0
    ) {
      var arrOfObj = nextProps.provider.providerMyClients;
      var providerClientListData = arrOfObj.map(function (el) {
        var o = Object.assign({}, el);
        o.type = "providerClient";
        return o;
      });
      this.setState({
        clients: providerClientListData,
        clientFilters: nextProps.provider.clientFilters,
        backUpOfClients: providerClientListData,
        selectedFilters: [],
      });
    }
  }
  /**
   * @return mounting the data (no.of applications) from service
   */
  componentDidMount() {
    this.loadProviderMyClientDetailsInfo();
  }

  fallbackProviderMyClients = () => {
    var email = null;
    const userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userObject !== undefined) email = userObject.email;
    if (email !== undefined) {
      this.hideLoader(true);
      this.setState({ myClientsError: false });
      this.props
        .providerMyClientDetailsInfo(email)
        .then((response) => {
          this.hideLoader(false);
        })
        .catch((err) => {
          this.hideLoader(false);
          this.setState({ myClientsError: true });
        });
    }
  };

  loadProviderMyClientDetailsInfo = () => {
    var email = null;

    const userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    this.props.multilanguageSelect();
    if (userObject !== undefined) email = userObject.email;
    if (email !== undefined) {
      this.hideLoader(true);
      this.props
        .providerMyClientDetailsInfo(email)
        .then((response) => {
          this.hideLoader(false);
        })
        .catch((err) => {
          if (
            this.props.provider.error.error.isError &&
            this.props.provider.error.error.code === 502
          ) {
            this.fallbackProviderMyClients();
          } else {
            this.hideLoader(false);
            this.setState({ myClientsError: true });
          }
        });
    } else {
      this.hideLoader(false);
      this.setState({ myClientsError: true });
    }
  };

  submitRAA = (
    ref,
    startDate,
    endDate,
    clientId,
    webAppId,
    webApplicationArray
  ) => {
    ref.showBlur("loading", "Submitting...");
    var startDateFormat =
      formatters.convertStringDateToWithNewFormat(startDate);
    var endDateFormat = formatters.convertStringDateToWithNewFormat(endDate);
    var userInfoDataObj = JSON.parse(sessionStorage.getItem("userInfo"));
    var userRole = userInfoDataObj.roleList[1]
      ? userInfoDataObj.roleList[1].roleName
      : userInfoDataObj.roleList[0].roleName;

    if (userRole !== roleProps.PROVIDER_ROLE_SPVUSR) {
      var user = {
        accountID: clientId !== undefined && clientId !== null ? clientId : 0,
        agencyID: clientId !== undefined && clientId !== null ? clientId : 0,
        email: userInfoDataObj.email,
        endDate: endDateFormat,
        orgID: "0",
        startDate: startDateFormat,
        vgLogID: "0",
        vgOrgID: "0",
        vgOrgName: "vgOrgName",
        vgStatusCode: "ACTIVE",
        vgUserID: userInfoDataObj.email,
        webAppID: webAppId,
        applicationSourceForPSIS: webApplicationArray,
      };

      this.props.saveRequestAccountAccessUserInfo(user);
      // This timeout simulates the POST request being finished
      setTimeout(() => {
        ref.showBlur("success", "Submitted!");
        setTimeout(() => {
          this.loadProviderMyClientDetailsInfo();
        }, 5000);
      }, 3000);
    }
  };

  loadViewingPage = () => {
    if (this.state.clients.length === 0) {
      return this.state.clients.length;
    } else if (this.state.endIndex <= this.state.clients.length) {
      return this.state.endIndex;
    } else if (this.state.clients >= 20) {
      return this.state.clients.length;
    } else {
      return this.state.clients.length;
    }
  };

  render() {
    const { search } = this.props.language.provider;
    const { home } = this.props.language.provider;
    const { myClientsError, loading } = this.state;
    if (loading) return helperFunction.isLoading(loading);
    if (myClientsError) {
      return (
        <div>
          <h1>{search.submitError}</h1>
        </div>
      );
    }
    return (
      <div>
        <div className="max-width max-width--three-quarters pad-all--double pad-bottom-none">
          <ControlWrapper
            type={FiltersData.CLIENT_FILTERS[Props.TYPE]}
            data={this.state.clientFilters}
            className="margin-bottom"
            questionClass="text--bold text--uppercase text--medium text--gray"
            optionClass="dta-checkbox--button-with-icon dta-checkbox--button-with-icon--quarters dta-checkbox--button-with-icon--center"
            textClass="dta-checkbox--button-with-icon__text text--center"
            doBuildOptionItemContents={this._buildFilterItem}
            value={this.state.selectedFilters}
            onChange={this._handleChangeForFilters}
            labelHidden={TextProps.VALUE_TRUE}
          />
          <ControlWrapper
            labelHidden
            className="pure-u-1 margin-right-half"
            controlContainerClass="margin-bottom"
            type={FiltersData.SEARCH_CLIENTS_HOME[Props.TYPE]}
            data={FiltersData.SEARCH_CLIENTS_HOME}
            value={this.state.searchValue}
            onChange={this._handleChangeForSearch}
          />
          <div className="provider-client-list-header">
            <h1 className="provider-client-list-header__title text--uppercase text--gray provider-title">
              {home.myClients}
            </h1>
            <div className="provider-client-list-header__label">
              <div className="provider-client-list-header__label-text text--gray">
                {home.viewing}{" "}
                {this.state.selectedFilters.length > 0
                  ? `${this._getSelectedFiltersLabel()} ${
                      this.state.startIndex + 1
                    } - ${this.loadViewingPage()} ${home.ofLabel} ${
                      this.state.clients.length
                    } ${home.records}`
                  : `${
                      this.state.clients.length === 0
                        ? 0
                        : this.state.startIndex + 1
                    } - ${this.loadViewingPage()} ${home.ofLabel} ${
                      this.state.backUpOfClients.length
                    } ${home.records}`}
              </div>
              <div className="provider-client-list-header__help-button refresh">
                <button
                  className="dta-button dta-button--gray dta-button--circle"
                  aria-label="Toggle help text"
                  onClick={this.refreshList}
                >
                  <Icon name="refresh" ariaHidden={TextProps.VALUE_TRUE} />
                </button>
              </div>
              <div className="provider-client-list-header__help-button">
                <button
                  className="dta-button dta-button--gray dta-button--circle"
                  aria-label="Toggle help text"
                  onClick={this.toggleHelp}
                >
                  ?
                </button>
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpened={this.state.helpOpen}>
          {this._renderHelpContent()}
        </Collapse>

        <div
          className="max-width max-width--three-quarters pad-all--double pad-top-none margin-top"
          onClick={this.goToConsumer}
        >
          <div className="pure-g">
            <div className="pure-u-2-3 pure-u-lg-2-5">
              <div className="provider-client__row">
                <div className="provider-client__column provider-client__column-name">
                  <div className="provider-client__name-container margin-bottom-quarter">
                    <span className="text--primary icon_sortspace">
                      {FiltersData.CLIENT_FILTER_CLIENT_INFORMATION_DISPLAY}
                    </span>
                    <button
                      className="dta-button--gray dta-button--minor circle-sort"
                      aria-label="sortButton"
                      onClick={this.sortingClients}
                    >
                      <Icon
                        name={
                          this.state.sorting === 0
                            ? "sort"
                            : this.state.sorting === 1
                            ? "Sort-a-z"
                            : "sort-z-a"
                        }
                        ariaHidden={TextProps.VALUE_TRUE}
                      />
                    </button>
                  </div>
                </div>
                <div className="provider-client__column provider-client__column-name">
                  <span className="text--primary">
                    {FiltersData.CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY}
                  </span>

                  <div
                    style={{ marginTop: 8 }}
                    className="provider-client__column-phone-small full-width"
                  >
                    <div className="margin-bottom-quarter">
                      <span className="text--primary">
                        {FiltersData.CLIENT_FILTER_URGENT_DISPLAY}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-u-1-3 pure-u-lg-3-5">
              <div className="pure-g">
                <div className="pure-u-1 pure-u-lg-1-3 provider-client__column provider-client__column-phone-large">
                  <span className="text--primary">
                    {FiltersData.CLIENT_FILTER_URGENT_DISPLAY}
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-1-3 provider-client__column provider-client__column-status">
                  <span className="text--primary">
                    {FiltersData.CLIENT_FILTER_CASE_STATUS_DISPLAY}
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-1-3 provider-client__column provider-client__column-status-message">
                  <span className="text--primary">
                    {FiltersData.CLIENT_FILTER_UNSUBMITTED_DISPLAY}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ListWrapper
            total={100}
            items={this.state.clients.slice(
              this.state.startIndex,
              this.state.endIndex
            )}
            onRequestAccess={this.openRAAModal}
            listItemClass="provider-client"
          />
          {this.state.clients.length > 0 ? (
            <Pagination
              activePage={this.state.activePage}
              displayPage={this.state.displayPage}
              itemsCount={this.state.clients.length}
              pageRangeDisplayed={5}
              handlePageChange={this.handlePageChange}
            />
          ) : null}

          <RequestAccountAccessModal
            requestAccessForUser={this.state.requestAccessForUser}
            isOpen={this.state.RAAModalOpen}
            onClose={this.closeRAAModal}
            filesToUpload={this.state.filesToUpload}
            onSelectFile={this.handleFileInputChange}
            onRemoveFile={this.handleRemoveFile}
            onSubmit={this.submitRAA}
            modalClass="raa-modal"
          />
        </div>
      </div>
    );
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    var page = pageNumber - 1;
    this.setState({
      activePage: pageNumber,
      startIndex: page * this.state.displayPage,
      endIndex: page * this.state.displayPage + this.state.displayPage,
    });
  };

  // Events
  // ------

  _handleChangeForFilters = (newFilters) => {
    this.setState({
      selectedFilters: newFilters,
      startIndex: 0,
      endIndex: 20,
      activePage: 1,
    });
    // taking back of original clients
    const listItems = _.cloneDeep(this.state.backUpOfClients);
    // filtering the based on selecting the application types. example urgent, expiring accounts
    let newClients = [];
    if (newFilters.length > 0) {
      for (let client of listItems) {
        for (let answerIndex of newFilters) {
          if (answerIndex === client.filterUrgent) {
            newClients.push(client);
            break;
          } else if (answerIndex === client.filterElibility) {
            newClients.push(client);
            break;
          } else if (answerIndex === client.filterExpiring) {
            newClients.push(client);
            break;
          } else if (answerIndex === client.filterUnsubmitted) {
            newClients.push(client);
            break;
          }
        }
      }
      this.setState({ clients: newClients });
    } else {
      // after unchecking the filters will populate the data
      this.setState({ clients: this.state.backUpOfClients });
    }
  };

  _handleChangeForSearch = (value) => {
    this.setState({ searchValue: value });
    let searchTotalClients = _.cloneDeep(this.state.backUpOfClients);
    // searchFilterName combine first_name, last_name to searching the values
    let searchTerm = this.state.searchValue.trim();
    if (value !== "" && value !== undefined) {
      let searchByTermClients = searchTotalClients.filter((client) => {
        var fullName =
          client.user.personName.last + client.user.personName.first;
        return fullName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      });
      this.setState({ clients: searchByTermClients });
    } else {
      // if type empty it will call list of clients
      this.setState({ clients: this.state.backUpOfClients });
    }
  };

  // Rendering
  // ---------

  _renderHelpContent = () => {
    const { home } = this.props.language.provider;
    return (
      <div className="provider-home-help">
        <div className="max-width max-width--three-quarters pad-all pad-vertical--double">
          <button
            className="dta-button dta-button--icon"
            onClick={this.toggleHelp}
          >
            <Icon name="close" />
          </button>
          <div className="pure-g">
            <div className="pure-u-1 pure-u-lg-2-5 provider-home-help__misc">
              <p>
                <Icon name="alert" /> {home.newActivity}
              </p>
              <p>
                <Icon name="warning" /> {home.accountAccess}
              </p>
              <p>
                <Icon name="sort" /> {home.alphabeticalSort}
              </p>
            </div>
            <div className="pure-u-1 pure-u-lg-2-5 provider-home-help__misc">
              <p>
                <Icon name="event" /> {home.appointmentSchedule}
              </p>
              <p>
                <Icon name="processing" /> {home.dtaProcess}
              </p>
              <p>
                <Icon name="refresh" /> {home.refresh}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  _getSelectedFiltersLabel = () => {
    let str = "";

    this.state.selectedFilters.forEach((filterValue, i) => {
      const displayValue = this._resolveDisplayValueForFilterValue(filterValue);

      str += `${
        i > 0 ? ", " : ""
      }${displayValue} (${this._showFilteringCountsValue(displayValue)})`;
    });

    return str;
  };

  _buildFilterItem = (textClass, textContents, thisCount) => {
    return (
      <div className="filter-block-container filter-block-container--column">
        <div className="text--x-large text--center margin-bottom-half">
          {this._resolveCountForFilter(textContents, thisCount)}
        </div>
        <span className={textClass}>
          {this._resolveDisplayContent(textContents)}
        </span>
      </div>
    );
  };

  _resolveDisplayContent(displayValue) {
    // Would be getting total count of clients for given type
    switch (displayValue) {
      case "Outstanding Tasks":
        return FiltersData.CLIENT_FILTER_URGENT_DISPLAY;
      case "Pending Cases":
        return FiltersData.CLIENT_FILTER_ELIBILITY_DISPLAY;
      case "Document Status":
        return FiltersData.CLIENT_FILTER_UNSUBMITTED_DISPLAY;
      case "Expiring PSI":
        return FiltersData.CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY;
      default:
        return "0";
    }
  }

  _resolveIconForFilter(displayValue) {
    switch (displayValue) {
      case FiltersData.CLIENT_FILTER_UNSUBMITTED_DISPLAY:
        return <Icon name="application" ariaHidden={TextProps.VALUE_TRUE} />;
      case FiltersData.CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY:
        return <Icon name="warning" ariaHidden={TextProps.VALUE_TRUE} />;
      default:
        return null;
    }
  }

  _resolveCountForFilter(displayValue, thisCount) {
    // Would be getting total count of clients for given type
    switch (displayValue) {
      case "Outstanding Tasks":
        return thisCount;
      case "Pending Cases":
        return thisCount;
      case "Document Status":
        return thisCount;
      case "Expiring PSI":
        return thisCount;
      default:
        return "0";
    }
  }

  _resolveDisplayValueForFilterValue(value) {
    switch (value) {
      case FiltersData.CLIENT_FILTER_URGENT_VALUE:
        return FiltersData.CLIENT_FILTER_URGENT_DISPLAY;
      case FiltersData.CLIENT_FILTER_ELIBILITY_VALUE:
        return FiltersData.CLIENT_FILTER_ELIBILITY_DISPLAY;
      case FiltersData.CLIENT_FILTER_UNSUBMITTED_VALUE:
        return FiltersData.CLIENT_FILTER_UNSUBMITTED_DISPLAY;
      case FiltersData.CLIENT_FILTER_EXPIRING_ACCESS_VALUE:
        return FiltersData.CLIENT_FILTER_EXPIRING_ACCESS_DISPLAY;
      default:
        return "";
    }
  }

  _convertDisplayValue(displayVal) {
    if (
      displayVal === "Tareas pendientes" ||
      displayVal === "Tarefas pendentes" ||
      displayVal === "待完成任务" ||
      displayVal === "Những công việc còn cần hoàn tất" ||
      displayVal === "Outstanding Tasks" ||
      displayVal === "Tach ki poko fini"
    ) {
      return "Outstanding Tasks";
    } else if (
      displayVal === "Estado del documento" ||
      displayVal === "Status do documento" ||
      displayVal === "文档状态" ||
      displayVal === "Tình trạng giấy tờ" ||
      displayVal === "Document Status" ||
      displayVal === "Estati dokiman an"
    ) {
      return "Document Status";
    } else if (
      displayVal === "PSI por vencer" ||
      displayVal === "PSI a expirar" ||
      displayVal === "即将过期的信息共享许可 (PSI)" ||
      displayVal === "PSI hết hạn" ||
      displayVal === "Expiring PSI" ||
      displayVal === "PSI ap ekspire"
    ) {
      return "Expiring PSI";
    } else if (
      displayVal === "Determining Eligibility" ||
      displayVal === "Pending Cases" ||
      displayVal === "Casos pendientes" ||
      displayVal === "Casos pendentes" ||
      displayVal === "未决案例" ||
      displayVal === "Trường hợp tồn đọng" ||
      displayVal === "Dosye Annatant"
    ) {
      return "Pending Cases";
    }
  }

  /**
   * @param {*} displayValue
   * @return count of applications based on chosen filter type.
   */
  _showFilteringCountsValue(displayValue) {
    displayValue = this._convertDisplayValue(displayValue);
    let filterItems = this.state.clientFilters;
    let filterOptions = filterItems.options;
    for (let count of filterOptions) {
      if (count.optionDisplay === displayValue) {
        return count.applicationTypeCount;
      }
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      providerMyClientDetailsInfo: providerMyClientDetailsInfo,
      multilanguageSelect: multilanguageSelect,
      saveRequestAccountAccessUserInfo: saveRequestAccountAccessUserInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderHome);
