import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import { Marker, InfoWindow } from "react-google-maps";

import { tryCall } from "../utilities/controls";
import { requiredIf } from "../utilities/proptypes";
import * as TextProps from "../../constants/text";

/*
  DTAMarker
  ---------

  # Purpose:unfinished

  # Props:
  position: Required, object with keys `lat` and `lng`, oordinates to place this marker at
  children: Required if `hasDetail` is TextProps.VALUE_TRUE, pass a single renderable node that will be rendered
            into the popup displayed when the marker is clicked
  onDetailOpen: Optional, function handler called without arguments when the details popup is opened
  onDetailClose: Optional, functional handler called without arguments when details popup is closed
  showDetail: Optional, boolean indicating whether or not to show the detail popup on first render,
              default is TextProps.VALUE_FALSE
  hasDetail: Optional, boolean indicating whether or not this marker has a detail popup or not,
             if TextProps.VALUE_TRUE then required to pass in a renderable node as a child, default is TextProps.VALUE_TRUE
  visible: Optional, boolean indicating whether or not to hide this marker on the map without
           removing the marker from the map, default is TextProps.VALUE_FALSE
  icon: Optional, string url pointing to an image that can be rendered in place of the default
        marker image

  # Example:
  <DTAMarker key={id} {...props}>
    <div>
      <span className="sr-only" id={this.getUniqueId(`${id}-name`)}>
        Name:
      </span>
      <span
        aria-labelledby={this.getUniqueId(`${id}-name`)}
        className="display-block text text--bold margin-top-half margin-bottom-half"
      >
        {name}
      </span>
      <span className="sr-only" id={this.getUniqueId(`${id}-address`)}>
        Address:
      </span>
      <span aria-labelledby={this.getUniqueId(`${id}-address`)} className="display-block">
        {address1}
        <br />
        {address2}
      </span>
    </div>
  </DTAMarker>
 */

class DTAMarker extends Component {
  static propTypes = {
    // required
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
    children: requiredIf(PropTypes.element, (props) => !!props.hasDetail),
    // handlers
    onDetailOpen: PropTypes.func,
    onDetailClose: PropTypes.func,
    // detail
    showDetail: PropTypes.bool,
    hasDetail: PropTypes.bool,
    // container
    visible: PropTypes.bool,
    icon: PropTypes.string,
  };

  static defaultProps = {
    // detail
    showDetail: TextProps.VALUE_FALSE,
    hasDetail: TextProps.VALUE_TRUE,
    // container
    visible: TextProps.VALUE_TRUE,
  };

  state = {
    showDetail: this.props.showDetail,
  };

  render() {
    const { hasDetail, visible, icon, ...otherProps } = this.props;
    return (
      <Marker
        {...otherProps}
        visible={visible}
        icon={icon}
        onClick={this._handleClickForMarker}
      >
        {hasDetail && this.state.showDetail && (
          <InfoWindow onCloseClick={this._handleClickForDetail}>
            {Children.only(this.props.children)}
          </InfoWindow>
        )}
      </Marker>
    );
  }

  // Events
  // ------

  _handleClickForMarker = () => {
    const isAlreadyOpen = this.state.showDetail;
    this.setState({ showDetail: !isAlreadyOpen });
    tryCall(isAlreadyOpen ? this.props.onDetailClose : this.props.onDetailOpen);
  };

  _handleClickForDetail = () => {
    this.setState({ showDetail: TextProps.VALUE_FALSE });
    tryCall(this.props.onDetailClose);
  };
}

export default DTAMarker;
