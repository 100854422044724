
import React, { Component } from "react";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText";
import _ from "lodash";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import {householdAddMemberTemplate} from "../reportChangeMapData";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import {signInSubmit} from '../reportChangeMapData.js';

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class Household extends Component {
    constructor(props) {
      super(props);
      this.state = {
        removeHeadModalOpen:TextProps.VALUE_FALSE,
        removeSubHeadModalOpen:TextProps.VALUE_FALSE,
        selectedMember: ""
      };
    }

    closeDeleteModal = () => {
        this.setState({ removeHeadModalOpen: TextProps.VALUE_FALSE });
      };

      closeSubDeleteModal = (action) => {
        if(action == TextProps.VALUE_TRUE){
          this.removeMember(this.state.selectedMember);
        }
        this.setState({ removeSubHeadModalOpen: TextProps.VALUE_FALSE }, () => {
          this.setState({ selectedMember: ""})
        });
      };

    showDeleteModal = () => {
        this.setState({ removeHeadModalOpen: TextProps.VALUE_TRUE });
      };

      showSubDeleteModal = (member) => {
        this.setState({ removeSubHeadModalOpen: TextProps.VALUE_TRUE, 
        selectedMember: member });
      };

      removeHead = (member) => {
        let houseHoldMembers = this.props.householdMembers;
        let filterdMembers = [];
        houseHoldMembers.forEach((HHmember) => {
          if (
            HHmember.agencyID !== member.agencyID ||
            HHmember.firstName !== member.firstName ||
            HHmember.lastName !== member.lastName ||
            HHmember.dateOfBirth !== member.dateOfBirth
          ) {
            filterdMembers.push(HHmember);
          } else {
            if (HHmember.allAnswers !== undefined) {
              console.log("NEWLY ADDED");
            } else {
              HHmember.dataChangeCode = "removed";
              filterdMembers.push(HHmember);
            }
          }
        });
        let stateObj = this.props.householdMembers;
        this.props.setHouseholdMembers(stateObj);
        this.props.updateHouseholdToState(stateObj);
        this.closeDeleteModal();
        this.props.appendSignInSections(signInSubmit);
        setTimeout( this.props.primaryActionClick(signInSubmit),100);
      };
    
    compare = (a, b) => {
        let aDate = new Date(this.props.convertStringTODate(a.dateOfBirth));
        let bDate = new Date(this.props.convertStringTODate(b.dateOfBirth));
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }
        return 0;
      };

      removeMember = (member) => {
        let members = this.props.householdMembers;
        let filterdMembers = [];
        members.forEach((mem) => {
          if (
            mem.agencyID !== member.agencyID ||
            mem.firstName !== member.firstName ||
            mem.lastName !== member.lastName ||
            mem.dateOfBirth !== member.dateOfBirth
          ) {
            filterdMembers.push(mem);
          } else {
            if (mem.allAnswers !== undefined) {
              filterdMembers = filterdMembers.filter((fmem)=>{
                    return fmem.firstName+fmem.lastName+fmem.dateOfBirth != mem.firstName+mem.lastName+mem.dateOfBirth
              });

            } else {
              mem.dataChangeCode = "removed";
              filterdMembers.push(mem);
            }
          }
        });
        let stateObj = this.props.householdMembers;
        stateObj = filterdMembers;
        this.props.setHouseholdMembers(stateObj);
        this.props.updateHouseholdToState(stateObj);
      };
    
      undoRemoveMember = (member) => {
        let members = this.props.householdMembers;
        let filterdMembers = [];
        members.forEach((mem) => {
          if (
            mem.agencyID !== member.agencyID ||
            mem.firstName !== member.firstName ||
            mem.lastName !== member.lastName ||
            mem.dateOfBirth !== member.dateOfBirth
          ) {
            // Checks house hold member details before undoing remove.
          } else {
            delete mem.dataChangeCode;
          }
          filterdMembers.push(mem);
        });
        let stateObj = this.props.householdMembers;
        stateObj.membersList = filterdMembers;
        this.props.setHouseholdMembers(stateObj);
        this.props.updateHouseholdToState(stateObj);
      };

      renderDataDeleteWarningModal = (head) => {
        return modalHelperFunction.dtaHelperModal(
          null,
          this.state.removeHeadModalOpen,
          this.closeDeleteModal,
          "save-progress-modal",
          "Delete Household Head",
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .removeHoHTitle,
          <div className="dta-modal__body pad-all">
            <p>
              {
                interimReportPageLabels(language, languageConstants.beforeYouStart)
                  .removeHoHLabel
              }
            </p>
            <p> </p>
            <div className="apply__footer pad-all--double">
              <button
                className="dta-button dta-button--outline-primary"
                onClick={() => this.closeDeleteModal()}
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).boBackButtonLabel
                }
              </button>
              <button
                className="dta-button dta-button--primary"
                onClick={() => this.removeHead(head)}
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).continueLabel
                }
              </button>
            </div>
          </div>
        );
      };

      renderSubDataDeleteWarningModal = (head) => {
        return modalHelperFunction.dtaHelperModal(
          null,
          this.state.removeSubHeadModalOpen,
          this.closeSubDeleteModal,
          "save-progress-modal",
          "Delete Household Head",
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .removeHoHTitle,
          <div className="dta-modal__body pad-all">
            <p>
              {
                interimReportPageLabels(language, languageConstants.beforeYouStart)
                  .removeNonHoHLabel
              }
            </p>
            <p> </p>
            <div className="apply__footer pad-all--double">
              <button
                className="dta-button dta-button--outline-primary"
                onClick={() => this.closeSubDeleteModal(TextProps.VALUE_FALSE)}
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).boBackButtonLabel
                }
              </button>
              <button
                className="dta-button dta-button--primary"
                onClick={() => this.closeSubDeleteModal(TextProps.VALUE_TRUE)}
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).continueLabel
                }
              </button>
            </div>
          </div>
        );
      };


      addMember = (e) => {
        let householdSubsectionObj = _.cloneDeep(householdAddMemberTemplate);
        this.props.cleanAddMemberClean();
        this.props.addHouseholdAddSectionToList(householdSubsectionObj);
        this.props.primaryActionClick(this.props.sections[0].subsections[0]);
      };
    

    render() {
        let members = [];
        let head = [];
        if (this.props.householdMembers ) {
            this.props.householdMembers.map((member) => {
              if (member.granteeFlag === TextProps.VALUE_TRUE) {
                head = member;
                return head;
              } else {
                members.push(member);
                return members;
              }
            });
          }
          members.sort(this.compare);
          
          let addMemberUI  = (
            <div className="pure-u-1-1">
              <button
                onClick={(e) => this.addMember(e)}
                className="full-width dta-button text--small interim-recert-outline-button"
              >
                <b>
                  +{" "}
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.householdStatic
                    ).addMemberButtonLabel
                  }
                </b>
              </button>
              <hr className="divider" />
            </div>
          );


        return this.props.householdMembers ? (
            <div className="pad-all--double">
              { head.firstName ? 
                <div className="pure-u-1-1">
                  <div className="max-width pure-u-6-24 pure-u-sm-8-24 label-text-clr">
                    <span>
                      {
                        interimReportPageLabels(
                          language,
                          languageConstants.householdStatic
                        ).headTitle
                      }
                    </span>
                    <br />
                    <br />
                  </div>
                  <div className="max-width pure-u-18-24 pure-u-sm-16-24">
                    <span
                      className="pure-u-18-24 household-memb-name"
                      style={{ verticalAlign: "middle" }}
                    >
                      {head.firstName + " " + head.lastName + " " + formatters.formatDate(head.dateOfBirth)}
                      {head.dataChangeCode && head.dataChangeCode === "removed" &&
                      <p>
                      <DataChangeIndicator
                                    text={
                                      interimReportPageLabels(
                                        language,
                                        languageConstants.householdStatic
                                      ).removedLabel
                                    }
                                  />
                                  </p>
    }
                    </span>
                     
                    {this.props.householdMembers ? (
                      <span className="pure-u-6-24">
                      {head.dataChangeCode && head.dataChangeCode === "removed" ? <button
                        style={{ width: "4.7rem", padding: "0.5rem" }}
                        onClick={() => this.undoRemoveMember(head)}
                        className="dta-button text--small interim-recert-outline-button"
                      >
                        {
                          interimReportPageLabels(
                            language,
                            languageConstants.householdStatic
                          ).undoButtonLabel
                        }
                      </button> : <button
                        style={{ width: "4.7rem", padding: "0.5rem" }}
                        onClick={() => this.showDeleteModal()}
                        className="dta-button dta-delete-button interim-recert-delete-button text--small"
                      >
                        {
                          interimReportPageLabels(
                            language,
                            languageConstants.householdStatic
                          ).removeButtonLabel
                        }
                      </button>
                      }
                      </span>
                    ) : (
                      TextProps.VALUE_FALSE
                    )}
                  </div>
                  {this.renderDataDeleteWarningModal(head)}
                  {this.renderSubDataDeleteWarningModal(head)}
                  <br/>
                  <br/>
                </div> : null}
      
              {members.length > 0 ? (
                <div className="pure-u-1-1">
                  <div className="max-width pure-u-6-24 pure-u-sm-8-24 label-text-clr">
                    <span>
                      {
                        interimReportPageLabels(
                          language,
                          languageConstants.householdStatic
                        ).additionalMembersTitle
                      }
                    </span>
                    <br />
                    <br />
                  </div>
      
                  <div className="max-width pure-u-18-24 pure-u-sm-16-24">
                    {members.map((member) => {
                      let color = "black";
                      let removedFlag = TextProps.VALUE_FALSE;
                      if (
                        member.dataChangeCode &&
                        (member.dataChangeCode === "removed")
                      ) {
                        color = "#be1317";
                        removedFlag = TextProps.VALUE_TRUE;
      
                        return (
                          <div className="household-memb-list-section">
                            <span
                              className="pure-u-18-24 household-memb-name"
                              style={{ verticalAlign: "middle", color: color }}
                            >
                              <span>
                                {member.firstName +
                                  " " +
                                  member.lastName +
                                  " " +
                                  formatters.formatDate(member.dateOfBirth) }
                              </span>
                              {removedFlag ? (
                                <div>
                                  <DataChangeIndicator
                                    text={
                                      interimReportPageLabels(
                                        language,
                                        languageConstants.householdStatic
                                      ).removedLabel
                                    }
                                  />
                                </div>
                              ) : member.allAnswers ? (
                                <DataChangeIndicator
                                  text={
                                    interimReportPageLabels(
                                      language,
                                      languageConstants.householdStatic
                                    ).newLabel
                                  }
                                />
                              ) : null}
                            </span>
                            {this.props.householdMembers ? (
                              <span className="pure-u-6-24">
                                <button
                                  style={{ width: "4.7rem", padding: "0.5rem" }}
                                  onClick={() => this.undoRemoveMember(member)}
                                  className="dta-button text--small interim-recert-outline-button"
                                >
                                  {
                                    interimReportPageLabels(
                                      language,
                                      languageConstants.householdStatic
                                    ).undoButtonLabel
                                  }
                                </button>
                              </span>
                            ) : (
                              TextProps.VALUE_FALSE
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div className="household-memb-list-section">
                            <span
                              className="pure-u-18-24 household-memb-name"
                              style={{ verticalAlign: "middle", color: color }}
                            >
                              {member.firstName +
                                " " +
                                member.lastName +
                                " " +
                                formatters.formatDate(member.dateOfBirth)
                                }
                              {removedFlag ? (
                                <span style={{ display: "inline-block" }}>
                                  <DataChangeIndicator
                                    text={
                                      interimReportPageLabels(
                                        language,
                                        languageConstants.householdStatic
                                      ).removedLabel
                                    }
                                  />
                                </span>
                              ) : member.allAnswers ? (
                                <span style={{ display: "inline-block" }}>
                                  <DataChangeIndicator
                                    text={
                                      interimReportPageLabels(
                                        language,
                                        languageConstants.householdStatic
                                      ).newLabel
                                    }
                                  />
                                </span>
                              ) : null}
                            </span>
                            {this.props.householdMembers  ? (
                              <span className="pure-u-6-24">
                                <button
                                  onClick={() => this.showSubDeleteModal(member)}
                                  style={{ width: "4.7rem", padding: "0.5rem" }}
                                  className="dta-button dta-delete-button interim-recert-delete-button text--small"
                                >
                                  {
                                    interimReportPageLabels(
                                      language,
                                      languageConstants.householdStatic
                                    ).removeButtonLabel
                                  }
                                </button>
                              </span>
                            ) : (
                              TextProps.VALUE_FALSE
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                  <hr className="divider" />
                </div>
              ) : null}
              {addMemberUI}
            </div>
          ) : null;
        
    }
}


export default Household;
