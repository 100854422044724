/*
  Constants specific to the HEADING non-question type. See the `types.js` file containing
  all of the possible types for full documentation of what properties
  are allowed for each type in the questions data file.
 */

// Required
export const VALUE = "value";
export const SIZE = "size";
export const SIZE_SMALL = "sizeSmall";
export const SIZE_MEDIUM = "sizeMedium";
export const SIZE_LARGE = "sizeLarge";
