import EnglishJSON from "../../utils/appText/appEnglishText.json";
import SpanishJSON from "../../utils/appText/appSpanishText.json";
import PortugueseJSON from "../../utils/appText/appPortugueseText.json";
import ChineseJSON from "../../utils/appText/appChineseText.json";
import VietnameseJSON from "../../utils/appText/appVietnamText.json";
import HaitianJSON from "../../utils/appText/appHaitianCreoleText.json";
import * as languageConsts from "../../utils/constants/types";
const EN = "en-US"; //English
const ES = "es-ES"; //spanish
const PT = "pt-BR"; //Portuguese
const ZH = "zh-Hans"; //Chinese (Simplified)
const VI = "vi-VI"; //Vietnamese
const HI = "ht"; //Haitian Creole

const actionTypes = {
  getLanguageFailed: "GET_LANGUAGE_FAILED",
  getLanguageFulfilled: "GET_LANGUAGE_FULFILLED",
  updateSelectedLanguage: "UPDATE_SELECTED_LANGUAGE",
};

//Language Update Service
export function getLanguage(languageCode) {
  return (dispatch) => {
    dispatch(selectedLanguageAction(languageCode));

    switch (languageCode) {
      case languageConsts.ENGLISH:
        dispatch(getLanguageFulfilledAction(EnglishJSON));
        break;
      case languageConsts.SPANISH:
        dispatch(getLanguageFulfilledAction(SpanishJSON));
        break;
      case languageConsts.PORTUGUESE:
        dispatch(getLanguageFulfilledAction(PortugueseJSON));
        break;
      case languageConsts.CHINESE:
        dispatch(getLanguageFulfilledAction(ChineseJSON));
        break;
      case languageConsts.VIETNAMESE:
        dispatch(getLanguageFulfilledAction(VietnameseJSON));
        break;
      case HI:
        dispatch(getLanguageFulfilledAction(HaitianJSON));
        break;
      default:
        dispatch(getLanguageFulfilledAction(EnglishJSON));
        break;
    }
  };
}

function getLanguageFulfilledAction(constantStrings) {
  return {
    type: actionTypes.getLanguageFulfilled,
    payload: constantStrings,
  };
}

function selectedLanguageAction(SelectedLanguage) {
  return {
    type: actionTypes.updateSelectedLanguage,
    payload: SelectedLanguage,
  };
}
