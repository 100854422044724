// Vendor / Components
import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
// DHR Components
import Icon from "../../dtaIcon";

// Constants / formatters
import * as SharedProps from "../../../constants/shared";
import * as BenefitProps from "../../../constants/benefit";
import {
  fullNameProvider,
  convertStringDateToDMY,
} from "../../utilities/formatters";
//navigate to consumer myInfo after clicking provider my clients
import { updateClientInfo } from "../../../../redux/consumer/consumerMyInfoActions";
import { push } from "react-router-redux";
// redux implementations
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import { getDocumentTypeDescription } from "../../../../utils/components/utilities/utils";
import * as DocumentProps from "../../../constants/document";
import * as TextProps from "../../../../utils/constants/text";
import moment from "moment";

/*
  Client ListItem contents
  --------------------------

  # Purpose:
  To display a provider's client in Provider home client list

 */

class ClientContents extends Component {
  static propTypes = {
    [SharedProps.ID]: PropTypes.string.isRequired,
    [SharedProps.USER]: PropTypes.shape({
      [SharedProps.FIRST_NAME]: PropTypes.string.isRequired,
      [SharedProps.LAST_NAME]: PropTypes.string.isRequired,
      [SharedProps.SUFFIX]: PropTypes.string,
      [SharedProps.DOB]: PropTypes.instanceOf(Date).isRequired,
      [SharedProps.PHONE]: PropTypes.string.isRequired,
      [SharedProps.ACCOUNT_ID]: PropTypes.string.isRequired,
    }).isRequired,
    [BenefitProps.CLIENT_ACTIVITY]: PropTypes.bool,
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]:
      PropTypes.instanceOf(Date).isRequired,
    [BenefitProps.STATUS]: PropTypes.oneOf([
      BenefitProps.STATUS_TYPE_ACTIVE,
      BenefitProps.STATUS_TYPE_PEND,
      BenefitProps.STATUS_TYPE_INELIGIBLE,
      BenefitProps.STATUS_TYPE_DENIED,
      BenefitProps.STATUS_TYPE_CLOSED,
    ]).isRequired,
    [BenefitProps.SUBSTATUSES]: PropTypes.arrayOf(
      PropTypes.shape({
        [BenefitProps.SUBSTATUS]: PropTypes.oneOf([
          BenefitProps.STATUS_TYPE_STARTED,
          BenefitProps.STATUS_TYPE_INTERVIEW,
          BenefitProps.STATUS_TYPE_DOCUMENTS,
          BenefitProps.STATUS_TYPE_PROCESSING,
          BenefitProps.STATUS_TYPE_EVENT,
        ]).isRequired,
        [BenefitProps.SUBSTATUS_PROBLEM]: PropTypes.bool,
      })
    ),
    [BenefitProps.STATUS_MESSAGE]: PropTypes.string.isRequired,
    [BenefitProps.STATUS_MESSAGE_URGENT]: PropTypes.bool,
    onRequestAccess: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    enableUniqueIds(this);
  }

  daysUntilAccountAccessExpires = () => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const today = moment(new Date()).format("YYYY-MM-DD");
    if (
      this.props[BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE] !== null &&
      this.props[BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE] !== undefined
    ) {
      const dateVal = moment(
        this.props[BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]
      );
      const currentDate = moment(today);
      const diffInMs = Math.abs(currentDate - dateVal);
      const days = diffInMs / _MS_PER_DAY;
      return Math.floor(days);
    } else {
      return "";
    }
  };

  redirectToConsumerClientInfo = (e) => {
    e.preventDefault();
    const user = this.props[SharedProps.USER];
    sessionStorage.removeItem("loadConsumerUser");
    sessionStorage.removeItem("clientDetails");
    // updating user information in Redux store and send to consumer account.
    this.props.updateClientInfo(user);
    // redirect to consumer myinfo page
    this.props.navigateToConsumer();
  };

  hasSubstatusOfType = (type) => {
    return !!+this.props[BenefitProps.SUBSTATUSES].filter(
      (substatus) => substatus[BenefitProps.SUBSTATUS] === type
    ).length;
  };

  substatusHasProblem = (type) => {
    return this.props[BenefitProps.SUBSTATUSES].find(
      (substatus) => substatus[BenefitProps.SUBSTATUS] === type
    )[BenefitProps.SUBSTATUS_PROBLEM];
  };

  resolveStatusTypeIcon = (type) => {
    switch (type) {
      case BenefitProps.STATUS_TYPE_STARTED:
        return <Icon name="application" ariaHidden={TextProps.VALUE_TRUE} />;
      case BenefitProps.STATUS_TYPE_INTERVIEW:
        return <Icon name="phone-call" ariaHidden={TextProps.VALUE_TRUE} />;
      case BenefitProps.STATUS_TYPE_DOCUMENTS:
        return <Icon name="documents" ariaHidden={TextProps.VALUE_TRUE} />;
      case BenefitProps.STATUS_TYPE_PROCESSING:
        return <Icon name="processing" ariaHidden={TextProps.VALUE_TRUE} />;
      case BenefitProps.STATUS_TYPE_EVENT:
        return <Icon name="event" ariaHidden={TextProps.VALUE_TRUE} />;
      default:
        return null;
    }
  };

  resolveStatusTypeScreenReaderMessage = (type) => {
    switch (type) {
      case BenefitProps.STATUS_TYPE_STARTED:
        return "Client has started an application";
      case BenefitProps.STATUS_TYPE_INTERVIEW:
        return "Client needs to be interviewed";
      case BenefitProps.STATUS_TYPE_DOCUMENTS:
        return "Client needs to submit required documents";
      case BenefitProps.STATUS_TYPE_PROCESSING:
        return "Client's application is processing";
      case BenefitProps.STATUS_TYPE_EVENT:
        return "An appointment has been scheduled";
      default:
        return null;
    }
  };

  renderStatusColumnIcon = (type) => {
    if (this.hasSubstatusOfType(type)) {
      return (
        <li
          key={type}
          className={`account-status-icons__icon-container ${
            this.substatusHasProblem(type)
              ? "account-status-icons__icon-container--problem"
              : ""
          }`}
        >
          {this.resolveStatusTypeIcon(type)}
        </li>
      );
    } else {
      return (
        <li
          key={type}
          className="account-status-icons__icon-container account-status-icons__bar-icon"
        >
          <div className="account-status-icons__bar"></div>
        </li>
      );
    }
  };

  renderStatusColumnIcons = () => {
    const types = [
      BenefitProps.STATUS_TYPE_STARTED,
      BenefitProps.STATUS_TYPE_INTERVIEW,
      BenefitProps.STATUS_TYPE_DOCUMENTS,
      BenefitProps.STATUS_TYPE_PROCESSING,
      BenefitProps.STATUS_TYPE_EVENT,
    ];
    return (
      <div className="account-status__container">
        {types.map((type) => {
          if (this.hasSubstatusOfType(type)) {
            return (
              <span className="sr-only" key={type}>
                {this.resolveStatusTypeScreenReaderMessage(type)}
              </span>
            );
          } else {
            return null;
          }
        })}
        <ul className="list--unstyled account-status-icons">
          {types.map((type) => {
            return this.renderStatusColumnIcon(type);
          })}
        </ul>
      </div>
    );
  };

  renderStatusColumnContents = (type) => {
    const { home } = this.props.language.provider;
    switch (type) {
      case BenefitProps.STATUS_TYPE_ACTIVE:
        return <span className="text--primary">{home.caseActive}</span>;
      case BenefitProps.STATUS_TYPE_INELIGIBLE:
        return (
          <span className="status-type--terminated">{home.caseIneligible}</span>
        );
      case BenefitProps.STATUS_TYPE_DENIED:
        return (
          <span className="status-type--terminated">{home.caseDenied}</span>
        );
      case BenefitProps.STATUS_TYPE_CLOSED:
        return (
          <span className="status-type--terminated">{home.caseClosed}</span>
        );
      case BenefitProps.STATUS_TYPE_PEND:
        return this.renderStatusColumnIcons();
      default:
        return <span>{home.statusUndetermined}</span>;
    }
  };

  _buildIndicator(disposition) {
    const { documentupload } = this.props.language.consumer;
    switch (disposition) {
      case DocumentProps.DISPOSITION_PENDING_REVIEW:
        return (
          <span className="status status--warningprovider">
            {documentupload.pendingReview}
          </span>
        );
      case DocumentProps.DISPOSITION_PENDING_AGENCY_REVIEW:
        return (
          <span className="status status--warningprovider">
            {documentupload.pendingAgencyReview}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_PENDING_INTERVIEW:
        return (
          <span className="status status--successprovider">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedPendingInterview}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_AND_ENTERED:
        return (
          <span className="status status--successprovider">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedAndEntered}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_DUPLICATE:
        return (
          <span className="status status--successprovider">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedDuplicate}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_NOTRELEVANT:
        return (
          <span className="status status--successprovider">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedNotRelevant}
          </span>
        );
      case DocumentProps.DISPOSITION_NOTENTERED_DUE_TO_CASESTATUS:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.notenteredDuetoCaseStatus}
          </span>
        );
      case DocumentProps.DISPOSITION_UNIDENTIFIABLE:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unidentifiable}
          </span>
        );
      case DocumentProps.DISPOSITION_UNREADABLE:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unreadable}
          </span>
        );
      case DocumentProps.DISPOSITION_UNSIGNED:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unsigned}
          </span>
        );
      case DocumentProps.DISPOSITION_INCOMPLETE:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.incomplete}
          </span>
        );
      case DocumentProps.DISPOSITION_INCOMPLETE_AND_UNSIGNED:
        return (
          <span className="status status--errorprovider">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.incompleteAndUnsigned}
          </span>
        );
      default:
        return null;
    }
  }

  onRequestAccess = (e) => {
    // It stops the browsers default behaviour.
    e.preventDefault();
    // Stops callback execution and returns immediately when called stopPropagation() method
    e.stopPropagation();
    this.props.onRequestAccess(this.props[SharedProps.USER]);
  };

  loadOutstandingTask = (outStandingTaskType) => {
    const { home } = this.props.language.provider;
    var returnValue = "";
    if (outStandingTaskType === "IR") {
      returnValue = home.interim;
    } else if (outStandingTaskType === "RECERT") {
      returnValue = home.recert;
    } else if (outStandingTaskType === "COVIDRECERT") {
      returnValue = home.covidRecert;
    }
    return returnValue;
  };

  render() {
    const user = this.props[SharedProps.USER];
    const personName = this.props.user[SharedProps.PERSON_NAME];
    const { home } = this.props.language.provider;
    let accountExpiryStatus = "";
    if (this.daysUntilAccountAccessExpires() <= 10) {
      accountExpiryStatus = "urgent";
    }
    return (
      /* Below can use id for consumer account link like `/consumer/${this.props[SharedProps.ID]}?provider=TextProps.VALUE_TRUE` */
      <a
        className="dta-link--container"
        aria-describedby={this.nextUniqueId()}
        onClick={this.redirectToConsumerClientInfo}
        href="#"
      >
        <span
          className="sr-only"
          id={this.lastUniqueId()}
        >{`Open ${fullNameProvider(personName)}'s account`}</span>
        <div className="pure-g">
          <div className="pure-u-2-3 pure-u-lg-2-5">
            <div className="provider-client__row">
              <div className="provider-client__column provider-client__column-name">
                <div className="provider-client__name-container margin-bottom-quarter">
                  <span className="sr-only" id={this.nextUniqueId()}>
                    Client name:
                  </span>
                  <span aria-labelledby={this.lastUniqueId()}>
                    <b>{fullNameProvider(personName, TextProps.VALUE_TRUE)}</b>
                  </span>
                  {this.props[BenefitProps.CLIENT_ACTIVITY] ? (
                    <Icon name="alert" label="New client activity" />
                  ) : null}
                </div>
                <div>
                  <span className="sr-only" id={this.nextUniqueId()}>
                    Client date of birth:
                  </span>
                  <span aria-labelledby={this.lastUniqueId()}>
                    ({convertStringDateToDMY(user[SharedProps.DOB])})
                  </span>
                </div>
              </div>
              {this.daysUntilAccountAccessExpires() <= 30 ? (
                <div className="provider-client__column provider-client__column-expiring-account-access">
                  <div className="expiring-account-access__button-container">
                    <button
                      className={`dta-button dta-button--outline-secondary dta-button--text-with-icon expiring-account-access__button ${accountExpiryStatus}`}
                      onClick={this.onRequestAccess}
                    >
                      <Icon name="warning" ariaHidden={TextProps.VALUE_TRUE} />
                      <span className="expiring-account-access__button-text-default">
                        {this.daysUntilAccountAccessExpires()} {home.daysLeft}
                        <span className="sr-only">
                          {" "}
                          until account access expires, submit now
                        </span>
                      </span>
                      <span className="expiring-account-access__button-text-hover">
                        <span className="sr-only">
                          {this.daysUntilAccountAccessExpires()} days left until
                          account access expires,{" "}
                        </span>
                        {home.submitNow}
                      </span>
                    </button>
                  </div>
                  <div className="provider-client__column-phone-small full-width">
                    <div className="margin-bottom-quarter">
                      <span className="sr-only" id={this.nextUniqueId()}>
                        Client phone number:
                      </span>
                      <span aria-labelledby={this.lastUniqueId()}>
                        {this.loadOutstandingTask(
                          this.props.outStandingTaskType
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="provider-client__column-phone-small full-width">
                  <div className="margin-bottom-quarter">
                    <span className="sr-only" id={this.nextUniqueId()}>
                      Client phone number:
                    </span>
                    <span aria-labelledby={this.lastUniqueId()}>
                      {this.loadOutstandingTask(this.props.outStandingTaskType)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="pure-u-1-3 pure-u-lg-3-5">
            <div className="pure-g">
              <div className="pure-u-1 pure-u-lg-1-3 provider-client__column provider-client__column-phone-large">
                <div className="margin-bottom-quarter">
                  <span className="sr-only" id={this.nextUniqueId()}>
                    Client phone number:
                  </span>
                  <span>
                    {this.loadOutstandingTask(this.props.outStandingTaskType)}
                  </span>
                </div>
              </div>
              <div className="pure-u-1 pure-u-lg-1-3 provider-client__column provider-client__column-status">
                {this.renderStatusColumnContents(
                  this.props[BenefitProps.STATUS]
                )}
              </div>
              <div className="pure-u-1 pure-u-lg-1-3 provider-client__column">
                <span
                  className={`${
                    this.props[BenefitProps.STATUS_MESSAGE_URGENT]
                      ? "text--red"
                      : ""
                  }`}
                >
                  {this.props.docType !== null
                    ? getDocumentTypeDescription(this.props.docType)
                    : ""}
                </span>
                <div className="text--left">
                  {this.props.docType !== null
                    ? this._buildIndicator(this.props.docDispositionCode)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateClientInfo: updateClientInfo,
      navigateToConsumer: () => push("/consumer?provider=TextProps.VALUE_TRUE"),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientContents);
