import React, { Component } from 'react';

import * as TextProps from "../../../../utils/constants/text"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer"
import DTAInput from '../../../../utils/components/dtaInput'
import DTATextArea from '../../../../utils/components/dtaTextArea'
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions"
import { consumerConst } from "../../consumerText";
import {
    withdrawappealPostCall,
} from "../../../../redux/concernsAppeals/concernsAppealsAction";
import moment from 'moment';
import * as modalHelperFunction from "../../../../utils/components/helperFunctions/modalHelperFunction";
import Loading from '../../../../utils/components/loading.js';


const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class AppealWithdraw extends Component {
    constructor(props) {
        const appealData = JSON.parse(sessionStorage.getItem("selectedAppeal"));
        const clientName = appealData.clientName.first + appealData.clientName.last;
        const appealTypeCode = appealData.appealTypeCode;
        const appealReasonCode = appealData.appealReasonCode;
        const appealProgramCode = appealData.appealProgramCode;
        const allegedIncidentDate = appealData.allegedIncidentDate;
        const clientIdK = appealData.clientIdK;
        const id = appealData.id;
        super(...arguments);
        this.state = {
            type: appealTypeCode,
            reason: appealReasonCode,
            program: appealProgramCode,
            date: allegedIncidentDate,
            id: id,
            clientname: clientName,
            clientid: clientIdK,
            comments: '',
            withdrawcomments: '',
            checkbox: '',
            checkboxError: TextProps.VALUE_FALSE,
            sign: '',
            signError: TextProps.VALUE_FALSE,
            showWarningModal: TextProps.VALUE_FALSE,
            showSuccessModal: TextProps.VALUE_FALSE,
        }
    }

    handleSubmit = () => {
        
        if (this.state.sign === "" && this.state.checkbox === "") {
            this.setState({
                signError: TextProps.VALUE_TRUE,
                checkboxError: TextProps.VALUE_TRUE,
            });
        }
        let payload = {
            appealId: this.state.id,
            clientId: this.state.clientid,
            withdrawComments: this.state.comments,
            signedName: this.state.sign,
        }
        if (this.state.sign !== "" && this.state.checkbox !== "") {
            this.props.withdrawappealPostCall(payload, selectedLanguage);
        }   
    }

    handleChange = (type , value) => {
        if ( type === 'comments') {
            this.setState({
                comments:value,
            });
          }else if (type === 'checkbox') {
            this.setState({
                checkbox: value,
                checkboxError: TextProps.VALUE_FALSE,
            })
          } else if(type === 'sign'){
            this.setState({
                sign: value,
                signError: TextProps.VALUE_FALSE
            })
          }
    };
  
    getAppeal = (appealTypeCode) => {

        switch (appealTypeCode) {
            case "FAIRHR":
                return consumerConst(selectedLanguage).appealType;
            default:
                return "";
        }
    };

    dateFormat = (date) => {
        return moment(date).format("DD-MM-YYYY");
    }
    showSuccessModal = () => {
      this.setState({ showSuccessModal: TextProps.VALUE_TRUE });
    };
    closeSuccessModal = () => {
      this.setState({ showSuccessModal: TextProps.VALUE_FALSE });
    };
    renderSuccessPopup = () => {
      return modalHelperFunction.dtaHelperModal(
        (instance) => (this.successModal = instance),
        this.state.showSuccessModal,
        this.closeSuccessModal,
        "success-withdraw-modal",
        "success-withdraw",
        false,
        
      );
    };
    componentWillReceiveProps(nextProps) {
      if (this.successModal) {
      if (nextProps.concernsAppeals.withdrawRequest) {
        this.showSuccessModal();
        setTimeout(() => {
          this.successModal.showBlur("loading", null);
        });
      }
    
      if (nextProps.concernsAppeals && nextProps.concernsAppeals.withdrawSuccess && nextProps.concernsAppeals.withdrawSuccess.appealReferenceNumber  ) {

        setTimeout(
          this.successModal.showBlur(
            "success",
            <div style={{marginBottom:'35px'}}>{consumerConst(selectedLanguage).withdrawSuccessPopup}</div>), 500);
            setTimeout(() => {
              window.location.assign("/consumer/concernappeal");
            }, 2000);
      }
      if ( this.props.concernsAppeals.withdrawFailure) {
          this.successModal.showBlur(
            "error",
            <div>Please try again later.</div>
          );
          setTimeout(() => {
            window.location.assign("/consumer/concernappeal/withdraw");
          }, 2000);
        }
    }
}
    
    modalContent = () => {
        return (
          <div style={{ padding: '1rem 1rem 1rem 1rem' }}>
            <span >
              {
                consumerConst(selectedLanguage).withdrawCancelText
              }<br />
              {
                consumerConst(selectedLanguage).withdrawCancelTextBack
              }
            </span>
            <br />
            <div style={{ display: "flex", float: "right", padding: "2rem 1.5rem 1rem 1rem" }}>
              <button className="dta-button dta-button--outline-primary" style={{ marginRight: "20px", backgroundColor: 'white', color: '#002a57' }} onClick={this.closeWarningModal}>{consumerConst(selectedLanguage).concernCancel}</button>
              <button className="dta-button dta-button--primary" onClick={this.handleGoBackWarningModal}> {consumerConst(selectedLanguage).concernsGoback}</button>
            </div>
          </div>
        )
      }
    
    
      renderWarningPopup = () => {
        return modalHelperFunction.dtaHelperModal(
          (instance) => (this.warningModal = instance),
          this.state.showWarningModal,
          this.closeWarningModal,
          "",
          "concerns-cancel",
          false, false, false,
          <div style={{ fontSize: '1.5rem', fontWeight: '700', padding: '0.5rem 0.5rem 0.5rem 0.5rem' }}>
            {consumerConst(selectedLanguage).concernsCancelHeader}</div>
          , this.modalContent(),
          false
        );
      };
    
    
      showWarningModal = () => {
        this.setState({ showWarningModal: TextProps.VALUE_TRUE });
      };
    
    
      closeWarningModal = () => {
        this.setState({
          showWarningModal: TextProps.VALUE_FALSE
        })
      };
    
      handleGoBackWarningModal = () => {
        this.closeWarningModal();
        this.resetConcernsState();
        window.location.assign("/consumer/concernappeal");
    }
    
      resetConcernsState = () => {
        this.setState ({
            comments: '',
                withdrawcomments: '',
                checkbox: '',
                sign: '',
        })
      }
      returnProgramValue = () => {
        if(this.state.program === consumerConst(selectedLanguage).concernsSNAP) {
          return consumerConst(selectedLanguage).concernsProgramOptions[0]
        }
        if(this.state.program === consumerConst(selectedLanguage).concernsTANF) {
          return consumerConst(selectedLanguage).concernsProgramOptions[1]
        }
        if(this.state.program === consumerConst(selectedLanguage).concernsJOBS) {
          return consumerConst(selectedLanguage).concernsProgramOptions[2]
        }
        if(this.state.program === consumerConst(selectedLanguage).concernsReset) {
          return consumerConst(selectedLanguage).concernsProgramOptions[3]
        }
        if(this.state.program === consumerConst(selectedLanguage).concernsNonDHR) {
          return consumerConst(selectedLanguage).concernsProgramOptions[4]
        }
      }

    render() {
        const {
            signError, checkboxError } = this.state
        const appealData = JSON.parse(sessionStorage.getItem("selectedAppeal"));
        const appealTypeCode = appealData.appealTypeCode;
       
        return (
            <div>
               
                    <div>
                        <div className='max-width-customer--concern  pad-all--double pad-bottom-none'>
                            <div className="pure-u-1">
                                <div>
                                    <span style={{ display: 'flex', fontSize: '2rem', marginBottom: '20px' }}><b>Withdraw Fair Hearing</b></span>
                                </div>
                                <div style={{ display: 'flex', borderBottom: '1px solid grey', marginBottom: '38px' }}>

                                </div>
                            </div>
                            <form id="withdraw-appeal" >
                            <label className="dta-form__label">{consumerConst(selectedLanguage).clientNameLabel}</label>
                                <DTAInput
                                    name={"withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.state.clientname}
                                    id={"clientname"}
                                    disabled
                                />
                                <label className="dta-form__label">{consumerConst(selectedLanguage).clientIdLabel}</label>
                                <DTAInput
                                    name={"withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.state.clientid}
                                    id={"clientid"}
                                    disabled
                                />
                                <label style={{ marginTop: "40px" }} className="dta-form__label">{consumerConst(selectedLanguage).typeLabel}</label>
                                <DTAInput
                                    name={"Withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.getAppeal(appealTypeCode)}
                                    id={"type"}
                                    disabled
                                />
                                <label className="dta-form__label">{consumerConst(selectedLanguage).reasonLabel}</label>
                                <DTAInput
                                    name={"Withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={consumerConst(selectedLanguage).appealsReasonOptions[this.state.reason]}
                                    id={"reason"}
                                    disabled
                                />
                                <label className="dta-form__label">{consumerConst(selectedLanguage).programLabel}</label>
                                <DTAInput
                                    name={"withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.returnProgramValue()}
                                    disabled
                                />
                                <label className="dta-form__label">{consumerConst(selectedLanguage).dateLabel}</label>
                                <DTAInput
                                    name={"withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.dateFormat(this.state.date)}
                                    id={"date"}
                                    disabled
                                />
                               
                                <label className="dta-form__label">{consumerConst(selectedLanguage).withdrawnCommentLabel}</label>
                                <DTATextArea
                                    name={"withdraw"}
                                    maxLength={4500}
                                    className="dta-form__control dta-select--expand-width"
                                    placeholder={consumerConst(selectedLanguage).withdrawplaceHolder}
                                    value={this.state.comments || ''}
                                    onChange={this.handleChange.bind(this,"comments")}
                                />
                                {checkboxError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).checkboxErrorMsg}
                                            </span>
                                        </div>
                                    </div>

                                )}<div className="pure-u-1" style={{ display: 'flex', alignItems: 'center', marginBottom: '40px' }} >
                                    <input
                                        className={this.state.checkboxError ? "checkbox-appeal-error" : "checkbox-appeal"}

                                        type="checkbox"
                                        value={this.state.checkbox}
                                        error={this.state.checkboxError}
                                        onChange={this.handleChange.bind(this,"checkbox")}
                                    />
                                    <label style={{ marginLeft: '8px', marginBottom: '1px' }} className="dta-form__label">{consumerConst(selectedLanguage).checkboxLabel}</label>
                                </div>
                                <label className="dta-form__label">{consumerConst(selectedLanguage).signLabel}</label>
                                {signError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).signErrorMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <DTAInput
                                    name={"withdraw"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.state.sign}
                                    error={this.state.signError}
                                    onChange={this.handleChange.bind(this,"sign")}
                                    id={"sign"}
                                    type="alpha-text"
                                />

                            </form>
                            <div className="float-left"  >
                            <button style={{ padding: '8px 20px' }}
                                className="dta-button dta-button--large dta-button--outline-primary" onClick={this.showWarningModal}>
                                {consumerConst(selectedLanguage).concernCancel}
                            </button>
                            <button style={{ padding: '8px 20px', marginLeft: '15px' }}
                            className="dta-button dta-button--large dta-button--primary" onClick={this.handleSubmit}>
                            {consumerConst(selectedLanguage).concernSubmit}
                        </button>
                                </div>
                                {this.renderSuccessPopup()}
                            {this.renderWarningPopup()}
                        </div>
                    </div>
               
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            withdrawappealPostCall: withdrawappealPostCall,

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppealWithdraw)