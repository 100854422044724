import * as Types from "./types";
import * as Props from "../components/shared";
import * as OptionsProps from "./options";
import * as consumerLabels from "../../pages/consumer/consumerText";
import * as helperFunction from "../components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const accountConnectQuestion = [
  {
    [Props.NAME]: "firstName",
    [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).labelFirstName,
    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]:consumerLabels.connecttodtaLogoutPopupLabels(selectedLanguage).firstNameErrMsg,
    [Props.MAX_LENGTH]: 15,
  },
  {
    [Props.NAME]: "lastName",
    [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).lastNamelabel,
    [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]:consumerLabels.connecttodtaLogoutPopupLabels(selectedLanguage).lastNameErrMsg,
    [Props.MAX_LENGTH]: 15,
  },
  {
    [Props.NAME]: "dob",
    [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).dateOfBirthLabel,
    [Props.TYPE]: Types.DATE,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]:consumerLabels.connecttodtaLogoutPopupLabels(selectedLanguage).dobErrMsg,
  },
  {
    [Props.NAME]: "ssnNumber",
    [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).ssn,
    [Props.TYPE]: Types.INPUT_SSN,
    [Props.REQUIRED]: TextProps.VALUE_TRUE,
    [Props.ERROR]: TextProps.VALUE_FALSE,
    [Props.ERROR_MESSAGE]:consumerLabels.connecttodtaLogoutPopupLabels(selectedLanguage).ssnErrMsg,
    [Props.MAX_LENGTH]: 9,
  },
  {
  [Props.NAME]: "accountConnectType",
  [Props.LABEL]:
    consumerLabels.consumerConst(selectedLanguage).accountConnectType,
  [Props.INLINE_HELP]:
    consumerLabels.consumerConst(selectedLanguage).accountConnectTypeHelpText,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]: "ebt",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).ebt,
    },
    {
      [OptionsProps.OPTION_VALUE]: "clientId",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).clientID,
    },
  ],
  value: "",
  [OptionsProps.OPTIONS_PER_ROW]: 1,
  [OptionsProps.CONDITIONALS]: [
    /*{
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ssn",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "ssnNumber",
          [Props.LABEL]:
            consumerLabels.consumerConst(selectedLanguage).ssnNumber,
          [Props.TYPE]: Types.INPUT_SSN,
        },
      ],
    },*/
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "ebt",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "ebt",
          [Props.LABEL]: consumerLabels.consumerConst(selectedLanguage).ebtNumber,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
    {
      [OptionsProps.CONDITIONAL_TARGET_VALUE]: "clientId",
      [OptionsProps.CONDITIONAL_QUESTIONS]: [
        {
          [Props.NAME]: "clientId",
          [Props.LABEL]:
            consumerLabels.consumerConst(selectedLanguage).whatIsClientId,
          [Props.TYPE]: Types.INPUT_TEXT,
        },
      ],
    },
  ],
  [Props.ERROR]: TextProps.VALUE_FALSE,
  [Props.ERROR_MESSAGE]: "Required Field",
}];

export const applicationUploadDocumentTypeQuestion = {
  "REEVAL":{
    [OptionsProps.OPTION_VALUE]: "TELIRE$TRECER",
    [OptionsProps.OPTION_DISPLAY]:
      consumerLabels.consumerConst(selectedLanguage).TELIRE$TRECER,
  },
  "RECERT":{
    [OptionsProps.OPTION_VALUE]: "SAPPL$SRECERT",
    [OptionsProps.OPTION_DISPLAY]:
      consumerLabels.consumerConst(selectedLanguage).SAPPL$SRECERT,
  },
  "AESAP":{
    [OptionsProps.OPTION_VALUE]: "SAPPL$SRECERTAPPL",
    [OptionsProps.OPTION_DISPLAY]:
      consumerLabels.consumerConst(selectedLanguage).SAPPL$SRECERTAPPL,
  },
  "IR":{
    [OptionsProps.OPTION_VALUE]: "SSIXRP$SSIXRF",
    [OptionsProps.OPTION_DISPLAY]:
      consumerLabels.consumerConst(selectedLanguage).SSIXRP$SSIXRF,
  },
  "LINKEDCLIENT":{
    [OptionsProps.OPTION_VALUE]: "TJACTR$TATTDPROGREP",
    [OptionsProps.OPTION_DISPLAY]:
      consumerLabels.consumerConst(selectedLanguage).TJACTR$TATTDPROGREP,
  }
}

export const defaultUploadDocumentTypeQuestion = {
  [Props.NAME]: "uploadDocumentType",
  [Props.LABEL]:  consumerLabels.consumerConst(selectedLanguage).uploadDocumentType,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [

    {
      [OptionsProps.OPTION_VALUE]: "STIDEN$SRFAREP",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STIDEN$SRFAREP,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STEINV$STEINVGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STEINV$STEINVGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STRTAD$STRTAD",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STRTAD$STRTAD,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STRTSB$STRTSB",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STRTSB$STRTSB,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STRTIE$STRTIE",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STRTIE$STRTIE,
    },
    {
      [OptionsProps.OPTION_VALUE]: "VERIFC",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).VERIFC,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STRTOT$STRTOT",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STRTOT$STRTOT,
    },
  ],
  [OptionsProps.OPTIONS_PER_ROW]: 1,
};

export const uploadDocumentAboutQuestion = {
  [Props.NAME]: "uploadDocumentAbout",
  [Props.LABEL]:
    consumerLabels.consumerConst(selectedLanguage).uploadDocumentAbout,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [],
  [OptionsProps.OPTIONS_PER_ROW]: 1,
};

export const uploadDocumentVerificationTypeQuestion = {
  [Props.NAME]: "VerificationDocumentType",
  [Props.LABEL]:
    consumerLabels.consumerConst(selectedLanguage).uploadDocumentVerificationType,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]: "STEINV$SWGVFM",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STEINV$SWGVFM,
    },
    {
      [OptionsProps.OPTION_VALUE]: "TUNINV$TUNINVGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).TUNINV$TUNINVGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STSHEX$STSHEXGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STSHEX$STSHEXGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "SCHSUP$SCHSUPGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).SCHSUP$SCHSUPGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "SMEDEX$SMEDEXGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).SMEDEX$SMEDEXGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "STIDEN$STIDENGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).STIDEN$STIDENGEN,
    },
    {
      [OptionsProps.OPTION_VALUE]: "TJACTR$TJACTRGEN",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).TJACTR$TJACTRGEN,
    },
  ],
  [OptionsProps.OPTIONS_PER_ROW]: 1,
};

export const uploadDocumentProgramQuestion = {
  [Props.NAME]: "uploadDocumentProgram",
  [Props.LABEL]:
    consumerLabels.consumerConst(selectedLanguage).documentProgramText,
  [Props.TYPE]: Types.RADIO,
  [Props.REQUIRED]: TextProps.VALUE_TRUE,
  [OptionsProps.OPTIONS]: [
    {
      [OptionsProps.OPTION_VALUE]: "SNAP",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).SNAP,
    },
    {
      [OptionsProps.OPTION_VALUE]: "TANF",
      [OptionsProps.OPTION_DISPLAY]:
        consumerLabels.consumerConst(selectedLanguage).TANF,
    },
  ],
  [OptionsProps.OPTIONS_PER_ROW]: 1,
};
