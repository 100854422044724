import _ from "lodash";
export function interimReportReducer(state = {}, action) {
  switch (action.type) {
    case "GET_INTERIM_DATA_FULFILLED": {
      const stateInterimData = _.cloneDeep(state);
      stateInterimData.responseObject = action.payload;
      stateInterimData.status = action.payload.status;
      stateInterimData.interimId = action.payload.interimID;
      stateInterimData.createdTime = action.payload.createdTime;
      stateInterimData.additionalProperties = action.payload.additionalProperties;
      stateInterimData.pendingHours = action.payload.pendingHours;
      stateInterimData.updatedDate = action.payload.updatedDt;
      stateInterimData.date = action.payload.date;
      return stateInterimData;
    }
    case "IR_APPLY_SNAP_PAGES_FULLFILLED": {
      var applySnapState = Object.assign({}, state);
      applySnapState.sections = action.payload;
      return applySnapState;
    }

    case "APPEND_IR_HOME_HOLD_DATA": {
      const irHomeState = Object.assign({}, state);
      irHomeState.sections[2].subsections = action.payload;
      return irHomeState;
    }
    case "APPEND_IR_INCOME_DATA": {
      const irIncomeState = Object.assign({}, state);

      irIncomeState.sections[3] = action.payload;
      return irIncomeState;
    }

    case "APPEND_UI_INCOME_DATA": {
      const otherIncomeState = Object.assign({}, state);

      otherIncomeState.sections[4] = action.payload;
      return otherIncomeState;
    }

    case "APPEND_IR_EXPENSE_DATA": {
      const irExpenseState = Object.assign({}, state);

      irExpenseState.sections[4] = action.payload;
      return irExpenseState;
    }

    case "FILL_INPUT_VALUES_STATE": {
      return state;
    }

    case "DOWNLOAD_PDF_IR_APPLICATION": {
      return state;
    }

    case "APPEND_SHELTER_DATA": {
      const shelterState = Object.assign({}, state);
      shelterState.sections[6] = action.payload;
      return shelterState;
    }

    case "APPEND_SHELTER_SUBSECTION_QUESTIONS": {
      const shelterState = Object.assign({}, state);
      shelterState.sections[6].subsections[0].subsections[0].questions = action.payload;
      return shelterState;
    }

    case "APPEND_CHILD_SUPPORT_SECTIONS":{
      const childSupportState = Object.assign({}, state);

      childSupportState.sections[5] = action.payload;
      return childSupportState;
    }

    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      return applyInit;
    }

    //sutudent list screen
    case "IR_ADD_STUDENT_TO_LIST": {
      const interimState = Object.assign({}, state);
      interimState.sections[4].subsections = action.payload;
      return interimState;
    }

    //Child Support screen
    case "IR_SET_CHILD_SUPPORT": {
      const interimState = Object.assign({}, state);
      interimState.childExpScreen = action.payload;
      return interimState;
    }

    //Contact info screen
    case "IR_SET_CONTACT_INFO": {
      const interimState = Object.assign({}, state);
      interimState.contactInfoScreen = action.payload;
      return interimState;
    }

    case "IR_ADD_ADD_CONTACT_INFO_SECTION": {
      const interimState = Object.assign({}, state);
      interimState.sections[1].subsections = action.payload;
      return interimState;
    }

    //Household info screen
    case "IR_SET_HOUSEHOLD_MEMBERS": {
      const interimState = Object.assign({}, state);
      interimState.householdMembers = action.payload;
      return interimState;
    }

    case "IR_ADD_ADD_HOUSEHOLD_INFO_SECTION": {
      const interimState = Object.assign({}, state);
      interimState.sections[2].subsections = action.payload;
      return interimState;
    }

    //To set yes no selection status
    case "IR_ADD_DATA_CHANGE_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.sections[action.index].selectionMade = action.payload;
      return interimState;
    }

    //Earned income
    case "SET_GROSS_EARNED_INCOME_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.grossEarnedIncomeChanged = action.payload;
      return interimState;
    }

    case "SET_EARNED_INCOME_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.earnedIncomeChanged = action.payload;
      return interimState;
    }

    //Earned income
    case "SET_UNEARNED_INCOME_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.unearnedIncomeChanged = action.payload;
      return interimState;
    }


    case "SET_OBLIGATION_CHILD_SUPPORT_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.obligationChildSupportChanged = action.payload;
      return interimState;
    }

    case "SET_COURT_ORDERED_CHILD_SUPPORT_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.courtOrderedChildSupportChanged = action.payload;
      return interimState;
    }

    case "SET_CHILD_SUPPORT_CHANGED": {
      const interimState = Object.assign({}, state);
      interimState.childSupportChanged = action.payload;
      return interimState;
    }

    case "SET_CHILD_SUPPORT_OPTIONS": {
      const interimState = Object.assign({}, state);
      interimState.obligationChildSupportChanged = action.payload.obligationChildSupportChanged;
      interimState.courtOrderedChildSupportChanged = action.payload.courtOrderedChildSupportChanged;
      return interimState;
    }


    case "SET_INTERIM_REPORT_RESPONSE": {
      const interimState = _.cloneDeep(state);
      let responseObject = action.payload;
      interimState.responseObject = responseObject;
      if (responseObject.status === "DRAFT") {
        interimState.interimId = responseObject.interimId;
        console.log("Created time",responseObject)
        interimState.createdTime = responseObject.createdTime;
        interimState.additionalProperties = responseObject.additionalProperties;
        interimState.pendingHours = responseObject.pendingHours;
        interimState.updatedDate = responseObject.updatedDt;
        interimState.date = responseObject.date;
      } else {
        interimState.interimId = 0;
      }
      return interimState;
    }
    case "SET_INTERIM_REPORT_SAVE_RESPONSE": {
      // const interimState = Object.assign({}, state);
      console.log("Interim Save Response Obj",action.payload);
      const interimState = _.cloneDeep(state);
      if (action.payload && action.payload.status === "DRAFT") {
        interimState.interimId = action.payload.interimID;
        interimState.createdTime = action.payload.createdTime;
        interimState.pendingHours = action.payload.pendingHours;
        interimState.updatedDate = action.payload.updatedDt;
        interimState.date = action.payload.date;
      }
      return interimState;
    }
    case "POST_INTERIM_DATA_FULFILLED": {
      const interimState = Object.assign({}, state);
      interimState.responseNumbers = action.payload;
      return interimState;
    }
    case "SET_SHELTER_UTILITY_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.shelterUtilityConfirmationChanged = action.payload;
      return interimState;
    }

    case "SET_SHELTER_DATA": {
      const interimState = _.cloneDeep(state);
      interimState.shelterUtilityData = action.payload;
      return interimState;
    }

    case "INERIM_SAVE_ACTION": {
      var newState = Object.assign({}, state);
      newState.interimSaveAndContinue = action.payload;
      return newState;
    }
    
    case "SAVE_INTERIM_APPLICATION": {
      const interimState = Object.assign({}, state);
      if(action.payload.data){
        let application = JSON.parse(action.payload.data.application);
        interimState.activeSectionId = action.payload.activeId;
        interimState.interimSaveAndContinue = false;
        interimState.prevSections = {
          activeSectionId: application.activeId,
          id: action.payload.data.id,
          date: action.payload.data.date,
          sections: application.application,
        };
      }
      console.log("Final Result",interimState)
      // interimState.sections = action.payload;
      return interimState;
    }

    case "APPEND_IR_DATA_FOR_SUMMARY": {
      let interimReportSummary = Object.assign({}, state);
      interimReportSummary.summaryData = action.payload;
      return interimReportSummary;
    }
    
    case "APPEND_IR_SHELTER_QUESTIONS": {
      const interimState = Object.assign({}, state);
      interimState.sections[6] = action.payload;
      return interimState;
    }

    case "APPEND_CHILD_SUPPORT_ANSWER": {
      const interimState = Object.assign({}, state);
      interimState.childExpScreen.tempChildExpArray = action.payload;
      return interimState;
    }

    case "APPEND_SHELTER_UPDATED_DATA": {
      const otherIncomeState = _.cloneDeep(state);
      otherIncomeState.sections[6] = action.payload;
      return otherIncomeState;
    }

    case "APPEND_UTILITY_LIHEAP_DATA": {
      const otherIncomeState = _.cloneDeep(state);
      otherIncomeState.isLiheapDataUpdated = action.payload;
      return otherIncomeState;
    }
    
    case "ENABLE_NAVIGATION_FOR_SECTIONS":{
      const interimState = Object.assign({}, state);
      interimState.sections = action.payload;
      return interimState
    }
    case "APPLY_ADDRESS_STRG": {
      const interimState = Object.assign({}, state);
      interimState.residentailAddressStored = action.payload[0];
      interimState.mailingAddressStored = action.payload[1];
      return interimState;
    }

    case "SET_RADIO_BUTTON_FLAGS": {
      const interimState = {
        ...state,
        grossEarnedIncomeChanged: action.payload.hasOwnProperty('grossEarnedIncomeChanged')
          ? action.payload.grossEarnedIncomeChanged
          : null,
        earnedIncomeChanged: action.payload.hasOwnProperty('earnedIncomeChanged')
          ? action.payload.earnedIncomeChanged
          : null,
        unearnedIncomeChanged: action.payload.hasOwnProperty('unearnedIncomeChanged')
          ? action.payload.unearnedIncomeChanged
          : null,
        obligationChildSupportChanged: action.payload.hasOwnProperty('obligationChildSupportChanged')
          ? action.payload.obligationChildSupportChanged
          : null,
        courtOrderedChildSupportChanged: action.payload.hasOwnProperty('courtOrderedChildSupportChanged')
          ? action.payload.courtOrderedChildSupportChanged
          : null,
        shelterUtilityConfirmationChanged: action.payload.hasOwnProperty('shelterChanged')
          ? action.payload.shelterChanged
          : null,
      }
      return interimState;
    }
    

    default:
      return state;
  }
}
