import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import * as Names from "../../../utils/constants/names";
import * as OptionsProps from "../../../utils/constants/options";
import * as Props from "../../../utils/components/shared";
import * as Types from "../../../utils/constants/types";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import {
  activatePEBTQuestionsSections,
  updateApplySection,
  updateCurrentSectionData,
  submitReplacePEBT,
} from "../../../redux/applyPebt/applyPebtActions";
import {
  pebtCaseInfoPagelanguageLabels,
  aboutMeMultilanguageLabels,
} from "../applyPebtText";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { screeningStaticLabels } from "../../screening/screeningText";
import pEBT from "../../../utils/resources/images/PEBT.png";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";
import sections from "../applyPebtMapData";
import SidebarNavigation from "../../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../../redux/language/languageSelect";
import { bindAnswers, toValidateInputErros } from "../applyPebtHelper";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ApplySection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    enableUniqueIds(this);
    this.houseHoldFirstName = [];
    this.houseHoldLastName = [];
    this.houseHoldDob = [];
    this.state = {
      sections: sections,
      formId: this.nextUniqueId(),
      ApplyAnswer: {},
      windowHgt: "",
      directionClass: "forward",
      submitCounter: 0,
      AnswerObject: [],
      matchType: "",
      uploadDocumentModalOpen: TextProps.VALUE_FALSE,
      errorMessage: TextProps.VALUE_TRUE,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activatePEBT.applicationSubmited === TextProps.VALUE_TRUE) {
      this.setState({
        matchType: nextProps.activatePEBT.applicationStatus,
        applicationSubmited: TextProps.VALUE_TRUE,
        uploadDocumentModalOpen: TextProps.VALUE_TRUE,
        hideCloseIcon: TextProps.VALUE_TRUE,
      });

      setTimeout(() => {
        if (this.state.matchType === TextProps.DTA_EXACT_MATCH) {
          this.uploadDocumentModal.showBlur(
            "warning",
            <div style={{ padding: "1em", textAlign: "center" }}>
              {aboutMeMultilanguageLabels(selectedLanguage).dtaExactMatchMsg1}
              <strong>
                {aboutMeMultilanguageLabels(selectedLanguage).dtaExactMatchMsg2}
              </strong>
              {aboutMeMultilanguageLabels(selectedLanguage).dtaExactMatchMsg3}
            </div>
          );
        } else if (this.state.matchType === TextProps.DESE_EXACT_MATCH) {
          this.uploadDocumentModal.showBlur(
            "success",
            <div
              style={{
                marginTop: "0.5em",
                padding: "1em",
                textAlign: "center",
              }}
            >
              {aboutMeMultilanguageLabels(selectedLanguage).deseExactMatchMsg}
            </div>
          );
        } else if (this.state.matchType === TextProps.PARTIAL_MATCH) {
          this.uploadDocumentModal.showBlur(
            "warning",
            <div
              style={{
                marginTop: "1.5em",
                padding: "1em",
                textAlign: "center",
              }}
            >
              {aboutMeMultilanguageLabels(selectedLanguage).partialMatchMsg}
            </div>
          );
        } else if (this.state.matchType === TextProps.NO_MATCH) {
          this.uploadDocumentModal.showBlur(
            "error",
            <div
              style={{
                marginTop: "1.5em",
                padding: "1em",
                textAlign: "center",
              }}
            >
              {aboutMeMultilanguageLabels(selectedLanguage).noMatchMsg1}{" "}
              <strong>
                {aboutMeMultilanguageLabels(selectedLanguage).noMatchMsg2}
              </strong>
            </div>
          );
        }
      }, 5);
      setTimeout(() => {
        this.setState({
          applicationSubmited: TextProps.VALUE_TRUE,
          uploadDocumentModalOpen: TextProps.VALUE_TRUE,
        });
      }, 45000);
      setTimeout(() => {
        window.location.href = routeProps.PEBT_PATH;
      }, 45000);
    }
  }

  componentWillMount() {
    // Set up conditional questions state
    this.setState({
      conditionalState: this._initConditionalState(this.state.sections),
    });
  }

  // Add Max height for iPad(Safari)
  componentDidUpdate() {
    var elmnt = document.getElementsByClassName("apply__content");
    var hgt = elmnt[0].offsetHeight;
    if (this.state.windowHgt != hgt) {
      {
        this.addMaxHeight(hgt);
      }
    }
  }

  addMaxHeight = (hgt) => {
    var str = window.navigator.appVersion;
    var res = str.substring(18, 22);
    var version = res.split("_").join(".");
    if (version < 12.2 && str.includes("iPad")) {
      this.setState({ windowHgt: hgt });
    }
  };

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  _getSection(sectionId) {
    let targetSection = {};
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    }

    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    this.setState({ submitCounter: this.state.submitCounter + 1 });
    const activeId = this.state.sections[1].id;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    //Dal Reference
    let dalReference = {};
    if (
      activeId === "about-me" &&
      typeof this.state.AnswerObject !== "undefined"
    ) {
      if (
        typeof this.state.AnswerObject[activeId] !== "undefined" &&
        typeof this.state.AnswerObject[activeId].ssn !== "undefined"
      ) {
        if (this.state.AnswerObject[activeId].ssn === "") {
          this.props.activatePEBT.sections[1].questions[5].isChecked =
            TextProps.VALUE_TRUE;
        }
      }
    }
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      let _this = this;
      _this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      // validation section here...
      let isValid = toValidateInputErros(dataSections, activeId);

      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
      } else {
        let AnswerObjectCopy = this.state.AnswerObject;
        AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
        this.setState({ AnswerObject: AnswerObjectCopy });

        var DOB = helperFunction.convertBirthDate(
          AnswerObjectCopy.replacepebt.dateOfBirth
        );
        if (AnswerObjectCopy && AnswerObjectCopy.replacepebt) {
          var payload = {
            addressLine1: AnswerObjectCopy.replacepebt.address.street
              ? AnswerObjectCopy.replacepebt.address.street
              : "",
            addressLine2: "",
            childDOB: DOB ? DOB : "",
            childFirstName: AnswerObjectCopy.replacepebt.firstName
              ? AnswerObjectCopy.replacepebt.firstName
              : "",
            childLastName: AnswerObjectCopy.replacepebt.lastname
              ? AnswerObjectCopy.replacepebt.lastname
              : "",
            childMiddleName: AnswerObjectCopy.replacepebt.middleName
              ? AnswerObjectCopy.replacepebt.middleName
              : "",
            city: AnswerObjectCopy.replacepebt.address.city
              ? AnswerObjectCopy.replacepebt.address.city
              : "",
            epicCaseNumber: AnswerObjectCopy.replacepebt.childcasenumber
              ? AnswerObjectCopy.replacepebt.childcasenumber
              : "",
            phoneNumber: AnswerObjectCopy.replacepebt.phoneNumber
              ? AnswerObjectCopy.replacepebt.phoneNumber
              : "",
            state: AnswerObjectCopy.replacepebt.address.state
              ? AnswerObjectCopy.replacepebt.address.state
              : "",
            textOptInFlg:
              AnswerObjectCopy.replacepebt.signupalert === TextProps.VALUE_YES
                ? TextProps.VALUE_Y
                : TextProps.VALUE_N,
            zip: AnswerObjectCopy.replacepebt.address.zipCode
              ? AnswerObjectCopy.replacepebt.address.zipCode
              : "",
          };
          this.props.submitReplacePEBT(payload, selectedLanguage);
        }
      }
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.activatePEBT);
  };

  _bindAnswerObject = (answer) => {
    this.setState({
      AnswerObject: answer,
    });
  };

  closeSubmitModal = () => {
    // Cancel any actions then...
    this.setState({
      uploadDocumentModalOpen: TextProps.VALUE_FALSE,
    });
    window.location.assign(routeProps.PEBT_PATH);
  };

  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    window.location.assign(routeProps.PEBT_PATH);
  };

  render() {
    const section = this.state.sections[1];
    const filterQuestions =
      this.state.sections[1] &&
      this.state.sections[1].questions.filter(
        (value) => value.error === TextProps.VALUE_TRUE
      );
    return (
      <div className={`full-height apply`}>
        <SidebarNavigation
          pebt={this.state.pebt}
          sections={this.state.sections}
          activeSectionId={this.state.sections[1].id}
          inProgressSectionId={this.state.sections[1].id}
          onSectionClick={() => {}}
          // onSaveProgress={this.presentSaveProgressModal}
          canSaveProgress={TextProps.VALUE_TRUE}
          skipToAnchor="#content"
        />
        <main
          className={`apply__content-container 
          ${
            this.state.sections[1].id === "replacepebt" &&
            filterQuestions.length === 0
              ? "replace-pebt"
              : this.state.sections[1].id === "replacepebt" &&
                filterQuestions.length > 0
              ? "replace-pebt-err"
              : null
          } `}
          id="content"
          tabIndex="-1"
          style={{ overflow: "hidden" }}
        >
          <div className={`slide-animation ${this.state.directionClass}`}>
            <div className="apply__content pebt-case-container">
              <div className="apply__header pad-all--one-five apply_tfdc">
                <div className="pebt-case-content pad-top pebt-title">
                  <h1 className="pebt-page-heading">
                    {section.id === "pebtCaseInfo"
                      ? pebtCaseInfoPagelanguageLabels(selectedLanguage).title
                      : aboutMeMultilanguageLabels(selectedLanguage).title}
                  </h1>
                  <div className="pure-g pebt-sub-heading">
                    <div className="pebt-case-header pure-u-2-3">
                      <p className="pebt-heading">
                        {" "}
                        {section.id === "pebtCaseInfo"
                          ? pebtCaseInfoPagelanguageLabels(selectedLanguage)
                              .subtitle
                          : aboutMeMultilanguageLabels(selectedLanguage)
                              .subHeader}
                      </p>
                    </div>
                    <div className="pebt-case-logo pure-u-1-3">
                      <img
                        src={pEBT}
                        className="apply__img-getting-started"
                        alt="PEBT Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply__body">
                {section.questions
                  ? this.renderQuestions()
                  : this.props.children}
              </div>
              {section.id !== "pebtCaseInfo" ? (
                <div className="apply__footer pad-all--double">
                  {/* Generally, clicking this secondary action button would route user to previous section,
              or back to homepage / where they came from if applicable */}
                  <button
                    type="button"
                    className="dta-button dta-button--outline-primary dta-button--large"
                    onClick={this.onSecondaryActionClick}
                  >
                    {screeningStaticLabels(selectedLanguage).goBack}
                  </button>
                  {/* Generally, clicking this primary action button would perform any necessary
              validation and route user to next section */}

                  <button
                    type="submit"
                    className="dta-button dta-button--primary dta-button--large"
                    form={section.id}
                    onClick={this.onPrimaryActionClick}
                    id="pebt"
                  >
                    {pebtCaseInfoPagelanguageLabels(selectedLanguage).submit}
                  </button>
                </div>
              ) : (
                TextProps.VALUE_FALSE
              )}
              {this.state.windowHgt > 950 ? (
                <div style={{ height: "95px" }}></div>
              ) : null}
            </div>
          </div>
          <LanguageSelect className="language-select--dark" />
        </main>
        {/* // Main markup for each apply section page */}
        {modalHelperFunction.dtaHelperModal(
          (instance) => (this.uploadDocumentModal = instance),
          this.state.uploadDocumentModalOpen,
          this.closeSubmitModal,
          "rivl-modal replace-pebt-modal",
          "pebt-modal",
          this.state.errorMessage
          // this.state.hideCloseIcon
        )}
      </div>
    );
  }

  redirectToApply = () => {
    window.location.assign(routeProps.APPLY_PATH);
  };

  // Markup for the apply page body when on a section with questions (the default case, for most apply pages)
  renderQuestions = () => {
    const { id, questions } = this.state.sections[1],
      requiredHandlers = {
        doRetrieve: this._doRetrieve.bind(this, id),
        onChange: this._handleChangeForQuestion.bind(this, id),
        doGetUniqueId: this._getUniqueId,
      },
      optionalHandlers = {
        doBuildContainerProps: this._doBuildContainerProps,
        doBuildControlProps: this._doBuildControlProps,
      };
    return (
      // The id on each form is required to properly namespace questions/labels
      <form className="pad-all--double" id={id}>
        {questions &&
          questions.map((questionData) => {
            return resolveQuestion(
              questionData,
              requiredHandlers,
              optionalHandlers
            );
          })}
      </form>
    );
  };

  // apply function to determine if bottom of footer should be shown
  canSubmit = () => {
    const sectionId = this.state.sections[1].id;
    if (
      sectionId === "screening" ||
      sectionId === "screening-results" ||
      sectionId === "pebtCaseInfo" ||
      sectionId === "about-me" ||
      sectionId === "sign-and-submit"
    )
      return TextProps.VALUE_FALSE;
    return TextProps.VALUE_TRUE;
  };

  // This function is related to simple, apply specific conditional display of questions, based on the values of earlier questions
  _initConditionalState(section) {
    const dataObj = {};
    this._initConditionalBySections(dataObj, section);
    return dataObj;
  }

  // Recursive helper function for _initConditionalState that goes through all sections
  // and subsections
  _initConditionalBySections(dataObj, sectionObj) {
    if (sectionObj.questions) {
      const sectionId = sectionObj.id;
      this._initConditionalByQuestions(
        dataObj,
        sectionId,
        sectionObj.questions
      );
    }
    if (sectionObj.subsections) {
      sectionObj.subsections.forEach((subObj) =>
        this._initConditionalBySections(dataObj, subObj)
      );
    }
  }

  // Recursive helper function for _initConditionalState that goes through all questions
  // and through the conditional block of the question, if it has one
  _initConditionalByQuestions(dataObj, sectionId, questions, targetValue = "") {
    questions.forEach((question) => {
      // look to see if each question has a conditional block
      if (question[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.targetValue
          ? question.targetValue
          : defaultValue;
        // look through each condition in the conditional block to see
        // if any of the questions have their own conditional block
        question[OptionsProps.CONDITIONALS].forEach((conditionalObj) => {
          this._initConditionalByQuestions(
            dataObj,
            sectionId,
            conditionalObj[OptionsProps.CONDITIONAL_QUESTIONS],
            conditionalObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
          );
        });
      }
    });
  }

  // apply-specific generation of unique keys to store the values of the questions that
  // trigger the conditional display of other questions
  _conditionalKey(sectionId, questionName, targetValue = "") {
    return `${sectionId}/${questionName}/${targetValue}`;
  }

  // Resolving questions
  // -------------------

  _doRetrieve = (sectionId, questionData, conditionObj) => {
    if (conditionObj && questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    } else if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      return this.state.conditionalState[key];
    } else if (questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    }
  };

  _updateAnswerForView = (questionData, val) => {
    let currentSectionQuestion = this.props.section.questions;
    let _this = this;
    if (currentSectionQuestion !== undefined) {
      currentSectionQuestion.map(function (question, index) {
        if (question.name === questionData.name) {
          _this.props.section.questions[index].Answer = val;
          _this.props.section.questions[index].targetValue = val;
        }
        return TextProps.VALUE_TRUE;
      });
    }
  };
  _handleWarningModal = (sectionId, questionData, val) => {
    if (sectionId === "my-household") {
      if (
        questionData.Answer > val &&
        val !== "" &&
        questionData.Answer !== "" &&
        questionData.Answer !== undefined
      ) {
        this.props.onWarningProgress();
      }
    }
  };

  _handleChangeForQuestion = (
    sectionId,
    questionData,
    conditionObj,
    val,
    optionalMetaData = null
  ) => {
    //break if invalid date

    if (this.AnswerObject === undefined) {
      this.AnswerObject = this.state.AnswerObject;
    }
    if (this.AnswerObject[sectionId] === undefined) {
      this.AnswerObject[sectionId] = {};
    }

    if (questionData.type === "date" && val !== "" && val !== undefined) {
      if (val.getFullYear() === 1899 || val.getFullYear() === 1900) {
        if (this.AnswerObject[sectionId][questionData.name] instanceof Date) {
          this.AnswerObject[sectionId][questionData.name] = "";
          this._bindAnswerObject(this.AnswerObject);
        }
        return;
      }
    }

    //Real Time Appeneding Data to Section for Realtime view (select)
    if (questionData.type === "select") {
      this._updateAnswerForView(questionData, val);
    }
    this._handleWarningModal(sectionId, questionData, val);
    //HouseHold Label Updates

    //Update question value as you normally would
    if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      this.setState({
        conditionalState: {
          ...this.state.conditionalState,
          [key]: val,
        },
      });
    }

    //Appending Values to Section

    this.AnswerObject[sectionId][questionData.name] = val;
    questionData.error = TextProps.VALUE_FALSE;
    //Dal Reference
    this._bindAnswerObject(this.AnswerObject);
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };

  // This function takes the name of the question and returns classes to modify the container
  // element. It is useful for displaying adjacent questions on the same row. You can use
  // pure-framework grid classes and our utility padding classes to customize.
  _doBuildContainerProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { className: "pure-u-4-5 pad-right-half" };
      case Names.SUFFIX:
        return { className: "pure-u-1-5" };
      default:
        return {};
    }
  };

  _doBuildControlProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { questionClass: "truncate-text" };
      case Names.SUFFIX:
        return { questionClass: "truncate-text" };
      default:
        return {};
    }
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      activatePEBTQuestionsSections: activatePEBTQuestionsSections,
      updateApplySection: updateApplySection,
      updateCurrentSectionData: updateCurrentSectionData,
      submitReplacePEBT: submitReplacePEBT,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplySection);
