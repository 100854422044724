const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function pebtCaseInfoPagelanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        heading: "P-EBT Case and Account Balance Information",
        title: "P-EBT Case and Account Balance Information",
        submitButtonLabel: "Submit",
        subtitle:
          "This page is for families who have gotten a P-EBT card in the mail and need help activating it (getting a PIN) or to check their balance. The P-EBT card looks like this:",
        warn1:
          "You need the last 4 digits of your child's Case Number to set up a PIN. If you do not have your child's Case Number or want to check your balance, enter your P-EBT card number below and click submit",
        label1: "P-EBT Card Number",
        validCardError: "Please enter a valid P-EBT card number.",
        submit: "Submit",
        saveNumber:
          "Save this number. You need the last 4 digits to PIN your card.",
        additionalInfo: "Additional Information:",
        childNumber: "Once you have your child's Case Number, ",
        learnPIN:
          "to learn how to PIN your card (so you can use it for food shopping).",
        forInfo: "For more information on P-EBT, go to",
        callDTA: "You can also call DHR at 1-877-382-2363.",
        saveCard:
          "Save the card for future P-EBT issuances. Do not throw it away!",
        noBenefits: "If you do not get DHR benefits, use",
        learnMore: "to learn more and apply.",
        noCaseInfo: "P-EBT case information not found.",
        yourCase: "Your Case Number is: ",
        ebtBalanceError:
          "The P-EBT balance information is currently unavailable, please try again later.",
        asOf: "As of ",
        pebtBalanceAmountIs: " your P-EBT balance amount is ",
        successMsgText1:
          "Thank you for your P-EBT card replacement request. A card will be mailed out to you as soon as possible.",
        successMsgText2: "Due to high volume, please allow 3-4 weeks",
        menuBtn: "Menu",
      }; //return End

    case ES:
      return {
        heading: "Información sobre el Cuenta saldo y el caso de P-EBT",
        title: "Información sobre casos de P-EBT y saldos de cuenta",
        subtitle:
          "Esta página es para familias que recibieron una tarjeta P-EBT por correo y necesitan ayuda para activarla (obtener un PIN) o para consultar el saldo. La tarjeta P-EBT se ve de esta manera:",
        warn1:
          "Para configurar el PIN necesita los últimos 4 dígitos del número de caso de su hijo/a. Si no tiene el número de caso de su hijo/a o quiere consultar su saldo, ingrese el número de su tarjeta P-EBT a continuación y haga clic en enviar.",
        label1: "Numero de tarjeta P-EBT",
        validCardError: "Ingrese un numero de tarjeta P-EBT valido.",
        submit: "Enviar",
        saveNumber:
          "Guardar este numero. Necesita los ultimos 4 digitos para ponerle PIN a su tarjeta.",
        additionalInfo: "Informacion Adicional:",
        childNumber: "Una vez que tenga el numero de caso de su hijo",
        learnPIN:
          "Para aprender como ponerle PIN a su tarjeta (para que pueda usarla para comprar alimentos).",
        forInfo: "Para obtener mas informacion sobre P-EBT, vaya a",
        callDTA: "Tambien puede llamar al DHR al 1-877-382-2363.",
        saveCard: "Guarde la tarjeta para recibir beneficios en el future",
        noBenefits: "¡No te deshagas de ella!",
        learnMore: "Si no obtiene beneficios de DHR, use",
        noCaseInfo: "No se encontro la informacion del caso P-EBT.",
        yourCase: "su número de caso es:",
        submitButtonLabel: "Submit",
        ebtBalanceError:
          "La informacion sobre el balance P-EBT no esta disponible actualmente, por avor intentelo de nuevo mas tarde.",
        asOf: "A partir de ",
        pebtBalanceAmountIs: " Su saldo P-EBT es ",
        successMsgText1:
          "Thank you for your P-EBT card replacement request. A card will be mailed out to you as soon as possible.",
        successMsgText2: "Due to high volume, please allow 3-4 weeks",
        menuBtn: "Menú",
      }; //return End

    case PT:
      return {
        heading: "Informações de caso e conta saldo de P-EBT",
        title: "Informações de saldo da conta e do caso P-EBT",
        subtitle:
          "Esta página é destinada a famílias que receberam o cartão P-EBT pelo correio e precisam de ajuda para ativá-lo (obter um PIN) ou para verificar seu saldo. O cartão P-EBT é mais ou menos assim:",
        warn1:
          "Para configurar um PIN, você precisa dos 4 últimos dígitos do número de caso do(a) seu(ua) filho(a). Caso não tenha o número de caso do(a) seu(ua) filho(a) ou se quiser verificar o saldo, digite o número do seu cartão P-EBT abaixo e clique em “Enviar”",
        label1: "Número do cartão P-EBT",
        validCardError: "Por favor digite um número valido de cartão P-EBT.",
        submit: "Enviar",
        saveNumber:
          "Salve este número. Você precisa dos últimos 4 dígitos para PIN o seu cartão.",
        additionalInfo: "Informação adicional:",
        childNumber: "Depois de obter o número do caso do seu filho,",
        learnPIN:
          "para aprender como PIN o seu cartão (para que você possa usá-lo para fazer compras de alimentos).",
        forInfo: "Para mais informações sobre P-EBT, vá para",
        callDTA:
          "Você também pode ligar para o DHR pelo telefone 1-877-382-2363.",
        saveCard:
          "Salve o cartão para futuras emissões de P-EBT. Não jogue o cartão fora!",
        noBenefits: "Se você não obtiver benefícios do DHR, use",
        learnMore: "para aprender mais e aplicar.",
        noCaseInfo: "Informações do caso P-EBT não encontradas.",
        yourCase: "seu número de caso é:",
        submitButtonLabel: "Submit",
        ebtBalanceError:
          "As informacoes do saldo P-EBT nao astao disponiveis no momento, tente novamente mais tarde.",
        asOf: "A partir de ",
        pebtBalanceAmountIs: " Seu saldo P-EBT e ",
        successMsgText1:
          "Thank you for your P-EBT card replacement request. A card will be mailed out to you as soon as possible.",
        successMsgText2: "Due to high volume, please allow 3-4 weeks",
        menuBtn: "Cardápio",
      }; //return End

    case ZH:
      return {
        heading: "大流行EBT案和帐户余额信息",
        title: "P-EBT 案例和账户余额信息",
        subtitle:
          "此页面适用于已在邮件中收到P-EBT卡，且需要协助激活该卡（获取PIN码）或查看余额的家庭。P-EBT卡如下所示：",
        warn1:
          "您需要您孩子案例号的最后4位数字来设置PIN码。 如果您没有您孩子的案例号，或是想查看余额，请在下面输入您的P-EBT卡号，然后点击提交",
        label1: "P-EBT卡号",
        validCardError: "请输入有效的P-EBT卡号。",
        submit: "提交",
        saveNumber:
          "保存该号码。 您需要使用后4位数字来对您的卡进行PIN密码验证。",
        additionalInfo: "附加信息：",
        childNumber: "当获得孩子的个案号码后，",
        learnPIN: "了解如何为您的卡加密码（以便您可以将其用于购买食品）。",
        forInfo: "有关P-EBT的更多信息，请到",
        callDTA: "您也可以致电DTA电话1-877-382-2363。",
        saveCard: "保存P-EBT卡以备将来P-EBT发行。 不要扔掉它！",
        noBenefits: "如果您没有获得DTA福利，请使用",
        learnMore: "了解更多并申请。",
        noCaseInfo: "找不到P-EBT个案信息。",
        yourCase: "您的案例編號是: ",
        submitButtonLabel: "Submit",
        ebtBalanceError: "现时无法查询P-EBT卡余额， 请稍后再试",
        asOf: "截至 ",
        pebtBalanceAmountIs: " 你的P-EBT卡余额是 ",
        successMsgText1:
          "Thank you for your P-EBT card replacement request. A card will be mailed out to you as soon as possible.",
        successMsgText2: "Due to high volume, please allow 3-4 weeks",
        menuBtn: "菜单",
      }; //return End

    case VI:
      return {
        heading: "Thong-tin ve so-du va Tai-khoan Truong-hop EBT dai-dich",
        title: "Thông tin về Hồ sơ P-EBT và Số dư Tài khoản",
        subtitle:
          "Trang này dành cho các gia đình đã nhận được thẻ P-EBT qua đường bưu điện và cần trợ giúp để kích hoạt thẻ (lấy mã PIN) hoặc để kiểm tra số dư của họ. Thẻ P-EBT trông giống như sau:",
        warn1:
          "Bạn cần 4 chữ số cuối trong Số hồ sơ của con mình để thiết lập mã PIN. Nếu bạn không có Số hồ sơ của con bạn hoặc muốn kiểm tra số dư của mình, hãy nhập số thẻ P-EBT của bạn bên dưới và nhấp vào gửi.",
        label1: "So the P-EBT",
        validCardError: "Vui-long nhap so the P-EBT hop-le.",
        submit: "Gui di",
        saveNumber: "Luu so nay.  Ban can 4 chu so cuoi de ma PIN the cua ban.",
        additionalInfo: "Thong-tin them:",
        childNumber: "Khi bạn đã có Số hồ sơ của con bạn,",
        learnPIN:
          "Khi ban da co so Ho-so cua con ban,cua ban(de ban cothe su-dung no de mua sam thuc-pham).",
        forInfo: "De biet them thong-tin ve P-EBT, go to",
        callDTA: "Ban cung co-the goi DHR theo so 877-382-2363.",
        saveCard:
          "Tiet-kiem the cua ban cho cac dot phat-hanh P-EBT trong tuong-lai.",
        noBenefits: "Dung vut no!",
        learnMore: "Neu ban khong nhan duoc loi-ich DHR, hay su-dung.",
        noCaseInfo: "Thong-tin truong-hop P-EBT khong tim-thay.",
        yourCase: "số trường hợp của bạn là: ",
        submitButtonLabel: "Submit",
        ebtBalanceError:
          "Thông tin về số còn lại trong thẻ P-EBT hiện tại không có, xin vui lòng thử lại sau.",
        asOf: "Kể từ ",
        pebtBalanceAmountIs: " Con số còn lại trong thẻ P-EBT là ",
        successMsgText1:
          "Thank you for your P-EBT card replacement request. A card will be mailed out to you as soon as possible.",
        successMsgText2: "Due to high volume, please allow 3-4 weeks",
        menuBtn: "Thực đơn",
      }; //return End

    case HI:
      return {
        heading: "Enfòmasyon sou Dosye P-EBT ak Balans Kont",
        title: "Enfòmasyon sou Dosye P-EBT ak Balans Kont lan",
        submitButtonLabel: "Soumèt",
        subtitle:
          "Paj sa a se pou fanmi ki te resevwa yon kat P-EBT pa lapòs epi ki bezwen èd pou aktive li (jwenn yon PIN) oswa pou tcheke balans yo. Kat P-EBT a sanble konsa:",
        warn1:
          "Ou bezwen 4 dènye chif Nimewo Dosye pitit ou a pou w fè yon PIN. Si ou pa gen Nimewo Dosye pitit ou a oswa ou ta vle tcheke balans ou, antre nimewo kat P-EBT w la anba a epi klike sou soumèt.",
        label1: "Nimewo Kat P-EBT",
        validCardError: "Tanpri antre yon nimewo kat P-EBT ki valid.",
        submit: "Soumèt",
        saveNumber:
          "Konsève nimewo sa a. Ou bezwen 4 dènye chif yo pou PIN kat ou a.",
        additionalInfo: "Enfòmasyon adisyonèl:",
        childNumber: "Depi w gen Nimewo Ka pitit ou a, ",
        learnPIN:
          "pou aprann kijan pou PIN kat ou a (konsa ou ka itilize li pou achte manje).",
        forInfo: "Pou plis enfòmasyon sou P-EBT, ale nan",
        callDTA: "Ou ka rele DHR tou nan 1-877-382-2363.",
        saveCard: "Sove kat la pou pwochen emisyon P-EBT. Pa jete l!",
        noBenefits: "Si ou pa jwenn avantaj DHR, sèvi ak",
        learnMore: "pou aprann plis epi aplike.",
        noCaseInfo: "Yo pa jwenn enfòmasyon sou dosye P-EBT.",
        yourCase: "Nimewo Dosye w la se: ",
        ebtBalanceError:
          "Enfòmasyon sou balans P-EBT a pa disponib kounye a, tanpri eseye ankò pita.",
        asOf: "Tankou ",
        pebtBalanceAmountIs: " montan balans P-EBT w la se ",
        successMsgText1:
          "Mèsi pou demann ranplasman kat P-EBT w la. Nou pral voye yon kat ba ou pi vit ke posib.",
        successMsgText2: "Akòz gwo volim, tanpri pèmèt 3-4 semèn.",
        menuBtn: "Menu",
      }; //return End

    default:
      return;
  } // Switch End
}

export function aboutMeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        title: "P-EBT Card Replacement Request",
        header: "P-EBT Card Replacement Request",
        subHeader:
          "This page is only for families who got a P-EBT card in the mail and are requesting a replacement card. The P-EBT card looks like this:",
        labelchildFirstName: "What is the child's first name?",
        middleName: "What is the child's middle name?",
        lastNamelabel: "What is the child's last name?",
        dateOfBirthLabel: "What is the child's date of birth?",
        addrQuestionlabel: "What is your address?",
        bestPhoneqlabel: "What is the best phone number to reach you?",
        signupalert:
          "Would you like to sign up for text message alerts on the status of your request?",
        childcaseNumber:
          "If you have it, what is the child's case number (located on the notice sent to you by DHR)?",
        firstNameErrMsg: "Please enter the child's first name",
        firstNameErrMsgLength:
          "Please enter first name less than or equal to 15 characters",
        middleNameErrMsg: "Please enter the child's middle name",
        middleNameErrMsgLength:
          "Please enter middle name less than or equal to 15 characters",
        lastNameErrMsg: "Please enter the child's last name",
        lastNameErrMsgLength:
          "Please enter last name  less than or equal to 20 characters",
        childDateOfBirth: "Child-1 date of birth",
        dobAlertMsg: "Please enter a valid date of birth",
        validZipcode: "Please enter a valid Zip code",
        zipCodeErrmsg: "Please enter a valid Zip code",
        masZipCodeErrmsg:
          "This TAFDC application is for Massachussetts residents. If you live in Massachussetts, please enter your Zip Code. If you do not live in Massachussetts, you must apply in your state.",
        citiErrmsg: "Please enter valid city.",
        addressErrmsg: "Please enter a valid address.",
        validPhoneAlert: "Please enter a valid phone number.",
        signupErrMsg: "Please select an option below.",
        childcaseNumberErrMsg:
          "The child's case number must be 9 digits, please enter a valid case number",
        howManyLabel: "How many children live with you?",
        houseErrMsg: "Please choose no. of household",
        childLiveYou: "Do you have any children under 18 living with you?",
        childSupport: "Do you receive any child support?",
        assistiveTechnology: "Do you require any assistive technology(TTY)?",
        moneyBank: "Do you have any money in the bank?",
        sourceOfIncome: "Do you have any source of income?",
        sourceIncomeOne: "Source",
        sourceIncomeTwo: "Source-2",
        dollarAmount: "($ Amount)",
        monthlyAmount: "Monthly Amount",
        incomeSourceList: [
          "Wages",
          "Self-Employment",
          "Work Study",
          "SSI (Supplemental Security Income)",
          "RSDI (Retirement, Survivors, and Disability Insurance)",
          "Unemployment",
          "Child Support",
          "Pension",
          "Veterans Benefits",
          "Rental Income",
          "Workers Compensation",
          "Other",
        ],
        lastEmployement: "What is your last date of employment?",
        childLiveYouErrMsg: "Please select an option below.",
        childSupportErrMsg: "Please select an option below.",
        assistiveTechnologyErrMsg: "Please select an option below.",
        moneyBankErrMsg: "Please select an option below.",
        dollarAmountErrMsg: "Please enter income amount?",
        lastEmployementErrMsg: "Please select valid date.",
        sourceIncomeErrMsg: "Please select an option below.",
        houseLessOptions: ["Yes", "No"],
        houseLessOptionsYes: ["Yes"],
        houseLessOptionsNo: ["No"],
        maleOptions: ["Male"],
        femaleOptions: ["Female"],
        alsoMailingLabel: "Is this also your mailing address?",
        optionsSendMail: "Yes, send mail there.",
        optionsNoDiffAddress: "No, I use a different address for mail.",
        mailingQlabel: "What is your mailing address?",
        inlineHelp:
          "Please enter a phone number if you have one. This will help us process your SNAP application faster.",
        emailAddressLabel: "What is your email address?",
        emailErrormsg: "Please enter a valid email address",
        titleEmergency: "Emergency SNAP benefits",
        headerEmergency: "Emergency SNAP benefits",
        emergencySnap:
          "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
        incomeMoneylabel:
          "Does your income and money in the bank add up to less than your monthly housing expenses?",
        inlineHelpLabel:
          "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
        optionYesNo: ["Yes", "No"],
        alertSelectIncome: "Please select income you pay",
        incomeLesslabel:
          "Is your monthly income less than $150 and is your money in the bank $100 or less?",
        monthlyIncomeoptions: ["Yes", "No"],
        selectIncomeErrmsg: "Please select income you pay",
        migrantBefore: "Are you a ",
        migrantTrigger: "migrant worker",
        migrantAfter: " and is your money in the bank $100 or less?",
        migrantHelp:
          "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
        migrantOptions: ["Yes", "No"],
        migrantErrmsg: "Please select migrant you pay",
        headerMoreAbout: "More about me",
        areUsBefore: "Are you a ",
        areUsTrigger: "US citizen",
        areUsafter: "?",
        eligibleHelp:
          "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
        chooseErrmsg: "Please choose citizen",
        eligibleMemberHelp:
          "Even if you are not a US Citizen, you or other household members may still be eligible.",
        eligibleOptions: ["Yes", "No"],
        languageBefore: "What language do you ",
        LANGUAGES: [
          "American Sign Language User",
          "Amharic",
          "Arabic",
          "Armenian",
          "Cambodian",
          "Chinese",
          "Chinese - cantonese",
          "Chinese - Mandarin",
          "Croatian",
          "English",
          "French",
          "Greek",
          "Haitian Creole",
          "Italian",
          "Laotian",
          "Other",
          "Polish",
          "Portuguese",
          "Russian",
          "Serbian Cyrillic",
          "Slovenian",
          "Somali",
          "Spanish",
          "Tagalog",
          "Vietnamese",
        ],
        languageTrigger: "prefer to speak ",
        languageAfter: "?",
        comfortLanHelp:
          "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
        languageErrMsg: "Please choose language",
        genderLabel: "What is your gender?",
        genderOptions: ["Male", "Female"],
        genderErrmsg: "Please choose gender",
        labelEthnicity: "What is your ethnicity?",
        helpEthnicity:
          "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "What is your race?",
        raceHelp:
          "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
        optionAIdisplay: "American Indian",
        optionAsiandisplay_VALUE: "ASIAN",
        optionAsiandisplay: "Asian",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Black/African American",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Pacific Islander",
        optionwhitedisplay_VALUE: "WHITE",
        optionwhitedisplay: "White",
        declineToAnswerLabel: "Decline to answer",
        inlineHelpSSN:
          "Noncitizens who are not applying for TAFDC do not need to give their SSN or citizenship status.",
        ebtCard: "Do you need an EBT Card?",
        deseExactMatchMsg:
          "Thank you for your P-EBT Card replacement request. A card will be mailed out to you in 5-7 business days.",
        dtaExactMatchMsg1:
          "Thank you for your request for a replacement P-EBT card. However, our records show that your P-EBT benefits are paid on an ",
        dtaExactMatchMsg2: "EBT card.",
        dtaExactMatchMsg3:
          " Contact the DHR Assistance Line at 877-382-2363 if you still require a new EBT card.",
        partialMatchMsg:
          "Thank you for your P-EBT Card replacement request. Some information you provided does not match our records or needs further review. We will review your request and contact you in 7-10 business days if we need more information.",
        noMatchMsg1:
          "Thank you for your P-EBT Card replacement request. We are unable to locate your record at this time.",
        noMatchMsg2:
          "Please contact the DHR Assistance Line at 877-382-2363 for further assistance.",
      };
    case ES:
      return {
        title: "Solicitud de reemplazo de tarjeta P-EBT",
        header: "Solicitud de reemplazo de una tarjeta P-EBT",
        subHeader:
          "Esta página es solo para familias que recibieron una tarjeta P-EBT por correo y están solicitando una tarjeta de reemplazo. La tarjeta P-EBT se ve así:",
        labelchildFirstName: "¿Cuál es el nombre del niño?",
        middleName: "¿Cuál es el segundo nombre del niño?",
        lastNamelabel: "¿Cuál es el apellido del niño?",
        dateOfBirthLabel: "¿Cuál es la fecha de nacimiento del niño?",
        addrQuestionlabel: "¿Cuál es su dirección postal?",
        bestPhoneqlabel:
          "¿A qué número telefónico podemos comunicarnos con usted?",
        signupalert:
          "¿Le gustaría registrarse para recibir alertas por mensaje de texto sobre el estado de su solicitud?",
        childcaseNumber:
          "Si lo tiene, ¿cuál es el número de caso del menor (se encuentra en el aviso que le envió DHR)?",
        labelFirstName: "¿Cuál es su nombre?",
        firstNameErrMsg: "Ingrese su nombre",
        firstNameErrMsgLength:
          "Por favor, ingrese el primer nombre con 15 caracteres máximo",
        middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        middleNameErrMsgLength:
          "Por favor, ingrese el segundo nombre con 15 caracteres máximo",
        lastNameErrMsg: "Ingrese su apellido",
        lastNameErrMsgLength:
          "Por favor, ingrese los apellidos con 20 caracteres máximo",
        labelSuffix: "Sufijo",
        suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
        dobAlertMsg: "Ingrese una fecha de nacimiento válida",
        ssnLabel: "¿Cuál es su número de seguro social?",
        inputClearLabel: "No tengo",
        ssnErrMsg: "Ingrese un número de Seguro Social válido",
        headerMyContactInfo: "Mi información de contacto",
        contactInfo1:
          "Para enviar una solicitud SNAP en línea, debe proporcionar una dirección postal y un número de fono. Si tiene alguna pregunta, llame a la línea directa de Project Bread Food Source al ",
        contactInfo2: "o vaya a su",
        pregnant: "¿Está embarazada?",
        pregnatErrMsg: "Por favor, seleccione una opción a continuación",
        childLiveYouErrMsg: "Seleccione una opción a continuación",
        childSupportErrMsg: "Seleccione una opción a continuación",
        assistiveTechnologyErrMsg: "Seleccione una opción a continuación",
        moneyBankErrMsg: "Seleccione una opción a continuación",
        sourceIncomeErrMsg: "Seleccione una opción a continuación",
        childLiveYou: "¿Tiene hijos menores de 18 años que vivan con usted?",
        sourceOfIncome: "¿Tiene alguna fuente de ingresos?",
        sourceIncomeOne: "Fuente",
        monthlyAmount: "Monto mensual",
        houseLessOptionsYes: ["Sí"],
        houseLessOptionsNo: ["No"],
        incomeSourceList: [
          "Salarios",
          "Trabajo independiente",
          "Trabajo estudio",
          "SSI (Ingreso de seguridad suplementario)",
          "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
          "Desempleo",
          "Manutención",
          "Pensión",
          "Beneficios de veteranos",
          "Ingreso por alquiler",
          "Compensación a los trabajadores",
          "Otro",
        ],
        moneyBank: "¿Tiene dinero en el banco?",
        assistiveTechnology: "¿Necesita tecnología de apoyo(TTY)?",
        contacInfoLink: "oficina de DHR local.",
        haveAgencyID: "¿Tiene una identificación de agencia?",
        houselessLabel: "¿Es su núcleo familiar indigente?",
        houselessErrMsg: "Seleccione si tiene o no su hogar",
        houseLessOptions: ["Sí", "No"],
        languageBefore: "¿En qué  idioma ",
        validZipcode: "Ingrese un código postal válido",
        alsoMailingLabel: "¿Es esta su dirección postal?",
        optionsSendMail: "Sí, enviar correo allí. ",
        optionsNoDiffAddress:
          "No, utilizo una dirección diferente para el correo postal.",
        mailingQlabel: "¿Cuál es su dirección postal?",
        zipCodeErrmsg: "Ingrese un código postal válido",
        masZipCodeErrmsg:
          "Esta aplicacion de TAFDC es para residents de Alabama. Si vive en Alabama, ingrese su codigo postal. Si no vive en Alabama, debe someter una solicitud en su estado.",
        citiErrmsg: "Por favor, ingrese una ciudad válida",
        addressErrmsg: "Por favor, ingrese una dirección válida",
        validPhoneAlert: "Ingrese un número de teléfono válido",
        signupErrMsg: "Seleccione una opción a continuación",
        childcaseNumberErrMsg:
          "El número de caso del niño debe tener 9 dígitos; por favor, ingrese un número de caso válido",
        emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
        emailErrormsg: "Ingrese una dirección de correo electrónico válida",
        titleEmergency: "Beneficios de SNAP de emergencia",
        headerEmergency: "Beneficios de SNAP de emergencia",
        emergencySnap:
          "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
        incomeMoneylabel:
          "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
        inlineHelpLabel:
          "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
        optionYesNo: ["Sí", "No"],
        alertSelectIncome: "Por favor seleccione el ingreso que paga",
        incomeLesslabel:
          "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
        monthlyIncomeoptions: ["Sí", "No"],
        selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
        migrantBefore: "¿Es usted un ",
        migrantTrigger: " trabajador migrante",
        migrantAfter: " y su dinero en el banco es $100 o menos?",
        migrantHelp:
          "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
        migrantOptions: ["Sí", "No"],
        migrantErrmsg: "Por favor selecciona migrante pagas",
        headerMoreAbout: "Más sobre mí",
        areUsBefore: "¿Es usted ",
        areUsTrigger: "ciudadano de EE. UU.",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegible para SNAP, una persona debe ser ciudadano estadounidense o no ciudadano calificado. Si no está seguro de si un miembro de su hogar tiene un estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar una solicitud para los miembros elegibles del hogar, incluso si no tiene un estado calificado.",
        chooseErrmsg: "Por favor elija ciudadano",
        eligibleMemberHelp:
          "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
        eligibleOptions: ["Sí", "No"],
        LANGUAGES: [
          "Usuario de lengua de señas estadounidense",
          "Ahmárico",
          "Árabe",
          "Armenio",
          "Camboyano",
          "Chino",
          "Chino cantonés",
          "Chino mandarín",
          "Croata",
          "Inglés",
          "Francés",
          "Griego",
          "Criollo haitiano",
          "Italiano",
          "Laosiano",
          "Otro",
          "Polaco",
          "Portugués",
          "Ruso",
          "Serbio cirílico",
          "Esloveno",
          "Somalí",
          "Español",
          "Tagalogo",
          "Vietnamita",
        ],
        laguageBefore: "¿Qué idioma ",
        languageTrigger: " prefiere hablar",
        languageAfter: "?",
        comfortLanHelp:
          "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
        languageErrMsg: "Por favor, elija el idioma",
        genderLabel: "¿Cuál es su sexo?",
        genderOptions: ["Masculino", "Femenino"],
        genderErrmsg: "Por favor elija género",
        labelEthnicity: "¿Cuál es tu origen étnico?",
        helpEthnicity:
          "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        hisPanicOption: "Hispano/Latino",
        nonhisPanicOption: "No hispano/Latino",
        raceLabel: "¿Cuál es tu raza?",
        raceHelp:
          "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
        optionAIdisplay: "Indio americano",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afroamericano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Isleño del Pacífico",
        optionwhitedisplay_VALUE: "Blanco",
        optionwhitedisplay: "Blanco",
        declineToAnswerLabel: "Me niego a responder",
        inlineHelpSSN:
          "Los no ciudadanos que no solicitan TAFDC no necesitan dar su SSN o estado de ciudadanía.",
        ebtCard: "Do you need an EBT Card?",
        deseExactMatchMsg:
          "Gracias por su solicitud de reemplazo de la tarjeta P-EBT. Se le enviará una tarjeta por correo en 5-7 días hábiles.",
        dtaExactMatchMsg1:
          "Gracias por solicitar el reemplazo de la tarjeta P-EBT. Sin embargo, nuestros registros muestran que sus beneficios de P-EBT se pagan en una ",
        dtaExactMatchMsg2: "tarjeta EBT.",
        dtaExactMatchMsg3:
          " Póngase en contacto con la línea de asistencia del DHR al 877-382-2363 si todavía necesita una nueva tarjeta EBT.",
        partialMatchMsg:
          "Gracias por su solicitud de reemplazo de la tarjeta P-EBT. Parte de la información que proporcionó no coincide con nuestros registros o requiere revisión adicional. Revisaremos su solicitud y nos pondremos en contacto con usted en un plazo de 7 a 10 días hábiles si necesitamos más información.",
        noMatchMsg1:
          "Gracias por su solicitud de reemplazo de la tarjeta P-EBT. No podemos localizar su expediente en este momento.",
        noMatchMsg2:
          "Por favor, póngase en contacto con la línea de asistencia del DHR al 877-382-2363 para obtener más ayuda.",
      };
    case PT: //portuguese
      return {
        title: "Solicitação de substituição do Cartão P-EBT ",
        header: "Solicitação de substituição do cartão P-EBT",
        subHeader:
          "Esta página serve apenas para famílias que receberam o cartão P-EBT pelo correio e que estão solicitando substituição. O cartão P-EBT é mais ou menos assim:",
        labelchildFirstName: "Qual é o primeiro nome da criança?",
        middleName: "Qual é o nome do meio da criança?",
        lastNamelabel: "Qual é o sobrenome da criança?",
        dateOfBirthLabel: "Qual é a data de nascimento da criança?",
        addrQuestionlabel: "Qual é o seu endereço para correspondência?",
        bestPhoneqlabel: "Qual é o melhor número de telefone para contato?",
        signupalert:
          "Você gostaria de receber alertas via mensagem de texto sobre o status da sua solicitação?",
        childcaseNumber:
          "Se tiver, qual é o número do processo da criança (localizado no aviso enviado a você pelo DHR)?",
        labelFirstName: "Qual é o seu primeiro nome? ",
        firstNameErrMsg: "Insira o seu primeiro nome",
        firstNameErrMsgLength: "Digite o seu nome com até 15 caracteres",
        middleNameErrMsg: "Digite o nome do meio",
        middleNameErrMsgLength:
          "Digite o seu nome do meio com até 15 caracteres",
        lastNameErrMsg: "Insira o seu sobrenome",
        lastNameErrMsgLength: "Digite o seu sobrenome com até 20 caracteres",
        labelSuffix: "Sufixo",
        suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
        dobAlertMsg: "Insira uma data de nascimento válida",
        ssnLabel: "Qual é seu Número de Seguridade Social?",
        childLiveYou: "Você tem filhos menores de 18 anos morando com você?",
        assistiveTechnology:
          "Você precisa de alguma tecnologia de assistência (TTY)?",
        moneyBank: "Você tem algum dinheiro no banco?",
        sourceOfIncome: "Você tem alguma fonte de renda?",
        sourceIncomeOne: "fonte",
        monthlyAmount: "Valor mensal",
        pregnant: "Você está grávida?",
        haveAgencyID: "Você tem um ID de agência?",
        childLiveYouErrMsg: "Selecione uma opção abaixo",
        childSupportErrMsg: "Selecione uma opção abaixo",
        assistiveTechnologyErrMsg: "Selecione uma opção abaixo",
        moneyBankErrMsg: "Selecione uma opção abaixo",
        sourceIncomeErrMsg: "Selecione uma opção abaixo",
        inputClearLabel: "Eu não tenho",
        ssnErrMsg: "Insira um número de seguro social válido",
        headerMyContactInfo: "Minhas informações de contato",
        contactInfo1:
          "Para enviar uma solicitação on-line do SNAP, forneça um endereço de correspondência e número de tfone. Se você tiver quaisquer perguntas, ligue para a Project Bread Food Source Hotline no ",
        contactInfo2: "ou compareça ao seu escritório local da",
        contacInfoLink: " DHR.",
        houselessLabel: "Você é desabrigado?",
        houselessErrMsg: "Selecione se você tem casa ou não",
        houseLessOptions: ["Sim", "Não"],
        houseLessOptionsYes: ["Sim"],
        houseLessOptionsNo: ["Não"],
        validZipcode: "Insira um Zip Code válido",
        alsoMailingLabel: "O seu endereço de correspondência é o mesmo?",
        optionsSendMail: "Sim, envie as correspondências para esse endereço. ",
        optionsNoDiffAddress:
          "Não, eu uso um endereço diferente para correspondência.",
        mailingQlabel: "Qual é o seu endereço de correspondência? ",
        zipCodeErrmsg: "Insira um Zip Code válido",
        masZipCodeErrmsg:
          "Esta aplicação de EAEDC é para residentes de Alabama. Se você mora em Alabama, digite seu CEP. Se você não mora em Alabama, deve applicar no seu estado",
        citiErrmsg: "Digite uma cidade válida",
        addressErrmsg: "Digite um endereço válido",
        validPhoneAlert: "Insira um número de telefone válido",
        signupErrMsg: "Selecione uma opção abaixo",
        childcaseNumberErrMsg:
          "O número do caso da criança deve conter 9 dígitos; digite um número do caso válido",
        emailAddressLabel: "Qual é seu endereço de e-mail?",
        emailErrormsg: "Insira um endereço de e-mail válido",
        titleEmergency: "Benefício SNAP emergencial",
        headerEmergency: "Benefício SNAP emergencial",
        emergencySnap:
          "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
        incomeMoneylabel:
          "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
        inlineHelpLabel:
          'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
        optionYesNo: ["Sim", "Não"],
        alertSelectIncome: "Selecione a renda que você paga",
        incomeLesslabel:
          "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
        monthlyIncomeoptions: ["Sim", "Não"],
        selectIncomeErrmsg: "Selecione a renda que você paga",
        migrantBefore: "Você é um ",
        migrantTrigger: "trabalhador migrante",
        migrantAfter: " e tem US$ 100 ou menos no banco?",
        migrantHelp:
          "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
        migrantOptions: ["Sim", "Não"],
        migrantErrmsg: "Selecione o migrante que você paga",
        headerMoreAbout: "Mais sobre mim",
        areUsBefore: "Você é ",
        areUsTrigger: "cidadão americano",
        areUsafter: "?",
        eligibleHelp:
          "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
        chooseErrmsg: "Escolha cidadão",
        eligibleMemberHelp:
          "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
        eligibleOptions: ["Sim", "Não"],
        languageBefore: "Em qual idioma você ",
        LANGUAGES: [
          "Usuário da linguagem americana de sinais",
          "Amárico",
          "Árabe",
          "Armênio",
          "Khmer",
          "Chinês",
          "Chinês - Cantonês",
          "Chinês - Mandarim",
          "Croata",
          "Inglês",
          "Francês",
          "Grego",
          "Créole haitiano",
          "Italiano",
          "Laociano",
          "Outro",
          "Polonês",
          "Português",
          "Russo",
          "Cirílico sérvio",
          "Esloveno",
          "Somali",
          "Espanhol",
          "Tagalog",
          "Vietnamita",
        ],
        languageTrigger: "prefere falar",
        languageAfter: "?",
        comfortLanHelp:
          "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
        languageErrMsg: "Escolha o idioma",
        genderLabel: "Qual é o seu gênero?",
        genderOptions: ["Masculino", "Feminino"],
        genderErrmsg: "Escolha o gênero",
        labelEthnicity: "Qual é a sua etnia?",
        helpEthnicity:
          "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        raceLabel: "Qual é a sua raça?",
        hisPanicOption: "Hispânico/Latino",
        nonhisPanicOption: "Não-Hispânico/Latino",
        raceHelp:
          "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
        optionAIdisplay: "Americano nativo",
        optionAsiandisplay_VALUE: "Asiático",
        optionAsiandisplay: "Asiático",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Negro/Afro-americano",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Nativo das ilhas do Pacífico",
        optionwhitedisplay_VALUE: "Branco",
        optionwhitedisplay: "Branco",
        declineToAnswerLabel: "Não quero responder",
        inlineHelpSSN:
          "Não cidadãos que não estiverem solicitando o TAFDC não precisam informar o SSN ou o status de cidadania.",
        incomeSourceList: [
          "Salário",
          "Trabalho autônomo",
          "Estudo",
          "SSI (Renda de segurança suplementar)",
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
          "Desemprego",
          "Suporte infantil",
          "Pensão",
          "Benefício de veterano",
          "Renda de aluguel",
          "Compensação do trabalhador",
          "Outros",
        ],
        ebtCard: "Do you need an EBT Card?",
        deseExactMatchMsg:
          "Obrigado por pedir a substituição do seu Cartão P-EBT. Um novo cartão será enviado a você em um prazo de 5 a 7 dias úteis.",
        dtaExactMatchMsg1:
          "Obrigado por pedir a substituição do seu cartão P-EBT. No entanto, nossos registros apontam que os seus benefícios P-EBT são pagos em um ",
        dtaExactMatchMsg2: "cartão EBT.",
        dtaExactMatchMsg3:
          " Caso você ainda precise de um novo cartão EBT, entre em contato com o Serviço de Atendimento da DHR no número 877-382-2363.",
        partialMatchMsg:
          "Obrigado por pedir a substituição do seu Cartão P-EBT. Algumas informações que você forneceu não coincidem com os nossos registros ou precisam de análises adicionais. Iremos analisar a sua solicitação. Se precisarmos de mais informações, entraremos em contato com você em um prazo de 7 a 10 dias úteis.",
        noMatchMsg1:
          "Obrigado por pedir a substituição do seu Cartão P-EBT. Não estamos conseguindo localizar os seus registros.",
        noMatchMsg2:
          "Para pedir ajuda, entre em contato com o Serviço de Atendimento da DHR no número 877-382-2363.",
      };
    case ZH: //China
      return {
        title: "P-EBT 换卡请求",
        header: "DHR-Connect P-EBT卡更换申请",
        subHeader:
          "本页面只针对收到P-EBT卡邮件并 要求更换其卡的家庭。 P-EBT卡的示范样本如下：",
        labelchildFirstName: "孩子的名字是什么？",
        middleName: "孩子的中间名是什么？",
        lastNamelabel: "孩子的姓氏是什么？",
        dateOfBirthLabel: "孩子的生日日期？",
        addrQuestionlabel: "您的邮寄地址是什么？",
        bestPhoneqlabel: "最有效的联系电话号码？",
        signupalert: "您是否愿意登记接收有关您申请信息的短信提示？",
        childcaseNumber:
          "如果你有的话，孩子的案宗号码是什么（可在DTA发给您的 通知中找到）？",
        labelFirstName: "您的名字 ",
        firstNameErrMsg: "请输入您的名字",
        firstNameErrMsgLength: "请输入名字，不超过 15 个字符",
        middleNameErrMsg: "請輸入中間名",
        middleNameErrMsgLength: "请输入中间名，不超过 15 个字符",
        lastNameErrMsg: "请输入您的姓氏",
        lastNameErrMsgLength: "请输入姓氏，不超过 20 个字符",
        labelSuffix: "后缀",
        suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
        dobAlertMsg: "请输入有效出生日期",
        ssnLabel: "你的社保号码？",
        childLiveYou: "您是否有 18 岁以下的孩子，并与其同住？",
        assistiveTechnology: "您需要辅助技术 文字电话 吗(TTY)",
        moneyBank: "您在银行有存款吗？",
        sourceOfIncome: "您有收入来源吗？",
        sourceIncomeOne: "来源",
        monthlyAmount: "每月金额",
        pregnant: "您是否怀孕？",
        haveAgencyID: "什么是机构编号？",
        childLiveYouErrMsg: "请在下面选择其中一项",
        childSupportErrMsg: "请在下面选择其中一项",
        assistiveTechnologyErrMsg: "请在下面选择其中一项",
        moneyBankErrMsg: "请在下面选择其中一项",
        sourceIncomeErrMsg: "请在下面选择其中一项",
        inputClearLabel: "我没有社保",
        ssnErrMsg: "请输入有效社会安全号码",
        headerMyContactInfo: "我的联系信息",
        contactInfo1:
          "如需提交在线 SNAP 申请，您必须提供邮寄地址 和電話號碼。如果你有任何问题 请拨打面包工程 (Project Bread) 食物来源热线 在 ",
        contactInfo2: " 或前往您当地的 ",
        contacInfoLink: "DHR 办公室。",
        houselessLabel: "您是无家可归吗？",
        houselessErrMsg: "請選擇您是否有家",
        houseLessOptions: ["是", "否"],
        houseLessOptionsYes: ["是"],
        houseLessOptionsNo: ["否"],
        incomeSourceList: [
          "工资",
          "个体经营",
          "工作研究",
          "SSI（补充保障收入）",
          "RSDI（退休，幸存者和残疾保险）",
          "失业",
          "子女抚养费",
          "养老金",
          "退伍军人补贴",
          "租金收入",
          "工伤赔偿其",
          "其他",
        ],
        validZipcode: "请输入有效邮编",
        alsoMailingLabel: "这也是您的邮箱地址吗？",
        optionsSendMail: "是，可以发送信件到这里。",
        optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
        mailingQlabel: "您的邮箱地址？",
        zipCodeErrmsg: "请输入有效邮编",
        masZipCodeErrmsg:
          "此TAFDC申请只适用于马萨诸塞州居民。 如果您居住在马萨诸塞州，请输入邮政编码。如果您不是居住在马萨诸塞州，则必须在您所在的州提出申请。",
        citiErrmsg: "请输入有效城市",
        addressErrmsg: "请输入有效地址",
        inlineHelp:
          "请输入电话号码（如有）。这将帮助我们更快地处理您的 SNAP 申请。",
        validPhoneAlert: "请输入有效电话号码",
        signupErrMsg: "请在下面选择其中一项",
        childcaseNumberErrMsg:
          "儿童的病案号必须为9位数字，请输入有效的病案号码",
        emailAddressLabel: "您的电子邮件地址是？",
        emailErrormsg: "请输入有效电子邮箱地址",
        titleEmergency: "紧急SNAP补助",
        headerEmergency: "紧急SNAP补助",
        emergencySnap:
          "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
        incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
        inlineHelpLabel:
          "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
        optionYesNo: ["是", "否"],
        alertSelectIncome: "請選擇您支付的收入",
        incomeLesslabel:
          "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
        monthlyIncomeoptions: ["是", "否"],
        selectIncomeErrmsg: "請選擇您支付的收入",
        migrantBefore: "您是否是 ",
        migrantTrigger: "外来务工人员",
        migrantAfter: " 您的银行存款是否少于100美元？",
        migrantHelp:
          "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
        migrantOptions: ["是 ", "否"],
        migrantErrmsg: "請選擇你支付的移民",
        headerMoreAbout: "关于我的更多信息",
        areUsBefore: "您是否是 ",
        areUsTrigger: "美国公民",
        areUsafter: "?",
        eligibleHelp:
          "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
        chooseErrmsg: "請選擇公民",
        eligibleMemberHelp:
          "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
        eligibleOptions: ["是", "否"],
        LANGUAGES: [
          "美国手语用户",
          "阿姆哈拉语",
          "阿拉伯语",
          "亚美尼亚语",
          "柬埔寨语",
          "中文",
          "中文 - 粤语",
          "中文 - 普通话",
          "克罗地亚语",
          "英语",
          "法语",
          "希腊语",
          "海地克里奥尔语",
          "意大利语",
          "老挝语",
          "其他",
          "波兰语",
          "葡萄牙语",
          "俄语",
          "塞尔维亚语西里尔语",
          "斯洛文尼亚语",
          "索马里语",
          "西班牙语",
          "塔加拉族语",
          "越南语",
        ],
        languageBefore: "您",
        languageTrigger: "喜欢讲 ",
        languageAfter: "哪种语言?",
        comfortLanHelp:
          "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
        languageErrMsg: "請選擇語言",
        genderLabel: "您的性别？",
        genderOptions: ["男", "女"],
        genderErrmsg: "請選擇性別",
        labelEthnicity: "您的民族？",
        helpEthnicity:
          "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        hisPanicOption: "西班牙/拉丁美洲",
        nonhisPanicOption: "非西班牙/拉丁美洲",
        raceLabel: "您的种族？",
        raceHelp:
          "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
        optionAIdisplay: "美洲印第安人",
        optionAsiandisplay_VALUE: "亚洲人",
        optionAsiandisplay: "亚洲人",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "黑人/非裔美国人",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "太平洋岛民",
        optionwhitedisplay_VALUE: "白人",
        optionwhitedisplay: "白人",
        declineToAnswerLabel: "拒绝回答",
        inlineHelpSSN:
          "未申请 TAFDC 的非公明身份人士无需提供其 SSN 或公民身份状态。",
        ebtCard: "Do you need an EBT Card?",
        deseExactMatchMsg:
          "感谢申请更换 P-EBT 卡。新卡将在 5 至 7 个工作日内邮寄给您。",
        dtaExactMatchMsg1:
          "感谢申请更换 P-EBT 卡。但我们的记录显示，您的 P-EBT 补助金是发放到 ",
        dtaExactMatchMsg2: "EBT",
        dtaExactMatchMsg3:
          " 卡上。如果您仍然需要新 EBT 卡，请致电 DHR 援助热线 877-382-2363。",
        partialMatchMsg:
          "感谢申请更换 P-EBT 卡。您提交的信息与我们的记录不符，或需进一步审核。我们会审核您的申请，如需更多信息，会在 7 至 10 个工作日内与您联系。",
        noMatchMsg1: "感谢申请更换 P-EBT 卡。我们目前查不到您的记录。",
        noMatchMsg2: "请致电 DHR 援助热线 877-382-2363 获得帮助。",
      };
    case VI: //Vietnam
      return {
        title: "Yêu cầu xin lại thẻ P-EBT",
        header: "Yêu Cầu Thay Thế Thẻ P-EBT",
        subHeader:
          "Trang này chỉ dành cho những gia đình nhận được thẻ P-EBT qua đường bưu điện và đang yêu cầu thẻ thay thế. Thẻ P-EBT trông giống như sau:",
        labelchildFirstName: "Tên của đứa trẻ là gì?",
        middleName: "Tên đệm của đứa trẻ là gì?",
        lastNamelabel: "Họ của đứa trẻ là gì?",
        dateOfBirthLabel: "Ngày sinh của đứa trẻ là gì?",
        addrQuestionlabel: "Địa chỉ gửi thư của bạn là gì?",
        bestPhoneqlabel: "Số điện thoại tốt nhất để liên hệ với bạn là gì?",
        signupalert:
          "Bạn có muốn đăng ký nhận thông báo bằng tin nhắn văn bản về tình trạng yêu cầu của mình không?",
        childcaseNumber:
          "Nếu có, xin cho biết số hồ sơ của con em quý vị (ghi trên thông báo DHR gửi cho quý vị)?",
        labelFirstName: "Tên của bạn là gì? ",
        firstNameErrMsg: "Điền tên",
        firstNameErrMsgLength:
          "Vui lòng nhập vào tên gọi có ít hơn hoặc bằng 15 ký tự",
        middleNameErrMsg: "Vui lòng nhập tên đệm",
        middleNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 15 ký tự",
        lastNameErrMsg: "Điền họ",
        lastNameErrMsgLength:
          "Vui lòng nhập vào tên đệm có ít hơn hoặc bằng 20 ký tự",
        labelSuffix: "Hậu tố",
        suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
        dobAlertMsg: "Điền ngày sinh",
        ssnLabel: "Số An Sinh Xã Hội của bạn là gì?",
        childLiveYou:
          "Bạn có đứa con nào dưới 18 tuổi sống chung với mình hay không?",
        assistiveTechnology: "Bạn có cần công nghệ hỗ trợ (TTY) không?",
        moneyBank: "Bạn có tiền trong ngân hàng hay không?",
        sourceOfIncome: "Bạn có bất cứ nguồn lợi tức nào hay không?",
        sourceIncomeOne: "nguồn",
        monthlyAmount: "Số tiền hàng tháng",
        pregnant: "Bạn hiện có thai hay không?",
        haveAgencyID: "Bạn có ID cơ quan không?",
        childLiveYouErrMsg: "Vui lòng chọn một trong những điều sau",
        childSupportErrMsg: "Vui lòng chọn một trong những điều sau",
        assistiveTechnologyErrMsg: "Vui lòng chọn một trong những điều sau",
        moneyBankErrMsg: "Vui lòng chọn một trong những điều sau",
        sourceIncomeErrMsg: "Vui lòng chọn một trong những điều sau",
        inputClearLabel: "Tôi không có số ASXH",
        ssnErrMsg: "Điền mã an xinh xã hội",
        headerMyContactInfo: "Thông tin liên lạc",
        contactInfo1:
          "Để nộp đơn xin SNAP trực tuyến, bạn phải cung cấp địa chỉ gửi thư và số điện thoại. Nếu bạn có câu hỏi nào, xin vui lòng gọi Đường dây nóng Dự án Nguồn thực phẩm (Project Bread Source) ở số điện thoạ ",
        contactInfo2: "hoặc tới",
        contacInfoLink: "văn phòng DHR tại địa phương.",
        houselessLabel: "Hộ gia đình của quý vị có thuộc diện vô gia cư không?",
        houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
        houseLessOptions: ["Có", "Không"],
        houseLessOptionsYes: ["Có"],
        houseLessOptionsNo: ["Không"],
        validZipcode: "Điền mã bưu điện",
        alsoMailingLabel: "Đây cũng là địa chỉ gởi thư của quý vị phải không?",
        optionsSendMail: "Phải, hãy gửi thư đến đó.",
        optionsNoDiffAddress: "Không, tôi dùng một địa chỉ khác để nhận thư.",
        mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
        zipCodeErrmsg: "Điền mã bưu điện",
        masZipCodeErrmsg:
          "This TAFDC application is for Massachussetts residents. If you live in Massachussetts, please enter your Zip Code. If you do not live in Massachussetts, you must apply in your state.",
        citiErrmsg: "Vui lòng nhập vào tên thành phố",
        addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
        inlineHelp:
          "Vui lòng nhập vào số điện thoại nếu có. Việc này sẽ giúp chúng tôi xử lý đơn SNAP của bạn nhanh hơn.",
        validPhoneAlert: "Điền số điện thoại",
        signupErrMsg: "Vui lòng chọn một trong những điều sau",
        childcaseNumberErrMsg:
          "The child's case number must be 9 digits, please enter a valid case number",
        emailAddressLabel: "Địa chỉ email của bạn là gì?",
        emailErrormsg: "Điền địa chỉ thư điện tử",
        titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
        emergencySnap:
          "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
        incomeMoneylabel:
          "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
        inlineHelpLabel:
          "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
        optionYesNo: ["Có", "Không"],
        alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
        incomeLesslabel:
          "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
        monthlyIncomeoptions: ["Có", "Không"],
        selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
        migrantBefore: "Quý vị có phải là ",
        migrantTrigger: "công nhân di cư",
        migrantAfter:
          " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
        migrantHelp:
          "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
        migrantOptions: ["Có", "Không"],
        migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
        headerMoreAbout: "Thông tin thêm về tôi",
        areUsBefore: "Quý vị có là một ",
        areUsTrigger: "Công dân Hoa kỳ",
        areUsafter: "?",
        eligibleHelp:
          "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
        chooseErrmsg: "Vui lòng chọn công dân",
        eligibleMemberHelp:
          "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
        eligibleOptions: ["Có", "Không"],
        LANGUAGES: [
          "Người Sử dụng Ngôn ngữ Ký hiệu Mỹ",
          "Tiếng Amhara",
          "Tiếng Ả rập",
          "Tiếng Armenia",
          "Tiếng Campuchia",
          "Tiếng Trung quốc",
          "Tiếng Trung quốc - Quảng Đông",
          "Tiếng Trung quốc - Quan thoại",
          "Tiếng Croatia",
          "Tiếng Anh",
          "Tiếng Pháp",
          "Tiếng Hy lạp",
          "Tiếng Haitian Creole",
          "Tiếng Ý",
          "Tiếng Lào",
          "Tiếng khác",
          "Tiếng Ba Lan",
          "Tiếng Bồ Đào nha",
          "Tiếng Nga",
          "Tiếng Seri Serbia",
          "Tiếng Slovenia",
          "Tiếng Somali",
          "Tiếng Tây Ban Nha",
          "Tiếng Tagalog",
          "Tiếng Việt",
        ],
        languageBefore: "Quý vị ",
        languageTrigger: "thích nói ",
        languageAfter: "ngôn ngữ nào hơn?",
        comfortLanHelp:
          "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
        languageErrMsg: "Vui lòng chọn ngôn ngữ",
        genderLabel: "Giới tính của quý vị là gì?",
        genderOptions: ["Nam", "Nữ"],
        genderErrmsg: "Vui lòng chọn giới tính",
        labelEthnicity: "Quý vị thuộc dân tộc nào?",
        hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
        nonhisPanicOption: "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
        helpEthnicity:
          "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        raceLabel: "Quý vị thuộc chủng tộc nào?",
        raceHelp:
          "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
        optionAIdisplay: "Người Mỹ bản xứ",
        optionAsiandisplay_VALUE: "Người Châu Á",
        optionAsiandisplay: "Người Châu Á",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
        optionwhitedisplay_VALUE: "Người da trắng",
        optionwhitedisplay: "Người da trắng",
        declineToAnswerLabel: "Từ chối trả lời",
        inlineHelpSSN:
          "Những người không phải là công dân mà không xin TAFDC thì không cần cung cấp thông tin về tình trạng công dân hay SSN của họ.",
        incomeSourceList: [
          "Lương",
          "Tự làm chủ",
          "Nghiên cứu việc làm",
          "SSI (Thu Nhập An Sinh Bổ Sung)",
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
          "Thất nghiệp",
          "Trợ cấp nuôi con",
          "Lương hưu",
          "Trợ cấp cho Cựu chiến binh",
          "Thu nhập cho thuê",
          "Bồi thường lao động",
          "Khác",
        ],
        ebtCard: "Do you need an EBT Card?",
        deseExactMatchMsg:
          "Cảm ơn bạn đã yêu cầu thay thế Thẻ P-EBT. Thẻ sẽ được gửi đến bạn trong vòng 5-7 ngày làm việc.",
        dtaExactMatchMsg1:
          "Cảm ơn bạn đã yêu cầu thẻ P-EBT thay thế Tuy nhiên, hồ sơ của chúng tôi cho thấy rằng quyền lợi P-EBT của bạn được thanh toán trên ",
        dtaExactMatchMsg2: "thẻ EBT.",
        dtaExactMatchMsg3:
          " Hãy liên hệ với Đường Dây Hỗ Trợ của DHR theo số 877-382-2363 nếu bạn vẫn yêu cầu thẻ EBT mới.",
        partialMatchMsg:
          "Cảm ơn bạn đã yêu cầu thay thế Thẻ P-EBT. Một số thông tin bạn đã cung cấp không khớp với hồ sơ của chúng tôi hoặc cần được xem xét thêm. Chúng tôi sẽ xem xét yêu cầu của bạn và liên hệ với bạn sau 7-10 ngày làm việc nếu chúng tôi cần thêm thông tin.",
        noMatchMsg1:
          "Cảm ơn bạn đã yêu cầu thay thế Thẻ P-EBT. Chúng tôi không thể tìm thấy hồ sơ của bạn vào lúc này.",
        noMatchMsg2:
          "Vui lòng liên hệ Đường Dây Hỗ Trợ DHR theo số 877-382-2363 để được giúp đỡ thêm.",
      };
    case HI:
      return {
        title: "Demann pou Ranplasman Kat P-EBT",
        header: "Demann pou Ranplasman Kat P-EBT",
        subHeader:
          "Paj sa a se sèlman pou fanmi ki te resevwa yon kat P-EBT pa lapòs epi ki mande yon ranplasman kat. Kat P-EBT a sanble konsa:",
        labelchildFirstName: "Ki premye non timoun nan?",
        middleName: "Ki dezyèm prenon timoun nan?",
        lastNamelabel: "Ki siyati timoun nan?",
        dateOfBirthLabel: "Ki dat nesans timoun nan?",
        addrQuestionlabel: "Ki adrès ou?",
        bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
        signupalert:
          "Èske ou ta renmen enskri pou tèks mesaj avètisman sou estati demann ou an?",
        childcaseNumber:
          "Si w genyen li, ki nimewo dosyè timoun nan (ki nan avi DHR te voye ba ou a)?",
        firstNameErrMsg: "Tanpri antre prenon timoun nan",
        firstNameErrMsgLength:
          "Tanpri antre prenon ki mwens pase oswa egal a 15 karaktè",
        middleNameErrMsg: "Tanpri antre dezyèm prenon timoun nan",
        middleNameErrMsgLength:
          "Tanpri antre dezyèm prenon ki mwens pase oswa egal a 15 karaktè",
        lastNameErrMsg: "Tanpri antre siyati timoun nan",
        lastNameErrMsgLength:
          "Tanpri antre siyati ki mwens pase oswa egal a 20 karaktè",
        childDateOfBirth: "Timoun-1 dat nesans",
        dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
        validZipcode: "Tanpri antre yon kòd postal ki valid",
        zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
        masZipCodeErrmsg:
          "Aplikasyon TAFDC sa a se pou rezidan Alabama. Si w ap viv nan Alabama, tanpri antre Kòd Postal ou. Si w pa abite nan Massachussetts, ou dwe aplike nan Eta w la.",
        citiErrmsg: "Tanpri antre yon vil ki valid",
        addressErrmsg: "Tanpri antre yon adrès ki valid",
        validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
        signupErrMsg: "Tanpri chwazi yon opsyon anba a",
        childcaseNumberErrMsg:
          "Nimewo dosye timoun nan dwe gen 9 chif, tanpri antre yon nimewo ka ki valid",
        howManyLabel: "Konbyen timoun k ap viv ak ou?",
        houseErrMsg: "Tanpri chwazi gwosè fwaye a",
        childLiveYou: "Èske w gen timoun ki poko gen 18 an k ap viv ak ou?",
        childSupport: "Èske ou resevwa nenpòt sipò timoun?",
        assistiveTechnology: "Èske w bezwen nenpòt asistans teknolojik(TTY)?",
        moneyBank: "Èske w gen nenpòt lajan labank?",
        sourceOfIncome: "Èske w gen nenpòt sous revni?",
        sourceIncomeOne: "Sous",
        SousIncomeTwo: "Sous-2",
        dollarAmount: "($ Montan)",
        monthlyAmount: "Montan Mansyèl",
        incomeSourceList: [
          "Salè",
          "Travay-Endepandan",
          "Travay Etidyan",
          "SSI (Revni Sekirite Siplemantè)",
          "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
          "Chomaj",
          "Sipò Timoun",
          "Pansyon",
          "Benefis",
          "pou Veteran",
          "Revni Lwaye",
          "Konpansasyon Travayè",
          "Lòt",
        ],
        lastEmployement: "Ki dènye dat ou te travay?",
        childLiveYouErrMsg: "Tanpri chwazi yon opsyon anba a",
        childSupportErrMsg: "Tanpri chwazi yon opsyon anba a",
        assistiveTechnologyErrMsg: "Tanpri chwazi yon opsyon anba a",
        moneyBankErrMsg: "Tanpri chwazi yon opsyon anba a",
        dollarAmountErrMsg: "Tanpri antre montan revni?",
        lastEmployementErrMsg: "Tanpri chwazi dat ki valid la",
        sourceIncomeErrMsg: "Tanpri chwazi yon opsyon anba a",
        houseLessOptions: ["Wi", "Non"],
        houseLessOptionsYes: ["Wi"],
        houseLessOptionsNo: ["Non"],
        maleOptions: ["Gason"],
        femaleOptions: ["Fi"],
        alsoMailingLabel: "Èske se adrès postal ou a tou?",
        optionsSendMail: "Wi, voye lapòs la.",
        optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
        mailingQlabel: "Ki adrès postal ou?",
        inlineHelp:
          "Tanpri antre yon nimewo telefòn si ou genyen youn. Sa pral ede nou trete aplikasyon SNAP ou a pi vit.",
        emailAddressLabel: "Ki adrès imel ou?",
        emailErrormsg: "Tanpri antre yon adrès imel ki valid",
        titleEmergency: "Benefis SNAP nan Ijans",
        headerEmergency: "Benefis SNAP nan Ijans",
        emergencySnap:
          "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
        incomeMoneylabel:
          "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
        inlineHelpLabel:
          "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
        optionYesNo: ["Wi", "Non"],
        alertSelectIncome: "Tanpri chwazi revni ou peye",
        incomeLesslabel:
          "Èske revni mansyèl ou mwens pase $150 epi èske lajan labank ou se $100 oswa mwens?",
        monthlyIncomeoptions: ["Wi", "Non"],
        selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
        migrantBefore: "Èske w se yon ",
        migrantTrigger: "travayè migran",
        migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
        migrantHelp:
          "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
        migrantOptions: ["Wi", "Non"],
        migrantErrmsg: "Tanpri chwazi migran ou peye",
        headerMoreAbout: "Plis konsènan mwen menm",
        areUsBefore: "Èske w se yon ",
        areUsTrigger: "sitwayen Ameriken",
        areUsafter: "?",
        eligibleHelp:
          "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
        chooseErrmsg: "Tanpri chwazi sitwayen ",
        eligibleMemberHelp:
          "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
        eligibleOptions: ["Wi", "Non"],
        languageBefore: "Ki lang ou ",
        LANGUAGES: [
          "Itilizatè Lang Siy Ameriken",
          "Amharik",
          "Arabik",
          "Amenyen",
          "Cambodian",
          "Chinwa",
          "Chinwa - Cantonese",
          "Chinwa - Mandarin",
          "Kwoasyen",
          "Angle",
          "Franse",
          "Grèk",
          "Kreyòl Ayisyen",
          "Italyen",
          "Laosyen",
          "Lòt",
          "Polonè",
          "Pòtigè",
          "Ris",
          "Sèb Sirilik",
          "Sloveni",
          "Somalyen",
          "Panyòl",
          "Tagalog",
          "Vyetnamyen",
        ],
        languageTrigger: "prefere pale ",
        languageAfter: "?",
        comfortLanHelp:
          " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
        languageErrMsg: "Tanpri chwazi ki lang",
        genderLabel: "Ki sèks ou?",
        genderOptions: ["Gason", "Fi"],
        genderErrmsg: "Tanpri chwazi sèks",
        labelEthnicity: "Ki etnisite ou?",
        helpEthnicity:
          "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        hisPanicOption: "Hispanic/Latino",
        nonhisPanicOption: "Non-Hispanic/Latino",
        raceLabel: "Ki ras ou?",
        raceHelp:
          "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
        optionAIdisplay: "Ameriken Endyen",
        optionAsiandisplay_VALUE: "AZYATIK",
        optionAsiandisplay: "Azyatik",
        optionBlackdisplay_VALUE: "BLCAFR",
        optionBlackdisplay: "Nwa/Afriken Ameriken",
        optionPacificdisplay_VALUE: "NTHPCI",
        optionPacificdisplay: "moun Zile Pasifik",
        optionwhitedisplay_VALUE: "BLAN",
        optionwhitedisplay: "Blan",
        declineToAnswerLabel: "Refize reponn",
        inlineHelpSSN:
          "Non-sitwayen Ameriken ki pa ap aplike pou TAFDC pa bezwen bay NSS yo oswa estati sitwayènte yo.",
        ebtCard: "Èske w bezwen yon kat EBT?",
        deseExactMatchMsg:
          "Mèsi pou demann ranplasman Kat P-EBT ou a. Yo pral voye yon kat ba ou pa lapòs nan 5-7 jou ouvrab.",
        dtaExactMatchMsg1:
          "Mèsi pou demann ou fè pou ranplase yon kat P-EBT. Men, dosye nou yo montre se nan yon ",
        dtaExactMatchMsg2: "kat BET",
        dtaExactMatchMsg3:
          " yo konn peye avantaj P-EBT ou yo. Kontakte Liy Asistans pou DHR a nan 1-877-382-2363 si ou toujou oblije genyen yon nouvo kat EBT.",
        partialMatchMsg:
          "Mèsi pou demann ranplasman Kat P-EBT ou a. Kèk enfòmasyon ou te bay yo pa koresponn ak dosye nou yo oswa yo bezwen plis revizyon. N ap revize demann ou an epi kontakte w nan 7-10 jou ouvrab si nou bezwen plis enfòmasyon.",
        noMatchMsg1:
          "Mèsi pou demann ranplasman Kat P-EBT ou a. Nou pa kapab lokalize dosye ou nan moman sa a.",
        noMatchMsg2:
          "Tanpri kontakte Liy Asistans DHR a nan 877-382-2363 pou plis asistans.",
      };
    default:
      return;
  } //house hold ending ending
}

export function incomeMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        incomeTitle: "Income",
        incomeHeader: "About Your Household's Income",
        aboutIncomeBefore: "Does anyone in the household receive any ",
        aboutIncomeTrigger: "income or benefits",
        incomeHelp:
          "Income is money you receive from working. Benefits are money you receive from a source other than employment.",
        clearOption: "No one",
        wageIncomeBeforeLabel: "What is the ",
        wageIncomeTriggerLabel: "gross income",
        wagesTargetValue: "Wages",
        selfemeploymentTargetValue: "Self-Employment",
        workStudyTargetValue: "Work Study",
        rsdiTargetValue:
          "RSDI (Retirement, Survivors, and Disability Insurance)",
        SSITargetValue: "SSI (Supplemental Security Income)",
        unEmploymentTargetValue: "Unemployment",
        childSuppTargetValue: "Child Support",
        pensionTargetValue: "Pension",
        veteranBenefitsTargetValue: "Veterans Benefits",
        rentalIncomeTargetValue: "Rental Income",
        workerCompTargetValue: "Workers Compensation",
        otherTargetValue: "Other",
        noneTargetValue: "None",

        mortageInlineHelpText:
          "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
        grossIncomeHelp:
          "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
        unitsPerLabel: "per",
        unitsOptions: ["Month", "Every two weeks", "Week"],
        labelOtherwage: "+ Add another Wage",
        adsSelfEmployee: "+ Add another Self-Employment",
        condWorkStudy: "Work Study",
        workStudyLabel: "+ Add another Work Study",
        anotherSSILabel: "+ Add another SSI",
        addRDILabel: "+ Add another RSDI",
        unEmpLabel: "+ Add another Unemployment",
        childSuppLabel: "Child Support",
        anotherChildsupport: "+ Add another Child Support",
        pensionLabel: "Pension",
        anotherPensionLabel: "+ Add another Pension",
        workerCompensationLabel: "+ Add another Worker's Compensation",
        veteranBenefit: "Veteran's Benefits",
        addAnotherVBenefitLabel: "+ Add another Veteran's Benefits",
        rentIncomeLabel: "Rental Income",
        repeatQuestionLabel: "+ Add another Rental Income",
        repeatincomeQuestionLabel: "+ Add another Rental Hours",
        unitsRentalOptions: ["Month", "Week"],
        questionButtonLabel: "Add another Other",
        incomeSummaryTitle: "Income summary",
        incomeSummaryHeader: "Income summary",
        expenseTitle: "Expenses",
        almostLabel: "Almost there!",
        houseUtlilityLabel: "Housing and Utility Costs",
        expenseHeader: "Tell us about your housing costs",
        expenseSubHeader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        houseHoldQLabel: "Is your household responsible for any utilities?",
        utilityQOptions: [
          "Heat (oil, gas, electricity or propane, etc.)",
          "Electricity for an air conditioner",
          "A fee to use an air conditioner",
          "Electricity and/or gas",
          "Phone or cell phone service",
        ],
        utilitiesClearOption: "None",
        houseQLabel: "What type of housing cost does your household have?",
        houseQOptions: [
          "Rent",
          "Mortgage",
          "Property Taxes",
          "Home Insurance",
          "Condo Fee",
        ],
        houseRentQLabel: "How much is your household's rent?",
        rentTargetValue: "RENT",
        mortageTargetValue: "MORTGAGE",
        propertyTaxTargetValue: "PROPERTY_TAX",
        homeInsurTargetValue: "HOME_INSRANCE",
        condoFeeTargetValue: "CONDO_FEE",

        unitBetween: "per",
        houseUnitOptions: ["Week", "Month", "Year"],
        mortageQLabel: "How much is your household's mortgage?",
        mortageunitBetween: "per",
        mortageQoptions: ["Week", "Month", "Year"],
        mortageProptaxLabel: "How much is your household's property taxes?",
        mortagepropBetween: "per",
        mortagepropoptions: ["Week", "Month", "Year"],
        insuranceCostQLabel:
          "How much is your household's home insurance costs?",
        insuranceCostpropBetween: "per",
        insuranceCostoptions: ["Week", "Month", "Year"],
        insuranceClearOption: "No. I don't need to pay for this.",
        condofeeQLabel: "How much is your household's condo fee?",
        condofeepropBetween: "per",
        condofeeCostoptions: ["Week", "Month", "Year"],
        condofeeClearOption: "No. I don't need to pay for this.",
        propTaxQLabel: "Does your household have property taxes?",
        propTaxBetween: "per",
        propTaxOptions: ["Week", "Month", "Year"],
        propTaxClearOption: "No. I don't need to pay for this.",
        homeInsuranceQLabel: "Does your household have home insurance costs?",
        homeInsuranceBetween: "per",
        homeInsuranceUnits: ["Week", "Month", "Year"],
        homeInsuranceClearOption: "No. I don't need to pay for this.",
        condoFeeQLabel: "Does your household have a condo fee?",
        condoFeeBeweenLabel: "per",
        condoFeeUnits: ["Week", "Month", "Year"],
        condoFeeClearOption: "No. I don't need to pay for this.",

        //new labels:
        anyUtilQcost: "Does your household have any utility costs?",
        chooseHoldErrmsg: "Please choose household utilities",
        houseCostErrmsg: "Please choose housing costs",
        rentErrMsg: "Please enter rent",
        anotherRentLabel: "+ Add another rent",
        mortageErrLabel: "Please enter mortgage",
        propTaxLabel: "Please enter property taxes",
        propTaxButtonLabel: "+ Add another property taxes",
        homeInsurbutton: "+ Add another home insurance",
        homeInsurErrmsg: "Please enter home insurance",
        condoButtonLabel: "+ Add another condo fee",
        condoFeeErrmsg: "Please enter condo fee",
        addMortageButtonLabel: "+ Add another mortgage",
        childTitle: "Child or Adult Dependent Care Costs",
        childCostHeader:
          "Tell us about your Child or Adult Dependent Care costs",
        childCostSubHeader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        careGiveObjbefore:
          "Does anyone in the household have Child or Adult Dependent Care costs? This includes costs to ",
        careGiveObjtrigger: "transport",
        careGiveObjAfter: " the dependent(s) to and/or from a care provider.",
        careGiveHelp:
          "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
        questionClearOption: "No one",
        kidsandadultSubheader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        costBetween: "per",
        weekMonthOptions: ["Month", "Week"],
        costOptions: ["Week", "Month", "Year"],
        yesnoOptions: ["Yes", "No"],
        targetValueYes: "Yes",
        noofAppointment: "Please choose no. of appointments",
        addressProvider: "What is the address of the provider?",
        freqTripoptions: ["Week", "Month", "Year"],
        unitMonthTargetValue: { unit: "Month" },

        childSupTitle: "Child Support Costs",
        supportCostHeader: "Child Support Costs",
        childSupportQlegalbefore:
          "Does anyone in the household have a legal obligation (court order) to pay ",
        childSupportQlegaltrigger: "child support",
        childSupportQlegalAfter: " to a child not living with you?",
        childSupportHelp:
          "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
        medicalTitle: "Medical Costs",
        medicalInlineSeethisFull: "See this full list of",
        medicalInlineEligibleMedical: " eligible medical expenses",
        medicalHeader: "Tell us about your medical costs",
        medicalSubHeader:
          "Please include the costs you are responsible for paying even if you are behind or not able to pay them.",
        medicalExpBefore:
          "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
        medicalExpTrigger: " transportation",
        medicalExpAfter:
          ", over the counter medications, dental or eye care, adult diapers, etc.?",
        medicalExphelp:
          "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
        medicalInhelp: "See this full list of",
        medicalInhelpEligible: "eligible medical expenses",
        medicalAddress:
          "What is the address of the medical appointment or pharmacy?",
        zipErrmsg: "Please enter a valid Zip code",
        signSubmitLabel: "Sign & submit",
        signSubmitHeader: "Sign & submit",
        signSubmitSub: "You made it through the form! Now for the easy stuff.",
        subSectionLabel: "Submitted",
        submitLabelFinal: "Your TAFDC application has been submitted!",
        tellusAbout: "Tell us about your Child support costs",
        moneyYougettitle:
          "This includes money you may get from a job, the government, or other sources.",
        tripCountErrMsg: "Please enter valid trip count",
        tripCountErrMsgLen:
          "Please enter less than/equal to 6 characters for trip count",
      };
    case ES: //SPANISH
      return {
        incomeTitle: "Ingreso",
        incomeHeader: "Sobre el ingreso de su núcleo familiar",
        aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
        aboutIncomeTrigger: "ingresos o beneficios?",
        incomeHelp:
          "El ingreso es dinero que usted recibe por trabajar. Los beneficios son dinero que usted recibe de una fuente distinta a un empleo.",
        clearOption: "Ninguna",
        wageIncomeBeforeLabel: "Cuál es el ",
        wageIncomeTriggerLabel: "ingresos brutos",
        wagesTargetValue: "Salarios",
        selfemeploymentTargetValue: "Trabajo independiente",
        workStudyTargetValue: "Trabajo estudio",
        rsdiTargetValue:
          "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
        SSITargetValue: "SSI (Ingreso de seguridad suplementario)",
        unEmploymentTargetValue: "Desempleo",
        childSuppTargetValue: "Manutención",
        pensionTargetValue: "Pensión",
        veteranBenefitsTargetValue: "Beneficios de veteranos",
        rentalIncomeTargetValue: "Ingreso por alquiler",
        workerCompTargetValue: "Compensación a los trabajadores",
        otherTargetValue: "Otro",
        noneTargetValue: "Ninguno",
        mortageInlineHelpText:
          "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
        grossIncomeHelp:
          "El ingreso bruto es el ingreso total antes de sacar algo, como impuestos o seguro de salud.",
        unitsPerLabel: "por",
        unitsOptions: ["Mes", "Cada dos semanas", "Semana"],
        labelOtherwage: "+ Agrega otro Salarios",
        adsSelfEmployee: "+ Agrega otro Trabajo independiente",
        condWorkStudy: "Trabajo estudio",
        workStudyLabel: "+ Agrega otro Trabajo estudio",
        anotherSSILabel: "+ Agrega otro SSI",
        addRDILabel: "+ Agregue otro RSDI",
        unEmpLabel: "+ Agrega otro Desempleo",
        childSuppLabel: "Manutención",
        anotherChildsupport: "+ Agrega otro Manutención",
        pensionLabel: "Pensión",
        anotherPensionLabel: "+ Agrega otro Pensión",
        workerCompensationLabel:
          "+ Agregue otra Compensación a los trabajadores",
        veteranBenefit: "Beneficios de veteranos",
        addAnotherVBenefitLabel: "+ Agrega otro Beneficios de veteranos",
        rentIncomeLabel: "Ingreso por alquiler",
        repeatQuestionLabel: "+ Agrega otro Ingreso por alquiler",
        repeatincomeQuestionLabel: "+ Agrega otro Ingreso por alquiler",
        unitsRentalOptions: ["Mes", "Semana"],
        questionButtonLabel: "Agrega otro Otro",
        incomeSummaryTitle: "Resumen de ingresos",
        incomeSummaryHeader: "Resumen de ingresos",
        expenseTitle: "Gastos",
        almostLabel: "¡Casi termina!",
        houseUtlilityLabel: " Costos de vivienda y servicios públicos",
        expenseHeader: "Díganos sobre los costos de su vivienda",
        expenseSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        houseHoldQLabel:
          "¿Es su hogar es responsable de pagar los servicios públicos?",
        utilityQOptions: [
          "Calefacción (aceite, gas, electricidad o propano, etc.)",
          "Electricidad para un aire acondicionado",
          "Una tarifa por uso de un aire acondicionado ",
          "Electricidad y/o gas",
          "Servicio de teléfono o celular",
        ],
        utilitiesClearOption: "Ninguno",
        houseQLabel: "¿Qué tipo de costo de vivienda tiene su núcleo familiar?",
        houseQOptions: [
          "Alquiler",
          "Hipoteca",
          "Impuestos a la propiedad",
          "Seguro de la casa",
          "Tasa de condominio",
        ],
        houseRentQLabel: "¿Cuál es el alquiler de su núcleo familiar?",
        rentTargetValue: "Alquiler",
        mortageTargetValue: "Hipoteca",
        propertyTaxTargetValue: "Impuestos a la propiedad",
        homeInsurTargetValue: "Seguro de la casa",
        condoFeeTargetValue: "Tasa de condominio",
        unitBetween: "por",
        houseUnitOptions: ["Semana", "Mes", "Año"],
        mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
        mortageunitBetween: "por",
        mortageQoptions: ["Semana", "Mes", "Año"],
        mortageProptaxLabel:
          "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
        mortagepropBetween: "por",
        mortagepropoptions: ["Semana", "Mes", "Año"],
        insuranceCostQLabel:
          "¿Cuál es el seguro de la casa de su núcleo familiar?",
        insuranceCostpropBetween: "por",
        insuranceCostoptions: ["Semana", "Mes", "Año"],
        insuranceClearOption: "No. No necesito pagar por esto.",
        condofeeQLabel:
          "¿Cuál es el la tasa de condominio de su núcleo familiar?",
        condofeepropBetween: "por",
        condofeeCostoptions: ["Semana", "Mes", "Año"],
        condofeeClearOption: "No. No necesito pagar por esto.",
        propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mes", "Año"],
        propTaxClearOption: "No. No necesito pagar por esto.",
        homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mes", "Año"],
        homeInsuranceClearOption: "No. No necesito pagar por esto.",
        condoFeeQLabel: "¿Su hogar tiene una tarifa de condominio?",
        condoFeeBeweenLabel: "por",
        condoFeeUnits: ["Semana", "Mes", "Año"],
        condoFeeClearOption: "No. No necesito pagar por esto.",

        //new labels:
        anyUtilQcost:
          "¿Su núcleo familiar tiene costos de servicios públicos? ",
        chooseHoldErrmsg: "Por favor elija servicios domésticos",
        houseCostErrmsg: "Por favor elija los costos de la vivienda",
        rentErrMsg: "Por favor ingrese renta",
        anotherRentLabel: "+ Agregar alquiler",
        mortageErrLabel: "Por favor ingrese la hipoteca",
        propTaxLabel: "Ingrese los impuestos a la propiedad",
        propTaxButtonLabel: "+ Agregar impuestos a la propiedad",
        homeInsurbutton: "+ Agregar seguro de la casa",
        homeInsurErrmsg: "Por favor ingrese seguro de la casa",
        condoButtonLabel: "+ Agregar tasa de condominio",
        condoFeeErrmsg: "Por favor ingrese el costo",
        addMortageButtonLabel: "+ Agregar hipoteca",
        childTitle: "Costos de cuidado de hijos o adultos dependientes",
        childCostHeader:
          "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
        childCostSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        careGiveObjbefore:
          "¿Alguna persona en su núcleo familiar tiene costos de cuidado de hijos o adultos dependientes? Esto incluye costos de ",
        careGiveObjtrigger: "transporte",
        careGiveObjAfter: "del dependiente hacia y desde un cuidador.",
        careGiveHelp:
          "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
        questionClearOption: "Ninguna",
        kidsandadultSubheader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        costBetween: "por",
        weekMonthOptions: ["Semana", "Mes"],
        costOptions: ["Semana", "Mes", "Año"],
        yesnoOptions: ["Sí", "No"],
        targetValueYes: "Sí",
        noofAppointment: "Por favor, elija no.of citas",
        addressProvider: "¿Cuál es la dirección del proveedor?",
        freqTripoptions: ["Semana", "Mes", "Año"],
        unitMonthTargetValue: { unit: "Mes" },
        childSupTitle: "Costos de manutención",
        supportCostHeader: "Costos de manutención",
        childSupportQlegalbefore:
          "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
        childSupportQlegaltrigger: "manutención a un niño",
        childSupportQlegalAfter: " que non viva con usted?",
        childSupportHelp:
          "La manutención pagada puede contarse como un gasto cuando se exige legalmente y los pagos se están haciendo. Esto incluye pagos por mora",
        medicalTitle: "Costos médicos",
        medicalInlineSeethisFull: "Consulte la lista completa de",
        medicalInlineEligibleMedical: " gastos médicos elegibles.",
        medicalHeader: "Díganos sobre sus costos médicos",
        medicalSubHeader:
          "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
        medicalExpBefore:
          "¿Alguna de las personas enumeradas a continuación paga seguro médico u otros costos médicos, incluso medicamentos con receta,",
        medicalExpTrigger: "transporte",
        medicalExpAfter:
          ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
        medicalExphelp:
          "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
        medicalInhelp: "Consulte la lista completa de",
        medicalInhelpEligible: " gastos médicos elegibles",
        medicalAddress: "¿Cuál es la dirección de la cita médica o farmacia?",
        zipErrmsg: "Por favor ingrese un código postal válido",
        signSubmitLabel: "Firmar y presentar",
        signSubmitHeader: "Firmar y presentar",
        signSubmitSub: "¡Ya completó el formulario! Ahora lo más fácil.",
        subSectionLabel: "Presentada",
        submitLabelFinal: "¡Se presentó su solicitud de TAFDC!",
        tellusAbout: "Díganos sobre sus costos de manutención",
        moneyYougettitle:
          "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
        tripCountErrMsg: "Ingrese un conteo válido de desconexiones",
        tripCountErrMsgLen:
          "Ingrese 6 caracteres como máximo por conteo de desconexiones",
      };
    case PT: //PORTUGES
      return {
        incomeTitle: "Renda",
        incomeHeader: "Sobre a renda da sua família",
        aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
        aboutIncomeTrigger: " renda ou benefício?",
        incomeHelp:
          "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
        clearOption: "Ninguém",
        incomeSourceList: [
          "Salário",
          "Trabalho autônomo",
          "Estudo",
          "SSI (Renda de segurança suplementar)",
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
          "Desemprego",
          "Suporte infantil",
          "Pensão",
          "Benefício de veterano",
          "Renda de aluguel",
          "Compensação do trabalhador",
          "Outros",
        ],
        wageIncomeBeforeLabel: "Qual é o ",
        wageIncomeTriggerLabel: "rendimento bruto",
        wagesTargetValue: "Salário",
        selfemeploymentTargetValue: "Trabalho autônomo",
        workStudyTargetValue: "Estudo",
        rsdiTargetValue:
          "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
        SSITargetValue: "SSI (Renda de segurança suplementar)",
        unEmploymentTargetValue: "Desemprego",
        childSuppTargetValue: "Suporte infantil",
        pensionTargetValue: "Pensão",
        veteranBenefitsTargetValue: "Benefício de veterano",
        rentalIncomeTargetValue: "Renda de aluguel",
        workerCompTargetValue: "Compensação do trabalhador",
        otherTargetValue: "Outros",
        noneTargetValue: "Nenhum",
        mortageInlineHelpText:
          "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
        grossIncomeHelp:
          "A renda bruta é a renda total antes que qualquer coisa seja retirada, como impostos ou seguro de saúde.",
        unitsPerLabel: "por",
        unitsOptions: ["Mês", "A cada duas semanas", "Semana"],
        labelOtherwage: "+ Adicionar outro Salário",
        adsSelfEmployee: "+ Adicionar outro Trabalho autônomo",
        condWorkStudy: "Estudo",
        workStudyLabel: "+ Adicionar outro Estudo",
        anotherSSILabel: "+ Adicionar outro SSI",
        addRDILabel: "+ Adicione outro RSDI",
        unEmpLabel: "+ Adicionar outro Desemprego",
        childSuppLabel: "Suporte infantil",
        anotherChildsupport: "+ Adicionar outro Suporte infantil",
        pensionLabel: "Pensão",
        anotherPensionLabel: "+ Adicionar outro Pensão",
        workerCompensationLabel: "+ adicionar compensação de outro trabalhador",
        veteranBenefit: "Benefício de veterano",
        addAnotherVBenefitLabel: "+ Adicionar outro Benefício de veterano",
        rentIncomeLabel: "Renda de aluguel",
        repeatQuestionLabel: "+ Adicionar outro Renda de aluguel",
        repeatincomeQuestionLabel: "+ Adicionar outro Renda de aluguel",
        unitsRentalOptions: ["Mês", "Semana"],
        questionButtonLabel: "Adicionar outro Outros",
        incomeSummaryTitle: "Resumo da renda",
        incomeSummaryHeader: "Resumo da renda",
        expenseTitle: "Despesas",
        almostLabel: "Quase lá!",
        houseUtlilityLabel: "Custos residenciais e utilitários",
        expenseHeader: "Fale sobre os seus custos residenciais",
        expenseSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        houseHoldQLabel: "A sua casa é atendida por algum serviço público?",
        utilityQOptions: [
          "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
          "Eletricidade para ar condicionado",
          "Taxa para o uso de ar condicionado",
          "Eletricidade e/ou gás",
          "Serviço de telefone fixo ou celular",
        ],
        utilitiesClearOption: "Nenhum",
        houseQLabel: "Que tipo de custos residenciais a sua família tem?",
        houseQOptions: [
          "Aluguel",
          "Financiamento",
          "Impostos da propriedade",
          "Seguro residencial",
          "Condomínio",
        ],
        houseRentQLabel: "Qual é o valor do seu aluguel?",
        rentTargetValue: "Aluguel",
        mortageTargetValue: "Financiamento",
        propertyTaxTargetValue: "Impostos da propriedade",
        homeInsurTargetValue: "Seguro residencial",
        condoFeeTargetValue: "Condomínio",
        unitBetween: "por",
        houseUnitOptions: ["Semana", "Mês", "Ano"],
        mortageQLabel: "Qual é o valor do seu financiamento?",
        mortageunitBetween: "por",
        mortageQoptions: ["Semana", "Mês", "Ano"],
        mortageProptaxLabel: "Qual é o valor dos impostos da sua propriedade?",
        mortagepropBetween: "por",
        mortagepropoptions: ["Semana", "Mês", "Ano"],
        insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
        insuranceCostpropBetween: "por",
        insuranceCostoptions: ["Semana", "Mês", "Ano"],
        insuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condofeeQLabel: "Qual é o valor do seu condomínio?",
        condofeepropBetween: "por",
        condofeeCostoptions: ["Semana", "Mês", "Ano"],
        condofeeClearOption: "Não. Eu não preciso pagar por isso.",
        propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
        propTaxBetween: "por",
        propTaxOptions: ["Semana", "Mês", "Ano"],
        propTaxClearOption: "Não. Eu não preciso pagar por isso.",
        homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
        homeInsuranceBetween: "por",
        homeInsuranceUnits: ["Semana", "Mês", "Ano"],
        homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
        condoFeeQLabel: "A sua casa possui uma taxa de condomínio?",
        condoFeeBeweenLabel: "por",
        condoFeeUnits: ["Semana", "Mês", "Ano"],
        condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

        //new labels:
        anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
        chooseHoldErrmsg: "Escolha utilitários domésticos",
        houseCostErrmsg: "Escolha os custos de habitação",
        rentErrMsg: "Entre com aluguel",
        anotherRentLabel: "+ Adicionar aluguel",
        mortageErrLabel: "Insira uma hipoteca",
        propTaxLabel: "Digite os impostos sobre a propriedade",
        propTaxButtonLabel: "+ Adicionar impostos da propriedade",
        homeInsurbutton: "+ Adicionar seguro residencial",
        homeInsurErrmsg: "Digite o seguro de casa",
        condoButtonLabel: "+ Adicionar condomínio",
        condoFeeErrmsg: "Entre com taxa de condomínio",
        addMortageButtonLabel: "+ Adicionar financiamento",
        childTitle: "Custos de crianças ou dependentes",
        childCostHeader:
          "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
        childCostSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        careGiveObjbefore:
          "Alguém na sua família tem custos com cuidados para crianças ou adultos dependentes? Incluindo os custos de ",
        careGiveObjtrigger: "transporte do(s)",
        careGiveObjAfter: "dependente(s) até o prestador de serviços.",
        careGiveHelp:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        questionClearOption: "Ninguém",
        kidsandadultSubheader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        costBetween: "por",
        weekMonthOptions: ["Semana", "Mês"],
        costOptions: ["Semana", "Mês", "Ano"],
        yesnoOptions: ["Sim", "Não"],
        targetValueYes: "Sim",
        noofAppointment: "Por favor, escolha o número de compromissos",
        addressProvider: "Qual é o endereço do prestador de serviço?",
        freqTripoptions: ["Semana", "Mês", "Ano"],
        unitMonthTargetValue: { unit: "Mês" },
        childSupTitle: "Custos de suporte infantil",
        supportCostHeader: "Custos de suporte infantil",
        childSupportQlegalbefore:
          "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar  ",
        childSupportQlegaltrigger: "pelo suporte de uma",
        childSupportQlegalAfter: " criança que não vive com você?",
        childSupportHelp:
          "O pagamento de suporte infantil pode ser contado como uma despesa quando for exigido legalmente e quando os pagamentos estiverem sendo feitos. Incluindo pagamentos em atraso.",
        medicalTitle: "Custos médicos",
        medicalInlineSeethisFull: "Veja esta lista completa de",
        medicalInlineEligibleMedical: " despesas médicas elegíveis.",
        medicalHeader: "Fale sobre os seus custos médicos",
        medicalSubHeader:
          "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
        medicalExpBefore:
          "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
        medicalExpTrigger: "transporte",
        medicalExpAfter:
          ", medicamentos de farmácia, dentistas, oftalmologistas, fraldas geriátricas, etc.?",
        medicalExphelp:
          "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
        medicalInhelp: "Veja esta lista completa de",
        medicalInhelpEligible: "despesas médicas elegíveis",
        medicalAddress: "Qual é o endereço da consulta médica ou da farmácia?",
        zipErrmsg: "Por favor insira um código postal válido",
        signSubmitLabel: "Assinar e enviar",
        signSubmitHeader: "Assinar e enviar",
        signSubmitSub: "Você terminou o formulário! Agora, o mais fácil.",
        subSectionLabel: "Submetido",
        submitLabelFinal: "Sua solicitação da TAFDC foi enviada!",
        tellusAbout: "Fale sobre os custos de suporte infantil",
        moneyYougettitle:
          "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
        tripCountErrMsg: "Digite um valor de medidor válido",
        tripCountErrMsgLen: "Digite um valor de medidor com até 6 caracteres",
      };
    case ZH: //China
      return {
        incomeTitle: "收入",
        incomeHeader: "关于您的家庭收入",
        aboutIncomeBefore: "您家中是否有人获得任何 ",
        aboutIncomeTrigger: "收入或补助?",
        incomeHelp:
          "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
        clearOption: "无人",
        incomeSourceList: [
          "工资",
          "个体经营",
          "工作研究",
          "SSI（补充保障收入）",
          "RSDI（退休，幸存者和残疾保险）",
          "失业",
          "子女抚养费",
          "养老金",
          "退伍军人补贴",
          "租金收入",
          "工伤赔偿其",
          "其他",
        ],
        wageIncomeBeforeLabel: "是什麼 ",
        wageIncomeTriggerLabel: "總收入",
        wagesTargetValue: "工资",
        selfemeploymentTargetValue: "个体经营",
        workStudyTargetValue: "工作研究",
        SSITargetValue: "SSI（补充保障收入）",
        unEmploymentTargetValue: "失业",
        rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
        childSuppTargetValue: "子女抚养费",
        pensionTargetValue: "养老金",
        veteranBenefitsTargetValue: "退伍军人补贴",
        rentalIncomeTargetValue: "租金收入",
        workerCompTargetValue: "工伤赔偿其",
        otherTargetValue: "其他",
        noneTargetValue: "没有",
        mortageInlineHelpText:
          "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
        grossIncomeHelp:
          "總收入是在取出任何東西之前的總收入，如稅收或健康保險。",
        unitsPerLabel: "每",
        unitsOptions: ["月", "每两周", "周"],
        labelOtherwage: "+ 添加另一個工資",
        adsSelfEmployee: "+ 添加另一個自僱",
        condWorkStudy: "工作研究",
        workStudyLabel: "+ 添加另一個工作研究",
        anotherSSILabel: "+ 添加另一個SSI",
        addRDILabel: "+ 添加另一個RSDI",
        unEmpLabel: "+ 再增加一個失業",
        childSuppLabel: "子女撫養費",
        anotherChildsupport: "+ 添加另一個子支持",
        pensionLabel: "養老金",
        anotherPensionLabel: "+ 再加一個養老金",
        workerCompensationLabel: "添加另一个工人的赔偿",
        veteranBenefit: "老兵的好处",
        addAnotherVBenefitLabel: "+ 添加另一個退伍軍人的好處",
        rentIncomeLabel: "贴租金收",
        repeatQuestionLabel: "+ 添加另一个租金收入",
        repeatincomeQuestionLabel: "+ 添加另一个租金收入",
        unitsRentalOptions: ["月", "周"],
        questionButtonLabel: "添加另一個租金收入",
        incomeSummaryTitle: "收入摘要",
        incomeSummaryHeader: "收入摘要",
        expenseTitle: "开支",
        almostLabel: "还差一步！",
        houseUtlilityLabel: "住房和通用开支",
        expenseHeader: "请告诉我们您的家庭开支",
        expenseSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        houseHoldQLabel: "您的家庭是否负责任何公用事业设备？",
        utilityQOptions: [
          "取暖费（石油，天然气，电力或丙烷等）",
          "空调用电费",
          "空调使用费",
          "电费和/或燃气费",
          "电话费或手机费",
        ],
        utilitiesClearOption: "没有",
        houseQLabel: "您的家庭有哪些住房开支？",
        houseQOptions: [
          "房租",
          "抵押贷款",
          "财产税",
          "家庭保险",
          "物业管理费用",
        ],
        houseRentQLabel: "您的住房的月租金有多少？",
        rentTargetValue: "房租",
        mortageTargetValue: "抵押贷款",
        propertyTaxTargetValue: "财产税",
        homeInsurTargetValue: "家庭保险",
        condoFeeTargetValue: "物业管理费用",
        unitBetween: "每",
        houseUnitOptions: ["周", "月", "年"],
        mortageQLabel: "您家的房贷有多少？",
        mortageunitBetween: "每",
        mortageQoptions: ["周", "月", "年"],
        mortageProptaxLabel: "您的住房的财产税有多少？",
        mortagepropBetween: "每",
        mortagepropoptions: ["周", "月", "年"],
        insuranceCostQLabel: "您的住房的房屋保险有多少？",
        insuranceCostpropBetween: "每",
        insuranceCostoptions: ["周", "月", "年"],
        insuranceClearOption: "不，我不需要为此付费。",
        condofeeQLabel: "您的住房的物业管理费有多少？",
        condofeepropBetween: "每",
        condofeeCostoptions: ["周", "月", "年"],
        condofeeClearOption: "不，我不需要为此付费。",
        propTaxQLabel: "你家有財產稅嗎？",
        propTaxBetween: "每",
        propTaxOptions: ["周", "月", "年"],
        propTaxClearOption: "不，我不需要为此付费。",
        homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
        homeInsuranceBetween: "每",
        homeInsuranceUnits: ["周", "月", "年"],
        homeInsuranceClearOption: "不，我不需要为此付费。",
        condoFeeQLabel: "你家有公寓費嗎？",
        condoFeeBeweenLabel: "每",
        condoFeeUnits: ["周", "月", "年"],
        condoFeeClearOption: "不，我不需要为此付费。",

        //new labels:
        anyUtilQcost: "您的家庭有哪些通用开支？",
        chooseHoldErrmsg: "请选择家用电器",
        houseCostErrmsg: "请选择住房费用",
        rentErrMsg: "请输入租金",
        anotherRentLabel: "+ 添加另一個租金",
        mortageErrLabel: "请输入抵押",
        propTaxLabel: "请输入财产税",
        propTaxButtonLabel: "+ 添加另一個財產稅",
        homeInsurbutton: "+ 添加另一個家庭保險",
        homeInsurErrmsg: "请输入家庭保险",
        condoButtonLabel: "+ 添加另一個公寓費用",
        condoFeeErrmsg: "请输入公寓费用",
        addMortageButtonLabel: "+ 添加另一個抵押",
        childTitle: "子女或成人照护费用",
        childCostHeader: "告诉我们您的子女或成人的照护费",
        childCostSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        careGiveObjbefore: "您的家人有子女或成人照护开支吗？这包括 ",
        careGiveObjtrigger: "接送",
        careGiveObjAfter: "被照护人到其看护人的费用。",
        careGiveHelp:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        questionClearOption: "无人",
        kidsandadultSubheader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        costBetween: "每",
        weekMonthOptions: ["周", "月"],
        costOptions: ["周", "月", "年"],
        yesnoOptions: ["是", "否"],
        targetValueYes: "是",
        noofAppointment: "请选择任命数量",
        addressProvider: "看护人的地址？",
        freqTripoptions: ["周", "月", "年"],
        unitMonthTargetValue: { unit: "月" },
        childSupTitle: "子女抚养费用",
        supportCostHeader: "子女抚养费用",
        childSupportQlegalbefore:
          "您家中是否有人必须向不与您同住的子女支付法定 ",
        childSupportQlegaltrigger: "子女抚养费",
        childSupportQlegalAfter: " ?",
        childSupportHelp:
          "在法定情况下以及正在支付的情况下，子女抚养费可以算作一项开支。这包括拖欠款项",
        medicalTitle: "医疗费用",
        medicalInlineSeethisFull: "查看有关",
        medicalInlineEligibleMedical: " 合格医疗开支的完整清单。",
        medicalHeader: "请告诉我们您的医疗费",
        medicalSubHeader:
          "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
        medicalExpBefore:
          "下列任何人是否支付健康保险或其他医疗费用，包括门诊，",
        medicalExpTrigger: "交通",
        medicalExpAfter: ", 非处方药，牙科或眼睛护理，成人尿片等？",
        medicalExphelp:
          "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
        medicalInhelp: "查看有关",
        medicalInhelpEligible: "合格医疗开支的完整清单。",
        medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
        medicalAddress: "医疗机构或药房地址？",
        zipErrmsg: "請輸入有效的郵政編碼",
        signSubmitLabel: "签名并提交",
        signSubmitHeader: "签名并提交",
        signSubmitSub: "您已完成表格填写！现在您需要完成一些简单操作",
        subSectionLabel: "提交",
        submitLabelFinal: "您的 TAFDC 申请已提交！",
        tellusAbout: "请告诉我们您的子女抚养费",
        moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
        tripCountErrMsg: "请输入有效行程计数",
        tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
      };
    case VI: //Vietnam
      return {
        incomeTitle: "Thu nhập",
        incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
        aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
        aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không?",
        incomeHelp:
          "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
        clearOption: "Không có ai",
        wageIncomeBeforeLabel: "Cái gì là ",
        wageIncomeTriggerLabel: "Tổng thu nhập",
        wagesTargetValue: "Lương",
        selfemeploymentTargetValue: "Tự làm chủ",
        workStudyTargetValue: "Nghiên cứu việc làm",
        SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
        rsdiTargetValue:
          "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
        unEmploymentTargetValue: "Thất nghiệp",
        childSuppTargetValue: "Trợ cấp nuôi con",
        pensionTargetValue: "Lương hưu",
        veteranBenefitsTargetValue: "Trợ cấp cho Cựu chiến binh",
        rentalIncomeTargetValue: "Thu nhập cho thuê",
        workerCompTargetValue: "Bồi thường lao động",
        otherTargetValue: "Khác",
        noneTargetValue: "Khôngcó thu nhập và trợ cấp",
        mortageInlineHelpText:
          "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
        grossIncomeHelp:
          "Tổng thu nhập là tổng thu nhập trước khi đưa ra bất cứ điều gì, chẳng hạn như thuế hoặc bảo hiểm y tế.",
        unitsPerLabel: "theo",
        workerCompensationLabel: "thêm bồi thường của công nhân khác",
        unitsOptions: ["Tháng", "Mỗi hai tuần", "Tuần"],
        labelOtherwage: "+ Thêm một mức lương khác",
        adsSelfEmployee: "+ Thêm một việc tự làm",
        condWorkStudy: "Nghiên cứu việc làm",
        workStudyLabel: "+ Thêm nghiên cứu việc làm khác",
        anotherSSILabel: "+ Thêm SSI khác",
        addRDILabel: "+ Thêm một RSDI khác",
        unEmpLabel: "+ Thêm một Thất nghiệp",
        childSuppLabel: "Trợ cấp nuôi con",
        anotherChildsupport: "+ Thêm một khoản Hỗ trợ Nuôi con khác",
        pensionLabel: "Lương hưu",
        anotherPensionLabel: "+ Thêm tiền trợ cấp khác",
        veteranBenefit: "Trợ cấp cho Cựu chiến binh",
        addAnotherVBenefitLabel: "+ Thêm một lợi ích của cựu chiến binh",
        rentIncomeLabel: "Thu nhập cho thuê",
        repeatQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
        repeatincomeQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
        unitsRentalOptions: ["Tháng", "Tuần"],
        questionButtonLabel: "Thêm một Khác",
        incomeSummaryTitle: "Tóm tắt Thu nhập",
        incomeSummaryHeader: "Tóm tắt Thu nhập",
        expenseTitle: "Các chi phí",
        almostLabel: "Gần xong rồi!",
        houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
        expenseHeader: "Hãy nói cho chúng tôi biết về chi phí nhà ở của quý vị",
        expenseSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        houseHoldQLabel:
          "Hộ gia đình của bạn có phải trả các dịch vụ công như điện, nước, ga không?",
        utilityQOptions: [
          "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
          "Điện cho máy điều hòa không khí",
          "Chi phí sử dụng máy điều hòa không khí",
          "Điện và / hoặc khí đốt",
          "Dịch vụ điện thoại hoặc điện thoại di động",
        ],
        utilitiesClearOption: "Khôngcó thu nhập và trợ cấp",
        houseQLabel: "Loại chi phí nhà ở mà hộ gia đình có?",
        houseQOptions: [
          "Thuê",
          "Thế chấp",
          "Thuế bất động sản",
          "Bảo hiểm nhà ở",
          "Chi phí công quản",
        ],
        houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
        rentTargetValue: "Thuê",
        mortageTargetValue: "Thế chấp",
        propertyTaxTargetValue: "Thuế bất động sản",
        homeInsurTargetValue: "Bảo hiểm nhà ở",
        condoFeeTargetValue: "Chi phí công quản",
        unitBetween: "theo",
        houseUnitOptions: ["Tuần", "Tháng", "Năm"],
        mortageQLabel: "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
        mortageunitBetween: "theo",
        mortageQoptions: ["Tuần", "Tháng", "Năm"],
        mortageProptaxLabel:
          "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
        mortagepropBetween: "theo",
        mortagepropoptions: ["Tuần", "Tháng", "Năm"],
        insuranceCostQLabel:
          "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
        insuranceCostpropBetween: "theo",
        insuranceCostoptions: ["Tuần", "Tháng", "Năm"],
        insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condofeeQLabel:
          "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
        condofeepropBetween: "theo",
        condofeeCostoptions: ["Tuần", "Tháng", "Năm"],
        condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
        propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
        propTaxBetween: "theo",
        propTaxOptions: ["Tuần", "Tháng", "Năm"],
        propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
        homeInsuranceQLabel:
          "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
        homeInsuranceBetween: "theo",
        homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
        homeInsuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
        condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
        condoFeeBeweenLabel: "theo",
        condoFeeUnits: ["Tuần", "Tháng", "Năm"],
        condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

        //new labels:
        anyUtilQcost:
          "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
        chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
        houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
        rentErrMsg: "Hãy nhập tiền thuê",
        anotherRentLabel: "+ Thêm tiền thuê khác",
        mortageErrLabel: "Hãy nhập khoản thế chấp",
        propTaxLabel: "Vui lòng nhập thuế bất động sản",
        propTaxButtonLabel: "+ Thêm tiền thuê khác",
        homeInsurbutton: "+ Thêm một bảo hiểm khác",
        homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
        condoButtonLabel: "+ Thêm một khoản phí chung",
        condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
        addMortageButtonLabel: "+ Thêm thế chấp khác",
        childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
        childCostHeader:
          "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
        childCostSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        careGiveObjbefore:
          "Có ai trong hộ gia đình có các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc không? Chi phí này bao gồm các chi phí ",
        careGiveObjtrigger: "chuyên chở",
        careGiveObjAfter:
          "(những) người phụ thuộc đến và/về từ nhà cung cấp dịch vụ chăm sóc.",
        careGiveHelp:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
        questionClearOption: "Không có ai",
        kidsandadultSubheader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        costBetween: "theo",
        weekMonthOptions: ["Tuần", "Tháng"],
        costOptions: ["Tuần", "Tháng", "Năm"],
        yesnoOptions: ["Có", "Không"],
        targetValueYes: "Có",
        noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
        addressProvider: "Địa chỉ của nhà cung cấp là gì?",
        freqTripoptions: ["Tuần", "Tháng", "Năm"],
        unitMonthTargetValue: { unit: "Tháng" },
        childSupTitle: "Chi phí Trợ cấp nuôi Con",
        supportCostHeader: "Chi phí Trợ cấp nuôi Con",
        childSupportQlegalbefore:
          "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
        childSupportQlegaltrigger: "trợ cấp nuôi con",
        childSupportQlegalAfter:
          " cho một đứa trẻ không sống chung với quý vị?",
        childSupportHelp:
          "Khoản tiền trợ cấp nuôi con được thanh toán có thể được tính là một khoản chi phí khi khoản này được bắt buộc một cách hợp pháp và các khoản thanh toán đang được thực hiện. Khoản này bao gồm các khoản thanh toán cho các khoản nợ",
        medicalTitle: "Chi phí Y tế",
        medicalInlineSeethisFull: "Xem danh sách đầy đủ về",
        medicalInlineEligibleMedical: " chi phí y tế hợp lệ",
        medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
        medicalSubHeader:
          "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
        medicalExpBefore:
          "Có ai được liệt kê dưới đây chi trả tiền bảo hiểm y tế hay các chi phí y tế khác bao gồmtoa thuốc,",
        medicalExpTrigger: " chuyên chở",
        medicalExpAfter:
          ", thuốc không cần toa, chăm sóc răng hoặc mắt, tã người lớn, v.v.?",
        medicalExphelp:
          "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
        medicalInhelp: "Xem danh sách đầy đủ về",
        medicalInhelpEligible: " chi phí y tế hợp lệ",
        medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
        zipErrmsg: "Please enter a valid Zip code",
        signSubmitLabel: "Ký tên & Gửi",
        signSubmitHeader: "Ký tên & Gửi",
        signSubmitSub:
          "Bạn đã điền được hết trong mẫu! Bây giờ đến những thứ dễ",
        subSectionLabel: "Đã gửi",
        submitLabelFinal: "Đơn xin TAFDC của bạn đã được gửi đi!",
        tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
        moneyYougettitle:
          "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
        tripCountErrMsg: "Vui lòng nhập vào số chuyến đi có giá trị",
        tripCountErrMsgLen:
          "Vui lòng nhập vào bằng hoặc ít hơn 6 ký tự cho chuyến đi",
      };
    case HI:
      return {
        incomeTitle: "Revni",
        incomeHeader: "Konsènan revni fwaye w la",
        aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
        aboutIncomeTrigger: "revni oswa benefis",
        incomeHelp:
          "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
        clearOption: "Pesonn",
        wageIncomeBeforeLabel: "Ki sa ki ",
        wageIncomeTriggerLabel: "revni brit",
        wagesTargetValue: "Salè",
        selfemeploymentTargetValue: "Travay Endepandan",
        workStudyTargetValue: "Travay Etidyan",
        rsdiTargetValue: "RSDI (Asirans pou pran retrèt, sivivan ak andikap)",
        SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
        unEmploymentTargetValue: "Chomaj",
        childSuppTargetValue: "Sipò pou Timoun",
        pensionTargetValue: "Pansyon",
        veteranBenefitsTargetValue: "Veterans Benefits",
        rentalIncomeTargetValue: "Revni lokasyon",
        workerCompTargetValue: "Konpansasyon Travayè",
        otherTargetValue: "Lòt",
        noneTargetValue: "Okenn",
        mortageInlineHelpText:
          "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
        grossIncomeHelp:
          "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
        unitsPerLabel: "pa",
        unitsOptions: ["Mwa", "Chak de semèn", "Semèn"],
        labelOtherwage: "+ Ajoute yon lòt Salè",
        adsSelfEmployee: "+ Ajoute yon lòt travay endepandan",
        condWorkStudy: "Travay Etidyan",
        workStudyLabel: "+ Ajoute yon lòt travay etidyan",
        anotherSSILabel: "+ Ajoute yon lòt SSI",
        addRDILabel: "+ Ajoute yon lòt RSDI",
        unEmpLabel: "+ Ajoute yon lòt Chomaj ",
        childSuppLabel: "Sipò pou Timoun",
        anotherChildsupport: "+ Ajoute yon lòt Sipò Timoun",
        pensionLabel: "Pansyon",
        anotherPensionLabel: "+ Ajoute yon lòt Pansyon",
        workerCompensationLabel: "+  Ajoute yon lòt Konpansasyon Travayè",
        veteranBenefit: "Benefis Veteran yo",
        addAnotherVBenefitLabel: "+ Ajoute yon lòt Benefis Veteran",
        rentIncomeLabel: "Revni lokasyon",
        repeatQuestionLabel: "+ Ajoute yon lòt revni lwaye",
        repeatincomeQuestionLabel: "+ Ajoute yon lòt Orè lokasyon",
        unitsRentalOptions: ["Mwa", "Semèn"],
        questionButtonLabel: "Ajoute yon lòt Lòt",
        incomeSummaryTitle: "Rezime revni",
        incomeSummaryHeader: "Rezime revni",
        expenseTitle: "Depans",
        almostLabel: "Preske rive!",
        houseUtlilityLabel: "Lojman ak depans sèvis piblik",
        expenseHeader: "Pale nou konsènan depans ou yo pou lojman",
        expenseSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        houseHoldQLabel: "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
        utilityQOptions: [
          "Chofaj (luil, gaz, elektrisite oswa pwopàn, elatriye)",
          "Elektrisite pou yon klimatizè",
          "Yon frè pou itilize yon klimatizè",
          "Elektrisite ak/oswa gaz ",
          "Telefòn oswa sèvis telefòn selilè",
        ],
        utilitiesClearOption: "Okenn",
        houseQLabel: "Ki tip depans pou lojman fwaye w la genyen?",
        houseQOptions: [
          "Lwaye",
          "Prè ipotèk",
          "Taks sou pwopriyete",
          "Asirans Kay",
          "Frè Kondo",
        ],
        houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
        rentTargetValue: "Lwaye",
        mortageTargetValue: "Prè Ipotèk",
        propertyTaxTargetValue: "Taks sou Pwopriyete",
        homeInsurTargetValue: "Asirans Kay",
        condoFeeTargetValue: "Frè Kondo",

        unitBetween: "pa",
        houseUnitOptions: ["Semèn", "Mwa", "Ane"],
        mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
        mortageunitBetween: "pa",
        mortageQoptions: ["Semèn", "Mwa", "Ane"],
        mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
        mortagepropBetween: "pa",
        mortagepropoptions: ["Semèn", "Mwa", "Ane"],
        insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
        insuranceCostpropBetween: "pa",
        insuranceCostoptions: ["Semèn", "Mwa", "Ane"],
        insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
        condofeepropBetween: "pa",
        condofeeCostoptions: ["Semèn", "Mwa", "Ane"],
        condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
        propTaxBetween: "pa",
        propTaxOptions: ["Semèn", "Mwa", "Ane"],
        propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
        homeInsuranceBetween: "pa",
        homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
        homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
        condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
        condoFeeBeweenLabel: "pa",
        condoFeeUnits: ["Semèn", "Mwa", "Ane"],
        condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

        //new labels:
        anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
        chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
        houseCostErrmsg: "Tanpri chwazi depans pou lojman",
        rentErrMsg: "Tanpri antre lwaye",
        anotherRentLabel: "+ Ajoute yon lòt lwaye",
        mortageErrLabel: "Tanpri antre prè ipotèk",
        propTaxLabel: "Tanpri antre taks sou pwopriyete",
        propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
        homeInsurbutton: "+ Ajoute yon lòt asirans kay",
        homeInsurErrmsg: "Tanpri antre asirans kay",
        condoButtonLabel: "+ Ajoute yon lòt frè kondo",
        condoFeeErrmsg: "Tanpri antre frè kondo",
        addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
        childTitle: "Depans pou Swen Timoun oswa Adilt",
        childCostHeader:
          "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
        childCostSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        careGiveObjbefore:
          "Èske gen yon moun nan Kay la ki gen depans pou swen pou timoun oswa adilt? Sa enkli depans pou ",
        careGiveObjtrigger: "transpòte",
        careGiveObjAfter:
          " depandan an(yo) pou ale/soti lakay yon founisè swen.",
        careGiveHelp:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        questionClearOption: "Pesonn",
        kidsandadultSubheader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        costBetween: "pa",
        weekMonthOptions: ["Mwa", "Semèn"],
        costOptions: ["Semèn", "Mwa", "Ane"],
        yesnoOptions: ["Wi", "Non"],
        targetValueYes: "Wi",
        noofAppointment: "Tanpri chwazi kantite randevou",
        addressProvider: "Ki adrès founisè a?",
        freqTripoptions: ["Semèn", "Mwa", "Ane"],
        unitMonthTargetValue: { unit: "Mwa" },

        childSupTitle: "Depans pou Sipò Timoun",
        supportCostHeader: "Depans pou Sipò Timoun",
        childSupportQlegalbefore:
          "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
        childSupportQlegaltrigger: "sipò timoun",
        childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
        childSupportHelp:
          "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
        medicalTitle: "Depans medikal",
        medicalInlineSeethisFull: "Gade lis konplè sa a",
        medicalInlineEligibleMedical: " depans medikal ki kalifye",
        medicalHeader: "Pale nou konsènan depans medikal ou yo",
        medicalSubHeader:
          "Tanpri enkli depans yo ou responsab pou peye, menm si ou an reta oswa ou pa ka peye yo.",
        medicalExpBefore:
          "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal tankou preskripsyon,  ",
        medicalExpTrigger: " transpò",
        medicalExpAfter:
          ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou adilt, elatriye?",
        medicalExphelp:
          "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
        medicalInhelp: "Gade lis konplè sa a",
        medicalInhelpEligible: "sou depans medikal yo ki kalifye",
        medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
        zipErrmsg: "Tanpri antre yon kòd postal ki valid",
        signSubmitLabel: "Siyen epi soumèt",
        signSubmitHeader: "Siyen epi soumèt",
        signSubmitSub: "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
        subSectionLabel: "Soumèt",
        submitLabelFinal: "Aplikasyon TAFDC ou a te soumèt!",
        tellusAbout: "Pale nou konsènan depans ou yo pou Sipò Timoun",
        moneyYougettitle:
          "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
        tripCountErrMsg: "Tanpri antre kantite vwayaj ki valid",
        tripCountErrMsgLen:
          "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
      };
    default:
      return;
  } //switch END
}
