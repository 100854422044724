// Vendor / Components
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from '../../../utils/components/modal/dtaModal'
import { mapStateToProps } from "../../../redux/rootReducer";
import PropTypes, { object } from "prop-types";
import _ from "lodash";
import { withMsal } from "@azure/msal-react";
import Collapse from "react-collapse";

import moment from "moment";
// DHR Components
import DTAEditableFields from "../../../utils/components/editableFields";
import DTAModal from "../../../utils/components/modal/dtaModal";
import Icon from "../../../utils/components/dtaIcon";
import Loading from "../../../utils/components/loading";
// Constants / formatters / utilties
import * as SharedProps from "../../../utils/constants/shared";
import * as TextProps from "../../../utils/constants/text";
import * as roleProps from "../../../utils/constants/rolesConstants";
import * as BenefitProps from "../../../utils/constants/benefit";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import { postData, setHouseholdMembersList } from "../../reportChange/reportChangeActions";
import {
  showBenefitListInfo,
  getConsumerInfo,
  addConsumerAboutMeData,
  serviceFailedAction,
  saveClientInfo,
  updateConsumerInfo,
  connecttoDtaAccount,
  addrsWarningPopupAction,
  setAddressFlag
} from "../../../redux/consumer/consumerMyInfoActions";
import { getRecertData, getTrackApplication } from "../../../redux/consumer/consumerHomeActions";
// Static data (questions)
import { accountConnectQuestion } from "../../../utils/constants/consumerQuestions";
import { push } from "react-router-redux";
import * as ListData from "../../../utils/constants/consumerListData";
import { consumerAboutMeInfo } from "../consumerInfoMapData";
import {
  onLogOut,
  onAzureLogOut,
} from "../../../utils/helper/accountNavigationActions";
import Radio from "../../../utils/components/radio";
import DTADate from "../../../utils/components/dtaDate";
import DTAInput from "../../../utils/components/dtaInput.js";
import DtaSelect from "../../../utils/components/dtaSelect";
import { getAudit } from "../../../utils/constants/audit";
import {
  IncomeSectionTemplate,
  incomeSections,
} from "../../reportChange/reportChangeMapData";
import { getIncomeCodes, getUtilityLabel } from "../../reportChange/helper.js";
import { formatDate, convertTimestampToToDataFormat } from "../../../utils/components/utilities/formatters";

import { recertificationPageLabels } from "../../recertification/recertificationText.js";
import * as languageConstants from "../../../utils/constants/constants";
import { gradeDescMapper,enrollmentStatusdescMapper } from "../../reportChange/reportChangeText.js";


import { connecttodtaLogoutPopupLabels,determinationCodeMapper, determinationDescriptionMapper } from "../consumerText";
import { hideUrgentApmntAlert } from "../../../redux/consumer/consumerActivityActions";
import * as config from "../../../utils/constants/appConfigConstants";
import {
  getSavedDataForUser,
  getSaveMyProgessDataByWebAppId,
  languageChanged,
  setSectionBasedApplicationType,
} from "../../../redux/applySnap/applySnapActions";
import { unlinkAccount } from "../../../redux/consumer/consumerMyInfoActions";
import { getSummaryFrequencyLabel } from "../../../utils/components/utilities/utils";
import { medicalExpenseKeys } from "../../reportChange/reportChangeMapData";
import * as routeProps from "../../../utils/constants/routesConstants";
import { DRAFT, langOptSel } from "../../../utils/constants/constants";
import * as consumerLabels from "../consumerText";
import { applyGreeterScreeningQuestionsSections } from "../../../redux/greeterScreening/greeterScreeningActions";
import { ReportChangePageLabels } from "../../reportChange/reportChangeText.js";
import { beforeYoustartMlanguageLabels, resourcesSectionMultilanguageLabels } from "../../applySnap/applySnapText.js";

let consumerDataUserObj = JSON.parse(
  sessionStorage.getItem("loadConsumerUser")
);
let providerObj = JSON.parse(sessionStorage.getItem("providerData"));
let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

class ConsumerMyInfo extends Component {
  constructor(props) {
    sessionStorage.setItem("connectAccountData", JSON.stringify({}));

    super(...arguments);
    this._handleChangeForQuestion = this._handleChangeForQuestion.bind(this);
    this.accountAnswer = {};
    enableUniqueIds(this);
    this.state = {
      user: {},
      benefitsList: [...ListData.RECEIVED_BENEFITS],
      languageChangeWarning: TextProps.VALUE_FALSE,
      //  only - newUser flag
      newUser: TextProps.VALUE_TRUE,
      accountConnectModalOpen: TextProps.VALUE_FALSE,
      trackApplicationModalOpen: TextProps.VALUE_FALSE,
      showBenefitListInfo: [],
      accountNotFoundError: TextProps.VALUE_FALSE,
      firstNameNotEnteredError: TextProps.VALUE_FALSE,
      lastNameNotEnteredError: TextProps.VALUE_FALSE,
      dobNotEnteredError: TextProps.VALUE_FALSE,
      ssnNotEnteredError: TextProps.VALUE_FALSE,
      optionNotSelectedError: TextProps.VALUE_FALSE,
      webNumberNotEnterError: TextProps.VALUE_FALSE,
      dtaNumberNotEnterError: TextProps.VALUE_FALSE,
      ssnNumberNotEnterError: TextProps.VALUE_FALSE,
      ebtNumberNotEnterError: TextProps.VALUE_FALSE,
      clientIdNotEnterError: TextProps.VALUE_FALSE,
      recordsNotMatchError: TextProps.VALUE_FALSE,
      recordsCanNotLinked: TextProps.VALUE_FALSE,
      RIVLModalOpen: TextProps.VALUE_FALSE,
      hideCloseIcon: TextProps.VALUE_TRUE,
      isTrackApplication: TextProps.VALUE_FALSE,
      application: {},
      onSearchConsumer: "",
      unLinkSearch: "",
      disableUnlinkButton: TextProps.VALUE_FALSE,
      showConfirmationForUnlink: TextProps.VALUE_FALSE,
      pregnancyViewMode: true,
      disabilityViewMode: true,
      attendanceViewMode: true,
      showNochangeWarningModal: false,
      addPregnancyOpen:false,
      addDisabilityOpen:false,
      isPregnantDueDateError: false,
      isDisabilityReasonError: false,
      pregnancy: {
        isPregnant: "",
        dueDates:{},
        pregnancyErrors:{},
        isDataUpdated:false,
      },
      disability: {
        isParticipate: "",
        disabilityReasons:{},
        disabilityTypes:{},
        disabilityReasonsError:{},
        disabilityTypesError:{},
        isDataUpdated:false,
      },
      attendance: {
        enrollmentChange: "",
        attendance: "",
        effectiveDate: "",
        householdMemberName: "",
        shoolName: "",
        grade: "",
        isDropout: ""
      },
      requestType: "",
      isAnyModuleInEditMode:false,
      selectedPregnancyMembers:[],
      nonPregnancyMembers:[],
      selectedDisabilityMembers:[],
      nonDisabilityMembers:[],
      selectedAttendanceMembers: [],
    };
  }

  // redux apply sections loading
  componentDidMount() {
    this.props.setAddressFlag(TextProps.VALUE_TRUE);    
    if (this.props.consumer && this.props.consumer.reportChangeSections) {
      window.location.reload();
      window.sessionStorage.removeItem("module");
    }
    if (this && this.props.match.params.webAppId !== undefined) {
      this.props.getSaveMyProgessDataByWebAppId(
        this.props.match.params.webAppId
      );
    } else if(!(this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.user && this.props.consumer.consumerData.user.clientID)){
      this.setState({ isLoading: TextProps.VALUE_TRUE });
      this.props
        .getSavedDataForUser(TextProps.VALUE_APP_INPROGRESS)
        .then((data) => {
          if (data.data.application) {
            let rawApplication = JSON.parse(data.data.application);
            this.setState({ application: rawApplication });
          } else if (data.data.length && data.data[0].appType) {
            console.log("data", data);
            this.setState({
              application: {
                status: "DRAFT",
                programCode: data.data[0].appType,
                appId: data.data[0].appId,
              },
            });
          }
          this.props.startTrackApplication();
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        })
        .catch((error) => {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { reportChangeEditingModule, reportChangeEditingAction } = this.props.consumer;
    if (
      reportChangeEditingModule &&
      prevProps.consumer.reportChangeEditingAction !== reportChangeEditingAction
    ) {
      let viewModeState = {};
  
      switch (reportChangeEditingModule) {
        case 'pregnancy':
          viewModeState = { pregnancyViewMode: reportChangeEditingAction !== 'close' };
          break;
        case 'disability':
          viewModeState = { disabilityViewMode: reportChangeEditingAction !== 'close' };
          break;
        case 'attendance':
          viewModeState = { attendanceViewMode: reportChangeEditingAction !== 'close' };
          break;
        default:
          break;
      }
  
      this.setState(viewModeState);
    }
    
    if((prevProps.consumer.consumerData !== this.props.consumer.consumerData)) {
      this.setState({ isLoading: TextProps.VALUE_TRUE });
      this.props.getSavedDataForUser(TextProps.VALUE_APP_INPROGRESS)
        .then((data) => {
          if (data.data.application) {
            let rawApplication = JSON.parse(data.data.application);
            this.setState({ application: rawApplication });
          } else if (data.data.length && data.data[0].appType) {
            console.log("data", data);
            this.setState({
              application: {
                status: "DRAFT",
                programCode: data.data[0].appType,
                appId: data.data[0].appId,
              },
            });
          }
          this.props.startTrackApplication();
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        })
        .catch((error) => {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        });
    }
  }
  

  componentWillMount = () => {
    this.setContactInfo();
    this.props.hideUrgentApmntAlert();
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      onSearchConsumer: JSON.parse(sessionStorage.getItem("onSearchConsumer")),
    });
    console.log("onSearch COnsumer", this.state.onSearchConsumer);
    this.setContactInfo(nextProps);

    this.setPregnancyData();
    this.setDisabilityData();
    this.setAttendanceData();
    
  }

  setPregnancyData = () =>{
    const { consumer } = this.props;
    if (consumer && consumer.consumerData && consumer.consumerData.householdMembers) {
    let { householdMembers = [], pregnancy = [] } = consumer.consumerData;
      let femalesNotHavePregData = householdMembers.filter(person => person.genderCode === "FEMALE" && !pregnancy.some(pregPerson => pregPerson.agencyID === person.agencyID));
      let femalesHavePregData = pregnancy;
      const agencyDueDateMap = {};
      femalesHavePregData && femalesHavePregData.length > 0 && femalesHavePregData.forEach(pregData => {
        const { agencyID, dueDate } = pregData;
        pregData['dataType'] = "OLD";
        agencyDueDateMap[agencyID] = dueDate;
      });

    this.setState({
      nonPregnancyMembers: femalesNotHavePregData,
      selectedPregnancyMembers: femalesHavePregData,
      pregnancy: {
        ...this.state.pregnancy,
        dueDates: agencyDueDateMap,
        pregnancyErrors:{},
        isDataUpdated:false
      },
    });
  }
  }

  setDisabilityData = ()=>{
    const { consumer } = this.props;
    if (consumer && consumer.consumerData && consumer.consumerData.householdMembers) {
    let { householdMembers = [] ,disability=[] } = _.cloneDeep(consumer.consumerData)
    const disabilityReasons = {};
    const disabilityTypes = {};
      let personNotHaveDisData = householdMembers.filter(person => !disability.some(disPerson => disPerson.agencyID === person.agencyID));


      disability && disability.length > 0 && disability.forEach(disData => {
        const { agencyID, disabilityType } = disData;
        disData['dataType'] = "OLD";
        // disabilityReasons[agencyID] = disabilityType;
        disabilityTypes[agencyID]= disabilityType;
      });


      this.setState({
        nonDisabilityMembers: personNotHaveDisData,
        selectedDisabilityMembers:disability,
        disability:{
        ...this.state.disability,
          disabilityReasons,
        disabilityTypes,
        isDataUpdated:false,
        disabilityReasonsError:{},
        disabilityTypesError:{}
      },
      
      });
    }

  }

  setAttendanceData = () =>{
    const { consumer } = this.props;
    if (consumer && consumer.consumerData && consumer.consumerData.householdMembers) {
      let { schoolAttendance=[] } = consumer.consumerData
      this.setState({
        selectedAttendanceMembers:schoolAttendance,
      });
    }
  }



  toggleAddPregnancyMembers = () => {
    this.setPregnancyData();
    this.setState({ addPregnancyOpen: !this.state.addPregnancyOpen });
  };


  renderPregnancyUI = (info, mode, type) => {
    const filteredMembers = mode === "EDIT" ?
      this.state.selectedPregnancyMembers.filter(mem => mem.dataType === type) :
      this.state.selectedPregnancyMembers;

    return this.state.selectedPregnancyMembers && this.state.selectedPregnancyMembers.length === 0 ? <div><p className={mode === "EDIT" && "hide-none"}>{this.props.language.consumer.info.noneText}</p><br /></div> : filteredMembers.map((member) => {
      return mode === "VIEW" ? (<div className="pure-g" style={{ marginBottom: "15px" }}>
        <div className="pure-u-1-2">
          <p>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s </p>
          <p>{info.pregDueDate}</p>
        </div>
        <div className="pure-u-1-2 ">
          <p>{formatters.formatDateBySlash(member.dueDate)}</p>
        </div>
      </div>) : (<div className="pure-g" style={{ marginBottom: "15px" }}>
        <div className="pure-u-1-2">
          <p>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s </p>
          <p>{info.pregDueDate}</p>
        </div>
        <div className="pure-u-1-2 ">
          {this.state.pregnancy.pregnancyErrors && this.state.pregnancy.pregnancyErrors.hasOwnProperty(member.agencyID) && this.state.pregnancy.pregnancyErrors[member.agencyID] && <span className="dta-form__error-message" style={{ marginTop: "15px" }}>{info.dateError}</span>}
          <DTADate
            name="dueDate"
            error={this.state.pregnancy.pregnancyErrors && this.state.pregnancy.pregnancyErrors.hasOwnProperty(member.agencyID) ? this.state.pregnancy.pregnancyErrors[member.agencyID] : false}
            required={TextProps.VALUE_TRUE}
            value={this.state.pregnancy.dueDates && this.state.pregnancy.dueDates.hasOwnProperty(member.agencyID) ? this.state.pregnancy.dueDates[member.agencyID] : null}
            onChange={(val) => this.onPregnancyDateChange("pregnancy", member, val)}
          />
        </div>
      </div>)
    })
  }

  renderDisabilityReason = (mem)=>{
    mem['dataType'] = "NEW";
    let filteredMembers = this.state.nonDisabilityMembers.filter((member)=>{
      return member.agencyID !== mem.agencyID
    })
    if(!this.state.selectedDisabilityMembers.some(item => item.agencyID === mem.agencyID)){
      this.setState({
        selectedDisabilityMembers:[...this.state.selectedDisabilityMembers,mem],
        addDisabilityOpen:!this.state.addDisabilityOpen,
        nonDisabilityMembers:filteredMembers,
        disability:{
          isDataUpdated:true,
          ...this.state.disability,
          disabilityReasons:{
            ...this.state.disability.disabilityReasons,
            [mem.agencyID]:""
          },
          disabilityTypes:{
            ...this.state.disability.disabilityTypes,
            [mem.agencyID]:""
          }
        }
      })
    }
 }

  renderDisabilityUI = (info, mode, type) => {
    const filteredMembers = mode === "EDIT" ?
      this.state.selectedDisabilityMembers.filter(mem => mem.dataType === type) :
      this.state.selectedDisabilityMembers;
    return this.state.selectedDisabilityMembers && this.state.selectedDisabilityMembers.length === 0 ? <div className={mode === "EDIT" && "hide-none"}><p >{this.props.language.consumer.info.noneText}</p><br /></div> : this.state.selectedDisabilityMembers && filteredMembers.map((member) => {
    return mode === "VIEW" ? (<div className="pure-g" style={{ marginBottom: "15px" }}>
      <div className="pure-u-1-2">
      <p>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s </p>
      </div>
      <div className="pure-u-1-2 ">
      </div>
      <div className="pure-u-1-2">
        <p>{info.disabilityDetermination}</p>
      </div>
      <div className="pure-u-1-2 ">
        <p>{determinationDescriptionMapper(language)[member.disabilityType]}</p>
      </div>
      <div className="pure-u-1-2">
        <p>{info.disabilityReason}</p>
      </div>
      <div className="pure-u-1-2 ">
        {/* no PrePopulation for now */}
        <p>-</p>
      </div>
    </div>) : (<div className="pure-g" style={{ marginBottom: "10px" }}>
    <div className="pure-u-1-2">
    <p>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s </p>
    </div>
    <div className="pure-u-1-2"></div>

    <div className="pure-u-1-2">
        <p>{info.disabilityDetermination}</p>
      </div>
        <div className="pure-u-1-2 " style={{marginTop:"10px"}}>
          {this.state.disability.disabilityTypesError && this.state.disability.disabilityTypesError.hasOwnProperty(member.agencyID) && this.state.disability.disabilityTypesError[member.agencyID] && <span className="dta-form__error-message" style={{ marginTop: "15px" }}>{info.typeError}</span>}
          <DtaSelect
            name={"type"}
            className="dta-units__item"
            value={this.state.disability && this.state.disability.disabilityTypes && this.state.disability.disabilityTypes.hasOwnProperty(member.agencyID) ? determinationDescriptionMapper(language)[this.state.disability.disabilityTypes[member.agencyID]] : null}
            options={info.determinationOptions}
            onChange={(value) => this.onDisabilityTypeChange("disability", member, value)}
            ariaLabel={"Disability Type"}
            error={this.state.disability.disabilityTypesError && this.state.disability.disabilityTypesError.hasOwnProperty(member.agencyID) ? this.state.disability.disabilityTypesError[member.agencyID] : false}
          />
      </div>
      {this.state.disability.disabilityReasonsError && this.state.disability.disabilityReasonsError.hasOwnProperty(member.agencyID) && this.state.disability.disabilityReasonsError[member.agencyID] && <span className="dta-form__error-message" style={{ marginTop: "15px" }}>{info.reasonError}</span>}
      <div className="pure-u-1-2">
        <p>{info.disabilityReason}</p>
      </div>
        <div className="pure-u-1-2 " style={{marginTop:"10px"}}>
        <DTAInput
          className="dta-form__control"
          id="disabilityReason"
          maxLength={1000}
          type="text"
          required={TextProps.VALUE_TRUE}
          value={this.state.disability.disabilityReasons && this.state.disability.disabilityReasons.hasOwnProperty(member.agencyID) ? this.state.disability.disabilityReasons[member.agencyID] : null}
          onChange={(val) => this.onDisabilityReasonChange("disability", member, val)}
          error={this.state.disability.disabilityReasonsError && this.state.disability.disabilityReasonsError.hasOwnProperty(member.agencyID) ? this.state.disability.disabilityReasonsError[member.agencyID] : false}
        />
      </div>
    </div>)
  })
}

  renderAttendanceUI = (info) => {
    return this.state.selectedAttendanceMembers && this.state.selectedAttendanceMembers.length === 0 ?   <div><p >{this.props.language.consumer.info.noneText}</p><br/></div>  : this.state.selectedAttendanceMembers && this.state.selectedAttendanceMembers.map((member) => {
      return <div>
        <p>{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}'s </p>
        <div className="pure-g" style={{ marginBottom: "15px" }}>
          <div className="pure-u-1-2">
            <p>{info.schoolName}</p>
          </div>
          <div className="pure-u-1-2 ">
            <p>{member.schoolName ? member.schoolName : "-"}</p>
          </div>
          <div className="pure-u-1-2">
            <p>{info.grade}</p>
          </div>
          <div className="pure-u-1-2 ">
            <p>{member.currentGradeLevel ? gradeDescMapper(language)[member.currentGradeLevel] : "-"}</p>
          </div>
          <div className="pure-u-1-2">
            <p>{info.enrollmentStatus}</p>
          </div>
          <div className="pure-u-1-2 ">
            <p>{member.attandanceStatus ? enrollmentStatusdescMapper(language)[member.attandanceStatus] : "-"}</p>
          </div>
          <div className="pure-u-1-2">
            <p>{info.effectiveDate }</p>
          </div>
          <div className="pure-u-1-2 ">
            <p>{member.effectiveDate ? formatters.formatDateBySlash(member.effectiveDate) : "-"}</p>
          </div>
        </div>
      </div>
    })
  }


  prepareResourcesSection = () => {
    let resources =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.resources
        ? this.props.consumer.consumerData.resources
        : [];

    let resourcesArray;
    resourcesArray = resources.sort((a, b) => {
      if (
        a.firstName + " " + a.lastName + formatDate(a.dateOfBirth) >
        b.firstName + " " + b.lastName + formatDate(b.dateOfBirth)
      ) {
        return -1;
      }
    });

    return resourcesArray.reduce((acc, curr) => {
      const { agencyID, ...obj } = curr;
      const objInAcc = acc.find((o) => o.agencyID === agencyID);
      if (objInAcc) objInAcc.resourcesArray.push(curr);
      else
        acc.push({
          agencyID: agencyID,
          name:
            obj.firstName +
            " " +
            obj.lastName +
            " " +
            formatters.formatDate(obj.dateOfBirth),
            resourcesArray: [curr],
        });
      return acc;
    }, []);
  };

renderPregnancyDue = (mem)=>{
    mem['dataType'] = "NEW";
    let filteredMembers = this.state.nonPregnancyMembers.filter((member)=>{
      return member.agencyID !== mem.agencyID
    })
    if(!this.state.selectedPregnancyMembers.some(item => item.agencyID === mem.agencyID)){
      this.setState({
        selectedPregnancyMembers:[...this.state.selectedPregnancyMembers,mem],
        addPregnancyOpen:!this.state.addPregnancyOpen,
        nonPregnancyMembers:filteredMembers,
        pregnancy:{
          ...this.state.pregnancy,
          dueDates:{
            ...this.state.pregnancy.dueDates,
            [mem.agencyID]:""
          }
        }
      })
    }
 }
 
   toggleAddDisablityMembers = () => {
    this.setDisabilityData();
    this.setState({ addDisabilityOpen: !this.state.addDisabilityOpen });
  };
  prepareIncomeSection = () => {
    let earnedIncome =
      this.props.consumer &&
        this.props.consumer.consumerData &&
        this.props.consumer.consumerData.earnedIncome
        ? this.props.consumer.consumerData.earnedIncome
        : [];
    let unEarnedIncome =
      this.props.consumer &&
        this.props.consumer.consumerData &&
        this.props.consumer.consumerData.unearnedIncome
        ? this.props.consumer.consumerData.unearnedIncome
        : [];

    let incomeArray = earnedIncome.concat(unEarnedIncome);
    incomeArray = incomeArray.sort((a, b) => {
      if (
        a.firstName + " " + a.lastName + formatDate(a.dateOfBirth) >
        b.firstName + " " + b.lastName + formatDate(b.dateOfBirth)
      ) {
        return -1;
      }
    });

    return incomeArray.reduce((acc, curr) => {
      const { agencyID, ...obj } = curr;
      const objInAcc = acc.find((o) => o.agencyID === agencyID);
      if (objInAcc) objInAcc.incomeArray.push(curr);
      else
        acc.push({
          agencyID: agencyID,
          name:
            obj.firstName +
            " " +
            obj.lastName +
            " " +
            formatters.formatDate(obj.dateOfBirth),
          incomeArray: [curr],
        });
      return acc;
    }, []);
  };

  onEditableFieldsChange = (module, id, value,info) => {
    if(id==="isPregnant" && info.YesNoOptions[1] === value){
      this.setState({
        pregnancy:{
          ...this.state.pregnancy,
          dueDates:{},
        }
      },()=>{
        this.setPregnancyData();
      })
    }

    if(id==="isParticipate" && info.YesNoOptions[1] === value){
      this.setState({
        disability:{
          ...this.state.disability,
          disabilityReasons:{},
          disabilityTypes:{}
        }
      },()=>{
        this.setDisabilityData();
      })
    }

    if (id === "pregnantDueDate" && value) {
      value = convertTimestampToToDataFormat(value)
    }
    if (id === "effectiveDate" && value) {
      value = convertTimestampToToDataFormat(value)
    }
    this.setState({
      [module]: {
        ...this.state[module],
        [id]: value,
      }
    })
  }
  
  
  
  // onDisabilityReasonChange = (module,member,value) => {
  //   this.setState({
  //     [module]:{
  //       ...this.state[module],
  //       disabilityReasons:{
  //       ...this.state[module].disabilityReasons,
  //       [member.agencyID]:value
  //     }
  //   }
  //   })
  // }

  onDisabilityTypeChange = (module,member,value) => {
    this.setState({
      [module]:{
        ...this.state[module],
        disabilityTypes:{
        ...this.state[module].disabilityTypes,
        [member.agencyID]:determinationCodeMapper(language)[value]
      }
    }
    })
  }

  onPregnancyDateChange = (module,member,value) => {
    if(value){
    this.setState({
      [module]:{
        isDataUpdated:true,
        ...this.state[module],
        dueDates:{
        ...this.state[module].dueDates,
        [member.agencyID]:convertTimestampToToDataFormat(value)
      }
    }
    })
  }
  }

  onDisabilityReasonChange = (module,member,value) => {
    this.setState({
      [module]:{
        isDataUpdated:true,
        ...this.state[module],
        disabilityReasons:{
        ...this.state[module].disabilityReasons,
        [member.agencyID]:value
      }
    }
    })
  }

  
  
  loadShelterData = () => {
    let ShelterData =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.shelterExpense;
    if (ShelterData)
      return ShelterData.map((expense) => {
        return (
          <div className="pure-g" style={{ marginBottom: "5px" }}>
            <div
              className="pure-u-1 pure-u-lg-1-2"
              style={{ marginBottom: "5px" }}
            >
              <React.Fragment>
                <span>{getIncomeCodes(expense.typeCode)}</span>
              </React.Fragment>{" "}
              <br />
            </div>
            <div
              className="pure-u-1 pure-u-lg-1-2 margin-bottom"
              style={{ marginBottom: "5px" }}
            >
              <React.Fragment>
                <span>
                  {"$ " + expense.amount}{" "}
                  {getSummaryFrequencyLabel(language)[expense.frequencyCode]}
                </span>
              </React.Fragment>{" "}
              <br />
            </div>
          </div>
        );
      });
  };

  loadUtilityData = () => {
    let UtilityExisting = [];
    let utilityData =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.utilityExpense;
    if (utilityData) {
      for (let key in utilityData) {
        if (
          utilityData.hasOwnProperty(key) &&
          !key.toLowerCase().includes("datachangecode") &&
          key !== "acFeeFlag"
        ) {
          let val = utilityData[key];
          if (val) {
            UtilityExisting.push(key);
          }
        }
      }
    }
    if (UtilityExisting.length > 0) {
      return UtilityExisting.map((exp) => {
        return (
          <div className="pure-g" style={{ marginBottom: "5px" }}>
            <div className="pure-u-1 pure-u-lg-1-1">
              <React.Fragment>
                <span>{getUtilityLabel(exp)}</span>
              </React.Fragment>{" "}
              <br />
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="pure-g" style={{ marginBottom: "5px" }}>
          <div className="pure-u-1 pure-u-lg-1-1">
            <React.Fragment>
              <span>{this.props.language.consumer.info.noneText}</span>
            </React.Fragment>{" "}
            <br />
          </div>
        </div>
      );
    }
  };

   loadViewModeData=(module,data,labels)=>{
    return Object.keys(data).filter(k => !k.includes("Error")).map((key) => {
      return (
        <div className="pure-g" style={{ marginBottom: "10px" }}>
          <div className="pure-u-1-2">
            <span>{labels[key]}</span>
          </div>
          <div className="pure-u-1-2">
            {/* {labels[key] === "Due Date"  ? <span>{data[key] ? formatters.formatDateBySlash(data[key]) : "-"}</span> : <span>{data[key] ? data[key] : "-"}</span> } */}

            <span> - </span> 
          </div>
        </div>
      );
    });
  }

  prepareChildSupportSection = () => {
    let data = [];

    if (this.props.consumer && this.props.consumer.consumerData) {
      const { childSupportExpense } = this.props.consumer.consumerData;

      if (childSupportExpense && childSupportExpense.length) {
        data = childSupportExpense.reduce((acc, curr) => {
          const { agencyID, ...obj } = curr;
          const objInAcc = acc.find((o) => o.agencyID === agencyID);
          if (objInAcc) objInAcc.expenses.push(curr);
          else
            acc.push({
              agencyID: agencyID,
              name:
                obj.firstName +
                " " +
                obj.lastName +
                " " +
                formatters.formatDate(obj.dateOfBirth),
              expenses: [curr],
            });
          return acc;
        }, []);
      }
    }

    return data;
  };
  prepareDependantCareSection = () => {
    let data = [];

    if (this.props.consumer && this.props.consumer.consumerData) {
      const { dependantCare } = this.props.consumer.consumerData;

      if (dependantCare && dependantCare.length) {
        data = dependantCare.reduce((acc, curr) => {
          const { agencyID, ...obj } = curr;
          if (obj)
            acc.push({
              name:
                obj.payee.firstName +
                " " +
                obj.payee.lastName +
                " " +
                formatters.formatDate(obj.payee.dateOfBirth),
              expenses: [curr],
            });
          return acc;
        }, []);
      }
    }

    return data;
  };
  prepareMedicalExpenseSection = () => {
    let data = [];
    let usersData = {};
    let medicalExpenseData =
      this.props.consumer && this.props.consumer.consumerData;

    if (medicalExpenseData && medicalExpenseData.medicalExpenses) {
      const medicalExpenses =
        medicalExpenseData && medicalExpenseData.medicalExpenses
          ? medicalExpenseData.medicalExpenses
          : [];
      for (var i = 0; i < medicalExpenses.length; i++) {
        let user = [
          medicalExpenses[i]["firstName"],
          medicalExpenses[i]["lastName"],
          formatters.formatDate(medicalExpenses[i]["dateOfBirth"]),
        ]
          .join(" ")
          .replace(/ +(?= )/g, "");

        if (user !== " ") {
          if (!(user in usersData)) {
            usersData[user] = {};
            usersData[user]["medicalExpenses"] = [];
            usersData[user]["selectedOptions"] = [];
          }
          Object.keys(medicalExpenseKeys).map((item) => {
            if (medicalExpenses[i][medicalExpenseKeys[item]["flag"]]) {
              usersData[user] = Object.assign(usersData[user], {
                id: medicalExpenses[i]["agencyID"],
                name: user,
              });
              usersData[user]["selectedOptions"].push(item);
              usersData[user]["medicalExpenses"].push({
                amount: medicalExpenses[i][medicalExpenseKeys[item]["amount"]],
                unit: medicalExpenses[i][medicalExpenseKeys[item]["unit"]],
                typeCode: item,
                desc: medicalExpenseKeys[item]["desc"],
                lable: medicalExpenses[i][medicalExpenseKeys[item]["code"]],
              });
            }
          });
        }
      }
    }
    for (let medUser in usersData) {
      data.push(usersData[medUser]);
    }
    return data;
  };

  setContactInfo = (nextProps) => {
    let userAuthToken = "";
    if (userInfo === null || userInfo === "" || userInfo === undefined) {
      userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    if (oauthToken.access_token !== undefined) {
      userAuthToken = oauthToken.access_token;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.authToken;
    } else {
      window.location.assign("/login");
    }
    if (
      this.props.consumer.consumerData &&
      Object.keys(this.props.consumer.consumerData).length > 0
    ) {
      if (
        this.props.consumer.consumerData.clientInfo &&
        this.props.consumer.consumerData.clientInfo.name.first &&
        Object.keys(this.props.consumer.consumerData.clientInfo.name).length >
        0 &&
        this.props.consumer.consumerData.clientInfo.agencyID > 0
      ) {
        this.setState({
          user: this.props.consumer.consumerData,
          newUser: TextProps.VALUE_FALSE,
        });
      } else if (this.props.consumer.consumerData.user) {
        var providerClient = this.props.consumer.consumerData.user;
        var userData = {};
        userData.clientInfo = {
          SAOFlag: TextProps.VALUE_FALSE,
          agencyID:
            providerClient.accountID !== undefined
              ? providerClient.accountID
              : 0,
          dateOfBirth: providerClient.DOB,
          genderCode: providerClient,
          granteeFlag: TextProps.VALUE_TRUE,
          webAppID: providerClient.webAppID,
          onlineAccessBlockFlag: TextProps.VALUE_FALSE,
          name: {
            first:
              providerClient.personName !== undefined
                ? providerClient.personName.first
                : providerClient.firstName,
            last:
              providerClient.personName !== undefined
                ? providerClient.personName.last
                : providerClient.lastName,
          },
        };
        this.setState({ user: userData, newUser: TextProps.VALUE_FALSE });
      } else if (consumerDataUserObj) {
        this.setState({
          user: consumerDataUserObj,
          newUser: TextProps.VALUE_FALSE,
        });
      } else if (providerObj) {
        userData = {};
        userData.clientInfo = {
          SAOFlag: TextProps.VALUE_FALSE,
          agencyID:
            providerObj.accountID !== undefined ? providerObj.accountID : 0,
          dateOfBirth: providerObj.DOB,
          granteeFlag: TextProps.VALUE_TRUE,
          webAppID: providerObj.webAppID,
          onlineAccessBlockFlag: TextProps.VALUE_FALSE,
          name: {
            first: providerObj.personName
              ? providerObj.personName.first
              : providerObj.firstName,
            last: providerObj.personName
              ? providerObj.personName.last
              : providerObj.lastName,
          },
        };
        this.setState({ user: userData, newUser: TextProps.VALUE_FALSE });
      }
    } else if (consumerDataUserObj) {
      this.setState({
        user: consumerDataUserObj,
        newUser: TextProps.VALUE_FALSE,
      });
    }
    var clientData = JSON.parse(sessionStorage.getItem("clientDetails"));
    if (
      consumerAboutMeInfo[1] !== undefined &&
      consumerAboutMeInfo[2] !== undefined &&
      (clientData === null || providerObj !== null)
    ) {
      sessionStorage.setItem(
        "consumerAboutMeInfoTemplate",
        JSON.stringify(consumerAboutMeInfo)
      );
    }
    var updatedTemplate;
    const resAddress = this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress.find(address => address.typeCode === "RES");    
    if (this.props.consumer.addressFlagupdated && ( resAddress && resAddress.resAddrMatchFlag)) {
      updatedTemplate = JSON.parse(sessionStorage.getItem("consumerAboutMeInfoTemplate"));
      updatedTemplate[0].fields[1] = consumerAboutMeInfo[0].fields[1];
    }
    else {
      updatedTemplate = JSON.parse(sessionStorage.getItem("consumerAboutMeInfoTemplate"));
        }
    let consumerAboutMeInfoTemplate = updatedTemplate;
    if (this.props.consumer.addressFlagupdated &&  (resAddress && resAddress.resAddrMatchFlag)) {
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_TRUE);
      let homelessFlagAssign =  resAddress && resAddress.homelessFlag;
      if(homelessFlagAssign){
        sessionStorage.setItem("isHousehold", TextProps.VALUE_TRUE);
      } 
      else{
        sessionStorage.setItem("isHousehold", TextProps.VALUE_FALSE);
      }   
      this.props.setAddressFlag(TextProps.VALUE_FALSE);
    }
    else if (this.props.consumer.addressFlagupdated) {
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_FALSE);
      let homelessFlagAssign =  resAddress && resAddress.homelessFlag;
      if(homelessFlagAssign){
        sessionStorage.setItem("isHousehold", TextProps.VALUE_TRUE);   
         } 
      else{
        sessionStorage.setItem("isHousehold", TextProps.VALUE_FALSE);
      }
    
      this.props.setAddressFlag(TextProps.VALUE_FALSE);
    }
    var mailMyInfo = JSON.parse(sessionStorage.getItem("mailMyInfo"));
    var isHousehold = JSON.parse(sessionStorage.getItem("isHousehold"));

    var residentialsMatchFlag;
    var homelessFlag;
    if (clientData !== null) {
      if (
        clientData.clientAddress !== undefined &&
        clientData.clientAddress.length > 0 &&
        consumerAboutMeInfoTemplate !== null
      ) {
        clientData.clientAddress.map((addr, index) => {
          if (addr.typeCode === "RES" &&  consumerAboutMeInfoTemplate) {            
            let address = consumerAboutMeInfoTemplate[0];
            let line1 = addr.line1 ? addr.line1 : addr.addressLine1;
            let line2 = addr.line2 ? addr.line2 : addr.addressLine2;
            let zip = addr.zip ? addr.zip : addr.zipCode;
            address.fields[0]["value"].addressLine1 = line1;
            address.fields[0]["value"].addressLine2 = line2;
            let addr2 = line2 ? line2 : "";
            address.fields[0]["value"].street = line1 + " " + addr2;
            address.fields[0]["value"].county = addr.county;
            address.fields[0]["value"].verified = addr.verified;
            address.fields[0]["value"].city = addr.city;
            address.fields[0]["value"].state = addr.state;
            address.fields[0]["value"].zipCode = zip;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.typeCode === "MAIL" && consumerAboutMeInfoTemplate) {                
            let address = consumerAboutMeInfoTemplate[0];
            let line1 = addr.line1 ? addr.line1 : addr.addressLine1;
            let line2 = addr.line2 ? addr.line2 : addr.addressLine2;
            let zip = addr.zip ? addr.zip : addr.zipCode;
            address.fields[1]["value"].addressLine1 = line1;
            address.fields[1]["value"].addressLine2 = line2;
            let addr2 = line2 ? line2 : "";
            address.fields[1]["value"].street = line1 + " " + addr2;
            address.fields[1]["value"].county = addr.county;
            address.fields[1]["value"].verified = addr.verified;
            address.fields[1]["value"].city = addr.city;
            address.fields[1]["value"].state = addr.state;
            address.fields[1]["value"].zipCode = zip;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            address.fields[1].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[1].resAddrMatchFlag = residentialsMatchFlag;
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.name === "residentialAddress" && consumerAboutMeInfoTemplate) {            
            let address = consumerAboutMeInfoTemplate[0];
            let line1 = addr.value.line1
              ? addr.value.line1
              : addr.value.addressLine1;
            let line2 = addr.value.line2
              ? addr.value.line2
              : addr.value.addressLine2;
            let zip = addr.value.zip ? addr.value.zip : addr.value.zipCode;
            address.fields[0]["value"].addressLine1 = line1;
            address.fields[0]["value"].addressLine2 = line2;
            let addr2 = line2 ? line2 : "";
            address.fields[0]["value"].street = line1 + " " + addr2;
            address.fields[0]["value"].county = addr.value.county;
            address.fields[0]["value"].verified = addr.value.verified;
            address.fields[0]["value"].city = addr.value.city;
            address.fields[0]["value"].state = addr.value.state;
            address.fields[0]["value"].zipCode = zip;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.name === "is-also-mailing" && consumerAboutMeInfoTemplate) {                       
            let address = consumerAboutMeInfoTemplate[0];
            let line1 = addr.value.line1
              ? addr.value.line1
              : addr.value.addressLine1;
            let line2 = addr.value.line2
              ? addr.value.line2
              : addr.value.addressLine2;
            let zip = addr.value.zip ? addr.value.zip : addr.value.zipCode;
            addr.value.addressLine1 = line1;
            addr.value.addressLine2 = line2;
            address.fields[1]["value"].addressLine1 = line1;
            address.fields[1]["value"].addressLine2 = line2;
            let addr2 = line2 ? line2 : "";
            address.fields[1]["value"].street = line1 + " " + addr2;
            address.fields[1]["value"].county = addr.value.county;
            address.fields[1]["value"].verified = addr.value.verified;
            address.fields[1]["value"].city = addr.value.city;
            address.fields[1]["value"].state = addr.value.state;
            address.fields[1]["value"].zipCode = zip;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[1].resAddrMatchFlag = residentialsMatchFlag;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[1].homelessFlag = homelessFlag;
          }
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        });
      } else if (
        clientData.clientAddress !== undefined &&
        clientData.clientAddress.length === 0
      ) {
        let address = consumerAboutMeInfoTemplate[0];
        if (mailMyInfo === null) {
          residentialsMatchFlag = TextProps.VALUE_FALSE;
        } else if (mailMyInfo === TextProps.VALUE_FALSE) {
          residentialsMatchFlag = TextProps.VALUE_FALSE;
        } else if (mailMyInfo === TextProps.VALUE_TRUE) {
          residentialsMatchFlag = TextProps.VALUE_TRUE;
        }
        address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
        address.fields[1].resAddrMatchFlag = residentialsMatchFlag;
        sessionStorage.setItem(
          "consumerAboutMeInfoTemplate",
          JSON.stringify(consumerAboutMeInfoTemplate)
        );
      }
      if (
        clientData.clientPhone !== undefined &&
        clientData.clientPhone.phoneNumber !== undefined &&
        consumerAboutMeInfoTemplate[1] !== undefined
      ) {
        consumerAboutMeInfoTemplate[1].fields[0].value =
          clientData.clientPhone.phoneNumber;
        consumerAboutMeInfoTemplate[1].fields[1].value =
          clientData.clientPhone.textOptInFlag !== TextProps.VALUE_TRUE
            ? TextProps.VALUE_NO
            : TextProps.VALUE_YES;
        consumerAboutMeInfoTemplate[1].fields[0].editableRole =
          TextProps.VALUE_TRUE;
        consumerAboutMeInfoTemplate[1].fields[1].editableRole =
          TextProps.VALUE_TRUE;
      } else if (consumerAboutMeInfoTemplate && consumerAboutMeInfoTemplate[1]) {
        consumerAboutMeInfoTemplate[1].fields[0].value = "";
        consumerAboutMeInfoTemplate[1].fields[1].value = "";
        consumerAboutMeInfoTemplate[1].fields[0].editableRole =
          TextProps.VALUE_TRUE;
        consumerAboutMeInfoTemplate[1].fields[1].editableRole =
          TextProps.VALUE_TRUE;
      }

      if (
        clientData.clientEmail !== undefined &&
        clientData.clientEmail.emailAddress !== undefined &&
         consumerAboutMeInfoTemplate &&
        consumerAboutMeInfoTemplate[2] ) {
        consumerAboutMeInfoTemplate[2].fields[0].value =
          clientData.clientEmail.emailAddress;
        consumerAboutMeInfoTemplate[2].fields[1].value =
          clientData.clientEmail.optInFlag !== TextProps.VALUE_TRUE
            ? TextProps.VALUE_NO
            : TextProps.VALUE_YES;
        consumerAboutMeInfoTemplate[2].fields[0].editableRole =
          TextProps.VALUE_TRUE;
        consumerAboutMeInfoTemplate[2].fields[1].editableRole =
          TextProps.VALUE_TRUE;
      } else if(consumerAboutMeInfoTemplate && consumerAboutMeInfoTemplate[2]) {
        consumerAboutMeInfoTemplate[2].fields[0].value = "";
        consumerAboutMeInfoTemplate[2].fields[1].value = "";
        consumerAboutMeInfoTemplate[2].fields[0].editableRole =
          TextProps.VALUE_TRUE;
        consumerAboutMeInfoTemplate[2].fields[1].editableRole =
          TextProps.VALUE_TRUE;
      }
      sessionStorage.setItem(
        "consumerAboutMeInfoTemplate",
        JSON.stringify(consumerAboutMeInfoTemplate)
      );
    } else {
      consumerAboutMeInfoTemplate = undefined;
    }
    this.props.consumer.consumerData.consumerAboutMeInfo =
      consumerAboutMeInfoTemplate;

    if (nextProps !== undefined) {
      if (nextProps.consumer.accountNotFoundError) {
        this.setState({ accountNotFoundError: TextProps.VALUE_TRUE });
        this.setState({ recordsNotMatchError: TextProps.VALUE_FALSE });
        this.setState({ recordsCanNotLinked: TextProps.VALUE_FALSE });
        this.accountConnectModal.hideBlur();
      } else if (nextProps.consumer.recordsCanNotLinked) {
        this.setState({ accountNotFoundError: TextProps.VALUE_FALSE });
        this.setState({ recordsCanNotLinked: TextProps.VALUE_TRUE });
        this.accountConnectModal.hideBlur();
      } else if (nextProps.consumer.accountConnected) {
        setTimeout(() => {
          this.accountConnectModal.showBlur(
            "success",
            connecttodtaLogoutPopupLabels(language).connected
          );
        }, 1000);
        setTimeout(() => {
          this.setState({
            RIVLModalOpen: TextProps.VALUE_TRUE,
            accountConnectModalOpen: TextProps.VALUE_FALSE,
            trackApplicationModalOpen: TextProps.VALUE_FALSE,
          });
        }, 2000);
        this.accountConnectModal.hideBlur();
      } else {
        this.setState({ recordsNotMatchError: TextProps.VALUE_TRUE });
        if (this.accountConnectModal) {
          this.accountConnectModal.hideBlur();
        }
      }
    }
    if (nextProps !== undefined) {
      if (
        nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
        nextProps.apply.islanguageChanged === true
      ) {
        // if (this.state.sectionDataUpdated) {
        this.props.languageChanged({ allowLanguageChange: true })
      }
      if (nextProps.consumer.recordsNotMatchError) {
        this.setState({ recordsNotMatchError: TextProps.VALUE_TRUE });
        this.accountConnectModal.hideBlur();
      }
    }
  };

  openAccountConnectModal = () => {
    sessionStorage.setItem("connectAccountData", JSON.stringify({}));
    this.setState({
      accountConnectModalOpen: TextProps.VALUE_TRUE,
      accountNotFoundError: TextProps.VALUE_FALSE,
      firstNameNotEnteredError: TextProps.VALUE_FALSE,
      lastNameNotEnteredError: TextProps.VALUE_FALSE,
      dobNotEnteredError: TextProps.VALUE_FALSE,
      ssnNotEnteredError: TextProps.VALUE_FALSE,
      optionNotSelectedError: TextProps.VALUE_FALSE,
      webNumberNotEnterError: TextProps.VALUE_FALSE,
      dtaNumberNotEnterError: TextProps.VALUE_FALSE,
      ssnNumberNotEnterError: TextProps.VALUE_FALSE,
      ebtNumberNotEnterError: TextProps.VALUE_FALSE,
      recordsNotMatchError: TextProps.VALUE_FALSE,
      recordsCanNotLinked: TextProps.VALUE_FALSE,
      clientIdNotEnterError: TextProps.VALUE_FALSE,
    });
  };

  openTrackApplicationModal = () => {
    this.setState({
      trackApplicationModalOpen: TextProps.VALUE_TRUE,
    })
  }

  closeAccountConnectModal = () => {
    // Cancel any actions then...
    this.setState({ accountConnectModalOpen: TextProps.VALUE_FALSE });
    this.error = TextProps.VALUE_FALSE;
    this.errorMessage = null;
    if (this.accountAnswer.answerType !== undefined) {
      this.accountAnswer.answerType = "";
    }
    this.clearData(accountConnectQuestion);

    sessionStorage.setItem("connectAccountData", JSON.stringify({}));
  };

  closeTrackApplicationModal = ()  => {
    this.setState({ trackApplicationModalOpen: TextProps.VALUE_FALSE })
  }

  returnTypeDescription = (type) => {
    if(type === "SNPAPP") {
      return "SNAP"
    }
    if(type === "TANF") {
      return "TANF"
    }
    if(type === "TNFRCT") {
      return "TANF Redetermination"
    }
    if(type === "SNPRCT") {
      return "SNAP Recertification"
    }
  }

  clearData = () => {
    let obj = accountConnectQuestion;
    if (obj.value === "ssn") {
      obj.conditionals[0].conditionalQuestions[0].value = "";
      obj.value = "";
    } else if (obj.value === "ebt") {
      obj.conditionals[1].conditionalQuestions[0].value = "";
      obj.value = "";
    } else if (obj.value === "clientId") {
      obj.conditionals[2].conditionalQuestions[0].value = "";
      obj.value = "";
    }
    if (obj) {
      obj.map((q) => {
        q.value = "";
      });
    }
  };

  /**
   * connecting to my DHR Account Info
   *  @return User Obj info
   */
  connectAccount = () => {
    const connectAccountData = JSON.parse(
      sessionStorage.getItem("connectAccountData")
    );
    const { info } = this.props.language.consumer;
    const { search } = this.props.language.provider;
    // Set the modal to loading state and do the connect account logic
    this.accountConnectModal.showBlur(
      "loading",
      connecttodtaLogoutPopupLabels(language).connectingAcc
    );

    this.setState({
      accountNotFoundError: TextProps.VALUE_FALSE,
      recordsNotMatchError: TextProps.VALUE_FALSE,
      recordsCanNotLinked: TextProps.VALUE_FALSE
    });
    let _this = this;
    _this.accountNotFoundError = TextProps.VALUE_FALSE;
    _this.recordsNotMatchError = TextProps.VALUE_FALSE;
    _this.recordsCanNotLinked = TextProps.VALUE_FALSE;
    accountConnectQuestion.map((item) => {
      item.error = false;
      if (item.name == "accountConnectType") {
        item.conditionals[0].conditionalQuestions[0].error = false;
        item.conditionals[1].conditionalQuestions[0].error = false;
      }
    })
    if (
      this.accountAnswer.answerType !== undefined &&
      this.accountAnswer.answerType !== "" &&
      connectAccountData &&
      connectAccountData.firstName !== undefined &&
      connectAccountData.firstName !== "" &&
      connectAccountData.lastName !== undefined &&
      connectAccountData.lastName !== "" &&
      connectAccountData.dob !== undefined &&
      connectAccountData.dob !== "" &&
      connectAccountData.ssnNumber !== undefined &&
      connectAccountData.ssnNumber !== "" &&
      connectAccountData.type !== undefined &&
      connectAccountData.type !== "" &&
      connectAccountData.value !== undefined &&
      connectAccountData.value !== ""
    ) {
      let AnswerObject = _this.accountAnswer.answerType;
      AnswerObject.dateOfBirth = moment(
        new Date(connectAccountData.dob).toISOString().substring(0, 10)
      ).format("MM/DD/YYYY");
      AnswerObject.email = userInfo.email;
      AnswerObject.firstName = connectAccountData.firstName;
      AnswerObject.lastName = connectAccountData.lastName;
      AnswerObject.ssn = connectAccountData.ssnNumber;
      AnswerObject.type = connectAccountData.type;
      AnswerObject.value = connectAccountData.value;
      if (AnswerObject.value === undefined || AnswerObject.value === "") {
        _this.errorMessage = "";
        if (AnswerObject.question === undefined) {
          if (AnswerObject.type === "ebt") {
            _this.setState({ ebtNumberNotEnterError: TextProps.VALUE_TRUE });
          }
          if (AnswerObject.type === "clientId") {
            _this.setState({ clientIdNotEnterError: TextProps.VALUE_TRUE });
          }
        }
        this.error = TextProps.VALUE_FALSE;
        _this.accountConnectModal.hideBlur();
      } else {
        this.errorMessage = "";
        //Axios HTTP Request
        this.props.connecttoDtaAccount(AnswerObject);
      }
    } else {
      for (var i = 0; i < accountConnectQuestion.length; i++) {
        var item = accountConnectQuestion[i];
        item.error = false;
        if (item.name == "firstName" && connectAccountData &&
          (connectAccountData.firstName === undefined ||
            connectAccountData.firstName === "")) {
          item.error = true
          _this.setState({ firstNameNotEnteredError: TextProps.VALUE_TRUE });
          _this.accountConnectModal.hideBlur();
        } else if (item.name == "lastName" && connectAccountData &&
          (connectAccountData.lastName === undefined ||
            connectAccountData.lastName === "")) {
          item.error = true
          _this.setState({ lastNameNotEnteredError: TextProps.VALUE_TRUE });
          _this.accountConnectModal.hideBlur();
        } else if (item.name == "dob" && connectAccountData &&
          (connectAccountData.dob === undefined || connectAccountData.dob === "")) {
          item.error = true
          _this.setState({ dobNotEnteredError: TextProps.VALUE_TRUE });
          _this.accountConnectModal.hideBlur();
        } else if (item.name == "ssnNumber" && connectAccountData &&
          (connectAccountData.ssnNumber === undefined ||
            connectAccountData.ssnNumber === "")) {
          _this.setState({ ssnNotEnteredError: TextProps.VALUE_TRUE });
          _this.accountConnectModal.hideBlur();
          item.error = true;
        } else if (item.name == "accountConnectType") {
          item.conditionals[0].conditionalQuestions[0].error = false;
          item.conditionals[1].conditionalQuestions[0].error = false;
          if (connectAccountData.type) {
            var regexEBT = /^[0-9]+$/;
            if (connectAccountData.type === "ebt" && (connectAccountData.value === "" || regexEBT.test(item.conditionals[0].conditionalQuestions[0].value) === TextProps.VALUE_FALSE)) {
              _this.setState({ ebtNumberNotEnterError: TextProps.VALUE_TRUE });
              _this.accountConnectModal.hideBlur();
              item.conditionals[0].conditionalQuestions[0].error = true;
              item.conditionals[0].conditionalQuestions[0].errorMessage = search.validEBTMsg;
            }
            if (connectAccountData.type === "clientId" && (connectAccountData.value === "" || regexEBT.test(item.conditionals[1].conditionalQuestions[0].value) === TextProps.VALUE_FALSE)) {
              _this.setState({ clientIdNotEnterError: TextProps.VALUE_TRUE });
              _this.accountConnectModal.hideBlur();
              item.conditionals[1].conditionalQuestions[0].error = true;
              item.conditionals[1].conditionalQuestions[0].errorMessage = search.validClientIdMsg;
            }
          } else if ((connectAccountData.type === undefined || connectAccountData.type === "")) {
            _this.setState({ optionNotSelectedError: TextProps.VALUE_TRUE });
            _this.accountConnectModal.hideBlur();
            item.error = true;
            item.errorMessage = info.selectOption;
          }
        }
      }
    }
  };

  convertToMultiLang = (data) => {
    const { common } = this.props.language.consumer;
    switch (data) {
      case "Active":
        return common.active;
      case "Pending":
        return common.pending;
      case "Closed":
        return common.closed;
      case "Denied":
        return common.denied;
      case "Ineligible":
        return common.ineligible;
      default:
        return data;
    }
  };

  renderStaticSummary = (date) => {
    const { info } = this.props.language.consumer;
    return (
      <li className="dta-content-box benefit-summary">
        <div className="benefit-summary__header">
          <div className="benefit-summary__type">
            {formatters.benefitTypeDisplay("SNAP", TextProps.VALUE_TRUE)}{" "}
            {info.benefits}
          </div>
        </div>
        <div className="pure-g benefit-summary__content">
          <div className="pure-u-1 pure-u-lg-1-3">
            <p className="margin-none"></p>
          </div>
          <div className="pure-u-1 pure-u-lg-2-3">
            <p className="margin-bottom-none benefit-summary__details">
              <b>{info.submitted}</b>
              <br />
              <span>{info.submitedOn} </span>
              {formatters.date(formatters.convertStringDateToDate(date))}
            </p>
          </div>
        </div>
      </li>
    );
  };

  renderBenefitSummary = (benefit) => {
    const { info } = this.props.language.consumer;
    if (
      benefit[SharedProps.ID] === undefined ||
      benefit[SharedProps.ID] === null
    ) {
      window.location.assign("/login");
    } else {
      return (
        <li
          className="dta-content-box benefit-summary"
          key={benefit[SharedProps.ID]}
        >
          <div className="benefit-summary__header">
            <div className="benefit-summary__type">
              {formatters.benefitTypeDisplay(
                benefit[BenefitProps.TYPECODE],
                TextProps.VALUE_TRUE
              )}{" "}
              {info.benefits}
            </div>
            <Link to={`/myInfo/benefit/${benefit[SharedProps.ID]}`}>
              {info.viewMore}
              <span className="sr-only">
                {info.dtailsOf}{" "}
                {formatters.benefitTypeDisplay(
                  benefit[BenefitProps.TYPECODE],
                  TextProps.VALUE_TRUE
                )}{" "}
                {info.benefits}
              </span>
            </Link>
          </div>
          <div className="pure-g benefit-summary__content">
            <div className="pure-u-1 pure-u-lg-1-3">
              <p className="margin-none">
                <span className="benefit-summary__dollar-amount text--x-large">
                  {formatters.money(
                    parseFloat(benefit[BenefitProps.EBT_BALANCE]),
                    TextProps.VALUE_FALSE
                  )}
                </span>
                <br />
                {formatters.benefitTypeDisplay(
                  benefit[BenefitProps.TYPECODE]
                )}{" "}
                {info.ebtBalance}
              </p>
            </div>
            <div className="pure-u-1 pure-u-lg-2-3">
              <p className="margin-bottom-none benefit-summary__details">
                <b>
                  {this.convertToMultiLang(
                    _.capitalize(
                      formatters.convertStatusShortToFull(
                        benefit[BenefitProps.STATUS]
                      )
                    )
                  )}
                </b>
                <br />
                {_.lowerCase(
                  formatters.convertStatusShortToFull(
                    benefit[BenefitProps.STATUS]
                  )
                ) === BenefitProps.STATUS_TYPE_DENIED ||
                  _.lowerCase(
                    formatters.convertStatusShortToFull(
                      benefit[BenefitProps.STATUS]
                    )
                  ) === BenefitProps.STATUS_TYPE_CLOSED ? (
                  <span>{benefit[BenefitProps.STATUS_REASON]}</span>
                ) : _.lowerCase(
                  formatters.convertStatusShortToFull(
                    benefit[BenefitProps.STATUS]
                  )
                ) === BenefitProps.STATUS_TYPE_PENDING ? (
                  <span></span>
                ) : (
                  <span>
                    {benefit[BenefitProps.RE_CERT_DATE] ? (
                      <span>
                        {formatters.benefitTypeDisplay(
                          benefit[BenefitProps.TYPECODE]
                        ) === "SNAP"
                          ? info.recertificationDate
                          : info.revaluationDate}{" "}
                        {formatters.date(
                          formatters.convertStringDateToDate(
                            benefit[BenefitProps.RE_CERT_DATE]
                          ),
                          TextProps.VALUE_TRUE
                        )}
                        <br />
                        {info.next}{" "}
                        {formatters.benefitTypeDisplay(
                          benefit[BenefitProps.TYPECODE]
                        )}{" "}
                        {info.allotment}{" "}
                        {formatters.date(
                          formatters.convertStringDateToDate(
                            benefit[BenefitProps.NEXT_BENEFIT_DATE]
                          ),
                          TextProps.VALUE_TRUE
                        )}
                      </span>
                    ) : benefit[BenefitProps.RE_EVAL_DATE] ? (
                      <span>
                        <Icon name="notice" />
                        {formatters.benefitTypeDisplay(
                          benefit[BenefitProps.TYPECODE]
                        ) === "SNAP"
                          ? info.recertificationDate
                          : info.revaluationDate}{" "}
                        {formatters.date(
                          formatters.convertStringDateToDate(
                            benefit[BenefitProps.RE_EVAL_DATE]
                          ),
                          TextProps.VALUE_TRUE
                        )}
                        <br />
                        {info.next}{" "}
                        {formatters.benefitTypeDisplay(
                          benefit[BenefitProps.TYPECODE]
                        )}
                        {info.allotment}{" "}
                        {formatters.date(
                          formatters.convertStringDateToDate(
                            benefit[BenefitProps.NEXT_BENEFIT_DATE]
                          ),
                          TextProps.VALUE_TRUE
                        )}
                      </span>
                    ) : (
                      <span>
                        {info.next}{" "}
                        {formatters.benefitTypeDisplay(
                          benefit[BenefitProps.TYPECODE]
                        )}{" "}
                        {info.allotment}{" "}
                        {formatters.date(
                          formatters.convertStringDateToDate(
                            benefit[BenefitProps.NEXT_BENEFIT_DATE]
                          ),
                          TextProps.VALUE_TRUE
                        )}
                      </span>
                    )}
                  </span>
                )}
              </p>
            </div>
          </div>
        </li>
      );
    }
  };

  applySnapHandler = (e) => {
    e.preventDefault();
    sessionStorage.setItem("applicationType", TextProps.MATCH_SNAP);
    const obj = this.state.user;
    this.props.updateConsumerInfo(obj);
    this.props.setSectionBasedApplicationType(TextProps.MATCH_SNAP);
    this.props.gotoAppApply();
  };

  openUnlinkConfirmationModal = () => {
    this.setState({
      showConfirmationForUnlink: TextProps.VALUE_TRUE,
    });
  };

  closeUnlinkConfirmationtModal = () => {
    // Cancel any actions then...
    this.setState({ showConfirmationForUnlink: TextProps.VALUE_FALSE });
  };

  handleUnlinkAccount = () => {
    let consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );

    this.setState({ disableUnlinkButton: TextProps.VALUE_TRUE });

    const payload = {
      agencyID: consumerDataUserObj.clientInfo.agencyID || "",
      name: consumerDataUserObj.clientInfo.name || "",
      dateOfBirth: consumerDataUserObj.clientInfo.dateOfBirth || "",
      granteeFlag: consumerDataUserObj.clientInfo.granteeFlag || "",
      onlineAccessBlockFlag:
        consumerDataUserObj.clientInfo.onlineAccessBlockFlag || "",
    };
    console.log("final payload", payload);
    this.props.unlinkAccount(payload);
    this.closeUnlinkConfirmationtModal();
  };
  applyTanfHandler = (e) => {
    e.preventDefault();
    sessionStorage.setItem("applicationType", TextProps.MATCH_TANF);
    const obj = this.state.user;
    this.props.updateConsumerInfo(obj);
    this.props.setSectionBasedApplicationType(TextProps.MATCH_TANF);
    this.props.gotoAppApply();
  };

  applyComboHandler = (e) => {
    e.preventDefault();
    sessionStorage.setItem("applicationType", TextProps.MATCH_COMBO);
    const obj = this.state.user;
    this.props.updateConsumerInfo(obj);
    this.props.setSectionBasedApplicationType(TextProps.MATCH_COMBO);
    this.props.gotoAppApply();
  };
  HandleContinueButton(e, applicationType) {
    if (applicationType === TextProps.MATCH_SNAP) {
      this.applySnapHandler(e);
    } else if (applicationType === TextProps.MATCH_TANF) {
      this.applyTanfHandler(e);
    } else {
      this.applyComboHandler(e);
    }
  }

  // redirectRecert = () => {
  //   var consumerDataUserObj = JSON.parse(
  //     sessionStorage.getItem("loadConsumerUser")
  //   );
  //   var consumerNewUserObj = JSON.parse(
  //     sessionStorage.getItem("consumerAddrData")
  //   );
  //   if (
  //     this.props.consumer.consumerData.clientInfo !== undefined &&
  //     this.props.consumer.consumerData.clientInfo.agencyID
  //   ) {
  //     var agencyId = this.props.consumer.consumerData.clientInfo.agencyID;
  //   } else if (
  //     this.props.consumer.consumerData.clientInfo !== undefined &&
  //     this.props.consumer.consumerData.clientInfo.accountId
  //   ) {
  //     agencyId = this.props.consumer.consumerData.clientInfo.accountId;
  //   } else if (
  //     consumerDataUserObj !== null &&
  //     consumerDataUserObj.clientInfo !== undefined &&
  //     consumerDataUserObj.clientInfo.agencyID
  //   ) {
  //     agencyId = consumerDataUserObj.clientInfo.agencyID;
  //   } else if (consumerNewUserObj !== null) {
  //     agencyId = consumerNewUserObj.accountId;
  //   }
  //   var auditInfo = getAudit();
  //   this.setState(
  //     {
  //       isLoading: TextProps.VALUE_TRUE,
  //     },
  //     () => {
  //       this.props
  //         .startRecert(agencyId, "SNAP", auditInfo)
  //         .then((result) => {
  //           if (result) {
  //           } else {
  //             this.setState({ isLoading: TextProps.VALUE_FALSE });
  //           }
  //         })
  //         .catch((error) => {
  //           this.setState({ isLoading: TextProps.VALUE_FALSE });
  //         });
  //     }
  //   );
  // };

  setModule = (module) => {
    window.sessionStorage.setItem("module", module);
  };

  goToHouseHoldChangePage = (e) => {
    let houseHoldMembers =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers;
    this.props.setHouseholdMembersList(houseHoldMembers);
    this.setModule("household");
    this.props.gotoReportChange("/reportchange");
  };

  goToAuthRepPage = (e) => {
    this.setModule("authrep");
    this.props.gotoReportChange("/reportchange");
  };

 goToResourceChangePage = (e) => {
    this.setModule('resources-summary');
    this.props.gotoReportChange("/reportchange");
  }
  goToIncomeChangePage = (e) => {
    this.setModule("income");
    this.props.gotoReportChange("/reportchange");
  };

  goToShelterChangePage = (e) => {
    this.setModule("shelter");
    this.props.gotoReportChange("/reportchange");
  };

  goToDependentCarePage = () => {
    this.setModule("Dependentcarecosts");
    this.props.gotoReportChange("/reportchange");
  };

  goToChildSupportPage = () => {
    this.setModule("child-support");
    this.props.gotoReportChange("/reportchange");
  };

  goToMedicalExpensePage = () => {
    this.setModule("medical");
    this.props.gotoReportChange("/reportchange");
  };

 goToAttendancePage = () => {
    this.setModule("attendance");
    this.props.gotoReportChange("/reportchange");
  };
  onLogOutClick = () => {
    this.props.onLogOut({}, TextProps.VALUE_Y);
  };

  isTypeEqual = (type, currentState, oldState, moduleInViewMode) => {
    return type === "pregnancy" && !moduleInViewMode ? _.isEqual(currentState, oldState) :
      type === "disability" && !moduleInViewMode ? _.isEqual(currentState, oldState) :
        type === "attendance" && !moduleInViewMode ? _.isEqual(currentState, oldState) :
          false;
  };

  isAnyModuleInEditState = () => {
    return !this.state.pregnancyViewMode || !this.state.disabilityViewMode || !this.state.attendanceViewMode
  }
  
  enableEdit = (type) => {
    const currentModule = `${type}ViewMode`;
    if (this.isAnyModuleInEditState()) {
      return
    }
    this.setState({ [currentModule]: !this.state[currentModule] });
  };

  renderAuthRepUI = (data,headings) => {
    if(data && data.authorizedRepresentatives){
      const { authorizedRepresentatives,snapAuthRepFlg, ebtAuthRepFlg  } = data;
      let isAuthorizeCert = null;
      if (snapAuthRepFlg !== null && snapAuthRepFlg !== undefined) {
          if (snapAuthRepFlg === "Y") {
            isAuthorizeCert = "Yes";
          } else {
            isAuthorizeCert = "No";
          }
      }
      
      let isAuthorizeEBT = null;
      if (ebtAuthRepFlg !== null && ebtAuthRepFlg !== undefined) {
          if (ebtAuthRepFlg === "Y") {
            isAuthorizeEBT = "Yes";
          } else {
            isAuthorizeEBT = "No";
          }
      }
      
      const {
        ebtFrstNam = "",
        ebtLstNam = "",
        ebtPhNum = "",
        snpFrstNam = "",
        snpLstNam = "",
        snpPhNum = ""
      } = authorizedRepresentatives;

      const ebtName = (ebtFrstNam || ebtLstNam) ? `${ebtFrstNam} ${ebtLstNam}` : "-";
      const snpName = (snpFrstNam || snpLstNam) ? `${snpFrstNam} ${snpLstNam}` : "-";

      return (
        <div>
          <div className="pure-g">
            <div class="pure-u-1-2">
              <p style={{width:"70%"}}>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                  ).authQuestionOptions
                }
              </p>
            </div>
            <div class="pure-u-1-3" ><p>{isAuthorizeEBT}</p></div>
          </div>
  
          <div className="pure-g">
            <div className="pure-u-1-2"><p>{headings.name}</p></div>
            <div className="pure-u-1-2"><p>{ebtName?ebtName:"-"}</p></div>
          </div>
  
          <div className="pure-g">
            <div className="pure-u-1-2"><p>{headings.phone}</p></div>
            <div className="pure-u-1-2"><p>{ebtPhNum?ebtPhNum:"-"}</p></div>
          </div>
  
          <div className="pure-g">
            <div className="pure-u-1-2">
            <p style={{width:"70%"}}>
              {recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
              ).authQuestiontwoQues}
            </p></div>
            <div className="pure-u-1-2" ><p>{isAuthorizeCert}</p></div>
          </div>
  
          <div className="pure-g">
            <div className="pure-u-1-2"><p>{headings.name}</p></div>
            <div className="pure-u-1-2" ><p>{snpName}</p></div>
          </div>
  
          <div className="pure-g">
            <div className="pure-u-1-2"><p>{headings.phone}</p></div>
            <div className="pure-u-1-2"><p>{snpPhNum?snpPhNum:"-"}</p></div>
          </div>
        </div>
      )
    }else{
      return <div><p>{headings.noneText}</p><br/></div>
    } 
    
  }


  isFutureDate = (dateStr) => {
    var inputDate = new Date(dateStr);
    var currentDate = new Date();
    var diffInYears = inputDate.getFullYear() - currentDate.getFullYear();
    if (diffInYears > 1) {
      return false;
    }
    return inputDate >= currentDate;
  }

  validateDates = (datesObject, fullData) => {
    const pregnantError = {};
    let error = false;
    for (const key in datesObject) {
      fullData.forEach((data) => {
        const { dataChangeCode } = data;
        if (data.agencyID == key && dataChangeCode !== "NOCHNG") {
          const date = datesObject[key];
          // if (!date || date === "" || !this.isFutureDate(date)) {
          if (!date || date === "") {
            pregnantError[key] = true;
            error = true;
          }
        }
      })
    }
    return [error, pregnantError];
  }

  validateReasons = (reasonObject, fullData) => {
    const disabilityError = {};
    let error = false;
    for (const key in reasonObject) {
      fullData.forEach((data) => {
        const { dataChangeCode } = data;
        if (data.agencyID == key && dataChangeCode !== "NOCHNG") {
          const reason = reasonObject[key];
          if (!reason || reason === "") {
            disabilityError[key] = true;
            error = true;
          }
        }
      })
    }
    return [error, disabilityError];
  }


validateDisabilityReasons=(reasonObject) =>{
  const disabilityError = {};
  for (const key in reasonObject) {
      if (!reasonObject[key] || reasonObject[key] === "") {
          disabilityError[key] = true;
      }
  }
  return disabilityError;
}

 comparePregnancyData = (oldData, newData) =>{
  let comparedData = [];
  newData.forEach(newItem => {
      let oldItem = oldData.find(item => item.agencyID === newItem.agencyID);
      if (oldItem) {
          if (newItem.dueDate !== oldItem.dueDate) {
              newItem.dataChangeCode = "CHANGE";
          } else {
              newItem.dataChangeCode = "NOCHNG";
          }
          comparedData.push(newItem);
      } else {
          newItem.dataChangeCode = "NEW";
          comparedData.push(newItem);
      }
  });
  return comparedData;
}

  compareDisabilityData = (oldData, newData) => {
    let comparedData = [];
    newData.forEach(newItem => {
      delete newItem['dataType']
      let oldItem = oldData.find(item => item.agencyID === newItem.agencyID);
      if (oldItem) {
        if (newItem.disabilityType !== oldItem.disabilityType || newItem.disabilityReason !== oldItem.disabilityReason) {
          newItem.dataChangeCode = "CHANGE";
        } else {
          newItem.dataChangeCode = "NOCHNG";
        }
        comparedData.push(newItem);
      } else {
        newItem.dataChangeCode = "NEW";
        comparedData.push(newItem);
      }
    });
    return comparedData;
  }


  submitData = (type, info) => {
    let requestType = "";
    window.sessionStorage.setItem("module", type);
    const userData = JSON.parse(window.sessionStorage.getItem("userInfo"));
    let oldData = this.props.consumer.consumerData[type];
    let newData = [];

    let isError = false;
    let isDisabilityError = false;
    let errors = null; 
    let disabilityErrors = null; 

      switch (type) {
        case "pregnancy":
          // if (this.state[type].isPregnant === info.YesNoOptions[0]) {
          requestType = "PREGNC";
            let newPregnancyData = [];
        if (Object.keys(this.state[type].dueDates).length !== 0 && this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.householdMembers) {
          for (const [agencyID, dueDate] of Object.entries(this.state[type].dueDates)) {
                    let due = formatters.formatDateNew(dueDate);
            for (const household of this.props.consumer.consumerData.householdMembers) {
              if (household.agencyID === parseInt(agencyID)) {
                const { granteeFlag, disabilityFlag, genderCode, ...householdWithoutGranteeFlag } = household;
                const householdWithDueDate = { ...householdWithoutGranteeFlag, dueDate: due, pregFlag: true };
                            newPregnancyData.push(householdWithDueDate);
                break;
              }
            }
          }
        }

            newData = this.comparePregnancyData(this.props.consumer.consumerData.pregnancy, newPregnancyData);
        if (!newData.some(data => data.dataChangeCode === "NEW" || data.dataChangeCode === "CHANGE")) {
          this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE, type);
            return false;
          }

            [isError, errors] = this.validateDates(this.state[type].dueDates, newData); // Assign errors
        this.setState({
          [type]: {
            ...this.state[type],
            pregnancyErrors: errors
          }
            });
          // }else{
          //   return false;
          // }
          break;
        case "disability":
            // if (this.state[type].isParticipate === info.YesNoOptions[0]) {
          requestType = "DISINC";
        let newDisabilityData = [];
        if ((Object.keys(this.state[type].disabilityReasons).length !== 0 || Object.keys(this.state[type].disabilityTypes).length !== 0) && this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.householdMembers) {
          const agencyIDs = new Set([
            ...Object.keys(this.state[type].disabilityReasons),
            ...Object.keys(this.state[type].disabilityTypes)
          ]);
          for (const agencyID of agencyIDs) {
            const disabilityReason = this.state[type].disabilityReasons[agencyID];
            const disabilityTypes = this.state[type].disabilityTypes[agencyID];
            for (const household of this.props.consumer.consumerData.householdMembers) {
              if (household.agencyID === parseInt(agencyID)) {
                const { granteeFlag, genderCode, disabilityFlag, ...householdWithoutGranteeFlag } = household;
                const householdWithDisability = { ...householdWithoutGranteeFlag, disabilityFlag };
                if (disabilityReason) {
                  householdWithDisability.disabilityReason = disabilityReason;
                }
                if (disabilityTypes) {
                  householdWithDisability.disabilityType = disabilityTypes;
          }
                newDisabilityData.push(householdWithDisability);
          break;
              }
            }
          }
        }
        newData = this.compareDisabilityData(this.props.consumer.consumerData.disability, newDisabilityData);
        if (!newData.some(data => data.dataChangeCode === "NEW" || data.dataChangeCode === "CHANGE")) {
          this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE, type);
          return false;
      }
                [isDisabilityError, disabilityErrors] = this.validateReasons(this.state[type].disabilityTypes, newData); // Assign disabilityErrors
        this.setState({
          [type]: {
            ...this.state[type],
            disabilityTypesError: disabilityErrors
    }
                });
            // }else{
            //   return false;
            // }
        break;
      default:
    }

    if (isDisabilityError || isError) {
        return false;
    }

    if(requestType === "PREGNC"){
      const prevPregMap = new Map();
      oldData.forEach(pregData => {
        prevPregMap.set(pregData.agencyID, pregData);
      });

      newData.forEach(pregData => {
        if (pregData.dataChangeCode === 'CHANGE') {
        const prevPregData = prevPregMap.get(pregData.agencyID);
        if (prevPregData) {
          pregData.previousDueDate = prevPregData.dueDate;
          pregData.setID = prevPregData.setID;
        }
      }
    });
    }else if(requestType === "DISINC"){
      const prevDisMap = new Map();
      oldData.forEach(disData => {
        prevDisMap.set(disData.agencyID, disData);
      });

      newData.forEach(disData => {
        if (disData.dataChangeCode === 'CHANGE') {
        const prevDisData = prevDisMap.get(disData.agencyID);
        if (prevDisData) {
          disData.previousDisabilityReason = prevDisData.disabilityReason;
          disData.previousDisabilityType = prevDisData.disabilityType;
          disData.setID = prevDisData.setID;
        }
      }
    });
    }

    let payload = {
      "prevTxt": JSON.stringify({ [type]: oldData }),
      "chngTxt": JSON.stringify({ [type]: newData }),
      "orgLocIdK": "", // this value is taken from the backend side
      "chngReqTypCdCodeK": requestType,
      "chngReqStatCdCodeK": "REQSTD",
      "clientIdK": userData.agencyID || ''
    };

    // console.log("++post data", payload);
     this.props.postData(payload)

  }

  getResourceType = (type) => {
    let resourceType;
    switch(type){
      case "CASH": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[0];
        break;
      case "SAVING": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[1];
        break;
      case "CHECK":  
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[2];
        break;
      case "STOCKS": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[3];
        break;
      case "BONDS": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[4];
        break;
      case "RETMNT":
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[5];
        break;
      case "TRUST": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[6];
        break;
      case "PPDFUN": 
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[7];
        break;
      case "OTHER":  
        resourceType = resourcesSectionMultilanguageLabels(language).combineResourcesoptions[8];
        break;
      default:
        resourceType = "";
        break;  
    }
    return resourceType;
  }
  

  render() {
    const { info, home } = this.props.language.consumer;
    let consumerAboutMeData = null;
    const { benefitInfo } = this.props.consumer.centrifyData;
    const { search } = this.props.language.provider;
    const isLoading = this.props.consumer.isLoading;
    const isUnlinkAccountSuccess =
      this.props.consumer.unlinkAccountSuccessState &&
      this.props.consumer.unlinkAccountSuccessState.code === "200";
    console.log("isUnlinkAccountSuccess", this.props);
    let pendingFlags =
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.updatePendingFlag;
    let consumerDataUserObject = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let snapMembers =
      this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers;
    let houseHoldMembers =
      snapMembers &&
      snapMembers.length &&
      snapMembers.filter((mem) => !mem.granteeFlag);

    let clientDetails = this.props.consumer.consumerData.consumerAboutMeInfo;
    const consumerBenefitInfo =
      benefitInfo ||
      (consumerDataUserObject !== null
        ? consumerDataUserObject.benefitInfo
        : null);
    if (
      consumerDataUserObject !== null &&
      consumerDataUserObject.accountId > 0
    ) {
      consumerAboutMeData = consumerDataUserObject;
    } else if (
      consumerDataUserObject !== null &&
      consumerDataUserObject.webAppId > 0
    ) {
      consumerAboutMeData = consumerDataUserObject;
    }
    if (clientDetails) {
      consumerAboutMeData = clientDetails;
    }
    else {
      consumerAboutMeData = JSON.parse(
        sessionStorage.getItem("consumerAboutMeInfoTemplate")
      );
    }
    let isNewUser = sessionStorage.getItem("isNewConsumer");
    const msalInstance = this.props.msalContext.instance;

    let getSelectedConsumer = sessionStorage.getItem("selectedConsumer");
    let isConsumerInfoPage = sessionStorage.getItem("isConsumerInfoPage");
    const inprogressAppsDetails = JSON.parse(sessionStorage.getItem("inprogressAppsDetails"));

    let isUnlinkFlag = false;
    let fetchSelectedConsumer = "";
    let isConsumerNewApplication = true;
    let applicationType = "";
    let inProgAppId = "";
    if (getSelectedConsumer) {
      fetchSelectedConsumer =
        this.state.onSearchConsumer &&
        this.state.onSearchConsumer.multiUserDetails &&
        this.state.onSearchConsumer.multiUserDetails.filter(
          (a) => a.clientId == getSelectedConsumer
        );

      if (fetchSelectedConsumer.length && inprogressAppsDetails && inprogressAppsDetails.length) {
        isUnlinkFlag =
          fetchSelectedConsumer[0].unLinkFlg &&
          !fetchSelectedConsumer[0].unLinkedByProviderFlg;
        isConsumerNewApplication = Object.keys(inprogressAppsDetails[0]).length == 0 ? true : false; //fetchSelectedConsumer[0].inProgAppId === 0;
        applicationType = inprogressAppsDetails[0].appType; //fetchSelectedConsumer[0].inProgAppType;
        applicationType =
          applicationType === "COMBO" ? "SNAP/TANF" : applicationType;
        inProgAppId = inprogressAppsDetails[0].appId; // fetchSelectedConsumer[0].inProgAppId;
      }
      isConsumerInfoPage = fetchSelectedConsumer.length && fetchSelectedConsumer[0].accountId == 0 && fetchSelectedConsumer[0].clientId == 0 ? true : false; //inProgAppId == 0 || inProgAppId ? true : false;
    }
    let trackAppDataList = this.props.consumer;
    let trackConsumer = trackAppDataList ? trackAppDataList.trackAppData : "";
    return isLoading ? (
      <Loading />
    ) : (
      <div className="max-width-myinfo max-width--three-quarters pad-all--double">
        {isConsumerInfoPage ? (
          <div>
            {isConsumerNewApplication ? (
              <div>
                <h1>{info.welcomeNew}</h1>
                <p>{info.welcomeNewContent}</p>
                <button
                  onClick={this.applySnapHandler}
                  className="dta-button dta-button--primary btn-margin"
                >
                  {info.snapApply}
                </button>{" "}
                &#160; &#160;
                <button
                  onClick={this.applyTanfHandler}
                  className="dta-button dta-button--primary btn-margin"
                >
                  {info.tanfApply}
                </button>{" "}
                &#160; &#160;
                <button
                  onClick={this.applyComboHandler}
                  className="dta-button dta-button--primary btn-margin"
                >
                  {info.snapTanfApply}
                </button>{" "}
                &#160; &#160;
                <p>{info.applyNowHelp}</p>
                {trackConsumer !== undefined && trackConsumer.length > 0 && (
                <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={this.openTrackApplicationModal}
            >
              {home.trackApplicationText}
            </button>
                )}
              </div>
            ) : (
              <div>
                <h1>{info.welcomeBack}!</h1>
                <p>
                  {info.welcomeBackContentBefore}
                  {inProgAppId}
                  {info.welcomeBackContentAfter}
                </p>
                <button
                  onClick={(event) =>
                    this.HandleContinueButton(
                      event,
                      //this.state.application.programCode
                      applicationType
                    )
                  }
                  className="dta-button dta-button--primary"
                >{`${info.continueApply} ${applicationType}`}</button>{" "}
                &#160; &#160;
                <p>{info.applyAfterHelpContinue1}{this.props.apply.sections[0].pendingHours.split(":")[0]}{info.applyAfterHelpContinue2}</p>
              </div>
            )}
            {trackConsumer !== undefined && trackConsumer.length > 0 && (
            <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={this.openTrackApplicationModal}
            >
              {home.trackApplicationText}
            </button>
            )}
          </div>
        ) : null}

        {isNewUser === TextProps.VALUE_STR_TRUE ? (
          <div>
            {this.state.application &&
              this.state.application.status === DRAFT &&
              !isConsumerInfoPage ? (
              <div>
                <h1>{info.welcomeBack}!</h1>
                <p>
                  {info.welcomeBackContentBefore}
                  {this.state.application.programCode === "COMBO" && this.props.apply.sections[0].appType === "COMBO" ?   
                   `SNAP ${this.props.apply.sections[0].snapId} & TANF ${this.props.apply.sections[0].tanfId}` : 
                    this.state.application.programCode + " " + this.state.application.appId
                  }
                  {info.welcomeBackContentAfter}
                </p>
                <button
                  onClick={(event) =>
                    this.HandleContinueButton(
                      event,
                      this.state.application.programCode
                    )
                  }
                  className="dta-button dta-button--primary"
                >{`${info.continueApply} ${this.state.application.programCode === "COMBO"
                    ? "SNAP/TANF"
                    : this.state.application.programCode
                  }`}</button>{" "}
                &#160; &#160;
                <p>{info.applyAfterHelpContinue1}{this.props.apply.sections[0].pendingHours.split(":")[0]}{info.applyAfterHelpContinue2}</p>
                {trackConsumer !== undefined && trackConsumer.length > 0 && (
                <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={this.openTrackApplicationModal}
            >
              {home.trackApplicationText}
            </button>
                )}
              </div>
            ) : (
              !isConsumerInfoPage && (
                <div>
                  <h1>{info.welcomeNew}</h1>
                  <p>{info.welcomeNewContent}</p>
                  <button
                    onClick={this.applySnapHandler}
                    className="dta-button dta-button--primary btn-margin"
                  >
                    {info.snapApply}
                  </button>{" "}
                  &#160; &#160;
                  <button
                    onClick={this.applyTanfHandler}
                    className="dta-button dta-button--primary btn-margin"
                  >
                    {info.tanfApply}
                  </button>{" "}
                  &#160; &#160;
                  <button
                    onClick={this.applyComboHandler}
                    className="dta-button dta-button--primary btn-margin"
                  >
                    {info.snapTanfApply}
                  </button>{" "}
                  &#160; &#160;
                  <p>{info.applyNowHelp}</p>
                  {trackConsumer !== undefined && trackConsumer.length > 0 && (
                  <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={() => {this.openTrackApplicationModal(); this.setState({isTrackApplication : TextProps.VALUE_TRUE})}}
            >
              {home.trackApplicationText}
            </button>
                  )}
                </div>
              )
            )}
          </div>
        ) : this.state.user.clientInfo && !isConsumerInfoPage ? (
          <div>
            <div style={{display: "flex", alignItems:"center"}}>
              <h1 className="dta-header-unlik">
                {info.hi}, {this.state.user.clientInfo.name[SharedProps.FIRST]}
              </h1>
              {(trackConsumer !== undefined && trackConsumer.length > 0) && (
                  <button
              className="dta-button dta-button--primary margin-bottom"
              style={{height: "46px"}}
              onClick={this.openTrackApplicationModal}
            >
              {home.trackRecertApplicationText}
            </button>
                  )}
              {userInfo &&
                userInfo.roleList &&
                userInfo.roleList[0].roleName ===
                roleProps.PROVIDER_ROLE_SPVUSR && (
                  <button
                    onClick={this.openUnlinkConfirmationModal}
                    className="dta-button dta-button--primary btn-margin dta-button-unlik"
                    disabled={!isUnlinkFlag || isUnlinkAccountSuccess}
                  >
                    Unlink Account
                  </button>
                )}
              <DTAModal
                isOpen={this.state.showConfirmationForUnlink}
                onClose={this.closeUnlinkConfirmationtModal}
                modalClass="save-exit-modal"
                titleText="Confirmation"
                headerText="Confirmation"
              >
                <div className="dta-modal__body pad-all--double">
                  <p className="text--small text--center">
                    Do you want to proceed?
                  </p>
                  <p> </p>
                  <div className="dta-modal__footer--inline-buttons pure-u-1">
                    <button
                      className="dta-button dta-button--outline-primary"
                      onClick={this.closeUnlinkConfirmationtModal}
                    >
                      {info.cancel}
                    </button>
                    <button
                      className="dta-button dta-button--primary"
                      onClick={() => this.handleUnlinkAccount()}
                    >
                      {info.ok}
                    </button>
                  </div>
                </div>
              </DTAModal>
            </div>
            <ul className="list--unstyled">
              {this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW] &&
                consumerBenefitInfo &&
                consumerBenefitInfo.length > 0
                ? consumerBenefitInfo.map((benefit) => {
                  return this.renderBenefitSummary(benefit);
                })
                : null}
              {consumerAboutMeData &&
                consumerAboutMeData.webAppId &&
                consumerBenefitInfo.length <= 0
                ? this.renderStaticSummary(consumerAboutMeData.appReceivedDate)
                : null}
            </ul>
            <p className="consumer__heading text--gray">{info.aboutMe}</p>
            <ul className="list--section-card" style={{ position: "relative" }}>
              <li className="myinfo">
              <div className="pure-g" >
                <div>
                <p className="consumer__heading">{info.houseHoldDetails}</p>
                </div>
                {
                    pendingFlags &&
                    pendingFlags.householdMembersUpdatePendingFlag === "N"
                  &&
                  <div className="edit-button-position">
                    <p className="badge" >{info.changeRequest}</p>
                  </div>
                  }
                </div>
                <button
                  onClick={(e) => !this.isAnyModuleInEditState() && this.goToHouseHoldChangePage(e)}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                <div className="pure-g margin-bottom">
                  <div className="pure-u-1 pure-u-lg-1-2">
                    <span className="text--gray">{info.headOfHouseHold}</span>
                  </div>
                  <div className="pure-u-1 pure-u-lg-1-2">
                    {formatters.fullNameProvider(
                      this.state.user.clientInfo.name
                    )}
                  </div>
                </div>
                <div className="pure-g margin-bottom">
                  <div className="pure-u-1 pure-u-lg-1-2">
                    <span className="text--gray">
                      {this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW]
                        ? info.agencyId
                        : info.webAppId}
                    </span>
                  </div>
                  <div className="pure-u-1 pure-u-lg-1-2">
                    {this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW]
                      ? this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW]
                      : this.state.user.clientInfo[SharedProps.WEBAPPID_NEW]}
                  </div>
                </div>
                <div className="pure-g margin-bottom">
                  <div className="pure-u-1 pure-u-lg-1-2">
                    <span className="text--gray">{info.additionalMembers}</span>
                  </div>
                  <div className="pure-u-1 pure-u-lg-1-2">
                    {houseHoldMembers &&
                      houseHoldMembers.length > 0 &&
                      houseHoldMembers.map((member) => {
                        return (
                          <React.Fragment>
                            <span
                              style={{
                                paddingBottom: "10px",
                                display: "inline-block",
                              }}
                            >
                              {member.firstName + " " + member.lastName}
                            </span>{" "}
                            <span
                              style={{
                                paddingBottom: "10px",
                                display: "inline-block",
                              }}
                            >
                              {formatters.formatDate(member.dateOfBirth)}
                            </span>
                            <br />{" "}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </li>
              <p className="consumer__heading">{info.contactDetails}</p>
              {console.log('consumerAboutMeData', consumerAboutMeData)}
              {consumerAboutMeData &&
                Object.keys(consumerAboutMeData).length > 0
                ? consumerAboutMeData.map((aboutMeItem) => {
                  return (
                    <DTAEditableFields
                      key={aboutMeItem[SharedProps.ID]}
                      editingLabel={aboutMeItem[SharedProps.EDITING_LABEL]}
                      columnClass="pure-u-1 pure-u-lg-1-2"
                      agencyId={
                        this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW]
                          ? this.state.user.clientInfo[
                          SharedProps.AGENCY_ID_NEW
                          ]
                          : 0
                      }
                      columnLabel={TextProps.VALUE_TRUE}
                      fields={aboutMeItem[SharedProps.FIELDS]}
                      onChange={this._handleChangeForQuestion}
                      onEditStateChange={this._handleChangeForQuestion}
                      controlLabel={TextProps.VALUE_FALSE}
                      pendingFlags={pendingFlags}
                      disableAddressEdit={this.props.consumer.disableAddressEdit}
                    />
                  );
                })
                : consumerAboutMeData &&
                consumerAboutMeData.consumerAboutMeInfo &&
                consumerAboutMeData.consumerAboutMeInfo.map((aboutMeItem) => {
                  return (
                    <DTAEditableFields
                      key={aboutMeItem[SharedProps.ID]}
                      editingLabel={aboutMeItem[SharedProps.EDITING_LABEL]}
                      columnClass="pure-u-1 pure-u-lg-1-2"
                      agencyId={
                        this.state.user.clientInfo[SharedProps.AGENCY_ID_NEW]
                          ? this.state.user.clientInfo[
                          SharedProps.AGENCY_ID_NEW
                          ]
                          : 0
                      }
                      columnLabel={TextProps.VALUE_TRUE}
                      fields={aboutMeItem[SharedProps.FIELDS]}
                      onChange={this._handleChangeForQuestion}
                      onEditStateChange={this._handleChangeForQuestion}
                      controlLabel={TextProps.VALUE_FALSE}
                      border={"none"}
                    />
                  );
                })}
              <hr
                style={{
                  height: ".5px",
                  backgroundColor: "#ccc",
                  border: "none",
                }}
              />
                  <li className="myinfo">
                    <div className="pure-g" >
                    <div>
                    <p className="consumer__heading">{info.authRepHeading}</p>
                    </div>
                      {
                        pendingFlags &&
                        pendingFlags.authRepUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>
                    <button
                      onClick={(e) => !this.isAnyModuleInEditState() && this.goToAuthRepPage(e)}
                      className={"dta-button dta-button--outline-secondary edit-button-position"}
                    >
                      {info.edit}
                    </button>
                          {this.renderAuthRepUI(this.props.consumer.consumerData,info)}
                  </li>
                                <li className="myinfo">
                <div className="pure-g" >
                <div>
                <p className="consumer__heading">{info.resource}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.resourceUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>
                <button
                  onClick={(e) => !this.isAnyModuleInEditState() && this.goToResourceChangePage(e)}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                {this.prepareResourcesSection().length > 0 ? this.prepareResourcesSection().map((member, index) => {
                return (
                <div className="pure-g" key={index}>
                    <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                        <span className="text--gray">
                            {info.resourceOf + " " + member.name}
                        </span>{" "}
                        <br />
                    </div>
                    <div className="pure-u-1 pure-u-lg-1-1">
                        {member.resourcesArray.map((resource, resIndex) => {
                            return (
                                <div
                                    className="pure-g"
                                    style={{ marginBottom: "5px" }}
                                    key={resIndex}
                                >
                                    <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                                        <span className="text--gray">{this.getResourceType(resource.resourceType)}</span>
                                        <br />
                                    </div>

                                    <div className="pure-u-1 pure-u-lg-1-1">
                                        {resource.resourceDetails && resource.resourceDetails.map((resDetails, detailIndex) => {
                                        return (
                                          <div key={detailIndex} className="pure-g" style={{ marginBottom: "5px" }}>
                                            <div
                                                  className="pure-u-1 pure-u-lg-1-2"
                                                  style={{ marginBottom: "5px" }}
                                                >
                                                <React.Fragment>
                                                  <span>
                                                    {beforeYoustartMlanguageLabels(language).amountLabel}
                                                  </span>
                                                </React.Fragment>{" "}
                                                <br />
                                            </div>
                                            <div
                                              className="pure-u-1 pure-u-lg-1-2 margin-bottom"
                                              style={{ marginBottom: "5px" }}
                                            >
                                              <React.Fragment>
                                                <span>
                                                ${resDetails.amount}
                                                </span>
                                              </React.Fragment>{" "}
                                              <br />
                                            </div>
                                            {resDetails.bankBrokerageName && (
                                              <React.Fragment>
                                              <div
                                                className="pure-u-1 pure-u-lg-1-2"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <React.Fragment>
                                                  <span>
                                                    {beforeYoustartMlanguageLabels(language).bankNameLabel}
                                                  </span>
                                                </React.Fragment>{" "}
                                              <br />
                                              </div> 
                                              <div
                                              className="pure-u-1 pure-u-lg-1-2"
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <React.Fragment>
                                                  <span>
                                                    {resDetails.bankBrokerageName}
                                                  </span>
                                                </React.Fragment>{" "}
                                              <br />
                                              </div> 
                                              </React.Fragment>
                                            )}
                                          </div>
                                        );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                );
                })
                 : (
                  <div style={{ marginBottom: "2rem" }}>
                    <p>{info.noneText}</p>
                  </div>
                )}
              </li>
              <li className="myinfo">
                <div className="pure-g" >
                <div>
                <p className="consumer__heading">{info.income}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.incomeUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>
                <button
                  onClick={(e) => !this.isAnyModuleInEditState() && this.goToIncomeChangePage(e)}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                   {info.edit}
                </button>
                {this.prepareIncomeSection().length > 0 ? (
                  this.prepareIncomeSection().map((member) => {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                          <React.Fragment>
                            <span className="text--gray">
                              {info.incomeOf + " " + member.name}
                            </span>{" "}
                            <br />
                          </React.Fragment>
                        </div>
                        <div className="pure-u-1 pure-u-lg-1-1">
                          {member.incomeArray.map((income) => {
                            return (
                              <div
                                className="pure-g"
                                style={{ marginBottom: "5px" }}
                              >
                                <div
                                  className="pure-u-1 pure-u-lg-1-2"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {getIncomeCodes(income.typeCode)}
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                                <div
                                  className="pure-u-1 pure-u-lg-1-2 margin-bottom"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {"$ " + income.amounts[0].amount}{" "}
                                      {
                                        getSummaryFrequencyLabel(language)[
                                        income.frequencyCode
                                        ]
                                      }
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ marginBottom: "2rem" }}>
                    <p>{info.noneText}</p>
                  </div>
                )}
              </li>

              <li className="myinfo">
                <div className="pure-g" >
                <div>
                <p className="consumer__heading">{info.shelterUtilityExp}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.shelterAndUtilityUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>    
                <button
                  onClick={(e) => !this.isAnyModuleInEditState() && this.goToShelterChangePage(e)}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                <p className="consumer__heading" style={{ fontSize: "16px" }}>
                  {info.shelterExp}
                </p>
                {this.loadShelterData()}
                <p className="consumer__heading" style={{ fontSize: "16px" }}>
                  {info.utilityExp}
                </p>
                {this.loadUtilityData()}
              </li>
              <li className="myInfo">
                <div className="pure-g" >
                <div>
                <p className="consumer__heading">{info.dependentCare}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.dpcareUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                </div>
                     
                <button
                  onClick={() => !this.isAnyModuleInEditState() && this.goToDependentCarePage()}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                {this.prepareDependantCareSection().length > 0 ? (
                  this.prepareDependantCareSection().map((member) => {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                          <React.Fragment>
                            <span className="text--gray">{member.name}'s</span>{" "}
                            <br />
                          </React.Fragment>
                        </div>
                        <div className="pure-u-1 pure-u-lg-1-1">
                          {member.expenses.map((income) => {
                            return (
                              <div
                                className="pure-g"
                                style={{ marginBottom: "5px" }}
                              >
                                <div
                                  className="pure-u-1 pure-u-lg-1-2"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {info.depCareLabel}
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                                <div
                                  className="pure-u-1 pure-u-lg-1-2 margin-bottom"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {"$ " + income.amount}{" "}
                                      {
                                        getSummaryFrequencyLabel(language)[
                                        income.frequency
                                        ]
                                      }
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ marginBottom: "2rem" }}>
                    <p>{info.noneText}</p>
                  </div>
                )}
              </li>
              <hr
                style={{
                  height: ".5px",
                  backgroundColor: "#ccc",
                  border: "none",
                }}
              />
              <li className="myInfo">
              <div className="pure-g" >
              <div>
                <p className="consumer__heading">{info.childSupportExp}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.childSupportExpencesUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>     
                <button
                  onClick={() => !this.isAnyModuleInEditState() && this.goToChildSupportPage()}
                  className={ "dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                {this.prepareChildSupportSection().length > 0 ? (
                  this.prepareChildSupportSection().map((member) => {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                          <React.Fragment>
                            <span className="text--gray">{member.name}'s</span>{" "}
                            <br />
                          </React.Fragment>
                        </div>
                        <div className="pure-u-1 pure-u-lg-1-1">
                          {member.expenses.map((income) => {
                            return (
                              <div
                                className="pure-g"
                                style={{ marginBottom: "5px" }}
                              >
                                <div
                                  className="pure-u-1 pure-u-lg-1-2"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {getIncomeCodes(income.typeCode)}
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                                <div
                                  className="pure-u-1 pure-u-lg-1-2 margin-bottom"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {"$ " + income.amount}{" "}
                                      {
                                        getSummaryFrequencyLabel(language)[
                                        income.frequencyCode
                                        ]
                                      }
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ marginBottom: "2rem" }}>
                    <p>{info.noneText}</p>
                  </div>
                )}
              </li>
              <hr
                style={{
                  height: ".5px",
                  backgroundColor: "#ccc",
                  border: "none",
                }}
              />
              <li className="myInfo">
              <div className="pure-g" >
              <div>
                <p className="consumer__heading">{info.MedicalExp}</p>
                </div>
                      {
                        pendingFlags &&
                        pendingFlags.medicalExpencesUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                </div>
                <button
                  onClick={() => !this.isAnyModuleInEditState() && this.goToMedicalExpensePage()}
                  className={"dta-button dta-button--outline-secondary edit-button-position"}
                >
                  {info.edit}
                </button>
                {this.prepareMedicalExpenseSection().length > 0 ? (
                  this.prepareMedicalExpenseSection().map((member) => {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 pure-u-lg-1-1 margin-bottom">
                          <React.Fragment>
                            <span className="text--gray">{member.name}'s</span>{" "}
                            <br />
                          </React.Fragment>
                        </div>
                        <div className="pure-u-1 pure-u-lg-1-1">
                          {member.medicalExpenses.map((exp) => {
                            return (
                              <div
                                className="pure-g"
                                style={{ marginBottom: "5px" }}
                              >
                                <div
                                  className="pure-u-1 pure-u-lg-1-2"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>{getIncomeCodes(exp.typeCode)}</span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                                <div
                                  className="pure-u-1 pure-u-lg-1-2 margin-bottom"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <React.Fragment>
                                    <span>
                                      {"$ " + exp.amount}{" "}
                                      {
                                        getSummaryFrequencyLabel(language)[
                                        exp.unit
                                        ]
                                      }
                                    </span>
                                  </React.Fragment>{" "}
                                  <br />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ marginBottom: "2rem" }}>
                    <p>{info.noneText}</p>
                  </div>
                )}
                <hr className="info-separator" />
              </li>

                  <li className="myInfo">
                        <div className="pure-g" >
                        <div>
                        <p className="consumer__heading">{info.pregnancyHeading}</p>
                        </div>
                      {
                        pendingFlags &&
                        pendingFlags.pregnancyUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                </div>
                      <div>
                        {this.state.pregnancyViewMode ?
                          <button
                            className={
                            "dta-button dta-button--outline-secondary edit-button-position"
                            }
                            onClick={() => { this.enableEdit('pregnancy') }}
                          >{info.edit} </button> :
                          <button
                            className={
                              "dta-button dta-button--outline-secondary edit-button-position"
                            }
                            onClick={() => { this.submitData('pregnancy', info) }}
                          >{info.done}</button>
                        }
                      </div>
                    {this.state.pregnancyViewMode ?
                      this.renderPregnancyUI(info,"VIEW") :
                      <div className={this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.pregnancy && this.props.consumer.consumerData.pregnancy.length > 0 ? "pregnancy-disablity-section" : "pregnancy-disablity-section-nodata"}>
                    {this.renderPregnancyUI(info, "EDIT", "OLD")}
                    <div className={"pure-g"} style={{marginBottom:"10px"}}>
                        <div className="pure-u-1-2">
                            <span>{info.isPregnant}</span>
                          </div>
                          <div className="pure-u-1-2">
                            <Radio
                              id="isPregnant"
                              name="isPregnant"
                              className="dta-form__option-list"
                              optionClass="dta-form__option dta-form__option--width-1-of-2"
                              options={info.YesNoOptions}
                              required={TextProps.VALUE_TRUE}
                              value={this.state.pregnancy.isPregnant}
                              onChange={(value) => this.onEditableFieldsChange('pregnancy', "isPregnant", value,info)}
                            />
                          </div>
                          </div>
                    {this.renderPregnancyUI(info, "EDIT", "NEW")}
                        <br />
                        <div className="pure-g">
                      <div className="pure-u-1-2"></div>
                          <div className="pure-u-1-2">
                        {this.state.pregnancy.isPregnant === info.YesNoOptions[0] ?
                          <div style={{marginBottom:"10px"}}>
                          <button
                            className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left`}
                            onClick={this.toggleAddPregnancyMembers}
                            aria-expanded={this.state.addPregnancyOpen}
                          >
                            <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
                            {
                              info.addAditionalMember
                            }
                          </button>
                          <Collapse isOpened={this.state.addPregnancyOpen}>
                            <span className="add-income-summary__label" />
                            <ul className="list--unstyled add-income-summary__list">
                              {this.state.nonPregnancyMembers && this.state.nonPregnancyMembers.filter((mem)=>mem.genderCode === "FEMALE").map((member) => {
                                return (
                                  <li key={member.id}>
                                    <a
                                      style={{ textDecoration: "none" }}
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        this.renderPregnancyDue(member);
                                      }}
                                      className="dta-button dta-button--outline-secondary dta-button--block"
                                    >
                                      <span className="truncate-text">{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}</span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </Collapse>
                        </div> : null}
                          </div>
                        </div>
                      </div>}
                  </li>

                  <hr
                    style={{
                      height: ".5px",
                      backgroundColor: "#ccc",
                      border: "none",
                    }}
                  />

                  <li className="myInfo">
                        <div className="pure-g" >
                        <div>
                        <p className="consumer__heading">{info.disabilityHeading}</p>
                        </div>
                      {
                        pendingFlags &&
                        pendingFlags.disabilityUpdatePendingFlag === "N"
                        &&
                        <div className="edit-button-position">
                          <p className="badge" >{info.changeRequest}</p>
                        </div>}
                    </div>
                  
                        {this.state.disabilityViewMode ?
                          <button
                          className={"dta-button dta-button--outline-secondary edit-button-position"}
                            onClick={() => { this.enableEdit('disability') }}
                          >{info.edit} </button> :
                          <button
                            className={
                              "dta-button dta-button--outline-secondary edit-button-position"
                            }
                            onClick={() => { this.submitData('disability', info) }}
                          >{info.done}</button>
                        }
                    {this.state.disabilityViewMode ?
                      this.renderDisabilityUI(info, 'VIEW') :
                      <div className={this.props.consumer && this.props.consumer.consumerData &&  this.props.consumer.consumerData.disability && this.props.consumer.consumerData.disability.length > 0 ? "pregnancy-disablity-section" : "pregnancy-disablity-section-nodata"}>
                    {this.renderDisabilityUI(info, "EDIT", "OLD")}
                    <div className={"pure-g" + (this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.disability && this.props.consumer.consumerData.disability.length === 0 ? " pregnancy-disablity-section" : "")} style={{marginBottom:"10px"}}>
                        <div className="pure-u-1-2">
                        <p style={{ width: "50%" ,marginTop:0}}>{info.isParticipate}</p>
                          </div>
                          <div className="pure-u-1-2">
                            <Radio
                              id="isParticipate"
                              name="isParticipate"
                              className="dta-form__option-list"
                              optionClass="dta-form__option dta-form__option--width-1-of-2"
                              options={info.YesNoOptions}
                              required={TextProps.VALUE_TRUE}
                              value={this.state.disability.isParticipate}
                              onChange={(value) => this.onEditableFieldsChange('disability', "isParticipate", value,info)}
                            />
                          </div>
                          </div>
                    {this.renderDisabilityUI(info, 'EDIT', "NEW")}
                        <div className="pure-g">
                          <div className="pure-u-1-2"></div>
                          <div className="pure-u-1-2">
                            {this.state.disability.isParticipate === info.YesNoOptions[0] ?
                              <div>
                                <button
                                  className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left`}
                                  onClick={this.toggleAddDisablityMembers}
                                  aria-expanded={this.state.addDisabilityOpen}
                                >
                                  <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
                                  {
                                    info.addAditionalMember
                                  }
                                </button>
                                <Collapse isOpened={this.state.addDisabilityOpen}>
                                  <span className="add-income-summary__label" />
                                  <ul className="list--unstyled add-income-summary__list">
                                    {this.state.nonDisabilityMembers && this.state.nonDisabilityMembers.map((member) => {
                                      return (
                                        <li key={member.id}>
                                          <a
                                            style={{ textDecoration: "none" }}
                                            href="javascript:void(0);"
                                            onClick={() => {
                                              this.renderDisabilityReason(member);
                                            }}
                                            className="dta-button dta-button--outline-secondary dta-button--block"
                                          >
                                            <span className="truncate-text">{member.firstName + " " + member.lastName + " " + formatters.formatDate(member.dateOfBirth)}</span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Collapse>
                              </div> : null}
                          </div>
                        </div>
                      </div>}
                  </li>

                  <hr
                    style={{
                      height: ".5px",
                      backgroundColor: "#ccc",
                      border: "none",
                    }}
                  />

                  <li className="myInfo">
                      <div className="pure-g" >
                      <div>
                        <p className="consumer__heading">{info.schoolAttendanceHeading}</p>
                        </div>
                        {
                            pendingFlags &&
                            pendingFlags.schoolAttendanceUpadtePendingFlag === "N"
                          &&
                          <div className="edit-button-position">
                            <p className="badge" >{info.changeRequest}</p>
                          </div>}
                    </div>
                          <button
                           className={"dta-button dta-button--outline-secondary edit-button-position"}
                            onClick={() => { this.goToAttendancePage() }}
                           >{info.edit} </button> 
                    {
                      this.renderAttendanceUI( info) 
                    }
                  </li>

            </ul>
          </div>
        ) : null}
      <DTAModal
              isOpen={this.state.trackApplicationModalOpen}
              onClose={this.closeTrackApplicationModal}
              modalClass="account-connect-modal consumer-search-client custom_width"
              titleText={this.state.isTrackApplication ? home.trackApplicationText : home.trackRecertApplicationText}
              headerText={this.state.isTrackApplication ? home.trackApplicationText : home.trackRecertApplicationText}
            >
              <div className="dta-modal__body pad-all">
                <div className="searchClientSection ">
              <div class="table" id="results">
              <div class='theader'>
                  <div class='table_header' >
                    <span className="  ">
                    {home.trackTableHeadType}
                    </span>
                  </div>
                  <div class='table_header'  style={{maxWidth:'121px'}}>
                    <span className="  ">
                    {home.trackTableHeadAppNum}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                    {home.trackTableHeadStatus}
                    </span>
                  </div>
                  <div class='table_header' style={{paddingLeft: '5px'}}>
                    <span className="  ">
                    {home.trackTableHeadSubDate}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                    {home.trackTableHeadStatDate}
                    </span>
                  </div>
                </div>
                {trackConsumer !== undefined && trackConsumer.map((userDetail) => {
                   return (
                    <div class='table_row'>
                    <div class='table_small'>
                      <div class='table_cell'>Header One</div>
                      <div class='table_cell'>
                        <span className=" ">
                          {userDetail.type ? this.returnTypeDescription(userDetail.type) : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Two</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.applicationNumber ? userDetail.applicationNumber : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Three</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.status ? userDetail.status : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Four</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.submittedDate ? formatters.formatDateBySlash(userDetail.submittedDate) : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Five</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.statusChangeDate ? formatters.formatDateBySlash(userDetail.statusChangeDate) : "N/A"}
                         </span>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </div> 
                </div>
                <div className="dta-modal__footer dta-modal__footer--inline-buttons" style={{display: "flex", justifyContent: "center", borderTop: "0px"}}>
                  <button
                    className="dta-button dta-button--large dta-button--primary"
                    style={{margin: "0 auto"}}
                    onClick={this.closeTrackApplicationModal}
                  >
                    {home.trackTableOk}
                  </button>
                </div>
              </div>
            </DTAModal>

        {(isNewUser === TextProps.VALUE_STR_TRUE || isConsumerInfoPage) && (
          <div>
            <h4 className="margin-bottom-half">
              {info.existingBenefitConfirmation}
            </h4>
            <p className="margin-top-none">{info.consumerMoreInfo}</p>
            <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={this.openAccountConnectModal}
            >
              {info.connectDTAInfo}
            </button>
            <DTAModal
              ref={(instance) => (this.accountConnectModal = instance)}
              isOpen={this.state.accountConnectModalOpen}
              onClose={this.closeAccountConnectModal}
              modalClass="account-connect-modal consumer-search-client"
              titleText="Connect to my DHR info"
              // error={this.error}
              // errorMessage={this.errorMessage}
              headerText={info.connectDTAInfoHeader}
            >
              <div>
                <p>{info.connectAcctInfoLbl}.</p>
              </div>
              <div className="dta-modal__body pad-all">
                {accountConnectQuestion &&
                  accountConnectQuestion.map((questionData) => {
                    return resolveQuestion(questionData, {
                      doRetrieve: this._doRetrieve,
                      onChange: this._handleChangeForQuestion,
                      doGetUniqueId: this._getUniqueId,
                    });
                  })}

                {this.state.accountNotFoundError ? (
                  <span className="dta-form__error-message">
                    {search.accountNotFound}
                  </span>
                ) : (
                  <span></span>
                )}
                {this.state.webNumberNotEnterError ? (
                  <span className="dta-form__error-message">
                    {search.validWebAppMsg}
                  </span>
                ) : (
                  <span></span>
                )}
                {this.state.recordsCanNotLinked ? (
                  <span className="dta-form__error-message">
                    {search.recordsCanNotLinked}
                  </span>
                ) : this.state.recordsNotMatchError ? (
                  <span className="dta-form__error-message">
                    {search.recordsNotMatch}
                  </span>
                ) : (
                  <span></span>
                )}
                <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                  <button
                    className="dta-button dta-button--large dta-button--outline-primary"
                    onClick={this.closeAccountConnectModal}
                  >
                    {info.back}
                  </button>
                  <button
                    className="dta-button dta-button--large dta-button--primary"
                    onClick={this.connectAccount}
                  >
                    {info.Connect}
                  </button>
                </div>
              </div>
            </DTAModal>
            <DTAModal
              ref={(instance) => (this.modalRef = instance)}
              isOpen={this.state.RIVLModalOpen}
              modalClass="rivl-modal"
              titleText="DHR connect account linked"
              error={this.state.RIVLError}
              hideCloseIcon={this.state.hideCloseIcon}
              errorMessage={this.state.RIVLErrorMessage}
            >
              <div className="dta-modal__body pad-all--double">
                <div>
                  {connecttodtaLogoutPopupLabels(language).connectToDtaLogout}
                </div>
                <div className="connecttodta-logout-btn-container">
                  {/* <button className="dta-button dta-button--primary" onClick={e => this.onLogOutClick()}>{info.ok}</button> */}

                  <button
                    className="dta-button dta-button--primary"
                    onClick={() =>
                      config.azureLoginFlag
                        ? this.props.onAzureLogOut(msalInstance)
                        : this.props.onAzureLogOut(msalInstance)
                    }
                  >
                    {info.ok}
                  </button>
                </div>
              </div>
            </DTAModal>
          </div>
        )}
      </div>
    );
  }

  // Helpers
  // -------
  _doRetrieve = (questionData, conditionObj) => {
    return questionData.value;
  };

  _handleChangeForQuestion = (questionData, conditionObj, val) => {
    const { search } = this.props.language.provider;
    // In production environment, binding new value would be handled with Redux
    // or similar and not require the forced update
    let consumerDataUserObject = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let consumerNOMATObj = JSON.parse(
      sessionStorage.getItem("consumerAddrData")
    );
    questionData.value = val;
    let _this = this;
    _this.state.optionNotSelectedError = TextProps.VALUE_FALSE;
    _this.state.firstNameNotEnteredError = TextProps.VALUE_FALSE;
    _this.state.lastNameNotEnteredError = TextProps.VALUE_FALSE;
    _this.state.ssnNotEnteredError = TextProps.VALUE_FALSE;
    _this.state.dobNotEnteredError = TextProps.VALUE_FALSE;
    _this.state.accountNotFoundError = TextProps.VALUE_FALSE;
    _this.state.webNumberNotEnterError = TextProps.VALUE_FALSE;
    _this.state.dtaNumberNotEnterError = TextProps.VALUE_FALSE;
    _this.state.ssnNumberNotEnterError = TextProps.VALUE_FALSE;
    _this.state.tanfNumberNotEnterError = TextProps.VALUE_FALSE;
    _this.state.ebtNumberNotEnterError = TextProps.VALUE_FALSE;
    _this.state.clientIdNotEnterError = TextProps.VALUE_FALSE;
    _this.state.recordsNotMatchError = TextProps.VALUE_FALSE;
    this.error = TextProps.VALUE_FALSE;
    this.errorMessage = "";
    var connectAccountData = {};
    if (sessionStorage.getItem("connectAccountData")) {
      connectAccountData = JSON.parse(
        sessionStorage.getItem("connectAccountData")
      );
    }

    this.accountAnswer.answerType = {};
    switch (questionData.name) {
      case "accountConnectType":
        //this.accountAnswer.answerType = {};
        questionData.conditionals.forEach((cond) => {
          cond.conditionalQuestions.forEach((quest) => {
            delete quest.value;
          });
        });
        this.accountAnswer.answerType.type = val;
        connectAccountData.type = val;
        break;
      // case "web":
      //   if (val !== "" && val !== "undefined") {
      //     var rex = /^[0-9]+$/;
      //     if (rex.test(val) === TextProps.VALUE_FALSE) {
      //       this.error = TextProps.VALUE_TRUE;
      //       this.errorMessage = search.validWebAppMsg;
      //     } else {
      //       this.error = TextProps.VALUE_FALSE;
      //       this.accountAnswer.answerType.value = val;
      //     }
      //   } else {
      //     this.error = TextProps.VALUE_TRUE;
      //     this.accountAnswer.answerType.webAppNumber = "";
      //   }
      //   break;
      // case "tanf":
      //   if (val !== "" && val !== "undefined") {
      //     var rex = /^[0-9]+$/;
      //     if (rex.test(val) === TextProps.VALUE_FALSE) {
      //       this.error = TextProps.VALUE_TRUE;
      //       this.errorMessage = search.validWebCashMsg;
      //     } else {
      //       this.error = TextProps.VALUE_FALSE;
      //       this.accountAnswer.answerType.value = val;
      //     }
      //   } else {
      //     this.error = TextProps.VALUE_TRUE;
      //     this.accountAnswer.answerType.webAppNumber = "";
      //   }
      //   break;

      case "dta":
        if (val !== "" && val !== "undefined") {
          var regex = /^[0-9]+$/;
          if (regex.test(val) === TextProps.VALUE_FALSE) {
            this.error = TextProps.VALUE_TRUE;
            this.errorMessage = search.validDTAMsg;
          } else {
            this.error = TextProps.VALUE_FALSE;
            this.accountAnswer.answerType.value = val;
          }
        } else {
          this.error = TextProps.VALUE_TRUE;
          this.accountAnswer.answerType.dtaId = "";
        }
        break;

      case "ebt":
        if (val !== "" && val !== "undefined") {
          var regexEBT = /^[0-9]+$/;
          if (regexEBT.test(val) === TextProps.VALUE_FALSE) {
            this.error = TextProps.VALUE_TRUE;
            this.errorMessage = search.validEBTMsg;
          } else {
            this.error = TextProps.VALUE_FALSE;
            this.accountAnswer.answerType.value = val;
            connectAccountData.value = val;
          }
        } else {
          this.error = TextProps.VALUE_TRUE;
          this.accountAnswer.answerType.ebtNumber = "";
          connectAccountData.value = "";
        }
        connectAccountData.type = "ebt";
        break;
      case "clientId":
        if (val !== "" && val !== "undefined") {
          var regexEBT = /^[0-9]+$/;
          if (regexEBT.test(val) === TextProps.VALUE_FALSE) {
            this.error = TextProps.VALUE_TRUE;
            this.errorMessage = search.validClientIdMsg;
          } else {
            this.error = TextProps.VALUE_FALSE;
            this.accountAnswer.answerType.value = val;
            connectAccountData.value = val;
          }
        } else {
          this.error = TextProps.VALUE_TRUE;
          this.accountAnswer.answerType.clientId = "";
          connectAccountData.value = "";
        }
        connectAccountData.type = "clientId";
        break;
      case "firstName":
        this.accountAnswer.answerType.firstName = val;
        connectAccountData.firstName = val;
        break;
      case "lastName":
        this.accountAnswer.answerType.lastName = val;
        connectAccountData.lastName = val;
        break;
      case "ssnNumber":
        if (val !== "" && val !== "undefined") {
          if (PropTypes.ssnError === 1) {
            this.error = TextProps.VALUE_TRUE;
            this.errorMessage = search.validSSNMsg;
            this.accountAnswer.answerType.ssnNumber = "";
            connectAccountData.ssnNumber = "";
          } else {
            this.error = TextProps.VALUE_FALSE;
            this.accountAnswer.answerType.ssnNumber = val;
            connectAccountData.ssnNumber = val;
          }
        } else {
          this.error = TextProps.VALUE_TRUE;
          this.accountAnswer.answerType.ssnNumber = "";
        }
        break;
      case "dob":
        this.accountAnswer.answerType.dob = val;
        connectAccountData.dob = val;
        break;
      case "phoneNumber": {
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[0].value =
            val;
          // To be replaced with the user name obtained from centrify
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[0].userName =
            this.props.consumer.consumerData.clientInfo.name.first;
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[0].clientId =
            this.props.consumer.consumerData.clientInfo.agencyID;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[1].fields[0].value = val;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[1].fields[0].userName =
            consumerDataUserObject.clientInfo.name.first;
          consumerDataUserObject.consumerAboutMeInfo[1].fields[0].clientId =
            consumerDataUserObject.clientInfo.agencyID;
        }
        else{
          let consumerAboutMeInfoTemplate = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          consumerAboutMeInfoTemplate[1].fields[0]["value"] = val
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        }
        break;
      }

      case  "allowTextNotification" :{
        
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[1].value =
            val;
          // To be replaced with the user name obtained from centrify
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[1].userName =
            this.props.consumer.consumerData.clientInfo.name.first;
          this.props.consumer.centrifyData.consumerAboutMeInfo[1].fields[1].clientId =
            this.props.consumer.consumerData.clientInfo.agencyID;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[1].fields[1].value = val;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[1].fields[1].userName =
            consumerDataUserObject.clientInfo.name.first;
          consumerDataUserObject.consumerAboutMeInfo[1].fields[1].clientId =
            consumerDataUserObject.clientInfo.agencyID;
        }
        else{
          let consumerAboutMeInfoTemplate = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          consumerAboutMeInfoTemplate[1].fields[1]["value"] = val
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        }
        break;
      }
     
      case "emailAddress": {
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[0].value =
            val;
          // To be replaced with the user name obtained from centrify
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[0].userName =
            this.props.consumer.consumerData.clientInfo.name.first;
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[0].clientId =
            this.props.consumer.consumerData.clientInfo.agencyID;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[2].fields[0].value = val;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[2].fields[0].userName =
            consumerDataUserObject.clientInfo.name.first;
          consumerDataUserObject.consumerAboutMeInfo[2].fields[0].clientId =
            consumerDataUserObject.clientInfo.agencyID;
        }
        else{
          let consumerAboutMeInfoTemplate = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          console.log("pop",consumerAboutMeInfoTemplate)
          console.log("pol",val)
          consumerAboutMeInfoTemplate[2].fields[0]["value"] = val
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        }
        break;
      }

      case "allowEmailNotification": {
        consumerDataUserObject = JSON.parse(
          sessionStorage.getItem("loadConsumerUser")
        );
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[1].value =
            val;
          // To be replaced with the user name obtained from centrify
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[1].userName =
            this.props.consumer.consumerData.clientInfo.name.first;
          this.props.consumer.centrifyData.consumerAboutMeInfo[2].fields[1].clientId =
            this.props.consumer.consumerData.clientInfo.agencyID;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[2].fields[1].value = val;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[2].fields[1].userName =
            consumerDataUserObject.clientInfo.name.first;
          consumerDataUserObject.consumerAboutMeInfo[2].fields[1].clientId =
            consumerDataUserObject.clientInfo.agencyID;
        }
        else{
          let consumerAboutMeInfoTemplate = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          consumerAboutMeInfoTemplate[2].fields[1]["value"] = val
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        }
        break;
      }
     
      case "residentialAddress": {
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          let line1 = val.line1 ? val.line1 : val.addressLine1;
          let line2 = val.line2 ? val.line2 : val.addressLine2;
          let zip = val.zip ? val.zip : val.zipCode;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.city =
            val.city;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.state =
            val.state;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.street =
            val.street;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.addressLine1 =
            line1;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.addressLine2 =
            line2;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.county =
            val.county;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.verified =
            val.verified;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[0].value.zipCode =
            zip;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.city =
            val.city;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.state =
            val.state;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.street =
            val.street;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.zipCode =
            val.zipCode;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].userName =
            consumerDataUserObject.user.firstName;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].clientId =
            consumerDataUserObject.accountId;
        } else if (
          consumerNOMATObj !== null &&
          consumerNOMATObj.accountId > 0
        ) {
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].value.city =
            val.city;
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].value.state =
            val.state;
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].value.street =
            val.street;
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].value.zipCode =
            val.zipCode;
          // To be replaced with the user name obtained from centrify
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].userName =
            consumerNOMATObj.user.firstName;
          consumerNOMATObj.consumerAboutMeInfo[0].fields[0].clientId =
            consumerNOMATObj.accountId;
        }
        let consumerAboutMeInfoTemplate = JSON.parse(
          sessionStorage.getItem("consumerAboutMeInfoTemplate")
        );
        consumerAboutMeInfoTemplate[0].fields[0]["value"].street = val.street;
        consumerAboutMeInfoTemplate[0].fields[0]["value"].city = val.city;
        consumerAboutMeInfoTemplate[0].fields[0]["value"].state = val.state;
        consumerAboutMeInfoTemplate[0].fields[0]["value"].zipCode = val.zipCode;
        sessionStorage.setItem(
          "consumerAboutMeInfoTemplate",
          JSON.stringify(consumerAboutMeInfoTemplate)
        );

        var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        if (
          clientDetails.clientAddress[1] !== undefined &&
          clientDetails.clientAddress.length === 2 &&
          clientDetails.clientAddress[1].typeCode === "RES"
        ) {
          let line1 = consumerAboutMeInfoTemplate[0].fields[0].value.line1
            ? consumerAboutMeInfoTemplate[0].fields[0].value.line1
            : consumerAboutMeInfoTemplate[0].fields[0].value.addressLine1;
          let line2 = consumerAboutMeInfoTemplate[0].fields[0].value.line2
            ? consumerAboutMeInfoTemplate[0].fields[0].value.line2
            : consumerAboutMeInfoTemplate[0].fields[0].value.addressLine2;
          let zip = consumerAboutMeInfoTemplate[0].fields[0].value.zip
            ? consumerAboutMeInfoTemplate[0].fields[0].value.zip
            : consumerAboutMeInfoTemplate[0].fields[0].value.zipCode;
          clientDetails.clientAddress[1].line1 = line1;
          clientDetails.clientAddress[1].line2 = line2;
          clientDetails.clientAddress[1].street =
            consumerAboutMeInfoTemplate[0].fields[0].value.street;
          clientDetails.clientAddress[1].county =
            consumerAboutMeInfoTemplate[0].fields[0].value.county;
          clientDetails.clientAddress[1].verified =
            consumerAboutMeInfoTemplate[0].fields[0].value.verified;
          clientDetails.clientAddress[1].city =
            consumerAboutMeInfoTemplate[0].fields[0].value.city;
          clientDetails.clientAddress[1].zip = zip;
          sessionStorage.setItem(
            "clientDetails",
            JSON.stringify(clientDetails)
          );
        }
        if (
          clientDetails.clientAddress[0] !== undefined &&
          clientDetails.clientAddress.length === 2 &&
          clientDetails.clientAddress[0].name === "residentialAddress"
        ) {
          let line1 = consumerAboutMeInfoTemplate[0].fields[0].value.line1
            ? consumerAboutMeInfoTemplate[0].fields[0].value.line1
            : consumerAboutMeInfoTemplate[0].fields[0].value.addressLine1;
          let line2 = consumerAboutMeInfoTemplate[0].fields[0].value.line2
            ? consumerAboutMeInfoTemplate[0].fields[0].value.line2
            : consumerAboutMeInfoTemplate[0].fields[0].value.addressLine2;
          let zip = consumerAboutMeInfoTemplate[0].fields[0].value.zip
            ? consumerAboutMeInfoTemplate[0].fields[0].value.zip
            : consumerAboutMeInfoTemplate[0].fields[0].value.zipCode;
          clientDetails.clientAddress[0].value.street =
            consumerAboutMeInfoTemplate[0].fields[0].value.street;
          clientDetails.clientAddress[0].value.addressLine1 = line1;
          clientDetails.clientAddress[0].value.addressLine2 = line2;
          clientDetails.clientAddress[0].value.county =
            consumerAboutMeInfoTemplate[0].fields[0].value.county;
          clientDetails.clientAddress[0].value.verified =
            consumerAboutMeInfoTemplate[0].fields[0].value.verified;
          clientDetails.clientAddress[0].value.city =
            consumerAboutMeInfoTemplate[0].fields[0].value.city;
          clientDetails.clientAddress[0].value.zipCode = zip;
          sessionStorage.setItem(
            "clientDetails",
            JSON.stringify(clientDetails)
          );
        }
        break;
      }
      case "is-also-mailing": {
        if (
          this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
        ) {
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.city =
            val.city;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.state =
            val.state;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.street =
            val.street;
          this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.zipCode =
            val.zipCode;
        } else if (
          consumerDataUserObject !== null &&
          consumerDataUserObject.accountId > 0
        ) {
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.city =
            val.city;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.state =
            val.state;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.street =
            val.street;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].value.zipCode =
            val.zipCode;
          // To be replaced with the user name obtained from centrify
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].userName =
            consumerDataUserObject.user.firstName;
          consumerDataUserObject.consumerAboutMeInfo[0].fields[0].clientId =
            consumerDataUserObject.accountId;
        }
        let consumerAboutMeInfoTemplate = JSON.parse(
          sessionStorage.getItem("consumerAboutMeInfoTemplate")
        );
        consumerAboutMeInfoTemplate[0].fields[1]["value"].street = val.street;
        consumerAboutMeInfoTemplate[0].fields[1]["value"].city = val.city;
        consumerAboutMeInfoTemplate[0].fields[1]["value"].state = val.state;
        consumerAboutMeInfoTemplate[0].fields[1]["value"].zipCode = val.zipCode;
        sessionStorage.setItem(
          "consumerAboutMeInfoTemplate",
          JSON.stringify(consumerAboutMeInfoTemplate)
        );

        var clientData = JSON.parse(sessionStorage.getItem("clientDetails"));
        if (
          clientData.clientAddress[0] !== undefined &&
          clientData.clientAddress[0].typeCode === "MAIL"
        ) {
          let line1 = val.line1 ? val.line1 : val.addressLine1;
          let line2 = val.line2 ? val.line2 : val.addressLine2;
          let zip = val.zip ? val.zip : val.zipCode;
          clientData.clientAddress[0].line1 = line1;
          clientData.clientAddress[0].line2 = line2;
          clientData.clientAddress[0].street = val.street;
          clientData.clientAddress[0].county = val.county;
          clientData.clientAddress[0].verified = val.verified;
          clientData.clientAddress[0].city = val.city;
          clientData.clientAddress[0].state = val.state;
          clientData.clientAddress[0].zip = zip;
          sessionStorage.setItem("clientDetails", JSON.stringify(clientData));
        }
        if (
          clientData.clientAddress[1] !== undefined &&
          clientData.clientAddress[1].name === "is-also-mailing"
        ) {
          let line1 = val.line1 ? val.line1 : val.addressLine1;
          let line2 = val.line2 ? val.line2 : val.addressLine2;
          let zip = val.zip ? val.zip : val.zipCode;
          clientData.clientAddress[1].value.line1 = line1;
          clientData.clientAddress[1].value.line2 = line2;
          clientData.clientAddress[1].value.street = val.street;
          clientData.clientAddress[1].value.county = val.county;
          clientData.clientAddress[1].value.verified = val.verified;
          clientData.clientAddress[1].value.city = val.city;
          clientData.clientAddress[1].state = val.state;
          clientData.clientAddress[1].value.zip = zip;
          sessionStorage.setItem("clientDetails", JSON.stringify(clientData));
        }
        break;
      }
      default:
        break;
    }

    sessionStorage.setItem(
      "connectAccountData",
      JSON.stringify(connectAccountData)
    );

    sessionStorage.setItem(
      "loadConsumerUser",
      JSON.stringify(consumerDataUserObject)
    );
    this.forceUpdate();
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showBenefitListInfo: showBenefitListInfo,
      getConsumerInfo: getConsumerInfo,
      addConsumerAboutMeData: addConsumerAboutMeData,
      serviceFailedAction: serviceFailedAction,
      saveClientInfo: saveClientInfo,
      updateConsumerInfo: updateConsumerInfo,
      getSavedDataForUser: getSavedDataForUser,
      getSaveMyProgessDataByWebAppId: getSaveMyProgessDataByWebAppId,
      connecttoDtaAccount: connecttoDtaAccount,
      gotoAppApply: () => push(routeProps.APPLY_PATH),
      gotoReportChange: (path) => push(path),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      hideUrgentApmntAlert: hideUrgentApmntAlert,
      setSectionBasedApplicationType: setSectionBasedApplicationType,
      unlinkAccount: unlinkAccount,
      setHouseholdMembersList: setHouseholdMembersList,
      startRecert: getRecertData,
      startTrackApplication: getTrackApplication,
      addrsWarningPopupAction: addrsWarningPopupAction,
      postData: postData,
      languageChanged: languageChanged,
      setAddressFlag:setAddressFlag
    },
    dispatch
  );
}

export default withRouter(
  withMsal(connect(mapStateToProps, mapDispatchToProps)(ConsumerMyInfo))
);