import React, { Component } from "react";
import PropTypes from "prop-types";
import Options from "./options";
import {
  addStringIf,
  buildObjectFromArray,
  tryCall,
  isNone,
} from "../components/utilities/controls";
import { OptionsTypes, OptionsDefaults } from "./propTypes";
import { CHECKBOX_CLASSES,CHECKBOX_CLASSES_DSNAP } from "../../utils/constants/controls";
import * as TextProps from "../../utils/constants/text";
class Checkbox extends Component {
  static propTypes = {
    ...OptionsTypes,
    value: PropTypes.array,
  };
  static defaultProps = {
    ...OptionsDefaults,
  };

  constructor(props) {
    super(...arguments);

    this.state = {
      _value: isNone(props.value) ? [] : props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        _value: nextProps.value,
      });
    }
  }


  render() {
    return (
      this.props.data && this.props.data.name === 'do-not-have-address' ? 
      (
        <Options
          {...CHECKBOX_CLASSES_DSNAP}
          {...this.props}
          value={this.state._value}
          onChange={this._handleChange}
          doBuildState={this._doBuildState}
          doBuildValue={this._doBuildValue}
          doInitializeCleared={this._checkIsCleared}
          inputType="checkbox"
          optionClass={addStringIf(
            this.props.optionClass,
            CHECKBOX_CLASSES_DSNAP.optionClass
          )}
        />
      ) : (
        <Options
          {...CHECKBOX_CLASSES}
          {...this.props}
          value={this.state._value}
          onChange={this._handleChange}
          doBuildState={this._doBuildState}
          doBuildValue={this._doBuildValue}
          doInitializeCleared={this._checkIsCleared}
          inputType="checkbox"
          optionClass={addStringIf(
            this.props.optionClass,
            CHECKBOX_CLASSES.optionClass
          )}
        />
      )
    );
  }

  // Rendering
  // ---------

  _doBuildState = (selected, clearLabel, options) => {
    return this._checkIsCleared(selected, clearLabel)
      ? buildObjectFromArray(options, TextProps.VALUE_FALSE)
      : {
          ...buildObjectFromArray(options, TextProps.VALUE_FALSE), // baseline of TextProps.VALUE_STR_FALSE for all provided options
          ...buildObjectFromArray(selected || [], TextProps.VALUE_TRUE), // selected options have TextProps.VALUE_STR_TRUE instead
        };
  };
  _doBuildValue(selectedOption, clearLabel, optionStates) {
    if (selectedOption === clearLabel) {
      return [clearLabel];
    }

    const newValue = Object.keys(optionStates).filter((option) => {
      // don't include if selectedOption because we are about to remove this selected option
      return (
        option !== selectedOption &&
        optionStates[option] === TextProps.VALUE_TRUE
      );
    });
    // if selectedOption is not selected, then will be selected after this method
    // so we want to include it as part of the new value
    if (optionStates[selectedOption] === TextProps.VALUE_FALSE) {
      newValue.push(selectedOption);
    }
    return newValue;
  }

  _checkIsCleared(selected, clearLabel) {
    return selected && selected.indexOf(clearLabel) > -1;
  }
  // Events
  // ------

  _handleChange = (value) => {
    const [lastElement] = value.slice(-1)
    if(lastElement === 'Decline to answer'){
      value = ['Decline to answer'];
    }else{
      const index = value.indexOf('Decline to answer')
      if(index!== -1){
        value.splice(index, 1);
      }
    }
    this.setState({ _value: value });
    tryCall(this.props.onChange, value);
  };
}

export default Checkbox;
