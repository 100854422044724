export const communicationAssistanceData = {
    LangData: [
        {
            LangId: 0,
            LangTye: 'Free Communication Assistance',
            LangContent: ''
        },
        {
            LangId: 1,
            LangTye: 'English',
            LangLabel: 'English',
            LangContent: 'If you have difficulty communicating with us because you do not speak English, please contact your Local County Office. See contact information listed below . Individuals who are deaf, hard of hearing or have speech disabilities can call the Alabama Relay Service at 711 or 800-548-2546 (TTY) for assistance contacting your Local County Office. Free language assistance or other aids and services are available upon request.'
        },
        {
            LangId: 2,
            LangTye: 'Español',
            LangLabel: 'Español (Spanish)',
            LangContent: 'Si tiene dificultades para comunicarse con nosotros porque no habla inglés, por favor, haga Consulte la información de contacto que se detalla a continuación para contactar a la oficina local del condado. Las personas sordas, con problemas de audición o discapacidades del habla pueden comunicarse con el Alabama Relay Service al 711 o al 800-548-2546 (TTY) para asistencia contactando a la oficina local del condado. Asistencia con el idioma u otros dispositivos y servicios están disponibles gratuitamente a solicitud.'
        },
        {
            LangId: 3,
            LangTye: '中文',
            LangLabel: '中文 (Chinese)',
            LangContent: `如果您因不会说英语而与我们有沟通困难，请参阅下面列出的联系信息 联系下面列出的当地县办事处。 聋哑人、有听力障碍或有语言障碍的人士请致电 711或800-548-2546（TTY) 联系 Alabama Relay Service ，帮助联系您当地县办事处。
            可应要求提供免费语言协助或其他帮助和服务。`
        },
        {
            LangId: 4,
            LangTye: '한국어',
            LangLabel: '한국어 (Korean)',
            LangContent: '영어를 하지 못해 저희와 커뮤니케이션 하는데 아래 나열된 연락처 정보 참조 클릭하여 현지 지역 사무소(Local County Office)로 연락해주십시오. 청각 장애나 난청 또는 언어 장애가 있으신 분은  711 또는 800-548-2546(TTY) 으로 Alabama Relay Service에연락하여 현지 지역 사무소(Local County Office)로부터 도움을 받으십시오.  요청시 무료 언어 지원이나 기타 지원 및 서비스를 받으실수 있습니다.'
        },
        {
            LangId: 5,
            LangTye: 'Tiếng Việt',
            LangLabel: 'Tiếng Việt (Vietnamese)',
            LangContent: 'Nếu quý vị gặp khó khăn trong việc trao đổi thông tin với chúng tôi do quý vị không nói được tiếng Anh, vui lòng liên hệ với Văn Phòng Quận Địa Phương của quý vị bằng cách nhấp vào . Các cá nhân bị khiếm thính, lãng tai hoặc bị khuyết tật ngôn ngữ có thể gọi đến Dịch Vụ Tiếp Âm Alabama theo số 711 hoặc 800-548-2546 (TTY) để được hỗ trợ liên lạc với Văn Phòng Quận Địa Phương của quý vị. Dịch vụ hỗ trợ ngôn ngữ miễn phí hoặc các hỗ trợ và dịch vụ khác được cung cấp theo yêu cầu.'
        },
        {
            LangId: 6,
            LangTye: 'العربية',
            LangLabel: 'العربية (Arabic)',
            LangContent: 'اللغة الإنجليزية، يُرجى التواصل مع مكتب المقاطعة المحلي الذي تنتمي إليه اعن طريق انظر معلومات الاتصال المدرجة أدناه . وبالنسبة إلى الأفراد المصابين بالصمم أو ضعاف السمع أو لديهم صعوبات في النطق، يُرجى الاتصال بخدمات الهاتف في ألاباما على رقم 711 أو 2546-548-800 للحصول علي مساعدة في الاتصال بمكتب المقاطعة المحلي التابع لك. كما يتم توفير خدمات المساعدة اللغوية المجانية أو أي خدمات أو مساعداتِ أخرى عند '
        },
        {
            LangId: 7,
            LangTye: 'Deutsch',
            LangLabel: 'Deutsch (German)',
            LangContent: 'Wenn Sie kein Englisch sprechen, wenden Sie sich bitte an die lokale Niederlassung in Ihrem Land, Die Kontaktinformationen finden Sie unten aufgeführt. Personen, die taub oder hörbehindert sind oder eine Sprachstörung haben, können die Alabama Relay Service bei 711 oder 800-548-2546 ((TTY) für Hilfe um die lokale Niederlassung zu kontaktieren, anrufen. Kostenlose Sprachunterstützung oder andere Hilfen und Services sind auf Anfrage verfügbar.'
        },
        {
            LangId: 8,
            LangTye: 'Français',
            LangLabel: 'Français (French)',
            LangContent: 'Si vous avez des difficultés à communiquer avec nous parce que vous ne parlez pas l’anglais, prière de contacter le bureau local pour votre Comté .Voir les coordonnées ci-dessous. Les personnes sourdes, malentendantes ou ayant un trouble de la parole peuvent contacter le Service Alabama Relay au 711 ou bien le 800-548-2546 (TTY) pour obtenir de l’assistance afin de contacter le burau local de votre Comté. Un service gratuit d’assistance linguistique et autres services sont disponibles sur simple demande.'
        },
        {
            LangId: 9,
            LangTye: 'ગજુ રાતી',
            LangLabel: 'ગુજરાતી (Gujarati)',
            LangContent: 'જો તમને અમારી સાથે વાતચીત કરવામાં મુશ્કેલી થાય છે કારણ કે તમે અંગ્રેજી બોલતા નથી, ક્લિક કરીને નીચે સૂચિબદ્ધ તમારા સ્થાનિક કાઉન્ટી ઑફિસનો સંપર્ક કરો. વ્યક્તિઓ જે બહેરા છે, ઓછું સંભળતા હોય અથવા બોલવાની અક્ષમતા ધરાવતા હોય તેઓ મદદ માટે તમારી સ્થાનિક કાઉન્ટી ઑફિસનો સંપર્ક કરવા અલબામા રિલે સેવાને 711 અથવા 800-548-2546 (TTY) પર કૉલ કરી શકે છે. મફત ભાષા સહાય અથવા અન્ય સહાય અને સેવાઓ વિનંતી પર ઉપલબ્ધ છે.'
        },
        {
            LangId: 10,
            LangTye: 'Tagalog',
            LangLabel: 'Tagalog (Tagalog)',
            LangContent: 'Kung nahihirapan ka sa pakikipag-usap sa amin dahil hindi ka nagsasalita ng Ingles, mangyaring kontakin ang iyong Local County Office .Tingnan ang impormasyon sa pakikipag-ugnayan na nakalista sa ibaba. Ang mga indibidwal na hindi nakakarinig, hirap makarinig o may kapansanan sa pagsasalita ay maaaring tumawag sa Alabama Relay Service sa 711 o 800-548-2546 (TTY) para sa tulong makipag-ugnayan sa iyong Local County Office. May libreng tulong sa wika o iba pang mga tulong at serbisyo kapag hiniling.'
        },
        {
            LangId: 11,
            LangTye: 'हिंदी',
            LangLabel: 'हिंदी (Hindi)',
            LangContent: 'यदि आपको हमारे साथ संवाद करने में कठिनाई होती है क्योंकि आप अंग्रेजी नहीं बोलते हैं तो कृपया ,नीचे सूचीबद्ध संपर्क जानकारी देखें. जो लोग श्रवण बधित हैं या जिन्हें कम सुनाई देता है या वाक् में असमर्थ हैं तो अपने स्थानीय काउंटी कार्यालय से संपर्क करने के लिए 711 या 800-548-2546 (TTY) पर अलाबामा रिले सर्विस से सम्पर्क कर सकते हैं। नि: शुल्क भाषा सहायता या अन्य सहायता और सेवाएं अनुरोध पर उपलब्ध हैं।'
        },
        {
            LangId: 12,
            LangTye: 'ລາວ',
            LangLabel: 'ລາວ (Laotian)',
            LangContent: 'ຫາກທ່ານມີຄວາມຫຍຸ້ງຍາກໃນການສື່ສານກັບພວກເຮົາເພາະວ່າທ່ານເວົ້າພາສາອັງກິດບໍ່ໄດ້ ກະລຸນາຕິດຕໍ່ຫາຫ້ອງການປົກຄອງໃນທ້ອງຖິ່ນຂອງທ່ານ ໂດຍການຄລິກໃສ່ ເບິ່ງຂໍ້ມູນການຕິດຕໍ່ທີ່ມີລາຍຊື່ຂ້າງລຸ່ມນີ້, ໄດ້ຍີນບໍ່ຊັດເຈນ ຫຼື ປາກກືກ ສາມາດໂທຫາ Alabama Relay Services ໄດ້ທີ່ 711 ຫຼື 800-548-2546 (TTY) ເພື່ອຂໍຄຳແນະນຳສຳຫລັບການຕິດຕໍ່ຫ້ອງການປົກຄອງທ້ອງຖີ່ນຂອງທ່ານ. ການຊ່ວຍເຫຼືອທາງດ້ານພາສາຟຣີ ຫຼື ການຊ່ວຍເຫຼືອອື່ນໆ ແລະ ການບໍລິການ ແມ່ນຂື້ນກັບການຮ້ອງຂໍຂອງທານ.'
        },
        {
            LangId: 13,
            LangTye: 'Русский',
            LangLabel: 'Русский (Russian)',
            LangContent: 'Если вам сложно с нами общаться из-за того, что вы не говорите по-английски, См. контактную информацию, указанную ниже. для связи с местным офисом вашего округа.  Лица с нарушениями слуха и речи могут обратиться в местный офис своего округа через службу коммутируемых сообщений по телефону 711 или 800-548-2546 (TTY).  По запросу бесплатно предоставляется языковая помощь, а также иные виды помощи и услуг.'
        },
        {
            LangId: 14,
            LangTye: 'Português',
            LangLabel: 'Português (Portugese)',
            LangContent: `Se você tiver dificuldade em se comunicar conosco porque não fala inglês, por favor entre em contato com o escritório local do condado.Veja as informações de contato listadas abaixo.Indivíduos surdos, com deficiência auditiva ou com deficiência de fala podem ligar para o Alabama Relay Service no número 711 ou 800-548-2546 (TTY) para obter assistência para entrar em contato com o escritório local do condado.
            Assistência linguística ou outros auxílios e serviços estão disponíveis sem custo mediante solicitação.`
        },
        {
            LangId: 15,
            LangTye: 'Türkçe',
            LangLabel: 'Türkçe (Turkish)',
            LangContent: 'İngilizce konuşmadığınız için bizimle iletişime geçmekte zorlanıyorsanız lütfen size en yakın olan İlçe Ofisiyle.Aşağıda listelenen iletişim bilgilerine bakın. İşitme engeli ya da zorluğu olan veya konuşma engeli olan kişiler, Yerel Bolge Ofisi ile iletisim kurma konusunda yardim almak icin Alabama Relay Servisi 711 veya 800-548-2546 (TTY) numarlarini arayabilirler. Ücretsiz dil desteği ya da diğer destek hizmetleri talep üzerine sunulabilmektedir.'
        },
        {
            LangId: 16,
            LangTye: '日本語 ',
            LangLabel: '日本語 (Japanese)',
            LangContent: '英語を話さないため、弊社への問い合わせが難しい方は、以下の連絡先情報を参照してくださいこちら</a>をクリックして地元の「国別オフィス」までご連絡ください。ろう者、難聴者または言語障害のある方は、Alabama Relay Serviceへ 711または 800-548-2546 (TTY)までお電話いただきますと、地元の国別オフィスへ連絡するためのサポートを受けることができます。ご希望に応じ、無料の言語援助やその他の支援サービスをご利用いただけます。'
        }],
    Title: 'DHR Local County Offices',
    LocalCountryOffices: [
        {
            LocalCountryName: 'AESAP',
            LocalCountryPhone: '1-800-438-2958'
        },
        {
            LocalCountryName: 'Autauga',
            LocalCountryPhone: '(334) 358-5000'
        },
        {
            LocalCountryName: 'Baldwin',
            LocalCountryPhone: '(251) 945-2400'
        },
        {
            LocalCountryName: 'Barbour',
            LocalCountryPhone: '(334) 775-2000'
        },
        {
            LocalCountryName: 'Bibb',
            LocalCountryPhone: '(205) 926-2900'
        },
        {
            LocalCountryName: 'Blount',
            LocalCountryPhone: '(205) 274-5200'
        },
        {
            LocalCountryName: 'Bullock',
            LocalCountryPhone: '(334) 738-6400'
        },
        {
            LocalCountryName: 'Butler',
            LocalCountryPhone: '(334) 382-4400'
        },
        {
            LocalCountryName: 'Calhoun',
            LocalCountryPhone: '(256) 240-2021'
        },
        {
            LocalCountryName: 'Chambers',
            LocalCountryPhone: '(334) 864-4000'
        },
        {
            LocalCountryName: 'Cherokee',
            LocalCountryPhone: '(256) 927-1440'
        },
        {
            LocalCountryName: 'Chilton',
            LocalCountryPhone: '(205) 280-2000'
        },
        {
            LocalCountryName: 'Choctaw',
            LocalCountryPhone: '(205) 459-9701'
        },
        {
            LocalCountryName: 'Clarke',
            LocalCountryPhone: '(251) 275-7001'
        },
        {
            LocalCountryName: 'Clay',
            LocalCountryPhone: '(256) 396-6800'
        },
        {
            LocalCountryName: 'Cleburne',
            LocalCountryPhone: '(256) 463-1700'
        },
        {
            LocalCountryName: 'Coffee',
            LocalCountryPhone: '(334) 348-2000'
        },
        {
            LocalCountryName: 'Colbert',
            LocalCountryPhone: '(256) 314-4900'
        },
        {
            LocalCountryName: 'Conecuh',
            LocalCountryPhone: '(251) 578-3900'
        },
        {
            LocalCountryName: 'Coosa',
            LocalCountryPhone: '(256) 377-2000'
        },
        {
            LocalCountryName: 'Covington',
            LocalCountryPhone: '(334) 427-7900'
        },
        {
            LocalCountryName: 'Crenshaw',
            LocalCountryPhone: '(334) 335-7000'
        },
        {
            LocalCountryName: 'Cullman',
            LocalCountryPhone: '(256) 737-5300'
        },
        {
            LocalCountryName: 'Dale',
            LocalCountryPhone: '(334) 445-4900'
        },
        {
            LocalCountryName: 'Dallas',
            LocalCountryPhone: '(334) 876-4100'
        },
        {
            LocalCountryName: 'DeKalb',
            LocalCountryPhone: '(256) 844-2700'
        },
        {
            LocalCountryName: 'Elmore',
            LocalCountryPhone: '(334) 514-3200'
        },
        {
            LocalCountryName: 'Escambia',
            LocalCountryPhone: '(251) 809-2000'
        },
        {
            LocalCountryName: 'Etowah',
            LocalCountryPhone: '(256) 549-4100'
        },
        {
            LocalCountryName: 'Fayette',
            LocalCountryPhone: '(205) 932-1665'
        },
        {
            LocalCountryName: 'Franklin',
            LocalCountryPhone: '(256) 331-5900'
        },
        {
            LocalCountryName: 'Geneva',
            LocalCountryPhone: '(334) 684-5800'
        },
        {
            LocalCountryName: 'Greene',
            LocalCountryPhone: '(205) 372-5000'
        },
        {
            LocalCountryName: 'Hale',
            LocalCountryPhone: '(334) 624-2583'
        },
        {
            LocalCountryName: 'Henry',
            LocalCountryPhone: '(334) 585-4100'
        },
        {
            LocalCountryName: 'Houston',
            LocalCountryPhone: '(334) 677-0400'
        },
        {
            LocalCountryName: 'Jackson',
            LocalCountryPhone: '(256) 575-6000'
        },
        {
            LocalCountryName: 'Jefferson',
            LocalCountryPhone: '(205) 423-4200'
        },
        {
            LocalCountryName: 'Jefferson-Bessemer',
            LocalCountryPhone: '(205) 744-3900'
        },
        {
            LocalCountryName: 'Lamar',
            LocalCountryPhone: '(205) 695-5000'
        },
        {
            LocalCountryName: 'Lauderdale',
            LocalCountryPhone: '(256) 765-4000'
        },
        {
            LocalCountryName: 'Lawrence',
            LocalCountryPhone: '(256) 905-3100'
        },
        {
            LocalCountryName: 'Lee',
            LocalCountryPhone: '(334) 737-7778'
        },
        {
            LocalCountryName: 'Limestone',
            LocalCountryPhone: '(256) 216-6380'
        },
        {
            LocalCountryName: 'Lowndes',
            LocalCountryPhone: '(334) 548-3800'
        },
        {
            LocalCountryName: 'Macon',
            LocalCountryPhone: '(334) 725-2100'
        },
        {
            LocalCountryName: 'Madison',
            LocalCountryPhone: '(256) 427-6000'
        },
        {
            LocalCountryName: 'Marengo',
            LocalCountryPhone: '(334) 295-2000'
        },
        {
            LocalCountryName: 'Marion',
            LocalCountryPhone: '(205) 921-6000'
        },
        {
            LocalCountryName: 'Marshall',
            LocalCountryPhone: '(256) 582-7100'
        },
        {
            LocalCountryName: 'Mobile',
            LocalCountryPhone: '(251) 450-6900'
        },
        {
            LocalCountryName: 'Monroe',
            LocalCountryPhone: '(251) 743-5900'
        },
        {
            LocalCountryName: 'Montgomery',
            LocalCountryPhone: '(334) 293-3100'
        },
        {
            LocalCountryName: 'Morgan',
            LocalCountryPhone: '(256) 301-8800'
        },
        {
            LocalCountryName: 'Perry',
            LocalCountryPhone: '(334) 683-5500'
        },
        {
            LocalCountryName: 'Pickens',
            LocalCountryPhone: '(205) 367-1500'
        },
        {
            LocalCountryName: 'Pike',
            LocalCountryPhone: '(334) 807-6120'
        },
        {
            LocalCountryName: 'Randolph',
            LocalCountryPhone: '(256) 357-3000'
        },
        {
            LocalCountryName: 'Russell',
            LocalCountryPhone: '(334) 214-5780'
        },
        {
            LocalCountryName: 'Saint Clair',
            LocalCountryPhone: '(205) 812-2100'
        },
        {
            LocalCountryName: 'Shelby',
            LocalCountryPhone: '(205) 669-3000'
        },
        {
            LocalCountryName: 'Sumter',
            LocalCountryPhone: '(205) 652-5000'
        },
        {
            LocalCountryName: 'Talladega',
            LocalCountryPhone: '(256) 761-6600'
        },
        {
            LocalCountryName: 'Tallapoosa',
            LocalCountryPhone: '(256) 825-3700'
        },
        {
            LocalCountryName: 'Tuscaloosa',
            LocalCountryPhone: '(256) 562-6100'
        },
        {
            LocalCountryName: 'Walker',
            LocalCountryPhone: '(205) 387-5400'
        },
        {
            LocalCountryName: 'Washington',
            LocalCountryPhone: '(251) 847-6100'
        },
        {
            LocalCountryName: 'Wilcox',
            LocalCountryPhone: '(334) 682-1200'
        },
        {
            LocalCountryName: 'Winston',
            LocalCountryPhone: '(205) 489-1500'
        }
    ]
}
