import React, { Component } from "react";
import PropTypes from "prop-types";

import * as DeadlineProps from "../../../constants/deadline";
import { date as formatDate } from "../../utilities/formatters";
import Icon from "../../dtaIcon";

/*
  Deadline ListItem contents
  --------------------------

  # Purpose:
  To display a deadline in a list

  # Props:
  [DeadlineProps.NAME]: Required, name of the deadline
  [DeadlineProps.URL]: Required, webcal url pointing to the calendar event
  [DeadlineProps.DATE]: Required, Date representing the deadline
  [DeadlineProps.DETAILS]: Required, string providing more details about what is due

  # Example:
  <DeadlineContents {...props} />
 */

class DeadlineContents extends Component {
  static propTypes = {
    [DeadlineProps.NAME]: PropTypes.string.isRequired,
    [DeadlineProps.URL]: PropTypes.string.isRequired,
    [DeadlineProps.DATE]: PropTypes.instanceOf(Date).isRequired,
    [DeadlineProps.DETAILS]: PropTypes.string.isRequired,
  };

  render() {
    const url = this.props[DeadlineProps.URL];
    return (
      <div>
        {/* icon decoration */}
        <div className="show-large show-slightly-larger show-extra-large dta-list-item__decoration">
          <Icon name="deadline" className="dta-list-item__icon" />
        </div>
        {/* list item heading */}
        <div className="pure-g">
          <div className="pure-u-4-5">
            <div className="text--bold text--medium">
              {this.props[DeadlineProps.NAME]}
            </div>
          </div>
          <div className="pure-u-1-5 text--right">
            <div className="show-mobile show-small show-medium">
              <Icon name="deadline" className="dta-list-item__icon" />
            </div>
            <div className="show-large show-slightly-larger show-extra-large">
              {this._buildLink(url)}
            </div>
          </div>
        </div>
        {/* spacer for small screens */}
        <div className="show-mobile show-small show-medium pure-u-1 pad-top-half" />
        {/* details */}
        <div>
          Re-Evaluation Due:{" "}
          <span className="text--bold">
            {formatDate(this.props[DeadlineProps.DATE])}
          </span>
        </div>
        <div>{this.props[DeadlineProps.DETAILS]}</div>
        <div className="show-mobile show-small show-medium margin-top-half">
          {this._buildLink(url)}
        </div>
      </div>
    );
  }

  // Rendering
  // ---------

  _buildLink(url) {
    return (
      <a
        href={url}
        className="dta-link dta-link--highlight text--underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Add to Calendar
      </a>
    );
  }
}

export default DeadlineContents;
