import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import * as formatters from "../../../utils/components/utilities/formatters";
import {
  setHouseholdMembers,
} from "../../../redux/recertification/recertificationActions";
import { expenseMedicalExpenseSections,medicalExpenseTemplate } from "../reportChangeMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { ReportChangePageLabels } from "../reportChangeText";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class MedicalExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      supportSummary: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addChildSupportMembersOpen: TextProps.VALUE_FALSE,
      isObligationChildSupportChanged: TextProps.VALUE_FALSE,
      isCourtOrderedChildSupportChanged: TextProps.VALUE_FALSE,
      subsectionCleared: TextProps.VALUE_FALSE,
      memberTemplate: null,
      summaryTemplate: null,
      valuesPopulated: TextProps.VALUE_FALSE,
      tempChildExpArray: [],
      dataQuestionChanged: null,
    };
    enableUniqueIds(this);
  }


  componentDidMount() {
  
    //   this.setInitialData();
    
  }

  setInitialData = () => {
  };

  childSupportSummaryEditClick = (section) => {
    this.setState({ supportSummary: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };

  toggleAddChildSupportMembers = () => {
    this.setState({
      addChildSupportMembersOpen: !this.state.addChildSupportMembersOpen,
    });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  renderMedicalExpensesAddNewMember = (name) => {
    var medicalTemplate = _.cloneDeep(medicalExpenseTemplate);
    var medicalExpensesCopy = expenseMedicalExpenseSections[0];
    let dataFound = TextProps.VALUE_FALSE;
    medicalExpensesCopy.subsections && medicalExpensesCopy.subsections.map((subSec) => {
      if(subSec.title === name){
        dataFound = TextProps.VALUE_TRUE
        medicalTemplate = subSec;
      }
    })
    if(!dataFound){
      if(!medicalExpensesCopy.subsections)
        medicalExpensesCopy.subsections = []
      
      medicalTemplate.id = "medical-expenses-type-" + (medicalExpensesCopy.subsections.length);
        medicalTemplate.header = ReportChangePageLabels(
        language,
        languageConstants.medicalExpenses,
        name
      ).heading;
      medicalTemplate.questions[0][Props.LABEL] = ReportChangePageLabels(
        language,
        languageConstants.medicalExpenses,
        name
      ).mainQuestion;
      // medicalTemplate.questions[0].Answer = "";
      // medicalTemplate.questions[0].value = "";
      // medicalTemplate.questions[0].targetValue = "";
      medicalTemplate.title = name;
      "summaryData" in this.props.medicalSummary && this.props.medicalSummary.summaryData.map((item) => {
        if(item.name === name){
          medicalTemplate.questions[0]['Answer'] = item.selectedOptions;
          medicalTemplate.questions[0]['value'] = item.selectedOptions;
          medicalTemplate.questions[0]['targetValue'] = item.selectedOptions;
          let currentData = {};
          item.medicalExpenses.map((exp) => {
            currentData[exp.typeCode]=[{"amount": exp.amount,"unit": exp.unit}]
          })
          medicalTemplate.subsections[0].questions.map((item1) => {
            if(item1.name in currentData){
              item1.Answer = currentData[item1.name]
              item1.targetValue = currentData[item1.name]
              item1.value = currentData[item1.name]
            }
          })
        }
      })
      medicalExpensesCopy.subsections[medicalExpensesCopy.subsections.length] = medicalTemplate;
    }
    
    this.props.updateMedicalExpenseDetails(medicalExpensesCopy);
    this.props.prepareMedicalExpensesSummary();
    this.props.onSectionChange(medicalTemplate);
  };

  getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case "Week":
      case "WEEK":
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.medicalExpenses,
          null,
        ).week;
        return shelterExpenseFrequency;
      case "Month":
      case "MONTH":
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.medicalExpenses,
          null,
        ).month;
        return shelterExpenseFrequency;
      case "Year":
      case "ANNUAL":
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.medicalExpenses,
          null,
        ).year;
        return shelterExpenseFrequency;

      default:
        break;
    }
  };

  renderSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <span
          className="interim-recert-heading pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getSummaryTitle(
                summary.name,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart,
                  null
                ).sIncomebefore,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart,
                  null
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </span>
        <div>
          <span className="interim-recert-heading apply__body-header help-text-fix">
            {
              ReportChangePageLabels(
                language,
                  languageConstants.beforeYouStart,
                  null
              ).medicalTitle
            }
          </span>
        </div>

        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
     
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.renderMedicalExpensesAddNewMember(summary.name)}
          >
            {
              ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart,
                null
              ).editButtonLabel
            }
          </button>
        
        <ul>
          
          {summary.medicalExpenses.filter(ans=>ans.label!=="REMOVE" && ans.label!=="removed").map((item,i) => {
            let label = "";
            if (item.label !== undefined) {
              if (item.label === "NEW") {
                label = "newLabel";
              } else if (item.label === "CHANGE") {
                label = "changedLabel";
              }else if (item.label === "REMOVE") {
                label = "removedLabel";
              }
            }

              let medicalExpFrequency = ReportChangePageLabels(
                language,
                languageConstants.medicalExpenses,
                null,
                ).unitsOptions.filter((frq) => frq.key === item.unit);
              let frequencyLabel = medicalExpFrequency && medicalExpFrequency.length > 0 ? medicalExpFrequency[0]['value'] : "";
              
                return item.amount && item.unit && <li key={summary.id+"_"+i}>
                    <p className={label !== "" ? "pure-u-12-24":"pure-u-1-1"}>{item.desc}</p>
                    {label !== "" ? (
                      <p class="pure-u-12-24">
                        <DataChangeIndicator
                          text={
                            ReportChangePageLabels(
                              language,
                              languageConstants.householdStatic,
                              null,
                            )[label]
                          }
                        />
                      </p>
                    ) : null}
                    <span className="pure-u-12-24">
                      {item.unit ? frequencyLabel +
                        " " +
                        "amount" : null}
                    </span>
                    <span className="pure-u-12-24">{"$" + item.amount}</span>
                  </li>
          })}
        </ul>
      </div>
    );
  };

  render() {
    var listOfUpdatedHHMembers = [];
    var existingMedicalMembers = [];
    let addChildSupportMembersActive = "";
    if(this.props.medicalSummary.summaryData){
    this.props.medicalSummary.summaryData.map((item) => {
        existingMedicalMembers.push(item.name);
    })
    this.props.consumer.consumerData.householdMembers.map((memberHH) => {
      let CombinedName = [memberHH["firstName"],memberHH["lastName"],formatters.formatDate(memberHH["dateOfBirth"])].join(' ').replace(/ +(?= )/g,'');
      if(!existingMedicalMembers.includes(CombinedName)){
        listOfUpdatedHHMembers.push(CombinedName);
      }
    });
  }

    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {"summaryData" in this.props.medicalSummary && this.props.medicalSummary.summaryData.map((summary) =>
          this.renderSummaryItem(summary)
        )}
          <div>
            <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${addChildSupportMembersActive}`}
              onClick={this.toggleAddChildSupportMembers}
              aria-expanded={this.state.addChildSupportMembersOpen}
            >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {
                ReportChangePageLabels(
                  language,
                  languageConstants.medicalExpenses,
                  null
                ).medicalExpenses_AddHouseHold
              }
            </button>
            <Collapse isOpened={this.state.addChildSupportMembersOpen}>
              <span className="add-income-summary__label" />
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingMedicalMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
                  return (
                    <li key={member.id}>
                      {/*
                      Clicking the following button routes the user to a
                      new child Income section to fill out income info for this
                      household member (e.g. "Listing John's Income"). When user
                      is finished and clicks 'Continue', they will be returned to Income Summary page
                    */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.renderMedicalExpensesAddNewMember(member);
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
                      >
                        <span className="sr-only">Add income for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
       
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setHouseholdMembers: setHouseholdMembers,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalExpenses);