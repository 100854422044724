import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
import * as Props from "../../utils/components/shared";
import Checkbox from "../../utils/components/checkbox";
import DTAInputSubmit from "../../utils/components/dtaInputSubmit";
import DTADropdown from "../../utils/components/dtaDropdown";
import { acesButtonClicked, languageChanged } from "../../redux/applySnap/applySnapActions";
import * as OptionsProps from "../../utils/constants/options";
import * as roleProps from "../../utils/constants/rolesConstants";
import { MONTHSHORT, INPUT_ALPHA_TEXT } from "../../utils/constants/controls";
import { getAudit } from "../../utils/constants/audit";
import * as formatters from "../../utils/components/utilities/formatters";
import ChildSupportExpenses from "./components/ChildSupportExpense";
import MedicalExpenses from "./components/medicalExpense";
import { ALABAMA_COUNTY_OFFICES } from "../../utils/constants/constants";
//azure
import { withMsal } from "@azure/msal-react";

// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import RecertSection from "./components/recertificationSection";
import {
  householdTemplate,
  studentDetailsTemplate,
  dependentCareTemplate,
  expenseMedicalExpenseTemplate,
  houseHoldInformationTemplate,
  expenseChildSupportTemplate,
  shelterUtilitySectionTemplate,
  utilityExpensesTemplate,
  utilityObj,
  excluedSections,
  medicalExpenseKeys,
  medicalExpenseObj,
  contactInfoSummaryTemplate,
  authRepSummaryTemplate,
  ethinicityCodeMapper,
  relationshipCodeMapper,
  raceCodeMapper,
  genderCodeMapper,
  householdSummaryTemplate,
  IncomeSectionTemplate,
  unearnedIncomeSectionTemplate,
  incomeSummaryTemplate,
  unearnedIncomeSummaryTemplate,
  resourcesSummaryTemplate,
  ResourcesSectionTemplate,
  earnedIncomeTypecode,
  unearnedIncomeTypecode,
  shelterTypecode,
  childSupportTypecode,
  resourcesTypecode,
  tanfhouseholdTemplatemain
} from "./recertificationMapData";
// Redux implementations
import { connect } from "react-redux";
import {
  recertStateRefreshSection,
  snapRefreshQuestionsSections,
  addContactInfoSubSection,
  addAuthRepSubSection,
  appendStudentetails,
  setRecertResponseObj,
  setDataChangeStatus,
  setHouseholdMembers,
  appendHouseHoldMembersRecert,
  addHouseholdAddSectionToList,
  setContactInfo,
  addStudentToList,
  recertQuestionsSections,
  // appendRecertIncomeDetails,
  appendRecertExpenseDetails,
  updateResolveQuestionsData,
  downloadPDFData,
  updateCurrentSectionData,
  postRecertData,
  saveRecertData,
  updateHouseholdTextChangesRecert,
  displayPDFData,
  addressValidation,
  applyAddressStorageAPI,
  setChildSupportData,
  setShelterOptions,
  setMedicalExpensesChanged,
  setDataChangeStatusForExpense,
  setShelterUtilityChangeStatus,
  setObligationChildSupportChangeStatus,
  setCourtOrderedChildSupportChangeStatus,
  setGrossEarnedIncomeChangeStatus,
  setEarnedIncomeChangeStatus,
  setHouseholdResourcesChangedStatus,
  setShelterData,
  appendShelterFilteredQuestions,
  saveandExitAction,
  deleteRecertApplication,
  appendRecertDataForSummary,
  appendIRIncomeDetails,
  appendResourcesDetails,
  clearMemberIncome,
  clearMemberResources,
  clearMemberUIIncome,
  setUnearnedIncomeChangeStatus,
  appendUIIncomeDetails,
  setShelterDefaultQuestions,
  saveRecretDataAction,
  setAuthRepResponseBodyData,
  setAuthRepChangeStatus,
  setTanfResponseBodyData
} from "../../redux/recertification/recertificationActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import {
  toValidateInputErros,
  filterQuestionsBySelection,
  bindAnswers,
  ImmediateNeeds,
  addressValidationObj,
  appendUpdatedAdrress,
  setContactInitData,
  setAddressValidatedToContactInfoHelper,
  incomeSubsectionRenderHelper,
  resourcesSubsectionRenderHelper,
  onUnearnedIncomeSubsectionRenderHelper,
  onChildSupportSubsectionRenderHelper,
  getShelterExpenseFrequency,
  prepareDataForSubmissionHelper,
  checkForAddressChange,
  convertDOBForDataPost,
  getMemberDataObjectByNameAndDOB,
  returnMemberId,
  returnAgencyID,
  filterData
} from "./recertificationHelper";
import {
  recertWarningPopupMLanguageLabels,
  childDependnMultilanguageLabels,
  recertificationPageLabels,
  termsLanguageTranslation,
} from "./recertificationText";
import { getMainSections } from "./components/recertificationSummary";

import * as languageConstants from "../../utils/constants/constants";
import * as TextProps from "../../utils/constants/text";
//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import StartForm from "../../utils/components/startForm/beforeYouStart";
import ContactInfo from "../../utils/components/contactInfo";
import HouseholdInfo from "../../utils/components/householdInfo";
import NonCitizen from "./components/nonCitizen";

import SubmitSummary from "./components/submitSummary";
import TermsRecert from "../../utils/components/termsrecert";
import {
  getFrequency,
  getFrequencyCode,
  getShelterandChildSupportFrequency,
} from "../../utils/components/utilities/utils";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as types from "../../utils/constants/types";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import Icon from "../../utils/components/dtaIcon";
import DocumentTitle from "react-document-title";
import * as config from "../../utils/constants/appConfigConstants";
import {
  beforeYoustartMlanguageLabels,
  incomeSectionMultilanguageLabels,
} from "../applySnap/applySnapText";
import Modal from "../../utils/components/modal/dtaModal";
import Radio from "../../utils/components/radio";
import ShelterExpenses from "./components/ShelterExpenses";
import {
  formAddressDisplayValue,
  formatAddressDisplay,
  checkIfAddressIsUpdated,
  todaysDateOrTime,
} from "../applySnap/applySnapHelper";
// import { incomeDynamicMultilanguageLabels } from "../recertCovid/recertCovidText";
import RecertSummaryContainer from "./components/RecertSummaryContainer";
import EarnedIncome from "./components/earnedIncome";
import ResourcesSummary from "./components/resourcesSummary";
import {
  incomeDynamicMultilanguageLabels,
  interimReportPageLabels,
  unearnedIncomeDynamicMultilanguageLabels,
  interimWarningPopupMLanguageLabels

} from "../recertInterimReport/recertInterimReportText";
import { earnedIncomeCodeMapper } from "./earnedIncomeMapData";
import UnearnedIncome from "./components/unearnedIncome";
import { unearnedIncomeCodeMapper } from "./unearnedIncomemapData";
import RecertApplicationEN from "../../utils/resources/pdfs/Recert-ApplicationEN.pdf";
import RecertApplicationES from "../../utils/resources/pdfs/Recert-ApplicationES.pdf";
import AuthRep from "./components/AuthRep";
import AddAuthRep from "./components/AddAuthRep";
import * as ControlConstants from "../../utils/constants/controls";
import * as appConfigConstants from "../../../src/utils/constants/appConfigConstants";
import HouseholdInfoTanf from "./components/householdInfo";

var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);
//end code for google analytics
var rights_en_url =
  require("../../utils/constants/appConfigConstants").rights_en_url;
var rights_es_url =
  require("../../utils/constants/appConfigConstants").rights_es_url;
var rights_pt_url =
  require("../../utils/constants/appConfigConstants").rights_pt_url;
var rights_vi_url =
  require("../../utils/constants/appConfigConstants").rights_vi_url;
var rights_zh_url =
  require("../../utils/constants/appConfigConstants").rights_zh_url;

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

// For tesing resume apply feautre
var resumeApply = TextProps.VALUE_FALSE;

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class Recertification extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.renderedMedicalSectionsCopy = "";
    this.housingCostsSubSections = [];
    this.dependentsCareCostsSubSections = [];
    this.providerAddressTemplate = [];
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.acceptTermsConditionsAgree = this.acceptTermsConditionsAgree.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onIncomeSubsectionRender = this.onIncomeSubsectionRender.bind(this);
    this.onResourcesSubsectionRender = this.onResourcesSubsectionRender.bind(this);
    this.onUnearnedIncomeSubsectionRender =
      this.onUnearnedIncomeSubsectionRender.bind(this);
    this.downloadApplicationAsPdf = this.downloadApplicationAsPdf.bind(this);
    this.designPdfAsHTMLTemplate = this.designPdfAsHTMLTemplate.bind(this);
    this.onSignSubmitActionClick = this.onSignSubmitActionClick.bind(this);
    this.downloadPDFData = this.downloadPDFData.bind(this);
    this.preLoadData = this.preLoadData.bind(this);
    this.incomeSummary = {};
    this.resourcesSummary = {};
    this.shelterSummary = {};
    this.medicalSummary = {};
    this.incomeSubSections = [];
    this.resourcesSubSections = [];
    this.unearnedIncomeSubSections = [];
    this.state = {
        isHOHRemoved: false,
        hOHRemovedSections: [],
        tanfRedetermination: TextProps.VALUE_FALSE,
        ebtQuestionRemoved: TextProps.VALUE_FALSE,
        languageChangeWarning:TextProps.VALUE_FALSE,
        snapQuestionRemoved: TextProps.VALUE_FALSE,
        signedNameNotMatch: TextProps.VALUE_FALSE,
        ebtAuthRepFlg: this.props.recert.responseObject ? this.props.recert.responseObject.ebtAuthRepFlg : null,
        snapAuthRepFlg: this.props.recert.responseObject ? this.props.recert.responseObject.snapAuthRepFlg : null,
            authorizedRepresentativesResponse: null,
            authRepDataChanged: TextProps.VALUE_FALSE,
            flags: {
            },
            agreeError: "",
            ebtPhoneValid: TextProps.VALUE_FALSE,
            snpPhoneValid: TextProps.VALUE_FALSE,
            addAuthRepData: {
              ebtFrstNam : "",
              ebtLstNam : "",
              ebtMdlNam : "",
              ebtPhNum : "",
              ebtSuffix : "IV",
              ebtWebDataChangeCode : null,
              signedName : "",
              snpFrstNam : "",
              snpLstNam : "",
              snpMdlNam : "",
              snpPhNum : "",
              snpSuffix : "I",
              snpWebDataChangeCode : null
            },
            householdInformation: {
              registerVoteFlg:"",
              changeAddressFlg:"",
              noRegisterToVoteFlg:"",
              hurtFlg: "",
              hurtingNowFlg:"",
              afraidOfFlg:"",
              otherInfoFlg:"",
              comments:"",
              domesticRefFlg: "",
            },
      referenceNumber: null,
      docReferenceNumber: null,
      agencyID: null,
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      houseHoldSubMemberTemplate: tanfhouseholdTemplatemain,
      studentDetailsTemplate: studentDetailsTemplate,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: incomeSummaryTemplate,
      incomeSubSectionCleared: TextProps.VALUE_FALSE,
      resourcesSubSectionCleared: TextProps.VALUE_FALSE,
      resourcesMemberTemplate: null,
      resourcesSummaryTemplate:resourcesSummaryTemplate,
      acesButtonClickWarningShowModal: TextProps.VALUE_FALSE,
      acesButtonClickWarningRedirectPage: TextProps.VALUE_FALSE,
      houseMemberLabels: [],
      dependentsParentsLabels: [],
      dependentsChildsLabels: [],
      dependentsCareCostsMemberLabels: [],
      dependentCareCostsTemplate: null,
      dependentCareCostsSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseMemberTemplate: null,
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      resourcesSummaryEdit: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      rights: 0,
      rightsAdd: 0,
      signature: "",
      AnswerObject: [],
      oldHouseHoldSizeValue: 0,
      sectionDataUpdated: TextProps.VALUE_FALSE,
      authRepDataUpdated: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      requestBody: {},
      respData: null,
      agreeErrorAdd: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      addressValidationModalOpen: TextProps.VALUE_FALSE,
      enteredResAddressValue: "",
      selectedResAdressValue: "",
      enteredMailingAddressValue: "",
      selectedMailingAddressValue: "",
      addressObj: {},
      finalResValueSelected: "",
      finalMailingValueSelected: "",
      mailingAddressFlag: TextProps.VALUE_FALSE,
      resAddressFlag: TextProps.VALUE_FALSE,
      MelissaApiResponded: true,
      addressDatasections: {},
      incomeSummary: {},
      saveExitShowModal: TextProps.VALUE_FALSE,
      deleteWarningShowModal: TextProps.VALUE_FALSE,
      incomeValuesPopulated: TextProps.VALUE_FALSE,
      resourcesValuesPopulated: TextProps.VALUE_FALSE,
      unearnedIncomeMemberTemplate: null,
      unearnedIncomeSummaryTemplate: unearnedIncomeSummaryTemplate,
      unearnedIncomeSubSectionCleared: TextProps.VALUE_FALSE,
      incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE,
      incomeUIValuesPopulated: TextProps.VALUE_FALSE,
      recert_type: "",
      displayExpeditedFlg: TextProps.VALUE_FALSE,
      saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      removeDependentCareIfOneHousehold: TextProps.VALUE_FALSE,
      addAuthRepSaveandContinue: TextProps.VALUE_FALSE,
      additionalhouseholdInformation: false,
      isHomelessModalOpen: TextProps.VALUE_FALSE,
      setHomelessModalOpen: TextProps.VALUE_FALSE
      // incomeSummary: {},
    };
    this.incomefilterQuestionsBySelection =
      this.incomefilterQuestionsBySelection.bind(this);
    this.resourcesfilterQuestionsBySelection =
      this.resourcesfilterQuestionsBySelection.bind(this);
    this.updateHouseHoldIncomeDetails =
      this.updateHouseHoldIncomeDetails.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount = () => {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var recertType = sessionStorage.getItem('recertType');
    var displayExpeditedFlg = userDetails.benefitInfo.filter(item => item.alertMessages.RECERT_TYPE === recertType && item.alertMessages.displayExpeditedFlg === true);
    if (recertType) {
      this.setState({
        recert_type: recertType,
        displayExpeditedFlg: displayExpeditedFlg.length ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
      });
    }

    var recertFlag, benifitListInfo;
    var allowedFlags = ["RECERT","AESAP","IR","REEVAL"];
    if (
      userObject !== null &&
      userObject !== undefined &&
      userDetails !== null &&
      userDetails !== undefined
    ) {
      benifitListInfo = userDetails.benefitInfo;
      if (userObject && benifitListInfo) {
        for (let listType of benifitListInfo) {
          if (allowedFlags.includes(listType.alertMessages.RECERT_TYPE)) {
            recertFlag = "TextProps.VALUE_YES";
          }
        }
        var userRole = userObject.roleList[1]
          ? userObject.roleList[1].roleName
          : userObject.roleList[0].roleName;
      }
    }
    if (
      (recertFlag === "TextProps.VALUE_YES") &&
      (userRole === roleProps.CONSUMER ||
        userRole === roleProps.PROVIDER ||
        userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
        userRole === roleProps.PROVIDER_ROLE_DCPVSG) &&
        this.props.recert.responseObject
    ) {
      //continue
    } else {
      this.props.gotoConsumer();
    }
  };

  componentWillReceiveProps(nextProps) {
    let activeSectionId =
      this.props.recert.additionalProperties &&
      this.props.recert.additionalProperties.activeSectionId
        ? this.props.recert.additionalProperties.activeSectionId
        : pageConfig.activeSectionId;
    if (this.props.recert.sections.length > 0) {
      this.setState(
        {
          sections: this.props.recert.sections,
          respData: this.props.recert.responseObject,
        },
        () => {
          if (this.state.renderedSections.length < 1) {
            this.setState(
              {
                renderedSections: [
                  this._getSection(pageConfig.activeSectionId),
                ],
                submitFormId: this.nextUniqueId(),
                discontinuedSection: activeSectionId,
                activeSectionId: pageConfig.activeSectionId,
              },
              () => {
                if (
                  activeSectionId &&
                  activeSectionId !== "before-you-start" &&
                  !this.state.preLoadDone
                ) {
                  this.preLoadData();
                }
              }
            );
          }
        }
      );
    }
    if (
      nextProps.apply.isAcesButtonClicked != undefined &&
      nextProps.apply.isAcesButtonClicked === true
    ) {
      if (this.state.sectionDataUpdated) {
        this.setState({
          acesButtonClickWarningShowModal: TextProps.VALUE_TRUE,
        });
      } else {
        this.redirectToHomePage();
      }
    }
      if (
        nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
        nextProps.apply.islanguageChanged === true
      ) {
          this.setState({
            languageChangeWarning: TextProps.VALUE_TRUE,
          });
        }
  }

  //Function to handle aces button close popup actions(close/cancel)
  closeAcesButtonClickWarningModal = (status, isClose, event) => {
    if (status) {
      sessionStorage.setItem("acesButtonClickedFlag", true);
      this.onPrimaryActionClick(event, TextProps.SAVEEXIT);
    }
    this.setState({
      acesButtonClickWarningShowModal: TextProps.VALUE_FALSE,
      acesButtonClickWarningRedirectPage: TextProps.VALUE_TRUE,
    });
    if (isClose) {
      sessionStorage.setItem("acesButtonClickedFlag", false);
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
        authRepDataUpdated: TextProps.VALUE_FALSE,
        authRepDataChanged: TextProps.VALUE_FALSE
      });
    }
    this.props.acesButtonClicked({ value: false, path: "" });
  };
  // Render a isHomeless Modal
  renderIsHomelessModal = () => {
    return (
      <Modal
        isOpen={this.state.isHomelessModalOpen}
        onClose={() =>
          this.closeRenderIsHomelessModal()
        }
        modalClass="homeless-modal"
        titleText="homeless Warning"
        headerText={
          beforeYoustartMlanguageLabels(language).isHomelessHouseholdheader
        }
      >
      <div className="dta-modal__body pad-all">
        <div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContentSR[0].line1}</div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContentSR[1].line2} <a target="_blank"  href={ALABAMA_COUNTY_OFFICES}>here</a></div>
         <div className="text--small">{beforeYoustartMlanguageLabels(language).isHomelessHouseholdContentSR[2].line3}</div>
        </div>
        <p> </p>
        <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeRenderIsHomelessModal()
              }
            >
            {beforeYoustartMlanguageLabels(language).okLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  closeRenderIsHomelessModal = () => {
    this.setState({isHomelessModalOpen : TextProps.VALUE_FALSE});
  }
  //Render A DHR modal for ACES button click updated info
  renderACESButtonClickWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.acesButtonClickWarningShowModal}
        onClose={() =>
          this.closeAcesButtonClickWarningModal(TextProps.VALUE_FALSE, true)
        }
        modalClass="save-exit-modal"
        titleText="save or exit Warning"
        headerText={
          recertWarningPopupMLanguageLabels(language).areYouSureWarningLabel
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {this.state.recert_type === "REEVAL" ? recertWarningPopupMLanguageLabels(language).savetanfOrExitLabel : recertWarningPopupMLanguageLabels(language).saveOrExitLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeAcesButtonClickWarningModal(
                  TextProps.VALUE_FALSE,
                  false,
                  event
                )
              }
            >
              {recertWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeAcesButtonClickWarningModal(
                  TextProps.VALUE_TRUE,
                  false,
                  event
                )
              }
            >
              {recertWarningPopupMLanguageLabels(language).saveAndExit}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  renderLanguageSwitchWarningModal = () => {

    return (
      <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
      <Modal
        isOpen={this.state.languageChangeWarning}
        onClose={() =>{this.props.languageChanged({ isCancel: true}),
        this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={recertWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
        >
        <div className="dta-modal__body pad-all">
          {/* <p className="text--small">
         {recertWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
          </p> */}
          <p className="text--small"> 
          {recertWarningPopupMLanguageLabels(language).languageWarningLabel}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>{
                this.props.languageChanged({ isCancel: true})
                this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}
              }
            >
             {recertWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => {
                this.closeDeleteWarningModal(TextProps.VALUE_TRUE),
                this.props.languageChanged({ isDelete: true})}}
            >
            {recertWarningPopupMLanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      </div>
    );
  };
  //Function to handle Save or Exit popup actions(close/cancel)
  closeSaveOrExitWarningModal = (status, isClose, event) => {
    if (status) {
      this.onPrimaryActionClick(event, TextProps.BACK);
    }
    this.setState({
      saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      saveORExitWarningRedirectPage: TextProps.VALUE_TRUE,
    });
    if (!isClose) {
      this.autoClearSelection();
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
        authRepDataUpdated: TextProps.VALUE_FALSE,
        authRepDataChanged: TextProps.VALUE_FALSE
      });
      this.goBackHandler();
    }
  };
  //Function to handle Save&Exit popup actions(save)
  onSaveOrExitClick = (event) => {
    this.setState({ saveOrExitWarningShowModal: TextProps.VALUE_TRUE });
    // alert("onDeleteClick");
  };
  //Render A DHR modal for Save or Exit updated info
  renderSaveOrExitWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.saveOrExitWarningShowModal}
        onClose={() =>
          this.closeSaveOrExitWarningModal(TextProps.VALUE_FALSE, true)
        }
        modalClass="save-exit-modal"
        titleText="save or exit Warning"
        headerText={
          beforeYoustartMlanguageLabels(language).areYouSureWarningLabel
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {this.state.recert_type === "REEVAL" ? beforeYoustartMlanguageLabels(language).tanfSaveOrExitLabel : beforeYoustartMlanguageLabels(language).saveOrExitLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeSaveOrExitWarningModal(
                  TextProps.VALUE_FALSE,
                  false,
                  event
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeSaveOrExitWarningModal(
                  TextProps.VALUE_TRUE,
                  true,
                  event
                )
              }
            >
              {beforeYoustartMlanguageLabels(language).saveLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  // redux apply sections loading
  async componentDidMount() {
    await this.props.recertQuestionsSections(this.state.displayExpeditedFlg);

    //expedited snap services section
    this.setExpeditedSnapServiceSection();
    if (
      this.props.recert.householdMembers &&
      this.props.recert.householdMembers.membersList !== undefined
    ) {
      if(this.state.recert_type !== "IR" && this.state.recert_type !== "REEVAL"){
      this.renderCaringKidsAdults();
       this.removeDependentCareIfOneHousehold();
       this.onChildSupportSubsectionRender();
      }
      this.updateHouseHoldIncomeDetails();
    }else if(this.props.recert.responseObject && this.props.recert.responseObject.householdMembers){
      this.props.setHouseholdMembers({
        membersListUpdated: null,
        addHouseholdMember: TextProps.VALUE_FALSE,
        membersList: _.cloneDeep(
          this.props.recert.responseObject.householdMembers
        ),
      });
    }
    //set completed flag
    if (
      this.props.recert.responseObject &&
      this.props.recert.responseObject.additionalProperties
    ) {
      if (
        this.props.recert.responseObject.additionalProperties.householdMembers
      ) {
        this._renderHouseholdInfo();
      }
      if(this.state.recert_type !== "REEVAL"){
        if (
          this.props.recert.responseObject.additionalProperties.studentsInfo &&
          this.props.recert.responseObject.additionalProperties.studentsInfo !==
            null
        ) {
          this.setStudentsInfo(
            this.props.recert.responseObject.additionalProperties.studentsInfo
          );
          this.updateHouseHoldIncomeDetails();
        }
        if (
          this.props.recert.responseObject.additionalProperties
            .Dependentcarecosts &&
          this.props.recert.responseObject.additionalProperties.Dependentcarecosts
            .length
        ) {
          this.setDependentcarecosts(
            this.props.recert.responseObject.additionalProperties
              .Dependentcarecosts
          );
          this.renderDependentsCareCosts();
          if (this.props.recert.responseObject.additionalProperties.answerObjectDependentCare && this.props.recert.responseObject.additionalProperties.answerObjectDependentCare.length){
            let AnswerObjectCopy = this.state.AnswerObject;
            this.props.recert.responseObject.additionalProperties.answerObjectDependentCare.map(
              (dependent) => {
                for (const [key, value] of Object.entries(dependent)) {
                  AnswerObjectCopy[key] = value;
                }
              }
            );
            this.setState({ AnswerObject: AnswerObjectCopy });
          }
          this.onChildSupportSubsectionRender();
        }
      }else{
        if (
          this.props.recert.responseObject.additionalProperties.additionalhouseholdInformation
        ) {
          this.setCompletedFlag("tanf-household-information");
          this.setState({additionalhouseholdInformation:true});
        }
      }
    }
    if (
      this.props.recert.responseObject &&
      this.props.recert.responseObject.webHouseholdInfo &&
      this.props.recert.responseObject.webHouseholdInfo.length
    ) {
      this.setWebHouseholdInfo(
        this.props.recert.responseObject.webHouseholdInfo
      );
    }
          if(this.props.recert.authorizedRepresentatives === null || this.props.recert.authorizedRepresentatives === undefined) {
        this.initializedAuthRepArray();
      }
      else {
          this.state.addAuthRepData = this.props.recert.authorizedRepresentatives;
      }
      
      if(this.props.recert.householdInformation === null || this.props.recert.householdInformation === undefined) {
        if(this.props.recert.responseObject) {
          if(this.props.recert.responseObject.householdInformation) {
            this.state.householdInformation = this.props.recert.responseObject.householdInformation;
            this.props.setTanfResponseBodyData(this.props.recert.responseObject.householdInformation);
          }
          else {
            this.props.setTanfResponseBodyData(this.state.householdInformation);
        }
        }  
      }
      else {
          this.state.householdInformation = this.props.recert.responseObject.householdInformation;
        }
    setTimeout(() => {
      // Remove Student sections for AESAP
      if (this.state.recert_type === "AESAP") {
        this.removeSpecificSection("students");
        if (this.props.recert && this.props.recert.sections) {
          filterData(
            this.props.recert.sections,
            "before-you-start"
          ).header = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).aesapHeading;
          filterData(
            this.props.recert.sections,
            "before-you-start"
          ).title = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).aesapHeading;
          filterData(
            filterData(this.props.recert.sections, "sign-and-submit")
              .subsections,
            "submitted"
          ).header = recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).submitSummaryAESAPHeader;
        }
      } else if(this.state.recert_type === "IR"){
        if (this.props.recert && this.props.recert.sections) {
          this.removeSpecificSection('household-information');
          this.removeSpecificSection('auth-rep');
          this.removeSpecificSection('resources-summary');
          this.removeSpecificSection('students');
          filterData(
            this.props.recert.sections,
            "before-you-start"
          ).title = interimReportPageLabels(language, languageConstants.beforeYouStart).howApplyLabel;
          filterData(
            this.props.recert.sections,
            "before-you-start"
          ).header = interimReportPageLabels(language, languageConstants.beforeYouStart).heading;
          let isExpenseSectionExists = filterData(this.props.recert.sections, "expenses");
          if(isExpenseSectionExists&& 
            isExpenseSectionExists.subsections &&
            isExpenseSectionExists.subsections.length > 0){
            let expenseSectionIndex = this.props.recert.sections.findIndex(section => section.id === "expenses");  
            let childSupportSection = filterData(
              filterData(this.props.recert.sections, "expenses").subsections,
              "childsupport-expenses"        
             );
             let shelterUtilitySection  = filterData(
               filterData(this.props.recert.sections, "expenses").subsections,
               "shelter-utility-summary"        
              );
            let addedSections = [childSupportSection, shelterUtilitySection];
            this.props.recert.sections.splice(expenseSectionIndex, 1, ...addedSections);
            filterData(
              filterData(this.props.recert.sections, "sign-and-submit")
                .subsections,
              "submitted"
            ).header = interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).submitLabelFinal;
            this.onChildSupportSubsectionRender();
          }
        }
      }else if (this.state.recert_type === "REEVAL") {
        if (this.props.recert && this.props.recert.sections) {
          this.removeSpecificSection("students");
          this.removeSpecificSection("resources-summary");
          this.removeSpecificSection("expenses");
          this.removeSpecificSection("expedited-snap");
          this.removeSpecificSection("auth-rep");
          this.removeSpecificSection("household-information");
          filterData(this.props.recert.sections, "before-you-start").title = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).tanfHeading;
          filterData(this.props.recert.sections, "before-you-start").header = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).tanfHeading;
          filterData(this.props.recert.sections, 'income-summary').header = recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).employmentHeader;
          filterData(this.props.recert.sections, 'income-summary').title = recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).employmentTitle;
          filterData(
            filterData(this.props.recert.sections, "sign-and-submit")
              .subsections,
            "submitted"
          ).header = recertificationPageLabels(
            language,
            null,
            languageConstants.incomeExpensesRecert
          ).submitSummaryTANFHeader;
        }
      }
      if (this.props.recert && this.props.recert.sections) {
        if (this.state.recert_type !== "REEVAL") {
          this.removeSpecificSection("tanf-household-information");
          this.removeNonCitizenNoMem();
        }
        this.props.snapRefreshQuestionsSections(this.props.recert.sections);
      }
      if (
        this.props.recert.responseObject &&
        this.props.recert.responseObject.additionalProperties
      ) {
        if (
          this.props.recert.additionalProperties &&
          this.props.recert.additionalProperties.activeSectionId
        ) {
          if (
            this.props.recert.additionalProperties.hasOwnProperty(
              "shelterUtilityConfirmationChanged"
            ) &&
            this.props.recert.additionalProperties
              .shelterUtilityConfirmationChanged !== null
          ) {
            this.shelterSubsectionRender();
            this.prepareShelterSummary();
            this.shelterQuestionsBySelection();
            this.props.setShelterOptions(
              this.props.recert.responseObject.additionalProperties
                .shelterUtilityConfirmationChanged
            );
           
            const shelterUtilSectionIndex = this.state.recert_type === "IR" ?
              this.props.recert.sections.indexOf(
                filterData(this.props.recert.sections, "shelter-utility-summary")
              )
              :  filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.indexOf(
                filterData(
                  filterData(this.props.recert.sections, "expenses").subsections,
                 "shelter-utility-summary"
                )
              );
              
            this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, shelterUtilSectionIndex);
          }
          if (
            this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "medicalExpensesSummaryChanged"
            ) &&
            this.props.recert.additionalProperties
              .medicalExpensesSummaryChanged !== null
          ) {
            this.props.setMedicalExpensesChanged(
              this.props.recert.responseObject.additionalProperties
                .medicalExpensesSummaryChanged
            );
            this.prepareMedicalExpensesSummary();
            this.renderMedicalExpensesSubSections();
            const medicalExpenseIndex = filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.indexOf(
                filterData(
                  filterData(this.props.recert.sections, "expenses").subsections,
                 "medical-expenses"
                )
              );
            this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, medicalExpenseIndex);
          }
          if ((this.props.recert.responseObject.additionalProperties.hasOwnProperty(
            "courtOrderedChildSupportChanged"
          ) &&
          this.props.recert.responseObject.additionalProperties
            .courtOrderedChildSupportChanged !== null) || (this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "obligationChildSupportChanged"
            ) &&
            this.props.recert.responseObject.additionalProperties
              .obligationChildSupportChanged !== null)) {

            const childSupportSectionIndex = this.state.recert_type === "IR" ?
            this.props.recert.sections.indexOf(
              filterData(this.props.recert.sections, "childsupport-expenses")
            )
            :  filterData(
              this.props.recert.sections,
              "expenses"
            ).subsections.indexOf(
              filterData(
                filterData(this.props.recert.sections, "expenses").subsections,
                "childsupport-expenses"
              )
            );
            this.props.setDataChangeStatusForExpense(TextProps.VALUE_TRUE, childSupportSectionIndex);
            this.props.setObligationChildSupportChangeStatus(this.props.recert.responseObject.additionalProperties.obligationChildSupportChanged);
            this.props.setCourtOrderedChildSupportChangeStatus(this.props.recert.responseObject.additionalProperties.courtOrderedChildSupportChanged);
            this.onChildSupportSubsectionRender();
            this.prepareChildSupportSummary();
          }
          
          if (
            (this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "householdResourcesChanged"
            ) &&
              this.props.recert.responseObject.additionalProperties
                .householdResourcesChanged !== null) 
          ) {
            this.setCompletedFlag("resources-summary");
            this.onResourcesSubSectionClean();
            this.onResourcesSubsectionRender();
            this.prepareResourcesSummary();
            this.props.setHouseholdResourcesChangedStatus(
              this.props.recert.responseObject.additionalProperties
                .householdResourcesChanged
            );
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
              this.filterData(this.props.recert.sections, "resources-summary")
            ));
            if (
              this.props.recert.additionalProperties.activeSectionId.includes(
                "combined-resources-subsection"
              )
            ) {
              var sectionIndex =
                this.props.recert.additionalProperties.activeSectionId
                  .split("-")
                  .slice(-1);
              this.resourcesfilterQuestionsBySelection(sectionIndex);
            }
          }
          if (this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "authRepChanged"
            )){
              this.props.setAuthRepChangeStatus(this.props.recert.responseObject.additionalProperties.authRepChanged);
              this.setState({authRepDataChanged: this.props.recert.responseObject.additionalProperties.authRepChanged === "Y" ? true : false});
            }
          if (
            (this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "earnedIncomeChanged"
            ) &&
              this.props.recert.responseObject.additionalProperties
                .earnedIncomeChanged !== null) ||
            (this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "grossEarnedIncomeChanged"
            ) &&
              this.props.recert.responseObject.additionalProperties
                .grossEarnedIncomeChanged !== null)
          ) {
            this.setCompletedFlag("income-summary");
            this.onIncomeSubSectionClean();
            this.onIncomeSubsectionRender();
            this.prepareIncomeSummary();
            this.props.setGrossEarnedIncomeChangeStatus(
              this.props.recert.responseObject.additionalProperties
                .grossEarnedIncomeChanged
            );
            this.props.setEarnedIncomeChangeStatus(
              this.props.recert.responseObject.additionalProperties
                .earnedIncomeChanged
            );
            let { sections } = this.props.recert;
            let earnedIncomeIndex = sections && sections.length > 0 && sections.indexOf(
              filterData(sections, "income-summary")
              );
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, earnedIncomeIndex);
            if (
              this.props.recert.additionalProperties.activeSectionId.includes(
                "combined-income-subsection"
              )
            ) {
              var sectionIndex =
                this.props.recert.additionalProperties.activeSectionId
                  .split("-")
                  .slice(-1);
              this.incomefilterQuestionsBySelection(sectionIndex);
            }
          }
          if (
            this.props.recert.responseObject.additionalProperties.hasOwnProperty(
              "unearnedIncomeChanged"
            ) &&
            this.props.recert.responseObject.additionalProperties
              .unearnedIncomeChanged !== null
          ) {
            this.setCompletedFlag("unearned-income-summary");
            this.onUnearnedIncomeSubSectionClean();
            this.onUnearnedIncomeSubsectionRender();
            this.prepareUnearnedIncomeSummary();
            this.props.setUnearnedIncomeChangeStatus(
              this.props.recert.responseObject.additionalProperties
                .unearnedIncomeChanged
            );
            let { sections } = this.props.recert;
            let unearnedIncomeIndex = sections && sections.length > 0 && sections.indexOf(
              filterData(sections, "unearned-income-summary")
              );
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, unearnedIncomeIndex);
            if (
              this.props.recert.additionalProperties.activeSectionId.includes(
                "unearned-combined-income-subsection"
              )
            ) {
              var sectionIndex =
                this.props.recert.additionalProperties.activeSectionId
                  .split("-")
                  .slice(-1);
              this.incomeUnearnedfilterQuestionsBySelection(sectionIndex);
            }
          }
          this._renderCurrentSection(
            this.props.recert.additionalProperties.activeSectionId
          );
        }
        if(this.props.recert.responseObject.additionalProperties.hOHRemovedSections && this.props.recert.responseObject.additionalProperties.hOHRemovedSections.length){
          this.onRemovedHOH(true);
          this.setState({
            hOHRemovedSections: this.props.recert.responseObject.additionalProperties.hOHRemovedSections
          });
        }
      }
    }, 100);

    this.updateDimensions();
    window.addEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }
  updateDimensions() {
    let mainElems = document.getElementsByClassName("apply__content");
    let applyCont = document.getElementsByClassName("apply_free_comm");
    let appHeight = document.getElementsByClassName("app");
    let bodyTag = document.getElementsByTagName("body");
    let modalHeight = document.getElementsByClassName("dta-modal");

    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    var contentHeight = 0;
    var applyfreeCommHeight = 0;
    var modalPopupHeight = 0;

    if (bodyTag) {
      for (let elems of bodyTag) {
        if (elems.style) {
          elems.style.height = "auto";
        }
      }
    }

    if (isFreeComOpen === "false") {
      let applyCont = document.getElementsByClassName("apply_free_comm");
      let appHeight = document.getElementsByClassName("app");
      var applyfreeCommHeight = 0;

      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${applyfreeCommHeight}px`;
          }
        }
      }
    } else {
      if (mainElems) {
        for (let elems of mainElems) {
          contentHeight = elems.scrollHeight;
        }
      }
      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (modalHeight) {
        for (let elems of modalHeight) {
          modalPopupHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            var bodySectionHeight = contentHeight + applyfreeCommHeight + modalPopupHeight;
            if(bodySectionHeight < window.innerHeight){
              bodySectionHeight = window.innerHeight;
            }
            elems.style.height = `${
              bodySectionHeight
            }px`;
          }
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      helperFunction._animatedScroll();
      window.scrollTo(0, 0);
    }
    this.updateDimensions();
    if(this.props.recert.responseObject && this.props.recert.responseObject.additionalProperties && "completedSections" in this.props.recert.responseObject.additionalProperties && this.props.recert.responseObject.additionalProperties.completedSections.length){
      this.updateCompletedBulk(this.props.recert.responseObject.additionalProperties.completedSections, this.props.recert.sections)
    }
  }

  preLoadData = async () => {
    let finalSummary = this.props.recert.summaryData
      ? this.props.recert.summaryData
      : [];
    await this.onResourcesSubSectionClean();
    await this.onResourcesSubsectionRender();
    await this.onIncomeSubSectionClean();
    await this.onIncomeSubsectionRender();
    await this.onUnearnedIncomeSubSectionClean();
    await this.onUnearnedIncomeSubsectionRender();

    await this.buildSummary(this.props.recert.sections, finalSummary);
    this.setState({ preLoadDone: true });
  };

  checkDataOnSectionChange = (section, primaryButtonClick, event) => {
    if (this.state.sectionDataUpdated) {
      this.autoClearSelection();
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
        authRepDataUpdated: TextProps.VALUE_FALSE,
        authRepDataChanged: TextProps.VALUE_FALSE
      });
    }
    this.onSectionChange(section, primaryButtonClick, event);
  };

  onSectionChange = async (
    section,
    primaryButtonClick = TextProps.VALUE_FALSE,
    event = null
  ) => {
    if (event) {
      event.preventDefault();
    }
    let previousSectionId = this.state.activeSectionId;
    if (primaryButtonClick === TextProps.VALUE_TRUE && previousSectionId === section.id) {
      //Check if user has changed any info on any section
      // let dataChange = TextProps.VALUE_TRUE;
      // if(section.id !== "shelter-utility-summary"){
      let dataChange = await this.checkForDataChange(section.id);
      // }
      //If the user has not changed any info then simply return TextProps.VALUE_FALSE to stay on the same section
      if (dataChange === TextProps.VALUE_FALSE) {
        this.forceUpdate();
        return;
      }
    }
    if (section.disabled === TextProps.VALUE_TRUE) return;
    let renderedSectionsCopy = [];
    let incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);
    if (
      section.id == "expenses" &&
      section.subsections.filter((sub) => sub.id === "Dependentcarecosts")
        .length
    )
      this.dependentsCareCostsSubSections[0] = undefined;
    let renderedSectionsCopyFilter = renderedSectionsCopy.filter(
      (ele, ind) =>
        ind ===
        renderedSectionsCopy.findIndex(
          (elem) => elem.id === ele.id && elem.id === ele.id
        )
    );
    let activeSectionId = section.id;
    if(activeSectionId === "Dependentcarecosts"){
      this.renderCaringKidsAdults();
    }
    if (
      this.state.removeDependentCareIfOneHousehold &&
      renderedSectionsCopyFilter[0].id === "Dependentcarecosts"
    ) {
      incomingSection = this._getSection("childsupport-expenses");
      activeSectionId = "childsupport-expenses";
      this.onChildSupportSubsectionRender();
      renderedSectionsCopy.push(incomingSection);
      if (
        section.id == "expenses" &&
        section.subsections.filter((sub) => sub.id === "Dependentcarecosts")
          .length
      )
        this.dependentsCareCostsSubSections[0] = undefined;
      renderedSectionsCopyFilter = renderedSectionsCopy.filter(
        (ele, ind) =>
          ind ===
          renderedSectionsCopy.findIndex(
            (elem) => elem.id === ele.id && elem.id === ele.id
          )
      );
      section.id;
    }
    this.setState(
      {
        renderedSections: renderedSectionsCopyFilter,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: activeSectionId,
          sectionDataUpdated: TextProps.VALUE_FALSE,
          authRepDataUpdated: TextProps.VALUE_FALSE,
          authRepDataChanged: TextProps.VALUE_FALSE,
          rights: 0,
          rightsAdd: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });
      }
    );
  };

  //Get Language and Appened to Answer
  setLanguageInScreen = () => {
    let currentLanguageCode = this.props.selectedLanguage;
    let currentLanguage;
    let selectedAnswer =
      this.props.recert.sections[1].subsections[2].questions[1].Answer;
    if (selectedAnswer === undefined) {
      switch (currentLanguageCode) {
        case types.ENGLISH:
          currentLanguage = "English";
          break;
        case types.SPANISH:
          currentLanguage = "Spanish";
          break;
        case types.PORTUGUESE:
          currentLanguage = "Portuguese";

          break;
        case types.CHINESE:
          currentLanguage = "Chinese";

          break;
        case types.VIETNAMESE:
          currentLanguage = "Vietnamese";

          break;
        case HI:
          currentLanguage = "Haitian Creole";
          break;
        default:
          currentLanguage = "English";
          break;
      }

      this.props.recert.sections[1].subsections[2].questions[1].Answer =
        currentLanguage;
      this.props.recert.sections[1].subsections[2].questions[1].targetValue =
        currentLanguage;
    }
  };
  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };
  //Remove noncitizen section if no member exists
  removeNonCitizenNoMem = () => {
    if (
      this.props.recert.sections.length > 0 &&
      this.props.recert.responseObject &&
      this.props.recert.responseObject.noncitizenStatus.length === 0
    ) {
      filterData(this.props.recert.sections, "noncitizen").active =
        TextProps.VALUE_FALSE;
      filterData(this.props.recert.sections, "noncitizen").hiddenFromNav =
        TextProps.VALUE_TRUE;
    }
  };

  removeSpecificSection = (sectionId) => {
    filterData(this.props.recert.sections, sectionId).active =
      TextProps.VALUE_FALSE;
    filterData(this.props.recert.sections, sectionId).hiddenFromNav =
      TextProps.VALUE_TRUE;
  };

  //calling all returningValues

  returnMemberId(member, incomeSub) {
    let membId;
    if (member.code === "new") {
      membId = 0;
    } else {
      if (incomeSub.fullIncomeObj !== undefined && incomeSub.fullIncomeObj.id) {
        membId = incomeSub.fullIncomeObj.id;
      } else {
        membId = 0;
      }
    }
    return membId;
  }

  //summary
  setContactInitialData = async () => {
    this.props.setContactInfo(setContactInitData(this.props.recert.responseObject,this.state.contactInfoChanged));
  };

  buildSummary = async (sections, finalSummary) => {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i] !== null) {
        const currentSectionData = this.getSectionDataForSummary(
          sections[i].id
        );
        const summaryData = this.getSectionWithBindAnswers(
          sections[i].id,
          currentSectionData
        );
        if (summaryData !== undefined) {
          finalSummary.push(summaryData);
          this.props.appendRecertDataForSummary(finalSummary);
        }
        if (sections[i].id === this.state.discontinuedSection) {
          break;
        } else {
          if (sections[i].subsections && sections[i].subsections.length > 0) {
            this.buildSummary(sections[i].subsections, finalSummary);
          }
        }
      }
    }
  };

  // end of return functions

  //expedited snap services section
  setExpeditedSnapServiceSection = () => {
    if (
      this.props.recert.sections.length > 0 &&
      this.props.recert.responseObject &&
      this.props.recert.responseObject.needs &&
      this.props.recert.responseObject.needs.length
    ) {
      let respObjNeeds = this.props.recert.responseObject.needs;
      filterData(
        this.props.recert.sections,
        "expedited-snap"
      ).questions.filter((need) => {
        let needData = respObjNeeds.filter(
          (resp) => resp.needTypeCode === need.pgrmCode
        )[0];
        if (needData.answer) {
          need.Answer =
            needData.answer === TextProps.VALUE_Y
              ? TextProps.VALUE_YES
              : TextProps.VALUE_NO;
          need.targetValue =
            needData.answer === TextProps.VALUE_Y
              ? TextProps.VALUE_YES
              : TextProps.VALUE_NO;
        }
      });
    }
  };
  //set completed flag
  setCompletedFlag = (activeId) => {
    let found = TextProps.VALUE_FALSE;
    this.props.recert.sections.filter((sec) => {
      if (found) return;
      if (sec.id === activeId) {
        found = TextProps.VALUE_TRUE;
        sec.completed = TextProps.VALUE_TRUE;
        return;
      } else if (activeId.indexOf("student-") >= 0 && sec.id === "students") {
        found = TextProps.VALUE_FALSE;
        return;
      } else {
        sec.completed = TextProps.VALUE_TRUE;
      }
    });
  };
  updateCompletedBulk = (completedActiveIds, sections) => {
    sections.filter((sec) => {
      if (completedActiveIds.includes(sec.id)) {
        sec.completed = TextProps.VALUE_TRUE;
      }
      if("subsections" in sec && sec.subsections && sec.subsections.length){
        this.updateCompletedBulk(completedActiveIds, sec.subsections);
      }
    });
  }
  setStudentsInfo = (studentsInfo) => {
    this.props.recert.sections[
      this.props.recert.sections.indexOf(
        this.props.recert.sections.filter((sec) => sec.id === "students")[0]
      )
    ] = studentsInfo;
    this.setCompletedFlag("students");
    // if (this.props.recert.sections[6].active !== TextProps.VALUE_FALSE)
    this.renderCaringKidsAdults();
  };
  setDependentcarecosts = (Dependentcarecosts) => {
    let depenObj = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0];
    filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections[0].active = TextProps.VALUE_TRUE;
    filterData(this.props.recert.sections, "expenses").subsections[
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(depenObj)
    ] = Dependentcarecosts[0];
  };
  setWebHouseholdInfo = (webHouseholdInfo) => {
    this._renderHouseholdInfo();
    let householdInfoTemplate = this.props.recert.sections.filter(
      (sec) => sec.id === "household-information"
    )[0];
    this.setCompletedFlag("household-information");
    webHouseholdInfo.map((householdInf) => {
      for (const [key, value] of Object.entries(householdInf)) {
        if (value === TextProps.VALUE_Y) {
          let questionObj = householdInfoTemplate.questions.filter(
            (qus) => qus.id === key
          );
          if (questionObj.length) {
            questionObj[0].targetValue = recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0];
            questionObj[0].value = recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0];
            questionObj[0].Answer = recertificationPageLabels(
              language,
              null,
              languageConstants.householdInformation
            ).options[0];
            let condquestionsObj =
              questionObj[0].conditionals[0].conditionalQuestions[0].options.filter(
                (opt) => opt.memInfo.agencyID === householdInf.agencyID
              );
            if (condquestionsObj.length) {
              if (
                questionObj[0].conditionals[0].conditionalQuestions[0].Answer
              ) {
                questionObj[0].conditionals[0].conditionalQuestions[0].Answer.push(
                  condquestionsObj[0].optionValue
                );
                questionObj[0].conditionals[0].conditionalQuestions[0].targetValue.push(
                  condquestionsObj[0].optionValue
                );
                questionObj[0].conditionals[0].conditionalQuestions[0].value.push(
                  condquestionsObj[0].optionValue
                );
              } else {
                questionObj[0].conditionals[0].conditionalQuestions[0].Answer =
                  [];
                questionObj[0].conditionals[0].conditionalQuestions[0].Answer.push(
                  condquestionsObj[0].optionValue
                );
                questionObj[0].conditionals[0].conditionalQuestions[0].targetValue =
                  [];
                questionObj[0].conditionals[0].conditionalQuestions[0].targetValue.push(
                  condquestionsObj[0].optionValue
                );
                questionObj[0].conditionals[0].conditionalQuestions[0].value =
                  [];
                questionObj[0].conditionals[0].conditionalQuestions[0].value.push(
                  condquestionsObj[0].optionValue
                );
              }
            }
          }
        }
      }
    });
  };

  redirectConsumerHome = () => {
    this.props.gotoConsumer();
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setState({ sections: [] });
  };
  renderIcon = (completed) => {
    return completed === TextProps.VALUE_Y ? (
      <div className="verified-box">
        <div><Icon
        name="check-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Verified</div>
      </div>
    ) : (
      <div className="verified-box"><div><Icon
        name="cross-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Not Verified</div>
      </div>
    );
  };
  //Render DHR modal for address validation
  renderAddressValidationModal = () => {
    return (
      <Modal
        isOpen={this.state.addressValidationModalOpen}
        onClose={this.cancelAddressValidationAction}
        modalClass="address-validation-modal"
        titleText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
        headerText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
      >
        <div className="dta-modal__body pad-all">
          {
            <div className="pure-g margin-bottom-half is-mail-address">
              {this.state.resAddressFlag && (
                <div className="pure-u-1">
                  <label className="dta-form__label">
                    {
                      beforeYoustartMlanguageLabels(language)
                        .addressValidLabelText
                    }
                  </label>
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label ">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredHomeAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedResAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-1"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredResAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue:
                            this.state.addressObj.enteredResAddress[0],
                          selectedResAdressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedHomeAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-2"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedResAdressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue: "",
                          selectedResAdressValue:
                            this.state.addressObj.suggestedResAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.mailingAddressFlag && (
                <div className="pure-u-1">
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredMailingAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedMailingAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-3"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue:
                            this.state.addressObj.enteredMailingAddress[0],
                          selectedMailingAddressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedMailingAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-4"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue: "",
                          selectedMailingAddressValue:
                            this.state.addressObj.suggestedMailingAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="dta-modal__footer dta-modal__footer--inline-buttons address-row-footer pure-u-1">
                <button
                  className="dta-button dta-button--large dta-button--primary address-row-footer-buttons address-row-footer-buttons-confirm"
                  onClick={this.confirmAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).confirmButtonLabel}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--outline-primary address-row-footer-buttons address-row-footer-buttons-cancel"
                  onClick={this.cancelAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };
  //Function to validate address by calling Mellisa API
  validateAddressesCallingMellisa(answerAddress, address) {
    let status = TextProps.VALUE_TRUE;
    this.props
      .addressValidation(answerAddress, language)
      .then((data) => {
        if (data.data.length) {
          status = TextProps.VALUE_TRUE;
          let suggestedResAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let suggestedMailingAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          let enteredResAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let enteredMailingAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          // const address = this.props.apply.sections[1].subsections[0];
          let resAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.recert.residentailAddressStored &&
            enteredResAddress.length
          ) {
            resAddrUpdated = checkIfAddressIsUpdated(
              this.formObjToValidate(
                address.questions.filter((addr) => addr.name === "address")[0]
                  .Answer
              ),
              this.props.recert.residentailAddressStored
            );
          } else if (enteredResAddress.length) {
            resAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            resAddrUpdated = TextProps.VALUE_FALSE;
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.recert.mailingAddressStored &&
            enteredMailingAddress.length
          ) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                this.formObjToValidate(mailingaddr.Answer),
                this.props.recert.mailingAddressStored
              );
            }
          } else if (enteredMailingAddress.length) {
            mailingAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            mailingAddrUpdated = TextProps.VALUE_FALSE;
          }
          this.setState(
            {
              addressObj: {
                originalEnteredResAddress: enteredResAddress,
                originalSuggestedResAddress: suggestedResAddress,
                originalEnteredMailingAddress: enteredMailingAddress,
                originalSuggestedMailingAddress: suggestedMailingAddress,
                enteredResAddress: formatAddressDisplay(enteredResAddress),
                suggestedResAddress: formatAddressDisplay(suggestedResAddress),
                enteredMailingAddress: formatAddressDisplay(
                  enteredMailingAddress
                ),
                suggestedMailingAddress: formatAddressDisplay(
                  suggestedMailingAddress
                ),
              },
              enteredResAddressValue: enteredResAddress.length
                ? enteredResAddress[0].formattedAddress
                : "",
              enteredMailingAddressValue: enteredMailingAddress.length
                ? enteredMailingAddress[0].formattedAddress
                : "",
              selectedResAdressValue: "",
              selectedMailingAddressValue: "",
              finalResValueSelected: enteredResAddress.length
                ? enteredResAddress[0]
                : "",
              finalMailingValueSelected: enteredMailingAddress.length
                ? enteredMailingAddress[0]
                : "",
              mailingAddressFlag: mailingAddrUpdated,
              resAddressFlag: resAddrUpdated,
              MelissaApiResponded: true,
            },
            () => {
              this.setState({
                addressValidationModalOpen: TextProps.VALUE_TRUE,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          MelissaApiResponded: TextProps.VALUE_FALSE,
        });
        status = TextProps.VALUE_FALSE;
      });
    return status;
  }
  //Function to handle address validation popup actions(close/cancel)
  cancelAddressValidationAction = () => {
    this.confirmAddressValidationAction();
    this.setState({ addressValidationModalOpen: false });
  };
  confirmAddressValidationAction = () => {
    let selectedResAddress = this.state.finalResValueSelected
      ? this.state.finalResValueSelected
      : this.state.addressObj.originalEnteredResAddress[0];
    let selectedMailingAddress = this.state.finalMailingValueSelected
      ? this.state.finalMailingValueSelected
      : this.state.addressObj.originalEnteredMailingAddress[0];
    let addressDatasections = this.state.addressDatasections;
    addressDatasections.questions = appendUpdatedAdrress(
      addressDatasections.questions,
      selectedResAddress,
      selectedMailingAddress
    );
    this.setAddressValidatedToContactInfo(addressDatasections);
    // this.props.recert.melisaAddressValidated = TextProps.VALUE_TRUE;
    const renderedSectionsCopy = [];
    const incomingSection = this._getSection(this.state.activeSectionId);
    renderedSectionsCopy.push(incomingSection);
    this.setState({
      addressValidationModalOpen: TextProps.VALUE_FALSE,
    });
    let AnswerObjectCopy = this.state.AnswerObject;
    const address = addressDatasections;
    if (AnswerObjectCopy["add-contact-info"].address) {
      AnswerObjectCopy["add-contact-info"].address = address.questions.filter(
        (addr) => addr.name === "address"
      )[0].Answer;
    }
    if (AnswerObjectCopy["add-contact-info"].mailingAddress) {
      AnswerObjectCopy["add-contact-info"].mailingAddress =
        address.questions.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0].conditionals[0].conditionalQuestions[0].Answer;
    }
    this.setState({ AnswerObject: AnswerObjectCopy });
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    setTimeout(() => {
      this.setState({ renderedSections: renderedSectionsCopy });
      let storeAddrObj = this.updateStoreAddr();
      this.props.applyAddressStorageAPI(storeAddrObj);
      // this.props.updateCurrentSectionData(this.props.apply);
      this.setState({ isLoading: TextProps.VALUE_FALSE });
      // this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
    }, 100);
  };
  //Function to update addresses.
  updateStoreAddr() {
    const subsectionData = this.state.addressDatasections;
    let storeAddrObj = [];
    let residentialAddr = this._findTheObj(subsectionData.questions, "address");
    let mailingAddr = this._findTheObj(
      subsectionData.questions,
      "is-also-mailing"
    );
    storeAddrObj.push(
      residentialAddr.Answer
        ? this.formObjToValidate(residentialAddr.Answer)
        : {}
    );
    storeAddrObj.push(
      mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        ? this.formObjToValidate(
            mailingAddr.conditionals[0].conditionalQuestions[0].Answer
          )
        : {}
    );
    return storeAddrObj;
  }
  _findTheObj = (array, find) => {
    return array.filter((value) => value.name === find)[0];
  };
  //set address validated to contact info
  setAddressValidatedToContactInfo = (dataSections) => {
    this.props.setContactInfo(setAddressValidatedToContactInfoHelper(dataSections,this.props.recert.contactInfoScreen));
  };
  //Function to handle address selection
  _handleAddressSelection = (option, value) => {
    let selectedResAddress = {};
    let selectedMailingAddress = {};
    if (option === "suggestedResAddress") {
      selectedResAddress = this.state.addressObj.originalSuggestedResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "enteredResAddress") {
      selectedResAddress = this.state.addressObj.originalEnteredResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "suggestedMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalSuggestedMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    } else if (option === "enteredMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalEnteredMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    }
  };

  onRemovedHOH = (value) => {
    let updatedSections = this.props.recert.sections;
    let removedSections = [];
    this.props.recert.sections.filter(
      (sec) => sec.id === "my-household"
    )[0].completed = TextProps.VALUE_TRUE;
    if(value){
      let startIndex = this.props.recert.sections.indexOf(
        this.props.recert.sections.filter(
          (sec) => sec.id === "my-household"
        )[0])+1;
      let endIndex = this.props.recert.sections.indexOf(
        this.props.recert.sections.filter(
          (sec) => sec.id === "summary"
        )[0])
      removedSections = updatedSections.splice(startIndex,endIndex - startIndex)
      this.props.updateCurrentSectionData(updatedSections);
    }else{
      let startIndex = this.props.recert.sections.indexOf(
        this.props.recert.sections.filter(
          (sec) => sec.id === "my-household"
        )[0])+1;
      let endIndex = 0
      removedSections = []
      updatedSections.splice(startIndex,endIndex, ...this.state.hOHRemovedSections);
      this.props.updateCurrentSectionData(updatedSections);
    }
    this.setState({
      isHOHRemoved : value,
      hOHRemovedSections: removedSections
    })
  }

  // Section Link click continue
  onSectionLinkClick = (event,sectionID = null) => {
    if (event) {
      event.preventDefault();
    }
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.recert;
    const nextSectionID = getNextSection(activeId, sections);
    if (nextSectionID) {
      const nextSection = this._getSection(sectionID ? sectionID : nextSectionID);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
    }
  };

  renderDependentsCareCosts = () => {
    let dependentCosts = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0];
    let expSubsections = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections;
    let dependentCareIndex = expSubsections.indexOf(
      expSubsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
    );
    let dependentCopy = _.cloneDeep(dependentCosts);
    let parentIds = dependentCosts.questions[0].Answer || [];
    let parentOptions = dependentCosts.questions[0].options;

    //Filtering Answers using IDs
    let parentMembers = [];
    let dependentMembers = [];
    if (parentIds)
      for (let parentIdValue of parentIds) {
        for (let optionValue of parentOptions) {
          if (parentIdValue === optionValue) {
            parentMembers.push(optionValue);
          }
        }
      }

    let parentNameMembers = {};
    let conditionalDependentsCount = {};
    let parentConditionals = dependentCosts.questions[0].conditionals;
    let childMembers = [];
    if (parentIds)
      for (var p = 0; p < parentIds.length; p++) {
        parentNameMembers[parentIds[p]] = [];
        for (let parentConditionalValue of parentConditionals) {
          if (parentConditionalValue.valueToMatch === parentIds[p]) {
            conditionalDependentsCount =
              parentConditionalValue.conditionalQuestions[0].Answer;
            for (let conditionalDependentsValue of conditionalDependentsCount) {
              childMembers.push(conditionalDependentsValue);
              parentNameMembers[parentIds[p]].push(conditionalDependentsValue);
              dependentMembers.push(
                parentMembers[p] + "-" + conditionalDependentsValue
              );
            }
          }
        }
      }
    filterData(this.props.recert.sections, "expenses").subsections.filter(
      (sub) => sub.id === "Dependentcarecosts"
    )[0].dependentCare = dependentMembers;

    if (
      this.state.dependentCareCostsSubSectionCleared ===
        TextProps.VALUE_FALSE &&
      dependentCosts.length > 0
    ) {
      this.setState(
        {
          dependentCareCostsSubSectionCleared: TextProps.VALUE_TRUE,
          dependentCareCostsTemplate: dependentCosts.subsections[0],
        },
        () => {
          this.props.appendRecertExpenseDetails(
            dependentCosts,
            dependentCareIndex
          );
        }
      );
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.dependentsCareCostsSubSections[0] === undefined) {
      this.dependentsCareCostsSubSections[0] = _.cloneDeep(dependentCosts);
    } else {
      dependentCosts = _.cloneDeep(this.dependentsCareCostsSubSections[0]);
      dependentCosts.completed = dependentCopy.completed;
    }

    dependentCosts.questions = dependentCopy.questions;

    if (dependentCosts.subsections === undefined) {
      dependentCosts.subsections = _.cloneDeep(
        dependentCareTemplate.subsections
      );
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (dependentMembers[0] !== "" && dependentMembers.length > 0) {
      for (var r = 0; r < dependentMembers.length; r++) {
        for (let memIndex = 0; memIndex < dependentMembers.length; memIndex++) {
          dependentCosts.subsections[memIndex] = _.cloneDeep(
            this.dependentsCareCostsSubSections[0].subsections[0]
          );
          dependentCosts.subsections[memIndex].id =
            "kids-and-adults-" + [memIndex];
            dependentCosts.subsections[memIndex].title = dependentMembers[memIndex].split("-")[1];
          dependentCosts.subsections[memIndex].provider =
            dependentMembers[memIndex].split("-")[0];
          dependentCosts.subsections[memIndex].header =
            childDependnMultilanguageLabels(
              dependentMembers[memIndex].split("-")[1],
              "",
              language
            ).depedentCostHeader;
          // set lables in conditionals questions.
          for (
            var q = 0;
            q < dependentCosts.subsections[memIndex].questions.length;
            q++
          ) {
            if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "units" &&
              q === 0
            ) {
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCostsQoneLabel;
            } else if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "radio" &&
              q === 1
            ) {
              /** Translation start */
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCostsQtwoLabel;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependentCoditionalQLabel;
              /** Translation end */
            } else if (
              dependentCosts.subsections[memIndex].questions[q].type ===
                "radio" &&
              q === 2
            ) {
              dependentCosts.subsections[memIndex].questions[q].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).driveToQLabel;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].label =
                childDependnMultilanguageLabels(
                  dependentMembers[memIndex].split("-")[0],
                  dependentMembers[memIndex].split("-")[1],
                  language
                ).dependConQuestion;
              if (dependentCosts.subsections[memIndex].subsections) {
                dependentCosts.subsections[memIndex].subsections[0].header =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[0],
                    "",
                    language
                  ).depedentCostHeader;
                dependentCosts.subsections[memIndex].dependent =
                  dependentMembers[memIndex];
                dependentMembers[memIndex].split("-")[1];
                dependentCosts.subsections[
                  memIndex
                ].subsections[0].questions[0].label =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[0],
                    "",
                    language
                  ).providerAddress;
                dependentCosts.subsections[
                  memIndex
                ].subsections[0].questions[1].label =
                  childDependnMultilanguageLabels(
                    dependentMembers[memIndex].split("-")[0],
                    dependentMembers[memIndex].split("-")[1],
                    language
                  ).subSectionQuestionTransportLabel;
              }
            }
          }
        }
      }
      let dependentCopyExisiting = _.cloneDeep(dependentCopy);
      if (dependentCopyExisiting.subsections) {
        dependentCosts.subsections.map((dependent) => {
          let existingDependent = dependentCopyExisiting.subsections.filter(
            (existingDepenedent) =>
              existingDepenedent.dependent &&
              dependent.dependent &&
              existingDepenedent.dependent.split("-")[0] ===
                dependent.dependent.split("-")[0]
          );
          if (existingDependent.length) {
            dependent.questions = existingDependent[0].questions;
            if (existingDependent[0].subsections)
              dependent.subsections = existingDependent[0].subsections;
          }
        });
      }
      this.props.appendRecertExpenseDetails(dependentCosts, dependentCareIndex);
    } else {
      delete dependentCosts.subsections;
      this.props.appendRecertExpenseDetails(dependentCosts, dependentCareIndex);
    }
  };

  addAddressesForDepCare = (memberIndex) => {
    let newDepend = _.cloneDeep(
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
    );
    let kidsAdultsForMember = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      .subsections[memberIndex];
    let mainQuestionAnswer = kidsAdultsForMember.questions[2].Answer;

    if (this.providerAddressTemplate[memberIndex] === undefined) {
      let dependentCareTemplateCopy = _.cloneDeep(
        dependentCareTemplate.subsections[0].subsections
      );
      let kidsAdultSub = _.cloneDeep(kidsAdultsForMember.subsections);
      this.providerAddressTemplate[memberIndex] = _.cloneDeep(
        kidsAdultSub ? kidsAdultSub : dependentCareTemplateCopy
      );
    } else {
      kidsAdultsForMember.subsections = _.cloneDeep(
        this.providerAddressTemplate[memberIndex]
      );
    }

    if (
      mainQuestionAnswer ===
      kidsAdultsForMember.questions[2].conditionals[0].valueToMatch
    ) {
      let providerAddressNo =
        kidsAdultsForMember.questions[2].conditionals[0].conditionalQuestions[0]
          .Answer;
      if (providerAddressNo > 0) {
        kidsAdultsForMember.subsections = [];
        for (
          var bindAddressIndex = 0;
          bindAddressIndex < providerAddressNo;
          bindAddressIndex++
        ) {
          kidsAdultsForMember.subsections[bindAddressIndex] = _.cloneDeep(
            this.providerAddressTemplate[memberIndex][0]
          );
          kidsAdultsForMember.subsections[bindAddressIndex].id =
            "kids-adult-multiple-address-" +
            memberIndex +
            "-" +
            bindAddressIndex;
          kidsAdultsForMember.subsections[bindAddressIndex].header =
            childDependnMultilanguageLabels(
              kidsAdultsForMember.dependent.split("-")[1],
              "",
              language
            ).depedentCostHeader +
            " " +
            (bindAddressIndex + 1);
          kidsAdultsForMember.subsections[bindAddressIndex].questions[0].label =
            childDependnMultilanguageLabels(
              kidsAdultsForMember.dependent.split("-")[0],
              "",
              language
            ).providerAddress;
          kidsAdultsForMember.subsections[bindAddressIndex].questions[1].label =
            childDependnMultilanguageLabels(
              kidsAdultsForMember.dependent.split("-")[0],
              kidsAdultsForMember.dependent.split("-")[1],
              language
            ).subSectionQuestionTransportLabel;
          if (
            newDepend.subsections[memberIndex].subsections !== undefined &&
            newDepend.subsections[memberIndex].subsections.length > 0 &&
            newDepend.subsections[memberIndex].subsections[bindAddressIndex] !==
              undefined
          ) {
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].Answer =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].value =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[0].targetValue =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[0].targetValue;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].Answer =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].value =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].Answer;
            kidsAdultsForMember.subsections[
              bindAddressIndex
            ].questions[1].targetValue =
              newDepend.subsections[memberIndex].subsections[
                bindAddressIndex
              ].questions[1].targetValue;
          }
        }
      } else {
        delete kidsAdultsForMember.subsections;
      }
    } else {
      delete kidsAdultsForMember.subsections;
    }
  };

  //Function to Manipulate Details on Income page
  updateHouseHoldIncomeDetails = () => {
    var incomeNameList = [];
    var houseHoldMembersCopy = this.props.recert.householdMembers.membersList;
    for (let memberListValue of houseHoldMembersCopy) {
      if (
        memberListValue.dataChangeCode &&
        memberListValue.dataChangeCode !== "NOCHNG"
      )
        continue;
      var incomeHouseHoldName = memberListValue.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName =
          incomeHouseHoldName + " " + memberListValue.lastName;
        let incomeHouseHoldDOB = formatters.formatDate(
          convertDOBForDataPost(memberListValue.dateOfBirth)
        );
        if (memberListValue.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName =
          memberListValue.firstName + " " + memberListValue.lastName;
      }

      incomeNameList.push(incomeCombinedName);
    }

    this.setState({ houseMemberLabels: incomeNameList });
  };
  newUpdateHouseHoldIncomeDetails = () => {
    var incomeNameList = [];
    var houseHoldMembersCopy = this.props.recert.householdMembers.membersList;
    for (let memberListValue of houseHoldMembersCopy) {
      if (
        memberListValue.dataChangeCode &&
        memberListValue.dataChangeCode !== "NOCHNG"
      )
        continue;
      var incomeHouseHoldName = memberListValue.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName =
          incomeHouseHoldName + " " + memberListValue.lastName;
        let incomeHouseHoldDOB = formatters.formatDate(
          memberListValue.dateOfBirth
        );
        if (memberListValue.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + " " + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName =
          memberListValue.firstName + " " + memberListValue.lastName;
      }

      incomeNameList.push(incomeCombinedName);
    }

    return incomeNameList;
  };

  // This function is for removing user data from summarydata when clicked on remove household member.
  updateSummaryScreens = () => {
    var houseHoldMembersList = this.props.recert.householdMembers.membersList;
    let userList = [];
    houseHoldMembersList.map((mem) => {
      var user = [
        mem["firstName"],
        mem["lastName"],
        formatters.formatDate(mem["dateOfBirth"]),
      ]
        .join(" ")
        .replace(/ +(?= )/g, "");
      if (
        !(mem.dataChangeCode === "removed" || mem.dataChangeCode === "REMOVE")
      ) {
        userList.push(user);
      }
    });

    // Updated ChildSupport Expenses.
    var childSupportSummaryData = this.incomeSummary.childSupportIncomeMembers;
    if (childSupportSummaryData && childSupportSummaryData.length) {
      this.incomeSummary.childSupportIncomeMembers =
        childSupportSummaryData.filter((item) =>
          userList.includes(item.firstName)
        );
    }
  };

  mapMemberListOptions = () => {
    var memberListInfo = [];

    var houseHoldMembersCopy = this.props.recert.householdMembers.membersList;

    for (let memberListValue of houseHoldMembersCopy) {
      var expenseHouseHoldName = memberListValue.firstName;
      let childMemberObject = {};
      var expenseCombinedName = "";
      if (expenseHouseHoldName !== "") {
        expenseCombinedName =
          expenseHouseHoldName + " " + memberListValue.lastName;
        expenseHouseHoldName = memberListValue.dateOfBirth;
        if (memberListValue !== "") {
          expenseCombinedName = expenseCombinedName + expenseHouseHoldName;
        }
      } else if (expenseHouseHoldName === "") {
        expenseCombinedName = memberListValue.firstName;
      }

      // binding the dependents clientIds
      childMemberObject[OptionsProps.OPTION_VALUE] = expenseCombinedName;
      childMemberObject[OptionsProps.OPTION_DISPLAY] = expenseCombinedName;

      memberListInfo.push(childMemberObject);
    }
    return memberListInfo;
  };

  onIncomeSubsectionRender = async (memberTemplate = null) => {
    this.props.appendIRIncomeDetails(incomeSubsectionRenderHelper(memberTemplate,this.props.recert.responseObject,this.props.recert.sections,this.state.incomeSummaryTemplate,this.state.recert_type));
    this.setState({ incomeValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareIncomeSummary();
  };
  onResourcesSubsectionRender = async (memberTemplate = null) => {
    let resourceDetails = _.cloneDeep(resourcesSubsectionRenderHelper(memberTemplate,this.props.recert.responseObject,this.props.recert.sections,this.state.resourcesSummaryTemplate));
    this.props.appendResourcesDetails(resourceDetails);
    this.setState({ resourcesValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareResourcesSummary();
  };

  onUnearnedIncomeSubsectionRender = async (memberTemplate = null) => {
    this.props.appendUIIncomeDetails(onUnearnedIncomeSubsectionRenderHelper(memberTemplate,this.props.recert.responseObject,this.props.recert.sections,this.state.unearnedIncomeSummaryTemplate,this.state.recert_type));
    this.setState({ incomeUIValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareUnearnedIncomeSummary();
  };

  checkForDataChange = (activeId) => {
    if (!this.props.recert.responseObject) return;
    const responseBody = _.cloneDeep(this.state.respData);
    let requestBody = _.cloneDeep(responseBody);

    //Get changes reported
    let reportedChanges = TextProps.VALUE_FALSE;
    //Get data change question selection
    let dataChangeSelection = TextProps.VALUE_FALSE;
    //Flag to identify what content to show in the warning popup
    let fullContentInPopup = TextProps.VALUE_FALSE;
    if (
      activeId === "contact-info" &&
      this.state.activeSectionId === "contact-info"
    ) {
      if (
        this.props.recert.contactInfoScreen &&
        this.props.recert.contactInfoScreen.contactInfoChanged
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        //address change
        let respAddress = responseBody.clientAddress;
        let respMailAddress = null;
        let respResAddress = null;

        for (let respAddressValue of respAddress) {
          if (respAddressValue.typeCode === "RES")
            respResAddress = respAddressValue;
          else if (respAddressValue.typeCode === "MAIL")
            respMailAddress = respAddressValue;
        }

        let updatedAddress =
          this.props.recert.contactInfoScreen.addressList.address;
        let updatedMailAddress = null;
        let updatedResAddress = null;

        for (let updatedAddressValue of updatedAddress) {
          if (
            updatedAddressValue.typeCode === "RES" &&
            updatedAddressValue.city &&
            updatedAddressValue.line1 &&
            updatedAddressValue.zip
          )
            updatedResAddress = updatedAddressValue;
          else if (updatedAddressValue.typeCode === "MAIL")
            updatedMailAddress = updatedAddressValue;
        }

        let finalAddressArray = [];
        if (respResAddress !== null && updatedResAddress !== null) {
          if (
            checkForAddressChange(respResAddress, updatedResAddress) ===
              "changed" ||
            checkForAddressChange(respResAddress, updatedResAddress) ===
              "removed"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.recert.contactInfoScreen.addressList.homeless !==
            respResAddress.homelessFlag
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          } else if (
            respResAddress.dataChangeCode &&
            this.props.recert.contactInfoScreen.changeFlags.homelssFlagCode ===
              "changedLabel"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respResAddress === null && updatedResAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respResAddress !== null && updatedResAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respResAddress.dataChangeCode) {
          if (respResAddress.dataChangeCode === "CHANGE") {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        //Mail Addre check
        if (respMailAddress !== null && updatedMailAddress !== null) {
          if (
            checkForAddressChange(respMailAddress, updatedMailAddress) ===
              "changed" ||
            checkForAddressChange(respMailAddress, updatedMailAddress) ===
              "removed"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.recert.contactInfoScreen.addressList.homeless !==
            respMailAddress.homelessFlag
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          } else if (
            respMailAddress.dataChangeCode &&
            this.props.recert.contactInfoScreen.changeFlags.homelssFlagCode ===
              "changedLabel"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respMailAddress === null && updatedMailAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respMailAddress !== null && updatedMailAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respMailAddress !== null && respMailAddress.dataChangeCode) {
          if (respMailAddress.dataChangeCode === "CHANGE") {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        requestBody.clientAddress = finalAddressArray;

        //Email change
        let respEmail = responseBody.clientEmail;
        let updatedEmail = this.props.recert.contactInfoScreen;
        if (
          (!respEmail.emailAddress && updatedEmail.email) ||
          (respEmail.emailAddress && !updatedEmail.email) ||
          (respEmail.emailAddress &&
            respEmail.emailAddress !== updatedEmail.email) ||
          (respEmail.optInFlag !== undefined &&
            respEmail.optInFlag !== null &&
            respEmail.optInFlag !== updatedEmail.emailOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respEmail.dataChangeCode) {
          if (respEmail.dataChangeCode === "CHANGE") {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        //Phone change
        let respPhone = responseBody.clientPhone;
        let updatedPhone = this.props.recert.contactInfoScreen;
        let phoneNumber = updatedPhone.phoneNumber
          ? updatedPhone.phoneNumber.split(" ").join("")
          : "";
        if (
          (!respPhone.phoneNumber && phoneNumber) ||
          (respPhone.phoneNumber && !phoneNumber) ||
          (respPhone.phoneNumber && respPhone.phoneNumber !== phoneNumber) ||
          (respPhone.textOptInFlag !== undefined &&
            respPhone.textOptInFlag !== null &&
            respPhone.textOptInFlag !== updatedPhone.textOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respPhone.dataChangeCode) {
          if (respPhone.dataChangeCode === "CHANGE") {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        //check for responseBody
        if (
          responseBody.additionalProperties &&
          responseBody.additionalProperties.contactInfoScreen
        )
          Object.values(
            responseBody.additionalProperties.contactInfoScreen.changeFlags
          ).map((flag) => {
            if (flag === "changedLabel") {
              reportedChanges = TextProps.VALUE_TRUE;
            }
          });
      }
      this.setDataCodeChangeFlags();
    } else if (activeId === "my-household") {
      //HOUSEHOLD CHANGE
      if (
        this.props.recert.householdMembers &&
        this.props.recert.householdMembers.membersListUpdated
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let newHouseholdList = [];

        for (let memberListValue of this.props.recert.householdMembers
          .membersList) {
          if (
            memberListValue.allAnswers !== undefined ||
            (memberListValue.dataChangeCode &&
              memberListValue.dataChangeCode === "removed")
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        requestBody.householdMembers = newHouseholdList;
      }
    } else if (activeId === "childsupport-expenses") {
      if (
        this.props.recert.childExpScreen !== undefined &&
        this.props.recert.obligationChildSupportChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let childExpNew = _.cloneDeep(
          this.incomeSummary.childSupportIncomeMembers
        );
        let childArrayExisting = _.cloneDeep(
          this.props.recert.responseObject.childSupportExpense
        );

        let childArrayNew = [];
        const activeSectionId = this.state.activeSectionId;
        if (childExpNew) {
          childExpNew.map((member) => {
            let memberObj = getMemberDataObjectByNameAndDOB(
              member.firstName,
              childArrayExisting
            );
            if (memberObj === null) {
              memberObj = getMemberDataObjectByNameAndDOB(
                member.firstName,
                this.props.recert.householdMembers.membersList
              );
            }

            let changeCode = "NOCHNG";
            switch (member.incomes[0].Answer.code) {
              case "new":
                changeCode = "NEW";
                break;
              case "changed":
                changeCode = "CHANGE";
                break;
              case "removed":
                changeCode = "REMOVE";
                break;
              default:
                break;
            }
            if (changeCode !== "NOCHNG") reportedChanges = TextProps.VALUE_TRUE;
            if (activeSectionId !== "childsupport-expenses")
              reportedChanges = TextProps.VALUE_TRUE;
          });
        }
        if (activeSectionId !== "childsupport-expenses")
          reportedChanges = TextProps.VALUE_TRUE;
      }
    } else if (
      activeId === "resources-summary" ||
      (activeId && activeId.includes("combined-resources"))
    ) {
      //RESOURCES CHANGE
      if (this.props.recert.householdResourcesChanged !== undefined &&
          this.props.recert.householdResourcesChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        if(this.props.recert.householdResourcesChangedToTrue || this.props.recert.responseObject.additionalProperties.householdResourcesChanged){
          reportedChanges = TextProps.VALUE_TRUE;
          this.setState({
            sectionDataUpdated: TextProps.VALUE_TRUE,
          });
        }
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (
      activeId === "income-summary" ||
      (activeId && activeId.includes("combined-income"))
    ) {
      //EARNED INCOME CHANGE
      if (
        (this.props.recert.earnedIncomeChanged !== undefined &&
          this.props.recert.earnedIncomeChanged === TextProps.VALUE_TRUE) ||
        (this.props.recert.grossEarnedIncomeChanged !== undefined &&
          this.props.recert.grossEarnedIncomeChanged === TextProps.VALUE_TRUE)
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.recert.responseObject.earnedIncome
        );

        let incomeArrayNew = [];
        allIncome.forEach((member) => {
          let memberObj = getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.recert.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.forEach((income) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.forEach((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  if (incomeSub.code === "new" || incomeSub.code === "NEW") {
                    changeCode = "NEW";
                  } else if (
                    incomeSub.code === "changed" ||
                    incomeSub.code === "CHANGE"
                  ) {
                    changeCode = "CHANGE";
                  } else if (
                    incomeSub.code === "removed" ||
                    incomeSub.code === "REMOVE"
                  ) {
                    changeCode = "REMOVE";
                  }
                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObj = {
                    id: returnMemberId(member, incomeSub),
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID: returnAgencyID(memberObj, incomeSub),
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    employerName: incomeSub.employer,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
          if (activeId.includes("combined-income")) {
            reportedChanges = TextProps.VALUE_TRUE;
            this.setState({
              sectionDataUpdated: TextProps.VALUE_TRUE,
            });
          }
        }); // member ends

        incomeArrayExisting.forEach((existing) => {
          existing.dateOfBirth = convertDOBForDataPost(
            existing.dateOfBirth
          );

          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.typeCode === existing.typeCode
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (
      activeId === "unearned-income-summary" ||
      (activeId && activeId.includes("unearned-combined-income"))
    ) {
      //UNEARNED INCOME CHANGE
      if (
        this.props.recert.unearnedIncomeChanged !== undefined &&
        this.props.recert.unearnedIncomeChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.recert.responseObject.unearnedIncome
        );

        let incomeArrayNew = [];
        allIncome.map((member) => {
          let memberObj = getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.recert.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.map((income, index) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.map((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  if (incomeSub.code === "new" || incomeSub.code === "NEW") {
                    changeCode = "NEW";
                  } else if (
                    incomeSub.code === "changed" ||
                    incomeSub.code === "CHANGE"
                  ) {
                    changeCode = "CHANGE";
                  } else if (
                    incomeSub.code === "removed" ||
                    incomeSub.code === "REMOVE"
                  ) {
                    changeCode = "REMOVE";
                  }

                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObj = {
                    id: returnMemberId(member, incomeSub),
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID: returnAgencyID(memberObj, incomeSub),
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
          if (activeId.includes("unearned-combined-income")) {
            reportedChanges = TextProps.VALUE_TRUE;
            this.setState({
              sectionDataUpdated: TextProps.VALUE_TRUE,
            });
          }
        }); // member ends

        incomeArrayExisting.map((existing) => {
          existing.dateOfBirth = convertDOBForDataPost(
            existing.dateOfBirth
          );
          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.setID === existing.setID
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (activeId === "shelter-utility-summary") {
      let changeCode = "NOCHNG";
      if (this.props.recert.shelterUtilityConfirmationChanged) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        //Shelter Changes
        let shelterExpNew = _.cloneDeep(
          this.shelterSummary.shelterExpense[0].incomes
        );
        let shelterExpExisting = _.cloneDeep(
          this.props.recert.responseObject.shelterExpenses
        );
        if (shelterExpNew && shelterExpNew.length > 0) {
          shelterExpNew.forEach((newExp) => {
            if (newExp.Answer)
              newExp.Answer.forEach((oneExp) => {
                if (oneExp.code) {
                  switch (oneExp.code) {
                    case "new":
                      changeCode = "NEW";
                      break;
                    case "changed":
                      changeCode = "CHANGE";
                      break;
                    case "removed":
                      changeCode = "REMOVE";
                      break;
                    default:
                      break;
                  }
                }
              });
          });
        }
        // Utility Expense
        let utilityExpNew = _.cloneDeep(this.shelterSummary.utilityExpenses);
        if (utilityExpNew && utilityExpNew.length > 0) {
          utilityExpNew.forEach((oneExp) => {
            if (oneExp.code) {
              switch (oneExp.code) {
                case "new":
                  changeCode = "NEW";
                  break;
                case "changed":
                  changeCode = "CHANGE";
                  break;
                case "removed":
                  changeCode = "REMOVE";
                  break;
                default:
                  break;
              }
            }
          });
        }
        if (changeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          this.props.recert.responseObject &&
          this.props.recert.responseObject.additionalProperties &&
          this.props.recert.responseObject.additionalProperties
            .shelterUtilityConfirmationChanged
        ) {
          if (
            this.props.recert.responseObject.additionalProperties
              .shelterUtilityConfirmationChanged === TextProps.VALUE_TRUE
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }
        // reportedChanges = TextProps.VALUE_TRUE;
      }
    } else if (activeId && activeId.includes("medical-expenses")) {
      // Medical expenses
      var medicalExpenseChanged = this.props.recert.medicalExpensesSummaryChanged;
      var changeCode = "NOCHNG";
      if (
        "summaryData" in this.medicalSummary &&
        this.medicalSummary.summaryData.length &&
        this.props.recert.medicalExpensesSummaryChanged
      ) {
        medicalExpenseChanged = TextProps.VALUE_TRUE;
        this.medicalSummary.summaryData.map((item) => {
          item.medicalExpenses.map((exp) => {
            if (exp.lable !== "NOCHNG") {
              changeCode = exp.lable;
            }
          });
        });
      }
      if (medicalExpenseChanged || activeId.includes("medical-expenses-")) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        this.props.recert.medicalExpensesChanged = TextProps.VALUE_TRUE;
        if (changeCode !== "NOCHNG" || activeId.includes("medical-expenses-")) {
          reportedChanges = TextProps.VALUE_TRUE;
          this.setState({
            sectionDataUpdated: TextProps.VALUE_TRUE,
          });
        }
      }
    }

    if (
      reportedChanges === TextProps.VALUE_FALSE &&
      dataChangeSelection === TextProps.VALUE_TRUE
    ) {
      const currentActiveSection = this.state.activeSectionId;
      if (currentActiveSection !== "before-you-start") {
        this.showWarningModal();
        return TextProps.VALUE_FALSE;
      } else {
        return TextProps.VALUE_TRUE;
      }
    } else if (
      reportedChanges === TextProps.VALUE_TRUE &&
      dataChangeSelection === TextProps.VALUE_TRUE
    ) {
      return TextProps.VALUE_TRUE;
    }
  };

  showWarningModal = () => {
    this.setState({ warningModalOpenStatus: TextProps.VALUE_TRUE });
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpenStatus: TextProps.VALUE_FALSE });
  };

  autoClearSelection = () => {
    const activeId = this.state.activeSectionId;
    let activeIndex = this.props.recert.sections.indexOf(
      filterData(this.props.recert.sections, activeId)
    );
    if (
      activeId === "childsupport-expenses" ||
      activeId === "medical-expenses" ||
      activeId === "shelter-utility-summary"
    ) {
      activeIndex = this.state.recert_type === "IR" ? this.props.recert.sections.indexOf(
        filterData(this.props.recert.sections, activeId)
      )
      :  filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        filterData(
          filterData(this.props.recert.sections, "expenses").subsections,
         "shelter-utility-summary"
        )
      );
    }
    if(activeId === "add-auth-rep") {
      // this.state.addAuthRepData = this.props.recert.authorizedRepresentatives;
      this.setState({addAuthRepData : this.props.recert.authorizedRepresentatives});
      this.state.ebtAuthRepFlg = this.props.recert.responseObject ? this.props.recert.responseObject.ebtAuthRepFlg : null;
      this.state.snapAuthRepFlg = this.props.recert.responseObject ? this.props.recert.responseObject.snapAuthRepFlg : null;
      this.setState({ agreeErrorAdd: TextProps.VALUE_FALSE });
    }
    if (activeId === "contact-info") {
      let contactInfoObj = this.props.recert.contactInfoScreen;
      contactInfoObj.contactInfoChanged = null;
      this.setContactInfoData(contactInfoObj);
      this.props.setDataChangeStatus(null, activeIndex);
    } else if (activeId === "my-household") {
      let stateObj = this.props.recert.householdMembers;
      stateObj.membersListUpdated = null;
      this.props.setHouseholdMembers(stateObj);
      this.props.setDataChangeStatus(null, activeIndex);
    } else if (activeId === "childsupport-expenses") {
      this.props.setObligationChildSupportChangeStatus(null);
      this.props.setCourtOrderedChildSupportChangeStatus(null);
      this.props.setDataChangeStatusForExpense(null, activeIndex);
    } else if (activeId === "shelter-utility-summary") {
      this.props.setShelterOptions(null);
      this.props.setShelterUtilityChangeStatus(null);
      this.props.setDataChangeStatusForExpense(null, activeIndex);
    } else if (activeId === "resources-summary") {
      this.props.setHouseholdResourcesChangedStatus(TextProps.VALUE_FALSE);
    } else if (activeId === "income-summary") {
      this.props.setEarnedIncomeChangeStatus(null);
      this.props.setGrossEarnedIncomeChangeStatus(null);
      this.props.setDataChangeStatus(null, activeIndex);
    } else if (activeId === "unearned-income-summary") {
      this.props.setUnearnedIncomeChangeStatus(null);
      this.props.setDataChangeStatus(null, activeIndex);
    } else if (activeId === "medical-expenses") {
      this.props.setMedicalExpensesChanged(null);
      this.props.recert.medicalExpensesChanged = TextProps.VALUE_TRUE;
      this.props.setDataChangeStatusForExpense(null, activeIndex);
    }
    return TextProps.VALUE_FALSE;
  };

  autoSelectNo = () => {
    const activeId = this.state.activeSectionId;
    if (activeId === "contact-info") {
      let contactInfoObj = this.props.recert.contactInfoScreen;
      contactInfoObj.contactInfoChanged = TextProps.VALUE_FALSE;
      this.setContactInfoData(contactInfoObj);
    } else if (activeId === "my-household") {
      let stateObj = this.props.recert.householdMembers;
      stateObj.membersListUpdated = TextProps.VALUE_FALSE;
      this.props.setHouseholdMembers(stateObj);
    } else if (activeId === "childsupport-expenses") {
      this.props.setObligationChildSupportChangeStatus(TextProps.VALUE_FALSE);
      this.props.setCourtOrderedChildSupportChangeStatus(TextProps.VALUE_FALSE);
    } else if (activeId === "shelter-utility-summary") {
      this.props.setShelterOptions(TextProps.VALUE_FALSE); 
    } else if (activeId === "resources-summary") {
      this.props.setHouseholdResourcesChangedStatus(TextProps.VALUE_FALSE);
    } else if (activeId === "income-summary") {
      this.props.setEarnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.setGrossEarnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.recert.setOldIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "unearned-income-summary") {
      this.props.setUnearnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.recert.setOldUIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "medical-expenses") {
      this.props.setMedicalExpensesChanged(TextProps.VALUE_FALSE);
      this.props.recert.medicalExpensesChanged = TextProps.VALUE_TRUE;
    }
    else if (activeId === "auth-rep") {
     this.props.setAuthRepChangeStatus("N");
     this.props.setDataChangeStatus(true, this.props.recert.sections.indexOf(
      filterData(this.props.recert.sections, activeId)
    ));

    }

    this.closeWarningModal();
    return TextProps.VALUE_FALSE;
  };

  renderWarningPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      null,
      this.state.warningModalOpenStatus,
      this.closeWarningModal,
      "save-progress-modal",
      "First pop-up",
      null,
      TextProps.VALUE_FALSE,
      TextProps.VALUE_FALSE,
      recertWarningPopupMLanguageLabels(language, null).title,
      <main className="dta-modal__body pad-all">
        <p>
          {recertWarningPopupMLanguageLabels(language, null).popupBodyTexts[0]}
        </p>
        <p>&nbsp;</p>
        <div className="apply__footer pad-all--double warning-modal-footer">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() => {
              this.autoSelectNo();
            }}
          >
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.aboutMe
              ).houseLessOptions[1]
            }
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className="dta-button dta-button--primary"
            onClick={() => this.closeWarningModal()}
          >
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.aboutMe
              ).houseLessOptions[0]
            }
          </button>
        </div>
      </main>
    );
  };

  setDataCodeChangeFlags = () => {
    if (
      this.props.recert.contactInfoScreen &&
      this.props.recert.contactInfoScreen.contactInfoChanged
    ) {
      const responseBody = _.cloneDeep(this.props.recert.responseObject);
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 1);
      let emailCode = null;
      let phoneCode = null;
      let mailingAddressCode = null;
      let resAddressCode = null;
      let homelssFlagCode = null;
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let respAddressValue of respAddress) {
        if (respAddressValue.typeCode === "RES")
          respResAddress = respAddressValue;
        else if (respAddressValue.typeCode === "MAIL")
          respMailAddress = respAddressValue;
      }

      let updatedAddress =
        this.props.recert.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let updatedAddressValue of updatedAddress) {
        if (
          updatedAddressValue.typeCode === "RES" &&
          updatedAddressValue.city &&
          updatedAddressValue.line1 &&
          updatedAddressValue.zip
        )
          updatedResAddress = updatedAddressValue;
        else if (updatedAddressValue.typeCode === "MAIL")
          updatedMailAddress = updatedAddressValue;
      }

      if (respResAddress !== null && updatedResAddress !== null) {
        if (
          checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed"
        ) {
          resAddressCode = "changedLabel";
        } else if (
          checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          resAddressCode = "removedLabel";
        } else if (respResAddress.dataChangeCode) {
          if (respResAddress.dataChangeCode === "CHANGE") {
            resAddressCode = "changedLabel";
          }
        }
        //Homeless flag check
        if (
          this.props.recert.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        } else if (
          respResAddress.dataChangeCode &&
          this.props.recert.contactInfoScreen.changeFlags.homelssFlagCode ===
            "changedLabel"
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respResAddress === null && updatedResAddress !== null) {
        resAddressCode = "newLabel";
      } else if (respResAddress !== null && updatedResAddress === null) {
        resAddressCode = "removedLabel";
      } else if (respResAddress !== null && respResAddress.dataChangeCode) {
        if (respResAddress.dataChangeCode === "CHANGE") {
          resAddressCode = "changedLabel";
        }
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        if (
          checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "changed"
        ) {
          mailingAddressCode = "changedLabel";
        } else if (respMailAddress.dataChangeCode) {
          if (respMailAddress.dataChangeCode === "CHANGE") {
            mailingAddressCode = "changedLabel";
          }
          if (respMailAddress.dataChangeCode === "NEW") {
            mailingAddressCode = "newLabel";
          }
        }
        //Homeless flag check
        if (
          this.props.recert.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        } else if (
          respMailAddress.dataChangeCode &&
          this.props.recert.contactInfoScreen.changeFlags.homelssFlagCode ===
            "changedLabel"
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        mailingAddressCode = "newLabel";
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        mailingAddressCode = "changedLabel";
      } else if (respMailAddress !== null && respMailAddress.dataChangeCode) {
        if (respMailAddress.dataChangeCode === "CHANGE") {
          mailingAddressCode = "changedLabel";
        }
        if (respMailAddress.dataChangeCode === "NEW") {
          mailingAddressCode = "newLabel";
        }
      }

      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.recert.contactInfoScreen;
      if (!respEmail.emailAddress && updatedEmail.email) {
        emailCode = "newLabel";
      } else if (respEmail.emailAddress && !updatedEmail.email) {
        emailCode = "removedLabel";
      } else if (
        respEmail.emailAddress &&
        (respEmail.emailAddress !== updatedEmail.email ||
          respEmail.optInFlag !== updatedEmail.emailOptIn)
      ) {
        emailCode = "changedLabel";
        if (respEmail.dataChangeCode === "NEW") {
          emailCode = "newLabel";
        }
      }else if(respEmail.emailAddress === updatedEmail.email){
        if (respEmail.dataChangeCode === "NEW") {
          emailCode = "newLabel";
        }else if(respEmail.dataChangeCode === "CHANGE"){
          emailCode = "changedLabel";
        }else if(respEmail.dataChangeCode === "REMOVE"){
          emailCode = "removedLabel";
        }
      }

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.recert.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.replace(/\D/g, "")
        : "";
      if (!respPhone.phoneNumber && phoneNumber) {
        phoneCode = "newLabel";
      } else if (respPhone.phoneNumber && !phoneNumber) {
        phoneCode = "removedLabel";
      } else if (
        respPhone.phoneNumber &&
        (respPhone.phoneNumber !== phoneNumber ||
          respPhone.textOptInFlag !== updatedPhone.textOptIn)
      ) {
        phoneCode = "changedLabel";
        if (respPhone.dataChangeCode === "NEW") {
          phoneCode = "newLabel";
        }
      }else if(respPhone.phoneNumber === phoneNumber){
        if (respPhone.dataChangeCode === "NEW") {
          phoneCode = "newLabel";
        }else if(respPhone.dataChangeCode === "CHANGE"){
          phoneCode = "changedLabel";
        }else if(respPhone.dataChangeCode === "REMOVE"){
          phoneCode = "removedLabel";
        }
      }

      //Set the codes to the state
      let contactInfoObj = this.props.recert.contactInfoScreen;
      contactInfoObj.changeFlags = {
        emailCode: emailCode,
        phoneCode: phoneCode,
        mailingAddressCode: mailingAddressCode,
        resAddressCode: resAddressCode,
        homelssFlagCode: homelssFlagCode,
      };
      this.props.setContactInfo(contactInfoObj);
    }
    if (
      this.props.recert.contactInfoScreen &&
      (this.props.recert.contactInfoScreen.contactInfoChanged ==
        TextProps.VALUE_TRUE ||
        this.props.recert.contactInfoScreen.contactInfoChanged ==
          TextProps.VALUE_FALSE)
    ) {
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 1);
    }
  };

  formObjToValidate = (address) => {
    return {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      county: address.county,
      countyCode: address.countyCode,
      state: address.state,
      verified: address.verified,
      zipCode: address.zipCode,
    };
  };

  //Auth Rep
  clearFieldsByQuestion = (question) => {
    if(question === "ebtQuestion") {
      this.inputCheck(null, "ebtFrstNam");
      this.inputCheck(null, "ebtLstNam");
      this.inputCheck(null, "ebtMdlNam");
      this.inputCheck(null, "ebtPhNum");
      this.inputCheck(null, "ebtSuffix");
      this.inputCheck(null, "ebtWebDataChangeCode");
    }
    if(question === "snapQuestion") {
      this.inputCheck(null, "snpFrstNam");
      this.inputCheck(null, "snpLstNam");
      this.inputCheck(null, "snpMdlNam");
      this.inputCheck(null, "snpPhNum");
      this.inputCheck(null, "snpSuffix");
      this.inputCheck(null, "snpWebDataChangeCode");
    }
    
    //If both flags if false, then clear signedName and i agree.
    if(( this.state.snapAuthRepFlg === "N") && (this.state.ebtAuthRepFlg === "N")) {
      this.inputCheck(null, "signedName");
    }
    if(( this.state.snapAuthRepFlg === null) && (this.state.ebtAuthRepFlg === null)) {
      this.inputCheck(null, "signedName");
    }
    if(( this.state.snapAuthRepFlg === undefined) && (this.state.ebtAuthRepFlg === undefined)) {
      this.inputCheck(null, "signedName");
    }
  }

  initializedAuthRepArray = () => {
    this.clearFieldsByQuestion("ebtQuestion");
    this.clearFieldsByQuestion("snapQuestion");
    //Clear signedName and I agree
  }


  //Primary Continue Click
  onPrimaryActionClick = (event, actionButtonType) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const { sections } = this.props.recert;
    const activeId = this.state.activeSectionId;
    let sectionId = "";
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    // let datasection = null;
    //Dal Reference
    let dalReference = this.props.recert.dalReference;

    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;

      this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }

    // if (activeId === "household-member")
    if (activeId === "my-household") {
      this.props.updateHouseholdTextChangesRecert(
        filterData(this.props.recert.sections, "my-household").subsections
      );
    } else if (activeId === "contact-info") {
      let contactInfoObjTemp = _.cloneDeep(this.props.recert.contactInfoScreen);
      if (
        contactInfoObjTemp.emailErrorExists === TextProps.VALUE_TRUE ||
        contactInfoObjTemp.phoneErrorExists === TextProps.VALUE_TRUE
      ) {
        contactInfoObjTemp.continueClicked = TextProps.VALUE_TRUE;
        this.props.setContactInfo(contactInfoObjTemp, () => {
          this.setState({
            activeSectionId: activeId,
          });
          this.forceUpdate();
        });
        return;
      }
    }

    if (activeId == "students" || activeId == "income-summary") {
      this.onUnearnedIncomeSubSectionClean();
    }  
    if (activeId === "auth-rep"){
      const authRepData = this.props.recert.authorizedRepresentatives;
      if(this.props.recert.authRepChanged === "Y" && (!authRepData || authRepData && authRepData.ebtWebDataChangeCode === "NOCHNG" &&  authRepData.snpWebDataChangeCode === "NOCHNG")){
        this.showWarningModal();
        return;
      }
    }
    if(activeId === "tanf-household-information") {
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
        filterData(this.props.recert.sections, activeId)
      ));
      this.props.setTanfResponseBodyData(this.state.householdInformation);
      this.setState({additionalhouseholdInformation:true});
    }
    if(activeId === "auth-rep") {
      this.props.setDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
        filterData(this.props.recert.sections, activeId)
      ));
    }

     if(activeId === "add-auth-rep") {
      if(this.props.recert.authorizedRepresentatives === null){
        if(this.state.ebtAuthRepFlg === "Y" && this.state.authRepDataChanged) {
          this.state.addAuthRepData.ebtWebDataChangeCode = "NEW"
        }
        if(this.state.snapAuthRepFlg === "Y" && this.state.authRepDataChanged) {
          this.state.addAuthRepData.snpWebDataChangeCode = "NEW"
        }
      }
      
      this.CheckForQuestionsDataModified();
        if (this.state.rightsAdd < 1) {
          this.setState({ agreeErrorAdd: TextProps.VALUE_TRUE });
          this.agrreErrorMsg = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).agreeTermsErrorMsg;
        }
        // else {
        //   this.setState({ agreeErrorAdd: TextProps.VALUE_FALSE });
        //   this.agrreErrorMsg = "";
        // }
        if(( this.state.snapAuthRepFlg === "N") && (this.state.ebtAuthRepFlg === "N")) {
          this.state.addAuthRepData.signedName = null;
        }
        if(( this.state.snapAuthRepFlg === "N") && (this.state.ebtAuthRepFlg === null)) {
          this.state.addAuthRepData.signedName = null;
        }
        if(( this.state.snapAuthRepFlg === null) && (this.state.ebtAuthRepFlg === "N")) {
          this.state.addAuthRepData.signedName = null;
        }
        if(( this.state.snapAuthRepFlg === null) && (this.state.ebtAuthRepFlg === null)) {
          this.state.addAuthRepData.signedName = null;

        }
        if(( this.state.snapAuthRepFlg === undefined) && (this.state.ebtAuthRepFlg === undefined)) {
          this.state.addAuthRepData.signedName = null;
        }
      if (this.verifyFieldValidationOnSave() === TextProps.VALUE_FALSE) {
        //statuschangecode 
          this.props.setAuthRepResponseBodyData(this.state.addAuthRepData);
        this.setState({sectionDataUpdated: TextProps.VALUE_TRUE});
        this.props.setDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
          filterData(this.props.recert.sections, "add-auth-rep")
        ));
        this.setState({ agreeErrorAdd: TextProps.VALUE_FALSE });
      }
      // else {
      //   this.setState({sectionDataUpdated: TextProps.VALUE_FALSE})
      // }
     }

    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      if (activeId === "add-contact-info") {
        let subsection = filterData(
          this.props.recert.sections,
          "contact-info"
        ).subsections[0];
        if (
          dataSections.questions[0].Answer ===
            recertificationPageLabels(language, null, languageConstants.aboutMe)
              .optionYesNo[0] &&
          dataSections.questions[2].Answer === TextProps.VALUE_STR_TRUE &&
          dataSections.questions[1].Answer.street === "" &&
          dataSections.questions[1].Answer.zipCode === "" &&
          dataSections.questions[1].Answer.city === ""
        ) {
          if (subsection !== undefined) {
            subsection.questions[2].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            subsection.questions[2].conditionals[0].conditionalQuestions[0].errorMessage =
              recertificationPageLabels(
                language,
                null,
                languageConstants.aboutMe
              ).addressErrmsg;
            subsection.questions[2].value = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].Answer = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].targetValue = TextProps.VALUE_STR_FALSE;

            let answer = this.state.AnswerObject;
            answer["add-contact-info"]["is-also-mailing"] =
              TextProps.VALUE_STR_FALSE;

            let mailingAddressSame =
              document.getElementsByName("is-also-mailing");
            mailingAddressSame[1].click();
            mailingAddressSame[0].checked = TextProps.VALUE_FALSE;
            mailingAddressSame[1].checked = TextProps.VALUE_TRUE;
            this.setState({
              activeSectionId: activeId,
              renderedSections: [dataSections],
              AnswerObject: answer,
            });
            this.props.addContactInfoSubSection([subsection]);
            return;
          }
        }
        this.props.addContactInfoSubSection([subsection]);
      }

      let isValid = toValidateInputErros(dataSections, activeId);
      // if (
      //   activeId.substring(0, 27) === "kids-adult-multiple-address" ||
      //   activeId.substring(0, 15) === "medical-address"
      // ) {
      //   this.props.recertStateRefreshSection();
      // }
      if (
        this.props.recert.householdMembers &&
        this.props.recert.householdMembers.membersList !== undefined
      ) {
        let membersArray = [];
        this.props.recert.responseObject.householdMembers.map(
          (houseHoldMember) => {
            membersArray.push(
              houseHoldMember.firstName +
                " " +
                houseHoldMember.lastName +
                houseHoldMember.dateOfBirth
            );
            return membersArray;
          }
        );
        dataSections.memberOptions = membersArray;
      }

      if (
        isValid === TextProps.VALUE_FALSE ||
        (incomingQuestions.selectionMade &&
          incomingQuestions.selectionMade === null)
      ) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          if (
            activeId === "household-information" ||
            activeId === "household-member" ||
            activeId.includes("combined-resources")
          ) {
            this.setState(
              {
                renderedSections: [],
              },
              () => {
                this.setState({
                  activeSectionId: activeId,
                  renderedSections: [dataSections],
                });
              }
            );
          } else {
            this.setState({
              activeSectionId: activeId,
              renderedSections: [dataSections],
            });
          }
        }
        return;
      } else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          case "household-details":
            this.onIncomeSubSectionClean();
            break;
          //Dynamically rendering the student details template for students
          case "students":
            if (
              filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
                .active !== TextProps.VALUE_FALSE
            ) {
              // if(!this.state.houseMemberLabels.length){
              this.updateHouseHoldIncomeDetails();
              this.renderCaringKidsAdults();
              // }
            }
            this.onStudentsSubsectionRender(dataSections.questions[0].Answer);
            break;
          //Clearing Caring Kids
          case "housing-costs":
            this.renderHousingCostsBySelection();
            break;
          case "Dependentcarecosts":
            this.renderDependentsCareCosts();
            // this.prepareChildSupportSummary();
            this.onChildSupportSubsectionRender();
            // sectionId = "childsupport-expenses";
            break;
          case "household-member":
            let stateObj = this.props.recert.householdMembers;
            stateObj.addHouseholdMember = TextProps.VALUE_FALSE;
            let dateOfB = new Date(dataSections.questions[5].Answer);
            let dateNumber =
              dateOfB.getDate() < 10
                ? "0" + dateOfB.getDate()
                : dateOfB.getDate();
            dateOfB =
              dateOfB.getFullYear() +
              "-" +
              (dateOfB.getMonth() < 10
                ? "0" + (dateOfB.getMonth() + 1)
                : dateOfB.getMonth()) +
              "-" +
              dateNumber;
            let newMember = {
              agencyID: 1,
              firstName: dataSections.questions[0].Answer,
              lastName: dataSections.questions[2].Answer,
              dateOfBirth: dateOfB,
              granteeFlag: TextProps.VALUE_FALSE,
              allAnswers: dataSections.questions,
            };
            let membersList = stateObj.membersList;
            membersList.push(newMember);
            stateObj.membersList = membersList;
            this.props.setHouseholdMembers(stateObj);
            let ansObjCopy = this.state.AnswerObject;
            ansObjCopy[activeId].firstName = "";
            ansObjCopy[activeId].lastName = "";
            ansObjCopy[activeId].dateOfBirth = "";
            ansObjCopy[activeId].ssn = "";
            ansObjCopy[activeId].relationship = "";
            ansObjCopy[activeId].ethnicity = "";
            ansObjCopy[activeId]["biological-sex"] = "";
            ansObjCopy[activeId]["is-citizen"] = "";
            ansObjCopy[activeId]["buy-food"] = "";
            ansObjCopy[activeId]["person-income"] = "";
            this.setState({
              AnswerObject: ansObjCopy,
            });
            break;
          case "add-contact-info":
            if (config.doAddressValidate) {
              const address = dataSections;
              let resAddrUpdated = TextProps.VALUE_FALSE;
              let isHomeless = address.questions.filter(
                (addr) => addr.name === "is-homeless"
              )[0];
              if (isHomeless && isHomeless.Answer === interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]) {
                resAddrUpdated = TextProps.VALUE_FALSE;
              }
              if (this.props.recert.residentailAddressStored) {
                resAddrUpdated = checkIfAddressIsUpdated(
                  this.formObjToValidate(
                    address.questions.filter(
                      (addr) => addr.name === "address"
                    )[0].Answer
                  ),
                  this.props.recert.residentailAddressStored
                );
              }
              let mailingAddrUpdated = TextProps.VALUE_FALSE;
              if (this.props.recert.mailingAddressStored) {
                let mailingaddr = address.questions.filter(
                  (addr) => addr.name === "is-also-mailing"
                )[0].conditionals[0].conditionalQuestions[0];
                if (mailingaddr.Answer && mailingaddr.Answer.addressLine1) {
                  mailingAddrUpdated = checkIfAddressIsUpdated(
                    this.formObjToValidate(mailingaddr.Answer),
                    this.props.recert.mailingAddressStored
                  );
                }
              }
              if (
                !this.props.recert.contactInfoScreen.melisaAddressValidated ||
                resAddrUpdated ||
                mailingAddrUpdated
              ) {
                let melissaResponse = this.validateAddressesCallingMellisa(
                  addressValidationObj(address.questions),
                  address
                );
                this.setState({
                  addressDatasections: address,
                });
                if (melissaResponse) {
                  return;
                }
              } else {
                this.setAddressValidatedToContactInfo(dataSections);
              }
            } else {
              this.setAddressValidatedToContactInfo(dataSections);
            }
            break;
          case "income-summary":
            this.onIncomeSubSectionClean();
            this.onUnearnedIncomeSubSectionClean();
            // this.onUnearnedIncomeSubsectionRender();
            break;
          // case "resources-summary":
          //   this.onResourcesSubSectionClean();
          //   this.onUnearnedIncomeSubSectionClean();
          //   break;
          case "unearned-income-summary":
            this.onUnearnedIncomeSubSectionClean();
            this.updateHouseHoldIncomeDetails();
            this.onUnearnedIncomeSubsectionRender(
              dataSections.questions[0].Answer
            );
            break;
          default:
            break;
        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        switch (activeIdSubString) {
          case "kids-and-adults":
            this.addAddressesForDepCare(activeSplitMemberIndex);
            break;
          case "student":
            this.renderCaringKidsAdults();
            break;
          case "child-support-amount":
            this.prepareChildSupportSummary();
            this.processChildSupportSectionData();
            // this.gotoChildSupportSummary();
            break;
          case "shelter-expenses":
            this.shelterQuestionsBySelection();
            break;
          case "medical-expenses-type":
            this.renderMedicalExpenseFilter(activeId);
            this.prepareMedicalExpensesSummary();
            break;
          case "medical-expenses-filter":
            this.prepareMedicalExpensesSummary();
            break;
          case "unearned-combined-income":
            this.prepareUnearnedIncomeSummary();
            this.incomeUnearnedfilterQuestionsBySelection(
              activeSplitMemberIndex
            );
            break;
          case "unearned-combined-income-subsection":
            this.prepareUnearnedIncomeSummary();
            this.incomeUnearnedfilterQuestionsBySelection(
              activeSplitMemberIndex
            );
            // dataSections.currentMember =
            //   this.props.recert.sections[8].currentMember;
            // this.gotoUnearnedIncomeSummary(activeSplitMemberIndex);
            // if(actionButtonType === TextProps.SAVEEXIT){
            //   this.updateAndSaveSection(activeId,actionButtonType);
            //   }
            break;
          case "combined-income":
            this.prepareIncomeSummary();
            this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
            // this.onIncomeSubSectionClean();
            break;
          case "combined-income-subsection":
            // this.onIncomeSubSectionClean();
            this.prepareIncomeSummary();
            this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
            break;
          case "combined-resources":
            this.prepareResourcesSummary();
            this.resourcesfilterQuestionsBySelection(activeSplitMemberIndex);
            break;
          case "combined-resources-subsection":
            this.prepareResourcesSummary();
            this.resourcesfilterQuestionsBySelection(activeSplitMemberIndex);
            break;
          default:
            //CHECK IF KIDS AND ADULT ADDRESS. MAP THE MEMBER ID AND SEQUENCE
            if (
              activeIdSubString.indexOf("kids-adult-multiple-address-") >= 0
            ) {
              for (var member of filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
                .questions[0].options) {
                if (member.name === dataSections.title) {
                  dataSections.clientId = member.id;
                }
                if (
                  filterData(
                    this.props.recert.sections,
                    "expenses"
                  ).subsections.filter(
                    (sub) => sub.id === "Dependentcarecosts"
                  )[0].currentExpSeq !== undefined &&
                  filterData(
                    this.props.recert.sections,
                    "expenses"
                  ).subsections.filter(
                    (sub) => sub.id === "Dependentcarecosts"
                  )[0].currentExpSeq !== null
                ) {
                  dataSections.currentExpSeq = filterData(
                    this.props.recert.sections,
                    "expenses"
                  ).subsections.filter(
                    (sub) => sub.id === "Dependentcarecosts"
                  )[0].currentExpSeq;
                }
              }
            }

            break;
        }
        if (activeId !== "kids-and-adults") {
          if (activeId.indexOf("medical-address-") >= 0) {
            if (
              filterData(this.props.recert.sections, "students")
                .currentExpSeq !== undefined &&
              filterData(this.props.recert.sections, "students")
                .currentExpSeq !== null
            ) {
              //This is intentional
            }
          }
        } else {
          if (!actionButtonType === TextProps.SAVEEXIT) {
            this._renderNextSection(activeId);
          }
          let AnswerObjectCopy = this.state.AnswerObject;
          AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
          this.setState({ AnswerObject: AnswerObjectCopy });
        }
      }
    } else if (incomingQuestions.selectionMade === TextProps.VALUE_FALSE) {
      const presentSectionId = this._getSection(activeId, this.state.sections);
      if (presentSectionId) {
        this.setState({
          activeSectionId: activeId,
        });
      }
      return;
    } else if (incomingQuestions.selectionMade === null) {
      let index = 0;
      let { sections } = this.props.recert;
      let currentSectionIndex = sections.indexOf(
        filterData(sections, activeId)
      );
      switch (activeId) {
        //Clearing HouseHold Cache Template
        case "contact-info":
          index = currentSectionIndex;
          break;

        case "my-household":
          index = currentSectionIndex;
          break;
        case "noncitizen":
          index = currentSectionIndex;
          break;
        case "resources-summary":
          index = this.props.recert.sections.indexOf(
            this.filterData(this.props.recert.sections, "resources-summary")
          );
         break;
        case "income-summary":
          index = currentSectionIndex;
          break;
        case "unearned-income-summary":
          index = currentSectionIndex;
          break;
        case "auth-rep":
          index = currentSectionIndex;
          break;
        case "add-auth-rep":
          index = currentSectionIndex;
          break;
        default:
          break;
      }
      if(activeId === "add-auth-rep"){
        this.saveAndContinue(activeId, incomingQuestions, actionButtonType);
      }
      let expensesSectionIndex;
      if(this.state.recert_type === "IR"){
        expensesSectionIndex = sections.indexOf(
          filterData(sections, activeId)
        )
      } else {
        expensesSectionIndex =  filterData(
          sections,
          "expenses"
        ).subsections.indexOf(
          filterData(
            filterData(sections, "expenses").subsections,
           activeId
          )
        );
      }
      if (activeId === "childsupport-expenses") {
        index = expensesSectionIndex;
        this.props.setDataChangeStatusForExpense(TextProps.VALUE_FALSE, index);
      } else if (activeId === "shelter-utility-summary") {
        index = expensesSectionIndex;
        this.props.setDataChangeStatusForExpense(TextProps.VALUE_FALSE, index);
      } else if (activeId === "medical-expenses") {
        index = expensesSectionIndex;
        this.props.setDataChangeStatusForExpense(TextProps.VALUE_FALSE, index);
      } else {
        this.props.setDataChangeStatus(TextProps.VALUE_FALSE, index);
      }
      return;
    } else if (activeId === "sign-and-submit") {
      let currentLanguageCode =
        helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
      if (currentLanguageCode) {
        switch (currentLanguageCode) {
          case types.SPANISH:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationESAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.PORTUGUESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationBRAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();

            break;
          case types.CHINESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationZHAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case types.VIETNAMESE:
          case HI:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationVIAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          default:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationENAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
        }
      }
      //Loading Dynamic SubSections , HouseHold Details
      let HOH;
      let userName = "";
      if (
        this.props.recert.responseObject.householdMembers
      ) {
        HOH = this.props.recert.responseObject.householdMembers.filter(
          (mem) => mem.granteeFlag === TextProps.VALUE_TRUE
        )[0];
        userName = HOH.firstName + " " + HOH.lastName;
      }
      if (this.state.rights < 1 || this.state.signature.length < 1 || (!this.state.isHOHRemoved && this.state.signature.length && this.state.signature.toLowerCase() !== userName.toLowerCase())) {
        if (this.state.rights < 1) {
          this.setState({ agreeError: TextProps.VALUE_TRUE });
          this.agrreErrorMsg = recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).agreeTermsErrorMsg;
        } else {
          this.setState({ agreeError: TextProps.VALUE_FALSE });
          this.agrreErrorMsg = "";
        }

        this.setState(
          { signature: this.refs.applicanSign.inputEl.value },
          () => {
            if (this.state.signature.length < 1) {
              this.setState({ signatureError: TextProps.VALUE_TRUE });
              if(this.state.recert_type === "REEVAL"){
                this.signatureErrorMsg = recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).tanfPleaseSign
              }else{
                this.signatureErrorMsg = recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).signatureErrorMsg;
              }
            }else if (!this.state.isHOHRemoved && this.state.signature.toLowerCase() !== userName.toLowerCase()){
              this.setState({ signatureError: TextProps.VALUE_TRUE });
              this.signatureErrorMsg = recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).signatureUserNameErrorMsg;
            } else {
              this.setState({ signatureError: TextProps.VALUE_FALSE });
              this.signatureErrorMsg = "";
            }
          }
        );

        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
          });
        }
        return;
      } else {
        if (actionButtonType == "saveExit") {
          this.saveAndContinue(activeId, incomingQuestions, actionButtonType);
        } else {
          // Sending obj to service side for saving
          this.saveToDatabase(activeId);
        }
      }
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.recert);

    if (activeId !== "sign-and-submit") {
      if (
        actionButtonType === TextProps.SAVEEXIT ||
        actionButtonType === TextProps.BACK || actionButtonType === TextProps.SIGNSUBMIT
      ) {
        this.saveAndContinue(activeId, incomingQuestions, actionButtonType);
      } else {
        if (!excluedSections.includes(activeId)) {
          this.saveAndContinue(
            activeId,
            incomingQuestions,
            TextProps.SAVECONTINE
          );
        } else {
          this._renderNextSection(activeId);
          this.subSectionData(incomingQuestions, activeId);
        }
      }
    }

    if (actionButtonType === TextProps.SAVEEXIT) {
      if (activeId === "summary") {
        this.prepareDataForSubmission(
          "summary",
          TextProps.VALUE_TRUE,
          TextProps.SAVEEXIT
        );
        this.setState({
          activeSectionId: "summary",
        });
      }
    } else if (actionButtonType === TextProps.SAVECONTINE) {
      this.setState({
        activeSectionId: "summary",
      });
    }

    const currentSectionData = this.getSectionDataForSummary(
      this.state.activeSectionId
    );
    const summaryData = this.getSectionWithBindAnswers(
      this.state.activeSectionId,
      currentSectionData
    );

    let finalSummary = this.props.recert.summaryData
      ? this.props.recert.summaryData
      : [];
    if (summaryData !== undefined) {
      finalSummary.push(summaryData);
    }

    this.props.appendRecertDataForSummary(finalSummary);
  };

  removeDependentCareIfOneHousehold = () => {
    let memberCount = 0;
    if (
      this.props.recert.householdMembers &&
      this.props.recert.householdMembers.membersList
    ) {
      let members = this.props.recert.householdMembers.membersList;
      members.map((houseHoldMemberValue) => {
        if (houseHoldMemberValue.dataChangeCode !== "removed") memberCount++;
      });
    }
    if (
      memberCount < 2 &&
      filterData(this.props.recert.sections, "expenses")
    ) {
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0].active =
        TextProps.VALUE_FALSE;
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter(
        (sub) => sub.id === "Dependentcarecosts"
      )[0].hiddenFromNav = TextProps.VALUE_TRUE;
      this.setState({
        removeDependentCareIfOneHousehold: TextProps.VALUE_TRUE,
      });
    } else if (
      memberCount > 1 &&
      filterData(this.props.recert.sections, "expenses")
    ) {
      let depCare = filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter((sub) => sub.id === "Dependentcarecosts");
      if (depCare.length) {
        depCare[0].active = TextProps.VALUE_TRUE;
        depCare[0].hiddenFromNav = TextProps.VALUE_FALSE;
        this.setState({
          removeDependentCareIfOneHousehold: TextProps.VALUE_FALSE,
        });
      }
    }
  };
  subSectionData = (incomingQuestions, activeId) => {
    if (
      incomingQuestions.navigateToPrimaryScreen !== undefined &&
      incomingQuestions.navigateToPrimaryScreen === TextProps.VALUE_TRUE
    ) {
      // event.preventDefault(); // allow react to take care of routing instead of browser
      const activeSectionId = activeId;
      const { sections } = this.props.recert;
      const previousSectionId = getPreviousSection(activeSectionId, sections);
      if (previousSectionId) {
        const previousSection = this._getSection(previousSectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(previousSection, TextProps.VALUE_TRUE);
          }
        );
      }
    }

    if (
      incomingQuestions.skipsubSectionNavigation !== undefined &&
      incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
    ) {
      // event.preventDefault(); // allow react to take care of routing instead of browser
      const activeQuestionsId = incomingQuestions.id;
      const { sections } = this.props.recert;
      let sectionId = getNextSection(activeQuestionsId, sections);

      switch (activeQuestionsId) {
        case "contact-info":
          let contactInfoObj = _.cloneDeep(this.props.recert.contactInfoScreen);

          if (contactInfoObj.emailTemp !== undefined) {
            contactInfoObj.email = contactInfoObj.emailTemp;
            delete contactInfoObj.emailTemp;
          }
          if (contactInfoObj.emailOptInTemp !== undefined) {
            contactInfoObj.emailOptIn = contactInfoObj.emailOptInTemp;
            delete contactInfoObj.emailOptInTemp;
          }

          if (contactInfoObj.phoneNumberTemp !== undefined) {
            contactInfoObj.phoneNumber = contactInfoObj.phoneNumberTemp;
            delete contactInfoObj.phoneNumberTemp;
          }
          if (contactInfoObj.textOptInTemp !== undefined) {
            contactInfoObj.textOptIn = contactInfoObj.textOptInTemp;
            delete contactInfoObj.textOptInTemp;
          }

          contactInfoObj.editEmail = TextProps.VALUE_FALSE;
          contactInfoObj.editPhone = TextProps.VALUE_FALSE;
          this.props.setContactInfo(contactInfoObj);
          if (this.expediatedSnapServicesValidated()) {
            sectionId = "expedited-snap";
          } else {
            sectionId = "my-household";
          }
          break;

        case "my-household":
          if(this.state.recert_type === "REEVAL" || this.state.recert_type === "IR") {
            this.onIncomeSubSectionClean();
            this.onIncomeSubsectionRender();
            sectionId = "income-summary";
          }else{
          //Remove dependent care section if only 1 member present in the household
          this.removeDependentCareIfOneHousehold();

          let houseHoldMembersCopy =
            this.props.recert.householdMembers.membersList;
          //Remove medical cost section if no member is above 60 years old
          let count = 0;
          for (let memberListValue of houseHoldMembersCopy) {
            if (memberListValue.dateOfBirth) {
              let date = new Date(
                convertDOBForDataPost(memberListValue.dateOfBirth)
              );
              let age = helperFunction._calculateAge(date);
              if (
                age >= 60 ||
                memberListValue.disabilityFlag === TextProps.VALUE_TRUE
              )
                count++;
            } else if (
              memberListValue.disabilityFlag === TextProps.VALUE_TRUE
            ) {
              count++;
            }
          }
          if (count === 0) {
            if (
              filterData(this.props.recert.sections, "expenses") &&
              filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.filter((sub) => sub.id === "medical-expenses")[0]
                .id === "medical-expenses"
            ) {
              // this.props.recert.sections.splice(8, 1);
            }
          } else {
            if (
              filterData(this.props.recert.sections, "expenses") &&
              filterData(
                this.props.recert.sections,
                "expenses"
              ).subsections.filter((sub) => sub.id === "medical-expenses")[0]
                .id !== "medical-expenses"
            ) {
              // this.props.recert.sections
              //   .splice(8, 0, expenseMedicalExpenseTemplate)
              //   .join();
            }
          }

          if (filterData(this.props.recert.sections, "noncitizen")) {
            if (
              filterData(this.props.recert.sections, "noncitizen") &&
              filterData(this.props.recert.sections, "noncitizen")
                .active === TextProps.VALUE_TRUE
            )
            {
              sectionId = "noncitizen";
            }
            else {
              sectionId = "household-information";
              this._renderHouseholdInfo();
              this.renderStudents();
              if (
                filterData(
                  this.props.recert.sections,
                  "shelter-utility-summary"
                ) &&
                filterData(
                  this.props.recert.sections,
                  "shelter-utility-summary"
                ).active !== TextProps.VALUE_FALSE
              )
                this.updateHouseHoldIncomeDetails();
            }
          } else sectionId = "sign-and-submit";
          }
          break;
        case "tanf-household-information": 
          sectionId = "summary";
          break;
        case "household-information":
          sectionId = "auth-rep";
          this.renderAuthRep();
          break;
        case "auth-rep":
            this.onResourcesSubSectionClean();
            this.onResourcesSubsectionRender();
            sectionId = "resources-summary";
            break;
        case "resources-summary":
          if (this.state.recert_type === "AESAP") {
            this.prepareResourcesSummary();
            this.onIncomeSubSectionClean();
            this.onIncomeSubsectionRender();
            sectionId = "income-summary";
          } else if(this.state.recert_type === "RECERT") {
            this.prepareResourcesSummary();
            sectionId = "students";
            this.renderStudents();
            if (
              filterData(
                this.props.recert.sections,
                "shelter-utility-summary"
              ) &&
              filterData(
                this.props.recert.sections,
                "shelter-utility-summary"
              ).active !== TextProps.VALUE_FALSE
            )
              this.updateHouseHoldIncomeDetails();
          }
          break;
        case "childsupport-expenses":
          if(this.state.recert_type === "IR"){
            this.shelterSubsectionRender();
            this.prepareShelterSummary();
            sectionId = "shelter-utility-summary";
          } else {
          sectionId = "medical-expenses";
          }
          break;
        case "medical-expenses":
          sectionId = "summary";
          break;
        case "students":
          this.onIncomeSubSectionClean();
          this.onIncomeSubsectionRender();
          sectionId = "income-summary";
          break;
        case "income-summary":
          // this.onIncomeSubsectionRender();
          this.prepareIncomeSummary();
          this.onUnearnedIncomeSubsectionRender();
          // this.prepareUnearnedIncomeSummary();
          sectionId = "unearned-income-summary";
          // sectionId = "shelter-utility-summary";
          break;
        case "unearned-income-summary":
          // this.prepareUnearnedIncomeSummary();
          // this.onUnearnedIncomeSubsectionRender();
          if(this.state.recert_type === "IR"){
            this.onChildSupportSubsectionRender();
            sectionId = "childsupport-expenses";
          } else if(this.state.recert_type === "REEVAL") {
            sectionId = "tanf-household-information"
          }else {
            this.shelterSubsectionRender();
            this.prepareShelterSummary();
            this.renderCaringKidsAdults();
            sectionId = "shelter-utility-summary";
          }
          break;
        case "shelter-utility-summary":
          // this.prepareShelterSummary();
          // this.shelterSubsectionRender();
          if(this.state.recert_type === "IR"){
            sectionId = "summary";
          } else {
          sectionId = "Dependentcarecosts";
          }
           
          break;
          case "auth-rep":
            if (this.state.recert_type === "AESAP") {
              sectionId = "income-summary";
            }
            else {
              sectionId = "students";
            }
            if(this.props.recert.responseObject !== null || this.props.recert.responseObject !== undefined) {
              this.setState({snapAuthRepFlg: this.props.recert.responseObject.snapAuthRepFlg});
              this.setState({ebtAuthRepFlg: this.props.recert.responseObject.ebtAuthRepFlg});
            }
            break;
            case "add-auth-rep":
              sectionId = "auth-rep";
              break;
        default:
          break;
      }

      if (sectionId) {
        const nextSection = this._getSection(sectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
          }
        );
      }
    }
  };
  // ,this.updateAndSaveSection(this.state.activeSectionId)
  // updateAndSaveSection = (activeId) =>{
  //   this._updateCurrentSection(activeId);
  //   this.saveAndContinue(activeId);
  // }
  saveToDatabase = (activeId) => {
    this.prepareDataForSubmission(activeId, TextProps.VALUE_FALSE);
  };

  getSummaryId = (currentId) => {
    if (currentId.includes("contact-info")) {
      return "contact-info";
    } else if (currentId.includes("household-member")) {
      return "my-household";
    } else if (currentId.includes("unearned-combined-income")) {
      return "unearned-income-summary";
    } else if (currentId.includes("combined-income")) {
      return "income-summary";
    }  else if (currentId.includes("combined-resources")) {
      return "resources-summary";
    } else if (currentId.includes("child-support-amount")) {
      return "childsupport-expenses";
    } else if (
      currentId.includes(
        "shelter-expenses-section" ||
          "shelter-expense-subsection" ||
          "utility-expenses-section"
      )
    ) {
      return "shelter-utility-summary";
    } else {
      return currentId;
    }
  };

  saveAndContinue = async (activeId, incomingQuestions, actionButtonType) => {
    let dataChange = await this.checkForDataChange(this.state.activeSectionId);
    //If the user has not changed any info then simply return TextProps.VALUE_FALSE to stay on the same section
    if (dataChange === TextProps.VALUE_FALSE) {
      this.forceUpdate();
      return;
    } else {
      // this.subSectionData(incomingQuestions);
      if (this.state.sectionDataUpdated) {
      this.prepareDataForSubmission(
        activeId,
        TextProps.VALUE_TRUE,
        actionButtonType
      );
      this.setState({ sectionDataUpdated: TextProps.VALUE_FALSE,
        authRepDataUpdated: TextProps.VALUE_FALSE,
        authRepDataChanged: TextProps.VALUE_FALSE });
      }else{
        if(activeId === "add-auth-rep" && this.verifyFieldValidationOnSave() === TextProps.VALUE_TRUE) {
          return;
        }
        else {
          if(activeId !== "add-auth-rep") {
            this.prepareDataForSubmission(
              activeId,
              TextProps.VALUE_TRUE,
              actionButtonType
            );
          }
        }
        // this.setState({ sectionDataUpdated: TextProps.VALUE_FALSE });
        // this._renderNextSection(activeId);
        // this.subSectionData(incomingQuestions,activeId);
      }
    }
    // if (activeId === "contact-info") {
    //   let contactInfoObj = this.props.recert.contactInfoScreen;
    //   contactInfoObj.contactInfoChanged = TextProps.VALUE_FALSE;
    //   this.props.setContactInfo(contactInfoObj);
    // } else if (activeId === "my-household") {
    //   let stateObj = this.props.recert.householdMembers;
    //   stateObj.membersListUpdated = TextProps.VALUE_FALSE;
    //   this.props.setHouseholdMembers(stateObj);
    // }
  };

  prepareIncomeSummary = () => {
    let incomeSection = filterData(
      this.props.recert.sections,
      "income-summary"
    );
    let houseMemberList = this.state.houseMemberLabels;

    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].valueToMatch;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.nonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };
  prepareResourcesSummary = () => {
    let resourcesSection = this.filterData(
      this.props.recert.sections,
      "resources-summary"
    );
    let houseMemberList = this.state.houseMemberLabels;

    this.resourcesSummary.resourcesMembers = [];
    this.resourcesSummary.nonResourcesMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < resourcesSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.resourcesSummary.resourcesMembers[memberIncomeIndex] = {};
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].section =
        resourcesSection.subsections[memberIncomeIndex];
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].id =
        resourcesSection.subsections[memberIncomeIndex].id;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].firstName =
        resourcesSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        resourcesSection.subsections[memberIncomeIndex].title;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesType =
        resourcesSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources = [];
      if (
        resourcesSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs =
          resourcesSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs
            .length;
          questionIndex++
        ) {
          // if (
          //   this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
          //     questionIndex
          //   ].name !== "rental-income-hours-worked-0"
          // ) {
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ] = {};
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].id =
              "resourcesSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].type =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].displayIncomeName;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
                questionIndex
              ].datachangeCode = this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].datachangeCode;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].typeCode =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].valueToMatch;
            this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources[
              questionIndex
            ].Answer =
              this.resourcesSummary.resourcesMembers[memberIncomeIndex].resourcesInputs[
                questionIndex
              ].Answer;
          // }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.resourcesSummary.nonResourcesMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  prepareUnearnedIncomeSummary = () => {
    let incomeSection = filterData(
      this.props.recert.sections,
      "unearned-income-summary"
    );
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.unearnedIncomeMembers = [];
    this.incomeSummary.nonUnearnedIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.unearnedIncomeMembers[
          memberIncomeIndex
        ].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
            .incomeInputs.length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
              .incomeInputs[questionIndex].name !==
            "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].displayIncomeName;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].valueToMatch;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.nonUnearnedIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  prepareChildSupportSummary = () => {
    let incomeSection = this.state.recert_type === "IR" ?  
    filterData(_.cloneDeep(this.props.recert.sections), "childsupport-expenses")
    : filterData(
      filterData(_.cloneDeep(this.props.recert.sections), "expenses").subsections,
      "childsupport-expenses"
    );
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.childSupportIncomeMembers = [];
    this.incomeSummary.childSupportNonIncomeMembers = [];
    let currentIncomeMembers = [];
    if (incomeSection && incomeSection.subsections) {
      for (
        var memberIncomeIndex = 0;
        memberIncomeIndex < incomeSection.subsections.length - 1;
        memberIncomeIndex++
      ) {
        if (!incomeSection.subsections[memberIncomeIndex].isRemoved) {
          this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex] = {};
          incomeSection.subsections[memberIncomeIndex].completed =
            TextProps.VALUE_TRUE;
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].section = incomeSection.subsections[memberIncomeIndex];
          this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].id =
            incomeSection.subsections[memberIncomeIndex].id;
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].firstName = incomeSection.subsections[memberIncomeIndex].title;
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].clientId = incomeSection.subsections[memberIncomeIndex].title;
          currentIncomeMembers[memberIncomeIndex] =
            incomeSection.subsections[memberIncomeIndex].title;
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].incomeType = [];
          incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].incomes = [
            incomeSection.subsections[memberIncomeIndex].questions[0].Answer,
          ];
          if (incomeSection.subsections[memberIncomeIndex] !== undefined) {
            this.incomeSummary.childSupportIncomeMembers[
              memberIncomeIndex
            ].incomeInputs =
              incomeSection.subsections[memberIncomeIndex].questions;
            for (
              var questionIndex = 0;
              questionIndex <
              this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex]
                .incomeInputs.length;
              questionIndex++
            ) {
              this.incomeSummary.childSupportIncomeMembers[
                memberIncomeIndex
              ].incomes[questionIndex] = {};
              this.incomeSummary.childSupportIncomeMembers[
                memberIncomeIndex
              ].incomes[questionIndex].id =
                "childExpense-" + memberIncomeIndex + "-" + questionIndex;
              this.incomeSummary.childSupportIncomeMembers[
                memberIncomeIndex
              ].incomes[questionIndex].Answer =
                this.incomeSummary.childSupportIncomeMembers[
                  memberIncomeIndex
                ].incomeInputs[questionIndex].Answer;
              this.incomeSummary.childSupportIncomeMembers[
                memberIncomeIndex
              ].incomes[questionIndex].isRemoved =
                this.incomeSummary.childSupportIncomeMembers[
                  memberIncomeIndex
                ].incomeInputs[questionIndex].isRemoved;
            }
          }
        }
        }
        // else if(incomeSection.subsections[memberIncomeIndex].isRemoved){
        //   incomeSection.subsections.splice(incomeSection.subsections.indexOf(incomeSection.subsections[memberIncomeIndex]),1);
        // }
      }
    
    this.incomeSummary.childSupportIncomeMembers =
      this.incomeSummary.childSupportIncomeMembers.filter((element) => {
        return element !== undefined;
      });
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.childSupportNonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
    // this.props.appendRecertExpenseDetails(
    //   incomeSection,
    //   this.filterData(
    //     this.props.recert.sections,
    //     "expenses"
    //   ).subsections.indexOf(
    //     this.filterData(
    //       this.filterData(this.props.recert.sections, "expenses").subsections,
    //       "childsupport-expenses"
    //     )
    //   )
    // );
  };
  processChildSupportSectionData = () => {
    if (
      this.props.recert.sections.length > 6 &&
      (this.props.recert.obligationChildSupportChanged ===
        TextProps.VALUE_TRUE ||
        this.props.recert.courtOrderedChildSupportChanged ===
          TextProps.VALUE_TRUE)
    ) {
      let childExpExisting = _.cloneDeep(
        this.props.recert.responseObject.childSupportExpense
      );
      let childExpNew = this.incomeSummary.childSupportIncomeMembers;
      childExpNew.forEach((newExp) => {
        let newMem = newExp.firstName;
        if (childExpExisting.length) {
          childExpExisting.forEach((oldExp) => {
            if (
              oldExp.firstName +
                " " +
                oldExp.lastName +
                formatters.formatDate(oldExp.dateOfBirth) ===
              newMem
            ) {
              if (newExp.incomes[0].isRemoved === true) {
                newExp.incomes[0].Answer.code = "removed";
              } else if (oldExp.dataChangeCode === "NEW") {
                newExp.incomes[0].Answer.code = "new";
              } else if (
                ((newExp.incomes[0].Answer.amount.toString() !==
                  oldExp.amount.toString() &&
                  newExp.incomes[0].isRemoved !== true &&
                  newExp.incomes[0].Answer.code !== "new") ||
                  newExp.incomes[0].Answer.unit !==
                    getShelterExpenseFrequency(oldExp.frequencyCode)) &&
                newExp.incomes[0].isRemoved !== true &&
                newExp.incomes[0].Answer.code !== "new"
              ) {
                newExp.incomes[0].Answer.code = "changed";
              } else if (oldExp.dataChangeCode === "NOCHNG") {
                newExp.incomes[0].Answer.code = "existing";
              } else if (oldExp.dataChangeCode === "CHANGE") {
                newExp.incomes[0].Answer.code = "changed";
              } else if (newExp.incomes[0].Answer.code === "removed") {
                newExp.incomes[0].Answer.code = "removed";
              } else if (
                newExp.incomes[0].Answer.code !== "changed" &&
                newExp.incomes[0].Answer.code !== "new"
              ) {
                newExp.incomes[0].Answer.code = "existing";
              }
            } else if (
              newExp.firstName &&
              newExp.incomes[0].Answer.code !== "changed" &&
              newExp.incomes[0].Answer.code !== "removed" &&
              newExp.incomes[0].Answer.code !== "existing"
            ) {
              // let filtered = _.findIndex(childExpExisting, {
              //   firstName: newExp.firstName.split(" ")[0],
              //   lastName: newExp.firstName.split(" ")[1],
              //   dateOfBirth: " "+newExp.firstName.split(" ")[2],
              // });
              // if(filtered < 0){
              newExp.incomes[0].Answer.code = "new";
              // }
            }
          });
        } else {
          newExp.incomes[0].Answer.code = "new";
        }
      });
    }
  };
  gotoChildSupportSummary = () => {
    this.prepareChildSupportSummary();
    this.onSectionChange(this.state.childSupportSummaryTemplate);
  };
  getIncomeSummaryTitle = (name, before, after) => {
    switch (language) {
      case types.ENGLISH:
      case types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case types.SPANISH:
      case types.PORTUGUESE:
      case types.VIETNAMESE:
      case HI:
        return {
          before: before,
          after: " " + name,
        };
    }
  };
  gotoIncomeSummary = () => {
    this.prepareIncomeSummary();
    this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.incomeSummaryTemplate);
    });
  };
  gotoResourcesSummary = () => {
    this.prepareResourcesSummary();
    this.setState({ resourcesSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.resourcesSummaryTemplate);
    });
  };

  gotoUnearnedIncomeSummary = () => {
    this.prepareUnearnedIncomeSummary();
    this.setState({ incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.unearnedIncomeSummaryTemplate);
    });
  };

  onChildSupportSubsectionRender = (memberTemplate = null) => {
    let childSupportSummaryTemplate = this.state.recert_type === "IR" ?
     _.cloneDeep(filterData(
      filterData(this.props.recert.sections, "childsupport-expenses").subsections,
      "childsupport-expenses"
    ))
    : _.cloneDeep(
      filterData(
        filterData(this.props.recert.sections, "expenses").subsections,
        "childsupport-expenses"
      ).subsections[
        filterData(
          filterData(this.props.recert.sections, "expenses").subsections,
          "childsupport-expenses"
        ).subsections.length - 1
      ]
    );
  
    this.setState({
      childSupportSummaryTemplate: childSupportSummaryTemplate,
    });
    let childSupportIndex = this.state.recert_type === "IR" ? 
        this.props.recert.sections.findIndex(section => section.id === "childsupport-expenses")
        : filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
          filterData(
            filterData(this.props.recert.sections, "expenses").subsections,
          "childsupport-expenses"
        )
         );
    this.props.appendRecertExpenseDetails(
      onChildSupportSubsectionRenderHelper(memberTemplate,this.props.recert.responseObject,this.props.recert.sections,this.handleRemove,this.handleDone, this.state.recert_type),
      childSupportIndex
    );
    this.prepareChildSupportSummary();
    this.processChildSupportSectionData();
  };
  // indexing issue as now income summary is coming at 9th place 
  // try to apply filter but it failings in sign and submit
  onIncomeSubSectionClean = async () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          // incomeSummaryTemplate: this.filterData(
          //   this.filterData(this.props.recert.sections, "income-summary")
          //     .subsections,
          //   "combined-income"
          // ).subsections[
          //   this.filterData(
          //     this.filterData(this.props.recert.sections, "income-summary")
          //       .subsections,
          //     "combined-income"
          //   ).subsections.length - 1
          // ],
          incomeSummaryTemplate:  filterData(
            filterData(
              this.props.recert.sections,
              "income-summary"
            ).subsections,
            "income-summary"
          ),
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            filterData(this.props.recert.sections, "income-summary")
          );
          this.onIncomeSubsectionRender();
        }
      );
    }
  };
  onResourcesSubSectionClean = async () => {
    if (this.state.resourcesSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          resourcesSubSectionCleared: TextProps.VALUE_TRUE,
          resourcesMemberTemplate: _.cloneDeep(ResourcesSectionTemplate),
          resourcesSummaryTemplate: this.filterData(this.props.recert.sections, "resources-summary").subsections[
            this.filterData(this.props.recert.sections, "resources-summary").subsections.length - 1
          ]
        },
        () => {
          this.props.clearMemberResources(
            this.state.resourcesMemberTemplate,
            this.state.resourcesSummaryTemplate,
            this.filterData(this.props.recert.sections, "resources-summary")
          );
          this.onResourcesSubsectionRender();
        }
      );
    }
  };

  // UnEarnedIncome
  onUnearnedIncomeSubSectionClean = async () => {
    if (this.state.unearnedIncomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          unearnedIncomeSubSectionCleared: TextProps.VALUE_TRUE,
          unearnedIncomeMemberTemplate: _.cloneDeep(unearnedIncomeSectionTemplate),
          unearnedIncomeSummaryTemplate: filterData(
            filterData(
              this.props.recert.sections,
              "unearned-income-summary"
            ).subsections,
            "unearned-income-summary"
          ),
        },
        () => {
          this.props.clearMemberUIIncome(
            this.state.unearnedIncomeMemberTemplate,
            this.state.unearnedIncomeSummaryTemplate,
            filterData(
              this.props.recert.sections,
              "unearned-income-summary"
            )
          );
          this.onUnearnedIncomeSubsectionRender();
        }
      );
    }
  };
  _renderHouseholdInfo = () => {
    let householfInfoTemp = _.cloneDeep(
      filterData(this.props.recert.sections, "household-information")
    );
    householfInfoTemp.questions.map((info) => {
      info.conditionals[0].conditionalQuestions[0].options = [];
      this.props.recert.householdMembers.membersList
        .filter(
          (item) =>
            !(
              item.dataChangeCode === "removed" ||
              item.dataChangeCode === "REMOVE"
            )
        )
        .map((mem, indx) => {
          let optionObj = {};
          optionObj.optionValue = indx + 1;
          optionObj.optionDisplay =
            mem.firstName +
            " " +
            mem.lastName +
            formatters.formatDate(mem.dateOfBirth);
          optionObj.memInfo = mem;
          info.conditionals[0].conditionalQuestions[0].options.push(optionObj);
        });
    });
    this.props.recert.sections[
      this.props.recert.sections.indexOf(
        filterData(this.props.recert.sections, "household-information")
      )
    ] = householfInfoTemp;
  };

  _renderNextSection = (activeId) => {
    let action = TextProps.VALUE_TRUE;
    if (
      activeId === "utility-expenses-section" ||
      activeId.includes("combined-income-subsection") || activeId.includes("combined-resources-subsection")
    ) {
      action = TextProps.VALUE_FALSE;
    }
    let nextSectionId = getNextSection(activeId, this.state.sections);
    if(activeId === 'income-summary' && nextSectionId === 'income-summary'){
      nextSectionId = "unearned-income-summary";
    }
    if(activeId === 'resources-summary' && nextSectionId === 'resources-summary'){
      nextSectionId = this.state.recert_type === "RECERT" ? "students" : 'income-summary';
    }
    if(activeId === 'unearned-income-summary' && nextSectionId === 'unearned-income-summary'){
      nextSectionId = "shelter-utility-summary";
    }
    if(activeId === 'childsupport-expenses' && nextSectionId === 'childsupport-expenses'){
      if(this.state.recert_type === "IR"){
        nextSectionId = "shelter-utility-summary";
      }else{
        nextSectionId = "medical-expenses";
      }
    }
    if(activeId === 'medical-expenses' && nextSectionId === 'medical-expenses'){
      nextSectionId = "summary";
    }
    if (activeId === "add-contact-info") {
      nextSectionId = "contact-info";
    }
    if (activeId === "household-member") {
      nextSectionId = "my-household";
    }
    if (activeId.includes("combined-resources-subsection")) {
      nextSectionId = "resources-summary";
    }
    if (activeId.includes("combined-income-subsection")) {
      nextSectionId = "income-summary";
    }
    if (activeId.includes("unearned-combined-income-subsection")) {
      nextSectionId = "unearned-income-summary";
    }
    if (activeId == "medical-expenses") {
      this.prepareMedicalExpensesSummary();
      this.renderMedicalExpensesSubSections(true);
    }
    if (activeId.includes("child-support-amount")) {
      nextSectionId = "childsupport-expenses";
    }
    if (activeId.includes("tanf-household-information")) {
      nextSectionId = "summary";
    }

    //Function to Execute before Next page renders
    switch (nextSectionId) {
      //Function to Manipulate Details on HouseHoldPage page
      case "expedited-snap":
        if (this.expediatedSnapServicesValidated()) {
          nextSectionId = "expedited-snap";
        } else {
          nextSectionId = "my-household";
        }
        break;
      case "shelter-expense-subsection":
        if(!this.isShelterExpenseDataSelected()){
          nextSectionId = "utility-expenses-section";
        }
        break;
      case "medical-expenses":
        this.prepareMedicalExpensesSummary();
        this.renderMedicalExpensesSubSections(true);
        action = TextProps.VALUE_FALSE;
        // nextSectionId = "sign-and-submit";
        break;
      case "household-information":
        this.updateHouseHoldIncomeDetails();
        this._renderHouseholdInfo();
        break;
      case "expenses":
        if(this.state.recert_type !== "REEVAL") {
          nextSectionId = "shelter-utility-summary";
          this.shelterSubsectionRender();
          this.prepareShelterSummary();
        }
        break;
      case "shelter-utility-summary":
        this.prepareShelterSummary();
        // nextSectionId = "Dependentcarecosts";
        break;
      case "noncitizen":
        if (!filterData(this.props.recert.sections, "noncitizen").active) {
          nextSectionId = "students";
          this.renderStudents();
        }
        break;
      case "students":
        if (
          filterData(
            this.props.recert.sections,
            "expenses"
          ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
            .active !== TextProps.VALUE_FALSE
        )
          this.updateHouseHoldIncomeDetails();
        this.renderStudents();
        break;
      case "resources-summary":
          // case "household-information":
          this.updateHouseHoldIncomeDetails();
          // if (
          //   !(
          //     this.resourcesSummary &&
          //     this.resourcesSummary.resourcesMembers &&
          //     this.resourcesSummary.resourcesMembers.length
          //   )
          // ) {
            this.onResourcesSubSectionClean();
            this.onResourcesSubsectionRender();
          // }
        break;
      case "income-summary":
        this.updateHouseHoldIncomeDetails();
        this.onIncomeSubSectionClean();
        this.onIncomeSubsectionRender();
        this.prepareUnearnedIncomeSummary();
        break;
      case "unearned-income-summary":
        this.updateHouseHoldIncomeDetails();
        this.onUnearnedIncomeSubSectionClean();
        this.onUnearnedIncomeSubsectionRender();
        break;
      case "income":
        this.updateHouseHoldIncomeDetails();
        break;
      case "sign-and-submit":
        this.renderSubmitSummary();
        break;
      case "childsupport-expenses":
        this.onChildSupportSubsectionRender();
        break;
      default:
        break;
    }
    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection, action);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };
  _renderCurrentSection = (activeId) => {
    let nextSectionId = getNextSection(activeId, this.state.sections);
    //Function to Execute before Next page renders
    if (activeId.includes("tanf-household-information")) {
      nextSectionId = "summary";
    }
    switch (nextSectionId) {
      //Function to Manipulate Details on HouseHoldPage page
      case "medical-expenses":
        this.mapMemberListOptions();
        break;
      case "household-information":
        this._renderHouseholdInfo();
        break;
      case "expenses":
        if(this.state.recert_type !== "REE")
        nextSectionId = "shelter-utility-summary";
        this.shelterSubsectionRender();
        this.prepareShelterSummary();
        break;
      case "shelter-utility-summary":
        this.prepareShelterSummary();
        // nextSectionId = "Dependentcarecosts";
        break;
      case "noncitizen":
        if (!filterData(this.props.recert.sections, "noncitizen").active) {
          nextSectionId = "students";
          this.renderStudents();
        }
        break;
      case "students":
        if (
          filterData(
            this.props.recert.sections,
            "expenses"
          ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
            .active !== TextProps.VALUE_FALSE
        )
          this.updateHouseHoldIncomeDetails();
        this.renderStudents();
        break;
      case "sign-and-submit":
        this.renderSubmitSummary();
        break;
      default:
        break;
    }
    if (activeId) {
      const nextSection = this._getSection(activeId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };
  renderSubmitSummary = () => {
    return (
      <SubmitSummary
        agencyID={this.state.agencyID}
        referenceNumber={this.state.referenceNumber}
        docReferenceNumber={this.state.docReferenceNumber}
        recertType={this.state.recert_type}
      />
    );
  };

  _dataCheckValidation = (data) => {
    let recert = [
      "unearnedIncome",
      "shelterExpenses",
      "medicalExpenses",
      "earnedIncome",
      "childSupportExpense",
    ];
    let interim =  [
      "unearnedIncome",
      "shelterExpense",
      "earnedIncome",
      "childSupportExpense",
    ];

    let tanfdc =  [
      "unearnedIncome",
      "earnedIncome",
    ];

    let tabDataCheck = this.state.recert_type === "REEVAL" ? tanfdc : this.state.recert_type === "IR" ? interim : recert;
    tabDataCheck.map((tab) => {
      data[tab].map((item) => {
        if (!("dataChangeCode" in item) || !item.dataChangeCode) {
          item["dataChangeCode"] = "NOCHNG";
        }
      });
    });
    return data;
  };

  formatValidTypecodes = (data) => {
    data.earnedIncome = data.earnedIncome.length ? data.earnedIncome.filter((item) => earnedIncomeTypecode.includes(item.typeCode)) : [];
    data.unearnedIncome = data.unearnedIncome.length ? data.unearnedIncome.filter((item) => unearnedIncomeTypecode.includes(item.typeCode)) : [];
    if(this.state.recert_type !== "REEVAL"){
      data.childSupportExpense = data.childSupportExpense.length ? data.childSupportExpense.filter((item) => childSupportTypecode.includes(item.typeCode)) : [];
      if(this.state.recert_type === "IR"){
        data.shelterExpense = data.shelterExpense.length ? data.shelterExpense.filter((item) => shelterTypecode.includes(item.typeCode)) : [];
      }else{
        data.shelterExpenses = data.shelterExpenses.length ? data.shelterExpenses.filter((item) => shelterTypecode.includes(item.typeCode)) : [];
        data.resources = data.resources.length ? data.resources.filter((item) => resourcesTypecode.includes(item.resourceType)) : [];
      }
    }
    return data;
  }

  getCompletedSections = (currentSectionId) => {
    let completedSections = [];
    if(this.props.recert.additionalProperties && "completedSections" in this.props.recert.additionalProperties){
      completedSections = this.props.recert.additionalProperties.completedSections
    }
    if(!completedSections.includes("before-you-start")){
      completedSections.push("before-you-start");
    }
    if(!completedSections.includes(currentSectionId)){
      completedSections.push(currentSectionId);
    }
    if(!completedSections.includes("expenses") && this.props.recert.sections.filter((item) => item.id === "expenses").length){
      let expenseSectionStarted = filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter((sub) => sub.completed === TextProps.VALUE_TRUE);
      let expenseSectionCompleted = filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.filter((sub) => !sub.hiddenFromNav === TextProps.VALUE_TRUE);
      let expenseCompletedIds = expenseSectionStarted.map(item => item.id);
      let expensesSectionsIds = expenseSectionCompleted.map(item => item.id);
      if(expensesSectionsIds.includes(currentSectionId) && !expenseCompletedIds.includes(currentSectionId)){
        expenseCompletedIds.push(currentSectionId);
      }

      if(expenseSectionStarted.length === expenseSectionCompleted.length || _.isEqual(expenseCompletedIds.sort(), expensesSectionsIds.sort())){
        completedSections.push("expenses");
      }
    }
    
    return completedSections;
  }

  findPreviousSubSection = (item,activeId) => {
    let matchedPreviousId = {found: false, previousSectionId: ""};
    item.subsections.map((subItem,i) => {
      if(matchedPreviousId.found === false){
        if(subItem.id === activeId){
          if(i === 0){
            matchedPreviousId = {found: true, previousSectionId: item.id}
          }else if(i>0){
            matchedPreviousId = {found: true, previousSectionId: item.subsections[i-1].id}
          }
        }
        
        if(matchedPreviousId.found === false && "subsections" in subItem && subItem.subsections && subItem.subsections.length){
          let matchedPreviousSubId = this.findPreviousSubSection(subItem,activeId);
          if(matchedPreviousSubId && matchedPreviousSubId.found){
            matchedPreviousId = matchedPreviousSubId;
          }
        }
      }   
    });
    return matchedPreviousId;
  }

  findPreviousSection = (activeId, sections) => {
    let found = false;
    let previousSectionId = "";
    let previousSectionData = {};
    sections.map(item => {
      if(found === false){
        if("subsections" in item && item.subsections && item.subsections.length){
          previousSectionData = this.findPreviousSubSection(item,activeId);
          if(previousSectionData.found){
            found = previousSectionData.found;
            previousSectionId = previousSectionData.previousSectionId;
          }
        }
        if(found === false && item.id === activeId){
          found = true;
        }
        if(found === false && item.completed === TextProps.VALUE_TRUE){
          previousSectionId = item.id
        }
      }
    })
    return previousSectionId;
  }

  prepareDataForSubmission = (activeId, saveAndContinue, actionButtonType) => {
    let requestBody = null;
    let { additionalProperties } = this.props.recert.responseObject;

    const responseBody = _.cloneDeep(this.state.respData);
    requestBody = _.cloneDeep(responseBody);
  
    if(activeId === "add-auth-rep") {

      if (this.verifyFieldValidationOnSave() === TextProps.VALUE_FALSE) {
        // requestBody.snapAuthRepFlg = this.state.snapAuthRepFlg,
        // requestBody.ebtAuthRepFlg =  this.state.ebtAuthRepFlg,
        // requestBody.authorizedRepresentatives = {...this.state.addAuthRepData};
        // requestBody= prepareDataForSubmissionHelper(activeId, responseBody, this.props.recert, this.refs);
        requestBody = prepareDataForSubmissionHelper(activeId, responseBody,this.props.recert,this.shelterSummary,this.incomeSummary,this.medicalSummary,this.refs, this.state.ebtAuthRepFlg,this.state.snapAuthRepFlg, this.state.recert_type);
      }
    }
    else {
      requestBody = prepareDataForSubmissionHelper(activeId, responseBody,this.props.recert,this.shelterSummary,this.incomeSummary,this.medicalSummary,this.refs,this.state.ebtAuthRepFlg,this.state.snapAuthRepFlg, this.state.recert_type);
    }
    if (this.props.recert.contactInfoScreen.contactInfoChanged) {
      this.setDataCodeChangeFlags();
    }
    // requestBody = prepareDataForSubmissionHelper(activeId, responseBody,this.props.recert,this.shelterSummary,this.incomeSummary,this.medicalSummary,this.refs);
    if (this.state.recert_type === "AESAP") {
      requestBody.isAesap = true;
    }
    let studentsInfo = [];
    if (saveAndContinue) {
      let currentSection = this._getSection(activeId);
      requestBody.status = "DRAFT";
      if(this.state.recert_type !== "IR"){
      requestBody.recertId = this.props.recert.recertId;
      }
      if (this.state.recert_type !== "REEVAL"){
        if (activeId === "students") {
          studentsInfo = filterData(this.props.recert.sections, "students");
          studentsInfo.subsections &&
            studentsInfo.subsections.forEach((sub) => {
              sub.completed = TextProps.VALUE_TRUE;
            });
        } 
        else if (
          this.props.recert.responseObject &&
          this.props.recert.responseObject.additionalProperties &&
          this.props.recert.responseObject.additionalProperties.studentsInfo &&
          this.props.recert.responseObject.additionalProperties.studentsInfo !==
            null
        ) {
          studentsInfo = filterData(this.props.recert.sections, "students");
        } 
        else {
          studentsInfo = null;
        }
      }
      let contactInfoScreen = _.cloneDeep(this.props.recert.contactInfoScreen);
      if (this.props.recert.contactInfoScreen.contactInfoChanged) {
        // contactInfoScreen.contactInfoChanged = false;
      }
      let validatedAddrsStored = [];
      if (this.props.recert.residentailAddressStored) {
        validatedAddrsStored.push(this.props.recert.residentailAddressStored);
      }
      if (this.props.recert.mailingAddressStored) {
        validatedAddrsStored.push(this.props.recert.mailingAddressStored);
      }
      contactInfoScreen.validatedAddrsStored = validatedAddrsStored;
      let householdMembers = _.cloneDeep(this.props.recert.householdMembers);
      if (
        this.props.recert.householdMembers &&
        this.props.recert.householdMembers.membersListUpdated
      ) {
        // householdMembers.membersListUpdated = false;
      }
      let dependentValue = [];
      let expenseSectionStarted = [];
      let expenseSectionCompleted = [];
      if (this.state.recert_type !== "REEVAL"){
        if (filterData(this.props.recert.sections, "expenses")) {
          dependentValue = filterData(
            this.props.recert.sections,
            "expenses"
          ).subsections.filter((sub) => sub.id === "Dependentcarecosts");
          expenseSectionStarted = filterData(
            this.props.recert.sections,
            "expenses"
          ).subsections.filter((sub) => sub.completed === TextProps.VALUE_TRUE);
          expenseSectionCompleted = filterData(
            this.props.recert.sections,
            "expenses"
          ).subsections.filter((sub) => !sub.hiddenFromNav === TextProps.VALUE_TRUE);
          if(expenseSectionStarted.length === expenseSectionCompleted.length){
            filterData(this.props.recert.sections, "expenses").completed = TextProps.VALUE_TRUE;
          }
        }
      }

      //Dependent care AnswerObject value
      let answerObjectCopy = this.state.AnswerObject;
      let answerObjectDependentCare = [];
      for (let answer of Object.keys(answerObjectCopy)) {
        let dependentAnswerObj = {};
        if (
          answer.includes("Dependentcarecosts") ||
          answer.includes("kids-and-adults")
        ) {
          console.log(answerObjectCopy[answer]);
          dependentAnswerObj[answer] = answerObjectCopy[answer];
          answerObjectDependentCare.push(dependentAnswerObj);
        }
      }
      if (this.state.recert_type === "REEVAL") {
        requestBody.pendingHours = null;
        requestBody.householdInformation = {
          registerVoteFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.registerVoteFlg : "",
          changeAddressFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.changeAddressFlg : "",
          noRegisterToVoteFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.noRegisterToVoteFlg : "",
          hurtFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.hurtFlg : "",
          hurtingNowFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.hurtingNowFlg : "",
          afraidOfFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.afraidOfFlg : "",
          otherInfoFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.otherInfoFlg : "",
          comments: this.props.recert.householdInformation ? this.props.recert.householdInformation.comments : "",
          domesticRefFlg: this.props.recert.householdInformation ? this.props.recert.householdInformation.domesticRefFlg : "",
        }
        requestBody.updateDateTime = null;
      }
      let activeSectionId = (currentSection.parentId ? currentSection.parentId : currentSection.id) || activeId;
      if(this.state.recert_type === "REEVAL") {
        delete requestBody.dependentCareExpenses;
        delete requestBody.webHouseholdInfo;
        delete requestBody.medicalExpenses;
        delete requestBody.childSupportExpense;
        delete requestBody.students;
        delete requestBody.needs;
        delete requestBody.noncitizenStatus;

        requestBody.additionalProperties = {
          hOHRemovedSections: this.state.hOHRemovedSections,
          activeSectionId: activeSectionId,
          completedSections: this.getCompletedSections(activeSectionId),
          contactInfoScreen: contactInfoScreen,
          householdMembers: householdMembers,
          earnedIncomeChanged: this.props.recert.earnedIncomeChanged,
          unearnedIncomeChanged: this.props.recert.unearnedIncomeChanged,
          grossEarnedIncomeChanged: this.props.recert.grossEarnedIncomeChanged,
          additionalhouseholdInformation: this.state.additionalhouseholdInformation
        }
      }
      else {
        requestBody.additionalProperties = {
          hOHRemovedSections: this.state.hOHRemovedSections,
          activeSectionId: activeSectionId,
          completedSections: this.getCompletedSections(activeSectionId),
          contactInfoScreen: contactInfoScreen,
          householdMembers: householdMembers,
          studentsInfo: studentsInfo,
          authRepChanged: this.props.recert.authRepChanged,
          earnedIncomeChanged: this.props.recert.earnedIncomeChanged,
          householdResourcesChanged:this.props.recert.householdResourcesChanged,
          unearnedIncomeChanged: this.props.recert.unearnedIncomeChanged,
          grossEarnedIncomeChanged: this.props.recert.grossEarnedIncomeChanged,
          Dependentcarecosts:
            dependentValue.length && dependentValue[0].questions[0].Answer
              ? dependentValue
              : [],
          answerObjectDependentCare: answerObjectDependentCare,
          expensesInputted: expenseSectionStarted.length ? "expenses" : null,
          obligationChildSupportChanged: 
            this.props.recert.obligationChildSupportChanged !== null
              ? this.props.recert.obligationChildSupportChanged
              : null,
          courtOrderedChildSupportChanged:
            this.props.recert.courtOrderedChildSupportChanged !== null
              ? this.props.recert.courtOrderedChildSupportChanged
              : null,
          shelterUtilityConfirmationChanged:
            this.props.recert.shelterUtilityConfirmationChanged !== null
              ? this.props.recert.shelterUtilityConfirmationChanged
              : null,
          medicalExpensesSummaryChanged:
            this.props.recert.medicalExpensesSummaryChanged !== null
              ? this.props.recert.medicalExpensesSummaryChanged
              : null,
        };
      }
      if(this.props.recert.additionalProperties){
        if(!("initialResourcesData" in this.props.recert.additionalProperties)){
          requestBody.additionalProperties["initialResourcesData"] = this.props.recert.responseObject.resources
        }else{
          requestBody.additionalProperties["initialResourcesData"] = this.props.recert.additionalProperties.initialResourcesData
        }
      } 
    } else {
      requestBody.status = "RECVED";
    }

    let selectedConsumerEmail = sessionStorage.getItem("selectedConsumerEmail");
    let user_email = sessionStorage.getItem("user_name");
    
    requestBody.clEmail = selectedConsumerEmail ? selectedConsumerEmail : user_email;

    if (actionButtonType === TextProps.SAVEEXIT) {
      setTimeout(
        () =>
          this.setState({
            isLoading: TextProps.VALUE_FALSE,
          }),
        1000
      );
    }
    if(activeId === "contact-info"){
      requestBody = this.formatValidTypecodes(requestBody);
    }

    this.setState(
      {
        isLoading: TextProps.VALUE_TRUE,
        requestBody: requestBody,
      },
      () => {
        if (saveAndContinue) {
          var auditData = getAudit();
          this.props
            .saveRecertData(this.state.requestBody, auditData, actionButtonType, this.state.recert_type)
            .then((result) => {
              if (
                result &&
                actionButtonType !== TextProps.SAVEEXIT &&
                actionButtonType !== TextProps.BACK && actionButtonType !== TextProps.SIGNSUBMIT 
              ) {
                this.props.saveRecretDataAction(result);
                let renderedSectionsCopy = [];
                let incomingSection = this._getSection(activeId);
                renderedSectionsCopy.push(incomingSection);
                this.setState(
                  {
                    renderedSections: renderedSectionsCopy,
                    isLoading: TextProps.VALUE_FALSE,
                    respData: result,
                  },
                  () => {
                    this._renderNextSection(activeId);
                    if (!incomingSection.navigateToPrimaryScreen) {
                      this.subSectionData(incomingSection, activeId);
                    }
                  }
                );
                // }
              } else if (result && actionButtonType === TextProps.SAVEEXIT) {
                setTimeout(
                  () =>
                    this.setState({
                      isLoading: TextProps.VALUE_FALSE,
                      saveExitShowModal: TextProps.VALUE_TRUE,
                      respData: result,
                    }),
                  2000
                );
              } else if (result && actionButtonType === TextProps.BACK) {
                this.goBackHandler();
                this.setState({
                  isLoading: TextProps.VALUE_FALSE,
                  respData: result,
                });
              }else if (result && actionButtonType === TextProps.SIGNSUBMIT) {
                this.props.saveRecretDataAction(result);
                this._updateCurrentSection(activeId);
                this.redirectToSignAndSubmit();
                this.setState({
                  isLoading: TextProps.VALUE_FALSE,
                  respData: result,
                });
              } else {
                this.setState({
                  isLoading: TextProps.VALUE_FALSE,
                });
              }
              if (sessionStorage.getItem("acesButtonClickedFlag") === "true") {
                sessionStorage.setItem("acesButtonClickedFlag", false);
                this.redirectToHomePage();
              }
            })
            .catch((error) => {
              // this.setState({ isLoading: TextProps.VALUE_FALSE });
              // this.props.displayError();
            });
        } else {
          var auditData = getAudit();
          let submitRequsestBody = _.cloneDeep(this.state.requestBody);
          if(submitRequsestBody.resources && submitRequsestBody.resources.length){
            submitRequsestBody.resources.map(resource => resource.datachangeCode === null ?  resource.datachangeCode = "NOCHNG" : resource.datachangeCode)
          }
          delete submitRequsestBody.additionalProperties;
          if(submitRequsestBody.resources){
            let filteredGrouppedResources = [];
            submitRequsestBody.resources.map(grp => {
              let detailsExists = grp.resourceDetails.filter(details => details.amount);
              if(detailsExists.length){
                grp.resourceDetails = detailsExists;
                filteredGrouppedResources.push(grp);
              }else{
                const previous = this.state.requestBody.additionalProperties.initialResourcesData.filter(item => (item.resourceType === grp.resourceType && item.firstName === grp.firstName && item.lastName === grp.lastName && item.dateOfBirth === grp.dateOfBirth));
                if(previous && previous.length)
                  grp.resourceDetails = previous[0].resourceDetails;
                grp.datachangeCode = "REMOVE";
                filteredGrouppedResources.push(grp);
              }
              
            })
            submitRequsestBody.resources = filteredGrouppedResources;
          }
          if (this.state.isHOHRemoved) {
            submitRequsestBody.earnedIncome = [];
            submitRequsestBody.unearnedIncome = [];
            if(this.state.recert_type !== "REEVAL"){
              submitRequsestBody.students = [];
              submitRequsestBody.shelterExpenses = [];
              submitRequsestBody.medicalExpenses = [];
              submitRequsestBody.childSupportExpense = [];
              submitRequsestBody.dependentCareExpenses = [];
              submitRequsestBody.utilityExpenses = _.cloneDeep(utilityObj);
              submitRequsestBody.webHouseholdInfo = [];
            }
          }
            if(this.state.addAuthRepData.ebtWebDataChangeCode === null) {
              submitRequsestBody.authorizedRepresentatives = this.props.recert.authorizedRepresentatives;
              if(submitRequsestBody.authorizedRepresentatives && submitRequsestBody.authorizedRepresentatives.ebtWebDataChangeCode !== undefined){
              submitRequsestBody.authorizedRepresentatives.ebtWebDataChangeCode = "NOCHNG"
            }
            }
            if(this.state.addAuthRepData.snpWebDataChangeCode === null) {
              submitRequsestBody.authorizedRepresentatives = this.props.recert.authorizedRepresentatives;
              if(submitRequsestBody.authorizedRepresentatives && submitRequsestBody.authorizedRepresentatives.snpWebDataChangeCode !== undefined){
              submitRequsestBody.authorizedRepresentatives.snpWebDataChangeCode = "NOCHNG"
              }
            }
          var agencyID = this.props.recert.responseObject.agencyID;
          var requestBody_update =
            this._dataCheckValidation(submitRequsestBody);
            if(this.state.recert_type === "REEVAL") {
              delete requestBody_update.webHouseholdInfo;
              delete requestBody_update.dependentCareExpenses;
              delete requestBody_update.childSupportExpense;
              delete requestBody_update.students;
              delete requestBody_update.medicalExpenses;
              delete requestBody_update.noncitizenStatus;
              delete requestBody_update.needs;
              
            }
          this.props
            .submitRecertData(requestBody_update, auditData, this.state.recert_type)
            .then((result) => {
              if (result) {
                this.setState(
                  {
                    isLoading: TextProps.VALUE_FALSE,
                    referenceNumber: result.referenceNumber,
                    docReferenceNumber: result.docReferenceNumber,
                    agencyID: agencyID,
                  },
                  () => {
                    this.disableRecertSections();
                    this._renderNextSection(activeId);
                  }
                );
              } else {
                this.setState({ isLoading: TextProps.VALUE_FALSE });
                this.props.displayError();
              }
            })
            .catch((error) => {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.displayError();
            });
        }
      }
    );
  };

  getCurrentSectionParentId = (activeId) => {
    if (activeId !== "" && activeId !== undefined && activeId !== null) {
      var activeIdSplit = activeId.split("-");
      var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
      var activeIdSubString = activeIdSplit.join("-");
    }
    switch (activeIdSubString) {
      case "kids-and-adults":
        // this.addAddressesForDepCare(activeSplitMemberIndex);
        break;
      case "student":
        // this.renderCaringKidsAdults();
        break;
      case "child-support-amount":
        // const { sections } = this.props.recert;
        // let previousSectionId = getPreviousSection("childsupport-expenses", sections);
        return "childsupport-expenses";
        break;
      default:
        //CHECK IF KIDS AND ADULT ADDRESS. MAP THE MEMBER ID AND SEQUENCE
        if (activeIdSubString.indexOf("kids-adult-multiple-address-") >= 0) {
          // for (var member of this.filterData(
          //   this.props.recert.sections,
          //   "expenses"
          // ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
          //   .questions[0].options) {
          //   if (member.name === dataSections.title) {
          //     dataSections.clientId = member.id;
          //   }
          //   if (
          //     this.filterData(
          //       this.props.recert.sections,
          //       "expenses"
          //     ).subsections.filter(
          //       (sub) => sub.id === "Dependentcarecosts"
          //     )[0].currentExpSeq !== undefined &&
          //     this.filterData(
          //       this.props.recert.sections,
          //       "expenses"
          //     ).subsections.filter(
          //       (sub) => sub.id === "Dependentcarecosts"
          //     )[0].currentExpSeq !== null
          //   ) {
          //     dataSections.currentExpSeq = this.filterData(
          //       this.props.recert.sections,
          //       "expenses"
          //     ).subsections.filter(
          //       (sub) => sub.id === "Dependentcarecosts"
          //     )[0].currentExpSeq;
          //   }
          // }
        }
        return activeId;
        break;
    }
  };

  disableRecertSections = () => {
    let sections = this.props.recert.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.recert.sections = sections;
      delete this.props.recert.responseObject;
    }
  };

  getMemberDataObjectByName = (name, listToCheck) => {
    let returnMember = null;
    listToCheck.map((member) => {
      let dob = member.dateOfBirth;
      if (dob.indexOf("-") > 0) {
        dob = formatters.formatDate(dob);
      }
      if (
        name === member.firstName + " " + member.lastName ||
        name === member.firstName + " " + member.lastName + dob
      ) {
        returnMember = member;
      }
    });
    return returnMember;
  };
  getMemberDataObjectByNameNew = (fn, ln, listToCheck) => {
    let returnMember = null;
    listToCheck.map((member) => {
      if (fn === member.firstName && ln === member.lastName) {
        returnMember = member;
      }
    });
    return returnMember;
  };

  retainDependentCareData = () => {
    let dependentCosts = filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "Dependentcarecosts"
    );
    let dependentCopy = _.cloneDeep(
      filterData(
        filterData(this.props.recert.sections, "expenses").subsections,
        "Dependentcarecosts"
      )
    );
    if (
      dependentCopy.subsections !== undefined &&
      dependentCopy.subsections.length > 0
    ) {
      for (
        let memIndex = 0;
        memIndex < dependentCopy.subsections.length;
        memIndex++
      ) {
        if (dependentCopy.subsections[memIndex]) {
          for (
            var q = 0;
            q < dependentCopy.subsections[memIndex].questions.length;
            q++
          ) {
            dependentCosts.subsections[memIndex].questions[q].Answer =
              dependentCopy.subsections[memIndex].questions[q].Answer;
            dependentCosts.subsections[memIndex].questions[q].targetValue =
              dependentCopy.subsections[memIndex].questions[q].targetValue;
            dependentCosts.subsections[memIndex].questions[q].value =
              dependentCopy.subsections[memIndex].questions[q].Answer;
            if (q > 0) {
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].Answer =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].targetValue =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].targetValue;
              dependentCosts.subsections[memIndex].questions[
                q
              ].conditionals[0].conditionalQuestions[0].value =
                dependentCopy.subsections[memIndex].questions[
                  q
                ].conditionals[0].conditionalQuestions[0].Answer;
              if (
                dependentCopy.subsections[memIndex].questions[q].conditionals[0]
                  .conditionalQuestions[0].Answer !== undefined &&
                dependentCopy.subsections[memIndex].questions[q].conditionals[0]
                  .conditionalQuestions[0].Answer > 0
              ) {
                for (
                  let add = 0;
                  add <
                  dependentCopy.subsections[memIndex].questions[q]
                    .conditionals[0].conditionalQuestions[0].Answer;
                  add++
                ) {
                  if (
                    dependentCosts.subsections[memIndex].subsections &&
                    dependentCosts.subsections[memIndex].subsections[add] !==
                      undefined
                  ) {
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].Answer =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].value =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[0].targetValue =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[0].targetValue;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].Answer =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].value =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].Answer;
                    dependentCosts.subsections[memIndex].subsections[
                      add
                    ].questions[1].targetValue =
                      dependentCopy.subsections[memIndex].subsections[
                        add
                      ].questions[1].targetValue;
                  }
                }
              }
            }
          }
        }
      }
    }
    let dependentSection = filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "Dependentcarecosts"
    );
    dependentSection = dependentCosts;
  };
  /*start Shelter and Utility */
  prepareShelterSummary = () => {
    let shelterSection = this.state.recert_type === "IR" ? 
    filterData(this.props.recert.sections, "shelter-utility-summary")
    : filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "shelter-utility-summary"
    );
    this.shelterSummary.shelterExpense = [];
    let currentIncomeMembers = [];
    let utilityOldAnswers = this.props.recert.responseObject.utilityExpenses;
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < shelterSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.shelterSummary.shelterExpense[memberIncomeIndex] = {};
      this.shelterSummary.shelterExpense[memberIncomeIndex].section =
        shelterSection.subsections[memberIncomeIndex];
      this.shelterSummary.shelterExpense[memberIncomeIndex].id =
        shelterSection.subsections[memberIncomeIndex].id;
      this.shelterSummary.shelterExpense[memberIncomeIndex].firstName =
        shelterSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        shelterSection.subsections[memberIncomeIndex].title;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomeType =
        shelterSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomes = [];
      if (
        shelterSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.shelterSummary.shelterExpense[memberIncomeIndex].incomeInputs =
          shelterSection.subsections[
            memberIncomeIndex
          ].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.shelterSummary.shelterExpense[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
            questionIndex
          ] = {};
          this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
            questionIndex
          ].id =
            "shelterSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
          this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
            questionIndex
          ].typeCode =
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomeInputs[
              questionIndex
            ].valueToMatch;
          this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
            questionIndex
          ].Answer =
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomeInputs[
              questionIndex
            ].Answer;
        }
      }
    }

    let utilityNewAnswer = [];
    let utilityOld = [];
    let shelterUtilSec;
    if(this.state.recert_type === "IR"){
      shelterUtilSec = filterData(this.props.recert.sections, "shelter-utility-summary").subsections;
    } else {
      shelterUtilSec =  filterData(
        filterData(this.props.recert.sections, "expenses").subsections,
        "shelter-utility-summary"
      ).subsections;
    }

    if (
      this.props.recert.sections &&
      shelterUtilSec &&
      shelterUtilSec[0] &&
      shelterUtilSec[0].subsections[1]
    ) {
      utilityNewAnswer = shelterUtilSec[0].subsections[1].questions[0].Answer;
    }
    let finalUtilList = [];
    let utilityExpenseData = [];
    let utilityExpensesTemplateData = _.cloneDeep(utilityExpensesTemplate);
    let { responseObject } = this.props.recert;
    let utilityResponseObject = this.state.recert_type === "IR" ? responseObject.utilityExpense : responseObject.utilityExpenses;
    if (
      responseObject &&
      utilityResponseObject
    ) {
      let utilityExpenses = _.cloneDeep(
        utilityResponseObject
      );
      for (let key in utilityExpenses) {
        let utilObj = utilityExpensesTemplateData.filter(
          (util) => util.key === key
        )[0];
        if (utilObj) {
          if (
            utilityExpenses[utilObj.key] === TextProps.VALUE_TRUE &&
            utilityExpenses[utilObj.value] === "NEW"
          ) {
            utilityExpenseData.push({
              type: key,
              code: "new",
            });
          } else if (
            utilityExpenses[utilObj.key] === TextProps.VALUE_FALSE &&
            utilityExpenses[utilObj.value] === "REMOVE"
          ) {
            utilityExpenseData.push({
              type: key,
              code: "removed",
            });
          } else if (
            utilityExpenses[utilObj.key] === TextProps.VALUE_TRUE &&
            utilityExpenses[utilObj.value] === "REMOVE"
          ) {
            utilityExpenseData.push({
              type: key,
              code: "removed",
            });
          } else if (
            utilityExpenses[utilObj.key] === TextProps.VALUE_TRUE &&
            (utilityExpenses[utilObj.value] === "NOCHNG" ||
              utilityExpenses[utilObj.value] === null)
          ) {
            utilityExpenseData.push({
              type: key,
              code: "existing",
            });
          }
        }
      }
    }
    if (utilityNewAnswer) {
      utilityExpenseData.map((utl) => {
        if (!utilityNewAnswer.includes(utl.type)) {
          if (utl.code === "existing" || utl.code === "removed") {
            finalUtilList.push({
              type: utl.type,
              code: "removed",
            });
          }
        } else if (utilityNewAnswer.includes(utl.type)) {
          finalUtilList.push(utl);
        }
      });
    }
    if (utilityNewAnswer) {
      utilityNewAnswer.map((finUtil) => {
        let objExists = finalUtilList.filter((utl) => utl.type === finUtil)[0];
        if (!objExists) {
          finalUtilList.push({
            type:
              finUtil ===
              incomeSectionMultilanguageLabels(language)
                .noUtilityQOptionSelected
                ? "noUtilityFlag"
                : finUtil,
            code: "new",
          });
        }
      });
    }
    this.shelterSummary.utilityExpenses = finalUtilList;
  };
  shelterSubsectionRender = () => {
    let shelterUtilitySectionTemplateCopy = _.cloneDeep(
      shelterUtilitySectionTemplate
    );
    let { responseObject } = this.props.recert;
    let shelterResponseObject = this.state.recert_type === "IR" ? responseObject.shelterExpense : responseObject.shelterExpenses;
    let utilityResObject = this.state.recert_type === "IR" ? responseObject.utilityExpense : responseObject.utilityExpenses;
    if(this.state.recert_type === "IR"){
      shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions.splice(2,1);
    }
    let answer = [];
    let utilityAnswer = [];
    let shelterSection = this.state.recert_type === "IR" ?  filterData(this.props.recert.sections, "shelter-utility-summary") : filterData( filterData(this.props.recert.sections, "expenses").subsections, "shelter-utility-summary" );
    shelterUtilitySectionTemplateCopy.selectionMade = shelterSection.selectionMade;
    shelterUtilitySectionTemplateCopy.completed = shelterSection.completed;

    this.setState({
      shelterDefaultQuestions:
        shelterUtilitySectionTemplateCopy.subsections[0].subsections[0]
          .questions,
    });
    shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].shelterDefaultQuestions =
      shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions;
    this.props.setShelterDefaultQuestions(
      shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions
    );
    if (
      responseObject &&
      shelterResponseObject
    ) {
      shelterResponseObject.map((income) => {
        if (!answer.includes(income.typeCode)) {
          answer.push(income.typeCode);
        }
      });
    }
    if (
      responseObject &&
      utilityResObject
    ) {
      utilityAnswer = Object.keys(
        utilityResObject
      ).filter((key) => {
        if (
          utilityResObject[key] ===
            TextProps.VALUE_TRUE &&
          key !== "liheapFlag" &&
          key !== "lifelineFlag"
        ) {
          return key;
        } else if (
          utilityResObject[key] ===
            TextProps.VALUE_TRUE &&
          key === "liheapFlag"
        ) {
          shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[1].Answer =
            TextProps.VALUE_YES;
          shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[1].targetValue =
            TextProps.VALUE_YES;
        } else if (
          utilityResObject[key] ===
            TextProps.VALUE_TRUE &&
          key === "lifelineFlag"
        ) {
          shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[2].Answer =
            TextProps.VALUE_YES;
          shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[2].targetValue =
            TextProps.VALUE_YES;
        }
      });
    }
    if (utilityAnswer.length > 0) {
      if (utilityAnswer.includes("noUtilityFlag")) {
        shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[0].Answer =
          [incomeSectionMultilanguageLabels(language).noUtilityQOptionSelected];
        shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[0].targetValue =
          [incomeSectionMultilanguageLabels(language).noUtilityQOptionSelected];
      } else {
        shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[0].Answer =
          utilityAnswer;
        shelterUtilitySectionTemplateCopy.subsections[0].subsections[1].questions[0].targetValue =
          utilityAnswer;
      }
    }
    if (answer.length > 0) {
      shelterUtilitySectionTemplateCopy.subsections[0].questions[0].Answer =
        answer;
      shelterUtilitySectionTemplateCopy.subsections[0].questions[0].targetValue =
        answer;
    }

    for (
      let i = 0;
      i <
      shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions
        .length;
      i++
    ) {
      let amountArr = [];
      for (
        let j = 0;
        j < shelterResponseObject.length;
        j++
      ) {
        if (
          shelterUtilitySectionTemplateCopy.subsections[0].subsections[0]
            .questions[i].valueToMatch ===
            shelterResponseObject[j].typeCode
        ) {
          if (
            shelterResponseObject[j].amount !==
            undefined
          ) {
            amountArr.push({
              fullIncomeObj:
              shelterResponseObject[j],
              originalTypeCode:
              shelterResponseObject.typeCode,
              code: shelterResponseObject[j]
                .dataChangeCode
                ? shelterResponseObject[j]
                    .dataChangeCode
                : "NOCHNG",
              unit: getShelterandChildSupportFrequency(language)[
                shelterResponseObject[j]
                  .frequencyCode
              ],
              amount:
              shelterResponseObject[j].amount,
              id: "repeatable_id_" + this.nextUniqueId(),
            });
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ].Answer = amountArr;
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ][OptionsProps.TARGET_VALUE] = amountArr;
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ].value = amountArr;
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ].completed = TextProps.VALUE_FALSE;
          } else {
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ].Answer = [];
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ][OptionsProps.TARGET_VALUE] = [];
            shelterUtilitySectionTemplateCopy.subsections[0].subsections[0].questions[
              i
            ].completed = TextProps.VALUE_FALSE;
          }
        }
      }
    }
    const sectionIndex = this.state.recert_type === "IR" ? 
      this.props.recert.sections.indexOf(
        filterData(this.props.recert.sections, "shelter-utility-summary")
      )
      : filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        filterData(
          filterData(this.props.recert.sections, "expenses").subsections,
         "shelter-utility-summary"
        )
      );
    this.props.appendRecertExpenseDetails(shelterUtilitySectionTemplateCopy, sectionIndex);
  };
  shelterQuestionsBySelection = (memberIndex) => {
    let shelterSection = this.state.recert_type === "IR" ?
    filterData(this.props.recert.sections, "shelter-utility-summary")
    : filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "shelter-utility-summary"
    );
    let incomePageAnswers = shelterSection.subsections[0].questions[0].Answer;
    let oldQuestions =
      shelterSection.subsections[0].subsections[0].shelterDefaultQuestions;
    let shelterFilteredQuestions = [];
    if (shelterSection.subsections[0].subsections !== undefined && incomePageAnswers) {
      shelterFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        oldQuestions
      );
    }
    this.props.appendShelterFilteredQuestions(shelterFilteredQuestions);
  };
  shleterUtilityNochange = () => {
    this.shelterSubsectionRender();
    this.prepareShelterSummary();
  };
  setShelterUtilityInfo = (data) => {
    if (data !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
    // this.props.setDataChangeStatusForExpense(data);
  };
  renderShelterAndUtility = () => {
    return (
      <ShelterExpenses
        getIncomeSummaryTitle={this.getSummaryTitle}
        prepareShelterSummary={this.prepareShelterSummary}
        shelterSummary={this.shelterSummary}
        shelterSubsectionRender={this.shelterSubsectionRender}
        onSectionChange={this.onSectionChange}
        setShelterData={this.props.setShelterData}
        shelterResponseData={this.props.recert.responseObject}
        shleterUtilityNochange={this.shleterUtilityNochange}
        setShelterUtilityInfo={this.setShelterUtilityInfo}
        recertType={this.state.recert_type}
      />
    );
  };
  /*end Shelter and Utility */
  resourcesfilterQuestionsBySelection = (memberIndex) => {
    let resourcesSection = this.filterData(
      this.props.recert.sections,
      "resources-summary"
    );
    let resourcesPageAnswers =
    resourcesSection.subsections[memberIndex].questions[0].Answer;
    let resourcesFilteredQuestions = [];
    if (resourcesSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.resourcesSubSections[memberIndex] === undefined) {
      this.resourcesSubSections[memberIndex] = _.cloneDeep(
        resourcesSection.subsections[memberIndex]
      );
    } else {
      resourcesSection.subsections[memberIndex] = _.cloneDeep(
        this.resourcesSubSections[memberIndex]
      );
      resourcesSection.subsections[memberIndex].questions[0].Answer =
      resourcesPageAnswers;
      resourcesSection.subsections[memberIndex].questions[0].targetValue =
      resourcesPageAnswers;
    }
    this.filterData(
      this.props.recert.sections,
      "resources-summary"
    ).currentMember = this.resourcesSubSections[memberIndex].title;
    if (
      resourcesSection.subsections[memberIndex] &&
      resourcesSection.subsections[memberIndex].subsections &&
      resourcesSection.subsections[memberIndex].subsections[0]
    ) {
      const allQuestions =
      resourcesSection.subsections[memberIndex].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        questions;
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      resourcesPageAnswers[0] !== "" &&
      resourcesPageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      resourcesPageAnswers[0] !== undefined &&
      resourcesPageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      resourcesFilteredQuestions = filterQuestionsBySelection(
        resourcesPageAnswers,
        resourcesSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of resourcesFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer.length
                ? oldQuestval.Answer
                : "";
              filterQuestion.targetValue = oldQuestval.targetValue.length
                ? oldQuestval.targetValue
                : "";
              filterQuestion.value =
                oldQuestval.value && oldQuestval.value.length
                  ? oldQuestval.value
                  : "";
              let typeText =
                earnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      resourcesSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(resourcesFilteredQuestions);
      this.props.appendResourcesDetails(resourcesSection);
    } else {
      delete resourcesSection.subsections[memberIndex].subsections;
      this.prepareResourcesSummary(() => {
        this.props.appendResourcesDetails(resourcesSection);
        this.gotoResourcesSummary();
      });
    }
  };
  incomefilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = filterData(
      this.props.recert.sections,
      "income-summary"
    );
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.incomeSubSections[memberIndex] === undefined) {
      this.incomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.incomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    filterData(
      this.props.recert.sections,
      "income-summary"
    ).currentMember = this.incomeSubSections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer.length
                ? oldQuestval.Answer
                : "";
              filterQuestion.targetValue = oldQuestval.targetValue.length
                ? oldQuestval.targetValue
                : "";
              filterQuestion.value =
                oldQuestval.value && oldQuestval.value.length
                  ? oldQuestval.value
                  : "";
              let typeText =
                earnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendIRIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareIncomeSummary(() => {
        this.props.appendIRIncomeDetails(incomeSection);
        this.gotoIncomeSummary();
      });
    }
  };

  // unearnedIncome Filter Question
  incomeUnearnedfilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = filterData(
      this.props.recert.sections,
      "unearned-income-summary"
    );
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.unearnedIncomeSubSections[memberIndex] === undefined) {
      this.unearnedIncomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.unearnedIncomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    filterData(
      this.props.recert.sections,
      "unearned-income-summary"
    ).currentMember = this.unearnedIncomeSubSections[memberIndex].title;
    incomeSection.subsections[memberIndex].subsections[0].header = this.state.recert_type === "REEVAL" ? interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      this.unearnedIncomeSubSections[memberIndex].title,
      null,
      null
    ).tanfHeading :
      interimReportPageLabels(
        language,
        languageConstants.unearnedIncomeDynamic,
        this.unearnedIncomeSubSections[memberIndex].title,
        null,
        null
      ).heading;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer.length
                ? oldQuestval.Answer
                : "";
              filterQuestion.targetValue = oldQuestval.targetValue.length
                ? oldQuestval.targetValue
                : "";
              filterQuestion.value =
                oldQuestval.value && oldQuestval.value.length
                  ? oldQuestval.value
                  : "";

              let typeText =
                unearnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  // filterQuestion[Props.LABEL] = typeText;
                  // filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                // filterQuestion[Props.LABEL] = typeText;
                // filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            } else {
              let typeText =
                unearnedIncomeCodeMapper(language)[filterQuestion.valueToMatch];
              // filterQuestion[Props.LABEL] = typeText;
              // filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendUIIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareUnearnedIncomeSummary(() => {
        this.props.appendUIIncomeDetails(incomeSection);
        this.gotoUnearnedIncomeSummary();
      });
    }
  };
  expediatedSnapServicesValidated = () => {
    let expediatedSection = filterData(
      this.props.recert.sections,
      "expedited-snap"
    );
    if (expediatedSection.hiddenFromNav) {
      return false;
    } else {
      return true;
    }
  };
  isShelterExpenseDataSelected = () => {
    let shelterExpenseData = this.shelterSummary.shelterExpense[0].section.questions[0].Answer
    if(shelterExpenseData && shelterExpenseData.length && shelterExpenseData[0] !== incomeSectionMultilanguageLabels(language).houseQOptions[11]){
      return TextProps.VALUE_TRUE;
    }
    return TextProps.VALUE_FALSE;
  }
  //Function to goBack to previous section.
  goBackHandler = () => {
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.recert;

    // Finding Previous Section Id with below function.
    // let previousSectionId = getPreviousSection(activeId, sections); //Previous logic to find the Previous Section Id.
    let previousSectionId = this.findPreviousSection(activeId, sections);

    // Custom condition need when activeId is shelter-utility-summary and it should not redirect to expenses,
    // custom condition need when activeId is summary and it should redirect to expenses and to it's subsection,
    if(previousSectionId === "expenses"){
      if(activeId === "summary"){
        let expensesSectons = filterData( sections, "expenses" ).subsections.filter((sub) => sub.completed === TextProps.VALUE_TRUE);
        previousSectionId = expensesSectons[expensesSectons.length -1].id;
      }else if(activeId === "shelter-utility-summary"){
        let expensesIndex = sections.indexOf(filterData( sections, "expenses" ));
        let previousCompletedSection = sections.slice(0,expensesIndex).filter((sub) => sub.completed === TextProps.VALUE_TRUE);
        previousSectionId = previousCompletedSection[previousCompletedSection.length -1].id;
      }
    }

    if (previousSectionId) {
      const incomingQuestions = this._getSection(activeId);
      if (
        incomingQuestions.skipsubSectionNavigation !== undefined &&
        incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
      ) {
        const activeQuestionId = incomingQuestions.id;
        switch (activeQuestionId) {
          case "add-contact-info":
            previousSectionId = "contact-info";
            break;
          case "my-household":
              if (this.state.recert_type !== "IR" && this.expediatedSnapServicesValidated()) {
              previousSectionId = "expedited-snap";
            } else {
              previousSectionId = "contact-info";
            }
            break;
          case "household-information":
              previousSectionId = "my-household";
              break;
          case "shelter-utility-summary":
              if(this.state.recert_type === "IR"){
                previousSectionId = "childsupport-expenses";
              }
              break;
          case "resources-summary":
              // if(!filterData(this.props.recert.sections,"noncitizen").active){
              //   previousSectionId = "my-household";
              // }
              previousSectionId = "auth-rep";
              this._renderHouseholdInfo();
              break;
          case "noncitizen":
              previousSectionId = "resources-summary"
              break;
          case "contact-info":
              let contactInfoObj = this.props.recert.contactInfoScreen;
  
              if (contactInfoObj.emailTemp !== undefined) {
                delete contactInfoObj.emailTemp;
              }
              if (contactInfoObj.emailOptInTemp !== undefined) {
                delete contactInfoObj.emailOptInTemp;
              }
  
              if (contactInfoObj.phoneNumberTemp !== undefined) {
                delete contactInfoObj.phoneNumberTemp;
              }
              if (contactInfoObj.textOptInTemp !== undefined) {
                delete contactInfoObj.textOptInTemp;
              }
  
              contactInfoObj.editEmail = TextProps.VALUE_FALSE;
              contactInfoObj.editPhone = TextProps.VALUE_FALSE;
              contactInfoObj.emailErrorExists = TextProps.VALUE_FALSE;
              contactInfoObj.phoneErrorExists = TextProps.VALUE_FALSE;
              this.props.setContactInfo(contactInfoObj);
              break;
          case "childsupport-expenses":
            if(this.state.recert_type === "IR"){
              previousSectionId = "unearned-income-summary";
            }else{
              if(this.state.removeDependentCareIfOneHousehold){
                previousSectionId = "shelter-utility-summary";
              }else{
                previousSectionId = "Dependentcarecosts";
              }
            }
              break;
          case "add-auth-rep":
                  previousSectionId = "auth-rep";
                  break;
          case "unearned-income-summary":
            previousSectionId = "income-summary";
            break;
          case "income-summary":
            if(this.state.recert_type === "REEVAL") {
              previousSectionId = "my-household"
            }
            else {
              previousSectionId = this.state.recert_type === "AESAP" ? "resources-summary" :
              this.state.recert_type === "IR" ? "my-household" 
              :"students";
            }
          break;
          case "tanf-household-information":
            previousSectionId = "unearned-income-summary";
            break;
          case "summary":
            previousSectionId = "tanf-household-information";
            break;
          default:
              break;
          }
        }
        if (
          activeId.indexOf("unearned-combined-income-") >= 0 &&
          activeId.indexOf("unearned-combined-income-subsection") < 0
        ) {
          previousSectionId = "unearned-income-summary";
        } else if (
          activeId.indexOf("combined-income-") >= 0 &&
          activeId.indexOf("combined-income-subsection") < 0
        ) {
          previousSectionId = "income-summary";
        } else if (activeId.includes("child-support-amount-")) {
          previousSectionId = "childsupport-expenses";
        }
        // else if(activeId.includes("utility-expenses-section")){
        //   if ((!Array.isArray(shelterSections.subsections[0].questions[0].Answer) || shelterSections.subsections[0].questions[0].Answer.length === 0) || this.shelterDoNotPayOptions.includes(shelterSections.subsections[0].questions[0].Answer[0])) {
        //     previousSectionId = "shelter-utility-summary";
        //   }
        // }
        if (
          previousSectionId === "noncitizen" &&
          filterData(this.props.recert.sections, "noncitizen").active ===
            TextProps.VALUE_FALSE
        ) {
          previousSectionId = "resources-summary";
        }
        if (previousSectionId && previousSectionId.indexOf("student-") >= 0) {
          this.onStudentsSubsectionRender(
            filterData(this.props.recert.sections, "students").questions[0]
              .Answer
          );
        } 
        else if (
          previousSectionId && previousSectionId.indexOf("kids-and-adults-") >= 0 ||
          previousSectionId && previousSectionId.indexOf("kids-adult-multiple-address") >= 0 ||
          previousSectionId === "Dependentcarecosts"
        ) {
          this.retainDependentCareData();
        }
        if (previousSectionId === "household-member") {
          previousSectionId = "my-household";
        }
        if (previousSectionId === "add-contact-info") {
          previousSectionId = "contact-info";
        }
        if (previousSectionId === "shelter-expense-subsection"){
          if(!this.isShelterExpenseDataSelected()){
            previousSectionId = "shelter-expenses-section";
          }
        }
        const previousSection = this._getSection(previousSectionId);
        this.setState(
          {
            directionClass: "backward",
          },
          () => {
            this.onSectionChange(previousSection);
          }
        );
      } else {
        this.redirectConsumerHome();
      }
  };
  
  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    if (this.state.sectionDataUpdated || this.state.authRepDataUpdated) {
      this.onSaveOrExitClick(event);
    } else {
      this.goBackHandler();
    }
  };

  onStudentsSubsectionRender = (answers) => {
    var studentDetailTemplate = null;
    var studentSubsectionCopy = this.state.studentDetailsTemplate;
    var studentsQuetionCopy = filterData(
      this.props.recert.sections,
      "students"
    ).questions[0];
    let studentCopy = _.cloneDeep(
      filterData(this.props.recert.sections, "students")
    );

    let existingStudents = [];
    if (studentCopy.subsections) {
      studentCopy.subsections.map((studentSub) => {
        existingStudents.push(studentSub.header.split("'")[0]);
      });
    }

    var studentCount = 0;
    if (answers.length > 0) {
      studentDetailTemplate = [];
      answers.map((value) => {
        studentsQuetionCopy[OptionsProps.OPTIONS].map((option, index) => {
          if (
            option[OptionsProps.OPTION_VALUE] === value &&
            value !==
              recertificationPageLabels(
                language,
                null,
                languageConstants.incomeExpensesRecert
              ).questionClearOption
          ) {
            studentSubsectionCopy.id = "student-" + studentCount;
            studentSubsectionCopy.title = option[OptionsProps.OPTION_DISPLAY];
            studentSubsectionCopy.header = recertificationPageLabels(
              language,
              option[OptionsProps.OPTION_DISPLAY],
              languageConstants.studentDynamic
            ).heading;
            studentSubsectionCopy.hiddenFromNav = TextProps.VALUE_FALSE;
            studentSubsectionCopy.questions[0][Props.LABEL] =
              recertificationPageLabels(
                language,
                option[OptionsProps.OPTION_DISPLAY],
                languageConstants.studentStatic
              ).schoolTypeQ;
            studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0][
              Props.LABEL
            ] = recertificationPageLabels(
              language,
              option[OptionsProps.OPTION_DISPLAY],
              languageConstants.studentStatic
            ).schoolNameQ;
            studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0][
              Props.LABEL
            ] = recertificationPageLabels(
              language,
              option[OptionsProps.OPTION_DISPLAY],
              languageConstants.studentStatic
            ).collegeNameQ;
            // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1][
            //   Props.LABEL
            // ] = recertificationPageLabels(
            //   language,
            //   option[OptionsProps.OPTION_DISPLAY],
            //   languageConstants.studentStatic
            // ).sasidQ;

            if (
              existingStudents.indexOf(option[OptionsProps.OPTION_DISPLAY]) >= 0
            ) {
              let existingSubStudent = null;
              studentCopy.subsections.map((oldStu) => {
                if (oldStu.title === option[OptionsProps.OPTION_DISPLAY]) {
                  existingSubStudent = oldStu;
                }
              });
              if (existingSubStudent !== null) {
                if (
                  existingSubStudent.questions[0].Answer !== undefined &&
                  existingSubStudent.questions[0].Answer !== ""
                ) {
                  studentSubsectionCopy.questions[0].Answer =
                    existingSubStudent.questions[0].Answer;
                  studentSubsectionCopy.questions[0].value =
                    existingSubStudent.questions[0].Answer;
                  studentSubsectionCopy.questions[0].targetValue =
                    existingSubStudent.questions[0].targetValue;
                  if (existingSubStudent.questions[0].Answer === "HGHSCH") {
                    studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].Answer =
                      existingSubStudent.questions[0].conditionals[0].conditionalQuestions[0].Answer;
                    studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].value =
                      existingSubStudent.questions[0].conditionals[0].conditionalQuestions[0].Answer;
                    studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].targetValue =
                      existingSubStudent.questions[0].conditionals[0].conditionalQuestions[0].targetValue;
                    // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].Answer =
                    //   existingSubStudent.questions[0].conditionals[0].conditionalQuestions[1].Answer;
                    // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].value =
                    //   existingSubStudent.questions[0].conditionals[0].conditionalQuestions[1].Answer;
                    // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].targetValue =
                    //   existingSubStudent.questions[0].conditionals[0].conditionalQuestions[1].targetValue;
                  } else if (
                    existingSubStudent.questions[0].Answer === "COLLGE"
                  ) {
                    studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].Answer =
                      existingSubStudent.questions[0].conditionals[1].conditionalQuestions[0].Answer;
                    studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].value =
                      existingSubStudent.questions[0].conditionals[1].conditionalQuestions[0].Answer;
                    studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].targetValue =
                      existingSubStudent.questions[0].conditionals[1].conditionalQuestions[0].targetValue;
                  }
                }
              }
            } else {
              studentSubsectionCopy.questions[0].Answer = "";
              studentSubsectionCopy.questions[0].value = "";
              studentSubsectionCopy.questions[0].targetValue = "";
              studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].Answer =
                "";
              studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].value =
                "";
              studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[0].targetValue =
                "";
              // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].Answer =
              //   "";
              // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].value =
              //   "";
              // studentSubsectionCopy.questions[0].conditionals[0].conditionalQuestions[1].targetValue =
              //   "";
              studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].Answer =
                "";
              studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].value =
                "";
              studentSubsectionCopy.questions[0].conditionals[1].conditionalQuestions[0].targetValue =
                "";
            }
            studentCount++;
            studentDetailTemplate.push(_.cloneDeep(studentSubsectionCopy));
          }
        });
      });
    }
    if (studentDetailTemplate !== null && studentDetailTemplate.length > 0) {
      this.props.appendStudentetails(studentDetailTemplate);
    } else
      delete filterData(this.props.recert.sections, "students")
        .subsections;
  };

  updateRecertExpenseDetails = (sec, index) => {
    this.props.appendRecertExpenseDetails(sec, index);
  };

  renderMedicalExpenseFilter = (activeId) => {
    let expenseSection = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "medical-expenses")[0];

    let medicalExpenseTypeTemplate = filterData(
      expenseSection.subsections,
      activeId
    );
    let index = activeId.split("-").splice(-1, 1).join();
    medicalExpenseTypeTemplate.completed = TextProps.VALUE_TRUE;
    let medicalExpensesTemplate = _.cloneDeep(
      expenseMedicalExpenseTemplate.subsections[0]
    );
    if(medicalExpenseTypeTemplate.questions[0].Answer && medicalExpenseTypeTemplate.questions[0].Answer.length){
      medicalExpensesTemplate.id = medicalExpensesTemplate.id + "-" + index;
      medicalExpensesTemplate.header = medicalExpenseTypeTemplate.header;
      medicalExpensesTemplate.title = medicalExpenseTypeTemplate.title;
      // medicalExpensesTemplate.questions = [];
      let selectedOptions = medicalExpenseTypeTemplate.questions[0].Answer;
      medicalExpensesTemplate.questions =
        medicalExpensesTemplate.questions.filter((item) =>
          selectedOptions.includes(item.name)
        );
      var previousData = {};
      if(medicalExpenseTypeTemplate.subsections && medicalExpenseTypeTemplate.subsections.length === 1){
        medicalExpenseTypeTemplate.subsections = [0].concat(medicalExpenseTypeTemplate.subsections)
      }else if(medicalExpenseTypeTemplate.subsections && medicalExpenseTypeTemplate.subsections.length === 2){
        medicalExpenseTypeTemplate.subsections[0].questions.map((item) => {
          if (item.Answer[0].amount != "") {
            previousData[item.name] = item.Answer;
          }
        });
      }
      medicalExpenseTypeTemplate.subsections[1].selectionMade = expenseSection.selectionMade;
      medicalExpensesTemplate.questions.map((item) => {
        item.repeatedQuestion.unitLabel = incomeDynamicMultilanguageLabels(
          medicalExpenseTypeTemplate.title,
          item.optionDisplay,
          language
        ).unitLabel;
        item.repeatedQuestion.amountLabel = incomeDynamicMultilanguageLabels(
          medicalExpenseTypeTemplate.title,
          item.optionDisplay,
          language
        ).amountLabel;
        if (item.name in previousData) {
          item.Answer = previousData[item.name];
          item.targetValue = previousData[item.name];
          item.value = previousData[item.name];
        }
      });
      medicalExpenseTypeTemplate.subsections[0] = medicalExpensesTemplate;
    }else if(medicalExpenseTypeTemplate.subsections[0].id.includes(medicalExpensesTemplate.id)){
      medicalExpenseTypeTemplate.subsections.shift();
    }
    this.updateRecertExpenseDetails(
      expenseSection,
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        filterData(
          this.props.recert.sections,
          "expenses"
        ).subsections.filter((sub) => sub.id === "medical-expenses")[0]
      )
    );
  };

  //Render Names on Main Care and Kids Page
  renderCaringKidsAdults = () => {
    let houseMemberList = this.newUpdateHouseHoldIncomeDetails();
    let expenseSection = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0];
    //LOAD THE MEMEBER DETAILS IF FRESH APPLY PROCESS
    if (!resumeApply) {
      expenseSection.questions[0].options = [];
      expenseSection.questions[0].options = houseMemberList;
    }

    if (!resumeApply) {
      let conditionalQuestionTemplate =
        expenseSection.questions[0].conditionals[0];
      expenseSection.questions[0].conditionals = [];
      for (var memIndex = 0; memIndex < houseMemberList.length; memIndex++) {
        expenseSection.questions[0].conditionals[memIndex] = _.cloneDeep(
          conditionalQuestionTemplate
        );
        expenseSection.questions[0].conditionals[memIndex].valueToMatch =
          houseMemberList[memIndex];
        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].label = recertificationPageLabels(
          language,
          houseMemberList[memIndex],
          languageConstants.householdDynamicRecert
        ).conditionalLabel;
        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].name = "recipient-of-care-" + [memIndex];

        expenseSection.questions[0].conditionals[
          memIndex
        ].conditionalQuestions[0].options = [];
        for (let houseMemberValue of houseMemberList) {
          if (houseMemberValue !== houseMemberList[memIndex]) {
            expenseSection.questions[0].conditionals[
              memIndex
            ].conditionalQuestions[0].options.push(houseMemberValue);
          }
        }
      }
    }

    if (!resumeApply) {
      expenseSection.questions[0].options = [];
      expenseSection.questions[0].options = houseMemberList;
    }

    this.props.appendRecertExpenseDetails(
      expenseSection,
      filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
        filterData(
          this.props.recert.sections,
          "expenses"
        ).subsections.filter((sub) => sub.id === "Dependentcarecosts")[0]
      )
    );
  };

  renderHousingCostsBySelection = () => {
    let housingCostsSection = filterData(
      this.props.recert.sections,
      "expenses"
    ).subsections.filter((sub) => sub.id === "shelter-utility-summary")[0];
    let housingCostsPageAnswers = housingCostsSection.questions[0].Answer;
    // let houseCopy = _.cloneDeep(this.props.recert.sections[7]);
    let houseCopy = _.cloneDeep(
      filterData(this.props.recert.sections, "expenses")
    );
    let housingCostsFilteredQuestions = [];
    //Swap full section for first time and it should not be manipulated!
    if (this.housingCostsSubSections[0] === undefined) {
      if (housingCostsSection.length > 0) {
        this.housingCostsSubSections[0] = _.cloneDeep(
          housingCostsSection.subsections[0]
        );
      }
    } else {
      housingCostsSection.subsections = [];
      housingCostsSection.subsections[0] = _.cloneDeep(
        this.housingCostsSubSections[0]
      );
    }
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      housingCostsPageAnswers &&
      housingCostsPageAnswers[0] !== "" &&
      housingCostsPageAnswers[0] !== undefined &&
      housingCostsPageAnswers[0] !==
        recertificationPageLabels(
          language,
          null,
          languageConstants.incomeExpensesRecert
        ).shelterDoNotPayOption
    ) {
      housingCostsFilteredQuestions = filterQuestionsBySelection(
        housingCostsPageAnswers,
        housingCostsSection.subsections[0].questions
      );
      housingCostsSection.subsections[0].questions = _.cloneDeep(
        housingCostsFilteredQuestions
      );
      housingCostsSection.subsections[0].questions.map((q, index) => {
        if (houseCopy.questions[0].Answer.indexOf(q.valueToMatch) >= 0) {
          if (
            houseCopy.subsections &&
            houseCopy.subsections[0].questions[index] &&
            houseCopy.subsections[0].questions[index].Answer !== undefined
          ) {
            housingCostsSection.subsections[0].questions[index].Answer =
              houseCopy.subsections[0].questions[index].Answer;
            housingCostsSection.subsections[0].questions[index].targetValue =
              houseCopy.subsections[0].questions[index].Answer;
          }
        }
      });
      this.props.appendRecertExpenseDetails(
        housingCostsSection,
        filterData(
          this.props.recert.sections,
          "expenses"
        ).subsections.indexOf(housingCostsSection)
      );
    } else {
      delete housingCostsSection.subsections;
      this.props.appendRecertExpenseDetails(
        housingCostsSection,
        filterData(
          this.props.recert.sections,
          "expenses"
        ).subsections.indexOf(housingCostsSection)
      );
    }
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };
  // redirect to Home page if no previous pages
  redirectToHomePage = () => {
    window.location.assign("/consumer/myInfo");
  };
  closeSaveExitModal = (status, event) => {
    // sessionStorage.setItem("saveExitShowModalEnabled", false);
    this.setState({
      saveExitShowModal: TextProps.VALUE_FALSE,
    });
    this.props.saveandExitAction({
      pendingHours: 0,
      saveExitShowModal: TextProps.VALUE_FALSE,
    });
    if (status) {
      this.redirectToHomePage();
    }
  };
  renderSaveExitWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.saveExitShowModal}
        onClose={() => this.closeSaveExitModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="Save progress"
        headerText={
          recertWarningPopupMLanguageLabels(language, null).saveAndExitHeader
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {this.state.recert_type === "AESAP" ? recertWarningPopupMLanguageLabels(language, null).aesapSaveExitLabel1+" " :
            this.state.recert_type === "REEVAL" ? recertWarningPopupMLanguageLabels(language, null).tanfSaveExitLabel1 + " " :
            this.state.recert_type === "IR" ?  interimWarningPopupMLanguageLabels(language).saveExitLabel1 :
            recertWarningPopupMLanguageLabels(language, null).saveExitLabel1}{" "}
            {todaysDateOrTime(
              TextProps.VALUE_TRUE,
              this.props.recert.updatedDate
            )}{" "}
            {recertWarningPopupMLanguageLabels(language, null).saveExitLabel2}{" "}
            {todaysDateOrTime(
              TextProps.VALUE_FALSE,
              this.props.recert.updatedDate
            )}{" "}
            {recertWarningPopupMLanguageLabels(language, null).saveExitLabel3}
            {this.props.recert.pendingHours
              ? this.props.recert.pendingHours.toString().split(":")[0]
              : this.props.recert.pendingHours}
            {recertWarningPopupMLanguageLabels(language, null).saveExitLabel4}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_FALSE, event)
              }
            >
              {
                recertWarningPopupMLanguageLabels(language, null)
                  .CancelButtonLabel
              }
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_TRUE, event)
              }
            >
              {recertWarningPopupMLanguageLabels(language, null).okLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  onDeleteClick = (event) => {
    event.preventDefault();
    this.setState({ deleteWarningShowModal: TextProps.VALUE_TRUE });
  };
  closeDeleteWarningModal = (status) => {
    if (status) {
      if(this.props.recert.recertId){
        this.props
        .deleteRecertApplication(
          this.props.recert.webAppTypeCode,
          this.props.recert.recertId,
          this.props.recert.createdTime
        )
        .then((data) => {
          this.redirectToHomePage();
        }); 
      }else{
        this.redirectToHomePage();
      }
    }
    this.setState({
      deleteWarningShowModal: TextProps.VALUE_FALSE,
    });
  };

  renderDeleteWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.deleteWarningShowModal}
        onClose={() => this.closeDeleteWarningModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={
          recertWarningPopupMLanguageLabels(language, null).confirmDelete
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {
              recertWarningPopupMLanguageLabels(language, null)
                .deleteWarningLabel
            }
          </p>
          <p className="text--small">
            {recertWarningPopupMLanguageLabels(language, null).deleteExitLabel}
          </p>
          <p className="text--small">
            {this.state.recert_type === "IR" ? 
            interimWarningPopupMLanguageLabels(language, null).deletePopUpLabel
            : 
            this.state.recert_type === "REEVAL" ? recertWarningPopupMLanguageLabels(language, null).deletePopUpLabelTanf : 
            this.state.recert_type === "AESAP" ? recertWarningPopupMLanguageLabels(language, null).deletePopUpLabelAesap : recertWarningPopupMLanguageLabels(language, null).deletePopUpLabel}
            </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>
                this.closeDeleteWarningModal(TextProps.VALUE_FALSE)
              }
            >
              {
                recertWarningPopupMLanguageLabels(language, null)
                  .CancelButtonLabel
              }
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => this.closeDeleteWarningModal(TextProps.VALUE_TRUE)}
            >
              {
                recertWarningPopupMLanguageLabels(language, null)
                  .deleteButtonLabel
              }
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText =
          this.state.recert_type === "AESAP"
            ? recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).getStartAESAPButtonLabel
            : this.state.recert_type === "IR"
            ? interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).getStartButtonLabel 
            : this.state.recert_type === "REEVAL"
            ? recertificationPageLabels(
              language,
              null,
              languageConstants.beforeYouStart
            ).getStartTafdcButtonLabel 
            : recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).getStartButtonLabel;
        return returnText;
      case "sign-and-submit":
        returnText = this.state.recert_type === "IR" ?  
        interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).submitButtonLabel
        : this.state.recert_type === "AESAP" ? recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).submitSnapAesapLabel
        : this.state.recert_type === "REEVAL" ? recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).submitTafdcLabel 
        : recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).submitButtonLabel
        return returnText;
      default:
        returnText = recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).saveAndContinue;
        return returnText;
    }
  };

  getSummaryTitle = (name, before, after) => {
    switch (language) {
      case types.ENGLISH:
      case types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case types.SPANISH:
      case types.PORTUGUESE:
      case types.VIETNAMESE:
      case HI:
        return {
          before: name + before,
          after: " " + name,
        };
    }
  };

  // Markup for the body of apply page when user is starting application.
  renderBeforeYouStart = () => {
    return (
      <StartForm
        pdfDownloader={this.downloadApplicationAsPdf}
        startFormData={this.props.recert}
        onNotificationItemClick={this.props.displayPDFData}
        recertType={this.state.recert_type}
      ></StartForm>
    );
  };
  setContactInfoData = (data) => {
    if (data.contactInfoChanged !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
    if (!data.contactInfoChanged) {
      data.editEmail = false;
      data.editPhone = false;
    }
    this.props.setContactInfo(data);
  };
  setHouseholdInfo = (data) => {
    if (data && data.membersListUpdated !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
    this.props.setHouseholdMembers(data);
  };
  setChildSupportExpenses = (data) => {
    if (data !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
  };
  renderContactInfo = () => {
    return (
      <ContactInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        onDataReset={this.resetContactState}
        setDataCodeChangeFlags={this.setDataCodeChangeFlags}
        contactInfoData={this.props.recert}
        responseObj={this.props.recert.responseObject}
        setContactInfo={this.setContactInfoData}
        addContactInfoSubSection={this.props.addContactInfoSubSection}
        setDataChangeStatus={this.props.setDataChangeStatus}
      ></ContactInfo>
    );
  };

  renderHouseholdInfo = () => {
    return (
      <HouseholdInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        addHouseholdAddSectionToList={this.props.addHouseholdAddSectionToList}
        updateCurrentSectionData={this.props.updateCurrentSectionData}
        setHouseholdMembers={this.setHouseholdInfo}
        setDataChangeStatus={this.props.setDataChangeStatus}
        snapRefreshQuestionsSections={this.props.snapRefreshQuestionsSections}
        householdData={this.props.recert}
        convertStringTODate={convertDOBForDataPost}
        applicationType={this.state.recert_type === "IR" ? this.state.recert_type : "recert"}
        onRemovedHOH={this.onRemovedHOH}
      ></HouseholdInfo>
    );
  };

  renderTanfHouseholdInfo = () => {
    return(
      <HouseholdInfoTanf householdInformation={this.state.householdInformation}/>
    )
  }

  renderNonCitizen = () => {
    return <NonCitizen></NonCitizen>;
  };
  //Eraned income screens
  renderChildSupportExpenses = () => {
    let { sections } = this.props.recert;
    const childSupportSectionIndex = this.state.recert_type === "IR" ?
          sections.indexOf(filterData(sections, "childsupport-expenses"))
          : filterData(
             sections,
              "expenses"
            ).subsections.indexOf(
            filterData(
             filterData(sections, "expenses").subsections,
               "childsupport-expenses"
              )
            );
    return (
      <ChildSupportExpenses
        getSummaryTitle={this.getSummaryTitle}
        prepareChildSupportSummary={this.prepareChildSupportSummary}
        incomeSummary={this.incomeSummary}
        onChildSupportSubsectionRender={this.onChildSupportSubsectionRender}
        onSectionChange={this.onSectionChange}
        setDataChangeStatusForExpense={this.props.setDataChangeStatusForExpense}
        setChildSupportExpenses={this.setChildSupportExpenses}
        handleRemove={this.handleRemove}
        handleDone={this.handleDone}
        recertType={this.state.recert_type}
        childSupportSectionIndex={childSupportSectionIndex}
      />
    );
  };

  prepareMedicalExpensesSummary = () => {
    let medicalExpensesData = this.props.recert.responseObject.medicalExpenses;
    let agencyIDME = this.props.recert.responseObject.agencyID;
    let updatedMedicalExpensesData = filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "medical-expenses"
    );
    let usersData = {};
    this.medicalSummary.summaryData = [];
    for (var i = 0; i < medicalExpensesData.length; i++) {
      if (medicalExpensesData[i]["agencyID"] !== null) {
        let user = [
          medicalExpensesData[i]["firstName"],
          medicalExpensesData[i]["lastName"],
          formatters.formatDate(medicalExpensesData[i]["dateOfBirth"]),
        ]
          .join(" ")
          .replace(/ +(?= )/g, "");
        if (!(user in usersData)) {
          usersData[user] = {};
          usersData[user]["medicalExpenses"] = [];
          usersData[user]["selectedOptions"] = [];
        }

        Object.keys(medicalExpenseKeys).map((item) => {
          usersData[user] = Object.assign(usersData[user], {
            id: medicalExpensesData[i]["agencyID"],
            name: user,
          });
          if (medicalExpensesData[i][medicalExpenseKeys[item]["flag"]]) {
            usersData[user]["selectedOptions"].push(item);
            usersData[user]["medicalExpenses"].push({
              amount:
                medicalExpensesData[i][medicalExpenseKeys[item]["amount"]],
              unit: medicalExpensesData[i][medicalExpenseKeys[item]["unit"]],
              typeCode: item,
              desc: medicalExpenseKeys[item]["desc"],
              lable:
                medicalExpensesData[i][medicalExpenseKeys[item]["code"]] ||
                "NOCHNG",
            });
            medicalExpensesData[i][medicalExpenseKeys[item]["code"]] =
              medicalExpensesData[i][medicalExpenseKeys[item]["code"]]
                ? medicalExpensesData[i][medicalExpenseKeys[item]["code"]]
                : "NOCHNG";
          }
        });
      }
    }

    if (
      updatedMedicalExpensesData.subsections &&
      this.props.recert.medicalExpensesSummaryChanged
    ) {
      for (var i = 0; i < updatedMedicalExpensesData.subsections.length; i++) {
        let updatedSummary = updatedMedicalExpensesData.subsections[i];
        let user = updatedSummary.title;
        if (!(user in usersData)) {
          usersData[user] = {};
          usersData[user]["medicalExpenses"] = [];
          usersData[user]["selectedOptions"] = [];
          usersData[user] = Object.assign(usersData[user], {
            id: agencyIDME,
            name: user,
          });
          if (
            updatedSummary.questions &&
            updatedSummary.questions[0].Answer &&
            updatedSummary.questions[0].Answer.length > 0
          ) {
            var selectedOptions = updatedSummary.subsections[0].questions;
            for (var j = 0; j < selectedOptions.length; j++) {
              var ele = selectedOptions[j];
              usersData[user]["selectedOptions"].push(ele.name);
              usersData[user]["medicalExpenses"].push({
                amount: ele.Answer[0].amount,
                unit: ele.Answer[0].unit,
                typeCode: ele.name,
                desc: ele.optionDisplay,
                lable: "NEW",
              });
            }
          }else{
            usersData[user]["medicalExpenses"] = [];
            usersData[user]["selectedOptions"] = [];
          }
        } else {
          if (
            updatedSummary.questions &&
            updatedSummary.questions[0].Answer &&
            updatedSummary.questions[0].Answer.length > 0
          ) {
            let currentMedicalExpenses = {};
            usersData[user]["medicalExpenses"].map((exp) => {
              if (updatedSummary.questions[0].Answer.includes(exp.typeCode)) {
                currentMedicalExpenses[exp.typeCode] = {
                  amount: exp.amount,
                  unit: exp.unit,
                  desc: exp.desc,
                  lable: exp.lable,
                };
              }
            });
            usersData[user]["medicalExpenses"] = [];
            usersData[user]["selectedOptions"] = [];
            var selectedOptions = updatedSummary.subsections[0].questions;
            for (var j = 0; j < selectedOptions.length; j++) {
              var ele = selectedOptions[j];
              usersData[user]["selectedOptions"].push(ele.name);
              if (ele.name in currentMedicalExpenses) {
                let lable =
                  currentMedicalExpenses[ele.name]["lable"] === "NOCHNG"
                    ? currentMedicalExpenses[ele.name]["amount"] ==
                        ele.Answer[0].amount &&
                      currentMedicalExpenses[ele.name]["unit"] ==
                        ele.Answer[0].unit
                      ? "NOCHNG"
                      : "CHANGE"
                    : currentMedicalExpenses[ele.name]["lable"];
                usersData[user]["medicalExpenses"].push({
                  amount: ele.Answer[0].amount,
                  unit: ele.Answer[0].unit,
                  typeCode: ele.name,
                  desc: ele.optionDisplay,
                  lable: lable,
                });
              } else {
                usersData[user]["medicalExpenses"].push({
                  amount: ele.Answer[0].amount,
                  unit: ele.Answer[0].unit,
                  typeCode: ele.name,
                  desc: ele.optionDisplay,
                  lable: "NEW",
                });
              }
            }
          }else{
            usersData[user]["medicalExpenses"] = [];
            usersData[user]["selectedOptions"] = [];
          }
        }
      }
    } else if (
      !this.props.recert.medicalExpensesSummaryChanged &&
      updatedMedicalExpensesData.subsections &&
      updatedMedicalExpensesData.subsections.length === 0
    ) {
      delete updatedMedicalExpensesData.subsections;
    }
    for (const item in usersData) {
      this.medicalSummary.summaryData.push(usersData[item]);
    }
  };

  renderMedicalExpensesSubSections = (isChangeSection) => {
    "summaryData" in this.medicalSummary &&
      this.medicalSummary.summaryData.map((item) => {
        this.renderMedicalExpensesSubSection(item.name, isChangeSection);
      });
  };

  renderMedicalExpensesSubSection = (name, isChangeSection = false) => {
    var medicalTemplate = _.cloneDeep(expenseMedicalExpenseTemplate);
    var medicalExpensesCopy = this.props.recert.sections
      .filter((sec) => sec.id === "expenses")[0]
      .subsections.filter((sub) => sub.id === "medical-expenses")[0];
    let dataFound = TextProps.VALUE_FALSE;
    medicalExpensesCopy.subsections &&
      medicalExpensesCopy.subsections.map((subSec) => {
        if (subSec.title === name) {
          dataFound = TextProps.VALUE_TRUE;
          medicalTemplate = subSec;
        }
      });
    if (!dataFound) {
      if (!medicalExpensesCopy.subsections)
        medicalExpensesCopy.subsections = [];

      medicalTemplate.id =
        "medical-expenses-type-" + medicalExpensesCopy.subsections.length;
      medicalTemplate.header = recertificationPageLabels(
        language,
        name,
        languageConstants.medicalExpenses
      ).heading;
      medicalTemplate.questions[0][Props.LABEL] = recertificationPageLabels(
        language,
        name,
        languageConstants.medicalExpenses
      ).mainQuestion;
      medicalTemplate.questions[0].Answer = "";
      medicalTemplate.questions[0].value = "";
      medicalTemplate.questions[0].targetValue = "";
      medicalTemplate.title = name;
      "summaryData" in this.medicalSummary &&
        this.medicalSummary.summaryData.map((item) => {
          if (item.name === name) {
            medicalTemplate.questions[0].Answer = item.selectedOptions;
            medicalTemplate.questions[0].value = item.selectedOptions;
            medicalTemplate.questions[0].targetValue = item.selectedOptions;
            let currentData = {};
            item.medicalExpenses.map((exp) => {
              currentData[exp.typeCode] = [
                { amount: exp.amount, unit: exp.unit },
              ];
            });
            medicalTemplate.subsections[0].id =
              "medical-expenses-filter-" +
              medicalExpensesCopy.subsections.length;
            medicalTemplate.subsections[0].header = medicalTemplate.header;
            medicalTemplate.subsections[0].title = medicalTemplate.title;
            medicalTemplate.subsections[0].questions =
              medicalTemplate.subsections[0].questions.filter((ele) =>
                item.selectedOptions.includes(ele.name)
              );
            medicalTemplate.subsections[0].questions.map((item1) => {
              item1.repeatedQuestion.unitLabel =
                incomeDynamicMultilanguageLabels(
                  medicalTemplate.title,
                  item1.optionDisplay,
                  language
                ).unitLabel;
              item1.repeatedQuestion.amountLabel =
                incomeDynamicMultilanguageLabels(
                  medicalTemplate.title,
                  item1.optionDisplay,
                  language
                ).amountLabel;
              if (item1.name in currentData) {
                item1.Answer = currentData[item1.name];
                item1.targetValue = currentData[item1.name];
                item1.value = currentData[item1.name];
              }
            });
          }
        });
      medicalExpensesCopy.subsections[medicalExpensesCopy.subsections.length] =
        medicalTemplate;
    }

    this.updateRecertExpenseDetails(
      medicalExpensesCopy,
      this.props.recert.sections
        .filter((sec) => sec.id === "expenses")[0]
        .subsections.indexOf(
          this.props.recert.sections
            .filter((sec) => sec.id === "expenses")[0]
            .subsections.filter((sub) => sub.id === "medical-expenses")[0]
        )
    );
    if (!isChangeSection) {
      this.onSectionChange(medicalTemplate);
    }
  };

  setSectionDataUpdated = (data) => {
    if (data !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
  };

  setAuthRepDataUpdated = (value) => {
    this.setState({authRepDataUpdated: value})
  }

  renderMedicalExpenses = () => {
    this.prepareMedicalExpensesSummary();
    return (
      <MedicalExpenses
        getSummaryTitle={this.getSummaryTitle}
        prepareMedicalExpensesSummary={this.prepareMedicalExpensesSummary}
        medicalSummary={this.medicalSummary}
        updateRecertExpenseDetails={this.updateRecertExpenseDetails}
        onSectionChange={this.onSectionChange}
        setDataChangeStatusForExpense={this.props.setDataChangeStatusForExpense}
        renderMedicalExpensesSubSection={this.renderMedicalExpensesSubSection}
        setSectionDataUpdated={this.setSectionDataUpdated}
      />
    );
  };

  handleRemove = (event) => {
    event.preventDefault();
    // let childSupportArray = _.cloneDeep(
    //   this.props.recert.childExpScreen.tempChildExpArray
    // );
    // const updatedChildSupportArray = childSupportArray.map((childSection) => {
    //   if (childSection.clientId === event.target.id) {
    //     childSection.dataChangeCode = "REMOVE";
    //   }
    //   return childSection;
    // });
    let childExpenseSection = this.incomeSummary.childSupportIncomeMembers;
    childExpenseSection.forEach((expSec, index) => {
      if (expSec.clientId === event.target.id) {
        if (
          expSec.incomes[0].Answer &&
          expSec.incomes[0].Answer.code &&
          expSec.incomes[0].Answer.code !== "new"
        ) {
          expSec.incomes[0].Answer.isRemoved = true;
        } else {
          childExpenseSection.splice(childExpenseSection.indexOf(expSec), 1);
          let nonMember = {};
          nonMember.id = index;
          nonMember.firstName = expSec.clientId;
          this.incomeSummary.childSupportNonIncomeMembers.push(nonMember);
        }
      }
    });
    let updatedChildSupportSection = this.state.recert_type === "IR" ?  
    filterData(this.props.recert.sections, "childsupport-expenses")
    : filterData(
      filterData(this.props.recert.sections, "expenses").subsections,
      "childsupport-expenses"
    );
    updatedChildSupportSection.subsections.map((childSection) => {
      if (childSection.clientId === event.target.id) {
        if (
          childSection.questions[0].Answer &&
          childSection.questions[0].Answer.code &&
          childSection.questions[0].Answer.code !== "new"
        ) {
          childSection.questions[0].Answer.code = "REMOVE";
          childSection.questions[0].targetValue.code = "REMOVE";
          childSection.questions[0].Answer.isRemoved = TextProps.VALUE_TRUE;
          childSection.questions[0].targetValue.isRemoved =
            TextProps.VALUE_TRUE;
          childSection.questions[0].isRemoved = TextProps.VALUE_TRUE;
        } else {
          childSection.isRemoved = TextProps.VALUE_TRUE;
          childSection.parentId = "childsupport-expenses";
          // updatedChildSupportSection.subsections.splice(updatedChildSupportSection.subsections.indexOf(childSection),1);
        }
      }
    });
    const childSupportIndex = this.state.recert_type === "IR" ?
    this.props.recert.sections.indexOf(
      filterData(this.props.recert.sections, "childsupport-expenses")
    )
    : filterData(
        this.props.recert.sections,
        "expenses"
      ).subsections.indexOf(
      filterData(
        filterData(this.props.recert.sections, "expenses").subsections,
          "childsupport-expenses"
        )
    );
    this.props.appendRecertExpenseDetails(
      updatedChildSupportSection,
      childSupportIndex
    );
    // this.processChildSupportSectionData();
    // this.gotoChildSupportSummary();
    this.onPrimaryActionClick(event);
  };

  handleDone = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event);
  };

  renderEarnedIncomeSummary = () => {
    return (
      <EarnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareIncomeSummary}
        incomeSummary={this.incomeSummary}
        onEarnedIncomeSubsectionRender={this.onIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
        setSectionDataUpdated={this.setSectionDataUpdated}
        recert_type={this.state.recert_type}
      />
    );
  };
  renderResourcesSummary = () => {
    return (
      <ResourcesSummary
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareResourcesSummary}
        resourcesSummary={this.resourcesSummary}
        onResourcesSubsectionRender={this.onResourcesSubsectionRender}
        onSectionChange={this.onSectionChange}
        setSectionDataUpdated={this.setSectionDataUpdated}
      />
    );
  };

  renderUnearnedIncomeSummary = () => {
    return (
      <UnearnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareUnearnedIncomeSummary={this.prepareUnearnedIncomeSummary}
        incomeSummary={this.incomeSummary}
        onUnearnedIncomeSubsectionRender={this.onUnearnedIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
        setSectionDataUpdated={this.setSectionDataUpdated}
        recert_type={this.state.recert_type}
      />
    );
  };

  renderAuthRep = () =>  {
    return(
      <AuthRep
      getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareIncomeSummary}
        incomeSummary={this.incomeSummary}
        // onEarnedIncomeSubsectionRender={this.onIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
        setAuthRepDataUpdated={this.setAuthRepDataUpdated}
        onPrimaryActionClick={this.onSectionLinkClick}
        addAuthRepSubSection={this.props.addAuthRepSubSection}
        AuthRepData={this.props.recert}
        ebtAuthRepFlg={this.state.ebtAuthRepFlg}
        snapAuthRepFlg={this.state.snapAuthRepFlg}
        authRepChangeCode={this.state.authRepChangeCode}
        addAuthRepData={this.state.addAuthRepData}

      />
      // <h1>List of Authorized Representative</h1>
    )
  }

  setAuthRepSave = (value) => {
    this.setState({addAuthRepSaveandContinue : value})
  }

  validatePhoneNumber = (phone) => {
    let phoneNumberString = phone;
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let { textOptIn } = this.state;
    if (
      (cleaned === "" && !textOptIn) ||
      (cleaned !== "" && cleaned.length === 10)
    )
    return TextProps.VALUE_TRUE;
  }; 

  setFlag = (flagName, value) => {
    this.setState((prevState) => ({
        flags: {
            ...prevState.flags,
            [flagName]: value
        }
    }));
}

  inputCheck = (event, key) => {
    this.setState((prevState) => ({
      addAuthRepData: {
          ...prevState.addAuthRepData,
          [key]: event
      }
  }));
  // this.setState({authRepDataUpdated: TextProps.VALUE_TRUE})
  this.handleAuthRepDataChanged(TextProps.VALUE_TRUE);
    };
    

    compareObjects = (ntwObj, localObj) => {
          const updatedFields = [];
          for (const key in ntwObj) {
            if(ntwObj.hasOwnProperty(key) && localObj.hasOwnProperty(key)){
              if(ntwObj[key] !== localObj[key]){
                updatedFields.push(key);
              }
            }
          }
          return updatedFields;
    }

    CheckForQuestionsDataModified = () => {
      let networkObj = this.props.recert.authorizedRepresentatives;
      let localObj = this.state.addAuthRepData;
      const updatedFields = this.compareObjects(networkObj, localObj);
      if(updatedFields.length >= 1) {
        let theQuestion = "";
        if(updatedFields.includes("ebtFrstNam") || updatedFields.includes("ebtLstNam") || updatedFields.includes("ebtMdlNam") || updatedFields.includes("ebtPhNum") ) {
          theQuestion = "ebtQuestion";
          this.statusChangeCode(theQuestion);
        }
        if(updatedFields.includes("snpFrstNam") || updatedFields.includes("snpLstNam") || updatedFields.includes("snpMdlNam") || updatedFields.includes("snpPhNum") ) {
          theQuestion = "snapQuestion";
          this.statusChangeCode(theQuestion);
        }
      }else{
        return;
      }
    }
    
    statusChangeCode = (question) => {
    if(question === "ebtQuestion"){
      if((this.state.ebtAuthRepFlg === "Y" || this.state.addAuthRepData.ebtWebDataChangeCode === "NEW") && (this.state.addAuthRepData.ebtWebDataChangeCode !== "NOCHNG" && this.state.addAuthRepData.ebtWebDataChangeCode !== "CHANGE")){
        this.setState(prevState => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              ebtWebDataChangeCode: "NEW",
          }
      })
      );
      this.state.addAuthRepData.ebtWebDataChangeCode = "NEW"
    }
      if(this.state.addAuthRepData.ebtWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.ebtWebDataChangeCode === "CHANGE"){
          //If data is updated. 
          this.setState((prevState) => ({
            addAuthRepData: {
                ...prevState.addAuthRepData,
                ebtWebDataChangeCode: "CHANGE",
            }}))
      this.state.addAuthRepData.ebtWebDataChangeCode = "CHANGE"

      }
    }
    if(question === "snapQuestion"){ 
      if((this.state.snapAuthRepFlg === "Y" || this.state.addAuthRepData.snpWebDataChangeCode  === "NEW") && (this.state.addAuthRepData.snpWebDataChangeCode !== "NOCHNG" && this.state.addAuthRepData.snpWebDataChangeCode  !== "CHANGE")) {
        this.setState(prevState => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              snpWebDataChangeCode: "NEW"
          }
        })
        );
        this.state.addAuthRepData.snpWebDataChangeCode = "NEW";
    }
      if(this.state.addAuthRepData.snpWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.snpWebDataChangeCode  === "CHANGE"){
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              snpWebDataChangeCode: "CHANGE",
          }}))
      this.state.addAuthRepData.snpWebDataChangeCode = "CHANGE"

      }
    }
  }


  verifyFieldValidationOnSave = () => {

    this.state.flags = {};

    let fieldErrorsFound = TextProps.VALUE_FALSE;
    if (this.state.ebtAuthRepFlg === "Y") {
      if (this.state.addAuthRepData.ebtFrstNam === "" || this.state.addAuthRepData.ebtFrstNam === null || this.state.addAuthRepData.ebtFrstNam === undefined) {
        this.setFlag("ebtFrstNamError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
      }
      if (this.state.addAuthRepData.ebtLstNam === "" || this.state.addAuthRepData.ebtLstNam === undefined || this.state.addAuthRepData.ebtLstNam === null) {
          this.setFlag("ebtLstNamError", TextProps.VALUE_TRUE);
          fieldErrorsFound = true;
        }
      if (this.state.addAuthRepData.ebtPhNum === "" || this.state.addAuthRepData.ebtPhNum === undefined || this.state.addAuthRepData.ebtPhNum === null || this.state.addAuthRepData.ebtPhNum.length < 10) {
        this.setFlag("ebtPhNumError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
        if(this.state.addAuthRepData.ebtPhNum && this.state.addAuthRepData.ebtPhNum.length < 10) {
          this.setState({ebtPhoneValid: TextProps.VALUE_TRUE})
        }
        if(this.state.addAuthRepData.ebtPhNum === "") {
          this.setState({ebtPhoneValid: TextProps.VALUE_FALSE})
        }
      }
      if (this.state.addAuthRepData.signedName === "" || this.state.addAuthRepData.signedName === undefined || this.state.addAuthRepData.signedName === null) {

        this.setFlag("signedNameError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
      }
    }
    let HOH;
      let userName = "";
      if (
        this.props.recert.householdMembers &&
        this.props.recert.householdMembers.membersList
      ) {
        HOH = this.props.recert.householdMembers.membersList.filter(
          (mem) => mem.granteeFlag === TextProps.VALUE_TRUE
        )[0];
        userName = HOH.firstName + " " + HOH.lastName;
        if(this.state.addAuthRepData.signedName && this.state.addAuthRepData.signedName.toLowerCase() !== userName.toLowerCase() && this.state.addAuthRepData.signedName !== "" && this.state.addAuthRepData.signedName !== null) {
        this.setFlag("signedNameError", TextProps.VALUE_TRUE);
        this.state.signedNameNotMatch = TextProps.VALUE_TRUE;
        fieldErrorsFound = true;
        }
        if(this.state.addAuthRepData.signedName === "") {
          this.state.signedNameNotMatch = TextProps.VALUE_FALSE;
        }
      }
    
      
    if (this.state.snapAuthRepFlg === "Y") {
      if (this.state.addAuthRepData.snpFrstNam === "" || this.state.addAuthRepData.snpFrstNam === null || this.state.addAuthRepData.snpFrstNam === undefined) {
        this.setFlag("snpFrstNamError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
      }
      if (this.state.addAuthRepData.snpLstNam === "" || this.state.addAuthRepData.snpLstNam === null || this.state.addAuthRepData.snpLstNam === undefined) {
        this.setFlag("snpLstNamError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
      }
      if (this.state.addAuthRepData.snpPhNum === "" || this.state.addAuthRepData.snpPhNum === null || this.state.addAuthRepData.snpPhNum === undefined || this.state.addAuthRepData.snpPhNum.length < 10) {
        this.setFlag("snpPhNumError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
        if(this.state.addAuthRepData.snpPhNum && this.state.addAuthRepData.snpPhNum.length < 10) {
          this.setState({snpPhoneValid: TextProps.VALUE_TRUE})
        }
        if(this.state.addAuthRepData.snpPhNum === "") {
          this.setState({snpPhoneValid: TextProps.VALUE_FALSE})
        }
      }
      if (this.state.addAuthRepData.signedName === "" || this.state.addAuthRepData.signedName === undefined || this.state.addAuthRepData.signedName === null) {
        this.setFlag("signedNameError", TextProps.VALUE_TRUE);
        fieldErrorsFound = true;
      }
      if(this.state.rightsAdd < 1) {
        fieldErrorsFound = true;
      }
    }
    return fieldErrorsFound;

  }

  handleAuthRepDataChanged = (value) => {
    this.setState({authRepDataChanged: value})
  }

  handleebtAuthRepFlg = (value) => {
    this.setState({ebtAuthRepFlg: value})
  }

  handlesnapAuthRepFlg = (value) => {
    this.setState({snapAuthRepFlg: value})
  }

  handleAuthReChangeCode = (typeName, value) => {
    if(typeName === "ebtQuestion") {
      this.setState({ebtQuestionRemoved: value});
    }
    if(typeName === "snapQuestion") {
      this.setState({snapQuestionRemoved: value});
    }
  }

  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agrreErrorMsg = recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agrreErrorMsg = "";
      }
    });
  };
  acceptTermsConditionsAgree = (event) => {
    this.setState({ rightsAdd: event.length }, () => {
      if (this.state.rightsAdd < 1) {
        this.setState({ agreeErrorAdd: TextProps.VALUE_TRUE });
        this.agrreErrorMsg = recertificationPageLabels(
          language,
          null,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeErrorAdd: TextProps.VALUE_FALSE });
        this.agrreErrorMsg = "";
      }
    });
  };

  OnRemoveButton = (question) => {
    if(question === "ebtQuestion"){
      if(this.state.ebtAuthRepFlg === "N" || this.state.ebtAuthRepFlg === null || this.state.ebtAuthRepFlg === "Y" || this.state.addAuthRepData.ebtWebDataChangeCode === "NEW" || this.state.ebtAuthRepFlg === null) {
          this.clearFieldsByQuestion("ebtQuestion");
          this.setState({ebtAuthRepFlg: null});
          this.setState((prevState) => ({
            addAuthRepData: {
                ...prevState.addAuthRepData,
                ebtWebDataChangeCode: null
            }
        }));
        this.setFlag("ebtFrstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtLstNamError", TextProps.VALUE_FALSE);
      this.setFlag("ebtPhNumError", TextProps.VALUE_FALSE);
      }
      if(this.state.addAuthRepData.ebtWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.ebtWebDataChangeCode === "CHANGE") {
        this.setState({ebtAuthRepFlg: null});
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              ebtWebDataChangeCode: "REMOVE"
          }
      }));
      }
    }
    if(question === "snapQuestion"){
      if(this.state.snapAuthRepFlg === "N" || this.state.snapAuthRepFlg === null || this.state.snapAuthRepFlg === "Y" || this.state.addAuthRepData.snpWebDataChangeCode === "NEW" || this.state.snapAuthRepFlg === null) {
        this.clearFieldsByQuestion("snapQuestion");
          this.setState({snapAuthRepFlg: null});
          this.setState((prevState) => ({
            addAuthRepData: {
                ...prevState.addAuthRepData,
                snpWebDataChangeCode: null
            }
        }));
        this.setFlag("snpFrstNamError", TextProps.VALUE_FALSE);
      this.setFlag("snpLstNamError", TextProps.VALUE_FALSE);
      this.setFlag("snpPhNumError", TextProps.VALUE_FALSE);
      }
      if(this.state.addAuthRepData.snpWebDataChangeCode === "NOCHNG" || this.state.addAuthRepData.snpWebDataChangeCode === "CHANGE") {
        this.setState({snapAuthRepFlg: null});
        this.setState((prevState) => ({
          addAuthRepData: {
              ...prevState.addAuthRepData,
              snpWebDataChangeCode: "REMOVE"
          }
      }));
      }
    }
  }

  validateNames = (value, name) => {
    if(/\d/.test(value)) {
        let finalValue = value.replace(/\d/g, "");
        this.inputCheck(finalValue, name);
        this.state.addAuthRepData[name] = value;
    }
    // else {
    //     this.inputCheck(value, name);
    // }
}

  renderAddAuthRep = () => {
    return(
      <AddAuthRep
        onSectionChange={this.onSectionChange}
        setAuthRepDataUpdated={this.setAuthRepDataUpdated}
        onPrimaryActionClick={this.onSectionLinkClick}
        addAuthRepSubSection={this.props.addAuthRepSubSection}
        AuthRepData={this.props.recert}
        inputCheck={this.inputCheck}
        setFlag={this.setFlag}
        flags={this.state.flags}
        signedNameNotMatch={this.state.signedNameNotMatch}
              ebtPhoneValid={this.state.ebtPhoneValid}
      snpPhoneValid={this.state.snpPhoneValid}
        validatePhoneNumber={this.validatePhoneNumber}
        validateNames={this.validateNames}
        acceptTermsConditionsAgree={this.acceptTermsConditionsAgree}
        agreeErrorAdd={this.state.agreeErrorAdd}
        addAuthRepData={this.state.addAuthRepData}
        clearFieldsByQuestion={this.clearFieldsByQuestion}
        handleAuthRepDataChanged={this.handleAuthRepDataChanged}
        handleebtAuthRepFlg={this.handleebtAuthRepFlg}
        handlesnapAuthRepFlg={this.handlesnapAuthRepFlg}
        ebtAuthRepFlg={this.state.ebtAuthRepFlg}
        snapAuthRepFlg={this.state.snapAuthRepFlg}
        handleAuthReChangeCode={this.handleAuthReChangeCode}
        OnRemoveButton={this.OnRemoveButton}
      />
    )
  }

  // Markup for the body of apply page when at first "Expenses" section
  renderExpensesIndex = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p>{beforeYoustartMlanguageLabels(language).expenseFieldLabel}</p>
        <ul>
          <li>
            {beforeYoustartMlanguageLabels(language).expenseHouseutility}{" "}
          </li>
          <li>{beforeYoustartMlanguageLabels(language).expenseDepCaring}</li>
          <li>{beforeYoustartMlanguageLabels(language).expenseChsupport}</li>
          <li>{beforeYoustartMlanguageLabels(language).expenseMed}</li>
        </ul>
        <p className="text">
          {beforeYoustartMlanguageLabels(language).expenseAnyinfo}
        </p>
      </div>
    );
  };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: this.refs.applicanSign.inputEl.value }, () => {
      // if (this.state.signature.length < 1) {
      //   this.signatureError = TextProps.VALUE_TRUE;
      // } else {
      //   this.signatureError = TextProps.VALUE_FALSE;
      // }
    });
  };

  designPdfAsHTMLTemplate = (sections) => {
    if (sections !== undefined) {
      this.props.downloadPDFData(sections);
    }
  }

  openModal = () => {
    let url = RecertApplicationEN;
    switch (language.toLowerCase()) {
      case "en":
        url = RecertApplicationEN;
        break;
      case "es":
        url = RecertApplicationES;
        break;
      case "pt":
        url = rights_pt_url;
        break;
      case "vi":
        url = rights_vi_url;
        break;
      case "zh":
        url = rights_zh_url;
        break;

      default:
        break;
    }

    window.open(url, "_blank");
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmit = () => {
    const { sections } = this.props.recert;
    let signAndSubmitContent = (
      this.state.recert_type === "IR" ?
      (
        <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.viewApplicationDataPopupOpen,
          this.closeModal,
          "save-progress-modal summary-progress-modal",
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryTitle,
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryHeader,
          <div className="dta-modal__body pad-all">
            {getMainSections(sections, this.state.viewApplicationDataPopupOpen)}
          </div>
        )}
        {/* Clicking 'View your Application Summary' button will download PDF summary */}
        <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).rightsText
          }
          &nbsp;
          <button
            className="dta-button dta-button--link"
            onClick={this.openModal}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).viewApplicationLinkLabel
            }
          </button>
        </b>
        <form id={this.state.submitFormId}>
           <br/>
          <div>
            <strong>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).signatureandPenalty}</strong>
            <br />

            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).whenYourHouseholdReceivesSnap}</p>
            <ul>
              <li>
                {/* The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social
          Security Number (SSN) and the SSN of each household member I apply for. DHR uses this
          information to determine my household�s eligibility for SNAP. DHR verifies this
          information through computer matching programs. I understand that DHR uses it to monitor
          compliance with program regulations. */}{
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).hideInformation
                }
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).tradeOrSell}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).alterEbt}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).alcoholicDrinksOrTobacco}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).someoneElseSnapOrEbt}
              </li>
            </ul>
            <strong>
              {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).federalLaws}
            </strong>
            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).iUnderstandThePenalty}</p>
            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).iCertifyThatTheStatement}</p>
            <br />
          </div>
          {
            interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).didReportChanges
            }
          <br />
          <br />
          <div>
            {this.state.agreeError ? (
              <b>
                <p className="error-text-clr">{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <p >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication 
              }
            </p>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p className="error-text-clr">{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
          />
          <strong>
            {
              /*  Nondiscrimination Statement    */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).nonDiscriminationHeader
            }
          </strong>
          <p>
            {
              /*  In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil
            rights regulations and policies, the USDA, its Agencies, offices, and employees, and
            institutions participating in or administering USDA programs are prohibited from
            discriminating based on race, color, national origin, sex, religious creed, disability, age,
            political beliefs, or reprisal or retaliation for prior civil rights activity in any program
            or activity conducted or funded by USDA. */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).nonDiscriminationContent
            }
          </p>
          <p>
            {
              /*         Persons with disabilities who require alternative means of communication for program
            information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should
            contact the Agency (State or local) where they applied for benefits. Individuals who are
            deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay
            Service at */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).personDisabilities
            }
            <a href="tel:(800) 877-8339">(800) 877-8339</a>.
            {
              /*  Additionally, program
            information may be made available in languages other than English. */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).programInformation
            }
          </p>
          <p>
            {
              /*    To file a program complaint of discrimination, complete the USDA Program Discrimination
            Complaint Form, (AD-3027) found online at: */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).programComplaint
            }

            <a
              href=" https://w.w.w.usda.gov/sites/default/fles/documents/ad-3027.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="dta-link--wrap"
            >
              {`${appConfigConstants.programComplainLink},`}
            </a>
            {
              /* and at any USDA office, or write a letter addressed to USDA and provide in the letter
            all of the information requested in the form. To request a copy of the complaint form, call */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).usdaOffice
            }
          </p>
          <ol>
            <li className="pad-vertical">
              <strong>{
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mail}
              </strong>
              <br/>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[0]}

              <br />
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[1]}
                 <br />
                 {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[2]}
                 <br />
            </li>
            <li className="pad-vertical">
              <strong>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).fax}
              </strong>
              <br/>
              {"(833) 256-1665 or (202) 690-7442;"}
              {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).orLabel}
            </li>
            <li>
              <strong>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).eamilTitle}
              </strong>
              <br/>
              <a href="FNSCIVILRIGHTSCOMPLAINTS@usda.gov">FNSCIVILRIGHTSCOMPLAINTS@usda.gov</a>.
            </li>
          </ol>
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).thisInstution
            }
          </p>
        </form>
      </div>
      )
      : (

      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.viewApplicationDataPopupOpen,
          this.closeModal,
          "save-progress-modal summary-progress-modal",
          recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).applicationSummaryTitle,
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          recertificationPageLabels(
            language,
            null,
            languageConstants.beforeYouStart
          ).applicationSummaryHeader,
          <div
            className="dta-modal__body pad-all"
            style={{ background: "red" }}
          >
            {getMainSections(sections, this.state.viewApplicationDataPopupOpen)}
          </div>
        )}  
        {this.state.recert_type !== "REEVAL" && (
        <b>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).rightsText
          }
          &nbsp;
          <button
            className="dta-button dta-button--link"
            onClick={this.openModal}
          >
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).viewApplicationLinkLabel
            }
          </button>
        </b>
        )}
        <form id={this.state.submitFormId}>
          <TermsRecert id="submit-1" className="dta-form__label" />
          {this.state.recert_type !== "REEVAL" ? <p>
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).agreeContent[0]
            }
          </p> : <p>
            {
              termsLanguageTranslation(language).tanfCertify
            }
          </p>}
          {/* <ul>
            <li>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).agreeContent[1]
              }
            </li>
            <li>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).agreeContent[2]
              }
            </li>
            <li>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).agreeContent[3]
              }
            </li>
          </ul> */}
          <div>
            {this.state.agreeError ? (
              <span className="agreeError dta-form__error-message">
              {this.agrreErrorMsg}
          </span>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={this.state.recert_type === "REEVAL" ? [
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).iagreetanfTermsLabel,
            ] : [
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <p>
              {this.state.recert_type === "IR" ? 
              (
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              )
              : this.state.recert_type !== "REEVAL" ? (
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).signYourAppllication 
              ) : recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).tanfSignyourApplication
              }
            </p>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <span className="agreeError dta-form__error-message">
              {this.signatureErrorMsg}
          </span>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
            value={this.props.recert && this.props.recert.responseObject && this.props.recert.responseObject.signedName ? this.props.recert.responseObject.signedName : ""}
          />
        </form>
      </div>
      )
    )
    return (
      signAndSubmitContent
    )
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmitPartial = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        <p>
          <b>
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).rightsHHRemovedText
            }
          </b>
          <br />
        </p>
        <form id={this.state.submitFormId}>
          <div>
            {this.state.agreeError ? (
              <b>
                <p style={{ color: "red" }}>{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <p>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              }
            </p>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p style={{ color: "red" }}>{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.signatureError}
            value={this.props.recert && this.props.recert.responseObject && this.props.recert.responseObject.signedName ? this.props.recert.responseObject.signedName : ""}
          />
        </form>
      </div>
    );
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    return null;
  };

  // download pdf files link
  downloadApplicationAsPdf = () => {
    window.open(
      "https://dta-connect-service.azurewebsites.net/snapapplication.pdf",
      "_blank"
    );
  }
  setHomelessMailingAddress = (answer) => {
    if (answer["add-contact-info"] && answer["add-contact-info"]["is-homeless"]) {
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        this.setState({ renderedSections: renderedSectionsCopy },
        () => {
          if(answer["add-contact-info"]["is-homeless"] === TextProps.VALUE_YES && !this.state.setHomelessModalOpen){
            this.setState({isHomelessModalOpen: TextProps.VALUE_TRUE,setHomelessModalOpen: TextProps.VALUE_TRUE});
          }else if(answer["add-contact-info"]["is-homeless"] === TextProps.VALUE_NO){
            this.setState({isHomelessModalOpen: TextProps.VALUE_FALSE,setHomelessModalOpen: TextProps.VALUE_FALSE});
          }
          renderedSectionsCopy.push(incomingSection);
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.recert);
        });
    }
  };
  _bindAnswerObject = (answer) => {
    if (this.state.activeSectionId === "add-contact-info") {
      let contactAnswer = answer["add-contact-info"];
      if (
        contactAnswer.mailingAddress !== undefined &&
        contactAnswer["is-also-mailing"] === undefined
      ) {
        contactAnswer["is-also-mailing"] =
          this.props.recert.contactInfoScreen.addressList.address[0]
            .resAddrMatchFlag &&
          this.props.recert.contactInfoScreen.addressList.homeless !==
            TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
      }
      if (
        contactAnswer.mailingAddress === undefined &&
        contactAnswer["is-also-mailing"] !== undefined &&
        contactAnswer["is-also-mailing"] === TextProps.VALUE_STR_FALSE
      ) {
        let addressList = this.props.recert.contactInfoScreen.addressList;
        let mailingAddress = null;
        addressList.address.map((addr) => {
          if (addr.typeCode === "MAIL") mailingAddress = addr;
        });
        contactAnswer.mailingAddress = mailingAddress
          ? {
              street: mailingAddress.line1 + " " + mailingAddress.line2,
              zipCode: mailingAddress.zip,
              state: mailingAddress.state,
              city: mailingAddress.city,
            }
          : { street: "", zipCode: "", state: "", city: "" };
      }
      answer["add-contact-info"] = contactAnswer;
    }
    this.setState({
      AnswerObject: answer,
      sectionDataUpdated: TextProps.VALUE_TRUE,
    },() => {
      if (answer && answer["add-contact-info"] && answer["add-contact-info"]["is-homeless"] && (answer["add-contact-info"]["is-homeless"] !== this.state.homelessCheck || answer["add-contact-info"]["is-also-mailing"] !== this.state.isMailCheck)) {
        if(answer["add-contact-info"]["is-homeless"] === TextProps.VALUE_YES){
          answer["add-contact-info"]["is-also-mailing"] = TextProps.VALUE_STR_FALSE;
        }
        if(answer["add-contact-info"]["is-homeless"] !== this.state.homelessCheck && answer["add-contact-info"]["is-homeless"] === TextProps.VALUE_NO){
          answer["add-contact-info"]["is-also-mailing"] = TextProps.VALUE_STR_TRUE;
        }
        this.setState({homelessCheck : _.cloneDeep(answer["add-contact-info"]["is-homeless"]),isMailCheck : _.cloneDeep(answer["add-contact-info"]["is-also-mailing"])});
        this.setHomelessMailingAddress(answer);
    }
    });
  };

  //Reset contact info answer state object when user selects "No" to change question and tries to edit address again
  resetContactState = () => {
    if (this.props.recert.contactInfoScreen !== undefined) {
      let contactAnswer = this.state.AnswerObject["add-contact-info"];
      if (contactAnswer) {
        let contactObj = this.props.recert.contactInfoScreen.addressList;
        contactAnswer["is-also-mailing"] =
          contactObj.address[0].resAddrMatchFlag &&
          contactObj.homeless !== TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
        let mailAddressObj = null;
        if (contactObj.address[0].typeCode === "MAIL") {
          mailAddressObj = contactObj.address[0];
        } else if (
          contactObj.address[1] &&
          contactObj.address[1].typeCode === "MAIL"
        ) {
          mailAddressObj = contactObj.address[1];
        }
        contactAnswer.mailingAddress = mailAddressObj
          ? {
              street: mailAddressObj.line1 + " " + mailAddressObj.line2,
              zipCode: mailAddressObj.zip,
              state: mailAddressObj.state,
              city: mailAddressObj.city,
            }
          : { street: "", zipCode: "", state: "", city: "" };
        this.setState({
          AnswerObject: contactAnswer,
        });
      }
    }
  };
  onSaveExitClick = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event, TextProps.SAVEEXIT);
  };
  /// uncomment all
  onSignSubmitActionClick = (event) => {
    event.preventDefault();
    this.onIncomeSubSectionClean();
    this.onIncomeSubsectionRender();
    this.onUnearnedIncomeSubSectionClean(); 
    this.onUnearnedIncomeSubsectionRender();
    if(this.state.recert_type !== "REEVAL") {
      this.shelterSubsectionRender();
      this.renderDependentsCareCosts();
      this.onChildSupportSubsectionRender();
      this.prepareMedicalExpensesSummary();
      this.renderMedicalExpensesSubSections(true);
    }
    this.onPrimaryActionClick(event, TextProps.SIGNSUBMIT);
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <RecertSection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            onSaveExitClick={this.onSaveExitClick}
            redirectSign={this.redirectToSignAndSubmit}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
            signAndSubmit={
              this.props.recert.additionalProperties &&
              this.props.recert.additionalProperties.contactInfoScreen
                ? this.props.recert.additionalProperties.contactInfoScreen
                    .contactInfoChanged === TextProps.VALUE_FALSE ||
                  this.props.recert.additionalProperties.contactInfoScreen
                    .contactInfoChanged === TextProps.VALUE_TRUE
                  ? TextProps.VALUE_TRUE
                  : TextProps.VALUE_FALSE
                : TextProps.VALUE_FALSE
            }
            onSignSubmitActionClick={this.onSignSubmitActionClick}
            recertType={this.state.recert_type}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </RecertSection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    //start of google analytics related code added in client side for  partial submit
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.DT_PARTIAL_APPLY,
        "submitApplicationPartially",
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();
    //end of google analytics related code added in client side for  partial submit

    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    if(this.state.activeSectionId === "summary") {
    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);
    }
    else {
      const incomingSection = this._getSection("summary");
      renderedSectionsCopy.push(incomingSection);
    }
    this.props.recert.sections.filter((sec) => sec.id === "summary")[0].completed = TextProps.VALUE_TRUE;

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: this.state.activeSectionId === "summary" ? "sign-and-submit" : "summary",
        });
      }
    );
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
        return this.renderBeforeYouStart();
      case "contact-info":
        return this.renderContactInfo();
      case "my-household":
        return this.renderHouseholdInfo(); 
      case "tanf-household-information":
        return this.renderTanfHouseholdInfo();
      case "noncitizen":
        return this.renderNonCitizen();
      case "shelter-utility-summary":
        return this.renderShelterAndUtility();
      case "childsupport-expenses":
        // this.shelterSubsectionRender();
        return this.renderChildSupportExpenses();
      case "medical-expenses":
        // this.shelterSubsectionRender();
        return this.renderMedicalExpenses();
      //childsupport-expenses-summary
      // case "childsupport-expenses":
      //   this.shelterSubsectionRender();
      case "auth-rep":
        return this.renderAuthRep();
        case "add-auth-rep":
          return this.renderAddAuthRep();
      case "expenses":
        // this.shelterSubsectionRender();
        return this.renderExpensesIndex();
      case "summary":
        return this.renderSummary();
      case "students":
        return this.renderStudents();
      case "income-summary":
        return this.renderEarnedIncomeSummary();
      case "resources-summary":
        return this.renderResourcesSummary();
      case "unearned-income-summary":
        return this.renderUnearnedIncomeSummary();
      case "sign-and-submit":
        if (!this.state.isHOHRemoved)
          return this.renderSignAndSubmit();
        else return this.renderSignAndSubmitPartial();
      case "submitted":
        return this.renderSubmitSummary();
      default:
        return <div>Something went wrong</div>;
    }
  };

  renderSummary = () => {
    return <RecertSummaryContainer authRepSummary={this.props.recert.authorizedRepresentatives} ebtStateFlg={this.state.ebtAuthRepFlg} snpStateFlg={this.state.snapAuthRepFlg}/>;
  };

  getSectionDataForSummary = (sectionId) => {
    let currentSectionData = {};
    switch (sectionId) {
      case "contact-info":
        currentSectionData = this.props.recert.contactInfoScreen;
        break;
      case "my-household":
        currentSectionData = this.props.recert.householdMembers;
        break;
      default:
        currentSectionData = {};
        break;
    }
    return currentSectionData;
  };

  getSectionWithBindAnswers = (sectionIdentifier, sectionData) => {
    let currentSectionTemplate;

    if (sectionIdentifier === "contact-info") {
      let contactInfoTemplate = _.cloneDeep(contactInfoSummaryTemplate);
      const headOfHousehold = this.props.recert.responseObject.householdMembers.filter(member => member.granteeFlag === true);
      const headOfHouseholdName = headOfHousehold && headOfHousehold.map(headMember => headMember.firstName + " " + headMember.lastName);
      const homeAddress =
        sectionData.addressList &&
        sectionData.addressList.address &&
        sectionData.addressList.address.filter(
          (homeAddress) => homeAddress.typeCode === "RES"
        );
      let homeAddressObj = {};
      if (homeAddress && homeAddress.length) {
        homeAddressObj = {
          addressLine1: homeAddress[0].line1,
          addressLine2: homeAddress[0].line2,
          city: homeAddress[0].city,
          county: homeAddress[0],
          state: homeAddress[0].state,
          zipCode: homeAddress[0].zip,
        };
      }

      const mailingAddress =
        sectionData.addressList &&
        sectionData.addressList.address &&
        sectionData.addressList.address.filter(
          (mailAddress) =>
            mailAddress.typeCode === "MAIL" &&
            mailAddress.resAddrMatchFlag === false
        );

        contactInfoTemplate.subsections[0].questions[0].Answer = headOfHouseholdName[0];
        contactInfoTemplate.subsections[0].questions[0].targetValue = headOfHouseholdName[0];
        contactInfoTemplate.subsections[0].questions[1].Answer = sectionData
        .addressList.homeless
        ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
      contactInfoTemplate.subsections[0].questions[1].targetValue = sectionData
        .addressList.homeless
        ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
      contactInfoTemplate.subsections[0].questions[2].Answer = homeAddressObj;
      contactInfoTemplate.subsections[0].questions[2].targetValue =
        homeAddressObj;
        contactInfoTemplate.subsections[0].questions[3].Answer = mailingAddress && mailingAddress.length > 0 ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
      contactInfoTemplate.subsections[0].questions[3].targetValue = mailingAddress && mailingAddress.length > 0 ? interimReportPageLabels(language, languageConstants.aboutMe)
      .optionYesNo[1]
      : interimReportPageLabels(language, languageConstants.aboutMe)
      .optionYesNo[0];
      contactInfoTemplate.subsections[0].questions[3].conditionals[0].conditionalQuestions[0].Answer =
        mailingAddress ? mailingAddress[0] : "Same as resedential address";
      contactInfoTemplate.subsections[0].questions[3].conditionals[0].conditionalQuestions[0].targetValue =
        mailingAddress ? mailingAddress[0] : "Same as resedential address";
      contactInfoTemplate.subsections[0].questions[4].Answer =
        sectionData.phoneNumber;
      contactInfoTemplate.subsections[0].questions[4].targetValue =
        sectionData.phoneNumber;
      contactInfoTemplate.subsections[0].questions[5].Answer =
        sectionData.textOptIn ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
      contactInfoTemplate.subsections[0].questions[5].targetValue =
        sectionData.textOptIn ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
      contactInfoTemplate.subsections[0].questions[6].Answer =
        sectionData.email;
      contactInfoTemplate.subsections[0].questions[6].targetValue =
        sectionData.email;
      contactInfoTemplate.subsections[0].questions[7].Answer =
        sectionData.emailOptIn ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
      contactInfoTemplate.subsections[0].questions[7].targetValue =
        sectionData.emailOptIn ? interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0]
        : interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];

      currentSectionTemplate = contactInfoTemplate;
    } else if (sectionIdentifier === "my-household") {
      let householdDetailsTemplate = _.cloneDeep(householdSummaryTemplate);
      let memberDetails =
        sectionData &&
        sectionData.membersList.map((household) => {
          let householdDetailsSubsections = _.cloneDeep(
            householdDetailsTemplate.subsections[0]
          );
          householdDetailsSubsections.questions[0].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[0].Answer
              : household.firstName;
          householdDetailsSubsections.questions[0].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[0].Answer
              : household.firstName;
          householdDetailsSubsections.questions[1].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[1].Answer
              : household.middleName;
          householdDetailsSubsections.questions[1].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[1].Answer
              : household.middleName;
          householdDetailsSubsections.questions[2].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[2].Answer
              : household.lastName;
          householdDetailsSubsections.questions[2].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[2].Answer
              : household.lastName;
          householdDetailsSubsections.questions[3].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[3].Answer
              : household.suffix;
          householdDetailsSubsections.questions[3].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[3].Answer
              : household.suffix;
          householdDetailsSubsections.questions[4].Answer =
            household.allAnswers && household.allAnswers.length
              ? relationshipCodeMapper(language)[household.allAnswers[4].Answer]
              : household.relationshipCode;
          householdDetailsSubsections.questions[4].targetValue =
            household.allAnswers && household.allAnswers.length
              ? relationshipCodeMapper(language)[household.allAnswers[4].Answer]
              : household.relationshipCode;
          householdDetailsSubsections.questions[5].Answer =
            household.allAnswers && household.allAnswers.length
              ? formatters.convertTimestampToToDataFormat(
                  household.allAnswers[5].Answer
                )
              : household.dateOfBirth;
          householdDetailsSubsections.questions[5].targetValue =
            household.allAnswers && household.allAnswers.length
              ? formatters.convertTimestampToToDataFormat(
                  household.allAnswers[5].Answer
                )
              : household.dateOfBirth;
          householdDetailsSubsections.questions[6].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[6].Answer
              : genderCodeMapper[household.gender];
          householdDetailsSubsections.questions[6].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[6].Answer
              : genderCodeMapper[household.gender];
          if(this.state.recert_type !== "REEVAL"){
            householdDetailsSubsections.questions[7].Answer =
            household.allAnswers && household.allAnswers.length
              ? ethinicityCodeMapper(language)[household.allAnswers[7].Answer]
              : ethinicityCodeMapper(language)[household.ethnicity];
          householdDetailsSubsections.questions[7].targetValue =
            household.allAnswers && household.allAnswers.length
              ? ethinicityCodeMapper(language)[household.allAnswers[7].Answer]
              : ethinicityCodeMapper(language)[household.ethnicity];
          householdDetailsSubsections.questions[8].Answer =
            household.allAnswers && household.allAnswers.length
              ? raceCodeMapper[household.allAnswers[8].Answer]
              : household.race &&
                household.race[0] &&
                raceCodeMapper[household.race[0].raceCode];
          householdDetailsSubsections.questions[8].targetValue =
            household.allAnswers && household.allAnswers.length
              ? raceCodeMapper[household.allAnswers[8].Answer]
              : household.race &&
                household.race[0] &&
                household.race[0].raceCode &&
                raceCodeMapper[household.race[0].raceCode];
          householdDetailsSubsections.questions[9].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[9].Answer
              : household.citizenFlag
              ?  interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
              : interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[1];
          householdDetailsSubsections.questions[9].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[9].Answer
              : household.citizenFlag
              ?  interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
              : interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[1];
          householdDetailsSubsections.questions[10].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[10].Answer
              : household.SSN;
          householdDetailsSubsections.questions[10].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[10].Answer
              : household.SSN;
          householdDetailsSubsections.questions[11].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[11].Answer
              : household["buy-food"];
          householdDetailsSubsections.questions[11].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[11].Answer
              : household["buy-food"];
          householdDetailsSubsections.questions[12].Answer =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[12].Answer
              : household.incomeFlag
              ?  interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
              : interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[1];
          householdDetailsSubsections.questions[12].targetValue =
            household.allAnswers && household.allAnswers.length
              ? household.allAnswers[12].Answer
              : household.incomeFlag
              ?  interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
              : interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[1];
          }
          return householdDetailsSubsections;
        });
      householdDetailsTemplate.subsections = memberDetails;
      currentSectionTemplate = householdDetailsTemplate;
    }
    return currentSectionTemplate;
  };

  // This is the main markup for the apply layout
  render() {
    let { sections } = this.props.recert;
    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);
    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      return <Loading />;
    }
  }

  renderStudents = () => {
    var studentSection = filterData(
      this.props.recert.sections,
      "students"
    );
    let student_count = 0;
    let students = [];
    studentSection.questions[0].options = [];
    const houseHoleMemberList = this.props.recert.householdMembers.membersList;
    houseHoleMemberList.map((studentdetail) => {
      if (
        !studentdetail.dataChangeCode ||
        studentdetail.dataChangeCode === "NOCHNG"
      ) {
        students[student_count] = {};
        students[student_count][OptionsProps.OPTION_VALUE] =
          "student-" + [student_count + 1];
        students[student_count][OptionsProps.OPTION_DISPLAY] =
          studentdetail.firstName +
          " " +
          studentdetail.lastName +
          " " +
          formatters.formatDate(studentdetail.dateOfBirth);
        students[student_count].isGrantee = studentdetail.isGrantee;
        studentSection.questions[0].options.push(students[student_count]);
        student_count++;
      }
    });
    this.props.addStudentToList(studentSection);
  };

  addStudentToList = (value) => {
    let students = [];
    value.map((student, index) => {
      students.push({
        id: "students-subsection-" + index,
        title: student, //'Submitted',
        header: student + "'s school details", //'Your application has been submitted!',
        questions: [
          {
            [Props.NAME]: "schooltype",
            [Props.LABEL]: recertificationPageLabels(
              language,
              student,
              languageConstants.studentStatic
            ).schoolTypeQ,
            [Props.TYPE]: types.RADIO,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "HGHSCH",
                [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.studentStatic
                ).hschool,
              },
              {
                [OptionsProps.OPTION_VALUE]: "COLLGE",
                [OptionsProps.OPTION_DISPLAY]: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.studentStatic
                ).college,
              },
            ],
            [OptionsProps.OPTIONS_PER_ROW]: 2,
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
          },
        ],
      });
    });

    this.props.addStudentToList(students);
  };

  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      this.updateDimensions();
      const isFreeComOpen = sessionStorage.getItem("openFreeComm");
      const { common } = this.props.language.security;
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
      var loginFlag = TextProps.VALUE_FALSE;
      if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
        loginFlag = TextProps.VALUE_TRUE;
      } else {
        if (loginUserInfo !== null && loginUserInfo !== undefined) {
          if (loginUserInfo.firstName !== "") {
            loginFlag = TextProps.VALUE_TRUE;
          }
        }
      }
      var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
      var userRole = userObject.roleList[1]
        ? userObject.roleList[1].roleName
        : userObject.roleList[0].roleName;
      const msalInstance = this.props.msalContext.instance;
      if (isFreeComOpen === "true") {
        return (
          <div style={{ height: "100%" }}>
            <div
              className="full-height apply recert-container"
              style={{ overflowY: "unset" }}
            >
              <div style={{ display: "contents" }}>
                <DocumentTitle
                  title={
                    this.props &&
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.title
                      ? this.props.location.state.title
                      : this.state.recert_type === "IR" ?
                      "Snap Six-Month Report" 
                      : "Recertification"
                  }
                />
                {/* See applyNavigation.js for documentation on ApplyNavigation props */}
                <SidebarNavigation
                  sections={questionsSections}
                  activeSectionId={this.state.activeSectionId}
                  numSectionsRemaining={4}
                  onSectionClick={this.checkDataOnSectionChange}
                  skipToAnchor="#content"
                  onDeleteClick={this.onDeleteClick}
                  recertType={this.state.recert_type}
                />
                <main
                  className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
                  id="content"
                  tabIndex="-1"
                  style={{ overflowY: "hidden" }}
                >
                  {this.state.renderedSections.map((section) =>
                    this.renderSection(section)
                  )}
                  {loginFlag === TextProps.VALUE_TRUE ? (
                    <div className="interim-recert__options">
                      <LanguageSelect className="interim-recert__language-select" />
                      {userRole !== roleProps.PROVIDER &&
                      userRole !== roleProps.PROVIDER_ROLE_SPVUSR &&
                      userRole !== roleProps.PROVIDER_ROLE_DCPVSG ? (
                        <div className="interim-recert__options-account">
                          <span className="interim-recert__headertext">
                            {common.hi}
                          </span>
                          &nbsp;
                          <DTADropdown
                            buttonText={userInfo.firstName}
                            buttonClass="dta-button dta-button--link"
                            menuClass="list--bordered"
                            caret={TextProps.VALUE_TRUE}
                            caretClass="color-primary"
                          >
                            <li className="pad-all--half">
                              <Link
                                to="/consumer/myInfo"
                                className="display-block"
                                onClick={this._handleApplyGoBack.bind(this)}
                              >
                                {common.home}
                              </Link>
                            </li>
                            <li className="pad-all--half border-top">
                              <React.Fragment>
                                {config.azureLoginFlag ? (
                                  <Link
                                    to="#"
                                    className="display-block"
                                    onClick={() =>
                                      this.props.onAzureLogOut(msalInstance)
                                    }
                                  >
                                    {common.logout}
                                  </Link>
                                ) : (
                                  <Link
                                    to="#"
                                    className="display-block"
                                    onClick={this._handleApplyLogoutSession.bind(
                                      this
                                    )}
                                  >
                                    {common.logout}
                                  </Link>
                                )}
                              </React.Fragment>
                            </li>
                          </DTADropdown>
                        </div>
                      ) : (
                        <div className="interim-recert__options-account">
                          <div className="provider-spacer" />
                          <button
                            className="dta-button provider-return"
                            onClick={this.gotoProvier}
                          >
                            <span className="provider-return__text">
                              {common.provider}
                              <br />
                              <Icon name="home" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <LanguageSelect className="language-select--dark" />
                  )}
                </main>
                {this.renderWarningPopup()}
                {this.renderAddressValidationModal()}
                {this.renderSaveExitWarningModal()}
                {this.renderDeleteWarningModal()}
                {this.renderACESButtonClickWarningModal()}
                {this.renderSaveOrExitWarningModal()}
                {this.renderLanguageSwitchWarningModal()}
                {this.renderIsHomelessModal()}
              </div>
            </div>
          </div>
        );
      }
    }
  }

  gotoProvier = () => {
    this.props.gotoProvider();
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.props.recert.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    // if(targetSection && targetSection.id === "shelter-utility-summary")
    // {
    //   targetSection.completed = true;
    //   targetSection.selectionMade = true;
    // }
    return targetSection;
  }

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj && sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  downloadPDFData() {
    this.props.downloadPDFData(this.props.recert.sections);
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      recertQuestionsSections: recertQuestionsSections,
      clearMemberIncome: clearMemberIncome,
      clearMemberResources:clearMemberResources,
      appendHouseHoldMembersRecert: appendHouseHoldMembersRecert,
      // appendRecertIncomeDetails: appendRecertIncomeDetails,
      appendStudentetails: appendStudentetails,
      appendRecertExpenseDetails: appendRecertExpenseDetails,
      setShelterDefaultQuestions: setShelterDefaultQuestions,
      saveRecretDataAction: saveRecretDataAction,
      updateResolveQuestionsData: updateResolveQuestionsData,
      addressValidation: addressValidation,
      applyAddressStorageAPI: applyAddressStorageAPI,
      downloadPDFData: downloadPDFData,
      updateCurrentSectionData: updateCurrentSectionData,
      addStudentToList: addStudentToList,
      setDataChangeStatus: setDataChangeStatus,
      setDataChangeStatusForExpense: setDataChangeStatusForExpense,
      setShelterUtilityChangeStatus: setShelterUtilityChangeStatus,
      setHouseholdMembers: setHouseholdMembers,
      setChildSupportData: setChildSupportData,
      setShelterOptions: setShelterOptions,
      setMedicalExpensesChanged: setMedicalExpensesChanged,
      addHouseholdAddSectionToList: addHouseholdAddSectionToList,
      setContactInfo: setContactInfo,
      setRecertResponseObj: setRecertResponseObj,
      submitRecertData: postRecertData,
      saveRecertData: saveRecertData,
      updateHouseholdTextChangesRecert: updateHouseholdTextChangesRecert,
      addContactInfoSubSection: addContactInfoSubSection,
      addAuthRepSubSection: addAuthRepSubSection,
      snapRefreshQuestionsSections: snapRefreshQuestionsSections,
      recertStateRefreshSection: recertStateRefreshSection,
      setGrossEarnedIncomeChangeStatus: setGrossEarnedIncomeChangeStatus,
      setUnearnedIncomeChangeStatus: setUnearnedIncomeChangeStatus,
      setEarnedIncomeChangeStatus: setEarnedIncomeChangeStatus,
      setHouseholdResourcesChangedStatus: setHouseholdResourcesChangedStatus,
      setObligationChildSupportChangeStatus:
        setObligationChildSupportChangeStatus,
      setCourtOrderedChildSupportChangeStatus:
        setCourtOrderedChildSupportChangeStatus,
      setShelterData: setShelterData,
      appendShelterFilteredQuestions: appendShelterFilteredQuestions,
      saveandExitAction: saveandExitAction,
      deleteRecertApplication: deleteRecertApplication,
      displayPDFData: displayPDFData,
      appendIRIncomeDetails: appendIRIncomeDetails,
      appendResourcesDetails:appendResourcesDetails,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoHome: () => push(routeProps.HOME_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      displayError: () => push(routeProps.API_ERR_PATH),
      appendRecertDataForSummary: appendRecertDataForSummary,
      appendUIIncomeDetails: appendUIIncomeDetails,
      // appendIRExpenseDetails: appendIRExpenseDetails,
      clearMemberUIIncome: clearMemberUIIncome,
      updateResolveQuestionsData: updateResolveQuestionsData,
      updateCurrentSectionData: updateCurrentSectionData,
      addStudentToList: addStudentToList,
      setHouseholdMembers: setHouseholdMembers,
      setContactInfo: setContactInfo,
      acesButtonClicked: acesButtonClicked,
      setAuthRepResponseBodyData: setAuthRepResponseBodyData,
      setAuthRepChangeStatus:setAuthRepChangeStatus,
      languageChanged: languageChanged,
      setTanfResponseBodyData: setTanfResponseBodyData,

      // setInterimReportResponseObj: setInterimReportResponseObj,
      // submitInterimReportData: postInterimReportData,
      // updateHouseholdTextChanges: updateHouseholdTextChanges,
    },
    dispatch
  );
}

export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(Recertification)
);
