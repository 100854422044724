
const EN = 'en-US';
const ES = 'es-ES';
const PT = 'pt-BR';
const ZH = 'zh-Hans';
const VI = 'vi-VI';
const HI = 'ht';

export function CommonMultiLanguage(language) { 
  switch (language) {
  case EN:
  return {
    validPassword:"A valid password is:",
    lowerCase: "At least one lowercase letter",
    upperCase: "At least one uppercase letter",
    digit: "At least one digit",
    eightChar: "At least 8 characters long",
    show: "Show",
    hide: "Hide",
    defineTerm: "Define this term",
    veryWeak: 'Very weak', 
    weak: 'Weak', 
    medium: 'Medium', 
    strong: 'Strong', 
    veryStrong: 'Very Strong',
    tooShort: 'too short',
  }
  case ES:
  return {
    validPassword:"Una contraseña válida tiene:",
    lowerCase: "Al menos una letra minúscula",
    upperCase: "Al menos una letra mayúscula",
    digit: "Al menos un número",
    eightChar: "Al menos 8 caracteres",
    show: "Mostrar",
    hide: "Ocultar",
    defineTerm: "Define this term",
    veryWeak: 'Muy débil', 
    weak: 'Débil', 
    medium: 'Medio', 
    strong: 'Fuerte', 
    veryStrong: 'Muy fuerte',
    tooShort: 'Demasiado corta',
  }
  case PT:
  return {
    validPassword:"Uma senha válida contém:",
    lowerCase: "Pelo menos uma letra minúscula",
    upperCase: "Pelo menos uma letra maiúscula",
    digit: "Pelo menos um número",
    eightChar: "Pelo menos 8 caracteres",
    show: "Mostrar",
    hide: "Ocultar",
    defineTerm: "Define this term",
    veryWeak: 'Muito fraco', 
    weak: 'Fraco', 
    medium: 'Médio', 
    strong: 'Forte', 
    veryStrong: 'Muito forte',
    tooShort: 'muito curto',
  }
  case ZH:
  return {
    validPassword:"有效密码是:",
    lowerCase: "至少一个小写字母",
    upperCase: "至少一个大写字母",
    digit: "至少一个数字",
    eightChar: "至少 8 个字符长",
    show: "显示",
    hide: "隐藏",
    defineTerm: "Define this term",
    veryWeak: '非常弱', 
    weak: '虚弱的', 
    medium: '中等的', 
    strong: '强的', 
    veryStrong: '非常强壮',
    tooShort: '太短',
  }
  case VI:
  return {
    validPassword:"Mật khẩu hợp lệ là:",
    lowerCase: "Có ít nhất một chữ thường",
    upperCase: "Có ít nhất một chữ hoa",
    digit: "Có ít nhất một con số",
    eightChar: "Có ít nhất 8 kí tự",
    show: "Cho thấy",
    hide: "Giấu đi",
    defineTerm: "Define this term",
    veryWeak: 'Rất yếu', 
    weak: 'Yếu đuối', 
    medium: 'Trung bình', 
    strong: 'Mạnh', 
    veryStrong: 'Rất mạnh',
    tooShort: 'quá ngắn',
  }
  case HI:
  return {
    validPassword:"Yon modpas ki valid gen:",
    lowerCase: "Omwen yon lèt miniskil",
    upperCase: "Omwen yon lèt majiskil",
    digit: "Omwen yon chif",
    eightChar: "Omwen longè 8 karaktè",
    show: "Montre",
    hide: "Kache",
    defineTerm: "Defini mo sa a",
    veryWeak: 'Trè fèb', 
    weak: 'Fèb', 
    medium: 'Mwayen', 
    strong: 'Fò', 
    veryStrong: 'Fò Anpil',
    tooShort: 'Twò kout',
  }
  default:
    return;
}
}