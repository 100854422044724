import * as TextProps from "../../utils/constants/text";

export function activatePEBTReducer(state = {}, action) {
  switch (action.type) {
    case "ACTIVATE_PEBT_PAGES_FULLFILLED": {
      let newState = Object.assign({}, state);
      newState.sections = action.payload;
      return newState;
    }
    case "ACTIVATE_PEBT_NETWORK_FAILED_LOAD_DATA": {
      let stateObj = Object.assign({}, state);
      if (stateObj.sections.length > 0) {
        if (stateObj.sections[0].errorRedirect !== undefined) {
          stateObj.sections[0].errorRedirect = TextProps.VALUE_TRUE;
          return stateObj;
        }
      }
      return Object.assign({}, state);
    }
    case "POST_APPLY_PEBT_INFO_DATA": {
      let stateObj = Object.assign({}, state);
      //INSTEAD, CHECKING AND SETTING ONY ID.
      if (action.payload.status === 200) {
        stateObj.applicationSubmited = TextProps.VALUE_TRUE;
        stateObj.applicationStatus = action.payload.data.matchType;
      }
      return stateObj;
    }
    case "FILL_PEBT_INPUT_VALUES_STATE": {
      return Object.assign({}, state);
    }
    case "APPEND_PEBT_APPLY_ANSWER": {
      const stateObj = Object.assign({}, state);
      stateObj.AnswerObject = action.payload[0];
      stateObj.dalReference = action.payload[1];
      return stateObj;
    }

    case "ACTIVE_PEBT_CASE_SUCCESS": {
      const stateObj = Object.assign({}, state);
      stateObj.pebtData = action.payload.data;
      stateObj.isloading = TextProps.VALUE_FALSE;
      return stateObj;
    }

    case "ACTIVE_PEBT_CASE_FAILED": {
      let stateObj = Object.assign({}, state);
      stateObj.pebtData = "No Case Information";
      stateObj.isloading = TextProps.VALUE_FALSE;
      return stateObj;
    }

    case "GET_ACTIVE_PEBT_CASE": {
      let stateObj = Object.assign({}, state);
      stateObj.isloading = TextProps.VALUE_TRUE;
      return stateObj;
    }

    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      applyInit.inProgressSaveErrorState = TextProps.VALUE_FALSE;
      applyInit.inProgressSaveErrorMessage = "";
      return applyInit;
    }

    default:
      return Object.assign({}, state);
  }
}
