import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";

import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import OptionItem from "./optionItem";
import { addStringIf, tryCall } from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import {
  RADIO_CLASSES,
  INPUT_TYPE_MONEY,
} from "../../utils/constants/controls";

import HelpTip from "../components/helpTip";

import { getFrequencyCode } from "../components/utilities/utils";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class UnEarnedIncome extends Component {
  static propTypes = {
    ...CommonTypes,
    // required
    options: PropTypes.array.isRequired,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // text display
    inBetween: PropTypes.string,
    // states
    money: PropTypes.bool,
    // one-way data bindings
    value: PropTypes.shape({
      amount: PropTypes.array,
      unit: PropTypes.string,
    }),
    // clear
    clearLabel: PropTypes.string,
    showClear: PropTypes.bool,
  };
  static defaultProps = {
    ...CommonDefaults,
    // text display
    inBetween: "per",
    // states
    money: TextProps.VALUE_FALSE,
    // one-way data bindings
    value: { amount: [], unit: "" },
    // clear
    clearLabel: "None",
    showClear: TextProps.VALUE_FALSE,
    maxLength: PropTypes.number,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    let amountCount = 1;

    if (props.value.unit) {
      let freqCode = getFrequencyCode(language)[props.value.unit];
      if (freqCode === "WEEK") {
        amountCount = 4;
      } else if (freqCode === "BIWEEK") {
        amountCount = 2;
      }
    }

    let newAmount = [];
    for (let i = 0; i < amountCount; i++) {
      newAmount[i] =
        props.value.amount && props.value.amount[i]
          ? props.value.amount[i]
          : "";
    }

    this.state = {
      _isCleared: TextProps.VALUE_FALSE,
      isNew:
      props.value.code === "existing" || props.value.code === "changed" || props.value.code === "CHANGE" || props.value.code === "NOCHNG"
          ? TextProps.VALUE_FALSE
          : TextProps.VALUE_TRUE,
      _amountValue: newAmount ? newAmount : [],
      _unitsValue: props.value.unit ? props.value.unit : "",
      _fullIncomeObj: props.value.fullIncomeObj
        ? props.value.fullIncomeObj
        : "",
    };

    this._handleChangeForAmountHelper = _.debounce(
      this._handleChangeForAmountHelper,
      500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        _isCleared:
          !nextProps.value || !nextProps.value.amount || !nextProps.value.unit,
        _amountValue: nextProps.value.amount,
        _unitsValue: nextProps.value.unit,
        _fullIncomeObj: nextProps.value.fullIncomeObj,
      });
    }
  }

  render() {
    const { name, money, error, disabled, required, clearLabel, maxLength } =
        this.props,
      inputId = this.props.id,
      optionLabels = this.props.options,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={addStringIf(this.props.className, "dta-units")}>
        <div className="full-width">
          {this._buildUnits(name, optionLabels, error, disabled, required)}
          {this._buildAmount(
            name,
            inputId,
            ariaLabels,
            money,
            error,
            disabled,
            required,
            maxLength
          )}
        </div>
        {this.props.showClear &&
          this._buildClear(ariaLabels, name, clearLabel, error, disabled)}
      </div>
    );
  }

  // Rendering
  // ---------

  _buildAmount(name, id, ariaLabels, isMoney, isError, isDisabled, required) {
    let amountCount = 1;
    let classRowCount = 1;
    let freqCode = getFrequencyCode(language)[this.state._unitsValue];
    if (freqCode === "WEEK") {
      amountCount = 4;
      classRowCount = 3;
    } else if (freqCode === "BIWEEK") {
      amountCount = 2;
      classRowCount = 6;
    }

    let amountContainer = [];
    let classNameToUse = "dta-units__item pure-u-" + classRowCount + "-24";
    for (let i = 0; i < amountCount; i++) {
      amountContainer.push(
        <DTAInput
          name={name + "-" + i}
          type={isMoney ? INPUT_TYPE_MONEY : "number"}
          className={classNameToUse}
          id={id}
          aria-labelledby={ariaLabels}
          onChange={(value) => this._handleChangeForAmount(value, i)}
          value={
            this.state._amountValue !== undefined && this.state._amountValue[i]
              ? this.state._amountValue[i]
              : ""
          }
          error={isError}
          disabled={isDisabled}
          required={required}
          maxLength={12}
          ariaLabel={"grossAmount"}
        />
      );
    }
    return (
      <div className="dta-units__control max-width pure-u-1-1 paddingTopAndBottom">
        {/* <span className="spaced-text">{this.props.amountLabel}</span> */}
        <HelpTip
          textBefore={this.props.amountLabel.textBefore}
          triggerText={this.props.amountLabel.triggerText}
          textAfter={this.props.amountLabel.textAfter}
          helpText={this.props.amountLabel.helpText}
          bypassMobileStyle={TextProps.VALUE_TRUE}
        />
        {amountContainer}
      </div>
    );
  }

  _buildUnits(name, optionLabels, isError, isDisabled, required) {
    return (
      <div className="dta-units__control paddingTopAndBottom">
        <HelpTip
          textBefore={this.props.unitLabel.textBefore}
          triggerText={this.props.unitLabel.triggerText}
          textAfter={this.props.unitLabel.textAfter}
          helpText={this.props.unitLabel.helpText}
          bypassMobileStyle={TextProps.VALUE_TRUE}
        />
        <DTASelect
          name={name}
          options={optionLabels}
          className="dta-units__item"
          id={this.lastUniqueId()}
          aria-labelledby={this.lastUniqueId()}
          onChange={this._handleChangeForUnits}
          value={this.state._unitsValue}
          error={isError}
          disabled={isDisabled}
          required={required}
          ariaLabel={"wageFrequency"}
        />
      </div>
    );
  }

  _buildClear(ariaLabels, name, clearLabel, isError, isDisabled) {
    const clearId = this.nextUniqueId();
    return (
      <ul className="dta-units__container full-width">
        <OptionItem
          {...RADIO_CLASSES}
          name={name}
          key={clearId}
          aria-labelledby={ariaLabels}
          value=""
          text={clearLabel}
          type="radio"
          onChange={this._handleChangeForClear}
          checked={this.state._isCleared}
          disabled={isDisabled}
          error={isError}
          className={`dta-units__item ${RADIO_CLASSES.optionClass}`}
          controlClass={`dta-radio__control--without-indicator ${RADIO_CLASSES.controlClass}`}
        />
      </ul>
    );
  }

  // Events
  // ------

  _handleChangeForAmount = (newAmount, i) => {
    if (this.props.disabled) {
      return;
    }
    let amount = [ ...this.state._amountValue ];
    amount[i] = newAmount;
    this.setState({ _isCleared: TextProps.VALUE_FALSE, _amountValue: amount });
    this._handleChangeForAmountHelper({
      code: this.state.isNew ? "new" : "changed",
      amount: amount,
      unit: this.state._unitsValue,
      fullIncomeObj: this.state._fullIncomeObj,
    });
  };

  _handleChangeForAmountHelper(value) {
    tryCall(this.props.onChange, value);
  }

  _handleChangeForUnits = (newUnits) => {
    if (this.props.disabled) {
      return;
    }

    let amountCount = 1;
    let freqCode = getFrequencyCode(language)[newUnits];
    if (freqCode === "WEEK") {
      amountCount = 4;
    } else if (freqCode === "BIWEEK") {
      amountCount = 2;
    }

    let newAmount = [];
    for (let i = 0; i < amountCount; i++) {
      newAmount[0] = "";
    }
    this.setState({ _isCleared: TextProps.VALUE_FALSE, _unitsValue: newUnits });
    tryCall(this.props.onChange, {
      code: this.state.isNew ? "new" : "changed",
      amount: newAmount,
      unit: newUnits,
      fullIncomeObj: this.state._fullIncomeObj,
    });
  };

  _handleChangeForClear = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({
      _isCleared: TextProps.VALUE_TRUE,
      _amountValue: "",
      _unitsValue: "",
    });
    // do not set to `null` or `undefined` or this controller component will become uncontrolled
    tryCall(this.props.onChange, "", "");
  };
}

export default UnEarnedIncome;
