import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { addStringIf } from "../../components/utilities/controls";
import * as TextProps from "../../../utils/constants/text";

/*
  ListItem
  --------

  # Purpose:
  Represents an item in a `List`

  # Props:
  className: Optional, string classes for this `ListItem`
  ignore: Optional, boolean indicating whether or not this item should be ignored when the parent `List`
          is calculating the number of items currentloy present
  error: Optional, boolean indicating whether or not this item should display its error state
  disabled: Optional,  boolean indicating whether or not this item should be disabled. The disabled
            state takes precedence over the error state

  # Example:
  <ListItem key={item[SharedProps.ID]} className={className}>
    <DocumentContents {...props} />
  </ListItem>
 */

class ListItem extends Component {
  static propTypes = {
    // container
    className: PropTypes.string,
    // items
    ignore: PropTypes.bool,
    // states
    error: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    // container
    className: "",
    // items
    ignore: TextProps.VALUE_FALSE,
    // states
    error: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  };

  render() {
    const { className, error, disabled } = this.props;
    let documentType =
      this.props.children.props !== undefined
        ? this.props.children.props.type
        : "";
    let documentTypeCode =
      this.props.children.props !== undefined
        ? this.props.children.props.typeCode
        : "";
    let notificationId =
      this.props.children.props !== undefined
        ? this.props.children.props.id
        : 0;

    if (
      notificationId !== undefined &&
      (documentType !== undefined || documentTypeCode !== undefined)
    ) {
      return (
        <li
          className={addStringIf(
            disabled,
            addStringIf(
              error,
              addStringIf(className, "dta-list-item"),
              "dta-list-item--error"
            ),
            "dta-list-item--disabled"
          )}
          aria-invalid={
            error ? TextProps.VALUE_STR_TRUE : TextProps.VALUE_STR_FALSE
          }
          aria-disabled={
            disabled ? TextProps.VALUE_STR_TRUE : TextProps.VALUE_STR_FALSE
          }
        >
          {this.props.children}
        </li>
      );
    }
    return null;
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
