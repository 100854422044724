import React, { Component } from "react";
import PropTypes from "prop-types";
import Collapse from "react-collapse";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import DOMPurify from "dompurify";

import { addStringIf, isCallable, tryCall } from "./utilities/controls";
import Icon from "./dtaIcon";
import { CommonMultiLanguage } from "../appText/commonMultiLanguage";
import * as TextProps from "../constants/text";
import { langOptSel } from "../constants/constants";

let language = localStorage.getItem("selectedLanguage");

if (language === null || language === langOptSel) {
  language = "en-US";
}

class HelpTip extends Component {
  static propTypes = {
    textBefore: PropTypes.string,
    textAfter: PropTypes.string,
    triggerText: PropTypes.string.isRequired,
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
      .isRequired,
    labelId: PropTypes.string,
    className: PropTypes.string,
    bypassMobileStyle: PropTypes.bool,
    hasMarkup: PropTypes.bool,
  };
  static defaultProps = {
    labelId: "",
    className: "",
    hasMarkup: TextProps.VALUE_FALSE,
  };

  constructor() {
    super();

    this.state = {
      isOpen: TextProps.VALUE_FALSE,
    };

    enableUniqueIds(this);
  }

  componentWillMount() {
    this.setState({ isOpen: TextProps.VALUE_FALSE });
  }

  componentWillReceiveProps(nextProps) {
    const isAnyDifferent = _.some(
      ["textBefore", "textAfter", "triggerText", "helpText"],
      (property) => {
        return this.props[property] !== nextProps[property];
      }
    );
    if (isAnyDifferent) {
      this.setState({ isOpen: TextProps.VALUE_FALSE });
    }
  }

  toggleOpen = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleOpenForKeyUp = (e) => {
    if (e.which === 13 || e.which === 32) {
      // 13 is enter, 32 is spacebar
      e.preventDefault();
      e.stopPropagation();
      this.toggleOpen(e);
    }
  };

  render() {
    const { hasMarkup, className } = this.props;
    let baseClass = addStringIf(className, "help-tip");

    if (!this.props.bypassMobileStyle && !this.props.hasRenderHtml) {
      baseClass += ' mobile-tray';
    }

    return (
      <span className={baseClass}>
        <span className="sr-only" id={this.nextUniqueId()}>
          {CommonMultiLanguage(language).defineTerm}
        </span>
        <span className="help-tip__label" id={this.props.labelId}>
          {this._buildDangerouslyIfHasMarkup(this.props.textBefore, hasMarkup)}
          {/* can't use a button b/c disrupts fieldsets when used inside of legend*/}
          <div
            className={`help-tip__trigger ${this.state.isOpen ? "active" : ""}`}
            onClick={this.toggleOpen}
            onKeyUp={this.toggleOpenForKeyUp}
            tabIndex="0"
            role="button"
            aria-describedby={this.lastUniqueId()}
            aria-expanded={this.state.isOpen}
            aria-label="household member name"
          >
            {this._buildDangerouslyIfHasMarkup(
              this.props.triggerText,
              hasMarkup
            )}
          </div>
          {this._buildDangerouslyIfHasMarkup(this.props.textAfter, hasMarkup)}
        </span>
        <Collapse isOpened={this.state.isOpen} className="help-tip__container">
          <div className="help-tip__content">
            <div
              tabIndex="0"
              role="button"
              className="dta-button dta-button--secondary dta-button--block help-tip__close-mobile"
              onClick={this.toggleOpen}
              onKeyUp={this.toggleOpenForKeyUp}
            >
              Close
            </div>
            <div
              tabIndex="0"
              role="button"
              className="help-tip__close-desktop"
              onClick={this.toggleOpen}
              onKeyUp={this.toggleOpenForKeyUp}
            >
              <Icon name="close" label="Close help tip" />
            </div>
            <span className="help-tip__text" aria-live="polite">
              {isCallable(this.props.helpText) ? tryCall(this.props.helpText) 
              : this._buildDangerouslyIfHasMarkup(
                  this.props.helpText,
                  hasMarkup
              )}
            </span>
          </div>
        </Collapse>
      </span>
    );
  }

  // Rendering
  // ---------

  _buildDangerouslyIfHasMarkup(text, hasMarkup) {
    if(hasMarkup) {
      const sanitizedHtmlContent = DOMPurify.sanitize(text);
      return <span dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
    } else {
      return text;
    }
  }
}

export default HelpTip;
