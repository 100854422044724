import React, { Component } from 'react'
import DTASelect from "../../../../utils/components/dtaSelect";
import DTATextArea from '../../../../utils/components/dtaTextArea';
import DTADate from '../../../../utils/components/dtaDate';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { consumerConst } from "../../../../pages/consumer/consumerText";
import DTAInput from '../../../../utils/components/dtaInput';
import {
  customerConcernsPostCall
} from "../../../../redux/concernsAppeals/concernsAppealsAction";
import Loading from '../../../../utils/components/loading';



const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class CustomerConcernsContainer extends Component {
  constructor(props) {
    super(...arguments);
  }
  render() {
    return (
      <div>  
          <div className='pad-all--double'>
            <div className="pure-u-1">
              <div>
                <span style={{ display: 'flex', fontSize: '2rem', marginBottom: '10px'}}><b>{consumerConst(selectedLanguage).concernsHeaderText}</b></span>
              </div>
              <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px' }}>
              </div>
            </div>
            <form id="customer-concerns">
            {this.props.concerns.showField && (
              <div>
                <div className="pure-u-1" style={{ marginTop: '40px', marginBottom: '35px', fontSize: '20px' }}>
                  <span ><b>
                    {consumerConst(selectedLanguage).concernsclientText}
                  </b>
                  </span>
                </div>
                <div className="pure-u-1">
                  <label for="concerns-name" className="dta-form__label">{consumerConst(selectedLanguage).concernsclientNameLabel}</label>
                  <DTAInput
                    className="dta-form__control"
                    id="concerns-name"
                    maxLength={40}
                    type="specialTextType"
                    value={this.props.concerns.anonymusUserName}
                    disabled
                  />
                </div>
                <div className="pure-u-1">
                  <label for="concerns-phone-number" className="dta-form__label">{consumerConst(selectedLanguage).concernsPhoneNumberLabel}</label>
                  {this.props.concerns.anonymusUserPhoneNumberError && (
                <div>
                  <div className="peat-case-error-msg">
                    <span className="dta-form__error-message">{consumerConst(selectedLanguage).concernsManagerPhoneNumberError}</span>
                  </div>
                </div>
              )}
                  <DTAInput
                    className="dta-form__control"
                    id="concerns-phone-number"
                    type="tel"
                    value={this.props.concerns.anonymusUserPhoneNumber}
                    error={this.props.concerns.anonymusUserPhoneNumberError}
                    onChange={this.props.handleConcerns.bind(this, 'anonymusUserPhoneNumber')}
                  />
                </div>
                <div className="pure-u-1">
                  <label className="dta-form__label" for="concerns-email">{consumerConst(selectedLanguage).concernsEmailLabel}</label>
                  {this.props.concerns.anonymusUserEmailError && (
                <div>
                  <div className="peat-case-error-msg">
                    <span className="dta-form__error-message">
                      {consumerConst(selectedLanguage).concernsEmailError}
                    </span>
                  </div>
                </div>
              )}
                  <DTAInput
                    className="dta-form__control"
                    id="concerns-email"
                    type="email"
                    maxLength={350}
                    value={this.props.concerns.anonymusUserEmail}
                    error={this.props.concerns.anonymusUserEmailError}
                    onChange={this.props.handleConcerns.bind(this, 'anonymusUserEmail')}
                  />
                </div>
                <div className="pure-u-1">
                  <label className="dta-form__label" for="concerns-clientId">{consumerConst(selectedLanguage).concernsClientIdLabel}</label>
                  <DTAInput
                    className="dta-form__control"
                    id="concerns-clientId"
                    value={this.props.concerns.anonymusClientId}
                    disabled
                  />
                </div>
              </div>
            )}
            <div className="pure-u-1" style={{marginTop:'1.8rem'}}>
                <label className="dta-form__label" for="concernsType">{consumerConst(selectedLanguage).concernTypeLabel}</label>
                {this.props.concerns.concernsTypeError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {consumerConst(selectedLanguage).concernTypeErrorText}
                      </span>
                    </div>
                  </div>
                )}
                <DTASelect
                  name={"concernsType"}
                  className="dta-form__control"
                  id={"concernsType"}
                  options={consumerConst(selectedLanguage).concernsTypeOptions}
                  error={this.props.concerns.concernsTypeError}
                  value={this.props.concerns.concernType}
                  onChange={this.props.handleConcerns.bind(this, 'type')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="concernsReason">{consumerConst(selectedLanguage).concernsReasonLabel}</label>
                {this.props.concerns.concernsReasonError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {consumerConst(selectedLanguage).concernsReasonErrorText}
                      </span>
                    </div>
                  </div>
                )}
                <DTASelect
                  name={"concernsReason"}
                  className="dta-form__control"
                  id={"concernsReason"}
                  options={Object.values(consumerConst(selectedLanguage).concernsReasonOptions).sort()}
                  error={this.props.concerns.concernsReasonError}
                  value={this.props.concerns.concernsReason}
                  onChange={this.props.handleConcerns.bind(this, 'reason')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="concernsProgram">{consumerConst(selectedLanguage).concernsProgramLabel}</label>
                {this.props.concerns.concernsProgramError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {consumerConst(selectedLanguage).concernsProgramErrorText}
                      </span>
                    </div>
                  </div>
                )}
                <DTASelect
                  name={"concernsProgram"}
                  className="dta-form__control"
                  id={"concernsProgram"}
                  options={consumerConst(selectedLanguage).concernsProgramOptions}
                  error={this.props.concerns.concernsProgramError}
                  value={this.props.concerns.concernsProgram}
                  onChange={this.props.handleConcerns.bind(this, 'program')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label">{consumerConst(selectedLanguage).concernsDateLabel}</label>
                {this.props.concerns.dateError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">{consumerConst(selectedLanguage).concernsDateError}</span>
                    </div>
                  </div>
                )}
                <DTADate
                  {
                  ...this
                    .props
                  }
                  name="concernsDate"
                  value={this.props.concerns.allegedIncidentDate}
                  error={this.props.concerns.dateError}
                  onChange={this.props.handleConcerns.bind(this, 'currentDate')}
                />
              </div>
              <div className="pure-u-1" style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }} >
                <input className='checkbox-anonymous' id="anonymous-label" type="checkbox" value={this.props.concerns.checked} onChange={this.props.handleChecked} />
                <label style={{ marginLeft: '10px' }} for="anonymous-label">{consumerConst(selectedLanguage).concernsAnonymousLabel}</label>

              </div>
           
              <div className="pure-u-1" style={{ marginTop: '40px', marginBottom: '35px', fontSize: '20px' }}>
                <span ><b>
                  {consumerConst(selectedLanguage).concernsubHeaderText}
                </b>
                </span>
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="auth-concerns-name">{consumerConst(selectedLanguage).concernsclientNameLabel}</label>
                <DTAInput
                  className="dta-form__control"
                  id="auth-concerns-name"
                  maxLength={40}
                  type="specialTextType"
                  value={this.props.concerns.authRepName}
                  onChange={this.props.handleConcerns.bind(this, 'authName')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="auth-concerns-phone-number">{consumerConst(selectedLanguage).concernsPhoneNumberLabel}</label>
                {this.props.concerns.authRepPhoneNumberError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">{consumerConst(selectedLanguage).concernsManagerPhoneNumberError}</span>
                    </div>
                  </div>
                )}
                <DTAInput
                  className="dta-form__control"
                  id="auth-concerns-phone-number"
                  type="tel"
                  value={this.props.concerns.authRepPhoneNumber}
                  error={this.props.concerns.authRepPhoneNumberError}
                  onChange={this.props.handleConcerns.bind(this, 'authPhoneNumber')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="auth-concerns-email">{consumerConst(selectedLanguage).concernsEmailLabel}</label>
                {this.props.concerns.authRepEmailError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {consumerConst(selectedLanguage).concernsEmailError}
                      </span>
                    </div>
                  </div>
                )}
                <DTAInput
                  className="dta-form__control"
                  id="auth-concerns-email"
                  type="email"
                  maxLength={350}
                  value={this.props.concerns.authRepEmail}
                  error={this.props.concerns.authRepEmailError}
                  onChange={this.props.handleConcerns.bind(this, 'authEmail')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="concernOffice">{consumerConst(selectedLanguage).concernsOfficeLabel}</label>
                <DTASelect
                  name={"concernOffice"}
                  className="dta-form__control"
                  id={"concernOffice"}
                  options={consumerConst(selectedLanguage).concernsLocationOptions}
                  value={this.props.concerns.OfficeLocation}
                  onChange={this.props.handleConcerns.bind(this, 'location')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="concerns-case-manager">{consumerConst(selectedLanguage).concernsManagerLabel}</label>
                <DTAInput
                  className="dta-form__control"
                  id="concerns-case-manager"
                  type="specialTextType"
                  maxLength={350}
                  value={this.props.concerns.ManagerName}
                  onChange={this.props.handleConcerns.bind(this, 'caseManager')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="concerns-manager-phone-number">{consumerConst(selectedLanguage).concernsManagerPhoneNumberLabel}</label>
                {this.props.concerns.ManagerPhoneNumberError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {consumerConst(selectedLanguage).concernsManagerPhoneNumberError}
                      </span>
                    </div>
                  </div>
                )}
                <DTAInput
                  className="dta-form__control"
                  id="concerns-manager-phone-number"
                  type="tel"
                  value={this.props.concerns.ManagerPhoneNumber}
                  error={this.props.concerns.ManagerPhoneNumberError}
                  onChange={this.props.handleConcerns.bind(this,'caseManagerPhoneNumber')}
                />
              </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="comments">{consumerConst(selectedLanguage).concernsCommentsLabel}</label>
                <DTATextArea
                  className="dta-form__control"
                  placeholder={consumerConst(selectedLanguage).concernsCommentsPlaceHolder}
                  maxLength={4500}
                  id="comments"
                  type="alpha-text"
                  value={this.props.concerns.comments}
                  onChange={this.props.handleConcerns.bind(this, 'comments')}
                />
              </div>
            </form>
          </div>  
      </div>

    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      customerConcernsPostCall: customerConcernsPostCall
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerConcernsContainer);