/*
  List item types
  ---------------

  The following are types of items that can go in lists. Below are
  fields common to all of the types. For additional type-specific required
  or optional fields, see the comment above that type.

  # Required fields:
    TYPE: type of the list item. Must be one of the types specified in this file
    ID: id of that list item, should be globally unique amongst items of that type
 */

// BENEFIT
//
// # Required fields:
//   AMOUNT: number (integer or float) monetary amount received
//   DATE: Date object that timestamps this benefit
//
export const BENEFIT = "benefit";

// DOCUMENT
//
// # Required fields
//   NAME: string name of the document
//   FOR: string name or identifier of the person the document pertains to
//   STATUS: status of the document, one of STATUS_PROCESSING, STATUS_ACCEPTED, STATUS_DENIED
//
// # Optional fields
//   RECEIVED: Date object that indicates when this document was received
//   WHEN_STATUS_CHANGED: Date object that indicates when this document's status changed
//
export const DOCUMENT = "document";

// NOTIFICATION
//
// # Required fields
//   NAME: string name of the notification
//   URL: fully qualified URL to pointing to this document for viewing
//   STATUS: status of the notification, one of STATUS_UNREAD or STATUS_READ
//   DATE: Date object that timestamps this notification
//
export const NOTIFICATION = "notification";

// EVENT
//
// # Required fields
//   NAME: name of the event
//   DATE: Date object that captures date and time of the event
//   LOCATION: string name of the location
//   STATUS: status of the event, one of STATUS_CLOSE or STATUS_NOT_CLOSE
//   URL: `webcal` url to the event
//
export const EVENT = "event";

// DEADLINE
//
// # Required fields
//   NAME: name of the item that is due
//   DATE: Date object that indicates when this is due
//   DETAILS: string details about what is due
//   STATUS: status of the deadline,one of STATUS_URGENT or STATUS_NOT_URGENT
//   URL: `webcal` url to the deadline
//
export const DEADLINE = "deadline";

// PROVIDER_CLIENT
//
// # Required fields:
//   [SharedProps.USER]: the user object associated with the client
//   [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: the date the Provider's account
//      access will expire for this Client.
//   [BenefitProps.STATUS]: the status of the client account, as one of main status types.
//   [BenefitProps.STATUS_MESSAGE]: the brief main status message to display on the
//      right of the list item.
//   onRequestAccess: handler for when user clicks to renew access request for given
//     client. Takes user object of client as argument.
//
export const PROVIDER_CLIENT = "providerClient";

// SEARCHED_CLIENT
//
// # Required fields:
//   [SharedProps.USER]: the user object associated with the searched client
//   onRequestAccess: handler for when user clicks to request access for given
//     client. Takes user object of client as argument.
export const SEARCHED_CLIENT = "searchedClient";
