import React, { Component } from 'react'

export class concernAppealsCancel extends Component {
  render() {
    return (
      <div>
        
            <div  className='pad-all--double'>
                <h1>Are You Sure?</h1>
                <p style={{wordSpacing:"3px"}}>Click on "Cancel" to remove all information on your <br/>appeal. Click on "Go Back" to go back to the Appeal<br/>screen.</p>
            </div>

        
    
      </div>
    )
  }
}

export default concernAppealsCancel
