import React from 'react';
import { recertificationPageLabels } from './recertificationText';
import * as languageConstants from "../../utils/constants/constants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as formatters from "../../utils/components/utilities/formatters";
import { interimReportPageLabels } from '../recertInterimReport/recertInterimReportText';
const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
export const HouseholdSummaryData = (householdInformation) => {
    const returnOtherFlag = () => {
        if (householdInformation.otherInfoFlg === "N") {
            return "No"
        }
        if (householdInformation.otherInfoFlg === "Y") {
            return "Yes"
        }
    }
    const returnhurtFlg = () => {
        if (householdInformation.hurtFlg === "N") {
            return "No"
        }
        if (householdInformation.hurtFlg === "Y") {
            return "Yes"
        }
    }
    const returnhurtingNowFlg = () => {
        if (householdInformation.hurtingNowFlg === "N") {
            return "No"
        }
        if (householdInformation.hurtingNowFlg === "Y") {
            return "Yes"
        }
    }
    const returnafraidOfFlg = () => {
        if (householdInformation.afraidOfFlg === "N") {
            return "No"
        }
        if (householdInformation.afraidOfFlg === "Y") {
            return "Yes"
        }
    }
    const returnIfAnswerFlg = () => {
        if (householdInformation.domesticRefFlg === "N") {
            return "No"
        }
        if (householdInformation.domesticRefFlg === "Y") {
            return "Yes"
        }
    }
    const returnSelectedAnswer = () => {
        if (householdInformation.registerVoteFlg !== "N" && householdInformation.registerVoteFlg !== "") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[0]
        }
        if (householdInformation.changeAddressFlg !== "N" && householdInformation.changeAddressFlg !== "") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[1]
        }
        if (householdInformation.noRegisterToVoteFlg !== "N" && householdInformation.noRegisterToVoteFlg !== "") {
            return recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart,
            ).householdOptions[2]
        }
    }
    return (
        <React.Fragment>
            {householdInformation && (
                <React.Fragment style={{ marginLeft: "12px" }}>
                    <ul className='description-list__itemContainer'>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                <h4 className='description-list__title' style={{ marginLeft: "10px", margin: 0 }}>
                                    {interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfheadingtwo}
                                </h4>
                            </div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfquestionfour
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnOtherFlag()}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfpleaseexplain
                                }
                            </div>
                            <div className='description-list__itemContent'>{householdInformation.comments}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                <h4 className='description-list__title' style={{ marginLeft: "10px", margin: 0 }}>
                                    {interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfheadingthree}
                                </h4>
                            </div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).voterQuestion
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnSelectedAnswer()}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                <h4 className='description-list__title' style={{ marginLeft: "10px", margin: 0 }}>
                                    {interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfheadingone}
                                </h4>
                            </div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfquestionone
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnhurtFlg()}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfquestiontwo
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnhurtingNowFlg()}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).tanfquestionthree
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnafraidOfFlg()}</div>
                        </li>
                        <li className='description-list__item' >
                            <div className='description-list__itemContent'>
                                {
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).ifansweryes
                                }
                            </div>
                            <div className='description-list__itemContent'>{returnIfAnswerFlg()}</div>
                        </li>
                    </ul>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}