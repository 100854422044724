import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";

import DTAInput from "./dtaInput";
import { addStringIf, tryCall } from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { INPUT_TYPE_MONEY } from "../../utils/constants/controls";
import * as TextProps from "../../utils/constants/text";

class NoFreqUnits extends Component {
  static propTypes = {
    ...CommonTypes,
    // required
    options: PropTypes.array.isRequired,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // text display
    inBetween: PropTypes.string,
    // states
    money: PropTypes.bool,
    // one-way data bindings
    value: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string,
    }),
    // clear
    clearLabel: PropTypes.string,
    showClear: PropTypes.bool,
  };
  static defaultProps = {
    ...CommonDefaults,
    // text display
    inBetween: "per",
    // states
    money: TextProps.VALUE_FALSE,
    // one-way data bindings
    value: { amount: "", unit: "" },
    // clear
    clearLabel: "None",
    showClear: TextProps.VALUE_FALSE,
    maxLength: PropTypes.number,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    this.state = {
      _isCleared: TextProps.VALUE_FALSE,
      _amountValue: props.value.amount ? props.value.amount : "",
      _unitsValue: props.value.unit ? props.value.unit : "",
    };
    this._handleChangeForAmountHelper = _.debounce(
      this._handleChangeForAmountHelper,
      500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        _isCleared:
          !nextProps.value || !nextProps.value.amount || !nextProps.value.unit,
        _amountValue: nextProps.value.amount,
        _unitsValue: nextProps.value.unit,
      });
    }
  }

  render() {
    const { name, money, error, disabled, required, inBetween, maxLength } =
        this.props,
      inputId = this.props.id,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={addStringIf(this.props.className, "dta-units")}>
        <div className="dta-units__container">
          {this._buildAmount(
            name,
            inputId,
            ariaLabels,
            money,
            error,
            disabled,
            required,
            maxLength
          )}
          {this._buildInBetween(inBetween, error, disabled)}
        </div>
      </div>
    );
  }

  // Rendering
  // ---------

  _buildAmount(name, id, ariaLabels, isMoney, isError, isDisabled, required) {
    return (
      <div className="dta-units__control">
        <DTAInput
          name={name}
          type={isMoney ? INPUT_TYPE_MONEY : "number"}
          className="dta-units__item"
          id={id}
          blocks={[9]}
          numericOnly={TextProps.VALUE_TRUE}
          aria-labelledby={ariaLabels}
          onChange={this._handleChangeForAmount}
          value={this.state._amountValue}
          error={isError}
          disabled={isDisabled}
          required={required}
          maxLength={12}
        />
      </div>
    );
  }

  _buildInBetween(inBetween, isError, isDisabled) {
    return (
      <div
        aria-hidden={TextProps.VALUE_STR_TRUE}
        className={addStringIf(
          isDisabled,
          addStringIf(isError, "dta-units__label", "dta-units__label--error"),
          "dta-units__label--disabled"
        )}
      >
        {inBetween}
      </div>
    );
  }
  // Events
  // ------

  _handleChangeForAmount = (newAmount) => {
    if (this.props.disabled) {
      return;
    }
    this.setState({
      _isCleared: TextProps.VALUE_FALSE,
      _amountValue: newAmount,
    });
    this._handleChangeForAmountHelper({
      amount: newAmount,
      unit: this.state._unitsValue,
    });
  };

  _handleChangeForAmountHelper(value) {
    tryCall(this.props.onChange, value);
  }
}

export default NoFreqUnits;
