import axios from "axios";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  onPasswordChange: "CHANGE_PASSWORD_SUCCESS",
  serviceFailedAction: "FORGOT_FROM_SERVICE_FAIL",
};

//Common Fetch Support Functions
export function onPasswordChange(passwordData) {
  return (dispatch) => {
    const HttpOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          this.props.consumer.oauthToken.token_type +
          " " +
          this.props.consumer.oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(
        config.TigerURL + "/security/changePassword",
        passwordData,
        HttpOptions
      )
      .then(function (response) {
        dispatch(onPasswordChangeSuccess(response.data.body));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onPasswordChangeSuccess(data) {
  return {
    type: actionTypes.onPasswordChange,
    payload: data,
  };
}

//Common Action for Service Fail
function serviceFailedAction(response) {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
