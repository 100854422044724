//Import Modules
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import axe from "react-axe";
import axios from "axios";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import SnapReducers from "./rootReducer";
import { getAudit } from "../utils/constants/audit";
import * as routeProps from "../utils/constants/routesConstants";
import * as TextProps from "../utils/constants/text";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config/appConfig";

//Config
const config = require("../utils/constants/appConfigConstants");

//For Logger
const logger = createLogger();

//For Router
export const history = createBrowserHistory();
const router = routerMiddleware(history);

//Store Configuration
const initialState = {};
const middleware = [thunk, logger, router];
const enhancers = [];

// log accessibility errors to the console. works in Chrome only
// Opted out of limited support for Safari and Firefox because
// axe results in 'too much recursion' errors
if (process.env.NODE_ENV === "development") {
  if (window.chrome) {
    axe(React, ReactDOM, 1000);
  }
  const devToolsExtension = window.devToolsExtension;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
//Store Integration
export const snapStore = createStore(
  SnapReducers,
  initialState,
  composedEnhancers
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // handle the response error
    const originalRequest = error.config;
    // Invalid Login credentials or duplicate account creation
    if (
      (error.response && error.response.status == 401) &&
      (originalRequest.url.includes("oauth/token?grant_type=password") ||
        originalRequest.url.includes("apipublic/security/register"))
    ) {
      return Promise.reject(error);
    }
    // Invalid Refresh token
    if (
      (error.response && error.response.status === 401) &&
      originalRequest.url.includes("oauth/token?grant_type=refresh")
    ) {
      history.push(routeProps.HOME_PATH);
      return Promise.reject(error);
    }
    if (
      (error.response && error.response.status === 401) &&
      !originalRequest.retry
    ) {
      originalRequest.retry = TextProps.VALUE_TRUE;
      return getRefreshToken()
        .then(() => {
          const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
          const configuration = error.config;
          configuration.headers["Authorization"] =
            oauthToken.token_type + " " + oauthToken.access_token;
          return new Promise((resolve, reject) => {
            axios
              .request(configuration)
              .then((response) => {
                resolve(response);
              })
              .catch((resErr) => {
                reject(resErr);
              });
          });
        })
        .catch((err) => {
          Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

function getRefreshToken() {
  return new Promise(async (resolve, reject) => {
    if (sessionStorage.getItem("oauthToken") != null) {
      try {
        const msalInstance = new PublicClientApplication(msalConfig);

        var username = window.sessionStorage.getItem("user_name");
        var currentAccount = msalInstance.getAccountByUsername(username);
        var silentRequest = {
          scopes: [
            "openid",
            "https://aldhrb2cuat.onmicrosoft.us/TodoList/ToDoList.ReadWrite", 
            "https://aldhrb2cuat.onmicrosoft.us/TodoList/ToDoList.Read"
          ],
          account: currentAccount,
          forceRefresh: false,
        };

        const tokenResponse = await msalInstance
          .acquireTokenSilent(silentRequest)
          .catch(async (error) => {
            if (error) {
              // fallback to interaction when silent call fails
              window.sessionStorage.setItem("is_Logout", TextProps.VALUE_TRUE);
              msalInstance.logoutRedirect();
            }
          });
        const oauth = {
          token_type: "Bearer",
          access_token: tokenResponse.idToken,
        };
        window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));

        // const oauthToken = JSON.parse(sessionStorage.getItem('oauthToken'));
        // const audit = getAudit();
        //   let url = config.TigerURL + "/oauth/token?grant_type=refresh_token&refresh_token=" + oauthToken.refresh_token;
        //   axios({
        //     method: "post",
        //     url: url,
        //     withCredentials: TextProps.VALUE_TRUE,
        //     headers: {
        //       'accept': 'application/json',
        //       'Content-Type': 'application/json',
        //       'audit': JSON.stringify(audit)
        //     },
        //     mode: 'no-cors',
        //     'Access-Control-Allow-Origin': config.allowed_domain,
        //     auth: {
        //       username: config.username,
        //       password: config.password
        //     }
        //   }).then(response => {
        //     const oauthTokenRes = response.data;
        //     sessionStorage.setItem("oauthToken", JSON.stringify(oauthTokenRes));
        //     resolve(response);
        //   }).catch(error => {
        //     console.log('Refresh Token Error', error);
        //   });
      } catch (err) {}
    }
  });
}
