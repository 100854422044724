export function recertReducer(state = {}, action) {
  let recertType = sessionStorage.getItem("recertType");
  switch (action.type) {
    case "GET_RECERT_DATA_FULFILLED": {
      let recertState = Object.assign({}, state);
      recertState.responseObject = action.payload;
      let responseObject = action.payload;
      recertState.webAppTypeCode = responseObject.webAppTypeCode;
      recertState.createdTime = responseObject.createdTime;
      recertState.recertId = recertType === "IR" ? responseObject.interimID : responseObject.recertId;
      if (responseObject.status === "DRAFT") {
        recertState.recertId = recertType === "IR" ? responseObject.interimID: responseObject.recertId;
        recertState.additionalProperties = responseObject.additionalProperties;
      } else {
        recertState.recertId = 0;
      }
      return recertState;
    }

    case "RECERT_PAGES_FULLFILLED":
    case "RECERT_STATE_REFRESH": {
      let recertState = Object.assign({}, state);
      recertState.sections = action.payload;
      return recertState;
    }

    case "RECERT_APPEND_INCOME_DATA": {
      const recertState = Object.assign({}, state);
      recertState.sections.filter(sub => sub.id === "income-summary")[0] = action.payload;
      return recertState;
    }
    case "RECERT_APPEND_EXPENSE_DATA": {
      const recertState = Object.assign({}, state);
      if(recertType === "IR"){
        recertState.sections[action.index] = action.payload;
      } else {
      recertState.sections.filter(sub => sub.id === "expenses")[0].subsections[action.index] = action.payload;
      }
      return recertState;
    }
    case "SET_OBLIGATION_CHILD_SUPPORT_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.obligationChildSupportChanged = action.payload;
      return recertState;
    }
    case "SET_COURT_ORDERED_CHILD_SUPPORT_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.courtOrderedChildSupportChanged = action.payload;
      return recertState;
    }
    case "IR_ADD_DATA_CHANGE_STATUS_EXP": {
      const recertState = Object.assign({}, state);
      if(recertType === "IR"){
        recertState.sections[action.index].selectionMade = action.payload;
      } else {
      recertState.sections.filter(sub => sub.id === "expenses")[0].subsections[action.index].selectionMade = action.payload;
      }
      return recertState;    
    }
    case "NETWORK_FAILED_LOAD_DATA": {
      return state;
    }

    case "FILL_INPUT_VALUES_STATE": {
      return state;
    }
    case "APPEND_APPLY_ANSER": {
      const recertState = Object.assign({}, state);
      recertState.AnswerObject = action.payload[0];
      recertState.dalReference = action.payload[1];
      return recertState;
    }
    case "DOWNLOAD_PDF_APPLY_APPLICATION": {
      return state;
    }

    case "UPDATE_BACK_RESPONSE_TO_SECTION": {
      let stateObj = Object.assign({}, state);

      if (action.payload.data.questionName !== undefined) {
        stateObj.sections[0].questionName = action.payload.data.questionName;
        stateObj.sections[0].rowId = action.payload.data.rowId;
      }
      return stateObj;
    }

    case "UPDATE_WEB_APPLICATION_ID": {
      let stateObj = Object.assign({}, state);
      if (action.payload.data.webAppId !== undefined) {
        stateObj.sections[0].webAppId = action.payload.data.webAppId;
      }
      return stateObj;
    }

    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      return applyInit;
    }

    case "SET_CONTACT_INFO": {
      const recertState = Object.assign({}, state);
      recertState.contactInfoScreen = action.payload;
      return recertState;
    }

    case "SET_HOUSEHOLD_MEMBERS": {
      const recertState = Object.assign({}, state);
      recertState.householdMembers = action.payload;
      return recertState;
    }
    case "SET_SHELTER_DEFAULT_QUESTIONS":{
      const recertState = Object.assign({}, state);
      recertState.shelterDefaultQuestions = action.payload;
      return recertState;
    }
    case "ADD_EXPITED_SNAP_TO_LIST": {
      const recertState = Object.assign({}, state);
      recertState.sections.filter(sec => sec.id === "expedited-snap")[0] = action.payload;
      return recertState;
    }

    case "ADD_STUDENT_TO_LIST": {
      const recertState = Object.assign({}, state);
      recertState.sections.filter(sec => sec.id === "students")[0] = action.payload;
      return recertState;
    }

    case "ADD_ADD_CONTACT_INFO_SECTION": {
      const recertState = Object.assign({}, state);
      recertState.sections[1].subsections = action.payload;
      return recertState;
    }

    case "ADD_AUTH_REP_SCREEN": {
      const recertState = Object.assign({}, state);
      recertState.sections[5].subsections = action.payload;
      return recertState
    }

    case "ADD_ADD_HOUSEHOLD_INFO_SECTION":
    case "RECERT_APPEND_HOME_HOLD_DATA": {
      const recertState = Object.assign({}, state);
      recertState.sections.filter(sec => sec.id === "my-household")[0].subsections = action.payload;
      return recertState;
    }

    case "SET_NON_CITIZEN_STATUS_CHANGE": {
      const recertState = Object.assign({}, state);
      recertState.noncitizenStatusUpdated = action.payload;
      return recertState;
    }

    case "ADD_DATA_CHANGE_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.sections[action.index].selectionMade = action.payload;
      return recertState;
    }

    case "SET_RECERT_RESPONSE": {
      const recertState = Object.assign({}, state);
      recertState.responseObject = action.payload;
      return recertState;
    }

    case "APPEND_STUDENT_DETAILS": {
      const recertState = Object.assign({}, state);
      recertState.sections.filter(sec => sec.id === "students")[0].subsections = [];
      recertState.sections.filter(sec => sec.id === "students")[0].subsections = action.payload;
      return recertState;
    }
    case "APPLY_ADDRESS_STRG": {
      const recertState = Object.assign({}, state);
      recertState.residentailAddressStored = action.payload[0];
      recertState.mailingAddressStored = action.payload[1];
      return recertState;
    }

    case "POST_RECERT_DATA_FULFILLED": {
      const recertState = Object.assign({}, state);
      recertState.responseNumbers = action.payload;
      return recertState;
    }
    case "IR_SET_CHILD_SUPPORT": {
      const recertState = Object.assign({}, state);
      recertState.childExpScreen = action.payload;
      return recertState;
    }
    case "SET_SHELTER_UTILITY_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.shelterUtilityConfirmationChanged = action.payload;
      return recertState;
    }
    case "SET_MEDICAL_EXPENSES_CHANGE_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.medicalExpensesSummaryChanged = action.payload;
      return recertState;
    }
    case "SET_SHELTER_DATA": {
      const recertState = Object.assign({}, state);
      recertState.shelterUtilityData = action.payload;
      return recertState;
    }
    case "APPEND_SHELTER_SUBSECTION_QUESTIONS_DATA": {
      const recertState = Object.assign({}, state);
      if(recertType === "IR"){
        recertState.sections.filter(sec => sec.id === "shelter-utility-summary")[0].subsections[0].subsections[0].questions = action.payload;
      } else {
      recertState.sections.filter(sub => sub.id === "expenses")[0].subsections[0].subsections[0].subsections[0].questions = action.payload;
      }
      return recertState;
    }
    case "SAVE_EXIT_DATA": {
      const recertState = Object.assign({}, state);
      recertState.updatedDate = action.payload.updatedDate;
      recertState.pendingHours = action.payload.pendingHours;
      recertState.saveExitShowModal = action.payload.saveExitShowModal;
      return recertState;
    }

    case "APPEND_RECERT_DATA_FOR_SUMMARY": {
      let recertSummary = Object.assign({}, state);
      recertSummary.summaryData = action.payload;
      return recertSummary;
    }
    //To set yes no selection status
    case "IR_RECERT_ADD_DATA_CHANGE_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.sections[action.index].selectionMade = action.payload;
      return recertState;
    }
    //Resources
    case "SET_HOUSEHOLD_RESOURCES_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdResourcesChanged = action.payload;
      return recertState;
    }
    //Resources Data changed
    case "SET_HOUSEHOLD_RESOURCES_STATUS_CHANGED_TO_TRUE": {
      const recertState = Object.assign({}, state);
      recertState.householdResourcesChangedToTrue = action.payload;
      return recertState;
    }
    //Earned income
    case "SET_GROSS_EARNED_INCOME_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.grossEarnedIncomeChanged = action.payload;
      return recertState;
    }

    case "SET_AUTH_REP_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.authRepChanged = action.payload;
      return recertState;
    }

    case "SET_AUTH_REP_PURCHASE_FOOD_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.ebtAuthRepFlg = action.payload;
      return recertState;
    }

    case "SET_AUTH_REP_ELIGIBILITY_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.snapAuthRepFlg = action.payload;
      return recertState;
    }

    case "SET_AUTH_REP_BODY": {
      const recertState = Object.assign({}, state);
      recertState.authorizedRepresentatives = action.payload;
      return recertState;
    }

    case "SET_TANF_BODY": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation = action.payload;
      return recertState;
    }

    case "SET_EARNED_INCOME_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.earnedIncomeChanged = action.payload;
      return recertState;
    }
    case "APPEND_SR_RESOURCES_DATA": {
      const resourcesState = Object.assign({}, state);
      resourcesState.sections.filter(sub => sub.id === "resources-summary")[0] = action.payload;
      return resourcesState;
    }
    case "APPEND_IR_INCOME_DATA": {
      const irIncomeState = Object.assign({}, state);
      irIncomeState.sections.filter(sub => sub.id === "income-summary")[0] = action.payload;
      return irIncomeState;
    }

    case "APPEND_UI_INCOME_DATA": {
      const otherIncomeState = Object.assign({}, state);
      otherIncomeState.sections.filter(sub => sub.id === "unearned-income-summary")[0] = action.payload;
      return otherIncomeState;
    }

    //Earned income
    case "SET_UNEARNED_INCOME_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.unearnedIncomeChanged = action.payload;
      return recertState;
    }

    case "SET_QUESTION_ONE_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.hurtFlg = action.payload;
      return recertState;
    }

    case "SET_QUESTION_TWO_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.hurtingNowFlg = action.payload;
      return recertState;
    }

    case "SET_QUESTION_THREE_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.afraidOfFlg = action.payload;
      return recertState;
    }

    case "SET_QUESTION_LAST_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.otherInfoFlg = action.payload;
      return recertState;
    }

    case "SET_IF_ANSWER_YES": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.domesticRefFlg = action.payload;
      return recertState;
    }

    case "SET_COMMENT_TEXT": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.comments = action.payload;
      return recertState;
    }

    case "SET_REGISTER_VOTE_FLG": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.registerVoteFlg = action.payload;
      return recertState;
    }

    case "SET_CHANGE_ADDRESS_FLG": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.changeAddressFlg = action.payload;
      return recertState;
    }
    case "SET_NO_REGISTER_VOTE_FLG": {
      const recertState = Object.assign({}, state);
      recertState.householdInformation.noRegisterToVoteFlg = action.payload;
      return recertState;
    }

    default:
      return state;
  }
}