export const ALL_STATES_COUNTY =
[
    {
        "code": "13165",
        "county": "Jenkins",
        "value": "Jenkins",
        "state": "GA"
    },
    {
        "code": "13167",
        "county": "Johnson",
        "value": "Johnson",
        "state": "GA"
    },
    {
        "code": "13169",
        "county": "Jones",
        "value": "Jones",
        "state": "GA"
    },
    {
        "code": "13171",
        "county": "Lamar",
        "value": "Lamar",
        "state": "GA"
    },
    {
        "code": "13173",
        "county": "Lanier",
        "value": "Lanier",
        "state": "GA"
    },
    {
        "code": "13175",
        "county": "Laurens",
        "value": "Laurens",
        "state": "GA"
    },
    {
        "code": "13177",
        "county": "Lee",
        "value": "Lee",
        "state": "GA"
    },
    {
        "code": "13179",
        "county": "Liberty",
        "value": "Liberty",
        "state": "GA"
    },
    {
        "code": "13181",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "GA"
    },
    {
        "code": "13183",
        "county": "Long",
        "value": "Long",
        "state": "GA"
    },
    {
        "code": "13185",
        "county": "Lowndes",
        "value": "Lowndes",
        "state": "GA"
    },
    {
        "code": "13187",
        "county": "Lumpkin",
        "value": "Lumpkin",
        "state": "GA"
    },
    {
        "code": "13189",
        "county": "McDuffie",
        "value": "McDuffie",
        "state": "GA"
    },
    {
        "code": "13191",
        "county": "McIntosh",
        "value": "McIntosh",
        "state": "GA"
    },
    {
        "code": "13193",
        "county": "Macon",
        "value": "Macon",
        "state": "GA"
    },
    {
        "code": "13195",
        "county": "Madison",
        "value": "Madison",
        "state": "GA"
    },
    {
        "code": "13197",
        "county": "Marion",
        "value": "Marion",
        "state": "GA"
    },
    {
        "code": "13199",
        "county": "Meriwether",
        "value": "Meriwether",
        "state": "GA"
    },
    {
        "code": "13201",
        "county": "Miller",
        "value": "Miller",
        "state": "GA"
    },
    {
        "code": "13205",
        "county": "Mitchell",
        "value": "Mitchell",
        "state": "GA"
    },
    {
        "code": "13207",
        "county": "Monroe",
        "value": "Monroe",
        "state": "GA"
    },
    {
        "code": "13209",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "GA"
    },
    {
        "code": "13211",
        "county": "Morgan",
        "value": "Morgan",
        "state": "GA"
    },
    {
        "code": "13213",
        "county": "Murray",
        "value": "Murray",
        "state": "GA"
    },
    {
        "code": "13215",
        "county": "Muscogee",
        "value": "Muscogee",
        "state": "GA"
    },
    {
        "code": "13217",
        "county": "Newton",
        "value": "Newton",
        "state": "GA"
    },
    {
        "code": "13219",
        "county": "Oconee",
        "value": "Oconee",
        "state": "GA"
    },
    {
        "code": "13221",
        "county": "Oglethorpe",
        "value": "Oglethorpe",
        "state": "GA"
    },
    {
        "code": "13223",
        "county": "Paulding",
        "value": "Paulding",
        "state": "GA"
    },
    {
        "code": "13225",
        "county": "Peach",
        "value": "Peach",
        "state": "GA"
    },
    {
        "code": "13227",
        "county": "Pickens",
        "value": "Pickens",
        "state": "GA"
    },
    {
        "code": "13229",
        "county": "Pierce",
        "value": "Pierce",
        "state": "GA"
    },
    {
        "code": "13231",
        "county": "Pike",
        "value": "Pike",
        "state": "GA"
    },
    {
        "code": "13233",
        "county": "Polk",
        "value": "Polk",
        "state": "GA"
    },
    {
        "code": "13235",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "GA"
    },
    {
        "code": "13237",
        "county": "Putnam",
        "value": "Putnam",
        "state": "GA"
    },
    {
        "code": "13239",
        "county": "Quitman",
        "value": "Quitman",
        "state": "GA"
    },
    {
        "code": "13241",
        "county": "Rabun",
        "value": "Rabun",
        "state": "GA"
    },
    {
        "code": "13243",
        "county": "Randolph",
        "value": "Randolph",
        "state": "GA"
    },
    {
        "code": "13245",
        "county": "Richmond",
        "value": "Richmond",
        "state": "GA"
    },
    {
        "code": "13247",
        "county": "Rockdale",
        "value": "Rockdale",
        "state": "GA"
    },
    {
        "code": "13249",
        "county": "Schley",
        "value": "Schley",
        "state": "GA"
    },
    {
        "code": "13251",
        "county": "Screven",
        "value": "Screven",
        "state": "GA"
    },
    {
        "code": "13253",
        "county": "Seminole",
        "value": "Seminole",
        "state": "GA"
    },
    {
        "code": "13255",
        "county": "Spalding",
        "value": "Spalding",
        "state": "GA"
    },
    {
        "code": "13257",
        "county": "Stephens",
        "value": "Stephens",
        "state": "GA"
    },
    {
        "code": "13259",
        "county": "Stewart",
        "value": "Stewart",
        "state": "GA"
    },
    {
        "code": "13000",
        "county": "Georgia",
        "value": "Georgia",
        "state": "GA"
    },
    {
        "code": "13001",
        "county": "Appling",
        "value": "Appling",
        "state": "GA"
    },
    {
        "code": "13003",
        "county": "Atkinson",
        "value": "Atkinson",
        "state": "GA"
    },
    {
        "code": "13005",
        "county": "Bacon",
        "value": "Bacon",
        "state": "GA"
    },
    {
        "code": "13007",
        "county": "Baker",
        "value": "Baker",
        "state": "GA"
    },
    {
        "code": "13009",
        "county": "Baldwin",
        "value": "Baldwin",
        "state": "GA"
    },
    {
        "code": "13011",
        "county": "Banks",
        "value": "Banks",
        "state": "GA"
    },
    {
        "code": "13013",
        "county": "Barrow",
        "value": "Barrow",
        "state": "GA"
    },
    {
        "code": "13015",
        "county": "Bartow",
        "value": "Bartow",
        "state": "GA"
    },
    {
        "code": "13017",
        "county": "Ben Hill",
        "value": "Ben Hill",
        "state": "GA"
    },
    {
        "code": "13019",
        "county": "Berrien",
        "value": "Berrien",
        "state": "GA"
    },
    {
        "code": "13021",
        "county": "Bibb",
        "value": "Bibb",
        "state": "GA"
    },
    {
        "code": "13023",
        "county": "Bleckley",
        "value": "Bleckley",
        "state": "GA"
    },
    {
        "code": "13025",
        "county": "Brantley",
        "value": "Brantley",
        "state": "GA"
    },
    {
        "code": "13027",
        "county": "Brooks",
        "value": "Brooks",
        "state": "GA"
    },
    {
        "code": "13029",
        "county": "Bryan",
        "value": "Bryan",
        "state": "GA"
    },
    {
        "code": "13031",
        "county": "Bulloch",
        "value": "Bulloch",
        "state": "GA"
    },
    {
        "code": "13033",
        "county": "Burke",
        "value": "Burke",
        "state": "GA"
    },
    {
        "code": "13035",
        "county": "Butts",
        "value": "Butts",
        "state": "GA"
    },
    {
        "code": "13037",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "GA"
    },
    {
        "code": "13039",
        "county": "Camden",
        "value": "Camden",
        "state": "GA"
    },
    {
        "code": "13043",
        "county": "Candler",
        "value": "Candler",
        "state": "GA"
    },
    {
        "code": "13045",
        "county": "Carroll",
        "value": "Carroll",
        "state": "GA"
    },
    {
        "code": "13047",
        "county": "Catoosa",
        "value": "Catoosa",
        "state": "GA"
    },
    {
        "code": "13049",
        "county": "Charlton",
        "value": "Charlton",
        "state": "GA"
    },
    {
        "code": "13051",
        "county": "Chatham",
        "value": "Chatham",
        "state": "GA"
    },
    {
        "code": "13053",
        "county": "Chattahoochee",
        "value": "Chattahoochee",
        "state": "GA"
    },
    {
        "code": "13055",
        "county": "Chattooga",
        "value": "Chattooga",
        "state": "GA"
    },
    {
        "code": "13057",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "GA"
    },
    {
        "code": "13059",
        "county": "Clarke",
        "value": "Clarke",
        "state": "GA"
    },
    {
        "code": "13061",
        "county": "Clay",
        "value": "Clay",
        "state": "GA"
    },
    {
        "code": "13063",
        "county": "Clayton",
        "value": "Clayton",
        "state": "GA"
    },
    {
        "code": "13065",
        "county": "Clinch",
        "value": "Clinch",
        "state": "GA"
    },
    {
        "code": "13067",
        "county": "Cobb",
        "value": "Cobb",
        "state": "GA"
    },
    {
        "code": "13069",
        "county": "Coffee",
        "value": "Coffee",
        "state": "GA"
    },
    {
        "code": "13071",
        "county": "Colquitt",
        "value": "Colquitt",
        "state": "GA"
    },
    {
        "code": "13073",
        "county": "Columbia",
        "value": "Columbia",
        "state": "GA"
    },
    {
        "code": "13075",
        "county": "Cook",
        "value": "Cook",
        "state": "GA"
    },
    {
        "code": "13077",
        "county": "Coweta",
        "value": "Coweta",
        "state": "GA"
    },
    {
        "code": "13079",
        "county": "Crawford",
        "value": "Crawford",
        "state": "GA"
    },
    {
        "code": "13081",
        "county": "Crisp",
        "value": "Crisp",
        "state": "GA"
    },
    {
        "code": "13083",
        "county": "Dade",
        "value": "Dade",
        "state": "GA"
    },
    {
        "code": "13085",
        "county": "Dawson",
        "value": "Dawson",
        "state": "GA"
    },
    {
        "code": "13087",
        "county": "Decatur",
        "value": "Decatur",
        "state": "GA"
    },
    {
        "code": "13089",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "GA"
    },
    {
        "code": "13091",
        "county": "Dodge",
        "value": "Dodge",
        "state": "GA"
    },
    {
        "code": "13093",
        "county": "Dooly",
        "value": "Dooly",
        "state": "GA"
    },
    {
        "code": "13095",
        "county": "Dougherty",
        "value": "Dougherty",
        "state": "GA"
    },
    {
        "code": "13099",
        "county": "Early",
        "value": "Early",
        "state": "GA"
    },
    {
        "code": "13101",
        "county": "Echols",
        "value": "Echols",
        "state": "GA"
    },
    {
        "code": "13103",
        "county": "Effingham",
        "value": "Effingham",
        "state": "GA"
    },
    {
        "code": "13105",
        "county": "Elbert",
        "value": "Elbert",
        "state": "GA"
    },
    {
        "code": "13107",
        "county": "Emanuel",
        "value": "Emanuel",
        "state": "GA"
    },
    {
        "code": "13109",
        "county": "Evans",
        "value": "Evans",
        "state": "GA"
    },
    {
        "code": "13111",
        "county": "Fannin",
        "value": "Fannin",
        "state": "GA"
    },
    {
        "code": "13113",
        "county": "Fayette",
        "value": "Fayette",
        "state": "GA"
    },
    {
        "code": "13115",
        "county": "Floyd",
        "value": "Floyd",
        "state": "GA"
    },
    {
        "code": "13117",
        "county": "Forsyth",
        "value": "Forsyth",
        "state": "GA"
    },
    {
        "code": "13119",
        "county": "Franklin",
        "value": "Franklin",
        "state": "GA"
    },
    {
        "code": "13121",
        "county": "Fulton",
        "value": "Fulton",
        "state": "GA"
    },
    {
        "code": "13123",
        "county": "Gilmer",
        "value": "Gilmer",
        "state": "GA"
    },
    {
        "code": "13125",
        "county": "Glascock",
        "value": "Glascock",
        "state": "GA"
    },
    {
        "code": "13127",
        "county": "Glynn",
        "value": "Glynn",
        "state": "GA"
    },
    {
        "code": "13129",
        "county": "Gordon",
        "value": "Gordon",
        "state": "GA"
    },
    {
        "code": "15000",
        "county": "Hawaii",
        "value": "Hawaii",
        "state": "HI"
    },
    {
        "code": "15001",
        "county": "Hawaii",
        "value": "Hawaii",
        "state": "HI"
    },
    {
        "code": "15009",
        "county": "Maui",
        "value": "Maui",
        "state": "HI"
    },
    {
        "code": "15005",
        "county": "Kalawao",
        "value": "Kalawao",
        "state": "HI"
    },
    {
        "code": "15007",
        "county": "Kauai",
        "value": "Kauai",
        "state": "HI"
    },
    {
        "code": "15003",
        "county": "Honolulu",
        "value": "Honolulu",
        "state": "HI"
    },
    {
        "code": "19115",
        "county": "Louisa",
        "value": "Louisa",
        "state": "IA"
    },
    {
        "code": "19117",
        "county": "Lucas",
        "value": "Lucas",
        "state": "IA"
    },
    {
        "code": "19119",
        "county": "Lyon",
        "value": "Lyon",
        "state": "IA"
    },
    {
        "code": "19121",
        "county": "Madison",
        "value": "Madison",
        "state": "IA"
    },
    {
        "code": "19123",
        "county": "Mahaska",
        "value": "Mahaska",
        "state": "IA"
    },
    {
        "code": "19125",
        "county": "Marion",
        "value": "Marion",
        "state": "IA"
    },
    {
        "code": "19127",
        "county": "Marshall",
        "value": "Marshall",
        "state": "IA"
    },
    {
        "code": "19129",
        "county": "Mills",
        "value": "Mills",
        "state": "IA"
    },
    {
        "code": "19131",
        "county": "Mitchell",
        "value": "Mitchell",
        "state": "IA"
    },
    {
        "code": "19133",
        "county": "Monona",
        "value": "Monona",
        "state": "IA"
    },
    {
        "code": "19135",
        "county": "Monroe",
        "value": "Monroe",
        "state": "IA"
    },
    {
        "code": "19137",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "IA"
    },
    {
        "code": "19139",
        "county": "Muscatine",
        "value": "Muscatine",
        "state": "IA"
    },
    {
        "code": "19141",
        "county": "O",
        "value": "O",
        "state": "IA"
    },
    {
        "code": "19143",
        "county": "Osceola",
        "value": "Osceola",
        "state": "IA"
    },
    {
        "code": "19145",
        "county": "Page",
        "value": "Page",
        "state": "IA"
    },
    {
        "code": "19147",
        "county": "Palo Alto",
        "value": "Palo Alto",
        "state": "IA"
    },
    {
        "code": "19149",
        "county": "Plymouth",
        "value": "Plymouth",
        "state": "IA"
    },
    {
        "code": "19151",
        "county": "Pocahontas",
        "value": "Pocahontas",
        "state": "IA"
    },
    {
        "code": "19153",
        "county": "Polk",
        "value": "Polk",
        "state": "IA"
    },
    {
        "code": "19155",
        "county": "Pottawattamie",
        "value": "Pottawattamie",
        "state": "IA"
    },
    {
        "code": "19000",
        "county": "Iowa",
        "value": "Iowa",
        "state": "IA"
    },
    {
        "code": "19001",
        "county": "Adair",
        "value": "Adair",
        "state": "IA"
    },
    {
        "code": "19003",
        "county": "Adams",
        "value": "Adams",
        "state": "IA"
    },
    {
        "code": "19005",
        "county": "Allamakee",
        "value": "Allamakee",
        "state": "IA"
    },
    {
        "code": "19007",
        "county": "Appanoose",
        "value": "Appanoose",
        "state": "IA"
    },
    {
        "code": "19009",
        "county": "Audubon",
        "value": "Audubon",
        "state": "IA"
    },
    {
        "code": "19011",
        "county": "Benton",
        "value": "Benton",
        "state": "IA"
    },
    {
        "code": "19013",
        "county": "Black Hawk",
        "value": "Black Hawk",
        "state": "IA"
    },
    {
        "code": "19015",
        "county": "Boone",
        "value": "Boone",
        "state": "IA"
    },
    {
        "code": "19017",
        "county": "Bremer",
        "value": "Bremer",
        "state": "IA"
    },
    {
        "code": "19019",
        "county": "Buchanan",
        "value": "Buchanan",
        "state": "IA"
    },
    {
        "code": "19021",
        "county": "Buena Vista",
        "value": "Buena Vista",
        "state": "IA"
    },
    {
        "code": "19023",
        "county": "Butler",
        "value": "Butler",
        "state": "IA"
    },
    {
        "code": "19025",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "IA"
    },
    {
        "code": "19027",
        "county": "Carroll",
        "value": "Carroll",
        "state": "IA"
    },
    {
        "code": "19157",
        "county": "Poweshiek",
        "value": "Poweshiek",
        "state": "IA"
    },
    {
        "code": "19159",
        "county": "Ringgold",
        "value": "Ringgold",
        "state": "IA"
    },
    {
        "code": "19161",
        "county": "Sac",
        "value": "Sac",
        "state": "IA"
    },
    {
        "code": "19163",
        "county": "Scott",
        "value": "Scott",
        "state": "IA"
    },
    {
        "code": "19165",
        "county": "Shelby",
        "value": "Shelby",
        "state": "IA"
    },
    {
        "code": "19167",
        "county": "Sioux",
        "value": "Sioux",
        "state": "IA"
    },
    {
        "code": "19169",
        "county": "Story",
        "value": "Story",
        "state": "IA"
    },
    {
        "code": "19171",
        "county": "Tama",
        "value": "Tama",
        "state": "IA"
    },
    {
        "code": "19173",
        "county": "Taylor",
        "value": "Taylor",
        "state": "IA"
    },
    {
        "code": "19175",
        "county": "Union",
        "value": "Union",
        "state": "IA"
    },
    {
        "code": "19177",
        "county": "Van Buren",
        "value": "Van Buren",
        "state": "IA"
    },
    {
        "code": "19179",
        "county": "Wapello",
        "value": "Wapello",
        "state": "IA"
    },
    {
        "code": "19181",
        "county": "Warren",
        "value": "Warren",
        "state": "IA"
    },
    {
        "code": "19183",
        "county": "Washington",
        "value": "Washington",
        "state": "IA"
    },
    {
        "code": "19185",
        "county": "Wayne",
        "value": "Wayne",
        "state": "IA"
    },
    {
        "code": "19187",
        "county": "Webster",
        "value": "Webster",
        "state": "IA"
    },
    {
        "code": "19189",
        "county": "Winnebago",
        "value": "Winnebago",
        "state": "IA"
    },
    {
        "code": "19191",
        "county": "Winneshiek",
        "value": "Winneshiek",
        "state": "IA"
    },
    {
        "code": "19193",
        "county": "Woodbury",
        "value": "Woodbury",
        "state": "IA"
    },
    {
        "code": "19195",
        "county": "Worth",
        "value": "Worth",
        "state": "IA"
    },
    {
        "code": "19197",
        "county": "Wright",
        "value": "Wright",
        "state": "IA"
    },
    {
        "code": "19029",
        "county": "Cass",
        "value": "Cass",
        "state": "IA"
    },
    {
        "code": "19031",
        "county": "Cedar",
        "value": "Cedar",
        "state": "IA"
    },
    {
        "code": "19033",
        "county": "Cerro Gordo",
        "value": "Cerro Gordo",
        "state": "IA"
    },
    {
        "code": "19035",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "IA"
    },
    {
        "code": "19037",
        "county": "Chickasaw",
        "value": "Chickasaw",
        "state": "IA"
    },
    {
        "code": "19039",
        "county": "Clarke",
        "value": "Clarke",
        "state": "IA"
    },
    {
        "code": "19041",
        "county": "Clay",
        "value": "Clay",
        "state": "IA"
    },
    {
        "code": "19043",
        "county": "Clayton",
        "value": "Clayton",
        "state": "IA"
    },
    {
        "code": "19045",
        "county": "Clinton",
        "value": "Clinton",
        "state": "IA"
    },
    {
        "code": "19047",
        "county": "Crawford",
        "value": "Crawford",
        "state": "IA"
    },
    {
        "code": "19049",
        "county": "Dallas",
        "value": "Dallas",
        "state": "IA"
    },
    {
        "code": "19051",
        "county": "Davis",
        "value": "Davis",
        "state": "IA"
    },
    {
        "code": "19053",
        "county": "Decatur",
        "value": "Decatur",
        "state": "IA"
    },
    {
        "code": "19055",
        "county": "Delaware",
        "value": "Delaware",
        "state": "IA"
    },
    {
        "code": "19057",
        "county": "Des Moines",
        "value": "Des Moines",
        "state": "IA"
    },
    {
        "code": "19059",
        "county": "Dickinson",
        "value": "Dickinson",
        "state": "IA"
    },
    {
        "code": "19061",
        "county": "Dubuque",
        "value": "Dubuque",
        "state": "IA"
    },
    {
        "code": "19063",
        "county": "Emmet",
        "value": "Emmet",
        "state": "IA"
    },
    {
        "code": "19065",
        "county": "Fayette",
        "value": "Fayette",
        "state": "IA"
    },
    {
        "code": "19067",
        "county": "Floyd",
        "value": "Floyd",
        "state": "IA"
    },
    {
        "code": "19069",
        "county": "Franklin",
        "value": "Franklin",
        "state": "IA"
    },
    {
        "code": "19071",
        "county": "Fremont",
        "value": "Fremont",
        "state": "IA"
    },
    {
        "code": "19073",
        "county": "Greene",
        "value": "Greene",
        "state": "IA"
    },
    {
        "code": "19075",
        "county": "Grundy",
        "value": "Grundy",
        "state": "IA"
    },
    {
        "code": "19077",
        "county": "Guthrie",
        "value": "Guthrie",
        "state": "IA"
    },
    {
        "code": "19079",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "IA"
    },
    {
        "code": "19081",
        "county": "Hancock",
        "value": "Hancock",
        "state": "IA"
    },
    {
        "code": "19083",
        "county": "Hardin",
        "value": "Hardin",
        "state": "IA"
    },
    {
        "code": "19085",
        "county": "Harrison",
        "value": "Harrison",
        "state": "IA"
    },
    {
        "code": "19087",
        "county": "Henry",
        "value": "Henry",
        "state": "IA"
    },
    {
        "code": "19089",
        "county": "Howard",
        "value": "Howard",
        "state": "IA"
    },
    {
        "code": "19091",
        "county": "Humboldt",
        "value": "Humboldt",
        "state": "IA"
    },
    {
        "code": "19093",
        "county": "Ida",
        "value": "Ida",
        "state": "IA"
    },
    {
        "code": "19095",
        "county": "Iowa",
        "value": "Iowa",
        "state": "IA"
    },
    {
        "code": "19097",
        "county": "Jackson",
        "value": "Jackson",
        "state": "IA"
    },
    {
        "code": "19099",
        "county": "Jasper",
        "value": "Jasper",
        "state": "IA"
    },
    {
        "code": "19101",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "IA"
    },
    {
        "code": "19103",
        "county": "Johnson",
        "value": "Johnson",
        "state": "IA"
    },
    {
        "code": "19105",
        "county": "Jones",
        "value": "Jones",
        "state": "IA"
    },
    {
        "code": "19107",
        "county": "Keokuk",
        "value": "Keokuk",
        "state": "IA"
    },
    {
        "code": "19109",
        "county": "Kossuth",
        "value": "Kossuth",
        "state": "IA"
    },
    {
        "code": "19111",
        "county": "Lee",
        "value": "Lee",
        "state": "IA"
    },
    {
        "code": "19113",
        "county": "Linn",
        "value": "Linn",
        "state": "IA"
    },
    {
        "code": "16000",
        "county": "Idaho",
        "value": "Idaho",
        "state": "ID"
    },
    {
        "code": "16087",
        "county": "Washington",
        "value": "Washington",
        "state": "ID"
    },
    {
        "code": "16003",
        "county": "Adams",
        "value": "Adams",
        "state": "ID"
    },
    {
        "code": "16005",
        "county": "Bannock",
        "value": "Bannock",
        "state": "ID"
    },
    {
        "code": "16007",
        "county": "Bear Lake",
        "value": "Bear Lake",
        "state": "ID"
    },
    {
        "code": "16009",
        "county": "Benewah",
        "value": "Benewah",
        "state": "ID"
    },
    {
        "code": "16011",
        "county": "Bingham",
        "value": "Bingham",
        "state": "ID"
    },
    {
        "code": "16013",
        "county": "Blaine",
        "value": "Blaine",
        "state": "ID"
    },
    {
        "code": "16015",
        "county": "Boise",
        "value": "Boise",
        "state": "ID"
    },
    {
        "code": "16017",
        "county": "Bonner",
        "value": "Bonner",
        "state": "ID"
    },
    {
        "code": "16019",
        "county": "Bonneville",
        "value": "Bonneville",
        "state": "ID"
    },
    {
        "code": "16021",
        "county": "Boundary",
        "value": "Boundary",
        "state": "ID"
    },
    {
        "code": "16023",
        "county": "Butte",
        "value": "Butte",
        "state": "ID"
    },
    {
        "code": "16025",
        "county": "Camas",
        "value": "Camas",
        "state": "ID"
    },
    {
        "code": "16027",
        "county": "Canyon",
        "value": "Canyon",
        "state": "ID"
    },
    {
        "code": "16029",
        "county": "Caribou",
        "value": "Caribou",
        "state": "ID"
    },
    {
        "code": "16031",
        "county": "Cassia",
        "value": "Cassia",
        "state": "ID"
    },
    {
        "code": "16033",
        "county": "Clark",
        "value": "Clark",
        "state": "ID"
    },
    {
        "code": "16035",
        "county": "Clearwater",
        "value": "Clearwater",
        "state": "ID"
    },
    {
        "code": "16037",
        "county": "Custer",
        "value": "Custer",
        "state": "ID"
    },
    {
        "code": "16039",
        "county": "Elmore",
        "value": "Elmore",
        "state": "ID"
    },
    {
        "code": "16041",
        "county": "Franklin",
        "value": "Franklin",
        "state": "ID"
    },
    {
        "code": "16043",
        "county": "Fremont",
        "value": "Fremont",
        "state": "ID"
    },
    {
        "code": "16045",
        "county": "Gem",
        "value": "Gem",
        "state": "ID"
    },
    {
        "code": "16047",
        "county": "Gooding",
        "value": "Gooding",
        "state": "ID"
    },
    {
        "code": "16049",
        "county": "Idaho",
        "value": "Idaho",
        "state": "ID"
    },
    {
        "code": "16051",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "ID"
    },
    {
        "code": "16053",
        "county": "Jerome",
        "value": "Jerome",
        "state": "ID"
    },
    {
        "code": "16055",
        "county": "Kootenai",
        "value": "Kootenai",
        "state": "ID"
    },
    {
        "code": "16057",
        "county": "Latah",
        "value": "Latah",
        "state": "ID"
    },
    {
        "code": "16059",
        "county": "Lemhi",
        "value": "Lemhi",
        "state": "ID"
    },
    {
        "code": "16061",
        "county": "Lewis",
        "value": "Lewis",
        "state": "ID"
    },
    {
        "code": "16063",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "ID"
    },
    {
        "code": "16065",
        "county": "Madison",
        "value": "Madison",
        "state": "ID"
    },
    {
        "code": "16067",
        "county": "Minidoka",
        "value": "Minidoka",
        "state": "ID"
    },
    {
        "code": "16069",
        "county": "Nez Perce",
        "value": "Nez Perce",
        "state": "ID"
    },
    {
        "code": "16071",
        "county": "Oneida",
        "value": "Oneida",
        "state": "ID"
    },
    {
        "code": "16073",
        "county": "Owyhee",
        "value": "Owyhee",
        "state": "ID"
    },
    {
        "code": "16075",
        "county": "Payette",
        "value": "Payette",
        "state": "ID"
    },
    {
        "code": "16077",
        "county": "Power",
        "value": "Power",
        "state": "ID"
    },
    {
        "code": "16079",
        "county": "Shoshone",
        "value": "Shoshone",
        "state": "ID"
    },
    {
        "code": "16081",
        "county": "Teton",
        "value": "Teton",
        "state": "ID"
    },
    {
        "code": "16083",
        "county": "Twin Falls",
        "value": "Twin Falls",
        "state": "ID"
    },
    {
        "code": "16085",
        "county": "Valley",
        "value": "Valley",
        "state": "ID"
    },
    {
        "code": "16001",
        "county": "Ada",
        "value": "Ada",
        "state": "ID"
    },
    {
        "code": "17163",
        "county": "St. Clair",
        "value": "St. Clair",
        "state": "IL"
    },
    {
        "code": "17165",
        "county": "Saline",
        "value": "Saline",
        "state": "IL"
    },
    {
        "code": "17167",
        "county": "Sangamon",
        "value": "Sangamon",
        "state": "IL"
    },
    {
        "code": "17169",
        "county": "Schuyler",
        "value": "Schuyler",
        "state": "IL"
    },
    {
        "code": "17171",
        "county": "Scott",
        "value": "Scott",
        "state": "IL"
    },
    {
        "code": "17173",
        "county": "Shelby",
        "value": "Shelby",
        "state": "IL"
    },
    {
        "code": "17175",
        "county": "Stark",
        "value": "Stark",
        "state": "IL"
    },
    {
        "code": "17177",
        "county": "Stephenson",
        "value": "Stephenson",
        "state": "IL"
    },
    {
        "code": "17179",
        "county": "Tazewell",
        "value": "Tazewell",
        "state": "IL"
    },
    {
        "code": "17181",
        "county": "Union",
        "value": "Union",
        "state": "IL"
    },
    {
        "code": "17183",
        "county": "Vermilion",
        "value": "Vermilion",
        "state": "IL"
    },
    {
        "code": "17185",
        "county": "Wabash",
        "value": "Wabash",
        "state": "IL"
    },
    {
        "code": "17187",
        "county": "Warren",
        "value": "Warren",
        "state": "IL"
    },
    {
        "code": "17189",
        "county": "Washington",
        "value": "Washington",
        "state": "IL"
    },
    {
        "code": "17191",
        "county": "Wayne",
        "value": "Wayne",
        "state": "IL"
    },
    {
        "code": "17193",
        "county": "White",
        "value": "White",
        "state": "IL"
    },
    {
        "code": "17195",
        "county": "Whiteside",
        "value": "Whiteside",
        "state": "IL"
    },
    {
        "code": "17197",
        "county": "Will",
        "value": "Will",
        "state": "IL"
    },
    {
        "code": "17199",
        "county": "Williamson",
        "value": "Williamson",
        "state": "IL"
    },
    {
        "code": "17201",
        "county": "Winnebago",
        "value": "Winnebago",
        "state": "IL"
    },
    {
        "code": "17203",
        "county": "Woodford",
        "value": "Woodford",
        "state": "IL"
    },
    {
        "code": "17035",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "IL"
    },
    {
        "code": "17037",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "IL"
    },
    {
        "code": "17039",
        "county": "De Witt",
        "value": "De Witt",
        "state": "IL"
    },
    {
        "code": "17041",
        "county": "Douglas",
        "value": "Douglas",
        "state": "IL"
    },
    {
        "code": "17043",
        "county": "DuPage",
        "value": "DuPage",
        "state": "IL"
    },
    {
        "code": "17045",
        "county": "Edgar",
        "value": "Edgar",
        "state": "IL"
    },
    {
        "code": "17047",
        "county": "Edwards",
        "value": "Edwards",
        "state": "IL"
    },
    {
        "code": "17049",
        "county": "Effingham",
        "value": "Effingham",
        "state": "IL"
    },
    {
        "code": "17051",
        "county": "Fayette",
        "value": "Fayette",
        "state": "IL"
    },
    {
        "code": "17053",
        "county": "Ford",
        "value": "Ford",
        "state": "IL"
    },
    {
        "code": "17055",
        "county": "Franklin",
        "value": "Franklin",
        "state": "IL"
    },
    {
        "code": "17057",
        "county": "Fulton",
        "value": "Fulton",
        "state": "IL"
    },
    {
        "code": "17059",
        "county": "Gallatin",
        "value": "Gallatin",
        "state": "IL"
    },
    {
        "code": "17061",
        "county": "Greene",
        "value": "Greene",
        "state": "IL"
    },
    {
        "code": "17063",
        "county": "Grundy",
        "value": "Grundy",
        "state": "IL"
    },
    {
        "code": "17065",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "IL"
    },
    {
        "code": "17067",
        "county": "Hancock",
        "value": "Hancock",
        "state": "IL"
    },
    {
        "code": "17069",
        "county": "Hardin",
        "value": "Hardin",
        "state": "IL"
    },
    {
        "code": "17071",
        "county": "Henderson",
        "value": "Henderson",
        "state": "IL"
    },
    {
        "code": "17073",
        "county": "Henry",
        "value": "Henry",
        "state": "IL"
    },
    {
        "code": "17075",
        "county": "Iroquois",
        "value": "Iroquois",
        "state": "IL"
    },
    {
        "code": "02000",
        "county": "Alaska",
        "value": "Alaska",
        "state": "AK"
    },
    {
        "code": "02290",
        "county": "Yukon-Koyukuk Census Area",
        "value": "Yukon-Koyukuk Census Area",
        "state": "AK"
    },
    {
        "code": "02016",
        "county": "Aleutians West Census Area",
        "value": "Aleutians West Census Area",
        "state": "AK"
    },
    {
        "code": "02020",
        "county": "Anchorage Borough",
        "value": "Anchorage Borough",
        "state": "AK"
    },
    {
        "code": "02050",
        "county": "Bethel Census Area",
        "value": "Bethel Census Area",
        "state": "AK"
    },
    {
        "code": "02060",
        "county": "Bristol Bay Borough",
        "value": "Bristol Bay Borough",
        "state": "AK"
    },
    {
        "code": "02068",
        "county": "Denali Borough",
        "value": "Denali Borough",
        "state": "AK"
    },
    {
        "code": "02070",
        "county": "Dillingham Census Area",
        "value": "Dillingham Census Area",
        "state": "AK"
    },
    {
        "code": "02090",
        "county": "Fairbanks North Star Borough",
        "value": "Fairbanks North Star Borough",
        "state": "AK"
    },
    {
        "code": "02100",
        "county": "Haines Borough",
        "value": "Haines Borough",
        "state": "AK"
    },
    {
        "code": "02110",
        "county": "Juneau Borough",
        "value": "Juneau Borough",
        "state": "AK"
    },
    {
        "code": "02122",
        "county": "Kenai Peninsula Borough",
        "value": "Kenai Peninsula Borough",
        "state": "AK"
    },
    {
        "code": "02130",
        "county": "Ketchikan Gateway Borough",
        "value": "Ketchikan Gateway Borough",
        "state": "AK"
    },
    {
        "code": "02150",
        "county": "Kodiak Island Borough",
        "value": "Kodiak Island Borough",
        "state": "AK"
    },
    {
        "code": "02164",
        "county": "Lake and Peninsula Borough",
        "value": "Lake and Peninsula Borough",
        "state": "AK"
    },
    {
        "code": "02170",
        "county": "Matanuska-Susitna Borough",
        "value": "Matanuska-Susitna Borough",
        "state": "AK"
    },
    {
        "code": "02180",
        "county": "Nome Census Area",
        "value": "Nome Census Area",
        "state": "AK"
    },
    {
        "code": "02185",
        "county": "North Slope Borough",
        "value": "North Slope Borough",
        "state": "AK"
    },
    {
        "code": "02188",
        "county": "Northwest Arctic Borough",
        "value": "Northwest Arctic Borough",
        "state": "AK"
    },
    {
        "code": "02201",
        "county": "Prince of Wales-Outer Ketchikan Census Area",
        "value": "Prince of Wales-Outer Ketchikan Census Area",
        "state": "AK"
    },
    {
        "code": "02220",
        "county": "Sitka Borough",
        "value": "Sitka Borough",
        "state": "AK"
    },
    {
        "code": "02231",
        "county": "Skagway-Yakutat-Angoon Census Area",
        "value": "Skagway-Yakutat-Angoon Census Area",
        "state": "AK"
    },
    {
        "code": "02232",
        "county": "Skagway-Hoonah-Angoon Census Area",
        "value": "Skagway-Hoonah-Angoon Census Area",
        "state": "AK"
    },
    {
        "code": "02240",
        "county": "Southeast Fairbanks Census Area",
        "value": "Southeast Fairbanks Census Area",
        "state": "AK"
    },
    {
        "code": "02261",
        "county": "Valdez-Cordova Census Area",
        "value": "Valdez-Cordova Census Area",
        "state": "AK"
    },
    {
        "code": "02270",
        "county": "Wade Hampton Census Area",
        "value": "Wade Hampton Census Area",
        "state": "AK"
    },
    {
        "code": "02280",
        "county": "Wrangell-Petersburg Census Area",
        "value": "Wrangell-Petersburg Census Area",
        "state": "AK"
    },
    {
        "code": "02282",
        "county": "Yakutat Borough",
        "value": "Yakutat Borough",
        "state": "AK"
    },
    {
        "code": "02013",
        "county": "Aleutians East Borough",
        "value": "Aleutians East Borough",
        "state": "AK"
    },
    {
        "code": "01119",
        "county": "Sumter",
        "value": "Sumter",
        "state": "AL"
    },
    {
        "code": "01121",
        "county": "Talladega",
        "value": "Talladega",
        "state": "AL"
    },
    {
        "code": "01123",
        "county": "Tallapoosa",
        "value": "Tallapoosa",
        "state": "AL"
    },
    {
        "code": "01125",
        "county": "Tuscaloosa",
        "value": "Tuscaloosa",
        "state": "AL"
    },
    {
        "code": "01127",
        "county": "Walker",
        "value": "Walker",
        "state": "AL"
    },
    {
        "code": "01129",
        "county": "Washington",
        "value": "Washington",
        "state": "AL"
    },
    {
        "code": "01131",
        "county": "Wilcox",
        "value": "Wilcox",
        "state": "AL"
    },
    {
        "code": "01133",
        "county": "Winston",
        "value": "Winston",
        "state": "AL"
    },
    // {
    //     "code": "01000",
    //     "county": "Alabama",
    //     "value": "Alabama",
    //     "state": "AL"
    // },
    {
        "code": "01001",
        "county": "Autauga",
        "value": "Autauga",
        "state": "AL"
    },
    {
        "code": "01003",
        "county": "Baldwin",
        "value": "Baldwin",
        "state": "AL"
    },
    {
        "code": "01005",
        "county": "Barbour",
        "value": "Barbour",
        "state": "AL"
    },
    {
        "code": "01007",
        "county": "Bibb",
        "value": "Bibb",
        "state": "AL"
    },
    {
        "code": "01009",
        "county": "Blount",
        "value": "Blount",
        "state": "AL"
    },
    {
        "code": "01011",
        "county": "Bullock",
        "value": "Bullock",
        "state": "AL"
    },
    {
        "code": "01013",
        "county": "Butler",
        "value": "Butler",
        "state": "AL"
    },
    {
        "code": "01015",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "AL"
    },
    {
        "code": "01017",
        "county": "Chambers",
        "value": "Chambers",
        "state": "AL"
    },
    {
        "code": "01019",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "AL"
    },
    {
        "code": "01021",
        "county": "Chilton",
        "value": "Chilton",
        "state": "AL"
    },
    {
        "code": "01023",
        "county": "Choctaw",
        "value": "Choctaw",
        "state": "AL"
    },
    {
        "code": "01025",
        "county": "Clarke",
        "value": "Clarke",
        "state": "AL"
    },
    {
        "code": "01027",
        "county": "Clay",
        "value": "Clay",
        "state": "AL"
    },
    {
        "code": "01029",
        "county": "Cleburne",
        "value": "Cleburne",
        "state": "AL"
    },
    {
        "code": "01031",
        "county": "Coffee",
        "value": "Coffee",
        "state": "AL"
    },
    {
        "code": "01033",
        "county": "Colbert",
        "value": "Colbert",
        "state": "AL"
    },
    {
        "code": "01035",
        "county": "Conecuh",
        "value": "Conecuh",
        "state": "AL"
    },
    {
        "code": "01037",
        "county": "Coosa",
        "value": "Coosa",
        "state": "AL"
    },
    {
        "code": "01039",
        "county": "Covington",
        "value": "Covington",
        "state": "AL"
    },
    {
        "code": "01041",
        "county": "Crenshaw",
        "value": "Crenshaw",
        "state": "AL"
    },
    {
        "code": "01043",
        "county": "Cullman",
        "value": "Cullman",
        "state": "AL"
    },
    {
        "code": "01045",
        "county": "Dale",
        "value": "Dale",
        "state": "AL"
    },
    {
        "code": "01047",
        "county": "Dallas",
        "value": "Dallas",
        "state": "AL"
    },
    {
        "code": "01049",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "AL"
    },
    {
        "code": "01051",
        "county": "Elmore",
        "value": "Elmore",
        "state": "AL"
    },
    {
        "code": "01053",
        "county": "Escambia",
        "value": "Escambia",
        "state": "AL"
    },
    {
        "code": "01055",
        "county": "Etowah",
        "value": "Etowah",
        "state": "AL"
    },
    {
        "code": "01057",
        "county": "Fayette",
        "value": "Fayette",
        "state": "AL"
    },
    {
        "code": "01059",
        "county": "Franklin",
        "value": "Franklin",
        "state": "AL"
    },
    {
        "code": "01061",
        "county": "Geneva",
        "value": "Geneva",
        "state": "AL"
    },
    {
        "code": "01063",
        "county": "Greene",
        "value": "Greene",
        "state": "AL"
    },
    {
        "code": "01065",
        "county": "Hale",
        "value": "Hale",
        "state": "AL"
    },
    {
        "code": "01067",
        "county": "Henry",
        "value": "Henry",
        "state": "AL"
    },
    {
        "code": "01069",
        "county": "Houston",
        "value": "Houston",
        "state": "AL"
    },
    {
        "code": "01071",
        "county": "Jackson",
        "value": "Jackson",
        "state": "AL"
    },
    {
        "code": "01073",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "AL"
    },
    {
        "code": "01075",
        "county": "Lamar",
        "value": "Lamar",
        "state": "AL"
    },
    {
        "code": "01077",
        "county": "Lauderdale",
        "value": "Lauderdale",
        "state": "AL"
    },
    {
        "code": "01079",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "AL"
    },
    {
        "code": "01081",
        "county": "Lee",
        "value": "Lee",
        "state": "AL"
    },
    {
        "code": "01083",
        "county": "Limestone",
        "value": "Limestone",
        "state": "AL"
    },
    {
        "code": "01085",
        "county": "Lowndes",
        "value": "Lowndes",
        "state": "AL"
    },
    {
        "code": "01087",
        "county": "Macon",
        "value": "Macon",
        "state": "AL"
    },
    {
        "code": "01089",
        "county": "Madison",
        "value": "Madison",
        "state": "AL"
    },
    {
        "code": "01091",
        "county": "Marengo",
        "value": "Marengo",
        "state": "AL"
    },
    {
        "code": "01093",
        "county": "Marion",
        "value": "Marion",
        "state": "AL"
    },
    {
        "code": "01095",
        "county": "Marshall",
        "value": "Marshall",
        "state": "AL"
    },
    {
        "code": "01097",
        "county": "Mobile",
        "value": "Mobile",
        "state": "AL"
    },
    {
        "code": "01099",
        "county": "Monroe",
        "value": "Monroe",
        "state": "AL"
    },
    {
        "code": "01101",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "AL"
    },
    {
        "code": "01103",
        "county": "Morgan",
        "value": "Morgan",
        "state": "AL"
    },
    {
        "code": "01105",
        "county": "Perry",
        "value": "Perry",
        "state": "AL"
    },
    {
        "code": "01107",
        "county": "Pickens",
        "value": "Pickens",
        "state": "AL"
    },
    {
        "code": "01109",
        "county": "Pike",
        "value": "Pike",
        "state": "AL"
    },
    {
        "code": "01111",
        "county": "Randolph",
        "value": "Randolph",
        "state": "AL"
    },
    {
        "code": "01113",
        "county": "Russell",
        "value": "Russell",
        "state": "AL"
    },
    {
        "code": "01115",
        "county": "Saint Clair",
        "value": "Saint Clair",
        "state": "AL"
    },
    {
        "code": "01117",
        "county": "Shelby",
        "value": "Shelby",
        "state": "AL"
    },
    {
        "code": "05029",
        "county": "Conway",
        "value": "Conway",
        "state": "AR"
    },
    {
        "code": "05031",
        "county": "Craighead",
        "value": "Craighead",
        "state": "AR"
    },
    {
        "code": "05033",
        "county": "Crawford",
        "value": "Crawford",
        "state": "AR"
    },
    {
        "code": "05035",
        "county": "Crittenden",
        "value": "Crittenden",
        "state": "AR"
    },
    {
        "code": "05037",
        "county": "Cross",
        "value": "Cross",
        "state": "AR"
    },
    {
        "code": "05039",
        "county": "Dallas",
        "value": "Dallas",
        "state": "AR"
    },
    {
        "code": "05041",
        "county": "Desha",
        "value": "Desha",
        "state": "AR"
    },
    {
        "code": "05043",
        "county": "Drew",
        "value": "Drew",
        "state": "AR"
    },
    {
        "code": "05045",
        "county": "Faulkner",
        "value": "Faulkner",
        "state": "AR"
    },
    {
        "code": "05047",
        "county": "Franklin",
        "value": "Franklin",
        "state": "AR"
    },
    {
        "code": "05049",
        "county": "Fulton",
        "value": "Fulton",
        "state": "AR"
    },
    {
        "code": "05051",
        "county": "Garland",
        "value": "Garland",
        "state": "AR"
    },
    {
        "code": "05053",
        "county": "Grant",
        "value": "Grant",
        "state": "AR"
    },
    {
        "code": "05055",
        "county": "Greene",
        "value": "Greene",
        "state": "AR"
    },
    {
        "code": "05057",
        "county": "Hempstead",
        "value": "Hempstead",
        "state": "AR"
    },
    {
        "code": "05059",
        "county": "Hot Spring",
        "value": "Hot Spring",
        "state": "AR"
    },
    {
        "code": "05061",
        "county": "Howard",
        "value": "Howard",
        "state": "AR"
    },
    {
        "code": "05063",
        "county": "Independence",
        "value": "Independence",
        "state": "AR"
    },
    {
        "code": "05065",
        "county": "Izard",
        "value": "Izard",
        "state": "AR"
    },
    {
        "code": "05067",
        "county": "Jackson",
        "value": "Jackson",
        "state": "AR"
    },
    {
        "code": "05069",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "AR"
    },
    {
        "code": "05071",
        "county": "Johnson",
        "value": "Johnson",
        "state": "AR"
    },
    {
        "code": "05073",
        "county": "Lafayette",
        "value": "Lafayette",
        "state": "AR"
    },
    {
        "code": "05075",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "AR"
    },
    {
        "code": "05077",
        "county": "Lee",
        "value": "Lee",
        "state": "AR"
    },
    {
        "code": "05079",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "AR"
    },
    {
        "code": "05081",
        "county": "Little River",
        "value": "Little River",
        "state": "AR"
    },
    {
        "code": "05083",
        "county": "Logan",
        "value": "Logan",
        "state": "AR"
    },
    {
        "code": "05085",
        "county": "Lonoke",
        "value": "Lonoke",
        "state": "AR"
    },
    {
        "code": "05087",
        "county": "Madison",
        "value": "Madison",
        "state": "AR"
    },
    {
        "code": "05089",
        "county": "Marion",
        "value": "Marion",
        "state": "AR"
    },
    {
        "code": "05091",
        "county": "Miller",
        "value": "Miller",
        "state": "AR"
    },
    {
        "code": "05093",
        "county": "Mississippi",
        "value": "Mississippi",
        "state": "AR"
    },
    {
        "code": "05095",
        "county": "Monroe",
        "value": "Monroe",
        "state": "AR"
    },
    {
        "code": "05097",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "AR"
    },
    {
        "code": "05099",
        "county": "Nevada",
        "value": "Nevada",
        "state": "AR"
    },
    {
        "code": "05101",
        "county": "Newton",
        "value": "Newton",
        "state": "AR"
    },
    {
        "code": "05103",
        "county": "Ouachita",
        "value": "Ouachita",
        "state": "AR"
    },
    {
        "code": "05105",
        "county": "Perry",
        "value": "Perry",
        "state": "AR"
    },
    {
        "code": "05107",
        "county": "Phillips",
        "value": "Phillips",
        "state": "AR"
    },
    {
        "code": "05109",
        "county": "Pike",
        "value": "Pike",
        "state": "AR"
    },
    {
        "code": "05111",
        "county": "Poinsett",
        "value": "Poinsett",
        "state": "AR"
    },
    {
        "code": "05113",
        "county": "Polk",
        "value": "Polk",
        "state": "AR"
    },
    {
        "code": "05115",
        "county": "Pope",
        "value": "Pope",
        "state": "AR"
    },
    {
        "code": "05117",
        "county": "Prairie",
        "value": "Prairie",
        "state": "AR"
    },
    {
        "code": "05119",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "AR"
    },
    {
        "code": "05121",
        "county": "Randolph",
        "value": "Randolph",
        "state": "AR"
    },
    {
        "code": "05123",
        "county": "St. Francis",
        "value": "St. Francis",
        "state": "AR"
    },
    {
        "code": "05125",
        "county": "Saline",
        "value": "Saline",
        "state": "AR"
    },
    {
        "code": "05127",
        "county": "Scott",
        "value": "Scott",
        "state": "AR"
    },
    {
        "code": "05129",
        "county": "Searcy",
        "value": "Searcy",
        "state": "AR"
    },
    {
        "code": "05131",
        "county": "Sebastian",
        "value": "Sebastian",
        "state": "AR"
    },
    {
        "code": "05133",
        "county": "Sevier",
        "value": "Sevier",
        "state": "AR"
    },
    {
        "code": "05135",
        "county": "Sharp",
        "value": "Sharp",
        "state": "AR"
    },
    {
        "code": "05137",
        "county": "Stone",
        "value": "Stone",
        "state": "AR"
    },
    {
        "code": "05139",
        "county": "Union",
        "value": "Union",
        "state": "AR"
    },
    {
        "code": "05141",
        "county": "Van Buren",
        "value": "Van Buren",
        "state": "AR"
    },
    {
        "code": "05143",
        "county": "Washington",
        "value": "Washington",
        "state": "AR"
    },
    {
        "code": "05145",
        "county": "White",
        "value": "White",
        "state": "AR"
    },
    {
        "code": "05147",
        "county": "Woodruff",
        "value": "Woodruff",
        "state": "AR"
    },
    {
        "code": "05149",
        "county": "Yell",
        "value": "Yell",
        "state": "AR"
    },
    {
        "code": "05000",
        "county": "Arkansas",
        "value": "Arkansas",
        "state": "AR"
    },
    {
        "code": "05001",
        "county": "Arkansas",
        "value": "Arkansas",
        "state": "AR"
    },
    {
        "code": "05003",
        "county": "Ashley",
        "value": "Ashley",
        "state": "AR"
    },
    {
        "code": "05005",
        "county": "Baxter",
        "value": "Baxter",
        "state": "AR"
    },
    {
        "code": "05007",
        "county": "Benton",
        "value": "Benton",
        "state": "AR"
    },
    {
        "code": "05009",
        "county": "Boone",
        "value": "Boone",
        "state": "AR"
    },
    {
        "code": "05011",
        "county": "Bradley",
        "value": "Bradley",
        "state": "AR"
    },
    {
        "code": "05013",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "AR"
    },
    {
        "code": "05015",
        "county": "Carroll",
        "value": "Carroll",
        "state": "AR"
    },
    {
        "code": "05017",
        "county": "Chicot",
        "value": "Chicot",
        "state": "AR"
    },
    {
        "code": "05019",
        "county": "Clark",
        "value": "Clark",
        "state": "AR"
    },
    {
        "code": "05021",
        "county": "Clay",
        "value": "Clay",
        "state": "AR"
    },
    {
        "code": "05023",
        "county": "Cleburne",
        "value": "Cleburne",
        "state": "AR"
    },
    {
        "code": "05025",
        "county": "Cleveland",
        "value": "Cleveland",
        "state": "AR"
    },
    {
        "code": "05027",
        "county": "Columbia",
        "value": "Columbia",
        "state": "AR"
    },
    {
        "code": "04000",
        "county": "Arizona",
        "value": "Arizona",
        "state": "AZ"
    },
    {
        "code": "04027",
        "county": "Yuma",
        "value": "Yuma",
        "state": "AZ"
    },
    {
        "code": "04003",
        "county": "Cochise",
        "value": "Cochise",
        "state": "AZ"
    },
    {
        "code": "04005",
        "county": "Coconino",
        "value": "Coconino",
        "state": "AZ"
    },
    {
        "code": "04007",
        "county": "Gila",
        "value": "Gila",
        "state": "AZ"
    },
    {
        "code": "04009",
        "county": "Graham",
        "value": "Graham",
        "state": "AZ"
    },
    {
        "code": "04011",
        "county": "Greenlee",
        "value": "Greenlee",
        "state": "AZ"
    },
    {
        "code": "04012",
        "county": "La Paz",
        "value": "La Paz",
        "state": "AZ"
    },
    {
        "code": "04013",
        "county": "Maricopa",
        "value": "Maricopa",
        "state": "AZ"
    },
    {
        "code": "04015",
        "county": "Mohave",
        "value": "Mohave",
        "state": "AZ"
    },
    {
        "code": "04017",
        "county": "Navajo",
        "value": "Navajo",
        "state": "AZ"
    },
    {
        "code": "04019",
        "county": "Pima",
        "value": "Pima",
        "state": "AZ"
    },
    {
        "code": "04021",
        "county": "Pinal",
        "value": "Pinal",
        "state": "AZ"
    },
    {
        "code": "04023",
        "county": "Santa Cruz",
        "value": "Santa Cruz",
        "state": "AZ"
    },
    {
        "code": "04025",
        "county": "Yavapai",
        "value": "Yavapai",
        "state": "AZ"
    },
    {
        "code": "04001",
        "county": "Apache",
        "value": "Apache",
        "state": "AZ"
    },
    {
        "code": "06000",
        "county": "California",
        "value": "California",
        "state": "CA"
    },
    {
        "code": "06115",
        "county": "Yuba",
        "value": "Yuba",
        "state": "CA"
    },
    {
        "code": "06003",
        "county": "Alpine",
        "value": "Alpine",
        "state": "CA"
    },
    {
        "code": "06005",
        "county": "Amador",
        "value": "Amador",
        "state": "CA"
    },
    {
        "code": "06007",
        "county": "Butte",
        "value": "Butte",
        "state": "CA"
    },
    {
        "code": "06009",
        "county": "Calaveras",
        "value": "Calaveras",
        "state": "CA"
    },
    {
        "code": "06011",
        "county": "Colusa",
        "value": "Colusa",
        "state": "CA"
    },
    {
        "code": "06013",
        "county": "Contra Costa",
        "value": "Contra Costa",
        "state": "CA"
    },
    {
        "code": "06015",
        "county": "Del Norte",
        "value": "Del Norte",
        "state": "CA"
    },
    {
        "code": "06017",
        "county": "El Dorado",
        "value": "El Dorado",
        "state": "CA"
    },
    {
        "code": "06019",
        "county": "Fresno",
        "value": "Fresno",
        "state": "CA"
    },
    {
        "code": "06021",
        "county": "Glenn",
        "value": "Glenn",
        "state": "CA"
    },
    {
        "code": "06023",
        "county": "Humboldt",
        "value": "Humboldt",
        "state": "CA"
    },
    {
        "code": "06025",
        "county": "Imperial",
        "value": "Imperial",
        "state": "CA"
    },
    {
        "code": "06027",
        "county": "Inyo",
        "value": "Inyo",
        "state": "CA"
    },
    {
        "code": "06029",
        "county": "Kern",
        "value": "Kern",
        "state": "CA"
    },
    {
        "code": "06031",
        "county": "Kings",
        "value": "Kings",
        "state": "CA"
    },
    {
        "code": "06033",
        "county": "Lake",
        "value": "Lake",
        "state": "CA"
    },
    {
        "code": "06035",
        "county": "Lassen",
        "value": "Lassen",
        "state": "CA"
    },
    {
        "code": "06037",
        "county": "Los Angeles",
        "value": "Los Angeles",
        "state": "CA"
    },
    {
        "code": "06039",
        "county": "Madera",
        "value": "Madera",
        "state": "CA"
    },
    {
        "code": "06041",
        "county": "Marin",
        "value": "Marin",
        "state": "CA"
    },
    {
        "code": "06043",
        "county": "Mariposa",
        "value": "Mariposa",
        "state": "CA"
    },
    {
        "code": "06045",
        "county": "Mendocino",
        "value": "Mendocino",
        "state": "CA"
    },
    {
        "code": "06047",
        "county": "Merced",
        "value": "Merced",
        "state": "CA"
    },
    {
        "code": "06049",
        "county": "Modoc",
        "value": "Modoc",
        "state": "CA"
    },
    {
        "code": "06051",
        "county": "Mono",
        "value": "Mono",
        "state": "CA"
    },
    {
        "code": "06053",
        "county": "Monterey",
        "value": "Monterey",
        "state": "CA"
    },
    {
        "code": "06055",
        "county": "Napa",
        "value": "Napa",
        "state": "CA"
    },
    {
        "code": "06057",
        "county": "Nevada",
        "value": "Nevada",
        "state": "CA"
    },
    {
        "code": "06059",
        "county": "Orange",
        "value": "Orange",
        "state": "CA"
    },
    {
        "code": "06061",
        "county": "Placer",
        "value": "Placer",
        "state": "CA"
    },
    {
        "code": "06063",
        "county": "Plumas",
        "value": "Plumas",
        "state": "CA"
    },
    {
        "code": "06065",
        "county": "Riverside",
        "value": "Riverside",
        "state": "CA"
    },
    {
        "code": "06067",
        "county": "Sacramento",
        "value": "Sacramento",
        "state": "CA"
    },
    {
        "code": "06069",
        "county": "San Benito",
        "value": "San Benito",
        "state": "CA"
    },
    {
        "code": "06071",
        "county": "San Bernardino",
        "value": "San Bernardino",
        "state": "CA"
    },
    {
        "code": "06073",
        "county": "San Diego",
        "value": "San Diego",
        "state": "CA"
    },
    {
        "code": "06075",
        "county": "San Francisco",
        "value": "San Francisco",
        "state": "CA"
    },
    {
        "code": "06077",
        "county": "San Joaquin",
        "value": "San Joaquin",
        "state": "CA"
    },
    {
        "code": "06079",
        "county": "San Luis Obispo",
        "value": "San Luis Obispo",
        "state": "CA"
    },
    {
        "code": "06081",
        "county": "San Mateo",
        "value": "San Mateo",
        "state": "CA"
    },
    {
        "code": "06083",
        "county": "Santa Barbara",
        "value": "Santa Barbara",
        "state": "CA"
    },
    {
        "code": "06085",
        "county": "Santa Clara",
        "value": "Santa Clara",
        "state": "CA"
    },
    {
        "code": "06087",
        "county": "Santa Cruz",
        "value": "Santa Cruz",
        "state": "CA"
    },
    {
        "code": "06089",
        "county": "Shasta",
        "value": "Shasta",
        "state": "CA"
    },
    {
        "code": "06091",
        "county": "Sierra",
        "value": "Sierra",
        "state": "CA"
    },
    {
        "code": "06093",
        "county": "Siskiyou",
        "value": "Siskiyou",
        "state": "CA"
    },
    {
        "code": "06095",
        "county": "Solano",
        "value": "Solano",
        "state": "CA"
    },
    {
        "code": "06097",
        "county": "Sonoma",
        "value": "Sonoma",
        "state": "CA"
    },
    {
        "code": "06099",
        "county": "Stanislaus",
        "value": "Stanislaus",
        "state": "CA"
    },
    {
        "code": "06101",
        "county": "Sutter",
        "value": "Sutter",
        "state": "CA"
    },
    {
        "code": "06103",
        "county": "Tehama",
        "value": "Tehama",
        "state": "CA"
    },
    {
        "code": "06105",
        "county": "Trinity",
        "value": "Trinity",
        "state": "CA"
    },
    {
        "code": "06107",
        "county": "Tulare",
        "value": "Tulare",
        "state": "CA"
    },
    {
        "code": "06109",
        "county": "Tuolumne",
        "value": "Tuolumne",
        "state": "CA"
    },
    {
        "code": "06111",
        "county": "Ventura",
        "value": "Ventura",
        "state": "CA"
    },
    {
        "code": "06113",
        "county": "Yolo",
        "value": "Yolo",
        "state": "CA"
    },
    {
        "code": "06001",
        "county": "Alameda",
        "value": "Alameda",
        "state": "CA"
    },
    {
        "code": "08009",
        "county": "Baca",
        "value": "Baca",
        "state": "CO"
    },
    {
        "code": "08011",
        "county": "Bent",
        "value": "Bent",
        "state": "CO"
    },
    {
        "code": "08013",
        "county": "Boulder",
        "value": "Boulder",
        "state": "CO"
    },
    {
        "code": "08015",
        "county": "Chaffee",
        "value": "Chaffee",
        "state": "CO"
    },
    {
        "code": "08017",
        "county": "Cheyenne",
        "value": "Cheyenne",
        "state": "CO"
    },
    {
        "code": "08019",
        "county": "Clear Creek",
        "value": "Clear Creek",
        "state": "CO"
    },
    {
        "code": "08021",
        "county": "Conejos",
        "value": "Conejos",
        "state": "CO"
    },
    {
        "code": "08023",
        "county": "Costilla",
        "value": "Costilla",
        "state": "CO"
    },
    {
        "code": "08025",
        "county": "Crowley",
        "value": "Crowley",
        "state": "CO"
    },
    {
        "code": "08027",
        "county": "Custer",
        "value": "Custer",
        "state": "CO"
    },
    {
        "code": "08029",
        "county": "Delta",
        "value": "Delta",
        "state": "CO"
    },
    {
        "code": "08031",
        "county": "Denver",
        "value": "Denver",
        "state": "CO"
    },
    {
        "code": "08033",
        "county": "Dolores",
        "value": "Dolores",
        "state": "CO"
    },
    {
        "code": "08035",
        "county": "Douglas",
        "value": "Douglas",
        "state": "CO"
    },
    {
        "code": "08037",
        "county": "Eagle",
        "value": "Eagle",
        "state": "CO"
    },
    {
        "code": "08039",
        "county": "Elbert",
        "value": "Elbert",
        "state": "CO"
    },
    {
        "code": "08041",
        "county": "El Paso",
        "value": "El Paso",
        "state": "CO"
    },
    {
        "code": "08043",
        "county": "Fremont",
        "value": "Fremont",
        "state": "CO"
    },
    {
        "code": "08045",
        "county": "Garfield",
        "value": "Garfield",
        "state": "CO"
    },
    {
        "code": "08047",
        "county": "Gilpin",
        "value": "Gilpin",
        "state": "CO"
    },
    {
        "code": "08049",
        "county": "Grand",
        "value": "Grand",
        "state": "CO"
    },
    {
        "code": "08051",
        "county": "Gunnison",
        "value": "Gunnison",
        "state": "CO"
    },
    {
        "code": "08053",
        "county": "Hinsdale",
        "value": "Hinsdale",
        "state": "CO"
    },
    {
        "code": "08055",
        "county": "Huerfano",
        "value": "Huerfano",
        "state": "CO"
    },
    {
        "code": "08057",
        "county": "Jackson",
        "value": "Jackson",
        "state": "CO"
    },
    {
        "code": "08059",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "CO"
    },
    {
        "code": "08061",
        "county": "Kiowa",
        "value": "Kiowa",
        "state": "CO"
    },
    {
        "code": "08063",
        "county": "Kit Carson",
        "value": "Kit Carson",
        "state": "CO"
    },
    {
        "code": "08065",
        "county": "Lake",
        "value": "Lake",
        "state": "CO"
    },
    {
        "code": "08067",
        "county": "La Plata",
        "value": "La Plata",
        "state": "CO"
    },
    {
        "code": "08069",
        "county": "Larimer",
        "value": "Larimer",
        "state": "CO"
    },
    {
        "code": "08071",
        "county": "Las Animas",
        "value": "Las Animas",
        "state": "CO"
    },
    {
        "code": "08073",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "CO"
    },
    {
        "code": "08075",
        "county": "Logan",
        "value": "Logan",
        "state": "CO"
    },
    {
        "code": "08077",
        "county": "Mesa",
        "value": "Mesa",
        "state": "CO"
    },
    {
        "code": "08079",
        "county": "Mineral",
        "value": "Mineral",
        "state": "CO"
    },
    {
        "code": "08081",
        "county": "Moffat",
        "value": "Moffat",
        "state": "CO"
    },
    {
        "code": "08083",
        "county": "Montezuma",
        "value": "Montezuma",
        "state": "CO"
    },
    {
        "code": "08085",
        "county": "Montrose",
        "value": "Montrose",
        "state": "CO"
    },
    {
        "code": "08087",
        "county": "Morgan",
        "value": "Morgan",
        "state": "CO"
    },
    {
        "code": "08089",
        "county": "Otero",
        "value": "Otero",
        "state": "CO"
    },
    {
        "code": "08091",
        "county": "Ouray",
        "value": "Ouray",
        "state": "CO"
    },
    {
        "code": "08093",
        "county": "Park",
        "value": "Park",
        "state": "CO"
    },
    {
        "code": "08095",
        "county": "Phillips",
        "value": "Phillips",
        "state": "CO"
    },
    {
        "code": "08097",
        "county": "Pitkin",
        "value": "Pitkin",
        "state": "CO"
    },
    {
        "code": "08099",
        "county": "Prowers",
        "value": "Prowers",
        "state": "CO"
    },
    {
        "code": "08101",
        "county": "Pueblo",
        "value": "Pueblo",
        "state": "CO"
    },
    {
        "code": "08103",
        "county": "Rio Blanco",
        "value": "Rio Blanco",
        "state": "CO"
    },
    {
        "code": "08105",
        "county": "Rio Grande",
        "value": "Rio Grande",
        "state": "CO"
    },
    {
        "code": "08107",
        "county": "Routt",
        "value": "Routt",
        "state": "CO"
    },
    {
        "code": "08109",
        "county": "Saguache",
        "value": "Saguache",
        "state": "CO"
    },
    {
        "code": "08111",
        "county": "San Juan",
        "value": "San Juan",
        "state": "CO"
    },
    {
        "code": "08113",
        "county": "San Miguel",
        "value": "San Miguel",
        "state": "CO"
    },
    {
        "code": "08115",
        "county": "Sedgwick",
        "value": "Sedgwick",
        "state": "CO"
    },
    {
        "code": "08117",
        "county": "Summit",
        "value": "Summit",
        "state": "CO"
    },
    {
        "code": "08119",
        "county": "Teller",
        "value": "Teller",
        "state": "CO"
    },
    {
        "code": "08121",
        "county": "Washington",
        "value": "Washington",
        "state": "CO"
    },
    {
        "code": "08123",
        "county": "Weld",
        "value": "Weld",
        "state": "CO"
    },
    {
        "code": "08125",
        "county": "Yuma",
        "value": "Yuma",
        "state": "CO"
    },
    {
        "code": "08000",
        "county": "Colorado",
        "value": "Colorado",
        "state": "CO"
    },
    {
        "code": "08001",
        "county": "Adams",
        "value": "Adams",
        "state": "CO"
    },
    {
        "code": "08003",
        "county": "Alamosa",
        "value": "Alamosa",
        "state": "CO"
    },
    {
        "code": "08005",
        "county": "Arapahoe",
        "value": "Arapahoe",
        "state": "CO"
    },
    {
        "code": "08007",
        "county": "Archuleta",
        "value": "Archuleta",
        "state": "CO"
    },
    {
        "code": "09009",
        "county": "New Haven",
        "value": "New Haven",
        "state": "CT"
    },
    {
        "code": "09011",
        "county": "New London",
        "value": "New London",
        "state": "CT"
    },
    {
        "code": "09013",
        "county": "Tolland",
        "value": "Tolland",
        "state": "CT"
    },
    {
        "code": "09015",
        "county": "Windham",
        "value": "Windham",
        "state": "CT"
    },
    {
        "code": "09007",
        "county": "Middlesex",
        "value": "Middlesex",
        "state": "CT"
    },
    {
        "code": "09001",
        "county": "Fairfield",
        "value": "Fairfield",
        "state": "CT"
    },
    {
        "code": "09003",
        "county": "Hartford",
        "value": "Hartford",
        "state": "CT"
    },
    {
        "code": "09005",
        "county": "Litchfield",
        "value": "Litchfield",
        "state": "CT"
    },
    {
        "code": "09000",
        "county": "Connecticut",
        "value": "Connecticut",
        "state": "CT"
    },
    {
        "code": "11000",
        "county": "District of Columbia",
        "value": "District of Columbia",
        "state": "DC"
    },
    {
        "code": "11001",
        "county": "District of Columbia",
        "value": "District of Columbia",
        "state": "DC"
    },
    {
        "code": "10005",
        "county": "Sussex",
        "value": "Sussex",
        "state": "DE"
    },
    {
        "code": "10001",
        "county": "Kent",
        "value": "Kent",
        "state": "DE"
    },
    {
        "code": "10000",
        "county": "Delaware",
        "value": "Delaware",
        "state": "DE"
    },
    {
        "code": "10003",
        "county": "New Castle",
        "value": "New Castle",
        "state": "DE"
    },
    {
        "code": "12127",
        "county": "Volusia",
        "value": "Volusia",
        "state": "FL"
    },
    {
        "code": "12129",
        "county": "Wakulla",
        "value": "Wakulla",
        "state": "FL"
    },
    {
        "code": "12131",
        "county": "Walton",
        "value": "Walton",
        "state": "FL"
    },
    {
        "code": "12133",
        "county": "Washington",
        "value": "Washington",
        "state": "FL"
    },
    {
        "code": "12000",
        "county": "Florida",
        "value": "Florida",
        "state": "FL"
    },
    {
        "code": "12001",
        "county": "Alachua",
        "value": "Alachua",
        "state": "FL"
    },
    {
        "code": "12003",
        "county": "Baker",
        "value": "Baker",
        "state": "FL"
    },
    {
        "code": "12005",
        "county": "Bay",
        "value": "Bay",
        "state": "FL"
    },
    {
        "code": "12007",
        "county": "Bradford",
        "value": "Bradford",
        "state": "FL"
    },
    {
        "code": "12009",
        "county": "Brevard",
        "value": "Brevard",
        "state": "FL"
    },
    {
        "code": "12011",
        "county": "Broward",
        "value": "Broward",
        "state": "FL"
    },
    {
        "code": "12013",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "FL"
    },
    {
        "code": "12015",
        "county": "Charlotte",
        "value": "Charlotte",
        "state": "FL"
    },
    {
        "code": "12017",
        "county": "Citrus",
        "value": "Citrus",
        "state": "FL"
    },
    {
        "code": "12019",
        "county": "Clay",
        "value": "Clay",
        "state": "FL"
    },
    {
        "code": "12021",
        "county": "Collier",
        "value": "Collier",
        "state": "FL"
    },
    {
        "code": "12023",
        "county": "Columbia",
        "value": "Columbia",
        "state": "FL"
    },
    {
        "code": "12025",
        "county": "Dade",
        "value": "Dade",
        "state": "FL"
    },
    {
        "code": "12027",
        "county": "DeSoto",
        "value": "DeSoto",
        "state": "FL"
    },
    {
        "code": "12029",
        "county": "Dixie",
        "value": "Dixie",
        "state": "FL"
    },
    {
        "code": "12031",
        "county": "Duval",
        "value": "Duval",
        "state": "FL"
    },
    {
        "code": "12033",
        "county": "Escambia",
        "value": "Escambia",
        "state": "FL"
    },
    {
        "code": "12035",
        "county": "Flagler",
        "value": "Flagler",
        "state": "FL"
    },
    {
        "code": "12037",
        "county": "Franklin",
        "value": "Franklin",
        "state": "FL"
    },
    {
        "code": "12039",
        "county": "Gadsden",
        "value": "Gadsden",
        "state": "FL"
    },
    {
        "code": "12041",
        "county": "Gilchrist",
        "value": "Gilchrist",
        "state": "FL"
    },
    {
        "code": "12043",
        "county": "Glades",
        "value": "Glades",
        "state": "FL"
    },
    {
        "code": "12045",
        "county": "Gulf",
        "value": "Gulf",
        "state": "FL"
    },
    {
        "code": "12047",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "FL"
    },
    {
        "code": "12049",
        "county": "Hardee",
        "value": "Hardee",
        "state": "FL"
    },
    {
        "code": "12051",
        "county": "Hendry",
        "value": "Hendry",
        "state": "FL"
    },
    {
        "code": "12053",
        "county": "Hernando",
        "value": "Hernando",
        "state": "FL"
    },
    {
        "code": "12055",
        "county": "Highlands",
        "value": "Highlands",
        "state": "FL"
    },
    {
        "code": "12057",
        "county": "Hillsborough",
        "value": "Hillsborough",
        "state": "FL"
    },
    {
        "code": "12059",
        "county": "Holmes",
        "value": "Holmes",
        "state": "FL"
    },
    {
        "code": "12061",
        "county": "Indian River",
        "value": "Indian River",
        "state": "FL"
    },
    {
        "code": "12063",
        "county": "Jackson",
        "value": "Jackson",
        "state": "FL"
    },
    {
        "code": "12065",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "FL"
    },
    {
        "code": "12067",
        "county": "Lafayette",
        "value": "Lafayette",
        "state": "FL"
    },
    {
        "code": "12069",
        "county": "Lake",
        "value": "Lake",
        "state": "FL"
    },
    {
        "code": "12071",
        "county": "Lee",
        "value": "Lee",
        "state": "FL"
    },
    {
        "code": "12073",
        "county": "Leon",
        "value": "Leon",
        "state": "FL"
    },
    {
        "code": "12075",
        "county": "Levy",
        "value": "Levy",
        "state": "FL"
    },
    {
        "code": "12077",
        "county": "Liberty",
        "value": "Liberty",
        "state": "FL"
    },
    {
        "code": "12079",
        "county": "Madison",
        "value": "Madison",
        "state": "FL"
    },
    {
        "code": "12081",
        "county": "Manatee",
        "value": "Manatee",
        "state": "FL"
    },
    {
        "code": "12083",
        "county": "Marion",
        "value": "Marion",
        "state": "FL"
    },
    {
        "code": "12085",
        "county": "Martin",
        "value": "Martin",
        "state": "FL"
    },
    {
        "code": "12087",
        "county": "Monroe",
        "value": "Monroe",
        "state": "FL"
    },
    {
        "code": "12089",
        "county": "Nassau",
        "value": "Nassau",
        "state": "FL"
    },
    {
        "code": "12091",
        "county": "Okaloosa",
        "value": "Okaloosa",
        "state": "FL"
    },
    {
        "code": "12093",
        "county": "Okeechobee",
        "value": "Okeechobee",
        "state": "FL"
    },
    {
        "code": "12095",
        "county": "Orange",
        "value": "Orange",
        "state": "FL"
    },
    {
        "code": "12097",
        "county": "Osceola",
        "value": "Osceola",
        "state": "FL"
    },
    {
        "code": "12099",
        "county": "Palm Beach",
        "value": "Palm Beach",
        "state": "FL"
    },
    {
        "code": "12101",
        "county": "Pasco",
        "value": "Pasco",
        "state": "FL"
    },
    {
        "code": "12103",
        "county": "Pinellas",
        "value": "Pinellas",
        "state": "FL"
    },
    {
        "code": "12105",
        "county": "Polk",
        "value": "Polk",
        "state": "FL"
    },
    {
        "code": "12107",
        "county": "Putnam",
        "value": "Putnam",
        "state": "FL"
    },
    {
        "code": "12109",
        "county": "St. Johns",
        "value": "St. Johns",
        "state": "FL"
    },
    {
        "code": "12111",
        "county": "St. Lucie",
        "value": "St. Lucie",
        "state": "FL"
    },
    {
        "code": "12113",
        "county": "Santa Rosa",
        "value": "Santa Rosa",
        "state": "FL"
    },
    {
        "code": "12115",
        "county": "Sarasota",
        "value": "Sarasota",
        "state": "FL"
    },
    {
        "code": "12117",
        "county": "Seminole",
        "value": "Seminole",
        "state": "FL"
    },
    {
        "code": "12119",
        "county": "Sumter",
        "value": "Sumter",
        "state": "FL"
    },
    {
        "code": "12121",
        "county": "Suwannee",
        "value": "Suwannee",
        "state": "FL"
    },
    {
        "code": "12123",
        "county": "Taylor",
        "value": "Taylor",
        "state": "FL"
    },
    {
        "code": "12125",
        "county": "Union",
        "value": "Union",
        "state": "FL"
    },
    {
        "code": "13261",
        "county": "Sumter",
        "value": "Sumter",
        "state": "GA"
    },
    {
        "code": "13263",
        "county": "Talbot",
        "value": "Talbot",
        "state": "GA"
    },
    {
        "code": "13265",
        "county": "Taliaferro",
        "value": "Taliaferro",
        "state": "GA"
    },
    {
        "code": "13267",
        "county": "Tattnall",
        "value": "Tattnall",
        "state": "GA"
    },
    {
        "code": "13269",
        "county": "Taylor",
        "value": "Taylor",
        "state": "GA"
    },
    {
        "code": "13271",
        "county": "Telfair",
        "value": "Telfair",
        "state": "GA"
    },
    {
        "code": "13273",
        "county": "Terrell",
        "value": "Terrell",
        "state": "GA"
    },
    {
        "code": "13275",
        "county": "Thomas",
        "value": "Thomas",
        "state": "GA"
    },
    {
        "code": "13277",
        "county": "Tift",
        "value": "Tift",
        "state": "GA"
    },
    {
        "code": "13279",
        "county": "Toombs",
        "value": "Toombs",
        "state": "GA"
    },
    {
        "code": "13281",
        "county": "Towns",
        "value": "Towns",
        "state": "GA"
    },
    {
        "code": "13283",
        "county": "Treutlen",
        "value": "Treutlen",
        "state": "GA"
    },
    {
        "code": "13285",
        "county": "Troup",
        "value": "Troup",
        "state": "GA"
    },
    {
        "code": "13287",
        "county": "Turner",
        "value": "Turner",
        "state": "GA"
    },
    {
        "code": "13289",
        "county": "Twiggs",
        "value": "Twiggs",
        "state": "GA"
    },
    {
        "code": "13291",
        "county": "Union",
        "value": "Union",
        "state": "GA"
    },
    {
        "code": "13293",
        "county": "Upson",
        "value": "Upson",
        "state": "GA"
    },
    {
        "code": "13295",
        "county": "Walker",
        "value": "Walker",
        "state": "GA"
    },
    {
        "code": "13297",
        "county": "Walton",
        "value": "Walton",
        "state": "GA"
    },
    {
        "code": "13299",
        "county": "Ware",
        "value": "Ware",
        "state": "GA"
    },
    {
        "code": "13301",
        "county": "Warren",
        "value": "Warren",
        "state": "GA"
    },
    {
        "code": "13303",
        "county": "Washington",
        "value": "Washington",
        "state": "GA"
    },
    {
        "code": "13305",
        "county": "Wayne",
        "value": "Wayne",
        "state": "GA"
    },
    {
        "code": "13307",
        "county": "Webster",
        "value": "Webster",
        "state": "GA"
    },
    {
        "code": "13309",
        "county": "Wheeler",
        "value": "Wheeler",
        "state": "GA"
    },
    {
        "code": "13311",
        "county": "White",
        "value": "White",
        "state": "GA"
    },
    {
        "code": "13313",
        "county": "Whitfield",
        "value": "Whitfield",
        "state": "GA"
    },
    {
        "code": "13315",
        "county": "Wilcox",
        "value": "Wilcox",
        "state": "GA"
    },
    {
        "code": "13317",
        "county": "Wilkes",
        "value": "Wilkes",
        "state": "GA"
    },
    {
        "code": "13319",
        "county": "Wilkinson",
        "value": "Wilkinson",
        "state": "GA"
    },
    {
        "code": "13321",
        "county": "Worth",
        "value": "Worth",
        "state": "GA"
    },
    {
        "code": "13097",
        "county": "Douglas",
        "value": "Douglas",
        "state": "GA"
    },
    {
        "code": "13131",
        "county": "Grady",
        "value": "Grady",
        "state": "GA"
    },
    {
        "code": "13133",
        "county": "Greene",
        "value": "Greene",
        "state": "GA"
    },
    {
        "code": "13135",
        "county": "Gwinnett",
        "value": "Gwinnett",
        "state": "GA"
    },
    {
        "code": "13137",
        "county": "Habersham",
        "value": "Habersham",
        "state": "GA"
    },
    {
        "code": "13139",
        "county": "Hall",
        "value": "Hall",
        "state": "GA"
    },
    {
        "code": "13141",
        "county": "Hancock",
        "value": "Hancock",
        "state": "GA"
    },
    {
        "code": "13143",
        "county": "Haralson",
        "value": "Haralson",
        "state": "GA"
    },
    {
        "code": "13145",
        "county": "Harris",
        "value": "Harris",
        "state": "GA"
    },
    {
        "code": "13147",
        "county": "Hart",
        "value": "Hart",
        "state": "GA"
    },
    {
        "code": "13149",
        "county": "Heard",
        "value": "Heard",
        "state": "GA"
    },
    {
        "code": "13151",
        "county": "Henry",
        "value": "Henry",
        "state": "GA"
    },
    {
        "code": "13153",
        "county": "Houston",
        "value": "Houston",
        "state": "GA"
    },
    {
        "code": "13155",
        "county": "Irwin",
        "value": "Irwin",
        "state": "GA"
    },
    {
        "code": "13157",
        "county": "Jackson",
        "value": "Jackson",
        "state": "GA"
    },
    {
        "code": "13159",
        "county": "Jasper",
        "value": "Jasper",
        "state": "GA"
    },
    {
        "code": "13161",
        "county": "Jeff Davis",
        "value": "Jeff Davis",
        "state": "GA"
    },
    {
        "code": "13163",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "GA"
    },
    {
        "code": "22123",
        "county": "West Carroll Parish",
        "value": "West Carroll Parish",
        "state": "LA"
    },
    {
        "code": "22125",
        "county": "West Feliciana Parish",
        "value": "West Feliciana Parish",
        "state": "LA"
    },
    {
        "code": "25000",
        "county": "Massachusetts",
        "value": "Massachusetts",
        "state": "MA"
    },
    {
        "code": "25027",
        "county": "Worcester",
        "value": "Worcester",
        "state": "MA"
    },
    {
        "code": "25003",
        "county": "Berkshire",
        "value": "Berkshire",
        "state": "MA"
    },
    {
        "code": "25005",
        "county": "Bristol",
        "value": "Bristol",
        "state": "MA"
    },
    {
        "code": "25007",
        "county": "Dukes",
        "value": "Dukes",
        "state": "MA"
    },
    {
        "code": "25009",
        "county": "Essex",
        "value": "Essex",
        "state": "MA"
    },
    {
        "code": "25011",
        "county": "Franklin",
        "value": "Franklin",
        "state": "MA"
    },
    {
        "code": "25013",
        "county": "Hampden",
        "value": "Hampden",
        "state": "MA"
    },
    {
        "code": "25015",
        "county": "Hampshire",
        "value": "Hampshire",
        "state": "MA"
    },
    {
        "code": "25017",
        "county": "Middlesex",
        "value": "Middlesex",
        "state": "MA"
    },
    {
        "code": "25019",
        "county": "Nantucket",
        "value": "Nantucket",
        "state": "MA"
    },
    {
        "code": "25021",
        "county": "Norfolk",
        "value": "Norfolk",
        "state": "MA"
    },
    {
        "code": "25023",
        "county": "Plymouth",
        "value": "Plymouth",
        "state": "MA"
    },
    {
        "code": "25025",
        "county": "Suffolk",
        "value": "Suffolk",
        "state": "MA"
    },
    {
        "code": "25001",
        "county": "Barnstable",
        "value": "Barnstable",
        "state": "MA"
    },
    {
        "code": "24000",
        "county": "Maryland",
        "value": "Maryland",
        "state": "MD"
    },
    {
        "code": "24510",
        "county": "Baltimore city",
        "value": "Baltimore city",
        "state": "MD"
    },
    {
        "code": "24003",
        "county": "Anne Arundel",
        "value": "Anne Arundel",
        "state": "MD"
    },
    {
        "code": "24005",
        "county": "Baltimore",
        "value": "Baltimore",
        "state": "MD"
    },
    {
        "code": "24009",
        "county": "Calvert",
        "value": "Calvert",
        "state": "MD"
    },
    {
        "code": "24011",
        "county": "Caroline",
        "value": "Caroline",
        "state": "MD"
    },
    {
        "code": "24013",
        "county": "Carroll",
        "value": "Carroll",
        "state": "MD"
    },
    {
        "code": "24015",
        "county": "Cecil",
        "value": "Cecil",
        "state": "MD"
    },
    {
        "code": "24017",
        "county": "Charles",
        "value": "Charles",
        "state": "MD"
    },
    {
        "code": "24019",
        "county": "Dorchester",
        "value": "Dorchester",
        "state": "MD"
    },
    {
        "code": "24021",
        "county": "Frederick",
        "value": "Frederick",
        "state": "MD"
    },
    {
        "code": "24023",
        "county": "Garrett",
        "value": "Garrett",
        "state": "MD"
    },
    {
        "code": "24025",
        "county": "Harford",
        "value": "Harford",
        "state": "MD"
    },
    {
        "code": "24027",
        "county": "Howard",
        "value": "Howard",
        "state": "MD"
    },
    {
        "code": "24029",
        "county": "Kent",
        "value": "Kent",
        "state": "MD"
    },
    {
        "code": "24031",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "MD"
    },
    {
        "code": "24033",
        "county": "Prince George",
        "value": "Prince George",
        "state": "MD"
    },
    {
        "code": "24035",
        "county": "Queen Anne",
        "value": "Queen Anne",
        "state": "MD"
    },
    {
        "code": "24037",
        "county": "St. Mary",
        "value": "St. Mary",
        "state": "MD"
    },
    {
        "code": "24039",
        "county": "Somerset",
        "value": "Somerset",
        "state": "MD"
    },
    {
        "code": "24041",
        "county": "Talbot",
        "value": "Talbot",
        "state": "MD"
    },
    {
        "code": "24043",
        "county": "Washington",
        "value": "Washington",
        "state": "MD"
    },
    {
        "code": "24045",
        "county": "Wicomico",
        "value": "Wicomico",
        "state": "MD"
    },
    {
        "code": "24047",
        "county": "Worcester",
        "value": "Worcester",
        "state": "MD"
    },
    {
        "code": "24001",
        "county": "Allegany",
        "value": "Allegany",
        "state": "MD"
    },
    {
        "code": "23000",
        "county": "Maine",
        "value": "Maine",
        "state": "ME"
    },
    {
        "code": "23031",
        "county": "York",
        "value": "York",
        "state": "ME"
    },
    {
        "code": "23003",
        "county": "Aroostook",
        "value": "Aroostook",
        "state": "ME"
    },
    {
        "code": "23005",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "ME"
    },
    {
        "code": "23007",
        "county": "Franklin",
        "value": "Franklin",
        "state": "ME"
    },
    {
        "code": "23009",
        "county": "Hancock",
        "value": "Hancock",
        "state": "ME"
    },
    {
        "code": "23011",
        "county": "Kennebec",
        "value": "Kennebec",
        "state": "ME"
    },
    {
        "code": "23013",
        "county": "Knox",
        "value": "Knox",
        "state": "ME"
    },
    {
        "code": "23015",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "ME"
    },
    {
        "code": "23017",
        "county": "Oxford",
        "value": "Oxford",
        "state": "ME"
    },
    {
        "code": "23019",
        "county": "Penobscot",
        "value": "Penobscot",
        "state": "ME"
    },
    {
        "code": "23021",
        "county": "Piscataquis",
        "value": "Piscataquis",
        "state": "ME"
    },
    {
        "code": "23023",
        "county": "Sagadahoc",
        "value": "Sagadahoc",
        "state": "ME"
    },
    {
        "code": "23025",
        "county": "Somerset",
        "value": "Somerset",
        "state": "ME"
    },
    {
        "code": "23027",
        "county": "Waldo",
        "value": "Waldo",
        "state": "ME"
    },
    {
        "code": "23029",
        "county": "Washington",
        "value": "Washington",
        "state": "ME"
    },
    {
        "code": "23001",
        "county": "Androscoggin",
        "value": "Androscoggin",
        "state": "ME"
    },
    {
        "code": "26115",
        "county": "Monroe",
        "value": "Monroe",
        "state": "MI"
    },
    {
        "code": "26117",
        "county": "Montcalm",
        "value": "Montcalm",
        "state": "MI"
    },
    {
        "code": "26119",
        "county": "Montmorency",
        "value": "Montmorency",
        "state": "MI"
    },
    {
        "code": "26121",
        "county": "Muskegon",
        "value": "Muskegon",
        "state": "MI"
    },
    {
        "code": "26123",
        "county": "Newaygo",
        "value": "Newaygo",
        "state": "MI"
    },
    {
        "code": "26125",
        "county": "Oakland",
        "value": "Oakland",
        "state": "MI"
    },
    {
        "code": "26127",
        "county": "Oceana",
        "value": "Oceana",
        "state": "MI"
    },
    {
        "code": "26129",
        "county": "Ogemaw",
        "value": "Ogemaw",
        "state": "MI"
    },
    {
        "code": "26131",
        "county": "Ontonagon",
        "value": "Ontonagon",
        "state": "MI"
    },
    {
        "code": "26133",
        "county": "Osceola",
        "value": "Osceola",
        "state": "MI"
    },
    {
        "code": "26135",
        "county": "Oscoda",
        "value": "Oscoda",
        "state": "MI"
    },
    {
        "code": "26137",
        "county": "Otsego",
        "value": "Otsego",
        "state": "MI"
    },
    {
        "code": "26139",
        "county": "Ottawa",
        "value": "Ottawa",
        "state": "MI"
    },
    {
        "code": "26000",
        "county": "Michigan",
        "value": "Michigan",
        "state": "MI"
    },
    {
        "code": "26001",
        "county": "Alcona",
        "value": "Alcona",
        "state": "MI"
    },
    {
        "code": "26003",
        "county": "Alger",
        "value": "Alger",
        "state": "MI"
    },
    {
        "code": "26005",
        "county": "Allegan",
        "value": "Allegan",
        "state": "MI"
    },
    {
        "code": "26007",
        "county": "Alpena",
        "value": "Alpena",
        "state": "MI"
    },
    {
        "code": "26009",
        "county": "Antrim",
        "value": "Antrim",
        "state": "MI"
    },
    {
        "code": "26011",
        "county": "Arenac",
        "value": "Arenac",
        "state": "MI"
    },
    {
        "code": "26141",
        "county": "Presque Isle",
        "value": "Presque Isle",
        "state": "MI"
    },
    {
        "code": "26143",
        "county": "Roscommon",
        "value": "Roscommon",
        "state": "MI"
    },
    {
        "code": "26145",
        "county": "Saginaw",
        "value": "Saginaw",
        "state": "MI"
    },
    {
        "code": "26147",
        "county": "St. Clair",
        "value": "St. Clair",
        "state": "MI"
    },
    {
        "code": "26149",
        "county": "St. Joseph",
        "value": "St. Joseph",
        "state": "MI"
    },
    {
        "code": "26151",
        "county": "Sanilac",
        "value": "Sanilac",
        "state": "MI"
    },
    {
        "code": "26153",
        "county": "Schoolcraft",
        "value": "Schoolcraft",
        "state": "MI"
    },
    {
        "code": "26155",
        "county": "Shiawassee",
        "value": "Shiawassee",
        "state": "MI"
    },
    {
        "code": "26157",
        "county": "Tuscola",
        "value": "Tuscola",
        "state": "MI"
    },
    {
        "code": "26159",
        "county": "Van Buren",
        "value": "Van Buren",
        "state": "MI"
    },
    {
        "code": "26161",
        "county": "Washtenaw",
        "value": "Washtenaw",
        "state": "MI"
    },
    {
        "code": "26163",
        "county": "Wayne",
        "value": "Wayne",
        "state": "MI"
    },
    {
        "code": "26165",
        "county": "Wexford",
        "value": "Wexford",
        "state": "MI"
    },
    {
        "code": "26013",
        "county": "Baraga",
        "value": "Baraga",
        "state": "MI"
    },
    {
        "code": "26015",
        "county": "Barry",
        "value": "Barry",
        "state": "MI"
    },
    {
        "code": "26017",
        "county": "Bay",
        "value": "Bay",
        "state": "MI"
    },
    {
        "code": "26019",
        "county": "Benzie",
        "value": "Benzie",
        "state": "MI"
    },
    {
        "code": "26021",
        "county": "Berrien",
        "value": "Berrien",
        "state": "MI"
    },
    {
        "code": "26023",
        "county": "Branch",
        "value": "Branch",
        "state": "MI"
    },
    {
        "code": "26025",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "MI"
    },
    {
        "code": "26027",
        "county": "Cass",
        "value": "Cass",
        "state": "MI"
    },
    {
        "code": "26029",
        "county": "Charlevoix",
        "value": "Charlevoix",
        "state": "MI"
    },
    {
        "code": "26031",
        "county": "Cheboygan",
        "value": "Cheboygan",
        "state": "MI"
    },
    {
        "code": "26033",
        "county": "Chippewa",
        "value": "Chippewa",
        "state": "MI"
    },
    {
        "code": "26035",
        "county": "Clare",
        "value": "Clare",
        "state": "MI"
    },
    {
        "code": "26037",
        "county": "Clinton",
        "value": "Clinton",
        "state": "MI"
    },
    {
        "code": "26039",
        "county": "Crawford",
        "value": "Crawford",
        "state": "MI"
    },
    {
        "code": "26041",
        "county": "Delta",
        "value": "Delta",
        "state": "MI"
    },
    {
        "code": "26043",
        "county": "Dickinson",
        "value": "Dickinson",
        "state": "MI"
    },
    {
        "code": "26045",
        "county": "Eaton",
        "value": "Eaton",
        "state": "MI"
    },
    {
        "code": "26047",
        "county": "Emmet",
        "value": "Emmet",
        "state": "MI"
    },
    {
        "code": "26049",
        "county": "Genesee",
        "value": "Genesee",
        "state": "MI"
    },
    {
        "code": "26051",
        "county": "Gladwin",
        "value": "Gladwin",
        "state": "MI"
    },
    {
        "code": "26053",
        "county": "Gogebic",
        "value": "Gogebic",
        "state": "MI"
    },
    {
        "code": "26055",
        "county": "Grand Traverse",
        "value": "Grand Traverse",
        "state": "MI"
    },
    {
        "code": "26057",
        "county": "Gratiot",
        "value": "Gratiot",
        "state": "MI"
    },
    {
        "code": "26059",
        "county": "Hillsdale",
        "value": "Hillsdale",
        "state": "MI"
    },
    {
        "code": "26061",
        "county": "Houghton",
        "value": "Houghton",
        "state": "MI"
    },
    {
        "code": "26063",
        "county": "Huron",
        "value": "Huron",
        "state": "MI"
    },
    {
        "code": "26065",
        "county": "Ingham",
        "value": "Ingham",
        "state": "MI"
    },
    {
        "code": "26067",
        "county": "Ionia",
        "value": "Ionia",
        "state": "MI"
    },
    {
        "code": "26069",
        "county": "Iosco",
        "value": "Iosco",
        "state": "MI"
    },
    {
        "code": "26071",
        "county": "Iron",
        "value": "Iron",
        "state": "MI"
    },
    {
        "code": "26073",
        "county": "Isabella",
        "value": "Isabella",
        "state": "MI"
    },
    {
        "code": "26075",
        "county": "Jackson",
        "value": "Jackson",
        "state": "MI"
    },
    {
        "code": "26077",
        "county": "Kalamazoo",
        "value": "Kalamazoo",
        "state": "MI"
    },
    {
        "code": "26079",
        "county": "Kalkaska",
        "value": "Kalkaska",
        "state": "MI"
    },
    {
        "code": "26081",
        "county": "Kent",
        "value": "Kent",
        "state": "MI"
    },
    {
        "code": "26083",
        "county": "Keweenaw",
        "value": "Keweenaw",
        "state": "MI"
    },
    {
        "code": "26085",
        "county": "Lake",
        "value": "Lake",
        "state": "MI"
    },
    {
        "code": "26087",
        "county": "Lapeer",
        "value": "Lapeer",
        "state": "MI"
    },
    {
        "code": "26089",
        "county": "Leelanau",
        "value": "Leelanau",
        "state": "MI"
    },
    {
        "code": "26091",
        "county": "Lenawee",
        "value": "Lenawee",
        "state": "MI"
    },
    {
        "code": "26093",
        "county": "Livingston",
        "value": "Livingston",
        "state": "MI"
    },
    {
        "code": "26095",
        "county": "Luce",
        "value": "Luce",
        "state": "MI"
    },
    {
        "code": "26097",
        "county": "Mackinac",
        "value": "Mackinac",
        "state": "MI"
    },
    {
        "code": "26099",
        "county": "Macomb",
        "value": "Macomb",
        "state": "MI"
    },
    {
        "code": "26101",
        "county": "Manistee",
        "value": "Manistee",
        "state": "MI"
    },
    {
        "code": "26103",
        "county": "Marquette",
        "value": "Marquette",
        "state": "MI"
    },
    {
        "code": "26105",
        "county": "Mason",
        "value": "Mason",
        "state": "MI"
    },
    {
        "code": "26107",
        "county": "Mecosta",
        "value": "Mecosta",
        "state": "MI"
    },
    {
        "code": "26109",
        "county": "Menominee",
        "value": "Menominee",
        "state": "MI"
    },
    {
        "code": "26111",
        "county": "Midland",
        "value": "Midland",
        "state": "MI"
    },
    {
        "code": "26113",
        "county": "Missaukee",
        "value": "Missaukee",
        "state": "MI"
    },
    {
        "code": "27053",
        "county": "Hennepin",
        "value": "Hennepin",
        "state": "MN"
    },
    {
        "code": "27055",
        "county": "Houston",
        "value": "Houston",
        "state": "MN"
    },
    {
        "code": "27057",
        "county": "Hubbard",
        "value": "Hubbard",
        "state": "MN"
    },
    {
        "code": "27059",
        "county": "Isanti",
        "value": "Isanti",
        "state": "MN"
    },
    {
        "code": "27061",
        "county": "Itasca",
        "value": "Itasca",
        "state": "MN"
    },
    {
        "code": "27063",
        "county": "Jackson",
        "value": "Jackson",
        "state": "MN"
    },
    {
        "code": "27065",
        "county": "Kanabec",
        "value": "Kanabec",
        "state": "MN"
    },
    {
        "code": "29067",
        "county": "Douglas",
        "value": "Douglas",
        "state": "MO"
    },
    {
        "code": "29069",
        "county": "Dunklin",
        "value": "Dunklin",
        "state": "MO"
    },
    {
        "code": "29071",
        "county": "Franklin",
        "value": "Franklin",
        "state": "MO"
    },
    {
        "code": "29073",
        "county": "Gasconade",
        "value": "Gasconade",
        "state": "MO"
    },
    {
        "code": "29075",
        "county": "Gentry",
        "value": "Gentry",
        "state": "MO"
    },
    {
        "code": "29077",
        "county": "Greene",
        "value": "Greene",
        "state": "MO"
    },
    {
        "code": "29079",
        "county": "Grundy",
        "value": "Grundy",
        "state": "MO"
    },
    {
        "code": "29081",
        "county": "Harrison",
        "value": "Harrison",
        "state": "MO"
    },
    {
        "code": "29083",
        "county": "Henry",
        "value": "Henry",
        "state": "MO"
    },
    {
        "code": "29085",
        "county": "Hickory",
        "value": "Hickory",
        "state": "MO"
    },
    {
        "code": "29087",
        "county": "Holt",
        "value": "Holt",
        "state": "MO"
    },
    {
        "code": "29089",
        "county": "Howard",
        "value": "Howard",
        "state": "MO"
    },
    {
        "code": "29091",
        "county": "Howell",
        "value": "Howell",
        "state": "MO"
    },
    {
        "code": "29093",
        "county": "Iron",
        "value": "Iron",
        "state": "MO"
    },
    {
        "code": "29095",
        "county": "Jackson",
        "value": "Jackson",
        "state": "MO"
    },
    {
        "code": "29097",
        "county": "Jasper",
        "value": "Jasper",
        "state": "MO"
    },
    {
        "code": "29099",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "MO"
    },
    {
        "code": "29101",
        "county": "Johnson",
        "value": "Johnson",
        "state": "MO"
    },
    {
        "code": "29103",
        "county": "Knox",
        "value": "Knox",
        "state": "MO"
    },
    {
        "code": "29105",
        "county": "Laclede",
        "value": "Laclede",
        "state": "MO"
    },
    {
        "code": "29107",
        "county": "Lafayette",
        "value": "Lafayette",
        "state": "MO"
    },
    {
        "code": "29109",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "MO"
    },
    {
        "code": "29111",
        "county": "Lewis",
        "value": "Lewis",
        "state": "MO"
    },
    {
        "code": "29113",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "MO"
    },
    {
        "code": "29115",
        "county": "Linn",
        "value": "Linn",
        "state": "MO"
    },
    {
        "code": "29117",
        "county": "Livingston",
        "value": "Livingston",
        "state": "MO"
    },
    {
        "code": "29119",
        "county": "McDonald",
        "value": "McDonald",
        "state": "MO"
    },
    {
        "code": "29121",
        "county": "Macon",
        "value": "Macon",
        "state": "MO"
    },
    {
        "code": "29123",
        "county": "Madison",
        "value": "Madison",
        "state": "MO"
    },
    {
        "code": "29125",
        "county": "Maries",
        "value": "Maries",
        "state": "MO"
    },
    {
        "code": "29127",
        "county": "Marion",
        "value": "Marion",
        "state": "MO"
    },
    {
        "code": "29129",
        "county": "Mercer",
        "value": "Mercer",
        "state": "MO"
    },
    {
        "code": "29131",
        "county": "Miller",
        "value": "Miller",
        "state": "MO"
    },
    {
        "code": "29133",
        "county": "Mississippi",
        "value": "Mississippi",
        "state": "MO"
    },
    {
        "code": "29135",
        "county": "Moniteau",
        "value": "Moniteau",
        "state": "MO"
    },
    {
        "code": "29137",
        "county": "Monroe",
        "value": "Monroe",
        "state": "MO"
    },
    {
        "code": "29139",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "MO"
    },
    {
        "code": "29141",
        "county": "Morgan",
        "value": "Morgan",
        "state": "MO"
    },
    {
        "code": "29000",
        "county": "Missouri",
        "value": "Missouri",
        "state": "MO"
    },
    {
        "code": "29001",
        "county": "Adair",
        "value": "Adair",
        "state": "MO"
    },
    {
        "code": "29003",
        "county": "Andrew",
        "value": "Andrew",
        "state": "MO"
    },
    {
        "code": "29005",
        "county": "Atchison",
        "value": "Atchison",
        "state": "MO"
    },
    {
        "code": "29007",
        "county": "Audrain",
        "value": "Audrain",
        "state": "MO"
    },
    {
        "code": "29009",
        "county": "Barry",
        "value": "Barry",
        "state": "MO"
    },
    {
        "code": "29011",
        "county": "Barton",
        "value": "Barton",
        "state": "MO"
    },
    {
        "code": "29013",
        "county": "Bates",
        "value": "Bates",
        "state": "MO"
    },
    {
        "code": "28015",
        "county": "Carroll",
        "value": "Carroll",
        "state": "MS"
    },
    {
        "code": "28017",
        "county": "Chickasaw",
        "value": "Chickasaw",
        "state": "MS"
    },
    {
        "code": "28019",
        "county": "Choctaw",
        "value": "Choctaw",
        "state": "MS"
    },
    {
        "code": "28021",
        "county": "Claiborne",
        "value": "Claiborne",
        "state": "MS"
    },
    {
        "code": "28023",
        "county": "Clarke",
        "value": "Clarke",
        "state": "MS"
    },
    {
        "code": "28025",
        "county": "Clay",
        "value": "Clay",
        "state": "MS"
    },
    {
        "code": "28027",
        "county": "Coahoma",
        "value": "Coahoma",
        "state": "MS"
    },
    {
        "code": "28029",
        "county": "Copiah",
        "value": "Copiah",
        "state": "MS"
    },
    {
        "code": "28031",
        "county": "Covington",
        "value": "Covington",
        "state": "MS"
    },
    {
        "code": "28033",
        "county": "DeSoto",
        "value": "DeSoto",
        "state": "MS"
    },
    {
        "code": "28035",
        "county": "Forrest",
        "value": "Forrest",
        "state": "MS"
    },
    {
        "code": "28037",
        "county": "Franklin",
        "value": "Franklin",
        "state": "MS"
    },
    {
        "code": "28039",
        "county": "George",
        "value": "George",
        "state": "MS"
    },
    {
        "code": "28041",
        "county": "Greene",
        "value": "Greene",
        "state": "MS"
    },
    {
        "code": "28043",
        "county": "Grenada",
        "value": "Grenada",
        "state": "MS"
    },
    {
        "code": "28045",
        "county": "Hancock",
        "value": "Hancock",
        "state": "MS"
    },
    {
        "code": "28047",
        "county": "Harrison",
        "value": "Harrison",
        "state": "MS"
    },
    {
        "code": "28049",
        "county": "Hinds",
        "value": "Hinds",
        "state": "MS"
    },
    {
        "code": "28051",
        "county": "Holmes",
        "value": "Holmes",
        "state": "MS"
    },
    {
        "code": "28053",
        "county": "Humphreys",
        "value": "Humphreys",
        "state": "MS"
    },
    {
        "code": "28055",
        "county": "Issaquena",
        "value": "Issaquena",
        "state": "MS"
    },
    {
        "code": "28057",
        "county": "Itawamba",
        "value": "Itawamba",
        "state": "MS"
    },
    {
        "code": "28059",
        "county": "Jackson",
        "value": "Jackson",
        "state": "MS"
    },
    {
        "code": "28061",
        "county": "Jasper",
        "value": "Jasper",
        "state": "MS"
    },
    {
        "code": "28063",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "MS"
    },
    {
        "code": "28065",
        "county": "Jefferson Davis",
        "value": "Jefferson Davis",
        "state": "MS"
    },
    {
        "code": "28067",
        "county": "Jones",
        "value": "Jones",
        "state": "MS"
    },
    {
        "code": "28069",
        "county": "Kemper",
        "value": "Kemper",
        "state": "MS"
    },
    {
        "code": "28071",
        "county": "Lafayette",
        "value": "Lafayette",
        "state": "MS"
    },
    {
        "code": "28073",
        "county": "Lamar",
        "value": "Lamar",
        "state": "MS"
    },
    {
        "code": "28075",
        "county": "Lauderdale",
        "value": "Lauderdale",
        "state": "MS"
    },
    {
        "code": "28077",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "MS"
    },
    {
        "code": "28079",
        "county": "Leake",
        "value": "Leake",
        "state": "MS"
    },
    {
        "code": "28081",
        "county": "Lee",
        "value": "Lee",
        "state": "MS"
    },
    {
        "code": "28083",
        "county": "Leflore",
        "value": "Leflore",
        "state": "MS"
    },
    {
        "code": "28085",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "MS"
    },
    {
        "code": "28087",
        "county": "Lowndes",
        "value": "Lowndes",
        "state": "MS"
    },
    {
        "code": "28089",
        "county": "Madison",
        "value": "Madison",
        "state": "MS"
    },
    {
        "code": "28091",
        "county": "Marion",
        "value": "Marion",
        "state": "MS"
    },
    {
        "code": "28093",
        "county": "Marshall",
        "value": "Marshall",
        "state": "MS"
    },
    {
        "code": "28095",
        "county": "Monroe",
        "value": "Monroe",
        "state": "MS"
    },
    {
        "code": "28097",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "MS"
    },
    {
        "code": "28099",
        "county": "Neshoba",
        "value": "Neshoba",
        "state": "MS"
    },
    {
        "code": "28101",
        "county": "Newton",
        "value": "Newton",
        "state": "MS"
    },
    {
        "code": "28103",
        "county": "Noxubee",
        "value": "Noxubee",
        "state": "MS"
    },
    {
        "code": "28105",
        "county": "Oktibbeha",
        "value": "Oktibbeha",
        "state": "MS"
    },
    {
        "code": "28107",
        "county": "Panola",
        "value": "Panola",
        "state": "MS"
    },
    {
        "code": "28109",
        "county": "Pearl River",
        "value": "Pearl River",
        "state": "MS"
    },
    {
        "code": "28111",
        "county": "Perry",
        "value": "Perry",
        "state": "MS"
    },
    {
        "code": "28113",
        "county": "Pike",
        "value": "Pike",
        "state": "MS"
    },
    {
        "code": "28115",
        "county": "Pontotoc",
        "value": "Pontotoc",
        "state": "MS"
    },
    {
        "code": "28117",
        "county": "Prentiss",
        "value": "Prentiss",
        "state": "MS"
    },
    {
        "code": "28119",
        "county": "Quitman",
        "value": "Quitman",
        "state": "MS"
    },
    {
        "code": "28121",
        "county": "Rankin",
        "value": "Rankin",
        "state": "MS"
    },
    {
        "code": "28123",
        "county": "Scott",
        "value": "Scott",
        "state": "MS"
    },
    {
        "code": "28125",
        "county": "Sharkey",
        "value": "Sharkey",
        "state": "MS"
    },
    {
        "code": "28127",
        "county": "Simpson",
        "value": "Simpson",
        "state": "MS"
    },
    {
        "code": "28129",
        "county": "Smith",
        "value": "Smith",
        "state": "MS"
    },
    {
        "code": "28131",
        "county": "Stone",
        "value": "Stone",
        "state": "MS"
    },
    {
        "code": "28133",
        "county": "Sunflower",
        "value": "Sunflower",
        "state": "MS"
    },
    {
        "code": "28135",
        "county": "Tallahatchie",
        "value": "Tallahatchie",
        "state": "MS"
    },
    {
        "code": "28137",
        "county": "Tate",
        "value": "Tate",
        "state": "MS"
    },
    {
        "code": "28139",
        "county": "Tippah",
        "value": "Tippah",
        "state": "MS"
    },
    {
        "code": "28141",
        "county": "Tishomingo",
        "value": "Tishomingo",
        "state": "MS"
    },
    {
        "code": "28143",
        "county": "Tunica",
        "value": "Tunica",
        "state": "MS"
    },
    {
        "code": "28145",
        "county": "Union",
        "value": "Union",
        "state": "MS"
    },
    {
        "code": "28147",
        "county": "Walthall",
        "value": "Walthall",
        "state": "MS"
    },
    {
        "code": "28149",
        "county": "Warren",
        "value": "Warren",
        "state": "MS"
    },
    {
        "code": "28151",
        "county": "Washington",
        "value": "Washington",
        "state": "MS"
    },
    {
        "code": "28153",
        "county": "Wayne",
        "value": "Wayne",
        "state": "MS"
    },
    {
        "code": "28155",
        "county": "Webster",
        "value": "Webster",
        "state": "MS"
    },
    {
        "code": "28157",
        "county": "Wilkinson",
        "value": "Wilkinson",
        "state": "MS"
    },
    {
        "code": "28159",
        "county": "Winston",
        "value": "Winston",
        "state": "MS"
    },
    {
        "code": "28161",
        "county": "Yalobusha",
        "value": "Yalobusha",
        "state": "MS"
    },
    {
        "code": "28163",
        "county": "Yazoo",
        "value": "Yazoo",
        "state": "MS"
    },
    {
        "code": "28000",
        "county": "Mississippi",
        "value": "Mississippi",
        "state": "MS"
    },
    {
        "code": "28001",
        "county": "Adams",
        "value": "Adams",
        "state": "MS"
    },
    {
        "code": "28003",
        "county": "Alcorn",
        "value": "Alcorn",
        "state": "MS"
    },
    {
        "code": "28005",
        "county": "Amite",
        "value": "Amite",
        "state": "MS"
    },
    {
        "code": "28007",
        "county": "Attala",
        "value": "Attala",
        "state": "MS"
    },
    {
        "code": "28009",
        "county": "Benton",
        "value": "Benton",
        "state": "MS"
    },
    {
        "code": "28011",
        "county": "Bolivar",
        "value": "Bolivar",
        "state": "MS"
    },
    {
        "code": "28013",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "MS"
    },
    {
        "code": "30039",
        "county": "Granite",
        "value": "Granite",
        "state": "MT"
    },
    {
        "code": "30041",
        "county": "Hill",
        "value": "Hill",
        "state": "MT"
    },
    {
        "code": "30043",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "MT"
    },
    {
        "code": "30045",
        "county": "Judith Basin",
        "value": "Judith Basin",
        "state": "MT"
    },
    {
        "code": "30047",
        "county": "Lake",
        "value": "Lake",
        "state": "MT"
    },
    {
        "code": "30049",
        "county": "Lewis and Clark",
        "value": "Lewis and Clark",
        "state": "MT"
    },
    {
        "code": "30051",
        "county": "Liberty",
        "value": "Liberty",
        "state": "MT"
    },
    {
        "code": "30053",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "MT"
    },
    {
        "code": "30055",
        "county": "McCone",
        "value": "McCone",
        "state": "MT"
    },
    {
        "code": "30057",
        "county": "Madison",
        "value": "Madison",
        "state": "MT"
    },
    {
        "code": "30059",
        "county": "Meagher",
        "value": "Meagher",
        "state": "MT"
    },
    {
        "code": "30061",
        "county": "Mineral",
        "value": "Mineral",
        "state": "MT"
    },
    {
        "code": "30063",
        "county": "Missoula",
        "value": "Missoula",
        "state": "MT"
    },
    {
        "code": "30065",
        "county": "Musselshell",
        "value": "Musselshell",
        "state": "MT"
    },
    {
        "code": "30067",
        "county": "Park",
        "value": "Park",
        "state": "MT"
    },
    {
        "code": "30069",
        "county": "Petroleum",
        "value": "Petroleum",
        "state": "MT"
    },
    {
        "code": "30071",
        "county": "Phillips",
        "value": "Phillips",
        "state": "MT"
    },
    {
        "code": "30073",
        "county": "Pondera",
        "value": "Pondera",
        "state": "MT"
    },
    {
        "code": "30075",
        "county": "Powder River",
        "value": "Powder River",
        "state": "MT"
    },
    {
        "code": "30077",
        "county": "Powell",
        "value": "Powell",
        "state": "MT"
    },
    {
        "code": "30079",
        "county": "Prairie",
        "value": "Prairie",
        "state": "MT"
    },
    {
        "code": "30081",
        "county": "Ravalli",
        "value": "Ravalli",
        "state": "MT"
    },
    {
        "code": "38001",
        "county": "Adams",
        "value": "Adams",
        "state": "ND"
    },
    {
        "code": "38003",
        "county": "Barnes",
        "value": "Barnes",
        "state": "ND"
    },
    {
        "code": "38005",
        "county": "Benson",
        "value": "Benson",
        "state": "ND"
    },
    {
        "code": "38007",
        "county": "Billings",
        "value": "Billings",
        "state": "ND"
    },
    {
        "code": "38009",
        "county": "Bottineau",
        "value": "Bottineau",
        "state": "ND"
    },
    {
        "code": "38011",
        "county": "Bowman",
        "value": "Bowman",
        "state": "ND"
    },
    {
        "code": "38013",
        "county": "Burke",
        "value": "Burke",
        "state": "ND"
    },
    {
        "code": "38015",
        "county": "Burleigh",
        "value": "Burleigh",
        "state": "ND"
    },
    {
        "code": "38017",
        "county": "Cass",
        "value": "Cass",
        "state": "ND"
    },
    {
        "code": "38019",
        "county": "Cavalier",
        "value": "Cavalier",
        "state": "ND"
    },
    {
        "code": "38021",
        "county": "Dickey",
        "value": "Dickey",
        "state": "ND"
    },
    {
        "code": "38023",
        "county": "Divide",
        "value": "Divide",
        "state": "ND"
    },
    {
        "code": "38025",
        "county": "Dunn",
        "value": "Dunn",
        "state": "ND"
    },
    {
        "code": "38027",
        "county": "Eddy",
        "value": "Eddy",
        "state": "ND"
    },
    {
        "code": "38029",
        "county": "Emmons",
        "value": "Emmons",
        "state": "ND"
    },
    {
        "code": "38031",
        "county": "Foster",
        "value": "Foster",
        "state": "ND"
    },
    {
        "code": "38033",
        "county": "Golden Valley",
        "value": "Golden Valley",
        "state": "ND"
    },
    {
        "code": "38035",
        "county": "Grand Forks",
        "value": "Grand Forks",
        "state": "ND"
    },
    {
        "code": "38037",
        "county": "Grant",
        "value": "Grant",
        "state": "ND"
    },
    {
        "code": "38039",
        "county": "Griggs",
        "value": "Griggs",
        "state": "ND"
    },
    {
        "code": "38041",
        "county": "Hettinger",
        "value": "Hettinger",
        "state": "ND"
    },
    {
        "code": "38043",
        "county": "Kidder",
        "value": "Kidder",
        "state": "ND"
    },
    {
        "code": "38045",
        "county": "LaMoure",
        "value": "LaMoure",
        "state": "ND"
    },
    {
        "code": "38047",
        "county": "Logan",
        "value": "Logan",
        "state": "ND"
    },
    {
        "code": "38049",
        "county": "McHenry",
        "value": "McHenry",
        "state": "ND"
    },
    {
        "code": "38051",
        "county": "McIntosh",
        "value": "McIntosh",
        "state": "ND"
    },
    {
        "code": "38053",
        "county": "McKenzie",
        "value": "McKenzie",
        "state": "ND"
    },
    {
        "code": "38055",
        "county": "McLean",
        "value": "McLean",
        "state": "ND"
    },
    {
        "code": "38057",
        "county": "Mercer",
        "value": "Mercer",
        "state": "ND"
    },
    {
        "code": "38059",
        "county": "Morton",
        "value": "Morton",
        "state": "ND"
    },
    {
        "code": "38061",
        "county": "Mountrail",
        "value": "Mountrail",
        "state": "ND"
    },
    {
        "code": "38063",
        "county": "Nelson",
        "value": "Nelson",
        "state": "ND"
    },
    {
        "code": "38065",
        "county": "Oliver",
        "value": "Oliver",
        "state": "ND"
    },
    {
        "code": "38067",
        "county": "Pembina",
        "value": "Pembina",
        "state": "ND"
    },
    {
        "code": "38069",
        "county": "Pierce",
        "value": "Pierce",
        "state": "ND"
    },
    {
        "code": "38071",
        "county": "Ramsey",
        "value": "Ramsey",
        "state": "ND"
    },
    {
        "code": "38073",
        "county": "Ransom",
        "value": "Ransom",
        "state": "ND"
    },
    {
        "code": "38075",
        "county": "Renville",
        "value": "Renville",
        "state": "ND"
    },
    {
        "code": "38077",
        "county": "Richland",
        "value": "Richland",
        "state": "ND"
    },
    {
        "code": "38079",
        "county": "Rolette",
        "value": "Rolette",
        "state": "ND"
    },
    {
        "code": "38085",
        "county": "Sioux",
        "value": "Sioux",
        "state": "ND"
    },
    {
        "code": "31127",
        "county": "Nemaha",
        "value": "Nemaha",
        "state": "NE"
    },
    {
        "code": "31129",
        "county": "Nuckolls",
        "value": "Nuckolls",
        "state": "NE"
    },
    {
        "code": "31131",
        "county": "Otoe",
        "value": "Otoe",
        "state": "NE"
    },
    {
        "code": "31133",
        "county": "Pawnee",
        "value": "Pawnee",
        "state": "NE"
    },
    {
        "code": "31135",
        "county": "Perkins",
        "value": "Perkins",
        "state": "NE"
    },
    {
        "code": "31137",
        "county": "Phelps",
        "value": "Phelps",
        "state": "NE"
    },
    {
        "code": "31139",
        "county": "Pierce",
        "value": "Pierce",
        "state": "NE"
    },
    {
        "code": "31141",
        "county": "Platte",
        "value": "Platte",
        "state": "NE"
    },
    {
        "code": "31143",
        "county": "Polk",
        "value": "Polk",
        "state": "NE"
    },
    {
        "code": "31145",
        "county": "Red Willow",
        "value": "Red Willow",
        "state": "NE"
    },
    {
        "code": "31147",
        "county": "Richardson",
        "value": "Richardson",
        "state": "NE"
    },
    {
        "code": "31149",
        "county": "Rock",
        "value": "Rock",
        "state": "NE"
    },
    {
        "code": "31151",
        "county": "Saline",
        "value": "Saline",
        "state": "NE"
    },
    {
        "code": "31153",
        "county": "Sarpy",
        "value": "Sarpy",
        "state": "NE"
    },
    {
        "code": "31155",
        "county": "Saunders",
        "value": "Saunders",
        "state": "NE"
    },
    {
        "code": "31157",
        "county": "Scotts Bluff",
        "value": "Scotts Bluff",
        "state": "NE"
    },
    {
        "code": "31159",
        "county": "Seward",
        "value": "Seward",
        "state": "NE"
    },
    {
        "code": "31161",
        "county": "Sheridan",
        "value": "Sheridan",
        "state": "NE"
    },
    {
        "code": "31163",
        "county": "Sherman",
        "value": "Sherman",
        "state": "NE"
    },
    {
        "code": "31165",
        "county": "Sioux",
        "value": "Sioux",
        "state": "NE"
    },
    {
        "code": "31167",
        "county": "Stanton",
        "value": "Stanton",
        "state": "NE"
    },
    {
        "code": "31169",
        "county": "Thayer",
        "value": "Thayer",
        "state": "NE"
    },
    {
        "code": "31171",
        "county": "Thomas",
        "value": "Thomas",
        "state": "NE"
    },
    {
        "code": "31173",
        "county": "Thurston",
        "value": "Thurston",
        "state": "NE"
    },
    {
        "code": "31175",
        "county": "Valley",
        "value": "Valley",
        "state": "NE"
    },
    {
        "code": "31177",
        "county": "Washington",
        "value": "Washington",
        "state": "NE"
    },
    {
        "code": "31179",
        "county": "Wayne",
        "value": "Wayne",
        "state": "NE"
    },
    {
        "code": "31181",
        "county": "Webster",
        "value": "Webster",
        "state": "NE"
    },
    {
        "code": "31183",
        "county": "Wheeler",
        "value": "Wheeler",
        "state": "NE"
    },
    {
        "code": "31185",
        "county": "York",
        "value": "York",
        "state": "NE"
    },
    {
        "code": "31000",
        "county": "Nebraska",
        "value": "Nebraska",
        "state": "NE"
    },
    {
        "code": "31001",
        "county": "Adams",
        "value": "Adams",
        "state": "NE"
    },
    {
        "code": "31003",
        "county": "Antelope",
        "value": "Antelope",
        "state": "NE"
    },
    {
        "code": "31005",
        "county": "Arthur",
        "value": "Arthur",
        "state": "NE"
    },
    {
        "code": "31007",
        "county": "Banner",
        "value": "Banner",
        "state": "NE"
    },
    {
        "code": "31009",
        "county": "Blaine",
        "value": "Blaine",
        "state": "NE"
    },
    {
        "code": "31011",
        "county": "Boone",
        "value": "Boone",
        "state": "NE"
    },
    {
        "code": "31013",
        "county": "Box Butte",
        "value": "Box Butte",
        "state": "NE"
    },
    {
        "code": "31015",
        "county": "Boyd",
        "value": "Boyd",
        "state": "NE"
    },
    {
        "code": "31017",
        "county": "Brown",
        "value": "Brown",
        "state": "NE"
    },
    {
        "code": "31019",
        "county": "Buffalo",
        "value": "Buffalo",
        "state": "NE"
    },
    {
        "code": "31021",
        "county": "Burt",
        "value": "Burt",
        "state": "NE"
    },
    {
        "code": "31023",
        "county": "Butler",
        "value": "Butler",
        "state": "NE"
    },
    {
        "code": "31025",
        "county": "Cass",
        "value": "Cass",
        "state": "NE"
    },
    {
        "code": "31027",
        "county": "Cedar",
        "value": "Cedar",
        "state": "NE"
    },
    {
        "code": "31029",
        "county": "Chase",
        "value": "Chase",
        "state": "NE"
    },
    {
        "code": "31031",
        "county": "Cherry",
        "value": "Cherry",
        "state": "NE"
    },
    {
        "code": "31033",
        "county": "Cheyenne",
        "value": "Cheyenne",
        "state": "NE"
    },
    {
        "code": "31035",
        "county": "Clay",
        "value": "Clay",
        "state": "NE"
    },
    {
        "code": "31037",
        "county": "Colfax",
        "value": "Colfax",
        "state": "NE"
    },
    {
        "code": "31039",
        "county": "Cuming",
        "value": "Cuming",
        "state": "NE"
    },
    {
        "code": "31041",
        "county": "Custer",
        "value": "Custer",
        "state": "NE"
    },
    {
        "code": "31043",
        "county": "Dakota",
        "value": "Dakota",
        "state": "NE"
    },
    {
        "code": "31045",
        "county": "Dawes",
        "value": "Dawes",
        "state": "NE"
    },
    {
        "code": "31047",
        "county": "Dawson",
        "value": "Dawson",
        "state": "NE"
    },
    {
        "code": "31049",
        "county": "Deuel",
        "value": "Deuel",
        "state": "NE"
    },
    {
        "code": "31051",
        "county": "Dixon",
        "value": "Dixon",
        "state": "NE"
    },
    {
        "code": "31053",
        "county": "Dodge",
        "value": "Dodge",
        "state": "NE"
    },
    {
        "code": "31055",
        "county": "Douglas",
        "value": "Douglas",
        "state": "NE"
    },
    {
        "code": "31057",
        "county": "Dundy",
        "value": "Dundy",
        "state": "NE"
    },
    {
        "code": "31059",
        "county": "Fillmore",
        "value": "Fillmore",
        "state": "NE"
    },
    {
        "code": "31061",
        "county": "Franklin",
        "value": "Franklin",
        "state": "NE"
    },
    {
        "code": "31063",
        "county": "Frontier",
        "value": "Frontier",
        "state": "NE"
    },
    {
        "code": "31065",
        "county": "Furnas",
        "value": "Furnas",
        "state": "NE"
    },
    {
        "code": "31067",
        "county": "Gage",
        "value": "Gage",
        "state": "NE"
    },
    {
        "code": "31069",
        "county": "Garden",
        "value": "Garden",
        "state": "NE"
    },
    {
        "code": "31071",
        "county": "Garfield",
        "value": "Garfield",
        "state": "NE"
    },
    {
        "code": "31073",
        "county": "Gosper",
        "value": "Gosper",
        "state": "NE"
    },
    {
        "code": "31075",
        "county": "Grant",
        "value": "Grant",
        "state": "NE"
    },
    {
        "code": "31077",
        "county": "Greeley",
        "value": "Greeley",
        "state": "NE"
    },
    {
        "code": "31079",
        "county": "Hall",
        "value": "Hall",
        "state": "NE"
    },
    {
        "code": "31081",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "NE"
    },
    {
        "code": "31083",
        "county": "Harlan",
        "value": "Harlan",
        "state": "NE"
    },
    {
        "code": "31085",
        "county": "Hayes",
        "value": "Hayes",
        "state": "NE"
    },
    {
        "code": "31087",
        "county": "Hitchcock",
        "value": "Hitchcock",
        "state": "NE"
    },
    {
        "code": "31089",
        "county": "Holt",
        "value": "Holt",
        "state": "NE"
    },
    {
        "code": "31091",
        "county": "Hooker",
        "value": "Hooker",
        "state": "NE"
    },
    {
        "code": "31093",
        "county": "Howard",
        "value": "Howard",
        "state": "NE"
    },
    {
        "code": "31095",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "NE"
    },
    {
        "code": "31097",
        "county": "Johnson",
        "value": "Johnson",
        "state": "NE"
    },
    {
        "code": "31099",
        "county": "Kearney",
        "value": "Kearney",
        "state": "NE"
    },
    {
        "code": "31101",
        "county": "Keith",
        "value": "Keith",
        "state": "NE"
    },
    {
        "code": "31103",
        "county": "Keya Paha",
        "value": "Keya Paha",
        "state": "NE"
    },
    {
        "code": "31105",
        "county": "Kimball",
        "value": "Kimball",
        "state": "NE"
    },
    {
        "code": "31107",
        "county": "Knox",
        "value": "Knox",
        "state": "NE"
    },
    {
        "code": "31109",
        "county": "Lancaster",
        "value": "Lancaster",
        "state": "NE"
    },
    {
        "code": "31111",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "NE"
    },
    {
        "code": "31113",
        "county": "Logan",
        "value": "Logan",
        "state": "NE"
    },
    {
        "code": "31115",
        "county": "Loup",
        "value": "Loup",
        "state": "NE"
    },
    {
        "code": "31117",
        "county": "McPherson",
        "value": "McPherson",
        "state": "NE"
    },
    {
        "code": "31119",
        "county": "Madison",
        "value": "Madison",
        "state": "NE"
    },
    {
        "code": "31121",
        "county": "Merrick",
        "value": "Merrick",
        "state": "NE"
    },
    {
        "code": "31123",
        "county": "Morrill",
        "value": "Morrill",
        "state": "NE"
    },
    {
        "code": "31125",
        "county": "Nance",
        "value": "Nance",
        "state": "NE"
    },
    {
        "code": "33000",
        "county": "New Hampshire",
        "value": "New Hampshire",
        "state": "NH"
    },
    {
        "code": "33001",
        "county": "Belknap",
        "value": "Belknap",
        "state": "NH"
    },
    {
        "code": "33003",
        "county": "Carroll",
        "value": "Carroll",
        "state": "NH"
    },
    {
        "code": "33005",
        "county": "Cheshire",
        "value": "Cheshire",
        "state": "NH"
    },
    {
        "code": "33007",
        "county": "Coos",
        "value": "Coos",
        "state": "NH"
    },
    {
        "code": "33019",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "NH"
    },
    {
        "code": "33011",
        "county": "Hillsborough",
        "value": "Hillsborough",
        "state": "NH"
    },
    {
        "code": "33013",
        "county": "Merrimack",
        "value": "Merrimack",
        "state": "NH"
    },
    {
        "code": "33015",
        "county": "Rockingham",
        "value": "Rockingham",
        "state": "NH"
    },
    {
        "code": "33017",
        "county": "Strafford",
        "value": "Strafford",
        "state": "NH"
    },
    {
        "code": "33009",
        "county": "Grafton",
        "value": "Grafton",
        "state": "NH"
    },
    {
        "code": "34009",
        "county": "Cape May",
        "value": "Cape May",
        "state": "NJ"
    },
    {
        "code": "34007",
        "county": "Camden",
        "value": "Camden",
        "state": "NJ"
    },
    {
        "code": "34013",
        "county": "Essex",
        "value": "Essex",
        "state": "NJ"
    },
    {
        "code": "34015",
        "county": "Gloucester",
        "value": "Gloucester",
        "state": "NJ"
    },
    {
        "code": "34017",
        "county": "Hudson",
        "value": "Hudson",
        "state": "NJ"
    },
    {
        "code": "30083",
        "county": "Richland",
        "value": "Richland",
        "state": "MT"
    },
    {
        "code": "30085",
        "county": "Roosevelt",
        "value": "Roosevelt",
        "state": "MT"
    },
    {
        "code": "30087",
        "county": "Rosebud",
        "value": "Rosebud",
        "state": "MT"
    },
    {
        "code": "30089",
        "county": "Sanders",
        "value": "Sanders",
        "state": "MT"
    },
    {
        "code": "30091",
        "county": "Sheridan",
        "value": "Sheridan",
        "state": "MT"
    },
    {
        "code": "30093",
        "county": "Silver Bow",
        "value": "Silver Bow",
        "state": "MT"
    },
    {
        "code": "30095",
        "county": "Stillwater",
        "value": "Stillwater",
        "state": "MT"
    },
    {
        "code": "30097",
        "county": "Sweet Grass",
        "value": "Sweet Grass",
        "state": "MT"
    },
    {
        "code": "30099",
        "county": "Teton",
        "value": "Teton",
        "state": "MT"
    },
    {
        "code": "30101",
        "county": "Toole",
        "value": "Toole",
        "state": "MT"
    },
    {
        "code": "30103",
        "county": "Treasure",
        "value": "Treasure",
        "state": "MT"
    },
    {
        "code": "30105",
        "county": "Valley",
        "value": "Valley",
        "state": "MT"
    },
    {
        "code": "30107",
        "county": "Wheatland",
        "value": "Wheatland",
        "state": "MT"
    },
    {
        "code": "30109",
        "county": "Wibaux",
        "value": "Wibaux",
        "state": "MT"
    },
    {
        "code": "30111",
        "county": "Yellowstone",
        "value": "Yellowstone",
        "state": "MT"
    },
    {
        "code": "30113",
        "county": "Yellowstone National Park",
        "value": "Yellowstone National Park",
        "state": "MT"
    },
    {
        "code": "30000",
        "county": "Montana",
        "value": "Montana",
        "state": "MT"
    },
    {
        "code": "30001",
        "county": "Beaverhead",
        "value": "Beaverhead",
        "state": "MT"
    },
    {
        "code": "30003",
        "county": "Big Horn",
        "value": "Big Horn",
        "state": "MT"
    },
    {
        "code": "30005",
        "county": "Blaine",
        "value": "Blaine",
        "state": "MT"
    },
    {
        "code": "30007",
        "county": "Broadwater",
        "value": "Broadwater",
        "state": "MT"
    },
    {
        "code": "30009",
        "county": "Carbon",
        "value": "Carbon",
        "state": "MT"
    },
    {
        "code": "30011",
        "county": "Carter",
        "value": "Carter",
        "state": "MT"
    },
    {
        "code": "30013",
        "county": "Cascade",
        "value": "Cascade",
        "state": "MT"
    },
    {
        "code": "30015",
        "county": "Chouteau",
        "value": "Chouteau",
        "state": "MT"
    },
    {
        "code": "30017",
        "county": "Custer",
        "value": "Custer",
        "state": "MT"
    },
    {
        "code": "30019",
        "county": "Daniels",
        "value": "Daniels",
        "state": "MT"
    },
    {
        "code": "30021",
        "county": "Dawson",
        "value": "Dawson",
        "state": "MT"
    },
    {
        "code": "30023",
        "county": "Deer Lodge",
        "value": "Deer Lodge",
        "state": "MT"
    },
    {
        "code": "30025",
        "county": "Fallon",
        "value": "Fallon",
        "state": "MT"
    },
    {
        "code": "30027",
        "county": "Fergus",
        "value": "Fergus",
        "state": "MT"
    },
    {
        "code": "30029",
        "county": "Flathead",
        "value": "Flathead",
        "state": "MT"
    },
    {
        "code": "30031",
        "county": "Gallatin",
        "value": "Gallatin",
        "state": "MT"
    },
    {
        "code": "30033",
        "county": "Garfield",
        "value": "Garfield",
        "state": "MT"
    },
    {
        "code": "30035",
        "county": "Glacier",
        "value": "Glacier",
        "state": "MT"
    },
    {
        "code": "30037",
        "county": "Golden Valley",
        "value": "Golden Valley",
        "state": "MT"
    },
    {
        "code": "37111",
        "county": "McDowell",
        "value": "McDowell",
        "state": "NC"
    },
    {
        "code": "37113",
        "county": "Macon",
        "value": "Macon",
        "state": "NC"
    },
    {
        "code": "37115",
        "county": "Madison",
        "value": "Madison",
        "state": "NC"
    },
    {
        "code": "37117",
        "county": "Martin",
        "value": "Martin",
        "state": "NC"
    },
    {
        "code": "37119",
        "county": "Mecklenburg",
        "value": "Mecklenburg",
        "state": "NC"
    },
    {
        "code": "37121",
        "county": "Mitchell",
        "value": "Mitchell",
        "state": "NC"
    },
    {
        "code": "37123",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "NC"
    },
    {
        "code": "37125",
        "county": "Moore",
        "value": "Moore",
        "state": "NC"
    },
    {
        "code": "37127",
        "county": "Nash",
        "value": "Nash",
        "state": "NC"
    },
    {
        "code": "37129",
        "county": "New Hanover",
        "value": "New Hanover",
        "state": "NC"
    },
    {
        "code": "37131",
        "county": "Northampton",
        "value": "Northampton",
        "state": "NC"
    },
    {
        "code": "37133",
        "county": "Onslow",
        "value": "Onslow",
        "state": "NC"
    },
    {
        "code": "37135",
        "county": "Orange",
        "value": "Orange",
        "state": "NC"
    },
    {
        "code": "37137",
        "county": "Pamlico",
        "value": "Pamlico",
        "state": "NC"
    },
    {
        "code": "37139",
        "county": "Pasquotank",
        "value": "Pasquotank",
        "state": "NC"
    },
    {
        "code": "37141",
        "county": "Pender",
        "value": "Pender",
        "state": "NC"
    },
    {
        "code": "37143",
        "county": "Perquimans",
        "value": "Perquimans",
        "state": "NC"
    },
    {
        "code": "37145",
        "county": "Person",
        "value": "Person",
        "state": "NC"
    },
    {
        "code": "37147",
        "county": "Pitt",
        "value": "Pitt",
        "state": "NC"
    },
    {
        "code": "37149",
        "county": "Polk",
        "value": "Polk",
        "state": "NC"
    },
    {
        "code": "37151",
        "county": "Randolph",
        "value": "Randolph",
        "state": "NC"
    },
    {
        "code": "37153",
        "county": "Richmond",
        "value": "Richmond",
        "state": "NC"
    },
    {
        "code": "37155",
        "county": "Robeson",
        "value": "Robeson",
        "state": "NC"
    },
    {
        "code": "37000",
        "county": "North Carolina",
        "value": "North Carolina",
        "state": "NC"
    },
    {
        "code": "37001",
        "county": "Alamance",
        "value": "Alamance",
        "state": "NC"
    },
    {
        "code": "37003",
        "county": "Alexander",
        "value": "Alexander",
        "state": "NC"
    },
    {
        "code": "37005",
        "county": "Alleghany",
        "value": "Alleghany",
        "state": "NC"
    },
    {
        "code": "37007",
        "county": "Anson",
        "value": "Anson",
        "state": "NC"
    },
    {
        "code": "37009",
        "county": "Ashe",
        "value": "Ashe",
        "state": "NC"
    },
    {
        "code": "37011",
        "county": "Avery",
        "value": "Avery",
        "state": "NC"
    },
    {
        "code": "37013",
        "county": "Beaufort",
        "value": "Beaufort",
        "state": "NC"
    },
    {
        "code": "37015",
        "county": "Bertie",
        "value": "Bertie",
        "state": "NC"
    },
    {
        "code": "37017",
        "county": "Bladen",
        "value": "Bladen",
        "state": "NC"
    },
    {
        "code": "37019",
        "county": "Brunswick",
        "value": "Brunswick",
        "state": "NC"
    },
    {
        "code": "37021",
        "county": "Buncombe",
        "value": "Buncombe",
        "state": "NC"
    },
    {
        "code": "37023",
        "county": "Burke",
        "value": "Burke",
        "state": "NC"
    },
    {
        "code": "37025",
        "county": "Cabarrus",
        "value": "Cabarrus",
        "state": "NC"
    },
    {
        "code": "37043",
        "county": "Clay",
        "value": "Clay",
        "state": "NC"
    },
    {
        "code": "37157",
        "county": "Rockingham",
        "value": "Rockingham",
        "state": "NC"
    },
    {
        "code": "37159",
        "county": "Rowan",
        "value": "Rowan",
        "state": "NC"
    },
    {
        "code": "37161",
        "county": "Rutherford",
        "value": "Rutherford",
        "state": "NC"
    },
    {
        "code": "37163",
        "county": "Sampson",
        "value": "Sampson",
        "state": "NC"
    },
    {
        "code": "37165",
        "county": "Scotland",
        "value": "Scotland",
        "state": "NC"
    },
    {
        "code": "37167",
        "county": "Stanly",
        "value": "Stanly",
        "state": "NC"
    },
    {
        "code": "37169",
        "county": "Stokes",
        "value": "Stokes",
        "state": "NC"
    },
    {
        "code": "37171",
        "county": "Surry",
        "value": "Surry",
        "state": "NC"
    },
    {
        "code": "37173",
        "county": "Swain",
        "value": "Swain",
        "state": "NC"
    },
    {
        "code": "37175",
        "county": "Transylvania",
        "value": "Transylvania",
        "state": "NC"
    },
    {
        "code": "37177",
        "county": "Tyrrell",
        "value": "Tyrrell",
        "state": "NC"
    },
    {
        "code": "37179",
        "county": "Union",
        "value": "Union",
        "state": "NC"
    },
    {
        "code": "37181",
        "county": "Vance",
        "value": "Vance",
        "state": "NC"
    },
    {
        "code": "37183",
        "county": "Wake",
        "value": "Wake",
        "state": "NC"
    },
    {
        "code": "37185",
        "county": "Warren",
        "value": "Warren",
        "state": "NC"
    },
    {
        "code": "37187",
        "county": "Washington",
        "value": "Washington",
        "state": "NC"
    },
    {
        "code": "37189",
        "county": "Watauga",
        "value": "Watauga",
        "state": "NC"
    },
    {
        "code": "37191",
        "county": "Wayne",
        "value": "Wayne",
        "state": "NC"
    },
    {
        "code": "37193",
        "county": "Wilkes",
        "value": "Wilkes",
        "state": "NC"
    },
    {
        "code": "37195",
        "county": "Wilson",
        "value": "Wilson",
        "state": "NC"
    },
    {
        "code": "37197",
        "county": "Yadkin",
        "value": "Yadkin",
        "state": "NC"
    },
    {
        "code": "37199",
        "county": "Yancey",
        "value": "Yancey",
        "state": "NC"
    },
    {
        "code": "37027",
        "county": "Caldwell",
        "value": "Caldwell",
        "state": "NC"
    },
    {
        "code": "37029",
        "county": "Camden",
        "value": "Camden",
        "state": "NC"
    },
    {
        "code": "37031",
        "county": "Carteret",
        "value": "Carteret",
        "state": "NC"
    },
    {
        "code": "37033",
        "county": "Caswell",
        "value": "Caswell",
        "state": "NC"
    },
    {
        "code": "37035",
        "county": "Catawba",
        "value": "Catawba",
        "state": "NC"
    },
    {
        "code": "37037",
        "county": "Chatham",
        "value": "Chatham",
        "state": "NC"
    },
    {
        "code": "37039",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "NC"
    },
    {
        "code": "37041",
        "county": "Chowan",
        "value": "Chowan",
        "state": "NC"
    },
    {
        "code": "37045",
        "county": "Cleveland",
        "value": "Cleveland",
        "state": "NC"
    },
    {
        "code": "37047",
        "county": "Columbus",
        "value": "Columbus",
        "state": "NC"
    },
    {
        "code": "37049",
        "county": "Craven",
        "value": "Craven",
        "state": "NC"
    },
    {
        "code": "37051",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "NC"
    },
    {
        "code": "37053",
        "county": "Currituck",
        "value": "Currituck",
        "state": "NC"
    },
    {
        "code": "37055",
        "county": "Dare",
        "value": "Dare",
        "state": "NC"
    },
    {
        "code": "37057",
        "county": "Davidson",
        "value": "Davidson",
        "state": "NC"
    },
    {
        "code": "37059",
        "county": "Davie",
        "value": "Davie",
        "state": "NC"
    },
    {
        "code": "37061",
        "county": "Duplin",
        "value": "Duplin",
        "state": "NC"
    },
    {
        "code": "37063",
        "county": "Durham",
        "value": "Durham",
        "state": "NC"
    },
    {
        "code": "37065",
        "county": "Edgecombe",
        "value": "Edgecombe",
        "state": "NC"
    },
    {
        "code": "37067",
        "county": "Forsyth",
        "value": "Forsyth",
        "state": "NC"
    },
    {
        "code": "37069",
        "county": "Franklin",
        "value": "Franklin",
        "state": "NC"
    },
    {
        "code": "37071",
        "county": "Gaston",
        "value": "Gaston",
        "state": "NC"
    },
    {
        "code": "37073",
        "county": "Gates",
        "value": "Gates",
        "state": "NC"
    },
    {
        "code": "37075",
        "county": "Graham",
        "value": "Graham",
        "state": "NC"
    },
    {
        "code": "37077",
        "county": "Granville",
        "value": "Granville",
        "state": "NC"
    },
    {
        "code": "37079",
        "county": "Greene",
        "value": "Greene",
        "state": "NC"
    },
    {
        "code": "37081",
        "county": "Guilford",
        "value": "Guilford",
        "state": "NC"
    },
    {
        "code": "37083",
        "county": "Halifax",
        "value": "Halifax",
        "state": "NC"
    },
    {
        "code": "37085",
        "county": "Harnett",
        "value": "Harnett",
        "state": "NC"
    },
    {
        "code": "37087",
        "county": "Haywood",
        "value": "Haywood",
        "state": "NC"
    },
    {
        "code": "37089",
        "county": "Henderson",
        "value": "Henderson",
        "state": "NC"
    },
    {
        "code": "37091",
        "county": "Hertford",
        "value": "Hertford",
        "state": "NC"
    },
    {
        "code": "37093",
        "county": "Hoke",
        "value": "Hoke",
        "state": "NC"
    },
    {
        "code": "37095",
        "county": "Hyde",
        "value": "Hyde",
        "state": "NC"
    },
    {
        "code": "37097",
        "county": "Iredell",
        "value": "Iredell",
        "state": "NC"
    },
    {
        "code": "37099",
        "county": "Jackson",
        "value": "Jackson",
        "state": "NC"
    },
    {
        "code": "37101",
        "county": "Johnston",
        "value": "Johnston",
        "state": "NC"
    },
    {
        "code": "37103",
        "county": "Jones",
        "value": "Jones",
        "state": "NC"
    },
    {
        "code": "37105",
        "county": "Lee",
        "value": "Lee",
        "state": "NC"
    },
    {
        "code": "37107",
        "county": "Lenoir",
        "value": "Lenoir",
        "state": "NC"
    },
    {
        "code": "37109",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "NC"
    },
    {
        "code": "38083",
        "county": "Sheridan",
        "value": "Sheridan",
        "state": "ND"
    },
    {
        "code": "38081",
        "county": "Sargent",
        "value": "Sargent",
        "state": "ND"
    },
    {
        "code": "38087",
        "county": "Slope",
        "value": "Slope",
        "state": "ND"
    },
    {
        "code": "38089",
        "county": "Stark",
        "value": "Stark",
        "state": "ND"
    },
    {
        "code": "38091",
        "county": "Steele",
        "value": "Steele",
        "state": "ND"
    },
    {
        "code": "38093",
        "county": "Stutsman",
        "value": "Stutsman",
        "state": "ND"
    },
    {
        "code": "38095",
        "county": "Towner",
        "value": "Towner",
        "state": "ND"
    },
    {
        "code": "38097",
        "county": "Traill",
        "value": "Traill",
        "state": "ND"
    },
    {
        "code": "38099",
        "county": "Walsh",
        "value": "Walsh",
        "state": "ND"
    },
    {
        "code": "38101",
        "county": "Ward",
        "value": "Ward",
        "state": "ND"
    },
    {
        "code": "38103",
        "county": "Wells",
        "value": "Wells",
        "state": "ND"
    },
    {
        "code": "38105",
        "county": "Williams",
        "value": "Williams",
        "state": "ND"
    },
    {
        "code": "38000",
        "county": "North Dakota",
        "value": "North Dakota",
        "state": "ND"
    },
    {
        "code": "27067",
        "county": "Kandiyohi",
        "value": "Kandiyohi",
        "state": "MN"
    },
    {
        "code": "27069",
        "county": "Kittson",
        "value": "Kittson",
        "state": "MN"
    },
    {
        "code": "27071",
        "county": "Koochiching",
        "value": "Koochiching",
        "state": "MN"
    },
    {
        "code": "27073",
        "county": "Lac qui Parle",
        "value": "Lac qui Parle",
        "state": "MN"
    },
    {
        "code": "27075",
        "county": "Lake",
        "value": "Lake",
        "state": "MN"
    },
    {
        "code": "27077",
        "county": "Lake of the Woods",
        "value": "Lake of the Woods",
        "state": "MN"
    },
    {
        "code": "27079",
        "county": "Le Sueur",
        "value": "Le Sueur",
        "state": "MN"
    },
    {
        "code": "27081",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "MN"
    },
    {
        "code": "27083",
        "county": "Lyon",
        "value": "Lyon",
        "state": "MN"
    },
    {
        "code": "27085",
        "county": "McLeod",
        "value": "McLeod",
        "state": "MN"
    },
    {
        "code": "27087",
        "county": "Mahnomen",
        "value": "Mahnomen",
        "state": "MN"
    },
    {
        "code": "27089",
        "county": "Marshall",
        "value": "Marshall",
        "state": "MN"
    },
    {
        "code": "27091",
        "county": "Martin",
        "value": "Martin",
        "state": "MN"
    },
    {
        "code": "27093",
        "county": "Meeker",
        "value": "Meeker",
        "state": "MN"
    },
    {
        "code": "27095",
        "county": "Mille Lacs",
        "value": "Mille Lacs",
        "state": "MN"
    },
    {
        "code": "27097",
        "county": "Morrison",
        "value": "Morrison",
        "state": "MN"
    },
    {
        "code": "27099",
        "county": "Mower",
        "value": "Mower",
        "state": "MN"
    },
    {
        "code": "27101",
        "county": "Murray",
        "value": "Murray",
        "state": "MN"
    },
    {
        "code": "27103",
        "county": "Nicollet",
        "value": "Nicollet",
        "state": "MN"
    },
    {
        "code": "27105",
        "county": "Nobles",
        "value": "Nobles",
        "state": "MN"
    },
    {
        "code": "27107",
        "county": "Norman",
        "value": "Norman",
        "state": "MN"
    },
    {
        "code": "27109",
        "county": "Olmsted",
        "value": "Olmsted",
        "state": "MN"
    },
    {
        "code": "27111",
        "county": "Otter Tail",
        "value": "Otter Tail",
        "state": "MN"
    },
    {
        "code": "27113",
        "county": "Pennington",
        "value": "Pennington",
        "state": "MN"
    },
    {
        "code": "27115",
        "county": "Pine",
        "value": "Pine",
        "state": "MN"
    },
    {
        "code": "27117",
        "county": "Pipestone",
        "value": "Pipestone",
        "state": "MN"
    },
    {
        "code": "27119",
        "county": "Polk",
        "value": "Polk",
        "state": "MN"
    },
    {
        "code": "27121",
        "county": "Pope",
        "value": "Pope",
        "state": "MN"
    },
    {
        "code": "27123",
        "county": "Ramsey",
        "value": "Ramsey",
        "state": "MN"
    },
    {
        "code": "27125",
        "county": "Red Lake",
        "value": "Red Lake",
        "state": "MN"
    },
    {
        "code": "27127",
        "county": "Redwood",
        "value": "Redwood",
        "state": "MN"
    },
    {
        "code": "27129",
        "county": "Renville",
        "value": "Renville",
        "state": "MN"
    },
    {
        "code": "27131",
        "county": "Rice",
        "value": "Rice",
        "state": "MN"
    },
    {
        "code": "27133",
        "county": "Rock",
        "value": "Rock",
        "state": "MN"
    },
    {
        "code": "27135",
        "county": "Roseau",
        "value": "Roseau",
        "state": "MN"
    },
    {
        "code": "27137",
        "county": "St. Louis",
        "value": "St. Louis",
        "state": "MN"
    },
    {
        "code": "27139",
        "county": "Scott",
        "value": "Scott",
        "state": "MN"
    },
    {
        "code": "27141",
        "county": "Sherburne",
        "value": "Sherburne",
        "state": "MN"
    },
    {
        "code": "27143",
        "county": "Sibley",
        "value": "Sibley",
        "state": "MN"
    },
    {
        "code": "27145",
        "county": "Stearns",
        "value": "Stearns",
        "state": "MN"
    },
    {
        "code": "27147",
        "county": "Steele",
        "value": "Steele",
        "state": "MN"
    },
    {
        "code": "27149",
        "county": "Stevens",
        "value": "Stevens",
        "state": "MN"
    },
    {
        "code": "27151",
        "county": "Swift",
        "value": "Swift",
        "state": "MN"
    },
    {
        "code": "27153",
        "county": "Todd",
        "value": "Todd",
        "state": "MN"
    },
    {
        "code": "27155",
        "county": "Traverse",
        "value": "Traverse",
        "state": "MN"
    },
    {
        "code": "27157",
        "county": "Wabasha",
        "value": "Wabasha",
        "state": "MN"
    },
    {
        "code": "27159",
        "county": "Wadena",
        "value": "Wadena",
        "state": "MN"
    },
    {
        "code": "27161",
        "county": "Waseca",
        "value": "Waseca",
        "state": "MN"
    },
    {
        "code": "27163",
        "county": "Washington",
        "value": "Washington",
        "state": "MN"
    },
    {
        "code": "27165",
        "county": "Watonwan",
        "value": "Watonwan",
        "state": "MN"
    },
    {
        "code": "27167",
        "county": "Wilkin",
        "value": "Wilkin",
        "state": "MN"
    },
    {
        "code": "27169",
        "county": "Winona",
        "value": "Winona",
        "state": "MN"
    },
    {
        "code": "27171",
        "county": "Wright",
        "value": "Wright",
        "state": "MN"
    },
    {
        "code": "27173",
        "county": "Yellow Medicine",
        "value": "Yellow Medicine",
        "state": "MN"
    },
    {
        "code": "27000",
        "county": "Minnesota",
        "value": "Minnesota",
        "state": "MN"
    },
    {
        "code": "27001",
        "county": "Aitkin",
        "value": "Aitkin",
        "state": "MN"
    },
    {
        "code": "27003",
        "county": "Anoka",
        "value": "Anoka",
        "state": "MN"
    },
    {
        "code": "27005",
        "county": "Becker",
        "value": "Becker",
        "state": "MN"
    },
    {
        "code": "27007",
        "county": "Beltrami",
        "value": "Beltrami",
        "state": "MN"
    },
    {
        "code": "27009",
        "county": "Benton",
        "value": "Benton",
        "state": "MN"
    },
    {
        "code": "27011",
        "county": "Big Stone",
        "value": "Big Stone",
        "state": "MN"
    },
    {
        "code": "27013",
        "county": "Blue Earth",
        "value": "Blue Earth",
        "state": "MN"
    },
    {
        "code": "27015",
        "county": "Brown",
        "value": "Brown",
        "state": "MN"
    },
    {
        "code": "27017",
        "county": "Carlton",
        "value": "Carlton",
        "state": "MN"
    },
    {
        "code": "27019",
        "county": "Carver",
        "value": "Carver",
        "state": "MN"
    },
    {
        "code": "27021",
        "county": "Cass",
        "value": "Cass",
        "state": "MN"
    },
    {
        "code": "27023",
        "county": "Chippewa",
        "value": "Chippewa",
        "state": "MN"
    },
    {
        "code": "27025",
        "county": "Chisago",
        "value": "Chisago",
        "state": "MN"
    },
    {
        "code": "27027",
        "county": "Clay",
        "value": "Clay",
        "state": "MN"
    },
    {
        "code": "27029",
        "county": "Clearwater",
        "value": "Clearwater",
        "state": "MN"
    },
    {
        "code": "27031",
        "county": "Cook",
        "value": "Cook",
        "state": "MN"
    },
    {
        "code": "27033",
        "county": "Cottonwood",
        "value": "Cottonwood",
        "state": "MN"
    },
    {
        "code": "27035",
        "county": "Crow Wing",
        "value": "Crow Wing",
        "state": "MN"
    },
    {
        "code": "27037",
        "county": "Dakota",
        "value": "Dakota",
        "state": "MN"
    },
    {
        "code": "27039",
        "county": "Dodge",
        "value": "Dodge",
        "state": "MN"
    },
    {
        "code": "27041",
        "county": "Douglas",
        "value": "Douglas",
        "state": "MN"
    },
    {
        "code": "27043",
        "county": "Faribault",
        "value": "Faribault",
        "state": "MN"
    },
    {
        "code": "27045",
        "county": "Fillmore",
        "value": "Fillmore",
        "state": "MN"
    },
    {
        "code": "27047",
        "county": "Freeborn",
        "value": "Freeborn",
        "state": "MN"
    },
    {
        "code": "27049",
        "county": "Goodhue",
        "value": "Goodhue",
        "state": "MN"
    },
    {
        "code": "27051",
        "county": "Grant",
        "value": "Grant",
        "state": "MN"
    },
    {
        "code": "29143",
        "county": "New Madrid",
        "value": "New Madrid",
        "state": "MO"
    },
    {
        "code": "29145",
        "county": "Newton",
        "value": "Newton",
        "state": "MO"
    },
    {
        "code": "29147",
        "county": "Nodaway",
        "value": "Nodaway",
        "state": "MO"
    },
    {
        "code": "29149",
        "county": "Oregon",
        "value": "Oregon",
        "state": "MO"
    },
    {
        "code": "29151",
        "county": "Osage",
        "value": "Osage",
        "state": "MO"
    },
    {
        "code": "29153",
        "county": "Ozark",
        "value": "Ozark",
        "state": "MO"
    },
    {
        "code": "29155",
        "county": "Pemiscot",
        "value": "Pemiscot",
        "state": "MO"
    },
    {
        "code": "29157",
        "county": "Perry",
        "value": "Perry",
        "state": "MO"
    },
    {
        "code": "29159",
        "county": "Pettis",
        "value": "Pettis",
        "state": "MO"
    },
    {
        "code": "29161",
        "county": "Phelps",
        "value": "Phelps",
        "state": "MO"
    },
    {
        "code": "29163",
        "county": "Pike",
        "value": "Pike",
        "state": "MO"
    },
    {
        "code": "29165",
        "county": "Platte",
        "value": "Platte",
        "state": "MO"
    },
    {
        "code": "29167",
        "county": "Polk",
        "value": "Polk",
        "state": "MO"
    },
    {
        "code": "29169",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "MO"
    },
    {
        "code": "29171",
        "county": "Putnam",
        "value": "Putnam",
        "state": "MO"
    },
    {
        "code": "29173",
        "county": "Ralls",
        "value": "Ralls",
        "state": "MO"
    },
    {
        "code": "29175",
        "county": "Randolph",
        "value": "Randolph",
        "state": "MO"
    },
    {
        "code": "29177",
        "county": "Ray",
        "value": "Ray",
        "state": "MO"
    },
    {
        "code": "29179",
        "county": "Reynolds",
        "value": "Reynolds",
        "state": "MO"
    },
    {
        "code": "29181",
        "county": "Ripley",
        "value": "Ripley",
        "state": "MO"
    },
    {
        "code": "29183",
        "county": "St. Charles",
        "value": "St. Charles",
        "state": "MO"
    },
    {
        "code": "29185",
        "county": "St. Clair",
        "value": "St. Clair",
        "state": "MO"
    },
    {
        "code": "29186",
        "county": "Ste. Genevieve",
        "value": "Ste. Genevieve",
        "state": "MO"
    },
    {
        "code": "29187",
        "county": "St. Francois",
        "value": "St. Francois",
        "state": "MO"
    },
    {
        "code": "29189",
        "county": "St. Louis",
        "value": "St. Louis",
        "state": "MO"
    },
    {
        "code": "29195",
        "county": "Saline",
        "value": "Saline",
        "state": "MO"
    },
    {
        "code": "29197",
        "county": "Schuyler",
        "value": "Schuyler",
        "state": "MO"
    },
    {
        "code": "29199",
        "county": "Scotland",
        "value": "Scotland",
        "state": "MO"
    },
    {
        "code": "29201",
        "county": "Scott",
        "value": "Scott",
        "state": "MO"
    },
    {
        "code": "29203",
        "county": "Shannon",
        "value": "Shannon",
        "state": "MO"
    },
    {
        "code": "29205",
        "county": "Shelby",
        "value": "Shelby",
        "state": "MO"
    },
    {
        "code": "29207",
        "county": "Stoddard",
        "value": "Stoddard",
        "state": "MO"
    },
    {
        "code": "29209",
        "county": "Stone",
        "value": "Stone",
        "state": "MO"
    },
    {
        "code": "29211",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "MO"
    },
    {
        "code": "29213",
        "county": "Taney",
        "value": "Taney",
        "state": "MO"
    },
    {
        "code": "29215",
        "county": "Texas",
        "value": "Texas",
        "state": "MO"
    },
    {
        "code": "29217",
        "county": "Vernon",
        "value": "Vernon",
        "state": "MO"
    },
    {
        "code": "29219",
        "county": "Warren",
        "value": "Warren",
        "state": "MO"
    },
    {
        "code": "29221",
        "county": "Washington",
        "value": "Washington",
        "state": "MO"
    },
    {
        "code": "29223",
        "county": "Wayne",
        "value": "Wayne",
        "state": "MO"
    },
    {
        "code": "29225",
        "county": "Webster",
        "value": "Webster",
        "state": "MO"
    },
    {
        "code": "29227",
        "county": "Worth",
        "value": "Worth",
        "state": "MO"
    },
    {
        "code": "29229",
        "county": "Wright",
        "value": "Wright",
        "state": "MO"
    },
    {
        "code": "29510",
        "county": "St. Louis city",
        "value": "St. Louis city",
        "state": "MO"
    },
    {
        "code": "29015",
        "county": "Benton",
        "value": "Benton",
        "state": "MO"
    },
    {
        "code": "29017",
        "county": "Bollinger",
        "value": "Bollinger",
        "state": "MO"
    },
    {
        "code": "29019",
        "county": "Boone",
        "value": "Boone",
        "state": "MO"
    },
    {
        "code": "29021",
        "county": "Buchanan",
        "value": "Buchanan",
        "state": "MO"
    },
    {
        "code": "29023",
        "county": "Butler",
        "value": "Butler",
        "state": "MO"
    },
    {
        "code": "29025",
        "county": "Caldwell",
        "value": "Caldwell",
        "state": "MO"
    },
    {
        "code": "29027",
        "county": "Callaway",
        "value": "Callaway",
        "state": "MO"
    },
    {
        "code": "29029",
        "county": "Camden",
        "value": "Camden",
        "state": "MO"
    },
    {
        "code": "29031",
        "county": "Cape Girardeau",
        "value": "Cape Girardeau",
        "state": "MO"
    },
    {
        "code": "29033",
        "county": "Carroll",
        "value": "Carroll",
        "state": "MO"
    },
    {
        "code": "29035",
        "county": "Carter",
        "value": "Carter",
        "state": "MO"
    },
    {
        "code": "29037",
        "county": "Cass",
        "value": "Cass",
        "state": "MO"
    },
    {
        "code": "29039",
        "county": "Cedar",
        "value": "Cedar",
        "state": "MO"
    },
    {
        "code": "29041",
        "county": "Chariton",
        "value": "Chariton",
        "state": "MO"
    },
    {
        "code": "29043",
        "county": "Christian",
        "value": "Christian",
        "state": "MO"
    },
    {
        "code": "29045",
        "county": "Clark",
        "value": "Clark",
        "state": "MO"
    },
    {
        "code": "29047",
        "county": "Clay",
        "value": "Clay",
        "state": "MO"
    },
    {
        "code": "29049",
        "county": "Clinton",
        "value": "Clinton",
        "state": "MO"
    },
    {
        "code": "29051",
        "county": "Cole",
        "value": "Cole",
        "state": "MO"
    },
    {
        "code": "29053",
        "county": "Cooper",
        "value": "Cooper",
        "state": "MO"
    },
    {
        "code": "29055",
        "county": "Crawford",
        "value": "Crawford",
        "state": "MO"
    },
    {
        "code": "29057",
        "county": "Dade",
        "value": "Dade",
        "state": "MO"
    },
    {
        "code": "29059",
        "county": "Dallas",
        "value": "Dallas",
        "state": "MO"
    },
    {
        "code": "29061",
        "county": "Daviess",
        "value": "Daviess",
        "state": "MO"
    },
    {
        "code": "29063",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "MO"
    },
    {
        "code": "29065",
        "county": "Dent",
        "value": "Dent",
        "state": "MO"
    },
    {
        "code": "17077",
        "county": "Jackson",
        "value": "Jackson",
        "state": "IL"
    },
    {
        "code": "17079",
        "county": "Jasper",
        "value": "Jasper",
        "state": "IL"
    },
    {
        "code": "17081",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "IL"
    },
    {
        "code": "17083",
        "county": "Jersey",
        "value": "Jersey",
        "state": "IL"
    },
    {
        "code": "17085",
        "county": "Jo Daviess",
        "value": "Jo Daviess",
        "state": "IL"
    },
    {
        "code": "17087",
        "county": "Johnson",
        "value": "Johnson",
        "state": "IL"
    },
    {
        "code": "17089",
        "county": "Kane",
        "value": "Kane",
        "state": "IL"
    },
    {
        "code": "17091",
        "county": "Kankakee",
        "value": "Kankakee",
        "state": "IL"
    },
    {
        "code": "17093",
        "county": "Kendall",
        "value": "Kendall",
        "state": "IL"
    },
    {
        "code": "17095",
        "county": "Knox",
        "value": "Knox",
        "state": "IL"
    },
    {
        "code": "17097",
        "county": "Lake",
        "value": "Lake",
        "state": "IL"
    },
    {
        "code": "17099",
        "county": "La Salle",
        "value": "La Salle",
        "state": "IL"
    },
    {
        "code": "17101",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "IL"
    },
    {
        "code": "17103",
        "county": "Lee",
        "value": "Lee",
        "state": "IL"
    },
    {
        "code": "17105",
        "county": "Livingston",
        "value": "Livingston",
        "state": "IL"
    },
    {
        "code": "17107",
        "county": "Logan",
        "value": "Logan",
        "state": "IL"
    },
    {
        "code": "17109",
        "county": "McDonough",
        "value": "McDonough",
        "state": "IL"
    },
    {
        "code": "17111",
        "county": "McHenry",
        "value": "McHenry",
        "state": "IL"
    },
    {
        "code": "17113",
        "county": "McLean",
        "value": "McLean",
        "state": "IL"
    },
    {
        "code": "17115",
        "county": "Macon",
        "value": "Macon",
        "state": "IL"
    },
    {
        "code": "17117",
        "county": "Macoupin",
        "value": "Macoupin",
        "state": "IL"
    },
    {
        "code": "17119",
        "county": "Madison",
        "value": "Madison",
        "state": "IL"
    },
    {
        "code": "17121",
        "county": "Marion",
        "value": "Marion",
        "state": "IL"
    },
    {
        "code": "17123",
        "county": "Marshall",
        "value": "Marshall",
        "state": "IL"
    },
    {
        "code": "17125",
        "county": "Mason",
        "value": "Mason",
        "state": "IL"
    },
    {
        "code": "17127",
        "county": "Massac",
        "value": "Massac",
        "state": "IL"
    },
    {
        "code": "17129",
        "county": "Menard",
        "value": "Menard",
        "state": "IL"
    },
    {
        "code": "17131",
        "county": "Mercer",
        "value": "Mercer",
        "state": "IL"
    },
    {
        "code": "17133",
        "county": "Monroe",
        "value": "Monroe",
        "state": "IL"
    },
    {
        "code": "17135",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "IL"
    },
    {
        "code": "17137",
        "county": "Morgan",
        "value": "Morgan",
        "state": "IL"
    },
    {
        "code": "17139",
        "county": "Moultrie",
        "value": "Moultrie",
        "state": "IL"
    },
    {
        "code": "17141",
        "county": "Ogle",
        "value": "Ogle",
        "state": "IL"
    },
    {
        "code": "17143",
        "county": "Peoria",
        "value": "Peoria",
        "state": "IL"
    },
    {
        "code": "17145",
        "county": "Perry",
        "value": "Perry",
        "state": "IL"
    },
    {
        "code": "17147",
        "county": "Piatt",
        "value": "Piatt",
        "state": "IL"
    },
    {
        "code": "17149",
        "county": "Pike",
        "value": "Pike",
        "state": "IL"
    },
    {
        "code": "17151",
        "county": "Pope",
        "value": "Pope",
        "state": "IL"
    },
    {
        "code": "17153",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "IL"
    },
    {
        "code": "17155",
        "county": "Putnam",
        "value": "Putnam",
        "state": "IL"
    },
    {
        "code": "17157",
        "county": "Randolph",
        "value": "Randolph",
        "state": "IL"
    },
    {
        "code": "17159",
        "county": "Richland",
        "value": "Richland",
        "state": "IL"
    },
    {
        "code": "17161",
        "county": "Rock Island",
        "value": "Rock Island",
        "state": "IL"
    },
    {
        "code": "17000",
        "county": "Illinois",
        "value": "Illinois",
        "state": "IL"
    },
    {
        "code": "17001",
        "county": "Adams",
        "value": "Adams",
        "state": "IL"
    },
    {
        "code": "17003",
        "county": "Alexander",
        "value": "Alexander",
        "state": "IL"
    },
    {
        "code": "17005",
        "county": "Bond",
        "value": "Bond",
        "state": "IL"
    },
    {
        "code": "17007",
        "county": "Boone",
        "value": "Boone",
        "state": "IL"
    },
    {
        "code": "17009",
        "county": "Brown",
        "value": "Brown",
        "state": "IL"
    },
    {
        "code": "17011",
        "county": "Bureau",
        "value": "Bureau",
        "state": "IL"
    },
    {
        "code": "17013",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "IL"
    },
    {
        "code": "17015",
        "county": "Carroll",
        "value": "Carroll",
        "state": "IL"
    },
    {
        "code": "17017",
        "county": "Cass",
        "value": "Cass",
        "state": "IL"
    },
    {
        "code": "17019",
        "county": "Champaign",
        "value": "Champaign",
        "state": "IL"
    },
    {
        "code": "17021",
        "county": "Christian",
        "value": "Christian",
        "state": "IL"
    },
    {
        "code": "17023",
        "county": "Clark",
        "value": "Clark",
        "state": "IL"
    },
    {
        "code": "17025",
        "county": "Clay",
        "value": "Clay",
        "state": "IL"
    },
    {
        "code": "17027",
        "county": "Clinton",
        "value": "Clinton",
        "state": "IL"
    },
    {
        "code": "17029",
        "county": "Coles",
        "value": "Coles",
        "state": "IL"
    },
    {
        "code": "17031",
        "county": "Cook",
        "value": "Cook",
        "state": "IL"
    },
    {
        "code": "17033",
        "county": "Crawford",
        "value": "Crawford",
        "state": "IL"
    },
    {
        "code": "18029",
        "county": "Dearborn",
        "value": "Dearborn",
        "state": "IN"
    },
    {
        "code": "18031",
        "county": "Decatur",
        "value": "Decatur",
        "state": "IN"
    },
    {
        "code": "18033",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "IN"
    },
    {
        "code": "18035",
        "county": "Delaware",
        "value": "Delaware",
        "state": "IN"
    },
    {
        "code": "18037",
        "county": "Dubois",
        "value": "Dubois",
        "state": "IN"
    },
    {
        "code": "18039",
        "county": "Elkhart",
        "value": "Elkhart",
        "state": "IN"
    },
    {
        "code": "18041",
        "county": "Fayette",
        "value": "Fayette",
        "state": "IN"
    },
    {
        "code": "18043",
        "county": "Floyd",
        "value": "Floyd",
        "state": "IN"
    },
    {
        "code": "18045",
        "county": "Fountain",
        "value": "Fountain",
        "state": "IN"
    },
    {
        "code": "18047",
        "county": "Franklin",
        "value": "Franklin",
        "state": "IN"
    },
    {
        "code": "18049",
        "county": "Fulton",
        "value": "Fulton",
        "state": "IN"
    },
    {
        "code": "18051",
        "county": "Gibson",
        "value": "Gibson",
        "state": "IN"
    },
    {
        "code": "18053",
        "county": "Grant",
        "value": "Grant",
        "state": "IN"
    },
    {
        "code": "18055",
        "county": "Greene",
        "value": "Greene",
        "state": "IN"
    },
    {
        "code": "18057",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "IN"
    },
    {
        "code": "18059",
        "county": "Hancock",
        "value": "Hancock",
        "state": "IN"
    },
    {
        "code": "18061",
        "county": "Harrison",
        "value": "Harrison",
        "state": "IN"
    },
    {
        "code": "18063",
        "county": "Hendricks",
        "value": "Hendricks",
        "state": "IN"
    },
    {
        "code": "18065",
        "county": "Henry",
        "value": "Henry",
        "state": "IN"
    },
    {
        "code": "18067",
        "county": "Howard",
        "value": "Howard",
        "state": "IN"
    },
    {
        "code": "18069",
        "county": "Huntington",
        "value": "Huntington",
        "state": "IN"
    },
    {
        "code": "18071",
        "county": "Jackson",
        "value": "Jackson",
        "state": "IN"
    },
    {
        "code": "18075",
        "county": "Jay",
        "value": "Jay",
        "state": "IN"
    },
    {
        "code": "18077",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "IN"
    },
    {
        "code": "18079",
        "county": "Jennings",
        "value": "Jennings",
        "state": "IN"
    },
    {
        "code": "18081",
        "county": "Johnson",
        "value": "Johnson",
        "state": "IN"
    },
    {
        "code": "18083",
        "county": "Knox",
        "value": "Knox",
        "state": "IN"
    },
    {
        "code": "18085",
        "county": "Kosciusko",
        "value": "Kosciusko",
        "state": "IN"
    },
    {
        "code": "18073",
        "county": "Jasper",
        "value": "Jasper",
        "state": "IN"
    },
    {
        "code": "18087",
        "county": "Lagrange",
        "value": "Lagrange",
        "state": "IN"
    },
    {
        "code": "18089",
        "county": "Lake",
        "value": "Lake",
        "state": "IN"
    },
    {
        "code": "18091",
        "county": "La Porte",
        "value": "La Porte",
        "state": "IN"
    },
    {
        "code": "18093",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "IN"
    },
    {
        "code": "18095",
        "county": "Madison",
        "value": "Madison",
        "state": "IN"
    },
    {
        "code": "18097",
        "county": "Marion",
        "value": "Marion",
        "state": "IN"
    },
    {
        "code": "18099",
        "county": "Marshall",
        "value": "Marshall",
        "state": "IN"
    },
    {
        "code": "18101",
        "county": "Martin",
        "value": "Martin",
        "state": "IN"
    },
    {
        "code": "18103",
        "county": "Miami",
        "value": "Miami",
        "state": "IN"
    },
    {
        "code": "18105",
        "county": "Monroe",
        "value": "Monroe",
        "state": "IN"
    },
    {
        "code": "18107",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "IN"
    },
    {
        "code": "18109",
        "county": "Morgan",
        "value": "Morgan",
        "state": "IN"
    },
    {
        "code": "18111",
        "county": "Newton",
        "value": "Newton",
        "state": "IN"
    },
    {
        "code": "18113",
        "county": "Noble",
        "value": "Noble",
        "state": "IN"
    },
    {
        "code": "18115",
        "county": "Ohio",
        "value": "Ohio",
        "state": "IN"
    },
    {
        "code": "18117",
        "county": "Orange",
        "value": "Orange",
        "state": "IN"
    },
    {
        "code": "18119",
        "county": "Owen",
        "value": "Owen",
        "state": "IN"
    },
    {
        "code": "18121",
        "county": "Parke",
        "value": "Parke",
        "state": "IN"
    },
    {
        "code": "18123",
        "county": "Perry",
        "value": "Perry",
        "state": "IN"
    },
    {
        "code": "18125",
        "county": "Pike",
        "value": "Pike",
        "state": "IN"
    },
    {
        "code": "18127",
        "county": "Porter",
        "value": "Porter",
        "state": "IN"
    },
    {
        "code": "18129",
        "county": "Posey",
        "value": "Posey",
        "state": "IN"
    },
    {
        "code": "18131",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "IN"
    },
    {
        "code": "18133",
        "county": "Putnam",
        "value": "Putnam",
        "state": "IN"
    },
    {
        "code": "18135",
        "county": "Randolph",
        "value": "Randolph",
        "state": "IN"
    },
    {
        "code": "18137",
        "county": "Ripley",
        "value": "Ripley",
        "state": "IN"
    },
    {
        "code": "18139",
        "county": "Rush",
        "value": "Rush",
        "state": "IN"
    },
    {
        "code": "18141",
        "county": "St. Joseph",
        "value": "St. Joseph",
        "state": "IN"
    },
    {
        "code": "18143",
        "county": "Scott",
        "value": "Scott",
        "state": "IN"
    },
    {
        "code": "18145",
        "county": "Shelby",
        "value": "Shelby",
        "state": "IN"
    },
    {
        "code": "18147",
        "county": "Spencer",
        "value": "Spencer",
        "state": "IN"
    },
    {
        "code": "18149",
        "county": "Starke",
        "value": "Starke",
        "state": "IN"
    },
    {
        "code": "18151",
        "county": "Steuben",
        "value": "Steuben",
        "state": "IN"
    },
    {
        "code": "18153",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "IN"
    },
    {
        "code": "18155",
        "county": "Switzerland",
        "value": "Switzerland",
        "state": "IN"
    },
    {
        "code": "18157",
        "county": "Tippecanoe",
        "value": "Tippecanoe",
        "state": "IN"
    },
    {
        "code": "18159",
        "county": "Tipton",
        "value": "Tipton",
        "state": "IN"
    },
    {
        "code": "18161",
        "county": "Union",
        "value": "Union",
        "state": "IN"
    },
    {
        "code": "18163",
        "county": "Vanderburgh",
        "value": "Vanderburgh",
        "state": "IN"
    },
    {
        "code": "18165",
        "county": "Vermillion",
        "value": "Vermillion",
        "state": "IN"
    },
    {
        "code": "18167",
        "county": "Vigo",
        "value": "Vigo",
        "state": "IN"
    },
    {
        "code": "18169",
        "county": "Wabash",
        "value": "Wabash",
        "state": "IN"
    },
    {
        "code": "18171",
        "county": "Warren",
        "value": "Warren",
        "state": "IN"
    },
    {
        "code": "18173",
        "county": "Warrick",
        "value": "Warrick",
        "state": "IN"
    },
    {
        "code": "18175",
        "county": "Washington",
        "value": "Washington",
        "state": "IN"
    },
    {
        "code": "18177",
        "county": "Wayne",
        "value": "Wayne",
        "state": "IN"
    },
    {
        "code": "18179",
        "county": "Wells",
        "value": "Wells",
        "state": "IN"
    },
    {
        "code": "18181",
        "county": "White",
        "value": "White",
        "state": "IN"
    },
    {
        "code": "18183",
        "county": "Whitley",
        "value": "Whitley",
        "state": "IN"
    },
    {
        "code": "18000",
        "county": "Indiana",
        "value": "Indiana",
        "state": "IN"
    },
    {
        "code": "18001",
        "county": "Adams",
        "value": "Adams",
        "state": "IN"
    },
    {
        "code": "18003",
        "county": "Allen",
        "value": "Allen",
        "state": "IN"
    },
    {
        "code": "18005",
        "county": "Bartholomew",
        "value": "Bartholomew",
        "state": "IN"
    },
    {
        "code": "18007",
        "county": "Benton",
        "value": "Benton",
        "state": "IN"
    },
    {
        "code": "18009",
        "county": "Blackford",
        "value": "Blackford",
        "state": "IN"
    },
    {
        "code": "18011",
        "county": "Boone",
        "value": "Boone",
        "state": "IN"
    },
    {
        "code": "18013",
        "county": "Brown",
        "value": "Brown",
        "state": "IN"
    },
    {
        "code": "18015",
        "county": "Carroll",
        "value": "Carroll",
        "state": "IN"
    },
    {
        "code": "18017",
        "county": "Cass",
        "value": "Cass",
        "state": "IN"
    },
    {
        "code": "18019",
        "county": "Clark",
        "value": "Clark",
        "state": "IN"
    },
    {
        "code": "18021",
        "county": "Clay",
        "value": "Clay",
        "state": "IN"
    },
    {
        "code": "18023",
        "county": "Clinton",
        "value": "Clinton",
        "state": "IN"
    },
    {
        "code": "18025",
        "county": "Crawford",
        "value": "Crawford",
        "state": "IN"
    },
    {
        "code": "18027",
        "county": "Daviess",
        "value": "Daviess",
        "state": "IN"
    },
    {
        "code": "20153",
        "county": "Rawlins",
        "value": "Rawlins",
        "state": "KS"
    },
    {
        "code": "20155",
        "county": "Reno",
        "value": "Reno",
        "state": "KS"
    },
    {
        "code": "20157",
        "county": "Republic",
        "value": "Republic",
        "state": "KS"
    },
    {
        "code": "20159",
        "county": "Rice",
        "value": "Rice",
        "state": "KS"
    },
    {
        "code": "20161",
        "county": "Riley",
        "value": "Riley",
        "state": "KS"
    },
    {
        "code": "20163",
        "county": "Rooks",
        "value": "Rooks",
        "state": "KS"
    },
    {
        "code": "20165",
        "county": "Rush",
        "value": "Rush",
        "state": "KS"
    },
    {
        "code": "20167",
        "county": "Russell",
        "value": "Russell",
        "state": "KS"
    },
    {
        "code": "20169",
        "county": "Saline",
        "value": "Saline",
        "state": "KS"
    },
    {
        "code": "20171",
        "county": "Scott",
        "value": "Scott",
        "state": "KS"
    },
    {
        "code": "20173",
        "county": "Sedgwick",
        "value": "Sedgwick",
        "state": "KS"
    },
    {
        "code": "20175",
        "county": "Seward",
        "value": "Seward",
        "state": "KS"
    },
    {
        "code": "20177",
        "county": "Shawnee",
        "value": "Shawnee",
        "state": "KS"
    },
    {
        "code": "20179",
        "county": "Sheridan",
        "value": "Sheridan",
        "state": "KS"
    },
    {
        "code": "20181",
        "county": "Sherman",
        "value": "Sherman",
        "state": "KS"
    },
    {
        "code": "20183",
        "county": "Smith",
        "value": "Smith",
        "state": "KS"
    },
    {
        "code": "20185",
        "county": "Stafford",
        "value": "Stafford",
        "state": "KS"
    },
    {
        "code": "20187",
        "county": "Stanton",
        "value": "Stanton",
        "state": "KS"
    },
    {
        "code": "20189",
        "county": "Stevens",
        "value": "Stevens",
        "state": "KS"
    },
    {
        "code": "20191",
        "county": "Sumner",
        "value": "Sumner",
        "state": "KS"
    },
    {
        "code": "20193",
        "county": "Thomas",
        "value": "Thomas",
        "state": "KS"
    },
    {
        "code": "20025",
        "county": "Clark",
        "value": "Clark",
        "state": "KS"
    },
    {
        "code": "20027",
        "county": "Clay",
        "value": "Clay",
        "state": "KS"
    },
    {
        "code": "20029",
        "county": "Cloud",
        "value": "Cloud",
        "state": "KS"
    },
    {
        "code": "20031",
        "county": "Coffey",
        "value": "Coffey",
        "state": "KS"
    },
    {
        "code": "20033",
        "county": "Comanche",
        "value": "Comanche",
        "state": "KS"
    },
    {
        "code": "20035",
        "county": "Cowley",
        "value": "Cowley",
        "state": "KS"
    },
    {
        "code": "20037",
        "county": "Crawford",
        "value": "Crawford",
        "state": "KS"
    },
    {
        "code": "20039",
        "county": "Decatur",
        "value": "Decatur",
        "state": "KS"
    },
    {
        "code": "20041",
        "county": "Dickinson",
        "value": "Dickinson",
        "state": "KS"
    },
    {
        "code": "20043",
        "county": "Doniphan",
        "value": "Doniphan",
        "state": "KS"
    },
    {
        "code": "20045",
        "county": "Douglas",
        "value": "Douglas",
        "state": "KS"
    },
    {
        "code": "20047",
        "county": "Edwards",
        "value": "Edwards",
        "state": "KS"
    },
    {
        "code": "20049",
        "county": "Elk",
        "value": "Elk",
        "state": "KS"
    },
    {
        "code": "20051",
        "county": "Ellis",
        "value": "Ellis",
        "state": "KS"
    },
    {
        "code": "20053",
        "county": "Ellsworth",
        "value": "Ellsworth",
        "state": "KS"
    },
    {
        "code": "20055",
        "county": "Finney",
        "value": "Finney",
        "state": "KS"
    },
    {
        "code": "20057",
        "county": "Ford",
        "value": "Ford",
        "state": "KS"
    },
    {
        "code": "20059",
        "county": "Franklin",
        "value": "Franklin",
        "state": "KS"
    },
    {
        "code": "20061",
        "county": "Geary",
        "value": "Geary",
        "state": "KS"
    },
    {
        "code": "20063",
        "county": "Gove",
        "value": "Gove",
        "state": "KS"
    },
    {
        "code": "20065",
        "county": "Graham",
        "value": "Graham",
        "state": "KS"
    },
    {
        "code": "20067",
        "county": "Grant",
        "value": "Grant",
        "state": "KS"
    },
    {
        "code": "20069",
        "county": "Gray",
        "value": "Gray",
        "state": "KS"
    },
    {
        "code": "20071",
        "county": "Greeley",
        "value": "Greeley",
        "state": "KS"
    },
    {
        "code": "20073",
        "county": "Greenwood",
        "value": "Greenwood",
        "state": "KS"
    },
    {
        "code": "20075",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "KS"
    },
    {
        "code": "20077",
        "county": "Harper",
        "value": "Harper",
        "state": "KS"
    },
    {
        "code": "20079",
        "county": "Harvey",
        "value": "Harvey",
        "state": "KS"
    },
    {
        "code": "20081",
        "county": "Haskell",
        "value": "Haskell",
        "state": "KS"
    },
    {
        "code": "20083",
        "county": "Hodgeman",
        "value": "Hodgeman",
        "state": "KS"
    },
    {
        "code": "20085",
        "county": "Jackson",
        "value": "Jackson",
        "state": "KS"
    },
    {
        "code": "20087",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "KS"
    },
    {
        "code": "20089",
        "county": "Jewell",
        "value": "Jewell",
        "state": "KS"
    },
    {
        "code": "20091",
        "county": "Johnson",
        "value": "Johnson",
        "state": "KS"
    },
    {
        "code": "20093",
        "county": "Kearny",
        "value": "Kearny",
        "state": "KS"
    },
    {
        "code": "20095",
        "county": "Kingman",
        "value": "Kingman",
        "state": "KS"
    },
    {
        "code": "20097",
        "county": "Kiowa",
        "value": "Kiowa",
        "state": "KS"
    },
    {
        "code": "20099",
        "county": "Labette",
        "value": "Labette",
        "state": "KS"
    },
    {
        "code": "20101",
        "county": "Lane",
        "value": "Lane",
        "state": "KS"
    },
    {
        "code": "20103",
        "county": "Leavenworth",
        "value": "Leavenworth",
        "state": "KS"
    },
    {
        "code": "20105",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "KS"
    },
    {
        "code": "20107",
        "county": "Linn",
        "value": "Linn",
        "state": "KS"
    },
    {
        "code": "20109",
        "county": "Logan",
        "value": "Logan",
        "state": "KS"
    },
    {
        "code": "20111",
        "county": "Lyon",
        "value": "Lyon",
        "state": "KS"
    },
    {
        "code": "20113",
        "county": "McPherson",
        "value": "McPherson",
        "state": "KS"
    },
    {
        "code": "20115",
        "county": "Marion",
        "value": "Marion",
        "state": "KS"
    },
    {
        "code": "20117",
        "county": "Marshall",
        "value": "Marshall",
        "state": "KS"
    },
    {
        "code": "20119",
        "county": "Meade",
        "value": "Meade",
        "state": "KS"
    },
    {
        "code": "20121",
        "county": "Miami",
        "value": "Miami",
        "state": "KS"
    },
    {
        "code": "20123",
        "county": "Mitchell",
        "value": "Mitchell",
        "state": "KS"
    },
    {
        "code": "20125",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "KS"
    },
    {
        "code": "20127",
        "county": "Morris",
        "value": "Morris",
        "state": "KS"
    },
    {
        "code": "20129",
        "county": "Morton",
        "value": "Morton",
        "state": "KS"
    },
    {
        "code": "20131",
        "county": "Nemaha",
        "value": "Nemaha",
        "state": "KS"
    },
    {
        "code": "20133",
        "county": "Neosho",
        "value": "Neosho",
        "state": "KS"
    },
    {
        "code": "20135",
        "county": "Ness",
        "value": "Ness",
        "state": "KS"
    },
    {
        "code": "20137",
        "county": "Norton",
        "value": "Norton",
        "state": "KS"
    },
    {
        "code": "20139",
        "county": "Osage",
        "value": "Osage",
        "state": "KS"
    },
    {
        "code": "20141",
        "county": "Osborne",
        "value": "Osborne",
        "state": "KS"
    },
    {
        "code": "20143",
        "county": "Ottawa",
        "value": "Ottawa",
        "state": "KS"
    },
    {
        "code": "20145",
        "county": "Pawnee",
        "value": "Pawnee",
        "state": "KS"
    },
    {
        "code": "20147",
        "county": "Phillips",
        "value": "Phillips",
        "state": "KS"
    },
    {
        "code": "20149",
        "county": "Pottawatomie",
        "value": "Pottawatomie",
        "state": "KS"
    },
    {
        "code": "20151",
        "county": "Pratt",
        "value": "Pratt",
        "state": "KS"
    },
    {
        "code": "20195",
        "county": "Trego",
        "value": "Trego",
        "state": "KS"
    },
    {
        "code": "20197",
        "county": "Wabaunsee",
        "value": "Wabaunsee",
        "state": "KS"
    },
    {
        "code": "20199",
        "county": "Wallace",
        "value": "Wallace",
        "state": "KS"
    },
    {
        "code": "20201",
        "county": "Washington",
        "value": "Washington",
        "state": "KS"
    },
    {
        "code": "20203",
        "county": "Wichita",
        "value": "Wichita",
        "state": "KS"
    },
    {
        "code": "20205",
        "county": "Wilson",
        "value": "Wilson",
        "state": "KS"
    },
    {
        "code": "20207",
        "county": "Woodson",
        "value": "Woodson",
        "state": "KS"
    },
    {
        "code": "20209",
        "county": "Wyandotte",
        "value": "Wyandotte",
        "state": "KS"
    },
    {
        "code": "20000",
        "county": "Kansas",
        "value": "Kansas",
        "state": "KS"
    },
    {
        "code": "20001",
        "county": "Allen",
        "value": "Allen",
        "state": "KS"
    },
    {
        "code": "20003",
        "county": "Anderson",
        "value": "Anderson",
        "state": "KS"
    },
    {
        "code": "20005",
        "county": "Atchison",
        "value": "Atchison",
        "state": "KS"
    },
    {
        "code": "20007",
        "county": "Barber",
        "value": "Barber",
        "state": "KS"
    },
    {
        "code": "20009",
        "county": "Barton",
        "value": "Barton",
        "state": "KS"
    },
    {
        "code": "20011",
        "county": "Bourbon",
        "value": "Bourbon",
        "state": "KS"
    },
    {
        "code": "20013",
        "county": "Brown",
        "value": "Brown",
        "state": "KS"
    },
    {
        "code": "20015",
        "county": "Butler",
        "value": "Butler",
        "state": "KS"
    },
    {
        "code": "20017",
        "county": "Chase",
        "value": "Chase",
        "state": "KS"
    },
    {
        "code": "20019",
        "county": "Chautauqua",
        "value": "Chautauqua",
        "state": "KS"
    },
    {
        "code": "20021",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "KS"
    },
    {
        "code": "20023",
        "county": "Cheyenne",
        "value": "Cheyenne",
        "state": "KS"
    },
    {
        "code": "21143",
        "county": "Lyon",
        "value": "Lyon",
        "state": "KY"
    },
    {
        "code": "21145",
        "county": "McCracken",
        "value": "McCracken",
        "state": "KY"
    },
    {
        "code": "21147",
        "county": "McCreary",
        "value": "McCreary",
        "state": "KY"
    },
    {
        "code": "21149",
        "county": "McLean",
        "value": "McLean",
        "state": "KY"
    },
    {
        "code": "21151",
        "county": "Madison",
        "value": "Madison",
        "state": "KY"
    },
    {
        "code": "21153",
        "county": "Magoffin",
        "value": "Magoffin",
        "state": "KY"
    },
    {
        "code": "21155",
        "county": "Marion",
        "value": "Marion",
        "state": "KY"
    },
    {
        "code": "21157",
        "county": "Marshall",
        "value": "Marshall",
        "state": "KY"
    },
    {
        "code": "21159",
        "county": "Martin",
        "value": "Martin",
        "state": "KY"
    },
    {
        "code": "21161",
        "county": "Mason",
        "value": "Mason",
        "state": "KY"
    },
    {
        "code": "21163",
        "county": "Meade",
        "value": "Meade",
        "state": "KY"
    },
    {
        "code": "21165",
        "county": "Menifee",
        "value": "Menifee",
        "state": "KY"
    },
    {
        "code": "21167",
        "county": "Mercer",
        "value": "Mercer",
        "state": "KY"
    },
    {
        "code": "21169",
        "county": "Metcalfe",
        "value": "Metcalfe",
        "state": "KY"
    },
    {
        "code": "21000",
        "county": "Kentucky",
        "value": "Kentucky",
        "state": "KY"
    },
    {
        "code": "21001",
        "county": "Adair",
        "value": "Adair",
        "state": "KY"
    },
    {
        "code": "21003",
        "county": "Allen",
        "value": "Allen",
        "state": "KY"
    },
    {
        "code": "21005",
        "county": "Anderson",
        "value": "Anderson",
        "state": "KY"
    },
    {
        "code": "21007",
        "county": "Ballard",
        "value": "Ballard",
        "state": "KY"
    },
    {
        "code": "21009",
        "county": "Barren",
        "value": "Barren",
        "state": "KY"
    },
    {
        "code": "21011",
        "county": "Bath",
        "value": "Bath",
        "state": "KY"
    },
    {
        "code": "21013",
        "county": "Bell",
        "value": "Bell",
        "state": "KY"
    },
    {
        "code": "21015",
        "county": "Boone",
        "value": "Boone",
        "state": "KY"
    },
    {
        "code": "21017",
        "county": "Bourbon",
        "value": "Bourbon",
        "state": "KY"
    },
    {
        "code": "21019",
        "county": "Boyd",
        "value": "Boyd",
        "state": "KY"
    },
    {
        "code": "21021",
        "county": "Boyle",
        "value": "Boyle",
        "state": "KY"
    },
    {
        "code": "21023",
        "county": "Bracken",
        "value": "Bracken",
        "state": "KY"
    },
    {
        "code": "21025",
        "county": "Breathitt",
        "value": "Breathitt",
        "state": "KY"
    },
    {
        "code": "21027",
        "county": "Breckinridge",
        "value": "Breckinridge",
        "state": "KY"
    },
    {
        "code": "21029",
        "county": "Bullitt",
        "value": "Bullitt",
        "state": "KY"
    },
    {
        "code": "21031",
        "county": "Butler",
        "value": "Butler",
        "state": "KY"
    },
    {
        "code": "21033",
        "county": "Caldwell",
        "value": "Caldwell",
        "state": "KY"
    },
    {
        "code": "21035",
        "county": "Calloway",
        "value": "Calloway",
        "state": "KY"
    },
    {
        "code": "21037",
        "county": "Campbell",
        "value": "Campbell",
        "state": "KY"
    },
    {
        "code": "21039",
        "county": "Carlisle",
        "value": "Carlisle",
        "state": "KY"
    },
    {
        "code": "21041",
        "county": "Carroll",
        "value": "Carroll",
        "state": "KY"
    },
    {
        "code": "21043",
        "county": "Carter",
        "value": "Carter",
        "state": "KY"
    },
    {
        "code": "21045",
        "county": "Casey",
        "value": "Casey",
        "state": "KY"
    },
    {
        "code": "21047",
        "county": "Christian",
        "value": "Christian",
        "state": "KY"
    },
    {
        "code": "21049",
        "county": "Clark",
        "value": "Clark",
        "state": "KY"
    },
    {
        "code": "21051",
        "county": "Clay",
        "value": "Clay",
        "state": "KY"
    },
    {
        "code": "21053",
        "county": "Clinton",
        "value": "Clinton",
        "state": "KY"
    },
    {
        "code": "21055",
        "county": "Crittenden",
        "value": "Crittenden",
        "state": "KY"
    },
    {
        "code": "21057",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "KY"
    },
    {
        "code": "21059",
        "county": "Daviess",
        "value": "Daviess",
        "state": "KY"
    },
    {
        "code": "21061",
        "county": "Edmonson",
        "value": "Edmonson",
        "state": "KY"
    },
    {
        "code": "21063",
        "county": "Elliott",
        "value": "Elliott",
        "state": "KY"
    },
    {
        "code": "21065",
        "county": "Estill",
        "value": "Estill",
        "state": "KY"
    },
    {
        "code": "21067",
        "county": "Fayette",
        "value": "Fayette",
        "state": "KY"
    },
    {
        "code": "21069",
        "county": "Fleming",
        "value": "Fleming",
        "state": "KY"
    },
    {
        "code": "21071",
        "county": "Floyd",
        "value": "Floyd",
        "state": "KY"
    },
    {
        "code": "21073",
        "county": "Franklin",
        "value": "Franklin",
        "state": "KY"
    },
    {
        "code": "21075",
        "county": "Fulton",
        "value": "Fulton",
        "state": "KY"
    },
    {
        "code": "21077",
        "county": "Gallatin",
        "value": "Gallatin",
        "state": "KY"
    },
    {
        "code": "21079",
        "county": "Garrard",
        "value": "Garrard",
        "state": "KY"
    },
    {
        "code": "21081",
        "county": "Grant",
        "value": "Grant",
        "state": "KY"
    },
    {
        "code": "21083",
        "county": "Graves",
        "value": "Graves",
        "state": "KY"
    },
    {
        "code": "21171",
        "county": "Monroe",
        "value": "Monroe",
        "state": "KY"
    },
    {
        "code": "21173",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "KY"
    },
    {
        "code": "21175",
        "county": "Morgan",
        "value": "Morgan",
        "state": "KY"
    },
    {
        "code": "21177",
        "county": "Muhlenberg",
        "value": "Muhlenberg",
        "state": "KY"
    },
    {
        "code": "21179",
        "county": "Nelson",
        "value": "Nelson",
        "state": "KY"
    },
    {
        "code": "21181",
        "county": "Nicholas",
        "value": "Nicholas",
        "state": "KY"
    },
    {
        "code": "21183",
        "county": "Ohio",
        "value": "Ohio",
        "state": "KY"
    },
    {
        "code": "21185",
        "county": "Oldham",
        "value": "Oldham",
        "state": "KY"
    },
    {
        "code": "21187",
        "county": "Owen",
        "value": "Owen",
        "state": "KY"
    },
    {
        "code": "21189",
        "county": "Owsley",
        "value": "Owsley",
        "state": "KY"
    },
    {
        "code": "21191",
        "county": "Pendleton",
        "value": "Pendleton",
        "state": "KY"
    },
    {
        "code": "21193",
        "county": "Perry",
        "value": "Perry",
        "state": "KY"
    },
    {
        "code": "21195",
        "county": "Pike",
        "value": "Pike",
        "state": "KY"
    },
    {
        "code": "21197",
        "county": "Powell",
        "value": "Powell",
        "state": "KY"
    },
    {
        "code": "21199",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "KY"
    },
    {
        "code": "21201",
        "county": "Robertson",
        "value": "Robertson",
        "state": "KY"
    },
    {
        "code": "21203",
        "county": "Rockcastle",
        "value": "Rockcastle",
        "state": "KY"
    },
    {
        "code": "21205",
        "county": "Rowan",
        "value": "Rowan",
        "state": "KY"
    },
    {
        "code": "21207",
        "county": "Russell",
        "value": "Russell",
        "state": "KY"
    },
    {
        "code": "21209",
        "county": "Scott",
        "value": "Scott",
        "state": "KY"
    },
    {
        "code": "21211",
        "county": "Shelby",
        "value": "Shelby",
        "state": "KY"
    },
    {
        "code": "21213",
        "county": "Simpson",
        "value": "Simpson",
        "state": "KY"
    },
    {
        "code": "21215",
        "county": "Spencer",
        "value": "Spencer",
        "state": "KY"
    },
    {
        "code": "21217",
        "county": "Taylor",
        "value": "Taylor",
        "state": "KY"
    },
    {
        "code": "21219",
        "county": "Todd",
        "value": "Todd",
        "state": "KY"
    },
    {
        "code": "21221",
        "county": "Trigg",
        "value": "Trigg",
        "state": "KY"
    },
    {
        "code": "21223",
        "county": "Trimble",
        "value": "Trimble",
        "state": "KY"
    },
    {
        "code": "21225",
        "county": "Union",
        "value": "Union",
        "state": "KY"
    },
    {
        "code": "21227",
        "county": "Warren",
        "value": "Warren",
        "state": "KY"
    },
    {
        "code": "21229",
        "county": "Washington",
        "value": "Washington",
        "state": "KY"
    },
    {
        "code": "21231",
        "county": "Wayne",
        "value": "Wayne",
        "state": "KY"
    },
    {
        "code": "21233",
        "county": "Webster",
        "value": "Webster",
        "state": "KY"
    },
    {
        "code": "21235",
        "county": "Whitley",
        "value": "Whitley",
        "state": "KY"
    },
    {
        "code": "21237",
        "county": "Wolfe",
        "value": "Wolfe",
        "state": "KY"
    },
    {
        "code": "21239",
        "county": "Woodford",
        "value": "Woodford",
        "state": "KY"
    },
    {
        "code": "21085",
        "county": "Grayson",
        "value": "Grayson",
        "state": "KY"
    },
    {
        "code": "21087",
        "county": "Green",
        "value": "Green",
        "state": "KY"
    },
    {
        "code": "21089",
        "county": "Greenup",
        "value": "Greenup",
        "state": "KY"
    },
    {
        "code": "21091",
        "county": "Hancock",
        "value": "Hancock",
        "state": "KY"
    },
    {
        "code": "21093",
        "county": "Hardin",
        "value": "Hardin",
        "state": "KY"
    },
    {
        "code": "21095",
        "county": "Harlan",
        "value": "Harlan",
        "state": "KY"
    },
    {
        "code": "21097",
        "county": "Harrison",
        "value": "Harrison",
        "state": "KY"
    },
    {
        "code": "21099",
        "county": "Hart",
        "value": "Hart",
        "state": "KY"
    },
    {
        "code": "21101",
        "county": "Henderson",
        "value": "Henderson",
        "state": "KY"
    },
    {
        "code": "21103",
        "county": "Henry",
        "value": "Henry",
        "state": "KY"
    },
    {
        "code": "21105",
        "county": "Hickman",
        "value": "Hickman",
        "state": "KY"
    },
    {
        "code": "21107",
        "county": "Hopkins",
        "value": "Hopkins",
        "state": "KY"
    },
    {
        "code": "21109",
        "county": "Jackson",
        "value": "Jackson",
        "state": "KY"
    },
    {
        "code": "21111",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "KY"
    },
    {
        "code": "21113",
        "county": "Jessamine",
        "value": "Jessamine",
        "state": "KY"
    },
    {
        "code": "21115",
        "county": "Johnson",
        "value": "Johnson",
        "state": "KY"
    },
    {
        "code": "21117",
        "county": "Kenton",
        "value": "Kenton",
        "state": "KY"
    },
    {
        "code": "21119",
        "county": "Knott",
        "value": "Knott",
        "state": "KY"
    },
    {
        "code": "21121",
        "county": "Knox",
        "value": "Knox",
        "state": "KY"
    },
    {
        "code": "21123",
        "county": "Larue",
        "value": "Larue",
        "state": "KY"
    },
    {
        "code": "21125",
        "county": "Laurel",
        "value": "Laurel",
        "state": "KY"
    },
    {
        "code": "21127",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "KY"
    },
    {
        "code": "21129",
        "county": "Lee",
        "value": "Lee",
        "state": "KY"
    },
    {
        "code": "21131",
        "county": "Leslie",
        "value": "Leslie",
        "state": "KY"
    },
    {
        "code": "21133",
        "county": "Letcher",
        "value": "Letcher",
        "state": "KY"
    },
    {
        "code": "21135",
        "county": "Lewis",
        "value": "Lewis",
        "state": "KY"
    },
    {
        "code": "21137",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "KY"
    },
    {
        "code": "21139",
        "county": "Livingston",
        "value": "Livingston",
        "state": "KY"
    },
    {
        "code": "21141",
        "county": "Logan",
        "value": "Logan",
        "state": "KY"
    },
    {
        "code": "22127",
        "county": "Winn Parish",
        "value": "Winn Parish",
        "state": "LA"
    },
    {
        "code": "22000",
        "county": "Louisiana",
        "value": "Louisiana",
        "state": "LA"
    },
    {
        "code": "22001",
        "county": "Acadia Parish",
        "value": "Acadia Parish",
        "state": "LA"
    },
    {
        "code": "22003",
        "county": "Allen Parish",
        "value": "Allen Parish",
        "state": "LA"
    },
    {
        "code": "22005",
        "county": "Ascension Parish",
        "value": "Ascension Parish",
        "state": "LA"
    },
    {
        "code": "22007",
        "county": "Assumption Parish",
        "value": "Assumption Parish",
        "state": "LA"
    },
    {
        "code": "22009",
        "county": "Avoyelles Parish",
        "value": "Avoyelles Parish",
        "state": "LA"
    },
    {
        "code": "22011",
        "county": "Beauregard Parish",
        "value": "Beauregard Parish",
        "state": "LA"
    },
    {
        "code": "22013",
        "county": "Bienville Parish",
        "value": "Bienville Parish",
        "state": "LA"
    },
    {
        "code": "22015",
        "county": "Bossier Parish",
        "value": "Bossier Parish",
        "state": "LA"
    },
    {
        "code": "22017",
        "county": "Caddo Parish",
        "value": "Caddo Parish",
        "state": "LA"
    },
    {
        "code": "22019",
        "county": "Calcasieu Parish",
        "value": "Calcasieu Parish",
        "state": "LA"
    },
    {
        "code": "22021",
        "county": "Caldwell Parish",
        "value": "Caldwell Parish",
        "state": "LA"
    },
    {
        "code": "22023",
        "county": "Cameron Parish",
        "value": "Cameron Parish",
        "state": "LA"
    },
    {
        "code": "22025",
        "county": "Catahoula Parish",
        "value": "Catahoula Parish",
        "state": "LA"
    },
    {
        "code": "22027",
        "county": "Claiborne Parish",
        "value": "Claiborne Parish",
        "state": "LA"
    },
    {
        "code": "22029",
        "county": "Concordia Parish",
        "value": "Concordia Parish",
        "state": "LA"
    },
    {
        "code": "22031",
        "county": "De Soto Parish",
        "value": "De Soto Parish",
        "state": "LA"
    },
    {
        "code": "22033",
        "county": "East Baton Rouge Parish",
        "value": "East Baton Rouge Parish",
        "state": "LA"
    },
    {
        "code": "22035",
        "county": "East Carroll Parish",
        "value": "East Carroll Parish",
        "state": "LA"
    },
    {
        "code": "22037",
        "county": "East Feliciana Parish",
        "value": "East Feliciana Parish",
        "state": "LA"
    },
    {
        "code": "22039",
        "county": "Evangeline Parish",
        "value": "Evangeline Parish",
        "state": "LA"
    },
    {
        "code": "22041",
        "county": "Franklin Parish",
        "value": "Franklin Parish",
        "state": "LA"
    },
    {
        "code": "22043",
        "county": "Grant Parish",
        "value": "Grant Parish",
        "state": "LA"
    },
    {
        "code": "22045",
        "county": "Iberia Parish",
        "value": "Iberia Parish",
        "state": "LA"
    },
    {
        "code": "22047",
        "county": "Iberville Parish",
        "value": "Iberville Parish",
        "state": "LA"
    },
    {
        "code": "22049",
        "county": "Jackson Parish",
        "value": "Jackson Parish",
        "state": "LA"
    },
    {
        "code": "22051",
        "county": "Jefferson Parish",
        "value": "Jefferson Parish",
        "state": "LA"
    },
    {
        "code": "22053",
        "county": "Jefferson Davis Parish",
        "value": "Jefferson Davis Parish",
        "state": "LA"
    },
    {
        "code": "22055",
        "county": "Lafayette Parish",
        "value": "Lafayette Parish",
        "state": "LA"
    },
    {
        "code": "22057",
        "county": "Lafourche Parish",
        "value": "Lafourche Parish",
        "state": "LA"
    },
    {
        "code": "22059",
        "county": "La Salle Parish",
        "value": "La Salle Parish",
        "state": "LA"
    },
    {
        "code": "22061",
        "county": "Lincoln Parish",
        "value": "Lincoln Parish",
        "state": "LA"
    },
    {
        "code": "22063",
        "county": "Livingston Parish",
        "value": "Livingston Parish",
        "state": "LA"
    },
    {
        "code": "22065",
        "county": "Madison Parish",
        "value": "Madison Parish",
        "state": "LA"
    },
    {
        "code": "22067",
        "county": "Morehouse Parish",
        "value": "Morehouse Parish",
        "state": "LA"
    },
    {
        "code": "22069",
        "county": "Natchitoches Parish",
        "value": "Natchitoches Parish",
        "state": "LA"
    },
    {
        "code": "22071",
        "county": "Orleans Parish",
        "value": "Orleans Parish",
        "state": "LA"
    },
    {
        "code": "22073",
        "county": "Ouachita Parish",
        "value": "Ouachita Parish",
        "state": "LA"
    },
    {
        "code": "22075",
        "county": "Plaquemines Parish",
        "value": "Plaquemines Parish",
        "state": "LA"
    },
    {
        "code": "22077",
        "county": "Pointe Coupee Parish",
        "value": "Pointe Coupee Parish",
        "state": "LA"
    },
    {
        "code": "22079",
        "county": "Rapides Parish",
        "value": "Rapides Parish",
        "state": "LA"
    },
    {
        "code": "22081",
        "county": "Red River Parish",
        "value": "Red River Parish",
        "state": "LA"
    },
    {
        "code": "22083",
        "county": "Richland Parish",
        "value": "Richland Parish",
        "state": "LA"
    },
    {
        "code": "22085",
        "county": "Sabine Parish",
        "value": "Sabine Parish",
        "state": "LA"
    },
    {
        "code": "22087",
        "county": "St. Bernard Parish",
        "value": "St. Bernard Parish",
        "state": "LA"
    },
    {
        "code": "22089",
        "county": "St. Charles Parish",
        "value": "St. Charles Parish",
        "state": "LA"
    },
    {
        "code": "22091",
        "county": "St. Helena Parish",
        "value": "St. Helena Parish",
        "state": "LA"
    },
    {
        "code": "22093",
        "county": "St. James Parish",
        "value": "St. James Parish",
        "state": "LA"
    },
    {
        "code": "22095",
        "county": "St. John the Baptist Parish",
        "value": "St. John the Baptist Parish",
        "state": "LA"
    },
    {
        "code": "22097",
        "county": "St. Landry Parish",
        "value": "St. Landry Parish",
        "state": "LA"
    },
    {
        "code": "22099",
        "county": "St. Martin Parish",
        "value": "St. Martin Parish",
        "state": "LA"
    },
    {
        "code": "22101",
        "county": "St. Mary Parish",
        "value": "St. Mary Parish",
        "state": "LA"
    },
    {
        "code": "22103",
        "county": "St. Tammany Parish",
        "value": "St. Tammany Parish",
        "state": "LA"
    },
    {
        "code": "22105",
        "county": "Tangipahoa Parish",
        "value": "Tangipahoa Parish",
        "state": "LA"
    },
    {
        "code": "22107",
        "county": "Tensas Parish",
        "value": "Tensas Parish",
        "state": "LA"
    },
    {
        "code": "22109",
        "county": "Terrebonne Parish",
        "value": "Terrebonne Parish",
        "state": "LA"
    },
    {
        "code": "22111",
        "county": "Union Parish",
        "value": "Union Parish",
        "state": "LA"
    },
    {
        "code": "22113",
        "county": "Vermilion Parish",
        "value": "Vermilion Parish",
        "state": "LA"
    },
    {
        "code": "22115",
        "county": "Vernon Parish",
        "value": "Vernon Parish",
        "state": "LA"
    },
    {
        "code": "22117",
        "county": "Washington Parish",
        "value": "Washington Parish",
        "state": "LA"
    },
    {
        "code": "22119",
        "county": "Webster Parish",
        "value": "Webster Parish",
        "state": "LA"
    },
    {
        "code": "22121",
        "county": "West Baton Rouge Parish",
        "value": "West Baton Rouge Parish",
        "state": "LA"
    },
    {
        "code": "47031",
        "county": "Coffee",
        "value": "Coffee",
        "state": "TN"
    },
    {
        "code": "47033",
        "county": "Crockett",
        "value": "Crockett",
        "state": "TN"
    },
    {
        "code": "47035",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "TN"
    },
    {
        "code": "47037",
        "county": "Davidson",
        "value": "Davidson",
        "state": "TN"
    },
    {
        "code": "47039",
        "county": "Decatur",
        "value": "Decatur",
        "state": "TN"
    },
    {
        "code": "47041",
        "county": "DeKalb",
        "value": "DeKalb",
        "state": "TN"
    },
    {
        "code": "47043",
        "county": "Dickson",
        "value": "Dickson",
        "state": "TN"
    },
    {
        "code": "47045",
        "county": "Dyer",
        "value": "Dyer",
        "state": "TN"
    },
    {
        "code": "47047",
        "county": "Fayette",
        "value": "Fayette",
        "state": "TN"
    },
    {
        "code": "47049",
        "county": "Fentress",
        "value": "Fentress",
        "state": "TN"
    },
    {
        "code": "47051",
        "county": "Franklin",
        "value": "Franklin",
        "state": "TN"
    },
    {
        "code": "47053",
        "county": "Gibson",
        "value": "Gibson",
        "state": "TN"
    },
    {
        "code": "47055",
        "county": "Giles",
        "value": "Giles",
        "state": "TN"
    },
    {
        "code": "47057",
        "county": "Grainger",
        "value": "Grainger",
        "state": "TN"
    },
    {
        "code": "47059",
        "county": "Greene",
        "value": "Greene",
        "state": "TN"
    },
    {
        "code": "47061",
        "county": "Grundy",
        "value": "Grundy",
        "state": "TN"
    },
    {
        "code": "48447",
        "county": "Throckmorton",
        "value": "Throckmorton",
        "state": "TX"
    },
    {
        "code": "48449",
        "county": "Titus",
        "value": "Titus",
        "state": "TX"
    },
    {
        "code": "48451",
        "county": "Tom Green",
        "value": "Tom Green",
        "state": "TX"
    },
    {
        "code": "48453",
        "county": "Travis",
        "value": "Travis",
        "state": "TX"
    },
    {
        "code": "48455",
        "county": "Trinity",
        "value": "Trinity",
        "state": "TX"
    },
    {
        "code": "48457",
        "county": "Tyler",
        "value": "Tyler",
        "state": "TX"
    },
    {
        "code": "48459",
        "county": "Upshur",
        "value": "Upshur",
        "state": "TX"
    },
    {
        "code": "48461",
        "county": "Upton",
        "value": "Upton",
        "state": "TX"
    },
    {
        "code": "48463",
        "county": "Uvalde",
        "value": "Uvalde",
        "state": "TX"
    },
    {
        "code": "48465",
        "county": "Val Verde",
        "value": "Val Verde",
        "state": "TX"
    },
    {
        "code": "48467",
        "county": "Van Zandt",
        "value": "Van Zandt",
        "state": "TX"
    },
    {
        "code": "48469",
        "county": "Victoria",
        "value": "Victoria",
        "state": "TX"
    },
    {
        "code": "48471",
        "county": "Walker",
        "value": "Walker",
        "state": "TX"
    },
    {
        "code": "48473",
        "county": "Waller",
        "value": "Waller",
        "state": "TX"
    },
    {
        "code": "48475",
        "county": "Ward",
        "value": "Ward",
        "state": "TX"
    },
    {
        "code": "48477",
        "county": "Washington",
        "value": "Washington",
        "state": "TX"
    },
    {
        "code": "48479",
        "county": "Webb",
        "value": "Webb",
        "state": "TX"
    },
    {
        "code": "48481",
        "county": "Wharton",
        "value": "Wharton",
        "state": "TX"
    },
    {
        "code": "48483",
        "county": "Wheeler",
        "value": "Wheeler",
        "state": "TX"
    },
    {
        "code": "48485",
        "county": "Wichita",
        "value": "Wichita",
        "state": "TX"
    },
    {
        "code": "48487",
        "county": "Wilbarger",
        "value": "Wilbarger",
        "state": "TX"
    },
    {
        "code": "48489",
        "county": "Willacy",
        "value": "Willacy",
        "state": "TX"
    },
    {
        "code": "48491",
        "county": "Williamson",
        "value": "Williamson",
        "state": "TX"
    },
    {
        "code": "48493",
        "county": "Wilson",
        "value": "Wilson",
        "state": "TX"
    },
    {
        "code": "48495",
        "county": "Winkler",
        "value": "Winkler",
        "state": "TX"
    },
    {
        "code": "48497",
        "county": "Wise",
        "value": "Wise",
        "state": "TX"
    },
    {
        "code": "48499",
        "county": "Wood",
        "value": "Wood",
        "state": "TX"
    },
    {
        "code": "48501",
        "county": "Yoakum",
        "value": "Yoakum",
        "state": "TX"
    },
    {
        "code": "48503",
        "county": "Young",
        "value": "Young",
        "state": "TX"
    },
    {
        "code": "48505",
        "county": "Zapata",
        "value": "Zapata",
        "state": "TX"
    },
    {
        "code": "48507",
        "county": "Zavala",
        "value": "Zavala",
        "state": "TX"
    },
    {
        "code": "48319",
        "county": "Mason",
        "value": "Mason",
        "state": "TX"
    },
    {
        "code": "48321",
        "county": "Matagorda",
        "value": "Matagorda",
        "state": "TX"
    },
    {
        "code": "48323",
        "county": "Maverick",
        "value": "Maverick",
        "state": "TX"
    },
    {
        "code": "48325",
        "county": "Medina",
        "value": "Medina",
        "state": "TX"
    },
    {
        "code": "48327",
        "county": "Menard",
        "value": "Menard",
        "state": "TX"
    },
    {
        "code": "48329",
        "county": "Midland",
        "value": "Midland",
        "state": "TX"
    },
    {
        "code": "48331",
        "county": "Milam",
        "value": "Milam",
        "state": "TX"
    },
    {
        "code": "48333",
        "county": "Mills",
        "value": "Mills",
        "state": "TX"
    },
    {
        "code": "48335",
        "county": "Mitchell",
        "value": "Mitchell",
        "state": "TX"
    },
    {
        "code": "48337",
        "county": "Montague",
        "value": "Montague",
        "state": "TX"
    },
    {
        "code": "48339",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "TX"
    },
    {
        "code": "48341",
        "county": "Moore",
        "value": "Moore",
        "state": "TX"
    },
    {
        "code": "48343",
        "county": "Morris",
        "value": "Morris",
        "state": "TX"
    },
    {
        "code": "48345",
        "county": "Motley",
        "value": "Motley",
        "state": "TX"
    },
    {
        "code": "48347",
        "county": "Nacogdoches",
        "value": "Nacogdoches",
        "state": "TX"
    },
    {
        "code": "48349",
        "county": "Navarro",
        "value": "Navarro",
        "state": "TX"
    },
    {
        "code": "48351",
        "county": "Newton",
        "value": "Newton",
        "state": "TX"
    },
    {
        "code": "48353",
        "county": "Nolan",
        "value": "Nolan",
        "state": "TX"
    },
    {
        "code": "48355",
        "county": "Nueces",
        "value": "Nueces",
        "state": "TX"
    },
    {
        "code": "48357",
        "county": "Ochiltree",
        "value": "Ochiltree",
        "state": "TX"
    },
    {
        "code": "48359",
        "county": "Oldham",
        "value": "Oldham",
        "state": "TX"
    },
    {
        "code": "48361",
        "county": "Orange",
        "value": "Orange",
        "state": "TX"
    },
    {
        "code": "48363",
        "county": "Palo Pinto",
        "value": "Palo Pinto",
        "state": "TX"
    },
    {
        "code": "48365",
        "county": "Panola",
        "value": "Panola",
        "state": "TX"
    },
    {
        "code": "48367",
        "county": "Parker",
        "value": "Parker",
        "state": "TX"
    },
    {
        "code": "48369",
        "county": "Parmer",
        "value": "Parmer",
        "state": "TX"
    },
    {
        "code": "48371",
        "county": "Pecos",
        "value": "Pecos",
        "state": "TX"
    },
    {
        "code": "48373",
        "county": "Polk",
        "value": "Polk",
        "state": "TX"
    },
    {
        "code": "48375",
        "county": "Potter",
        "value": "Potter",
        "state": "TX"
    },
    {
        "code": "48377",
        "county": "Presidio",
        "value": "Presidio",
        "state": "TX"
    },
    {
        "code": "48379",
        "county": "Rains",
        "value": "Rains",
        "state": "TX"
    },
    {
        "code": "48381",
        "county": "Randall",
        "value": "Randall",
        "state": "TX"
    },
    {
        "code": "48383",
        "county": "Reagan",
        "value": "Reagan",
        "state": "TX"
    },
    {
        "code": "48385",
        "county": "Real",
        "value": "Real",
        "state": "TX"
    },
    {
        "code": "48387",
        "county": "Red River",
        "value": "Red River",
        "state": "TX"
    },
    {
        "code": "48389",
        "county": "Reeves",
        "value": "Reeves",
        "state": "TX"
    },
    {
        "code": "48391",
        "county": "Refugio",
        "value": "Refugio",
        "state": "TX"
    },
    {
        "code": "48393",
        "county": "Roberts",
        "value": "Roberts",
        "state": "TX"
    },
    {
        "code": "48395",
        "county": "Robertson",
        "value": "Robertson",
        "state": "TX"
    },
    {
        "code": "48397",
        "county": "Rockwall",
        "value": "Rockwall",
        "state": "TX"
    },
    {
        "code": "48399",
        "county": "Runnels",
        "value": "Runnels",
        "state": "TX"
    },
    {
        "code": "48401",
        "county": "Rusk",
        "value": "Rusk",
        "state": "TX"
    },
    {
        "code": "48403",
        "county": "Sabine",
        "value": "Sabine",
        "state": "TX"
    },
    {
        "code": "48405",
        "county": "San Augustine",
        "value": "San Augustine",
        "state": "TX"
    },
    {
        "code": "48407",
        "county": "San Jacinto",
        "value": "San Jacinto",
        "state": "TX"
    },
    {
        "code": "48409",
        "county": "San Patricio",
        "value": "San Patricio",
        "state": "TX"
    },
    {
        "code": "48411",
        "county": "San Saba",
        "value": "San Saba",
        "state": "TX"
    },
    {
        "code": "48413",
        "county": "Schleicher",
        "value": "Schleicher",
        "state": "TX"
    },
    {
        "code": "48415",
        "county": "Scurry",
        "value": "Scurry",
        "state": "TX"
    },
    {
        "code": "48417",
        "county": "Shackelford",
        "value": "Shackelford",
        "state": "TX"
    },
    {
        "code": "48419",
        "county": "Shelby",
        "value": "Shelby",
        "state": "TX"
    },
    {
        "code": "48421",
        "county": "Sherman",
        "value": "Sherman",
        "state": "TX"
    },
    {
        "code": "48423",
        "county": "Smith",
        "value": "Smith",
        "state": "TX"
    },
    {
        "code": "48425",
        "county": "Somervell",
        "value": "Somervell",
        "state": "TX"
    },
    {
        "code": "48427",
        "county": "Starr",
        "value": "Starr",
        "state": "TX"
    },
    {
        "code": "48429",
        "county": "Stephens",
        "value": "Stephens",
        "state": "TX"
    },
    {
        "code": "48431",
        "county": "Sterling",
        "value": "Sterling",
        "state": "TX"
    },
    {
        "code": "48433",
        "county": "Stonewall",
        "value": "Stonewall",
        "state": "TX"
    },
    {
        "code": "48435",
        "county": "Sutton",
        "value": "Sutton",
        "state": "TX"
    },
    {
        "code": "48437",
        "county": "Swisher",
        "value": "Swisher",
        "state": "TX"
    },
    {
        "code": "48439",
        "county": "Tarrant",
        "value": "Tarrant",
        "state": "TX"
    },
    {
        "code": "48441",
        "county": "Taylor",
        "value": "Taylor",
        "state": "TX"
    },
    {
        "code": "48443",
        "county": "Terrell",
        "value": "Terrell",
        "state": "TX"
    },
    {
        "code": "48445",
        "county": "Terry",
        "value": "Terry",
        "state": "TX"
    },
    {
        "code": "48191",
        "county": "Hall",
        "value": "Hall",
        "state": "TX"
    },
    {
        "code": "48193",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "TX"
    },
    {
        "code": "48195",
        "county": "Hansford",
        "value": "Hansford",
        "state": "TX"
    },
    {
        "code": "48197",
        "county": "Hardeman",
        "value": "Hardeman",
        "state": "TX"
    },
    {
        "code": "48199",
        "county": "Hardin",
        "value": "Hardin",
        "state": "TX"
    },
    {
        "code": "48201",
        "county": "Harris",
        "value": "Harris",
        "state": "TX"
    },
    {
        "code": "48203",
        "county": "Harrison",
        "value": "Harrison",
        "state": "TX"
    },
    {
        "code": "48205",
        "county": "Hartley",
        "value": "Hartley",
        "state": "TX"
    },
    {
        "code": "48207",
        "county": "Haskell",
        "value": "Haskell",
        "state": "TX"
    },
    {
        "code": "48209",
        "county": "Hays",
        "value": "Hays",
        "state": "TX"
    },
    {
        "code": "48211",
        "county": "Hemphill",
        "value": "Hemphill",
        "state": "TX"
    },
    {
        "code": "48213",
        "county": "Henderson",
        "value": "Henderson",
        "state": "TX"
    },
    {
        "code": "48215",
        "county": "Hidalgo",
        "value": "Hidalgo",
        "state": "TX"
    },
    {
        "code": "48217",
        "county": "Hill",
        "value": "Hill",
        "state": "TX"
    },
    {
        "code": "48219",
        "county": "Hockley",
        "value": "Hockley",
        "state": "TX"
    },
    {
        "code": "48221",
        "county": "Hood",
        "value": "Hood",
        "state": "TX"
    },
    {
        "code": "48223",
        "county": "Hopkins",
        "value": "Hopkins",
        "state": "TX"
    },
    {
        "code": "48225",
        "county": "Houston",
        "value": "Houston",
        "state": "TX"
    },
    {
        "code": "48227",
        "county": "Howard",
        "value": "Howard",
        "state": "TX"
    },
    {
        "code": "48229",
        "county": "Hudspeth",
        "value": "Hudspeth",
        "state": "TX"
    },
    {
        "code": "48231",
        "county": "Hunt",
        "value": "Hunt",
        "state": "TX"
    },
    {
        "code": "48233",
        "county": "Hutchinson",
        "value": "Hutchinson",
        "state": "TX"
    },
    {
        "code": "48235",
        "county": "Irion",
        "value": "Irion",
        "state": "TX"
    },
    {
        "code": "48237",
        "county": "Jack",
        "value": "Jack",
        "state": "TX"
    },
    {
        "code": "48239",
        "county": "Jackson",
        "value": "Jackson",
        "state": "TX"
    },
    {
        "code": "48241",
        "county": "Jasper",
        "value": "Jasper",
        "state": "TX"
    },
    {
        "code": "48243",
        "county": "Jeff Davis",
        "value": "Jeff Davis",
        "state": "TX"
    },
    {
        "code": "48245",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "TX"
    },
    {
        "code": "48247",
        "county": "Jim Hogg",
        "value": "Jim Hogg",
        "state": "TX"
    },
    {
        "code": "48249",
        "county": "Jim Wells",
        "value": "Jim Wells",
        "state": "TX"
    },
    {
        "code": "48251",
        "county": "Johnson",
        "value": "Johnson",
        "state": "TX"
    },
    {
        "code": "48253",
        "county": "Jones",
        "value": "Jones",
        "state": "TX"
    },
    {
        "code": "48255",
        "county": "Karnes",
        "value": "Karnes",
        "state": "TX"
    },
    {
        "code": "48257",
        "county": "Kaufman",
        "value": "Kaufman",
        "state": "TX"
    },
    {
        "code": "48259",
        "county": "Kendall",
        "value": "Kendall",
        "state": "TX"
    },
    {
        "code": "48261",
        "county": "Kenedy",
        "value": "Kenedy",
        "state": "TX"
    },
    {
        "code": "48263",
        "county": "Kent",
        "value": "Kent",
        "state": "TX"
    },
    {
        "code": "48265",
        "county": "Kerr",
        "value": "Kerr",
        "state": "TX"
    },
    {
        "code": "48267",
        "county": "Kimble",
        "value": "Kimble",
        "state": "TX"
    },
    {
        "code": "48269",
        "county": "King",
        "value": "King",
        "state": "TX"
    },
    {
        "code": "48271",
        "county": "Kinney",
        "value": "Kinney",
        "state": "TX"
    },
    {
        "code": "51610",
        "county": "Falls Church city",
        "value": "Falls Church city",
        "state": "VA"
    },
    {
        "code": "51620",
        "county": "Franklin city",
        "value": "Franklin city",
        "state": "VA"
    },
    {
        "code": "51630",
        "county": "Fredericksburg city",
        "value": "Fredericksburg city",
        "state": "VA"
    },
    {
        "code": "51640",
        "county": "Galax city",
        "value": "Galax city",
        "state": "VA"
    },
    {
        "code": "51650",
        "county": "Hampton city",
        "value": "Hampton city",
        "state": "VA"
    },
    {
        "code": "51660",
        "county": "Harrisonburg city",
        "value": "Harrisonburg city",
        "state": "VA"
    },
    {
        "code": "51670",
        "county": "Hopewell city",
        "value": "Hopewell city",
        "state": "VA"
    },
    {
        "code": "51678",
        "county": "Lexington city",
        "value": "Lexington city",
        "state": "VA"
    },
    {
        "code": "51680",
        "county": "Lynchburg city",
        "value": "Lynchburg city",
        "state": "VA"
    },
    {
        "code": "51683",
        "county": "Manassas city",
        "value": "Manassas city",
        "state": "VA"
    },
    {
        "code": "51685",
        "county": "Manassas Park city",
        "value": "Manassas Park city",
        "state": "VA"
    },
    {
        "code": "51690",
        "county": "Martinsville city",
        "value": "Martinsville city",
        "state": "VA"
    },
    {
        "code": "51700",
        "county": "Newport News city",
        "value": "Newport News city",
        "state": "VA"
    },
    {
        "code": "51710",
        "county": "Norfolk city",
        "value": "Norfolk city",
        "state": "VA"
    },
    {
        "code": "51720",
        "county": "Norton city",
        "value": "Norton city",
        "state": "VA"
    },
    {
        "code": "51730",
        "county": "Petersburg city",
        "value": "Petersburg city",
        "state": "VA"
    },
    {
        "code": "51735",
        "county": "Poquoson city",
        "value": "Poquoson city",
        "state": "VA"
    },
    {
        "code": "51740",
        "county": "Portsmouth city",
        "value": "Portsmouth city",
        "state": "VA"
    },
    {
        "code": "51750",
        "county": "Radford city",
        "value": "Radford city",
        "state": "VA"
    },
    {
        "code": "51760",
        "county": "Richmond city",
        "value": "Richmond city",
        "state": "VA"
    },
    {
        "code": "51770",
        "county": "Roanoke city",
        "value": "Roanoke city",
        "state": "VA"
    },
    {
        "code": "51083",
        "county": "Halifax",
        "value": "Halifax",
        "state": "VA"
    },
    {
        "code": "51085",
        "county": "Hanover",
        "value": "Hanover",
        "state": "VA"
    },
    {
        "code": "51087",
        "county": "Henrico",
        "value": "Henrico",
        "state": "VA"
    },
    {
        "code": "51089",
        "county": "Henry",
        "value": "Henry",
        "state": "VA"
    },
    {
        "code": "51091",
        "county": "Highland",
        "value": "Highland",
        "state": "VA"
    },
    {
        "code": "51093",
        "county": "Isle of Wight",
        "value": "Isle of Wight",
        "state": "VA"
    },
    {
        "code": "51095",
        "county": "James City",
        "value": "James City",
        "state": "VA"
    },
    {
        "code": "51097",
        "county": "King and Queen",
        "value": "King and Queen",
        "state": "VA"
    },
    {
        "code": "51099",
        "county": "King George",
        "value": "King George",
        "state": "VA"
    },
    {
        "code": "51101",
        "county": "King William",
        "value": "King William",
        "state": "VA"
    },
    {
        "code": "51103",
        "county": "Lancaster",
        "value": "Lancaster",
        "state": "VA"
    },
    {
        "code": "51105",
        "county": "Lee",
        "value": "Lee",
        "state": "VA"
    },
    {
        "code": "51107",
        "county": "Loudoun",
        "value": "Loudoun",
        "state": "VA"
    },
    {
        "code": "51109",
        "county": "Louisa",
        "value": "Louisa",
        "state": "VA"
    },
    {
        "code": "51111",
        "county": "Lunenburg",
        "value": "Lunenburg",
        "state": "VA"
    },
    {
        "code": "51113",
        "county": "Madison",
        "value": "Madison",
        "state": "VA"
    },
    {
        "code": "51115",
        "county": "Mathews",
        "value": "Mathews",
        "state": "VA"
    },
    {
        "code": "51117",
        "county": "Mecklenburg",
        "value": "Mecklenburg",
        "state": "VA"
    },
    {
        "code": "51119",
        "county": "Middlesex",
        "value": "Middlesex",
        "state": "VA"
    },
    {
        "code": "51121",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "VA"
    },
    {
        "code": "51125",
        "county": "Nelson",
        "value": "Nelson",
        "state": "VA"
    },
    {
        "code": "51127",
        "county": "New Kent",
        "value": "New Kent",
        "state": "VA"
    },
    {
        "code": "51131",
        "county": "Northampton",
        "value": "Northampton",
        "state": "VA"
    },
    {
        "code": "51133",
        "county": "Northumberland",
        "value": "Northumberland",
        "state": "VA"
    },
    {
        "code": "51135",
        "county": "Nottoway",
        "value": "Nottoway",
        "state": "VA"
    },
    {
        "code": "51137",
        "county": "Orange",
        "value": "Orange",
        "state": "VA"
    },
    {
        "code": "51139",
        "county": "Page",
        "value": "Page",
        "state": "VA"
    },
    {
        "code": "51141",
        "county": "Patrick",
        "value": "Patrick",
        "state": "VA"
    },
    {
        "code": "51143",
        "county": "Pittsylvania",
        "value": "Pittsylvania",
        "state": "VA"
    },
    {
        "code": "51145",
        "county": "Powhatan",
        "value": "Powhatan",
        "state": "VA"
    },
    {
        "code": "51147",
        "county": "Prince Edward",
        "value": "Prince Edward",
        "state": "VA"
    },
    {
        "code": "51149",
        "county": "Prince George",
        "value": "Prince George",
        "state": "VA"
    },
    {
        "code": "51153",
        "county": "Prince William",
        "value": "Prince William",
        "state": "VA"
    },
    {
        "code": "51155",
        "county": "Pulaski",
        "value": "Pulaski",
        "state": "VA"
    },
    {
        "code": "51157",
        "county": "Rappahannock",
        "value": "Rappahannock",
        "state": "VA"
    },
    {
        "code": "51159",
        "county": "Richmond",
        "value": "Richmond",
        "state": "VA"
    },
    {
        "code": "51161",
        "county": "Roanoke",
        "value": "Roanoke",
        "state": "VA"
    },
    {
        "code": "51163",
        "county": "Rockbridge",
        "value": "Rockbridge",
        "state": "VA"
    },
    {
        "code": "51165",
        "county": "Rockingham",
        "value": "Rockingham",
        "state": "VA"
    },
    {
        "code": "51167",
        "county": "Russell",
        "value": "Russell",
        "state": "VA"
    },
    {
        "code": "51169",
        "county": "Scott",
        "value": "Scott",
        "state": "VA"
    },
    {
        "code": "51171",
        "county": "Shenandoah",
        "value": "Shenandoah",
        "state": "VA"
    },
    {
        "code": "51173",
        "county": "Smyth",
        "value": "Smyth",
        "state": "VA"
    },
    {
        "code": "51175",
        "county": "Southampton",
        "value": "Southampton",
        "state": "VA"
    },
    {
        "code": "51177",
        "county": "Spotsylvania",
        "value": "Spotsylvania",
        "state": "VA"
    },
    {
        "code": "51179",
        "county": "Stafford",
        "value": "Stafford",
        "state": "VA"
    },
    {
        "code": "51181",
        "county": "Surry",
        "value": "Surry",
        "state": "VA"
    },
    {
        "code": "51183",
        "county": "Sussex",
        "value": "Sussex",
        "state": "VA"
    },
    {
        "code": "51185",
        "county": "Tazewell",
        "value": "Tazewell",
        "state": "VA"
    },
    {
        "code": "51187",
        "county": "Warren",
        "value": "Warren",
        "state": "VA"
    },
    {
        "code": "51191",
        "county": "Washington",
        "value": "Washington",
        "state": "VA"
    },
    {
        "code": "51193",
        "county": "Westmoreland",
        "value": "Westmoreland",
        "state": "VA"
    },
    {
        "code": "51195",
        "county": "Wise",
        "value": "Wise",
        "state": "VA"
    },
    {
        "code": "51197",
        "county": "Wythe",
        "value": "Wythe",
        "state": "VA"
    },
    {
        "code": "51199",
        "county": "York",
        "value": "York",
        "state": "VA"
    },
    {
        "code": "51510",
        "county": "Alexandria city",
        "value": "Alexandria city",
        "state": "VA"
    },
    {
        "code": "51515",
        "county": "Bedford city",
        "value": "Bedford city",
        "state": "VA"
    },
    {
        "code": "51520",
        "county": "Bristol city",
        "value": "Bristol city",
        "state": "VA"
    },
    {
        "code": "51530",
        "county": "Buena Vista city",
        "value": "Buena Vista city",
        "state": "VA"
    },
    {
        "code": "51540",
        "county": "Charlottesville city",
        "value": "Charlottesville city",
        "state": "VA"
    },
    {
        "code": "51550",
        "county": "Chesapeake city",
        "value": "Chesapeake city",
        "state": "VA"
    },
    {
        "code": "51560",
        "county": "Clifton Forge city",
        "value": "Clifton Forge city",
        "state": "VA"
    },
    {
        "code": "51570",
        "county": "Colonial Heights city",
        "value": "Colonial Heights city",
        "state": "VA"
    },
    {
        "code": "51580",
        "county": "Covington city",
        "value": "Covington city",
        "state": "VA"
    },
    {
        "code": "51775",
        "county": "Salem city",
        "value": "Salem city",
        "state": "VA"
    },
    {
        "code": "51780",
        "county": "South Boston city",
        "value": "South Boston city",
        "state": "VA"
    },
    {
        "code": "51790",
        "county": "Staunton city",
        "value": "Staunton city",
        "state": "VA"
    },
    {
        "code": "51800",
        "county": "Suffolk city",
        "value": "Suffolk city",
        "state": "VA"
    },
    {
        "code": "51810",
        "county": "Virginia Beach city",
        "value": "Virginia Beach city",
        "state": "VA"
    },
    {
        "code": "51820",
        "county": "Waynesboro city",
        "value": "Waynesboro city",
        "state": "VA"
    },
    {
        "code": "51830",
        "county": "Williamsburg city",
        "value": "Williamsburg city",
        "state": "VA"
    },
    {
        "code": "51840",
        "county": "Winchester city",
        "value": "Winchester city",
        "state": "VA"
    },
    {
        "code": "51000",
        "county": "Virginia",
        "value": "Virginia",
        "state": "VA"
    },
    {
        "code": "51001",
        "county": "Accomack",
        "value": "Accomack",
        "state": "VA"
    },
    {
        "code": "51003",
        "county": "Albemarle",
        "value": "Albemarle",
        "state": "VA"
    },
    {
        "code": "51005",
        "county": "Alleghany",
        "value": "Alleghany",
        "state": "VA"
    },
    {
        "code": "51007",
        "county": "Amelia",
        "value": "Amelia",
        "state": "VA"
    },
    {
        "code": "51009",
        "county": "Amherst",
        "value": "Amherst",
        "state": "VA"
    },
    {
        "code": "51011",
        "county": "Appomattox",
        "value": "Appomattox",
        "state": "VA"
    },
    {
        "code": "51013",
        "county": "Arlington",
        "value": "Arlington",
        "state": "VA"
    },
    {
        "code": "51015",
        "county": "Augusta",
        "value": "Augusta",
        "state": "VA"
    },
    {
        "code": "51017",
        "county": "Bath",
        "value": "Bath",
        "state": "VA"
    },
    {
        "code": "51019",
        "county": "Bedford",
        "value": "Bedford",
        "state": "VA"
    },
    {
        "code": "51021",
        "county": "Bland",
        "value": "Bland",
        "state": "VA"
    },
    {
        "code": "51023",
        "county": "Botetourt",
        "value": "Botetourt",
        "state": "VA"
    },
    {
        "code": "51025",
        "county": "Brunswick",
        "value": "Brunswick",
        "state": "VA"
    },
    {
        "code": "51027",
        "county": "Buchanan",
        "value": "Buchanan",
        "state": "VA"
    },
    {
        "code": "51029",
        "county": "Buckingham",
        "value": "Buckingham",
        "state": "VA"
    },
    {
        "code": "51031",
        "county": "Campbell",
        "value": "Campbell",
        "state": "VA"
    },
    {
        "code": "51033",
        "county": "Caroline",
        "value": "Caroline",
        "state": "VA"
    },
    {
        "code": "51035",
        "county": "Carroll",
        "value": "Carroll",
        "state": "VA"
    },
    {
        "code": "51036",
        "county": "Charles City",
        "value": "Charles City",
        "state": "VA"
    },
    {
        "code": "51037",
        "county": "Charlotte",
        "value": "Charlotte",
        "state": "VA"
    },
    {
        "code": "51041",
        "county": "Chesterfield",
        "value": "Chesterfield",
        "state": "VA"
    },
    {
        "code": "51043",
        "county": "Clarke",
        "value": "Clarke",
        "state": "VA"
    },
    {
        "code": "51045",
        "county": "Craig",
        "value": "Craig",
        "state": "VA"
    },
    {
        "code": "51047",
        "county": "Culpeper",
        "value": "Culpeper",
        "state": "VA"
    },
    {
        "code": "51049",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "VA"
    },
    {
        "code": "51051",
        "county": "Dickenson",
        "value": "Dickenson",
        "state": "VA"
    },
    {
        "code": "51053",
        "county": "Dinwiddie",
        "value": "Dinwiddie",
        "state": "VA"
    },
    {
        "code": "51057",
        "county": "Essex",
        "value": "Essex",
        "state": "VA"
    },
    {
        "code": "51059",
        "county": "Fairfax",
        "value": "Fairfax",
        "state": "VA"
    },
    {
        "code": "51061",
        "county": "Fauquier",
        "value": "Fauquier",
        "state": "VA"
    },
    {
        "code": "51063",
        "county": "Floyd",
        "value": "Floyd",
        "state": "VA"
    },
    {
        "code": "51065",
        "county": "Fluvanna",
        "value": "Fluvanna",
        "state": "VA"
    },
    {
        "code": "51067",
        "county": "Franklin",
        "value": "Franklin",
        "state": "VA"
    },
    {
        "code": "51069",
        "county": "Frederick",
        "value": "Frederick",
        "state": "VA"
    },
    {
        "code": "51071",
        "county": "Giles",
        "value": "Giles",
        "state": "VA"
    },
    {
        "code": "51073",
        "county": "Gloucester",
        "value": "Gloucester",
        "state": "VA"
    },
    {
        "code": "51075",
        "county": "Goochland",
        "value": "Goochland",
        "state": "VA"
    },
    {
        "code": "51077",
        "county": "Grayson",
        "value": "Grayson",
        "state": "VA"
    },
    {
        "code": "51079",
        "county": "Greene",
        "value": "Greene",
        "state": "VA"
    },
    {
        "code": "51081",
        "county": "Greensville",
        "value": "Greensville",
        "state": "VA"
    },
    {
        "code": "50000",
        "county": "Vermont",
        "value": "Vermont",
        "state": "VT"
    },
    {
        "code": "50027",
        "county": "Windsor",
        "value": "Windsor",
        "state": "VT"
    },
    {
        "code": "50003",
        "county": "Bennington",
        "value": "Bennington",
        "state": "VT"
    },
    {
        "code": "50005",
        "county": "Caledonia",
        "value": "Caledonia",
        "state": "VT"
    },
    {
        "code": "50007",
        "county": "Chittenden",
        "value": "Chittenden",
        "state": "VT"
    },
    {
        "code": "50009",
        "county": "Essex",
        "value": "Essex",
        "state": "VT"
    },
    {
        "code": "50011",
        "county": "Franklin",
        "value": "Franklin",
        "state": "VT"
    },
    {
        "code": "48273",
        "county": "Kleberg",
        "value": "Kleberg",
        "state": "TX"
    },
    {
        "code": "48275",
        "county": "Knox",
        "value": "Knox",
        "state": "TX"
    },
    {
        "code": "48277",
        "county": "Lamar",
        "value": "Lamar",
        "state": "TX"
    },
    {
        "code": "48279",
        "county": "Lamb",
        "value": "Lamb",
        "state": "TX"
    },
    {
        "code": "48281",
        "county": "Lampasas",
        "value": "Lampasas",
        "state": "TX"
    },
    {
        "code": "48283",
        "county": "La Salle",
        "value": "La Salle",
        "state": "TX"
    },
    {
        "code": "48285",
        "county": "Lavaca",
        "value": "Lavaca",
        "state": "TX"
    },
    {
        "code": "48287",
        "county": "Lee",
        "value": "Lee",
        "state": "TX"
    },
    {
        "code": "48289",
        "county": "Leon",
        "value": "Leon",
        "state": "TX"
    },
    {
        "code": "48291",
        "county": "Liberty",
        "value": "Liberty",
        "state": "TX"
    },
    {
        "code": "48293",
        "county": "Limestone",
        "value": "Limestone",
        "state": "TX"
    },
    {
        "code": "48295",
        "county": "Lipscomb",
        "value": "Lipscomb",
        "state": "TX"
    },
    {
        "code": "48297",
        "county": "Live Oak",
        "value": "Live Oak",
        "state": "TX"
    },
    {
        "code": "48299",
        "county": "Llano",
        "value": "Llano",
        "state": "TX"
    },
    {
        "code": "48301",
        "county": "Loving",
        "value": "Loving",
        "state": "TX"
    },
    {
        "code": "48303",
        "county": "Lubbock",
        "value": "Lubbock",
        "state": "TX"
    },
    {
        "code": "48305",
        "county": "Lynn",
        "value": "Lynn",
        "state": "TX"
    },
    {
        "code": "48307",
        "county": "McCulloch",
        "value": "McCulloch",
        "state": "TX"
    },
    {
        "code": "48309",
        "county": "McLennan",
        "value": "McLennan",
        "state": "TX"
    },
    {
        "code": "48311",
        "county": "McMullen",
        "value": "McMullen",
        "state": "TX"
    },
    {
        "code": "48313",
        "county": "Madison",
        "value": "Madison",
        "state": "TX"
    },
    {
        "code": "48315",
        "county": "Marion",
        "value": "Marion",
        "state": "TX"
    },
    {
        "code": "48317",
        "county": "Martin",
        "value": "Martin",
        "state": "TX"
    },
    {
        "code": "48063",
        "county": "Camp",
        "value": "Camp",
        "state": "TX"
    },
    {
        "code": "48065",
        "county": "Carson",
        "value": "Carson",
        "state": "TX"
    },
    {
        "code": "48067",
        "county": "Cass",
        "value": "Cass",
        "state": "TX"
    },
    {
        "code": "48069",
        "county": "Castro",
        "value": "Castro",
        "state": "TX"
    },
    {
        "code": "48071",
        "county": "Chambers",
        "value": "Chambers",
        "state": "TX"
    },
    {
        "code": "48073",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "TX"
    },
    {
        "code": "48075",
        "county": "Childress",
        "value": "Childress",
        "state": "TX"
    },
    {
        "code": "48077",
        "county": "Clay",
        "value": "Clay",
        "state": "TX"
    },
    {
        "code": "48079",
        "county": "Cochran",
        "value": "Cochran",
        "state": "TX"
    },
    {
        "code": "48081",
        "county": "Coke",
        "value": "Coke",
        "state": "TX"
    },
    {
        "code": "48083",
        "county": "Coleman",
        "value": "Coleman",
        "state": "TX"
    },
    {
        "code": "48085",
        "county": "Collin",
        "value": "Collin",
        "state": "TX"
    },
    {
        "code": "48087",
        "county": "Collingsworth",
        "value": "Collingsworth",
        "state": "TX"
    },
    {
        "code": "48089",
        "county": "Colorado",
        "value": "Colorado",
        "state": "TX"
    },
    {
        "code": "48091",
        "county": "Comal",
        "value": "Comal",
        "state": "TX"
    },
    {
        "code": "48093",
        "county": "Comanche",
        "value": "Comanche",
        "state": "TX"
    },
    {
        "code": "48095",
        "county": "Concho",
        "value": "Concho",
        "state": "TX"
    },
    {
        "code": "48097",
        "county": "Cooke",
        "value": "Cooke",
        "state": "TX"
    },
    {
        "code": "48099",
        "county": "Coryell",
        "value": "Coryell",
        "state": "TX"
    },
    {
        "code": "48101",
        "county": "Cottle",
        "value": "Cottle",
        "state": "TX"
    },
    {
        "code": "48103",
        "county": "Crane",
        "value": "Crane",
        "state": "TX"
    },
    {
        "code": "48105",
        "county": "Crockett",
        "value": "Crockett",
        "state": "TX"
    },
    {
        "code": "48107",
        "county": "Crosby",
        "value": "Crosby",
        "state": "TX"
    },
    {
        "code": "48109",
        "county": "Culberson",
        "value": "Culberson",
        "state": "TX"
    },
    {
        "code": "48111",
        "county": "Dallam",
        "value": "Dallam",
        "state": "TX"
    },
    {
        "code": "48113",
        "county": "Dallas",
        "value": "Dallas",
        "state": "TX"
    },
    {
        "code": "48115",
        "county": "Dawson",
        "value": "Dawson",
        "state": "TX"
    },
    {
        "code": "48117",
        "county": "Deaf Smith",
        "value": "Deaf Smith",
        "state": "TX"
    },
    {
        "code": "48119",
        "county": "Delta",
        "value": "Delta",
        "state": "TX"
    },
    {
        "code": "48121",
        "county": "Denton",
        "value": "Denton",
        "state": "TX"
    },
    {
        "code": "48123",
        "county": "DeWitt",
        "value": "DeWitt",
        "state": "TX"
    },
    {
        "code": "48125",
        "county": "Dickens",
        "value": "Dickens",
        "state": "TX"
    },
    {
        "code": "48127",
        "county": "Dimmit",
        "value": "Dimmit",
        "state": "TX"
    },
    {
        "code": "48129",
        "county": "Donley",
        "value": "Donley",
        "state": "TX"
    },
    {
        "code": "48131",
        "county": "Duval",
        "value": "Duval",
        "state": "TX"
    },
    {
        "code": "48133",
        "county": "Eastland",
        "value": "Eastland",
        "state": "TX"
    },
    {
        "code": "48135",
        "county": "Ector",
        "value": "Ector",
        "state": "TX"
    },
    {
        "code": "48137",
        "county": "Edwards",
        "value": "Edwards",
        "state": "TX"
    },
    {
        "code": "48139",
        "county": "Ellis",
        "value": "Ellis",
        "state": "TX"
    },
    {
        "code": "48141",
        "county": "El Paso",
        "value": "El Paso",
        "state": "TX"
    },
    {
        "code": "48143",
        "county": "Erath",
        "value": "Erath",
        "state": "TX"
    },
    {
        "code": "48145",
        "county": "Falls",
        "value": "Falls",
        "state": "TX"
    },
    {
        "code": "48147",
        "county": "Fannin",
        "value": "Fannin",
        "state": "TX"
    },
    {
        "code": "48149",
        "county": "Fayette",
        "value": "Fayette",
        "state": "TX"
    },
    {
        "code": "48151",
        "county": "Fisher",
        "value": "Fisher",
        "state": "TX"
    },
    {
        "code": "48153",
        "county": "Floyd",
        "value": "Floyd",
        "state": "TX"
    },
    {
        "code": "48155",
        "county": "Foard",
        "value": "Foard",
        "state": "TX"
    },
    {
        "code": "48157",
        "county": "Fort Bend",
        "value": "Fort Bend",
        "state": "TX"
    },
    {
        "code": "48159",
        "county": "Franklin",
        "value": "Franklin",
        "state": "TX"
    },
    {
        "code": "48161",
        "county": "Freestone",
        "value": "Freestone",
        "state": "TX"
    },
    {
        "code": "48163",
        "county": "Frio",
        "value": "Frio",
        "state": "TX"
    },
    {
        "code": "48165",
        "county": "Gaines",
        "value": "Gaines",
        "state": "TX"
    },
    {
        "code": "48167",
        "county": "Galveston",
        "value": "Galveston",
        "state": "TX"
    },
    {
        "code": "48169",
        "county": "Garza",
        "value": "Garza",
        "state": "TX"
    },
    {
        "code": "48171",
        "county": "Gillespie",
        "value": "Gillespie",
        "state": "TX"
    },
    {
        "code": "48173",
        "county": "Glasscock",
        "value": "Glasscock",
        "state": "TX"
    },
    {
        "code": "48175",
        "county": "Goliad",
        "value": "Goliad",
        "state": "TX"
    },
    {
        "code": "48177",
        "county": "Gonzales",
        "value": "Gonzales",
        "state": "TX"
    },
    {
        "code": "48179",
        "county": "Gray",
        "value": "Gray",
        "state": "TX"
    },
    {
        "code": "48181",
        "county": "Grayson",
        "value": "Grayson",
        "state": "TX"
    },
    {
        "code": "48183",
        "county": "Gregg",
        "value": "Gregg",
        "state": "TX"
    },
    {
        "code": "48185",
        "county": "Grimes",
        "value": "Grimes",
        "state": "TX"
    },
    {
        "code": "48187",
        "county": "Guadalupe",
        "value": "Guadalupe",
        "state": "TX"
    },
    {
        "code": "48189",
        "county": "Hale",
        "value": "Hale",
        "state": "TX"
    },
    {
        "code": "48000",
        "county": "Texas",
        "value": "Texas",
        "state": "TX"
    },
    {
        "code": "48001",
        "county": "Anderson",
        "value": "Anderson",
        "state": "TX"
    },
    {
        "code": "48003",
        "county": "Andrews",
        "value": "Andrews",
        "state": "TX"
    },
    {
        "code": "48005",
        "county": "Angelina",
        "value": "Angelina",
        "state": "TX"
    },
    {
        "code": "48007",
        "county": "Aransas",
        "value": "Aransas",
        "state": "TX"
    },
    {
        "code": "48009",
        "county": "Archer",
        "value": "Archer",
        "state": "TX"
    },
    {
        "code": "48011",
        "county": "Armstrong",
        "value": "Armstrong",
        "state": "TX"
    },
    {
        "code": "48013",
        "county": "Atascosa",
        "value": "Atascosa",
        "state": "TX"
    },
    {
        "code": "48015",
        "county": "Austin",
        "value": "Austin",
        "state": "TX"
    },
    {
        "code": "48017",
        "county": "Bailey",
        "value": "Bailey",
        "state": "TX"
    },
    {
        "code": "48019",
        "county": "Bandera",
        "value": "Bandera",
        "state": "TX"
    },
    {
        "code": "48021",
        "county": "Bastrop",
        "value": "Bastrop",
        "state": "TX"
    },
    {
        "code": "48023",
        "county": "Baylor",
        "value": "Baylor",
        "state": "TX"
    },
    {
        "code": "48025",
        "county": "Bee",
        "value": "Bee",
        "state": "TX"
    },
    {
        "code": "48027",
        "county": "Bell",
        "value": "Bell",
        "state": "TX"
    },
    {
        "code": "48029",
        "county": "Bexar",
        "value": "Bexar",
        "state": "TX"
    },
    {
        "code": "48031",
        "county": "Blanco",
        "value": "Blanco",
        "state": "TX"
    },
    {
        "code": "48033",
        "county": "Borden",
        "value": "Borden",
        "state": "TX"
    },
    {
        "code": "48035",
        "county": "Bosque",
        "value": "Bosque",
        "state": "TX"
    },
    {
        "code": "48037",
        "county": "Bowie",
        "value": "Bowie",
        "state": "TX"
    },
    {
        "code": "48039",
        "county": "Brazoria",
        "value": "Brazoria",
        "state": "TX"
    },
    {
        "code": "48041",
        "county": "Brazos",
        "value": "Brazos",
        "state": "TX"
    },
    {
        "code": "48043",
        "county": "Brewster",
        "value": "Brewster",
        "state": "TX"
    },
    {
        "code": "48045",
        "county": "Briscoe",
        "value": "Briscoe",
        "state": "TX"
    },
    {
        "code": "48047",
        "county": "Brooks",
        "value": "Brooks",
        "state": "TX"
    },
    {
        "code": "48049",
        "county": "Brown",
        "value": "Brown",
        "state": "TX"
    },
    {
        "code": "48051",
        "county": "Burleson",
        "value": "Burleson",
        "state": "TX"
    },
    {
        "code": "48053",
        "county": "Burnet",
        "value": "Burnet",
        "state": "TX"
    },
    {
        "code": "48055",
        "county": "Caldwell",
        "value": "Caldwell",
        "state": "TX"
    },
    {
        "code": "48057",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "TX"
    },
    {
        "code": "48059",
        "county": "Callahan",
        "value": "Callahan",
        "state": "TX"
    },
    {
        "code": "48061",
        "county": "Cameron",
        "value": "Cameron",
        "state": "TX"
    },
    {
        "code": "49000",
        "county": "Utah",
        "value": "Utah",
        "state": "UT"
    },
    {
        "code": "49057",
        "county": "Weber",
        "value": "Weber",
        "state": "UT"
    },
    {
        "code": "49003",
        "county": "Box Elder",
        "value": "Box Elder",
        "state": "UT"
    },
    {
        "code": "49005",
        "county": "Cache",
        "value": "Cache",
        "state": "UT"
    },
    {
        "code": "49007",
        "county": "Carbon",
        "value": "Carbon",
        "state": "UT"
    },
    {
        "code": "49009",
        "county": "Daggett",
        "value": "Daggett",
        "state": "UT"
    },
    {
        "code": "49011",
        "county": "Davis",
        "value": "Davis",
        "state": "UT"
    },
    {
        "code": "49013",
        "county": "Duchesne",
        "value": "Duchesne",
        "state": "UT"
    },
    {
        "code": "49015",
        "county": "Emery",
        "value": "Emery",
        "state": "UT"
    },
    {
        "code": "49017",
        "county": "Garfield",
        "value": "Garfield",
        "state": "UT"
    },
    {
        "code": "49019",
        "county": "Grand",
        "value": "Grand",
        "state": "UT"
    },
    {
        "code": "49021",
        "county": "Iron",
        "value": "Iron",
        "state": "UT"
    },
    {
        "code": "49023",
        "county": "Juab",
        "value": "Juab",
        "state": "UT"
    },
    {
        "code": "49025",
        "county": "Kane",
        "value": "Kane",
        "state": "UT"
    },
    {
        "code": "49027",
        "county": "Millard",
        "value": "Millard",
        "state": "UT"
    },
    {
        "code": "49029",
        "county": "Morgan",
        "value": "Morgan",
        "state": "UT"
    },
    {
        "code": "49031",
        "county": "Piute",
        "value": "Piute",
        "state": "UT"
    },
    {
        "code": "49033",
        "county": "Rich",
        "value": "Rich",
        "state": "UT"
    },
    {
        "code": "49035",
        "county": "Salt Lake",
        "value": "Salt Lake",
        "state": "UT"
    },
    {
        "code": "49037",
        "county": "San Juan",
        "value": "San Juan",
        "state": "UT"
    },
    {
        "code": "49039",
        "county": "Sanpete",
        "value": "Sanpete",
        "state": "UT"
    },
    {
        "code": "49041",
        "county": "Sevier",
        "value": "Sevier",
        "state": "UT"
    },
    {
        "code": "49043",
        "county": "Summit",
        "value": "Summit",
        "state": "UT"
    },
    {
        "code": "49045",
        "county": "Tooele",
        "value": "Tooele",
        "state": "UT"
    },
    {
        "code": "49047",
        "county": "Uintah",
        "value": "Uintah",
        "state": "UT"
    },
    {
        "code": "49049",
        "county": "Utah",
        "value": "Utah",
        "state": "UT"
    },
    {
        "code": "49051",
        "county": "Wasatch",
        "value": "Wasatch",
        "state": "UT"
    },
    {
        "code": "49053",
        "county": "Washington",
        "value": "Washington",
        "state": "UT"
    },
    {
        "code": "49055",
        "county": "Wayne",
        "value": "Wayne",
        "state": "UT"
    },
    {
        "code": "49001",
        "county": "Beaver",
        "value": "Beaver",
        "state": "UT"
    },
    {
        "code": "51590",
        "county": "Danville city",
        "value": "Danville city",
        "state": "VA"
    },
    {
        "code": "51595",
        "county": "Emporia city",
        "value": "Emporia city",
        "state": "VA"
    },
    {
        "code": "51600",
        "county": "Fairfax city",
        "value": "Fairfax city",
        "state": "VA"
    },
    {
        "code": "34019",
        "county": "Hunterdon",
        "value": "Hunterdon",
        "state": "NJ"
    },
    {
        "code": "34021",
        "county": "Mercer",
        "value": "Mercer",
        "state": "NJ"
    },
    {
        "code": "34023",
        "county": "Middlesex",
        "value": "Middlesex",
        "state": "NJ"
    },
    {
        "code": "34025",
        "county": "Monmouth",
        "value": "Monmouth",
        "state": "NJ"
    },
    {
        "code": "34027",
        "county": "Morris",
        "value": "Morris",
        "state": "NJ"
    },
    {
        "code": "34029",
        "county": "Ocean",
        "value": "Ocean",
        "state": "NJ"
    },
    {
        "code": "34031",
        "county": "Passaic",
        "value": "Passaic",
        "state": "NJ"
    },
    {
        "code": "34033",
        "county": "Salem",
        "value": "Salem",
        "state": "NJ"
    },
    {
        "code": "34035",
        "county": "Somerset",
        "value": "Somerset",
        "state": "NJ"
    },
    {
        "code": "34037",
        "county": "Sussex",
        "value": "Sussex",
        "state": "NJ"
    },
    {
        "code": "34039",
        "county": "Union",
        "value": "Union",
        "state": "NJ"
    },
    {
        "code": "34041",
        "county": "Warren",
        "value": "Warren",
        "state": "NJ"
    },
    {
        "code": "34000",
        "county": "New Jersey",
        "value": "New Jersey",
        "state": "NJ"
    },
    {
        "code": "34001",
        "county": "Atlantic",
        "value": "Atlantic",
        "state": "NJ"
    },
    {
        "code": "34003",
        "county": "Bergen",
        "value": "Bergen",
        "state": "NJ"
    },
    {
        "code": "34005",
        "county": "Burlington",
        "value": "Burlington",
        "state": "NJ"
    },
    {
        "code": "34011",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "NJ"
    },
    {
        "code": "35000",
        "county": "New Mexico",
        "value": "New Mexico",
        "state": "NM"
    },
    {
        "code": "35061",
        "county": "Valencia",
        "value": "Valencia",
        "state": "NM"
    },
    {
        "code": "35003",
        "county": "Catron",
        "value": "Catron",
        "state": "NM"
    },
    {
        "code": "35005",
        "county": "Chaves",
        "value": "Chaves",
        "state": "NM"
    },
    {
        "code": "35006",
        "county": "Cibola",
        "value": "Cibola",
        "state": "NM"
    },
    {
        "code": "35007",
        "county": "Colfax",
        "value": "Colfax",
        "state": "NM"
    },
    {
        "code": "35009",
        "county": "Curry",
        "value": "Curry",
        "state": "NM"
    },
    {
        "code": "35011",
        "county": "DeBaca",
        "value": "DeBaca",
        "state": "NM"
    },
    {
        "code": "35013",
        "county": "Dona Ana",
        "value": "Dona Ana",
        "state": "NM"
    },
    {
        "code": "35015",
        "county": "Eddy",
        "value": "Eddy",
        "state": "NM"
    },
    {
        "code": "35017",
        "county": "Grant",
        "value": "Grant",
        "state": "NM"
    },
    {
        "code": "35019",
        "county": "Guadalupe",
        "value": "Guadalupe",
        "state": "NM"
    },
    {
        "code": "35021",
        "county": "Harding",
        "value": "Harding",
        "state": "NM"
    },
    {
        "code": "35023",
        "county": "Hidalgo",
        "value": "Hidalgo",
        "state": "NM"
    },
    {
        "code": "35025",
        "county": "Lea",
        "value": "Lea",
        "state": "NM"
    },
    {
        "code": "35027",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "NM"
    },
    {
        "code": "35028",
        "county": "Los Alamos",
        "value": "Los Alamos",
        "state": "NM"
    },
    {
        "code": "35029",
        "county": "Luna",
        "value": "Luna",
        "state": "NM"
    },
    {
        "code": "35031",
        "county": "McKinley",
        "value": "McKinley",
        "state": "NM"
    },
    {
        "code": "35033",
        "county": "Mora",
        "value": "Mora",
        "state": "NM"
    },
    {
        "code": "35035",
        "county": "Otero",
        "value": "Otero",
        "state": "NM"
    },
    {
        "code": "35037",
        "county": "Quay",
        "value": "Quay",
        "state": "NM"
    },
    {
        "code": "35039",
        "county": "Rio Arriba",
        "value": "Rio Arriba",
        "state": "NM"
    },
    {
        "code": "35041",
        "county": "Roosevelt",
        "value": "Roosevelt",
        "state": "NM"
    },
    {
        "code": "35043",
        "county": "Sandoval",
        "value": "Sandoval",
        "state": "NM"
    },
    {
        "code": "35045",
        "county": "San Juan",
        "value": "San Juan",
        "state": "NM"
    },
    {
        "code": "35047",
        "county": "San Miguel",
        "value": "San Miguel",
        "state": "NM"
    },
    {
        "code": "35049",
        "county": "Santa Fe",
        "value": "Santa Fe",
        "state": "NM"
    },
    {
        "code": "35051",
        "county": "Sierra",
        "value": "Sierra",
        "state": "NM"
    },
    {
        "code": "35053",
        "county": "Socorro",
        "value": "Socorro",
        "state": "NM"
    },
    {
        "code": "35055",
        "county": "Taos",
        "value": "Taos",
        "state": "NM"
    },
    {
        "code": "35057",
        "county": "Torrance",
        "value": "Torrance",
        "state": "NM"
    },
    {
        "code": "35059",
        "county": "Union",
        "value": "Union",
        "state": "NM"
    },
    {
        "code": "35001",
        "county": "Bernalillo",
        "value": "Bernalillo",
        "state": "NM"
    },
    {
        "code": "32000",
        "county": "Nevada",
        "value": "Nevada",
        "state": "NV"
    },
    {
        "code": "32510",
        "county": "Carson City",
        "value": "Carson City",
        "state": "NV"
    },
    {
        "code": "32003",
        "county": "Clark",
        "value": "Clark",
        "state": "NV"
    },
    {
        "code": "32005",
        "county": "Douglas",
        "value": "Douglas",
        "state": "NV"
    },
    {
        "code": "32007",
        "county": "Elko",
        "value": "Elko",
        "state": "NV"
    },
    {
        "code": "32009",
        "county": "Esmeralda",
        "value": "Esmeralda",
        "state": "NV"
    },
    {
        "code": "32011",
        "county": "Eureka",
        "value": "Eureka",
        "state": "NV"
    },
    {
        "code": "32013",
        "county": "Humboldt",
        "value": "Humboldt",
        "state": "NV"
    },
    {
        "code": "32015",
        "county": "Lander",
        "value": "Lander",
        "state": "NV"
    },
    {
        "code": "32017",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "NV"
    },
    {
        "code": "32019",
        "county": "Lyon",
        "value": "Lyon",
        "state": "NV"
    },
    {
        "code": "32021",
        "county": "Mineral",
        "value": "Mineral",
        "state": "NV"
    },
    {
        "code": "32023",
        "county": "Nye",
        "value": "Nye",
        "state": "NV"
    },
    {
        "code": "32027",
        "county": "Pershing",
        "value": "Pershing",
        "state": "NV"
    },
    {
        "code": "32029",
        "county": "Storey",
        "value": "Storey",
        "state": "NV"
    },
    {
        "code": "32031",
        "county": "Washoe",
        "value": "Washoe",
        "state": "NV"
    },
    {
        "code": "32033",
        "county": "White Pine",
        "value": "White Pine",
        "state": "NV"
    },
    {
        "code": "32001",
        "county": "Churchill",
        "value": "Churchill",
        "state": "NV"
    },
    {
        "code": "36025",
        "county": "Delaware",
        "value": "Delaware",
        "state": "NY"
    },
    {
        "code": "36023",
        "county": "Cortland",
        "value": "Cortland",
        "state": "NY"
    },
    {
        "code": "36029",
        "county": "Erie",
        "value": "Erie",
        "state": "NY"
    },
    {
        "code": "36031",
        "county": "Essex",
        "value": "Essex",
        "state": "NY"
    },
    {
        "code": "36033",
        "county": "Franklin",
        "value": "Franklin",
        "state": "NY"
    },
    {
        "code": "36035",
        "county": "Fulton",
        "value": "Fulton",
        "state": "NY"
    },
    {
        "code": "36037",
        "county": "Genesee",
        "value": "Genesee",
        "state": "NY"
    },
    {
        "code": "36039",
        "county": "Greene",
        "value": "Greene",
        "state": "NY"
    },
    {
        "code": "36041",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "NY"
    },
    {
        "code": "36043",
        "county": "Herkimer",
        "value": "Herkimer",
        "state": "NY"
    },
    {
        "code": "36045",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "NY"
    },
    {
        "code": "36047",
        "county": "Kings",
        "value": "Kings",
        "state": "NY"
    },
    {
        "code": "36049",
        "county": "Lewis",
        "value": "Lewis",
        "state": "NY"
    },
    {
        "code": "36051",
        "county": "Livingston",
        "value": "Livingston",
        "state": "NY"
    },
    {
        "code": "36053",
        "county": "Madison",
        "value": "Madison",
        "state": "NY"
    },
    {
        "code": "36055",
        "county": "Monroe",
        "value": "Monroe",
        "state": "NY"
    },
    {
        "code": "36057",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "NY"
    },
    {
        "code": "36059",
        "county": "Nassau",
        "value": "Nassau",
        "state": "NY"
    },
    {
        "code": "36061",
        "county": "New York",
        "value": "New York",
        "state": "NY"
    },
    {
        "code": "36063",
        "county": "Niagara",
        "value": "Niagara",
        "state": "NY"
    },
    {
        "code": "36065",
        "county": "Oneida",
        "value": "Oneida",
        "state": "NY"
    },
    {
        "code": "36067",
        "county": "Onondaga",
        "value": "Onondaga",
        "state": "NY"
    },
    {
        "code": "36069",
        "county": "Ontario",
        "value": "Ontario",
        "state": "NY"
    },
    {
        "code": "36071",
        "county": "Orange",
        "value": "Orange",
        "state": "NY"
    },
    {
        "code": "36073",
        "county": "Orleans",
        "value": "Orleans",
        "state": "NY"
    },
    {
        "code": "36075",
        "county": "Oswego",
        "value": "Oswego",
        "state": "NY"
    },
    {
        "code": "36077",
        "county": "Otsego",
        "value": "Otsego",
        "state": "NY"
    },
    {
        "code": "36079",
        "county": "Putnam",
        "value": "Putnam",
        "state": "NY"
    },
    {
        "code": "36081",
        "county": "Queens",
        "value": "Queens",
        "state": "NY"
    },
    {
        "code": "36083",
        "county": "Rensselaer",
        "value": "Rensselaer",
        "state": "NY"
    },
    {
        "code": "36085",
        "county": "Richmond",
        "value": "Richmond",
        "state": "NY"
    },
    {
        "code": "36087",
        "county": "Rockland",
        "value": "Rockland",
        "state": "NY"
    },
    {
        "code": "36089",
        "county": "St. Lawrence",
        "value": "St. Lawrence",
        "state": "NY"
    },
    {
        "code": "36091",
        "county": "Saratoga",
        "value": "Saratoga",
        "state": "NY"
    },
    {
        "code": "36093",
        "county": "Schenectady",
        "value": "Schenectady",
        "state": "NY"
    },
    {
        "code": "36095",
        "county": "Schoharie",
        "value": "Schoharie",
        "state": "NY"
    },
    {
        "code": "36097",
        "county": "Schuyler",
        "value": "Schuyler",
        "state": "NY"
    },
    {
        "code": "36099",
        "county": "Seneca",
        "value": "Seneca",
        "state": "NY"
    },
    {
        "code": "36101",
        "county": "Steuben",
        "value": "Steuben",
        "state": "NY"
    },
    {
        "code": "36103",
        "county": "Suffolk",
        "value": "Suffolk",
        "state": "NY"
    },
    {
        "code": "36105",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "NY"
    },
    {
        "code": "36107",
        "county": "Tioga",
        "value": "Tioga",
        "state": "NY"
    },
    {
        "code": "36109",
        "county": "Tompkins",
        "value": "Tompkins",
        "state": "NY"
    },
    {
        "code": "36111",
        "county": "Ulster",
        "value": "Ulster",
        "state": "NY"
    },
    {
        "code": "36113",
        "county": "Warren",
        "value": "Warren",
        "state": "NY"
    },
    {
        "code": "36115",
        "county": "Washington",
        "value": "Washington",
        "state": "NY"
    },
    {
        "code": "36117",
        "county": "Wayne",
        "value": "Wayne",
        "state": "NY"
    },
    {
        "code": "36119",
        "county": "Westchester",
        "value": "Westchester",
        "state": "NY"
    },
    {
        "code": "36121",
        "county": "Wyoming",
        "value": "Wyoming",
        "state": "NY"
    },
    {
        "code": "36123",
        "county": "Yates",
        "value": "Yates",
        "state": "NY"
    },
    {
        "code": "36000",
        "county": "New York",
        "value": "New York",
        "state": "NY"
    },
    {
        "code": "36001",
        "county": "Albany",
        "value": "Albany",
        "state": "NY"
    },
    {
        "code": "36003",
        "county": "Allegany",
        "value": "Allegany",
        "state": "NY"
    },
    {
        "code": "36005",
        "county": "Bronx",
        "value": "Bronx",
        "state": "NY"
    },
    {
        "code": "36007",
        "county": "Broome",
        "value": "Broome",
        "state": "NY"
    },
    {
        "code": "36009",
        "county": "Cattaraugus",
        "value": "Cattaraugus",
        "state": "NY"
    },
    {
        "code": "36011",
        "county": "Cayuga",
        "value": "Cayuga",
        "state": "NY"
    },
    {
        "code": "36013",
        "county": "Chautauqua",
        "value": "Chautauqua",
        "state": "NY"
    },
    {
        "code": "36015",
        "county": "Chemung",
        "value": "Chemung",
        "state": "NY"
    },
    {
        "code": "36017",
        "county": "Chenango",
        "value": "Chenango",
        "state": "NY"
    },
    {
        "code": "36019",
        "county": "Clinton",
        "value": "Clinton",
        "state": "NY"
    },
    {
        "code": "36021",
        "county": "Columbia",
        "value": "Columbia",
        "state": "NY"
    },
    {
        "code": "36027",
        "county": "Dutchess",
        "value": "Dutchess",
        "state": "NY"
    },
    {
        "code": "39000",
        "county": "Ohio",
        "value": "Ohio",
        "state": "OH"
    },
    {
        "code": "39001",
        "county": "Adams",
        "value": "Adams",
        "state": "OH"
    },
    {
        "code": "39003",
        "county": "Allen",
        "value": "Allen",
        "state": "OH"
    },
    {
        "code": "39005",
        "county": "Ashland",
        "value": "Ashland",
        "state": "OH"
    },
    {
        "code": "39007",
        "county": "Ashtabula",
        "value": "Ashtabula",
        "state": "OH"
    },
    {
        "code": "39009",
        "county": "Athens",
        "value": "Athens",
        "state": "OH"
    },
    {
        "code": "39011",
        "county": "Auglaize",
        "value": "Auglaize",
        "state": "OH"
    },
    {
        "code": "39013",
        "county": "Belmont",
        "value": "Belmont",
        "state": "OH"
    },
    {
        "code": "39015",
        "county": "Brown",
        "value": "Brown",
        "state": "OH"
    },
    {
        "code": "39017",
        "county": "Butler",
        "value": "Butler",
        "state": "OH"
    },
    {
        "code": "39019",
        "county": "Carroll",
        "value": "Carroll",
        "state": "OH"
    },
    {
        "code": "39021",
        "county": "Champaign",
        "value": "Champaign",
        "state": "OH"
    },
    {
        "code": "39023",
        "county": "Clark",
        "value": "Clark",
        "state": "OH"
    },
    {
        "code": "39025",
        "county": "Clermont",
        "value": "Clermont",
        "state": "OH"
    },
    {
        "code": "39027",
        "county": "Clinton",
        "value": "Clinton",
        "state": "OH"
    },
    {
        "code": "39029",
        "county": "Columbiana",
        "value": "Columbiana",
        "state": "OH"
    },
    {
        "code": "39031",
        "county": "Coshocton",
        "value": "Coshocton",
        "state": "OH"
    },
    {
        "code": "39033",
        "county": "Crawford",
        "value": "Crawford",
        "state": "OH"
    },
    {
        "code": "39163",
        "county": "Vinton",
        "value": "Vinton",
        "state": "OH"
    },
    {
        "code": "39165",
        "county": "Warren",
        "value": "Warren",
        "state": "OH"
    },
    {
        "code": "39167",
        "county": "Washington",
        "value": "Washington",
        "state": "OH"
    },
    {
        "code": "39169",
        "county": "Wayne",
        "value": "Wayne",
        "state": "OH"
    },
    {
        "code": "39171",
        "county": "Williams",
        "value": "Williams",
        "state": "OH"
    },
    {
        "code": "39173",
        "county": "Wood",
        "value": "Wood",
        "state": "OH"
    },
    {
        "code": "39175",
        "county": "Wyandot",
        "value": "Wyandot",
        "state": "OH"
    },
    {
        "code": "39035",
        "county": "Cuyahoga",
        "value": "Cuyahoga",
        "state": "OH"
    },
    {
        "code": "39037",
        "county": "Darke",
        "value": "Darke",
        "state": "OH"
    },
    {
        "code": "39039",
        "county": "Defiance",
        "value": "Defiance",
        "state": "OH"
    },
    {
        "code": "39041",
        "county": "Delaware",
        "value": "Delaware",
        "state": "OH"
    },
    {
        "code": "39043",
        "county": "Erie",
        "value": "Erie",
        "state": "OH"
    },
    {
        "code": "39045",
        "county": "Fairfield",
        "value": "Fairfield",
        "state": "OH"
    },
    {
        "code": "39047",
        "county": "Fayette",
        "value": "Fayette",
        "state": "OH"
    },
    {
        "code": "39049",
        "county": "Franklin",
        "value": "Franklin",
        "state": "OH"
    },
    {
        "code": "39051",
        "county": "Fulton",
        "value": "Fulton",
        "state": "OH"
    },
    {
        "code": "39053",
        "county": "Gallia",
        "value": "Gallia",
        "state": "OH"
    },
    {
        "code": "39055",
        "county": "Geauga",
        "value": "Geauga",
        "state": "OH"
    },
    {
        "code": "39057",
        "county": "Greene",
        "value": "Greene",
        "state": "OH"
    },
    {
        "code": "39059",
        "county": "Guernsey",
        "value": "Guernsey",
        "state": "OH"
    },
    {
        "code": "39061",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "OH"
    },
    {
        "code": "39063",
        "county": "Hancock",
        "value": "Hancock",
        "state": "OH"
    },
    {
        "code": "39065",
        "county": "Hardin",
        "value": "Hardin",
        "state": "OH"
    },
    {
        "code": "39067",
        "county": "Harrison",
        "value": "Harrison",
        "state": "OH"
    },
    {
        "code": "39069",
        "county": "Henry",
        "value": "Henry",
        "state": "OH"
    },
    {
        "code": "39071",
        "county": "Highland",
        "value": "Highland",
        "state": "OH"
    },
    {
        "code": "39073",
        "county": "Hocking",
        "value": "Hocking",
        "state": "OH"
    },
    {
        "code": "39075",
        "county": "Holmes",
        "value": "Holmes",
        "state": "OH"
    },
    {
        "code": "39077",
        "county": "Huron",
        "value": "Huron",
        "state": "OH"
    },
    {
        "code": "39079",
        "county": "Jackson",
        "value": "Jackson",
        "state": "OH"
    },
    {
        "code": "39081",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "OH"
    },
    {
        "code": "39083",
        "county": "Knox",
        "value": "Knox",
        "state": "OH"
    },
    {
        "code": "39085",
        "county": "Lake",
        "value": "Lake",
        "state": "OH"
    },
    {
        "code": "39087",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "OH"
    },
    {
        "code": "39089",
        "county": "Licking",
        "value": "Licking",
        "state": "OH"
    },
    {
        "code": "39091",
        "county": "Logan",
        "value": "Logan",
        "state": "OH"
    },
    {
        "code": "39093",
        "county": "Lorain",
        "value": "Lorain",
        "state": "OH"
    },
    {
        "code": "39095",
        "county": "Lucas",
        "value": "Lucas",
        "state": "OH"
    },
    {
        "code": "39097",
        "county": "Madison",
        "value": "Madison",
        "state": "OH"
    },
    {
        "code": "39099",
        "county": "Mahoning",
        "value": "Mahoning",
        "state": "OH"
    },
    {
        "code": "39101",
        "county": "Marion",
        "value": "Marion",
        "state": "OH"
    },
    {
        "code": "39103",
        "county": "Medina",
        "value": "Medina",
        "state": "OH"
    },
    {
        "code": "39105",
        "county": "Meigs",
        "value": "Meigs",
        "state": "OH"
    },
    {
        "code": "39107",
        "county": "Mercer",
        "value": "Mercer",
        "state": "OH"
    },
    {
        "code": "39109",
        "county": "Miami",
        "value": "Miami",
        "state": "OH"
    },
    {
        "code": "39111",
        "county": "Monroe",
        "value": "Monroe",
        "state": "OH"
    },
    {
        "code": "39113",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "OH"
    },
    {
        "code": "39115",
        "county": "Morgan",
        "value": "Morgan",
        "state": "OH"
    },
    {
        "code": "39117",
        "county": "Morrow",
        "value": "Morrow",
        "state": "OH"
    },
    {
        "code": "39119",
        "county": "Muskingum",
        "value": "Muskingum",
        "state": "OH"
    },
    {
        "code": "39121",
        "county": "Noble",
        "value": "Noble",
        "state": "OH"
    },
    {
        "code": "39123",
        "county": "Ottawa",
        "value": "Ottawa",
        "state": "OH"
    },
    {
        "code": "39125",
        "county": "Paulding",
        "value": "Paulding",
        "state": "OH"
    },
    {
        "code": "39127",
        "county": "Perry",
        "value": "Perry",
        "state": "OH"
    },
    {
        "code": "39129",
        "county": "Pickaway",
        "value": "Pickaway",
        "state": "OH"
    },
    {
        "code": "39131",
        "county": "Pike",
        "value": "Pike",
        "state": "OH"
    },
    {
        "code": "39133",
        "county": "Portage",
        "value": "Portage",
        "state": "OH"
    },
    {
        "code": "39135",
        "county": "Preble",
        "value": "Preble",
        "state": "OH"
    },
    {
        "code": "39137",
        "county": "Putnam",
        "value": "Putnam",
        "state": "OH"
    },
    {
        "code": "39139",
        "county": "Richland",
        "value": "Richland",
        "state": "OH"
    },
    {
        "code": "39141",
        "county": "Ross",
        "value": "Ross",
        "state": "OH"
    },
    {
        "code": "39143",
        "county": "Sandusky",
        "value": "Sandusky",
        "state": "OH"
    },
    {
        "code": "39145",
        "county": "Scioto",
        "value": "Scioto",
        "state": "OH"
    },
    {
        "code": "39147",
        "county": "Seneca",
        "value": "Seneca",
        "state": "OH"
    },
    {
        "code": "39149",
        "county": "Shelby",
        "value": "Shelby",
        "state": "OH"
    },
    {
        "code": "39151",
        "county": "Stark",
        "value": "Stark",
        "state": "OH"
    },
    {
        "code": "39153",
        "county": "Summit",
        "value": "Summit",
        "state": "OH"
    },
    {
        "code": "39155",
        "county": "Trumbull",
        "value": "Trumbull",
        "state": "OH"
    },
    {
        "code": "39157",
        "county": "Tuscarawas",
        "value": "Tuscarawas",
        "state": "OH"
    },
    {
        "code": "39159",
        "county": "Union",
        "value": "Union",
        "state": "OH"
    },
    {
        "code": "39161",
        "county": "Van Wert",
        "value": "Van Wert",
        "state": "OH"
    },
    {
        "code": "40085",
        "county": "Love",
        "value": "Love",
        "state": "OK"
    },
    {
        "code": "40087",
        "county": "McClain",
        "value": "McClain",
        "state": "OK"
    },
    {
        "code": "40089",
        "county": "McCurtain",
        "value": "McCurtain",
        "state": "OK"
    },
    {
        "code": "40091",
        "county": "McIntosh",
        "value": "McIntosh",
        "state": "OK"
    },
    {
        "code": "40093",
        "county": "Major",
        "value": "Major",
        "state": "OK"
    },
    {
        "code": "40095",
        "county": "Marshall",
        "value": "Marshall",
        "state": "OK"
    },
    {
        "code": "40097",
        "county": "Mayes",
        "value": "Mayes",
        "state": "OK"
    },
    {
        "code": "40099",
        "county": "Murray",
        "value": "Murray",
        "state": "OK"
    },
    {
        "code": "40101",
        "county": "Muskogee",
        "value": "Muskogee",
        "state": "OK"
    },
    {
        "code": "40103",
        "county": "Noble",
        "value": "Noble",
        "state": "OK"
    },
    {
        "code": "40105",
        "county": "Nowata",
        "value": "Nowata",
        "state": "OK"
    },
    {
        "code": "40107",
        "county": "Okfuskee",
        "value": "Okfuskee",
        "state": "OK"
    },
    {
        "code": "40109",
        "county": "Oklahoma",
        "value": "Oklahoma",
        "state": "OK"
    },
    {
        "code": "40111",
        "county": "Okmulgee",
        "value": "Okmulgee",
        "state": "OK"
    },
    {
        "code": "40113",
        "county": "Osage",
        "value": "Osage",
        "state": "OK"
    },
    {
        "code": "40115",
        "county": "Ottawa",
        "value": "Ottawa",
        "state": "OK"
    },
    {
        "code": "40117",
        "county": "Pawnee",
        "value": "Pawnee",
        "state": "OK"
    },
    {
        "code": "40119",
        "county": "Payne",
        "value": "Payne",
        "state": "OK"
    },
    {
        "code": "40121",
        "county": "Pittsburg",
        "value": "Pittsburg",
        "state": "OK"
    },
    {
        "code": "40123",
        "county": "Pontotoc",
        "value": "Pontotoc",
        "state": "OK"
    },
    {
        "code": "40125",
        "county": "Pottawatomie",
        "value": "Pottawatomie",
        "state": "OK"
    },
    {
        "code": "40127",
        "county": "Pushmataha",
        "value": "Pushmataha",
        "state": "OK"
    },
    {
        "code": "40129",
        "county": "Roger Mills",
        "value": "Roger Mills",
        "state": "OK"
    },
    {
        "code": "40131",
        "county": "Rogers",
        "value": "Rogers",
        "state": "OK"
    },
    {
        "code": "40133",
        "county": "Seminole",
        "value": "Seminole",
        "state": "OK"
    },
    {
        "code": "40135",
        "county": "Sequoyah",
        "value": "Sequoyah",
        "state": "OK"
    },
    {
        "code": "40137",
        "county": "Stephens",
        "value": "Stephens",
        "state": "OK"
    },
    {
        "code": "40139",
        "county": "Texas",
        "value": "Texas",
        "state": "OK"
    },
    {
        "code": "40141",
        "county": "Tillman",
        "value": "Tillman",
        "state": "OK"
    },
    {
        "code": "40143",
        "county": "Tulsa",
        "value": "Tulsa",
        "state": "OK"
    },
    {
        "code": "40145",
        "county": "Wagoner",
        "value": "Wagoner",
        "state": "OK"
    },
    {
        "code": "40147",
        "county": "Washington",
        "value": "Washington",
        "state": "OK"
    },
    {
        "code": "40149",
        "county": "Washita",
        "value": "Washita",
        "state": "OK"
    },
    {
        "code": "40151",
        "county": "Woods",
        "value": "Woods",
        "state": "OK"
    },
    {
        "code": "40153",
        "county": "Woodward",
        "value": "Woodward",
        "state": "OK"
    },
    {
        "code": "40000",
        "county": "Oklahoma",
        "value": "Oklahoma",
        "state": "OK"
    },
    {
        "code": "40001",
        "county": "Adair",
        "value": "Adair",
        "state": "OK"
    },
    {
        "code": "40003",
        "county": "Alfalfa",
        "value": "Alfalfa",
        "state": "OK"
    },
    {
        "code": "40005",
        "county": "Atoka",
        "value": "Atoka",
        "state": "OK"
    },
    {
        "code": "40007",
        "county": "Beaver",
        "value": "Beaver",
        "state": "OK"
    },
    {
        "code": "40009",
        "county": "Beckham",
        "value": "Beckham",
        "state": "OK"
    },
    {
        "code": "40011",
        "county": "Blaine",
        "value": "Blaine",
        "state": "OK"
    },
    {
        "code": "40013",
        "county": "Bryan",
        "value": "Bryan",
        "state": "OK"
    },
    {
        "code": "40015",
        "county": "Caddo",
        "value": "Caddo",
        "state": "OK"
    },
    {
        "code": "40017",
        "county": "Canadian",
        "value": "Canadian",
        "state": "OK"
    },
    {
        "code": "40019",
        "county": "Carter",
        "value": "Carter",
        "state": "OK"
    },
    {
        "code": "40021",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "OK"
    },
    {
        "code": "40023",
        "county": "Choctaw",
        "value": "Choctaw",
        "state": "OK"
    },
    {
        "code": "40025",
        "county": "Cimarron",
        "value": "Cimarron",
        "state": "OK"
    },
    {
        "code": "40027",
        "county": "Cleveland",
        "value": "Cleveland",
        "state": "OK"
    },
    {
        "code": "40029",
        "county": "Coal",
        "value": "Coal",
        "state": "OK"
    },
    {
        "code": "40031",
        "county": "Comanche",
        "value": "Comanche",
        "state": "OK"
    },
    {
        "code": "40033",
        "county": "Cotton",
        "value": "Cotton",
        "state": "OK"
    },
    {
        "code": "40035",
        "county": "Craig",
        "value": "Craig",
        "state": "OK"
    },
    {
        "code": "40037",
        "county": "Creek",
        "value": "Creek",
        "state": "OK"
    },
    {
        "code": "40039",
        "county": "Custer",
        "value": "Custer",
        "state": "OK"
    },
    {
        "code": "40041",
        "county": "Delaware",
        "value": "Delaware",
        "state": "OK"
    },
    {
        "code": "40043",
        "county": "Dewey",
        "value": "Dewey",
        "state": "OK"
    },
    {
        "code": "40045",
        "county": "Ellis",
        "value": "Ellis",
        "state": "OK"
    },
    {
        "code": "40047",
        "county": "Garfield",
        "value": "Garfield",
        "state": "OK"
    },
    {
        "code": "40049",
        "county": "Garvin",
        "value": "Garvin",
        "state": "OK"
    },
    {
        "code": "40051",
        "county": "Grady",
        "value": "Grady",
        "state": "OK"
    },
    {
        "code": "40053",
        "county": "Grant",
        "value": "Grant",
        "state": "OK"
    },
    {
        "code": "40055",
        "county": "Greer",
        "value": "Greer",
        "state": "OK"
    },
    {
        "code": "40057",
        "county": "Harmon",
        "value": "Harmon",
        "state": "OK"
    },
    {
        "code": "40059",
        "county": "Harper",
        "value": "Harper",
        "state": "OK"
    },
    {
        "code": "40061",
        "county": "Haskell",
        "value": "Haskell",
        "state": "OK"
    },
    {
        "code": "40063",
        "county": "Hughes",
        "value": "Hughes",
        "state": "OK"
    },
    {
        "code": "40065",
        "county": "Jackson",
        "value": "Jackson",
        "state": "OK"
    },
    {
        "code": "40067",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "OK"
    },
    {
        "code": "40069",
        "county": "Johnston",
        "value": "Johnston",
        "state": "OK"
    },
    {
        "code": "40071",
        "county": "Kay",
        "value": "Kay",
        "state": "OK"
    },
    {
        "code": "40073",
        "county": "Kingfisher",
        "value": "Kingfisher",
        "state": "OK"
    },
    {
        "code": "40075",
        "county": "Kiowa",
        "value": "Kiowa",
        "state": "OK"
    },
    {
        "code": "40077",
        "county": "Latimer",
        "value": "Latimer",
        "state": "OK"
    },
    {
        "code": "40079",
        "county": "Le Flore",
        "value": "Le Flore",
        "state": "OK"
    },
    {
        "code": "40081",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "OK"
    },
    {
        "code": "40083",
        "county": "Logan",
        "value": "Logan",
        "state": "OK"
    },
    {
        "code": "41049",
        "county": "Morrow",
        "value": "Morrow",
        "state": "OR"
    },
    {
        "code": "41047",
        "county": "Marion",
        "value": "Marion",
        "state": "OR"
    },
    {
        "code": "41053",
        "county": "Polk",
        "value": "Polk",
        "state": "OR"
    },
    {
        "code": "41055",
        "county": "Sherman",
        "value": "Sherman",
        "state": "OR"
    },
    {
        "code": "41057",
        "county": "Tillamook",
        "value": "Tillamook",
        "state": "OR"
    },
    {
        "code": "41059",
        "county": "Umatilla",
        "value": "Umatilla",
        "state": "OR"
    },
    {
        "code": "41061",
        "county": "Union",
        "value": "Union",
        "state": "OR"
    },
    {
        "code": "41063",
        "county": "Wallowa",
        "value": "Wallowa",
        "state": "OR"
    },
    {
        "code": "41065",
        "county": "Wasco",
        "value": "Wasco",
        "state": "OR"
    },
    {
        "code": "41067",
        "county": "Washington",
        "value": "Washington",
        "state": "OR"
    },
    {
        "code": "41069",
        "county": "Wheeler",
        "value": "Wheeler",
        "state": "OR"
    },
    {
        "code": "41071",
        "county": "Yamhill",
        "value": "Yamhill",
        "state": "OR"
    },
    {
        "code": "41000",
        "county": "Oregon",
        "value": "Oregon",
        "state": "OR"
    },
    {
        "code": "41001",
        "county": "Baker",
        "value": "Baker",
        "state": "OR"
    },
    {
        "code": "41003",
        "county": "Benton",
        "value": "Benton",
        "state": "OR"
    },
    {
        "code": "41005",
        "county": "Clackamas",
        "value": "Clackamas",
        "state": "OR"
    },
    {
        "code": "41007",
        "county": "Clatsop",
        "value": "Clatsop",
        "state": "OR"
    },
    {
        "code": "41009",
        "county": "Columbia",
        "value": "Columbia",
        "state": "OR"
    },
    {
        "code": "41011",
        "county": "Coos",
        "value": "Coos",
        "state": "OR"
    },
    {
        "code": "41013",
        "county": "Crook",
        "value": "Crook",
        "state": "OR"
    },
    {
        "code": "41015",
        "county": "Curry",
        "value": "Curry",
        "state": "OR"
    },
    {
        "code": "41017",
        "county": "Deschutes",
        "value": "Deschutes",
        "state": "OR"
    },
    {
        "code": "41019",
        "county": "Douglas",
        "value": "Douglas",
        "state": "OR"
    },
    {
        "code": "41021",
        "county": "Gilliam",
        "value": "Gilliam",
        "state": "OR"
    },
    {
        "code": "41023",
        "county": "Grant",
        "value": "Grant",
        "state": "OR"
    },
    {
        "code": "41025",
        "county": "Harney",
        "value": "Harney",
        "state": "OR"
    },
    {
        "code": "41027",
        "county": "Hood River",
        "value": "Hood River",
        "state": "OR"
    },
    {
        "code": "41029",
        "county": "Jackson",
        "value": "Jackson",
        "state": "OR"
    },
    {
        "code": "41031",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "OR"
    },
    {
        "code": "41033",
        "county": "Josephine",
        "value": "Josephine",
        "state": "OR"
    },
    {
        "code": "41035",
        "county": "Klamath",
        "value": "Klamath",
        "state": "OR"
    },
    {
        "code": "41037",
        "county": "Lake",
        "value": "Lake",
        "state": "OR"
    },
    {
        "code": "41039",
        "county": "Lane",
        "value": "Lane",
        "state": "OR"
    },
    {
        "code": "41041",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "OR"
    },
    {
        "code": "41043",
        "county": "Linn",
        "value": "Linn",
        "state": "OR"
    },
    {
        "code": "41045",
        "county": "Malheur",
        "value": "Malheur",
        "state": "OR"
    },
    {
        "code": "41051",
        "county": "Multnomah",
        "value": "Multnomah",
        "state": "OR"
    },
    {
        "code": "42127",
        "county": "Wayne",
        "value": "Wayne",
        "state": "PA"
    },
    {
        "code": "42129",
        "county": "Westmoreland",
        "value": "Westmoreland",
        "state": "PA"
    },
    {
        "code": "42131",
        "county": "Wyoming",
        "value": "Wyoming",
        "state": "PA"
    },
    {
        "code": "42133",
        "county": "York",
        "value": "York",
        "state": "PA"
    },
    {
        "code": "42000",
        "county": "Pennsylvania",
        "value": "Pennsylvania",
        "state": "PA"
    },
    {
        "code": "42001",
        "county": "Adams",
        "value": "Adams",
        "state": "PA"
    },
    {
        "code": "42003",
        "county": "Allegheny",
        "value": "Allegheny",
        "state": "PA"
    },
    {
        "code": "42005",
        "county": "Armstrong",
        "value": "Armstrong",
        "state": "PA"
    },
    {
        "code": "42007",
        "county": "Beaver",
        "value": "Beaver",
        "state": "PA"
    },
    {
        "code": "42009",
        "county": "Bedford",
        "value": "Bedford",
        "state": "PA"
    },
    {
        "code": "42011",
        "county": "Berks",
        "value": "Berks",
        "state": "PA"
    },
    {
        "code": "42013",
        "county": "Blair",
        "value": "Blair",
        "state": "PA"
    },
    {
        "code": "42015",
        "county": "Bradford",
        "value": "Bradford",
        "state": "PA"
    },
    {
        "code": "42017",
        "county": "Bucks",
        "value": "Bucks",
        "state": "PA"
    },
    {
        "code": "42019",
        "county": "Butler",
        "value": "Butler",
        "state": "PA"
    },
    {
        "code": "42021",
        "county": "Cambria",
        "value": "Cambria",
        "state": "PA"
    },
    {
        "code": "42023",
        "county": "Cameron",
        "value": "Cameron",
        "state": "PA"
    },
    {
        "code": "42025",
        "county": "Carbon",
        "value": "Carbon",
        "state": "PA"
    },
    {
        "code": "42027",
        "county": "Centre",
        "value": "Centre",
        "state": "PA"
    },
    {
        "code": "42029",
        "county": "Chester",
        "value": "Chester",
        "state": "PA"
    },
    {
        "code": "42031",
        "county": "Clarion",
        "value": "Clarion",
        "state": "PA"
    },
    {
        "code": "42033",
        "county": "Clearfield",
        "value": "Clearfield",
        "state": "PA"
    },
    {
        "code": "42035",
        "county": "Clinton",
        "value": "Clinton",
        "state": "PA"
    },
    {
        "code": "42037",
        "county": "Columbia",
        "value": "Columbia",
        "state": "PA"
    },
    {
        "code": "42039",
        "county": "Crawford",
        "value": "Crawford",
        "state": "PA"
    },
    {
        "code": "42041",
        "county": "Cumberland",
        "value": "Cumberland",
        "state": "PA"
    },
    {
        "code": "42043",
        "county": "Dauphin",
        "value": "Dauphin",
        "state": "PA"
    },
    {
        "code": "42045",
        "county": "Delaware",
        "value": "Delaware",
        "state": "PA"
    },
    {
        "code": "42047",
        "county": "Elk",
        "value": "Elk",
        "state": "PA"
    },
    {
        "code": "42049",
        "county": "Erie",
        "value": "Erie",
        "state": "PA"
    },
    {
        "code": "42051",
        "county": "Fayette",
        "value": "Fayette",
        "state": "PA"
    },
    {
        "code": "42053",
        "county": "Forest",
        "value": "Forest",
        "state": "PA"
    },
    {
        "code": "42055",
        "county": "Franklin",
        "value": "Franklin",
        "state": "PA"
    },
    {
        "code": "42057",
        "county": "Fulton",
        "value": "Fulton",
        "state": "PA"
    },
    {
        "code": "42059",
        "county": "Greene",
        "value": "Greene",
        "state": "PA"
    },
    {
        "code": "42061",
        "county": "Huntingdon",
        "value": "Huntingdon",
        "state": "PA"
    },
    {
        "code": "42063",
        "county": "Indiana",
        "value": "Indiana",
        "state": "PA"
    },
    {
        "code": "42065",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "PA"
    },
    {
        "code": "42067",
        "county": "Juniata",
        "value": "Juniata",
        "state": "PA"
    },
    {
        "code": "42069",
        "county": "Lackawanna",
        "value": "Lackawanna",
        "state": "PA"
    },
    {
        "code": "42071",
        "county": "Lancaster",
        "value": "Lancaster",
        "state": "PA"
    },
    {
        "code": "42073",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "PA"
    },
    {
        "code": "42075",
        "county": "Lebanon",
        "value": "Lebanon",
        "state": "PA"
    },
    {
        "code": "42077",
        "county": "Lehigh",
        "value": "Lehigh",
        "state": "PA"
    },
    {
        "code": "42079",
        "county": "Luzerne",
        "value": "Luzerne",
        "state": "PA"
    },
    {
        "code": "42081",
        "county": "Lycoming",
        "value": "Lycoming",
        "state": "PA"
    },
    {
        "code": "42083",
        "county": "Mc Kean",
        "value": "Mc Kean",
        "state": "PA"
    },
    {
        "code": "42085",
        "county": "Mercer",
        "value": "Mercer",
        "state": "PA"
    },
    {
        "code": "42087",
        "county": "Mifflin",
        "value": "Mifflin",
        "state": "PA"
    },
    {
        "code": "42089",
        "county": "Monroe",
        "value": "Monroe",
        "state": "PA"
    },
    {
        "code": "42091",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "PA"
    },
    {
        "code": "42093",
        "county": "Montour",
        "value": "Montour",
        "state": "PA"
    },
    {
        "code": "42095",
        "county": "Northampton",
        "value": "Northampton",
        "state": "PA"
    },
    {
        "code": "42097",
        "county": "Northumberland",
        "value": "Northumberland",
        "state": "PA"
    },
    {
        "code": "42099",
        "county": "Perry",
        "value": "Perry",
        "state": "PA"
    },
    {
        "code": "42101",
        "county": "Philadelphia",
        "value": "Philadelphia",
        "state": "PA"
    },
    {
        "code": "42103",
        "county": "Pike",
        "value": "Pike",
        "state": "PA"
    },
    {
        "code": "42105",
        "county": "Potter",
        "value": "Potter",
        "state": "PA"
    },
    {
        "code": "42107",
        "county": "Schuylkill",
        "value": "Schuylkill",
        "state": "PA"
    },
    {
        "code": "42109",
        "county": "Snyder",
        "value": "Snyder",
        "state": "PA"
    },
    {
        "code": "42111",
        "county": "Somerset",
        "value": "Somerset",
        "state": "PA"
    },
    {
        "code": "42113",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "PA"
    },
    {
        "code": "42115",
        "county": "Susquehanna",
        "value": "Susquehanna",
        "state": "PA"
    },
    {
        "code": "42117",
        "county": "Tioga",
        "value": "Tioga",
        "state": "PA"
    },
    {
        "code": "42119",
        "county": "Union",
        "value": "Union",
        "state": "PA"
    },
    {
        "code": "42121",
        "county": "Venango",
        "value": "Venango",
        "state": "PA"
    },
    {
        "code": "42123",
        "county": "Warren",
        "value": "Warren",
        "state": "PA"
    },
    {
        "code": "42125",
        "county": "Washington",
        "value": "Washington",
        "state": "PA"
    },
    {
        "code": "44000",
        "county": "Rhode Island",
        "value": "Rhode Island",
        "state": "RI"
    },
    {
        "code": "44001",
        "county": "Bristol",
        "value": "Bristol",
        "state": "RI"
    },
    {
        "code": "44009",
        "county": "Washington",
        "value": "Washington",
        "state": "RI"
    },
    {
        "code": "44005",
        "county": "Newport",
        "value": "Newport",
        "state": "RI"
    },
    {
        "code": "44007",
        "county": "Providence",
        "value": "Providence",
        "state": "RI"
    },
    {
        "code": "44003",
        "county": "Kent",
        "value": "Kent",
        "state": "RI"
    },
    {
        "code": "45000",
        "county": "South Carolina",
        "value": "South Carolina",
        "state": "SC"
    },
    {
        "code": "45091",
        "county": "York",
        "value": "York",
        "state": "SC"
    },
    {
        "code": "45003",
        "county": "Aiken",
        "value": "Aiken",
        "state": "SC"
    },
    {
        "code": "45005",
        "county": "Allendale",
        "value": "Allendale",
        "state": "SC"
    },
    {
        "code": "45007",
        "county": "Anderson",
        "value": "Anderson",
        "state": "SC"
    },
    {
        "code": "45009",
        "county": "Bamberg",
        "value": "Bamberg",
        "state": "SC"
    },
    {
        "code": "45011",
        "county": "Barnwell",
        "value": "Barnwell",
        "state": "SC"
    },
    {
        "code": "45013",
        "county": "Beaufort",
        "value": "Beaufort",
        "state": "SC"
    },
    {
        "code": "45015",
        "county": "Berkeley",
        "value": "Berkeley",
        "state": "SC"
    },
    {
        "code": "45017",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "SC"
    },
    {
        "code": "45019",
        "county": "Charleston",
        "value": "Charleston",
        "state": "SC"
    },
    {
        "code": "45021",
        "county": "Cherokee",
        "value": "Cherokee",
        "state": "SC"
    },
    {
        "code": "45023",
        "county": "Chester",
        "value": "Chester",
        "state": "SC"
    },
    {
        "code": "45025",
        "county": "Chesterfield",
        "value": "Chesterfield",
        "state": "SC"
    },
    {
        "code": "45027",
        "county": "Clarendon",
        "value": "Clarendon",
        "state": "SC"
    },
    {
        "code": "45029",
        "county": "Colleton",
        "value": "Colleton",
        "state": "SC"
    },
    {
        "code": "45031",
        "county": "Darlington",
        "value": "Darlington",
        "state": "SC"
    },
    {
        "code": "45033",
        "county": "Dillon",
        "value": "Dillon",
        "state": "SC"
    },
    {
        "code": "45035",
        "county": "Dorchester",
        "value": "Dorchester",
        "state": "SC"
    },
    {
        "code": "45037",
        "county": "Edgefield",
        "value": "Edgefield",
        "state": "SC"
    },
    {
        "code": "45039",
        "county": "Fairfield",
        "value": "Fairfield",
        "state": "SC"
    },
    {
        "code": "45041",
        "county": "Florence",
        "value": "Florence",
        "state": "SC"
    },
    {
        "code": "45043",
        "county": "Georgetown",
        "value": "Georgetown",
        "state": "SC"
    },
    {
        "code": "45045",
        "county": "Greenville",
        "value": "Greenville",
        "state": "SC"
    },
    {
        "code": "45047",
        "county": "Greenwood",
        "value": "Greenwood",
        "state": "SC"
    },
    {
        "code": "45049",
        "county": "Hampton",
        "value": "Hampton",
        "state": "SC"
    },
    {
        "code": "45051",
        "county": "Horry",
        "value": "Horry",
        "state": "SC"
    },
    {
        "code": "45053",
        "county": "Jasper",
        "value": "Jasper",
        "state": "SC"
    },
    {
        "code": "45055",
        "county": "Kershaw",
        "value": "Kershaw",
        "state": "SC"
    },
    {
        "code": "45057",
        "county": "Lancaster",
        "value": "Lancaster",
        "state": "SC"
    },
    {
        "code": "45059",
        "county": "Laurens",
        "value": "Laurens",
        "state": "SC"
    },
    {
        "code": "45061",
        "county": "Lee",
        "value": "Lee",
        "state": "SC"
    },
    {
        "code": "45063",
        "county": "Lexington",
        "value": "Lexington",
        "state": "SC"
    },
    {
        "code": "45065",
        "county": "McCormick",
        "value": "McCormick",
        "state": "SC"
    },
    {
        "code": "45067",
        "county": "Marion",
        "value": "Marion",
        "state": "SC"
    },
    {
        "code": "45069",
        "county": "Marlboro",
        "value": "Marlboro",
        "state": "SC"
    },
    {
        "code": "45071",
        "county": "Newberry",
        "value": "Newberry",
        "state": "SC"
    },
    {
        "code": "45073",
        "county": "Oconee",
        "value": "Oconee",
        "state": "SC"
    },
    {
        "code": "45075",
        "county": "Orangeburg",
        "value": "Orangeburg",
        "state": "SC"
    },
    {
        "code": "45077",
        "county": "Pickens",
        "value": "Pickens",
        "state": "SC"
    },
    {
        "code": "45079",
        "county": "Richland",
        "value": "Richland",
        "state": "SC"
    },
    {
        "code": "45081",
        "county": "Saluda",
        "value": "Saluda",
        "state": "SC"
    },
    {
        "code": "45083",
        "county": "Spartanburg",
        "value": "Spartanburg",
        "state": "SC"
    },
    {
        "code": "45085",
        "county": "Sumter",
        "value": "Sumter",
        "state": "SC"
    },
    {
        "code": "45087",
        "county": "Union",
        "value": "Union",
        "state": "SC"
    },
    {
        "code": "45089",
        "county": "Williamsburg",
        "value": "Williamsburg",
        "state": "SC"
    },
    {
        "code": "45001",
        "county": "Abbeville",
        "value": "Abbeville",
        "state": "SC"
    },
    {
        "code": "46035",
        "county": "Davison",
        "value": "Davison",
        "state": "SD"
    },
    {
        "code": "46037",
        "county": "Day",
        "value": "Day",
        "state": "SD"
    },
    {
        "code": "46039",
        "county": "Deuel",
        "value": "Deuel",
        "state": "SD"
    },
    {
        "code": "46041",
        "county": "Dewey",
        "value": "Dewey",
        "state": "SD"
    },
    {
        "code": "46043",
        "county": "Douglas",
        "value": "Douglas",
        "state": "SD"
    },
    {
        "code": "46045",
        "county": "Edmunds",
        "value": "Edmunds",
        "state": "SD"
    },
    {
        "code": "46047",
        "county": "Fall River",
        "value": "Fall River",
        "state": "SD"
    },
    {
        "code": "46049",
        "county": "Faulk",
        "value": "Faulk",
        "state": "SD"
    },
    {
        "code": "46051",
        "county": "Grant",
        "value": "Grant",
        "state": "SD"
    },
    {
        "code": "46053",
        "county": "Gregory",
        "value": "Gregory",
        "state": "SD"
    },
    {
        "code": "46055",
        "county": "Haakon",
        "value": "Haakon",
        "state": "SD"
    },
    {
        "code": "46057",
        "county": "Hamlin",
        "value": "Hamlin",
        "state": "SD"
    },
    {
        "code": "46059",
        "county": "Hand",
        "value": "Hand",
        "state": "SD"
    },
    {
        "code": "46061",
        "county": "Hanson",
        "value": "Hanson",
        "state": "SD"
    },
    {
        "code": "46063",
        "county": "Harding",
        "value": "Harding",
        "state": "SD"
    },
    {
        "code": "46065",
        "county": "Hughes",
        "value": "Hughes",
        "state": "SD"
    },
    {
        "code": "46067",
        "county": "Hutchinson",
        "value": "Hutchinson",
        "state": "SD"
    },
    {
        "code": "46069",
        "county": "Hyde",
        "value": "Hyde",
        "state": "SD"
    },
    {
        "code": "46071",
        "county": "Jackson",
        "value": "Jackson",
        "state": "SD"
    },
    {
        "code": "46073",
        "county": "Jerauld",
        "value": "Jerauld",
        "state": "SD"
    },
    {
        "code": "46075",
        "county": "Jones",
        "value": "Jones",
        "state": "SD"
    },
    {
        "code": "46077",
        "county": "Kingsbury",
        "value": "Kingsbury",
        "state": "SD"
    },
    {
        "code": "46079",
        "county": "Lake",
        "value": "Lake",
        "state": "SD"
    },
    {
        "code": "46081",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "SD"
    },
    {
        "code": "46083",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "SD"
    },
    {
        "code": "46085",
        "county": "Lyman",
        "value": "Lyman",
        "state": "SD"
    },
    {
        "code": "46087",
        "county": "McCook",
        "value": "McCook",
        "state": "SD"
    },
    {
        "code": "46089",
        "county": "McPherson",
        "value": "McPherson",
        "state": "SD"
    },
    {
        "code": "46091",
        "county": "Marshall",
        "value": "Marshall",
        "state": "SD"
    },
    {
        "code": "46093",
        "county": "Meade",
        "value": "Meade",
        "state": "SD"
    },
    {
        "code": "46095",
        "county": "Mellette",
        "value": "Mellette",
        "state": "SD"
    },
    {
        "code": "46097",
        "county": "Miner",
        "value": "Miner",
        "state": "SD"
    },
    {
        "code": "46099",
        "county": "Minnehaha",
        "value": "Minnehaha",
        "state": "SD"
    },
    {
        "code": "46101",
        "county": "Moody",
        "value": "Moody",
        "state": "SD"
    },
    {
        "code": "46103",
        "county": "Pennington",
        "value": "Pennington",
        "state": "SD"
    },
    {
        "code": "46105",
        "county": "Perkins",
        "value": "Perkins",
        "state": "SD"
    },
    {
        "code": "46107",
        "county": "Potter",
        "value": "Potter",
        "state": "SD"
    },
    {
        "code": "46109",
        "county": "Roberts",
        "value": "Roberts",
        "state": "SD"
    },
    {
        "code": "46111",
        "county": "Sanborn",
        "value": "Sanborn",
        "state": "SD"
    },
    {
        "code": "46113",
        "county": "Shannon",
        "value": "Shannon",
        "state": "SD"
    },
    {
        "code": "46115",
        "county": "Spink",
        "value": "Spink",
        "state": "SD"
    },
    {
        "code": "46117",
        "county": "Stanley",
        "value": "Stanley",
        "state": "SD"
    },
    {
        "code": "46119",
        "county": "Sully",
        "value": "Sully",
        "state": "SD"
    },
    {
        "code": "46121",
        "county": "Todd",
        "value": "Todd",
        "state": "SD"
    },
    {
        "code": "46123",
        "county": "Tripp",
        "value": "Tripp",
        "state": "SD"
    },
    {
        "code": "46125",
        "county": "Turner",
        "value": "Turner",
        "state": "SD"
    },
    {
        "code": "46127",
        "county": "Union",
        "value": "Union",
        "state": "SD"
    },
    {
        "code": "46129",
        "county": "Walworth",
        "value": "Walworth",
        "state": "SD"
    },
    {
        "code": "46135",
        "county": "Yankton",
        "value": "Yankton",
        "state": "SD"
    },
    {
        "code": "46137",
        "county": "Ziebach",
        "value": "Ziebach",
        "state": "SD"
    },
    {
        "code": "46000",
        "county": "South Dakota",
        "value": "South Dakota",
        "state": "SD"
    },
    {
        "code": "46003",
        "county": "Aurora",
        "value": "Aurora",
        "state": "SD"
    },
    {
        "code": "46005",
        "county": "Beadle",
        "value": "Beadle",
        "state": "SD"
    },
    {
        "code": "46007",
        "county": "Bennett",
        "value": "Bennett",
        "state": "SD"
    },
    {
        "code": "46009",
        "county": "Bon Homme",
        "value": "Bon Homme",
        "state": "SD"
    },
    {
        "code": "46011",
        "county": "Brookings",
        "value": "Brookings",
        "state": "SD"
    },
    {
        "code": "46013",
        "county": "Brown",
        "value": "Brown",
        "state": "SD"
    },
    {
        "code": "46015",
        "county": "Brule",
        "value": "Brule",
        "state": "SD"
    },
    {
        "code": "46017",
        "county": "Buffalo",
        "value": "Buffalo",
        "state": "SD"
    },
    {
        "code": "46019",
        "county": "Butte",
        "value": "Butte",
        "state": "SD"
    },
    {
        "code": "46021",
        "county": "Campbell",
        "value": "Campbell",
        "state": "SD"
    },
    {
        "code": "46023",
        "county": "Charles Mix",
        "value": "Charles Mix",
        "state": "SD"
    },
    {
        "code": "46025",
        "county": "Clark",
        "value": "Clark",
        "state": "SD"
    },
    {
        "code": "46027",
        "county": "Clay",
        "value": "Clay",
        "state": "SD"
    },
    {
        "code": "46029",
        "county": "Codington",
        "value": "Codington",
        "state": "SD"
    },
    {
        "code": "46031",
        "county": "Corson",
        "value": "Corson",
        "state": "SD"
    },
    {
        "code": "46033",
        "county": "Custer",
        "value": "Custer",
        "state": "SD"
    },
    {
        "code": "47063",
        "county": "Hamblen",
        "value": "Hamblen",
        "state": "TN"
    },
    {
        "code": "47065",
        "county": "Hamilton",
        "value": "Hamilton",
        "state": "TN"
    },
    {
        "code": "47067",
        "county": "Hancock",
        "value": "Hancock",
        "state": "TN"
    },
    {
        "code": "47069",
        "county": "Hardeman",
        "value": "Hardeman",
        "state": "TN"
    },
    {
        "code": "47071",
        "county": "Hardin",
        "value": "Hardin",
        "state": "TN"
    },
    {
        "code": "47073",
        "county": "Hawkins",
        "value": "Hawkins",
        "state": "TN"
    },
    {
        "code": "47075",
        "county": "Haywood",
        "value": "Haywood",
        "state": "TN"
    },
    {
        "code": "47077",
        "county": "Henderson",
        "value": "Henderson",
        "state": "TN"
    },
    {
        "code": "47079",
        "county": "Henry",
        "value": "Henry",
        "state": "TN"
    },
    {
        "code": "47081",
        "county": "Hickman",
        "value": "Hickman",
        "state": "TN"
    },
    {
        "code": "47083",
        "county": "Houston",
        "value": "Houston",
        "state": "TN"
    },
    {
        "code": "47085",
        "county": "Humphreys",
        "value": "Humphreys",
        "state": "TN"
    },
    {
        "code": "47087",
        "county": "Jackson",
        "value": "Jackson",
        "state": "TN"
    },
    {
        "code": "47089",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "TN"
    },
    {
        "code": "47091",
        "county": "Johnson",
        "value": "Johnson",
        "state": "TN"
    },
    {
        "code": "47093",
        "county": "Knox",
        "value": "Knox",
        "state": "TN"
    },
    {
        "code": "47095",
        "county": "Lake",
        "value": "Lake",
        "state": "TN"
    },
    {
        "code": "47097",
        "county": "Lauderdale",
        "value": "Lauderdale",
        "state": "TN"
    },
    {
        "code": "47099",
        "county": "Lawrence",
        "value": "Lawrence",
        "state": "TN"
    },
    {
        "code": "47101",
        "county": "Lewis",
        "value": "Lewis",
        "state": "TN"
    },
    {
        "code": "47103",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "TN"
    },
    {
        "code": "47105",
        "county": "Loudon",
        "value": "Loudon",
        "state": "TN"
    },
    {
        "code": "47107",
        "county": "McMinn",
        "value": "McMinn",
        "state": "TN"
    },
    {
        "code": "47109",
        "county": "McNairy",
        "value": "McNairy",
        "state": "TN"
    },
    {
        "code": "47111",
        "county": "Macon",
        "value": "Macon",
        "state": "TN"
    },
    {
        "code": "47113",
        "county": "Madison",
        "value": "Madison",
        "state": "TN"
    },
    {
        "code": "47115",
        "county": "Marion",
        "value": "Marion",
        "state": "TN"
    },
    {
        "code": "47117",
        "county": "Marshall",
        "value": "Marshall",
        "state": "TN"
    },
    {
        "code": "47119",
        "county": "Maury",
        "value": "Maury",
        "state": "TN"
    },
    {
        "code": "47121",
        "county": "Meigs",
        "value": "Meigs",
        "state": "TN"
    },
    {
        "code": "47123",
        "county": "Monroe",
        "value": "Monroe",
        "state": "TN"
    },
    {
        "code": "47125",
        "county": "Montgomery",
        "value": "Montgomery",
        "state": "TN"
    },
    {
        "code": "47127",
        "county": "Moore",
        "value": "Moore",
        "state": "TN"
    },
    {
        "code": "47129",
        "county": "Morgan",
        "value": "Morgan",
        "state": "TN"
    },
    {
        "code": "47131",
        "county": "Obion",
        "value": "Obion",
        "state": "TN"
    },
    {
        "code": "47133",
        "county": "Overton",
        "value": "Overton",
        "state": "TN"
    },
    {
        "code": "47135",
        "county": "Perry",
        "value": "Perry",
        "state": "TN"
    },
    {
        "code": "47137",
        "county": "Pickett",
        "value": "Pickett",
        "state": "TN"
    },
    {
        "code": "47139",
        "county": "Polk",
        "value": "Polk",
        "state": "TN"
    },
    {
        "code": "47141",
        "county": "Putnam",
        "value": "Putnam",
        "state": "TN"
    },
    {
        "code": "47143",
        "county": "Rhea",
        "value": "Rhea",
        "state": "TN"
    },
    {
        "code": "47145",
        "county": "Roane",
        "value": "Roane",
        "state": "TN"
    },
    {
        "code": "47147",
        "county": "Robertson",
        "value": "Robertson",
        "state": "TN"
    },
    {
        "code": "47149",
        "county": "Rutherford",
        "value": "Rutherford",
        "state": "TN"
    },
    {
        "code": "47151",
        "county": "Scott",
        "value": "Scott",
        "state": "TN"
    },
    {
        "code": "47153",
        "county": "Sequatchie",
        "value": "Sequatchie",
        "state": "TN"
    },
    {
        "code": "47155",
        "county": "Sevier",
        "value": "Sevier",
        "state": "TN"
    },
    {
        "code": "47157",
        "county": "Shelby",
        "value": "Shelby",
        "state": "TN"
    },
    {
        "code": "47159",
        "county": "Smith",
        "value": "Smith",
        "state": "TN"
    },
    {
        "code": "47161",
        "county": "Stewart",
        "value": "Stewart",
        "state": "TN"
    },
    {
        "code": "47163",
        "county": "Sullivan",
        "value": "Sullivan",
        "state": "TN"
    },
    {
        "code": "47165",
        "county": "Sumner",
        "value": "Sumner",
        "state": "TN"
    },
    {
        "code": "47167",
        "county": "Tipton",
        "value": "Tipton",
        "state": "TN"
    },
    {
        "code": "47169",
        "county": "Trousdale",
        "value": "Trousdale",
        "state": "TN"
    },
    {
        "code": "47171",
        "county": "Unicoi",
        "value": "Unicoi",
        "state": "TN"
    },
    {
        "code": "47173",
        "county": "Union",
        "value": "Union",
        "state": "TN"
    },
    {
        "code": "47175",
        "county": "Van Buren",
        "value": "Van Buren",
        "state": "TN"
    },
    {
        "code": "47177",
        "county": "Warren",
        "value": "Warren",
        "state": "TN"
    },
    {
        "code": "47179",
        "county": "Washington",
        "value": "Washington",
        "state": "TN"
    },
    {
        "code": "47181",
        "county": "Wayne",
        "value": "Wayne",
        "state": "TN"
    },
    {
        "code": "47183",
        "county": "Weakley",
        "value": "Weakley",
        "state": "TN"
    },
    {
        "code": "47185",
        "county": "White",
        "value": "White",
        "state": "TN"
    },
    {
        "code": "47187",
        "county": "Williamson",
        "value": "Williamson",
        "state": "TN"
    },
    {
        "code": "47189",
        "county": "Wilson",
        "value": "Wilson",
        "state": "TN"
    },
    {
        "code": "47000",
        "county": "Tennessee",
        "value": "Tennessee",
        "state": "TN"
    },
    {
        "code": "47001",
        "county": "Anderson",
        "value": "Anderson",
        "state": "TN"
    },
    {
        "code": "47003",
        "county": "Bedford",
        "value": "Bedford",
        "state": "TN"
    },
    {
        "code": "47005",
        "county": "Benton",
        "value": "Benton",
        "state": "TN"
    },
    {
        "code": "47007",
        "county": "Bledsoe",
        "value": "Bledsoe",
        "state": "TN"
    },
    {
        "code": "47009",
        "county": "Blount",
        "value": "Blount",
        "state": "TN"
    },
    {
        "code": "47011",
        "county": "Bradley",
        "value": "Bradley",
        "state": "TN"
    },
    {
        "code": "47013",
        "county": "Campbell",
        "value": "Campbell",
        "state": "TN"
    },
    {
        "code": "47015",
        "county": "Cannon",
        "value": "Cannon",
        "state": "TN"
    },
    {
        "code": "47017",
        "county": "Carroll",
        "value": "Carroll",
        "state": "TN"
    },
    {
        "code": "47019",
        "county": "Carter",
        "value": "Carter",
        "state": "TN"
    },
    {
        "code": "47021",
        "county": "Cheatham",
        "value": "Cheatham",
        "state": "TN"
    },
    {
        "code": "47023",
        "county": "Chester",
        "value": "Chester",
        "state": "TN"
    },
    {
        "code": "47025",
        "county": "Claiborne",
        "value": "Claiborne",
        "state": "TN"
    },
    {
        "code": "47027",
        "county": "Clay",
        "value": "Clay",
        "state": "TN"
    },
    {
        "code": "47029",
        "county": "Cocke",
        "value": "Cocke",
        "state": "TN"
    },
    {
        "code": "66000",
        "county": "Guam",
        "value": "Guam",
        "state": "GU"
    },
    {
        "code": "72000",
        "county": "Puerto Rico",
        "value": "Puerto Rico",
        "state": "PR"
    },
    {
        "code": "78000",
        "county": "Virgin Islands",
        "value": "Virgin Islands",
        "state": "VI"
    },
    {
        "code": "50013",
        "county": "Grand Isle",
        "value": "Grand Isle",
        "state": "VT"
    },
    {
        "code": "50015",
        "county": "Lamoille",
        "value": "Lamoille",
        "state": "VT"
    },
    {
        "code": "50017",
        "county": "Orange",
        "value": "Orange",
        "state": "VT"
    },
    {
        "code": "50019",
        "county": "Orleans",
        "value": "Orleans",
        "state": "VT"
    },
    {
        "code": "50021",
        "county": "Rutland",
        "value": "Rutland",
        "state": "VT"
    },
    {
        "code": "50023",
        "county": "Washington",
        "value": "Washington",
        "state": "VT"
    },
    {
        "code": "50025",
        "county": "Windham",
        "value": "Windham",
        "state": "VT"
    },
    {
        "code": "50001",
        "county": "Addison",
        "value": "Addison",
        "state": "VT"
    },
    {
        "code": "53000",
        "county": "Washington",
        "value": "Washington",
        "state": "WA"
    },
    {
        "code": "53077",
        "county": "Yakima",
        "value": "Yakima",
        "state": "WA"
    },
    {
        "code": "53003",
        "county": "Asotin",
        "value": "Asotin",
        "state": "WA"
    },
    {
        "code": "53005",
        "county": "Benton",
        "value": "Benton",
        "state": "WA"
    },
    {
        "code": "53007",
        "county": "Chelan",
        "value": "Chelan",
        "state": "WA"
    },
    {
        "code": "53009",
        "county": "Clallam",
        "value": "Clallam",
        "state": "WA"
    },
    {
        "code": "53011",
        "county": "Clark",
        "value": "Clark",
        "state": "WA"
    },
    {
        "code": "53013",
        "county": "Columbia",
        "value": "Columbia",
        "state": "WA"
    },
    {
        "code": "53015",
        "county": "Cowlitz",
        "value": "Cowlitz",
        "state": "WA"
    },
    {
        "code": "53017",
        "county": "Douglas",
        "value": "Douglas",
        "state": "WA"
    },
    {
        "code": "53019",
        "county": "Ferry",
        "value": "Ferry",
        "state": "WA"
    },
    {
        "code": "53021",
        "county": "Franklin",
        "value": "Franklin",
        "state": "WA"
    },
    {
        "code": "53023",
        "county": "Garfield",
        "value": "Garfield",
        "state": "WA"
    },
    {
        "code": "53025",
        "county": "Grant",
        "value": "Grant",
        "state": "WA"
    },
    {
        "code": "53027",
        "county": "Grays Harbor",
        "value": "Grays Harbor",
        "state": "WA"
    },
    {
        "code": "53029",
        "county": "Island",
        "value": "Island",
        "state": "WA"
    },
    {
        "code": "53031",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "WA"
    },
    {
        "code": "53033",
        "county": "King",
        "value": "King",
        "state": "WA"
    },
    {
        "code": "53035",
        "county": "Kitsap",
        "value": "Kitsap",
        "state": "WA"
    },
    {
        "code": "53037",
        "county": "Kittitas",
        "value": "Kittitas",
        "state": "WA"
    },
    {
        "code": "53039",
        "county": "Klickitat",
        "value": "Klickitat",
        "state": "WA"
    },
    {
        "code": "53041",
        "county": "Lewis",
        "value": "Lewis",
        "state": "WA"
    },
    {
        "code": "53043",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "WA"
    },
    {
        "code": "53045",
        "county": "Mason",
        "value": "Mason",
        "state": "WA"
    },
    {
        "code": "53047",
        "county": "Okanogan",
        "value": "Okanogan",
        "state": "WA"
    },
    {
        "code": "53049",
        "county": "Pacific",
        "value": "Pacific",
        "state": "WA"
    },
    {
        "code": "53051",
        "county": "Pend Oreille",
        "value": "Pend Oreille",
        "state": "WA"
    },
    {
        "code": "53053",
        "county": "Pierce",
        "value": "Pierce",
        "state": "WA"
    },
    {
        "code": "53055",
        "county": "San Juan",
        "value": "San Juan",
        "state": "WA"
    },
    {
        "code": "53057",
        "county": "Skagit",
        "value": "Skagit",
        "state": "WA"
    },
    {
        "code": "53059",
        "county": "Skamania",
        "value": "Skamania",
        "state": "WA"
    },
    {
        "code": "53061",
        "county": "Snohomish",
        "value": "Snohomish",
        "state": "WA"
    },
    {
        "code": "53063",
        "county": "Spokane",
        "value": "Spokane",
        "state": "WA"
    },
    {
        "code": "53065",
        "county": "Stevens",
        "value": "Stevens",
        "state": "WA"
    },
    {
        "code": "53067",
        "county": "Thurston",
        "value": "Thurston",
        "state": "WA"
    },
    {
        "code": "53069",
        "county": "Wahkiakum",
        "value": "Wahkiakum",
        "state": "WA"
    },
    {
        "code": "53071",
        "county": "Walla Walla",
        "value": "Walla Walla",
        "state": "WA"
    },
    {
        "code": "53073",
        "county": "Whatcom",
        "value": "Whatcom",
        "state": "WA"
    },
    {
        "code": "53075",
        "county": "Whitman",
        "value": "Whitman",
        "state": "WA"
    },
    {
        "code": "53001",
        "county": "Adams",
        "value": "Adams",
        "state": "WA"
    },
    {
        "code": "55045",
        "county": "Green",
        "value": "Green",
        "state": "WI"
    },
    {
        "code": "55047",
        "county": "Green Lake",
        "value": "Green Lake",
        "state": "WI"
    },
    {
        "code": "55049",
        "county": "Iowa",
        "value": "Iowa",
        "state": "WI"
    },
    {
        "code": "55051",
        "county": "Iron",
        "value": "Iron",
        "state": "WI"
    },
    {
        "code": "55053",
        "county": "Jackson",
        "value": "Jackson",
        "state": "WI"
    },
    {
        "code": "55055",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "WI"
    },
    {
        "code": "55057",
        "county": "Juneau",
        "value": "Juneau",
        "state": "WI"
    },
    {
        "code": "55059",
        "county": "Kenosha",
        "value": "Kenosha",
        "state": "WI"
    },
    {
        "code": "55061",
        "county": "Kewaunee",
        "value": "Kewaunee",
        "state": "WI"
    },
    {
        "code": "55063",
        "county": "La Crosse",
        "value": "La Crosse",
        "state": "WI"
    },
    {
        "code": "55065",
        "county": "Lafayette",
        "value": "Lafayette",
        "state": "WI"
    },
    {
        "code": "55067",
        "county": "Langlade",
        "value": "Langlade",
        "state": "WI"
    },
    {
        "code": "55069",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "WI"
    },
    {
        "code": "55071",
        "county": "Manitowoc",
        "value": "Manitowoc",
        "state": "WI"
    },
    {
        "code": "55073",
        "county": "Marathon",
        "value": "Marathon",
        "state": "WI"
    },
    {
        "code": "55075",
        "county": "Marinette",
        "value": "Marinette",
        "state": "WI"
    },
    {
        "code": "55077",
        "county": "Marquette",
        "value": "Marquette",
        "state": "WI"
    },
    {
        "code": "55078",
        "county": "Menominee",
        "value": "Menominee",
        "state": "WI"
    },
    {
        "code": "55079",
        "county": "Milwaukee",
        "value": "Milwaukee",
        "state": "WI"
    },
    {
        "code": "55081",
        "county": "Monroe",
        "value": "Monroe",
        "state": "WI"
    },
    {
        "code": "55083",
        "county": "Oconto",
        "value": "Oconto",
        "state": "WI"
    },
    {
        "code": "55085",
        "county": "Oneida",
        "value": "Oneida",
        "state": "WI"
    },
    {
        "code": "55087",
        "county": "Outagamie",
        "value": "Outagamie",
        "state": "WI"
    },
    {
        "code": "55089",
        "county": "Ozaukee",
        "value": "Ozaukee",
        "state": "WI"
    },
    {
        "code": "55091",
        "county": "Pepin",
        "value": "Pepin",
        "state": "WI"
    },
    {
        "code": "55093",
        "county": "Pierce",
        "value": "Pierce",
        "state": "WI"
    },
    {
        "code": "55095",
        "county": "Polk",
        "value": "Polk",
        "state": "WI"
    },
    {
        "code": "55097",
        "county": "Portage",
        "value": "Portage",
        "state": "WI"
    },
    {
        "code": "55099",
        "county": "Price",
        "value": "Price",
        "state": "WI"
    },
    {
        "code": "55101",
        "county": "Racine",
        "value": "Racine",
        "state": "WI"
    },
    {
        "code": "55103",
        "county": "Richland",
        "value": "Richland",
        "state": "WI"
    },
    {
        "code": "55105",
        "county": "Rock",
        "value": "Rock",
        "state": "WI"
    },
    {
        "code": "55107",
        "county": "Rusk",
        "value": "Rusk",
        "state": "WI"
    },
    {
        "code": "55109",
        "county": "St. Croix",
        "value": "St. Croix",
        "state": "WI"
    },
    {
        "code": "55111",
        "county": "Sauk",
        "value": "Sauk",
        "state": "WI"
    },
    {
        "code": "55113",
        "county": "Sawyer",
        "value": "Sawyer",
        "state": "WI"
    },
    {
        "code": "55115",
        "county": "Shawano",
        "value": "Shawano",
        "state": "WI"
    },
    {
        "code": "55117",
        "county": "Sheboygan",
        "value": "Sheboygan",
        "state": "WI"
    },
    {
        "code": "55119",
        "county": "Taylor",
        "value": "Taylor",
        "state": "WI"
    },
    {
        "code": "55121",
        "county": "Trempealeau",
        "value": "Trempealeau",
        "state": "WI"
    },
    {
        "code": "55123",
        "county": "Vernon",
        "value": "Vernon",
        "state": "WI"
    },
    {
        "code": "55125",
        "county": "Vilas",
        "value": "Vilas",
        "state": "WI"
    },
    {
        "code": "55127",
        "county": "Walworth",
        "value": "Walworth",
        "state": "WI"
    },
    {
        "code": "55129",
        "county": "Washburn",
        "value": "Washburn",
        "state": "WI"
    },
    {
        "code": "55131",
        "county": "Washington",
        "value": "Washington",
        "state": "WI"
    },
    {
        "code": "55133",
        "county": "Waukesha",
        "value": "Waukesha",
        "state": "WI"
    },
    {
        "code": "55135",
        "county": "Waupaca",
        "value": "Waupaca",
        "state": "WI"
    },
    {
        "code": "55137",
        "county": "Waushara",
        "value": "Waushara",
        "state": "WI"
    },
    {
        "code": "55139",
        "county": "Winnebago",
        "value": "Winnebago",
        "state": "WI"
    },
    {
        "code": "55141",
        "county": "Wood",
        "value": "Wood",
        "state": "WI"
    },
    {
        "code": "55000",
        "county": "Wisconsin",
        "value": "Wisconsin",
        "state": "WI"
    },
    {
        "code": "55001",
        "county": "Adams",
        "value": "Adams",
        "state": "WI"
    },
    {
        "code": "55003",
        "county": "Ashland",
        "value": "Ashland",
        "state": "WI"
    },
    {
        "code": "55005",
        "county": "Barron",
        "value": "Barron",
        "state": "WI"
    },
    {
        "code": "55007",
        "county": "Bayfield",
        "value": "Bayfield",
        "state": "WI"
    },
    {
        "code": "55009",
        "county": "Brown",
        "value": "Brown",
        "state": "WI"
    },
    {
        "code": "55011",
        "county": "Buffalo",
        "value": "Buffalo",
        "state": "WI"
    },
    {
        "code": "55013",
        "county": "Burnett",
        "value": "Burnett",
        "state": "WI"
    },
    {
        "code": "55015",
        "county": "Calumet",
        "value": "Calumet",
        "state": "WI"
    },
    {
        "code": "55017",
        "county": "Chippewa",
        "value": "Chippewa",
        "state": "WI"
    },
    {
        "code": "55019",
        "county": "Clark",
        "value": "Clark",
        "state": "WI"
    },
    {
        "code": "55021",
        "county": "Columbia",
        "value": "Columbia",
        "state": "WI"
    },
    {
        "code": "55023",
        "county": "Crawford",
        "value": "Crawford",
        "state": "WI"
    },
    {
        "code": "55025",
        "county": "Dane",
        "value": "Dane",
        "state": "WI"
    },
    {
        "code": "55027",
        "county": "Dodge",
        "value": "Dodge",
        "state": "WI"
    },
    {
        "code": "55029",
        "county": "Door",
        "value": "Door",
        "state": "WI"
    },
    {
        "code": "55031",
        "county": "Douglas",
        "value": "Douglas",
        "state": "WI"
    },
    {
        "code": "55033",
        "county": "Dunn",
        "value": "Dunn",
        "state": "WI"
    },
    {
        "code": "55035",
        "county": "Eau Claire",
        "value": "Eau Claire",
        "state": "WI"
    },
    {
        "code": "55037",
        "county": "Florence",
        "value": "Florence",
        "state": "WI"
    },
    {
        "code": "55039",
        "county": "Fond du Lac",
        "value": "Fond du Lac",
        "state": "WI"
    },
    {
        "code": "55041",
        "county": "Forest",
        "value": "Forest",
        "state": "WI"
    },
    {
        "code": "55043",
        "county": "Grant",
        "value": "Grant",
        "state": "WI"
    },
    {
        "code": "54047",
        "county": "McDowell",
        "value": "McDowell",
        "state": "WV"
    },
    {
        "code": "54049",
        "county": "Marion",
        "value": "Marion",
        "state": "WV"
    },
    {
        "code": "54051",
        "county": "Marshall",
        "value": "Marshall",
        "state": "WV"
    },
    {
        "code": "54053",
        "county": "Mason",
        "value": "Mason",
        "state": "WV"
    },
    {
        "code": "54055",
        "county": "Mercer",
        "value": "Mercer",
        "state": "WV"
    },
    {
        "code": "54057",
        "county": "Mineral",
        "value": "Mineral",
        "state": "WV"
    },
    {
        "code": "54059",
        "county": "Mingo",
        "value": "Mingo",
        "state": "WV"
    },
    {
        "code": "54061",
        "county": "Monongalia",
        "value": "Monongalia",
        "state": "WV"
    },
    {
        "code": "54063",
        "county": "Monroe",
        "value": "Monroe",
        "state": "WV"
    },
    {
        "code": "54065",
        "county": "Morgan",
        "value": "Morgan",
        "state": "WV"
    },
    {
        "code": "54067",
        "county": "Nicholas",
        "value": "Nicholas",
        "state": "WV"
    },
    {
        "code": "54069",
        "county": "Ohio",
        "value": "Ohio",
        "state": "WV"
    },
    {
        "code": "54071",
        "county": "Pendleton",
        "value": "Pendleton",
        "state": "WV"
    },
    {
        "code": "54073",
        "county": "Pleasants",
        "value": "Pleasants",
        "state": "WV"
    },
    {
        "code": "54075",
        "county": "Pocahontas",
        "value": "Pocahontas",
        "state": "WV"
    },
    {
        "code": "54077",
        "county": "Preston",
        "value": "Preston",
        "state": "WV"
    },
    {
        "code": "54079",
        "county": "Putnam",
        "value": "Putnam",
        "state": "WV"
    },
    {
        "code": "54081",
        "county": "Raleigh",
        "value": "Raleigh",
        "state": "WV"
    },
    {
        "code": "54083",
        "county": "Randolph",
        "value": "Randolph",
        "state": "WV"
    },
    {
        "code": "54085",
        "county": "Ritchie",
        "value": "Ritchie",
        "state": "WV"
    },
    {
        "code": "54087",
        "county": "Roane",
        "value": "Roane",
        "state": "WV"
    },
    {
        "code": "54089",
        "county": "Summers",
        "value": "Summers",
        "state": "WV"
    },
    {
        "code": "54091",
        "county": "Taylor",
        "value": "Taylor",
        "state": "WV"
    },
    {
        "code": "54093",
        "county": "Tucker",
        "value": "Tucker",
        "state": "WV"
    },
    {
        "code": "54095",
        "county": "Tyler",
        "value": "Tyler",
        "state": "WV"
    },
    {
        "code": "54097",
        "county": "Upshur",
        "value": "Upshur",
        "state": "WV"
    },
    {
        "code": "54099",
        "county": "Wayne",
        "value": "Wayne",
        "state": "WV"
    },
    {
        "code": "54101",
        "county": "Webster",
        "value": "Webster",
        "state": "WV"
    },
    {
        "code": "54103",
        "county": "Wetzel",
        "value": "Wetzel",
        "state": "WV"
    },
    {
        "code": "54105",
        "county": "Wirt",
        "value": "Wirt",
        "state": "WV"
    },
    {
        "code": "54107",
        "county": "Wood",
        "value": "Wood",
        "state": "WV"
    },
    {
        "code": "54109",
        "county": "Wyoming",
        "value": "Wyoming",
        "state": "WV"
    },
    {
        "code": "54000",
        "county": "West Virginia",
        "value": "West Virginia",
        "state": "WV"
    },
    {
        "code": "54001",
        "county": "Barbour",
        "value": "Barbour",
        "state": "WV"
    },
    {
        "code": "54003",
        "county": "Berkeley",
        "value": "Berkeley",
        "state": "WV"
    },
    {
        "code": "54005",
        "county": "Boone",
        "value": "Boone",
        "state": "WV"
    },
    {
        "code": "54007",
        "county": "Braxton",
        "value": "Braxton",
        "state": "WV"
    },
    {
        "code": "54009",
        "county": "Brooke",
        "value": "Brooke",
        "state": "WV"
    },
    {
        "code": "54011",
        "county": "Cabell",
        "value": "Cabell",
        "state": "WV"
    },
    {
        "code": "54013",
        "county": "Calhoun",
        "value": "Calhoun",
        "state": "WV"
    },
    {
        "code": "54015",
        "county": "Clay",
        "value": "Clay",
        "state": "WV"
    },
    {
        "code": "54017",
        "county": "Doddridge",
        "value": "Doddridge",
        "state": "WV"
    },
    {
        "code": "54019",
        "county": "Fayette",
        "value": "Fayette",
        "state": "WV"
    },
    {
        "code": "54021",
        "county": "Gilmer",
        "value": "Gilmer",
        "state": "WV"
    },
    {
        "code": "54023",
        "county": "Grant",
        "value": "Grant",
        "state": "WV"
    },
    {
        "code": "54025",
        "county": "Greenbrier",
        "value": "Greenbrier",
        "state": "WV"
    },
    {
        "code": "54027",
        "county": "Hampshire",
        "value": "Hampshire",
        "state": "WV"
    },
    {
        "code": "54029",
        "county": "Hancock",
        "value": "Hancock",
        "state": "WV"
    },
    {
        "code": "54031",
        "county": "Hardy",
        "value": "Hardy",
        "state": "WV"
    },
    {
        "code": "54033",
        "county": "Harrison",
        "value": "Harrison",
        "state": "WV"
    },
    {
        "code": "54035",
        "county": "Jackson",
        "value": "Jackson",
        "state": "WV"
    },
    {
        "code": "54037",
        "county": "Jefferson",
        "value": "Jefferson",
        "state": "WV"
    },
    {
        "code": "54039",
        "county": "Kanawha",
        "value": "Kanawha",
        "state": "WV"
    },
    {
        "code": "54041",
        "county": "Lewis",
        "value": "Lewis",
        "state": "WV"
    },
    {
        "code": "54043",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "WV"
    },
    {
        "code": "54045",
        "county": "Logan",
        "value": "Logan",
        "state": "WV"
    },
    {
        "code": "56045",
        "county": "Weston",
        "value": "Weston",
        "state": "WY"
    },
    {
        "code": "56043",
        "county": "Washakie",
        "value": "Washakie",
        "state": "WY"
    },
    {
        "code": "56001",
        "county": "Albany",
        "value": "Albany",
        "state": "WY"
    },
    {
        "code": "56003",
        "county": "Big Horn",
        "value": "Big Horn",
        "state": "WY"
    },
    {
        "code": "56005",
        "county": "Campbell",
        "value": "Campbell",
        "state": "WY"
    },
    {
        "code": "56007",
        "county": "Carbon",
        "value": "Carbon",
        "state": "WY"
    },
    {
        "code": "56009",
        "county": "Converse",
        "value": "Converse",
        "state": "WY"
    },
    {
        "code": "56011",
        "county": "Crook",
        "value": "Crook",
        "state": "WY"
    },
    {
        "code": "56013",
        "county": "Fremont",
        "value": "Fremont",
        "state": "WY"
    },
    {
        "code": "56015",
        "county": "Goshen",
        "value": "Goshen",
        "state": "WY"
    },
    {
        "code": "56017",
        "county": "Hot Springs",
        "value": "Hot Springs",
        "state": "WY"
    },
    {
        "code": "56019",
        "county": "Johnson",
        "value": "Johnson",
        "state": "WY"
    },
    {
        "code": "56021",
        "county": "Laramie",
        "value": "Laramie",
        "state": "WY"
    },
    {
        "code": "56023",
        "county": "Lincoln",
        "value": "Lincoln",
        "state": "WY"
    },
    {
        "code": "56025",
        "county": "Natrona",
        "value": "Natrona",
        "state": "WY"
    },
    {
        "code": "56027",
        "county": "Niobrara",
        "value": "Niobrara",
        "state": "WY"
    },
    {
        "code": "56029",
        "county": "Park",
        "value": "Park",
        "state": "WY"
    },
    {
        "code": "56031",
        "county": "Platte",
        "value": "Platte",
        "state": "WY"
    },
    {
        "code": "56033",
        "county": "Sheridan",
        "value": "Sheridan",
        "state": "WY"
    },
    {
        "code": "56035",
        "county": "Sublette",
        "value": "Sublette",
        "state": "WY"
    },
    {
        "code": "56037",
        "county": "Sweetwater",
        "value": "Sweetwater",
        "state": "WY"
    },
    {
        "code": "56039",
        "county": "Teton",
        "value": "Teton",
        "state": "WY"
    },
    {
        "code": "56041",
        "county": "Uinta",
        "value": "Uinta",
        "state": "WY"
    },
    {
        "code": "56000",
        "county": "Wyoming",
        "value": "Wyoming",
        "state": "WY"
    }
]