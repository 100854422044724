import React, { Component } from "react";
import PropTypes from "prop-types";

import * as BenefitProps from "../../../constants/benefit";
import {
  date,
  money,
  convertStringDateToDate,
} from "../../utilities/formatters";
import * as TextProps from "../../../constants/text";

/*
  Benefits ListItem contents
  --------------------------

  # Purpose:
  To display a received benefit in a list

  # Props:
  [BenefitProps.AMOUNT]: Required, numerical cash amount
  [BenefitProps.DATE]: Required, Date object indicating when the benefit was received

  # Example:
   <BenefitContents {...props} />
 */

class BenefitContents extends Component {
  static propTypes = {
    [BenefitProps.AMOUNT]: PropTypes.number.isRequired,
    [BenefitProps.DATE]: PropTypes.instanceOf(Date).isRequired,
  };

  render() {
    return (
      <div className="pure-g">
        <div className="text--bold pure-u-2-5 pure-u-sm-1-2">
          {money(this.props[BenefitProps.AMOUNT])}
        </div>
        <div className="text--right pure-u-3-5 pure-u-sm-1-2">
          received on{" "}
          {date(convertStringDateToDate(this.props[BenefitProps.DATE]), {
            humanize: TextProps.VALUE_TRUE,
          })}
        </div>
      </div>
    );
  }
}

export default BenefitContents;
