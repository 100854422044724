import React from "react";
import { withMsal } from "@azure/msal-react";
import _ from "lodash";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { formSummaryObj } from "../../../utils/components/utilities/utils";
import SectionSummary from "./SectionSummary";

const SummaryContainer = (props) => {
  const { sections } = props.apply;
  let mineData = sections.filter(item => item.id === "auth-rep");
  let authRep = mineData.length ? mineData[0] : {};
  const applySections = _.cloneDeep(sections).filter((item) => {
    if(!item.hiddenFromSummary && item.subsections && item.subsections.length){
      item.subsections = item.subsections.filter((subItem) => !subItem.hiddenFromSummary)
    }
    return !item.hiddenFromSummary
  });
  const summaryData = formSummaryObj(applySections.slice(1));

  return (
    <div>
       {summaryData.map((section) => {
            return <SectionSummary section={section}  key={section.sectionId} applicationType="apply" authorizedRepresentatives={authRep.authorizedRepresentatives} ebtAuthRepFlg={authRep.authorizedRepresentatives && authRep.authorizedRepresentatives["ebtAuthRepFlg"]} snapAuthRepFlg={authRep.authorizedRepresentatives && authRep.authorizedRepresentatives["snapAuthRepFlg"]}/>;
        })
        }
     
    </div>
  );
};

export default withMsal(connect(mapStateToProps)(SummaryContainer));
