import * as SharedProps from "../../utils/constants/shared";
import * as providerConst from "../provider/providerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const providerNavSections = [
  {
    [SharedProps.ID]: "snapOutreach",
    [SharedProps.TITLE]: "SNAP Gap Outreach",
    [SharedProps.ICON]: "handshake",
    [SharedProps.TYPE]: "mh-provider-nav",
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]: "My Account",
    [SharedProps.ICON]: "my-info",
  },
];

/**
 * added new const for NOPSI roles doesn't have myClients tab
 */
export const providerNOPSIRoleNavSections = [
  {
    [SharedProps.ID]: "snapOutreach",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).searchAllClients,
    [SharedProps.ICON]: "home",
  },
  {
    [SharedProps.ID]: "memberSearch",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).memberSearch,
    [SharedProps.ICON]: "home",
    [SharedProps.TYPE]: "mh-provider-nav",
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]: "My Account",
    [SharedProps.ICON]: "my-info",
  },
];

export default providerNavSections;
