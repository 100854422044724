//Combine all Reducers of Redux
import { combineReducers } from "redux";
import { homeReducer } from "./home/homeReducer";
import { chatReducer } from "./chat/chatReducer";
import { screeningReducer } from "./screening/screeningReducer";
import { applyReducer } from "./applySnap/applySnapReducer";
import { applyTafdcReducer } from "./applyTafdc/applyTafdcReducer";
import { applyEaedcReducer } from "./applyEaedc/applyEaedcReducer";
import { greeterScreeningReducer } from "./greeterScreening/greeterScreeningReducer";
import { recertReducer } from "./recertification/recertificationReducer";
import { interimReportReducer } from "./recertInterimReport/recertInterimReportReducer";
import { covidRecertReducer } from "./recertCovid/recertCovidReducer";
import { providerReducer } from "./provider/providerReducer";
import { consumerReducer } from "./consumer/consumerReducer";
import { oauthTokenReducer } from "./consumer/oauthTokenReducer";
import { routerReducer } from "react-router-redux";
import { languageReducer } from "../redux/language/languageReducer";
import { selfDeclareReducer } from "./selfdeclare/selfDeclareReducer";
import { activatePEBTReducer } from "./applyPebt/applyPebtReducer";
import { mhproviderReducer } from "./mhProvider/mhProviderReducer";
import { concernsAppealsReducer } from "./concernsAppeals/concernsAppealsReducer";
import { applyDsnapReducer } from "./applyDsnap/applyDsnapReducer";

const snapReducers = combineReducers({
  home: homeReducer,
  chat: chatReducer,
  apply: applyReducer,
  applyTafdc: applyTafdcReducer,
  applyEaedc: applyEaedcReducer,
  greeterScreening: greeterScreeningReducer,
  recert: recertReducer,
  interimReport: interimReportReducer,
  covidRecert: covidRecertReducer,
  provider: providerReducer,
  consumer: consumerReducer,
  router: routerReducer,
  language: languageReducer,
  screening: screeningReducer,
  selfDeclare: selfDeclareReducer,
  oauthToken: oauthTokenReducer,
  activatePEBT: activatePEBTReducer,
  mhProvider: mhproviderReducer,
  concernsAppeals: concernsAppealsReducer,
  applyDsnap: applyDsnapReducer
});

export default snapReducers;

export const mapStateToProps = (state) => {
  return {
    home: state.home,
    chatToken: state.chat.token,
    language: state.language.constantStrings,
    selectedLanguage: state.language.selectedLanguage,
    screening: state.screening,
    provider: state.provider,
    apply: state.apply,
    applyTafdc: state.applyTafdc,
    applyEaedc: state.applyEaedc,
    greeterScreening: state.greeterScreening,
    recert: state.recert,
    interimReport: state.interimReport,
    covidRecert: state.covidRecert,
    consumer: state.consumer,
    selfDeclare: state.selfDeclare,
    oauthToken: state.oauthToken,
    activatePEBT: state.activatePEBT,
    mhProvider: state.mhProvider,
    chatMessages:state.chat.chatMessages,
    concernsAppeals: state.concernsAppeals,
    applyDsnap: state.applyDsnap
  };
};
