import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DTAInput from "../../../utils/components/dtaInput";
import Radio from "../../../utils/components/radio";
import Checkbox from "../../../utils/components/checkbox";
import { ZIPCODE } from "../../../utils/constants/zipCode";
import { mhProviderSNAPGAP } from "../../provider/providerText";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as formatters from "../../../utils/components/utilities/formatters";
import {
  getMhproviderClientInfo,
  mhproviderClientInfo,
  updateSnapOutReachClientInfo,
  memberSearchNamesClientInfo,
  cancelClientCaseInfo,
  refreshMhproviderClientInfo,
  mhproviderGetCaseInfo,
  memberSearchZipCodeClientInfo,
} from "../../../redux/mhProvider/mhProviderAction";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as types from "../../../utils/constants/types";
import * as TextProps from "../../../utils/constants/text";

export class MemberSearch extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      memberSearchPage: TextProps.VALUE_TRUE,
      isSnapForm: TextProps.VALUE_FALSE,
      disableSubmit: TextProps.VALUE_FALSE,
      outReachStatus: TextProps.VALUE_FALSE,
      memberSearchErrorMsg: TextProps.VALUE_FALSE,
      zipcodeSearchErrorMsg: TextProps.VALUE_FALSE,
      isMemberCaseInfoForm: TextProps.VALUE_FALSE,
      noSelectedOption: TextProps.VALUE_FALSE,
      checkbox: TextProps.VALUE_FALSE,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mhProvider !== undefined) {
      if (
        nextProps.mhProvider.memberInfo !== undefined &&
        nextProps.mhProvider.memberInfo !== null
      ) {
        if (
          nextProps.mhProvider.radioToggle !== undefined &&
          nextProps.mhProvider.radioToggle === "Name"
        ) {
          this.setState({
            nameToggleView: TextProps.VALUE_TRUE,
            zipcodeToggleView: TextProps.VALUE_FALSE,
          });
          if (
            nextProps.mhProvider.memberSearchSuccess !== undefined &&
            nextProps.mhProvider.memberSearchSuccess === TextProps.VALUE_TRUE &&
            nextProps.mhProvider.memberErrorMsg === TextProps.VALUE_FALSE
          ) {
            this.setState({
              memberSearchPage: TextProps.VALUE_FALSE,
              memberSearchErrorMsg: TextProps.VALUE_FALSE,
            });
          } else if (
            nextProps.mhProvider.memberSearchSuccess !== undefined &&
            nextProps.mhProvider.memberSearchSuccess ===
              TextProps.VALUE_FALSE &&
            nextProps.mhProvider.memberErrorMsg === TextProps.VALUE_TRUE
          ) {
            this.setState({
              memberSearchPage: TextProps.VALUE_TRUE,
              memberSearchErrorMsg: TextProps.VALUE_TRUE,
            });
          }
        }
      }
      if (
        nextProps.mhProvider.radioToggle !== undefined &&
        nextProps.mhProvider.radioToggle === "Name"
      ) {
        if (
          nextProps.mhProvider.memberSearchSuccess !== undefined &&
          nextProps.mhProvider.memberSearchSuccess === TextProps.VALUE_FALSE &&
          nextProps.mhProvider.memberErrorMsg === TextProps.VALUE_TRUE
        ) {
          this.setState({
            memberSearchPage: TextProps.VALUE_TRUE,
            memberSearchErrorMsg: TextProps.VALUE_TRUE,
          });
        }
      }
      if (
        nextProps.mhProvider.radioToggle !== undefined &&
        nextProps.mhProvider.radioToggle === "Zip"
      ) {
        this.setState({
          zipcodeToggleView: TextProps.VALUE_TRUE,
          nameToggleView: TextProps.VALUE_FALSE,
        });
        if (nextProps.mhProvider.zipcodeErrorMsg === TextProps.VALUE_TRUE) {
          this.setState({ zipcodeSearchErrorMsg: TextProps.VALUE_TRUE });
        }
      }
    }
    var firstName = sessionStorage.getItem("textfirstnamevalue");
    var lastName = sessionStorage.getItem("textlastnamevalue");
    var zipcode = sessionStorage.getItem("textzipcodevalue");
    var checkbox = sessionStorage.getItem("checkboxvalue");
    this.setState({
      firstName: firstName,
      lastName: lastName,
      zipcode: zipcode,
      checkbox: checkbox,
    });
  }

  memberCaseInfoButton = (mdmId, outreachId) => {
    if (
      mdmId !== undefined &&
      mdmId !== null &&
      outreachId !== undefined &&
      outreachId !== null
    ) {
      this.setState({ memberSearchPage: TextProps.VALUE_FALSE });
      this.props.mhproviderGetCaseInfo(mdmId, outreachId);
    }
  };

  memberSearchSelectOption = (event) => {
    event.preventDefault();
    this.setState({ noSelectedOption: TextProps.VALUE_TRUE });
  };

  updateAbove60Toggle = (event) => {
    if (
      event.length > 0 &&
      (this.state.ageAbove60 != TextProps.VALUE_Y ||
        this.state.ageAbove60 != undefined)
    ) {
      this.setState({ ageAbove60: "Y", checkbox: event });
      sessionStorage.setItem("checkboxvalue", event);
    } else {
      this.setState({ ageAbove60: TextProps.VALUE_FALSE, checkbox: event });
      sessionStorage.setItem("checkboxvalue", event);
    }
  };

  updateMemberSearchToggle = (value) => {
    const { firstName, lastName, zipcode } = this.state;
    if (value === "First Name / Last Name") {
      if (firstName != undefined || firstName === "" || firstName != null) {
        sessionStorage.setItem("textfirstnamevalue", firstName);
      }
      if (lastName != undefined || lastName === "" || lastName != null) {
        sessionStorage.setItem("textlastnamevalue", lastName);
      }
      this.setState({
        zipcodeToggleView: TextProps.VALUE_FALSE,
        nameToggleView: TextProps.VALUE_TRUE,
        noSelectedOption: TextProps.VALUE_FALSE,
        zipCodeErrorMsg: TextProps.VALUE_FALSE,
        zipCodeNotMAErrorMsg: TextProps.VALUE_FALSE,
        zipcodeSearchErrorMsg: TextProps.VALUE_FALSE,
      });
    } else if (value === "Zip Code") {
      if (zipcode !== undefined || zipcode === "" || zipcode != null) {
        sessionStorage.setItem("textzipcodevalue", zipcode);
      }
      this.setState({
        zipcodeToggleView: TextProps.VALUE_TRUE,
        nameToggleView: TextProps.VALUE_FALSE,
        noSelectedOption: TextProps.VALUE_FALSE,
        firstNameErrorMsg: TextProps.VALUE_FALSE,
        lastNameErrorMsg: TextProps.VALUE_FALSE,
        memberSearchErrorMsg: TextProps.VALUE_FALSE,
      });
    }
  };

  memberSearchZipCodeButton = (event) => {
    event.preventDefault();
    const { zipcode, ageAbove60, noSelectedOption } = this.state;
    if (noSelectedOption === TextProps.VALUE_FALSE) {
      if (zipcode === undefined || zipcode === "" || zipcode === null) {
        this.setState({
          zipCodeErrorMsg: TextProps.VALUE_TRUE,
          firstNameErrorMsg: TextProps.VALUE_FALSE,
          lastNameErrorMsg: TextProps.VALUE_FALSE,
          zipCodeNotMAErrorMsg: TextProps.VALUE_FALSE,
          zipcodeSearchErrorMsg: TextProps.VALUE_FALSE,
        });
      } else if (zipcode.length < 5 && zipcode != null) {
        this.setState({
          zipCodeErrorMsg: TextProps.VALUE_TRUE,
          firstNameErrorMsg: TextProps.VALUE_FALSE,
          lastNameErrorMsg: TextProps.VALUE_FALSE,
          zipCodeNotMAErrorMsg: TextProps.VALUE_FALSE,
          zipcodeSearchErrorMsg: TextProps.VALUE_FALSE,
        });
      } else if (zipcode != null) {
        const zipCodeMACheck = ZIPCODE.find((obj) => obj.ZIPCODE === zipcode);
        if (!zipCodeMACheck) {
          this.setState({
            zipCodeNotMAErrorMsg: TextProps.VALUE_TRUE,
            firstNameErrorMsg: TextProps.VALUE_FALSE,
            lastNameErrorMsg: TextProps.VALUE_FALSE,
            zipCodeErrorMsg: TextProps.VALUE_FALSE,
            zipcodeSearchErrorMsg: TextProps.VALUE_FALSE,
          });
        } else {
          this.setState({
            zipCodeErrorMsg: TextProps.VALUE_FALSE,
            zipCodeNotMAErrorMsg: TextProps.VALUE_FALSE,
          });
          if (ageAbove60) {
            this.props.memberSearchZipCodeClientInfo(zipcode, ageAbove60);
            sessionStorage.setItem("textzipcodevalue", zipcode);
          } else if (!ageAbove60) {
            this.props.memberSearchZipCodeClientInfo(zipcode);
            sessionStorage.setItem("textzipcodevalue", zipcode);
          }
        }
      }
    }
  };

  memberSearchNamesButton = (event) => {
    event.preventDefault();
    const { firstName, lastName, noSelectedOption } = this.state;
    if (noSelectedOption === TextProps.VALUE_FALSE) {
      if (
        (firstName === undefined || firstName === "" || firstName === null) &&
        (lastName === undefined || lastName === "" || lastName === null)
      ) {
        this.setState({
          firstNameErrorMsg: TextProps.VALUE_TRUE,
          lastNameErrorMsg: TextProps.VALUE_TRUE,
        });
      } else if (
        firstName === undefined ||
        firstName === "" ||
        firstName === null
      ) {
        this.setState({
          lastNameErrorMsg: TextProps.VALUE_FALSE,
          firstNameErrorMsg: TextProps.VALUE_TRUE,
        });
      } else if (
        lastName === undefined ||
        lastName === "" ||
        lastName === null
      ) {
        this.setState({
          firstNameErrorMsg: TextProps.VALUE_FALSE,
          lastNameErrorMsg: TextProps.VALUE_TRUE,
        });
      } else if (firstName !== undefined && lastName !== undefined) {
        this.setState({
          firstNameErrorMsg: TextProps.VALUE_FALSE,
          lastNameErrorMsg: TextProps.VALUE_FALSE,
        });
        this.props.memberSearchNamesClientInfo(firstName, lastName);
        sessionStorage.setItem("textfirstnamevalue", firstName);
        sessionStorage.setItem("textlastnamevalue", lastName);
      }
    }
  };

  radioToggleComponent = (
    firstName,
    lastName,
    zipcode,
    checkbox,
    noSelectedOption,
    memberSearchErrorMsg,
    zipcodeSearchErrorMsg
  ) => {
    let providerRadioToggleZip = "";
    let firstNameErrMsg = null;
    let lastNameErrMsg = null;
    let memberSearchErrMsg = "";
    if (this.props.mhProvider.radioToggle === "Zip") {
      providerRadioToggleZip = mhProviderSNAPGAP(types.ENGLISH)
        .mhProviderMemberSearch[1];
    }
    if (this.state.firstNameErrorMsg) {
      firstNameErrMsg = (
        <span id="firstNameError" className="firstname dta-form__error-message">
          {mhProviderSNAPGAP(types.ENGLISH).firstNameErrorMsg}
        </span>
      );
    }
    if (this.state.lastNameErrorMsg) {
      lastNameErrMsg = (
        <span id="lastNameError" className="lastname dta-form__error-message">
          {mhProviderSNAPGAP(types.ENGLISH).lastNameErrorMsg}
        </span>
      );
    }
    if (memberSearchErrorMsg) {
      memberSearchErrMsg = (
        <div className="pure-u-5-5 max-width max-width--three-quarters pad-bottom-none snap-oureact-content-ie">
          <div className="dta-form__error-message member-search-error-message">
            {mhProviderSNAPGAP(types.ENGLISH).noRecords}
          </div>
        </div>
      );
    }

    let zipcodeErrorMsgVal = null;
    if (this.state.zipCodeErrorMsg) {
      zipcodeErrorMsgVal = (
        <span id="zipCodeError" className="zipCode dta-form__error-message">
          {mhProviderSNAPGAP(types.ENGLISH).zipCode5DigitErrorMsg}
        </span>
      );
    } else if (this.state.zipCodeNotMAErrorMsg) {
      zipcodeErrorMsgVal = (
        <span id="zipCodeError" className="zipCode dta-form__error-message">
          {mhProviderSNAPGAP(types.ENGLISH).zipCodeNotMAErrorMsg}
        </span>
      );
    }

    let zipcodeSearchErrMsgVal = "";
    if (zipcodeSearchErrorMsg) {
      zipcodeSearchErrMsgVal = (
        <div className="pure-u-5-5 max-width max-width--three-quarters pad-bottom-none snap-oureact-content-ie">
          <div className="dta-form__error-message member-search-error-message">
            {mhProviderSNAPGAP(types.ENGLISH).noRecords}
          </div>
        </div>
      );
    }
    let toggleViewVal;
    if (this.state.zipcodeToggleView) {
      toggleViewVal = this.memberSearchZipCodeButton;
    } else if (this.state.nameToggleView) {
      toggleViewVal = this.memberSearchNamesButton;
    } else {
      toggleViewVal = this.memberSearchSelectOption;
    }

    return (
      <form className="max-width pure-u-1-1">
        <div
          style={{ paddingBottom: "1.5%" }}
          className="pure-u-xl-20-24 pure-u-lg-20-24 pure-u-md-20-24 pure-u-sm-20-24 mh-provider-input-container"
        >
          <h1 className="dta-form__label mh-provider-search">
            {mhProviderSNAPGAP(types.ENGLISH).accountSearchType}
          </h1>
          {noSelectedOption ? (
            <div className="pure-u-5-5 max-width snap-oureact-content-ie">
              <div className="dta-form__error-message">
                {mhProviderSNAPGAP(types.ENGLISH).selectOption}
              </div>
            </div>
          ) : null}
          <Radio
            name="memberSearch-radio-toggle"
            className="dta-form__option-list"
            optionClass="dta-form__option dta-form__option--width-2-of-2"
            options={mhProviderSNAPGAP(types.ENGLISH).mhProviderMemberSearch}
            required={TextProps.VALUE_TRUE}
            error={this.state.noSelectedOption}
            value={
              this.props.mhProvider.radioToggle === "Name"
                ? mhProviderSNAPGAP(types.ENGLISH).mhProviderMemberSearch[0]
                : providerRadioToggleZip
            }
            onChange={(value) => this.updateMemberSearchToggle(value)}
          />
        </div>

        {this.state.nameToggleView ? (
          <div>
            <div className="pure-u-xl-10-24 pure-u-lg-10-24 pure-u-md-10-24 pure-u-sm-10-24 mh-provider-input-container">
              <label className="dta-form__label">
                {mhProviderSNAPGAP(types.ENGLISH).firstName}
              </label>
              <div className="pure-u-5-5">
                {firstNameErrMsg}
                <div className={"dta-custom-input__item"}>
                  <DTAInput
                    id={"firstname"}
                    name={"firstname"}
                    className="dta-form__control"
                    type="text"
                    required={TextProps.VALUE_TRUE}
                    error={this.state.firstNameErrorMsg}
                    onChange={(val) => this.setState({ firstName: val })}
                    value={firstName}
                    ariaLabel={"firstname"}
                  />
                </div>
              </div>
            </div>

            <div className="pure-u-xl-10-24 pure-u-lg-10-24 pure-u-md-10-24 pure-u-sm-10-24 mh-provider-input-container mhprovider-last-name-remove-padding">
              <label className="dta-form__label">
                {mhProviderSNAPGAP(types.ENGLISH).lastName}
              </label>
              <div className="pure-u-5-5">
                {lastNameErrMsg}
                <div className={"dta-custom-input__item"}>
                  <DTAInput
                    id={"lastname"}
                    name={"lastname"}
                    className="dta-form__control"
                    type="text"
                    required={TextProps.VALUE_TRUE}
                    error={this.state.lastNameErrorMsg}
                    onChange={(val) => this.setState({ lastName: val })}
                    value={lastName}
                    ariaLabel={"lastname"}
                  />
                </div>
              </div>
            </div>

            {memberSearchErrMsg}
          </div>
        ) : this.state.zipcodeToggleView ? (
          <div>
            <div className="pure-u-xl-10-24 pure-u-lg-10-24 pure-u-md-10-24 pure-u-sm-10-24 mh-provider-input-container mhprovider-zipcode-field">
              <label className="dta-form__label">
                {mhProviderSNAPGAP(types.ENGLISH).zipCode}
              </label>
              <div className={"dta-custom-input__item"}>
                <DTAInput
                  id={"zipcode"}
                  name={"zipcode"}
                  type="number"
                  blocks={[5]}
                  numericOnly={TextProps.VALUE_TRUE}
                  className="dta-form__control"
                  required={TextProps.VALUE_TRUE}
                  error={
                    this.state.zipCodeErrorMsg ||
                    this.state.zipCodeNotMAErrorMsg
                  }
                  onChange={(val) => this.setState({ zipcode: val })}
                  value={zipcode}
                  ariaLabel={"zipcode"}
                />
              </div>
              {zipcodeErrorMsgVal}
            </div>

            <div className="pure-u-xl-6-24 pure-u-lg-6-24 pure-u-md-6-24 pure-u-sm-10-24 mhprovider-overage60-field mhprovider-last-name-remove-padding">
              <Checkbox
                name="memberSearch-age-60-toggle"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-2-of-2"
                options={mhProviderSNAPGAP(types.ENGLISH).agedOver60}
                onChange={this.updateAbove60Toggle}
                value={
                  checkbox === "Aged over 60"
                    ? mhProviderSNAPGAP(types.ENGLISH).agedOver60
                    : ""
                }
              />
            </div>
            {zipcodeSearchErrMsgVal}
          </div>
        ) : null}

        <div
          style={{ display: "contents" }}
          className="dta-button dta-button--primary dta-button--large"
        >
          <div
            style={{ justifyContent: "center" }}
            className="dta-modal__footer--inline-buttons"
          >
            <button
              className="dta-button dta-button--large dta-button--primary mhprovider-search-button-remove-padding"
              onClick={toggleViewVal}
            >
              {mhProviderSNAPGAP(types.ENGLISH).search}
            </button>
          </div>
        </div>
        <div></div>
      </form>
    );
  };

  render() {
    const {
      memberSearchPage,
      nameToggleView,
      noSelectedOption,
      memberSearchErrorMsg,
      zipcodeSearchErrorMsg,
    } = this.state;

    var { memberSearchSuccess, memberInfo, isLoading } = this.props.mhProvider;
    var memberInfoList = memberInfo;
    if (Object.keys(this.props.mhProvider).length === 0) {
      const reloadSnapForm = JSON.parse(sessionStorage.getItem("isSnapForm"));
      const mhClientInfo = JSON.parse(sessionStorage.getItem("mhClientInfo"));
      if (reloadSnapForm && mhClientInfo !== null) {
        this.props.getMhproviderClientInfo();
        this.props.refreshMhproviderClientInfo();
      }
    }
    if (isLoading) return helperFunction.isLoading(isLoading);
    const firstName = sessionStorage.getItem("textfirstnamevalue");
    const lastName = sessionStorage.getItem("textlastnamevalue");
    const zipcode = sessionStorage.getItem("textzipcodevalue");
    const checkbox = sessionStorage.getItem("checkboxvalue");

    return memberSearchPage && !memberSearchSuccess ? (
      <div className="max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content-ie">
        <div
          style={{ paddingBottom: "4%" }}
          className="pure-g mh-case-info-container"
        >
          {this.radioToggleComponent(
            firstName,
            lastName,
            zipcode,
            checkbox,
            noSelectedOption,
            memberSearchErrorMsg,
            zipcodeSearchErrorMsg
          )}
        </div>
      </div>
    ) : memberSearchSuccess ? (
      <div className="mh-provider-container">
        <div className="max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content-ie">
          <div
            style={{ paddingBottom: "4%" }}
            className="pure-g mh-case-info-container"
          >
            {this.radioToggleComponent(
              firstName,
              lastName,
              zipcode,
              checkbox,
              noSelectedOption,
              memberSearchErrorMsg,
              zipcodeSearchErrorMsg
            )}

            <div
              className={
                memberSearchSuccess &&
                nameToggleView === TextProps.VALUE_TRUE &&
                (firstName || lastName)
                  ? "pure-u-5-5 max-width max-width--three-quarters pad-all--double pad-bottom-none snap-oureact-content-ie"
                  : "mh-provider-display-none"
              }
            >
              <h3 className="text--gray">
                {mhProviderSNAPGAP(types.ENGLISH).searchResults}
              </h3>
              <ul className="dta-list">
                <li className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 remove-header-mobile-view">
                  <label className="text--gray">
                    {mhProviderSNAPGAP(types.ENGLISH).mhDetails[0]}
                  </label>
                </li>
                <li className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 remove-header-mobile-view">
                  <label className="text--gray">
                    {mhProviderSNAPGAP(types.ENGLISH).mhDetails[1]}
                  </label>
                </li>
                <li className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 remove-header-mobile-view">
                  <label className="text--gray">
                    {mhProviderSNAPGAP(types.ENGLISH).mhDetails[2]}
                  </label>
                </li>
                <li className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 remove-header-mobile-view">
                  <label className="text--gray">
                    {mhProviderSNAPGAP(types.ENGLISH).mhDetails[3]}
                  </label>
                </li>
                <li className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 remove-header-mobile-view">
                  <label className="text--gray">
                    {mhProviderSNAPGAP(types.ENGLISH).mhDetails[4]}
                  </label>
                </li>
              </ul>
              <br />
              <ul className="dta-list">
                {memberInfoList.map((member) => {
                  return (
                    <li
                      className="dta-link--container"
                      title="Click here to update the outcome for this member and apply for SNAP."
                      onClick={() =>
                        this.memberCaseInfoButton(
                          member.mdmId,
                          member.outreachId
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <tr className="pure-u-24-24 pure-u-lg-5-5 pure-u-md-5-5 pure-u-sm-24-24">
                        <td className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 search-result-table-column">
                          {member.firstName} {member.lastName}
                        </td>
                        <td className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 search-result-table-column">
                          {member.residentialAddress.line1} <br />{" "}
                          {member.residentialAddress.line2}{" "}
                          {member.residentialAddress.city} <br />{" "}
                          {member.residentialAddress.state}{" "}
                          {member.residentialAddress.zip}
                        </td>
                        <td className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 search-result-table-column">
                          {formatters.phone(member.cellPhone)}
                        </td>
                        <td className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 search-result-table-column">
                          {formatters.phone(member.dayPhone)}
                        </td>
                        <td className="pure-u-2-3 pure-u-lg-1-5 pure-u-md-1-5 pure-u-sm-1-5 search-result-table-column">
                          {formatters.phone(member.nightPhone)}
                        </td>
                      </tr>
                      <br />
                      <br />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}
//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMhproviderClientInfo: getMhproviderClientInfo,
      mhproviderClientInfo: mhproviderClientInfo,
      updateSnapOutReachClientInfo: updateSnapOutReachClientInfo,
      memberSearchNamesClientInfo: memberSearchNamesClientInfo,
      memberSearchZipCodeClientInfo: memberSearchZipCodeClientInfo,
      cancelClientCaseInfo: cancelClientCaseInfo,
      refreshMhproviderClientInfo: refreshMhproviderClientInfo,
      mhproviderGetCaseInfo: mhproviderGetCaseInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberSearch);
