import PropTypes from "prop-types";
import { interimReportPageLabels } from "./recertCovidText";
import * as languageConstants from "../../utils/constants/constants";
import { ZIPCODE } from "../../utils/constants/zipCode";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];

      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
            return conditionQuestion;
          });
          return condition;
        });
      }
    } else {
      if (
        (AnswerObj[activeId] === undefined && question.Answer === undefined) ||
        question.Answer === undefined
      ) {
        question.Answer = "";
        question.targetValue = "";
      }
    }
    return question;
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "about-me") {
        buildApplyAboutMeValidations(inputSections);
      } else if (activeId === "add-contact-info") {
        buildApplyContactInfoValidations(inputSections);
      } else if (activeId === "emergency-snap") {
        buildApplyEmergencyValidations(inputSections);
      } else if (activeId === "more-info") {
        buildApplyMoreInfoValidations(inputSections);
      } else if (activeId === "Other information") {
        buildApplyOptionalValidations(inputSections);
      } else if (activeId === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId.substring(0, 16) === "household-member") {
        buildApplyHouseholdMembersValidations(inputSections);
      } else if (activeId.substring(0, 15) === "medical-address") {
        buildApplyMediacalAddressValidations(inputSections);
      } else if (activeId.substring(0, 27) === "kids-adult-multiple-address") {
        buildApplyKidsAdultAddressValidations(inputSections);
      }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSections = sections.questions;
    for (let answerNotElected of dataSections) {
      if (
        answerNotElected.type === "date" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputSSN" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputPhone" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "inputText" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "emailAddress"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "") &&
        answerNotElected.name === "address"
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (answerNotElected.isRequired === TextProps.VALUE_TRUE) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        dataID.substring(0, 27) === "kids-adult-multiple-address" ||
        dataID.substring(0, 15) === "medical-address"
      ) {
        if (
          answerNotElected.type === "address" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
        if (
          answerNotElected.type === "units" &&
          answerNotElected.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElected.Answer);
        }
      } else if (dataID === "add-contact-info") {
        var answerNotElectedMailingAddress =
          dataSections[2].conditionals[0].conditionalQuestions[0];
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      } else if (
        answerNotElected.type === "address" &&
        (answerNotElected.error === TextProps.VALUE_TRUE ||
          answerNotElected.Answer !== "")
      ) {
        totalReqFields.push(answerNotElected.Answer);
      } else if (
        (answerNotElected.type === "inputText" ||
          answerNotElected.type === "inputAlphaText") &&
        answerNotElected.error === TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(answerNotElected.Answer);
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnwsers = [];
  if (sections !== undefined) {
    const dataSections = sections.questions;
    for (var answerElected of dataSections) {
      if (
        (answerElected.type === "date" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputSSN" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputPhone" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE) ||
        (answerElected.type === "inputText" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "emailAddress") ||
        (answerElected.type === "address" &&
          answerElected.Answer !== "" &&
          answerElected.error === TextProps.VALUE_FALSE &&
          answerElected.name === "address") ||
        (answerElected.Answer !== "" &&
          answerElected.isRequired === TextProps.VALUE_TRUE &&
          answerElected.type !== "address" &&
          answerElected.error === TextProps.VALUE_FALSE)
      ) {
        totalAnwsers.push(answerElected.Answer);
      }
    }
  }
  // checking required fields and total touched fileds
  return sections !== undefined && totalReqFields.length !== totalAnwsers.length
    ? TextProps.VALUE_FALSE
    : TextProps.VALUE_TRUE;
}

function buildApplyHouseholdMembersValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let section of dataSections) {
      if (section.type === "select") {
        if (
          section.Answer.length < 1 &&
          section.Answer !== null &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (
        section.type === "inputText" ||
        section.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexNumber = /\d+/;
        var rexSpace = /^(?=.*\S).+$/;
        section.Answer = section.Answer.trim();
        if (
          section.Answer.length < 1 &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          section.Answer.length >= 1 &&
          (rexNumber.test(section.Answer) === TextProps.VALUE_TRUE ||
            rexSpace.test(section.Answer) === TextProps.VALUE_FALSE)
        ) {
          section.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.type === "date") {
        if (PropTypes.date_error === 1) {
          section.error = TextProps.VALUE_TRUE;
        } else if (
          (section.Answer === "" || section.Answer === null) &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else if (
          (section.Answer !== "" || section.Answer !== null) &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          var inputDate = section.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            section.error = TextProps.VALUE_TRUE;
          } else {
            section.error = TextProps.VALUE_FALSE;
          }
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.type === "inputSSN") {
        if (
          section.Answer.length < 1 &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else if (
          section.Answer.length > 0 &&
          section.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (regSSN.test(section.Answer) === TextProps.VALUE_FALSE) {
            section.error = TextProps.VALUE_TRUE;
          } else {
            section.error = TextProps.VALUE_FALSE;
          }
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      } else if (section.type === "radio") {
        if (
          section.Answer === "" &&
          section.isRequired === TextProps.VALUE_TRUE
        ) {
          section.error = TextProps.VALUE_TRUE;
        } else {
          section.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyOptionalValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var value of dataSections) {
      if (value.type === "radio" || value.type === "checkbox") {
        if (value.Answer === "" && value.isRequired === TextProps.VALUE_TRUE) {
          value.error = TextProps.VALUE_TRUE;
        } else {
          value.error = TextProps.VALUE_FALSE;
          value.errorMessage = "";
        }
      }
    }
  }
}

function buildApplyAboutMeValidations(dataSections) {
  if (dataSections !== undefined) {
    for (let sectionValue of dataSections) {
      if (sectionValue.type === "select") {
        if (
          sectionValue.Answer.length < 1 &&
          sectionValue.Answer !== null &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (
        sectionValue.type === "inputText" ||
        sectionValue.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          sectionValue.Answer.length >= 0 &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(sectionValue.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(sectionValue.Answer) === TextProps.VALUE_FALSE
          ) {
            sectionValue.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          sectionValue.Answer.length > 0 &&
          sectionValue.isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(sectionValue.Answer) === TextProps.VALUE_TRUE ||
            rexAnySpace.test(sectionValue.Answer) === TextProps.VALUE_FALSE
          ) {
            sectionValue.error = TextProps.VALUE_TRUE;
            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          sectionValue.name === "lastName" &&
          sectionValue.Answer.length > 20
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
          sectionValue.errorMessage = sectionValue.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (
          sectionValue.name !== "lastName" &&
          sectionValue.Answer.length > 15
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
          sectionValue.errorMessage = sectionValue.errorMessageLength;
          errorFlag = TextProps.VALUE_TRUE;
        }
        if (!errorFlag) {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionValue.type === "date") {
        if (PropTypes.date_error === 1) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionValue.Answer === "" || sectionValue.Answer === null) &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          (sectionValue.Answer !== "" || sectionValue.Answer === null) &&
          sectionValue.isRequired === TextProps.VALUE_FALSE
        ) {
          var inputDate = sectionValue.Answer;
          var currentDate = new Date();
          if (inputDate.getTime() > currentDate.getTime()) {
            sectionValue.error = TextProps.VALUE_TRUE;
          } else {
            sectionValue.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      } else if (sectionValue.type === "inputSSN") {
        if (
          sectionValue.Answer.length < 1 &&
          sectionValue.isRequired === TextProps.VALUE_TRUE
        ) {
          sectionValue.error = TextProps.VALUE_TRUE;
        } else if (
          sectionValue.Answer.length > 0 &&
          sectionValue.isRequired === TextProps.VALUE_FALSE
        ) {
          var regSSN = /^([0-9]){3}([ -]?([0-9]){2})([ -]?([0-9]){4})$/;
          if (regSSN.test(sectionValue.Answer) === TextProps.VALUE_FALSE) {
            sectionValue.error = TextProps.VALUE_TRUE;
          } else {
            sectionValue.error = TextProps.VALUE_FALSE;
          }
        } else {
          sectionValue.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyContactInfoValidations(dataSections) {
  const resZipCode = dataSections.find((obj) => obj.name === "address").Answer
    .zipCode;
  const masResZipCode = ZIPCODE.find((obj) => obj.ZIPCODE === resZipCode);

  if (dataSections !== undefined) {
    if (
      dataSections[0].Answer ===
        interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[0] &&
      dataSections[1].Answer.street === "" &&
      dataSections[1].Answer.city === "" &&
      dataSections[1].Answer.zipCode === ""
    ) {
      dataSections[2].Answer = TextProps.VALUE_STR_FALSE;
      dataSections[2].value = TextProps.VALUE_STR_FALSE;
      dataSections[2].targetValue = TextProps.VALUE_STR_FALSE;
    }
    for (var k = 0; k < dataSections.length; k++) {
      if (dataSections[k].type === "radio" && k === 0) {
        if (
          dataSections[k].Answer ===
          interimReportPageLabels(language, languageConstants.aboutMe)
            .optionYesNo[0]
        )
          dataSections[1].isRequired = TextProps.VALUE_FALSE;
        else dataSections[1].isRequired = TextProps.VALUE_TRUE;
      }
      if (
        k === 1 &&
        (dataSections[k].Answer.street !== "" ||
          dataSections[k].Answer.zipCode !== "" ||
          dataSections[k].Answer.city !== "")
      ) {
        dataSections[k].isRequired = TextProps.VALUE_TRUE;
      }
      // Validation for mailing Address
      if (dataSections[k].type === "radio" && k === 2) {
        var mailingAddressObj = {};
        dataSections[k].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        if (dataSections[k].targetValue === TextProps.VALUE_STR_FALSE) {
          mailingAddressObj =
            dataSections[k].conditionals[0].conditionalQuestions[0];
          const mailingZipCode = mailingAddressObj.targetValue.zipCode;
          const masMailingZipCode = ZIPCODE.find(
            (obj) => obj.ZIPCODE === mailingZipCode
          );

          if (
            mailingAddressObj.Answer === "" ||
            mailingAddressObj.Answer.street ===
              "" /* && mailingAddressObj.isRequired === TextProps.VALUE_TRUE */
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.addressErrmsg;
          } else if (
            mailingAddressObj.Answer.zipCode === "" ||
            mailingAddressObj.Answer.zipCode.length < 5
          ) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.zipCodeErrmsg;
          } else if (!masMailingZipCode) {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.masZipCodeErrmsg;
          } else if (mailingAddressObj.Answer.city === "") {
            mailingAddressObj.error = TextProps.VALUE_TRUE;
            mailingAddressObj.errorMessage = mailingAddressObj.citiErrmsg;
          } else {
            mailingAddressObj.error = TextProps.VALUE_FALSE;
          }
        }
      }

      if (
        (dataSections[k].type === "radio" && k !== 2) ||
        (dataSections[k].type === "text" &&
          dataSections[k].name === "emailAddress" &&
          dataSections[k].Answer !== undefined)
      ) {
        if (
          dataSections[k].Answer === "" &&
          dataSections[k].isRequired === TextProps.VALUE_TRUE
        ) {
          dataSections[k].error = TextProps.VALUE_TRUE;
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputText") {
        if (
          (dataSections[k].Answer.length > 0 ||
            dataSections[k].Answer !== "") &&
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          var emailRegEx =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          if (
            emailRegEx.test(dataSections[k].Answer) === TextProps.VALUE_FALSE
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (dataSections[k].type === "inputPhone") {
        if (
          (dataSections[k].Answer.length > 0 ||
            dataSections[k].Answer !== "") &&
          !isNaN(dataSections[k].Answer) &&
          dataSections[k].isRequired === TextProps.VALUE_FALSE
        ) {
          var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          if (phoneno.test(dataSections[k].Answer) === TextProps.VALUE_FALSE) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].Answer = "";
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSections[k].error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSections[k].type === "address" &&
        dataSections[k].name === "address"
      ) {
        if (
          !(
            dataSections[0].Answer ===
              interimReportPageLabels(language, languageConstants.aboutMe)
                .houseLessOptions[0] &&
            dataSections[2].Answer === TextProps.VALUE_STR_FALSE
          )
        ) {
          if (
            dataSections[k].Answer === "" ||
            dataSections[k].Answer.street === ""
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].addressErrmsg;
          } else if (
            dataSections[k].Answer.zipCode === "" ||
            dataSections[k].Answer.zipCode.length < 5
          ) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].zipCodeErrmsg;
          } else if (!masResZipCode) {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].masZipCodeErrmsg;
          } else if (dataSections[k].Answer.city === "") {
            dataSections[k].error = TextProps.VALUE_TRUE;
            dataSections[k].errorMessage = dataSections[k].citiErrmsg;
          } else {
            dataSections[k].error = TextProps.VALUE_FALSE;
          }
        }
      }
    }
  }
}

function buildApplyMediacalAddressValidations(dataSections) {
  for (let section of dataSections) {
    if (section.type === "address") {
      let errorFlag = TextProps.VALUE_FALSE;
      if (
        section.Answer.zipCode === "" ||
        section.Answer.city === "" ||
        section.Answer.state === "" ||
        section.Answer.street === ""
      ) {
        section.error = TextProps.VALUE_TRUE;
        section.errorMessage = section.addressErrmsg;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (
        section.Answer.zipCode !== "" &&
        section.isRequired === TextProps.VALUE_FALSE &&
        section.Answer.zipCode !== undefined
      ) {
        if (section.Answer.zipCode.length < 5) {
          section.error = TextProps.VALUE_TRUE;
          section.Answer = "";
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        section.Answer.city !== "" &&
        section.isRequired === TextProps.VALUE_FALSE &&
        section.Answer.city !== undefined
      ) {
        var rex = /^[A-Za-z' ]+$/;
        if (rex.test(section.Answer.city) === TextProps.VALUE_FALSE) {
          section.error = TextProps.VALUE_TRUE;
          section.Answer = "";
          section.errorMessage = section.citiErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        section.error = TextProps.VALUE_FALSE;
      }
    }
    if (section.Answer.amount !== "" && section.Answer.amount !== undefined) {
      let errorFlag = TextProps.VALUE_FALSE;
      var numRegEx = /^[0-9]+$/;
      if (numRegEx.test(section.Answer.amount) === TextProps.VALUE_FALSE) {
        section.error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (section.Answer.amount.length > 6) {
        section.error = TextProps.VALUE_TRUE;
        section.errorMessage = section.errorMessageLength;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        section.error = TextProps.VALUE_FALSE;
      }
    }
  }
}

function buildApplyKidsAdultAddressValidations(dataSections) {
  for (let sectionValue of dataSections) {
    if (sectionValue.type === "address") {
      let errorFlag = TextProps.VALUE_FALSE;
      sectionValue.error = TextProps.VALUE_TRUE;
      if (
        sectionValue.Answer.zipCode === "" ||
        sectionValue.Answer.city === "" ||
        sectionValue.Answer.state === "" ||
        sectionValue.Answer.street === ""
      ) {
        sectionValue.error = TextProps.VALUE_TRUE;
        sectionValue.errorMessage = sectionValue.addressErrmsg;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (
        sectionValue.Answer.zipCode !== "" &&
        (sectionValue.isRequired === TextProps.VALUE_FALSE ||
          sectionValue.Answer.zipCode !== undefined)
      ) {
        if (sectionValue.Answer.zipCode.length < 5) {
          sectionValue.error = TextProps.VALUE_TRUE;
          sectionValue.Answer = "";
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (
        sectionValue.Answer.city !== "" &&
        (sectionValue.isRequired === TextProps.VALUE_FALSE ||
          sectionValue.Answer.city !== undefined)
      ) {
        var alphaRex = /^[A-Za-z' ]+$/;
        if (alphaRex.test(sectionValue.Answer.city) === TextProps.VALUE_FALSE) {
          sectionValue.error = TextProps.VALUE_TRUE;
          sectionValue.errorMessage = sectionValue.citiErrmsg;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        sectionValue.error = TextProps.VALUE_FALSE;
      }
    }
    if (
      sectionValue.Answer.amount !== "" &&
      sectionValue.Answer.amount !== undefined
    ) {
      var rex = /^[0-9]+$/;
      let errorFlag = TextProps.VALUE_FALSE;
      if (rex.test(sectionValue.Answer.amount) === TextProps.VALUE_FALSE) {
        sectionValue.error = TextProps.VALUE_TRUE;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (sectionValue.Answer.amount.length > 6) {
        sectionValue.error = TextProps.VALUE_TRUE;
        sectionValue.errorMessage = sectionValue.errorMessageLength;
        errorFlag = TextProps.VALUE_TRUE;
      }
      if (!errorFlag) {
        sectionValue.error = TextProps.VALUE_FALSE;
      }
    }
  }
}
function buildApplyEmergencyValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var value of dataSections) {
      if (value.type === "radio") {
        if (value.Answer === "" && value.isRequired === TextProps.VALUE_TRUE) {
          value.error = TextProps.VALUE_TRUE;
        } else {
          value.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

function buildApplyMoreInfoValidations(dataSections) {
  if (dataSections !== undefined) {
    for (var value of dataSections) {
      if (value.type === "radio") {
        if (value.Answer === "" && value.isRequired === TextProps.VALUE_TRUE) {
          value.error = TextProps.VALUE_TRUE;
        } else {
          value.error = TextProps.VALUE_FALSE;
        }
      } else if (value.type === "select") {
        if (
          value.Answer.length < 1 &&
          value.Answer !== null &&
          value.isRequired === TextProps.VALUE_TRUE
        ) {
          value.error = TextProps.VALUE_TRUE;
        } else {
          value.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
}

export function filterQuestionsBySelection(
  activePageAnswers,
  nextPageQuestions
) {
  let selectedQuestions = [];
  for (var answer of activePageAnswers) {
    for (var question of nextPageQuestions) {
      if (answer === question.valueToMatch) {
        selectedQuestions.push(question);
      }
    }
  }
  return selectedQuestions;
}
