import React, { Component } from "react";
import { interimReportPageLabels } from "../../../pages/recertInterimReport/recertInterimReportText";
import { recertificationPageLabels } from "../../../pages/recertification/recertificationText";
import InterimApplicationEN from "../../../utils/resources/pdfs/Form-1911-SixMonthReportForm-English.pdf";
import InterimApplicationES from "../../../utils/resources/pdfs/Form-1911-SixMonthReportForm-Spanish.pdf";
import RecertApplicationEN from "../../../utils/resources/pdfs/SNAP-ApplicationEN.pdf";
import RecertApplicationES from "../../../utils/resources/pdfs/SNAP-ApplicationES.pdf";

import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import * as languageConstants from "../../constants/constants";
import { getAudit } from "../../constants/audit";
import * as helperFunction from "../helperFunctions/helperFunctions";
import { INLINE_HELP } from "../shared";
import { Fragment } from "react/cjs/react.production.min";
import { ENGLISH } from "../../constants/types";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if(language === null || language === languageConstants.langOptSel) {
    language = ENGLISH
  }

class BeforeYouStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tafdcBanner:[],
      headOfHouseHold:[]
    }
  }
  handleListClickAction = (event) => {
    event.preventDefault();
    var documentId = this.props.startFormData.responseObject.documentID;
    var agencyId = this.props.startFormData.responseObject.agencyID;
    var auditData = getAudit();
    this.props.onNotificationItemClick(documentId, auditData, agencyId);
  };

  componentDidMount() {
    if(this.props.consumer && this.props.consumer.consumerData && this.props.consumer.consumerData.benefitInfo && this.props.consumer.consumerData.benefitInfo.length){
      var benefitInfo = this.props.consumer.consumerData.benefitInfo;
      var tafdcBanner = benefitInfo.filter(item => item.alertMessages.RECERT_TYPE === "REEVAL" )
      if(tafdcBanner.length){
        this.setState({tafdcBanner:tafdcBanner});
        var headOfHouseHold = tafdcBanner[0].householdMembers.filter(item => item.granteeFlag === true );
        this.setState({headOfHouseHold:headOfHouseHold});
      }
    }
  }

  openModal = () => {
    let url = InterimApplicationEN;
    switch (language) {
      case "en-EN":
        url = InterimApplicationEN;
        break;
      case "es-ES":
        url = InterimApplicationES;
        break;
      // case "pt":
      //   url = rights_pt_url;
      //   break;
      // case "vi":
      //   url = rights_vi_url;
      //   break;
      // case "zh":
      //   url = rights_zh_url;
        // break;
    }
    window.open(url, "_blank");
  };

  openRecertModal = () => {
    let url = RecertApplicationEN;
    switch (language) {
      case "en-EN":
        url = RecertApplicationEN;
        break;
      case "es-ES":
        url = RecertApplicationES;
        break;
      // case "pt":
      //   url = rights_pt_url;
      //   break;
      // case "vi":
      //   url = rights_vi_url;
      //   break;
      // case "zh":
      //   url = rights_zh_url;
        // break;
    }
    window.open(url, "_blank");
  };

  openthehereModal = () => {
    window.open("/consumer/documents","_blank")
  }
  
  render() {
    const interimReport =
    (this.props.startFormData.sections[0].title === "SNAP Six-Month Report" || this.props.startFormData.sections[0].title === "Informe de seis meses de SNAP");
    let interimDueDate = sessionStorage.getItem("interimDueDate");
    let interimOverdueMonth = sessionStorage.getItem("interimOverdueMonth");
    return (
      <div className="pad-all--double">
        {interimReport ? (
          <div>
          <div>
            {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).fieldLabel1
            }
            {console.log("interimOverdueMonth",interimOverdueMonth)}
              {interimOverdueMonth == 6 ?                 <span>
                  <strong>
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fieldLabelCompleteForm
                    }
                  </strong>
                  <strong> {formatters.convertStringDateToMonthOnly(interimDueDate)} </strong>
                  <strong>{interimReportPageLabels(language, languageConstants.beforeYouStart).fieldLabelCompleteFormsecond}</strong>
                  <strong> {formatters.convertStringDateToMonthOnly(interimDueDate)}.</strong>
                </span> :
               <strong>
               {
                 interimReportPageLabels(
                   language,
                   languageConstants.beforeYouStart
                 ).fieldOverDueLabel
               }
             </strong>
              }

            <br />
            </div>
            <div style= {{paddingTop :"20px"}}>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).fieldLabel2
            }
            <br />
            </div> 
            <div style= {{paddingTop :"20px"}}>
            <strong>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).fieldLabel3
            }
            </strong>
            <br />
            </div>                     
            </div>     
        
        ) : (
          <p className="interim-recert-heading">
            { this.props.recertType === "AESAP" ? recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).aesapfieldLabel : this.props.recertType === "REEVAL" ? "" :
              recertificationPageLabels(
                language,
                null,
                languageConstants.beforeYouStart
              ).fieldLabel1
            }
            <br />
          </p>
        )}
        {this.props.recertType === "AESAP" && 
          (<ul>
            <li>
              {recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).aesaplist1}
            </li>
            <li>
            {recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).aesaplist2}
          </li>
          <li>
              {recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).aesaplist3}
            </li>
          </ul>)}
          { this.props.recertType === "AESAP" && 
            <p>
              <span>
              {recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).aesapfinaltext}
              </span>
              <a href={"https://"+recertificationPageLabels(
                                              language,
                                              null,
                                              languageConstants.beforeYouStart
                                            ).aesaplink} 
                target="_blank">
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).aesaplink
                }
              </a>
              <span>.</span>
          </p>
          }
          {this.props.recertType !== "AESAP" && this.props.recertType !== "REEVAL" && <ul>
          {interimReport ? (
            <Fragment>
              <li
              dangerouslySetInnerHTML={{
                __html: interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[0],
              }}
            />
            <br/>
            </Fragment>
          ) : (
            <Fragment>
              <li
              dangerouslySetInnerHTML={{
                __html: recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).recertMethods[0],
              }}
            />
            <br/>
            </Fragment>
          )}
          <li>
            {interimReport ? <span>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).interimbeforeStart
              }
            </span> : <span>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).recertMethods[1].before 
            }
              </span>}
            {interimReport ? 
            <a onClick={this.openModal}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[1].hereLinkText
              }
            </a>
            :
            <a onClick={this.openRecertModal}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[1].hereLinkText
              }
            </a>
            }
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).recertMethods[1].after
            }
          </li>
          <li style={{ listStyle: "none" }}>
            <ul style={{ marginTop: "1.4rem" }}>
              {interimReport && (
              <li style={{ listStyle: "disc"}}>
                <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).interimBeforePicture
                  }
                </span>
                <a href="" onClick={() => this.openthehereModal()}>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).recertMethods[1].hereLinkText
                  }
                </a>
              </li>
              )}
              <li style={{ listStyle: "disc" }}>
                {interimReport ?
                  <span>
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).interimFaxText[0]}
                  </span> :
                  <span>
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).recertMethods[2].before}
                  </span>
                }
            <a onClick={() => window.location.href = "/consumer/documents"}>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).recertMethods[2].hereLinkText
                }
            </a>
                {interimReport ? <span>
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.beforeYouStart
                    ).interimFaxText[1]}
                </span> : ""}
              </li>
            </ul>
          </li>
        </ul>}
        {this.props.recertType === "REEVAL" && (
          <React.Fragment>
            <p style={{marginTop: "-72px"}}>
              {
                 recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffirstlabel1 + (this.state.tafdcBanner.length && formatters.convertStringDateToMonth(this.state.tafdcBanner[0].alertMessages.NEXT_RECERT_DUEDATE)) + ". "
              }
              <b>
                {(this.state.headOfHouseHold.length && (this.state.headOfHouseHold[0].name.first +" "+this.state.headOfHouseHold[0].name.last)) +
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffirstlabel2
                }
                  </b>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffirstlabel3
                }
            </p>
            {/* <br/> */}
            <p className="">
              <b>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.beforeYouStart
                ).tanffirsthead2
              }
              </b>
            </p>
            <ul>
              <li>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfRedeterminationText
                }
              </li>
              <li>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfRedeterminationDesc
                }
                {" "}
                 <a onClick={() => this.openthehereModal()}>
                <span>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfhere
                }
                </span>
                </a>
                {". "}
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfRedeterminationDesc2
                }
              </li>
            </ul>
            <ul style={{paddingLeft:"70px"}}>
            <li>
              {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfmailtext
                }
              </li>
              <li>
              {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffaxmailtext
                }
                <br />
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfclicktext
                }{" "}
                <a onClick={() => window.location.href = "/consumer/documents"}>
                <span>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfhere
                }
                </span>
                </a>
                {" "}{
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanfAddresstext
                }
              </li>
              </ul>
              <p>
                <b>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffirstcontinue1
                }
                </b>
                {
                  recertificationPageLabels(
                    language,
                    null,
                    languageConstants.beforeYouStart
                  ).tanffirstcontinue2
                }
              </p>
              </React.Fragment>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, )(BeforeYouStart);
// export default BeforeYouStart;
