import axios from "axios";
import { getAudit } from "../constants/audit";
import * as routeProps from "../constants/routesConstants";
import {
  msalConfig,
  forgotPasswordRequest,
  postLogoutRedirectUri,
} from "config/appConfig";
import * as TextProps from "../constants/text";

var config = require("../constants/appConfigConstants");

const actionTypes = {
  onUserLoggedOut: "USER_LOGGED_OUT",
  serviceFailedAction: "FORGOT_FROM_SERVICE_FAIL",
};

//Common Fetch Support Functions
export function onLogOut(authData, loginFlagAfterLogout) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL + "/security/logout", TigerOptions)
      .then(function (response) {
        sessionStorage.clear();
        if (loginFlagAfterLogout === "Y") {
          window.location.assign(routeProps.HOME_PATH);
        } else {
          window.location.assign(routeProps.HOME_PATH);
        }
        dispatch(onLogOutSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function onLogOutSuccess(data) {
  return {
    type: actionTypes.onUserLoggedOut,
    payload: data,
  };
}

//Common Action for Service Fail
function serviceFailedAction(response) {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

export function onAzureLogOut(msalInstance) {
  return async (dispatch, getState) => {
    window.sessionStorage.setItem("is_Logout", TextProps.VALUE_TRUE);
    if (window.sessionStorage.getItem("home_account")) {
      const currentAccount = await msalInstance.getAccountByHomeId(
        JSON.parse(window.sessionStorage.getItem("home_account")).homeAccountId
      );
      await msalInstance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: `${postLogoutRedirectUri.redirectUri}/home`,
        authority: msalConfig.auth.authority,
      });
    } else {
      await msalInstance.logoutRedirect({
        postLogoutRedirectUri: `${postLogoutRedirectUri.redirectUri}/home`,
        authority: msalConfig.auth.authority,
      });
    }
    await dispatch(onLogOutSuccess({ code: "200" }));
  };
}

// export function onAzureManageAccount(msalInstance) {
//   return (dispatch) =>{
//    msalInstance.loginPopup(editProfile)
//    .then((res)=>{
//      // do something after profile edit
//      console.log('Profile edited successfully', res);
//    })
//    .catch((err)=>{
//      console.log('Error in edit profile');
//    })
//   }
//  }
export function onAzureResetPassword(msalInstance) {
  return (dispatch) => {
    msalInstance
      .loginRedirect(forgotPasswordRequest)
      .then(() => {
        // do something after password change
        const currentAccount = msalInstance.getAccountByHomeId(
          JSON.parse(window.sessionStorage.getItem("home_account"))
            .homeAccountId
        );
        msalInstance.logout({
          account: currentAccount,
          postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
          authority: msalConfig.auth.authority,
        });
      })
      .catch(() => {
        console.log("Error in Changing password");
      });
  };
}


export function showBellIcon(notificationsList) {
  let isShowBellIcon = "Y";

  if (notificationsList && notificationsList.length > 0) {
    notificationsList.some(item => {
      if (item.readFlg === "N") {
       isShowBellIcon = "N";
      }
   
    })
  }
  return isShowBellIcon;
  
}