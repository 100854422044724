import constants from "../../utils/constants/appConfigConstants";
import * as routeProps from "../../utils/constants/routesConstants";
import { postLogoutRedirectUri, msalConfig } from "config/appConfig";

export function idleTimer(msalInstance) {
  var t;
  t = setTimeout(idle, constants.idleTimeout);

  function idle() {
    const homeAccount = JSON.parse(
      window.sessionStorage.getItem("home_account")
    );
    if (homeAccount) {
      const currentAccount = msalInstance.getAccountByHomeId(
        homeAccount.homeAccountId
      );
      msalInstance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: postLogoutRedirectUri.redirectUri,
        authority: msalConfig.auth.authority,
      });
    } else if (window.location.pathname !== "/") {
      let sessionExpiredArialModal = document.getElementsByClassName(
        "session-expired-arial-modal"
      );
      sessionExpiredArialModal[0].click();
    }
    // sessionStorage.clear();
    // window.location.href = "/";
  }

  function resetTimer() {
    clearTimeout(t);
    var u = window.location.href;
    if (u.endsWith(routeProps, routeProps.MHPROVIDER_SNAPOUTREACH_PATH)) {
      t = setTimeout(idle, 1800000);
    } else {
      t = setTimeout(idle, constants.idleTimeout);
      // t = setTimeout(idle, 1200); //test with 0.2min
    }
  }

  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.onclick = resetTimer;
  window.onscroll = resetTimer;
  window.onkeypress = resetTimer;
}
