import React from "react";
import ReactLoading from "react-loading";

const Loading = () => (
  <span>
    <div className="loading"></div>
    <div className="reactloading">
      <ReactLoading type="cylon" color="#112e51" width="100px" height="50px" />
    </div>
  </span>
);

export default Loading;
