import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import ProviderApp from "./components/searchAllClients";

/**
 * provider main page with roting navigation
 */
class ProviderContainer extends Component {
  render() {
    return (
      <Router basename="provider">
        <ProviderApp />
      </Router>
    );
  }
}

export default ProviderContainer;
