import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as formatters from "../../utils/components/utilities/formatters";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerSearchRequested: "CONSUMER_SEARCH_REQUESTED",
  getConsumerSearchRejected: "CONSUMER_SEARCH_REJECTED",
  getConsumerSearchFulfilled: "CONSUMER_SEARCH_FULFILLED",
  getConsumerSearchfailed: "CONSUMER_SEARCH_FAILED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
};

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function onConsumerSearch(AnswerObject) {
  console.log(AnswerObject);
  const audit = getAudit();
  const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));

  let answerObjectValue;
  console.log('hey AnswerObject from provider', AnswerObject)
  if (AnswerObject.question === "web") {
    answerObjectValue = AnswerObject.webAppNumber;
  } else if (AnswerObject.question === "dtaid") {
    answerObjectValue = AnswerObject.dtaId;
  } else if (AnswerObject.question === "ebt") {
    answerObjectValue = AnswerObject.ebtNumber;
  } else if (AnswerObject.question === "ssn") {
    answerObjectValue = AnswerObject.ssnNumber;
  } else if (AnswerObject.question === "applicationdate") {
    answerObjectValue = AnswerObject.applicationdateValue;
  } else if (AnswerObject.question === "clientemail") {
    answerObjectValue = AnswerObject.clientemail;
  } else if (AnswerObject.question === 'tanfweb') {
    answerObjectValue = AnswerObject.tanfweb;
  } else if (AnswerObject.question === 'casenumber') {
    answerObjectValue = AnswerObject.casenumber;
  } else if(AnswerObject.question === "UnLinkFlg"){
    answerObjectValue = AnswerObject.unLinkFlg;
  }else { 
    answerObjectValue = 0;
  }

  const Headers = {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      audit: JSON.stringify(audit),
      Authorization: oauthToken.token_type + " " + oauthToken.access_token,
    },
    mode: "no-cors",
    "Access-Control-Allow-Origin": config.allowed_domain,
  };
  let data = {
    type: AnswerObject.question,
    value: answerObjectValue,
    unLinkFlg: AnswerObject.unLinkFlg // Assuming unLinkFlg is a property of AnswerObject
  };
  sessionStorage.setItem("selectedConsumer", "");
  sessionStorage.setItem("selectedConsumerEmail", "");
  if (AnswerObject.question === "clientname") {
    data = {
      type: AnswerObject.question.toLowerCase(),
      firstname: AnswerObject.clientFirstName,
      lastname: AnswerObject.clientLastName,
    }
  }
  return (dispatch) => {
    axios
      .post(config.TigerContextURL + "/searchclients/", data, Headers)
      .then(checkStatus)
      .then(function (consumerData) {
        consumerData.data.filterType = AnswerObject.question;
        consumerData.data.ebtNumber = AnswerObject.ebtNumber;
        consumerData.data.unLinkFlg = AnswerObject.unLinkFlg;
        sessionStorage.setItem(
          "onSearchConsumer",
          JSON.stringify(consumerData.data)
        );
        dispatch(getConsumerSearchFulfilledAction(consumerData.data));
      })
      .catch(function (errorMessage) {
        dispatch(getConsumerSearchRejectedAction(errorMessage));
      });
  };
}

//Action Dispatch

function getConsumerSearchRejectedAction(errorMessage) {
  return {
    type: actionTypes.getConsumerSearchfailed,
    payload: errorMessage,
  };
}

function getConsumerSearchFulfilledAction(consumerHomeConstants) {
  return {
    type: actionTypes.getConsumerSearchFulfilled,
    payload: consumerHomeConstants,
  };
}

export function saveRequestAccountAccessUserInfo(WEBAPPID, CLINETID, PsiObj) {
  return (dispatch) => {
    var startDateFormat = formatters.convertStringDateToWithNewFormat(
      PsiObj.startDate
    );
    var endDateFormat = formatters.convertStringDateToWithNewFormat(
      PsiObj.endDate
    );
    var userInfoDataObj = JSON.parse(sessionStorage.getItem("userInfo"));
    if (CLINETID !== null || WEBAPPID !== null) {
      var user = {
        accountID: CLINETID !== undefined && CLINETID !== null ? CLINETID : 0,
        agencyID: CLINETID !== undefined && CLINETID !== null ? CLINETID : 0,
        email: userInfoDataObj.email,
        endDate: endDateFormat,
        orgID: "0",
        startDate: startDateFormat,
        vgLogID: "0",
        vgOrgID: "0",
        vgOrgName: "vgOrgName",
        vgStatusCode: "ACTIVE",
        vgUserID: userInfoDataObj.email,
        webAppID: WEBAPPID,
        applicationSourceForPSIS: PsiObj.webApplicationArray,
      };
    }
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        authToken: "authToken",
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .post(
        config.TigerContextURL + "/requestclientaccountaccess",
        user,
        TigerOptions
      )
      .then(function (data) {
        console.log(
          "requestclientaccountaccess success" + JSON.stringify(data)
        );
        // dispatch is not required because of no need render to UI
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

// if service failure to getting data
function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
