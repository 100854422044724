export const ID = "id";
export const TYPE = "type";
export const USER = "user";
export const MULTI_USER = "multiUserDetails";
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const SUFFIX = "suffix";
export const DOB = "dob";
export const SSN_LAST_FOUR = "ssnLastFour";
export const PHONE = "phone";
export const TITLE = "title";
export const ICON = "icon";
export const FIELDS = "fields";
export const EDITING_LABEL = "editingLabel";
export const AGENCY_ID = "agencyId";
export const ACCOUNT_ID = "accountId";
export const NUM_OF_NOTIFICATIONS = "numOfNotifications";
export const alertMessages = "alertMessages";
export const SNAP_FLAG = "SNAP";
export const SNAP_Date = "date";
export const TAFDC_Date = "date";
export const EAEDC_Date = "date";
export const CASH_FLAG = "CASH";
export const CASH_Date = "date";
export const CLIENT_ID = "clientId";
export const ENABLE_ROLE = "enableRole";
export const SSN = "ssn";
export const STATE = "state";
export const STATUS = "status";
export const LASTLOGIN = "lastLogin";
export const EMAIL = "email";
export const LASTPWDCHG = "lastPwdChange";
export const WEBAPPID = "webAppId";
export const PSI_FLAG = "psiFlag";
export const CASENUMBER = "caseNumber";

export const FIRST = "first";
export const LAST = "last";
export const ACCOUNT_ID_NEW = "accountID";
export const ACCOUNT_ID_TIGER_NEW = "accountId";
export const CLIENT_ID_NEW = "clientID";
export const PERSON_NAME = "personName";
export const WEBAPPID_NEW = "webAppID";
export const DOB_NEW = "dob";
export const BENEFITINFO_ID = "id";
export const DATEOFBIRTH = "dateOfBirth";
export const AGENCY_ID_NEW = "agencyID";
export const TYPECODE = "typeCode";
export const FILTERTYPE = "filterType";
export const EBTNUMBER = "ebtNumber";
