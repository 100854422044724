import _ from "lodash";
import { MONTHSHORT } from "../../utils/constants/controls";
import { isNone } from "../../utils/components/utilities/controls";
import { houseHoldMultilanguageLabels } from "./applySnapText";
import * as OptionsProps from "../../utils/constants/options";
import * as TextProps from "../../utils/constants/text";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

export function expenseBinding(sections, serverJson, ExpenseSectionTemplate) {
  sections[4].completed = TextProps.VALUE_FALSE;
  let HouseHoldSection = sections[2];
  let houseHoldMembers = HouseHoldSection.subsections;
  let HouseHoldMembersOptions =
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options;
  sections[4].subsections[2].questions[0].options = HouseHoldMembersOptions;
  sections[4].subsections[0].questions = housingAndUtilityCostBinding(
    sections[4],
    sections[4].subsections[0].questions,
    serverJson
  );
  sections = mapExpenseOptions(sections, serverJson);
  sections[4].subsections[1].questions = childAndAdultCareBinding(
    sections[4],
    sections[4].subsections[1].questions,
    serverJson
  );
  sections[4].subsections[2].questions = childSupportCostSectionBinding(
    sections[4],
    sections[4].subsections[2].questions,
    serverJson
  );
  sections[4].subsections[3].questions = childMedicalCostSectionBinding(
    sections,
    sections[4].subsections[3].questions,
    serverJson
  );
  return sections[4];
}

export function housingAndUtilityCostBinding(
  expenseSection,
  housingAndUtilityCost,
  serverJson
) {
  let housingAnswer = [],
    shelterAnswer = [];
  let housingItems = ["payHeat", "payAC", "payACFee", "elgas", "hasPhone"];
  let shelterExpenseItems = ["RENT", "MTGPRN", "PRPTAX", "HOMEIN", "CONFEE"];

  for (let item of housingItems) {
    if (serverJson.application[item] === "Y") {
      housingAnswer.push(item);
    }
  }
  bindBothTargetAnswer(housingAndUtilityCost[0], housingAnswer);

  for (let shellterItem of serverJson.application.shelterExpenses) {
    if (
      shelterExpenseItems.indexOf(shellterItem.householdExpenseTypeCode) !== -1
    ) {
      shelterAnswer.push(shellterItem.householdExpenseTypeCode);
    }
  }
  bindBothTargetAnswer(housingAndUtilityCost[1], shelterAnswer);

  housingAndUtilitySubsectionBinding(expenseSection, shelterAnswer, serverJson);

  return housingAndUtilityCost;
}

export function childAndAdultCareBinding(
  expenseSection,
  childAndAdultCare,
  serverJson
) {
  childAndAdultCare[0].Answer = [];
  childAndAdultCare[0].targetValue = [];
  //Child Support Cost for Grantee
  for (let supportItem of serverJson.application.applicant.supportExpenses) {
    let granteeFullName =
      serverJson.application.applicant.firstName +
      " " +
      serverJson.application.applicant.lastName +
      " " +
      _convertDateToState(serverJson.application.applicant.birthDate);
    childAndAdultCare[0].Answer.push(serverJson.application.applicant.clientId);
    childAndAdultCare[0].targetValue.push(
      serverJson.application.applicant.clientId
    );

    let careConditional = _.cloneDeep(childAndAdultCare[0].conditionals[0]);

    childAndAdultCare[0].options.forEach(function (member, index) {
      if (
        member.optionValue ===
        serverJson.application.applicant.clientId.toString()
      ) {
        careConditional.conditionalQuestions[0].Answer = {
          amount: serverJson.application.applicant.supportExpenses[0].amount,
          unit: serverJson.application.applicant.supportExpenses[0]
            .frequencyCode,
        };

        let conditionalQuestionTemplate = childAndAdultCare[0].conditionals[0];
        childAndAdultCare[0].conditionals = [];
        childAndAdultCare[0].conditionals[0] = _.cloneDeep(
          conditionalQuestionTemplate
        );
        childAndAdultCare[0].conditionals[0].valueToMatch = member.optionValue;
        childAndAdultCare[0].conditionals[0].conditionalQuestions[0].label =
          houseHoldMultilanguageLabels(
            member[OptionsProps.OPTION_DISPLAY],
            language
          ).conditionalLabel;
        childAndAdultCare[0].conditionals[0].conditionalQuestions[0].name =
          "recipient-of-care-" + [0];

        childAndAdultCare[0].conditionals[0].conditionalQuestions[0].options =
          [];

        childAndAdultCare[0].options.forEach(function (hhMember, memIndex) {
          if (
            hhMember.optionValue !==
            serverJson.application.applicant.clientId.toString()
          ) {
            childAndAdultCare[0].conditionals[0].conditionalQuestions[0].options.push(
              hhMember
            );
          }
        }, this);
      }
    }, this);
  }
  for (let householdItem of serverJson.application.applicant.household) {
    if (householdItem.supportExpenses.length > 0) {
      if (
        householdItem.clientId === householdItem.supportExpenses[0].clientId
      ) {
      }
    }
  }
  return childAndAdultCare;
}

export function childSupportCostSectionBinding(
  expenseSection,
  childSupportCost,
  serverJson
) {
  childSupportCost[0].Answer = [];
  childSupportCost[0].targetValue = [];
  //Child Support Cost for Grantee
  for (let supportItem of serverJson.application.applicant.supportExpenses) {
    let granteeFullName =
      serverJson.application.applicant.firstName +
      " " +
      serverJson.application.applicant.lastName +
      " " +
      _convertDateToState(serverJson.application.applicant.birthDate);
    childSupportCost[0].Answer.push(serverJson.application.applicant.clientId);
    childSupportCost[0].targetValue.push(
      serverJson.application.applicant.clientId
    );
    for (let conditional of childSupportCost[0].conditionals) {
      if (
        conditional.valueToMatch === serverJson.application.applicant.clientId
      ) {
        conditional.conditionalQuestions[0].Answer = {
          amount: serverJson.application.applicant.supportExpenses[0].amount,
          unit: serverJson.application.applicant.supportExpenses[0]
            .frequencyCode,
        };
      }
    }
  }
  //Child Support Cost for HH Members
  for (let householdItem of serverJson.application.applicant.household) {
    if (householdItem.supportExpenses.length > 0) {
      if (
        householdItem.clientId === householdItem.supportExpenses[0].clientId
      ) {
        childSupportCost[0].Answer.push(householdItem.clientId);
        childSupportCost[0].targetValue.push(householdItem.clientId);
        for (let conditional of childSupportCost[0].conditionals) {
          if (conditional.valueToMatch === householdItem.clientId) {
            conditional.conditionalQuestions[0].Answer = {
              amount:
                serverJson.application.applicant.supportExpenses[0].amount,
              unit: serverJson.application.applicant.supportExpenses[0]
                .frequencyCode,
            };
          }
        }
      }
    }
  }
  return childSupportCost;
}

export function housingAndUtilitySubsectionBinding(
  expenseSection,
  shelterAnswer,
  serverJson
) {
  for (let costItem of expenseSection.subsections[0].subsections[0].questions) {
    if (shelterAnswer.indexOf(costItem.valueToMatch !== -1)) {
      for (let shellterItem of serverJson.application.shelterExpenses) {
        if (shellterItem.householdExpenseTypeCode === costItem.valueToMatch) {
          bindBothTargetAnswer(costItem, [
            { amount: shellterItem.amount, unit: shellterItem.frequencyCode },
          ]);
        }
      }
    }
  }
}

export function childMedicalCostSectionBinding(
  sections,
  medicalSectionQuestion,
  serverJson
) {
  medicalSectionQuestion[0].Answer = [];
  medicalSectionQuestion[0].targetValue = [];
  sections[4].subsections[3].subsections[0].Answer = [];
  for (let granteeMedicalItem of serverJson.application.applicant
    .medicalExpenses) {
    let medicalExpAnswer = {};
    medicalExpAnswer.id = serverJson.application.applicant.clientId;
    medicalExpAnswer.drive = "Yes";
    medicalExpAnswer.count =
      serverJson.application.applicant.medicalExpenses.length;
    medicalExpAnswer.address = {
      street: granteeMedicalItem.address.street,
      city: granteeMedicalItem.address.city,
      state: granteeMedicalItem.address.state,
      zipCode: granteeMedicalItem.address.postal,
    };
    medicalExpAnswer.cost = {
      amount: granteeMedicalItem.driveTripCount,
      unit: granteeMedicalItem.driveFrequencyCode,
    };
    medicalExpAnswer.frequency = {
      amount: granteeMedicalItem.transportationAmount,
      unit: granteeMedicalItem.transportationFrequencyCode,
    };
    // ADDRESS SHOULD BE A ARRAY. iT CAN BE EITHER "address" OR "addresses"
    sections[4].subsections[3].subsections[0].Answer.push(medicalExpAnswer);
  }

  for (let dependentItem of serverJson.application.applicant.household) {
    for (let dependentMedicalItem of dependentItem.medicalExpenses) {
      medicalSectionQuestion[0].Answer.push(dependentItem.clientId);

      medicalSectionQuestion[0].targetValue.push(dependentItem.clientId);
      let medicalExpAnswer = {};
      medicalExpAnswer.id = dependentItem.clientId;
      medicalExpAnswer.drive = "Yes";
      medicalExpAnswer.count = dependentItem.medicalExpenses.length;
      medicalExpAnswer.address = {
        street: dependentMedicalItem.address.street,
        city: dependentMedicalItem.address.city,
        state: dependentMedicalItem.address.state,
        zipCode: dependentMedicalItem.address.postal,
      };
      medicalExpAnswer.cost = {
        amount: dependentMedicalItem.driveTripCount,
        unit: dependentMedicalItem.driveFrequencyCode,
      };
      medicalExpAnswer.frequency = {
        amount: dependentMedicalItem.transportationAmount,
        unit: dependentMedicalItem.transportationFrequencyCode,
      };
      // ADDRESS SHOULD BE A ARRAY. iT CAN BE EITHER "address" OR "addresses"

      sections[4].subsections[3].subsections[0].Answer.push(medicalExpAnswer);
    }
  }
  return medicalSectionQuestion;
}

export function mapExpenseOptions(sections, serverJson) {
  // Update Expense template with HouseHold Member details
  var memberObject = {};
  var memberListInfo = [];
  var expenseAboutMeFull =
    serverJson.application.applicant.firstName +
    " " +
    serverJson.application.applicant.lastName +
    " " +
    _convertDateToState(serverJson.application.applicant.birthDate);
  memberObject[OptionsProps.OPTION_VALUE] =
    serverJson.application.applicant.clientId;
  memberObject[OptionsProps.OPTION_DISPLAY] = expenseAboutMeFull;
  memberObject.isGrantee = TextProps.VALUE_Y;
  memberListInfo.push(memberObject);
  var expenseCopy = sections[4];
  for (
    let memberIndex = 0;
    memberIndex < serverJson.application.applicant.household.length;
    memberIndex++
  ) {
    let member = serverJson.application.applicant.household[memberIndex];
    let childMemberObject = _.cloneDeep(memberObject);
    var expenseCombinedName =
      member.firstName +
      " " +
      member.lastName +
      " " +
      _convertDateToState(member.birthDate);
    childMemberObject[OptionsProps.OPTION_VALUE] = member.clientId;
    childMemberObject[OptionsProps.OPTION_DISPLAY] = expenseCombinedName;
    childMemberObject.isGrantee = TextProps.VALUE_N;

    memberListInfo.push(childMemberObject);
  }
  expenseCopy.subsections[1].questions[0].options = memberListInfo;
  expenseCopy.subsections[2].questions[0].options = memberListInfo;
  expenseCopy.subsections[3].questions[0].options = memberListInfo;
  let conditionalQuestions =
    expenseCopy.subsections[2].questions[0].conditionals;
  for (let index = 0; index < conditionalQuestions.length; index++) {
    conditionalQuestions[index].valueToMatch =
      expenseCopy.subsections[2].questions[0].options[0].optionValue;
    conditionalQuestions[index].clientId =
      expenseCopy.subsections[2].questions[0].options[0].id;
  }
  return sections;
}

//Answer and Target Object Created Here
function bindBothTargetAnswer(question, Answer) {
  question.Answer = Answer;
  question.targetValue = Answer;
  return question;
}

function _convertMonthNumberToString(monthNumber) {
  return isNone(MONTHSHORT[monthNumber])
    ? MONTHSHORT[0]
    : MONTHSHORT[monthNumber];
}
function _convertDateToState(date) {
  if (isNone(date)) {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return (
    " (" +
    date.getDate() +
    "." +
    _convertMonthNumberToString(date.getMonth()) +
    "." +
    date.getFullYear() +
    ")"
  );
}
