import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import DTADropdown from "./dtaDropdown";
import {
  addStringIf,
  tryCall,
  validateAria,
} from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { date as formatDate } from "../components/utilities/formatters";
import { consumerConst } from "../../pages/consumer/consumerText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class DTADatePicker extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.instanceOf(Date),
    // container
    dateProps: PropTypes.object,
    emptyLabel: PropTypes.string,
    buttonClass: PropTypes.string,
    // behavior
    closeOnChange: PropTypes.bool,
    // accessibility
    "aria-describedby": PropTypes.string,
  };

  static defaultProps = {
    ...CommonDefaults,
    // container
    dateProps: {},
    emptyLabel: consumerConst(selectedLanguage).selectDate,
    buttonClass: "",
    // behavior
    closeOnChange: TextProps.VALUE_TRUE,
    // accessibility
    "aria-describedby": "",
  };

  state = {
    _value: this.props.value,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ _value: nextProps.value });
    }
  }

  render() {
    const {
        className,
        buttonClass,
        name,
        "aria-labelledby": ariaLabels,
        "aria-describedby": ariaDescriptions,
        disabled,
        error,
        required,
        dateProps,
        emptyLabel,
      } = this.props,
      value = this.state._value,
      triggerLabel = value
        ? `${formatDate(value, { humanize: TextProps.VALUE_TRUE })}`
        : emptyLabel;
    return (
      <div className={addStringIf(className, "dta-date-picker")}>
        <span className="sr-only" id={this.nextUniqueId()}>
          {required && "Required."}
        </span>
        <DTADropdown
          ref={(el) => (this._dropdown = el)}
          className={addStringIf(className, "dta-date-picker__trigger-wrapper")}
          buttonText={triggerLabel}
          buttonClass={addStringIf(
            error,
            addStringIf(
              buttonClass,
              "dta-date-picker__trigger dta-button dta-button--outline-secondary"
            ),
            "dta-button--outline-error"
          )}
          buttonProps={{
            "aria-labelledby": validateAria(
              `${ariaLabels} ${this.lastUniqueId()}`
            ),
            "aria-describedby": validateAria(ariaDescriptions),
            "aria-invalid": error
              ? TextProps.VALUE_STR_TRUE
              : TextProps.VALUE_STR_FALSE,
            disabled,
            name,
          }}
          menuClass="pad-all--half"
          menuTagName="div"
        >
          <DayPicker
            {...dateProps}
            onDayClick={this._handleClickForPicker}
            selectedDays={this.state._value}
            required
          />
        </DTADropdown>
      </div>
    );
  }

  // Events
  // ------

  _handleClickForPicker = (date, { disabled }) => {
    if (disabled) {
      return;
    }
    this.setState({ _value: date });
    tryCall(this.props.onChange, date);
    if (this.props.closeOnChange && this._dropdown) {
      this._dropdown.close();
    }
  };
}

export default DTADatePicker;
