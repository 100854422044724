import * as TextProps from "../../utils/constants/text";

export function greeterScreeningReducer(state = {}, action) {
  switch (action.type) {
    case "APPLY_GREETER_PAGES_FULLFILLED": {
      let newState = Object.assign({}, state);
      newState.sections = action.payload;
      return newState;
    }

    case "GREETER_NETWORK_FAILED_LOAD_DATA":
      {
        let stateObj = Object.assign({}, state);
        if (stateObj.sections.length > 0) {
          if (stateObj.sections[0].errorRedirect !== undefined) {
            stateObj.sections[0].errorRedirect = TextProps.VALUE_TRUE;
            stateObj.applicationSubmited = TextProps.VALUE_FALSE;
            return stateObj;
          }
        }
        stateObj.sections[0].errorRedirect = TextProps.VALUE_TRUE;
        stateObj.applicationSubmited = TextProps.VALUE_FALSE;
        return stateObj;
      }
      break;
    case "POST_APPLY_GREETER_INFO_DATA": {
      let stateObj = Object.assign({}, state);
      //INSTEAD, CHECKING AND SETTING ONY ID.
      if (action.payload !== undefined) {
        stateObj.sections[0].isSubmitted = TextProps.VALUE_TRUE;
        stateObj.application = action.payload;
      }
      if (action.payload.status === 200) {
        stateObj.applicationSubmited = TextProps.VALUE_TRUE;
      }
      return stateObj;
    }
    case "FILL_GREETER_INPUT_VALUES_STATE": {
      return Object.assign({}, state);
    }

    case "GREETER_UPDATE_BACK_RESPONSE_TO_SECTION": {
      let stateObj = Object.assign({}, state);
      if (action.payload.data.questionName !== undefined) {
        stateObj.sections[0].questionName = action.payload.data.questionName;
        stateObj.sections[0].rowId = action.payload.data.rowId;
      }
      return stateObj;
    }

    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      applyInit.inProgressSaveErrorState = TextProps.VALUE_FALSE;
      applyInit.inProgressSaveErrorMessage = "";
      return applyInit;
    }

    default:
      return Object.assign({}, state);
  }
}
