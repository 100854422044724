import * as languageConsts from "../../constants/types";
import axios from "axios";
import Bowser from "bowser";
import _ from "lodash";

import appConfig from "../../constants/appConfigConstants";
import { address, convertDateToStateFormat,formatPhoneNumber } from "./formatters";
import * as Types from "../../constants/types";
import * as SharedProps from "../../../utils/components/shared";
import * as TextProps from "../../../utils/constants/text";
import * as consumerLabels from "../../../pages/consumer/consumerText";

let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

// function to get Doc type code based on doc type text
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function getDocumentTypeCode(docText) {
  let theCode = "";

  switch (docText) {
    case "Household Misfortune Document":
      theCode = "HOUMIS";
      break;
    case "Returned Mail Notice":
      theCode = "RMNOT";
      break;
    case "SNAP Annual Report Form":
      theCode = "SARF";
      break;
    case "Verification of Caring for the Disabled Notice":
      theCode = "VCDISN";
      break;
    case "Voter Registration Form":
      theCode = "VOTREG";
      break;
    case "ABAWD Work Program Requirement Medical Report Form":
      theCode = "ABWMRF";
      break;
    case "Learnfare Attendance Report for Schools":
      theCode = "LRNATT";
      break;
    case "Learnfare Monthly Attendance Report":
      theCode = "LRNMON";
      break;
    case "SNAP Application for Seniors":
      theCode = "SASEN";
      break;
    case "CSA Membership Agreement":
      theCode = "CSAMAG";
      break;
    case "Overpayments and Hearings Documents":
      theCode = "OVRHER";
      break;
    case "Community Service Participation Form":
      theCode = "COMSPF";
      break;
    case "Education Expenses":
      theCode = "EDUEXP";
      break;
    case "Learnfare":
      theCode = "LRNFRE";
      break;
    case "SNAP Recert Form":
      theCode = "SRECF";
      break;
    case "TAFDC Disability Supplement":
      theCode = "TDISSU";
      break;
    case "SNAP Simplified Reporting Form":
      theCode = "RCRS24";
      break;
    case "Application for Payment of Funeral":
      theCode = "APFUN";
      break;
    case "ESP Referral and Response Form":
      theCode = "ESPREF";
      break;
    case "FIDMU":
      theCode = "FIDMU";
      break;
    case "MONREP":
      theCode = "Monthly Report";
      break;
    case "SNAP Elderly Disabled Recert Form":
      theCode = "SEDRF";
      break;
    case "SSI-SNAP Benefits Reapplication":
      theCode = "SSISBR";
      break;
    case "Transition Plan":
      theCode = "TRAPLA";
      break;
    case "Verification":
      theCode = "VERIFC";
      break;
    case "Learnfare School ID Request Form":
      theCode = "LRNSID";
      break;
    case "CSA Cancellation Form":
      theCode = "CSACAF";
      break;
    case "ADA Document":
      theCode = "ADADOC";
      break;
    case "ADA Medical Form":
      theCode = "ADAMED";
      break;
    case "EAEDC Disability Supplement":
      theCode = "EDISSU";
      break;
    case "Notice of Missed Interview for SNAP Application":
      theCode = "NOMISA";
      break;
    case "SSI-MassHealth":
      theCode = "SSIMAH";
      break;
    case "Referral for Medical Assistance":
      theCode = "REFFMA";
      break;
    case "Request for ABAWD Work Program Exemption Form":
      theCode = "ABWPEF";
      break;
    case "No Trespass Order":
      theCode = "NOTRSP";
      break;
    case "Learnfare Notice of Probation":
      theCode = "LRNNOT";
      break;
    case "Cooperation with Child Support Good Cause Claim":
      theCode = "CCSGCG";
      break;
    case "EAEDC Medical Report":
      theCode = "EMEDRP";
      break;
    case "ESP7 Participation and Attendance":
      theCode = "ESP7PA";
      break;
    case "Hearings and Appeals":
      theCode = "HRGAPP";
      break;
    case "Quality Control":
      theCode = "QC";
      break;
    case "SNAP Benefits Application":
      theCode = "SBENA";
      break;
    case "SNAP Report Change Form [SNAP-5]":
      theCode = "SRCF5";
      break;
    case "TAFDC Reevaluation Form":
      theCode = "TREVF";
      break;
    case "TAFDC Combo Reevaluation Form":
      theCode = "TREVC";
      break;
    case "SSP Invoice":
      theCode = "SSPINV";
      break;
    case "Learnfare Probation Termination":
      theCode = "LRNPRO";
      break;
    case "Learnfare Intervention Form":
      theCode = "LRNINT";
      break;
    case "ABAWD Training Program Information Request Form":
      theCode = "ABWTRA";
      break;
    case "Authorization of Reimbursement AP-SSI-IAR":
      theCode = "AUTREI";
      break;
    case "Bay State CAP Recertification Form":
      theCode = "BAYCAP";
      break;
    case "Interim Report":
      theCode = "SSIXRP$SSIXRF";
      break;
    case "Returned Mail Form SP-RMN":
      theCode = "RMSPRM";
      break;
    case "Simplified SNAP Application for Elderly Applicants":
      theCode = "SSAEA";
      break;
    case "Voluntary Withdrawal":
      theCode = "VOLWIT";
      break;
    case "ABAWD Work Program Participation Report":
      theCode = "ABWPTF";
      break;
    case "Learnfare Sanction Termination":
      theCode = "LRNSAN";
      break;
    case "PSS Document":
      theCode = "PSSDOC";
      break;
    case "Direct Deposit Form":
      theCode = "DDFORM";
      break;
    case "Disability Determination Tracking Form":
      theCode = "DISDTF";
      break;
    case "EDP Warning Notice":
      theCode = "EDPWAR";
      break;
    case "Good Cause Medical Statement":
      theCode = "GCMEDS";
      break;
    case "Recoveries":
      theCode = "RCVRIS";
      break;
    case "Request for Authorized Representative":
      theCode = "STIDEN$SRFAREP";
      break;
    case "Employment Information":
      theCode = "STEINV$STEINVGEN";
      break;
    case "School Verification Notice [SV-1]":
      theCode = "SCVER1";
      break;
    case "Shared Housing Verification Form":
      theCode = "SHVER";
      break;
    case "Third Party Income Verification Request":
      theCode = "TPIVER";
      break;
    case "TAFDC EAEDC Application-Reevaluation":
      theCode = "TEAPRE";
      break;
    case "Program Int Employment Verification Request":
      theCode = "PIEVRE";
      break;
    case "TAFDC Reevaluation Reminder":
      theCode = "TREVR";
      break;
    case "Voluntary Consent to Release Information":
      theCode = "VOLCRI";
      break;
    case "Certification of Immunization Status[TAFDC-2]":
      theCode = "IMSTA2";
      break;
    case "Client Correspondence":
      theCode = "CLCORR";
      break;
    case "Landlord Verification Form":
      theCode = "LLVERF";
      break;
    case "Permanent Verification":
      theCode = "PERVER";
      break;
    case "SNAP Job Search Log":
      theCode = "SJSL";
      break;
    case "Work Study Internship Participation":
      theCode = "WSIP";
      break;
    case "Rest Home Document":
      theCode = "RHDOCS";
      break;
    case "Program Int Fraud Report":
      theCode = "PIFREP";
      break;
    case "CC Cover Sheet":
      theCode = "CCLETT";
      break;
    case "SNAP Recertification":
      theCode = "SAPPL";
      break;
    case "Address Change":
      theCode = "STRTAD$STRTAD";
      break;
    case "Request to Stop Benefit(s)":
      theCode = "STRTSB$STRTSB";
      break;
    case "Income and/or Expense Changes":
      theCode = "STRTIE$STRTIE";
      break;
    case "Other":
      theCode = "STRTOT$STRTOT";
      break;
    case "TANF Redetermination":
      theCode = "TELIRE$TRECER";
      break;
    case "Wage Verification":
      theCode = "STEINV$SWGVFM";
      break;
    case "Unearned Income Verification":
      theCode = "TUNINV$TUNINVGEN";
      break;
    case "Shelter Verification":
      theCode = "STSHEX$STSHEXGEN";
      break;
    case "Child Support Verification":
      theCode = "SCHSUP$SCHSUPGEN";
      break;
    case "Medical Support Verification":
      theCode = "SMEDEX$SMEDEXGEN";
      break;
    case "Identification/Relationship Verification":
      theCode = "STIDEN$STIDENGEN";
      break;
    case "Participation/Activity for JOBS":
      theCode = "TJACTR$TJACTRGEN";
      break;
    case "TANF JOBS Attendance":
      theCode = "TJACTR$TATTDPROGREP";
      break;
    /* case 'All':
      theCode = 'All';
      break; */
    default:
      theCode = "All";
      break;
  }
  return theCode;
}

export function getDocumentTypeDescription(docText) {
  let theCode = "";

  switch (docText) {
    case "HOUMIS":
      theCode = "Household Misfortune Document";
      break;
    case "RMNOT":
      theCode = "Returned Mail Notice";
      break;
    case "SARF":
      theCode = "SNAP Annual Report Form";
      break;
    case "VCDISN":
      theCode = "Verification of Caring for the Disabled Notice";
      break;
    case "VOTREG":
      theCode = "Voter Registration Form";
      break;
    case "ABWMRF":
      theCode = "ABAWD Work Program Requirement Medical Report Form";
      break;
    case "LRNATT":
      theCode = "Learnfare Attendance Report for Schools";
      break;
    case "LRNMON":
      theCode = "Learnfare Monthly Attendance Report";
      break;
    case "SASEN":
      theCode = "SNAP Application for Seniors";
      break;
    case "CSAMAG":
      theCode = "CSA Membership Agreement";
      break;
    case "OVRHER":
      theCode = "Overpayments and Hearings Documents";
      break;
    case "COMSPF":
      theCode = "Community Service Participation Form";
      break;
    case "EDUEXP":
      theCode = "Education Expenses";
      break;
    case "LRNFRE":
      theCode = "Learnfare";
      break;
    case "SRECF":
      theCode = "SNAP Recert Form";
      break;
    case "TDISSU":
      theCode = "TAFDC Disability Supplement";
      break;
    case "RCRS24":
      theCode = "SNAP Simplified Reporting Form";
      break;
    case "APFUN":
      theCode = "Application for Payment of Funeral";
      break;
    case "ESPREF":
      theCode = "ESP Referral and Response Form";
      break;
    case "FIDMU":
      theCode = "FIDMU";
      break;
    case "MONREP":
      theCode = "Monthly Report";
      break;
    case "SEDRF":
      theCode = "SNAP Elderly Disabled Recert Form";
      break;
    case "SSISBR":
      theCode = "SSI-SNAP Benefits Reapplication";
      break;
    case "TRAPLA":
      theCode = "Transition Plan";
      break;
    case "VERIFC":
      theCode = "Verification";
      break;
    case "LRNSID":
      theCode = "Learnfare School ID Request Form";
      break;
    case "CSACAF":
      theCode = "CSA Cancellation Form";
      break;
    case "ADADOC":
      theCode = "ADA Document";
      break;
    case "ADAMED":
      theCode = "ADA Medical Form";
      break;
    case "EDISSU":
      theCode = "EAEDC Disability Supplement";
      break;
    case "NOMISA":
      theCode = "Notice of Missed Interview for SNAP Application";
      break;
    case "SSIMAH":
      theCode = "SSI-MassHealth";
      break;
    case "REFFMA":
      theCode = "Referral for Medical Assistance";
      break;
    case "ABWPEF":
      theCode = "Request for ABAWD Work Program Exemption Form";
      break;
    case "NOTRSP":
      theCode = "No Trespass Order";
      break;
    case "LRNNOT":
      theCode = "Learnfare Notice of Probation";
      break;
    case "CCSGCG":
      theCode = "Cooperation with Child Support Good Cause Claim";
      break;
    case "EMEDRP":
      theCode = "EAEDC Medical Report";
      break;
    case "ESP7PA":
      theCode = "ESP7 Participation and Attendance";
      break;
    case "HRGAPP":
      theCode = "Hearings and Appeals";
      break;
    case "QC":
      theCode = "Quality Control";
      break;
    case "SBENA":
      theCode = "SNAP Benefits Application";
      break;
    case "SRCF5":
      theCode = "SNAP Report Change Form [SNAP-5]";
      break;
    case "TREVF":
      theCode = "TAFDC Reevaluation Form";
      break;
    case "TREVC":
      theCode = "TAFDC Combo Reevaluation Form";
      break;
    case "SSPINV":
      theCode = "SSP Invoice";
      break;
    case "LRNPRO":
      theCode = "Learnfare Probation Termination";
      break;
    case "LRNINT":
      theCode = "Learnfare Intervention Form";
      break;
    case "ABWTRA":
      theCode = "ABAWD Training Program Information Request Form";
      break;
    case "AUTREI":
      theCode = "Authorization of Reimbursement AP-SSI-IAR";
      break;
    case "BAYCAP":
      theCode = "Bay State CAP Recertification Form";
      break;
    case "INTREP":
    case "SSIXRP$SSIXRF":
      theCode = "Interim Report";
      break;
    case "RMSPRM":
      theCode = "Returned Mail Form SP-RMN";
      break;
    case "SSAEA":
      theCode = "Simplified SNAP Application for Elderly Applicants";
      break;
    case "VOLWIT":
      theCode = "Voluntary Withdrawal";
      break;
    case "ABWPTF":
      theCode = "ABAWD Work Program Participation Report";
      break;
    case "LRNSAN":
      theCode = "Learnfare Sanction Termination";
      break;
    case "PSSDOC":
      theCode = "PSS Document";
      break;
    case "DDFORM":
      theCode = "Direct Deposit Form";
      break;
    case "DISDTF":
      theCode = "Disability Determination Tracking Form";
      break;
    case "EDPWAR":
      theCode = "EDP Warning Notice";
      break;
    case "GCMEDS":
      theCode = "Good Cause Medical Statement";
      break;
    case "RCVRIS":
      theCode = "Recoveries";
      break;
    case "STIDEN$SRFAREP":
      theCode = "Request for Authorized Representative";
      break;
    case "STEINV$STEINVGEN":
      theCode = "Employment Information";
      break;
    case "SCVER1":
      theCode = "School Verification Notice [SV-1]";
      break;
    case "SHVER":
      theCode = "Shared Housing Verification Form";
      break;
    case "TPIVER":
      theCode = "Third Party Income Verification Request";
      break;
    case "TEAPRE":
      theCode = "TAFDC EAEDC Application-Reevaluation";
      break;
    case "PIEVRE":
      theCode = "Program Int Employment Verification Request";
      break;
    case "TREVR":
      theCode = "TAFDC Reevaluation Reminder";
      break;
    case "VOLCRI":
      theCode = "Voluntary Consent to Release Information";
      break;
    case "IMSTA2":
      theCode = "Certification of Immunization Status[TAFDC-2]";
      break;
    case "CLCORR":
      theCode = "Client Correspondence";
      break;
    case "LLVERF":
      theCode = "Landlord Verification Form";
      break;
    case "PERVER":
      theCode = "Permanent Verification";
      break;
    case "SJSL":
      theCode = "SNAP Job Search Log";
      break;
    case "WSIP":
      theCode = "Work Study Internship Participation";
      break;
    case "RHDOCS":
      theCode = "Rest Home Document";
      break;
    case "PIFREP":
      theCode = "Program Int Fraud Report";
      break;
    case "CCLETT":
      theCode = "CC Cover Sheet";
      break;
    case "SAPPL":
      theCode = "SNAP Recertification";
      break;
    case "RECERT":
      theCode = "Recertification Form";
      break;
    case "STRTAD$STRTAD":
      theCode = "Address Change";
      break;
    case "STRTSB$STRTSB":
      theCode = "Request to Stop Benefit(s)";
      break;
    case "STRTIE$STRTIE":
      theCode = "Income and/or Expense Changes";
      break;
    case "STRTOT$STRTOT":
      theCode = "Other";
      break;
    case "TELIRE$TRECER":
      theCode = "TANF Redetermination";
      break;
    case "STEINV$SWGVFM":
      theCode = "Wage Verification";
      break;
    case "TUNINV$TUNINVGEN":
      theCode = "Unearned Income Verification";
      break;
    case "STSHEX$STSHEXGEN":
      theCode = "Shelter Verification";
      break;
    case "SCHSUP$SCHSUPGEN":
      theCode = "Child Support Verification";
      break;
    case "SMEDEX$SMEDEXGEN":
      theCode = "Medical Support Verification";
      break;
    case "STIDEN$STIDENGEN":
      theCode = "Identification/Relationship Verification";
      break;
    case "TJACTR$TJACTRGEN":
      theCode = "Participation/Activity for JOBS";
      break;
    case "TJACTR$TATTDPROGREP":
      theCode = "TANF JOBS Attendance";
      break;
    /* case 'All':
       theCode = 'All';
       break; */
    default:
      theCode = "";
      break;
  }
  return theCode;
}

export function getDocumentDesc(language = null) {
  switch (language) {
    case languageConsts.ENGLISH:
    case languageConsts.SPANISH:
    case languageConsts.PORTUGUESE:
    case languageConsts.CHINESE:
    case languageConsts.VIETNAMESE:
    case HI:
      return {
        ABWMRF: "ABAWD Work Program Requirement Medical Report Form",
        ABWPEF: "Request for ABAWD Work Program Exemption Form",
        ABWPTF: "ABAWD Work Program Participation Report",
        ABWTRA: "ABAWD Training Program Information Request Form",
        ADADOC: "ADA Document",
        ADAMED: "ADA Medical Form",
        APFUN: "Application for Payment of Funeral",
        AUTREI: "Authorization of Reimbursement AP-SSI-IAR",
        BAYCAP: "Bay State CAP Recertification Form",
        CCLETT: "CC Cover Sheet",
        CCSGCG: "Cooperation with Child Support Good Cause Claim",
        CLCORR: "Client Correspondence",
        COMSPF: "Community Service Participation Form",
        CSACAF: "CSA Cancellation Form",
        CSAMAG: "CSA Membership Agreement",
        DDFORM: "Direct Deposit Form",
        DISDTF: "Disability Determination Tracking Form",
        EDISSU: "EAEDC Disability Supplement",
        EDPWAR: "EDP Warning Notice",
        EDUEXP: "Education Expenses",
        EMEDRP: "EAEDC Medical Report",
        ESP7PA: "ESP7 Participation and Attendance",
        ESPREF: "ESP Referral and Response Form",
        FIDMU: "FIDMU",
        GCMEDS: "Good Cause Medical Statement",
        HOUMIS: "Household Misfortune Document",
        HRGAPP: "Hearings and Appeals",
        IMSTA2: "Certification of Immunization Status[TAFDC-2]",
        SSIXRP$SSIXRF: "Interim Report",
        INTREP: "Interim Report",
        LLVERF: "Landlord Verification Form",
        LRNATT: "Learnfare Attendance Report for Schools",
        LRNFRE: "Learnfare",
        LRNINT: "Learnfare Intervention Form",
        LRNMON: "Learnfare Monthly Attendance Report",
        LRNNOT: "Learnfare Notice of Probation",
        LRNPRO: "Learnfare Probation Termination",
        LRNSAN: "Learnfare Sanction Termination",
        LRNSID: "Learnfare School ID Request Form",
        MONREP: "Monthly Report",
        NOMISA: "Notice of Missed Interview for SNAP Application",
        NOTRSP: "No Trespass Order",
        OVRHER: "Overpayments and Hearings Documents",
        PERSHI: "Permission to Share Information",
        PERVER: "Permanent Verification",
        PIEVRE: "Program Int Employment Verification Request",
        PIFREP: "Program Int Fraud Report",
        PSSDOC: "PSS Document",
        QC: "Quality Control",
        RCRS24: "SNAP Simplified Reporting Form",
        RCVRIS: "Recoveries",
        SAPPL: "SNAP Recertification",
        STRTAD$STRTAD: "Address Change",
        STRTSB$STRTSB: "Request to Stop Benefit(s)",
        STRTIE$STRTIE: "Income and/or Expense Changes",
        STRTOT$STRTOT: "Other",
        TELIRE$TRECER:"TANF Redetermination",
        STEINV$SWGVFM: "Wage Verification",
        TUNINV$TUNINVGEN: "Unearned Income Verification",
        STSHEX$STSHEXGEN: "Shelter Verification",
        SCHSUP$SCHSUPGEN: "Child Support Verification",
        SMEDEX$SMEDEXGEN: "Medical Support Verification",
        STIDEN$STIDENGEN: "Identification/Relationship Verification",
        TJACTR$TJACTRGEN: "Participation/Activity for JOBS",
        TJACTR$TATTDPROGREP: "TANF JOBS Attendance",
        RECERT: "Recertification Form",
        REFFMA: "Referral for Medical Assistance",
        STIDEN$SRFAREP: "Request for Authorized Representative",
        STEINV$STEINVGEN: "Employment Information",
        RHDOCS: "Rest Home Document",
        RMNOT: "Returned Mail Notice",
        RMSPRM: "Returned Mail Form SP-RMN",
        SARF: "SNAP Annual Report Form",
        SASEN: "SNAP Application for Seniors",
        SBENA: "SNAP Benefits Application",
        SCVER1: "School Verification Notice [SV-1]",
        SEDRF: "SNAP Elderly Disabled Recert Form",
        SHVER: "Shared Housing Verification Form",
        SJSL: "SNAP Job Search Log",
        SNWRMR: "SNAP Work Requirement Medical Report Form",
        SRCF5: "SNAP Report Change Form [SNAP-5]",
        SRECF: "SNAP Recert Form",
        SSAEA: "Simplified SNAP Application for Elderly Applicants",
        SSIMAH: "SSI-MassHealth",
        SSISBR: "SSI-SNAP Benefits Reapplication",
        SSPINV: "SSP Invoice",
        TDISSU: "TAFDC Disability Supplement",
        TEAPRE: "TAFDC EAEDC Application-Reevaluation",
        TPIVER: "Third Party Income Verification Request",
        TRAPLA: "Transition Plan",
        TREVC: "TAFDC Combo Reevaluation Form",
        TREVF: "TAFDC Reevaluation Form",
        TREVR: "TAFDC Reevaluation Reminder",
        VCDISN: "Verification of Caring for the Disabled Notice",
        VERIFC: "Verification",
        VOLCRI: "Voluntary Consent to Release Information",
        VOLWIT: "Voluntary Withdrawal",
        VOTREG: "Voter Registration Form",
        WAGVER: "Wage verification form",
        WSIP: "Work Study Internship Participation",
      };
  }
}


export function getShelterandChildSupportFrequency(language = null) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        ANNUAL: "Annual",
        BIMON: "Bimonthly(every two months)",
        BIWEEK: "Biweekly(every two weeks)",
        QRTRLY: "Quarterly",
        SEMANL: "Semi-annual(Twice a year)",
        SEMMON: "Semi-monthly(Twice a month)",
        MONTH: "Monthly",
        WEEK: "Weekly"
      };
    case languageConsts.SPANISH:
      return {
        ANNUAL: "Año",
        BIMON: "Bimestralmente (cada dos meses)",
        BIWEEK: "Quincenalmente (cada dos semanas)",
        MONTH: "Mes",
        QRTRLY: "Trimestralmente",
        WEEK: "Semana",
      };
    case languageConsts.PORTUGUESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
      };
    case languageConsts.CHINESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
      };
    case languageConsts.VIETNAMESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
      };
    case HI:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
      };
  }
}

export function getFrequency(language = null) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        ANNUAL: "Annual",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Monthly",
        QRTRLY: "Quarterly",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)",
        WEEK: "Weekly",
      };
    case languageConsts.SPANISH:
      return {
        ANNUAL: "Anualmente",
        BIMON: "Bimestralmente (cada dos meses)",
        BIWEEK: "Quincenalmente (cada dos semanas)",
        MONTH: "Mensualmente",
        QRTRLY: "Trimestralmente",
        SEMANL: "Bianual (dos veces al año)",
        SEMMON: "semi-mensal (dos veces al mes)",
        WEEK: "Semanalmente",
      };
    case languageConsts.PORTUGUESE:
      return {
        ANNUAL: "Anual",
        BIMON: "Bimestral (a cada duas semanas)",
        BIWEEK: "Quinzenal (a cada duas semanas)",
        MONTH: "Mensal",
        QRTRLY: "Trimestral",
        SEMANL: "Semestral (duas vezes ao ano)",
        SEMMON: "semi-mensal (duas vezes por mês)",
        WEEK: "Semanal",
      };
    case languageConsts.CHINESE:
      return {
        ANNUAL: "每年",
        BIMON: "每半月（两周一次）",
        BIWEEK: "每两周（两周一次）",
        MONTH: "每月",
        QRTRLY: "每季度",
        SEMANL: "每半年（一年两次）",
        SEMMON: "每半月（每月两次）",
        WEEK: "每周",
      };
    case languageConsts.VIETNAMESE:
      return {
        ANNUAL: "hàng năm",
        BIMON: "Hai tháng một lần (hai tuần một lần)",
        BIWEEK: "Hai tuần một lần",
        MONTH: "Hàng tháng",
        QRTRLY: "Hàng quý",
        SEMANL: "Nửa năm (Hai lần một năm)",
        SEMMON: "nửa tháng (hai lần một tháng)",
        WEEK: "Hàng tuần",
      };
    case HI:
      return {
        ANNUAL: "Anyèl",
        BIMON: "Bimansyèl (chak de mwa)",
        BIWEEK: "Biebdomadè (chak de semèn)",
        MONTH: "Mansyèl",
        QRTRLY: "Chak trimès",
        SEMANL: "Semianyèl (de fwa pa ane)",
        SEMMON: "Semimansyèl (de fwa pa mwa)",
        WEEK: "Chak semèn",
      };
  }
}

export function getFrequencyCode(language) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        Month:"MONTH",
        Year:"ANNUAL",
        Week:"WEEK",
        Annual: "ANNUAL",
        "Bimonthly (every two months)": "BIMON",
        "Bimonthly(every two months)": "BIMON",
        "Biweekly (every two weeks)": "BIWEEK",
        "Biweekly(every two weeks)": "BIWEEK",
        Monthly: "MONTH",
        Quarterly: "QRTRLY",
        "Semiannual (twice a year)": "SEMANL",
        "Semi-annual(Twice a year)": "SEMANL",
        "Semimonthly (twice a month)": "SEMMON",
        "Semi-monthly(Twice a month)": "SEMMON",
        Weekly: "WEEK",
      };
    case languageConsts.SPANISH:
      return {
        Anualmente: "ANNUAL",
        "Bimestralmente (cada dos meses)": "BIMON",
        "Bimestralmente(cada dos meses)": "BIMON",
        "Quincenalmente (cada dos semanas)": "BIWEEK",
        "Quincenalmente(cada dos semanas)": "BIWEEK",
        Mensualmente: "MONTH",
        Trimestralmente: "QRTRLY",
        "Bianual (dos veces al año)": "SEMANL",
        "Bianual(dos veces al año)": "SEMANL",
        "semi-mensal (dos veces al mes)": "SEMMON",
        "semi-mensal(dos veces al mes)": "SEMMON",
        Semanalmente: "WEEK",
        Semana: "WEEK",
        Mes: "MONTH",
        Año: "ANNUAL"
      };
    case languageConsts.PORTUGUESE:
      return {
        Anual: "ANNUAL",
        "Bimestral (a cada duas semanas)": "BIMON",
        "Quinzenal (a cada duas semanas)": "BIWEEK",
        Mensal: "MONTH",
        Trimestral: "QRTRLY",
        "Semestral (duas vezes ao ano)": "SEMANL",
        "semi-mensal (duas vezes por mês)": "SEMMON",
        Semanal: "WEEK",
      };
    case languageConsts.CHINESE:
      return {
        每年: "ANNUAL",
        "每半月（两周一次）": "BIMON",
        "每两周（两周一次）": "BIWEEK",
        每月: "MONTH",
        每季度: "QRTRLY",
        "每半年（一年两次）": "SEMANL",
        "每半月（每月两次）": "SEMMON",
        每周: "WEEK",
      };
    case languageConsts.VIETNAMESE:
      return {
        "hàng năm": "ANNUAL",
        "Hai tháng một lần (hai tuần một lần)": "BIMON",
        "Hai tuần một lần": "BIWEEK",
        "Hàng tháng": "MONTH",
        "Hàng quý": "QRTRLY",
        "Nửa năm (Hai lần một năm)": "SEMANL",
        "nửa tháng (hai lần một tháng)": "SEMMON",
        "Hàng tuần": "WEEK",
      };
    case HI:
      return {
        Anyèl: "ANNUAL",
        "Bimansyèl (chak de mwa)": "BIMON",
        "Biebdomadè (chak de semèn)": "BIWEEK",
        Mansyèl: "MONTH",
        "Chak trimès": "QRTRLY",
        "Semianyèl (de fwa pa ane)": "SEMANL",
        "Semimansyèl (de fwa pa mwa)": "SEMMON",
        "Chak semèn": "WEEK",
      };
  }
}

export function filterData(data, startDate, endDate, key) {
  var result = [];
  if (
    startDate !== undefined &&
    endDate !== undefined &&
    startDate !== null &&
    endDate !== null
  ) {
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() + 1
    );
    data.map((doc) => {
      if (doc[key] !== undefined && doc[key] !== null && doc[key] !== "") {
        var recDate = new Date(doc[key]);
        if (recDate >= startDate && recDate < endDate) {
          result.push(doc);
        }
      } else {
        result.push(doc);
      }
    });
  } else {
    result = data;
  }
  return result;
}

export function resetAlertMessage(type) {
  var benefits = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
  var benefitsInfo = benefits.benefitInfo;
  var resultBenefitInfo = [];
  if (benefitsInfo !== undefined && type !== undefined) {
    benefitsInfo.map((benefit) => {
      if (
        benefit.alertMessages !== undefined &&
        benefit.alertMessages.RECERT_TYPE !== undefined &&
        benefit.alertMessages.RECERT_TYPE === type
      ) {
        benefit.alertMessages.RECERT_TYPE = "";
        benefit.alertMessages.SNAP_FLAG = "";
      }
      resultBenefitInfo.push(benefit);
    });
  }

  benefits.benefitInfo = resultBenefitInfo;

  return benefits;
}

export function setConfig() {
  let systemInfo = {
    ip: "",
    source: appConfig.source,
    deviceId: "",
  };
  var browser = Bowser.getParser(navigator.userAgent);
  if (browser) {
    systemInfo.deviceId =
      browser.getPlatform().type +
        " - " +
        browser.getBrowser().name +
        " " +
        browser.getBrowser().version +
        " - " +
        browser.getOS().name +
        " " +
        browser.getOS().version || "";
    systemInfo.deviceId =
      browser.getPlatform().type +
        " - " +
        browser.getBrowser().name +
        " " +
        browser.getBrowser().version +
        " - " +
        browser.getOS().name +
        " " +
        browser.getOS().version || "";
  }
  axios
    .get("https://api.ipify.org", appConfig.HttpOptions)
  axios
    .get("https://api.ipify.org", appConfig.HttpOptions)
    .then((response) => {
      systemInfo.ip = response.data;
      sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
    })
    .catch((error) => {
      systemInfo.ip = "";
      sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
      sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
    })
    .catch((error) => {
      systemInfo.ip = "";
      sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
    });
}

const formQuestionObj = (question,rootQuestion) => {
  let questionObj = {};
  if (question.type === Types.CHECKBOX && Array.isArray(question.Answer)) {
    questionObj[SharedProps.LABEL] = getFormattedLabel(question.label);
    questionObj[TextProps.VALUE] = question.name === "disability" && question.Answer.includes(question.clearOption) ? question.clearOption : getCheckboxGroupDisplayText(
      question.options,
      question.Answer,
      rootQuestion
    );
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
  } else if (question.type === Types.DATE) {
    questionObj[SharedProps.LABEL] = question.label;
    questionObj[TextProps.VALUE] = question.Answer
      ? convertDateToStateFormat(question.Answer)
      : "-";
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
  } else if (question.labelHasMarkup) {
    questionObj[SharedProps.LABEL] = getFormattedLabel(question.label);
    questionObj[TextProps.VALUE] = getFormattedValue(
      question.Answer,
      question.type
    );
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
  } else if(question.type === Types.REPEATABLE_QUESTION){
    const isResourceSection = question && question.Answer && question.Answer.filter(ans => ans.type === "resources");
    if(isResourceSection && isResourceSection.length > 0){
      questionObj['type'] = "resources";
      questionObj['templateType'] = question.templateType;
      questionObj['resourceType'] = question.Answer && question.Answer.length === 1 && (question.Answer[0].amount === "" || question.Answer[0].amount === null) ? "" : question.displayIncomeName;
      questionObj.Answer = question.Answer;
    } else if(question.Answer && question.Answer.length && question.Answer[0] && question.Answer[0].amount){
      let quesLabel = question.repeatedQuestion.label;
      if ("employer" in question.Answer[0]){
        quesLabel = quesLabel + consumerLabels.consumerConst(selectedLanguage).fromMultiLang +question.Answer[0].employer
      }
    questionObj[SharedProps.LABEL] = getFormattedLabel(quesLabel);
    questionObj[TextProps.VALUE] = getFormattedValue(
      question.Answer,
      question.type
    );
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
    }
  } else if (question.type === Types.INPUT_SSN && question.isChecked) {
    questionObj[SharedProps.LABEL] = question.label;
    questionObj[TextProps.VALUE] = question.inputClearLabel;
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
  } else {
    questionObj[SharedProps.LABEL] = getFormattedLabel(question.label);
    questionObj[TextProps.VALUE] = getFormattedValue(
      question.Answer,
      question.type
    );
    questionObj[SharedProps.LABEL_HAS_MARKUP] = question.labelHasMarkup ? true : false;
  }
  
  return questionObj;
};

export const formSummaryObj = (sections) => {
  if (!sections) {
    return [];
  }
  let summaryData = [];

  sections.forEach((sectionObj) => {
    //form items array from questions
    const summaryQuestions =
      sectionObj.questions &&
      sectionObj.questions
        .filter((question) => {
          if (question.type === Types.DIVIDER) {
            return false;
          }
          if ( question.type === Types.TEXT) {
            return false;
          }
          if (
            typeof question.label === "string" &&
            question.label.length > 200
          ) {
            return false;
          }
          return true;
        })
        .flatMap((sectionQuestion) => {
          let sectionQuestionObj;
          let sectionQuestionArray;
          let conditionalQuestion;
          if (
            sectionQuestion.conditionals &&
            sectionQuestion.conditionals.length
          ) {
            let questionItemArray = [];
            for (let i = 0; i < sectionQuestion.conditionals.length; i++) {
              if (
                sectionQuestion.conditionals[i].conditionalQuestions &&
                sectionQuestion.conditionals[i].conditionalQuestions.length
              ) {
                if (
                  sectionQuestion.conditionals[i].conditionalQuestions.length
                ) {
                  sectionQuestion.conditionals[i].conditionalQuestions.forEach(
                    (question) => {
                      if (
                        question.Answer &&
                        sectionQuestion.name !== "marital-status"
                      ) {
                        conditionalQuestion = formQuestionObj(
                          question,
                          sectionObj
                        );
                        questionItemArray.push(conditionalQuestion);
                      } else if (sectionQuestion.name === "marital-status") {
                        if (
                          question.label &&
                          question.label.includes(sectionQuestion.Answer)
                        ) {
                          conditionalQuestion = formQuestionObj(
                            question,
                            sectionObj
                          );
                          questionItemArray.push(conditionalQuestion);
                        }
                      }
                    }
                  );
                }
              }
            }
            const outerQuestion = formQuestionObj(
              sectionQuestion,
              sectionObj
            );
            questionItemArray.unshift(outerQuestion);
            sectionQuestionArray = questionItemArray;
          } else {
            if(sectionQuestion.type === "repeatableQuestion" && sectionQuestion.Answer && sectionQuestion.Answer.length > 1 ){
              let questionItemArray = [];
              sectionQuestion.Answer.map((item,i) => {
                let questionItem = _.cloneDeep(sectionQuestion)
                questionItem.Answer = [item];
                let questionItemObj = formQuestionObj(questionItem);
                if(!_.isEmpty(questionItemObj)){
                  questionItemArray.push(questionItemObj)
                }
              }) 
              sectionQuestionArray = questionItemArray;
            }else{
              sectionQuestionObj = formQuestionObj(sectionQuestion);
            }
            
          }
          
          return  sectionQuestionArray ? sectionQuestionArray : sectionQuestionObj;
        });

    const summaryObj = {
      sectionId: sectionObj.id,
      header: sectionObj.header,
      title: sectionObj.title,
      items: summaryQuestions,
      completed: sectionObj.completed && sectionObj.completed
    };

    //final summary array
    if (sectionObj.subsections && sectionObj.subsections.length) {
      summaryObj["subsections"] = formSummaryObj(sectionObj.subsections);
    }
    if (sectionObj.id !== "noncitizen" && sectionObj.id !== "summary" && sectionObj.id !== "sign-and-submit" && sectionObj.id !== "expedited-snap") {
      summaryData.push(summaryObj);
    }
  });
  return summaryData;
};

const getFormattedLabel = (label) => {
  let _label = "";
  if (typeof label === "string") {
    _label = label;
  } else if (typeof label === "object") {
    if (Array.isArray(label) && (label[0].helpText || label[0].textBefore)) {
      const helpText = `${label[0].textBefore} ${label[0].triggerText} ${label[0].textAfter}`;
      _label = helpText;
    }else if (label.helpText || label.textBefore) {
      const helpText = `${label.textBefore} ${label.triggerText} ${label.textAfter}`;
      _label = helpText;
    }
  } else {
    _label = "-";
  }
  return _label || "-";
};

const getFormattedValue = (value, type = "") => {
  let _value = "";
  if (typeof value === "string") {
    if(summaryCodeMapper(selectedLanguage).hasOwnProperty(value)){
      _value = summaryCodeMapper(selectedLanguage)[value];
    } else if (type === "inputPhone" && value) {
      _value =  formatPhoneNumber (value);
    } else if (type === "inputSSN" && value) {
      _value =  value.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
    } else{
    _value = value;
    }
  } else if (typeof value === "object") {
    if (type === Types.ADDRESS) {
      const { firstLine, secondLine } = address(value);
      _value = (firstLine !== "" && firstLine !== "undefined" && secondLine !== "-" && secondLine !== "undefined") ?  `${firstLine}   ${secondLine}` : "-";
    } else if (type === Types.CHECKBOX) {
      if (Array.isArray(value)) {
        _value = value.join(", ");
      }
    } else if(type === Types.REPEATABLE_QUESTION) {
      if(Array.isArray(value) && value.length && value.length>1) {
          _value = value.map((oneVal)=>{
            if(typeof oneVal.amount === "object" && oneVal.amount.length > 1){
              return oneVal.amount.map((oneAmt)=>{
                return  oneAmt ? `$ ${oneAmt} ${getExpenseFrequencyLabel(oneVal.unit)} ` : ""
              }).filter(n => n).join(', ')
            } else {
              return  oneVal.amount[0] ? `$ ${oneVal.amount[0]} ${getExpenseFrequencyLabel(oneVal.unit)} ` : ""
            }
          }).filter(n => n).join(', ')
          _value = _value ? _value : value[0].unit ? `${getExpenseFrequencyLabel(value[0].unit)}` : " - ";
      }else if(Array.isArray(value) && value.length && typeof value[0].amount !== "object" ){
        _value = value[0].amount ? `$ ${value[0].amount} ${getExpenseFrequencyLabel(value[0].unit)}` : " - "
      } else if(Array.isArray(value) && value.length && typeof value[0].amount === "object" ){
        if(typeof value[0].amount === "object" && value[0].amount.length > 1){
          _value = value[0].amount.map((oneAmt)=>{
            return  oneAmt ? `$ ${oneAmt} ${getExpenseFrequencyLabel(value[0].unit)} `: ""
          }).filter(n => n).join(', ')
          _value = _value ? _value : value[0].unit ? `${getExpenseFrequencyLabel(value[0].unit)}` : " - ";
        } else {
          _value = value[0].amount[0] ? `$ ${value[0].amount[0]} ${getExpenseFrequencyLabel(value[0].unit)} ` : value[0].unit ? `${getExpenseFrequencyLabel(value[0].unit)}` : " - "
        }
      }
    }  else if(type === Types.UNITS){
      _value = value.amount ? `$ ${value.amount} ${getExpenseFrequencyLabel(value.unit)}` : value.unit ? `${getExpenseFrequencyLabel(value.unit)}` : "-";
    }
  } else {
    _value = "-";
  }
  return _value || "-";
};

const getCheckboxGroupDisplayText = (options, selectedValues,rootQuestion) => {
  let displayTextArray=[];
  if(rootQuestion && rootQuestion.id === "household-information" && rootQuestion.memberOptions){
        let members = rootQuestion && rootQuestion.memberOptions.length ? rootQuestion.memberOptions : [];
        displayTextArray = selectedValues.map((value) => {
          const matchedOption = members[Number(value)-1];
          if (matchedOption) {
            return matchedOption;
          } else {
            return "";
          }
        });
  }else{
    if(typeof options[0] !== "object") {
      displayTextArray = selectedValues.map((value) => {
        const matchedOption = options.find(
          (option) => option === value
        );
        if (matchedOption) {
          return matchedOption;
        } else {
          return "";
        }
      });
    } else {
      displayTextArray = selectedValues.map((value) => {
        const matchedOption = options.find(
          (option) => option.optionValue === value
        );
        if (matchedOption) {
          return matchedOption.optionDisplay;
        } else {
          return "";
        }
      });
    }
 
  }
  return displayTextArray.join(", ");
};

export function getExpenseFrequencyLabel (frequency) {
  switch (frequency) {
      case 'WEEK':
      case 'Week': 
      case 'Weekly':
      case 'Semanalmente': 
      case 'Semana':
          return consumerLabels.consumerConst(selectedLanguage).weeklyFrequencyLabel;
      case 'MONTH':
      case 'Month':
      case 'Monthly':  
      case 'Mensualmente':
      case 'Mes':
      case 'Mensual':
          return consumerLabels.consumerConst(selectedLanguage).monthlyFrequencyLabel;
      case 'ANNUAL':
      case 'Annual':
      case 'Anualmente':
      case 'Año':
      case 'Anual':
          return consumerLabels.consumerConst(selectedLanguage).annualFrequencyLabel;
      case 'BIWEEK':
      case 'Biweekly (every two weeks)': 
      case 'Biweekly(every two weeks)':
      case 'Quincenalmente (cada dos semanas)':
      case 'Bisemanal (cada dos semanas)':
          return consumerLabels.consumerConst(selectedLanguage).biweeklyFrequencyLabel;
      case 'BIMON':
      case 'Bimonthly (every two months)':
      case 'Bimonthly(every two months)':
      case 'Bimestralmente (cada dos meses)':
      case 'Bimestral (cada dos meses)':
          return consumerLabels.consumerConst(selectedLanguage).bimonthlyFrequencyLabel;    
      case 'QRTRLY':
      case 'Quarterly': 
      case 'Trimestralmente':  
      case 'Trimestral':
          return consumerLabels.consumerConst(selectedLanguage).quarterlyFrequencyLabel;
      case 'SEMANL':
      case 'Semiannual (twice a year)':
      case 'Semi-annual(Twice a year)':
      case 'Bianual (dos veces al año)':
      case 'Semestral (dos veces al año)':
          return consumerLabels.consumerConst(selectedLanguage).semiannualFrequencyLabel;
      case 'SEMMON':
      case 'Semimonthly (twice a month)':
      case 'Semi-monthly(Twice a month)':
      case 'semi-mensal (dos veces al mes)':
      case 'Quincenal (dos veces al mes)':
          return consumerLabels.consumerConst(selectedLanguage).semimonthlyFrequencyLabel; 
      case 'Year':  
      case 'YEAR':  
        return consumerLabels.consumerConst(selectedLanguage).yearFrequencyLabel;     
      default:
          return ''
  }
}
 
export function getSummaryFrequencyLabel(language = null) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)",
        YEAR: "Year",
      };
    case languageConsts.SPANISH:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)",
        YEAR: "Year",
      };
    case languageConsts.PORTUGUESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
      };
    case languageConsts.CHINESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)"
      };
    case languageConsts.VIETNAMESE:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)"
      };
    case HI:
      return {
        ANNUAL: "Year",
        BIMON: "Bimonthly (every two months)",
        BIWEEK: "Biweekly (every two weeks)",
        MONTH: "Month",
        QRTRLY: "Quarter",
        WEEK: "Week",
        SEMANL: "Semiannual (twice a year)",
        SEMMON: "Semimonthly (twice a month)"
      };
  }
}

const summaryCodeMapper = (language = null) => {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        HGHSCH: "High School",
        COLLGE: "College",
        SPOUSE: "Spouse",
        MO: "Mother",
        FA: "Father",
        DAUGH: "Daughter",
        SON: "Son",
        BROTH: "Brother",
        SIST: "Sister",
        GRMOT: "GrandMother",
        GRFATH: "Grandfather",
        GRDAU: "Granddaughter",
        GRSON: "Grandson",
        OTHREL: "Other",
      }
    case languageConsts.SPANISH: 
      return {
        HGHSCH: "Escuela secundaria",
        COLLGE: "Colega",
        SPOUSE: "Cónyuge",
        MO: "Madre",
        FA: "Padre",
        DAUGH: "Hija",
        SON: "Hijo",
        BROTH: "Hermano",
        SIST: "Hermana",
        GRMOT: "Abuela",
        GRFATH: "Abuelo",
        GRDAU: "Nieta",
        GRSON: "Nieto",
        OTHREL: "Otro",
      }
    default:
      return {
        HGHSCH: "High School",
        COLLGE: "College",
        SPOUSE: "Spouse",
        MO: "Mother",
        FA: "Father",
        DAUGH: "Daughter",
        SON: "Son",
        BROTH: "Brother",
        SIST: "Sister",
        GRMOT: "GrandMother",
        GRFATH: "Grandfather",
        GRDAU: "Granddaughter",
        GRSON: "Grandson",
        OTHREL: "Other",
      }
    }
}

//Comparison two array of objects
export function compareArrayOfObjects(firstArrayOfObjects, secondArrayOfObjects){
  return (
    firstArrayOfObjects && secondArrayOfObjects && firstArrayOfObjects.length === secondArrayOfObjects.length &&
    firstArrayOfObjects.every((element_1) =>
      secondArrayOfObjects.some((element_2) =>
        Object.keys(element_1).every((key) => element_1[key] === element_2[key])
      )
    )
  );
}
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function sanitizeURL(url) {
  if(url.startsWith('https:') || url.startsWith('http:') || url.startsWith('#') || url.startsWith('tel:')) {
    return url;
  }
  else {
    return '';
  }
}