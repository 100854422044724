import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import ApplySection from "../applySnap/components/applySnapSection";
import sections from "../applySnap/applySnapSectionsMapData";
import {
  toValidateInputErros,
  bindAnswers,
} from "./components/screeningInputInfo";

import { renderScreeningResults } from "./components/screeningResult";
// Redux implementations
import { connect } from "react-redux";
import {
  getScreeningQuestions,
  updateResolveQuestionsData,
  updateScreeningQuestions,
} from "../../redux/screening/screeningActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import { screeningStaticLabels } from "./screeningText";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import Modal from "../../utils/components/modal/dtaModal"; 
import { beforeYoustartMlanguageLabels } from "../applySnap/applySnapText";
import { withMsal } from "@azure/msal-react";
import { loginRequest, signUp,registerUser } from "config/appConfig";
import { setCookie } from "../../utils/helper/cookie";
// screening specific configuration
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "screening",
  // The ID of the section last in progress.
  inProgressSectionId: "screening-results",
};
const EN = "en-US";
const ES = "es-ES";
/* NOTE
 * There are many 'key' properties used in this screening area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class Screening extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);

    this.renderScreeningQuestions = this.renderScreeningQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.renderCounter = 0;
    this.nonRenderCounter = 0;
    this.state = {
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      getScreeningResultsData: [],
      AnswerObject: [],
      isAnonymousModeModalOpen: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    let check =
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      this.props.match.params.webAppId !== undefined;
    if (check === TextProps.VALUE_TRUE) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }

    return TextProps.VALUE_TRUE;
  }
  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.screening.sections,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(pageConfig.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
  }

  //loading screening sections questions from Redux questions JSON
  componentDidMount() {
    this.props.getScreeningQuestions();
  }

  // In this lifecycle hook, use the ref of the application container to reset the scroll when the page changes.
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      helperFunction._animatedScroll();
    }
  }

  onSectionChange = (section, event = null) => {
    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
        });
      }
    );
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  _bindAnswerObject = (answer) => {
    this.setState({
      AnswerObject: answer,
    });
  };
  //Primary Continue Click
  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;

    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      let isValid = toValidateInputErros(dataSections, activeId);
      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
          this.props.updateScreeningQuestions(dataSections);
        }
        return;
      } else {
        const nextFirstSectionId = getNextSection(
          activeId,
          this.props.screening.sections
        );
        if (nextFirstSectionId) {
          const nextSecondSectionId = getNextSection(activeId, sections);
          if (nextSecondSectionId) {
            const nextSection = this._getSection(nextSecondSectionId);
            this.setState(
              {
                directionClass: "forward",
              },
              () => {
                this.onSectionChange(nextSection);
              }
            );
          }
          this.setState({
            getScreeningResultsData: [dataSections],
          });
        }
      }
    }
    if(activeId === "screening-results"){
      this.setState({isAnonymousModeModalOpen : true });
      sessionStorage.setItem("applicationType", "SNAP");
      sessionStorage.setItem("applicationInanonymousMode", true);
    }

    // Dispatch the current section
    this.props.updateResolveQuestionsData(this.props.screening);
  };

  renderScreeningResults = () => {
    const outputObj = this.state.getScreeningResultsData;
    return <div>{renderScreeningResults(outputObj)}</div>;
  };
  // This function is related to simple, screening specific 'routing' between sections.
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.screening;
    const previousSectionId = getPreviousSection(activeId, sections);
    if (previousSectionId) {
      const previousSection = this._getSection(previousSectionId);
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    } else {
      // this block willl work redirect/ navigation to home page
      window.location.assign(routeProps.HOME_PATH);
    }
  };

  // This function is related to simple, screening specific 'routing' between sections.
  getPrimaryActionLabel = (sectionId) => {
    switch (sectionId) {
      case "screening":
        return screeningStaticLabels(selectedLanguage).getMyResult;
      case "screening-results":
        return screeningStaticLabels(selectedLanguage).applynow;
      default:
        return screeningStaticLabels(selectedLanguage).applynow;
    }
  };
  getUserSelectedLanguage = () => {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    let language = "en";
    if (selectedLanguage === EN) {
      language = "en";
    } else if (selectedLanguage === ES) {
      language = "es";
    }
    else {
      language = "en";
    }
    return language;
  };
  onCreateAccount = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const signUpReq = {
      ...registerUser,
      extraQueryParameters: {
        ...registerUser.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns.loginRedirect(signUpReq);
  };

  _handleAzureLogin = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const loginReq = {
      ...loginRequest,
      extraQueryParameters: {
        ...loginRequest.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns
      .loginRedirect(loginReq)
      .then()
      .catch(() => {
      });
  };
  redirectToAzureLogin = () => {
    sessionStorage.clear();
    this.clearCookies();
    this._handleAzureLogin(this.props.msalContext.instance);
  };
  clearCookies = () => {
    setCookie("isLoginFailed", "");
    setCookie("isUserSignedUp", "");
    setCookie("isUserResetPassword", "");
    setCookie("isForgotUserName", false);
    setCookie("usernameRecoveryError", false);
    setCookie("RecoveredUserName", "");
    setCookie("isLoginFailedDueToUnlinkAc","")
  };
  // screening implementation for rendering screening section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <ApplySection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            isPreScreening = {pageConfig.screening}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </ApplySection>
        </div>
      </CSSTransition>
    );
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the screening page.
  // Meant strictly for screening display purposes.
  getSectionBody = (sectionId) => {
    if (sectionId === "screening-results") return this.renderScreeningResults();
    return <div>Something went wrong</div>;
  };

  // This is the main markup for the screening layout
  render() {
    const { sections } = this.props.screening;
    if (sections.length > 0) {
      return this.renderScreeningQuestions(sections);
    } else {
      return <Loading />;
    }
  }

  renderScreeningQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      return (
        <div className="full-height apply">
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            inProgressSectionId={this.state.inProgressSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            onSaveProgress={this.presentSaveProgressModal}
            canSaveProgress={TextProps.VALUE_FALSE}
            skipToAnchor="#content"
            isPreScreening = {pageConfig.screening}
          />
          <main className="apply__content-container" id="content" tabIndex="-1">
            <LanguageSelect className="language-select--dark" />
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
          </main>
           {this.renderApplicationInanonymousMode()}
        </div>
      );
    }
  }
  // Render a isHomeless Modal
  renderApplicationInanonymousMode = () => {
    const msalInstance = this.props.msalContext.instance;
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    return (
      <Modal
      isOpen={this.state.isAnonymousModeModalOpen}
      onClose={() =>
        this.closeApplicationInanonymousMode()
      }
      modalClass="anonymous-modal"
      titleText="homeless Warning"
      headerText={
        beforeYoustartMlanguageLabels(selectedLanguage).applyForSnapLabel
      }
      >
        <div className="dta-modal__body pad-all anonymous-mode-container">
          <div>
           <div>{beforeYoustartMlanguageLabels(selectedLanguage).applyForSnapContent}</div>
           <div className="top-content">
             <div className="pure-u-5-12 pure-u-lg-5-12">{beforeYoustartMlanguageLabels(selectedLanguage).renderApplyForSnapcol1Content()}</div>
             <div className="pure-u-7-12 pure-u-lg-7-12">{beforeYoustartMlanguageLabels(selectedLanguage).renderApplyForSnapcol2Content()}</div>
           </div>
          </div>
          <p> </p>
          <div className="footer-content">
            <div className="pure-u-1 pure-u-lg-1-2 create-account">
              <button
                className="dta-button dta-button--primary"
                onClick={() =>
                  this.onCreateAccount(msalInstance)
                }
              >
                 {beforeYoustartMlanguageLabels(selectedLanguage).createAccountLabel}
              </button>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 login-btn">
              <button
                className="dta-button dta-button--outline-secondary"
                onClick={() =>
                  this.redirectToAzureLogin()
                }
              >
                 {beforeYoustartMlanguageLabels(selectedLanguage).loginLabel}
              </button>
            </div>
          </div>
          <p> </p>
          <div className="footer-link">
           <span>{beforeYoustartMlanguageLabels(selectedLanguage).clickHereLabel1}</span>
           <a 
            href="/apply" 
            id="clickhere"
            onClick={() => {
              this.clickHereToAnon();
            }}
            className="text--center display-block spc"
           >{" click here "}</a>
           <span>{beforeYoustartMlanguageLabels(selectedLanguage).clickHereLabel2}</span>
          </div>
        </div>
      </Modal>
      );
  };
  clickHereToAnon = () => {
    this.closeApplicationInanonymousMode();
    this.applyHandler("SNAP");
    window.location.assign("/apply");
    sessionStorage.setItem("applicationInanonymousMode", true);
  }

  closeApplicationInanonymousMode = () => {
    this.setState({isAnonymousModeModalOpen : TextProps.VALUE_FALSE});
  }
  // This function is related to simple, specific 'routing'
  // between sections. Not intended for production
  _getSection(sectionId) {
    let targetSection = {};

    _.some(sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getScreeningQuestions: getScreeningQuestions,
      updateResolveQuestionsData: updateResolveQuestionsData,
      updateScreeningQuestions: updateScreeningQuestions,
    },
    dispatch
  );
}

export default withMsal(connect(mapStateToProps, mapDispatchToProps)(Screening));
