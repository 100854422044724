import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ConsumerApp from "./components/consumerApp";
import * as routeProps from "../../utils/constants/routesConstants";
class ConsumerContainer extends Component {
  render() {
    return (
      <Router basename={routeProps.CONSUMER_PATH}>
        <ConsumerApp />
      </Router>
    );
  }
}

export default ConsumerContainer;
