import * as languageConsts from "../../utils/constants/types";
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function earnedIncomeCodeMapper(language) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        AMRCRP: "Americorps OJT",
        FEDWRK: "Federal Work Study",
        FEP: "Full Employment Income",
        JTPA: "JTPA",
        NONFED: "Non-Federal Work Study",
        SELEMP: "Self-Employment",
        SHELWS: "Sheltered Workshop",
        STUDNT: "Student Earnings",
        SUMYTH: "Summer Youth E & T",
        // WAGES: "Wages",
        YTHBLD: "Youthbuild",
        WAGES: "Job Income",
        BOARD: "Room and Board Income"
      };
    case languageConsts.SPANISH:
      return {
        AMRCRP: "Capacitación en el trabajo de Americorps",
        FEDWRK: "Programa federal de estudio-trabajo",
        FEP: "'Ingresos por empleo de tiempo completo",
        JTPA: "JTPA",
        NONFED: "Programa de estudio-trabajo no federal",
        SELEMP: "Trabajo independiente",
        SHELWS: "Taller de inmersión",
        STUDNT: "Ingresos de estudiante",
        SUMYTH: "Educación y capacitación para jóvenes en verano",
        WAGES: "Salarios",
        YTHBLD: "Youthbuild",
        JOBINC: "Ingresos laborales",
        BOARD: "Ingresos por alojamiento y comida"
      };
    case languageConsts.PORTUGUESE:
      return {
        AMRCRP: "Americorps OJT",
        FEDWRK: "Estudo de trabalho federal",
        FEP: "Renda pleno emprego",
        JTPA: "JTPA",
        NONFED: "Estudo de trabalho não federal",
        SELEMP: "Trabalho autônomo",
        SHELWS: "Workshop protegido",
        STUDNT: "Ganhos do estudante",
        SUMYTH: "Entretenimento e viagem de jovens no verão",
        WAGES: "Salário",
        YTHBLD: "Youthbuild",
      };

    case languageConsts.CHINESE: //chinees
      return {
        AMRCRP: "美国志愿队 OJT",
        FEDWRK: "联邦工作补助金",
        FEP: "全职工作收入",
        JTPA: "JTPA",
        NONFED: "非联邦工作补助",
        SELEMP: "个体经营",
        SHELWS: "庇护工场",
        STUDNT: "学生收入",
        SUMYTH: "Summer Youth E & T",
        WAGES: "工资",
        YTHBLD: "Youthbuild",
      };
    case languageConsts.VIETNAMESE:
      return {
        AMRCRP: "Americorps OJT",
        FEDWRK: "Vừa học vừa làm liên bang",
        FEP: "Thu nhập từ việc làm toàn thời gian",
        JTPA: "JTPA",
        NONFED: "Vừa học vừa làm phi liên bang",
        SELEMP: "Tự làm chủ",
        SHELWS: "Nhà xưởng có mái che",
        STUDNT: "Thu nhập của sinh viên",
        SUMYTH: "Summer Youth E & T",
        WAGES: "Lương",
        YTHBLD: "Youthbuild",
      };
    case HI:
      return {
        AMRCRP: "Americorps OJT",
        FEDWRK: "Vừa học vừa làm liên bang",
        FEP: "Thu nhập từ việc làm toàn thời gian",
        JTPA: "JTPA",
        NONFED: "Vừa học vừa làm phi liên bang",
        SELEMP: "Tự làm chủ",
        SHELWS: "Nhà xưởng có mái che",
        STUDNT: "Thu nhập của sinh viên",
        SUMYTH: "Summer Youth E & T",
        WAGES: "Salè",
        YTHBLD: "Youthbuild",
      };
    default:
      return;
  }
}
