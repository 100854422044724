import * as Props from "../../utils/components/shared";
import * as languageConstants from "../../utils/constants/constants";
import * as Types from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function ReportChangePageLabels(
  language,
  pageType,
  name,
  caseManagerPhone
) {
  switch (pageType) {
    case languageConstants.aboutMe: {
      switch (language) {
        case EN:
          return {
            title: "About me",
            header: "About me",
            labelFirstName: "What is your first name?",
            firstNameErrMsg: "Required - Please enter first name",
            middleName: "What is your middle name?",
            middleNameErrMsg: "Please enter middle name",
            lastNamelabel: "What is your last name?",
            lastNameErrMsg: "Required - Please enter last name",
            labelSuffix: "Suffix",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "What is your date of birth?",
            dobAlertMsg: "Please enter a valid Date of Birth",
            ssnLabel: "What is your Social Security Number?",
            inputClearLabel: "I don't have one",
            ssnErrMsg: "Please enter a valid Social Security Number.",
            headerMyContactInfo: "My contact info",
            houselessLabel: "Is your household homeless?",
            houselessErrMsg: "Please select whether your having home or not",
            houseLessOptions: ["Yes", "No"],
            addrQuestionlabel: "What is your home address?",
            validZipcode: "Please enter a valid Zipcode.",
            alsoMailingLabel: "Is this also your mailing address?",
            optionsSendMail: "Yes, send mail there.",
            optionsNoDiffAddress: "No, I use a different address for mail.",
            mailOptions: [
              "Yes, send mail there.",
              "No, I use a different address for mail.",
            ],
            mhProviderMemberSearch: ["First Name / Last Name", "Zip Code"],
            agedOver60: ["Aged over 60"],
            mailingQlabel: "What is your mailing address?",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            masZipCodeErrmsg: `If you moved outside of Alabama, please call ${caseManagerPhone}.`,
            citiErrmsg: "Please enter a valid City.",
            addressErrmsg: "Please enter a valid address.",
            bestPhoneqlabel: "What is the best phone number to reach you?",
            inlineHelp: "This is the number the DHR will use to reach you.",
            validPhoneAlert: "Please enter a valid phone number.",
            emailAddressLabel: "What is your email address?",
            emailErrormsg: "Please enter a valid email address.",
            titleEmergency: "Emergency SNAP benefits",
            headerEmergency: "Emergency SNAP benefits",
            emergencySnap:
              "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
            incomeMoneylabel:
              "Does your income and money in the bank add up to less than your monthly housing expenses?",
            inlineHelpLabel:
              "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
            optionYesNo: ["Yes", "No"],
            alertSelectIncome: "Please select income you pay",
            incomeLesslabel:
              "Is your monthly income less than $150 and is your money in the bank $100 or less?",
            monthlyIncomeoptions: ["Yes", "No"],
            selectIncomeErrmsg: "Please select income you pay",
            migrantBefore: "Are you a ",
            migrantTrigger: "migrant worker",
            migrantAfter: " and is your money in the bank $100 or less?",
            migrantHelp:
              "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
            migrantOptions: ["Yes", "No"],
            migrantErrmsg: "Please select migrant you pay",
            headerMoreAbout: "More about me",
            areUsBefore: "Are you a ",
            areUsTrigger: "US citizen",
            areUsafter: "?",
            eligibleHelp:
              "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            chooseErrmsg: "Please choose citizen",
            eligibleMemberHelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            eligibleOptions: ["Yes", "No"],
            laguageBefore: "What language do you ",
            LANGUAGES: [
              "English",
              "Spanish",
              "Portuguese",
              "French",
              "Haitian Creole",
              "Chinese",
              "Vietnamese",
              "Khmer",
              "Korean",
              "Russian",
              "Italian",
              "Polish",
              "Arabic",
            ],
            languageTrigger: "prefer to speak",
            languageAfter: "?",
            comfortLanHelp:
              "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
            languageErrMsg: "Please choose language",
            genderLabel: "What is your gender?",
            genderOptions: ["Male", "Female"],
            genderErrmsg: "Please choose gender",
            labelEthnicity: "What is your ethnicity?",
            helpEthnicity:
              "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "What is your race?",
            raceHelp:
              "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            optionAIdisplay: "American Indian",
            optionAsiandisplay_VALUE: "ASIAN",
            optionAsiandisplay: "Asian",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Black/African American",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Pacific Islander",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "White",
            declineToAnswerLabel: "Decline to answer",
            tripCountErrMsgLen:
              "Please enter less than/equal to 6 characters for trip count",
            didNotMakeChange:"You did not make any changes to the existing information.",
            clickGoBack:"Click Ok to go back to the home screen. Click Cancel if changes are needed.",
            dataChangeWarning:"Your changes have not been submitted.",
            areYouSure:"Are You Sure ?",
            tryAgain:"Please try again later.",
            pregnancySuccessMessage:"Thank you for reporting your pregnancy details. This change will be effective once the change request has been approved by the Case Worker.",
            disabilitySuccessMessage:"Thank you for reporting your Disability/Incapacity details.This change will be effective once the change request has been approved by the Case Worker.",
            attendanceSuccessMessage:"Thank you for reporting your School Attendance details.This change will be effective once the change request has been approved by the Case Worker."
          };

        case ES:
          return {
            title: "Información de contacto",
            header: "Mi información de contacto",
            addContactInfoTitle: "Cambiar mi dirección",
            addContactInfoHeader: "Cambiar mi dirección",
            contactChangedQuestion:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",
            required: "Obligatorio",
            requiredErrorMessage: "Escoja una opción",
            addContactInfoSubHeader:
              'Para presentar un Informe provisional del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de la Fuente de alimentos de Project Bread al<a href="tel:18006458333">1-800-645-8333</a> o diríjase a la oficina local de DHR.',
            labelFirstName: "¿Cuál es su nombre?",
            firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            middleName: "¿Cuál es su segundo nombre?",
            middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            lastNamelabel: "¿Cuál es su apellido?",
            lastNameErrMsg: "Requerido: ingrese el apellido",
            labelSuffix: "Sufijo",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
            resSameAddressLabel: "Igual que el domicilio",
            dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
            ssnLabel: "¿Cuál es su número de seguro social?",
            inputClearLabel: "No tengo",
            ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
            headerMyContactInfo: "Mi información de contacto",
            houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
            resAddressLabel: "Dirección residencial",
            mailingAddresssLabel: "Dirección postal",
            houselessErrMsg: "Seleccione si tiene o no su hogar",
            houseLessOptions: ["Sí", "No"],
            addrQuestionlabel: "¿Cuál es su dirección?",
            addressErrmsg: "Por favor, ingrese una dirección válida",
            validZipcode: "Por favor ingrese un código postal válido",
            alsoMailingLabel: "¿Es igual a su dirección postal?",
            optionsSendMail: "Sí, enviar correo allí. ",
            optionsNoDiffAddress:
              "No, utilizo una dirección diferente para el correo postal.",
            mailOptions: [
              "Sí, enviar correo allí.",
              "No, utilizo una dirección diferente para el correo postal.",
            ],
            mailingQlabel: "¿Cuál es su dirección postal?",
            zipCodeErrmsg: "Por favor ingrese un código postal válido",
            masZipCodeErrmsg: `Si se mudó fuera de Massachussetts, llame ${caseManagerPhone}.`,
            citiErrmsg: "Por favor introduzca una ciudad válida.",
            bestPhoneqlabel:
              "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
            inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
            validPhoneAlert: "Por favor ingrese un número de teléfono válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Listo",
            phoneEditTitle: "Editar los detalles de mi teléfono",
            phoneLabel: "Teléfono principal",
            phoneNotificationQuestion:
              "¿Permitir notificaciones por mensaje de texto?",
            phoneNotificationAgreeLabel:
              "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailEditTitle: "Dirección de correo electrónico",
            emailLabel: "Editar mi dirección de correo electrónico",
            emailNotificationQuestion:
              "Permitir notificaciones por correo electrónico?",
            emailNotificationAgreeLabel:
              "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
            emailErrormsg:
              "Por favor, introduce una dirección de correo electrónico válida",
            titleEmergency: "Beneficios de SNAP de emergencia",
            headerEmergency: "Beneficios de SNAP de emergencia",
            emergencySnap:
              "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
            incomeMoneylabel:
              "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
            inlineHelpLabel:
              "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
            optionYesNo: ["Sí", "No"],
            alertSelectIncome: "Por favor seleccione el ingreso que paga",
            incomeLesslabel:
              "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
            monthlyIncomeoptions: ["Sí", "No"],
            selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
            migrantBefore: "¿Es usted un ",
            migrantTrigger: " trabajador migrante",
            migrantAfter: " y su dinero en el banco es $100 o menos?",
            migrantHelp:
              "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
            migrantOptions: ["Sí", "No"],
            migrantErrmsg: "Por favor selecciona migrante pagas",
            headerMoreAbout: "Más sobre mí",
            areUsBefore: "¿Es usted ",
            areUsTrigger: "ciudadano de EE. UU.",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            chooseErrmsg: "Por favor elija ciudadano",
            eligibleMemberHelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            eligibleOptions: ["Sí", "No"],
            LANGUAGES: [
              "Inglés",
              "Español",
              "Portugués",
              "Francés",
              "Criollo haitiano",
              "Chino",
              "Vietnamita",
              "Camboyano",
              "Coreano",
              "Ruso",
              "Italiano",
              "Polaco",
              "Árabe",
            ],
            laguageBefore: "¿Qué idioma ",
            languageTrigger: " prefiere hablar",
            languageAfter: "?",
            comfortLanHelp:
              "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
            languageErrMsg: "Por favor, elija el idioma",
            genderLabel: "¿Cuál es su sexo?",
            genderOptions: ["Masculino", "Femenino"],
            genderErrmsg: "Por favor elija género",
            labelEthnicity: "¿Cuál es tu origen étnico?",
            helpEthnicity:
              "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            hisPanicOption: "Hispano/Latino",
            nonhisPanicOption: "No hispano/Latino",
            raceLabel: "¿Cuál es tu raza?",
            raceHelp:
              "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            optionAIdisplay: "Indio americano",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afroamericano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Isleño del Pacífico",
            optionwhitedisplay_VALUE: "Blanco",
            optionwhitedisplay: "Blanco",
            declineToAnswerLabel: "Me niego a responder",
            tripCountErrMsgLen:
              "Ingrese 6 caracteres como máximo por conteo de desconexiones",
              didNotMakeChange:"No realizó ningún cambio en la información existente.",
              clickGoBack:"Haga clic en Aceptar para volver a la pantalla de inicio.",
              dataChangeWarning:"¿Está seguro? Sus cambios no han sido enviados. Haga clic en Aceptar para volver a la pantalla de inicio.",
              areYouSure:"Estas seguro",
              tryAgain:"Por favor, inténtelo de nuevo más tarde.",
              pregnancySuccessMessage:"Gracias por informar los detalles de su embarazo. Este cambio será efectivo una vez que la solicitud de cambio haya sido aprobada por el asistente social.",
              disabilitySuccessMessage:"Gracias por informar los detalles de su discapacidad/incapacidad. Este cambio entrará en vigor una vez que el trabajador social haya aprobado la solicitud de cambio.",
              attendanceSuccessMessage:"Gracias por informar los detalles de su asistencia a la escuela. Este cambio entrará en vigor una vez que el trabajador social haya aprobado la solicitud de cambio."
          };

        case PT: //portugeesh
          return {
            title: "Dados de contato",
            header: "Minhas informações de contato",
            addContactInfoTitle: "Alterar meu endereço",
            addContactInfoHeader: "Alterar meu endereço",
            contactChangedQuestion:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
            required: "Obrigatório",
            requiredErrorMessage: "Por favor escolha uma opção",
            addContactInfoSubHeader:
              'Para enviar um Relatório Interino SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para a Linha Direta do projeto Project Bread Food Source no número <a href="tel:18006458333">1-800-645-8333</a> ou acesse o escritório local do DHR.',
            labelFirstName: "Qual é o seu primeiro nome?",
            firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
            middleName: "Qual é o seu nome do meio?",
            middleNameErrMsg: "Digite o nome do meio",
            lastNamelabel: "Qual é o seu último nome?",
            lastNameErrMsg: "Obrigatório - Digite o sobrenome",
            labelSuffix: "Sufixo",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "Qual é a sua data de nascimento?",
            dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
            ssnLabel: "Qual é o número do seu seguro social?",
            inputClearLabel: "Eu não tenho",
            ssnErrMsg: "Digite um Número de Segurança Social válido",
            headerMyContactInfo: "Minhas informações de contato",
            houselessLabel: "Sua família não tem moradia?",
            resAddressLabel: "Endereço residencial",
            resSameAddressLabel: "O mesmo endereço residencial",
            mailingAddresssLabel: "Endereço de correspondência",
            houselessErrMsg: "Selecione se você tem casa ou não",
            houseLessOptions: ["Sim", "Não"],
            addrQuestionlabel: "Qual é o seu endereço?",
            addressErrmsg: "Digite um endereço válido",
            validZipcode: "Por favor insira um código postal válido",
            alsoMailingLabel:
              "Esse também é o seu endereço de correspondência?",
            optionsSendMail:
              "Sim, envie as correspondências para esse endereço. ",
            optionsNoDiffAddress:
              "Não, eu uso um endereço diferente para correspondência.",
            mailOptions: [
              "Sim, envie as correspondências para esse endereço.",
              "Não, eu uso um endereço diferente para correspondência.",
            ],
            mailingQlabel: "Qual é o seu endereço de correspondência? ",
            zipCodeErrmsg: "Por favor insira um código postal válido",
            masZipCodeErrmsg: `Caso tenha se mudado de Massachussets, ligue para ${caseManagerPhone}.`,
            citiErrmsg: "Digite uma cidade válida",
            bestPhoneqlabel:
              "Qual é o melhor número de telefone para falar com você?",
            inlineHelp:
              "Esse será o número que o DHR irá usar para falar com você.",
            validPhoneAlert: "Por favor insira um número de telefone válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Concluir",
            phoneEditTitle: "Editar of detalhes do meu telefone",
            phoneLabel: "Número de telefone principal",
            phoneNotificationQuestion:
              "Permitir notificações por mensagens de texto",
            phoneNotificationAgreeLabel:
              "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
            emailEditTitle: "Endereço de e-mail",
            emailLabel: "Editar of detalhes do meu endereço.",
            emailNotificationQuestion: "Permitir notificações por e-mail?",
            emailNotificationAgreeLabel:
              "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
            emailAddressLabel: "Qual é o seu endereço de e-mail?",
            emailErrormsg: "Por favor insira um endereço de e-mail válido",
            titleEmergency: "Benefício SNAP emergencial",
            headerEmergency: "Benefício SNAP emergencial",
            emergencySnap:
              "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
            incomeMoneylabel:
              "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
            inlineHelpLabel:
              'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
            optionYesNo: ["Sim", "Não"],
            alertSelectIncome: "Selecione a renda que você paga",
            incomeLesslabel:
              "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
            monthlyIncomeoptions: ["Sim", "Não"],
            selectIncomeErrmsg: "Selecione a renda que você paga",
            migrantBefore: "Você é um ",
            migrantTrigger: "trabalhador migrante",
            migrantAfter: " e tem US$ 100 ou menos no banco?",
            migrantHelp:
              "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
            migrantOptions: ["Sim", "Não"],
            migrantErrmsg: "Selecione o migrante que você paga",
            headerMoreAbout: "Mais sobre mim",
            areUsBefore: "Você é ",
            areUsTrigger: "cidadão americano",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            chooseErrmsg: "Escolha cidadão",
            eligibleMemberHelp:
              "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
            eligibleOptions: ["Sim", "Não"],
            laguageBefore: "Em qual idioma você ",
            LANGUAGES: [
              "Inglês",
              "Espanhol",
              "Português",
              "Francês",
              "Créole haitiano",
              "Chinês",
              "Vietnamita",
              "Kmè",
              "Coreano",
              "Russo",
              "Italiano",
              "Polonês",
              "Árabe",
            ],
            languageTrigger: "prefere se comunicar",
            languageAfter: "?",
            comfortLanHelp:
              "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
            languageErrMsg: "Escolha o idioma",
            genderLabel: "Qual é o seu gênero?",
            genderOptions: ["Masculino", "Feminino"],
            genderErrmsg: "Escolha o gênero",
            labelEthnicity: "Qual é a sua etnia?",
            helpEthnicity:
              "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            raceLabel: "Qual é a sua raça?",
            hisPanicOption: "Hispânico/Latino",
            nonhisPanicOption: "Não-Hispânico/Latino",
            raceHelp:
              "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            optionAIdisplay: "Americano nativo",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afro-americano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Nativo das ilhas do Pacífico",
            optionwhitedisplay_VALUE: "Branco",
            optionwhitedisplay: "Branco",
            declineToAnswerLabel: "Não quero responder",
            tripCountErrMsgLen:
              "Digite um valor de medidor com até 6 caracteres",
          };

        case ZH: //China
          return {
            title: "联系信息",
            header: "我的联系信息",
            addContactInfoTitle: "更改我的地址",
            addContactInfoHeader: "更改我的地址",
            contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
            required: "必填",
            requiredErrorMessage: "请选择一个选项",
            resSameAddressLabel: "与居住地址相同",
            addContactInfoSubHeader:
              '如需在线提交 SNAP《中间报告》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请致电面包项目食物来源热线 (Project Bread Food Source Hotline) <a href="tel:18006458333">1-800-645-8333</a> 或前往您当地的 DHR 办事处.',
            labelFirstName: "您的名字",
            firstNameErrMsg: "必填 - 請輸入名字",
            middleName: "您的中间名",
            middleNameErrMsg: "請輸入中間名",
            lastNamelabel: "您的姓氏",
            lastNameErrMsg: "必填 - 請輸入姓氏",
            labelSuffix: "后缀",
            suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
            dateOfBirthLabel: "您的出生日期？",
            dobAlertMsg: "請輸入有效的出生日期",
            ssnLabel: "你的社保号码？",
            inputClearLabel: "我没有社保",
            ssnErrMsg: "請輸入有效的社會安全號碼",
            headerMyContactInfo: "我的联系信息",
            houselessLabel: "您的家庭是否无家可归？",
            houselessErrMsg: "請選擇您是否有家",
            houseLessOptions: ["是", "否"],
            addrQuestionlabel: "您的家庭地址？",
            addressErrmsg: "请输入有效地址",
            validZipcode: "請輸入有效的郵政編碼",
            alsoMailingLabel: "这是否也是您的邮寄地址？",
            optionsSendMail: "是，可以发送信件到这里。",
            optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
            mailOptions: [
              "是，可以发送信件到这里。",
              "否，我的邮箱地址是另外一个。",
            ],
            mailingQlabel: "您的邮箱地址？",
            zipCodeErrmsg: "請輸入有效的郵政編碼",
            masZipCodeErrmsg: `如果您搬出麻省，请致电 ${caseManagerPhone}.`,
            citiErrmsg: "请输入有效城市",
            bestPhoneqlabel: "您最常使用的电话号码？",
            inlineHelp: "这将是DTA与您联络所使用的电话号码。",
            validPhoneAlert: "請輸入一個有效的電話號碼",
            editButtonLabel: "编辑",
            doneButtonlabel: "完成",
            mailingAddresssLabel: "邮寄地址",
            phoneEditTitle: "编辑我的手机详情",
            phoneLabel: "主要电话号码",
            phoneNotificationQuestion: "允许短信通知？",
            phoneNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
            emailEditTitle: "电子邮箱地址",
            emailLabel: "编辑我的电子邮件地址",
            emailNotificationQuestion: "允许电子邮件通知?",
            emailNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
            emailAddressLabel: "您的邮箱地址？",
            emailErrormsg: "請輸入有效的電子郵件地址",
            titleEmergency: "紧急SNAP补助",
            headerEmergency: "紧急SNAP补助",
            emergencySnap:
              "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
            incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
            inlineHelpLabel:
              "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
            optionYesNo: ["是", "否"],
            alertSelectIncome: "請選擇您支付的收入",
            incomeLesslabel:
              "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
            monthlyIncomeoptions: ["是", "否"],
            selectIncomeErrmsg: "請選擇您支付的收入",
            migrantBefore: "您是否是 ",
            migrantTrigger: "外来务工人员",
            migrantAfter: " 您的银行存款是否少于100美元？",
            migrantHelp:
              "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
            migrantOptions: ["是 ", "否"],
            migrantErrmsg: "請選擇你支付的移民",
            headerMoreAbout: "关于我的更多信息",
            areUsBefore: "您是否是 ",
            areUsTrigger: "美国公民",
            areUsafter: "?",
            eligibleHelp:
              "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            chooseErrmsg: "請選擇公民",
            eligibleMemberHelp:
              "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
            eligibleOptions: ["是", "否"],
            resAddressLabel: "住宅地址",
            laguageBefore: "您 ",
            LANGUAGES: [
              "英语",
              "西班牙语",
              "葡萄牙语",
              "法语",
              "海地克里奥尔语",
              "中文",
              "越南语",
              "柬埔寨语",
              "朝鮮的",
              "俄语",
              "意大利语",
              "波兰语",
              "阿拉伯语",
            ],
            languageTrigger: "日常使用哪种语言交流",
            languageAfter: "?",
            comfortLanHelp:
              "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
            languageErrMsg: "請選擇語言",
            genderLabel: "您的性别？",
            genderOptions: ["男", "女"],
            genderErrmsg: "請選擇性別",
            labelEthnicity: "您的民族？",
            helpEthnicity:
              "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            hisPanicOption: "西班牙/拉丁美洲",
            nonhisPanicOption: "非西班牙/拉丁美洲",
            raceLabel: "您的种族？",
            raceHelp:
              "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            optionAIdisplay: "美洲印第安人",
            optionAsiandisplay_VALUE: "亚洲人",
            optionAsiandisplay: "亚洲人",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "黑人/非裔美国人",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "太平洋岛民",
            optionwhitedisplay_VALUE: "白人",
            optionwhitedisplay: "白人",
            declineToAnswerLabel: "拒绝回答",
            tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
          };

        case VI: //Vietnam
          return {
            title: "Thông tin liên lạc",
            header: "Thông tin liên lạc",
            addContactInfoTitle: "Thay đổi địa chỉ của tôi",
            addContactInfoHeader: "Thay đổi địa chỉ của tôi",
            contactChangedQuestion:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
            required: "Bắt buộc",
            resSameAddressLabel: "Giống như địa chỉ nhà ở",
            requiredErrorMessage: "Vui lòng chọn một lựa chọn",
            addContactInfoSubHeader:
              'Để gửi Báo cáo Giữa kỳ SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, xin vui lòng gọi Đường dây nóng của Project Bread Food Source theo sốat <a href="tel:18006458333">1-800-645-8333</a> hoặc đến Văn phòng DHR địa phương của quý vị.',
            labelFirstName: "Tên của quý vị?",
            firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
            middleName: "Tên lót của quý vị?",
            middleNameErrMsg: "Vui lòng nhập tên đệm",
            lastNamelabel: "Họ của quý vị?",
            lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
            labelSuffix: "Tước hiệu",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
            dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
            ssnLabel: "Số an sinh xã hội của quý vị?",
            inputClearLabel: "Tôi không có số ASXH",
            mailingAddresssLabel: "Địa chỉ gửi thư",
            ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            headerMyContactInfo: "Thông tin liên lạc",
            resAddressLabel: "Địa chỉ cư trú",
            houselessLabel: "Gia đình quý vị có chỗ ở không?",
            houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            houseLessOptions: ["Có", "Không"],
            addrQuestionlabel: "Địa chỉ của quý vị là gì?",
            addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
            validZipcode: "Vui lòng nhập một mã zip hợp lệ",
            alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
            optionsSendMail: "Phải, hãy gửi thư đến đó.",
            optionsNoDiffAddress:
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            mailOptions: [
              "Phải, hãy gửi thư đến đó.",
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            ],
            mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
            zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
            masZipCodeErrmsg: `Nếu bạn chuyển ra ngoài Alabama, vui lòng gọi ${caseManagerPhone}.`,
            citiErrmsg: "Vui lòng nhập vào tên thành phố",
            bestPhoneqlabel:
              "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
            inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
            validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
            editButtonLabel: "Chỉnh sửa",
            doneButtonlabel: "Hoàn tất",
            phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
            phoneLabel: "Số điện thoại chính",
            phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
            phoneNotificationAgreeLabel:
              "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailEditTitle: "Địa chỉ email",
            emailLabel: "Chỉnh sửa địa chỉ email của tôi",
            emailNotificationQuestion: "Cho phép các thông báo bằng email?",
            emailNotificationAgreeLabel:
              "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailAddressLabel: "Địa chỉ email của quý vị là gì?",
            emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
            titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            emergencySnap:
              "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
            incomeMoneylabel:
              "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
            inlineHelpLabel:
              "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
            optionYesNo: ["Có", "Không"],
            alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
            incomeLesslabel:
              "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
            monthlyIncomeoptions: ["Có", "Không"],
            selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
            migrantBefore: "Quý vị có phải là ",
            migrantTrigger: "công nhân di cư",
            migrantAfter:
              " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
            migrantHelp:
              "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
            migrantOptions: ["Có", "Không"],
            migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
            headerMoreAbout: "Thông tin thêm về tôi",
            areUsBefore: "Quý vị có là một ",
            areUsTrigger: "Công dân Hoa kỳ",
            areUsafter: "?",
            eligibleHelp:
              "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            chooseErrmsg: "Vui lòng chọn công dân",
            eligibleMemberHelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong gia đình vẫn có thể đủ điều kiện để hưởng các lợi ích.",
            eligibleOptions: ["Có", "Không"],
            laguageBefore: "Quý vị ",
            LANGUAGES: [
              "Tiếng Anh",
              "Tiếng Tây Ban Nha",
              "Tiếng Bồ Đào nha",
              "Tiếng Pháp",
              "Tiếng Haitian Creole",
              "Tiếng Trung quốc",
              "Tiếng Việt",
              "Tiếng Campuchia",
              "Hàn Quốc",
              "Tiếng Nga",
              "Tiếng Ý",
              "Tiếng Ba Lan",
              "Tiếng Ả rập",
            ],
            languageTrigger: " thích nói ngôn ngữ nào hơn",
            languageAfter: "?",
            comfortLanHelp:
              "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
            languageErrMsg: "Vui lòng chọn ngôn ngữ",
            genderLabel: "Giới tính của quý vị là gì?",
            genderOptions: ["Nam", "Nữ"],
            genderErrmsg: "Vui lòng chọn giới tính",
            labelEthnicity: "Quý vị thuộc dân tộc nào?",
            hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
            nonhisPanicOption:
              "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
            helpEthnicity:
              "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            raceLabel: "Quý vị thuộc chủng tộc nào?",
            raceHelp:
              "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            optionAIdisplay: "Người Mỹ bản xứ",
            optionAsiandisplay_VALUE: "Người Châu Á",
            optionAsiandisplay: "Người Châu Á",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
            optionwhitedisplay_VALUE: "Người da trắng",
            optionwhitedisplay: "Người da trắng",
            declineToAnswerLabel: "Từ chối trả lời",
            tripCountErrMsgLen:
              "Vui lòng nhập vào bằng hoặc ít hơn 6 ký tự cho chuyến đi",
          };

        case HI:
          return {
            title: "Konsènan mwen menm",
            header: "Konsènan mwen menm",
            labelFirstName: "Ki prenon w?",
            firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
            middleName: "Ki dezyèm prenon w?",
            middleNameErrMsg: "Tanpri antre dezyèm prenon",
            lastNamelabel: "Ki siyati w?",
            lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
            labelSuffix: "Sifiks",
            suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            dateOfBirthLabel: "Ki dat nesans ou?",
            dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
            ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
            inputClearLabel: "M pa genyen youn",
            ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            headerMyContactInfo: "Enfòmasyon kontak mwen",
            houselessLabel: "Èske fwaye w la sanzabri?",
            houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
            houseLessOptions: ["Wi", "Non"],
            addrQuestionlabel: "Ki adrès ou?",
            validZipcode: "Tanpri antre yon kòd postal ki valid",
            alsoMailingLabel: "Èske se adrès postal ou a tou?",
            optionsSendMail: "Wi, voye lapòs la.",
            optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
            mailOptions: [
              "Wi, voye lapòs la.",
              "Non, mwen itilize yon lòt adrès pou lapòs.",
            ],
            mhProviderMemberSearch: ["Prenon / Siyati", "Kòd Postal"],
            agedOver60: ["Plis pase 60 an"],
            mailingQlabel: "Ki adrès postal ou?",
            zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
            masZipCodeErrmsg: `Si w te demenaje andeyò Alabama, tanpri rele ${caseManagerPhone}.`,
            citiErrmsg: "Tanpri antre yon vil ki valid",
            addressErrmsg: "Tanpri antre yon adrès ki valid",
            bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
            inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
            validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
            emailAddressLabel: "Ki adrès imel ou?",
            emailErrormsg: "Tanpri antre yon adrès imel ki valid.",
            titleEmergency: "Benefis SNAP nan Ijans",
            headerEmergency: "Benefis SNAP nan Ijans",
            emergencySnap:
              "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
            incomeMoneylabel:
              "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
            inlineHelpLabel:
              "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
            optionYesNo: ["Wi", "Non"],
            alertSelectIncome: "Tanpri chwazi revni ou peye",
            incomeLesslabel:
              "Èske revni mansyèl ou mwens pase $150 epi èske lajan labank ou se $100 oswa mwens?",
            monthlyIncomeoptions: ["Wi", "Non"],
            selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
            migrantBefore: "Èske w se yon ",
            migrantTrigger: "travayè migran",
            migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
            migrantHelp:
              "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
            migrantOptions: ["Wi", "Non"],
            migrantErrmsg: "Tanpri chwazi migran ou peye",
            headerMoreAbout: "Plis konsènan mwen menm",
            areUsBefore: "Èske w se yon ",
            areUsTrigger: "sitwayen Ameriken",
            areUsafter: "?",
            eligibleHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            chooseErrmsg: "Tanpri chwazi sitwayen",
            eligibleMemberHelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            eligibleOptions: ["Wi", "Non"],
            LANGUAGES: [
              "Angle",
              "Panyòl",
              "Pòtigè",
              "Franse",
              "Kreyòl Ayisyen",
              "Chinwa",
              "Vyetnamyen",
              "Kmè",
              "Korean",
              "Ris",
              "Italyen",
              "Polonè",
              "Arabik",
            ],
            languageBefore: "Ki lang ou ",
            languageTrigger: "prefere pale ",
            languageAfter: "?",
            comfortLanHelp:
              " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
            languageErrMsg: "Tanpri chwazi ki lang",
            genderLabel: "Ki sèks ou?",
            genderOptions: ["Gason", "Fi"],
            genderErrmsg: "Tanpri chwazi sèks",
            labelEthnicity: "Ki etnisite ou?",
            helpEthnicity:
              "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Ispanik/Latino",
            raceLabel: "Ki ras ou?",
            raceHelp:
              "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            optionAIdisplay: "Ameriken Endyen",
            optionAsiandisplay_VALUE: "Azyatik",
            optionAsiandisplay: "Azyatik",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Nwa/Afriken Ameriken",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "moun Zile Pasifik",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "Blan",
            declineToAnswerLabel: "Refize reponn",
            tripCountErrMsgLen:
              "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
          };

        default:
          return;
      }
    }

    case languageConstants.beforeYouStart: {
      switch (language) {
        case EN:
          return {
            heading: "SNAP Six-Month Report",
            utilityExpenseLabel: "Utility Expenses",
            howApplyLabel: "SNAP Six-Month Report",
            emailTextLabel: "Email address",
            fieldLabel1: "It is time for your six-month report. ",
            fieldLabelCompleteForm:
              "You must complete this form and submit it to the country office with verification of any changes between the 1st and 10th of August or your SNAP case will close at the end of August.",
            fieldLabel2:
              "Make sure to answer all the questions within this form. Each section displays if you are required to provide verification of the changes. If you do not sign and submit it with all required verfication, your benefits may be delayed, reduced, or terminated. Call your local office if you need help completeing this form. If you need help with reading, writing, hearing etc., under the americans with Disabilities Act, you should make your needs known to the country office where you live. If upu completed and submitted a paper six-month repory form, please do not complete this form.",
            fieldLabel3:
              "You may complete your SNAP Six-Month Report and/or submit your verification documents using the following methods:",
            recertMethods: [
              "online by clicking <strong>Start SNAP Six-Month Report.</strong> This is the fastest way!",
              {
                before: " Download and print your paper form ",
                hereLinkText: "here.",
                after: " You can submit that form by:",
              },
              {
                before:
                  "Taking a picture of each page and uploading via ACES DHR Self Service Portal ",
                hereLinkText: "here",
                after: "for more information)",
              },
              {
                before:
                  "Faxing, emailing, or mailing to your local country office (click ",
                hereLinkText: "here",
                after: " for more information)",
              },
            ],
            boBackButtonLabel: "Go Back",
            getStartButtonLabel: "Start SNAP Six-Month Report",
            //sIncome:"'s gross earned income",
            sIncomebefore: "'s ",
            sIncometrigger: "Gross Earned Income",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. insurance",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: "'s ",
            sUIncometrigger: "Gross Unearned Income",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Gross Unearned Income is money you receive from a source other than employment  before anything is taken out, such as taxes or health insurance",
            //sUIIncome:"'s gross unearned income",
            editButtonLabel: "Edit",
            perLabel: "Per",
            ofLabel: "of",
            residentialAddr: "Home Address",
            mailingAddress: "Mailing address",
            fromMultiLang: " from ",
            renderAppNoLabel: "Your application no:",
            youMayLabelOne: "You may want to ",
            youMayLabeltwo: "download your application",
            youMayLabelThree: "for future reference.",
            whatisNextLabel: "What's Next?",
            dtaReachLabel:
              "The DHR will reach out to you within the next 5 days.",
            sendDocLabel:
              "Send in documents so that DHR can confirm your situation.",
            howDoSpeedLabel: "How do I speed up the process?",
            donotNeedLabel:
              "You do not need to wait for DHR to send you a list of verifications",
            sendLabelOne: "You can ",
            sendLabelTwo: "send us your documents ",
            sendLabelThree: "in many ways",
            toLearnOneLabel:
              "To learn more about documents that you may submit now,",
            toLearnOneLabelTwo: "click here",
            manyWayLabel:
              "There are many other ways to submit documents to us.",
            manyWayLabelTwo: "Find out more",
            howDoSubmitLabel: "How do I submit documents?",
            weSuggestLabel: "We suggest",
            weSuggestLabelOne: "creating an online account",
            weSuggestLabelTwo:
              "so you can upload documents, track the progress of your application, check your balance, and more!",
            findOutMoreLabel: "Find out more",
            createOnlineLabel: "Create an online account",
            orExistLabel: "Or exit without uploading any documents",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "Great! Here's what we got from you.",
            lookIncorrectLabel:
              "If any of it looks incorrect, feel free to edit.",
            IncomePerMonthLabel:
              "Has your household gross earned income changed by more than $100? (Required)",
            anotherHouseholdIncomeLabel:
              "Add the income of another household member",
            anotherHouseholdUnEarnedIncomeLabel:
              "Add another household member's unearned income",
            anotherHouseholdResourceLabel:
              "Add the resources of another household member",
            unearnedIncome_ChangeQuestion:
              "Has your household's gross unearned income changed by more than $100? (Required)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Application Summary",
            applicationSummaryHeader: "Application Summary",
            viewApplicationLinkLabel: "view as pdf",
            iagreeTermsLabel: "I agree",
            signYourApplication: "Sign your full name below.(Required)",
            continueLabel: "Continue",
            saveAndContinueLabel: "Save and Continue",
            getStartedLabel: "Get Started",
            submitButtonLabel: "Submit Interim Report",
            submitSelfButtonLabel: "Submit Self-declare expenses",
            expenseFieldLabel: "This last section is about",
            expenseHouseutility: "Housing and utility costs",
            expenseCaring: "Caring for kids and adults",
            expenseChsupport: "Child Support",
            expenseMed: "Medical expenses",
            expenseAnyinfo:
              "Any information you provide here will provide a more accurate benefit amount.",
            removeHoHTitle: "Warning",
            removeHoHLabel:
              "You are attempting to delete the head of the household. If this is correct, you may hit Continue now and DHR will contact you for more information. If this was a mistake, please hit “Go Back”",
            agreeContent: [
              "By signing, I agree that:",
              "I have read this entire form (or have had it read to me in a language that I understand), including the section about rights and responsibilities, and understand that I must comply with these rules;",
              "The information I am giving is true and complete to the best of my knowledge;",
              "I could go to prison or be required to pay fines if I knowingly give wrong or incomplete information; and DHR and other federal, state, and local officials may verify (check) any information I give.",
            ],
            didReportChanges: "I did report changes",
            didNotReportChanges: "I did not report changes",
            badgeChangedLabel: "Changed",
            badgeNewLabel: "New",
            agreeTermsErrorMsg: "Please agree to the terms.",
            signatureErrorMsg: "Please sign.",

            shelterExpense_ChangeQuestion:
              "Has your household shelter or utility expenses changed? (Required)",
            shelterExpenseSubText:
              "Note: If you have moved, you must submit proof of your shelter expenses. If you have not moved but report an increase in the following shelter expenses (rent, mortgage, property taxes or home insurance) and do not provide verification, the change will not be made until recertification.",
            incomePerMonthSubText:
              'If you Answered "Yes" to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.',
            earningsPerMonthLabel:
              "Has anyone had a change in earnings (including earnings from self-employment) because they started or stopped a job or changed jobs? (Required)",
            earningsNote:
              'NOTE: If you are an "Able-Bodied Adult without Dependents" (ABAWD), you must report when your work hours fall below 20 hours a week (or at least 80 hours a month). The change must be reported 10 days from the end of the month the change occurred.',
            nonDiscriminationHeader: "USDA Nondiscrimination Statement",
            nonDiscriminationContent:
              "In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color,national origin, sex (including gender identity and sexual orientation), religious creed, disability, age,  political beliefs, or reprisal or retaliation for prior civil rights activity.",
            personDisabilities:
              "Program information may be made available in languages other than English. Persons with disabilities who require alternative means of communication to obtain program information (e.g., Braille, large print, audiotape, American Sign Language), should contact the agency (state or local)where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at (800) 877-8339.",
            programComplaint:
              "To file a program discrimination complaint, a Complainant should complete a Form AD-3027,USDA Program Discrimination Complaint Form which can be obtained online at: ",
            usdaOffice:
              "from any USDA office, by calling (833) 620-1071, or by writing a letter addressed to USDA. ‘The letter must contain the complainant's name,  address, telephone number, and a written description of the alleged discriminatory action in sufficient detail to inform the Assistant Secretary for Civil Rights (ASCR) about the nature and date of an alleged civil rights violation. The completed AD-3027 form or letter must be submitted to: ",
            mail: "mail:",
            mailAddress:
              "Food and Nutrition Service, USDA 1320 Braddock Place, Room 334 Alexandria, VA 22314; or",
            fax: " fax:",
            orLabel: " or",
            eamilTitle: "email: ",
            thisInstution: "This institution is an equal opportunity provider.",
            federalLaws:
              "You may also be fined up to $250,000, imprisoned up to 20 years, or both; and subject to  prosecution under other applicable federal laws.",
            hideInformation:
              "Hide information or give false information to get or continue  receiving SNAP",
            tradeOrSell:
              "Trade or sell your SNAP or EBT cards (also known as trafficking)",
            alterEbt:
              "Alter EBT cards to get SNAP you are not entitled to receive",
            alcoholicDrinksOrTobacco:
              "Use SNAP to buy ineligible items such as alcoholic drinks or tobacco",
            someoneElseSnapOrEbt:
              "Use someone else's SNAP or EBT cards for your houschold",
            iUnderstandThePenalty:
              " I understand the penalty warning above. I also understand that I will owe the value of any extra SNAP benefits I receive if don't fully report changes in my household circumstances when required. I agree to provide proof of any changes I report.",
            iCertifyThatTheStatement:
              "I certify that the statements on this form are true and correct to the best of my knowledge.I understand that the information that I provide on this form may result in a reduction or termination of my benefits.",
            signatureandPenalty: "Signature and Penalty Warning.",
            whenYourHouseholdReceivesSnap:
              " When your household receives SNAP, you must follow the rules. If you do any of the following you  will lose your SNAP benefits for one year for the first violation, ow years for the second violation and life for the third violation.",
            reportingRulesDtachanges:
              "Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR)  and recertification with the exception of:",
            submitLabel: "Submit",
            shelterExpensesLabel:"Shelter Expenses"
          }; //return End

        case ES:
          return {
            heading: "Informe provisional",
            utilityExpenseLabel: "Gastos de servicios públicos",
            howApplyLabel: "Informe provisional",
            residentialAddr: "Dirección residencial",
            mailingAddress: "Dirección postal",
            emailTextLabel: "Dirección de correo electrónico",
            fieldLabel1:
              "Puede completar su Informe provisional con los siguientes métodos:",
            recertMethods: [
              "En línea, haciendo clic <b>en Informe provisional.</b> Esta es la forma más rápida!",
              {
                before:
                  "Aceptaremos un formulario impreso si incluye su nombre, dirección y firma. Esta opción no está disponible para realizar la presentación en línea. Descargue e imprima su formulario ",
                hereLinkText: "aquí.",
                after:
                  " Después de completar el formulario, puede presentarlo de las siguientes maneras: ",
              },
              "Tomar una foto de cada página y subirlas a través de ACES DHR Self Service Portal",
              "Por fax al (617) 887-8765",
              "Por correo a P.O. Box 4406, Taunton, MA 02780",
              "Llevarlo la oficina local de DHR para escanearlo en el área de autoservicio",
            ],
            boBackButtonLabel: "Volver",
            sIncomebefore: " ",
            sIncometrigger: "Ingresos brutos del trabajo de",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "Ingresos brutos no provenientes del trabajo de",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Los ingresos brutos no devengados es dinero que recibe de una fuente que no es su empleo antes de cualquier deducción, como impuestos o seguro médico.",
            getStartButtonLabel: "Comenzar el Informe provisional",
            editButtonLabel: "Editar",
            badgeNewLabel: "Nuevo",
            perLabel: "Por",
            ofLabel: "De",
            //new label:

            renderAppNoLabel: "El número de su solicitud es:",
            youMayLabelOne: "Puede",
            fromMultiLang: " de ",
            youMayLabeltwo: "descargar su solicitud",
            youMayLabelThree: "para tenerla como referencia en el futuro.",
            whatisNextLabel: "¿Cuál es el siguiente paso?",
            dtaReachLabel:
              "El DHR se comunicará con usted en los próximos 5 días.",
            sendDocLabel:
              "Envíe los documentos para que el DHR pueda confirmar su situación.",
            howDoSpeedLabel: "¿Cómo puedo acelerar el proceso?",
            donotNeedLabel:
              "No es necesario que espere a que el DHR le envíe una lista de verificaciones. ",
            sendLabelOne: "Puede",
            IncomePerMonthLabel:
              "¿Se modificaron los ingresos brutos provenientes del trabajo del grupo familiar en más o menos de $100/mes? (Obligatorio)",
            sendLabelTwo: "enviarnos sus documentos",
            sendLabelThree: "de muchas maneras. ",
            toLearnOneLabel:
              "Para saber más acerca de los documentos que puede enviar ahora, ",
            toLearnOneLabelTwo: "haga clic aquí",
            manyWayLabel: "Hay muchas otras maneras de presentar documentos.",
            manyWayLabelTwo: "Obtenga más información.",
            howDoSubmitLabel: "¿Cómo envío documentos?",
            weSuggestLabel: "¡Le sugerimos que",
            weSuggestLabelOne: " cree una cuenta en línea",
            weSuggestLabelTwo:
              "para que pueda subir documentos, darle seguimiento a su solicitud, consultar su saldo y más!",
            findOutMoreLabel: "Averiguar más",
            createOnlineLabel: "Crear una cuenta en línea",
            orExistLabel: "O salir sin subir documentos", //
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "Excelente. Esto es lo que tenemos de usted",
            lookIncorrectLabel:
              "Si algo parece incorrecto, no dude en editarlo.",
            anotherHouseholdIncomeLabel:
              "Sumar el ingreso de otro miembro del hogar",
            anotherHouseholdUnEarnedIncomeLabel:
              "Agregar ingresos no provenientes del trabajo de otro miembro del grupo familiar",
            anotherHouseholdResourceLabel:
              "Add the resources of another household member",
            unearnedIncome_ChangeQuestion:
              "¿Se modificaron los ingresos brutos no provenientes del trabajo del grupo familiar en más o menos de $100/mes? (Obligatorio)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Resumen de la aplicación",
            applicationSummaryHeader: "Resumen de la aplicación",
            viewApplicationLinkLabel: "Ver como PDF",
            iagreeTermsLabel: "Acepto",
            signYourAppllication:
              "Firme su informe preliminar escribiendo su nombre completo a continuación",
            continueLabel: "Continuar",
            badgeChangedLabel: "Modificado",
            getStartedLabel: "Comenzar",
            submitButtonLabel: "Presentar Informe provisional",
            submitSelfButtonLabel: "Presentar Autodeclaración de gastos",
            expenseFieldLabel: "Esta última sección trata sobre",
            expenseHouseutility: "Costos de vivienda y servicios públicos",
            expenseCaring: "Cuidado de niños y adultos",
            expenseChsupport: "Manutención",
            expenseMed: "Gastos médicos",
            expenseAnyinfo:
              "Toda la información que proporcione aquí dará un monto más exacto del beneficio.",
            removeHoHTitle: "Advertencia",
            removeHoHLabel:
              "Intenta eliminar al jefe/la jefa de familia. Si es correcto, puede hacer clic en Presentar ahora y DHR se comunicará con usted para obtener más información. Si es un error, haga clic en 'Volver'. ",
            agreeContent: [
              "Al firmar, acepto lo siguiente:",
              "Leí todo el formulario (o alguien lo leyó para mí en un idioma que comprendo), incluida la sección sobre derechos y responsabilidades, y entiendo que debo cumplir con esas reglas;",
              "A mi leal saber y entender, la información que proporciono es verdadera y completa;",
              "Podría ir a prisión o se me podría exigir el pago de multas si proporciono intencionalmente información errónea o incompleta; y DHR y otros funcionarios federales, estatales y locales podrán verificar (controlar) la información que proporciono.",
            ],
            didReportChanges: "Informé cambios.",
            didNotReportChanges: "No informé cambios.",
            agreeTermsErrorMsg: "Por favor, acepte los términos.",
            signatureErrorMsg: "Please sign.",
            submitLabel: "Submit",
            shelterExpensesLabel:"Gastos de alojamiento"
          }; //return End

        case PT:
          return {
            heading: "Relatório interino",
            howApplyLabel: "Relatório interino",
            emailTextLabel: "Endereço de e-mail",
            residentialAddr: "Endereço residencial",
            mailingAddress: "Endereço de correspondência",
            fieldLabel1:
              "Você pode preencher seu Relatório interino usando os seguintes métodos:",
            recertMethods: [
              "Online clicando em <b>iniciar Relatório interino.</b> Essa é a forma mais rápida!",
              {
                before:
                  "Aceitaremos um formulário impresso se você incluir seu nome, endereço e assinatura. Esta opção não está disponível para envio online. Baixe e imprima seu formulário ",
                hereLinkText: "aqui.",
                after:
                  " Após preencher esse formulário, você pode enviá-lo por:",
              },
              "Foto, tirando uma foto de cada página e carregando via ACES DHR Self Service Portal",
              "Fax para (617) 887-8765",
              "Correio para P.O. Box 4406, Taunton, MA 02780",
              "Pessoalmente, trazendo ao seu escritório DHR local para digitalizar na área de autoatendimento",
            ],
            boBackButtonLabel: "Voltar",
            sIncomebefore: " ",
            sIncometrigger: "Rendimento bruto de",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            badgeNewLabel: "Novo",
            sUIncometrigger: "Rendimento bruto não proveniente de trabalho de",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Renda bruta não realizada é o dinheiro que você recebe de uma fonte que não seja emprego antes de qualquer coisa ser descontada, como impostos ou seguro de saúde.",
            getStartButtonLabel: "Iniciar Relatório interino",
            editButtonLabel: "Editar",
            fromMultiLang: " de ",
            badgeChangedLabel: "Alterado",
            IncomePerMonthLabel:
              "O rendimento bruto do seu domicílio foi alterado em mais ou menos $100/mês? (Obrigatório)",
            perLabel: "Por",
            ofLabel: "do",

            //new label:

            renderAppNoLabel: "O número da sua solicitação é :",
            youMayLabelOne: "Baixe a sua solicitação ",
            youMayLabeltwo: "caso precise dela no futuro.",
            youMayLabelThree: "for future reference.",
            whatisNextLabel: "Qual é a próxima etapa?",
            dtaReachLabel: "O DHR entrará em contato com você em 5 dias.",
            sendDocLabel:
              "Envie os documentos para que o DHR possa confirmar a sua situação.",
            howDoSpeedLabel: "Como eu posso acelerar o processo?",
            donotNeedLabel:
              "Você não precisa esperar que o DHR envie uma lista de verificações.",
            sendLabelOne: "Você pode nos enviar seus",
            sendLabelTwo: " documentos",
            sendLabelThree: "de diversas formas.",
            toLearnOneLabel:
              "Para saber mais sobre os documentos que você pode enviar agora,",
            toLearnOneLabelTwo: "clique aqui",
            manyWayLabel:
              "Existem muitas outras maneiras de enviar documentos para nós.",
            manyWayLabelTwo: "Saiba mais",
            howDoSubmitLabel: "Como eu envio os documentos?",
            weSuggestLabel: "Sugerimos que você crie uma conta",
            weSuggestLabelOne: " on-line para fazer o upload dos ",
            weSuggestLabelTwo:
              "documentos, verificar o andamento da sua solicitação, checar o seu saldo e muito mais!",
            findOutMoreLabel: "Saiba mais",
            createOnlineLabel: "Crie uma conta on-line",
            orExistLabel: "Ou saia sem fazer o upload de nenhum documento",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "Ótimo! É isso que recebemos de você.",
            lookIncorrectLabel:
              "Se algo estiver incorreto, sinta-se à vontade para editar.",
            anotherHouseholdIncomeLabel:
              "Add the income of another household member",
            anotherHouseholdUnEarnedIncomeLabel:
              "Adicionar outro rendimento não proveniente de trabalho de membros do agregado familiar",
            unearnedIncome_ChangeQuestion:
              "O rendimento bruto não proveniente de trabalho do seu domicílio foi alterado em mais ou menos $ 100/mês? (Obrigatório)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Resumo da aplicação",
            applicationSummaryHeader: "Resumo da aplicação",
            viewApplicationLinkLabel: "Visualizar como PDF",
            iagreeTermsLabel: "Concordo",
            signYourAppllication:
              "Assine seu Relatório Provisório digitando seu nome completo abaixo",
            continueLabel: "Continuar",
            getStartedLabel: "Vamos Começar",
            submitButtonLabel: "Enviar Relatório interino",
            submitSelfButtonLabel: "Enviar Auto-declarar despesas",
            expenseFieldLabel: "Esta última seção é sobre",
            expenseHouseutility: "Custos residenciais e utilitários",
            expenseCaring: "Cuidados com crianças e adultos",
            expenseChsupport: "Suporte infantil",
            expenseMed: "Despesas médicas",
            expenseAnyinfo:
              "Qualquer informação que você prestar aqui irá fornecer um valor mais preciso do benefício.",
            removeHoHTitle: "Aviso",
            removeHoHLabel:
              "Você está tentando excluir o chefe do domicílio. Se isso estiver correto, você pode clicar em enviar agora e o DHR entrará em contato para obter mais informações. Se isso foi um erro, clique em 'Voltar'. ",
            agreeContent: [
              "Ao assinar, concordo que:",
              "Li todo este formulário (ou o leram para mim em um idioma que compreendo), incluindo a seção sobre direitos e responsabilidades, e entendo que devo cumprir essas regras;",
              "As informações que estou dando são verdadeiras e completas, de acordo com o meu conhecimento; ",
              "Eu poderia ir para a prisão ou ser obrigado a pagar multas se conscientemente fornecer informações erradas ou incompletas; e o DHR e outras autoridades federais, estaduais e locais podem verificar (conferir) qualquer informação que eu der.",
            ],
            didReportChanges: "Eu reportei mudanças.",
            didNotReportChanges: "Eu não relatei nenhuma alteração.",
            agreeTermsErrorMsg: "Concorde com os termos",
            signatureErrorMsg: "Assine o seu Relatório Provisório",
          }; //return End

        case ZH:
          return {
            heading: "中间报告",
            howApplyLabel: "中间报告",
            residentialAddr: "住宅地址",
            mailingAddress: "邮寄地址",
            fieldLabel1: "您可使用下列方法填写您的《中间报告》:",
            emailTextLabel: "电子邮箱地址",
            fromMultiLang: " 来自 ",
            recertMethods: [
              "在线填写，点击开始填写 <b>《中间报告》。</b> 这是最快的填写方式！",
              {
                before:
                  "如果您在纸质表格中填写您的姓名、地址并签名，我们也接受纸质表格。此选项针对在线提交不可用。处下载并打印您的纸质表格 ",
                hereLinkText: "在此。",
                after: " 您填写好表格后. 可以通过下列方式提交:",
              },
              "将表格各页拍照并利用 ACES DHR Self Service Portal 上传",
              "传真发送至 (617)887-8765",
              "邮寄至 P.O. Box 4406, Taunton, MA 02780",
              "携带表格前往您当地的 DHR 办事处，在自助区域进行扫描",
            ],
            boBackButtonLabel: "返回",
            sIncomebefore: " ",
            sIncometrigger: "的劳动所得收入总额",
            IncomePerMonthLabel:
              "您的家庭劳动所得总收入变动是否超过或少于 $100 美元/月？（必填）",
            sIncomeAfter: " ",
            sIncomeHelp:
              "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险）",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "的非劳动所得总收入",
            sUIncomeAfter: "",
            sUIncomeHelp:
              "非劳动所得收入总额是您从工作以外的来源获得的金钱（未扣除任何费用，例如税或健康保险）",
            getStartButtonLabel: "开始填写《中间报告》",
            editButtonLabel: "编辑",
            badgeChangedLabel: "已变化",
            perLabel: "每",
            ofLabel: "的",
            badgeNewLabel: "新添内容",
            //new label:
            renderAppNoLabel: "您的申请编号是",
            youMayLabelOne: "您可能想要",
            youMayLabeltwo: "下载申请表",
            youMayLabelThree: "下载申请表以备日后查阅。",
            whatisNextLabel: "下一步",
            dtaReachLabel: "DTA将在接下来5个工作日内与您联系。",
            sendDocLabel: "发送文件以便DTA能够确认您的状况。",
            howDoSpeedLabel: "如何加速审核流程？",
            donotNeedLabel: "您无需等待DTA向您发送证明文件清单。",
            sendLabelOne: "您可通过多种方式",
            sendLabelTwo: "向我们发送文件 ",
            sendLabelThree: "in many ways",
            toLearnOneLabel: "要详细了解您现在可以提交的文件，请",
            toLearnOneLabelTwo: "单击这里",
            manyWayLabel: "向我们提交文件可通过很多其他方式。",
            manyWayLabelTwo: "了解更多",
            howDoSubmitLabel: "如何提交文件？",
            weSuggestLabel: "我们建议您",
            weSuggestLabelOne: "创建一个在线账户",
            weSuggestLabelTwo:
              "您可以通过此账户上传资料，跟踪申请进度，查看余额等等！",
            findOutMoreLabel: "了解更多",
            createOnlineLabel: "创建在线账户",
            orExistLabel: "或退出而不上传任何资料",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "这是我们从你那里了解到的情况",
            lookIncorrectLabel: "如果有任何错误之处，敬请改正。",
            anotherHouseholdIncomeLabel:
              "Add the income of another household member",
            anotherHouseholdUnEarnedIncomeLabel:
              "添加另一位家庭成员的非劳动所得收入",
            unearnedIncome_ChangeQuestion:
              "您的家庭非劳动所得总收入变动是否超过或少于 $100 美元/月？ (必填)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "應用摘要",
            applicationSummaryHeader: "應用摘要",
            viewApplicationLinkLabel: "查看 PDF 文件",
            iagreeTermsLabel: "我同意",
            signYourAppllication: "在下方输入您的全名即为签署您的中间报告文件",
            continueLabel: "继续",
            getStartedLabel: "开始",
            submitButtonLabel: "提交中间报告",
            submitSelfButtonLabel: "提交自我声明的费用",
            expenseFieldLabel: "最后一部分是关于",
            expenseHouseutility: "住房和通用开支",
            expenseCaring: "子女和成人照护费",
            expenseChsupport: "子女抚养费",
            expenseMed: "医疗费",
            expenseAnyinfo:
              "您在此提供的任何信息都将有助于我们确定更准确的补助金额。",
            removeHoHTitle: "警告",
            removeHoHLabel:
              "您正在尝试删除户主。如果这一操作正确，您可现在点击提交，DHR 会联系您获取更多信息。如果这一操作为误操作，请点击“返回”。 ",
            agreeContent: [
              "我签名即表示同意：",
              "我已经阅读此表格完整内容（或让人以我理解的语言为我朗读此表格完整内容），包括关于权利和责任的部分，而且我理解，我必须遵守这些规定；",
              "我提供的信息据我所知均真实而且完整。 ",
              "如果我在知情的前提下给出错误或不完整的信息，我可能入狱或按要求支付罚款；DHR 及其他联邦、州和地方官员可验证（检查）我提供的所有信息。",
            ],
            didReportChanges: "我已经报告信息 更改。",
            didNotReportChanges: "我并未报告任何更改。",
            agreeTermsErrorMsg: "请同意条款",
            signatureErrorMsg: "请签署您的中间报告",
          }; //return End
        case VI:
          return {
            heading: "Báo cáo giữa kỳ",
            howApplyLabel: "Báo cáo giữa kỳ",
            residentialAddr: "Địa chỉ cư trú",
            mailingAddress: "Địa chỉ hòm thư",
            fieldLabel1:
              "Quý vị có thể hoàn thành Báo cáo giữa kỳ của mình bằng các phương pháp sau:",
            emailTextLabel: "Địa chỉ email",
            recertMethods: [
              "Trực tuyến bằng cách nhấp vào nút <b>bắt đầu Báo cáo giữa kỳ.</b> Đây là cách nhanh nhất!",
              {
                before:
                  "Chúng tôi sẽ chấp nhận đăng ký bằng biểu mẫu trên giấy nếu trên đó có tên, địa chỉ và chữ ký của quý vị. Tùy chọn này không có sẵn khi nộp trực tuyến. Tải về và in biểu mẫu ở ",
                hereLinkText: "đây.",
                after:
                  " Sau khi quý vị hoàn thành biểu mẫu đó, quý vị có thể gửi bằng cách:",
              },
              "Chụp ảnh từng trang và tải lên qua ACES DHR Self Service Portal",
              "Gửi fax theo số (617) 887-8765",
              "Gửi thư đến Hộp thư bưu điện 4406, Taunton, MA 02780",
              "Mang đến văn phòng DHR địa phương của quý vị để scan tại khu vực tự phục vụ",
            ],
            boBackButtonLabel: "Quay lại",
            sIncomebefore: " ",
            sIncometrigger: "Tổng thu nhập đã thu của",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Tổng thu nhập kiếm được là số tiền bạn nhận được từ công việc trước khi trừ ra bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            badgeChangedLabel: "thay đổi",
            fromMultiLang: " từ ",
            sUIncometrigger: "Tổng thu nhập chưa thu của",
            sUIncomeAfter: " ",
            badgeNewLabel: "mới",
            IncomePerMonthLabel:
              "Tổng thu nhập đã thu được của gia đình quý vị đã thay đổi nhiều hơn hoặc ít hơn $100 Đô la / tháng? (Bắt buộc)",
            sUIncomeHelp:
              "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
            getStartButtonLabel: "Bắt đầu báo cáo giữa kỳ",
            editButtonLabel: "Chỉnh sửa",
            perLabel: "Mỗi",
            ofLabel: "Của",

            //new label:
            renderAppNoLabel: "Số Đơn xin:",
            youMayLabelOne: "Có thể Quý vị muốn ",
            youMayLabeltwo: " tải đơn xin của quý vị về",
            youMayLabelThree: "để tham khảo trong tương lai.",
            whatisNextLabel: "Điều gì diễn ra kế tiếp?",
            dtaReachLabel: "DHR sẽ liên lạc với quý vị trong vòng 5 ngày tới.",
            sendDocLabel:
              "Gửi các tài liệu để DHR có thể xác nhận tình trạng của quý vị. ",
            howDoSpeedLabel: "Làm thế nào để đẩy nhanh tiến trình?",
            donotNeedLabel:
              "Quý vị không cần đợi DHR gửi cho quý vị danh sách xác minh.",
            sendLabelOne: "Quý vị có thể ",
            sendLabelTwo: " gửi cho chúng tôi giấy tờ của quý vị",
            sendLabelThree: " bằng nhiều cách",
            toLearnOneLabel:
              "Để tìm hiểu thêm về các giấy tờ mà quý vị có thể gửi ngay bây giờ,",
            toLearnOneLabelTwo: "hãy nhấp vào đây",
            manyWayLabel:
              "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
            manyWayLabelTwo: "Tìm hiểu thêm",
            howDoSubmitLabel: "Làm thế nào để tôi gửi giấy tờ?",
            weSuggestLabel: "Chúng tôi khuyên quý vị nên",
            weSuggestLabelOne: "tạo một tài khoản trực tuyến",
            weSuggestLabelTwo:
              "để quý vị có thể tải lên giấy tờ, theo dõi tiến trình đơn xin của quý vị, kiểm tra số dư của quý vị và những việc khác nữa!",
            findOutMoreLabel: "Tìm hiểu thêm",
            createOnlineLabel: "Tạo tài khoản trực tuyến",
            orExistLabel:
              "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel:
              "Tuyệt vời, đây là những gì chúng tôi thu thập được từ quý vị",
            lookIncorrectLabel:
              "Nếu có bất cứ điều nào trông có vẻ không chính xác, quý vị cứ tự nhiên chỉnh sửa",
            anotherHouseholdIncomeLabel:
              "Add the income of another household member",
            anotherHouseholdUnEarnedIncomeLabel:
              "Bổ sung thu nhập chưa thu khác của các thành viên trong gia đình ",
            unearnedIncome_ChangeQuestion:
              "Tổng thu nhập chưa thu của gia đình quý vị có thay đổi nhiều hơn hoặc ít hơn $100 Đô la / tháng không? (Bắt buộc)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Tóm tắt ứng dụng",
            applicationSummaryHeader: "Tóm tắt ứng dụng",
            viewApplicationLinkLabel: "Xem dưới dạng PDF",
            iagreeTermsLabel: "Tôi đồng ý",
            signYourAppllication:
              "Ký Báo cáo tạm thời của bạn bằng cách nhập tên đầy đủ của bạn dưới đây",
            continueLabel: "Tiếp tục",
            getStartedLabel: "Bắt đầu",
            submitButtonLabel: "Gửi đi báo cáo giữa kỳ",
            submitSelfButtonLabel: "Gửi đi chi phí tự kê khai",
            expenseFieldLabel: "Phần cuối cùng này là về",
            expenseHouseutility: "Chi phí nhà ở và tiện ích",
            expenseCaring: "Chăm sóc trẻ em và người lớn",
            expenseChsupport: "Trợ cấp nuôi con",
            expenseMed: "Chi phí y tế",
            expenseAnyinfo:
              "Bất kỳ thông tin nào quý vị cung cấp ở đây sẽ đưa ra số tiền trợ cấp chính xác hơn.",
            removeHoHTitle: "Cảnh báo",
            removeHoHLabel:
              "Quý vị đang cố gắng xóa mục chủ hộ. Nếu đúng, quý vị có thể nhấp vào gửi ngay bây giờ và DHR sẽ liên hệ với quý vị để biết thêm thông tin. Nếu đây là lỗi, xin vui lòng bấm nút 'quay lại.'",
            agreeContent: [
              "Bằng cách ký vào mẫu này, tôi đồng ý rằng:",
              "Tôi đã đọc toàn bộ biểu mẫu này (hoặc biểu mẫu này đã được đọc cho tôi bằng ngôn ngữ mà tôi hiểu) bao gồm cả phần về quyền và trách nhiệm và hiểu rằng tôi phải tuân thủ các quy tắc này;",
              "Theo sự hiểu biết tốt nhất của tôi, thông tin tôi đang cung cấp là đúng sự thật và đầy đủ;",
              "Tôi có thể vào tù hoặc bị buộc phải nộp tiền phạt nếu tôi cố tình cung cấp thông tin sai hoặc không đầy đủ; và DHR và các cán bộ liên bang, tiểu bang và địa phương khác có thể xác minh (kiểm tra) bất kỳ thông tin nào tôi cung cấp",
            ],
            didReportChanges: "Tôi đã báo cáo những thay dổi.",
            didNotReportChanges: "Tôi đã không báo cáo bất kỳ thay đổi nào.",
            agreeTermsErrorMsg: "Vui lòng đồng ý với các điều khoản",
            signatureErrorMsg: "Vui lòng ký Báo cáo tạm thời của bạn",
          }; //return End
        case HI:
          return {
            heading: "Rapò Pwovizwa",
            howApplyLabel: "Rapò Pwovizwa",
            emailTextLabel: "Adrès imel",
            fieldLabel1:
              "Ou ka ranpli Rapò Pwovizwa w la nan itilize metòd sa yo:",
            recertMethods: [
              "An liy nan klike sou <b>Kòmanse Rapò Pwovizwa.</b> Se fason ki pi rapid la!",
              {
                before:
                  "Nou pral aksepte yon fòm papye si w mete non, adrès ak siyati w. Opsyon sa a pa disponib pou soumèt an liy. Telechaje epi enprime fòm  ",
                hereLinkText: "papye w la la a.",
                after: " Ou ka soumèt fòm ou an nan:",
              },
              "Pran yon foto chak paj epi telechaje atravè ACES DHR Self Service Portal",
              "Fakse nan (617) 887-8765",
              "Poste nan P.O. Box 4406, Taunton, MA 02780.",
            ],
            boBackButtonLabel: "Tounen",
            getStartButtonLabel: "Kòmanse Rapò Pwovizwa",
            //sIncome:"'s gross earned income",
            sIncomebefore: "",
            sIncometrigger: "Revni brit",
            sIncomeAfter: "",
            sIncomeHelp:
              "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante.",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "revni brit ou pa fè nan travay",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Revni Brit ou pa Touche se lajan ou resevwa nan yon lòt sous ki pa travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante",
            //sUIIncome:"'s gross unearned income",
            editButtonLabel: "Edite",
            perLabel: "Pa",
            ofLabel: "nan",
            residentialAddr: "Adrès Rezidansyèl",
            mailingAddress: "Adrès postal",
            fromMultiLang: " soti ",
            renderAppNoLabel: "Nimewo aplikasyon w lan:",
            youMayLabelOne: "Ou ka vle ",
            youMayLabeltwo: "telechaje aplikasyon w lan",
            youMayLabelThree: "pou plis referans.",
            whatisNextLabel: "Kisa k ap vini apre?",
            dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
            sendDocLabel:
              "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
            howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
            donotNeedLabel:
              "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
            sendLabelOne: "Ou ka ",
            sendLabelTwo: "voye dokiman w yo ban nou ",
            sendLabelThree: "nan plizyè fason",
            toLearnOneLabel:
              "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
            toLearnOneLabelTwo: "klike la a",
            manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman ban nou.",
            manyWayLabelTwo: "Jwenn plis enfòmasyon",
            howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
            weSuggestLabel: "Nou sijere",
            weSuggestLabelOne: "kreye yon kont an liy",
            weSuggestLabelTwo:
              "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
            findOutMoreLabel: "Jwenn plis enfòmasyon",
            createOnlineLabel: "Kreye yon kont an Liy",
            orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
            renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
            laterOnLabel:
              "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
            orExitLabel: "Oswa sòti san ou pa konsève travay ou",
            changingLabel:
              "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
            greatHereLabel: "Ekselan! Men sa nou te jwenn na men w.",
            lookIncorrectLabel:
              "Si nenpòt nan yo sanble pa kòrèk, pa ezite modifye.",
            IncomePerMonthLabel:
              "Èske revni brit fwaye a fè te chanje pa plis pase oswa mwens pase $100/mwa? (Obligatwa)",
            anotherHouseholdIncomeLabel:
              "Add the income of another household member",
            anotherHouseholdUnEarnedIncomeLabel:
              "Ajoute revni yon lòt manm fwaye a pa fè nan travay",
            unearnedIncome_ChangeQuestion:
              "Èske revni brit fwaye w la pa fè nan travay plis pase $100/mwa? (Obligatwa)",
            unearnedIncomeSubText1:
              "EXAMPLES: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2:
              "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3:
              "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Rezime aplikasyon",
            applicationSummaryHeader: "Rezime aplikasyon",
            viewApplicationLinkLabel: "gade sou fòma pdf",
            iagreeTermsLabel: "Mwen dakò",
            signYourAppllication:
              "Siyen Rapò Pwovizwa w la nan tape non konplè w anba a",
            continueLabel: "Kontinye",
            getStartedLabel: "Kòmanse",
            submitButtonLabel: "Soumèt Rapò Pwovizwa",
            submitSelfButtonLabel: "Soumèt depans yo ou deklare oumenm",
            expenseFieldLabel: "Dènye seksyon sa a se konsènan",
            expenseHouseutility: "Depans pou lojman ak sèvis piblik",
            expenseCaring: "Pran swen timoun ak adilt ",
            expenseChsupport: "Sipò Timoun",
            expenseMed: "Depans medikal",
            expenseAnyinfo:
              "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
            removeHoHTitle: "avètisman",
            removeHoHLabel:
              "W ap eseye efase chèf fwaye a. Si sa kòrèk, ou ka klike sou Kontinye kounye a epi DHR pral kontakte w pou plis enfòmasyon. Si se te yon erè, tanpri klike sou 'Tounen'",
            agreeContent: [
              "Nan siyen, mwen dakò ke:",
              "Mwen te li tout fòm sa a (oswa mwen te fè li li pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite yo, epi m konprann ke mwen dwe respekte règleman sa yo.",
              "Enfòmasyon m ap bay yo vre epi yo konplè dapre sa m byen konnen.",
              "M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) tout enfòmasyon m bay yo",
            ],
            didReportChanges: "Mwen te rapòte chanjman",
            didNotReportChanges: "Mwen pa t rapòte chanjman ",
            badgeChangedLabel: "Chanje",
            badgeNewLabel: "Nouvo",
            agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
            signatureErrorMsg: "Tanpri siyen Rapò Pwovizwa w la",
          }; //return End

        default:
          return;
      }
    }

    case languageConstants.contactInfo: {
      switch (language) {
        case EN:
          return {
            title: "Contact information",
            header: "My Contact Info",
            addContactInfoTitle: "Change My Address",
            addContactInfoHeader: "Change My Address",
            contactChangedQuestion:
              "Has there been any change in your contact information? (Required)",
            required: "Required",
            requiredErrorMessage: "Please choose an option.",
            addContactInfoSubHeader:
              'To submit an online SNAP Interim Report you must provide a mailing address. If you do not have an address DHR can send mail to, please call  DHR Assistance Line at <a href="tel:8773822363">877-382-2363</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR office.</a>',
            labelFirstName: "What is your first name?",
            firstNameErrMsg: "Required - Please enter first name",
            middleName: "What is your middle name?",
            middleNameErrMsg: "Please enter middle name",
            lastNamelabel: "What is your last name?",
            lastNameErrMsg: "Required - Please enter last name",
            labelSuffix: "Suffix",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "What is your date of birth?",
            dobAlertMsg: "Please enter a valid Date of Birth",
            ssnLabel: "What is your Social Security Number?",
            inputClearLabel: "I don't have one",
            ssnErrMsg: "Please enter a valid Social Security Number.",
            headerMyContactInfo: "My contact info",
            houselessLabel: "Is your household homeless?",
            resAddressLabel: "Home address",
            resSameAddressLabel: "Same as Home Address",
            mailingAddresssLabel: "Mailing address",
            houselessErrMsg: "Please select whether your having home or not",
            houseLessOptions: ["Yes", "No"],
            addrQuestionlabel: "What is your address?",
            validZipcode: "Please enter a valid Zipcode.",
            alsoMailingLabel: "Is this also your mailing address?",
            optionsSendMail: "Yes, send mail there.",
            optionsNoDiffAddress: "No, I use a different address for mail.",
            mailingQlabel: "What is your mailing address?",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            bestPhoneqlabel: "What is the best phone number to reach you?",
            inlineHelp: "This is the number the DHR will use to reach you.",
            validPhoneAlert: "Please enter a valid phone number.",
            editButtonLabel: "Edit",
            doneButtonlabel: "Done",
            phoneEditTitle: "Edit my phone details",
            phoneLabel: "Primary phone number",
            phoneNotificationQuestion: "Allow text message notifications?",
            phoneNotificationAgreeLabel:
              "Alabama DHR may be able to send you text messages about due dates, case information, office closings, and other important information.  Click yes if you would like to opt-in",
            emailEditTitle: "E-mail",
            emailLabel: "Edit my e-mail address",
            emailNotificationQuestion: "Allow email notifications?",
            emailNotificationAgreeLabel:
              "Alabama DHR may be able to send you email notifications about due dates, case information, office closings, and other important information.  Click yes if you would like to opt-in",
            emailAddressLabel: "What is your email address?",
            emailErrormsg: "Please enter a valid email address.",
            titleEmergency: "Emergency SNAP benefits",
            headerEmergency: "Emergency SNAP benefits",
            emergencySnap:
              "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
            incomeMoneylabel:
              "Does your income and money in the bank add up to less than your monthly housing expenses?",
            inlineHelpLabel:
              "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
            optionYesNo: ["Yes", "No"],
            alertSelectIncome: "Please select income you pay",
            incomeLesslabel:
              "Is your monthly income less than $150 and is your money in the bank $100 or less?",
            monthlyIncomeoptions: ["Yes", "No"],
            selectIncomeErrmsg: "Please select income you pay",
            migrantBefore: "Are you a ",
            migrantTrigger: "migrant worker",
            migrantAfter: " and is your money in the bank $100 or less?",
            migrantHelp:
              "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
            migrantOptions: ["Yes", "No"],
            migrantErrmsg: "Please select migrant you pay",
            headerMoreAbout: "More about me",
            areUsBefore: "Are you a ",
            areUsTrigger: "US citizen",
            areUsafter: "?",
            eligibleHelp:
              "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            chooseErrmsg: "Please choose citizen",
            eligibleMemberHelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            eligibleOptions: ["Yes", "No"],
            laguageBefore: "What language do you ",
            LANGUAGES: [
              "English",
              "Spanish",
              "Portuguese",
              "French",
              "Haitian Creole",
              "Chinese",
              "Vietnamese",
              "Khmer",
              "Korean",
              "Russian",
              "Italian",
              "Polish",
              "Arabic",
            ],
            languageTrigger: "prefer to speak",
            languageAfter: "?",
            comfortLanHelp:
              "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
            languageErrMsg: "Please choose language",
            genderLabel: "What is your gender?",
            genderOptions: ["Male", "Female"],
            genderErrmsg: "Please choose gender",
            labelEthnicity: "What is your ethnicity?",
            helpEthnicity:
              "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "What is your race?",
            raceHelp:
              "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            optionAIdisplay: "American Indian",
            optionAsiandisplay_VALUE: "ASIAN",
            optionAsiandisplay: "Asian",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Black/African American",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Pacific Islander",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "White",
            declineToAnswerLabel: "Decline to answer",
            headOfHousehold: "Head of Household",
          };

        case ES:
          return {
            title: "Información de contacto",
            header: "Mi información de contacto",
            addContactInfoTitle: "Cambiar mi dirección",
            addContactInfoHeader: "Cambiar mi dirección",
            contactChangedQuestion:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",
            required: "Obligatorio",
            requiredErrorMessage: "Escoja una opción",
            addContactInfoSubHeader:
              'Para presentar un Informe provisional del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de asistencia del DHR al <a href="tel:8773822363">877-382-2363</a> o acuda a su <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">oficina local del DHR.</a>',
            labelFirstName: "¿Cuál es su nombre?",
            firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            middleName: "¿Cuál es su segundo nombre?",
            middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            lastNamelabel: "¿Cuál es su apellido?",
            lastNameErrMsg: "Requerido: ingrese el apellido",
            labelSuffix: "Sufijo",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
            resSameAddressLabel: "Igual que el domicilio",
            dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
            ssnLabel: "¿Cuál es su número de seguro social?",
            inputClearLabel: "No tengo",
            ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
            headerMyContactInfo: "Mi información de contacto",
            houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
            resAddressLabel: "Dirección residencial",
            mailingAddresssLabel: "Dirección postal",
            houselessErrMsg: "Seleccione si tiene o no su hogar",
            houseLessOptions: ["Sí", "No"],
            addrQuestionlabel: "¿Cuál es su dirección?",
            validZipcode: "Por favor ingrese un código postal válido",
            alsoMailingLabel: "¿Es igual a su dirección postal?",
            optionsSendMail: "Sí, enviar correo allí. ",
            optionsNoDiffAddress:
              "No, utilizo una dirección diferente para el correo postal.",
            mailingQlabel: "¿Cuál es su dirección postal?",
            zipCodeErrmsg: "Por favor ingrese un código postal válido",
            bestPhoneqlabel:
              "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
            inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
            validPhoneAlert: "Por favor ingrese un número de teléfono válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Listo",
            phoneEditTitle: "Editar los detalles de mi teléfono",
            phoneLabel: "Teléfono principal",
            phoneNotificationQuestion:
              "¿Permitir notificaciones por mensaje de texto?",
            phoneNotificationAgreeLabel:
              "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailEditTitle: "Dirección de correo electrónico",
            emailLabel: "Editar mi dirección de correo electrónico",
            emailNotificationQuestion:
              "Permitir notificaciones por correo electrónico?",
            emailNotificationAgreeLabel:
              "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
            emailErrormsg:
              "Por favor, introduce una dirección de correo electrónico válida",
            titleEmergency: "Beneficios de SNAP de emergencia",
            headerEmergency: "Beneficios de SNAP de emergencia",
            emergencySnap:
              "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
            incomeMoneylabel:
              "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
            inlineHelpLabel:
              "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
            optionYesNo: ["Sí", "No"],
            alertSelectIncome: "Por favor seleccione el ingreso que paga",
            incomeLesslabel:
              "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
            monthlyIncomeoptions: ["Sí", "No"],
            selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
            migrantBefore: "¿Es usted un ",
            migrantTrigger: " trabajador migrante",
            migrantAfter: " y su dinero en el banco es $100 o menos?",
            migrantHelp:
              "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
            migrantOptions: ["Sí", "No"],
            migrantErrmsg: "Por favor selecciona migrante pagas",
            headerMoreAbout: "Más sobre mí",
            areUsBefore: "¿Es usted ",
            areUsTrigger: "ciudadano de EE. UU.",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            chooseErrmsg: "Por favor elija ciudadano",
            eligibleMemberHelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            eligibleOptions: ["Sí", "No"],
            LANGUAGES: [
              "Inglés",
              "Español",
              "Portugués",
              "Francés",
              "Criollo haitiano",
              "Chino",
              "Vietnamita",
              "Camboyano",
              "Coreano",
              "Ruso",
              "Italiano",
              "Polaco",
              "Árabe",
            ],
            laguageBefore: "¿Qué idioma ",
            languageTrigger: " prefiere hablar",
            languageAfter: "?",
            comfortLanHelp:
              "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
            languageErrMsg: "Por favor, elija el idioma",
            genderLabel: "¿Cuál es su sexo?",
            genderOptions: ["Masculino", "Femenino"],
            genderErrmsg: "Por favor elija género",
            labelEthnicity: "¿Cuál es tu origen étnico?",
            helpEthnicity:
              "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            hisPanicOption: "Hispano/Latino",
            nonhisPanicOption: "No hispano/Latino",
            raceLabel: "¿Cuál es tu raza?",
            raceHelp:
              "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            optionAIdisplay: "Indio americano",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afroamericano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Isleño del Pacífico",
            optionwhitedisplay_VALUE: "Blanco",
            optionwhitedisplay: "Blanco",
            declineToAnswerLabel: "Me niego a responder",
          };

        case PT: //portugeesh
          return {
            title: "Dados de contato",
            header: "Minhas informações de contato",
            addContactInfoTitle: "Alterar meu endereço",
            addContactInfoHeader: "Alterar meu endereço",
            contactChangedQuestion:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
            required: "Obrigatório",
            requiredErrorMessage: "Por favor escolha uma opção",
            addContactInfoSubHeader:
              'Para enviar um Relatório Interino SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para o Serviço de Atendimento DHR no número <a href="tel:8773822363">877-382-2363</a> ou compareça ao seu <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">escritório do DHR local.</a> ',
            labelFirstName: "Qual é o seu primeiro nome?",
            firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
            middleName: "Qual é o seu nome do meio?",
            middleNameErrMsg: "Digite o nome do meio",
            lastNamelabel: "Qual é o seu último nome?",
            lastNameErrMsg: "Obrigatório - Digite o sobrenome",
            labelSuffix: "Sufixo",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "Qual é a sua data de nascimento?",
            dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
            ssnLabel: "Qual é o número do seu seguro social?",
            inputClearLabel: "Eu não tenho",
            ssnErrMsg: "Digite um Número de Segurança Social válido",
            headerMyContactInfo: "Minhas informações de contato",
            houselessLabel: "Sua família não tem moradia?",
            resAddressLabel: "Endereço residencial",
            resSameAddressLabel: "O mesmo endereço residencial",
            mailingAddresssLabel: "Endereço de correspondência",
            houselessErrMsg: "Selecione se você tem casa ou não",
            houseLessOptions: ["Sim", "Não"],
            addrQuestionlabel: "Qual é o seu endereço?",
            validZipcode: "Por favor insira um código postal válido",
            alsoMailingLabel:
              "Esse também é o seu endereço de correspondência?",
            optionsSendMail:
              "Sim, envie as correspondências para esse endereço. ",
            optionsNoDiffAddress:
              "Não, eu uso um endereço diferente para correspondência.",
            mailingQlabel: "Qual é o seu endereço de correspondência? ",
            zipCodeErrmsg: "Por favor insira um código postal válido",
            bestPhoneqlabel:
              "Qual é o melhor número de telefone para falar com você?",
            inlineHelp:
              "Esse será o número que o DHR irá usar para falar com você.",
            validPhoneAlert: "Por favor insira um número de telefone válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Concluir",
            phoneEditTitle: "Editar of detalhes do meu telefone",
            phoneLabel: "Número de telefone principal",
            phoneNotificationQuestion:
              "Permitir notificações por mensagens de texto",
            phoneNotificationAgreeLabel:
              "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
            emailEditTitle: "Endereço de e-mail",
            emailLabel: "Editar of detalhes do meu endereço.",
            emailNotificationQuestion: "Permitir notificações por e-mail?",
            emailNotificationAgreeLabel:
              "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
            emailAddressLabel: "Qual é o seu endereço de e-mail?",
            emailErrormsg: "Por favor insira um endereço de e-mail válido",
            titleEmergency: "Benefício SNAP emergencial",
            headerEmergency: "Benefício SNAP emergencial",
            emergencySnap:
              "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
            incomeMoneylabel:
              "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
            inlineHelpLabel:
              'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
            optionYesNo: ["Sim", "Não"],
            alertSelectIncome: "Selecione a renda que você paga",
            incomeLesslabel:
              "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
            monthlyIncomeoptions: ["Sim", "Não"],
            selectIncomeErrmsg: "Selecione a renda que você paga",
            migrantBefore: "Você é um ",
            migrantTrigger: "trabalhador migrante",
            migrantAfter: " e tem US$ 100 ou menos no banco?",
            migrantHelp:
              "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
            migrantOptions: ["Sim", "Não"],
            migrantErrmsg: "Selecione o migrante que você paga",
            headerMoreAbout: "Mais sobre mim",
            areUsBefore: "Você é ",
            areUsTrigger: "cidadão americano",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            chooseErrmsg: "Escolha cidadão",
            eligibleMemberHelp:
              "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
            eligibleOptions: ["Sim", "Não"],
            laguageBefore: "Em qual idioma você ",
            LANGUAGES: [
              "Inglês",
              "Espanhol",
              "Português",
              "Francês",
              "Créole haitiano",
              "Chinês",
              "Vietnamita",
              "Khmer",
              "Coreano",
              "Russo",
              "Italiano",
              "Polonês",
              "Árabe",
            ],
            languageTrigger: "prefere se comunicar",
            languageAfter: "?",
            comfortLanHelp:
              "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
            languageErrMsg: "Escolha o idioma",
            genderLabel: "Qual é o seu gênero?",
            genderOptions: ["Masculino", "Feminino"],
            genderErrmsg: "Escolha o gênero",
            labelEthnicity: "Qual é a sua etnia?",
            helpEthnicity:
              "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            raceLabel: "Qual é a sua raça?",
            hisPanicOption: "Hispânico/Latino",
            nonhisPanicOption: "Não-Hispânico/Latino",
            raceHelp:
              "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            optionAIdisplay: "Americano nativo",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afro-americano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Nativo das ilhas do Pacífico",
            optionwhitedisplay_VALUE: "Branco",
            optionwhitedisplay: "Branco",
            declineToAnswerLabel: "Não quero responder",
          };

        case ZH: //China
          return {
            title: "联系信息",
            header: "我的联系信息",
            addContactInfoTitle: "更改我的地址",
            addContactInfoHeader: "更改我的地址",
            contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
            required: "必填",
            requiredErrorMessage: "请选择一个选项",
            resSameAddressLabel: "与居住地址相同",
            addContactInfoSubHeader:
              '如需在线提交 SNAP《中间报告》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请拨打 DHR 援助热线 <a href="tel:8773822363"> 877-382-2363</a>  或前往您当地的 <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR</a>办公室。',
            labelFirstName: "您的名字",
            firstNameErrMsg: "必填 - 請輸入名字",
            middleName: "您的中间名",
            middleNameErrMsg: "請輸入中間名",
            lastNamelabel: "您的姓氏",
            lastNameErrMsg: "必填 - 請輸入姓氏",
            labelSuffix: "后缀",
            suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
            dateOfBirthLabel: "您的出生日期？",
            dobAlertMsg: "請輸入有效的出生日期",
            ssnLabel: "你的社保号码？",
            inputClearLabel: "我没有社保",
            ssnErrMsg: "請輸入有效的社會安全號碼",
            headerMyContactInfo: "我的联系信息",
            houselessLabel: "您的家庭是否无家可归？",
            houselessErrMsg: "請選擇您是否有家",
            houseLessOptions: ["是", "否"],
            addrQuestionlabel: "您的家庭地址？",
            validZipcode: "請輸入有效的郵政編碼",
            alsoMailingLabel: "这是否也是您的邮寄地址？",
            optionsSendMail: "是，可以发送信件到这里。",
            optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
            mailingQlabel: "您的邮箱地址？",
            zipCodeErrmsg: "請輸入有效的郵政編碼",
            bestPhoneqlabel: "您最常使用的电话号码？",
            inlineHelp: "这将是DTA与您联络所使用的电话号码。",
            validPhoneAlert: "請輸入一個有效的電話號碼",
            editButtonLabel: "编辑",
            doneButtonlabel: "完成",
            mailingAddresssLabel: "邮寄地址",
            phoneEditTitle: "编辑我的手机详情",
            phoneLabel: "主要电话号码",
            phoneNotificationQuestion: "允许短信通知？",
            phoneNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
            emailEditTitle: "电子邮箱地址",
            emailLabel: "编辑我的电子邮件地址",
            emailNotificationQuestion: "允许电子邮件通知?",
            emailNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
            emailAddressLabel: "您的邮箱地址？",
            emailErrormsg: "請輸入有效的電子郵件地址",
            titleEmergency: "紧急SNAP补助",
            headerEmergency: "紧急SNAP补助",
            emergencySnap:
              "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
            incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
            inlineHelpLabel:
              "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
            optionYesNo: ["是", "否"],
            alertSelectIncome: "請選擇您支付的收入",
            incomeLesslabel:
              "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
            monthlyIncomeoptions: ["是", "否"],
            selectIncomeErrmsg: "請選擇您支付的收入",
            migrantBefore: "您是否是 ",
            migrantTrigger: "外来务工人员",
            migrantAfter: " 您的银行存款是否少于100美元？",
            migrantHelp:
              "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
            migrantOptions: ["是 ", "否"],
            migrantErrmsg: "請選擇你支付的移民",
            headerMoreAbout: "关于我的更多信息",
            areUsBefore: "您是否是 ",
            areUsTrigger: "美国公民",
            areUsafter: "?",
            eligibleHelp:
              "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            chooseErrmsg: "請選擇公民",
            eligibleMemberHelp:
              "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
            eligibleOptions: ["是", "否"],
            resAddressLabel: "住宅地址",
            laguageBefore: "您 ",
            LANGUAGES: [
              "英语",
              "西班牙语",
              "葡萄牙语",
              "法语",
              "海地克里奥尔语",
              "中文",
              "越南语",
              "柬埔寨语",
              "朝鮮的",
              "俄语",
              "意大利语",
              "波兰语",
              "阿拉伯语",
            ],
            languageTrigger: "日常使用哪种语言交流",
            languageAfter: "?",
            comfortLanHelp:
              "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
            languageErrMsg: "請選擇語言",
            genderLabel: "您的性别？",
            genderOptions: ["男", "女"],
            genderErrmsg: "請選擇性別",
            labelEthnicity: "您的民族？",
            helpEthnicity:
              "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            hisPanicOption: "西班牙/拉丁美洲",
            nonhisPanicOption: "非西班牙/拉丁美洲",
            raceLabel: "您的种族？",
            raceHelp:
              "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            optionAIdisplay: "美洲印第安人",
            optionAsiandisplay_VALUE: "亚洲人",
            optionAsiandisplay: "亚洲人",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "黑人/非裔美国人",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "太平洋岛民",
            optionwhitedisplay_VALUE: "白人",
            optionwhitedisplay: "白人",
            declineToAnswerLabel: "拒绝回答",
          };

        case VI: //Vietnam
          return {
            title: "Thông tin liên lạc",
            header: "Thông tin liên lạc",
            addContactInfoTitle: "Thay đổi địa chỉ của tôi",
            addContactInfoHeader: "Thay đổi địa chỉ của tôi",
            contactChangedQuestion:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
            required: "Bắt buộc",
            resSameAddressLabel: "Giống như địa chỉ nhà ở",
            requiredErrorMessage: "Vui lòng chọn một lựa chọn",
            addContactInfoSubHeader:
              'Để gửi Báo cáo Giữa kỳ SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, vui lòng gọi cho Đường Dây Hỗ Trợ DHR tại số  <a href="tel:8773822363">877-382-2363</a> hoặc tới <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">văn phòng DHR tại địa phương của bạn.</a>',
            labelFirstName: "Tên của quý vị?",
            firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
            middleName: "Tên lót của quý vị?",
            middleNameErrMsg: "Vui lòng nhập tên đệm",
            lastNamelabel: "Họ của quý vị?",
            lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
            labelSuffix: "Tước hiệu",
            suffixOption: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
            dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
            ssnLabel: "Số an sinh xã hội của quý vị?",
            inputClearLabel: "Tôi không có số ASXH",
            mailingAddresssLabel: "Địa chỉ gửi thư",
            ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            headerMyContactInfo: "Thông tin liên lạc",
            resAddressLabel: "Địa chỉ cư trú",
            houselessLabel: "Gia đình quý vị có chỗ ở không?",
            houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            houseLessOptions: ["Có", "Không"],
            addrQuestionlabel: "Địa chỉ của quý vị là gì?",
            validZipcode: "Vui lòng nhập một mã zip hợp lệ",
            alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
            optionsSendMail: "Phải, hãy gửi thư đến đó.",
            optionsNoDiffAddress:
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
            zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
            bestPhoneqlabel:
              "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
            inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
            validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
            editButtonLabel: "Chỉnh sửa",
            doneButtonlabel: "Hoàn tất",
            phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
            phoneLabel: "Số điện thoại chính",
            phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
            phoneNotificationAgreeLabel:
              "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailEditTitle: "Địa chỉ email",
            emailLabel: "Chỉnh sửa địa chỉ email của tôi",
            emailNotificationQuestion: "Cho phép các thông báo bằng email?",
            emailNotificationAgreeLabel:
              "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailAddressLabel: "Địa chỉ email của quý vị là gì?",
            emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
            titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            emergencySnap:
              "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
            incomeMoneylabel:
              "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
            inlineHelpLabel:
              "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
            optionYesNo: ["Có", "Không"],
            alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
            incomeLesslabel:
              "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
            monthlyIncomeoptions: ["Có", "Không"],
            selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
            migrantBefore: "Quý vị có phải là ",
            migrantTrigger: "công nhân di cư",
            migrantAfter:
              " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
            migrantHelp:
              "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
            migrantOptions: ["Có", "Không"],
            migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
            headerMoreAbout: "Thông tin thêm về tôi",
            areUsBefore: "Quý vị có là một ",
            areUsTrigger: "Công dân Hoa kỳ",
            areUsafter: "?",
            eligibleHelp:
              "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            chooseErrmsg: "Vui lòng chọn công dân",
            eligibleMemberHelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong gia đình vẫn có thể đủ điều kiện để hưởng các lợi ích.",
            eligibleOptions: ["Có", "Không"],
            laguageBefore: "Quý vị ",
            LANGUAGES: [
              "Tiếng Anh",
              "Tiếng Tây Ban Nha",
              "Tiếng Bồ Đào nha",
              "Tiếng Pháp",
              "Tiếng Haitian Creole",
              "Tiếng Trung quốc",
              "Tiếng Việt",
              "Tiếng Campuchia",
              "Hàn Quốc",
              "Tiếng Nga",
              "Tiếng Ý",
              "Tiếng Ba Lan",
              "Tiếng Ả rập",
            ],
            languageTrigger: " thích nói ngôn ngữ nào hơn",
            languageAfter: "?",
            comfortLanHelp:
              "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
            languageErrMsg: "Vui lòng chọn ngôn ngữ",
            genderLabel: "Giới tính của quý vị là gì?",
            genderOptions: ["Nam", "Nữ"],
            genderErrmsg: "Vui lòng chọn giới tính",
            labelEthnicity: "Quý vị thuộc dân tộc nào?",
            hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
            nonhisPanicOption:
              "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
            helpEthnicity:
              "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            raceLabel: "Quý vị thuộc chủng tộc nào?",
            raceHelp:
              "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            optionAIdisplay: "Người Mỹ bản xứ",
            optionAsiandisplay_VALUE: "Người Châu Á",
            optionAsiandisplay: "Người Châu Á",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
            optionwhitedisplay_VALUE: "Người da trắng",
            optionwhitedisplay: "Người da trắng",
            declineToAnswerLabel: "Từ chối trả lời",
          };
        case HI:
          return {
            title: "Enfòmasyon sou kontak",
            header: "Enfòmasyon kontak mwen",
            addContactInfoTitle: "Chanje adrès mwen",
            addContactInfoHeader: "Chanje adrès mwen",
            contactChangedQuestion:
              "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",
            required: "Obligatwa",
            requiredErrorMessage: "Tanpri chwazi yon opsyon",
            addContactInfoSubHeader:
              'Pou soumèt yon Rapò Pwovizwa SNAP an liy ou dwe bay yon adrès postal. Si w pa gen yon adrès kote DHR ka voye lapòs, tanpri rele Liy Asistans DHR a nan <a href="tel:8773822363">877-382-2363</a>  oswa ale nan biwo lokal <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR w la.</a>',
            labelFirstName: "Ki prenon w?",
            firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
            middleName: "Ki dezyèm prenon w?",
            middleNameErrMsg: "Tanpri antre dezyèm prenon",
            lastNamelabel: "Ki siyati w?",
            lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
            labelSuffix: "Sifiks",
            suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            dateOfBirthLabel: "Ki dat nesans ou?",
            dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
            ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
            inputClearLabel: "M pa genyen youn",
            ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            headerMyContactInfo: "Enfòmasyon kontak mwen",
            houselessLabel: "Èske fwaye w la sanzabri?",
            resAddressLabel: "Adrès rezidansyèl",
            resSameAddressLabel: "Menm ak Adrès Rezidansyèl",
            mailingAddresssLabel: "Adrès postal",
            houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
            houseLessOptions: ["Wi", "Non"],
            addrQuestionlabel: "Ki adrès ou?",
            validZipcode: "Tanpri antre yon kòd postal ki valid",
            alsoMailingLabel: "Èske se adrès postal ou a tou?",
            optionsSendMail: "Wi, voye lapòs la.",
            optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
            mailingQlabel: "Ki adrès postal ou?",
            zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
            bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
            inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
            validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
            editButtonLabel: "Edite",
            doneButtonlabel: "Fini",
            phoneEditTitle: "Edite detay telefòn mwen yo",
            phoneLabel: "Nimewo telefòn prensipal la",
            phoneNotificationQuestion: "Pèmèt notifikasyon mesaj tèks?",
            phoneNotificationAgreeLabel:
              "DHR ka anmezi pou voye mesaj tèks ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe.",
            emailEditTitle: "Imel",
            emailLabel: "Korije adrès imel mwen",
            emailNotificationQuestion: "Pèmèt notifikasyon imel?",
            emailNotificationAgreeLabel:
              "DHR ka anmezi pou voye imèl notifikasyon ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe",
            emailAddressLabel: "Ki adrès imel ou?",
            emailErrormsg: "Tanpri antre yon adrès imel ki valid",
            titleEmergency: "Benefis SNAP nan Ijans",
            headerEmergency: "Benefis SNAP nan Ijans",
            emergencySnap:
              "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
            incomeMoneylabel:
              "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
            inlineHelpLabel:
              "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
            optionYesNo: ["Wi", "Non"],
            alertSelectIncome: "Tanpri chwazi revni ou peye",
            incomeLesslabel:
              "Èske revni mansyèl ou mwens pase $150epi èske lajan labank ou se $100 oswa mwens?",
            monthlyIncomeoptions: ["Wi", "Non"],
            selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
            migrantBefore: "Èske w se yon ",
            migrantTrigger: "travayè migran",
            migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
            migrantHelp:
              "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
            migrantOptions: ["Wi", "Non"],
            migrantErrmsg: "Tanpri chwazi migran ou peye",
            headerMoreAbout: "Plis konsènan mwen menm",
            areUsBefore: "Èske w se yon ",
            areUsTrigger: "sitwayen Ameriken",
            areUsafter: "?",
            eligibleHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            chooseErrmsg: "Tanpri chwazi sitwayen",
            eligibleMemberHelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            eligibleOptions: ["Wi", "Non"],
            LANGUAGES: [
              "Angle",
              "Panyòl",
              "Pòtigè",
              "Franse",
              "Kreyòl Ayisyen",
              "Chinwa",
              "Vyetnamyen",
              "Kmè",
              "Korean",
              "Ris",
              "Italyen",
              "Polonè",
              "Arabik",
            ],
            languageBefore: "Ki lang ou ",
            languageTrigger: "prefere pale ",
            languageAfter: "?",
            comfortLanHelp:
              " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
            languageErrMsg: "Tanpri chwazi ki lang",
            genderLabel: "Ki sèks ou?",
            genderOptions: ["Gason", "Fi"],
            genderErrmsg: "Tanpri chwazi sèks",
            labelEthnicity: "Ki etnisite ou?",
            helpEthnicity:
              "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "Ki ras ou?",
            raceHelp:
              "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            optionAIdisplay: "Ameriken Endyen",
            optionAsiandisplay_VALUE: "Azyatik",
            optionAsiandisplay: "Azyatik",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Nwa/Afriken Ameriken",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "moun Zile Pasifik",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "Blan",
            declineToAnswerLabel: "Refize reponn",
          };
        default:
          return;
      }
    }

    case languageConstants.householdStatic: {
      switch (language) {
        case EN:
          return {
            houseTitle: "Household members",
            houseHeader: "About My Household",
            howManyLabel:
              "How many people live in your household, including you?",
            houseHelp:
              "If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.",
            houseErrMsg: "Please choose no.of household",
            houseMemberTitle: "Add a New Household Member",
            houseMemberHeader: "Your first household member is ",
            houseMemberSubHeader1:
              "Please fill in the information for anyone that has moved into your household. If a new member has income, please attach proof of the income such as check stubs or statement from employer, award letter, and written statements to verify the income.",
            houseMemberSubHeader2:
              "We can give Food Assistance benefits only to people who give us their social security number or proof that they have applied for a social security number. You don't have to give us the social security number or citizenship status for the people you do not want Food Assistance benefits for. (We have to ask for ethnicity and race, but you don't have to answer. Your answer won't affect how many benefits you get or how soon you get them).",
            houseFirstName: "What is this member's first name?",
            firstErrmsg: "Please enter first name.",
            houseMiddleLabel: "What is this member's middle name?",
            houseMiddleName: "Please enter middle name",
            houseLastNameLabel: "What is this member's last name?",
            enterLastname: "Please enter last name.",
            enterDOB: "Please enter a valid date of birth.",
            suffixLabel: "Suffix",
            SuffixOptions: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            relationQlabel: "What is this member's relationship to you?",
            spouseDisplay: "Spouse",
            motherDisplay: "Mother",
            faterDisplay: "Father",
            daughterDisplay: "Daughter",
            sonDisplay: "Son",
            brotherDisplay: "Brother",
            sisterDisplay: "Sister",
            grandMaDisplay: "Grandmother",
            grandFaDisplay: "Grandfather",
            grandDaugther: "Granddaughter",
            grandSon: "Grandson",
            otherDisplay: "Other",
            houseDobLabel: "What is this member's date of birth?",
            personGenLabel: "What is this member's gender?",
            genderOptios: ["Male", "Female"],
            citiBeforeQLabel: "Is this member a ",
            citiTriggerQLabel: "US Citizen",
            citiAfterQLabel: "?",
            citizenHelp:
              " 'To be eligible for SNAP, a member must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            citizenInhelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            citizenOptions: ["Yes", "No"],
            ssnQLabel: "What is this member's Social Security Number?",
            ssnDntLabel: "Doesn't have one",
            ssnErrLabel: "Please enter a valid Social Security Number.",
            hholdHeader: "Household details",
            disabilityQLabel:
              "Does anyone in the house have a physical or mental ",
            disabilityQlabel2: "",
            disabilityQTriggerLabel: "disability",
            disabilityHelp:
              "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
            disablilityCler: "No one",
            buyFoodLabel:
              "Do you regularly purchase and prepare food together?",
            buyFoodOptions: ["Yes", "No"],
            personIncomLabel: "Does this member have any income?",
            personIncomeOptions: ["Yes", "No"],
            headTitle: "Head of Household",
            removeButtonLabel: "Remove",
            undoButtonLabel: "Undo",
            changedLabel: "Changed",
            removedLabel: "Removed",
            newLabel: "New",
            membersTitle: "Members of Household",
            additionalMembersTitle: "Additional Household Members ",
            addMemberButtonLabel: "Add a new household member",
            householdChangedQuestionLabel:
              "Has anyone moved into or out of your household?",
            inlineHelpSSN:
              "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength:
              "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1:
              "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2:
              "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3:
              "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4:
              "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5:
              "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6:
              "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel:
              "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
            rightsHHRemovedText:
              "Important: DHR must speak with you to confirm changes.",
          };

        case ES: //spanish
          return {
            houseTitle: "Miembros del grupo familiar",
            houseHeader: "Acerca de mi grupo familiar",
            howManyLabel:
              "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
            houseHelp:
              "Si compra y prepara más de 2/3 de sus comidas con otras personas, esas personas deben estar en su núcleo familiar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su núcleo familiar aunque usted no compre y prepare comidas con ellos.",
            houseErrMsg: "Por favor, elija no.of hogar",
            headTitle: "Jefe/a de familia",
            houseMemberTitle: "Agregar un nuevo miembro al grupo familiar",
            houseMemberHeader: "Su primer miembro del hogar es ",
            houseFirstName: "¿Cuál es el nombre de esta persona?",
            firstErrmsg: "Por favor, introduzca el primer nombre",
            houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
            houseMiddleName: "Por favor ingrese un segundo nombre",
            houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
            enterLastname: "Por favor ingrese el apellido",
            enterDOB: "Ingrese una fecha de nacimiento válida.",
            suffixLabel: "Sufijo",
            SuffixOptions: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            relationQlabel:
              "¿Cuál es la relación entre usted y de esta persona?",
            spouseDisplay: "Cónyuge",
            motherDisplay: "Madre",
            faterDisplay: "Padre",
            daughterDisplay: "Hija",
            sonDisplay: "Hijo",
            brotherDisplay: "Hermano",
            sisterDisplay: "Hermana",
            grandMaDisplay: "Abuela",
            grandFaDisplay: "Abuelo",
            grandDaugther: "Nieta",
            grandSon: "Nieto",
            otherDisplay: "Otro",
            houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
            personGenLabel: "¿Cuál es el género de esta persona?",
            genderOptios: ["Masculino", "Femenino"],
            citiBeforeQLabel: "¿Es esta persona ",
            citiTriggerQLabel: "ciudadano/a estadounidense",
            citiAfterQLabel: "?",
            addMemberButtonLabel: "Agregar un nuevo miembro al grupo familiar",
            citizenHelp:
              " 'Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            citizenInhelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            citizenOptions: ["Sí", "No"],
            ssnQLabel: "¿Cuál es el Número de Seguro Social de esta persona?",
            ssnDntLabel: "No tiene",
            ssnErrLabel: "Ingrese un Número de Seguro Social válido.",
            hholdHeader: "Detalles de mi núcleo familiar",
            disabilityQLabel: "¿Alguna persona tiene una ",
            disabilityQlabel2: " mental o física ",
            disabilityQTriggerLabel: "discapacidad",
            disabilityHelp:
              "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
            disablilityCler: "Ninguno",
            inlineHelpSSN:
              "Según las regulaciones federales, los no ciudadanos que no están solicitando SNAP no necesitan dar su SSN o estado de ciudadanía",
            removeButtonLabel: "Eliminar",
            undoButtonLabel: "Deshacer",
            changedLabel: "Modificado",
            removedLabel: "Eliminado",
            newLabel: "Nuevo",
            membersTitle: "Miembros del grupo familiar",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel:
              "¿Alguien se ha unido o ha dejado su hogar?",
            buyFoodLabel: "¿Normalmente compran alimentos y cocinan juntos?",
            buyFoodOptions: ["Sí", "No"],
            personIncomeOptions: ["Sí", "No"],
            personIncomLabel: "¿Esta persona tiene ingresos?",
            labelEthnicity: "Cuál es tu etnia?",
            raceLabel: "¿Cuál es su raza?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Por favor ingresa otra información de la carrera",
            otherRaceErrMsgLength:
              "Ingrese otra información de la carrera de más de 3 caracteres o menos de 250 caracteres",
            helpTextSSN1:
              "La recopilación de un Número de Seguro Social (SSN) para cada miembro del hogar está autorizada según la Ley de Alimentos y Nutrición de 2008, modificada por la P.L.96-58 (7 U.S. C.2025F). La información se utilizará para determinar si su hogar es elegible o continúa siendo elegible para participar en el Programa de Asistencia Alimentaria. ",
            helpTextSSN2:
              "Su SSN se utilizará para monitorear el cumplimiento de las regulaciones del programa y para la gestión del programa. Su SSN se utilizará en comparaciones por computadora y revisiones o auditorías de programas para garantizar que su hogar sea elegible para recibir asistencia alimentaria. ",
            helpTextSSN3:
              "Proporcionar un SSN para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro. Aún deberá proporcionar información como los ingresos de este miembro. ",
            helpTextSSN4:
              "Cualquier SSN proporcionado se utilizará y divulgará de la misma manera que los SSN de los miembros elegibles del hogar.",
            helpTextSSN5:
              "El uso o divulgación de información se realizará únicamente para ciertos fines limitados permitidos según las leyes y regulaciones estatales y federales. También se puede revelar información a funcionarios encargados de hacer cumplir la ley con el fin de detener a personas que huyen para eludir la ley.",
            helpTextSSN6:
              "Si surge un reclamo de asistencia alimentaria contra su hogar, la información contenida en este informe semestral, incluidos todos los números de seguro social, puede remitirse a agencias federales y estatales, así como a agencias privadas de cobro de reclamos, para que se tomen medidas de cobro de reclamos.",
            receivedLieapOptions: ["Sí", "No"],
            receivedLieapLabel:
              "¿Ha recibido un pago del Programa de asistencia energética para hogares de bajos ingresos o espera recibir LIHEAP?",
            houseMemberSubHeader1:
              "Complete la información de cualquier persona que se haya mudado a su hogar. Si un nuevo miembro tiene ingresos, adjunte prueba de los ingresos, como talones de cheques o declaración del empleador, carta de concesión y declaraciones escritas para verificar los ingresos.",
            houseMemberSubHeader2:
              "Podemos otorgar beneficios de Asistencia Alimentaria solo a personas que nos proporcionen su número de seguro social o prueba de que han solicitado un número de seguro social. No es necesario que nos proporcione el número de seguro social o el estado de ciudadanía de las personas para las que no desea recibir beneficios de Asistencia Alimentaria. (Tenemos que preguntar por origen étnico y raza, pero no es necesario que responda. Su respuesta no afectará la cantidad de beneficios que obtenga ni la rapidez con la que los obtenga).",
            rightsHHRemovedText:
              "Importante: DHR debe hablar con usted para confirmar los cambios.",
          };

        case PT: //portugeesh
          return {
            houseTitle: "Membros do domicílio",
            houseHeader: "Sobre o meu domicílio",
            howManyLabel:
              "Quantas pessoas compõem a sua família, incluindo você?",
            houseHelp:
              "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
            houseErrMsg: "Por favor, escolha o número de casa",
            houseMemberTitle: "Adicionar um novo membro ao domicílio",
            houseMemberHeader: "Seu primeiro membro da família é ",
            houseFirstName: "Qual é o primeiro nome dessa pessoa?",
            firstErrmsg: "Por favor digite o primeiro nome",
            houseMiddleLabel: "Qual é o nome do meio dessa pessoa?",
            houseMiddleName: "Digite o nome do meio",
            houseLastNameLabel: "Qual é o último nome dessa pessoa?",
            enterLastname: "Por favor insira o sobrenome",
            enterDOB: "Insira uma data de nascimento válida",
            suffixLabel: "Sufixo",
            SuffixOptions: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            relationQlabel: "Qual é o relacionamento dessa pessoa com você?",
            spouseDisplay: "Cônjuge",
            motherDisplay: "Mãe",
            faterDisplay: "Pai",
            daughterDisplay: "Filha",
            sonDisplay: "Filho",
            brotherDisplay: "Irmão",
            sisterDisplay: "Irmã",
            grandMaDisplay: "Avó",
            grandFaDisplay: "Avô",
            grandDaugther: "Neta",
            grandSon: "Neto",
            headTitle: "Chefe do domicílio",
            otherDisplay: "Outros",
            houseDobLabel: "Qual é a data de nascimento desta pessoa? ",
            personGenLabel: "Qual é o sexo dessa pessoa?",
            genderOptios: ["Masculino", "Feminino"],
            citiBeforeQLabel: "Esta pessoa é um ",
            citiTriggerQLabel: "cidadão americano ",
            citiAfterQLabel: "?",
            citizenHelp:
              " 'Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            citizenInhelp:
              "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
            citizenOptions: ["Sim", "Não"],
            ssnQLabel: "Qual é o número do seguro social dessa pessoa?",
            ssnDntLabel: "Não tem",
            ssnErrLabel: "Digite um Número de Segurança Social válido",
            hholdHeader: "Informações da minha família",
            disabilityQLabel: "Alguém sofre de algum tipo de deficiência ",
            disabilityQlabel2: " física ou mental ",
            disabilityQTriggerLabel: "deficiência",
            disabilityHelp:
              "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
            disablilityCler: "Nenhum",
            inlineHelpSSN:
              "De acordo com as regulamentações federais, os não-cidadãos que não solicitam o SNAP não precisam fornecer seu status de SSN ou de cidadania.",
            removeButtonLabel: "Excluir",
            undoButtonLabel: "Desfazer",
            changedLabel: "Alterado",
            removedLabel: "excluído",
            newLabel: "Novo",
            membersTitle: "Membros da família",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel:
              "Alguém se juntou ou saiu da sua casa?",
            buyFoodLabel: "Vocês compram e fazem comida juntos regularmente?",
            buyFoodOptions: ["Sim", "Não"],
            personIncomeOptions: ["Sim", "Não"],
            personIncomLabel: "Essa pessoa tem algum rendimento?",
            addMemberButtonLabel: "Adicionar um novo membro ao domicílio",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength:
              "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1:
              "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2:
              "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3:
              "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4:
              "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5:
              "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6:
              "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel:
              "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };

        case ZH: //China
          return {
            houseTitle: "家庭成员",
            houseHeader: "关于我的家庭",
            howManyLabel: "包括您自己在内，您家庭中有几人？",
            houseHelp:
              "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭",
            houseErrMsg: "請選擇家庭數量",
            houseMemberTitle: "新增家庭成员",
            houseMemberHeader: "你的第一個家庭成員是 ",
            houseFirstName: "此人的名字？",
            firstErrmsg: "請輸入名字",
            houseMiddleLabel: "此人的中间名?",
            houseMiddleName: "請輸入中間名",
            houseLastNameLabel: "此人的姓氏？",
            enterLastname: "請輸入姓氏",
            enterDOB: "请输入有效出生日期",
            suffixLabel: "后缀",
            addMemberButtonLabel: "新增家庭成员",
            headTitle: "户主",
            SuffixOptions: ["一世", "II", "III", "IV", "V", "小", "高級"],
            relationQlabel: "此人与您的关系是？",
            spouseDisplay: "配偶",
            motherDisplay: "母亲",
            faterDisplay: "父亲",
            daughterDisplay: "女儿",
            sonDisplay: "儿子",
            brotherDisplay: "兄弟",
            sisterDisplay: "姐妹",
            grandMaDisplay: "祖母",
            grandFaDisplay: "祖父",
            grandDaugther: "（外）孙女",
            grandSon: "（外）孙子",
            otherDisplay: "其他",
            houseDobLabel: "此人的出生日期是？ ",
            personGenLabel: "此人的性别是？",
            genderOptios: ["男", "女"],
            citiBeforeQLabel: "此人是否是 ",
            citiTriggerQLabel: "美国公民",
            citiAfterQLabel: "?",
            citizenHelp:
              " '要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            citizenInhelp:
              "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
            citizenOptions: ["是", "否"],
            ssnQLabel: "此人的社会安全号码是？",
            ssnDntLabel: "无社会安全号码",
            ssnErrLabel: "請輸入有效的社會安全號碼",
            hholdHeader: "家庭详细情况",
            disabilityQLabel: "是否有人患有身体或精神 ",
            disabilityQlabel2: "",
            disabilityQTriggerLabel: "残疾",
            disabilityHelp:
              "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾",
            disablilityCler: "无人",
            inlineHelpSSN:
              "根据联邦法规，未申请SNAP的非公民不需要提供其SSN或公民身份",
            removeButtonLabel: "删除",
            undoButtonLabel: "撤销",
            changedLabel: "已变化",
            removedLabel: "已搬迁",
            newLabel: "新添内容",
            membersTitle: "家庭成员",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel: "有没有人加入或离开你的家庭？ ",
            buyFoodLabel: "你们是否定期一起购买并制作食物？",
            buyFoodOptions: ["是", "否"],
            personIncomeOptions: ["是", "否"],
            personIncomLabel: "此人是否有收入?",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength:
              "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1:
              "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2:
              "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3:
              "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4:
              "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5:
              "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6:
              "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel:
              "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };

        case VI: //Vietnam
          return {
            headTitle: "Chủ hộ",
            houseTitle: "Các thành viên trong hộ gia đình",
            houseHeader: "Về hộ gia đình của tôi",
            howManyLabel:
              "Có bao nhiêu người sống trong hộ gia đình của quý vị, kể cả quý vị?",
            houseHelp:
              "Nếu quý vị mua và nấu hơn 2/3 bữa ăn của quý vị với những người khác, họ phải ở trong hộ gia đình của quý vị. Nếu vợ / chồng hoặc con của quý vị dưới 22 tuổi sống chung với quý vị, họ phải ở trong hộ gia đình của quý vị ngay cả khi quý vị không mua và nấu bữa ăn cùng với họ",
            houseErrMsg: "Vui lòng chọn số hộ gia đình",
            houseMemberTitle: "Bổ sung một thành viên mới trong gia đình",
            houseMemberHeader: "Thành viên gia đình đầu tiên của bạn là ",
            houseFirstName: "Tên của người này là gì?",
            firstErrmsg: "Vui lòng nhập tên",
            houseMiddleLabel: "Tên lót của người này là gì?",
            houseMiddleName: "Vui lòng nhập tên đệm",
            houseLastNameLabel: "Họ của người này là gì?",
            enterLastname: "Vui lòng nhập họ",
            enterDOB: "Điền ngày sinh",
            suffixLabel: "Tước hiệu",
            addMemberButtonLabel: "Bổ sung một thành viên mới trong gia đình",
            SuffixOptions: [
              "I",
              "II",
              "III",
              "IV",
              "V",
              "VI",
              "VII",
              "VIII",
              "IX",
              "X",
              "Jr.",
              "Sr.",
            ],
            relationQlabel: "Mối quan hệ của người này với quý vị là gì?",
            spouseDisplay: "Vợ / chồng",
            motherDisplay: "Mẹ",
            faterDisplay: "Cha",
            daughterDisplay: "Con gái",
            sonDisplay: "Con trai",
            brotherDisplay: "Em trai",
            sisterDisplay: "Em gái",
            grandMaDisplay: "Bà ",
            grandFaDisplay: "Ông ",
            grandDaugther: "Cháu gái",
            grandSon: "Cháu trai (nội, ngoại)",
            otherDisplay: "Quan hệ khác",
            houseDobLabel: "Ngày sinh của người là gì?",
            personGenLabel: "Giới tính của người này là gì?",
            genderOptios: ["Nam", "Nữ"],
            citiBeforeQLabel: "Người này có phải là ",
            citiTriggerQLabel: "Công dân Hoa Kỳ không",
            citiAfterQLabel: "?",
            citizenHelp:
              " 'Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            citizenInhelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
            citizenOptions: ["Có", "Không"],
            ssnQLabel: "Số An sinh Xã hội của người này là gì?",
            ssnDntLabel: "Không có",
            ssnErrLabel: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            hholdHeader: "Chi tiết về hộ gia đình",
            disabilityQLabel: "Có ai bị ",
            disabilityQlabel2: " về thể chất hoặc tinh thần không ",
            disabilityQTriggerLabel: "khuyết tật",
            disabilityHelp:
              "Một người khuyết tật phải được liên bang xác nhận để được xem xét hưởng trợ cấp SNAP. Ví dụ, người người nhận SSI là người khuyết tật được liên bang xác nhận",
            disablilityCler: "Không có ai",
            inlineHelpSSN:
              "Theo quy định của Liên bang, những người không phải là công dân không đăng ký SNAP không cần phải cung cấp SSN hoặc tư cách công dân của họ.",
            removeButtonLabel: "Xóa",
            undoButtonLabel: "Hủy bỏ",
            changedLabel: "thay đổi",
            removedLabel: "xóa bỏ",
            newLabel: "mới",
            membersTitle: "Thành viên hộ gia đình",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel:
              "Có ai tham gia hoặc rời khỏi hộ gia đình của bạn?",
            buyFoodLabel:
              "Quý vị có thường xuyên mua thức ăn và nấu ăn cùng nhau không?",
            buyFoodOptions: ["Có", "Không"],
            personIncomeOptions: ["Có", "Không"],
            personIncomLabel: "Người này có thu nhập không",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength:
              "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1:
              "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2:
              "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3:
              "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4:
              "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5:
              "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6:
              "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
          };
        case HI:
          return {
            houseTitle: "Manm fwaye a",
            houseHeader: "Konsènan Kay mwen an",
            howManyLabel: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
            houseHelp:
              "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
            houseErrMsg: "Tanpri chwazi kantite moun nan fwaye",
            houseMemberTitle: "Ajoute yon nouvo manm fwaye",
            houseMemberHeader: "Premye manm nan fwaye w la se ",
            houseFirstName: "Ki prenon moun sa a?",
            firstErrmsg: "Tanpri antre prenon",
            houseMiddleLabel: "Ki dezyèm prenon moun sa a?",
            houseMiddleName: "Tanpri antre dezyèm prenon",
            houseLastNameLabel: "Ki siyati moun sa a?",
            enterLastname: "Tanpri antre siyati",
            enterDOB: "Tanpri antre yon dat nesans ki valid",
            suffixLabel: "Sifiks",
            SuffixOptions: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            relationQlabel: "Ki relasyon moun sa genyen ak ou?",
            spouseDisplay: "Konjwen",
            motherDisplay: "Manman",
            faterDisplay: "Papa",
            daughterDisplay: "Pitit fi",
            sonDisplay: "Pitit gason",
            brotherDisplay: "Frè",
            sisterDisplay: "Sè",
            grandMaDisplay: "Grann",
            grandFaDisplay: "Granpè",
            grandDaugther: "Pitit pitit fi",
            grandSon: "Pitit pitit gason",
            otherDisplay: "Lòt",
            houseDobLabel: "Ki dat nesans moun sa a?",
            personGenLabel: "Ki sèks moun sa a?",
            genderOptios: ["Gason", "Fi"],
            citiBeforeQLabel: "Èske Moun sa a se yon ",
            citiTriggerQLabel: "Sitwayen Ameriken",
            citiAfterQLabel: "?",
            citizenHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            citizenInhelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            citizenOptions: ["Wi", "Non"],
            ssnQLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
            ssnDntLabel: "Li pa gen youn",
            ssnErrLabel: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            hholdHeader: "Detay Fwaye",
            disabilityQLabel: "Èske nenpòt moun nan fwaye a gen yon ",
            disabilityQlabel2: " fizik oswa mantal",
            disabilityQTriggerLabel: "andikap",
            disabilityHelp:
              "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
            disablilityCler: "Pesonn",
            buyFoodLabel: "Èske ou regilyèman achte epi prepare repa ansanm?",
            buyFoodOptions: ["Wi", "Non"],
            personIncomLabel: "Èske moun sa a gen revni?",
            personIncomeOptions: ["Wi", "Non"],
            headTitle: "Chèf Fwaye a",
            removeButtonLabel: "Retire",
            undoButtonLabel: "Defèt",
            changedLabel: "Chanje",
            removedLabel: "Retire",
            newLabel: "Nouvo",
            membersTitle: "Manm Fwaye",
            additionalMembersTitle: "Additional Household Members ",
            addMemberButtonLabel: "Ajoute yon nouvo manm fwaye",
            householdChangedQuestionLabel:
              "Èske nenpòt moun vin jwenn oswa kite fwaye w la?",
            inlineHelpSSN:
              "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength:
              "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1:
              "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2:
              "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3:
              "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4:
              "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5:
              "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6:
              "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel:
              "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };
        default:
          return;
      }
    }

    case languageConstants.householdDynamic: {
      switch (language) {
        case EN:
          return {
            title: "Member " + name,
            header: "About Member " + name,
            conditionalLabel: "Who does " + name + " pay dependent care for?",
          };
        case ES:
          return {
            title: "miembro " + name,
            header: "Acerca de los miembros " + name,
            conditionalLabel:
              "¿Por quién paga " + name + " el cuidado de dependientes ?",
          };
        case PT:
          return {
            title: "miembro " + name,
            header: "Sobre o Membro " + name,
            conditionalLabel:
              "Para quem " + name + " paga pelos cuidados de dependentes ?",
          };
        case ZH:
          return {
            title: "會員 " + name,
            header: "關於會員 " + name,
            conditionalLabel: name + " 为谁支付亲属护理费用？",
          };
        case VI:
          return {
            title: "thành viên " + name,
            header: "Giới thiệu Thành viên " + name,
            conditionalLabel:
              name + " trả tiền chi phí chăm sóc người phụ thuộc cho ai?",
          };
        case HI:
          return {
            title: "Manm " + name,
            header: "Konsènan Manm " + name,
            conditionalLabel: "Who does " + name + " peye swen depandan?",
          };
        default:
          return;
      }
    }

    case languageConstants.earnedIncomeStatic: {
      switch (language) {
        case EN:
          return {
            emptyLabel: "'s ",
            shelterExpensesLabel: "Shelter expenses",
            amountLabel: "amount",
            wages_AmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) wages?",
            wages_EmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these wages?",
            wages_UnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) wages?",
            wages_Label: "Wages ",
            selfGross_Label: "Self employment ",
            workStudy_Label: "Work study ",
            rental_Label: "Rental income ",
            childSupportSideBar: "Child support expenses",
            childSupportSummaryHeader: "Child Support Expenses Summary",
            otherInfoSideBar: "Other information",
            childSupport_Question: "What is the child support expense for ",
            selectHouseHold_Question:
              "Please select the name of the household member, who is paying this cost?",
            childSupport_AddHouseHold:
              "Add another household member's child support",
            childSupportExpenses_Question:
              "Has any household member had a change in his/her legal obligation to pay child support? (Required)",
            doneButtonlabel: "Done",
            deleteButtonlabel: "Remove",
            titlePer: "Per",
            editButtonLabel: "Edit",
            childSupportPayLabel: "pays",
            childSupportPerLabel: "Per",
            childSupportLabel: "in child support",
            otherInfo_Question:
              "Has there been any change in your contact information? (Required)",

            incomeTitle: "Income Summary",
            earnedIncomeMenuTitle: "Income",
            earnedIncomeTitle: "Income Summary",
            unEarnedIncomeMenuTitle: "Unearned Income",
            unEarnedIncomeTitle: "Unearned Income Summary",
            incomeHeader: "About your household's income",
            aboutIncomeBefore: "Does anyone in the household receive any ",
            aboutIncomeTrigger: "income or benefits",
            incomeHelp:
              "Income is money you receive from working. Benefits are money you receive from a source other than employment.",
            clearOption: "No one",
            combineIncomeoptions: [
              "Job Income",
              "Self-Employment",
              "Room and Board Income",
              "Wages",
              "Work Study",
              "Rental Income",
              "RSDI (Retirement, Survivors, and Disability Insurance)",
              "Unemployment",
              "Pension",
              "Worker's Compensation",
              "Paid Family and Medical Leave",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "SSI (Supplemental Security Income)",
              "Veterans Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other",
            ],
            wageIncomeBeforeLabel: "What is the ",
            wageIncomeTriggerLabel: "gross income",
            wagesTargetValue: "Wages",
            selfemeploymentTargetValue: "Self-Employment",
            workStudyTargetValue: "Work Study",
            rsdiTargetValue:
              "RSDI (Retirement, Survivors, and Disability Insurance)",
            SSITargetValue: "SSI (Supplemental Security Income)",
            unEmploymentTargetValue: "Unemployment",
            childSuppTargetValue: "Child Support",
            pensionTargetValue: "Pension",
            veteranBenefitsTargetValue: "Veterans Benefits",
            rentalIncomeTargetValue: "Rental Income",
            workerCompTargetValue: "Workers Compensation",
            otherTargetValue: "Other",
            noneTargetValue: "None",

            mortageInlineHelpText:
              "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
            grossIncomeHelp:
              "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            labelOtherwage: "+ Add another wage",
            adsSelfEmployee: "+ Add another Self-Employment",
            condWorkStudy: "Work Study",
            workStudyLabel: "+ Add another Work Study",
            anotherSSILabel: "+ Add another SSI (Supplemental Security Income)",
            anotherLabel: "+ Add another",
            addRDILabel: "+ Add another Social Security (SSA /RSDI)",
            unEmpLabel: "+ Add another Unemployment",
            childSuppLabel: "Child Support",
            anotherChildsupport: "+ Add another Child Support",
            pensionLabel: "Pension",
            anotherPensionLabel: "+ Add another Pension",
            workerCompensationLabel: "+ Add another Worker's Compensation",
            veteranBenefit: "Veteran's Benefits",
            addAnotherVBenefitLabel: "+ Add another Veteran's Benefits",
            rentIncomeLabel: "Rental Income",
            repeatQuestionLabel: "+ Add another Rental Income",
            repeatincomeQuestionLabel: "+ Add another Rental Hours",
            unitsRentalOptions: ["Month", "Week"],
            questionButtonLabel: "+ Add another Other",
            incomeSummaryTitle: "Income summary",
            incomeSummaryHeader: "Income summary",
            expenseTitle: "Expenses",
            almostLabel: "Almost there!",
            houseUtlilityLabel: "Housing and Utility Costs",
            expenseHeader: "Tell us about your housing costs",
            expenseSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Heat (oil, gas, electricity or propane, etc.)",
              "Electricity for an air conditioner",
              "A fee to use an air conditioner",
              "Electricity and/or gas",
              "Phone or cell phone service",
            ],
            utilitiesClearOption: "None",
            houseQLabel: "What type of housing cost does your household have?",
            houseQOptions: [
              "Rent",
              "Mortgage",
              "Property Taxes",
              "Home Insurance",
              "Condo Fee",
            ],
            houseRentQLabel: "How much is your household's rent?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",

            unitBetween: "per",
            houseUnitOptions: ["Week", "Month", "Year"],
            unitsOptions: ["Annual",
              "Bimonthly(every two months)",
              "Biweekly(every two weeks)",
              "Monthly",
              "Quarterly",
              "Semi-annual(Twice a year)",
              "Semi-monthly(Twice a month)",
              "Weekly"],
            mortageQLabel: "How much is your household's mortgage?",
            mortageunitBetween: " ",
            mortageQoptions: ["Week", "Month", "Year"],
            mortageProptaxLabel: "How much is your household's property taxes?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Week", "Month", "Year"],
            insuranceCostQLabel:
              "How much is your household's home insurance costs?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Week", "Month", "Year"],
            insuranceClearOption: "No. I don't need to pay for this.",
            condofeeQLabel: "How much is your household's condo fee?",
            condofeepropBetween: "per",
            condofeeCostoptions: ["Week", "Month", "Year"],
            condofeeClearOption: "No. I don't need to pay for this.",
            propTaxQLabel: "Does your household have property taxes?",
            propTaxBetween: "per",
            propTaxOptions: ["Week", "Month", "Year"],
            propTaxClearOption: "No. I don't need to pay for this.",
            homeInsuranceQLabel:
              "Does your household have home insurance costs?",
            homeInsuranceBetween: "per",
            homeInsuranceUnits: ["Week", "Month", "Year"],
            homeInsuranceClearOption: "No. I don't need to pay for this.",
            condoFeeQLabel: "Does your household have a condo fee?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Week", "Month", "Year"],
            condoFeeClearOption: "No. I don't need to pay for this.",

            //new labels:
            anyUtilQcost: "Does your household have any utility costs?",
            chooseHoldErrmsg: "Please choose household utilities",
            houseCostErrmsg: "Please choose housing costs",
            rentErrMsg: "Please enter rent",
            anotherRentLabel: "+ Add another rent",
            mortageErrLabel: "Please enter mortgage",
            propTaxLabel: "Please enter property taxes",
            propTaxButtonLabel: "+ Add another property taxes",
            homeInsurbutton: "+ Add another home insurance",
            homeInsurErrmsg: "Please enter home insurance",
            condoButtonLabel: "+ Add another condo fee",
            condoFeeErrmsg: "Please enter condo fee",
            addMortageButtonLabel: "+ Add another mortgage",
            childTitle: "Child or Adult Dependent Care Costs",
            childCostHeader:
              "Tell us about your Child or Adult Dependent Care costs",
            childCostSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            careGiveObjbefore:
              "Does anyone in the household have Child or Adult Dependent Care costs? This includes costs to ",
            careGiveObjtrigger: "transport",
            careGiveObjAfter:
              " the dependent(s) to and/or from a care provider.",
            careGiveHelp:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            questionClearOption: "No one",
            kidsandadultSubheader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            costBetween: " ",
            weekMonthOptions: ["Month", "Week"],
            costOptions: ["Week", "Month", "Year"],
            yesnoOptions: ["Yes", "No"],
            targetValueYes: "Yes",
            targetValueNo: "No",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "What is the address of the provider?",
            freqTripoptions: ["Week", "Month", "Year"],
            unitMonthTargetValue: { unit: "Month" },

            childSupTitle: "Child Support Costs",
            supportCostHeader: "Child Support Costs",
            childSupportQlegalbefore:
              "Does anyone in the household have a legal obligation (court order) to pay ",
            childSupportQlegaltrigger: "child support",
            childSupportQlegalAfter: " to a child not living with you?",
            childSupportHelp:
              "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
            medicalTitle: "Medical Costs",
            medicalInlineSeethisFull: "See this full list of",
            medicalInlineEligibleMedical: " eligible medical expenses",
            medicalHeader: "Tell us about your medical costs",
            medicalSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            medicalExpBefore:
              "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
            medicalExpTrigger: " transportation",
            medicalExpAfter:
              ", over the counter medications, dental or eye care, adult diapers, etc.?",
            medicalExphelp:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            medicalInhelp: "See this full list of",
            medicalInhelpEligible: "eligible medical expenses",
            medicalAddress:
              "What is the address of the medical appointment or pharmacy?",
            zipErrmsg: "Please enter a valid Zipcode.",
            signSubmitLabel: "Sign & submit",
            signSubmitHeader: "Sign & submit",
            signSubmitSub:
              "You made it through the form! Now for the easy stuff.",
            subSectionLabel: "Submitted",
            submitLabelFinal: "Your Interim Report has been submitted!",
            tellusAbout: "Tell us about your Child support costs",
            moneyYougettitle:
              "This includes money you may get from a job, the government, or other sources.",
            jobIncomeTargetValue: "Job Income",
            roomAndBoardIncomeTargetValue: "Room and Board Income",
            jobIncomeAmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) job income?",
            jobIncomeEmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these job income?",
            jobIncomeUnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) job income?",
            jobIncomeLabel: "Job Income ",
            roomAndBoardIncomeLabel: "Room and Board Income",
            labelOtherJobIncome: "+ Add another Job Income",
            addAnotherRoomAndBoardIncome: "+ Add another Room and Board Income",
            boardIncomeAmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) room and Board Income?",
            boardIncomeEmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these room and Board Income?",
            boardIncomeUnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) room and Board Income?",
            //unearnedIncome
            anotherTANFLabel: "+ Add another TANF",
            TANFTargetValue: "TANF",
            workmanCompensationLabel: "+ Add another Workman's Compensation",
            unEmpCompensationLabel: "+ Add another Unemployment Compensation",
            interestDividendTargetValue:
              "Interest Dividends from Stock and Bonds",
            interestDividendCompensationLabel:
              "+ Add another Interest Dividends from Stock and Bonds",
            workmanTargetValue: "Workman's Compensation",
            militaryTargetValue: "Military Allotment",
            militaryCompensationLabel: "+ Add another Military Allotment",
            pensionTargetValue: "Pension or RetirementIncome",
            pensionCompensationLabel:
              "+ Add another Pension or Retirement Income",
            railroadTargetValue: "Railroad Retirement",
            railroadCompensationLabel: "+ Add another Railroad Retirement",
            hudTargetValue: "HUD Utility Assistance",
            hudCompensationLabel: "+ Add another HUD Utility Assistance",
            landTargetValue: "Income from Land Rental or Rental Property",
            landCompensationLabel:
              "+ Add another Income from Land Rental or Rental Property",
            childSupportTargetValue: "Child Support",
            childSupportCompensationLabel:
              "+ Add another Income from Child Support",
            alimonyTargetValue: "Alimony",
            alimonyCompensationLabel: "+ Add another Income from Alimony",
            contributionTargetValue: "Contributions from Friends and Relatives",
            contributionCompensationLabel:
              "+ Add another Income from Contributions from Friends and Relatives",
            courtOrderedChildSupport:
              "Is any other household member now paying court-ordered child support?",
            perLabel: "per",
            summaryLabel: "Summary",
          };
        case ES: //SPANISH
          return {
            emptyLabel: " ",
            anotherTANFLabel: "+ Añadir otro TANF",
            amountLabel: "monto",
            wages_AmountLabel:
              "¿Cuál es el monto bruto del John Doe's (8.Aug.1967) de wages?",
            wages_EmployerLabel:
              "¿Quién es el empleador de John Doe's (8.Aug.1967) para este de wages?",
            wages_UnitLabel:
              "¿Cuál es la frecuencia del John Doe's (8.Aug.1967) de wages?",
            wages_Label: "Wages from MarketBasket",
            childSupportSideBar: "Gastos de manutención infantil",
            childSupportSummaryHeader:
              "Resumen de gastos de manutención infantil",
            childSupport_Question:
              "¿Cuál es el costo de la manutención infantil para ",
            selectHouseHold_Question:
              "Seleccione el nombre del miembro del grupo familiar que está pagando este costo?",
            childSupport_AddHouseHold:
              "+ Agregar manutención infantil de otros miembros del grupo familiar",
            childSupportExpenses_Question:
              "¿Ocurrió algún cambio en su información sobre gastos de manutención infantil? (Obligatorio)",
            doneButtonlabel: "Listo",
            otherInfoSideBar: "Otra información",
            deleteButtonlabel: "Eliminar",
            titlePer: "cad",
            childSupportPayLabel: "paga",
            childSupportPerLabel: "cada",
            childSupportLabel: "en manutención infantil",
            editButtonLabel: "Editar",
            otherInfo_Question:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",

            incomeTitle: "Resumen de ingresos del trabajo",
            earnedIncomeMenuTitle: "Ingresos del trabajo",
            earnedIncomeTitle: "Resumen de ingresos del trabajo",
            unEarnedIncomeMenuTitle: "Ingresos no derivados del trabajo",
            unEarnedIncomeTitle: "Resumen de ingresos no devengados",
            incomeHeader: "Sobre el ingreso de su núcleo familiar",
            aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
            aboutIncomeTrigger: "ingresos o beneficios?",
            incomeHelp:
              "El ingreso es dinero que usted recibe por trabajar. Los beneficios son dinero que usted recibe de una fuente distinta a un empleo.",
            clearOption: "Ninguno",
            combineIncomeoptions: [
              "Salarios",
              "Trabajo independiente",
              "Trabajo estudio",
              "Ingreso por alquiler",
              "SSI (Ingreso de seguridad suplementario)",
              "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
              "Desempleo",
              "Manutención",
              "Pensión",
              "Beneficios de veteranos",
              "Compensación del trabajador",
              "Otro",
            ],
            wageIncomeBeforeLabel: "Cuál es el ",
            wageIncomeTriggerLabel: "ingresos brutos",
            wagesTargetValue: "Salarios",
            selfemeploymentTargetValue: "Trabajo independiente",
            workStudyTargetValue: "Trabajo estudio",
            rsdiTargetValue:
              "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
            SSITargetValue: "SSI (Ingreso de seguridad suplementario)",
            unEmploymentTargetValue: "Desempleo",
            childSuppTargetValue: "Manutención",
            pensionTargetValue: "Pensión",
            veteranBenefitsTargetValue: "Beneficios de veteranos",
            rentalIncomeTargetValue: "Ingreso por alquiler",
            workerCompTargetValue: "Compensación a los trabajadores",
            otherTargetValue: "Otro",
            noneTargetValue: "Ninguno",
            mortageInlineHelpText:
              "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
            grossIncomeHelp:
              "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            labelOtherwage: "+ Agrega otro Salarios",
            adsSelfEmployee: "+ Agrega otro Trabajo independiente",
            condWorkStudy: "Trabajo estudio",
            workStudyLabel: "+ Agrega otro Trabajo estudio",
            anotherSSILabel: "+ Agrega otro SSI",
            anotherLabel: "+ Agregar otro",
            addRDILabel: "+ Agregue otro RSDI",
            unEmpLabel: "+ Agrega otro Desempleo",
            childSuppLabel: "Manutención",
            anotherChildsupport: "+ Agrega otro Manutención",
            pensionLabel: "Pensión",
            anotherPensionLabel: "+ Agrega otro Pensión",
            workerCompensationLabel:
              "+ Agregue otra Compensación para Trabajadores",
            veteranBenefit: "Beneficios de veteranos",
            addAnotherVBenefitLabel: "+ Agrega otro Beneficios de veteranos",
            rentIncomeLabel: "Ingreso por alquiler",
            repeatQuestionLabel: "+ Agrega otro Ingreso por alquiler",
            repeatincomeQuestionLabel: "+ Agrega otro Ingreso por alquiler",
            unitsRentalOptions: ["Mes", "Semana"],
            questionButtonLabel: "+ Agrega otro Otro",
            incomeSummaryTitle: "Resumen de ingresos",
            incomeSummaryHeader: "Resumen de ingresos",
            expenseTitle: "Gastos",
            almostLabel: "¡Casi termina!",
            houseUtlilityLabel: " Costos de vivienda y servicios públicos",
            expenseHeader: "Díganos sobre los costos de su vivienda",
            expenseSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Calefacción (nafta, gas, electricidad o propano, etc.)",
              "Electricidad para un aire acondicionado",
              "Cargo por usar un aire acondicionado ",
              "Electricidad y/o gas",
              "Servicio de teléfono o celular",
            ],
            utilitiesClearOption: "Ninguno",
            houseQLabel:
              "¿Qué tipo de costo de vivienda tiene su núcleo familiar?",
            houseQOptions: [
              "Alquiler",
              "Hipoteca",
              "Impuestos a la propiedad",
              "Seguro de la casa",
              "Tasa de condominio",
            ],
            houseRentQLabel: "¿Cuánto es el alquiler de su grupo familiar?",
            rentTargetValue: "Alquiler",
            mortageTargetValue: "Hipoteca",
            propertyTaxTargetValue: "Impuestos a la propiedad",
            homeInsurTargetValue: "Seguro de la casa",
            condoFeeTargetValue: "Tasa de condominio",
            unitBetween: "por",
            houseUnitOptions: ["Semana", "Mes", "Año"],
            unitsOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semana", "Mes", "Año"],
            mortageProptaxLabel:
              "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semana", "Mes", "Año"],
            insuranceCostQLabel:
              "¿Cuál es el seguro de la casa de su núcleo familiar?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semana", "Mes", "Año"],
            insuranceClearOption: "No. No necesito pagar por esto.",
            condofeeQLabel:
              "¿Cuál es el tasa de condominio de su núcleo familiar?",
            condofeepropBetween: "por",
            condofeeCostoptions: ["Semana", "Mes", "Año"],
            condofeeClearOption: "No. No necesito pagar por esto.",
            propTaxQLabel: "¿Tiene su hogar impuestos a la propiedad?",
            propTaxBetween: "por",
            propTaxOptions: ["Semana", "Mes", "Año"],
            propTaxClearOption: "No. No necesito pagar por esto.",
            homeInsuranceQLabel: "¿Su hogar tiene costos de seguro de hogar?",
            homeInsuranceBetween: "por",
            homeInsuranceUnits: ["Semana", "Mes", "Año"],
            homeInsuranceClearOption: "No. No necesito pagar por esto.",
            condoFeeQLabel: "¿Su hogar tiene una tasa de condominio?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semana", "Mes", "Año"],
            condoFeeClearOption: "No. No necesito pagar por esto.",

            //new labels:
            anyUtilQcost:
              "¿Su núcleo familiar tiene costos de servicios públicos? ",
            chooseHoldErrmsg: "Por favor elija servicios domésticos",
            houseCostErrmsg: "Por favor elija los costos de la vivienda",
            rentErrMsg: "Por favor ingrese alquiler",
            anotherRentLabel: "+ Agregar otra alquiler",
            mortageErrLabel: "Por favor ingrese la hipoteca",
            propTaxLabel: "Ingrese los impuestos a la propiedad",
            propTaxButtonLabel: "+ Agregue otros impuestos a la propiedad",
            homeInsurbutton: "+ Agregue otro seguro de hogar",
            homeInsurErrmsg: "Por favor ingrese seguro de hogar",
            condoButtonLabel: "+ Agregar otra Tasa de condominio",
            condoFeeErrmsg: "Por favor ingrese el costo",
            addMortageButtonLabel: "+ Agregar otra hipoteca",
            childTitle: "Costos de cuidado de hijos o adultos dependientes",
            childCostHeader:
              "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
            childCostSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            careGiveObjbefore:
              "¿Alguna persona en su núcleo familiar tiene costos de cuidado de hijos o adultos dependientes? Esto incluye costos de ",
            careGiveObjtrigger: "transporte ",
            careGiveObjAfter: "del dependiente hacia y desde un cuidador.",
            careGiveHelp:
              "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
            questionClearOption: "Ninguno",
            kidsandadultSubheader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            costBetween: " ",
            weekMonthOptions: ["Semana", "Mes"],
            costOptions: ["Semana", "Mes", "Año"],
            yesnoOptions: ["Sí", "No"],
            targetValueYes: "Sí",
            targetValueNo: "No",
            noofAppointment: "Por favor, elija no.of citas",
            addressProvider: "¿Cuál es la dirección del proveedor?",
            freqTripoptions: ["Semana", "Mes", "Año"],
            unitMonthTargetValue: { unit: "Mes" },
            childSupTitle: "costo de la manutención infanti",
            supportCostHeader: "costo de la manutención infanti",
            childSupportQlegalbefore:
              "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
            childSupportQlegaltrigger: "manutención a un niño",
            childSupportQlegalAfter: " que no viva con usted?",
            childSupportHelp:
              "La manutención infantil pagada puede contabilizarse como un gasto cuando lo exija la ley y cuando se estén realizando los pagos. Esto incluye pagos en mora.",
            medicalTitle: "Costos médicos",
            medicalInlineSeethisFull: "Consulte la lista completa de",
            medicalInlineEligibleMedical: " gastos médicos elegibles.",
            medicalHeader: "Díganos sobre sus costos médicos",
            medicalSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            medicalExpBefore:
              "¿Alguna de las personas enumeradas a continuación paga seguro médico u otros costos médicos, incluso medicamentos con receta,",
            medicalExpTrigger: "transporte",
            medicalExpAfter:
              ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
            medicalExphelp:
              "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
            medicalInhelp: "Consulte esta lista completa de",
            medicalInhelpEligible: " gastos médicos elegibles",
            medicalAddress:
              "¿Cuál es la dirección de la cita médica o farmacia?",
            zipErrmsg: "Por favor ingrese un código postal válido.",
            signSubmitLabel: "Firmar y presentar",
            signSubmitHeader: "Firmar y presentar",
            signSubmitSub:
              "¡Completo todo el formulario! Ahora, pasemos a lo fácil.",
            subSectionLabel: "Presentada",
            submitLabelFinal: "Se presentó su Informe provisorio!",
            tellusAbout: "Díganos sobre sus costos de manutención",
            moneyYougettitle:
              "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
            courtOrderedChildSupport:
              "Is any other household member now paying court-ordered child support?",
            combineIncomeoptions: [
              "Ingresos laborales",
              "Auto-empleo",
              "Ingresos por alojamiento y comida",
              "Wages",
              "Work Study",
              "Rental Income",
              "RSDI (Retirement, Survivors, and Disability Insurance)",
              "Unemployment",
              "Pension",
              "Worker's Compensation",
              "Paid Family and Medical Leave",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Seguridad Social (SSA/RSDI)",
              "SSI (Seguridad de Ingreso Suplementario)",
              "Beneficios para veteranos",
              "Compensacion por desempleo",
              "Compensación laboral",
              "Dividendos de intereses de acciones y bonos",
              "Asignación militar",
              "Ingresos de pensión o jubilación",
              "Jubilación ferroviaria",
              "Asistencia de servicios públicos de HUD",
              "Ingresos por alquiler de terrenos o propiedades en alquiler",
              "Manutención de los hijos",
              "Pensión alimenticia",
              "Contribuciones de amigos y familiares",
              "Otro",
            ],
            jobIncomeAmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) job income?",
            jobIncomeEmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these job income?",
            jobIncomeUnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) job income?",
            jobIncomeLabel: "Job Income ",
            roomAndBoardIncomeLabel: "Room and Board Income",
            labelOtherJobIncome: "+ Agregar otro ingreso laboral",
            addAnotherRoomAndBoardIncome: "+ Agregar otro ingreso por alojamiento y comida",
            boardIncomeAmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) room and Board Income?",
            boardIncomeEmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these room and Board Income?",
            boardIncomeUnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) room and Board Income?",
            anotherTANFLabel: "+ Add another TANF",
            TANFTargetValue: "TANF",
            workmanCompensationLabel: "+ Agregar otra compensación laboral",
            unEmpCompensationLabel: "+ Agregar otra Compensación por Desempleo",
            interestDividendTargetValue:
              "Interest Dividends from Stock and Bonds",
            interestDividendCompensationLabel:
              "+ Agregue otros dividendos de intereses de acciones y bonos",
            workmanTargetValue: "Workman's Compensation",
            militaryTargetValue: "Military Allotment",
            militaryCompensationLabel: "+ Agregar otra asignación militar",
            pensionTargetValue: "Pension or RetirementIncome",
            pensionCompensationLabel:
              "+ Add another Pension or Retirement Income",
            railroadTargetValue: "Railroad Retirement",
            railroadCompensationLabel: "+ Agregar otro retiro ferroviario",
            hudTargetValue: "HUD Utility Assistance",
            hudCompensationLabel: "+ Agregar otra asistencia de servicios públicos de HUD",
            landTargetValue: "Income from Land Rental or Rental Property",
            landCompensationLabel:
              "+ Add another Income from Land Rental or Rental Property",
            childSupportTargetValue: "Child Support",
            childSupportCompensationLabel:
              "+ Agregue otro ingreso por manutención infantil",
            alimonyTargetValue: "Alimony",
            alimonyCompensationLabel: "+ Agregar otro Ingreso por Pensión Alimenticia",
            contributionTargetValue: "Contributions from Friends and Relatives",
            contributionCompensationLabel:
              "+ Añade otro Ingreso por Aportes de Amigos y Familiares",
            courtOrderedChildSupport:
              "Is any other household member now paying court-ordered child support?",
            perLabel: "per",
            summaryLabel: "Summary",
          };
        case PT: //PORTUGES
          return {
            emptyLabel: " ",
            amountLabel: "quantia",
            wages_AmountLabel:
              "Qual é o valor bruto de John Doe's (8.Aug.1967) de wages?",
            wages_EmployerLabel:
              "Quem é o empregador de John Doe's (8.Aug.1967) para estes de wages?",
            wages_UnitLabel:
              "Qual é a frequência da John Doe's de (8.Aug.1967) wages?",
            wages_Label: "Wages from MarketBasket",
            childSupportLabel: "pensão alimentícia",
            childSupport_Question:
              "Qual é o custo de pensão alimentícia da criança para ",
            selectHouseHold_Question:
              "Selecione o nome do membro do domicílio, quem está pagando esse custo?",
            childSupport_AddHouseHold:
              "+ Adicionar pensão alimentícia para outros membros da família",
            childSupportExpenses_Question:
              "Há alguma mudança nas informações sobre despesas com pensão alimentívia? (Obrigatório)",
            doneButtonlabel: "Concluir",
            deleteButtonlabel: "Excluir",
            titlePer: "por",
            childSupportPayLabel: "paga",
            otherInfoSideBar: "Outras informações",
            editButtonLabel: "Editar",
            otherInfo_Question:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",

            incomeTitle: "Resumo de rendimentos",
            childSupportPerLabel: "por",
            earnedIncomeMenuTitle: "Rendimento proveniente de trabalho",
            earnedIncomeTitle: "Resumo de rendimentos",
            unEarnedIncomeMenuTitle: "Rendimento não proveniente de trabalho",
            unEarnedIncomeTitle: "Resumo do rendimento não realizado",
            childSupportSideBar: "Despesas com pensão alimentícia",
            childSupportSummaryHeader:
              "Resumo de despesas com pensão alimentícia",
            incomeHeader: "Sobre a renda da sua família",
            aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
            aboutIncomeTrigger: " renda ou benefício?",
            incomeHelp:
              "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
            clearOption: "Nenhum",
            combineIncomeoptions: [
              "Salário",
              "Trabalho autônomo",
              "Estudo",
              "Renda de aluguel",
              "SSI (Renda de segurança suplementar)",
              "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
              "Desemprego",
              "Suporte infantil",
              "Pensão",
              "Benefício de veterano",
              "compensação do trabalhador",
              "Outros",
            ],
            wageIncomeBeforeLabel: "Qual é o ",
            wageIncomeTriggerLabel: "rendimento bruto",
            wagesTargetValue: "Salário",
            selfemeploymentTargetValue: "Trabalho autônomo",
            workStudyTargetValue: "Estudo",
            rsdiTargetValue:
              "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
            SSITargetValue: "SSI (Renda de segurança suplementar)",
            unEmploymentTargetValue: "Desemprego",
            childSuppTargetValue: "Suporte infantil",
            pensionTargetValue: "Pensão",
            veteranBenefitsTargetValue: "Benefício de veterano",
            rentalIncomeTargetValue: "Renda de aluguel",
            workerCompTargetValue: "Compensação do trabalhador",
            otherTargetValue: "Outros",
            noneTargetValue: "Nenhum",
            mortageInlineHelpText:
              "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
            grossIncomeHelp:
              "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            labelOtherwage: "+ Adicionar outro Salário",
            adsSelfEmployee: "+ Adicionar outro Trabalho autônomo",
            condWorkStudy: "Estudo",
            workStudyLabel: "+ Adicionar outro Estudo",
            anotherSSILabel: "+ Adicionar outro SSI",
            anotherLabel: "+ Adicionar outra",
            addRDILabel: "+ Adicione outro RSDI",
            unEmpLabel: "+ Adicionar outro Desemprego",
            childSuppLabel: "Suporte infantil",
            anotherChildsupport: "+ Adicionar outro Suporte infantil",
            pensionLabel: "Pensão",
            anotherPensionLabel: "+ Adicionar outro Pensão",
            workerCompensationLabel:
              "+ adicionar compensação de outro trabalhador",
            veteranBenefit: "Benefício de veterano",
            addAnotherVBenefitLabel: "+ Adicionar outro Benefício de veterano",
            rentIncomeLabel: "Renda de aluguel",
            repeatQuestionLabel: "+ Adicionar outro Renda de aluguel",
            repeatincomeQuestionLabel: "+ Adicionar outro Renda de aluguel",
            unitsRentalOptions: ["Mês", "Semana"],
            questionButtonLabel: "+ Adicionar outro Outros",
            incomeSummaryTitle: "Resumo da renda",
            incomeSummaryHeader: "Resumo da renda",
            expenseTitle: "Despesas",
            almostLabel: "Quase lá!",
            houseUtlilityLabel: "Custos residenciais e utilitários",
            expenseHeader: "Fale sobre os seus custos residenciais",
            expenseSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
              "Eletricidade para ar condicionado",
              "Uma taxa para usar um ar condicionado",
              "Eletricidade e/ou gás",
              "Serviço de telefone fixo ou celular",
            ],
            utilitiesClearOption: "Nenhum",
            houseQLabel: "Que tipo de custos residenciais a sua família tem?",
            houseQOptions: [
              "Aluguel",
              "Financiamento",
              "Impostos da propriedade",
              "Seguro residencial",
              "Condomínio",
            ],
            houseRentQLabel: "Qual é o valor do seu aluguel?",
            rentTargetValue: "Aluguel",
            mortageTargetValue: "Financiamento",
            propertyTaxTargetValue: "Impostos da propriedade",
            homeInsurTargetValue: "Seguro residencial",
            condoFeeTargetValue: "Condomínio",
            unitBetween: "por",
            houseUnitOptions: ["Semana", "Mês", "Ano"],
            mortageQLabel: "Qual é o valor do seu financiamento?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semana", "Mês", "Ano"],
            mortageProptaxLabel:
              "Qual é o valor dos impostos da sua propriedade?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semana", "Mês", "Ano"],
            insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semana", "Mês", "Ano"],
            insuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condofeeQLabel: "Qual é o valor do seu condomínio?",
            condofeepropBetween: "por",
            condofeeCostoptions: ["Semana", "Mês", "Ano"],
            condofeeClearOption: "Não. Eu não preciso pagar por isso.",
            propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
            propTaxBetween: "por",
            propTaxOptions: ["Semana", "Mês", "Ano"],
            propTaxClearOption: "Não. Eu não preciso pagar por isso.",
            homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
            homeInsuranceBetween: "por",
            homeInsuranceUnits: ["Semana", "Mês", "Ano"],
            homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condoFeeQLabel: "A sua casa possui uma Tasa de condominio?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semana", "Mês", "Ano"],
            condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

            //new labels:
            anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
            chooseHoldErrmsg: "Escolha utilitários domésticos",
            houseCostErrmsg: "Escolha os custos de habitação",
            rentErrMsg: "Entre com aluguel",
            anotherRentLabel: "+ Adicionar outro aluguel",
            mortageErrLabel: "Insira uma hipoteca",
            propTaxLabel: "Digite os impostos sobre a propriedade",
            propTaxButtonLabel:
              "+ Adicione outros impostos sobre a propriedade",
            homeInsurbutton: "+ Adicione outro seguro de casa",
            homeInsurErrmsg: "Digite o seguro de casa",
            condoButtonLabel: "+ Adicionar outra Tasa de condominio",
            condoFeeErrmsg: "Entre com Tasa de condominio",
            addMortageButtonLabel: "+ Adicionar outros Tasa de condominio",
            childTitle: "Custos de crianças ou dependentes",
            childCostHeader:
              "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
            childCostSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            careGiveObjbefore:
              "Alguém na sua família tem custos com cuidados para crianças ou adultos dependentes? Incluindo os custos de ",
            careGiveObjtrigger: "transporte do(s)",
            careGiveObjAfter: "dependente(s) até o prestador de serviços.",
            careGiveHelp:
              "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
            questionClearOption: "Nenhum",
            kidsandadultSubheader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            costBetween: " ",
            weekMonthOptions: ["Semana", "Mês"],
            costOptions: ["Semana", "Mês", "Ano"],
            yesnoOptions: ["Sim", "Não"],
            targetValueYes: "Sim",
            targetValueNo: "Não",
            noofAppointment: "Por favor, escolha o número de compromissos",
            addressProvider: "Qual é o endereço do prestador de serviço?",
            freqTripoptions: ["Semana", "Mês", "Ano"],
            unitMonthTargetValue: { unit: "Mês" },
            childSupTitle: "costo de la manutención infanti",
            supportCostHeader: "costo de la manutención infanti",
            childSupportQlegalbefore:
              "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar pelo ",
            childSupportQlegaltrigger: " suporte de uma criança",
            childSupportQlegalAfter: " que não vive com você?",
            childSupportHelp:
              "A pensão alimentícia paga pode ser contada como uma despesa quando é legalmente exigida e os pagamentos estão sendo feitos. Isso inclui pagamentos por atrasos.",
            medicalTitle: "Custos médicos",
            medicalInlineSeethisFull: "Veja esta lista completa de",
            medicalInlineEligibleMedical: " despesas médicas elegíveis.",
            medicalHeader: "Fale sobre os seus custos médicos",
            medicalSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            medicalExpBefore:
              "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
            medicalExpTrigger: "transporte",
            medicalExpAfter:
              ", medicamentos de farmácia, dentistas, oftalmologistas, fraldas geriátricas, etc.?",
            medicalExphelp:
              "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
            medicalInhelp: "Veja esta lista completa de",
            medicalInhelpEligible: "despesas médicas elegíveis",
            medicalAddress:
              "Qual é o endereço da consulta médica ou da farmácia?",
            zipErrmsg: "Por favor insira um código postal válido",
            signSubmitLabel: "Assinar e enviar",
            signSubmitHeader: "Assinar e enviar",
            signSubmitSub:
              "Você chegou ao fim do formulário! Agora vamos à parte fácil.",
            subSectionLabel: "Submetido",
            submitLabelFinal: "Seu Relatório interino foi enviado!",
            tellusAbout: "Fale sobre os custos de suporte infantil",
            moneyYougettitle:
              "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
          };
        case ZH: //China
          return {
            emptyLabel: " ",
            amountLabel: "金额",
            wages_AmountLabel: "John Doe's (8.Aug.1967) wages 总额是多少？?",
            wages_EmployerLabel: "这些{收入类型}的雇主{姓名（出生日期）}是？",
            wages_UnitLabel: "John Doe's (8.Aug.1967) wages 的频率如何?",
            wages_Label: "Wages from MarketBasket",
            childSupport_Question: "的子女抚养费用是多少 ",
            selectHouseHold_Question: "请选择家庭成员姓名，谁支付这笔费用？",
            childSupport_AddHouseHold: "+ 添加另一位家庭成员的子女抚养费",
            childSupportExpenses_Question:
              "您的子女抚养费信息是否有任何变更？（必填）",
            doneButtonlabel: "完成",
            deleteButtonlabel: "删除",
            titlePer: "频率",
            editButtonLabel: "编辑",
            otherInfo_Question: "您的联系信息是否有任何更改？（必填）",

            earnedIncomeTitle: "劳动所得收入汇总",
            incomeTitle: "劳动所得收入汇总",
            earnedIncomeMenuTitle: "劳动所得收入",
            childSupportLabel: "子女抚养费",
            unEarnedIncomeMenuTitle: "非劳动所得收入",
            unEarnedIncomeTitle: "非劳动所得收入汇总",
            incomeHeader: "关于您的家庭收入",
            aboutIncomeBefore: "您家中是否有人获得任何 ",
            aboutIncomeTrigger: "收入或补助?",
            childSupportSideBar: "子女抚养费",
            childSupportPayLabel: "支付",
            childSupportPerLabel: "频率",
            childSupportSummaryHeader: "子女抚养费汇总",
            incomeHelp:
              "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
            clearOption: "无人",
            otherInfoSideBar: "其他信息",
            combineIncomeoptions: [
              "工资",
              "个体经营",
              "工作研究",
              "租金收入",
              "SSI（补充保障收入）",
              "RSDI（退休，幸存者和残疾保险）",
              "失业",
              "子女抚养费",
              "养老金",
              "退伍军人补贴",
              "劳动者报酬",
              "其他",
            ],
            wageIncomeBeforeLabel: "是什麼 ",
            wageIncomeTriggerLabel: "總收入",
            wagesTargetValue: "工资",
            selfemeploymentTargetValue: "个体经营",
            workStudyTargetValue: "工作研究",
            SSITargetValue: "SSI（补充保障收入）",
            unEmploymentTargetValue: "失业",
            rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
            childSuppTargetValue: "子女抚养费",
            pensionTargetValue: "养老金",
            veteranBenefitsTargetValue: "退伍军人补贴",
            rentalIncomeTargetValue: "租金收入",
            workerCompTargetValue: "工伤赔偿其",
            otherTargetValue: "其他",
            noneTargetValue: "无",
            mortageInlineHelpText:
              "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
            grossIncomeHelp:
              "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
            unitsPerLabel: " ",
            unitsOptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            labelOtherwage: "+ 添加另一個工資",
            adsSelfEmployee: "+ 添加另一個自僱",
            condWorkStudy: "工作研究",
            workStudyLabel: "+ 添加另一個工作研究",
            anotherSSILabel: "+ 添加另一個SSI",
            anotherLabel: "+ 添加另一项",
            addRDILabel: "+ 添加另一個RSDI",
            unEmpLabel: "+ 再增加一個失業",
            childSuppLabel: "子女撫養費",
            anotherChildsupport: "+ 添加另一個子支持",
            pensionLabel: "養老金",
            anotherPensionLabel: "+ 再加一個養老金",
            workerCompensationLabel: "+ 添加另一个工人的赔偿",
            veteranBenefit: "老兵的好处",
            addAnotherVBenefitLabel: "+ 添加另一個退伍軍人的好處",
            rentIncomeLabel: "贴租金收",
            repeatQuestionLabel: "+ 添加另一个租金收入",
            repeatincomeQuestionLabel: "+ 添加另一个租金收入",
            unitsRentalOptions: ["月", "周"],
            questionButtonLabel: "+ 添加另一個租金收入",
            incomeSummaryTitle: "收入摘要",
            incomeSummaryHeader: "收入摘要",
            expenseTitle: "开支",
            almostLabel: "还差一步！",
            houseUtlilityLabel: "住房和通用开支",
            expenseHeader: "请告诉我们您的家庭开支",
            expenseSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            houseHoldQLabel: "你家是否负责任何公用事业？?",
            utilityQOptions: [
              "取暖费（石油，天然气，电力或丙烷等）",
              "空调用电费",
              "空调使用费",
              "电费和/或燃气费",
              "电话费或手机费",
            ],
            utilitiesClearOption: "无",
            houseQLabel: "您的家庭有哪些住房开支？",
            houseQOptions: [
              "房租",
              "抵押贷款",
              "财产税",
              "家庭保险",
              "物业管理费用",
            ],
            houseRentQLabel: "您的住房的月租金有多少？",
            rentTargetValue: "房租",
            mortageTargetValue: "抵押贷款",
            propertyTaxTargetValue: "财产税",
            homeInsurTargetValue: "家庭保险",
            condoFeeTargetValue: "物业管理费用",
            unitBetween: "每",
            houseUnitOptions: ["周", "月", "年"],
            mortageQLabel: "您家的房贷有多少？",
            mortageunitBetween: " ",
            mortageQoptions: ["周", "月", "年"],
            mortageProptaxLabel: "您的住房的财产税有多少？",
            mortagepropBetween: " ",
            mortagepropoptions: ["周", "月", "年"],
            insuranceCostQLabel: "您的住房的房屋保险有多少？",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["周", "月", "年"],
            insuranceClearOption: "不，我不需要为此付费。",
            condofeeQLabel: "您的住房的物业管理费有多少？",
            condofeepropBetween: "每",
            condofeeCostoptions: ["周", "月", "年"],
            condofeeClearOption: "不，我不需要为此付费。",
            propTaxQLabel: "你家有財產稅嗎？",
            propTaxBetween: "每",
            propTaxOptions: ["周", "月", "年"],
            propTaxClearOption: "不，我不需要为此付费。",
            homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
            homeInsuranceBetween: "每",
            homeInsuranceUnits: ["周", "月", "年"],
            homeInsuranceClearOption: "不，我不需要为此付费。",
            condoFeeQLabel: "你家有公寓費嗎？",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["周", "月", "年"],
            condoFeeClearOption: "不，我不需要为此付费。",

            //new labels:
            anyUtilQcost: "您的家庭有哪些通用开支？",
            chooseHoldErrmsg: "请选择家用电器",
            houseCostErrmsg: "请选择住房费用",
            rentErrMsg: "请输入租金",
            anotherRentLabel: "+ 添加另一個租金",
            mortageErrLabel: "请输入抵押",
            propTaxLabel: "请输入财产税",
            propTaxButtonLabel: "+ 添加另一個財產稅",
            homeInsurbutton: "+ 添加另一個家庭保險",
            homeInsurErrmsg: "请输入家庭保险",
            condoButtonLabel: "+ 添加另一個公寓費用",
            condoFeeErrmsg: "请输入公寓费用",
            addMortageButtonLabel: "+ 添加另一個抵押",
            childTitle: "子女或成人照护费用",
            childCostHeader: "告诉我们您的子女或成人的照护费",
            childCostSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            careGiveObjbefore: "您的家人有子女或成人照护开支吗？这包括 ",
            careGiveObjtrigger: "接送",
            careGiveObjAfter: "被照护人到其看护人的费用。",
            careGiveHelp:
              "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
            questionClearOption: "无人",
            kidsandadultSubheader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            costBetween: " ",
            weekMonthOptions: ["周", "月"],
            costOptions: ["周", "月", "年"],
            yesnoOptions: ["是", "否"],
            targetValueYes: "是",
            targetValueNo: "否",
            noofAppointment: "请选择任命数量",
            addressProvider: "看护人的地址？",
            freqTripoptions: ["周", "月", "年"],
            unitMonthTargetValue: { unit: "月" },
            childSupTitle: "子女抚养费用",
            supportCostHeader: "子女抚养费用",
            childSupportQlegalbefore:
              "您家中是否有人必须向不与您同住的子女支付法定 ",
            childSupportQlegaltrigger: "子女抚养费",
            childSupportQlegalAfter: " ?",
            childSupportHelp:
              "已支付的子女抚养费在按法律要求完成付款的情况下可被记为支出费用。这包括欠款支付。",
            medicalTitle: "医疗费用",
            medicalInlineSeethisFull: "参阅此的完整列表。",
            medicalInlineEligibleMedical: "合格的医疗费用",
            medicalHeader: "请告诉我们您的医疗费",
            medicalSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            medicalExpBefore:
              "下列任何人是否支付健康保险或其他医疗费用，包括门诊，",
            medicalExpTrigger: "交通",
            medicalExpAfter: ", 非处方药，牙科或眼睛护理，成人尿片等？",
            medicalExphelp:
              "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
            medicalInhelp: "请参阅此完整列表",
            medicalInhelpEligible: "合格医疗开支的完整清单。",
            medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
            medicalAddress: "医疗机构或药房地址？",
            zipErrmsg: "請輸入有效的郵政編碼",
            signSubmitLabel: "签名并提交",
            signSubmitHeader: "签名并提交",
            signSubmitSub: "您已填写完全部表格！现在更简单。",
            subSectionLabel: "提交",
            submitLabelFinal: "您的《中间报告》已提交!",
            tellusAbout: "请告诉我们您的子女抚养费",
            moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
          };
        case VI: //Vietnam
          return {
            emptyLabel: " ",
            amountLabel: "số Tiền",
            wages_AmountLabel:
              "Tổng số tiền của John Doe's (8.Aug.1967) wages là bao nhiêu?",
            wages_EmployerLabel:
              "Ai là chủ nhân của John Doe's (8.Aug.1967)'s {Tên (Ngày sinh) cho {Loại thu nhập} này?",
            wages_UnitLabel:
              "Bao lâu một lần John Doe's (8.Aug.1967) nhận wages?",
            wages_Label: "Wages from MarketBasket",
            childSupport_Question: "Chi phí trợ cấp nuôi con cho là bao nhiêu",
            selectHouseHold_Question:
              "Vui lòng chọn tên của thành viên trong hộ gia đình, ai đang trả chi phí này?",
            childSupport_AddHouseHold:
              "+ Bổ sung khoản hỗ trợ nuôi con khác của các thành viên trong gia đình",
            childSupportExpenses_Question:
              "Có thay đổi nào về thông tin chi phí hỗ trợ nuôi con của bạn không? (Bắt buộc)",
            doneButtonlabel: "Hoàn tất",
            deleteButtonlabel: "Xóa",
            titlePer: "mỗi",
            editButtonLabel: "Chỉnh sửa",
            childSupportSideBar: "Chi phí trợ cấp nuôi con",
            childSupportSummaryHeader: "Tóm tắt chi phí hỗ trợ nuôi con",
            otherInfoSideBar: "Thông tin khác",
            otherInfo_Question:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",

            incomeTitle: "Tóm tắt thu nhập đã kiếm được",
            childSupportPerLabel: "mỗi",
            earnedIncomeMenuTitle: "Thu nhập đã thu",
            earnedIncomeTitle: "Tóm tắt thu nhập đã kiếm được",
            unEarnedIncomeMenuTitle: "Thu nhập chưa thu",
            unEarnedIncomeTitle: "Tóm tắt thu nhập chưa kiếm được",
            incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
            aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
            aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không?",
            incomeHelp:
              "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
            clearOption: "Không có ai",
            combineIncomeoptions: [
              "Lương",
              "Tự làm chủ",
              "Nghiên cứu việc làm",
              "Thu nhập cho thuê",
              "SSI (Thu Nhập An Sinh Bổ Sung)",
              "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
              "Thất nghiệp",
              "Trợ cấp nuôi con",
              "Lương hưu",
              "Trợ cấp cho cựu chiến binh",
              "Bồi thường lao động",
              "Khác",
            ],
            wageIncomeBeforeLabel: "Cái gì là ",
            wageIncomeTriggerLabel: "Tổng thu nhập",
            wagesTargetValue: "Lương",

            selfemeploymentTargetValue: "Tự làm chủ",
            workStudyTargetValue: "Nghiên cứu việc làm",
            SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
            rsdiTargetValue:
              "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
            unEmploymentTargetValue: "Thất nghiệp",
            childSuppTargetValue: "Trợ cấp nuôi con",
            childSupportLabel: "hỗ trợ nuôi con",
            pensionTargetValue: "Lương hưu",
            veteranBenefitsTargetValue: "Trợ cấp cho cựu chiến binh",
            rentalIncomeTargetValue: "Thu nhập cho thuê",
            workerCompTargetValue: "Bồi thường lao động",
            otherTargetValue: "Khác",
            noneTargetValue: "Không có",
            mortageInlineHelpText:
              "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
            grossIncomeHelp:
              "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
            unitsPerLabel: " ",
            workerCompensationLabel: "+ thêm bồi thường của công nhân khác",
            unitsOptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            labelOtherwage: "+ Bổ sung mức lương khác",
            adsSelfEmployee: "+ Bổ sung việc tự khác",
            condWorkStudy: "Nghiên cứu việc làm",
            workStudyLabel: "+ Thêm nghiên cứu việc khác",
            anotherSSILabel: "+ Thêm SSI khác",
            anotherLabel: "+ Bổ sung khác",
            addRDILabel: "+ Bổ sung RSDI khác",
            unEmpLabel: "+ Bổ sung Thất nghiệp",
            childSuppLabel: "Trợ cấp nuôi con",
            anotherChildsupport: "+ Bổ sung khoản Hỗ trợ Nuôi con khác",
            pensionLabel: "Lương hưu",
            anotherPensionLabel: "+ Thêm tiền trợ cấp khác",
            veteranBenefit: "Trợ cấp cho cựu chiến binh",
            addAnotherVBenefitLabel: "+ Bổ sung lợi ích của cựu chiến binh",
            rentIncomeLabel: "Thu nhập cho thuê",
            repeatQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
            repeatincomeQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
            unitsRentalOptions: ["Tháng", "Tuần"],
            questionButtonLabel: "+ Bổ sung Khác",
            incomeSummaryTitle: "Tóm tắt Thu nhập",
            incomeSummaryHeader: "Tóm tắt Thu nhập",
            expenseTitle: "Các chi phí",
            almostLabel: "Gần xong rồi!",
            houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
            expenseHeader:
              "Hãy nói cho chúng tôi biết về chi phí nhà ở của quý vị",
            expenseSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
              "Điện cho máy điều hòa không khí",
              "Một khoản phí để sử dụng điều hòa không khí",
              "Điện và / hoặc khí đốt",
              "Dịch vụ điện thoại hoặc điện thoại di động",
            ],
            utilitiesClearOption: "Không có",
            houseQLabel: "Loại chi phí nhà ở mà hộ gia đình có?",
            houseQOptions: [
              "Thuê",
              "Thế chấp",
              "Thuế bất động sản",
              "Bảo hiểm nhà ở",
              "Chi phí công quản",
            ],
            houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
            rentTargetValue: "Thuê",
            mortageTargetValue: "Thế chấp",
            propertyTaxTargetValue: "Thuế bất động sản",
            homeInsurTargetValue: "Bảo hiểm nhà ở",
            condoFeeTargetValue: "Chi phí công quản",
            unitBetween: "theo",
            childSupportPayLabel: "trả tiền",
            houseUnitOptions: ["Tuần", "Tháng", "Năm"],
            mortageQLabel:
              "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
            mortageunitBetween: " ",
            mortageQoptions: ["Tuần", "Tháng", "Năm"],
            mortageProptaxLabel:
              "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Tuần", "Tháng", "Năm"],
            insuranceCostQLabel:
              "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Tuần", "Tháng", "Năm"],
            insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
            condofeeQLabel:
              "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
            condofeepropBetween: "theo",
            condofeeCostoptions: ["Tuần", "Tháng", "Năm"],
            condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
            propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
            propTaxBetween: "theo",
            propTaxOptions: ["Tuần", "Tháng", "Năm"],
            propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
            homeInsuranceQLabel:
              "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
            homeInsuranceBetween: "theo",
            homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
            homeInsuranceClearOption:
              "Không, tôi không cần trả tiền cho việc này.",
            condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Tuần", "Tháng", "Năm"],
            condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

            //new labels:
            anyUtilQcost:
              "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
            chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
            houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
            rentErrMsg: "Hãy nhập tiền thuê",
            anotherRentLabel: "+ Thêm tiền thuê khác",
            mortageErrLabel: "Hãy nhập khoản thế chấp",
            propTaxLabel: "Vui lòng nhập thuế bất động sản",
            propTaxButtonLabel: "+ Thêm tiền thuê khác",
            homeInsurbutton: "+ Bổ sung bảo hiểm khác",
            homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
            condoButtonLabel: "+ Bổ sung khoản phí chung",
            condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
            addMortageButtonLabel: "+ Thêm thế chấp khác",
            childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
            childCostHeader:
              "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
            childCostSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            careGiveObjbefore:
              "Có ai trong hộ gia đình có các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc không? Chi phí này bao gồm các chi phí ",
            careGiveObjtrigger: "chuyên chở",
            careGiveObjAfter:
              "(những) người phụ thuộc đến và/về từ nhà cung cấp dịch vụ chăm sóc.",
            careGiveHelp:
              "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
            questionClearOption: "Không có ai",
            kidsandadultSubheader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            costBetween: " ",
            weekMonthOptions: ["Tuần", "Tháng"],
            costOptions: ["Tuần", "Tháng", "Năm"],
            yesnoOptions: ["Có", "Không"],
            targetValueYes: "Có",
            targetValueNo: "Không",
            noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
            addressProvider: "Địa chỉ của nhà cung cấp là gì?",
            freqTripoptions: ["Tuần", "Tháng", "Năm"],
            unitMonthTargetValue: { unit: "Tháng" },
            childSupTitle: "Chi phí Trợ cấp nuôi Con",
            supportCostHeader: "Chi phí Trợ cấp nuôi Con",
            childSupportQlegalbefore:
              "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
            childSupportQlegaltrigger: "trợ cấp nuôi con",
            childSupportQlegalAfter:
              " cho một đứa trẻ không sống chung với quý vị?",
            childSupportHelp:
              "Tiền trợ cấp nuôi con có thể được tính là một khoản chi phí khi được yêu cầu về mặt pháp lý và các khoản thanh toán đang được thực hiện, bao gồm các khoản thanh toán sau.",
            medicalTitle: "Chi phí Y tế",
            medicalInlineSeethisFull: "Xem danh sách đầy đủ các",
            medicalInlineEligibleMedical: " chi phí y tế đủ điều kiện.",
            medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
            medicalSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            medicalExpBefore:
              "Có ai được liệt kê dưới đây chi trả tiền bảo hiểm y tế hay các chi phí y tế khác bao gồmtoa thuốc,",
            medicalExpTrigger: " chuyên chở",
            medicalExpAfter:
              ", thuốc không cần toa, chăm sóc răng hoặc mắt, tã người lớn, v.v.?",
            medicalExphelp:
              "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
            medicalInhelp: "Xem danh sách đầy đủ các",
            medicalInhelpEligible: " chi phí y tế hợp lệ",
            medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
            zipErrmsg: "Please enter a valid Zipcode.",
            signSubmitLabel: "Ký và gửi",
            signSubmitHeader: "Ký và gửi",
            signSubmitSub:
              "Quý vị đã làm xong mẫu đơn! Bây giờ đến những việc dễ dàng.",
            subSectionLabel: "Đã gửi",
            submitLabelFinal: "Báo cáo giữa kỳ của quý vị đã được gửi!",
            tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
            moneyYougettitle:
              "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
          };
        case HI:
          return {
            emptyLabel: "'s ",
            shelterExpensesLabel: "Depans pou lojman",
            amountLabel: "Montan",
            wages_AmountLabel: "Ki salè brit John Doe's (8.Aug.1967) ?",
            wages_EmployerLabel:
              "Kilès ki se patwon John Doe (8.Aug.1967) pou salè sa yo?",
            wages_UnitLabel: "Ki frekans salè John Doe's (8.Aug.1967) ?",
            wages_Label: "Salè ",
            selfGross_Label: "Travay endepandan ",
            workStudy_Label: "Travay Etidyan ",
            rental_Label: "Revni Lwaye ",
            childSupportSideBar: "Depans pou Sipò Timoun",
            childSupportSummaryHeader: "Rezime depans pou sipò timoun",
            otherInfoSideBar: "Lòt enfòmasyon",
            childSupport_Question: "Ki depans sipò timoun ",
            selectHouseHold_Question:
              "Please select the name of the household member, who is paying this cost?",
            childSupport_AddHouseHold:
              "+ Ajoute yon lòt manm nan kay la sipò timoun",
            childSupportExpenses_Question:
              "Èske gen nenpòt chanjman nan enfòmasyon w lan sou depans pou sipò timoun? (Obligatwa) ",
            doneButtonlabel: "Fini",
            deleteButtonlabel: "Retire",
            titlePer: "Pa",
            editButtonLabel: "Edite",
            childSupportPayLabel: "pays",
            childSupportPerLabel: "Pa",
            childSupportLabel: "nan sipò timoun",
            otherInfo_Question:
              "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",

            incomeTitle: "Rezime revni ou fè",
            earnedIncomeMenuTitle: "Revni touche",
            earnedIncomeTitle: "Rezime revni ou fè",
            unEarnedIncomeMenuTitle: "Revni pa touche",
            unEarnedIncomeTitle: "Rezime revni ki pa fèt nan travay",
            incomeHeader: "Konsènan revni fwaye w la",
            aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
            aboutIncomeTrigger: "revni oswa benefis",
            incomeHelp:
              "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
            clearOption: "Pesonn",
            combineIncomeoptions: [
              "Salè",
              "Travay Endepandan",
              "Travay Etidyan",
              "Revni Lwaye",
              "SSI (Revni Sekirite Siplemantè)",
              "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
              "Chomaj",
              "Sipò pou Timoun",
              "Pansyon",
              "Benefis pou Veteran",
              "Worker's Compensation",
              "Lòt",
            ],
            wageIncomeBeforeLabel: "Ki sa ki ",
            wageIncomeTriggerLabel: "revni brit",
            wagesTargetValue: "Salè",
            selfemeploymentTargetValue: "Travay Endepandan",
            workStudyTargetValue: "Travay Etidyan",
            rsdiTargetValue: "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
            SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
            unEmploymentTargetValue: "Chomaj",
            childSuppTargetValue: "Sipò pou timoun",
            pensionTargetValue: "Pansyon",
            veteranBenefitsTargetValue: "Benefis pou Veteran",
            rentalIncomeTargetValue: "Revni Lwaye",
            workerCompTargetValue: "Konpansasyon Travayè",
            otherTargetValue: "Lòt",
            noneTargetValue: "Okenn",

            mortageInlineHelpText:
              "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
            grossIncomeHelp:
              "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anyèl",
              "Bimansyèl (chak de mwa)",
              "Biebdomadè (chak de semèn)",
              "Mansyèl",
              "Chak trimès",
              "Semianyèl (de fwa pa ane)",
              "Semimansyèl (de fwa pa mwa)",
              "Chak semèn",
            ],
            labelOtherwage: "+ Ajoute yon lòt salè",
            adsSelfEmployee: "+ Ajoute yon lòt travay endepandan",
            condWorkStudy: "Travay Etidyan",
            workStudyLabel: "+ Ajoute yon lòt travay etidyan",
            anotherSSILabel: "+ Ajoute yon lòt SSI",
            anotherLabel: "+ Ajoute yon lòt",
            addRDILabel: "+ Ajoute yon lòt RSDI",
            unEmpLabel: "+ Ajoute yon lòt Chomaj ",
            childSuppLabel: "Sipò Timoun",
            anotherChildsupport: "+ Ajoute yon lòt Sipò Timoun",
            pensionLabel: "Pansyon",
            anotherPensionLabel: "+ Ajoute yon lòt Pansyon",
            workerCompensationLabel: "+ Ajoute yon lòt Konpansasyon Travayè",
            veteranBenefit: "Benefis pou Veteran",
            addAnotherVBenefitLabel: "+ Ajoute yon lòt benefis pou veteran",
            rentIncomeLabel: "Revni Lwaye",
            repeatQuestionLabel: "+ Ajoute yon lòt revni lwaye",
            repeatincomeQuestionLabel: "+ Ajoute èdtan pou yon lòt lwaye",
            unitsRentalOptions: ["Mwa", "Semèn"],
            questionButtonLabel: "+ Ajoute yon lòt Lòt",
            incomeSummaryTitle: "Rezime revni",
            incomeSummaryHeader: "Rezime revni",
            expenseTitle: "Depans",
            almostLabel: "Prèske fini!",
            houseUtlilityLabel: "Depans pou lojman ak sèvis piblik",
            expenseHeader: "Pale nou konsènan depans ou yo pou lojman",
            expenseSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            houseHoldQLabel:
              "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
            utilityQOptions: [
              "Chofaj (luil, gaz, elektrisite oswa pwopàn, elatriye)",
              "Elektrisite pou yon klimatizè",
              "Yon frè pou itilize yon klimatizè",
              "Elektrisite ak/yon gaz",
              "Telefòn oswa sèvis telefòn selilè",
            ],
            utilitiesClearOption: "Okenn",
            houseQLabel: "Ki tip depans pou lojman fwaye w la genyen?",
            houseQOptions: [
              "Lwaye",
              "Prè ipotèk",
              "Taks sou pwopriyete",
              "Asirans Kay",
              "Frè Kondo",
            ],
            houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
            rentTargetValue: "Lwaye",
            mortageTargetValue: "Prè Ipotèk",
            propertyTaxTargetValue: "Taks sou Pwopriyete",
            homeInsurTargetValue: "Asirans Kay",
            condoFeeTargetValue: "Frè Kondo",

            unitBetween: "pa",
            houseUnitOptions: ["Semèn", "Mwa", "Ane"],
            mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semèn", "Mwa", "Ane"],
            mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semèn", "Mwa", "Ane"],
            insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semèn", "Mwa", "Ane"],
            insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
            condofeepropBetween: "pa",
            condofeeCostoptions: ["Semèn", "Mwa", "Ane"],
            condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
            propTaxBetween: "pa",
            propTaxOptions: ["Semèn", "Mwa", "Ane"],
            propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
            homeInsuranceBetween: "pa",
            homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
            homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semèn", "Mwa", "Ane"],
            condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

            //new labels:
            anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
            chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
            houseCostErrmsg: "Tanpri chwazi depans pou lojman",
            rentErrMsg: "Tanpri antre lwaye",
            anotherRentLabel: "+ Ajoute yon lòt lwaye",
            mortageErrLabel: "Tanpri antre prè ipotèk",
            propTaxLabel: "Tanpri antre taks sou pwopriyete",
            propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
            homeInsurbutton: "+ Ajoute yon lòt asirans kay",
            homeInsurErrmsg: "Tanpri antre asirans kay",
            condoButtonLabel: "+ Ajoute yon lòt frè kondo",
            condoFeeErrmsg: "Tanpri antre frè kondo",
            addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
            childTitle: "Depans pou Swen Timoun oswa Adilt",
            childCostHeader:
              "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
            childCostSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            careGiveObjbefore:
              "Èske gen yon moun nan Kay la ki gen depans pou swen pou timoun oswa adilt? Sa enkli depans pou ",
            careGiveObjtrigger: "transpòte",
            careGiveObjAfter:
              " depandan an(yo) pou ale/soti lakay yon founisè swen.",
            careGiveHelp:
              "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
            questionClearOption: "Pesonn",
            kidsandadultSubheader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            costBetween: " ",
            weekMonthOptions: ["Mwa", "Semèn"],
            costOptions: ["Semèn", "Mwa", "Ane"],
            yesnoOptions: ["Wi", "Non"],
            targetValueYes: "Wi",
            targetValueNo: "Non",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "Ki adrès founisè a?",
            freqTripoptions: ["Semèn", "Mwa", "Ane"],
            unitMonthTargetValue: { unit: "Mwa" },

            childSupTitle: "Depans pou Sipò Timoun",
            supportCostHeader: "Depans pou Sipò Timoun",
            childSupportQlegalbefore:
              "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
            childSupportQlegaltrigger: "sipò timoun",
            childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
            childSupportHelp:
              "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
            medicalTitle: "Depans medikal",
            medicalInlineSeethisFull: "Gade lis konplè sa a",
            medicalInlineEligibleMedical: " depans medikal ki kalifye",
            medicalHeader: "Pale nou konsènan depans medikal ou yo",
            medicalSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            medicalExpBefore:
              "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
            medicalExpTrigger: " transpò",
            medicalExpAfter:
              ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
            medicalExphelp:
              "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
            medicalInhelp: "Gade lis konplè sa a",
            medicalInhelpEligible: " depans medikal ki kalifye",
            medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
            zipErrmsg: "Tanpri antre yon kòd postal ki valid",
            signSubmitLabel: "Siyen epi soumèt",
            signSubmitHeader: "Siyen epi soumèt",
            signSubmitSub:
              "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
            subSectionLabel: "Soumèt",
            submitLabelFinal: "Rapò Pwovizwa w la te soumèt!",
            tellusAbout: "Pale nou konsènan depans pou sipò timoun",
            moneyYougettitle:
              "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
          };
        default:
          return;
      }
    }

    case languageConstants.earnedIncomeDynamic: {
      switch (language) {
        case EN:
          return {
            heading: name + "'s Gross Income",
            unearnedHeading: name + "'s gross earned income",
            mainQuestion:
              "What type(s) of income and benefits does " + name + " have?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " job income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s job income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these job income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s job income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " self-employment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s self-employment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these self-employment?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s self-employment?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " room and board income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s room and board income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " +
                  name +
                  "'s employer for these room and board income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s room and board income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " work study?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s work study?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these work study?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s work study?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " wages?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s wages?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these wages?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s wages?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + "TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Income from Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
            ],
          };
        case ES:
          return {
            heading: "Ingresos brutos del trabajo des " + name,
            unearnedHeading: "Ingresos brutos del trabajo des " + name,
            mainQuestion:
              "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de" + name + " para este Salarios?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Trabajo independiente?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + "de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Trabajo estudi?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
            ],
          };
        case PT:
          return {
            heading: "Rendimento bruto de " + name,
            mainQuestion:
              "Que tipo(s) de rendimentos e benefícios " + name + " tem?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto dos salários de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o",
                  [Props.LABEL_OBJ_TRIGGER]: " salário bruto",
                  [Props.LABEL_OBJ_AFTER]: " de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " + name + "para estes Salário?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " dos salários de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto de Trabalho autônomo de" + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]:
                    "de Trabalho autônomo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " +
                  name +
                  " para estes Trabalho autônomo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Trabalho autônomo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto de Estudo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]: "de " + name + " Estudo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " + name + " para estes Estudo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Estudo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
            ],
          };
        case ZH:
          return {
            heading: name + "的劳动所得收入总额",
            unearnedHeading: name + "的劳动所得收入总额",
            mainQuestion: name + " 有哪些类型的收入和福利？",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 工资?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工资的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的工资雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工资的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 个体经营?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的个体经营的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的个体经营雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的个体经营的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 工作研究?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工作研究的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的工作研究雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工作研究的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
            ],
          };
        case VI:
          return {
            heading: name + " Tổng thu nhập đã thu của",
            unearnedHeading: "Tổng thu nhập đã thu của " + name,
            mainQuestion:
              "(Những) loại thu nhập và lợi ích mà " + name + " có?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " Lương?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]: "của lương của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel: "Ai là người chủ của " + name + " cho là gì?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]: "của lương của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " Tự làm chủ?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Tự làm chủ của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel: "Ai là người chủ của " + name + " cho là gì?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Tự làm chủ của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền " + name + " Nghiên cứu việc làm?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Nghiên cứu việc làm của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel:
                  "Ai là người chủ của " + name + " cho Nghiên cứu việc làm?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Nghiên cứu việc làm của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
            ],
          };
        case HI:
          return {
            heading: name + " Revni Brit",
            unearnedHeading: name + " Revni Brit",
            mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel: "Kilès ki se patwon " + name + "pou salè sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel:
                  "Kilès ki se anplwayè " +
                  name +
                  " pou travay endepandan sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel:
                  "Kilès ki se anplwayè " + name + " pou travay etidyan sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
            ],
          };
        default:
          return;
      }
    }

    case languageConstants.unearnedIncomeDynamic: {
      switch (language) {
        case EN:
          return {
            heading: name + "'s Gross Unearned Income",
            unearnedHeading: name + "'s Gross Unearned Income",
            mainQuestion:
              "What type(s) of income and benefits does " + name + " have?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + "TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Income from Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
            ],
          };
        case ES:
          return {
            heading:
              "Ingresos brutos no provenientes del trabajo de " + name + ".",
            unearnedHeading:
              "Ingresos brutos no provenientes del trabajo de " + name + ".",
            mainQuestion:
              "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Manutención?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Manutención?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Manutención?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Ingreso por alquiler?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
            ],
          };
        case PT:
          return {
            heading:
              "Rendimento bruto não proveniente de trabalho de " + name + ".",
            unearnedHeading: "Rendimento bruto de " + name + ".",
            mainQuestion:
              "Que tipo(s) de rendimentos e benefícios " + name + " tem?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de SSI (Renda de segurança suplementar)?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]:
                    "de SSI (Renda de segurança suplementar) de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do SSI (Renda de segurança suplementar) de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de RSDI (Seguro de aposentadoria, sobreviventes e deficiências)?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de RSDI (Seguro de aposentadoria, sobreviventes e deficiências) de " +
                    name +
                    "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do RSDI (Seguro de aposentadoria, sobreviventes e deficiências) de " +
                    name +
                    "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Desemprego?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Desemprego de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Desemprego de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Suporte infantil?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Suporte infantil de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Suporte infantil de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Pensão?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Pensão de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Pensão de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de Benefício de veterano?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Benefício de veterano de" + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Benefício de veterano de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de Compensação do trabalhador?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Compensação do trabalhador de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Compensação do trabalhador de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Renda de aluguel?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Renda de aluguel de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                employerLabel:
                  "Quem é o empregador de " +
                  name +
                  " para estes Renda de aluguel?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Renda de aluguel de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " Outros?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Outros de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Outros de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
            ],
          };
        case ZH:
          return {
            heading: name + "的非劳动所得总收入",
            unearnedHeading: name + "的非劳动所得总收入",
            mainQuestion: name + " 有哪些类型的收入和福利？",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " SSI（补充保障收入）?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的SSI（补充保障收入）的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的SSI（补充保障收入）的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " +
                    name +
                    " RSDI（退休，幸存者和残疾保险）?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]:
                    name + "的RSDI（退休，幸存者和残疾保险）的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]:
                    name + "的RSDI（退休，幸存者和残疾保险）的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 失业?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的失业的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的失业的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 子女抚养费?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的子女抚养费的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的子女抚养费的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 养老金?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的养老金的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的养老金的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 退伍军人补贴?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的退伍军人补贴的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的退伍军人补贴的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 工伤赔偿其?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工伤赔偿其的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工伤赔偿其的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 贴租金收?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的贴租金收的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的贴租金收的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
                employerLabel: name + "的贴租金收雇主是谁？",
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 其他?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的其他的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的其他的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
            ],
          };
        case VI:
          return {
            heading: "Tổng thu nhập chưa thu của " + name + ".",
            unearnedHeading: "Tổng thu nhập đã thu của " + name + ".",
            mainQuestion:
              "(Những) loại thu nhập và lợi ích mà " + name + " có?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " SSI (Thu Nhập An Sinh Bổ Sung)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của SSI (Thu Nhập An Sinh Bổ Sung) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của SSI (Thu Nhập An Sinh Bổ Sung) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " +
                    name +
                    " RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Thất nghiệp?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thất nghiệp của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thất nghiệp của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Trợ cấp nuôi con?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp nuôi con của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp nuôi con của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Lương hưu?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Lương hưu của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Lương hưu của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Trợ cấp cho cựu chiến binh?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp cho cựu chiến binh của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp cho cựu chiến binh của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Bồi thường lao động?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Bồi thường lao động của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Bồi thường lao động của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Thu nhập cho thuê?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thu nhập cho thuê của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thu nhập cho thuê của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
                employerLabel:
                  "Ai là chủ nhân của " + name + " cho này Thu nhập cho thuê?",
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Những chi phí khác?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Những chi phí khác của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Những chi phí khác của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
            ],
          };
        case HI:
          return {
            heading: name + "revni brit ou pa fè nan travay",
            unearnedHeading: name + "revni brit ou pa fè nan travay",
            mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " pansyon?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + "'s pansyon?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + "'s pansyon?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these rental income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
            ],
          };
        default:
      }
    }

    case languageConstants.childSupport: {
      switch (language) {
        case EN:
          return {
            childSupportSideBar: "Child support expenses",
            childSupportSummaryHeader: "Child Support Expenses Summary",
            perLabel: "per",
            freqTripoptions: ["Week", "Month", "Year"],
            unitsOptions: ["Annual",
              "Bimonthly(every two months)",
              "Biweekly(every two weeks)",
              "Monthly",
              "Quarterly",
              "Semi-annual(Twice a year)",
              "Semi-monthly(Twice a month)",
              "Weekly"],
          };
        case ES:
          return {
            childSupportSideBar: "Gastos de manutención infantil",
            childSupportSummaryHeader: "Resumen de gastos de manutención infantil",
            perLabel: "por",
            freqTripoptions: ["Semana", "Mes", "Año"],
            unitsOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
          };

        default:
          return {
            childSupportSideBar: "Child support expenses",
            childSupportSummaryHeader: "Child Support Expenses Summary",
            perLabel: "per",
            freqTripoptions: ["Week", "Month", "Year"],
          };
      }
    }

    case languageConstants.childSupportDynamic: {
      switch (language) {
        case EN:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion: "What is the child support expense for " + name + "?",
          };
        case ES:
          return {
            heading: "Resumen de gastos de manutención infantil",
            mainQuestion: "¿Para qué es el gasto de manutención infantil " + name + "?",
          };
        case PT:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion: "What is the child support expense for " + name + "?",
          };
        case ZH:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion: "What is the child support expense for " + name + "?",
          };
        case VI:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion: "What is the child support expense for " + name + "?",
          };
        case HI:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion: "What is the child support expense for " + name + "?",
          };
        default:
          return;
      }
    }

    // Need translation
    case languageConstants.shelterAndutility: {
      switch (language) {
        case EN:
          return {
            shelterAndUtilityMenuTitle: "Shelter & Utility",
            shelterAndUtilityHeading: "Shelter & Utility Expenses Summary",
            shelterAndUtilitySideMenuTitle: "Shelter & Utility Expenses",
            rentTargetValue: "RENT",
            mortgageTargetValue: "MTGPRN",
            propInsTargetValue: "PRPINS",
            propTaxTargetValue: "PRPTAX",
            secondMortgageTargetValue: "SECMTG",
            homelessExpTargetValue: "HOMEXP",
            homeEquityTargetValue: "HOMEEQ",
            condoTargetValue: "CONFEE",
            houseOwnerTargetValue: "HOASFE",
            otherTargetValue: "OTHER",
            rentQuestion: "How much is your household's rent?",
            mortageQuestion: "How much is your household's mortage?",
            propInsQuestion: "How much is your household's property insurance?",
            propTaxQuestion: "How much is your household's property taxes?",
            secMortgageQuestion: "How much is your household's second mortage?",
            homelessQuestion: "How much is your household's homeless expenses?",
            equityLoanQuestion:
              "How much is your household's home equity loan?",
            condoQuestion: "How much is your household's condo fee?",
            houseAssQuestion:
              "How much is your household's house owner association fees?",
            otherQuestion: "How much is your household's other?",
            rentErrMsg: "Please enter rent",
            mortageErrMsg: "Please enter mortage",
            propInsErrMsg: "Please enter property insurance",
            propTaxErrMsg: "Please enter property taxes",
            secondMortgageErrMsg: "Please enter second mortage",
            homelessExpErrMsg: "Please enter homeless expenses",
            homeEquityErrMsg: "Please enter home equity loan",
            condoErrMsg: "Please enter condo fee",
            houseOwnerErrMsg: "Please enter house owner association fees",
            otherErrMsg: "Please enter other amount",
            perLabel: "Per",
            anotherRentLabel: "+ Add another rent",
            anothermortgageLabel: "+ Add another mortage",
            anotherpropInsLabel: "+ Add another property insurance",
            anotherpropTaxLabel: "+ Add another property taxes",
            anothersecondMortgageLabel: "+ Add another second mortage",
            anotherhomelessExpLabel: "+ Add another homeless expenses",
            anotherhomeEquityLabel: "+ Add another home equity loan",
            anothercondoLabel: "+ Add another condo fee",
            anotherhouseOwnerLabel:
              "+ Add another house owner association fees",
            anotherOtherLabel: "+ Add another other amount",
            houseUnitOptions: ["Week", "Month", "Year"],
            unitsOptions: ["Annual",
              "Bimonthly(every two months)",
              "Biweekly(every two weeks)",
              "Monthly",
              "Quarterly",
              "Semi-annual(Twice a year)",
              "Semi-monthly(Twice a month)",
              "Weekly"],
            rent: "Rent",
            mortgage: "Mortgage",
            propertyTaxes: "Property Taxes",
            homeInsurance: "Home Insurance",
            condoFee: "Condo Fee",
            propertyInsurance: "Property Insurance",
            secondMortage: "Second Mortgage",
            homelessExpenses: "Homeless Expenses",
            homeEquityLoan: "Home Equity Loan",
            homeOwnerAssociationFees: "Homeowner Association Fees",
            other: "Other",
            week: "Weekly",
            quarter: "Quarter",
            month: "Monthly",
            year: "Yearly",
            weekly: "Week",
            monthly: "Month",
            yearly: "Yearly",
            semanl: "Semi-yearly",
            biweek: "Biweekly",
            onetime: "One-time only",
            bimonthly: "Bimonthly",
            semimonthly: "Semimonthly",
            interimShelterQlabel:
              "What type(s) of shelter expenses your household have?",
            interimUtilityQlabel:
              "What type(s) of utility expenses your household have?",
            houseCostErrmsg: "Please choose housing costs",
            houseQOptions: [
              "Rent",
              "Mortgage",
              "Property Taxes",
              "Home Insurance",
              "Condo Fee",
              "Property Insurance",
              "Second Mortgage",
              "Homeless Expenses",
              "Home Equity Loan",
              "Homeowner Association Fees",
              "Other",
            ],
            //utility
            utilityQOptions: [
              "Heat (oil, gas, electricity or propane, etc.)",
              "Electricity for an air conditioner",
              "A fee to use an air conditioner",
              "Electricity and/or gas",
              "Phone or cell phone service",
              "Water and Sewage",
              "Garbage",
              "Energy Assistance (LIHEAP)",
              "I do not pay for any utility expenses"
            ],
            heatTargetValue: "payHeat",
            eletricityTargetValue: "payAC",
            elgasTargetValue: "elgas",
            phoneTargetValue: "hasPhone",
            waterTargetValue: "water",
            garbageTargetValue: "garbage",
            energyTargetValue: "liheap",
            heading: "Shelter & Utility",
            heatOil: "Heat (oil, gas, electricity or propane, etc.)",
            electricityairConditioner: "Electricity for an air conditioner",
            feeairConditioner: "A fee to use an air conditioner",
            electricityGas: "Electricity and/or gas",
            cellPhoneService: "Phone or cell phone service",
            none: "None",
            waterAndSewage: "Water and Sewage",
            garbage: "Garbage",
            energyAssistance: "Energy Assistance (LIHEAP)",
            idontPayUtility:"I do not pay for any utility expenses",
            idontPayShelter:"I do not pay for any shelter expenses"

          };
        case ES:
          return {
            shelterAndUtilityMenuTitle: "Refugio y utilidad",
            shelterAndUtilityHeading: "Resumen de gastos de alojamiento y servicios públicos",
            shelterAndUtilitySideMenuTitle: "Gastos de alojamiento y servicios públicos",
            rentTargetValue: "RENT",
            mortgageTargetValue: "MTGPRN",
            propInsTargetValue: "PRPINS",
            propTaxTargetValue: "PRPTAX",
            secondMortgageTargetValue: "SECMTG",
            homelessExpTargetValue: "HOMEXP",
            homeEquityTargetValue: "HOMEEQ",
            condoTargetValue: "CONFEE",
            houseOwnerTargetValue: "HOASFE",
            otherTargetValue: "OTRO",
            rentQuestion: "¿A cuánto asciende el alquiler de su hogar?",
            mortageQuestion: "¿A cuánto asciende la hipoteca de su hogar?",
            propInsQuestion: "¿A cuánto asciende el seguro de propiedad de su hogar?",
            propTaxQuestion: "¿A cuánto ascienden los impuestos a la propiedad de su hogar?",
            secMortgageQuestion: "¿A cuánto asciende la segunda hipoteca de su hogar?",
            homelessQuestion: "¿A cuánto ascienden los gastos de personas sin hogar de su hogar?",
            equityLoanQuestion:
              "¿A cuánto asciende el préstamo con garantía hipotecaria de su hogar?",
            condoQuestion: "¿A cuánto asciende la tarifa de condominio de su hogar?",
            houseAssQuestion:
              "¿A cuánto ascienden las cuotas de la asociación de propietarios de su hogar?",
            otherQuestion: "¿Cuánto cuesta el otro de su hogar?",
            rentErrMsg: "Por favor ingrese el alquiler",
            mortageErrMsg: "Por favor ingrese la hipoteca",
            propInsErrMsg: "Por favor ingrese seguro de propiedad",
            propTaxErrMsg: "Por favor ingrese los impuestos a la propiedad",
            secondMortgageErrMsg: "Por favor introduzca segunda hipoteca",
            homelessExpErrMsg: "Por favor ingrese los gastos de personas sin hogar",
            homeEquityErrMsg: "Por favor ingrese préstamo sobre el valor líquido de la vivienda",
            condoErrMsg: "Por favor ingrese la tarifa del condominio",
            houseOwnerErrMsg: "Por favor, introduzca las tarifas de la asociación de propietarios de viviendas.",
            otherErrMsg: "Por favor ingresa otra cantidad",
            perLabel: "Por",
            anotherRentLabel: "+ Añadir otro alquiler",
            anothermortgageLabel: "+ Añadir otra hipoteca",
            anotherpropInsLabel: "+ Agregar otro seguro de propiedad",
            anotherpropTaxLabel: "+ Agregar otros impuestos a la propiedad",
            anothersecondMortgageLabel: "+ Añadir otra segunda hipoteca",
            anotherhomelessExpLabel: "+ Agregue otros gastos para personas sin hogar",
            anotherhomeEquityLabel: "+ Agregar otro préstamo sobre el valor líquido de la vivienda",
            anothercondoLabel: "+ Agregar otra tarifa de condominio",
            anotherhouseOwnerLabel:
              "+ Agregar otras tarifas de asociación de propietarios de casas",
            anotherOtherLabel: "+ Añade otra cantidad",
            houseUnitOptions: ["Semana", "Mes", "Año"],
            unitsOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            rent: "Alquilar",
            mortgage: "Hipoteca",
            propertyTaxes: "Impuestos de propiedad",
            homeInsurance: "Seguro de hogar",
            condoFee: "Tarifa de condominio",
            propertyInsurance: "Seguro de propiedad",
            secondMortage: "Segunda hipoteca",
            homelessExpenses: "Gastos para personas sin hogar",
            homeEquityLoan: "Préstamo con garantía hipotecaria",
            homeOwnerAssociationFees: "Tarifas de la asociación de propietarios",
            other: "Otro",
            week: "Semanalmente",
            quarter: "Cuarto",
            none: "Ninguno",
            month: "Mensual",
            year: "Anual",
            weekly: "Semana",
            monthly: "Mes",
            yearly: "Anual",
            semanl: "Semestral",
            biweek: "Quincenal",
            onetime: "Solo una vez",
            bimonthly: "Bimensual",
            semimonthly: "Semi mensual",
            interimShelterQlabel:
              "¿Qué tipo(s) de gastos de alojamiento tiene su hogar?",
            interimUtilityQlabel:
              "¿Qué tipo(s) de gastos de servicios públicos tiene su hogar?",
            houseCostErrmsg: "Por favor elija costos de alojamiento",
            houseQOptions: [
              "Alquilar",
              "Hipoteca",
              "Impuestos de propiedad",
              "Seguro de hogar",
              "Tarifa de condominio",
              "Seguro de propiedad",
              "Segunda hipoteca",
              "Gastos para personas sin hogar",
              "Préstamo con garantía hipotecaria",
              "Tarifas de la asociación de propietarios",
              "Otro",
            ],
            //utility
            utilityQOptions: [
              "Calor (petróleo, gas, electricidad o propano, etc.)",
              "Electricidad para un aire acondicionado.",
              "Una tarifa por usar un aire acondicionado.",
              "Electricidad y/o gas",
              "Servicio de telefonía o celular",
              "Agua y Alcantarillado",
              "Basura",
              "Asistencia Energética (LIHEAP)",
            ],
            heatTargetValue: "payHeat",
            eletricityTargetValue: "payAC",
            elgasTargetValue: "elgas",
            phoneTargetValue: "hasPhone",
            waterTargetValue: "water",
            garbageTargetValue: "garbage",
            energyTargetValue: "liheap",
            heading: "Refugio y utilidad",
            heatOil: "Calor (petróleo, gas, electricidad o propano, etc.)",
            electricityairConditioner: "Electricidad para un aire acondicionado.",
            feeairConditioner: "Una tarifa por usar un aire acondicionado.",
            electricityGas: "Electricidad y/o gas",
            cellPhoneService: "Servicio de telefonía o celular",
            none: "None",
            waterAndSewage: "Agua y Alcantarillado",
            garbage: "Basura",
            energyAssistance: "Asistencia Energética (LIHEAP)",
            idontPayUtility:"No pago ningún gasto de servicios públicos",
            idontPayShelter:"No pago ningún gasto de alojamiento"
          };
      }
    }

    case languageConstants.medicalExpenses: {
      switch (language) {
        case EN:
          return {
            medicalTitle: "Medical Expenses",
            medicalHeader: "Medical Expenses Summary",
            medicalExpenseType:
              "What type of medical expenses do you have? (Select all that apply)",
            doctorOrDentistOptions: "Doctor or Dentist",
            doctorOrDentist:
              "What is the amount namePlaceholder spends on Doctor or Dentist?",
            hospitalOrNursingHomeOptions: "Hospital or Nursing Home",
            hospitalOrNursingHome:
              "What is the amount namePlaceholder spends on Hospital or Nursing Home?",
            prescriptionDrugsOptions: "Prescription Drugs ",
            prescriptionDrugs:
              "What is the amount namePlaceholder spends on Prescription Drugs?",
            overTheCounterMedicineOptions: "Over the Counter Medicine",
            overTheCounterMedicine:
              "What is the amount namePlaceholder spends on Over the Counter Medicine?",
            healthInsurancePremiumsOptions: "Health Insurance Premiums",
            healthInsurancePremiums:
              "What is the amount namePlaceholder spends on Health Insurance Premiums?",
            medicalSuppliesOptions: " Medical Supplies",
            medicalSupplies:
              "What is the amount namePlaceholder spends on Medical Supplies?",
            medicaidCostSharingOptions: "Medicaid Cost-Sharing",
            medicaidCostSharing:
              "What is the amount namePlaceholder spends on Medicaid Cost-Sharing?",
            denturesOrHearingAidsOptions: "Dentures or Hearing Aids",
            denturesOrHearingAids:
              "What is the amount namePlaceholder spends on Dentures or Hearing Aids?",
            prescriptionEyeGlasesOptions: "Prescription Eye Glasses",
            prescriptionEyeGlases:
              "What is the amount namePlaceholder spends on Prescription Eye Glasses?",
            transportationOrLoadingForMedicalServicesOptions:
              "Transportation or Loading for Medical Services",
            transportationOrLoadingForMedicalServices:
              "What is the amount namePlaceholder spends on Transportation or Loading for Medical Services?",
            HudHomeHealthAideOptions: "HUD Home Health Aide or Child Care",
            HudHomeHealthAide:
              "What is the amount namePlaceholder spends on HUD Home Health Aide or Child Care?",
            serviceAnimalOptions: "Service Animal",
            serviceAnimal:
              "What is the amount namePlaceholder spends on Service Animal?",
            otherMedicalExpenseOptions: "Other",
            otherMedicalExpense:
              "What is the amount namePlaceholder spends on Other?",
            medicalAppointmentsOrPharmacy:
              "Does namePlaceholder drive to medical appointments or the pharmacy?",
            howManyMedicalAppointmentsOrPharmacy:
              "How many addresses, does namePlaceholder to medical appointments or the pharmacy?",
            tellUsPocketMedicalExpense:
              "Tell us about any out-of-pocket medical expenses.",
            beSureLegally:
              "Be sure to tell your worker if you are legally responsible for any medical expenses for an elderly or disabled person who was a former member of your household.",
            fulllist: "See full of eligible medical expenses.",
            allMedicalExpenseVerified:
              "-All medical expenses have to be verified.",
            verfiedExpenseMay:
              "-Verified expenses may increase the amount of your benefits.",
            acceptableForms:
              "-Your worker will discuss with you acceptable forms of verification.",
            obtainVerificationNeeded:
              " -We will help you obtain verification when needed.",
            unitsOptions: [
              { key: "MONTH", value: "Monthly" },
              { key: "WEEK", value: "Weekly" },
              { key: "ANNUAL", value: "Annual" },
            ],
            amountLabel:
              "How much amount does client spends on Medical Expenses?",
            unitLabel: "What is the frequency for Medical Expenses?",
            questionClearOption: "None",
            optionYesNo: ["Yes", "No"],
            question:
              "Does " +
              name +
              " drive to medical appointments or the pharmacy?",
            typeQuestion:
              "What type of medical expenses are there for " + name + "?",
            totalCostQuestion:
              "What is total cost of " + name + "'s medical expenses?",
            typeOptions: [
              "Medical/Dental Care",
              "Medications",
              "Health Insurance",
              "Other (such as OTCs, medical supplies, etc.)",
            ],
            medicalExpenses_AddHouseHold:
              "Add another household member’s medical expenses",
            mainQuestion:
              "What type of medical expenses do you have? (Select all that apply)",
            heading: "Medical Expenses for " + name,
          };
        case ES:
          return {
            medicalTitle: "Medical Expenses",
            medicalHeader: "Medical Expenses Summary",
            medicalExpenseType:
              "What type of medical expenses do you have? (Select all that apply)",
            doctorOrDentistOptions: "Doctor or Dentist",
            doctorOrDentist:
              "What is the amount namePlaceholder spends on Doctor or Dentist?",
            hospitalOrNursingHomeOptions: "Hospital or Nursing Home",
            hospitalOrNursingHome:
              "What is the amount namePlaceholder spends on Hospital or Nursing Home?",
            prescriptionDrugsOptions: "Prescription Drugs ",
            prescriptionDrugs:
              "What is the amount namePlaceholder spends on Prescription Drugs?",
            overTheCounterMedicineOptions: "Over the Counter Medicine",
            overTheCounterMedicine:
              "What is the amount namePlaceholder spends on Over the Counter Medicine?",
            healthInsurancePremiumsOptions: "Health Insurance Premiums",
            healthInsurancePremiums:
              "What is the amount namePlaceholder spends on Health Insurance Premiums?",
            medicalSuppliesOptions: " Medical Supplies",
            medicalSupplies:
              "What is the amount namePlaceholder spends on Medical Supplies?",
            medicaidCostSharingOptions: "Medicaid Cost-Sharing",
            medicaidCostSharing:
              "What is the amount namePlaceholder spends on Medicaid Cost-Sharing?",
            denturesOrHearingAidsOptions: "Dentures or Hearing Aids",
            denturesOrHearingAids:
              "What is the amount namePlaceholder spends on Dentures or Hearing Aids?",
            prescriptionEyeGlasesOptions: "Prescription Eye Glasses",
            prescriptionEyeGlases:
              "What is the amount namePlaceholder spends on Prescription Eye Glasses?",
            transportationOrLoadingForMedicalServicesOptions:
              "Transportation or Loading for Medical Services",
            transportationOrLoadingForMedicalServices:
              "What is the amount namePlaceholder spends on Transportation or Loading for Medical Services?",
            HudHomeHealthAideOptions: "HUD Home Health Aide or Child Care",
            HudHomeHealthAide:
              "What is the amount namePlaceholder spends on HUD Home Health Aide or Child Care?",
            serviceAnimalOptions: "Service Animal",
            serviceAnimal:
              "What is the amount namePlaceholder spends on Service Animal?",
            otherMedicalExpenseOptions: "Other",
            otherMedicalExpense:
              "What is the amount namePlaceholder spends on Other?",
            medicalAppointmentsOrPharmacy:
              "Does namePlaceholder drive to medical appointments or the pharmacy?",
            howManyMedicalAppointmentsOrPharmacy:
              "How many addresses, does namePlaceholder to medical appointments or the pharmacy?",
            tellUsPocketMedicalExpense:
              "Tell us about any out-of-pocket medical expenses.",
            beSureLegally:
              "Be sure to tell your worker if you are legally responsible for any medical expenses for an elderly or disabled person who was a former member of your household.",
            fulllist: "See full of eligible medical expenses.",
            allMedicalExpenseVerified:
              "-All medical expenses have to be verified.",
            verfiedExpenseMay:
              "-Verified expenses may increase the amount of your benefits.",
            acceptableForms:
              "-Your worker will discuss with you acceptable forms of verification.",
            obtainVerificationNeeded:
              " -We will help you obtain verification when needed.",
            unitsOptions: [
              { key: "MONTH", value: "Monthly" },
              { key: "WEEK", value: "Weekly" },
              { key: "ANNUAL", value: "Annual" },
            ],
            amountLabel:
              "How much amount does client spends on Medical Expenses?",
            unitLabel: "What is the frequency for Medical Expenses?",
            questionClearOption: "None",
            optionYesNo: ["Yes", "No"],
            heading: "Medical costs for " + name,
            question:
              "Does " +
              name +
              " drive to medical appointments or the pharmacy?",
            typeQuestion:
              "What type of medical expenses are there for " + name + "?",
            totalCostQuestion:
              "What is total cost of " + name + "'s medical expenses?",
            typeOptions: [
              "Medical/Dental Care",
              "Medications",
              "Health Insurance",
              "Other (such as OTCs, medical supplies, etc.)",
            ],
            medicalExpenses_AddHouseHold:
              "Add another household member’s medical expenses",
            mainQuestion:
              "What type of medical expenses do you have? (Select all that apply)",
            heading: "Medical Expenses for " + name,
          };
      }
    }
    case languageConstants.dependentCareCostStatic: {
      switch (language) {
        case EN:
          return {
            dependentCareCostTitle: "Child or Adult Dependent Care Expenses",
            dependentCareCostHeader: "Tell Us About Your Dependent Care Expenses",
            dependentCareCostSubHeader:
              "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
            careGiveObjbefore1:
              "Does anyone in the household pay child or adult ",
            careGiveObjtrigger1: "dependent care expenses",
            careGiveObjAfter1: "? ",
            careGiveObjbefore2: "This includes the costs to ",
            careGiveObjtrigger2: "transport",
            careGiveObjAfter2:
              " the dependent(s) to and/or from a care provider.",
            careGiveHelp1:
              "Dependent care costs include any payment you make for someone to watch and/or transport a child or adult who requires care.",
            careGiveHelp2:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            questionClearOption: "None",
            kidsandadultSubheader:
              "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
            costBetween: " ",
            weekMonthOptions: ["Month", "Week"],
            costOptions: ["Week", "Month", "Year"],
            yesnoOptions: ["Yes", "No"],
            targetValueYes: "Yes",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "What is the address of the provider?",
            freqMonthoption: ["Monthly"],
            freqTripoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            unitMonthTargetValue: { unit: "Monthly" },
            tripCountErrMsgLen:
              "Please enter less than/equal to 6 characters for trip count",
            tripCostErrMsgLen:
              "Please enter less than/equal to 6 characters for trip cost",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            citiErrmsg: "Please enter a valid City.",
            addressErrmsg: "Please enter a valid Address.",
          };
        case ES:
          return {
            dependentCareCostTitle: "Gastos de cuidado de dependientes",
            dependentCareCostHeader:
              "Infórmenos sobre los costos del cuidado de dependientes",
            dependentCareCostSubHeader:
              "Por favor, incluya los costos de los que es responsable de pagar, aunque esté atrasado/a o no pueda pagarlos.",
            careGiveObjbefore1:
              "Does anyone in the household has child or adult ",
            careGiveObjtrigger1: "dependent care Expenses",
            careGiveObjAfter1: "? ",
            careGiveObjbefore2: "This includes the costs to ",
            careGiveObjtrigger2: "transport",
            careGiveObjAfter2:
              " the dependent(s) to and/or from a care provider.",
            careGiveHelp1:
              "Dependent care costs include any payment you make for someone to watch and/or transport a child or adult who requires care.",
            careGiveHelp2:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            questionClearOption: "None",
            kidsandadultSubheader:
              "Please include expenses that you are responsible for paying even if you are behind or not able to pay them.",
            costBetween: " ",
            weekMonthOptions: ["Month", "Week"],
            costOptions: ["Week", "Month", "Year"],
            yesnoOptions: ["Yes", "No"],
            targetValueYes: "Yes",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "What is the address of the provider?",
            freqMonthoption: ["Monthly"],
            freqTripoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            unitMonthTargetValue: { unit: "Monthly" },
            tripCountErrMsgLen:
              "Please enter less than/equal to 6 characters for trip count",
            tripCostErrMsgLen:
              "Please enter less than/equal to 6 characters for trip cost",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            citiErrmsg: "Please enter a valid City.",
            addressErrmsg: "Please enter a valid Address.",
          };
      }
    }
  }
}

export function incomeDynamicMultilanguageLabels(name, typeCode, language, code) {
  switch (language) {
    case EN:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross income",
          [Props.LABEL_OBJ_AFTER]: " amount of " + name + "'s " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross amount",
          [Props.LABEL_OBJ_AFTER]: " of " + name + "'s " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
        },
        
        employerLabel: code ===  "SELEMP" ? "What is " + name + "'s business name?"  :"Who is " + name + "'s employer for this " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "frequency",
          [Props.LABEL_OBJ_AFTER]: " of " + name + "'s " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "How often you are paid.",
        },
        amountLabel1:
          "How much amount does " + name + " spends on " + typeCode + "?",
      };
    case ES:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
          [Props.LABEL_OBJ_AFTER]:
            " monto del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
        },
        employerLabel: code ===  "SELEMP" ? "Qué es " + name + " ¿Nombre del Negocio?" :
          "¿Quién es el empleador del " + name + " para este " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
        },
        amountLabel1:
          "How much amount does " + name + " spends on " + typeCode + "?",
      };
    case PT:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
          [Props.LABEL_OBJ_AFTER]:
            " valor bruto do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
          [Props.LABEL_OBJ_TRIGGER]: "valor bruto do ",
          [Props.LABEL_OBJ_AFTER]: typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
        },
        employerLabel: "Quem é o empregador de " + name + " " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "frequência",
          [Props.LABEL_OBJ_AFTER]: " do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
        },
      };
    case ZH:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
          [Props.LABEL_OBJ_TRIGGER]: "收入总额",
          [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "总金额",
          [Props.LABEL_OBJ_AFTER]: " ?",
          [Props.LABEL_OBJ_HELP]:
            "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
        },
        employerLabel: name + "的" + typeCode + "雇主是谁？",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "收入频率",
          [Props.LABEL_OBJ_AFTER]: " 是多少?",
          [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
        },
      };
    case VI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Là gì ",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
          [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
        },
        employerLabel: "Ai là người chủ của " + name + " cho " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
        },
      };
    case HI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan",
          [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan brit",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
        },
        employerLabel:
          "Kilès ki se anplwayè " + name + "pou travay endepandan sa yo?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "frekans",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
        },
      };
    default:
      return;
  }
}

export function enrollmentStatusCodeMapper(language) {
  switch (language) {
    case EN:
      return {
        "Fulltime": "FT",
        "Halftime": "HT",
        "Not Enrolled": "NE",
        "Completed": "CM",
        "Suspended": "SU"
      };
    case ES:
      return {
        "Tiempo completo": "FT",
        "Medio tiempo": "HT",
        "No está inscrito": "NE",
        "Estudios completos": "CM",
        "Estudios suspendidos": "SU"
      };

    default:
      return {
        "Fulltime": "FT",
        "Halftime": "HT",
        "Not Enrolled": "NE",
        "Completed": "CM",
        "Suspended": "SU"
      };
  }
}

export function enrollmentStatusdescMapper(language) {
  switch (language) {
    case EN:
      return {
        "FT": "Fulltime",
        "HT": "Halftime",
        "NE": "Not Enrolled",
        "CM": "Completed",
        "SU": "Suspended"
    };
    case ES:
      return{
        "FT": "Tiempo completo",
        "HT": "Medio tiempo",
        "NE": "No está inscrito",
        "CM": "Estudios completos",
        "SU": "Estudios suspendidos"
    };

    default:
      return {
        "FT": "Fulltime",
        "HT": "Halftime",
        "NE": "Not Enrolled",
        "CM": "Completed",
        "SU": "Suspended"
    };
  }
}

export function gradeDescMapper(language) {
  switch (language) {
    case EN:
      return {
        "NO": "None",
        "ELE": "Elementary School",
        "MIDSCH": "Middle School",
        "HGHSCH": "High School",
        "GED": "GED/High School Equivalency",
        "ASC": "Associate Degree",
        "BACH": "Bachelor's Degree",
        "MAST": "Master's",
        "DOC": "Doctorate",
        "CERTIF": "Other License/Certification",
        "LPN": "Licensed Practical Nurse",
        "DHLIC": "Dental Hygienist License",
        "CDL": "Truck Driver License",
        "EMT": "Emergency Medical Technician",
        "BEALIC": "Cosmetology License",
        "CNA": "Certified Nurse Assistant",
        "RN": "Registered Nurse",
        "TCHR": "Teacher Certificate",
        "PHYASS":"Physician Assistant",
        "TRNG":"Other Training Program"
    };
    case ES:
      return {
        "NO": "Ninguno",
        "ELE": "Escuela Primaria",
        "MIDSCH": "Escuela Intermedia",
        "HGHSCH": "Escuela Secundaria",
        "GED": "GED/Equivalencia de Escuela Secundaria",
        "ASC": "Título Asociado",
        "BACH": "Licenciatura",
        "MAST": "Maestría",
        "DOC": "Doctorado",
        "CERTIF": "Otra Licencia/Certificación",
        "LPN": "Enfermero Practico Licenciado",
        "DHLIC": "Licencia de Higienista Dental",
        "CDL": "Licencia de Conductor de Camión",
        "EMT": "Técnico en Emergencias Médicas",
        "BEALIC": "Licencia de Cosmetología",
        "CNA": "Certificado Asistente de Enfermería",
        "RN": "Enfermera Registrada",
        "TCHR": "Certificado de Maestro",
        "PHYASS":"Asistente medico",
        "TRNG":"Otro programa de formación"
    };

    default:
      return {
        "NO": "None",
        "ELE": "Elementary School",
        "MIDSCH": "Middle School",
        "HGHSCH": "High School",
        "GED": "GED/High School Equivalency",
        "ASC": "Associate Degree",
        "BACH": "Bachelor's Degree",
        "MAST": "Master's",
        "DOC": "Doctorate",
        "CERTIF": "Other License/Certification",
        "LPN": "Licensed Practical Nurse",
        "DHLIC": "Dental Hygienist License",
        "CDL": "Truck Driver License",
        "EMT": "Emergency Medical Technician",
        "BEALIC": "Cosmetology License",
        "CNA": "Certified Nurse Assistant",
        "RN": "Registered Nurse",
        "TCHR": "Teacher Certificate",
        "PHYASS":"Physician Assistant",
        "TRNG":"Other Training Program"
    };
  }
}


export function gradeCodeMapper(language) {
  switch (language) {
    case EN:
      return {
        "None": "NO",
        "Elementary School": "ELE",
        "Middle School": "MIDSCH",
        "High School": "HGHSCH",
        "GED/High School Equivalency": "GED",
        "Associate Degree": "ASC",
        "Bachelor's Degree": "BACH",
        "Master's": "MAST",
        "Doctorate": "DOC",
        "Other License/Certification": "CERTIF",
        "Licensed Practical Nurse": "LPN",
        "Dental Hygienist License": "DHLIC",
        "Truck Driver License": "CDL",
        "Emergency Medical Technician": "EMT",
        "Cosmetology License": "BEALIC",
        "Certified Nurse Assistant": "CNA",
        "Registered Nurse": "RN",
        "Teacher Certificate": "TCHR",
        "Physician Assistant":"PHYASS",
        "Other Training Program":"TRNG",
      };
    case ES:
      return {
        "Ninguno": "NO",
        "Escuela Primaria": "ELE",
        "Escuela Intermedia": "MIDSCH",
        "Escuela Secundaria": "HGHSCH",
        "GED/Equivalencia de Escuela Secundaria": "GED",
        "Título Asociado": "ASC",
        "Licenciatura": "BACH",
        "Maestría": "MAST",
        "Doctorado": "DOC",
        "Otra Licencia/Certificación": "CERTIF",
        "Enfermero Practico Licenciado": "LPN",
        "Licencia de Higienista Dental": "DHLIC",
        "Licencia de Conductor de Camión": "CDL",
        "Técnico en Emergencias Médicas": "EMT",
        "Licencia de Cosmetología": "BEALIC",
        "Certificado Asistente de Enfermería": "CNA",
        "Enfermera Registrada": "RN",
        "Certificado de Maestro": "TCHR",
        "Asistente medico":"PHYASS",
        "Otro programa de formación":"TRNG",
      };

    default:
      return {
        "None": "NO",
        "Elementary School": "ELE",
        "Middle School": "MIDSCH",
        "High School": "HGHSCH",
        "GED/High School Equivalency": "GED",
        "Associate Degree": "ASC",
        "Bachelor's Degree": "BACH",
        "Master's": "MAST",
        "Doctorate": "DOC",
        "Other License/Certification": "CERTIF",
        "Licensed Practical Nurse": "LPN",
        "Dental Hygienist License": "DHLIC",
        "Truck Driver License": "CDL",
        "Emergency Medical Technician": "EMT",
        "Cosmetology License": "BEALIC",
        "Certified Nurse Assistant": "CNA",
        "Registered Nurse": "RN",
        "Teacher Certificate": "TCHR",
        "Physician Assistant":"PHYASS",
        "Other Training Program":"TRNG",
      };
  }
}
