import _ from 'lodash';
import * as roleProps from "../../utils/constants/rolesConstants";
import * as TextProps from "../../utils/constants/text";

const initalData = {
  recordsCanNotLinked: false,
  recordsNotMatchError: false
}

export function consumerReducer(state = initalData, action) {
  switch (action.type) {
    case "CONSUMER_NOTIFICATION_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.notificationList = action.payload.notices;
      return newState;
    }

    case "CONSUMER_NOTICE_DATA_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.noticeData = action.payload;
      newState.isDocuemntLoaded = TextProps.VALUE_TRUE;
      newState.showDocumentLoading = TextProps.VALUE_TRUE;
      return newState;
    }

    case "UNLINK_ACCOUNT_SERVICE_SUCCESS": {
      let newState = Object.assign({}, state);
      newState.unlinkAccountSuccessState = action.payload;
      return newState;
    }

    case "UNLINK_ACCOUNT_STATE_CLEAR": {
      let newState = Object.assign({}, state);
      newState.unlinkAccountSuccessState = action.payload;
      return newState;
    }

    

    case "CONSUMER_NOTIFICATION_REJECTED": {
      let newState = Object.assign({}, state);
      newState.isDocuemntLoaded = TextProps.VALUE_FALSE;
      newState.showDocumentLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CONSUMER_DOCUMENTS_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.documentsList = action.payload.documents;
      newState.documentFilteredList = action.theCode;
      return newState;
    }

    case "CONSUMER_DOCUMENTLIST_DATA_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.documentClientList = action.payload;
      return newState;
    }

    case "CONSUMER_DOCUMENTLIST_DATA_REJECTED": {
      return Object.assign({}, state);
    }

    case "CONSUMER_FILTER_DOCUMENTS_LIST": {
      let newState = Object.assign({}, state);
      newState.documentFilteredList = action.payload;
      return newState;
    }

    case "INCOME_VERIFICATION_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.isIncomeVerified = action.payload.data;
      return newState;
    }

    case "EBT_CARD_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.isEbtCardRequested = action.payload.data;
      sessionStorage.setItem("requestEbtCardAlert", TextProps.VALUE_TRUE);
      return newState;
    }

    case "EBT_CARD_REQUEST_FAILED": {
      return Object.assign({}, state);
    }

    //SET THE ACTIVITY LIST IN OBJECT TO DISPACTCH
    case "CONSUMER_ACTIVITY_FULFILLED": {
      let newState = Object.assign({}, state);
      if (action.payload !== undefined) {
        newState.consumerActivityList = action.payload;
        newState.isLoading = TextProps.VALUE_FALSE;
      }
      return newState;
    }

    case "CONSUMER_ACTIVITY_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.isLoading = TextProps.VALUE_TRUE;
      return newState;
    }

    case "CONSUMER_SIGNUP_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.registrationConstants = action.payload;
      return newState;
    }

    case "NETWORK_OR_SERVICE_FAILED": {
      let newState = Object.assign({}, state);
      newState.consumerLogin = action.payload;
      newState.loginErrorState = TextProps.VALUE_TRUE;
      newState.loginErrorMessage = action.payload;
      newState.isLoading = TextProps.VALUE_TRUE;
      newState.mfaErrorState = TextProps.VALUE_TRUE;
      return newState;
    }

    case "CONSUMER_HOME_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.consumerHomeConstants = action.payload;
      return newState;
    }

    case "CONSUMER_SIGNUP_REQUESTED":
    case "CONSUMER_HOME_REQUESTED":
    case "CONSUMER_INFO_REQUESTED": {
      return Object.assign({}, state);
    }

    case "CONSUMER_INFO_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.consumerData = action.payload;
      return newState;
    }

    case "FORGOT_PASSWORD_INFO_REQUESTED":
    case "ASSIGN_PAYLOAD_FORGOT_PASSWORD": {
      let newState = Object.assign({}, state);
      newState.forgotPassword = action.payload;
      return newState;
    }

    case "SAVE_FORGOT_RESET_SUCCESS": {
      return Object.assign({}, state);
    }

    case "FORGOT_FROM_SERVICE_FAIL": {
      return Object.assign({}, state);
    }

    case "CONSUMER_DOCUMENT_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.consumerDocument = action.payload;
      return newState;
    }

    case "GET_CONSUMERUPLOAD_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.documentUploaded = TextProps.VALUE_TRUE;
      newState.consumerUploadDocument = action.payload;
      return newState;
    }

    case "GET_CONSUMERUPLOAD_FAILED": {
      let newState = Object.assign({}, state);
      newState.documentUploaded = "error";
      newState.consumerUploadDocument = action.payload;
      return newState;
    }

    case "SHOW_BENEFIT_LIST_INFO": {
      let newState = Object.assign({}, state);
      newState.showBenefitListInfo = action.payload;
      return newState;
    }

    case "NETWORK_FAILED_LOAD_DATA": {
      let newState = Object.assign({}, state);
      newState.isLoading = TextProps.VALUE_FALSE;
      newState.postDataSuccess = false;
      newState.postDataLoading = false;
      newState.postDataError = true;
      return newState;
    }

    case "BENEFIT_HISTORY_INFO": {
      let newState = Object.assign({}, state);
      newState.benefitHistoryInfo = action.payload;
      return newState;
    }

    case "ADD_ABOUT_ME_DATA": {
      let newState = Object.assign({}, state);
      newState.aboutMe = action.payload;
      return newState;
    }

    case "FILTER_BENEFIT_RECORDS": {
      let newState = Object.assign({}, state);
      newState.benefitHistoryFilter = action.payload;
      newState.isLoading = TextProps.VALUE_FALSE;
      newState.benefitFilter = TextProps.VALUE_TRUE;
      return newState;
    }

    case "POST_CONSUMER_LOGIN_SUCCESS": {
      let newState = Object.assign({}, state);
      newState.sessionID = action.payload.IS_SESSIONID;
      return newState;
    }

    case "LOGIN_STATUS_MESSAGE": {
      let newState = Object.assign({}, state);
      newState.loginMessage = "Enter valid and correct user name/password";
      return newState;
    }

    case "STORE_PROVIDER_CLIENT_DATA": {
      const newState = Object.assign({}, state);
      newState.centrifyData = {};
      newState.notificationList = [];
      newState.documentsList = [];
      newState.userInfo = action.payload;
      newState.consumerData = { user: {} };
      newState.consumerData.user = action.payload;
      newState.isLoading = TextProps.VALUE_FALSE;
      if (action.payload.accountID) {
        newState.isLoading = TextProps.VALUE_TRUE;
      }
      return newState;
    }

    case "REGISTER_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.code === "200") {
        newState.registrationSuccessful = TextProps.VALUE_TRUE;
      }
      return newState;
    }

    case "REGISTER_FAILED": {
      const newState = Object.assign({}, state);
      if (action.payload.code === "506" || action.payload.code === "601") {
        newState.registrationErrorState = TextProps.VALUE_TRUE;
        newState.registrationErrorMessage = action.payload.message;
      }
      return newState;
    }

    case "AZURE_LOGIN_SUCCESSFULL": {
      const newState = Object.assign({}, state);
      if (action.payload !== "") {
        newState.loginSuccessful = true;
        newState.mfaLoginValidated = true;
        newState.loginDetails = action.payload;
        sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
        if (action.payload.roleList[0].roleName === roleProps.USER) {
          sessionStorage.setItem("isNewConsumer", TextProps.VALUE_TRUE);
          newState.isLoading = TextProps.VALUE_FALSE;
        }
        newState.langPreSelection = action.payload.languageCode;
      } else {
        newState.loginErrorState = true;
        newState.loginErrorMessage = action.payload.response.data.message;
      }
      
      newState.langChangeSaved = TextProps.VALUE_FALSE;
      return newState;
    }

    case "LOGIN_SUCCESSFULL": {
      const newState = Object.assign({}, state);
      if (action.payload !== "") {
        newState.isLoading = TextProps.VALUE_TRUE;
        newState.loginSuccessful = TextProps.VALUE_TRUE;
        newState.mfaLoginValidated = TextProps.VALUE_TRUE;
        newState.loginDetails = action.payload;
        newState.langPreSelection = action.payload.languageCode;
        newState.langChangeSaved = TextProps.VALUE_FALSE;
        sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
        if (action.payload.roleList[0].roleName === roleProps.USER) {
          sessionStorage.setItem("isNewConsumer", TextProps.VALUE_TRUE);
          newState.isLoading = TextProps.VALUE_FALSE;
        }
      } else {
        newState.loginErrorState = TextProps.VALUE_TRUE;
        newState.loginErrorMessage = action.payload.response.data.message;
      }
      return newState;
    }
    case "LANGUAGE_CHANGED_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.langChangeSaved = action.payload;
      return newState;
    }

    case "MFA_SUCCESSFULL": {
      const newState = Object.assign({}, state);

      const mfaLogin =
        action.payload.expires_in <= 0
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;
      const mfaLoginDetails = action.payload;
      newState.loginSuccessful = TextProps.VALUE_TRUE;
      newState.mfaLoginValidated = TextProps.VALUE_FALSE;
      newState.mfaLoginDetails = mfaLoginDetails;
      newState.mfaLogin = mfaLogin;
      return newState;
    }

    case "FORGOT_EMAIL_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.forgetEmailSuccessful = TextProps.VALUE_TRUE;
        newState.forgetEmailDetails = action.payload.data.forgotpassword;
      }
      return newState;
    }

    case "FORGOT_MFACODE_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.forgetMfaSuccessful = TextProps.VALUE_TRUE;
        newState.forgetMfaDetails = action.payload.data.forgotpassword;
      }
      return newState;
    }

    case "FORGOT_PASS_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.forgetPasswordSuccessful = TextProps.VALUE_TRUE;
        newState.forgetPasswordDetails = action.payload.data.forgotpassword;
      }
      return newState;
    }

    case "CONSUMER_LOGIN_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.clientInfo !== undefined) {
        newState.consumerData = action.payload;
        newState.consumerLoaded = TextProps.VALUE_TRUE;
        newState.isLoading = TextProps.VALUE_FALSE;
        newState.addressFlagupdated = TextProps.VALUE_TRUE;
      }
      return newState;
    }

    case "P_EBT_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.data) {
        newState.pEbtClient = action.payload.data;
        newState.pEbtClient.map((obj) => {
          var base64regex =
            /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
          if (base64regex.test(obj.desc)) {
            let buff = new Buffer(obj.desc, "base64");
            obj.desc = buff.toString("UTF-8");
          }
        });
      }
      return newState;
    }

    case "P_EBT_ERROR": {
      const newState = Object.assign({}, state);
      newState.pEbtClient = {};
      return newState;
    }

    case "CHANGE_PASSWORD_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.success === TextProps.VALUE_TRUE) {
        newState.isPasswordChange = TextProps.VALUE_TRUE;
      } else {
        newState.passwordChangeErrorState = TextProps.VALUE_TRUE;
        newState.passwordChangeErrorMessage = action.payload.Message;
      }
      return newState;
    }

    case "BIND_EMAIL_APPLY_INPROGRESS": {
      const newState = Object.assign({}, state);
      newState.mfaDetails.userDetails.email = action.payload;
      newState.registrationSuccessful = TextProps.VALUE_FALSE;
      return newState;
    }

    case "RESET_SIGNUP_STATE": {
      const newState = Object.assign({}, state);
      newState.registrationSuccessful = TextProps.VALUE_FALSE;
      newState.registrationErrorState = TextProps.VALUE_FALSE;
      return newState;
    }

    case "PROVIDER_CLIENT_ABOUT_ME_INFO": {
      const newState = Object.assign({}, state);
      newState.centrifyData = action.payload;
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "USER_LOGGED_OUT": {
      const newState = Object.assign({}, state);
      if (action.payload.code === "200") {
        newState.consumerData = {};
        newState.centrifyData = {};
        newState.loggedIn = TextProps.VALUE_FALSE;
      }
      return newState;
    }

    case "GET_CONSUMER_BENEFIT_DETAILS": {
      const newState = Object.assign({}, state);
      newState.isLoading = TextProps.VALUE_TRUE;
      return newState;
    }

    case "GET_CONSUMER_BENEFIT_DETAILS_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.benefitFilter = undefined;
      newState.isLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CONNECT_DTA_ACCOUNT_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        var status = action.payload.data;
        if (
          status.code !== undefined &&
          (status.code === "101" || status.code === "515")
        ) {
          newState.accountNotFoundError = TextProps.VALUE_TRUE;
          newState.accountConnected = TextProps.VALUE_FALSE;
        } else if (
          status.code !== undefined && status.code === "518") {
          newState.recordsCanNotLinked = TextProps.VALUE_TRUE;
          newState.accountConnected = TextProps.VALUE_FALSE;
        } else if (status.code === undefined) {
          newState.accountConnected = TextProps.VALUE_TRUE;
          sessionStorage.setItem("loadConsumerUser", JSON.stringify(status));
        }
      } else {
        newState.recordsNotMatchError = TextProps.VALUE_TRUE;
        newState.accountConnected = TextProps.VALUE_FALSE;
      }
      return newState;
    }

    case "CONNECT_DTA_ACCOUNT_ERROR":
      {
        const newState = Object.assign({}, state);
        if (action.payload.response !== "") {
          var error = action.payload.response;
          if (error !== undefined) {
            if (
              error.data.code !== undefined &&
              (error.data.code === "101" ||
                error.data.code === "515" ||
                error.data.code === "508")
            ) {
              newState.recordsNotMatchError = TextProps.VALUE_TRUE;
              newState.accountConnected = TextProps.VALUE_FALSE;
              newState.recordsCanNotLinked = TextProps.VALUE_FALSE;
            } else if(error.data.code === "518"){
              newState.recordsNotMatchError = TextProps.VALUE_FALSE;
              newState.recordsCanNotLinked = TextProps.VALUE_TRUE;
            }
            newState.accountConnected = TextProps.VALUE_FALSE;
            return newState;
          }
        }
      }
      break;
    case "CONSUMER_PHONE_FULFILLED":
    case "CONSUMER_EMAIL_FULFILLED": {
      const newState = Object.assign({}, state);
      newState.myInfoUpdated = TextProps.VALUE_TRUE;
      return newState;
    }

    case "IS_MAIL_MY_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      var mailMyInfo = sessionStorage.getItem("mailMyInfo");
      if (mailMyInfo !== null) {
        newState.mailMyInfo = mailMyInfo;
      }
      if (action.payload !== undefined) {
        if (action.payload.status === 200) {
            newState.myInfoUpdated = TextProps.VALUE_TRUE;
            newState.disableAddressEdit = action.payload.disableUpdate;
          }
        }
      newState.showAddressValidationModal = TextProps.VALUE_FALSE;
      return newState;
    }

    case "IS_POP_UP_CLOSED": {
      const newState = Object.assign({}, state);
      newState.myInfoUpdated = TextProps.VALUE_FALSE;
      newState.showAddressValidationModal = TextProps.VALUE_FALSE;
      return newState;
    }

    case "IS_HOUSEHOLD_INFO_SUCCESS": {
      const newState = Object.assign({}, state);
      var isHousehold = sessionStorage.getItem("isHousehold");
      if (isHousehold !== null) {
        newState.isHousehold = isHousehold;
      }
      return newState;
    }

    case "SHOW_SCEDULAR_PAGE": {
      const newState = Object.assign({}, state);
      newState.showSchedularPage = TextProps.VALUE_TRUE;
      return newState;
    }

    case "SHOW_SCEDULAR_SNAP_PAGE": {
      const newState = Object.assign({}, state);
      newState.showSchedularSnapPage = TextProps.VALUE_TRUE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
      return newState;
    }

    case "SHOW_SCEDULAR_CASE_MODEL_PAGE": {
      const newState = Object.assign({}, state);
      newState.showSchedularSnapPage = TextProps.VALUE_FALSE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_TRUE;
      return newState;
    }

    case "HIDE_SCEDULAR_PAGE": {
      const newState = Object.assign({}, state);
      newState.showSchedularPage = TextProps.VALUE_FALSE;
      newState.aptSnapRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.aptCaseModelRescheduleSubmitted = TextProps.VALUE_FALSE;
      return newState;
    }

    case "APPOINTMENT_RESCHEDULE_FAW_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.aptSnapRescheduleSubmitted = TextProps.VALUE_TRUE;
        newState.aptCaseModelRescheduleSubmitted = TextProps.VALUE_FALSE;
      }
      setTimeout(() => {
        window.location.assign("/consumer/activity");
      }, 5000);
      return newState;
    }

    case "APPOINTMENT_RESCHEDULE_CASE_MODEL_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.aptSnapRescheduleSubmitted = TextProps.VALUE_FALSE;
        newState.aptCaseModelRescheduleSubmitted = TextProps.VALUE_TRUE;
      }
      setTimeout(() => {
        window.location.assign("/consumer/activity");
      }, 5000);
      return newState;
    }

    case "APPOINTMENT_RESCHEDULER_SERVICE_FAILED": {
      const newState = Object.assign({}, state);
      if (action.payload.response !== undefined) {
        if (action.payload.response.data.code === "521") {
          newState.dateTimeNotAvailable = TextProps.VALUE_TRUE;
          newState.showPopupFlag = TextProps.VALUE_FALSE;
        }else{
          newState.showPopupFlag = TextProps.VALUE_FALSE;
          newState.rescheduleAptFailure = TextProps.VALUE_TRUE;
        }
      } else {
        newState.showPopupFlag = TextProps.VALUE_TRUE;
      }
      return newState;
    }

    case "CANCEL_APPOINTMENT_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.cancelDataLoading = true;
      newState.cancelAppointmentSuccess = false;
      newState.cancelAppointmentFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CANCEL_APPOINTMENT_SUCCESS": {
      const newState = Object.assign({}, state);
        newState.cancelAppointmentSuccess = TextProps.VALUE_TRUE;
        newState.cancelDataLoading = false;
        newState.cancelAppointmentFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CANCEL_APPOINTMENT_FAILURE": {
      const newState = Object.assign({}, state);
      newState.cancelAppointmentFailure = TextProps.VALUE_TRUE;
      newState.cancelDataLoading = false;
      newState.cancelAppointmentSuccess = TextProps.VALUE_FALSE;
      newState.showPopupFlag = TextProps.VALUE_TRUE;
      return newState;
    }

    case "GET_DATE_TIMES_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.availableDateTimeSlots = action.payload.data;
      if (action.payload.data !== undefined && action.payload.status === 200) {
        newState.appointmentAvailableSlots = action.payload.data;
        newState.showSchedularPage = TextProps.VALUE_TRUE;
        newState.showSchedularSnapPage = TextProps.VALUE_TRUE;
      }
      if (action.payload.status === 204) {
        newState.showPopupFlag = TextProps.VALUE_FALSE;
        newState.showSchedularPage = TextProps.VALUE_FALSE;
        newState.showSchedularSnapPage = TextProps.VALUE_FALSE;
        newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
        newState.dateTimeNotAvailable = TextProps.VALUE_FALSE;
        newState.slotsNotAvailable = TextProps.VALUE_TRUE;
      }
      return newState;
    }

    case "GET_DATE_TIMES_FAILIURE": {
      const newState = Object.assign({}, state);
      newState.showPopupFlag = TextProps.VALUE_TRUE;
      newState.showSchedularPage = TextProps.VALUE_FALSE;
      newState.showSchedularSnapPage = TextProps.VALUE_FALSE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
      return newState;
    }

    case "HIDE_POPUP": {
      let newState = Object.assign({}, state);
      newState.showPopupFlag = TextProps.VALUE_FALSE;
      newState.dateTimeNotAvailable = TextProps.VALUE_FALSE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
      newState.showSchedularPage = TextProps.VALUE_FALSE;
      newState.showSchedularSnapPage = TextProps.VALUE_FALSE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
      newState.aptSnapRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.aptComboRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.aptTafdcRescheduleSubmitted = TextProps.VALUE_FALSE;
      return newState;
    }
    case "ADDRS_SHOW_WARNING_POPUP": {
      let newState = Object.assign({}, state);
      newState.showAddrWarningModal = action.payload.status;
      newState.reportChangeEditingModule = action.payload.module;
      newState.reportChangeEditingAction = action.payload.action;
      return newState;
    }

    case "SHOW_URGENT_APPOINTMENT_ALERT": {
      let newState = Object.assign({}, state);
      newState.showAppointmentAlert = TextProps.VALUE_TRUE;
      return newState;
    }

    case "HIDE_URGENT_APPOINTMENT_ALERT": {
      let newState = Object.assign({}, state);
      newState.showAppointmentAlert = TextProps.VALUE_FALSE;
      return newState;
    }


    //Report a change Actions 

    case "FETCH_SECTION_QUESTIONS": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections = action.payload;
      return newState;
    }

    case "UPDATE_REPORT_CHANGE_SECTIONS": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections = action.payload;
      return newState;
    }

    case "ADD_HOUSEHOLD_INFO_INTO_SECTIONS":{
      let newState = Object.assign({}, state);
      return newState;
    }
    
    case "DELETE_HOUSEHOLD_INFO_INTO_SECTIONS":{
      let newState = Object.assign({}, state);
      newState.reportChangeSections[0].subsections = null;
      return newState;
    }
    

    case "RC_SET_HOUSEHOLD_MEMBERS": {
      let newState = Object.assign({}, state);
      newState.householdMembers = action.payload;
      return newState;
    }

    case "ADD_HOUSEHOLD_MEMBER_SECTION": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections[0].subsections = action.payload;
      return newState;
    }

    case "UPDATE_RC_HOUSEHOLD_LABEL_CHANGE": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections[0].subsections = action.payload;
      return newState;
    }


    case "SET_ERROR": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections = action.payload;
      return newState;
    }
    

    case "REPORT_POST_DATA_LOADING":{
      let newState = Object.assign({}, state);
      newState.postDataLoading = true;
      newState.postDataSuccess = false;
      newState.postDataError = false;
      return newState;
    }
    
    case "REPORT_POST_DATA_SUCCESS":{
      let newState = Object.assign({}, state);
      newState.postDataSuccess = true;
      newState.postDataLoading = false;
      newState.postDataError = false;
      return newState;
    }

    case "REPORT_POST_DATA_ERROR":{
      let newState = Object.assign({}, state);
      newState.postDataSuccess = false;
      newState.postDataLoading = false;
      newState.postDataError = true;
      return newState;
    }

    case "APPEND_RC_INCOME": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections = [action.payload];
      return newState;
    }

    case "SET_RC_SHELTER_DATA": {
      const newState = _.cloneDeep(state);
      newState.shelterUtilityData = action.payload;
      return newState;
    }

    case "APPEND_RC_SECTIONS": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections = [action.payload];
      return newState;
    }

    case "APPEND_RC_SHELTER_SUBSECTIONS_QUESTIONS": {
      const newState = Object.assign({}, state);
      newState.reportChangeSections[0].subsections[0].subsections[0].questions = action.payload;
      return newState;
    }

    case "RC_SET_CHILD_SUPPORT": {
      const newState = Object.assign({}, state);
      newState.childExpScreen = action.payload;
      return newState;
    }

    case "APPEND_RC_CHILD_SUPPORT_ANSWER": {
      const newState = Object.assign({}, state);
      newState.childExpScreen.tempChildExpArray = action.payload;
      return newState;
    }

    case "APPEND_RC_UTILITY_LIHEAP_DATA": {
      const otherIncomeState = _.cloneDeep(state);
      otherIncomeState.isLiheapDataUpdated = action.payload;
      return otherIncomeState;
    }

    case "APPEND_SIGN_IN_SECTIONS": {
      let newState = Object.assign({}, state);
      newState.reportChangeSections[1] = action.payload;
      return newState;
    }

    case "APPEND_RC_RESOURCES_DATA": {
      const resourcesState = Object.assign({}, state);
      resourcesState.reportChangeSections = [action.payload];
      return resourcesState;
    }
    
    case "SET_HOUSEHOLD_RESOURCES_STATUS": {
      const recertState = Object.assign({}, state);
      recertState.householdResourcesChanged = action.payload;
      return recertState;
    }
    case "SET_ADDRESS_FLAG": {
      let newState = Object.assign({}, state);     
      newState.addressFlagupdated = action.payload;      
      return newState;
    }
    

    case "@@redux/INIT": {
      let newState = Object.assign({}, state);
      newState.constantStrings = {};
      newState.registrationConstants = {};
      newState.consumerLogin = {};
      newState.consumerHomeConstants = {};
      newState.consumerData = {};
      newState.forgotPassword = {};
      newState.sessionID = "";
      newState.consumerDocument = [];
      newState.consumerUploadDocument = {};
      newState.showBenefitListInfo = [];
      newState.benefitHistoryInfo = {};
      newState.benefitHouseHoldInfo = [];
      newState.aboutMe = [];
      newState.loginMessage = " ";
      newState.userInfo = {};
      newState.registrationSuccessful = TextProps.VALUE_FALSE;
      newState.registrationErrorState = TextProps.VALUE_FALSE;
      newState.registrationErrorMessage = "";
      newState.loginSuccessful = TextProps.VALUE_FALSE;
      newState.loginErrorState = TextProps.VALUE_FALSE;
      newState.loginErrorMessage = "";
      newState.mfaSuccessful = TextProps.VALUE_FALSE;
      newState.mfaErrorState = TextProps.VALUE_FALSE;
      newState.mfaErrorMessage = "";
      newState.mfaDetails = {};
      newState.isPasswordChange = TextProps.VALUE_FALSE;
      newState.passwordChangeErrorState = TextProps.VALUE_FALSE;
      newState.passwordChangeErrorMessage = "";
      newState.mfaDetails.userDetails = {};
      newState.mfaDetails.userDetails.email = "";
      newState.centrifyData = {};
      newState.loggedIn = TextProps.VALUE_FALSE;
      newState.loginDetails = {};
      newState.myInfoUpdated = TextProps.VALUE_FALSE;
      newState.showSchedularPage = TextProps.VALUE_FALSE;
      newState.showSchedularSnapPage = TextProps.VALUE_FALSE;
      newState.showSchedularCaseModelPage = TextProps.VALUE_FALSE;
      newState.aptSnapRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.aptComboRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.aptTafdcRescheduleSubmitted = TextProps.VALUE_FALSE;
      newState.showPopupFlag = TextProps.VALUE_FALSE;
      newState.appointmentAvailableSlots = null;
      newState.showAppointmentAlert = TextProps.VALUE_FALSE;
      newState.dateTimeNotAvailable = TextProps.VALUE_FALSE;
      newState.showMailingAddressValidationModal = TextProps.VALUE_FALSE;
      newState.withdrawRequest=TextProps.VALUE_FALSE;
      newState.withdrawSuccess =TextProps.VALUE_FALSE;
      newState.withdrawFailure= TextProps.VALUE_FALSE;
      newState.appealdocRequest=TextProps.VALUE_FALSE;
      newState.appealdocSuccess=TextProps.VALUE_FALSE;
      newState.appealdocFailure=TextProps.VALUE_FALSE;
      
      newState.consumerAppealsLoading = {};
      newState.consumerAppealsSuccess = {};
      newState.consumerAppealsFailure = TextProps.VALUE_FALSE;
    
      newState.anonymousGetCallSuccess = {},
      newState.documentDetailList = {};

      return newState;
    }

    case "ADDR_VALIDATION_POPUP": {
      let newState = Object.assign({}, state);
      newState.showAddressValidationModal = TextProps.VALUE_TRUE;
      newState.showMailingAddressValidationModal = TextProps.VALUE_FALSE;
      newState.melissaPayload = action.melissaPayload;
      return newState;
    }
    case "MAILING_ADDR_VALIDATION_POPUP": {
      let newState = Object.assign({}, state);
      newState.existingAddressObj = action.melissaPayload;
      return newState;
    }
    case "SECURE_MESSAGE_LIST_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.messageList = action.payload;
      return newState;
    }

    case "SECURE_MESSAGE_LIST_REJECTED": {
      return Object.assign({}, state);

    }
    case "APPLICATION_TYPE_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.caseManager = action.payload;
      return newState;
    }

    case "SECURE_MESSAGE_NEW_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.newMessage = action.payload;
      return newState;
    }
    case "SECURE_MESSAGE_UPDATE_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.updateMessage = action.payload;
      return newState;
    }
    case "SECURE_MESSAGE_REPLY_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.replyMessage = action.payload;
      return newState;
    }
     // consumer Appeals
     case "CONSUMER_APPEALS_REQUESTED": {
      let newState = Object.assign({}, state);
      newState.consumerAppealsLoading =TextProps.VALUE_TRUE;
      // newState.consumerAppealsSuccess= TextProps.VALUE_FALSE;
      newState.consumerAppealsFailure= TextProps.VALUE_FALSE;
      return newState;
    }

    case "CONSUMER_APPEALS_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.consumerAppealsLoading =TextProps.VALUE_FALSE;
      newState.consumerAppealsSuccess= action.payload.data;
      newState.consumerAppealsFailure= TextProps.VALUE_FALSE;
      return newState;
    }
    case "CONSUMER_APPEALS_FAILURE": {
      const newState = Object.assign({}, state);
      newState.consumerAppealsLoading =TextProps.VALUE_FALSE;
      // newState.consumerAppealsSuccess= TextProps.VALUE_FALSE;
      newState.consumerAppealsFailure= TextProps.VALUE_TRUE;
      return newState;
    }
      case "NOTICES_UPDATE_SUCCESS": {
      const newState = Object.assign({}, state);    
        newState.noticesUpdateSuccess = TextProps.VALUE_TRUE;
        newState.noticesUpdateFailure = TextProps.VALUE_FALSE;    
      return newState;
    }

    case "NOTICES_UPDATE_FAILURE": {
      const newState = Object.assign({}, state);
      newState.noticesUpdateFailure = TextProps.VALUE_TRUE;
      newState.noticesUpdateSuccess = TextProps.VALUE_FALSE;
      return newState;
    }
    case "DOCUMENT_DETAIL_LIST":{
      const newState = Object.assign({}, state);
      newState.documentDetailList = action.payload.data;
      return newState;
    }
    case "MESSAGE_STATUS_FLG": {
      const newState = Object.assign({}, state);
      newState.messageStatus = action.payload;
      return newState;
    }
    case "ERROR_MESSAGE_STATUS_FLG": {
      const newState = Object.assign({}, state);
      newState.errormessageStatus = action.payload;
      return newState;
    }
    case "GET_TRACK_APPLICATION_DATA_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.trackAppData = action.payload;
      return newState;
    }
    default:
      return Object.assign({}, state);
  }
}
