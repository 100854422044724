import React, { Component } from 'react'
import * as routeProps from "../../../../utils/constants/routesConstants.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { consumerConst } from "../../consumerText";
import * as formatters from '../../../../utils/components/utilities/formatters.js';
import Loading from '../../../../utils/components/loading.js';

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class concernHome extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
     concerndata:'',
      appealdata:''
  }
}
  
withdrawAppeal (appeal){
  window.sessionStorage.setItem('selectedAppeal',JSON.stringify(appeal));
  window.location.assign(routeProps.APPEAL_WITHDRAW);
}

getconcernlabel = (concernTypeCode) => {
  switch (concernTypeCode) {
    case "INQ":
      return consumerConst(selectedLanguage).concernsTypeOptions[0];
    case "CMPLNT":
      return consumerConst(selectedLanguage).concernsTypeOptions[1];
    case "FRAUD":
      return consumerConst(selectedLanguage).concernsTypeOptions[2];
    case "CONF":
      return consumerConst(selectedLanguage).concernsTypeOptions[3];
    case "State Office Review":
      return consumerConst(selectedLanguage).concernsTypeOptions[4];
    default:
      return concernTypeCode;
  }
};
getConcernStatus = (concernStatus) =>{
  switch(concernStatus){
    case "CLOSED":
      return consumerConst(selectedLanguage).closedLabel;
    case "PEND":
      return consumerConst(selectedLanguage).pendingLabel;
      default:
        return concernStatus;

  }
}
getAppealStatus = (appealStatus) =>{
  switch(appealStatus){
    case "CLOSED":
      return consumerConst(selectedLanguage).closedLabel;
    case "PEND":
      return consumerConst(selectedLanguage).pendingLabel;
      default:
        return appealStatus;

  }
}
getappeallabel = (appealTypeCode) => {
  switch (appealTypeCode) {
    case "FAIRHR":
      return consumerConst(selectedLanguage).appealType;
   
    default:
      return appealTypeCode;
  }
};



renderconcernsAppeals(concernsAppeals) {
  return concernsAppeals.map((item) => (
    <li key={item.id} style={{ borderBottom: '1px solid #d6d6d6', padding: '20px' }}>
      <div className="pure-g margin-bottom my-info-row">
        <div className="pure-u-1" style={{ width: "400px" }}>
          {item.concernTypeCode ? (
            <strong>{consumerConst(selectedLanguage).concernlabel} {this.getconcernlabel(item.concernTypeCode)}</strong>
          ) : (
            <strong> {this.getappeallabel(item.appealTypeCode)}</strong>
          )}
          <br />
          {
            item.anonymusFlag === "Y" ? (
              `${consumerConst(selectedLanguage).forlabel} ${consumerConst(selectedLanguage).anonymusUserlabel}${consumerConst(selectedLanguage).receivedlabel} (${formatters.convertTimestampToToDataFormatConcern(item.receivedDate)})`
            ) : (
              `${consumerConst(selectedLanguage).forlabel} ${item.clientName.first},${item.clientName.last} ${formatters.formatDate(item.dateOfBirth)}, ${consumerConst(selectedLanguage).receivedlabel} (${formatters.convertTimestampToToDataFormatConcern(item.receivedDate)})`
            )
          }
        </div>
        <div className="pure-u-1" style={{ width: "300px", display: 'flex', justifyContent: 'center' }}>
          <div style={{ textAlign: 'left', width: '100%' }}>
            {item.concernStatus ? (
              <React.Fragment>
                {this.getConcernStatus(item.concernStatus)}
                <br />
                {consumerConst(selectedLanguage).StatusChangedlabel} <strong>({(formatters.convertTimestampToToDataFormatConcern(item.lastUpdateTs))})</strong>
                </React.Fragment>
            ) : (
              <React.Fragment>
                {this.getAppealStatus(item.appealStatus)}
                <br />
                {consumerConst(selectedLanguage).StatusChangedlabel} <strong>({(formatters.convertTimestampToToDataFormatConcern(item.lastUpdateTs))})</strong>
                </React.Fragment>
            )}
          </div>
        </div>
        <div className="pure-u-1" style={{ width: "200px", justifyContent: 'flex-end', display: 'flex' }}>
          {item.appealStatus && (
            <button
              className="dta-button dta-button--large dta-button--outline-primary"
              onClick={() => this.withdrawAppeal(item)}
              disabled={item.allowWithdraw === "N"}
            >
              {consumerConst(selectedLanguage).withdraw}
            </button>
          )}
        </div>
      </div>
    </li>
  ));
}



  render() {

    if (this.props.concernsAppeals !== undefined && this.props.concernsAppeals.concernsAppeals !== undefined) {
      this.state.concerndata = this.props.concernsAppeals.concernsAppeals.concerns
      this.state.appealdata = this.props.concernsAppeals.concernsAppeals.appeals

    }
    let concerns = this.state.concerndata;
    let appeals = this.state.appealdata;
    let concernsAppeals = concerns.concat(appeals);

    if (concernsAppeals && concernsAppeals.length > 0) {

      concernsAppeals.sort((a, b) => new Date(b.lastUpdateTs) - new Date(a.lastUpdateTs));


    } else {
      console.error('concernsAppeals is not an array.');
    }






    return (
      <div >
      {this.props.concernsAppeals.getConcernsAppealDataLoading ?  <Loading/>        
      : 
        (this.props.concernsAppeals !== undefined && this.props.concernsAppeals.concernsAppeals !== undefined && 

          (this.props.concernsAppeals.concernsAppeals.appeals.length >0  || this.props.concernsAppeals.concernsAppeals.concerns.length > 0) ? (
            <div>

              <ul style={{ listStyleType: 'none' }}>
                {this.renderconcernsAppeals(concernsAppeals)}
              </ul>
            </div>
            
          ) : (
            <div style={{display : 'flex', justifyContent :'center'}}>
            {consumerConst(selectedLanguage).nothinghereYetlabel}
          </div>
          ))
         
      }

    </div>


    )
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
 
      },
      dispatch
    );
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(concernHome);