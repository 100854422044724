import _ from "lodash";
import { MONTHSHORT } from "../../utils/constants/controls";
import { isNone } from "../../utils/components/utilities/controls";
import * as OptionsProps from "../../utils/constants/options";
import * as TextProps from "../../utils/constants/text";

export function houseHoldBinding(
  sections,
  householdTemplate,
  householdDisabilityTemplate,
  serverJson
) {
  let AboutMeSection = sections[1];
  let HouseHoldSection = sections[2];
  let application = serverJson.application;
  let applicant = application.applicant;

  //First Bind the HouseHold Section.
  let HouseHoldQuestions = HouseHoldSection.questions;
  HouseHoldQuestions[0] = bindBothTargetAnswer(
    HouseHoldQuestions[0],
    application.householdSize
  );

  HouseHoldSection.subsections = [];
  //Itreting HouseHold
  for (
    let houseHoldIndex = 0;
    houseHoldIndex < applicant.household.length;
    houseHoldIndex++
  ) {
    let dependentTemplate = _.cloneDeep(householdTemplate);
    let dependentValues = applicant.household[houseHoldIndex];

    let dependentQuestions = dependentTemplate.questions;

    dependentTemplate.id = "household-member-" + (houseHoldIndex + 1);
    dependentTemplate.header = "About " + dependentValues.firstName;
    dependentTemplate.clientId = dependentValues.clientId;
    dependentTemplate.isGrantee = dependentValues.isGrantee;
    dependentQuestions[0] = bindBothTargetAnswer(
      dependentQuestions[0],
      dependentValues.firstName
    );
    dependentQuestions[1] = bindBothTargetAnswer(
      dependentQuestions[1],
      dependentValues.middleName
    );
    dependentQuestions[2] = bindBothTargetAnswer(
      dependentQuestions[2],
      dependentValues.lastName
    );
    dependentQuestions[3] = bindBothTargetAnswer(
      dependentQuestions[3],
      dependentValues.nameSuffix
    );
    dependentQuestions[4] = bindBothTargetAnswer(
      dependentQuestions[4],
      dependentValues.relationshipCode
    );
    dependentQuestions[5] = bindBothTargetAnswer(
      dependentQuestions[5],
      dependentValues.birthDate
    );
    dependentQuestions[6] = bindBothTargetAnswer(
      dependentQuestions[6],
      dependentValues.gender
    );
    dependentQuestions[7] = bindBothTargetAnswer(
      dependentQuestions[7],
      dependentValues.isUSCitizen === TextProps.VALUE_Y
        ? TextProps.VALUE_YES
        : TextProps.VALUE_NO
    );
    dependentQuestions[8] = bindBothTargetAnswer(
      dependentQuestions[8],
      dependentValues.SSN
    );
    HouseHoldSection.subsections.push(dependentTemplate);
  }
  HouseHoldSection.subsections.push(householdDisabilityTemplate);
  //Disability
  var houseHoldMembers = HouseHoldSection.subsections;
  let aboutMeSectionQuestions = AboutMeSection.questions;
  let aboutMeName =
    aboutMeSectionQuestions[0].Answer +
    " " +
    aboutMeSectionQuestions[2].Answer +
    " " +
    _convertDateToState(aboutMeSectionQuestions[4].Answer);
  let houseHoldMemberOptions = [];
  houseHoldMembers[houseHoldMembers.length - 1].questions[0].options = [];
  let aboutMeOption = {};
  aboutMeOption[OptionsProps.OPTION_VALUE] = AboutMeSection.clientId;
  aboutMeOption[OptionsProps.OPTION_DISPLAY] = aboutMeName;
  aboutMeOption.isGrantee = AboutMeSection.isGrantee;
  houseHoldMembers[houseHoldMembers.length - 1].questions[0].Answer = [];
  houseHoldMembers[houseHoldMembers.length - 1].questions[0].targetValue = [];
  if (applicant.hasDisability !== "" && applicant.hasDisability !== null) {
    let hasDisability =
      applicant.hasDisability === TextProps.VALUE_Y
        ? TextProps.VALUE_YES
        : TextProps.VALUE_NO;
    if (hasDisability === "Yes") {
      houseHoldMembers[houseHoldMembers.length - 1].questions[0].Answer.push(
        AboutMeSection.clientId
      );
      houseHoldMembers[
        houseHoldMembers.length - 1
      ].questions[0].targetValue.push(AboutMeSection.clientId);
    }
  }

  houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
    aboutMeOption
  );
  for (
    var memberIndex = 0;
    memberIndex < houseHoldMembers.length - 1;
    memberIndex++
  ) {
    // changed if member first name not entered , taking count of index namespace title
    var memName = houseHoldMembers[memberIndex].questions[0].Answer;
    if (memName !== "" && memName !== undefined) {
      memName =
        houseHoldMembers[memberIndex].questions[0].Answer +
        " " +
        houseHoldMembers[memberIndex].questions[2].Answer +
        " " +
        _convertDateToState(houseHoldMembers[memberIndex].questions[5].Answer);
    } else {
      memName = houseHoldMembers[memberIndex].title;
    }
    houseHoldMemberOptions[memberIndex] = {};
    houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_VALUE] =
      houseHoldMembers[memberIndex].clientId;
    houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_DISPLAY] = memName;
    houseHoldMemberOptions[memberIndex].isGrantee =
      houseHoldMembers[memberIndex].isGrantee;
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
      houseHoldMemberOptions[memberIndex]
    );
    if (
      applicant.household[memberIndex].hasDisability !== "" &&
      applicant.household[memberIndex].hasDisability !== null
    ) {
      let hasDepDisability =
        applicant.household[memberIndex].hasDisability === TextProps.VALUE_Y
          ? TextProps.VALUE_YES
          : TextProps.VALUE_NO;
      if (hasDepDisability === "Yes") {
        houseHoldMembers[houseHoldMembers.length - 1].questions[0].Answer.push(
          houseHoldMembers[memberIndex].clientId
        );
        houseHoldMembers[
          houseHoldMembers.length - 1
        ].questions[0].targetValue.push(houseHoldMembers[memberIndex].clientId);
      }
    }
  }
  return HouseHoldSection;
}

//Answer and Target Object Created Here
function bindBothTargetAnswer(question, Answer) {
  question.Answer = Answer;
  question.targetValue = Answer;
  return question;
}

function _convertMonthNumberToString(monthNumber) {
  return isNone(MONTHSHORT[monthNumber])
    ? MONTHSHORT[0]
    : MONTHSHORT[monthNumber];
}

function _convertDateToState(date) {
  if (isNone(date)) {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return (
    " (" +
    date.getDate() +
    "." +
    _convertMonthNumberToString(date.getMonth()) +
    "." +
    date.getFullYear() +
    ")"
  );
}
