import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import * as Names from "../../../utils/constants/names";
import * as OptionsProps from "../../../utils/constants/options";
import * as Props from "../../../utils/components/shared";
import * as Types from "../../../utils/constants/types";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { screeningStaticLabels } from "../../screening/screeningText";
import { applySectionStaticMultilanguageLabels } from "../../applySnap/applySnapText";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";
import * as languageConstants from "../../../utils/constants/constants";
import DOMPurify from "dompurify";

import {updateHouseholdLabelChanges,addRCIncomeSections,appendRCSections,appendRCUtilityLiheapData, appendResourcesDetails} from '../../../pages/reportChange/reportChangeActions'
import { iDontPayShelter } from "../reportChangeMapData";
import {
  recertificationPageLabels
} from "../../recertification/recertificationText";
const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ReportChangeSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    enableUniqueIds(this);
    this.houseHoldFirstName = [];
    this.houseHoldLastName = [];
    this.houseHoldDob = [];
    this.state = {
      formId: this.nextUniqueId(),
      ApplyAnswer: {},
    };
  }

  componentWillMount() {
    // Set up conditional questions state
    this.setState({
      conditionalState: this._initConditionalState(this.props.section),
    });
  }

  returnContinueDisable = () => {
    if(this.props.activeSectionId === "Dependentcarecosts") {
      let onChangeAnswerList = this.props.answerObject;
      let onChangeDependentCare = onChangeAnswerList.Dependentcarecosts;
      let changeDependent = onChangeDependentCare && onChangeDependentCare.caregivers ? onChangeDependentCare.caregivers[0] : "";
      if((changeDependent === "None" || this.props.consumer.reportChangeSections[0].questions[0].Answer.length === 0) && this.props.consumer.reportChangeSections[0].questions[0].options.length > 1){
        return TextProps.VALUE_FALSE
      }
      if(changeDependent !== "None" && this.props.consumer.reportChangeSections[0].questions[0].options.length === 1){
        return TextProps.VALUE_TRUE
      }
    }
  }

  render() {
    const section = this.props.section;
    let renderButton = null;
      renderButton = (
        <button
          type="submit"
          disabled={this.returnContinueDisable()}
          className="dta-button dta-button--primary dta-button--large"
          form={section.id}
          onClick={this.props.onPrimaryActionClick}
        >
          {this.props.primaryActionLabel}
        </button>
      );
    return (
      // Main markup for each apply section page
      <div className="apply__content">
        <div className="apply__header pad-all--double">
          <h1 style={{ wordBreak: "break-word", marginTop: "7px" }}>
            {section.header}
          </h1>
          {/* Subheader is optional property in JSON */}
          {section.subHeader ? (
            <p
              className="apply__sub-header"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.subHeader) }}
            />
          ) : null}
        </div>
        <div className="apply__body">
          {section.questions ? this.renderQuestions() : this.props.children}
        </div>
        {section.id !== "submitted" ? (
          <div className="apply__footer pad-all--double">
            {/* Generally, clicking this secondary action button would route user to previous section,
              or back to homepage / where they came from if applicable */}
            <button
              type="button"
              className="dta-button dta-button--outline-primary dta-button--large"
              onClick={this.props.onSecondaryActionClick}
            >
              {screeningStaticLabels(selectedLanguage).goBack}
            </button>
    
            {/* Generally, clicking this primary action button would perform any necessary
              validation and route user to next section */}
            {renderButton}
          </div>
        ) : (
          TextProps.VALUE_FALSE
        )}
      </div>
    );
  }

  redirectToApply = () => {
    window.location.assign(routeProps.APPLY_PATH);
  };

  // Markup for the apply page body when on a section with questions (the default case, for most apply pages)
  renderQuestions = () => {
    const { id, questions } = this.props.section,
      requiredHandlers = {
        doRetrieve: this._doRetrieve.bind(this, id),
        onChange: this._handleChangeForQuestion.bind(this, id),
        doGetUniqueId: this._getUniqueId,
      },
      optionalHandlers = {
        doBuildContainerProps: this._doBuildContainerProps,
        doBuildControlProps: this._doBuildControlProps,
      };
    return (
      // The id on each form is required to properly namespace questions/labels
      <form className="pad-all--double" id={id}>
        {questions &&
          questions.map((questionData) => {
            return resolveQuestion(
              questionData,
              requiredHandlers,
              optionalHandlers
            );
          })}
      </form>
    );
  };

  // apply function to determine if bottom of footer should be shown
  canSubmit = () => {
    const sectionId = this.props.section.id;
    if (
      sectionId === "screening" ||
      sectionId === "screening-results" ||
      sectionId === "before-you-start" ||
      sectionId === "about-me" ||
      sectionId === "sign-and-submit"
    )
      return TextProps.VALUE_FALSE;
    return TextProps.VALUE_TRUE;
  };

  // This function is related to simple, apply specific conditional display of questions, based on the values of earlier questions
  _initConditionalState(section) {
    const dataObj = {};
    this._initConditionalBySections(dataObj, section);
    return dataObj;
  }

  // Recursive helper function for _initConditionalState that goes through all sections
  // and subsections
  _initConditionalBySections(dataObj, sectionObj) {
    if (sectionObj && sectionObj.questions) {
      const sectionId = sectionObj.id;
      this._initConditionalByQuestions(
        dataObj,
        sectionId,
        sectionObj.questions
      );
    }
    if (sectionObj && sectionObj.subsections) {
      sectionObj.subsections.forEach((subObj) =>
        this._initConditionalBySections(dataObj, subObj)
      );
    }
  }

  // Recursive helper function for _initConditionalState that goes through all questions
  // and through the conditional block of the question, if it has one
  _initConditionalByQuestions(dataObj, sectionId, questions, targetValue = "") {
    questions.forEach((question) => {
      // look to see if each question has a conditional block
      if (question[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.value ? question.value : defaultValue;
        // look through each condition in the conditional block to see
        // if any of the questions have their own conditional block
        question[OptionsProps.CONDITIONALS].forEach((conditionalObj) => {
          this._initConditionalByQuestions(
            dataObj,
            sectionId,
            conditionalObj[OptionsProps.CONDITIONAL_QUESTIONS],
            conditionalObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
          );
        });
      }
    });
  }

  // apply-specific generation of unique keys to store the values of the questions that
  // trigger the conditional display of other questions
  _conditionalKey(sectionId, questionName, targetValue = "") {
    return `${sectionId}/${questionName}/${targetValue}`;
  }

  // Resolving questions
  // -------------------

  _doRetrieve = (sectionId, questionData, conditionObj) => {
    // Updating the required label on contact info's permanent address field
    const res = this.props.section; /*To update the required label in OnChange*/
   

    if (conditionObj && questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    } else if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      return this.state.conditionalState[key];
    } else if (questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    }
  };

  _appendLabelToQuestions = (sectionId, val, fieldName) => {
    let { subsections } = this.props.consumer.reportChangeSections[0];

    for (var k = 0; k < subsections.length; k++) {
      if (subsections[k].id === sectionId) {
        if (fieldName === "firstName") {
          if (val !== "" && val !== null) {
            this.houseHoldFirstName[k] = val;
          } else {
            this.houseHoldFirstName[k] = "Member " + (k + 1);
            this.houseHoldLastName[k] = "";
            this.houseHoldDob[k] = "";
          }
        } else if (this.houseHoldFirstName[k] === undefined) {
          this.houseHoldFirstName[k] = "Member " + (k + 1);
          this.houseHoldLastName[k] = "";
          this.houseHoldDob[k] = "";
        }
        if (fieldName === "lastName") {
          this.houseHoldLastName[k] = val;
        } else if (this.houseHoldLastName[k] === undefined) {
          this.houseHoldLastName[k] = "";
        }
        if (fieldName === "dateOfBirth") {
          let year = val !== undefined ? val.getFullYear() : "";
          if (val !== "" && val !== null && year !== 1899 && year !== 1900) {
            this.houseHoldDob[k] = helperFunction._convertDateToState(val);
          } else {
            this.houseHoldDob[k] = "";
          }
        } else if (this.houseHoldDob[k] === undefined) {
          this.houseHoldDob[k] = "";
        }
        if (fieldName === "firstName") {
          if (val !== "" && val !== null) {
            subsections[k].header = applySectionStaticMultilanguageLabels(
              subsections[k].title,
              selectedLanguage
            ).aboutTitle; //"About "+subsections[k].title
            subsections[k].questions[1].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelMiddle; //'What is ' + val + "'s middle name?"
            subsections[k].questions[2].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelLastName; //'What is ' + val + "'s last name?"
            subsections[k].questions[4].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelRelation; //'What is ' + val + "'s relationship to you?"
            subsections[k].questions[5].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).dateOfBirthQLabel; //'What is ' + val + "'s date of birth?"
            subsections[k].questions[6].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).genderQLabel; //'What is ' + val + "'s gender?"
              subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ethnicityLabel; //'What is ' + val + "'s ethnicity?"
              subsections[k].questions[8].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).raceLabel; //'What is ' + val + "'s race?" 
            subsections[k].questions[10].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ssnQLabel; //'What is ' + val + "'s Social Security Number?"
            //this updates realtime without redux store
            this.props.section.questions[1].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelMiddle;
            this.props.section.questions[2].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelLastName;
            this.props.section.questions[4].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelRelation;
            this.props.section.questions[5].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).dateOfBirthQLabel;
            this.props.section.questions[6].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).genderQLabel;
              this.props.section.questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ethnicityLabel;
              this.props.section.questions[8].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).raceLabel;  
            this.props.section.questions[10].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ssnQLabel;
          } else {
            subsections[k].questions[1].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personMiddleName; //"What is this person's middle name?"
            subsections[k].questions[2].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personLastName; //"What is this person's last name?"
            subsections[k].questions[4].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRelation; //"What is this person's relationship to you?"
            subsections[k].questions[5].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personDobLabel; //"What is this person's date of birth?"
            subsections[k].questions[6].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personGenderLabel; //"What is this person's gender?"
              subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ethnicityLabel; //'What is ' + val + "'s ethnicity?"
              subsections[k].questions[8].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).raceLabel; //'What is ' + val + "'s race?" 
            subsections[k].questions[10].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personSSNLabel; //"What is this person's Social Security Number?"
            //this updates realtime without redux store
            this.props.section.questions[1].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personMiddleName;
            this.props.section.questions[2].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personLastName;
            this.props.section.questions[4].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRelation;
            this.props.section.questions[5].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personDobLabel;
            this.props.section.questions[6].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personGenderLabel;
            this.props.section.questions[10].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personSSNLabel;
          }
        }
      }
    }
    this.props.updateHouseholdLabelChanges(subsections);
  };

  _incomeAddButton = (sectionId, questionData, val, optionalMetaData) => {
    if (optionalMetaData.action) {
      questionData[OptionsProps.TARGET_VALUE] = val;
      if (questionData.Answer === undefined) {
        questionData.Answer = [];
      }
      if(sectionId !== ""  && sectionId !== undefined && sectionId !== null){
        let activeId = sectionId.split("-");
        let activeIdSub = activeId.join("-");
        if(optionalMetaData.action === "REMOVE"){
            questionData.Answer = _.cloneDeep(optionalMetaData.combinedOj);
        }else{
          questionData.Answer = val;
        }
      }
    }

    if(optionalMetaData.action === "REMOVE" && sectionId.includes('medical-expenses-filter-')){
      let incomeSectionCopy = this.props.consumer.reportChangeSections[0]
      for (
        var subSectionIndex = 0;
        subSectionIndex < incomeSectionCopy.subsections[0].subsections.length - 1;
        subSectionIndex++
      ) {
        if (
          incomeSectionCopy.subsections[subSectionIndex].subsections !==
          undefined
        ) {
          if (
            incomeSectionCopy.subsections[0].subsections[subSectionIndex].id ===
            sectionId
          ) {
            for (
              var questionDataIndex = 0;
              questionDataIndex <
              incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                .questions.length;
              questionDataIndex++
            ) {
              if (
                incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                  .questions[questionDataIndex].name === questionData.name
              ) {
                incomeSectionCopy.subsections[
                  subSectionIndex
                ].subsections[0].questions[questionDataIndex] = questionData;
              }
              this.props.appendRCSections(incomeSectionCopy)
            }
          }
        }
      }
    }

    let activeIdSplit;
    let activeIdSubString;
    let index=0;
    if (optionalMetaData.action !== undefined) {
      if (sectionId !== "" && sectionId !== undefined && sectionId !== null) {
         activeIdSplit = sectionId.split("-");
        activeIdSplit.pop();
         activeIdSubString = activeIdSplit.join("-");
      }
      switch (activeIdSubString) {
        case "combined-incomesubsection":
          index = 0;
          break;
        default:
          index = 0;
          break;
      }

      let incomeSectionCopy = _.cloneDeep(
        this.props.consumer.reportChangeSections[index]
      );
      
      for (
        var subSectionIndex = 0;
        subSectionIndex < incomeSectionCopy.subsections.length - 1;
        subSectionIndex++
      ) {
        if (
          incomeSectionCopy.subsections[subSectionIndex].subsections !==
          undefined
        ) {
          if (
            incomeSectionCopy.subsections[subSectionIndex].subsections[0].id ===
            sectionId
          ) {
            for (
              var questionDataIndex = 0;
              questionDataIndex <
              incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                .questions.length;
              questionDataIndex++
            ) {
              if (
                incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                  .questions[questionDataIndex].name === questionData.name
              ) {
                incomeSectionCopy.subsections[
                  subSectionIndex
                ].subsections[0].questions[questionDataIndex] =
                  _.cloneDeep(questionData);
              }
            }
          }
        }
      }
      if(activeIdSubString === "combined-incomesubsection"){
        this.props.addRCIncomeSections(incomeSectionCopy);
      }else if(activeIdSubString === "shelter-expense"){
        this.props.appendRCSections(incomeSectionCopy)
      } else if(activeIdSubString === "combined-resources-subsection"){
        this.props.appendResourcesDetails(incomeSectionCopy);
      }
    }
  };

  _updateAnswerForView = (questionData, val) => {
    let currentSectionQuestion = this.props.section.questions;
    let _this = this;
    if (currentSectionQuestion !== undefined) {
      currentSectionQuestion.map(function (question, index) {
        if (question.name === questionData.name) {
          _this.props.section.questions[index].Answer = val;
          _this.props.section.questions[index].targetValue = val;
        }
        return TextProps.VALUE_TRUE;
      });
    }
  };

  _handleWarningModal = (sectionId, questionData, val) => {
    if (sectionId === "my-household") {
      if (
        questionData.Answer > val &&
        val !== "" &&
        questionData.Answer !== "" &&
        questionData.Answer !== undefined
      ) {
        this.props.onWarningProgress();
      }
    }
  };

  _handleChangeForQuestion = (
    sectionId,
    questionData,
    conditionObj,
    val,
    optionalMetaData = null
  ) => {

    //break if invalid date
    if (questionData.type === "date" && val !== undefined) {
      if (val.getFullYear() === 1899 || val.getFullYear() === 1900) {
        return;
      }
    }

    if (sectionId !== "" && sectionId !== undefined && sectionId !== null) {
      let sectionCopy = _.cloneDeep(
        this.props.consumer.reportChangeSections[0]
      );
      switch (sectionId) {
        case "shelter-expenses-section":
          let noShelterOptions = iDontPayShelter;
          sectionCopy.subsections[0].questions[0].Answer = val;
          sectionCopy.subsections[0].questions[0].targetValue = val;
          if(noShelterOptions.includes(val[0])){
             sectionCopy.subsections[0].subsections.splice(0, 1);
          }else{
            sectionCopy.subsections[0].subsections = [sectionCopy.subsections[0].subsections[0],this.props.consumer.reportChangeSections[0].subsections[0].subsections[this.props.consumer.reportChangeSections[0].subsections[0].subsections.length-1]];
          }
          this.props.appendRCSections(sectionCopy);
          break;
        case "utility-expenses-section":
          if(questionData.name !== "is-received-liheap"){
          sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].Answer = val;
          sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].targetValue = val;
          }
          else if(questionData.name === "is-received-liheap"){
            let liHeapFlag = null;
            if(val === recertificationPageLabels(
              selectedLanguage,
              null,
              languageConstants.aboutMe
            ).optionYesNo[0]){
              liHeapFlag="Yes";
              sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].Answer = [...sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].Answer,'liheapFlag'];
              sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[1].Answer = "Yes"
              sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[1].targetValue = "Yes"
              this.props.appendRCUtilityLiheapData(liHeapFlag);  
              this.props.appendRCSections(sectionCopy);
            }else{
              liHeapFlag="No";
              let utility = sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].Answer
              if (utility.includes('liheapFlag')) {
                let index = utility.indexOf('liheapFlag');
                utility.splice(index, 1);
                sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[0].Answer = utility;
            }
            sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[1].Answer = "No"
            sectionCopy.subsections[0].subsections[sectionCopy.subsections[0].subsections.length-1].questions[1].targetValue = "No"
            this.props.appendRCUtilityLiheapData(liHeapFlag);  
            this.props.appendRCSections(sectionCopy);
            }
          }
          this.props.appendRCSections(sectionCopy);
          break;
        // default:
        //   break;
      }
    }


    if (sectionId !== "" && sectionId !== undefined && sectionId !== null) {
      let activeIdSplit = sectionId.split("-");
      let activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
      let activeIdSubString = activeIdSplit.join("-");
      let incomeCopy = this.props.consumer.reportChangeSections[0];

      switch (activeIdSubString) {
        case "combined-income":
          incomeCopy.subsections[activeSplitMemberIndex].questions[0].Answer = val
          this.props.addRCIncomeSections(incomeCopy);
          break;
        case "combined-resources":
          incomeCopy.subsections[activeSplitMemberIndex].questions[0].Answer = val
          this.props.appendResourcesDetails(incomeCopy);
          break;
        default:
          break;
      }
    }




    //Income Add Button - only on optionalMetaData
    if (optionalMetaData.action) {
      this._incomeAddButton(sectionId, questionData, val, optionalMetaData);
    }

    //Real Time Appeneding Data to Section for Realtime view (select)
    // if (questionData.type === "select") {
    //   this._updateAnswerForView(questionData, val);
    // }
    // this._handleWarningModal(sectionId, questionData, val);

    //HouseHold Label Updates

    if ("household-member" === sectionId.substring(0, 16)) {
      this._appendLabelToQuestions(sectionId, val, questionData[Props.NAME]);
    }

    //Appending Values to Section
    if (this.AnswerObject === undefined) {
      this.AnswerObject = this.props.answerObject;
    }
    if (this.AnswerObject[sectionId] === undefined) {
      this.AnswerObject[sectionId] = {};
    }
    this.AnswerObject[sectionId][questionData.name] = val;
    questionData.error = TextProps.VALUE_FALSE;

  

    //Update question value as you normally would
    if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      this.setState({
        conditionalState: {
          ...this.state.conditionalState,
          [key]: val,
        },
      });
    }

    //Dal Reference
    this.props.bindAnswerToState(this.AnswerObject);
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };

  // This function takes the name of the question and returns classes to modify the container
  // element. It is useful for displaying adjacent questions on the same row. You can use
  // pure-framework grid classes and our utility padding classes to customize.
  _doBuildContainerProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { className: "pure-u-4-5 pad-right-half" };
      case Names.SUFFIX:
        return { className: "pure-u-1-5" };
      default:
        return {};
    }
  };

  _doBuildControlProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { questionClass: "truncate-text" };
      case Names.SUFFIX:
        return { questionClass: "truncate-text" };
      default:
        return {};
    }
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateHouseholdLabelChanges:updateHouseholdLabelChanges,
      addRCIncomeSections:addRCIncomeSections,
      appendRCSections:appendRCSections,
      appendRCUtilityLiheapData:appendRCUtilityLiheapData,
      appendResourcesDetails: appendResourcesDetails
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportChangeSection);