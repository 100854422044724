import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import * as Names from "../../../utils/constants/names";
import * as OptionsProps from "../../../utils/constants/options";
import * as Props from "../../../utils/components/shared";
import * as Types from "../../../utils/constants/types";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import {
  appendHouseHoldMembers,
  updateHouseholdTextChanges,
  applySanpQuestionsSections,
  appendIncomeDetails,
  updateApplySection,
} from "../../../redux/applySnap/applySnapActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { MONTHSHORT } from "../../../utils/constants/controls";
import { isNone } from "../../../utils/components/utilities/controls";
import { screeningStaticLabels } from "../../screening/screeningText";
import {
  applySectionStaticMultilanguageLabels,
  houseHoldSectionMultilanguageLabels,
  aboutMeMultilanguageLabels,
} from "../applySnapText";
import * as TextProps from "../../../utils/constants/text";
import { filterData } from "../applySnapHelper";
import DOMPurify from "dompurify";

let selectedLanguage = localStorage.getItem("selectedLanguage");
// let applicationType = sessionStorage.getItem("applicationType");

if (selectedLanguage === null || selectedLanguage === "select" ) {
  selectedLanguage = "en-US";
}

class ApplySection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    enableUniqueIds(this);
    this.houseHoldFirstName = [];
    this.houseHoldLastName = [];
    this.houseHoldDob = [];
    this.state = {
      formId: this.nextUniqueId(),
      ApplyAnswer: {},
      windowHgt: "",
    };
  }

  componentWillMount() {
    // Set up conditional questions state
    this.setState({
      conditionalState: this._initConditionalState(this.props.section),
    });
  }

  // Add Max height for iPad(Safari)
  componentDidUpdate() {
    var elmnt = document.getElementsByClassName("apply__content");
    var hgt = elmnt[0].offsetHeight;
    if (this.state.windowHgt != hgt) {
      {
        this.addMaxHeight(hgt);
      }
    }
  }

  addMaxHeight = (hgt) => {
    var str = window.navigator.appVersion;
    var res = str.substring(18, 22);
    var version = res.split("_").join(".");
    if (version < 12.2 && str.includes("iPad")) {
      this.setState({ windowHgt: hgt });
    }
  };

  render() {
    const section = this.props.section;

    return (
      // Main markup for each apply section page
      <div className="apply__content">
        <div className="apply__header pad-all--double">
          {this.props.signAndSubmit &&
          section.id !== "submitted" &&
          section.id !== "sign-and-submit" &&
          section.id !== "summary" ? (
            <div className="apply__sign__submit">
              <button
                type="button"
                className="dta-button dta-button--outline-primary"
                onClick={this.props.onSignSubmitActionClick}
              >
                {screeningStaticLabels(selectedLanguage).signAndSubmit}
              </button>
            </div>
          ) : null}
          <h1>{section.header}</h1>
          {/* Subheader is optional property in JSON */}
          {section.subHeader ? (
            <p
              className="apply__sub-header"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(section.subHeader),
              }}
            ></p>
          ) : null}
        </div>
        <div className="apply__body">
          {section.questions ? this.renderQuestions() : this.props.children}
        </div>

        {section.id !== "submitted" ? (
          <div className={`pad-all--double ${!this.props.appInanonymousMode ? 'apply__footer': 'apply__footer_anon'}`}>
            {/* Generally, clicking this secondary action button would route user to previous section,
              or back to homepage / where they came from if applicable */}
            <button
              type="button"
              className="dta-button dta-button--outline-primary dta-button--large"
              onClick={this.props.onSecondaryActionClick}
            >
              {screeningStaticLabels(selectedLanguage).goBack}
            </button>
            {section.id !== "before-you-start" && !this.props.isPreScreening
              ? [this.props.onResetClick &&
                  <button
                    type="button"
                    className="dta-button dta-button--outline-primary dta-button--large"
                    form={section.id}
                    onClick={this.props.onResetClick}
                    disabled={section.id === "summary"}
                  >
                    {screeningStaticLabels(selectedLanguage).reset}
                  </button>,
                  this.props.onSaveExitClick  && <button
                    type="button"
                    className="dta-button dta-button--outline-primary dta-button--large"
                    form={section.id}
                    onClick={this.props.onSaveExitClick}
                  >
                    {screeningStaticLabels(selectedLanguage).saveAndExit}
                  </button>,
                ]
              : ""}
            {/* Generally, clicking this primary action button would perform any necessary
              validation and route user to next section */}
            {section.id === "screening-results" ? (
              <button
                type="submit"
                className="dta-button dta-button--primary dta-button--large"
                form={section.id}
                onClick={this.props.onPrimaryActionClick}
              >
                {/* Using a placeholder function here to get a dynamic value for button text.
                The value could also be stored in data or a state machine could be used,
                not unlike the simplified approach here */}
                {this.props.primaryActionLabel}
              </button>
            ) : (
              <button
                type="submit"
                className="dta-button dta-button--primary dta-button--large"
                form={section.id}
                onClick={this.props.onPrimaryActionClick}
              >
                {this.props.primaryActionLabel}
              </button>
            )}
          </div>
        ) : (
          TextProps.VALUE_FALSE
        )}
        {this.state.windowHgt > 950 ? (
          <div style={{ height: "95px" }}></div>
        ) : null}
      </div>
    );
  }

  redirectToApply = () => {
    window.location.assign("/apply");
  };

  // Markup for the apply page body when on a section with questions (the default case, for most apply pages)
  renderQuestions = () => {
    const { id, questions } = this.props.section,
      requiredHandlers = {
        doRetrieve: this._doRetrieve.bind(this, id),
        onChange: this._handleChangeForQuestion.bind(this, id),
        doGetUniqueId: this._getUniqueId,
      },
      optionalHandlers = {
        doBuildContainerProps: this._doBuildContainerProps,
        doBuildControlProps: this._doBuildControlProps,
      };
    return (
      // The id on each form is required to properly namespace questions/labels
      <form className="pad-all--double" id={id}>
        {questions &&
          questions.map((questionData) => {
            return resolveQuestion(
              questionData,
              requiredHandlers,
              optionalHandlers
            );
          })}
      </form>
    );
  };

  // This function is related to simple, apply specific conditional display of questions, based on the values of earlier questions
  _initConditionalState(section) {
    const dataObj = {};
    this._initConditionalBySections(dataObj, section);
    return dataObj;
  }

  // Recursive helper function for _initConditionalState that goes through all sections
  // and subsections
  _initConditionalBySections(dataObj, sectionObj) {
    if (sectionObj && sectionObj.questions) {
      const sectionId = sectionObj.id;
      this._initConditionalByQuestions(
        dataObj,
        sectionId,
        sectionObj.questions
      );
    }
    if (sectionObj && sectionObj.subsections) {
      sectionObj.subsections.forEach((subObj) =>
        this._initConditionalBySections(dataObj, subObj)
      );
    }
  }

  // Recursive helper function for _initConditionalState that goes through all questions
  // and through the conditional block of the question, if it has one
  _initConditionalByQuestions(dataObj, sectionId, questions, targetValue = "") {
    questions.forEach((question) => {
      // look to see if each question has a conditional block
      if (question[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.targetValue
          ? question.targetValue
          : defaultValue;
        question[OptionsProps.CONDITIONALS].forEach((conditionalObj) => {
          this._initConditionalByQuestions(
            dataObj,
            sectionId,
            conditionalObj[OptionsProps.CONDITIONAL_QUESTIONS],
            conditionalObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
          );
        });
      } else if (question.Answer && question.Answer.length) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.targetValue
          ? question.targetValue
          : defaultValue;
      }
    });
  }

  // apply-specific generation of unique keys to store the values of the questions that
  // trigger the conditional display of other questions
  _conditionalKey(sectionId, questionName, targetValue = "") {
    return `${sectionId}/${questionName}/${targetValue}`;
  }

  _doRetrieve = (sectionId, questionData, conditionObj) => {
    if (conditionObj && questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    } else if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      return this.state.conditionalState[key];
    } else if (questionData[OptionsProps.TARGET_VALUE]) {
      return questionData[OptionsProps.TARGET_VALUE];
    } else if (questionData[OptionsProps.OPTIONS]) {
      questionData.value = "";
      return questionData[OptionsProps.VALUE];
    }
  };

  _bindAnswerToQuestion = (sectionId, val, fieldName) => {
    let { subsections } = this.props.apply.sections[2];
    for (var k = 0; k < subsections.length - 1; k++) {
      if (subsections[k].id === sectionId) {
        switch (fieldName) {
          case "firstName":
            subsections[k].questions[0].tempAnswer = val;
            this.props.updateHouseholdTextChanges(subsections);
            break;
          case "lastName":
            subsections[k].questions[2].tempAnswer = val;
            this.props.updateHouseholdTextChanges(subsections);
            break;
          case "dateOfBirth":
            if (val !== "" && val !== undefined) {
              subsections[k].questions[5].tempAnswer =
                this._convertDateToState(val);
            } else if (
              subsections[k].questions[5].Answer !== "" &&
              subsections[k].questions[5].Answer !== undefined
            ) {
              subsections[k].questions[5].tempAnswer = this._convertDateToState(
                subsections[k].questions[5].Answer
              );
            }

            this.props.updateHouseholdTextChanges(subsections);
            break;
          default:
            break;
        }
      }
    }
  };
  _appendLabelToQuestions = (sectionId, val, fieldName) => {
    let applicationType = sessionStorage.getItem("applicationType");
    let { subsections } = this.props.apply.sections[2];
    for (var k = 0; k < subsections.length - 1; k++) {
      if (subsections[k].id === sectionId) {
        //FirstName Binding
        if (
          subsections[k].questions[0].tempAnswer !== undefined &&
          subsections[k].questions[0].tempAnswer !== ""
        ) {
          subsections[k].title = subsections[k].questions[0].tempAnswer;
        } else {
          if (
            subsections[k].questions[2].tempAnswer !== "" &&
            subsections[k].questions[2].tempAnswer !== undefined
          ) {
            subsections[k].title = "";
          } else {
            subsections[k].title =
              houseHoldSectionMultilanguageLabels(selectedLanguage)
                .houseMemberTitle +
              (k + 1);
            subsections[k].header = applySectionStaticMultilanguageLabels(
              k + 1,
              selectedLanguage
            ).aboutMemberTitle;
            this.props.section.header = applySectionStaticMultilanguageLabels(
              k + 1,
              selectedLanguage
            ).aboutMemberTitle;
          }
        }
        //Last Name Binding
        if (
          subsections[k].questions[0].tempAnswer !== undefined &&
          subsections[k].questions[0].tempAnswer !== "" &&
          subsections[k].questions[2].tempAnswer !== undefined &&
          subsections[k].questions[2].tempAnswer !== ""
        ) {
          subsections[k].title =
            subsections[k].title + " " + subsections[k].questions[2].tempAnswer;
        } else if (
          subsections[k].questions[2].tempAnswer !== "" &&
          subsections[k].questions[2].tempAnswer !== undefined
        ) {
          subsections[k].title = subsections[k].questions[2].tempAnswer;
        }

        //DOB Binding
        if (
          subsections[k].questions[5].tempAnswer !== "" &&
          subsections[k].questions[5].tempAnswer !== undefined
        ) {
          subsections[k].title =
            subsections[k].title + subsections[k].questions[5].tempAnswer;
        }
        subsections[k].header = applySectionStaticMultilanguageLabels(
          subsections[k].title,
          selectedLanguage
        ).aboutTitle;
        this.props.section.header = applySectionStaticMultilanguageLabels(
          subsections[k].title,
          selectedLanguage
        ).aboutTitle;
        if (fieldName === "firstName") {
          if (val !== "" && val !== null) {
            subsections[k].header = applySectionStaticMultilanguageLabels(
              subsections[k].title,
              selectedLanguage
            ).aboutTitle;
            subsections[k].questions[1].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelMiddle;
            subsections[k].questions[2].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelLastName;
            subsections[k].questions[4].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelRelation;
            subsections[k].questions[5].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).dateOfBirthQLabel;
            subsections[k].questions[6].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).genderQLabel;
            if(applicationType === "SNAP")
            subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).UScitizenLabelForSnap;
            else if(applicationType === "TANF")
              subsections[k].questions[7].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).UScitizenLabelForTanf;
            else if(applicationType === "COMBO")
            subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).UScitizenLabelForCombo;

            if (applicationType !== "SNAP") {
              subsections[
                k
              ].questions[7].conditionals[0].conditionalQuestions[0].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).immigrationStatusLabel;
            }

            subsections[k].questions[8].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ssnQLabel;
            //this updates realtime without redux store
            this.props.section.header = applySectionStaticMultilanguageLabels(
              subsections[k].title,
              selectedLanguage
            ).aboutTitle;
            this.props.section.questions[1].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelMiddle;
            this.props.section.questions[2].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelLastName;
            this.props.section.questions[4].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).labelRelation;
            this.props.section.questions[5].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).dateOfBirthQLabel;
            this.props.section.questions[6].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).genderQLabel;

            // this.props.section.questions[7].label =
            //   applySectionStaticMultilanguageLabels(
            //     val,
            //     selectedLanguage
            //   ).UScitizenLabel;
            console.log("djkjsdL"+applicationType)
            if(applicationType === "SNAP")
            subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).UScitizenLabelForSnap;
            if(applicationType === "TANF")
              subsections[k].questions[7].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).UScitizenLabelForTanf;
                if(applicationType === "COMBO")
            subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).UScitizenLabelForCombo;
            if (applicationType !== "SNAP") {
              this.props.section.questions[7].conditionals[0].conditionalQuestions[0].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).immigrationStatusLabel;
            }
            this.props.section.questions[8].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ssnQLabel;
            this.props.section.questions[9].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).ethnicityLabel;
            this.props.section.questions[10].label =
              applySectionStaticMultilanguageLabels(
                val,
                selectedLanguage
              ).raceLabel;
          } else {
            subsections[k].questions[1].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personMiddleName; //"What is this person's middle name?";
            subsections[k].questions[2].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personLastName; //"What is this person's last name?";
            subsections[k].questions[4].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRelation; //"What is this person's relationship to you?";
            subsections[k].questions[5].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personDobLabel; //"What is this person's date of birth?";
            subsections[k].questions[6].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personGenderLabel; //"What is this person's gender?";
            subsections[k].questions[7].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personUScitizenLabel;
            if (applicationType !== "SNAP") {
              subsections[
                k
              ].questions[7].conditionals[0].conditionalQuestions[0].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).immigrationStatusLabel;
            }
            subsections[k].questions[8].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personSSNLabel; //"What is this person's Social Security Number?";

            subsections[k].questions[9].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personEthnicityLabel;

            subsections[k].questions[10].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRaceLabel;
            //this updates realtime without redux store

            this.props.section.questions[1].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personMiddleName;
            this.props.section.questions[2].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personLastName;
            this.props.section.questions[4].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRelation;
            this.props.section.questions[5].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personDobLabel;
            this.props.section.questions[6].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personGenderLabel;
            this.props.section.questions[7].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personUScitizenLabel;

            if (applicationType !== "SNAP") {
              this.props.section.questions[7].conditionals[0].conditionalQuestions[0].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personImmigrationStatusLabel;
            }

            this.props.section.questions[8].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personSSNLabel;

            this.props.section.questions[9].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personEthnicityLabel;
            this.props.section.questions[10].label =
              applySectionStaticMultilanguageLabels(
                "",
                selectedLanguage
              ).personRaceLabel;
          }
        }
      }
    }
    // Finally, update the state
    this.props.updateHouseholdTextChanges(subsections);
  };

  _incomeAddButton = (sectionId, questionData, val, optionalMetaData) => {
    //AddButton Value Update
    if (optionalMetaData.action) {
      questionData[OptionsProps.TARGET_VALUE] = val;
      if (questionData.Answer === undefined) {
        questionData.Answer = [];
      }
      questionData.Answer = val;
    }
    if (optionalMetaData.action !== undefined) {
      let incomeSectionCopy = _.cloneDeep(filterData(this.props.apply.sections, "income"));
      //finding which subsection to append
      for (
        var subSectionIndex = 0;
        subSectionIndex < incomeSectionCopy.subsections.length - 1;
        subSectionIndex++
      ) {
        if (
          incomeSectionCopy.subsections[subSectionIndex].subsections !==
          undefined
        ) {
          if (
            incomeSectionCopy.subsections[subSectionIndex].subsections[0].id ===
            sectionId
          ) {
            for (
              var questionDataIndex = 0;
              questionDataIndex <
              incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                .questions.length;
              questionDataIndex++
            ) {
              if (
                incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                  .questions[questionDataIndex].name === questionData.name
              ) {
                incomeSectionCopy.subsections[
                  subSectionIndex
                ].subsections[0].questions[questionDataIndex] =
                  _.cloneDeep(questionData);
              }
            }
          }
        }
      }

      this.props.appendIncomeDetails(incomeSectionCopy);
    }
  };
  _updateAnswerForView = (questionData, val) => {
    let currentSectionQuestion = this.props.section.questions;
    let _this = this;
    if (currentSectionQuestion !== undefined) {
      currentSectionQuestion.map(function (question, index) {
        if (question.name === questionData.name) {
          _this.props.section.questions[index].Answer = val;
          _this.props.section.questions[index].targetValue = val;
        }
        return TextProps.VALUE_TRUE;
      });
    }
  };
  _handleWarningModal = (sectionId, questionData, val) => {
    switch (sectionId) {
      case "my-household":
        if (
          Number(questionData.Answer) > Number(val) &&
          val !== "" &&
          questionData.Answer !== "" &&
          questionData.Answer !== undefined
        ) {
          this.props.onWarningProgress(val);
        }
        break;
      default:
        break;
    }
  };

  _handleChangeForQuestion = (
    sectionId,
    questionData,
    conditionObj,
    val,
    optionalMetaData = null
  ) => {
    //break if invalid date
    if (this.AnswerObject === undefined) {
      this.AnswerObject = this.props.answerObject;
    }

    if (this.AnswerObject[sectionId] === undefined) {
      this.AnswerObject[sectionId] = {};
    }

    if (questionData.type === "date" && val !== "" && val !== undefined) {
      if (val.getFullYear() === 1899 || val.getFullYear() === 1900) {
        if (this.AnswerObject[sectionId][questionData.name] instanceof Date) {
          this.AnswerObject[sectionId][questionData.name] = "";
          this.props.bindAnswerToState(this.AnswerObject);
        }
        return;
      }
    }

    //Income Add Button - only on optionalMetaData
    if (optionalMetaData.action) {
      this._incomeAddButton(sectionId, questionData, val, optionalMetaData);
    }

    //Real Time Appeneding Data to Section for Realtime view (select)
    if (questionData.type === "select") {
      this._updateAnswerForView(questionData, val);
    }

    this._handleWarningModal(sectionId, questionData, val);

    //HouseHold Label Updates
    if ("household-member" === sectionId.substring(0, 16)) {
      this._bindAnswerToQuestion(sectionId, val, questionData[Props.NAME]);
      this._appendLabelToQuestions(sectionId, val, questionData[Props.NAME]);
    }

    //Appending Values to Section
    if (sectionId === "kids-and-adults") {
      this.AnswerObject[sectionId][questionData.name] = val;
      questionData.error = TextProps.VALUE_FALSE;
      console.log(this.state.conditionalState);
      for (const [key, value] of Object.entries(this.state.conditionalState)) {
        console.log(`${key}: ${value}`);
        let keyValues = key.split("/");
        if (
          keyValues[0] === "kids-and-adults" &&
          keyValues[1].includes("recipient-of-care")
        ) {
          let keyExists = Object.keys(this.AnswerObject[sectionId]).filter(
            (key) => key === keyValues[1]
          );
          if (!keyExists.length) {
            this.AnswerObject[sectionId][keyValues[1]] = value;
          }
        }
        if (
          keyValues[0] === "kids-and-adults" &&
          keyValues[1].includes("caregivers")
        ) {
          let keyExists = Object.keys(this.AnswerObject[sectionId]).filter(
            (key) => key === "caregivers"
          );
          if (!keyExists.length) {
            this.AnswerObject[sectionId][keyValues[1]] = value;
          }
        }
        // caregivers
      }
      // this.state.conditionalState.map(cond => {

      // })
    } else {
      if (
        sectionId === "my-household" &&
        (val < 0 || val === "0" || val === "")
      ) {
        val = "";
        // questionData.error = TextProps.VALUE_TRUE;
        questionData.isRequired = TextProps.VALUE_TRUE;
      } else {
        questionData.error = TextProps.VALUE_FALSE;
      }

      this.AnswerObject[sectionId][questionData.name] = val;
    }

    // Updating the required label on contact info's permanent address field
    if (sectionId === "contact-info") {
      const radioTypeSection = this.AnswerObject["contact-info"];
      const res =
        this.props.section; /*To update the required label in OnChange*/
      const pat =
        this.props.apply.sections[1]
          .subsections[0]; /*To update the required label in Continue Button*/

      if (
        radioTypeSection["is-homeless"] ===
          aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0] ||
        (radioTypeSection["is-homeless"] ===
          aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0] &&
        radioTypeSection["is-also-mailing"] === TextProps.VALUE_STR_FALSE)
      ) {
        res.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        res.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_TRUE;
        pat.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_TRUE;
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
        });
      } else if(
        radioTypeSection["is-homeless"] ===
          aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1] ){
          res.questions.filter((addr) => addr.name === "address")[0].isRequired =
            TextProps.VALUE_TRUE;
          pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_TRUE;
          res.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_FALSE;
          pat.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_FALSE;
          this.setState({
            conditionalState: {
              ...this.state.conditionalState,
            },
          });
      } else {
        // res.questions[3].isRequired = TextProps.VALUE_TRUE;
        // pat.questions[3].isRequired = TextProps.VALUE_TRUE;
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
        });
      }
    }

    //Update question value as you normally would
    // if (sectionId === 'kids-and-adults') {
    // } else {
    if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
      const key = this._conditionalKey(
        sectionId,
        questionData[Props.NAME],
        conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
      );
      this.setState({
        conditionalState: {
          ...this.state.conditionalState,
          [key]: val,
        },
      });
    }
    // }

    //Dal Reference
    this.props.bindAnswerToState(this.AnswerObject);
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };

  // This function takes the name of the question and returns classes to modify the container
  // element. It is useful for displaying adjacent questions on the same row. You can use
  // pure-framework grid classes and our utility padding classes to customize.
  _doBuildContainerProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { className: "pure-u-4-5 pad-right-half" };
      case Names.SUFFIX:
        return { className: "pure-u-1-5 suffix" };
      default:
        return {};
    }
  };

  _convertMonthNumberToString = (monthNumber) => {
    return isNone(MONTHSHORT[monthNumber])
      ? MONTHSHORT[0]
      : MONTHSHORT[monthNumber];
  };

  _convertDateToState = (date) => {
    if (isNone(date)) {
      return "";
    }
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return " (" + mm + "/" + dd + "/" + yyyy + ")";
  };
  _doBuildControlProps = (questionData, conditionObj) => {
    const name = questionData[Props.NAME];
    switch (name) {
      case Names.LAST_NAME:
        return { questionClass: "truncate-text" };
      case Names.SUFFIX:
        return { questionClass: "truncate-text" };
      default:
        return {};
    }
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      appendHouseHoldMembers: appendHouseHoldMembers,
      updateHouseholdTextChanges: updateHouseholdTextChanges,
      applySanpQuestionsSections: applySanpQuestionsSections,
      appendIncomeDetails: appendIncomeDetails,
      updateApplySection: updateApplySection,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplySection);
