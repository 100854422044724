import * as routeProps from "./routesConstants";
import * as types from "./types";
/*
  Classes specific to the Radio component used if you want to create a single
  OptionItem that looks like a radio option
 */
export const RADIO_CLASSES = {
  optionClass: "dta-radio",
  controlClass: "dta-radio__control",
  labelClass: "dta-radio__label",
  errorClass: "dta-radio__control--error",
};

/*
  Classes specific to the Checkbox component used if you want to create a single
  OptionItem that looks like a checkbox option
 */
export const CHECKBOX_CLASSES = {
  optionClass: "dta-checkbox",
  controlClass: "dta-checkbox__control",
  labelClass: "dta-checkbox__label",
  errorClass: "dta-checkbox__control--error",
};
export const CHECKBOX_CLASSES_DSNAP = {
  optionClass: "dta__dsnap_checkbox",
  controlClass: "dta__dsnap_checkbox",
 };
/*
  Classes specific to the Checkbox component used if you want to create a single
  OptionItem that looks like a checkbox option very close to browser defaults
 */
export const DEFAULT_CHECKBOX_CLASSES = {
  optionClass: "dta-default-checkbox",
  controlClass: "dta-default-checkbox__control",
  labelClass: "dta-default-checkbox__label",
  errorClass: "dta-default-checkbox__control--error",
};

/*
  All HTML input types and custom input types accepted by the DTAInput component
 */
export const INPUT_TYPES = [
  "button",
  "checkbox",
  "file",
  "hidden",
  "image",
  "password",
  "radio",
  "reset",
  "submit",
  "text",
  // from HTML5
  "search",
  "email",
  "url",
  "tel",
  "date",
  "time",
  "number",
  "range",
  "color",
  // from HTML5.1 and the WHATWG HTML Living Standard
  "datetime-local",
  "month",
  "week",
  "datetime",
];

export const INPUT_TYPE_SSN = "ssn";
export const INPUT_TYPE_EBT = "ebt";
export const INPUT_TYPE_MONEY = "money";
export const INPUT_ALPHA_TEXT = "alpha-text";
export const SPECIAL_TEXT = "specialTextType";
export const INPUT_ALPHANUMERIC_TEXT = "alphanumeric";
export const INPUT_ALPHANUMERIC_SPACE_TEXT = "alphanumeric_space";
export const INPUT_TEXT_AREA = "textarea";
export const INPUT_NUMERIC_ONLY_TWO = "input_numberic_only_two";
export const INPUT_TYPE_DOLLAR = "dollar";

/*
  Hardcoded list of all languages that the interview can take place in.
  Used in one of the questions in the questions data file.
 */

export const LANGUAGES = [
  "American Sign Language User",
  "Amharic",
  "Arabic",
  "Armenian",
  "Cambodian",
  "Chinese",
  "Chinese - Cantonese",
  "Chinese - Mandarin",
  "Croatian",
  "English",
  "French",
  "Greek",
  "Haitian Creole",
  "Italian",
  "Laotian",
  "Other",
  "Polish",
  "Portuguese",
  "Russian",
  "Serbian Cyrillic",
  "Slovenian",
  "Somali",
  "Spanish",
  "Tagalog",
  "Vietnamese",
];

/*
  Hardcoded list of all of the months. Used in the DTADate component.*/
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export const GetMonths = (language) => {
  var u = window.location.href;
  if (u.endsWith(routeProps.GREETER_ROLE)) {
    return MONTHS;
  } else {
    switch (language) {
      case types.ENGLISH:
        return MONTHS;
      case types.SPANISH:
        return [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];
      case types.CHINESE:
        return [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ];
      case types.PORTUGUESE:
        return [
          "janeiro",
          "fevereiro",
          "Março",
          "abril",
          "Maio",
          "Junho",
          "Julho",
          "agosto",
          "setembro",
          "Outubro",
          "novembro",
          "dezembro",
        ];
      case types.VIETNAMESE:
        return [
          "Tháng 1",
          "Tháng 2",
          "Tháng 3",
          "Tháng 4",
          "Tháng 5",
          "Tháng 6",
          "Tháng 7",
          "Tháng 8",
          "Tháng 9",
          "Tháng 10",
          "Tháng 11",
          "Tháng 12",
        ];
      case HI:
        return [
          "janvye",
          "fevriye",
          "mas",
          "avril",
          "me",
          "jen",
          "jiyè",
          "out",
          "septanm",
          "oktòb",
          "novanm",
          "desanm",
        ];
      default:
        return MONTHS;
    }
  }
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHSHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/*
  Hardcoded list of all US state abbreviations. Used in the Address component.
 */
export const STATES = ["AL"];

export const COUNTRY = ["USA"];

export const AL_STATES = ["AL"];
export const AL_STATE = "AL";

export const STATES_FULL = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const MEMBERS = [
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "eventeenth",
  "eighteenth",
  "nineteenth",
  "twentieth",
  "twenty first",
  "twenty second",
  "twenty third",
  "twenty fourth",
  "twenty fifth",
  "twenty sixth",
  "twenty seventh",
  "twenty eighth",
  "twenty ninth",
  "thirtieth",
];

// For optionally repeated questions
export const REPEATABLE_QUESTION = "repeatedQuestion";
export const REPEATABLE_QUESTION_BUTTON_LABEL = "repeatedQuestionLabel";
export const REPEATABLE_QUESTION_CLASS ="repeatedQuestionClass";
export const HIDE_REPEATABLE_QUESTION_BUTTON = "hideRepeatedQuestion";
export const REPEATABLE_QUESTION_ACTION_ADD = "ADD";
export const REPEATABLE_QUESTION_ACTION_REMOVE = "REMOVE";
export const REPEATABLE_QUESTION_ACTION_UPDATE = "UPDATE";
