import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getMhproviderClientInfo: "GET_MHPROVIDER_CLIENT_INFO",
  refreshMhproviderClientInfo: "REFRESH_MHPROVIDER_CLIENT_INFO",
  mhproviderClientInfoSuccess: "MHPROVIDER_CLIENT_INFO_SUCCESS",
  mhproviderClientInfoFailed: "MHPROVIDER_CLIENT_INFO_FAILED",
  snapOutReachDataFulfilled: "POST_SNAP_OUT_REACH_DATA_FULFILLED",
  submitSnapOutReachDataFailed: "POST_SNAP_OUT_REACH_DATA_FAILED",
  clientRelationInfoSuccess: "CLIENT_RELATION_INFO_SUCCESS",
  clientRelationInfoFailed: "CLIENT_RELATION_INFO_FAILED",
  memberSearchClientInfoSuccess: "MEMBER_SEARCH_INFO_SUCCESS",
  memberSearchClientActionFailed: "MEMBER_SEARCH_INFO_FAILURE",
  cancelOutReachDataFulfilled: "CANCEL_SNAP_OUT_REACH_DATA_FULFILLED",
  cancelSnapOutReachFailed: "CANCEL_SNAP_OUT_REACH_DATA_FAILED",
  mhproviderGetCaseInfoSuccess: "MHPROVIDER_GET_CASE_INFO_SUCCESS",
  mhproviderGetCaseInfoFailed: "MMHPROVIDER_GET_CASE_INFO_FAILED",
  memberSearchZipCodeClientInfoSuccess: "MEMBER_SEARCH_ZIPCODE_INFO_SUCCESS",
  memberSearchZipCodeClientInfoFailed: "MEMBER_SEARCH_ZIPCODE_INFO_FAILED",
  removePropsValues: "REMOVE_MH_PROVIDER_PROPS",
};

export function getMhproviderClientInfo() {
  return {
    type: actionTypes.getMhproviderClientInfo,
  };
}

export function refreshMhproviderClientInfo() {
  return {
    type: actionTypes.refreshMhproviderClientInfo,
  };
}

export function mhproviderClientInfo() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url = config.TigerContextURL + "/mhcaseinfo/";
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(mhproviderClientInfoSuccess(response));
          return response;
        })
        .catch(function (error) {
          dispatch(mhproviderClientInfoFailed(error));
        });
    });
  };
}

function mhproviderClientInfoSuccess(response) {
  return {
    type: actionTypes.mhproviderClientInfoSuccess,
    payload: response,
  };
}

function mhproviderClientInfoFailed(error) {
  return {
    type: actionTypes.mhproviderClientInfoFailed,
    payload: error,
  };
}

export function memberSearchNamesClientInfo(first, last) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const request = {
        firstName: first,
        lastName: last,
      };
      const url = config.TigerContextURL + "/membersearch/";
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, request, options)
        .then(function (response) {
          dispatch(memberSearchClientAction(response));
          return response;
        })
        .catch((error) => {
          dispatch(memberSearchClientActionFailed(error));
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

export function updateSnapOutReachClientInfo(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/mhcaseinfo";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, payload, TigerOptions)
        .then((response) => {
          dispatch(submitSnapOutReachAction(response));
          sessionStorage.removeItem("mhClientInfo");
          sessionStorage.removeItem("mhClientRelationInfo");
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(submitSnapOutReachActionFailed(error));
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function submitSnapOutReachAction(response) {
  return {
    type: actionTypes.snapOutReachDataFulfilled,
    payload: response,
  };
}

function memberSearchClientAction(response) {
  return {
    type: actionTypes.memberSearchClientInfoSuccess,
    payload: response,
  };
}

function submitSnapOutReachActionFailed(error) {
  return {
    type: actionTypes.submitSnapOutReachDataFailed,
    payload: error,
  };
}

function memberSearchClientActionFailed(error) {
  return {
    type: actionTypes.memberSearchClientActionFailed,
    payload: error,
  };
}

export function cancelClientCaseInfo(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/cancelmhcaseinfo";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, payload, TigerOptions)
        .then((response) => {
          dispatch(cancelSnapOutReachAction(response));
          sessionStorage.removeItem("mhClientInfo");
          sessionStorage.removeItem("mhClientRelationInfo");
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(cancelSnapOutReachAction(error));
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function cancelSnapOutReachAction(response) {
  return {
    type: actionTypes.cancelOutReachDataFulfilled,
    payload: response,
  };
}

function cancelSnapOutReachActionFailed(error) {
  return {
    type: actionTypes.cancelSnapOutReachFailed,
    payload: error,
  };
}

function clientRelationInfo(response) {
  const { mdmId } = response.data;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url = config.TigerContextURL + `/mhrelationaseinfo/${mdmId}`;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (responseObject) {
          dispatch(clientRelationInfoSuccess(responseObject));
        })
        .catch(function (error) {
          dispatch(clientRelationInfoFailed(error));
        });
    });
  };
}

function clientRelationInfoSuccess(response) {
  return {
    type: actionTypes.clientRelationInfoSuccess,
    payload: response,
  };
}

function clientRelationInfoFailed(response) {
  return {
    type: actionTypes.clientRelationInfoFailed,
    payload: response,
  };
}

export function mhproviderGetCaseInfo(mdmId, outreactId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const url =
        config.TigerContextURL + "/mhcaseinfo/" + mdmId + "/" + outreactId;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(mhproviderGetCaseInfoSuccess(response));
          return response;
        })
        .catch(function (error) {
          dispatch(mhproviderGetCaseInfoFailed(error));
        });
    });
  };
}

export function memberSearchZipCodeClientInfo(zipcode, ageOver60) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const request = {
        zipCode: zipcode,
      };
      let url = "";
      if (ageOver60 === undefined) {
        url = config.TigerContextURL + "/mhcaseinfobyzipcode/";
      } else {
        url = config.TigerContextURL + "/mhcaseinfobyzipcode/" + ageOver60;
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, request, options)
        .then(function (response) {
          dispatch(memberSearchZipCodeClientInfoSuccess(response));
          return response;
        })
        .catch(function (error) {
          dispatch(memberSearchZipCodeClientInfoFailed(error));
        });
    });
  };
}

function memberSearchZipCodeClientInfoSuccess(response) {
  return {
    type: actionTypes.memberSearchZipCodeClientInfoSuccess,
    payload: response,
  };
}

function memberSearchZipCodeClientInfoFailed(error) {
  return {
    type: actionTypes.memberSearchZipCodeClientInfoFailed,
    payload: error,
  };
}

function mhproviderGetCaseInfoSuccess(response) {
  return {
    type: actionTypes.mhproviderGetCaseInfoSuccess,
    payload: response,
  };
}

function mhproviderGetCaseInfoFailed(error) {
  return {
    type: actionTypes.mhproviderGetCaseInfoFailed,
    payload: error,
  };
}

export function removePropsValues() {
  return {
    type: actionTypes.removePropsValues,
  };
}
