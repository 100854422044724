import React, { Component } from "react";

import { recertificationPageLabels } from "../../recertification/recertificationText";

import * as languageConstants from "../../../utils/constants/constants";
import { selfDeclarePageLabels } from "../selfdeclareText";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class SubmitSummary extends Component {
  render() {
    let medicalExpSelection = TextProps.VALUE_FALSE;
    let childSupportSelection = TextProps.VALUE_FALSE;
    if (this.props.selfDeclare.sections[0].questions[3].Answer !== undefined) {
      if (
        this.props.selfDeclare.sections[0].questions[3].Answer.indexOf(
          "medical-costs"
        ) > -1
      ) {
        medicalExpSelection = TextProps.VALUE_TRUE;
      }
      if (
        this.props.selfDeclare.sections[0].questions[3].Answer.indexOf(
          "child-support"
        ) > -1
      ) {
        childSupportSelection = TextProps.VALUE_TRUE;
      }
    }
    return (
      <div className="pad-all--double">
        <p>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).referenceNumberText
          }
          {this.props.referenceNumber}
        </p>
        {medicalExpSelection ? (
          <p>
            <b>
              {
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpenses
                ).medicalBoldTitle
              }
            </b>
            <p>
              {
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpenses
                ).medicalSummarySenior
              }
              <br />
            </p>
          </p>
        ) : null}
        {childSupportSelection ? (
          <p>
            <b>
              {
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpenses
                ).childSupportBoldTitle
              }
            </b>
            <p>
              {
                selfDeclarePageLabels(
                  language,
                  languageConstants.selfDeclareExpenses
                ).childSummary
              }
              <br />
            </p>
          </p>
        ) : null}
        <br />
        <br />
        <hr className="divider" />
        <button
          className="dta-button dta-button--primary dta-button--large dta-button--block no-underline-link"
          onClick={() => {
            window.location.href = routeProps.CONSUMER_PATH;
          }}
        >
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).sendDocumentBtnLabel
          }
        </button>
        <p>
          <center>
            <a
              className="underline-link"
              onClick={() => window.location.assign(routeProps.CONSUMER_PATH)}
            >
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.signAndSubmitSummaryRecert
                ).dontSendDocumentBtnLabel
              }
            </a>
          </center>
          <p>
            <span>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.signAndSubmitSummaryRecert
                ).otherDocWays
              }
            </span>
            <a href={languageConstants.SUBMIT_DOCS_DEPT_TRANS_ASS}>
              {
                recertificationPageLabels(
                  language,
                  null,
                  languageConstants.signAndSubmitSummaryRecert
                ).findMoreText
              }
            </a>
          </p>
        </p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSummary);
