import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import HelpTip from "../../../utils/components/helpTip";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import * as formatters from "../../../utils/components/utilities/formatters.js";

import { ReportChangePageLabels } from "../reportChangeText";
import { iDontPayUtility } from "../reportChangeMapData.js";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");



  export const getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode.toLowerCase()) {
      case 'semana':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).week;
        return shelterExpenseFrequency;
      case "mes":
      case "mensual":
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'a�o':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;
      case 'biweek':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).biweek;
        return shelterExpenseFrequency;
      case 'semanl':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).semanl;
        return shelterExpenseFrequency;
      case 'onetme':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).onetime;
        return shelterExpenseFrequency;
      case 'bimon':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).bimonthly;
        return shelterExpenseFrequency;
      case 'semmon':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).semimonthly;
        return shelterExpenseFrequency;
      case 'monthly':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'annual':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;
      case 'bimonthly(every two months)':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).bimonthly;
        return shelterExpenseFrequency;
      case 'biweekly(every two weeks)':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).biweek;
        return shelterExpenseFrequency;
      case 'quarterly':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).quarter;
        return shelterExpenseFrequency;
      case 'semi-monthly(twice a month)':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).semimonthly;
        return shelterExpenseFrequency;
      case 'semi-annual(twice a year)':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).semanl;  
        return shelterExpenseFrequency; 
      case 'weekly':
        shelterExpenseFrequency = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).week;  
        return shelterExpenseFrequency;
      return shelterExpenseFrequency;
      default: break;
    }
  }

  class ShelterExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelterUtilitySectionTemplate: null,
      respData: null,
      shelterUIValuesPopulated: TextProps.VALUE_FALSE,
      shelterUtilitySubSectionCleared: TextProps.VALUE_FALSE,
      shelterUtilityConfirmationChanged: TextProps.VALUE_FALSE,
      shelterSummaryEdit: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      sections: [],
      renderedSections: [],
      houseMemberLabels: [],
    };
    this.houseHoldHead = "";
    enableUniqueIds(this);
  }


  componentDidMount() {
    if (!_.isEmpty(this.props.consumer.consumerData) && _.isEmpty(this.props.shelterSummary)) {
      this.props.shelterSubsectionRender();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEmpty(prevProps.consumer.consumerData) &&
      !_.isEmpty(this.props.consumer.consumerData)
    ) {
      // this.setInitialData()
      this.props.shelterSubsectionRender();
    }
  }



  getShelterLabel = (type) => {
    let shelterExpenseLabel;
    switch (type) {
      case 'RENT':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).rent;
        return shelterExpenseLabel;
      case 'MTGPRN':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).mortgage;
        return shelterExpenseLabel;
      case 'PRPTAX':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).propertyTaxes;
        return shelterExpenseLabel;
      case 'HOMEIN':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeInsurance;
        return shelterExpenseLabel;
      case 'CONFEE':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).condoFee;
        return shelterExpenseLabel;
      case 'PRPINS':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).propertyInsurance;
        return shelterExpenseLabel;
      case 'SECMTG':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).secondMortage;
        return shelterExpenseLabel;
      case 'HOMEXP':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).homelessExpenses;
        return shelterExpenseLabel;
      case 'HOMEEQ':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeEquityLoan;
        return shelterExpenseLabel;
      case 'HOASFE':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeOwnerAssociationFees;
        return shelterExpenseLabel;
      case 'OTHER':
        shelterExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).rentTargetValue;
        return shelterExpenseLabel;
      default:
        shelterExpenseLabel = 'None';
        return shelterExpenseLabel;
    }
  }
 
  getutilityLabel = (type) => {
    let utilityExpenseLabel;
    switch (type) {
      case 'heatFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).heatOil;
        return utilityExpenseLabel;
      case 'acFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityairConditioner;
        return utilityExpenseLabel;
      case 'electricOrGasFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityGas;
        return utilityExpenseLabel;
      case 'phoneFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).cellPhoneService;
        return utilityExpenseLabel;
      case 'noUtilityFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).none;
        return utilityExpenseLabel;
      case 'payWaterSewage':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).waterAndSewage;
        return utilityExpenseLabel;
      case 'payGarbage':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).garbage;
        return utilityExpenseLabel;
      case 'liheapFlag':
        utilityExpenseLabel = ReportChangePageLabels(
          language,
          languageConstants.shelterAndutility
        ).energyAssistance;
        return utilityExpenseLabel;
        case ReportChangePageLabels(
            language,
            languageConstants.shelterAndutility
          ).utilityQOptions[8]:
          utilityExpenseLabel = ReportChangePageLabels(
            language,
            languageConstants.shelterAndutility
          ).utilityQOptions[8];
          return utilityExpenseLabel;
      default:
        utilityExpenseLabel = '';
        return utilityExpenseLabel;
    }
  }


  renderHouseHoldHeadSummary = () =>{
    let HOH=[];
    let summary={};
    if(this.props.consumer.consumerData.householdMembers && this.props.consumer.consumerData.householdMembers.membersList){
      HOH = this.props.consumer.consumerData.householdMembers.membersList.filter((mem)=>{
        return mem.granteeFlag
    })
    summary = HOH[0]
    }

    return (
       <p>
      { ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart
              ).noShelterIncomeText
       }
       </p>
    );
  }

  // this.getUtilityExpense(utilityItem)
  renderShelterUtilitySummary = (shelterSummary) => {
    let summary = shelterSummary && shelterSummary.shelterExpense && shelterSummary.shelterExpense[0].section;
    let utilitySummary = shelterSummary && shelterSummary.utilityExpenses && shelterSummary.utilityExpenses.filter((exp)=>exp.type !== "liheapFlag");
    return ( summary &&
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}>{this.houseHoldHead + "'s"}</p>

        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >

        </p>
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.shelterSummaryEditClick(summary)}
          >
            {
              ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
       
        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart
              ).shelterExpensesLabel
            }
            textAfter={
              this.props.getIncomeSummaryTitle && summary.firstName ?
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).after :""
            }
            helpText={""}
            // helpText={
            //   ReportChangePageLabels(
            //     language,
            //     languageConstants.beforeYouStart
            //   ).sUIncomeHelp
            // }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <ul>
          {this.props.shelterSummary && this.props.shelterSummary.shelterExpense && this.props.shelterSummary.shelterExpense[0].incomes.map((shelterSumm)=>{
              if(shelterSumm.Answer && Array.isArray(shelterSumm.Answer))
              return shelterSumm.Answer.map((oneAmount)=>{
                let label = "";
                if (oneAmount.code !== undefined) {
                  if (oneAmount.code === "new" || oneAmount.code === "NEW") {
                    label = "newLabel";
                  } else if (oneAmount.code === "changed" || oneAmount.code === "CHANGE") {
                    label = "changedLabel";
                  } else if (oneAmount.code === "removed" || oneAmount.code === "REMOVE") {
                    label = "removedLabel";
                  }
                }
                if(oneAmount.amount && oneAmount.unit){
                  return <li key={Math.random()}>
                <span className="pure-u-12-24">
                  {this.getShelterLabel(shelterSumm.typeCode)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      ReportChangePageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
                <div  style={{ paddingTop: "10px" }}>
                  <span className="pure-u-12-24">
                    { getShelterExpenseFrequency(oneAmount.unit) + ' ' +'Amount'}
                  </span>
                  <span className="pure-u-12-24">
                    {"$" + oneAmount.amount}
                  </span>
                </div>
              </li>
                }
              })
          })}
        </ul>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart
              ).utilityExpenseLabel
            }
            textAfter={
              this.props.getIncomeSummaryTitle && summary.firstName ?
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).after:""}
            
            helpText={""}
    
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        
        <ul>
          {utilitySummary && utilitySummary.filter(utilityItem=> !iDontPayUtility.includes(utilityItem.type)).map((utilityItem) => {
             let label = "";
             if (utilityItem.code !== undefined && utilityItem.type !== "dataChangeCode") {
               if (utilityItem.code === "new" || utilityItem.code === "NEW") {
                 label = "newLabel";
               } else if (utilityItem.code === "removed" || utilityItem.code === "REMOVE") {
                 label = "removedLabel";
               }
            return (
              <li key={Math.random()}>
                <span className="pure-u-12-24">                 
                  {this.getutilityLabel(utilityItem.type)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      ReportChangePageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
              </li>
            );
             }
          })}
        </ul>

      </div> 
    );
  };

  //Edit Button in Income Summary
  shelterSummaryEditClick = (section) => {
    this.setState({ shelterSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
    this.props.shelterSubsectionRender();
  };


  render() {
    let head = [];
    if (this.props.consumer.consumerData && this.props.consumer.consumerData.householdMembers) {
      this.props.consumer.consumerData.householdMembers.forEach((member) => {
        if (member.granteeFlag === TextProps.VALUE_TRUE) {
          head.push(member);
        }
      });
    }
    if (head && head.length > 0) {
      this.houseHoldHead = head[0].firstName + " " + head[0].lastName + " " + formatters.formatDate(head[0].dateOfBirth);
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {this.renderShelterUtilitySummary(this.props.shelterSummary)}
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {

    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShelterExpenses);
