// Vendor / Components
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import DTAInput from "../../../utils/components/dtaInput";
import DTALoginModal from "../../../utils/components/modal/dtaLoginModal";
import { onPasswordChange } from "../../../redux/consumer/myAccountActions";
import moment from "moment";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as TextProps from "../../../utils/constants/text";
import { forgotPasswordRequest, loginRequest } from "config/appConfig";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config/appConfig";
const msalInstance = new PublicClientApplication(msalConfig);

class ConsumerMyAccount extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {};
  }

  componentDidMount = () => {
    msalInstance.addEventCallback(async (event) => {
      // console.log("MSAL EVENT Forgot ",JSON.stringify(event));
    });
  };

  componentWillMount = () => {
    this.setState({
      user: this.props.consumer.consumerData.user,
      changePassword: TextProps.VALUE_FALSE,
      passwordChanged: TextProps.VALUE_FALSE,
      passwordChangeErrorState: TextProps.VALUE_FALSE,
      passwordChangeErrorMessage: "",
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.consumer.isPasswordChange === TextProps.VALUE_TRUE) {
      this.setState({
        changePassword: TextProps.VALUE_FALSE,
        passwordChanged: TextProps.VALUE_TRUE,
        passwordChangeErrorState: TextProps.VALUE_TRUE,
      });
      setTimeout(() => {
        this.goToLogin();
      }, 5000);
    } else if (
      nextProps.consumer.passwordChangeErrorState === TextProps.VALUE_TRUE
    ) {
      this.setState({
        changePassword: TextProps.VALUE_TRUE,
        passwordChanged: TextProps.VALUE_FALSE,
        passwordChangeErrorMessage:
          nextProps.consumer.passwordChangeErrorMessage,
        passwordChangeErrorState: TextProps.VALUE_TRUE,
      });
    }
  }

  onChangePassword(e) {
    e.preventDefault();
    this.setState({ passwordChangeErrorState: TextProps.VALUE_FALSE });
    let passwordRequest = {};
    passwordRequest.oldPassword = this.state.currentPassword;
    passwordRequest.newPassword = this.state.newPassword;
    passwordRequest.Authorization = this.props.consumer.mfaDetsails.userAuth;
    this.props.onPasswordChange(passwordRequest);
  }

  goToLogin() {
    window.location.assign(routeProps.HOME_PATH);
  }

  goToForgetPassword() {
    window.location.assign(routeProps.FORGOT_PASSWORD_PATH);
  }

  getUserSelectedLanguage = () => {
    const EN = "en-US";
    const ES = "es-ES";
    const PT = "pt-BR";
    const ZH = "zh-Hans";
    const VI = "vi-VI";
    const HT = "ht";
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    let language = "en";
    if (selectedLanguage === EN) {
      language = "en";
    } else if (selectedLanguage === ES) {
      language = "es";
    } else if (selectedLanguage === PT) {
      language = "pt-br";
    } else if (selectedLanguage === ZH) {
      language = "zh-hans";
    } else if (selectedLanguage === VI) {
      language = "vi";
    } else if (selectedLanguage === HT) {
      language = "fr";
    } else {
      language = "en";
    }
    return language;
  };

  onAzureChangePassword() {
    let language = this.getUserSelectedLanguage();
    const forReq = {
      ...forgotPasswordRequest,
      extraQueryParameters: {
        ...forgotPasswordRequest.extraQueryParameters,
        ui_locales: language,
      },
    };
    try {
      msalInstance.loginRedirect(forReq);
    } catch (err) {
      console.log("Error on reset password", err);
    }
  }

  render() {
    const { changePassword, common } = this.props.language.security;
    let dateOfBirth = "";
    let fullName = "";
    let email = "";
    var accUserObject = JSON.parse(sessionStorage.getItem("userInfo"));
    if (accUserObject !== null) {
      dateOfBirth = moment(
        new Date(accUserObject.dateOfBirth).toISOString().substring(0, 10)
      ).format("MM/DD/YYYY");
      fullName = formatters.fullName(accUserObject);
      email = accUserObject.email;
    } else if (
      this.props.consumer.mfaDetails.email !== undefined &&
      this.props.consumer.mfaDetails.email !== ""
    ) {
      dateOfBirth = moment(
        new Date(this.props.consumer.mfaDetails.dateOfBirth)
          .toISOString()
          .substring(0, 10)
      ).format("MM/DD/YYYY");
      fullName = formatters.fullName(this.props.consumer.mfaDetails);
      email = this.props.consumer.mfaDetails.email;
    } else if (this.props.consumer.consumerData.user !== undefined) {
      dateOfBirth = moment(
        new Date(this.props.consumer.consumerData.user.dateOfBirth)
          .toISOString()
          .substring(0, 10)
      ).format("MM/DD/YYYY");
      fullName = formatters.fullName(this.props.consumer.consumerData.user);
      email = this.props.consumer.consumerData.user.email;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }
    return (
      <div className="max-width-myinfo max-width--three-quarters pad-all--double">
        <h1 className="text--gray consumer-title">{common.manageAcct}</h1>
        <ul className="list--sectioned-card">
          <li>
            <div className="pure-g margin-bottom">
              <div className="pure-u-1 pure-u-lg-1-2">
                <span className="text--gray">{changePassword.fullName}</span>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">{fullName}</div>
            </div>
          </li>
          <li>
            <div className="pure-g margin-bottom">
              <div className="pure-u-1 pure-u-lg-1-2">
                <span className="text--gray">{changePassword.dob}</span>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">{dateOfBirth}</div>
            </div>
          </li>
          <li>
            <div className="pure-g margin-bottom">
              <div className="pure-u-1 pure-u-lg-1-2">
                <span className="text--gray">
                  {changePassword.emailAddress}
                </span>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">{email}</div>
            </div>
          </li>
          <li>
            <div className="pure-g margin-bottom">
              <div className="pure-u-1 pure-u-lg-1-2">
                <span className="text--gray"></span>
              </div>
              <div className="pure-u-1 pure-u-lg-1-2">
                {!this.state.changePassword ? (
                  <div>
                    <button
                      className="dta-button dta-button--primary dta-button dta-forget"
                      onClick={() => {
                        this.onAzureChangePassword();
                      }}
                    >
                      {changePassword.changePsw}
                    </button>
                  </div>
                ) : (
                  <form
                    id="changePasswordForm"
                    onSubmit={this.onChangePassword.bind(this)}
                  >
                    <div className="pure-g">
                      <div className="pure-u-1">
                        <p>{changePassword.currentPsw}</p>
                        <DTAInput
                          className="dta-form__control"
                          id="forget-currentPassword"
                          maxLength={30}
                          ref="currentPassword"
                          onChange={(val) =>
                            this.setState({ currentPassword: val })
                          }
                          type="password"
                          required={TextProps.VALUE_TRUE}
                          error={this.currentPasswordError}
                        />
                      </div>
                    </div>
                    <div className="pure-g">
                      <div className="pure-u-1">
                        <p>{changePassword.newPsw}</p>
                        <DTAInput
                          className="dta-form__control"
                          id="forget-newPassword"
                          maxLength={30}
                          ref="newPassword"
                          onChange={(val) =>
                            this.setState({ newPassword: val })
                          }
                          type="password"
                          required={TextProps.VALUE_TRUE}
                          error={this.newPasswordError}
                        />
                      </div>
                    </div>

                    {(() => {
                      if (
                        this.state.passwordChangeErrorState ===
                        TextProps.VALUE_TRUE
                      ) {
                        return (
                          <div className="pure-g">
                            <div className="pure-u-1 dta-form__error-message">
                              {this.state.passwordChangeErrorMessage}
                            </div>
                          </div>
                        );
                      }
                    })()}
                    <div className=" apply__footer pad-all">
                      <button
                        className="dta-button dta-button--outline-primary dta-button--large"
                        onClick={() =>
                          this.setState({
                            changePassword: TextProps.VALUE_FALSE,
                          })
                        }
                      >
                        {changePassword.cancel}
                      </button>
                      <button
                        className="dta-button dta-button--primary dta-button--large"
                        type="submit"
                      >
                        {changePassword.changePsw}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </li>
        </ul>
        <DTALoginModal
          isOpen={this.state.passwordChanged}
          modalClass="login-modal"
          titleText={changePassword.changePsw}
        >
          <div className="dta-modal__body pad-all dataLoginModel">
            <div className="model-header">
              <h1>{changePassword.pswChangeSuccess}</h1>
            </div>

            <div className="pure-g">
              <div className="pure-u-1">
                <p>{changePassword.redirectLogin}</p>
                <button
                  className="dta-button dta-button--outline-primary dta-button--large dta-login-create-button"
                  onClick={this.goToLogin.bind(this)}
                >
                  {changePassword.login}
                </button>
              </div>
            </div>
          </div>
        </DTALoginModal>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onPasswordChange: onPasswordChange,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerMyAccount);
