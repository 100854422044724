import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

class DTAFileInput extends Component {
  static propTypes = {
    // handlers
    onChange: PropTypes.func.isRequired,
    // container
    id: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  constructor() {
    super();

    enableUniqueIds(this);
  }

  render() {
    return (
      <div className="dta-file-input">
        <input
          id={this.props.id || this.nextUniqueId()}
          type="file"
          accept="*/*;capture=camera"
          multiple
          onChange={this.props.onChange}
          className="dta-file-input__control"
        />
        <label
          className="dta-file-input__label"
          htmlFor={this.props.id || this.lastUniqueId()}
        >
          {this.props.labelText}
        </label>
      </div>
    );
  }
}

export default DTAFileInput;
