import React from "react";

import { addStringIf } from "./utilities/controls";
import { termsLanguageTranslation } from "../../pages/recertification/recertificationText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as appConfigConstants from "../constants/appConfigConstants";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export default function TermsRecert({ id, className }) {
  let recertType = sessionStorage.getItem("recertType")
  return (
    <div id={id} className={addStringIf(className, "dta-terms")}>
      <br /> 
      {recertType !== "REEVAL" && (
        <div>
      <strong>{termsLanguageTranslation(selectedLanguage).headerLabel}</strong>
      <p>{termsLanguageTranslation(selectedLanguage).certifyContents}</p>
      {/* <strong>
        {termsLanguageTranslation(selectedLanguage).understandDta}
      </strong> */} 
      <ul>
      {termsLanguageTranslation(selectedLanguage).penaltiesList.map((item) => {
        return (<li>
                {item}
        </li>);
      })}
      </ul>
      <p>
        {
          /* I understand that by signing this application I give DHR permission to verify and
        investigate the information I give that relates to my eligibility for SNAP benefits,
        including permission to: */
          termsLanguageTranslation(selectedLanguage).signingApplication
        }
      </p>
      <strong>{termsLanguageTranslation(selectedLanguage).headerLabel}</strong>
      <ol>
        {termsLanguageTranslation(selectedLanguage).understandList.map((item) => {
          return (<li>
                  {item}
          </li>);
        })}
      </ol>
      </div>
      )}
      {recertType == "REEVAL" && (
        <React.Fragment>
        <h3 style={{textAlign: "center"}}>{termsLanguageTranslation(selectedLanguage).tanfheadone}</h3>
        <h3 style={{textAlign: "center"}}>{termsLanguageTranslation(selectedLanguage).tanfheadtwo}</h3>
        <h4>{termsLanguageTranslation(selectedLanguage).tanftermheadone}</h4>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParaone}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParatwo}</p>
        <ul>
          {termsLanguageTranslation(selectedLanguage).tanfListing.map((item) => {
            return(
              <li>
                {item}
              </li>
            )
          })}
        </ul>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParathree}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParafour}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParafive}</p>
          <h4>{termsLanguageTranslation(selectedLanguage).tanftermheadtwo}</h4>
        <div><a href="https://sos.alabama.gov/alabama-votes/voter/register-to-vote" target="_blank">{termsLanguageTranslation(selectedLanguage).tanfParasix}</a></div>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParaseven}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParaeight}</p><br/>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParanine}</p>
        <span><a href="">{termsLanguageTranslation(selectedLanguage).tanfParaten}</a></span>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParaeleven}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParatwelve}</p>
        <p>{termsLanguageTranslation(selectedLanguage).tanfParathirteen}</p>
        </React.Fragment>
      )}
    </div>
  );
}
