// Vendor / components
import React, { Component } from "react";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import _ from "lodash";
import { history } from "../../../redux/configureStore";
// DHR Components
import Icon from "../../../utils/components/dtaIcon";
import DTAEditableFields from "../../../utils/components/editableFields";
import ListWrapper from "../../../utils/components/list/listWrapper";
import SearchFilters from "../../../utils/components/searchFilters/searchFilters";
import Filter from "../../../utils/components/searchFilters/filter";
// Constants / Formatters
import * as SharedProps from "../../../utils/constants/shared";
import * as BenefitProps from "../../../utils/constants/benefit";
import * as formatters from "../../../utils/components/utilities/formatters.js";
import { sanitizeURL } from "../../../utils/components/utilities/utils.js";
// Static data
import * as FiltersData from "../../../utils/constants/searchFilters";
// User-specific data (fake)
import { consumerAboutMeInfo } from "../consumerInfoMapData";
import { showBenefitListInfo,isPopupClosed } from "../../../redux/consumer/consumerMyInfoActions";
import {
  requestIncomeVerificationAnction,
  requestEbtCardAction,
  getConsumerBenefitDetails,
  consumerBenefitDetailsSuccess,
} from "../../../redux/consumer/consumerBenefitActions";
// Redux implementations
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import * as TextProps from "../../../utils/constants/text";
import { consumerConst } from "../consumerText";

class ConsumerBenefitDetail extends Component {
  constructor(props) {
    super(...arguments);
    this.sendRIVL = this.sendRIVL.bind(this);
    this.handleMailingAddressChange =
      this.handleMailingAddressChange.bind(this);
    this.state = {
      // User should really come from redux or elsewhere
      user: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1987, 5, 7),
        [SharedProps.AGENCY_ID]: "2000001",
      },
      consumerIncomeMailingUpdate: [],
      benefit: {},
      benefitsList: [],
      RIVLModalOpen: TextProps.VALUE_FALSE,
      RECModalOpen: TextProps.VALUE_FALSE,
      RIVLError: TextProps.VALUE_FALSE,
      RIVLErrorMessage: null,
      isEditingMailingAddress: TextProps.VALUE_FALSE,
      otherMembers: [],
      isLoading: TextProps.VALUE_TRUE,
      benefitFilter: TextProps.VALUE_FALSE,
      showEbtCardAlert: TextProps.VALUE_FALSE,
    };
  }

  componentDidMount() {
    var benefitInfo = JSON.parse(sessionStorage.getItem("consumerAccountData"));
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    let userAuthToken = "";
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }
    if (
      (this.props.consumer.consumerData.clientInfo !== undefined &&
        this.props.consumer.consumerData.clientInfo.name !== undefined &&
        this.props.consumer.consumerData.clientInfo.name !== null) ||
      (clientDetails !== undefined && clientDetails.clientInfo !== undefined)
    ) {
      this.programTypeTrigger();
      this.setBenefit();
    } else if (benefitInfo !== null) {
      this.setBenefit();
      //BenefitHouseholdDetails API call
      this.programTypeTrigger();
    }
    // Scroll back to the top of the page
    window.scrollTo(0, 0);
    this.setState({ isLoading: TextProps.VALUE_FALSE });
    this.props.getConsumerBenefitDetails();
    setTimeout(() => {
      this.props.consumerBenefitDetailsSuccess();
    }, 2000);
  }

  componentDidUpdate() {
    this.setBenefit();
  }

  componentWillReceiveProps(nextProps) {
    var clientData = JSON.parse(sessionStorage.getItem("clientDetails"));
    var providerObj = JSON.parse(sessionStorage.getItem("providerData"));
    if (
      (consumerAboutMeInfo[1] !== undefined &&
        consumerAboutMeInfo[2] !== undefined &&
        clientData === null) ||
      (consumerAboutMeInfo[1] !== undefined &&
        consumerAboutMeInfo[2] !== undefined &&
        providerObj !== null)
    ) {
      sessionStorage.setItem(
        "consumerAboutMeInfoTemplate",
        JSON.stringify(consumerAboutMeInfo)
      );
    }
    var consumerAboutMeInfoTemplate = JSON.parse(
      sessionStorage.getItem("consumerAboutMeInfoTemplate")
    );
    var mailMyInfo = JSON.parse(sessionStorage.getItem("mailMyInfo"));
    var isHousehold = JSON.parse(sessionStorage.getItem("isHousehold"));

    var homelessFlag;
    var residentialsMatchFlag;
    if (clientData !== null) {
      if (
        clientData.clientAddress !== undefined &&
        clientData.clientAddress.length > 0 &&
        consumerAboutMeInfoTemplate !== null
      ) {
        clientData.clientAddress.map((addr, index) => {
          if (addr.typeCode === "RES") {
            let address = consumerAboutMeInfoTemplate[0];
            address.fields[0]["value"].addressLine1 = addr.line1;
            address.fields[0]["value"].addressLine2 = addr.line2;
            address.fields[0]["value"].street = addr.line1+' '+addr.line2;
            address.fields[0]["value"].city = addr.city;
            address.fields[0]["value"].state = addr.state;
            address.fields[0]["value"].zipCode = addr.zip;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.typeCode === "MAIL") {
            let address = consumerAboutMeInfoTemplate[0];
            address.fields[1]["value"].addressLine1 = addr.line1;
            address.fields[1]["value"].addressLine2 = addr.line2;
            address.fields[1]["value"].street = addr.line1+' '+addr.line2;
            address.fields[1]["value"].city = addr.city;
            address.fields[1]["value"].state = addr.state;
            address.fields[1]["value"].zipCode = addr.zip;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            address.fields[1].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
            address.fields[1].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.name === "residentialAddress") {
            let address = consumerAboutMeInfoTemplate[0];
            address.fields[0]["value"].street = addr.value.street;
            address.fields[0]["value"].city = addr.value.city;
            address.fields[0]["value"].state = addr.value.state;
            address.fields[0]["value"].zipCode = addr.value.zipCode;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].homelessFlag = homelessFlag;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
          } else if (addr.name === "is-also-mailing") {
            let address = consumerAboutMeInfoTemplate[0];
            address.fields[1]["value"].street = addr.value.street;
            address.fields[1]["value"].city = addr.value.city;
            address.fields[1]["value"].state = addr.value.state;
            address.fields[1]["value"].zipCode = addr.value.zipCode;
            if (mailMyInfo === null) {
              residentialsMatchFlag = addr.resAddrMatchFlag;
            } else if (mailMyInfo === TextProps.VALUE_FALSE) {
              residentialsMatchFlag = TextProps.VALUE_FALSE;
            } else if (mailMyInfo === TextProps.VALUE_TRUE) {
              residentialsMatchFlag = TextProps.VALUE_TRUE;
            }
            address.fields[1].resAddrMatchFlag = residentialsMatchFlag;
            if (isHousehold === null) {
              homelessFlag = addr.homelessFlag;
            } else if (isHousehold === TextProps.VALUE_FALSE) {
              homelessFlag = TextProps.VALUE_FALSE;
            } else if (isHousehold === TextProps.VALUE_TRUE) {
              homelessFlag = TextProps.VALUE_TRUE;
            }
            address.fields[1].homelessFlag = homelessFlag;
          }
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );
        });
      } else if (
        clientData.clientAddress !== undefined &&
        clientData.clientAddress.length === 0
      ) {
        let address = consumerAboutMeInfoTemplate[0];
        if (mailMyInfo === null) {
          residentialsMatchFlag = TextProps.VALUE_FALSE;
        } else if (mailMyInfo === TextProps.VALUE_FALSE) {
          residentialsMatchFlag = TextProps.VALUE_FALSE;
        } else if (mailMyInfo === TextProps.VALUE_TRUE) {
          residentialsMatchFlag = TextProps.VALUE_TRUE;
        }
        address.fields[0].resAddrMatchFlag = residentialsMatchFlag;
        address.fields[1].resAddrMatchFlag = TextProps.VALUE_FALSE;
        sessionStorage.setItem(
          "consumerAboutMeInfoTemplate",
          JSON.stringify(consumerAboutMeInfoTemplate)
        );
      }
    }
    this.props.consumer.consumerData.consumerAboutMeInfo =
      consumerAboutMeInfoTemplate;
  }

  handleMailingAddressChange = (questionData, conditionObj, val) => {
    questionData.value = val;
    switch (questionData.name) {
      case "accountConnectType":
        this.accountAnswer.answerType = {};
        questionData.conditionals.forEach((cond) => {
          cond.conditionalQuestions.forEach((quest) => {
            delete quest.value;
          });
        });
        this.accountAnswer.answerType.question = val;
        break;
      case "residentialAddress":
        {
          var consumerAboutMeInfoTemplate = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          consumerAboutMeInfoTemplate[0].fields[0]["value"].street = val.street;
          consumerAboutMeInfoTemplate[0].fields[0]["value"].city = val.city;
          consumerAboutMeInfoTemplate[0].fields[0]["value"].state = val.state;
          consumerAboutMeInfoTemplate[0].fields[0]["value"].zipCode =
            val.zipCode;
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplate)
          );

          var clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
          if (
            clientDetails.clientAddress[1] !== undefined &&
            clientDetails.clientAddress.length === 2 &&
            clientDetails.clientAddress[1].typeCode === "RES"
          ) {
            clientDetails.clientAddress[1].line1 =
              consumerAboutMeInfoTemplate[0].fields[0].value.addressLine1;
              clientDetails.clientAddress[1].line2 =
              consumerAboutMeInfoTemplate[0].fields[0].value.addressLine2;
            clientDetails.clientAddress[1].city =
              consumerAboutMeInfoTemplate[0].fields[0].value.city;
            clientDetails.clientAddress[1].zip =
              consumerAboutMeInfoTemplate[0].fields[0].value.zipCode;
            sessionStorage.setItem(
              "clientDetails",
              JSON.stringify(clientDetails)
            );
          }
          if (
            clientDetails.clientAddress[0] !== undefined &&
            clientDetails.clientAddress.length === 2 &&
            clientDetails.clientAddress[0].name === "residentialAddress"
          ) {
            clientDetails.clientAddress[0].value.street =
              consumerAboutMeInfoTemplate[0].fields[0].value.street;
            clientDetails.clientAddress[0].value.city =
              consumerAboutMeInfoTemplate[0].fields[0].value.city;
            clientDetails.clientAddress[0].value.zip =
              consumerAboutMeInfoTemplate[0].fields[0].value.zipCode;
            sessionStorage.setItem(
              "clientDetails",
              JSON.stringify(clientDetails)
            );
          }
        }
        break;
      case "is-also-mailing":
        {
          if (
            this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
          ) {
            this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.city =
              val.city;
            this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.state =
              val.state;
            this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.street =
              val.street;
            this.props.consumer.centrifyData.consumerAboutMeInfo[0].fields[1].value.zipCode =
              val.zipCode;
          }
          var consumerAboutMeInfoTemplateVal = JSON.parse(
            sessionStorage.getItem("consumerAboutMeInfoTemplate")
          );
          consumerAboutMeInfoTemplateVal[0].fields[1]["value"].street =
            val.street;
          consumerAboutMeInfoTemplateVal[0].fields[1]["value"].city = val.city;
          consumerAboutMeInfoTemplateVal[0].fields[1]["value"].state =
            val.state;
          consumerAboutMeInfoTemplateVal[0].fields[1]["value"].zipCode =
            val.zipCode;
          sessionStorage.setItem(
            "consumerAboutMeInfoTemplate",
            JSON.stringify(consumerAboutMeInfoTemplateVal)
          );

          var clientData = JSON.parse(sessionStorage.getItem("clientDetails"));
          if (
            clientData.clientAddress[0] !== undefined &&
            clientData.clientAddress[0].typeCode === "MAIL"
          ) {
            clientData.clientAddress[0].line1 = val.addressLine1;
            clientData.clientAddress[0].line2 = val.addressLine2;
            clientData.clientAddress[0].city = val.city;
            clientData.clientAddress[0].zip = val.zipCode;
            clientData.clientAddress[0].state = val.state;
            sessionStorage.setItem("clientDetails", JSON.stringify(clientData));
          }
          if (
            clientData.clientAddress[1] !== undefined &&
            clientData.clientAddress[1].name === "is-also-mailing"
          ) {
            clientData.clientAddress[1].value.street = val.street;
            clientData.clientAddress[1].value.city = val.city;
            clientData.clientAddress[1].value.zip = val.zipCode;
            clientData.clientAddress[1].value.state = val.state;
            sessionStorage.setItem("clientDetails", JSON.stringify(clientData));
          }
        }
        break;
      default:
        break;
    }
    this.forceUpdate();
  };

  getBenefit = () => {
    let found = undefined;
    let consumerBenefitsNew = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    ).benefitInfo;
    consumerBenefitsNew.forEach((benefit) => {
      const match = matchPath(this.props.location.pathname, {
        path: `/myInfo/benefit/${this.props.match.params.id}`,
        exact: TextProps.VALUE_TRUE,
        strict: TextProps.VALUE_FALSE,
      });
      if (match) {
        found = benefit;
      }
    });
    return found;
  };

  setBenefit = () => {
    const benefit = this.getBenefit();
    if (benefit && benefit.id !== this.state.benefit.id) {
      this.setState({ benefit });
    }
  };

  //Benefithousehold API trigger onclick View more link
  programTypeTrigger() {
    sessionStorage.setItem("programID", this.props.match.params.id);
  }
  // End: Faking router + redux
  getHeadOfHousehold = () => {
    return this.state.benefit[BenefitProps.HOUSEHOLD_MEMBERS].filter(
      (member) => member[BenefitProps.HEAD_OF_HOUSEHOLD]
    )[0];
  };

  getOtherMembers = () => {
    return this.state.benefit[BenefitProps.HOUSEHOLD_MEMBERS].filter(
      (member) => !member[BenefitProps.HEAD_OF_HOUSEHOLD]
    );
  };

  openRIVLModal = () => {
    this.setState({ RIVLModalOpen: TextProps.VALUE_TRUE });
  };

  openRECModal = (ebtButton) => {
    if (ebtButton) {
      this.setState({
        RECModalOpen: TextProps.VALUE_FALSE,
        showEbtCardAlert: TextProps.VALUE_TRUE,
      });
    } else {
      this.setState({
        RECModalOpen: TextProps.VALUE_TRUE,
        showEbtCardAlert: TextProps.VALUE_FALSE,
      });
    }
  };

  closeRIVLModal = () => {
    // Cancel any actions then...
    this.setState({
      RIVLModalOpen: TextProps.VALUE_FALSE,
      isEditingMailingAddress: TextProps.VALUE_FALSE,
      RECModalOpen: TextProps.VALUE_FALSE,
      showEbtCardAlert: TextProps.VALUE_FALSE,
    });
  };

  onMailingAddressEditStateChange = (isEditing) => {
    this.setState({ isEditingMailingAddress: isEditing });
  };

  sendRIVL = () => {
    // Logic to dictate the letter should be sent and set loading state for modal
    this.props.isPopupClosed();
    const { info } = this.props.language.consumer;
    const requestingString = info.requesting;
    const requestingSubmittedString = info.requestSubmitted;
    this.RIVLModal.showBlur("loading", requestingString);
    var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));

    let agencyID = "";
    if (this.props.consumer.consumerData.clientInfo !== undefined) {
      agencyID = this.props.consumer.consumerData.clientInfo.agencyID;
    } else if (this.props.consumer.consumerData.user !== undefined) {
      agencyID = this.props.consumer.consumerData.user.accountID;
    } else if (clientDetails !== null) {
      agencyID = clientDetails.clientInfo.agencyID;
    }

    if (agencyID !== "") {
      this.props.requestIncomeVerificationAnction(agencyID);
    }

    // setTimeout simulate communicating with server, for  purposes
    setTimeout(() => {
      //If letter successfully sent, then show success message
      this.RIVLModal.showBlur("success", requestingSubmittedString);
    }, 2500);
  };

  // api call for request EBT card
  sendREC = () => {
    let consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    const { info } = this.props.language.consumer;
    var { benefitInfo } = this.props.consumer.consumerData;
    if (benefitInfo !== undefined) {
      benefitInfo = consumerDataUserObj.benefitInfo;
    }

    //currentBenefitIndex is used to iterate multiple arrays of values in benefit info
    let currentBenefitIndex = 0,
      id;
    if (benefitInfo !== undefined) {
      id =
        consumerDataUserObj.benefitInfo[currentBenefitIndex].householdMembers[
          currentBenefitIndex
        ].agencyID;
    }
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    let userAuthToken = "";
    this.RIVLModal.showBlur("loading", info.requesting);
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    }

    let agencyID = "";
    if (this.props.consumer.consumerData.clientInfo !== undefined) {
      agencyID = this.props.consumer.consumerData.clientInfo.agencyID;
    } else if (this.props.consumer.consumerData.user !== undefined) {
      agencyID = this.props.consumer.consumerData.user.accountID;
    } else if (clientDetails !== null) {
      agencyID = clientDetails.clientInfo.agencyID;
    }

    if (agencyID !== "") {
      // pushing path and state in history location
      history.push(
        `/consumer/myInfo/benefit/${id}`.replace(
          `${id}`,
          this.props.match.params.id
        ),
        { buttonEbtCardUpd: TextProps.VALUE_TRUE }
      );
      sessionStorage.setItem("requestEbtCard", TextProps.VALUE_TRUE);
      this.props.requestEbtCardAction(agencyID);
    }
    // setTimeout simulate communicating with server, for  purposes
    setTimeout(() => {
      this.RIVLModal.showBlur("success", `${info.thanksMessageEbtCard}`);
    }, 5);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  renderMemberDetails = (member) => {
    const { info } = this.props.language.consumer;
    let status;
    const benefit = this.getBenefit();
    if (member && member.benefitStatusCode !== undefined) {
      status = member["benefitStatusCode"];
    } else {
      status = benefit[BenefitProps.STATUSCODE];
    }
    var memName = member;
    if (member.name !== undefined) {
      memName = member.name;
    }
    return (
      <p className="margin-top-none" key={member[SharedProps.ID]}>
        <strong>{formatters.fullNameProvider(memName)}</strong> (
        {formatters.date(
          formatters.convertStringDateToDate(member[SharedProps.DATEOFBIRTH])
        )}
        )
        <br />
        {info.benefitStatus}
        <strong>
          {_.capitalize(
            formatters.convertStatusShortToFull(status.toUpperCase())
          )}
        </strong>
        {member[BenefitProps.STATUS_REASON] ? (
          <span>
            <br />
            Status Reason: {member[BenefitProps.STATUS_REASON]}
          </span>
        ) : null}
      </p>
    );
  };

  convertToMultiLang = (data) => {
    const { common } = this.props.language.consumer;
    switch (data) {
      case "Active":
        return common.active;
      case "Pending":
        return common.pending;
      case "Closed":
        return common.closed;
      case "Denied":
        return common.denied;
      case "Ineligible":
        return common.ineligible;
      default:
        return data;
    }
  };

  // Events
  // ------
  // doLoadMore function fakes getting more results for list.
  // Can be done through appropriate production logic instead
  _doLoadMore = (stateKey, toInsert) => {
    var array = new Uint32Array(1);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState({
          [stateKey]: [
            ...this.state[stateKey],
            ...toInsert.map((item) => {
              return {
                ...item,
                id: `${window.crypto.getRandomValues(array)[0]}`,
              };
            }),
          ],
        });
        resolve();
      }, 2000);
    });
  };

  showEbtCardRender = (
    isEligibleForEBTReplacement,
    ebtCardAnticipatedDeliveryDate,
    ebtCardRequestDate
  ) => {
    const { info, documentupload } = this.props.language.consumer;
    let ebtCardTextMsg = null;
    if (isEligibleForEBTReplacement) {
      if (ebtCardAnticipatedDeliveryDate !== null) {
        ebtCardTextMsg = (
          <div>
            {" "}
            <p className="textAlignJustify">
              {info.anticipatedDeliveryDateMsg}
              {formatters.convertDateToStateFormat(
                ebtCardAnticipatedDeliveryDate
              )}
              {info.dot}
            </p>
          </div>
        );
      } else if (ebtCardRequestDate !== null) {
        ebtCardTextMsg = (
          <div>
            {" "}
            <p className="textAlignJustify">
              {info.requestEbtCardMsg1}
              {formatters.convertDateToStateFormat(ebtCardRequestDate)}
              {info.requestEbtCardMsg2}
            </p>
          </div>
        );
      }
    }
    return (
      <div className="dta-modal__body pad-all">
        {ebtCardTextMsg}
        <div className="showEbtCardFooter">
          <button
            className="dta-button dta-button--large dta-button--outline-primary showEbtCardButton"
            onClick={() => {
              this.closeRIVLModal();
            }}
          >
            {documentupload.cancelButton}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, benefitFilter, benefitHistoryFilter } =
      this.props.consumer;
    let consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    var { benefitInfo } = this.props.consumer.consumerData;
    const { info, documentupload } = this.props.language.consumer;
    const { RIVLModalOpen, RECModalOpen } = this.state;
    let currentBenefitIndex = 0;
    let providerAddr = JSON.parse(sessionStorage.getItem("providerAddr"));
    let providerData = JSON.parse(sessionStorage.getItem("providerData"));
    let consumerAddressInfo = JSON.parse(
      sessionStorage.getItem("consumerAddressInfo")
    );
    var requestIncomeAdress = [];
    var agencyId = "";
    const openModal = RIVLModalOpen ? RIVLModalOpen : RECModalOpen;
    const headerText = RIVLModalOpen
      ? info.requestIncomeVerificationLetter
      : info.requestEbtCard;
    // condition for request Ebt card button

    let providerDataAccountId = null;
    if (providerData !== null) {
      providerDataAccountId = providerData.accountID;
    }
    if (
      this.props.consumer.consumerData !== undefined &&
      this.props.consumer.consumerData.consumerAboutMeInfo !== undefined
    ) {
      // Display Mailing address or Home Address
      const addressFields =
        this.props.consumer.consumerData.consumerAboutMeInfo[0];
      agencyId =
        this.props.consumer.consumerData.clientInfo !== undefined
          ? this.props.consumer.consumerData.clientInfo.agencyID
          : providerDataAccountId;
      requestIncomeAdress.push(addressFields);
      sessionStorage.setItem(
        "consumerAddressInfo",
        JSON.stringify(addressFields)
      );
    } else if (
      this.props.consumer.centrifyData !== undefined &&
      this.props.consumer.centrifyData.consumerAboutMeInfo !== undefined
    ) {
      const addressFields =
        this.props.consumer.centrifyData.consumerAboutMeInfo[0];
      requestIncomeAdress.push(addressFields);
    } else if (providerAddr !== null) {
      requestIncomeAdress.push(providerAddr.consumerAboutMeInfo[0]);
    } else if (consumerAddressInfo !== null) {
      requestIncomeAdress.push(consumerAddressInfo);
    }
    benefitInfo = consumerDataUserObj.benefitInfo;

    //currentBenefitIndex is used to iterate multiple arrays of values in benefit info
    for (let [index, element] of benefitInfo.entries()) {
      if (element.id.toString() === this.props.match.params.id) {
        currentBenefitIndex = index;
        break;
      }
    }

    const benefitDetail = consumerDataUserObj.benefitInfo[currentBenefitIndex];
    if (benefitDetail.householdMembers != undefined) {
      var householdMembers = benefitDetail.householdMembers;
    }
    let benefitHistory = [];
    if (benefitFilter === undefined) {
      if (benefitDetail.benefitHistory !== undefined) {
        benefitHistory = benefitDetail.benefitHistory;
      }
    } else {
      benefitHistory = benefitHistoryFilter;
    }

    let ebtCardRequestDate = "";
    let ebtCardAnticipatedDeliveryDate = "";
    let ebtButton = TextProps.VALUE_FALSE;
    const isEligibleForEBTReplacement =
      consumerDataUserObj.benefitInfo[currentBenefitIndex]
        .isEligibleForEBTReplacement === "Y"
        ? TextProps.VALUE_TRUE
        : TextProps.VALUE_FALSE;
    if (isEligibleForEBTReplacement) {
      ebtCardRequestDate =
        consumerDataUserObj.benefitInfo[currentBenefitIndex].ebtCardTrackingInfo
          .ebtCardRequestDate;
      ebtCardAnticipatedDeliveryDate =
        consumerDataUserObj.benefitInfo[currentBenefitIndex].ebtCardTrackingInfo
          .ebtCardAnticipatedDeliveryDate;

      ebtButton =
        consumerDataUserObj.benefitInfo[currentBenefitIndex].ebtCardTrackingInfo
          .canRequestCardNow == TextProps.VALUE_Y
          ? TextProps.VALUE_FALSE
          : TextProps.VALUE_TRUE;
    }

    if (
      this.props.consumer.isIncomeVerified !== undefined &&
      this.props.consumer.isIncomeVerified !== null
    ) {
      if (this.props.consumer.isIncomeVerified.code === "200") {
        this.props.consumer.isIncomeVerified.Status = "Done";
      }
    }

    if (
      this.props.consumer.isEbtCardRequested !== undefined &&
      this.props.consumer.isEbtCardRequested !== null
    ) {
      if (this.props.consumer.isEbtCardRequested.code === "200") {
        this.props.consumer.isEbtCardRequested.Status = "Done";
      }
    }

    if (isLoading) return helperFunction.isLoading(isLoading);
    let benefitValue = "";
    if (
      benefitInfo[currentBenefitIndex][BenefitProps.STATUS] ===
        BenefitProps.STATUS_TYPE_DENIED ||
      benefitInfo[currentBenefitIndex][BenefitProps.STATUS] ===
        BenefitProps.STATUS_TYPE_CLOSED
    ) {
      benefitValue = (
        <span>
          <br /> {info.closedOn}{" "}
          {formatters.date(
            benefitInfo[currentBenefitIndex][BenefitProps.CLOSED_DATE]
          )}
        </span>
      );
    } else if (
      benefitInfo[BenefitProps.STATUS] === BenefitProps.STATUS_TYPE_PENDING
    ) {
      benefitValue = (
        <span>
          <br /> {info.submitedOn}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.APP_SUBMITTED_DATE]
            ),
            TextProps.VALUE_TRUE
          )}
        </span>
      );
    } else {
      benefitValue = (
        <span>
          <br /> {formatters.benefitTypeDisplay(
                          benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
                        )}{" "}{info.ebtBalance}{" "}{info.effectiveAsOf} {formatters.date(new Date(), TextProps.VALUE_TRUE)}
        </span>
      );
    }

    let benefitInfoValue = "";
    if (
      benefitInfo[currentBenefitIndex][BenefitProps.STATUS] ===
        BenefitProps.STATUS_TYPE_DENIED ||
      benefitInfo[BenefitProps.STATUS] === BenefitProps.STATUS_TYPE_CLOSED
    ) {
      benefitInfoValue = (
        <span>
          {benefitInfo[currentBenefitIndex][BenefitProps.STATUS_REASON]}
        </span>
      );
    } else if (
      benefitInfo[currentBenefitIndex][BenefitProps.STATUS] ===
      BenefitProps.STATUS_TYPE_PENDING
    ) {
      benefitInfoValue = (
        <span>
          {info.documentNeed} <Link to="/documents">{info.sendToDTA}</Link>
          <br /> {info.dtaReachOut}
        </span>
      );
    } else if (benefitInfo[currentBenefitIndex][BenefitProps.RE_CERT_DATE]) {
      benefitInfoValue = (
        <span>
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          ) === "SNAP"
            ? info.revaluationDate
            : info.recertificationDate}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.RE_CERT_DATE]
            )
          )}
          <br />
          {info.next}{" "}
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          )}{" "}
          {info.allotment}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.NEXT_BENEFIT_DATE]
            ),
            TextProps.VALUE_TRUE
          )}
        </span>
      );
    } else if (benefitInfo[currentBenefitIndex][BenefitProps.RE_EVAL_DATE]) {
      benefitInfoValue = (
        <span>
          <Icon name="notice" />
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          ) === "SNAP"
            ? info.revaluationDate
            : info.recertificationDate}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.RE_EVAL_DATE]
            ),
            TextProps.VALUE_TRUE
          )}
          <br />
          {info.next}{" "}
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          )}{" "}
          {info.allotment}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.NEXT_BENEFIT_DATE]
            ),
            TextProps.VALUE_TRUE
          )}
        </span>
      );
    } else {
      benefitInfoValue = (
        <span>
          <br />
          {info.next}{" "}
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          )}{" "}
          {info.allotment}{" "}
          {formatters.date(
            formatters.convertStringDateToDate(
              benefitInfo[currentBenefitIndex][BenefitProps.NEXT_BENEFIT_DATE]
            ),
            TextProps.VALUE_TRUE
          )}
        </span>
      );
    }

    let phoneNumberVal = null;
    if (
      benefitDetail.caseManagerName &&
      benefitDetail.caseManagerPhone.phoneNumber !== undefined
    ) {
      phoneNumberVal = (
        <div>
          <br />
          <a href={sanitizeURL(`tel:${benefitDetail.caseManagerPhone.phoneNumber}`)}>
            {formatters.phone(benefitDetail.caseManagerPhone.phoneNumber)}
          </a>
        </div>
      );
    }

    let mailAddressIncomOrEbt = "";
    if (RIVLModalOpen) {
      mailAddressIncomOrEbt = (
        <p
          className={`rivl-modal__message ${
            this.state.isEditingMailingAddress
              ? "is-editing-address" || "MAIL"
              : ""
          }`}
        >
          {info.editingIncomeVerificationMailingAddress}
          <br></br>
          <br></br>
          <br></br>
          <strong>{info.isMailAddr}</strong>
        </p>
      );
    } else {
      mailAddressIncomOrEbt = (
        <p className="rivl-modal__message">
         <strong>Lost, Stolen, or Damaged EBT Card</strong>
        </p>
      );
    }

    let isEditMailingAddrCloseRIVLModal = null;
    if (this.state.isEditingMailingAddress === TextProps.VALUE_FALSE) {
      isEditMailingAddrCloseRIVLModal = (
        <button
          className="dta-button dta-button--large dta-button--outline-primary"
          disabled={this.state.isEditingMailingAddress}
          onClick={this.closeRIVLModal}
        >
          {info.cancel}
        </button>
      );
    }

    let isEditMailingAddrSendRIVL = null;
    if (this.state.isEditingMailingAddress === TextProps.VALUE_FALSE) {
      isEditMailingAddrSendRIVL = (
        <button
          className="dta-button dta-button--large dta-button--primary"
          disabled={this.state.isEditingMailingAddress}
          onClick={this.sendRIVL}
        >
          {info.submit}
        </button>
      );
    }

    return (
      <div className="max-width-myinfo max-width--three-quarters pad-all--double benefit-detail">
        <Link
          to="/myInfo"
          className="dta-button dta-button--outline-secondary dta-button--text-with-icon benefit-detail__back-button"
        >
          <Icon name="arrow-back" ariaHidden={TextProps.VALUE_TRUE} />
          {info.back}
        </Link>
        {}
        <div className="benefit-detail__header pure-g">
          <div className="pure-u-1-2">
            <h1>
              {formatters.benefitTypeDisplay(
                benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE],
                TextProps.VALUE_TRUE
              )}{" "}
              {info.benifits}
            </h1>
          </div>
          <div className="pure-u-1-2 benefit-detail__header-status">
            <span>
              <b>
                {this.convertToMultiLang(
                  _.capitalize(
                    formatters.convertStatusShortToFull(
                      benefitInfo[currentBenefitIndex][BenefitProps.STATUS]
                    )
                  )
                )}
              </b>
            </span>
          </div>
        </div>
        <div className="benefit-detail__summary pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <p className="margin-none">
              <span className="benefit-summary__dollar-amount text--x-large">
                {formatters.money(
                  benefitInfo[currentBenefitIndex][BenefitProps.EBT_BALANCE],
                  TextProps.VALUE_FALSE
                )}
              </span>
              {benefitValue}
            </p>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <p className="margin-top-none">{benefitInfoValue}</p>
            <button
              className="dta-button dta-button--primary dta-button--incverletter"
              onClick={this.openRIVLModal}
            >
              {info.VerificationLetter}
            </button>
            {isEligibleForEBTReplacement ? (
              <div>
                <button
                  className="dta-button dta-button--primary dta-button--ebtcard"
                  onClick={() => this.openRECModal(ebtButton)}
                >
                  {info.buttonEbtCard}
                </button>
              </div>
            ) : null}
          <div>
            <p>
            <a
            href="https://www.connectebt.com/"
            target="_blank"
            style={{textDecoration:'underline',cursor:'pointer',fontWeight:"bold"}} 

            >
              {info.ebtClientPortal}
            </a>
          {formatters.benefitTypeDisplay(
            benefitInfo[currentBenefitIndex][BenefitProps.TYPECODE]
          ) === "SNAP" &&
            <a
            href="https://www.fns.usda.gov/snap/retailer-locator"
            target="_blank"
            style={{textDecoration:'underline',cursor:'pointer',marginLeft:"25%",fontWeight:"bold"}} 

            >
                {info.ebtRetailers}
            </a>}
            </p>
          </div>
          
          </div>
        </div>
        <p className="consumer__heading text--gray margin-top-double">
          {info.details}
        </p>
        <ul className="list--sectioned-card">
          <li>
            <div className="pure-g margin-bottom">
              <div className="pure-u-1 pure-u-lg-1-2">
                <span className="text--gray">{info.headOfHousehold}</span>
              </div>
              {householdMembers ? (
                <div className="pure-u-1 pure-u-lg-1-2">
                  {householdMembers.map((member) =>
                    member.granteeFlag === TextProps.VALUE_TRUE
                      ? this.renderMemberDetails(member)
                      : null
                  )}
                </div>
              ) : null}
            </div>
          </li>
          {householdMembers ? (
            <li>
              <div className="pure-g margin-bottom">
                <div className="pure-u-1 pure-u-lg-1-2">
                  <span className="text--gray">
                    {info.membersOfHousehold} ({householdMembers.length - 1})
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-1-2">
                  {householdMembers.map((member) =>
                    member.granteeFlag !== TextProps.VALUE_TRUE
                      ? this.renderMemberDetails(member)
                      : null
                  )}
                </div>
              </div>
            </li>
          ) : null}
          {benefitDetail.caseManagerName ? (
            <li>
              <div className="pure-g margin-bottom">
                <div className="pure-u-1 pure-u-lg-1-2">
                  <span className="text--gray">{info.caseManager}</span>
                </div>
                <div className="pure-u-1 pure-u-lg-1-2">
                  <p className="margin-none">
                    <strong>
                      {formatters.fullNameProvider(
                        benefitDetail.caseManagerName
                      )}
                    </strong>
                    {phoneNumberVal}
                  </p>
                </div>
              </div>
            </li>
          ) : null}
        </ul>
        <p className="consumer__heading text--uppercase truncate-text">
          {info.historyOfBenefits}
        </p>
        <SearchFilters
          search={
            <p className="consumer__heading text--uppercase truncate-text">
              {info.historyOfBenefits}
            </p>
          }
        >
          <Filter
            data={FiltersData.FILTER_DATE}
            className="pure-u-3-5 dta-filter--no-grow"
          />
        </SearchFilters>
        {benefitHistory !== undefined && benefitHistory.length > 0 ? (
          <ListWrapper
            total={100}
            doLoadMore={this._doLoadMore.bind(
              this,
              "benefitsList",
              benefitHistory
            )}
            items={benefitHistory}
          />
        ) : (
          <ListWrapper
            total={100}
            doLoadMore={this._doLoadMore.bind(this, "benefitsList", [])}
            items={[]}
          />
        )}
        {modalHelperFunction.dtaHelperModal(
          (instance) => (this.RIVLModal = instance),
          openModal,
          this.closeRIVLModal,
          "rivl-modal req-income-container",
          RIVLModalOpen ? info.VerificationLetter : info.buttonEbtCard,
          this.state.RIVLError,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          headerText,
          <main className="dta-modal__body pad-all">
            {!RIVLModalOpen &&(           
            <div>
              <p className="rivl-modal__message">
                <strong>{consumerConst(this.props.selectedLanguage).ebtCardHeader}</strong>
              </p>
              <p>{consumerConst(this.props.selectedLanguage).ebtCardUpdateContact}.</p>
              <p>
              {consumerConst(this.props.selectedLanguage).ebtCardInfo}
              </p>
              <p className="dta-ebt-remainder">
              {consumerConst(this.props.selectedLanguage).ebtCardRemainder} </p>
              <button
                className="dta-button dta-button--large dta-button--primary dta-button--ebtsubmit dta-okbutton"
                onClick={this.closeRIVLModal}
              >
                {consumerConst(this.props.selectedLanguage).okButton}
              </button>
            </div> )}
            {RIVLModalOpen && !this.state.showEbtCardAlert &&
            
              (<div>
                { mailAddressIncomOrEbt }
            {requestIncomeAdress !== undefined &&
              Object.keys(requestIncomeAdress).length > 0
              ? requestIncomeAdress.map((requestIncomeAddress) => {
                return (
                  <ul className="list--unstyled">
                    <DTAEditableFields
                      key={requestIncomeAddress[SharedProps.ID]}
                      editingLabel={
                        requestIncomeAddress[SharedProps.EDITING_LABEL]
                      }
                      columnClass="pure-u-1 pure-u-lg-1-2"
                      agencyId={agencyId}
                      columnLabel={TextProps.VALUE_TRUE}
                      fields={requestIncomeAddress[SharedProps.FIELDS]}
                      onChange={this.handleMailingAddressChange}
                      onEditStateChange={this.onMailingAddressEditStateChange}
                      onEditingIncomeverification={
                        this.onMailingAddressEditStateChange
                      }
                      controlLabel={TextProps.VALUE_FALSE}
                      benefitLabel={TextProps.VALUE_TRUE}
                      requestEbt={
                        RIVLModalOpen
                          ? TextProps.VALUE_FALSE
                          : TextProps.VALUE_TRUE
                      }
                    />
                  </ul>
                );
              })
              : requestIncomeAdress !== undefined &&
              requestIncomeAdress.map((requestIncomeAddress) => {
                return (
                  <ul className="list--unstyled">
                    <DTAEditableFields
                      key={requestIncomeAddress[SharedProps.ID]}
                      editingLabel={
                        requestIncomeAddress[SharedProps.EDITING_LABEL]
                      }
                      columnClass="pure-u-1 pure-u-lg-1-2"
                      agencyId={agencyId}
                      columnLabel={TextProps.VALUE_TRUE}
                      fields={requestIncomeAddress[SharedProps.FIELDS]}
                      onChange={this.handleMailingAddressChange}
                      onEditStateChange={this.onMailingAddressEditStateChange}
                      onEditingIncomeverification={
                        this.onMailingAddressEditStateChange
                      }
                      controlLabel={TextProps.VALUE_FALSE}
                      benefitLabel={TextProps.VALUE_TRUE}
                    />
                  </ul>
                );
              })}
            {RIVLModalOpen ? (
              <div className="dta-modal__footer dta-modal__footer--inline-buttons">
                {isEditMailingAddrCloseRIVLModal}
                {isEditMailingAddrSendRIVL}
              </div>
            ) : (
              <div className="dta-modal__footer dta-modal__footer--ebt">
                <p>
                  <strong>{info.updateMailingAddressTextCaseManager}</strong>
                </p>
                <div className="dta-modal__footer--inline-buttons request-ebt-footer">
                  <button
                    type="button"
                    className="dta-button dta-button--outline-primary dta-button--large"
                    onClick={this.closeRIVLModal}
                  >
                    {" "}
                    {documentupload.cancelButton}{" "}
                  </button>
                  <button
                    className="dta-button dta-button--large dta-button--primary dta-button--ebtsubmit"
                    onClick={this.sendREC}
                  >
                    {info.submit}
                  </button>
                </div>
              </div>
            )}</div>)
            }
          </main>,
          this.state.isEditingMailingAddress
        )}

        {/* Display an alert when not eligible for an EBT card replacement */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.showEbtCardAlert,
          this.closeRIVLModal,
          "rivl-modal req-income-container",
          info.EbtCardRequestLabel,
          null,
          null,
          TextProps.VALUE_FALSE,
          info.EbtCardRequestLabel,
          this.showEbtCardRender(
            isEligibleForEBTReplacement,
            ebtCardAnticipatedDeliveryDate,
            ebtCardRequestDate
          )
        )}
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showBenefitListInfo: showBenefitListInfo,
      requestIncomeVerificationAnction: requestIncomeVerificationAnction,
      requestEbtCardAction: requestEbtCardAction,
      getConsumerBenefitDetails: getConsumerBenefitDetails,
      consumerBenefitDetailsSuccess: consumerBenefitDetailsSuccess,
      isPopupClosed:isPopupClosed,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerBenefitDetail);
