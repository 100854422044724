import React from "react";

import { addStringIf } from "./utilities/controls";
import { termsLanguageTranslation } from "../appText/termsinterimText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as appConfigConstants from "../constants/appConfigConstants";
/*
  Terms

  # Purpose:
  Encapsulates the markup for the terms and conditions

  # Props:
  id: optional id to provide to the component
  className: optional className to provide to the component

  # Example:
  <Terms id="example-id" className="example-class" />

 */
const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export default function TermsInterim({ id, className }) {
  return (
    <div id={id} className={addStringIf(className, "dta-terms")}>
      <br />
      <strong>{termsLanguageTranslation(selectedLanguage).headerLabel}</strong>
      <p>{termsLanguageTranslation(selectedLanguage).certifyContents}</p>
      <strong>
        {termsLanguageTranslation(selectedLanguage).understandDta}
      </strong>
      <ul>
        <li>
          {/* The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social
          Security Number (SSN) and the SSN of each household member I apply for. DHR uses this
          information to determine my household’s eligibility for SNAP. DHR verifies this
          information through computer matching programs. I understand that DHR uses it to monitor
          compliance with program regulations. */}
          {termsLanguageTranslation(selectedLanguage).foodNutritionact}
        </li>
        <li>
          {
            /*   	Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR) 
          and recertification with the exception of: */
            termsLanguageTranslation(selectedLanguage).reportingRulesDtachanges
          }

          <ul>
            <li>
              {
                /* If my household’s income exceeds the gross income threshold */
                termsLanguageTranslation(selectedLanguage).incomeExceeds
              }
            </li>
            <li>
              {
                /*     If I am under the able-bodied adult without dependents (ABAWD) work requirements and
              my work hours drop below 20 hours weekly */
                termsLanguageTranslation(selectedLanguage).workRequirementlabel
              }
            </li>
          </ul>
        </li>
        <li>
          {
            /* If DHR receives verified information about my household, my benefit amount may change */
            termsLanguageTranslation(selectedLanguage).verifiedInfomation
          }
        </li>
        <li>
          {/* If I am not under the SNAP Simplified Reporting rules or Transitional Benefits Alternative (TBA) rules, 
        I must report to DHR changes to my household that may affect our eligibility.
        I understand that I must report these changes to DHR in person, in writing or by phone 
        <b>within 10 days of the change</b>. 
        For example, you must report changes in your household’s income, size, or address. */}
          {termsLanguageTranslation(selectedLanguage).tbaRulesone}{" "}
          <b>{termsLanguageTranslation(selectedLanguage).tbaRuletwo} </b>{" "}
          {termsLanguageTranslation(selectedLanguage).tbaExample}
        </li>
        <li>
          {
            /* I have a right to speak to a supervisor if DHR finds me ineligible for emergency SNAP 
        benefits and I disagree. I may speak to a supervisor if I am eligible for emergency SNAP 
        benefits but do not get my benefits by the seventh calendar day after I applied for SNAP. 
        I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my 
        Electronic Benefit Transfer (EBT) card by the seventh calendar day after I applied for SNAP. */
            termsLanguageTranslation(selectedLanguage).findmeIneligible
          }
        </li>
        <li>
          {
            /* I may receive more SNAP benefits if I report and give verification to DHR of: */
            termsLanguageTranslation(selectedLanguage).verificationtoDta
          }
          <ul>
            <li>
              {
                /* child or other dependent care costs, shelter costs, and/or utility costs */
                termsLanguageTranslation(selectedLanguage).childorOtherTitile
              }
            </li>
            <li>
              {
                /* legally-obligated child support to a nonhousehold member */
                termsLanguageTranslation(selectedLanguage).legallyObligatedlabel
              }
            </li>
          </ul>
        </li>
        <li>
          {
            /* If I am 60 years or older or if I am disabled and I pay for medical costs, I can report
          and give verification of these costs to DHR. This may make me eligible for a deduction and
          increase my SNAP benefits. */

            termsLanguageTranslation(selectedLanguage).disabledMedicalcost
          }
        </li>
        <li>
          {
            /* Unless they meet an exemption, all SNAP recipients between the ages of 16 and 59 are work
          registered and subject to General SNAP Work Requirements. SNAP recipients between the ages
          of 18 and 49 may also be subject to the ABAWD Work Program requirements. DHR will inform
          nonexempt household members of the work requirements. DHR will inform nonexempt household
          members of exceptions and penalties for noncompliance. */
            termsLanguageTranslation(selectedLanguage).meetExemptionlabel
          }
        </li>
        <li>
          {
            /* Most SNAP recipients may voluntarily participate in education and employment training
          services through the SNAP Path to Work program. DHR will give referrals to the SNAP Path
          to Work program if appropriate. */
            termsLanguageTranslation(selectedLanguage)
              .voluntarilyParticipatelabel
          }
        </li>
        <li>
          {
            /*
          DHR may also share the names and contact information of SNAP recipients with SNAP Path to
          Work providers for recruitment purposes. I understand that members of my household may be
          contacted by DHR SNAP Path to Work specialists or contracted providers to explore SNAP
          Path to Work participation options. For more information about the SNAP Path to Work
         program, visit */
            termsLanguageTranslation(selectedLanguage).dtaNamescontact
          }
          <a
            href="https://www.snappathtowork.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.snappathtowork.org
          </a>
          .
        </li>
      </ul>
      <strong>
        {
          /*   I understand that the information I give with my application will be subject to verification
        to determine if it is TextProps.VALUE_TRUE. If any information is TextProps.VALUE_FALSE, DHR may deny my SNAP benefits. I
        may also be subject to criminal prosecution for providing TextProps.VALUE_FALSE information. */
          termsLanguageTranslation(selectedLanguage).subjectToverification
        }
      </strong>
      <p>
        {
          /* I understand that by signing this application I give DHR permission to verify and
        investigate the information I give that relates to my eligibility for SNAP benefits,
        including permission to: */
          termsLanguageTranslation(selectedLanguage).signingApplication
        }
      </p>
      <ul>
        <li>
          {
            /* Get documents to prove information on this application with other state agencies, federal
          agencies, local housing authorities, out-of-state welfare departments, financial
          institutions and from Equifax Workforce Solutions. I also give permission to these
          agencies to give DHR information about my household that concerns my SNAP benefits. */
            termsLanguageTranslation(selectedLanguage).proveInformation
          }
        </li>
        <li>
          {
            /* If applicable, verify my immigration status through the United States Citizenship and
          Immigration Services (USCIS). I understand that DHR may check information from my SNAP
          application with USCIS. Any information received from USCIS may affect my household’s
          eligibility and amount of SNAP benefits. */
            termsLanguageTranslation(selectedLanguage).immigrationStatusLabel
          }
        </li>
        <li>
          {
            /* Share information about me and my dependents under age 19 with the Department of
          Elementary and Secondary Education (DESE). DESE will certify my dependents for school
          breakfast and lunch programs. */
            termsLanguageTranslation(selectedLanguage).secondaryEducation
          }
        </li>
        <li>
          {
            /* Share information about me, my dependents under age 5 and anyone pregnant in my household
          with the Department of Public Health (DPH). DPH refers these individuals to the Women,
          Infants and Children (WIC) Program for nutrition services. */
            termsLanguageTranslation(selectedLanguage).publicHealth
          }
        </li>
        <li>
          {
            /* Share information, along with the Alabama Executive Office of Health and Human
          Services, about my eligibility for SNAP with electric companies, gas companies and
          eligible phone and cable carriers to certify my eligibility for discount utility rates. */
            termsLanguageTranslation(selectedLanguage).executiveOfficeHealth
          }
        </li>
        <li>
          {
            /*           
          Share my information with the Department of Housing and Community Development (DHCD) for
          the purpose of enrolling me in the Heat & Eat Program. */
            termsLanguageTranslation(selectedLanguage).housingCommuntiy
          }
        </li>
      </ul>
      <p>
        {
          /* DHR may deny, stop or lower my benefits based on information from Equifax Workforce
        Solutions. I have the right to a free copy of my report from Equifax if I request it within
        60 days of DHR’s decision. I have the right to question the accuracy or completeness of the
        information in my report. I may contact Equifax at: Equifax Workforce Solutions, 11432
        Lackland Road, St. Louis, MO 63146,  */
          termsLanguageTranslation(selectedLanguage).lowerBenefitslabel
        }
        <a href="tel:1-800-996-7566">1-800-996-7566</a> 
        {termsLanguageTranslation(selectedLanguage).tollFreeLabel}
      </p>
      <p>
        {
          /* I understand that I will get a copy of the “Your Right to Know” brochure and the SNAP
        Program brochure. I will read or have read to me the brochures and I must understand their
        contents and my rights and responsibilities. If I have any questions about the brochures or
        any of this information, I will contact DHR. If I have trouble reading or understanding any
        of this information, I will contact DHR. DHR can be reached at:*/
          termsLanguageTranslation(selectedLanguage).rightoKnowbrochure
        }{" "}
        <a href="tel:1-877-382-2363">1-877-382-2363</a>.
      </p>
      <p>
        {
          /*  I swear that all members of my SNAP household requesting SNAP benefits are either U.S.
        citizens or lawfully residing noncitizens. */
          termsLanguageTranslation(selectedLanguage).lawfullyResiding
        }
      </p>
      <strong>
        {
          /*  Right to Register to Vote */
          termsLanguageTranslation(selectedLanguage).registerVoteTitle
        }
      </strong>
      <p>
        {
          /*    I understand I have the right to register to vote at DHR. I understand that DHR will help me
        fill out the voter registration application form if I want help. I am allowed to fill out
        the voter registration application form in private. */
          termsLanguageTranslation(selectedLanguage).registerVoteFirstLabel
        }
      </p>
      <p>
        {
          /*  I understand that applying to register or declining to register to vote will not affect the
        amount of benefits I get from DHR. */
          termsLanguageTranslation(selectedLanguage).registerVoteSecondLabel
        }
      </p>
      <strong>
        {termsLanguageTranslation(selectedLanguage).penaltyWarningTitle}
      </strong>
      <p>
        {
          /* I understand that if I or any member of my SNAP household intentionally breaks any of the
        rules listed below, that person will not be eligible for SNAP for <i>one year</i> after the
        first violation, <i>two years</i> after the second violation and <i>forever</i> after the
        third violation. That person may also be fined up to ,000, imprisoned up to 20 years or
        both. S/he may also be subject to prosecution under other applicable Federal and State laws.
        These rules are: */
          termsLanguageTranslation(selectedLanguage).penaltyWarningContent
        }
      </p>
      <ul>
        <li>
          {
            /*Do not give TextProps.VALUE_FALSE information or hide information to get SNAP benefits.*/ termsLanguageTranslation(
              selectedLanguage
            ).penaltyWarningOne
          }
        </li>
        <li>
          {
            /*Do not trade or sell SNAP benefits.*/ termsLanguageTranslation(
              selectedLanguage
            ).penaltyWarningTwo
          }
        </li>
        <li>
          {
            /*Do not alter EBT cards to get SNAP benefits you are not eligible to get.*/ termsLanguageTranslation(
              selectedLanguage
            ).penaltyWarningThree
          }
        </li>
        <li>
          {
            /*  Do not use SNAP benefits to buy ineligible items, such as alcoholic drinks and tobacco. */ termsLanguageTranslation(
              selectedLanguage
            ).penaltyWarningFour
          }
        </li>
        <li>
          {
            /* Do not use someone else’s SNAP benefits or EBT card, unless you are an authorized
          representative. */
            termsLanguageTranslation(selectedLanguage).penaltyWarningFive
          }
        </li>
      </ul>
      <p>
        {
          /* I also understand the following penalties: */ termsLanguageTranslation(
            selectedLanguage
          ).penaltiesTitle
        }
      </p>
      <ul>
        <li>
          {
            /*  Individuals who commit a <b>cash program</b> Intentional Program Violation (IPV) will be
          ineligible for SNAP for the same period the individual is ineligible from cash assistance. */
            termsLanguageTranslation(selectedLanguage).individualLabel
          }{" "}
          <b> {termsLanguageTranslation(selectedLanguage).cashProgram} </b>{" "}
          {termsLanguageTranslation(selectedLanguage).internationalProgram}
        </li>
        <li>
          {/*          Individuals who make a fraudulent statement about their identity or residency to get
          multiple SNAP benefits <i>at the same time</i> will be ineligible for SNAP for{' '} */}
          {termsLanguageTranslation(selectedLanguage).penalitiesTwo}
          <i>
            <b> {termsLanguageTranslation(selectedLanguage).tenYearLabel}</b>
          </i>
          .
        </li>
        <li>
          {/*  Individuals who trade (buy or sell) SNAP benefits for a controlled substance/illegal
          drug(s), will be ineligible for SNAP for  */}
          {termsLanguageTranslation(selectedLanguage).penalitiesThree}
          <i>
            <b>{termsLanguageTranslation(selectedLanguage).twoYearLabel}</b>
          </i>
           {/* for the first finding, and */}{" "}
          {termsLanguageTranslation(selectedLanguage).penalitiesThreeMiddle} 
          <i>
            <b>{termsLanguageTranslation(selectedLanguage).foreverText}</b>
          </i>
           {termsLanguageTranslation(selectedLanguage).penalitiesThreeLast}
        </li>
        <li>
          {/*  Individuals who trade (buy or sell) SNAP benefits for firearms, ammunition or explosives,
          will be ineligible for SNAP */}
          {termsLanguageTranslation(selectedLanguage).penalitiesFour} 
          <i>
            <b>{termsLanguageTranslation(selectedLanguage).foreverText}</b>
          </i>
          .
        </li>
        <li>
          {/*  Individuals who trade (buy or sell) SNAP benefits having a value of $500 or more, will be
          ineligible for SNAP  */}
          {termsLanguageTranslation(selectedLanguage).penalitiesFive}
          <i>
            <b> {termsLanguageTranslation(selectedLanguage).foreverText}</b>
          </i>
          .
        </li>
        <li>
          {/*  The State may pursue an IPV against an individual who makes an offer to sell SNAP benefits
          or an EBT card online or in person. */}
          {termsLanguageTranslation(selectedLanguage).penalitiesSix}
        </li>
        <li>
          {/*   Individuals who are fleeing to avoid prosecution, custody or confinement after conviction
          for a felony, or are violating probation or parole, are <i>ineligible</i> for SNAP. */}
          {termsLanguageTranslation(selectedLanguage).penalitiesSeven}
        </li>
        <li>
          {/*       Paying for food purchased on credit is not allowed and can result in disqualification from
          SNAP. */}
          {termsLanguageTranslation(selectedLanguage).penalitiesEight}
        </li>
        <li>
          {/*   Individuals may not buy products with SNAP benefits with the intent to discard the
          contents and return containers for cash. */}
          {termsLanguageTranslation(selectedLanguage).penaltiesNine}
        </li>
      </ul>
      <strong>
        {
          /* Right to an Interpreter */ termsLanguageTranslation(
            selectedLanguage
          ).interpreterHeader
        }
      </strong>
      <p>
        {
          /* I understand that I have a right to an interpreter provided by DHR if no adult in my SNAP
        household is able to speak or understand English. I also understand that I can get an
        interpreter for any DHR fair hearing or bring one of my own. If I need an interpreter for a
        hearing, I must call the Division of Hearings at least one week before the hearing date. */
          termsLanguageTranslation(selectedLanguage).interpreterContent
        }
      </p>
      <strong>
        {
          /*  Nondiscrimination Statement    */
          termsLanguageTranslation(selectedLanguage).nonDiscriminationHeader
        }
      </strong>
      <p>
        {
          /*  In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil
        rights regulations and policies, the USDA, its Agencies, offices, and employees, and
        institutions participating in or administering USDA programs are prohibited from
        discriminating based on race, color, national origin, sex, religious creed, disability, age,
        political beliefs, or reprisal or retaliation for prior civil rights activity in any program
        or activity conducted or funded by USDA. */
          termsLanguageTranslation(selectedLanguage).nonDiscriminationContent
        }
      </p>
      <p>
        {
          /*         Persons with disabilities who require alternative means of communication for program
        information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should
        contact the Agency (State or local) where they applied for benefits. Individuals who are
        deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay
        Service at */
          termsLanguageTranslation(selectedLanguage).personDisabilities
        }
         <a href="tel:(800) 877-8339">(800) 877-8339</a>.
        {
          /*  Additionally, program
        information may be made available in languages other than English. */
          termsLanguageTranslation(selectedLanguage).programInformation
        }
      </p>
      <p>
        {
          /*    To file a program complaint of discrimination, complete the USDA Program Discrimination
        Complaint Form, (AD-3027) found online at: */
          termsLanguageTranslation(selectedLanguage).programComplaint
        }
         
        <a
          href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="dta-link--wrap"
        >
          {`${appConfigConstants.programComplainLink},`}
        </a>
        {
          /* and at any USDA office, or write a letter addressed to USDA and provide in the letter
        all of the information requested in the form. To request a copy of the complaint form, call */
          termsLanguageTranslation(selectedLanguage).usdaOffice
        }{" "}
        <a href="tel:(866) 632-9992">(866) 632-9992</a>.
        {
          /* Submit your completed form or letter to
        USDA by: */
          termsLanguageTranslation(selectedLanguage).sumbitYourLabel
        }
      </p>
      <ol>
        <li>
          {
            /* mail: U.S. Department of Agriculture  */
            termsLanguageTranslation(selectedLanguage).mailAddress
          }
          <br />{" "}
          {
            /* Office of the Assistant Secretary for Civil
          Rights 
           */
            termsLanguageTranslation(selectedLanguage).addressOne
          }
          <br />
          {
            /* 1400 Independence Avenue, SW  */
            termsLanguageTranslation(selectedLanguage).addressVenue
          }
          <br />
          {
            /*  Washington, D.C. 20250-9410; */
            termsLanguageTranslation(selectedLanguage).addressState
          }
        </li>
        <li>
          {/*  fax: */ termsLanguageTranslation(selectedLanguage).fax} 
          <a href="tel:(202) 690-7442">(202) 690-7442</a>;{" "}
          {/* or */ termsLanguageTranslation(selectedLanguage).orLabel}
        </li>
        <li>
          {/* email: */ termsLanguageTranslation(selectedLanguage).eamilTitle} 
          <a href="mailto:program.intake@usda.gov">program.intake@usda.gov</a>.
        </li>
      </ol>
      <p>
        {
          /*     This institution is an equal opportunity provider. */
          termsLanguageTranslation(selectedLanguage).thisInstution
        }
      </p>
    </div>
  );
}
