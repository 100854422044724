import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import { getAudit } from "../../utils/constants/audit";
import LanguageSelect from "../../redux/language/languageSelect";
import TermsInterim from "../../utils/components/termsinterim";
import * as OptionsProps from "../../utils/constants/options";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as Props from "../../utils/components/shared";
import Checkbox from "../../utils/components/checkbox";
import DTAInputSubmit from "../../utils/components/dtaInputSubmit";
import DTADropdown from "../../utils/components/dtaDropdown";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import * as TextProps from "../../utils/constants/text";
// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import InterimSection from "./components/recertCovidSection";
import * as Types from "../../utils/constants/types";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import {
  IncomeSectionTemplate,
  unearnedIncomeSectionTemplate,
} from "./recertCovidMapData";
import * as languageConstants from "../../utils/constants/constants";
import {
  interimReportPageLabels,
  interimWarningPopupMLanguageLabels,
  incomeDynamicMultilanguageLabels,
  unearnedIncomeDynamicMultilanguageLabels,
} from "./recertCovidText";
import * as formatters from "../../utils/components/utilities/formatters";
// Redux implementations
import { connect } from "react-redux";
//azure
import { withMsal } from "@azure/msal-react";

import {
  addContactInfoSubSection,
  setDataChangeStatus,
  setHouseholdMembers,
  setContactInfo,
  addStudentToList,
  applyIRSanpQuestionsSections,
  appendIRHouseHoldMembers,
  clearHouseHoldMembers,
  appendIRIncomeDetails,
  appendUIIncomeDetails,
  clearMemberIncome,
  clearMemberUIIncome,
  appendIRExpenseDetails,
  updateResolveQuestionsData,
  downloadIRPDFData,
  updateCurrentSectionData,
  setEarnedIncomeChangeStatus,
  setUnearnedIncomeChangeStatus,
  setInterimReportResponseObj,
  updateHouseholdTextChanges,
  setChildSupportData,
  snapRefreshQuestionsSections,
  addHouseholdAddSectionToList,
  displayPDFData,
  postInterimReportData,
} from "../../redux/recertCovid/recertCovidActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  filterQuestionsBySelection,
  toValidateInputErros,
  bindAnswers,
} from "./recertCovidHelper";
import {
  getFrequencyCode,
  getFrequency,
} from "../../utils/components/utilities/utils";
import { getMainSections } from "./components/recertCovidSummary";
//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import StartForm from "../../utils/components/startForm/covidRecertStartForm";
import ContactInfo from "../../utils/components/contactInfo";
import HouseholdInfo from "../../utils/components/householdInfo";
import EarnedIncome from "./components/earnedIncome";
import UnearnedIncome from "./components/unearnedIncome";
import ChildSupportExpenses from "./components/childSupportExpense";
import SubmitSummary from "./components/submitSummary";
import { otherInfoCodeMapper } from "./otherInfoMapData";
import { unearnedIncomeCodeMapper } from "./unearnedIncomeMapData";
import { earnedIncomeCodeMapper } from "./earnedIncomeMapData";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import Icon from "../../utils/components/dtaIcon";
import DocumentTitle from "react-document-title";
import * as config from "../../utils/constants/appConfigConstants";

var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);
//end code for google analytics
var rights_en_url =
  require("../../utils/constants/appConfigConstants").rights_en_url;
var rights_es_url =
  require("../../utils/constants/appConfigConstants").rights_es_url;
var rights_pt_url =
  require("../../utils/constants/appConfigConstants").rights_pt_url;
var rights_vi_url =
  require("../../utils/constants/appConfigConstants").rights_vi_url;
var rights_zh_url =
  require("../../utils/constants/appConfigConstants").rights_zh_url;

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class CovidRecert extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.downloadApplicationAsPdf = this.downloadApplicationAsPdf.bind(this);
    this.downloadIRPDFData = this.downloadIRPDFData.bind(this);
    this.updateHouseHoldIncomeDetails =
      this.updateHouseHoldIncomeDetails.bind(this);
    this.onIncomeSubsectionRender = this.onIncomeSubsectionRender.bind(this);
    this.onUnearnedIncomeSubsectionRender =
      this.onUnearnedIncomeSubsectionRender.bind(this);
    this.onIncomeSubSectionClean = this.onIncomeSubSectionClean.bind(this);
    this.onUnearnedIncomeSubSectionClean =
      this.onUnearnedIncomeSubSectionClean.bind(this);
    this.incomefilterQuestionsBySelection =
      this.incomefilterQuestionsBySelection.bind(this);
    this.incomeSubSections = [];
    this.unearnedIncomeSubSections = [];
    this.incomeSummary = {};
    this.existingtypes = [
      "SSI",
      "RSDI",
      "UNEMPL",
      "CHSDOR",
      "EMPPEN",
      "VETBPR",
      "RENTAL",
      "WRKCMP",
      "RENTAL",
      "OTHER",
    ];
    this.existingEItypes = ["WAGES", "SELEMP", "FEDWRK"];

    this.state = {
      referenceNumber: null,
      docReferenceNumber: null,
      agencyID: null,
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      houseHoldSubMemberTemplate: null,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: null,
      incomeSubSectionCleared: TextProps.VALUE_FALSE,
      unearnedIncomeMemberTemplate: null,
      unearnedIncomeSummaryTemplate: null,
      unearnedIncomeSubSectionCleared: TextProps.VALUE_FALSE,
      incomeValuesPopulated: TextProps.VALUE_FALSE,
      incomeUIValuesPopulated: TextProps.VALUE_FALSE,
      unearnedIncomeValuesPopulated: TextProps.VALUE_FALSE,
      houseMemberLabels: [],
      dependentsParentsLabels: [],
      dependentsChildsLabels: [],
      dependentsCareCostsMemberLabels: [],
      dependentCareCostsTemplate: null,
      dependentCareCostsSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseMemberTemplate: null,
      kidsAdultExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseMemberTemplate: null,
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE,
      c: TextProps.VALUE_FALSE,
      rights: 0,
      signature: "",
      AnswerObject: [],
      oldHouseHoldSizeValue: 0,
      //For contact info screens
      email: "John.doe@example.com",
      phoneNumber: "(413) 444-4444",
      emailOptIn: TextProps.VALUE_TRUE,
      textOptIn: TextProps.VALUE_FALSE,
      editPhone: TextProps.VALUE_FALSE,
      editEmail: TextProps.VALUE_FALSE,
      editAddress: TextProps.VALUE_FALSE,
      sameMailingAddress: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      //For household info screens
      addHouseholdMember: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      requestBody: {},
      respData: null,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      houseHoldMembers: [
        {
          id: 1,
          firstName: "John",
          lastName: "Smith",
          dob: "16.Aug.1982",
          isGrantee: TextProps.VALUE_TRUE,
          citizenship: "Legal permanent resident",
        },
        {
          id: 2,
          firstName: "Mary",
          lastName: "Smith",
          dob: "04.July.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Immigrant worker (H1B)",
        },
        {
          id: 3,
          firstName: "Bob",
          lastName: "Smith",
          dob: "01.Feb.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Noncitizen",
        },
      ],
      earnedIncomeChangeFlag: TextProps.VALUE_FALSE,
      unearnedIncomeChanged: TextProps.VALUE_FALSE,
    };
  }

  componentWillMount = () => {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var recertFlag, benifitListInfo;
    if (
      userObject !== null &&
      userObject !== undefined &&
      userDetails !== null &&
      userDetails !== undefined
    ) {
      benifitListInfo = userDetails.benefitInfo;
      if (userObject && benifitListInfo) {
        for (let listType of benifitListInfo) {
          if (
            listType.alertMessages.SNAP_FLAG &&
            listType.alertMessages.SNAP_FLAG === TextProps.VALUE_YES &&
            listType.alertMessages.RECERT_TYPE === "COVIDRECERT" &&
            this.props.covidRecert.responseObject
          ) {
            recertFlag = TextProps.VALUE_YES;
          }
        }
        var userRole = userObject.roleList[1]
          ? userObject.roleList[1].roleName
          : userObject.roleList[0].roleName;
      }
    }

    if (
      recertFlag === TextProps.VALUE_YES &&
      (userRole === roleProps.CONSUMER ||
        userRole === roleProps.PROVIDER ||
        userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
        userRole ===
          roleProps.PROVIDER_ROLE_DCPVSG) /*&& this.props.covidRecert.responseObject */
    ) {
      //continue
    } else {
      this.props.gotoConsumer();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    let check =
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      this.props.match.params.webAppId !== undefined;
    if (check === TextProps.VALUE_TRUE) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }

    if (
      nextState.sections[0] !== undefined &&
      nextState.sections[0].isSubmitInitiated &&
      nextState.sections[0].isSubmitted
    ) {
      return this.state;
    }
    return check;
  }

  componentWillReceiveProps() {
    if (this.props.covidRecert.sections.length > 0) {
      this.setState(
        {
          sections: this.props.covidRecert.sections,
          respData: this.props.covidRecert.responseObject,
        },
        () => {
          if (this.state.renderedSections.length < 1) {
            this.setState({
              renderedSections: [this._getSection(pageConfig.activeSectionId)],
              submitFormId: this.nextUniqueId(),
            });
          }
        }
      );
    }
  }

  // redux apply sections loading
  async componentDidMount() {
    await this.props.applyIRSanpQuestionsSections();
    //Remove other info section if no question exist
    if (
      this.props.covidRecert.responseObject &&
      this.props.covidRecert.responseObject.otherInformation
    ) {
      let otherInfo =
        this.props.covidRecert.responseObject.otherInformation.workRequirement;
      if (otherInfo === undefined || (otherInfo && otherInfo.length === 0)) {
        if (
          this.props.covidRecert.sections.length > 0 &&
          this.props.covidRecert.sections[6].id === "Other information"
        ) {
          this.props.covidRecert.sections.splice(6, 1);
        }
      }
    }
    this.setState({
      earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
      unearnedIncomeChanged: this.state.unearnedIncomeChanged,
    });
  }

  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      helperFunction._animatedScroll();
    }
  }

  onSectionChange = (
    section,
    primaryButtonClick = TextProps.VALUE_FALSE,
    event = null
  ) => {
    if (event) {
      event.preventDefault();
    }
    if (primaryButtonClick === TextProps.VALUE_TRUE) {
      //Check if user has changed any info on any section
      let dataChange = this.checkForDataChange(this.state.activeSectionId);
      //If the user has not changed any info then simply return TextProps.VALUE_FALSE to stay on the same section
      if (dataChange === TextProps.VALUE_FALSE) {
        this.forceUpdate();
        return;
      }
    }
    if (section.disabled === TextProps.VALUE_TRUE) return;
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });
      }
    );
  };

  //Get Language and Appened to Answer
  setLanguageInScreen = () => {
    let currentLanguageCode = this.props.selectedLanguage;
    let currentLanguage;
    let selectedAnswer =
      this.props.covidRecert.sections[1].subsections[2].questions[1].Answer;
    if (selectedAnswer === undefined) {
      switch (currentLanguageCode) {
        case Types.ENGLISH:
          currentLanguage = "English";
          break;
        case Types.SPANISH:
          currentLanguage = "Spanish";
          break;
        case Types.PORTUGUESE:
          currentLanguage = "Portuguese";

          break;
        case Types.CHINESE:
          currentLanguage = "Chinese";

          break;
        case Types.VIETNAMESE:
          currentLanguage = "Vietnamese";

          break;
        default:
          currentLanguage = "English";
          break;
      }

      this.props.covidRecert.sections[1].subsections[2].questions[1].Answer =
        currentLanguage;
      this.props.covidRecert.sections[1].subsections[2].questions[1].targetValue =
        currentLanguage;
    }
  };

  redirectConsumerHome = () => {
    this.props.gotoConsumer();
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  _dynamicSectionIdMatch = (activeId) => {
    switch (activeId) {
      case "my-household":
        return TextProps.VALUE_TRUE;

      case "income":
        return TextProps.VALUE_TRUE;
      case "unearned-income":
        return TextProps.VALUE_TRUE;
      default:
        return TextProps.VALUE_FALSE;
    }
  };

  // Section Link click continue
  onSectionLinkClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.covidRecert;
    const nextSectionID = getNextSection(activeId, sections);
    if (nextSectionID) {
      const nextSection = this._getSection(nextSectionID);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
    }
  };

  checkForDataChange = (activeId) => {
    if (!this.props.covidRecert.responseObject) return;
    const responseBody = _.cloneDeep(this.state.respData);
    let requestBody = _.cloneDeep(responseBody);

    //Get changes reported
    let reportedChanges = TextProps.VALUE_FALSE;
    //Get data change question selection
    let dataChangeSelection = TextProps.VALUE_FALSE;
    //Flag to identify what content to show in the warning popup
    let fullContentInPopup = TextProps.VALUE_FALSE;

    if (activeId === "contact-info") {
      if (this.props.covidRecert.contactInfoScreen.contactInfoChanged) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        //address change
        let respAddress = responseBody.clientAddress;
        let respMailAddress = null;
        let respResAddress = null;

        for (let respAddr of respAddress) {
          if (respAddr.typeCode === "RES") respResAddress = respAddr;
          else if (respAddr.typeCode === "MAIL") respMailAddress = respAddr;
        }

        let updatedAddress =
          this.props.covidRecert.contactInfoScreen.addressList.address;
        let updatedMailAddress = null;
        let updatedResAddress = null;

        for (let updatedAddr of updatedAddress) {
          if (
            updatedAddr.typeCode === "RES" &&
            updatedAddr.city &&
            updatedAddr.line1 &&
            updatedAddr.zip
          )
            updatedResAddress = updatedAddr;
          else if (updatedAddr.typeCode === "MAIL")
            updatedMailAddress = updatedAddr;
        }

        let finalAddressArray = [];
        if (respResAddress !== null && updatedResAddress !== null) {
          if (
            this.checkForAddressChange(respResAddress, updatedResAddress) ===
              "changed" ||
            this.checkForAddressChange(respResAddress, updatedResAddress) ===
              "removed"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.covidRecert.contactInfoScreen.addressList.homeless !==
            respResAddress.homelessFlag
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respResAddress === null && updatedResAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respResAddress !== null && updatedResAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        }

        //Mail Addre check
        if (respMailAddress !== null && updatedMailAddress !== null) {
          if (
            this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
              "changed" ||
            this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
              "removed"
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.covidRecert.contactInfoScreen.addressList.homeless !==
            respMailAddress.homelessFlag
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respMailAddress === null && updatedMailAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respMailAddress !== null && updatedMailAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        }

        requestBody.clientAddress = finalAddressArray;

        //Email change
        let respEmail = responseBody.clientEmail;
        let updatedEmail = this.props.covidRecert.contactInfoScreen;
        if (
          (!respEmail.emailAddress && updatedEmail.email) ||
          (respEmail.emailAddress && !updatedEmail.email) ||
          (respEmail.emailAddress &&
            respEmail.emailAddress !== updatedEmail.email) ||
          (respEmail.optInFlag !== undefined &&
            respEmail.optInFlag !== null &&
            respEmail.optInFlag !== updatedEmail.emailOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        //Phone change
        let respPhone = responseBody.clientPhone;
        let updatedPhone = this.props.covidRecert.contactInfoScreen;
        let phoneNumber = updatedPhone.phoneNumber
          ? updatedPhone.phoneNumber.split(" ").join("")
          : "";
        if (
          (!respPhone.phoneNumber && phoneNumber) ||
          (respPhone.phoneNumber && !phoneNumber) ||
          (respPhone.phoneNumber && respPhone.phoneNumber !== phoneNumber) ||
          (respPhone.textOptInFlag !== undefined &&
            respPhone.textOptInFlag !== null &&
            respPhone.textOptInFlag !== updatedPhone.textOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      this.setDataCodeChangeFlags();
    } else if (activeId === "my-household") {
      //HOUSEHOLD CHANGE
      if (this.props.covidRecert.householdMembers.membersListUpdated) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let newHouseholdList = [];

        for (let memberVal of this.props.covidRecert.householdMembers
          .membersList) {
          if (
            memberVal.allAnswers !== undefined ||
            (memberVal.dataChangeCode && memberVal.dataChangeCode === "removed")
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        requestBody.householdMembers = newHouseholdList;
      }
    } else if (activeId === "income-summary") {
      //EARNED INCOME CHANGE
      if (
        this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.earnedIncomeChanged &&
        this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.covidRecert.responseObject.earnedIncome
        );

        let incomeArrayNew = [];
        allIncome.map((member) => {
          let memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = this.getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.covidRecert.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.map((income) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.map((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  switch (incomeSub.code) {
                    case "new":
                      changeCode = "NEW";
                      break;
                    case "changed":
                      changeCode = "CHANGE";
                      break;
                    case "removed":
                      changeCode = "REMOVE";
                      break;
                    default:
                      break;
                  }
                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObjId = 0;
                  if (
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.id
                  ) {
                    incomeObjId = incomeSub.fullIncomeObj.id;
                  }
                  let memObjAgencyId = 0;
                  if (memberObj !== undefined && memberObj.agencyID) {
                    memObjAgencyId = memberObj.agencyID;
                  }
                  let incomeObj = {
                    id:
                      member.code === "new"
                        ? 0
                        : incomeObjId === undefined
                        ? 0
                        : incomeObjId,
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.agencyID
                        ? incomeSub.fullIncomeObj.agencyID
                        : memObjAgencyId,
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    employerName: incomeSub.employer,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
        }); // member ends

        incomeArrayExisting.map((existing) => {
          existing.dateOfBirth = this.convertDOBForDataPost(
            existing.dateOfBirth
          );

          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.typeCode === existing.typeCode
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (activeId === "unearned-income-summary") {
      //UNEARNED INCOME CHANGE
      if (
        this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.unearnedIncomeChanged !== undefined &&
        this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.covidRecert.responseObject.unearnedIncome
        );

        let incomeArrayNew = [];
        allIncome.map((member) => {
          let memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = this.getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.covidRecert.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.map((income, index) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.map((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  switch (incomeSub.code) {
                    case "new":
                      changeCode = "NEW";
                      break;
                    case "changed":
                      changeCode = "CHANGE";
                      break;
                    case "removed":
                      changeCode = "REMOVE";
                      break;
                    default:
                      break;
                  }

                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObjId = 0;
                  if (
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.id
                  ) {
                    incomeObjId = incomeSub.fullIncomeObj.id;
                  }
                  let memObjAgencyId = 0;
                  if (memberObj !== undefined && memberObj.agencyID) {
                    memObjAgencyId = memberObj.agencyID;
                  }
                  let incomeObj = {
                    id:
                      member.code === "new"
                        ? 0
                        : incomeObjId === undefined
                        ? 0
                        : incomeObjId,
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.agencyID
                        ? incomeSub.fullIncomeObj.agencyID
                        : memObjAgencyId,
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
        }); // member ends

        incomeArrayExisting.map((existing) => {
          existing.dateOfBirth = this.convertDOBForDataPost(
            existing.dateOfBirth
          );
          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.setID === existing.setID
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (activeId === "childsupport-expenses") {
      //CHILE EXP CHANGE
      if (
        this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.childExpScreen !== undefined &&
        this.props.covidRecert.childExpScreen.childExpChanged ===
          TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let childExpNew = _.cloneDeep(
          this.props.covidRecert.childExpScreen.tempChildExpArray
        );

        if (childExpNew.length > 0) {
          childExpNew.map((member) => {
            if (
              member.dataChangeCode !== undefined &&
              member.isComplete === TextProps.VALUE_TRUE &&
              (member.dataChangeCode === "new" ||
                member.dataChangeCode === "changed" ||
                member.dataChangeCode === "removed")
            )
              reportedChanges = TextProps.VALUE_TRUE;
          }); // member ends
        }
      }
    }

    if (
      reportedChanges === TextProps.VALUE_FALSE &&
      dataChangeSelection === TextProps.VALUE_TRUE
    ) {
      this.showWarningModal(fullContentInPopup);
      return TextProps.VALUE_FALSE;
    }
  };

  showWarningModal = (fullContentInPopup) => {
    this.setState({
      warningModalOpenStatus: TextProps.VALUE_TRUE,
      fullContentInPopup,
    });
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpenStatus: TextProps.VALUE_FALSE });
  };

  autoSelectNo = () => {
    const activeId = this.state.activeSectionId;
    if (activeId === "contact-info") {
      let contactInfoObj = this.props.covidRecert.contactInfoScreen;
      contactInfoObj.contactInfoChanged = TextProps.VALUE_FALSE;
      this.props.setContactInfo(contactInfoObj);
    } else if (activeId === "my-household") {
      let stateObj = this.props.covidRecert.householdMembers;
      stateObj.membersListUpdated = TextProps.VALUE_FALSE;
      this.props.setHouseholdMembers(stateObj);
    } else if (activeId === "income-summary") {
      this.props.setEarnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.covidRecert.setOldIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "unearned-income-summary") {
      this.props.setUnearnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.covidRecert.setOldUIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "childsupport-expenses") {
      let stateObj = this.props.covidRecert.childExpScreen;
      stateObj.childExpChanged = TextProps.VALUE_FALSE;
      this.props.setChildSupportData(stateObj);
    }

    this.closeWarningModal();
    return TextProps.VALUE_FALSE;
  };

  renderWarningPopup = () => {
    //Get interim due date
    if (this.props.covidRecert.responseObject) {
      var caseManager = this.props.covidRecert.responseObject.caseManager;
      var caseManagerNumber = caseManager
        ? caseManager.phoneNumber
        : "8773822363";
    }
    var showBenefitListInfo = [];
    var benefitListResult = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    if (
      !_.isEmpty(this.props.consumer.centrifyData) &&
      this.props.consumer.centrifyData.benefitInfo.length > 0
    ) {
      showBenefitListInfo = this.props.consumer.centrifyData.benefitInfo;
    } else if (benefitListResult) {
      showBenefitListInfo = benefitListResult.benefitInfo;
    }
    if (caseManagerNumber) {
      var caseWorkerPhone = formatters.phone(caseManagerNumber);
    }
    var _SNAP_DATE;
    //itrate to get alert messages data , if one avail the flag no need set
    if (showBenefitListInfo.length > 0) {
      for (let alertVal of showBenefitListInfo) {
        let alertMessages = alertVal.alertMessages;

        if (alertMessages.SNAP_FLAG === TextProps.VALUE_YES) {
          _SNAP_DATE = alertMessages.NEXT_RECERT_DUEDATE;
        }
      }
    }
    //Get interim due date ends

    let popupTexts = interimWarningPopupMLanguageLabels(
      language,
      helperFunction._convertDateToState(_SNAP_DATE),
      caseWorkerPhone
    ).popupBodyTexts;

    return modalHelperFunction.dtaHelperModal(
      null,
      this.state.warningModalOpenStatus,
      this.closeWarningModal,
      "save-progress-modal",
      "First pop-up",
      interimWarningPopupMLanguageLabels(language, null).title,
      null,
      TextProps.VALUE_FALSE,
      interimWarningPopupMLanguageLabels(language, null).title,
      <main className="dta-modal__body pad-all">
        <p>
          {popupTexts[0]}
          {this.state.fullContentInPopup ? (
            <p>
              {" "}
              {popupTexts[1]} <br />
              <br /> {popupTexts[2]} <br />
              <br /> {popupTexts[3]}{" "}
            </p>
          ) : null}
        </p>
        <p>&nbsp;</p>
        <div className="apply__footer pad-all--double warning-modal-footer">
          <button
            align="center"
            className="dta-button dta-button--outline-primary"
            onClick={() => {
              this.closeWarningModal();
            }}
          >
            {
              interimReportPageLabels(language, languageConstants.aboutMe)
                .houseLessOptions[0]
            }
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            align="center"
            className="dta-button dta-button--outline-primary"
            onClick={() => this.autoSelectNo()}
          >
            {
              interimReportPageLabels(language, languageConstants.aboutMe)
                .houseLessOptions[1]
            }
          </button>
        </div>
      </main>
    );
  };

  setDataCodeChangeFlags = () => {
    if (
      this.props.covidRecert.contactInfoScreen &&
      this.props.covidRecert.contactInfoScreen.contactInfoChanged
    ) {
      const responseBody = _.cloneDeep(this.props.covidRecert.responseObject);

      let emailCode = null;
      let phoneCode = null;
      let mailingAddressCode = null;
      let resAddressCode = null;
      let homelssFlagCode = null;
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let resAddr of respAddress) {
        if (resAddr.typeCode === "RES") respResAddress = resAddr;
        else if (resAddr.typeCode === "MAIL") respMailAddress = resAddr;
      }

      let updatedAddress =
        this.props.covidRecert.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let updatedAddr of updatedAddress) {
        if (
          updatedAddr.typeCode === "RES" &&
          updatedAddr.city &&
          updatedAddr.line1 &&
          updatedAddr.zip
        )
          updatedResAddress = updatedAddr;
        else if (updatedAddr.typeCode === "MAIL")
          updatedMailAddress = updatedAddr;
      }

      if (respResAddress !== null && updatedResAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed"
        ) {
          resAddressCode = "changedLabel";
        } else if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          resAddressCode = "removedLabel";
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respResAddress === null && updatedResAddress !== null) {
        resAddressCode = "newLabel";
      } else if (respResAddress !== null && updatedResAddress === null) {
        resAddressCode = "removedLabel";
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "changed"
        ) {
          mailingAddressCode = "changedLabel";
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        mailingAddressCode = "newLabel";
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        mailingAddressCode = "changedLabel";
      }

      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.covidRecert.contactInfoScreen;
      if (!respEmail.emailAddress && updatedEmail.email) {
        emailCode = "newLabel";
      } else if (respEmail.emailAddress && !updatedEmail.email) {
        emailCode = "removedLabel";
      } else if (
        respEmail.emailAddress &&
        (respEmail.emailAddress !== updatedEmail.email ||
          respEmail.optInFlag !== updatedEmail.emailOptIn)
      ) {
        emailCode = "changedLabel";
      }

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.covidRecert.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.split(" ").join("")
        : "";
      if (!respPhone.phoneNumber && phoneNumber) {
        phoneCode = "newLabel";
      } else if (respPhone.phoneNumber && !phoneNumber) {
        phoneCode = "removedLabel";
      } else if (
        respPhone.phoneNumber &&
        (respPhone.phoneNumber !== phoneNumber ||
          respPhone.textOptInFlag !== updatedPhone.textOptIn)
      ) {
        phoneCode = "changedLabel";
      }

      //Set the codes to the state
      let contactInfoObj = this.props.covidRecert.contactInfoScreen;
      contactInfoObj.changeFlags = {
        emailCode: emailCode,
        phoneCode: phoneCode,
        mailingAddressCode: mailingAddressCode,
        resAddressCode: resAddressCode,
        homelssFlagCode: homelssFlagCode,
      };
      this.props.setContactInfo(contactInfoObj);
    }
  };

  //Primary Continue Click
  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);

    if (activeId === "household-member") {
      this.props.updateHouseholdTextChanges(
        this.props.covidRecert.sections[2].subsections
      );
    } else if (activeId === "contact-info") {
      let contactInfoObjTemp = _.cloneDeep(
        this.props.covidRecert.contactInfoScreen
      );
      if (
        contactInfoObjTemp.emailErrorExists === TextProps.VALUE_TRUE ||
        contactInfoObjTemp.phoneErrorExists === TextProps.VALUE_TRUE
      ) {
        contactInfoObjTemp.continueClicked = TextProps.VALUE_TRUE;
        this.props.setContactInfo(contactInfoObjTemp, () => {
          this.setState({
            activeSectionId: activeId,
          });
          this.forceUpdate();
        });
        return;
      }
    }

    let dataSections = null;
    //Dal Reference
    let dalReference = this.props.covidRecert.dalReference;
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;

      this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
      this._dynamicSectionIdMatch(activeId);
    }

    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      if (activeId === "add-contact-info") {
        let subsection = this.props.covidRecert.sections[1].subsections[0];
        if (
          dataSections.questions[0].Answer ===
            interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0] &&
          dataSections.questions[2].Answer === TextProps.VALUE_STR_TRUE &&
          dataSections.questions[1].Answer.street === "" &&
          dataSections.questions[1].Answer.zipCode === "" &&
          dataSections.questions[1].Answer.city === ""
        ) {
          if (subsection !== undefined) {
            subsection.questions[2].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            subsection.questions[2].conditionals[0].conditionalQuestions[0].errorMessage =
              interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addressErrmsg;
            subsection.questions[2].value = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].Answer = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].targetValue = TextProps.VALUE_STR_FALSE;

            let answer = this.state.AnswerObject;
            answer["add-contact-info"]["is-also-mailing"] =
              TextProps.VALUE_STR_FALSE;

            let mailingAddressSame =
              document.getElementsByName("is-also-mailing");
            mailingAddressSame[1].click();
            mailingAddressSame[0].checked = TextProps.VALUE_FALSE;
            mailingAddressSame[1].checked = TextProps.VALUE_TRUE;
            this.setState({
              activeSectionId: activeId,
              renderedSections: [dataSections],
              AnswerObject: answer,
            });
            this.props.addContactInfoSubSection([subsection]);
            return;
          }
        }
        this.props.addContactInfoSubSection([subsection]);
      }

      let isValid = toValidateInputErros(dataSections, activeId);

      if (
        isValid === TextProps.VALUE_FALSE ||
        incomingQuestions.selectionMade === null
      ) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        return;
      } else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          //Clearing HouseHold Cache Template
          case "optional":
            this.onHouseHoldSubSectionClean();
            break;
          //clearning Income Template
          case "household-details":
            this.onIncomeSubSectionClean();
            this.onUnearnedIncomeSubSectionClean();
            break;
          //clearning Medical Expense Tempalte
          case "child-support":
            this.onMedicalExpenseSectionClean();
            break;
          //Rendering Dynamic Household Members
          case "my-household":
            if (this.props.covidRecert.sections.length > 4) {
              this.onHouseHoldSubsectionRender(
                dataSections.questions[0].Answer
              );
              this.onIncomeSubSectionClean();
              this.onUnearnedIncomeSubSectionClean();
              this.onIncomeSubsectionRender();
            }
            break;
          case "income-summary":
            this.onIncomeSubSectionClean();
            this.onUnearnedIncomeSubSectionClean();
            this.onUnearnedIncomeSubsectionRender();
            break;
          case "unearned-income":
            this.onUnearnedIncomeSubsectionRender(
              dataSections.questions[0].Answer
            );
            break;

          case "household-member":
            let stateObj = this.props.covidRecert.householdMembers;
            stateObj.addHouseholdMember = TextProps.VALUE_FALSE;
            let dateOfB = new Date(dataSections.questions[4].Answer);
            let dateNumber =
              dateOfB.getDate() < 9
                ? "0" + dateOfB.getDate()
                : dateOfB.getDate();
            dateOfB =
              " (" +
              dateNumber +
              "." +
              helperFunction._convertMonthNumberToString(dateOfB.getMonth()) +
              "." +
              dateOfB.getFullYear() +
              ")";
            let newMember = {
              agencyID: 1,
              firstName: dataSections.questions[0].Answer,
              lastName: dataSections.questions[2].Answer,
              dateOfBirth: dateOfB,
              granteeFlag: TextProps.VALUE_FALSE,
              allAnswers: dataSections.questions,
            };
            let membersList = stateObj.membersList;
            membersList.push(newMember);
            stateObj.membersList = membersList;
            this.props.setHouseholdMembers(stateObj);
            let ansObjCopy = this.state.AnswerObject;
            ansObjCopy[activeId].firstName = "";
            ansObjCopy[activeId].lastName = "";
            ansObjCopy[activeId].dateOfBirth = "";
            ansObjCopy[activeId].ssn = "";
            ansObjCopy[activeId].relationship = "";
            ansObjCopy[activeId]["biological-sex"] = "";
            ansObjCopy[activeId]["is-citizen"] = "";
            ansObjCopy[activeId]["buy-food"] = "";
            ansObjCopy[activeId]["person-income"] = "";
            this.setState({
              AnswerObject: ansObjCopy,
            });
            break;
          case "add-contact-info":
            let contactInfoObj = this.props.covidRecert.contactInfoScreen;
            contactInfoObj.addressList.homeless =
              dataSections.questions[0].Answer ===
              interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0]
                ? TextProps.VALUE_TRUE
                : TextProps.VALUE_FALSE;
            contactInfoObj.addressList.address[0].line1 =
              dataSections.questions[1].Answer.street;
            contactInfoObj.addressList.address[0].line2 = "";
            contactInfoObj.addressList.address[0].city =
              dataSections.questions[1].Answer.city;
            contactInfoObj.addressList.address[0].state =
              dataSections.questions[1].Answer.street &&
              dataSections.questions[1].Answer.city &&
              dataSections.questions[1].Answer.zipCode
                ? dataSections.questions[1].Answer.state
                : "";
            contactInfoObj.addressList.address[0].zip =
              dataSections.questions[1].Answer.zipCode;
            contactInfoObj.addressList.address[0].typeCode = "RES";
            contactInfoObj.addressList.address[0].resAddrMatchFlag =
              TextProps.VALUE_Y;

            contactInfoObj.sameMailingAddress = TextProps.VALUE_TRUE;
            if (
              dataSections.questions[2].Answer === TextProps.VALUE_STR_FALSE
            ) {
              contactInfoObj.sameMailingAddress = TextProps.VALUE_FALSE;
              if (
                this.props.covidRecert.contactInfoScreen.addressList.address
                  .length === 1
              ) {
                let addressMailing = {};
                addressMailing.line1 = dataSections.questions[2].conditionals[0]
                  .conditionalQuestions[0].Answer.street
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.street
                  : "";
                addressMailing.line2 = "";
                addressMailing.city = dataSections.questions[2].conditionals[0]
                  .conditionalQuestions[0].Answer.city
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.city
                  : "";
                addressMailing.state = dataSections.questions[2].conditionals[0]
                  .conditionalQuestions[0].Answer.state
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.state
                  : "";
                addressMailing.zip = dataSections.questions[2].conditionals[0]
                  .conditionalQuestions[0].Answer.zipCode
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.zipCode
                  : "";
                addressMailing.resAddrMatchFlag = TextProps.VALUE_FALSE;
                addressMailing.typeCode = "MAIL";
                contactInfoObj.addressList.address.push(addressMailing);
                contactInfoObj.addressList.address[0].resAddrMatchFlag =
                  TextProps.VALUE_FALSE;
              } else {
                contactInfoObj.addressList.address[1].line1 = dataSections
                  .questions[2].conditionals[0].conditionalQuestions[0].Answer
                  .street
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.street
                  : "";
                contactInfoObj.addressList.address[1].city = dataSections
                  .questions[2].conditionals[0].conditionalQuestions[0].Answer
                  .city
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.city
                  : "";
                contactInfoObj.addressList.address[1].state = dataSections
                  .questions[2].conditionals[0].conditionalQuestions[0].Answer
                  .state
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.state
                  : "";
                contactInfoObj.addressList.address[1].zip = dataSections
                  .questions[2].conditionals[0].conditionalQuestions[0].Answer
                  .zipCode
                  ? dataSections.questions[2].conditionals[0]
                      .conditionalQuestions[0].Answer.zipCode
                  : "";
                contactInfoObj.addressList.address[1].resAddrMatchFlag =
                  TextProps.VALUE_FALSE;
                contactInfoObj.addressList.address[1].line2 = "";
                contactInfoObj.addressList.address[1].typeCode = "MAIL";
                contactInfoObj.addressList.address[0].resAddrMatchFlag =
                  TextProps.VALUE_FALSE;
                contactInfoObj.addressList.address[0].line2 = "";
              }
            } else if (
              dataSections.questions[2].Answer === TextProps.VALUE_STR_TRUE
            ) {
              contactInfoObj.addressList.address[0].resAddrMatchFlag =
                TextProps.VALUE_TRUE;
              contactInfoObj.addressList.address.splice(1, 1);
            } else {
              contactInfoObj.addressList.address[0].resAddrMatchFlag =
                TextProps.VALUE_FALSE;
            }
            this.props.setContactInfo(contactInfoObj);
            break;
          default:
            //Nothing
            break;
        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        switch (activeIdSubString) {
          // rendering Income Dynamic Questions.
          case "unearned-combined-income":
            this.incomeUnearnedfilterQuestionsBySelection(
              activeSplitMemberIndex
            );
            break;
          case "unearned-combined-income-subsection":
            dataSections.currentMember =
              this.props.covidRecert.sections[4].currentMember;
            this.gotoUnearnedIncomeSummary(activeSplitMemberIndex);
            return;
          case "combined-income":
            this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
            break;
          case "combined-income-subsection":
            dataSections.currentMember =
              this.props.covidRecert.sections[3].currentMember;
            if (this.state.incomeSummaryEdit) {
              this.gotoIncomeSummary(activeSplitMemberIndex);
              return;
            }
            break;

          default:
            break;
        }
        if (
          activeId !== "income" &&
          activeIdSubString !== "combined-income" &&
          activeId !== "kids-and-adults" &&
          activeId !== "medical-expenses" &&
          activeId.indexOf("medical-expenses-") < 0
        ) {
          if (activeId.indexOf("medical-address-") >= 0) {
            if (
              this.props.covidRecert.sections[4].currentExpSeq !== undefined &&
              this.props.covidRecert.sections[4].currentExpSeq !== null
            ) {
              dataSections.currentExpSeq =
                this.props.covidRecert.sections[4].currentExpSeq;
            }
          }
        } else {
          this._renderNextSection(activeId);
          let AnswerObjectCopy = this.state.AnswerObject;
          AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
          this.setState({ AnswerObject: AnswerObjectCopy });
        }
      }
    } else if (incomingQuestions.selectionMade === TextProps.VALUE_FALSE) {
      const presentSectionId = this._getSection(activeId, this.state.sections);
      if (presentSectionId) {
        this.setState({
          activeSectionId: activeId,
        });
      }
      return;
    } else if (incomingQuestions.selectionMade === null) {
      let index = 0;
      switch (activeId) {
        //Clearing HouseHold Cache Template
        case "contact-info":
          index = 1;
          break;

        case "my-household":
          index = 2;
          break;

        case "income-summary":
          index = 3;
          this.setState({
            earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
          });
          break;

        case "unearned-income-summary":
          index = 4;
          this.setState({
            unearnedIncomeChanged: !this.state.unearnedIncomeChanged,
          });
          break;
        case "childsupport-expenses":
          index = 5;
          break;

        default:
          //Nothing
          break;
      }
      this.props.setDataChangeStatus(TextProps.VALUE_FALSE, index);
      return;
    } else if (activeId === "sign-and-submit") {
      //start google analytics code for getting number of people applying application group by language
      let currentLanguageCode =
        helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
      if (currentLanguageCode) {
        switch (currentLanguageCode) {
          case Types.SPANISH:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationESAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case Types.PORTUGUESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationBRAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();

            break;
          case Types.CHINESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationZHAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case Types.VIETNAMESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationVIAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case Types.ENGLISH:
          default:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                "submitApplicationENAction",
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
        }
      }
      //end google analytics code for getting number of people applying application group by language
      //Loading Dynamic SubSections , HouseHold Details
      if (this.state.rights < 1 || this.state.signature.length < 1) {
        if (this.state.rights < 1) {
          this.setState({ agreeError: TextProps.VALUE_TRUE });
          this.agrreErrorMsg = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).agreeTermsErrorMsg;
        } else {
          this.setState({ agreeError: TextProps.VALUE_FALSE });
          this.agrreErrorMsg = "";
        }

        this.setState(
          { signature: this.refs.applicanSign.inputEl.value },
          () => {
            if (this.state.signature.length < 1) {
              this.setState({ signatureError: TextProps.VALUE_TRUE });
              this.signatureErrorMsg = interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).signatureErrorMsg;
            } else {
              this.setState({ signatureError: TextProps.VALUE_FALSE });
              this.signatureErrorMsg = "";
            }
          }
        );

        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
          });
        }
        return;
      } else {
        this.prepareDataForSubmission(activeId);
      }
    }

    if (activeId === "childsupport-expenses") {
      if (
        this.props.covidRecert.sections.length > 4 &&
        this.props.covidRecert.childExpScreen !== undefined &&
        this.props.covidRecert.childExpScreen.childExpChanged ===
          TextProps.VALUE_TRUE
      ) {
        let childExpNew = _.cloneDeep(
          this.props.covidRecert.childExpScreen.tempChildExpArray
        );
        let finalChildList = [];
        if (childExpNew.length > 0) {
          childExpNew.map((member) => {
            if (
              member.dataChangeCode !== undefined &&
              (member.dataChangeCode === "new" ||
                member.dataChangeCode === "changed" ||
                member.dataChangeCode === "removed" ||
                member.dataChangeCode === "existing")
            ) {
              if (member.editedAmount !== "")
                member.amount = member.editedAmount
                  ? member.editedAmount
                  : member.amount;
              if (member.editedFrequency !== "")
                member.frequency = member.editedFrequency
                  ? member.editedFrequency
                  : member.frequency;
              if (
                (member.editedAmount || member.editedFrequency) &&
                (member.dataChangeCode === "existing" ||
                  member.dataChangeCode === "changed")
              ) {
                if (
                  member.amount === member.originalAmount &&
                  member.frequency === member.originalFrequency
                )
                  member.dataChangeCode = "existing";
                else member.dataChangeCode = "changed";
              }
              member.editedAmount = "";
              member.editedFrequency = "";
              member.isOpen = TextProps.VALUE_FALSE;
              member.isComplete = TextProps.VALUE_TRUE;
              finalChildList.push(member);
            } else if (
              member.isComplete === TextProps.VALUE_FALSE &&
              member.editedAmount !== undefined &&
              member.editedAmount !== ""
            ) {
              member.isComplete = TextProps.VALUE_TRUE;
              if (member.editedAmount !== "")
                member.amount = member.editedAmount
                  ? member.editedAmount
                  : member.amount;
              if (member.editedFrequency !== "")
                member.frequency = member.editedFrequency
                  ? member.editedFrequency
                  : member.frequency;
              member.editedAmount = "";
              member.editedFrequency = "";
              member.dataChangeCode = "new";
              member.isOpen = TextProps.VALUE_FALSE;
              finalChildList.push(member);
            }
          }); // member ends
        }
        this.props.setChildSupportData({
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: finalChildList,
        });
      }
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.covidRecert);

    if (activeId !== "sign-and-submit") {
      this._renderNextSection(activeId);
    }

    if (
      incomingQuestions.navigateToPrimaryScreen !== undefined &&
      incomingQuestions.navigateToPrimaryScreen === TextProps.VALUE_TRUE
    ) {
      event.preventDefault(); // allow react to take care of routing instead of browser
      const activeStateSectionId = this.state.activeSectionId;
      const { sections } = this.props.covidRecert;
      const previousSectionId = getPreviousSection(
        activeStateSectionId,
        sections
      );
      if (previousSectionId) {
        const previousSection = this._getSection(previousSectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(previousSection, TextProps.VALUE_TRUE);
          }
        );
      }
    }

    if (
      incomingQuestions.skipsubSectionNavigation !== undefined &&
      incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
    ) {
      event.preventDefault(); // allow react to take care of routing instead of browser
      const activeQuestionId = incomingQuestions.id;
      const { sections } = this.props.covidRecert;
      let sectionId = getNextSection(activeQuestionId, sections);

      switch (activeQuestionId) {
        case "contact-info":
          let contactInfoObj = _.cloneDeep(
            this.props.covidRecert.contactInfoScreen
          );

          if (contactInfoObj.emailTemp !== undefined) {
            contactInfoObj.email = contactInfoObj.emailTemp;
            delete contactInfoObj.emailTemp;
          }
          if (contactInfoObj.emailOptInTemp !== undefined) {
            contactInfoObj.emailOptIn = contactInfoObj.emailOptInTemp;
            delete contactInfoObj.emailOptInTemp;
          }

          if (contactInfoObj.phoneNumberTemp !== undefined) {
            contactInfoObj.phoneNumber = contactInfoObj.phoneNumberTemp;
            delete contactInfoObj.phoneNumberTemp;
          }
          if (contactInfoObj.textOptInTemp !== undefined) {
            contactInfoObj.textOptIn = contactInfoObj.textOptInTemp;
            delete contactInfoObj.textOptInTemp;
          }

          contactInfoObj.editEmail = TextProps.VALUE_FALSE;
          contactInfoObj.editPhone = TextProps.VALUE_FALSE;
          this.props.setContactInfo(contactInfoObj);
          sectionId = "my-household";
          break;

        case "my-household":
          if (this.props.covidRecert.sections.length > 4) {
            this.onIncomeSubSectionClean();
            sectionId = "income-summary";
          } else {
            sectionId = "sign-and-submit";
          }
          break;
        case "income-summary":
          this.onUnearnedIncomeSubSectionClean();
          sectionId = "unearned-income-summary";
          break;
        case "unearned-income-summary":
          this.onUnearnedIncomeSubSectionClean();
          sectionId = "childsupport-expenses";
          break;

        default:
          //Nothing
          break;
      }

      if (sectionId) {
        const nextSection = this._getSection(sectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
          }
        );
      }
    }
  };

  saveFinalSignAndSubmit = (activeId) => {
    sessionStorage.setItem(
      "householdMembers",
      JSON.stringify(this.state.requestBody.householdMembers)
    );
    sessionStorage.setItem(
      "programID",
      JSON.stringify(this.state.requestBody.programID)
    );
    var auditData = getAudit();
    this.props
      .submitInterimReportData(this.state.requestBody, auditData)
      .then((result) => {
        if (result) {
          this.setState(
            {
              isLoading: TextProps.VALUE_FALSE,
              referenceNumber:
                this.props.covidRecert.responseNumbers.referenceNumber,
              docReferenceNumber:
                this.props.covidRecert.responseNumbers.docReferenceNumber,
              agencyID: this.props.covidRecert.responseObject.agencyID,
            },
            () => {
              this.disableInterimReportSections();
              this._renderNextSection(activeId);
            }
          );
        } else {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
          this.props.displayError();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
        this.props.displayError();
      });
  };

  disableInterimReportSections = () => {
    let sections = this.props.covidRecert.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.covidRecert.sections = sections;
      delete this.props.covidRecert.responseObject;
    }
  };

  //Function to Manipulate Details on Income page
  updateHouseHoldIncomeDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    var houseHoldMembersCopy =
      this.props.covidRecert.householdMembers.membersList;
    for (let memberListVal of houseHoldMembersCopy) {
      if (memberListVal.dataChangeCode) continue;
      let childMemberObject = _.cloneDeep(memberObject);
      var incomeHouseHoldName = memberListVal.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName = incomeHouseHoldName + " " + memberListVal.lastName;
        let incomeHouseHoldDOB = memberListVal.dateOfBirth;
        if (memberListVal.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = memberListVal.firstName;
      }

      // binding the dependents clientIds
      childMemberObject[OptionsProps.OPTION_VALUE] = memberListVal.id;
      childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
      childMemberObject.id = memberListVal.id;
      childMemberObject.isGrantee = TextProps.VALUE_N;
      incomeNameList.push(incomeCombinedName);
    }
    this.setState({ houseMemberLabels: incomeNameList });
  };

  strToTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  onIncomeSubsectionRender = (memberTemplate = null) => {
    let answer = [];

    this.props.covidRecert.responseObject.earnedIncome.map((income) => {
      answer.push(
        income.firstName + " " + income.lastName + income.dateOfBirth
      );
    });
    let existingtypes = ["WAGES", "SELEMP", "FEDWRK"];
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate = this.state.incomeMemberTemplate;
    if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
    if (incomeMeTemplate.subsections === undefined && answer.length > 0) {
      incomeMeTemplate.subsections = IncomeSectionTemplate.subsections;
    }

    var incomeSummaryTemplate = this.state.incomeSummaryTemplate;
    var incomeCopy = this.props.covidRecert.sections[3];
    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          incomeMeTemplate.id = "combined-income-" + prevClientIndex;
          incomeMeTemplate.header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "combined-income-subsection-" + prevClientIndex;
          incomeMeTemplate.subsections[0].header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.title = answer[i];
          incomeMeTemplate.clientId = answer[i];
          if (
            this.props.covidRecert.sections[3].retainData ===
              TextProps.VALUE_TRUE ||
            this.props.covidRecert.sections[3].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              ) >= 0
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode,
              ];
            } else {
              let optionsObj = {};
              optionsObj.optionValue =
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode;
              optionsObj.optionDisplay =
                earnedIncomeCodeMapper(language)[
                  this.props.covidRecert.responseObject.earnedIncome[i].typeCode
                ];
              incomeMeTemplate.questions[0][OptionsProps.OPTIONS].push(
                optionsObj
              );
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode,
              ];
              existingtypes.push(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              );
              let newType = {
                [Props.NAME]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  earnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  ],
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      earnedIncomeCodeMapper(language)[
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  employerLabel:
                    "" +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  unitLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  earnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  ], //'Add another SSI'
              };
              incomeMeTemplate.subsections[0].questions.push(newType);
            }
          } else if (
            this.props.covidRecert.sections[3].retainData ===
              TextProps.VALUE_FALSE ||
            this.props.covidRecert.sections[3].retainData === null
          ) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
                incomeCopy.subsections[i].questions[0].targetValue;
              incomeMeTemplate.questions[0].Answer =
                incomeCopy.subsections[i].questions[0].Answer;
            }
          }
          var incomeReturn = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ); // (incomeMultilanguageLabels(incomeCopy.questions[0].Answer[i],language))
          let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
            answer[i],
            earnedIncomeCodeMapper(language)[
              this.props.covidRecert.responseObject.earnedIncome[i].typeCode
            ],
            language
          );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);
          for (
            let j = 0;
            j < incomeMeTemplate.subsections[0].questions.length;
            j++
          ) {
            incomeMeTemplate.subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[j].employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMeTemplate.subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
              TextProps.VALUE_TRUE;
            let typeText =
              earnedIncomeCodeMapper(language)[
                incomeMeTemplate.subsections[0].questions[j].valueToMatch
              ];
            if (
              this.props.covidRecert.sections[3].retainData ===
                TextProps.VALUE_TRUE ||
              this.props.covidRecert.sections[3].retainData === null
            ) {
              if (
                incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              ) {
                let amountObj = [];
                if (
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .amounts !== undefined
                ) {
                  this.props.covidRecert.responseObject.earnedIncome[
                    i
                  ].amounts.map((amount, index) => {
                    amountObj.push(amount.amount + "");
                  });
                }
                incomeMeTemplate.subsections[0].questions[j].Answer = [
                  {
                    fullIncomeObj:
                      this.props.covidRecert.responseObject.earnedIncome[i],
                    code: "existing",
                    amount: amountObj,
                    unit: getFrequency(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [
                  {
                    fullIncomeObj:
                      this.props.covidRecert.responseObject.earnedIncome[i],
                    code: "existing",
                    amount: amountObj,
                    unit: getFrequency(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].value = [
                  {
                    fullIncomeObj:
                      this.props.covidRecert.responseObject.earnedIncome[i],
                    code: "existing",
                    amount: amountObj,
                    unit: getFrequency(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              } else {
                incomeMeTemplate.subsections[0].questions[j].Answer = [];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            } else if (
              this.props.covidRecert.sections[3].retainData ===
                TextProps.VALUE_FALSE ||
              this.props.covidRecert.sections[3].retainData === null
            ) {
              if (
                incomeCopy.subsections[i] !== undefined &&
                incomeCopy.subsections[i].questions !== undefined &&
                incomeMeTemplate !== undefined
              ) {
                incomeMeTemplate.subsections[0].questions[j].Answer =
                  incomeCopy.subsections[i].subsections[j].questions[j].Answer;
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] =
                  incomeCopy.subsections[i].subsections[j].questions[
                    j
                  ].targetValue;
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            }
          }
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        } // if prevClient check ends
        else {
          if (
            this.props.covidRecert.sections[3].retainData ===
              TextProps.VALUE_TRUE ||
            this.props.covidRecert.sections[3].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              ) >= 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].indexOf(
                  this.props.covidRecert.responseObject.earnedIncome[i].typeCode
                ) < 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].length > 0
                ) {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].push(
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  );
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer.push(
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  );
                } else {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ] = [
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode,
                  ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer = [
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode,
                  ];
                }
              }
            } else {
              let OptionsVal = {};
              OptionsVal.optionValue =
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode;
              OptionsVal.optionDisplay =
                earnedIncomeCodeMapper(language)[
                  this.props.covidRecert.responseObject.earnedIncome[i].typeCode
                ];
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.OPTIONS
              ].push(OptionsVal);
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.TARGET_VALUE
              ].push(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              );
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].questions[0].Answer.push(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              );
              existingtypes.push(
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              );
              let newTypeVal = {
                [Props.NAME]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  earnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  ],
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.covidRecert.responseObject.earnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      earnedIncomeCodeMapper(language)[
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  employerLabel:
                    "" +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  unitLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.earnedIncome[i]
                        .typeCode
                    ],
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  earnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .typeCode
                  ], //'Add another SSI'
              };
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions.push(newTypeVal);
            }
            let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
              answer[i],
              earnedIncomeCodeMapper(language)[
                this.props.covidRecert.responseObject.earnedIncome[i].typeCode
              ],
              language
            );
            incomeReturn.subQuestions.push(dynamicQuestionLabels);
            for (
              let j = 0;
              j <
              incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                .questions.length;
              j++
            ) {
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].employerLabel =
                incomeReturn.subQuestions[j].employerLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.employerLabel =
                incomeReturn.subQuestions[j].employerLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
              let typeText =
                earnedIncomeCodeMapper(language)[
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].valueToMatch
                ];

              if (
                this.props.covidRecert.sections[3].retainData ===
                  TextProps.VALUE_TRUE ||
                this.props.covidRecert.sections[3].retainData === null
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].valueToMatch ===
                  this.props.covidRecert.responseObject.earnedIncome[i].typeCode
                ) {
                  let amountObj = [];
                  if (
                    this.props.covidRecert.responseObject.earnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.covidRecert.responseObject.earnedIncome[
                      i
                    ].amounts.map((amount, index) => {
                      amountObj.push(amount.amount + "");
                    });
                  }
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].Answer !== undefined &&
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].Answer.length > 0
                  ) {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer.push({
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.earnedIncome[i],
                      code: "existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][
                      OptionsProps.TARGET_VALUE
                    ].push({
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.earnedIncome[i],
                      code: "existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value.push({
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.earnedIncome[i],
                      code: "existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.covidRecert.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  } else {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer = [
                      {
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.earnedIncome[i],
                        code: "existing",
                        amount: amountObj,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .frequencyCode
                        ],
                        employer:
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        employerLabel:
                          typeText +
                          interimReportPageLabels(
                            language,
                            languageConstants.beforeYouStart
                          ).fromMultiLang +
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        id: "repeatable_id_" + this.nextUniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] = [
                      {
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.earnedIncome[i],
                        code: "existing",
                        amount: amountObj,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .frequencyCode
                        ],
                        employer:
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        employerLabel:
                          typeText +
                          interimReportPageLabels(
                            language,
                            languageConstants.beforeYouStart
                          ).fromMultiLang +
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        id: "repeatable_id_" + this.nextUniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value = [
                      {
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.earnedIncome[i],
                        code: "existing",
                        amount: amountObj,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .frequencyCode
                        ],
                        employer:
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        employerLabel:
                          typeText +
                          interimReportPageLabels(
                            language,
                            languageConstants.beforeYouStart
                          ).fromMultiLang +
                          this.props.covidRecert.responseObject.earnedIncome[i]
                            .employerName,
                        id: "repeatable_id_" + this.nextUniqueId(),
                      },
                    ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  }
                }
              }
            }
          } //If income populated check ends
        }
      }
    }
    incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeMemberMultipleTemplate;
    this.props.appendIRIncomeDetails(incomeCopy);
    this.setState({ incomeValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareIncomeSummary();
  };

  onUnearnedIncomeSubsectionRender = (memberTemplate = null) => {
    let answer = [];
    var existingtypes = [
      "SSI",
      "RSDI",
      "UNEMPL",
      "CHSDOR",
      "EMPPEN",
      "VETBPR",
      "RENTAL",
      "WRKCMP",
      "RENTAL",
      "OTHER",
    ];
    this.props.covidRecert.responseObject.unearnedIncome.map((income) => {
      answer.push(
        income.firstName + " " + income.lastName + income.dateOfBirth
      );
    });
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate = this.state.unearnedIncomeMemberTemplate;
    if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
    if (incomeMeTemplate.subsections === undefined && answer.length > 0) {
      incomeMeTemplate.subsections = unearnedIncomeSectionTemplate.subsections;
    }
    var unearnedIncomeSummaryTemplate =
      this.state.unearnedIncomeSummaryTemplate;
    var incomeCopy = this.props.covidRecert.sections[4];

    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          incomeMeTemplate.id = "unearned-combined-income-" + prevClientIndex;
          incomeMeTemplate.header = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "unearned-combined-income-subsection-" + prevClientIndex;
          incomeMeTemplate.subsections[0].header = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.title = answer[i];
          incomeMeTemplate.clientId = answer[i];
          if (
            this.props.covidRecert.sections[4].retainData ===
              TextProps.VALUE_TRUE ||
            this.props.covidRecert.sections[4].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              ) >= 0
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.covidRecert.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.covidRecert.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
            } else {
              let optObj = {};
              optObj.optionValue =
                this.props.covidRecert.responseObject.unearnedIncome[
                  i
                ].typeCode;
              optObj.optionDisplay =
                unearnedIncomeCodeMapper(language)[
                  this.props.covidRecert.responseObject.unearnedIncome[
                    i
                  ].typeCode
                ];
              incomeMeTemplate.questions[0][OptionsProps.OPTIONS].push(optObj);
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.covidRecert.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.covidRecert.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
              existingtypes.push(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              );
              let newType = {
                [Props.NAME]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  unearnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ],
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      unearnedIncomeCodeMapper(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  employerLabel:
                    "" +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  unitLabel:
                    " " +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  " " +
                  unearnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ], //'Add another SSI'
              };
              incomeMeTemplate.subsections[0].questions.push(newType);
            }
          } else if (
            this.props.covidRecert.sections[4].retainData ===
            TextProps.VALUE_FALSE
          ) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
                incomeCopy.subsections[i].questions[0].targetValue;
              incomeMeTemplate.questions[0].Answer =
                incomeCopy.subsections[i].questions[0].Answer;
            }
          }
          var incomeReturn = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ); // (incomeMultilanguageLabels(incomeCopy.questions[0].Answer[i],language))
          let dynamicQuestionLabels = unearnedIncomeDynamicMultilanguageLabels(
            answer[i],
            unearnedIncomeCodeMapper(language)[
              this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
            ],
            language
          );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);
          for (
            let j = 0;
            j < incomeMeTemplate.subsections[0].questions.length;
            j++
          ) {
            incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
              incomeReturn.subQuestions[j][Props.LABEL];
            incomeMeTemplate.subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
              Props.LABEL
            ] = incomeReturn.subQuestions[j][Props.LABEL];
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
              TextProps.VALUE_TRUE;
            if (
              this.props.covidRecert.sections[4].retainData ===
                TextProps.VALUE_TRUE ||
              this.props.covidRecert.sections[4].retainData === null
            ) {
              if (
                existingtypes.indexOf(
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode
                ) >= 0
              ) {
                if (
                  incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode
                ) {
                  let amountArray = [];
                  if (
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.covidRecert.responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  incomeMeTemplate.subsections[0].questions[j].Answer = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].value = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                } else {
                  incomeMeTemplate.subsections[0].questions[j].Answer = [];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                }
              } else {
                if (
                  incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                  "OTHER"
                ) {
                  let amountArray = [];
                  if (
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.covidRecert.responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  incomeMeTemplate.subsections[0].questions[j].Answer = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].value = [
                    {
                      fullIncomeObj:
                        this.props.covidRecert.responseObject.unearnedIncome[i],
                      originalTypeCode:
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode,
                      code: "existing",
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                } else {
                  incomeMeTemplate.subsections[0].questions[j].Answer = [];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                }
              }
            } else if (
              this.props.covidRecert.sections[4].retainData ===
              TextProps.VALUE_FALSE
            ) {
              if (
                incomeCopy.subsections[i] !== undefined &&
                incomeCopy.subsections[i].questions !== undefined &&
                incomeMeTemplate !== undefined
              ) {
                incomeMeTemplate.subsections[0].questions[j].Answer =
                  incomeCopy.subsections[i].subsections[j].questions[j].Answer;
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] =
                  incomeCopy.subsections[i].subsections[j].questions[
                    j
                  ].targetValue;
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            }
          }
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        } else {
          if (
            this.props.covidRecert.sections[4].retainData ===
              TextProps.VALUE_TRUE ||
            this.props.covidRecert.sections[4].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              ) >= 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].indexOf(
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode
                ) < 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].length > 0
                ) {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].push(
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  );
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer.push(
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  );
                } else {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ] = [
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode,
                  ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer = [
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode,
                  ];
                }
              }
            } else {
              let optionsObject = {};
              optionsObject.optionValue =
                this.props.covidRecert.responseObject.unearnedIncome[
                  i
                ].typeCode;
              optionsObject.optionDisplay =
                unearnedIncomeCodeMapper(language)[
                  this.props.covidRecert.responseObject.unearnedIncome[
                    i
                  ].typeCode
                ];
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.OPTIONS
              ].push(optionsObject);
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.TARGET_VALUE
              ].push(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              );
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].questions[0].Answer.push(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              );
              existingtypes.push(
                this.props.covidRecert.responseObject.unearnedIncome[i].typeCode
              );
              let newType = {
                [Props.NAME]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  unearnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ],
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      unearnedIncomeCodeMapper(language)[
                        this.props.covidRecert.responseObject.unearnedIncome[i]
                          .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  employerLabel:
                    "" +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  unitLabel:
                    " " +
                    unearnedIncomeCodeMapper(language)[
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .typeCode
                    ],
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  " " +
                  unearnedIncomeCodeMapper(language)[
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ], //'Add another SSI'
              };
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions.push(newType);
            }
            let dynamicQuestionLabels =
              unearnedIncomeDynamicMultilanguageLabels(
                answer[i],
                unearnedIncomeCodeMapper(language)[
                  this.props.covidRecert.responseObject.unearnedIncome[i]
                    .typeCode
                ],
                language
              );
            incomeReturn.subQuestions.push(dynamicQuestionLabels);

            for (
              let j = 0;
              j <
              incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                .questions.length;
              j++
            ) {
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j][Props.LABEL] =
                incomeReturn.subQuestions[j][Props.LABEL];
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion[Props.LABEL] =
                incomeReturn.subQuestions[j][Props.LABEL];
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
              if (
                this.props.covidRecert.sections[4].retainData ===
                  TextProps.VALUE_TRUE ||
                this.props.covidRecert.sections[4].retainData === null
              ) {
                if (
                  existingtypes.indexOf(
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ) >= 0
                ) {
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].valueToMatch ===
                    this.props.covidRecert.responseObject.unearnedIncome[i]
                      .typeCode
                  ) {
                    let amountArray = [];
                    if (
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .amounts !== undefined
                    ) {
                      this.props.covidRecert.responseObject.unearnedIncome[
                        i
                      ].amounts.map((amount) => {
                        amountArray.push(amount.amount);
                      });
                    }
                    if (
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer !== undefined &&
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer.length > 0
                    ) {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer.push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][
                        OptionsProps.TARGET_VALUE
                      ].push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value.push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    } else {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer = [
                        {
                          fullIncomeObj:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i],
                          originalTypeCode:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].typeCode,
                          code: "existing",
                          employer: "State",
                          amount: amountArray,
                          unit: getFrequency(language)[
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].frequencyCode
                          ],
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                        [
                          {
                            fullIncomeObj:
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i].typeCode,
                            code: "existing",
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value = [
                        {
                          fullIncomeObj:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i],
                          originalTypeCode:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].typeCode,
                          code: "existing",
                          employer: "State",
                          amount: amountArray,
                          unit: getFrequency(language)[
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].frequencyCode
                          ],
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    }
                  }
                } else {
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].valueToMatch === "OTHER"
                  ) {
                    let amountArray = [];
                    if (
                      this.props.covidRecert.responseObject.unearnedIncome[i]
                        .amounts !== undefined
                    ) {
                      this.props.covidRecert.responseObject.unearnedIncome[
                        i
                      ].amounts.map((amount) => {
                        amountArray.push(amount.amount);
                      });
                    }
                    if (
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer !== undefined &&
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer.length > 0
                    ) {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer.push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][
                        OptionsProps.TARGET_VALUE
                      ].push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value.push({
                        fullIncomeObj:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ],
                        originalTypeCode:
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: "existing",
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.covidRecert.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    } else {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer = [
                        {
                          fullIncomeObj:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i],
                          originalTypeCode:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].typeCode,
                          code: "existing",
                          employer: "State",
                          amount: amountArray,
                          unit: getFrequency(language)[
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].frequencyCode
                          ],
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                        [
                          {
                            fullIncomeObj:
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i].typeCode,
                            code: "existing",
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.covidRecert.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value = [
                        {
                          fullIncomeObj:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i],
                          originalTypeCode:
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].typeCode,
                          code: "existing",
                          employer: "State",
                          amount: amountArray,
                          unit: getFrequency(language)[
                            this.props.covidRecert.responseObject
                              .unearnedIncome[i].frequencyCode
                          ],
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    }
                  }
                }
              }
            }
          } // If income populated check ends
        }
      }
    }
    incomeMemberMultipleTemplate.push(unearnedIncomeSummaryTemplate);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeMemberMultipleTemplate;
    this.props.appendUIIncomeDetails(incomeCopy);
    this.setState({ incomeUIValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareUnearnedIncomeSummary();
  };

  onIncomeSubSectionClean = () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          incomeSummaryTemplate:
            this.props.covidRecert.sections[3].subsections[
              this.props.covidRecert.sections[3].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            this.props.covidRecert.sections[3]
          );
          this.onIncomeSubsectionRender();
        }
      );
    }
  };

  onUnearnedIncomeSubSectionClean = () => {
    if (this.state.unearnedIncomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          unearnedIncomeSubSectionCleared: TextProps.VALUE_TRUE,
          unearnedIncomeMemberTemplate: _.cloneDeep(
            unearnedIncomeSectionTemplate
          ),
          unearnedIncomeSummaryTemplate:
            this.props.covidRecert.sections[4].subsections[
              this.props.covidRecert.sections[4].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberUIIncome(
            this.state.unearnedIncomeMemberTemplate,
            this.state.unearnedIncomeSummaryTemplate,
            this.props.covidRecert.sections[4]
          );
          this.onUnearnedIncomeSubsectionRender();
        }
      );
    }
  };

  prepareIncomeSummary = () => {
    let incomeSection = this.props.covidRecert.sections[3];
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].valueToMatch;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let houseMember of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(houseMember) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = houseMember;
        this.incomeSummary.nonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  prepareUnearnedIncomeSummary = () => {
    let incomeSection = this.props.covidRecert.sections[4];
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.unearnedIncomeMembers = [];
    this.incomeSummary.nonUnearnedIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.unearnedIncomeMembers[
          memberIncomeIndex
        ].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
            .incomeInputs.length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
              .incomeInputs[questionIndex].name !==
            "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].displayIncomeName;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].valueToMatch;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let houseMember of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(houseMember) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = houseMember;
        this.incomeSummary.nonUnearnedIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  incomefilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = this.props.covidRecert.sections[3];
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.incomeSubSections[memberIndex] === undefined) {
      this.incomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.incomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    this.props.covidRecert.sections[3].currentMember =
      this.incomeSubSections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer;
              filterQuestion.targetValue = oldQuestval.targetValue;
              let typeText =
                earnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendIRIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareIncomeSummary(() => {
        this.props.appendIRIncomeDetails(incomeSection);
        this.gotoIncomeSummary();
      });
    }
  };

  incomeUnearnedfilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = this.props.covidRecert.sections[4];
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.unearnedIncomeSubSections[memberIndex] === undefined) {
      this.unearnedIncomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.unearnedIncomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    this.props.covidRecert.sections[4].currentMember =
      this.unearnedIncomeSubSections[memberIndex].title;
    incomeSection.subsections[memberIndex].subsections[0].header =
      interimReportPageLabels(
        language,
        languageConstants.unearnedIncomeDynamic,
        this.unearnedIncomeSubSections[memberIndex].title,
        null,
        null
      ).heading;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer;
              filterQuestion.targetValue = oldQuestval.targetValue;

              let typeText =
                unearnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            } else {
              let typeText =
                unearnedIncomeCodeMapper(language)[filterQuestion.valueToMatch];
              filterQuestion[Props.LABEL] = typeText;
              filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendUIIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareUnearnedIncomeSummary(() => {
        this.props.appendUIIncomeDetails(incomeSection);
        this.gotoUnearnedIncomeSummary();
      });
    }
  };

  renderUnearnedIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = _.cloneDeep(unearnedIncomeSectionTemplate);
    var incomeCopy = this.props.covidRecert.sections[4];
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);
    incomeMeTemplate.id =
      "unearned-combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).mainQuestion;
    incomeMeTemplate.questions[0].Answer = "";
    incomeMeTemplate.questions[0].value = "";
    incomeMeTemplate.questions[0].targetValue = "";
    incomeMeTemplate.subsections[0].id =
      "unearned-combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.title = name;
    let incomeReturn = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    );
    for (let j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j].amountLabel =
        incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[
        j
      ].repeatedQuestion.amountLabel = incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion.unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
        TextProps.VALUE_TRUE;
      incomeMeTemplate.subsections[0].questions[j].Answer = "";
      incomeMeTemplate.subsections[0].questions[j].value = "";
      incomeMeTemplate.subsections[0].questions[j].targetValue = "";
    }
    //}
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendUIIncomeDetails(incomeCopy);
    this.prepareUnearnedIncomeSummary();
    this.onSectionChange(incomeMeTemplate);
  };

  _renderNextSection = (activeId) => {
    const nextSectionId = getNextSection(activeId, this.state.sections);
    //Function to Execute before Next page renders
    switch (nextSectionId) {
      // rendering Income Dynamic Questions.
      case "income-summary":
      case "household-member":
        this.updateHouseHoldIncomeDetails();
        this.prepareIncomeSummary();
        this.prepareUnearnedIncomeSummary();
        break;
      case "unearned-income":
        this.prepareUnearnedIncomeSummary();
        break;
      //Function to Manipulate Details on HouseHoldPage page
      case "household-details":
        this.updateHouseHoldDetails();
        break;
      //Function to Manipulate Details on Income page
      case "income":
        this.updateHouseHoldIncomeDetails();
        break;
      //Function to Manipulate Details on Expense child support page
      case "child-support":
        this.updateHouseHoldExpenseDetails();
        break;
      //Function to Manipulate Details on Expense medical page
      case "medical-expenses":
        this.updateHouseHoldMedicalDetails();
        break;
      //Clearing HouseHold Cache Template
      case "more-info":
        this.setLanguageInScreen();
        break;
      case "Other information":
        this.renderOtherInformation();
        break;
      default:
        //Nothing
        break;
    }
    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection, TextProps.VALUE_TRUE);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };

  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.covidRecert;
    let previousSectionId = getPreviousSection(activeId, sections);
    if (previousSectionId) {
      const incomingQuestions = this._getSection(activeId);
      if (
        incomingQuestions.skipsubSectionNavigation !== undefined &&
        incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
      ) {
        const activeQuestionId = incomingQuestions.id;
        switch (activeQuestionId) {
          case "my-household":
            previousSectionId = "contact-info";
            break;

          case "contact-info":
            let contactInfoObj = this.props.covidRecert.contactInfoScreen;

            if (contactInfoObj.emailTemp !== undefined) {
              delete contactInfoObj.emailTemp;
            }
            if (contactInfoObj.emailOptInTemp !== undefined) {
              delete contactInfoObj.emailOptInTemp;
            }
            if (contactInfoObj.phoneNumberTemp !== undefined) {
              delete contactInfoObj.phoneNumberTemp;
            }
            if (contactInfoObj.textOptInTemp !== undefined) {
              delete contactInfoObj.textOptInTemp;
            }

            contactInfoObj.editEmail = TextProps.VALUE_FALSE;
            contactInfoObj.editPhone = TextProps.VALUE_FALSE;
            contactInfoObj.emailErrorExists = TextProps.VALUE_FALSE;
            contactInfoObj.phoneErrorExists = TextProps.VALUE_FALSE;
            this.props.setContactInfo(contactInfoObj);
            break;

          case "income-summary":
            previousSectionId = "my-household";
            break;

          case "unearned-income-summary":
            previousSectionId = "income-summary";
            break;

          case "childsupport-expenses":
            previousSectionId = "unearned-income-summary";
            break;
          default:
            //Nothing
            break;
        }
      }

      if (
        activeId.indexOf("unearned-combined-income-") >= 0 &&
        activeId.indexOf("unearned-combined-income-subsection") < 0
      ) {
        previousSectionId = "unearned-income-summary";
      } else if (
        activeId.indexOf("combined-income-") >= 0 &&
        activeId.indexOf("combined-income-subsection") < 0
      ) {
        previousSectionId = "income-summary";
      }

      const previousSection = this._getSection(previousSectionId);
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    } else {
      this.redirectConsumerHome();
    }
    // this.props.selectMaxOfIdsComesBack(backQuestionsObj)
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).getStartButtonLabel;
        return returnText;
      case "sign-and-submit":
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).submitButtonLabel;
        return returnText;
      default:
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).continueLabel;
        return returnText;
    }
  };

  // Markup for the body of apply page when user is starting application.
  renderBeforeYouStart = () => {
    return (
      <StartForm
        pdfDownloader={this.downloadApplicationAsPdf}
        startFormData={this.props.covidRecert}
        onNotificationItemClick={this.props.displayPDFData}
      ></StartForm>
    );
  };

  renderContactInfo = () => {
    sessionStorage.setItem("covidRecertContact", TextProps.VALUE_TRUE);
    sessionStorage.setItem("covidRecert", TextProps.VALUE_TRUE);
    return (
      <ContactInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        onDataReset={this.resetContactState}
        setDataCodeChangeFlags={this.setDataCodeChangeFlags}
        contactInfoData={this.props.covidRecert}
        responseObj={this.props.covidRecert.responseObject}
        setContactInfo={this.props.setContactInfo}
        addContactInfoSubSection={this.props.addContactInfoSubSection}
        setDataChangeStatus={this.props.setDataChangeStatus}
      />
    );
  };

  renderHouseholdInfo = () => {
    return (
      <HouseholdInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        addHouseholdAddSectionToList={this.props.addHouseholdAddSectionToList}
        updateCurrentSectionData={this.props.updateCurrentSectionData}
        setHouseholdMembers={this.props.setHouseholdMembers}
        setDataChangeStatus={this.props.setDataChangeStatus}
        snapRefreshQuestionsSections={this.props.snapRefreshQuestionsSections}
        householdData={this.props.covidRecert}
        convertStringTODate={this.convertDOBForDataPost}
      />
    );
  };

  //Eraned income screens
  renderChildSupportExpenses = () => {
    return <ChildSupportExpenses />;
  };

  renderEarnedIncomeSummary = () => {
    return (
      <EarnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareIncomeSummary}
        incomeSummary={this.incomeSummary}
        onEarnedIncomeSubsectionRender={this.onIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
      />
    );
  };

  renderUnearnedIncomeSummary = () => {
    return (
      <UnearnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareUnearnedIncomeSummary={this.prepareUnearnedIncomeSummary}
        incomeSummary={this.incomeSummary}
        onUnearnedIncomeSubsectionRender={this.onUnearnedIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
      />
    );
  };

  renderSubmitSummary = () => {
    return (
      <SubmitSummary
        agencyID={this.state.agencyID}
        referenceNumber={this.state.referenceNumber}
        docReferenceNumber={this.state.docReferenceNumber}
      />
    );
  };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: this.refs.applicanSign.inputEl.value }, () => {
      if (this.state.signature.length < 1) {
        this.signatureError = TextProps.VALUE_TRUE;
      } else {
        this.signatureError = TextProps.VALUE_FALSE;
      }
    });
  };

  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agrreErrorMsg = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agrreErrorMsg = "";
      }
    });
  };

  openModal = () => {
    let url = rights_en_url;
    switch (language.toLowerCase()) {
      case "en":
        url = rights_en_url;
        break;
      case "es":
        url = rights_es_url;
        break;
      case "pt":
        url = rights_pt_url;
        break;
      case "vi":
        url = rights_vi_url;
        break;
      case "zh":
        url = rights_zh_url;
        break;
    }
    window.open(url, "_blank");
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmit = () => {
    const { sections } = this.props.covidRecert;
    if (!this.props.covidRecert.responseObject) return;
    const responseBody = _.cloneDeep(this.state.respData);
    let requestBody = _.cloneDeep(responseBody);

    //Get changes reported
    let reportedChanges = TextProps.VALUE_FALSE;
    if (this.props.covidRecert.contactInfoScreen.contactInfoChanged) {
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let address of respAddress) {
        if (address.typeCode === "RES") respResAddress = address;
        else if (address.typeCode === "MAIL") respMailAddress = address;
      }

      let updatedAddress =
        this.props.covidRecert.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let updatedAddr of updatedAddress) {
        if (
          updatedAddr.typeCode === "RES" &&
          updatedAddr.city &&
          updatedAddr.line1 &&
          updatedAddr.zip
        )
          updatedResAddress = updatedAddr;
        else if (updatedAddr.typeCode === "MAIL")
          updatedMailAddress = updatedAddr;
      }

      let finalAddressArray = [];
      if (respResAddress !== null && updatedResAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "changed" ||
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "removed"
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      } else if (respResAddress === null && updatedResAddress !== null) {
        reportedChanges = TextProps.VALUE_TRUE;
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "changed" ||
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "removed"
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        if (finalAddressArray.length > 0) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      requestBody.clientAddress = finalAddressArray;

      //Email change
      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.covidRecert.contactInfoScreen;
      let emailCode = null;
      if (
        (!respEmail.emailAddress && updatedEmail.email) ||
        (respEmail.emailAddress && !updatedEmail.email) ||
        (respEmail.emailAddress &&
          (respEmail.emailAddress !== updatedEmail.email ||
            respEmail.optInFlag !== updatedEmail.emailOptIn))
      ) {
        reportedChanges = TextProps.VALUE_TRUE;
      }
      requestBody.clientEmail.dataChangeCode = emailCode;
      requestBody.clientEmail.emailAddress = updatedEmail.email;
      requestBody.clientEmail.optInFlag =
        updatedEmail.emailOptIn === TextProps.VALUE_Y ||
        updatedEmail.emailOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.covidRecert.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.split(" ").join("")
        : "";
      if (
        (!respPhone.phoneNumber && phoneNumber) ||
        (respPhone.phoneNumber && !phoneNumber) ||
        (respPhone.phoneNumber &&
          (respPhone.phoneNumber !== phoneNumber ||
            respPhone.textOptInFlag !== updatedPhone.textOptIn))
      ) {
        reportedChanges = TextProps.VALUE_TRUE;
      }
    }

    //HOUSEHOLD CHANGE
    if (this.props.covidRecert.householdMembers.membersListUpdated) {
      let newHouseholdList = [];

      for (let memberVal of this.props.covidRecert.householdMembers
        .membersList) {
        if (
          memberVal.allAnswers !== undefined ||
          (memberVal.dataChangeCode && memberVal.dataChangeCode === "removed")
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      requestBody.householdMembers = newHouseholdList;
    }

    //EARNED INCOME CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.earnedIncomeChanged &&
      this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.covidRecert.responseObject.earnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.covidRecert.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (incomeSub.unit === "Weekly") {
                    amountIterator = 4;
                  } else if (incomeSub.unit === "Biweekly (every two weeks)") {
                    amountIterator = 2;
                  }

                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }
                let changeCode = "NOCHNG";
                switch (incomeSub.code) {
                  case "new":
                    changeCode = "NEW";
                    break;
                  case "changed":
                    changeCode = "CHANGE";
                    break;
                  case "removed":
                    changeCode = "REMOVE";
                    break;
                  default:
                    break;
                }
                if (changeCode !== "NOCHNG")
                  reportedChanges = TextProps.VALUE_TRUE;

                typeCode = income.typeCode;

                let incomeObjId = 0;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.id
                ) {
                  incomeObjId = incomeSub.fullIncomeObj.id;
                }
                let memObjAgencyId = 0;
                if (memberObj !== undefined && memberObj.agencyID) {
                  memObjAgencyId = memberObj.agencyID;
                }
                let incomeObj = {
                  id:
                    member.code === "new"
                      ? 0
                      : incomeObjId === undefined
                      ? 0
                      : incomeObjId,
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.agencyID
                      ? incomeSub.fullIncomeObj.agencyID
                      : memObjAgencyId,
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  employerName: incomeSub.employer,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);

        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.typeCode === existing.typeCode
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
    }

    //UNEARNED INCOME CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.unearnedIncomeChanged !== undefined &&
      this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.covidRecert.responseObject.unearnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.covidRecert.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income, index) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (incomeSub.unit === "Weekly") {
                    amountIterator = 4;
                  } else if (incomeSub.unit === "Biweekly (every two weeks)") {
                    amountIterator = 2;
                  }
                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }

                let changeCode = "NOCHNG";
                switch (incomeSub.code) {
                  case "new":
                    changeCode = "NEW";
                    break;
                  case "changed":
                    changeCode = "CHANGE";
                    break;
                  case "removed":
                    changeCode = "REMOVE";
                    break;
                  default:
                    break;
                }

                if (changeCode !== "NOCHNG")
                  reportedChanges = TextProps.VALUE_TRUE;

                typeCode = income.typeCode;

                let incomeObjId = 0;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.id
                ) {
                  incomeObjId = incomeSub.fullIncomeObj.id;
                }
                let memObjAgencyId = 0;
                if (memberObj !== undefined && memberObj.agencyID) {
                  memObjAgencyId = memberObj.agencyID;
                }
                let incomeObj = {
                  id:
                    member.code === "new"
                      ? 0
                      : incomeObjId === undefined
                      ? 0
                      : incomeObjId,
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.agencyID
                      ? incomeSub.fullIncomeObj.agencyID
                      : memObjAgencyId,
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);
        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.setID === existing.setID
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
    }

    //CHILE EXP CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.childExpScreen !== undefined &&
      this.props.covidRecert.childExpScreen.childExpChanged ===
        TextProps.VALUE_TRUE
    ) {
      let childExpNew = _.cloneDeep(
        this.props.covidRecert.childExpScreen.tempChildExpArray
      );

      if (childExpNew.length > 0) {
        childExpNew.map((member) => {
          if (
            member.dataChangeCode !== undefined &&
            (member.dataChangeCode === "new" ||
              member.dataChangeCode === "changed" ||
              member.dataChangeCode === "removed")
          )
            reportedChanges = TextProps.VALUE_TRUE;
        }); // member ends
      }
    }

    //Other information change
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.sections[6].questions !== undefined &&
      this.props.covidRecert.sections[6].questions.length > 0
    ) {
      this.props.covidRecert.sections[6].questions.map((question, index) => {
        if (
          question.type === "radio" &&
          question.Answer === TextProps.VALUE_YES
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      });
    }

    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.viewApplicationDataPopupOpen,
          this.closeModal,
          "save-progress-modal summary-progress-modal",
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryTitle,
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryHeader,
          <div className="dta-modal__body pad-all">
            {getMainSections(sections, this.state.viewApplicationDataPopupOpen)}
            ,
          </div>
        )}
        {/* Clicking 'View your Application Summary' button will download PDF summary */}
        <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).rightsText
          }
          &nbsp;
          <button
            className="dta-button dta-button--link"
            onClick={this.openModal}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).viewApplicationLinkLabel
            }
          </button>
        </b>
        <form id={this.state.submitFormId}>
          <TermsInterim id="submit-1" className="dta-form__label" />
          <strong>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).agreeContent[0]
            }
          </strong>
          <ul>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[1]
              }
            </li>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[2]
              }
            </li>
            <li>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).agreeContent[3]
              }
            </li>
          </ul>
          <strong>
            {reportedChanges
              ? interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).didReportChanges
              : interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).didNotReportChanges}
          </strong>
          <br />
          <br />
          <div>
            {this.state.agreeError ? (
              <b>
                <p className="error-text-clr">{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <p className="interim-recert-heading">
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              }
            </p>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p className="error-text-clr">{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type={ControlConstants.INPUT_ALPHA_TEXT}
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
          />
        </form>
      </div>
    );
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmitPartial = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}

        {/* Clicking 'View your Application Summary' button will download PDF summary */}
        {/* <button className="dta-button dta-button--link" onClick={this.openModal}>{interimReportPageLabels(language, languageConstants.beforeYouStart).viewApplicationLinkLabel}</button> */}
        <p>
          <b>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).rightsHHRemovedText
            }
          </b>
          <br />
        </p>
        <form id={this.state.submitFormId}>
          <div>
            {this.state.agreeError ? (
              <b>
                <p style={{ color: "red" }}>{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <h3>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              }
            </h3>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p style={{ color: "red" }}>{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type={ControlConstants.INPUT_ALPHA_TEXT}
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
          />
        </form>
      </div>
    );
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    return null;
  };

  // download pdf files link
  downloadApplicationAsPdf() {
    window.open(
      "https://dta-connect-service.azurewebsites.net/snapapplication.pdf",
      "_blank"
    );
  }

  _bindAnswerObject = (answer) => {
    if (this.state.activeSectionId === "add-contact-info") {
      let contactAnswer = answer["add-contact-info"];
      if (
        contactAnswer.mailingAddress !== undefined &&
        contactAnswer["is-also-mailing"] === undefined
      ) {
        contactAnswer["is-also-mailing"] =
          this.props.covidRecert.contactInfoScreen.addressList.address[0]
            .resAddrMatchFlag &&
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
            TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
      }
      if (
        contactAnswer.mailingAddress === undefined &&
        contactAnswer["is-also-mailing"] !== undefined &&
        contactAnswer["is-also-mailing"] === TextProps.VALUE_STR_FALSE
      ) {
        let addressList = this.props.covidRecert.contactInfoScreen.addressList;
        let mailingAddress = null;
        addressList.address.map((addr) => {
          if (addr.typeCode === "MAIL") mailingAddress = addr;
        });
        contactAnswer.mailingAddress = mailingAddress
          ? {
              street: mailingAddress.line1 + " " + mailingAddress.line2,
              zipCode: mailingAddress.zip,
              state: mailingAddress.state,
              city: mailingAddress.city,
            }
          : {
              street: "",
              zipCode: "",
              state: "",
              city: "",
            };
      }
      answer["add-contact-info"] = contactAnswer;
    }
    this.setState({
      AnswerObject: answer,
    });
  };

  //Reset contact info answer state object when user selects "No" to change question and tries to edit address again
  resetContactState = () => {
    if (this.props.covidRecert.contactInfoScreen !== undefined) {
      let contactAnswer = this.state.AnswerObject["add-contact-info"];
      if (contactAnswer) {
        let contactObj = this.props.covidRecert.contactInfoScreen.addressList;
        contactAnswer["is-also-mailing"] =
          contactObj.address[0].resAddrMatchFlag &&
          contactObj.homeless !== TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
        let mailAddressObj = null;
        if (contactObj.address[0].typeCode === "MAIL") {
          mailAddressObj = contactObj.address[0];
        } else if (
          contactObj.address[1] &&
          contactObj.address[1].typeCode === "MAIL"
        ) {
          mailAddressObj = contactObj.address[1];
        }
        contactAnswer.mailingAddress = mailAddressObj
          ? {
              street: mailAddressObj.line1 + " " + mailAddressObj.line2,
              zipCode: mailAddressObj.zip,
              state: mailAddressObj.state,
              city: mailAddressObj.city,
            }
          : {
              street: "",
              zipCode: "",
              state: "",
              city: "",
            };

        this.setState({
          AnswerObject: contactAnswer,
        });
      }
    }
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <InterimSection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.redirectToSignAndSubmit}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </InterimSection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    //start of google analytics related code added in client side for  partial submit
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.DT_PARTIAL_APPLY,
        "submitApplicationPartially",
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();
    //end of google analytics related code added in client side for  partial submit

    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);

    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: "sign-and-submit",
        });
      }
    );
  };

  gotoIncomeSummary = () => {
    this.prepareIncomeSummary();
    this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.incomeSummaryTemplate);
    });
  };

  gotoUnearnedIncomeSummary = () => {
    this.prepareUnearnedIncomeSummary();
    this.setState({ incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.unearnedIncomeSummaryTemplate);
    });
  };

  getIncomeSummaryTitle = (name, before, after) => {
    switch (language) {
      case Types.ENGLISH:
      case Types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case Types.SPANISH:
      case Types.PORTUGUESE:
      case Types.VIETNAMESE:
      case HI:
        return {
          before: before,
          after: " " + name,
        };
    }
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
        return this.renderBeforeYouStart();
      case "contact-info":
        return this.renderContactInfo();
      case "my-household":
        return this.renderHouseholdInfo();
      case "income-summary":
        return this.renderEarnedIncomeSummary();
      case "unearned-income-summary":
        return this.renderUnearnedIncomeSummary();
      case "childsupport-expenses":
        return this.renderChildSupportExpenses();
      case "other-information":
        return this.renderOtherInformation();
      case "expenses":
        return this.renderExpensesIndex();
      case "sign-and-submit":
        if (this.props.covidRecert.sections.length > 4)
          return this.renderSignAndSubmit();
        else return this.renderSignAndSubmitPartial();
      case "submitted":
        return this.renderSubmitSummary();
      default:
        return <div>Something went wrong</div>;
    }
  };

  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.covidRecert;

    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);

    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      return <Loading />;
    }
  }

  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      const { common } = this.props.language.security;
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
      var loginFlag = TextProps.VALUE_FALSE;
      if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
        loginFlag = TextProps.VALUE_TRUE;
      } else {
        if (loginUserInfo !== null && loginUserInfo !== undefined) {
          if (loginUserInfo.firstName !== "") {
            loginFlag = TextProps.VALUE_TRUE;
          }
        }
      }
      var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
      var userRole = userObject.roleList[1]
        ? userObject.roleList[1].roleName
        : userObject.roleList[0].roleName;
      const msalInstance = this.props.msalContext.instance;

      return (
        <div className="full-height apply ir-container">
          <DocumentTitle
            title={
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.title
                ? this.props.location.state.title
                : "COVID Recertification"
            }
          />
          {/* See applyNavigation.js for documentation on ApplyNavigation props */}
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            skipToAnchor="#content"
          />
          <main
            className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
            id="content"
            tabIndex="-1"
          >
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
            {loginFlag === TextProps.VALUE_TRUE ? (
              <div className="interim-recert__options">
                <LanguageSelect className="interim-recert__language-select" />
                {userRole !== roleProps.PROVIDER &&
                userRole !== roleProps.PROVIDER_ROLE_SPVUSR &&
                userRole !== roleProps.PROVIDER_ROLE_DCPVSG ? (
                  <div className="interim-recert__options-account">
                    <span className="interim-recert__headertext">
                      {common.hi}
                    </span>
                    &nbsp;
                    <DTADropdown
                      buttonText={userInfo.firstName}
                      buttonClass="dta-button dta-button--link"
                      menuClass="list--bordered"
                      caret={TextProps.VALUE_TRUE}
                      caretClass="color-primary"
                    >
                      <li className="pad-all--half">
                        <Link
                          to={routeProps.CONSUMER_MY_INFO_PATH}
                          className="display-block"
                          onClick={this._handleApplyGoBack.bind(this)}
                        >
                          {common.home}
                        </Link>
                      </li>
                      <li className="pad-all--half border-top">
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={() =>
                                this.props.onAzureLogOut(msalInstance)
                              }
                            >
                              {common.logout}
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={this._handleApplyLogoutSession.bind(
                                this
                              )}
                            >
                              {common.logout}
                            </Link>
                          )}
                        </React.Fragment>
                      </li>
                    </DTADropdown>
                  </div>
                ) : (
                  <div className="interim-recert__options-account">
                    <div className="provider-spacer" />
                    <button
                      className="dta-button provider-return"
                      onClick={this.gotoProvier}
                    >
                      <span className="provider-return__text">
                        {common.provider}
                        <br />
                        <Icon name="home" />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <LanguageSelect className="language-select--dark" />
            )}
          </main>
          {this.renderWarningPopup()}
        </div>
      );
    }
  }

  gotoProvier = () => {
    this.props.gotoProvider();
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  downloadIRPDFData() {
    this.props.downloadIRPDFData(this.props.covidRecert.sections);
  }

  convertDOBForDataPost = (dobFrom) => {
    let retrunValue = null;
    if (dobFrom.indexOf(".") !== -1) {
      let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
      let dobArray = dob.split(".");

      let month = ControlConstants.MONTHSHORT.indexOf(dobArray[1]) + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = parseInt(dobArray[0]);
      if (date < 10) {
        date = "0" + date;
      }
      retrunValue = dobArray[2] + "-" + month + "-" + date;
    } else {
      retrunValue = dobFrom;
    }

    return retrunValue;
  };

  prepareDataForSubmission = (activeId) => {
    let reportedChanges = TextProps.VALUE_FALSE;
    let requestBody = null;

    const responseBody = _.cloneDeep(this.state.respData);
    requestBody = _.cloneDeep(responseBody);

    if (this.props.covidRecert.contactInfoScreen.contactInfoChanged) {
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let resAddrVal of respAddress) {
        if (resAddrVal.typeCode === "RES") respResAddress = resAddrVal;
        else if (resAddrVal.typeCode === "MAIL") respMailAddress = resAddrVal;
      }

      let updatedAddress =
        this.props.covidRecert.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let updatedAddrVal of updatedAddress) {
        if (
          updatedAddrVal.typeCode === "RES" &&
          updatedAddrVal.city &&
          updatedAddrVal.line1 &&
          updatedAddrVal.zip
        )
          updatedResAddress = updatedAddrVal;
        else if (updatedAddrVal.typeCode === "MAIL")
          updatedMailAddress = updatedAddrVal;
      }

      let finalAddressArray = [];
      if (respResAddress !== null && updatedResAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedResAddress.line1;
        newAddress.line2 = updatedResAddress.line2;
        newAddress.city = updatedResAddress.city;
        newAddress.state = updatedResAddress.state;
        newAddress.zip = updatedResAddress.zip;
        newAddress.homelessFlag =
          this.props.covidRecert.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
        newAddress.typeCode = "RES";
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed"
        ) {
          newAddress.dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          newAddress.dataChangeCode = "REMOVE";
          reportedChanges = TextProps.VALUE_TRUE;
        } else {
          newAddress.dataChangeCode = "NOCHNG";
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          newAddress.dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        }
        finalAddressArray.push(newAddress);
      } else if (respResAddress === null && updatedResAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedResAddress.line1;
        newAddress.line2 = updatedResAddress.line2;
        newAddress.city = updatedResAddress.city;
        newAddress.state = updatedResAddress.state;
        newAddress.zip = updatedResAddress.zip;
        newAddress.homelessFlag =
          this.props.covidRecert.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
        newAddress.typeCode = "RES";
        newAddress.dataChangeCode = "NEW";
        finalAddressArray.push(newAddress);
        reportedChanges = TextProps.VALUE_TRUE;
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedMailAddress.line1;
        newAddress.line2 = updatedMailAddress.line2;
        newAddress.city = updatedMailAddress.city;
        newAddress.state = updatedMailAddress.state;
        newAddress.zip = updatedMailAddress.zip;
        newAddress.homelessFlag =
          this.props.covidRecert.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
        newAddress.typeCode = "MAIL";
        if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "changed"
        ) {
          newAddress.dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "removed"
        ) {
          newAddress.dataChangeCode = "REMOVE";
          reportedChanges = TextProps.VALUE_TRUE;
        } else {
          newAddress.dataChangeCode = "NOCHNG";
        }
        //Homeless flag check
        if (
          this.props.covidRecert.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          newAddress.dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (finalAddressArray.length > 0)
          finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
        finalAddressArray.push(newAddress);
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedMailAddress.line1;
        newAddress.line2 = updatedMailAddress.line2;
        newAddress.city = updatedMailAddress.city;
        newAddress.state = updatedMailAddress.state;
        newAddress.zip = updatedMailAddress.zip;
        newAddress.homelessFlag =
          this.props.covidRecert.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
        newAddress.typeCode = "MAIL";
        newAddress.dataChangeCode = "NEW";
        reportedChanges = TextProps.VALUE_TRUE;
        if (finalAddressArray.length > 0)
          finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
        finalAddressArray.push(newAddress);
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        if (finalAddressArray.length > 0) {
          finalAddressArray[0].dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }

      requestBody.clientAddress = finalAddressArray;

      //Email change
      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.covidRecert.contactInfoScreen;
      let emailCode = null;
      if (!respEmail.emailAddress && updatedEmail.email) {
        emailCode = "NEW";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respEmail.emailAddress && !updatedEmail.email) {
        emailCode = "REMOVE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respEmail.emailAddress &&
        (respEmail.emailAddress !== updatedEmail.email ||
          respEmail.optInFlag !== updatedEmail.emailOptIn)
      ) {
        emailCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else {
        emailCode = "NOCHNG";
      }

      requestBody.clientEmail.dataChangeCode = emailCode;
      requestBody.clientEmail.emailAddress = updatedEmail.email;
      requestBody.clientEmail.optInFlag =
        updatedEmail.emailOptIn === TextProps.VALUE_Y ||
        updatedEmail.emailOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.covidRecert.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.split(" ").join("")
        : "";
      let phoneCode = null;
      if (!respPhone.phoneNumber && phoneNumber) {
        phoneCode = "NEW";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respPhone.phoneNumber && !phoneNumber) {
        phoneCode = "REMOVE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respPhone.phoneNumber &&
        (respPhone.phoneNumber !== phoneNumber ||
          respPhone.textOptInFlag !== updatedPhone.textOptIn)
      ) {
        phoneCode = "CHANGE";
        reportedChanges = TextProps.VALUE_TRUE;
      } else {
        phoneCode = "NOCHNG";
      }

      requestBody.clientPhone.dataChangeCode = phoneCode;
      requestBody.clientPhone.phoneNumber = phoneNumber;
      requestBody.clientPhone.textOptInFlag =
        updatedPhone.textOptIn === TextProps.VALUE_Y ||
        updatedPhone.textOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;
    }

    if (requestBody.clientAddress.length > 0) {
      let newAddressArray = [];
      requestBody.clientAddress.map((address) => {
        if (address.dataChangeCode === undefined) {
          address.dataChangeCode = "NOCHNG";
        }
        newAddressArray.push(address);
      });
      requestBody.clientAddress = newAddressArray;
    }

    if (requestBody.clientPhone.dataChangeCode === undefined) {
      requestBody.clientPhone.dataChangeCode = "NOCHNG";
    }

    if (requestBody.clientEmail.dataChangeCode === undefined) {
      requestBody.clientEmail.dataChangeCode = "NOCHNG";
    }

    //HOUSEHOLD CHANGE
    if (this.props.covidRecert.householdMembers.membersListUpdated) {
      let newHouseholdList = [];
      for (let membersList of this.props.covidRecert.householdMembers
        .membersList) {
        if (membersList.allAnswers !== undefined) {
          let genderCodeValue = "";
          if (
            membersList.allAnswers[7].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .genderOptios[0]
          ) {
            genderCodeValue = "MALE";
          } else if (
            membersList.allAnswers[7].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .genderOptios[1]
          ) {
            genderCodeValue = "FEMALE";
          }

          let citizenFlagVal = null;
          if (
            membersList.allAnswers[8].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .citizenOptions[0]
          ) {
            citizenFlagVal = TextProps.VALUE_TRUE;
          } else if (
            membersList.allAnswers[8].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .citizenOptions[1]
          ) {
            citizenFlagVal = TextProps.VALUE_FALSE;
          }

          let buyFoodFlagVal = null;
          if (
            membersList.allAnswers[9].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[0]
          ) {
            buyFoodFlagVal = TextProps.VALUE_TRUE;
          } else if (
            membersList.allAnswers[9].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[1]
          ) {
            buyFoodFlagVal = TextProps.VALUE_FALSE;
          }

          let incomeFlagVal = null;
          if (
            membersList.allAnswers[10].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[0]
          ) {
            incomeFlagVal = TextProps.VALUE_TRUE;
          } else if (
            membersList.allAnswers[10].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[1]
          ) {
            incomeFlagVal = TextProps.VALUE_FALSE;
          }

          let newMemberObj = {
            agencyID: 0,
            granteeFlag: TextProps.VALUE_FALSE,
            firstName: membersList.allAnswers[0].Answer,
            middleName: membersList.allAnswers[1].Answer,
            lastName: membersList.allAnswers[2].Answer,
            suffix: this.getSuffixValue(membersList.allAnswers[3].Answer),
            dateOfBirth: this.convertDOBForDataPost(membersList.dateOfBirth),
            SSN: membersList.allAnswers[5].Answer,
            relationshipCode: membersList.allAnswers[6].Answer.toUpperCase(),
            genderCode: genderCodeValue,
            citizenFlag: citizenFlagVal,
            buyFoodFlag: buyFoodFlagVal,
            incomeFlag: incomeFlagVal,
            dataChangeCode: "NEW",
          };
          newHouseholdList.push(newMemberObj);
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          membersList.dataChangeCode &&
          membersList.dataChangeCode === "removed"
        ) {
          let removedMember = membersList;
          let granteeFlagVal = TextProps.VALUE_FALSE;
          if (
            removedMember.granteeFlag === TextProps.VALUE_Y ||
            removedMember.granteeFlag === TextProps.VALUE_TRUE
          ) {
            granteeFlagVal = TextProps.VALUE_TRUE;
          }

          removedMember.dataChangeCode = "REMOVE";
          removedMember.granteeFlag = granteeFlagVal;
          removedMember.dateOfBirth = this.convertDOBForDataPost(
            removedMember.dateOfBirth
          );
          newHouseholdList.push(removedMember);
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (membersList.agencyID > 1) {
          let existingMember = membersList;

          let granteeFlagValue = TextProps.VALUE_FALSE;
          if (
            existingMember.granteeFlag === TextProps.VALUE_Y ||
            existingMember.granteeFlag === TextProps.VALUE_TRUE
          ) {
            granteeFlagValue = TextProps.VALUE_TRUE;
          }
          existingMember.granteeFlag = granteeFlagValue;
          existingMember.dateOfBirth = this.convertDOBForDataPost(
            existingMember.dateOfBirth
          );
          newHouseholdList.push(existingMember);
        }
      }
      requestBody.householdMembers = newHouseholdList;
    }

    if (requestBody.householdMembers.length > 0) {
      let newHouseholdArray = [];
      requestBody.householdMembers.map((member) => {
        let memberGranteeFlagVal;
        if (member.granteeFlag === TextProps.VALUE_Y) {
          memberGranteeFlagVal = TextProps.VALUE_TRUE;
        } else if (member.granteeFlag === TextProps.VALUE_N) {
          memberGranteeFlagVal = TextProps.VALUE_FALSE;
        } else {
          memberGranteeFlagVal = member.granteeFlag;
        }

        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        member.granteeFlag = memberGranteeFlagVal;
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        }
        newHouseholdArray.push(member);
      });
      requestBody.householdMembers = newHouseholdArray;
    }

    //EARNED INCOME CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.earnedIncomeChanged &&
      this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.covidRecert.responseObject.earnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.covidRecert.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                    amountIterator = 4;
                  } else if (
                    getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                  ) {
                    amountIterator = 2;
                  }
                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }

                let changeCode = "NOCHNG";
                switch (incomeSub.code) {
                  case "new":
                    changeCode = "NEW";
                    break;
                  case "changed":
                    changeCode = "CHANGE";
                    break;
                  case "removed":
                    changeCode = "REMOVE";
                    break;
                  default:
                    break;
                }

                typeCode = income.typeCode;

                let idVal;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.id
                ) {
                  idVal = incomeSub.fullIncomeObj.id;
                } else if (member.code === "new") {
                  idVal = 0;
                }

                let agencyIDVal = null;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.agencyID
                ) {
                  agencyIDVal = incomeSub.fullIncomeObj.agencyID;
                } else if (memberObj !== undefined && memberObj.agencyID) {
                  agencyIDVal = memberObj.agencyID;
                } else {
                  agencyIDVal = 0;
                }

                let incomeObj = {
                  id: idVal === undefined ? 0 : idVal,
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: agencyIDVal,
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  employerName: incomeSub.employer,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };

                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      let finalArray = _.cloneDeep(incomeArrayNew);
      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);

        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.typeCode === existing.typeCode
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
        }
      }
      requestBody.earnedIncome = finalArray;
    }

    if (requestBody.earnedIncome.length > 0) {
      let newHouseholdArray = [];
      requestBody.earnedIncome.map((member) => {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (member.id <= 1) {
          member.dataChangeCode = "NEW";
        }
        newHouseholdArray.push(member);
      });
      requestBody.earnedIncome = newHouseholdArray;
    }

    //UNEARNED INCOME CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.unearnedIncomeChanged !== undefined &&
      this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.covidRecert.responseObject.unearnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.covidRecert.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income, index) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                    amountIterator = 4;
                  } else if (
                    getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                  ) {
                    amountIterator = 2;
                  }

                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }
                let changeCode = "NOCHNG";
                switch (incomeSub.code) {
                  case "new":
                    changeCode = "NEW";
                    break;
                  case "changed":
                    changeCode = "CHANGE";
                    break;
                  case "removed":
                    changeCode = "REMOVE";
                    break;
                  default:
                    break;
                }
                typeCode = income.typeCode;

                let idValue;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.id
                ) {
                  idValue = incomeSub.fullIncomeObj.id;
                } else if (member.code === "new") {
                  idValue = 0;
                }

                let agencyIDValue = null;
                if (
                  incomeSub.fullIncomeObj !== undefined &&
                  incomeSub.fullIncomeObj.agencyID
                ) {
                  agencyIDValue = incomeSub.fullIncomeObj.agencyID;
                } else if (memberObj !== undefined && memberObj.agencyID) {
                  agencyIDValue = memberObj.agencyID;
                } else {
                  agencyIDValue = 0;
                }

                let incomeObj = {
                  id: idValue === undefined ? 0 : idValue,
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: agencyIDValue,
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      let finalArray = _.cloneDeep(incomeArrayNew);
      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);
        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.setID === existing.setID
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
        }
      }
      requestBody.unearnedIncome = finalArray;
    }

    if (requestBody.unearnedIncome.length > 0) {
      let newHouseholdArray = [];
      for (let member of requestBody.unearnedIncome) {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (member.id <= 1) {
          member.dataChangeCode = "NEW";
        }
        newHouseholdArray.push(member);
      }
      requestBody.unearnedIncome = newHouseholdArray;
    }

    //CHILE EXP CHANGE
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.childExpScreen !== undefined &&
      this.props.covidRecert.childExpScreen.childExpChanged ===
        TextProps.VALUE_TRUE
    ) {
      let childExpNew = _.cloneDeep(
        this.props.covidRecert.childExpScreen.tempChildExpArray
      );
      let childArrayNew = [];
      if (childExpNew.length > 0) {
        childExpNew.map((member) => {
          if (member.isComplete === TextProps.VALUE_TRUE) {
            let memberDataChangeCode = null;
            if (member.dataChangeCode === "new") {
              memberDataChangeCode = "NEW";
            } else if (member.dataChangeCode === "changed") {
              memberDataChangeCode = "CHANGE";
            } else if (member.dataChangeCode === "removed") {
              memberDataChangeCode = "REMOVE";
            } else {
              memberDataChangeCode = "NOCHNG";
            }

            let incomeObj = {
              id: member.id,
              setID: member.setID,
              agencyID: member.agencyID,
              firstName: member.firstName,
              lastName: member.lastName,
              dateOfBirth: this.convertDOBForDataPost(member.dateOfBirth),
              typeCode: member.typeCode ? member.typeCode : "CHDSPT",
              frequencyCode: getFrequencyCode(language)[member.frequency],
              amount: member.amount
                ? formatters.formatAmountFromStringToNumber(member.amount)
                : "",
              dataChangeCode: memberDataChangeCode,
            };
            childArrayNew.push(incomeObj);
          }
        }); // member ends
      }

      let finalArray = _.cloneDeep(childArrayNew);
      requestBody.childSupportExpense = finalArray;
    }

    if (requestBody.childSupportExpense.length > 0) {
      let newHouseholdArray = [];
      requestBody.childSupportExpense.map((member) => {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        newHouseholdArray.push(member);
      });
      requestBody.childSupportExpense = newHouseholdArray;
    }

    //Other information change
    let otherInfo = [];
    if (
      this.props.covidRecert.sections.length > 4 &&
      this.props.covidRecert.sections[6].questions !== undefined &&
      this.props.covidRecert.sections[6].questions.length > 0
    ) {
      let indexOther = 0;
      this.props.covidRecert.sections[6].questions.map((question, index) => {
        if (
          question.type === "radio" &&
          question.Answer ===
            interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
        ) {
          let otherInfoItem =
            responseBody.otherInformation.workRequirement[indexOther];
          otherInfoItem["dataChangeCode"] = "CHANGE";
          otherInfo.push(otherInfoItem);
          reportedChanges = TextProps.VALUE_TRUE;
          indexOther++;
        } else if (question.type === "radio") {
          let otherInfoItem =
            responseBody.otherInformation.workRequirement[indexOther];
          otherInfoItem["dataChangeCode"] = "NOCHNG";
          otherInfo.push(otherInfoItem);
          indexOther++;
        }
      });
    }
    requestBody.otherInformation.workRequirement = otherInfo;
    if (requestBody.otherInformation.workRequirement.length > 0) {
      let newHouseholdArray = [];
      requestBody.otherInformation.workRequirement.map((member) => {
        if (
          member.dateOfBirth !== undefined &&
          member.dateOfBirth.indexOf("-") >= 0
        )
          member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        }
        newHouseholdArray.push(member);
      });
      requestBody.otherInformation.workRequirement = newHouseholdArray;
    }

    if (reportedChanges) {
      requestBody.dataChangeCode = "CHANGE";
      requestBody.languageCode = language.split("-")[0];
      requestBody.signedName = this.refs.applicanSign.inputEl.value;
      requestBody.webAppTypeCode = "SNCVRC";
    } else {
      //delete requestBody.dataChangeCode
      requestBody.dataChangeCode = "NOCHNG";
      requestBody.languageCode = language.split("-")[0];
      requestBody.signedName = this.refs.applicanSign.inputEl.value;
      requestBody.webAppTypeCode = "SNCVRC";
    }

    this.setState(
      {
        isLoading: TextProps.VALUE_TRUE,
        reportedChanges: reportedChanges,
        requestBody: requestBody,
      },
      () => {
        this.saveFinalSignAndSubmit(activeId);
      }
    );
  };

  getSuffixValue = (answer) => {
    switch (language) {
      case Types.ENGLISH:
        return answer;
      case Types.SPANISH:
        return answer;
      case Types.PORTUGUESE:
        return answer;
      case Types.CHINESE: //chinees
        if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[0]
        )
          return "I";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      case Types.VIETNAMESE:
        return answer;
      case HI:
        if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[3]
        )
          return "IV";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[4]
        )
          return "V";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      default:
        return answer;
    }
  };

  checkForAddressChange = (source, dest) => {
    let streetSource = null;
    if (source) {
      streetSource = (
        source.line1 + (source.line2 !== undefined ? source.line2 : "")
      ).replace(/ /g, "");
    }

    let streetDest = dest ? (dest.line1 + dest.line2).replace(/ /g, "") : null;
    if (
      source !== null &&
      dest !== null &&
      ((source.zip !== "" && dest.zip === "") ||
        (source.city !== "" && dest.city === "") ||
        (streetSource !== "" && streetDest === ""))
    ) {
      return "removed";
    } else if (streetSource !== streetDest) {
      return "changed";
    } else if (source !== null && dest !== null && source.city !== dest.city) {
      return "changed";
    } else if (source !== null && dest !== null && source.zip !== dest.zip) {
      return "changed";
    }
  };

  getMemberDataObjectByNameAndDOB = (name, listToCheck) => {
    let returnMember = null;
    listToCheck.map((member) => {
      let dob = member.dateOfBirth;
      if (dob.indexOf("-") > 0) {
        dob = formatters.formatDate(dob);
      }
      if (
        name.trim() ===
        member.firstName.trim() + " " + member.lastName.trim() + dob
      ) {
        returnMember = member;
      }
    });
    return returnMember;
  };

  renderOtherInformation = () => {
    let section = this.props.covidRecert.sections[6];
    if (!this.state.otherInfoPopulated) {
      let newQuestions = [];
      let otherInfo =
        this.props.covidRecert.responseObject.otherInformation.workRequirement;
      otherInfo.map((info) => {
        let name =
          info.firstName +
          " " +
          info.lastName +
          formatters.formatDate(info.dateOfBirth);
        let questionText = {};
        let questionValue = {};
        let questionDivider = {};
        questionText.type = "text";
        questionText.value = otherInfoCodeMapper(language, name)[
          info.excemptReasonCode
        ];

        newQuestions.push(questionText);

        questionValue.type = "radio";
        questionValue.options = [
          {
            optionDisplay: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[0],
            optionValue: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[0],
          },
          {
            optionDisplay: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[1],
            optionValue: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[1],
          },
        ];
        questionValue.name = info.programID + "_" + info.excemptReasonCode;
        questionValue.optionsPerRow = 2;
        questionValue.label = interimReportPageLabels(
          language,
          languageConstants.otherInfoPage,
          name,
          null,
          null
        ).hasChangedQuestionLabel;
        questionValue.isRequired = TextProps.VALUE_TRUE;
        questionValue.error = TextProps.VALUE_FALSE;
        questionValue.errorMessage = interimReportPageLabels(
          language,
          languageConstants.contactInfo
        ).requiredErrorMessage;
        newQuestions.push(questionValue);
        questionDivider.type = "divider";
        newQuestions.push(questionDivider);
      });
      section.questions = newQuestions;
      this.setState({
        otherInfoPopulated: TextProps.VALUE_TRUE,
      });
    }
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      applyIRSanpQuestionsSections: applyIRSanpQuestionsSections,
      appendIRHouseHoldMembers: appendIRHouseHoldMembers,
      clearHouseHoldMembers: clearHouseHoldMembers,
      appendIRIncomeDetails: appendIRIncomeDetails,
      appendUIIncomeDetails: appendUIIncomeDetails,
      appendIRExpenseDetails: appendIRExpenseDetails,
      clearMemberIncome: clearMemberIncome,
      clearMemberUIIncome: clearMemberUIIncome,
      updateResolveQuestionsData: updateResolveQuestionsData,
      downloadIRPDFData: downloadIRPDFData,
      updateCurrentSectionData: updateCurrentSectionData,
      addStudentToList: addStudentToList,
      setDataChangeStatus: setDataChangeStatus,
      setHouseholdMembers: setHouseholdMembers,
      setContactInfo: setContactInfo,
      setEarnedIncomeChangeStatus: setEarnedIncomeChangeStatus,
      setUnearnedIncomeChangeStatus: setUnearnedIncomeChangeStatus,
      setInterimReportResponseObj: setInterimReportResponseObj,
      submitInterimReportData: postInterimReportData,
      updateHouseholdTextChanges: updateHouseholdTextChanges,
      addContactInfoSubSection: addContactInfoSubSection,
      setChildSupportData: setChildSupportData,
      snapRefreshQuestionsSections: snapRefreshQuestionsSections,
      addHouseholdAddSectionToList: addHouseholdAddSectionToList,
      displayPDFData: displayPDFData,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoHome: () => push(routeProps.HOME_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      displayError: () => push(routeProps.API_ERR_PATH),
    },
    dispatch
  );
}

export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(CovidRecert)
);
