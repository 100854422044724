// Vendor / components
import React, { Component } from "react";
import { matchPath } from "react-router";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { mapStateToProps } from "../../../redux/rootReducer";
// DHR Components
import Icon from "../../../utils/components/dtaIcon";
import DTANavigation from "../../../utils/components/accountNavigation";
import DTAFooter from "../../../utils/components/dtaFooter";
import NotFound from "../../../utils/components/notFound";
import * as formatters from "../../../utils/components/utilities/formatters.js";
//  Components
import ConsumerMyInfo from "./consumerMyInfo";
import ConsumerActivity from "./consumerActivity";
import ConsumerDocuments from "./consumerDocuments";
import ConsumerDTAInfo from "./consumerDTAInfo";
import ConsumerMyAccount from "./myAccount";
import CustomerConcerns from "./consumerConcernAppeal/concernsContainer.js"
import ConsumerBenefitDetail from "./consumerBenefitDetail";
import appealWithdraw from "./consumerConcernAppeal/appealWithdraw.js";
import appealWithdrawSubmit from "./consumerConcernAppeal/appealWithdrawSubmit.js";
import moment from "moment";

import { getAudit } from "../../../utils/constants/audit";
import {
  getConsumerAboutMeData,
  updateClientAddress,
  addrsWarningPopupAction
} from "../../../redux/consumer/consumerMyInfoActions";
import {
  getInterimReportData,
  getRecertData,
  getCovidRecertData,
  hidePopupAction,
  getTrackApplication,
} from "../../../redux/consumer/consumerHomeActions";
// Constants
import * as SharedProps from "../../../utils/constants/shared";
// Nav section data
import { consumerNavSectionsMapData } from "../consumerNavSectionsMapData";
import { push } from "react-router-redux";
import * as roleProps from "../../../utils/constants/rolesConstants";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import DTAModal from "../../../utils/components/modal/dtaModal";
import Modal from "../../../utils/components/modal/dtaModal";
import Radio from "../../../utils/components/radio";
import * as TextProps from "../../../utils/constants/text";
import { Fragment } from "react/cjs/react.production.min";
import * as modalHelperFunction from "../../../utils/components/helperFunctions/modalHelperFunction";
import { ReportChangePageLabels } from "../../reportChange/reportChangeText";
import * as languageConstants from "../../../utils/constants/constants";
import ClientConcern from "./anonymousConcern/clientConcerns.js";
import appealsSubmit from "./consumerConcernAppeal/appealsSubmit.js";
import DOMPurify from "dompurify";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
let addr;
class ConsumerApp extends Component {
  constructor(props) {
    super(...arguments);
    this._handleAddressSelection = this._handleAddressSelection.bind(this);
    this.confirmAddressValidationAction =
      this.confirmAddressValidationAction.bind(this);
    this.state = {
      // User should really come from redux or elsewhere
      user: {
        [SharedProps.FIRST_NAME]: "Guest",
        [SharedProps.LAST_NAME]: "Account",
        [SharedProps.DOB]: new Date(1988, 9, 27),
        [SharedProps.AGENCY_ID]: "2000001",
      },
      navItems: consumerNavSectionsMapData,
      activeItemId: consumerNavSectionsMapData[0][SharedProps.ID],
      SNAP_MSG: TextProps.VALUE_FALSE,
      CASH_MSG: TextProps.VALUE_FALSE,
      SNAP_DATE: null,
      TAFDC_Date: null,
      EAEDC_Date: null,
      CASH_DATE: null,
      TAFDC_MSG: TextProps.VALUE_FALSE,
      EAEDC_MSG: TextProps.VALUE_FALSE,
      providerViewingClient: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      showWarningModal: TextProps.VALUE_FALSE,
      showAddrWarningModal: TextProps.VALUE_FALSE,
      apmntAlertInActivityTab: TextProps.VALUE_FALSE,
      dateTimeWarning: TextProps.VALUE_FALSE,
      slotsNotAvailWarning: TextProps.VALUE_FALSE,
      addressValidationModalOpen: TextProps.VALUE_FALSE,
      enteredResAddressValue: "",
      selectedResAdressValue: "",
      enteredMailingAddressValue: "",
      selectedMailingAddressValue: "",
      addressObj: {},
      finalResValueSelected: "",
      finalMailingValueSelected: "",
      mailingAddressFlag: TextProps.VALUE_FALSE,
      resAddressFlag: TextProps.VALUE_FALSE,
      showSuccessModal:false,
      editingModuleSuccess:"",
       missedApmntAlert:TextProps.VALUE_FALSE,
       trackApplicationModalOpen: TextProps.VALUE_FALSE,
    };
  }

  // Start:  routing helpers
  componentWillReceiveProps(nextProps) {
    this.setLoader();
    // Show Address Validation Popup
    if (nextProps.consumer.showAddressValidationModal) {
      this.setState({
        addressValidationModalOpen: TextProps.VALUE_TRUE,
        addressObj: nextProps.consumer.melissaPayload.addressObj,
        enteredResAddressValue:
          nextProps.consumer.melissaPayload.enteredResAddressValue,
        enteredMailingAddressValue:
          nextProps.consumer.melissaPayload.enteredMailingAddressValue,
        selectedResAdressValue:
          nextProps.consumer.melissaPayload.selectedResAdressValue,
        selectedMailingAddressValue:
          nextProps.consumer.melissaPayload.selectedMailingAddressValue,
        finalResValueSelected:
          nextProps.consumer.melissaPayload.finalResValueSelected,
        finalMailingValueSelected:
          nextProps.consumer.melissaPayload.finalMailingValueSelected,
        mailingAddressFlag:
          nextProps.consumer.melissaPayload.mailingAddressFlag,
        resAddressFlag: nextProps.consumer.melissaPayload.resAddressFlag,
      });
    } else {
      this.setState({ addressValidationModalOpen: TextProps.VALUE_FALSE });
    }
    // Warning Popup shows technical issue
    if (nextProps.consumer.showPopupFlag) {
      this.setState({ showWarningModal: TextProps.VALUE_TRUE });
    } else {
      this.setState({ showWarningModal: TextProps.VALUE_FALSE });
    }
    // Warning Popup shows date/time nolonger available
    if (nextProps.consumer.dateTimeNotAvailable) {
      this.setState({ dateTimeWarning: TextProps.VALUE_TRUE });
    } else {
      this.setState({ dateTimeWarning: TextProps.VALUE_FALSE });
    }
    if (nextProps.consumer.slotsNotAvailable) {
      this.setState({ slotsNotAvailWarning: TextProps.VALUE_TRUE });
    } else {
      this.setState({ slotsNotAvailWarning: TextProps.VALUE_FALSE });
    }
    if (nextProps.consumer.showAppointmentAlert === TextProps.VALUE_TRUE) {
      this.setState({ apmntAlertInActivityTab: TextProps.VALUE_TRUE });
    } else {
      this.setState({ apmntAlertInActivityTab: TextProps.VALUE_FALSE });
    }
    // Warning Address change request
    if (nextProps.consumer.showAddrWarningModal) {
      this.setState({ showAddrWarningModal: TextProps.VALUE_TRUE });
    } else {
      this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE });
    }
      var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    if(userDetails && userDetails.alerts && userDetails.alerts.appointments){
      let isShowMissedBanner= this.checkDateAndStatus(userDetails.alerts.appointments)
      this.setState({
        missedApmntAlert : isShowMissedBanner
      })
  }

    if(this.successModal){
      if (nextProps && nextProps.consumer.postDataLoading) {
          this.setReportChangeSuccessMessage();
          this.showSuccessModal();
          this.successModal.showBlur("loading", null);
      }
  
      if (nextProps && nextProps.consumer.postDataSuccess) {
       setTimeout(
        this.successModal.showBlur(
          "success",
          <div>{this.state.editingModuleSuccess}</div>),500);
        setTimeout(() => {
          window.location.reload();
        }, 6000);
      }
  
      if (nextProps && nextProps.consumer.postDataError) {
        this.successModal.showBlur(
          "error",
          <div>{
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).tryAgain
          }</div>
        );
        setTimeout(() => {
          // window.location.reload();
          this.closeSuccessModal();
        }, 5000);
      }
    }
  }
 checkDateAndStatus(appointmentsArray) {
    let today = new Date();
    const dateObject = new Date(today);
    return appointmentsArray.some(alerts => {
        if (alerts.appointmentChangeRequestStatusCode === "MISSED") {
            let itemDate = new Date(alerts.endTime);
            let differenceInTime = today.getTime() - itemDate.getTime();
            let differenceInDays = differenceInTime / (1000 * 3600 * 24);  // Convert difference to days
            return differenceInDays <= 3  &&  differenceInDays > 0  ;  // we can set it in config if neeeded
        }
        return false ;
    });
  }
  setReportChangeSuccessMessage = () =>{
    let editingModule = window.sessionStorage.getItem('module');
    let editingModuleSuccess = ""
    if(editingModule){
    switch (editingModule) {
      case "pregnancy":
        editingModuleSuccess =  ReportChangePageLabels(
          language,
          languageConstants.aboutMe
        ).pregnancySuccessMessage;
          break;
      case "disability":
        editingModuleSuccess =  ReportChangePageLabels(
          language,
          languageConstants.aboutMe
        ).disabilitySuccessMessage;
          break;
      case "attendance":
        editingModuleSuccess = ReportChangePageLabels(
          language,
          languageConstants.aboutMe
        ).attendanceSuccessMessage;
          break;
      default:
          return ""; 
  }
  this.setState({editingModuleSuccess})
  }
  }

  setLoader = () => {
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let status = TextProps.VALUE_TRUE;
    if (this.props.consumer.consumerLoaded === TextProps.VALUE_TRUE) {
      if (consumerDataUserObj !== undefined || consumerDataUserObj !== null) {
        status = TextProps.VALUE_FALSE;
      }
    } else {
      status = TextProps.VALUE_FALSE;
    }
    this.setState({ isLoading: status });
  };

  /**
   * here navigate through provider and mounting to consumer
   */
  componentWillMount() {
       var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
       console.log(userDetails,"userDetails+++");

    localStorage.getItem("selectedLanguage") === null ||
    localStorage.getItem("selectedLanguage") === "undefined"
      ? localStorage.setItem("selectedLanguage", "en-US")
      : "";
    this.setLoader();
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    var providerData = JSON.parse(sessionStorage.getItem("providerData"));
    if (userObject !== null) {
      var UserObj = userObject;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }
    if (this.props) {
      let locationPath = this.props.location;
      let isProvider = locationPath.search;
      if (isProvider === "?provider=TextProps.VALUE_TRUE") {
        this.setState({ providerViewingClient: TextProps.VALUE_TRUE });
        const userObj = this.props.consumer.userInfo;
        sessionStorage.setItem("providerData", JSON.stringify(userObj));
        if (this.props.consumer.loginDetails !== undefined) {
          userObj.userId = this.props.consumer.loginDetails.email;
          if (userObj.userId !== "") {
            this.setState({ user: userObj });
          }
        } else {
          this.setState({ user: userObj });
        }
      } else if (
        Object.keys(this.props.consumer.consumerData).length > 0 &&
        !providerData
      ) {
        if (this.props.consumer.consumerData.clientInfo !== undefined) {
          let isUserObj = this.props.consumer.loginDetails;
          if (this.props.consumer.consumerData.clientInfo.agencyID > 0) {
            this.setState({ navItems: consumerNavSectionsMapData });
            this.setState({ user: isUserObj });
          } else {
            this.setState({ navItems: [consumerNavSectionsMapData[0]] });
            this.setState({ user: isUserObj });
          }
        } else if (
          consumerDataUserObj !== null &&
          consumerDataUserObj !== undefined &&
          consumerDataUserObj.clientInfo.agencyID > 0
        ) {
          this.setState({ navItems: consumerNavSectionsMapData });
          this.setState({ user: UserObj });
        }
      } else {
        let providerFlag = TextProps.VALUE_FALSE;
        userObject.roleList.forEach((role) => {
          if (
            role.roleName === roleProps.PROVIDER ||
            role.roleName === roleProps.PROVIDER_ROLE_SPVUSR ||
            role.roleName === roleProps.PROVIDER_ROLE_DCPVSG
          ) {
            providerFlag = TextProps.VALUE_TRUE;
          }
        });
        if (providerData !== null && providerData !== undefined) {
          this.setState({ user: providerData });
        } else if (userObject !== null && userObject !== undefined) {
          this.setState({ user: userObject });
        }
        this.setState({ providerViewingClient: providerFlag });
      }
      this.props.getConsumerAboutMeData(UserObj.email);
    }
    if(!!userObject)
    this.setState({ user: UserObj });
  }

  cancelAddressValidationAction = () => {
    this.setState({ addressValidationModalOpen: false });
    setTimeout(() => {document.getElementById("edit-address-button").click()},500);
  };

  _handleAddressSelection = (option, value) => {
    let selectedResAddress = {};
    let selectedMailingAddress = {};
    if (option === "suggestedResAddress") {
      selectedResAddress = this.state.addressObj.originalSuggestedResAddress[0];
      let resAddr = this.state.addressObj.fields[0];
      resAddr.value = selectedResAddress;
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "enteredResAddress") {
      selectedResAddress = this.state.addressObj.originalEnteredResAddress;
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "suggestedMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalSuggestedMailingAddress[0];
      let mailingAddr = this.state.addressObj.fields[1];
      mailingAddr.value = selectedMailingAddress;
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    } else if ("enteredMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalEnteredMailingAddress;
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    }
  };
  confirmAddressValidationAction = () => {
    let clientData = JSON.parse(sessionStorage.getItem("clientDetails"));
    let selectedResAddress = this.state.finalResValueSelected
      ? this.state.finalResValueSelected
      : this.state.addressObj.originalEnteredResAddress;
    let selectedMailingAddress = this.state.finalMailingValueSelected
      ? this.state.finalMailingValueSelected
      : this.state.addressObj.originalEnteredMailingAddress;
    clientData.clientAddress.map((addr, index) => {
      if (addr.typeCode === "RES") {
        addr.addressLine1 = selectedResAddress.line1;
        addr.addressLine2 = selectedResAddress.line2;
        addr.line1 = selectedResAddress.line1;
        addr.line2 = selectedResAddress.line2;
        addr.street = selectedResAddress.line1 + " " + selectedResAddress.line2;
        addr.county = selectedResAddress.county;
        addr.city = selectedResAddress.city;
        addr.state = selectedResAddress.state;
        addr.zip = selectedResAddress.zip
          ? selectedResAddress.zip
          : selectedResAddress.zipCode;
        addr.zipCode = selectedResAddress.zip
          ? selectedResAddress.zip
          : selectedResAddress.zipCode;
        addr.verified = selectedResAddress.verified
          ? selectedResAddress.verified
          : TextProps.VALUE_N;
      }
      if (addr.typeCode === "MAIL") {
        addr.addressLine1 = selectedMailingAddress.line1;
        addr.addressLine2 = selectedMailingAddress.line2;
        addr.line1 = selectedMailingAddress.line1;
        addr.line2 = selectedMailingAddress.line2;
        addr.street =
          selectedMailingAddress.line1 + " " + selectedMailingAddress.line2;
        addr.county = selectedMailingAddress.county;
        addr.city = selectedMailingAddress.city;
        addr.state = selectedMailingAddress.state;
        addr.zip = selectedMailingAddress.zip
          ? selectedMailingAddress.zip
          : selectedMailingAddress.zipCode;
        addr.zipCode = selectedMailingAddress.zip
          ? selectedMailingAddress.zip
          : selectedMailingAddress.zipCode;
        addr.verified = selectedMailingAddress.verified
          ? selectedMailingAddress.verified
          : TextProps.VALUE_N;
      }
    });
    // sessionStorage.setItem("clientDetails", JSON.stringify(clientData));
    // this.state.addressObj.fields = clientData.clientAddress;
    // if(!this.state.addressObj.fields[0].resAddrMatchFlag){
    //   this.props.mailingAddressValidationPopUpAction();
    // }
    // this.setState({ addressValidationModalOpen: false });
    // setTimeout(() => this.setState({ addressValidationModalOpen: false }),100);
    this.setState({ addressValidationModalOpen: false }, () => {
      this.props.updateClientAddress(
        this.state.addressObj.fields,
        this.state.addressObj.agencyId
      );
    });
    this.setState({ finalResValueSelected: "", finalMailingValueSelected: "" });
  };
  renderIcon = (completed) => {
    return completed === TextProps.VALUE_Y ? (
      <div className="verified-box">
        <div><Icon
        name="check-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Verified</div>
      </div>
    ) : (
      <div className="verified-box"><div><Icon
        name="cross-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Not Verified</div>
      </div>
    );
  };
  //Render DHR modal for address validation
  renderAddressValidationModal = () => {
    return (
      <Modal
        isOpen={this.state.addressValidationModalOpen}
        onClose={this.cancelAddressValidationAction}
        modalClass="address-validation-modal"
        titleText="Address Validation"
        headerText="Address Validation"
      >
        <div className="dta-modal__body pad-all">
          {
            <div className="pure-g margin-bottom-half is-mail-address">
              {this.state.resAddressFlag && (
                <div className="pure-u-1">
                  <label className="dta-form__label">
                    Please select from the results below.
                  </label>
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label ">
                      Entered home address:
                      {this.renderIcon(this.state.addressObj.originalSuggestedResAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-1"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredResAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue:
                            this.state.addressObj.enteredResAddress[0],
                          selectedResAdressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      Suggested home address:
                    </label>
                    <Radio
                      name="contact-info-changed-2"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedResAdressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue: "",
                          selectedResAdressValue:
                            this.state.addressObj.suggestedResAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.mailingAddressFlag && (
                <div className="pure-u-1">
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label">
                      Entered mailing address:
                      {this.renderIcon(this.state.addressObj.originalSuggestedMailingAddress[0].verified)}
                    </label>
                    <Radio
                      name="contact-info-changed-3"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue:
                            this.state.addressObj.enteredMailingAddress[0],
                          selectedMailingAddressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      Suggested mailing address:
                    </label>
                    <Radio
                      name="contact-info-changed-4"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue: "",
                          selectedMailingAddressValue:
                            this.state.addressObj.suggestedMailingAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="dta-modal__footer dta-modal__footer--inline-buttons address-row-footer pure-u-1">
                <button
                  className="dta-button dta-button--large dta-button--primary address-row-footer-buttons address-row-footer-buttons-confirm"
                  onClick={this.confirmAddressValidationAction}
                >
                  Confirm
                </button>
                <button
                  className="dta-button dta-button--large dta-button--outline-primary address-row-footer-buttons address-row-footer-buttons-cancel"
                  onClick={this.cancelAddressValidationAction}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };

  componentDidUpdate(prevProps) {
    this.setActiveItemId();
    if (
      prevProps.consumer.consumerData.clientInfo !== undefined &&
      prevProps.consumer.consumerData.clientInfo.agencyID !==
        this.props.consumer.consumerData.clientInfo.agencyID
    ) {
      this.setState({ navItems: consumerNavSectionsMapData });
    }
  }

  getActiveItemId = () => {
    let found = undefined;
    this.state.navItems.forEach((item) => {
      const match = matchPath(this.props.location.pathname, {
        path: `/${item[SharedProps.ID]}`,
        exact: TextProps.VALUE_TRUE,
        strict: TextProps.VALUE_FALSE,
      });
      if (match) {
        found = item[SharedProps.ID];
      }
    });
    return found;
  };

  setActiveItemId = () => {
    const activeItemId = this.getActiveItemId();
    if (activeItemId && activeItemId !== this.state.activeItemId) {
      this.setState({ activeItemId: activeItemId });
      // Scroll back to the top of the page
      window.scrollTo(0, 0);
    }
  };
  // End:  routing helpers

  resolveComponentFromId(id) {
    switch (id) {
      case "myInfo":
        return ConsumerMyInfo;
      case "activity":
        return ConsumerActivity;
      case "documents":
        return ConsumerDocuments;
      case "dtaInfo":
        return ConsumerDTAInfo;
      case "myAccount":
        return ConsumerMyAccount;
      case "concernappeal":
          return CustomerConcerns;
      default:
        return NotFound;
    }
  }

  redirectRecert = (recertType) => {
    sessionStorage.setItem("recertType", recertType);
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    var consumerNewUserObj = JSON.parse(
      sessionStorage.getItem("consumerAddrData")
    );
    if (
      this.props.consumer.consumerData.clientInfo !== undefined &&
      this.props.consumer.consumerData.clientInfo.agencyID
    ) {
      var agencyId = this.props.consumer.consumerData.clientInfo.agencyID;
    } else if (
      this.props.consumer.consumerData.clientInfo !== undefined &&
      this.props.consumer.consumerData.clientInfo.accountId
    ) {
      agencyId = this.props.consumer.consumerData.clientInfo.accountId;
    } else if (
      consumerDataUserObj !== null &&
      consumerDataUserObj.clientInfo !== undefined &&
      consumerDataUserObj.clientInfo.agencyID
    ) {
      agencyId = consumerDataUserObj.clientInfo.agencyID;
    } else if (consumerNewUserObj !== null) {
      agencyId = consumerNewUserObj.accountId;
    }
    var auditInfo = getAudit();
    this.setState(
      {
        isLoading: TextProps.VALUE_TRUE,
      },
      () => {
        this.props
          .startRecert(agencyId, "SNAP", auditInfo, recertType)
          .then((result) => {
            if (result) {
              if(recertType === "REEVAL"){
                this.props.goToTAFDC();
              }else if(recertType === "IR"){
                this.props.goToIR();
              } else {
              this.props.goToRecert();
              }
            } else {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.displayError();
            }
          })
          .catch((error) => {
            this.setState({ isLoading: TextProps.VALUE_FALSE });
            this.props.displayError();
          });
      }
    );
  };

  redirectInterim = () => {
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    var consumerNewUserObj = JSON.parse(
      sessionStorage.getItem("consumerAddrData")
    );
    if (
      this.props.consumer.consumerData.clientInfo !== undefined &&
      this.props.consumer.consumerData.clientInfo.agencyID
    ) {
      var agencyId = this.props.consumer.consumerData.clientInfo.agencyID;
    } else if (
      this.props.consumer.consumerData.clientInfo !== undefined &&
      this.props.consumer.consumerData.clientInfo.accountId
    ) {
      agencyId = this.props.consumer.consumerData.clientInfo.accountId;
    } else if (
      consumerDataUserObj !== null &&
      consumerDataUserObj.clientInfo !== undefined &&
      consumerDataUserObj.clientInfo.agencyID
    ) {
      agencyId = consumerDataUserObj.clientInfo.agencyID;
    } else if (consumerNewUserObj !== null) {
      agencyId = consumerNewUserObj.accountId;
    }
    var auditInfo = getAudit();
    this.setState(
      {
        isLoading: TextProps.VALUE_TRUE,
      },
      () => {
        this.props
          .startInterimReport(agencyId, "SNAP", auditInfo)
          .then((result) => {
            if (result) {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.goToIR();
            } else {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.displayError();
            }
          })
          .catch((error) => {
            this.setState({ isLoading: TextProps.VALUE_FALSE });
            this.props.displayError();
          });
      }
    );
  };

  redirectCovidRecert = () => {
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    var consumerNewUserObj = JSON.parse(
      sessionStorage.getItem("consumerAddrData")
    );
    if (this.props.consumer.consumerData.agencyId) {
      var agencyId = this.props.consumer.consumerData.agencyId;
    } else if (this.props.consumer.consumerData.accountId) {
      agencyId = this.props.consumer.consumerData.accountId;
    } else if (
      consumerDataUserObj !== null &&
      consumerDataUserObj.clientInfo !== undefined &&
      consumerDataUserObj.clientInfo.agencyID
    ) {
      agencyId = consumerDataUserObj.clientInfo.agencyID;
    } else if (consumerNewUserObj !== null) {
      agencyId = consumerNewUserObj.accountId;
    }
    var auditInfo = getAudit();
    this.setState(
      {
        isLoading: TextProps.VALUE_TRUE,
      },
      () => {
        this.props
          .startCovidRecert(agencyId, "SNAP", auditInfo)
          .then((result) => {
            if (result) {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.goToCovidRecert();
            } else {
              this.setState({ isLoading: TextProps.VALUE_FALSE });
              this.props.displayError();
            }
          })
          .catch((error) => {
            this.setState({ isLoading: TextProps.VALUE_FALSE });
            this.props.displayError();
          });
      }
    );
  };

  closeWarningModal = () => {
    this.props.hidePopupAction();
    window.location.assign("/consumer/activity");
  };

  modalContent = () => {
    const isReportChangeEditingModule = this.props.consumer.reportChangeEditingModule;
   
    return (
      <div style={{ padding: "10px" }}>
        {!this.state.dataChangeStatus ? <React.Fragment>
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).didNotMakeChange
          }
        </span>
        <br />
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).clickGoBack
          }
        </span>
        </React.Fragment> : <React.Fragment> 

        <span>
        {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).dataChangeWarning
          }
        </span>
        <br />
        <span>
          {
            ReportChangePageLabels(
              language,
              languageConstants.aboutMe
            ).clickGoBack
          }
        </span>

        </React.Fragment> }
        <br />
        <div style={{ display: "flex", float: "right", padding: "10px" }}>
          <button
            className="dta-button dta-button--outline-primary"
            style={{ marginRight: "10px" }}
            onClick={isReportChangeEditingModule ? this.closeRcWarningModal : this.closeAddrsWarningModal}
          >
            Cancel
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={isReportChangeEditingModule ? this.okRCWarningModal : this.okAddrsWarningModal}
          >
            Ok
          </button>
        </div>
      </div>
    )
  }
  closeAddrsWarningModal = () => {
   var type = sessionStorage.getItem("editType");
    if(type === "address" ){
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    setTimeout(() => {document.getElementById("edit-address-button").click()},500);
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
    }else if(type === "inputPhone"){
      this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
      setTimeout(() => {document.getElementById("edit-phone-button").click()},500);
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
    }else if(type === "inputText"){
      this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
      setTimeout(() => {document.getElementById("edit-email-button").click()},500);
      this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
    }
    sessionStorage.setItem("editType","");
  };
  closeRcWarningModal = () => {
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE,this.props.consumer.reportChangeEditingModule,"close");
  };
  okRCWarningModal = () => {
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE,this.props.consumer.reportChangeEditingModule,"ok");
  }
  okAddrsWarningModal = () => {
    var type = sessionStorage.getItem("editType");
    if(type === "address" ){
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
    }else if(type === "inputPhone"){
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
    }else if(type === "inputText"){
    this.setState({ showAddrWarningModal: TextProps.VALUE_FALSE});
    this.props.addrsWarningPopupAction(TextProps.VALUE_FALSE);
  }
    sessionStorage.removeItem("editType")
  }
  renderWarningPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.warningModal = instance),
      this.state.showAddrWarningModal,
      this.closeAddrsWarningModal,
      "",
      "mh-provider",
      false,false,false,
      ReportChangePageLabels(
        language,
        languageConstants.aboutMe
      ).areYouSure
      ,this.modalContent(),
      true
    );
  };

  showSuccessModal = () => {
    this.setState({ showSuccessModal: TextProps.VALUE_TRUE });
  };

  closeSuccessModal = () => {
    this.setState({ showSuccessModal: TextProps.VALUE_FALSE });
  };

  renderSubmitPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.successModal = instance),
      this.state.showSuccessModal,
      this.closeSuccessModal,
      "report-change-modal",
      "mh-provider",
      false
    );
  };

  openTrackApplicationModal = () => {
    this.setState({
      trackApplicationModalOpen: TextProps.VALUE_TRUE,
    })
  }

  closeTrackApplicationModal = ()  => {
    this.setState({ trackApplicationModalOpen: TextProps.VALUE_FALSE })
  }

  returnTypeDescription = (type) => {
    if(type === "SNPAPP") {
      return "SNAP"
    }
    if(type === "TANF") {
      return "TANF"
    }
    if(type === "TNFRCT") {
      return "TANF Redetermination"
    }
    if(type === "SNPRCT") {
      return "SNAP Recertification"
    }
  }

  render() {
    const {
      location: { state },
      consumer,
    } = this.props;

    const { activeItemId } = this.state;
    const isConcernAppeal = activeItemId !== "concernAppealHome" && activeItemId !== "concernappeal";
    const isNotMyInfo = !(window.location.pathname === "/consumer/concernappeal/withdraw" && activeItemId === "myInfo" || window.location.pathname === "/consumer/concernappeal/appealWithDrawSubmit"  && activeItemId === "myInfo");
    const isShowBanners = isConcernAppeal && isNotMyInfo;

    const { home, info, common } = this.props.language.consumer;
    const pEbtClientData = this.props.consumer.pEbtClient;
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    const benefitListResult =
      userDetails !== null &&
      userDetails.benefitInfo !== undefined &&
      userDetails.benefitInfo !== null
        ? userDetails.benefitInfo
        : null;
    var userRole = userObject.roleList[1]
      ? userObject.roleList[1].roleName
      : userObject.roleList[0].roleName;
    var showBenefitListInfo = [];

    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);

    // Logic for showing the Appointment urgent alert
    let showApmntAlert = TextProps.VALUE_FALSE;
    if (userDetails !== null && userDetails !== undefined) {
      if (userDetails.alerts !== undefined && userDetails.alerts !== null) {
        if (userDetails.alerts.apptChangeAllowedFlg === "Y") {
          showApmntAlert = TextProps.VALUE_TRUE;
        }
      }
    }
    if (
      this.props.consumer !== undefined &&
      this.props.consumer.centrifyData.benefitInfo !== undefined &&
      this.props.consumer.centrifyData.benefitInfo.length > 0 &&
      userRole !== roleProps.PROVIDER_ROLE_DCPVSG
    ) {
      showBenefitListInfo = this.props.consumer.centrifyData.benefitInfo;
    } else if (benefitListResult !== null) {
      showBenefitListInfo = benefitListResult;
    }
    let _SNAP_MSG,
      _SNAP_DATE,
      _CASH_MSG,
      _CASH_DATE,
      _TAFDC_MSG,
      _TAFDC_TYPE,
      _TAFDC_DATE,
      _EAEDC_MSG,
      _EAEDC_DATE,
      _RECERT_TYPE,
      ebtCardRequestDate,
      ebtCardAnticipatedDeliveryDate,
      isEligibleForEBTReplacement,
      caseManagerName,
      _irOverdueMonth,
      RECERT_OVERDUE_MONTH,
      AESAP_OVERDUE_MONTH,
      caseManagerPhoneNumber;
    //itrate to get alert messages data , if one avail the flag no need set
    if (showBenefitListInfo.length > 0) {
      for (let showBenefitItem of showBenefitListInfo) {
        //To show up the EBT Card request alret
        isEligibleForEBTReplacement =
          showBenefitItem.isEligibleForEBTReplacement;
        if (showBenefitItem.ebtCardTrackingInfo !== undefined) {
          if (
            showBenefitItem.ebtCardTrackingInfo
              .ebtCardAnticipatedDeliveryDate !== undefined
          ) {
            ebtCardAnticipatedDeliveryDate =
              showBenefitItem.ebtCardTrackingInfo
                .ebtCardAnticipatedDeliveryDate;
          }
          if (
            showBenefitItem.ebtCardTrackingInfo.ebtCardRequestDate !== undefined
          ) {
            ebtCardRequestDate =
              showBenefitItem.ebtCardTrackingInfo.ebtCardRequestDate;
          }
        }

        let firstName = showBenefitItem.caseManagerName.first;
        let middleName =
          showBenefitItem.caseManagerName.middle === undefined
            ? ""
            : showBenefitItem.caseManagerName.middle;
        let lastName = showBenefitItem.caseManagerName.last;
        caseManagerPhoneNumber = showBenefitItem.caseManagerPhone.phoneNumber;
        caseManagerName = firstName + " " + middleName + " " + lastName + " ";

        if (showBenefitItem.alertMessages === undefined) continue;
        let alertMessages = showBenefitItem.alertMessages;

        if (alertMessages.SNAP_FLAG === "Yes") {
          _SNAP_MSG = alertMessages.SNAP_FLAG === "Yes";
          _SNAP_DATE = alertMessages.NEXT_RECERT_DUEDATE;
          _RECERT_TYPE = alertMessages.RECERT_TYPE;
          _irOverdueMonth = alertMessages.IR_OVERDUE_MONTH;
          RECERT_OVERDUE_MONTH = alertMessages.RECERT_OVERDUE_MONTH;
          AESAP_OVERDUE_MONTH = alertMessages.AESAP_OVERDUE_MONTH;
          sessionStorage.setItem("interimOverdueMonth", _irOverdueMonth)
          sessionStorage.setItem("interimDueDate", _SNAP_DATE)
        }
        if (alertMessages.CASH_FLAG === "Yes") {
          _CASH_MSG = alertMessages.CASH_FLAG === "Yes";

          _CASH_DATE = alertMessages.NEXT_RECERT_DUEDATE;
        }
        if (alertMessages.TAFDC_FLAG === "Yes") {
          _TAFDC_MSG = alertMessages.TAFDC_FLAG === "Yes";
          _TAFDC_TYPE = alertMessages.RECERT_TYPE;
          _TAFDC_DATE = alertMessages.NEXT_RECERT_DUEDATE;
        }
        if (alertMessages.EAEDC_FLAG === "Yes") {
          _EAEDC_MSG = alertMessages.EAEDC_FLAG === "Yes";
          _EAEDC_DATE = alertMessages.NEXT_RECERT_DUEDATE;
        }
      }
    }
    let ebtAlert = null;
    let requestEbtCardMsg = null;
    if (ebtCardRequestDate !== null) {
      requestEbtCardMsg = (
        <strong>
          {info.requestEbtCardMsg1}
          {formatters.convertDateToStateFormat(ebtCardRequestDate)}
          {info.requestEbtCardMsg2}
        </strong>
      );
    }
    if (
      isEligibleForEBTReplacement === TextProps.VALUE_Y &&
      (ebtCardAnticipatedDeliveryDate !== null || ebtCardRequestDate !== null)
    ) {
      ebtAlert = (
        <div className="notification notification--urgent">
          <p className="margin-top-none notification-message">
            <Icon name="notice" />
            {ebtCardAnticipatedDeliveryDate !== null ? (
              <strong>
                {info.anticipatedDeliveryDateMsg}
                {formatters.convertDateToStateFormat(
                  ebtCardAnticipatedDeliveryDate
                )}
                {info.dot}
              </strong>
            ) : (
              requestEbtCardMsg
            )}
          </p>
        </div>
      );
    }

    let inprogressApps = {}; 
    this.props.apply.sections.map(item => inprogressApps[item.appType] = item);
            let loadConsumerUser = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
            let isSnapFlag = loadConsumerUser && loadConsumerUser.benefitInfo && loadConsumerUser.benefitInfo.length && loadConsumerUser.benefitInfo[0] && loadConsumerUser.benefitInfo[0].alertMessages ? loadConsumerUser.benefitInfo[0].alertMessages.SNAP_FLAG : "";
    let trackAppDataList = this.props.consumer;
    let trackConsumer = trackAppDataList ? trackAppDataList.trackAppData : "";
    
            return (
      <div>
      <Fragment>
          <div className="consumer">
            <div className="content-align">
              <main className="main" id="content">
          <DTANavigation
            navItems={this.state.navItems}
            activeItemId={this.state.activeItemId}
            user={this.state.user}
            provider={TextProps.VALUE_FALSE}
            providerViewingClient={this.state.providerViewingClient}
            skipToAnchor="#content"
          />
        {
          isShowBanners ? (
            <div className="max-width-myinfo max-width--three-quarters pad-all--double pad-bottom-none">
              {_SNAP_MSG === TextProps.VALUE_TRUE &&
              showBenefitListInfo.length > 0 &&
              (userRole === roleProps.CONSUMER ||
                userRole === roleProps.PROVIDER ||
                userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
                userRole === roleProps.PROVIDER_ROLE_DCPVSG) &&
              _RECERT_TYPE === "IR" ? (
                <div className="notification notification--urgent">
                  {"SNAPIR" in inprogressApps ? (<p className="margin-top-none notification-message">
                   <Icon name="notice" />
                   <strong>{home.interimTxtContinue1} </strong>
                   <strong>
                     {formatters.convertTimestampToToDataFormat(inprogressApps["SNAPIR"].updatedDt)}
                   </strong>
                   <strong>{home.interimTxtContinue2} </strong>
                   <strong>
                   {formatters.convertTimestampToTimeFormat(inprogressApps["SNAPIR"].updatedDt)}
                   </strong>
                   <strong>{home.interimTxtContinue3} </strong>
                   <strong>
                   {inprogressApps["SNAPIR"].pendingHours.split(":")[0]}
                   </strong>
                   <strong>{home.interimTxtContinue4} </strong>
                   </p>) : _irOverdueMonth === '6'? (
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.interimTxt} </strong>
                    <strong>
                    {formatters.convertStringDateToMonth(_SNAP_DATE)}
                      {home.interimTxtSecondLine}
                      {formatters.convertStringDateToMonth(_SNAP_DATE)}.
                    </strong>{" "}
                  </p>
                    ): 
                    (<p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.interimTxtSevenMonth} </strong>
                    <strong>
                      {formatters.convertStringDateToMonth(_SNAP_DATE, TextProps.VALUE_TRUE)+" "+ home.interimTxtSevenMonthSecLine}
                    </strong>{" "}
                    
                    </p>)}
                  <p style={{ width: "75%" }}></p>
                  <button
                    className="dta-button dta-button--primary"
                    style={{ marginLeft: "60%", width: "40%" }}
                    onClick={() => this.redirectRecert(_RECERT_TYPE)}
                  >
                    {"SNAPIR" in inprogressApps ? home.continue : home.interimButtonTxt}
                  </button>
                </div>
              ) : null}
              {_SNAP_MSG === TextProps.VALUE_TRUE &&
              showBenefitListInfo.length > 0 &&
              (userRole === roleProps.CONSUMER ||
                userRole === roleProps.PROVIDER ||
                userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
                userRole === roleProps.PROVIDER_ROLE_DCPVSG) &&
              _RECERT_TYPE === "AESAP" ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                  {trackConsumer !== undefined && trackConsumer.length > 0 && (
                <button
              className="dta-button dta-button--primary margin-bottom figuring12"
              onClick={this.openTrackApplicationModal}
            >
              {home.trackRecertApplicationText}
            </button>
                  )}
                <div className="notification notification--urgent">
                  {"SNPRCT" in inprogressApps ? <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.aesapTxtContinue1} </strong>
                    <strong>
                     {formatters.convertTimestampToToDataFormat(inprogressApps["SNPRCT"].updatedDt)}
                   </strong>
                   <strong>{home.aesapTxtContinue2} </strong>
                   <strong>
                   {formatters.convertTimestampToTimeFormat(inprogressApps["SNPRCT"].updatedDt)}
                   </strong>
                   <strong>{home.aesapTxtContinue3} </strong>
                   <strong>
                   {inprogressApps["SNPRCT"].pendingHours.split(":")[0]}
                   </strong>
                   <strong>{home.aesapTxtContinue4} </strong>
                  </p> : (AESAP_OVERDUE_MONTH == 37) ? <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.newaesapelseTxt} </strong>
                    <strong>
                     {formatters.convertStringDateToMonth(_SNAP_DATE, TextProps.VALUE_TRUE)}
                   </strong>
                   <strong>{home.newaesapelseContinue1} </strong>
                  </p> : <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.newaesapTxt} </strong>
                    <strong>
                      {formatters.convertStringDateToMonth(_SNAP_DATE)}.
                    </strong>{" "}
                  </p>}
                  <p style={{ width: "75%" }}></p>
                  <button
                    className="dta-button dta-button--primary"
                    style={{ marginLeft: "75%", width: "25%" }}
                    onClick={() => this.redirectRecert(_RECERT_TYPE)}
                  >
                    {"SNPRCT" in inprogressApps ? home.continue : home.aesapButtonTxt}
                  </button>
                </div>
                </div>
              ) : null}
              {_SNAP_MSG === TextProps.VALUE_TRUE &&
              showBenefitListInfo.length > 0 &&
              (userRole === roleProps.CONSUMER ||
                userRole === roleProps.PROVIDER ||
                userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
                userRole === roleProps.PROVIDER_ROLE_DCPVSG) &&
              _RECERT_TYPE === "RECERT" ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                <div className="notification notification--urgent">
                  {("SNPRCT" in inprogressApps) ? 
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.recertTxtContinue1} </strong>
                    <strong>
                     {formatters.convertTimestampToToDataFormat(inprogressApps["SNPRCT"].updatedDt)}
                   </strong>
                   <strong>{home.recertTxtContinue2} </strong>
                   <strong>
                   {formatters.convertTimestampToTimeFormat(inprogressApps["SNPRCT"].updatedDt)}
                   </strong>
                   <strong>{home.recertTxtContinue3} </strong>
                   <strong>
                   {inprogressApps["SNPRCT"].pendingHours.split(":")[0]}
                   </strong>
                   <strong>{home.recertTxtContinue4} </strong>
                  </p> 
                  : (RECERT_OVERDUE_MONTH == 13) ? <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.newelserecertTxt} </strong>
                    <strong>
                      {formatters.convertStringDateToMonth(_SNAP_DATE, TextProps.VALUE_TRUE)}
                    </strong>{" "}
                    <strong>{home.newelserecertContinue1}</strong>
                  </p> : <p className="margin-top-none notification-message"> 
                    <Icon name="notice" />
                    <strong>{home.newrecertTxt} </strong>
                    <strong>
                     {formatters.convertStringDateToMonth(_SNAP_DATE)}
                   </strong>
                  </p>}
                  <p style={{ width: "75%" }}></p>
                  <button
                    className="dta-button dta-button--primary"
                    style={{ marginLeft: "75%", width: "25%" }}
                    onClick={() => this.redirectRecert(_RECERT_TYPE)}
                  >
                    {"SNPRCT" in inprogressApps ? home.continue : home.recertButtonTxt}
                  </button>
                </div>
                </div>
              ) :  null}
              {_SNAP_MSG === TextProps.VALUE_TRUE &&
              showBenefitListInfo.length > 0 &&
              (userRole === roleProps.CONSUMER ||
                userRole === roleProps.PROVIDER ||
                userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
                userRole === roleProps.PROVIDER_ROLE_DCPVSG) &&
              _RECERT_TYPE === "COVIDRECERT" ? (
                <div className="notification notification--urgent">
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.covidRecertTxt} </strong>
                    <strong>
                      {formatters.date(
                        formatters.convertStringDateToDate(_SNAP_DATE)
                      )}
                      .
                    </strong>{" "}
                  </p>
                  <p style={{ width: "75%" }}></p>
                  <button
                    className="dta-button dta-button--primary"
                    style={{ marginLeft: "70%", width: "30%" }}
                    onClick={this.redirectCovidRecert}
                  >
                    {"COVIDRECERT" in inprogressApps ? home.continue : home.covidRecertButtonTxt}
                  </button>
                </div>
              ) : null}
              {_CASH_MSG === TextProps.VALUE_TRUE &&
              !consumer.showSchedularPage ? (
                <div className="notification notification--urgent">
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.noticeCASHMsgTxt} </strong>
                    <strong>
                      {helperFunction._convertDateToState(_CASH_DATE)}.
                    </strong>{" "}
                  </p>
                  <p className="margin-bottom-none">{home.noticeCASHHowMsg}</p>
                </div>
              ) : null}
              {_TAFDC_MSG === TextProps.VALUE_TRUE &&
              _TAFDC_TYPE === "REEVAL" &&
              !consumer.showSchedularPage ? (
                <div style={{display: "flex", flexDirection: "column"}}>
                <div className="notification notification--urgent">
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.TAFDCTextBanner1} </strong>
                    <strong>
                      {formatters.convertStringDateToMonth(_TAFDC_DATE)}
                    </strong>
                    <strong>{home.TAFDCTextBanner2} </strong>
                    
                  </p>
                  <button
                    className="dta-button dta-button--primary"
                    style={{ marginLeft: "75%", width: "25%" }}
                    onClick={() => this.redirectRecert(_TAFDC_TYPE)}
                  >
                    {"TNFRCT" in inprogressApps ? home.continue : home.tafdcButtonTxt}
                  </button>
                </div>
                </div>
              ) : null}
              {_EAEDC_MSG === TextProps.VALUE_TRUE &&
              !consumer.showSchedularPage ? (
                <div className="notification notification--urgent">
                  <p className="margin-top-none notification-message">
                    <Icon name="notice" />
                    <strong>{home.noticeEAEDCText} </strong>
                    <strong>
                      {helperFunction._convertDateToState(_EAEDC_DATE)}.
                    </strong>{" "}
                  </p>
                  <p className="margin-bottom-none">
                    {home.noticeText1} {caseManagerName} {home.noticeText2}{" "}
                    {formatters.formatPhoneNumber(caseManagerPhoneNumber)}{" "}
                    {home.noticeText3}
                  </p>
                </div>
              ) : null}
              {/* ebt alert */}
              {ebtAlert}
              {!_.isEmpty(pEbtClientData) &&
                pEbtClientData.map((obj) => (
                  <div className="notification notification--urgent">
                    <p className="margin-top-none notification-message">
                      <Icon name="notice" />
                      <strong
                        className="pebt-header"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.desc) }}
                      ></strong>
                    </p>
                  </div>
                ))}
              {showApmntAlert &&
                this.state.apmntAlertInActivityTab &&
                !consumer.showSchedularPage && (
                  <div className="notification notification--urgent">
                    <p className="margin-top-none notification-message">
                      <Icon name="notice" />
                      <strong className="pebt-header">
                        {info.apmntAlertText1}
                      </strong>
                    </p>
                  </div>
                )}
                      {this.state.missedApmntAlert &&                   
                       this.state.apmntAlertInActivityTab &&              
                        !consumer.showSchedularPage && (
                          <div className="notification notification--urgent">
                            <p className="margin-top-none notification-message">
                              <Icon name="notice" />
                              <strong className="pebt-header">
                                {info.missedAppoinmentText}

                              </strong>
                            </p>
                          </div>
                        )}
                        {_SNAP_MSG === undefined && _RECERT_TYPE === undefined && isSnapFlag === "No" && (
                          <div></div>
                        )}
            </div>
            ) : (
              <div>
               <button
              className="dta-button dta-button--primary margin-bottom"
              onClick={this.openTrackApplicationModal}
            >
              {home.trackApplicationText}
            </button>
              </div>
            )
          }

            <DocumentTitle
              title={state && state.title ? state.title : "DHR Client Portal"}
            >
              <Switch>
                <Redirect exact from={routeProps.HOME_PATH} to="/myInfo" />
                {consumerNavSectionsMapData.map(({ id }) => {
                  return (
                    <Route
                      key={id}
                      exact
                      path={`/${id}`}
                      component={this.resolveComponentFromId(id)}
                    />
                  );
                })}
                <Route
                  exact
                  path="/myInfo/benefit/:id"
                  component={ConsumerBenefitDetail}
                  router={this.context.router}
                />
                <Route
                  exact
                  path="/concernappeal/withdraw"
                  component={appealWithdraw}
                  router={this.context.router}
                />
                 <Route
                  exact
                   path="/concerns"
                   component={ClientConcern}
                     />
                <Route
                  exact
                  path="/concernappeal/appealWithDrawSubmit"
                  component={appealWithdrawSubmit}
                  router={this.context.router}
                />
                <Route component={NotFound} />
              </Switch>
            </DocumentTitle>
              </main>
     </div>
     <DTAModal
              isOpen={this.state.trackApplicationModalOpen}
              onClose={this.closeTrackApplicationModal}
              modalClass="account-connect-modal consumer-search-client custom_width"
              titleText={home.trackRecertApplicationText}
              headerText={home.trackRecertApplicationText}
            >
              <div className="dta-modal__body pad-all">
                <div className="searchClientSection ">
              <div class="table" id="results">
                <div class='theader'>
                  <div class='table_header' >
                    <span className="  ">
                    {home.trackTableHeadType}
                    </span>
                  </div>
                  <div class='table_header'  style={{maxWidth:'121px'}}>
                    <span className="  ">
                    {home.trackTableHeadAppNum}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                    {home.trackTableHeadStatus}
                    </span>
                  </div>
                  <div class='table_header' style={{paddingLeft: '5px'}}>
                    <span className="  ">
                    {home.trackTableHeadSubDate}
                    </span>
                  </div>
                  <div class='table_header'>
                    <span className="  ">
                    {home.trackTableHeadStatDate}
                    </span>
                  </div>
                </div>
                {trackConsumer !== undefined && trackConsumer.map((userDetail) => {
                   return (
                    <div class='table_row'>
                    <div class='table_small'>
                      <div class='table_cell'>Header One</div>
                      <div class='table_cell'>
                        <span className=" ">
                          {userDetail.type ? this.returnTypeDescription(userDetail.type) : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Two</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.applicationNumber ? userDetail.applicationNumber : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Three</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.status ? userDetail.status : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Four</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.submittedDate ? formatters.formatDateBySlash(userDetail.submittedDate) : "N/A"}
                         </span>
                      </div>
                    </div>
                    <div class='table_small'>
                      <div class='table_cell'>Header Five</div>
                      <div class='table_cell'>
                        <span className=" ">
                        {userDetail.statusChangeDate ? formatters.formatDateBySlash(userDetail.statusChangeDate) : "N/A"}
                         </span>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </div> 
                </div>
                <div className="dta-modal__footer dta-modal__footer--inline-buttons" style={{display: "flex", justifyContent: "center"}}>
                  <button
                    className="dta-button dta-button--large dta-button--primary"
                    style={{margin: "0 auto"}}
                    onClick={this.closeTrackApplicationModal}
                  >
                    {home.trackTableOk}
                  </button>
                </div>
              </div>
            </DTAModal>
          <DTAModal
            isOpen={
              this.state.showWarningModal ||
              this.state.dateTimeWarning ||
              this.state.slotsNotAvailWarning
            }
            onClose={this.closeWarningModal}
            modalClass="save-progress-modal"
            titleText="First pop-up"
            headerText="Info"
          >
            <div className="dta-modal__body pad-all">
              {this.state.showWarningModal ? (
                <p style={{ marginBottom: "1rem" }}>
                  {common.technicalWarnText1} <br /> {common.technicalWarnText2}
                </p>
              ) : this.state.slotsNotAvailWarning ? (
                <p style={{ marginBottom: "1rem" }}>
                  {common.slotsNotAvailable}
                </p>
              ) : (
                <p style={{ marginBottom: "1rem" }}>{common.dateTimeMsg}</p>
              )}
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="apply__footer pad-all--double"
              >
                <button
                  align="center"
                  className="dta-button dta-button--outline-primary"
                  onClick={this.closeWarningModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </DTAModal>
      
          </div>
        {this.renderAddressValidationModal()}
        {this.renderWarningPopup()}
        {this.renderSubmitPopup()}
      </Fragment>
      </div>
    );
  }

  gotoConsumerSignup() {
    this.props.onConsumerSignup();
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onConsumerSignup: () => push(routeProps.CONSUMER_LOGIN_PATH),
      getConsumerAboutMeData: getConsumerAboutMeData,
      updateClientAddress: updateClientAddress,
      addrsWarningPopupAction:addrsWarningPopupAction,
      startInterimReport: getInterimReportData,
      startCovidRecert: getCovidRecertData,
      startRecert: getRecertData,
      startTrackApplication: getTrackApplication,
      goToIR: () => push(routeProps.SNAP_SIX_MONTH_REPORT_PATH),
      goToRecert: () => push(routeProps.RECERTIFICATION_PATH),
      goToCovidRecert: () => push(routeProps.COVID_RECERT_PATH),
      goToTAFDC: () => push(routeProps.TAFDC_PATH),
      displayError: () => push(routeProps.API_ERR_PATH),
      hidePopupAction: hidePopupAction,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsumerApp)
);
