import { isNone } from "../../utils/components/utilities/controls";
import * as TextProps from "../../utils/constants/text";
import { langOptSel } from "../../utils/constants/constants";
import { filterData } from "./applyDsnapHelper";
import { aboutMeDsnapMultilanguageLabels, householdDsnapMultilanguageLabels, resourcesDsnapMultilanguageLabels, expensesDsnapMultilanguageLabels, MyhouseholdDsnapMultilanguageLabels } from './applyDsnapText'
import _ from 'lodash'

var frequencyCode = require("../../utils/constants/sanpApplyConstant");


export function convertToDTOObj(sections, userSignature, inProgressObj, dsnapId) {
  let selectedLanguage = localStorage.getItem("selectedLanguage");

  if (selectedLanguage === null || selectedLanguage === langOptSel) {
    selectedLanguage = "en-US";
  }

  let application = new Application();

  let beforYouStart = filterData(sections, "before-you-start").questions;

  if (
    beforYouStart[0].Answer !== null &&
    beforYouStart[0].Answer !== undefined &&
    beforYouStart[0].Answer !== ""
  ) {
    application.dsnapCountyCode = beforYouStart[0].countyCode;
  }

  //id
  application.id = dsnapId;

  // from UI answers and questions
  let headHouseholdInfo = filterData(sections, "head-of-household-info").questions;
  let applicant = new Person();

  applicant.personId = filterData(sections, "before-you-start").webClientId;
  applicant.firstName = headHouseholdInfo[1].Answer;
  applicant.middleName = headHouseholdInfo[2].Answer;
  applicant.lastName = headHouseholdInfo[3].Answer;
  applicant.SSN = headHouseholdInfo[6].Answer;
  applicant.birthDate = convertDOBObjectToString(headHouseholdInfo[5].Answer);
  applicant.dlOrStateId = headHouseholdInfo[15].Answer;
  let biologicalSex = headHouseholdInfo.filter(
    (info) => info.name === "biological-sex"
  )[0];
  if (
    biologicalSex.Answer !== null &&
    biologicalSex.Answer !== undefined &&
    biologicalSex.Answer !== ""
  ) {
    let genederOptions = biologicalSex.options;
    if (genederOptions[0] === biologicalSex.targetValue) {
      applicant.gender = "MALE";
    } else if(genederOptions[1] === biologicalSex.targetValue){
      applicant.gender = "FEMALE";
    }
  }

  if (
    headHouseholdInfo[4].targetValue ===
    aboutMeDsnapMultilanguageLabels(selectedLanguage).suffixOption[0]
  ) {
    applicant.nameSuffix = "I";
  } else if (
    headHouseholdInfo[4].targetValue ===
    aboutMeDsnapMultilanguageLabels(selectedLanguage).suffixOption[10]
  ) {
    applicant.nameSuffix = "Jr.";
  } else if (
    headHouseholdInfo[4].targetValue ===
    aboutMeDsnapMultilanguageLabels(selectedLanguage).suffixOption[11]
  ) {
    applicant.nameSuffix = "Sr.";
  } else {
    applicant.nameSuffix = headHouseholdInfo[4].targetValue;
  }

  /** status of the application */
  if (
    inProgressObj !== undefined &&
    inProgressObj !== "" &&
    inProgressObj !== null
  ) {
    application.status = inProgressObj.status;
  } else {
    application.status = "RECVED";
  }

  /** sign name as first_name of application */
  application.signedName = userSignature;

  //don't have a address
  if (
    headHouseholdInfo[10].Answer[0] !== null &&
    headHouseholdInfo[10].Answer[0] !== undefined &&
    headHouseholdInfo[10].Answer[0] !== ""
  ) {
    if (
      headHouseholdInfo[10].Answer[0] === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      applicant.noAddress = TextProps.VALUE_Y;
    } else {
      applicant.noAddress = TextProps.VALUE_N;
    }
  }

  //isDHREmployee

  if (
    headHouseholdInfo[0].Answer !== null &&
    headHouseholdInfo[0].Answer !== undefined &&
    headHouseholdInfo[0].Answer !== ""
  ) {
    if (
      headHouseholdInfo[0].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      applicant.isDHREmployee = TextProps.VALUE_Y;
    } else {
      applicant.isDHREmployee = TextProps.VALUE_N;
    }
  }


  // Homeless
  if (
    headHouseholdInfo[8].Answer !== null &&
    headHouseholdInfo[8].Answer !== undefined &&
    headHouseholdInfo[8].Answer !== ""
  ) {
    if (
      headHouseholdInfo[8].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      applicant.isHomeless = TextProps.VALUE_Y;
    } else {
      applicant.isHomeless = TextProps.VALUE_N;
    }
  }

  // applicant residence address
  let resAddress = new Address();

  if (headHouseholdInfo[10].Answer !== undefined) {
    if (
      headHouseholdInfo[10].Answer.addressLine1 &&
      headHouseholdInfo[10].Answer.zipCode
    ) {
      resAddress.addressType = "RES";
      resAddress.street = headHouseholdInfo[10].Answer.addressLine1;
      resAddress.aptOrUnit = headHouseholdInfo[10].Answer.addressLine2;
      resAddress.city = headHouseholdInfo[10].Answer.city;
      resAddress.postal = headHouseholdInfo[10].Answer.zipCode;
      resAddress.state = headHouseholdInfo[10].Answer.state;
      resAddress.countyCode = headHouseholdInfo[10].Answer.countyCode;
      resAddress.verified = headHouseholdInfo[10].Answer.verified;
      if (
        headHouseholdInfo[11].Answer !== undefined ||
        headHouseholdInfo[11].Answer !== null ||
        headHouseholdInfo[11].Answer !== ""
      ) {
        resAddress.isSameMailAndPhysical =
          headHouseholdInfo[11].Answer === "" ||
            headHouseholdInfo[11].Answer === TextProps.VALUE_STR_TRUE
            ? TextProps.VALUE_Y
            : TextProps.VALUE_N;
      }
      applicant.addresses.push(resAddress);
    }

  }

  //mailing address
  if (headHouseholdInfo[11].Answer !== undefined && headHouseholdInfo[11].Answer !== "") {
    if (headHouseholdInfo[11].Answer === TextProps.VALUE_STR_FALSE) {
      let mailAddress = new Address();
      if (
        headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer !==
        undefined
      ) {
        if (
          headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer
            .addressLine1
        ) {
          mailAddress.addressType = "MAIL";
          mailAddress.street =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.addressLine1;
          mailAddress.aptOrUnit = 
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.addressLine2;
          mailAddress.city =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.city;
          mailAddress.postal =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.zipCode;
          mailAddress.state =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.state;
          mailAddress.isSameMailAndPhysical =
            headHouseholdInfo[11].Answer === TextProps.VALUE_STR_TRUE
              ? TextProps.VALUE_Y
              : TextProps.VALUE_N;

          mailAddress.countyCode =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.countyCode;
          mailAddress.verified =
            headHouseholdInfo[11].conditionals[0].conditionalQuestions[0].Answer.verified;
          applicant.addresses.push(mailAddress);
        }
      }
    }
  }


  // applicant temporary address
  let tempAddress = new Address();
  if (headHouseholdInfo[12].Answer !== undefined) {
    if (
      headHouseholdInfo[12].Answer.addressLine1 &&
      headHouseholdInfo[12].Answer.zipCode
    ) {
      tempAddress.addressType = "TEMP";
      tempAddress.street = headHouseholdInfo[12].Answer.addressLine1;
      tempAddress.aptOrUnit = headHouseholdInfo[12].Answer.addressLine2;
      tempAddress.city = headHouseholdInfo[12].Answer.city;
      tempAddress.postal = headHouseholdInfo[12].Answer.zipCode;
      tempAddress.state = headHouseholdInfo[12].Answer.state;
      tempAddress.countyCode = headHouseholdInfo[12].Answer.countyCode;
      tempAddress.verified = headHouseholdInfo[12].Answer.verified;
      applicant.addresses.push(tempAddress);
    }
  }

  //Disability
  if (
    headHouseholdInfo[13].Answer !== null &&
    headHouseholdInfo[13].Answer !== undefined &&
    headHouseholdInfo[13].Answer !== ""
  ) {
    if (
      headHouseholdInfo[13].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      applicant.hasDisability = TextProps.VALUE_Y;
    } else {
      applicant.hasDisability = TextProps.VALUE_N;
    }
  }


  //Authorized Representative
  if (
    headHouseholdInfo[14].Answer !== null &&
    headHouseholdInfo[14].Answer !== undefined &&
    headHouseholdInfo[14].Answer !== ""
  ) {
    if (
      headHouseholdInfo[14].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      applicant.dsnapAuthRepFlg = TextProps.VALUE_Y;
    } else {
      applicant.dsnapAuthRepFlg = TextProps.VALUE_N;
    }
  }

  //authRep Name and authRep Phone Number
  let houseHoldConditionalObj = headHouseholdInfo[14].conditionals && headHouseholdInfo[14].conditionals[0].conditionalQuestions;
  if (
    headHouseholdInfo[14].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
    houseHoldConditionalObj[0] !== null &&
    houseHoldConditionalObj[0] !== undefined &&
    houseHoldConditionalObj[0] !== ""

  ) {

    applicant.authRepName = houseHoldConditionalObj[0].Answer;
  }

  if (
    headHouseholdInfo[14].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
    houseHoldConditionalObj[1] !== null &&
    houseHoldConditionalObj[1] !== undefined &&
    houseHoldConditionalObj[1] !== ""

  ) {
    applicant.authRepPhone = houseHoldConditionalObj[1].Answer.replace(/\D/g, '');
  }


  // spliting to 3 parts of phone no
  if (
    headHouseholdInfo[16].Answer !== undefined &&
    headHouseholdInfo[16].Answer !== null
  ) {
    let phoneNo = headHouseholdInfo[16].Answer.replace(/[^0-9]/g, "");
    applicant.areaCode = phoneNo.substring(0, 3);
    applicant.exchangeNumber = phoneNo.substring(3, 6);
    applicant.lineNumber = phoneNo.substring(6, 10);
  }
  if (headHouseholdInfo[17].Answer !== undefined) {
    applicant.emailAddress = headHouseholdInfo[17].Answer;
  }


  // Household Situation

  let situation = new Situation();
  let householdSituation = filterData(sections, "household-situation").questions;

  if (
    householdSituation[0].Answer !== null &&
    householdSituation[0].Answer !== undefined &&
    householdSituation[0].Answer !== ""
  ) {
    if (
      householdSituation[0].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.householdLivingInDisasterArea = TextProps.VALUE_Y;
    } else {
      situation.householdLivingInDisasterArea = TextProps.VALUE_N;
    }
  }


  if (
    householdSituation[1].Answer !== null &&
    householdSituation[1].Answer !== undefined &&
    householdSituation[1].Answer !== ""
  ) {
    if (
      householdSituation[1].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.householdWorkingInDisasterArea = TextProps.VALUE_Y;
    } else {
      situation.householdWorkingInDisasterArea = TextProps.VALUE_N;
    }
  }

  if (
    householdSituation[2].Answer !== null &&
    householdSituation[2].Answer !== undefined &&
    householdSituation[2].Answer !== ""
  ) {
    if (
      householdSituation[2].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.didDisasterDestroyedHomeOrWorkPlace = TextProps.VALUE_Y;
    } else {
      situation.didDisasterDestroyedHomeOrWorkPlace = TextProps.VALUE_N;
    }
  }


  if (
    householdSituation[3].Answer !== null &&
    householdSituation[3].Answer !== undefined &&
    householdSituation[3].Answer !== ""
  ) {
    if (
      householdSituation[3].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.pocketExpensedDueToDisaster = TextProps.VALUE_Y;
    } else {
      situation.pocketExpensedDueToDisaster = TextProps.VALUE_N;
    }
  }

  if (
    householdSituation[4].Answer !== null &&
    householdSituation[4].Answer !== undefined &&
    householdSituation[4].Answer !== ""
  ) {
    if (
      householdSituation[4].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.willBuyFoodDuringCleanup = TextProps.VALUE_Y;
    } else {
      situation.willBuyFoodDuringCleanup = TextProps.VALUE_N;
    }
  }

  if (
    householdSituation[5].Answer !== null &&
    householdSituation[5].Answer !== undefined &&
    householdSituation[5].Answer !== ""
  ) {
    if (
      householdSituation[5].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.householdIncomeStopDueToDisaster = TextProps.VALUE_Y;
    } else {
      situation.householdIncomeStopDueToDisaster = TextProps.VALUE_N;
    }
  }

  if (
    householdSituation[6].Answer !== null &&
    householdSituation[6].Answer !== undefined &&
    householdSituation[6].Answer !== ""
  ) {
    if (
      householdSituation[6].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.moneyInBankCannotBeTakenFromBank = TextProps.VALUE_Y;
    } else {
      situation.moneyInBankCannotBeTakenFromBank = TextProps.VALUE_N;
    }
  }
  let conditionalObj = householdSituation[7].conditionals && householdSituation[7].conditionals[0].conditionalQuestions;
  if (
    householdSituation[7].Answer !== null &&
    householdSituation[7].Answer !== undefined &&
    householdSituation[7].Answer !== ""
  ) {
    if (
      householdSituation[7].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]
    ) {
      situation.householdReceivedSNAP = TextProps.VALUE_Y;
    } else {
      situation.householdReceivedSNAP = TextProps.VALUE_N;
    }

    if (
      householdSituation[7].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
      conditionalObj[0] !== null &&
      conditionalObj[0] !== undefined &&
      conditionalObj[0] !== ""

    ) {

      situation.receivedSNAPstate = conditionalObj[0].Answer.state;
    }
    if (
      householdSituation[7].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
      conditionalObj[0] !== null &&
      conditionalObj[0] !== undefined &&
      conditionalObj[0] !== ""

    ) {
      situation.receivedSNAPcountyCode = conditionalObj[0].Answer.countyCode;
    }
  }
  let conditionalQuestion = householdSituation[8].conditionals && householdSituation[8].conditionals[0].conditionalQuestions;
  if (
    householdSituation[8].Answer !== null &&
    householdSituation[8].Answer !== undefined &&
    householdSituation[8].Answer !== ""
  ) {
    if (
      householdSituation[8].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0]) {
      situation.didReceivedSNAPBenifit2020 = TextProps.VALUE_Y;
    } else {
      situation.didReceivedSNAPBenifit2020 = TextProps.VALUE_N;
    }
    if (
      householdSituation[8].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
      conditionalQuestion[0] !== null &&
      conditionalQuestion[0] !== undefined &&
      conditionalQuestion[0] !== ""

    ) {
      situation.receivedSNAPBenifit2020state = conditionalQuestion[0].Answer.state;
    }
    if (
      householdSituation[8].Answer === householdDsnapMultilanguageLabels(selectedLanguage).yesNoOptions[0] &&
      conditionalQuestion[0] !== null &&
      conditionalQuestion[0] !== undefined &&
      conditionalQuestion[0] !== ""

    ) {
      situation.receivedSNAPBenifit2020countyCode = conditionalQuestion[0].Answer.countyCode;
    }
  }

  // My Hosehold
  /** here going to added no.of house-hold size */
  application.householdSize = filterData(sections, "my-household").questions[0].Answer;


  /** here going to set household members details */
  let houseHolds = filterData(sections, "my-household").subsections;
  if (houseHolds !== undefined) {
    for (let member = 0; member < houseHolds.length; member++) {
      let dependentInfo = houseHolds[member].questions;

      let myHouseHold = new Household();
      myHouseHold.isGrantee = TextProps.VALUE_N;
      // setting clientId from UI
      myHouseHold.personId = houseHolds[member].clientId;

      if (
        dependentInfo[0].Answer !== "" &&
        dependentInfo[0].Answer !== undefined
      ) {
        myHouseHold.firstName = dependentInfo[0].Answer;
      } else {
        if (houseHolds[member].title) {
          var fname = houseHolds[member].title;
          fname = fname.split("(");
        }
        myHouseHold.firstName = fname[0];
      }
      myHouseHold.middleName = dependentInfo[1].Answer;
      if (
        dependentInfo[2].Answer !== "" &&
        dependentInfo[2].Answer !== undefined
      ) {
        myHouseHold.lastName = dependentInfo[2].Answer;
      } else {
        if (houseHolds[member].title) {
          var lname = houseHolds[member].title;
          lname = lname.split("(");
        }
        myHouseHold.lastName = lname[0];
      }

      if (
        dependentInfo[3].targetValue ===
        MyhouseholdDsnapMultilanguageLabels(selectedLanguage).memberSuffixOption[0]
      ) {
        myHouseHold.nameSuffix = "I";
      } else if (
        dependentInfo[3].targetValue ===
        MyhouseholdDsnapMultilanguageLabels(selectedLanguage).memberSuffixOption[10]
      ) {
        myHouseHold.nameSuffix = "Jr.";
      } else if (
        dependentInfo[3].targetValue ===
        MyhouseholdDsnapMultilanguageLabels(selectedLanguage).memberSuffixOption[11]
      ) {
        myHouseHold.nameSuffix = "Sr.";
      } else {
        myHouseHold.nameSuffix = dependentInfo[3].targetValue;
      }

      //SSN
      let ssnFeild = dependentInfo.filter((dep) => dep.name === "ssn")[0];
      if (ssnFeild) myHouseHold.SSN = ssnFeild.Answer;


      if (
        dependentInfo[4].Answer !== "" &&
        dependentInfo[4].Answer !== undefined &&
        dependentInfo[4].Answer !== null
      ) {
        myHouseHold.birthDate = new Date(dependentInfo[4].Answer);
      }
      if (
        dependentInfo[5].Answer !== "" &&
        dependentInfo[5].Answer !== undefined
      ) {
        let genederOptions = dependentInfo[5].options;
        if (genederOptions[0] === dependentInfo[5].targetValue) {
          myHouseHold.gender = "MALE";
        } else {
          myHouseHold.gender = "FEMALE";
        }
      }
      let raceFeild = dependentInfo.filter((dep) => dep.name === "race")[0];
      if (raceFeild && raceFeild.Answer !== undefined) {
        for (let race = 0; race < dependentInfo[7].Answer.length; race++) {
          let web_race = new Race();
          if (raceFeild.Answer.length > 0) {
            if (
              dependentInfo[7].Answer[race] === "Decline to answer" ||
              dependentInfo[7].Answer[race] === "DCL"
            ) {
              web_race.raceCode = "DCL";
            } else {
              if (dependentInfo[7].Answer[race] === 'OTHER') {
                web_race.otherDesc = dependentInfo[7].conditionals[0].conditionalQuestions[0].Answer;
              }
              web_race.raceCode = dependentInfo[7].Answer[race];
            }
            myHouseHold.race.push(web_race);
          }
        }
      }
      applicant.household.push(myHouseHold);
    }
  }


  //Income

  let incomeMembers = filterData(sections, "income").subsections;
  const noIncomeMembers = filterData(sections, "income");
  if (incomeMembers !== undefined) {
    if (incomeMembers.length > 1) {
      let hhNum;
      for (let income = 0; income < incomeMembers.length - 1; income++) {
        if (incomeMembers.length >= 0) {
          // hasIncome flag is set to TextProps.VALUE_TRUE when any member receive's income or benefits
          // applicant.hasIncome = TextProps.VALUE_Y;
          if (
            incomeMembers[income].questions[0].Answer !== undefined &&
            incomeMembers[income].questions[0].Answer !== ""
          ) {
            let selectedBenefit = incomeMembers[income].questions[0].Answer;
            if (
              incomeMembers[income].subsections !== undefined &&
              incomeMembers[income].subsections.length > 0
            ) {
              let types = incomeMembers[income].subsections[0].questions;
              for (let incAns = 0; incAns < selectedBenefit.length; incAns++) {
                for (
                  let incomeType = 0;
                  incomeType < types.length;
                  incomeType++
                ) {
                  if (
                    types[incomeType].valueToMatch === selectedBenefit[incAns]
                  ) {
                    let typeOfWage = types[incomeType];
                    let whichTable = typeOfWage.valueToMatch;
                    let tableName = whichTable.split("-");
                    let actionName = typeOfWage.name;
                    var actionSplit = actionName.split("-");

                    let removeIndex = actionSplit.splice(-1, 1).join();
                    var finalString = actionSplit.join("-");
                    if (applicant.household.length > 0) {
                      if (
                        applicant.household[hhNum] !== undefined &&
                        applicant.household[hhNum].income !== undefined &&
                        applicant.household[hhNum].income.length > 0
                      ) {
                        for (
                          let incm = 0;
                          incm < applicant.household[hhNum].income.length;
                          incm++
                        ) {
                          if (
                            applicant.household[hhNum].income[incm]
                              .otherIncomeTypeCode === tableName[1]
                          ) {
                            let isHoseHoldRentalIncome;
                            if (
                              applicant.household[hhNum].income !==
                              undefined &&
                              applicant.household[hhNum].income.length > 0
                            ) {
                              isHoseHoldRentalIncome =
                                applicant.household[hhNum].income;
                            }

                            if (
                              isHoseHoldRentalIncome !== undefined &&
                              isHoseHoldRentalIncome.length > 0
                            ) {
                              if (
                                isHoseHoldRentalIncome[incm].key !== "" &&
                                isHoseHoldRentalIncome[incm].key !== undefined
                              ) {
                                if (
                                  isHoseHoldRentalIncome[incm].key <
                                  typeOfWage.Answer.length
                                ) {
                                  isHoseHoldRentalIncome[incm].hours =
                                    typeOfWage.Answer[
                                      isHoseHoldRentalIncome[incm].key
                                    ].amount;
                                  let freqCode =
                                    typeOfWage.Answer[
                                      isHoseHoldRentalIncome[incm].key
                                    ].unit;
                                  if (/\s/g.test(freqCode)) {
                                    freqCode = freqCode.replace(/ /g, "");
                                  }
                                  isHoseHoldRentalIncome[
                                    incm
                                  ].hoursfrequencyCode =
                                    frequencyCode.getfrequencyCode[freqCode];
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    let incomeObj = new Income();
                    if (
                      typeOfWage.Answer === undefined ||
                      typeOfWage.Answer.length === 0
                    ) {
                      if (tableName[0] === "EI") {
                        incomeObj.wageTypeCode = tableName[1];
                      } else if (
                        tableName[0] === "UI" &&
                        (tableName[1] === "RENTAL" || tableName[0] === "UI")
                      ) {
                        incomeObj.otherIncomeTypeCode = tableName[1];
                      }
                      if (
                        applicant.personId === incomeMembers[income].clientId
                      ) {
                        applicant.income.push(incomeObj);
                      } else {
                        for (
                          let houseMember = 0;
                          houseMember < applicant.household.length;
                          houseMember++
                        ) {
                          if (
                            applicant.household[houseMember].personId ===
                            incomeMembers[income].clientId
                          ) {
                            applicant.household[houseMember].income.push(
                              incomeObj
                            );
                          }
                        }
                      }
                    }
                    if (typeOfWage.Answer !== undefined) {
                      for (
                        let repeatAnswer = 0;
                        repeatAnswer < typeOfWage.Answer.length;
                        repeatAnswer++
                      ) {
                        let incomeObj = new Income();
                        if (
                          tableName[0] === "EI" &&
                          typeOfWage.Answer !== undefined
                        ) {
                          if (isNone(typeOfWage.Answer)) {
                            incomeObj.grossIncome = "";
                            incomeObj.frequencyCode = "";
                            incomeObj.wageTypeCode = tableName[1];
                          } else {
                            incomeObj.grossIncome =
                              typeOfWage.Answer[repeatAnswer].amount;
                            let freqCode =
                              typeOfWage.Answer[repeatAnswer].unit;
                            if (/\s/g.test(freqCode)) {
                              freqCode = freqCode.replace(/ /g, "");
                            }
                            incomeObj.frequencyCode =
                              frequencyCode.getfrequencyCode[freqCode];
                            incomeObj.wageTypeCode = tableName[1];
                          }
                        } else if (
                          tableName[0] === "UI" &&
                          tableName[1] === "RENTAL" &&
                          typeOfWage.Answer !== undefined
                        ) {
                          if (isNone(typeOfWage.Answer)) {
                            incomeObj.grossIncome = "";
                            incomeObj.frequencyCode = "";
                            incomeObj.otherIncomeTypeCode = tableName[1];
                            incomeObj.hours = "";
                          } else {
                            if (
                              typeOfWage.repeatedQuestion.isMoney ===
                              TextProps.VALUE_TRUE
                            ) {
                              incomeObj.grossIncome =
                                typeOfWage.Answer[repeatAnswer].amount;
                              let freqCode =
                                typeOfWage.Answer[repeatAnswer].unit;
                              if (/\s/g.test(freqCode)) {
                                freqCode = freqCode.replace(/ /g, "");
                              }
                              incomeObj.frequencyCode =
                                frequencyCode.getfrequencyCode[freqCode];
                              incomeObj.otherIncomeTypeCode = tableName[1];
                              /** setting key as a which rental income for mapping hours */
                              incomeObj.key = repeatAnswer;
                            } else {
                              // nothing do here for rental income if hours working
                            }
                          }
                        } else if (
                          tableName[0] === "UI" &&
                          typeOfWage.Answer !== undefined
                        ) {
                          if (isNone(typeOfWage.Answer)) {
                            incomeObj.grossIncome = "";
                            incomeObj.frequencyCode = "";
                            incomeObj.otherIncomeTypeCode = tableName[1];
                          } else {
                            incomeObj.grossIncome =
                              typeOfWage.Answer[repeatAnswer].amount;
                            let freqCode =
                              typeOfWage.Answer[repeatAnswer].unit;
                            if (/\s/g.test(freqCode)) {
                              freqCode = freqCode.replace(/ /g, "");
                            }
                            incomeObj.frequencyCode =
                              frequencyCode.getfrequencyCode[freqCode];
                            incomeObj.otherIncomeTypeCode = tableName[1];
                          }
                        }
                        if (
                          applicant.personId ===
                          incomeMembers[income].clientId
                        ) {
                          applicant.income.push(incomeObj);
                          hhNum = "";
                        } else {
                          for (
                            let houseMember = 0;
                            houseMember < applicant.household.length;
                            houseMember++
                          ) {
                            if (
                              applicant.household[houseMember].personId ===
                              incomeMembers[income].clientId
                            ) {
                              applicant.household[houseMember].income.push(
                                incomeObj
                              );
                              hhNum = houseMember;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    //  if (
    //   noIncomeMembers.questions[0].Answer[0] &&
    //   (noIncomeMembers.questions[0].Answer[0] === "" ||
    //     noIncomeMembers.questions[0].Answer[0] === "No income to report" ||
    //     noIncomeMembers.questions[0].Answer[0] === 'Decline to answer')
    // ) {
    //   applicant.hasIncome = TextProps.VALUE_N;
    // }
  }


  // Resources
  let householdResources = filterData(sections, "resources-landing").subsections[0];
  if (householdResources) {
    const filteredQuestions = householdResources.questions.filter(question => {
      return (
        (
          question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[0] ||
          question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[1] ||
          question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[2]
        ) &&
        question.Answer !== undefined
      );
    });

    filteredQuestions.forEach(question => {
      let resourceType;
      if (question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[0]) {
        resourceType = "CASH";
      } else if (question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[1]) {
        resourceType = "SAVING";
      } else if (question.name === resourcesDsnapMultilanguageLabels(selectedLanguage).combineResourcesoptions[2]) {
        resourceType = "CHECK";
      }
      let resourcesDetailsObj = new Resources();
      resourcesDetailsObj.resourceType = resourceType;
      resourcesDetailsObj.amount = question.Answer;
      application.resources.push(resourcesDetailsObj);
    });
  } else {
    let resourcesDetailsObj = new Resources();
    resourcesDetailsObj.resourceType = "";
    resourcesDetailsObj.amount = "";
    application.resources.push(resourcesDetailsObj);
  }

  //Expenses
  let householdExpenses = filterData(sections, "expense-landing-page").subsections[1];
  application.expensesSignedName = filterData(sections, "expense-landing-page").questions[0].Answer;
  if (householdExpenses) {
    const expensesFliterQuestion = householdExpenses.questions.filter(question => {
      return (
        (
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[0] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[1] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[2] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[3] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[4] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[5] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[6] ||
          question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[7]
        ) &&
        question.Answer !== undefined
      );
    });

    expensesFliterQuestion.forEach(question => {
      let expenseType;
      if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[0]) {
        expenseType = "PRTPRP";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[1]) {
        expenseType = "RPLPRP";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[2]) {
        expenseType = "DPCARE";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[3]) {
        expenseType = "FDDTRY";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[4]) {
        expenseType = "FUNMED";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[5]) {
        expenseType = "STORAG";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[6]) {
        expenseType = "OTHER";
      } else if (question.name === expensesDsnapMultilanguageLabels(selectedLanguage).combineExpensesoptions[7]) {
        expenseType = "SHLTR";
      }
      let expensesDetailsObj = new Expenses();
      expensesDetailsObj.expenseType = expenseType;
      expensesDetailsObj.expenseAmount = question.Answer;
      application.expenses.push(expensesDetailsObj);
    })
  } else {
    let expensesDetailsObj = new Expenses();
    expensesDetailsObj.expenseType = "";
    expensesDetailsObj.expenseAmount = "";
    application.expenses.push(expensesDetailsObj);
  }


  /** setting all applicant's details in application */
  application.applicant = applicant;
  application.situation = situation;

  /** creating object for apply with user_name 'GUEST' and sending data to service - save function */
  let finalObj = application;
  if (finalObj && finalObj.applicant && finalObj.applicant.household && finalObj.applicant.household.length > 0) {
    finalObj.applicant.household.forEach(oneObj => {
      delete oneObj['isGrantee'];
      delete oneObj['personId'];
    });
  }
  delete finalObj.applicant.personId
  var applicationData = {
    DSNAP_APPLY: {
      userName: "GUEST",
      timestamp: new Date(),
      application: finalObj,
    },
  };
  return applicationData;
}
function convertDOBObjectToString(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
function Application() {
  this.householdSize = " ";
  this.status = "";
  this.signedName = "";
  this.expensesSignedName = "";
  this.id = 0;
  this.expenses = [];
  this.resources = [];
  this.dsnapCountyCode = "";
}

function Person() {
  this.isDHREmployee = "";
  this.firstName = "";
  this.middleName = "";
  this.lastName = "";
  this.nameSuffix = "";
  this.SSN = "";
  this.birthDate = null;
  this.gender = "";
  this.isHomeless = "";
  this.noAddress = "";
  this.hasDisability = "";
  this.dlOrStateId = "";
  this.emailAddress = "";
  this.areaCode = "";
  this.exchangeNumber = "";
  this.lineNumber = "";
  this.dsnapAuthRepFlg = "";
  this.authRepName = "";
  this.authRepPhone = "";

  //List of Address
  this.addresses = [];
  this.income = [];
  this.household = [];
}

function Address() {
  this.addressType = "";
  this.street = "";
  this.aptOrUnit = "";
  this.city = "";
  this.state = "";
  this.postal = "";
  this.countyCode = "";
  this.verified = "N";
  this.isSameMailAndPhysical = "";
  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}

function Situation() {
  this.householdLivingInDisasterArea = "";
  this.householdWorkingInDisasterArea = "";
  this.didDisasterDestroyedHomeOrWorkPlace = "";
  this.pocketExpensedDueToDisaster = "";
  this.willBuyFoodDuringCleanup = "";
  this.householdIncomeStopDueToDisaster = "";
  this.moneyInBankCannotBeTakenFromBank = "";
  this.householdReceivedSNAP = "";
  this.didReceivedSNAPBenifit2020 = "";
  this.receivedSNAPstate = "";
  this.receivedSNAPcountyCode = "";
  this.receivedSNAPBenifit2020state = "";
  this.receivedSNAPBenifit2020countyCode = "";
}

function Household() {
  this.firstName = "";
  this.middleName = "";
  this.lastName = "";
  this.nameSuffix = "";
  this.SSN = "";
  this.birthDate = "";
  this.gender = "";
  this.race = [];
  this.income = [];
}

function Race() {
  this.raceCode = "";
  this.otherDesc = "";
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}


function Income() {
  this.grossIncome = "";
  this.frequencyCode = "";
  this.otherIncomeTypeCode = "";
  this.hours = "";
  this.hoursfrequencyCode = "";
  this.wageTypeCode = "";
  this.id = 0;
  this.clientId = "";
  this.lastUpdateUser = "";
  this.key = "";
  this.lastUpdateTime = new Date();

}

function Resources() {
  this.resourceType = "";
  this.amount = "";
}

function Expenses() {
  this.expenseType = "";
  this.expenseAmount = "";
}