export function homeReducer(state = {}, action) {
  switch (action.type) {
    case "P_EBT_BANNER_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.data) {
        newState.pEbtBanner = action.payload.data;
        newState.pEbtBanner.map((obj) => {
          let buff = new Buffer(obj.desc, "base64");
          obj.desc = buff.toString("UTF-8");
        });
        sessionStorage.setItem("PEBTBanner", JSON.stringify(newState.pEbtBanner));
      }
      return newState;
    }

    case "P_EBT_BANNER_ERROR": {
      const newState = Object.assign({}, state);
      newState.pEbtBanner = null;
      return newState;
    }
    default:
      return state;
  }
}
