import * as languageConsts from "../../utils/constants/types";
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function nonCitizenCodeMapper(language) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        ASYLEE: "Asylee",
        BATRD: "Battered Noncitizen/VAWA",
        CONENT: "Conditional Entrant",
        CUBHAT: "Cuban/Haitian Entrant",
        DEPORT: "Deportation Withheld Noncit.",
        DOS: "Deportation Order Shown",
        FILIPI: "Filipino",
        HMONG: "Hmong",
        LAOS: "Highland Laotian",
        LPR: "Legal Permanent Resident",
        NATCTZ: "Naturalized Citizen",
        NCVET: "Noncitizen Veteran",
        NLR: "Not legally residing",
        NONIM: "Nonimmigrant",
        OTHER: "Other",
        PAROLE: "Parolee",
        PRUCOL: "PRUCOL",
        REFUG: "Refugee",
        UNDET: "Undetermined",
        UNKWN: "Unknown",
        VAWA: "Violence Against Women Act (VAWA)",
        VCTOTR: "Victim of Trafficking",
        VIETAM: "Vietnamese Amerasian",
      };
    case languageConsts.SPANISH:
      return {
        ASYLEE: "Asilado",
        BATRD: "No ciudadano maltratado/Ley VAWA",
        CONENT: "Ingresante condicional",
        CUBHAT: "Ingresante cubano/haitiano",
        DEPORT: "No ciudadano con deportación retenida",
        DOS: "Exhibición de orden de deportación",
        FILIPI: "Filipino",
        HMONG: "Hmong",
        LAOS: "Laosiano de la región montañosa",
        LPR: "Residente legal permanente",
        NATCTZ: "Ciudadano naturalizado",
        NCVET: "Estado de no ciudadano",
        NLR: "Residente ilegal",
        NONIM: "No inmigrante",
        OTHER: "Otro",
        PAROLE: "Liberado bajo palabra",
        PRUCOL: "PRUCOL",
        REFUG: "Refugiado",
        UNDET: "Indeterminado",
        UNKWN: "Desconocido",
        VAWA: "Ley de Violencia Contra la Mujer (VAWA, por sus siglas en inglés)",
        VCTOTR: "Víctima de tráfico",
        VIETAM: "Vietnamita americoasiático",
      };
    case languageConsts.PORTUGUESE:
      return {
        ASYLEE: "Asilado",
        BATRD: "Não cidadão violentado/VAWA",
        CONENT: "Entrante condicional",
        CUBHAT: "Entrante cubano/haitiano",
        DEPORT: "Não cidadão retido por deportação",
        DOS: "Ordem de deportação mostrada",
        FILIPI: "Filipino",
        HMONG: "Hmong",
        LAOS: "Laosiano",
        LPR: "Residente permanente legal",
        NATCTZ: "Cidadão naturalizado",
        NCVET: "Veterano não cidadão",
        NLR: "Não legalmente residente",
        NONIM: "Não imigrante",
        OTHER: "Outro",
        PAROLE: "Em liberdade condicional",
        PRUCOL: "PRUCOL",
        REFUG: "Refugiado",
        UNDET: "Indeterminado",
        UNKWN: "Desconhecido",
        VAWA: "Lei de Violência contra a Mulher (VAWA)",
        VCTOTR: "Vítima de tráfico",
        VIETAM: "Amerasiático Vietnamita",
      };

    case languageConsts.CHINESE: //chinees
      return {
        ASYLEE: "难民",
        BATRD: "受虐非公民/VAWA",
        CONENT: "符合条件的入境者",
        CUBHAT: "古巴/海地入境者",
        DEPORT: "驱逐出境扣留非公民",
        DOS: "已收到驱逐出境命令者",
        FILIPI: "菲律宾人",
        HMONG: "苗族人",
        LAOS: "高山族辽国人",
        LPR: "合法永久居民",
        NATCTZ: "归化公民",
        NCVET: "非公民退伍军人",
        NLR: "非合法居留者",
        NONIM: "非移民",
        OTHER: "其他",
        PAROLE: "假释犯",
        PRUCOL: "PRUCOL",
        REFUG: "难民",
        UNDET: "未决定",
        UNKWN: "未知",
        VAWA: "《防止对妇女施暴法》(VAWA)",
        VCTOTR: "贩卖人口受害者",
        VIETAM: "越南美亚混血儿",
      };
    case languageConsts.VIETNAMESE:
      return {
        ASYLEE: "Người xin tị nạn",
        BATRD:
          "Người nhập cư bị đánh đập/Đạo Luật Chống Bạo Lực Đối Với Phụ Nữ (VAWA)",
        CONENT: "Người đăng ký có điều kiện",
        CUBHAT: "Người Cuba / Haiti",
        DEPORT: "Người nhập cư trục xuất bị giữ lại",
        DOS: "Lệnh trục xuất được xuất trình",
        FILIPI: "Người Philipin",
        HMONG: "Người Hmong",
        LAOS: "Người Lào vùng Cao nguyên",
        LPR: "Thường trú nhân hợp pháp",
        NATCTZ: "Công dân đã nhập tịch",
        NCVET: "Cựu chiến binh không phải là cư dân",
        NLR: "Không cư trú hợp pháp",
        NONIM: "Không định cư",
        OTHER: "Khác",
        PAROLE: "Người được tha",
        PRUCOL: 'NGƯỜI CƯ TRÚ "KHÔNG HỢP PHÁP"',
        REFUG: "Người tị nạn",
        UNDET: "Không xác định",
        UNKWN: "Không biết",
        VAWA: "Đạo Luật Chống Bạo Lực Đối Với Phụ Nữ (VAWA)",
        VCTOTR: "Nạn nhân của nạn buôn người",
        VIETAM: "Người Mỹ gốc Việt",
      };
    case HI:
      return {
        ASYLEE: "Azile",
        BATRD: "Non-sitwayen yo Bat/VAWA",
        CONENT: "Antre Kondisyonèl",
        CUBHAT: "Antre Kiben/Ayisyen",
        DEPORT: "Depòtasyon Nonsit Kenbe.",
        DOS: "Òdonans Depòtasyon Montre",
        FILIPI: "Filipino",
        HMONG: "Hmong",
        LAOS: "Highland Laotian",
        LPR: "Rezidan Pèmanan Legal",
        NATCTZ: "Sitwayen Natiralize",
        NCVET: "Veteran Non-sitwayen",
        NLR: "Not legally residing",
        NONIM: "Non-imigran",
        OTHER: "Lòt",
        PAROLE: "Libere sou Kondisyon",
        PRUCOL: "PRUCOL",
        REFUG: "Refije",
        UNDET: "Endetèmine",
        UNKWN: "Enkoni",
        VAWA: "Violence Against Women Act (VAWA)",
        VCTOTR: "Viktim nan Moun",
        VIETAM: "Vyetnamyen Amerendyen",
      };

    default:
      return;
  }
}
