import * as SharedProps from "../../utils/constants/shared";
import * as consumerLabels from "./consumerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export const consumerNavSectionsMapData = [
  {
    [SharedProps.ID]: "myInfo",
    [SharedProps.TITLE]: consumerLabels.consumerConst(selectedLanguage).myInfo,
    [SharedProps.ICON]: "my-info",
    hidetoUnLinkedUser: false,
  },
  {
    [SharedProps.ID]: "activity",
    [SharedProps.TITLE]:
      consumerLabels.consumerConst(selectedLanguage).activity,
    [SharedProps.ICON]: "alert",
    hidetoUnLinkedUser: false,
  },
  {
    [SharedProps.ID]: "documents",
    [SharedProps.TITLE]:
      consumerLabels.consumerConst(selectedLanguage).documents,
    [SharedProps.ICON]: "documents",
    hidetoUnLinkedUser: false,
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]:
      consumerLabels.consumerConst(selectedLanguage).myAccount,
    [SharedProps.ICON]: "my-info",
    hidetoUnLinkedUser: true,
  },
  {
    [SharedProps.ID]: "concernappeal",
    [SharedProps.TITLE]: consumerLabels.consumerConst(selectedLanguage).consumerconcernfair + '     '+consumerLabels.consumerConst(selectedLanguage).consumerfair,
    [SharedProps.ICON]: "my-info",
    hidetoUnLinkedUser: true,
  },
 
];

export const consumerNewUserNavSections = [
  {
    [SharedProps.ID]: "myInfo",
    [SharedProps.TITLE]: consumerLabels.consumerConst(selectedLanguage).myInfo,
    [SharedProps.ICON]: "my-info",
  },
  {
    [SharedProps.ID]: "documents",
    [SharedProps.TITLE]:
      consumerLabels.consumerConst(selectedLanguage).documents,
    [SharedProps.ICON]: "documents",
  },
];

export default consumerNavSectionsMapData;
