import React from 'react';
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { SummaryData } from '../../recertification/SummaryData';
import { beforeYoustartMlanguageLabels, resourcesSectionMultilanguageLabels, incomeSectionMultilanguageLabels } from '../applySnapText';
import { HouseholdSummaryData } from '../../recertification/HouseholdSummaryData';

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

const DescriptionList = ({title = "", list = [], completed = false, applicationType = "", authorizedRepresentatives, sectionId, ebtAuthRepFlg, snapAuthRepFlg, snpStateFlg, ebtStateFlg, householdInformation}) => {

  return(
      <div>
        {title && completed && (applicationType === "apply") 
         ? <h4 className="description-list__title">{title}</h4> : 
        title && applicationType !== "apply" && title !== "namePlaceholder" && sectionId !== "add-auth-rep"
        ? <h4 className="description-list__title">{title}</h4> 
        : null} 
        {sectionId === "auth-rep" && (
          <React.Fragment>
          {SummaryData(authorizedRepresentatives, ebtAuthRepFlg, snapAuthRepFlg, ebtStateFlg, snpStateFlg)}
          </React.Fragment>
        )}
        {sectionId === "tanf-household-information" && (
          <React.Fragment>
            {HouseholdSummaryData(householdInformation)}
          </React.Fragment>
        )}
        <ul className="description-list__itemContainer"> 
          {list && list.map(listItem => {
            if(listItem){
              let { label , value, labelHasMarkup = false } = listItem;
              {
                if(typeof value === "string") {
                  if(value === 'false') {
                    value = "No"
                  }else if(value === "true") {
                    value = "Yes"
                  }
                }
              }

              if(listItem.type === "resources"){
                return <div>
                  <h4>{listItem.resourceType}</h4>
                  {listItem && listItem.Answer && listItem.Answer.map(resourceSection => {
                    return (
                    <React.Fragment> 
                      {resourceSection.amount && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">{beforeYoustartMlanguageLabels(language).amountLabel}</div>
                          {<div className="description-list__itemContent">{resourceSection.amount}</div>} 
                        </li>
                      }
                      {resourceSection.templateType === "ResourcesTemplateOne" && resourceSection.additionalInformation && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">{resourcesSectionMultilanguageLabels(language).templateOneLabel2}</div>
                          <div className="description-list__itemContent">{resourceSection.additionalInformation}</div> 
                        </li>
                      }
                      {resourceSection.templateType !== "ResourcesTemplateOne" && resourceSection.bankName && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">
                            {resourceSection.templateType === "ResourcesTemplateTwo" ? 
                               resourcesSectionMultilanguageLabels(language).templateTwoLabelNotReq2
                               : resourcesSectionMultilanguageLabels(language).templateOneLabeNotReq32
                            }
                          </div>
                          <div className="description-list__itemContent">{resourceSection.bankName}</div> 
                        </li>
                      }
                      {resourceSection.templateType === "ResourcesTemplateTwo" && resourceSection.accountnumber && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">{resourcesSectionMultilanguageLabels(language).templateTwoLabel3}</div>
                          <div className="description-list__itemContent">{resourceSection.accountnumber}</div> 
                        </li>
                      }
                      {resourceSection.templateType === "ResourcesTemplateTwo" && resourceSection.jointAccount && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">{resourcesSectionMultilanguageLabels(language).templateTwoLabelNotReq4}</div>
                          <div className="description-list__itemContent">{resourceSection.jointAccount}</div> 
                        </li>
                      }
                      {resourceSection.templateType === "ResourcesTemplateTwo" && resourceSection.jointAccount === incomeSectionMultilanguageLabels(language).targetValueYes && 
                        <li className="description-list__item" key={uniqueId()}>
                          <div className="description-list__itemContent">{resourcesSectionMultilanguageLabels(language).templateTwoLabelNotReq5}</div>
                          <div className="description-list__itemContent">{resourceSection.jointAccountHolder}</div> 
                        </li>
                      }
                    </React.Fragment> 
                    )
                  })}
              </div>
              
              } else {
              return label && value && value !== '-' && value !== 'Monthly' && <li className="description-list__item" key={uniqueId()}>
                  <div className="description-list__itemContent">
                    {labelHasMarkup ? (<span dangerouslySetInnerHTML={{__html: label}} />) : label}
                  </div>
                  <div className="description-list__itemContent">{value ? value : '-'}</div>
                </li>
              }
            }else{
              return "";
            }
          })}
        </ul>
      </div>
    )
}

DescriptionList.PropTypes = {
   title: PropTypes.string,
   items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    labelHasMarkup: PropTypes.bool
   })
   )
}

export default DescriptionList;
