import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Collapse from "react-collapse";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import { enableUniqueIds } from "react-html-id";
import DTAInput from "../../../utils/components/dtaInput";
import DTASelect from "../../../utils/components/dtaSelect";
import {
  setChildSupportData,
  setDataChangeStatus,
  setChildSupportChangedStatus,
} from "../../../redux/recertCovid/recertCovidActions";
import { INPUT_TYPE_MONEY } from "../../../utils/constants/controls";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import { interimReportPageLabels } from "../recertCovidText";
import HelpTip from "../../../utils/components/helpTip";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ChildSupportExpenses extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      nonChildExpList: [],
      childExpList: [],
      addChildSupportExp: TextProps.VALUE_FALSE,
      dataQuestionChanged: null,
      childAddOpen: TextProps.VALUE_FALSE,
      memberToAddExpFor: null,
      addExpAmount: "",
      addExpFreq: "",
      tempChildExpArray: [],
    };
  }

  componentDidMount() {
    let tempExistList = [];
    let tempNonExistList = [];

    if (this.props.covidRecert.childExpScreen) {
      //Get updated household members list for adding child exp details
      let childExpMembersNames = [];

      this.props.covidRecert.childExpScreen.tempChildExpArray.map((child) => {
        if (child.dataChangeCode !== null) {
          child.isOpen = TextProps.VALUE_FALSE;
          tempExistList.push(child);

          childExpMembersNames.push(
            child.firstName + " " + child.lastName + child.dateOfBirth
          );
        }
      });

      this.props.covidRecert.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode) return;
        let memName =
          member.firstName + " " + member.lastName + member.dateOfBirth;

        if (childExpMembersNames.indexOf(memName) === -1) {
          let child = {
            id: 0,
            setID: 0,
            agencyID: member.agencyID,
            firstName: member.firstName,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth,
            typeCode: "CHDSPT",
            frequencyCode: "",
            amount: "",
            dataChangeCode: null,
            isOpen: TextProps.VALUE_FALSE,
            isComplete: TextProps.VALUE_FALSE,
          };
          tempNonExistList.push(child);
        }
      });

      let tempChildExpArray = _.concat(tempExistList, tempNonExistList);
      this.setState(
        {
          dataQuestionChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged:
              this.props.covidRecert.childExpScreen.childExpChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
    } else {
      this.setInitialData();
    }
  }

  setInitialData = () => {
    let childExpMembersNames = [];
    let tempExistList = [];
    let tempNonExistList = [];
    let childExpResponseObj = _.cloneDeep(
      this.props.covidRecert.responseObject.childSupportExpense
    );
    if (childExpResponseObj && childExpResponseObj.length > 0) {
      childExpResponseObj.map((child) => {
        child.dataChangeCode = "existing";
        child.isOpen = TextProps.VALUE_FALSE;
        child.originalAmount = child.amount + "";
        child.originalFrequency = child.frequency;
        child.isComplete = TextProps.VALUE_TRUE;
        tempExistList.push(child);
        childExpMembersNames.push(
          child.firstName + " " + child.lastName + child.dateOfBirth
        );
      });

      this.props.covidRecert.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode) return;
        let memName =
          member.firstName + " " + member.lastName + member.dateOfBirth;

        if (memName.indexOf(childExpMembersNames) === -1) {
          let child = {
            id: 0,
            setID: 0,
            agencyID: member.agencyID,
            firstName: member.firstName,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth,
            typeCode: "CHDSPT",
            frequencyCode: "",
            amount: "",
            dataChangeCode: null,
            isOpen: TextProps.VALUE_FALSE,
            isComplete: TextProps.VALUE_FALSE,
          };
          tempNonExistList.push(child);
        }
      });

      let tempChildExpArray = _.concat(tempExistList, tempNonExistList);

      this.setState(
        {
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
    } else {
      this.props.covidRecert.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode === "removed") return;

        let child = {
          id: 0,
          setID: 0,
          agencyID: member.agencyID,
          firstName: member.firstName,
          lastName: member.lastName,
          dateOfBirth: member.dateOfBirth,
          typeCode: "CHDSPT",
          frequencyCode: "",
          amount: "",
          dataChangeCode: null,
          isOpen: TextProps.VALUE_FALSE,
          isComplete: TextProps.VALUE_FALSE,
        };
        tempNonExistList.push(child);
      });

      let tempChildExpArray = tempNonExistList;

      this.setState(
        {
          childExpChanged: this.state.dataQuestionChanged,
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
    }
  };

  render() {
    let nonMemberCount = 0;
    let childExpVal = null;
    if (
      this.props.covidRecert.childExpScreen &&
      this.props.covidRecert.childExpScreen.childExpChanged ===
        TextProps.VALUE_TRUE
    ) {
      childExpVal = interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else if (
      this.props.covidRecert.childExpScreen &&
      this.props.covidRecert.childExpScreen.childExpChanged ===
        TextProps.VALUE_FALSE
    ) {
      childExpVal = interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1];
    }

    return (
      <div className="pad-all--double child-help-tip-section">
        <span className="spaced-text">
          <HelpTip
            textBefore={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportQlegalbefore
            }
            triggerText={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportQlegaltrigger
            }
            textAfter={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportQlegalAfter
            }
            helpText={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportHelp
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </span>
        <br />
        <br />
        {this.props.covidRecert.childExpScreen &&
        this.props.covidRecert.childExpScreen.tempChildExpArray
          ? this.props.covidRecert.childExpScreen.tempChildExpArray.map(
              (member, index) => {
                if (member.dataChangeCode)
                  return this.childExpBox(member, index);
                else if (member.dataChangeCode === null) nonMemberCount++;
              }
            )
          : null}
        <br />
        {this.props.covidRecert.childExpScreen &&
        this.props.covidRecert.childExpScreen.childExpChanged &&
        nonMemberCount >= 0 ? (
          <div className="pure-u-1-1">
            <Collapse isOpened={this.state.addChildSupportExp}>
              {this.renderChildSupportToAddNewMember()}
              <br />
              <br />
            </Collapse>
            <button
              onClick={this.toggleChildExpAddMember}
              aria-expanded={this.state.addChildSupportExp}
              className="full-width dta-button text--small interim-recert-outline-button"
            >
              <b>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSupport_AddHouseHold
                }
              </b>
            </button>
            <br />
            <br />
          </div>
        ) : (
          TextProps.VALUE_FALSE
        )}
        <span style={{ wordBreak: "keep-all" }}>
          {
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).childSupportExpenses_Question
          }
        </span>

        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.covidRecert.sections[5].selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                name="earned-income-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={childExpVal}
                onChange={(value) => this.onChildExpChanged(value)}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }

  renderChildSupportToAddNewMember = () => {
    return (
      <div key={this.nextUniqueId()}>
        {this.state.tempChildExpArray.map((member) => {
          if (
            member.dataChangeCode === null &&
            member.isOpen === TextProps.VALUE_TRUE
          )
            return this.renderChildForm(member);
        })}
        <Collapse isOpened={this.state.addChildSupportExp}>
          <span className="add-income-summary__label"></span>
          <ul className="list--unstyled add-income-summary__list">
            {this.state.tempChildExpArray.map((member) => {
              if (
                member.dataChangeCode === null &&
                member.isOpen === TextProps.VALUE_FALSE
              ) {
                return (
                  <li key={this.nextUniqueId()}>
                    <a
                      style={{ textDecoration: "none" }}
                      href="javascript:void(0);"
                      onClick={() => {
                        this.renderChildExpAddForm(member);
                        return TextProps.VALUE_FALSE;
                      }}
                      className="dta-button dta-button--outline-secondary dta-button--block"
                    >
                      <span className="truncate-text">
                        {this.getDisplayName(member)}
                      </span>
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </Collapse>
      </div>
    );
  };

  renderChildExpAddForm = (member) => {
    let childArray = this.state.tempChildExpArray;
    let filtered = _.findIndex(childArray, {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth,
    });
    if (filtered >= 0) childArray[filtered].isOpen = TextProps.VALUE_TRUE;
    this.setState({ tempChildExpArray: childArray }, () => {
      this.props.setChildSupportData({
        childExpChanged: this.props.covidRecert.childExpScreen.childExpChanged,
        tempChildExpArray: this.state.tempChildExpArray,
      });
    });
  };

  handleCancelAddMember = (member) => {
    let childArray = this.state.tempChildExpArray;
    let filtered = _.findIndex(childArray, {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth,
    });
    if (filtered >= 0) {
      childArray[filtered].isOpen = TextProps.VALUE_FALSE;
      childArray[filtered].dataChangeCode = null;
      childArray[filtered].amount = "";
      childArray[filtered].frequency = "";
    }
    this.setState({ tempChildExpArray: childArray }, () => {
      this.props.setChildSupportData({
        childExpChanged: this.props.covidRecert.childExpScreen.childExpChanged,
        tempChildExpArray: this.state.tempChildExpArray,
      });
    });
  };

  handleExpValueChanged = (member, value) => {
    let childArray = this.state.tempChildExpArray;
    let filtered = _.findIndex(childArray, {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth,
    });
    if (filtered >= 0) {
      childArray[filtered].editedAmount = value;
      childArray[filtered].isComplete = TextProps.VALUE_FALSE;
    }
    this.setState({ tempChildExpArray: childArray }, () => {
      this.props.setChildSupportData({
        childExpChanged: this.props.covidRecert.childExpScreen.childExpChanged,
        tempChildExpArray: this.state.tempChildExpArray,
      });
    });
  };

  handleExpFreqChanged = (member, value) => {
    let childArray = this.state.tempChildExpArray;
    let filtered = _.findIndex(childArray, {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth,
    });
    if (filtered >= 0) {
      childArray[filtered].editedFrequency = value;
      childArray[filtered].isComplete = TextProps.VALUE_FALSE;
    }
    this.setState({ tempChildExpArray: childArray }, () => {
      this.props.setChildSupportData({
        childExpChanged: this.props.covidRecert.childExpScreen.childExpChanged,
        tempChildExpArray: this.state.tempChildExpArray,
      });
    });
  };

  getDisplayName = (member) => {
    if (member)
      return member.firstName + " " + member.lastName + member.dateOfBirth;
    else return "";
  };

  renderChildForm = (member) => {
    return (
      <div>
        <hr className="divider" />
        <div className="spaced-text pure-u-1-1">
          <div className="pure-g">
            <div
              className="pure-u-sm-20-24 pure-u-md-21-24 pure-u-lg-19-24 pure-u-xl-20-24"
              style={{ textAlign: "right" }}
            >
              <button
                onClick={() => this.handleCancelAddMember(member)}
                className="dta-button dta-delete-button interim-recert-delete-button text--small remove-btn-align"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).deleteButtonlabel
                }
              </button>
            </div>
            <div
              className="pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-5-24 pure-u-xl-4-24"
              style={{ textAlign: "right" }}
            >
              <button
                onClick={() => this.handleAddChildSupportExp(member)}
                className="dta-button text--small interim-recert-outline-button"
              >
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).doneButtonlabel
                }
              </button>
            </div>
          </div>
          <br />
          <span className="spaced-text">
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupport_Question
            }
            {this.getDisplayName(member)}?
          </span>
          <div className="child-edit-ans">
            <div className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24">
              <DTAInput
                name={"amount"}
                type={INPUT_TYPE_MONEY}
                className={"dta-units__item"}
                value={member.amount ? member.amount : ""}
                maxLength={12}
                onChange={(value) => this.handleExpValueChanged(member, value)}
                ariaLabel={"amount"}
              />
            </div>
            <div
              aria-hidden={TextProps.VALUE_STR_TRUE}
              className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24 child-units-label"
            >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).titlePer
              }
            </div>
            <div className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24">
              <DTASelect
                name={"unit"}
                className="dta-units__item"
                value={member.frequency ? member.frequency : ""}
                options={
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions
                }
                onChange={(value) => this.handleExpFreqChanged(member, value)}
                ariaLabel={"unit"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderChildSupportToEditMember = (member, index) => {
    return (
      <div key={index} className="pure-g child-edit-question">
        <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-18-24 pure-u-lg-15-24 pure-u-xl-17-24">
          {this.getDisplayName(member)}
          <br />
          <br />
        </div>
        <div
          className="pure-u-12 pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-4-24 pure-u-xl-3-24"
          style={{ textAlign: "right" }}
        >
          <button
            onClick={() => this.handleDeleteMember(index)}
            className="dta-button text--small dta-delete-button interim-recert-delete-button interim-mar-btn"
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).deleteButtonlabel
            }
          </button>
        </div>
        <div
          className="pure-u-12 pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-5-24 pure-u-xl-4-24"
          style={{ textAlign: "right" }}
        >
          <button
            onClick={() => this.handleEditDoneMember(index)}
            className="dta-button text--small interim-mar-btn interim-recert-outline-button"
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).doneButtonlabel
            }
          </button>
        </div>

        <div className="pure-u-1-1 child-edit-question">
          <span className="spaced-text">
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupport_Question
            }
            {this.getDisplayName(member)}?
          </span>

          <div className="child-edit-ans">
            <div className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24">
              <DTAInput
                name={"amount"}
                id={"amount-" + index}
                type={INPUT_TYPE_MONEY}
                className={"dta-units__item"}
                value={member.amount ? member.amount : ""}
                maxLength={12}
                onChange={(value) => this.handleExpValueChanged(member, value)}
              />
            </div>
            <div
              aria-hidden={TextProps.VALUE_STR_TRUE}
              className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24 child-units-label"
            >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).titlePer
              }
            </div>
            <div className="pure-u-24-24 pure-u-sm-8-24 pure-u-md-8-24 pure-u-lg-8-24 pure-u-xl-8-24">
              <DTASelect
                name={"unit-" + index}
                id={"unit-" + index}
                className="dta-units__item"
                value={member.frequency ? member.frequency : ""}
                options={
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions
                }
                onChange={(value) => this.handleExpFreqChanged(member, value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleAddChildSupportExp = (member) => {
    let childArray = this.state.tempChildExpArray;
    let filtered = _.findIndex(childArray, {
      firstName: member.firstName,
      lastName: member.lastName,
      dateOfBirth: member.dateOfBirth,
    });
    if (filtered >= 0) {
      childArray[filtered].isOpen = TextProps.VALUE_FALSE;
      childArray[filtered].amount = childArray[filtered].editedAmount
        ? childArray[filtered].editedAmount
        : childArray[filtered].amount;
      childArray[filtered].frequency = childArray[filtered].editedFrequency
        ? childArray[filtered].editedFrequency
        : childArray[filtered].frequency;
      childArray[filtered].editedAmount = "";
      childArray[filtered].editedFrequency = "";
      childArray[filtered].isComplete = TextProps.VALUE_TRUE;
      childArray[filtered].dataChangeCode = "new";
    }
    this.setState(
      ...this,
      {
        tempChildExpArray: childArray,
      },
      () => {
        this.props.setChildSupportData({
          tempChildExpArray: this.state.tempChildExpArray,
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
        });
      }
    );
  };

  handleEdit = (index) => {
    let childArray = this.state.tempChildExpArray;
    if (index >= 0) {
      childArray[index].isOpen = TextProps.VALUE_TRUE;
    }
    this.setState(
      {
        tempExisingChildSupp: childArray,
      },
      () => {
        this.props.setChildSupportData({
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: this.state.tempChildExpArray,
        });
      }
    );
  };

  handleUndo = (index) => {
    let childArray = this.state.tempChildExpArray;
    if (index >= 0) {
      if (
        childArray[index].amount + "" === childArray[index].originalAmount &&
        childArray[index].frequency === childArray[index].originalFrequency
      )
        childArray[index].dataChangeCode = "existing";
      else childArray[index].dataChangeCode = "changed";
    }
    this.setState(
      {
        tempExisingChildSupp: childArray,
      },
      () => {
        this.props.setChildSupportData({
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: this.state.tempChildExpArray,
        });
      }
    );
  };

  handleEditDoneMember = (index) => {
    let childArray = this.state.tempChildExpArray;
    if (index >= 0) {
      childArray[index].isOpen = TextProps.VALUE_FALSE;
      childArray[index].isComplete = TextProps.VALUE_TRUE;
      childArray[index].amount = childArray[index].editedAmount
        ? childArray[index].editedAmount
        : childArray[index].amount;
      childArray[index].frequency = childArray[index].editedFrequency
        ? childArray[index].editedFrequency
        : childArray[index].frequency;
      if (
        (childArray[index].amount || childArray[index].frequency) &&
        childArray[index].dataChangeCode !== null
      ) {
        if (
          childArray[index].amount + "" === childArray[index].originalAmount &&
          childArray[index].frequency === childArray[index].originalFrequency
        )
          childArray[index].dataChangeCode = "existing";
        else if (childArray[index].dataChangeCode === "existing")
          childArray[index].dataChangeCode = "changed";
      }
      childArray[index].editedAmount = "";
      childArray[index].editedFrequency = "";
    }

    this.setState(
      {
        tempExisingChildSupp: childArray,
      },
      () => {
        this.props.setChildSupportData({
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: this.state.tempChildExpArray,
        });
      }
    );
  };

  handleDeleteMember = (index) => {
    let childArray = this.state.tempChildExpArray;
    if (index >= 0) {
      if (
        childArray[index].dataChangeCode !== null &&
        (childArray[index].dataChangeCode === "existing" ||
          childArray[index].dataChangeCode === "changed")
      ) {
        childArray[index].dataChangeCode = "removed";
        childArray[index].isComplete = TextProps.VALUE_TRUE;
        childArray[index].isOpen = TextProps.VALUE_FALSE;
        childArray[index].editedAmount = "";
        childArray[index].editedFrequency = "";
      } else if (
        childArray[index].dataChangeCode !== null &&
        childArray[index].dataChangeCode === "new"
      ) {
        childArray[index].dataChangeCode = null;
        childArray[index].isComplete = TextProps.VALUE_FALSE;
        childArray[index].isOpen = TextProps.VALUE_FALSE;
        childArray[index].editedAmount = "";
        childArray[index].editedFrequency = "";
        childArray[index].amount = "";
        childArray[index].frequency = "";
      }
    }
    this.setState(
      {
        tempExisingChildSupp: childArray,
      },
      () => {
        this.props.setChildSupportData({
          childExpChanged:
            this.props.covidRecert.childExpScreen.childExpChanged,
          tempChildExpArray: this.state.tempChildExpArray,
        });
      }
    );
  };

  toggleChildExpAddMember = () => {
    this.setState({ addChildSupportExp: !this.state.addChildSupportExp });
  };

  childExpBox = (member, index) => {
    if (
      (this.props.covidRecert.childExpScreen &&
        this.props.covidRecert.childExpScreen.childExpChanged ===
          TextProps.VALUE_FALSE) ||
      !member.isOpen
    ) {
      let label = "";
      let color = "black";
      if (member.dataChangeCode === "new") {
        label = "newLabel";
      } else if (member.dataChangeCode === "changed") {
        label = "changedLabel";
      } else if (member.dataChangeCode === "removed") {
        label = "removedLabel";
        color = "#be1317";
      }

      let memberDataCodeChange;
      if (member.dataChangeCode && member.dataChangeCode === "removed") {
        memberDataCodeChange = (
          <button
            onClick={() => this.handleUndo(index)}
            className="dta-button text--small interim-recert-outline-button"
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.householdStatic
              ).undoButtonLabel
            }
          </button>
        );
      } else {
        memberDataCodeChange = (
          <button
            onClick={() => this.handleEdit(index)}
            className="dta-button text--small interim-recert-outline-button"
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).editButtonLabel
            }
          </button>
        );
      }

      return (
        <div
          key={index}
          className="max-width pure-u-1-1 spaced-text child-edit-question"
        >
          <div
            className="pure-u-15-24 pure-u-sm-19-24 child-question-right"
            style={{ verticalAlign: "middle", color: color }}
          >
            {this.getDisplayName(member)}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportPayLabel
            }{" "}
            ${member.amount}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportPerLabel
            }{" "}
            {member.frequency}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).childSupportLabel
            }
            {label !== "" ? (
              <DataChangeIndicator
                text={
                  interimReportPageLabels(
                    language,
                    languageConstants.householdStatic,
                    null,
                    null,
                    null
                  )[label]
                }
              />
            ) : null}
          </div>
          {this.props.covidRecert.childExpScreen &&
          this.props.covidRecert.childExpScreen.childExpChanged ? (
            <div
              className="pure-u-9-24 pure-u-sm-5-24"
              style={{ textAlign: "right" }}
            >
              {memberDataCodeChange}
            </div>
          ) : null}
          <hr className="divider" />
        </div>
      );
    } else {
      if (member.dataChangeCode !== null)
        return this.renderChildSupportToEditMember(member, index);
    }
  };

  onChildExpChanged = (value) => {
    if (
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState({ dataQuestionChanged: TextProps.VALUE_FALSE }, () => {
        this.setInitialData();
        this.props.setChildSupportChangedStatus(TextProps.VALUE_FALSE);
        this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 5);
      });
    } else {
      let stateObj = this.props.covidRecert.childExpScreen;
      stateObj.childExpChanged = TextProps.VALUE_TRUE;
      this.setState({ dataQuestionChanged: TextProps.VALUE_TRUE }, () => {
        this.props.setChildSupportData(stateObj);
        this.props.setChildSupportChangedStatus(TextProps.VALUE_TRUE);
        this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 5);
      });
    }
  };

  strToTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setChildSupportData: setChildSupportData,
      setDataChangeStatus: setDataChangeStatus,
      setChildSupportChangedStatus: setChildSupportChangedStatus,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildSupportExpenses);
