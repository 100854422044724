import * as TextProps from "../../utils/constants/text";

export function aboutMeBinding(aboutMeSection, serverJson) {
  const applicationType = sessionStorage.getItem("applicationType");

  let application = serverJson.application;
  let applicant = application.applicant;
  //First Bind the AboutMe Section.
  aboutMeSection.clientId = applicant.clientId;
  aboutMeSection.isGrantee = applicant.isGrantee;
  let aboutMeQuestions = aboutMeSection.questions;
  aboutMeQuestions[0] = bindBothTargetAnswer(
    aboutMeQuestions[0],
    applicant.firstName
  );
  aboutMeQuestions[1] = bindBothTargetAnswer(
    aboutMeQuestions[1],
    applicant.middleName
  );
  aboutMeQuestions[2] = bindBothTargetAnswer(
    aboutMeQuestions[2],
    applicant.lastName
  );
  aboutMeQuestions[3] = bindBothTargetAnswer(
    aboutMeQuestions[3],
    applicant.nameSuffix
  );
  aboutMeQuestions[4] = bindBothTargetAnswer(
    aboutMeQuestions[4],
    new Date(applicant.birthDate)
  );
  aboutMeQuestions[5] = bindBothTargetAnswer(
    aboutMeQuestions[5],
    applicant.SSN
  );

  //Contact Info Section
  let contactQuestions = aboutMeSection.subsections[0].questions;
  if (applicationType === "SNAP") {
    contactQuestions[0] = bindBothTargetAnswer(
      contactQuestions[0],
      applicant.isHomeless === TextProps.VALUE_Y
        ? TextProps.VALUE_YES
        : TextProps.VALUE_NO
    );
    if (applicant.addresses.length > 0) {
      applicant.addresses[0].zipCode = applicant.addresses[0].postal;
      contactQuestions[1] = bindBothTargetAnswer(
        contactQuestions[1],
        applicant.addresses[0]
      );
      contactQuestions[2] = bindBothTargetAnswer(
        contactQuestions[2],
        applicant.addresses[0].isSameMailAndPhysical === TextProps.VALUE_Y
          ? TextProps.VALUE_STR_TRUE
          : TextProps.VALUE_STR_FALSE
      );
      if (
        applicant.addresses[0].isSameMailAndPhysical !== TextProps.VALUE_Y &&
        applicant.addresses[0].isSameMailAndPhysical !== ""
      ) {
        applicant.addresses[1].zipCode = applicant.addresses[1].postal;
        contactQuestions[2].conditionals[0].conditionalQuestions[0] =
          bindBothTargetAnswer(
            contactQuestions[2].conditionals[0].conditionalQuestions[0],
            applicant.addresses[1]
          );
      }
    }
    contactQuestions[3] = bindBothTargetAnswer(
      contactQuestions[3],
      applicant.areaCode + applicant.exchangeNumber + applicant.lineNumber
    );
    contactQuestions[4] = bindBothTargetAnswer(
      contactQuestions[4],
      applicant.emailAddress
    );
  } else if (applicationType === "TANF") {
    if (applicant.addresses.length > 0) {
      applicant.addresses[0].zipCode = applicant.addresses[0].postal;
      contactQuestions[0] = bindBothTargetAnswer(
        contactQuestions[0],
        applicant.addresses[0]
      );
      contactQuestions[1] = bindBothTargetAnswer(
        contactQuestions[1],
        applicant.addresses[0].isSameMailAndPhysical === TextProps.VALUE_Y
          ? TextProps.VALUE_STR_TRUE
          : TextProps.VALUE_STR_FALSE
      );
      if (
        applicant.addresses[0].isSameMailAndPhysical !== TextProps.VALUE_Y &&
        applicant.addresses[0].isSameMailAndPhysical !== ""
      ) {
        applicant.addresses[1].zipCode = applicant.addresses[1].postal;
        contactQuestions[1].conditionals[0].conditionalQuestions[0] =
          bindBothTargetAnswer(
            contactQuestions[1].conditionals[0].conditionalQuestions[0],
            applicant.addresses[1]
          );
      }
    }
    contactQuestions[2] = bindBothTargetAnswer(
      contactQuestions[3],
      applicant.areaCode + applicant.exchangeNumber + applicant.lineNumber
    );
    contactQuestions[3] = bindBothTargetAnswer(
      contactQuestions[4],
      applicant.emailAddress
    );
  }

  //Emergency Info Section
  let emergencyQuestions = aboutMeSection.subsections[1].questions;
  emergencyQuestions[2] = bindBothTargetAnswer(
    emergencyQuestions[2],
    extractEmeregencyNeeds(20, application.needs)
  );
  emergencyQuestions[3] = bindBothTargetAnswer(
    emergencyQuestions[3],
    extractEmeregencyNeeds(21, application.needs)
  );
  emergencyQuestions[4] = bindBothTargetAnswer(
    emergencyQuestions[4],
    extractEmeregencyNeeds(22, application.needs)
  );

  //More Info Section

  let moreInfoQuestions = aboutMeSection.subsections[2].questions;
  moreInfoQuestions[0] = bindBothTargetAnswer(
    moreInfoQuestions[0],
    applicant.isUSCitizen === TextProps.VALUE_Y
      ? TextProps.VALUE_YES
      : TextProps.VALUE_NO
  );
  moreInfoQuestions[1] = bindBothTargetAnswer(
    moreInfoQuestions[1],
    applicant.languageCodeSpoken
  );

  //Optional Section
  let optionalQuestions = aboutMeSection.subsections[3].questions;
  optionalQuestions[0] = bindBothTargetAnswer(
    optionalQuestions[0],
    applicant.gender
  );
  optionalQuestions[1] = bindBothTargetAnswer(
    optionalQuestions[1],
    applicant.ethnicity
  );
  optionalQuestions[2].Answer = [];
  optionalQuestions[2].targetValue = [];

  for (let applicantValue of applicant.race) {
    optionalQuestions[2].Answer.push(applicantValue.raceCode);
    optionalQuestions[2].targetValue.push(applicantValue.raceCode);
  }
  return aboutMeSection;
}

//Answer and Target Object Created Here
function bindBothTargetAnswer(question, Answer) {
  question.Answer = Answer;
  question.targetValue = Answer;
  return question;
}

//Find Emergency Needs Answer using TypeCode
function extractEmeregencyNeeds(code, object) {
  for (let objectVal of object) {
    if (objectVal.needTypeCode === code) {
      return objectVal.answer === TextProps.VALUE_Y
        ? TextProps.VALUE_YES
        : TextProps.VALUE_NO;
    }
  }
  return "";
}
