import React, { Component } from 'react';

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
    };
  }

  handleSelectChange = (event) => {
    this.setState({ selectedOption: event.target.value });
    if (this.props.onChange) {
        this.props.onChange(event.target.value);
      }
  }

  render() {
    const {option,language,defaultValue,customCss} = this.props;
    return (
      <div className={`${customCss ? 'w-100' : 'float-right'}`}>
        {language ? <label htmlFor="selectOption"><b>{language.folders}:</b> </label> : ""}
        <select
            className={`language-select ${customCss ? "p-8" : ''}`}
          id="selectOption"
          value={this.state.selectedOption || defaultValue}
          onChange={this.handleSelectChange}
        >
             {option.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
        </select>
      </div>
    );
  }
}

export default SelectDropdown;
