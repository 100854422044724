import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/rootReducer";
import Radio from "../../utils/components/radio";
import HelpTip from "../../utils/components/helpTip";
import { formatDate, formatMoney } from "../../utils/components/utilities/formatters";
import { setDataChangeStatus, appendIRIncomeDetails, clearMemberIncome, setHouseholdMembers, setRecertDataChangeStatus, setAuthRepPurchaseFoodChangeStatus, setAuthRepEligibilityChangeStatus, setAuthRepResponseBodyData } from "../../redux/recertification/recertificationActions";
// import { IncomeSectionTemplate } from "../recertInterimReportMapData";
import * as languageConstants from "../../utils/constants/constants";
import * as TextProps from "../../utils/constants/text";
import { interimReportPageLabels } from "../recertInterimReport/recertInterimReportText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import { recertificationPageLabels } from "../recertification/recertificationText";
import { houseHoldSectionMultilanguageLabels } from "./applySnapText";
import DTAInput from "../../utils/components/dtaInput";
import DtaSelect from "../../utils/components/dtaSelect";
import Checkbox from "../../utils/components/checkbox";
import { mhProviderSNAPGAP } from "../provider/providerText";
import * as types from "../../utils/constants/types";

const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class AuthRep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderedSections: [],
            incomeSummaryEdit: TextProps.VALUE_FALSE,
            isLoading: TextProps.VALUE_FALSE,
            addIncomeMembersOpen: TextProps.VALUE_FALSE,
            sections: null,
            respData: null,
            submitFormId: null,
            errorMessagesforData: {
                firstnameError: TextProps.VALUE_FALSE,
                lastnameError: TextProps.VALUE_FALSE,
                phonenumberError: TextProps.VALUE_FALSE,
            },
            iagreeState: TextProps.VALUE_FALSE,
            authorizedRepresentatives: {
                ebtFrstNam: "",
                ebtLstNam: "",
                ebtMdlNam: "",
                ebtPhNum: "",
                ebtSuffix: null,
                ebtWebDataChangeCode: "NEW",
                snpFrstNam: "",
                snpLstNam: "",
                snpMdlNam: "",
                snpPhNum: "",
                snpSuffix: null,
                snpWebDataChangeCode: "NEW"
            },
        };
        enableUniqueIds(this);
    }

    componentWillReceiveProps() {
        this.setState(
            {
                sections: this.props.recert.sections,
                respData: this.props.recert.responseObject,
                authorizedRepresentativesResponse: this.props.recert.authorizedRepresentatives
            },
            () => {
                if (this.state.renderedSections.length < 1) {
                    this.setState({
                        renderedSections: [this._getSection(this.props.activeSectionId)],
                        submitFormId: this.nextUniqueId(),
                    });
                }
            }
        );
        if (this.props.recert.setOldIncomeData === TextProps.VALUE_TRUE) {
            this.onAuthRepChanged(
                interimReportPageLabels(
                    language,
                    languageConstants.aboutMe,
                    null,
                    null,
                    null
                ).optionYesNo[1]
            );
        }
    }


    toggleAddIncomeMembers = () => {
        this.setState({ addIncomeMembersOpen: !this.state.addIncomeMembersOpen });
    };

    // This function is related to simple, specific 'routing'between sections.
    _getSection(sectionId) {
        let targetSection = {};

        _.some(this.state.sections, (sectionObj) => {
            const found = this._getSectionHelper(sectionId, sectionObj);
            if (found) {
                targetSection = found;
                return TextProps.VALUE_TRUE; // short circuit
            } else {
                return TextProps.VALUE_FALSE; // keep on searching
            }
        });
        return targetSection;
    }

    _getSectionHelper(sectionId, sectionObj) {
        if (sectionObj.id === sectionId) {
            return sectionObj;
        } else if (sectionObj.subsections) {
            const numSub = sectionObj.subsections.length;
            for (let i = 0; i < numSub; ++i) {
                const found = this._getSectionHelper(
                    sectionId,
                    sectionObj.subsections[i]
                );
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    onAuthRepPurchaseFoodChanged = (value) => {
        this.props.handleAuthRepDataChanged(TextProps.VALUE_TRUE);
        // this.props.recert.responseObject.ebtAuthRepFlg = "Y"
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.setState(
                {
                    incomeValuesPopulated: TextProps.VALUE_FALSE,
                    incomeSubSectionCleared: TextProps.VALUE_FALSE,
                },
                () => {
                    this.props.handleebtAuthRepFlg("N")
                        // this.props.setRecertDataChangeStatus(TextProps.VALUE_TRUE, 6);
                        // this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);
                    // this.props.setSectionDataUpdated(TextProps.VALUE_FALSE);
                    this.props.clearFieldsByQuestion("ebtQuestion");
                    this.props.setFlag("ebtFrstNamError", TextProps.VALUE_FALSE);
      this.props.setFlag("ebtLstNamError", TextProps.VALUE_FALSE);
      this.props.setFlag("ebtPhNumError", TextProps.VALUE_FALSE);
                }
            );
        } else {
            this.setState(
                {},
                () => {
                    this.props.handleebtAuthRepFlg("Y")
                        // this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
                        // this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);

                    // this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
                }
            );
        }
    };



    onAuthRepEligibilityChanged = (value) => {
        this.props.handleAuthRepDataChanged(TextProps.VALUE_TRUE);
        if (
            value ===
            interimReportPageLabels(
                language,
                languageConstants.aboutMe,
                null,
                null,
                null
            ).optionYesNo[1]
        ) {
            this.setState(
                {
                    incomeValuesPopulated: TextProps.VALUE_FALSE,
                    incomeSubSectionCleared: TextProps.VALUE_FALSE,
                },
                () => {
                    this.props.handlesnpAuthRepFlg("N");
                        // this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
                        // this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);

                    // this.props.setSectionDataUpdated(TextProps.VALUE_FALSE);
                    this.props.clearFieldsByQuestion("snapQuestion");
                    this.props.setFlag("snpFrstNamError", TextProps.VALUE_FALSE);
        this.props.setFlag("snpLstNamError", TextProps.VALUE_FALSE);
        this.props.setFlag("snpPhNumError", TextProps.VALUE_FALSE);
                }
            );
        } else {
            this.setState(
                {},
                () => {
                    // this.props.setAuthRepEligibilityChangeStatus("Y");
                   this.props.handlesnpAuthRepFlg("Y")
                        // this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
                        // this.props.setAuthRepDataUpdated(TextProps.VALUE_TRUE);
                    // this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
                }
            );
        }
    };


    returnAuthRepPurchaseFoodLabel() {
          if (
            this.props.addAuthRepData && this.props.addAuthRepData.ebtAuthRepFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.addAuthRepData && this.props.addAuthRepData.ebtAuthRepFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }

    returnAuthRepEligibilityLabel() {
        if (
            this.props.addAuthRepData !== null && this.props.addAuthRepData.snapAuthRepFlg === "Y"
        ) {
            return interimReportPageLabels(language, languageConstants.aboutMe)
                .optionYesNo[0];
        } else {
            if (
                this.props.addAuthRepData !== null && this.props.addAuthRepData.snapAuthRepFlg === "N"
            ) {
                return interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1];
            } else {
                return null;
            }
        }
    }
  

    handleSubmissionData = () => {
        const authRepSummary = this.props.recert.authorizedRepresentatives;
    }

    handleSuffixChangedEg = (value) => {
        this.setState({ suffixValueEg: value })
    }

    handleSuffixChanged = (value) => {
        this.setState({ suffixValue: value })
    }


    render() {
        var listOfUpdatedHHMembers = [];
        var existingIncomeMembers = [];

        let addIncomeMembersActive = "";
        if (this.state.addIncomeMembersOpen) {
            addIncomeMembersActive = "is-active";
        }
        const authRepResp = this.props.recert.authorizedRepresentatives;
        return (
            <div key={this.nextUniqueId()} className="pad-all--double">
                {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
                <p style={{ color: "#005974", fontWeight: "bolder" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestionone
                    }
                </p>
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiononedesc
                    }
                </span>
                <p style={{ color: "#005974", fontWeight: "bolder" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwo
                    }
                </p>
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc1
                    }
                </span>{" "}
                <span style={{ textDecoration: "underline" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).underlineText1
                    }
                </span>{" "}
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc2
                    }
                </span>{" "}
                <span style={{ textDecoration: "underline" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).underlineText2
                    }
                </span>{" "}
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc3
                    }
                </span>
                <br />
                <br />
                <p>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authSnapTanfQuestion
                    }
                </p>
                <div className="pure-u-1-1">
                    <br />
                    <div>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="earned-income"
                                name="earned-income-chnaged"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    interimReportPageLabels(
                                        language,
                                        languageConstants.aboutMe,
                                        null,
                                        null,
                                        null
                                    ).optionYesNo
                                }
                                required={TextProps.VALUE_TRUE}
                                value={this.returnAuthRepPurchaseFoodLabel()}
                                onChange={(value) => this.onAuthRepPurchaseFoodChanged(value)}
                            />
                        </fieldset>
                    </div>
                </div>
                {(this.props.addAuthRepData && this.props.addAuthRepData.ebtAuthRepFlg === "Y") ?
                    <div>
                        <div className="pure-u-1">
                            <label className="dta-form__label">
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).firstName
                                }
                            </label>
                            {this.props.flags.ebtFrstNamError == true && (
                                <span id="ebtFrstNamError" className="firstname dta-form__error-message">
                                    {mhProviderSNAPGAP(types.ENGLISH).firstNameErrorMsg}
                                </span>
                            )}
                            <DTAInput
                                name={"firstName"}
                                className="dta-form__control"
                                type="specialTextType"
                                ref="firstName"
                                maxLength={15}
                                value={this.props.addAuthRepData.ebtFrstNam}
                                onChange={(event) => this.props.inputCheck(event, "ebtFrstNam")}
                                required={TextProps.VALUE_TRUE}
                                error={this.props.flags.ebtFrstNamError}
                            />
                        </div>
                        <br />
                        <div className="pure-u-1">
                            <label className="dta-form__label">
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).middleName
                                }
                            </label>
                            <DTAInput
                                className="dta-form__control"
                                required={TextProps.VALUE_TRUE}
                                type="specialTextType"
                                maxLength={15}
                                value={this.props.addAuthRepData.ebtMdlNam}
                                onChange={(e) => this.props.inputCheck(e, "ebtMdlNam")}
                            />
                        </div>
                        <br />
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 200px" }}>
                            <div className="pure-u-1 pad-right-half">
                                <label className="dta-form__label">
                                    {
                                        recertificationPageLabels(
                                            language,
                                            null,
                                            languageConstants.authRep
                                        ).lastName
                                    }
                                </label>
                                {this.props.flags.ebtLstNamError && (
                                    <span id="firstNameError" className="firstname dta-form__error-message">
                                        {mhProviderSNAPGAP(types.ENGLISH).lastNameErrorMsg}
                                    </span>
                                )}
                                <DTAInput
                                    className="dta-form__control"
                                    required={TextProps.VALUE_TRUE} 
                                    type="specialTextType"
                                    maxLength={20}
                                    value={this.props.addAuthRepData.ebtLstNam}
                                    onChange={(value) => this.props.inputCheck(value, "ebtLstNam")}
                                    error={this.props.flags.ebtLstNamError}
                                />
                            </div>
                            <div className="pure-u-1">
                                <label className="dta-form__label">
                                    {
                                        recertificationPageLabels(
                                            language,
                                            null,
                                            languageConstants.authRep
                                        ).suffixLabel
                                    }
                                </label>
                                <DtaSelect
                                    name={"unit"}
                                    className="dta-units__item"
                                    value={this.props.addAuthRepData.ebtSuffix}
                                    options={
                                        houseHoldSectionMultilanguageLabels(language).SuffixOptions
                                    }
                                    onChange={(value) => this.props.inputCheck(value, "ebtSuffix")}
                                    ariaLabel={"Suffix"}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="pure-u-1">
                            <label className="dta-form__label">
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).phoneNumber
                                }
                            </label>
                            {this.props.flags.ebtPhNumError && (
                                <span id="firstNameError" className="firstname dta-form__error-message">
                                    {this.props.ebtPhoneValid ?
                                    mhProviderSNAPGAP(types.ENGLISH).phoneNumberLengthErrorMsg
                                :

                                    mhProviderSNAPGAP(types.ENGLISH).phoneNumberErrorMsg
                                }
                                </span>
                            )}
                            <DTAInput
                                className="dta-form__control"
                                required={TextProps.VALUE_TRUE}
                                type="tel"
                                value={this.props.addAuthRepData.ebtPhNum}
                                onChange={(value) => this.props.inputCheck(value, "ebtPhNum")}
                                error={this.props.flags.ebtPhNumError}
                            />
                        </div>
                    </div>
                    : <div></div>}
                {/* <br /> */}
                <p style={{ color: "#005974", fontWeight: "bolder" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep,
                        ).authCertificationTitle
                    }
                </p>
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep,
                        ).authCertificationDesc
                    }
                </span>
                <br />
                <br />
                <br />
                <div className="pure-u-1-1">
                    <p>{recertificationPageLabels(
                        language,
                        null,
                        languageConstants.authRep
                    ).authQuestiontwoQues}</p>
                    <br/>
                    <div>
                        <fieldset className="dta-form__group">
                            <Radio
                                id="earnings"
                                name="earnings-changed"
                                className="dta-form__option-list"
                                optionClass="dta-form__option dta-form__option--width-1-of-2"
                                options={
                                    ['Yes', 'No']
                                }
                                required={TextProps.VALUE_TRUE}
                                value={this.returnAuthRepEligibilityLabel()}
                                onChange={(value) => this.onAuthRepEligibilityChanged(value)}
                            />
                        </fieldset>
                    </div>
                    <br />
                    {(this.props.addAuthRepData && this.props.addAuthRepData.snapAuthRepFlg === "Y") ?
                        <div>
                            <div className="pure-u-1">
                                <label className="dta-form__label">
                                    {
                                        recertificationPageLabels(
                                            language,
                                            null,
                                            languageConstants.authRep
                                        ).firstName
                                    }
                                </label>
                                {this.props.flags.snpFrstNamError && (
                                    <span id="firstNameError" className="firstname dta-form__error-message">
                                        {mhProviderSNAPGAP(types.ENGLISH).firstNameErrorMsg}
                                    </span>
                                )}
                                <DTAInput
                                    className="dta-form__control"
                                    required={TextProps.VALUE_TRUE}
                                    type="specialTextType"
                                    maxLength={15}
                                    value={this.props.addAuthRepData.snpFrstNam}
                                    onChange={(value) => this.props.inputCheck(value, "snpFrstNam")}
                                    error={this.props.flags.snpFrstNamError}
                                />
                            </div>
                            <br />
                            <div className="pure-u-1">
                                <label className="dta-form__label">
                                    {
                                        recertificationPageLabels(
                                            language,
                                            null,
                                            languageConstants.authRep
                                        ).middleName
                                    }
                                </label>
                                <DTAInput
                                    className="dta-form__control"
                                    required={TextProps.VALUE_TRUE}
                                    type="specialTextType"
                                    maxLength={15}
                                    value={this.props.addAuthRepData.snpMdlNam}
                                    onChange={(value) => this.props.inputCheck(value, "snpMdlNam")}
                                />
                            </div>
                            <br />
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 200px" }}>
                                <div className="pure-u-1 pad-right-half">
                                    <label className="dta-form__label">
                                        {
                                            recertificationPageLabels(
                                                language,
                                                null,
                                                languageConstants.authRep
                                            ).lastName
                                        }
                                    </label>
                                    {this.props.flags.snpLstNamError && (
                                        <span id="firstNameError" className="firstname dta-form__error-message">
                                            {mhProviderSNAPGAP(types.ENGLISH).lastNameErrorMsg}
                                        </span>
                                    )}
                                    <DTAInput
                                        className="dta-form__control"
                                        required={TextProps.VALUE_TRUE}
                                        type="specialTextType"
                                        maxLength={20}
                                        value={this.props.addAuthRepData.snpLstNam}
                                        onChange={(value) => this.props.inputCheck(value, "snpLstNam")}
                                        error={this.props.flags.snpLstNamError}

                                    />
                                </div>
                                <div className="pure-u-1">
                                    <label className="dta-form__label">
                                        {
                                            recertificationPageLabels(
                                                language,
                                                null,
                                                languageConstants.authRep
                                            ).suffixLabel
                                        }
                                    </label>
                                    <DtaSelect
                                        name={"unit"}
                                        className="dta-units__item"
                                        value={this.props.addAuthRepData.snpSuffix}
                                        options={
                                            houseHoldSectionMultilanguageLabels(language).SuffixOptions
                                        }
                                        onChange={(value) => this.props.inputCheck(value, "snpSuffix")}
                                        ariaLabel={"Suffix"}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="pure-u-1">
                                <label className="dta-form__label blue_text">
                                    {
                                        recertificationPageLabels(
                                            language,
                                            null,
                                            languageConstants.authRep
                                        ).phoneNumber
                                    }
                                </label>
                                {this.props.flags.snpPhNumError && (
                                    <span id="firstNameError" className="firstname dta-form__error-message">
                                        {this.props.snpPhoneValid ?

mhProviderSNAPGAP(types.ENGLISH).phoneNumberLengthErrorMsg
:
                                        mhProviderSNAPGAP(types.ENGLISH).phoneNumberErrorMsg
                                    }
                                    </span>
                                )}
                                <DTAInput
                                    className="dta-form__control"
                                    required={TextProps.VALUE_TRUE}
                                    type="tel"
                                    value={this.props.addAuthRepData.snpPhNum}
                                    onChange={(value) => this.props.inputCheck(value, "snpPhNum")}
                                    error={this.props.flags.snpPhNumError}
                                />
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearMemberIncome: clearMemberIncome,
            appendIRIncomeDetails: appendIRIncomeDetails,
            setHouseholdMembers: setHouseholdMembers,
            setRecertDataChangeStatus: setRecertDataChangeStatus,
            setDataChangeStatus: setDataChangeStatus,
            setAuthRepPurchaseFoodChangeStatus: setAuthRepPurchaseFoodChangeStatus,
            setAuthRepEligibilityChangeStatus: setAuthRepEligibilityChangeStatus,
            setAuthRepResponseBodyData: setAuthRepResponseBodyData
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRep);
