//Global Routes
import React, { Component } from "react";

//Main App Class
class NotFound extends Component {
  render() {
    return <div>PAGE NOT FOUND</div>;
  }
}

export default NotFound;
