import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import TermsTafdc from "../../../utils/components/termstafdc";
import Checkbox from "../../../utils/components/checkbox";
import DTAInputSubmit from "../../../utils/components/dtaInputSubmit";
import { beforeYoustartMlanguageLabels } from "../applyTafdcText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";
import { langOptSel } from "../../../utils/constants/constants";

//end code for google analytics

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === langOptSel) {
    language = "en-US";
  } 

class SignAndSubmit extends Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div>
        <div key={this.nextUniqueId()} className="pad-all--double">
          {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}

          {/* Clicking 'View your Application Summary' button will download PDF summary */}

          <form id={this.props.submitFormId}>
            <TermsTafdc id="submit-1" className="dta-form__label" />
            <div>
              {this.props.agreeError ? (
                <b>
                  <p className="error-text-clr">
                    {beforeYoustartMlanguageLabels(language).agreeTermsErrorMsg}
                  </p>
                </b>
              ) : (
                <p></p>
              )}
            </div>
            <Checkbox
              className="dta-form__option-list"
              optionClass="dta-form__option"
              aria-labelledby="submit-1"
              options={[
                beforeYoustartMlanguageLabels(language).iagreeTermsLabel,
              ]}
              ref="isAcceptTerms"
              onChange={this.props.acceptTermsConditions}
              required={TextProps.VALUE_TRUE}
              error={this.agreeError}
            />

            <hr className="divider" />
            <label className="dta-form__label" htmlFor="sign-app">
              <p className="apply__heading">
                {beforeYoustartMlanguageLabels(language).signYourAppllication}
              </p>
            </label>
            {/* Clicking will submit the application and route user to follow up page */}
            <div>
              {this.props.signatureError ? (
                <b>
                  <p className="error-text-clr">
                    {beforeYoustartMlanguageLabels(language).signatureErrorMsg}
                  </p>
                </b>
              ) : (
                <p></p>
              )}
            </div>
            <DTAInputSubmit
              className="dta-form__control"
              id="sign-app"
              type="alpha-text"
              maxLength={30}
              value={this.props.signature}
              ref="applicanSign"
              onChange={this.props.updateSignature}
              required={TextProps.VALUE_TRUE}
              error={this.props.signatureError}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default SignAndSubmit;
