import React, { Component } from 'react';
import Pagination from '../../../utils/components/pagination';
import reply from '../../../utils/resources/images/reply.png';
import info from '../../../utils/resources/images/info.png';
import infoLight from '../../../utils/resources/images/icons/info-grey.svg';
import DTAModal from '../../../utils/components/modal/dtaModal';
import { formatDateToCustomFormat } from '../../../utils/helper/DateConversion';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as consumerLabels from "../consumerText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class DynamicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            englishHeading: {
                'Inbox': ['Inbox', 'Received', 'Reply'],
                'Sent Messages': ["Sent Messages", "Sent", "Reply"],
                'Archive': ["Archive messages", "By Date"],
            },
            spanishHeading: {
                'Bandeja de entrada': [ consumerLabels.consumerConst(selectedLanguage).inbox, consumerLabels.consumerConst(selectedLanguage).received, consumerLabels.consumerConst(selectedLanguage).reply],
                'Mensajes enviados': [consumerLabels.consumerConst(selectedLanguage).sendMsg, consumerLabels.consumerConst(selectedLanguage).send, consumerLabels.consumerConst(selectedLanguage).reply],
                'Archivo': [consumerLabels.consumerConst(selectedLanguage).archives, consumerLabels.consumerConst(selectedLanguage).byDate],
            },
            activePage: 1,
            conditionMet: true,
            startIndex: 0,
            endIndex: 20,
            displayPage: 20,
            isReplyClicked: false,
            isMessageViewed: false,
            messageContent: {},
            editorState: EditorState.createEmpty(),
            fromEmailID:'',
            to:'',
            cc: '',
            sendToCountyList: false,
            importantMessage: false,
            clientId: "",
            replyFromInbox:false


        };
    }



    handlePageChange = (pageNumber) => {
        const page = pageNumber - 1;
        this.setState({
            activePage: pageNumber,
            startIndex: page * this.state.displayPage,
            endIndex: page * this.state.displayPage + this.state.displayPage,
        });
    };

    onEditorStateChange = (editorState) => {
        this.setState({ editorState });
        this.setState({ setContent: `${draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}` })
        const currentState = this.state.setContent;
    }


    openReplyModal = (messageContent,via) => {
        const updatedMessageContent = { ...messageContent, fromModal: via === 'inbox' };
        var clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
        const { name, agencyID } = clientDetails.clientInfo;
        const { emailAddress } = clientDetails.clientEmail;
        if(updatedMessageContent.clientId === agencyID){
            this.setState({
                isReplyClicked: true,
                fromEmailID: `${updatedMessageContent.clientNam} (${updatedMessageContent.clientId})`,
                to: via === 'inbox' ? updatedMessageContent.fromEmail : updatedMessageContent.toEmail,
                messageContent: updatedMessageContent,
            });
        }else{
            updatedMessageContent.ccEmail = updatedMessageContent.toEmail
            this.setState({
                isReplyClicked: true,
                fromEmailID: `${name.first} ${name.last} (${agencyID})`,
                to: via === 'inbox' ? updatedMessageContent.fromEmail : emailAddress,
                messageContent: updatedMessageContent,
            }); 
        }

        updatedMessageContent.dsc = updatedMessageContent.dsc.replaceAll('\n','').replace('</p><table>','</p><br><table>').replaceAll('</td></tr>', '<br></td></tr>')

        const blocksFromHtml = htmlToDraft(`${
            `
            <br>_________________________________________________________<br>
        <span><strong>From:</strong> ${updatedMessageContent.fromEmail}</span><br>
        <span><strong>To:</strong> ${updatedMessageContent.toEmail}</span><br>
        <span><strong>Subject:</strong> ${updatedMessageContent.subject ==null? "": updatedMessageContent.subject}</span><br>
         
         
         ${updatedMessageContent.dsc}<br>`
        }`); 

        const { contentBlocks, entityMap } = blocksFromHtml; 
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap); 
        const editorState = EditorState.createWithContent(contentState);

        this.setState({ editorState });
        
        this.props.updateGrid(this.props.activeTab, this.props.from);
    };

    closeReplyModal = () => {
        this.setState({ isReplyClicked: false,editorState:EditorState.createEmpty() });
        this.props.updateGrid(this.props.activeTab, this.props.from);
        document.getElementsByTagName("body")[0].style.position='unset';
    };

    replyFromInbox = (messageContent) =>{
        const { clientNam, fromEmail, toEmail, clientId } = messageContent;
        var clientDetails = JSON.parse(
            sessionStorage.getItem("clientDetails")
          );
        const { name, agencyID } = clientDetails.clientInfo;
        const { emailAddress } = clientDetails.clientEmail;
        if(clientId === agencyID){
            this.setState({
                isMessageViewed: false,
                isReplyClicked: true,
                fromEmailID: `${clientNam} (${clientId})`,
                to: this.state.replyFromInbox ? fromEmail : toEmail,
                sendToCountyList: "",
                editorState: ""
            });    
        }else{
            messageContent.ccEmail = messageContent.toEmail
            this.setState({
                isMessageViewed: false,
                isReplyClicked: true,
                fromEmailID: `${name.first} ${name.last} (${agencyID})`,
                to: this.state.replyFromInbox ? fromEmail : emailAddress,
                sendToCountyList: "",
                editorState: "",
                messageContent
            }); 
        }
        document.getElementsByTagName("body")[0].style.position='fixed';

        messageContent.dsc = messageContent.dsc.replaceAll('\n','').replace('</p><table>','</p><br><table>').replaceAll('</td></tr>', '<br></td></tr>')

        const blocksFromHtml = htmlToDraft(`${`
        
        <br>_________________________________________________________<br>
        <span><strong>From:</strong> ${fromEmail}</span><br>
        <span><strong>To:</strong> ${toEmail}</span><br>
        <span><strong>Subject:</strong> ${messageContent.subject ==null? "": messageContent.subject}</span><br>


        ${messageContent.dsc}<br>
        `}`); 

        const { contentBlocks, entityMap } = blocksFromHtml; 
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap); 
        const editorState = EditorState.createWithContent(contentState);

        this.setState({ editorState });


    }
    openMessageModal = (messageContent,via) => {
        const { fromEmail, clientNam, toEmail, clientId } = messageContent;
        const newState = {
            isMessageViewed: true,
            fromEmailID: via === "inbox" ? fromEmail : `${clientNam} (${clientId})`,
            to: via === "inbox" ? `${clientNam} (${clientId})` : toEmail,
            messageContent: messageContent,
        };

        if(via === "inbox"){
            newState.replyFromInbox = true;
        }

        this.setState(newState);

        if (messageContent.readFlg === 'N') {
            const msgPayload = {
                "lastUpdtUidNam": "SECUREMSG",
                "setId": messageContent.setId,
                "messageCenterId": messageContent.messageCenterId
            };
            this.props.onSecureMessageReply(msgPayload, 'update');
        }
    };
    closeMessageModal = () => {
        this.setState({ isMessageViewed: false, messageContent: {} });
        this.props.updateGrid(this.props.activeTab, this.props.from);
    };
    handleCcInputChange = (event) => {
        const {messageContent} = this.state;
        messageContent.ccEmail = event.target.value;
        this.setState({ messageContent });
    };
    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        this.setState({
            [name]: checked
        });
    };
    onSubmitMessage = (e) => {
        e.preventDefault();
        const { sendToCountyList, importantMessage, setContent,fromEmailID,to} = this.state;
        const { ccEmail,subject,msgDlCc,messageCenterId,setId } = this.state.messageContent;
        const userDetails = JSON.parse(sessionStorage.getItem("userInfo"));
        const { agencyID,email } = userDetails

        const replyPayload = {
            "messageCenterId": messageCenterId,
            "setId": setId,
            "clientId": agencyID,
            "sourceNam": "Client",
            "msgFrom": email,
            "msgTo": to,
            "msgDlCc": msgDlCc || ccEmail,
            "recvrType": "Worker",
            "subject": subject,
            "dsc": setContent,
            "lastUpdtUidNam": "SECUREMSG",
            "msgImp": importantMessage ? "Y" : "N",
            "isMsgDl": sendToCountyList ? "Y" : "N",
        }
        this.props.onSecureMessageReply(replyPayload, 'reply');
        setTimeout(() => {
            this.closeReplyModal()
        }, 1500);

    };

    renderTable(via) {
        const { activePage, displayPage } = this.state;
        const { data, loading, from,lang } = this.props;
        const startIndex = (activePage - 1) * displayPage;
        const endIndex = startIndex + displayPage;
        const headings = lang === "en-US" ? this.state.englishHeading[from] : this.state.spanishHeading[from] ;
        const rowsForCurrentPage = data.slice(startIndex, endIndex);

        return (
            <table className="custom-table">
                <thead>
                    <tr>
                        {headings.map((heading, index) => (
                            <th key={index}>{heading}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {loading ? (
                        <tr>
                            <td style={{ textAlign: "center", padding: "8px" }} colSpan={headings.length}>
                            {consumerLabels.consumerConst(selectedLanguage).loadingLabel}

                            </td>
                        </tr>
                    ) : rowsForCurrentPage.length === 0 ? (
                        <tr>
                            <td style={{ textAlign: "center", padding: "8px" }} colSpan={headings.length}>
                               {consumerLabels.consumerConst(selectedLanguage).NoDataFound}
                            </td>
                        </tr>
                    ) : (
                        rowsForCurrentPage.map((rowData, rowIndex) => (
                            <tr key={rowIndex}>
                                {headings.map((heading, index) => (
                                    <td key={index}>
                                        {index === 0 ? (
                                            <div className='flex cursor-pointer' onClick={() => this.openMessageModal(rowData,via)}>
                                                {via === "inbox" ? <div className={`line-height  pl-8 ${rowData.readFlg === "Y" ? "" : "blue-border-left"}`}></div> : ""}
                                                {via === 'inbox' ? <div className={`d-inblock ${rowData.readFlg === "Y" ? "pl-6" : ""}`} style={{ paddingTop: "22px" }}>
                                                    {rowData.msgImp === "Y" ? <img src={info} alt="reply" /> : <img src={infoLight} alt="reply" />}
                                                </div> : ""}

                                                {via === 'sent' ? <div className={`d-inblock pl-6`} style={{ paddingTop: "22px" }}>
                                                    {rowData.msgImp === "Y" ? <img src={info} alt="reply" /> : <img src={infoLight} alt="reply" />}
                                                </div> : ""}

                                                <div className='p-8' style={{ lineHeight: "1.35" }}>
                                                    <h3 className='m-0 font-bold' style={{ fontSize: "17px" }}>{rowData.clientNam}</h3>
                                                    <h4 className={`m-0 ${rowData.readFlg === 'N' ? 'font-bold':'font-normal'} ${via === "inbox" && rowData.readFlg !== "Y" ? "theme-colour" : ""}`}>{rowData.subject}</h4>
                                                    <div className="messageContent" dangerouslySetInnerHTML={{ __html: rowData.dsc.length ? rowData.dsc.split('<br>____________')[0]:''}} />
                                                </div>
                                            </div>
                                        ) : index === 2 ? (
                                            <span className='cursor-pointer replyFromGrid' style={{ padding: "0 15px" }}
                                                onClick={() => this.openReplyModal(rowData,via)}>
                                                <img src={reply} alt="reply" /> {rowData[heading]}
                                            </span>
                                        ) : (
                                            
                                            <span style={{ padding: "0 6px" }}>
                                              {via === 'Archive' ? formatDateToCustomFormat(rowData.archiveTs)  : formatDateToCustomFormat(rowData.createTs) }
                                            </span> 
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        const { activePage, displayPage,messageContent,fromEmailID,to,cc } = this.state;
        const { data, from } = this.props;
        const rowsForCurrentPage = data.slice(this.state.startIndex, this.state.endIndex);
        const { editorState } = this.state;
        return (
            <div>
                {from === consumerLabels.consumerConst(selectedLanguage).inbox ? this.renderTable("inbox") : from === consumerLabels.consumerConst(selectedLanguage).sendMsg ? this.renderTable("sent") : this.renderTable("Archive")}

                {(rowsForCurrentPage.length > 0 && !this.props.loading) && (
                    <Pagination
                        activePage={activePage}
                        displayPage={displayPage}
                        itemsCount={data.length}
                        pageRangeDisplayed={1}
                        handlePageChange={this.handlePageChange}
                        alignCenter="messageCenter"
                    />
                )}

                {/* Reply Message */}


                <DTAModal
                    isOpen={this.state.isReplyClicked}
                    onClose={this.closeReplyModal}
                    modalClass="message-modal"
                    titleText={messageContent.subject ? messageContent.subject : "message-modal"}
                    headerText={messageContent.subject ? messageContent.subject : ""}
                    error={false}
                    errorMessage={"this.state.exampleModalErrorMessage"}>
                    <div className="dta-modal__body pad-all" id="replyModal">
                        <form id="secureMessage">
                            <div className="form-group flex">
                                <label htmlFor="from">{consumerLabels.consumerConst(selectedLanguage).from}</label>
                                <input 
                                type="text" 
                                id="from" 
                                name="from" 
                                style={{ opacity: "0.85" }} 
                                readOnly 
                                disabled 
                                value={ fromEmailID} />

                            </div>

                            <div className="form-group flex" style={{ marginBottom: "7px" }}>
                                <label className="d-inblock" htmlFor="to">{consumerLabels.consumerConst(selectedLanguage).to}</label>
                                <input 
                                type="text" 
                                id="to" 
                                name="to" 
                                style={{ opacity: "0.85" }} 
                                readOnly 
                                disabled
                                value={ to} />

                            </div>
                            <div className="form-group flex">
                                <label className="d-inblock" htmlFor="to"></label>
                                <div className="d-inblock message-checkbox-label">
                                    <input
                                        type="checkbox"
                                        id="sendToCountyList"
                                        name="sendToCountyList"
                                        checked={this.state.sendToCountyList}
                                        onChange={this.handleCheckboxChange}
                                    />
                                    <label className='w-100' htmlFor="sendToCountyList">{consumerLabels.consumerConst(selectedLanguage).sendTxt}</label>
                                </div>
                            </div>
                            <div className="form-group flex" style={{ marginBottom: "7px" }}>
                                <label className="d-inblock" htmlFor="cc">Cc</label>
                                {messageContent.msgDlCc ? <input
                                    className="d-inblock"
                                    type="text"
                                    id="cc"
                                    name="cc"
                                    value={messageContent.ccEmail}
                                    disabled
                                /> : <input
                                    className="d-inblock"
                                    type="text"
                                    id="cc"
                                    name="cc"
                                    value={messageContent.ccEmail}
                                    onChange={this.handleCcInputChange}
                                />}
                                
                        
                            </div>
                            <div className="form-group flex">
                                <label className="d-inblock" htmlFor="checkbox"></label>
                                <div className="d-inblock message-checkbox-label">
                                    <input
                                        type="checkbox"
                                        id="importantMessage"
                                        name="importantMessage"
                                        checked={this.state.importantMessage}
                                        onChange={this.handleCheckboxChange}
                                    />
                                    <label htmlFor="importantMessage">{consumerLabels.consumerConst(selectedLanguage).importantMsg}</label>
                                </div>
                            </div>

                            <div>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    toolbar={
                                        {

                                            fontFamily: {
                                                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto', 'Source Sans Pro'],
                                            },
                                        }}
                                    editorStyle={{ border: "1px solid #ccc", height: "200px", padding: "15px" }}
                                    toolbarStyle={{ border: "1px solid #ccc", paddingRight: "10%" }}
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                            </div>

                        </form>
                        <div className="dta-modal__footer print_btn">
                            <div className="float-right secure" style={{ paddingBottom: "10px" }}>
                                <button className="dta-button dta-button--primary" style={{backgroundColor:'white', color: '#002a57'}} onClick={() => window.print()}>{consumerLabels.consumerConst(selectedLanguage).print}</button>
                                <button onClick={this.onSubmitMessage} className="dta-button dta-button--primary">{consumerLabels.consumerConst(selectedLanguage).send}</button>
                            </div>
                        </div>
                    </div>
                </DTAModal>

                {/* View Message */}
                <DTAModal
                    isOpen={this.state.isMessageViewed}
                    onClose={this.closeMessageModal}
                    modalClass="message-modal"
                    titleText={this.state.messageContent.subject ? this.state.messageContent.subject : "message-modal"}
                    headerText={this.state.messageContent.subject ? this.state.messageContent.subject : " "}
                    error={false}
                    errorMessage={"this.state.exampleModalErrorMessage"}>
                    <div className="dta-modal__body pad-all" id="viewMessageModal">
                        <form id="secureMessage">
                            <div className="form-group flex">
                                <label htmlFor="from">{consumerLabels.consumerConst(selectedLanguage).from}</label>
                                {/* <h4 className='m-0'>{this.state.messageContent.clientNam}</h4> */}
                                <input type="text" id="from" name="from" style={{ opacity: "0.85" }} readOnly disabled value={fromEmailID} />
                            </div>

                            <div className="form-group flex" style={{ marginBottom: "7px" }}>
                                <label className="d-inblock" htmlFor="cc">{consumerLabels.consumerConst(selectedLanguage).to}</label>
                                <input type="text" id="to" name="to" style={{ opacity: "0.85" }} readOnly disabled value={to} />
                            </div>
                            {this.state.messageContent.msgDlCc &&  <div className="form-group flex" style={{ marginBottom: "7px" }}>
                                <label className="d-inblock" htmlFor="cc">Cc</label>
                                <input type="text" id="cc" name="cc" style={{ opacity: "0.85" }} readOnly disabled value={this.state.messageContent.msgDlCc} />
                            </div>}
                            <div className="form-group flex" style={{ marginBottom: "7px" }}>
                                <label className="d-inblock" htmlFor="cc">Cc</label>
                                {messageContent.msgDlCc ? <input
                                    className="d-inblock"
                                    type="text"
                                    id="cc"
                                    name="cc"
                                    value={messageContent.ccEmail}
                                    disabled
                                /> : <input
                                    className="d-inblock"
                                    type="text"
                                    id="cc"
                                    name="cc"
                                    style={{ opacity: "0.85" }} readOnly disabled value={messageContent.ccEmail}
                                    onChange={this.handleCcInputChange}
                                    
                                />}
                                
                        
                            </div>

                            <div className="messageContent viewContent" dangerouslySetInnerHTML={{ __html: this.state.messageContent.dsc }} />
                        </form>
                        <div className="dta-modal__footer print_btn">
                            <div className="float-right secure" style={{ paddingBottom: "10px" }}>
                                <button className="dta-button dta-button--outline-primary" onClick={() => window.print()}>{consumerLabels.consumerConst(selectedLanguage).print}</button>
                                {this.props.from !== consumerLabels.consumerConst(selectedLanguage).archives && <button onClick={()=> this.replyFromInbox(messageContent)} className="dta-button dta-button--primary replyFromInboxBtn">{consumerLabels.consumerConst(selectedLanguage).reply}</button>}
                            </div>
                        </div>
                    </div>
                </DTAModal>
            </div>
        );
    }
}


export default DynamicTable;
