//Consumer Info Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  benefitHistoryInfo: "BENEFIT_HISTORY_INFO",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  getIncmVerDocumentRequested: "INCOME_VERIFICATION_REQUESTED",
  getIncmVerDocumentRejected: "INCOME_VERIFICATION_REQUESTED",
  getIncmVerDocumentFulfilled: "INCOME_VERIFICATION_REQUESTED",
  filterBenefitHistoryRecordsAction: "FILTER_BENEFIT_RECORDS",
  getEbtCardFulfilled: "EBT_CARD_REQUESTED",
  getEbtCardRejected: "EBT_CARD_REQUEST_FAILED",
  getConsumerBenefitDetails: "GET_CONSUMER_BENEFIT_DETAILS",
  consumerBenefitDetailsSuccess: "GET_CONSUMER_BENEFIT_DETAILS_SUCCESS",
};

/**
 * here getting benefit details to showing in consumer myinfo pages
 */
// if service failure to getting data
function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

/**
 * here getting households beneficiary details
 * here getting benefit details to showing in consumer myinfo pages
 */
export function benefitHouseHoldNewDetailsInfo(
  agencyID,
  type,
  programID,
  accountID,
  aut
) {
  return (dispatch, getState) => {
    if (
      type != null &&
      type !== undefined &&
      agencyID !== null &&
      agencyID !== undefined
    ) {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      };
      var passingParams = "/benefitdetails/" + agencyID + "/" + type;
      axios
        .get(config.TigerContextURL + passingParams, TigerOptions)
        .then(function (houseHolds) {
          if (
            type != null &&
            type != undefined &&
            agencyID != null &&
            agencyID !== undefined
          ) {
            if (programID !== null) {
              var filterHistory = {
                agencyID: programID,
                startDate: null,
                endDate: null,
              };
              var historyURL = "/benefithistory";
              axios
                .post(
                  config.TigerContextURL + historyURL,
                  filterHistory,
                  TigerOptions
                )
                .then(function (benefitHistory) {
                  dispatch(
                    filterBenefitHistoryRecordsAction(
                      benefitHistory.data.benefitHistory
                    )
                  );
                })
                .catch(function (error) {
                  dispatch(serviceFailedAction());
                });
            }
            return dispatch(benefitHouseHoldInfoAction(houseHolds.data));
          }
        })
        .catch(function (error) {
          dispatch(serviceFailedAction());
        });
    }
  };
}
// sending/storing to redux store
function benefitHouseHoldInfoAction(houseHolds) {
  return {
    type: actionTypes.benefitHouseHoldAction,
    payload: houseHolds,
  };
}

export function requestIncomeVerificationAnction(agencyID) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    var payload = {
      agencyID: agencyID,
    };
    axios
      .post(config.TigerContextURL + "/proofofbenefits", payload, TigerOptions)
      .then(function (data) {
        dispatch(getIncmVerFulfilledAction(data));
      })
      .catch(function (error) {
        dispatch(getIncmVerRejectedAction({ Result: "False" }));
      });
  };
}

function getIncmVerRejectedAction(error) {
  return {
    type: actionTypes.getIncmVerDocumentRejected,
  };
}

function getIncmVerFulfilledAction(data) {
  return {
    type: actionTypes.getIncmVerDocumentFulfilled,
    payload: data,
  };
}

export function requestEbtCardAction(agencyID) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    var payload = {
      agencyID: agencyID,
    };
    axios
      .post(config.TigerContextURL + "/ebtrepalcement", payload, TigerOptions)
      .then(function (data) {
        dispatch(getEbtCardFulfilledAction(data));
      })
      .catch(function (error) {
        dispatch(getEbtCardRejectedAction({ Result: "False" }));
      });
  };
}

function getEbtCardRejectedAction(error) {
  return {
    type: actionTypes.getEbtCardRejected,
  };
}

function getEbtCardFulfilledAction(data) {
  return {
    type: actionTypes.getEbtCardFulfilled,
    payload: data,
  };
}

// sorting the records based date filter selections
export function filterBenefitHistoryRecords(
  clientId,
  startDate,
  endDate,
  userAuth
) {
  return (dispatch, getState) => {
    if (clientId !== null && clientId !== undefined) {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      var filterHistory = {
        agencyID: clientId,
        startDate: startDate,
        endDate: endDate,
      };
      var passingParams = "/benefithistory";
      axios
        .post(
          config.TigerContextURL + passingParams,
          filterHistory,
          TigerOptions
        )
        .then(function (benefitHistory) {
          return dispatch(
            filterBenefitHistoryRecordsAction(
              benefitHistory.data.benefitHistory
            )
          );
        })
        .catch(function (error) {
          dispatch(serviceFailedAction());
        });
    }
  };
}

function filterBenefitHistoryRecordsAction(data) {
  return {
    type: actionTypes.filterBenefitHistoryRecordsAction,
    payload: data,
  };
}

export function getConsumerBenefitDetails() {
  return {
    type: actionTypes.getConsumerBenefitDetails,
  };
}

export function consumerBenefitDetailsSuccess() {
  return {
    type: actionTypes.consumerBenefitDetailsSuccess,
  };
}
