import React, { Component } from "react";
import _ from "lodash";
import Collapse from "react-collapse";
import CSSTransition from "react-transition-group/CSSTransition";
import BeforeYouStart from "./components/BeforeYouStart";
import ApplySection from "./components/applyDsnapSection";
import { Link } from "react-router-dom";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import Icon from "../../utils/components/dtaIcon";
import * as Props from "../../utils/components/shared";
import * as TextProps from "../../utils/constants/text";
import {
  getNextSection,
  getPreviousSection,
}
  from "../../utils/helper/pageRoutingHelper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import { IncomeSectionTemplate } from "./applyDsnapMapData"
import * as OptionsProps from "../../utils/constants/options";
import {
  setDsnapSectionBasedApplicationType,
  applyDSanpQuestionsSectionsNew,
  updateCurrentSectionData,
  submitDsnapApply,
  dsnapAddressStorageAPI,
  addressValidation
} from "../../redux/applyDsnap/applyDsnapActions"
import {
  beforeYoustartDsnapMlanguageLabels,
  signAndSubmitDsnapMultilanguageLabels,
  resourcesDsnapMultilanguageLabels,
  expensesDsnapMultilanguageLabels,
  incomeSectionMultilanguageLabels,
  houseHoldMultilanguageLabels,
  incomeMultilanguageLabels,
  householdDsnapMultilanguageLabels,
  dymanicMultilanguageLabels,
  aboutMeDsnapMultilanguageLabels,
  MyhouseholdDsnapMultilanguageLabels
} from "./applyDsnapText"
import {
  filterData
} from "./applyDsnapHelper";
import {
  bindAnswers,
  toValidateInputErros,
  nameWithoutWhiteSpace,
  deleteHouseholdMems
} from "./applyDsnapHelper";
import { resourcesSectionTemplate, expensesSectionTemplate, emptyExpensesSectionTemplate, snapHouseholdTemplate,myHouseHoldTemplate } from "./applyDsnapMapData";
import SignAndSubmit from "./components/signAndSubmit";
import { animateScroll } from "react-scroll";
import { 
  addressValidationObj,
  formAddressDisplayValue,
  formatAddressDisplay,
  appendUpdatedAdrress,
  checkIfAddressIsUpdated,
  filterQuestionsBySelection } from "./applyDsnapHelper";
import { appendIncomeDetails, appendResourcesDetails, appendExpenseDetails, appendHouseHoldMembers, setHouseholdMembers, updateLastKnownLocationDsnap, countyList,appendRequiredFieldDnsap,languageChanged,appendIncomeSection } from "../../redux/applyDsnap/applyDsnapActions";
import { convertToDTOObj } from "./applyDsnapconverter";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import Checkbox from "../../utils/components/checkbox";
import Modal from "../../utils/components/modal/dtaModal";
import Radio from "../../utils/components/radio";
import { ALABAMA_COUNTY_OFFICES } from "../../utils/constants/constants";
import moment from "moment";


let language = localStorage.getItem("selectedLanguage");
if (language === null || language === "select") {
  language = "en-US";
}
var config = require("../../utils/constants/appConfigConstants");

const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
}

let applicationType = "";
let householdTemplate = {};
class ApplyDsnapContainer extends Component {

  constructor(props) {
    super(props);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.onPrimaryActionClickSaveContinue = this.onPrimaryActionClickSaveContinue.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onSecondaryActionClick = this.onSecondaryActionClick.bind(this);
    this.renderIncomeToAddNewMember =
      this.renderIncomeToAddNewMember.bind(this);
    this.incomefilterQuestionsBySelection =
      this.incomefilterQuestionsBySelection.bind(this);
    this.resourcesfilterQuestionsBySelection =
      this.resourcesfilterQuestionsBySelection.bind(this);
    this.expensesfilterQuestionsBySelection =
      this.expensesfilterQuestionsBySelection.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.onSignSubmitActionClick = this.onSignSubmitActionClick.bind(this);
    this.redirectToSignAndSubmit = this.redirectToSignAndSubmit.bind(this);
    this.onIncomeSubsectionRender = this.onIncomeSubsectionRender.bind(this);
    this.onResourcesSubsectionRender = this.onResourcesSubsectionRender.bind(this);
    this.resourcesSubsectionAdded = this.resourcesSubsectionAdded.bind(this);
    this.expensesSubsectionAdded = this.expensesSubsectionAdded.bind(this);
    this.onExpensesSubsectionRender = this.onExpensesSubsectionRender.bind(this);
    this.onHouseHoldSubsectionRender = this.onHouseHoldSubsectionRender.bind(this);
    this.updateHouseHoldDetails = this.updateHouseHoldDetails.bind(this);
    this.confirmAddressValidationAction = this.confirmAddressValidationAction.bind(this);
    // this.incomeSectionAdded = this.incomeSectionAdded.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.resourcesSummary = {};
    this.expensesSummary = {};
    this.incomeSummary = {};
    this.state = {
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      AnswerObject: [],
      isLoading: TextProps.VALUE_FALSE,
      previousSectionId: null,
      // REPEATABLE_QUESTION: [],
      freeCommunication: false,
      selectedLang: '',
      showDefaultSection: true,
      activeLang: {},
      sectionDataUpdated: TextProps.VALUE_FALSE,
      directionClass: "forward",
      prevIncomingQuestions: [],
      signature: "",
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      resourcesSummaryEdit: TextProps.VALUE_FALSE,
      expensesSummaryEdit: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: null,
      resourcesMemberTemplate: null,
      resourcesSummaryTemplate: null,
      expensesMemberTemplate: null,
      expensesSummaryTemplate: null,
      houseHoldSubMemberTemplate: null,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      showSuccessModal: TextProps.VALUE_FALSE,
      confirmedHouseholdMemForDeletion: [],
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      selectedHouseholdMemCount: 0,
      isDeleteValidCount: 0,
      showSuccessWarningModal: TextProps.VALUE_FALSE,
      registrationId: "",
      countyType: "",
      requiredField:TextProps.VALUE_FALSE,
      languageChangeWarning:TextProps.VALUE_FALSE,
      deleteWarningRedirectToHomePage: TextProps.VALUE_FALSE,
      deleteWarningShowModal: TextProps.VALUE_FALSE,
      enteredResAddressValue: "",
      selectedResAdressValue: "",
      enteredMailingAddressValue: "",
      selectedMailingAddressValue: "",
      enteredTempAddressValue:"",
      selectedTempAddressValue:"",
      addressObj: {},
      finalResValueSelected: "",
      finalMailingValueSelected: "",
      finalTempValueSelected: "",
      mailingAddressFlag: TextProps.VALUE_FALSE,
      resAddressFlag: TextProps.VALUE_FALSE,
      tempAddressFlag: TextProps.VALUE_FALSE,
      incomeMemberSelected: TextProps.VALUE_FALSE,
      dsnapId: '',
      isHomelessModalOpen: TextProps.VALUE_FALSE,
      setHomelessModalOpen: TextProps.VALUE_FALSE
    }

  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      if (prevState.activeSectionId === "sign-and-submit")
        this.removeSection(prevState.activeSectionId);
      animateScroll.scrollToTop({
        containerId: "content",
        duration: 500, // Should be roughly the same as CSS transition time
        smooth: TextProps.VALUE_TRUE,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.applyDsnap && nextProps.applyDsnap.islanguageChanged != undefined &&
      nextProps.applyDsnap.islanguageChanged === true
    ) {
        // if (this.state.sectionDataUpdated) {
        this.setState({
          languageChangeWarning: TextProps.VALUE_TRUE,
        });
      }
    this.setState(
      {
        sections: nextProps.applyDsnap.sections,
        sectionsCopy: nextProps.applyDsnap.sections,
      },
    )
    if (this.state.renderedSections.length < 1 && this.props.applyDsnap.sections.length) {
      this.setState({
        renderedSections: [
          this._getSection(this.props.applyDsnap.activeSectionId)
        ],
        activeSectionId: this.props.applyDsnap.activeSectionId,
      })
    }
    applicationType = sessionStorage.getItem("applicationType");
    if (applicationType === TextProps.MATCH_DSNAP) {
      householdTemplate = snapHouseholdTemplate; //applicationType = "DSNAP"
    }

    let registrationId = nextProps.applyDsnap && nextProps.applyDsnap.dsnapApplySuccess && nextProps.applyDsnap.dsnapApplySuccess.appId
    this.setState({
      registrationId: registrationId
    })

    //countyList for before you start section
    if (this.state.activeSectionId === "before-you-start") {
      const countyList = nextProps.applyDsnap && nextProps.applyDsnap.countyList && nextProps.applyDsnap.countyList.impactedCounties;
      if (countyList && countyList.length > 0) {
        const countyDesc = countyList.map(county => county.desc);
        const beforeYouStartSection = filterData(nextProps.applyDsnap.sections, "before-you-start");
        if (beforeYouStartSection) {
          const options = beforeYouStartSection.questions[0].options;
          if (options.length === 0 && Array.isArray(options)) {
            options.push(...countyDesc);
          }
          const question = beforeYouStartSection.questions[0];
          countyList.forEach(county => {
            if (county.desc === question.Answer) {
              question.countyCode = county.countyCode;
            }
          });
        }
      }
    }
    if(nextProps.applyDsnap.dsnapApplySuccess){
    this.showSucessWarningModal();
    }
    this.setState({
      dsnapId: nextProps.applyDsnap && nextProps.applyDsnap.countyList && nextProps.applyDsnap.countyList.dsnapId
    })
  }

  async componentDidMount() {
    this.updateDimensions();
    window.addEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
    this.props.setDsnapSectionBasedApplicationType(TextProps.MATCH_DSNAP);
    this.props.countyList(language);
  }

  updateDimensions() {
    let mainElems = document.getElementsByClassName("apply__content");
    let applyCont = document.getElementsByClassName("apply_free_comm");
    let appHeight = document.getElementsByClassName("app");
    let bodyTag = document.getElementsByTagName("body");
    let modalHeight = document.getElementsByClassName("dta-modal");

    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    var contentHeight = 0;
    var applyfreeCommHeight = 0;
    var modalPopupHeight = 0;

    if (bodyTag) {
      for (let elems of bodyTag) {
        if (elems.style) {
          elems.style.height = "auto";
        }
      }
    }

    if (isFreeComOpen === "false") {
      let applyCont = document.getElementsByClassName("apply_free_comm");
      let appHeight = document.getElementsByClassName("app");
      var applyfreeCommHeight = 0;

      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${applyfreeCommHeight}px`;
          }
        }
      }
    } else {
      if (mainElems) {
        for (let elems of mainElems) {
          contentHeight = elems.scrollHeight;
        }
      }
      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (modalHeight) {
        for (let elems of modalHeight) {
          modalPopupHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${contentHeight + applyfreeCommHeight + modalPopupHeight
              }px`;
          }
        }
      }
    }
  }

  onHouseHoldSubSectionClean = () => {
    if (filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer !== undefined) {
      let houseHoldSize = filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer;
      if (houseHoldSize > 0) {
        if (this.state.houseHoldSubSectionCleared === TextProps.VALUE_FALSE) {
          this.setState({
            houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
            houseHoldSubMemberTemplate: householdTemplate,
            // houseHoldSubDetailTemplate: householdDisabilityTemplate,
          });
        }
      }
    } else {
      if (this.state.houseHoldSubSectionCleared === TextProps.VALUE_FALSE) {
        this.setState({
          houseHoldSubSectionCleared: TextProps.VALUE_TRUE,
          houseHoldSubMemberTemplate: householdTemplate,
          // houseHoldSubDetailTemplate: householdDisabilityTemplate,
        });
      }
    }
  };

  onHouseHoldSubsectionRender = (member) => {
    let memberOptionsArray = [];
    let memberHeadObject = {};
    let headOfHouseholdQuestions = filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
    let headOfHouseholdName = headOfHouseholdQuestions[2].Answer + " " + headOfHouseholdQuestions[4].Answer;
    memberHeadObject[OptionsProps.OPTION_DISPLAY] = headOfHouseholdName;
    memberHeadObject.id = filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
    memberHeadObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
    memberOptionsArray.push(memberHeadObject);
    let houseHoldSubSection = this.state.houseHoldSubMemberTemplate
      ? this.state.houseHoldSubMemberTemplate
      : householdTemplate;
    let houseHoldSubDetailTemplate = this.state.houseHoldSubDetailTemplate
      ? this.state.houseHoldSubDetailTemplate
      : householdTemplate;


    /** ---end ---- */
    /** ---prePolpulate the data */
    if (
      filterData(this.props.applyDsnap.sections, "my-household").subsections &&
      filterData(this.props.applyDsnap.sections, "my-household").subsections.length.toString() ===
      filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer
    ) {
      let houseHoldMultipleTemplates = filterData(this.props.applyDsnap.sections, "my-household").subsections;
      this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);
    } else if (
      filterData(this.props.applyDsnap.sections, "my-household").subsections &&
      filterData(this.props.applyDsnap.sections, "my-household").subsections.length.toString() <
      filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer
    ) {
      let newHouseholdMems = [];
      let existingHouseholdMems = _.cloneDeep(
        filterData(this.props.applyDsnap.sections, "my-household").subsections
      );
      let newlyAddedMems = Number(
        filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer
      );
      newlyAddedMems--;
      let existingHouseholdMemsFiltered = [];
      existingHouseholdMems.map((exstMem) => {
        let firstName = exstMem.questions.filter(
          (qus) => qus.name === "firstName"
        )[0];
        let lastName = exstMem.questions.filter(
          (qus) => qus.name === "lastName"
        )[0];
        if (firstName.Answer && firstName.Answer) {
          existingHouseholdMemsFiltered.push(exstMem);

        }
      });
      let addNewMem = newlyAddedMems;
      if (existingHouseholdMems.length) {
        addNewMem = newlyAddedMems - existingHouseholdMems.length;
      }
      for (var j = 0; j < addNewMem; j++) {
        let k = existingHouseholdMems.length + (j + 1);
        houseHoldSubSection.id = "household-member-" + k;
        houseHoldSubSection.clientId = "DEP-" + k;
        houseHoldSubSection.title = houseHoldMultilanguageLabels(
          k,
          language
        ).title;
        houseHoldSubSection.header = houseHoldMultilanguageLabels(
          k,
          language
        ).header;
        houseHoldSubSection.subHeader = houseHoldMultilanguageLabels(
          k,
          language
        ).subHeader;
        let optionObj = {};
        optionObj[OptionsProps.OPTION_VALUE] = "DEP-" + k;
        optionObj[OptionsProps.OPTION_DISPLAY] = houseHoldSubSection.title;
        optionObj.isGrantee = TextProps.VALUE_N;
        memberOptionsArray.push(optionObj)
        this.props.setHouseholdMembers(memberOptionsArray);
        newHouseholdMems.push(_.cloneDeep(houseHoldSubSection));
      }
      let householdMems = [];
      existingHouseholdMemsFiltered.forEach((mem) => householdMems.push(mem));
      householdMems = [...existingHouseholdMems, ...newHouseholdMems];
      this.props.appendHouseHoldMembers(householdMems);
    } else {
      /** ---end of PrePopulation */
      member--;
      if (
        (this.state.oldHouseHoldSizeValue === 0 ||
          this.state.oldHouseHoldSizeValue > member) &&
        this.props.match.params.webAppId === undefined
      ) {
        let houseHoldMultipleTemplate = [];
        houseHoldSubDetailTemplate.questions[0].options = [];
        for (var i = 1; i <= member; i++) {
          houseHoldSubSection.id = "household-member-" + i;
          houseHoldSubSection.title = houseHoldMultilanguageLabels(
            i,
            language
          ).title;
          houseHoldSubSection.header = houseHoldMultilanguageLabels(
            i,
            language
          ).header;
          houseHoldSubSection.subHeader = houseHoldMultilanguageLabels(
            i,
            language
          ).subHeader;
          houseHoldSubSection.clientId = "DEP-" + i;
          houseHoldMultipleTemplate.push(_.cloneDeep(houseHoldSubSection));
          let optionObj = {};
          optionObj[OptionsProps.OPTION_VALUE] = "DEP-" + i;
          optionObj[OptionsProps.OPTION_DISPLAY] = houseHoldSubSection.title;
          optionObj.isGrantee = TextProps.VALUE_N;
          houseHoldSubDetailTemplate.questions[0].options.push(optionObj);
        }
        houseHoldMultipleTemplate.push(houseHoldSubDetailTemplate);

        this.setState({
          houseHoldMembersCount: member,
          oldHouseHoldSizeValue: member,
        });
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplate);
      } else if (

        this.state.oldHouseHoldSizeValue < member &&
        this.props.match.params.webAppId === undefined
      ) {
        let houseHoldMultipleTemplates =
          filterData(this.props.applyDsnap.sections, "my-household").subsections;
        let houseHoldDetailTemplate = houseHoldMultipleTemplates.splice(
          -1,
          1
        )[0];
        for (var j = this.state.oldHouseHoldSizeValue; j < member; j++) {
          let k = j + 1;
          houseHoldSubSection.id = "household-member-" + k;
          houseHoldSubSection.clientId = "DEP-" + k;
          houseHoldMultipleTemplates.push(_.cloneDeep(houseHoldSubSection));
        }
        houseHoldMultipleTemplates.push(houseHoldDetailTemplate);
        this.setState({
          houseHoldMembersCount: member,
          oldHouseHoldSizeValue: member,
        });
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);
      } else {
        let houseHoldMultipleTemplates =
          filterData(this.props.applyDsnap.sections, "my-household");
        this.props.appendHouseHoldMembers(houseHoldMultipleTemplates);

      }
    }
  };

  // Functions for setting state for if warning progress modal is open or not
  presentWarningProgressModal = (val) => {
    let isDeleteValidCount =
      filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer - val;
    this.setState({
      WarningProgressModalOpen: TextProps.VALUE_TRUE,
      selectedHouseholdMemCount: val,
      isDeleteValidCount: isDeleteValidCount,
    });
  };


  checkSectionHasAnswers = (sections, activeId) => {
    let isHasAnswerFlag = false;
    let getActiveID = sections.filter((item) => item.id === activeId)
    if (getActiveID[0].questions && getActiveID[0].questions[0].Answer && getActiveID[0].questions[0].Answer.length) {
      return false;
    }
    else if (getActiveID[0].questions && (getActiveID[0].questions[0].Answer === "" || (Array.isArray(getActiveID[0].questions[0].Answer) && getActiveID[0].questions[0].Answer.length === 0))) {
      return true;
    }
    return isHasAnswerFlag;
  }

  closeWarningProgressModal = () => {
    this.setState({ WarningProgressModalOpen: TextProps.VALUE_FALSE });
    let Updatedsections;
    if (
      this.state.confirmedHouseholdMemForDeletion.length &&
      filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer !==
      this.state.confirmedHouseholdMemForDeletion.length.toString()
    ) {
      // this.state.AnswerObject['income'] = {};
      if (this.incomeSummary.incomeMembers) {
        this.state.confirmedHouseholdMemForDeletion.map((deletedMem) => {
          this.incomeSummary.incomeMembers =
            this.incomeSummary.incomeMembers.filter(
              (deleteHousehold) =>
                nameWithoutWhiteSpace(deleteHousehold.firstName) !==
                nameWithoutWhiteSpace(deletedMem)
            );
        });
      }
      Updatedsections = deleteHouseholdMems(
        this.state.sections,
        this.state.confirmedHouseholdMemForDeletion
      );
      this.setDeletedHouseholdDetails(Updatedsections);
    } else if (!this.state.confirmedHouseholdMemForDeletion.length) {
      filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer =
        filterData(this.props.applyDsnap.sections, "my-household").subsections.length.toString();
      this.setDeletedHouseholdDetails(this.props.applyDsnap.sections);
      this.setState({ isLoading: TextProps.VALUE_TRUE });
    }
  };
  setDeletedHouseholdDetails = (Updatedsections) => {
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    this.setState(
      {
        sections: Updatedsections,
      },
      () => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
      }
    );
    let householdMem = filterData(Updatedsections, "my-household").subsections;
    let incomeMem = filterData(Updatedsections, "income").questions[0].options;
    let filteredGrantee = incomeMem.filter(item => item.optionValue === MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[0])
    let filteredNoIncome = incomeMem.filter(item => item.optionValue === MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[1])

    let filteredHouseholdMembers = [];
    for (let i = 0; i < householdMem.length; i++) {
      const member = householdMem[i];
      let optionObj = {};
      optionObj[OptionsProps.OPTION_VALUE] = member.clientId;
      optionObj[OptionsProps.OPTION_DISPLAY] = member.title;
      optionObj.isGrantee = TextProps.VALUE_N;
      filteredHouseholdMembers.push(optionObj);
    }
    let filterOptionValue = [...filteredGrantee, ...filteredHouseholdMembers, ...filteredNoIncome];
    this.props.setHouseholdMembers(filterOptionValue);
  };

  confirmDeleteOfHousehold = (event) => {
    this.setState({ confirmedHouseholdMemForDeletion: event });
  };
  ///******************************************// *//
  //Render DHR modal for address validation
  renderIcon = (completed) => {
    return completed === TextProps.VALUE_Y ? (
      <div className="verified-box">
        <div><Icon
        name="check-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Verified</div>
      </div>
    ) : (
      <div className="verified-box"><div><Icon
        name="cross-icon"
        className="icon-checkmark"
      /></div>
      <div className="verified-box-text">Not Verified</div>
      </div>
    );
  };
  renderAddressValidationModal = () => {
    return (
      <Modal
        isOpen={this.state.addressValidationModalOpen}
        onClose={this.cancelAddressValidationAction}
        modalClass="address-validation-modal"
        titleText={
          beforeYoustartDsnapMlanguageLabels(language).addressValidHeaderText
        }
        headerText={
          beforeYoustartDsnapMlanguageLabels(language).addressValidHeaderText
        }
      >
        <div className="dta-modal__body pad-all">
          {
            <div className="pure-g margin-bottom-half is-mail-address">
              {this.state.resAddressFlag && (
                <div className="pure-u-1">
                  <label className="dta-form__label">
                    {
                      beforeYoustartDsnapMlanguageLabels(language)
                        .addressValidLabelText
                    }
                  </label>
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label ">
                      {
                        beforeYoustartDsnapMlanguageLabels(language)
                          .enteredHomeAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedResAddress[0].verified)}
                    </label>
                    <Radio
                      name="head-of-household-info-changed-1"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredResAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue:
                            this.state.addressObj.enteredResAddress[0],
                          selectedResAdressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartDsnapMlanguageLabels(language)
                          .suggestedHomeAddressLabelText
                      }
                    </label>
                    <Radio
                      name="head-of-household-info-changed-2"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedResAdressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue: "",
                          selectedResAdressValue:
                            this.state.addressObj.suggestedResAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.mailingAddressFlag && (
                <div className="pure-u-1">
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label">
                      {
                        beforeYoustartDsnapMlanguageLabels(language)
                          .enteredMailingAddressLabelText
                      }
                      {this.renderIcon(this.state.addressObj.originalSuggestedMailingAddress[0].verified)}
                    </label>
                    <Radio
                      name="head-of-household-info-changed-3"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue:
                            this.state.addressObj.enteredMailingAddress[0],
                          selectedMailingAddressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartDsnapMlanguageLabels(language)
                          .suggestedMailingAddressLabelText
                      }
                    </label>
                    <Radio
                      name="head-of-household-info-changed-4"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue: "",
                          selectedMailingAddressValue:
                            this.state.addressObj.suggestedMailingAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.tempAddressFlag && (
                <div className="pure-u-1">
                <div className="address-row">
                  <label className="dta-form__label dta-form__label--size-small address-row-label">
                    {
                      beforeYoustartDsnapMlanguageLabels(language)
                        .enteredTempAddressLabelText
                    }
                    {this.renderIcon(this.state.addressObj.originalSuggestedTempAddress[0].verified)}
                  </label>
                  <Radio
                    name="head-of-household-info-changed-5"
                    className="dta-form__option-list address-row-radio"
                    optionClass="dta-form__option dta-form__option--width-1-of-2"
                    options={
                      this.state.addressObj
                        ? this.state.addressObj.enteredTempAddress
                        : []
                    }
                    required={TextProps.VALUE_TRUE}
                    value={this.state.enteredTempAddressValue}
                    onChange={(value) => {
                      this.setState({
                        enteredTempAddressValue:
                          this.state.addressObj.enteredTempAddress[0],
                        selectedTempAddressValue: "",
                      });
                      // this.setState({ selectedResAdressValue: "" });
                      this._handleAddressSelection(
                        "enteredTempAddress",
                        value
                      );
                    }}
                  />
                </div>
                <div className="address-row">
                  <label
                    className="dta-form__label dta-form__label--size-small address-row-label"
                    //htmlFor={this.nextUniqueId()}
                  >
                    {
                      beforeYoustartDsnapMlanguageLabels(language)
                        .suggestedTempAddressLabelText
                    }
                  </label>
                  <Radio
                    name="head-of-household-info-changed-6"
                    className="dta-form__option-list address-row-radio"
                    optionClass="dta-form__option dta-form__option--width-1-of-2"
                    options={
                      this.state.addressObj
                        ? this.state.addressObj.suggestedTempAddress
                        : []
                    }
                    required={TextProps.VALUE_TRUE}
                    value={this.state.selectedTempAddressValue}
                    onChange={(value) => {
                      this.setState({
                        enteredTempAddressValue: "",
                        selectedTempAddressValue:
                          this.state.addressObj.suggestedTempAddress[0],
                      });
                      this._handleAddressSelection(
                        "suggestedTempAddress",
                        value
                      );
                    }}
                  />
                </div>
              </div>
              )}
              <div className="dta-modal__footer dta-modal__footer--inline-buttons address-row-footer pure-u-1">
                <button
                  className="dta-button dta-button--large dta-button--primary address-row-footer-buttons address-row-footer-buttons-confirm"
                  onClick={this.confirmAddressValidationAction}
                >
                  {beforeYoustartDsnapMlanguageLabels(language).confirmButtonLabel}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--outline-primary address-row-footer-buttons address-row-footer-buttons-cancel"
                  onClick={this.cancelAddressValidationAction}
                >
                  {beforeYoustartDsnapMlanguageLabels(language).CancelButtonLabel}
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };
  //Function to validate address by calling Mellisa API
  validateAddressesCallingMellisa(answerAddress, address) {
    let status = TextProps.VALUE_TRUE;
    this.props
      .addressValidation(answerAddress, language)
      .then((data) => {
        if (data.data.length) {
          status = TextProps.VALUE_TRUE;
          let suggestedResAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let suggestedMailingAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          let suggestedTempAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_TEMP)
          );
          let enteredResAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let enteredMailingAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          let enteredTempAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_TEMP)
          );

          const address = filterData(this.props.applyDsnap.sections, "head-of-household-info");
          let resAddrUpdated = TextProps.VALUE_FALSE;
          if (
            filterData(this.props.applyDsnap.sections, "head-of-household-info").residentailAddressStored &&
            enteredResAddress.length
          ) {
            let resAdress = address.questions.filter((addr) => addr.name === "address")[0];
            if (resAdress.Answer) {
            resAddrUpdated = checkIfAddressIsUpdated(
              resAdress.Answer,
              filterData(this.props.applyDsnap.sections, "head-of-household-info").residentailAddressStored
            );
            }
          } else if (enteredResAddress.length) {
            resAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            resAddrUpdated = TextProps.VALUE_FALSE;
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (
            filterData(this.props.applyDsnap.sections, "head-of-household-info").mailingAddressStored &&
            enteredMailingAddress.length
          ) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                mailingaddr.Answer,
                filterData(this.props.applyDsnap.sections, "head-of-household-info").mailingAddressStored
              );
            }
          } else if (enteredMailingAddress.length) {
            mailingAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            mailingAddrUpdated = TextProps.VALUE_FALSE;
          }
          let tempAddrUpdated = TextProps.VALUE_FALSE;
          if (
            filterData(this.props.applyDsnap.sections, "head-of-household-info").temporaryAddressStored &&
            enteredResAddress.length
          ) {
            let tempAdress = address.questions.filter((addr) => addr.name === "temporary")[0];
            if (tempAdress.Answer) {
            tempAddrUpdated = checkIfAddressIsUpdated(
              tempAdress.Answer,
              filterData(this.props.applyDsnap.sections, "head-of-household-info").temporaryAddressStored
            );
            if(tempAddrUpdated && answerAddress.filter(ans => ans.type === TextProps.VALUE_TEMP).length){
              tempAddrUpdated = TextProps.VALUE_TRUE;
            }else{
              tempAddrUpdated = TextProps.VALUE_FALSE;
            }
            }
          } else if (enteredTempAddress.length) {
            tempAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            tempAddrUpdated = TextProps.VALUE_FALSE;
          }
          this.setState(
            {
              addressObj: {
                originalEnteredResAddress: enteredResAddress,
                originalSuggestedResAddress: suggestedResAddress,
                originalEnteredMailingAddress: enteredMailingAddress,
                originalSuggestedMailingAddress: suggestedMailingAddress,
                originalEnteredTempAddress: enteredTempAddress,
                originalSuggestedTempAddress: suggestedTempAddress,
                enteredResAddress: formatAddressDisplay(enteredResAddress),
                suggestedResAddress: formatAddressDisplay(suggestedResAddress),
                enteredMailingAddress: formatAddressDisplay(
                  enteredMailingAddress
                ),
                suggestedMailingAddress: formatAddressDisplay(
                  suggestedMailingAddress
                ),
                enteredTempAddress: formatAddressDisplay(enteredTempAddress),
                suggestedTempAddress: formatAddressDisplay(suggestedTempAddress),
              },
              enteredResAddressValue: enteredResAddress.length
                ? enteredResAddress[0].formattedAddress
                : "",
              enteredMailingAddressValue: enteredMailingAddress.length
                ? enteredMailingAddress[0].formattedAddress
                : "",
              enteredTempAddressValue: enteredTempAddress.length
                ? enteredTempAddress[0].formattedAddress
                : "",
              selectedResAdressValue: "",
              selectedMailingAddressValue: "",
              selectedTempAddressValue: "",
              finalResValueSelected: enteredResAddress.length
                ? enteredResAddress[0]
                : "",
              finalMailingValueSelected: enteredMailingAddress.length
                ? enteredMailingAddress[0]
                : "",
              finalTempValueSelected: enteredTempAddress.length
                ? enteredTempAddress[0]
                : "",
              mailingAddressFlag: mailingAddrUpdated,
              resAddressFlag: resAddrUpdated,
              tempAddressFlag: tempAddrUpdated,
              MelissaApiResponded: true,
            },
            () => {
              this.setState({
                addressValidationModalOpen: TextProps.VALUE_TRUE,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          MelissaApiResponded: TextProps.VALUE_FALSE,
        });
        status = TextProps.VALUE_FALSE;
      });
    return status;
  }
  //Function to handle address validation popup actions(close/cancel)
  cancelAddressValidationAction = () => {
    this.confirmAddressValidationAction();
    this.setState({ addressValidationModalOpen: false });
  };
  _findTheObj = (array, find) => {
    return array.filter((value) => value.name === find)[0];
  };
  //Function to update addresses.
  updateStoreAddr() {
    const subsectionData = filterData(this.props.applyDsnap.sections, "head-of-household-info");
    let storeAddrObj = [];
    let residentialAddr = this._findTheObj(subsectionData.questions, "address");
    let mailingAddr = this._findTheObj(
      subsectionData.questions,
      "is-also-mailing"
    );
    let temporaryAddr = this._findTheObj(subsectionData.questions, "temporary");
    storeAddrObj.push(residentialAddr.Answer ? residentialAddr.Answer : {});
    storeAddrObj.push(
      mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        ? mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        : {}
    );
    storeAddrObj.push(temporaryAddr.Answer ? temporaryAddr.Answer : {});
    return storeAddrObj;
  }
  //Function to handle confirmAddressValidationAction popup actions(confirm)
  confirmAddressValidationAction = () => {
    let selectedResAddress = this.state.finalResValueSelected
      ? this.state.finalResValueSelected
      : this.state.addressObj.originalEnteredResAddress[0];
    let selectedMailingAddress = this.state.finalMailingValueSelected
      ? this.state.finalMailingValueSelected
      : this.state.addressObj.originalEnteredMailingAddress[0];
    let selectedTempAddress = this.state.finalTempValueSelected
      ? this.state.finalTempValueSelected
      : this.state.addressObj.originalEnteredTempAddress[0];
    filterData(this.props.applyDsnap.sections, "head-of-household-info").questions =
      appendUpdatedAdrress(
        filterData(this.props.applyDsnap.sections, "head-of-household-info").questions,
        selectedResAddress,
        selectedMailingAddress,
        selectedTempAddress
      );
    filterData(this.props.applyDsnap.sections, "head-of-household-info").melisaAddressValidated =
      TextProps.VALUE_TRUE;
    const renderedSectionsCopy = [];
    const incomingSection = this._getSection(this.state.activeSectionId);
    renderedSectionsCopy.push(incomingSection);
    this.setState({
      addressValidationModalOpen: TextProps.VALUE_FALSE,
    });
    let AnswerObjectCopy = this.state.AnswerObject;
    const address = filterData(this.props.applyDsnap.sections, "head-of-household-info");
    if (AnswerObjectCopy && AnswerObjectCopy["head-of-household-info"] && AnswerObjectCopy["head-of-household-info"].address) {
      AnswerObjectCopy["head-of-household-info"].address = address.questions.filter(
        (addr) => addr.name === "address"
      )[0].Answer;
    }
    if (AnswerObjectCopy && AnswerObjectCopy["head-of-household-info"].mailingAddress) {
      AnswerObjectCopy["head-of-household-info"].mailingAddress =
        address.questions.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0].conditionals[0].conditionalQuestions[0].Answer;
    }
    this.setState({ AnswerObject: AnswerObjectCopy });
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    setTimeout(() => {
      this.setState({ renderedSections: renderedSectionsCopy });
      let storeAddrObj = this.updateStoreAddr();
      this.props.dsnapAddressStorageAPI(storeAddrObj);
      this.props.updateCurrentSectionData(this.props.applyDsnap);
      this.setState({ isLoading: TextProps.VALUE_FALSE });
      // this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
    }, 100);
  };
  //Function to handle address selection
  _handleAddressSelection = (option, value) => {
    let selectedResAddress = {};
    let selectedMailingAddress = {};
    let selectedTempAddress = {};
    if (option === "suggestedResAddress") {
      selectedResAddress = this.state.addressObj.originalSuggestedResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "enteredResAddress") {
      selectedResAddress = this.state.addressObj.originalEnteredResAddress[0];
      this.setState({
        finalResValueSelected: selectedResAddress,
      });
    } else if (option === "suggestedMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalSuggestedMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    } else if (option === "enteredMailingAddress") {
      selectedMailingAddress =
        this.state.addressObj.originalEnteredMailingAddress[0];
      this.setState({
        finalMailingValueSelected: selectedMailingAddress,
      });
    } else if (option === "enteredTempAddress") {
      selectedTempAddress = this.state.addressObj.originalEnteredTempAddress[0];
      this.setState({
        finalTempValueSelected: selectedTempAddress,
      });
    } else if (option === "suggestedTempAddress") {
      selectedTempAddress = this.state.addressObj.originalSuggestedTempAddress[0];
      this.setState({
        finalTempValueSelected: selectedTempAddress,
      });
    }
  };
  // Render a DHR modal for the save progress dialog
  renderDataDeleteWarningModal = () => {
    let memberOptions = [];
    filterData(this.props.applyDsnap.sections, "my-household").subsections &&
      filterData(this.props.applyDsnap.sections, "my-household").subsections.forEach((household) => {
        if (household.id !== "household-details")
          memberOptions.push(household.title);
      });
    return (
      <Modal
        isOpen={this.state.WarningProgressModalOpen}
        onClose={this.closeWarningProgressModal}
        modalClass="delete-household-modal"
        titleText="Save progress"
        headerText={signAndSubmitDsnapMultilanguageLabels(language).headerText}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {signAndSubmitDsnapMultilanguageLabels(language).changingLabel}
          </p>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={memberOptions}
            ref="isAcceptTerms"
            onChange={this.confirmDeleteOfHousehold}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <p> </p>
          <button
            className="dta-button dta-button--primary dta-button--block"
            disabled={
              this.state.isDeleteValidCount !==
              this.state.confirmedHouseholdMemForDeletion.length
            }
            onClick={() => this.closeWarningProgressModal()}
          >
            {signAndSubmitDsnapMultilanguageLabels(language).householdDeleteLabel}
          </button>
        </div>
      </Modal>
    );
  };


  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText = beforeYoustartDsnapMlanguageLabels(language).getStartedLabel;
        return returnText;
      case "sign-and-submit":
        returnText = beforeYoustartDsnapMlanguageLabels(language).submitButtonLabel;
        return returnText;
      default:
        returnText = beforeYoustartDsnapMlanguageLabels(language).continueLabel;
        return returnText;
    }
  }

  renderBeforeYouStart = () => {
    let component = null;
    component = <BeforeYouStart onCountyChange={this.handleCountyChange} />;
    return component;
  };
  handleCountyChange = (selectedValue) => {
    this.setState({
      countyType: selectedValue
    })
  }
  //Edit Button in Income Summary
  incomeSummaryEditClick = (section) => {
    this.setState({ incomeSummaryEdit: TextProps.VALUE_TRUE });
    this.onSectionChange(section);
  };
  renderIncomeSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p className="apply__heading apply__body-header">
          {incomeSectionMultilanguageLabels(language).incomeOf}
          {summary.firstName}
        </p>
        {/*
            The edit button should route the user to the appropriate apply section
            to edit income for this household member.
            Later, when the user clicks continue on that section, they are returned to the
            "Income Summary" section again.
          */}
        <button
          className="dta-button dta-bordered-button income-edit-btn"
          onClick={() => this.incomeSummaryEditClick(summary.section)}
        >
          {incomeSectionMultilanguageLabels(language).editButtonLabel}
        </button>
        <ul>
          {summary.incomes.map((incomeItem) => {
            if (incomeItem.Answer) {
              return incomeItem.Answer.map((answer) => {
                if(answer.length == undefined && answer.code !== "REMOVE") {
                let unitLabel;
                switch (answer.unit) {
                  case "Month":
                    unitLabel = incomeSectionMultilanguageLabels(language).monthlyAmount;
                    break;
                  case "Week":
                    unitLabel = incomeSectionMultilanguageLabels(language).weeklyAmount;
                    break;
                  case "Every two weeks":
                    unitLabel = incomeSectionMultilanguageLabels(language).everyTwoWeeks;
                    break;
                  default:
                    unitLabel = answer.unit;
                }
                return (
                  <li key={answer.id}>
                    <li>{incomeItem.type}</li>
                    <li style={{ display: "flex", flexDirection: "row" }}><li className="pure-u-1">{unitLabel} </li><li className="pure-u-1" style={{ marginLeft: "80px" }}>${answer.amount}{" "}</li>
                    </li>
                  </li>
                );
                }
              });
            }
          })}
        </ul>
      </div>
    );
  };

  toggleAddIncomeMembers = () => {
    this.setState({ addIncomeMembersOpen: !this.state.addIncomeMembersOpen });
  };

  prepareIncomeSummary = () => {
    let incomeSection = filterData(this.props.applyDsnap.sections, "income");
    // let houseMemberList = this.state.houseMemberLabels && this.state.houseMemberLabels.length ? this.state.houseMemberLabels : incomeSection.memberOptions;
    let houseMemberList = filterData(this.props.applyDsnap.sections, "income").questions[0].options;
    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      // currentIncomeMembers[memberIncomeIndex] = incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].clientId;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];

      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    if (incomeSection.subsections.length > 1) {
      for (var j = 0; j < houseMemberList.length; j++) {
        let present = false;
        currentIncomeMembers.map((cur) => {
          if (cur === houseMemberList[j].optionValue) {
            present = !present;
          }
        });
        if (!present) {
          let nonMember = {};
          nonMember.id = nonMemberCount;
          nonMember.clientId = houseMemberList[j].optionValue;
          nonMember.firstName = houseMemberList[j].optionDisplay;
          this.incomeSummary.nonIncomeMembers.push(nonMember);
          nonMemberCount++;
        }
      }
    }
  };
  renderIncomeSummary = () => {
    return (
      <div className="pad-all--double">
        {/* <p className="text--small">
            {incomeSectionMultilanguageLabels(language).lookIncorrectLabel}
          </p>
          <hr className="divider" style={{ borderColor: "#828282" }} /> */}
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.incomeSummary.incomeMembers.map((summary) => {
          if (summary.incomeType && summary.incomeType.length > 0) {
            return this.renderIncomeSummaryItem(summary);
            
          }else{
            return null;
          }
        }
        )}
        <button
          className={`dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon add-new-button ${this.state.addIncomeMembersOpen ? "is-active" : ""
            }`}
          onClick={this.toggleAddIncomeMembers}
          aria-expanded={this.state.addIncomeMembersOpen}
        >
          {/* should the icon be hidden from screen readers altogether when expand the data*/}
          <Icon
            ariaHidden
            name={this.state.addIncomeMembersOpen ? "minus" : "plus"}
          />
          {incomeSectionMultilanguageLabels(language).anotherHouseholdIncomeLabel}
        </button>
        <Collapse isOpened={this.state.addIncomeMembersOpen}>
          <span className="add-income-summary__label"></span>
          <ul className="list--unstyled add-income-summary__list">
            {this.incomeSummary.nonIncomeMembers.map((member) => {
              if (member.clientId != incomeSectionMultilanguageLabels(language).questionClearOptionTwo) {
                return (
                  <li key={member.id}>
                    {/*
                        Clicking the following button routes the user to a
                        new child Income section to fill out income info for this
                        household member (e.g. "Listing John's Income"). When user
                        is finished and clicks 'Continue', they will be returned to Income Summary page
                      */}
                    <span className="sr-only">Add income for </span>
                    <button
                      className="full-width dta-button text--small interim-recert-outline-button"
                      onClick={() => {
                        this.renderIncomeToAddNewMember(member.firstName);
                      }}
                    >
                      <b>{member.firstName}</b>
                    </button>
                  </li>
                );
              }
            })}
          </ul>
        </Collapse>
      </div>
    );
  };
  prepareResourcesSummary = () => {
    let resourcesSection = filterData(this.props.applyDsnap.sections, "resources-landing");
    this.resourcesSummary.resourcesMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < resourcesSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.resourcesSummary.resourcesMembers[memberIncomeIndex] = {};
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].section =
        resourcesSection.subsections[memberIncomeIndex];
      this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources = [];
      if (
        this.resourcesSummary.resourcesMembers[memberIncomeIndex] !== undefined
      ) {
        this.resourcesSummary.resourcesMembers[memberIncomeIndex].resources =
          resourcesSection.subsections[0].questions[0];
      }
    }
  };

  resourcesSubsectionAdded = () => {
    let resourcesCopy = [];
    resourcesCopy = _.cloneDeep(resourcesSectionTemplate);
    this.props.appendResourcesDetails(resourcesCopy);
    this.resourcesSummary.resourcesMembers = [];
  }

  expensesSubsectionAdded = () => {
    let expensesCopy = [];
    expensesCopy = _.cloneDeep(expensesSectionTemplate);
    this.props.appendExpenseDetails(expensesCopy);
    this.expensesSummary.expensesMembers = [];
  }

  //Edit Button in Resources Summary
  resourcesSummaryEditClick = (section) => {
    this.setState({ resourcesSummaryEdit: TextProps.VALUE_TRUE });
    this.onSectionChange(section);
  };
  getResourceItemFrequencyLabel = (resources) => {
    switch (resources) {
        case "Cash on Hand":
            return resourcesDsnapMultilanguageLabels(language).combineResourcesoptions[0]
        case "Saving Accounts":
            return resourcesDsnapMultilanguageLabels(language).combineResourcesoptions[1]
        case "Checking Accounts":
            return resourcesDsnapMultilanguageLabels(language).combineResourcesoptions[2]       
        default:
            return resources
    }
}

  renderResourcesSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p className="apply__heading apply__body-header">
          {resourcesDsnapMultilanguageLabels(language).title}
        </p>
        <button
          className="dta-button dta-bordered-button income-edit-btn"
          onClick={() => this.resourcesSummaryEditClick(summary.section)}
        >
          {resourcesDsnapMultilanguageLabels(language).editButtonLabel}
        </button>
        <ul>
          {summary.section.questions.map((resourceItem) => (
            <div className="pure-g" key={resourceItem.id}>
              <div className="pure-u-1-1">
                <div className="margin-bottom-half">{this.getResourceItemFrequencyLabel(resourceItem.name)}<br /></div>
                <div className="pure-u-2-3 margin-bottom-half">{resourcesDsnapMultilanguageLabels(language).amountLabel}</div>
                <div className="pure-u-1-3 pad-left-half margin-bottom-half">${resourceItem.Answer}</div>
              </div>
            </div>
          ))}

        </ul>
      </div>
    );
  };

  renderResourcesSummary = () => {
    return (
      <div className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.resourcesSummary.resourcesMembers.map((summary) =>
          this.renderResourcesSummaryItem(summary)
        )}

      </div>
    );
  };
  prepareExpenseSummary = () => {
    let expensesSection = filterData(this.props.applyDsnap.sections, "expense-landing-page");
    this.expensesSummary.expensesMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < expensesSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.expensesSummary.expensesMembers[memberIncomeIndex] = {};
      this.expensesSummary.expensesMembers[memberIncomeIndex].section =
        expensesSection.subsections[memberIncomeIndex];
      this.expensesSummary.expensesMembers[memberIncomeIndex].expenses = [];

      if (
        this.expensesSummary.expensesMembers[memberIncomeIndex] !== undefined
      ) {
        this.expensesSummary.expensesMembers[memberIncomeIndex].expenses =
          expensesSection.subsections[1].questions;

      }
    }
  };



  expensesSummaryEditClick = (section) => {
    this.setState({ expensesSummaryEdit: TextProps.VALUE_TRUE });
    this.onSectionChange(section);
  };
 

  getExpensesItemFrequencyLabel = (expenses) => {
    switch (expenses) {
      case "Expenses to protect property during disaster":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[0]
      case "Expense to repair or replace items for home or self -employement property":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[1]
      case "Dependent care due to disaster":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[2]
      case "Food destoryed in disaster":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[3]
      case "Funeral/medical expenses due to disaster":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[4]
      case "Moving and storage expenses due to disaster":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[5]
      case "Other disaster-related expenses":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[6]
      case "Temporary shelter expenses":
        return expensesDsnapMultilanguageLabels(language).combineExpensesoptions[7]
      default:
        return expenses
    }
  }

  expensesResourcesSummaryItem = (summary) => {
    return (
      <div>
        {
          (summary.section.questions[0].name !== "types") &&
          <div className="dta-content-box apply__summary-item" key={summary.id}>
            <p className="apply__heading apply__body-header">
              {expensesDsnapMultilanguageLabels(language).title}
            </p>
            <button
              className="dta-button dta-bordered-button income-edit-btn"
              onClick={() => this.expensesSummaryEditClick(summary.section)}
            >
              {expensesDsnapMultilanguageLabels(language).editButtonLabel}
            </button>
            <ul>
              {summary.section.questions.map((expensesItem) => (
                <div className="pure-g" key={expensesItem.id}>
                  <div className="pure-u-1-1">
                    <div className="margin-bottom-half">{this.getExpensesItemFrequencyLabel(expensesItem.name)}<br /></div>
                    <div className="pure-u-2-3 margin-bottom-half">{expensesDsnapMultilanguageLabels(language).amountLabel}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half">${expensesItem.Answer}</div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        }
      </div>

    );
  };

  renderExpensesSummary = () => {
    return (
      <div className="pad-all--double">
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.expensesSummary.expensesMembers.map((summary) =>
          this.expensesResourcesSummaryItem(summary)
        )}

      </div>
    );
  };





  renderSignAndSubmit = () => {
    return (
      <SignAndSubmit
        submitFormId={this.state.submitFormId}
        signatureError={this.state.signatureError}
        signatureErrorMsg={this.signatureErrorMsg}
        updateSignature={this.updateSignature}
        signature={this.state.signature}
      />
    );
  };
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
        return this.renderBeforeYouStart();
      case "head-of-household-info":
        return this.renderaboutmeSummary();
      case "household-situation":
        return this.renderhouseholdsituationSummary();
      case "income-summary":
        if (
          !this.incomeSummary ||
          !this.incomeSummary.length
        ) {
          this.prepareIncomeSummary();
        }
        return this.renderIncomeSummary();
      case "combined-income":
        return this.renderIncomeSubsection();
      case "combined-income-summary":
        return this.renderIncomeSummarySubsection();
      case "resources-summary":
        if (

          !this.resourcesSummary.resourcesMembers ||
          !this.resourcesSummary.resourcesMembers.length
        ) {
          this.prepareResourcesSummary();
        }
        return this.renderResourcesSummary();
      case "expenses-summary":
        if (
          !this.expensesSummary.expensesMembers ||
          !this.expensesSummary.expensesMembers.length
        ) {
          this.prepareExpenseSummary();
        }
        return this.renderExpensesSummary();
      case "sign-and-submit":
        return this.renderSignAndSubmit();
      case "member":
        return this.renderhouseholdMember();

      default:
        return <div>Something went wrong</div>;
    }
  };
  onContinueClick = (event) => {
    event.preventDefault();
    // sessionStorage.setItem("saveExitShowModalEnabled", true);
    if (this.state.sectionDataUpdated) {
      this.onPrimaryActionClick(event, TextProps.CONTINUE);
    } else {
      this.props
        .getSavedDataForUser(TextProps.VALUE_APP_EXIT)
        .then((data) => {
          this.setState({
            saveExitShowModal: TextProps.VALUE_TRUE,
            createdDateTime: data.date,
            updateDateTime: data.updatedDt,
            serverTimeZone: data.timeZone,
            pendingHours: data.pendingHours,
            appId: data.id,
            signature: "",
            agreeError: TextProps.VALUE_FALSE,
            signatureError: TextProps.VALUE_FALSE,
          });
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        })
        .catch((error) => {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
        });
      this.exitFromTheApplication();
    }
  };
  onSignSubmitActionClick = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event, TextProps.SIGNSUBMIT);
  };
  redirectToSignAndSubmit = (section, event = null) => {
    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);

    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          lastKnownSection: previousSectionId,
          activeSectionId: "sign-and-submit",
        });
      }
    );
    this.props.updateLastKnownLocationDsnap(previousSectionId);
  };

  onSectionChange = (section, event = null) => {
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);
    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
          prevIncomingQuestions: _.cloneDeep(incomingSection),
        });
      }
    );
  };

  householdMemUpdated = (memIndex) => {
    let houseHoldSection = filterData(this.props.applyDsnap.sections, "my-household");
    let houseHoldMemberIndex = houseHoldSection.subsections.findIndex(
      (sub) => sub.id === memIndex
    );
    houseHoldSection.subsections[houseHoldSection.subsections.length - 1].questions[0].options.map((opt) => {

      if (
        houseHoldSection.subsections[houseHoldMemberIndex].clientId ===
        opt.optionValue
      )
        opt.optionDisplay =
          houseHoldSection.subsections[houseHoldMemberIndex].title;

    });

  };
  updateHouseHoldDetails = () => {
    var houseHoldMembers = filterData(this.props.applyDsnap.sections, "my-household").subsections;
    let aboutMeSectionQuestions = filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
    let aboutMeName =
      aboutMeSectionQuestions[2].Answer +
      " " +
      aboutMeSectionQuestions[4].Answer;

    let houseHoldMemberOptions = [];
    let beforeStartPage = filterData(this.props.applyDsnap.sections, "before-you-start");
    houseHoldMembers.questions[0].options = [];
    let aboutMeOption = {};
    aboutMeOption[OptionsProps.OPTION_VALUE] = beforeStartPage.webClientId;
    aboutMeOption[OptionsProps.OPTION_DISPLAY] = aboutMeName;
    aboutMeOption.isGrantee = TextProps.VALUE_Y;
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
      aboutMeOption
    );
    for (
      var memberIndex = 0;
      memberIndex < houseHoldMembers.length - 1;
      memberIndex++
    ) {
      // changed if member first name not entered , taking count of index namespace title
      var memName = houseHoldMembers[memberIndex].questions[0].Answer;
      if (memName !== "" && memName !== undefined) {
        memName =
          houseHoldMembers[memberIndex].questions[0].Answer +
          " " +
          houseHoldMembers[memberIndex].questions[2].Answer;
        // binding DOB
        let memDob = houseHoldMembers[memberIndex].questions[5].Answer;
        if (memDob !== "" && memDob !== undefined) {
          memName =
            memName +
            " " +
            this._convertDateToState(
              houseHoldMembers[memberIndex].questions[5].Answer
            );
        }
      } else {
        memName = houseHoldMembers[memberIndex].title;
      }
      houseHoldMemberOptions[memberIndex] = {};
      houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_VALUE] =
        "DEP-" + [memberIndex + 1];
      houseHoldMemberOptions[memberIndex][OptionsProps.OPTION_DISPLAY] =
        memName;
      houseHoldMemberOptions[memberIndex].isGrantee = TextProps.VALUE_N;
      houseHoldMembers[houseHoldMembers.length - 1].questions[0].options.push(
        houseHoldMemberOptions[memberIndex]
      );
    }
    this.props.appendHouseHoldMembers(houseHoldMembers);
  };

  _dynamicSectionIdMatch = (activeId) => {
    switch (activeId) {
      case "my-household":
        return TextProps.VALUE_TRUE;
      case "income":
        return TextProps.VALUE_TRUE;
      default:
        return TextProps.VALUE_FALSE;
    }
  };
  onIncomeSubsectionRender = (dataSections) => {
    if (filterData(this.props.applyDsnap.sections, "income")) {
    }
    let existingIncome = _.cloneDeep(filterData(this.props.applyDsnap.sections, "income").subsections);
    let existingInconeDeatils = existingIncome.splice(-1)[0];
    const answer = dataSections.questions[0].Answer;
    const options = dataSections.questions[0].options;
    const subsections = dataSections.subsections;
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate =
      this.state.incomeMemberTemplate &&
        this.state.incomeMemberTemplate.subsections
        ? this.state.incomeMemberTemplate
        : IncomeSectionTemplate;
    var incomeSummaryTemplate = this.state.incomeSummaryTemplate
      ? this.state.incomeSummaryTemplate
      : filterData(this.props.applyDsnap.sections, "income").subsections[
      filterData(this.props.applyDsnap.sections, "income").subsections.length - 1
      ];
    var incomeCopy = filterData(this.props.applyDsnap.sections, "income");
    let questionClearOption = incomeMultilanguageLabels(
      "",
      language
    ).questionClearOption;
    if (answer[0] === "" || answer[0] === questionClearOption) {
      // nothing to retrun
    } else if (incomeMeTemplate.length === undefined) {
      for (var i = 0; i < answer.length; i++) {
        let memExists = existingIncome.filter(
          (income) => income.clientId === answer[i]
        );
        if (!memExists.length) {
          let MemberName = "";
          for (
            var optionIndex = 0;
            optionIndex < incomeCopy.questions[0].options.length;
            optionIndex++
          ) {
            if (
              incomeCopy.questions[0].options[optionIndex].optionValue ===
              answer[i]
            ) {
              MemberName =
                incomeCopy.questions[0].options[optionIndex].optionDisplay;
            }
          }
          incomeMeTemplate.id = "combined-income-" + i;
          incomeMeTemplate.header = incomeMultilanguageLabels(
            MemberName,
            language
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] =
            incomeMultilanguageLabels(MemberName, language).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "combined-income-subsection-" + i;
          incomeMeTemplate.subsections[0].header = incomeMultilanguageLabels(
            MemberName,
            language
          ).heading;
          incomeMeTemplate.subsections[0].title = MemberName;
          incomeMeTemplate.title = MemberName;
          incomeMeTemplate.clientId = answer[i];
          let incomeReturn = incomeMultilanguageLabels(MemberName, language);
          if (applicationType === TextProps.MATCH_TANF) {
            this.incomeRenderSectionLabelMapping(
              1,
              incomeMeTemplate,
              incomeReturn
            );
          } else {
            this.incomeRenderSectionLabelMapping(
              0,
              incomeMeTemplate,
              incomeReturn
            );
          }
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        }
        // else {
        //   incomeMemberMultipleTemplate.push(_.cloneDeep(memExists[0]));
        // }
      }
    }
    if (existingIncome.length && Array.isArray(answer)) {
      existingIncome.forEach((income) => {
        let incomeFilled = answer.filter((ans) => ans === income.clientId);
        if (incomeFilled.length) {
          incomeMemberMultipleTemplate.push(income);
        }
      });
    }
    if (this.props.match.params.webAppId !== undefined) {
      incomeCopy.subsections = [];
      incomeCopy.subsections = incomeMeTemplate;
    } else {
      if (answer[0] !== questionClearOption && answer.length > 0) {
        incomeSummaryTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
        incomeCopy.subsections[0].hiddenFromNav = TextProps.VALUE_TRUE;
        incomeSummaryTemplate.active = TextProps.VALUE_TRUE;
        incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
      }
      incomeCopy.subsections = [];
      incomeCopy.subsections = incomeMemberMultipleTemplate;
    }
    const optionsObj = options.find((item) => item.optionValue == answer);
    if (
      optionsObj &&
      subsections[0] &&
      subsections[0].title == optionsObj.optionDisplay &&
      incomeCopy.subsections[0]
    ) {
      incomeCopy.subsections[0].questions[0].Answer =
        subsections[0].questions[0].Answer;
      incomeCopy.subsections[0].questions[0].targetValue =
        subsections[0].questions[0].targetValue;
      // incomeCopy.subsections[0].subsections[0].questions = subsections[0].subsections[0].questions
      incomeCopy.subsections[0].hiddenFromNav = false;

    }
    this.props.appendIncomeDetails(incomeCopy);
  };


  incomeRenderSectionLabelMapping(value, incomeMeTemplate, incomeReturn) {
    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
        incomeReturn.subQuestions[j][Props.LABEL];
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions[j + value][Props.LABEL];
    }
    for (
      let j = 0;
      j < incomeMeTemplate.subsections[0].originalQuestions.length;
      j++
    ) {
      incomeMeTemplate.subsections[0].originalQuestions[j][Props.LABEL] =
        incomeReturn.subQuestions[j][Props.LABEL];
      incomeMeTemplate.subsections[0].originalQuestions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions[j + value][Props.LABEL];
    }
  }

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    }
    else {
      section = this.props.applyDsnap.sections;
    }
    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }
  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: event }, () => {
      let userName = "";
      if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[2].Answer !== "") {
        userName =
          userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[2].Answer + " ";
      }
      if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[3].Answer !== "") {
        userName =
          userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[3].Answer + " ";
      }
      if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[4].Answer !== "") {
        userName = userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[4].Answer;
      }
    });
  };
  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
  incomefilterQuestionsBySelection = (memIndex) => {
    let incomeSection = filterData(this.props.applyDsnap.sections, "income");
    let memberIndex = incomeSection.subsections.findIndex(
      (sub) => sub.id === memIndex
    );
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    let oldQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    // if (this.incomeSubSections[memberIndex] === undefined) {
    //   this.incomeSubSections[memberIndex] = _.cloneDeep(incomeSection.subsections[memberIndex]);
    // } else {
    if (
      incomeSection.subsections[memberIndex] &&
      incomeSection.subsections[memberIndex].subsections &&
      incomeSection.subsections[memberIndex].subsections[0]
    ) {
      const allQuestions =
        incomeSection.subsections[memberIndex].subsections[0].originalQuestions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item.name == answerItem.name
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      incomeSection.subsections[memberIndex].subsections[0].questions =
        questions;
    }
    // // incomeSection.subsections[memberIndex] = _.cloneDeep(this.incomeSubSections[memberIndex]);
    // incomeSection.subsections[memberIndex].questions[0].Answer = incomePageAnswers;
    // incomeSection.subsections[memberIndex].questions[0].targetValue = incomePageAnswers;
    // if (oldQuestions.length) {
    //   incomeSection.subsections[memberIndex].subsections[0].questions.forEach((item) => {
    //     const existing = oldQuestions.find((oldItem) => item.name == oldItem.name)
    //     if (existing) {
    //       item.Answer = existing.Answer
    //       item.targetValue = existing.targetValue
    //     }
    //   })
    // }
    // }
    // filterData(this.props.apply.sections, "income").currentMember = this.incomeSubSections[memberIndex].title;
    filterData(this.props.applyDsnap.sections, "income").currentMember =
      incomeSection.subsections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    let noneTargetValue = incomeMultilanguageLabels(
      "",
      language
    ).noneTargetValue;
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !== noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection &&
        incomeSection.subsections[memberIndex] &&
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (
        let filterQuestionIndex = 0;
        filterQuestionIndex < incomeFilteredQuestions.length;
        filterQuestionIndex++
      ) {
        if (oldQuestions !== undefined) {
          for (
            let oldQuestionIndex = 0;
            oldQuestionIndex < oldQuestions.length;
            oldQuestionIndex++
          ) {
            if (
              oldQuestions[oldQuestionIndex].valueToMatch ===
              incomeFilteredQuestions[filterQuestionIndex].valueToMatch &&
              oldQuestions[oldQuestionIndex].name ===
              incomeFilteredQuestions[filterQuestionIndex].name
            ) {
              incomeFilteredQuestions[filterQuestionIndex].Answer =
                oldQuestions[oldQuestionIndex].Answer;
              incomeFilteredQuestions[filterQuestionIndex].targetValue =
                oldQuestions[oldQuestionIndex].targetValue;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendIncomeDetails(incomeSection);
    } else {
      // delete incomeSection.subsections[memberIndex].subsections;
      this.props.appendIncomeDetails(incomeSection);
    }
  }

  resourcesfilterQuestionsBySelection = () => {
    let resourcesSection = filterData(this.props.applyDsnap.sections, "resources-landing");
    let resourcesPageAnswers = filterData(this.props.applyDsnap.sections, "resources-landing") && filterData(this.props.applyDsnap.sections, "resources-landing").questions[0] && filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer;
    let resourcesFilteredQuestions = [];
    let resoucestemplatecopy = resourcesSectionTemplate;
    let oldQuestions = [];
    if (resourcesSection.subsections && resourcesSection.subsections[0].questions) {
      oldQuestions = resourcesSection.subsections[0].questions;
    }
    if (resourcesSection.subsections && resourcesSection.subsections[0].questions) {
      const allQuestions = resourcesSection.subsections[0].questions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item[Props.NAME] === answerItem[Props.NAME]
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      resourcesSection.subsections[0].questions = questions;
    }

    if (
      resourcesPageAnswers[0] !== "" &&
      resourcesPageAnswers[0] !== undefined
    ) {
      let copyresourcesBaseQuestions = resoucestemplatecopy.subsections[0].questions;
      let old = _.uniqBy([...resourcesSection, ...copyresourcesBaseQuestions], (q) => q.name);
      resourcesFilteredQuestions = old.filter(question => {
        return resourcesPageAnswers.some(answer => answer === question.name);
      });


      // Iterate through the filtered questions.
      for (
        let filterQuestionIndex = 0;
        filterQuestionIndex < resourcesFilteredQuestions.length;
        filterQuestionIndex++
      ) {
        if (oldQuestions !== undefined) {
          for (
            let oldQuestionIndex = 0;
            oldQuestionIndex < oldQuestions.length;
            oldQuestionIndex++
          ) {
            if (
              oldQuestions[oldQuestionIndex][Props.NAME] ===
              resourcesFilteredQuestions[filterQuestionIndex][Props.NAME]
            ) {
              resourcesFilteredQuestions[filterQuestionIndex].Answer =
                oldQuestions[oldQuestionIndex].Answer;
              resourcesFilteredQuestions[filterQuestionIndex].targetValue =
                oldQuestions[oldQuestionIndex].targetValue;
            }
          }
        }
      }
      resourcesSection.subsections[0].questions =
        _.cloneDeep(resourcesFilteredQuestions);
      // Assuming this.props.appendResourcesDetails is defined
      this.props.appendResourcesDetails(resourcesSection);
    } else {
      // delete  resourcesSection.subsections[0].questions;
      // Assuming this.props.appendResourcesDetails is defined
      this.props.appendResourcesDetails(resourcesSection);
    }
  };

  expensesfilterQuestionsBySelection = () => {
    let expensePageAnswers = [];
    expensePageAnswers = filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0] && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0] && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer;
    // Initialize an array to store the answers
    let expenseSection = [];
    expenseSection = filterData(this.props.applyDsnap.sections, "expense-landing-page");
    let expensetemplatecopy = expensesSectionTemplate;
    let expensesFilteredQuestions = [];
    let oldQuestions = [];
    if (filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1].questions) {
      oldQuestions = filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1].questions;
    }
    if (filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1] && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1].questions) {
      const allQuestions = filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions;
      const questions = [];
      allQuestions.forEach((item) => {
        const filledAnswer = oldQuestions.find(
          (answerItem) => item[Props.NAME] === answerItem[Props.NAME]
        );
        if (filledAnswer) {
          questions.push(filledAnswer);
        } else {
          questions.push(item);
        }
      });
      filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1].questions = questions;
    }

    if (
      expensePageAnswers[0] !== "" &&
      expensePageAnswers[0] !== undefined
    ) {
      let copyresourcesBaseQuestions = expensetemplatecopy.subsections[1].questions;
      let old = _.uniqBy([...expenseSection, ...copyresourcesBaseQuestions], (q) => q.name);

      expensesFilteredQuestions = old.filter(question => {
        return expensePageAnswers.some(answer => answer === question.name);
      });


      // Iterate through the filtered questions.
      for (
        let filterQuestionIndex = 0;
        filterQuestionIndex < expensesFilteredQuestions.length;
        filterQuestionIndex++
      ) {
        if (oldQuestions !== undefined) {
          for (
            let oldQuestionIndex = 0;
            oldQuestionIndex < oldQuestions.length;
            oldQuestionIndex++
          ) {
            if (
              oldQuestions[oldQuestionIndex][Props.NAME] ===
              expensesFilteredQuestions[filterQuestionIndex][Props.NAME]
            ) {
              expensesFilteredQuestions[filterQuestionIndex].Answer =
                oldQuestions[oldQuestionIndex].Answer;
              expensesFilteredQuestions[filterQuestionIndex].targetValue =
                oldQuestions[oldQuestionIndex].targetValue;
            }
          }
        }
      }
      filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[1].questions =
        _.cloneDeep(expensesFilteredQuestions);
      // Assuming this.props.appendResourcesDetails is defined
      this.props.appendExpenseDetails(expenseSection);
    } else {
      // delete  expenseSection.subsections[0].questions;
      // Assuming this.props.appendResourcesDetails is defined
      this.props.appendExpenseDetails(expenseSection);
    }

  }

  onResourcesSubsectionRender = (dataSections) => {
    let existingResources = _.cloneDeep(filterData(this.props.applyDsnap.sections, "resources-landing"));
    const answer = dataSections.questions[0].Answer && dataSections.questions[0].Answer.filter(opt => opt !== resourcesDsnapMultilanguageLabels
      (language).noResourcesToReportOption);
    const options = dataSections.questions[0].options.filter(opt => opt.optionValue !== resourcesDsnapMultilanguageLabels
      (language).noResourcesToReportOption);
    const subsections = dataSections.subsections;
    let resourcesMemberMultipleTemplate = [];
    let resourcesMeTemplate =
      this.state.resourcesMemberTemplate &&
        this.state.resourcesMemberTemplate.subsections
        ? this.state.resourcesMemberTemplate
        : resourcesSectionTemplate;
    let resourcesSummaryTemplate = this.state.resourcesSummaryTemplate
      ? this.state.resourcesSummaryTemplate
      : filterData(this.props.applyDsnap.sections, "resources-landing").subsections[
      filterData(this.props.applyDsnap.sections, "resources-landing").subsections.length - 1
      ];
    let resourcesCopy = filterData(this.props.applyDsnap.sections, "resources-landing");
    let questionClearOption1 = resourcesDsnapMultilanguageLabels
      (
        language,
        null
      ).noResourcesToReportOption;
    let questionClearOption2 = resourcesDsnapMultilanguageLabels
      (
        language,
        null
      ).questionClearOption;
    if (answer[0] === "" || answer[0] === questionClearOption1 || answer[0] === questionClearOption2) {
      // nothing to retrun
    } else if (resourcesMeTemplate.length === undefined) {
      for (let i = 0; i < answer.length; i++) {
        let memExists = existingResources.filter(
          (income) => income.clientId === answer[i]
        );
        if (!memExists.length) {
          let MemberName = "";
          for (
            let optionIndex = 0;
            optionIndex < resourcesCopy.questions[0].options.length;
            optionIndex++
          ) {
            if (
              resourcesCopy.questions[0].options[optionIndex].optionValue ===
              answer[i]
            ) {
              MemberName =
                resourcesCopy.questions[0].options[optionIndex].optionDisplay;
            }
          }
          resourcesMeTemplate.id = "combined-resources-" + i;
          resourcesMeTemplate.header = resourcesDsnapMultilanguageLabels
            (
              language,
              MemberName
            ).heading;
          resourcesMeTemplate.questions[0][Props.LABEL] = resourcesDsnapMultilanguageLabels
            (language).aboutResourceBefore
          resourcesMeTemplate.subsections[0].id =
            "combined-resources-subsection-" + i;
          resourcesMeTemplate.subsections[0].header = resourcesDsnapMultilanguageLabels
            (
              language,
              MemberName
            ).heading;
          resourcesMeTemplate.subsections[0].title = MemberName;
          resourcesMeTemplate.title = MemberName;
          resourcesMeTemplate.clientId = answer[i];
          let resourcesReturn = resourcesDsnapMultilanguageLabels
            (language, MemberName);
          if (applicationType === TextProps.MATCH_TANF) {
            this.resourcesRenderSectionLabelMapping(
              1,
              resourcesMeTemplate,
              resourcesReturn
            );
          } else {
            this.resourcesRenderSectionLabelMapping(
              0,
              resourcesMeTemplate,
              resourcesReturn
            );
          }
          resourcesMemberMultipleTemplate.push(_.cloneDeep(resourcesMeTemplate));
        }
        else {
          resourcesMemberMultipleTemplate.push(_.cloneDeep(memExists[0]));
        }
      }
    }
    if (this.props.match.params.webAppId !== undefined) {
      resourcesCopy.subsections = [];
      resourcesCopy.subsections = resourcesMeTemplate;
    } else {
      if (answer[0] !== questionClearOption1 && answer[0] !== questionClearOption2 && answer.length > 0 && resourcesSummaryTemplate) {
        resourcesSummaryTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
        resourcesSummaryTemplate.active = TextProps.VALUE_TRUE;
        resourcesMemberMultipleTemplate.push(resourcesSummaryTemplate);
      }
      resourcesCopy.subsections = [];
      resourcesCopy.subsections = resourcesMemberMultipleTemplate;
    }
    const optionsObj = options.find((item) => item.optionValue == answer);
    if (
      optionsObj &&
      subsections[0] &&
      subsections[0].title == optionsObj.optionDisplay &&
      resourcesCopy.subsections[0]
    ) {
      resourcesCopy.subsections[0].questions[0].Answer =
        subsections[0].questions[0].Answer;
      resourcesCopy.subsections[0].questions[0].targetValue =
        subsections[0].questions[0].targetValue;
      // incomeCopy.subsections[0].subsections[0].questions = subsections[0].subsections[0].questions
    }
    this.props.appendResourcesDetails(resourcesCopy);
  };

  onExpensesSubsectionRender = (dataSections) => {
    let newexpenseCopy = _.cloneDeep(emptyExpensesSectionTemplate);
    let existingExpenses = _.cloneDeep(filterData(this.props.applyDsnap.sections, "expense-landing-page"));
    const answer = dataSections.questions[0].Answer && dataSections.questions[0].Answer.filter(opt => opt !== resourcesDsnapMultilanguageLabels
      (language).noResourcesToReportOption);
    const options = dataSections.questions[0].options.filter(opt => opt.optionValue !== resourcesDsnapMultilanguageLabels
      (language).noResourcesToReportOption);
    const subsections = dataSections.subsections;
    let expensesMemberTemplate = [];
    let expensesMeTemplate =
      this.state.resourcesMemberTemplate &&
        this.state.resourcesMemberTemplate.subsections
        ? this.state.resourcesMemberTemplate
        : expensesSectionTemplate;
    let expensesSummaryTemplate = this.state.expensesSummaryTemplate
      ? this.state.expensesSummaryTemplate
      : filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[
      filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections.length - 1
      ];
    let expensesCopy = filterData(this.props.applyDsnap.sections, "expense-landing-page");
    let questionClearOption1 = resourcesDsnapMultilanguageLabels
      (
        language,
        null
      ).noResourcesToReportOption;
    let questionClearOption2 = resourcesDsnapMultilanguageLabels
      (
        language,
        null
      ).questionClearOption;
    if (answer[0] === "" || answer[0] === questionClearOption1 || answer[0] === questionClearOption2) {
      // nothing to retrun
    } else if (expensesMeTemplate.length === undefined) {
      for (let i = 0; i < answer.length; i++) {
        let memExists = existingExpenses.filter(
          (income) => income.clientId === answer[i]
        );
        if (!memExists.length) {
          let MemberName = "";
          for (
            let optionIndex = 0;
            optionIndex < expensesCopy.questions[0].options.length;
            optionIndex++
          ) {
            if (
              expensesCopy.questions[0].options[optionIndex].optionValue ===
              answer[i]
            ) {
              MemberName =
                expensesCopy.questions[0].options[optionIndex].optionDisplay;
            }
          }
          expensesMeTemplate.id = "combined-resources-" + i;
          expensesMeTemplate.header = resourcesDsnapMultilanguageLabels
            (
              language,
              MemberName
            ).heading;
          expensesMeTemplate.questions[0][Props.LABEL] = resourcesDsnapMultilanguageLabels
            (language).aboutResourceBefore
          expensesMeTemplate.subsections[0].id =
            "combined-resources-subsection-" + i;
          expensesMeTemplate.subsections[0].header = resourcesDsnapMultilanguageLabels
            (
              language,
              MemberName
            ).heading;
          expensesMeTemplate.subsections[0].title = MemberName;
          expensesMeTemplate.title = MemberName;
          expensesMeTemplate.clientId = answer[i];
          let resourcesReturn = resourcesDsnapMultilanguageLabels
            (language, MemberName);
          if (applicationType === TextProps.MATCH_TANF) {
            this.resourcesRenderSectionLabelMapping(
              1,
              expensesMeTemplate,
              resourcesReturn
            );
          } else {
            this.resourcesRenderSectionLabelMapping(
              0,
              expensesMeTemplate,
              resourcesReturn
            );
          }
          expensesMemberTemplate.push(_.cloneDeep(expensesMeTemplate));
        }
        else {
          expensesMemberTemplate.push(_.cloneDeep(memExists[0]));
        }
      }
    }
    if (this.props.match.params.webAppId !== undefined) {
      expensesCopy.subsections = [];
      expensesCopy.subsections = newexpenseCopy.subsections;
    } else {
      if (answer[0] !== questionClearOption1 && answer[0] !== questionClearOption2 && answer.length > 0 && expensesSummaryTemplate) {
        expensesSummaryTemplate.hiddenFromNav = TextProps.VALUE_FALSE;
        expensesSummaryTemplate.active = TextProps.VALUE_TRUE;
        expensesMemberTemplate.push(expensesSummaryTemplate);
      }
      expensesCopy.subsections = newexpenseCopy.subsections;
    }
    const optionsObj = options.find((item) => item.optionValue == answer);
    if (
      optionsObj &&
      subsections[0] &&
      subsections[0].title == optionsObj.optionDisplay &&
      expensesCopy.subsections[0]
    ) {
      expensesCopy.subsections[0].questions[0].Answer =
        subsections[0].questions[0].Answer;
      expensesCopy.subsections[0].questions[0].targetValue =
        subsections[0].questions[0].targetValue;
      // incomeCopy.subsections[0].subsections[0].questions = subsections[0].subsections[0].questions
    }

    this.props.appendExpenseDetails(expensesCopy);
  };

  updateHouseholdInfo = () => {
    let memberOptionsArray = [];
    let memberHeadObject = {};
    let headOfHouseholdQuestions = filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
    let headOfHouseholdName = headOfHouseholdQuestions[1].Answer + " " + headOfHouseholdQuestions[3].Answer;
    memberHeadObject[OptionsProps.OPTION_DISPLAY] = headOfHouseholdName;
    memberHeadObject.id = filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
    memberHeadObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
    memberOptionsArray.push(memberHeadObject);
    let optionObj = {};
    let noIncomeReportOption = incomeSectionMultilanguageLabels(language).questionClearOptionTwo;
    optionObj[OptionsProps.OPTION_DISPLAY] = noIncomeReportOption;
    optionObj[OptionsProps.OPTION_VALUE] = noIncomeReportOption;
    memberOptionsArray.push(optionObj);
    this.props.setHouseholdMembers(memberOptionsArray);
  }

  updateDynamicLabel = () => {
    let dsnapDetailObj = this.props.applyDsnap && this.props.applyDsnap.countyList;
    let benefitStartDate;
    let benefitEndDate;
    let formattedDate;
    let disasterDate;

    var startDateObj = dsnapDetailObj.benefitStartDate;
    var endDateObj = dsnapDetailObj.benefitEndDate;
    var disasterDateObj = dsnapDetailObj.disasterDate;

    benefitStartDate = moment(startDateObj,"YYYY-MM-DD").format('MMMM DD,YYYY');
    benefitEndDate = moment(endDateObj,"YYYY-MM-DD").format('MMMM DD,YYYY');
    disasterDate = moment(disasterDateObj,"YYYY-MM-DD").format('MMMM DD,YYYY');
    formattedDate = moment(startDateObj,"YYYY-MM-DD").format('MMMM YYYY');

    let dynamicLabel = dymanicMultilanguageLabels(benefitStartDate, benefitEndDate, formattedDate, disasterDate, language);
        let houseHoldSitutation = filterData(this.props.applyDsnap.sections, "household-situation").questions;
        const questionIndex = houseHoldSitutation.findIndex(q => q.name === "food-benefits");
        if (questionIndex !== -1) {
          houseHoldSitutation[questionIndex].label = dynamicLabel.foodBenefitsLabel;
        }
        const questionIndexDisaster = houseHoldSitutation.findIndex(q => q.name === "due-to-Disaster");
        if (questionIndexDisaster !== -1) {
          houseHoldSitutation[questionIndexDisaster].label = dynamicLabel.dueToDisasterLabel;
        }

        let resources = filterData(this.props.applyDsnap.sections, "resources-landing").questions[0];
        resources.label = dynamicLabel.subHeaderExpense;
        let resourceSectionTemplate = resourcesSectionTemplate.questions[0];
        resourceSectionTemplate.label = dynamicLabel.subHeaderExpense;

        let expenses = filterData(this.props.applyDsnap.sections, "expense-landing-page").questions[0];
        expenses.inlineHelp = dynamicLabel.descriptionLabel;

    
  }

  onPrimaryActionClick = (event, actionButtonType) => {
    if (event) {
      event.preventDefault(); // allow react to take care of routing instead of browser
    }
    let activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    let dalReference = this.props.applyDsnap.dalReference;
    //Logic to bind answers to sections JSON
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      let _this = this;
      _this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );
      let isValid = toValidateInputErros(dataSections, activeId);
      // store address in the store for validating if the address as changed
      if (activeId === "head-of-household-info" && isValid) {
        if (config.doAddressValidate) {
          const address = dataSections;
          let resAddrUpdated = TextProps.VALUE_FALSE;
          let isHomeless = address.questions.filter(
            (addr) => addr.name === "is-homeless"
          )[0];
          if (isHomeless && isHomeless.Answer === TextProps.VALUE_YES) {
            resAddrUpdated = TextProps.VALUE_FALSE;
          } else if (filterData(this.props.applyDsnap.sections, "head-of-household-info").residentailAddressStored) {
            let resAdress = address.questions.filter((addr) => addr.name === "address")[0];
            if (resAdress.Answer) {
            resAddrUpdated = checkIfAddressIsUpdated(
              resAdress.Answer,
              filterData(this.props.applyDsnap.sections, "head-of-household-info").residentailAddressStored
            );
            }
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (filterData(this.props.applyDsnap.sections, "head-of-household-info").mailingAddressStored) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                mailingaddr.Answer,
                filterData(this.props.applyDsnap.sections, "head-of-household-info").mailingAddressStored
              );
            }
          }
          let tempAddrUpdated = TextProps.VALUE_FALSE;
          if (filterData(this.props.applyDsnap.sections, "head-of-household-info").temporaryAddressStored) {
            let tempAdress = address.questions.filter((addr) => addr.name === "temporary")[0];
            if (tempAdress.Answer) {
            tempAddrUpdated = checkIfAddressIsUpdated(
              tempAdress.Answer,
              filterData(this.props.applyDsnap.sections, "head-of-household-info").temporaryAddressStored
            );
            }
          }
          if (
            addressValidationObj(address.questions).length && (!filterData(this.props.applyDsnap.sections, "head-of-household-info").melisaAddressValidated ||
            resAddrUpdated ||
            mailingAddrUpdated ||
            tempAddrUpdated)
          ) {
            let melissaResponse = this.validateAddressesCallingMellisa(
              addressValidationObj(address.questions),
              address
            );
            if (melissaResponse) {
              return;
            }
          } else {
            filterData(this.props.applyDsnap.sections, "before-you-start").signAndSubmit = TextProps.VALUE_TRUE;
          }
        } else {
          filterData(this.props.applyDsnap.sections, "before-you-start").signAndSubmit = TextProps.VALUE_TRUE;
        }
      } else if (activeId === "household-details") {
        //Populate household members from user answers
        let houseHolds = filterData(this.props.applyDsnap.sections, "my-household").subsections;
        let houseHoldMembersCopy = [];
        let head = {
          personId: 0,
          firstName: null,
          lastName: null,
        };
        let aboutMe = filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
        {
          head.firstName = aboutMe[2].Answer;
          head.lastName = aboutMe[4].Answer;
        }
        head.personId = filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;

        houseHoldMembersCopy.push(head);
        if (houseHolds !== undefined) {
          for (let member = 0; member < houseHolds.length - 1; member++) {
            let dependentInfo = houseHolds[member].questions;
            let hhMember = {
              personId: 0,


              firstName: null,
              lastName: null,
            };
            hhMember.personId = houseHolds[member].clientId;
            hhMember.disabilityFlag = TextProps.VALUE_FALSE;
            if (
              dependentInfo[5].Answer !== "" &&
              dependentInfo[5].Answer !== undefined &&
              dependentInfo[5].Answer !== null
            ) {

              hhMember.dateOfBirth = new Date(dependentInfo[5].Answer);
            }
            hhMember.firstName = dependentInfo[0].Answer;
            hhMember.lastName = dependentInfo[2].Answer;
            houseHoldMembersCopy.push(hhMember);
          }
        }
        let houseHoldsDisabilities = filterData(this.props.applyDsnap.sections, "my-household").subsections;
        if (houseHoldsDisabilities !== undefined) {
          let houseHoldDetails =
            houseHoldsDisabilities[houseHoldsDisabilities.length - 1].questions;
          // seeting disability to grantee
          if (houseHoldDetails !== undefined) {
            if (houseHoldDetails[0].options[0].optionValue === MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[0]) {
              if (
                houseHoldDetails[0].Answer !== undefined &&
                houseHoldDetails[0].Answer !== ""
              ) {
                if (
                  houseHoldDetails[0].Answer[0] ===
                  houseHoldMembersCopy[0].personId
                ) {
                  houseHoldMembersCopy[0].disabilityFlag = TextProps.VALUE_TRUE;
                }
              }
            }
            if (
              houseHoldMembersCopy.length > 0 &&
              houseHoldDetails[0].Answer !== undefined
            ) {
              for (
                let applicantDep = 0;
                applicantDep < houseHoldDetails[0].Answer.length;
                applicantDep++
              ) {
                for (
                  let houseHold = 0;
                  houseHold < houseHoldMembersCopy.length;
                  houseHold++
                ) {
                  if (houseHoldMembersCopy[houseHold].personId == MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[0])
                    continue;
                  if (
                    houseHoldDetails[0].Answer[applicantDep] ===
                    houseHoldMembersCopy[houseHold].personId
                  ) {
                    houseHoldMembersCopy[houseHold].disabilityFlag =
                      TextProps.VALUE_TRUE;
                  }
                }
              }
            }
          }
        }

      }
      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }

        this.props.applyDSanpQuestionsSectionsNew(this.props.applyDsnap.sections);
        return;
      }
      else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          // Rendering Dynamic Income Template for Members
          case "my-household":
            if (actionButtonType === TextProps.SIGNSUBMIT) {
              this.redirectToSignAndSubmit();
              return;
            }
            else if(this.state.requiredField){
              let myhouseHold = _.cloneDeep(myHouseHoldTemplate.questions);
              this.props.appendRequiredFieldDnsap(myhouseHold)
              return;
            }

            else if (dataSections.questions[0].Answer === "" || dataSections.questions[0].Answer === "1") {
              this.updateHouseholdInfo();
              this._renderNextSection("my-household");
              return;
            }
        
            else (dataSections.questions[0].Answer !== "")
            {

              this.onHouseHoldSubsectionRender(dataSections.questions[0].Answer);
            }

            break;
          case "income":
            this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE });
            this.setState({ incomeMemberSelected: TextProps.VALUE_TRUE })
            if (actionButtonType === TextProps.SIGNSUBMIT) {
              this.redirectToSignAndSubmit();
              return;
            }
            else if (
              (filterData(this.props.applyDsnap.sections, "income").questions[0] &&
                filterData(this.props.applyDsnap.sections, "income").questions[0].Answer === "") ||
              (filterData(this.props.applyDsnap.sections ,"income").questions[0].Answer.includes(MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[1]) ||
               filterData( this.props.applyDsnap.sections ,"income").questions[0].Answer.includes(MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[2]))||
                (
                  Array.isArray(filterData(this.props.applyDsnap.sections, "income").questions[0].Answer) &&
                  filterData(this.props.applyDsnap.sections, "income").questions[0].Answer.length === 0
                )
            ) {
              let incomeCompletedValue = filterData(this.props.applyDsnap.sections, "income");
              incomeCompletedValue.subsections = incomeCompletedValue.subsections.map(subsection => ({
                ...subsection,
                hiddenFromNav: true
            }));
              incomeCompletedValue.completed = true;
              this._renderNextSection("income-summary");
              return;
            }
            else {
              let incomeCompletedValue = filterData(this.props.applyDsnap.sections, "income");
              incomeCompletedValue.subsections = incomeCompletedValue.subsections.map(subsection => ({
                ...subsection,
                hiddenFromNav: false
            }));
              this.onIncomeSubsectionRender(dataSections);
            }
            break;
          case "resources-landing":
            if (actionButtonType === TextProps.SIGNSUBMIT) {
              this.redirectToSignAndSubmit();
              return;
            }
            if (
              filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer === "" ||
              filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer.length === 0) {
              this.setState({ resourcesSummaryEdit: TextProps.VALUE_FALSE });
              this.onResourcesSubsectionRender(dataSections);
            }
            break;
          case "expenses-combined":
            if (actionButtonType === TextProps.SIGNSUBMIT) {
              this.redirectToSignAndSubmit();
              return;
            }
            if (
              filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer === "" ||
              filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer.length === 0
            ) {
              this.setState({ expensesSummaryEdit: TextProps.VALUE_FALSE });
              this.onExpensesSubsectionRender(dataSections);
            }
            break;
          default:
            break;
        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        // let incomeSections = filterData(this.props.applyDsnap.sections, "income").subsections;
        // console.log(incomeSections ,"incomeSections");
        switch (activeIdSubString) {
          case "household-member":
            if (this.state.incomeMemberSelected) {
              let householdMem = filterData(this.props.applyDsnap.sections, "my-household").subsections;
              let filteredHouseholdMembers = [];
              for (let i = 0; i < householdMem.length; i++) {
                const member = householdMem[i];
                let optionObj = {};
                optionObj[OptionsProps.OPTION_VALUE] = member.clientId;
                optionObj[OptionsProps.OPTION_DISPLAY] = member.title;
                optionObj.isGrantee = TextProps.VALUE_N;
                filteredHouseholdMembers.push(optionObj);
              }
              let incomeFilter = [];
              let incomeSection = filterData(this.props.applyDsnap.sections, "income").subsections;
              for (let i = 0; i < incomeSection.length; i++) {
                for (let j = 0; j < filteredHouseholdMembers.length; j++) {
                  if (filteredHouseholdMembers[j].optionValue === incomeSection[i].clientId) {
                    incomeSection[i].title = filteredHouseholdMembers[j].optionDisplay;
                    incomeSection[i].header = filteredHouseholdMembers[j].optionDisplay + " " + incomeSectionMultilanguageLabels(language).incomeBenefitLabel;
                    incomeFilter.push(incomeSection[i]);
                  }
                }
              }
              let incomeGrante = incomeSection.filter(item => item.clientId === 'GRANTEE');
              let incomeSummary = incomeSection.filter(item => item.id === "income-summary");
              let updateIncomeSection = [...incomeGrante, ...incomeFilter, ...incomeSummary]
              this.props.appendIncomeSection(updateIncomeSection)
            }
            break;

          case "combined-income":
            if (actionButtonType === TextProps.SIGNSUBMIT) {
              this.redirectToSignAndSubmit();
              return;
            }
            else if (this.checkSectionHasAnswers((filterData(this.props.applyDsnap.sections, "income").subsections), activeId)) {
            let incomeCompletedValue = filterData(this.props.applyDsnap.sections, "income");
            incomeCompletedValue.completed = true;
            this._renderNextSection("income-summary");
              let anyAnswerNotEmpty = false;

              incomeCompletedValue.subsections.forEach(section => {
                if (section.id === 'income') {
                  section.questions.forEach(question => {
                    if (question.Answer !== "") {
                      anyAnswerNotEmpty = true;
                      return;
                    }
                  });
                }
              });
              incomeCompletedValue.subsections.forEach(section => {
                if (section.id === 'income-summary') {
                  section.completed = anyAnswerNotEmpty;
                }
              });
            return;
          }

            else{
              this.incomefilterQuestionsBySelection(activeId);
            }
            break;
          case "resources":
            if (
              filterData(this.props.applyDsnap.sections, "resources-landing").questions[0] && filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer !== " " && filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer.length && filterData(this.props.applyDsnap.sections, "resources-landing").questions[0].Answer.length > 0
            ) {
              this.resourcesfilterQuestionsBySelection(activeId);
            }
            break;
          case "expenses":
            if (
              filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0] && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0] && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer !== " " && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer.length && filterData(this.props.applyDsnap.sections, "expense-landing-page").subsections[0].questions[0].Answer.length > 0
            ) {
              this.expensesfilterQuestionsBySelection(activeId);
            }
            break;
          default:
            if (
              activeIdSubString.indexOf("kids-adult-multiple-address-") > -1
            ) {
              // for (let adultMember of dataSections.memberOptions) {
              //   if (adultMember.name === dataSections.title) {
              //     dataSections.clientId = adultMember.id;
              //   }
              for (let adultMember of filterData(this.props.apply.sections, "income").questions[0]
                .options) {
                if (adultMember.optionDisplay === dataSections.title) {
                  dataSections.clientId = adultMember.optionValue;
                }
                if (
                  filterData(this.props.apply.sections, "expenses").currentExpSeq !== undefined &&
                  filterData(this.props.apply.sections, "expenses").currentExpSeq !== null
                ) {
                  dataSections.currentExpSeq =
                    filterData(this.props.apply.sections, "expenses").currentExpSeq;
                }
              }
            } else if (activeIdSubString.indexOf("medical-address-") > -1) {
              let currentChild = dataSections.title.split("'s ")[0];

              for (var memdicalMember of dataSections.memberOptions) {
                if (memdicalMember.name === currentChild) {
                  dataSections.clientId = memdicalMember.id;
                  break;
                }
              }
            }
            break;
        }
        if (
          activeId !== "income" &&
          activeIdSubString !== "combined-income" &&
          activeId !== "kids-and-adults" &&
          activeId !== "medical-expenses" &&
          !(activeId.indexOf("medical-expenses-") > -1)
        ) {
          if (activeId.indexOf("medical-address-") > -1) {
            if (
              filterData(this.props.apply.sections, "expenses").currentExpSeq !== undefined &&
              filterData(this.props.apply.sections, "expenses").currentExpSeq !== null
            ) {
              dataSections.currentExpSeq =
                filterData(this.props.apply.sections, "expenses").currentExpSeq;
            }
          }
        } else {
          if (actionButtonType === TextProps.SIGNSUBMIT && isValid) {
            this.redirectToSignAndSubmit();
            return;
          } else {
            this._renderNextSection(activeId);
            let AnswerObjectCopy = this.state.AnswerObject;
            AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
            this.setState({ AnswerObject: AnswerObjectCopy });
          }
        }
      }
    }
    this.props.updateCurrentSectionData(this.props.applyDsnap);

    //Date Dynmaic passing in the label
    if (activeId === "before-you-start") {
      this.updateDynamicLabel();
    }

    if (actionButtonType === TextProps.SIGNSUBMIT) {
      this.redirectToSignAndSubmit();
      return;
    } else {
      this._renderNextSection(activeId);
      let AnswerObjectCopy = this.state.AnswerObject;
      AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
      this.setState({ AnswerObject: AnswerObjectCopy });
    }
    let userName = "";
    if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[1].Answer !== "") {
      userName =
        userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[1].Answer + " ";
    }
    if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[2].Answer !== "") {
      userName =
        userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[2].Answer + " ";
    }
    if (filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[3].Answer !== "") {
      userName = userName + filterData(this.props.applyDsnap.sections, "head-of-household-info").questions[3].Answer;
    }
    if (
      this.state.signature.length < 1 ||
      this.state.signature !== userName
    ) {
      if (
        this.state.signature.length < 1
      ) {
        this.setState({ signatureError: TextProps.VALUE_TRUE });
        this.signatureErrorMsg =
          signAndSubmitDsnapMultilanguageLabels(language).signatureErrorMsg;
      } else if (
        this.state.signature !== userName
      ) {
        this.setState({ signatureError: TextProps.VALUE_TRUE });
        this.signatureErrorMsg =
          signAndSubmitDsnapMultilanguageLabels(language).signatureErrorMsg1;
      } else {
        this.setState({ signatureError: TextProps.VALUE_FALSE });
        this.signatureErrorMsg = "";
      }
    }
    else {
      //Sending obj to service side for saving
      // application having object kind of data
      let application;

      let inProgressObj = {};
      inProgressObj.status = "RECVED";
      application = convertToDTOObj(
        this.state.sections,
        this.state.signature,
        inProgressObj,
        this.state.dsnapId
      );
      if (this.state.signature === userName) {
        this.setState({ signatureError: TextProps.VALUE_FALSE });
        this.props.submitDsnapApply(application, language);
      }
    }
  }

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  onPrimaryActionClickSaveContinue = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event, TextProps.CONTINUE);
  };

  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    this.goBackHandler();

  };
  _saveHandler = (activeId, actionButtonType) => {
    //Final save logic
    if (this.state.sectionDataUpdated) {
      this.setState({ sectionDataUpdated: TextProps.VALUE_FALSE });
      this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
      this.props.updateCurrentSectionData(this.props.applyDsnap);
    }
    if (
      actionButtonType === TextProps.CONTINUE ||
      actionButtonType === undefined
    ) {
      this._renderNextSection(activeId);
    } else if (actionButtonType === TextProps.BACK) {
      this.goBackHandler();
    }
  };

  goBackHandler = () => {
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.applyDsnap;
    let previousSectionId;
    previousSectionId = getPreviousSection(activeId, sections);
    delete this.state.AnswerObject[
      activeId
    ]

    if(activeId ==="head-of-household-info"){
     let headHousehold =  filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
     for (let i = 0; i < headHousehold.length; i++) {
      if(headHousehold[i].error){
        headHousehold[i].error = false;
      }
      this._renderNextSection(previousSectionId)
     }
    }

    if(activeId ==="household-situation"){
      let householdSituation =  filterData(this.props.applyDsnap.sections, "household-situation").questions;
      for (let i = 0; i < householdSituation.length; i++) {

       if(householdSituation[i].error){
        householdSituation[i].error = false;
       }
       if ((householdSituation[i].Answer === aboutMeDsnapMultilanguageLabels(language).yesNoOptions[0])&&(householdSituation[i].name === "food-assistance" || householdSituation[i].name ==="food-benefits")){
        if(householdSituation[i].conditionals[0].conditionalQuestions[0].error){
          householdSituation[i].conditionals[0].conditionalQuestions[0].error = false;
        }
       }

       this._renderNextSection(previousSectionId)
      }
     }

      var activeIdSplit = activeId.split("-");
      var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
      var activeIdSubString = activeIdSplit.join("-");

   if (activeIdSubString === "household-member"){
    let myHousehold =  filterData(this.props.applyDsnap.sections, "my-household").subsections;
    for (let i = 0; i < myHousehold.length; i++) {
      if (myHousehold[i].questions && Array.isArray(myHousehold[i].questions)) {
        for (let j = 0; j < myHousehold[i].questions.length; j++) {
          if (myHousehold[i].questions[j].error) {
            myHousehold[i].questions[j].error = false;
          }
        }
      }
    }
    this._renderNextSection(previousSectionId) 

   }

   if (activeIdSubString === "combined-income-subsection") {
    let combinedIncome = filterData(this.props.applyDsnap.sections, "income").subsections;
    for (let i = 0; i < combinedIncome.length; i++) {
        if (combinedIncome[i].subsections && Array.isArray(combinedIncome[i].subsections)) {
            for (let j = 0; j < combinedIncome[i].subsections.length; j++) {
                if (combinedIncome[i].subsections[j].questions && Array.isArray(combinedIncome[i].subsections[j].questions)) {
                    for (let k = 0; k < combinedIncome[i].subsections[j].questions.length; k++) {
                        if (combinedIncome[i].subsections[j].questions[k].repeatedQuestion && combinedIncome[i].subsections[j].questions[k].repeatedQuestion.error) {
                          combinedIncome[i].subsections[j].questions[k].repeatedQuestion.error = false;
                        }
                    }
                }
            }
        }
    }
    this._renderNextSection(previousSectionId);
}

    if (activeId === "expense-landing-page" && previousSectionId === "resources-landing") {
      this.resourcesSubsectionAdded();
    }

    if( activeId === "resources-landing" && 
      (
        filterData(this.props.applyDsnap.sections, "income").questions[0].Answer.indexOf(MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[1]) !== -1 ||
        filterData(this.props.applyDsnap.sections, "income").questions[0].Answer.indexOf(MyhouseholdDsnapMultilanguageLabels(language).incomeOptionLabel[2]) !== -1
    )){
      let incomeCompletedValue = filterData(this.props.applyDsnap.sections, "income");  
      const lastSubsectionIndex = incomeCompletedValue.subsections.length - 1;
      incomeCompletedValue.subsections[lastSubsectionIndex].completed = false;
    }

    if (activeId === "sign-and-submit" && previousSectionId === "expenses-combined") {
      this.expensesSubsectionAdded();
    }
    if ((activeId === "resources-landing" && previousSectionId === "income-summary") &&
      (filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer === "1" ||
        filterData(this.props.applyDsnap.sections, "my-household").questions[0].Answer === "")) {
      this._renderNextSection("my-household")
      return;
    }


    if (activeId === "resources-landing" && previousSectionId === "income-summary") {
      const houseHoldSubSections = filterData(this.props.applyDsnap.sections, "my-household").subsections;
      const getHouseholdValue = houseHoldSubSections.map(item => parseInt(item.id.match(/\d+/)[0]));
      const getLastActiveId = Math.max(...getHouseholdValue);
      const setLastActiveID = `household-member-${getLastActiveId}`;
      this._renderNextSection(setLastActiveID)
      return;
    }

    if (activeId === "income-summary") {
      const incomeSection = filterData(this.props.applyDsnap.sections, "income").subsections;
      
      const questionsWithAnswer = incomeSection.filter(section => {
        return section.questions && section.questions.some(question => question.Answer && question.Answer.length > 0);
      });
      const lastIndex = questionsWithAnswer.length - 1;
      this._renderNextSection(`combined-income-${lastIndex}`)
      return;
    }
    

    let signAndSubmitSection = this.props.applyDsnap.sections.filter(
      (sec) => sec.id === "sign-and-submit"
    )[0];
    if (signAndSubmitSection && signAndSubmitSection.partialSave) {
      this.props.applyDsnap.sections.filter(
        (sec) => sec.id === "sign-and-submit"
      )[0].partialSave = TextProps.VALUE_FALSE;
      previousSectionId = signAndSubmitSection.lastKnownLocation;
    } else {
      previousSectionId = getPreviousSection(activeId, sections);
    }



    if (previousSectionId) {
      const previousSection = this._getSection(previousSectionId);
      this.setState({ prevIncomingQuestions: _.cloneDeep(previousSection) });
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    }

    //Go back for before you start
    if (activeId === "before-you-start") {
      this.props.history.push(`/`)
    }
    //Date dynamic label update in goBack 
    this.updateDynamicLabel();
  }

  _renderNextSection = (activeId) => {
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.applyDsnap.sections;
    }
    let nextSectionId = getNextSection(activeId, section);
    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState({ prevIncomingQuestions: _.cloneDeep(nextSection) });
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };
  setHomelessMailingAddress = (answer) => {
    if (answer["head-of-household-info"] && answer["head-of-household-info"]["is-homeless"]) {
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        this.setState({ renderedSections: renderedSectionsCopy },
        () => {
          renderedSectionsCopy.push(incomingSection);
          if(answer["head-of-household-info"]["is-homeless"] === TextProps.VALUE_YES && !this.state.setHomelessModalOpen){
            this.setState({isHomelessModalOpen: TextProps.VALUE_TRUE,setHomelessModalOpen: TextProps.VALUE_TRUE});
          }else if(answer["head-of-household-info"]["is-homeless"] === TextProps.VALUE_NO){
            this.setState({isHomelessModalOpen: TextProps.VALUE_FALSE,setHomelessModalOpen: TextProps.VALUE_FALSE});
          }
          this.setState({ renderedSections: renderedSectionsCopy});
          this.props.updateCurrentSectionData(this.props.apply);
        });
    }
  };
  _bindAnswerObject = (answer) => {
    if (answer && answer.income && answer.income.income && answer.income.income.length > 1 && answer.income.income.includes(incomeSectionMultilanguageLabels(language).questionClearOptionTwo)) {
      let incomeCopy = filterData(this.props.applyDsnap.sections, "income");
      if(incomeCopy.questions[0].Answer.includes(incomeSectionMultilanguageLabels(language).questionClearOptionTwo)){
        answer.income.income = answer.income.income.filter(ans => ans !== incomeSectionMultilanguageLabels(language).questionClearOptionTwo);
      }else{
        answer.income.income = answer.income.income.filter(ans => ans === incomeSectionMultilanguageLabels(language).questionClearOptionTwo);
      }
    }
    if (answer && answer.income && answer.income.income) {
      this.setIncome(answer);
    }
    if (answer && answer["head-of-household-info"] && answer["head-of-household-info"]["is-homeless"] && (answer["head-of-household-info"]["is-homeless"] !== this.state.homelessCheck || answer["head-of-household-info"]["is-also-mailing"] !== this.state.isMailCheck)) {
      if(answer["head-of-household-info"]["is-homeless"] === TextProps.VALUE_YES){
        answer["head-of-household-info"]["is-also-mailing"] = TextProps.VALUE_STR_FALSE;
      }
      if(answer["head-of-household-info"]["is-homeless"] !== this.state.homelessCheck && answer["head-of-household-info"]["is-homeless"] === TextProps.VALUE_NO){
        answer["head-of-household-info"]["is-also-mailing"] = TextProps.VALUE_STR_TRUE;
      }
      this.setState({homelessCheck : _.cloneDeep(answer["head-of-household-info"]["is-homeless"]),isMailCheck : _.cloneDeep(answer["head-of-household-info"]["is-also-mailing"])});
      this.setHomelessMailingAddress(answer);
    }

    let myhouseHold = answer&&answer["my-household"];
    if(myhouseHold && myhouseHold!==""){
      const myhouseHoldobj = myhouseHold.numberInHousehold;
   
      if(myhouseHoldobj === ""){
        this.setState({
          requiredField: TextProps.VALUE_TRUE

        })

      }
      else{
        this.setState({
          requiredField: TextProps.VALUE_FALSE

        })
      }    
      }
      else{
        this.setState({
          requiredField: TextProps.VALUE_FALSE
        })
      }  
  };

  setIncome = (answer) => {
    if (answer.income.income) {
        const renderedSectionsCopy = [];
        const incomingQuestions = this._getSection(this.state.activeSectionId);
        const incomingSection = bindAnswers(
          incomingQuestions,
          this.state.activeSectionId,
          this.state.AnswerObject
        );
        //this._getSection(this.state.activeSectionId);
        renderedSectionsCopy.push(incomingSection);

        setTimeout(() => {
          this.setState({ renderedSections: renderedSectionsCopy });
          this.props.updateCurrentSectionData(this.props.applyDsnap);
        }, 3);
    }
  };

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }
  renderIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = this.state.incomeMemberTemplate
      ? this.state.incomeMemberTemplate
      : IncomeSectionTemplate;
    var incomeCopy = filterData(this.props.applyDsnap.sections, "income");
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);

    incomeMeTemplate.id = "combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = incomeMultilanguageLabels(name, language).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = incomeMultilanguageLabels(
      name,
      language
    ).mainQuestion;
    incomeMeTemplate.subsections[0].id =
      "combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = incomeMultilanguageLabels(
      name,
      language
    ).heading;
    incomeMeTemplate.subsections[0].title = name;
    incomeMeTemplate.title = name;
    if (
      incomeCopy !== undefined &&
      incomeCopy.questions !== undefined &&
      incomeCopy.questions.length > 0
    ) {
      for (let income = 0; income < incomeCopy.questions.length; income++) {
        for (
          let incomeOpt = 0;
          incomeOpt < incomeCopy.questions[income].options.length;
          incomeOpt++
        ) {
          if (
            incomeCopy.questions[income].Answer[income] ===
            incomeCopy.questions[income].options[incomeOpt].optionValue &&
            name ===
            incomeCopy.questions[income].options[incomeOpt].optionDisplay
          ) {
            incomeMeTemplate.clientId =
              incomeCopy.questions[income].options[incomeOpt].optionValue;
          } else if (
            name ===
            incomeCopy.questions[income].options[incomeOpt].optionDisplay
          ) {
            incomeMeTemplate.clientId =
              incomeCopy.questions[income].options[incomeOpt].optionValue;
          }
        }
      }
    }
    let incomeReturn = incomeMultilanguageLabels(name, language); //name
    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
        incomeReturn.subQuestions[j][Props.LABEL];
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
        Props.LABEL
      ] = incomeReturn.subQuestions[j][Props.LABEL];
    }
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendIncomeDetails(incomeCopy);
    this.onSectionChange(incomeMeTemplate);
  };
  modalContentSucess = () => {
    let registrationText = signAndSubmitDsnapMultilanguageLabels(language).registrationText;
    let concatenatedText = `${registrationText} ${this.state.registrationId}`;
    return (
      <div style={{ padding: '1rem 0 1rem 1rem' }}>
        <span >
          {
            signAndSubmitDsnapMultilanguageLabels(language).congratulationText
          }<br />
          {
            signAndSubmitDsnapMultilanguageLabels(language).successfullText
          }<br />
          {concatenatedText}
        </span>
        <br />
        <div style={{ display: "flex", padding: '1rem 3rem 0.8rem 0' }}>
          <button className="dta-button dta-button--primary" style={{ width: '5rem' }} onClick={this.closeSucessWarningModal}>{signAndSubmitDsnapMultilanguageLabels(language).okText}</button>
        </div>
      </div>
    )
  }


  renderWarningSucessPopup = () => {
    return modalHelperFunction.dtaHelperModal(
      (instance) => (this.warningModal = instance),
      this.state.showSuccessWarningModal,
      this.closeSucessWarningModal,
      "",
      "Dsnap-modal",
      false, false, false,
      <div style={{ fontSize: '1.5rem', fontWeight: '700', padding: '0.5rem 0rem 0.5rem 0.5rem' }}>
        {signAndSubmitDsnapMultilanguageLabels(language).submittedHeaderText}</div>
      , this.modalContentSucess(),
      false
    );
  };

    showSucessWarningModal = () => {
    this.setState({ showSuccessWarningModal: TextProps.VALUE_TRUE });
  };


  closeSucessWarningModal = () => {
    this.setState({
      showSuccessWarningModal: TextProps.VALUE_FALSE
    })
    this.props.history.push(`/`)
  };
  renderLanguageSwitchWarningModal = () => {
    return (
      <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
      <Modal
        isOpen={this.state.languageChangeWarning}
              onClose={() => {
                this.props.languageChanged({ isCancel: true }),
                  this.setState({ languageChangeWarning: TextProps.VALUE_FALSE })
              }}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={beforeYoustartDsnapMlanguageLabels(language).confirmDelete}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
          {beforeYoustartDsnapMlanguageLabels(language).areYouSureWarningLabel}
          </p>
          <p className="text--small"> 
          {beforeYoustartDsnapMlanguageLabels(language).languageWarningLabel}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>{
                this.props.languageChanged({ isCancel: true})
                      this.setState({ languageChangeWarning: TextProps.VALUE_FALSE })
                    }
              }
            >
              {beforeYoustartDsnapMlanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => {
                this.closeDeleteWarningModal(TextProps.VALUE_TRUE),
                        this.props.languageChanged({ isDelete: true })
                    }}
            >
              {beforeYoustartDsnapMlanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      </div>
    );
  };
  closeRenderIsHomelessModal = () => {
    this.setState({isHomelessModalOpen : TextProps.VALUE_FALSE});
  }
  // Render a isHomeless Modal
  renderIsHomelessModal = () => {
    return (
      <Modal
        isOpen={this.state.isHomelessModalOpen}
        onClose={() =>
          this.closeRenderIsHomelessModal()
        }
        modalClass="homeless-modal"
        titleText="homeless Warning"
        headerText={
          beforeYoustartDsnapMlanguageLabels(language).isHomelessHouseholdheader
        }
      >
      <div className="dta-modal__body pad-all">
        <div>
         <div className="text--small">{beforeYoustartDsnapMlanguageLabels(language).isHomelessHouseholdContent[0].line1}</div>
         <div className="text--small">{beforeYoustartDsnapMlanguageLabels(language).isHomelessHouseholdContent[1].line2} <a target="_blank"  href={ALABAMA_COUNTY_OFFICES}>here</a></div>
         <div className="text--small">{beforeYoustartDsnapMlanguageLabels(language).isHomelessHouseholdContent[2].line3}</div>
        </div>
        <p> </p>
        <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeRenderIsHomelessModal()
              }
            >
            {beforeYoustartDsnapMlanguageLabels(language).okText}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  closeDeleteWarningModal = (status) => {
    if (status) {
              this.props.history.push(`/dSnap`)
      }
    this.setState({
      deleteWarningRedirectToHomePage: TextProps.VALUE_TRUE,
    });
  };
  //Function to handle delete popup actions(confirm)
  onDeleteClick = (event) => {
    event.preventDefault();
    this.setState({ deleteWarningShowModal: TextProps.VALUE_TRUE });
  };

  renderApplyQuestions(questionsSections) {
    const { section } = this.props.applyDsnap;
    this.updateDimensions();
    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    if (isFreeComOpen === "true") {
      return (
        <div style={{ height: "100%" }}>
          <div className="full-height apply" style={{ overflowY: "unset" }}>
            <div style={{ display: "contents" }}>
              <SidebarNavigation
                sections={questionsSections}
                activeSectionId={this.state.activeSectionId}
                onSectionClick={this.onSectionChange}
                skipToAnchor="#content"
              />
              <main
                className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
                id="content"
                tabIndex="-1"
                style={{ overflowY: "hidden" }}
              >
                {
                  this.state.renderedSections.map((section) => this.renderSection(section))
                }
              </main>
              {this.renderDataDeleteWarningModal()}
              {this.renderAddressValidationModal()}
              {this.renderWarningSucessPopup()}
              {this.renderLanguageSwitchWarningModal()}
              {this.renderIsHomelessModal()}
            </div>
          </div>
        </div>
      );
    }
  }
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500}
        onExited={(node) => {
          this.removeSection(section.id);
        }}>
        <div
          className={`slide-animation ${this.state.directionClass} ${section.id === this.state.activeSectionId
            ? this.state.directionClass
            : "exit-done"
            }`}
        >
          <div className={`slide-animation ${this.state.directionClass}`}>
            <ApplySection
              key={section.id}
              section={section}
              primaryActionLabel={this.getPrimaryActionLabel(section.id)}
              // onPrimaryActionClick={this.onPrimaryActionClick}
              onPrimaryActionClick={this.onPrimaryActionClickSaveContinue}
              onContinueClick={this.onContinueClick}
              onSecondaryActionClick={this.onSecondaryActionClick}
              bindAnswerToState={this._bindAnswerObject}
              answerObject={this.state.AnswerObject}
              onWarningProgress={this.presentWarningProgressModal}
              onSignSubmitActionClick={this.onSignSubmitActionClick}
              signAndSubmit={filterData(this.props.applyDsnap.sections, "before-you-start").signAndSubmit}
            >
              {!section.questions ? this.getSectionBody(section.id) : null}
            </ApplySection>

          </div>
        </div>
      </CSSTransition>
    )
  }

  handleActiveLangChange = (lang) => {
    if (lang === this.state.selectedLang) {
      this.setState({ selectedLang: '', showDefaultSection: true, freeCommunication: false });
    } else if (lang === "freeComm") {
      this.setState({ freeCommunication: true, selectedLang: lang, showDefaultSection: false, })
    } else {
      this.setState({ selectedLang: lang, showDefaultSection: false });
    }
  };

  render() {
    const { sections } = this.props.applyDsnap;
    if (this.state.isLoading) {
      return <Loading />;
    }
    if (
      sections.length > 0
      // &&
      // sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      const newSections = [...sections];
      // if (sessionStorage.getItem("applicationType") === TextProps.MATCH_SNAP) {
      //   const isProvider = sessionStorage.getItem("isProvider");
      //   if (isProvider) {
      //     newSections[1].subsections[0].questions[0].label =
      //       isProvider === "true"
      //         ? aboutMeMultilanguageLabels(language).contactInformation1 +
      //           ' <a href="tel:8773822363">877-382-2363 </a>' +
      //           aboutMeMultilanguageLabels(language).contactInformation2 +
      //           ' <a href="https://dhr.alabama.gov/county-office-contact/" target="_blank" rel="noopener noreferrer">' +
      //           aboutMeMultilanguageLabels(language).contacInformationLink +
      //           "</a>" +
      //           aboutMeMultilanguageLabels(language).contactInfo3
      //         : aboutMeMultilanguageLabels(language).contactInfo1;
      //   }
      // }
      return this.renderApplyQuestions(newSections)
    } else {
      if (sections.length > 0 && sections[0].errorRedirect) {
        return (
          <div>
            <h1>
              Something went wrong. Please contact{" "}
              <Link href="https://dhr.alabama.gov/county-office-contact/">
                DHR County Office
              </Link>
            </h1>
          </div>
        );
      }
      return <Loading />;

    }

  }

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDsnapSectionBasedApplicationType: setDsnapSectionBasedApplicationType,
      applyDSanpQuestionsSectionsNew: applyDSanpQuestionsSectionsNew,
      updateCurrentSectionData: updateCurrentSectionData,
      appendIncomeDetails: appendIncomeDetails,
      appendResourcesDetails: appendResourcesDetails,
      appendExpenseDetails: appendExpenseDetails,
      appendHouseHoldMembers: appendHouseHoldMembers,
      setHouseholdMembers: setHouseholdMembers,
      submitDsnapApply: submitDsnapApply,
      dsnapAddressStorageAPI:dsnapAddressStorageAPI,
      addressValidation: addressValidation,
      updateLastKnownLocationDsnap: updateLastKnownLocationDsnap,
      countyList: countyList,
      appendRequiredFieldDnsap:appendRequiredFieldDnsap,
      languageChanged: languageChanged,
      appendIncomeSection: appendIncomeSection
    },
    dispatch
  );
}

export default (connect(mapStateToProps, mapDispatchToProps)(ApplyDsnapContainer));

