//Home Banner Alert Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getPEBTBannerSuccess: "P_EBT_BANNER_SUCCESS",
  getPEBTBannerFailed: "P_EBT_BANNER_ERROR",
};

export function getPEBTBannerAlerts() {
  const url = config.TigerURL + `/apipublic/security/banner/`;
  return (dispatch) => {
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(url, TigerOptions)
      .then(function (response) {
        dispatch(getPEBTBannerSuccessAction(response));
      })
      .catch(function (error) {
        dispatch(getPEBTBannerFailedAction());
      });
  };
}

// sending/storing to redux store
function getPEBTBannerSuccessAction(data) {
  return {
    type: actionTypes.getPEBTBannerSuccess,
    payload: data,
  };
}

function getPEBTBannerFailedAction(data) {
  return {
    type: actionTypes.getPEBTBannerFailed,
  };
}
