import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
let config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  FETCH_TOKEN_SUCCESS: "FETCH_TOKEN_SUCCESS",
  FETCH_TOKEN_ERROR: "FETCH_TOKEN_ERROR",
};
export function fetchToken() {
  const url = 'https://directline.botframework.azure.us/v3/directline/tokens/generate';
  return (dispatch) => {
    axios
      .post(url, {}, {
        headers: {
          'Authorization': "Bearer QvWmvDTz8dw.ihRqMQthHzn1X7Lj2pU_IqzE1X6tZ_51aD4OeSLSf60"
        },
        json: {
          User: { Id: "userId" }
        }
      })
      .then(response => {
        dispatch(fetchTokenSuccessAction(response.data.token));
      })
      .catch(error => {
        dispatch(fetchTokenErrorAction());
      });
  };
}
function fetchTokenSuccessAction(token) {
  return {
    type: actionTypes.FETCH_TOKEN_SUCCESS,
    payload: token,
  };
}
function fetchTokenErrorAction() {
  return {
    type: actionTypes.FETCH_TOKEN_ERROR,
  };
}

export function sendChatData(messages) {
  
    console.log("send chat data called with messages", messages);  
    const url = config.TigerContextURL+"/saveChatbotConversation";
    const email = sessionStorage.getItem("user_name");
    const audit = getAudit();
    if (email){
       console.log("messages are:;", messages); 
      const combinedMessageTxt = messages.map(msg => msg.MESSAGE_TXT).join(' '); 
      const messagePayload = { 
       email: email, 
        conversationId: messages.length > 0 ? messages[0].CONVERSATION_ID : null, 
        messageTxt: combinedMessageTxt, 
        createTs: new Date()
      };
      console.log('Sending chat payload:', messagePayload);
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
         Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
  
      axios
      .post(url, { 
        email: email, 
         conversationId: messages.length > 0 ? messages[0].CONVERSATION_ID : null, 
         messageTxt: combinedMessageTxt, 
         createTs: new Date()
       }, options)
      .then(function (data) {
        console.log("data saved!", data);
      })
      .catch(function (error) {
        console.log(error);
      });
     }
}