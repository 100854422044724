import React, { Component } from "react";

import ConsumerDTAInfo from "../../consumer/components/consumerDTAInfo";

class ProviderDTAInfo extends Component {
  render() {
    return <ConsumerDTAInfo />;
  }
}

export default ProviderDTAInfo;
