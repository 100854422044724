import React, { Component } from 'react'
import { consumerConst } from "../../../../pages/consumer/consumerText";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import * as TextProps from "../../../../utils/constants/text";
import SnapHearingEN from "../../../../utils/resources/pdfs/SNAPHearingPacket-English.pdf"
import SnapHearingES from "../../../../utils/resources/pdfs/SNAPHearingPacket-Spanish.pdf"


import {
    appealDocumentGetCall,
} from "../../../../redux/concernsAppeals/concernsAppealsAction"



const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
let webAppealIdValue;



class appealSubmit extends Component {
    constructor(props) {
        super(props);
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        this.state = {
            agencyId: agencyID,
            webAppealIdValue: "",
            appealRefNum: "",
            isShowTextModal: TextProps.VALUE_FALSE,
        }
    }

    componentDidMount() {
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        let webAppealIdValue = this.props && this.props.consumer.consumerAppealsSuccess && this.props.consumer.consumerAppealsSuccess.docReferenceNumber;
        this.setState({ webAppealIdValue });
        let appealRefNum = this.props&&this.props.consumer.consumerAppealsSuccess&&this.props.consumer.consumerAppealsSuccess.appealReferenceNumber;
        this.setState({ appealRefNum })
    }

    handlePrintClick = () => {
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        let webAppealIdValue =  this.props && this.props.consumer.consumerAppealsSuccess && this.props.consumer.consumerAppealsSuccess.docReferenceNumber;
        this.props.appealDocumentGetCall(agencyID, selectedLanguage, webAppealIdValue);
    }

    handleLink = () => {
        if(this.state.isShowTextModal === TextProps.VALUE_FALSE) {
            this.setState({isShowTextModal : TextProps.VALUE_TRUE})
        }
        if(this.state.isShowTextModal === TextProps.VALUE_TRUE) {
            this.setState({isShowTextModal : TextProps.VALUE_FALSE})
        }
    }

    render() {
        return (
            <div className='pad-all--double'>
                <div className="pure-u-1">
                    <div>
                        <span style={{ display: 'flex', fontSize: '2rem', fontWeight: '600', marginBottom: '10px', padding: '0rem 12rem 0rem 2rem' }}>{consumerConst(selectedLanguage).appealSubmitHeaderText}</span>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px' }}>
                    </div>
                </div>
                <div className="pure-u-1" style={{ display: 'flex', flexDirection: 'column', padding: '0rem 12rem 0rem 2rem' }}>
                    <span >{consumerConst(selectedLanguage).appealsNumber}
                        {this.state.appealRefNum}
                    </span>
                    <span style={{ marginTop: '1rem' }}>{consumerConst(selectedLanguage).appealsYouCanText}<a onClick={this.handlePrintClick} style={{ textDecoration: "underline", cursor: "poineter",marginLeft:"2px" }}>{consumerConst(selectedLanguage).appealSubmitPrintText}</a> {consumerConst(selectedLanguage).appealSubmitorText} <a onClick={this.handlePrintClick} style={{ textDecoration: "underline", cursor: "pointer",marginRight:"3px" }}>{consumerConst(selectedLanguage).appealSubmitSaveText}</a>
                    {consumerConst(selectedLanguage).appealsRequestText} </span>
                    <span style={{ marginTop: '1rem' }} onClick={() => this.handleLink()}><a href='#'>{consumerConst(selectedLanguage).appealsStatementText}</a></span>
                    {this.state.isShowTextModal && (
                        <div className='pad-all--double dta-terms' style={{padding: "12px"}}>
                            <span>{consumerConst(selectedLanguage).appealBoxText}</span>
                            <br/>
                            <br/>
                            <span><a href={selectedLanguage === "en-US" ? SnapHearingEN : SnapHearingES } target="_blank">{consumerConst(selectedLanguage).appealLanguageText}</a></span>
                            </div>
                    )}
                </div>
            </div>  
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            appealDocumentGetCall: appealDocumentGetCall,

        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(appealSubmit);