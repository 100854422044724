import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MhProviderApp from "./components/mhProviderApp";

/**
 * provider main page with roting navigation
 */
class MhProviderContainer extends Component {
  render() {
    return (
      <Router basename="mhProvider">
        <MhProviderApp />
      </Router>
    );
  }
}

export default MhProviderContainer;
