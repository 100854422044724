import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as languageConsts from "../constants/types";
import { langOptSel } from "../constants/constants";
//end code for google analytics

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === langOptSel) {
    language = "en-US";
  }
const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

class TermsSnapTafdcEaedc extends Component {
  constructor() {
    super();
    enableUniqueIds(this);
  }

  render() {
    let appType = sessionStorage.getItem("applicationType");
    if(appType === "SNAP"){
      switch(language) {
        case languageConsts.ENGLISH:
          return (
            <div>
            <p style={{lineHeight: 0, textAlign: "center"}}>
              <strong>Department of Human Resources (DHR) </strong>
            </p>
            <p style={{textAlign: "center" }}>
              <strong>Notice of Rights, Responsibilities and Penalties </strong>
            </p>
             <p>
                 <strong>Statement of Understanding</strong>
             </p>
             <p>
             When your household receives food assistance benefits, you must follow all the rules. 
             You must provide true and complete information about everyone in your household and 
             you must provide documents to prove what you say if you are asked to by the worker. 
             Any member of your household who breaks any of these rules on purpose can be 
             barred from SNAP for 1 year for first offense, 2 years for second offense, and 
             permanently for third offense; fined up to $250,000, imprisoned up to 20 years or both; 
             and subject to prosecution under other federal laws. She/he may also be barred from 
             the Food Assistance Program for an additional 18 months if court ordered. DO NOT 
             give false information or hide information to get or continue to get SNAP benefits. DO 
             NOT trade or sell EBT cards. DO NOT alter EBT cards to get SNAP benefits you are 
             not entitled to receive. DO NOT use SNAP benefits to buy ineligible items such as 
             alcohol and tobacco or to pay on credit accounts. DO NOT use someone else’s SNAP 
             benefits or EBT card for your household. Individuals determined by a court to have 
             committed the following program violations will be subject to the following penalties:
             </p>
             <ul>
               <li>
               If you are found to have used or received benefits in a transaction involving the 
               sale of a controlled substance, you will be ineligible to receive SNAP benefits for 
               a period of two years for the first offense and permanently upon the second such 
               offense.
               </li>
               <li>
               If you are found to have used or received benefits in a transaction involving the 
               sale of firearms, ammunition or explosives, you will be permanently ineligible to 
               receive SNAP benefits upon the first occasion of such violation.
               </li>
               <li>
               If you have been found guilty of having trafficked benefits for an aggregate 
               amount of $500 or more, you will be permanently ineligible to receive SNAP 
               benefits upon the first occasion of such violation.
               </li>
               <li>
               If you have been found to have made a fraudulent statement or representation 
               with respect to your identity or place of residence in order to receive multiple 
               SNAP benefits simultaneously, you will be ineligible to participate in the program 
               for a period of 10 years.
               </li>
               <li>
               If you are fleeing to avoid prosecution, custody, or confinement, after conviction 
               for a crime or an attempt to commit a crime, which is a felony, or are in violation 
               of probation or parole imposed under a federal or state law, you are ineligible for 
               food assistance.
               </li>
               <li>
                 If you are convicted of using or receiving food assistance benefits in a transaction 
                 involving the sale of a controlled substance, you will be ineligible 24 months for 
                 the first violation and permanently for the second violation.
               </li>
               <li>
               If you are convicted of a federal or state felony that has an element the 
               possession, use, or distribution of a controlled substance, you may be ineligible 
               for food assistance.
               </li>
             </ul>
             <p>
             I certify under penalty of perjury that my answers to all questions about each household 
             member, including those about citizenship or alien status, are correct and complete.
             </p>
             <p>
               <strong>Statement of Understanding</strong>
             </p>
             <ol>
               <li>
               I understand that information provided by me in connection with this application for food 
   assistance benefits will be subject to verification by federal, state and local officials to 
   determine if such information is true. I further understand that if any such information is found 
   to be untrue or incorrect, my application for food assistance benefits may be denied or 
   terminated and I may be subject to criminal prosecution for knowingly providing incorrect 
   information.
               </li>
               <li>
               I understand the information given is needed to determine my eligibility for food assistance. I 
   understand all information is confidential and any use or disclosure without my permission 
   will be made only for certain limited purposes allowed under State or Federal laws and 
   regulations. Such purposes include, but are not limited to establishing eligibility, determining 
   benefit amounts, and for providing services to applicants and recipients.
               </li>
               <li>
               I agree to provide information needed to determine eligibility for my household. I understand 
   that I may have to provide documents to prove what I have said. I understand that if 
   documents are not available, I have to give the name of a person or organization my food 
   stamp worker may contact to obtain the necessary proof.
   
               </li>
               <li>
               I certify to the best of my knowledge, under penalty of perjury, that all household members 
   for whom benefits are being sought are U.S. citizens or aliens legally admitted to the United 
   States.
   
               </li>
               <li>
               I understand the information given on this application will be checked by using the State 
   Income and Eligibility Verification System, other computer matching systems, program 
   reviews and audits. In addition, any information given may also be checked by other Federal 
   Aid Programs and Federally Aided State Programs such as school lunch, Family Assistance, 
   and Medicaid. If any information is incorrect, my household may be denied food assistance 
   benefits. If I give false information on purpose, legal or administrative action may be taken 
   against me. I may have to repay food assistance benefits I receive to which I am not entitled.
               </li>
               <li>
               I understand that my household will be ineligible for benefits if I refuse to cooperate in 
   completing the application process or in subsequent reviews of my eligibility including 
   reviews resulting from reported changes, recertification, or as part of a State or Federal 
   Quality Control Review.
   
               </li>
               <li>
               I understand that my signature on this application will serve as my authorization for State and 
   Federal Quality Control Reviewers to verify my household circumstances for food assistance 
   eligibility purposes.
   
               </li>
               <li>
               I understand the work requirements and that my household or a household member may be 
   disqualified from receiving food assistance benefits for failure to cooperate.
               </li>
               <li>
               I understand that if any member of my household voluntarily quits a job or reduces the 
   number of hours s/he works without good cause, my household or a household member may 
   be disqualified from receiving food assistance benefits.
               </li>
               <li>
               I understand that I may request a conference or a fair hearing if I am not satisfied with any 
   decisions of the County Department. This request may be made orally or in writing. My case 
   may be presented at the hearing by any person I choose.
               </li>
               <li>
               I understand that my household will not receive an increase in food assistance benefits if 
   anyone in my household fails to comply with the requirements of another means tested 
   program (Family Assistance, SSI).
               </li>
               <li>
               I understand that my household will be ineligible for benefits if I refuse to cooperate in 
   completing the application process or in subsequent reviews of my eligibility including 
   reviews resulting from reported changes, recertification, or as part of a State or Federal 
   Quality Control Review.
               </li>
               <li>
               I understand that some able bodied food stamp recipients will only be eligible to receive food 
   assistance for 3 months in a 36 month period, unless they work 20 hours a week or participate in certain work and training programs. I understand my worker will advise me if 
   this provision applies to anyone in my household.
               </li>
               <li>
               I understand that I am not to: give false information or hide information to get or continue to 
   get food assistance benefits; use someone else's food assistance benefits; or use food 
   assistance benefits to buy ineligible items such as alcoholic drinks or tobacco.
               </li>
               <li>
               I declare under penalty of perjury, that I have read or had read to me all statements on the 
   application and the information is true and correct to the best of my knowledge.
   
               </li>
             </ol>
           </div>
          );

          case languageConsts.SPANISH:
          return (
            <div>
            <p style={{lineHeight: 0, textAlign: "center"}}>
              <strong>Departamento de Recursos Humanos (DRH) </strong>
            </p>
            <p style={{textAlign: "center" }}>
              <strong> Aviso de Derechos, Responsabilidades y Sanciones </strong>
            </p>
             <p>
                 <strong>Declaración de entendimiento</strong>
             </p>
             <p>
             Cuando su hogar recibe beneficios de asistencia alimentaria, debe seguir todas las reglas.
             Debe proporcionar información verdadera y completa sobre todas las personas en su hogar y
             debe proporcionar documentos para probar lo que dice si el trabajador se lo solicita.
             Cualquier miembro de su hogar que rompa cualquiera de estas reglas a propósito puede ser
             excluido de SNAP por 1 año por la primera ofensa, 2 años por la segunda ofensa, y
             permanentemente por tercera ofensa; multado hasta $250,000, encarcelado hasta 20 años o ambos;
             y sujeto a enjuiciamiento bajo otras leyes federales. También se le puede prohibir
             el Programa de Asistencia Alimentaria por 18 meses adicionales si la corte lo ordena. NO
             dar información falsa u ocultar información para obtener o continuar recibiendo beneficios de SNAP. HACER
             NO intercambie ni venda tarjetas EBT. NO modifique las tarjetas EBT para obtener los beneficios de SNAP que está
             sin derecho a recibir. NO use los beneficios de SNAP para comprar artículos no elegibles como
             alcohol y tabaco o para pagar cuentas de crédito. NO use el SNAP de otra persona
             beneficios o tarjeta EBT para su hogar. Individuos determinados por un tribunal a tener
             cometió las siguientes violaciones del programa estará sujeto a las siguientes sanciones:
             </p>
             <ul>
               <li>
               Si se determina que usó o recibió beneficios en una transacción que involucre al
               venta de una sustancia controlada, no será elegible para recibir los beneficios de SNAP por
               un período de dos años por la primera infracción y de forma permanente a partir de la segunda.
               ofensa.
               </li>
               <li>
               Si se determina que usó o recibió beneficios en una transacción que involucre al
               venta de armas de fuego, municiones o explosivos, quedará permanentemente inelegible para
               recibir beneficios de SNAP en la primera ocasión de dicha violación.
               </li>
               <li>
               Si ha sido declarado culpable de haber traficado con beneficios por un total
               cantidad de $500 o más, no será elegible permanentemente para recibir SNAP
               beneficios en la primera ocasión de tal violación.
               </li>
               <li>
               Si se determina que usted ha hecho una declaración o representación fraudulenta
               con respecto a su identidad o lugar de residencia para recibir múltiples
               beneficios de SNAP simultáneamente, no será elegible para participar en el programa
               por un periodo de 10 años.
               </li>
               <li>
               Si está huyendo para evitar el enjuiciamiento, la custodia o el confinamiento, después de la condena
               por un delito o un intento de cometer un delito, que es un delito grave, o están en violación
               de libertad condicional o libertad condicional impuesta en virtud de una ley federal o estatal, no es elegible para
               asistencia alimentaria.
               </li>
               <li>
               Si es condenado por usar o recibir beneficios de asistencia alimentaria en una transacción
                 que involucre la venta de una sustancia controlada, no será elegible por 24 meses para
                 la primera infracción y permanentemente para la segunda infracción.
               </li>
               <li>
               Si es condenado por un delito federal o estatal que tiene un elemento que
               posesión, uso o distribución de una sustancia controlada, es posible que no sea elegible
               para asistencia alimentaria.
               </li>
             </ul>
             <p>
             Certifico bajo pena de perjurio que mis respuestas a todas las preguntas sobre cada hogar
             miembro, incluidas las relativas a la ciudadanía o la condición de extranjero, son correctas y completas.
             </p>
             <p>
               <strong>Declaración de entendimiento</strong>
             </p>
             <ol>
               <li>
               Entiendo que la información proporcionada por mí en relación con esta solicitud de alimentos
   los beneficios de asistencia estarán sujetos a verificación por parte de funcionarios federales, estatales y locales para
   determinar si dicha información es verdadera. Además, entiendo que si se encuentra dicha información
   ser falso o incorrecto, mi solicitud de beneficios de asistencia alimentaria puede ser denegada o
   rescindido y puedo estar sujeto a enjuiciamiento penal por proporcionar a sabiendas información incorrecta
   información.
               </li>
               <li>
               Entiendo que la información proporcionada es necesaria para determinar mi elegibilidad para recibir asistencia alimentaria. I
   entiendo que toda la información es confidencial y cualquier uso o divulgación sin mi permiso
   se hará sólo para ciertos fines limitados permitidos por las leyes estatales o federales y
   reglamentos Dichos propósitos incluyen, entre otros, establecer la elegibilidad, determinar
   los montos de los beneficios y para brindar servicios a los solicitantes y beneficiarios.
               </li>
               <li>
               Acepto proporcionar la información necesaria para determinar la elegibilidad de mi hogar. Entiendo
   que es posible que tenga que proporcionar documentos para probar lo que he dicho. entiendo que si
   los documentos no están disponibles, tengo que dar el nombre de una persona u organización mi comida
   El trabajador del sello puede ponerse en contacto para obtener la prueba necesaria.
   
               </li>
               <li>
               Certifico a mi leal saber y entender, bajo pena de perjurio, que todos los miembros del hogar
   para quienes se solicitan beneficios son ciudadanos estadounidenses o extranjeros admitidos legalmente en los Estados Unidos
   estados.
   
               </li>
               <li>
               Entiendo que la información proporcionada en esta solicitud se verificará utilizando el Estado
   Sistema de Verificación de Ingresos y Elegibilidad, otros sistemas de cotejo por computadora, programa
   revisiones y auditorias. Además, cualquier información proporcionada también puede ser verificada por otros organismos federales.
   Programas de Ayuda y Programas Estatales con Ayuda Federal tales como almuerzo escolar, Asistencia Familiar,
   y Medicaid. Si alguna información es incorrecta, mi hogar puede ser negado asistencia alimentaria
   beneficios. Si doy información falsa a propósito, se pueden tomar medidas legales o administrativas.
   contra mi. Es posible que tenga que devolver los beneficios de asistencia alimentaria que recibo y a los que no tengo derecho.
               </li>
               <li>
               Entiendo que mi hogar no será elegible para los beneficios si me niego a cooperar en
   completar el proceso de solicitud o en revisiones posteriores de mi elegibilidad, incluyendo
   revisiones resultantes de cambios informados, recertificación o como parte de un estado o federal
   Revisión de control de calidad.
   
               </li>
               <li>
               Entiendo que mi firma en esta solicitud servirá como mi autorización para el Estado y
   Revisores federales de control de calidad para verificar las circunstancias de mi hogar para asistencia alimentaria
   propósitos de elegibilidad.
   
               </li>
               <li>
               Entiendo los requisitos de trabajo y que mi hogar o un miembro del hogar puede ser
   descalificado para recibir beneficios de asistencia alimentaria por falta de cooperación.
               </li>
               <li>
               Entiendo que si algún miembro de mi hogar renuncia voluntariamente a un trabajo o reduce la
   número de horas que trabaja sin una buena causa, mi hogar o un miembro del hogar puede
   ser descalificado para recibir beneficios de asistencia alimentaria.
               </li>
               <li>
               Entiendo que puedo solicitar una conferencia o una audiencia imparcial si no estoy satisfecho con alguna
   decisiones del Departamento del Condado. Esta solicitud puede hacerse oralmente o por escrito. Mi caso
   puede ser presentado en la audiencia por cualquier persona que yo elija.
               </li>
               <li>
               Entiendo que mi hogar no recibirá un aumento en los beneficios de asistencia alimentaria si
   alguien en mi hogar no cumple con los requisitos de otro medio probado
   (Asistencia Familiar, SSI).
               </li>
               <li>
               Entiendo que mi hogar no será elegible para los beneficios si me niego a cooperar en
   completar el proceso de solicitud o en revisiones posteriores de mi elegibilidad, incluyendo
   revisiones resultantes de cambios informados, recertificación o como parte de un estado o federal
   Revisión de control de calidad.
               </li>
               <li>
               Entiendo que algunos beneficiarios de cupones de alimentos sin discapacidad solo serán elegibles para recibir alimentos
   asistencia durante 3 meses en un período de 36 meses, a menos que trabajen 20 horas a la semana o participen en ciertos programas de trabajo y capacitación. Entiendo que mi trabajador me informará si
   esta disposición se aplica a cualquier miembro de mi hogar.
               </li>
               <li>
               Entiendo que no debo: dar información falsa u ocultar información para obtener o continuar
   obtener beneficios de asistencia alimentaria; utilizar los beneficios de asistencia alimentaria de otra persona; o usar comida
   beneficios de asistencia para comprar artículos no elegibles como bebidas alcohólicas o tabaco.
               </li>
               <li>
               Declaro bajo pena de perjurio, que he leído o me han leído todas las declaraciones en el
   solicitud y la información es verdadera y correcta a mi leal saber y entender.
   
               </li>
             </ol>
           </div>
          );
          
      }
    }else{
      switch (language) {
        case languageConsts.ENGLISH:
          return (
          <div>
             <p style={{lineHeight: 0, textAlign: "center"}}>
              <strong>Department of Human Resources (DHR) </strong>
            </p>
            <p style={{textAlign: "center" }}>
              <strong>Notice of Rights, Responsibilities and Penalties </strong>
            </p>
            <p>
              <strong>HHS Nondiscrimination Statement </strong>
            </p>
            <p>
              The Alabama Department of Human Resources complies with applicable
              Federal civil rights laws and does not discriminate on the basis
              of race, color, national origin, age, disability, or sex. The
              Alabama Department of Human Resources does not exclude people or
              treat them differently because of race, color, national origin,
              age, disability, or sex.
            </p>
            <p>The Alabama Department of Human Resources provides:</p>
            <ul style={{ listStyle: "square" }}>
              <li>
                Free aids and services to people with disabilities to
                communicate effectively with us, such as: Braille, large print,
                audiotape, American Sign Language, etc.
              </li>
              <li>Qualified sign language interpreters</li>
              <li>
                Written information in other formats (large print, audio,
                accessible electronic formats, other formats)
              </li>
              <li>
                Free language services to people whose primary language is not
                English, such as: Qualified interpreters and Information written
                in other languages
              </li>
            </ul>
            <p>
              If you need these services, please contact your local county
              office.
            </p>
            <p>
              If you believe that this agency has failed to provide these
              services or discriminated in another way on the basis of race,
              color, national origin, age, disability, or sex, you can file a
              grievance with: The Alabama Department of Human Resources Office
              of Civil Rights, Gordon Persons Building 50 N. Ripley Street,
              Montgomery, Al. 36130-4000, (334)242-1550,{" "}
              <a href="mailto:oeecr@dhr.alabama.gov">oeecr@dhr.alabama.gov</a>.
              You can file a grievance in person, by mail, or email. If you need
              help filing a grievance, The Office of Civil Rights is available
              to help you at (334) 242-1550.
            </p>
            <p>
              You can also file a civil rights complaint with the U.S.
              Department of Health and Human Services, Office for Civil Rights
              electronically through the Office for Civil Rights Complaint
              Portal, available at{" "}
              <a
                href="https://ocrportal.hhs.gov/ocr/portal/lobby.jsf"
                target="_blank"
              >
                {" "}
                https://ocrportal.hhs.gov/ocr/portal/lobby.jsf{" "}
              </a>
              , or by mail or phone at: U.S. Department of Health and Human
              Services, 200 Independence Avenue SW., Room 509F, HHH Building,
              Washington, DC 20201, 1-800-868-1019, 800-537-7697 (TDD).
              Complaint forms are available at{" "}
              <a
                href="https://www.hhs.gov/civil-rights/filing-a-complaint/index.html"
                target="_blank"
              >
                {" "}
                https://www.hhs.gov/civil-rights/filing-a-complaint/index.html
              </a>
            </p>
            <p>
              <strong>USDA Nondiscrimination Statement</strong>
            </p>
            <p>
              In accordance with federal civil rights law and U.S. Department of
              Agriculture (USDA) civil rights regulations and policies, this
              institution is prohibited from discriminating on the basis of
              race, color, national origin, sex (including gender identity and
              sexual orientation), disability, age, or reprisal or retaliation
              for prior civil rights activity.
            </p>
            <p>
              Program information may be made available in languages other than
              English. Persons with disabilities who require alternative means
              of communication to obtain program information (e.g., Braille,
              large print, audiotape, American Sign Language), should contact
              the responsible state or local agency that administers the program
              or USDA’s TARGET Center at (202) 720-2600 (voice and TTY) or
              contact USDA through the Federal Relay Service at (800) 877-8339.
            </p>
            <p>
              To file a program discrimination complaint, a Complainant should
              complete a Form AD-3027, USDA Program Discrimination Complaint
              Form which can be obtained online at:{" "}
              <a
                href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
                target="_blank"
              >
                https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
              </a>
              , from any USDA office, by calling (866) 632-9992, or by writing a
              letter addressed to USDA. The letter must contain the
              complainant’s name, address, telephone number, and a written
              description of the alleged discriminatory action in sufficient
              detail to inform the Assistant Secretary for Civil Rights (ASCR)
              about the nature and date of an alleged civil rights violation.
              The completed AD-3027 form or letter must be submitted to USDA by:
            </p>
            <ol>
              <li>
                {" "}
                mail:U.S. Department of AgricultureOffice of the Assistant
                Secretary for Civil Rights1400 Independence Avenue,
                SWWashington, D.C. 20250-9410; or{" "}
              </li>
              <li>fax:(833) 256-1665 or (202) 690-7442; or</li>
              <li>email:Program.Intake@usda.gov</li>
            </ol>
            <p>This institution is an equal opportunity provider.</p>
            <p>
              <strong>Register to Vote:</strong>
            </p>
            <p>
              <strong>Voter Registration Information </strong>
            </p>
            <a
              href="https://sos.alabama.gov/alabama-votes/voter/register-to-vote"
              target="_blank"
            >
              https://sos.alabama.gov/alabama-votes/voter/register-to-vote
            </a>
            <p>
              The National Voter Registration Act (NVRA) requires the Department
              of Human Resources to provide you with an opportunity to register
              to vote.
            </p>
            <p>
              Applying to register or declining to register to vote will not
              affect the amount of assistance that you will be provided by this
              agency.
            </p>
            <p>
              If you are already registered to vote at your current address, you
              do not need to re-register. If you would like to register to vote
              or would like to change your address for voting purposes, you may
              do so through the Secretary of State’s office at the following
              link:{" "}
              <a href="" target="_blank">
                Secretary of State
              </a>
            </p>
            <p>
              If you would like help in filling out the voter registration form,
              we will help you. You may seek assistance with the application
              form at the time of your interview or by calling your local
              Department of Human Resources office located within your county.
              The decision to seek or accept help is yours. You may fill out the
              application form in private.
            </p>
            <p>
              If you choose to apply to register to vote or if you decline to
              register to vote, the information on your application form will
              remain confidential and will be used for voter registration
              purposes only.
            </p>
            <p>
              If you believe that someone has interfered with your right to
              register or to decline to register to vote, your right to privacy
              in deciding whether to register or in applying to register to
              vote, or your right to choose your own political party or other
              political preference, you may file a complaint with the Secretary
              of State at State Capitol, 600 Dexter Avenue Suite E-208,
              Montgomery, AL 36130 or by calling 334-242-7210 or 1-800-274-VOTE
              (1-800-274-8683).
            </p>
            <br />
          </div>
        );
        case languageConsts.SPANISH:
          return (
            <div>
              <p style={{lineHeight: 0, textAlign: "center"}}>
              <strong>Departamento de Recursos Humanos (DRH) </strong>
            </p>
            <p style={{textAlign: "center" }}>
              <strong> Aviso de Derechos, Responsabilidades y Sanciones </strong>
            </p>
              <p>
                <strong>Declaración de no discriminación del HHS </strong>
              </p>
              <p>
              El Departamento de Recursos Humanos de Alabama cumple con las normas aplicables
                leyes federales de derechos civiles y no discrimina por motivos
                de raza, color, origen nacional, edad, discapacidad o sexo. El
                El Departamento de Recursos Humanos de Alabama no excluye a las personas o
                tratarlos de manera diferente debido a su raza, color, origen nacional,
                edad, discapacidad o sexo.
              </p>
              <p>El Departamento de Recursos Humanos de Alabama proporciona:</p>
              <ul style={{ listStyle: "square" }}>
                <li>
                Ayudas y servicios gratuitos a personas con discapacidad para
                  comunicarse efectivamente con nosotros, tales como: Braille, letra grande,
                  cinta de audio, lenguaje de señas americano, etc.
                </li>
                <li>Qualified sign language interpreters</li>
                <li>
                Información escrita en otros formatos (letra grande, audio,
                  formatos electrónicos accesibles, otros formatos)
                </li>
                <li>
                Servicios lingüísticos gratuitos para personas cuyo idioma principal no es
                  Inglés, tales como: Intérpretes calificados e Información escrita
                  en otros idiomas
                </li>
              </ul>
              <p>
              Si necesita estos servicios, comuníquese con su condado local
                oficina.
              </p>
              <p>
              Si cree que esta agencia no ha proporcionado estos
                servicios o discriminado de otra manera por motivos de raza,
                color, origen nacional, edad, discapacidad o sexo, puede presentar una
                queja con: La Oficina del Departamento de Recursos Humanos de Alabama
                de Derechos Civiles, Edificio Gordon Persons 50 N. Ripley Street,
                Montgomery, Al. 36130-4000, (334)242-1550,{" "}
                <a href="mailto:oeecr@dhr.alabama.gov">oeecr@dhr.alabama.gov</a>.
                Puede presentar una queja en persona, por correo postal o por correo electrónico. Si necesitas
                ayuda para presentar una queja, la Oficina de Derechos Civiles está disponible
                para ayudarte al (334) 242-1550.
              </p>
              <p>
              También puede presentar una queja de derechos civiles ante los EE. UU.
                Departamento de Salud y Servicios Humanos, Oficina de Derechos Civiles
                electrónicamente a través de la Oficina de Quejas de Derechos Civiles
                Portal, disponible en{" "}
                <a
                  href="https://ocrportal.hhs.gov/ocr/portal/lobby.jsf"
                  target="_blank"
                >
                  {" "}
                  https://ocrportal.hhs.gov/ocr/portal/lobby.jsf{" "}
                </a>
                , o por correo o teléfono a: U.S. Department of Health and Human
                Servicios, 200 Independence Avenue SW., Sala 509F, Edificio HHH,
                Washington, DC 20201, 1-800-868-1019, 800-537-7697 (TDD).
                Los formularios de quejas están disponibles en{" "}
                <a
                  href="http://www.hhs.gov/ocr/office/file/index.html"
                  target="_blank"
                >
                  {" "}
                  http://www.hhs.gov/ocr/office/file/index.html
                </a>
              </p>
              <p>
                <strong>USDA Nondiscrimination Statement</strong>
              </p>
              <p>
              De acuerdo con la ley federal de derechos civiles y el Departamento de
                Reglamentos y políticas de derechos civiles de agricultura (USDA), este
                institución tiene prohibido discriminar en base a
                raza, color, origen nacional, sexo (incluida la identidad de género y
                orientación sexual), discapacidad, edad, o represalia o represalia
                por actividad previa de derechos civiles.
              </p>
              <p>
              La información del programa puede estar disponible en otros idiomas que no sean
                Inglés. Personas con discapacidad que requieren medios alternativos
                de comunicación para obtener información del programa (por ejemplo, Braille,
                letra grande, cinta de audio, lenguaje de señas americano), debe comunicarse
                la agencia estatal o local responsable que administra el programa
                o el Centro TARGET del USDA al (202) 720-2600 (voz y TTY) o
                comuníquese con USDA a través del Servicio Federal de Retransmisión al (800) 877-8339.
              </p>
              <p>
              Para presentar una queja por discriminación en el programa, el Demandante debe
                completar un Formulario AD-3027, Queja de Discriminación del Programa USDA
                Formulario que se puede obtener en línea en:{" "}
                <a
                  href="https://www.usda.gov/sites/default/files/documents/ad-3027.pdf"
                  target="_blank"
                >
                  https://www.usda.gov/sites/default/files/documents/ad-3027.pdf
                </a>
                , desde cualquier oficina del USDA, llamando al (866) 632-9992, o escribiendo un
                carta dirigida al USDA. La carta debe contener la
                el nombre, la dirección, el número de teléfono y un escrito del denunciante
                descripción de la supuesta acción discriminatoria en suficiente
                detalle para informar a la Subsecretaria de Derechos Civiles (ASCR)
                sobre la naturaleza y la fecha de una supuesta violación de los derechos civiles.
                El formulario o carta AD-3027 completo debe enviarse al USDA antes de:
              </p>
              <ol>
                <li>
                  {" "}
                  correo: EE. UU. Departamento de AgriculturaOficina del Asistente
                  Secretario de Derechos Civiles 1400 Independence Avenue,
                  SW Washington, DC 20250-9410; o{" "}
                </li>
                <li>fax:(833) 256-1665 or (202) 690-7442; or</li>
                <li>email:Program.Intake@usda.gov</li>
              </ol>
              <p>Esta institución es un proveedor de igualdad de oportunidades.</p>
              <p>
                <strong>Registrarse para votar:</strong>
              </p>
              <p>
                <strong>Información de registro de votantes </strong>
              </p>
              <a
                href="https://sos.alabama.gov/alabama-votes/voter/register-to-vote"
                target="_blank"
              >
                https://sos.alabama.gov/alabama-votes/voter/register-to-vote
              </a>
              <p>
              La Ley Nacional de Registro de Votantes (NVRA) requiere que el Departamento
                de Recursos Humanos para brindarle la oportunidad de registrarse
                votar.
              </p>
              <p>
              Solicitar registrarse o negarse a registrarse para votar no
                afectará la cantidad de asistencia que recibirá de este
                agencia.
              </p>
              <p>
              Si ya está registrado para votar en su dirección actual, puede
                no es necesario volver a registrarse. Si desea registrarse para votar
                o le gustaría cambiar su dirección para votar, puede
                hacerlo a través de la oficina del Secretario de Estado en el siguiente
                enlace:{" "}
                <a href="" target="_blank">
                Secretaria de estado
                </a>
              </p>
              <p>
              Si desea ayuda para completar el formulario de registro de votantes,
                te ayudaremos. Puede buscar ayuda con la aplicación
                formulario en el momento de su entrevista o llamando a su
                Oficina del Departamento de Recursos Humanos ubicada dentro de su condado.
                La decisión de buscar o aceptar ayuda es suya. Puede llenar el
                formulario de solicitud en privado.
              </p>
              <p>
              Si elige solicitar registrarse para votar o si se niega a
                registrarse para votar, la información en su formulario de solicitud
                permanecerán confidenciales y se utilizarán para el registro de votantes
                propósitos solamente.
              </p>
              <p>
              Si cree que alguien ha interferido con su derecho a
                registrarse o negarse a registrarse para votar, su derecho a la privacidad
                en la decisión de registrarse o en la solicitud de registro para
                votar, o su derecho a elegir su propio partido político u otro
                preferencia política, puede presentar una queja ante el Secretario
                del Estado en el Capitolio del Estado, 600 Dexter Avenue Suite E-208,
                Montgomery, AL 36130 o llamando al 334-242-7210 o al 1-800-274-VOTE
                (1-800-274-8683).
              </p>
              <br />
            </div>
          );
        case languageConsts.PORTUGUESE:
          return (
            <div>
              <p style={{ textAlign: "center", lineHeight: "0" }}>
                <strong>
                  Departamento de Assistência Transitória (DHR) Notificação de
                  direitos,{" "}
                </strong>
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>responsabilidades e penalidades</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Esta notificação lista os direitos e as responsabilidades para
                  todos os programas do DHR. Você deve seguir as regras dos
                  programas para os quais se inscreve.
                </li>
                <li>Leia estas páginas e guarde-as em seus registros.</li>
                <li>Em caso de dúvidas, informe o DHR.</li>
              </ul>
  
              <p>
                <strong>Juro, sob pena de perjúrio, que:</strong>
              </p>
              <ul>
                <li>
                  Li as informações contidas neste formulário, ou alguém leu essas
                  informações para mim.
                </li>
                <li>
                  Minhas respostas neste formulário são verdadeiras e completas,
                  de acordo com o meu conhecimento.
                </li>
                <li>
                  Fornecerei ao DHR informações verdadeiras e completas, de acordo
                  com o meu conhecimento, durante a minha entrevista e em qualquer
                  evento futuro.
                </li>
              </ul>
              <p>
                <strong>Entendo que:</strong>
              </p>
              <ul>
                <li>fornecer informações falsas ou enganosas é fraude;</li>
                <li>
                  deturpar ou omitir fatos para obter benefícios do DHR é fraude;
                </li>
                <li>
                  fraude é considerada uma Violação Intencional do Programa (IPV);
                  e
                </li>
                <li>
                  se o DHR achar que cometi fraude, poderá entrar com processo
                  civil e criminal contra mim.
                </li>
              </ul>
              <p>
                <strong>Também entendo que:</strong>
              </p>
              <ul>
                <li>
                  O DHR verificará as informações que eu fornecer em minha
                  solicitação. Caso qualquer informação fornecida seja falsa, o
                  DHR poderá negar meus benefícios.
                </li>
                <li>
                  Também estarei sujeito(a) a processo criminal por fornecer
                  informações falsas.
                </li>
                <li>
                  Se o DHR obtiver informações de uma fonte confiável sobre uma
                  mudança em minha família, o valor do meu benefício poderá mudar.
                </li>
                <li>
                  Ao assinar este formulário, autorizo o DHR a verificar a minha
                  elegibilidade aos benefícios, incluindo:
                  <ul>
                    <li>
                      Obter informações de outros órgãos estaduais ou federais,
                      autoridades locais de habitação, departamentos de
                      assistência social fora do estado, instituições financeiras
                      e Equifax Workforce Solutions (the Work Number). Também
                      autorizo esses órgãos a compartilhar informações sobre a
                      elegibilidade da minha família aos benefícios do DHR.
                    </li>
                    <li>
                      Se o DHR usar as informações do Equifax para averiguar a
                      minha renda familiar, tenho o direito de receber uma cópia
                      gratuita do relatório da Equifax, caso solicite dentro de 60
                      dias contados a partir da data da decisão do DHR. Tenho
                      direito de contestar as informações contidas no relatório.
                      Poderei contatar a Equifax no endereço: Equifax Workforce
                      Solutions, 11432 Lackland Road, St. Louis, MO 63146, ou
                      ligando para 1-800-996-7566 (ligação gratuita).
                    </li>
                  </ul>
                </li>
                <li>
                  Tenho direito de receber uma cópia da minha solicitação,
                  incluindo as informações que o DHR utiliza para decidir sobre a
                  elegibilidade da minha família e o valor do benefício. Posso
                  solicitar ao DHR uma cópia eletrônica da solicitação preenchida.
                </li>
              </ul>
  
              <p>
                <strong>Como o DHR usará as minhas informações? </strong>
              </p>
  
              <p>
                Ao assinar abaixo, autorizo o DHRa coletare compartilhar
                informações sobre mim e os membros da minha família com:
                                 
              </p>
              <ul>
                <li>
                  Bancos, escolas, governo, empregadores, senhorios, empresas e
                  órgãos de serviços públicos paraverificar a minha elegibilidade
                  aos benefícios.
                </li>
                <li>
                  Empresas de fornecimento de energia elétrica, gás e telefonia
                  para que eu possa obter descontos. As empresas não podem
                  compartilhar ouusar minhas informações para qualquer outra
                  finalidade.
                </li>
                <li>
                  O Departamento da Habitação e Desenvolvimento da Comunidade,
                  para que eu possa me inscrever no Programa Heat &amp; Eat
                  (Alimentação e Aquecimento). Este programa ajuda as pessoas a
                  desfrutarem ao máximo dos seus benefícios SNAP.
                </li>
                <li>
                  O Departamento de Educação Infantil e Secundária, para que meus
                  filhos possam receber merendas gratuitas.              
                </li>
                <li>
                  O Programa Mulheres, Bebês e Crianças (WIC) , para que qualquer
                  criança menor de 5 anos ou gestantes em minha família possam
                  obter o benefício WIC.
                </li>
                <li>
                  O Serviço de Cidadania e Imigração dos Estados Unidos (USCIS),
                  para verificar meu status imigratório. As informações do USCIS
                  podem afetar a elegibilidade da minha família e o valor dos
                  benefícios do DHR.
                </li>
              </ul>
              <p style={{ paddingLeft: "40px" }}>
                <em>
                  Nota: Mesmo que você não seja elegível aos benefícios do DHR
                  devido ao status imigratório, o DHR não denunciará você às
                  autoridades de imigração a menos que você apresente ao DHR uma
                  sentença final de deportação.{" "}
                </em>
              </p>
              <ul>
                <li>
                  A Receita Federal (DOR), para verificar minha elegibilidade para
                  receber créditos fiscais baseados na renda — como renda auferida
                  e renda limitada — e para ver se sou elegível ao status de
                  “Isenção de impostos” ou pobreza.
                </li>
                <li>
                  O Departamento de Crianças e Famílias (DCF), para coordenar os
                  serviços oferecidos em conjunto pelo DHR e DCF.
                </li>
              </ul>
              <p>
                <strong>Como o DHR usa os dados do seguro social (S</strong>
                <strong>S</strong>
                <strong>Ns)? </strong>
              </p>
  
              <p>
                De acordo com a Lei de Alimentos e Nutrição de 2008 (7 U.S.C.
                2011-2036) e nos termos do M.G.L.c. 18 Seção 33 do TAFDC e EAEDC,
                o DHR tem permissão para solicitar SSNs para o benefício SNAP. O
                DHR usa os SSNs para:
              </p>
  
              <ul>
                <li>
                  Verificar a identidade e elegibilidade de cada membro da família
                  que estiver se inscrevendo, por meio de programas de
                  correspondência de dados.
                </li>
                <li>Monitorar a conformidade com as regras do programa.</li>
                <li>
                  Receber dinheiro, se o DHR alegar que recebi benefícios para os
                  quais não era elegível.
                </li>
                <li>
                  Ajudar as agências de aplicação da lei a pegar os fugitivos da
                  lei.
                </li>
              </ul>
  
              <p>
                Entendo que não preciso informar ao DHR o SSN de nenhum membro da
                família que não seja cidadão americano, incluindo eu mesmo(a), e
                que não esteja solicitando benefícios. A renda de um membro que
                não é cidadão americano pode ser levada em consideração, mesmo que
                esse membro não obtenha os benefícios.
              </p>
  
              <p>
                <strong>Direito a um intérprete</strong>
              </p>
              <p>Entendo que:</p>
              <ul>
                <li>
                  Tenho direito aos serviços gratuitos de um intérprete
                  profissional, oferecido pelo DHR, caso prefira me comunicar em
                  outro idioma diferente do inglês.
                </li>
                <li>
                  Se tiver uma audiência do DHR marcada, posso pedir para que o
                  DHR providencie, sem custo, um intérprete profissional ou, se eu
                  preferir, posso trazer alguém para ser meu intérprete. Se eu
                  precisar que o DHR providencie um intérprete profissional para
                  minha audiência, devo ligar para o Departamento de Audiências
                  com pelo menos uma semana de antecedência à data da audiência.
                </li>
              </ul>
  
              <p>
                <strong>Direito ao registro eleitoral</strong>
              </p>
              <p>Entendo que:</p>
              <ul>
                <li>
                  Tenho o direito de fazer meu registro eleitoral através do DHR.
                </li>
                <li>
                  Se precisar de ajuda, o DHR me ajudará a preencher o formulário
                  de registro de eleitor.
                </li>
                <li>
                  Posso preencher meu formulário de registro de eleitor de forma
                  privada.
                </li>
                <li>
                  O fato de optar por fazer ou não o meu registro de eleitor não
                  afetará meus benefícios do DHR.
                </li>
              </ul>
  
              <p>
                <strong>Oportunidades de emprego</strong>
              </p>
              <p>
                Concordo que o DHR poderá compartilhar meu nome e minhas
                informações de contato com agências de emprego e treinamento,
                incluindo:
              </p>
              <ul>
                <li>
                  Provedores SNAP Path Work ou especialistas do DHR para clientes
                  SNAP; e
                </li>
                <li>
                  Contracted Employment and Training (agências de emprego e
                  treinamento por contrato) ou Full Engagement Workers
                  (funcionários contratados em período integral) para clientes
                  TAFDC.
                </li>
              </ul>
  
              <p>
                Os clientes SNAP podem, voluntariamente, participar de serviços de
                formação e treinamento profissional através do programa SNAP Path
                to Work.
              </p>
  
              <p>
                <strong>Status de cidadania</strong>
              </p>
              <p>
                Juro que todos os membros da minha família que se candidatam aos
                benefícios do DHR são cidadãos dos EUA ou residentes legais no
                país.
              </p>
  
              <p style={{ textAlign: "center" }}>
                <strong>Programa de Assistência Nutricional Suplementar </strong>
              </p>
              <p>
                <strong>Entendo que:</strong>
              </p>
              <ul>
                <li>O DHR administra o programa SNAP em Alabama.</li>
                <li>
                  Após me candidatar aos benefícios do DHR (por telefone, pela
                  internet, de forma presencial, via fax ou correio), o DHR tem 30
                  dias contados a partir da data do recebimento da minha
                  solicitação para decidir se sou elegível.
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Caso seja elegível a receber o SNAP emergencial, o DHR
                      precisa fornecer o SNAP e se certificar de que eu receba o
                      cartão EBT (Transferência Eletrônica de Benefícios) dentro
                      de 7 dias contados a partir da data de recebimento da minha
                      solicitação.
                    </li>
                    <li>Tenho o direito de falar com um supervisor do DHR se:</li>
                    <li style={{ listStyle: "none" }}>
                      <ul style={{ listStyle: "square" }}>
                        <li>
                          O DHR afirmar que não sou elegível aos benefícios do
                          SNAP emergencial e eu discordar da decisão.
                        </li>
                        <li>
                          Eu for elegível aos benefícios do SNAP emergencial, mas
                          não tiver acesso a esses benefícios até 7 dias após a
                          data de solicitação do SNAP.
                        </li>
                        <li>
                          Se eu for elegível aos benefícios do SNAP emergencial,
                          mas não receber meu cartão EBT em até 7 dias após ter
                          solicitado o SNAP.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Quando obtenho os benefícios SNAP, tenho que cumprir certas
                  regras. Após ser aprovado(a) para o SNAP, o DHR me entregará uma
                  cópia do livreto “Your Right to Know” (Seu direito de saber) e
                  do livreto sobre o programa SNAP.  Eu me comprometo a ler os
                  livretos ou a solicitar que alguém os leia para mim.  Em caso de
                  dúvidas, ou se eu precisar de ajuda para ler ou compreender
                  essas informações, posso ligar para o DHR no número
                  1-877-382-2363.
                </li>
              </ul>
  
              <ul>
                <li>
                  <strong>
                    Informar ao DHR sobre mudanças em minha família:
                  </strong>
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Se a minha família se enquadra no SNAP Simplified Reporting
                      (um sistema simplificado de fornecimento de informações), eu
                      não preciso informar ao DHR a maioria das mudanças até o
                      momento do Interim Report (também conhecido como “relatório
                      provisório”) ou a recertificação. As únicas coisas que
                      preciso relatar o quanto antes são:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>
                          Se a minha renda familiar ultrapassar o limite da renda
                          bruta (listada na minha notificação de aprovação).
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Essa informação deverá ser relatada até o 10º dia do
                              mês seguinte àquele em que a minha renda ultrapassou
                              o limite.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Se eu me enquadrar nos requisitos de trabalho de adulto
                          fisicamente capaz sem dependentes (ABAWD, ou Able-Bodied
                          Adult Without Dependents) e as minhas horas de trabalho
                          forem reduzidas para menos de 20 horas semanais.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Se todos em minha família tiverem 60 anos ou mais, forem
                      deficientes ou menores de 18 anos, e não recebem salário, as
                      únicas coisas que preciso relatar são:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>Se alguém começar a trabalhar; ou</li>
                        <li>
                          Se alguém passar a morar comigo ou sair da minha casa.
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Essas mudanças deverão ser relatadas até o 10º dia
                              do mês seguinte àquele em que ocorreu a mudança.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Se eu obtiver o SNAP através do Transitional Benefits
                      Alternative (TBA, a alternativa para benefícios
                      transicionais) devido a interrupção do meu TAFDC, não
                      precisarei informar quaisquer mudanças ao DHR nos 5 meses em
                      que obtiver o TBA.
                    </li>
                    <li>
                      Se eu obtiver o SNAP através do Bay State CAP, não
                      precisarei relatar nenhuma mudança ao DHR.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Caso eu ou qualquer membro da minha família obtenha auxílio
                financeiro (TAFDC ou EAEDC), precisarei reportar algumas mudanças
                ao DHR dentro do prazo de 10 dias após a mudança. Consulte{" "}
                <strong>
                  Quando preciso informar ao DHR sobre as mudanças em minha
                  família?
                </strong>{" "}
                nas seções{" "}
                <strong>
                  Auxílio de transição para famílias com filhos dependentes
                  (TAFDC) e Auxílio de emergência a idosos, portadores de
                  deficiência e crianças (EAEDC){" "}
                </strong>
                abaixo.
              </p>
              <p>
                Posso obter mais benefícios SNAP se relatar e fornecer ao DHR, a
                qualquer momento, evidências sobre o seguinte:
              </p>
              <ul>
                <li>
                  Despesas com cuidados infantis ou outros dependentes, abrigo
                  e/ou serviços públicos;
                </li>
                <li>
                  Pensão alimentícia que eu (ou alguém da minha família) é
                  legalmente obrigado a pagar a um membro que não faz parte da
                  família; e
                </li>
                <li>
                  Despesas médicas dos membros da minha família, incluindo eu, com
                  60 anos ou mais ou portadores de deficiência.
                </li>
              </ul>
  
              <p>
                <strong>Regras de trabalho para clientes SNAP:</strong>  Se você
                recebe benefícios SNAP e tem entre 16 e 59 anos, terá que cumprir
                com as regras gerais de trabalho do SNAP ou ABAWD — a menos que
                seja isento(a). O DHR informará se eu ou os membros da minha
                família precisaremos cumprir com quaisquer regras de trabalho,
                quais são as isenções, e o que acontecerá caso as regras não sejam
                cumpridas. 
              </p>
              <p>Se você está sob as Regras de trabalho do SNAP: </p>
              <ul>
                <li>
                  Você deve se registrar para trabalhar no momento da inscrição e
                  da recertificação para o SNAP. O seu registro é feito ao assinar
                  o formulário de inscrição ou de recertificação do SNAP.
                </li>
                <li>
                  Quando solicitado, você deverá fornecer ao DHR informações sobre
                  seu status de emprego.
                </li>
                <li>
                  Você deve se reportar a um empregador se este tiver sido
                  indicado pelo DHR.
                </li>
                <li>
                  Você deve aceitar uma oferta de emprego (a menos que tenha uma
                  boa razão para não fazê-lo).
                </li>
                <li>
                  Você não pode sair de um emprego de mais de 30 horas por semana
                  sem uma boa razão.
                </li>
                <li>
                  Você não pode reduzir suas horas de trabalho para menos de 30
                  horas por semana sem uma boa razão.
                </li>
              </ul>
  
              <p>
                <strong>Regras do SNAP</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Não forneça informações falsas ou oculte informações para obter
                  os benefícios SNAP.
                </li>
                <li>Não troque ou venda os benefícios SNAP.</li>
                <li>
                  Não altere os cartões EBT para receber benefícios SNAP dos quais
                  não é elegível.
                </li>
                <li>
                  Não use os benefícios SNAP para comprar itens não elegíveis,
                  como bebidas alcoólicas ou tabaco.
                </li>
                <li>
                  Não use os benefícios SNAP ou o cartão EBT de outra pessoa, a
                  menos que seja o representante autorizado dessa pessoa ou que o
                  destinatário do benefício tenha autorizado você a usar o cartão
                  em seu nome.
                </li>
              </ul>
  
              <p>
                <strong>Avisos de penalidade do SNAP</strong>
              </p>
              <p>
                Entendo que se eu ou qualquer membro da minha família SNAP
                intencionalmente violar qualquer uma das regras listadas acima,
                essa pessoa não será elegível a receber o SNAP por <em>um ano</em>{" "}
                após a primeira violação, <em>dois anos</em> após a segunda
                violação e <em>perpetuamente</em> após a terceira violação. Essa
                pessoa também poderá ser multada em até US$ 250.000, estar sujeita
                a até 20 anos de prisão ou ambos. Também pode estar sujeita a
                processos judiciais de acordo com as leis federais e estaduais.
              </p>
  
              <p>
                Declaro que também entendo as seguintes penalidades. Se eu ou um
                membro de minha família SNAP:
              </p>
              <ul>
                <li>
                  Cometer uma IPV (Violação Intencional do Programa) do{" "}
                  <strong>programa de auxílio financeiro, </strong>seremos
                  inelegíveis ao SNAP pelo mesmo período de inelegibilidade ao
                  auxílio financeiro.
                </li>
                <li>
                  Fizer uma declaração fraudulenta de identidade ou residência
                  para obter vários benefícios do SNAP <em>ao mesmo tempo</em>,
                  seremos inelegíveis ao SNAP por{" "}
                  <strong>
                    <em>dez anos</em>
                  </strong>
                  .
                </li>
                <li>
                  Trocar (comprar ou vender) benefícios do SNAP por uma substância
                  controlada/drogas ilegais, seremos inelegíveis ao SNAP por{" "}
                  <strong>
                    <em>dois anos</em>
                  </strong>{" "}
                  na primeira incidência, e{" "}
                  <strong>
                    <em>permanentemente</em>
                  </strong>{" "}
                  na segunda incidência.
                </li>
                <li>
                  Trocar (comprar ou vender) benefícios do SNAP por armas de fogo,
                  munições ou explosivos, seremos inelegíveis ao SNAP{" "}
                  <strong>
                    <em>permanentemente</em>
                  </strong>
                  .
                </li>
                <li>
                  Fizer uma oferta para vender benefícios do SNAP ou um cartão EBT
                  online ou pessoalmente, o Estado pode reivindicar uma IPV contra
                  o autor da oferta.
                </li>
                <li>
                  Pagar por alimentos comprados a crédito, seremos inelegíveis ao
                  SNAP.
                </li>
                <li>
                  Comprar produtos usando os benefícios do SNAP com a intenção de
                  descartar o conteúdo e retornar as embalagens em troca de
                  dinheiro, seremos inelegíveis ao SNAP.
                </li>
                <li>
                  Fugir para evitar acusação, custódia ou confinamento após a
                  condenação por um crime, seremos inelegíveis ao SNAP.
                </li>
                <li>
                  Violar uma condicional ou período probatório, e estiver sendo
                  ativamente procurado pelas autoridades para receber voz de
                  prisão, seremos inelegíveis ao SNAP.
                </li>
              </ul>
              <p>
                Qualquer pessoa condenada por um crime após 7 de fevereiro de 2014
                não será elegível aos benefícios do SNAP se for um fugitivo da
                polícia ou se estiver violando uma condicional ou um período
                probatório (de acordo com a 7 CFR §273.11(n)){" "}
                <strong>
                  <u>e</u>
                </strong>{" "}
                tiver sido condenada como adulto por:
              </p>
              <ol>
                <li>
                  Abuso sexual agravado, nos termos da seção 2241, título 18 do
                  U.S.C. (Código de Leis dos Estados Unidos);
                </li>
                <li>
                  Assassinato, nos termos da seção 1111, título 18 do U.S.C.
                  (Código de Leis dos Estados Unidos);
                </li>
                <li>
                  Qualquer crime nos termos do capítulo 110, título 18 do U.S.C.
                  (Código de Leis dos Estados Unidos);
                </li>
                <li>
                  Um crime federal ou estadual envolvendo abuso sexual, conforme
                  definido na seção 40002(a) do VAWA de 1994 (42 U.S.C. 13925a);
                  ou
                </li>
                <li>
                  Infração ao abrigo da lei estadual determinada pelo
                  Procurador-Geral como substancialmente semelhante a um crime
                  descrito nesta lista.
                </li>
              </ol>
  
              <p>
                <strong>Declaração de não-discriminação</strong>
              </p>
              <p>
                De acordo com as leis civis federais e os regulamentos civis e
                políticas do Departamento de Agricultura dos Estados Unidos
                (USDA), suas agências, escritórios, funcionários e instituições
                que participam ou administram programas do USDA estão proibidos de
                exercer discriminação com base em raça, cor, nacionalidade, sexo,
                religião, deficiência, idade, crenças políticas, ou represálias ou
                retaliações por atividades anteriores de direitos civis, em
                qualquer programa ou atividade conduzida ou financiada pelo USDA.
              </p>
              <p>
                Pessoas com deficiências que requerem meios alternativos de
                comunicação para informações sobre o programa (por exemplo,
                braile, letras grandes, áudio, língua americana de sinais etc.)
                devem entrar em contato com a agência (estadual ou local) onde se
                candidataram aos benefícios. Pessoas com deficiência auditiva ou
                de fala devem entrar em contato com o USDA através do Serviço
                Federal de Relay pelo telefone 1-800-877-8339. Além disso, as
                informações do programa podem ser fornecidas em outros idiomas
                além do inglês.
              </p>
              <p>Para apresentar uma denúncia de discriminação no programa:</p>
              <ul>
                <li>
                  Preencha o Formulário de Denúncia de Discriminação do Programa
                  do USDA (AD-3027) disponível on-line em{" "}
                  <a
                    href="https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf"
                    target="_blank"
                  >
                    https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf
                  </a>{" "}
                  e em qualquer escritório do USDA. Você pode solicitar uma cópia
                  do formulário de denúncia ligando para 1-866-632-9992; ou
                </li>
                <li>
                  Escreva uma carta endereçada ao USDA e mencione todas as
                  informações solicitadas no formulário.
                </li>
              </ul>
              <p>Envie seu formulário preenchido ou carta para o USDA:</p>
              <ul>
                <li>
                  por correio: U.S. Department of Agriculture Office of the
                  Assistant Secretary for Civil Rights 1400 Independence Avenue SW
                  Washington, D.C.20250-9410; ou
                </li>
                <li>por fax: 1-202-690-7442; ou</li>
                <li>
                  por e-mail:{" "}
                  <a href="mailto:program.intake@usda.gov">
                    program.intake@usda.gov
                  </a>
                </li>
              </ul>
              <p>Esta instituição é um provedor de oportunidades iguais.</p>
              <p style={{ textAlign: "center" }}>
                <strong>
                  Auxílio de transição para famílias com filhos dependentes
                  (TAFDC) e{" "}
                </strong>
                <br />
                <strong>
                  Auxílio de emergência a idosos, portadores de deficiência e
                  crianças (EAEDC){" "}
                </strong>
              </p>
  
              <p>
                O TAFDC e o EAEDC são programas de assistência financeira. Acesse
                DHRConnect.com ou ligue para o escritório DHR local para obter
                mais informações e se inscrever nos programas. Essas informações
                se aplicam apenas às famílias que estão solicitando ou que recebem
                o TAFDC ou EAEDC.
              </p>
  
              <p>
                <strong>
                  Quando preciso informar ao DHR sobre as mudanças em minha
                  família?
                </strong>
              </p>
              <p>
                Devo informar ao DHR sobre as mudanças que podem afetar meu TAFDC
                ou EAEDC (benefícios financeiros em dinheiro){" "}
                <strong>dentro de 10 dias; </strong>não preciso informar ao DHR
                sobre mudanças em meus ganhos, caso sejam inferiores a US$ 100 por
                mês. Isso inclui mudanças em minha renda, endereço, com quem moro,
                tamanho da família, trabalho e seguro saúde.
              </p>
  
              <p>
                <strong>Como faço para obter seguro saúde? </strong>
              </p>
              <ul>
                <li>
                  Se eu já tenho TAFDC ou EAEDC, também terei a cobertura do
                  MassHealth.
                </li>
                <li>
                  Se o benefício TAFDC ou EAEDC tiver sido negado, o MassHealth
                  usará minhas informações para determinar se souelegível ao
                  seguro saúde.  
                </li>
                <li>
                  Se o meu benefício EAEDC for suspenso, terei que solicitar o
                  seguro saúde MassHealth separadamente. Para fazer a solicitação,
                  ligue para 1-800-841-2900.
                </li>
              </ul>
  
              <p>
                 Se eu tiver o seguro saúde MassHealth, concordo que o MassHealth
                colete:
              </p>
              <ul>
                <li>
                  dinheiro devido a mim de outras fontes, para meus cuidados
                  médicos, e
                </li>
                <li>
                  autorização médica do pai/mãe ausente de qualquer criança menor
                  de 19 anos que receba os benefícios do MassHealth.
                </li>
              </ul>
  
              <table style={{ border: "1px solid black" }}>
                <tbody>
                  <tr>
                    <td width="626">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <p>
                                <strong>
                                  Existem regras especiais se eu for elegível{" "}
                                  <u>apenas</u> devido a um acidente ou lesão?{" "}
                                </strong>
                              </p>
                              <p>
                                Se a minha família obtiver benefícios do
                                MassHealth ou DHR devido a um acidente ou lesão,
                                devo usar todo o dinheiro que receber pelo
                                acidente ou lesão para reembolsá-los. O dinheiro
                                pode vir de uma apólice de seguro, um acordo ou
                                qualquer outra fonte. Isso se aplica mesmo que eu
                                ainda não saiba quais são as possíveis fontes do
                                dinheiro.
                              </p>
  
                              <p>
                                Concordo em colaborar com o MassHealth e o DHR
                                para:
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Apresentar uma reclamação para
                                receber dinheiro de outras fontes.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Informar imediatamente ao
                                MassHealth e ao DHR sobre-qualquer reclamação de
                                seguro, ação judicial ou outro processo para obter
                                dinheiro.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Fornecer novas informações ao
                                MassHealth e ao DHR tão logo as obtiver.
                              </p>
  
                              <p>
                                Se eu não colaborar, o MassHealth e o DHR podem
                                suspender ou negar meus benefícios. Autorizo o
                                MassHealth e o DHR a:           
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Compartilhar informações sobre
                                meus benefícios a fim de coletar dinheiro para
                                reembolsar esses benefícios.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Ver todos os registros
                                relacionados ao dinheiro que posso vir a receber
                                em decorrência de acidente ou lesão, como os
                                registros do Departamento de Acidentes
                                Industriais.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                       
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <p>
                Se estiver recebendo EAEDC em função de deficiência ou porque
                tenho mais de 65 anos, preciso solicitar os benefícios da Renda de
                Segurança Suplementar (SSI). Se a minha solicitação dos benefícios
                da SSI, que abrange o mesmo período que recebi o EAEDC, for
                aprovada, a Administração da Previdência Social enviará parte da
                minha SSI retroativa ao DHR para reembolsar o EAEDC.
              </p>
  
              <p>
                <strong>Aviso importante sobre a lei e seus benefícios</strong>
              </p>
              <p>
                A Violação Intencional do Programa (IPV) significa fornecer
                intencionalmente uma declaração falsa ou enganosa ou deturpar,
                ocultar ou reter fatos — verbalmente ou por escrito — a fim de
                estabelecer ou manter a elegibilidade dos benefícios TAFDC ou
                EAEDC, ou para obter benefícios aos quais não tenho direito.
              </p>
              <p>
                Se eu for considerado(a) culpado(a) de uma IPV por um tribunal,
                uma audiência administrativa de desqualificação ou ao assinar uma
                renúncia voluntária de direitos, serei desqualificado(a) para
                receber os benefícios TAFDC ou EAEDC por um período de:
              </p>
  
              <ul>
                <li>6 meses para a primeira violação</li>
                <li>12 meses para a segunda violação</li>
                <li>permanentemente para a terceira violação</li>
              </ul>
  
              <p>Além disso, outras leis podem ser aplicadas.</p>
  
              <p>
                <strong>Compras não permitidas com o cartão EBT</strong>
              </p>
              <p>
                Entendo que é ilegal usar os fundos TAFDC ou EAEDC creditados em
                um cartão de transferência eletrônica de benefícios (EBT) para
                pagar por: bebidas alcoólicas; produtos originados do tabaco;
                bilhetes de loteria; materiais ou apresentações voltadas para
                público adulto; jogatinas; armas de fogo e munições; serviços de
                férias; tatuagens; piercings corporais; joias; televisores;
                aparelhos de som; videogames ou consoles em lojas próprias;
                maconha recreativa; taxas de ordem judicial; multas; fiança ou
                garantias.  
              </p>
  
              <p>
                <strong>Onde é proibido usar meu cartão EBT</strong>
              </p>
              <p>
                Entendo que é ilegal usar meu cartão de transferência eletrônica
                de benefícios (EBT) nos seguintes locais: livrarias para adultos;
                lojas de parafernália para adultos ou casas de espetáculos
                voltados para adultos; revendedores de munições; cassinos;
                cassinos de jogos de azar ou estabelecimentos de jogos; cruzeiros;
                revendedores de armas de fogo; joalherias; lojas de bebidas;
                manicures ou centros de estética; agências de envio de dinheiro
                para outros países; lojas de maconha recreativa ou estúdios de
                tatuagem.
              </p>
  
              <p>
                <strong>
                  Penalidades para compras proibidas em dinheiro com cartão EBT
                </strong>
              </p>
              <ul>
                <li>
                  <strong>Primeira violação:</strong> Devo reembolsar o valor
                  gasto ao DHR.
                </li>
                <li>
                  <strong>Segunda violação:</strong> Devo reembolsar o valor gasto
                  ao DHR e terei meu benefício financeiro suspenso por{" "}
                  <strong>dois meses</strong>.
                </li>
                <li>
                  <strong>Terceira violação</strong>: devo reembolsar o valor
                  gasto ao DHR e terei meu benefício financeiro suspenso{" "}
                  <strong>permanentemente</strong>.
                </li>
              </ul>
            </div>
          );
  
        case languageConsts.CHINESE:
          return (
            <div>
              <p style={{ textAlign: "center" }}>
                <strong>过渡援助部（</strong>
                <strong>DHR）关于权利、责任和处罚的通知</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  本通知列出了所有DHR计划的权利和责任。您必须遵守您所申请的计划相关规则。
                </li>
                <li>请阅读这些页面上的内容，并妥善保存作为记录。</li>
                <li>如有任何疑问，请联系DHR。</li>
              </ul>
  
              <p>
                <strong>我对以下内容发誓，否则将承担伪证罪的责任：</strong>
              </p>
              <ul>
                <li>我已阅读此表格中的信息，或已有人为我读出了该信息。</li>
                <li>就我所知，我在此表格中给出的答案是真实完整的。</li>
                <li>
                  在我的面试中以及将来，我将尽我所知为DHR提供真实完整的信息。
                </li>
              </ul>
              <p>
                <strong>我理解：</strong>
              </p>
              <ul>
                <li>提供虚假或误导性信息是欺诈行为，</li>
                <li>通过歪曲或隐瞒事实来获得DHR福利是欺诈行为，</li>
                <li>欺诈被视为“蓄意违反计划”（IPV），并且</li>
                <li>如果DHR认为我犯了欺诈罪，DHR可以对我进行民事和刑事处罚。</li>
              </ul>
              <p>
                <strong>我也理解：</strong>
              </p>
              <ul>
                <li>
                  DHR将核实我在申请表中提供的信息。如有任何虚假信息，DHR可能会拒绝为我提供福利。
                </li>
                <li>我也可能会因为提供虚假信息而受到刑事起诉。</li>
                <li>
                  如果DHR从可靠来源了解到我家庭情况变化的信息，我的福利金额可能会发生变化。
                </li>
                <li>
                  签署此表格即表示，我允许DHR核实我获得福利的资格条件，包括：
                  <ul>
                    <li>
                      从其他州或联邦机构、地区住房管理局、外州福利部门、金融机构，以及Equifax
                      Workforce
                      Solutions（工作号码）获取信息。我也授权这些机构分享有关我的家庭成员获得DHR福利资格的信息。
                    </li>
                    <li>
                      如果DHR使用Equifax提供的我的家庭收入信息，则我有权在DHR做出决定后的60天内索取一份免费的Equifax报告。我有权质疑报告中的信息。我可以通过以下方式联系Equifax：Equifax
                      Workforce Solutions, 11432 Lackland Road, St. Louis, MO
                      63146,1-800-996-7566（免费电话）。
                    </li>
                  </ul>
                </li>
                <li>
                  我有权获得一份我的申请副本，包括DHR用来决定我的家庭资格和福利金额的信息。我可以向DHR索取一份完整的申请表电子文件副本。
                </li>
              </ul>
  
              <p>
                <strong>DHR将如何使用我的信息？</strong>
              </p>
  
              <p>
                在下方签名即代表我允许DHR从以下机构获取并与之共享关于我和我家人的信息：
              </p>
              <ul>
                <li>
                  银行、学校、政府、雇主、房东、公用事业公司和其他机构，以查看我是否有资格获得福利。
                </li>
                <li>
                  电力、天然气和电话公司，以便我获得公用事业优惠。这些公司不能分享我的信息或将其用于任何其他目的。
                </li>
                <li>
                  住房和社区发展部（Department of Housing and Community
                  Development），以便注册我参加“取暖和饮食计划”（Heat &amp; Eat
                  Program）。该计划帮助人们在最大程度上获得SNAP福利。
                </li>
                <li>
                  小学及中学教育部（Department of Early and Secondary
                  Education），以便我的子女可以在学校得到免费餐食。
                </li>
                <li>
                  妇女、婴儿和儿童（WIC）营养计划，以便我家中任何5岁以下的儿童或孕妇得到WIC。
                </li>
                <li>
                  美国公民及移民服务局（USCIS），以核实我的移民身份。USCIS提供的信息可能会影响我的家庭资格和DHR福利金额。
                </li>
              </ul>
              <p style={{ paddingLeft: "40px" }}>
                <em>请注意：即使您因移民身份而没有资格获得福利，</em>
                <em>DHR也不会向移民当局报告您，除非您向DHR出示最终驱逐令。 </em>
              </p>
              <ul>
                <li>
                  税务局（DOR），以核实我是否有资格获得基于收入的税收抵免，例如劳动所得和有限收入，并查看我是否符合“无税身份”（No
                  Tax Status）或艰苦条件的资格。
                </li>
                <li>儿童和家庭部（DCF），以协调由DHR和DCF共同提供的服务。</li>
              </ul>
              <p>
                <strong>DHR如何使用社会安全号码（SSN）？ </strong>
              </p>
  
              <p>
                根据2008年关于SNAP的《
                食品和营养法》（《美国法典》第7篇第2011-2036节）和《马萨诸塞州一般法》第18章第33节关于TAFDC和EAEDC的规定，DHR可以要求您提供社会安全号码。DHR将社会安全号码用于以下目的：
              </p>
  
              <ul>
                <li>
                  通过数据匹配程序检查我为之提出申请的每个家庭成员的身份和资格。
                </li>
                <li>监督对计划规则的遵守情况。</li>
                <li>在DHR声称我得到了我并不符合资格的福利时进行讨债。</li>
                <li>帮助执法机构抓获逃避法律的人。</li>
              </ul>
  
              <p>
                我知道，我不必向DHR提供我家中任何不希望获得福利的非公民的社会安全号码，包括我本人在内。即使非公民不享受福利，其收入可能也会计入。
              </p>
  
              <p>
                <strong>获得口译员服务的权利</strong>
              </p>
              <p>我了解：</p>
              <ul>
                <li>
                  如果我首选用英语以外的其他语言交流，我有权获得由DHR提供的免费专业口译员服务。
                </li>
                <li>
                  如果我需要参加DHR听证会，我可以要求DHR为我提供免费的专业口译员，或者如果我愿意的话，我可以带人为我翻译。如果我需要DHR为我在听证会上提供口译员，我必须在听证会召开日期前至少一周打电话联系听证会部门。
                </li>
              </ul>
  
              <p>
                <strong>注册投票的权利</strong>
              </p>
              <p>我了解：</p>
              <ul>
                <li>我有权通过DHR登记投票。</li>
                <li>如果我需要帮助，DHR将帮助我填写选民登记申请表。</li>
                <li>我可以私下填写选民登记申请表。</li>
                <li>申请登记或拒绝登记投票不会影响我的DHR福利。</li>
              </ul>
  
              <p>
                <strong>就业机会</strong>
              </p>
              <p>我同意DHR与就业和培训提供者分享我的姓名和联系方式，包括：</p>
              <ul>
                <li>
                  为SNAP客户提供的SNAP工作之路（SNAP Path
                  Work）提供者或DHR专业人员；以及
                </li>
                <li>
                  合同雇佣和培训提供者，或为TAFDC客户提供的全力投入员工（Full
                  Engagement Workers）。
                </li>
              </ul>
  
              <p>SNAP客户可以自愿通过SNAP工作之路计划参加教育和就业培训服务。</p>
  
              <p>
                <strong>公民身份</strong>
              </p>
              <p>
                我发誓，我的家庭成员中所有申请DHR福利的人都是美国公民或合法居住的非公民。
              </p>
  
              <p style={{ textAlign: "center" }}>
                <strong>补充营养援助计划 </strong>
              </p>
              <p>
                <strong>我理解：</strong>
              </p>
              <ul>
                <li>DHR管理马萨诸塞州的SNAP计划。</li>
                <li>
                  当我（通过电话、线上、面对面，或通过邮寄或传真方式）向DHR提出申请时，从收到我的申请之日起，DHR需要在30天内决定我是否符合资格。
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      如果我有资格获得快速（紧急）SNAP，
                      DHR必须为我提供SNAP，并保证在收到我的申请之日起的7天之内为我提供电子福利转账（EBT）卡。
                    </li>
                    <li>在以下情况下，我有权与一名DHR主管谈话：</li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>
                          DHR说我没有资格获得紧急SNAP福利，但我对此持有异议。
                        </li>
                        <li>
                          我有资格获得紧急SNAP福利，但在我申请SNAP后的第7天仍未获得福利。 
                        </li>
                        <li>
                          我有资格获得紧急SNAP福利，但在我申请SNAP后的第7天仍未收到我的EBT卡。
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  当我获得SNAP时，我必须遵守某些规则。当我被批准获得SNAP后，DHR会给我一份《您的知情权》（Your
                  Right to
                  Know）手册和《SNAP计划手册》。我会阅读这些手册，或者请别人读给我听。如果我有任何疑问，或需要帮助阅读或理解这些信息，我可以拨打1-877-382-2363联系DHR。
                </li>
              </ul>
              <ul>
                <li>
                  <strong>告知</strong>
                  <strong>DHR关于我家庭情况的变更：</strong>
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      如果我是一个SNAP简化报告家庭，则大多数变更都可以等到中期报告（Interim
                      Report）或重新认证（Recertification）时再通知DHR。只有以下情况必须提前报告：
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>
                          如果我的家庭收入超过总收入上限（显示在我的批准通知中）。
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              我必须在我的收入超过上限之月的下月10日之前报告此情况。
                            </li>
                          </ul>
                        </li>
                        <li>
                          如果我必须满足“无家属健康成年人”（ABAWD）的工作要求，且我的工作时间减少到每周20小时以下。
                        </li>
                      </ul>
                    </li>
                    <li>
                      如果我家中的每个人都分别是年龄在60岁以上的长者、残障人士，或18岁以下人士，且无人有工作收入，则我仅需要报告以下情况： 
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>如果有人开始工作，或</li>
                        <li>有人加入或离开了我的家庭。</li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              我必须在情况更改之月的下月10日之前报告这些变更。
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      如果我因为TAFDC停止而通过“过渡福利替代”（TBA）获得SNAP，则在获得TBA的5个月内，我不必向DHR报告任何变更。
                    </li>
                    <li>
                      如果我通过Bay State CAP获得SNAP，我不必向DHR报告任何变更。
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                如果我和家中的每个人都获得现金援助（TAFDC或EAEDC），对于某些变更，我必须在变更后的10天内向DHR进行报告。请参阅下文“
                <strong>对有抚养子女家庭的过渡援助（</strong>
                <strong>
                  TAFDC）和对老年人、残疾人和儿童的紧急援助（EAEDC）”
                </strong>
                部分的“<strong>我应在什么时候通知</strong>
                <strong>DHR有关我家庭情况的变更？”。 </strong> 
              </p>
  
              <p>
                如果我在任何时间向DHR报告以下情况并提供相关证明，我可能会得到更多SNAP福利：   
              </p>
              <ul>
                <li>
                  儿童或其他受抚养人的看护花销、住所费用，和/或公用事业费用；
                </li>
                <li>
                  我（或我家庭中的某人）依法需要向一名非家庭成员支付的子女抚养费；以及
                </li>
                <li>
                  60岁以上或身有残疾的家庭成员（包括我自己在内）的医疗费用。
                </li>
              </ul>
  
              <p>
                <strong>SNAP客户的工作要求：</strong>
                如果您享受SNAP福利且年龄在16至59岁之间，除非获得豁免，您可能需要满足SNAP工作要求或ABAWD工作要求。DHR会告诉我和我的家庭成员是否需要满足任何工作要求，有哪些豁免，以及不遵守这些要求可能造成的后果。 
              </p>
              <p>如果您需要遵守SNAP工作规则：</p>
              <ul>
                <li>
                  您必须在申请和重新认证SNAP时进行注册方能工作。您在签署SNAP申请或重新认证表格时进行注册。
                </li>
                <li>当DHR要求时，你必须提供有关您的就业状况的信息。</li>
                <li>如果由DHR推荐，您必须向雇主报告。</li>
                <li>您必须接受一份工作（除非您有充分的理由不接受）。</li>
                <li>
                  如果没有充分的理由，您不能辞掉每周工作时间超过30小时的工作。
                </li>
                <li>
                  如果没有充分的理由，您不能把工作时间削减到每周30小时以下。
                </li>
              </ul>
  
              <p>
                <strong>SNAP准则</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>不要通过提供虚假信息或隐瞒信息来获得SNAP福利。</li>
                <li>不要交换或出售SNAP福利。</li>
                <li>不要更改EBT卡，以获得您不符合资格的SNAP福利。</li>
                <li>不要用SNAP福利购买不符合资格的物品，例如酒精饮品和烟草。</li>
                <li>
                  除非您是授权代表，或接受福利之人允许您代表他/她使用其卡，否则不要使用他人的SNAP福利或EBT卡。
                </li>
              </ul>
  
              <p>
                <strong>SNAP处罚警告 </strong>
              </p>
              <p>
                我了解，如果我或我任何享受SNAP福利的家庭成员故意违反上述任何规则，则该人将在第一次违规后的一年，第二次违规后的两年，以及第三次违规后永远失去获得SNAP福利的资格。该人也可能会被处以最高25万美元的罚款、最高20年的监禁，或同时接受两种处罚。他们还可能会根据联邦和州法被起诉。
              </p>
  
              <p>我也理解以下处罚。如果我或我享受SNAP福利的家庭成员：</p>
              <ul>
                <li>
                  有<strong>现金计划</strong>
                  的蓄意违反计划（IPV）行为，他们将在失去现金援助资格的同一时期失去获得SNAP的资格。
                </li>
                <li>
                  对其身份或居住情况进行欺诈性声明，以便<em>同时</em>
                  获得多项SNAP福利，他们将失去
                  <strong>
                    <em>十年</em>
                  </strong>
                  的SNAP资格。
                </li>
                <li>
                  用SNAP福利交换（购买或出售）管制药物/非法药物，第一次被发现时他们将失去
                  <strong>
                    <em>两年</em>
                  </strong>
                  的SNAP资格，第二次被发现时他们将
                  <strong>
                    <em>永远</em>
                  </strong>
                  丧失SNAP的资格。
                </li>
                <li>
                  用SNAP福利交换（购买或出售）枪支、弹药或爆炸物，他们将
                  <strong>
                    <em>永远</em>
                  </strong>
                  丧失SNAP的资格。
                </li>
                <li>
                  许诺在网上或以面对面的方式出售SNAP福利或EBT卡，州政府可能会对其追加IPV的责任。
                </li>
                <li>支付用信用购买的食物，他们将没有资格得到SNAP。</li>
                <li>
                  用SNAP福利购买产品，其用意在于丢弃包装内的物品，然后退回包装得到现金，他们将没有资格得到SNAP。
                </li>
                <li>
                  在重罪定罪后为逃避起诉、拘留或监禁而逃跑，他们将没有资格得到SNAP。
                </li>
                <li>
                  违反缓刑或假释，且执法部门正在对其进行积极追捕，他们将没有资格得到SNAP。
                </li>
              </ul>
              <p>
                任何在2014年2月7日之后被定罪为重犯的人，如果根据7
                CFR§273.11（n）的规定是一名在逃重犯或违反了缓刑或假释，
                <strong>
                  <u>并且</u>
                </strong>
                是在以下方面被定罪的成年人，则其没有资格获得SNAP福利：
              </p>
              <ol>
                <li>《美国法典》第18篇第2241节定义的严重性虐待；</li>
                <li>《美国法典》第18篇第1111节定义的谋杀；</li>
                <li>《美国法典》第18篇第110章定义的任何犯罪行为；</li>
                <li>
                  1994年《防止对妇女施暴法》（VAWA）第40002(a)节（《美国法典》第42篇第13925a节）定义的涉及性侵犯的联邦或州犯罪行为；或
                </li>
                <li>
                  总检察长根据州法认为与本清单中所述犯罪类型基本相似的犯罪行为。
                </li>
              </ol>
  
              <p>
                <strong>非歧视声明</strong>
              </p>
              <p>
                联邦民权法和美国农业部（USDA）的法规与政策禁止USDA及其机构、办公室、员工，以及参与或管理USDA计划的机关在任何由USDA开展或出资的计划或活动中以种族、肤色、原籍国、性别、宗教信仰、残疾、年龄、政治信仰为基础进行歧视，或对之前的民权活动进行打击报复。
              </p>
              <p>
                需要通过其他交流方式来了解计划信息的残障人士（例如盲文、大号字体、录音带、美国手语等）应联系他们申请福利的机构（州或地方）。失聪、听力不佳或有言语障碍的人士可以拨打1-800-877-8339，通过联邦信息传递服务（Federal
                Relay
                Service）联系USDA。此外，我们还可以用英语以外的其他语言为您提供项目信息。
              </p>
              <p>如需提出计划歧视投诉：</p>
              <ul>
                <li>
                  填写USDA计划歧视投诉表（AD-3027）。您可以在线上：{" "}
                  <a
                    href="https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf"
                    target="_blank"
                  >
                    https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf
                  </a>
                  ，以及任何USDA办事处获得此表格。您可以拨打1-866-632-9992索取一份投诉表；或{" "}
                </li>
                <li>写信给USDA，并在信中包括表格中要求提供的所有信息。</li>
              </ul>
              <p>请通过以下方式向USDA提交完整填写的表格或信件：</p>
              <ul>
                <li>
                  邮寄：U.S. Department of Agriculture Office of the Assistant
                  Secretary for Civil Rights 1400 Independence Avenue SW
                  Washington, D.C.20250-9410; 或
                </li>
                <li>传真：1-202-690-7442；或</li>
                <li>
                  电子邮件：
                  <a href="mailto:program.intake@usda.gov">
                    program.intake@usda.gov
                  </a>
                </li>
              </ul>
              <p>本机构是机会均等的提供者。</p>
              <p style={{ textAlign: "center" }}>
                <strong>对有被抚养子女的家庭的过渡援助（</strong>
                <strong>TAFDC）和 </strong>
                <br />
                <strong>对老年人、残疾人和儿童的紧急援助（EAEDC） </strong>
              </p>
  
              <p>
                TAFDC和EAEDC是现金援助计划。如需了解更多情况或提出申请，请访问DHRConnect.com或致电您当地的DHR办公室。此信息仅适用于正在申请或已经有TAFDC或EAEDC福利的家庭。
              </p>
  
              <p>
                <strong>我应在什么时候通知</strong>
                <strong>DHR有关我家庭情况的变更？</strong>
              </p>
              <p>
                我必须<strong>在</strong>
                <strong>10天以内</strong>
                通知DHR有可能影响我的TAFDC或EAEDC（现金福利）的变更，但无需告知DHR低于每月100美元的收入变更。其中包括我在收入、住址、同居者、家庭规模、工作和医疗保险方面发生的变化。
              </p>
  
              <p>
                <strong>我如何获得医疗保险？ </strong>
              </p>
              <ul>
                <li>
                  如果我有TAFDC或EAEDC，我也将得到麻州医保补助有限计划（MassHealth）。
                </li>
                <li>
                  如果我的TAFDC或EAEDC被拒，MassHealth将用我的信息查看我是否有资格获得医疗保险。
                </li>
                <li>
                  如果我的EAEDC停止，我需要单独申请MassHealth。请致电1-800-841-2900要求申请表。
                </li>
              </ul>
  
              <p> 如果我得到MassHealth，我同意MassHealth收取：</p>
              <ul>
                <li>其他来源欠我的钱，以用于我的医疗服务，和</li>
                <li>
                  向任何享受MassHealth福利的19岁以下儿童的缺席父母索取医疗抚养费。
                </li>
              </ul>
              <p>
                <strong> </strong>
                <strong> </strong>
              </p>
              <table style={{ border: "1px solid black" }}>
                <tbody>
                  <tr>
                    <td width="626">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <p>
                                <strong>
                                  如果我<u>仅是</u>
                                  因为一起事故或伤害而符合资格的话，有哪些特殊规则吗？
                                </strong>
                              </p>
                              <p>
                                如果我的家庭因一起事故或伤害而从MassHealth或DHR获得福利，我必须用我从事故或伤害中获得的任何财产来偿还他们。这笔钱可能来自于保险、和解协议或任何其他来源。即使我不知道钱可能来自于哪里，本规则也适用。
                              </p>
  
                              <p>我同意在以下方面与MassHealth和DHR合作：</p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> 向其他来源进行财产索赔。
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>
                                 第一时间通知MassHealth和DHR有关任何保险索赔、诉讼或其他获得财产赔偿的流程。
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>
                                 当我获得财产赔偿时，为MassHealth和DHR提供新信息。
                              </p>
                              <p>
                                如果我不合作，MassHealth和DHR可能会停止或拒绝我的福利。我同意MassHealth和DHR：
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>
                                 为收取用来偿还福利的费用而分享我的福利信息。
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>
                                 查看所有关于我因事故或伤害而可能拿到财产赔偿的记录，例如工业事故部门的记录。      
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                       
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <p>
                如果我是因残障或年龄在65岁以上而获得EAEDC，我必须申请联邦社会安全生活补助金（SSI）福利。如果我获批得到的SSI福利涵盖我获得EAEDC的同一时期，则社会安全局（Social
                Security
                Administration）将把我的一部分追溯SSI寄给DHR，以偿还EAEDC。
              </p>
  
              <p>
                <strong>关于法律和您的福利的重要通知</strong>
              </p>
              <p>
                蓄意违反计划（IPV）是指故意以口头或书面形式提供虚假或误导性声明，或误导、隐藏或隐瞒事实，以期建立或维持TAFDC或EAEDC福利的资格，或得到我没有资格获得的福利。
              </p>
              <p>
                如果法院、取消资格行政听证会判定我犯有IPV，或我因签署弃权书而犯有IPV，则我将根据以下规则在一定期限内失去TAFDC或EAEDC的福利资格：
              </p>
  
              <ul>
                <li>第一次违规后丧失福利6个月</li>
                <li>第二次违规后丧失福利12个月</li>
                <li>第三次违规后永久性丧失福利</li>
              </ul>
  
              <p>除此以外，其他法律也可能适用。</p>
  
              <p>
                <strong>禁止使用</strong>
                <strong>EBT卡购买的项目</strong>
              </p>
              <p>
                我知道，将电子福利转账（EBT）卡上的TAFDC或EAEDC资金用于以下用途是非法的：酒精饮品；烟草制品；彩票；成人导向的内容或表演；赌博；枪支弹药；度假服务；纹身；身体穿孔；首饰；电视机；立体声音响；先租后买商店中的电子游戏或游戏机；休闲大麻；法院命令支付的费用；罚款；保释或保释金。  
              </p>
  
              <p>
                <strong>禁止使用</strong>
                <strong>EBT卡的场合</strong>
              </p>
              <p>
                我知道，在以下场合使用我的电子福利转账（EBT）卡是违法的：成人书店；成人用品商店或面向成人的表演场所；弹药经销商；赌场；赌博娱乐场或游戏场所；游轮；枪械经销商；首饰店；酒类专卖店；美甲店或美容店；将现金转至国外的转账机构；休闲大麻商店或纹身店。
              </p>
  
              <p>
                <strong>对于用</strong>
                <strong>EBT卡现金购买禁止项目的处罚</strong>
              </p>
              <ul>
                <li>
                  <strong>第一次违规：</strong>我必须将所花的费用偿还给DHR。
                </li>
                <li>
                  <strong>第二次违规：</strong>
                  我必须将所花的费用偿还给DHR，并将失去<strong>两个月</strong>
                  的现金福利。
                </li>
                <li>
                  <strong>第三次违规：</strong>必须将所花的费用偿还给DHR，并将
                  <strong>永久性</strong>失去现金福利。
                </li>
              </ul>
            </div>
          );
  
        case languageConsts.VIETNAMESE:
          return (
            <div>
              <p style={{ textAlign: "center", lineHeight: "0" }}>
                <strong>Thông báo về Quyền, Trách nhiệm và </strong>
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>Hình phạt của Cục Hỗ trợ Vận chuyển (DHR)</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Thông báo này liệt kê các quyền và trách nhiệm đối với tất cả
                  các chương trình DHR. Bạn phải tuân theo các quy tắc đối với các
                  chương trình mà bạn đăng ký.
                </li>
                <li>Vui lòng đọc các trang này và lưu giữ chúng.</li>
                <li>
                  Nếu bạn có bất kỳ câu hỏi nào, hãy thông báo cho DHR biết.
                </li>
              </ul>
  
              <p>
                <strong>Tôi hoàn toàn chịu trách nhiệm nếu khai man rằng:</strong>
              </p>
              <ul>
                <li>
                  Tôi đã đọc thông tin trong biểu mẫu này, hoặc ai đó đã đọc cho
                  tôi nghe.
                </li>
                <li>
                  Câu trả lời của tôi trong biểu mẫu này là đúng và đầy đủ theo sự
                  hiểu biết của tôi.
                </li>
                <li>
                  Tôi sẽ cung cấp thông tin cho DHR một cách trung thực và đầy đủ
                  theo sự hiểu biết của tôi trong cuộc phỏng vấn và trong tương
                  lai.
                </li>
              </ul>
              <p>
                <strong>Tôi hiểu rằng:</strong>
              </p>
              <ul>
                <li>
                  cung cấp thông tin sai lệch hoặc gây hiểu lầm là gian lận,
                </li>
                <li>
                  trình bày sai sự thật hoặc giữ lại sự thật để nhận được các lợi
                  ích của DHR là gian lận,
                </li>
                <li>
                  gian lận được coi là Hành vi Cố ý Vi phạm Chương trình (IPV), và
                </li>
                <li>
                  nếu DHR cho rằng tôi đã có hành vi gian lận, DHR có thể thực
                  hiện các hình phạt dân sự và hình sự đối với tôi.
                </li>
              </ul>
              <p>
                <strong>Tôi cũng hiểu điều rằng:</strong>
              </p>
              <ul>
                <li>
                  DHR sẽ xác minh thông tin mà tôi cung cấp cùng với đơn đăng ký
                  của tôi. Nếu có bất kỳ thông tin sai nào, DHR có thể từ chối các
                  quyền lợi của tôi.
                </li>
                <li>
                  Tôi cũng có thể bị truy tố hình sự vì cung cấp thông tin sai
                  lệch.
                </li>
                <li>
                  Nếu DHR nhận được thông tin từ một nguồn đáng tin cậy về sự thay
                  đổi trong hộ gia đình tôi, số tiền trợ cấp của tôi có thể thay
                  đổi.
                </li>
                <li>
                  Bằng cách ký vào biểu mẫu này, tôi cho phép DHR xác minh tính đủ
                  điều kiện nhận các quyền lợi của tôi, bao gồm:
                  <ul>
                    <li>
                      Nhận thông tin từ các cơ quan của tiểu bang hoặc liên bang
                      khác, cơ quan quản lý nhà ở địa phương, các sở phúc lợi
                      ngoài tiểu bang, tổ chức tài chính và Giải pháp Lực lượng
                      Lao động Equifax (Số lao động). Tôi cũng cho phép các cơ
                      quan này chia sẻ thông tin về tính đủ điều kiện nhận các
                      quyền lợi của hộ gia đình tôi với DHR.
                    </li>
                    <li>
                      Nếu DHR sử dụng thông tin từ Equifax về thu nhập kiếm được
                      từ hộ gia đình tôi, tôi có quyền nhận bản sao miễn phí về
                      báo cáo Equifax của mình nếu tôi yêu cầu trong vòng 60 ngày
                      kể từ ngày DHR đưa ra quyết định. Tôi có quyền đặt câu hỏi
                      về thông tin trong báo cáo. Tôi có thể liên hệ với Equifax
                      tại: Giải pháp Lực lượng Lao động Equifax, 11432 Lackland
                      Road, St. Louis, MO 63146, 1-800-996-7566 (miễn phí).
                    </li>
                  </ul>
                </li>
                <li>
                  Tôi có quyền nhận được một bản sao đơn đăng ký của mình, bao gồm
                  thông tin mà DHR sử dụng để quyết định về tính đủ điều kiện và
                  số tiền trợ cấp của hộ gia đình tôi. Tôi có thể yêu cầu DHR cung
                  cấp bản sao điện tử của đơn đã hoàn thành.
                </li>
              </ul>
  
              <p>
                <strong>DHR sẽ sử dụng thông tin của tôi như thế nào </strong>
                <strong>'</strong>
                <strong>? </strong>
              </p>
  
              <p>
                Bằng cách ký tên dưới đây, tôi cho phép DHR lấy thông tin và chia
                sẻ thông tin về tôi và các thành viên trong gia đình tôi với:  
              </p>
              <ul>
                <li>
                  Các ngân hàng, trường học, chính phủ, người sử dụng lao động,
                  chủ nhà, công ty tiện ích và các cơ quan khác để kiểm tra xem
                  tôi có đủ điều kiện nhận trợ cấp hay không.
                </li>
                <li>
                  Các công ty điện, gas và điện thoại để tôi có thể được giảm giá
                  tiện ích. Các công ty không thể chia sẻ thông tin của tôi hoặc
                  sử dụng nó cho bất kỳ mục đích nào khác.
                </li>
                <li>
                  Bộ Gia cư và Phát triển Cộng đồng để ghi danh tôi vào Chương
                  trình Heat &amp; Eat. Chương trình này giúp mọi người nhận được
                  tiền trợ cấp SNAP nhiều nhất.
                </li>
                <li>
                  Phòng Giáo dục Mầm non và Trung học để con tôi có được bữa ăn ở
                  trường học miễn phí.
                </li>
                <li>
                  Chương trình dành cho Phụ nữ, Trẻ sơ sinh và Trẻ em (WIC) để bất
                  kỳ trẻ em nào dưới 5 tuổi hoặc phụ nữ mang thai trong gia đình
                  tôi đều có thể nhận được WIC.
                </li>
                <li>
                  Sở Di trú và Nhập tịch Hoa Kỳ (USCIS), để xác minh tình trạng
                  nhập cư của tôi. Thông tin từ USCIS có thể ảnh hưởng đến tính đủ
                  điều kiện của hộ gia đình tôi và số lượng tiền trợ cấp DHR.
                </li>
              </ul>
              <p style={{ paddingLeft: "40px" }}>
                <em>
                  Lưu ý: Ngay cả khi bạn không đủ điều kiện nhận tiền trợ cấp do
                  tình trạng nhập cư, DHR sẽ không báo cáo bạn với cơ quan nhập cư
                  trừ khi bạn trình cho DHR lệnh trục xuất cuối cùng.{" "}
                </em>
              </p>
              <ul>
                <li>
                  Sở Thuế Vụ (DOR) để xác minh tính đủ điều kiện của tôi đối với
                  các khoản tín dụng thuế dựa trên thu nhập, chẳng hạn như thu
                  nhập từ lao động và Thu nhập Giới hạn, và để xem liệu tôi có đủ
                  điều kiện hưởng chính sách "Miễn Thuế" hoặc chính sách dành cho
                  người nghèo khó hay không.
                </li>
                <li>
                  Bộ Trẻ em và Gia đình (DCF) để điều phối các dịch vụ do DHR và
                  DCF cùng cung cấp.
                </li>
              </ul>
              <p>
                <strong>DHR sử dụng Số An sinh Xã hội (SSN) như thế nào? </strong>
              </p>
  
              <p>
                DHR được phép yêu cầu SSN theo Đạo luật Thực phẩm và Dinh dưỡng
                năm 2008 (7 U.S.C. 2011-2036) đối với SNAP và theo M.G.L. c. 18
                Phần 33 đối với TAFDC và EAEDC. DHR sử dụng SSNs để:
              </p>
  
              <ul>
                <li>
                  Kiểm tra danh tính và tư cách hợp lệ của từng thành viên trong
                  gia đình mà tôi đăng ký thông qua các chương trình thích ứng dữ
                  liệu.
                </li>
                <li>Giám sát việc tuân thủ các quy tắc của chương trình.</li>
                <li>
                  Thu tiền nếu DHR tuyên bố tôi đã nhận được tiền trợ cấp mà tôi
                  không đủ điều kiện nhận.
                </li>
                <li>
                  Giúp các cơ quan pháp luật truy bắt những kẻ trốn tránh pháp
                  luật.
                </li>
              </ul>
  
              <p>
                Tôi hiểu rằng tôi không phải cung cấp cho DHR số SSN của bất kỳ
                người nào không có quyền công dân trong hộ gia đình tôi, kể cả bản
                thân tôi, người không muốn nhận tiền trợ cấp. Thu nhập của một
                người không có quyền công dân có thể được tính ngay cả khi anh
                ta/cô ta không nhận được tiền trợ cấp.
              </p>
  
              <p>
                <strong>Quyền được thuê thông dịch viên</strong>
              </p>
              <p>Tôi hiểu rằng:</p>
              <ul>
                <li>
                  Tôi có quyền thuê một thông dịch viên chuyên nghiệp miễn phí do
                  DHR cung cấp nếu tôi muốn giao tiếp bằng ngôn ngữ khác ngoài
                  tiếng Anh.
                </li>
                <li>
                  Nếu tôi có một phiên điều trần DHR, tôi có thể yêu cầu DHR cung
                  cấp cho tôi một thông dịch viên chuyên nghiệp miễn phí, hoặc nếu
                  tôi thích, tôi có thể nhờ một người nào đó để thông dịch cho
                  tôi. Nếu tôi cần DHR cung cấp cho tôi một thông dịch viên cho
                  một phiên điều trần, tôi phải gọi cho Ban Điều trần ít nhất một
                  tuần trước ngày xảy ra phiên điều trần.
                </li>
              </ul>
  
              <p>
                <strong>Quyền đăng ký bỏ phiếu</strong>
              </p>
              <p>Tôi hiểu rằng:</p>
              <ul>
                <li>Tôi có quyền đăng ký bỏ phiếu thông qua DHR.</li>
                <li>
                  DHR sẽ giúp tôi điền vào mẫu đơn đăng ký người bỏ phiếu nếu tôi
                  muốn được trợ giúp.
                </li>
                <li>
                  Tôi có thể điền vào biểu mẫu đăng ký của người bỏ phiếu một cách
                  riêng tư.
                </li>
                <li>
                  Nộp đơn đăng ký hoặc từ chối đăng ký bỏ phiếu sẽ không ảnh hưởng
                  đến tiền trợ cấp DHR của tôi.
                </li>
              </ul>
  
              <p>
                <strong>Cơ hội việc làm</strong>
              </p>
              <p>
                Tôi đồng ý rằng DHR có thể chia sẻ tên và thông tin liên hệ của
                tôi với các nhà cung cấp việc làm và đào tạo, bao gồm:
              </p>
              <ul>
                <li>
                  Các nhà cung cấp SNAP Path Work hoặc các chuyên gia DHR cho các
                  khách hàng SNAP; và
                </li>
                <li>
                  Các nhà cung cấp Việc làm và Đào tạo theo Hợp đồng hoặc Nhân
                  viên làm việc toàn thời gian cho các khách hàng TAFDC.
                </li>
              </ul>
  
              <p>
                Khách hàng SNAP có thể tự nguyện tham gia vào các dịch vụ giáo dục
                và đào tạo việc làm thông qua chương trình SNAP Path to Work.
              </p>
  
              <p>
                <strong>Tình trạng công dân</strong>
              </p>
              <p>
                Tôi xin thề rằng tất cả các thành viên trong gia đình mà tôi nộp
                đơn xin trợ cấp DHR đều là công dân Hoa Kỳ hoặc những người không
                có quyền công dân cư trú hợp pháp.
              </p>
  
              <p style={{ textAlign: "center" }}>
                <strong>Chương trình Hỗ trợ Dinh dưỡng Bổ sung </strong>
              </p>
              <p>
                Tôi hiểu rằng<strong>:</strong>
              </p>
              <ul>
                <li>DHR quản lý chương trình SNAP ở Alabama.</li>
                <li>
                  Khi tôi nộp đơn với DHR (qua điện thoại, trực tuyến, trực tiếp
                  hoặc qua thư hoặc fax), DHR có 30 ngày để quyết định xem tôi có
                  đủ điều kiện hay không kể từ ngày nhận được đơn của tôi.
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Nếu tôi đủ điều kiện nhận SNAP khẩn cấp, DHR phải cung cấp
                      cho tôi SNAP và đảm bảo rằng tôi sẽ có thẻ Chuyển trợ cấp
                      bằng Điện tử (EBT) trong vòng 7 ngày kể từ ngày họ nhận được
                      đơn đăng ký của tôi.
                    </li>
                    <li>Tôi có quyền nói chuyện với người giám sát DHR nếu:</li>
                    <li style={{ listStyle: "none" }}>
                      <ul style={{ listStyle: "square" }}>
                        <li>
                          DHR nói rằng tôi không đủ điều kiện nhận trợ cấp SNAP
                          khẩn cấp và tôi không đồng ý.
                        </li>
                        <li>
                          Tôi đủ điều kiện nhận trợ cấp SNAP khẩn cấp, nhưng không
                          nhận được trợ cấp của mình trước ngày 7 sau khi tôi nộp
                          đơn xin SNAP.
                        </li>
                        <li>
                          Tôi đủ điều kiện nhận trợ cấp SNAP khẩn cấp nhưng không
                          nhận được thẻ EBT vào ngày 7 sau khi tôi nộp đơn xin
                          SNAP.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Khi nhận được SNAP, tôi phải đáp ứng các quy tắc nhất định. Khi
                  tôi được chấp thuận SNAP, DHR sẽ cung cấp cho tôi một bản sao
                  của tập tài liệu “Quyền được biết của Bạn” và tập tài liệu về
                  Chương trình SNAP. Tôi sẽ đọc các tài liệu quảng cáo hoặc nhờ ai
                  đó đọc cho tôi. Nếu tôi có bất kỳ câu hỏi nào hoặc cần trợ giúp
                  đọc hoặc hiểu thông tin này, tôi có thể gọi cho DHR theo số
                  1-877-382-2363.
                </li>
              </ul>
  
              <ul>
                <li>
                  <strong>
                    Thông báo với DHR về những thay đổi trong hộ gia đình tôi
                  </strong>
                  :
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Nếu tôi thuộc hộ gia đình Báo cáo Đơn giản hóa SNAP, tôi
                      không phải báo cáo hầu hết các thay đổi đối với DHR cho đến
                      khi Báo cáo Tạm thời hoặc Chứng nhận lại đến hạn. Điều duy
                      nhất tôi phải báo cáo sớm hơn là:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>
                          Nếu thu nhập của hộ gia đình tôi vượt quá ngưỡng tổng
                          thu nhập (được liệt kê trong thông báo chấp thuận của
                          tôi).
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Tôi phải báo cáo điều này trước ngày 10 của tháng
                              sau tháng mà thu nhập của tôi vượt quá ngưỡng.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Nếu tôi phải đáp ứng Người Lớn có Khả Năng Đi Làm và
                          Không Có Người Phụ Thuộc (ABAWD) và số giờ làm việc của
                          tôi giảm xuống dưới 20 giờ mỗi tuần.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Nếu tất cả mọi người trong gia đình tôi từ 60 tuổi trở lên,
                      bị tàn tật hoặc dưới 18 tuổi, và không ai có thu nhập, điều
                      duy nhất tôi phải báo cáo là:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>Nếu ai đó bắt đầu làm việc, hoặc</li>
                        <li>Ai đó tham gia hoặc rời khỏi hộ gia đình của tôi.</li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Tôi phải báo cáo những thay đổi này trước ngày 10
                              của tháng sau tháng thay đổi.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Nếu tôi nhận SNAP thông qua Giải pháp Thay thế trợ cấp
                      Chuyển tiếp (TBA) vì TAFDC của tôi đã ngừng hoạt động, tôi
                      không phải báo cáo bất kỳ thay đổi nào đối với DHR trong 5
                      tháng tôi nhận được TBA.
                    </li>
                    <li>
                      Nếu tôi nhận SNAP thông qua Bay State CAP, tôi không phải
                      báo cáo bất kỳ thay đổi nào đối với DHR.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Nếu tôi và mọi người trong gia đình tôi được hỗ trợ tiền mặt
                (TAFDC hoặc EAEDC), tôi phải báo cáo một số thay đổi cho DHR trong
                vòng 10 ngày kể từ ngày thay đổi. Xem Khi nào tôi cần thông báo
                cho DHR về những thay đổi trong hộ gia đình của tôi? theo Viện trợ
                chuyển tiếp cho các gia đình có trẻ em phụ thuộc (TAFDC) và Viện
                trợ khẩn cấp cho người già, người tàn tật và trẻ em (EAEDC) dưới
                đây.
              </p>
  
              <p>
                Tôi có thể nhận được nhiều khoản trợ cấp SNAP vào bất kỳ lúc nào
                nếu tôi báo cáo và cung cấp bằng chứng DHR cho những điều sau đây:
              </p>
              <ul>
                <li>
                  Chi phí chăm sóc trẻ em hoặc người phụ thuộc khác, chi phí ăn ở
                  và / hoặc chi phí tiện ích;
                </li>
                <li>
                  Tiền cấp dưỡng nuôi con mà tôi (hoặc một người nào đó trong hộ
                  gia đình của tôi) phải trả cho một thành viên không phải là
                  thành viên trong hộ gia đình; và
                </li>
                <li>
                  Chi phí y tế cho các thành viên trong hộ gia đình tôi, bao gồm
                  cả tôi, từ 60 tuổi trở lên hoặc bị tàn tật.
                </li>
              </ul>
  
              <p>
                <strong>Quy tắc làm việc đối với các khách hàng SNAP: </strong>Nếu
                bạn nhận được trợ cấp SNAP và ở độ tuổi từ 16 đến 59, bạn cần phải
                đáp ứng các quy tắc làm việc chung của SNAP hoặc quy tắc làm việc
                của ABAWD trừ khi bạn được miễn. DHR sẽ cho tôi và các thành viên
                trong gia đình tôi biết nếu chúng tôi cần phải đáp ứng bất kỳ Quy
                tắc làm việc nào, các trường hợp miễn trừ là gì và điều gì sẽ xảy
                ra nếu chúng tôi không đáp ứng các quy tắc. 
              </p>
              <p>Nếu bạn tuân theo Quy Tắc Làm Việc SNAP: </p>
              <ul>
                <li>
                  Bạn phải đăng ký làm việc khi nộp đơn và khi bạn xác nhận lại
                  SNAP. Bạn đăng ký khi bạn ký vào đơn SNAP hoặc mẫu xác nhận lại.
                </li>
                <li>
                  Bạn phải cung cấp cho DHR thông tin về tình trạng việc làm của
                  bạn khi DHR yêu cầu.
                </li>
                <li>
                  Bạn phải báo cáo với nhà tuyển dụng nếu được DHR giới thiệu.
                </li>
                <li>
                  Bạn phải chấp nhận một lời mời làm việc (trừ khi bạn có lý do
                  chính đáng để không làm).
                </li>
                <li>
                  Bạn không được nghỉ việc hơn 30 giờ một tuần mà không có lý do
                  chính đáng.
                </li>
                <li>
                  Bạn không được cắt giảm số giờ làm việc của mình xuống dưới 30
                  giờ một tuần mà không có lý do chính đáng.
                </li>
              </ul>
  
              <p>
                <strong>Quy tắc SNAP</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Không cung cấp thông tin sai lệch hoặc che giấu thông tin để
                  nhận trợ cấp SNAP.
                </li>
                <li>Không mua bán trợ cấp SNAP.</li>
                <li>
                  Không thay đổi thẻ EBT để nhận trợ cấp SNAP mà bạn không đủ điều
                  kiện nhận.
                </li>
                <li>
                  Không sử dụng trợ cấp SNAP để mua các mặt hàng không đủ điều
                  kiện, chẳng hạn như đồ uống có cồn và thuốc lá.
                </li>
                <li>
                  Không sử dụng trợ cấp SNAP hoặc thẻ EBT của người khác trừ khi
                  bạn là đại diện được ủy quyền hoặc người nhận đã cho phép bạn sử
                  dụng thẻ của họ.
                </li>
              </ul>
  
              <p>
                <strong>Cảnh báo hình phạt SNAP</strong>
              </p>
              <p>
                Tôi hiểu rằng nếu tôi hoặc bất kỳ thành viên nào trong gia đình
                SNAP của tôi cố tình vi phạm bất kỳ quy tắc nào được liệt kê ở
                trên, người đó sẽ không đủ điều kiện nhận SNAP trong một năm sau
                lần vi phạm đầu tiên, hai năm sau lần vi phạm thứ hai và mãi mãi
                sau lần vi phạm thứ ba. Người đó cũng có thể bị phạt tới 250.000
                đô la, bị phạt tù đến 20 năm hoặc cả hai. Họ cũng có thể bị truy
                tố theo luật Liên bang và Tiểu bang.
              </p>
  
              <p>
                Tôi cũng hiểu các hình phạt sau đây. Nếu tôi hoặc một thành viên
                trong hộ gia đình SNAP của tôi:
              </p>
              <ul>
                <li>
                  Cam kết một chương trình tiền mặt đối với Vi phạm hành chính
                  (IPV) và họ sẽ không đủ điều kiện nhận SNAP trong cùng khoảng
                  thời gian mà họ không đủ điều kiện nhận hỗ trợ tiền mặt.
                </li>
                <li>
                  Khai báo gian dối về danh tính hoặc nơi cư trú của họ để nhận
                  được nhiều trợ cấp SNAP và họ sẽ không đủ điều kiện nhận SNAP
                  trong mười năm.
                </li>
                <li>
                  Trao đổi (mua hoặc bán) trợ cấp SNAP cho một chất bị kiểm soát /
                  ma túy bất hợp pháp, họ sẽ không đủ điều kiện nhận SNAP trong
                  hai năm đối với phát hiện đầu tiên và mãi mãi đối với phát hiện
                  thứ hai.
                </li>
                <li>
                  Trao đổi (mua hoặc bán) trợ cấp SNAP đối với súng ống, đạn dược
                  hoặc chất nổ, họ sẽ không đủ điều kiện nhận SNAP mãi mãi.
                </li>
                <li>
                  Đề xuất bán trực tuyến hoặc trực tiếp trợ cấp SNAP hoặc thẻ EBT
                  mà Tiểu bang có thể áp dụng một lệnh vi phạm hành chính IPV đối
                  với họ.
                </li>
                <li>
                  Thanh toán thực phẩm mua bằng tín dụng, họ sẽ không đủ điều kiện
                  nhận SNAP.
                </li>
                <li>
                  Mua các sản phẩm có trợ cấp SNAP với mục đích loại bỏ dung lượng
                  và trả lại hộp đựng để đổi lấy tiền mặt, họ sẽ không đủ điều
                  kiện nhận SNAP.
                </li>
                <li>
                  Chạy trốn để tránh bị truy tố, giam giữ sau khi bị kết án tội
                  nghiêm trọng và họ sẽ không đủ điều kiện nhận SNAP.
                </li>
                <li>
                  Vi phạm thời gian thử thách hoặc bản cam kết, trong đó cơ quan
                  thực thi pháp luật đang tích cực tìm cách bắt giữ họ, họ sẽ
                  không đủ điều kiện nhận SNAP.
                </li>
              </ul>
              <p>
                Bất kỳ người phạm tội nghiêm trọng bị kết án sau ngày 7 tháng 2
                năm 2014 sẽ không đủ điều kiện nhận trợ cấp SNAP nếu họ là một
                người phạm tội nghiêm trọng đang bỏ trốn hoặc đang vi phạm thời
                gian thử thách hoặc bản cam kết - theo 7 CFR §273.11 (n) - và bị
                kết án khi:
              </p>
              <ol>
                <li>
                  Lạm dụng tình dục nghiêm trọng theo mục 2241 của tiêu đề 18,
                  U.S.C.;
                </li>
                <li>Phạm tôi giết người theo mục 1111 của tiêu đề 18, U.S.C.;</li>
                <li>
                  Bất kỳ hành vi vi phạm nào theo chương 110 của tiêu đề 18,
                  U.S.C.;
                </li>
                <li>
                  Một tội danh Liên bang hoặc Tiểu bang liên quan đến tấn công
                  tình dục, theo mục 40002 (a) của VAWA 1994 (42 U.S.C. 13925a);
                  hoặc
                </li>
                <li>
                  Một tội danh Tiểu bang về cơ bản được Bộ trưởng Tư pháp xác định
                  là tương tự như một tội danh được mô tả trong danh sách này.
                </li>
              </ol>
  
              <p>
                <strong>Tuyên bố Không phân biệt đối xử</strong>
              </p>
              <p>
                Theo luật dân quyền của Liên bang, các quy định và chính sách của
                Bộ Nông nghiệp Hoa Kỳ (USDA), USDA, các Cơ quan, văn phòng và nhân
                viên cũng như các tổ chức tham gia hoặc quản lý các chương trình
                của USDA bị cấm phân biệt đối xử dựa trên chủng tộc, màu da, nguồn
                gốc quốc gia , giới tính, tín ngưỡng tôn giáo, khuyết tật, tuổi
                tác, niềm tin chính trị hoặc sự trả thù đối với hoạt động dân
                quyền trước đây trong bất kỳ chương trình hoặc hoạt động nào do
                USDA tiến hành hoặc tài trợ.
              </p>
              <p>
                Những người khuyết tật cần các phương tiện liên lạc thay thế để
                biết thông tin về chương trình (ví dụ: chữ nổi Braille, bản in khổ
                lớn, băng ghi âm, Ngôn ngữ ký hiệu Hoa Kỳ, v.v.), nên liên hệ với
                Cơ quan (tiểu bang hoặc địa phương) nơi họ nộp đơn xin trợ cấp.
                Những người bị điếc, khiếm thính hoặc khuyết tật giọng nói có thể
                liên hệ với USDA thông qua Dịch Vụ Tiếp Âm Liên Bang theo số
                1-800-877-8339. Ngoài ra, thông tin chương trình có thể được cung
                cấp bằng các ngôn ngữ khác ngoài tiếng Anh.
              </p>
              <p>Nộp đơn khiếu nại chương trình về phân biệt đối xử:</p>
              <ul>
                <li>
                  Hoàn thành Biểu mẫu Khiếu nại Phân biệt Đối xử Chương trình
                  USDA, (AD-3027) trực tuyến tại:{" "}
                  <a
                    href="https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf"
                    target="_blank"
                  >
                    https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf
                  </a>
                  , và tại bất kỳ văn phòng nào của USDA. Bạn có thể yêu cầu một
                  bản sao của biểu mẫu khiếu nại bằng cách gọi 1-866-632-9992;
                  hoặc
                </li>
                <li>
                  Viết một lá thư gửi đến USDA và điền tất cả các thông tin được
                  yêu cầu trong biểu mẫu vào lá thư.
                </li>
              </ul>
              <p>Gửi biểu mẫu hoặc thư đã hoàn thành của bạn tới USDA qua:</p>
              <ul>
                <li>
                  thư: U.S. Department of Agriculture Office of the Assistant
                  Secretary for Civil Rights 1400 Independence Avenue SW
                  Washington, D.C.20250-9410; hoặc
                </li>
                <li>fax: 1-202-690-7442; or</li>
                <li>
                  email:{" "}
                  <a href="mailto:program.intake@usda.gov">
                    program.intake@usda.gov
                  </a>
                </li>
              </ul>
              <p>Đây là cơ quan cung cấp cơ hội bình đẳng.</p>
              <p style={{ textAlign: "center", lineHeight: "0" }}>
                <strong>
                  Hỗ trợ chuyển tiếp cho các gia đình có trẻ em phụ thuộc (TAFDC)
                  và
                </strong>
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>
                  Viện trợ khẩn cấp cho người già, người tàn tật và trẻ em (EAEDC){" "}
                </strong>
              </p>
  
              <p>
                TAFDC và EAEDC là các chương trình hỗ trợ tiền mặt. Để tìm hiểu
                thêm và đăng ký, hãy truy cập DHRConnect.com hoặc gọi cho văn
                phòng DHR địa phương của bạn. Thông tin này chỉ áp dụng cho các hộ
                gia đình đang nộp đơn xin hoặc nhận TAFDC hoặc EAEDC.
              </p>
  
              <p>
                <strong>
                  Khi nào tôi cần thông báo với DHR về những thay đổi trong hộ gia
                  đình tôi?
                </strong>
              </p>
              <p>
                Tôi phải nói với DHR về những thay đổi có thể ảnh hưởng đến TAFDC
                hoặc EAEDC (trợ cấp tiền mặt) của tôi trong vòng 10 ngày, ngoại
                trừ việc tôi không phải nói với DHR về sự thay đổi trong thu nhập
                dưới $ 100 mỗi tháng của tôi. Điều này bao gồm những thay đổi về
                thu nhập, địa chỉ, người sống chung với tôi, số người trong gia
                đình, công việc làm và bảo hiểm y tế.
              </p>
  
              <p>
                <strong>
                  Làm cách nào để tôi có thể nhận được bảo hiểm y tế?{" "}
                </strong>
              </p>
              <ul>
                <li>
                  Nếu tôi nhận TAFDC hoặc EAEDC, tôi cũng sẽ nhận được MassHealth.
                </li>
                <li>
                  Nếu tôi bị TAFDC hoặc EAEDC từ chối, MassHealth sẽ sử dụng thông
                  tin của tôi để xem liệu tôi có đủ điều kiện nhận bảo hiểm y tế
                  hay không.  
                </li>
                <li>
                  Nếu EAEDC của tôi chấm dứt, tôi cần đăng ký MassHealth riêng. Để
                  yêu cầu đơn đăng ký, hãy gọi 1-800-841-2900.
                </li>
              </ul>
  
              <p>
                 Nếu tôi nhận MassHealth, tôi đồng ý rằng MassHealth có thể thu:
              </p>
              <ul>
                <li>
                  tiền nợ tôi từ một nguồn khác để chăm sóc y tế cho tôi, và
                </li>
                <li>
                  hỗ trợ y tế từ cha / mẹ vắng mặt của bất kỳ trẻ em nào dưới 19
                  tuổi nhận trợ cấp MassHealth.
                </li>
              </ul>
  
              <table width="0">
                <tbody>
                  <tr>
                    <td width="696"></td>
                  </tr>
                </tbody>
              </table>
  
              <table style={{ border: "1px solid black" }}>
                <tbody>
                  <tr>
                    <td width="626">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <p>
                                <strong>
                                  Có quy định đặc biệt nào không nếu tôi đủ điều
                                  kiện chỉ vì tai nạn hoặc thương tích?{" "}
                                </strong>
                              </p>
                              <p>
                                Nếu gia đình tôi nhận được trợ cấp từ MassHealth
                                hoặc DHR vì tai nạn hoặc thương tích, tôi phải sử
                                dụng bất kỳ khoản tiền nào tôi nhận được do tai
                                nạn hoặc thương tích để trả lại cho họ. Tiền có
                                thể thu được từ một hợp đồng bảo hiểm, một khoản
                                thanh toán hoặc bất kỳ nguồn nào khác. Điều này áp
                                dụng ngay cả khi tôi chưa biết các nguồn tiền có
                                thể có là gì.
                              </p>
  
                              <p>
                                Tôi đồng ý hợp tác với MassHealth và DHR bằng
                                cách:
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Nộp đơn yêu cầu tiền từ các
                                nguồn khác.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Thông báo cho MassHealth và DHR
                                về-việc đòi bồi thường bảo hiểm, vụ kiện hoặc quy
                                trình khác ngay lập tức để nhận tiền.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Cung cấp thông tin mới cho
                                MassHealth và DHR khi tôi nhận được chúng.
                              </p>
  
                              <p>
                                Nếu tôi không hợp tác, MassHealth và DHR có thể
                                ngừng hoặc từ chối trợ cấp của tôi. Tôi đồng ý
                                rằng MassHealth và DHR có thể:
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Chia sẻ thông tin về trợ cấp
                                của tôi để thu tiền trả lại trợ cấp đó.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong> Xem tất cả hồ sơ về số tiền tôi
                                có thể nhận được do tai nạn hoặc thương tích,
                                chẳng hạn như hồ sơ tại Cục Tai nạn Công nghiệp.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                       
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <p>
                Nếu tôi đang nhận EAEDC vì tôi bị khuyết tật hoặc tôi trên 65
                tuổi, tôi phải nộp đơn xin Tiền Phụ Cấp An Sinh (SSI) của liên
                bang. Nếu tôi được chấp thuận SSI bao gồm cùng thời gian tôi nhận
                EAEDC, thì Cơ quan An sinh Xã hội sẽ gửi một số SSI có hiệu lực
                trở về trước của tôi đến DHR để hoàn trả EAEDC.
              </p>
  
              <p>
                <strong>Thông báo quan trọng về luật và trợ cấp của bạn</strong>
              </p>
              <p>
                Vi phạm hành chính (IPV) là cố ý đưa ra một tuyên bố sai lệch hoặc
                gây hiểu lầm hoặc trình bày sai, che giấu hoặc giấu giếm sự thật,
                bằng lời nói hoặc bằng văn bản, để thiết lập hoặc duy trì tính đủ
                điều kiện nhận trợ cấp TAFDC hoặc EAEDC hoặc để đạt được trợ cấp
                mà tôi tôi không có quyền.
              </p>
              <p>
                Nếu tôi bị một tòa án, phiên điều trần hành chính kết tội về IPV,
                hoặc bằng cách ký đơn từ bỏ, tôi sẽ không đủ tư cách nhận trợ cấp
                TAFDC hoặc EAEDC trong một khoảng thời gian:
              </p>
  
              <ul>
                <li>6 tháng đối với lần vi phạm đầu tiên</li>
                <li>12 tháng đối với lần vi phạm thứ hai</li>
                <li>mãi mãi đối với lần vi phạm thứ ba</li>
              </ul>
  
              <p>Ngoài ra, có thể áp dụng các luật khác.</p>
  
              <p>
                <strong>Cấm mua thẻ EBT</strong>
              </p>
              <p>
                Tôi hiểu rằng việc sử dụng quỹ TAFDC hoặc EAEDC được giữ trên thẻ
                chuyển quyền lợi điện tử (EBT) để thanh toán cho các khoản sau là
                bất hợp pháp: đồ uống có cồn; Sản phẩm thuốc lá; xổ số kiến thiết;
                tài liệu hoặc buổi biểu diễn dành cho người lớn; cờ bạc; súng và
                đạn dược; dịch vụ nghỉ dưỡng; hình xăm; Xỏ lỗ trên cơ thể; đồ
                trang sức; ti vi; dàn âm thanh nổi; trò chơi điện tử hoặc máy chơi
                game tại các cửa hàng cho thuê; cần sa giải trí; lệ phí theo lệnh
                của tòa án; tiền phạt; tiền bảo lãnh hoặc trái phiếu bảo lãnh.  
              </p>
  
              <p>
                <strong>Những Điều Cấm Tôi Sử Dụng Thẻ EBT Của Mình</strong>
              </p>
              <p>
                Tôi hiểu rằng việc sử dụng thẻ chuyển quyền lợi điện tử (EBT) của
                tôi tại các địa điểm sau là bất hợp pháp: hiệu sách dành cho người
                lớn; cửa hàng bán đồ dùng cá nhân dành cho người lớn hoặc cơ sở
                biểu diễn dành cho người lớn; đại lý đạn dược; sòng bạc; tàu du
                lịch; đại lý súng ống; cửa hàng trang sức; cửa hàng rượu; cửa hàng
                làm móng hoặc cửa hàng thẩm mỹ; đại lý chuyển tiền ra nước ngoài;
                cửa hàng cần sa giải trí hoặc tiệm xăm hình.
              </p>
  
              <p>
                <strong>
                  Hình phạt cho các giao dịch mua thẻ EBT bằng tiền mặt bị cấm
                </strong>
              </p>
              <ul>
                <li>
                  <strong>Vi phạm đầu tiên: </strong>Tôi phải trả lại cho DHR số
                  tiền đã chi tiêu.
                </li>
                <li>
                  <strong>Vi phạm thứ hai: </strong>Tôi phải trả lại cho DHR số
                  tiền đã chi tiêu và sẽ mất trợ cấp tiền mặt trong hai tháng.
                </li>
                <li>
                  <strong>Vi phạm thứ ba: </strong>phải trả lại cho DHR số tiền đã
                  chi tiêu và sẽ mất trợ cấp tiền mặt vĩnh viễn.
                </li>
              </ul>
            </div>
          );
  
        case HI:
          return (
            <div>
              <p style={{ textAlign: "center", lineHeight: "0" }}>
                <strong>Depatman Asistans Tranzisyonèl (DHR) </strong>
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>Avi sou Dwa, Responsablite ak Sanksyon</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Avi sa a bay lis dwa ak responsablite pou tout pwogram DHR yo.
                  Ou dwe suiv règleman yo nan pwogram ou aplike pou yo a.
                </li>
                <li>Tanpri, li paj sa yo epi kenbe yo pou dosye ou.</li>
                <li>Fè DHR konnen si ou gen nenpòt kesyon.</li>
              </ul>
              <p>
                <strong>Mwen jire anba sanksyon fo temwayaj ke:</strong>
              </p>
              <ul>
                <li>
                  Mwen te li enfòmasyon ki nan fòm sa a, oswa yon moun te li l pou
                  mwen.
                </li>
                <li>
                  Repons mwen yo nan fòm sa a yo vre ak konplè nan pi bon konesans
                  mwen.
                </li>
                <li>
                  Mwen pral bay DHR enfòmasyon ki vre ak konplè nan pi bon
                  konesans mwen pandan entèvyou mwen an ak alavni.
                </li>
              </ul>
              <p>
                <strong>Mwen konprann ke:</strong>
              </p>
              <ul>
                <li>bay enfòmasyon ki fo oswa pou twonpe se fwòd,</li>
                <li>defòme oswa kenbe fè yo pou jwenn benefis DHR se fwòd,</li>
                <li>
                  Fwòd konsidere kòm yon Vyolasyon Entansyonèl Pwogram nan (IPV),
                  epi
                </li>
                <li>
                  si DHR panse ke mwen te komèt fwòd, DHR ka pousuiv sanksyon
                  sivil ak kriminèl kont mwen.
                </li>
              </ul>
              <p>
                <strong>Mwen konprann tou ke:</strong>
              </p>
              <ul>
                <li>
                  DHR pral verifye enfòmasyon m bay ak aplikasyon mwen an. Si
                  nenpòt enfòmasyon fo, DHR gen dwa rejte benefis mwen yo.
                </li>
                <li>
                  Mwen ka sijè tou a pousuit kriminèl paske mwen bay enfòmasyon ki
                  fo.
                </li>
                <li>
                  Si DHR jwenn enfòmasyon de yon sous fyab konsènan yon chanjman
                  nan fwaye m, montan benefis mwen ka chanje.
                </li>
                <li>
                  Nan siyen fòm sa a, mwen bay DHR pèmisyon pou verifye
                  kalifikasyon mwen pou benefis, tankou:
                  <ul>
                    <li>
                      Jwenn enfòmasyon soti nan lòt ajans etatik oswa federal,
                      otorite lojman lokal yo, depatman byennèt andeyò eta a,
                      enstitisyon finansye yo, ak Equifax Workforce Solutions
                      (Nimewo Travay la). Mwen bay ajans sa yo tou pèmisyon pou
                      pataje enfòmasyon sou kalifikasyon fwaye mwen pou benefis ak
                      DHR.
                    </li>
                    <li>
                      Si DHR itilize enfòmasyon soti nan Equifax sou revni fwaye
                      mwen fè, m gen dwa a yon kopi gratis rapò Equifax mwen si
                      mwen mande li nan espas 60 jou apre desizyon DHR a. Mwen gen
                      dwa pou kesyone enfòmasyon ki nan rapò a. Mwen ka kontakte
                      Equifax nan: Equifax Workforce Solutions, 11432 Lackland
                      Road, Saint Louis, MO 63146, 1-800-996-7566 (gratis).
                    </li>
                  </ul>
                </li>
                <li>
                  Mwen gen dwa a yon kopi aplikasyon mwen an, ki enkli enfòmasyon
                  ke DHR itilize pou deside sou kalifikasyon fwaye mwen ak montan
                  benefis mwen. Mwen ka mande DHR yon kopi elektwonik aplikasyon
                  ranpli a.
                </li>
              </ul>
              <p>
                <strong>Kijan DHR pral itilize enfòmasyon mwen</strong>
                <strong>?</strong>
              </p>
              <p>
                Nan siyen anba a, mwen bay DHR pèmisyon p ou jwenn enfòmasyon soti
                nan e p i pataje enfòmasyon konsènan mwen menm ak manm nan fwaye m
                ak:
              </p>
              <ul>
                <li>
                  Bank, lekòl, gou v ènman, patwon, pwopriyetè kay, konpayi sèvis
                  itilitè ak lòt aja ns p ou ve ri fye si m kalifye pou benefis.
                </li>
                <li>
                  Konpayi,elektrikgaz aktelefòn pou m ka jwenn rabèsèvis itilitè.
                  Konpayi yo pa ka pa ta je enfòmasyon m oswa itilize li p o u oke
                  nn lò t objektif.
                </li>
                <li>
                  Depatman Lojman ak d Devlopman K om in o tè pou enskri mwen nan
                  Pwogram Heat &amp; Eat la. Pwogram sa a ede moun jwenn plis
                  benefis SNAP po si b.
                </li>
                <li>
                  Depatman Early and Secondary Education pou pitit mwen yo ka
                  jwenn manje lekòl gratis.
                </li>
                <li>
                  Pwogram, Woman, Infants and Children (WIC) lla pounenpòt timoun
                  anbalaj 5 oswa fanmansent nan fwa ye m ka jwenn WIC.
                </li>
                <li>
                  Sèvis Sitwayènte ak Imigrasyon Etazini (USCIS), pou verifye
                  estati imigrasyon mwen. Enfòmasyon ki soti nan USCIS ka afekte
                  kalifikasyon fwaye mwen ansanm ak montan benefis DHR yo.
                </li>
              </ul>
              <p style={{ paddingLeft: "40px" }}>
                <em>
                  Remak: Menm si ou pa kalifye pou benefis akoz estati imigrasyon
                  w, DHR pa pral rapòte w bay otorite imigrasyon sof si ou montre
                  DHR yon òdonans final pou depòtasyon.
                </em>
              </p>
              <ul>
                <li>
                  Depatman Revni an (DOR) pou verifye kalifikasyon mwen pou kredi
                  taks ki baze sou revni, tankou revni mwen fè ak revni limite, ak
                  pou wè si mwen kalifye pou "Estati san Taks" oswa estati nan
                  difikilte.
                </li>
                <li>
                  Depatman Timoun ak Fanmi (DCF) pou kowòdone sèvis ke DHR ak DCF
                  yo ofri ansanm.
                </li>
              </ul>
              <p>
                <strong>
                  Kijan DHR itilize Nimewo Sekirite Sosyal (NSS yo)?
                </strong>
              </p>
              <p>
                DHR gen dwa mande pou NSS yo dapre Lwa 2008 sou Manje ak Nitrisyon
                (7 USC 2011-2036) pou SNAP ak anba MGL c. 18 Seksyon 33 pou TAFDC
                ak EAEDC. DHR itilize NSS yo ou pou:
              </p>
              <ul>
                <li>
                  Verifye idantite ak kalifikasyon chak manm nan fwaye a mwen te
                  aplike pou yo atravè pwogram matche done.
                </li>
                <li>Kontwole konfòmite ak pwogram règleman yo.</li>
                <li>
                  Kolekte lajan si DHR deklare ke mwen te resevwa benefis ke mwen
                  pa te kalifye pou yo.
                </li>
                <li>
                  Ede ajans ki fè respekte lalwa yo jwenn moun ki nan kache pou
                  lalwa.
                </li>
              </ul>
              <p>
                Mwen konprann ke mwen pa oblije bay DHR NSS okenn moun nan fwaye
                mwen ki pa sitwayen ameriken, ki enkli mwen menm, ki pa vle
                benefis. Revni yon moun ki pa sitwayen ameriken ka konte menm si
                moun ki pa sitwayen ameriken an pa resevwa benefis.
              </p>
              <p>
                <strong>
                  Dwa a yon entèprèt
                  <br />
                </strong>
                Mwen konprann ke:
              </p>
              <ul>
                <li>
                  Mwen gen dwa a yon entèprèt pwofesyonèl gratis ke DHR founi si m
                  prefere kominike nan yon lang ki pa angle
                </li>
                <li>
                  Si m gen yon odyans DHR, mwen ka mande DHR pou ban mwen yon
                  entèprèt pwofesyonèl gratis, oswa si m prefere, mwen ka vini ak
                  yon moun pou entèprete pou mwen. Si m bezwen pou DHR ban mwen
                  yon entèprèt pou yon odyans, mwen dwe rele Divizyon Odyans lan
                  omwen yon semèn avan dat odyans lan.
                </li>
              </ul>
              <p>
                <strong>
                  Dwa pou Enskri pou Vote
                  <br />
                </strong>
                Mwen konprann ke:
              </p>
              <ul>
                <li>Mwen gen dwa enskri pou vote atravè DHR.</li>
                <li>
                  DHR pral ede m ranpli fòm aplikasyon enskripsyon elektè a si
                  mwen vle èd.
                </li>
                <li>
                  Mwen ka ranpli fòm aplikasyon enskripsyon elektè a an prive.
                </li>
                <li>
                  Aplike pou enskri oswa refize enskri pou vote pa pral afekte
                  benefis DHR mwen yo.
                </li>
              </ul>
              <p>
                <strong>
                  Opòtinite Travay
                  <br />
                </strong>
                Mwen dakò ke DHR ka pataje non ak enfòmasyon kontak mwen ak
                founisè travay ak fòmasyon, ki enkli:
              </p>
              <ul>
                <li>
                  Founisè SNAP Path Work oswa espesyalis DHR pou kliyan SNAP; ak
                </li>
                <li>
                  Founisè Travay ak Fòmasyon sou kontra oswa Travayè ak Angajman
                  Konplè pou kliyan TAFDC yo.
                </li>
              </ul>
              <p>
                Kliyan SNAP yo ka patisipe volontèman nan sèvis edikasyon ak
                fòmasyon travay atravè pwogram SNAP Path to Work la.
              </p>
              <p>
                <strong>
                  Estati Sitwayènte
                  <br />
                </strong>
                Mwen jire ke tout manm nan fwaye mwen k ap aplike pou benefis DHR
                se swa sitwayen ameriken, swa rezidan legal ki pa sitwayen
                ameriken.
              </p>
              <p style={{ textAlign: "center" }}>
                <strong>Pwogram Asistans Nitrisyonèl Siplemantè</strong>
              </p>
              <p>
                <strong>Mwen konprann ke:</strong>
              </p>
              <ul>
                <li>DHR jere pwogram SNAP la nan Alabama.</li>
                <li>
                  Lè mwen ranpli yon aplikasyon ak DHR (pa telefòn, sou entènèt,
                  an pèsòn, oswa pa lapòs oswa faks), DHR gen 30 jou apati dat li
                  resevwa aplikasyon mwen an pou deside si mwen kalifye.
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Si mwen kalifye pou SNAP rapid (ijans), DHR dwe ban mwen
                      SNAP epi fè si ke mwen gen yon kat Transfè Elektwonik
                      Benefis (EBT) nan espas 7 jou apati dat yo resevwa
                      aplikasyon mwen an.
                    </li>
                    <li>Mwen gen dwa pale ak yon sipèvizè DHR si:</li>
                    <li style={{ listStyle: "none" }}>
                      <ul style={{ listStyle: "square" }}>
                        <li>
                          DHR di ke m pa kalifye pou benefis SNAP ijans, men mwen
                          pa dakò.
                        </li>
                        <li>
                          Mwen kalifye pou benefis SNAP ijans, men mwen pa resevwa
                          benefis mwen yo nan 7yèm jou apre mwen te fin aplike pou
                          SNAP.
                        </li>
                        <li>
                          Mwen kalifye pou benefis SNAP ijans men mwen pa resevwa
                          kat EBT mwen nan 7èm jou apre mwen te fin aplike pou
                          SNAP.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Lè mwen jwenn SNAP, mwen dwe satisfè sèten règleman. Lè yo
                  apwouve m pou SNAP, DHR pral ban mwen yon kopi bwochi "Dwa w yo
                  pou Konnen" ak bwochi Pwogram SNAP la Mwen pral li bwochi yo
                  oswa fè yon moun li yo pou mwen. Si mwen gen nenpòt kesyon oswa
                  si mwen bezwen èd pou li oswa konprann enfòmasyon sa yo, mwen ka
                  rele DHR nan 1-877-382-2363.
                </li>
              </ul>
              <ul>
                <li>
                  <strong>Enfòme DHR konsènan chanjman nan fwaye mwen</strong>:
                </li>
                <li style={{ listStyle: "none" }}>
                  <ul>
                    <li>
                      Si mwen se yon fwaye SNAP Rapò Senplifye, mwen pa oblije
                      rapòte pifò chanjman bay DHR jiskaske dat limit Rapò
                      Pwovizwa a oswa Resètifikasyon an rive. Sèl bagay mwen dwe
                      rapòte pi bonè se:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>
                          Si revni fwaye mwen depase limit revni brit la (ki
                          endike nan lis avi apwobasyon mwen an).{" "}
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Mwen dwe rapòte sa a nan 10èm jou nan mwa apre mwa a
                              revni mwen te depase limit la.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Si m oblije satisfè Règleman Travay Adilt Kapab ki pa
                          gen Depandan yo (ABAWD) epi valè èdtan travay mwen yo
                          diminye pi ba pase 20 èdtan pa semèn.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Si tout moun nan fwaye mwen gen 60 an oswa plis, andikape,
                      oswa ki poko gen 18 an, epi pèsonn pa gen salè nan travay,
                      sèl bagay mwen dwe rapòte se:
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <ul>
                        <li>Si yon moun kòmanse travay, oswa</li>
                        <li>Yon moun vin abite nan oswa kite fwaye mwen.</li>
                        <li style={{ listStyle: "none" }}>
                          <ul style={{ listStyle: "inside" }}>
                            <li>
                              Mwen dwe rapòte chanjman sa yo nan limit 10èm jou
                              nan mwa apre mwa chanjman an.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Si mwen resevwa SNAP atravè Altènatif Benefis Tranzisyonèl
                      (TBA) paske TAFDC mwen te sispann, mwen pa oblije rapòte
                      okenn chanjman bay DHR pandan 5 mwa yo kote mwen jwenn TBA.
                    </li>
                    <li>
                      Si mwen resevwa SNAP atravè Bay State CAP, mwen pa oblije
                      rapòte okenn chanjman bay DHR.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Si mwen menm ak tout moun nan fwaye mwen resevwa asistans kach
                (TAFDC oswa EAEDC), mwen dwe rapòte sèten chanjman yo bay DHR nan
                espas 10 jou apre chanjman an. Gade{" "}
                <strong>
                  {" "}
                  Kilè mwen bezwen deklare bay DHR chanjman nan fwaye mwen?{" "}
                </strong>
                anba{" "}
                <strong>
                  Èd Tranzisyonèl pou Fanmi ki gen Timoun Depandan (TAFDC) ak Èd
                  Ijans pou Granmoun Aje, Andikape, ak Timoun (EAEDC){" "}
                </strong>{" "}
                anba a.
              </p>
              <p>
                Mwen gen dwa resevwa plis avantaj SNAP si mwen rapòte epi bay prèv
                DHR pou bagay sa yo, nenpòt lè:
              </p>
              <ul>
                <li>
                  Depans pou swen timoun oswa lòt depandan, depans pou lojman,
                  ak/oswa depans pou sèvis itilitè;
                </li>
                <li>
                  Sipò pou timoun ke mwen (oswa yon moun nan fwaye mwen) legalman
                  oblije peye a yon manm ki pa fè pati fwaye a; ak
                </li>
                <li>
                  Depans medikal pou manm nan fwaye mwen, ki enkli tèt mwen, moun
                  ki gen 60 oswa plis oswa moun andikape.
                </li>
              </ul>
              <p>
                <strong>Règleman travay pou kliyan SNAP</strong>: Si ou resevwa
                benefis SNAP epi ou gen ant 16 ak 59 an ou ka bezwen satisfè
                règleman jeneral travay SNAP oswa règleman travay ABAWD yo sof si
                ou egzante. DHR pral di m ak manm nan fwaye mwen si nou bezwen
                satisfè nenpòt nan Règleman Travay yo, kisa egzanpsyon yo ye, ak
                kisa ki pral rive si nou pa satisfè règleman yo.
              </p>
              <p>Si w anba Règleman Travay SNAP:</p>
              <ul>
                <li>
                  Ou dwe enskri pou travay pandan aplikasyon an ak lè ou resètifye
                  pou SNAP. Ou enskri lè ou siyen aplikasyon SNAP la oswa fòm
                  resètifikasyon an.
                </li>
                <li>
                  Ou dwe bay DHR enfòmasyon sou estati travay ou lè DHR mande w
                  li.
                </li>
                <li>Ou dwe prezante kote yon anplwayè si DHR refere w.</li>
                <li>
                  Ou dwe aksepte yon òf travay (sòf si ou gen yon rezon valid pou
                  pa fè sa).
                </li>
                <li>
                  Ou pa dwe kite yon travay ki gen plis pase 30 èdtan pa semèn san
                  yon rezon valid.
                </li>
                <li>
                  Ou pa dwe koupe nan lè travay ou pou mwens pase 30 èdtan pa
                  semèn san yon rezon valid.
                </li>
              </ul>
              <p>
                <strong>Règleman SNAP</strong>
              </p>
              <ul style={{ listStyle: "square" }}>
                <li>
                  Pa bay enfòmasyon ki fo oswa kache enfòmasyon pou ka jwenn
                  benefis SNAP.
                </li>
                <li>Pa fè echanj ni vann benefis SNAP.</li>
                <li>
                  Pa modifye kat EBT yo pou ka jwenn benefis SNAP ou pa kalifye
                  pou yo.
                </li>
                <li>
                  Pa sèvi ak benefis SNAP yo pou achte bagay ki pa kalifye, tankou
                  bwason ki gen alkòl ak tabak.
                </li>
                <li>
                  Pa sèvi ak benefis SNAP oswa kat EBT yon lòt moun sof si ou se
                  yon reprezantan otorize, oswa benefisyè a ba ou pèmisyon pou
                  itilize kat yo nan non yo.
                </li>
              </ul>
              <p>
                <strong>Avètisman SNAP sou Sanksyon</strong>
              </p>
              <p>
                Mwen konprann ke si mwen menm oswa nenpòt manm nan fwaye SNAP mwen
                entansyonèlman vyole nenpòt nan règleman ki endike anlè yo, moun
                sa a pa pral elijib pou SNAP pou yon ane apre premye vyolasyon an,
                de zan apre dezyèm vyolasyon an ak pou toujou apre twazyèm
                vyolasyon an. Moun sa a ka peye tou amann jiska $ 250,000, pran
                prizon jiska 20 an, oswa toulède. Yo ka sijè tou a pousuit jidisyè
                dapre lwa federal ak etatik yo.
              </p>
              <p>
                Mwen konprann tou sanksyon ki anba yo. Si mwen menm oswa yon manm
                nan fwaye SNAP mwen:
              </p>
              <ul>
                <li>
                  Komèt yon <strong> pwogram kach </strong>Vyolasyon Entansyonèl
                  Pwogram (IPV), li pa pral kalifye pou SNAP pou menm peryòd li pa
                  kalifye pou asistans kach la.
                </li>
                {/* <li>Make a fraudulent statement about their identity or residency to get multiple SNAP benefits <em>at the same time </em>they will be ineligible for SNAP for <strong><em>ten years</em></strong>.</li> */}
                <li>
                  Fè yon fo deklarasyon konsènan idantite oswa rezidans li pou li
                  ka resevwa plizyè benefis SNAP an menm tan li pa pral kalifye
                  pou SNAP pou <strong>dis an.</strong>
                </li>
                {/* <li>Trade (buy or sell) SNAP benefits for a controlled substance/illegal drug(s), they will be ineligible for SNAP for <strong><em>two years </em></strong>for the first finding, and <strong><em>forever </em></strong>for the second finding.</li> */}
                <li>
                  Fè komès (achte oswa vann) benefis SNAP pou yon sibstans
                  kontwole/dwòg ilegal(yo), yo pa pral kalifye pou SNAP pou{" "}
                  <strong>de zan </strong>pou premye dekouvèt la, ak{" "}
                  <strong>pou toujou </strong>pou dezyèm dekouvèt la.
                </li>
                {/* <li>Fè yon òf pou vann benefis SNAP oswa yon kat EBT an liy oswa an pèsòn, Eta a ka pousuiv yon IPV kont yo.SNAP <strong><em>pou tout tan</em></strong>.</li> */}
                <li>
                  Fè komès (achte oswa vann) benefis SNAP pou zam afe, minisyon
                  oswa eksplozif, yo pa pral kalifye pou SNAP{" "}
                  <strong>pou toujou.</strong>
                </li>
                <li>
                  Fè yon òf pou vann benefis SNAP oswa yon kat EBT sou entènèt
                  oswa an pèsòn Eta a ka pousuiv yon IPV kont yo.
                </li>
                <li>
                  Peye pou manje ou te achte kredi pa pral kalifye pou SNAP.
                </li>
                <li>
                  Achte pwodui ki gen benefis SNAP ak entansyon pou jete kontni yo
                  epi retounen resipyan yo pou kach yo pa pral kalifye pou SNAP.
                </li>
                <li>
                  Sove pou evite pousuit jidisyè, prizon oswa konfinman apre
                  kondanasyon pou yon krim yo pa pral kalifye pou SNAP.
                </li>
                <li>
                  Vyole pwobasyon oswa libète pwovizwa, kote ki fè respekte lalwa
                  ap aktivman chache arete yo, yo pap kalifye pou SNAP.
                </li>
              </ul>
              <p>
                Nenpòt ki moun ki te tounen yon kriminèl kondane apre 7 fevriye
                2014 pa kalifye pou benefis SNAP si yo se yon kriminèl ki nan sove
                oswa si yo ap vyole pwobasyon oswa libète pwovizwa - dapre 7 CFR
                §273.11 (n)-{" "}
                <strong>
                  <u> epi</u>
                </strong>{" "}
                yo te kondane kòm yon adilt pou:
              </p>
              <ol>
                <li>Abi seksyèl grav anba seksyon 2241 nan tit 18, USC;</li>
                <li>Touye moun anba seksyon 1111 nan tit 18, USC;</li>
                <li>Nenpòt ofans anba chapit 110 nan tit 18, USC;</li>
                <li>
                  Yon ofans Federal oswa Etatik ki enplike atak seksyèl, jan sa
                  defini nan seksyon 40002 (a) nan VAWA 1994 la (42 USC 13925a);
                  oswa
                </li>
                <li>
                  Yon ofans dapre lwa Eta ke Pwokirè Jeneral la jije sanble anpil
                  ak yon ofans ki dekri nan lis sa a.
                </li>
              </ol>
              <p>
                <strong>Deklarasyon Non-Diskriminasyon</strong>
              </p>
              <p>
                Konfòmeman ak Lwa Federal sou dwa sivil ak règleman ak politik
                Depatman Agrikilti Etazini (USDA) USDA, Ajans li yo, biwo, ak
                pèsonèl, ak enstitisyon k ap patisipe oswa administre pwogram USDA
                yo entèdi pou fè diskriminasyon ki baze sou ras, koulè, orijin
                nasyonal, sèks, andikap, laj, oswa reprezay oswa vanjans pou
                aktivite dwa sivil ki te pase avan nan nenpòt pwogram oswa
                aktivite ki fèt oswa ki te finanse pa USDA.
              </p>
              <p>
                Moun yo ki gen andikap ki bezwen mwayen altènatif pou kominikasyon
                enfòmasyon sou pwogram nan (pa egzanp Bray, gwo ekriti, kasèt
                odyo, Lang Siy Ameriken, elatriye), ta dwe kontakte Ajans lan
                (etatik oswa lokal) kote yo te aplike pou benefis yo. Moun ki
                soud, mal pou tande oswa ki gen andikap pou pale ka kontakte USDA
                atravè Sèvis Relè Federal la nan (800) 877-8339. Anplis,
                enfòmasyon sou pwogram nan ka disponib nan lang ki pa angle.
              </p>
              <p>Pou pote yon plent kont diskriminasyon nan pwogram nan:</p>
              <ul>
                <li>
                  Ranpli Fòm Plent kont Diskriminasyon Pwogram USDA a, (AD-3027)
                  ke w ap jwenn sou entènèt nan:{" "}
                  <a
                    href="https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf"
                    target="_blank"
                  >
                    https://www.usda.gov/sites/default/files/documents/usda-program-discrimination-complaint-form.pdf
                  </a>
                  ,{" "}
                </li>
                <li>
                  ak nan nenpòt biwo USDA. Ou ka mande yon kopi fòm plent lan nan
                  rele 1-866-632-9992; oswa
                </li>
                <li>
                  Ekri yon lèt adrese bay USDA epi mete nan lèt la tout enfòmasyon
                  yo mande nan fòm nan.
                </li>
              </ul>
              <p>Depoze fòm ou ranpli a oswa lèt la bay USDA pa mwayen:</p>
              <ul>
                <li>
                  sèvis lapòs: Depatman Agrikilti Etazini, Biwo Sekretè Adjwen pou
                  Dwa Sivil, 1400 Independence Avenue, SW, Washington, DC
                  20250-9410;
                </li>
                <li>Faks: 1-202-690-7442; oswa</li>
                <li>
                  imel: 
                  <a href="mailto:program.intake@usda.gov">
                    program.intake@usda.gov
                  </a>
                </li>
              </ul>
              <p>Enstitisyon sa a se yon founisè opòtinite egal.</p>
              <p style={{ textAlign: "center" }}>
                <strong>
                  Èd Tranzisyonèl pou Fanmi ki gen Timoun Depandan (TAFDC) ak{" "}
                  <br />
                  Èd Ijans pou granmoun aje, andikape, ak timoun (EAEDC)
                </strong>
              </p>
              <p>
                TAFDC ak EAEDC se pwogram asistans kach. Pou aprann plis ak pou
                aplike, vizite DHRConnect.com oswa rele biwo lokal DHR w la.
                Enfòmasyon sa a aplikab sèlman pou fwaye k ap aplike pou oswa
                resevwa TAFDC oswa EAEDC.
              </p>
              <p>
                <strong>
                  Kilè mwen bezwen pale ak DHR konsènan chanjman nan fwaye mwen?{" "}
                </strong>
              </p>
              <p>
                Mwen dwe pale ak DHR konsènan chanjman ki ka afekte TAFDC oswa
                EAEDC mwen (benefis kach)<strong>nan espas 10 jou</strong>, sof ke
                mwen pa oblije di DHR konsènan yon chanjman nan salè mwen ki mwens
                pase $ 100 pa mwa. Sa enkli chanjman nan revni m, adrès, ak ki
                moun m ap viv, gwosè fwaye, travay, ak asirans sante
              </p>
              <p>
                <strong>Kijan pou m jwenn asirans sante?</strong>
              </p>
              <ul>
                <li>
                  Si m resevwa TAFDC oswa EAEDC, mwen pral resevwa MassHealth tou.
                </li>
                <li>
                  Si yo refize m TAFDC oswa EAEDC, MassHealth pral itilize
                  enfòmasyon mwen yo pou wè si mwen ka lifye p o u asirans sante.
                </li>
                <li>
                  Si EAEDC mwen sispann, mwen bezwen aplike pou MassHealth
                  separeman. Pou mande yon aplikasyon rele 1-800-841-2900.
                </li>
              </ul>
              <p>
                Si mwen resevwa MassHealth, mwen dakò ke MassHealth ka kolekte:
              </p>
              <ul>
                <li>
                  lajan mwen dwe nan yon lòt sous pou swen medikal mwen, epi
                </li>
                <li>
                  sipò medikal nan men paran absan nenpòt timoun ki poko gen laj
                  19 an ki resevwa benefis MassHealth.
                </li>
              </ul>
              <table style={{ border: "1px solid black" }}>
                <tbody>
                  <tr>
                    <td width="626">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: "10px" }}>
                              <p>
                                <strong>
                                  Èske gen règleman espesyal si m kalifye sèlman
                                  akoz yon aksidan oswa blesi?
                                </strong>
                              </p>
                              <p>
                                Si fanmi mwen resevwa benefis nan men MassHealth
                                oswa DHR akoz yon aksidan oswa blesi, m dwe
                                itilize nenpòt lajan mwen resevwa pou aksidan an
                                oswa blesi a pou ranbouse yo. Lajan an ta ka soti
                                nan yon politik asirans, yon rezolisyon, oswa
                                nenpòt lòt sous. Sa aplikab menm si m pa konnen ki
                                sa sous posibyo pou lajan yedeja.
                              </p>
                              <p>
                                Mwen dakò pou kolabore ak MassHealth ak DHR nan:
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>Depoze reklamasyon pou lajan ki
                                soti nan lòt sous.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>Deklare bay MassHealth ak DHR
                                tousuit konsènan-nenpòt reklamasyon asirans,
                                pwosè, oswa lòt pwo s esis pou jwenn lajan.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>Bay MassHealth ak DHR nouvo
                                enfòmasyon lè mwen genyen li.
                              </p>
                              <p>
                                Si m pa kolabore, MassHealth ak DHR ka sispann
                                oswa rejte benefis mwen yo. Mwen dakò ke
                                MassHealth ak DHR ka:
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>Pataje enfòmasyon sou benefis
                                mwen yo pou kolekte lajan pou ranbouse benefis sa
                                yo.
                              </p>
                              <p style={{ paddingLeft: "20px" }}>
                                <strong>·</strong>Gade tout dosye sou lajan mwen
                                ta ka jwenn akoz aksidan an oswa blesi, tankou
                                dosye nan Depatman aksidan endistriyèl yo.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                       
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
                Si m ap resevwa EAEDC paske mwen gen yon andikap oswa mwen gen
                plis pase 65 an, mwen dwe aplike pou benefis Revni Sekirite
                Siplemantè federal (SSI). Si yo apwouve m pou benefis SSI ki
                kouvri menm peryòd ke mwen te resevwa EAEDC, Administrasyon
                Sekirite Sosyal la pral voye kèk nan SSI retroaktif mwen bay DHR
                pou ranbouse EAEDC a.
              </p>
              <p>
                <strong>Avi Enpòtan konsènan Lwa ak Benefis ou yo</strong>
              </p>
              <p>
                Yon Vyolasyon Entansyonèl Pwogram (IPV) se bay entansyonèlman yon
                deklarasyon ki fo oswa pou twonpe oswa defòme, kache, oswa kenbe
                fè, swa oralman oswa alekri, nan bi pou etabli oswa kenbe
                kalifikasyon pou TAFDC oswa EAEDC, oswa jwenn benefis mwen pa gen
                okenn dwa jwenn.
              </p>
              <p>
                Si yon tribinal, yon odyans diskalifikasyon administratif, jwenn
                mwen koupab de yon IPV, oswa nan siyen yon egzansyon, mwen pral
                diskalifye pou resevwa benefis TAFDC oswa EAEDC pou yon peryòd:
              </p>
              <ul>
                <li>6 mwa pou premye vyolasyon an</li>
                <li>12 mwa pou dezyèm vyolasyon an</li>
                <li>pou toujou pou twazyèm vyolasyon an</li>
              </ul>
              <p>An plis, lòt lwa ka aplike.</p>
              <p>
                <strong>Entèdiksyon sou Acha Kat EBT</strong>
              </p>
              <p>
                Mwen konprann ke li ilegal pou itilize lajan TAFDC oswa EAEDC ki
                sou yon kat transfè elektwonik benefis (EBT) pou peye pou bagay sa
                yo: bwason ki gen alkòl; pwodui tabak; tikè lotri; materyèl oswa
                pèfòmans ki fèt pou adilt; jwèt aza; zam afe ak minisyon; sèvis
                vakans; tatoo; kò pèse kò; bijou; televizyon; stereyo; jwèt videyo
                oswa konsola nan magazen lwe pou achte; marigwana pou lwazi; frè
                tribinal te òdone; amann; kosyon oswa lajan kosyon.
              </p>
              <p>
                <strong>
                  Entèdiksyon sou Kote mwen ka Itilize Kat EBT mwen an
                </strong>
              </p>
              <p>
                Mwen konprann ke li ilegal pou mwen itilize kat transfè elektwonik
                benefis mwen an (EBT) nan kote sa yo: libreri pou adilt; magazen
                ekipman pou adilt oswa etablisman pèfòmans oryante pou adilt;
                minisyon dilè; kazino; kazino jwèt aza oswa etablisman jwèt; bato
                kwazyè; dilè zam afe; magazen bijou; magazen likè; boutik maniki
                oswa boutik estetik; ajans transmisyon lajan kach nan peyi
                etranje; magazen marigwana pou lwazi oswa salon tatoo.
              </p>
              <p>
                <strong>Sanksyon pou acha entèdi ak kat kach EBT</strong>
              </p>
              <ul>
                <li>
                  <strong>Premye ofans</strong>: Mwen dwe remèt DHR montan ki te
                  depanse a.
                </li>
                <li>
                  <strong>Dezyèm ofans</strong>: Mwen dwe remèt DHR montan ki te
                  depanse a epi mwen pral pèdi benefis kach pou{" "}
                  <strong>de mwa</strong>.
                </li>
                <li>
                  <strong>Twazyèm Ofans</strong>: Mwen dwe remèt DHR montan ki te
                  depanse a epi pral pèdi benefis kach
                  <strong> yon fason pèmanan</strong>.
                </li>
              </ul>
              <br />
            </div>
          );
      }
    }
    
  }
}

export default TermsSnapTafdcEaedc;
