import React, { Component } from "react";
import PropTypes from "prop-types";
import AriaModal from "react-aria-modal";
import { enableUniqueIds } from "react-html-id";
import { consumerConst } from "../../../pages/consumer/consumerText";
import * as TextProps from "../../../utils/constants/text";

import Icon from "../dtaIcon";

let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

/*
  DTAModal
  ------

  # Purpose:
  A component for building accessible modal windows using react-aria-modal.

  # Props:
  isOpen:        (Boolean)  Required, is the modal open or not?
  onClose:       (Function) Required, handler for when the modal is closed,
                            (like clicking 'X' button, outside click, 'back', etc)
  titleText:     (String)   Required, title of the modal for screen reader use
  headerText:    (String)   Optional, title to be displayed in modal header
  modalClass:    (String)   Optional, additional classes to add onto modal dialog
  error:         (Boolean)  Optional, does the modal have an error to display?
  errorMesssage: (String)   Optional, error message to display in top of modal body
  children:                 Optional, anything contained inside the DTAModal tag will
                            be rendered inside the modal body

  # Other API:
  showBlur: (Function)
    Shows can overlay which blurs the contents of the modal and displays the Loading
    or Success state animation and message.
    @param  {string} (one of '', 'loading' or 'success') Required, string marking the
                     blur animation desired.
                     '' option will show no animation until hideBlur is called or
                     showBlur is called again.
                     'loading' option will display loading animation until hideBlur
                     is called, or showBlur is called again.
                     'success' will display success animation then close the modal
                     automatically after a brief pause.
    @param  {string} String which is a brief message to be displayed below animation.

  hideBlur: (Function)
    Removes the blur overlay, including any animations and messages.

  To access the modal to call these functions, use a reference to it. Based on
  example below you could call it like this in your component:
  this.exampleModal.showBlur('loading', 'Loading results...');

  # Example:
  <DTAModal ref={instance => this.exampleModal = instance }
            isOpen={ this.state.exampleModalOpen }
            onClose={ this.closeExampleModal }
            modalClass="example-modal"
            titleText="Example Modal"
            headerText="EXAMPLE MODAL"
            error={ this.state.exampleModalError }
            errorMessage={ this.state.exampleModalErrorMessage }>
    <div className="dta-modal__body pad-all">
      <p>The path ahead is dangerous, are you sure you wish to proceed?</p>
      <div className="dta-modal__footer dta-modal__footer--inline-buttons">
        <button className="dta-button dta-button--large dta-button--outline-primary" onClick={ this.closeExampleModal }>Back</button>
        <button className="dta-button dta-button--large dta-button--primary" onClick={ this.continueOnwards }>Yes, Continue</button>
      </div>
    </div>
  </DTAModal>
*/

class DTALoginModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    titleText: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    modalClass: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      modalOpen: TextProps.VALUE_FALSE,
      modalHasEntered: TextProps.VALUE_FALSE,
      showBlur: TextProps.VALUE_FALSE,
      showLoading: TextProps.VALUE_FALSE,
      showSuccess: TextProps.VALUE_FALSE,
      blurMessage: "",
    };

    enableUniqueIds(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        this.activateModal();
      } else {
        this.deactivateModal();
      }
    }
  }

  activateModal = () => {
    this.setState({ modalOpen: TextProps.VALUE_TRUE });
  };

  deactivateModal = () => {
    this.setState(
      {
        modalHasEntered: TextProps.VALUE_FALSE,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalOpen: TextProps.VALUE_FALSE,
            showBlur: TextProps.VALUE_FALSE,
            showLoading: TextProps.VALUE_FALSE,
            showSuccess: TextProps.VALUE_FALSE,
          });
        }, 200); // Timeout here should be roughly same time as CSS transition for modal
      }
    );
  };

  onModalEnter = () => {
    this.setState({ modalHasEntered: TextProps.VALUE_TRUE });
  };

  showBlur = (type, blurMessage = null) => {
    // Set the modal (if applicable) back to top so message shows clearly
    document.getElementById("react-aria-modal-dialog").scrollTop = 0;

    this.setState(
      {
        showBlur: TextProps.VALUE_TRUE,
        showLoading:
          type === "loading" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        showSuccess:
          type === "success" ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE,
        blurMessage: blurMessage
          ? blurMessage
          : type === "loading"
          ? consumerConst(selectedLanguage).loadingLabel
          : type === "success"
          ? "Success!"
          : "",
      },
      () => {
        if (type === "success") {
          setTimeout(() => {
            this.props.onClose();
          }, 2000);
        }
      }
    );
  };

  hideBlur = () => {
    this.setState({
      showBlur: TextProps.VALUE_FALSE,
      showLoading: TextProps.VALUE_FALSE,
      showSuccess: TextProps.VALUE_FALSE,
    });
  };

  getApplicationNode = () => {
    return document.getElementById("root");
  };

  getBlurMsg = (stateVal) => {
    if (stateVal.showSuccess) {
      return (
        <div>
          <svg
            className="checkmark-success"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            aria-hidden={TextProps.VALUE_STR_TRUE}
          >
            <circle
              className="checkmark-success__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark-success__check"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              fill="none"
            />
          </svg>
          {stateVal.blurMessage}
        </div>
      );
    }
    return stateVal.showLoading ? (
      <div>
        <div className="loader" aria-hidden={TextProps.VALUE_STR_TRUE}>
          <div className="loader__spinner"></div>
        </div>
        <div>{stateVal.blurMessage}</div>
      </div>
    ) : null;
  };

  render() {
    let underlayClass = "dta-modal";
    let dialogContentClass = `dta-modal__content ${this.props.modalClass}`;

    if (this.state.modalHasEntered) {
      underlayClass += " has-entered";
      dialogContentClass += " has-entered";
    }

    if (this.state.showBlur) {
      dialogContentClass += " is-blurred";
    }

    return (
      <AriaModal
        mounted={this.state.modalOpen}
        titleText={this.props.titleText}
        onEnter={this.onModalEnter}
        onExit={this.props.onClose}
        initialFocus={"#" + this.nextUniqueId()}
        includeDefaultStyles={TextProps.VALUE_FALSE}
        underlayClass={underlayClass}
        dialogClass={dialogContentClass}
        getApplicationNode={this.getApplicationNode}
        ref={(modal) => (this.modalNode = modal)}
      >
        <div
          className={`dta-modal__blur-wrapper ${
            this.state.showBlur ? "is-blurred" : ""
          }`}
        >
          <div style={{ "padding-left": "1rem" }}>
            <div>
              <h2>{this.props.headerText}</h2>
            </div>

            <button
              className="dta-button--minor close-modal"
              id={this.lastUniqueId()}
              onClick={this.props.onClose}
              style={{ display: "none" }}
            >
              <Icon name="close" label="Close modal" />
            </button>
          </div>
          {this.props.error && this.props.errorMessage ? (
            <div
              className="dta-modal__error-message"
              aria-expanded={this.props.error && this.props.errorMessage}
              aria-live="assertive"
            >
              <p className="notification-message notification-message--warning">
                {this.props.errorMessage}
              </p>
            </div>
          ) : null}
          {this.props.children}
          {this.state.showBlur ? (
            <div className="dta-modal__blur-overlay"></div>
          ) : null}
        </div>
        {this.state.showLoading || this.state.showSuccess ? (
          <div
            className="dta-modal__blur-message"
            aria-expanded={this.state.showLoading || this.state.showSuccess}
            aria-live="assertive"
          >
            {this.getBlurMsg(this.state)}
          </div>
        ) : null}
      </AriaModal>
    );
  }
}

export default DTALoginModal;
