import React, { Component } from 'react'
import * as routeProps from "../../../../utils/constants/routesConstants";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { consumerConst } from "../../../../pages/consumer/consumerText";
import { bindActionCreators } from "redux";
import {
  appealWithDrawDocumentGetCall
} from "../../../../redux/concernsAppeals/concernsAppealsAction"
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer"
import { result } from 'lodash';

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

export class appealWithdrawSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appealReferenceNumber: "",
      getAppealRefrenceNumber: ""

    }
  }



  componentDidMount() {
    let result = this.props.concernsAppeals.withdrawSuccess
    let getValue = result && result.docReferenceNumber;
    let getAppealRefNum = result && result.appealReferenceNumber;
    this.setState({ getAppealRefrenceNumber: getAppealRefNum, appealReferenceNumber: getValue })
  }





  handlePrintClick = () => {

    const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    const agencyID = clientDetails.clientInfo.agencyID;

    let result = this.props.concernsAppeals.withdrawSuccess
    let appealReferenceNumberValue = result && result.docReferenceNumber;
    this.props.appealWithDrawDocumentGetCall(selectedLanguage, agencyID, appealReferenceNumberValue);
  }

  onPrimaryClick() {
    // console.log(this.props ,"{}");
    // this.props.match.path("/consumer/concernappeal")
    // this.props.history.push("/consumer/concernappeal")
    window.location.assign("/consumer/concernappeal");
  }


  render() {
    return (
      <div>
        <div className='max-width-customer--concern  pad-all--double pad-bottom-none'>
          <div className='pad-all--double'>
            <div className="pure-u-1">
              <div>
                <span style={{ display: 'flex', fontSize: '2rem', fontWeight: '600', marginBottom: '10px', padding: '0rem 12rem 0rem 2rem' }}>{consumerConst(selectedLanguage).appealWithDrawsSubmitText}</span>
              </div>
              <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px' }}>
              </div>
            </div>
            <div className="pure-u-1" style={{ display: 'flex', flexDirection: 'column', padding: '0rem 12rem 0rem 2rem' }}>
              <span >{consumerConst(selectedLanguage).widthDrawAppealNumber} {this.state.getAppealRefrenceNumber}

              </span>
              <span style={{ marginTop: '1rem' }}>You may want to <a onClick={this.handlePrintClick} style={{ textDecoration: 'underline', cursor: "pointer" }}>print</a> or <a onClick={this.handlePrintClick} style={{ textDecoration: "underline", cursor: "pointer" }}>save</a> the application you have just submitted for future reference.</span>
              <span style={{ marginTop: '1rem' }}><a onClick={this.handlePrintAppealWithDraw}>Statement of Understanding / Fair Hearing documents.</a></span>
            </div>
          </div>
          <div className="float-left secure" style={{ padding: '0px 12rem 1rem 3.5rem', marginTop: '10px', marginBottom: '2rem' }}>
            <button className="dta-button dta-button--primary" onClick={this.onPrimaryClick}>
              {consumerConst(selectedLanguage).exitLabel}
            </button>
          </div>

        </div>

      </div>

    )


  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      appealWithDrawDocumentGetCall: appealWithDrawDocumentGetCall

    },
    dispatch
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(appealWithdrawSubmit)
