import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import {
  aboutMeMultilanguageLabels,
  incomeMultilanguageLabels,
  beforeYoustartMlanguageLabels,
} from "./applyTafdcText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === "select") {
    language = "en-US";
  }
export const sections = [
  {
    id: "before-you-start",
    title: beforeYoustartMlanguageLabels(language).howApplyLabel, //'How does applying work?',
    header: beforeYoustartMlanguageLabels(language).heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    errorRedirect: TextProps.VALUE_FALSE,
  },
  {
    id: "about-me",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    questions: [
      {
        [Props.TYPE]: Types.CHECKBOX,
        [Props.LABEL]:
          aboutMeMultilanguageLabels(language).contactInfoText1 +
          aboutMeMultilanguageLabels(language).contactInfoText2 +
          ' <a href="https://www.mass.gov/how-to/apply-for-tafdc" target="_blank" rel="noopener noreferrer">' +
          aboutMeMultilanguageLabels(language).contacInfoTextLink +
          "</a>" +
          aboutMeMultilanguageLabels(language).contactInfoText3,
        [Props.LABEL_HAS_MARKUP]: TextProps.VALUE_TRUE,
        [OptionsProps.OPTIONS]: [],
      },
      {
        [Props.TYPE]: Types.DIVIDER,
      },
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelFirstName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: Names.LAST_NAME,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: Names.SUFFIX,
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelSuffix,
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).suffixOption,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: "choose suffix",
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "ssn",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).ssnLabel,
        [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).inlineHelpSSN,
        [Props.TYPE]: Types.INPUT_SSN,
        [Props.CHECKED]: TextProps.VALUE_FALSE,
        [InputProps.CLEAR_LABEL]:
          aboutMeMultilanguageLabels(language).inputClearLabel,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).ssnErrMsg,
      },
      {
        [Props.NAME]: "ap-id",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).haveAgencyID,
        [Props.TYPE]: Types.INPUT_NUMERIC_ONLY,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).haveAgencyIDErrMsg,
        [Props.MAX_LENGTH]: 9,
      },
      {
        [Props.NAME]: "address",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).addrQuestionlabel,
        [Props.TYPE]: Types.ADDRESS,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_CHAR]:
          aboutMeMultilanguageLabels(language).citiErrmsg,
        [Props.ERROR_MESSAGE_FOR_ADDRESS]:
          aboutMeMultilanguageLabels(language).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
          aboutMeMultilanguageLabels(language).zipCodeErrmsg,
        [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
          aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
      },
      {
        [Props.NAME]: "phoneNumber",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
        [Props.TYPE]: Types.INPUT_PHONE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).validPhoneAlert,
      },
      {
        [Props.NAME]: "emailAddress",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).emailAddressLabel,
        [Props.TYPE]: Types.INPUT_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).emailErrormsg,
        [Props.MAX_LENGTH]: 100,
      },
      {
        [Props.NAME]: "language-preference",
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]:
            aboutMeMultilanguageLabels(language).languageBefore,
          [Props.LABEL_OBJ_AFTER]:
            aboutMeMultilanguageLabels(language).languageAfter,
          [Props.LABEL_OBJ_TRIGGER]:
            aboutMeMultilanguageLabels(language).languageTrigger,
          [Props.LABEL_OBJ_HELP]:
            aboutMeMultilanguageLabels(language).comfortLanHelp,
          [Props.REQUIRED]: TextProps.VALUE_TRUE,
          [Props.ERROR]: TextProps.VALUE_FALSE,
          [Props.ERROR_MESSAGE]:
            aboutMeMultilanguageLabels(language).languageErrMsg,
        },
        [Props.TYPE]: Types.SELECT,
        [SelectProps.OPTIONS]: aboutMeMultilanguageLabels(language).LANGUAGES,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).languageErrMsg,
      },
      {
        [Props.NAME]: "is-pregnant",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).pregnant,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).pregnatErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "is-child-live-with-you",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).childLiveYou,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).childLiveYouErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },

      {
        [Props.NAME]: "is-any-assistive-technology",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).assistiveTechnology,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).assistiveTechnologyErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },

      {
        [Props.NAME]: "income-source",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).sourceOfIncome,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).sourceIncomeErrMsg,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_YES,
            [OptionsProps.OPTION_DISPLAY]:
              aboutMeMultilanguageLabels(language).houseLessOptions[0],
          },
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_NO,
            [OptionsProps.OPTION_DISPLAY]:
              aboutMeMultilanguageLabels(language).houseLessOptions[1],
          },
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,

        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]: TextProps.VALUE_YES,
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "source",
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]:
                    aboutMeMultilanguageLabels(language).sourceIncomeOne,

                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    aboutMeMultilanguageLabels(language).languageErrMsg,
                },
                [Props.TYPE]: Types.SELECT_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [SelectProps.OPTIONS]:
                  aboutMeMultilanguageLabels(language).incomeSourceList,
              },
              {
                [Props.NAME]: "amount",
                [Props.INPUT_LABEL]:
                  aboutMeMultilanguageLabels(language).monthlyAmount,
                [Props.TYPE]: Types.INPUT_NUMERIC_ONLY_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).apIDErrMsg,
                [Props.MAX_LENGTH]: 10,
              },
              {
                [Props.NAME]: "source-two",
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]:
                    aboutMeMultilanguageLabels(language).sourceIncomeOne,

                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    aboutMeMultilanguageLabels(language).languageErrMsg,
                },
                [Props.TYPE]: Types.SELECT_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [SelectProps.OPTIONS]:
                  aboutMeMultilanguageLabels(language).incomeSourceList,
              },
              {
                [Props.NAME]: "amount-two",
                [Props.INPUT_LABEL]:
                  aboutMeMultilanguageLabels(language).monthlyAmount,
                [Props.TYPE]: Types.INPUT_NUMERIC_ONLY_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).apIDErrMsg,
                [Props.MAX_LENGTH]: 10,
              },
              {
                [Props.NAME]: "source-three",
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]:
                    aboutMeMultilanguageLabels(language).sourceIncomeOne,
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    aboutMeMultilanguageLabels(language).languageErrMsg,
                },
                [Props.TYPE]: Types.SELECT_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [SelectProps.OPTIONS]:
                  aboutMeMultilanguageLabels(language).incomeSourceList,
              },
              {
                [Props.NAME]: "amount-three",
                [Props.INPUT_LABEL]:
                  aboutMeMultilanguageLabels(language).monthlyAmount,
                [Props.TYPE]: Types.INPUT_NUMERIC_ONLY_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).apIDErrMsg,
                [Props.MAX_LENGTH]: 10,
              },
              {
                [Props.NAME]: "source-four",
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]:
                    aboutMeMultilanguageLabels(language).sourceIncomeOne,
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    aboutMeMultilanguageLabels(language).languageErrMsg,
                },
                [Props.TYPE]: Types.SELECT_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [SelectProps.OPTIONS]:
                  aboutMeMultilanguageLabels(language).incomeSourceList,
              },
              {
                [Props.NAME]: "amount-four",
                [Props.INPUT_LABEL]:
                  aboutMeMultilanguageLabels(language).monthlyAmount,
                [Props.TYPE]: Types.INPUT_NUMERIC_ONLY_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).apIDErrMsg,
                [Props.MAX_LENGTH]: 10,
              },
              {
                [Props.NAME]: "source-five",
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]:
                    aboutMeMultilanguageLabels(language).sourceIncomeOne,
                  [Props.REQUIRED]: TextProps.VALUE_TRUE,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]:
                    aboutMeMultilanguageLabels(language).languageErrMsg,
                },
                [Props.TYPE]: Types.SELECT_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [SelectProps.OPTIONS]:
                  aboutMeMultilanguageLabels(language).incomeSourceList,
              },
              {
                [Props.NAME]: "amount-five",
                [Props.INPUT_LABEL]:
                  aboutMeMultilanguageLabels(language).monthlyAmount,
                [Props.TYPE]: Types.INPUT_NUMERIC_ONLY_TWO,
                [Props.REQUIRED]: TextProps.VALUE_FALSE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).apIDErrMsg,
                [Props.MAX_LENGTH]: 10,
              },
            ],
          },
        ],
      },

      {
        [Props.NAME]: "need-ebt-card",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).needEbtCard,
        [Props.INLINE_HELP]:
          aboutMeMultilanguageLabels(language).inlineHelpEbtcard,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
    ],
    // completed: TextProps.VALUE_FALSE
  },

  {
    id: "sign-and-submit",
    title: incomeMultilanguageLabels(language).signSubmitLabel, //'Sign & submit',
    header: incomeMultilanguageLabels(language).signSubmitHeader, //'Sign & submit',
    subHeader: incomeMultilanguageLabels(language).signSubmitSub, //'You made it through the form! Now for the easy stuff.',
    subsections: [
      {
        id: "submitted",
        title: incomeMultilanguageLabels(language).subSectionLabel, //'Submitted',
        header: incomeMultilanguageLabels(language).submitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
  },
];

export default sections;
