const config = require("config");

//Service API PROD ENV
const BaseUrl = config.serviceURL;
const homeUrl = config.appHomeUrl;
const TigerURL = config.tigerURL;
const TigerContextURL = config.azureLoginFlag
  ? config.tigerURL + "/res"
  : config.tigerURL + "/api";
const idleTimeout = config.idleTimeout; // 20 minutes
const applyTimeOut = config.applyTimeOut; // 2 minutes
const azureLoginFlag = config.azureLoginFlag;
//Axios Http Options
const HttpOptions = {
  headers: { "Content-Type": "application/json" },
  mode: "no-cors",
  "Access-Control-Allow-Origin": config.allowed_domain,
};

const doAddressValidate = config.addressValidate;

//rights and responsibility pdf URLs
const rights_en_url = config.rights_en_url;
const rights_es_url = config.rights_es_url;
const rights_pt_url = config.rights_pt_url;
const rights_zh_url = config.rights_zh_url;
const rights_vi_url = config.rights_vi_url;

const source = config.source;

//google analytics Configurations
const TRACKING_ID = config.TRACKING_ID; // DEV ENV Only
//this.trackId ='UA-112764375-3'; // PROD CONFIG
const CLIENT_ID = config.CLIENT_ID;

// site key for Dev
const SITE_KEY = config.SITE_KEY;
const SECRETE_KEY = config.SECRETE_KEY;

const username = config.tiger_login.username;
const password = config.tiger_login.password;

const loginScreenData_un = config.login_screen_data.username;
const loginScreenData_pwd = config.login_screen_data.password;

const programComplainLink = config.programComplainLink;
//flag to enable and disable invisible recaptcha
const RECAPTCHA_FLAG = config.RECAPTCHA_FLAG;

module.exports = {
  BaseUrl: BaseUrl,
  homeUrl: homeUrl,
  HttpOptions: HttpOptions,
  TigerURL: TigerURL,
  TigerContextURL: TigerContextURL,
  idleTimeout: idleTimeout,
  applyTimeOut: applyTimeOut,
  SITE_KEY: SITE_KEY,
  SECRETE_KEY: SECRETE_KEY,
  RECAPTCHA_FLAG: RECAPTCHA_FLAG,
  TRACKING_ID: TRACKING_ID,
  CLIENT_ID: CLIENT_ID,
  rights_en_url: rights_en_url,
  rights_es_url: rights_es_url,
  rights_pt_url: rights_pt_url,
  rights_vi_url: rights_vi_url,
  rights_zh_url: rights_zh_url,
  source,
  allowed_domain: config.allowed_domain,
  username: username,
  password: password,
  loginScreenData_un: loginScreenData_un,
  loginScreenData_pwd: loginScreenData_pwd,
  programComplainLink: programComplainLink,
  azureLoginFlag: azureLoginFlag,
  doAddressValidate: doAddressValidate
};
