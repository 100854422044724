import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { enableUniqueIds } from "react-html-id";
import { requiredIf } from "../utilities/proptypes";
import SidebarNavigationSection from "./sidebarNavigationSection";
import {
  screeningStaticLabels,
  copyrightLabels,
} from "../../../pages/screening/screeningText";
import * as routeProps from "../../constants/routesConstants";
import * as helperFunction from "../helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { deleteWebappData } from "../../../redux/applySnap/applySnapActions";
import {
  DEPT_OF_TRANS_ASS_LOCATIONS,
  SITE_POLICIES,
  alabama,
  langOptSel
} from "../../constants/constants";
import { sanitizeURL } from "../utilities/utils";

let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (selectedLanguage === null || selectedLanguage === langOptSel) {
    selectedLanguage = "en-US";
  }
class SidebarNavigation extends Component {
  static propTypes = {
    /*
          sections: (array, required)
            An array of sections of the application. The navigation makes use of
            of the following properties on a section item.
          {
            id: (string, required) unique ID for section,
            title: (string, required if not hidden) Title to display in navigation,
            subsections: (array, optional) List of subsections, of the same format as a section
            hiddenFromNav: (boolean, optional) Should the section not be displayed in the navigation list?
            completed: (boolean, required) Has the user finished this section?
                       Note: Since few inputs are required, 'Completed' doesn't
                             necessarily mean all inputs filled in, but that the
                             user has visited that section and clicked 'Continue' button
          }
        */
    sections: PropTypes.array.isRequired,
    /*
          activeSectionId: (string, required)
            The id of the currently active (being viewed) apply section
        */
    activeSectionId: PropTypes.string.isRequired,
    /*
          inProgressSectionId: (string, required)
            The id of the furthest section the user has reached in filling out form.
            It is not necessarily the section being viewed, since the user can go back.
            This prop is required to help determine which sections are navigable.
        */
    inProgressSectionId: PropTypes.string.isRequired,
    /*
          numSectionsRemaining: (int, required)
            The total number of uncompleted first level sections (e.g. "Income", "Expenses", "Sign and submit")
        */
    numSectionsRemaining: PropTypes.number.isRequired,
    /*
          onSectionClick: (function, required)
            Handler for when a navigation section item is clicked.
        */
    onSectionClick: PropTypes.func.isRequired,
    /*
          canSaveProgress: (bool, optional)
            Configure if user can 'save progress' on application at this time. This shows the 'Save my
            progress for later button' and also triggers onSaveProgress on home logo click.
            If TextProps.VALUE_TRUE, requires following onSaveProgress prop.
        */
    canSaveProgress: PropTypes.bool,
    /*
          onSaveProgress: (function, optional)
            Required if canSaveProgress is TextProps.VALUE_TRUE.
            How to handle when the user is about the leave the form prematurely.
            This includes clicking both "dta-logo" to return home as well as the
            "Save my progress for later" button.
        */
    onSaveProgress: requiredIf(
      PropTypes.func,
      (props) => props.canSaveProgress
    ),
    /*
          skipToAnchor: (string, required)
            The HTML ID of the content that is to be focused on if the user
            decides to skip the navigation menu.
        */
    skipToAnchor: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    enableUniqueIds(this);
    this.state = {
      navOpen: TextProps.VALUE_FALSE,
      navDisplay: TextProps.VALUE_FALSE,
    };
  }

  openNav = () => {
    this.setState({
      navDisplay: TextProps.VALUE_TRUE,
      navOpen: TextProps.VALUE_TRUE,
    });
  };

  closeNav = () => {
    this.setState({
      navOpen: TextProps.VALUE_FALSE,
    });
  };

  // In order to set content of menu to "display: none;" CSS when closed,
  // but still show transition, a transition event listener is needed
  onNavTransitionEnd = (e) => {
    if (e.target.tagName === "NAV") {
      if (!this.state.navOpen) {
        this.setState({ navDisplay: TextProps.VALUE_FALSE });
      }
    }
  };

  onSectionClick = (event, section) => {
    // Always close the nav when clicking a section,
    // in order to reveal content below (mobile)
    this.closeNav();
    this.props.onSectionClick(section, TextProps.VALUE_TRUE, event);
  };

  onSkipNav = () => {
    // Always close the nav when clicking skip link,
    // in order to reveal content below (mobile)
    this.setState({ navOpen: TextProps.VALUE_FALSE });
  };

  onHomeClick = () => {
    if (this.props.canSaveProgress) {
      this.props.onSaveProgress();
    }
  };

  render() {
    const { info } = this.props.language.consumer;
    var filterQuestions = [];
    if (
      this.props.inProgressSectionId === "pebtCaseInfo" &&
      this.props.inProgressSectionId === "replacepebt"
    ) {
      filterQuestions =
        this.props.sections[1] &&
        this.props.sections[1].questions.filter(
          (value) => value.error === TextProps.VALUE_TRUE
        );
    }
    var arrayPath = [
      routeProps.SNAP_SIX_MONTH_REPORT_PATH,
      routeProps.COVID_RECERT_PATH,
      routeProps.RECERTIFICATION_PATH,
      routeProps.DECLARE_EXPENSES_PATH,
      routeProps.APPLY_PATH
    ];
    var greeterPath = [routeProps.GREETER_ROLE];
    var toPath;
    if (arrayPath.includes(window.location.pathname)) {
      toPath = routeProps.CONSUMER_PATH;
    } else if (greeterPath.includes(window.location.pathname)) {
      toPath = routeProps.GREETER_ROLE;
    } else {
      toPath = routeProps.HOME_PATH;
    }

    return (
      <nav
        className={`nav apply__nav ${this.state.navOpen ? "nav-open" : ""} ${
          this.state.navDisplay ? "nav-visible" : ""
        }
            ${
              this.props.activeSectionId === "replacepebt" &&
              filterQuestions.length === 0
                ? "apply__nav-pebt-replace"
                : this.props.activeSectionId === "replacepebt" &&
                  filterQuestions.length > 0
                ? "apply__nav-pebt-replace-err"
                : this.props.activeSectionId === "pebtCaseInfo" &&
                  this.props.pebtCaseNumber === ""
                ? "apply__nav-pebt-case-info"
                : this.props.activeSectionId === "pebtCaseInfo" &&
                  this.props.pebtCaseNumber !== ""
                ? "apply__nav-pebt-case-info-success"
                : ""
            } 
            `}
        onTransitionEnd={this.onNavTransitionEnd}
      >
        <div className="navbar">
          {window.location.pathname === "/pebtCaseInfo" ||
          window.location.pathname === "/replacepebt" ? null : (
            <ul className="nav-list navbar__nav-list">
              <li className="nav-list__item nav-list__item--grow">
                <button
                  className={`dta-button dta-button--outline-white dta-button--block ${
                    this.state.navOpen ? "is-active" : ""
                  }`}
                  onClick={this.state.navOpen ? this.closeNav : this.openNav}
                >
                  How Close Am I?
                </button>
              </li>
            </ul>
          )}
        </div>
        <div className="nav__menu">
          {this.props.numSectionsRemaining &&
          this.props.numSectionsRemaining !== undefined &&
          this.props.numSectionsRemaining !== "" ? (
            <p className="hidden-desktop text--large text--center pad-horizontal">
              {this.props.numSectionsRemaining} sections left
            </p>
          ) : null}
          {this.props.activeSectionId == "pebtCaseInfo" ||
          this.props.activeSectionId == "replacepebt" ? null : (
            <ul className="nav-list nav-list--column">
              <li key={this.nextUniqueId()} className="nav-list__item">
                <a
                  href={sanitizeURL(this.props.skipToAnchor)}
                  className="sr-only sr-focusable"
                  onClick={this.onSkipNav}
                >
                  Skip to main content
                </a>
              </li>
              {this.props.sections.map((section) =>
                section.hiddenFromNav === undefined ||
                section.hiddenFromNav === TextProps.VALUE_FALSE ? (
                  <SidebarNavigationSection
                    pebt={this.props.pebt}
                    key={section.id}
                    section={section}
                    activeSectionId={this.props.activeSectionId}
                    inProgressSectionId={this.props.inProgressSectionId}
                    onSectionClick={this.onSectionClick}
                    className="nav-list__item nav-list__item--bordered"
                  />
                ) : null
              )}
            </ul>
          )}{window.location.pathname === routeProps.SNAP_SIX_MONTH_REPORT_PATH ? (!this.props.isPreScreening && this.props.activeSectionId !== "submitted") && (
            this.props.onDeleteClick && <button
              className="dta-button dta-button--primary dta-button-delete"
              onClick={(event) => this.props.onDeleteClick(event)}
            >
            {info.deleteWebAppInterim}
            </button>
          ) : (!this.props.isPreScreening && this.props.activeSectionId !== "submitted") && (
            this.props.onDeleteClick && <button
            className="dta-button dta-button--primary dta-button-delete"
            onClick={(event) => this.props.onDeleteClick(event)}
          >
            {this.props.recertType ? 
            this.props.recertType === "AESAP" ? info.deleteSnapAesapRecertification : 
            this.props.recertType === "RECERT" ? info.deleteSnapRecertification : 
            this.props.recertType === "IR" ? info.deleteWebAppInterim :
            this.props.recertType === "REEVAL" ? info.deleteWebAppTafdc : 
            info.deleteWebApp : info.deleteWebApp}
            </button>
          )}
        
          <div className="nav__footer">
            <div className="nav__footer-content">
              {window.location.pathname !== routeProps.GREETER_ROLE && (
                <p className="text--small">
                  {screeningStaticLabels(selectedLanguage).needHelpFeedback}
                  <br />
                  <a
                    href={DEPT_OF_TRANS_ASS_LOCATIONS}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dta-link"
                  >
                    {screeningStaticLabels(selectedLanguage).ContactUs}
                    <span className="sr-only">
                      {screeningStaticLabels(selectedLanguage).OpensNewTab}
                    </span>
                  </a>
                </p>
              )}
              {window.location.pathname !== routeProps.GREETER_ROLE && (
                <p className="text--small">
                  <a
                    href={SITE_POLICIES}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dta-link"
                  >
                    {screeningStaticLabels(selectedLanguage).sitePolicies}
                    <span className="sr-only">
                      {screeningStaticLabels(selectedLanguage).OpensNewTab}
                    </span>
                  </a>
                </p>
              )}
              <p className="text--small">
                {
                  /*   &copy; 2018 Commonwealth of Alabama */
                  copyrightLabels(selectedLanguage)
                }
              </p>
            </div>
            <button
              className="dta-button dta-button--block dta-button--secondary dta-button--no-radius hidden-desktop"
              onClick={this.closeNav}
            >
              {screeningStaticLabels(selectedLanguage).close}
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteWebApp: deleteWebappData,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavigation);
