import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import * as DocumentProps from "../../../constants/document";
import Icon from "../../dtaIcon";
import * as formatters from "../../utilities/formatters";
import { getDocumentDesc } from "../../utilities/utils";
import * as helperFunction from "../../helperFunctions/helperFunctions";
import * as TextProps from "../../../../utils/constants/text";
import moment from "moment";

/*
  Document ListItem contents
  --------------------------

  # Purpose:
  To display a document in a list

  # Props:
  [DocumentProps.NAME]: Required, string name of the document
  [DocumentProps.FOR]: Required, string representing the person of the name the document pertains to
  [DocumentProps.STATUS]: Required, status of the document, one of STATUS_PROCESSING,
                          STATUS_ACCEPTED, or STATUS_DENIED
  [DocumentProps.RECEIVED]: Optional, Date indicating when this document was received
  [DocumentProps.WHEN_STATUS_CHANGED]: Optional, Date indicating when the status of changed

  # Example:
  <DocumentContents {...props} />
 */

class DocumentContents extends Component {
  static propTypes = {
    [DocumentProps.DOCUMENT_NAME]: PropTypes.string.isRequired,
    [DocumentProps.CLIENT_NAME]: PropTypes.string.isRequired,
    [DocumentProps.STATUS]: PropTypes.string.isRequired,
    [DocumentProps.STATUS]: PropTypes.oneOf([
      DocumentProps.STATUS_PROCESSING,
      DocumentProps.STATUS_ACCEPTED,
      DocumentProps.STATUS_DENIED,
      DocumentProps.STATUS_INADEQUATE,
      DocumentProps.STATUS_NOT_ENTERED,
      DocumentProps.STATUS_AVAILABLE,
      DocumentProps.STATUS_ENTERED,
      DocumentProps.STATUS_REVIEWED,
      DocumentProps.STATUS_UNIDENTIFIABLE,
    ]),
    [DocumentProps.DISPOSITION]: PropTypes.string.isRequired,
    [DocumentProps.DISPOSITION]: PropTypes.oneOf([
      DocumentProps.DISPOSITION_PENDING_REVIEW,
      DocumentProps.DISPOSITION_PENDING_AGENCY_REVIEW,
      DocumentProps.DISPOSITION_REVIEWED_AND_ENTERED,
      DocumentProps.DISPOSITION_REVIEWED_DUPLICATE,
      DocumentProps.DISPOSITION_REVIEWED_NOTRELEVANT,
      DocumentProps.DISPOSITION_REVIEWED_PENDING_INTERVIEW,
      DocumentProps.DISPOSITION_UNIDENTIFIABLE,
      DocumentProps.DISPOSITION_UNREADABLE,
      DocumentProps.DISPOSITION_UNSIGNED,
      DocumentProps.DISPOSITION_INCOMPLETE,
      DocumentProps.DISPOSITION_INCOMPLETE_AND_UNSIGNED,
      DocumentProps.DISPOSITION_NOTENTERED_DUE_TO_CASESTATUS,
    ]),
    [DocumentProps.EXT_DOC_ID]: PropTypes.string.isRequired,
    [DocumentProps.DOCUMENT_SOURCE]: PropTypes.string.isRequired,
    [DocumentProps.STATUS_AVAILABLE]: PropTypes.instanceOf(Date),
    [DocumentProps.STATUS_CHANGED_DATE]: PropTypes.instanceOf(Date),
  };

  render() {
    const { common } = this.props.language.consumer;
    const disposition = this.props[DocumentProps.DISPOSITION];
    const receivedDate = this.props[DocumentProps.RECEIVED_DATE],
      statusChangedDate = this.props[DocumentProps.STATUS_CHANGED_DATE],
      createdDate = this.props[DocumentProps.CREATED_DATE];
    const dateOfBirth = this.props[DocumentProps.DATE_OF_BIRTH];

    const selectedLanguage = helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
    let typeDesc = getDocumentDesc(selectedLanguage)[this.props[DocumentProps.DOCUMENT_NAME]];
    if(!typeDesc){
      typeDesc = this.props.subTypeDesc
    }
    return (
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2">
          <div className="text--bold text--medium">
            {typeDesc}
          </div>
          <div>
            for {this.props.clientName[DocumentProps.CLIENT_NAME_LAST]},{" "}
            {this.props.clientName[DocumentProps.CLIENT_NAME_FIRST]}{" "}
            {`${formatters.convertStringDateToWithFormat(dateOfBirth, {
                humanize: TextProps.VALUE_TRUE,
            })}`}
            {receivedDate ? `, ` + common.received : ""}{" "}
            {receivedDate && ` ${moment(
            receivedDate,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ).format("MM/DD/YYYY hh:mm A")}`}
          </div>
        </div>
        <div className="show-mobile show-small pure-u-1 pad-top-half" />
        <div className="pure-u-1 pure-u-md-1-2 text--right">
          <div>{this._buildIndicator(disposition)}</div>
          {createdDate &&
            <div>
              {common.submittedOn}{" "}
              <span className="text--bold">
              {formatters.formatDateBySlash(createdDate, {
                    humanize: TextProps.VALUE_TRUE,
              })}
              </span>
            </div>
          }
          {statusChangedDate &&
            <div>
              {common.statusChange}{" "}
              <span className="text--bold">
              {formatters.formatDateBySlash(statusChangedDate, {
                    humanize: TextProps.VALUE_TRUE,
              })}
              </span>
            </div>
          }
        </div>
      </div>
    );
  }

  _buildIndicator(disposition) {
    const { documentupload } = this.props.language.consumer;
    switch (disposition) {
      case DocumentProps.DISPOSITION_PENDING_REVIEW:
        return (
          <span className="status status--warning">
            {documentupload.pendingReview}
          </span>
        );
      case DocumentProps.DISPOSITION_PENDING_AGENCY_REVIEW:
        return (
          <span className="status status--warning">
            {documentupload.pendingAgencyReview}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_PENDING_INTERVIEW:
        return (
          <span className="status status--success">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedPendingInterview}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_AND_ENTERED:
        return (
          <span className="status status--success">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedAndEntered}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_DUPLICATE:
        return (
          <span className="status status--success">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedDuplicate}
          </span>
        );
      case DocumentProps.DISPOSITION_REVIEWED_NOTRELEVANT:
        return (
          <span className="status status--success">
            <Icon name="checkmark" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.reviewedNotRelevant}
          </span>
        );
      case DocumentProps.DISPOSITION_NOTENTERED_DUE_TO_CASESTATUS:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.notenteredDuetoCaseStatus}
          </span>
        );
      case DocumentProps.DISPOSITION_UNIDENTIFIABLE:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unidentifiable}
          </span>
        );
      case DocumentProps.DISPOSITION_UNREADABLE:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unreadable}
          </span>
        );
      case DocumentProps.DISPOSITION_UNSIGNED:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unsigned}
          </span>
        );
      case DocumentProps.DISPOSITION_INCOMPLETE:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.incomplete}
          </span>
        );
      case DocumentProps.DISPOSITION_INCOMPLETE_AND_UNSIGNED:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.incompleteAndUnsigned}
          </span>
        );
      default:
        return (
          <span className="status status--error">
            <Icon name="close" ariaHidden={TextProps.VALUE_TRUE} />
            {documentupload.unidentifiable}
          </span>
        );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentContents);
