import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

import {
  addStringIf,
  validateAria,
  tryCall,
} from "../components/utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import * as TextProps from "../constants/text";

class OptionItem extends Component {
  static propTypes = {
    ...CommonTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    // handlers
    onChange: PropTypes.func.isRequired,
    doBuildOptionItemContents: PropTypes.func,
    // states
    checked: PropTypes.bool.isRequired,
    // container
    "aria-describedby": PropTypes.string,
    index: PropTypes.number,
    // classes
    controlClass: PropTypes.string.isRequired,
    labelClass: PropTypes.string.isRequired,
    errorClass: PropTypes.string.isRequired,
    textClass: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };
  static defaultProps = {
    ...CommonDefaults,
    "aria-describedby": "",
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
  }

  render() {
    const {
        error,
        required,
        index,
        controlClass,
        errorClass,
        textClass,
        text,
        doBuildOptionItemContents,
        count,
      } = this.props,
      ariaLabels = this.props["aria-labelledby"],
      thisId = this.nextUniqueId();

    return (
      <li className={this.props.className}>
        <input
          name={this.props.name}
          value={this.props.value}
          type={this.props.type}
          onChange={this.props.onChange}
          checked={this.props.checked}
          disabled={this.props.disabled}
          className={addStringIf(error, controlClass, errorClass)}
          aria-labelledby={validateAria(
            ariaLabels ? `${ariaLabels} ${thisId}` : null
          )}
          aria-describedby={validateAria(this.props["aria-describedby"])}
          aria-invalid={
            error ? TextProps.VALUE_STR_TRUE : TextProps.VALUE_STR_FALSE
          }
          id={thisId}
          required={required}
          data-index={index}
        />
        <label className={this.props.labelClass} htmlFor={thisId}>
          {this._buildContents(
            doBuildOptionItemContents,
            textClass,
            text,
            count
          )}
        </label>
      </li>
    );
  }

  // Rendering
  // ---------

  _buildContents(builderFunc, textClass, textContents, count) {
    if (builderFunc) {
      return tryCall(builderFunc, textClass, textContents, count);
    } else {
      return <span className={textClass}>{textContents}</span>;
    }
  }
}

export default OptionItem;
