import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import Radio from "./../../../utils/components/radio";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import { formatMoney } from "../../../utils/components/utilities/formatters";
import {
  setDataChangeStatus,
  setHouseholdMembers,
  setEarnedIncomeChangeStatus,
  appendIRIncomeDetails,
  clearMemberIncome,
} from "../../../redux/recertCovid/recertCovidActions";
import { IncomeSectionTemplate } from "../recertCovidMapData";
import * as languageConstants from "../../../utils/constants/constants";
import { interimReportPageLabels } from "../recertCovidText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class EarnedIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
    };
    enableUniqueIds(this);
  }

  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.covidRecert.sections,
        respData: this.props.covidRecert.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.covidRecert.setOldIncomeData === TextProps.VALUE_TRUE) {
      this.onEarnedIncomeChanged(
        interimReportPageLabels(
          language,
          languageConstants.aboutMe,
          null,
          null,
          null
        ).optionYesNo[1]
      );
    }
  }

  earnedIncomeSummaryEditClick = (section) => {
    this.setState({ incomeSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };

  toggleAddIncomeMembers = () => {
    this.setState({ addIncomeMembersOpen: !this.state.addIncomeMembersOpen });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  onEarnedIncomeSubSectionClean = () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          incomeSummaryTemplate:
            this.props.covidRecert.sections[3].subsections[
              this.props.covidRecert.sections[3].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            this.props.covidRecert.sections[3]
          );
          this.props.onEarnedIncomeSubsectionRender();
        }
      );
    }
  };

  onEarnedIncomeSubSectionRetain = () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          incomeSummaryTemplate:
            this.props.covidRecert.sections[3].subsections[
              this.props.covidRecert.sections[3].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            this.props.covidRecert.sections[3],
            TextProps.VALUE_FALSE
          );
          this.props.onEarnedIncomeSubsectionRender(
            this.state.incomeMemberTemplate
          );
        }
      );
    }
  };

  onEarnedIncomeChanged = (value) => {
    if (
      value ===
      interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1]
    ) {
      this.setState(
        {
          incomeValuesPopulated: TextProps.VALUE_FALSE,
          incomeSubSectionCleared: TextProps.VALUE_FALSE,
          earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
        },
        () => {
          this.onEarnedIncomeSubSectionRetain();
          this.props.setEarnedIncomeChangeStatus(TextProps.VALUE_FALSE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 3);
        }
      );
    } else {
      this.setState(
        {
          earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
        },
        () => {
          this.props.setEarnedIncomeChangeStatus(TextProps.VALUE_TRUE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 3);
        }
      );
    }
  };

  renderIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = _.cloneDeep(IncomeSectionTemplate);
    if (incomeMeTemplate.questions === undefined) {
      incomeMeTemplate = IncomeSectionTemplate;
    }
    var incomeCopy = this.props.covidRecert.sections[3];
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);

    incomeMeTemplate.id = "combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).mainQuestion;
    incomeMeTemplate.questions[0].Answer = "";
    incomeMeTemplate.questions[0].value = "";
    incomeMeTemplate.questions[0].targetValue = "";
    incomeMeTemplate.subsections[0].id =
      "combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.title = name;
    let incomeReturn = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    );

    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
        incomeReturn.subQuestions[j][Props.LABEL];
      incomeMeTemplate.subsections[0].questions[j].amountLabel =
        incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].employerLabel =
        incomeReturn.subQuestions[j].employerLabel;
      incomeMeTemplate.subsections[0].questions[j].unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[
        j
      ].repeatedQuestion.amountLabel = incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion.unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[
        j
      ].repeatedQuestion.employerLabel =
        incomeReturn.subQuestions[j].employerLabel;
      incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
        TextProps.VALUE_TRUE;
      incomeMeTemplate.subsections[0].questions[j].Answer = "";
      incomeMeTemplate.subsections[0].questions[j].value = "";
      incomeMeTemplate.subsections[0].questions[j].targetValue = "";
    }
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendIRIncomeDetails(incomeCopy);
    this.props.prepareEarnedIncomeSummary();
    this.props.onSectionChange(incomeMeTemplate);
  };

  renderEarnedIncomeSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>

        <p className="interim-recert-heading apply__body-header help-text-fix">
          <HelpTip
            triggerText={
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).sIncometrigger
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).after
            }
            helpText={
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).sIncomeHelp
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
        {this.props.covidRecert.earnedIncomeChanged &&
        this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.earnedIncomeSummaryEditClick(summary.section)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}
        <ul>
          {summary.incomes.map((incomeItem) => {
            if (
              incomeItem.Answer !== undefined &&
              incomeItem.Answer.length > 0
            ) {
              return incomeItem.Answer.map((answer) => {
                if (answer.unit && answer.amount) {
                  let label = "";
                  if (answer.code !== undefined) {
                    if (answer.code === "new") {
                      label = "newLabel";
                    } else if (answer.code === "changed") {
                      label = "changedLabel";
                    }
                  }
                  return (
                    <li key={answer.id}>
                      <span className="spaced-text">
                        {incomeItem.type}{" "}
                        {
                          interimReportPageLabels(
                            language,
                            languageConstants.beforeYouStart
                          ).fromMultiLang
                        }{" "}
                        {answer.employer}
                      </span>
                      {label != "" ? (
                        <DataChangeIndicator
                          text={
                            interimReportPageLabels(
                              language,
                              languageConstants.householdStatic,
                              null,
                              null,
                              null
                            )[label]
                          }
                        />
                      ) : null}
                      {this.amountIteratorForIncome(answer.unit, answer.amount)}
                    </li>
                  );
                }
              });
            }
          })}
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;
    if (getFrequencyCode(language)[unit] === "WEEK") {
      amountIterator = 4;
      classRowCount = 24;
    } else if (getFrequencyCode(language)[unit] === "BIWEEK") {
      amountIterator = 2;
      classRowCount = 24;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      if (amount[i]) {
        amountRows.push(
          <div className={"pure-u-" + classRowCount + "-24"}>
            <span>
              {amount !== undefined &&
              amount[i] !== undefined &&
              amount[i] !== ""
                ? formatMoney(amount[i])
                : ""}
            </span>
          </div>
        );
      }
    }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-11-24 pure-u-sm-18-24 pure-u-md-18-24 pure-u-lg-18-24">
          <span className="label-text-clr" style={{ wordBreak: "break-word" }}>
            {getFrequency(language)[getFrequencyCode(language)[unit]]}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).amountLabel
            }
          </span>
        </div>
        <div
          className={"pure-u-8-24 pure-u-sm-6-24 pure-u-md-6-24 pure-u-lg-6-24"}
        >
          {amountRows}
        </div>
        <hr className="divider" />
      </div>
    );
  };

  render() {
    var listOfUpdatedHHMembers = [];
    var existingIncomeMembers = [];
    let memberIncomeStatus = "";
    if (this.state.addIncomeMembersOpen) {
      memberIncomeStatus = "is-active";
    }
    let earnedIncChangeVal = null;
    if (this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE) {
      earnedIncChangeVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[0];
    } else if (
      this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_FALSE
    ) {
      earnedIncChangeVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1];
    }
    this.props.covidRecert.householdMembers.membersList.map((memberHH) => {
      if (memberHH.dataChangeCode !== undefined) return;
      var incomeHouseHoldName = memberHH.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName = incomeHouseHoldName + " " + memberHH.lastName;
        let incomeHouseHoldDOB = memberHH.dateOfBirth;
        if (memberHH.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = memberHH.firstName;
      }
      listOfUpdatedHHMembers.push(incomeCombinedName);

      if (this.props.incomeSummary.incomeMembers !== undefined) {
        this.props.incomeSummary.incomeMembers.map((mem) => {
          existingIncomeMembers.push(mem.firstName);
        });
      }
    });

    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {this.props.incomeSummary.incomeMembers.map((summary) =>
          this.renderEarnedIncomeSummaryItem(summary)
        )}
        {this.props.covidRecert.earnedIncomeChanged &&
        this.props.covidRecert.earnedIncomeChanged === TextProps.VALUE_TRUE &&
        this.props.incomeSummary.nonIncomeMembers.length > 0 ? (
          <div>
            <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${memberIncomeStatus}`}
              onClick={this.toggleAddIncomeMembers}
              aria-expanded={this.state.addIncomeMembersOpen}
            >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart,
                  null,
                  null,
                  null
                ).anotherHouseholdIncomeLabel
              }
            </button>
            <Collapse isOpened={this.state.addIncomeMembersOpen}>
              <span className="add-income-summary__label" />
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingIncomeMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
                  return (
                    <li key={member.id}>
                      {/*
                      Clicking the following button routes the user to a
                      new child Income section to fill out income info for this
                      household member (e.g. "Listing John's Income"). When user
                      is finished and clicks 'Continue', they will be returned to Income Summary page
                    */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.renderIncomeToAddNewMember(member);
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
                      >
                        <span className="sr-only">Add income for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        ) : null}
        <hr className="divider" />
        <span>
          {
            interimReportPageLabels(
              language,
              languageConstants.beforeYouStart,
              null,
              null,
              null
            ).IncomePerMonthLabel
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.covidRecert.sections[3].selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo,
                      null,
                      null,
                      null
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                name="earned-income-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(
                    language,
                    languageConstants.aboutMe,
                    null,
                    null,
                    null
                  ).optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={earnedIncChangeVal}
                onChange={(value) => this.onEarnedIncomeChanged(value)}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberIncome: clearMemberIncome,
      appendIRIncomeDetails: appendIRIncomeDetails,
      setHouseholdMembers: setHouseholdMembers,
      setDataChangeStatus: setDataChangeStatus,
      setEarnedIncomeChangeStatus: setEarnedIncomeChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EarnedIncome);
