import React from "react";
import {
  screenresultMultilanguageLabels,
  monthSelectLabels,
} from "../screeningText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

// Markup for the body of apply page when user passes screening and should continue
export function renderScreeningResults(dataSections) {
  console.log(dataSections);
  const outputObj = dataSections;
  if (outputObj !== undefined) {
    if (outputObj[0].questions[1].Answer === TextProps.VALUE_YES) {
      outputObj[0].subsections[0].header =
        screenresultMultilanguageLabels(selectedLanguage).screeningHeader;
      return (
        <div className="pad-all--double">
          <p>
            {
              screenresultMultilanguageLabels(selectedLanguage)
                .pleaseclickapplyLabel
            }
          </p>
          <p>
            {
              screenresultMultilanguageLabels(selectedLanguage)
                .snapeligibilityBenefitLabel
            }
          </p>
          <ul>
            <li>
              {
                screenresultMultilanguageLabels(selectedLanguage)
                  .snapnumberofFactorsList[0]
              }
            </li>
            <li>
              {
                screenresultMultilanguageLabels(selectedLanguage)
                  .snapnumberofFactorsList[1]
              }
            </li>
            <li>
              {
                screenresultMultilanguageLabels(selectedLanguage)
                  .snapnumberofFactorsList[2]
              }
            </li>
            <li>
              {
                screenresultMultilanguageLabels(selectedLanguage)
                  .snapnumberofFactorsList[3]
              }
            </li>
          </ul>
        </div>
      );
    } else if (
      (outputObj[0].questions[1].Answer === TextProps.VALUE_NO ||
        outputObj[0].questions[1].Answer === "Non") &&
      outputObj[0].questions[2].Answer === TextProps.VALUE_NO &&
      (outputObj[0].questions[3].Answer !== null ||
        outputObj[0].questions[3].Answer.amount !== undefined)
    ) {
      var inputNos = outputObj[0].questions[3].Answer.amount.replace(/,/g, "");
      var income = Number(inputNos);
      var familySize = Number(outputObj[0].questions[0].Answer);

      var annualIncome = [
        29160, 39440, 49720, 60000, 70280, 80560, 90840, 101120,
      ];
      var monthlyIncome = [2430, 3287, 4143, 5000, 5857, 6713, 7570, 8427];
      var weeklyIncome = [561, 758, 956, 1154, 1352, 1549, 1747, 1945];
      var extraPersonAmt = [10280, 857, 198];
      var extraIncome = null;

      // greaterthan 8 members logic starts here
      if (
        familySize >= 8 &&
        outputObj[0].questions[3].Answer.unit ===
          monthSelectLabels(selectedLanguage).year
      ) {
        var lastPersonYearAmount = annualIncome[7];
        for (var i = 0; i < familySize - 8; i++) {
          extraIncome = lastPersonYearAmount + (i + 1) * extraPersonAmt[0];
          annualIncome.push(extraIncome);
        }
      } else if (
        familySize >= 8 &&
        outputObj[0].questions[3].Answer.unit ===
          monthSelectLabels(selectedLanguage).month
      ) {
        var lastPersonMonthlyAmount = monthlyIncome[7];
        for (var j = 0; j < familySize - 8; j++) {
          extraIncome = lastPersonMonthlyAmount + (j + 1) * extraPersonAmt[1];
          monthlyIncome.push(extraIncome);
        }
      } else if (
        familySize >= 8 &&
        outputObj[0].questions[3].Answer.unit ===
          monthSelectLabels(selectedLanguage).week
      ) {
        var lastPersonWeeklyAmount = weeklyIncome[7];
        for (var k = 0; k < familySize - 8; k++) {
          extraIncome = lastPersonWeeklyAmount + (k + 1) * extraPersonAmt[2];
          weeklyIncome.push(extraIncome);
        }
      }
      // lessthan 8 members logic starts here
      if (
        (income <= annualIncome[familySize - 1] &&
          outputObj[0].questions[3].Answer.unit ===
            monthSelectLabels(selectedLanguage).year) ||
        (income <= monthlyIncome[familySize - 1] &&
          outputObj[0].questions[3].Answer.unit ===
            monthSelectLabels(selectedLanguage).month) ||
        (income <= weeklyIncome[familySize - 1] &&
          outputObj[0].questions[3].Answer.unit ===
            monthSelectLabels(selectedLanguage).week)
      ) {
        return <div>{renderQualifyInfo(outputObj)}</div>;
      } else {
        return (
          <span>{renderNotQualifyInfo(income, familySize, outputObj)}</span>
        );
      }
    } else {
      return <div>{renderQualifyInfo(outputObj)}</div>;
    }
  } else {
    return <div>{renderQualifyInfo()}</div>;
  }
}

function renderQualifyInfo(outputObj) {
  outputObj[0].subsections[0].header =
    screenresultMultilanguageLabels(selectedLanguage).screeningHeader;
  return (
    <div className="pad-all--double">
      <p>
        {
          screenresultMultilanguageLabels(selectedLanguage)
            .pleaseclickapplyLabel
        }
      </p>
      <p>
        {
          screenresultMultilanguageLabels(selectedLanguage)
            .snapeligibilityBenefitLabel
        }
      </p>
      <ul>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[0]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[1]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[2]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[3]
          }
        </li>
      </ul>
    </div>
  );
}
function renderNotQualifyInfo(income, countMembers, outputObj) {
  outputObj[0].subsections[0].header =
    screenresultMultilanguageLabels(selectedLanguage).notEligibleHeader;
  //'You may not be eligible based on your income and household size.';
  return (
    <div className="pad-all--double">
      <p>
        {
          screenresultMultilanguageLabels(selectedLanguage)
            .snapeligibilityBenefitLabel
        }
      </p>
      <ul>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[0]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[1]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[2]
          }
        </li>
        <li>
          {
            screenresultMultilanguageLabels(selectedLanguage)
              .snapnumberofFactorsList[3]
          }
        </li>
      </ul>
      <p></p>
      <p>
        {screenresultMultilanguageLabels(selectedLanguage).specificRulelabel}
      </p>
    </div>
  );
}
