import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import List from "./list";
import ListItem from "./listItem";
import { addStringIf } from "../../components/utilities/controls";

import BenefitContents from "./itemContents/benefitContents";
import DeadlineContents from "./itemContents/deadlineContents";
import DocumentContents from "./itemContents/document";
import EventContents from "./itemContents/eventContents";
import NotificationContents from "./itemContents/notification";
import ClientContents from "./itemContents/client";
import SearchedClientContents from "./itemContents/searchedClient";

import * as DeadlineProps from "../../constants/deadline";
import * as DocumentProps from "../../constants/document";
import * as EventProps from "../../constants/event";
import * as NotificationProps from "../../constants/notification";
import * as SharedProps from "../../constants/shared";
import * as ListItemTypes from "../../constants/listItemTypes";

/*
  ListWrapper
  -----------

  # Purpose:
  Manages rendering `ListItem`s with the appropriate contents into a `List

  # Props:
  All props other than the ones specified are passed through to the underlying `List`
  items: Optional, array of list data objects. Objects must have two required entries (TYPE and ID)
  listItemClass: Optional, string classes for the list item

  # Example:
  <ListWrapper
    total={100}
    doLoadMore={this._doLoadMore.bind(this, 'activityList', ListData.ACTIVITY)}
    items={this.state.activityList}
  />
 */

class ListWrapper extends Component {
  constructor(props) {
    super(...arguments);
    this.handleListClickAction = this.handleListClickAction.bind(this);
  }

  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [SharedProps.TYPECODE]: PropTypes.oneOf(_.values(ListItemTypes))
          .isRequired,
        [SharedProps.ID]: PropTypes.number.isRequired,
      })
    ).isRequired,
    listItemClass: PropTypes.string,
    displayPage: PropTypes.number,
    activePage: PropTypes.number
  };

  static defaultProps = {
    listItemClass: "",
  };

  handleListClickAction = (id, typeCode) => {
    console.log("Id -- " + id + "  Type --- " + typeCode);
  };

  render() {
    const { items, children, listItemClass, ...otherProps } = this.props;
    if (items !== undefined) {
      return (
        <List {...otherProps}>
          {items.map(this._buildListItem.bind(this, listItemClass))}
          {children}
        </List>
      );
    }
  }

  // Rendering
  // ---------

  _buildListItem = (listItemClass, item, index) => {
    const isProcessing =
        item[DocumentProps.STATUS] === DocumentProps.STATUS_PROCESSING,
      isUnread =
        item[NotificationProps.STATUS] === NotificationProps.STATUS_UNREAD,
      isClose = item[EventProps.STATUS] === EventProps.STATUS_CLOSE,
      isUrgent = item[DeadlineProps.STATUS] === DeadlineProps.STATUS_URGENT;
    let className = listItemClass;
    className = addStringIf(
      isProcessing,
      className,
      "dta-list-item--style-plain"
    );
    className = addStringIf(
      isUnread || isClose || isUrgent,
      className,
      "dta-list-item--style-emphasized"
    );
    // if (item[SharedProps.ID] === 0) {
    //   let user = item[SharedProps.USER];
    //   index = user[SharedProps.WEBAPPID];
    // }

    return item.type ? (
      <ListItem
        key={index}
        className={className}
        onClick={this.handleListClickAction.bind(item.id, item.type)}
      >
        {this._resolveContentsFromType(item[SharedProps.TYPE], item)}
      </ListItem>
    ) : (
      <ListItem
        key={index}
        className={className}
        onClick={this.handleListClickAction.bind(item.id, item.typeCode)}
      >
        {this._resolveContentsFromTypeCode(item[SharedProps.TYPECODE], item)}
      </ListItem>
    );
  };

  // CREATE LIST WITH LIST TYPE
  _resolveContentsFromType(type, props) {
    switch (type) {
      case ListItemTypes.BENEFIT:
        return <BenefitContents {...props} />;
      case ListItemTypes.DOCUMENT:
        return <DocumentContents {...props} />;
      case ListItemTypes.NOTIFICATION:
        return <NotificationContents {...props} />;
      case ListItemTypes.EVENT:
        return <EventContents {...props} />;
      case ListItemTypes.DEADLINE:
        return <DeadlineContents {...props} />;
      case ListItemTypes.PROVIDER_CLIENT:
        return (
          <ClientContents
            onRequestAccess={this.props.onRequestAccess}
            {...props}
          />
        );
      case ListItemTypes.SEARCHED_CLIENT:
        
      console.log("this.listWrapper", props);

      const multiUserDetails = props.multiUserDetails.slice((this.props.activePage - 1)*this.props.displayPage, (this.props.activePage - 1)*this.props.displayPage + this.props.displayPage)
        return (
          <SearchedClientContents
            onRequestAccess={this.props.onRequestAccess}
            {...props}
            multiUserDetails={multiUserDetails}
          />
        );
      default:
        throw Error(`${type} is not a valid list item type`);
    }
  }

  _resolveContentsFromTypeCode(type, props) {
    return <EventContents {...props} />;
  }
}

export default ListWrapper;
