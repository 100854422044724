import axios from "axios";
import { sections } from "../../pages/applyEaedc/applyEaedcMapData";
import { getAudit } from "../../utils/constants/audit";
var TigerURL = require("../../utils/constants/appConfigConstants").TigerURL;

const actionTypes = {
  applyEaedcPagesAction: "APPLY_EAEDC_PAGES_FULLFILLED",
  serviceFailedAction: "EAEDC_NETWORK_FAILED_LOAD_DATA",
  postApplyData: "POST_APPLY_EAEDC_INFO_DATA",
  fillInputValuesAction: "FILL_EAEDC_INPUT_VALUES_STATE",
  applySnapSectionData: "APPLY_EAEDC_SNAP_SECTIONS_FULLFILLED",
};

export function applyEaedcQuestionsSections() {
  return (dispatch) => {
    return dispatch(applyEaedcSections(sections));
  };
}
function applyEaedcSections(sectionsVal) {
  return {
    type: actionTypes.applyEaedcPagesAction,
    payload: sectionsVal,
  };
}
export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}
export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}
function questionsInputValuesAction(sectionsValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsValue,
  };
}
export function saveSnapapplyEaedc(application, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = TigerURL + "/apipublic/security/eafdc/apply";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
        },
      };
      axios
        .post(url, application, options)
        .then(function (data) {
          dispatch(saveApplyData(data));
          resolve(data);
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}
function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}
export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}
export function updateDefault() {
  return (dispatch) => {
    return dispatch({
      type: "default",
    });
  };
}
export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_EAEDC_APPLY_ANSER",
      payload: data,
    });
  };
}
export function updateLastKnownLocation(section) {
  return (dispatch) => {
    return dispatch({
      type: "APPEND_TAFDC_LAST_KNOWN_LOCATION",
      payload: section,
    });
  };
}
