import React, { Component } from "react";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import * as PROPS from "../../utils/components/shared";
import * as TYPES from "../constants/types";
import * as formatters from "./utilities/formatters";
import { consumerConst } from "../../pages/consumer/consumerText";
import { tryCall } from "./utilities/controls";
import { resolve as resolveQuestion } from "./utilities/questions";
import _ from "lodash";
import {
  updatePhoneNumber,
  updateClientEmail,
  updateClientAddress,
  isMailMyInfo,
  isPopupClosed,
  isHouseholdUpdate,
  addressValidationPopupAction,
  mailingAddressValidationPopUpAction,
  addrsWarningPopupAction
} from "../../redux/consumer/consumerMyInfoActions";

import { addressValidation } from "../../redux/applySnap/applySnapActions";
import {
  formAddressDisplayValue,
  formatAddressDisplay,
  checkIfAddressIsUpdated,
} from "../../pages/applySnap/applySnapHelper";
import { interimReportPageLabels } from "../../pages/recertInterimReport/recertInterimReportText";
import * as languageConstants from "../constants/constants";
import Radio from "./radio";
import { ZIPCODE } from "../constants/zipCode";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as modalHelperFunction from "./helperFunctions/modalHelperFunction";
import * as TextProps from "../../utils/constants/text";
import { ALABAMA_COUNTY_OFFICES } from "../../utils/constants/constants";
import Modal from "../../utils/components/modal/dtaModal";
import DOMPurify from "dompurify";
import * as routesConstants from '../../utils/constants/routesConstants';


var config = require("../../utils/constants/appConfigConstants");
let consumerLabels = require("../../utils/constants/benefit");

let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if(selectedLanguage === null || selectedLanguage === languageConstants.langOptSel) {
    selectedLanguage = TYPES.ENGLISH;
  }

class DTAEditableFields extends Component {
  static propTypes = {
    editingLabel: PropTypes.string.isRequired,
    columnClass: PropTypes.string.isRequired,
    columnLabel: PropTypes.bool,
    fields: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onEditStateChange: PropTypes.func,
    controlLabel: PropTypes.bool.isRequired,
    border: PropTypes.string
  };

  state = {
    renderedSections: [],
    isEditing: TextProps.VALUE_FALSE,
    uploadDocumentModalOpen: TextProps.VALUE_FALSE,
    errorMessage: TextProps.VALUE_TRUE,
    isEditFinished: TextProps.VALUE_FALSE,
    addressMessage: TextProps.VALUE_FALSE,
    emailMessage: TextProps.VALUE_FALSE,
    phoneMessage: TextProps.VALUE_FALSE,
    addressRadioOptions1: [],
    addressRadioOptions2: [],
    calledMellisa: TextProps.VALUE_FALSE,
    melissaPayload: {
      enteredResAddress: [],
      suggestedResAddress: [],
      originalEnteredResAddress: {},
      originalSuggestedResAddress: [],
      enteredMailingAddress: [],
      suggestedMailingAddress: [],
      originalEnteredMailingAddress: {},
      originalSuggestedMailingAddress: [],
      fields: this.props.fields,
      agencyId: this.props.agencyId,
      MailingAddressFlag: TextProps.VALUE_FALSE,
    },
    prevFields: [],
    showWarningModal:TextProps.VALUE_FALSE,
  };

  constructor() {
    super(...arguments);

    this.startEditing = this.startEditing.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
    enableUniqueIds(this);
  }

  startEditing = (isEditing) => {
    if (this.props.fields && this.props.fields.length > 0) {
      sessionStorage.setItem("editType", this.props.fields[0].type);
    }
    var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    var consumerAboutMeInfoTemplate = JSON.parse(
      sessionStorage.getItem("consumerAboutMeInfoTemplate")
    );
    if (
      clientDetails.clientAddress != undefined &&
      clientDetails.clientAddress.length > 0
    ) {
      if (
        clientDetails.clientAddress[0].homelessFlag === TextProps.VALUE_TRUE
      ) {
        sessionStorage.setItem("isHousehold", TextProps.VALUE_TRUE);
        consumerAboutMeInfoTemplate[0].fields[0].homelessFlag =
          TextProps.VALUE_TRUE;
        clientDetails.clientAddress[0].homelessFlag =
          consumerAboutMeInfoTemplate[0].fields[0].homelessFlag;
        sessionStorage.setItem("clientDetails", JSON.stringify(clientDetails));
      } else {
        sessionStorage.setItem("isHousehold", TextProps.VALUE_FALSE);
        consumerAboutMeInfoTemplate[0].fields[0].homelessFlag =
          TextProps.VALUE_FALSE;
        clientDetails.clientAddress[0].homelessFlag =
          consumerAboutMeInfoTemplate[0].fields[0].homelessFlag;
        sessionStorage.setItem("clientDetails", JSON.stringify(clientDetails));
      }
    } else {
      sessionStorage.setItem("isHousehold", TextProps.VALUE_FALSE);
    }
    if (this.props.onEditingIncomeverification !== undefined) {
      this.props.onEditingIncomeverification(TextProps.VALUE_TRUE);
    }
    this.setState({
      prevFields: _.cloneDeep(this.props.fields),
      isEditing: TextProps.VALUE_TRUE,
    });
  };

removeHtmlStyle=()=>{
    var htmlTag = document.documentElement; 
    htmlTag.style.overflow = '';
}
 closeWarningModal=()=>{
    this.setState({ showWarningModal: TextProps.VALUE_FALSE });
  };

  showWarningModal = () => {
    this.setState({ showWarningModal: TextProps.VALUE_TRUE },()=>{
      this.removeHtmlStyle();
    });
  };

  renderWarningPopup = () => {
    return (
      <Modal
        isOpen={this.state.showWarningModal}
        onClose={() =>
          this.closeWarningModal()
        }
        modalClass="homeless-modal"
        titleText="homeless Warning"
        headerText={
          consumerConst(selectedLanguage).isHomelessHouseholdheader
        }
      >
        <div className="dta-modal__body pad-all">
          <div>
           <div className="text--small">{consumerConst(selectedLanguage).isHomelessHouseholdContent[0].line1}</div>
           <div className="text--small">{consumerConst(selectedLanguage).isHomelessHouseholdContent[1].line2} <a target="_blank"  href={ALABAMA_COUNTY_OFFICES}>here</a></div>
           <div className="text--small">{consumerConst(selectedLanguage).isHomelessHouseholdContent[2].line3}</div>
          </div>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeWarningModal()
              }
            >
              {consumerConst(selectedLanguage).okButton}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  convertingMultiLangLabels = (labelName) => {
    const { common } = this.props.language.consumer;
    switch (labelName) {
      case consumerLabels.PHONENUM:
        return common.phoneNo;
      case consumerLabels.EMAILADDRS:
        return common.emailAddress;
      case consumerLabels.RESDADDRS:
        return common.residentialAddr;
      case consumerLabels.ISALSOMAILINGADDR:
        return common.mailingAddress;
      case consumerLabels.TEXTNOTIFICATIONS:
        return common.allowTextNotification;
      case consumerLabels.EMAILNOTIFICATIONS:
        return common.allowEmailNotification;
      case consumerLabels.EDITPHONEDETTAILS:
        return common.editPhnDetails;
      case consumerLabels.EDITEMAILDETAILS:
        return common.editEmailDetails;
      case consumerLabels.EDITADDRESSDETAILS:
        return common.editAddress;
      case consumerLabels.EDITEMAILADDRESS:
        return common.editEmailDetails;
      default:
        return TextProps.VALUE_FALSE;
    }
  };
  convertingMultiLangLabelsMailingAddres = (labelName, fields) => {
    const { common } = this.props.language.consumer;
    if (labelName) {
      if (consumerLabels.ISALSOMAILINGADDR) {
        return common.mailingAddress;
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    const { common } = this.props.language.consumer;
    if (
      nextProps.consumer.myInfoUpdated === TextProps.VALUE_TRUE &&
      this.state.isEditFinished === TextProps.VALUE_TRUE
    ) {
      var myInfoSuccessMessage;
      if(this.props.benefitLabel && this.state.addressMessage === TextProps.VALUE_TRUE ){
           myInfoSuccessMessage =common.addressPopupMessage;
      }
      else if (this.state.addressMessage === TextProps.VALUE_TRUE) {
        
        myInfoSuccessMessage = common.addressPopupMessage;
      } else if (this.state.emailMessage === TextProps.VALUE_TRUE) {
        myInfoSuccessMessage = common.emailPopupMessage;
        // setTimeout(()=>{
        //   window.location.reload();
        // },2000)
      } else if (this.state.phoneMessage === TextProps.VALUE_TRUE) {
        myInfoSuccessMessage = common.phonePopupMessage;
        // setTimeout(()=>{
        //   window.location.reload();
        // },2000)
      }
      this.setState({
        uploadDocumentModalOpen: TextProps.VALUE_TRUE,
        hideCloseIcon: TextProps.VALUE_TRUE,
      });
      setTimeout(() => {
        this.uploadDocumentModal.showBlur(
          "success",
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(myInfoSuccessMessage) }}/>
        );
      }, 5);
      setTimeout(() => {
        this.props.isPopupClosed();
      }, 6);

      setTimeout(() => {
        this.setState({
          uploadDocumentModalOpen: TextProps.VALUE_TRUE,
          hideCloseIcon: TextProps.VALUE_TRUE,
        });
      }, 5000);
      setTimeout(()=>{
          window.location.reload();
        },6000)
    }
  }

  closeSubmitModal = () => {
    // Cancel any actions then...
    setTimeout(() => {
      this.setState({
        uploadDocumentModalOpen: TextProps.VALUE_FALSE,
      });
    }, 5000);
  };

  finishEditing = (isEditing) => {
    let mailMyInfo = sessionStorage.getItem("mailMyInfo");
    let isEditingStarted = sessionStorage.getItem("isEditingStarted");
    if(mailMyInfo !== null || (isEditingStarted !== null && isEditingStarted !== "false")){
    const { common } = this.props.language.consumer;
    var agencyId = this.props.agencyId;
    var accountInfo = JSON.parse(sessionStorage.getItem("consumerAccountData"));
    var isHousehold = JSON.parse(sessionStorage.getItem("isHousehold"));
    var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    if (
      clientDetails.clientAddress &&
      clientDetails.clientAddress.length > 1 &&
      clientDetails.clientAddress[1].value &&
      clientDetails.clientAddress[1].value.state === ""
    ) {
      clientDetails.clientAddress[1].value.state =
        this.props.fields[1].value.state;
      sessionStorage.setItem("clientDetails", JSON.stringify(clientDetails));
    }
    if (
      (this.props.fields[0].name === "residentialAddress" &&
        this.state.isEditing === TextProps.VALUE_TRUE) ||
      (this.props.fields[1].name === "is-also-mailing" &&
        this.state.isEditing === TextProps.VALUE_TRUE)
    ) {
      if (isHousehold && !this.props.fields[0].resAddrMatchFlag) {
        if (this.props.fields[1].name === "is-also-mailing") {
          var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
            this.props.fields[1].value.zipCode
          );
          const fields = this.props.fields[1].value;
          const masResZipCode = ZIPCODE.find(
            (obj) => obj.ZIPCODE === this.props.fields[1].value.zipCode
          );
          var errCounter = 0;
          var errMsg = common.streetErrMsg;
          if (!isValidZip) {
            errCounter++;
            errMsg = common.zipcodeErrMsg;
          } else if (!masResZipCode && isValidZip) {
            errCounter++;
            errMsg = common.masZipCodeErrmsg;
          }
          if (fields.addressLine1 === "") {
            errMsg = common.streetErrMsg;
            errCounter++;
          }
          if (fields.city === "") {
            errMsg = common.cityErrMsg;
            errCounter++;
          }
          if (fields.county === "" || fields.county === undefined ) {
            errMsg = common.countyErrmsg;
            errCounter++;
          }
          if (errCounter > 0) {
            if (errCounter > 1) {
              errMsg = common.addressErrMsg;
            }
            this.props.fields[1].error = TextProps.VALUE_TRUE;
            this.props.fields[1].errorMessage = errMsg;
            this.setState({ isEditing: TextProps.VALUE_TRUE });
            return;
          } else {
            this.setState({
              isEditing: TextProps.VALUE_FALSE,
              isEditFinished: TextProps.VALUE_TRUE,
              addressMessage: TextProps.VALUE_TRUE,
            });
            // this.props.addressValidationPopupAction();
            //set value to modal
            //call true
            const mailAddress = this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress.find(address => address.typeCode === "MAIL");
            const resAddress = this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress.find(address => address.typeCode === "RES");
            const mailFieldvalue = this.props.fields[1].value;
            const resFieldvalue = this.props.fields[0].value;     
           
            if (mailAddress || resAddress) {
              const mailAddressFlag = mailAddress &&  (
                mailFieldvalue.addressLine1 !== mailAddress.line1 ||
                mailFieldvalue.addressLine2 !== mailAddress.line2 ||
                mailFieldvalue.county !== mailAddress.county ||
                mailFieldvalue.city !== mailAddress.city ||
                this.props.fields[1].homelessFlag !== mailAddress.homelessFlag
              );             
              const resAddressFlag = resAddress && (
                resFieldvalue.addressLine1 !== resAddress.line1 ||
                resFieldvalue.addressLine2 !== resAddress.line2 ||
                resFieldvalue.county !== resAddress.county ||
                resFieldvalue.city !== resAddress.city ||
                this.props.fields[0].homelessFlag !== resAddress.homelessFlag
              );              
              if (mailAddressFlag || resAddressFlag) {
            if (!config.doAddressValidate) {
              this.props.updateClientAddress(this.props.fields, agencyId,clientDetails.clientAddress);
            }
              } else {
                this.setState({
                  isEditing: TextProps.VALUE_FALSE,
                  isEditFinished: TextProps.VALUE_TRUE,
                  addressMessage: TextProps.VALUE_FALSE,
                });
                sessionStorage.setItem("isEditingStarted", TextProps.VALUE_FALSE);
                this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE);
              }
            }    
          }
        }
      } else {
        if (isHousehold && this.props.fields[0].resAddrMatchFlag) {
          if (this.props.fields[0].name === "residentialAddress") {
            var isZipValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
              this.props.fields[0].value.zipCode
            );
            const fields = this.props.fields[0].value;
            const masResZipCode = ZIPCODE.find(
              (obj) => obj.ZIPCODE === this.props.fields[0].value.zipCode
            );
            var errCount = 0;
            var errorMsg = common.streetErrMsg;
            if (!isZipValid) {
              errCount++;
              errorMsg = common.zipcodeErrMsg;
            } else if (!masResZipCode && isZipValid) {
              errCount++;
              errorMsg = common.masZipCodeErrmsg;
            }
            if (fields.addressLine1 === "") {
              errorMsg = common.streetErrMsg;
              errCount++;
            }
            if (fields.city === "") {
              errorMsg = common.cityErrMsg;
              errCount++;
            }
            if (fields.county === "" || fields.county === undefined) {
              errorMsg = common.countyErrmsg;
              errCount++;
            }
            if (errCount > 0) {
              if (errCount > 1) {
                errorMsg = common.addressErrMsg;
              }
              if (errCount === 3) {
                this.props.fields[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
                sessionStorage.setItem("mailMyInfo", TextProps.VALUE_FALSE);
                this.props.fields[0].error = TextProps.VALUE_FALSE;
                this.setState({ isEditing: TextProps.VALUE_TRUE });
                return;
              }
              this.props.fields[0].error = TextProps.VALUE_TRUE;
              this.props.fields[0].errorMessage = errorMsg;
              this.setState({ isEditing: TextProps.VALUE_TRUE });
              return;
            } else {
              this.setState({
                isEditing: TextProps.VALUE_FALSE,
                isEditFinished: TextProps.VALUE_TRUE,
                addressMessage: TextProps.VALUE_TRUE,
              });
              // this.props.addressValidationPopupAction();
              if (!config.doAddressValidate) {
                this.props.updateClientAddress(this.props.fields, agencyId,clientDetails.clientAddress);
              }
            }
          }
        } else {
          var isValidZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
            this.props.fields[0].value.zipCode
          );
          const fields = this.props.fields[0].value;
          const masResZipCode = ZIPCODE.find(
            (obj) => obj.ZIPCODE === this.props.fields[0].value.zipCode
          );
          var errorCounter = 0;
          var errMessage = common.streetErrMsg;
          if (!isValidZipCode) {
            errorCounter++;
            errMessage = common.zipcodeErrMsg;
          } else if (!masResZipCode && isValidZipCode) {
            errorCounter++;
            errMessage = common.masZipCodeErrmsg;
          }
          if (fields.addressLine1 === "") {
            errMessage = common.streetErrMsg;
            errorCounter++;
          }
          if (fields.city === "") {
            errMessage = common.cityErrMsg;
            errorCounter++;
          }
          if (fields.county === "" || fields.county === undefined) {
            errMessage = common.countyErrmsg;
            errorCounter++;
          }
          if (errorCounter > 0) {
            if (errorCounter > 1) {
              errMessage = common.addressErrMsg;
            }
            this.props.fields[0].error = TextProps.VALUE_TRUE;
            this.props.fields[0].errorMessage = errMessage;
            this.setState({ isEditing: TextProps.VALUE_TRUE });
            return;
          } else {
            this.props.fields[0].error = TextProps.VALUE_FALSE;
            if (this.props.consumer.consumerData.accountId !== undefined) {
              this.props.fields[0].clientId =
                this.props.consumer.consumerData.accountId;
              this.props.fields[0].userAuth =
                this.props.consumer.mfaDetails.userAuth;
            } else if (
              this.props.consumer.consumerData.user !== undefined &&
              this.props.consumer.consumerData.user.accountId !== undefined
            ) {
              this.props.fields[0].clientId =
                this.props.consumer.consumerData.user.accountId;
              this.props.fields[0].userAuth =
                this.props.consumer.mfaDetails.userAuth;
            } else if (accountInfo !== null && accountInfo.clientId > 0) {
              this.props.fields[0].clientId = accountInfo.clientId;
              this.props.fields[0].userAuth = accountInfo.userAuth;
            }
            //mailing address validation part
            if (
              this.props.fields[1].type === "address" &&
              this.props.columnLabel &&
              this.props.fields[0].resAddrMatchFlag === TextProps.VALUE_FALSE
            ) {
              var isValidPin = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
                this.props.fields[1].value.zipCode
              );
              const masZipCode = ZIPCODE.find(
                (obj) => obj.ZIPCODE === this.props.fields[1].value.zipCode
              );
              var errorCount = 0;
              var errorText = common.streetErrMsg;
              if (!isValidPin) {
                errorCount++;
                errorText = common.zipcodeErrMsg;
              } else if (!masZipCode && isValidZip) {
                errorCount++;
                errorText = common.masZipCodeErrmsg;
              }
              if (this.props.fields[1].value.addressLine1 === "") {
                errorCount++;
                errorText = common.streetErrMsg;
              }
              if (this.props.fields[1].value.city === "") {
                errorCount++;
                errorText = common.cityErrMsg;
              }
              if (this.props.fields[1].value.county === "") {
                errorCount++;
                errorText = common.countyErrmsg;
              }
              if (errorCount > 0) {
                if (errorCount > 1) {
                  errorText = common.addressErrMsg;
                }
                this.props.fields[1].error = TextProps.VALUE_TRUE;
                this.props.fields[1].errorMessage = errorText;
                this.setState({ isEditing: TextProps.VALUE_TRUE });
                return;
              } else {
                this.props.fields[1].error = TextProps.VALUE_FALSE;
                if (this.props.consumer.consumerData.accountId !== undefined) {
                  this.props.fields[0].clientId =
                    this.props.consumer.consumerData.accountId;
                  this.props.fields[0].userAuth =
                    this.props.consumer.mfaDetails.userAuth;
                } else if (
                  this.props.consumer.consumerData.user !== undefined &&
                  this.props.consumer.consumerData.user.accountId !== undefined
                ) {
                  this.props.fields[0].clientId =
                    this.props.consumer.consumerData.user.accountId;
                  this.props.fields[0].userAuth =
                    this.props.consumer.mfaDetails.userAuth;
                } else if (accountInfo !== null && accountInfo.clientId > 0) {
                  this.props.fields[0].clientId = accountInfo.clientId;
                  this.props.fields[0].userAuth = accountInfo.userAuth;
                }
              }
            }
            this.setState({
              isEditing: TextProps.VALUE_FALSE,
              isEditFinished: TextProps.VALUE_TRUE,
              addressMessage: TextProps.VALUE_TRUE,
            });
          }
         
          const mailAddress = this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress.find(address => address.typeCode === "MAIL");
          const resAddress = this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress.find(address => address.typeCode === "RES");
          const mailFieldvalue = this.props.fields[1].value;
          const resFieldvalue = this.props.fields[0].value;                               
          if (mailAddress || resAddress) {
            const mailAddressFlag = mailAddress && (
              mailFieldvalue.addressLine1 !== mailAddress.line1 ||
              mailFieldvalue.addressLine2 !== mailAddress.line2 ||
              mailFieldvalue.county !== mailAddress.county ||
              mailFieldvalue.city !== mailAddress.city ||
              this.props.fields[1].homelessFlag !== mailAddress.homelessFlag
            );        
          
            const resAddressFlag = resAddress && (
              resFieldvalue.addressLine1 !== resAddress.line1 ||
              resFieldvalue.addressLine2 !== resAddress.line2 ||
              resFieldvalue.county !== resAddress.county ||
              resFieldvalue.city !== resAddress.city ||
              this.props.fields[0].homelessFlag !== resAddress.homelessFlag
            );           
            if (mailAddressFlag || resAddressFlag) {
          if (!config.doAddressValidate) {
            this.props.updateClientAddress(this.props.fields, agencyId,clientDetails.clientAddress);
          }
            } else {
              this.setState({
                isEditing: TextProps.VALUE_FALSE,
                isEditFinished: TextProps.VALUE_TRUE,
                addressMessage: TextProps.VALUE_FALSE,
              });
              sessionStorage.setItem("isEditingStarted", TextProps.VALUE_FALSE);
              this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE);
            }
          } 
        }
      }
    }
    if (
      config.doAddressValidate &&
      isEditing &&
      (this.props.fields[0].name === "residentialAddress" ||
        this.props.fields[1].name === "is-also-mailing")
    ) {
      this.setState({ calledMellisa: TextProps.VALUE_TRUE });
      // const infoAddress = this.props.fields[0];
      this.callMellisaAddrValidation(clientDetails.clientAddress);
    }
    if (this.props.onEditingIncomeverification !== undefined) {
      this.props.onEditingIncomeverification(this.state.isEditing);
    }
   }else{
    this.setState({
      isEditing: TextProps.VALUE_FALSE,
      isEditFinished: TextProps.VALUE_TRUE,
      addressMessage: TextProps.VALUE_FALSE,
    });
    sessionStorage.setItem("isEditingStarted", TextProps.VALUE_FALSE);
    this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE);
   }
  };
  formMellisaObj(infoAddress, type) {
    return {
      line1:
        infoAddress.value.addressLine1 !== undefined
          ? infoAddress.value.addressLine1
          : "",
      line2:
        infoAddress.value.addressLine2 !== undefined
          ? infoAddress.value.addressLine2
          : "",
      city: infoAddress.value.city !== undefined ? infoAddress.value.city : "",
      state:
        infoAddress.value.state !== undefined ? infoAddress.value.state : "",
      zipCode:
        infoAddress.value.zipCode !== undefined
          ? infoAddress.value.zipCode
          : "",
      county:
        infoAddress.value.county !== undefined ? infoAddress.value.county : "",
      type: type,
    };
  }
  callMellisaAddrValidation(previousAddressObj) {
    if (this.props.fields !== undefined) {
      let resAddressMelisa = [];
      resAddressMelisa.push(this.formMellisaObj(this.props.fields[0], "RES"));
      if (!this.props.fields[0].resAddrMatchFlag) {
        resAddressMelisa.push(
          this.formMellisaObj(this.props.fields[1], "MAIL")
        );
      }
      let resAddrUpdated = TextProps.VALUE_FALSE;
      if (this.state.prevFields) {
        resAddrUpdated = checkIfAddressIsUpdated(
          this.state.prevFields[0].value,
          this.props.fields[0].value
        );
      }
      let mailingAddrUpdated = TextProps.VALUE_FALSE;
      if (this.state.prevFields) {
        if (!this.props.fields[0].resAddrMatchFlag) {
          mailingAddrUpdated = checkIfAddressIsUpdated(
            this.state.prevFields[1],
            this.props.fields[1]
          );
        }
      }
      if (resAddrUpdated || mailingAddrUpdated) {
        this.callToMellisa(
          resAddressMelisa,
          resAddrUpdated,
          mailingAddrUpdated,
          previousAddressObj
        );
      } else {
        this.props.updateClientAddress(this.props.fields, this.props.agencyId,previousAddressObj);
      }
    }
  }

  callToMellisa(answerAddress, resAddrUpdated, mailingAddrUpdated,prevAddressObject) {
    if (answerAddress !== undefined) {
      this.props
        .addressValidation(answerAddress, "en-US")
        .then((data) => {
          if (data.data.length) {
            let suggestedResAddress = formAddressDisplayValue(
              data.data.filter((res) => res.type === TextProps.VALUE_RES)
            );
            let suggestedMailingAddress = formAddressDisplayValue(
              data.data.filter((res) => res.type === TextProps.VALUE_MAIL)
            );
            let enteredResAddress = formAddressDisplayValue(
              answerAddress.filter((res) => res.type === TextProps.VALUE_RES)
            );
            let enteredMailingAddress = formAddressDisplayValue(
              answerAddress.filter((res) => res.type === TextProps.VALUE_MAIL)
            );
            let melissaPayload = {
              addressObj: {
                originalEnteredResAddress: enteredResAddress,
                originalSuggestedResAddress: suggestedResAddress,
                originalEnteredMailingAddress: enteredMailingAddress,
                originalSuggestedMailingAddress: suggestedMailingAddress,
                enteredResAddress: formatAddressDisplay(enteredResAddress),
                suggestedResAddress: formatAddressDisplay(suggestedResAddress),
                enteredMailingAddress: formatAddressDisplay(
                  enteredMailingAddress
                ),
                suggestedMailingAddress: formatAddressDisplay(
                  suggestedMailingAddress
                ),
                fields: _.cloneDeep(this.props.fields),
                agencyId: this.props.agencyId,
              },
              enteredResAddressValue: enteredResAddress[0].formattedAddress,
              enteredMailingAddressValue: enteredMailingAddress.length
                ? enteredMailingAddress[0].formattedAddress
                : "",
              selectedResAdressValue: "",
              selectedMailingAddressValue: "",
              finalResValueSelected: enteredResAddress[0],
              finalMailingValueSelected: enteredMailingAddress[0],
              mailingAddressFlag: mailingAddrUpdated,
              resAddressFlag: resAddrUpdated,
              storedFields: _.cloneDeep(this.props.fields),
            };
            this.props.mailingAddressValidationPopUpAction(melissaPayload);
            this.props.addressValidationPopupAction(melissaPayload);
          } else {
            this.props.updateClientAddress(
              this.props.fields,
              this.props.agencyId,
              prevAddressObject
            );
          }
        })
        .catch((error) => {
          this.props.updateClientAddress(
            this.props.fields,
            this.props.agencyId,
            prevAddressObject
          );
          return false;
        });
      // return answerAddress;
    }
  }

  resolveDisplayFormat = (type, value) => {
    const address = formatters.address(value);
    let addressLine1, addressLine2;
    if (address.firstLine) {
      addressLine1 = address.firstLine;
    } else {
      addressLine1 = null;
    }
    if (address.secondLine) {
      addressLine2 = address.secondLine;
    } else {
      addressLine2 = null;
    }
    switch (type) {
      case TYPES.ADDRESS:
        return (
          <div>
            {address.firstLine || address.firstLine === "" ? (
              <div>
                <div>{addressLine1}</div>
                <div>{addressLine2}</div>
              </div>
            ) : null}
          </div>
        );
      case TYPES.INPUT_PHONE:
        return formatters.phone(value);

      default:
        if (type === "radio" && value === "Yes") {
          value = consumerConst(selectedLanguage).yes;
          return value;
        } else if (type === "radio" && value === "No") {
          value = consumerConst(selectedLanguage).no;
          return value;
        } else {
          return value;
        }
    }
  };

  isHomeLessUpdate(value) {
    if( interimReportPageLabels(
      selectedLanguage,
      languageConstants.contactInfo
    ).houseLessOptions[0] === value ){
      this.showWarningModal()
    }
    sessionStorage.setItem("isEditingStarted", TextProps.VALUE_TRUE);
    if (value === "Yes") {
      sessionStorage.setItem("isHousehold", TextProps.VALUE_TRUE);
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_FALSE);
    } else {
      sessionStorage.setItem("isHousehold", TextProps.VALUE_FALSE);
      if(window.location.pathname !== routesConstants.CONSUMER_MY_INFO_PATH){
      sessionStorage.setItem("mailMyInfo", TextProps.VALUE_TRUE);
    }
    }
    this.props.isHouseholdUpdate();
  }

  onUpdatePhoneEmail = (isEditing) => {
    const { common,info } = this.props.language.consumer;
    var accountInfo = JSON.parse(sessionStorage.getItem("consumerAccountData"));
    var clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
    var optInFlagData = this.props.fields[1].value;
    var textOptInFlag;
    var optInFlag;
    if (optInFlagData === "Yes") {
      textOptInFlag = TextProps.VALUE_TRUE;
      optInFlag = TextProps.VALUE_TRUE;
    } else if (optInFlagData === "No") {
      textOptInFlag = TextProps.VALUE_FALSE;
      optInFlag = TextProps.VALUE_FALSE;
    }
    if(this.props.fields[0].name === "phoneNumber"){
    if (
      this.state.isEditing === TextProps.VALUE_TRUE &&
      (this.props.fields[0].value !== clientDetails.clientPhone.phoneNumber ||
        textOptInFlag !== clientDetails.clientPhone.textOptInFlag)
    ) {
      var phnNum = this.props.fields[0].value;
      var withoutSpace = phnNum.replace(/\D/g, "");
      var phnNoCnt = withoutSpace.length;
      if (
        (phnNoCnt !== 10 && textOptInFlag === TextProps.VALUE_TRUE) ||
        (phnNum !== "" && phnNoCnt !== 10)
      ) {
        this.props.fields[0].error = TextProps.VALUE_TRUE;
        this.props.fields[0].errorMessage = common.phnErrMsg;
        this.setState({ isEditing: TextProps.VALUE_TRUE });
        return;
      } else {
        this.props.fields[0].error = TextProps.VALUE_FALSE;
        if (this.props.consumer.consumerData.accountId !== undefined) {
          this.props.fields[0].clientId =
            this.props.consumer.consumerData.accountId;
          this.props.fields[0].userAuth =
            this.props.consumer.mfaDetails.userAuth;
        } else if (
          this.props.consumer.consumerData.user !== undefined &&
          this.props.consumer.consumerData.user.accountId !== undefined
        ) {
          this.props.fields[0].clientId =
            this.props.consumer.consumerData.user.accountId;
          this.props.fields[0].userAuth =
            this.props.consumer.mfaDetails.userAuth;
        } else if (accountInfo !== null && accountInfo.clientId > 0) {
          this.props.fields[0].clientId = accountInfo.clientId;
          this.props.fields[0].userAuth = accountInfo.userAuth;
        }
        let typeCode =
          clientDetails.clientPhone !== undefined &&
          clientDetails.clientPhone.typeCode !== undefined
            ? clientDetails.clientPhone.typeCode
            : "CELL";
        var payload = {
          agencyID: this.props.agencyId,
          clientPhone: {
            dataChangeCode: phnNum === "" ? "REMOVE" : "CHANGE",
            phoneNumber: phnNum.replace(/\D/g, ""),
            textOptInFlag: textOptInFlag,
            typeCode: typeCode,
            voiceOptInFlag:
              clientDetails.clientPhone !== undefined &&
              clientDetails.clientPhone.voiceOptInFlag !== undefined
                ? clientDetails.clientPhone.voiceOptInFlag
                : TextProps.VALUE_TRUE,
          },
        };
        this.setState({
          isEditing: TextProps.VALUE_FALSE,
          isEditFinished: TextProps.VALUE_TRUE,
          phoneMessage: TextProps.VALUE_TRUE,
        });
        this.props.updatePhoneNumber(payload);
      }
    } else {
      this.setState({
        isEditing: TextProps.VALUE_FALSE,
        isEditFinished: TextProps.VALUE_TRUE,
        phoneMessage: TextProps.VALUE_FALSE,
      });
      this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE);
    }
    }
    if(this.props.fields[0].name === "emailAddress"){
    if (
      this.state.isEditing === TextProps.VALUE_TRUE &&
      (this.props.fields[0].value !== clientDetails.clientEmail.emailAddress ||
      optInFlag !== clientDetails.clientEmail.optInFlag)
    ) {
      var emailData = this.props.fields[0].value;
      var isValidEmail =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.props.fields[0].value
        );
      if (
        (!isValidEmail && optInFlag === TextProps.VALUE_TRUE) ||
        (!isValidEmail && emailData !== "")
      ) {
        this.props.fields[0].error = TextProps.VALUE_TRUE;
        this.props.fields[0].errorMessage = common.emailErrMsg;
        this.setState({ isEditing: TextProps.VALUE_TRUE });
        return;
      } else {
        this.props.fields[0].error = TextProps.VALUE_FALSE;
        if (this.props.consumer.consumerData.accountId !== undefined) {
          this.props.fields[0].clientId =
            this.props.consumer.consumerData.accountId;
          this.props.fields[0].userAuth =
            this.props.consumer.mfaDetails.userAuth;
        } else if (
          this.props.consumer.consumerData.user !== undefined &&
          this.props.consumer.consumerData.user.accountId !== undefined
        ) {
          this.props.fields[0].clientId =
            this.props.consumer.consumerData.user.accountId;
          this.props.fields[0].userAuth =
            this.props.consumer.mfaDetails.userAuth;
        } else if (accountInfo !== null && accountInfo.clientId > 0) {
          this.props.fields[0].clientId = accountInfo.clientId;
          this.props.fields[0].userAuth = accountInfo.userAuth;
        }
        var resPayload = {
          agencyID: this.props.agencyId,
          clientEmail: {
            dataChangeCode: emailData === "" ? "REMOVE" : "CHANGE",
            emailAddress: emailData,
            optInFlag: optInFlag,
          },
        };
        this.setState({
          isEditing: TextProps.VALUE_FALSE,
          isEditFinished: TextProps.VALUE_TRUE,
          emailMessage: TextProps.VALUE_TRUE,
        });
        this.props.updateClientEmail(resPayload);
      }
    } else {
      this.setState({
        isEditing: TextProps.VALUE_FALSE,
        isEditFinished: TextProps.VALUE_TRUE,
        emailMessage: TextProps.VALUE_FALSE,
      });
      this.props.addrsWarningPopupAction(TextProps.VALUE_TRUE);
    }
    }
  };

  householdHomelessView = () => {
    if (
      !this.props.benefitLabel &&
      this.props.fields !== undefined &&
      this.props.fields[0].type === "address" &&
      this.props.columnLabel &&
      this.props.fields[0].name !== "allowEmailNotification" &&
      this.props.fields[1].name !== "allowTextNotification"
    ) {
      return (
        <div className="pure-u-1 pure-u-lg-1-2 editable-field__label is-household-container">
          <span className="text--gray">
            {
              interimReportPageLabels(
                selectedLanguage,
                languageConstants.contactInfo
              ).houselessLabel
            }
          </span>
        </div>
      );
    }
    return null;
  };

  startEditingFieldButton = (common,info) => {
    if (this.props.agencyId) {
      return (
        <div className="edit-button-position" >
          {
            this.props.pendingFlags &&
            this.props.pendingFlags.addressUpdatePendingFlag === "N"
            &&
            <div style={{marginBottom:"15px"}}>
              <p className="badge">{info.changeRequest}</p>
            </div>
          }
        <button
          // className="dta-button dta-button--outline-secondary editable-field__edit-button"
          onClick={this.startEditing}
          ref={(button) => (this.button = button)}
            style={{position:"absolute",right:0}}
            className={"dta-button dta-button--outline-secondary"}
          id={"edit-address-button"}
        >
          <span aria-hidden={TextProps.VALUE_STR_TRUE}>{
              common.edit
          }</span>
          <span className="sr-only">
            {" "}
            {this.convertingMultiLangLabels(this.props.editingLabel)
              ? this.convertingMultiLangLabels(this.props.editingLabel)
              : this.props.editingLabel}
          </span>
        </button>
        </div>
      );
    }
    return null;
  };

  householdHomelessUIMode = (common,info) => {
    if (
      !this.props.benefitLabel &&
      this.props.fields !== undefined &&
      this.props.fields[0].type === "address"
    ) {
      return (
        <div className="pure-u-1 pure-u-lg-1-2 editable-field__label is-household-container">
          <div className="editable-field__value">
            <div className="editable-field__value-display">
              {this.props.fields[0].homelessFlag
                ? interimReportPageLabels(
                    selectedLanguage,
                    languageConstants.earnedIncomeStatic
                  ).targetValueYes
                : interimReportPageLabels(
                    selectedLanguage,
                    languageConstants.earnedIncomeStatic
                  ).targetValueNo}
            </div>
            {
              // disabled Edit button as per DHR UTR comment
              this.startEditingFieldButton(common,info)
            }
          </div>
        </div>
      );
    }
    return null;
  };

  residentialAddrEditingMode = () => {
    let editablefieldClassName = "";
    if (!this.props.benefitLabel) {
      editablefieldClassName = "pure-u-1 pure-u-lg-1-2 editable-field__label";
    } else {
      editablefieldClassName =
        "pure-u-1 pure-u-lg-1-1 editable-field__label dta-form__label--size-small";
    }

    let benefitLabelClassName = "";
    if (!this.props.benefitLabel) {
      benefitLabelClassName = "pure-u-1 pure-u-lg-1-2";
    } else {
      benefitLabelClassName = "pure-u-1 pure-u-lg-1-1 riv-content";
    }

    if (this.props.fields[0].type === "address" && this.props.columnLabel) {
      return (
        <div className="edit-addr-wrapper">
          <div className={editablefieldClassName}>
            <span className="text--gray">
              {
                interimReportPageLabels(
                  selectedLanguage,
                  languageConstants.contactInfo
                ).houselessLabel
              }
            </span>
          </div>
          <div className={benefitLabelClassName}>
            <div className="editable-field__value">
              <div className="editable-field__value-edit">
                <Radio
                  name="contact-info-changed homeless_flag"
                  className="dta-form__option-list"
                  optionClass="dta-form__option dta-form__option--width-1-of-2"
                  options={
                    interimReportPageLabels(
                      selectedLanguage,
                      languageConstants.contactInfo
                    ).houseLessOptions
                  }
                  required={TextProps.VALUE_TRUE}
                  value={
                    interimReportPageLabels(
                      selectedLanguage,
                      languageConstants.aboutMe
                    ).optionYesNo[this.props.fields[0].homelessFlag ? 0 : 1]
                  }
                  onChange={(value) => {
                    this.isHomeLessUpdate(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={
              !this.props.benefitLabel
                ? "pure-u-1 pure-u-lg-1-2 editable-field__label res-addr-edit-container"
                : "pure-u-1 pure-u-lg-1-1 editable-field__label res-addr-edit-container"
            }
          >
            <span className="text--gray">
              {this.convertingMultiLangLabels(this.props.fields[0][PROPS.NAME])
                ? this.convertingMultiLangLabels(
                    this.props.fields[0][PROPS.NAME]
                  )
                : this.props.fields[0][PROPS.LABEL]}
            </span>
          </div>
          <div
            className={
              !this.props.benefitLabel
                ? "pure-u-1 pure-u-lg-1-2 res-addr-edit-container"
                : "pure-u-1 pure-u-lg-1-1 res-addr-edit-container"
            }
          >
            <div className="editable-field__value">
              <div className="editable-field__value-edit">
                {this.props.fields[0].error && (
                  <div className="myinfo-resiaddr-error">
                    <font className="error-text-clr">
                      <b>{this.props.fields[0].errorMessage}</b>
                    </font>
                  </div>
                )}
                {resolveQuestion(
                  this.props.fields[0],
                  {
                    doRetrieve: this._doRetrieve,
                    onChange: this._handleChangeForQuestion,
                    doGetUniqueId: this._getUniqueId,
                  },
                  {
                    doBuildControlProps: this._doBuildControlProps,
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  mailingAddressEdittingMode = () => {
    if (
      this.props.fields[1].type === "address" &&
      this.props.columnLabel &&
      this.props.fields[0].resAddrMatchFlag === TextProps.VALUE_FALSE
    ) {
      return (
        <div>
          <div
            className={
              !this.props.benefitLabel
                ? "pure-u-1 pure-u-lg-1-2 editable-field__label"
                : "pure-u-1 pure-u-lg-1-1 editable-field__label"
            }
          >
            <span className="text--gray">
              {this.convertingMultiLangLabels(this.props.fields[1][PROPS.NAME])
                ? this.convertingMultiLangLabels(
                    this.props.fields[1][PROPS.NAME]
                  )
                : this.props.fields[1][PROPS.LABEL]}
            </span>
          </div>
          <div
            className={
              !this.props.benefitLabel
                ? "pure-u-1 pure-u-lg-1-2"
                : "pure-u-1 pure-u-lg-1-1 riv-content"
            }
          >
            <div className="editable-field__value">
              <div className="editable-field__value-edit">
                {this.props.fields[1].error && (
                  <div className="myinfo-resiaddr-error">
                    <font className="error-text-clr">
                      <b>{this.props.fields[1].errorMessage}</b>
                    </font>
                  </div>
                )}
                {resolveQuestion(
                  this.props.fields[1],
                  {
                    doRetrieve: this._doRetrieve,
                    onChange: this._handleChangeForQuestion,
                    doGetUniqueId: this._getUniqueId,
                  },
                  {
                    doBuildControlProps: this._doBuildControlProps,
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  requestBenefitVerificationMailAddr = (common) => {
    if (
      this.props.benefitLabel &&
      !this.props.requestEbt &&
      this.props.fields !== undefined &&
      this.props.fields[1].type === "address"
    ) {
      return (
        <div>
          <div className="pure-u-sm-20-24 pure-u-md-21-24 pure-u-lg-19-24 pure-u-xl-20-24 editable-field__label">
            <div className="editable-field__value">
              <div className="editable-field__value-display">
                <div>
                  {" "}
                  {this.props.language.consumer.common.mailingAddress}{" "}
                </div>
                {this.props.agencyId === 0
                  ? this.props.fields[0].name !== "allowEmailNotification" &&
                    this.props.fields[0].name !== "allowTextNotification"
                    ? this.resolveDisplayFormat(
                        this.props.fields[1].type
                          ? this.props.fields[1].type
                          : this.props.fields[0].type,
                        this.props.fields[1].value.city !== ""
                          ? this.props.fields[1].value
                          : this.props.fields[0].value
                      )
                    : null
                  : this.resolveDisplayFormat(
                      this.props.fields[1].type
                        ? this.props.fields[1].type
                        : this.props.fields[0].type,
                      this.props.fields[1].value.city !== ""
                        ? this.props.fields[1].value
                        : this.props.fields[0].value
                    )}
              </div>
            </div>
          </div>

          <div className="pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-5-24 pure-u-xl-4-24 editable-field__label req-income-addr-edit-btn">
            <div className="editable-field__value">
              <div className="editable-field__value-display">
                <button
                  className="dta-button dta-button--outline-secondary editable-field__edit-button"
                  onClick={this.startEditing}
                  ref={(button) => (this.button = button)}
                >
                  <span aria-hidden={TextProps.VALUE_STR_TRUE}>
                    {common.edit}
                  </span>
                  <span className="sr-only">
                    {" "}
                    {this.convertingMultiLangLabels(this.props.editingLabel)
                      ? this.convertingMultiLangLabels(this.props.editingLabel)
                      : this.props.editingLabel}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  ressAddressView = () => {
    let allowNotificationVal = null;
    if (
      this.props.fields[0].name !== "allowEmailNotification" &&
      this.props.fields[0].name !== "allowTextNotification"
    ) {
      allowNotificationVal = this.resolveDisplayFormat(
        this.props.fields[0].type,
        this.props.fields[0].value
      );
    }
    if (
      !this.props.benefitLabel &&
      this.props.fields !== undefined &&
      this.props.fields[0].type === "address"
    ) {
      return (
        <div className="pure-u-1 pure-u-lg-1-2 editable-field__label">
          <div className="editable-field__value">
            <div className="editable-field__value-display">
              {this.props.agencyId === 0
                ? allowNotificationVal
                : this.resolveDisplayFormat(
                    this.props.fields[0].type,
                    this.props.fields[0].value
                  )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  requestEbtcardMailingAddress = () => {
    if (
      this.props.benefitLabel &&
      this.props.requestEbt &&
      this.props.fields !== undefined &&
      this.props.fields[1].type === "address"
    ) {
      return (
        <div className="pure-u-1 pure-u-lg-1-2 editable-field__label">
          <div className="editable-field__value">
            <div className="editable-field__value-display">
              <div>
                {
                  interimReportPageLabels(
                    selectedLanguage,
                    languageConstants.contactInfo
                  ).mailingAddress
                }
              </div>
              {this.props.agencyId === 0
                ? this.props.fields[0].name !== "allowEmailNotification" &&
                  this.props.fields[0].name !== "allowTextNotification"
                  ? this.resolveDisplayFormat(
                      this.props.fields[1].type
                        ? this.props.fields[1].type
                        : this.props.fields[0].type,
                      this.props.fields[1].value.city !== ""
                        ? this.props.fields[1].value
                        : this.props.fields[0].value
                    )
                  : null
                : this.resolveDisplayFormat(
                    this.props.fields[1].type
                      ? this.props.fields[1].type
                      : this.props.fields[0].type,
                    this.props.fields[1].value.city !== ""
                      ? this.props.fields[1].value
                      : this.props.fields[0].value
                  )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { common,info } = this.props.language.consumer;
    let marginEditingLabel;
    if (this.convertingMultiLangLabels(this.props.editingLabel)) {
      marginEditingLabel = this.convertingMultiLangLabels(
        this.props.editingLabel
      );
    } else {
      marginEditingLabel = this.props.editingLabel;
    }

    if (this.state.isEditing) {
      return (
        <div>
          
        <li>
          {/* <FocusTrap
            focusTrapOptions={{
              onDeactivate: this.finishEditing,
              clickOutsideDeactivates: TextProps.VALUE_FALSE,
            }}
          > */}
            <div className="editable-field__edit-header margin-bottom">
              <p className="margin-none">
                <b> {marginEditingLabel}</b>
              </p>

              <button
                className="dta-button dta-button--outline-secondary editable-field__done-button"
                onClick={
                  this.props.fields[0].type === "address"
                    ? this.finishEditing
                    : this.onUpdatePhoneEmail
                }
              >
                {common.done}
              </button>
            </div>
            {/* residentials address ui on editing mode   */}
            {this.residentialAddrEditingMode()}
            {/* mailing address ui on editing mode   */}
            {this.mailingAddressEdittingMode()}

            {/* phone and e-mail ui on editing mode   */}
            {this.props.fields.map((field, i) => {
              let nameText = "";
              if (this.convertingMultiLangLabels(field[PROPS.NAME])) {
                nameText = this.convertingMultiLangLabels(field[PROPS.NAME]);
              } else {
                nameText = field[PROPS.LABEL];
              }

              return (
                <div
                  className="pure-g margin-bottom editable-field"
                  key={field[PROPS.NAME]}
                >
                  {field.type !== "address" && this.props.columnLabel ? (
                    <div
                      className={`${this.props.columnClass} editable-field__label`}
                    >
                      <span className="text--gray">{nameText}</span>
                    </div>
                  ) : null}
                  {field.type !== "address" && this.props.columnLabel ? (
                    <div
                      className={`${this.props.columnClass} editable-field__field`}
                    >
                      <div className="editable-field__value">
                        <div className="editable-field__value-edit">
                          {resolveQuestion(
                            field,
                            {
                              doRetrieve: this._doRetrieve,
                              onChange: this._handleChangeForQuestion,
                              doGetUniqueId: this._getUniqueId,
                            },
                            {
                              doBuildControlProps: this._doBuildControlProps,
                            }
                          )}
                          {this.props.fields[i].error && (
                            <span>
                              <font className="error-text-clr">
                                <b>{this.props.fields[i].errorMessage}</b>
                              </font>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          {/* </FocusTrap> */}
        </li>
        {this.renderWarningPopup()}
        </div>
      );
    } else {
      return (
        <li>
          {/* Is your household homeless ui on view mode   */}
          {this.householdHomelessView()}
          {/* Is your household homeless ui on view mode   */}
          {this.householdHomelessUIMode(common,info)}

          {/* residentials address ui on view mode   */}
          {!this.props.benefitLabel ? (
            this.props.fields !== undefined ? (
              this.props.fields[0].type === "address" ? (
                this.props.columnLabel && this.props.agencyId === 0 ? (
                  this.props.fields[0].name !== "allowEmailNotification" &&
                  this.props.fields[0].name !== "allowTextNotification" ? (
                    <div className="pure-u-1 pure-u-lg-1-2 editable-field__label">
                      <span className="text--gray">
                        {this.convertingMultiLangLabels(
                          this.props.fields[0].name
                        )
                          ? this.convertingMultiLangLabels(
                              this.props.fields[0].name
                            )
                          : this.props.fields[0][PROPS.LABEL]}
                      </span>
                    </div>
                  ) : null
                ) : (
                  <div
                    className={`${this.props.columnClass} editable-field__label`}
                  >
                    <span className="text--gray">
                      {this.convertingMultiLangLabels(this.props.fields[0].name)
                        ? this.convertingMultiLangLabels(
                            this.props.fields[0].name
                          )
                        : this.props.fields[0][PROPS.LABEL]}
                    </span>
                  </div>
                )
              ) : null
            ) : null
          ) : null}
          {/* residentials address ui on view mode   */}
          {this.ressAddressView()}
          {/* mailing address ui on view mode   */}
          {!this.props.benefitLabel ? (
            this.props.fields !== undefined ? (
              this.props.fields[1].type === "address" ? (
                this.props.columnLabel && this.props.agencyId === 0 ? (
                  this.props.fields[1].name !== "allowEmailNotification" &&
                  this.props.fields[1].name !== "allowTextNotification" ? (
                    <div className="pure-u-1 pure-u-lg-1-2 editable-field__label view-mail-add-container">
                      <span className="text--gray">
                        {this.convertingMultiLangLabels(
                          this.props.fields[1].name
                        )
                          ? this.convertingMultiLangLabels(
                              this.props.fields[1].name
                            )
                          : this.props.fields[1][PROPS.LABEL]}
                      </span>
                    </div>
                  ) : null
                ) : (
                  <div className="pure-u-1 pure-u-lg-1-2 editable-field__label view-mail-add-container">
                    <span className="text--gray">
                      {this.convertingMultiLangLabels(this.props.fields[1].name)
                        ? this.convertingMultiLangLabels(
                            this.props.fields[1].name
                          )
                        : this.props.fields[1][PROPS.LABEL]}
                    </span>
                  </div>
                )
              ) : null
            ) : null
          ) : null}
          {/* mailing address ui on view mode   */}
          {!this.props.benefitLabel ? (
            this.props.fields !== undefined ? (
              this.props.fields[1].type === "address" &&
              this.props.fields[1].name === "is-also-mailing" &&
              this.props.columnLabel &&
              this.props.consumer.consumerData.clientAddress &&  this.props.consumer.consumerData.clientAddress.length >0 && this.props.consumer.consumerData.clientAddress[0].resAddrMatchFlag === TextProps.VALUE_FALSE ? (
                <div className="pure-u-1 pure-u-lg-1-2 editable-field__label view-mail-add-container">
                  <div className="editable-field__value">
                    <div className="editable-field__value-display">
                      {this.props.agencyId === 0
                        ? this.props.fields[1].name !==
                            "allowEmailNotification" &&
                          this.props.fields[1].name !== "allowTextNotification"
                          ? this.resolveDisplayFormat(
                              this.props.fields[1].type,
                              this.props.fields[1].value
                            )
                          : null
                        : this.resolveDisplayFormat(
                            this.props.fields[1].type,
                            this.props.fields[1].value
                          )}
                    </div>
                  </div>
                </div>
              ) : this.props.fields[1].name === "is-also-mailing" ? (
                <div className="pure-u-1 pure-u-lg-1-2 editable-field__label view-mail-add-container">
                  <div className="editable-field__value">
                    <div className="editable-field__value-display">
                      {this.props.language.consumer.common.sameResAddress}
                    </div>
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}

          {/* phone and email ui on view mode   */}
          {!this.props.benefitLabel
            ? this.props.fields.map((field, i) => {
                return (
                  <div
                    className="pure-g margin-bottom editable-field"
                    key={field.name}
                  >
                    {/* phone and e-mail ui on view mode   */}
                    {field.type !== "address" ? (
                      this.props.columnLabel && this.props.agencyId === 0 ? (
                        field.name !== "allowEmailNotification" &&
                        field.name !== "allowTextNotification" ? (
                          <div
                            className={`${this.props.columnClass} editable-field__label`}
                          >
                            <span className="text--gray">
                              {this.convertingMultiLangLabels(field.name)
                                ? this.convertingMultiLangLabels(field.name)
                                : field[PROPS.LABEL]}
                            </span>
                          </div>
                        ) : null
                      ) : (
                        <div
                          className={`${this.props.columnClass} editable-field__label`}
                        >
                          <span className="text--gray">
                            {this.convertingMultiLangLabels(field.name)
                              ? this.convertingMultiLangLabels(field.name)
                              : field[PROPS.LABEL]}
                          </span>
                        </div>
                      )
                    ) : null}

                    {/* phone and e-mail ui on view mode   */}

                    {field.type !== "address" ? (
                      <div
                        className={`${this.props.columnClass} editable-field__field`}
                        style={{marginBottom:"10px"}}
                      >
                        <div className="editable-field__value">
                          <div className="editable-field__value-display">
                            {this.props.fields[0].value === "" &&
                            field.name !== "allowEmailNotification" &&
                            field.name !== "allowTextNotification" ? (
                              <span className="badge">
                                {
                                  interimReportPageLabels(
                                    selectedLanguage,
                                    languageConstants.householdStatic
                                  ).removedLabelContact
                                }
                              </span>
                            ) : this.props.agencyId === 0 ? (
                              field.name !== "allowEmailNotification" &&
                              field.name !== "allowTextNotification" ? (
                                this.resolveDisplayFormat(
                                  field.type,
                                  field.value
                                )
                              ) : null
                            ) : (
                              this.resolveDisplayFormat(field.type, field.value)
                            )}
                          </div>
                          {i === 0 && this.props.agencyId ? (
                            <div className="edit-button-position" >
                              {
                                (field.type === "inputPhone" && this.props.pendingFlags &&
                                this.props.pendingFlags.phoneUpdatePendingFlag === "N" )|| (field.type === "inputText" && this.props.pendingFlags &&
                                this.props.pendingFlags.emailUpdatePendingFlag  === "N")
                                 ?
                                  <div style={{marginTop:"-40px"}}>
                                    <p className="badge" style={{marginBottom:"15px"}} >{info.changeRequest}</p>
                                  </div>
                                  :
                                  null
                              }
                              <div>
                              <button
                                className={"dta-button dta-button--outline-secondary edit-button-position"}
                              onClick={this.startEditing}
                              ref={(button) => (this.button = button)}
                                style={{position:"absolute",right:0}}
                                id={field.type === "inputText" ? "edit-email-button" : "edit-phone-button"}
                            >
                              <span aria-hidden={TextProps.VALUE_STR_TRUE}>
                                  {field.type === "inputPhone" && common.edit}
                                  {field.type === "inputText" && common.edit}
                              </span>
                              <span className="sr-only">
                                {" "}
                                {this.convertingMultiLangLabels(
                                  this.props.editingLabel
                                )
                                  ? this.convertingMultiLangLabels(
                                      this.props.editingLabel
                                    )
                                  : this.props.editingLabel}
                              </span>
                            </button>
                               </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}

          {/* Success popup */}
          {modalHelperFunction.dtaHelperModal(
            (instance) => (this.uploadDocumentModal = instance),
            this.state.uploadDocumentModalOpen,
            this.closeSubmitModal,
            "rivl-modal editable-modal",
            "pebt-modal",
            this.state.errorMessage,
            this.state.hideCloseIcon
          )}

          {/* benefit list info mailing address ui on view mode */}

          {/* request benefit verification letter mailing address ui on view mode */}
          {this.requestBenefitVerificationMailAddr(common)}

          {/* request ebt card mailing address ui on view mode */}
          {this.requestEbtcardMailingAddress()}
        </li>
      );
    }
  }

  // Helpers
  // -------

  _doRetrieve = (questionData, conditionObj) => {
    return questionData.value;
  };

  _handleChangeForQuestion = (questionData, conditionObj, val) => {
    sessionStorage.setItem("isEditingStarted", TextProps.VALUE_TRUE);
    tryCall(this.props.onChange, questionData, conditionObj, val);
  };

  _getUniqueId = (name = "") => {
    return name ? this.getUniqueId(name) : this.nextUniqueId();
  };

  _doBuildControlProps = (questionData, conditionObj) => {
    return {
      labelHidden: !this.props.controlLabel,
    };
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addressValidation: addressValidation,
      updatePhoneNumber: updatePhoneNumber,
      updateClientEmail: updateClientEmail,
      updateClientAddress: updateClientAddress,
      addressValidationPopupAction: addressValidationPopupAction,
      mailingAddressValidationPopUpAction: mailingAddressValidationPopUpAction,
      isMailMyInfo: isMailMyInfo,
      isHouseholdUpdate: isHouseholdUpdate,
      isPopupClosed: isPopupClosed,
      addrsWarningPopupAction:addrsWarningPopupAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DTAEditableFields);