const initialState = {
  chatMessages:[],
  token: null
}

export function chatReducer(state = initialState, action) {
    switch (action.type) {
      case "FETCH_TOKEN_SUCCESS":
        return {
          ...state,
          token: action.payload,
        };
      case "FETCH_TOKEN_ERROR":
        return {
          ...state,
          token: null,
        };
      case "ADD_MESSAGE":
        return {
          ...state, 
          chatMessages:[...state.chatMessages, action.payload],
        }
      case "CLEAR_MESSAGE":
      return {
        ...state, 
        chatMessages:[],
      }
      default: 
        return state;
    }
   }