import React, { Component } from "react";
import logo from "../../../utils/resources/images/alabama.png";

import { connect } from "react-redux";
import { loadBenefitDetails } from "../../../redux/provider/benefitDetailsAction";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as TextProps from "../../../utils/constants/text";

class BenefitDetails extends Component {
  constructor() {
    super();
    this.state = {
      error: TextProps.VALUE_FALSE,
    };
    this.displayBenefitDetails = this.displayBenefitDetails.bind(this);
  }

  componentDidMount() {
    this.props.loadBenefitDetails();
  }

  render() {
    const { benefitDetails } = this.props.provider;
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2> SNAP Apply</h2>
        </div>
        <div>
          <span>
            {" "}
            DHR Coonect provide food stamps very low income people under comes
            selef, LLP, LP, Other{" "}
          </span>
        </div>
        <p></p>
        <div>{this.displayBenefitDetails(benefitDetails)}</div>
      </div>
    );
  }

  displayBenefitDetails(dataInfo) {
    if (
      dataInfo === null ||
      dataInfo.length === undefined ||
      dataInfo === {} ||
      dataInfo.length === 0
    ) {
      return <p>NO data----</p>;
    } else {
      return (
        <div>
          <form>
            <body>
              <table>
                <tr>
                  {" "}
                  <td>
                    {" "}
                    <label> EBT Balance </label>
                    {dataInfo[0].EBT_BALANCE}{" "}
                  </td>
                  <td>
                    {" "}
                    <label> Status </label>
                    {dataInfo[0].STATUS}{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <label> Start Date </label>
                    {dataInfo[0].START_DATE}{" "}
                  </td>
                  <td>
                    <label> Next benefit amount </label>
                    {dataInfo[0].NEXT_BENF_AMT}
                  </td>
                </tr>

                <tr>
                  {" "}
                  <td>
                    {" "}
                    <label> Next benefit amount date </label>
                    {dataInfo[0].NEXT_BENF_AMT_DATE}{" "}
                  </td>
                  <td>
                    {" "}
                    <label> ADDRESS </label>
                    {dataInfo[0].ADDRESS}{" "}
                  </td>
                </tr>

                <tr>
                  {" "}
                  <td>
                    {" "}
                    <label> CASE MANAGER Full Name </label>
                    {dataInfo[0].LAST_NAME} &nbsp; {dataInfo[0].FIRST_NAME}{" "}
                  </td>
                  <td>
                    {" "}
                    <label> Phone number </label>
                    {dataInfo[0].MOBILE_NO}{" "}
                  </td>
                </tr>

                <tr>
                  {" "}
                  <td>
                    {" "}
                    <label> MEMBER HOUSEHOLD Full Name </label>
                    {dataInfo[0].FAM_LAST_NAME} &nbsp;{" "}
                    {dataInfo[0].FAM_FIRST_NAME}{" "}
                  </td>
                  <td>
                    {" "}
                    <label> DATE OF BIRTH </label>
                    {dataInfo[0].FAM_DATE_OF_BIRTH}{" "}
                  </td>
                </tr>

                <tr>
                  {" "}
                  <td>
                    {" "}
                    <label> BENEFIT STATUS </label>
                    {dataInfo[0].BENEFIT_STATUS}{" "}
                  </td>
                  <td>
                    {" "}
                    <label> BENEFIT DATE </label>
                    {dataInfo[0].BENEFIT_DATE}{" "}
                  </td>
                </tr>
              </table>
            </body>
          </form>
        </div>
      );
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBenefitDetails: loadBenefitDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitDetails);
