const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function monthSelectLabels(language) {
  var u = window.location.href;
  if (u.endsWith("/greeterScreening")) {
    return {
      dayLabel: "Day",
      monthLabel: "Month",
      yearLabel: "Year",
    };
  } else {
    switch (language) {
      case ES:
        return {
          year: "Año",
          month: "Mes",
          week: "Semana",
        };
      case PT:
        return {
          year: "Ano",
          month: "Mês",
          week: "Semana",
        };
      case ZH:
        return {
          year: "年",
          month: "月",
          week: "周",
        };
      case VI:
        return {
          year: "Năm",
          month: "Tháng",
          week: "Tuần",
        };
      case HI:
        return {
          year: "Ane",
          month: "Mwa",
          week: "Semèn",
        };
      case EN:
      default:
        return {
          year: "Year",
          month: "Month",
          week: "Week",
        };
    }
  }
}

export function requiredLabels(language) {
  var u = window.location.href;
  if (u.endsWith("/greeterScreening")) {
    return "(Required)";
  } else {
    switch (language) {
      case EN:
        return "(Required)";
      case ES:
        return "(Obligatorio)";
      case PT:
        return "(Obrigatório)";
      case ZH:
        return "(必填)";
      case VI:
        return "(bắt buộc)";
      case HI:
        return "(Obligatwa)";
      default:
        return "(Required)";
    }
  }
}

export function screenresultMultilanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        screeningHeader: "You may be eligible for SNAP benefits",
        pleaseclickapplyLabel:
          "Please click the Apply Now button to complete the online SNAP application.",
        snapeligibilityBenefitLabel:
          "SNAP eligibility and benefit amounts are based on a number of factors, including:",
        snapnumberofFactorsList: [
          "Household size",
          "Citizenship/Non-citizen status",
          "Income",
          "Expenses",
        ],
        notEligibleHeader:
          "You may not be eligible based on your income and household size.",
        specificRulelabel:
          "SNAP has specific rules for these factors and we may treat them differently than you’d expect.  For a more in-depth review you may contact the Food Source Hotline at 800-645-8333. Please note although you may not be eligible you have the right to apply and may click Apply Now to complete an online application.",
      };
    case ES:
      return {
        screeningHeader:
          "Es posible que sea elegible para recibir beneficios de SNAP.",
        pleaseclickapplyLabel:
          "Haga clic en el botón Solicitar ahora para completar la solicitud a SNAP en línea.",
        snapeligibilityBenefitLabel:
          "La elegibilidad y los montos de los beneficios de SNAP se basan en diversos factores, que incluyen:",
        snapnumberofFactorsList: [
          "Tamaño del núcleo familiar",
          "Ciudadanía/Estado de no ciudadano",
          "Ingreso",
          "Gastos",
        ],
        notEligibleHeader:
          "Es posible que no sea elegible con base en sus ingresos y el tamaño de su núcleo familiar.",
        specificRulelabel:
          "SNAP tiene reglas específicas para estos factores y podríamos tratarlos distinto de lo que usted esperaría.  Para obtener una a revisión más detallada, puede comunicarse con la Línea directa de FoodSource al 800-645-8333. Tenga en cuenta que, aunque es posible que no sea elegible, tiene derecho a presentar la solicitud y puede hacer clic en Solicitar ahora para completar la solicitud.",
      };
    case PT:
      return {
        screeningHeader: "Você é elegível ao benefício SNAP.",
        pleaseclickapplyLabel:
          "Clique no botão Solicitar agora para preencher a sua solicitação on-line do benefício SNAP.",
        snapeligibilityBenefitLabel:
          "Os valores relacionados à elegibilidade e ao benefício SNAP se baseiam em diversos fatores, como:",
        snapnumberofFactorsList: [
          "Tamanho da família",
          "Status de cidadania",
          "Renda",
          "Despesas",
        ],
        notEligibleHeader:
          "Você não é elegível ao benefício SNAP com base na sua renda e no tamanho da sua família.",
        specificRulelabel:
          "O benefício SNAP tem regras específicas para esses fatores e podemos abordar isso de um modo diferente das suas expectativas. Para se aprofundar nessas questões, entre em contato com a linha direta de assistência no número 800-645-8333. Observe que, embora você não seja elegível ao benefício, é seu direito solicitar e clicar no botão Solicitar agora para preencher a sua solicitação.",
      };
    case ZH:
      return {
        screeningHeader: "您符合SNAP补助资格。",
        pleaseclickapplyLabel: "请单击立刻申请按钮以填写SNAP在线申请表。",
        snapeligibilityBenefitLabel:
          "SNAP资格与补助金额基于多种因素确定，包括：",
        snapnumberofFactorsList: [
          "家庭规模",
          "城市居民/非城市居民身份",
          "收入",
          "开支",
        ],
        notEligibleHeader: "根据您的收入和家庭规模，您不具备申请资格。",
        specificRulelabel:
          "SNAP针对这些因素设有具体规定，我们的处理方式可能与您的预期有一定出入。欲深入了解详情，请拨打食物获取热线 800-645-8333。请注意，尽管您可能不具备资格，您仍有权申请，请单击立刻申请以填写在线申请表。  ",
      };
    case VI:
      return {
        screeningHeader:
          "Quý vị có thể có đủ điều kiện để nhận các khoản trợ cấp SNAP",
        pleaseclickapplyLabel:
          'Vui lòng nhấp vào nút "Apply Now" (Nộp đơn Bây giờ) để hoàn thành việc nộp đơn xin SNAP trực tuyến.',
        snapeligibilityBenefitLabel:
          "Đủ điều kiện và các khoản tiền trợ cấp SNAP được dựa trên một số yếu tố, bao gồm:",
        snapnumberofFactorsList: [
          "Số thành viên trong hộ gia đình",
          "Tình trạng công dân / không phải là công dân",
          "Thu nhập",
          "Chi phí",
        ],
        notEligibleHeader:
          "Quý vị có thể không hội đủ điều kiện xét về thu nhập và số thành viên trong hộ gia đình của quý vị.",
        specificRulelabel:
          'SNAP có những quy định cụ thể về các yếu tố này và chúng tôi có thể xử lý chúng khác với mong muốn của quý vị. Để xem xét sâu hơn, quý vị có thể liên lạc với Food Source Hotline số 800-645-8333. Xin lưu ý rằng mặc dù quý vị có thể không hội đủ điều kiện nhưng quý vị vẫn có quyền nộp đơn và có thể nhấp vào nút "Apply Now" (Đăng ký ngay bây giờ) để hoàn tất đơn xin trực tuyến.',
      };
    case HI:
      return {
        screeningHeader: "Ou ka kalifye pou benefis SNAP",
        pleaseclickapplyLabel:
          "Tanpri klike sou bouton Apply Now pou ranpli aplikasyon an liy SNAP la.",
        snapeligibilityBenefitLabel:
          "Kalifikasyon SNAP ak montan benefis yo baze sou yon kantite faktè, tankou:",
        snapnumberofFactorsList: [
          "Gwosè fwaye a",
          "Estati Sitwayen/Non- sitwayen Ameriken",
          "Revni",
          "Depans",
        ],
        notEligibleHeader: "Ou ka pa kalifye baze sou revni ak gwosè fwaye w.",
        specificRulelabel:
          "SNAP gen règleman espesifik pou faktè sa yo epi nou ka trete yo yon fason diferan ke jan ou ta espere. Pou yon revizyon pi apwofondi ou ka kontakte Liy Dirèk Food Source la nan 800-645-8333. Tanpri sonje ke menm si ou ka pa kalifye, ou gen dwa pou aplike epi ou ka klike sou Apply Now pou ranpli yon aplikasyon an liy.",
      };
    default:
      return;
  }
}

export function screeningStaticLabels(language) {
  var u = window.location.href;
  if (u.endsWith("/greeterScreening")) {
    return {
      getMyResult: "Get my results",
      goBack: "Go Back",
      saveAndExit: "Save & Exit",
      exit: "Exit",
      reset: "Reset",
      signAndSubmit:"Sign and Submit",
      needHelpFeedback: "Need help or have feedback?",
      ContactUs: "Contact Us",
      OpensNewTab: "Opens in a new tab",
      sitePolicies: "Site Policies",
      close: "Close",
      saveMyprogressLabel: "Save my progress for later",
      applynow: "Apply Now",
      youCanLegally: "You can legally stop and",
      submitYourLabel: "submit your application now.",
      howeverLabel:
        "However, we encourage you to keep going and make the rest of the process easier.",
      streetAddr: "Street Address",
      zipCode: "Zip",
      cityLabel: "City",
      stateLabel: "State",
      countyLabel: "County",
      countryLabel: "Country",
      streetAddrPlaceholder:
        "Please enter House # / Apartment # / Unit #, if any.",
    };
  } else {
    switch (language) {
      case EN:
        return {
          getMyResult: "Get my results",
          goBack: "Go Back",
          saveAndExit: "Save and Exit",
          exit: "Exit",
          reset: "Reset",
          signAndSubmit:"Sign and Submit",
          needHelpFeedback: "Need help or have feedback?",
          ContactUs: "Contact Us",
          OpensNewTab: "Opens in a new tab",
          sitePolicies: "Site Policies",
          close: "Close",
          saveMyprogressLabel: "Save my progress for later",
          applynow: "Apply Now",
          youCanLegally: "You can legally stop and",
          submitYourLabel: "submit your application now.",
          howeverLabel:
            "However, we encourage you to keep going and make the rest of the process easier.",
          streetAddr: "Street Address",
          addrLine2: "Apt./Unit",
          addressLine1: "Address line1",
          addressLine2: "Address line2",
          county: "County",
          country: "Country",
          zipCode: "Zip Code",
          cityLabel: "City",
          stateLabel: "State",
          streetAddrPlaceholder:
            "Please enter House # / Apartment # / Unit #, if any.",
        };
      case ES:
        return {
          getMyResult: "Ver mis resultados",
          goBack: "Volver",
          saveAndExit: "Guardar y salir",
          exit: "salida",
          reset: "Restablecimiento",
          signAndSubmit:"Firme y envíe",
          needHelpFeedback: "Necesita ayuda o tiene comentarios",
          ContactUs: "Contáctenos",
          OpensNewTab: "Se abre en una nueva pestaña",
          sitePolicies: "Políticas del sitio",
          close: "Cerca",
          saveMyprogressLabel: "Guardar mi avance para más tarde",
          applynow: "Solicitar ahora",
          youCanLegally: "Legalmente, puede parar y",
          submitYourLabel: "enviar su solicitud ahora.",
          howeverLabel:
              "Sin embargo, le recomendamos que continúe y haga el resto del proceso más sencillo.",
          streetAddr: "Dirección",
          addrLine2: "Apto./Unidad",
          addressLine1: "Dirección line1",
          addressLine2: "Dirección line2",
          county: "Condado",
          country: "País",
          zipCode: "Código postal",
          cityLabel: "Ciudad",
          stateLabel: "Estado",
          streetAddrPlaceholder:
              "Ingrese n.º de casa/n.º de apartamento/n.º de unidad, si lo hubiera.",
        };
      case PT:
        return {
          getMyResult: "Obter meus resultados",
          goBack: "Voltar",
          needHelpFeedback: "Precisa de ajuda ou tem um feedback a fazer?",
          ContactUs: "Contate-Nos",
          OpensNewTab: "Abre em uma nova guia",
          sitePolicies: "Políticas do site",
          close: "Fechar",
          saveMyprogressLabel: "Salvar meu progresso para continuar depois",
          applynow: "Solicitar agora",
          youCanLegally: "Legalmente, você pode parar por aqui e",
          submitYourLabel: "enviar a sua solicitação agora.",
          howeverLabel:
            "Entretanto, encorajamos você a continuar para que o processo se torne mais fácil.",
          streetAddr: "Street Address",
          addrLine2: "Apt./Unit",
          addressLine1: "Address line1",
          addressLine2: "Address line2",
          county: "County",
          country: "Country",
          zipCode: "Cep",
          cityLabel: "Cidade",
          stateLabel: "Estado",
          streetAddrPlaceholder:
            "Insira o nº da casa / apartamento / unidade, se houver.",
        };
      case ZH:
        return {
          getMyResult: "查看结果",
          goBack: "返回",
          needHelpFeedback: "需要帮助或提供反馈",
          ContactUs: "联系我们",
          OpensNewTab: "在新选项卡中打开",
          sitePolicies: "网站政策",
          close: "关",
          saveMyprogressLabel: "保存我的进度以备日后使用",
          applynow: "立刻申请",
          youCanLegally: "您可以仅填写到此，并",
          submitYourLabel: "提交您的申请表。",
          howeverLabel: "然而，我们鼓励您继续填写剩余部分。",
          iAgreeTermsLabel: "我同意这些条款",
          streetAddr: "Street Address",
          addrLine2: "Apt./Unit",
          addressLine1: "Address line1",
          addressLine2: "Address line2",
          county: "County",
          country: "Country",
          zipCode: "邮政编码",
          cityLabel: "城市",
          stateLabel: "州",
          streetAddrPlaceholder: "如果有，请输入房屋号/公寓房号/单元号。",
        };
      case VI:
        return {
          getMyResult: "Nhận kết quả",
          goBack: "Trở Lại",
          needHelpFeedback: "Quý vị cần trợ giúp hoặc có phản hồi",
          ContactUs: "Liên hệ chúng tôi",
          OpensNewTab: "Mở trong một tab mới",
          sitePolicies: "Chính sách trang web",
          close: "Gần",
          saveMyprogressLabel: "Lưu tiến trình của tôi để dùng sau này",
          applynow: "Nộp đơn Bây giờ",
          youCanLegally: "Quý vị có thể dừng lại và ",
          submitYourLabel: "gửi đơn của quý vị ngay bây giờ ",
          howeverLabel:
            "một cách hợp lệ. Tuy nhiên, chúng tôi khuyến khích quý vị nên tiếp tục và giúp cho phần còn lại của tiến trình dễ dàng hơn.",
          streetAddr: "Street Address",
          addrLine2: "Apt./Unit",
          addressLine1: "Address line1",
          addressLine2: "Address line2",
          county: "County",
          country: "Country",
          zipCode: "Số Zip",
          cityLabel: "Thành phố",
          stateLabel: "Tiểu bang",
          streetAddrPlaceholder:
            "Vui lòng nhập Số Nhà # / Số Căn hộ # / Số Đơn Vị #, nếu có.",
        };
      case HI:
        return {
          getMyResult: "Jwenn rezilta m yo",
          goBack: "Tounen",
          needHelpFeedback: "Èske ou bezwen èd oswa ou gen fidbak?",
          ContactUs: "Kontakte nou",
          OpensNewTab: "Opens in a new tab",
          sitePolicies: "Règleman Sit",
          close: "Fèmen",
          saveMyprogressLabel: "Save my progress for later",
          applynow: "Applike kounye a",
          youCanLegally: "Ou kapab legalman stope epi",
          submitYourLabel: "soumèt aplikasyon w lan kounye a.",
          howeverLabel:
            "Sepandan, nou ankouraje w kontinye epi rann rès pwosesis la pi fasil.",
          streetAddr: "Street Address",
          addrLine2: "Apt./Unit",
          addressLine1: "Address line1",
          addressLine2: "Address line2",
          county: "County",
          country: "Country",
          zipCode: "Kòd postal",
          cityLabel: "Vil",
          stateLabel: "Eta",
          streetAddrPlaceholder:
            "Tanpri rantre nan Kay #/Apatman #/Init #, si genyen.",
        };
      default:
        return;
    }
  }
}

export function moreLabels(language) {
  switch (language) {
    case EN:
      return "More";
    case ES:
      return "más";
    case PT:
      return "mais";
    case ZH:
      return "更多";
    case VI:
      return "Thông";
    case HI:
      return "Plis";
    default:
      return "More";
  }
}
export function copyrightLabels(language) {
  var u = window.location.href;
  const currentYear = new Date().getFullYear();

  if (u.endsWith("/greeterScreening")) {
    return `${currentYear} Commonwealth of Alabama`;
  } else {
    switch (language) {
      case EN:
        return `${currentYear} Alabama Department of Human Resources`;
      case ES:
        return `${currentYear} Departamento de Recursos Humanos de Alabama`;
      case PT:
        return `${currentYear} Comunidade de Alabama`;
      case ZH:
        return `${currentYear} 马萨诸塞联邦`;
      case VI:
        return `${currentYear} Khối Thịnh vượng Alabama`;
      case HI:
        return `${currentYear} Commonwealth Alabama`;
      default:
        return `${currentYear} Commonwealth of Alabama`;
    }
  }
}

export function datelanguageLabel(language) {
  var u = window.location.href;
  if (u.endsWith("/greeterScreening")) {
    return {
      dayLabel: "Day",
      monthLabel: "Month",
      yearLabel: "Year",
    };
  } else {
    switch (language) {
      case ES:
        return {
          dayLabel: "Día",
          monthLabel: "Mes",
          yearLabel: "Año",
        };
      case PT:
        return {
          dayLabel: "Dia",
          monthLabel: "Mês",
          yearLabel: "Ano",
        };
      case ZH:
        return {
          dayLabel: "日",
          monthLabel: "月",
          yearLabel: "年",
        };
      case VI:
        return {
          dayLabel: "Ngày",
          monthLabel: "Tháng",
          yearLabel: "Năm",
        };
      case HI:
        return {
          dayLabel: "Jou",
          monthLabel: "Mwa",
          yearLabel: "Ane",
        };
      case EN:
      default:
        return {
          dayLabel: "Day",
          monthLabel: "Month",
          yearLabel: "Year",
        };
    }
  }
}

export function activityGetDescription(typeCode) {
  const activityAlertList = {
    appnmt_code_1: "Assessment",
    appnmt_code_11: "24-Month Ext. Agreement",
    appnmt_code_12: "Ext. Appt. In-Office",
    appnmt_code_13: "Ext. Appt. Telephone",
    appnmt_code_15: "Income Match",
    appnmt_code_17: "Case Maint In-Office: Any Program",
    appnmt_code_18: "Reevaluation In-Office: EAEDC only",
    appnmt_code_19: "Application In-Office: SNAP",
    appnmt_code_190: "Client Assistance Coordinator - In-Office",
    appnmt_code_2: "Reassessment",
    appnmt_code_20: "Application Telephone: SNAP",
    appnmt_code_200: "Client Assistance Coordinator - Telephone",
    appnmt_code_201: "PSS Appointment In-Office",
    appnmt_code_202: "PSS Appointment Telephone",
    appnmt_code_90: "PSS Appointment Special Intensive Evaluation",
    appnmt_code_21: "Application Telephone: SNAP - Web Unit",
    appnmt_code_210: "Vendor Payment - Rent",
    appnmt_code_211: "Vendor Payment - Utility",
    appnmt_code_22: "Reevaluation In-Office: TANF only",
    appnmt_code_23: "Reevaluation: EA",
    appnmt_code_24: "Reevaluation In-Office: SNAP only",
    appnmt_code_25: "Reevaluation In-Office: EAEDC and SNAP",
    appnmt_code_26: "Reevaluation In-Office: TANF and SNAP",
    appnmt_code_3: "Cont Assessment",
    appnmt_code_4: "Cont Reassessment",
    appnmt_code_51: "Case Maint Telephone: Any Program",
    appnmt_code_52: "Reevaluation Telephone: EAEDC only",
    appnmt_code_53: "Reevaluation Telephone: SNAP only",
    appnmt_code_54: "Reevaluation Telephone: TANF only",
    appnmt_code_55: "EBT Photo Card",
    appnmt_code_57: "TANF Orientation",
    appnmt_code_58: "Reevaluation Telephone: EAEDC and SNAP",
    appnmt_code_59: "Reevaluation Telephone: TANF and SNAP",
    appnmt_code_9: "Good Cause Expiration",
    appnmt_code_203: "Family Cap - In-Office",
    appnmt_code_204: "Family Cap - Telephone",
  };

  const map = new Map(Object.entries(activityAlertList));
  return map.get(typeCode);
}
