import React, { Component } from "react";

export default class AlertAccordion extends Component {
  constructor(props) {
    super(props);
    this.elementRef = null;
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
  }
  render() {
    const { texts } = this.props;
    return (
      <div
        ref={(element) => (this.elementRef = element)}
        className="covid-banner-outer alert-collapsible"
      >
        <div className="covid-banner-inner max-width">
          {texts.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isAlertOpen !== this.props.isAlertOpen) {
      if (nextProps.isAlertOpen) {
        this.expandSection();
      } else {
        this.collapseSection();
      }
    }
  }
  collapseSection() {
    const element = this.elementRef;
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = "";

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + "px";
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + "px";
      });
    });
  }
  expandSection() {
    const element = this.elementRef;
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + "px";

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener("transitionend", this.onTransitionEnd);
  }
  onTransitionEnd() {
    const element = this.elementRef;
    element.removeEventListener("transitionend", this.onTransitionEnd);

    // remove "height" from the element's inline styles, so it can return to its initial value
    element.style.height = null;
  }
}
