import * as languageConsts from '../constants/types';

const EN = 'en-US';
const ES = 'es-ES';
const PT = 'pt-BR';
const ZH = 'zh-Hans';
const VI = 'vi-VI';
const HI = 'ht';

export function termsLanguageTranslation(language) { 
  
  switch (language) {
  case languageConsts.ENGLISH:
  return {
   termsHeader:"Rights, Responsibilities and Penalties",
   headerLabel: "Notice of Rights, Responsibilities and Penalties – Please Read Carefully",
   certifyContents:"I certify that I have read, or have had read to me, the information in this application. My answers to the questions in this application are true and complete to the best of my knowledge. I also certify that information I provide to the Department during the application interview and in the future will also be true and complete to the best of my knowledge. I understand that giving false or misleading information is fraud. I also understand that misrepresenting or withholding facts to establish SNAP eligibility is fraud. This results in an Intentional Program Violation (IPV) and is punishable by civil and criminal penalties.",
   understandDta:"I understand that the Department of Transitional Assistance (DHR) administers SNAP. I understand that DHR has 30 days from the date of application to process my application. Further, I understand that:",
   foodNutritionact:"The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social Security Number (SSN) and the SSN of each household member I apply for. DHR uses this information to determine my household’s eligibility for SNAP. DHR verifies this information through computer matching programs. I understand that DHR uses it to monitor compliance with program regulations.",
   reportingRulesDtachanges:"Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR)  and recertification with the exception of:",
   incomeExceeds:"If my household’s income exceeds the gross income threshold",
   workRequirementlabel:"If I am under the able-bodied adult without dependents (ABAWD) work requirements and my work hours drop below 20 hours weekly",
   verifiedInfomation:"If DHR receives verified information about my household, my benefit amount may change",
   tbaRulesone: "If I am not under the SNAP Simplified Reporting rules or Transitional Benefits Alternative (TBA) rules, I must report to DHR changes to my household that may affect our eligibility. I understand that I must report these changes to DHR in person, in writing or by phone ",
   tbaRuletwo:" within 10 days of the change.",
   tbaExample:" For example, you must report changes in your household’s income, size, or address.",
   findmeIneligible:"I have a right to speak to a supervisor if DHR finds me ineligible for emergency SNAP benefits and I disagree. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my benefits by the seventh calendar day after I applied for SNAP. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my Electronic Benefit Transfer (EBT) card by the seventh calendar day after I applied for SNAP.",
   verificationtoDta:"I may receive more SNAP benefits if I report and give verification to DHR of:",
   childorOtherTitile: "child or other dependent care costs, shelter costs, and/or utility costs",
   legallyObligatedlabel:"legally-obligated child support to a nonhousehold member",
   disabledMedicalcost:"If I am 60 years or older or if I am disabled and I pay for medical costs, I can report and give verification of these costs to DHR. This may make me eligible for a deduction and increase my SNAP benefits.",
   meetExemptionlabel: "Unless they meet an exemption, all SNAP recipients between the ages of 16 and 59 are work registered and subject to General SNAP Work Requirements. SNAP recipients between the ages of 18 and 49 may also be subject to the ABAWD Work Program requirements. DHR will inform nonexempt household members of the work requirements. DHR will inform nonexempt household members of exceptions and penalties for noncompliance.",
   voluntarilyParticipatelabel:"Most SNAP recipients may voluntarily participate in education and employment training services through the SNAP Path to Work program. DHR will give referrals to the SNAP Path to Work program if appropriate",
   dtaNamescontact:"DHR may also share the names and contact information of SNAP recipients with SNAP Path to Work providers for recruitment purposes. I understand that members of my household may be contacted by DHR SNAP Path to Work specialists or contracted providers to explore SNAP Path to Work participation options. For more information about the SNAP Path to Work program, visit ",
   subjectToverification: "I understand that the information I give with my application will be subject to verification to determine if it is true. If any information is false, DHR may deny my SNAP benefits. I may also be subject to criminal prosecution for providing false information.",
   signingApplication:"I understand that by signing this application I give DHR permission to verify and investigate the information I give that relates to my eligibility for SNAP benefits, including permission to:",
   proveInformation:"Get documents to prove information on this application with other state agencies, federal agencies, local housing authorities, out-of-state welfare departments, financial institutions and from Equifax Workforce Solutions. I also give permission to these agencies to give DHR information about my household that concerns my SNAP benefits.",
   immigrationStatusLabel:"If applicable, verify my immigration status through the United States Citizenship and Immigration Services (USCIS). I understand that DHR may check information from my SNAP application with USCIS. Any information received from USCIS may affect my household’s eligibility and amount of SNAP benefits. ",
   secondaryEducation:"Share information about me and my dependents under age 19 with the Department of Elementary and Secondary Education (DESE). DESE will certify my dependents for school breakfast and lunch programs. ",
   publicHealth:"Share information about me, my dependents under age 5 and anyone pregnant in my household with the Department of Public Health (DPH). DPH refers these individuals to the Women,  Infants and Children (WIC) Program for nutrition services.",
   executiveOfficeHealth:"Share information, along with the Alabama Executive Office of Health and Human Services, about my eligibility for SNAP with electric companies, gas companies and eligible phone and cable carriers to certify my eligibility for discount utility rates.",
   housingCommuntiy:"Share my information with the Department of Housing and Community Development (DHCD) for the purpose of enrolling me in the Heat & Eat Program. ",
   lowerBenefitslabel:"DHR may deny, stop or lower my benefits based on information from Equifax Workforce Solutions. I have the right to a free copy of my report from Equifax if I request it within 60 days of DHR’s decision. I have the right to question the accuracy or completeness of the information in my report. I may contact Equifax at: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
   tollFreeLabel:"",
   rightoKnowbrochure:"I understand that I will get a copy of the “Your Right to Know” brochure and the SNAP Program brochure. I will read or have read to me the brochures and I must understand their contents and my rights and responsibilities. If I have any questions about the brochures or any of this information, I will contact DHR. If I have trouble reading or understanding any of this information, I will contact DHR. DHR can be reached at:",
   lawfullyResiding:"I swear that all members of my SNAP household requesting SNAP benefits are either U.S. citizens or lawfully residing noncitizens.",
   registerVoteTitle: "Right to Register to Vote",
   registerVoteFirstLabel:"I understand I have the right to register to vote at DHR. I understand that DHR will help me fill out the voter registration application form if I want help. I am allowed to fill out the voter registration application form in private.",
   registerVoteSecondLabel:"I understand that applying to register or declining to register to vote will not affect the amount of benefits I get from DHR. ",
   penaltyWarningTitle:"SNAP Penalty Warning",
   penaltyWarningContent:"I understand that if I or any member of my SNAP household intentionally breaks any of the rules listed below, that person will not be eligible for SNAP for one year after the first violation, two years after the second violation and forever after the third violation. That person may also be fined up to $250,000, imprisoned up to 20 years or both. S/he may also be subject to prosecution under other applicable Federal and State laws. These rules are:",
   penaltyWarningOne:"Do not give false information or hide information to get SNAP benefits.",
   penaltyWarningTwo:"Do not trade or sell SNAP benefits.",
   penaltyWarningThree:"Do not alter EBT cards to get SNAP benefits you are not eligible to get.",
   penaltyWarningFour:"Do not use SNAP benefits to buy ineligible items, such as alcoholic drinks and tobacco.",
   penaltyWarningFive:"Do not use someone else’s SNAP benefits or EBT card, unless you are an authorized representative.",
   
   penaltiesTitle:"I also understand the following penalties: ",
   individualLabel:"Individuals who commit a",
   cashProgram: "cash program",
   internationalProgram:" Intentional Program Violation (IPV) will be ineligible for SNAP for the same period the individual is ineligible from cash assistance.",
   penalitiesTwo:"Individuals who make a fraudulent statement about their identity or residency to get multiple SNAP benefits at the same time will be ineligible for SNAP for  ",
   tenYearLabel:"ten years",
   penalitiesThree:"Individuals who trade (buy or sell) SNAP benefits for a controlled substance/illegal drug(s), will be ineligible for SNAP for ",
   twoYearLabel:"two years " ,
   penalitiesThreeMiddle:"for the first finding, and",
   foreverText:"forever ",
   penalitiesThreeLast:"for the second finding.",
   penalitiesFour:"Individuals who trade (buy or sell) SNAP benefits for firearms, ammunition or explosives, will be ineligible for SNAP",
   penalitiesFive:"Individuals who trade (buy or sell) SNAP benefits having a value of $500 or more, will be ineligible for SNAP",
   penalitiesSix:"The State may pursue an IPV against an individual who makes an offer to sell SNAP benefits or an EBT card online or in person",
   penalitiesSeven:"Individuals who are fleeing to avoid prosecution, custody or confinement after conviction for a felony, or are violating probation or parole, are ineligible for SNAP.",
   penalitiesEight:"Paying for food purchased on credit is not allowed and can result in disqualification from SNAP.",
   penaltiesNine:"Individuals may not buy products with SNAP benefits with the intent to discard the contents and return containers for cash.",

   interpreterHeader:"Right to an Interpreter",
   interpreterContent:"I understand that I have a right to an interpreter provided by DHR if no adult in my SNAP household is able to speak or understand English. I also understand that I can get an interpreter for any DHR fair hearing or bring one of my own. If I need an interpreter for a hearing, I must call the Division of Hearings at least one week before the hearing date.",
   nonDiscriminationHeader: "Nondiscrimination Statement",
   nonDiscriminationContent:"In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, sex, religious creed, disability, age, political beliefs, or reprisal or retaliation for prior civil rights activity in any program or activity conducted or funded by USDA.",
   personDisabilities:"Persons with disabilities who require alternative means of communication for program information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should contact the Agency (State or local) where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at ",
   programInformation:"Additionally, program information may be made available in languages other than English.,",
   
   programComplaint:"To file a program complaint of discrimination, complete the USDA Program Discrimination Complaint Form, (AD-3027) found online at: ",
   usdaOffice:"and at any USDA office, or write a letter addressed to USDA and provide in the letter all of the information requested in the form. To request a copy of the complaint form, call ",
   sumbitYourLabel:"Submit your completed form or letter to USDA by:",

  mailAddress:"mail: U.S. Department of Agriculture",
  addressOne:" Office of the Assistant Secretary for Civil Rights",
  addressVenue:" 1400 Independence Avenue, SW",
  addressState:"Washington, D.C. 20250-9410",
  fax:" fax:",
  orLabel:" or",
  eamilTitle:"email: ",
  thisInstution:"This institution is an equal opportunity provider."


  }

    case languageConsts.SPANISH:
    return {
      termsHeader:"Derechos, responsabilidades y multas",
      headerLabel: "Aviso de Derechos, responsabilidades y multas – Por favor, lea atentamente",
      certifyContents:"Certifico que he leído, o me han leído, la información de esta solicitud. Mis respuestas a las preguntas en esta solicitud son verdaderas y completas a mi mejor saber y entender. También certifico que la información que proporcione al Departamento durante la entrevista de solicitud y en el futuro también será verdadera y completa a mi mejor saber y entender. Entiendo que dar información falsa o engañosa es fraude. También entiendo que alterar o retener datos para establecer elegibilidad para SNAP es fraude. Esto lleva a una Violación Intencional del Programa (IPV) y es sancionable con multas civiles y criminales.",
      understandDta:"Entiendo que el Departamento de Asistencia Transicional (DHR) administra SNAP. Entiendo que el DHR tiene 30 días desde la fecha de la solicitud para procesar mi solicitud. Más aún, entiendo que:",
      foodNutritionact:"La Ley de Alimentos y Nutrición del 2008 (7 U.S.C. 2011-2036) permite que el DHR use mi Número de Seguro Social (SSN) y el SSN de cada miembro del hogar para quien presente la solicitud. El DHR usa esta información para determinar la elegibilidad de mi hogar para SNAP. El DHR verifica esta información por medio de programas informáticos de comparación de datos. Entiendo que el DHR los usa para monitorear el cumplimiento de los reglamentos del programa.",
      reportingRulesDtachanges:"La mayor parte del tiempo, los hogares bajo las reglas de Informe Simplificado de SNAP deben informar al DHR sobre cambios en el Informe Provisional (IR) y la recertificación con excepción de:",
      incomeExceeds:"Si los ingresos de mi hogar superan el umbral de ingresos brutos",
      workRequirementlabel:" Si yo estoy bajo los requisitos de trabajo de adultos sanos sin dependientes a cargo (ABAWD) y mis horas de trabajo disminuyeran por debajo de 20 horas por semana",
      verifiedInfomation:"Si el DHR recibe información verificada sobre mi hogar, la cantidad de mi beneficio podría cambiar",
      tbaRulesone: " Si yo no estuviera bajo las reglas del Informe Simplificado de SNAP o de la Alternativa de Beneficios Transicionales (TBA), debo informar al DHR sobre cambios en mi hogar que puedan afectar nuestra elegibilidad. Entiendo que debo informar sobre estos cambios al DHR en persona, por escrito o por teléfono ",
      tbaRuletwo:" dentro de los 10 días del cambio.",
      tbaExample:" Por ejemplo, usted debe informar sobre cambios en los ingresos, tamaño o dirección de su hogar.",
      findmeIneligible: "Tengo derecho a hablar con un supervisor si el DHR no me considerara elegible para beneficios de emergencia de SNAP y yo estuviera en desacuerdo. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mis beneficios al séptimo día calendario después de haber presentado la solicitud para SNAP. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mi tarjeta de Transferencia Electrónica de Beneficios (EBT) al séptimo día calendario después de haber presentado la solicitud para SNAP.",
      verificationtoDta:"Podría recibir más beneficios de SNAP si yo informara y presentara verificación al DHR de:",
      childorOtherTitile: "costos para niños o de otros servicios de atención de dependientes, costos de albergue, y/o costos de servicios públicos",
      legallyObligatedlabel:"Manutención infantil de obligación legal para un miembro que no esté en el hogar.",
      disabledMedicalcost:"Si tuviera 60 años de edad o más o si estuviera discapacitado y pagara por costos médicos, puedo informar y presentar verificación de estos costos al DHR. Esto podría hacerme elegible para una deducción y aumentar mis beneficios de SNAP.",
      meetExemptionlabel:"A menos que cumplan con una exención, todos los beneficiarios de SNAP entre los 16 y 59 años de edad están registrados para trabajar y sujetos a los Requisitos Generales de Trabajo de SNAP. Los beneficiarios de SNAP entre los 18 y 49 años de edad también podrían estar sujetos a los requisitos del Programa de Trabajo de ABAWD. El DHR le informará a los miembros no exentos del hogar sobre los requisitos de trabajo. El DHR le informará a los miembros no exentos del hogar sobre las excepciones y multas por incumplimiento.",
      voluntarilyParticipatelabel:"La mayoría de los beneficiarios de SNAP pueden participar voluntariamente en servicios de capacitación para el empleo y educación por medio del programa Camino al Trabajo de SNAP. El DHR entregará remisiones para el programa Camino al Trabajo de SNAP si correspondiera.",
      dtaNamescontact:"El DHR también podría compartir los nombres e información de contacto de los beneficiarios de SNAP con los proveedores de Camino al Trabajo de SNAP para propósitos de contratación. Entiendo que los miembros de mi hogar podrían ser contactados por especialistas de Camino al Trabajo de SNAP del DHR o por proveedores contratados para explorar las opciones de participación en Camino al Trabajo de SNAP. Para obtener más información sobre el programa Camino al Trabajo de SNAP, visite ",
      subjectToverification:"Entiendo que la información que entregue con mi solicitud estará sujeta a verificación para determinar si es verdadera. Si cualquier información fuera falsa, el DHR podría negarme mis beneficios de SNAP. También yo podría estar sujeto a juicio por proporcionar información falsa.",
      signingApplication:"Entiendo que al firmar esta solicitud le doy permiso al DHR para verificar e investigar la información que proporcione relacionada con mi elegibilidad para beneficios de SNAP, incluyendo permiso para:",
      proveInformation:"Obtener documentos para probar información en esta solicitud con otras agencias estatales y federales, autoridades locales de vivienda, departamentos de bienestar fuera del estado, instituciones financieras y de Equifax Workforce Solutions. También doy permiso a estas agencias para que le den información sobre mi hogar al DHR concerniente a mis beneficios de SNAP.",
      immigrationStatusLabel:"Si correspondiera, verificar mi estado inmigratorio por medio de los Servicios de Ciudadanía e Inmigración de Estados Unidos (USCIS). Entiendo que el DHR podría verificar la información de mi solicitud para SNAP con USCIS. Cualquier información recibida de USCIS podría afectar la elegibilidad de mi hogar y la cantidad de los beneficios de SNAP.",
      secondaryEducation:"Compartir información sobre mí y mis dependientes menores de 19 años con el Departamento de Educación Primaria y Secundaria (DESE). El DESE certificará a mis dependientes para programas escolares de desayuno y almuerzo.",
      publicHealth:"Compartir información sobre mí, mis dependientes menores de 5 años y cualquier persona embarazada en mi hogar con el Departamento de Salud Pública (DPH). El DPH remite a estas personas para servicios de nutrición al Programa para Mujeres, Infantes y Niños (WIC).",
      executiveOfficeHealth:"Compartir información, junto con la Oficina Ejecutiva de Salud y Servicios Humanos de Alabama, sobre mi elegibilidad para SNAP con compañías de electricidad, gas y proveedores de teléfono y cable elegibles para certificar mi elegibilidad para descuentos en las tarifas de servicios públicos.",
      housingCommuntiy:"Compartir mi información con el Departamento de Vivienda y Desarrollo Comunitario (DHCD) para el fin de inscribirme en el programa de Calefacción y Alimentación.",
      lowerBenefitslabel:"El DHR podría negar, suspender o disminuir mis beneficios basado en la información de Equifax Workforce Solutions. Tengo derecho a una copia gratuita de mi informe de Equifax si lo solicitara dentro de los 60 días de la decisión del DHR. Tengo derecho a cuestionar la precisión o integridad de la información en mi informe. Podría comunicarme con Equifax a: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
      tollFreeLabel: "(línea gratuita).",
      rightoKnowbrochure:"Entiendo que obtendré una copia del folleto “Su derecho a saber” y del folleto del programa de SNAP. Leeré o me leerán los folletos, debiendo entender su contenido y mis derechos y responsabilidades. Si tuviera preguntas sobre los folletos o cualquier parte de esta información, me comunicaré con el DHR. Si tuviera dificultad para leer o entender cualquier parte de esta información, me comunicaré con el DHR. Puede comunicarse con el DHR al: ",
      lawfullyResiding:"Juro que todos los miembros de mi hogar SNAP que solicitan beneficios de SNAP son ciudadanos de EE. UU. o no ciudadanos con residencia legal.",
      registerVoteTitle: "Derecho a inscribirse para votar",
      registerVoteFirstLabel:"Entiendo que tengo derecho a inscribirme para votar en el DHR. Entiendo que el DHR me ayudará a completar el Formulario de Inscripción de Votante si deseara ayuda. Tengo derecho a completar el Formulario de Inscripción de Votante en privado.",
      registerVoteSecondLabel:"Entiendo que solicitar o rechazar la inscripción para votar no afectará la cantidad de beneficios que obtenga del DHR. ",
      penaltyWarningTitle:"Advertencia de multa de SNAP",
      penaltyWarningContent:"Entiendo que, si yo o cualquier miembro de mi hogar SNAP intencionalmente no cumpliera cualquiera de las reglas indicadas a continuación, esa persona no será elegible para SNAP durante un año después de la primera infracción, dos años después de la segunda infracción y para siempre después de la tercera infracción. Esa persona también puede ser multada hasta con $250,000, ir a prisión hasta 20 años o ambas. También puede estar sujeta a juicio bajo otras leyes federales y estatales aplicables. Estas reglas son:",
      penaltyWarningOne:"No proporcionar información falsa u ocultar información para obtener beneficios de SNAP.",
      penaltyWarningTwo:"No intercambiar o vender beneficios de SNAP.",
      penaltyWarningThree:"No alterar las tarjetas EBT para obtener beneficios de SNAP para los cuales usted no es elegible.",
      penaltyWarningFour:"No usar los beneficios de SNAP para comprar artículos inelegibles, como bebidas alcohólicas y tabaco.",
      penaltyWarningFive:"No usar los beneficios de SNAP o la tarjeta EBT de otra persona, a menos que sea un Representante autorizado.",


      penaltiesTitle:"También entiendo las siguientes multas: ",
      individualLabel:"Las personas que cometan una Infracción Intencional del Programa (IPV)",
      cashProgram: "de efectivo ",
      internationalProgram:" no serán elegibles para SNAP durante el mismo período en que la persona no sea elegible para la asistencia con efectivo.",
      penalitiesTwo:"Las personas que hagan una declaración fraudulenta sobre su identidad o residencia para obtener múltiples beneficios de SNAP en el mismo período no serán elegibles para SNAP durante   ",
      tenYearLabel:"diez años",
      penalitiesThree:"Las personas que intercambien (compren o vendan) beneficios de SNAP por drogas ilegales/sustancias controladas, no serán elegibles para SNAP durante dos años para la primera falta y para siempre para la segunda falta",
      twoYearLabel:"dos años " ,
      penalitiesThreeMiddle:"para la primera falta ",
      foreverText:"para siempre ",
      penalitiesThreeLast:"para la segunda falta.",
      penalitiesFour:"Las personas que intercambien (compren o vendan) beneficios de SNAP por armas de fuego, municiones o explosivos, no serán elegibles para SNAP",
      penalitiesFive:"Las personas que intercambien (compren o vendan) beneficios de SNAP que tengan un valor de $500 o más, no serán elegibles para SNAP",
      penalitiesSix:"El estado podría enjuiciar por una IPV a una persona que haga una oferta para vender beneficios de SNAP o una tarjeta EBT en línea o en persona",
      penalitiesSeven:"Las personas que escapen para evitar el juicio, detención o reclusión después de la convicción por un delito, o que estén violando la libertad bajo palabra, no son elegibles para SNAP.",
      penalitiesEight:"No se permite pagar por alimentos comprados a crédito, y esto pudiera resultar en la descalificación de SNAP.",
      penaltiesNine:"Las personas no pueden comprar productos con beneficios de SNAP con la intención de descartar el contenido y devolver los envases por efectivo.",


      interpreterHeader:"Derecho a un intérprete",
      interpreterContent:"Entiendo que tengo derecho a un intérprete proporcionado por el DHR si ningún adulto en mi hogar SNAP pudiera hablar o entender inglés. También entiendo que puedo obtener un intérprete para cualquier audiencia imparcial del DHR o llevar a un intérprete por mi cuenta. Si necesitara un intérprete para una audiencia, debo llamar a la División de Audiencias al menos una semana antes de la fecha de la audiencia.",
      nonDiscriminationHeader: "Declaración de no discriminación",
      nonDiscriminationContent:"De acuerdo con la ley federal de derechos civiles y los reglamentos y políticas de derechos civiles del Departamento de Agricultura de EE. UU. (USDA), se prohíbe al USDA, sus agencias, oficinas, empleados e instituciones que participen o administren programas de USDA discriminar basado en raza, color, nacionalidad, sexo, credo religioso, discapacidad, edad, creencias políticas, represalia o venganza por actividades de derechos civiles en cualquier programa o actividad realizado o patrocinado por USDA.",
      personDisabilities:"Las personas con discapacidades que requieran medios alternativos de comunicación para obtener información sobre el programa (por ej. braille, letras grandes, cintas de audio, Lenguaje de Señas Americano, etc.), deben comunicarse con la Agencia (estatal o local) donde presentaron la solicitud para beneficios. Las personas sordas, con dificultad auditiva o discapacidades del habla pueden comunicarse con el USDA por medio del Servicio de Retransmisión Federal al ",
      programInformation:"La información sobre el programa también puede ponerse a disposición en otros idiomas además del inglés.,",
      
      programComplaint:"Para presentar una queja de discriminación para el programa, complete el Formulario de Queja por Discriminación del Programa del USDA, (AD-3027) que se encuentra en línea en: ",
      usdaOffice:"y en cualquier oficina del USDA, o escriba una carta dirigida al USDA y proporcione en la carta toda la información solicitada en el formulario. Para solicitar una copia del formulario de quejas, llame al ",
      sumbitYourLabel:"Envíe su carta o formulario completado al USDA por:",
   
     mailAddress:"correo: U.S. Department of Agriculture",
     addressOne:" Office of the Assistant Secretary for Civil Rights",
     addressVenue:" 1400 Independence Avenue, SW",
     addressState:"Washington, D.C. 20250-9410",
     fax:" fax:",
     orLabel:" o por",
     eamilTitle:"correo electrónico: ",
     thisInstution:"Esta institución es un proveedor que ofrece igualdad de oportunidades."     
      
    }
      

      case languageConsts.PORTUGUESE: //portugeesh
      return {
        termsHeader:"Direitos, responsabilidades e penalidades",
        headerLabel: "Notificação de direitos, responsabilidades e penalidades. Leia com atenção!",
        certifyContents:"Certifico de que li, ou de que alguém leu para mim, as informações contidas neste formulário. Atesto que minhas respostas às perguntas deste formulário são verdadeiras e completas, segundo o meu conhecimento. Também certifico de que todas as informações fornecidas por mim ao departamento, tanto durante a entrevista de inscrição bem como em ocasiões futuras, serão verdadeiras e completas, de acordo com o meu conhecimento. Entendo que fornecer informações falsas ou deturpadas constituem fraude. Também compreendo constituir fraude qualquer fornecimento de informações deturpadas ou a omissão de fatos com o objetivo de garantir a qualificação no programa SNAP. Tais atos se enquadram em Violação Intencional do Programa (IPV, Intentional Program Violation), sendo passíveis de punições civis e criminais.",
        understandDta:"Eu entendo que o Departamento de Auxílio Transitório (DHR) administra o SNAP. Eu entendo que o DHR tem um prazo de 30 dias a contar da data de inscrição para processar o meu caso. Entendo ainda que:",
        foodNutritionact:"De acordo com o Food and Nutrition Act de 2008 (7 U.S.C. 2011-2036), o DHR pode utilizar o número do meu seguro social (SSN) e o de qualquer membro da minha família que esteja se inscrevendo neste programa. O DHR utiliza esta informação a fim de determinar a qualificação da minha família ao programa SNAP. Além disso, o DHR verifica tais informações por meio de programas de comparação por computador. Entendo que o DHR faz uso desta prática para monitorar o cumprimento das normas do programa.",
        reportingRulesDtachanges:"Na maioria das vezes, as famílias ou grupos familiares que se encontram sujeitas às regras de fornecimento de Informações Simplificadas do SNAP precisam informar ao DHR sobre quaisquer mudanças que afetem o Relatório Provisório (IR, Interim Report), bem como em casos de re-certificação, com as seguintes exceções:",
        incomeExceeds:"Caso a minha renda familiar ultrapasse o limite de renda bruta",
        workRequirementlabel:"Caso eu me encaixe nos requisitos de trabalho de adulto fisicamente capaz sem dependentes (ABAWD, able-bodied adult without dependents,) e as minhas horas de trabalho forem reduzidas para menos de 20 horas semanais",
        verifiedInfomation:"O valor do meu benefício pode ser alterado, caso o DHR receba informações previamente verificadas sobre a minha família.",
        tbaRulesone:"Caso eu não esteja sujeito às regras de Informações Simplificadas (Simplified Reporting) do SNAP, nem às regras da Alternativa de Benefícios Transitórios (TBA, Transitional Benefits Alternative) é obrigatório que eu informe o DHR sobre quaisquer mudanças em minha família, ou agregado familiar, que altere nossa qualificação. Eu entendo que preciso informar o DHR sobre tais alterações pessoalmente, por escrito ou por telefone ",
        tbaRuletwo:" dentro de um prazo de 10 dias da mudança.",
        tbaExample: " aPor exemplo, é obrigatório informar sobre alterações de renda, tamanho ou endereço da família.",
        findmeIneligible:"Tenho direito a falar com um supervisor, caso o DHR negue a minha qualificação para os benefícios de emergência do SNAP e eu não concorde com esta decisão. Também poderei entrar em contato com um supervisor, caso seja qualificado para os benefícios de emergência do SNAP, mas não tenha recebido nenhum benefício depois de sete dias corridos após a inscrição no programa SNAP. O candidato poderá falar com um supervisor, caso se qualifique para os benefícios, mas não tenha recebido o cartão de Transferência Eletrônica de Benefícios (EBT) depois de sete dias corridos após ter realizado a inscrição.",
        verificationtoDta:"Poderei receber benefícios SNAP adicionais caso eu informe e comprove ao DHR:",
        childorOtherTitile: "possuir custos relativos a cuidados de crianças e outros dependentes, custos de asilo e/ou custos de serviços de utilidade pública",
        legallyObligatedlabel:"estar obrigado legalmente a fornecer pensão alimentícia a um membro que não pertença a minha família",
        disabledMedicalcost:"Se eu tiver 60 anos ou mais, ou no caso de ser portador de deficiência e pagar por despesas médicas, posso informar e enviar comprovação ao DHR dos custos relativos a estas despesas, Talvez isso qualifique o candidato para uma redução ou aumento dos benefícios do SNAP.",
        meetExemptionlabel:"Todos os beneficiários do SNAP com idades de 16 a 59 anos devem possuir registro de trabalho de acordo com os Requisitos Gerais de Trabalho do SNAP (General SNAP Work Requirements), a não ser que eles atendam alguma categoria de isenção. Os beneficiários do SNAP com idades entre 18 e 49 anos podem também estar sujeitos aos requisitos do programa de trabalho ABAWD. O DHR se encarregará de informar os membros da família não-isentos sobre os requisitos de trabalho necessários. O DHR também informará os membros não-isentos da família sobre as exceções e as penalidades caso as exigências solicitadas não forem cumpridas.",
        voluntarilyParticipatelabel:"A maior parte dos beneficiários do SNAP poderá participar voluntariamente em serviços de ensino e formação profissionais por meio do programa Caminho para Trabalhar do SNAP (SNAP Path to Work). O DHR poderá encaminhar beneficiários para o programa SNAP Path to Work, se necessário.",
        dtaNamescontact:"Para fins de recrutamento, o DHR poderá também compartilhar nomes e informações de contato dos beneficiários com os organizadores do SNAP Path to Work. Entendo que os membros da minha família poderão ser contatados por especialistas do programa SNAP Path to Work do DHR, ou pelos fornecedores de serviço contratados para o programa, a fim de analisar opções de participação no programa SNAP Path to Work. Para obter mais informações sobre o programa SNAP Path to Work, visite ",
        subjectToverification:"Entendo que as informações fornecidas com a minha inscrição estão sujeitas à verificação para determinar sua veracidade. Se alguma informação for falsa, o DHR poderá negar-me os benefícios do SNAP. Eu posso estar sujeito a julgamento criminal por fornecimento de informações falsas.",
        signingApplication:"Entendo que ao assinar este formulário eu autorizo a verificação e a investigação pelo DHR dos dados informados relativos à minha qualificação para os benefícios do SNAP, incluindo permissão para:",
        proveInformation:"Obter documentos que comprovem a informação deste formulário com outras agências estaduais e federais, autoridades locais de moradia, departamentos de assistência social de outros estados, instituições financeiras e da Equifax Worforce Solutions. Também autorizo que estas agências forneçam ao DHR dados sobre minha família referentes aos meus benefícios do SNAP.",
        immigrationStatusLabel:"Se for o caso, verificar minha situação de imigração junto aos Serviços de Imigração e Cidadania dos Estados Unidos (USCIS, United States Citizenship and Immigration Services). Entendo que o DHR poderá verificar dados da minha inscrição no SNAP com o USCIS. Qualquer informação recebida do USCIS poderá afetar a qualificação da minha família, bem como o valor dos benefícios do SNAP.",
        secondaryEducation:"Compartilhar meus dados pessoais e de meus dependentes menores de 19 anos de idade com o Departamento de Ensino Fundamental e Médio (DESE, Department of Elementary and Secondary Education). O DESE irá aprovar meus dependentes para os programas de alimentação escolar (café da manhã e almoço).",
        publicHealth:"Compartilhar meus dados pessoais, de meus dependentes menores de cinco anos de idade e de qualquer gestante em minha família com o Departamento de Saúde Pública (DPH, Department of Public Health). O DPH encaminha estes indivíduos para o Programa para Mulheres, Bebês e Crianças (WIC, Women, Infants and Children Program) para auxílio nutricional.",
        executiveOfficeHealth:"Compartilhar informações, junto ao Escritório Executivo de Serviços Humanos e Saúde de Alabama (Alabama Executive Office of Health and Human Services), sobre minha qualificação no programa SNAP, com empresas fornecedoras de eletricidade, gás e de serviços de telefonia e TV a cabo qualificadas para certificar-se da minha qualificação para receber descontos nas taxas de serviços de utilidade pública.",
        housingCommuntiy:"Compartilhar meus dados com o Departamento de Desenvolvimento de Habitação e Comunidade (DHCD, Department of Housing and Community Development) com o objetivo de inscrever-me no Programa Aquecer e Alimentar (Heat & Eat Program).",
        lowerBenefitslabel:"DHR poderá negar, interromper ou diminuir meus benefícios de acordo com as informações recebidas do Equifax Workforce Solutions. Tenho o direito de receber uma cópia gratuita do meu relatório do Equifax caso eu solicite o mesmo dentro do prazo de 60 dias a partir da decisão do DHR. Tenho o direito de questionar se as informações no meu relatório estão precisas ou completas. Eu posso entrar em contato com a Equifax em: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
        tollFreeLabel:"(toll free).",
        rightoKnowbrochure:"Entendo que irei receber uma cópia dos folhetos “Seu Direito de Saber” ('Your Right to Know') e do SNAP. Confirmo que vou ler ou já li os folhetos e preciso entender o conteúdo dos mesmos, bem como meus direitos e responsabilidades. Caso tenha qualquer pergunta sobre estes folhetos ou sobre quaisquer destas informações, entrarei em contato com o DHR. Caso eu tenha dificuldade de ler ou compreender quaisquer informações, entrarei em contato com o DHR. O DHR pode ser contactado pelo número:",
        lawfullyResiding:"Eu asseguro que todos os membros da minha família solicitantes dos benefícios do SNAP são cidadãos dos Estados Unidos ou não-cidadãos residentes legais.",
        registerVoteTitle: "Direito de registro eleitoral",
        registerVoteFirstLabel:"Entendo que no DHR tenho o direito de registrar-me para votar. Entendo que o DHR vai me ajudar no preenchimento do formulário de inscrição eleitoral caso necessário. Posso optar por preencher o formulário de registro eleitoral em particular.",
        registerVoteSecondLabel:"Entendo que o ato de inscrição ou desistência ao registro eleitoral não afetará o valor dos benefícios que eu receber do DHR. ",
        penaltyWarningTitle:"Aviso de penalidade do SNAP",
        penaltyWarningContent:"Entendo que se eu mesmo, ou quaisquer membros da minha família beneficiários do SNAP, não cumprirmos qualquer uma das regras listadas abaixo, perderemos a qualificação ao SNAP por um ano após a primeira infração, dois anos após a segunda infração e permanentemente após a terceira infração. Este indivíduo poderá ser multado em até US$ 250.000,00 (duzentos e cinquenta mil dólares), ficar preso por até vinte anos ou ambos. O beneficiário também poderá ser processado sob outras leis federais e estaduais aplicáveis. As regras a serem seguidas são:",
        penaltyWarningOne:"Não ocultar ou fornecer informações falsas com o objetivo de receber benefícios do SNAP.",
        penaltyWarningTwo:"Não negociar ou vender benefícios do SNAP.",
        penaltyWarningThree:"Não falsificar cartões de EBT ou receber benefícios do SNAP para os quais você não se qualifica.",
        penaltyWarningFour:"Não usar os benefícios do SNAP para comprar itens ilegais como bebidas alcoólicas, cigarros e outros produtos de tabaco.",
        penaltyWarningFive:"Não usar os benefícios do SNAP ou cartões de EBT de terceiros, a não ser que você seja um representante autorizado.",

        penaltiesTitle:"Também entendo as penalidades a seguir:",
        individualLabel:"Os beneficiários, que porventura cometam uma Violação Intencional do Programa (IPV, Intentional Program Violation) relativa ao ",
        cashProgram: "recebimento em dinheiro",
        internationalProgram:", ficarão desqualificados do SNAP. Durante esse mesmo período eles não poderão receber o auxílio em dinheiro.",
        penalitiesTwo:"O beneficiário que fizer uma declaração fraudulenta sobre sua identidade ou residência visando receber diversos benefícios do SNAP ficará desqualificado do programa por um prazo de ",
        tenYearLabel:"dez anos.",
        penalitiesThree:"Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de substância(s)/droga(s) ilegal(is) ficarão desqualificados por ",
        twoYearLabel:"dois anos " ,
        penalitiesThreeMiddle:"para a primeira violação, e",
        foreverText:"permanentemente ",
        penalitiesThreeLast:"para a segunda.",
        penalitiesFour:"Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de armas de fogo, munição ou explosivos ficarão permanentemente desqualificados do SNAP",
        penalitiesFive:"Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP no valor de US$ 500,00 (quinhentos dólares) ou mais ficarão permanentemente desqualificados do SNAP.",
        penalitiesSix:"O Estado poderá prosseguir com uma IPV contra o beneficiário que fizer uma oferta para vender on-line ou pessoalmente os benefícios do SNAP ou os cartões de EBT.",
        penalitiesSeven:"Indivíduos que estiverem fugindo para evitar processo, custódia ou prisão após condenação por crime ou que estejam violando a liberdade condicional ou em liberdade provisória não se qualificam para o programa SNAP.",
        penalitiesEight:"Não é permitido pagar por alimento comprado a crédito e isso poderá resultar em desqualificação pelo SNAP.",
        penaltiesNine:"Os beneficiários não poderão comprar produtos com os benefícios do SNAP com a intenção de descartar seus conteúdos e retornar as embalagens em troca de dinheiro.",
        

        interpreterHeader:"Direito a um intérprete",
        interpreterContent:"Entendo ter o direito de solicitar um intérprete fornecido pelo DHR, caso nenhum membro adulto pertencente ao meu agregado familiar do SNAP falar ou entender inglês. Também entendo poder solicitar um intérprete para qualquer sessão de audiência com o DHR, ou trazer um intérprete de minha confiança. Caso eu precise de um intérprete para uma audiência, preciso ligar para a Division of Hearings, com pelo menos uma semana de antecedência da data da minha audiência.",
        nonDiscriminationHeader: "Declaração de não-discriminação",
        nonDiscriminationContent:"De acordo com as leis federais de direitos civis e os regulamentos e as políticas de direitos civis do Departamento de Agricultura dos EUA (USDA, U.S. Department of Agriculture), o USDA, suas agências, escritórios e empregados, assim como as instituições que participam ou administram programas do USDA são proibidas de fazer qualquer tipo de discriminação com base em raça, cor, nacionalidade de origem, sexo, credo religioso, deficiência, idade, convicções políticas. Também são proibidas em todos os programas ou atividades conduzidos ou custeados pelo USDA qualquer tipo de represálias ou retaliações relativas a atividades de direitos civis anteriores.",
        personDisabilities:"Os indivíduos portadores de deficiências que precisam de formas alternativas de comunicação para obter informações sobre o programa (por exemplo, braile, publicações com letras maiores, fitas de áudio, linguagem americana de sinais etc.), devem entrar em contato com a agência (estadual ou local) onde foi feita a inscrição para os benefícios. Pessoas deficiente auditivas, com dificuldades de audição ou que possuem distúrbios da fala devem entrar em contato com o USDA por meio do Federal Relay Service no telefone  ",
        programInformation:"Adicionalmente, as informações do programa podem estar disponíveis em outros idiomas, que não somente o inglês.",
        
        programComplaint:"Para fazer uma reclamação ou relatar um caso de discriminação, preencha o formulário AD-3027 (USDA Program Discrimination Complaint Form), disponível on-line no site: ",
        usdaOffice:"e em qualquer escritório do USDA. Você pode também enviar uma carta para o USDA, fornecendo todas as informações solicitadas no formulário. Para solicitar uma cópia do formulário de reclamação, ligue para ",
        sumbitYourLabel:"Envie o formulário preenchido ou a carta para o USDA por:",
  
       mailAddress:"Correio: U.S. Department of Agriculture",
       addressOne:" Office of the Assistant Secretary for Civil Rights",
       addressVenue:" 1400 Independence Avenue, SW",
       addressState:"Washington, D.C. 20250-9410",
       fax:" fax:",
       orLabel:" ou",
       eamilTitle:"Email: ",
       thisInstution:"Esta instituição oferece igualdade de oportunidades."
        
   
      }

        case languageConsts.CHINESE: //China
        return {
          termsHeader:"《权利、责任和处罚》",
          headerLabel: "权利、责任和处罚通知 — 请细心阅读",
          certifyContents:"我确认，我已经阅读或有人向我读出本申请表中的资讯。据我所知所信，我对本申请表中问题的回答是真实和完整的。我还确认，据我所知所信，我在申请面谈期间向过渡援助部提供的资料是真实和完整的，将来提供的资料也会真实和完整。我理解，提供虚假或误导性信息是欺诈行为。我还理解，为了符合 SNAP 资格而歪曲或隐瞒事实是欺诈行为。这等行为将导致蓄意违反计划行为（IPV），本人亦因此受到民事和刑事处罚。",
          understandDta:"我理解，过渡援助部（DHR）负责管理 SNAP。我理解，DHR 将在申请日期开始的 30 天内处理我的申请。此外，我理解：",
          foodNutritionact:"《2008 年食品与营养法案》（7 U.S.C. 2011-2036）允许 DHR 使用我的社会安全号码（SSN）以及我为其提交申请的每一名家庭成员的 SSN。DTA会 使用此类资料确定我的家庭是否有获取 SNAP 的资格。DHR 通过电脑匹配程序确认此类资料的准确性。我理解， DHR 会利用此类资讯监管申请是否符合计划规章。",
          reportingRulesDtachanges:"在大多数情况下，符合 SNAP 简化报告规定的家庭必须在临时报告（IR）中将所有变化通知 DHR，并重新认证，但以下情况例外：",
          incomeExceeds:"我的家庭收入超过总收入限额",
          workRequirementlabel:"我受「无家属健全成人」（ABAWD）的工作要求制约，而我的工作时数降低到每周 20 小时以下",
          verifiedInfomation:"如果 DHR 收到已确认的有关我家庭的资讯，我的福利数额可能会有改变。",
          tbaRulesone:"如果我没有受制於SNAP 简化报告规定或过渡福利替代（TBA）的规定，则我必须将有可能影响我们获得批准的资格之家庭变化向DTA 报告。我理解我必须 ",
          tbaRuletwo: "在变更发生后 10 天内",
          tbaExample:" 亲自到办事处、写信或打电话向 DHR 报告这些变更。例如，您必须报告您的家庭的收入、人口或地址的改变。",
          findmeIneligible:" 如果 DTA认为我没有资格享受紧急 SNAP 福利，而我不同意他们的决定，我有权向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未有收到我的福利，我可以向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未收到我的电子福利转账（EBT）卡，我可以向直属主管反映。",
          verificationtoDta:"如果我向 DHR 报告以下情况并提供证明，我可能享受更多的 SNAP 福利：",
          childorOtherTitile: "子女或其他家属护理费用、庇护所费用、及水、电、煤气、电话等费用",
          legallyObligatedlabel:"付给非家庭成员法律规定支付的子女赡养费",
          disabledMedicalcost:"如果我年满 60 岁，又或者我是残障人仕并自付医疗费用，我可以向 DHR 申报这些费用，并提供证明。这可能会使我有资格获得税收减免，并增加我的 SNAP 福利。",
          meetExemptionlabel:"•	除非符合豁免条件，否则所有年龄在 16 岁至 59 岁之间的 SNAP 受惠者均须进行工作注册，且须符合SNAP的普通工作要求 (General SNAP Work Requirement)。年龄在 18 岁至 49 岁之间的 SNAP 受惠者亦可能须要符合 ABAWD 工作计划要求。DHR 将会通知非豁免的家庭成员有关工作要求的事宜。DHR 亦通知非豁免的家庭成员有关例外情况和不遵守此项规定的处罚。",
          voluntarilyParticipatelabel:"大多数 SNAP 受惠者会自愿通过 SNAP 工作之路（SNAP Path to Work）计划参与教育和就业培训。DHR 将在有需要时提供 SNAP 工作之路计划的转介服务。",
          dtaNamescontact:"DHR 还可能与 SNAP 工作之路提供服务者分享 SNAP 受惠者的姓名和联系资料，以作招募之用。我理解，DHR SNAP 工作之路计划专家或特约提供服务者可能与我的家庭成员联系，讨论参加 SNAP 工作之路计划的各种选择。如欲得到更多有关 SNAP 工作之路计划的详情，请查阅网站 ",
          subjectToverification:"我理解，我在申请表中提供的资料必须接受验证，以确定其真实性。如果有任何虚假资讯，DHR 有可能拒绝提供我的 SNAP 福利。我还可能因提供虚假资讯而受到刑事起诉。",
          signingApplication:"我理解，我在本申请表中的签名表示我允许 DHR 确认和调查我所提供的、与我获得 SNAP 福利资格相关的资料，包括允许他们：",
          proveInformation:"向其他州立机构、联邦机构、地方住宅管理机构、外州福利部门、金融机构和 Equifax Workforce Solutions 索取文件，以确定本申请表中的资讯。我还允许这些机构向 DHR 提供与我的 SNAP 福利相关的、有关我的家庭的资料。",
          immigrationStatusLabel:" 如果适用的话，通过美国公民与移民服务部（USCIS）确认我的移民身份。我理解，DHR 可能与 USCIS 核对我的 SNAP 申请表中的资料。从 USCIS 收到的任何资讯都可能影响我的家庭获 SNAP 福利的资格和福利数额。",  
          secondaryEducation:" 与小学和中学教育部（DESE）分享有关我和我的 19 岁以下的家属的资料。DESE 将证明我的家属可参加学校早餐和午餐计划",
          publicHealth:"与公共卫生部（DPH）分享有关我、我的 5 岁以下的家属和我的家中任何孕妇的资料。DPH 会将这些人转介给妇女、婴儿和儿童（WIC）计划，接受营养服务。",
          executiveOfficeHealth:"与马萨诸塞州健康与公众服务执行办公室合作，与电力公司、煤气公司和合格的电话和有线电视运营商分享有关我参加 SNAP 资格的资讯，以确认我获得水、电、煤气、电话等优惠费率的资格。",
          housingCommuntiy:"与住宅与社区开发部（DHCD）分享有关我的资料，从而使我得以注册参加取暖与食品计划（Heat & Eat Program）。",
          lowerBenefitslabel:"DHR 可能根据从 Equifax Workforce Solutions 获得的讯息拒绝、终止或减低我的福利。如果我在 DHR 作出决定后 60 天内提出请求，我有权免费获得一份我的 Equifax 报告。我有权质疑我的报告中的资料的准确性或完整性。我可以与 Equifax 联系，地址：Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146；免费电话：",
          tollFreeLabel:" ",
          rightoKnowbrochure:"我理解，我会收到一份《您的知情权利》（Your Right to Know）手册和《补充营养援助计划》（SNAP Program）手册。我将阅读或请人向我读出这两份手册的内容，我必须理解手册的内容以及我的权利和责任。如果我对手册或手册中的任何资讯有任何疑问，我将与 DHR 联系。如果我在阅读或理解任何此类资讯时遇到困难，我将与 DHR 联系。DHR 的电话号码：",
          lawfullyResiding:"我宣誓，所有与我一同申请 SNAP 福利的 SNAP 家庭成员都是美国公民或合法居住非公民。",
          registerVoteTitle: "登记投票的权利",
          registerVoteFirstLabel:"我理解，我有权在 DHR 登记投票。我理解，如果我需要帮助，DHR 将帮助我填写选民登记申请表。我亦可以私下填写选民登记申请表。",
          registerVoteSecondLabel:"我理解，申请登记投票或拒绝登记投票不会影响我从 DHR 获得的福利数额。 ",
          penaltyWarningTitle:"SNAP 处罚警告",
          penaltyWarningContent:"我理解，如果我或我的任何SNAP 家庭的成员有意违反下列任何规定，该人仕将在首次违规后一年内没有资格享用SNAP；在第二次违规后两年内没有资格享用 SNAP；在第三次违规后将永远没有资格享用 SNAP。该人仕亦可能被处以高达 $250,000 的罚款、高达 20 年的监禁或二者并罚。根据其他相关联邦法律和州法律的，该人仕还可能被起诉。此类规定是：",
          penaltyWarningOne:"不得为了获得 SNAP 福利提供虚假资料或隐瞒资讯。",
          penaltyWarningTwo:"不得买卖或出售 SNAP 福利。",
          penaltyWarningThree:"不得更改 EBT 卡，以便获取您没有资格享用的 SNAP 福利。",
          penaltyWarningFour:"不得使用 SNAP 福利购买不合格的物品，例如酒精饮料和烟草产品。",
          penaltyWarningFive:"	除非您是经授权代表，否则不得使用他人的 SNAP 福利或 EBT 卡。",

          penaltiesTitle:"我还理解以下处罚 ",
          individualLabel:"有蓄意违反",
          cashProgram: "现金计划",
          internationalProgram:" 	行为（IPV）者，在其失去获得现金援助资格的同时，亦会被取消获得SNAP 福利的资格。",
          penalitiesTwo:"•	为了同时获得多项 SNAP 福利而以自己的身份或居住地作出欺骗性陈述者将在  ",
          tenYearLabel:"十年",
          penalitiesThree:"意图获得受控物质/毒品而买卖 SNAP 福利者，首次发现将在两年内被取消获得SNAP 福利的资格，第二次发现将永远被取消获得 SNAP 福利的资格。 ",
          twoYearLabel:"两年 " ,
          penalitiesThreeMiddle:"",
          foreverText:"永远 ",
          penalitiesThreeLast:"第二次发现将永远被取消获得  SNAP 福利的资格。",
          penalitiesFour:"意图获取武器、弹药或爆炸品而买卖 SNAP 福利者，将永远失去获得SNAP 福利的资格。",
          penalitiesFive:"买卖价值达 $500 的 SNAP 福利者，将永远被取消获得 SNAP 福利的资格。",
          penalitiesSix:"州政府有可能向在网上或亲自出售 SNAP 福利或 EBT 卡者 提出蓄意违反计划行为（IPV）的起诉。",
          penalitiesSeven:"避免被起诉而逃走者、已被定罪而逃避羁押或监禁者、及违反缓刑或假释规定者，均没有资格获得 SNAP 福利。",
          penalitiesEight:"严禁用获得的福利以支付购买粮食的信贷，违者有可能被取消 SNAP 资格。",
          penaltiesNine:"严禁以 SNAP 福利购买物品后，意图丢弃物品内装物再退回容器以套取现金。",


            
          interpreterHeader:"接受口译员协助的权利",
          interpreterContent:"我理解，如果我的 SNAP 家庭成员中没有任何成年人会讲或听懂英语，我有权获得 DHR 提供的口译员协助。我还理解，出席任何 DHR 公平听证时，我可以请求口译员协助，或者自带口译员。如果我因为出席听证而需要请求口译员协助，我必须在听证日期至少一周前打电话给听证分部（Division of Hearings）。",
          nonDiscriminationHeader: "不歧视声明",
          nonDiscriminationContent:"依照联邦民权法和美国农业部（USDA）民权法规章和政策的规定，USDA 及其代理机构、办事处和雇员以及参加或管理 USDA 计划的机构不得在 USDA 开展或资助的任何计划或活动中，有任何对种族、肤色、原国籍、性别、宗教信仰、残障、年龄、政治信仰的歧视行动或行为，或因以前的民权活动进行报复。",
          personDisabilities:"要求其他计划信息交流方法（例如，盲文、大印刷体、录音带、美国手语等）的残障人士应当与申请福利的（州或地方）代理机构联系。失聪者、听力或语言障碍者，均可通过联邦电话中转服务与 USDA 联系。电话号码为",
          programInformation:"此外，本计划是以用英语之外的其他语言提供计划资讯的。",
          
          programComplaint:"如需提交计划歧视申诉，请填写 USDA 计划歧视申诉表（AD-3027 表），请在以下网站查找该表格：",
          usdaOffice:"或到任何 USDA 办事处索取；您亦可写信给 USDA 提出申诉，请在信函中提供申诉表中要求的所有资料。如需索取申诉表，请电洽  ",
          sumbitYourLabel:"请通过以下方法向 USDA 提交填妥的申诉表或信函：",

          mailAddress:"邮寄: U.S. Department of Agriculture",
          addressOne:" Office of the Assistant Secretary for Civil Rights",
          addressVenue:" 1400 Independence Avenue, SW",
          addressState:"Washington, D.C. 20250-9410",
          fax:" 传真:",
          orLabel:" 或",
          eamilTitle:"电子邮件: ",
          thisInstution:"本机构是公平机会服务提供者。"
                  
          
        }

          case languageConsts.VIETNAMESE: //Vietnam
          return {
            termsHeader:"Quyền, Trách Nhiệm và Mức Phạt",
            headerLabel: "Thông Báo về Quyền, Trách Nhiệm và Mức Phạt - Vui lòng Đọc Kỹ",
            certifyContents:"Tôi xác nhận rằng tôi đã đọc, hoặc đã được đọc cho nghe, những dữ kiện thông tin trong đơn này. Các câu trả lời của tôi cho các câu hỏi trong đơn xin này là đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi cũng xác nhận rằng các dữ kiện thông tin mà tôi cung cấp cho Sở này trong buổi phỏng vấn xin trợ cấp và trong tương lai cũng sẽ đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi hiểu rằng việc khai man hoặc cung cấp các dữ kiện thông tin không đúng là gian lận. Tôi cũng hiểu rằng việc khai man hoặc che giấu các dữ kiện thông tin để hợp lệ nhận trợ cấp SNAP là gian lận. Việc này sẽ có hậu quả là tội Cố Ý Vi Phạm Chương Trình (IPV) và sẽ bị phạt bởi các hình phạt dân sự và hình sự.",
            understandDta:"Tôi hiểu rằng Sở Trợ Cấp Chuyển Tiếp (DHR) quản lý trợ cấp SNAP. Tôi hiểu rằng DHR có 30 ngày kể từ ngày nộp đơn xin để xử lý đơn xin của tôi. Ngoài ra, tôi cũng hiểu rằng:",
            foodNutritionact:"Đạo Luật Thực Phẩm và Dinh Dưỡng ban hành năm 2008 (7 U.S.C. 2011-2036) cho phép DHR sử dụng Số An Sinh Xã Hội (SSN) của tôi và SSN của mỗi thành viên hộ gia đình được tôi nộp đơn cho. DHR sử dụng thông tin này để xác định sự hợp lệ nhận trợ cấp SNAP của hộ gia đình tôi. DHR xác minh dữ kiện thông tin này qua các chương trình đối chiếu trên máy điện toán. Tôi hiểu rằng DHR sử dụng các dữ kiện thông tin này để giám sát việc tuân thủ nội quy chương trình.",
            reportingRulesDtachanges:"Trong đa số các trường hợp, các hộ gia đình thuộc diện quy định Báo Cáo Đơn Giản của SNAP phải thông báo cho DHR biết về các thay đổi trong bản Báo Cáo Tạm Thời (IR) và tái chứng nhận ngoại trừ :",
            incomeExceeds:"Nếu thu nhập của hộ gia đình tôi vượt quá mức ngưỡng tổng thu nhập",
            workRequirementlabel:"o	Nếu tôi là người lớn khỏe mạnh không có người phụ thuộc (ABAWD) tuân thủ các yêu cầu về làm việc và số giờ làm việc của tôi giảm xuống dưới 20 giờ mỗi tuần",
            verifiedInfomation:"Nếu DHR nhận được thông tin đã xác minh về hộ gia đình tôi, số tiền trợ cấp của tôi có thể thay đổi",
            tbaRulesone:"Nếu tôi không thuộc diện qui định trong các quy chế Báo Cáo Đơn Giản của SNAP hoặc các quy chế Thay Thế Trợ Cấp Chuyển Tiếp (TBA), tôi phải báo cáo cho DHR về các thay đổi liên quan đến hộ gia đình tôi mà có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của chúng tôi. Tôi hiểu rằng tôi phải báo cáo các thay đổi này cho DHR bằng cách đích thân tới gặp, qua thư viết hoặc qua điện thoại ",
            tbaRuletwo:" trong vòng 10 ngày kể từ khi có thay đổi đó.",
            tbaExample:" Ví dụ, quý vị phải báo cáo các thay đổi về thu nhập của hộ gia đình của quý vị, số người trong gia đình, hoặc địa chỉ.",
            findmeIneligible:"Tôi có quyền nói chuyện với một giám sát viên nếu DHR thấy tôi không hợp lệ nhận trợ cấp SNAP khẩn cấp và tôi không đồng ý với quyết định đó. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được trợ cấp của tôi trong vòng 7 ngày tính theo lịch sau khi nộp đơn xin trợ cấp SNAP. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được Thẻ Chuyển Trợ Cấp qua Mạng Điện Tử (EBT) của tôi trong vòng 7 ngày tính theo lịch sau khi tôi nộp đơn xin trợ cấp SNAP.",
            verificationtoDta:"Tôi có thể được nhận thêm trợ cấp SNAP nếu tôi báo cáo và cung cấp chứng từ cho DHR về:",
            childorOtherTitile: "chi phí giữ trẻ hoặc chi phí chăm sóc người phụ thuộc khác, chi phí nơi tạm trú, và/hoặc chi phí dịch vụ điện nước",
            legallyObligatedlabel: "tiền chu cấp nuôi con theo nghĩa vụ pháp lý đối với một thành viên không ở trong hộ gia đình",
            disabledMedicalcost:"Nếu tôi 60 tuổi hoặc già hơn và hoặc nếu tôi bị khuyết tật và tôi trả chi phí y tế, tôi có thể báo cáo và cung cấp chứng từ về các khoản chi phí này cho DHR. Việc này có thể giúp tôi hợp lệ được hưởng một khoản khấu trừ và làm tăng trợ cấp SNAP của tôi.",
            meetExemptionlabel:"Trừ khi hội đủ điều kiện được miễn, tất cả những người nhận SNAP tuổi từ 16 đến 59 nào đều phải đăng ký làm việc và phải tuân thủ các Yêu Cầu Làm Việc Tổng Quát của SNAP. Những người nhận SNAP tuổi từ 18 đến 49 cũng có thể phải tuân thủ các yêu cầu của Chương Trình Làm Việc ABAWD. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các yêu cầu làm việc nói trên. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các trường hợp ngoại lệ và các mức phạt trong trường hợp không tuân thủ.",
            voluntarilyParticipatelabel:"Đa số những người nhận SNAP có thể tự nguyện tham gia các dịch vụ huấn luyện việc làm và giáo dục qua chương trình Path to Work của SNAP. DHR sẽ giới thiệu hồ sơ đến chương trình SNAP Path to Work nếu thích hợp.",
            dtaNamescontact: "DHR cũng sẽ chia sẻ tên và thông tin liên lạc của những người nhận SNAP với các cơ sở cung cấp dịch vụ SNAP Path to Work để tuyển dụng. Tôi hiểu rằng các thành viên trong hộ gia đình của tôi có thể được các chuyên gia SNAP Path to Work hoặc các nhà thầu của DHR liên lạc để tìm hiểu về các lựa chọn tham gia SNAP Path to Work. Để biết thêm tin tức về chương trình SNAP Path to Work, thăm mạng ",
            subjectToverification:"Tôi hiểu rằng các dữ kiện thông tin mà tôi cung cấp cùng với đơn xin của tôi sẽ được kiểm tra xác minh để xác định là có trung thực hay không. Nếu bất kỳ dữ kiện thông tin nào là giả mạo, DHR có thể từ chối trợ cấp SNAP của tôi. Tôi cũng có thể bị truy tố hình sự vì cung cấp dữ kiện thông tin giả mạo.",
            signingApplication:"Tôi hiểu rằng khi ký đơn xin này, tôi cho phép DHR kiểm tra xác minh và điều tra các dữ kiện thông tin mà tôi cung cấp liên quan đến sự hợp lệ hưởng trợ cấp SNAP của tôi, trong đó bao gồm cả việc cho phép:",
            proveInformation:"Có được các giấy tờ để chứng minh dữ kiện thông tin trong đơn xin này với các cơ quan khác của tiểu bang, các cơ quan liên bang, các cơ quan gia cư địa phương, các sở phúc lợi ngoài tiểu bang, các tổ chức tài chánh và từ Equifax Workforce Solutions. Tôi cũng cho phép các cơ quan này cung cấp cho DHR thông tin về hộ gia đình tôi liên quan đến trợ cấp SNAP của tôi.",
            immigrationStatusLabel:"Nếu ứng dụng, kiểm tra xác minh diện di trú của tôi qua Sở Di Trú và Nhập Tịch Hoa Kỳ (USCIS). Tôi hiểu rằng DHR có thể xác minh dữ kiện thông tin trong đơn xin SNAP của tôi với USCIS. Bất kỳ dữ kiện thông tin nào nhận được từ USICIS có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của gia đình tôi và số tiền trợ cấp SNAP.",
            secondaryEducation: "Chia sẻ dữ kiện thông tin về tôi và những người phụ thuộc dưới 19 tuổi của tôi với Sở Giáo Dục Tiểu Học và Trung Học Phổ Thông (DESE). DESE sẽ chứng nhận những người phụ thuộc của tôi cho các chương trình bữa sáng và bữa trưa học đường.",
            publicHealth:"Chia sẻ dữ kiện thông tin về tôi, những người phụ thuộc dưới 5 tuổi của tôi và bất kỳ ai hiện đang mang thai trong gia đình tôi với Sở Sức Khỏe Công Cộng (DPH). DPH giới thiệu những người này tới Chương Trình Phụ Nữ, Trẻ Sơ Sinh và Trẻ Em (WIC) để nhận các dịch vụ dinh dưỡng.",
            executiveOfficeHealth:"Chia sẻ dữ kiện thông tin, cùng với Văn Phòng Điều Hành Dịch Vụ Sức Khỏe và Nhân Sự Alabama, về sự hợp lệ nhận trợ cấp SNAP của tôi với các hãng điện, hãng khí đốt, và các hãng cung cấp dịch vụ truyền hình cáp và điện thoại hợp lệ để chứng nhận sự hợp lệ được giảm giá dịch vụ điện nước của tôi.",
            housingCommuntiy:"Chia sẻ dữ kiện thông tin của tôi với Sở Gia Cư và Phát Triển Cộng Đồng (DHCD) để ghi danh cho tôi tham gia Chương Trình Sưởi Ấm và Thực Phẩm (Heat & Eat Program).",
            lowerBenefitslabel:"DHR có thể từ chối, ngừng hoặc giảm trợ cấp của tôi dựa trên dữ kiện thông tin từ Equifax Workforce Solutions. Tôi có quyền nhận được một bản miễn phí báo cáo của tôi từ Equifax nếu tôi yêu cầu trong vòng 60 ngày kể từ khi có quyết định của DHR. Tôi có quyền chất vấn mức độ chính xác hoặc đầy đủ của các dữ kiện thông tin trong báo cáo của tôi. Tôi có thể liên lạc với Equifax tại: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
            tollFreeLabel:"(đường dây miễn phí).",
            rightoKnowbrochure:"Tôi hiểu rằng tôi sẽ nhận được một bản sao tờ thông tin 'Quyền Được Biết của Quý Vị' và tờ thông tin về Chương Trình SNAP. Tôi sẽ đọc hoặc sẽ nhờ người đọc các tờ thông tin và tôi phải hiểu nội dung cũng như các quyền và trách nhiệm của tôi. Nếu có thắc mắc về các tờ thông tin hoặc bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Nếu gặp khó khăn trong việc đọc hoặc hiểu bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Có thể liên lạc với DHR tại: ",
            lawfullyResiding:"Tôi tuyên thề rằng tất cả các thành viên trong hộ gia đình SNAP hiện đang xin trợ cấp SNAP của tôi là công dân Hoa Kỳ hoặc ngoại kiều cư trú hợp pháp.",
            registerVoteTitle: "Quyền Đăng Ký để Bỏ Phiếu",
            registerVoteFirstLabel:"Tôi hiểu rằng tôi có quyền đăng ký bỏ phiếu tại DHR. Tôi hiểu rằng DHR sẽ giúp tôi điền mẫu đơn xin đăng ký cử tri nếu tôi cần giúp đỡ. Tôi được phép điền mẫu đơn xin đăng ký cử tri một cách kín đáo.",
            registerVoteSecondLabel:"Tôi hiểu rằng việc xin đăng ký hoặc từ chối đăng ký bỏ phiếu sẽ không ảnh hưởng đến số tiền trợ cấp mà tôi nhận được từ DHR. ",
            penaltyWarningTitle:"Cảnh Báo về Mức Phạt SNAP",
            penaltyWarningContent:"Tôi hiểu rằng nếu tôi hoặc bất kỳ thành viên nào trong hộ gia đình SNAP của tôi cố ý vi phạm bất kỳ quy chế nào ghi dưới đây, người đó sẽ không hợp lệ nhận trợ cấp SNAP cho một năm sau lần vi phạm đầu tiên, hai năm sau lần vi phạm thứ hai và vĩnh viễn sau lần vi phạm thứ ba. Người đó cũng có thể bị phạt tiền tới mức tối đa là $250.000 (250 ngàn Mỹ kim), phạt tù tới mức tối đa 20 năm hoặc cả hai. Người đó cũng có thể bị truy tố theo các điều luật hiện hành khác của Tiểu Bang và Liên Bang. Các quy chế này là:",
            penaltyWarningOne:"Không khai man hoặc che giấu đữ kiện thông tin để được nhận trợ cấp SNAP.",
            penaltyWarningTwo:"Không mua bán trao đổi hoặc bán trợ cấp SNAP.",
            penaltyWarningThree:"Không sửa đổi thẻ EBT để được nhận tiền trợ cấp SNAP mà quý vị không hợp lệ nhận.",
            penaltyWarningFour:"Không sử dụng trợ cấp SNAP để mua các vật dụng không hợp lệ, chẳng hạn như đồ uống có chất rượu và thuốc lá.",
            penaltyWarningFive:"Không sử dụng trợ cấp SNAP hoặc thẻ EBT của người khác, trừ khi quý vị là người được ủy quyền hợp pháp.",

               
            penaltiesTitle:"Tôi cũng hiểu các mức phạt sau đây: ",
            individualLabel:"	Những người phạm tội Cố Ý Vi Phạm Chương Trình (IPV) của ",
            cashProgram: "chương trình trợ cấp tiền mặt ",
            internationalProgram:" sẽ không hợp lệ nhận SNAP trong cùng một giai đoạn mà người đó không hợp lệ nhận trợ cấp tiền mặt.",
            penalitiesTwo:"Những người khai man về lý lịch hoặc nơi cư trú để được nhận nhiều khoản trợ cấp SNAP cùng một lúc sẽ không hợp lệ nhận trợ cấp SNAP trong  ",
            tenYearLabel:"mười năm",
            penalitiesThree:"Những người trao đổi (mua hay bán) trợ cấp SNAP để lấy chất kích thích bất hợp pháp/chất bị kiểm soát, sẽ không hợp lệ nhận SNAP trong  ",
            twoYearLabel:"hai năm  " ,
            penalitiesThreeMiddle:"đối với lần tuyên án đầu tiên và ",
            foreverText:"vĩnh viễn  ",
            penalitiesThreeLast:" đối với lần tuyên án thứ hai.",
            penalitiesFour:"Những người trao đổi (mua hay bán) trợ cấp SNAP để đổi lấy vũ khí, đạn dược, hoặc chất nổ, sẽ không hợp lệ nhận SNAP",
            penalitiesFive:"Những người trao đổi mua bán trợ cấp SNAP có giá trị từ $500 trở lên sẽ không hội đủ điều kiện nhận trợ cấp SNAP ",
            penalitiesSix:"Tiểu Bang có thể áp dụng IPV đối với một người mời chào bán trợ cấp SNAP hoặc thẻ EBT trên mạng trực tuyến hoặc gặp trực diện.",
            penalitiesSeven:"Những người bỏ trốn để tránh bị truy tố, quản chế hoặc giam giữ sau khi bị kết án phạm trọng tội, hoặc vi phạm điều kiện quản chế hoặc tha bổng có điều kiện, đều không hợp lệ nhận trợ cấp SNAP.",
            penalitiesEight:"Không được phép dùng để trả đồ ăn mua nợ và nếu làm như vậy có thể bị mất điều kiện nhận trợ cấp SNAP.",
            penaltiesNine:"Các cá nhân không được mua sản phẩm bằng trợ cấp SNAP với mục đích lấy sản phẩm bên trong ra và trả lại hộp đựng để lấy tiền mặt.",

                      
            interpreterHeader:"Quyền có Người Thông Dịch",
            interpreterContent:"Tôi hiểu rằng tôi có quyền dùng một thông dịch viên do DHR cung cấp nếu trong hộ gia đình tôi không có người lớn nào có thể nói hoặc hiểu tiếng Anh. Tôi cũng hiểu rằng tôi có thể có một thông dịch viên cho bất kỳ buổi điều trần công bằng DHR nào hoặc mang theo người thông dịch riêng. Nếu cần người thông dịch cho một buổi điều trần, tôi phải gọi Ban Điều Trần ít nhất một tuần trước ngày điều trần.",
            nonDiscriminationHeader: "Tuyền Bố về Không Phân Biệt Đối Xử",
            nonDiscriminationContent:"Chiếu theo luật dân quyền Liên Bang và các chính sách và quy chế về dân quyền của Bộ Nông Nghiệp Hoa Kỳ (USDA), USDA, các Cơ Quan, văn phòng, và nhân viên của bộ, cũng như các tổ chức tham gia hoặc điều hành các chương trình của USDA thì bị nghiêm cấm sự phân biệt đối xử vì lý do sắc tộc, màu da, nguồn gốc quốc gia, giới tính, tín ngưỡng, tôn giáo, tình trạng khuyết tật, tuổi, lý tưởng chính trị, hoặc trả đũa hoặc trả thù vì đã từng tham gia hoạt động dân quyền trong bất kỳ chương trình hay hoạt động nào do USDA tổ chức hoặc đài thọ..",
            personDisabilities:"Những người khuyết tật cần phương tiện giao tiếp thay thế khác để nhận tin tức về chương trình (ví dụ như chữ nổi Braille, bản in bằng chữ khổ lớn, băng ghi âm, Ngôn ngữ ký hiệu Hoa Kỳ, v.v...), cần liên lạc với văn phòng Cơ quan (Tiểu bang hoặc địa phương) nơi họ nộp đơn xin trợ cấp. Người bị điếc, khiếm thính hoặc có khuyết tật về nói có thể liên lạc với USDA qua Dịch vụ Tiếp Âm Liên Bang tại số ",
            programInformation:"Thêm vào đó, tin tức về chương trình có thể được cung cấp bằng các ngôn ngữ khác không phải là tiếng Anh.,",
            
            programComplaint:"Để nộp khiếu nại về phân biệt đối xử liên quan đến chương trình, điền Mẫu Đơn Khiếu Nại về Phân Biệt Đối Xử trong Chương Trình của USDA, (AD-3027), có trên mạng trực tuyến tại: ",
            usdaOffice:"và tại bất kỳ văn phòng nào của USDA, hoặc viết thư gửi cho USDA và trong thư cung cấp tất cả các dữ kiện thông tin yêu cầu trong mẫu đơn khiếu nại. Để yêu cầu cung cấp mẫu đơn khiếu nại, vui lòng gọi số ",
            sumbitYourLabel:"Nộp mẫu đơn đã điền hoàn tất hoặc thư của quý vị tới cho USDA qua:",

            mailAddress:"qua thư bưu điện: U.S. Department of Agriculture",
            addressOne:" Office of the Assistant Secretary for Civil Rights",
            addressVenue:" 1400 Independence Avenue, SW",
            addressState:"Washington, D.C. 20250-9410",
            fax:" fax:",
            orLabel:" hoặc",
            eamilTitle:"email: ",
            thisInstution:"Tổ chức này là một cơ quan cung cấp cơ hội bình đẳng."

                     

          }
          case HI:
            return {
             termsHeader:"Avi sou Dwa, Responsablite ak Sanksyon",
             headerLabel: "Avi sou Dwa, Responsablite ak Sanksyon – Tanpri Li ak anpil Atansyon",
             certifyContents:"Mwen sètifye ke m te li, oswa te fè li pou mwen, enfòmasyon ki nan aplikasyon sa a. Repons mwen a kesyon yo nan aplikasyon sa a vre epi konplè selon sa m byen konnen. Mwen sètifye tou ke enfòmasyon mwen bay Depatman an pandan entèvyou aplikasyon an ak alavni pral vre epi konplè selon sa m byen konnen. M konprann ke bay fo enfòmasyon oswa ki twonpe se fwòd. M konprann tou ke defòme oswa kache enfòmasyon pou etabli kalifikasyon SNAP se fwòd. Sa lakòz yon Vyolasyon Pwogram Entansyonèl (IPV) epi ki pini ak sanksyon sivil ak kriminèl.",
             understandDta:"M konprann ke Depatman Asistans Tranzisyonèl la (DHR) administre SNAP. M konprann ke DHR gen 30 jou apati dat aplikasyon an pou trete aplikasyon mwen an. Anplis, m konprann ke:",
             foodNutritionact:"Lwa sou Manje ak Nitrisyon 2008 la (7 U.S.C. 2011-2036) pèmèt DHR itilize Nimewo Sekirite Sosyal mwen (NSS) ak NSS chak manm fwaye mwen aplike pou yo. DHR itilize enfòmasyon sa a pou detèmine kalifikasyon fwaye mwen pou SNAP. DHR verifye enfòmasyon sa yo atravè pwogram enfòmatik pou matche. M konprann ke DHR itilize li pou kontwole konfòmite ak règleman pwogram yo.",
             reportingRulesDtachanges:"Anpil fwa, fwaye yo ki anba règleman SNAP Rapò Senplifye dwe enfòme DHR konsènan chanjman nan Rapò Pwovizwa (IR) ak resètifikasyon eksepte:",
             incomeExceeds:"Si revni fwaye mwen depase limit revni brit la",
             workRequirementlabel:"Si mwen anba kondisyon travay Adilt kapab san depandan yo (ABAWD) epi kantite lè travay mwen an desann pi ba pase 20 èdtan pa semèn.",
             verifiedInfomation:"Si DHR resevwa enfòmasyon ki verifye konsènan fwaye mwen, montan benefis mwen ka chanje",
             tbaRulesone: "Si mwen pa anba règleman SNAP Rapò Senplifye oswa Benefis Altènatif Tranzisyonèl (TBA), mwen dwe rapòte bay DHR chanjman ki fèt nan fwaye m ki ka afekte kalifikasyon nou. M konprann ke mwen dwe rapòte chanjman sa yo bay DHR an pèsòn, alekri oswa pa telefòn ",
             tbaRuletwo:" nan 10 jou apre chanjman an.",
             tbaExample:" Pa egzanp, ou dwe rapòte chanjman nan revni, gwosè, oswa adrès fwaye w la.",
             findmeIneligible:"Mwen gen yon dwa pou pale ak yon sipèvizè si DHR jwenn mwen pa kalifye pou benefis Ijans SNAP epi m pa dakò. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis SNAP ijans men m pa resevwa benefis mwen nan setyèm jou kalandriye apre mwen te aplike pou SNAP la. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis ijans SNAP men m pa resevwa kat Benefis Transfè Elektwonik (EBT) mwen nan setyèm jou Kalandriye apre mwen te aplike pou SNAP la.",
             verificationtoDta:"Mwen ka resevwa plis benefis SNAP si mwen rapòte epi bay DHR verifikasyon pou:",
             childorOtherTitile: "depans pou swen timoun oswa lòt depandan, depans pou lojman, ak/oswa depans pou sèvis piblik",
             legallyObligatedlabel:"sipò timoun legalman-obligatwa a yon manm ki pa nan fwaye a",
             disabledMedicalcost:"Si m gen 60 an oswa plis oswa si mwen andikape epi mwen peye pou depans medikal, mwen ka rapòte epi bay DHR verifikasyon depans sa yo. Sa ka fè m kalifye pou yon dediksyon epi ogmante benefis SNAP mwen.",
             meetExemptionlabel: "Sòf si yo satisfè yon egzanpsyon, tout benefisyè SNAP yo ki genyen ant laj 16 ak 59 an enskri pou travay epi anba Kondisyon Jeneral Travay SNAP. Benefisyè SNAP ki genyen ant laj 18 ak 49 an kapab tou anba kondisyon Pwogram Travay ABAWD. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan kondisyon travay yo. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan eksepsyon ak sanksyon pou non-konfòmite.",
             voluntarilyParticipatelabel:"Pifò benefisyè SNAP yo ka volontèman patisipe nan sèvis edikasyon ak fòmasyon pou travay atravè pwogram Path to Work SNAP la. DHR pral bay referans nan pwogram Path to Work SNAP la si sa apwopriye",
             dtaNamescontact:"DHR ka pataje tou non ak enfòmasyon kontak benefisyè SNAP yo ak founisè Path to Work yo pou rezon rekritman. M konprann ke manm fwaye mwen yo ka kontakte pa espesyalis DHR SNAP Path to Work oswa founisè kontra pou eksplore opsyon patisipasyon nan SNAP Path to Work. Pou plis enfòmasyon konsènan SNAP Path to Work la, vizite ",
             subjectToverification: "M konprann ke enfòmasyon mwen bay ak aplikasyon m nan pral sijè a verifikasyon pou detèmine si li vre. Si nenpòt enfòmasyon fo, DHR ka refize benefis SNAP mwen yo. Mwen ka tou sijè a pwosekisyon kriminèl paske m te bay fo enfòmasyon.",
             signingApplication:"M konprann ke nan siyen aplikasyon sa a, mwen bay DHR pèmisyon pou verifye epi mennen ankèt sou enfòmasyon mwen bay ki lye ak kalifikasyon m pou benefis SNAP, ki enkli pèmisyon pou:",
             proveInformation:"Jwenn dokiman pou pwouve enfòmasyon nan aplikasyon sa a ak lòt ajans etatik, ajans federal, otorite lojman lokal, depatman byennèt andeyò eta a, enstitisyon finansye ak soti nan Equifax Workforce Solutions. Mwen bay ajans sa yo pèmisyon tou pou bay DHR enfòmasyon sou fwaye m nan ki konsène benefis SNAP mwen yo.",
             immigrationStatusLabel:"Si sa aplikab, verifye estati imigrasyon mwen atravè Sèvis Sitwayènte ak Imigrasyon Etazini (USCIS). M konprann ke DHR ka verifye enfòmasyon ki nan aplikasyon SNAP mwen ak USCIS. Tout enfòmasyon yo resevwa nan men USCIS ka afekte kalifikasyon fwaye m ak montan benefis SNAP.",
             secondaryEducation:"Pataje enfòmasyon konsènan mwen menm ak depandan mwen yo ki poko gen 19 an ak Depatman Edikasyon Elemantè ak Segondè (DESE). DESE pral sètifye depandan mwen yo pou pwogram manje maten ak manje midi nan lekòl. ",
             publicHealth:"Pataje enfòmasyon konsènan mwen, depandan mwen ki poko gen laj 5 an ak nenpòt moun ki ansent nan fwaye m nan ak Depatman Sante Piblik (DPH). DPH refere moun sa yo bay Pwogram Fanm, Tibebe ak Timoun (WIC) pou sèvis nitrisyon.",
             executiveOfficeHealth:"Pataje enfòmasyon, ansanm ak Biwo Egzekitif Sante ak Sèvis Imen Alabama la, sou kalifikasyon m pou SNAP ak konpayi elektrik, konpayi gaz ak konpayi telefòn ak kab ki kalifye pou sètifye kalifikasyon m nan tarif redui pou rabè sèvis piblik.",
             housingCommuntiy:"Pataje enfòmasyon m ak Depatman Lojman ak Devlopman Kominotè (DHCD) nan objektif pou enskri m nan Pwogram Heat & Eat a. ",
             lowerBenefitslabel:"DHR ka refize, sispann oswa diminye benefis mwen yo baze sou enfòmasyon ki soti nan Equifax Workforce Solutions. M gen dwa pou jwenn yon kopi rapò mwen an gratis nan men Equifax si mwen mande li nan 60 jou apre desizyon DHR a. M gen dwa pou poze kesyon sou presizyon oswa eta konplè enfòmasyon ki nan rapò mwen an. Mwen ka kontakte Equifax nan: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, 1-800-996-7566 ",
             tollFreeLabel:"",
             rightoKnowbrochure:"M konprann ke m pral jwenn yon kopi bwochi “Your Right to Know” a ak bwochi Pwogram SNAP la. M pral li oswa te fè li bwochi yo epi mwen dwe konprann kontni yo ak dwa ak responsablite mwen yo. Si m gen nenpòt kesyon konsènan bwochi yo oswa nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Si m gen pwoblèm pou li oswa konprann nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Ou ka kontakte DHR nan:",
             lawfullyResiding:"Mwen fè sèman ke tout manm fwaye SNAP mwen yo k ap mande benefis SNAP se swa Sitwayen Ameriken swa Non-sitwayen Ameriken ki rezide legalman.",
             registerVoteTitle: "Dwa pou Enskri pou Vote",
             registerVoteFirstLabel:"M konprann ke mwen gen dwa enskri pou vote nan DHR. Mwen konprann ke  DHR pral ede m ranpli fòm aplikasyon pou enskripsyon elektè a si mwen bezwen èd. Mwen gen dwa ranpli fòm aplikasyon pou enskripsyon elektè a an prive.",
             registerVoteSecondLabel:"M konprann ke nan aplike pou m enskri oswa refize enskri pou vote pa pral afekte montan benefis mwen resevwa nan men DHR. ",
             penaltyWarningTitle:"Avètisman sou Sanksyon SNAP",
             penaltyWarningContent:"M konprann ke si mwen menm oswa nenpòt manm fwaye SNAP mwen vyole nenpòt nan règleman ki anba yo, moun sa a pa pral kalifye pou SNAP pou yon ane apre premye vyolasyon an, dezan apre dezyèm vyolasyon an epi pou tout tan apre twazyèm vyolasyon an. Moun sa a ka peye tou yon amann jiska $250,000, ale nan prizon jiska 20 an, oswa toulede. Yo kapab tou sijè a pousuit anba lwa Federal ak Etatik. Règleman sa yo se:",
             penaltyWarningOne:"Pa bay fo enfòmasyon oswa kache enfòmasyon pou resevwa benefis SNAP.",
             penaltyWarningTwo:"Pa fè echanj ak oswa vann benefis SNAP.",
             penaltyWarningThree:"Pa chanje kat EBT pou resevwa benefis SNAP ou pa kalifye pou yo.",
             penaltyWarningFour:"Pa itilize benefis SNAP pou achte atik ki pa kalifye, tankou bwason alkolize ak tabak.",
             penaltyWarningFive:"Pa itilize SNAP oswa kat EBT yon lòt moun, sof si ou se yon reprezantan otorize.",
             
             penaltiesTitle:"M konprann tou sanksyon ki anba yo: ",
             individualLabel:"Moun yo ki komèt yon",
             cashProgram: "pwogram kach",
             internationalProgram:" Pwogram Vyolasyon Entansyonèl (IPV), yo pa pral kalifye pou SNAP pou menm peryòd moun nan pa kalifye pou asistans lajan kach.",
             penalitiesTwo:"Moun yo ki fè yon deklarasyon fwòd sou idantite oswa rezidans yo pou resevwa plizyè benefis SNAP alafwa pa pral kalifye pou SNAP pou  ",
             tenYearLabel:"dizan",
             penalitiesThree:"Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou yon sibstans kontwole/dwòg ilegal (yo), yo pa pral kalifye pou SNAP pou ",
             twoYearLabel:"dezan " ,
             penalitiesThreeMiddle:"pou premye dekouvèt la, epi",
             foreverText:"pou tout tan ",
             penalitiesThreeLast:"pou dezyèm dekouvèt la.",
             penalitiesFour:"Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou zam afe, minisyon oswa eksplozif, yo pa pral kalifye pou SNAP",
             penalitiesFive:"Moun ki fè echanj ak (achte oswa vann) benefis SNAP ki gen yon valè $500 oswa plis, pa pral kalifye pou SNAP",
             penalitiesSix:"Eta a ka pousuiv yon IPV kont yon moun ki fè yon òf pou vann benefis SNAP oswa yon kat EBT an liy oswa an pèsòn",
             penalitiesSeven:"Moun ki sove pou evite pousuit, detansyon oswa prizon apre yo fin kondane pou yon krim, oswa ki vyole pwobasyon oswa libète sou kondisyon, yo pa kalifye pou SNAP.",
             penalitiesEight:"Peye pou manje achte kredi pa otorize epi ka menmen nan diskalifikasyon pou SNAP.",
             penaltiesNine:"Moun pa gen dwa achte pwodui ak benefis SNAP ak entansyon pou jete kontni yo epi retounen resipyan pou lajan kach.",
          
             interpreterHeader:"Dwa a yon Entèprèt",
             interpreterContent:"M konprann ke mwen gen dwa pou jwenn yon entèprèt DHR bay si okenn adilt nan fwaye SNAP mwen pa kapab pale oswa konprann angle. Mwen konprann tou ke mwen ka jwenn yon entèprèt pou nenpòt odyans DHR san patipri oswa mennen pwòp entèprèt mwen. Si m bezwen yon entèprèt pou yon odyans, mwen dwe rele Divizyon Odyans lan omwen yon semèn anvan dat odyans lan.",
             nonDiscriminationHeader: "Deklarasyon Non-diskriminasyon",
             nonDiscriminationContent:"Nan konfòmite ak lwa sou dwa sivil federal yo ak règleman ak politik Depatman Agrikilti Ameriken (USDA), USDA, ajans li yo, biwo, ak anplwaye li yo, ak enstitisyon k ap patisipe nan oswa ap administre pwogram USDA yo, yo entèdi pou fè diskriminasyon ki baze sou ras, koulè, orijin nasyonal, sèks, kwayans relijye, andikap, laj, konviksyon politik, oswa reprezay oswa vanjans pou aktivite dwa sivil anvan nan nenpòt pwogram oswa aktivite ki fèt oswa ki finanse pa USDA.",
             personDisabilities:"Moun andikape ki bezwen lòt mwayen kominikasyon pou enfòmasyon sou pwogram yo (pa egzanp Bray, gwo lèt, kasèt odyo, Lang Siy Ameriken, elatriye), ta dwe kontakte Ajans (Etatik oswa lokal) la kote yo te aplike pou benefis yo. Moun ki soud, ki pa tande byen oswa ki gen andikap pou pale ka kontakte USDA atravè Sèvis Federal Relay la nan ",
             programInformation:"Anplis, enfòmasyon sou pwogram yo ka disponib nan lòt lang ki pa Angle.",
             
             programComplaint:"Pou depoze yon plent diskriminasyon kont yon pwogram, ranpli Fòm Program Discrimination Complaint USDA a, (AD-3027) ke w ap jwenn an liy nan: ",
             usdaOffice:"ak nan nenpòt biwo USDA, oswa ekri yon lèt ki adrese bay USDA epi mete nan lèt la tout enfòmasyon yo mande nan fòm nan. Pou mande yon kopi fòm plent lan, rele (866) 632-9992. ",
             sumbitYourLabel:"Soumèt fòm oswa lèt ranpli w la bay USDA nan:",
          
            mailAddress:"lapòs: U.S. Department of Agriculture",
            addressOne:" Office of the Assistant Secretary for Civil Rights",
            addressVenue:" 1400 Independence Avenue, SW",
            addressState:"Washington, D.C. 20250-9410",
            fax:" faks: (202) 690-7442;",
            orLabel:" oswa",
            eamilTitle:"imel:  program.intake@usda.gov.",
            thisInstution:"Enstitisyon sa a se yon founisè opòtinite egal."
          
          
            }
    default:
    return
  } //terms tranlation ending

  
}
