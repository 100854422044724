import React, { Component } from 'react'
import { consumerConst } from "../../../../pages/consumer/consumerText";
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer";
import { bindActionCreators } from "redux";

import {
    concernDocumentGetCall
} from "../../../../redux/concernsAppeals/concernsAppealsAction"



const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");




class concernSubmit extends Component {
    constructor(props) {
        super(props);
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        this.state = {
            agencyId: agencyID,
            concernRefrenceNumber: "",
            concernRefNum: ""
        }

    }

    componentDidMount() {
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        let concernRefNum = this.props && this.props.concernsAppeals && this.props.concernsAppeals.customerConcernsSuccess && this.props.concernsAppeals.customerConcernsSuccess.data &&this.props.concernsAppeals.customerConcernsSuccess.data.concernReferenceNumber;
        this.setState({ concernRefNum })
        let concernRefrenceNumber = this.props && this.props.concernsAppeals && this.props.concernsAppeals.customerConcernsSuccess && this.props.concernsAppeals.customerConcernsSuccess.data && this.props.concernsAppeals.customerConcernsSuccess.data.docReferenceNumber;

        this.setState({ concernRefrenceNumber })
    }


    handlePrintClick = () => {
        const clientDetails = JSON.parse(sessionStorage.getItem("clientDetails"));
        const agencyID = clientDetails.clientInfo.agencyID;
        let concernRefrenceNumber = this.props.concernsAppeals.customerConcernsSuccess.data.docReferenceNumber;
        this.props.concernDocumentGetCall(agencyID, concernRefrenceNumber, selectedLanguage);
    }

    render() {
        return (
            <div className='pad-all--double'>
                <div className="pure-u-1">
                    <div>
                        <span style={{ display: 'flex', fontSize: '2rem', fontWeight: '600', marginBottom: '10px', padding: '0rem 12rem 0rem 2rem' }}>{consumerConst(selectedLanguage).concernSubmitHeaderText}</span>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '1px solid #d6d6d6', marginBottom: '20px' }}>
                    </div>
                </div>
                <div className="pure-u-1" style={{ display: 'flex', flexDirection: 'column', padding: '0rem 12rem 0rem 2rem' }}>
                    <span >{consumerConst(selectedLanguage).concernNumber} :{this.state.concernRefNum}</span>
                    <span style={{ marginTop: '1rem' }}>{consumerConst(selectedLanguage).concernSubmitYouText} <a onClick={this.handlePrintClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{consumerConst(selectedLanguage).concernSubmitPrintText} </a> {consumerConst(selectedLanguage).concernSubmitText}  <a onClick={this.handlePrintClick} style={{ textDecoration: "underline", cursor: "pointer" }}>{consumerConst(selectedLanguage).concernSubmitSaveText}</a> {consumerConst(selectedLanguage).concernSubmitRefText}</span>
                </div>
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            concernDocumentGetCall: concernDocumentGetCall,

        },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(concernSubmit);