import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import * as TextProps from "../../../utils/constants/text";
import DTAInput from "../../../utils/components/dtaInput";
import DtaSelect from "../../../utils/components/dtaSelect";
import Checkbox from "../../../utils/components/checkbox";
import { recertificationPageLabels } from "../../recertification/recertificationText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as languageConstants from "../../../utils/constants/constants";
import { mhProviderSNAPGAP } from "../../provider/providerText";
import * as types from "../../../utils/constants/types";



const language =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class AuthRep extends Component {

    constructor(props) {
        super(props);
        const userData = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
        const { authorizedRepresentatives={}, snapAuthRepFlg="", ebtAuthRepFlg="" } = userData || { authorizedRepresentatives: {} };

        let snapFlag = null;
        if (snapAuthRepFlg !== null && snapAuthRepFlg !== undefined) {
            if (snapAuthRepFlg === "Y") {
                snapFlag =
                    recertificationPageLabels(
                        language,
                        null,
                        languageConstants.authRep
                    ).yesnoOptions[0]

            } else {
                snapFlag = recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                ).yesnoOptions[1];
            }
        }

        let ebtFlag = null;
        if (ebtAuthRepFlg !== null && ebtAuthRepFlg !== undefined) {
            if (ebtAuthRepFlg === "Y") {
                ebtFlag = recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                ).yesnoOptions[0]
            } else {
                ebtFlag = recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                ).yesnoOptions[1];
            }
        }



        const {
            ebtFrstNam = "",
            ebtLstNam = "",
            ebtPhNum = "",
            snpFrstNam = "",
            snpLstNam = "",
            snpPhNum = ""
        } = authorizedRepresentatives;

        this.state = {
            isAuthorizeEBT: ebtFlag,
            ebtFrstNam,
            ebtMdlNam: authorizedRepresentatives.ebtMdlNam || "",
            ebtLstNam,
            ebtSuffix: authorizedRepresentatives.ebtSuffix || "",
            ebtPhNum,
            snpFrstNam,
            snpMdlNam: authorizedRepresentatives.snpMdlNam || "",
            snpLstNam,
            snpSuffix: authorizedRepresentatives.snpSuffix || "",
            snpPhNum,
            isAuthorizeCert: snapFlag,
            agree: [],
            signedName: "",
            isShowEBTForm: (ebtFrstNam || ebtLstNam) ? true : false,
            isShowauthCertForm: (snpFrstNam || snpLstNam) ? true : false,
            isAuthRepDataUpdated: false,
            snpWebDataChangeCode: authorizedRepresentatives.snpWebDataChangeCode,
            ebtWebDataChangeCode: authorizedRepresentatives.ebtWebDataChangeCode,
            ebtAssistorId: authorizedRepresentatives.ebtAssistorId,
            snpAssistorId: authorizedRepresentatives.snpAssistorId,
            errorData: {
                snpFrstNamError: false,
                snpLstNamError: false,
                snpPhNumError: false,
                ebtFrstNamError: false,
                ebtLstNamError: false,
                ebtPhNumError: false,
                signedNameError: false,
                agreeError: false
            }
        };
    }

    componentDidMount() {
        this.props.authRepDataHandler(this.state)
    }

    clearQuestionFields(question) {
        if (question === "AuthorizedEBT"){
            this.OnDataChange("ebtFrstNam", "");
            this.OnDataChange("ebtMdlNam", "");
            this.OnDataChange("ebtLstNam", "");
            this.OnDataChange("ebtPhNum", "");
            this.OnDataChange("ebtSuffix", "");
        }
        if (question === "AuthorizedSNP"){
            this.OnDataChange("snpFrstNam", "");
            this.OnDataChange("snpMdlNam", "");
            this.OnDataChange("snpLstNam", "");
            this.OnDataChange("snpPhNum", "");
            this.OnDataChange("snpSuffix", "");
        }
    }

    // clearFieldsErroronRemove(question) {
    //     if(question === "AuthorizedEBT") {
    //             this.state.errorData.ebtFrstNamError = false,
    //             this.state.errorData.ebtLstNamError = false,
    //             this.state.errorData.ebtPhNumError = false,
    //             this.state.errorData.signedNameError = false,
    //             this.state.errorData.agreeError = false
    //         }
    //         if(question === "AuthorizedSNP") {
    //             this.state.errorData.snpFrstNamError = false,
    //             this.state.errorData.snpLstNamError = false,
    //             this.state.errorData.snpPhNumError = false,
    //             this.state.errorData.signedNameError = false,
    //             this.state.errorData.agreeError = false
    //         }
    
    // }

    componentWillReceiveProps(nextProps) {
        const errorData = JSON.parse(nextProps.errorData);
        this.setState({
            errorData: {
                ...errorData
            }
        })
    }


    OnDataChange = (id, value) => {
        const userData = JSON.parse(sessionStorage.getItem('loadConsumerUser'));
        const { authorizedRepresentatives={} } = userData || { authorizedRepresentatives: {} };
        const { ebtWebDataChangeCode ,snpWebDataChangeCode } = this.state;
        const { ebtWebDataChangeCode: authorizedEbtWebDataChangeCode ,snpWebDataChangeCode: authorizedSnpWebDataChangeCode} = authorizedRepresentatives;

        const nextState = {};
        if (id === "isAuthorizeEBT") {
            if (value === recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
            ).yesnoOptions[0]) {
                nextState.isShowEBTForm = true;
            } else if (value === recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
            ).yesnoOptions[1]) {
                this.onRemoveAuthEBT("hide");
                this.clearQuestionFields("AuthorizedEBT");
                // this.clearFieldsErroronRemove("AuthorizedEBT");
            }
            if (ebtWebDataChangeCode === "REMOVE") {
                nextState.ebtWebDataChangeCode = authorizedEbtWebDataChangeCode ? "CHANGE" : "NEW";
            }
            this.props.resetErrorData('ebt',this.state.errorData);
        } else if (id === "isAuthorizeCert") {
            if (value === recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
            ).yesnoOptions[0]) {
                nextState.isShowauthCertForm = true;
            } else if (value === recertificationPageLabels(
                language,
                null,
                languageConstants.authRep
            ).yesnoOptions[1]) {
                this.onRemoveAuthCert("hide");
                this.clearQuestionFields("AuthorizedSNP");
                // this.clearFieldsErroronRemove("AuthorizedSNP");
            }
            this.props.resetErrorData('snp',this.state.errorData);
            if (snpWebDataChangeCode === "REMOVE") {
                nextState.snpWebDataChangeCode = authorizedSnpWebDataChangeCode ? "CHANGE" : "NEW";
            }
        }
        nextState[id] = value;
        nextState['isAuthRepDataUpdated'] = true;
        this.setState(nextState, () => {
            this.props.authRepDataHandler(this.state);
        });
    }



    onRemoveAuthCert = (action) => {
        this.props.resetErrorData('snp',this.state.errorData);
        const nextState = {
            isShowauthCertForm: false
        };

        if (action !== "hide") {
            nextState.isAuthorizeCert = null;
            nextState.isAuthRepDataUpdated = true;
            nextState.snpWebDataChangeCode = "REMOVE";
        }
        // this.clearFieldsErroronRemove("AuthorizedSNP");
        this.clearQuestionFields("AuthorizedSNP");
        this.setState(nextState, () => {
            this.props.authRepDataHandler(this.state)
        });
    }

    onRemoveAuthEBT = (action) => {
        this.props.resetErrorData('ebt',this.state.errorData);
        const nextState = {
            isShowEBTForm: false
        };

        if (action !== "hide") {
            nextState.isAuthorizeEBT = null;
            nextState.isAuthRepDataUpdated = true;
            nextState.ebtWebDataChangeCode = "REMOVE";
        }
        // this.clearFieldsErroronRemove("AuthorizedEBT");
        this.clearQuestionFields("AuthorizedEBT");
        this.setState(nextState, () => {
            this.props.authRepDataHandler(this.state)
        });
    }


    render() {
        return (
            <div style={{ padding: "0 30px" }}>
                <p style={{ color: "#005974", fontWeight: "bolder" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestionone
                    }
                </p>
                <p>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiononedesc
                    }
                </p>
                <div>
                    <div className="pure-g">
                        <div className="pure-u-4-5">
                            <span style={{ color: 'rgb(0, 89, 116)', fontWeight: 'bolder' }}>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).authQuestiontwo
                                }
                            </span>
                        </div>
                        <div className="pure-u-1-5">
                            <button onClick={() => { this.onRemoveAuthEBT('remove') }} className="dta-button dta-delete-button interim-recert-delete-button " style={{ float: "right" }}>Remove</button>
                        </div>
                    </div>
                </div>
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc1
                    }
                </span>{" "}
                <span style={{ textDecoration: "underline" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).underlineText1
                    }
                </span>{" "}
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc2
                    }
                </span>{" "}
                <span style={{ textDecoration: "underline" }}>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).underlineText2
                    }
                </span>{" "}
                <span>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwodesc3
                    }
                </span>
                <div>
                    <p>
                        {
                            recertificationPageLabels(
                                language,
                                null,
                                languageConstants.authRep
                            ).authQuestionOptions
                        }
                    </p>
                    <Radio
                        id="isAuthorizeEBT"
                        name="isAuthorizeEBT"
                        className="dta-form__option-list"
                        optionClass="dta-form__option dta-form__option--width-1-of-2"
                        options={recertificationPageLabels(
                            language,
                            null,
                            languageConstants.aboutMe,
                        ).optionYesNo}
                        required={TextProps.VALUE_TRUE}
                        value={this.state.isAuthorizeEBT}
                        onChange={(value) => this.OnDataChange("isAuthorizeEBT", value)}
                    />
                </div>
                {this.state.isShowEBTForm && <div>
                    <div>
                        {this.state.errorData.ebtFrstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px" }}>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).firstNameError
                            }
                        </span>}
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).firstName
                            }
                        </p>
                        <DTAInput
                            name={"firstName"}
                            className="dta-form__control"
                            // disabled
                            type="specialTextType"
                            maxLength={15}
                            value={this.state.ebtFrstNam}
                            onChange={(value) => this.OnDataChange("ebtFrstNam", value)}
                            required={TextProps.VALUE_TRUE}
                            error={this.state.errorData.ebtFrstNamError}
                        />
                    </div>
                    <div>
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).middleName
                            }
                        </p>
                        <DTAInput
                            name={"middleName"}
                            className="dta-form__control"
                            // disabled
                            type="specialTextType"
                            maxLength={15}
                            value={this.state.ebtMdlNam}
                            onChange={(value) => this.OnDataChange("ebtMdlNam", value)}
                            required={TextProps.VALUE_TRUE}
                        // error={this.props.flags.ebtFrstNamError}
                        />
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-3-5">
                            {this.state.errorData.ebtLstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px" }}>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).lastNameError
                                }
                            </span>}
                            <p>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).lastName
                                }
                            </p>
                            {/* {this.props.flags.ebtLstNamError && (
                            <span id="firstNameError" className="firstname dta-form__error-message">
                                {mhProviderSNAPGAP(types.ENGLISH).lastNameErrorMsg}
                            </span>
                        )} */}
                            <DTAInput
                                className="dta-form__control"
                                required={TextProps.VALUE_TRUE}
                                // disabled
                                type="specialTextType"
                                maxLength={20}
                                value={this.state.ebtLstNam}
                                onChange={(value) => this.OnDataChange("ebtLstNam", value)}
                                error={this.state.errorData.ebtLstNamError}
                            />
                        </div>
                        <div className="pure-u-2-5">
                            {this.state.errorData.ebtLstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px", visibility: "hidden" }}>Please enter suffix</span>}
                            <p>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).suffixLabel
                                }
                            </p>
                            <DtaSelect
                                name={"unit"}
                                className="dta-units__item"
                                value={this.state.ebtSuffix}
                                onChange={(value) => this.OnDataChange("ebtSuffix", value)}
                                options={

                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.aboutMe
                                    ).suffixOption

                                }
                                // onChange={(value) => this.handleExpFreqChanged(member, value)}
                                ariaLabel={"Suffix"}
                            />
                        </div>
                    </div>
                    <div>
                        {this.state.errorData.ebtPhNumError && 
                        <span id="firstNameError" className="firstname dta-form__error-message">
                                   {this.props.ebtPhoneValid ?
                                    mhProviderSNAPGAP(types.ENGLISH).phoneNumberLengthErrorMsg
                                :
                                    mhProviderSNAPGAP(types.ENGLISH).phoneNumberErrorMsg
                                }
                                </span>
                        }
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).phoneNumber
                            }
                        </p>
                        <DTAInput
                            name={"phone"}
                            className="dta-form__control"
                            // disabled
                            type="tel"
                            value={this.state.ebtPhNum}
                            onChange={(value) => this.OnDataChange("ebtPhNum", value)}
                            required={TextProps.VALUE_TRUE}
                            error={this.state.errorData.ebtPhNumError}
                        />
                    </div>
                </div>}
                <br />
                <div>
                    <div className="pure-g">
                        <div className="pure-u-4-5">
                            <span style={{ color: 'rgb(0, 89, 116)', fontWeight: 'bolder' }}>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep,
                                    ).authCertificationTitle
                                }
                            </span>
                        </div>
                        <div className="pure-u-1-5">
                            <button onClick={() => { this.onRemoveAuthCert('remove') }} className="dta-button dta-delete-button interim-recert-delete-button" style={{ float: "right" }}>Remove</button>
                        </div>
                    </div>
                </div>
                <p>
                    {
                        recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep,
                        ).authCertificationDesc
                    }                </p>

                <div>
                    <p>
                        {recertificationPageLabels(
                            language,
                            null,
                            languageConstants.authRep
                        ).authQuestiontwoQues}
                    </p>
                    <Radio
                        id="isAuthorizeCert"
                        name="isAuthorizeCert"
                        className="dta-form__option-list"
                        optionClass="dta-form__option dta-form__option--width-1-of-2"
                        options={recertificationPageLabels(
                            language,
                            null,
                            languageConstants.aboutMe,
                        ).optionYesNo}
                        required={TextProps.VALUE_TRUE}
                        value={this.state.isAuthorizeCert}
                        onChange={(value) => this.OnDataChange("isAuthorizeCert", value)}
                    />
                </div>
                {this.state.isShowauthCertForm && <div>
                    <div>
                        {this.state.errorData.snpFrstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px" }}>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).firstNameError
                            }
                        </span>}
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).firstName
                            }
                        </p>
                        <DTAInput
                            name={"certFirstName"}
                            className="dta-form__control"
                            // disabled
                            type="specialTextType"
                            maxLength={15}
                            value={this.state.snpFrstNam}
                            onChange={(value) => this.OnDataChange("snpFrstNam", value)}
                            required={TextProps.VALUE_TRUE}
                            error={this.state.errorData.snpFrstNamError}
                        />
                    </div>
                    <div>
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).middleName
                            }
                        </p>
                        <DTAInput
                            name={"certMiddleName"}
                            className="dta-form__control"
                            // disabled
                            type="specialTextType"
                            maxLength={15}
                            value={this.state.snpMdlNam}
                            onChange={(value) => this.OnDataChange("snpMdlNam", value)}
                            required={TextProps.VALUE_TRUE}
                        // error={this.props.flags.ebtFrstNamError}
                        />
                    </div>
                    <div className="pure-g">

                        <div className="pure-u-3-5">
                            {this.state.errorData.snpLstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px" }}>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).lastNameError
                                }
                            </span>}
                            <p>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).lastName
                                }
                            </p>
                            <DTAInput
                                name="certLastName"
                                className="dta-form__control"
                                required={TextProps.VALUE_TRUE}
                                // disabled
                                type="specialTextType"
                                maxLength={20}
                                value={this.state.snpLstNam}
                                onChange={(value) => this.OnDataChange("snpLstNam", value)}
                                error={this.state.errorData.snpLstNamError}

                            />
                        </div>
                        <div className="pure-u-2-5">
                            {this.state.errorData.snpLstNamError && <span className="dta-form__error-message" style={{ marginTop: "10px", visibility: "hidden" }}>Please enter suffix</span>}
                            <p>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).suffixLabel
                                }
                            </p>
                            <DtaSelect
                                name={"certSuffix"}
                                className="dta-units__item"
                                options={
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.aboutMe
                                    ).suffixOption
                                }
                                value={this.state.snpSuffix}
                                onChange={(value) => this.OnDataChange("snpSuffix", value)}
                                ariaLabel={"Suffix"}
                            />
                        </div>
                    </div>
                    <div>
                        {this.state.errorData.snpPhNumError && 
                        <span id="firstNameError" className="firstname dta-form__error-message">
                        {this.props.snpPhoneValid ?
                         mhProviderSNAPGAP(types.ENGLISH).phoneNumberLengthErrorMsg
                     :
                         mhProviderSNAPGAP(types.ENGLISH).phoneNumberErrorMsg
                     }
                     </span>
                        }
                        <p>
                            {
                                recertificationPageLabels(
                                    language,
                                    null,
                                    languageConstants.authRep
                                ).phoneNumber
                            }
                        </p>
                        <DTAInput
                            name={"certPhone"}
                            className="dta-form__control"
                            type="tel"
                            value={this.state.snpPhNum}
                            onChange={(value) => this.OnDataChange("snpPhNum", value)}
                            required={TextProps.VALUE_TRUE}
                            error={this.state.errorData.snpPhNumError}
                        />
                    </div>
                </div>}
                {(this.state.isAuthorizeEBT === recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                ).yesnoOptions[0] || this.state.isAuthorizeCert === recertificationPageLabels(
                    language,
                    null,
                    languageConstants.authRep
                ).yesnoOptions[0]) && <div>
                        <p>
                            {recertificationPageLabels(
                                language,
                                null,
                                languageConstants.authRep
                            ).iunderstandText}
                        </p>
                        {console.log("++err", this.state.errorData.agreeError)}
                        <div>
                            <Checkbox
                                className="dta-form__option-list"
                                optionClass="dta-form__option"
                                aria-labelledby="submit-1"
                                options={[
                                    "I agree to the terms and conditions"
                                ]}
                                ref="isAcceptTerms"
                                value={this.state.agree}
                                onChange={(value) => this.OnDataChange("agree", value)}
                                required={TextProps.VALUE_TRUE}
                                error={this.state.errorData.agreeError}
                            />
                        </div>
                        <div>
                            {this.state.errorData.signedNameError &&
                                <span className="dta-form__error-message" style={{ marginTop: "10px" }}>
                                    {this.props.signedNameValid ?

                                        mhProviderSNAPGAP(types.ENGLISH).signedNameNotMatch
                                        :
                                        mhProviderSNAPGAP(types.ENGLISH).signedNameErrorMsg
                                    }
                                </span>
                            }
                            <p>
                                {
                                    recertificationPageLabels(
                                        language,
                                        null,
                                        languageConstants.authRep
                                    ).signByText
                                }
                            </p>
                            <DTAInput
                                name={"signature"}
                                className="dta-form__control"
                                type="specialTextType"
                                maxLength={31}
                                value={this.state.signedName}
                                onChange={(value) => this.OnDataChange("signedName", value)}
                                required={TextProps.VALUE_TRUE}
                                error={this.state.errorData.signedNameError}
                            />
                        </div>
                    </div>}

            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRep);