import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import ProviderHome from "../provider/providerContainer";
import ConsumerHome from "../consumer/consumerContainer";
import GreeterScreening from "../greeterScreening/greeterScreeningContainer";
import MhProviderHome from "../mhProvider/mhProviderContainer";
import * as DtaRolesConstants from "../../utils/constants/rolesConstants";
import * as routesConstants from "../../utils/constants/routesConstants";
import * as TextProps from "../../utils/constants/text";

class RoleBasedAccessControl extends Component {
  render() {
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var path = window.location.href;
    var location = "";
    var roles = DtaRolesConstants.DTAROLES;
    var role = "";
    var accessableRole = "";
    var proctectedComponent = "";
    var changeRoleFlag = TextProps.VALUE_FALSE;
    var changeRole = "";
    // To access provider to consumer on my clients page, We are changing the changeRole variable
    if (sessionStorage.getItem("consumerAccess")) {
      changeRoleFlag = TextProps.VALUE_TRUE;
      changeRole = DtaRolesConstants.CONSUMER;
      // Provider not allowed to access consumer/myaccount page
      if (window.location.pathname === routesConstants.CONSUMER_MY_ACC_PATH) {
        return <Redirect to={routesConstants.HOME_PATH} />;
      }
    }
    if (
      path.includes(routesConstants.PROVIDER_PATH) &&
      changeRoleFlag === TextProps.VALUE_TRUE
    ) {
      changeRoleFlag = TextProps.VALUE_FALSE;
    }
    if (userInfo !== null && userInfo !== undefined) {
      var roleListCopy = userInfo.roleList;
      for (let roleList of roleListCopy) {
        let roleListObj = roleList;
        if (roleListObj !== undefined && roleListObj.roleName !== undefined) {
          if (roles.includes(roleListObj.roleName)) {
            accessableRole = roleListObj.roleName;
            accessableRole = changeRoleFlag ? changeRole : accessableRole;
            switch (accessableRole) {
              case DtaRolesConstants.CONSUMER:
              case DtaRolesConstants.USER:
                role = accessableRole;
                location = routesConstants.CONSUMER_PATH;
                proctectedComponent = ConsumerHome;
                break;
              case DtaRolesConstants.PROVIDER:
              case DtaRolesConstants.PROVIDER_SNAP_GAP:
              case DtaRolesConstants.PROVIDER_NO_PSI:
                role = accessableRole;
                location = routesConstants.PROVIDER_PATH;
                proctectedComponent = ProviderHome;
                break;
              case DtaRolesConstants.TAO_SCREEN_USER:
                role = accessableRole;
                location = routesConstants.GREETER_ROLE;
                proctectedComponent = GreeterScreening;
                break;
              case DtaRolesConstants.MH_SNAP_GAP:
                role = accessableRole;
                location = routesConstants.MH_PROVIDER_PATH;
                proctectedComponent = MhProviderHome;
                break;
              default:
                break;
            }
          }
        } else {
          return <Redirect to={routesConstants.HOME_PATH} />;
        }
      }
    } else {
      return <Redirect to={routesConstants.HOME_PATH} />;
    }
    return accessableRole === role && path.includes(location) ? (
      <Route path={location} component={proctectedComponent} />
    ) : (
      accessableRole === role && !path.includes(location) && (
        <Redirect to={routesConstants.HOME_PATH} />
      )
    );
  }
}

export default RoleBasedAccessControl;
