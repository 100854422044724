import * as TextProps from "../../utils/constants/text";

export function applyReducer(state = {}, action) {
  switch (action.type) {
    case "APPLY_ADD_ADD_CONTACT_INFO_SECTION": {
      const applyState = Object.assign({}, state);
      applyState.sections.filter((sec) => sec.id === "about-me")[0].subsections[0] = action.payload;
      return applyState;
    }
    // Assiging values for applySnapPagesAction from applysnapAction 
    case "APPLY_SNAP_PAGES_FULLFILLED": {
      var newState = Object.assign({}, state);
      newState.sections = action.payload;
      newState.activeSectionId = action.payload[0].activeSectionId;
      newState.snapSaveContinue = TextProps.VALUE_FALSE;
      newState.prevSections = {
        activeSectionId: "before-you-start",
        id: 0,
        date: 0,
        sections: action.payload,
      };
      return newState;
    }
    // Assiging values for applySnapPagesActionValidated from applysnapAction 
    case "APPLY_SNAP_PAGES_FULLFILLED_VALIDATED":{
      var newState = Object.assign({}, state);
      newState.sections = action.payload;
      newState.activeSectionId = action.payload[0].activeSectionId;
      newState.snapSaveContinue = TextProps.VALUE_FALSE;
      newState.prevSections = {
        activeSectionId: "before-you-start",
        id:  newState.prevSections ? newState.prevSections.id : 0,
        date: newState.prevSections ? newState.prevSections.date : 0,
        sections: action.payload,
      };
      return newState;
    }
    // Assiging values for applyAddressValidation from applysnapAction 
    case "APPLY_ADDRESS_VALIDATION":{
      var newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "about-me")[0] = action.payload;
      return newState;
    }
    // Assiging values for prePopulateSnapJson from applysnapAction 
    case "APPLY_SNAP_PAGES_RETR": {
      var newState = Object.assign({}, state);
      if (action.payload.data) {
        let rawApplication = JSON.parse(action.payload.data.application);
        newState.sections = rawApplication.application;
        newState.activeSectionId = rawApplication.activeId;
        newState.snapSaveContinue = TextProps.VALUE_FALSE;
        newState.prevSections = {
          activeSectionId: rawApplication.activeId,
          id: action.payload.data.id,
          date: action.payload.data.date,
          sections: rawApplication.application,
        };
      } else {
        newState.sections = action.payload;
        // newState.activeSectionId = action.payload[0].activeSectionId;
        // newState.dataPopulated = action.payload[0].dataPopulated;
      }
      return newState;
    }
    // Assiging values for applySnapSaveAction from applysnapAction 
    case "APPLY_SNAP_PAGES_SAVE": {
      var newState = Object.assign({}, state);
      newState.snapSaveContinue = action.payload;

      return newState;
    }
    case "ACES_BUTTON_CLICK": {
      var newState = Object.assign({}, state);
      newState.isAcesButtonClicked = action.payload.value;
      newState.acesRedictPath = action.payload.path
      return newState;
    }
    case "LANGUAGE_MODIFIED": {
      var newState = Object.assign({}, state);
      newState.islanguageChanged = action.payload.value;
      newState.isDelete = action.payload.isDelete,
      newState.isCancel = action.payload.isCancel,
      newState.allowLanguageChange = action.payload.allowLanguageChange

      return newState;
    }
    case "APPLY_WEB_APP_ID_FULLFILLED": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "before-you-start")[0].webAppId = action.payload;
      return newState;
    }
    case "APPEND_HOME_HOLD_DATA": {
      const newState = Object.assign({}, state);
      action.payload.forEach((element) => {
        element.questions.forEach((question) => {
          if (question.name === "firstName") {
            question.maxLength = 15;
          }
          if (question.name === "middleName") {
            question.maxLength = 15;
          }
          if (question.name === "lastName") {
            question.maxLength = 20;
          }
        });
      });
      newState.sections.filter((sec) => sec.id === "my-household")[0].subsections = action.payload;
      return newState;
    }
    case "APPEND_RESOURCES_DATA": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "resources")[0] = action.payload;
      return newState;
    }
    case "APPEND_INCOME_DATA": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "income")[0] = action.payload;
      return newState;
    }
    case "APPEND_EXPENSE_DATA": {
      const newState = Object.assign({}, state);

      newState.sections.filter((sec) => sec.id === "expenses")[0] = action.payload;
      return newState;
    }
    case "APPEND_LAST_KNOWN_LOCATION": {
      const newState = Object.assign({}, state);
      newState.sections.filter(sec => sec.id === "sign-and-submit")[0].partialSave = TextProps.VALUE_TRUE;
      newState.sections.filter(sec => sec.id === "sign-and-submit")[0].lastKnownLocation = action.payload;
      return newState;
    }
    case "NETWORK_FAILED_LOAD_DATA": {
      let stateObj = Object.assign({}, state);
      if (stateObj.sections.length > 0) {
        if (stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].errorRedirect !== undefined) {
          stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].errorRedirect = TextProps.VALUE_TRUE;
          return stateObj;
        }
      }
      return Object.assign({}, state);
    }
    case "DOWNLOAD_PDF_ACTION": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "before-you-start")[0].downloadPdfResult = action.payload.data.documentId;
      return newState;
    }
    // Assiging values for checkAddressValidation from applysnapAction 
    case "ADDRESS_VALIDATION": {
      const newState = Object.assign({}, state);
      newState.melissaAddress = action.payload.data;
      return newState;
    }

    case "POST_APPLY_INFO_DATA": {
      let stateObj = Object.assign({}, state);
      console.log("save data", action.payload.data);
      //INSTEAD, CHECKING AND SETTING ONY ID.
      if (action.payload.data.appId) {
        stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].webAppId = action.payload.data.appId;
      }
      if(Array.isArray(action.payload.data)){
        let snapObj = action.payload.data.filter(snap => snap.applicationType === TextProps.MATCH_SNAP)[0];
        if(snapObj){
          stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].snapSubmittedDetails = snapObj;
        }
        let tnafObj = action.payload.data.filter(tnaf => tnaf.applicationType === TextProps.MATCH_TANF)[0];
        if(tnafObj){
          stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].tnafSubmittedDetails = tnafObj;
        }
      }
      stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].isSubmitted = TextProps.VALUE_TRUE;
      stateObj.application = action.payload.data;
      if (action.payload.success === TextProps.VALUE_TRUE) {
        stateObj.applicationSubmited = TextProps.VALUE_TRUE;
      } else {
      }
      // stateObj.isLoading = TextProps.VALUE_FALSE
      return stateObj;
    }
    case "FILL_INPUT_VALUES_STATE": {
      return Object.assign({}, state);
    }
    case "APPEND_APPLY_ANSER": {
      const newState = Object.assign({}, state);
      newState.AnswerObject = action.payload[0];
      newState.dalReference = action.payload[1];
      return newState;
    }
    case "DOWNLOAD_PDF_APPLY_APPLICATION": {
      return Object.assign({}, state);
    }

    case "UPDATE_BACK_RESPONSE_TO_SECTION": {
      let stateObj = Object.assign({}, state);

      if (action.payload.data.questionName !== undefined) {
        stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].questionName = action.payload.data.questionName;
        stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].rowId = action.payload.data.rowId;
      }
      return stateObj;
    }
    case "UPDATE_WEB_APPLICATION_ID": {
      let stateObj = Object.assign({}, state);
      if (action.payload.data.webAppId !== undefined) {
        stateObj.sections.filter((sec) => sec.id === "before-you-start")[0].webAppId = action.payload.data.webAppId;
      }
      return stateObj;
    }
    // Assiging values for deleteWebApp from applysnapAction 
    case "DELETE_WEB_APP": {
      let stateObj = Object.assign({}, state);
      console.log("delete ", action.payload.data);
      if (action.payload.status === 200) {
        stateObj.sections = state.sections;
        stateObj.activeSectionId = state.sections.filter((sec) => sec.id === "before-you-start")[0].activeSectionId;
        stateObj.snapSaveContinue = TextProps.VALUE_FALSE;
        stateObj.prevSections = {
          activeSectionId: "before-you-start",
          id: 0,
          date: 0,
          sections: [],
        };
        return stateObj;
      }
      return state;
    }
    // Assiging values for applyAddressStorageAction from applysnapAction  
    case "APPLY_ADDRESS_STORAGE": {
      const newState = Object.assign({}, state);
      newState.residentailAddressStored = action.payload[0];
      newState.mailingAddressStored = action.payload[1];
      return newState;
    }
    case "APPEND_MEDICAL_EXPENSE_SECTIONS": {
      const newState = Object.assign({}, state);
      newState.sections.filter((sec) => sec.id === "expenses")[0].subsections[3].subsections[action.memberIndex].subsections[0].questions = action.payload;
      return newState;
    }
    case "APPEND_MEDICAL_COST_OTHER_QUESTION": {
      const newState = Object.assign({}, state);
      if(action.action === "remove"){
       newState.sections.filter((sec) => sec.id === "expenses")[0].subsections[3].subsections[action.memberIndex].questions.splice(1,2);
      }else{
        newState.sections.filter((sec) => sec.id === "expenses")[0].subsections[3].subsections[action.memberIndex].questions[1] = action.payload[0];
        newState.sections.filter((sec) => sec.id === "expenses")[0].subsections[3].subsections[action.memberIndex].questions[2] = action.payload[1];
      }
      return newState;
    }

    case "PREPOPULATE_DATA_SUCCESS":{
      const newState = Object.assign({}, state);
      let rawApplicationData = JSON.parse(action.payload.data.application)
      newState.applicantInfo = rawApplicationData;
      return newState;
    }

    case "PREPOPULATE_DATA_FAILURE":{
      const newState = Object.assign({}, state);
      newState.getDataError = true;
      return newState;
    }
    
    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      applyInit.inProgressSaveErrorState = TextProps.VALUE_FALSE;
      applyInit.inProgressSaveErrorMessage = "";
      return applyInit;
    }

    default:
      return Object.assign({}, state);
  }
}
