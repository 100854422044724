import React, { Component } from "react";
import { displayPDFData } from "../../../redux/recertification/recertificationActions";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { getAudit } from "../../../utils/constants/audit";
import { recertificationPageLabels } from "../recertificationText";
import * as languageConstants from "../../../utils/constants/constants";
import * as routeProps from "../../../utils/constants/routesConstants";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText"; 

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class SubmitSummary extends Component {
  constructor(props) {
    super(props);
    this.displayPDFData = this.displayPDFData.bind(this);
  }
  render() {
    return (
      <div className="pad-all--double">
        {this.props.recertType === "REEVAL" && (
          <div>
            <p>
              { recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).tanfSubmitpara[0]}
            <span><a onClick={() => this.displayPDFData("print")}>{recertificationPageLabels(
             language,
             null,
             languageConstants.signAndSubmitSummaryRecert
           ).tanfSubmitpara[1] }</a></span>
           <span> {recertificationPageLabels(
          language,
          null,
          languageConstants.signAndSubmitSummaryRecert
        ).tanfSubmitpara[2]} </span>
        <span><a onClick={this.displayPDFData}>{recertificationPageLabels(
           language,
           null,
           languageConstants.signAndSubmitSummaryRecert
         ).tanfSubmitpara[3]}</a></span>
         <span>{recertificationPageLabels(
         language,
         null,
         languageConstants.signAndSubmitSummaryRecert
       ).tanfSubmitpara[4]}</span>
        </p>
        {this.props.referenceNumber}
        <h3>
          {
             recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).tanfSubmithead
          }
        </h3>
        <p>{recertificationPageLabels(
         language,
         null,
         languageConstants.signAndSubmitSummaryRecert
       ).tanfSubmitparatwo[0]}{" "}<span><a onClick={() => window.location.href = "/consumer/documents"}>{recertificationPageLabels(
        language,
        null,
        languageConstants.signAndSubmitSummaryRecert
      ).tanfSubmitparatwo[1]}</a></span>{""}<span>{recertificationPageLabels(
        language,
        null,
        languageConstants.signAndSubmitSummaryRecert
      ).tanfSubmitparatwo[2]}</span>{" "}<span>{recertificationPageLabels(
        language,
        null,
        languageConstants.signAndSubmitSummaryRecert
      ).tanfSubmitparatwo[3]}</span>{" "}<span><a href="">{recertificationPageLabels(
        language,
        null,
        languageConstants.signAndSubmitSummaryRecert
      ).tanfSubmitparatwo[4]}</a></span> {" "} <span>{recertificationPageLabels(
        language,
        null,
        languageConstants.signAndSubmitSummaryRecert
      ).tanfSubmitparatwo[5]}</span></p>
        <p>{recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).tanfListHead}</p>
        <ul>
          {
             recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).tanfSubmitListing.map((list) => {
              return(
              <li>
                {list}
              </li>
              )
            })
          }
        </ul>
        <p>{recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).tanfFinalPara[0]}{" "}<span><a href="">{recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).tanfFinalPara[1]}</a></span></p>
          </div>
        )}
        <p>
          {this.props.recertType === "IR" ?
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).referenceNumberText
          : this.props.recertType !== "REEVAL" && recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).referenceNumberText
          }
        </p>
        {this.props.recertType !== "REEVAL" && (
        <p>
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).downloadBeforeText
          }{" "}
          <a onClick={this.displayPDFData}>
            {
              this.props.recertType === "AESAP" ? 
              recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).downloadAESAPLinkText :
              this.props.recertType === "IR" ?
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).downloadLinkText
              : this.props.recertType !== "REEVAL" && recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).downloadLinkText
            }
          </a>{" "}
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).downloadAfterText
          }
        </p>
        )}
        {this.props.recertType === "IR" ? 
        <div>
           <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).householdBoldMovedText
          }
        </b>
        <ul>
          <li>
          <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryList1
          }{" "}
          <a onClick={() => window.location.href = "/consumer/documents"}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).textHere
            }
          </a>{" "}
          </p>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
              ).summaryListExample
            }
          </p>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign: "center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample1
          }
          </p>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign: "center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample2
          }
          </p>
          </div>
          <div style={{display: "flex"}}>
            <div style={{minWidth: "30px", textAlign:"center"}}> - </div>
          <p style={{margin: 0}}>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryListExample3
          }
          </p>
          </div>
          </li>
          <li>
          <p>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).summaryList2
          }{" "}
          <a onClick={() => window.open("https://dhr.alabama.gov/county-office-contact/", '_blank')}>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).summaryCountryLive
            }
          </a>{" "}
          </p>
          </li>
        </ul>
        <br />
        <br />
        <button
              type="button"
              className="dta-button dta-button--primary dta-button--small"
              onClick={this.redirectToHomePage}
            >
             {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).summaryExit
            }
            </button>
        </div>
        :  this.props.recertType !== "REEVAL" &&
        (<div>
        <p>
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).earnedIncomeBold
            }
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).earnedIncomeNormal
          }
          <ul>
            {recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).earnedIncomeSteps.map((text) => {
              return <li>{text}</li>;
            })}
          </ul>
        </p>
        <p>
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).unearnedIncomeBold
            }
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).unearnedIncomeNormal
          }
          <ul>
            {recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).unearnedIncomeSteps.map((text) => {
              return <li>{text}</li>;
            })}
          </ul>
          <b>
            {
              recertificationPageLabels(
                language,
                null,
                languageConstants.signAndSubmitSummaryRecert
              ).uneanrednoteText
            }
            :
          </b>{" "}
          {
            recertificationPageLabels(
              language,
              null,
              languageConstants.signAndSubmitSummaryRecert
            ).uneanrednoteDetailText
          }
        </p>
        </div> ) 
        }
      </div>
    );
  }

  displayPDFData(action = "download") {
    var agencyId = this.props.agencyID;
    var auditData = getAudit();
    this.props.displayPDFData(
      this.props.docReferenceNumber,
      auditData,
      agencyId,
      action
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayPDFData: displayPDFData,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitSummary);
