import _ from "lodash";
import * as TextProps from "../../utils/constants/text";

// Status codes used in the below recursion
// ----------------------------------------

const YES_FOUND = "yesFound", // recursion has found the current id and we can short circuit
  NOT_FOUND = "notFound", // recursion has NOT found the current id and we need to continue looping
  // recursion has found current id and we need to go to the next section at the one-shallower level
  YES_FOUND_GO_NEXT = "goNext",
  // recursion has found current id and we need to go to the previous section at the one-shallower level
  YES_FOUND_GO_PREVIOUS = "goPrevious";

// Exports
// -------

/**
 * A sandbox-specific function for obtaining the next section id to navigate to
 * @param  {string} currentId id of the currently active section
 * @param  {object} sections  the entire sections data object
 * @return {string}           if of the next section to navigate to
 */
export function getNextSection(currentId, sections) {
  // array of indicies in order from shallowest to deepest nested level in the data structure
  // For example, the value at the 0th position corresponds to the index for the top-level section,
  // the value at the 1th position corresponds to the index for the 2nd-level section and so on
  const indices = [];
  // recursively look through sections and their subsections, modifying the passed-in index variables
  _.some(_.range(sections.length), (sectionIndex) => {
    const status = getNextSectionHelper(
      0,
      indices,
      currentId,
      sections,
      sectionIndex
    );
    if (status === YES_FOUND_GO_NEXT || status === YES_FOUND)
      return TextProps.VALUE_TRUE; // short circuit if found
    return TextProps.VALUE_FALSE; // continue looping if not found
  });
  return resolveIndicesToId(indices, sections);
}

/**
 * A sandbox-specific function for obtaining the previous section id to navigate to
 * @param  {string} currentId id of the currently active section
 * @param  {object} sections  the entire sections data object
 * @return {string}           if of the previous section to navigate to
 */
export function getPreviousSection(currentId, sections) {
  // array of indicies in order from shallowest to deepest nested level in the data structure
  // For example, the value at the 0th position corresponds to the index for the top-level section,
  // the value at the 1th position corresponds to the index for the 2nd-level section and so on
  const indices = [];
  // recursively look through sections and their subsections, modifying the passed-in index variables
  _.some(_.range(sections.length), (sectionIndex) => {
    const status = getPrevSectionHelper(
      0,
      indices,
      currentId,
      sections,
      sectionIndex
    );
    if (status === YES_FOUND_GO_PREVIOUS || status === YES_FOUND)
      return TextProps.VALUE_TRUE; // short circuit if found
    return TextProps.VALUE_FALSE; // continue looping if not found
  });
  return resolveIndicesToId(indices, sections);
}

export function getSection(sectionId, sections) {
  let targetSection = {};

  _.some(sections, (sectionObj) => {
    const found = getSectionHelper(sectionId, sectionObj);
    if (found) {
      targetSection = found;
      return TextProps.VALUE_TRUE; // short circuit
    } else {
      return TextProps.VALUE_FALSE; // keep on searching
    }
  });
  return targetSection;
}

function getSectionHelper(sectionId, sectionObj) {
  if (sectionObj.id === sectionId) {
    return sectionObj;
  } else if (sectionObj.subsections) {
    const numSub = sectionObj.subsections.length;
    for (let i = 0; i < numSub; ++i) {
      const found = getSectionHelper(
        sectionId,
        sectionObj.subsections[i]
      );
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// Next helpers
// ------------

function getNextSectionHelper(
  currentLevel,
  indices,
  currentId,
  sections,
  sectionIndex
) {
  const sectionObj = sections[sectionIndex],
    numSections = sections.length;
  // if currently on this section
  if (sectionObj.id === currentId) {
    if (sectionObj.subsections && sectionObj.subsections.length>0) {
      // go to first subsection
      indices[currentLevel] = sectionIndex;
      indices[currentLevel + 1] = 0;
      return YES_FOUND;
    } else {
      // go to next section
      return doGoNext(currentLevel, indices, numSections, sectionIndex);
    }
  } else if (sectionObj.subsections && sectionObj.subsections.length) {
    let currentStatus;
    _.some(_.range(sectionObj.subsections.length), (subIndex) => {
      currentStatus = getNextSectionHelper(
        currentLevel + 1,
        indices,
        currentId,
        sectionObj.subsections,
        subIndex
      );
      if (currentStatus === YES_FOUND_GO_NEXT) {
        currentStatus = doGoNext(
          currentLevel,
          indices,
          numSections,
          sectionIndex
        );
      } else if (currentStatus === YES_FOUND) {
        // as we are unwinding this recursion, need to set the current index at this current level
        // in order to start building up breadcrumbs in the indices array
        indices[currentLevel] = sectionIndex;
      }
      return shouldStatusShortCircuit(currentStatus);
    });
    return currentStatus;
  } else {
    // return that this recursive loop has NOT found a match
    return NOT_FOUND;
  }
}

function doGoNext(currentLevel, indices, numSections, sectionIndex) {
  if (numSections > sectionIndex + 1) {
    // next is the next section in this list if we are NOT the last item in the list
    indices[currentLevel] = sectionIndex + 1;
    return YES_FOUND;
  } else {
    // we need to go to the next section that is one level of recursion shallower
    return YES_FOUND_GO_NEXT;
  }
}

// Previous helpers
// ----------------

function getPrevSectionHelper(
  currentLevel,
  indices,
  currentId,
  sections,
  sectionIndex
) {
  const sectionObj = sections[sectionIndex];
  // if currently on this section
  if (sectionObj.id === currentId) {
    return doGoPrevious(currentLevel, indices, sections, sectionIndex);
  } else if (sectionObj.subsections) {
    let currentStatus;
    _.some(_.range(sectionObj.subsections.length), (subIndex) => {
      currentStatus = getPrevSectionHelper(
        currentLevel + 1,
        indices,
        currentId,
        sectionObj.subsections,
        subIndex
      );
      if (
        currentStatus === YES_FOUND ||
        currentStatus === YES_FOUND_GO_PREVIOUS
      ) {
        // FOR YES_FOUND: as we are unwinding this recursion, need to set the current index at this current level
        //   in order to start building up breadcrumbs in the indices array
        // FOR YES_FOUND_GO_PREVIOUS: go to the index page on this level
        indices[currentLevel] = sectionIndex;
        currentStatus = YES_FOUND;
      }
      return shouldStatusShortCircuit(currentStatus);
    });
    return currentStatus;
  } else {
    // return that this recursive loop has NOT found a match
    return NOT_FOUND;
  }
}

function doGoPrevious(currentLevel, indices, sections, sectionIndex) {
  if (sectionIndex > 0) {
    const prevSectionIndex = sectionIndex - 1,
      prevSection = sections[prevSectionIndex];
    // go to the previous section on this level
    indices[currentLevel] = prevSectionIndex;
    // ALSO need to go to last most nested subsection of the previous section, if any
    findLastSubsectionOfSection(currentLevel, indices, prevSection);
    return YES_FOUND;
  } else {
    // we need to go to the next section that is one level of recursion shallower
    return YES_FOUND_GO_PREVIOUS;
  }
}

function findLastSubsectionOfSection(currentLevel, indices, sectionObj) {
  let deeperLevel = currentLevel + 1,
    thisObj = sectionObj;
  while (thisObj) {
    if (thisObj.subsections && thisObj.subsections.length) {
      const lastSubIndex = thisObj.subsections.length - 1;
      indices[deeperLevel] = lastSubIndex;
      // preparing to go one level deeper
      deeperLevel = deeperLevel + 1;
      thisObj = thisObj.subsections[lastSubIndex];
    } else {
      thisObj = null; // short circuit if no more subsections
    }
  }
}

// Shared helpers
// --------------

function resolveIndicesToId(indices, sections) {
  const numIndices = indices.length;
  let currentLevelList = sections,
    foundId;
  _.forEach(indices, (index, indexInList) => {
    if (numIndices > indexInList + 1) {
      // continue iterating
      currentLevelList = currentLevelList[index].subsections;
    } else {
      foundId = currentLevelList.length && currentLevelList[index].id;
    }
  });
  return foundId;
}

function shouldStatusShortCircuit(status) {
  switch (status) {
    case YES_FOUND:
      return TextProps.VALUE_TRUE; // short circuit
    case YES_FOUND_GO_PREVIOUS:
      return TextProps.VALUE_TRUE; // short circuit
    case YES_FOUND_GO_NEXT:
      return TextProps.VALUE_TRUE; // short circuit
    case NOT_FOUND:
      return TextProps.VALUE_FALSE;
    default:
      throw Error(`${status} is not a valid status code.`);
  }
}

//Other Helpers


export function updateCurrentSectionCompletionStatus(sectionId, sections, isCompleted = false) {
  let tickCurrentSection = {};
  _.some(sections, (sectionObj) => {
    const found = getSectionHelper(sectionId, sectionObj);
    if (found) {
      tickCurrentSection = found;
      if (sectionObj.subsections) {
        const numSub = sectionObj.subsections.length;
        for (let i = 0; i < numSub; ++i) {
          if (sectionObj.subsections[i].id === sectionId) {
            tickCurrentSection.completed = isCompleted;
          }
        }
        if (sectionObj.id === sectionId) {
          tickCurrentSection.completed = isCompleted;
          return tickCurrentSection;
        }
      } else {
        tickCurrentSection.completed = isCompleted;
        return tickCurrentSection;
      }
    }
  });
}