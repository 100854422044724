import axios from "axios";
import sections from "../../pages/recertInterimReport/recertInterimReportMapData";
import { getAudit } from "../../utils/constants/audit";
import * as TextProps from "../../utils/constants/text";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;
var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  applyIRSnapPagesAction: "IR_APPLY_SNAP_PAGES_FULLFILLED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  appendIRHouseHold: "APPEND_IR_HOME_HOLD_DATA",
  appendIRIncome: "APPEND_IR_INCOME_DATA",
  appendUIIncome: "APPEND_UI_INCOME_DATA",
  appendIRExpense: "APPEND_IR_EXPENSE_DATA",
  appendShelterData: "APPEND_SHELTER_DATA",
  appendShelterSubSectionQuestions:"APPEND_SHELTER_SUBSECTION_QUESTIONS",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  downloadIRPdfAction: "DOWNLOAD_PDF_IR_APPLICATION",
  setContactInfo: "IR_SET_CONTACT_INFO",
  setChildSupportData: "IR_SET_CHILD_SUPPORT",
  setHouseholdMembers: "IR_SET_HOUSEHOLD_MEMBERS",
  addStudentToList: "IR_ADD_STUDENT_TO_LIST",
  addContactInfoToSections: "IR_ADD_ADD_CONTACT_INFO_SECTION",
  addHouseholdInfoToSections: "IR_ADD_ADD_HOUSEHOLD_INFO_SECTION",
  setDataChangeStatus: "IR_ADD_DATA_CHANGE_STATUS",
  //Earned income
  setGrossEarnedIncomeStatus: "SET_GROSS_EARNED_INCOME_STATUS",
  setEarnedIncomeStatus: "SET_EARNED_INCOME_STATUS",
  setUnearnedIncomeStatus: "SET_UNEARNED_INCOME_STATUS",
  setChildSupportChanged: "SET_CHILD_SUPPORT_CHANGED",
  setInterimReportResponse: "SET_INTERIM_REPORT_RESPONSE",
  setInterimSaveReportResponse:"SET_INTERIM_REPORT_SAVE_RESPONSE",
  postInterimDataFulfilled: "POST_INTERIM_DATA_FULFILLED",
  setChildSupportStatus: "SET_CHILD_SUPPORT_OPTIONS",
  setShelterUtilityStatus: "SET_SHELTER_UTILITY_STATUS",
  setShelterData: "SET_SHELTER_DATA",
  saveInterimApplication: "SAVE_INTERIM_APPLICATION",
  setObligationChildSupportChanged: "SET_OBLIGATION_CHILD_SUPPORT_STATUS",
  setCourtOrderedChildSupportChanged: "SET_COURT_ORDERED_CHILD_SUPPORT_STATUS",
  interimSaveAction: "INERIM_SAVE_ACTION",
  appendChildSupportSections: "APPEND_CHILD_SUPPORT_SECTIONS",
  appendInterimDataForSummary: "APPEND_IR_DATA_FOR_SUMMARY",
  appendIRShelterQuestions: "APPEND_IR_SHELTER_QUESTIONS",
  appendChildSupportAnswer: "APPEND_CHILD_SUPPORT_ANSWER",
  appendShelterUpdatedData: "APPEND_SHELTER_UPDATED_DATA",
  appendUtilityLiheapData: "APPEND_UTILITY_LIHEAP_DATA",

  enableNavigationForSections:"ENABLE_NAVIGATION_FOR_SECTIONS",
  applyAddressStorageAction: "APPLY_ADDRESS_STRG",
  setRadioButtonFlags:"SET_RADIO_BUTTON_FLAGS"

};

export function applyIRSanpQuestionsSections() {
  return (dispatch) => {
    return dispatch(applyIRSnapSections(sections));
  };
}

export function snapRefreshQuestionsSections(originalSections) {
  return (dispatch) => {
    return dispatch(applyIRSnapSections(originalSections));
  };
}

function applyIRSnapSections(sectionsVal) {
  return {
    type: actionTypes.applyIRSnapPagesAction,
    payload: sectionsVal,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}
function questionsInputValuesAction(sectionsValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsValue,
  };
}

//Household Action
export function appendIRHouseHoldMembers(subMembers) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(houseHoldSuccess(subMembers));
  };
}

function houseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendIRHouseHold,
    payload: houseHold,
  };
}

//Appened Income
export function appendIRIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(incomeSuccess(income));
  };
}

function incomeSuccess(income) {
  return {
    type: actionTypes.appendIRIncome,
    payload: income,
  };
}

//Appened Income
export function appendUIIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(uiIncomeSuccess(income));
  };
}

function uiIncomeSuccess(income) {
  return {
    type: actionTypes.appendUIIncome,
    payload: income,
  };
}

//Appened Expense
export function appendIRExpenseDetails(expense) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(expenseSuccess(expense));
  };
}

function expenseSuccess(expense) {
  return {
    type: actionTypes.appendIRExpense,
    payload: expense,
  };
}

export function clearHouseHoldMembers(
  houseHoldSubMemberTemplate,
  houseHoldSubDetailTemplate
) {
  return (dispatch) => {
    let detailTemplate = [];
    detailTemplate.push(houseHoldSubDetailTemplate);
    return dispatch(houseHoldSuccess(detailTemplate));
  };
}

//Clearing Income Template
export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(incomeSuccess(incomeTemplate));
  };
}

//Clearing Income Template
export function clearMemberUIIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(uiIncomeSuccess(incomeTemplate));
  };
}

export function updateHouseholdTextChanges(householdDetails) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(appendIRHouseHoldTextChanges(householdDetails));
  };
}

function appendIRHouseHoldTextChanges(householdDetails) {
  return {
    type: actionTypes.appendIRHouseHold,
    payload: householdDetails,
  };
}

export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_APPLY_ANSER",
      payload: data,
    });
  };
}


//Appened Income
export function appendShelterSections(shelter) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(shelterSuccess(shelter));
  };
}

function shelterSuccess(shelter) {
  return {
    type: actionTypes.appendShelterData,
    payload: shelter,
  };
}

export function postInterimReportData(answerObject, auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/interimreport";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, answerObject, TigerOptions)
        .then((response) => {
          dispatch(submitInterimReportDataAction(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function submitInterimReportDataAction(response) {
  return {
    type: actionTypes.postInterimDataFulfilled,
    payload: response,
  };
}

//
export function downloadIRPDFData(sectionsObj) {
  return (dispatch) => {
    axios
      .post(
        BaseUrl + "/interimReport/downloadPDFData",
        sectionsObj,
        HttpOptions
      )
      .then(function (data) {
        if (data !== undefined)
          toShowPDFinWindow(data, sectionsObj[0].webAppId);
      });
  };
}
/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, webAppId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    window.navigator.msSaveOrOpenBlob(blob, "SnapApply_" + webAppId + ".pdf");
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export function setContactInfo(contactInfoObj) {
  return (dispatch) => {
    dispatch(setContactInfoObjIR(contactInfoObj));
  };
}

function setContactInfoObjIR(contactInfoObj) {
  return {
    type: actionTypes.setContactInfo,
    payload: contactInfoObj,
  };
}

export function setChildSupportData(childObj) {
  return (dispatch) => {
    dispatch(setChildSupportObjIR(childObj));
  };
}

function setChildSupportObjIR(childObj) {
  return {
    type: actionTypes.setChildSupportData,
    payload: childObj,
  };
}

export function setHouseholdMembers(houseHoldObj) {
  return (dispatch) => {
    dispatch(setHouseholdMembersListIR(houseHoldObj));
  };
}

function setHouseholdMembersListIR(houseHoldObj) {
  return {
    type: actionTypes.setHouseholdMembers,
    payload: houseHoldObj,
  };
}

export function setShelterData(shelterObj) {
  return (dispatch) => {
    dispatch(setShelterDataSuccess(shelterObj));
  };
}

function setShelterDataSuccess(shelterObj) {
  return {
    type: actionTypes.setShelterData,
    payload: shelterObj,
  };
}

export function addStudentToList(studentObj) {
  return (dispatch) => {
    dispatch(addStudentObjIR(studentObj));
  };
}

function addStudentObjIR(studentObj) {
  return {
    type: actionTypes.addStudentToList,
    payload: studentObj,
  };
}

export function addContactInfoSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addContactInfoToSectionsIR(subSectionObj));
  };
}

function addContactInfoToSectionsIR(subSectionObj) {
  return {
    type: actionTypes.addContactInfoToSections,
    payload: subSectionObj,
  };
}

export function addHouseholdAddSectionToList(houseHoldAddObj) {
  return (dispatch) => {
    dispatch(addHouseholdToSectionsIR(houseHoldAddObj));
  };
}

function addHouseholdToSectionsIR(houseHoldAddObj) {
  return {
    type: actionTypes.addHouseholdInfoToSections,
    payload: houseHoldAddObj,
  };
}

export function setGrossEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setGrossEarnedIncomeStatus(status));
  };
}

function setGrossEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setGrossEarnedIncomeStatus,
    payload: status,
  };
}

export function setEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setEarnedIncomeStatus(status));
  };
}

function setEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setEarnedIncomeStatus,
    payload: status,
  };
}


export function setObligationChildSupportChangeStatus(status) {
  return (dispatch) => {
    dispatch(setObligationChangeStatus(status));
  };
}

function setObligationChangeStatus(status) {
  return {
    type: actionTypes.setObligationChildSupportChanged,
    payload: status,
  };
}

export function setCourtOrderedChildSupportChangeStatus(status) {
  return (dispatch) => {
    dispatch(setCourtOrderedChangeStatus(status));
  };
}

function setCourtOrderedChangeStatus(status) {
  return {
    type: actionTypes.setCourtOrderedChildSupportChanged,
    payload: status,
  };
}

export function setUnearnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setUnearnedIncomeStatus(status));
  };
}

function setUnearnedIncomeStatus(status) {
  return {
    type: actionTypes.setUnearnedIncomeStatus,
    payload: status,
  };
}

export function setDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatusIR(status, index));
  };
}

function setChangeStatusIR(status, index) {
  return {
    type: actionTypes.setDataChangeStatus,
    payload: status,
    index: index,
  };
}

export function setInterimReportResponseObj(payload) {
  return (dispatch) => {
    dispatch(setIRResponseObjToState(payload));
  };
}

function setIRResponseObjToState(payload) {
  return {
    type: actionTypes.setInterimReportResponse,
    payload: payload,
  };
}

function setIRSaveResponse(payload) {
  return (dispatch) => {
    dispatch(
      {
        type: actionTypes.setInterimSaveReportResponse,
        payload: payload,
      }
    );
  };
}

export function displayPDFData(noticeId, auditData, agencyId) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(
        config.TigerContextURL + "/notices/" + agencyId + "/" + noticeId,
        TigerOptions
      )
      .then(checkStatus)
      .then(function (data) {
        if (data !== undefined) {
          let stream = {
            data: {
              base64: data.data.stream,
            },
          };
          toShowPDFinWindow(stream, noticeId);
        }
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function setShelterUtilityChangeStatus(status) {
  return (dispatch) => {
    dispatch(setShelterUtilityStatus(status));
  };
}

function setShelterUtilityStatus(status) {
  return {
    type: actionTypes.setShelterUtilityStatus,
    payload: status,
  };
}


//Appened Income
export function appendShelterFilteredQuestions(shelterQuestions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(ShelterFilteredQuestionsSuccess(shelterQuestions));
  };
}

function ShelterFilteredQuestionsSuccess(shelterQuestions) {
  return {
    type: actionTypes.appendShelterSubSectionQuestions,
    payload: shelterQuestions,
  };
}

export function setChildSupportOptions(childOptions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
      type: actionTypes.setChildSupportStatus,
      payload: childOptions,
    });
  };
}


export function setShelterOptions(shelterOptions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(setShelterOptionsSuccess(shelterOptions));
  };
}

function setShelterOptionsSuccess(data) {
  return {
    type: actionTypes.setShelterUtilityStatus,
    payload: data,
  };
}

export function saveInterimApplication(interimData,language) {
  const audit = getAudit();
  let url = config.TigerContextURL + "/saveInterimReport";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };

        axios.post(url, interimData, options)
        .then(function (response) {
          resolve(response);
          if (response.data.id != 0) {
            dispatch(setIRSaveResponse(response.data)) 
          } 
        })
        .catch(function (error) {
          console.log("API ERROR")
          reject(error);
          // dispatch({
          //   type: actionTypes.interimSaveAction,
          //   payload: TextProps.VALUE_FALSE,
          // }) 
        });
    });
  };
}



export function deleteInterimApplication(id,ts) {
  const audit = getAudit();
  let url = config.TigerContextURL + "/deleteirapplication";
  let interimDeleteData = {
    webAppTypeCode:"SNAPIR",
    id:id,
    createTs:ts
  };

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
        axios.put(url, interimDeleteData, options)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.log("DELETE API ERROR")
          reject(error);
        });
    });
  };
}



// export function getInterimSavedDataForUser(status) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       const audit = getAudit();
//       let url = config.TigerContextURL + "/interimreport";;
//       const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
//       // Axios Http Options
//       const Headers = {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           // 'locale': language,
//           audit: JSON.stringify(audit),
//           Authorization: oauthToken.token_type + " " + oauthToken.access_token,
//         },
//         mode: "no-cors",
//         "Access-Control-Allow-Origin": config.allowed_domain,
//       };
//       if (status === TextProps.VALUE_APP_INPROGRESS || status === TextProps.VALUE_APP_EXIT) {
//         url = config.TigerContextURL+"/inprogressApps";
//         axios
//           .get(url, Headers)
//           .then(function (response) {
//             let statusCheck = response.data[0];
//             if (status === TextProps.VALUE_APP_INPROGRESS && statusCheck.appId != 0) {
//               resolve(response);
//               return ;
//               // return dispatch(populateSnapApplyInfo(statusCheck));

//             }
//             if(status === TextProps.VALUE_APP_EXIT){
//               resolve(statusCheck);
//             }
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       }else {
//         axios
//           .get(url, Headers)
//           .then(function (response) {
//             resolve(response);
//             if (response.data.id != 0) {
//               console.log("Get saved data",response)
//               return dispatch(
//                 {
//                   type: actionTypes.saveInterimApplication,
//                   payload: response,
//                 }
//               );
//             }
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       }
//     });
//   };
// }

export function onPrimaryActionSaveClickedAction(saveFlag) {
	return dispatch => {
		return dispatch(
      {
        type: actionTypes.interimSaveAction,
        payload: saveFlag,
      }
    )
	}
}



//Appened child support 
export function appendChildSupportDetails(childSupport) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch( {
      type: actionTypes.appendChildSupportSections,
      payload: childSupport,
    });
  };
}


//appendShelterQuestions

export function appendIRShelterQuestions(section) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
      type: actionTypes.appendIRShelterQuestions,
      payload: section,
    });
  };
}

//update child support answer

export function appendChildSupportAnswer(answer) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.appendChildSupportAnswer,
      payload: answer,
    });
  };
}


export function appendShelterUpdatedData(shelter) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
        type: actionTypes.appendShelterUpdatedData,
        payload: shelter,
    });
  };
}

export function appendUtilityLiheapData(data) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
        type: actionTypes.appendUtilityLiheapData,
        payload: data,
    });
  };
}

export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}

//Append Interim data for summary
export function appendInterimDataForSummary(sectionData) {
  return (dispatch) => {
    return dispatch(appendInterimSectionData(sectionData));
  };
}

function appendInterimSectionData(sectionData) {
  return {
    type: actionTypes.appendInterimDataForSummary,
    payload: sectionData,
  };
}

export function enableNavigationForSections(sectionData) {
  return (dispatch) => {
    return dispatch({
        type: actionTypes.enableNavigationForSections,
        payload: sectionData,
    });
  };
}
export function applyAddressStorageAPI(responseData) {
  return {
    type: actionTypes.applyAddressStorageAction,
    payload: responseData,
  };
}
export function addressValidation(addressObj, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/address/validate";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, addressObj, options)
        .then(function (data) {
          // dispatch(checkAddressValidation(data));
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

export function setRadioButtonFlags(flags) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
        type: actionTypes.setRadioButtonFlags,
        payload: flags
    });
  };
}