/*
  Constants shared between ALL question types. See the `types.js` file containing
  all of the possible question types for full documentation of what properties
  are allowed for each question type in the questions data file.
 */

// Required
export const NAME = "name";
export const LABEL = "label";
export const LABEL_OBJ_BEFORE = "textBefore";
export const LABEL_OBJ_AFTER = "textAfter";
export const LABEL_OBJ_TRIGGER = "triggerText";
export const LABEL_OBJ_HELP = "helpText";
export const TYPE = "type";
export const CHECKED = "isChecked";
export const MAX_LENGTH = "maxLength";
export const MIN_LENGTH = "minLength";
export const IS_RESIDENTIAL_ADDRESS = 'isResidentialAddress';
// Optional
export const LABEL_HAS_MARKUP = "labelHasMarkup";
export const INLINE_HELP = "inlineHelp";
export const INLINE_HELP_HAS_MARKUP = "inlineHelpHasMarkup";
export const REQUIRED = "isRequired";
export const ID = "id";

// handling error messages on each fields and send back to state.questions
export const ERROR = "error";
export const ERROR_MESSAGE = "errorMessage";
export const BENEFIT_TARGET = "target";
export const ERROR_MESSAGE_NUM = "errorMessageNum";
export const ERROR_MESSAGE_LENGTH = "errorMessageLength";
export const ERROR_MESSAGE_FOR_CHAR = "citiErrmsg";
export const ERROR_MESSAGE_FOR_COUNTY = "countyErrmsg";
export const ERROR_MESSAGE_FOR_ADDRESS = "addressErrmsg";
export const ERROR_MESSAGE_FOR_HOME_ADDRESS = "homeAddressErrmsg";
export const ERROR_MESSAGE_FOR_MAILING_ADDRESS = "mailingAddressErrmsg";
export const ERROR_MESSAGE_FOR_ZIPCODE = "zipCodeErrmsg";
export const ERROR_MESSAGE_FOR_AL_ZIPCODE = "zipCodeAlErrmsg";
export const ERROR_MESSAGE_FOR_MAS_ZIPCODE = "masZipCodeErrmsg";
export const ERROR_MESSAGE_FREQ = "errorMessageFreq";
export const ERROR_MESSAGE_INCM = "errorMessageIncm";
export const ERROR_MESSAGE_LAST_EMPY = "errorlastEmployment";
export const INPUT_LABEL = "inputLabel";
export const ERROR_MESSAGE_PO_BOX = "poBoxErrmsg";
export const NUMBER_TEXTBOX_LABEL = "numberTxBoxLabel";

export const ADD_DELETE_AND_DONE= "addDeleteAndDone";
export const SUB_LABEL = "subLabel";
