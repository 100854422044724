import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/rootReducer";
import AlertAccordion from "../../utils/components/alertAccordion";
import { push } from "react-router-redux";
import SNAPApplicationEN from "../../utils/resources/pdfs/SNAP-ApplicationEN.pdf"
import SNAPApplicationES from "../../utils/resources/pdfs/SNAP-ApplicationES.pdf"
import TANFApplicationEN from "../../utils/resources/pdfs/TANF-ApplicationEN.pdf"
import TANFApplicationES from "../../utils/resources/pdfs/TANF-ApplicationES.pdf"
import Icon from "../../utils/components/dtaIcon";
import LanguageSelect from "../../redux/language/languageSelect";
import { onLogOut } from "../../utils/helper/accountNavigationActions";
import DTAFooter from "../../utils/components/dtaFooter";
import DTADropdown from "../../utils/components/dtaDropdown";
import DTALoginModal from "../../utils/components/modal/dtaLoginModal";
import Slider from "react-slick";
//azure
import textSpanish from "../../utils/appText/appSpanishText.json";
import textEnglish from "../../utils/appText/appEnglishText.json";
import { withMsal } from "@azure/msal-react";
import { loginRequest, signUp,registerUser } from "config/appConfig";
import { azureLogin } from "../../redux/user/loginActions";
import { onAzureLogOut } from "../../utils/helper/accountNavigationActions";
import { getCookie, setCookie } from "../../utils/helper/cookie";

import axios from 'axios';
import Bowser from "bowser";
import appConfig from '../../utils/constants/appConfigConstants';

import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import * as TextProps from "../../utils/constants/text";

import { getPEBTBannerAlerts } from "../../redux/home/homeActions";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as routeProps from "../../utils/constants/routesConstants";
import * as routesConstants from "../../utils/constants/routesConstants";
import * as config from "../../utils/constants/appConfigConstants";
import { communicationAssistanceData } from "../../utils/appText/communicationAssistanceConstants";
import * as logos from "../../utils/constants/constants";
// import * as azureLoginFlag from '../../utils/constants/appConfigConstants';
import { history } from "../../redux/configureStore";
import DSNAPApplicationPdf from '../../utils/resources/pdfs/DSNAPApplication.pdf';
import DSNAPApplicationSpanish from '../../utils/resources/pdfs/DSNAPApplication-Spanish.pdf';
import { languageChanged } from "../../redux/applySnap/applySnapActions";
import Modal from "../../utils/components/modal/dtaModal"; 
import { beforeYoustartMlanguageLabels } from "../applySnap/applySnapText";
import DTAModal from "../../utils/components/modal/dtaModal";
import { addStringIf } from "../../utils/components/utilities/controls";
import DOMPurify from "dompurify";


//start google analytics code
var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HT = "ht";

//end google analytics code
const processItems = [
  {
    icon: "send-app",
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: "upload-documents",
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: "ebt-card",
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: "groceries",
    arrow: TextProps.VALUE_FALSE,
  },
];

const processItemsTafdcEaedc = [
  {
    icon: <img alt="Submit Application" src={logos.list_icon} />,
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: <img alt="Interview" src={logos.interview_icon} />,
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: <img alt="Provide Verifications" src={logos.docs_icon} />,
    arrow: TextProps.VALUE_TRUE,
  },
  {
    icon: <img alt="Receive eligibility" src={logos.groceries_icon} />,
    arrow: TextProps.VALUE_FALSE,
  },
];

var alertLabel;

class MainHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: TextProps.VALUE_FALSE,
      isInfoAlertOpen: TextProps.VALUE_FALSE,
      showAlert: TextProps.VALUE_FALSE,
      bannerAlertLabelArr: [],
      forgotFullSuccess: false,
      forgotUserName: {flag: false, username: null, error: false, success: false },
      isLoginError: false,
      isUserSignUp: false,
      activeLang: {},
      freeCommunication: false,
      selectedLang: '',
      showDefaultSection: true,
      isLoginFailedDuetoUnlinkAccount: false,
      isAnonymousModeModalOpen: false,
      helpVideoModalOpen: TextProps.VALUE_FALSE,
      selectedHelpValue: "",
    };
    this.toggleAlert = this.toggleAlert.bind(this);
    this.toggleInfoAlert = this.toggleInfoAlert.bind(this);
    this.redirectScreening = this.redirectScreening.bind(this);
    this.redirectApply = this.redirectApply.bind(this);
    this.redirectPEBT = this.redirectPEBT.bind(this);
    this.redirectTAFDC = this.redirectTAFDC.bind(this);
    this.redirectEAEDC = this.redirectEAEDC.bind(this);
  }
  componentWillMount = () => {
   if (localStorage.getItem("selectedLanguage") != null) {
     setCookie("selectedLanguage", localStorage.getItem("selectedLanguage"));
   }
    let systemInfo = {
      ip: "",
      source: appConfig.source,
      deviceId: ""
    }
    var browser = Bowser.getParser(navigator.userAgent);
    if (browser) {
      systemInfo.deviceId = browser.getPlatform().type + " - " + browser.getBrowser().name + " " + browser.getBrowser().version + " - " + browser.getOS().name + " " + browser.getOS().version || '';
    }
    axios.get('https://api.ipify.org', appConfig.HttpOptions)
      .then((response) => {
        systemInfo.ip = response.data;
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
        this.props.getPEBTBannerAlerts();
      }).catch((error) => {
        systemInfo.ip = ''
        sessionStorage.setItem('systemInfo', JSON.stringify(systemInfo));
        this.props.getPEBTBannerAlerts();
      });
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.getPEBTBannerAlerts();
    }, 1200);

    if (getCookie("isLoginFailed") === "true") {
      this.setState({ isLoginError: true });
    }
    if (getCookie("redirectLogin") === "true") {
      this.redirectToAzureLogin();
    }
    if (getCookie("isLoginFailedDueToUnlinkAc") === "true") {
      this.setState({ isLoginFailedDuetoUnlinkAccount: true });
    }
    
    if (getCookie("isUserSignedUp") === "true") {
      this.setState({ isUserSignUp: true });
    }
    if (getCookie("isUserResetPassword") === "true") {
      this.setState({ forgotFullSuccess: true });
    }
    if (getCookie("isForgotUserName") === "true") {
      if (getCookie("usernameRecoveryError") === "true") {
        this.setState({ forgotUserName: {flag: true, username: null, error: true } });
      } else {
        let name = getCookie("RecoveredUserName");
        this.setState({ forgotUserName: {flag: true, username: name, error: false } });
      }      
    }

    const callbackId = this.props.msalContext.instance.addEventCallback(
      async (event) => {
        //console.log("MSAL EVENT",JSON.stringify(event));
        if (event.eventType === "msal:loginSuccess" || event.eventType === "msal:acquireTokenSuccess") {
          // ForgotPassword Event handler
          if (event.payload && event.payload.idTokenClaims.newUser) {
            this.props.onAzureLogOut(this.props.msalContext.instance);
            setCookie("isUserSignedUp", true);
          }
          if (event.payload && event.payload.idTokenClaims.isForgotPassword) {
            this.props.onAzureLogOut(this.props.msalContext.instance);
            setCookie("isUserResetPassword", true);
          } else if (event.payload && event.payload.idTokenClaims.extension_isForgotUsername) {
              let claims = event.payload.idTokenClaims;
              this.props.onAzureLogOut(this.props.msalContext.instance);
              setCookie("isForgotUserName", true);
              setCookie("usernameRecoveryError", claims.isApiResponseEmpty || 
              ([claims.extension_SecurityAnwerIsNotSame1, claims.extension_SecurityAnwerIsNotSame2, claims.extension_SecurityAnwerIsNotSame3].filter(Boolean).length >= 2));
              setCookie("RecoveredUserName", claims.email);
          }
          else {
            const myAccount =
              await this.props.msalContext.instance.getAccountByHomeId(
                event.payload.account.homeAccountId
              );
            await this.props.msalContext.instance.setActiveAccount(myAccount);
            window.sessionStorage.setItem(
              "home_account",
              JSON.stringify(myAccount)
            );
            window.sessionStorage.setItem(
              "user_name",
              event.payload.account.idTokenClaims.email
            );
            window.sessionStorage.setItem("id_token", event.payload.idToken);
            const oauth = {
              token_type: "Bearer",
              access_token: event.payload.idToken,
            };
            window.sessionStorage.setItem("oauthToken", JSON.stringify(oauth));
            this.props.azureLogin(this.props.msalContext.instance);
          }
        }
        if (event.eventType === "msal:logoutSuccess") {
          sessionStorage.clear();
          localStorage.clear();
        }
        if (
          event.eventType === "msal:loginFailure" ||
          event.eventType === "msal:acquireTokenFailure"
        ) {
          setCookie("isLoginFailed", true);
          this.props.msalContext.instance.logout();
        }
      },
      (error) => {
        console.log("error", error);
      }
    );

    this.setState({ callbackId: callbackId });

    if (window.location.pathname === "/home") {
      window.location.href = "/";
    }

    const isLogout = window.sessionStorage.getItem("is_Logout");
    if (isLogout === TextProps.VALUE_STR_TRUE) {
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.home.pEbtBanner !== undefined &&
      nextProps.home.pEbtBanner !== null
    ) {
      let pEbtBannerData = JSON.parse(sessionStorage.getItem("PEBTBanner"));
      let language;
      let selectedLanguage = localStorage.getItem("selectedLanguage");

      if (selectedLanguage === EN) {
        language = "EN";
      } else if (selectedLanguage === ES) {
        language = "ES";
      }
       else if (selectedLanguage === PT) {
        language = 'PT'
      } else if (selectedLanguage === ZH) {
        language = 'ZH'
      } else if (selectedLanguage === VI) {
        language = 'VI'
      }else if (selectedLanguage === HT) {
        language = 'HT'
      }
      else {
        language = "EN";
      }
      let bannerAlertLabelArr = [];
      pEbtBannerData.forEach((obj) => {
        if (
          obj.languageCD === language &&
          obj.subject !== "dtaconnect.eohhs.mass.gov/PEBT PEBT Meals Banner"
        ) {
          this.setState({ bannerAlertLabelArr: bannerAlertLabelArr.push(obj) });
        }
        alertLabel = bannerAlertLabelArr.map((objVal, index) => {
          return (
            <div className="banner-container">
              <div
                className={`${
                  objVal.subject === "DSNAP Application Banner"
                    ? "banner-content"
                    : "banner-without-button"
                }`}
              >
                <p
                  className="pebt-header"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(objVal.desc) }}
                ></p>
              </div>
              {objVal.subject === "DSNAP Application Banner" && (
                <div >
                  <div className="home-margin">
                  <button
                    onClick={() => this.applyHandler("DSNAP")}
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                    style={{ width: "100%" }}
                  >
                    {this.props.language.home.applyForDsnapButton}
                  </button>
                  </div>
                <div className="dsnap-banner-margin"> 
                  <Link
                    to={DSNAPApplicationPdf}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                    style={{ width: "100%" }}
                  >
                    {this.props.language.home.bannerButton}
                  </Link>
                </div>
                <div className="dsnap-banner-margin">
                  <Link
                    to={DSNAPApplicationSpanish}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                  >
                    {this.props.language.home.bannerButtonSpanish}
                  </Link>
                </div>
                </div>
              )}
            </div>
          );
        });
      });
    }
    //azure
    if (nextProps.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
      this.setState({
        loginSuccessful: TextProps.VALUE_TRUE,
        loginErrorState: TextProps.VALUE_FALSE,
      });
      this.routeUser(nextProps);
    }
    if (
      nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
      nextProps.apply.islanguageChanged === true
    ) {
      // if (this.state.sectionDataUpdated) {
      this.props.languageChanged({ allowLanguageChange: true })
    }
  }

  //azure
  routeUser = (nextProps) => {
    let providerFlag, mhProviderFlag;
    this.setState(
      {
        loginSucessDetails: nextProps.consumer.loginDetails,
      },
      () => {
        this.state.loginSucessDetails.roleList.forEach((role) => {
          if (
            role.roleName === roleProps.USER ||
            role.roleName === roleProps.CONSUMER
          ) {
            providerFlag = TextProps.VALUE_FALSE;
            sessionStorage.setItem("isProvider", false);
          } else if (
            role.roleName === roleProps.PROVIDER ||
            role.roleName === roleProps.PROVIDER_ROLE_SPVUSR ||
            role.roleName === roleProps.PROVIDER_ROLE_DCPVSG
          ) {
            providerFlag = TextProps.VALUE_TRUE;
            sessionStorage.setItem("isProvider", true);
          } else if (role.roleName === roleProps.MH_PROVIDER_ROLE) {
            mhProviderFlag = TextProps.VALUE_TRUE;
            sessionStorage.setItem("isProvider", true);
          } else if (role.roleName === roleProps.TAO_SCREEN_USER) {
            this.setState({ loading: TextProps.VALUE_FALSE });
            window.location.assign(routeProps.GREETER_ROLE);
          }
        });
        if (providerFlag === TextProps.VALUE_TRUE) {
          this.props.gotoProvider(this.state.loginSucessDetails, nextProps);
        } else if (providerFlag === TextProps.VALUE_FALSE) {
          this.props.gotoConsumerPage(this.state.loginSucessDetails, nextProps);
        } else if (mhProviderFlag === TextProps.VALUE_TRUE) {
          this.props.gotoMhProvider(this.state.loginSucessDetails, nextProps);
        }
      }
    );
  };

  //azure
  getUserSelectedLanguage = () => {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    let language = "en";
    if (selectedLanguage === EN) {
      language = "en";
    } else if (selectedLanguage === ES) {
      language = "es";
    }
    // else if (selectedLanguage === PT) {
    //   language = 'pt-br'
    // } else if (selectedLanguage === ZH) {
    //   language = 'zh-hans'
    // } else if (selectedLanguage === VI) {
    //   language = 'vi'
    // }else if (selectedLanguage === HT) {
    //   language = 'fr'
    // }
    else {
      language = "en";
    }
    return language;
  };

  onSignUp = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const signUpReq = {
      ...signUp,
      extraQueryParameters: {
        ...signUp.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns.loginRedirect(signUpReq);
  };

  onCreateAccount = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const signUpReq = {
      ...registerUser,
      extraQueryParameters: {
        ...registerUser.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns.loginRedirect(signUpReq);
  };

  _handleAzureLogin = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const loginReq = {
      ...loginRequest,
      extraQueryParameters: {
        ...loginRequest.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns
      .loginRedirect(loginReq)
      .then()
      .catch(() => {
      });
  };
  redirectToAzureLogin = () => {
    sessionStorage.clear();
    this.clearCookies();
    this._handleAzureLogin(this.props.msalContext.instance);
  };

  clearCookies = () => {
    setCookie("isLoginFailed", "");
    setCookie("isUserSignedUp", "");
    setCookie("isUserResetPassword", "");
    setCookie("isForgotUserName", false);
    setCookie("usernameRecoveryError", false);
    setCookie("RecoveredUserName", "");
    setCookie("isLoginFailedDueToUnlinkAc","");
    setCookie("redirectLogin", false);
    this.setState({ isLoginError: false });
    this.setState({ isUserSignUp: false });
    this.setState({ forgotFullSuccess: false });
    this.setState({ forgotUserName: {flag: false, username: null, error: false, success: false }});
    this.setState({ forgotUserName: {} });
    this.setState({isLoginFailedDuetoUnlinkAccount: false})
  };

  toggleAlert() {
    this.setState({ isAlertOpen: !this.state.isAlertOpen });
  }

  toggleInfoAlert() {
    this.setState({ isInfoAlertOpen: !this.state.isInfoAlertOpen });
  }

  renderProcessItem = (item) => {
    if (Object.keys(this.props.language.home).length > 0) {
      for (var i = 0; i < processItems.length; i++) {
        if (processItems[i].icon === item.icon) {
          item.title = this.props.language.home.processItem[i].title;
          item.description =
            this.props.language.home.processItem[i].description;
        }
      }
    }
    return (
      <div className="pure-u-1 pure-u-lg-1-4" key={item.icon}>
        <div className="homepage-process__step">
          <div className="homepage-process__icons">
            <Icon name={item.icon} />
            {item.arrow ? (
              <Icon name="arrow-long" className="hidden-touch" />
            ) : null}
          </div>
          <p className="homepage-process__text">
            <span className="homepage-process__title">{item.title}</span>
            {item.description}
          </p>
        </div>
      </div>
    );
  };

  renderTAFDCEAEDCProcessItem = (item) => {
    if (Object.keys(this.props.language.home).length > 0) {
      for (var i = 0; i < processItemsTafdcEaedc.length; i++) {
        if (processItemsTafdcEaedc[i].icon === item.icon) {
          item.title = this.props.language.home.processItemTafdcEaedc[i].title;
          item.description =
            this.props.language.home.processItemTafdcEaedc[i].description;
        }
      }
    }
    return (
      <div className="pure-u-1 pure-u-lg-1-4" key={item.icon}>
        <div className="homepage-process__step">
          <div className="homepage-process__icons process_icons">
            {typeof item.icon === "string" ? (
              <Icon name={item.icon} />
            ) : (
              item.icon
            )}
            {item.arrow ? (
              <img alt="Arrow" src={logos.arrow_icon} className="hidden_sm" />
            ) : null}
          </div>
          <p className="homepage-process__text">
            <span className="homepage-process__title">{item.title}</span>
            {item.description}
          </p>
        </div>
      </div>
    );
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  handleActiveLangChange = (lang) => {
    if (lang === this.state.selectedLang) {
      this.setState({selectedLang: '', showDefaultSection: true, freeCommunication: false});
    } else if(lang === "freeComm") {
      this.setState({freeCommunication: true, selectedLang: lang, showDefaultSection: false,})
    } else {
      this.setState({selectedLang: lang, showDefaultSection: false });
    }
  };
  applyHandler = (appType) => {
    let loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (!loginUserInfo && appType === "SNAP") {
      this.setState({isAnonymousModeModalOpen : true });
      // sessionStorage.setItem("applicationInanonymousMode", true);
      sessionStorage.setItem("applicationType", appType);
    }
    if (loginUserInfo && appType === "SNAP") {
      sessionStorage.setItem("applicationType", appType);
      history.push(routeProps.APPLY_PATH);
    } else if (appType === "TANF") {
      sessionStorage.setItem("applicationType", appType);
      this._handleAzureLogin(this.props.msalContext.instance);
    }
    if(appType === "DSNAP"){
      sessionStorage.setItem("applicationType", appType);
      window.location.assign(routeProps.APPLY_DSNAP);
    }
    // else {
    //   if(config.azureLoginFlag){
    //   this._handleAzureLogin(this.props.msalContext.instance)
    //   }else{
    //    history.push(routeProps.LOGIN_PATH);
    //  }
    //  }
  };
  //Function to handle logout action
  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
  };

  openHelpVideoModal = () => {
    this.setState({
      helpVideoModalOpen: TextProps.VALUE_TRUE,
    })
  }
  closeHelpVideoModal = () => {
    this.setState({
      helpVideoModalOpen: TextProps.VALUE_FALSE,
    })
  }
  selectedHelpCategory = (e) => {
    this.setState({
      selectedHelpValue: e.target.value
    })
  }
  // Example Link = "https://www.youtube.com/embed/4JDu69Jy41Y?si=gGDfB_eSZPRuU2C6"
  returnHelpVideoLink = () => {
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[0]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[1]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[2]) {
      return ""
    }
    if(this.state.selectedHelpValue === textSpanish.home.helpVideoDropdown[3]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[0]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[1]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[2]) {
      return ""
    }
    if(this.state.selectedHelpValue === textEnglish.home.helpVideoDropdown[3]) {
      return ""
    }
  }

  render() {
    const { home, pebtHome } = this.props.language;
    const { common, resetpassword, login, register, forgotusername } =
      this.props.language.security;
    const { isAlertOpen, isInfoAlertOpen } = this.state;
    //azure
    const msalInstance = this.props.msalContext.instance;
    const accounts = this.props.msalContext.accounts;
    const loginProgress = this.props.msalContext.inProgress;
    var userInfo, alertStatus;
    var providerRoleFlag = TextProps.VALUE_FALSE;
    var mhProviderRoleFlag = TextProps.VALUE_FALSE;
    var greeterFlag = TextProps.VALUE_FALSE;
    let dropDownLink = null;
    let greeterLink = null;
    var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    
    if(this.state.selectedHelpValue === "") {
      this.setState({
        selectedHelpValue: home.helpVideoDropdown && home.helpVideoDropdown[0]
      })
    }

    if (loginUserInfo !== null && loginUserInfo !== undefined) {
      userInfo = loginUserInfo;
    } else {
      if (
        this.props.consumer.mfaDetails.userDetails !== undefined &&
        this.props.consumer.mfaDetails.userDetails !== null
      ) {
        userInfo = this.props.consumer.mfaDetails.userDetails;
      }
    }
    if (
      this.props.consumer.loginDetails !== undefined &&
      this.props.consumer.loginDetails.roleList !== undefined
    ) {
      var roleList = this.props.consumer.loginDetails.roleList;
      for (var i = 0; i <= roleList.length; i++) {
        let roleListObj = roleList[i];
        if (roleListObj !== undefined && roleListObj.roleName !== undefined) {
          let Name = roleListObj.roleName;
          if (
            Name === roleProps.PROVIDER ||
            Name === roleProps.PROVIDER_ROLE_SPVUSR ||
            Name === roleProps.PROVIDER_ROLE_DCPVSG
          ) {
            providerRoleFlag = TextProps.VALUE_TRUE;
          } else if (Name === roleProps.MH_PROVIDER_ROLE) {
            mhProviderRoleFlag = TextProps.VALUE_TRUE;
          }
        }
      }
    } else {
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        const loginUserRoleList = loginUserInfo.roleList;
        if (loginUserRoleList !== null && loginUserRoleList !== undefined) {
          for (let j = 0; j <= loginUserRoleList.length; j++) {
            let roleListObj = loginUserRoleList[j];
            if (
              roleListObj !== undefined &&
              roleListObj.roleName !== undefined
            ) {
              let Name = roleListObj.roleName;
              if (
                Name === roleProps.PROVIDER ||
                Name === roleProps.PROVIDER_ROLE_SPVUSR ||
                Name === roleProps.PROVIDER_ROLE_DCPVSG
              ) {
                providerRoleFlag = TextProps.VALUE_TRUE;
              } else if (Name === roleProps.MH_PROVIDER_ROLE) {
                mhProviderRoleFlag = TextProps.VALUE_TRUE;
              } else if (Name === roleProps.GREETER_ROLE) {
                greeterFlag = TextProps.VALUE_TRUE;
              }
            }
          }
        }
      }
    }
    var loginFlag = TextProps.VALUE_FALSE;
    if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
      loginFlag = TextProps.VALUE_TRUE;
    } else {
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        if (loginUserInfo.email !== undefined && loginUserInfo.email !== "") {
          loginFlag = TextProps.VALUE_TRUE;
        }
      }
    }
    if (isAlertOpen) {
      alertStatus = home.alertHideStatus;
    } else {
      alertStatus = home.alertShowStatus;
    }
    if (
      providerRoleFlag === TextProps.VALUE_FALSE &&
      !greeterFlag &&
      mhProviderRoleFlag === TextProps.VALUE_FALSE
    ) {
      dropDownLink = (
        <li className="pad-all--half">
          <Link
            to={"/"}
            className="display-block"
            onClick={this._handleApplyGoBack.bind(this)}
          >
            {common.home}
          </Link>
        </li>
      );
    } else if (
      providerRoleFlag === TextProps.VALUE_TRUE &&
      mhProviderRoleFlag === TextProps.VALUE_FALSE
    ) {
      dropDownLink = (
        <li className="pad-all--half">
          <Link
            to={"/"}
            className="display-block"
            onClick={this._handleApplyGoBack.bind(this)}
          >
            {common.home}
          </Link>
        </li>
      );
    } else if (
      providerRoleFlag === TextProps.VALUE_FALSE &&
      mhProviderRoleFlag === TextProps.VALUE_TRUE
    ) {
      dropDownLink = (
        <li className="pad-all--half">
          <Link
            to={"/"}
            className="display-block"
            onClick={this._handleApplyGoBack.bind(this)}
          >
            {common.home}
          </Link>
        </li>
      );
    }
    if (greeterFlag === TextProps.VALUE_TRUE) {
      greeterLink = (
        <li className="pad-all--half">
          <Link
            to={"/"}
            className="display-block"
            onClick={this._handleApplyGoBack.bind(this)}
          >
            {common.home}
          </Link>
        </li>
      );
    }
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      nextArrow: <img src={logos.right_icon} alt="right" />,
      prevArrow: <img src={logos.left_icon} alt="left" />,
      adaptiveHeight: true,
    };

    const { LocalCountryOffices } = communicationAssistanceData;

    return (
      <div className="home page-wrapper" style={{ overflowX: "hidden" }}>
        <header>
          <div className="main-blue-banner">
            <div className="blue-banner">
              <div className="blue-banner-header max-width">
                <div
                  onClick={() => this.handleActiveLangChange(this.state.selectedLang)}
                  style={{ cursor: "pointer", marginRight: "1rem" }}
                >
                  <img src={logos.logo_icon} alt="logo" />
                </div>
                <div>
                <p className="blue-banner-heading">{home.greenBannerHeader} </p>
                <div className="blue-banner-header max-width">
                <p className="blue-banner-header-content">
                  {home.greenBannerContent1}
                  <Icon name="lock" />
                  {home.greenBannerContent2}
                </p>
              </div>
                </div>
              </div>
            </div>
          </div>
          <div className="free-comm-banner-main">
          <div className="free-comm-banner-inner max-width">
            <h3
              onClick={() => this.handleActiveLangChange('freeComm')}
              style={{ cursor: "pointer" }}
            >
              {home.whiteBannerText}
            </h3>
            <div>
              {communicationAssistanceData.LangData.map((lang, i) => {
                return (
                  <a
                    key={i}
                    style={{ fontSize: "14px" }}
                    onClick={() => this.handleActiveLangChange(lang)}
                  >
                    {lang.LangId !== 0 && lang.LangTye}
                  </a>
                );
              })}
              <a
                onClick={() => this.handleActiveLangChange('freeComm')}
                style={{ cursor: "pointer" }}
              >
                {home.DeafText}
              </a>
            </div>
          </div>
          {this.state.freeCommunication && this.state.selectedLang === "freeComm" ? (
            <div className="fca max-width">
              <h1 className="fca_heading">{home.whiteBannerText}</h1>
              <div className="fca_contents">
                {communicationAssistanceData.LangData.map((item) => {
                  return (
                    <div className="fca_content">
                      <h3>{item.LangLabel}</h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.LangContent }}
                      ></p>
                    </div>
                  );
                })}
              </div>
              <div className="ca_content">
                <p
                  className="ca_description"
                  dangerouslySetInnerHTML={{
                    __html: this.state.activeLang.LangContent,
                  }}
                ></p>
                <div className="ca_contacts" id="contacts">
                  <h3 className="ca_contacts_heading">
                    {home.countryOfficeTitle}
                  </h3>
                  <div className="ca_contacts_wrapper">
                    {LocalCountryOffices.map((office) => {
                      return (
                        <div className="ca_contact">
                          <span>{office.LocalCountryName}</span>
                          <span>{office.LocalCountryPhone}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ): Object.keys(this.state.selectedLang).length > 0 && (
            <div className="ca_content">
              <p
                className="ca_description"
                dangerouslySetInnerHTML={{
                  __html: this.state.selectedLang.LangContent,
                }}
              ></p>
              <div className="ca_contacts" id="contacts">
                <h3 className="ca_contacts_heading">{home.countryOfficeTitle}</h3>
                <div className="ca_contacts_wrapper">
                  {LocalCountryOffices.map((office) => {
                    return (
                      <div className="ca_contact">
                        <span>{office.LocalCountryName}</span>
                        <span>{office.LocalCountryPhone}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          </div>
          {this.state.bannerAlertLabelArr.length !== 0 && this.state.selectedLang === '' ? (
            <div>
              <div className="covid-banner-outer">
                <div className="covid-banner-inner max-width">
                  <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-18-24 pure-u-lg-18-24 pure-u-xl-18-24 alert-and-text-wrap">
                    <div className="alert-icon-container">
                      <img src={logos.bell_icon} alt="alert" />
                    </div>
                    <div className="banner-text-wrap bell-border">
                      <p className="display-alert-content">
                        {home.redBannerText}
                      </p>
                    </div>
                  </div>
                  <div className="pure-u-24-24 pure-u-sm-24-24 pure-u-md-6-24 pure-u-lg-6-24 pure-u-xl-6-24 alert-toggle-wrap">
                    <a
                      className="alert-toggle-link"
                      onClick={this.toggleAlert}
                    >{`${alertStatus}`}</a>
                  </div>
                </div>
              </div>

              {this.state.isAlertOpen == true ? (
                <AlertAccordion
                  texts={[alertLabel]}
                  isAlertOpen={this.state.isAlertOpen}
                />
              ) : null}
            </div>
          ) : null}
        </header>
        {this.state.showDefaultSection && (
          <div>
            <nav className="nav homepage__nav">
              <div className="navbar">
                <div className="max-width">
                  <a
                    href="/"
                    className="navbar__logo text--center display-block"
                  >
                    <img src={logos.alabama} alt="ACES DHR Self-Service" />
                    <span className="sr-only">ACES DHR Self-Service</span>
                  </a>
                  <h1 className="nav__title hidden-touch">
                    {home.acesDhrTitle}
                  </h1>
                  <ul className="nav-list navbar__nav-list">
                  <li className="nav-list__item1">{home.translatePageText}</li>
                    <li className="nav-list__item">
                      <LanguageSelect />
                    </li>
                    <li className="nav-list__item">
                      <button
                        onClick={() => this.openHelpVideoModal()}
                        className="custom-help-btn dta-button dta-button--outline-secondary dta-button--medium"
                        style={{ width: "100%" }}
                      >
                        {home.helpBtntext}
                      </button>
                    </li>
                    <li className="nav-list__item">
                      {/* This should link to log in page */}
                      {loginFlag === TextProps.VALUE_TRUE ? (
                        <div className="dta-nav__options-account height">
                          <div className="dta-nav__options-account">
                            <div className="nav__dropdown_home">
                              {common.hi}&nbsp;
                              <DTADropdown
                                buttonText={userInfo.firstName}
                                buttonClass="dta-button dta-button--applylink"
                                menuClass="list--bordered"
                                caret={TextProps.VALUE_TRUE}
                                caretClass="color-primary"
                              >
                                {dropDownLink}
                                {greeterLink}
                                <li className="pad-all--half border-top">
                                  <React.Fragment>
                                    {config.azureLoginFlag ? (
                                      <Link
                                        to="#"
                                        className="display-block"
                                        onClick={() =>
                                          this.props.onAzureLogOut(msalInstance)
                                        }
                                      >
                                        {common.logout}
                                      </Link>
                                    ) : (
                                      <Link
                                        to="#"
                                        className="display-block"
                                        onClick={this._handleApplyLogoutSession.bind(
                                          this
                                        )}
                                      >
                                        {common.logout}
                                      </Link>
                                    )}
                                  </React.Fragment>
                                </li>
                              </DTADropdown>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              onClick={() =>
                                this._handleAzureLogin(msalInstance)
                              }
                              className="custom-login-btn dta-button dta-button--primary"
                            >
                              {home.loginText}
                            </Link>
                          ) : (
                            <Link
                              to={routeProps.LOGIN_PATH}
                              className="custom-login-btn dta-button dta-button--outline-secondary"
                            >
                              {home.loginText}
                            </Link>
                          )}
                        </React.Fragment>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <main className="main">
              <section className="homepage__hero-container">
                <section className="homepage__hero-container">
                  <Slider {...settings}>
                    <div>
                      <img className="carouselImg" alt="Banner Image 1" src={logos.carousel1_img} />
                    </div>
                    <div>
                      <img className="carouselImg" alt="Banner Image 2" src={logos.carousel2_img} />
                    </div>
                    <div>
                      <img className="carouselImg" alt="Banner Image 3" src={logos.carousel3_img} />
                    </div>
                    <div>
                      <img className="carouselImg" alt="Banner Image 4" src={logos.carousel4_img} />
                    </div>
                  </Slider>
                </section>
              </section>
              <div className="pure-g max-width responsive_heads c_acc_content_wrapper">
                <p>{home.createAccountText}</p>
                <div>
                  <React.Fragment>
                    {config.azureLoginFlag ? (
                      <Link
                        to="#"
                        onClick={() => this.onCreateAccount(msalInstance)}
                        className="custom-login-btn dta-button dta-button--outline-secondary"
                        style={{ width: "100%" }}
                      >
                        {home.createAccountBtnText}
                      </Link>
                    ) : (
                      <Link
                        to={routeProps.SIGNUP_PATH}
                        className="custom-login-btn dta-button dta-button--outline-secondary"
                        style={{ width: "100%" }}
                      >
                        {home.createAccountBtnText}
                      </Link>
                    )}
                  </React.Fragment>
                </div>
              </div>
              <div className="pure-g max-width responsive_heads">
                <div className="pure-u-1 pure-u-lg-1-3">
                  <div className="max-width--half pad-all--double">
                    <h2>{home.whatSnapHeading}</h2>
                    <p>
                      {home.whatSnapText} {home.heroBannerTAFDCClick}
                      <a
                        href={logos.FOOD_ASSISTANCE}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        {home.heroBannerTAFDCLink}
                      </a>
                      {home.heroBannerTAFDCFind}
                    </p>
                  </div>
                </div>
                <div className="pure-u-1 pure-u-lg-1-3">
                  <div className="max-width--half pad-all--double">
                    <h2>{home.snapScreenerHeading}</h2>
                    <p><b>{home.snapScreenerText1}</b></p>
                    {/* This should link to apply page */}
                    <Link
                      to={routeProps.SCREENING_PATH}
                      className="dta-button dta-button--outline-secondary"
                    >
                      {home.screeningButton}
                    </Link>
                    <p style={{ color: "#000", fontSize: "16px" }}>
                      {home.snapScreenerText2}
                    </p>
                  </div>
                </div>
                <div className="pure-u-1 pure-u-lg-1-3">
                  <div className="max-width--half pad-all--double">
                    <h2>{home.whatTANFHeading}</h2>
                    <p>
                      {home.whatTANFText}
                      {home.heroBannerEAEDCClick}
                      <a
                        href={logos.FAMILY_ASSISTANCE}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        {home.heroBannerEAEDCLink}
                      </a>{" "}
                      {home.heroBannerEAEDCFind}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={
                  selectedLanguage === ZH
                    ? "homepage-process pad-all max-width pad-snap-works-chinese"
                    : "homepage-process pad-all max-width"
                }
              >
                <h2 className="homepage-process__header">
                  {home.sanpTANFProcessHeading}
                </h2>
                <div className="homepage-process__container">
                  <div className="homepage-process__steps">
                    <div className="pure-g responsive_process">
                      {processItemsTafdcEaedc.map((item) =>
                        this.renderTAFDCEAEDCProcessItem(item)
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="btns-homepage homepage-process pad-all max-width">
                <div>
                  <h2>{home.applySnapHeading}</h2>
                  <p>{home.applySnapText}</p>              
                </div>
                <div>
                  <h2>{home.applyTANFHeading}</h2>
                  <p> {home.applyTANFText} </p>
                  <p> {home.applyTANFADDITIONALText} </p>
                </div>
              </div>
              <div className="btns-homepage homepage-process pad-all max-width">
              <div>
                  <button
                    onClick={() => this.applyHandler("SNAP")}
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                    style={{ width: "100%" }}
                  >
                    {home.applyNowButton}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.applyHandler("TANF")}
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                    style={{ width: "100%" }}
                  >
                    {home.applyTAFDCButton}
                  </button>
                </div>
              </div>
              <div className="btns-homepage homepage-process pad-all max-width">
                <div>
                  <Link
                    to={SNAPApplicationEN}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                  >
                    {this.props.language.home.printSnapApplicationEN}
                  </Link>
                  <Link
                    to={SNAPApplicationES}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                  >
                    {this.props.language.home.printSnapApplicationES}
                  </Link>
                </div>
                <div>
                  <Link
                    to={TANFApplicationEN}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                  >
                    {this.props.language.home.printTanfApplicationEN}
                  </Link>
                  <Link
                    to={TANFApplicationES}
                    target="_blank"
                    className="custom-login-btn dta-button dta-button--outline-secondary"
                  >
                    {this.props.language.home.printTanfApplicationES}
                  </Link>
                </div>
              </div>
              <div className="pure-g homepage__additional-questions">
                <div className="pure-u-1 pure-u-lg-1-2">
                  <div className="max-width--half pad-all--double">
                    <h2>{home.needFoodSoooner}</h2>
                    <img alt="Feeding Alabama" src={logos.feeding_img} />
                    <p>
                      {home.needFoodSubHeading}
                      <a
                        style={{ textDecoration: "underline" }}
                        href={logos.NEED_HELP}
                        target="_blank"
                      >
                        {home.clickHere}
                      </a>
                      {home.addInfo}
                    </p>
                  </div>
                </div>
                <div className="pure-u-1 pure-u-lg-1-2">
                  <div className="max-width--half pad-all--double">
                    <h2>{home.otherServiceHeading}</h2>
                    <p>
                      <br /> {home.otherServiceCatalogText}{" "}
                      <a
                        href="https://dhr.alabama.gov/"
                        target={"_blank"}
                        rel="norefferer"
                      >
                        {home.otherServiceCatalogLink}
                      </a>
                      .
                    </p>
                    <a
                      href="https://www.211connectsalabama.org/"
                      target={"_blank"}
                      rel="norefferer"
                    >
                      {home.nationalLinkText}
                    </a>
                    <br/>
                    <br/>
                    <a
                      href="https://www.alabamaworks.alabama.gov/"
                      target={"_blank"}
                      rel="norefferer"
                    >
                      {home.AlabamaWorks}
                    </a>
                    <br/>
                    <br/>
                    <a
                      href="https://dhr.alabama.gov/nondiscrimination-statements"
                      target={"_blank"}
                      rel="norefferer"
                    >
                      {home.nondiscriminationAnchorText}
                    </a>
                  </div>
                </div>
              </div>
              <DTALoginModal
                isOpen={this.state.forgotFullSuccess}
                modalClass="login-modal"
                titleText="Forget Password"
              >
                <div className="dta-modal__body pad-all dtaRegisterModel">
                  <div className="dta-modal__header">
                    <h1>{resetpassword.signupbutton}</h1>
                    <button
                      className="dta-button--minor close-modal"
                      onClick={() => this.clearCookies()}
                    >
                      <Icon name="close" label="Close modal" />
                    </button>
                  </div>
                  <p>{resetpassword.passwordChanged}</p>
                  <button
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    onClick={() => this.redirectToAzureLogin()}
                  >
                    {resetpassword.login}
                  </button>
                </div>
              </DTALoginModal>
              <DTALoginModal
                isOpen={this.state.forgotUserName.flag}
                modalClass="login-modal"
                titleText="Forgot Username"
              >
                <div className="dta-modal__body pad-all dtaRegisterModel">
                  <div className="dta-modal__header">
                  <h1>{this.state.forgotUserName.error ? (forgotusername && forgotusername.failure) : (forgotusername && forgotusername.success)}</h1>
                    <button
                      className="dta-button--minor close-modal"
                      onClick={() => this.clearCookies()}
                    >
                      <Icon name="close" label="Close modal" />
                    </button>
                  </div>
                  {
                    this.state.forgotUserName.error ?
                      <p>{forgotusername.usernameRecoveryUnSuccessful}</p>
                    :
                      (
                        <div>
                          <p>{forgotusername && forgotusername.usernameRecovered}</p>
                          <p>Recovered email: {this.state.forgotUserName.username}</p>
                        </div>
                      )                      
                  }
                  <button
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    onClick={() => this.redirectToAzureLogin()}
                  >
                     {forgotusername && forgotusername.login}
                  </button>
                </div>
              </DTALoginModal>
              <DTALoginModal
                isOpen={this.state.isLoginError || this.state.isUserSignUp}
                modalClass="login-modal"
                titleText="User"
              >
                <div className="dta-modal__body pad-all dtaRegisterModel">
                  <div className="dta-modal__header">
                    <h1>
                      {this.state.isUserSignUp
                        ? register.signupbutton
                        : resetpassword.login}
                    </h1>

                    {!this.state.isUserSignUp ? (
                      <button
                        className="dta-button--minor close-modal"
                        onClick={() => this.clearCookies()}
                      >
                        <Icon name="close" label="Close modal" />
                      </button>
                    ) : null}
                  </div>
                  <p>
                    {this.state.isUserSignUp
                      ? register.regSuccess
                      : this.state.isLoginFailedDuetoUnlinkAccount?login.unlinkAuthFailed:
                      resetpassword.authFailed}
                  </p>
                  {this.state.isUserSignUp ? (
                    <button
                      className="dta-button dta-button--primary dta-button--large dta-login"
                      onClick={() => this.redirectToAzureLogin()}
                    >
                      {resetpassword.login}
                    </button>
                  ) : null}
                </div>
              </DTALoginModal>
            </main>
            {this.renderApplicationInanonymousMode()}
            <DTAModal
              ref={(instance) => (this.accountConnectModal = instance)}
              isOpen={this.state.helpVideoModalOpen}
              onClose={this.closeHelpVideoModal}
              modalClass="account-connect-modal consumer-search-client custom_width"
              titleText={home.helpBtntext}
            >
              <div className="dta-modal__body pad-all">
              <h1 style={{position: "absolute", top:"-10px"}}>{home.helpBtntext}</h1>
                <span><b>{home.helpChooseCategory}</b></span>&nbsp;&nbsp;
                <select
                  onChange={this.selectedHelpCategory}
                  className={addStringIf(this.props.className, "language-select")}
                  aria-label="Translate"
                  value={this.state.selectedHelpValue}
                >
                  {home.helpVideoDropdown ? home.helpVideoDropdown.map((lang) => {
                    return (
                      <option key={lang} value={lang}>
                        {lang}
                      </option>
                    );
                  }) : ""}
                </select>
                <br />
                <br />
                <iframe width="100%" height="480px" src={this.returnHelpVideoLink()} title={this.returnHelpVideoLink()}/>
              </div>
            </DTAModal>
          </div>
        )}
        {<DTAFooter language={home} />}
      </div>
    );
  }
  redirectScreening() {
    //start code of google analytics for screening
    visitor
      .pageview({
        dp: googleAnalyticsConstVar.SCREENING_PATH,
        dt: googleAnalyticsConstVar.SCREENING,
        dh: googleAnalyticsConstVar.URL_PATH,
      })
      .send();
    //end code of google analytics for screening
    window.location.assign(routeProps.SCREENING_PATH);
  }
  // Render a isHomeless Modal
  renderApplicationInanonymousMode = () => {
    const msalInstance = this.props.msalContext.instance;
    let selectedLanguage = localStorage.getItem("selectedLanguage") ? localStorage.getItem("selectedLanguage") : "en-US";
    return (
      <Modal
      isOpen={this.state.isAnonymousModeModalOpen}
      onClose={() =>
        this.closeApplicationInanonymousMode()
      }
      modalClass="anonymous-modal"
      titleText="homeless Warning"
      headerText={
        beforeYoustartMlanguageLabels(selectedLanguage).applyForSnapLabel
      }
      >
        <div className="dta-modal__body pad-all anonymous-mode-container">
          <div>
           <div>{beforeYoustartMlanguageLabels(selectedLanguage).applyForSnapContent}</div>
           <div className="top-content">
             <div className="pure-u-5-12 pure-u-lg-5-12">{beforeYoustartMlanguageLabels(selectedLanguage).renderApplyForSnapcol1Content()}</div>
             <div className="pure-u-7-12 pure-u-lg-7-12">{beforeYoustartMlanguageLabels(selectedLanguage).renderApplyForSnapcol2Content()}</div>
           </div>
          </div>
          <p> </p>
          <div className="footer-content">
            <div className="pure-u-1 pure-u-lg-1-2 create-account">
              <button
                className="dta-button dta-button--primary"
                onClick={() =>
                  this.onCreateAccount(msalInstance)
                }
              >
                 {beforeYoustartMlanguageLabels(selectedLanguage).createAccountLabel}
              </button>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 login-btn">
              <button
                className="dta-button dta-button--outline-secondary"
                onClick={() =>
                  this.redirectToAzureLogin()
                }
              >
                 {beforeYoustartMlanguageLabels(selectedLanguage).loginLabel}
              </button>
            </div>
          </div>
          <p> </p>
          <div className="footer-link">
           <span>{beforeYoustartMlanguageLabels(selectedLanguage).clickHereLabel1}</span>
           <a 
            href="/apply" 
            id="clickhere"
            onClick={() => {
              this.clickHereToAnon();
            }}
            className="text--center display-block spc"
           >{beforeYoustartMlanguageLabels(selectedLanguage).clickHereLabel3}</a>
           <span>{beforeYoustartMlanguageLabels(selectedLanguage).clickHereLabel2}</span>
          </div>
        </div>
      </Modal>
      );
  };
  clickHereToAnon = () => {
    this.closeApplicationInanonymousMode();
    this.applyHandler("SNAP");
    window.location.assign("/apply");
    sessionStorage.setItem("applicationInanonymousMode", true);
  }

  closeApplicationInanonymousMode = () => {
    this.setState({isAnonymousModeModalOpen : TextProps.VALUE_FALSE});
  }
  redirectApply() {
    //start code of google analytics for apply
    visitor
      .pageview({
        dp: googleAnalyticsConstVar.SNAP_APPLY,
        dt: googleAnalyticsConstVar.SCREENING,
        dh: googleAnalyticsConstVar.URL_PATH,
      })
      .send();
    //end code of google analytics for apply
    window.location.assign(routeProps.APPLY_PATH);
  }

  redirectPEBT() {
    window.location.assign(routeProps.PEBT_PATH);
  }

  redirectTAFDC() {
    window.location.assign(routeProps.APPLY_TAFDC_PATH);
  }

  redirectEAEDC() {
    window.location.assign(routeProps.APPLY_EAEDC_PATH);
  }

  redirectGreeterScreening() {
    window.location.assign(routeProps.GREETER_ROLE);
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPEBTBannerAlerts: getPEBTBannerAlerts,
      onLogOut: onLogOut,
      azureLogin: azureLogin,
      onAzureLogOut: onAzureLogOut,
      gotoConsumerPage: () => push("/consumer"),
      gotoProvider: () => push("/provider"),
      gotoMhProvider: () => push("/mhProvider"),
      onConsumerSignup: () => push(routesConstants.SIGNUP_PATH),
      languageChanged: languageChanged
    },
    dispatch
  );
}

export default withMsal(connect(mapStateToProps, mapDispatchToProps)(MainHome));
