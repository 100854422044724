export function languageReducer(state = {}, action) {
  switch (action.type) {
    case "GET_LANGUAGE_FULFILLED": {
      const newState = Object.assign({}, state);
      newState.constantStrings = action.payload;
      return newState;
    }
    case "UPDATE_SELECTED_LANGUAGE": {
      const newState = Object.assign({}, state);
      newState.selectedLanguage = action.payload;

      return newState;
    }
    case "GET_LANGUAGE_REQUESTED": {
      return state;
    }
    case "@@redux/INIT": {
      const newState = Object.assign({}, state);
      newState.constantStrings = {};
      newState.constantStrings.home = {};
      newState.constantStrings.security = {};
      newState.constantStrings.security.login = {};
      newState.constantStrings.security.register = {};
      newState.constantStrings.security.resetpassword = {};
      newState.constantStrings.security.forgotusername = {};
      newState.constantStrings.security.changePassword = {};
      newState.constantStrings.security.common = {};
      newState.constantStrings.consumer = {};
      newState.constantStrings.consumer.login = {};
      newState.constantStrings.consumer.home = {};
      newState.constantStrings.consumer.common = {};
      newState.constantStrings.consumer.documentupload = {};
      newState.constantStrings.consumer.info = {};
      newState.constantStrings.screening = {};
      newState.constantStrings.provider = {};
      newState.constantStrings.provider.home = {};
      newState.constantStrings.provider.search = {};
      newState.constantStrings.activePebt = {};
      newState.constantStrings.pebtHome = {};
      newState.selectedLanguage = "";
      return newState;
    }
    default:
      return state;
  }
}
