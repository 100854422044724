import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { beforeYoustartDsnapMlanguageLabels, householdDsnapMultilanguageLabels } from "../applyDsnapText";
import gettingStarted from "../../../utils/resources/images/getting-started.png";
import { DEPT_OF_TRANS_ASS_LOCATIONS } from "../../../utils/constants/constants";
import SNAPApplicationEN from "../../../utils/resources/pdfs/SNAP-ApplicationEN.pdf";
import SNAPApplicationES from "../../../utils/resources/pdfs/SNAP-ApplicationES.pdf";
import { langOptSel } from "../../../utils/constants/constants";
import DTASelect from "../../../utils/components/dtaSelect";
import ApplyDsnapContainer from "../applyDsnapContainer";

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

class BeforeYouStart extends Component {
  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
    this.state = {
      countyType: '',
      countyError: false
    }
  }

  handleCounty = (value) => {
    if(value === ""){
      this.props.onCountyChange(value);
      this.setState({
        countyType: value,
        countyError: true
      })
    }
    else{
      this.props.onCountyChange(value);
    this.setState({
      countyType: value,
      countyError: false
    })
  }
 
  }
  render() {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p className="apply__heading">
          {beforeYoustartDsnapMlanguageLabels(language).snapFieldLabel1}
        </p>
        <ul>
          <li> {beforeYoustartDsnapMlanguageLabels(language).fieldFactors[0]}</li>
          <li>
            {" "}
            {beforeYoustartDsnapMlanguageLabels(language).fieldFactors[1]}
          </li>
        </ul>
        <div>
          <p><b>{beforeYoustartDsnapMlanguageLabels(language).completingPreReglabel}</b>
            {beforeYoustartDsnapMlanguageLabels(language).completingPreRegSub}
          </p>
          <ul>
            <li>{beforeYoustartDsnapMlanguageLabels(language).fieldFactors[2]}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).fieldFactors[3]}</li>
          </ul>
          <p>{beforeYoustartDsnapMlanguageLabels(language).informationLabel}</p>
          <ul>
            <li>{beforeYoustartDsnapMlanguageLabels(language).memberText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).permanentText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).proofText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).proofText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).proofText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).proofResidencyText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).amountIncomeText}</li>
            <li>{beforeYoustartDsnapMlanguageLabels(language).amountHouseholdText}</li>
          </ul>
          <p>{beforeYoustartDsnapMlanguageLabels(language).preRegistrationText}</p>
          <div>
            <p>{beforeYoustartDsnapMlanguageLabels(language).stateLabelText}</p>
            {this.state.countyError && (
              <div className="peat-case-error-msg">
                <span className="dta-form__error-message">{beforeYoustartDsnapMlanguageLabels(language).countyErrorMsg}</span>
              </div>
            )}
            <DTASelect
              name={"before-you-start-county"}
              className="dta-form__control"
              id={"county"}
              options={householdDsnapMultilanguageLabels(language).countycode}
              error={this.state.countyError}
              value={this.state.countyType}
              onChange={this.handleCounty}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BeforeYouStart;
