import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";

import DTAInput from "./dtaInput";
import DTASelect from "./dtaSelect";
import OptionItem from "./optionItem";
import { addStringIf, tryCall } from "./utilities/controls";
import { CommonTypes, CommonDefaults } from "./propTypes";
import {
  RADIO_CLASSES,
  INPUT_TYPE_MONEY,
} from "../constants/controls";

// import HelpTip from "./helpTip";

import { getFrequencyCode } from "./utilities/utils";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../constants/text";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ShelterExpense extends Component {
  static propTypes = {
    ...CommonTypes,
    // required
    options: PropTypes.array.isRequired,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // text display
    inBetween: PropTypes.string,
    // states
    money: PropTypes.bool,
    // one-way data bindings
    value: PropTypes.shape({
      code: PropTypes.string,
      amount: PropTypes.array,
      unit: PropTypes.string,
    }),
    // clear
    clearLabel: PropTypes.string,
    showClear: PropTypes.bool,
  };
  static defaultProps = {
    ...CommonDefaults,
    // text display
    inBetween: "per",
    // states
    money: TextProps.VALUE_FALSE,
    // one-way data bindings
    value: { code: "", amount: "", unit: "" },
    // clear
    clearLabel: "None",
    showClear: TextProps.VALUE_FALSE,
    maxLength: PropTypes.number,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);

    let amountCount = 1;

    // if (props.value.unit) {
    //   let freqCode = getFrequencyCode(language)[props.value.unit];
    //   if (freqCode === "WEEK") {
    //     amountCount = 4;
    //   } else if (freqCode === "BIWEEK") {
    //     amountCount = 2;
    //   }
    // }

    let newAmount = [];
    for (let i = 0; i < amountCount; i++) {
      newAmount[i] =
        props.value.amount && props.value.amount[i]
          ? props.value.amount[i]
          : "";
    }
    // isNew:
    // props.value.code === "existing" || props.value.code === "changed" || props.value.code === "CHANGE" || props.value.code === "NOCHNG"
    //   ? TextProps.VALUE_FALSE
    //   : TextProps.VALUE_TRUE,
    this.state = {
      _isCleared: TextProps.VALUE_FALSE,
       isNew: props.value.code,
      _amountValue: props.value.amount ? props.value.amount : "",
      _unitsValue: props.value.unit ? props.value.unit : "",
    };

    this._handleChangeForAmountHelper = _.debounce(
      this._handleChangeForAmountHelper,
      500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        _isCleared:
          !nextProps.value || !nextProps.value.amount || !nextProps.value.unit,
        _amountValue: nextProps.value.amount,
        _unitsValue: nextProps.value.unit,
        _employerName: nextProps.value.employer,
        _fullIncomeObj: nextProps.value.fullIncomeObj,
      });
    }
  }

  render() {
    const { name, money, error, disabled, required, clearLabel, maxLength,inBetween,
    } =
        this.props,
      inputId = this.props.id,
      optionLabels = this.props.options,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={addStringIf(this.props.className, "dta-units")}>
        <div style={{display:"flex"}}>
          {this._buildAmount(
            name,
            inputId,
            ariaLabels,
            money,
            error,
            disabled,
            required,
            maxLength
          )}
          {this._buildInBetween(inBetween, error, disabled)}
          {this._buildUnits(name, optionLabels, error, disabled, required)}
        </div>
        {this.props.showClear &&
          this._buildClear(ariaLabels, name, clearLabel, error, disabled)}
      </div>
    );
  }

  // Rendering
  // ---------

  _buildAmount(name, id, ariaLabels, isMoney, isError, isDisabled, required,maxLength) {
    let amountCount = 1;
    let classRowCount = 1;
    let freqCode = getFrequencyCode(language)[this.state._unitsValue];
    // if (freqCode === "WEEK") {
    //   amountCount = 4;
    //   classRowCount = 3;
    // } else if (freqCode === "BIWEEK") {
    //   amountCount = 2;
    //   classRowCount = 6;
    // }
    let amountContainer = [];
    let classNameToUse = "dta-units__item";
    for (let i = 0; i < amountCount; i++) {
      amountContainer.push(
        <DTAInput
          name={name + "-" + i}
          type={isMoney ? INPUT_TYPE_MONEY : "number"}
          className={classNameToUse}
          id={id + "-" + i}
          aria-labelledby={ariaLabels}
          onChange={(value) => this._handleChangeForAmount(value, i)}
          value={this.state._amountValue}
          error={isError}
          disabled={isDisabled}
          required={required}
          maxLength={maxLength || 12}
          ariaLabel={"grossAmount"}
        />
      );
    }
    return (
      <div className="dta-units__control pure-u-1-2">
        {amountContainer}
      </div>
    );
  }

  _buildUnits(name, optionLabels, isError, isDisabled, required) {
    return (
      <div className="dta-units__control">
          <label htmlFor={this.nextUniqueId()} className="sr-only">
          How often?
        </label>
        <DTASelect
          name={name}
          options={optionLabels}
          className="dta-units__item"
          id={this.lastUniqueId()}
          aria-labelledby={this.lastUniqueId()}
          onChange={this._handleChangeForUnits}
          value={this.state._unitsValue}
          error={isError}
          disabled={isDisabled}
          required={required}
          ariaLabel={"shelterFrequency"}
        />
      </div>
    );
  }

  _buildClear(ariaLabels, name, clearLabel, isError, isDisabled) {
    const clearId = this.nextUniqueId();
    return (
      <ul className="dta-units__container">
        <OptionItem
          {...RADIO_CLASSES}
          name={name}
          key={clearId}
          aria-labelledby={ariaLabels}
          value=""
          text={clearLabel}
          type="radio"
          onChange={this._handleChangeForClear}
          checked={this.state._isCleared}
          disabled={isDisabled}
          error={isError}
          className={`dta-units__item ${RADIO_CLASSES.optionClass}`}
          controlClass={`dta-radio__control--without-indicator ${RADIO_CLASSES.controlClass}`}
        />
      </ul>
    );
  }

  // Events
  // ------

  _buildInBetween(inBetween, isError, isDisabled) {
    return (
      <div
        aria-hidden={TextProps.VALUE_STR_TRUE}
        className={addStringIf(
          isDisabled,
          addStringIf(isError, "dta-units__label", "dta-units__label--error"),
          "dta-units__label--disabled"
        )}
      >
        {inBetween}
      </div>
    );
  }

  _handleChangeForAmount = (newAmount, i) => {
    if (this.props.disabled) {
      return;
    }
    // let amount = { ...this.state._amountValue };
    // amount[i] = newAmount;
    this.setState({ _isCleared: TextProps.VALUE_FALSE, _amountValue: newAmount });
    let codeValue = this.state.isNew;
    if(this.state._amountValue !== newAmount && codeValue === "NOCHNG" || codeValue === "existing"){
      codeValue = "changed"
    }
    if(this.state._amountValue !== newAmount && !codeValue){
      codeValue = "new"
    }
    this._handleChangeForAmountHelper({
      code: codeValue,
      amount: newAmount,
      unit: this.state._unitsValue,
    });
  };

  _handleChangeForAmountHelper(value) {
    tryCall(this.props.onChange, value);
  }

  _handleChangeForUnits = (newUnits) => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ _isCleared: TextProps.VALUE_FALSE, _unitsValue: newUnits });
    let codeValue = this.state.isNew;
    if(this.state._unitsValue !== newUnits && codeValue === "NOCHNG" || codeValue === "existing"){
      codeValue = "changed"
    }
    if(this.state._unitsValue !== newUnits && !codeValue){
      codeValue = "new"
    }
    tryCall(this.props.onChange, {
      code: codeValue,
      amount: this.state._amountValue,
      unit: newUnits,
    });
  };

  _handleChangeForClear = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({
      _isCleared: TextProps.VALUE_TRUE,
      _amountValue: "",
      _unitsValue: "",
    });
    // do not set to `null` or `undefined` or this controller component will become uncontrolled
    tryCall(this.props.onChange, "", "");
  };
}

export default ShelterExpense;
