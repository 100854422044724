import React, { Component } from 'react';
import DynamicTable from "../../pages/consumer/secureMessage/DynamicTable";
import * as consumerLabels from "../../pages/consumer/consumerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class TabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  setActiveChange = (index) => {
    this.setState({ activeTab: index }, () => {
      this.props.setActiveTab(index)
      });
  };

  handleSecureUpdateMessage = (msgPayload, action) => {
    this.props.onSecureMessageReply(msgPayload, action);
      }

  render() {
    const { activeTab } = this.state;
    const { data, loading,lang,from, updateGrid } = this.props;
    const tabs = [
      {
        label: consumerLabels.consumerConst(selectedLanguage).all,
        content: data,
      },
      {
        label: consumerLabels.consumerConst(selectedLanguage).unRead,
        content: data,
      },
    ];

    return (
      <div>
        <div className="tab-bar">
          <ul className="nav-list nav-list--row" style={{ marginTop: "9px" }}>
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`tab-list__item ${index === activeTab ? "tab-active" : ""}`}
                onClick={() => this.setActiveChange(index)}
              >
                <a data-index="1" className={index === activeTab ? "active" : ""}  aria-label={tab.label} href="javascript:void(0)">
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content mt-8" style={{ marginTop: "17px" }}>
          <DynamicTable 
            loading={loading}
          data={tabs[activeTab].content || []} 
            from={from}
          replyModal={this.props.replyModal}
            onSecureMessageReply={this.handleSecureUpdateMessage} 
            updateGrid={updateGrid}
            activeTab={this.state.activeTab}
            lang = {lang} />

        </div>
      </div>
    );
  }
}


export default TabBar;
