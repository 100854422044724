/*
  Constants specific to the UNITS question type. See the `types.js` file containing
  all of the possible question types for full documentation of what properties
  are allowed for each question type in the questions data file.
 */

//  Required
export const IN_BETWEEN = "inBetween";
export const UNITS = "units";

// Optional
export const CLEAR_OPTION = "clearOption";
export const MONEY = "isMoney";
export const MAX_LENGTH = "maxLength";
export const ISNOTEMPTYOPTION = "isNotEmptyOption";
