import axios from "axios"; // resume apply stufftanfSections
import _, { constant } from "lodash";
import * as TextProps from "../../utils/constants/text";
import { DsnapSections, snapHouseholdTemplate  } from '../../pages/applyDsnap/applyDsnapMapData'
import { getAudit } from "../../utils/constants/audit";
import { loginRequest } from "config";
var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;

let config = require("../../utils/constants/appConfigConstants");

let sections = [];
let householdTemplate = {};

//setting up the application type from session storage
//assigning the sections for all application type
export function setDsnapSectionBasedApplicationType(applicationType) {
	sessionStorage.setItem("applicationType", applicationType)
  if (applicationType === TextProps.MATCH_DSNAP) {
    sections = _.cloneDeep(DsnapSections);
    householdTemplate = snapHouseholdTemplate;
    // householdTemplate = snapHouseholdTemplate;
  } 
  return (dispatch) => {
    return dispatch(applyDSnapSections(sections,actionTypes.applyDSnapPagesAction));
  };
}
//get the application type from session storage
function getApplicationType() {
  let applicationType = sessionStorage.getItem("applicationType");
  return applicationType;
}

const actionTypes = {
  applyDSnapPagesAction: "APPLY_DSNAP_PAGES_FULLFILLED",
  applyDSnapPagesActionValidated: "APPLY_DSNAP_PAGES_FULLFILLED_VALIDATED",
  fillDsnapInputValuesAction: "FILL_INPUT_DSNAPVALUES_STATE",
  appendIncome: "APPEND_INCOME",
  appendResources: "APPEND_RESOURCES",
  appendExpense: "APPEND_EXPENSES",
  appendHouseHold: "APPEND_DSNAP_HOME_HOLD_DATA",
  setHousehold: "SET_HOUSEHOLD_DATA",
  dsnapDataLoading: "DSNAP_DATA_LOADING",
  dsnapApplySuccess: "DSNAP_DATA_SUCCESS",
  dsnapApplyFailure: "DSNAP_DATA_FAILURE",
  countyList: "COUNTY_LIST_SUCCESS",
  appendMyHousehold:"APPEND_REQUIRED_FIELD_DSNAP",
  languageChanged: "LANGUAGE_MODIFIED",
  appendIncomeSectionDsnap:"APPEND_INCOME_SECTION_DSNAP",
  applyAddressStorageAction: "DSNAP_ADDRESS_STORAGE",
  checkAddressValidation: "ADDRESS_VALIDATION"

};

function applyDSnapSections(sections,action) {
    return {
      type: action,
      payload: sections,
    };
  }

  export function applyDSanpQuestionsSectionsNew(sections) {
    return (dispatch) => {
    return dispatch(applyDSnapSections(sections,actionTypes.applyDSnapPagesActionValidated));
    };
  }

  export function updateCurrentSectionData(applyDsnapUserSectionData) {
    return (dispatch) => {
      return dispatch(questionsInputValuesAction(applyDsnapUserSectionData));
    };
  }

  function questionsInputValuesAction(sections) {
    return {
      type: actionTypes.fillDsnapInputValuesAction,
      payload: sections,
    };
  }

  export function appendIncomeDetails(income) {
    return (dispatch) => {
      return dispatch(incomeSuccess(income))
      // (incomeSuccess(income));
    };
  }

  function incomeSuccess(income) {
    return {
      type: actionTypes.appendIncome,
      payload: income,
    };
  }
  
  export function appendResourcesDetails(income) {
    return (dispatch) => {
      //rendering Multiple templates to render
      return dispatch(resourcesSuccess(income));
    };
  }
  
  function resourcesSuccess(income) {
    return {
      type: actionTypes.appendResources,
      payload: income,
    };
  }
//Appened Expense
  export function appendExpenseDetails(income) {
    return (dispatch) => {
      //rendering Multiple templates to render
      return dispatch(expenseSuccess(income));
    };
  }
  
  function expenseSuccess(income) {
    return {
      type: actionTypes.appendExpense,
      payload: income,
    };
  }
  export function appendHouseHoldMembers(subMembers) {
    return (dispatch) => {
      //rendering Multiple templates to render
      return dispatch(houseHoldSuccess(subMembers));
    };
  }
  
  function houseHoldSuccess(houseHold) {
    return {
      type: actionTypes.appendHouseHold,
      payload: houseHold,
    };
  }
  export function clearHouseHoldMembers(
    houseHoldSubMemberTemplate,
    houseHoldSubDetailTemplate
  ) {
    return (dispatch) => {
      let detailTemplate = [];
      detailTemplate.push(houseHoldSubDetailTemplate);
      return dispatch(clearHouseHoldSuccess(detailTemplate));
    };
  }
  
  function clearHouseHoldSuccess(houseHold) {
    return {
      type: actionTypes.appendHouseHold,
      payload: houseHold,
    };
  }
  export function updateHouseholdTextChanges(householdDetails) {
    return (dispatch) => {
      //rendering Multiple templates to render
      return dispatch(appendHouseholdTextChanges(householdDetails));
    };
  }
  
  function appendHouseholdTextChanges(householdDetails) {
    return {
      type: actionTypes.appendHouseHold,
      payload: householdDetails,
    };
  }

  export function setHouseholdMembers(houseHoldData) {
    return {
      type: actionTypes.setHousehold,
      payload: houseHoldData,
    };
  }

  export function submitDsnapApply(payload, selectedLanguage) {
    return (dispatch) => {
      dispatch({
        type: actionTypes.dsnapDataLoading,
      })
      const audit = getAudit();
        var url = config.TigerURL + "/apipublic/security/dsnapapply";
        var options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            locale: selectedLanguage,
            audit: JSON.stringify(audit)
          },
        };
        axios
          .post(url, payload, options)
          .then(function (response) {
            dispatch(submitDsnapApplySuccess(response));
          })
          .catch(function (error) {
            dispatch(submitDsnapApplyFaliure(error));
          });
    };
  }
  
  
  function submitDsnapApplySuccess (data) {
    return {
      type: actionTypes.dsnapApplySuccess,
      payload: data,
    };
  }
  
  function submitDsnapApplyFaliure (error) {
    return {
      type: actionTypes.dsnapApplyFailure,
      payload: error,
    };
  }
  export function updateLastKnownLocationDsnap(section) {
    return (dispatch) => {
      return dispatch({
        type: "APPEND_LAST_KNOWN_LOCATION_DSNAP",
        payload: section,
      });
    };
  }

  export function countyList(selectedLanguage) {
    return (dispatch) => {
      const audit = getAudit();
        var url = config.TigerURL + "/apipublic/dsnapdetail";
        var options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            locale: selectedLanguage,
            audit: JSON.stringify(audit)
          },
        };
        axios
          .get(url, options)
          .then(function (response) {
            dispatch(countyListSuccess(response));
          })
          .catch(function (error) {
          });
    };
  }
  
  function countyListSuccess (countyData) {
    return {
      type: actionTypes.countyList,
      payload: countyData,
    };
  }

  export function appendRequiredFieldDnsap(appendRequiredField) {
    return {
      type: actionTypes.appendMyHousehold,
      payload: appendRequiredField,
    };
  }

  function languageChangeAction(data){
    return {
      type: actionTypes.languageChanged,
      payload: data
    }
  }

  export function languageChanged(data){
    return (dispatch) => dispatch(languageChangeAction(data))
  }
   export function appendIncomeSection(income) {
    return (dispatch) => {
        //rendering Multiple templates to render
        return dispatch(appendIncome(income));
          };
    }

    function appendIncome(income) {
      return {
        type: actionTypes.appendIncomeSectionDsnap,
        payload: income,
      };
    }

  export function dsnapAddressStorageAPI(responseData) {
    return {
      type: actionTypes.applyAddressStorageAction,
      payload: responseData,
    };
  }
  //Triggering the API call to hit the Melissa for address validation 
export function addressValidation(addressObj, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerURL +"/apipublic/address/validateDsnapAddr";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit)
        },
      };
      axios
        .post(url, addressObj, options)
        .then(function (data) {
          dispatch(checkAddressValidation(data));
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}
//Assigning type of action and payload for ADDRESS_VALIDATION
function checkAddressValidation(data) {
  return {
    type: actionTypes.checkAddressValidation,
    payload: data,
  };
}
