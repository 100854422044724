import * as Names from "../../utils/constants/names";
import * as Types from "../../utils/constants/types";
import * as InputProps from "../../utils/constants/input";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import * as SelectProps from "../../utils/constants/select";
import * as TextProps from "../../utils/constants/text";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import * as languageConstants from "../../utils/constants/constants";
import { interimReportPageLabels } from "./recertCovidText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === languageConstants.langOptSel) {
    language = "en-US";
  }

export const sections = [
  {
    id: "before-you-start",
    title: interimReportPageLabels(language, languageConstants.beforeYouStart)
      .howApplyLabel, //'How does applying work?',
    header: interimReportPageLabels(language, languageConstants.beforeYouStart)
      .heading, //'Before you start',
    completed: TextProps.VALUE_FALSE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "contact-info",
    title: interimReportPageLabels(
      language,
      languageConstants.contactInfo,
      language
    ).title,
    header: interimReportPageLabels(language, languageConstants.contactInfo)
      .header,
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "my-household",
    title: interimReportPageLabels(language, languageConstants.householdStatic)
      .houseTitle, // 'My household',
    header: interimReportPageLabels(language, languageConstants.householdStatic)
      .houseHeader,
    completed: null,
    selectionMade: null,
    subsections: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).earnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "combined-income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "WAGES",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[0],
              },
              {
                [OptionsProps.OPTION_VALUE]: "SELEMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[1],
              },
              {
                [OptionsProps.OPTION_VALUE]: "FEDWRK",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[2],
              },
            ],

            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "combined-income-subsection",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "wages-income-repeatable",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).wagesTargetValue,
                [OptionsProps.TABLE_DSC]: "WAGES",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.NAME]: "wages-gross-income-0",

                  amountLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).wages_AmountLabel,
                  employerLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).wages_EmployerLabel,
                  unitLabel: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).wages_UnitLabel,
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).wages_Label,

                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).labelOtherwage, //'Add another Wage'
              },
              {
                [Props.NAME]: "self-gross-income-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfemeploymentTargetValue,
                [OptionsProps.TABLE_DSC]: "SELEMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Self-Employment?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).selfGross_Label,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).adsSelfEmployee, //'Add another Self-Employment'
              },
              {
                [Props.NAME]: "work-study-gross-incom-0",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "FEDWRK", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workStudyTargetValue,
                [OptionsProps.TABLE_DSC]: "FEDWRK",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Work Study?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Work Study?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Work Study?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Work Study?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workStudy_Label,
                  [Props.TYPE]: Types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workStudyLabel, //'Add another Work Study'
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "income-summary",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).earnedIncomeMenuTitle, //'Income summary',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).earnedIncomeTitle, //'Income summary',
        completed: TextProps.VALUE_FALSE,
        hiddenFromNav: TextProps.VALUE_TRUE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "unearned-income-summary",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeMenuTitle, //'Income',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).unEarnedIncomeTitle, //"About your household's income",
    selectionMade: null,
    skipsubSectionNavigation: TextProps.VALUE_TRUE,
    subsections: [
      {
        id: "unearned-combined-income",
        title: "John Doe (8.Aug.1967)",
        header: "John Doe's (8.Aug.1967) income and benefits",
        questions: [
          {
            [Props.NAME]: "types",
            [Props.LABEL]:
              "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
            [Props.TYPE]: Types.CHECKBOX,
            [OptionsProps.OPTIONS]: [
              {
                [OptionsProps.OPTION_VALUE]: "SSI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[4],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RSDI",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[5],
              },
              {
                [OptionsProps.OPTION_VALUE]: "UNEMPL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[6],
              },
              {
                [OptionsProps.OPTION_VALUE]: "CHSDOR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[7],
              },
              {
                [OptionsProps.OPTION_VALUE]: "EMPPEN",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[8],
              },
              {
                [OptionsProps.OPTION_VALUE]: "VETBPR",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[9],
              },
              {
                [OptionsProps.OPTION_VALUE]: "WRKCMP",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[10],
              },
              {
                [OptionsProps.OPTION_VALUE]: "RENTAL",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[3],
              },
              {
                [OptionsProps.OPTION_VALUE]: "OTHER",
                [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
                  language,
                  languageConstants.earnedIncomeStatic
                ).combineIncomeoptions[11],
              },
            ],

            [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).utilitiesClearOption,
          },
        ],
        subsections: [
          {
            id: "unearned-combined-income-subsection",
            title: "John Doe (8.Aug.1967)",
            header: "John Doe's (8.Aug.1967) income and benefits",
            hiddenFromNav: TextProps.VALUE_TRUE,
            completed: TextProps.VALUE_FALSE,
            questions: [
              {
                [Props.NAME]: "ssi-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).SSITargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "SSI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherSSILabel, //'Add another SSI'
              },
              {
                [Props.NAME]: "social-security-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).rsdiTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RSDI",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these wages?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addRDILabel, //'Add another RSDI'
              },
              {
                [Props.NAME]: "unemployment-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmploymentTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "UNEMPL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Unemployment?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unEmpLabel, //'Add another Unemployment'
              },
              {
                [Props.NAME]: "child-support-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).childSuppTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "CHSDOR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Child Support?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherChildsupport, //'Add another Child Support'
              },
              {
                [Props.NAME]: "pension-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EMPPEN", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).pensionTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "EMPPEN",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) pension?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) pension?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these pension?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) pension?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherPensionLabel, // 'Add another Pension'
              },
              {
                [Props.NAME]: "veterans-benefits-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBPR", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).veteranBenefitsTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "VETBPR",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
              },
              {
                [Props.NAME]: "workers-compensation-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workerCompTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "WRKCMP",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) Worker's Compensation?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) Worker's Compensation?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Worker's Compensation?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Worker's Compensation?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel, //'per',
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).workerCompensationLabel, //"Add another Worker's compensation"
              },
              {
                [Props.NAME]: "rental-income-gross-income-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).rentalIncomeTargetValue,
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]: "RENTAL",
                [ControlConstants.REPEATABLE_QUESTION]: {
                  amountLabel:
                    "What is the gross income amount of John Doe's (8.Aug.1967) Rental Income?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these Rental Income ?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) Rental Income?",
                  [Props.LABEL]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).rental_Label,
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, // ['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).repeatQuestionLabel,
              },
              {
                [Props.NAME]: "other-income-benified-0",
                [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).otherTargetValue,
                [OptionsProps.TABLE_DSC]: "OTHER",
                [Props.TYPE]: Types.REPEATABLE_QUESTION,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "What is the ",
                    [Props.LABEL_OBJ_TRIGGER]: "gross income",
                    [Props.LABEL_OBJ_AFTER]:
                      " amount of John Doe's (8.Aug.1967) other income or benefits?",
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
                  employerLabel:
                    "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
                  unitLabel:
                    "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
                  [Props.TYPE]: Types.UNEARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsPerLabel,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions, //['Month','Every two weeks', 'Week'],
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).questionButtonLabel, //"Add another Other"
              },
            ],
          },
        ],
        completed: TextProps.VALUE_FALSE,
      },
      {
        id: "unearned-income-summary",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).unEarnedIncomeMenuTitle, //'Income',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).unEarnedIncomeTitle, //"About your household's income",
        completed: TextProps.VALUE_FALSE,
        skipsubSectionNavigation: TextProps.VALUE_TRUE,
        hiddenFromNav: TextProps.VALUE_TRUE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "childsupport-expenses",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).childSupportSideBar, //'Sign & submit',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).childSupportSummaryHeader, //'Sign & submit',
    completed: TextProps.VALUE_FALSE,
    selectionMade: null,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "Other information",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).otherInfoSideBar, //'Expenses',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).otherInfoSideBar,
    questions: [
      {
        [Props.TYPE]: Types.TEXT,
        [TextProps.VALUE]:
          "John Smith (16.Aug.1982) does not have to meet the ABAWD work requirement because s/he has a disability or illness that makes it hard to work.",
      },
      {
        [Props.NAME]: "work",
        [Props.LABEL]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).otherInfo_Question, //'What type of housing cost does your household have?',

        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_YES,
            [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_YES,
          },
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_NO,
            [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_NO,
          },
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).houseCostErrmsg, //'Please choose housing costs'
      },
      {
        [Props.TYPE]: Types.DIVIDER,
      },
      {
        [Props.TYPE]: Types.TEXT,
        [TextProps.VALUE]:
          "Mary Smith (04.Jul.1980) is participating in training at Division of Unemployment Assistance.",
      },
      {
        [Props.NAME]: "division-unemployment",
        // [Props.LABEL]: "Has there been any change in this information?", //'What type of housing cost does your household have?',
        [Props.LABEL]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).otherInfo_Question, //'What type of housing cost does your household have?',

        [Props.TYPE]: Types.RADIO,
        [OptionsProps.OPTIONS]: [
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_YES,
            [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_YES,
          },
          {
            [OptionsProps.OPTION_VALUE]: TextProps.VALUE_NO,
            [OptionsProps.OPTION_DISPLAY]: TextProps.VALUE_NO,
          },
        ],
        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).houseCostErrmsg, //'Please choose housing costs'
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
  {
    id: "sign-and-submit",
    title: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).signSubmitLabel, //'Sign & submit',
    header: interimReportPageLabels(
      language,
      languageConstants.earnedIncomeStatic
    ).signSubmitHeader, //'Sign & submit',
    subsections: [
      {
        id: "submitted",
        title: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).subSectionLabel, //'Submitted',
        header: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).submitLabelFinal, //'Your application has been submitted!',
        hiddenFromNav: TextProps.VALUE_TRUE,
        completed: TextProps.VALUE_FALSE,
      },
    ],
    completed: TextProps.VALUE_FALSE,
    disabled: TextProps.VALUE_FALSE,
  },
];

export default sections;

export const householdTemplate = {
  id: "household-member",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseMemberTitle,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .submitLabelFinal, //'Your first household member is ',
  dependentId: "",
  questions: [
    {
      [Props.NAME]: "firstName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseFirstName, //"What is this person's first name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).firstErrmsg, //'Please enter first name'
    },
    {
      [Props.NAME]: "middleName",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseMiddleLabel, //"What is this person's middle name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).houseMiddleName, //'Please enter middle name'
    },
    {
      [Props.NAME]: Names.LAST_NAME,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseLastNameLabel, //"What is this person's last name?",
      [Props.TYPE]: Types.INPUT_TEXT,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).enterLastname, //'Please enter last name'
    },
    {
      [Props.NAME]: Names.SUFFIX,
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).suffixLabel, //'Suffix',
      [Props.TYPE]: Types.SELECT,
      [SelectProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).SuffixOptions, // ['I', 'II', 'III', 'IV', 'V', 'Jr.', 'Sr.']
    },
    {
      [Props.NAME]: "relationship",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).relationQlabel, // "What is this person's relationship to you?",
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SPOUSE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).spouseDisplay, //'Spouse'
        },
        {
          [OptionsProps.OPTION_VALUE]: "MO",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).motherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "FA",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).faterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "DAUGH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).daughterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sonDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "BROTH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).brotherDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "SIST",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).sisterDisplay,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRMOT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandMaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRFATH",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandFaDisplay,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRDAU",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandDaugther,
          [OptionsProps.OPTION_DISPLAY_SIZE]:
            OptionsProps.OPTION_DISPLAY_SIZE_SMALL,
        },
        {
          [OptionsProps.OPTION_VALUE]: "GRSON",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).grandSon,
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHREL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.householdStatic,
            null,
            null,
            null
          ).otherDisplay,
        },
      ],
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "dateOfBirth",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).houseDobLabel, //"What is this person's date of birth?",
      [Props.TYPE]: Types.DATE,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
    },
    {
      [Props.NAME]: "biological-sex",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).personGenLabel,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).genderOptios,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "is-citizen",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiBeforeQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citiTriggerQLabel,
        [Props.LABEL_OBJ_AFTER]: "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).citizenHelp,
      },
      [Props.INLINE_HELP]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).citizenInhelp,
      [Props.TYPE]: Types.RADIO,
      [OptionsProps.OPTIONS]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).citizenOptions,
      [OptionsProps.OPTIONS_PER_ROW]: 2,
    },
    {
      [Props.NAME]: "ssn",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnQLabel,
      [Props.TYPE]: Types.INPUT_SSN,
      [InputProps.CLEAR_LABEL]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).ssnDntLabel,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.householdStatic
      ).ssnErrLabel,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const householdDisabilityTemplate = {
  id: "household-details",
  title: interimReportPageLabels(language, languageConstants.householdStatic)
    .hholdHeader,
  header: interimReportPageLabels(language, languageConstants.householdStatic)
    .houseHeader,
  questions: [
    {
      [Props.NAME]: "disability",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQLabel,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityQTriggerLabel,
        [Props.LABEL_OBJ_AFTER]:
          interimReportPageLabels(language, languageConstants.householdStatic)
            .disabilityQlabel2 + "?",
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.householdStatic,
          null,
          null,
          null
        ).disabilityHelp,
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.householdStatic,
        null,
        null,
        null
      ).disablilityCler,
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const IncomeSectionTemplate = {
  id: "combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "WAGES",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "SELEMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "FEDWRK",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[2],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "combined-income-subsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "wages-income-repeatable",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WAGES", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).wagesTargetValue,//'Wages', //Testing
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).wagesTargetValue,
          [OptionsProps.TABLE_DSC]: "WAGES",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.NAME]: "wages-gross-income-0",

            amountLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).wages_AmountLabel,
            employerLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).wages_EmployerLabel,
            unitLabel: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).wages_UnitLabel,
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).wages_Label,

            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [OptionsProps.TARGET_VALUE]: [{ units: "month" }],
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).labelOtherwage, //'Add another Wage'
        },
        {
          [Props.NAME]: "self-gross-income-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SELEMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).selfemeploymentTargetValue,//'Self-Employment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).selfemeploymentTargetValue,
          [OptionsProps.TABLE_DSC]: "SELEMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Self-Employment?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Self-Employment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Self-Employment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Self-Employment?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).selfGross_Label,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).adsSelfEmployee, //'Add another Self-Employment'
        },
        {
          [Props.NAME]: "work-study-gross-incom-0",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "FEDWRK", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workStudyTargetValue,//'Work Study',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).workStudyTargetValue,
          [OptionsProps.TABLE_DSC]: "FEDWRK",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Work Study?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Work Study?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Work Study?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Work Study?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workStudy_Label,
            [Props.TYPE]: Types.EARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workStudyLabel, //'Add another Work Study'
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const incomeSummaryTemplate = {
  id: "income-summary",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .incomeSummaryTitle, //'Income summary',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).incomeSummaryHeader, //'Income summary',
  completed: TextProps.VALUE_FALSE,
};

export const unearnedIncomeSectionTemplate = {
  id: "unearned-combined-income",
  title: "John Doe (8.Aug.1967)",
  header: "John Doe's (8.Aug.1967) income and benefits",
  questions: [
    {
      [Props.NAME]: "types",
      [Props.LABEL]:
        "What type(s) of income or benefits does John Doe (8.Aug.1967) have?",
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "SSI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[4],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RSDI",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[5],
        },
        {
          [OptionsProps.OPTION_VALUE]: "UNEMPL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[6],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CHSDOR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[7],
        },
        {
          [OptionsProps.OPTION_VALUE]: "EMPPEN",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[8],
        },
        {
          [OptionsProps.OPTION_VALUE]: "VETBPR",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[9],
        },
        {
          [OptionsProps.OPTION_VALUE]: "RENTAL",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "WRKCMP",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[10],
        },
        {
          [OptionsProps.OPTION_VALUE]: "OTHER",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).combineIncomeoptions[11],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).utilitiesClearOption,
    },
  ],
  subsections: [
    {
      id: "unearned-combined-income-subsection",
      title: "John Doe (8.Aug.1967)",
      header: "John Doe's (8.Aug.1967) income and benefits",
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "ssi-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "SSI", // ,interimReportPageLabels(language, languageConstants.earnedIncomeStatic).SSITargetValue,//'SSI (Supplemental Security Income)',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).SSITargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "SSI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these SSI (Supplemental Security Income)?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) SSI (Supplemental Security Income)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherSSILabel, //'Add another SSI'
        },
        {
          [Props.NAME]: "social-security-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RSDI", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rsdiTargetValue,//"RSDI (Retirement, Survivors, and Disability Insurance)",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).rsdiTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RSDI",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these wages?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) RSDI (Retirement, Survivors, and Disability Insurance)?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addRDILabel, //'Add another RSDI'
        },
        {
          [Props.NAME]: "unemployment-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "UNEMPL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).unEmploymentTargetValue,//'Unemployment',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).unEmploymentTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "UNEMPL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Unemployment?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Unemployment?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Unemployment?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Unemployment?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unEmpLabel, //'Add another Unemployment'
        },
        {
          [Props.NAME]: "child-support-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "CHSDOR", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).childSuppTargetValue,//'Child Support',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).childSuppTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "CHSDOR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Child Support?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Child Support?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Child Support?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Child Support?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherChildsupport, //'Add another Child Support'
        },
        {
          [Props.NAME]: "pension-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "EMPPEN", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).pensionTargetValue, //'Pension',
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).pensionTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "EMPPEN",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) pension?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) pension?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these pension?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) pension?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).anotherPensionLabel, // 'Add another Pension'
        },
        {
          [Props.NAME]: "veterans-benefits-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "VETBPR", // interimReportPageLabels(language, languageConstants.earnedIncomeStatic).veteranBenefitsTargetValue,//"Veteran's Benefits",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).veteranBenefitsTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "VETBPR",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Veteran's Benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Veteran's Benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Veteran's Benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).addAnotherVBenefitLabel, //"Add another Veteran's Benefits"
        },
        {
          [Props.NAME]: "workers-compensation-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "WRKCMP", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).workerCompTargetValue,//"Worker's Compensation",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).workerCompTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "WRKCMP",
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) Worker's Compensation?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) Worker's Compensation?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Worker's Compensation?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Worker's Compensation?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).workerCompensationLabel, //"Add another Worker's compensation"
        },
        {
          [Props.NAME]: "rental-income-gross-income-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "RENTAL", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).rentalIncomeTargetValue,//"Rental Income",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).rentalIncomeTargetValue,
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [OptionsProps.TABLE_DSC]: "RENTAL",
          [ControlConstants.REPEATABLE_QUESTION]: {
            amountLabel:
              "What is the gross income amount of John Doe's (8.Aug.1967) Rental Income?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these Rental Income ?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) Rental Income?",
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).rental_Label,
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).costBetween,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, // ['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).repeatQuestionLabel,
        },
        {
          [Props.NAME]: "other-income-benified-0",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "OTHER", //interimReportPageLabels(language, languageConstants.earnedIncomeStatic).otherTargetValue, //"Other",
          [OptionsProps.DISPLAY_INCOME_TYPE_NAME]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).otherTargetValue,
          [OptionsProps.TABLE_DSC]: "OTHER",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: {
              [Props.LABEL_OBJ_BEFORE]: "What is the ",
              [Props.LABEL_OBJ_TRIGGER]: "gross income",
              [Props.LABEL_OBJ_AFTER]:
                " amount of John Doe's (8.Aug.1967) other income or benefits?",
              [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).grossIncomeHelp,
              //'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
            },
            amountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) other income or benefits?",
            employerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these other income or benefits?",
            unitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) other income or benefits?",
            [Props.TYPE]: Types.UNEARNED_INCOME,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsPerLabel,
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).unitsOptions, //['Month','Every two weeks', 'Week'],
            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).questionButtonLabel, //"Add another Other"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseHousingCostTemplate = {
  id: "housing-costs",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .houseUtlilityLabel,
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).expenseHeader,
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).expenseSubHeader,
  questions: [
    {
      [Props.NAME]: "utilities",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).anyUtilQcost, //'Does your household have any utility costs?',
      [Props.TYPE]: Types.CHECKBOX,

      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "payHeat",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payAC",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "payACFee",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "elgas",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "hasPhone",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).utilityQOptions[4],
        },
      ],

      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).chooseHoldErrmsg, //'Please choose household utilities'
    },
    {
      [Props.NAME]: "type",
      [Props.LABEL]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseQLabel, //'What type of housing cost does your household have?',
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        {
          [OptionsProps.OPTION_VALUE]: "RENT",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[0],
        },
        {
          [OptionsProps.OPTION_VALUE]: "MORTGAGE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[1],
        },
        {
          [OptionsProps.OPTION_VALUE]: "PROPERTY_TAX",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[2],
        },
        {
          [OptionsProps.OPTION_VALUE]: "HOME_INSURANCE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[3],
        },
        {
          [OptionsProps.OPTION_VALUE]: "CONDO_FEE",
          [OptionsProps.OPTION_DISPLAY]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).houseQOptions[4],
        },
      ],
      [UnitsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).utilitiesClearOption,
      [Props.REQUIRED]: TextProps.VALUE_FALSE,
      [Props.ERROR]: TextProps.VALUE_FALSE,
      [Props.ERROR_MESSAGE]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseCostErrmsg, //'Please choose housing costs'
    },
  ],
  subsections: [
    {
      id: "housing-cost-detail",
      title: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).houseUtlilityLabel, //'Housing and Utility Costs',
      header: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseHeader, //'Tell us about your housing costs',
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseSubHeader, //'Please include the costs you are responsible for paying even if you are behind or not able to pay them',
      completed: TextProps.VALUE_FALSE,
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "rent",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).rentTargetValue, //"Rent",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).houseRentQLabel, //"How much is your household's rent?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).unitsPerLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).houseUnitOptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).rentErrMsg, // 'Please enter rent'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).anotherRentLabel, //"Add another rent"
        },
        {
          [Props.NAME]: "mortgage",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).mortageTargetValue, //"Mortgage",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).mortageQLabel, // "How much is your household's mortgage?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageunitBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageQoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageErrLabel, //'Please enter mortgage'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).addMortageButtonLabel, //"Add another mortgage"
        },
        {
          [Props.NAME]: "property-taxes",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).propertyTaxTargetValue, //"Property Taxes",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortageProptaxLabel, //"How much is your household's property taxes?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).mortagepropoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxLabel, //'Please enter property taxes'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).propTaxButtonLabel, //"Add another property taxes"
        },
        {
          [Props.NAME]: "home-insurance-costs",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).homeInsurTargetValue, //"Home Insurance",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostQLabel, //"How much is your household's home insurance costs?",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostpropBetween, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).insuranceCostoptions, //['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurErrmsg, //'Please enter home insurance'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).homeInsurbutton, //"Add another home insurance"
        },
        {
          [Props.NAME]: "condo-fee",
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).condoFeeTargetValue, //"Condo Fee",
          [Props.TYPE]: Types.REPEATABLE_QUESTION,
          [ControlConstants.REPEATABLE_QUESTION]: {
            [Props.LABEL]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic
            ).condofeeQLabel, //"How much is your household's condo fee",
            [Props.TYPE]: Types.UNITS,
            [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeBeweenLabel, //'per',
            [UnitsProps.UNITS]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condofeeCostoptions, // ['Week', 'Month', 'Year'],

            [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            [Props.REQUIRED]: TextProps.VALUE_FALSE,
            [Props.ERROR]: TextProps.VALUE_FALSE,
            [Props.ERROR_MESSAGE]: interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoFeeErrmsg, //'Please enter condo fee'
          },
          [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
            interimReportPageLabels(
              language,
              languageConstants.earnedIncomeStatic,
              null,
              null,
              null
            ).condoButtonLabel, //"Add another condo fee"
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseKidsAndAdultsTemplate = {
  id: "kids-and-adults",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .childTitle, //'Child or Adult Dependent Care Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostHeader, //,'Tell us about your Child or Adult Dependent Care costs',
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).childCostSubHeader, // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "caregivers",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjbefore,
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjtrigger, //'transport',
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).careGiveObjAfter, //' the dependent(s) to and/or from a care provider.',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic
        ).careGiveHelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption, // 'No one',
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-1",
              [Props.LABEL]:
                "Who does John Doe (8.Aug.1967) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["Marty Smith (2.Jun.1994)"],
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "recipient-of-care-2",
              [Props.LABEL]:
                "Who does Marty Smith (2.Jun.1994) pay dependent care for?",
              [Props.TYPE]: Types.CHECKBOX,
              [OptionsProps.OPTIONS]: ["John Doe (8.Aug.1967)"],
              [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).questionClearOption, //'No one'
            },
          ],
        },
      ],
    },
  ],
  subsections: [
    {
      id: "kids-and-adults-marty",
      title: "Marty Smith (2.Jun.1994)",
      header: "Caring for Marty Smith (2.Jun.1994)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).kidsandadultSubheader, //      'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      completed: TextProps.VALUE_FALSE,
      questions: [
        {
          [Props.NAME]: "caring-kids-adults-cost-1",
          [Props.LABEL]:
            "What is the cost of provider care for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.UNITS,
          [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).costBetween,
          [UnitsProps.UNITS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).freqTripoptions,
          [OptionsProps.TARGET_VALUE]: { unit: "Month" },
          [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
        },
        {
          [Props.NAME]: "if-pays-transportation-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "transportation-cost-1",
                  [Props.LABEL]:
                    "How much does John Doe (8.Aug.1967) pay for transportation for Marty Smith (2.Jun.1994)?",
                  [Props.TYPE]: Types.UNITS,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).freqTripoptions,
                  [OptionsProps.TARGET_VALUE]: { unit: "Month" },
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
              ],
            },
          ],
        },
        {
          [Props.NAME]: "drive-to-provider-1",
          [Props.LABEL]:
            "Does anyone in your household drive Marty Smith (2.Jun.1994) to and/or from the provider?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy?",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      subsections: [
        {
          id: "kids-adult-multiple-address",
          title: "Marty Smith (2.Jun.1994)",
          header: "Caring for Marty Smith (2.Jun.1994)",
          subHeader: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic
          ).expenseSubHeader,
          //'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
          hiddenFromNav: TextProps.VALUE_TRUE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).addressProvider, //'What is the address of the provider?',
              [Props.TYPE]: Types.ADDRESS,
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does your household drive to the provider? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween, //'per',
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions, //['Week', 'Month', 'Year'],
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseChildSupportTemplate = {
  id: "child-support",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .childSupTitle, //'Child Support Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).tellusAbout, //'Tell us about your Child support costs',
  questions: [
    {
      [Props.NAME]: "with-child-support",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).childSupportQlegalbefore, // 'Does anyone in the household have a legal obligation (court order) to pay ',
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).childSupportQlegaltrigger, //'child support',
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).childSupportQlegalAfter, //' to a child not living with you?',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).childSupportHelp,
        //'Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption,
      [OptionsProps.CONDITIONALS]: [
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "John Doe (8.Aug.1967)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-1",
              [Props.LABEL]:
                "What is the child support expense for John Doe (8.Aug.1967)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
        {
          [OptionsProps.CONDITIONAL_TARGET_VALUE]: "Marty Smith (2.Jun.1994)",
          [OptionsProps.CONDITIONAL_QUESTIONS]: [
            {
              [Props.NAME]: "child-support-cost-2",
              [Props.LABEL]:
                "What is the child support expense for Marty Smith (2.Jun.1994)?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const expenseMedicalExpenseTemplate = {
  id: "medical-expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .medicalTitle, //'Medical Costs',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).medicalHeader, //"Tell us about your medical costs",
  subHeader: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).medicalSubHeader,
  // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
  questions: [
    {
      [Props.NAME]: "with-medical-expenses",
      [Props.LABEL]: {
        [Props.LABEL_OBJ_BEFORE]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpBefore,
        //'Does anyone listed below pay for health insurance or other medical costs including prescriptions, ',
        [Props.LABEL_OBJ_TRIGGER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpTrigger,
        [Props.LABEL_OBJ_AFTER]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExpAfter,
        // ', over the counter medications, dental or eye care, adult diapers, etc.?',
        [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
          language,
          languageConstants.earnedIncomeStatic,
          null,
          null,
          null
        ).medicalExphelp,
        // 'Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.'
      },
      [Props.TYPE]: Types.CHECKBOX,
      [Props.INLINE_HELP]:
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .medicalInlineSeethisFull +
        ' <a href="https://www.mass.gov/files/documents/2017/10/02/SNAP-MEB-English-0815.pdf" target="_blank" rel="noopener noreferrer">' +
        interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
          .medicalInlineEligibleMedical +
        "</a>.",
      [Props.INLINE_HELP_HAS_MARKUP]: TextProps.VALUE_TRUE,
      [OptionsProps.OPTIONS]: [
        "John Doe (8.Aug.1967)",
        "Marty Smith (2.Jun.1994)",
      ],
      [OptionsProps.CLEAR_OPTION]: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic,
        null,
        null,
        null
      ).questionClearOption,
    },
  ],
  subsections: [
    {
      id: "medical-expenses-1",
      title: "John Doe (8.Aug.1967)",
      header: "Medical cost for John Doe (8.Aug.1967)",
      subHeader: interimReportPageLabels(
        language,
        languageConstants.earnedIncomeStatic
      ).expenseSubHeader,
      // 'Please include the costs you are responsible for paying even if you are behind or not able to pay them.',
      hiddenFromNav: TextProps.VALUE_TRUE,
      questions: [
        {
          [Props.NAME]: "driving-1",
          [Props.LABEL]:
            "Does John Doe (8.Aug.1967) drive to medical appointments or the pharmacy?",
          [Props.TYPE]: Types.RADIO,
          [OptionsProps.OPTIONS]: interimReportPageLabels(
            language,
            languageConstants.earnedIncomeStatic,
            null,
            null,
            null
          ).yesnoOptions,
          [OptionsProps.OPTIONS_PER_ROW]: 2,
          [OptionsProps.CONDITIONALS]: [
            {
              [OptionsProps.CONDITIONAL_TARGET_VALUE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).targetValueYes,
              [OptionsProps.CONDITIONAL_QUESTIONS]: [
                {
                  [Props.NAME]: "driving-address-1",
                  [Props.LABEL]:
                    "How many addresses, does John drive to medical appointments or the pharmacy??",
                  [Props.TYPE]: Types.NUMBERS,
                  [Props.ERROR]: TextProps.VALUE_FALSE,
                  [Props.ERROR_MESSAGE]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic,
                    null,
                    null,
                    null
                  ).noofAppointment,
                },
              ],
            },
          ],
        },
      ],
      completed: TextProps.VALUE_FALSE,
      subsections: [
        {
          id: "medical-address-1",
          title: "Medical Appointment for John Doe",
          header: "Medical Appointment for John Doe",
          hiddenFromNav: TextProps.VALUE_TRUE,
          completed: TextProps.VALUE_FALSE,
          questions: [
            {
              [Props.NAME]: "address",
              [Props.LABEL]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).medicalAddress,
              // 'What is the address of the medical appointment or pharmacy?',
              [Props.TYPE]: Types.ADDRESS,
              [Props.REQUIRED]: TextProps.VALUE_FALSE,
              [Props.ERROR]: TextProps.VALUE_FALSE,
              [Props.ERROR_MESSAGE]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).zipErrmsg, //'Please enter a valid Zipcode'
            },
            {
              [Props.NAME]: "frequency-trips-1",
              [Props.LABEL]:
                "How often does John Doe (8.Aug.1967) drive to this address? To and from a provider should be counted as two trips.",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_FALSE,
            },
            {
              [Props.NAME]: "cost-trips-1",
              [Props.LABEL]:
                "How much does John Doe (8.Aug.1967) pay for transportation, parking to get to medical appointments or the pharmacy?",
              [Props.TYPE]: Types.UNITS,
              [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).costBetween,
              [UnitsProps.UNITS]: interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).freqTripoptions,
              [OptionsProps.TARGET_VALUE]: { unit: "Month" },
              [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
            },
          ],
        },
      ],
    },
  ],
  completed: TextProps.VALUE_FALSE,
};

export const ExpenseSectionTemplate = {
  id: "expenses",
  title: interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
    .expenseTitle, //'Expenses',
  header: interimReportPageLabels(
    language,
    languageConstants.earnedIncomeStatic
  ).almostLabel,
  subsections: [
    expenseHousingCostTemplate,
    expenseKidsAndAdultsTemplate,
    expenseChildSupportTemplate,
    expenseMedicalExpenseTemplate,
  ],
  completed: TextProps.VALUE_FALSE,
};
