const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";

export function aboutMeMultilanguageLabels(language) {
  return {
    title: "Greeter Screening",
    header: "Greeter Screening",
    labelFirstName: "First Name",
    firstNameErrMsg: "Please enter your first name.",
    firstNameErrMsgLength:
      "Please enter first name less than or equal to 15 characters",
    middleName: "Middle Name",
    middleNameErrMsg: "Please enter middle name",
    middleNameErrMsgLength:
      "Please enter middle name less than or equal to 15 characters",
    lastNamelabel: "Last Name",
    lastNameErrMsg: "Please enter your last name.",
    lastNameErrMsgLength:
      "Please enter last name  less than or equal to 20 characters",
    dobAlertMsg: "Please enter a valid date of birth.",
    dateOfBirthLabel: "Date of Birth",
    haveAgencyID: "Agency ID",
    haveAgencyIDErrMsg: "Please enter a valid Agency ID",
    access: "Access",
    clientAccess: "Do you have reliable and secure phone and internet access?",
    visitReason: "Reason For Visit?",
    visitReasonOptions: [
      "Apply for SNAP",
      "Apply for Cash/Combo",
      "Document Dropoff/Scan",
      "Need Access to Document",
      "Recertification/Reevaluation",
      "EBT",
      "Speak to Staff",
      "P-EBT",
      "Other Agency",
    ],
    services: "Service Utilized?",
    serviceOptions: [
      "Opted for Off-site",
      "Kiosk",
      "Phone",
      "Scanner/Printer",
      "Co-located Agency",
      "ACES DHR Self-Service Portal/Technical Coaching",
      "Other",
    ],
    radioErrMsg: "Please select an option below.",
    accessOptions: ["Yes", "No"],
    bestPhoneqlabel: "Phone Number",
    validPhoneAlert: "Please enter a valid phone number.",
    cmtOtherErrMsg: "Please enter other type of service.",
  };
}

export function beforeYoustartMlanguageLabels(language) {
  return {
    submitButtonLabel: "Submit",
    continueLabel: "Continue",
  };
}
