import React, { Component } from "react";
import PropTypes from "prop-types";
import { Wrapper, Tab, TabList, TabPanel } from "react-aria-tabpanel";
import * as TextProps from "../../utils/constants/text";
import * as logos from "../../utils/constants/constants";

class DTATabs extends Component {
  static PropTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.func.isRequired,
      })
    ).isRequired,
  };

  render() {
    return (
      <Wrapper letterNavigation={TextProps.VALUE_TRUE} className="dta-tabs">
        <TabList aria-owns="tabDocuments tabNotifications" aria-busy="true">
          <ul className="dta-tabs__list">
            {this.props.tabs.map((tab) => {
              let isShowNotificationIcon = (tab.id === "tabNotifications" &&  this.props.tabs[1].isShowBellIcon === "N" && this.props.alertIcon === false);
              return (
                <li key={tab.id} className="dta-tabs__list-item">
                  <Tab id={tab.id} className="dta-tabs__tab">
                    {this._buildTabContents.bind(null, tab.title,isShowNotificationIcon,this.props.postNotification)}
                  </Tab>
                </li>
              );
            })}
          </ul>
        </TabList>
        <div className="dta-tabs__panel-wrapper">
          {this.props.tabs.map((tab) => {
            return (
              <TabPanel key={tab.id} tabId={tab.id}>
                <div className="dta-tabs__panel-inner">{tab.content()}</div>
              </TabPanel>
            );
          })}
        </div>
      </Wrapper>
    );
  }
  _buildTabContents = (content, isShowNotificationIcon, postDhrNotices, ...tabState) => {
    let cl = "dta-tabs__tab-inner";
    if (tabState[0].isActive) cl += " is-active";

    if (isShowNotificationIcon) {
      return <div onClick={postDhrNotices} className={cl}>{content} <div className="document-notification-svg" >
      <img style={{width:"20px", height: "20px",marginBottom:"8px"}} src={logos.notification_icon} alt="alert"/></div></div>    }
    else {
      return (<div className={cl}>
        {content}
      </div>)
    }
  }
}

export default DTATabs;
