import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import { push } from "react-router-redux";
import CSSTransition from "react-transition-group/CSSTransition";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import ApplySection from "./components/initialApplicationForTafdc";
import { convertToDTOObj } from "./applyTafdcConverter";
// Redux implementations
import { connect } from "react-redux";
import {
  serviceFailedAction,
  applyTafdcQuestionsSections,
  updateCurrentSectionData,
  updateLastKnownLocation,
  saveSnapApplyTAFDC,
} from "../../redux/applyTafdc/applyTafdcActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import { toValidateInputErros, bindAnswers } from "./applyTafdcHelper";
import { beforeYoustartMlanguageLabels } from "./applyTafdcText";
//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import {
  getConsumerLoginContents,
  onLogin,
  onMFASubmit,
} from "../../redux/user/loginActions";
import {
  onRegister,
  onBindEmail,
} from "../../redux/user/consumerSignUpActions";
import { onLogOut } from "../../utils/helper/accountNavigationActions";
import { updateConsumerInfo } from "../../redux/consumer/consumerMyInfoActions";
import BeforeYouStart from "./components/beforeYouStart";
import SignAndSubmit from "./components/signAndSubmit";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as languageConsts from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";

var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class ApplyTafdc extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.onSecondaryActionClick = this.onSecondaryActionClick.bind(this);
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.beforeGoToSignupPage = this.beforeGoToSignupPage.bind(this);
    this.renderCounter = 0;
    this.nonRenderCounter = 0;
    this.state = {
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      rights: 0,
      signature: "",
      AnswerObject: [],
      lastKnownSection: "",
      isLoggedin: TextProps.VALUE_FALSE,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      dataSheetObj: { appId: "", clientId: "", documentId: "" },
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    let check =
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      this.props.match.params.webAppId !== undefined;
    if (check === TextProps.VALUE_TRUE) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }

    if (
      nextState.sections[0] !== undefined &&
      nextState.sections[0].isSubmitInitiated &&
      !nextState.sections[0].isSubmitted
    ) {
      return TextProps.VALUE_TRUE;
    }

    return check;
  }
  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        sections: this.props.applyTafdc.sections,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(pageConfig.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );

    if (nextProps.consumer.applicationSubmited === TextProps.VALUE_TRUE) {
      this.setState({ applicationSubmited: TextProps.VALUE_TRUE });
    }
  }

  // redux apply sections loading
  componentDidMount() {
    if (this.props.match.params.webAppId === undefined) {
      this.props.applyTafdcQuestionsSections();
    }

    //Check Login Status
    if (Object.keys(this.props.consumer.consumerData).length > 0) {
      if (this.props.consumer.consumerData.user !== undefined) {
        if (
          this.props.consumer.consumerData.user.email !== undefined &&
          this.props.consumer.consumerData.user.email !== ""
        ) {
          this.setState({ isLoggedin: TextProps.VALUE_TRUE });
        } else {
          this.setState({ isLoggedin: TextProps.VALUE_FALSE });
        }
      }
    }
  }

  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      if (prevState.activeSectionId === "sign-and-submit")
        this.removeSection(prevState.activeSectionId);
      helperFunction._animatedScroll();
    }
  }

  onSectionChange = (section, event = null) => {
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });
      }
    );
  };

  _dynamicSectionIdMatch = (activeId) => {
    if (activeId === "income") return TextProps.VALUE_TRUE;
    return TextProps.VALUE_FALSE;
  };

  //Primary Continue Click
  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    //Dal Reference
    let dalReference = this.props.applyTafdc.dalReference;
    if (
      activeId === "about-me" &&
      typeof this.state.AnswerObject !== "undefined"
    ) {
      if (
        typeof this.state.AnswerObject[activeId] !== "undefined" &&
        typeof this.state.AnswerObject[activeId].ssn !== "undefined"
      ) {
        if (this.state.AnswerObject[activeId].ssn === "") {
          this.props.applyTafdc.sections[1].questions[5].isChecked =
            TextProps.VALUE_TRUE;
        }
      }
    }
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      let _this = this;
      _this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      //TAFDC form validations
      let isValid = toValidateInputErros(dataSections, activeId);

      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        this.props.applyTafdcQuestionsSections();
        return;
      }
    } else if (activeId === "sign-and-submit") {
      //start google analytics code for getting number of people applying application group by language
      let currentLanguageCode = language;
      if (currentLanguageCode) {
        switch (currentLanguageCode) {
          case languageConsts.SPANISH:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.SPANISH,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case languageConsts.PORTUGUESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.PORTUGUESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case languageConsts.CHINESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.CHINESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case languageConsts.VIETNAMESE:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.VIETNAMESE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          case "ht":
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.HAITIANCREOLE,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
          default:
            visitor.pageview({
              dp: googleAnalyticsConstVar.DP_APPLY,
              dt: googleAnalyticsConstVar.DT_APPLY,
              dh:
                googleAnalyticsConstVar.URL_PATH +
                googleAnalyticsConstVar.SNAP_APPLY,
            });
            visitor
              .event(
                googleAnalyticsConstVar.DT_APPLY,
                googleAnalyticsConstVar.ENGLISH,
                googleAnalyticsConstVar.EVENT_LABEL,
                42
              )
              .send();
            break;
        }
      }
      //end google analytics code for getting number of people applying application group by language
      //Loading Dynamic SubSections , HouseHold Details

      if (this.state.rights < 1 || this.state.signature.length < 1) {
        if (this.state.rights < 1) {
          this.setState({ agreeError: TextProps.VALUE_TRUE });
          this.agreeErrorMsg =
            beforeYoustartMlanguageLabels(language).agreeTermsErrorMsg;
        } else {
          this.setState({ agreeError: TextProps.VALUE_FALSE });
          this.agreeErrorMsg = "";
        }

        if (this.state.signature.length < 1) {
          this.setState({ signatureError: TextProps.VALUE_TRUE });
          this.signatureErrorMsg =
            beforeYoustartMlanguageLabels(language).signatureErrorMsg;
        } else {
          this.setState({ signatureError: TextProps.VALUE_FALSE });
          this.signatureErrorMsg = "";
        }

        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
          });
        }
        return;
      } else {
        //Sending obj to service side for saving
        // application having object kind of data
        let application;
        let userDetails = sessionStorage.getItem("userInfo");
        if (
          userDetails === null ||
          userDetails === "" ||
          userDetails === undefined
        ) {
          application = convertToDTOObj(
            this.state.sections,
            this.state.signature,
            null
          );
        } else {
          let userDetailsObj = JSON.parse(userDetails);
          let inProgressObj = {};
          inProgressObj.status = "RECVED";
          inProgressObj.userId = userDetailsObj.email;
          application = convertToDTOObj(
            this.state.sections,
            this.state.signature,
            inProgressObj
          );
        }
        this.saveToDatabase(application, activeId, dalReference);
      }
    }
    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.applyTafdc);
    if (activeId !== "sign-and-submit") {
      this._renderNextSection(activeId);
    }
  };

  saveToDatabase = (dataSections, activeId, dalReference) => {
    this.props.applyTafdc.sections[0].isSubmitInitiated = TextProps.VALUE_TRUE;
    this.setState({ isLoading: TextProps.VALUE_TRUE });

    this.props
      .saveSnapApplyTAFDC(dataSections, language)
      .then((data) => {
        if (activeId === "sign-and-submit") {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
          this._renderNextSection(activeId);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
      });
  };

  _renderNextSection = (activeId) => {
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.applyTafdc.sections;
    }
    let nextSectionId = getNextSection(activeId, section);
    //Function to Execute before Next page renders

    if (nextSectionId) {
      const nextSection = this._getSection(nextSectionId);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
      this._updateCurrentSection(activeId);
    }
  };

  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.applyTafdc;
    let previousSectionId;

    if (this.props.applyTafdc.sections[1].partialSave) {
      this.props.applyTafdc.sections[1].partialSave = TextProps.VALUE_FALSE;
      previousSectionId = this.props.applyTafdc.sections[1].lastKnownLocation;
    } else {
      previousSectionId = getPreviousSection(activeId, sections);
    }

    if (previousSectionId) {
      const previousSection = this._getSection(previousSectionId);
      this.setState(
        {
          directionClass: "backward",
        },
        () => {
          this.onSectionChange(previousSection);
        }
      );
    } else {
      helperFunction.redirectToHome();
    }
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        returnText = beforeYoustartMlanguageLabels(language).getStartedLabel;
        return returnText;
      case "sign-and-submit":
        returnText = beforeYoustartMlanguageLabels(language).submitButtonLabel;
        return returnText;
      default:
        returnText = beforeYoustartMlanguageLabels(language).continueLabel;
        return returnText;
    }
  };

  // Functions for setting state for if save progress modal is open or not
  presentSaveProgressModal = (e) => {
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.EVENT_CATEGORY_PARTIAL,
        googleAnalyticsConstVar.EVENT_ACTION_PARTIAL,
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();

    e.preventDefault();
    this.setState({ saveProgressModalOpen: TextProps.VALUE_TRUE });
    // saving the json for GUEST User on clicking save my progress later
  };

  // Functions for setting state for if warning progress modal is open or not
  presentWarningProgressModal = () => {
    this.setState({ warningProgressModalOpen: TextProps.VALUE_TRUE });
  };

  closeWarningProgressModal = () => {
    this.setState({ warningProgressModalOpen: TextProps.VALUE_FALSE });
  };

  // Markup for the body of apply page when user is starting application.
  renderBeforeYouStart = () => {
    return <BeforeYouStart />;
  };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: event }, () => {
      if (this.state.signature.length < 1) {
        this.setState({ signatureError: TextProps.VALUE_TRUE });
        this.signatureErrorMsg =
          beforeYoustartMlanguageLabels(language).signatureErrorMsg;
      } else {
        this.setState({ signatureError: TextProps.VALUE_FALSE });
        this.signatureErrorMsg = "";
      }
    });
  };
  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agreeErrorMsg =
          beforeYoustartMlanguageLabels(language).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agreeErrorMsg = "";
      }
    });
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmit = () => {
    return (
      <SignAndSubmit
        submitFormId={this.state.submitFormId}
        agreeError={this.state.agreeError}
        agreeErrorMsg={this.agreeErrorMsg}
        signatureError={this.state.signatureError}
        signatureErrorMsg={this.signatureErrorMsg}
        updateSignature={this.updateSignature}
        signature={this.state.signature}
        acceptTermsConditions={this.acceptTermsConditions}
      />
    );
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p className="margin-top-none">
          {beforeYoustartMlanguageLabels(language).renderAppNoLabel}
          {this.props.applyTafdc.sections[0].webAppId}.{" "}
          {beforeYoustartMlanguageLabels(language).reachOut}
          {this.props.applyTafdc.application.taoName}
          {beforeYoustartMlanguageLabels(language).atLabel}
          {this.props.applyTafdc.application.phoneNumber}
        </p>

        <p className="apply__heading margin-top-double margin-bottom-half">
          {beforeYoustartMlanguageLabels(language).whatisNextLabel}
        </p>
        <ul className="margin-top-none">
          <li>{beforeYoustartMlanguageLabels(language).dtaReachLabel}</li>
        </ul>
        <br />
        <br />
        <button
          className="dta-button dta-button--primary dta-button--large dta-button--block"
          onClick={this.goHome}
        >
          {beforeYoustartMlanguageLabels(language).orExistLabel}
        </button>
      </div>
    );
  };

  _bindAnswerObject = (answer) => {
    this.setState({
      AnswerObject: answer,
    });
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.

  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <ApplySection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.beforeGoToSignupPage}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </ApplySection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);

    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          lastKnownSection: previousSectionId,
          activeSectionId: "sign-and-submit",
        });
      }
    );
    this.props.updateLastKnownLocation(previousSectionId);
  };

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
        return this.renderBeforeYouStart();
      case "sign-and-submit":
        return this.renderSignAndSubmit();
      case "submitted":
        this.disableApplySections();
        return this.renderSubmitted();
      default:
        return <div>Something went wrong</div>;
    }
  };

  disableApplySections = () => {
    let sections = this.props.applyTafdc.sections;
    if (sections) {
      sections.map((section) => (section.disabled = TextProps.VALUE_TRUE));
      this.props.applyTafdc.sections = sections;
    }
  };

  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.applyTafdc;

    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);

    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      if (sections.length > 0 && sections[0].errorRedirect) {
        return (
          <div>
            <h1>
              Something went wrong. Please contact dhrapphelpdesk@aldhr.gov
            </h1>
          </div>
        );
      }
      return <Loading />;
    }
  }

  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      return (
        <div className="full-height apply">
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            inProgressSectionId={this.state.inProgressSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            onSaveProgress={this.presentSaveProgressModal}
            canSaveProgress={TextProps.VALUE_TRUE}
            skipToAnchor="#content"
          />
          <main className="apply__content-container" id="content" tabIndex="-1">
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
            <LanguageSelect className="language-select--dark" />
          </main>
        </div>
      );
    }
  }

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.applyTafdc.sections;
    }

    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  beforeGoToSignupPage() {
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );
      if (
        this.props.applyTafdc.sections[3].questions[0].memberOptions !==
        undefined
      ) {
        dataSections.memberOptions =
          this.props.applyTafdc.sections[3].questions[0].memberOptions;
      }
      let isValid = toValidateInputErros(dataSections, activeId);
      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        return;
      } else {
        //gotoSignupPage
        this.redirectToSignAndSubmit();
      }
    } else {
      //gotoSignupPage
      this.redirectToSignAndSubmit();
    }
  }

  goHome = () => {
    window.location.assign(routeProps.HOME_PATH);
  };

  closeRegisterModal = () => {
    this.setState({ signupSectionContents: TextProps.VALUE_FALSE });
  };

  closeRegisterSuccessfulModal = () => {
    this.setState({
      registrationSuccessful: TextProps.VALUE_FALSE,
      isLoggedin: TextProps.VALUE_TRUE,
    });
    this.props.onBindEmail(this.state.email);
  };

  onRegister(e) {
    e.preventDefault();
    let registerDetails = {};
    registerDetails.grant_type = "client_credentials";
    registerDetails.scope = "UserReg";
    registerDetails.email = this.state.email;
    registerDetails.password = this.state.password;
    registerDetails.firstname = this.state.firstName;
    registerDetails.lastname = this.state.lastName;
    registerDetails.dob = this.state.dob.toLocaleDateString();
    this.props.onRegister(registerDetails);
  }

  goToLogin() {
    this.setState({
      signupSectionContents: TextProps.VALUE_FALSE,
      loginSectionContents: TextProps.VALUE_TRUE,
    });
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveSnapApplyTAFDC: saveSnapApplyTAFDC,
      applyTafdcQuestionsSections: applyTafdcQuestionsSections,
      updateCurrentSectionData: updateCurrentSectionData,
      serviceFailedAction: serviceFailedAction,
      updateLastKnownLocation: updateLastKnownLocation,

      //login
      onGetConsumerLogin: getConsumerLoginContents,
      updateConsumerInfo: updateConsumerInfo,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      onConsumerSignup: () => push(routeProps.SIGNUP_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogin: onLogin,
      onMFASubmit: onMFASubmit,
      onRegister: onRegister,
      onBindEmail: onBindEmail,
      goToLogin: () => push(routeProps.HOME_PATH),
      onLogOut: onLogOut,
      gotoHome: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyTafdc);
