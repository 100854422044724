import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { beforeYoustartMlanguageLabels } from "../applySnapText";
import gettingStarted from "../../../utils/resources/images/getting-started.png";
import { DEPT_OF_TRANS_ASS_LOCATIONS } from "../../../utils/constants/constants";
import SNAPApplicationEN from "../../../utils/resources/pdfs/SNAP-ApplicationEN.pdf";
import SNAPApplicationES from "../../../utils/resources/pdfs/SNAP-ApplicationES.pdf";
import { langOptSel } from "../../../utils/constants/constants";

let language = localStorage.getItem("selectedLanguage");
if (language === null || language === langOptSel) {
  language = "en-US";
}

class BeforeYouStart extends Component {
  constructor(props) {
    super();
    enableUniqueIds(this);
  }

  render() {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p className="apply__heading">
          {beforeYoustartMlanguageLabels(language).snapFieldLabel1}
        </p>
        <ul>
          <li> {beforeYoustartMlanguageLabels(language).fieldFactors[0]}</li>
          <li>
            {" "}
            {beforeYoustartMlanguageLabels(language).fieldFactors[1]}
            <a target="_blank" href={language=="es-ES"?SNAPApplicationES:SNAPApplicationEN}>
              {beforeYoustartMlanguageLabels(language).fieldFactors[4]}
            </a>
            {beforeYoustartMlanguageLabels(language).fieldFactors[2]}
          </li>
        </ul>
        <div className="apply__ul-margin">
          <ul>
            <li>
              {" "}
              {beforeYoustartMlanguageLabels(language).fieldFactors[3]}
              <span><a target="_blank"  href={DEPT_OF_TRANS_ASS_LOCATIONS}>{beforeYoustartMlanguageLabels(language).fieldFactors[4]}</a>.</span>
            </li>
          </ul>
        </div>
        <p className="apply__heading">
          {beforeYoustartMlanguageLabels(language).snapFieldLabel2}
        </p>
        <ul>
          {beforeYoustartMlanguageLabels(language).fieldFactors2.map((field2) => {
              return(
                <li> {field2}</li>
              )
          })}
        </ul>
        <p className="margin-none">
          {beforeYoustartMlanguageLabels(language).snapFieldLabel3}
        </p>
        <ul style={{marginTop:'0'}}>
          {beforeYoustartMlanguageLabels(language).fieldFactors3.map((field3) => {
              return(
                <li> {field3}</li>
              )
          })}
        </ul>
        <p className="margin-none">
          {beforeYoustartMlanguageLabels(language).snapFieldLabel4}
        </p>
        <ul className="margin-none">
          {beforeYoustartMlanguageLabels(language).fieldFactors4.map((field4) => {
              return(
                <li> {field4}</li>
              )
          })}
        </ul>
        <p className="apply__heading">
          {beforeYoustartMlanguageLabels(language).snapFieldLabel5}
        </p>
      </div>
    );
  }
}

export default BeforeYouStart;
