import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Collapse from "react-collapse";
import Icon from "../dtaIcon";
import * as TextProps from "../../constants/text";
class SidebarNavigationSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    activeSectionId: PropTypes.string.isRequired,
    inProgressSectionId: PropTypes.string.isRequired,
    onSectionClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    noCollapse: PropTypes.bool,
  };

  hasSubsections = (section) => {
    return section.subsections && section.subsections.length;
  };

  isMatch = (targetID, section) => {
    return section.id === targetID;
  };

  hasSubsectionMatching = (targetID, section) => {
    let found = TextProps.VALUE_FALSE;

    if (this.hasSubsections(section)) {
      section.subsections.forEach((subsection) => {
        if (subsection && subsection.id === targetID) {
          found = TextProps.VALUE_TRUE;
        }
        if (subsection && subsection.id !== targetID && found !== TextProps.VALUE_TRUE) {
          found = this.hasSubsectionMatching(targetID, subsection);
        }
      });
    }

    return found;
  };

  isCompleted = (section) => {
    return section.completed;
  };

  hasCompletedSubection = (section) => {
    let found = TextProps.VALUE_FALSE;

    if (this.hasSubsections(section)) {
      section.subsections.forEach((subsection) => {
        if (subsection && subsection.completed) {
          found = TextProps.VALUE_TRUE;
        }
        if (subsection && !subsection.completed && found !== TextProps.VALUE_TRUE) {
          found = this.hasCompletedSubection(subsection);
        }
      });
    }

    return found;
  };

  isActive = (section) => {
    return (
      this.isMatch(this.props.activeSectionId, section) ||
      this.hasActiveAndHiddenSubsection(section)
    );
  };

  isExpanded = (section) => {
    const active = this.props.activeSectionId;

    return (
      this.isMatch(active, section) ||
      this.hasSubsectionMatching(this.props.activeSectionId, section)
    );
  };

  hasActiveAndHiddenSubsection = (section) => {
    if (this.hasSubsections(section)) {
      return !!+section.subsections.filter(
        (subsection) => subsection && subsection.hiddenFromNav && this.isActive(subsection)
      ).length;
    }
  };

  isNavigable = (section) => {
    if (
      section.disabled === TextProps.VALUE_TRUE ||
      section.id === "sign-and-submit"
    ) {
      return TextProps.VALUE_FALSE;
    }

    const inProgress = this.props.inProgressSectionId;

    return (
      this.isExpanded(section) ||
      this.isMatch(inProgress, section) ||
      this.hasSubsectionMatching(inProgress, section) ||
      this.isCompleted(section) ||
      this.hasCompletedSubection(section)
    );
  };

  onSectionClick = (section) => (event) => {
    if (this.isNavigable(section)) {
      this.props.onSectionClick(event, section);
    }
  };

  renderIcon = (completed) => {
    return completed ? (
      <Icon
        name="checkmark"
        label="Section complete"
        className="icon-checkmark"
      />
    ) : null;
  };

  renderActiveSectionIcon(section, pebt) {
    if (this.isNavigable(section)) {
      return (
        <Link
          to="#"
          role="link"
          onClick={this.onSectionClick(section)}
          className={`${this.isActive(section) ? "is-active" : ""}`}
          aria-label={section.id}
        >
          {this.isActive(section) ? (
            <span className="sr-only">Active section</span>
          ) : null}
          {!pebt && this.renderIcon(section.completed)}
          {section.title}
        </Link>
      );
    } else {
      return (
        <a role="button" disabled>
          {this.renderIcon(section.completed)}
          {section.title}
        </a>
      );
    }
  }

  render() {
    const section = this.props.section;
    const { pebt } = this.props;

    return (
      <li className={this.props.className}>
        {section.hiddenFromNav === undefined ||
        section.hiddenFromNav === TextProps.VALUE_FALSE
          ? this.renderActiveSectionIcon(section, pebt)
          : null}
        {
          // For snapApply Page
          this.hasSubsections(section) ? (
            <Collapse isOpened={this.isExpanded(section)}>
              <ul className="nav-list nav-list--column">
                {section.subsections.map((subsection) => {
                 return subsection && subsection.hiddenFromNav === undefined ||
                 subsection && subsection.hiddenFromNav === TextProps.VALUE_FALSE ? (
                    <SidebarNavigationSection
                      key={subsection.id}
                      noCollapse={TextProps.VALUE_TRUE}
                      section={subsection}
                      activeSectionId={this.props.activeSectionId}
                      inProgressSectionId={this.props.inProgressSectionId}
                      onSectionClick={this.props.onSectionClick}
                      className="nav-list__item nav-list__item--bordered"
                    />
                  ) : null
                }
                )}
              </ul>
            </Collapse>
          ) : null
        }
      </li>
    );
  }
}

export default SidebarNavigationSection;
