import axios from "axios";
import sections from "../../pages/recertification/recertificationMapData";
import { getAudit } from "../../utils/constants/audit";
import { resetAlertMessage } from "../../utils/components/utilities/utils";
import * as TextProps from "../../utils/constants/text";
import { formatDate } from "../../utils/components/utilities/formatters";
import _ from "lodash";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;

var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;
var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  recertPagesAction: "RECERT_PAGES_FULLFILLED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  appendHouseHoldRecert: "RECERT_APPEND_HOME_HOLD_DATA",
  appendRecertIncome: "RECERT_APPEND_INCOME_DATA",
  appendStudentDetails: "APPEND_STUDENT_DETAILS",
  appendRecertExpense: "RECERT_APPEND_EXPENSE_DATA",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  downloadPdfAction: "DOWNLOAD_PDF_APPLY_APPLICATION",
  setContactInfo: "SET_CONTACT_INFO",
  setHouseholdMembers: "SET_HOUSEHOLD_MEMBERS",
  addStudentToList: "ADD_STUDENT_TO_LIST",
  addContactInfoToSections: "ADD_ADD_CONTACT_INFO_SECTION",
  addAuthRepToSections: "ADD_AUTH_REP_SCREEN",
  addHouseholdInfoToSections: "ADD_ADD_HOUSEHOLD_INFO_SECTION",
  setDataChangeStatus: "ADD_DATA_CHANGE_STATUS",
  setRecertDataChangeStatus: "IR_RECERT_ADD_DATA_CHANGE_STATUS",
  setHouseholdResourcesChangeStatus: "SET_HOUSEHOLD_RESOURCES_STATUS",
  setHouseholdResourcesChangeStatusToTrue: "SET_HOUSEHOLD_RESOURCES_STATUS_CHANGED_TO_TRUE",
  setRecertResponse: "SET_RECERT_RESPONSE",
  setNonCitizenStatusUpdate: "SET_NON_CITIZEN_STATUS_CHANGE",
  postRecertDataFulfilled: "POST_RECERT_DATA_FULFILLED",
  recertStateSection: "RECERT_STATE_REFRESH",
  saveRecertDataFulfilled: "GET_RECERT_DATA_FULFILLED",
  applyAddressStorageAction: "APPLY_ADDRESS_STRG",
  setChildSupportData: "IR_SET_CHILD_SUPPORT",
  setDataChangeStatusForExpense: "IR_ADD_DATA_CHANGE_STATUS_EXP",
  setObligationChildSupportChanged: "SET_OBLIGATION_CHILD_SUPPORT_STATUS",
  setCourtOrderedChildSupportChanged: "SET_COURT_ORDERED_CHILD_SUPPORT_STATUS",
  setShelterUtilityStatus: "SET_SHELTER_UTILITY_STATUS",
  setShelterDefaultQuestions: "SET_SHELTER_DEFAULT_QUESTIONS",
  setMedicalExpensesChangeStatus: "SET_MEDICAL_EXPENSES_CHANGE_STATUS",
  appendIRShelterQuestions: "APPEND_IR_SHELTER_QUESTIONS",
  setShelterData: "SET_SHELTER_DATA",
  appendShelterSubSectionQuestions:"APPEND_SHELTER_SUBSECTION_QUESTIONS_DATA",
  appendRecertDataForSummary: "APPEND_RECERT_DATA_FOR_SUMMARY",
  appendShelterSubSectionQuestions: "APPEND_SHELTER_SUBSECTION_QUESTIONS_DATA",
  saveandExitAction :"SAVE_EXIT_DATA",
  setGrossEarnedIncomeStatus: "SET_GROSS_EARNED_INCOME_STATUS",
  setQuestionOneStatus: "SET_QUESTION_ONE_STATUS",
  setQuestionTwoStatus: "SET_QUESTION_TWO_STATUS",
  setQuestionThreeStatus: "SET_QUESTION_THREE_STATUS",
  setLastQuestionStatus:"SET_QUESTION_LAST_STATUS",
  setRegisterVoteFlg:"SET_REGISTER_VOTE_FLG",
  setChangeAddressFlg:"SET_CHANGE_ADDRESS_FLG",
  setNoRegisterVoteFlg:"SET_NO_REGISTER_VOTE_FLG",
  setCommentStatus: "SET_COMMENT_TEXT",
  setIfAnswerYesStatus: "SET_IF_ANSWER_YES",
  setAuthRepStatus: "SET_AUTH_REP_STATUS",
  setAuthRepPurchaseFoodStatus: "SET_AUTH_REP_PURCHASE_FOOD_STATUS",
  setAuthRepEligibilityStatus: "SET_AUTH_REP_ELIGIBILITY_STATUS",
  setAuthRepBody: "SET_AUTH_REP_BODY",
  setTanfBody: "SET_TANF_BODY",
  setEarnedIncomeStatus: "SET_EARNED_INCOME_STATUS",
  appendResources: "APPEND_SR_RESOURCES_DATA",
  appendIRIncome: "APPEND_IR_INCOME_DATA",
  appendUIIncome: "APPEND_UI_INCOME_DATA",
  setUnearnedIncomeStatus: "SET_UNEARNED_INCOME_STATUS",
};

export function recertQuestionsSections(recertQuestionsSections) {
  if(recertQuestionsSections){
    sections.filter(sec => sec.id === "expedited-snap")[0].hiddenFromNav = TextProps.VALUE_FALSE
  }
  return (dispatch) => {
      return dispatch(recertSections(_.cloneDeep(sections)));
  };
}

export function snapRefreshQuestionsSections(originalSections) {
  return (dispatch) => {
    return dispatch(recertSections(originalSections));
  };
}

function recertSections(recertPageSections) {
  return {
    type: actionTypes.recertPagesAction,
    payload: recertPageSections,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}
function questionsInputValuesAction(questionSections) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: questionSections,
  };
}
export function addressValidation(addressObj, language) {
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/address/validate";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, addressObj, options)
        .then(function (data) {
          // dispatch(checkAddressValidation(data));
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}
//Appened Income
// export function appendRecertIncomeDetails(income) {
//   return (dispatch) => {
//     //rendering Multiple templates to render
//     return dispatch(incomeSuccess(income));
//   };
// }

export function appendStudentetails(students) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(studentDetailSuccess(students));
  };
}

function studentDetailSuccess(students) {
  return {
    type: actionTypes.appendStudentDetails,
    payload: students,
  };
}

// function incomeSuccess(income) {
//   return {
//     type: actionTypes.appendRecertIncome,
//     payload: income,
//   };
// }
//Appened Expense
export function appendRecertExpenseDetails(expense, index) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(expenseSuccess(expense, index));
  };
}

function expenseSuccess(expense, index) {
  return {
    type: actionTypes.appendRecertExpense,
    payload: expense,
    index: index,
  };
}

export function updateHouseholdTextChangesRecert(householdDetails) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(appendHouseholdTextChangesRecert(householdDetails));
  };
}

function appendHouseholdTextChangesRecert(householdDetails) {
  return {
    type: actionTypes.appendHouseHoldRecert,
    payload: householdDetails,
  };
}

export function postRecertData(answerObject, auditData, recertType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const appType = recertType === "REEVAL" ? "/redetermination" : recertType === "IR" ? "/interimreport" : "/recertification";
      var url = config.TigerContextURL + appType;
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, answerObject, TigerOptions)
        .then((response) => {
          var newConsumerInfo = resetAlertMessage("RECERT");
          sessionStorage.setItem(
            "loadConsumerUser",
            JSON.stringify(newConsumerInfo)
          );
          dispatch(submitRecertDataAction(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(serviceFailedAction(error));
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}
export function saveRecertData(answerObject, auditData,actionButtonType, recertType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let applicationType = recertType === "REEVAL" ? "/saveRedetermination" : recertType === "IR" ? "/saveInterimReport" : "/saveRecertification";
      var url = config.TigerContextURL + applicationType;
      // var url = config.TigerContextURL + applicationType;
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, answerObject, TigerOptions)
        .then((response) => {
          let householdTemp = [];
          let nonCitizenTemp = [];
          if(actionButtonType ===  TextProps.SAVEEXIT){
            const updatedDateAndTime = recertType === "IR" ?  response.data.updatedDt: response.data.updateDateTime;
            dispatch(saveandExitAction({updatedDate: updatedDateAndTime,pendingHours: response.data.pendingHours,saveExitShowModal:TextProps.VALUE_TRUE}));
          }
          // if (response.data.householdMembers[0] !== undefined) {
          //   response.data.householdMembers.map((mem) => {
          //     mem.dateOfBirth = formatDate(mem.dateOfBirth);
          //     householdTemp.push(mem);
          //   });
          // }
          if(recertType !== "REEVAL"){
            if (
              response.data.noncitizenStatus !== undefined &&
              response.data.noncitizenStatus !== null
            ) {
              response.data.noncitizenStatus.map((mem) => {
                mem.dateOfBirth = formatDate(mem.dateOfBirth);
                nonCitizenTemp.push(mem);
              });
            } else if (
              response.data.noncitizenStatus !== null &&
              response.data.noncitizenStatus.dateOfBirth !== undefined
            ) {
              response.data.noncitizenStatus.dateOfBirth = formatDate(
                response.data.noncitizenStatus.dateOfBirth
              );
              nonCitizenTemp.push(response.data.noncitizenStatus);
            }
  
            // response.data.householdMembers = householdTemp;
            response.data.noncitizenStatus = nonCitizenTemp;
          }
          if (
            response.data.additionalProperties &&
            response.data.additionalProperties.contactInfoScreen
          ) {
            dispatch(
              setContactInfoObj(
                response.data.additionalProperties.contactInfoScreen
              )
            );
          }
          if (
            response.data.additionalProperties &&
            response.data.additionalProperties.householdMembers
          ) {
            for (let memberListValue of response.data.additionalProperties.householdMembers
              .membersList) {
              if (memberListValue.dataChangeCode && memberListValue.dataChangeCode !== "NOCHNG") {
                if (memberListValue.dataChangeCode === "REMOVE") {
                  memberListValue.dataChangeCode = "removed"
                }
              }
            }
            dispatch(
              setHouseholdMembers(
                response.data.additionalProperties.householdMembers
              )
            );
          }
          dispatch(saveRecretDataAction(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(serviceFailedAction(error));
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}
export function saveRecretDataAction(recertData) {
  return {
    type: actionTypes.saveRecertDataFulfilled,
    payload: recertData,
  };
}
function submitRecertDataAction(response) {
  return {
    type: actionTypes.postRecertDataFulfilled,
    payload: response,
  };
}
export function displayPDFData(noticeId, auditData, agencyId, action) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    axios
      .get(
        config.TigerContextURL + "/notices/" + agencyId + "/" + noticeId,
        TigerOptions
      )
      .then(checkStatus)
      .then(function (data) {
        if (data !== undefined) {
          let stream = {
            data: {
              base64: data.data.stream,
            },
          };
          toShowPDFinWindow(stream, noticeId, action);
        }
      })
      .catch(function (error) {
        dispatch(serviceFailedAction(error));
      });
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}

//
export function downloadPDFData(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/downloadPDFData", sectionsObj, HttpOptions)
      .then(function (data) {
        if (data !== undefined)
          toShowPDFinWindow(data, sectionsObj[0].webAppId);
      });
  };
}
/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, webAppId, action = "download") {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    window.navigator.msSaveOrOpenBlob(blob, "SnapApply_" + webAppId + ".pdf");
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    let byteCharacters = atob(data.data.base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    var fileURL = URL.createObjectURL(file);
    if(action === "print"){
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = fileURL;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    }else{
      window.open(fileURL);
    }
  }
}

export function setContactInfo(contactInfoObj) {
  return (dispatch) => {
    dispatch(setContactInfoObj(contactInfoObj));
  };
}

function setContactInfoObj(contactInfoObj) {
  return {
    type: actionTypes.setContactInfo,
    payload: contactInfoObj,
  };
}

export function setHouseholdMembers(houseHoldObj) {
  return (dispatch) => {
    dispatch(setHouseholdMembersList(houseHoldObj));
  };
}

function setHouseholdMembersList(houseHoldObj) {
  return {
    type: actionTypes.setHouseholdMembers,
    payload: houseHoldObj,
  };
}

export function addStudentToList(studentObj) {
  return (dispatch) => {
    dispatch(addStudentObj(studentObj));
  };
}

function addStudentObj(studentObj) {
  return {
    type: actionTypes.addStudentToList,
    payload: studentObj,
  };
}

export function addContactInfoSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addContactInfoToSections(subSectionObj));
  };
}

function addContactInfoToSections(subSectionObj) {
  return {
    type: actionTypes.addContactInfoToSections,
    payload: subSectionObj,
  };
}

export function addAuthRepSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addAuthRepToSections(subSectionObj));
  };
}

function addAuthRepToSections(subSectionObj) {
  return {
    type: actionTypes.addAuthRepToSections,
    payload: subSectionObj,
  };
}

export function recertStateRefreshSection() {
  return (dispatch) => {
    return dispatch(stateRefreshSections(sections));
  };
}

function stateRefreshSections(recertRefreshSections) {
  return {
    type: actionTypes.recertStateSection,
    payload: recertRefreshSections,
  };
}
//Household Action
export function appendHouseHoldMembersRecert(subMembers) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(houseHoldSuccess(subMembers));
  };
}

function houseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendHouseHoldRecert,
    payload: houseHold,
  };
}

export function addHouseholdAddSectionToList(houseHoldAddObj) {
  return (dispatch) => {
    dispatch(addHouseholdToSections(houseHoldAddObj));
  };
}

function addHouseholdToSections(houseHoldAddObj) {
  return {
    type: actionTypes.addHouseholdInfoToSections,
    payload: houseHoldAddObj,
  };
}

export function setDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatus(status, index));
  };
}
export function applyAddressStorageAPI(responseData) {
  return {
    type: actionTypes.applyAddressStorageAction,
    payload: responseData,
  };
}
function setChangeStatus(status, index) {
  return {
    type: actionTypes.setDataChangeStatus,
    payload: status,
    index: index,
  };
}

export function setNonCitizenStatusUpdate(payload) {
  return (dispatch) => {
    dispatch(setNonCitizenStatusToState(payload));
  };
}

function setNonCitizenStatusToState(payload) {
  return {
    type: actionTypes.setNonCitizenStatusUpdate,
    payload: payload,
  };
}

export function setRecertResponseObj(payload) {
  return (dispatch) => {
    dispatch(setRecertResponseObjToState(payload));
  };
}

function setRecertResponseObjToState(payload) {
  return {
    type: actionTypes.setRecertResponse,
    payload: payload,
  };
}
export function setChildSupportData(childObj) {
  return (dispatch) => {
    dispatch(setChildSupportObjIR(childObj));
  };
}

function setChildSupportObjIR(childObj) {
  return {
    type: actionTypes.setChildSupportData,
    payload: childObj,
  };
}

export function setDataChangeStatusForExpense(status, index) {
  return (dispatch) => {
    dispatch(setDataChangeStatusForIR(status, index));
  };
}

function setDataChangeStatusForIR(status, index) {
  return {
    type: actionTypes.setDataChangeStatusForExpense,
    payload: status,
    index: index,
  };
}
export function setObligationChildSupportChangeStatus(status) {
  return (dispatch) => {
    dispatch(setObligationChangeStatus(status));
  };
}

function setObligationChangeStatus(status) {
  return {
    type: actionTypes.setObligationChildSupportChanged,
    payload: status,
  };
}

export function setCourtOrderedChildSupportChangeStatus(status) {
  return (dispatch) => {
    dispatch(setCourtOrderedChangeStatus(status));
  };
}

function setCourtOrderedChangeStatus(status) {
  return {
    type: actionTypes.setCourtOrderedChildSupportChanged,
    payload: status,
  };
}

export function setShelterOptions(shelterOptions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(setShelterOptionsSuccess(shelterOptions));
  };
}

function setShelterOptionsSuccess(data) {
  return {
    type: actionTypes.setShelterUtilityStatus,
    payload: data,
  };
}

export function setMedicalExpensesChanged(payload) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(setMedicalExpensesChangedStatus(payload));
  };
}

function setMedicalExpensesChangedStatus(data) {
  return {
    type: actionTypes.setMedicalExpensesChangeStatus,
    payload: data,
  };
}

//appendShelterQuestions

export function appendIRShelterQuestions(section) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
      type: actionTypes.appendIRShelterQuestions,
      payload: section,
    });
  };
}

export function setShelterUtilityChangeStatus(status) {
  return (dispatch) => {
    dispatch(setShelterUtilityStatus(status));
  };
}

function setShelterUtilityStatus(status) {
  return {
    type: actionTypes.setShelterUtilityStatus,
    payload: status,
  };
}
export function setShelterDefaultQuestions(status) {
  return (dispatch) => {
    dispatch(setShelterDefaultQuestionsStatus(status));
  };
}

function setShelterDefaultQuestionsStatus(status) {
  return {
    type: actionTypes.setShelterDefaultQuestions,
    payload: status,
  };
}
export function setShelterData(shelterObj) {
  return (dispatch) => {
    dispatch(setShelterDataSuccess(shelterObj));
  };
}

function setShelterDataSuccess(shelterObj) {
  return {
    type: actionTypes.setShelterData,
    payload: shelterObj,
  };
}

export function appendShelterFilteredQuestions(shelterQuestions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(ShelterFilteredQuestionsSuccess(shelterQuestions));
  };
}

function ShelterFilteredQuestionsSuccess(shelterQuestions) {
  return {
    type: actionTypes.appendShelterSubSectionQuestions,
    payload: shelterQuestions,
  };
}

export function saveandExitAction(recertData) {
  return {
    type: actionTypes.saveandExitAction,
    payload: recertData,
  };
}

export function deleteRecertApplication(webType,id,ts) {
  const audit = getAudit();
  let url = config.TigerContextURL + (webType !== "TNFRCT" ? "/deleteirapplication" : "/deletetanfapp");
  let interimDeleteData = {
    webAppTypeCode: webType,
    id:id,
    createTs:ts
  };

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
        axios.put(url, interimDeleteData, options)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.log("DELETE API ERROR")
          reject(error);
        });
    });
  };
}

//Append Recert data for summary
export function appendRecertDataForSummary(sectionData) {
  return (dispatch) => {
    return dispatch(appendRecertSectionData(sectionData));
  };
}

function appendRecertSectionData(sectionData) {
  return {
    type: actionTypes.appendRecertDataForSummary,
    payload: sectionData,
  };
}
export function setRecertDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatusIR(status, index));
  };
}

function setChangeStatusIR(status, index) {
  return {
    type: actionTypes.setRecertDataChangeStatus,
    payload: status,
    index: index,
  };
}
export function setHouseholdResourcesChangedStatus(status) {
  return (dispatch) => {
    dispatch(setHouseholdResourcesStatus(status));
  };
}

function setHouseholdResourcesStatus(status) {
  return {
    type: actionTypes.setHouseholdResourcesChangeStatus,
    payload: status,
  };
}
export function setHouseholdResourcesChangedStatusToTrue(status) {
  return (dispatch) => {
    dispatch(setHouseholdResourcesStatusToTrue(status));
  };
}

function setHouseholdResourcesStatusToTrue(status) {
  return {
    type: actionTypes.setHouseholdResourcesChangeStatusToTrue,
    payload: status,
  };
}

export function setGrossEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setGrossEarnedIncomeStatus(status));
  };
}

function setGrossEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setGrossEarnedIncomeStatus,
    payload: status,
  };
}

export function setAuthRepChangeStatus(status) {
  return (dispatch) => {
    dispatch(setAuthRepStatus(status));
  }
}

function setAuthRepStatus(status) {
  return{
    type: actionTypes.setAuthRepStatus,
    payload: status,
  }
}

export function setAuthRepPurchaseFoodChangeStatus(status) {
  return (dispatch) => {
    dispatch(setAuthRepPurchaseFoodStatus(status));
  }
}

function setAuthRepPurchaseFoodStatus(status) {
  return{
    type: actionTypes.setAuthRepPurchaseFoodStatus,
    payload: status,
  }
}

export function setAuthRepEligibilityChangeStatus(status) {
  return (dispatch) => {
    dispatch(setAuthRepEligibilityStatus(status));
  }
}

function setAuthRepEligibilityStatus(status) {
  return{
    type: actionTypes.setAuthRepEligibilityStatus,
    payload: status,
  }
}

export function setAuthRepResponseBodyData(body) {
  return (dispatch) => {
    dispatch(setAuthRepBody(body))
  }
}

function setAuthRepBody(body) {
  return{
    type: actionTypes.setAuthRepBody,
    payload: body
  }
}

export function setTanfResponseBodyData(body) {
  return (dispatch) => {
    dispatch(setTanfBody(body))
  }
}

function setTanfBody(body) {
  return{
    type: actionTypes.setTanfBody,
    payload: body
  }
}


export function setEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setEarnedIncomeStatus(status));
  };
}

// function setEarnedIncomeStatus(status) {
//   return {
//     type: actionTypes.setEarnedIncomeStatus,
//     payload: status,
//   };
// }

export function appendIRIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(incomeSuccess(income));
  };
}

function incomeSuccess(income) {
  return {
    type: actionTypes.appendIRIncome,
    payload: income,
  };
}
export function appendResourcesDetails(resources) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(resourcesSuccess(resources));
  };
}

function resourcesSuccess(income) {
  return {
    type: actionTypes.appendResources,
    payload: income,
  };
}

//Appened Un-Earned Income
export function appendUIIncomeDetails(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(uiIncomeSuccess(income));
  };
}


//Clearing Income Template
export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(incomeSuccess(incomeTemplate));
  };
}

//Clearing Resources Template
export function clearMemberResources(
  resourcesMemberTemplate,
  resourcesSummaryTemplate,
  resourcesTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    resourcesTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    resourcesTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    if(resourcesSummaryTemplate)summaryTemplate.push(resourcesSummaryTemplate);
    resourcesTemplate.subsections = summaryTemplate;
    return dispatch(resourcesSuccess(resourcesTemplate));
  };
}
//Clearing Income Template
export function clearMemberUIIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(uiIncomeSuccess(incomeTemplate));
  };
}

function uiIncomeSuccess(income) {
  return {
    type: actionTypes.appendUIIncome,
    payload: income,
  };
}

// export function setEarnedIncomeChangeStatus(status) {
//   return (dispatch) => {
//     dispatch(setEarnedIncomeStatus(status));
//   };
// }

function setEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setEarnedIncomeStatus,
    payload: status,
  };
}

export function setUnearnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setUnearnedIncomeStatus(status));
  };
}

function setUnearnedIncomeStatus(status) {
  return {
    type: actionTypes.setUnearnedIncomeStatus,
    payload: status,
  };
}

export function setQuestionOneChange(status) {
  return (dispatch) => {
    dispatch(setQuestionOneChangeStatus(status));
  };
}

function setQuestionOneChangeStatus(status) {
  return {
    type: actionTypes.setQuestionOneStatus,
    payload: status,
  };
}

export function setQuestionTwoChange(status) {
  return (dispatch) => {
    dispatch(setQuestionTwoChangeStatus(status));
  };
}

function setQuestionTwoChangeStatus(status) {
  return {
    type: actionTypes.setQuestionTwoStatus,
    payload: status,
  };
}

export function setQuestionThreeChange(status) {
  return (dispatch) => {
    dispatch(setQuestionThreeChangeStatus(status));
  };
}

function setQuestionThreeChangeStatus(status) {
  return {
    type: actionTypes.setQuestionThreeStatus,
    payload: status,
  };
}

export function setLastQuestionChange(status) {
  return (dispatch) => {
    dispatch(setLastQuestionChaneStatus(status));
  };
}

function setLastQuestionChaneStatus(status) {
  return {
    type: actionTypes.setLastQuestionStatus,
    payload: status,
  };
}

export function setIfAnswerYesChange(status) {
  return (dispatch) => {
    dispatch(setIfAnswerYesChangeStatus(status));
  };
}

function setIfAnswerYesChangeStatus(status) {
  return {
    type: actionTypes.setIfAnswerYesStatus,
    payload: status,
  };
}

export function setCommentText(status) {
  return (dispatch) => {
    dispatch(setCommentTextStatus(status));
  };
}

function setCommentTextStatus(status) {
  return {
    type: actionTypes.setCommentStatus,
    payload: status,
  };
}

export function setRegisterVoteFlgChange(status) {
  return (dispatch) => {
    dispatch(setRegisterVoteStatus(status));
  };
}

function setRegisterVoteStatus(status) {
  return {
    type: actionTypes.setRegisterVoteFlg,
    payload: status,
  };
}

export function setChangeAddressChange(status) {
  return (dispatch) => {
    dispatch(setChangeAddressStatus(status));
  };
}

function setChangeAddressStatus(status) {
  return {
    type: actionTypes.setChangeAddressFlg,
    payload: status,
  };
}

export function setNoRegisterVoteFlgChange(status) {
  return (dispatch) => {
    dispatch(setNoRegisterVoteStatus(status));
  };
}

function setNoRegisterVoteStatus(status) {
  return {
    type: actionTypes.setNoRegisterVoteFlg,
    payload: status,
  };
}