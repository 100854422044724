import * as ListItemTypes from "../../utils/constants/listItemTypes";
import * as SharedProps from "../../utils/constants/shared";
import * as BenefitProps from "../../utils/constants/benefit";
import * as providerConst from "../provider/providerText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

const daysFromToday = (days) => {
  return new Date(new Date().setDate(new Date().getDate() + days));
};

export const PROVIDER_CLIENTS = [
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "1",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Eric",
      [SharedProps.LAST_NAME]: "Bai",
      [SharedProps.DOB]: new Date(1981, 11, 2),
      [SharedProps.PHONE]: "9132893789",
      [SharedProps.ACCOUNT_ID]: "1947385024",
      [SharedProps.CLIENT_ID]: 0,
    },
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(15),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
    [BenefitProps.STATUS_MESSAGE]: "Receiving SNAP since 3.Feb.17",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "2",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Eric",
      [SharedProps.LAST_NAME]: "Benoit",
      [SharedProps.SUFFIX]: "Sr.",
      [SharedProps.DOB]: new Date(1950, 5, 16),
      [SharedProps.PHONE]: "9137291123",
      [SharedProps.ACCOUNT_ID]: "1938532269",
    },
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(90),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
    [BenefitProps.SUBSTATUSES]: [
      {
        [BenefitProps.SUBSTATUS]: BenefitProps.STATUS_TYPE_STARTED,
        [BenefitProps.SUBSTATUS_PROBLEM]: TextProps.VALUE_FALSE,
      },
    ],
    [BenefitProps.STATUS_MESSAGE]: "24 days till case closes",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "3",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Caitlynn",
      [SharedProps.LAST_NAME]: "Carter",
      [SharedProps.DOB]: new Date(1981, 5, 21),
      [SharedProps.PHONE]: "9131661832",
      [SharedProps.ACCOUNT_ID]: "0029643728",
    },
    [BenefitProps.CLIENT_ACTIVITY]: TextProps.VALUE_TRUE,
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(90),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_DENIED,
    [BenefitProps.STATUS_MESSAGE]: "as of 3.Sep.17",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "4",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Edwin",
      [SharedProps.LAST_NAME]: "Choi",
      [SharedProps.DOB]: new Date(1950, 5, 16),
      [SharedProps.PHONE]: "9137291123",
      [SharedProps.ACCOUNT_ID]: "1938532269",
    },
    [BenefitProps.CLIENT_ACTIVITY]: TextProps.VALUE_TRUE,
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(90),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
    [BenefitProps.SUBSTATUSES]: [
      {
        [BenefitProps.SUBSTATUS]: BenefitProps.STATUS_TYPE_INTERVIEW,
        [BenefitProps.SUBSTATUS_PROBLEM]: TextProps.VALUE_FALSE,
      },
      {
        [BenefitProps.SUBSTATUS]: BenefitProps.STATUS_TYPE_DOCUMENTS,
        [BenefitProps.SUBSTATUS_PROBLEM]: TextProps.VALUE_TRUE,
      },
    ],
    [BenefitProps.STATUS_MESSAGE]: "6 days till case closes",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "5",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Taylor",
      [SharedProps.LAST_NAME]: "Dayton",
      [SharedProps.DOB]: new Date(1983, 11, 2),
      [SharedProps.PHONE]: "9137895765",
      [SharedProps.ACCOUNT_ID]: "5028937051",
    },
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(6),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_ACTIVE,
    [BenefitProps.STATUS_MESSAGE]: "Receiving SNAP since 3.Feb.17",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "6",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "John",
      [SharedProps.LAST_NAME]: "Fitzgerald",
      [SharedProps.DOB]: new Date(1945, 7, 21),
      [SharedProps.PHONE]: "9132092700",
      [SharedProps.ACCOUNT_ID]: "1798759873",
    },
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(90),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
    [BenefitProps.SUBSTATUSES]: [
      {
        [BenefitProps.SUBSTATUS]: BenefitProps.STATUS_TYPE_PROCESSING,
        [BenefitProps.SUBSTATUS_PROBLEM]: TextProps.VALUE_FALSE,
      },
    ],
    [BenefitProps.STATUS_MESSAGE]: "processing since 4.May.17",
  },
  {
    [SharedProps.TYPE]: ListItemTypes.PROVIDER_CLIENT,
    [SharedProps.ID]: "7",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Maryanne",
      [SharedProps.LAST_NAME]: "Grossman",
      [SharedProps.DOB]: new Date(1943, 7, 21),
      [SharedProps.PHONE]: "9137290393",
      [SharedProps.ACCOUNT_ID]: "6009402941",
    },
    [BenefitProps.ACCOUNT_ACCESS_EXPIRATION_DATE]: daysFromToday(90),
    [BenefitProps.STATUS]: BenefitProps.STATUS_TYPE_PENDING,
    [BenefitProps.SUBSTATUSES]: [
      {
        [BenefitProps.SUBSTATUS]: BenefitProps.STATUS_TYPE_DOCUMENTS,
        [BenefitProps.SUBSTATUS_PROBLEM]: TextProps.VALUE_TRUE,
      },
    ],
    [BenefitProps.STATUS_MESSAGE]: "4 days till case closes",
    [BenefitProps.STATUS_MESSAGE_URGENT]: TextProps.VALUE_TRUE,
  },
];

export const SEARCHED_CLIENTS = [
  {
    [SharedProps.TYPE]: ListItemTypes.SEARCHED_CLIENT,
    [SharedProps.ID]: "1",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Tory",
      [SharedProps.LAST_NAME]: "Michelson",
      [SharedProps.DOB]: new Date(1979, 5, 8),
      [SharedProps.PHONE]: "9132092700",
      [SharedProps.ACCOUNT_ID]: "1798759873",
      [SharedProps.SSN_LAST_FOUR]: "3232",
    },
  },
  {
    [SharedProps.TYPE]: ListItemTypes.SEARCHED_CLIENT,
    [SharedProps.ID]: "2",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "John",
      [SharedProps.LAST_NAME]: "Michelson",
      [SharedProps.DOB]: new Date(1945, 7, 21),
      [SharedProps.PHONE]: "9134938209",
      [SharedProps.ACCOUNT_ID]: "2049827411",
      [SharedProps.SSN_LAST_FOUR]: "2089",
    },
  },
  {
    [SharedProps.TYPE]: ListItemTypes.SEARCHED_CLIENT,
    [SharedProps.ID]: "3",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Sandra",
      [SharedProps.LAST_NAME]: "Michelson",
      [SharedProps.DOB]: new Date(1970, 3, 28),
      [SharedProps.PHONE]: "9133348893",
      [SharedProps.ACCOUNT_ID]: "3948765664",
      [SharedProps.SSN_LAST_FOUR]: "4928",
    },
  },
  {
    [SharedProps.TYPE]: ListItemTypes.SEARCHED_CLIENT,
    [SharedProps.ID]: "4",
    [SharedProps.USER]: {
      [SharedProps.FIRST_NAME]: "Maria",
      [SharedProps.LAST_NAME]: "Michelson",
      [SharedProps.DOB]: new Date(1992, 9, 3),
      [SharedProps.PHONE]: "9139731195",
      [SharedProps.ACCOUNT_ID]: "9482664518",
      [SharedProps.SSN_LAST_FOUR]: "7729",
    },
  },
];

export const providerSNAPGAPNavSections = [
  {
    [SharedProps.ID]: "searchConsumer",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).searchAllClients,
    [SharedProps.ICON]: "home",
  },
  {
    [SharedProps.ID]: "home",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).myClients,
    [SharedProps.ICON]: "map",
  },
  {
    [SharedProps.ID]: "snapOutreach",
    [SharedProps.TITLE]: "SNAP Gap Outreach",
    [SharedProps.ICON]: "handshake",
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]: "My Account",
    [SharedProps.ICON]: "my-info",
  },
];

export const providerNavSections = [
  {
    [SharedProps.ID]: "searchConsumer",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).searchAllClients,
    [SharedProps.ICON]: "home",
  },
  {
    [SharedProps.ID]: "home",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).myClients,
    [SharedProps.ICON]: "map",
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]: "My Account",
    [SharedProps.ICON]: "my-info",
  },
];
/**
 * added new const for NOPSI roles doesn't have myClients tab
 */
export const providerNOPSIRoleNavSections = [
  {
    [SharedProps.ID]: "searchConsumer",
    [SharedProps.TITLE]:
      providerConst.providerConst(selectedLanguage).searchAllClients,
    [SharedProps.ICON]: "home",
  },
  {
    [SharedProps.ID]: "myAccount",
    [SharedProps.TITLE]: "My Account",
    [SharedProps.ICON]: "my-info",
  },
];

export default providerNavSections;
