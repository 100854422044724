import * as Types from "../../utils/constants/types";
import * as OptionsProps from "../../utils/constants/options";
import * as TextProps from "../../utils/constants/text";
import * as Props from "../../utils/components/shared";
import { aboutMeMultilanguageLabels } from "./greeterScreeningText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
// greeter screening page has no translations as per bussiness requirement
if (language === null || language != Types.ENGLISH) {
  language = Types.ENGLISH;
}

export const sections = [
  {
    id: "about-me",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelFirstName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "lastName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "ap-id",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).haveAgencyID,
        [Props.TYPE]: Types.INPUT_NUMERIC_ONLY,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).haveAgencyIDErrMsg,
        [Props.MAX_LENGTH]: 9,
      },
      {
        [Props.NAME]: "phoneNumber",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
        [Props.TYPE]: Types.INPUT_PHONE,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).validPhoneAlert,
      },
      {
        [Props.NAME]: "access",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).access,
        [Props.INLINE_HELP]: aboutMeMultilanguageLabels(language).clientAccess,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).radioErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).accessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "visit-reason",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).visitReason,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).radioErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).visitReasonOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "services",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).services,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).radioErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).serviceOptions,

        [OptionsProps.OPTIONS_PER_ROW]: 2,
        [OptionsProps.CONDITIONALS]: [
          {
            [OptionsProps.CONDITIONAL_TARGET_VALUE]:
              aboutMeMultilanguageLabels(language).serviceOptions[6],
            [OptionsProps.CONDITIONAL_QUESTIONS]: [
              {
                [Props.NAME]: "text-area",
                [Props.TYPE]: Types.INPUT_TEXT_AREA,
                [Props.REQUIRED]: TextProps.VALUE_TRUE,
                [Props.ERROR]: TextProps.VALUE_FALSE,
                [Props.ERROR_MESSAGE]:
                  aboutMeMultilanguageLabels(language).cmtOtherErrMsg,
                [Props.MAX_LENGTH]: 200,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default sections;
