import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import DTAInput from "./dtaInput";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { tryCall } from "./utilities/controls";
import _ from "lodash";
import { resourcesSectionMultilanguageLabels } from "../../pages/applySnap/applySnapText";
import {
  INPUT_ALPHANUMERIC_SPACE_TEXT,
  INPUT_ALPHA_TEXT,
  INPUT_TYPE_MONEY,
} from "../constants/controls";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import * as languageConsts from "../../utils/constants/types";

import { langOptSel } from "../constants/constants";
let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
if (selectedLanguage === null || selectedLanguage === langOptSel) {
  selectedLanguage = "en-US";
}

class ResourcesTemplateThree extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onZipCodeChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      amount: PropTypes.string,
      bankName: PropTypes.string,
      amountRequired: PropTypes.bool,
      amountError: PropTypes.bool,
      amountErrorMessage: PropTypes.string,
      bankNameRequired: PropTypes.bool,
      bankNameError: PropTypes.bool,
      bankNameErrorMessage: PropTypes.string,
      type: PropTypes.string,
    }),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
    let copyProps = _.cloneDeep(props);
    this.state = this._buildStateFromProps(copyProps);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this._buildStateFromProps(nextProps));
    }
  }

  render() {
    const { error, disabled, required, name, className, data } = this.props,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={className}>
        {this._buildFirstLine(
          name,
          ariaLabels,
          error,
          disabled,
          required,
          data
        )}
      </div>
    );
  }

  // Rendering
  _buildFirstLine(name, ariaLabels, error, disabled, required, data) {
    let currentSection =  window.location.pathname === "/reportchange" ? 
        this.props.consumer.reportChangeSections[0] 
        : window.location.pathname === "/recertification" 
        ? this.props.recert.sections.filter((sec) => sec.id === "resources-summary")[0]
        : {};
    let currentApllication = currentSection;
   
    return (
      <div className="pure-g margin-bottom-half">
        {
          <div className="pure-u-1-1">
            {/* <div>
             <b>{data.subHeader}</b>
            </div>
            <br /> */}
            <div>
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {
                  resourcesSectionMultilanguageLabels(
                    selectedLanguage,
                    data.subLabel
                  ).templateOneLabe31
                }
              </label>
              {this.state._value.amountError && (
                <div className="pure-g">
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state._value.amountErrorMessage}
                  </div>
                </div>
              )}
              <DTAInput
                name={name}
                type={INPUT_TYPE_MONEY}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForAmount}
                value={this.state._value.amount}
                error={this.state._value.amountError}
                disabled={disabled}
                required={this.state._value.amountRequired}
                maxLength={13}
              />
            </div>
            <br />
            <div>
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {currentApllication && currentApllication.srApplication
                  ? resourcesSectionMultilanguageLabels(selectedLanguage)
                      .templateOneLabeNotReq32
                  : resourcesSectionMultilanguageLabels(selectedLanguage)
                      .templateOneLabe32}
              </label>
              {this.state._value.bankNameError && (
                <div className="pure-g">
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state._value.bankNameErrorMessage}
                  </div>
                </div>
              )}
              <DTAInput
                name={name}
                type={INPUT_ALPHA_TEXT}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForBankName}
                value={this.state._value.bankName}
                error={this.state._value.bankNameError}
                disabled={disabled}
                required={this.state._value.bankNameRequired}
                maxLength={30}
              />
            </div>
          </div>
        }
      </div>
    );
  }

  _handleChangeForAmount = (amountValue) => {
    let newValue = {};
    if(window.location.pathname === "/reportchange"){
        if(this.state._value.amount !== amountValue && this.state._value.hasOwnProperty('datachangeCode') && this.state._value.datachangeCode !== "NEW"){
          newValue = {
            ...this.state._value,
            amount: Number(amountValue),
            datachangeCode: "CHANGE"
          }
        } else if(!this.state._value.hasOwnProperty('datachangeCode') || this.state._value.datachangeCode === "NEW"){
          newValue = {
            ...this.state._value,
            amount: Number(amountValue),
            datachangeCode: "NEW"
          } 
        } else {
           newValue = {
            ...this.state._value,
            amount: Number(amountValue),
            datachangeCode: "NOCHNG"
          } 
        }
    } else {
      newValue =  {...this.state._value, amount: amountValue};
    }
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForBankName = (bankNameValue) => {
  let newValue =  {};
  if(window.location.pathname === "/reportchange"){
    if(this.state._value.bankName !== bankNameValue && this.state._value.hasOwnProperty('datachangeCode') && this.state._value.datachangeCode !== "NEW"){
      newValue = {
        ...this.state._value,
        bankName: bankNameValue,
        datachangeCode: "CHANGE"
      }
    } else if(!this.state._value.hasOwnProperty('datachangeCode') || this.state._value.datachangeCode === "NEW"){
      newValue = {
        ...this.state._value,
        bankName: bankNameValue,
        datachangeCode: "NEW"
      } 
    } else {
      newValue = {
        ...this.state._value,
        bankName: bankNameValue,
        datachangeCode: "NOCHNG"
      } 
    }
  } else {
    newValue =  {...this.state._value, bankName: bankNameValue};
  }
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  // Helpers
  _buildStateFromProps(props) {
    if (props.value) {
      return {
        _value: props.value,
      };
    } else {
      return {
        _value: {
          amount: "",
          bankName: "",
          amountRequired: TextProps.VALUE_FALSE,
          amountError: TextProps.VALUE_FALSE,
          amountErrorMessage: "",
          bankNameRequired: TextProps.VALUE_FALSE,
          bankNameError: TextProps.VALUE_FALSE,
          bankNameErrorMessage: "",
          type: "resources",
          templateType: languageConsts.RESOURCES_TEMP_THREE,
        },
      };
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesTemplateThree);
