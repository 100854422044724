import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import {
  getConsumerLoginContents,
  onLogin,
  onMFASubmit,
} from "../../redux/user/loginActions";
import { mapStateToProps } from "../../redux/rootReducer";
import dtaLogo from "../../utils/resources/images/alabama.png";
import LanguageSelect from "../../redux/language/languageSelect";
import { Link } from "react-router-dom";
import DTAFooter from "../../utils/components/dtaFooter";
import DTALoginModal from "../../utils/components/modal/dtaLoginModal";
import DTAInput from "../../utils/components/dtaInput";
import * as recapthaKey from "../../utils/constants/appConfigConstants";
import Icon from "../../utils/components/dtaIcon";
import { enableUniqueIds } from "react-html-id";
import Recaptcha from "react-recaptcha";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as routesConstants from "../../utils/constants/routesConstants";
import * as TextProps from "../../utils/constants/text";

let recaptchaInstance;
const executeCaptcha = function () {
  recaptchaInstance.execute();
};
const verifyCallback = function (response) {
  recaptchaInstance.reset();
};

class ConsumerLogin extends Component {
  constructor(props) {
    super(props);
    this.goToSignup = this.goToSignup.bind(this);
    this.loginValidation = this.loginValidation.bind(this);
    this.state = {
      isAutErrorMsg: "",
      mfaModel: TextProps.VALUE_FALSE,
      loginSpinner: TextProps.VALUE_FALSE,
      type: "password",
      emailErrorMessage: TextProps.VALUE_FALSE,
      pswError: TextProps.VALUE_FALSE,
      mfaToken: "",
      mfaLoginDetails: "",
      userPassword: "",
    };
    enableUniqueIds(this);
  }

  componentDidMount() {
    this.setState({
      loginSectionContents: TextProps.VALUE_TRUE,
      loginSuccessful: TextProps.VALUE_FALSE,
      loginErrorState: TextProps.VALUE_FALSE,
      mfaSuccessful: TextProps.VALUE_FALSE,
    });
    this.setState({ loginSpinner: TextProps.VALUE_TRUE });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.consumer.loginSuccessful === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_TRUE
    ) {
      this.setState({
        loginSectionContents: TextProps.VALUE_FALSE,
        loginSuccessful: TextProps.VALUE_TRUE,
        loginErrorState: TextProps.VALUE_FALSE,
      });
      this.loginModal.hideBlur();
      if (nextProps.consumer.mfaLogin === TextProps.VALUE_TRUE) {
        this.setState({
          loginSectionContents: TextProps.VALUE_FALSE,
          mfaModel: TextProps.VALUE_TRUE,
          mfaErrorState: TextProps.VALUE_FALSE,
          loginSucessDetails: nextProps.consumer.loginDetails,
          mfaLoginDetails: nextProps.consumer.mfaLoginDetails,
        });
      } else {
        this.routeUser(nextProps);
      }
    } else if (
      nextProps.consumer.loginSuccessful === TextProps.VALUE_TRUE &&
      nextProps.consumer.mfaLoginValidated === TextProps.VALUE_TRUE
    ) {
      this.setState({
        loginSectionContents: TextProps.VALUE_FALSE,
        mfaModel: TextProps.VALUE_FALSE,
        mfaErrorState: TextProps.VALUE_FALSE,
        loginSucessDetails: nextProps.consumer.loginDetails,
      });
      this.routeUser(nextProps);
    } else if (
      nextProps.consumer.loginErrorState === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_TRUE
    ) {
      this.setState({
        loginErrorState: TextProps.VALUE_TRUE,
        loginErrorMessage: nextProps.consumer.loginErrorMessage,
      });
      this.loginModal.hideBlur();
    } else if (
      nextProps.consumer.mfaErrorState === TextProps.VALUE_TRUE &&
      this.state.loginSectionContents === TextProps.VALUE_FALSE
    ) {
      this.setState({
        mfaErrorState: TextProps.VALUE_TRUE,
      });
    }
  }

  routeUser = (nextProps) => {
    let _this = this;
    let providerFlag, mhProviderFlag;
    this.setState(
      {
        mfaErrorState: TextProps.VALUE_FALSE,
        loginSucessDetails: nextProps.consumer.loginDetails,
      },
      () => {
        this.state.loginSucessDetails.roleList.forEach((role) => {
          if (
            role.roleName === roleProps.USER ||
            role.roleName === roleProps.CONSUMER
          ) {
            providerFlag = TextProps.VALUE_FALSE;
          } else if (
            role.roleName === roleProps.PROVIDER ||
            role.roleName === roleProps.PROVIDER_ROLE_SPVUSR ||
            role.roleName === roleProps.PROVIDER_ROLE_DCPVSG
          ) {
            providerFlag = TextProps.VALUE_TRUE;
          } else if (role.roleName === roleProps.MH_PROVIDER_ROLE) {
            mhProviderFlag = TextProps.VALUE_TRUE;
          } else if (role.roleName === roleProps.GREETER_ROLE) {
            this.setState({ loading: TextProps.VALUE_FALSE });
            window.location.assign(routesConstants.GREETER_ROLE);
          }
        });
        if (providerFlag === TextProps.VALUE_TRUE) {
          _this.providerLoginInfo(_this.state.loginSucessDetails, nextProps);
        } else if (providerFlag === TextProps.VALUE_FALSE) {
          _this.consumerLoginInfo(_this.state.loginSucessDetails, nextProps);
        } else if (mhProviderFlag === TextProps.VALUE_TRUE) {
          _this.mhPoviderLoginInfo(_this.state.loginSucessDetails, nextProps);
        }
      }
    );
  };

  // connecting to PROVIDER page with auth details
  providerLoginInfo = (nextProps) => {
    this.props.gotoProvider();
  };

  // getting consumer clientId to display consumer my info by passing Centrify info(email,last_name,first_name..etc)
  consumerLoginInfo = (nextProps) => {
    this.props.gotoConsumer();
  };

  mhPoviderLoginInfo = (nextProps) => {
    this.props.gotoMhProvider();
  };

  //Validation before sending
  submitValidation = (e) => {
    e.preventDefault();
    //Trigger Submit Request
    this.loginSubmit();
  };

  onCloseModal = () => {
    window.location.assign(routesConstants.HOME_PATH);
  };

  loginChangeVal(loginDetails) {
    loginDetails.email = this.state.email;
    const pwd = new Buffer(this.state.password).toString("base64");
    this.setState({ userPassword: pwd });
    loginDetails.password = pwd;
    this.props.onLogin(loginDetails);
  }

  loginValidation = (event) => {
    const { login } = this.props.language.security;
    this.setState({ email: this.refs.email.inputEl.value });
    var emailAddr = this.state.email;
    var emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegEx.test(emailAddr) || emailAddr === undefined) {
      this.setState({ emailErrorMessage: TextProps.VALUE_TRUE });
      this.emailErrMSg = login.emailErrorMsg;
      event.preventDefault();
    } else {
      this.emailErrMSg = "";
    }
    this.setState({ password: this.refs.password.inputEl.value });
    var pswd = this.state.password;
    if (pswd === "" || pswd === undefined) {
      this.setState({ pswError: TextProps.VALUE_TRUE });
      this.pswErrorMessage = login.passwordErrorMessage;
      event.preventDefault();
    } else {
      this.pswErrorMessage = "";
    }
  };

  loginSubmit = () => {
    const { loginSpinner } = this.state;
    if (!loginSpinner) {
      this.loginModal.hideBlur();
    } else {
      this.loginModal.showBlur("loading", "");
    }
    let loginDetails = {};

    setTimeout(() => {
      this.loginChangeVal(loginDetails);
    }, 500);

    //recaptcha related code
    var recaptchaFlag = recapthaKey.RECAPTCHA_FLAG;
    if (recaptchaFlag) {
      executeCaptcha();
    }
  };

  submitMFA = (event) => {
    var MFADetails = {};
    const { login } = this.props.language.security;
    var mfaToken = this.state.mfaToken;
    if (mfaToken === "" || mfaToken === undefined) {
      this.setState({
        pswError: TextProps.VALUE_TRUE,
        mfaErrorState: TextProps.VALUE_FALSE,
      });
      this.mfaErrorMessage = login.verificationCodeErrorMessage;
      event.preventDefault();
    } else {
      this.mfaErrorMessage = "";
      this.setState({ pswError: TextProps.VALUE_FALSE });
      event.preventDefault();

      if (this.state.loginSucessDetails !== undefined) {
        if (this.state.email !== undefined) {
          MFADetails.email = this.state.email;
          const pwd = new Buffer(this.state.mfaToken).toString("base64");
          MFADetails.password = pwd;
          MFADetails.tenantId = this.state.mfaLoginDetails.tenantId;
          MFADetails.sessionId = this.state.mfaLoginDetails.sessionId;
          MFADetails.mechanismId = this.state.mfaLoginDetails.mechanismId;

          var userPassword = this.state.userPassword;
          this.props.onLogin(MFADetails, userPassword);
          this.setState({ loginSpinner: TextProps.VALUE_TRUE });
        }
      }
    }
  };

  showHidePass = (event) => {
    event.preventDefault();
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  };

  render() {
    const { login } = this.props.language.security;
    const { home } = this.props.language;
    let hide = login.hide;
    let show = login.show;
    let loginErrMsg = this.state.loginErrorMessage;

    return (
      <div className="home page-wrapper">
        <nav className="nav homepage__nav">
          <div className="navbar navbar--white">
            <div className="max-width">
              <Link to={routesConstants.HOME_PATH} className="navbar__logo">
                <img src={dtaLogo} alt="ACES DHR Self-Service Portal home" />
              </Link>
              <h1 className="nav__title hidden-touch">ACES DHR Self-Service Portal</h1>
              <ul className="nav-list navbar__nav-list">
              <li className="nav-list__item1">Translate Page</li>
                <li className="nav-list__item">
                  <LanguageSelect />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <main className="main login-modal">
          <section className="homepage__hero-container">
            <div className="hero homepage__hero"></div>
          </section>
          <DTALoginModal
            ref={(instance) => (this.loginModal = instance)}
            isOpen={this.state.loginSectionContents}
            modalClass="login-modal"
            titleText="Log in"
          >
            <div className="dta-modal__body pad-all dataLoginModel">
              <div className="dta-modal__header">
                <h2>{login.login}</h2>
                <button
                  className="dta-button--minor close-modal"
                  id={this.lastUniqueId()}
                  onClick={this.onCloseModal}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
              <form
                name="dtaloginform"
                onSubmit={this.submitValidation.bind(this)}
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">{login.email}</label>
                    <div>
                      {this.state.emailErrorMessage ? (
                        <b>
                          <p style={{ color: "red" }}>{this.emailErrMSg}</p>
                        </b>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="signin-email"
                      maxLength={100}
                      ref="email"
                      onChange={(val) => this.setState({ email: val })}
                      type="email"
                      required={TextProps.VALUE_TRUE}
                      error={this.inputUsernameError}
                      ariaLabel="signInEmail"
                    />
                  </div>
                </div>
                <div className="pure-g">
                  <div className="pure-u-1">
                    <div className="pure-g">
                      <div className="pure-u-1-2">
                        <label className="dta-form__label">
                          {login.password}
                        </label>
                      </div>
                      <div className="pure-u-1-2">
                        <a
                          className="forgetPasswordLink"
                          href={routesConstants.FORGOT_PASSWORD_PATH}
                        >
                          {login.forgot_password}
                        </a>
                      </div>
                    </div>
                    <div>
                      {this.state.pswError ? (
                        <b>
                          <p style={{ color: "red" }}>{this.pswErrorMessage}</p>
                        </b>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="signin-pass"
                      maxLength={30}
                      ref="password"
                      onChange={(val) => this.setState({ password: val })}
                      type={this.state.type}
                      required={TextProps.VALUE_TRUE}
                      error={this.passwordError}
                      value={this.state.password}
                      ariaLabel="signInPassword"
                    />
                    <span className="pass-toggle" onClick={this.showHidePass}>
                      {this.state.type === "text" ? hide : show}
                    </span>
                  </div>
                </div>
                {(() => {
                  if (this.state.loginErrorState === TextProps.VALUE_TRUE) {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 dta-form__error-message">
                          {loginErrMsg ? login.authFailed : null}
                        </div>
                      </div>
                    );
                  }
                })()}
                <div>
                  {recapthaKey.RECAPTCHA_FLAG ? (
                    <span>
                      <button
                        className="dta-button dta-button--primary dta-button--large dta-login"
                        data-badge="inline"
                        type="submit"
                      >
                        {login.login}
                      </button>
                      <Recaptcha
                        ref={(e) => (recaptchaInstance = e)}
                        sitekey={recapthaKey.SITE_KEY}
                        render="explicit"
                        size="invisible"
                        verifyCallback={verifyCallback}
                      />
                    </span>
                  ) : (
                    <button
                      className="dta-button dta-button--primary dta-button--large dta-login"
                      type="submit"
                      onClick={this.loginValidation}
                    >
                      {login.login}
                    </button>
                  )}
                </div>
              </form>
              <div className="pure-g">
                <div className="pure-u-1">
                  <hr />
                  <h4 className="margin-bottom-half register-bottom-text">
                    {login.signupMsg}
                  </h4>
                  <br />
                  <button
                    className="dta-button dta-button--outline-primary dta-button--large dta-login-create-button"
                    onClick={this.goToSignup}
                  >
                    {login.signupbutton}
                  </button>
                </div>
              </div>
            </div>
          </DTALoginModal>
          {/* MFA Confirmation Code Modal */}
          <DTALoginModal
            isOpen={this.state.mfaModel}
            modalClass="login-modal"
            titleText="MFA Authentication"
          >
            <div className="dta-modal__body pad-all dataLoginModel">
              <div className="model-header">
                <h1>{login.verify_Account}</h1>
              </div>
              <form
                name="dtaloginMfaform"
                onSubmit={this.submitMFA.bind(this)}
                novalidate
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <p>{login.mfatokenText}</p>
                    <div>
                      {this.state.pswError ? (
                        <b>
                          <p style={{ color: "red" }}>{this.mfaErrorMessage}</p>
                        </b>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="signin-pass"
                      maxLength={30}
                      placeholder={login.enterVerificationCode}
                      ref="mfaCode"
                      onChange={(val) => this.setState({ mfaToken: val })}
                      type={this.state.type}
                      error={this.passwordError}
                      value={this.state.mfaToken}
                    />
                    <span className="pass-toggle" onClick={this.showHidePass}>
                      {this.state.type === "text" ? hide : show}
                    </span>
                  </div>
                </div>
                {(() => {
                  if (this.state.mfaErrorState === TextProps.VALUE_TRUE) {
                    return (
                      <div className="pure-g">
                        <div className="pure-u-1 dta-form__error-message">
                          {this.state.mfaErrorState ? login.authFailed : null}
                        </div>
                      </div>
                    );
                  }
                })()}
                <div>
                  <button
                    className="dta-button dta-button--primary dta-button--large dta-login"
                    type="submit"
                  >
                    {login.submitCode}
                  </button>
                </div>
              </form>
            </div>
          </DTALoginModal>
        </main>
        {<DTAFooter language={home} />}
      </div>
    );
  }

  goToSignup = () => {
    this.props.onConsumerSignup();
  };

  _doRetrieve = () => {
    return "";
  };

  _handleChangeForQuestion = () => {
    return "";
  };

  _getUniqueId = () => {
    return "";
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onGetConsumerLogin: getConsumerLoginContents,
      onConsumerLogin: () => push(routesConstants.CONSUMER_HOME_PATH),
      onConsumerSignup: () => push(routesConstants.SIGNUP_PATH),
      gotoProvider: () => push(routesConstants.PROVIDER_PATH),
      gotoConsumer: () => push(routesConstants.CONSUMER_PATH),
      gotoMhProvider: () => push(routesConstants.MH_PROVIDER_PATH),
      onLogin: onLogin,
      onMFASubmit: onMFASubmit,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerLogin);
