//Consumer Info Action
import axios from "axios";
import { getAudit } from "../../utils/constants/audit";
import { filterData } from "../../utils/components/utilities/utils";
import * as TextProps from "../../utils/constants/text";

import * as roleProps from "../../../src/utils/constants/rolesConstants";
var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  getConsumerDocumentRequested: "CONSUMER_DOCUMENTS_REQUESTED",
  getConsumerDocumentRejected: "CONSUMER_DOCUMENTS_REJECTED",
  getConsumerDocumentFulfilled: "CONSUMER_DOCUMENTS_FULFILLED",
  getDTANotificationRejected: "CONSUMER_NOTIFICATION_REJECTED",
  getDTANotificationFulfilled: "CONSUMER_NOTIFICATION_FULFILLED",
  getDTANoticeData: "CONSUMER_NOTICE_DATA_FULFILLED",
  getConsumerDocumentListFulfilledAction:
    "CONSUMER_DOCUMENTLIST_DATA_FULFILLED",
  getConsumerDocumentListRejectedAction: "CONSUMER_DOCUMENTLIST_DATA_REJECTED",
  getConsumerSelectedDocsList: "CONSUMER_FILTER_DOCUMENTS_LIST",
  getSecureMessageListRequested: "SECURE_MESSAGE_LIST_REQUESTED",
  getSecureMessageListRejected: "SECURE_MESSAGE_LIST_REJECTED",
  getEmailBaesdOnTypeRequested:"APPLICATION_TYPE_REQUESTED",
  getEmailBaesdOnTypeRejected:"APPLICATION_TYPE_REJECTED",
  getSecureMessageNewRequested: "SECURE_MESSAGE_NEW_REQUESTED",
  getSecureMessageNewRejected: "SECURE_MESSAGE_NEW_REJECTED",
  getSecureMessageUpdateRequested: "SECURE_MESSAGE_UPDATE_REQUESTED",
  getSecureMessageUpdateRejected: "SECURE_MESSAGE_UPDATE_REJECTED",
  getSecureMessageReplyRequested: "SECURE_MESSAGE_REPLY_REQUESTED",
  getSecureMessageReplyRejected: "SECURE_MESSAGE_REPLY_REJECTED",
  noticesUpdateSuccess: "NOTICES_UPDATE_SUCCESS",
  noticesUpdateFailure: "NOTICES_UPDATE_FAILURE",
  documentDetailList: "DOCUMENT_DETAIL_LIST",
  messageStatusFlg: "MESSAGE_STATUS_FLG",
  errormessageStatusFlg: "ERROR_MESSAGE_STATUS_FLG"
};

/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, webAppId) {
  //For IE using atob convert base64 encoded data to byte array
  if (data.stream != undefined) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      let byteCharacters = atob(data.stream);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      window.navigator.msSaveOrOpenBlob(blob, webAppId + ".pdf");
    } else {
      let byteCharacters = atob(data.stream);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArrayVal = new Uint8Array(byteNumbers);
      let file = new Blob([byteArrayVal], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    // for Blob as Html page
  } else {
    var content = atob(data.noticeHtml);
    let file = new Blob([content], { type: "text/html" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

//FETCH THE LIST OF DHR NOTIFICATION FOR THE LOGGED-IN CONSUMER
export function getDTANotificationList(agencyID) {
  return (dispatch, getState) => {
    // Axios Http Options
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL + "/notices/" + agencyID, TigerOptions)
      .then(function (response) {
        dispatch(getDTANotificationFulfilledAction(response.data));
      })
      .catch(function (error) {
        dispatch(getDTANotificationRejectedAction());
      });
  };
}

export function getDTANotificationListFilter(agencyID, startDate, endDate) {
  return (dispatch, getState) => {
    // Axios Http Options
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL + "/notices/" + agencyID, TigerOptions)
      .then(function (response) {
        var filteredData = filterData(
          response.data.notices,
          startDate,
          endDate,
          "sentDate"
        );
        response.data.notices = filteredData;
        dispatch(getDTANotificationFulfilledAction(response.data));
      })
      .catch(function (error) {
        dispatch(getDTANotificationRejectedAction());
      });
  };
}

//FETCH THE URL MAPPED FOR THE CLICKED DHR NOTIFICATION FOR THE LOGGED-IN CONSUMER
export function getDTANotificationData(noticeID, agencyID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .get(
          config.TigerContextURL + "/notices/" + agencyID + "/" + noticeID,
          TigerOptions
        )
        .then(function (response) {
          resolve(response);
          toShowPDFinWindow(response.data, response.data.noticeInfo.id);
        })
        .catch((error) => {
          dispatch(getDTANotificationRejectedAction(error));
          reject(error);
        });
    });
  };
}

export function getClientDocumentsList(agencyID) {
  if (agencyID !== null && agencyID !== undefined && agencyID !== "") {
    return (dispatch, getState) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };

      axios
        .get(
          config.TigerContextURL + "/documentMemberList/" + agencyID,
          TigerOptions
        )
        .then(function (data) {
          sessionStorage.setItem(
            "ClientDocumentsList",
            JSON.stringify(data.data)
          );
          dispatch(getConsumerDocumentListFulfilledAction(data));
        })
        .catch(function (error) {
          dispatch(getConsumerDocumentListRejectedAction(error));
        });
    };
  }
}

//FETCH THE LIST OF DOCUMENTS FOR THE LOGGED-IN CONSUMER
export function getConsumerDocumentList(agencyID, theCode) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    let selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
    const grantedAppRoles = [roleProps.PROVIDER, roleProps.PROVIDER_SNAP_GAP, roleProps.PROVIDER_NO_PSI, roleProps.USER, roleProps.CONSUMER]

    if(loggedInUser){
      const roleList = loggedInUser.roleList.filter(a=> grantedAppRoles.includes(a.roleName));
      if(roleList.length && roleList[0].roleName !== roleProps.CONSUMER){
        if(roleList[0].roleName === roleProps.USER){
          agencyID = loggedInUser.email;
        }else{
          agencyID = selectedConsumerEmail;
        }
      }
    }

    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL + "/documents/" + agencyID, TigerOptions)
      .then(function (response) {
        if (theCode === undefined) {
          dispatch(getConsumerDocumentFulfilledAction(response.data));
        } else {
          dispatch(getConsumerDocumentFulfilledAction(response.data, theCode));
        }
      })
      .catch(function (error) {
        dispatch(getConsumerDocumentRejectedAction());
      });
  };
}

//FETCH THE LIST OF DOCUMENTS FOR THE LOGGED-IN CONSUMER
export function getConsumerDocumentListFilter(
  agencyID,
  webAppId,
  startDate,
  endDate,
  type,
  userAuth
) {
  return (dispatch, getState) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL + "/documents/" + agencyID, TigerOptions)
      .then(function (response) {
        // dispatch(getConsumerDocumentFulfilledAction(sampleResponse))
        var filteredData = filterData(
          response.data.documents,
          startDate,
          endDate,
          "receivedDate"
        );
        response.data.documents = filteredData;
        dispatch(getConsumerDocumentFulfilledAction(response.data));
      })
      .catch(function (error) {
        dispatch(getConsumerDocumentRejectedAction());
      });
  };
}

//FILTERTHE LIST OF DOCUMENTS FOR THE LOGGED-IN CONSUMER
export function getConsumerSelectedDocumentList(filteredDocTypeCode) {
  return (dispatch, getState) => {
    dispatch(getConsumerSelectedDocumentListAction(filteredDocTypeCode));
  };
}

function getConsumerSelectedDocumentListAction(data) {
  return {
    type: actionTypes.getConsumerSelectedDocsList,
    payload: data,
  };
}

//Notification Action Dispatch
function getDTANotificationRejectedAction(error) {
  return {
    type: actionTypes.getDTANotificationRejected,
    payload: error,
  };
}

function getDTANotificationFulfilledAction(data) {
  return {
    type: actionTypes.getDTANotificationFulfilled,
    payload: data,
  };
}

function getConsumerDocumentListFulfilledAction(data) {
  return {
    type: actionTypes.getConsumerDocumentListFulfilledAction,
    payload: data,
  };
}

function getConsumerDocumentListRejectedAction(error) {
  return {
    type: actionTypes.getConsumerDocumentListRejectedAction,
  };
}

//Document Action Dispatch
function getConsumerDocumentRequestedAction() {
  return {
    type: actionTypes.getConsumerDocumentRequested,
  };
}

function getConsumerDocumentRejectedAction() {
  return {
    type: actionTypes.getConsumerDocumentRejected,
  };
}
//Secure Message

export function getSecureMessageData(payload,url) {
  return (dispatch) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    axios({
      method: 'post',
      url: config.TigerContextURL + "/securemessage/"+url,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      data: payload
    })
    .then(function (response) {
      if(response.status ===  200) {
        dispatch(getSecureMessageRequestedAction(response.data,url));
        if(url === "compose" || url === "reply") {
          setTimeout(() => {
            dispatch(getMessageStatus(TextProps.VALUE_TRUE))
          }, 500);
        }
      }
    })
    .catch(function (error) {
      if(url === "compose" || url === "reply") {
        setTimeout(() => {
          dispatch(getErrorMessageStatus(TextProps.VALUE_TRUE))
          // dispatch(getSecureMessageRejectedAction(url));
        }, 500);
      }
      else {
        console.log("Error Message", error);
      }
    });
  }
}


export function getCaseManagerDetail(params,url) {

  return (dispatch) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    const TigerOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };

    axios
      .get(config.TigerContextURL+ "/" + url + "/" + params, TigerOptions)
      .then(function (response) {
        dispatch(getSecureMessageRequestedAction(response.data,url));
      })
      .catch(function (error) {
        dispatch(getSecureMessageRejectedAction(url));
      });
  };
}
function getConsumerDocumentFulfilledAction(data, theCode) {
  return {
    type: actionTypes.getConsumerDocumentFulfilled,
    payload: data,
    theCode: theCode,
  };
}

export function getDocumentsDetails() {
  return (dispatch) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const email = window.sessionStorage.getItem('user_name');
    const selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/clientstatus/" + (selectedConsumerEmail ? selectedConsumerEmail : email);
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: true,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url, options)
        .then(function (response) {
          dispatch(documentGetCall(response));
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

function documentGetCall(data) {
  return {
    type: actionTypes.documentDetailList,
    payload: data,
  };
}

function getSecureMessageRequestedAction(data,url) {
  let actionText = ''
  switch (url) {
    case 'list':
      actionText =  actionTypes.getSecureMessageListRequested
      break;
      case 'consumer':
        actionText = actionTypes.getEmailBaesdOnTypeRequested
      break;
      case 'compose':
        actionText = actionTypes.getSecureMessageNewRequested
      break;
      case 'update':
        actionText = actionTypes.getSecureMessageUpdateRequested
      break;
      case 'reply':
        actionText = actionTypes.getSecureMessageReplyRequested
      break;

      
  
    default:
      break;
  }
  return {
    type:actionText,
    payload: data,
  };
}

function getSecureMessageRejectedAction(url) {
  let actionText = ''
  switch (url) {
    case 'list':
      actionText =  actionTypes.getSecureMessageListRejected
      break;
      case 'consumer':
        actionText = actionTypes.getEmailBaesdOnTypeRejected
      break;
      case 'compose':
        actionText = actionTypes.getSecureMessageNewRejected
      break;
      case 'update':
        actionText = actionTypes.getSecureMessageUpdateRejected
      break;
      case 'reply':
        actionText = actionTypes.getSecureMessageReplyRejected
      break;
      
  
    default:
      break;
  }
}

// noticesUpadte post call 

export function noticesUpdatePostCall(payload) {
  return (dispatch) => {
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const audit = getAudit();
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/notices/update";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: true,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, payload, options)
        .then(function (response) {
          dispatch(noticesUpdatePostCallSuccess(response));
          resolve(response);
        })
        .catch(function (error) {
          dispatch(noticesUpdatePostCallSuccessFailure(error));
          reject(error);
        });
    });
  };
}

export function getMessageStatus (data) {
  return {
    type: actionTypes.messageStatusFlg,
    payload: data,
  };
}

export function getErrorMessageStatus (data) {
  return {
    type: actionTypes.errormessageStatusFlg,
    payload: data,
  };
}

function noticesUpdatePostCallSuccess(data) {
  return {
    type: actionTypes.noticesUpdateSuccess,
    payload: data,
  };
}

function noticesUpdatePostCallSuccessFailure(error) {
  return {
    type: actionTypes.noticesUpdateFailure,
    payload: error,
  };
}